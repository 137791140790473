import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Calendar, FileOutline, FolderOpen, Trash, User } from '@superb-ai/icons';
import { Box, Icon, IconButton, Typography, vars } from '@superb-ai/ui';
import { format } from 'date-fns';

import { COLOR_CHIP_PALETTE } from '../../../../../../../consts/ColorChips';
import { MemberData } from '../../../../../../../types/memberTypes';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { DatasetResult } from '../../../../../services/DatasetService';
import { DatasetPool } from '../../../../../types/routeTypes';
import PrepareDownloadButton from '../../actionButton/PrepareDownloadButton';

const CustomCheckbox = withStyles({
  root: {
    color: COLOR_CHIP_PALETTE.WHITE,
    '&$checked': {
      color: COLOR_CHIP_PALETTE.GRAPEFRUIT,
    },
  },
  checked: {},
})(Checkbox);

export default function SliceListBox({
  slice,
  selectedSlice,
  handleSelectDataset,
  setSelectedDeleteSlice,
  users,
}: {
  slice: DatasetResult;
  selectedSlice: string[];
  handleSelectDataset: (id: string) => void;
  setSelectedDeleteSlice: (id: string) => void;
  users: MemberData[];
}): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();

  const { datasetId, accountName, datasetPool } = useParams<{
    accountName: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [createdBy, setCreatedBy] = useState<MemberData>();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { datasetInfo } = useDatasetContext();

  const checked = useMemo(() => selectedSlice.includes(slice.id), [selectedSlice, slice.id]);

  useEffect(() => {
    const result = users.find(user => user.email === slice.createdBy);
    setCreatedBy(result);
  }, []);

  return (
    <Box>
      <Box
        key={slice.id}
        position="relative"
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        cursor="pointer"
        onClick={e => {
          e.stopPropagation();
          history.push(`/${accountName}/curate/${datasetPool}/${datasetId}/slice/${slice.id}`);
        }}
        backgroundColor={{
          default: checked ? 'primary-opacity-100' : undefined,
          hover: 'gray-opacity-100',
        }}
        borderRadius="2px"
        style={{
          ...(checked && {
            boxShadow: `0px 0px 0px 2px ${vars.color.primary}`,
          }),
        }}
      >
        <Box
          position="relative"
          width="100%"
          borderRadius="2px"
          style={{
            paddingTop: '100%',
            backgroundImage: slice.thumbnailUrl ? `url(${slice.thumbnailUrl})` : 'grey',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <CustomCheckbox
            style={{
              position: 'absolute',
              top: '5px',
              left: '5px',
              zIndex: 5,
              cursor: 'pointer',
              ...(isHovered || selectedSlice.includes(slice.id) ? {} : { display: 'none' }),
            }}
            checked={checked}
            onClick={e => {
              e.stopPropagation();
              handleSelectDataset(slice.id);
            }}
          />
          {!slice.thumbnailUrl && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0"
              left="0"
              flexDirection="column"
              backgroundColor="white"
              borderRadius="2px"
            >
              <Icon icon={FolderOpen} size="48px" color="gray-250" />
              <Typography variant="m-strong" color="gray-300">
                {t('curate.slices.empty')}
              </Typography>
            </Box>
          )}
          {isHovered && (
            <Box
              borderRadius="2px"
              width="100%"
              height="100%"
              position="absolute"
              top="0"
              left="0"
              backgroundColor="gray-opacity-100"
            ></Box>
          )}
          {/* {selectedSlice.includes(slice.id) && (
            <div
              style={{
                width: '100%',
                height: '100%',
                border: '4px solid #FF625A',
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '4',
              }}
            ></div>
          )} */}
        </Box>
        <Box py={1.5} px={1}>
          <Box
            style={{
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '1',
            }}
            mb={0.5}
            overflow="hidden"
          >
            <Typography variant="l-strong">{slice?.name}</Typography>
          </Box>
          <Box
            style={{
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              minHeight: '38px',
            }}
            overflow="hidden"
            mb={0.5}
          >
            <Typography variant="m-regular">{slice.description}</Typography>
          </Box>
          <Box mb={1} display="flex">
            <Box display="flex" gap="small" alignItems="center">
              <Icon icon={FileOutline} color="gray-300" />
              <Typography variant="m-regular">{slice.imageCount?.toLocaleString()}</Typography>
            </Box>
          </Box>
          {!showPublicDatasets && (
            <>
              <Box mb={1} display="flex" gap="small">
                <Icon icon={User} color="gray-300" />
                <Typography
                  variant="m-regular"
                  style={{
                    maxWidth: '80px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {createdBy?.name || '-'}
                </Typography>
              </Box>
              <Box display="flex" gap="small">
                <Icon icon={Calendar} color="gray-300" />
                <Typography
                  variant="m-regular"
                  color="gray-300"
                  style={{
                    maxWidth: '80px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {slice?.createdAt ? format(new Date(slice?.createdAt), 'MMM d,yyyy') : '-'}
                </Typography>
              </Box>
              {isHovered && (
                <Box
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  style={{ bottom: '12px', right: '8px' }}
                >
                  <PrepareDownloadButton
                    imageTotalCount={slice.imageCount}
                    sliceName={slice.name}
                    sliceId={slice.id}
                    datasetId={datasetId}
                    datasetName={datasetInfo.name}
                    onClick={e => e.stopPropagation()}
                    buttonProps={{ size: 's' }}
                  />
                  <IconButton
                    size="s"
                    variant="text"
                    color="primary"
                    icon={Trash}
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedDeleteSlice(slice.id);
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box borderBottom="2px solid" borderColor="gray-150" />
    </Box>
  );
}
