interface Point {
  x: number;
  y: number;
}

const rotateTransform = (origin: Point, point: Point, radian: number): Point => {
  return {
    x:
      (point.x - origin.x) * Math.cos(-radian) -
      (point.y - origin.y) * Math.sin(-radian) +
      origin.x,
    y:
      (point.x - origin.x) * Math.sin(-radian) +
      (point.y - origin.y) * Math.cos(-radian) +
      origin.y,
  };
};

/**
 * Given a numerator and a denominator, returns ratio
 * rounded to the nearest integer value
 */
const calculateRatio = (params: {
  numerator: number;
  denominator: number;
  // nearest?: 'hundredth' | 'int';
}): number => {
  const { numerator, denominator } = params;
  if (denominator == 0 || numerator == 0) return 0;
  const ratio = numerator / denominator;
  return Math.round(ratio);
};

/**
 *  Given a numerator and a denominator calculates percent total
 *
 *  If 0 <= ratio < 1, percent = 0
 *  If 99 < ratio, percent = 99
 *  Other we just return percent rounded to nearest integer or specified decimal
 **/
const calculatePercent = (params: {
  numerator: number;
  denominator: number;
  nearest?: 'hundredth' | 'int' | 'exact';
}): number => {
  const { numerator, denominator, nearest } = params;
  if (denominator == 0 || numerator == 0) return 0;
  if (nearest === 'exact') return (numerator / denominator) * 100;
  return roundPercent((numerator / denominator) * 100, nearest);
};

function round(value: number, precision?: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const roundPercent = (percent: number, nearest: 'hundredth' | 'int' = 'int'): number => {
  if (nearest === 'hundredth') return Math.round(100 * percent) / 100;
  if (percent >= 0 && percent < 1) {
    return Math.ceil(percent);
  } else if (percent >= 99 && percent < 100) {
    return Math.floor(percent);
  }
  return Math.round(percent);
};

const roundToPercentStr = (percent: number, nearest: 'hundredth' | 'int'): string => {
  if (percent === 0) return '0';
  if (percent === 100) return '100';
  const roundedPercent =
    nearest === 'hundredth' ? Math.round(100 * percent) / 100 : Math.round(percent);

  return percent < 1 ? '< 1' : percent >= 99 ? '> 99' : `${roundedPercent}`;
};

export default {
  rotateTransform,
  calculateRatio,
  calculatePercent,
  round,
  roundPercent,
  roundToPercentStr,
};
