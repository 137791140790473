import RegexUtils from '../../../utils/RegexUtils';

export const MINIMUM_NAME_LENGTH = 4;
export const MAXIMUM_NAME_LENGTH = 50;

export function isNameLengthValid(name: string): boolean {
  if (!name) return false;
  return name.length >= MINIMUM_NAME_LENGTH && name.length <= MAXIMUM_NAME_LENGTH;
}

export function isNameSpecialCharacterValid(name: string): boolean {
  if (!name) return false;
  return RegexUtils.IS_ASCII(name) && !RegexUtils.HAS_SPECIAL_SYMBOLS(name);
}
