const DATASET = {
  min: 4,
  max: 50,
};

const TAG = {
  min: 1,
  max: 20,
};

export default {
  DATASET,
  TAG,
};
