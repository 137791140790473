import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CurveIngest, Trash } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Icon, IconButton, Tooltip } from '@superb-ai/ui';

import { useActionContext } from '../../../../contexts/ActionContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useSliceContext } from '../../../../contexts/SliceContext';
import DeleteDataModal from '../modal/DeleteDataModal';
import RemoveFromSlice from '../modal/RemoveFromSlice';

export default function DeleteActionButton({ sliceName }: { sliceName?: string }) {
  const { t } = useTranslation();

  const { sliceInfo } = useSliceContext();
  const { selectedData, selectedAllData } = useActionContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [removeFromSliceModalIsOpen, setRemoveFromSliceModalIsOpen] = useState<boolean>(false);

  return (
    <>
      {deleteModalIsOpen && (
        <DeleteDataModal
          deleteDataModalIsOpen={deleteModalIsOpen}
          setDeleteDataModalIsOpen={setDeleteModalIsOpen}
        />
      )}

      {removeFromSliceModalIsOpen && (
        <RemoveFromSlice
          removeFromSliceModalIsOpen={removeFromSliceModalIsOpen}
          setRemoveFromSliceModalIsOpen={setRemoveFromSliceModalIsOpen}
        />
      )}
      {sliceInfo?.id ? (
        <DropdownMenu
          AnchorElement={
            <IconButton
              variant="text"
              disabled={(!selectedData.length && !selectedAllData) || showPublicDatasets}
              icon={Trash}
            />
          }
          placement="bottom-start"
        >
          <DropdownMenuItem onClick={() => setRemoveFromSliceModalIsOpen(true)}>
            <Box display="flex" alignItems="center" gap="small">
              <Icon icon={CurveIngest} />
              {t('curate.button.removeFromSlice')}{' '}
            </Box>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setDeleteModalIsOpen(true)}>
            <Box display="flex" alignItems="center" gap="small">
              <Icon icon={Trash} />
              {t('curate.button.deleteFromDataset')}
            </Box>
          </DropdownMenuItem>
        </DropdownMenu>
      ) : (
        <Tooltip content={t('curate.button.delete')} placement="top" hideOnEmptyContent>
          <span>
            <IconButton
              variant="text"
              disabled={(!selectedData.length && !selectedAllData) || showPublicDatasets}
              onClick={() => {
                setDeleteModalIsOpen(true);
              }}
              icon={Trash}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
}
