import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Pencil } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';
import { get, join, last, map } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService from '../../../../services/LabelsService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import { formatDate, formatDistance } from '../../../../utils/date';
import LabelUtils from '../../../../utils/LabelUtils';
import CircularProgressDialog from '../../../elements/CircularProgressDialog';
import EditTagsDialog from '../labelList/EditTagsDialog';

const useStyles = makeStyles(() => ({
  box: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  table: {
    '& .MuiTableRow-root': {
      marginBottom: '3px',
      verticalAlign: 'top',
      minHeight: '25px',
    },
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
    '& .MuiTableCell-body': {
      fontSize: '11px',
      color: '#212121',
      padding: '3px 0px',
      '& p': {
        fontSize: '11px',
        color: '#212121',
      },

      '&:nth-child(1)': {
        fontWeight: 500,
        width: '80px',
      },
      '&:nth-child(2)': {
        paddingLeft: '6px',
      },
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-2px',
    alignItems: 'center',
  },
  chip: {
    borderRadius: '2px',
    height: '16px',
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.71)',
    padding: '2px 6px',
    margin: '2px',
    '& .MuiChip-label': {
      padding: '0px',
    },
  },
  categorizationCell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  preLabelCell: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const LabelInformation = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const projectInfo = useProjectInfo();
  const labelsInfo = useLabelsInfo();
  const { categorizationIdToName } = projectInfo;
  const { label, setLabel } = labelDetailViewInfo;
  const { getLabels, setCheckedLabels } = labelsInfo;
  const [isEditTagsDialogOpen, setIsEditTagsDialogOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const assigneeName = projectInfo.userObjects[label.workAssignee]
    ? projectInfo.userObjects[label.workAssignee].name
    : label.workAssignee;

  const categorizations = get(label, 'info.result.categorization.value', undefined);

  const getCategorizationPath = (categorization: string): string => {
    return join(
      categorization.split(',').map(id => categorizationIdToName[id]),
      '-',
    );
  };

  const getPreLabelDateInfo = (): string => {
    const { autoLabelInfoLastUpdatedAt } = label;

    let result = '';

    if (autoLabelInfoLastUpdatedAt) {
      result += `Auto-Label (${formatDate(autoLabelInfoLastUpdatedAt)})`;
    }

    result = result === '' ? 'None' : result;

    return result;
  };

  const handleClickEditTags = (): void => {
    setIsEditTagsDialogOpen(true);
  };

  const updateLabels = async (): Promise<void> => {
    setIsRequesting(true);
    try {
      const nextLabel = await LabelsService.getLabel({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setLabel(nextLabel);

      await getLabels();
      setIsRequesting(false);
    } catch (err: any) {
      setIsRequesting(false);
    }
  };

  return (
    <>
      <MUI.Box className={classes.box}>
        <MUI.Table className={classes.table}>
          <MUI.TableBody>
            <MUI.TableRow>
              <MUI.TableCell>Date added</MUI.TableCell>
              <MUI.TableCell>{formatDate(label.createdAt)}</MUI.TableCell>
            </MUI.TableRow>
            <MUI.TableRow>
              <MUI.TableCell>Last updated</MUI.TableCell>
              <MUI.TableCell>{formatDistance(label.lastUpdatedAt)}</MUI.TableCell>
            </MUI.TableRow>
            <MUI.TableRow>
              <MUI.TableCell>Assignee</MUI.TableCell>
              <MUI.TableCell>{assigneeName || 'None'}</MUI.TableCell>
            </MUI.TableRow>
            {categorizations && (
              <MUI.TableRow>
                <MUI.TableCell>Image Category</MUI.TableCell>
                <MUI.TableCell className={classes.categorizationCell}>
                  {map(categorizations, (categorization: string) => (
                    <MUI.Box key={categorization}>
                      <MUI.Tooltip title={getCategorizationPath(categorization)}>
                        <MUI.Box
                          height="16px"
                          bgcolor="#fbfbfb"
                          mr="8px"
                          mb="5px"
                          padding="0 10px"
                          border="solid 1px #f3f3f3"
                          display="flex"
                          alignItems="center"
                        >
                          {categorizationIdToName[last(categorization.split(',')) as string]}
                        </MUI.Box>
                      </MUI.Tooltip>
                    </MUI.Box>
                  ))}
                </MUI.TableCell>
              </MUI.TableRow>
            )}
            {!UserRoleUnion.isWorker(authInfo?.projectRole) && !authInfo.isGuest && (
              <MUI.TableRow>
                <MUI.TableCell>Label Tag</MUI.TableCell>
                <MUI.TableCell>
                  <MUI.Box className={classes.tags}>
                    {map(label.tags, tag => (
                      <MUI.Chip
                        className={classes.chip}
                        key={tag.id}
                        label={tag.name}
                        style={{ backgroundColor: tag.info.color }}
                      />
                    ))}
                    <MUI.IconButton
                      size="small"
                      style={{ width: '16px', height: '16px' }}
                      onClick={handleClickEditTags}
                    >
                      <Icon icon={Pencil} size={14} />
                    </MUI.IconButton>
                  </MUI.Box>
                </MUI.TableCell>
              </MUI.TableRow>
            )}
            <MUI.TableRow>
              <MUI.TableCell>Status</MUI.TableCell>
              <MUI.TableCell>{t(LabelUtils.getStatusText(label.status))}</MUI.TableCell>
            </MUI.TableRow>
            <MUI.TableRow>
              <MUI.TableCell>Pre-labels</MUI.TableCell>
              <MUI.TableCell className={classes.preLabelCell}>
                {getPreLabelDateInfo()}
              </MUI.TableCell>
            </MUI.TableRow>
          </MUI.TableBody>
        </MUI.Table>
      </MUI.Box>
      <EditTagsDialog
        isOpen={isEditTagsDialogOpen}
        setIsOpen={setIsEditTagsDialogOpen}
        checkedLabels={[label.id]}
        setCheckedLabels={setCheckedLabels}
        isAllLabelsChecked={false}
        getLabels={updateLabels}
      />
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
};

export default LabelInformation;
