import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Clear } from '@superb-ai/icons';
import { Box, Checkbox, IconButton, ToggleButton, Tooltip, Typography } from '@superb-ai/ui';

import SortAscDescButton from '../../../../../../../components/composition/SortAscDescButton';
import useSectionSize from '../../../../../../../components/elements/windowedImageGrid/useSectionSize';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { ClusterCenterGrid } from './ClusterCenterGrid';

export default function ClusterCenters({ onClose }) {
  const { t } = useTranslation();
  const { setSelectedAllData, setSelectedData, setDeselectedData } = useActionContext();
  const {
    leafImageClusters,
    imageClusters,
    showSelectedOnly,
    setShowSelectedOnly,
    handleSelectAllSuperClusters,
    selectedLeafIds,
  } = useImageFilterContext();
  const containerRef = useRef<HTMLDivElement>();
  const sectionSize = useSectionSize(containerRef);

  const [clusterSizeSortOrder, setClusterSizeSortOrder] = useState<'asc' | 'desc'>('desc');
  const [displayedItems, setDisplayedItems] =
    useState<{ id: string; size: number; thumbnailUrl?: string }[]>();

  const selectedFilteredCount = (displayedItems || []).filter(cluster =>
    selectedLeafIds.includes(cluster?.id),
  ).length;

  const totalCount = leafImageClusters.length;
  const selectedCount = selectedLeafIds.length;

  function getCheckboxState() {
    if (selectedFilteredCount === 0) return false;
    if (selectedFilteredCount === displayedItems.length) return true;
    return 'mixed';
  }

  function handleCheckAll(superClusters: string[]) {
    const selectAll = getCheckboxState() === 'mixed' ? true : !getCheckboxState();
    handleSelectAllSuperClusters(superClusters, selectAll);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      border="1px solid"
      borderColor="gray-200"
      ref={containerRef}
      style={{ width: '372px', borderRadius: '4px 0 0 0' }}
    >
      <Box style={sectionSize && { height: sectionSize[3] - 2 }}>
        <Box backgroundColor="gray-100" display="flex" alignItems="center" pl={1}>
          <Typography variant="m-strong">
            {t('curate.imageFilter.aiSelectedImages')} (
            <Typography color="primary">{totalCount.toLocaleString('en')}</Typography>)
          </Typography>
          <IconButton
            variant="text"
            icon={Clear}
            onClick={onClose}
            style={{ marginLeft: 'auto' }}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          borderBottom="1px solid"
          borderColor="gray-100"
          px={1}
          py={0.5}
        >
          <Checkbox
            value={getCheckboxState()}
            onClick={() => handleCheckAll(imageClusters?.map(c => c.id))}
          />
          <Typography variant="s-strong">
            <Trans
              i18nKey="curate.filter.selectedCount"
              values={{ count: selectedCount }}
              components={{ emphasize1: <Typography color="primary" /> }}
            />
          </Typography>
          <Tooltip placement="bottom" content={t('curate.imageFilter.selectedOnly')}>
            <span>
              <ToggleButton
                size="xs"
                disabled={selectedCount === 0}
                checked={selectedCount !== 0 && showSelectedOnly}
                onChange={() => setShowSelectedOnly(!showSelectedOnly)}
              />
            </span>
          </Tooltip>
          <Tooltip content={t('curate.filter.sortBySize')}>
            <span style={{ marginLeft: 'auto' }}>
              <SortAscDescButton
                sortOrder={clusterSizeSortOrder}
                onSort={setClusterSizeSortOrder}
              />
            </span>
          </Tooltip>
        </Box>
        <Box p={1} style={{ flex: 1 }}>
          {containerRef.current && (
            <ClusterCenterGrid
              sortOrder={clusterSizeSortOrder}
              setDisplayedItems={setDisplayedItems}
              height={containerRef.current.clientHeight - 80}
              width={containerRef.current.clientWidth - 8}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
