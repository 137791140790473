import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import { CurateImageData } from '../../../../../../services/DatasetService';
import { excludedImageMetadataKeys } from '../image/const';
import DetailModalCabinetCollapse from '../image/DetailModalCabinetCollapse';

export default function ImageMetadata({ imageInfo }: { imageInfo: CurateImageData }) {
  const { t } = useTranslation();

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const metadataEntries = Object.entries(imageInfo.metadata).filter(
    ([key]) => !excludedImageMetadataKeys.includes(key),
  );

  return (
    <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageMetadata')}>
      <Box display="flex" flexDirection="column" gap={1.5}>
        {metadataEntries.map((pair, index) => (
          <Box display="flex" alignItems="center" key={pair[0]}>
            <Box style={{ ...textEllipsisStyle, width: '50%' }}>
              <Typography variant="m-strong">{pair[0]}</Typography>
            </Box>
            <Box style={{ ...textEllipsisStyle, width: '50%' }}>
              <Typography variant="m-regular">
                {pair[0] === 'fileSize' ? (pair[1] as number).toLocaleString('en') : pair[1]}
                {(pair[0] === 'width' || pair[0] === 'height') && 'px'}
                {pair[0] === 'fileSize' && ' byte'}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </DetailModalCabinetCollapse>
  );
}
