export enum LabelFields {
  status = 'status',
  labelTag = 'tag_ids',
  assetGroup = 'asset_group',
  autoLabelDifficulty = 'auto_label_info_tags_difficulty',
  lastReviewAction = 'last_review_action',
  reviewRound = 'review_round',
}

export enum ObjectFields {
  classIds = 'class_ids',
  labelTag = 'tag_ids',
}

export enum CategoryFields {
  categoryIds = 'info_tags_categories_id',
}

/** Display name for each field */
export const GroupByDisplayNames = {
  [LabelFields.status]: 'by Status',
  [LabelFields.labelTag]: 'by Tag',
  [LabelFields.assetGroup]: 'by Dataset',
  [ObjectFields.classIds]: 'by Class Name', // on UI, display class name
  [CategoryFields.categoryIds]: 'by Category',
} as Record<GroupByField, string>;

export const groupByDisplayName = (groupBy: GroupByField) => GroupByDisplayNames[groupBy];
export type GroupByField = LabelFields | ObjectFields | CategoryFields;
export const IdFields = [LabelFields.labelTag, ObjectFields.classIds, CategoryFields.categoryIds];
export type IdField = LabelFields.labelTag | ObjectFields.classIds | CategoryFields.categoryIds;

export function isObjectFieldEnum(value: string): value is ObjectFields {
  return Object.values(ObjectFields).includes(value as ObjectFields);
}

export function isLabelFieldEnum(value: string): value is LabelFields {
  return Object.values(LabelFields).includes(value as LabelFields);
}
