import React from 'react';

type GradientProps = {
  id: string;
  // gradientStyle: React.CSSProperties[]
  startStopStyle: React.CSSProperties;
  endStopStyle: React.CSSProperties;
};

// Sample Data for multiple stops
{
  /* <linearGradient id="line-gradient" gradientUnits="userSpaceOnUse" x1="0" y1="450" x2="0" y2="-257.2913883344074">
  <stop offset="0%" stopColor="red" stopOpacity="0"></stop>
  <stop offset="40%" stopColor="red"></stop>
  <stop offset="40%" stopColor="black"></stop>
  <stop offset="62%" stopColor="black"></stop>
  <stop offset="62%" stopColor="lawngreen"></stop>
  <stop offset="100%" stopColor="lawngreen"></stop>
</linearGradient>; */
}

/**
 * Set gradient by choosing start and end colors for <stop> elements.
 */
const LinearGradient: React.FC<GradientProps> = ({ id, startStopStyle, endStopStyle }) => {
  return (
    <defs>
      <linearGradient id={id} gradientUnits="userSpaceOnUse" x1="0%" x2="0%" y1="30%" y2="100%">
        <stop offset="0%" style={startStopStyle} />
        <stop offset="100%" style={endStopStyle} />
      </linearGradient>
    </defs>
  );
};

export default LinearGradient;
