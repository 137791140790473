import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { LoadingIndicatorDiv } from '../../../../../apps/Curate/components/datasets/dataset/analytics/components/LoadingIndicatorDiv';
import {
  ChartBar,
  ChartDonut,
  ChartTreemap,
  Clear,
  Download,
  ImageObject,
  ImageSequence,
  MoreHorizontal,
  Pencil,
} from '@superb-ai/icons';
import { DropdownMenu } from '@superb-ai/norwegian-forest';
import { Box, Icon, IconButton, SegmentedControl } from '@superb-ai/ui';

import {
  getClassIdToNameMap,
  getIdToNameMap,
  Mapper,
} from '../../../../../apps/projects/project/labels/actions/analyticsUtils';
import { PreviewChartArea } from '../../../../../apps/projects/project/labels/actions/PreviewChartArea';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { DEFAULT_DASHBOARD } from '../../../../../services/CustomAnalyticsService';
import FileUtils from '../../../../../utils/FileUtils';
import { formatNumber } from '../../../../../utils/numberFormat';
import { renderMenuItem } from '../../../../elements/buttonMenu/render';
import { ButtonSize } from '../../AnalyticsCustomizeTab';
import ReportStatisticsCard from '../../userStats/ReportStatisticsCard';
import { PREVIEW_CHART_COLOR } from '../color';
import {
  useDeleteChartMutation,
  useGetChartDataQuery,
  useUpdateChartMutation,
} from '../customQueries';
import { getDownloadFilename, transformDownload } from '../download';
import { CategoryFields, GroupByField, IdField, LabelFields, ObjectFields } from '../fields';
import { TransformFunctionMap } from '../transformer';
import { ChartStorage, ChartType, ChartTypeEnum } from '../type';
import ChartInfoDetail from './ChartInfoDetail';
import { EditableTitle } from './EditableTitle';

const CARD_BACKGROUND_COLOR = '#FBFBFB';

export const ChartCard = (props: {
  chartId: string;
  chartData: ChartStorage;
  chartTitle: string;
  handleDownload?: (chart: ChartStorage) => void;
  style?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();

  const tagIdToName = getIdToNameMap(projectInfo.tags);
  const classIdToName = getClassIdToNameMap(projectInfo.project.labelInterface);
  const categoryIdToName = projectInfo.categorizationIdToName;

  const { chartData, chartTitle, style, chartId, ...otherProps } = props;
  const { data: dataSpec, filters, groupBy } = chartData;

  const [chartType, setChartType] = useState<ChartType>(chartData.chartType as ChartTypeEnum);
  const [editMode, setEditMode] = useState<boolean>(false);

  const editMutation = useUpdateChartMutation({
    dashboardId: DEFAULT_DASHBOARD,
  });

  const changeChartType = (newChartType: ChartType) => {
    setChartType(newChartType);
    editMutation.mutate({
      chartId,
      chartData: { ...chartData, chartType: newChartType }, //newChartData,
    });
  };

  const dependencies = {
    dataSpec: chartData.data,
    groupBy,
    ...(filters && { filters }),
  };
  const query = useGetChartDataQuery(dependencies);

  const getIdToNameOrNone = (groupBy: GroupByField) => {
    const mapper: Record<IdField, Mapper> = {
      [LabelFields.labelTag]: tagIdToName,
      [ObjectFields.classIds]: classIdToName,
      [CategoryFields.categoryIds]: categoryIdToName,
    };
    return groupBy in mapper ? mapper[groupBy as IdField] : {};
  };

  const downloadData = () => {
    if (!query?.data) return;
    const transformed = TransformFunctionMap(groupBy)(
      query.data as any,
      getIdToNameOrNone(groupBy),
      dataSpec,
      projectInfo.project.labelInterface,
    );

    FileUtils.exportToExcel(
      transformDownload(transformed.data) as Record<string, any>[],
      'json',
      projectInfo.project.name,
      getDownloadFilename(projectInfo.project.name, dataSpec, groupBy as GroupByField) + '.csv',
    );
  };
  const deleteMutation = useDeleteChartMutation({
    chartId: chartId,
    dashboardId: DEFAULT_DASHBOARD,
  });
  const deleteChart = () => {
    deleteMutation.mutate();
  };

  const chartComponent = () => {
    return (
      <PreviewChartArea
        data={query.data}
        isFetching={query.isFetching}
        dataSpec={chartData.data}
        groupBy={groupBy}
        style={{ width: '100%', height: '300px' }}
        previewAreaColor={PREVIEW_CHART_COLOR.tabView}
        chartType={chartType}
      />
    );
  };

  const chartTypeOptions = [
    { label: <Icon icon={ChartBar} />, value: ChartTypeEnum.BAR },
    { label: <Icon icon={ChartDonut} />, value: ChartTypeEnum.DONUT },
    { label: <Icon icon={ChartTreemap} />, value: ChartTypeEnum.TREEMAP },
  ];

  const editName = () => setEditMode(!editMode);
  const chartActions = [
    {
      icon: Pencil,
      onClick: editName,
      label: t('analytics.customizeAnalytics.action.editName'),
      disabled: false,
      tooltip: t('analytics.customizeAnalytics.action.editName'),
    },
    {
      icon: Clear,
      color: 'primary' as const,
      onClick: deleteChart,
      label: t('analytics.customizeAnalytics.action.delete'),
      disabled: false,
      tooltip: t('analytics.customizeAnalytics.action.delete'),
    },
  ];
  const RightButtons = (
    <Box display="flex" marginLeft="auto" gap={0.5}>
      <SegmentedControl
        value={chartType}
        options={chartTypeOptions}
        buttonProps={{
          color: 'primary',
          size: ButtonSize,
          square: true,
        }}
        onChangeValue={(value: ChartType) => changeChartType(value)}
      />
      <DropdownMenu
        width="130px"
        AnchorElement={
          <IconButton icon={MoreHorizontal} color="gray" variant="stroke" size={ButtonSize} />
        }
      >
        {chartActions.map(def => renderMenuItem(def))}
      </DropdownMenu>
    </Box>
  );

  return (
    <Box
      display="flex"
      borderColor="gray-150"
      borderRadius="2px"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
      flexDirection="column"
      style={{ ...style }}
      {...otherProps}
    >
      <Box
        display="flex"
        width="100%"
        p={1}
        alignItems="center"
        position="relative"
        borderBottom="1px solid"
        borderColor="gray-150"
        justifyContent="space-between"
      >
        <EditableTitle
          editMode={editMode}
          setEditMode={setEditMode}
          defaultTitle={chartTitle}
          width={50}
        />
        <IconButton icon={Download} variant="text" color="gray" size="m" onClick={downloadData} />
        {RightButtons}
      </Box>
      <Box
        display="flex"
        p={2}
        width="100%"
        height="100%"
        position="relative"
        flexDirection="column"
        style={{
          backgroundColor: CARD_BACKGROUND_COLOR,
        }}
      >
        <ChartInfoDetail chartDetail={chartData} />
        <Box display="flex" flexDirection="row" position="relative" width="100%">
          <Box
            display="flex"
            justifyContent="flex-start"
            gap={3}
            flexDirection="column"
            mt={4}
            ml={1}
            style={{ width: '20%' }}
          >
            {chartData?.totalAnnotationCount && (
              <ReportStatisticsCard
                title={t('analytics.customizeAnalytics.chart.objects')}
                statistic={formatNumber(Number(chartData.totalAnnotationCount))}
                icon={ImageObject}
                style={{ margin: 0 }}
              />
            )}
            <ReportStatisticsCard
              title={t('analytics.customizeAnalytics.chart.labels')}
              statistic={formatNumber(Number(chartData.totalLabelCount))}
              icon={ImageSequence}
              style={{ margin: 0 }}
            />
          </Box>
          <Box display="flex" style={{ width: '80%' }}>
            {chartComponent()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
