import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box } from '@superb-ai/norwegian-forest';

import { MeteringMini } from '../../../components/pages/account/billingAndUsage/MeteringMini';
import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useWindowSize } from '../../../hooks/WindowSizeHook';
import { AppMenu, MenuItemProp, MenuTitle } from '../../../menu';
import { SerializedMeteringSelector } from '../../../queries/useMeteringQuery';
import { getUrl } from '../../../routes/util';
import MenuButtons from './MenuButtons';
import { Params } from './path';
import ProjectSelector from './ProjectSelector';

export function ProjectMenu({ menuItem }: MenuItemProp): JSX.Element {
  const history = useHistory();
  const match = useRouteMatch<Params>();
  const { project } = useProjectInfo();
  const authInfo = useAuthInfo();

  // Adjust contents of metering info according to available space
  const meteringBox = useRef<HTMLDivElement | null>(null);
  const [meteringItems, setMeteringItems] = useState<
    Exclude<SerializedMeteringSelector, 'model:endpoint'>[]
  >([]);
  const { height } = useWindowSize();
  useEffect(() => {
    if (!meteringBox.current || height == -1) return;
    const marginTop = parseInt(
      getComputedStyle(meteringBox.current).getPropertyValue('margin-top'),
      10,
    );
    const usedHeight = parseInt(
      getComputedStyle(meteringBox.current).getPropertyValue('height'),
      10,
    );
    const space = marginTop + usedHeight;

    if (space > 252) {
      setMeteringItems(['label:data-volume', 'common:user-seat', 'common:ai-credit']);
    } else if (space > 176) {
      setMeteringItems(['label:data-volume', 'common:ai-credit']);
    } else if (space > 100) {
      setMeteringItems(['label:data-volume']);
    } else {
      setMeteringItems([]);
    }
  }, [height, meteringBox]);

  function changeProject({ id }: { id: string }) {
    history.push(getUrl(match.path, { ...match.params, projectId: id }));
  }

  return (
    <>
      <MenuTitle position="sticky" top={'0'} p={0} alignItems="stretch" style={{ zIndex: 5 }}>
        <ProjectSelector
          value={project}
          onChange={changeProject}
          variant="text"
          size="l"
          width={300}
          maxHeight={315}
          positionFixed
        />
      </MenuTitle>

      <Box
        p={1}
        flex={1}
        display="flex"
        flexDirection="column"
        borderColor="#e5e5e5"
        className="app-menu"
      >
        <Box mb={1}>
          <MenuButtons />
        </Box>
        <AppMenu items={menuItem.children ?? []} nested exact />
        {isOwnerOrAdminOrSystemAdmin(authInfo) && (
          <Box mt="auto" p={2} ref={meteringBox}>
            <MeteringMini show={meteringItems} />
          </Box>
        )}
      </Box>
    </>
  );
}
