import { TFunction, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LinkTypography } from '@superb-ai/ui';

export function COMING_SOON(t: TFunction, feature: string, target?: string): string {
  return t('consts.featureMessage.comingSoon', {
    feature,
    target,
  });
}

export function NOT_ENABLED_FOR_PROJECT(t: TFunction, feature: string): string {
  return t('consts.featureMessage.notEnabledForProject', {
    feature,
  });
}

export function NOT_ENABLED_EXPORT_COMPARE(t: TFunction, feature: string): string {
  return feature === 'Video projects'
    ? t('consts.featureMessage.notEnabledExportCompare.notEnabledExportCompareForVideo', {
        feature,
      })
    : t('consts.featureMessage.notEnabledExportCompare.notEnabledExportCompare', { feature });
}

// TODO: need to check [tsnoh -> mjlimb]
export function NOT_ENABLED_FOR_PROJECT_ADMIN(
  t: TFunction,
  feature: string,
  url: string,
): JSX.Element {
  return (
    <Link to={url}>
      {t('consts.featureMessage.notEnabledForProjectAdmin.1', { feature })}
      <br />
      <Trans
        i18nKey="consts.featureMessage.notEnabledForProjectAdmin.2"
        components={{
          LinkTypography: <LinkTypography />,
        }}
      />
    </Link>
  );
}
