import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CaretDown, CaretUp } from '@superb-ai/icons';
import { Box } from '@superb-ai/norwegian-forest';
import { Icon, Typography } from '@superb-ai/ui';

import { FORMAT_COUNT } from '../config/d3Formatters';
import { ClassNameCell } from './components/ClassNameCell';
import { DataCell } from './components/DataCell';
import { AnnotationsChangedTableProps, ObjectCountsRow } from './types/types';

interface Props {
  header: AnnotationsChangedTableProps[];
  row: ObjectCountsRow;
}

const ObjectCountsTableRow = (props: Props) => {
  const { row, header } = props;
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const CountChangeCell = (row: ObjectCountsRow, id: string): JSX.Element => {
    const changedCount = row[id as keyof ObjectCountsRow] as number;
    return (
      <Box
        display="flex"
        alignItems="center"
        ml={0.5}
        gap="4px"
        style={{
          fontVariantNumeric: 'tabular-nums',
          justifyContent: 'flex-end',
          paddingRight: '12px',
          ...(row?.className === 'Total' && { fontWeight: 600 }),
        }}
      >
        {changedCount > 0 && (
          <>
            <Icon icon={CaretUp} color="red-400" size={22} />
            <Typography color="red-400" variant="m-strong" style={{ marginLeft: '-5px' }}>
              {FORMAT_COUNT(changedCount)}
            </Typography>
          </>
        )}
        {changedCount < 0 && (
          <>
            <Icon icon={CaretDown} color="blue-400" size={22} />
            <Typography color="blue-400" variant="m-strong">
              {FORMAT_COUNT(Math.abs(changedCount))}
            </Typography>
          </>
        )}
        {changedCount == 0 && (
          <Typography color="gray-300" variant="m-strong">
            {'––'}
          </Typography>
        )}
      </Box>
    );
  };

  const TotalCell = (row: ObjectCountsRow, id: string): JSX.Element => {
    return (
      <Box display="flex" alignItems="center" ml={0.5} style={{ fontWeight: 600 }}>
        {row[id as keyof ObjectCountsRow]}
      </Box>
    );
  };
  return (
    <StyledTableRow hover style={{ cursor: 'default' }}>
      {header.map(col => {
        const columnId = col.id as keyof ObjectCountsRow;
        const isClassName = columnId === 'className';
        const isCountChange = columnId === 'changes';
        return (
          <React.Fragment key={`${columnId}`}>
            <TableCell align="right" style={{ textAlign: 'right' }}>
              {isClassName && row[columnId] === 'Total' && TotalCell(row, columnId)}
              {isClassName && row[columnId] !== 'Total' && ClassNameCell(row, columnId)}
              {isCountChange && CountChangeCell(row, columnId)}
              {!isCountChange && Number.isInteger(row[columnId]) && DataCell(row, columnId)}
            </TableCell>
          </React.Fragment>
        );
      })}
    </StyledTableRow>
  );
};

export default ObjectCountsTableRow;
