import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';

import { calculatePercent } from '../../../../../apps/Curate/utils/numberUtils';
import { SVG_PROPERTY_DONUT } from '../../config/svgConfig';
import { JsonObj } from '../../userStats/types';
import DonutChart from './DonutChart';
import DonutLegend from './DonutLegend';

type ChartDatum = {
  key: string;
  name: string;
  count: number;
  percentTotal: number;
  groups?: string;
};

const GenericDonutChartWithLegend = (props: {
  data: ChartDatum[];
  idKey: string;
  countKey: string;
  chartName: string;
  xKeyToDisplayName?: Record<string, string>;
  getDatumColor: (datum: JsonObj) => string;
  containerWidth: number;
  containerHeight: number;
  legendTitle: string;
}): React.ReactElement => {
  const {
    data,
    idKey,
    countKey,
    chartName,
    xKeyToDisplayName,
    containerHeight,
    containerWidth,
    getDatumColor,
    legendTitle,
  } = props;
  const { t } = useTranslation();
  const yValues = data.map(data => data[countKey]);
  const totalCounts = data.reduce((acc, d) => acc + d[countKey], 0);
  const transformedData = map(data, d => ({
    ...d,
    key: d[idKey],
    count: d[countKey],
    percentTotal: calculatePercent({ numerator: d[countKey], denominator: totalCounts }),
    name: d.name,
  }));

  const svgInfo = SVG_PROPERTY_DONUT({
    width: containerWidth,
    height: containerHeight - 70,
    marginRight: 20,
  });
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const highlightSlice = (i: number, status?: 'in' | 'out') => {
    status === 'in' ? setHoveredIndex(i) : setHoveredIndex(-1);
  };

  const handleOnClick = (i: number, clicked: JsonObj, groupIds?: string[]) => {
    // TODO: implement later
    // console.log('clicked chart');
  };
  const getDonutCenterText = (params: {
    hoveredIndex: number;
    count: number;
    total: number;
    datum?: JsonObj;
  }) => {
    const { hoveredIndex, count, total, datum } = params;
    const name = datum?.[idKey ?? 'name'];
    const centerText = {
      text:
        hoveredIndex === -1
          ? t('curate.analytics.text.total')
          : name?.length > 17
          ? name.slice(0, 17) + '...'
          : name,
      count: hoveredIndex === -1 ? total : count,
    };
    return [centerText];
  };

  const getLegendObject = () => {
    /**
     * categories: {count}
     * classes: {count}
     */
    return {
      title: legendTitle,
      hasMarker: true,
    };
  };

  return (
    <>
      <Box
        id={`${chartName}-container`}
        display="flex"
        style={{
          position: 'relative',
          justifyContent: 'center',
          paddingBottom: '8px',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <DonutChart
            x={svgInfo.svgWidth / 2} // outer svg
            id={`${chartName}-donut-chart`}
            yValues={yValues}
            data={transformedData as ChartDatum[]}
            totalCounts={totalCounts}
            getCenterText={getDonutCenterText}
            getColor={getDatumColor}
            highlightSlice={highlightSlice}
            handleOnClick={undefined}
            hoveredIndex={hoveredIndex}
            chartName={chartName}
            xKeyToDisplayName={xKeyToDisplayName}
            svgInfo={svgInfo}
          />
          <Box style={{ marginTop: '20px' }}>
            <DonutLegend
              id={`${chartName}-donut-legend`}
              data={transformedData}
              idKey="key"
              countKey="count"
              shareKey="percentTotal"
              getColor={getDatumColor}
              highlightSlice={highlightSlice}
              handleOnClick={undefined}
              chartName={chartName}
              xKeyToDisplayName={xKeyToDisplayName}
              chartObj={getLegendObject()}
              selectedIndex={hoveredIndex}
              fixedTopMargin
              showButton={false}
            />
          </Box>
        </Box>
      </Box>
      <div id="outer" style={{ display: 'none' }} />
    </>
  );
};

export default GenericDonutChartWithLegend;
