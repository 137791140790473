import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Typography } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { MislabelDetectionResultsPerClass } from '../../../../types/mislabelDetectionTypes';

type Props = {
  item: MislabelDetectionResultsPerClass;
};
export default function ClassInfo({ item }: React.PropsWithChildren<Props>) {
  const advancedAiTestingFeatures = useFeatureFlag('advancedAiTestingFeatures');
  const { t } = useTranslation();

  return (
    <>
      {item.class_instance_num ? (
        <Typography variant="headline6">
          {startCase(item.class_name)} (
          {t('advancedAIFeatures.mislabelDetection.result.detectedPerTotalCount', {
            detectedCount: item.results.length,
            totalCount: item.class_instance_num,
          })}
          )
        </Typography>
      ) : (
        <Typography variant="headline6">
          {startCase(item.class_name)} (
          {t('advancedAIFeatures.mislabelDetection.result.detectedCount', {
            detectedCount: item.results.length,
          })}
          )
        </Typography>
      )}
      {advancedAiTestingFeatures && item.class_mislabel_score_all_avg && (
        <Chip color="violet">
          Mislabel average score: {Math.round(item.class_mislabel_score_all_avg * 1000) / 1000}
        </Chip>
      )}
      {advancedAiTestingFeatures && item.class_mislabel_score_10_avg && (
        <Chip color="cobalt">
          Mislabel top 10 average score:{' '}
          {Math.round(item.class_mislabel_score_10_avg * 1000) / 1000}
        </Chip>
      )}
    </>
  );
}
