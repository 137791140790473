import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { Command, CommandStatus, CommandType, CurateJobParams } from '../types/commandTypes';

const getJobPresignedUrl: AfterLoginCallback<
  { uploadUrl: string; id: string },
  { file_size: number }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/curate/batch/params/',
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const postJob: AfterLoginCallback<
  Command<CommandType>,
  {
    job_type: CommandType;
    param_id?: string;
    lock?: { dataset_id: string };
    param?: CurateJobParams<CommandType>;
  }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/curate/batch/jobs/',
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
  });
  return data;
};

const cancelJob: AfterLoginCallback<Command<CommandType>, { jobId: string }> = async params => {
  const { data: paramData, ...otherParams } = params;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/curate/batch/jobs/${paramData.jobId}/cancel/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...otherParams,
  });

  return data;
};

const retryJob: AfterLoginCallback<Command<CommandType>, { jobId: string }> = async params => {
  const { data: paramData, ...otherParams } = params;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/curate/batch/jobs/${paramData.jobId}/retry/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...otherParams,
  });

  return data;
};

const getJobs: AfterLoginCallback<
  { count: number; nextCursor: string; results: Command<CommandType>[] },
  {
    params: {
      created_by?: string;
      status_in: CommandStatus[];
      // from_date: string;
      cursor: string | null;
    };
  }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/?${qs.stringify({ ...params.data.params } || {}, {
      arrayFormat: 'brackets',
    })}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const getJob: AfterLoginCallback<Command<CommandType>, { jobId: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/${params.data.jobId}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export function useCurateCommandsService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getJobPresignedUrl: afterLoginFetcher(getJobPresignedUrl),
    postJob: afterLoginFetcher(postJob),
    cancelJob: afterLoginFetcher(cancelJob),
    retryJob: afterLoginFetcher(retryJob),
    getJobs: afterLoginFetcher(getJobs),
    getJob: afterLoginFetcher(getJob),
  };
}
