import React, { ReactElement } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
  },
}));

const CategoryListDropZone = (): ReactElement => {
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();

  const handleMouseDown = () => {
    newProjectInfo.setSelectedCategories({});
    newProjectInfo.setSelectedCategoryGroupId('');
  };

  return <MUI.Box className={classes.box} onMouseDown={handleMouseDown} />;
};

export default CategoryListDropZone;
