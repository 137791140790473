import { clone, extend, has, isEmpty } from 'lodash';

import { Property } from '../components/elements/projectConfiguration/utils';
import WorkappUnion from '../union/WorkappUnion';

// TODO: check casing: cuboid2D vs cuboid2d
export type LegacyAnnotationType = 'tiltedbox'; // image-default, remove when deprecated
export type NewAnnotationType =
  | 'box'
  | 'cuboid'
  | 'cuboid2d'
  | 'cuboid2D'
  | 'cuboid2d'
  | 'keypoint'
  | 'keypoints' // video
  | 'polygon'
  | 'polygons' // video-siesta, remove when deprecated
  | 'polyline'
  | 'rbox';
export type AnnotationType = NewAnnotationType | LegacyAnnotationType;

export type AnnotationTypeUpper = Uppercase<AnnotationType>;

export type PropertyOption = {
  id: string;
  name: string;
  children?: PropertyOption[];
  parents?: PropertyOption[];
};

export type CategoryType = 'radio' | 'checkbox' | 'free response';

export type Category = {
  id: string;
  name: string;
  defaultValue: string | string[]; // list for checkbox
  description: string;
  required: boolean;
  renderValue: boolean;
  perFrame?: boolean; // video only
  type: string;
  options: PropertyOption[];
  aiProperty?: {
    simple: true;
    engineId?: string;
    propertyId?: string;
  };
};

export type CategoryPropertyList = (Category & { id: string; options: PropertyOption[] })[];

export type WorkappType = 'image-siesta' | 'video-siesta' | 'pointclouds-siesta';
export type DataType = 'image' | 'video' | 'pointclouds'; // project data type

export interface ObjectClass {
  aiClassMap: any[];
  annotationType: string;
  color: string;
  constraints: any;
  id: string;
  name: string;
  properties: Property[];
  children?: ObjectClass[]; // only for pretained AI
  keypointInterfaceId?: string; // only for keypoint
}

interface ObjDetection {
  annotationTypes: (AnnotationType | 'image category')[];
  keypoints: Record<string, any>[];
  objectClasses: ObjectClass[];
  objectGroups: any[];
}

type ObjTracking = ObjDetection;

export interface ImageLabelInterface {
  categorization: { properties: CategoryPropertyList };
  dataType: 'image' | 'video';
  objectDetection: ObjDetection;
  type: 'image-siesta';
  version: string;
}

export interface VideoLabelInterface {
  categorization: { properties: CategoryPropertyList };
  dataType: 'video' | 'image sequence';
  objectTracking: ObjTracking;
  type: 'video-siesta';
  version: string;
  aiAdvancedSettings?: {
    tracking: boolean;
    frequencyFilter: boolean;
  };
}

export type LabelInterface = {
  categorization: { properties: CategoryPropertyList };
  dataType: 'image' | 'video' | 'image sequence';
  version: string;
} & (
  | { objectDetection: ObjDetection; type: 'image-siesta' }
  | { objectTracking: ObjDetection; type: 'video-siesta' }
);

export type LegacyCategory = {
  id: string;
  name: string;
  parent: string;
  is_group: boolean;
  children: any[];
};

export interface LegacyLabelInterface {
  objects: any;
  categorization: { wordMap: LegacyCategory[] };
  groups: any[];
}

export type Mapper<T> = Record<string, T>;

type CategoryNode = {
  id: string;
  name: string;
};
interface CategoryOption extends CategoryNode {
  children?: CategoryOption[];
}

type FlatCategoryOption = {
  id: string;
  name: string;
  parents: Mapper<string>;
};

const hasObject = (labelInterface: LabelInterface): labelInterface is ImageLabelInterface => {
  // @ts-ignore: we're performing the type check manually, so this is ok
  return !isEmpty(labelInterface?.objectDetection?.objectClasses);
};

const videoHasObject = (labelInterface: LabelInterface): labelInterface is VideoLabelInterface => {
  // @ts-ignore: we're performing the type check manually, so this is ok
  return !isEmpty(labelInterface?.objectTracking?.objectClasses);
};

const pointCloudHasObject = (
  labelInterface: LabelInterface,
): labelInterface is VideoLabelInterface => {
  // @ts-ignore: we're performing the type check manually, so this is ok
  return !isEmpty(labelInterface?.objectTracking?.objectClasses);
};

const hasCategory = (labelInterface: LabelInterface): boolean => {
  return !isEmpty(labelInterface?.categorization?.properties);
};

const hasCategoryLegacy = (labelInterface: LegacyLabelInterface): boolean => {
  return !isEmpty(labelInterface?.categorization?.wordMap);
};

const hasObjectLegacy = (labelInterface: LegacyLabelInterface): boolean => {
  return !isEmpty(labelInterface?.objects);
};

const getImageCategories = (labelInterface: LabelInterface) => {
  if (hasCategory(labelInterface)) {
    return labelInterface?.categorization?.properties;
  }
  return [];
};

// Different from ProjectUtils.getAnnotationTypes which gets annotation type display names
const getAnnotationTypes = (labelInterface: LabelInterface | undefined): any[] => {
  if (!labelInterface) {
    return [];
  }
  if (videoHasObject(labelInterface) || pointCloudHasObject(labelInterface)) {
    return labelInterface.objectTracking.annotationTypes.filter(type => type !== 'image category');
  }
  if (hasObject(labelInterface)) {
    return labelInterface.objectDetection.annotationTypes.filter(type => type !== 'image category');
  }
  // @ts-ignore: legacy workapp
  // if (labelInterface.objects) {
  // }
  return [];
};

const LabelInterfaceObjectClassesMap = new WeakMap();
const getObjectClasses = (labelInterface: LabelInterface | undefined): ObjectClass[] => {
  const getAnnotations = () => {
    if (!labelInterface) {
      return [];
    }
    if (videoHasObject(labelInterface)) {
      return labelInterface.objectTracking?.objectClasses ?? [];
    }
    if (hasObject(labelInterface)) {
      return labelInterface.objectDetection.objectClasses;
    }
    // @ts-ignore: legacy workapp
    if (labelInterface.objects) {
      // @ts-ignore: legacy workapp
      return labelInterface.objects.map(obj => ({
        ...obj,
        ...obj.info,
        id: obj.name,
        annotationType: Object.keys(obj.info.shapes)[0],
      }));
    }
    return [];
  };

  if (labelInterface) {
    LabelInterfaceObjectClassesMap.set(labelInterface, getAnnotations());
  }

  return labelInterface ? LabelInterfaceObjectClassesMap.get(labelInterface) : [];
};

// TODO (mlimb) -> se getLeafNodesByProperty if possible
const getCategoryOptionConfig = (options: CategoryOption[]): FlatCategoryOption[] => {
  // map category id to its complete info, including parents if applicable
  const result = [] as FlatCategoryOption[];

  /**
   * parentsNames is used to display category groups ex. good weather / day (group / subgroup)
   * in tooltip, and possibly in x-axis later
   * parentsId is used for label list filtering
   */
  const traverseNode = (options: CategoryOption[], parents: Mapper<string>) => {
    for (const node of options) {
      const currentParents = clone(parents);
      if (has(node, 'children')) {
        currentParents[node?.id || ''] = node?.name || '';
        extend(result, traverseNode(node.children as CategoryOption[], currentParents));
      } else {
        currentParents[node?.id || ''] = node?.name || '';
        result.push({
          id: node.id,
          name: node.name,
          parents: currentParents,
        });
      }
    }
    return result;
  };

  return traverseNode(options, {} as Mapper<string>);
};

const getCategoryPropertyInfo = (labelInterface: LabelInterface): Record<string, Category> => {
  const properties = labelInterface?.categorization?.properties;

  return properties.reduce((agg, property) => {
    agg[property?.id] = {
      defaultValue: property?.defaultValue,
      description: property?.description,
      required: property?.required,
      name: property?.name,
      renderValue: property?.renderValue,
      type: property?.type,
      options: property?.options ? getCategoryOptionConfig(property?.options) : [],
    };
    return agg;
  }, {} as Record<PropertyKey, any>);
};

export interface CategoryLeafNode {
  id: string;
  name: string;
  propertyId: string;
  propertyName: string;
  count?: number;
}
/**
 *
 * @param category config from labelInterface
 * @returns array of arrays. Outer array is category properties, inner array is leaf
 *   nodes for that property
 */
const getLeafNodesByProperty = (properties: CategoryPropertyList): CategoryLeafNode[][] => {
  const output = [] as any[];
  for (const property of properties) {
    const propertyId = property.id;
    const nodes = getLeafNodes(property.options);
    const categoryProps = [] as CategoryLeafNode[];
    for (const node of nodes) {
      categoryProps.push({
        id: node.id,
        name: node.name,
        propertyId: propertyId,
        propertyName: property.name,
        count: 0,
      });
    }
    output.push(categoryProps);
  }
  return output;
};

type Node<T> = {
  children?: T[];
};
function getLeafNodes<T extends Node<T>>(nodes: T[], result: T[] = []): T[] {
  for (const node of nodes) {
    if (node.children?.length) {
      result = getLeafNodes(node.children, result);
    } else {
      result.push(node);
    }
  }
  return result;
}

export function* treeIterator<T extends Node<T>>(node: T): Generator<T> {
  yield node;
  if (node.children) {
    for (const child of node.children) {
      yield* treeIterator(child);
    }
  }
}

// only supports siesta workapps
function hasObjectProperties(labelInterface: LabelInterface): boolean {
  const objects = getObjectClasses(labelInterface);
  return objects.some(object => !isEmpty(object?.properties));
}

/**
 * Get classes and categories that are configured for Auto Label
 */
export function getAutoLabelConfig(labelInterface: LabelInterface) {
  const categories: Category[] = getImageCategories(labelInterface);
  const objectClasses: ObjectClass[] = getObjectClasses(labelInterface);
  const classesWithAi = objectClasses.filter(cls => cls.aiClassMap?.[0]?.engineId);
  const categoriesWithAi = categories.filter(cat => cat.aiProperty?.engineId);
  return { objectClasses: classesWithAi, categories: categoriesWithAi };
}

/**
 * Check if label interface has any classes or categories mapped to Auto Label
 */
export function isAutoLabelConfigured(labelInterface: LabelInterface): boolean {
  const { objectClasses, categories } = getAutoLabelConfig(labelInterface);
  return objectClasses.length > 0 || categories.length > 0;
}

export function getCategoryHash(labelInterface: LabelInterface): CategoryPropertyList {
  // only siesta workapp
  return labelInterface?.categorization?.properties || [];
}

export type ProjectConfig = {
  workapp: WorkappType;
  hasObjects: boolean;
  hasCategories: boolean;
  hasFrames: boolean;
  // hasObjectProperty: boolean;
};

export function getProjectConfig(
  workapp: WorkappType,
  labelInterface: LabelInterface,
): ProjectConfig {
  const objectSettings = getObjectClasses(labelInterface) as ObjectClass[];
  const hasObjects = !isEmpty(objectSettings);
  const hasCategories = hasCategory(labelInterface);
  return {
    workapp,
    hasFrames: WorkappUnion.isVideoApp(workapp),
    hasObjects,
    hasCategories,
  };
}
export default {
  getCategoryPropertyInfo,
  getLeafNodesByProperty,
  hasCategory,
  hasCategoryLegacy,
  hasObject,
  videoHasObject,
  hasObjectLegacy,
  isAutoLabelConfigured,
  getObjectClasses,
  getAnnotationTypes,
  getLeafNodes,
  hasObjectProperties,
  getCategoryHash,
  getProjectConfig,
};
