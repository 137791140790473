import React from 'react';

import { Button } from '@superb-ai/norwegian-forest';

interface Props {
  selectOption: 'Daily' | 'Total' | 'Today' | 'All';
  selected: boolean;
  changeIsCumulative: (dailyOrCumulative: boolean) => void;
  isDisabled: boolean;
  size: 's' | 'xxs' | 'xs' | 'm' | 'l' | 'xl' | undefined;
}

const SingleItemButton: React.FC<Props> = props => {
  const { selectOption, selected, changeIsCumulative, isDisabled, size } = props;
  const handleButtonClick = () => {
    if (!selected) {
      changeIsCumulative(!(selectOption === 'Daily'));
    }
  };

  return (
    <Button
      variant="stroke"
      color={selected ? 'primary' : 'lightgrey'}
      iconPosition="left"
      size={size}
      disabled={isDisabled}
      onClick={handleButtonClick}
    >
      {selectOption}
    </Button>
  );
};

export default SingleItemButton;
