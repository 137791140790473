import { ReactElement } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  AnnotationDomainType,
  LineAnnotation,
  LineAnnotationDatum,
  LineAnnotationStyle,
} from '@elastic/charts';
import { CheckFilled, InfoCircleOutline, WarningFilled } from '@superb-ai/icons';
import { Box, Chip, Icon, Tooltip, Typography } from '@superb-ai/ui';
import { lowerCase, snakeCase } from 'lodash';

import analyticsTracker from '../../../../../../../../../analyticsTracker';
import FileUtils from '../../../../../../../../../utils/FileUtils';
import { useDiagnosisAnalyticsFilterContext } from '../../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { useDiagnosisCommonFilterContext } from '../../../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisModelContext } from '../../../../../../../contexts/DiagnosisModelContext';
import { usePerformanceCurveQuery } from '../../../../../../../queries/diagnosisAnalyticsQueries';
import { ChartCard } from '../ChartCard';
import { lineChartTheme } from '../charts/customTheme';
import SingleSourceMultiLineChart, {
  CustomLineSeriesSpec,
} from '../charts/SingleSourceMultiLineChart';
import { getPerformanceCurveSeriesColor } from '../colorScale';
import { LOW_PRECISION_THRESHOLD, LOW_RECALL_THRESHOLD } from '../const';
import { roundAndSortDownload } from '../download';
import { getDisplayClassName, transformPerformanceCurvePoints } from '../transformer';
import { findMetricAtConfidence, isLowerThanThreshold } from '../utils';
import { CardProps } from './ConfusionMatrixCard';

export function getPrecisionRecallF1ChartConfig(t: TFunction) {
  const colorMap = getPerformanceCurveSeriesColor;
  return {
    showLegend: true,
    axes: {
      xAxisTitle: t('curate.diagnosis.metric.modelConfidence'),
      yAxisTitle: t('curate.diagnosis.metric.metricValue'),
    },
    series: [
      {
        id: 'Precision',
        yAccessors: ['Precision'],
        xAccessor: 'Confidence',
        color: colorMap('Precision'),
      },
      {
        id: 'Recall',
        yAccessors: ['Recall'],
        xAccessor: 'Confidence',
        color: colorMap('Recall'),
      },
      {
        id: 'F1 Score',
        yAccessors: ['F1 Score'],
        xAccessor: 'Confidence',
        color: colorMap('F1 Score'),
      },
    ] as CustomLineSeriesSpec[],
  };
}

export function PerformanceCurveTooltipHeader(props: { datum: Record<string, any> }): ReactElement {
  const { t } = useTranslation();
  const confidenceScore = props.datum?.Confidence;
  return (
    <Box display="flex" style={{ width: '140px', marginRight: -3 }}>
      <Box>{t('curate.diagnosis.metric.confidence')}</Box>
      <Box display="flex" ml="auto" pr={0}>
        {confidenceScore ? confidenceScore.toFixed(2) : 'n/A'}
      </Box>
    </Box>
  );
}

const PerformanceCurveCard = ({ diagnosis }: CardProps) => {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { targetIou } = useDiagnosisModelContext();
  const { selectedClass } = useDiagnosisAnalyticsFilterContext();
  const { sliceId } = useDiagnosisCommonFilterContext();

  const dependencies = {
    datasetId,
    diagnosisId: diagnosis.id,
    sliceId,
  };

  const dataQuery = usePerformanceCurveQuery({
    ...dependencies,
    ...(selectedClass !== 'all' && { predictionClass: selectedClass }),
  });

  const curveData = dataQuery?.data?.data ?? [];

  const handleDownload = () => {
    if (!curveData) return;
    const columnNames = {
      confidenceThreshold: snakeCase(t('curate.diagnosis.metric.confidenceThreshold')),
      precision: lowerCase(t('curate.diagnosis.metric.precision')),
      recall: lowerCase(t('curate.diagnosis.metric.recall')),
      f1Score: snakeCase(t('curate.diagnosis.metric.f1')),
    };
    const timestamp = new Date().toISOString();
    const downloadData = roundAndSortDownload({
      data: curveData,
      sortBy: columnNames.confidenceThreshold,
    });
    const fileName = `${diagnosis?.modelName}_${
      selectedClass === 'all' ? 'all_classes' : selectedClass
    }_${timestamp}_precision_recall_f1_curve`;
    FileUtils.exportToCsv(downloadData, Object.values(columnNames), fileName);
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: 'precision-recall-f1-curve',
      feature: 'model-diagnosis',
    });
  };

  const optimalConfidenceThreshold = Number(dataQuery?.data?.optimalThreshold);
  const precisionAtOptimalConfidence = findMetricAtConfidence(
    curveData,
    'precision',
    optimalConfidenceThreshold,
  );
  const recallAtOptimalConfidence = findMetricAtConfidence(
    curveData,
    'recall',
    optimalConfidenceThreshold,
  );
  function getOptimalThresholdDetails(): string {
    const precision = t('curate.diagnosis.metric.precision');
    const recall = t('curate.diagnosis.metric.recall');
    const HIGH_VALUE_ICON = '✔';
    const LOW_VALUE_ICON = '⚠️';
    const precisionIcon =
      (isLowerThanThreshold(precisionAtOptimalConfidence, LOW_PRECISION_THRESHOLD) &&
        LOW_VALUE_ICON) ||
      HIGH_VALUE_ICON;
    const recallIcon =
      (isLowerThanThreshold(recallAtOptimalConfidence, LOW_RECALL_THRESHOLD) && LOW_VALUE_ICON) ||
      HIGH_VALUE_ICON;

    return (
      `${precision}: ${precisionIcon || ''} ${precisionAtOptimalConfidence?.toFixed(2)} ` +
      `| ${recall}: ${recallIcon || ''} ${recallAtOptimalConfidence?.toFixed(2)}`
    );
  }

  function hasLowPrecisionOrRecall() {
    return (
      isLowerThanThreshold(precisionAtOptimalConfidence, LOW_PRECISION_THRESHOLD) ||
      isLowerThanThreshold(recallAtOptimalConfidence, LOW_RECALL_THRESHOLD)
    );
  }

  return (
    <ChartCard
      handleDownload={handleDownload}
      style={{ height: '360px', paddingRight: '5px' }}
      isLoading={dataQuery?.isLoading}
      chartTitle={t('curate.diagnosis.chart.precisionRecallF1.title')}
      headerLeftArea={
        <Box display="flex" gap={1} alignItems="center">
          <Tooltip
            content={
              <Trans
                i18nKey="curate.diagnosis.chart.precisionRecallF1.info"
                components={{ bold: <Typography color="gray-300" /> }}
                values={{ targetIou }}
              />
            }
          >
            <Icon icon={InfoCircleOutline} />
          </Tooltip>
          <Chip color="primary">{getDisplayClassName(selectedClass, t)}</Chip>
        </Box>
      }
      chartComponent={
        <SingleSourceMultiLineChart
          data={transformPerformanceCurvePoints(curveData)}
          getColor={getPerformanceCurveSeriesColor}
          theme={lineChartTheme()}
          config={getPrecisionRecallF1ChartConfig(t)}
          TooltipHeaderComponent={PerformanceCurveTooltipHeader}
          vLineAnnotation={OptimalThresholdVLine(
            hasLowPrecisionOrRecall,
            optimalConfidenceThreshold,
            getOptimalThresholdDetails,
            t,
          )}
          // rectAnnotation={
          //   <RectAnnotationAboveXThreshold
          //     id={'confidence-threshold-area'}
          //     threshold={mockPerformanceCurve.models[0].optimalConfidenceThreshold}
          //     color={'lightgreen'}
          //     details={t('curate.diagnosis.chart.aboveConfidenceScoreOf', {
          //       score: CONFIDENCE_THRESHOLD,
          //     })}
          //   />
          // }
        />
      }
    />
  );
};

export default PerformanceCurveCard;

function OptimalThresholdVLine(
  hasLowPrecisionOrRecall: () => boolean,
  optimalConfidenceThreshold: number,
  getOptimalThresholdDetails: () => string,
  t: TFunction<'translation', undefined>,
) {
  const YELLOW_WARNING_COLOR = '#FFD704';
  const SUCCESS_COLOR = '#82DB24';
  return (
    <LineAnnotation
      id={'optimal-confidence-line'}
      domainType={AnnotationDomainType.XDomain}
      marker={
        <Box style={{ marginTop: '2px' }}>
          {hasLowPrecisionOrRecall() ? (
            <WarningFilled color={YELLOW_WARNING_COLOR} />
          ) : (
            <CheckFilled color={SUCCESS_COLOR} />
          )}
        </Box>
      }
      dataValues={[
        {
          dataValue: optimalConfidenceThreshold,
          details: getOptimalThresholdDetails(),
          header: `${t(
            'curate.diagnosis.chart.precisionRecallF1.optimalConfidenceThreshold',
          )}: ${optimalConfidenceThreshold?.toFixed(2)}`,
        } as LineAnnotationDatum,
      ]}
      style={
        {
          line: {
            strokeWidth: 1.5,
            stroke: hasLowPrecisionOrRecall() ? YELLOW_WARNING_COLOR : SUCCESS_COLOR,
            opacity: 0.8,
            dash: [3, 2], // dashwidth, dashgap width
          },
        } as Partial<LineAnnotationStyle>
      }
    />
  );
}
