import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDialogState } from '@superb-ai/ui';

import { useAppContext } from '../../contexts/AppContext';
import TrialHubspotFormDialog from './trialHubspotFormDialog/TrialHubspotFormDialog';

interface Props {
  id: string;
  state: ReturnType<typeof useDialogState>;
  showSuccessModal: () => void;
}

const HubspotFormInfo: Record<string, { formId: string }> = {
  'South Korea': {
    formId: '61fa5684-4711-4fb7-af48-57d727714288',
  },
  'United States': {
    formId: 'b4cb95a8-0226-4392-b036-0f140e4d4b4a',
  },
  Japan: {
    formId: 'f38d18d8-c952-4a41-a861-4ae5de26a93e',
  },
};

export default function CurateDiagnosisHubspotFormDialog({ state, showSuccessModal, id }: Props) {
  const { t } = useTranslation();
  const { tenantCountry } = useAppContext();

  const { formId } = useMemo(
    () => (tenantCountry && HubspotFormInfo[tenantCountry]) || HubspotFormInfo['United States'],
    [tenantCountry],
  );

  return (
    <TrialHubspotFormDialog
      clickId={id}
      state={state}
      showSuccessModal={showSuccessModal}
      title={<Trans t={t} i18nKey="hubspotForm.curateDiagnosis.title" />}
      description={<Trans t={t} i18nKey="hubspotForm.curateDiagnosis.description" />}
      hubspotFormId={formId}
    />
  );
}
