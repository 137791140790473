import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Copy } from '@superb-ai/icons';
import { Box, IconButton, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../../../../../consts/SnackbarMessage';
import { formatDateTimeLong } from '../../../../../../../../../utils/date';
import FileUtils from '../../../../../../../../../utils/FileUtils';
import { usePublicDatasetContext } from '../../../../../../../contexts/PublicDatasetContextProvider';
import { CurateImageData } from '../../../../../../../services/DatasetService';
import { EvaluationValue } from '../../../../../../../services/diagnosisTypes';
import DetailModalCabinetCollapse from '../../../../views/grid/image/DetailModalCabinetCollapse';
import SelectionClasses from './SelectionClasses';
type Props = {
  imageInfo: CurateImageData;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  // clickedAnnotation: CurateAnnotation | null;
  handleSelectImage: () => void;
  evaluations: { confidence: number; iou: number };
} & Pick<EvaluationValue, 'annotation' | 'prediction'>;

export default function DetailModalCabinet({
  imageInfo,
  annotation,
  prediction,
  evaluations,
}: Props) {
  const { t } = useTranslation();
  const [copyButtonVisible, setCopyButtonVisible] = useState<
    'image_key' | 'image_id' | 'image_url'
  >();
  const { enqueueSnackbar } = useSnackbar();
  const { showPublicDatasets } = usePublicDatasetContext();

  if (!imageInfo) return <></>;

  const metadataEntries = Object.entries(imageInfo.metadata);

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const cabinetWidth = 288;

  const handleClickCopy = () => {
    if (!copyButtonVisible) return;
    FileUtils.copyToClipboard({
      value: {
        image_key: imageInfo.key,
        image_id: imageInfo.id,
        image_url: imageInfo.image_url,
      }[copyButtonVisible],
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label: startCase(copyButtonVisible) }), {
      variant: 'success',
    });
  };

  return (
    <Box pl={2} overflow="auto" height="100%">
      <DetailModalCabinetCollapse title={t('curate.datasets.detailView.selectionClasses')}>
        <SelectionClasses
          annotation={annotation}
          prediction={prediction}
          evaluations={evaluations}
        />
      </DetailModalCabinetCollapse>
      <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageProperties')}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_key')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('curate.datasets.detailView.imageKey')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular">{imageInfo.key}</Typography>
              </p>
              {copyButtonVisible === 'image_key' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
          <Box
            pt={0.5}
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_id')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('curate.datasets.detailView.imageId')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular">{imageInfo.id}</Typography>
              </p>
              {copyButtonVisible === 'image_id' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
          <Box
            pt={0.5}
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_url')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('curate.datasets.detailView.imageUrl')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular" style={{}}>
                  {imageInfo.image_url}
                </Typography>
              </p>
              {copyButtonVisible === 'image_url' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
        </Box>
      </DetailModalCabinetCollapse>
      <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageMetadata')}>
        <Box display="flex" flexDirection="column" gap={1}>
          {metadataEntries.map((pair, index) => (
            <Box
              pb={index < metadataEntries.length ? 0.5 : 0}
              display="flex"
              alignItems="center"
              key={pair[0]}
            >
              <Box style={{ ...textEllipsisStyle, width: cabinetWidth / 2 }}>
                <Typography variant="m-strong">{pair[0]}</Typography>
              </Box>
              <Box style={{ ...textEllipsisStyle, width: cabinetWidth / 2 }}>
                <Typography variant="m-regular">
                  {pair[0] === 'fileSize' ? (pair[1] as number).toLocaleString('en') : pair[1]}
                  {(pair[0] === 'width' || pair[0] === 'height') && 'px'}
                  {pair[0] === 'fileSize' && ' byte'}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DetailModalCabinetCollapse>
      {showPublicDatasets && (
        <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageInformation')}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box
              position="relative"
              style={{ width: cabinetWidth }}
              display="flex"
              flexDirection="column"
              gap={0.5}
            >
              <Typography variant="m-strong">{t('curate.datasets.detailView.uploader')}</Typography>
              <Box>
                <p style={{ ...textEllipsisStyle, width: 240 }}>
                  <Typography variant="m-regular">{imageInfo.created_by}</Typography>
                </p>
              </Box>
            </Box>
            <Box
              position="relative"
              style={{ width: cabinetWidth }}
              display="flex"
              flexDirection="column"
              gap={0.5}
            >
              <Typography variant="m-strong">
                {t('curate.datasets.detailView.uploadedAt')}
              </Typography>
              <Box>
                <p style={{ ...textEllipsisStyle, width: 240 }}>
                  <Typography variant="m-regular">
                    {formatDateTimeLong(imageInfo.created_at)}
                  </Typography>
                </p>
              </Box>
            </Box>
          </Box>
        </DetailModalCabinetCollapse>
      )}
    </Box>
  );
}
