import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box } from '@superb-ai/norwegian-forest';
import debounce from 'debounce-promise';

import { useGetProjectList } from '../../../../hooks/GetProjectList';
import { ProjectData } from '../../../../types/projectTypes';
import ProjectsAsyncTable from '../../../elements/manageMembers/ProjectsAsyncTable';
import ProjectSearchBar from '../../../elements/manageMembers/ProjectSearchBar';

type Props = {
  selectedRole: string;
  setSelectedRole: Dispatch<SetStateAction<string>>;
  selectedProjects: ProjectData[];
  unSelectableProjects?: ProjectData[];
  setSelectedProjects: Dispatch<SetStateAction<ProjectData[]>>;
  isTotalProjectsSelected: boolean;
  setIsTotalProjectsSelected: Dispatch<SetStateAction<boolean>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  tab?: string;
  open: boolean;
};

const ProjectAsyncTableContainer: React.FC<Props> = ({
  tab,
  open,
  selectedRole,
  setSelectedRole,
  selectedProjects,
  setSelectedProjects,
  unSelectableProjects,
  isTotalProjectsSelected,
  setIsTotalProjectsSelected,
  totalCount,
  setTotalCount,
}) => {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const getProjectList = useGetProjectList();

  const loadProjects = async ({ value }: { value: string }) => {
    setIsLoading(true);
    const projectRes = await getProjectList({ nameIcontains: value });
    setProjects(projectRes.results);
    setTotalCount(projectRes.count);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!open) return;
    (async () => {
      await loadProjects({ value: '' });
    })();
    // eslint-disable-next-line
  }, [open, tab]);

  const debounceLoadProjects = debounce(async (value: string) => {
    await loadProjects({ value });
  }, 500);

  const handleChangeSearchKey = async (value: string) => {
    await debounceLoadProjects(value);
    setSearchKey(value);
  };

  if (!open) return <></>;
  return (
    <>
      <Box mt={3}>
        <ProjectSearchBar
          hasRoleSelect
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          onChangeSearchKey={handleChangeSearchKey}
        />
      </Box>
      <Box mt={1}>
        <ProjectsAsyncTable
          selectable
          isLoading={isLoading}
          totalCount={totalCount}
          columns={['project', 'labelCount', 'createdAt']}
          searchKey={searchKey}
          projects={projects}
          unSelectableProjects={unSelectableProjects}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          isTotalProjectsSelected={isTotalProjectsSelected}
          setIsTotalProjectsSelected={setIsTotalProjectsSelected}
        />
      </Box>
    </>
  );
};

export default ProjectAsyncTableContainer;
