import { useTranslation } from 'react-i18next';

import { Box, Chip, Icon } from '@superb-ai/norwegian-forest';

import { formatDurationShort } from '../../utils/date';

export default function VideoAssetSize({
  frameCount,
  duration,
}: {
  frameCount?: number;
  duration?: number;
}) {
  const { t } = useTranslation();
  return (
    <Chip size="xxs" color="cloud">
      <Box display="flex" alignItems="center" gap="4px">
        <Icon name={!!duration ? 'playCircleOutline' : 'videoFrames'} />
        {!!frameCount && t('labels.frameCount', { count: frameCount })}
        {!!duration && `${formatDurationShort(duration)}`}
      </Box>
    </Chip>
  );
}
