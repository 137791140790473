import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import ProjectMembers from '../../../../components/pages/settings/projectMembers';
import { canManageProject } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';

export const ProjectMembersMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectMembers,
  path: 'members',
  title: ({ t }) => t('projectMembers.title'),
  component: ProjectMembers,
  icon: 'users',
  isVisible({ authInfo }) {
    return canManageProject(authInfo);
  },
  isEnabled({ authInfo }) {
    return canManageProject(authInfo);
  },
});
