import { DatasetResult } from '../../../../../../../services/DatasetService';
import { PageSet } from '../../../../../../../types/routeTypes';

export function getSliceOrDatasetName(
  currentPageSet: PageSet,
  datasetInfo?: DatasetResult,
  sliceInfo?: DatasetResult,
) {
  return currentPageSet === 'slice' ? sliceInfo?.name : datasetInfo?.name;
}
