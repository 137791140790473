import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Icon, List, ListItem } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';
import { flatten, omit, without } from 'lodash';

import { GeoJSONPolygon } from '../../../../../types/advancedAIFeaturesTypes';
import {
  MislabelDetectionAction,
  MislabelDetectionAnnotationType,
  mislabelDetectionIssueTypeValueLabelMap,
  MislabelDetectionResult,
} from '../../../../../types/mislabelDetectionTypes';
import ActionPopper from './ActionPopper';

type Props = {
  classInfo: { class_id: string; class_name: string };
  imageInfo: MislabelDetectionResult<MislabelDetectionAnnotationType> & { url?: string };
  addIssueList?: Record<string, MislabelDetectionAction>;
  setAddIssueList: Dispatch<SetStateAction<Record<string, MislabelDetectionAction>>>;
  isPopperVisible: boolean;
  setIsPopperVisible: Dispatch<SetStateAction<boolean>>;
  hover: boolean;
  setHover: Dispatch<SetStateAction<boolean>>;
};

export default function AddIssuesPopper({
  addIssueList,
  setAddIssueList,
  imageInfo,
  hover,
  setHover,
  isPopperVisible,
  setIsPopperVisible,
}: Props) {
  const { mislabel_rank: mislabelRank } = imageInfo;
  const [typeValue, setTypeValue] = useState<string[]>([]);
  const [freeText, setFreeText] = useState<string>();

  useEffect(() => {
    if (isPopperVisible) {
      const defaultValue = addIssueList && addIssueList[mislabelRank];
      if (defaultValue && defaultValue.issue_type) {
        const freeTextList = defaultValue.params.message.split(
          [...defaultValue.issue_type, ''].join('\n'),
        );
        const freeText = freeTextList[freeTextList.length - 1];
        setTypeValue(defaultValue.issue_type);
        setFreeText(freeText);
      }
    } else {
      setTypeValue([]);
      setFreeText(undefined);
    }
  }, [isPopperVisible, addIssueList]);

  const resetIssue = () => {
    setAddIssueList(omit(addIssueList, mislabelRank));
    setTypeValue([]);
    setFreeText(undefined);
  };

  const getBboxCoordinate = () => {
    if (imageInfo.annotation_type === 'box') {
      return imageInfo.annot_ori || imageInfo.bbox; //bbox is deprecated but used in some projects
    }

    const minMaxXY = flatten(
      (imageInfo.annot_ori as GeoJSONPolygon).map(coordinates => coordinates[0]),
    ).reduce<Record<string, number | undefined>>(
      (acc, anno) => {
        return {
          minX: acc.minX ? Math.min(acc.minX, anno.x) : anno.x,
          maxX: acc.maxX ? Math.max(acc.maxX, anno.x) : anno.x,
          minY: acc.minY ? Math.min(acc.minY, anno.y) : anno.y,
          maxY: acc.maxY ? Math.max(acc.maxY, anno.y) : anno.y,
        };
      },
      { minX: undefined, maxX: undefined, minY: undefined, maxY: undefined },
    );

    return [
      minMaxXY.minX || 0,
      minMaxXY.minY || 0,
      (minMaxXY.maxX || 0) - (minMaxXY.minX || 0),
      (minMaxXY.maxY || 0) - (minMaxXY.minY || 0),
    ];
  };

  const applyIssue = () => {
    setHover(false);
    setIsPopperVisible(false);

    if (!typeValue.length) return;

    setAddIssueList({
      ...addIssueList,
      [imageInfo.mislabel_rank]: {
        label_id: imageInfo.label_id,
        annotation_id: imageInfo.annotation_id,
        mislabel_rank: imageInfo.mislabel_rank,
        change_type: 'CREATE_ISSUE',
        params: {
          message: [
            ...typeValue.map(
              value =>
                mislabelDetectionIssueTypeValueLabelMap[
                  value as 'redraw' | 'change_class' | 'delete' | 'other'
                ],
            ),
            freeText,
          ].join('\n'),
        },
        issue_type: typeValue,
        bbox: getBboxCoordinate(),
      },
    });
  };

  const options = [
    { label: 'Redraw Annotation', value: 'redraw' },
    { label: 'Change Class', value: 'change_class' },
    { label: 'Delete Annotation', value: 'delete' },
    { label: 'Other', value: 'other' },
  ];

  const onChange = (value: string) => {
    if (typeValue.includes(value)) {
      setTypeValue(without(typeValue, value));
      return;
    }
    setTypeValue([...typeValue, value]);
  };

  return (
    <ActionPopper
      anchorIcon="messageCircle"
      anchorButtonText={addIssueList && addIssueList[mislabelRank] ? 'Edit Issue' : 'Add Issue'}
      applyButton={{
        text: 'Apply',
        onClick: applyIssue,
      }}
      resetButton={
        addIssueList &&
        addIssueList[mislabelRank] && {
          onClick: resetIssue,
        }
      }
      hover={hover}
      setHover={setHover}
      isPopperVisible={isPopperVisible}
      setIsPopperVisible={setIsPopperVisible}
      isSelected={!!(addIssueList && addIssueList[mislabelRank])}
    >
      <Box px={1.5} pt={1.5}>
        <Typography variant="m-strong">Add Mislabel Issue</Typography>
      </Box>
      <List>
        {options.map(option => {
          const isChecked = typeValue.indexOf(option.value) >= 0;
          return (
            <ListItem key={option.value} onClick={() => onChange(option.value)}>
              <Box display="flex" alignItems="center" gap="4px">
                <Icon name={isChecked ? 'checkboxChecked' : 'checkbox'} color="primary" />
                <Typography variant="m-regular">{option.label}</Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
      {typeValue.length > 0 && (
        <Box my={1} display="flex" px={1.5}>
          <textarea
            rows={2}
            style={{
              width: '100%',
              resize: 'none',
              height: 72,
              boxShadow: 'inset 0 0 0 1px #1010102a',
              border: 'none',
              outline: 'none',
              borderRadius: 2,
              padding: '8px',
              fontSize: 12,
              fontFamily: 'Inter, sans-serif',
              lineHeight: 1.5,
            }}
            placeholder="(Optional) Describe what should be improved..."
            onChange={event => {
              setFreeText(event.target.value);
            }}
            value={freeText}
          ></textarea>
        </Box>
      )}
    </ActionPopper>
  );
}
