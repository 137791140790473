import { TFunction } from 'react-i18next';

import { capitalize } from 'lodash';
// TODO (tsnoh)
// 1. 카테고리 별로 묶기 (label, data, new project ...)
// 2. 카테고리 별로 함수 이름 정하기 (LABEL_DELETE, DATA_ASSIGN, ...)
// 3. 공통으로 만들 수 있는 부분 분리하기 (NAME_RANGE, FILL_REQUIRED_FILEDS,... )
// 4. 변수로 받을 수 있는 수치는 변수로 받기

type MsgArgs = { t: TFunction } & Record<string, any>;

// name
export const NAME_RANGE = ({ t, name, min, max }: MsgArgs): string =>
  t('consts.snackbarMessage.nameRange', { name, min, max });

export const NAME_DUPLICATE = ({ t, name }: MsgArgs): string =>
  t('consts.snackbarMessage.nameDuplicate', { name });

// common
export const PLEASE_ENTER_ALL_REQUIRED_FIELDS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.pleaseEnterAllRequiredFields');

export const COPY_SUCCESS = ({ t, label }: MsgArgs): string =>
  t('consts.snackbarMessage.copySuccess', { label });

// filter
export const FILTER_TEXT_ALREADY_SELECTED = ({ t, inputValue }: MsgArgs): string =>
  t('consts.snackbarMessage.filterTextAlreadySelected', { inputValue });
export const FILTER_OPTION_LIMIT = ({ t, max }: MsgArgs): string =>
  t('consts.snackbarMessage.filterOptionLimit', { max });

// user report
export const SHOW_COLUMNS = ({ t, groupName }: MsgArgs): string =>
  t('consts.snackbarMessage.showColumns', { groupName });

export const HIDE_COLUMNS = ({ t, groupName }: MsgArgs): string =>
  t('consts.snackbarMessage.hideColumns', { groupName });

// manage member
export const MANAGE_MEMBER_INVITE_USER_TO_PROJECT = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.manageMemberInviteUserToProject');

export const DELETE_USER_FROM_ACCOUNT = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.deleteUserFromAccount');

export const DELETE_USERS_FROM_ACCOUNT = ({ t, count }: MsgArgs): string =>
  t('consts.snackbarMessage.deleteUsersFromAccount', { count });

export const REMOVE_USER_FROM_PROJECT = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.removeUserFromProject');

export const REMOVE_USERS_FROM_PROJECT = ({ t, count }: MsgArgs): string =>
  t('consts.snackbarMessage.removeUsersFromProject', { count });

export const MANAGE_REMOVE_USER_FROM_PROJECTS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.manageRemoveUserFromProjects');

export const RESEND_INVITATION = ({ t, email }: MsgArgs): string =>
  t('consts.snackbarMessage.resendInvitation', { email });

// upload
export const CSV_UPLOAD_TYPE_NOT_SUPPORTED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.csvUploadTypeNotSupported');

export const CSV_UPLOAD_WRONG_HEADER_FORMAT = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.csvUploadWrongHeaderFormat');

export const CSV_UPLOAD_EMPTY_ROW = ({ t, emptyCsvRowCount }: MsgArgs): string =>
  t('consts.snackbarMessage.csvUploadEmptyRow', { emptyCsvRowCount });

export const CSV_UPLOAD_SAME_DATA_KEY_WITH_INFO = ({ t, hasSameDataKey }: MsgArgs): string =>
  t('consts.snackbarMessage.csvUploadSameDataKeyWithInfo', { hasSameDataKey });

export const CSV_UPLOAD_SAME_DATA_KEY = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.csvUploadSameDataKey');

export const FILE_UPLOAD_TYPE_NOT_SUPPORTED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.fileUploadTypeNotSupported');

export const FILE_UPLOAD_EXTENDED_TYPE_NOT_SUPPORTED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.fileUploadExtendedTypeNotSupported');

// auth
export const AVATAR_UPLOAD_EXCEED_SIZE = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.avatarUploadExceedSize');

export const CHANGE_PASSWORD_SUCCESS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.changePasswordSuccess');

export const CHANGE_PASSWORD_FAIL = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.changePasswordFail');

export const NAME_CHANGE_SUCCESS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.nameChangeSuccess');

// data
export const ASSIGN_DATA_TO_PROJECT_COMPLETE = ({
  t,
  checkedListLength,
  failedListLength,
}: MsgArgs): string => {
  const text = failedListLength
    ? `${(checkedListLength - failedListLength).toLocaleString(
        'en',
      )}/${checkedListLength.toLocaleString('en')}`
    : `${checkedListLength.toLocaleString('en')}`;

  return t('consts.snackbarMessage.assignDataToProjectComplete', { text });
};

export const DELETE_DATA_COMPLETE = ({ t, checkedListLength }: MsgArgs): string =>
  t('consts.snackbarMessage.deleteDataComplete', { checkedListLength });

export const DATASET_JOIN_FAIL = ({ t, fromName, toName }: MsgArgs): string =>
  t('consts.snackbarMessage.datasetJoinFail', { fromName, toName });

export const DATASET_NAME_RANGE_RULE = ({
  t,
  min,
  max,
}: MsgArgs & { min: number; max: number }): string =>
  t('consts.snackbarMessage.datasetNameRangeRule', {
    min,
    max,
  });

// labels

export const LABELS_DO_NOT_EXIST = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.labelDoNotExist');

export const UNKNOWN_ERROR = ({ t }: MsgArgs): string => t('consts.snackbarMessage.unknownError');

export const PAGE_NOT_FOUND = ({ t }: MsgArgs): string => t('consts.snackbarMessage.pageNotFound');

export const LABEL_NOT_FOUND = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.labelNotFound');
export const ISSUE_NOT_FOUND = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.issueNotFound');
export const COMMENT_NOT_FOUND = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.commentNotFound');
// detail view

export const FIRST_LABEL = ({ t }: MsgArgs): string => t('consts.snackbarMessage.firstLabel');
export const LAST_LABEL = ({ t }: MsgArgs): string => t('consts.snackbarMessage.lastLabel');

// new project

export const NEW_PROJECT_CAN_ONLY_ONE_KEYPOINT = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.newProjectCanOnlyOneKeypoint');

export const NEW_PROJECT_CLASS_NAME_CAN_NOT_CONTAIN_SPECIAL_SYMBOL = ({
  t,
  name,
}: MsgArgs): string =>
  t('consts.snackbarMessage.newProjectClassNameCanNotContainSpecialSymbol', { name });

export const NEW_PROJECT_CAN_NOT_MOVE_DUPLICATE_NAME_GROUP = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.newProjectCanNotMoveDuplicateNameGroup');
// export
export const EXPORT_SUCCESS = ({ t, labelsCount }: MsgArgs): string => {
  const count = labelsCount.toLocaleString('en');
  return t('consts.snackbarMessage.exportSuccess', { count });
};

export const EXPORT_NAME_CHANGE_SUCCESS = ({ t, historyName }: MsgArgs): string =>
  t('consts.snackbarMessage.exportNameChangeSuccess', { historyName });

export const EXPORT_NAME_CHANGE_ERROR = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.exportNameChangeError');

export const EXPORT_NAME_EMPTY_ERROR = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.exportNameEmptyError');

// notification
export const NOTIFICATION_INVITE = ({ t, fromFirstName, projectName }: MsgArgs): string =>
  t('consts.snackbarMessage.notificationInvite', { fromFirstName, projectName });

export const NOTIFICATION_ASSIGN = ({
  t,
  fromFirstName,
  labelCount,
  projectName,
}: MsgArgs): string =>
  t('consts.snackbarMessage.notificationAssign', { fromFirstName, labelCount, projectName });

export const NOTIFICATION_THREAD = ({
  t,
  fromFirstName,
  labelName,
  projectName,
}: MsgArgs): string =>
  t('consts.snackbarMessage.notificationThread', { fromFirstName, labelName, projectName });

export const NOTIFICATION_COMMENT = ({
  t,
  fromFirstName,
  labelName,
  projectName,
}: MsgArgs): string =>
  t('consts.snackbarMessage.notificationComment', { fromFirstName, labelName, projectName });

export const NOTIFICATION_EXPORT = ({
  t,
  exportSeqNum,
  subType,
  labelCount,
  projectName,
}: MsgArgs): string =>
  t('consts.snackbarMessage.notificationExport', {
    exportSeqNum,
    subType,
    labelCount,
    projectName,
  });

// export const NOTIFICATION_USER_STATS_ANALYTICS = ({ labelCount }: MsgArgs): string => `
// Generating a user report table for ${labelCount} label(s). Please look out for a notification :)
// `;
export const NOTIFICATION_USER_STATS_ANALYTICS = ({ t, labelCount }: MsgArgs): string =>
  t('consts.snackbarMessage.notificationUserStatsAnalytics', { labelCount });

export const USER_STATS_ZERO_LABELS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.userStatsZeroLabels');

export const USER_STATS_UNKNOWN_ERROR = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.userStatsUnknownError');

export const USER_STATS_REQUEST_EXISTS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.userStatsRequestExists');

export const USER_STATS_NOT_FOUND = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.userStatsNotFound');

// toast
export const TOAST_TASK_NOT_YET_COMPLETE = ({ t, action }: MsgArgs): string =>
  t('consts.snackbarMessage.toastTaskNotYetComplete', { action });

// webhook

export const WEBHOOK_ALERT_CAN_NOT_INSERT_HOOK_INFO = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.webhookAlertCanNotInsertHookInfo');

export const INTEGRATION_ADDED = ({ t, target }: MsgArgs & { target: string }): string =>
  t('consts.snackbarMessage.integrationAdded', { target: capitalize(target) });

export const OBJECT_CLASS_NAME_COMMA = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.objectClassNameComma');

// customize analytics
export const CHART_NAME_EMPTY_ERROR = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.chartNameEmptyError');

export const MODEL_NAME_CHANGED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelNameChanged');

export const MODEL_CANCELED = ({ t }: MsgArgs): string => t('consts.snackbarMessage.modelCanceled');

export const MODEL_DELETED_SUCCESS = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelDeletedSuccess');

export const MODEL_DELETED_FAIL = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelDeletedFail');

export const MODEL_ENDPOINT_SETTING_APPLIED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointSettingApplied');

export const MODEL_ENDPOINT_DELETED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointDeleted');

export const MODEL_ENDPOINT_STATUS_PAUSED = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointStatusPaused');

export const MODEL_ENDPOINT_STATUS_RESUME = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointStatusResume');

export const MODEL_ENDPOINT_CREATE_RETRY = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointCreateRetry');

export const MODEL_ENDPOINT_CREATE_START = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointCreateStart');

export const MODEL_ENDPOINT_MAXIMUM_ERROR = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.modelEndpointMaximumError');

export const JSON_COPIED = ({ t }: MsgArgs): string => t('consts.snackbarMessage.jsonCopied');

export const UPLOAD_FAILED = ({ t }: MsgArgs): string => t('consts.snackbarMessage.uploadFailed');

export const EXCEED_FILE_SIZE = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.exceedFileSize');

export const UPLOAD_ONLY_ONE_IMAGE = ({ t }: MsgArgs): string =>
  t('consts.snackbarMessage.uploadOnlyOneImage');
