import { useRef } from 'react';
import { useHistory, useParams } from 'react-router';

import { Skeleton } from '@mui/material';
import { Box } from '@superb-ai/norwegian-forest';

import { ScopeMode } from '../../../apps/Curate/types/viewTypes';
import { NoResultsFound } from '../NoResultsFound';
import ImageGrid from './ImageGrid';
import { InfiniteWindowedGrid } from './InfiniteWindowedGrid';
import { InfiniteWindowedGridContainerProps } from './types';
import useSectionSize from './useSectionSize';

export default function InfiniteWindowedGridContainer({
  loadItems,
  loadedItems,
  hasNextPage,
  isLoading,
  CellComponent,
  itemData,
  boxProps,
  aspectRatio,
  additionalHeight,
}: InfiniteWindowedGridContainerProps<any>) {
  const containerRef = useRef<HTMLDivElement>();
  const sectionSize = useSectionSize(containerRef);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const { diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const scope = (params.get('scope') || 'image') as ScopeMode;

  if (!hasNextPage && itemData.totalCount === 0) {
    const titleKey = `curate.datasets.noResultFound${
      scope === 'object' || diagnosisId ? 'Object' : ''
    }Title`;
    const explanationKey = diagnosisId
      ? 'curate.datasets.noResultFoundDiagnosis'
      : `curate.datasets.noResultFound${scope === 'object' ? 'Object' : ''}`;
    return <NoResultsFound titleKey={titleKey} explanationKey={explanationKey} />;
  }

  return (
    <Box ref={containerRef} {...boxProps}>
      {sectionSize &&
        (isLoading ? (
          <Box width={sectionSize[2]} height={sectionSize[3]}>
            <ImageGrid columns={itemData?.columns}>
              {[...Array(itemData?.columns).keys()].map((item, index) => (
                <Skeleton
                  key={`skeleton-${index}`}
                  height={
                    ((sectionSize[2] - 8 * itemData?.columns) / 6) * (aspectRatio ?? 1) +
                    (additionalHeight || 0)
                  }
                  variant="rectangular"
                />
              ))}
            </ImageGrid>
          </Box>
        ) : (
          <InfiniteWindowedGrid
            hasNextPage={hasNextPage}
            loadItems={loadItems}
            loadedItems={loadedItems}
            width={sectionSize[2]}
            height={sectionSize[3]}
            CellComponent={CellComponent}
            itemData={itemData}
            aspectRatio={aspectRatio}
            additionalHeight={additionalHeight}
          />
        ))}
    </Box>
  );
}
