import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';
import ColorChipSelectBox from '../../../../elements/ColorChipSelectBox';
import { ThreadProps } from './types';

const useStyles = makeStyles({
  threadHeader: {
    position: 'relative',
    height: '24px',
    padding: '5px 20px 2px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  colorChipBox: {
    position: 'absolute',
    left: 16,
  },
});

type Props = Omit<ThreadProps, 'pos'>;

const ThreadHeader = ({ isCreate, issue }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { label } = labelDetailViewInfo;
  const { nextIssue, setNextIssue, setIssues, openBadgeInfo, setOpenBadgeInfo } = labelIssuesInfo;
  const [selectedColor, setSelectedColor] = useState(issue ? issue.info.color : nextIssue.color);

  useEffect(() => {
    if (!issue) return;
    setSelectedColor(issue.info.color);
  }, [issue]);

  const handleClickColorChip = async (color: string) => {
    setSelectedColor(color);

    if (isCreate) {
      const nextNextIssue = cloneDeep(nextIssue);
      nextNextIssue.info.color = color;
      setNextIssue(nextNextIssue);
    } else {
      if (color === selectedColor) return;
      await IssuesService.updateIssue({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        issueId: openBadgeInfo.issue.id,
        info: {
          info: {
            ...openBadgeInfo.issue.info,
            color,
          },
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
      setOpenBadgeInfo({
        issue,
        ref: null,
      });
    }
  };

  return (
    <MUI.Box className={classes.threadHeader}>
      <MUI.Box className={classes.colorChipBox}>
        <ColorChipSelectBox
          direction="row"
          chipSize="8px"
          selectedColor={selectedColor}
          handleClickColorChip={handleClickColorChip}
        />
      </MUI.Box>
    </MUI.Box>
  );
};

export default ThreadHeader;
