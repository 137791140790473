import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CaretDown, CaretRight, Folder, FolderOutline } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';
import ClassGroupItemDropBar from './ClassGroupItemDropBar';
import ClassGroupItemDropPanel from './ClassGroupItemDropPanel';
import helper from './helper';
import ObjectClassItem from './ObjectClassItem';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff6f6',
    },
    '&.selected': {
      background: '#ffeeee',
    },
  },
  iconButton: {
    padding: '0px',
    width: '20px',
    height: '20px',
    marginRight: '2px',
  },
  createSubgroupButton: {
    position: 'absolute',
    right: 12,
    padding: '6px 12px 6px 10px',
    fontWeight: 400,
    color: '#9e9e9e',
    fontSize: '10px',
    borderRadius: '7px',
  },
  createSubgroupButtonAddIcon: {
    width: '12px',
    height: '12px',
    marginRight: '4px',
  },
  subcategoryBox: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '18px',
  },
  subgroupBox: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

// eslint-disable-next-line
const GroupItem = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classGroupRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { id, index, depth, rootObjectClassGroups } = props;
  const classGroup = newProjectInfo.objectClassGroupMap[id];
  const pl = (depth + 1) * 20;
  const isOpen = newProjectInfo.openedObjectClassGroupIds[id];
  const isSelected = newProjectInfo.selectedObjectClassGroupId === id;

  const handleClickGroup = () => {
    if (isSelected) {
      newProjectInfo.setSelectedObjectClassGroupId('');
    } else {
      newProjectInfo.setSelectedObjectClassGroupId(id);
    }
  };

  const handleClickArrow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (newProjectInfo.openedObjectClassGroupIds[id]) {
      helper.closeGroups(newProjectInfo, [id]);
    } else {
      helper.openGroups(newProjectInfo, [id]);
    }
  };

  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Class group name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const sameHierarchyNameList = rootObjectClassGroups.map(
      (item: any) => newProjectInfo.objectClassGroupMap[item].name,
    );

    return parentHelper.checkNameRule({
      t,
      type: 'Class property name',
      prevValue: classGroup.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
    });
  };

  const editName = (newValue: string) => {
    const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);
    nextObjectClassGroupMap[id].name = newValue;
    newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
  };

  const getChildren = () => {
    if (!isOpen) return null;

    return (
      <MUI.Box className={classes.subgroupBox}>
        {classGroup.children.map((objectClassId: string, index: number) => (
          <ObjectClassItem key={objectClassId} id={objectClassId} index={index} depth={depth + 1} />
        ))}
      </MUI.Box>
    );
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (!classGroupRef || !classGroupRef.current) return;

    newProjectInfo.setDragState({ type: 'classGroup', id });
    classGroupRef.current.style.cursor = 'grabbing';
    if (!e?.dataTransfer) return;
    e.dataTransfer.setData('text', id);
    e.dataTransfer.setDragImage(classGroupRef.current, -10, -10);
  };

  const handleDragEnd = () => {
    if (!classGroupRef || !classGroupRef.current) return;

    newProjectInfo.setDragState(null);
    classGroupRef.current.style.cursor = 'pointer';
  };

  return (
    <MUI.Box position="relative">
      {index === 0 ? <ClassGroupItemDropBar dir="prev" id={id} depth={depth} pl={pl} /> : null}
      <MUI.Box
        {...{ ref: classGroupRef }}
        className={`${classes.box} ${isSelected ? 'selected' : ''}`}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleClickGroup}
        draggable
      >
        <MUI.Box width={pl} />
        <MUI.IconButton className={classes.iconButton} onClick={handleClickArrow}>
          {isOpen ? (
            <Icon icon={CaretDown} size={18} color="gray-400" />
          ) : (
            <Icon icon={CaretRight} size={18} color="gray-400" />
          )}
        </MUI.IconButton>
        <Box mr={2}>
          {isOpen ? (
            <Icon icon={FolderOutline} size={18} color="gray-400" />
          ) : (
            <Icon icon={Folder} size={18} color="gray-400" />
          )}
        </Box>
        <EditableText
          text={classGroup.name}
          maxWidth={`${440}px`}
          fontStyles={{
            fontSize: '13px',
            color: '#797979',
            fontWeight: 500,
          }}
          parseValue={parseValue}
          checkRule={checkNameRule}
          editText={editName}
          isMount={newProjectInfo.createState === 'OBJECT_DETECTION_GROUP'}
        />
        <ClassGroupItemDropPanel classGroup={classGroup} />
      </MUI.Box>
      {getChildren()}
      <ClassGroupItemDropBar dir="next" id={id} depth={depth} pl={pl} />
    </MUI.Box>
  );
};

export default GroupItem;
