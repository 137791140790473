import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { ProjectData } from '../../../types/projectTypes';

const getSyncedLabelProjects: AfterLoginCallback<
  { count: number; results: ProjectData[] },
  { datasetId: string; isCurateSynced?: boolean }
> = async params => {
  const {
    data: { datasetId, isCurateSynced },
    ...otherParams
  } = params;
  const result = await apiCallAfterLogin({
    method: 'get',
    url: `/projects/?${qs.stringify(
      { is_curate_synced: true, curate_dataset_id_in: [datasetId] },
      { arrayFormat: 'brackets' },
    )}`,
    hasPublicApi: false,
    ...otherParams,
  });

  return result.data;
};

export function useCurateSyncedLabelProjectService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getSyncedLabelProjects: afterLoginFetcher(getSyncedLabelProjects),
  };
}
