import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ChevronDown } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Button, Icon, Tooltip, Typography } from '@superb-ai/ui';

import { useImageScopeContext } from '../contexts/ImageScopeContext';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../contexts/QueryContext';
import { SampleDatasetTooltip } from './SampleDatasetTooltip';

type Props<T> = {
  selectedData: T[];
  setSelectedData: Dispatch<SetStateAction<T[]>>;
  deselectedData: T[];
  setDeselectedData: Dispatch<SetStateAction<T[]>>;
  selectedAllData: boolean;
  setSelectedAllData: Dispatch<SetStateAction<boolean>>;
};

export default function SelectItemsDropdown<T>(props: Props<T>) {
  const {
    selectedData,
    setSelectedData,
    deselectedData,
    setDeselectedData,
    selectedAllData,
    setSelectedAllData,
  } = props;
  const { showPublicDatasets } = usePublicDatasetContext();
  const { totalCount: totalImageCount } = useImageScopeContext();
  const { setHiddenQueryString } = useQueryContext();

  const { diagnosisId } = useParams<{ diagnosisId: string }>();

  const { t } = useTranslation();

  function TooltipMessage() {
    if (showPublicDatasets) {
      if (diagnosisId) {
        // Model Diagnosis
        return <SampleDatasetTooltip feature="diagnosisSelectData" />;
      } else {
        // Explore / Slice
        return <SampleDatasetTooltip feature="selectData" />;
      }
    }
    return undefined;
  }

  return (
    <Tooltip content={TooltipMessage()} placement="bottom" hideOnEmptyContent>
      <span>
        <DropdownMenu
          width={112}
          AnchorElement={
            <Button style={{ padding: '0 12px' }} disabled={showPublicDatasets}>
              {!selectedAllData ? (
                <>
                  <Typography color="pink">{selectedData?.length?.toString() || '0'}</Typography>{' '}
                  <Typography variant="m-regular">{t('curate.button.selected')}</Typography>
                </>
              ) : deselectedData?.length ? (
                <>
                  <Typography color="pink">{totalImageCount - deselectedData.length}</Typography>{' '}
                  <Typography variant="m-regular">{t('curate.button.selected')}</Typography>
                </>
              ) : (
                <Typography variant="m-regular">{t('curate.button.selectedAll')}</Typography>
              )}
              <span style={{ marginLeft: '4px' }}>
                <Icon icon={ChevronDown} />
              </span>
            </Button>
          }
          placement="bottom-start"
        >
          <DropdownMenuItem
            onClick={() => {
              setSelectedData([]);
              setSelectedAllData(true);
              setHiddenQueryString && setHiddenQueryString(undefined);
              setDeselectedData([]);
            }}
          >
            {t('curate.button.selectAll')}
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={!selectedAllData && selectedData && selectedData?.length < 1}
            onClick={() => {
              setSelectedData([]);
              setSelectedAllData(false);
              setHiddenQueryString && setHiddenQueryString(undefined);
              setDeselectedData([]);
            }}
          >
            {t('curate.button.deselectAll')}
          </DropdownMenuItem>
        </DropdownMenu>{' '}
      </span>
    </Tooltip>
  );
}
