import React from 'react';

interface Props {
  symbol: string;
  label?: string;
  className?: string;
}
const Emoji: React.FC<Props> = ({ label, symbol, className }) => {
  return (
    <span
      className={className || ''}
      role="img"
      aria-label={label || ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
    </span>
  );
};

export default Emoji;
