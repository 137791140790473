import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Button, Dialog, Icon, Tooltip, useDialogState } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { VerticalStepper } from '../../../../../../../components/composition/VerticalStepper';
import { UploadProvider, useUploadInfo } from '../../../../../contexts/UploadContext';
import { useCurateCreateJobMutation } from '../../../../../queries/commandQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { CLOUD_ANNOTATION_UPLOAD, CLOUD_UPLOAD } from '../../../../../types/commandTypes';
import CloudImageUpload from './CloudImageUpload';

export default function UploadModal({
  dialog,
  hasSliceStep,
}: {
  dialog: ReturnType<typeof useDialogState>;
  hasSliceStep: boolean;
}) {
  const { t } = useTranslation();

  return (
    <UploadProvider>
      <Dialog state={dialog} aria-label="Upload dialog">
        <Dialog.Header>
          <Box display="flex" alignItems="center" gap={0.5}>
            {t('curate.datasets.cloudUpload.modalTitle')}
            <Tooltip content={t('curate.datasets.cloudUpload.storedBySpb')} placement="right">
              <Icon icon={InfoCircleOutline} color="black-400" />
            </Tooltip>
          </Box>
        </Dialog.Header>
        <UploadModalContent dialog={dialog} hasSliceStep={hasSliceStep} />
      </Dialog>
    </UploadProvider>
  );
}

function UploadModalContent({
  dialog,
  hasSliceStep,
}: {
  dialog: ReturnType<typeof useDialogState>;
  hasSliceStep: boolean;
}) {
  const { mutate: createJob } = useCurateCreateJobMutation();
  const { createSlice } = useCurateDatasetService();
  const {
    isStepsCompleted,
    initialize,
    cloudIntegrationId,
    cloudPrefix,
    selectedSlice,
    currentSliceId,
    cloudStorageType,
    uploadTarget,
  } = useUploadInfo();
  const { datasetId, accountName } = useParams<{ datasetId: string; accountName: string }>();
  const { t, i18n } = useTranslation();
  const steps = CloudImageUpload(t, i18n.language, datasetId, hasSliceStep);

  const isOnlyImageTypeSelected =
    uploadTarget.includes('image') && !uploadTarget.includes('annotation');
  const isOnlyAnnotationTypeSelected =
    !uploadTarget.includes('image') && uploadTarget.includes('annotation');
  const isImageAndAnnotationTypesSelected =
    uploadTarget.includes('image') && uploadTarget.includes('annotation');

  useEffect(() => {
    if (!dialog.visible) {
      initialize();
    }
  }, [dialog.visible]);

  const trackCloudUpload = () => {
    analyticsTracker.curateDataUploadRequested({
      accountId: accountName,
      cloudStorage: cloudStorageType,
      hasTargetSlice: Boolean(selectedSlice?.id),
      dataType: 'image',
    });
  };
  async function handleClickUpload() {
    let newSliceId;
    if (selectedSlice?.isNew) {
      newSliceId = (await createSlice({ name: selectedSlice.id, description: '', datasetId })).id;
    }
    await createJob({
      data: {
        dataset_id: datasetId,
        integration_id: cloudIntegrationId,
        slice_id: (!hasSliceStep && currentSliceId) || newSliceId || selectedSlice?.id,
        prefix: cloudPrefix,
        include_annotations: isImageAndAnnotationTypesSelected,
      },
      jobType: isOnlyAnnotationTypeSelected ? CLOUD_ANNOTATION_UPLOAD : CLOUD_UPLOAD,
    });
    trackCloudUpload();
    dialog.hide();
  }

  return (
    <>
      <Dialog.Content
        border="1px solid"
        borderColor="gray-150"
        borderRadius="2px"
        overflow="overlay"
        style={{ height: '482px', width: '600px' }}
      >
        {dialog.visible && <VerticalStepper steps={steps} />}
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void dialog.hide()}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="strong-fill"
          color="primary"
          disabled={!isStepsCompleted}
          onClick={handleClickUpload}
        >
          {t('button.okay')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
