import { useTranslation } from 'react-i18next';

import {
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { MODEL_DELETED_FAIL, MODEL_DELETED_SUCCESS } from '../../../consts/SnackbarMessage';
import { FIVE_MINUTES, ONE_MINUTE } from '../../../queries';
import { readUrlJson } from '../../../utils/FileUtils';
import {
  GenerationEndpointInferenceImageParams,
  RecognitionEndpointInferenceImageData,
  RecognitionEndpointInferenceImageParams,
  useModelService,
} from '../services/ModelService';
import {
  CancelModelParams,
  CreateEndpointParams,
  CreateModelParams,
  CreateModelTagParams,
  EarlyCompleteParams,
  EditEndpointSettingParams,
  EditModelMemoParams,
  EditModelParams,
  EndpointListParams,
  EstimatedTrainingTimeParams,
  ModelHistoryParams,
  ModelListParams,
  ModelStatus,
  ModelTag,
  PublicModelListParams,
  PurposeType,
  ResumeEndpointParams,
  SamplePredictionData,
  SchedulingEndpointParams,
} from '../services/types';

type Params = {
  sortBy?: 'created_at' | 'updated_at';
  sortOrder?: 'desc' | 'asc';
  size?: number;
};

export function usePublicModelQuery({ id }: { id: string }) {
  const { getPublicModel } = useModelService();
  return useQuery({
    queryKey: ['public-model', id],
    queryFn: () => getPublicModel({ id }),
    retry: 3,
    refetchOnWindowFocus: false,
  });
}

export function usePublicModelListQuery({
  params = {},
  enabled,
}: { params?: PublicModelListParams; enabled?: boolean } = {}) {
  const { getPublicModelList } = useModelService();
  const { size = 10 } = params;
  return useInfiniteQuery({
    queryKey: ['public-models', params],
    queryFn: ({ pageParam }) => {
      return getPublicModelList({ ...params, page: pageParam });
    },
    getNextPageParam: ({ count }, pages) => {
      return count > pages.length * size ? pages.length + 1 : undefined;
    },
    enabled,
    retry: 3,
  });
}

export function useModelListQuery({
  params = {},
  enabled,
}: { params?: ModelListParams; enabled?: boolean } = {}) {
  const { getModelList } = useModelService();

  return useInfiniteQuery({
    queryKey: ['models', params],
    queryFn: ({ pageParam }) => {
      return getModelList({ ...params, searchAfter: pageParam });
    },
    getNextPageParam: ({ next }) => next,
    enabled,
    // To update status, progress
    refetchInterval: data =>
      data?.pages
        .flatMap(p => p.modelList)
        .find(model => model.status === 'pending' || model.status === 'training')
        ? 3 * ONE_MINUTE
        : undefined,
    retry: 3,
    refetchOnWindowFocus: false,
  });
}

export function useModelDetailQuery({
  id,
  modelPurpose,
  enabled,
  retry = 3,
}: {
  id: string;
  modelPurpose: PurposeType;
  enabled?: boolean;
  retry?: number;
}) {
  const { getModel } = useModelService();

  return useQuery({
    queryKey: ['model', id],
    queryFn: () => getModel({ id, modelPurpose }),
    refetchInterval: data => (data?.status !== 'trained' ? 3 * ONE_MINUTE : undefined),
    enabled,
    retry,
    refetchOnWindowFocus: false,
  });
}

export function useDeployedModelListQuery({
  params = {},
}: { params?: Params; enabled?: boolean } = {}) {
  const { getDeployedModels } = useModelService();

  return useQuery({
    queryKey: ['deployed-model', params],
    queryFn: () => getDeployedModels({ ...params }),
    refetchInterval: FIVE_MINUTES,
    retry: 3,
  });
}

export function useCheckModelNameUniqueQuery({ id, name }: { id?: string; name: string }) {
  const { checkModelNameUnique } = useModelService();

  return useInfiniteQuery({
    queryKey: ['check-model-name-unique', id, name],
    queryFn: ({ pageParam }) => {
      return checkModelNameUnique({ id, name, searchAfter: pageParam });
    },
    getNextPageParam: ({ next }) => next,
    refetchInterval: FIVE_MINUTES,
    retry: 3,
  });
}

export function useModelTrainingResultQuery({ id }: { id: string }) {
  const { getModelTrainingResult } = useModelService();

  return useQuery({
    queryKey: ['model-training-result'],
    queryFn: () => getModelTrainingResult({ id }),
    refetchInterval: FIVE_MINUTES,
    retry: 3,
  });
}

export function useModelTrainingSetQuery({ id }: { id: string }) {
  const { getModelTrainingSet } = useModelService();

  return useQuery({
    queryKey: ['model-training-set'],
    queryFn: () => getModelTrainingSet({ id }),
    refetchInterval: FIVE_MINUTES,
    retry: 3,
  });
}

export function useModelTrainingEpochsQuery({ id, status }: { id: string; status?: ModelStatus }) {
  const { getModelTrainingEpochs } = useModelService();

  return useQuery({
    queryKey: ['model-training-epochs', id],
    queryFn: () => getModelTrainingEpochs({ id }),
    refetchInterval: status === 'trained' ? FIVE_MINUTES : false,
    refetchOnWindowFocus: false,
    retry: 3,
  });
}

export function useModelFilterChoicesQuery({ modelPurpose }: { modelPurpose?: PurposeType }) {
  const { getModelFilterChoices } = useModelService();

  return useQuery({
    queryKey: ['model-filter-choices'],
    queryFn: () => getModelFilterChoices({ modelPurpose }),
    refetchInterval: FIVE_MINUTES,
    retry: 3,
  });
}

export function useModelEndpointsQuery({
  params = {},
  enabled,
}: {
  params?: EndpointListParams;
  enabled?: boolean;
}) {
  const { getEndpointsList } = useModelService();

  return useInfiniteQuery({
    queryKey: ['model-endpoints', params],
    queryFn: ({ pageParam }) => getEndpointsList({ ...params, searchAfter: pageParam }),
    getNextPageParam: ({ next }) => next,
    enabled,
    retry: 3,
    refetchInterval: data =>
      data?.pages
        .flatMap(p => p.endpointList)
        .find(
          endpoint =>
            endpoint.status === 'pausing' ||
            endpoint.status === 'running' ||
            endpoint.status === 'starting',
        )
        ? ONE_MINUTE
        : undefined,
    refetchOnWindowFocus: false,
  });
}

export function useModelEndpointQueries({ ids }: { ids: string[] }) {
  const { getEndpointDetail } = useModelService();
  return useQueries({
    queries: ids.map(id => {
      return {
        queryKey: ['model-endpoint', id],
        queryFn: () => getEndpointDetail({ id }),
        refetchInterval: ONE_MINUTE,
        refetchOnWindowFocus: false,
      };
    }),
  });
}

export function useCheckEndpointNameUniqueQuery({ id, name }: { id?: string; name: string }) {
  const { checkEndpointNameUnique: getCheckEndpointNameUnique } = useModelService();

  return useInfiniteQuery({
    queryKey: ['check-endpoint-name-unique', id, name],
    queryFn: ({ pageParam }) => {
      return getCheckEndpointNameUnique({ id, name, searchAfter: pageParam });
    },
    getNextPageParam: ({ next }) => next,
  });
}

export function useModelEndpointDetailQuery({ id }: { id: string }) {
  const { getEndpointDetail } = useModelService();
  return useQuery({
    queryKey: ['model-endpoint-detail', id],
    queryFn: () => getEndpointDetail({ id }),
    // staleTime: TWENTY_SECONDS,
    refetchInterval: data =>
      data?.status === 'pausing' || data?.status === 'starting' ? ONE_MINUTE : false,
  });
}

export function useModelHistoryQuery({
  params,
  enabled,
}: {
  params: ModelHistoryParams;
  enabled?: boolean;
}) {
  const { getModelHistory } = useModelService();
  return useInfiniteQuery({
    queryKey: ['model-history', params.id],
    queryFn: ({ pageParam }) => getModelHistory({ ...params, searchAfter: pageParam }),
    getNextPageParam: ({ next }) => next,
    enabled,
    retry: 3,
    refetchInterval: FIVE_MINUTES,
    refetchOnWindowFocus: false,
  });
}

export function useModelTrainingSamplePredictionsQuery({
  url,
  status,
  enabled,
}: {
  url: string;
  status?: ModelStatus;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: ['sample-prediction', url, enabled],
    queryFn: () => readUrlJson(url),
    enabled,
    retry: 3,
    refetchInterval: status === 'trained' ? undefined : ONE_MINUTE,
    refetchOnWindowFocus: false,
  }) as UseQueryResult<SamplePredictionData, unknown>;
}

export function useModelTrainingSamplePredictionsQueries({
  urls,
  status,
  enabled,
}: {
  urls: string[];
  status?: ModelStatus;
  enabled?: boolean;
}) {
  return useQueries({
    queries: urls.map(url => {
      return {
        queryKey: ['sample-prediction', url, enabled],
        queryFn: () => readUrlJson(url),
        enabled,
        retry: 3,
        refetchInterval: status === 'trained' ? undefined : ONE_MINUTE,
        refetchOnWindowFocus: false,
      };
    }),
  }) as UseQueryResult<SamplePredictionData, unknown>[];
}

export function useModelTagsQuery({ enabled }: { enabled?: boolean }) {
  const { getModelTags } = useModelService();

  return useQuery({
    queryKey: ['model-tags', enabled],
    queryFn: () => getModelTags({}),
    enabled,
    retry: 3,
    refetchInterval: FIVE_MINUTES,
  });
}

export const useModelOverviewQuery = ({ enabled }: { enabled?: boolean }) => {
  const { getOverview } = useModelService();

  return useQuery({
    queryKey: ['model-overview', enabled],
    queryFn: () => getOverview({}),
    enabled,
    retry: 3,
    refetchInterval: ONE_MINUTE,
  });
};

export const useEndpointFilterQuery = ({ enabled }: { enabled?: boolean }) => {
  const { getEndpointFilterChoices } = useModelService();

  return useQuery({
    queryKey: ['endpoint-filter-choices', enabled],
    queryFn: () => getEndpointFilterChoices({}),
    enabled,
    retry: 3,
    refetchInterval: FIVE_MINUTES,
  });
};

export const useBaselineModelSamplesQuery = ({
  id,
  enabled,
}: {
  id: string;
  enabled?: boolean;
}) => {
  const { getBaselineModelSamples } = useModelService();

  return useQuery({
    queryKey: ['baseline-model-samples', id],
    queryFn: () => getBaselineModelSamples({ id }),
    enabled,
    retry: 3,
    refetchOnWindowFocus: false,
  });
};

export const useBaselineModelSamplesDetailQuery = ({
  id,
  domain,
  enabled,
}: {
  id: string;
  domain: string;
  enabled?: boolean;
}) => {
  const { getBaselineModelSamplesDetail } = useModelService();

  return useQuery({
    queryKey: ['baseline-model-samples', id, domain],
    queryFn: () => getBaselineModelSamplesDetail({ id, domain }),
    enabled,
    retry: 3,
    refetchOnWindowFocus: false,
  });
};

export function useEditTagToModelMuatation() {
  const { editTagToModel } = useModelService();
  const queryClient = useQueryClient();

  return useMutation(
    (params: CreateModelTagParams) =>
      editTagToModel({ modelId: params.modelId, modelTags: params.modelTags }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['model-tags']);
        queryClient.invalidateQueries(['model']);
        queryClient.invalidateQueries(['models']);
      },
    },
  );
}

export function useDeleteModelTagMutation() {
  const { deleteModelTag } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((tagName: string) => deleteModelTag({ name: tagName }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-tags']);
      queryClient.invalidateQueries(['model']);
      queryClient.invalidateQueries(['models']);
    },
  });
}

export function useCreateModelTagMutation() {
  const { createModelTag } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((tag: ModelTag) => createModelTag({ modelTag: tag }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-tags']);
    },
  });
}

export function useDeleteModelMutation() {
  const { t } = useTranslation();
  const { deleteModel } = useModelService();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => deleteModel({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      enqueueSnackbar(MODEL_DELETED_SUCCESS({ t }), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(MODEL_DELETED_FAIL({ t }), {
        variant: 'error',
      });
    },
  });
}

export function useDeleteEndpointMutation() {
  const { deleteEndpoint } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteEndpoint({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
      queryClient.invalidateQueries(['model-endpoint-detail']);
    },
  });
}

export function useResumeEndpointMutation() {
  const { resumeEndpoint } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((params: ResumeEndpointParams) => resumeEndpoint(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
      queryClient.invalidateQueries(['model-endpoint-detail']);
    },
  });
}

export function usePauseEndpointMutation() {
  const { pauseEndpoint } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((id: string) => pauseEndpoint({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
      queryClient.invalidateQueries(['model-endpoint-detail']);
    },
  });
}

export function useEndpointSchedulingMutation() {
  const { schedulingEndpoint } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((params: SchedulingEndpointParams) => schedulingEndpoint(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
      queryClient.invalidateQueries(['model-endpoint-detail']);
    },
  });
}

export function useModelEarlyCompleteMutation() {
  const { postEarlyCompleteModel } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((params: EarlyCompleteParams) => postEarlyCompleteModel(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      queryClient.invalidateQueries(['model']);
    },
  });
}

export function useCancelModelMutation() {
  const { postCancelModel } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((params: CancelModelParams) => postCancelModel(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      queryClient.invalidateQueries(['model']);
    },
  });
}

export function useEstimatedTrainingTimeMutation() {
  const { postEstimatedTrainingTime } = useModelService();
  return useMutation((params: EstimatedTrainingTimeParams) =>
    postEstimatedTrainingTime({ ...params }),
  );
}

export function useModelPinMutation() {
  const { postModelPin } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((id: string) => postModelPin({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      queryClient.invalidateQueries(['model']);
      queryClient.invalidateQueries(['model-overview']);
    },
  });
}

export function useModelUnpinMutation() {
  const { postModelUnpin } = useModelService();
  const queryClient = useQueryClient();

  return useMutation((id: string) => postModelUnpin({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      queryClient.invalidateQueries(['model']);
      queryClient.invalidateQueries(['model-overview']);
    },
  });
}

export function useEditModelMemoMuataion() {
  const { editModelMemo } = useModelService();
  const queryClient = useQueryClient();

  return useMutation(
    (params: EditModelMemoParams) => editModelMemo({ id: params.modelId, memo: params.memo }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['model']);
      },
    },
  );
}

export function useEditModelMutation() {
  const { editModel } = useModelService();
  const queryClient = useQueryClient();
  return useMutation((params: EditModelParams) => editModel(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
      queryClient.invalidateQueries(['model']);
    },
  });
}

export function useEditEndpointSettingMutation() {
  const { editEndpointSetting } = useModelService();
  const queryClient = useQueryClient();
  return useMutation((params: EditEndpointSettingParams) => editEndpointSetting(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
      queryClient.invalidateQueries(['model-endpoint-detail']);
    },
  });
}

export function useCreateModelMutation() {
  const { createModel } = useModelService();
  const queryClient = useQueryClient();
  return useMutation((params: CreateModelParams) => createModel(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
}

export function useCreateEndpointMutation() {
  const { createEndpoint } = useModelService();
  const queryClient = useQueryClient();
  return useMutation((params: CreateEndpointParams) => createEndpoint(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['model-endpoints']);
    },
  });
}

export function usePostRecognitionEndpointInferenceImageMutation({
  handleSuccess,
}: {
  handleSuccess: (data: RecognitionEndpointInferenceImageData) => void;
}) {
  const { postRecognitionEndpointInferenceImage } = useModelService();
  return useMutation(
    (params: RecognitionEndpointInferenceImageParams) =>
      postRecognitionEndpointInferenceImage(params),
    {
      onSuccess: data => {
        handleSuccess(data);
      },
    },
  );
}

export function usePostGenerationEndpointInferenceImage() {
  const { postGenerationEndpointInferenceImage } = useModelService();
  return useMutation((params: GenerationEndpointInferenceImageParams) =>
    postGenerationEndpointInferenceImage(params),
  );
}
