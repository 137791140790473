import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@mui/material';
import { Box, Typography } from '@superb-ai/norwegian-forest';

import { AscDesc } from '../../../types/muiTypes';
import TableSortLabel from '../TableSortLabel';

export interface MembersTableHeaderProps {
  columns: string[];
  columnsWidth: (number | string)[];
  isSelectedAll?: boolean;
  sortBy?: string;
  sortOrder?: AscDesc;
  onToggleSelectAll?: () => void;
  onRequestSort?: (field: string, ascDesc: AscDesc) => void;
}

const ColToLabel: Record<string, string> = {
  name: 'users.table.name',
  email: 'users.table.email',
  role: 'settings.profile.role',
  lastLoginedAt: 'users.table.lastActive',
  deleteButtonArea: '',
  projects: 'users.projectAccess',
  createdAt: 'users.table.created',
  accountMenuArea: '',
  mfaStatus: 'users.table.2fa',
};

const MembersTableHead: React.FC<MembersTableHeaderProps> = props => {
  const { t } = useTranslation();
  const {
    columns,
    columnsWidth,
    isSelectedAll,
    sortBy,
    sortOrder,
    onToggleSelectAll,
    onRequestSort,
  } = props;
  return (
    <Box display="flex" alignItems="center" shadow={2} overflow="auto" borderRadius>
      <Box p={1} width={44} boxSizing="border-box">
        <Checkbox
          color="primary"
          onClick={() => onToggleSelectAll && onToggleSelectAll()}
          checked={isSelectedAll}
        />
      </Box>
      {columns.map((column, index) =>
        typeof sortBy !== 'undefined' &&
        typeof onRequestSort !== 'undefined' &&
        column !== 'deleteButtonArea' &&
        column !== 'projects' &&
        column !== 'accountMenuArea' ? (
          <Box p={1} width={columnsWidth[index]} boxSizing="border-box" key={column}>
            <TableSortLabel
              property={column}
              onRequestSort={onRequestSort}
              isDesc={sortOrder === 'desc'}
              orderBy={sortBy}
              defaultOrder={column === 'createdAt' ? 'desc' : 'asc'}
            >
              <Typography variant="headline7">{t(ColToLabel[column])}</Typography>
            </TableSortLabel>
          </Box>
        ) : (
          <Box p={1} width={columnsWidth[index]} boxSizing="border-box" key={column}>
            <Typography variant="headline7">{t(ColToLabel[column])}</Typography>
          </Box>
        ),
      )}
    </Box>
  );
};

export default MembersTableHead;
