import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import Integrations from '../../../components/pages/account/integrations/Integrations';
import { CreateMenuItem } from '../../../menu/MenuItem';

export const IntegrationsListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountIntegrationList,
  path: '',
  title: 'Integrations',
  component: Integrations,
  icon: 'link2',
});
