import React, { CSSProperties, useEffect, useState } from 'react';

import * as d3 from 'd3';
import { isEmpty, isUndefined, map, zip } from 'lodash';

import { ClickedEventProps } from '../../chartContainers/types';
import { FORMAT_COUNT } from '../../config/d3Formatters';
import { SvgConfigObject, SvgConfigScrollBarObject } from '../../config/types';
import { BarChartNameChoice } from '../../dataTypes/analyticsDataTypes';
import { Tooltip } from '../../elements/TooltipBar';
import XAxis from '../../elements/XAxis';
import YAxis from '../../elements/YAxis';
import { BaseDatum, JsonObj } from '../../userStats/types';
import { getBarScales } from '../helper';
import AreaGradient from '../lineChart/AreaGradient';

const TEXT_STYLE: CSSProperties = {
  fontSize: '12px',
  fontWeight: 500,
  textAnchor: 'middle',
};

interface ChartProps {
  xValues: string[];
  yValues: number[];
  sValues: string[];
  totalCounts: number;
  tooltipKeyMap?: Record<string, string>; // map keys to display names for
  chartName: BarChartNameChoice; // name defined in plotConfig
  isScrolling: boolean; // Indicates whether scroll is moving
  groups: JsonObj[];
  filter: Record<string, string[] | []>;
  xKeyToDisplayName?: Record<string, string> | undefined;
  svgInfo: SvgConfigScrollBarObject | SvgConfigObject;
  handleOnHover?: (index: number) => void;
  hasTooltip?: boolean;
  hoveredIndex: number;
  rotateXLabel?: boolean;
  handleClickDatum?: ClickedEventProps;
}

interface ContentProps {
  x: string;
  y: number;
  xFilter: string;
  supplement: string;
  groups: { [x: string]: string };
}

const BarChart: React.FC<ChartProps> = props => {
  const {
    xValues,
    yValues,
    sValues,
    totalCounts,
    tooltipKeyMap,
    chartName,
    groups,
    isScrolling,
    filter,
    xKeyToDisplayName,
    svgInfo,
    handleOnHover,
    hasTooltip,
    hoveredIndex,
    rotateXLabel,
    handleClickDatum,
  } = props;

  // TODO: refactor as prop
  const hasPercentShare =
    (!isEmpty(sValues) && typeof sValues[0] === 'string' && sValues[0].indexOf('@')) === -1; // is not email
  const [hoveredBarIndex, setHoveredBarIndex] = useState(-1);
  const [tooltipContent, setTooltipContent] = useState({});
  const [keepTooltipOpen, setKeepTooltipOpen] = useState(false);
  const [removeTooltip, setRemoveTooltip] = useState(false);
  const COLOR = {
    hovered: svgInfo?.barDefaultColor ?? '#FF625A',
    default: svgInfo?.barHoverColor ?? '#FE9573',
  };

  useEffect(() => {
    setHoveredBarIndex(hoveredIndex);
  }, [hoveredIndex]);

  // TODO (ml) - fix this so editing display value is done later
  // const xDisplayValues = _.map(xValues, d => _.upperFirst(d));
  const [x, y] = getBarScales(xValues, yValues, svgInfo, 'BAND');
  const getThirdVariable = (chartName: BarChartNameChoice) => {
    return {
      workerStats: 'email',
      objectClassStats: 'share',
      imageCategoryStats: 'share',
    }[chartName];
  };
  const gradientId = `bar-chart-area-gradient-${chartName}`;
  const gradientIdHover = `bar-chart-area-gradient-${chartName}-hover`;
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (removeTooltip && !keepTooltipOpen) {
      const timer = setTimeout(() => {
        setHoveredBarIndex(-1);
        setKeepTooltipOpen(false);
        setRemoveTooltip(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [removeTooltip, keepTooltipOpen]);

  const handleMouseEnter = (i: number, content: ContentProps) => {
    if (isEmpty(setHoveredBarIndex) && !keepTooltipOpen) {
      setHoveredBarIndex(i);
      if (tooltipKeyMap) {
        setRemoveTooltip(false);
        const tooltipContent: JsonObj = {
          xValue: [tooltipKeyMap.x, content.x],
          xFilterValue: [tooltipKeyMap.xFilter, content.xFilter],
          yValue: [tooltipKeyMap.y, content.y],
          supplementValue: [tooltipKeyMap[getThirdVariable(chartName)], content.supplement],
        };
        if (chartName === 'imageCategoryStats') {
          tooltipContent.groups = ['Group', content.groups];
        }
        setTooltipContent(tooltipContent);
      }
      handleOnHover && handleOnHover(i);
    }
  };
  const handleMouseLeave = () => {
    if (tooltipKeyMap && !keepTooltipOpen) {
      setRemoveTooltip(true);
    }
  };
  const handleClick = () => {
    if (!!hasTooltip) return; // ignore click event
    if (keepTooltipOpen) {
      setHoveredBarIndex(-1);
    }
    setKeepTooltipOpen(!keepTooltipOpen);
  };

  const handleSVGClick = () => {
    if (keepTooltipOpen) {
      setKeepTooltipOpen(!keepTooltipOpen);
      setHoveredBarIndex(-1);
    }
  };

  const handleTooltipMouseEnter = () => {
    setKeepTooltipOpen(true);
  };

  const handleTooltipMouseLeave = () => {
    setKeepTooltipOpen(false);
  };

  const textDistanceAboveBar = 8; // Distance between text and rect svg

  const xDisplayValues = xValues;
  const bars =
    !isEmpty(xDisplayValues) &&
    !isEmpty(yValues) &&
    !isEmpty(xValues) &&
    map(zip(xDisplayValues, yValues, sValues, xValues, groups), (d: BaseDatum[], index) => {
      // TODO (mlimb) consider moving barPadding to chartDefaults
      const idOrName = d[0] as string;

      return (
        <g key={`g${index}`}>
          {!isUndefined(d[1]) &&
            !isUndefined(idOrName) &&
            !Number.isNaN(idOrName) &&
            !Number.isNaN(d[1]) && (
              <rect
                key={`rect-${index}`}
                className="bar-chart-rect"
                cursor={!!hasTooltip ? 'pointer' : 'default'}
                x={x(idOrName as any)}
                y={y(d[1] as any)}
                width={x.bandwidth()}
                height={svgInfo.height - (y(d[1]) as number)}
                opacity={1}
                fill={
                  hoveredBarIndex === index ? `url(#${gradientIdHover})` : `url(#${gradientId})`
                }
                onClick={() => handleClick()}
                onMouseEnter={() =>
                  handleMouseEnter(index, {
                    x: idOrName,
                    xFilter: d[3],
                    y: d[1],
                    supplement: d[2],
                    groups: d[4],
                  })
                }
                onMouseLeave={() => handleMouseLeave()}
              />
            )}
          <rect
            key={`rect-text-${index}`}
            cursor="pointer"
            x={x(d[0])}
            y={(y(d[1]) as number) - 14}
            width={x.bandwidth()}
            height={22}
            opacity={0}
            onClick={() => handleClick()}
            onMouseEnter={() =>
              handleMouseEnter(index, {
                x: d[0],
                xFilter: d[3],
                y: d[1],
                supplement: d[2],
                groups: d[4],
              })
            }
            onMouseLeave={() => handleMouseLeave()}
          />
          {!isUndefined(d[0]) && !isUndefined(d[1]) && (
            <text
              style={TEXT_STYLE}
              key={`text${index}`}
              fill={
                hoveredBarIndex === index
                  ? d3.rgb(COLOR.hovered).darker(0.5).toString()
                  : d3.rgb(COLOR.default).darker(0.5).toString()
              }
              x={(x(d[0]) as number) + x.bandwidth() / 2}
              y={(y(d[1]) as number) - textDistanceAboveBar}
              onClick={() => handleClick()}
              onMouseEnter={() =>
                handleMouseEnter(index, {
                  x: d[0],
                  xFilter: d[3],
                  y: d[1],
                  supplement: d[2],
                  groups: d[4],
                })
              }
              onMouseLeave={() => handleMouseLeave()}
            >
              {FORMAT_COUNT(d[1] as number)}
            </text>
          )}
        </g>
      );
    });
  const gTransform = `translate(${svgInfo.left},${svgInfo.top})`;
  const svgStyle = {
    overflow: 'visible',
    width: svgInfo.svgWidth,
    height: svgInfo.svgHeight,
  };

  const xMaxLength = Math.max(...xValues.map(el => el.length));

  return (
    <svg id="bar-chart-svg" style={svgStyle}>
      <AreaGradient gradientId={gradientId} brightness="dark" />
      <AreaGradient gradientId={gradientIdHover} brightness="darkHover" />
      <rect
        x={svgInfo.left}
        y={svgInfo.top}
        width={svgInfo.width}
        height={svgInfo.height}
        fill="white"
        opacity={0}
        onClick={() => handleSVGClick()}
      />
      <XAxis
        bottom={svgInfo.bottom}
        left={svgInfo.left}
        width={svgInfo.width}
        height={svgInfo.svgHeight}
        scale={x}
        xLength={xValues.length}
        xKeyToDisplayName={xKeyToDisplayName}
        // hoveredBarIndex={hoveredBarIndex}
        rotateXLabel={rotateXLabel ?? false}
        xMaxLength={xMaxLength}
      />
      <YAxis top={svgInfo.top} left={svgInfo.left} width={svgInfo.width} scale={y} />
      {hasPercentShare ? (
        <YAxis
          top={svgInfo.top}
          left={svgInfo.left}
          width={svgInfo.width}
          scale={y}
          totalCounts={totalCounts}
          isSecondAxis
        />
      ) : null}
      <g transform={gTransform} className="outer-group">
        <g className="chart">{bars}</g>
      </g>
      {!!hasTooltip && !isEmpty(tooltipContent) && !isScrolling && hoveredBarIndex !== -1 ? (
        <Tooltip
          left={svgInfo.left}
          top={svgInfo.top}
          content={tooltipContent}
          scales={[x, y]}
          chartType="bar"
          chartName={chartName}
          handleTooltipMouseEnter={handleTooltipMouseEnter}
          handleTooltipMouseLeave={handleTooltipMouseLeave}
          filter={filter}
          xKeyToDisplayName={xKeyToDisplayName}
          handleClickInspect={handleClickDatum}
        />
      ) : null}
    </svg>
  );
};

export default BarChart;
