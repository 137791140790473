import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';
import { isEmpty, map, sumBy } from 'lodash';

import DonutChart from '../../charts/donutChart/DonutChart';
import { getSpbDefaultColors } from '../../charts/donutChart/donutChartColors';
import DonutLegend from '../../charts/donutChart/DonutLegend';
import { GetDonutColorFn } from '../../charts/donutChart/types';
import { SvgConfigDonutObject } from '../../config/types';
import { JsonObj } from '../../userStats/types';
import {
  convertPropertyToChartData,
  getClassLegendObject,
  getPropertyById,
  getPropertyLegendObject,
} from './helper';
import { DonutDatum, ObjectProperty } from './interface';
import { PropertyTree } from './PropertyTree';
import styles from './PropertyTree.module.scss';

const PropertyDonutChartWithLegend = (props: {
  data: JsonObj[];
  selectedClassProperties: ObjectProperty[];
  handleSelectClass: (classId: string) => void;
  selectedClassIndex: number;
  selectedClassId: string;
  xValues: string[];
  yValues: number[];
  totalCounts: number; // total label counts or object counts?
  chartName: string;
  filter: Record<string, string[] | []>;
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigDonutObject;
  getDatumColor: GetDonutColorFn;
  getPropertyCount: (classId: string) => Promise<ObjectProperty[]>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    selectedClassProperties,
    handleSelectClass,
    selectedClassIndex,
    xValues,
    chartName,
    xKeyToDisplayName,
    svgInfo,
    getDatumColor,
  } = props;
  const [hoveredClassIndex, setHoveredClassIndex] = useState<number>(0);
  const [hoveredPropertyOptionIndex, setHoveredPropertyOptionIndex] = useState<number>(-1);
  const [visualizedProperty, setVisualizedProperty] = useState<ObjectProperty>();
  const [chartData, setChartData] = useState<DonutDatum[]>();

  useEffect(() => {
    setHoveredClassIndex(selectedClassIndex > 0 ? selectedClassIndex : 0);
  }, []);

  useEffect(() => {
    if (isEmpty(selectedClassProperties)) return;
    const initProperty = selectedClassProperties[0];
    setChartData(convertPropertyToChartData(initProperty));
    setVisualizedProperty(initProperty);
  }, [selectedClassProperties]);

  const highlightClass = (i: number, status: 'in' | 'out') => {
    status === 'in' ? setHoveredClassIndex(i) : setHoveredClassIndex(selectedClassIndex);
  };

  const handleMouseOverOption = (index: number, property: ObjectProperty) => {
    setHoveredPropertyOptionIndex(index);
    handleDisplayPropertyInChart(property);
  };
  const handleMouseOutOption = (property: ObjectProperty) => {
    setHoveredPropertyOptionIndex(-1);
    if (isEmpty(selectedClassProperties)) return;
    const currentProperty = getPropertyById(selectedClassProperties, property?.id);
    if (currentProperty) handleDisplayPropertyInChart(currentProperty);
  };

  const handleDisplayPropertyInChart = (datum: ObjectProperty) => {
    const selectedProperty = getPropertyById(selectedClassProperties, datum?.id);
    setVisualizedProperty(selectedProperty);
    if (selectedProperty) setChartData(convertPropertyToChartData(selectedProperty));
  };

  // const handlePropertyChartClick = (datum: JsonObj) => {
  //   // Implement me: when label list filter supports property
  // };

  const handleClassLegendClick = async (datum: JsonObj, i: number) => {
    setHoveredClassIndex(i);
    // setHoveredClassId(datum?.classId);
    await handleSelectClass(datum?.classId);
  };

  const propObject = getPropertyLegendObject(selectedClassProperties);
  const colorMap = getSpbDefaultColors(map(chartData, d => d.id));
  const getArcColorForProperty = (data: JsonObj): string => colorMap(data.id) as string;

  const getCenterText = (params: {
    hoveredIndex: number;
    count: number;
    total: number;
    datum?: JsonObj | { name: string; id: string; count: number };
  }): JsonObj[] => {
    const { hoveredIndex, count, datum, total } = params;
    if (hoveredIndex === -1) {
      return [{ text: visualizedProperty?.name, count: total }] as JsonObj[];
    }
    return [{ text: datum?.name, count }] as JsonObj[];
  };

  return (
    <>
      <Box
        id={`${chartName}-container`}
        gap={2}
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'space-between',
          paddingBottom: '4px',
          minWidth: '0px',
          minHeight: '280px',
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '10px',
        }}
      >
        <Box
          display="flex"
          style={{
            flexDirection: 'column',
            marginLeft: '15px',
          }}
        >
          <DonutLegend
            id={`${chartName}-class-donut-legend`}
            chartObj={getClassLegendObject({ dataLength: xValues.length })}
            data={data}
            idKey={'classId'}
            countKey={'count'}
            shareKey={'percentTotal'}
            groupValues={map(data, d => d.groups)}
            getColor={getDatumColor}
            highlightSlice={highlightClass}
            handleOnClick={handleClassLegendClick}
            chartName={chartName}
            xKeyToDisplayName={xKeyToDisplayName}
            fixedTopMargin
            selectedIndex={hoveredClassIndex}
            showButton={false}
          />
        </Box>
        <Box
          display="flex"
          className={styles.scroll}
          style={{
            overflow: 'hidden',
            marginLeft: '10px',
          }}
        >
          {visualizedProperty && (
            <PropertyTree
              propObject={getPropertyLegendObject(selectedClassProperties)}
              maxHeight={300}
              maxWidth={500}
              handleMouseEnterProperty={handleDisplayPropertyInChart}
              handleMouseOverOption={handleMouseOverOption}
              handleMouseOutOption={handleMouseOutOption}
              hoveredPropertyOptionIndex={hoveredPropertyOptionIndex}
              selectedProperty={visualizedProperty}
            />
          )}
        </Box>
        <Box display="flex" style={{ marginRight: '20px' }}>
          {propObject?.numProps > 0 && (
            <DonutChart
              x={svgInfo.svgWidth / 3}
              id={`${chartName}-donut-chart`}
              yValues={map(chartData, d => d?.count)}
              data={chartData}
              totalCounts={sumBy(chartData, 'count')}
              getColor={getArcColorForProperty}
              getCenterText={getCenterText}
              highlightSlice={(index: number) => setHoveredPropertyOptionIndex(index)}
              hoveredIndex={hoveredPropertyOptionIndex}
              chartName={chartName}
              xKeyToDisplayName={xKeyToDisplayName}
              svgInfo={{ ...svgInfo, width: svgInfo.width / 2 }}
            />
          )}
          {propObject?.numProps === 0 && (
            <DonutChart
              x={svgInfo.svgWidth / 3}
              id={`${chartName}-donut-chart`}
              yValues={[]}
              data={propObject?.data as unknown as DonutDatum[]}
              totalCounts={propObject?.data.length ?? 0}
              defaultCenterText={t('analytics.project.property.noProperty')}
              getColor={() => '#F5F5F5'}
              getCenterText={getCenterText}
              hoveredIndex={hoveredPropertyOptionIndex}
              chartName={chartName}
              xKeyToDisplayName={xKeyToDisplayName}
              svgInfo={{ ...svgInfo, width: svgInfo.width / 2 }}
            />
          )}
        </Box>
      </Box>
      <div id="outer" style={{ display: 'none' }} />
    </>
  );
};

export default PropertyDonutChartWithLegend;
