import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import { ProjectDataType, useOverviewService } from '../services/OverviewService';

export type Dependencies = {
  projectDataType: ProjectDataType;
};

export function useLabelingStatusQuery({ projectDataType }: Dependencies) {
  const params = useApiDefaultParams();
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { getLabelingStatus } = useOverviewService();
  return useQuery({
    queryKey: ['overview-labeling-status', projectId, accountName],
    queryFn: () => {
      const result = getLabelingStatus({
        ...params,
        projectId,
        projectDataType,
      });
      return result;
    },
  });
}
