import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router';

import { WarningFilled } from '@superb-ai/icons';
import { Box, Icon, LinkTypography, Typography } from '@superb-ai/ui';

import { useCurateCommandContext } from '../../../../../../contexts/CommandContext';
import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { useStartOrUpdateDiagnosisMutation } from '../../../../../../queries/diagnosisModelQueries';
import DeactivateDiagnosisModal from '../../../modal/DeactivateDiagnosisModal';
import { isDiagnosisActive } from '../types';

export default function ModelsInfoBanner(): JSX.Element {
  const { selectedDiagnosis, setJobInitiatedTimestamp, jobInitiatedTimestamp } =
    useDiagnosisModelContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { datasetId } = useParams<{ datasetId: string }>();

  const commandContext = useCurateCommandContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { mutate: updateDiagnosis, isError: isUpdateDiagnosisError } =
    useStartOrUpdateDiagnosisMutation(commandContext);

  const needsUpdate =
    (selectedDiagnosis?.updatedImageCount || 0) + (selectedDiagnosis?.deletedImageCount || 0) > 0;

  const handleUpdateDiagnosis = async () => {
    if (!selectedDiagnosis) return;
    await updateDiagnosis({
      datasetId,
      diagnosisId: selectedDiagnosis.id,
      modelId: selectedDiagnosis.modelId,
      modelSource: selectedDiagnosis.modelSource,
      jobName: 'UPDATE_DIAGNOSIS',
      annotationType: selectedDiagnosis.iouType === 'bbox' ? 'box' : 'polygon',
      createdAt: selectedDiagnosis.createdAt,
    });

    if (!isUpdateDiagnosisError) {
      setJobInitiatedTimestamp({ jobType: 'UPDATE_DIAGNOSIS', timeStamp: new Date().getTime() });
    }
  };

  if (!selectedDiagnosis) return <></>;
  if (jobInitiatedTimestamp || !isDiagnosisActive(selectedDiagnosis.status)) return <></>;

  if (!selectedDiagnosis.modelName)
    return (
      <Box
        backgroundColor="yellow-100"
        color="yellow-500"
        py={1}
        px={4}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Icon icon={WarningFilled} />
          <Typography variant="m-regular">
            <Trans i18nKey={'curate.diagnosis.banner.deletedModel'}>
              ...<LinkTypography onClick={() => setIsModalOpen(true)}>delete</LinkTypography>...
            </Trans>
          </Typography>
        </Box>
        {isModalOpen && (
          <DeactivateDiagnosisModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            diagnosis={selectedDiagnosis}
          />
        )}
      </Box>
    );

  if (!showPublicDatasets && needsUpdate) {
    return (
      <Box
        backgroundColor="yellow-100"
        color="yellow-500"
        py={1}
        px={4}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Icon icon={WarningFilled} />
          <Typography variant="m-regular">
            <Trans i18nKey={'curate.diagnosis.banner.updateModel'}>
              ...<LinkTypography onClick={handleUpdateDiagnosis}>update</LinkTypography>...
            </Trans>
          </Typography>
        </Box>
      </Box>
    );
  }

  return <></>;
}
