import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeHandlerFunc } from 'react-mentions';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MentionItemType } from '@superb-ai/norwegian-forest';
import { trim } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import Mentions from '../../../../elements/mentions/Mentions';
import { CommentProps, ThreadProps } from './types';

const useStyles = makeStyles({
  commentBody: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
    minHeight: '26px',
    marginRight: '-16px',
  },
  textField: {
    transition: '0.3s',
    '&.error': {
      background: '#ffcc00',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  commentButton: {
    width: '60px',
    height: '26px',
    padding: '0px',
    '& .MuiButton-label': {
      color: '#eb5757',
      fontSize: '10px',
    },
  },
});

type Props = ThreadProps['issue'] &
  Pick<CommentProps, 'index' | 'comment' | 'indexOfEditingComment' | 'setIndexOfEditingComment'>;

const CommentBody = ({
  issue,
  index,
  comment,
  indexOfEditingComment,
  setIndexOfEditingComment,
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const { label } = labelDetailViewInfo;
  const { setIssues } = labelIssuesInfo;
  const [inputValue, setInputValue] = useState('');
  const [backupInputValue, setBackupInputValue] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [addedMentions, setAddedMentions] = useState<string[]>([]);
  const [isOverMaxLength, setIsOverMaxLength] = useState(false);

  const isEdit = index === indexOfEditingComment;

  useEffect(() => {
    if (!isOverMaxLength) return;

    setTimeout(() => {
      setIsOverMaxLength(false);
    }, 300);
  }, [isOverMaxLength]);

  useEffect(() => {
    setInputValue(comment.message);
    setBackupInputValue(comment.message);
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (indexOfEditingComment === null) return;

      if (isEdit && inputRef.current) {
        inputRef.current.focus();
      } else {
        setInputValue(backupInputValue);
      }
    },
    // eslint-disable-next-line
    [indexOfEditingComment],
  );

  const handleChangeMentionInput: OnChangeHandlerFunc = (
    event,
    newValue,
    newPlainTextValue,
    mentions,
  ) => {
    const lastChar = newPlainTextValue[newPlainTextValue.length - 1];
    if (lastChar === '\n') {
      if (newPlainTextValue.length === 1) return;
    }

    if (newPlainTextValue.length >= 1000) {
      setInputValue(newPlainTextValue.slice(0, 1000));
      if (!isOverMaxLength) setIsOverMaxLength(true);
      return;
    }
    setInputValue(newValue);
    setAddedMentions(mentions.map((mention: MentionItemType) => mention.id));
  };

  const handleClickEditComment = async () => {
    const nextInputValue = trim(inputValue);
    if (nextInputValue === '') {
      setIndexOfEditingComment(null);
      setInputValue(backupInputValue);
      return;
    }

    try {
      setIsProcessing(true);

      await IssuesService.updateComment({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        issueId: issue.id,
        commentId: comment.id,
        info: {
          message: nextInputValue,
          mentioned: addedMentions,
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setInputValue(nextInputValue);
      setBackupInputValue(nextInputValue);
      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
      setIsProcessing(false);
      setIndexOfEditingComment(null);
    } catch (err: any) {
      setIsProcessing(false);
      setIndexOfEditingComment(null);
      throw err;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key, shiftKey } = event;
    event.stopPropagation();

    switch (key) {
      case 'Enter':
        if (!shiftKey) handleClickEditComment();
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const { key } = event;
    event.stopPropagation();

    switch (key) {
      case 'Escape':
        setIndexOfEditingComment(null);
        setInputValue(backupInputValue);
        break;
      default:
        break;
    }
  };

  const handleFocusInput = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };

  return (
    <MUI.Box className={`${classes.commentBody}`}>
      <Mentions
        boxProps={{ width: isEdit ? 'calc(100% - 60px)' : '90%' }}
        className={`${classes.textField}  ${isOverMaxLength && 'error'}`}
        isEdit={isEdit}
        inputRef={inputRef}
        disabled={!isEdit}
        onChange={handleChangeMentionInput}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onFocus={handleFocusInput}
        value={inputValue}
      />
      {isEdit && (
        <MUI.Box className={classes.buttonBox}>
          <MUI.Button className={classes.commentButton} onClick={handleClickEditComment}>
            POST
          </MUI.Button>
        </MUI.Box>
      )}
      {isProcessing && <CircularProgressDialog isLoading={isProcessing} />}
    </MUI.Box>
  );
};

export default CommentBody;
