import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import emptyImage from '../../assets/img/empty_graphic.png';

type Props = {
  titleKey: string;
  explanationKey: string;
  explanationValues?: Record<string, string>;
} & ComponentProps<typeof Box>;

export function NoResultsFound({
  titleKey,
  explanationKey,
  explanationValues,
  ...boxProps
}: Props) {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2} {...boxProps}>
      <Image width={86} src={emptyImage} alt="emptyImage" />
      <Box mt={1}>
        <Typography variant="h2">
          <Trans t={t} i18nKey={titleKey} />
        </Typography>
      </Box>
      <Box
        mt={0.5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="m-regular" color={'gray-300'} style={{ textAlign: 'center' }}>
          <Trans t={t} i18nKey={explanationKey} values={explanationValues} />
        </Typography>
      </Box>
    </Box>
  );
}
