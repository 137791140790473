import * as React from 'react';
import {
  Directory,
  FileEntry,
  FileOrDir,
  FileTree as RAFileTree,
  FileType,
  IFileTreeHandle,
  IItemRendererProps,
  ItemType,
  Root,
  TreeModel,
  WatchEvent,
} from 'react-aspen';

import { Decoration, TargetMatchMode } from 'aspen-decorations';

import { KeyboardHotkeys } from './keyboardHotkeys';
import { FileTreeModel } from './model';
import getCss from './styles';
import TreeItem from './TreeItem';
import { IFileTreeXHandle, IFileTreeXProps } from './types';

interface ScollbarConfig {
  width: number;
  outerWidth?: number;
  foreground?: string;
  background?: string;
  onHover?: boolean;
}

function getScrollbarStyles({
  width,
  outerWidth = width * 2,
  foreground = 'rgba(0, 0, 0, 0.35)',
  background = 'rgba(0, 0, 0, 0.075)',
  onHover = false,
}: ScollbarConfig) {
  const margin = `${(outerWidth - width) / 2}px`;
  const trackSize = `${outerWidth}px`;
  const hover = !onHover
    ? {}
    : {
        boxShadow: 'none',
      };
  return {
    scrollbarColor: `${foreground} transparent`, // fallback
    scrollbarWidth: 'thin', // fallback
    scrollbarGutter: 'stable',
    '&::-webkit-scrollbar': {
      width: trackSize,
      height: trackSize,
    },
    '&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb': {
      borderRadius: trackSize,
      border: `solid ${margin} transparent`,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 ${margin} ${margin} ${background}`,
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 ${margin} ${margin} ${foreground}`,
    },
    '&:not(:hover)::-webkit-scrollbar-thumb': hover,
  } as const;
}

/**
 * Return the first ancestor that is targeted by decoration
 */
function getParentTarget(fileOrDir: FileOrDir, decoration: Decoration): FileOrDir | undefined {
  if (decoration.appliedTargets.get(fileOrDir)) return fileOrDir;
  if (decoration.negatedTargets.get(fileOrDir)) return undefined;
  return fileOrDir.parent ? getParentTarget(fileOrDir.parent, decoration) : undefined;
}

function* iterateTree(node: Directory): Generator<FileOrDir> {
  yield node;
  if ((node as Directory).children) {
    for (const n of node.children) {
      yield* iterateTree(n as Directory);
    }
  }
}

function getActiveFilesCascade(model: FileTreeModel) {
  const activeFiles: FileOrDir[] = [];
  for (const item of iterateTree(model.root)) {
    const decorations = model.decorations.getDecorations(item);
    if (decorations.classlist.indexOf('active') !== -1) {
      activeFiles.push(item);
    }
  }
  return [...new Set(activeFiles)];
}

abstract class Tree extends React.Component<IFileTreeXProps> {
  static TreeItemComponent: typeof TreeItem;

  protected fileTreeHandle!: IFileTreeXHandle;
  protected activeFileDec: Decoration;
  protected mixedActiveDec: Decoration;
  protected loadingDirDec: Decoration;
  protected hiddenDec: Decoration;
  protected disabledDec: Decoration;
  protected activeFile: FileOrDir | null = null;
  protected activeFiles: FileOrDir[] = [];
  protected wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  protected keyboardHotkeys!: KeyboardHotkeys;
  protected disposables: any = [];

  constructor(props: IFileTreeXProps) {
    super(props);
    this.activeFileDec = new Decoration('active');
    this.loadingDirDec = new Decoration('loading');
    this.hiddenDec = new Decoration('hidden');
    this.disabledDec = new Decoration('disabled');
    this.mixedActiveDec = new Decoration('mixed');
  }

  componentWillUnmount(): void {
    const { model } = this.props;
    model.decorations.removeDecoration(this.activeFileDec);
    model.decorations.removeDecoration(this.loadingDirDec);
    model.decorations.removeDecoration(this.hiddenDec);
    model.decorations.removeDecoration(this.disabledDec);
    model.decorations.removeDecoration(this.mixedActiveDec);
    this.disposables.forEach((disposable: any) => {
      disposable.dispose();
    });
  }

  protected handleTreeReady = (handle: IFileTreeHandle): void => {
    const { onReady, model } = this.props;

    this.fileTreeHandle = {
      ...handle,
      getModel: () => this.props.model,
      getActiveFile: () => this.activeFile,
      setActiveFile: this.setActiveFile,
      getActiveFiles: (withCascade = false) => {
        // Return only explicitly selected items
        if (!withCascade) return this.activeFiles;
        // Return selected items with all their non-negated children
        return getActiveFilesCascade(model);
      },
      setActiveFiles: this.setActiveFiles,
      toggleDirectory: this.toggleDirectory,
      hasDirectFocus: () => this.wrapperRef.current === document.activeElement,
      resetActiveFiles: this.resetActiveFiles,
      setDisabledFiles: this.setDisabledFiles,
    };

    model.decorations.addDecoration(this.activeFileDec);
    model.decorations.addDecoration(this.loadingDirDec);
    model.decorations.addDecoration(this.hiddenDec);
    model.decorations.addDecoration(this.disabledDec);
    model.decorations.addDecoration(this.mixedActiveDec);

    this.disposables.push(
      this.fileTreeHandle.onDidChangeModel((prevModel: TreeModel, newModel: TreeModel) => {
        this.setActiveFile(null);
        (prevModel as FileTreeModel).decorations.removeDecoration(this.activeFileDec);
        (prevModel as FileTreeModel).decorations.removeDecoration(this.loadingDirDec);
        (prevModel as FileTreeModel).decorations.removeDecoration(this.hiddenDec);
        (prevModel as FileTreeModel).decorations.removeDecoration(this.disabledDec);
        (prevModel as FileTreeModel).decorations.removeDecoration(this.mixedActiveDec);
        (newModel as FileTreeModel).decorations.addDecoration(this.activeFileDec);
        (newModel as FileTreeModel).decorations.addDecoration(this.loadingDirDec);
        (newModel as FileTreeModel).decorations.addDecoration(this.hiddenDec);
        (newModel as FileTreeModel).decorations.addDecoration(this.disabledDec);
        (newModel as FileTreeModel).decorations.addDecoration(this.mixedActiveDec);
        (newModel as FileTreeModel).root.onDidChangeDirExpansionState((directory, expanded) => {
          if (expanded) {
            this.loadingDirDec.removeTarget(directory);
          }
        });
        setTimeout(() => {
          this.maybeExpandDirectories();
        });
      }),
    );

    this.disposables.push(
      this.fileTreeHandle.getModel().root.onDidChangeDirExpansionState((directory, expanded) => {
        if (expanded) {
          this.loadingDirDec.removeTarget(directory);
        }
      }),
    );

    this.keyboardHotkeys = new KeyboardHotkeys(this.fileTreeHandle, {
      selectableTypes: this.props.selectableTypes,
    });

    setTimeout(() => {
      this.maybeExpandDirectories();
    });

    if (typeof onReady === 'function') {
      onReady(this.fileTreeHandle);
    }
  };

  /**
   * Recursively expand all sub directories.
   * Similar to https://github.com/NeekSandhu/react-aspen/issues/2
   * @param node start directory
   * @param recurse expand sub-directories
   */
  protected expandChildren = async (node: Directory | Root, recurse = false): Promise<void> => {
    if (!node || !(node instanceof Directory) || !node.children) return;
    await Promise.all(
      node.children.map((child): Promise<any> => {
        if (child.type !== FileType.Directory || (child as Directory).expanded) {
          return Promise.resolve();
        }
        const dir = child as Directory;
        this.loadingDirDec.addTarget(dir, TargetMatchMode.Self);
        // Expand with visible=false
        return this.props.model.root.expandDirectory(dir, false).then(async () => {
          if (recurse) await this.expandChildren(dir, true);
        });
      }),
    );
    // Expand with visible=true
    if (node === this.props.model.root) {
      this.props.model.root.expandDirectory(node, true);
    }
  };

  protected maybeExpandDirectories = async (): Promise<void> => {
    if (!this.props.expandTopLevel && !this.props.expandAll) return;
    this.expandChildren(this.props.model.root, this.props.expandAll);
  };

  protected resetActiveFiles = (): void => {
    for (const [targetedFileH] of this.activeFileDec.appliedTargets.entries()) {
      this.activeFileDec.removeTarget(targetedFileH);
    }
    for (const [targetedFileH] of this.activeFileDec.negatedTargets.entries()) {
      this.activeFileDec.unNegateTarget(targetedFileH);
    }
    this.activeFile = null;
    this.activeFiles = [];
    this.props.onSelect?.(null);
  };

  protected setActiveFile = async (fileOrDirOrPath: FileOrDir | string | null): Promise<void> => {
    const { selectMultiple = false } = this.props;
    const fileH =
      typeof fileOrDirOrPath === 'string'
        ? await this.fileTreeHandle.getFileHandle(fileOrDirOrPath)
        : fileOrDirOrPath;
    const isDirectorySelectable =
      typeof this.props.selectableTypes === 'undefined' ||
      this.props.selectableTypes.indexOf(FileType.Directory) !== -1;

    if (fileH === this.props.model.root) return;
    if (!selectMultiple) {
      for (const [targetedFileH] of this.activeFileDec.appliedTargets.entries()) {
        this.activeFileDec.removeTarget(targetedFileH);
      }
      if (fileH) {
        this.activeFileDec.addTarget(fileH as any, TargetMatchMode.Self);
        this.activeFile = fileH;
        this.activeFiles = [fileH];
      }
    } else if (fileH) {
      const removeTarget = (item: FileOrDir) => {
        this.activeFileDec.removeTarget(item);
        this.mixedActiveDec.removeTarget(item);
        this.activeFiles = this.activeFiles.filter(f => f != item);
      };
      // cases for multiple selection:
      // A) no parent is a target or a parent is negated -> addTarget / removeTarget
      //     if directory and not a target: -> recursively removeTarget and unNegateTarget all children
      // B) any parent is a non-negated target -> negateTarget / unnegateTarget
      const isTarget = !!this.activeFileDec.appliedTargets.get(fileH);
      const isNegated = !!this.activeFileDec.negatedTargets.get(fileH);

      if (!isTarget && fileH.type === FileType.Directory) {
        // If directory, recursively removeTarget/unNegateTarget for all targeted children
        for (const item of iterateTree(fileH as Directory)) {
          const isTarget = this.activeFileDec.appliedTargets.get(item);
          const isNegated = this.activeFileDec.negatedTargets.get(item);
          const isDisabled = this.disabledDec.appliedTargets.get(item);
          if (isTarget) {
            removeTarget(item);
          }
          if (isNegated && !isDisabled) {
            this.activeFileDec.unNegateTarget(item);
          }
        }
      }
      if (isTarget) {
        removeTarget(fileH);
      }
      if (isNegated) {
        this.activeFileDec.unNegateTarget(fileH);
        // check if parent target has become complete (no negated children) now
        const parentTarget = getParentTarget(fileH, this.activeFileDec);
        if (parentTarget) {
          let parentHasNegatedChildren = false;
          for (const item of iterateTree(parentTarget as Directory)) {
            if (item === parentTarget || item === fileH) continue;
            const isNegated = this.activeFileDec.negatedTargets.get(item);
            if (isNegated) {
              parentHasNegatedChildren = true;
              break;
            }
          }
          if (!parentHasNegatedChildren) {
            this.mixedActiveDec.removeTarget(parentTarget);
          }
        }
      }
      if (!isTarget && !isNegated) {
        const parentTarget = getParentTarget(fileH, this.activeFileDec);
        if (parentTarget) {
          this.activeFileDec.negateTarget(fileH as any, TargetMatchMode.SelfAndChildren);
          // check if parent target has become empty (no non-negated children) now
          let parentHasAllNegatedChilden = true;
          for (const item of iterateTree(parentTarget as Directory)) {
            if (item === parentTarget || item === fileH) continue;
            const isNegated = this.activeFileDec.negatedTargets.get(item);
            if (!isNegated) {
              parentHasAllNegatedChilden = false;
              break;
            }
          }
          if (parentHasAllNegatedChilden) {
            this.mixedActiveDec.removeTarget(fileH);
            removeTarget(parentTarget);
            for (const item of iterateTree(parentTarget as Directory)) {
              this.activeFileDec.unNegateTarget(item);
            }
          }
          // else {
          //   this.mixedActiveDec.addTarget(parentTarget);
          // }
        } else {
          // has no active parent target
          let allChildrenSelected = true;
          let someChildrenSelected = false;
          if (isDirectorySelectable) {
            // check if immediate parent is complete (all active children)
            for (const item of [...iterateTree(fileH.parent)].filter(item =>
              fileH.parent.children.includes(item),
            )) {
              if (item === fileH.parent || item === fileH) continue;
              const isTarget = this.activeFileDec.appliedTargets.get(item);
              const isNegated = this.activeFileDec.negatedTargets.get(item);
              if (!isTarget || isNegated) {
                allChildrenSelected = false;
              } else {
                someChildrenSelected = true;
                if (!allChildrenSelected && someChildrenSelected) break;
              }
            }
          } else {
            // don't select the parent if directories aren't selectable
            allChildrenSelected = false;
          }
          if (allChildrenSelected) {
            this.setActiveFile(fileH.parent);
          } else {
            if (!someChildrenSelected) {
              this.mixedActiveDec.removeTarget(fileH as any);
            }
            this.activeFileDec.addTarget(fileH as any, TargetMatchMode.SelfAndChildren);
            this.activeFiles.push(fileH);
            this.activeFile = fileH;
          }
        }
      }
    }
    if (this.props.onSelect) {
      // N.B.: If this callback causes the tree to update, you need to delay the
      // update by at least 150ms to give the internal model time to apply the changes.
      this.props.onSelect(fileH ? fileH.path : null);
    }
    if (fileH) {
      await this.fileTreeHandle.ensureVisible(fileH);
    }
  };

  protected setActiveFiles = async (
    fileOrDirOrPaths: (FileOrDir | string | null)[],
  ): Promise<void> => {
    for (const file of fileOrDirOrPaths) {
      await this.setActiveFile(file);
    }
  };

  protected setDisabledFiles = async (
    fileOrDirOrPaths: (FileOrDir | string | null)[],
  ): Promise<void> => {
    for (const [targetedFileH] of this.disabledDec.appliedTargets.entries()) {
      this.disabledDec.removeTarget(targetedFileH);
    }
    for (const fileOrDirOrPath of fileOrDirOrPaths) {
      const fileH =
        typeof fileOrDirOrPath === 'string'
          ? await this.fileTreeHandle.getFileHandle(fileOrDirOrPath)
          : fileOrDirOrPath;
      if (fileH) {
        this.disabledDec.addTarget(fileH as any, TargetMatchMode.SelfAndChildren);
        this.activeFileDec.negateTarget(fileH as any, TargetMatchMode.SelfAndChildren);
      }
    }
  };

  protected toggleDirectory = async (pathOrDir: string | Directory): Promise<void> => {
    const item =
      typeof pathOrDir === 'string'
        ? await this.fileTreeHandle.getFileHandle(pathOrDir)
        : pathOrDir;
    if (item.type !== FileType.Directory) return;
    const dir = item as Directory;
    if (dir.expanded) {
      this.fileTreeHandle.closeDirectory(dir);
    } else {
      this.loadingDirDec.addTarget(dir, TargetMatchMode.Self);
      this.fileTreeHandle.openDirectory(dir);
    }
  };

  protected handleItemClicked = (item: FileOrDir, type: ItemType): void => {
    if (!FileType[type]) return;
    const fileType = type as unknown as FileType;
    if (!this.props.selectableTypes || this.props.selectableTypes.indexOf(fileType) !== -1) {
      this.setActiveFile(item as FileEntry);
    } else {
      if (type === ItemType.Directory) {
        setTimeout(() => {
          this.toggleDirectory(item as Directory);
        });
      }
    }
  };

  protected handleDirectoryToggleClicked = (item: FileOrDir, type: ItemType): void => {
    if (!FileType[type]) return;

    if (type === ItemType.Directory) {
      setTimeout(() => {
        this.toggleDirectory(item as Directory);
      });
    }
  };

  protected handleDeleteItem = ({ path }: FileOrDir): void => {
    this.props.model.root.inotify({
      type: WatchEvent.Removed,
      path,
    });
    if (this.props.onDelete) {
      // N.B.: If this callback causes the tree to update, you need to delay the
      // update by at least 150ms to give the internal model time to apply the deletion.
      this.props.onDelete(path);
    }
  };

  protected handleKeyDown = (ev: React.KeyboardEvent): boolean | null => {
    return this.keyboardHotkeys.handleKeyDown(ev);
  };

  protected isItemDeletable(item: FileOrDir): boolean {
    return (
      typeof this.props.deletableTypes !== 'undefined' &&
      this.props.deletableTypes.indexOf(item.type) !== -1
    );
  }

  protected isItemSelectable(item: FileOrDir): boolean {
    return (
      typeof this.props.selectableTypes === 'undefined' ||
      this.props.selectableTypes.indexOf(item.type) !== -1
    );
  }

  protected isItemVisibilityToggle(item: FileOrDir): boolean {
    return (
      typeof this.props.visibilityChangeTypes === 'undefined' ||
      this.props.visibilityChangeTypes.indexOf(item.type) !== -1
    );
  }

  protected toggleVisibility = (item: FileOrDir): void => {
    let nextVisible = false;
    const parentTarget = getParentTarget(item.parent, this.hiddenDec);
    if (parentTarget) {
      // Just ignore when parent is invisible already.
      // We could choose to negate/unnegate like for 'active'
      // but seems not necessary for visibility
      return;
    }
    if (this.hiddenDec.appliedTargets.has(item)) {
      this.hiddenDec.removeTarget(item);
      nextVisible = true;
    } else {
      this.hiddenDec.addTarget(item as Directory, TargetMatchMode.SelfAndChildren);
      nextVisible = false;
    }
    if (this.props.onVisibilityChange) {
      this.props.onVisibilityChange(item.path, nextVisible);
    }
  };

  render(): JSX.Element {
    const { height, width, model, selectMultiple = false, showIcons = true } = this.props;
    const { decorations } = model;
    const ConcreteTreeClass = Object.getPrototypeOf(this).constructor as typeof Tree;
    const TreeItemComponent = ConcreteTreeClass.TreeItemComponent;

    return (
      <div
        role="listbox"
        onKeyDown={this.handleKeyDown}
        ref={this.wrapperRef}
        tabIndex={0}
        css={getCss()}
      >
        {/* @ts-ignore */}
        <RAFileTree
          height={height}
          width={width}
          model={model}
          itemHeight={TreeItemComponent.RenderHeight}
          onReady={this.handleTreeReady}
          css={getScrollbarStyles({ width: 4, outerWidth: 14 })}
        >
          {(props: IItemRendererProps) => {
            const isSelectable = this.isItemSelectable(props.item as FileEntry);
            return (
              // @ts-ignore
              <TreeItemComponent
                item={props.item as any}
                itemType={props.itemType}
                decorations={decorations.getDecorations(props.item as any)}
                onClick={this.handleItemClicked}
                onClickDirectoryToggle={this.handleDirectoryToggleClicked}
                isSelectable={isSelectable}
                isDeletable={this.isItemDeletable(props.item as FileEntry)}
                onDelete={this.handleDeleteItem}
                isVisibilityToggle={this.isItemVisibilityToggle(props.item as FileEntry)}
                toggleVisibility={this.toggleVisibility}
                showCheckbox={selectMultiple && isSelectable}
                showIcon={showIcons}
              />
            );
          }}
        </RAFileTree>
      </div>
    );
  }
}

export default Tree;
