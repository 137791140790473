import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { Select } from '@superb-ai/norwegian-forest';

import { Option, PageAdditional } from '../../../../../../../types/selectTypes';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useCurateDatasetService } from '../../../../../services/DatasetService';

export default function DiagnosisAddSliceModalSelectSlice({
  selectedSlice,
  setSelectedSlice,
}: {
  selectedSlice?: SingleValue<Option>;
  setSelectedSlice: Dispatch<SetStateAction<SingleValue<Option> | undefined>>;
}): JSX.Element {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { t } = useTranslation();
  const { getSliceList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const handleLoadSlice: LoadOptions<Option, GroupBase<Option>, PageAdditional> = async (
    search,
    _prevOptions,
    additional,
  ) => {
    const page = additional?.page || 1;
    const pageSize = 10;

    const sliceList = await getSliceList({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      name_contains: search,
      page,
    });
    const result = sliceList.results.map(result => ({
      label: result.name,
      value: result.id,
    }));

    const hasMore = Math.ceil(sliceList.count / pageSize) > page;

    return {
      options: result,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Select.Async
      isClearable
      blurInputOnSelect
      placeholder={t('curate.slices.placeholder.selectSlice')}
      loadOptions={handleLoadSlice}
      onChange={setSelectedSlice}
      value={selectedSlice}
      isMulti={false}
    />
  );
}
