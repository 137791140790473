import React, { CSSProperties } from 'react';

import { upperFirst } from 'lodash';

import { FORMAT_COUNT } from '../../config/d3Formatters';
import { getDisplayNameFromKey } from '../../tools/helper';
import { JsonObj } from '../../userStats/types';

/**
 * @param {array} displayContent Display content (ex. Total count)
 */
const DonutCenterTextTemplate = (
  displayContent: JsonObj[],
  radius: number,
  chartName: string,
  xKeyToDisplayName?: Record<string, string>,
): SVGTextElement => {
  const TEXT_STYLE: CSSProperties = {
    textAnchor: 'middle',
    fontWeight: 500,
    fontSize: `${radius / 7}px`,
    fill: '#333333',
  };
  const content = displayContent[0];
  return (
    <text className={`donut-center-text-${chartName}`} style={TEXT_STYLE}>
      <tspan key="donut-chart-center-text" x="0" fontSize="15px" dy="-1.5em">
        {upperFirst(getDisplayNameFromKey(content?.text, xKeyToDisplayName))}
      </tspan>
      <tspan key="donut-chart-center-count" x="0" fontSize="36px" dy="1.3em">
        {FORMAT_COUNT(content?.count)}
      </tspan>
    </text>
  ) as unknown as SVGTextElement;
};

export default DonutCenterTextTemplate;
