import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { labelTag } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterWithSearch from './components/FilterWithSearch';
import { FilterTypeProps } from './types';

const useStyles = makeStyles(() => ({
  select: {
    '& .AutoSelect__control': {
      width: '500px',
    },
  },
}));

const FilterTag: React.FC<FilterTypeProps> = props => {
  const classes = useStyles();

  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  const projectInfo = useProjectInfo();

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={labelTag.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      {filter.condition !== 'is empty' && filter.condition !== 'is not empty' && (
        <>
          <MUI.Grid item style={{ flex: 1 }}>
            <FilterWithSearch
              className={classes.select}
              index={index}
              objKey="options"
              filterBy={filter.filterBy}
              options={projectInfo.tagOptions}
              value={filter.options}
              onValueChange={onValueChange}
              isCurrentRow={isCurrentRow}
              onChangeCurrentRow={onChangeCurrentRow}
            />
          </MUI.Grid>
        </>
      )}
    </>
  );
};

export default FilterTag;
