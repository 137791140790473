import { PerformanceCurveDatum } from '../types';

export function findMetricAtConfidence(
  data: PerformanceCurveDatum[] | undefined,
  metric: keyof PerformanceCurveDatum,
  confidence: number | undefined,
) {
  if (!confidence || !data) return;
  const datumIndex = data.findIndex(d => d.confidence === confidence);
  return data[datumIndex]?.[metric];
}

export function isLowerThanThreshold(value: number | undefined, threshold: number | undefined) {
  return value !== undefined && threshold !== undefined && value < threshold;
}
