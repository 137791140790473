import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Badge, Box, Icon, Typography } from '@superb-ai/norwegian-forest';
import Image from 'next/image';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { getUrl } from '../../../../../routes/util';
import Howto from '../../cards/Howto';
import Step from '../../cards/Step';
import TutorialCard from '../../cards/TutorialCard';
import tutorialImage from './tutorial.png';

const stepsUrls = ['auto-label/settings#mapping', 'labels', 'labels'];

export default function Tutorial() {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const projectInfo = useProjectInfo();
  const history = useHistory();
  function goTo(path: string) {
    const url = getUrl([accountName, 'label', 'project', projectInfo.project.id, path]);
    history.push(url);
  }

  return (
    <TutorialCard
      title={
        <Typography variant="body2" textAlign="center">
          {t('autoLabel.settings.guide.description')}
        </Typography>
      }
      image={
        <Image
          style={{ maxWidth: '100%', height: 'auto' }}
          src={tutorialImage}
          alt="Illustration showing a robot holding the hand of a person"
        />
      }
    >
      <Howto title={t('autoLabel.settings.guide.title')}>
        {stepsUrls.map((path, index) => (
          <Step
            key={index}
            number={index + 1}
            text={
              <Typography variant="body3">
                <Trans t={t} i18nKey={`autoLabel.settings.guide.items.${index}`} />
              </Typography>
            }
            onClick={() => goTo(path)}
          />
        ))}
        <Box p={3} bt display="flex" flexDirection="column" alignItems="flex-start" gap="10px">
          <Badge color="cobalt" size="s">
            <Icon name="autoLabel" />
          </Badge>
          <Typography variant="headline6">{t('autoLabel.settings.guide.calTitle')}</Typography>
          <Typography variant="body3">{t('autoLabel.settings.guide.calText')}</Typography>
        </Box>
      </Howto>
    </TutorialCard>
  );
}
