import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';
import { useQuery } from '@tanstack/react-query';
import { isEmpty, map } from 'lodash';

import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useApiDefaultParams } from '../../../hooks/ApiParamsHook';
import { FIVE_MINUTES } from '../../../queries';
import {
  getAssetGroupIn,
  useProjectProgressMutation,
} from '../../../queries/useProjectAnalyticsQuery';
import AnalyticsService from '../../../services/AnalyticsService';
import WorkappUnion from '../../../union/WorkappUnion';
import LabelInterfaceUtils, { DataType, ObjectClass } from '../../../utils/LabelInterfaceUtils';
import ProjectUtils from '../../../utils/ProjectUtils';
import CategoryCountChart from './chartContainers/CategoryCountChart';
import ChartContainer from './chartContainers/ChartContainer';
import { StatsHistogramAPIResponse } from './chartContainers/labelingTime/interface';
import LegacyCategoryCountChart from './chartContainers/LegacyCategoryCountChart';
import ObjectCountChart from './chartContainers/ObjectCountChart';
import {
  combinePropSettingWithData,
  getPropertyTree,
} from './chartContainers/objectPropertyChart/helper';
import { ObjectProperty } from './chartContainers/objectPropertyChart/interface';
import ProjectProgressChart from './chartContainers/ProjectProgressChart';
import { RefreshPageBanner } from './chartContainers/RefreshPageBanner';
import { CHART } from './config/plotConfig';
import * as categoryHelper from './dataTransformers/CategorySummaryTransformer';
import * as legacyCategoryHelper from './dataTransformers/CategorySummaryTransformers2011';
import * as objectHelper from './dataTransformers/ObjectSummaryTransformer';
import progressHelper from './dataTransformers/ProjectSummaryTransformer';
// Data transformers & types
import { LabelingProgressDatum, SortOption } from './dataTypes/analyticsDataTypes';
import MiniCard from './elements/miniCards/MiniCard';
import PercentCard from './elements/miniCards/PercentCard';
// Charts & Cards
import TotalCard from './elements/miniCards/TotalCard';
import ProjectAnalyticsFilter from './elements/ProjectAnalyticsFilter';
import { ObjectCountResult } from './interfaces/apiResponse';
import { BaseDatum, JsonObj } from './userStats/types';
import { checkIfCategoryNameIsUndefined, checkIfClassNameIsUndefined } from './utils/projectUtils';

const ProjectTab: React.FC = () => {
  const projectInfo = useProjectInfo();
  const {
    project: { labelCount, labelInterface, workapp },
  } = projectInfo;
  const projectId = projectInfo.project.id;
  const analyticsProxy = useFeatureFlag('analyticsProxy');
  const { t } = useTranslation();

  const dataType: DataType = labelInterface?.type ? labelInterface?.type.split('-')[0] : 'image';
  const isSiestaWorkapp = WorkappUnion.isSiesta(workapp);
  const objectSettings = LabelInterfaceUtils.getObjectClasses(labelInterface) as ObjectClass[];
  const hasObjects = !isEmpty(objectSettings);
  const hasCategories =
    LabelInterfaceUtils.hasCategory(labelInterface) ||
    LabelInterfaceUtils.hasCategoryLegacy(labelInterface);

  const categoryHash =
    labelInterface?.categorization?.properties || labelInterface?.categorization?.wordMap;

  const categoryPropertyIdToInfo =
    hasCategories && isSiestaWorkapp
      ? LabelInterfaceUtils.getCategoryPropertyInfo(labelInterface)
      : {};

  const [totalSubmitted, setTotalSubmitted] = useState(0);
  const [countField, setCountField] = useState<'count' | 'annotationCount'>('count');

  /** chart data */
  const [projectProgress, setProjectProgress] = useState<LabelingProgressDatum[]>([]);
  const [isLoadingProjectProgress, setIsLoadingProjectProgress] = useState<boolean>(true);
  const [objectClassStats, setObjectClassStats] = useState<ObjectCountResult[]>([]);
  const [imageCategoryStats, setImageCategoryStats] = useState<BaseDatum[]>([]);
  const [avgTimePerLabel, setAvgTimePerLabel] = useState<number>(0);
  const [labelingTimeStats, setLabelingTimeStat] = useState<StatsHistogramAPIResponse>();
  const [labelingTimeTable, setLabelingTimeTable] = useState<JsonObj[]>([]);
  const [labelingTimeThreshold, setLabelingTimeThreshold] = useState<number>(0);
  const [filteredTotalLabelCount, setFilteredTotalLabelCount] = useState<number>(0);
  const [objectCountUpdateTime, setObjectCountUpdateTime] = useState<Date>();
  // FEATURE: implement today button
  const [topLabelsToday, setTopLabelsToday] = useState<boolean>(false);

  /** category info */
  const categorySortX = CHART.imageCategoryStats.sortX as SortOption;
  const categorySortY = CHART.imageCategoryStats.sortY as SortOption;
  const [selectedDatasets, setSelectedDatasets] = useState<string[] | []>([]);

  useEffect(() => {
    const fetchData = async () => {
      await refetchAll();
    };
    fetchData();
  }, []);

  const defaultApiArgs = useApiDefaultParams({
    t,
    projectId,
    origin: 'components/pages/analytics/AnalyticsProjectTab.tsx',
  });

  const onSetViewMode = useCallback(
    viewMode => setCountField(viewMode === 'frame' ? 'annotationCount' : 'count'),
    [],
  );

  const projectProgressMutation = useProjectProgressMutation(defaultApiArgs);

  const projectProgressQuery = useQuery(
    ['projectProgress', selectedDatasets],
    () =>
      AnalyticsService.getLabelingProgressSummary({
        ...defaultApiArgs,
        groupByDataset: Boolean(selectedDatasets.length),
        summaryType: 'progress-summaries',
      }),
    {
      cacheTime: 0,
    },
  );

  const objectCountQuery = useQuery(
    ['objectCount', selectedDatasets, projectId],
    () =>
      AnalyticsService.getAnnotationCounts({
        ...defaultApiArgs,
        summaryType: 'object-summaries',
        apiParams: {
          projectDataType: labelInterface?.dataType,
          ...getAssetGroupIn(selectedDatasets),
        },
      }),
    {
      refetchInterval: FIVE_MINUTES,
    },
  );

  const categoryCountQuery = useQuery(
    ['categoryCount', selectedDatasets],
    () =>
      AnalyticsService.getAnnotationCounts({
        ...defaultApiArgs,
        summaryType: 'category-summaries',
        apiParams: {
          projectDataType: labelInterface?.dataType,
          ...getAssetGroupIn(selectedDatasets),
        },
      }),
    { refetchInterval: FIVE_MINUTES },
  );

  const labelingTimeQuery = useQuery(
    ['labelingTime', selectedDatasets],
    () =>
      AnalyticsService.getStatsHistogram({
        ...defaultApiArgs,
        apiParams: {
          ...getAssetGroupIn(selectedDatasets),
          projectDataType: labelInterface?.dataType,
          field: 'labeling_time',
        },
      }),
    // { enabled: filteredTotalLabelCount > 0 },
  );

  const topLabelsQuery = useQuery(
    ['topLabels', selectedDatasets, labelingTimeThreshold], //, topLabelsToday],
    () =>
      AnalyticsService.getTopLabels({
        ...defaultApiArgs,
        apiParams: {
          ...(topLabelsToday && { ...progressHelper.filterByToday() }),
          ...getAssetGroupIn(selectedDatasets),
          projectDataType: labelInterface?.dataType,
          labeling_time_gte: labelingTimeThreshold,
          sort_by: '-labeling_time',
        },
      }),
    { enabled: typeof labelingTimeThreshold !== 'undefined' && labelingTimeThreshold !== 0 },
  );

  useEffect(() => {
    if (labelingTimeQuery.data) {
      setLabelingTimeStat(labelingTimeQuery.data);
    }
  }, [labelingTimeQuery.data]);

  useEffect(() => {
    if (projectProgressQuery.data) {
      const transformed = progressHelper.formatLabelingProgressData(
        projectProgressQuery.data.results,
        selectedDatasets,
        CHART.projectProgress,
      );
      setProjectProgress(transformed);
    }
    setIsLoadingProjectProgress(false);
  }, [projectProgressQuery.data, selectedDatasets]);

  useEffect(() => {
    if (objectCountQuery.data) {
      const { avgTimePerLabel, totalLabels, submittedLabels } = objectCountQuery.data;
      setAvgTimePerLabel(avgTimePerLabel);
      setTotalSubmitted(submittedLabels);
      setFilteredTotalLabelCount(totalLabels); // fetched label count
    }
    if (hasObjects) {
      const isFrameBasedProject = ProjectUtils.isFrameBasedProject(labelInterface);
      const preprocessed = objectHelper.preprocessObjectCounts(
        objectCountQuery.data?.results || [],
        objectSettings,
        isFrameBasedProject,
      );
      setObjectClassStats(preprocessed);
      setObjectCountUpdateTime(new Date());
    }
  }, [objectCountQuery.data, objectSettings]);

  useEffect(() => {
    if (hasCategories && categoryCountQuery.data) {
      const transformed: JsonObj[][] = categoryHelper.preprocessCategoryCount(
        categoryCountQuery.data,
        isSiestaWorkapp,
        analyticsProxy,
        {
          plotConfig: CHART.imageCategoryStats,
          categoryMap: categoryHash,
        },
      );
      setImageCategoryStats(transformed);
    }
  }, [categoryCountQuery.data, categoryHash]);

  useEffect(() => {
    if (topLabelsQuery.data) {
      setLabelingTimeTable(topLabelsQuery.data);
    }
  }, [topLabelsQuery.data]);

  const delayInMilliseconds = 5 * 1000; // 5 seconds
  const requestProjectProgressUpdate = async () => {
    setIsLoadingProjectProgress(false);
    projectProgressMutation.mutate();
    setTimeout(async () => {
      await projectProgressQuery.refetch();
    }, delayInMilliseconds);
    setIsLoadingProjectProgress(true);
    return;
  };

  const refreshObjectCount = () => {
    return objectCountQuery.refetch();
  };

  const refreshCategoryCount = () => {
    return categoryCountQuery.refetch();
  };

  const refreshTopLabels = () => {
    return topLabelsQuery.refetch();
  };

  const refetchAll = async () => {
    return await Promise.all([
      projectProgressQuery.refetch(),
      objectCountQuery.refetch(),
      categoryCountQuery.refetch(),
      labelingTimeQuery.refetch(),
    ]);
  };

  const getPropertyCountCallback = useCallback(
    async (classId: string): Promise<ObjectProperty[]> => {
      const classPropCount = await AnalyticsService.getPropertyCount({
        ...defaultApiArgs,
        apiParams: {
          ...getAssetGroupIn(selectedDatasets),
          projectDataType: labelInterface?.dataType,
          classId,
        },
      });
      const data = combinePropSettingWithData({
        propertySetting: getPropertyTree(objectSettings),
        data: classPropCount,
        classId,
      })[classId];
      return data;
    },
    [projectId, selectedDatasets, objectSettings],
  );
  const CONTAINER_HEIGHT = 380;

  const handleChangeSelectedDatasets = (datasets: string[]) => {
    setSelectedDatasets(datasets);
  };

  const isClassConfigOutdated = checkIfClassNameIsUndefined(objectClassStats);
  const isCategoryConfigOutdated = checkIfCategoryNameIsUndefined(
    categoryCountQuery?.data?.results ?? [],
    categoryHash ?? [],
  );
  return (
    <Box position="absolute" top={0} left={0} right={0} mb={1.5}>
      <Box gap={0.5}>
        {(isClassConfigOutdated || isCategoryConfigOutdated) && (
          <RefreshPageBanner target={isClassConfigOutdated ? 'Classes' : 'Categories'} />
        )}
      </Box>{' '}
      <Box m={4}>
        <Box mb={2} mt={-0.625} display="flex" alignItems="center" justifyContent="flex-start">
          <ProjectAnalyticsFilter
            selectedDatasets={selectedDatasets}
            changeSelectedDatasets={handleChangeSelectedDatasets}
          />
        </Box>
        <Box display="flex" mb={4} justifyContent="flex-start" gap={32}>
          <Box
            width={280}
            display="flex"
            alignItems="space-between"
            justifyContent="space-between"
            flexDirection="column"
            height={360}
          >
            <TotalCard title={t('analytics.totalLabels')} labelCount={filteredTotalLabelCount} />
            <TotalCard title={t('analytics.project.cards.submitted')} labelCount={totalSubmitted} />
            <PercentCard
              title={t('analytics.project.cards.percentSubmitted')}
              submitted={totalSubmitted}
              total={filteredTotalLabelCount}
            />
            <MiniCard
              title={t('analytics.project.cards.avgLabelingTime.title')}
              value={avgTimePerLabel}
              isSiestaWorkapp={isSiestaWorkapp}
              labelCount={labelCount}
            />
          </Box>
          <Box flex={1} minWidth={0}>
            {projectProgress && (
              <ProjectProgressChart
                chartInfo={CHART.projectProgress}
                isLoading={isLoadingProjectProgress}
                labelCount={filteredTotalLabelCount || 0}
                sourceData={projectProgress}
                filter={{ assetGroupIn: selectedDatasets }}
                syncTime={progressHelper.getLatestData(projectProgress)?.updatedAt}
                height={360}
                refresh={requestProjectProgressUpdate}
              />
            )}
          </Box>
        </Box>
        {isSiestaWorkapp && labelingTimeStats && (
          <Box mb={4}>
            <ChartContainer
              setLabelingTimeThreshold={setLabelingTimeThreshold}
              sourceData={labelingTimeStats}
              tableData={labelingTimeTable || []}
              chartObject={CHART.labelingTime}
              isLoading={
                filteredTotalLabelCount
                  ? labelingTimeQuery.isLoading || topLabelsQuery.isLoading
                  : false
              }
              labelCount={filteredTotalLabelCount}
              filter={{ assetGroupIn: selectedDatasets }}
              height={CONTAINER_HEIGHT}
              syncTime={new Date()}
              refresh={refreshTopLabels}
            />
          </Box>
        )}
        {hasObjects && (
          <Box mb={4}>
            {objectClassStats && objectCountUpdateTime && (
              <ObjectCountChart
                chartInfo={CHART.objectClassStats}
                objectSettings={objectSettings}
                isLoading={objectCountQuery.isLoading}
                labelCount={filteredTotalLabelCount}
                sourceData={objectClassStats}
                filter={{ assetGroupIn: selectedDatasets }}
                syncTime={objectCountUpdateTime}
                height={CONTAINER_HEIGHT}
                refresh={refreshObjectCount}
                getPropertyCount={getPropertyCountCallback}
                onSetViewMode={onSetViewMode}
                showViewMode={ProjectUtils.isFrameBasedProject(labelInterface)}
                countField={countField}
              />
            )}
          </Box>
        )}
        {analyticsProxy &&
          isSiestaWorkapp &&
          hasCategories &&
          map(imageCategoryStats, property => {
            const propId = property[0]?.propertyId;
            const propertyInfo = categoryPropertyIdToInfo[propId];
            const formatted = categoryHelper.formatSiestaCategoryData(
              property,
              propertyInfo,
              categorySortX,
              categorySortY,
            );
            return (
              <Box mb={4} key={`category-stats-${propId}`}>
                {property && (
                  <CategoryCountChart
                    chartInfo={CHART.imageCategoryStats}
                    isLoading={categoryCountQuery.isLoading}
                    labelCount={filteredTotalLabelCount}
                    sourceData={formatted}
                    propertyName={property[0]?.propertyName || ''}
                    dataType={dataType}
                    filter={{ assetGroupIn: selectedDatasets }}
                    height={CONTAINER_HEIGHT}
                    syncTime={new Date()}
                    refresh={refreshCategoryCount}
                  />
                )}
              </Box>
            );
          })}
        {analyticsProxy && !isSiestaWorkapp && hasCategories && (
          <Box mb={4}>
            {imageCategoryStats && hasCategories && (
              <LegacyCategoryCountChart
                chartInfo={CHART.imageCategoryStats}
                isLoading={categoryCountQuery.isLoading}
                labelCount={filteredTotalLabelCount}
                sourceData={imageCategoryStats}
                filter={{ assetGroupIn: selectedDatasets }}
                height={CONTAINER_HEIGHT}
                syncTime={new Date()}
                refresh={refreshCategoryCount}
              />
            )}
          </Box>
        )}
        {/** TODO (ml) deprecate after proxy deployment */}
        {!analyticsProxy &&
          isSiestaWorkapp &&
          hasCategories &&
          map(imageCategoryStats, categoryProperty => {
            const propertyInfo = categoryPropertyIdToInfo[categoryProperty?.propertyId];
            const formatted = legacyCategoryHelper.formatSiestaCategoryData2011(
              categoryProperty,
              propertyInfo,
              categorySortX,
              categorySortY,
            );
            return (
              <Box mb={4} key={'category-stats' + categoryProperty?.propertyName}>
                {categoryProperty && (
                  <CategoryCountChart
                    chartInfo={CHART.imageCategoryStats}
                    isLoading={categoryCountQuery.isLoading}
                    labelCount={filteredTotalLabelCount}
                    sourceData={formatted}
                    propertyName={propertyInfo?.name || ''}
                    dataType={dataType}
                    filter={{ assetGroupIn: selectedDatasets }}
                    height={CONTAINER_HEIGHT}
                    syncTime={new Date()}
                    refresh={refreshCategoryCount}
                  />
                )}
              </Box>
            );
          })}
        {!analyticsProxy && !isSiestaWorkapp && hasCategories && (
          <Box mb={4}>
            {imageCategoryStats && hasCategories && (
              <LegacyCategoryCountChart
                chartInfo={CHART.imageCategoryStats}
                isLoading={categoryCountQuery.isLoading}
                labelCount={filteredTotalLabelCount}
                sourceData={imageCategoryStats}
                filter={{ assetGroupIn: selectedDatasets }}
                height={CONTAINER_HEIGHT}
                syncTime={new Date()}
                refresh={refreshCategoryCount}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProjectTab;
