import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';
import ThreadBody from './ThreadBody';
import ThreadFooter from './ThreadFooter';
import ThreadHeader from './ThreadHeader';
import { ThreadProps } from './types';

const useStyles = makeStyles({
  thread: {
    position: 'absolute',
    width: '300px',
    borderRadius: '6px',
    background: '#fff',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
  },
});

const Layout: React.FC<ThreadProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const { label } = labelDetailViewInfo;
  const { setIssues } = labelIssuesInfo;
  const { isCreate, issue, pos } = props;
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const [indexOfEditingComment, setIndexOfEditingComment] = useState<string | null>(null);

  useEffect(() => {
    if (isCreate) return;
    (async () => {
      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
    })();
    // eslint-disable-next-line
  }, []);

  if (!issue) return null;

  return (
    <MUI.Box className={classes.thread} style={{ ...pos }} boxShadow={1}>
      <ThreadHeader isCreate={isCreate} issue={issue} />
      <ThreadBody
        isCreate={isCreate}
        issue={issue}
        pos={pos}
        indexOfEditingComment={indexOfEditingComment}
        setIndexOfEditingComment={setIndexOfEditingComment}
      />
      <ThreadFooter
        isCreate={isCreate}
        issue={issue}
        indexOfEditingComment={indexOfEditingComment}
      />
    </MUI.Box>
  );
};

export default Layout;
