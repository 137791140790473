import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { FolderOpen } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Icon } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { DATASET_NAME_DUPLICATE } from '../../../../consts/ModalMessage';
import RangeConst from '../../../../consts/RangeConst';
import { DATASET_JOIN_FAIL, DATASET_NAME_RANGE_RULE } from '../../../../consts/SnackbarMessage';
import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import AssetsService from '../../../../services/AssetsService';
import CommandsService from '../../../../services/CommandsService';
import { DatasetData } from '../../../../types/dataTypes';
import { formatDate } from '../../../../utils/date';
import StringUtils from '../../../../utils/StringUtils';
import EditableText from '../../../elements/EditableText';
import newProjectHelper from '../../newProject/helper';

type Props = {
  dataset: DatasetData;
  setIsLoadingDatasets: Dispatch<SetStateAction<boolean>>;
  checkedDatasets: string[];
  onClickDataset: (id: string) => void;
  onClickCheckbox: (event: React.MouseEvent, groupName: string) => void;
};

const TableViewRow: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { enqueueSnackbar } = useSnackbar();
  const { dataset, onClickDataset, onClickCheckbox, checkedDatasets, setIsLoadingDatasets } = props;
  const isDatasetSelected = checkedDatasets.includes(dataset.group);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const assetsInfo = useAssetsInfo();
  const commandContext = useLabelCommandContext();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = lokiFlag;

  const parseValue = (newValue: string) => {
    return newProjectHelper.parseNameValue(t, newValue, 'Object class name', enqueueSnackbar);
  };

  const checkDatasetNameRule = async (newValue: string) => {
    const trimmedNewValue = newValue.trim();
    if (dataset.group === trimmedNewValue) {
      return { result: false, state: 'noChange', name: trimmedNewValue };
    }

    const assetsWithTheGroupName = await AssetsService.getAssets({
      params: { groupIn: [trimmedNewValue] },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    const isGroupNameAlreadyExists = assetsWithTheGroupName.count > 0;

    if (isGroupNameAlreadyExists) {
      const joinDataset = async () => {
        try {
          await assetsInfo.updateDatasetName({
            curName: dataset.group,
            newName: trimmedNewValue,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
            origin: 'components/pages/data/DatasetTableViewRow.jsx',
          });
        } catch (err: any) {
          if (err.message === 'Duplicated') {
            enqueueSnackbar(
              DATASET_JOIN_FAIL({ t, fromName: dataset.group, toName: trimmedNewValue }),
              { variant: 'error' },
            );
          }
        }
      };

      openModal({
        ...DATASET_NAME_DUPLICATE({
          t,
          currentDatasetName: dataset.group,
          newDatasetName: trimmedNewValue,
        }),
        mainButton: {
          text: t('button.confirm'),
          onClick: () => {
            joinDataset();
            closeModal();
          },
        },
        subButton: { text: t('button.cancel'), onClick: closeModal },
      });
      return { result: false, state: 'duplicate', name: trimmedNewValue };
    }

    if (
      !StringUtils.isWithinRangeLength(
        trimmedNewValue,
        RangeConst.DATASET.min,
        RangeConst.DATASET.max,
      )
    ) {
      enqueueSnackbar(
        DATASET_NAME_RANGE_RULE({ t, min: RangeConst.DATASET.min, max: RangeConst.DATASET.max }),
        {
          variant: 'warning',
        },
      );
      return {
        result: false,
        state: 'exceedTextLength',
        name: trimmedNewValue,
      };
    }

    return { result: true, state: 'success', name: trimmedNewValue };
  };

  const editDataset = async (newValue: string) => {
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'ASSETS_UPDATE_GROUP',
      params: {
        groupIn: [dataset.group],
      },
      actionInfo: {
        group: newValue,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
  };

  return (
    <MUI.TableRow hover onClick={() => onClickDataset(dataset.group)} selected={isDatasetSelected}>
      <MUI.TableCell padding="checkbox" align="center">
        <MUI.Checkbox
          color="primary"
          onClick={event => onClickCheckbox(event, dataset.group)}
          checked={checkedDatasets.includes(dataset.group)}
        />
      </MUI.TableCell>
      <MUI.TableCell>
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box display="flex" alignItems="center" mr={1}>
            <Icon icon={FolderOpen} />
          </MUI.Box>
          <EditableText
            text={dataset.group}
            maxWidth="600px"
            fontStyles={{
              fontSize: '12px',
              color: '#000',
            }}
            parseValue={parseValue}
            checkRule={checkDatasetNameRule}
            editText={editDataset}
          />
        </MUI.Box>
      </MUI.TableCell>
      <MUI.TableCell align="center">
        {t('charts.itemCount', { count: dataset.numAssets || 0 })}
      </MUI.TableCell>
      <MUI.TableCell align="center">{formatDate(dataset.createdAt)}</MUI.TableCell>
    </MUI.TableRow>
  );
};

export default TableViewRow;
