import { useParams } from 'react-router';

import { useMutation, useQuery } from '@tanstack/react-query';
import AnalyticsService from '../services/AnalyticsService';
import { BaseApiCallArgs } from '../services/types';

export type ProjectDataType = 'image' | 'video';

export interface AnalyticsParams extends BaseApiCallArgs {
  projectId: string;
}

export interface AnalyticsApiParams {
  selectedDatasets?: string[];
  projectDataType?: ProjectDataType;
}

export const getAssetGroupIn = (selectedDatasets: string[]) =>
  selectedDatasets.length ? { assetGroupIn: selectedDatasets } : {};

export const useProjectProgressMutation = (defaultParams: AnalyticsParams) => {
  return useMutation({
    mutationFn: () => {
      return AnalyticsService.postProgressSummarySync(defaultParams);
    },
  });
};
