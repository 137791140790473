import React from 'react';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { Page } from '../../../../menu';
import DatasetContainer from '../datasetList/DatasetContainer';
import DataViewFilter from '../DataViewFilter';
import DataContainer from './DataContainer';
import DataFilters from './DataFilters';

const DataLayout: React.FC = () => {
  const { params } = useRouteInfo();
  return (
    <>
      <Page>
        <DataViewFilter />
        {params.view !== 'dataset' && <DataFilters />}
        {params.view === 'dataset' ? <DatasetContainer /> : <DataContainer />}
      </Page>
    </>
  );
};

export default DataLayout;
