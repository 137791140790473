import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChevronRight } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import { capitalize } from 'lodash';
import NextLink from 'next/link';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useSubscriptionQuery } from '../../../../queries/useSubscriptionQuery';
import { getUrl } from '../../../../routes/util';
import { openChatWidget } from '../../../../utils/chatWidget';
import { formatDate } from '../../../../utils/date';

const Plan: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { accountName } = routeInfo.urlMatchInfo;

  const { data } = useSubscriptionQuery();

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      p={3}
      boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
      mb={3}
      gap={8}
      borderRadius="2px"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="m-regular">{t('settings.billing.plan')}</Typography>
        <Box display="flex" gap={0.5}>
          <Typography variant="h3" color="primary">
            {capitalize(data?.plan.tier.name ?? undefined)}
          </Typography>
          {data?.trialEnd && (
            <Typography variant="h3" color="gray">
              {t('settings.billing.trial')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {data?.trialEnd ? (
          <>
            <Typography variant="m-regular">{t('settings.billing.trialEndDate')}</Typography>
            <Typography variant="h3">{formatDate(data.trialEnd)}</Typography>
          </>
        ) : (
          <>
            <Typography variant="m-regular">{t('settings.billing.endDate')}</Typography>
            <Typography variant="h3">{data?.endDate ? formatDate(data.endDate) : '-'}</Typography>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={1} mr="auto">
        <Typography variant="m-regular">{t('settings.billing.paymentMethod')}</Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">{data?.paymentMethod ?? '-'}</Typography>
          {data?.paymentMethod === 'AWS Marketplace' && (
            <NextLink
              href="https://aws.amazon.com/marketplace/pp/prodview-7ehgf7thyom3i"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box display="flex" alignItems="center">
                <Icon color="gray" size="20" icon={ChevronRight} />
              </Box>
            </NextLink>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Button color="primary" variant="stroke" size="l" onClick={() => openChatWidget()}>
          {t('auth.mfa.problems.contactUs')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          size="l"
          style={{ textDecoration: 'none', color: 'white' }}
          {...({ as: Link, to: getUrl([accountName, 'plan']) } as any)}
        >
          {t('settings.billing.changePlan')}
        </Button>
      </Box>
    </Box>
  );
};

export default Plan;
