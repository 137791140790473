import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import { AppRouteLayoutProps, AppRoutes } from '../../../../menu';

export default function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  return (
    <Box mx="auto">
      <AppRoutes items={menuItem.children ?? []} />
    </Box>
  );
}
