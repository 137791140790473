/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { ProjectListInfo } from '../../../hooks/ProjectListInfo';
import { MemberData } from '../../../types/memberTypes';
import { getIsOwnerOrAdmin } from '../../pages/label/labelList/AssignDialog/utils';
import PopperTooltip from '../PopperTooltip';

type MembersTableRowProps = {
  member: MemberData;
  projectListInfo: ProjectListInfo;
};

const MembersTableRow: React.FC<MembersTableRowProps> = ({
  member,
  projectListInfo: projectsInfo,
}) => {
  const history = useHistory();
  const { urlMatchInfo } = useRouteInfo();

  const isOwnerOrAdmin = getIsOwnerOrAdmin(member);

  const projects = isOwnerOrAdmin ? projectsInfo.projects : member.projects;

  const handleClickProject = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    projectId: string,
  ) => {
    event.stopPropagation();
    history.push(`/${urlMatchInfo.accountName}/label/project/${projectId}/settings/members`);
  };

  return (
    <>
      {isOwnerOrAdmin ? (
        <Box
          style={{
            color: '#7B7B7B',
            backgroundColor: '#F3F3F3',
            fontSize: '12px',
            padding: '2px 8px',
          }}
          onClick={() => history.push(`/${urlMatchInfo.accountName}/label/projects`)}
          cursor="pointer"
          borderRadius="2px"
          display="inline"
        >
          All {projectsInfo.totalCount} {projectsInfo.totalCount === 1 ? 'project' : 'projects'}
        </Box>
      ) : (
        <PopperTooltip
          placement="bottom-start"
          bgColor="#fff"
          shadow
          padding="4px"
          anchor={
            <Box
              display="inline-flex"
              style={{
                backgroundColor: '#F3F3F3',
                color: '#7B7B7B',
                fontSize: '12px',
                padding: '2px 8px',
              }}
              borderRadius="2px"
            >
              {member.projects?.length} {member.projects?.length === 1 ? 'project' : 'projects'}
            </Box>
          }
          tooltipWidth="320px"
        >
          <Box
            width="100%"
            style={{ maxHeight: '320px' }}
            overflow="auto"
            display="flex"
            flexWrap="wrap"
          >
            {projects?.map((project: any) => (
              <Box
                key={project.projectId || project.id}
                m={0.25}
                style={{
                  backgroundColor: '#F3F3F3',
                  color: '#7B7B7B',
                  fontSize: '12px',
                  padding: '2px 8px',
                }}
                borderRadius="2px"
                onClick={event => handleClickProject(event, project.projectId || project.id)}
              >
                {project.name}
              </Box>
            ))}
          </Box>
        </PopperTooltip>
      )}
    </>
  );
};

export default MembersTableRow;
