import { useTranslation } from 'react-i18next';

import {
  Box,
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  ToggleButton,
} from '@superb-ai/norwegian-forest';

import { isOwnerOrAdmin, useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';

function ActionItem({
  filterKey,
  label,
}: {
  filterKey: keyof typeof commandContext.filters;
  label: string;
}) {
  const commandContext = useLabelCommandContext();
  const value = commandContext.filters[filterKey];
  return (
    <DropdownMenuItem
      onClick={e => {
        commandContext.setFilters({ ...commandContext.filters, [filterKey]: !value });
        e.stopPropagation();
      }}
    >
      <Box display="flex" gap="16px" alignItems="center" justifyContent="space-between">
        <span>{label}</span>
        <ToggleButton
          color={value ? 'grey' : 'backgroundColor'}
          value={value}
          style={{ background: value ? '#82DB24' : 'grey' }}
        />
      </Box>
    </DropdownMenuItem>
  );
}

export function CommandProgressFilters(): JSX.Element {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const showProjectFilter = routeInfo.urlMatchInfo.projectId && isOwnerOrAdmin(authInfo);

  return (
    <DropdownMenu
      AnchorElement={<IconButton round size="s" icon="moreHorizontal" color="textDefault" />}
      offset={0}
    >
      {showProjectFilter && (
        <ActionItem
          filterKey="onlyThisProject"
          label={t('commandProgressFilter.onlyCurrentProject')}
        />
      )}
      <ActionItem filterKey="onlyMy" label={t('commandProgressFilter.onlyYourActions')} />
    </DropdownMenu>
  );
}
