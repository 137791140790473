import React from 'react';

import {
  Box,
  Chip,
  Icon,
  IconName,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@superb-ai/norwegian-forest';

import { Category, ObjectClass } from '../../../../../../utils/LabelInterfaceUtils';
import { Engine } from '../helper';

export function getIconName(shape: string): IconName {
  if (shape === 'keypoint') {
    return 'keypoints';
  }
  switch (shape) {
    case 'box':
    case 'polygon':
    case 'polyline':
    case 'keypoints':
    case 'imageCategory':
      return shape;
    case 'checkbox':
      return 'checkboxCheckedOutline';
    case 'radio':
      return 'radioChecked';
    case 'free response':
      return 'pencil';
    case 'cuboid':
      return 'cuboid';
    default:
      return 'dot';
  }
}

interface Item {
  id: string;
  name: string;
}

export function ItemList({
  items,
  selectedId,
  onClickItem,
}: {
  items: (Item & { annotationType?: string })[];
  selectedId: string | undefined;
  onClickItem: (id: string) => void;
}): JSX.Element {
  return (
    <List selectedBgColor="secondary" bgColor="grey" margin="" itemPadding="9px 10px">
      {items.map(item => {
        const isSelected = selectedId === item.id;
        const selectedProps = isSelected
          ? {
              themedColor: 'secondary',
            }
          : {
              themedColor: 'grey',
            };
        const count =
          (item as ObjectClass).aiClassMap?.[0]?.classIds?.length ??
          ((item as Category).aiProperty?.propertyId !== undefined ? 1 : 0);
        return (
          <ListItem key={item.id} onClick={() => onClickItem(item.id)} isSelected={isSelected}>
            <Box display="flex" gap="6px" alignItems="center" {...selectedProps}>
              <Icon name={getIconName(item.annotationType ?? 'imageCategory')} size="10px" />
              <Typography variant="body3" style={{ wordBreak: 'break-all' }}>{`${
                item.name ?? item.id
              }`}</Typography>
              {count > 0 && (
                <Box ml="auto" display="flex">
                  <Chip color="cobalt">{`${count < 100 ? count : '99+'}`}</Chip>
                </Box>
              )}
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}

interface EngineListProps {
  title: string;
  items: Engine[];
  selectable?: boolean;
  selectedId?: string | undefined;
  onClickItem?: (item: Engine) => void;
  tooltipText?: React.ReactNode;
}

export function EngineList(props: EngineListProps): JSX.Element {
  const { title, items, selectable, selectedId, onClickItem, tooltipText } = props;
  return (
    <>
      <Box themedBackgroundColor={['grey', 50]} px={1.5} py={1}>
        <Typography variant="body5">{title}</Typography>
      </Box>
      <List selectedBgColor="secondary" bgColor="grey" margin="" itemPadding="9px 10px">
        {items.map(item => {
          const isSelected = selectedId === item.id;
          const listItemProps = selectable
            ? {
                onClick: () => onClickItem?.(item),
                isSelected,
              }
            : { disabled: true };
          const boxProps = !selectable
            ? {
                themedColor: ['grey', 300],
              }
            : isSelected
            ? {
                themedColor: 'secondary',
              }
            : {
                themedColor: 'grey',
              };
          const scrollIntoViewRef = (elem: HTMLDivElement) =>
            isSelected && elem?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          const textNodes = (
            <>
              <Typography variant="headline7">{`${item.name ?? item.id}`}</Typography>
              <Typography variant="body5">{`${item.project?.name ?? 'Pretrained'}`}</Typography>
            </>
          );
          return (
            <ListItem key={item.id} {...listItemProps}>
              <Box {...boxProps} ref={scrollIntoViewRef}>
                {selectable ? (
                  textNodes
                ) : (
                  <Tooltip placement="bottom-start" anchorEl={<div>{textNodes}</div>}>
                    {tooltipText}
                  </Tooltip>
                )}
              </Box>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
