import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: (props: any) => ({
    position: 'absolute',
    width: '100%',
    height: '6px',
    background: 'none',
    borderRadius: '4px',
    zIndex: (props.depth + 1) * 11,
    '&.prev': {
      top: 0,
    },
    '&.next': {
      bottom: 0,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  }),
}));

// eslint-disable-next-line
const ClassGroupItemDropBar = (props: any): React.ReactElement | null => {
  const newProjectInfo = useNewProjectInfo();
  const { dir, id, pl, depth } = props;
  const classes = useStyles({ depth });

  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.type !== 'classGroup' ||
    newProjectInfo.dragState.id === id
  )
    return null;

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    helper.moveGroupToGroup(newProjectInfo, newProjectInfo.dragState.id, id, {
      dir,
      id,
    });
    setIsDragEnter(false);
    newProjectInfo.setDragState(null);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      marginLeft={depth === 0 ? 0 : `${pl}px`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default ClassGroupItemDropBar;
