import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon, Tooltip } from '@superb-ai/norwegian-forest';

import { canManageProject, useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectService from '../../../services/ProjectService';
import UserRoleUnion from '../../../union/UserRoleUnion';

const useStyle = makeStyles(() => ({
  guidelineInput: {
    display: 'none',
  },
}));

type Props = {
  getOverviewInfos: () => Promise<void>;
};

const GuidelineUploadButton: React.FC<Props> = props => {
  const classes = useStyle();
  const inputRef = useRef<HTMLInputElement>(null);
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { t } = useTranslation();

  const {
    project: { id },
  } = projectInfo;
  const { getOverviewInfos } = props;

  useEffect(() => {
    if (routeInfo.params.guideline_uploader) {
      inputRef.current?.click();
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/overview`,
      );
    }

    // eslint-disable-next-line
  }, [routeInfo.params]);

  const handleChangeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      await ProjectService.uploadGuidelines({
        projectId: id,
        fileInfo: { size: file.size, title: file.name, scope: 'worker' },
        file,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      await getOverviewInfos();
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const handleClickUpload = async () => {
    if (!UserRoleUnion.isWorker(authInfo.projectRole)) {
      inputRef.current?.click();
    }
  };

  if (!canManageProject(authInfo)) return null;

  return (
    <>
      <input
        id="myInput"
        type="file"
        accept=".pdf"
        ref={inputRef}
        className={classes.guidelineInput}
        onChange={handleChangeInput}
      />
      <Tooltip
        anchorEl={
          <span>
            <MUI.IconButton size="small" onClick={handleClickUpload}>
              <Icon name="plus" size="15px" />
            </MUI.IconButton>
          </span>
        }
        placement="left"
      >
        {t('overview.guideline.buttonTooltip')}
      </Tooltip>
    </>
  );
};

export default GuidelineUploadButton;
