import { ReactElement } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { CurveType, SeriesColorAccessor } from '@elastic/charts';
import { Box } from '@superb-ai/ui';

import { FormattedPRCurveDatum, PredictionSetType } from '../../types';
import { diagnosisComparedClassColors } from '../colorScale';
import { lineChartTheme } from './customTheme';
import DynamicLineChart, { CustomLineSeriesType } from './DynamicLineChart';

export function PrecisionRecallTooltipHeader(props: { datum: Record<string, any> }): ReactElement {
  const { t } = useTranslation();
  const recall = props.datum?.Recall;
  return (
    <Box display="flex" style={{ width: '182px', marginRight: '-10px' }}>
      <Box>{t('curate.diagnosis.metric.recall')}</Box>
      <Box display="flex" ml="auto" pr={1}>
        {recall ? recall.toFixed(2) : '-'}
      </Box>
    </Box>
  );
}

const getSeriesConfig = (colorValue: string, t: TFunction, className?: string) => {
  return {
    id: className ?? t('curate.diagnosis.chart.precisionRecall.interpolatedPrecision'),
    yAccessors: ['Precision'],
    xAccessor: 'Recall',
    curve: CurveType.CURVE_STEP_AFTER,
    color: () => colorValue as SeriesColorAccessor,
  };
};

function getChartConfig(t: TFunction) {
  return {
    showLegend: true,
    axes: {
      xAxisTitle: t('curate.diagnosis.metric.recall'),
      yAxisTitle: t('curate.diagnosis.metric.precision'),
    },
    series: [] as CustomLineSeriesType[],
  };
}

type Props = {
  data: Record<string, FormattedPRCurveDatum[]>;
  comparedData: Record<string, FormattedPRCurveDatum[]>;
  predictionSetType: PredictionSetType;
};

export default function PrecisionRecallCurve(props: Props) {
  const { t } = useTranslation();
  const { comparedData, data } = props;
  const combined = {
    ...data,
    ...comparedData,
  };

  const chartConfig = getChartConfig(t);
  const seriesConfig = Object.keys(combined)?.map((className, index) => {
    return getSeriesConfig(diagnosisComparedClassColors[index], t, className);
  }) as CustomLineSeriesType[];
  chartConfig['series'] = seriesConfig;

  return (
    <DynamicLineChart
      data={combined as any}
      config={chartConfig}
      theme={lineChartTheme()}
      TooltipHeaderComponent={PrecisionRecallTooltipHeader}
    />
  );
}
