import { SuiteLogo } from '@superb-ai/ui';

import { isOwnerOrAdminOrSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu';
import { EndpointsMenuItem } from './endpoints/MenuItem';
import { GenerativeAIMenuItem } from './gen-ai/MenuItem';
import ModelLayout from './ModelLayout';
import { OverviewMenuItem } from './overview/MenuItem';
import { RecognitionAIMenuItem } from './recognition-ai/MenuItem';
import { TrainModelMenuItem } from './train/MenuItem';

export const ModelMenuItem = CreateMenuItem({
  // pageId: PAGE_TRACKING_ID.modelInitialPage, // not tracked
  path: 'model',
  matchParam: ':suite(model)',
  group: 'suite',
  icon: <SuiteLogo type="model" />,
  title: ({ t }) => t('model.title'),
  component: ModelLayout,
  children: [
    OverviewMenuItem,
    // BaselineModelsMenuItem,
    RecognitionAIMenuItem,
    GenerativeAIMenuItem,
    // AutoLabelAIMenuItem,
    EndpointsMenuItem,
    TrainModelMenuItem,
    // DocsMenuItem,
  ],
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
