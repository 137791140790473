import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import ImageVideoDragAndDrop from '../steps/ImageVideoDragAndDrop';
import PointcloudSequenceDragAndDrop from '../steps/PointcloudSequenceDragAndDrop';

const File = (t: TFunction) => {
  const fileInfo = useUploadInfo();
  const { dataType } = fileInfo;

  const FILES = 'files';

  const steps = {
    [FILES]:
      dataType === 'POINT_CLOUD_SEQUENCE'
        ? PointcloudSequenceDragAndDrop(t)
        : ImageVideoDragAndDrop(t),
  };

  return steps;
};

export default File;
