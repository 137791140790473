import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AsyncCombobox, Box, Button, Checkbox, Dialog, Label, Typography } from '@superb-ai/ui';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import analyticsTracker from '../../../../analyticsTracker';
import { useCurateCreateJobMutation } from '../../../../apps/Curate/queries/commandQueries';
import { useSlicesInfiniteQuery } from '../../../../apps/Curate/queries/sliceQueries';
import { useCurateDatasetService } from '../../../../apps/Curate/services/DatasetService';
import { getNamingRuleErrorMessage } from '../../../../configs/NamingRulesConfig';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useApiDefaultParams } from '../../../../hooks/ApiParamsHook';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { hasSufficientCurateVolume } from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import LabelsService from '../../../../services/LabelsService';
import LabelInterfaceUtils from '../../../../utils/LabelInterfaceUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';
import { AlertBox } from '../../../elements/AlertBox';
import { VerticalDivider } from '../../analytics/exportsAnalytics/components/VerticalDivider';
import { ImageWithFallback } from './ImageWithFallback';
import SendToCurateCompleteDialog from './SendToCurateCompleteDialog';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  checkedLabels: any[];
  setCheckedLabels(labels: any[]): void;
  totalCount: number;
  isAllLabelsChecked: boolean;
  filterApiParams: any;
};

function DialogContent({
  state,
  isAllLabelsChecked,
  totalCount,
  checkedLabels,
  filterApiParams,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authInfo = useAuthInfo();
  const { project } = useProjectInfo();
  const { rootLabels: labels } = useLabelsInfo();
  const { createSlice, getDatasetList } = useCurateDatasetService();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(null);
  const [datasetSearchInput, setDatasetSearchInput] = useState<string>('');
  const [selectedSliceId, setSelectedSliceId] = useState<string | null>(null);
  const [sliceSearchInput, setSliceSearchInput] = useState('');
  const [sendToCurateCompleteIsOpen, setSendToCurateCompleteIsOpen] = useState(false);
  const [addNew, setAddNew] = useState(true);
  const [createdSliceList, setCreatedSliceList] = useState<{ label: string; value: string }[]>([]);
  const params = useApiDefaultParams({ projectId: project.id, origin: 'SendToCurateDialog' });
  const debouncedSearchInput = useDebounce(datasetSearchInput, 300);

  const { mutate: createJob, isLoading: isCreating } = useCurateCreateJobMutation();

  const checkedLabelCount = isAllLabelsChecked ? totalCount : checkedLabels.length;
  const sampleLabelsAll = isAllLabelsChecked
    ? labels
    : labels.filter(label => checkedLabels.includes(label.id));
  const sampleLabels = sampleLabelsAll.filter(l => l.thumbnail).slice(0, 4);

  const metering = useMetering('curate:data-volume');
  const isVolumeSufficient = hasSufficientCurateVolume(metering, { minimum: checkedLabelCount });

  // useEffect(() => {
  //   if (!state.visible) {
  //     setSelectedDatasetId(null);
  //     setSelectedSliceId(null);
  //     setDatasetSearchInput('');
  //     setSliceSearchInput('');
  //     setAddNew(true);
  //   }
  // }, [state]);

  function initialize() {
    setSelectedDatasetId(null);
    setSelectedSliceId(null);
    setDatasetSearchInput('');
    setSliceSearchInput('');
    setAddNew(true);
  }

  const pageSize = 10;
  const {
    data: datasetData,
    hasNextPage: hasDatasetNextPage,
    fetchNextPage: fetchDatasetNextPage,
    isLoading: isLoadingDataset,
  } = useInfiniteQuery({
    queryKey: ['curate-dataset-list', authInfo.accountName, debouncedSearchInput],
    queryFn({ pageParam = 1 }) {
      return getDatasetList({
        fromPublicDatasets: false,
        page: pageParam,
        size: pageSize,
        sort_order: 'desc',
        sort_by: 'updated_at',
        name_icontains: debouncedSearchInput,
      });
    },
    getNextPageParam: ({ count }, pages) => {
      return count > pages.length * pageSize ? pages.length + 1 : undefined;
    },
  });

  const datasetOptions = datasetData?.pages.flatMap(page =>
    page.results.map(dataset => ({ value: dataset.id, label: dataset.name })),
  );
  const datasetName = datasetOptions?.find(option => option.value === selectedDatasetId)?.label;

  const {
    data: sliceData,
    hasNextPage: hasSliceNextPage,
    fetchNextPage: fetchSliceNextPage,
    isLoading: isLoadingSlice,
  } = useSlicesInfiniteQuery({
    datasetId: selectedDatasetId,
    size: pageSize,
    nameContains: sliceSearchInput,
    enabled: !!selectedDatasetId,
  });

  const sliceOptions = useMemo(
    () => [
      ...(sliceData?.pages.flatMap(page =>
        page.results.map(slice => ({ value: slice.id, label: slice.name })),
      ) || []),
      ...createdSliceList.filter(slice =>
        slice.value.toLowerCase().includes((sliceSearchInput || '').toLowerCase()),
      ),
    ],
    [sliceData, createdSliceList, sliceSearchInput],
  );
  const sliceName = useMemo(
    () => sliceOptions?.find(option => option.value === selectedSliceId)?.label,
    [sliceOptions, selectedSliceId, createdSliceList],
  );

  useEffect(() => {
    setSliceSearchInput('');
    setCreatedSliceList([]);
  }, [selectedDatasetId]);

  // TODO: Refactor this. This code is repeated in too many places.
  const getFilterParamRaw = () => {
    if (checkedLabels?.length > 0 && !isAllLabelsChecked) {
      return { idIn: checkedLabels };
    }
    return omit(filterApiParams, [
      'ordering',
      'page',
      'pageSize',
      'searchAfter',
      'searchBefore',
      'searchLast',
    ]);
  };

  const showHistoryCounts = false;

  const { data: historyData } = useQuery({
    queryKey: ['curate-integration-histories', project.id, getFilterParamRaw()],
    queryFn() {
      return LabelsService.getLabelCurateIntegrationHistories({
        ...params,
        params: getFilterParamRaw(),
      });
    },
    enabled: showHistoryCounts,
  });

  const selectedHistoryEntry = historyData?.results.find(h => h.id === selectedDatasetId);

  useEffect(() => {
    // If history available, pre-select first dataset
    if (historyData?.results?.length && !selectedDatasetId) {
      const firstResult = historyData?.results[0];
      setSelectedDatasetId(firstResult.id);
    }
  }, [historyData, selectedDatasetId]);

  async function sendToCurate() {
    if (!selectedDatasetId) return;
    let sliceId: string | undefined | null;
    const newSliceName = createdSliceList.find(slice => slice.value === selectedSliceId)?.label;

    if (newSliceName) {
      try {
        const { id } = await createSlice({
          name: newSliceName,
          description: t('labels.dialogs.sendToCurate.sliceDescription', {
            date: format(new Date(), 'yyyy-MM-dd'),
            email: authInfo.email,
          }),
          datasetId: selectedDatasetId,
        });
        sliceId = id;
        setSelectedSliceId(id);
        setCreatedSliceList(prev =>
          prev.map(item => (item.label === newSliceName ? { ...item, value: id } : item)),
        );
        analyticsTracker.dataSliceCreated({
          accountId: authInfo.accountName,
          sliceId: id,
          datasetId: selectedDatasetId,
          dataCount: checkedLabelCount,
          dataType: 'image',
          referrer: 'label-to-curate',
        });
      } catch (e) {
        enqueueSnackbar(
          <>
            Error creating slice.
            <br />
            {`${e}`}
          </>,
          { variant: 'error' },
        );
        return;
      }
    } else {
      sliceId = selectedSliceId;
    }
    const data = {
      dataset_id: selectedDatasetId,
      slice_id: sliceId,
      project_id: project.id,
      filter: ServiceUtils.getParamString(getFilterParamRaw()) || '',
      correlation_id: uuidv4(),
      policy: addNew ? 'ALL' : 'ONLY_UPDATE',
    };
    await createJob({
      jobType: 'IMPORT_FROM_LABELING',
      data,
    });
    setSendToCurateCompleteIsOpen(true);
    analyticsTracker.sendToCurateRequested({
      accountId: authInfo.accountName ?? '',
      datasetId: selectedDatasetId,
      filterBy: Object.keys(getFilterParamRaw()) || [],
      filterString: ServiceUtils.getParamString(getFilterParamRaw()) || '',
      dataCount: checkedLabelCount,
      dataType: project.labelInterface.dataType,
    });
    state.hide();
  }

  // Show type warning only when project has annotation types that are not yet supported.
  const supportedAnnotationTypes = ['box', 'polygon'];
  const annotationTypes = LabelInterfaceUtils.getAnnotationTypes(project.labelInterface);
  const showTypeWarning = annotationTypes.some(type => !supportedAnnotationTypes.includes(type));
  const sliceNameInvalidReasons = getNamingRuleErrorMessage({
    str: sliceSearchInput,
    target: 'slice',
  });
  return (
    <>
      <Dialog.Header
        onClickClose={() => {
          void state.hide();
          initialize();
        }}
      >
        {t('labels.button.sendToCurate')}
      </Dialog.Header>
      <Dialog.Content style={{ width: 450 }}>
        <Box display="flex" gap={2} flexDirection="column">
          <Label vertical>
            <span>
              <Trans t={t} i18nKey="labels.selectedCount" values={{ count: checkedLabelCount }}>
                Selected <Typography color="primary">{checkedLabelCount}</Typography>
              </Trans>
            </span>
            <LabelImageGrid labels={sampleLabels} />
          </Label>

          {!isVolumeSufficient && (
            <>
              <AlertBox type="danger">
                <span>
                  <Trans
                    t={t}
                    i18nKey="labels.sendToCurate.volumeWarning"
                    values={{ leftQuantity: metering.leftQuantity }}
                  />
                </span>
              </AlertBox>
            </>
          )}

          {isVolumeSufficient && (
            <>
              <Label vertical alignItems="stretch">
                <Typography variant="m-regular">
                  {t('labels.dialogs.sendToCurate.selectDataset')}
                </Typography>
                <Box display="flex">
                  <AsyncCombobox
                    placeholder={t('labels.sendToCurate.pleaseSelectDataset')}
                    isLoading={isLoadingDataset}
                    data={datasetOptions}
                    value={selectedDatasetId}
                    loadingText={t('button.loading')}
                    hasNextPage={hasDatasetNextPage}
                    fetchNextPage={fetchDatasetNextPage}
                    onChangeValue={(nextValue: string) => {
                      setSelectedDatasetId(nextValue);
                      setSelectedSliceId(null);
                    }}
                    onChangeSearchInput={(value: string) => {
                      setDatasetSearchInput(value);
                    }}
                    onBlurSearchInput={() => {
                      setDatasetSearchInput('');
                    }}
                    searchInput={datasetSearchInput}
                    multiple={false}
                    creatable={false}
                  />
                </Box>
              </Label>

              {showHistoryCounts && (
                <Box
                  backgroundColor="gray-100"
                  p={1}
                  display="grid"
                  gap={1}
                  style={{ gridTemplateColumns: '1fr 1px 1fr', marginTop: '-6px' }}
                >
                  <Label>
                    <Checkbox value disabled />
                    {t('labels.dialogs.sendToCurate.overwriteExisting')}
                    <strong>{selectedHistoryEntry?.labelCounts ?? '0'}</strong>
                  </Label>
                  <VerticalDivider my={0.5} height={undefined} />
                  <Label>
                    <Checkbox value={addNew} onClick={() => void setAddNew(a => !a)} />
                    {t('labels.dialogs.sendToCurate.addNew')}
                    <strong>{checkedLabelCount - (selectedHistoryEntry?.labelCounts ?? 0)}</strong>
                  </Label>
                </Box>
              )}

              {showTypeWarning && (
                <AlertBox type="info">
                  <span>
                    <Trans t={t} i18nKey="labels.dialogs.sendToCurate.supportedTypes" />
                  </span>
                </AlertBox>
              )}
              <Label vertical alignItems="stretch">
                <Typography variant="m-regular">
                  {t('labels.dialogs.sendToCurate.createSlice')}
                </Typography>
                <Box position="relative" display="flex">
                  {sliceNameInvalidReasons.length > 0 && (
                    <Box
                      bottom="0"
                      mb={0.25}
                      position="absolute"
                      width="100%"
                      p={1}
                      border="1px solid"
                      borderColor="primary-400"
                      backgroundColor="primary-100"
                      borderRadius="2px"
                      display="flex"
                      flexDirection="column"
                      gap={0.25}
                      style={{ bottom: '32px' }}
                    >
                      {sliceNameInvalidReasons.map(reason => (
                        <Typography key={reason} variant="m-regular" color="primary">
                          {reason}
                        </Typography>
                      ))}
                    </Box>
                  )}
                  <AsyncCombobox
                    disabled={!selectedDatasetId}
                    isLoading={isLoadingSlice}
                    data={sliceOptions}
                    value={selectedSliceId}
                    placeholder={t('labels.sendToCurate.enterSliceName')}
                    loadingText={t('button.loading')}
                    hasNextPage={hasSliceNextPage}
                    fetchNextPage={fetchSliceNextPage}
                    onChangeValue={(nextValue: string) => {
                      setSelectedSliceId(nextValue);
                    }}
                    onCreate={(value: string) => {
                      setCreatedSliceList(prev => [...prev, { label: value, value }]);
                      setSelectedSliceId(value);
                    }}
                    onChangeSearchInput={(value: string) => {
                      setSliceSearchInput(value);
                    }}
                    onBlurSearchInput={() => {
                      setSliceSearchInput('');
                    }}
                    searchInput={sliceSearchInput}
                    multiple={false}
                    creatable={sliceNameInvalidReasons.length === 0}
                    createMessage={t('labels.dialogs.sendToCurate.createNewSlice')}
                  />
                </Box>
              </Label>
              <AlertBox type="danger">{t('labels.dialogs.sendToCurate.overrideWarning')}</AlertBox>
            </>
          )}
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          variant="text"
          onClick={() => {
            void state.hide();
            initialize();
          }}
        >
          {t('button.cancel')}
        </Button>
        <Button
          variant="strong-fill"
          color="primary"
          disabled={!selectedDatasetId || !isVolumeSufficient}
          loading={isCreating}
          loadingText={t('button.sending')}
          onClick={async () => {
            await sendToCurate();
          }}
        >
          {t('button.send')}
        </Button>
      </Dialog.Actions>
      {selectedDatasetId && datasetName && (
        <SendToCurateCompleteDialog
          initialize={initialize}
          sendToCurateCompleteIsOpen={sendToCurateCompleteIsOpen}
          setSendToCurateCompleteIsOpen={setSendToCurateCompleteIsOpen}
          selectedSliceName={sliceName}
          selectedSliceId={selectedSliceId}
          selectedDatasetName={datasetName}
          selectedDatasetId={selectedDatasetId}
        />
      )}
    </>
  );
}

export function SendToCurateDialog(props: Props) {
  return (
    <Dialog state={props.state}>
      <DialogContent {...props} />
    </Dialog>
  );
}

function LabelImageGrid({ labels }: { labels: { id: string; thumbnail: string }[] }) {
  return (
    <Box display="grid" width="100%" style={{ gridTemplateColumns: 'repeat(4, 1fr)' }} gap={1}>
      {labels.map(label => (
        <ImageWithFallback
          key={label.id}
          src={label.thumbnail}
          fallbackSrc="/static/image/not_found_thumbnail.png"
          style={{
            width: '100%',
            objectFit: 'cover',
            aspectRatio: '90 / 50',
            borderRadius: '2px',
          }}
          alt="thumbnail"
          crossOrigin="anonymous"
        />
      ))}
    </Box>
  );
}
