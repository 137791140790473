import { useQuery } from '@tanstack/react-query';

import { useCurateAnalyticsService } from '../../../../../services/AnalyticsService';
import { stringifyFilters } from '../../../../../utils/filterUtils';
import { ImageFilterSchema } from '../../filter/types';

export type Dependencies = {
  datasetId: string;
  fromPublicDatasets: boolean;
  sliceId?: string;
  slice?: string;
  queryString?: string;
  isCompareMode?: boolean;
  appliedFilters?: ImageFilterSchema;
};
export function useStatsMetadataKeyQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  appliedFilters,
}: Dependencies) {
  const { getStatsMetadataKey } = useCurateAnalyticsService();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  const queryFn = async () => {
    const result = await getStatsMetadataKey({
      datasetId,
      fromPublicDatasets,
      query: queryString,
      slice,
      appliedFilters,
    });
    return result;
  };
  return useQuery({
    queryKey: ['stats-metadata-key', datasetId, sliceId, queryString, stringifiedFilters],
    queryFn,
    retry: 3,
    staleTime: 300000,
    cacheTime: 3600000,
  });
}

export function useStatsComparedMetadataKeyQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  compareSetId,
  isCompareMode,
}: Omit<Dependencies, 'clusterLevel' | 'appliedFilters'> & { compareSetId?: string }) {
  const { getStatsMetadataKey } = useCurateAnalyticsService();
  const filterBySlice = { slice };
  const queryFn = async () => {
    const result = await getStatsMetadataKey({
      datasetId: datasetId,
      fromPublicDatasets: fromPublicDatasets,
      ...(compareSetId === sliceId && filterBySlice),
    });
    return result;
  };
  return useQuery({
    queryKey: ['stats-compared-metadata-key', datasetId, sliceId, queryString, compareSetId],
    queryFn: queryFn,
    retry: 3,
    enabled: isCompareMode || !!queryString,
    staleTime: 300000,
    cacheTime: 3600000,
  });
}

export type ValueDepedencies = Dependencies & { metadataKey: string; compareSetId?: string };
export function useStatsMetadataValueQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  metadataKey,
  appliedFilters,
}: ValueDepedencies) {
  const { getStatsMetadataValue } = useCurateAnalyticsService();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  return useQuery(
    ['stats-metadata-value', datasetId, sliceId, queryString, metadataKey, stringifiedFilters],
    async () => {
      const result = await getStatsMetadataValue({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        metadataKey: metadataKey,
        query: queryString,
        appliedFilters,
      });
      if (!result) {
        throw new Error('Failed to fetch data');
      }
      return result;
    },
    {
      enabled: !!metadataKey && metadataKey !== '',
      staleTime: 300000,
      cacheTime: 3600000,
    },
  );
}

export function useStatsComparedMetadataValueQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  metadataKey,
  isCompareMode,
  compareSetId,
}: Omit<ValueDepedencies, 'clusterLevel' | 'appliedFilters'>) {
  const { getStatsMetadataValue } = useCurateAnalyticsService();
  const filterBySlice = { slice };
  return useQuery(
    ['stats-compared-metadata-value', datasetId, sliceId, queryString, compareSetId, metadataKey],
    async () => {
      const result = await getStatsMetadataValue({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        metadataKey: metadataKey,
        ...(compareSetId === sliceId && filterBySlice),
      });
      if (!result) {
        throw new Error('Failed to fetch data');
      }
      return result;
    },
    {
      enabled: isCompareMode && !!metadataKey && metadataKey !== '',
      staleTime: 300000,
      cacheTime: 3600000,
    },
  );
}
