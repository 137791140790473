import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';
import { BrushSelection, D3BrushEvent } from 'd3';
import { filter, isEmpty, isUndefined, map, sumBy, zip } from 'lodash';

import { SvgConfigObject } from '../../config/types';
import LinearGradient from '../../elements/gradient/LinearGradient';
import XAxis from '../../elements/XAxis';
import YAxis from '../../elements/YAxis';
import { JsonObj } from '../../userStats/types';
import { getBarScales } from '../helper';
import { BinSizeOptions, scaleBandInvert } from './histogramUtil';
import Stamp from './Stamp';

interface ChartProps {
  chartName?: string;
  svgInfo: SvgConfigObject;
  data: JsonObj[];
  totalCounts: number;
  xKey: string;
  yKey: string;
  xLabelName: string;
  yLabelName: string;
  binSize: BinSizeOptions;
}

const GREY = '#E5E5E5';
const BRIGHT_LAVENDER = '#AF48FF';
const LIGHT_LAVENDER = '#E7C8FF';

const Histogram: React.FC<ChartProps> = props => {
  const { totalCounts, svgInfo, data, xKey, yKey, xLabelName, yLabelName, binSize } = props;
  const gradientStyles: CSSProperties[] = [
    { stopColor: BRIGHT_LAVENDER, stopOpacity: 0.8 },
    { stopColor: BRIGHT_LAVENDER, stopOpacity: 0.01 },
  ];

  // TODO (ml) - check if QATab has submitted consensus label count
  const totalLabelCount = sumBy(data, 'labelCount');

  /** By default, display brush from scores 0~20 (we do this so brush feature is easy to discover) */
  const [brushExtent, setBrushExtent] = useState<number[]>([]);
  const [isBrushing, setIsBrushing] = useState<boolean>(false);
  const [brushExtentPos, setBrushExtentPos] = useState<number[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const xValues = map(data, d => d[xKey]);
  const yValues = map(data, d => d[yKey]);
  const getYFromX = data.reduce((acc, cur) => {
    acc[cur[xKey]] = cur[yKey];
    return acc;
  }, {});

  const [x, y] = getBarScales(
    xValues,
    yValues,
    { ...svgInfo, innerPadding: 0.1, outerPadding: 0 },
    'BAND',
    1.1,
  );

  const gradientId = 'histogram-gradient';
  const brushRef = useRef<SVGGElement>(null);
  const chartGroupRef = useRef<SVGGElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  /**
   * Below three functions style brush, its handles, and data marker
   *  - `any` type is sufficient, as we currently don't support want
   *    strict typing over d3
   */
  const styleBrushGroupSelection = (
    brushGroup: d3.Selection<SVGGElement, unknown, SVGGElement, unknown>,
  ) => {
    brushGroup
      .selectAll('rect.selection')
      .attr('ref', 'brushSelectionRef')
      .attr('id', 'brush-selection-id')
      .attr('opacity', 0.2);
  };

  const styleBrushGroupHandles = (brushGroup: any, selection: number[]) => {
    brushGroup
      .selectAll('.handle--custom')
      .data([
        { type: 'w', xPos: selection[0] },
        { type: 'e', xPos: selection[1] },
      ])
      .join(
        //@ts-ignore this works (investigate later)
        enter => {
          enter
            .append('line')
            .attr('class', 'handle--custom')
            .attr('y1', -svgInfo.height / 2)
            .attr('y2', svgInfo.height / 2)
            .attr('stroke', LIGHT_LAVENDER)
            .attr('stroke-width', 1.5)
            .attr('stroke-dasharray', '3 2')
            .attr('cursor', 'ew-resize');
        },
      )
      // .attr('display', selection === null ? 'none' : null)
      .attr('display', selection)
      .attr(
        'transform',
        // @ts-ignore this works
        selection === null
          ? null
          : (d: number, i: number) =>
              `translate(${selection[i]},${(svgInfo.height + svgInfo.top - svgInfo.bottom) / 2})`,
      );
  };

  /**
   * @param chartGroup
   * @param selection brush extent as [start position, end position]
   * @param yLeft y-value at left brush handle
   * @param yRight y-value at right brush handle
   */
  const placeBrushHandleDataMarkers = (
    chartGroup: any,
    selection: number[],
    yLeft: number,
    yRight: number,
    cx: number,
  ) => {
    chartGroup
      .selectAll('.handle--circles')
      .data([
        {
          type: 'w',
          xPos: selection[0],
          yPos: !Number.isNaN(yLeft) ? yLeft : svgInfo.bottom,
        },
        {
          type: 'e',
          xPos: selection[1],
          yPos: !Number.isNaN(yRight) ? yRight : svgInfo.bottom,
        },
      ])
      .join(
        //@ts-ignore this works (investigate later)
        enter => {
          enter
            .append('circle')
            .attr('class', 'handle--circles')
            .attr('fill', '#fff')
            .attr('fill-opacity', 1)
            .attr('stroke', BRIGHT_LAVENDER)
            .attr('stroke-width', 4)
            .attr('cursor', 'ew-resize')
            .attr('cx', cx)
            .attr('cy', cx)
            .attr('r', 4);
        },
      )
      .attr('display', selection)
      .attr(
        'transform',
        // @ts-ignore this works
        selection === null ? null : (d: number) => `translate(${d.xPos - cx}, ${d.yPos - cx / 2})`,
      );
  };

  useEffect(() => {
    setBrushExtent([0, 50]);
    setBrushExtentPos([x(0) || 0, x(50) as number]);
  }, [data]);

  useEffect(() => {
    const brush = d3.brushX().extent([
      [0, 0],
      [svgInfo.width, svgInfo.height],
    ]);

    if (brushRef.current && chartGroupRef.current) {
      const brushGroup = d3.select(brushRef.current);
      const chartGroup = d3.select(chartGroupRef.current);
      const cx = 6;

      const clearBrushEdges = () => {
        /** Clear Stamp and is brushing variable */
        setIsBrushing(false);
        brushGroup.selectAll('.handle--custom').attr('display', 'none');
        chartGroup.selectAll('.handle--circles').attr('display', 'none');
      };

      brush(brushGroup);
      styleBrushGroupSelection(brushGroup.selectAll('rect.selection'));

      if (isInitialLoad && !isBrushing && brushExtent.length > 0) {
        brush.move(brushGroup, [x(brushExtent[0]) || 0, x(brushExtent[1]) || 100]);
        setIsBrushing(true);
        setIsInitialLoad(false);
      }

      brush
        .on('brush', (event: D3BrushEvent<BrushSelection>) => {
          // used to grey out histogram bars outside the brush during brush event
          setIsBrushing(true);

          const selection = event?.selection as [number, number];

          if (selection) {
            const [x1, x2] = selection;
            const [xBrushStartValue, xBrushEndValue] = [
              scaleBandInvert(x1, x),
              scaleBandInvert(x2, x),
            ];

            // style brush and handles
            styleBrushGroupSelection(brushGroup.selectAll('rect.selection'));
            styleBrushGroupHandles(brushGroup, selection);

            // Use circles at brush handles to indicate data height (label count)
            const yLeft = y(getYFromX[Math.max(Math.round(xBrushStartValue), 0)]) as number;
            const yRight = y(getYFromX[Math.min(Math.round(xBrushEndValue), 100)]) as number;
            placeBrushHandleDataMarkers(chartGroup, selection, yLeft, yRight, cx);

            setBrushExtentPos([x1, x2]);
            setBrushExtent([xBrushStartValue, xBrushEndValue]);
          }
        })
        .on('start', (event: D3BrushEvent<BrushSelection>) => {
          // clear brush on single click in rect iff brush exists
          if (!isEmpty(brushExtent)) {
            const selection = event?.selection;
            if (
              selection &&
              selection[0] == brushExtentPos[0] &&
              selection[1] == brushExtentPos[1]
            ) {
              return;
            }
            clearBrushEdges();
          }
        });
    }
  }, [svgInfo.width, svgInfo.height, brushExtent, x, y, brushRef, chartGroupRef]);

  const getBarColor = (xValue: number, brushExtent: number[], isBrushing: boolean) => {
    if (!isBrushing) {
      return `url(#${gradientId})`;
    }
    return xValue >= brushExtent[0] && xValue <= brushExtent[1] ? `url(#${gradientId})` : GREY;
  };

  const bars =
    !isEmpty(xValues) &&
    !isEmpty(yValues) &&
    map(zip(xValues, yValues), (d, index) => {
      return (
        <g key={`g${index}`}>
          {!isUndefined(d[1]) &&
            !isUndefined(d[0]) &&
            !Number.isNaN(d[0]) &&
            !Number.isNaN(d[1]) && (
              <rect
                rx="1.5"
                ry="1.5"
                key={`rect-${index}`}
                className={`histogram-bar-${d[0]}`}
                x={x(d[0] as any)}
                y={y(d[1] as any)}
                width={x.bandwidth()}
                height={svgInfo.height - (y(d[1]) as number)}
                stroke={'#FFFFFF'}
                strokeWidth={0.1}
                opacity={1}
                fill={getBarColor(d[0], brushExtent, isBrushing)}
              />
            )}
        </g>
      );
    });
  const gTransform = `translate(${svgInfo.left},${svgInfo.top})`;
  const svgStyle = {
    overflow: 'visible',
    width: svgInfo.svgWidth,
    height: svgInfo.svgHeight,
  };

  return (
    <>
      <svg id="bar-chart-svg" style={svgStyle} ref={svgRef}>
        <LinearGradient
          id={gradientId}
          startStopStyle={gradientStyles[0]}
          endStopStyle={gradientStyles[1]}
        />
        <rect
          x={svgInfo.left}
          y={svgInfo.top}
          width={svgInfo.width}
          height={svgInfo.height}
          fill="white"
          opacity={0}
        />
        <XAxis
          bottom={svgInfo.bottom}
          left={svgInfo.left}
          width={svgInfo.width}
          height={svgInfo.svgHeight}
          scale={x}
          scaleType="LINEAR"
          xDisplayName={xLabelName}
          xLength={xValues.length}
          xMaxLength={d3.max(xValues) || 0}
          rotateXLabel={false}
          labelFontWeight={'normal'}
          labelFontSize={'10px'}
          labelXOffset={40}
          labelYOffset={25}
          textFontSize={'8px'}
          tickValues={filter(xValues, d => d % 10 === 0)}
        />
        <YAxis
          top={svgInfo.top}
          left={svgInfo.left}
          width={svgInfo.width}
          scale={y}
          totalCounts={totalCounts}
          yDisplayName={yLabelName}
          labelFontWeight={'normal'}
          labelFontSize={'10px'}
          textFontSize={'8px'}
          labelXOffset={-14}
          labelYOffset={10}
        />
        <g transform={gTransform} className="outer-group" ref={chartGroupRef}>
          <g className="chart">{bars}</g>
          <g ref={brushRef} />
        </g>
      </svg>
      {isBrushing && brushExtent && brushRef?.current && (
        <Stamp
          brushElement={brushRef.current}
          brushStart={brushExtentPos[0]}
          brushEnd={brushExtentPos[1]}
          data={data}
          scoreMin={(brushExtent && brushExtent[0]) || 0}
          scoreMax={(brushExtent && brushExtent[1]) ?? 100}
          totalLabelCount={totalLabelCount}
          binSize={binSize}
        />
      )}
    </>
  );
};

export default Histogram;
