import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownSelect } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import AssetsService from '../../../../services/AssetsService';

type Props = {
  selectedDatasets: string[] | [];
  changeSelectedDatasets: (selectedDatasets: string[]) => void;
};

const ProjectAnalyticsFilter: React.FC<Props> = props => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { selectedDatasets, changeSelectedDatasets } = props;
  const [options, setOptions] = useState<{ label: string; value: string }[] | []>([]);
  const getDatasetList = async (query?: string) => {
    const datasetList = await AssetsService.getProjectDatasets({
      params: {
        groupIcontains: query,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const result = datasetList.results.map((result: { group: string }) => ({
      label: result.group,
      value: result.group,
    }));
    return result;
  };

  useEffect(() => {
    (async () => {
      const datasetList = await getDatasetList();
      setOptions(datasetList);
    })();
    // eslint-disable-next-line
  }, []);

  const handleChange = async (newValue: string[]) => {
    changeSelectedDatasets(newValue);
  };

  const getOptions = async (query: string) => {
    if (!query) return options;
    return getDatasetList(query);
  };

  return (
    <Box width="200px" display="flex">
      <DropdownSelect
        width={250}
        hasSearch
        value={selectedDatasets}
        getOptions={getOptions}
        onChange={handleChange}
        placeholder={t('analytics.project.datasetDropDown.default')}
      />
    </Box>
  );
};

export default ProjectAnalyticsFilter;
