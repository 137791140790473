import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Button } from '@superb-ai/norwegian-forest';

import { getUrl } from '../../../routes/util';
import { MislabelDetectionResultsPerClass } from '../../../types/mislabelDetectionTypes';
import ImageGrid from '../../elements/windowedImageGrid/ImageGrid';
import ClassInfo from './mislabelDetection/ClassInfo';

type Props = {
  item: MislabelDetectionResultsPerClass;
};

export default function GroupeResultsLayout({ item, children }: React.PropsWithChildren<Props>) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" gap="4px">
        <ClassInfo item={item} />
        <Box ml="auto">
          <Button
            variant="text-simple"
            color="grey"
            iconPosition="right"
            IconAdornment="chevronBigRight"
            onClick={() => {
              history.push(getUrl([history.location.pathname, 'class', item.class_id]));
            }}
          >
            {t('advancedAIFeatures.button.showAll')}
          </Button>
        </Box>
      </Box>
      <ImageGrid columns={5}>{children}</ImageGrid>
    </Box>
  );
}
