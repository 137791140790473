import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { overflowOverlayOrAuto } from '../../../utils/style';
import SidebarFooter from './SidebarFooter';
import SidebarStepper from './SidebarStepper';
import SidebarTip from './SidebarTip';

const useStyles = makeStyles(() => ({
  box: {
    width: '150px',
    minWidth: '150px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    overflow: overflowOverlayOrAuto(),
  },
}));

const Sidebar = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box width="100%">
        <SidebarStepper />
      </MUI.Box>
      <MUI.Box width="100%">
        <SidebarTip />
        <SidebarFooter />
      </MUI.Box>
    </MUI.Box>
  );
};

export default Sidebar;
