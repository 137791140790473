import { useTranslation } from 'react-i18next';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { getUserManualUrl } from '../../../consts/DocsLink';
import { openChatWidget } from '../../../utils/chatWidget';

export default function MfaProblems() {
  const { i18n, t } = useTranslation();
  const { MFA_MANUAL } = getUserManualUrl(i18n.language);

  return (
    <Box display="flex" justifyContent="center" gap={2}>
      <Typography variant="m-regular" color="gray-300">
        {t('auth.mfa.problems.title')}
      </Typography>
      <Box display="flex" flexDirection="column">
        <LinkTypography variant="m-regular" color="primary" onClick={() => openChatWidget()}>
          {t('auth.mfa.problems.contactUs')} →
        </LinkTypography>
        <LinkTypography variant="m-regular" color="primary" href={MFA_MANUAL} target="_blank">
          {t('auth.mfa.problems.userGuide')} →
        </LinkTypography>
      </Box>
    </Box>
  );
}
