import React from 'react';

import { Box, Typography } from '@superb-ai/ui';

interface Props extends React.ComponentProps<typeof Box> {
  title?: string;
}

export default function MenuTitle({
  children,
  title,
  ...otherProps
}: React.PropsWithChildren<Props>): JSX.Element {
  const boxProps: React.ComponentProps<typeof Box> = {
    display: 'flex',
    alignItems: 'center',
    p: 1.5,
    backgroundColor: 'white',
    borderBottom: '1px solid',
    borderColor: 'gray-150',
    ...otherProps,
  };
  return (
    <Box {...boxProps} style={{ height: 50 }}>
      {title ? <Typography variant="l-strong">{title}</Typography> : children}
    </Box>
  );
}
