import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';
import { LinkExternal } from '@superb-ai/icons';
import { Chip, Typography } from '@superb-ai/norwegian-forest';
import { Box, Icon, LinkTypography, Tooltip } from '@superb-ai/ui';

import DataTypeConst from '../../../consts/DataTypeConst';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { getUrl } from '../../../routes/util';
import { ProjectData } from '../../../types/projectTypes';
import { formatDate, formatDistance } from '../../../utils/date';
import { formatCount } from '../../../utils/numberFormat';
import ProjectUtils from '../../../utils/ProjectUtils';
import TextEllipisTooltip from '../../elements/TextEllipsisTooltip';
import { AnnotationTypeIconList } from './AnnotationTypeIconList';

const getPercent = (number: number, total: number) => {
  if (number === 0 || total === 0) return 0;

  return (number / total) * 100;
};

const getFormatedPercent = (number: number, total: number) => {
  return `${Math.round(getPercent(number, total))}`;
};

const ProjectTableRow = ({
  project,
  canViewProjectStatus,
}: {
  project: ProjectData;
  canViewProjectStatus: boolean;
}) => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const {
    history,
    urlMatchInfo: { accountName },
  } = routeInfo;

  const projectType = ProjectUtils.getProjectDataType(project.workapp);

  const handleClickProject = (e: React.MouseEvent, projectId: string) => {
    e.stopPropagation();
    history.push(`/${accountName}/label/project/${projectId}/overview`);
  };

  const { labelingStatus } = project;
  const projectStatusExist = !!project.labelingStatus;
  const projectStatusVisible = projectStatusExist && canViewProjectStatus;
  const total =
    project.labelCount ??
    (labelingStatus
      ? labelingStatus.skipped + labelingStatus.submitted + labelingStatus.working
      : 0);

  return (
    <TableRow hover onClick={e => handleClickProject(e, project.id)}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box mr={0.5} display="flex" alignItems="center">
            {DataTypeConst.getDataTypeIcon(t, projectType)}
          </Box>
          <TextEllipisTooltip maxWidth="205px" text={project.name}>
            <Typography variant="body2">{project.name}</Typography>
          </TextEllipisTooltip>
          <Box ml={1}>
            <Chip color="cloud" size="xxs">
              {project.isPublic ? t('projects.table.public') : t('projects.table.private')}
            </Chip>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        {project.curateDatasetId && project.curateSyncSettings && (
          <Box display="flex" alignItems="center" gap={0.5}>
            <LinkTypography
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="gray-400"
              onClick={e => {
                e.stopPropagation();
                history.push(getUrl([accountName, 'curate', 'dataset', project.curateDatasetId]));
              }}
            >
              {project.curateSyncSettings.datasetName}
            </LinkTypography>
            <Icon icon={LinkExternal} />
          </Box>
        )}
      </TableCell>
      <TableCell>
        <AnnotationTypeIconList items={ProjectUtils.getAnnotationTypes(project.labelInterface)} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" gap={1}>
          {formatCount(total)}
          {projectStatusVisible && (
            <>
              <Box backgroundColor="gray-250" style={{ width: '1px', height: '6px' }} />
              <PercentChip color="yellow" count={labelingStatus?.working ?? 0} total={total} />
              <PercentChip color="green" count={labelingStatus?.submitted ?? 0} total={total} />
              <PercentChip color="cloud" count={labelingStatus?.skipped ?? 0} total={total} />
            </>
          )}
        </Box>
      </TableCell>
      {canViewProjectStatus && (
        <TableCell>
          {projectStatusVisible && (
            <>
              {formatCount(labelingStatus?.submitApproved ?? 0)} (
              {getFormatedPercent(labelingStatus?.submitApproved ?? 0, total)}%)
            </>
          )}
        </TableCell>
      )}
      <TableCell align="center">{formatDate(project.createdAt)}</TableCell>
      <TableCell align="center">{formatDistance(project.lastUpdatedAt)}</TableCell>
    </TableRow>
  );
};

const PercentChip = ({
  color,
  count,
  total,
}: {
  color: ComponentProps<typeof Box>['backgroundColor'];
  count: number;
  total: number;
}) => {
  return (
    <Tooltip content={<Typography>{formatCount(count)}</Typography>} placement="top">
      <Box display="flex" alignItems="center" gap={0.5}>
        <Box backgroundColor={color} borderRadius="100%" style={{ width: '4px', height: '4px' }} />
        {getFormatedPercent(count, total)}%
      </Box>
    </Tooltip>
  );
};

export default ProjectTableRow;
