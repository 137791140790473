import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@superb-ai/norwegian-forest';
import classNames from 'classnames';
import { map, upperCase } from 'lodash';

import DropDownMenu from '../../../elements/DropDownMenu';
import { FileFormat } from '../dataTypes/analyticsDataTypes';

/**
 * Style for Dropdown.
 */
const useStyles = makeStyles(ml => ({
  menuPopper: {
    fontWeight: 500,
    fontSize: '13px',
    // margin: theme.spacing(1), // 1 * 8px scaling factor
    marginLeft: `${ml}px` || '8px',
    '&.disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
}));

/**
 * @param {boolean} isToggleDisabled whether dropdown should be disabled
 */
const DownloadDropDown = (props: {
  handleDownload: (option: FileFormat) => void;
  ml?: number;
  iconButtonEl?: JSX.Element;
  options: FileFormat[];
  isToggleDisabled: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const { handleDownload, ml, iconButtonEl, options, isToggleDisabled } = props;
  const classes = useStyles(ml);

  return (
    <DropDownMenu>
      <MUI.Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        key="anchor"
        style={{ cursor: isToggleDisabled ? 'default' : 'pointer' }}
        className={classNames(classes.menuPopper, {
          disabled: isToggleDisabled,
        })}
      >
        {iconButtonEl || (
          <IconButton
            round
            size="xs"
            icon="download"
            color="textDefault"
            variant="text"
            disabled={isToggleDisabled}
          />
        )}
      </MUI.Box>
      <MUI.Box key="popper">
        {map(options, (option, i) => {
          return (
            <MUI.MenuItem key={`item${i}`} onClick={() => handleDownload(option)}>
              <MUI.Box key={`box${i}`} width={90}>
                {`${t('button.download')} ${upperCase(option)}`}
              </MUI.Box>
            </MUI.MenuItem>
          );
        })}
      </MUI.Box>
    </DropDownMenu>
  );
};

export default DownloadDropDown;
