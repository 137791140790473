import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Editor, { OnChange, OnMount, OnValidate } from '@monaco-editor/react';
import { Box, Button, Input, Typography } from '@superb-ai/norwegian-forest';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { omit } from 'lodash';

import { useDebounce } from '../../../../../hooks/DebounceHook';
import { overflowOverlayOrAuto } from '../../../../../utils/style';
import { createErrorMessage, validate } from './helper';
import { Keypoint } from './type';

type Props = {
  keypoint: Keypoint | null;
  setKeypoint: (keypoint: Keypoint) => void;
  setErrorMessage: (error: string) => void;
  customKeypointName: string;
  setCustomKeypointName: (name: string) => void;
};

const JsonEditor: React.FC<Props> = ({
  keypoint,
  setKeypoint,
  setErrorMessage,
  customKeypointName,
  setCustomKeypointName,
}) => {
  const { t } = useTranslation();
  const debouncedCustomKeypointName = useDebounce(customKeypointName, 300);
  const [editor, setEditor] = useState<any>(null);
  const [value, setValue] = useState('');
  const [requestSave, setRequestSave] = useState(false);

  useEffect(() => {
    if (!keypoint) return;

    setValue(
      JSON.stringify(decamelizeKeys(omit(keypoint, 'id', 'name', 'allowValidInvisibles')), null, 4),
    );
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {
    editor?.getAction('editor.action.formatDocument').run();
    setErrorMessage(t('button.saving'));
    setRequestSave(true);
  };

  useEffect(() => {
    if (!editor) return;
    const save = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 's') {
        e.preventDefault();
        handleSave();
      }
    };

    window.addEventListener('keydown', save);

    return () => {
      window.removeEventListener('keydown', save);
    };
    // eslint-disable-next-line
  }, [editor]);

  useEffect(() => {
    save(debouncedCustomKeypointName);
  }, [debouncedCustomKeypointName]);

  const save = (name?: string) => {
    try {
      let nextErrorMessage = '';

      nextErrorMessage = validateName(customKeypointName);
      if (nextErrorMessage) {
        setErrorMessage(nextErrorMessage);
        return;
      }

      const parsedValue: any = camelizeKeys(JSON.parse(value));

      if (name) {
        parsedValue.name = name;
      } else if (parsedValue.name) {
        setCustomKeypointName(parsedValue.name);
      }

      const keypointKeys = [
        { value: 'points', type: 'array' },
        { value: 'edges', type: 'array' },
      ];
      const validateKeypointKeys = validate(parsedValue, keypointKeys);
      nextErrorMessage = createErrorMessage(validateKeypointKeys, keypointKeys, '', t);

      if (nextErrorMessage) {
        setErrorMessage(nextErrorMessage);
        return;
      }
      const pointKeys = [
        { value: 'color', type: 'string' },
        { value: 'defaultValue', type: 'object' },
        { value: 'name', type: 'string' },
      ];
      const defaultValueKeys = [
        { value: 'state', type: 'object' },
        { value: 'x', type: 'number' },
        { value: 'y', type: 'number' },
      ];
      const stateKeys = [{ value: 'visible', type: 'boolean' }];

      if (
        parsedValue.points.some((point: any, index: number) => {
          const validatePoint = validate(point, pointKeys);
          nextErrorMessage = createErrorMessage(validatePoint, pointKeys, `points[${index}].`, t);
          if (nextErrorMessage) {
            setErrorMessage(nextErrorMessage);
            return true;
          }

          const validateDefaultValue = validate(point.defaultValue, defaultValueKeys);
          nextErrorMessage = createErrorMessage(
            validateDefaultValue,
            defaultValueKeys,
            `points[${index}].default_value.`,
            t,
          );
          if (nextErrorMessage) {
            setErrorMessage(nextErrorMessage);
            return true;
          }

          const validateState = validate(point.defaultValue.state, stateKeys);
          nextErrorMessage = createErrorMessage(
            validateState,
            stateKeys,
            `points[${index}].default_value.state.`,
            t,
          );
          if (nextErrorMessage) {
            setErrorMessage(nextErrorMessage);
            return true;
          }

          return false;
        })
      ) {
        return;
      }
      const edgeKeys = [
        { value: 'u', type: 'number' },
        { value: 'v', type: 'number' },
        { value: 'color', type: 'string' },
      ];
      if (
        parsedValue.edges.some((edge: any, index: number) => {
          const validateEdge = validate(edge, edgeKeys);
          nextErrorMessage = createErrorMessage(validateEdge, edgeKeys, `edges[${index}].`, t);
          if (nextErrorMessage) {
            setErrorMessage(nextErrorMessage);
            return true;
          }

          if (parsedValue.points[edge.u] === undefined) {
            setErrorMessage(t('projects.createProject.keypoint.edgeuMustExist', { index }));
            return true;
          }

          if (parsedValue.points[edge.v] === undefined) {
            setErrorMessage(t('projects.createProject.keypoint.edgevMustExist', { index }));
            return true;
          }

          if (parsedValue.points[edge.u] === parsedValue.points[edge.v]) {
            setErrorMessage(t('projects.createProject.keypoint.edgesMustDifferent', { index }));
            return true;
          }

          return false;
        })
      ) {
        return;
      }

      parsedValue.allowValidInvisibles = true;

      // clear
      setKeypoint(parsedValue);
      setErrorMessage('');
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    if (!requestSave) return;
    save();
    setRequestSave(false);
    // eslint-disable-next-line
  }, [requestSave]);

  const handleEditorDidMount: OnMount = editor => {
    setEditor(editor);
  };

  const handleChange: OnChange = value => {
    if (value === undefined) return;
    setValue(value);
    setErrorMessage(t('projects.createProject.keypoint.entering'));
  };

  const handleValidate: OnValidate = markers => {
    if (markers.length === 0) {
      setRequestSave(true);
      return;
    }
    setErrorMessage(markers[0].message);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const nextName = e.target.value;
    setCustomKeypointName(nextName);
    setErrorMessage(t('projects.createProject.keypoint.entering'));
  };

  const validateName = (name: string) => {
    if (name.trim().length <= 0 || name.trim().length > 50) {
      return t('projects.createProject.keypoint.nameConstraint');
    }
    return '';
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="16px"
    >
      <Box width="100%" flex={1} display="flex" overflow="overlay" alignItems="center" gap="16px">
        <Typography variant="headline6">{t('shared.name')}</Typography>
        <Box display="flex" flex={1}>
          <Input
            size="xs"
            value={customKeypointName}
            onChange={handleChangeName}
            onKeyDown={e => e.stopPropagation()}
            required
          />
        </Box>
      </Box>
      <Box width="100%" height="380px" shadow={1} borderRadius="8px">
        <Editor
          width="100%"
          height="100%"
          language="json"
          value={value}
          onChange={handleChange}
          onMount={handleEditorDidMount}
          onValidate={handleValidate}
        />
      </Box>
      <Box width="100%" flex={1} display="flex" overflow={overflowOverlayOrAuto()}>
        <Button onClick={handleSave}>{t('button.save')}</Button>
      </Box>
    </Box>
  );
};

export default JsonEditor;
