import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  FileTreeModel,
  FileType,
  getTreeModel,
  IFileTreeXHandle,
  IItem,
  searchTree,
  splitPath,
  useContainerSize,
} from '@superb-ai/norwegian-forest';

import { ObjectClass } from '../../../../../../utils/LabelInterfaceUtils';
import ObjectTree from '../../../../../elements/tree/ObjectTree';

interface TreeData<D> {
  name: string;
  path: string;
  data?: D;
  children: TreeData<D>[];
}

interface Props {
  items: ObjectClass[];
  selectedItems: string[];
  onChange(selectedIds: string[]): void;
  disabledItems: string[];
}

function prepareTree(items: ObjectClass[]): TreeData<ObjectClass> {
  function mapItems(items: ObjectClass[], parentPath = ''): TreeData<ObjectClass>[] {
    return items.map(item => ({
      name: item.name,
      path: `${parentPath}/${item.name}`,
      data: item,
      children: item.children ? mapItems(item.children, `${parentPath}/${item.name}`) : [],
    }));
  }

  return {
    name: '',
    path: '/',
    children: mapItems(items),
  };
}

function findNodeBydId(fileTree: TreeData<ObjectClass>, id: string): TreeData<ObjectClass> | null {
  if (fileTree.data?.id === id) return fileTree;
  for (const node of fileTree.children) {
    const found = findNodeBydId(node, id);
    if (found) return found;
  }
  return null;
}

export default function SelectClassesTree(props: Props): JSX.Element {
  const { items, selectedItems, onChange, disabledItems } = props;

  const [treeModel, setTreeModel] = useState<FileTreeModel | null>(null);
  const [treeHandle, setTreeHandle] = useState<IFileTreeXHandle | null>(null);
  const initialized = useRef(false);
  const initDebounce = useRef<null | NodeJS.Timeout>(null);

  const ref = useRef<HTMLDivElement>(null);

  const { width, height } = useContainerSize(ref);

  const fileTree = useMemo(() => prepareTree(items), [items]);

  useEffect(() => {
    const getObjectsAtPath = async (path: string): Promise<IItem[]> => {
      // @ts-ignore: assume all items have children
      const node = searchTree(fileTree, splitPath(path));
      if (!node?.children) return [];
      return node.children.map(item => ({
        name: item.name,
        data: item.data,
        type: item.children?.length ? FileType.Directory : FileType.File,
      }));
    };

    const treeModel = getTreeModel(getObjectsAtPath, () => 0); // the second parameter disables sorting
    setTreeModel(treeModel);
  }, [fileTree]);

  // From React to Tree
  useEffect(() => {
    if (!treeHandle || !fileTree) return;

    // For some reason that I don't know, this effect can get triggered multiple times,
    // which can override the selection. Debounce is a very hacky fix for that.
    if (initDebounce.current) {
      clearTimeout(initDebounce.current);
    }
    initDebounce.current = setTimeout(async () => {
      const selectedPaths = selectedItems.flatMap(id => findNodeBydId(fileTree, id)?.path ?? []);
      const disabledPaths = disabledItems
        .flatMap(id => findNodeBydId(fileTree, id)?.path ?? [])
        .filter(path => !selectedPaths.includes(path));

      initialized.current = false;
      treeHandle.resetActiveFiles();
      await treeHandle.setActiveFiles(selectedPaths);
      treeHandle.setDisabledFiles(disabledPaths);
      initialized.current = true;
    }, 50);
  }, [fileTree, treeHandle, selectedItems, disabledItems]);

  // From Tree to React
  const handleSetSelected = useCallback(() => {
    if (!treeHandle || !initialized.current) return;
    const activeFiles = treeHandle.getActiveFiles(true);
    const nodes = activeFiles.map(file => searchTree(fileTree, splitPath(file.path)));
    const selectedLeafIds = nodes.flatMap(node =>
      !node?.children?.length ? node?.data?.id ?? [] : [],
    );
    // TODO: need to use branch nodes as well because they might be unexpanded
    onChange(selectedLeafIds);
  }, [fileTree, treeHandle, onChange]);

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      {treeModel && (
        <ObjectTree
          onReady={setTreeHandle}
          height={height}
          width={width}
          model={treeModel}
          onSelect={handleSetSelected}
          selectMultiple
          expandAll
          showIcons={false}
          selectableTypes={[FileType.File, FileType.Directory]}
          visibilityChangeTypes={[]}
        />
      )}
    </div>
  );
}
