import MathUtils from '../../../../../utils/MathUtils';
import { AssigneeLabelStatusDto } from '../../dataTypes/analyticsDataTypes';
import { JsonObj, LabelerLabelsDatum } from '../types';

const addReviewProgress = (rows: JsonObj[]): JsonObj[] => {
  return rows.map(member => {
    return {
      ...member,
      progress: MathUtils.calculatePercent({
        numerator: member?.approved,
        denominator: member?.reviewAssigned,
      }),
    };
  });
};

const addLabelerProgress = (rows: AssigneeLabelStatusDto[], tables: JsonObj[]) => {
  const userToLabels: Record<string, LabelerLabelsDatum> = rows
    ? rows.reduce((agg, datum) => {
        const { assignee, ...labels } = datum;
        Object.assign(agg, { [assignee]: labels });
        return agg;
      }, {})
    : {};

  const users = Object.entries(userToLabels).map(userLabel => userLabel[1]);
  const progress = users.map(user => {
    const complete = user?.cumSkipped + user?.cumSubmitted;
    const allAssignedLabels = complete + user?.cumWorking;
    return MathUtils.calculatePercent({
      numerator: complete,
      denominator: allAssignedLabels,
    });
  });

  tables.forEach((table, i) => {
    table['progress'] = progress[i];
    //if (table['frameCounts']) table['frameProgress'] = progress[i];
  });

  return tables;
};

export { addLabelerProgress, addReviewProgress };
