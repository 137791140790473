import { TFunction } from 'next-i18next';

import AccessKey from '../steps/AccessKey';
import CliInstallation from '../steps/CliInstallation';
import UploadCmd from '../steps/UploadCmd';

const CLI = (t: TFunction) => {
  const INSTALLATION = 'installation';
  const ACCESS_KEY = 'access_key';
  const UPLOAD_VIA_CLI = 'upload_via_cli';

  const steps = {
    [INSTALLATION]: CliInstallation(t),
    [ACCESS_KEY]: AccessKey(t),
    [UPLOAD_VIA_CLI]: UploadCmd(t),
  };

  return steps;
};

export default CLI;
