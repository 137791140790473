import React from 'react';
import { useTranslation } from 'react-i18next';

import { SegmentedControl } from '@superb-ai/ui';

import { useActionContext } from '../../../../../contexts/ActionContext';
import { TargetMetric } from './AnalyticsContents';

export default function AnalyticsLeftSection() {
  const { targetMetric, setTargetMetric } = useActionContext();
  const { t } = useTranslation();
  const targetMetricOptions = [
    { value: 'annotation', label: t('curate.analytics.annotation') },
    { value: 'metadata', label: t('curate.analytics.metadata') },
  ];

  return (
    <SegmentedControl
      options={targetMetricOptions}
      value={targetMetric}
      onChangeValue={(value: TargetMetric) => setTargetMetric(value)}
    />
  );
}
