import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { InfoChat } from '@superb-ai/icons';
import { Button, Icon } from '@superb-ai/ui';

import analyticsTracker from '../../analyticsTracker';
import { useAuthInfo } from '../AuthContext';

const HelpHubLauncher = () => {
  const { t, i18n } = useTranslation();
  const { accountName } = useAuthInfo();
  const { suite } = useParams<{ suite: 'label' | 'curate' | 'model' }>();
  const handleClick = () => {
    if (window.CommandBar) {
      analyticsTracker.helphubClicked({ accountId: accountName, product: suite });
      window.CommandBar.toggleHelpHub();
    } else {
      console.warn('CommandBar SDK not found');
    }
  };

  return (
    <Button
      variant="text"
      color="gray-500"
      onClick={handleClick}
      style={{
        minWidth: i18n.language == 'ko' ? '155px' : '120px',
      }}
    >
      <Icon icon={InfoChat} size="16px" color="gray-500" style={{ marginRight: '2px' }} />
      {t('integration.needHelp')}
    </Button>
  );
};

export default HelpHubLauncher;
