import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ChevronDown, Hide, Show } from '@superb-ai/icons';
import { Box, Collapse, Icon, IconButton, Typography } from '@superb-ai/ui';

import { getAnnotationIcon, textEllipsisStyle } from '../../components/components';
import { FormattedAnnotation } from './SamplePredictionCabinet';

export default function AnnotationsCabinetCollapse({
  annotation,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  objectAnnotationId,
  clickedAnnotationId,
  hoveredAnnotationId,
  children,
}: PropsWithChildren<{
  annotation: FormattedAnnotation;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  objectAnnotationId?: string;
  clickedAnnotationId: string | undefined;
  hoveredAnnotationId: string | undefined;
}>) {
  const [open, setOpen] = useState(
    annotation.annotations.filter(list => list.id === objectAnnotationId).length > 0,
  );

  const hasUnfilteredAnnotation = useMemo(
    () => annotation.annotations.filter(list => !filteredAnnotationsIds.includes(list.id)),
    [filteredAnnotationsIds, annotation],
  );

  const IncludeObjectAnnotatin = useMemo(
    () => annotation.annotations.filter(list => list.id === objectAnnotationId),
    [objectAnnotationId, annotation],
  );

  const isClickedObjectAnnotation = useMemo(
    () => annotation.annotations.filter(list => list.id === clickedAnnotationId).length > 0,
    [clickedAnnotationId, annotation],
  );

  const isHoveredObjectAnnotation = useMemo(
    () => annotation.annotations.filter(list => list.id === hoveredAnnotationId).length > 0,
    [annotation.annotations, hoveredAnnotationId],
  );

  useEffect(() => {
    if (isClickedObjectAnnotation) setOpen(true);
    else setOpen(false);
  }, [isClickedObjectAnnotation]);

  return (
    <Box
      bb="1px solid"
      borderColor="white"
      backgroundColor={{
        default: isClickedObjectAnnotation || isHoveredObjectAnnotation ? 'primary-100' : 'white',
        hover: 'gray-100',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        key={annotation.id}
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setOpen(!open)}
        pl={1.5}
      >
        <Box style={{ ...textEllipsisStyle }} display="flex" alignItems="center" gap={1}>
          <Icon
            icon={getAnnotationIcon(annotation.type)}
            color={
              hasUnfilteredAnnotation.length > 0
                ? IncludeObjectAnnotatin.length > 0
                  ? 'primary-400'
                  : 'gray-400'
                : 'gray-300'
            }
          />
          <Box display="flex">
            <Typography
              variant="m-strong"
              color={
                hasUnfilteredAnnotation.length > 0
                  ? IncludeObjectAnnotatin.length > 0
                    ? 'primary-400'
                    : 'gray-400'
                  : 'gray-300'
              }
            >
              {annotation.name}
            </Typography>
            <Typography
              variant="m-strong"
              ml={0.5}
              color={
                hasUnfilteredAnnotation.length > 0
                  ? IncludeObjectAnnotatin.length > 0
                    ? 'primary-400'
                    : 'gray-400'
                  : 'gray-300'
              }
            >{`(${annotation.count})`}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            icon={hasUnfilteredAnnotation.length > 0 ? Show : Hide}
            variant="text"
            onClick={e => {
              setFilteredAnnotationsIds(prev => {
                const annotationIds = annotation.annotations.map(list => list.id);
                const newList = prev.filter(list => !annotationIds.includes(list));
                if (hasUnfilteredAnnotation.length === annotationIds.length)
                  return [...prev, ...annotationIds];
                else if (
                  hasUnfilteredAnnotation.length > 0 &&
                  hasUnfilteredAnnotation.length < annotationIds.length
                )
                  return [...newList, ...annotationIds];
                else return [...newList];
              });
              e.stopPropagation();
            }}
            color={hasUnfilteredAnnotation.length > 0 ? 'gray' : 'cloud'}
          />
          <Box
            display="flex"
            style={{
              transition: 'all 0.1s ease-in-out',
              ...(open ? {} : { transform: 'rotate(-90deg)' }),
            }}
          >
            <IconButton icon={ChevronDown} variant="text" />
          </Box>
        </Box>
      </Box>
      <Collapse open={open}>{children}</Collapse>
    </Box>
  );
}
