import React, { ReactElement, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    '&.dragEntered': {
      background: '#fff6f6',
      opacity: '0.5',
    },
  },
}));

const CategoryListDropZone = (): ReactElement => {
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();

  const [isDragEntered, setIsDragEntered] = useState(false);

  const handleMouseDown = () => {
    newProjectInfo.setSelectedCategories({});
    newProjectInfo.setSelectedCategoryGroupId('');
  };

  const handleDragEnter = () => {
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;
    setIsDragEntered(true);
  };
  const handleDragLeave = () => {
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;
    setIsDragEntered(false);
  };

  const handleDrop = () => {
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;

    const ids = Object.keys(newProjectInfo.selectedCategories);
    helper.moveCategoryToGroup(newProjectInfo, ids, 'root', null);
    newProjectInfo.setDragState(null);
    setIsDragEntered(false);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${isDragEntered ? 'dragEntered' : ''}`}
      onMouseDown={handleMouseDown}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default CategoryListDropZone;
