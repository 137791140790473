import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, RadioGroup, Typography } from '@superb-ai/ui';
import { debounce } from 'lodash';

import {
  useAccessAccountMutation,
  useAccessAccountQuery,
} from '../../../../queries/useAccessAccountQuery';

const Advanced = () => {
  const { t } = useTranslation();
  const { data, isRefetching } = useAccessAccountQuery();
  const { mutate: toggleAccessAccount } = useAccessAccountMutation();

  const allowSystem = data?.allowSystem ?? false;

  const handleChange = (value: boolean) => {
    if (allowSystem !== value && !isRefetching) {
      toggleAccessAccount();
    }
  };

  const controlledHandleChange = debounce(handleChange, 200);

  const RadioOptions = [
    { value: true, label: t('settings.advanced.radioButtons.allow') },
    { value: false, label: t('settings.advanced.radioButtons.dontAllow') },
  ] as const;

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <Typography variant="h3">{t('settings.advanced.accountAccess')}</Typography>
      </Box>
      <Box display="flex" px={3} boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)" borderRadius="2px">
        <Box py={3} pr={4} borderRight="1px solid" borderColor="gray-150">
          <Box mb={1.5}>
            <Typography style={{ whiteSpace: 'nowrap' }} variant="l-strong" color="gray-400">
              {t('settings.advanced.allowAccess')}
            </Typography>
          </Box>
          <RadioGroup
            onChangeValue={controlledHandleChange}
            size={30}
            options={RadioOptions}
            value={allowSystem}
          />
        </Box>
        <Box py={3} pl={4}>
          <Typography variant="m-regular" color="gray-400">
            <Trans t={t} i18nKey="settings.advanced.allowAccessExplanation">
              When you enable this option, our staff can access your account to provide support.
              <br />
              Superb AI reserves the right to access your account without prior notice in certain
              situations.
              <br />
              These include emergencies to prevent harm to you or other users, and situations where
              we
              <br />
              suspect your use of our Service is violating our Terms of Use or Privacy Policy.
            </Trans>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Advanced;
