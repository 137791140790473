import { ComponentProps, ReactElement } from 'react';

import { Box, NamedColor, NamedColorWeight, Tooltip, Typography } from '@superb-ai/ui';

import { AP_THRESHOLD_COLOR } from '../colorScale';
import { Circle } from '../elements/Circle';
import { formatDecimalPrecision } from '../transformer';

export type MetricInfo = {
  name: string;
  threshold: number | string;
  value?: number;
  tooltipText?: string | ReactElement;
};

type Props = {
  metric: MetricInfo;
  color: NamedColorWeight | NamedColor;
  style?: ComponentProps<typeof Box>['style'];
} & ComponentProps<typeof Box>;

function MeanAvpMiniCard(props: Props) {
  const { metric, style, color, ...otherProps } = props;

  const formattedValue = formatDecimalPrecision(metric.value ?? 0, 'ap');

  const MetricScoreWithTooltip = (metric: MetricInfo) => {
    const MetricScore = (
      <Typography variant={'m-regular'} color="gray-500">
        {metric.name}
        <text style={{ color: AP_THRESHOLD_COLOR, fontSize: '10px' }}>{`(%)  ${
          metric.threshold ?? '@0.5'
        }`}</text>
      </Typography>
    );
    return (
      <Tooltip hideOnEmptyContent content={metric.tooltipText} placement={'bottom'}>
        {MetricScore}
      </Tooltip>
    );
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      ml="auto"
      p={1.5}
      mr={1.5}
      style={{
        backgroundColor: '#F5F5F5',
        height: '37px',
        ...style,
      }}
      {...otherProps}
      gap={1}
    >
      <Box display="flex" justifyContent="center" alignItems="baseline" gap={1}>
        <Circle color={color ?? 'red-400'} />
        {MetricScoreWithTooltip(metric)}
        <Box display="flex" ml="auto">
          <Typography variant={'l-strong'} color="gray-500" mr={1}>
            {formattedValue ?? '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MeanAvpMiniCard;
