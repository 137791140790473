import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Button, Card, Typography, useAlertModal } from '@superb-ai/norwegian-forest';

import { CANNOT_DELETE_PROJECT } from '../../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import LabelsService from '../../../../../services/LabelsService';
import DeleteConfirmDialog from './DeleteConfirmDialog';

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();

  const history = useHistory();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const {
    urlMatchInfo: { accountName, projectId },
  } = routeInfo;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickDeleteProject = async () => {
    setIsLoading(true);
    try {
      const labels = await LabelsService.getLabels({
        params: {},
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const { count } = labels;

      if (count > 0) {
        const handleClickDeleteAllLabels = () => {
          history.push(`/${accountName}/label/project/${projectId}/labels`);
          closeModal();
        };

        openModal({
          ...CANNOT_DELETE_PROJECT({ t, onClickLink: handleClickDeleteAllLabels }),
          mainButton: {
            text: t('button.ok'),
            onClick: closeModal,
          },
        });

        return;
      }
      setIsConfirmDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card display="flex" justifyContent="space-between" p={3} mb={3}>
        <Box>
          <Typography variant="headline5">{t('projectSettings.deleteProject.title')}</Typography>
          <Box pt={1} />
          <Typography variant="body3">{t('projectSettings.deleteProject.explanation')}</Typography>
        </Box>
        <Box>
          <Button
            variant="stroke"
            color="primary"
            onClick={handleClickDeleteProject}
            isLoading={isLoading}
            loadingText={t('button.verifying')}
          >
            {t('projectSettings.deleteProject.deleteButton')}
          </Button>
        </Box>
      </Card>
      <DeleteConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
      />
    </>
  );
};

export default Layout;
