import { useRef } from 'react';

import {
  Axis,
  BarSeries,
  Chart,
  Placement,
  PointerOverEvent,
  PointerUpdateListener,
  Position,
  ScaleType,
  Settings,
} from '@elastic/charts';
import { Box } from '@superb-ai/ui';
import { truncate } from 'lodash';

import { CHART_CONFIG, getCustomTheme, xAxisTickLabel } from './customTheme';
import { ChartDatum } from './types';

type SeriesProps = {
  id: string;
  data: ChartDatum[];
  color: string;
  xAxis: { label: string };
  yAxis: { label: string };
};

type ChartMultiSeriesProps = {
  series: SeriesProps[];
};

export function CompareBarCharts({ series }: ChartMultiSeriesProps) {
  const topRef = useRef<Chart>(null);
  const bottomRef = useRef<Chart>(null);
  const pointerUpdate = (event: PointerOverEvent) => {
    if (topRef.current) {
      topRef.current.dispatchExternalPointerEvent(event);
    }
    if (bottomRef.current) {
      bottomRef.current.dispatchExternalPointerEvent(event);
    }
  };
  const topChart = series[0];
  const bottomChart = series[1];
  const chartWidth = Math.max(CHART_CONFIG.DEFAULT_CHART_WIDTH, topChart?.data.length * 21);
  return (
    <Box display="flex" flexDirection="column">
      <Chart ref={topRef} size={{ width: `${chartWidth}px`, height: '50%' }} id="top-chart">
        <Settings
          onPointerUpdate={pointerUpdate as PointerUpdateListener}
          // pointerUpdateTrigger={trigger}
          pointerUpdateDebounce={CHART_CONFIG.DEBOUNCE_DELAY}
          externalPointerEvents={{
            tooltip: { visible: true, placement: Placement.Bottom },
          }}
          theme={getCustomTheme(topChart?.color)}
        />
        <Axis
          id="bottom"
          position={Position.Bottom}
          style={{ tickLabel: xAxisTickLabel({ dataCount: topChart.data.length }) }}
          showOverlappingTicks
          tickFormat={d => truncate(d, { length: 20 })}
        />
        <Axis id="selection-left" position={Position.Left} title={topChart?.yAxis.label} />
        <BarSeries
          id={topChart?.id}
          xScaleType={ScaleType.Ordinal}
          yScaleType={ScaleType.Linear}
          xAccessor="key"
          yAccessors={['count']}
          displayValueSettings={{ showValueLabel: true }}
          color={topChart.color}
          data={topChart.data}
        />
      </Chart>
      <Chart ref={bottomRef} size={{ width: `${chartWidth}px`, height: '50%' }} id="bottom-chart">
        <Settings
          pointerUpdateDebounce={CHART_CONFIG.DEBOUNCE_DELAY}
          onPointerUpdate={pointerUpdate as PointerUpdateListener}
          externalPointerEvents={{
            tooltip: { visible: true, placement: Placement.Bottom, boundary: 'chart' },
          }}
          theme={getCustomTheme(bottomChart?.color)}
        />
        <Axis id="compared-left" position={Position.Left} title={bottomChart?.yAxis.label} />
        <Axis
          id="bottom"
          position={Position.Bottom}
          title={'Metadata'}
          style={{ tickLabel: xAxisTickLabel({ dataCount: bottomChart.data.length }) }}
        />
        <BarSeries
          id={bottomChart?.id}
          xScaleType={ScaleType.Ordinal}
          yScaleType={ScaleType.Linear}
          xAccessor="key"
          yAccessors={['count']}
          displayValueSettings={{ showValueLabel: true }}
          color={bottomChart.color}
          data={bottomChart.data}
        />
      </Chart>
    </Box>
  );
}
