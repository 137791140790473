import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR, RESEND_ACCOUNT_ACTIVATION_LINK } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useAuthService } from '../../../services/NewAuthService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import { useChangeContent } from './Layout';

export default function EmailVerifyLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const { openModal, closeModal } = useAlertModal();
  const { resendCode } = useAuthService();
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { tenantId, email } = routeInfo.params;

  useChangeContent('valueProposition');

  useEffect(() => {
    if (!tenantId || !email) {
      routeInfo.history.push('/auth/login');
    }
  }, [tenantId, email]);

  const handleClickResendCode = async (): Promise<void> => {
    if (isRequesting) return;

    try {
      setIsRequesting(true);

      await resendCode({ email: atob(email), tenantUuid: tenantId });

      openModal(RESEND_ACCOUNT_ACTIVATION_LINK({ t, closeModal }));
    } catch (err: any) {
      openModal(DEFAULT_ERROR({ t, message: err.message, onClickMainButton: closeModal }));
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column">
        <Typography variant="h1" color="gray-400">
          {t('auth.messages.checkYourEmail')}
        </Typography>
        <Typography variant="m-regular">{t('auth.messages.weSentYouAnEmail')}</Typography>
        <Box
          my={2}
          p={1}
          backgroundColor="gray-100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="2px"
        >
          <Typography variant="l-strong">✉️ {atob(email)}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="s-regular">
            <Trans t={t} i18nKey="auth.messages.clickToResendActivationLink">
              <LinkTypography color="secondary" onClick={handleClickResendCode}>
                Click here to resend
              </LinkTypography>{' '}
              account activation link.
            </Trans>
          </Typography>
        </Box>
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
