import React from 'react';
import { TFunction } from 'react-i18next';

import { Icon } from '@superb-ai/norwegian-forest';

import { DataType } from '../utils/LabelInterfaceUtils';

export function getSupportedDataType(t: TFunction) {
  return {
    image: {
      icon: 'imageOutline' as const,
      label: t('consts.dataTypeConst.image'),
    },
    video: {
      icon: 'playCircleOutline' as const,
      label: t('consts.dataTypeConst.video'),
    },
    pointclouds: {
      icon: 'lidar' as const,
      label: t('consts.dataTypeConst.pointclouds'),
    },
  };
}

const getDataTypeIcon = (t: TFunction, projectType: DataType): JSX.Element => {
  return <Icon name={getSupportedDataType(t)[projectType]?.icon || 'clear'} size="16px" />;
};

const DataTypes = ['image', 'video', 'pointclouds'];

export default {
  getDataTypeIcon,
  DataTypes,
};
