import tokens from '@superb-ai/ui/dist/theme/tokens';

import { COLOR_CHIP_TAG_DEFAULT } from '../consts/ColorChips';

export const getLabelTypeColor = (type: 'current' | 'main' | 'autoLabel' | 'consensus'): string =>
  ({
    current: COLOR_CHIP_TAG_DEFAULT.GRAPEFRUIT,
    main: COLOR_CHIP_TAG_DEFAULT.GREENY_YELLOW,
    autoLabel: COLOR_CHIP_TAG_DEFAULT.LIGHTISH_BLUE,
    consensus: COLOR_CHIP_TAG_DEFAULT.BRIGHT_LAVENDER,
  }[type]);

export function getColorsOf400() {
  return Object.entries(tokens).reduce((result: string[], [colorName, colorValue]) => {
    if (
      !colorName.toLowerCase().startsWith('primary') &&
      !colorName.toLowerCase().startsWith('secondary') &&
      !colorName.toLowerCase().startsWith('gray') &&
      colorName.toLowerCase() !== 'black' &&
      colorName.toLowerCase() !== 'white' &&
      colorName.toLowerCase() !== 'overlays'
    ) {
      if ('400' in colorValue && colorValue['400'].value) {
        result.push(colorValue['400'].value);
      }
    }
    return result;
  }, []);
}
