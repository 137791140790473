import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { SingleValue } from 'react-select';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { Option } from '../../../../../../../types/selectTypes';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../contexts/ObjectScopeContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useDatasetObjectCountQuery } from '../../../../../queries/datasetObjectQueries';
import {
  useUpdateSliceJobByAnnosMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { getView } from '../../../../../utils/routeUtils';
import DiagnosisAddSliceModalSelectSlice from './DiagnosisAddSliceModalSelectSlice';
import SliceSummary from './SliceSummary';

export default function ObjectAddSliceModal({
  addSliceModalIsOpen,
  setAddSliceModalIsOpen,
}: {
  addSliceModalIsOpen: boolean;
  setAddSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId, sliceId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId: string;
  }>();
  const { t } = useTranslation();
  const { queryString } = useQueryContext();
  const { selectedData, setSelectedData, selectedAllData, setSelectedAllData } = useActionContext();
  const { totalCount } = useObjectScopeContext();

  const [selectedSlice, setSelectedSlice] = useState<SingleValue<Option>>();
  const [isLoading, setIsLoading] = useState(false);

  const { getDataset } = useCurateDatasetService();
  const { setDatasetInfo } = useDatasetContext();
  const commandContext = useCurateCommandContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { appliedFilters } = useObjectFilterContext();
  const history = useHistory();
  const viewType = getView(history);

  const trackDataSliceUpdated = useCallback(
    (selectedSliceId: string, dataCount?: number, selectedCellCount?: number) => {
      analyticsTracker.dataSliceUpdated({
        datasetId,
        accountId: accountName,
        sliceId: selectedSliceId,
        viewType: viewType,
        dataType: 'image',
        dataCount: dataCount,
        selectedCount: selectedCellCount,
        referrer: 'explore-object',
        filters: combineFiltersForTracking(appliedFilters, sliceId),
        queryString,
      });
    },
    [accountName, appliedFilters, datasetId, queryString, sliceId, viewType],
  );

  const selectedImageIdsSet = selectedData.reduce((acc, data) => {
    if (data.imageId) {
      acc.add(data.imageId);
    }
    return acc;
  }, new Set<string>());

  const selectedImageIds = Array.from(selectedImageIdsSet);

  const { data: annotations, isLoading: isLoadingAnnotationCount } = useDatasetObjectCountQuery({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    queryString,
    appliedFilters,
  });

  const { mutateAsync: startUpdateSliceJob, isLoading: isUpdatingSliceJob } =
    useUpdateSliceJobWithIdsMutation({
      datasetId,
      commandContext,
      selectedImageIds,
    });

  const { mutateAsync: startUpdateSliceJobByAnnos, isLoading: isUpdatingSliceByAnnsJob } =
    useUpdateSliceJobByAnnosMutation({
      datasetId,
      commandContext,
      queryString,
      annotationFilter: appliedFilters || { metadata_in: {} },
    });

  const handleAddSlice = async () => {
    setIsLoading(true);
    if (!selectedSlice) return;
    try {
      if (!selectedData?.length) {
        startUpdateSliceJobByAnnos({
          sliceId: selectedSlice.value,
        });
        // selected all does not have object and image count
        trackDataSliceUpdated(selectedSlice.value);
      } else {
        startUpdateSliceJob({ sliceId: selectedSlice.value });
        trackDataSliceUpdated(selectedSlice.value, selectedImageIds.length, selectedObjects);
      }
      const datasetInfo = await getDataset({
        datasetId,
        fromPublicDatasets: showPublicDatasets,
        expand: ['image_count', 'slice_count'],
      });
      setDatasetInfo(datasetInfo);
      if (setSelectedAllData && setSelectedData) {
        setSelectedAllData(false);
        setSelectedData([]);
      }
    } finally {
      setAddSliceModalIsOpen(false);

      setIsLoading(false);
    }
  };

  const selectedObjects = selectedData?.length || annotations?.count || 0;

  return (
    <Modal
      open={addSliceModalIsOpen}
      close={{
        onClose: () => {
          setAddSliceModalIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.AddToExistingSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleAddSlice,
        color: 'primary',
        isLoading,
        disabled: !selectedSlice,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setAddSliceModalIsOpen(false);
        },
      }}
    >
      <Box px={4} py={2.5}>
        {annotations && (
          <SliceSummary
            selectedObjects={selectedObjects}
            // imageCount={imageToAddToSlice()}
            pt={2}
            px={2}
            mb={2}
          />
        )}
        <Box pb={0.5} display="flex" justifyContent="space-between">
          <Typography variant="m-regular" color="black">
            {t('curate.dialogs.AllSlicesCount', { count: totalCount })}
          </Typography>
        </Box>
        <DiagnosisAddSliceModalSelectSlice
          selectedSlice={selectedSlice}
          setSelectedSlice={setSelectedSlice}
        />
        {selectedAllData ||
          (selectedData?.length > 0 && (
            <Box mt={2}>
              <Typography variant="m-regular">
                {selectedAllData ? (
                  <Trans t={t} i18nKey="curate.dialogs.selectedAll">
                    Selected
                    <Typography color="primary">All</Typography>
                  </Trans>
                ) : (
                  <Trans
                    t={t}
                    i18nKey="curate.dialogs.selectedCount"
                    values={{ count: selectedData.length }}
                  >
                    Selected
                    <Typography color="primary">{selectedData?.length}</Typography>
                  </Trans>
                )}
              </Typography>
            </Box>
          ))}
      </Box>
    </Modal>
  );
}
