import { useState } from 'react';

import constate from 'constate';

export type ScatterViewMode = 'thumbnails' | 'points';

function _useMode({}: {}) {
  const [scatterViewMode, setScatterViewMode] = useState<ScatterViewMode>('points');

  return { scatterViewMode, setScatterViewMode };
}

export const [ViewModeProvider, useViewMode, useSetViewMode] = constate(
  _useMode,
  ({ scatterViewMode }) => scatterViewMode,
  ({ setScatterViewMode }) => setScatterViewMode,
);
