import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';

import { DonutDatum } from '../../chartContainers/objectPropertyChart/interface';
import { ClickedEventProps } from '../../chartContainers/types';
import { SvgConfigDonutObject } from '../../config/types';
import { JsonObj } from '../../userStats/types';
import DonutChart from './DonutChart';
import DonutLegend from './DonutLegend';

const DonutChartWithLegend = (props: {
  data: JsonObj[];
  xValues: string[];
  yValues: number[];
  totalCounts: number; // total label counts or object counts?
  chartName: string;
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigDonutObject;
  getDatumColor: (datum: JsonObj) => string;
  handleClickDatum?: ClickedEventProps;
}): React.ReactElement => {
  const {
    data,
    xValues,
    yValues,
    totalCounts,
    chartName,
    xKeyToDisplayName,
    svgInfo,
    getDatumColor,
    handleClickDatum,
  } = props;
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const highlightSlice = (i: number, status?: 'in' | 'out') => {
    if (status === 'in') {
      setHoveredIndex(i);
    } else {
      setHoveredIndex(-1);
    }
  };

  const getDonutCenterText = (params: {
    hoveredIndex: number;
    count: number;
    total: number;
    datum?: JsonObj;
  }) => {
    const { hoveredIndex, count, total, datum } = params;
    const name = datum?.name;
    const centerText = {
      text:
        hoveredIndex === -1
          ? t('curate.analytics.text.total')
          : name?.length > 17
          ? name.slice(0, 17) + '...'
          : name,
      count: hoveredIndex === -1 ? total : count,
    };
    return [centerText];
  };

  const getClassLegendObject = () => {
    return {
      title:
        chartName === 'imageCategoryStats'
          ? `${t('text.categories')} (${xValues.length})`
          : `${t('components.shared.class')} (${xValues.length})`,
      hasMarker: true,
    };
  };

  return (
    <>
      <Box
        id={`${chartName}-container`}
        display="flex"
        style={{
          position: 'relative',
          justifyContent: 'center',
          paddingBottom: '8px',
        }}
      >
        <Box display="flex">
          <Box flex={1} style={{ marginLeft: '180px' }}>
            <DonutChart
              x={svgInfo.svgWidth / 2} // outer svg
              id={`${chartName}-donut-chart`}
              yValues={yValues}
              data={data as DonutDatum[]}
              totalCounts={totalCounts}
              getCenterText={getDonutCenterText}
              getColor={getDatumColor}
              highlightSlice={highlightSlice}
              handleOnClick={handleClickDatum}
              hoveredIndex={hoveredIndex}
              chartName={chartName}
              xKeyToDisplayName={xKeyToDisplayName}
              svgInfo={svgInfo}
            />
          </Box>
          <Box style={{ marginRight: '160px', marginTop: '20px' }} width="310px">
            <DonutLegend
              id={`${chartName}-donut-legend`}
              idKey="name"
              countKey="count"
              shareKey="percentTotal"
              displayNameKey="name"
              data={data}
              groupValues={map(data, d => d.groups)}
              getColor={getDatumColor}
              highlightSlice={highlightSlice}
              handleOnClick={handleClickDatum}
              chartName={chartName}
              xKeyToDisplayName={xKeyToDisplayName}
              chartObj={getClassLegendObject()}
              selectedIndex={hoveredIndex}
              fixedTopMargin
              showButton
            />
          </Box>
        </Box>
      </Box>
      <div id="outer" style={{ display: 'none' }} />
    </>
  );
};

export default DonutChartWithLegend;
