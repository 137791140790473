import { TFunction } from 'react-i18next';

import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  initialFreeResponse,
  initialImageCategoryMap,
  PROPERTY_TYPE,
} from '../../../../consts/NewProjectConst';
import { NEW_PROJECT_CAN_NOT_MOVE_DUPLICATE_NAME_GROUP } from '../../../../consts/SnackbarMessage';
import ProjectUtils from '../../../../utils/ProjectUtils';
// eslint-disable-next-line
const nonManipulatingCalDepth = (imageCategoryMap: any, id: string): number => {
  let curId = id;
  let depth = -1;

  while (imageCategoryMap[curId].parent) {
    curId = imageCategoryMap[curId].parent;
    depth++;

    if (depth > 100) return -1;
  }

  return depth;
};

// eslint-disable-next-line
const nonManipulatingIsChildOfParent = (
  imageCategoryMap: any,
  parentId: string,
  childId: string,
): boolean => {
  let curParentOfChild = imageCategoryMap[childId].parent;
  while (curParentOfChild) {
    if (curParentOfChild === parentId) return true;
    curParentOfChild = imageCategoryMap[curParentOfChild].parent;
  }

  return false;
};

// eslint-disable-next-line
const nonManipulatingDetachChild = (imageCategoryMap: any, childId: string): void => {
  const parentId = imageCategoryMap[childId].parent;
  if (!parentId) return;

  const idx = imageCategoryMap[parentId].children.indexOf(childId);
  imageCategoryMap[parentId].children.splice(idx, 1);
};

// eslint-disable-next-line
const nonManipulatingDeleteCategory = (
  imageCategoryMap: any,
  selectedCategories: any,
  id: string,
): void => {
  nonManipulatingDetachChild(imageCategoryMap, id);

  // eslint-disable-next-line no-param-reassign
  delete imageCategoryMap[id];

  if (selectedCategories[id]) {
    // eslint-disable-next-line no-param-reassign
    delete selectedCategories[id];
  }
};

// eslint-disable-next-line
const nonManipulatingAddChildren = (
  imageCategoryMap: any,
  parentId: string,
  childIds: string[],
  dragInfo: any = null,
  selectedCategories: any,
): void => {
  const childrenOfParent = imageCategoryMap[parentId].children;

  for (let i = 0; i < childIds.length; i++) {
    const curChild = imageCategoryMap[childIds[i]];
    for (let j = 0; j < childrenOfParent.length; j++) {
      const curChildOfParent = imageCategoryMap[childrenOfParent[j]];
      if (curChild.name === curChildOfParent.name) {
        nonManipulatingDeleteCategory(imageCategoryMap, selectedCategories, curChildOfParent.id);
      }
    }

    curChild.parent = parentId;
  }

  if (dragInfo) {
    let idx = imageCategoryMap[parentId].children.indexOf(dragInfo.id);
    if (dragInfo.dir === 'next') idx += 1;

    imageCategoryMap[parentId].children.splice(idx, 0, ...childIds);
  } else {
    imageCategoryMap[parentId].children.push(...childIds);
  }
};

// eslint-disable-next-line
const nonManipulatingCreateGroup = (
  imageCategoryMap: any,
  parent: any,
  selectedCategories: any,
): string => {
  const id = uuidv4();
  // eslint-disable-next-line no-param-reassign
  imageCategoryMap[id] = {
    id,
    name: ProjectUtils.getNewName(
      'Untitled Category Group',
      imageCategoryMap[parent].children
        .filter((a: any) => imageCategoryMap[a].children && imageCategoryMap[a].parent === parent)
        .map((a: any) => imageCategoryMap[a]),
    ),
    parent: null,
    children: [],
  };

  nonManipulatingAddChildren(imageCategoryMap, parent, [id], null, selectedCategories);
  return id;
};

// eslint-disable-next-line
const nonManipulatingDeleteGroup = (
  imageCategoryMap: any,
  selectedCategories: any,
  id: string,
): void => {
  const isGroup = imageCategoryMap[id].children;

  if (isGroup) {
    const childrenIds = cloneDeep(imageCategoryMap[id].children);

    for (let i = 0; i < childrenIds.length; i++) {
      nonManipulatingDeleteGroup(imageCategoryMap, selectedCategories, childrenIds[i]);
    }

    nonManipulatingDetachChild(imageCategoryMap, id);
    // eslint-disable-next-line no-param-reassign
    delete imageCategoryMap[id];
  } else {
    nonManipulatingDeleteCategory(imageCategoryMap, selectedCategories, id);
  }
};

// eslint-disable-next-line
const nonManipulatingChangeCategoriesParent = (
  imageCategoryMap: any,
  categoryIds: string[],
  toGroupId: string,
  dragInfo: any,
  selectedCategories: any,
): void => {
  const nextChildIds = [];
  for (let i = 0; i < categoryIds.length; i++) {
    const curCategory = imageCategoryMap[categoryIds[i]];
    let isAlreadyExist = false;
    for (let j = 0; j < nextChildIds.length; j++) {
      const curNextCategory = imageCategoryMap[nextChildIds[j]];
      if (curCategory.name === curNextCategory.name) {
        isAlreadyExist = true;
        break;
      }
    }

    if (isAlreadyExist) {
      nonManipulatingDeleteCategory(imageCategoryMap, selectedCategories, categoryIds[i]);
    } else {
      nonManipulatingDetachChild(imageCategoryMap, categoryIds[i]);
      nextChildIds.push(categoryIds[i]);
    }
  }

  nonManipulatingAddChildren(
    imageCategoryMap,
    toGroupId,
    nextChildIds,
    dragInfo,
    selectedCategories,
  );
};

const nonManipulatingChangeGroupParent = (
  imageCategoryMap: any,
  fromGroupId: string,
  toGroupId: string,
  dragInfo: any,
  selectedCategories: any,
): void => {
  nonManipulatingDetachChild(imageCategoryMap, fromGroupId);
  nonManipulatingAddChildren(
    imageCategoryMap,
    toGroupId,
    [fromGroupId],
    dragInfo,
    selectedCategories,
  );
};

// mutable function

const createGroup = (
  // eslint-disable-next-line
  newProjectInfo: any,
  parentGroupId: string,
  childIds: string[] | null = null,
  isCreateSubgroup = false,
  hasSubgroup = false,
): void => {
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];

  const nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);
  const createdGroupId = nonManipulatingCreateGroup(
    selectedImageCategory.imageCategoryMap,
    parentGroupId,
    nextSelectedCategories,
  );

  if (childIds) {
    if (!isCreateSubgroup) {
      nonManipulatingChangeCategoriesParent(
        selectedImageCategory.imageCategoryMap,
        childIds,
        createdGroupId,
        null,
        nextSelectedCategories,
      );
    } else if (!hasSubgroup) {
      nonManipulatingChangeCategoriesParent(
        selectedImageCategory.imageCategoryMap,
        childIds,
        'root',
        null,
        nextSelectedCategories,
      );
    }
  }

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategoryGroupId(createdGroupId);
  newProjectInfo.setSelectedCategories({});
  newProjectInfo.setCreateState('IMAGE_CATEGORY_GROUP');
};

// eslint-disable-next-line
const nonManipulatingSelectSelectedCategoryGroupIdWhenGroupRemoved = (
  newProjectInfo: any,
  imageCategoryMap: any,
): void => {
  const parentId = imageCategoryMap[newProjectInfo.selectedCategoryGroupId].parent;
  const parent = imageCategoryMap[parentId];
  const children = parent.children.filter((item: any) =>
    Object.prototype.hasOwnProperty.call(imageCategoryMap[item], 'children'),
  );

  if (children.length === 1) {
    if (parentId === 'root') {
      newProjectInfo.setSelectedCategoryGroupId('');
    } else {
      newProjectInfo.setSelectedCategoryGroupId(parentId);
    }
  } else {
    const curIndex = children.findIndex(
      (item: any) => item === newProjectInfo.selectedCategoryGroupId,
    );
    if (children.length - 1 === curIndex) {
      newProjectInfo.setSelectedCategoryGroupId(children[curIndex - 1]);
    } else {
      newProjectInfo.setSelectedCategoryGroupId(children[curIndex + 1]);
    }
  }
};

// eslint-disable-next-line
const deleteGroup = (newProjectInfo: any): void => {
  if (newProjectInfo.selectedCategoryGroupId === '') return;
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
  const { imageCategoryMap } = selectedImageCategory;
  const nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);

  nonManipulatingSelectSelectedCategoryGroupIdWhenGroupRemoved(newProjectInfo, imageCategoryMap);
  nonManipulatingDeleteGroup(
    imageCategoryMap,
    nextSelectedCategories,
    newProjectInfo.selectedCategoryGroupId,
  );

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories(nextSelectedCategories);
};

// eslint-disable-next-line
const deleteCategory = (newProjectInfo: any, ids?: string[]): void => {
  if (newProjectInfo.selectedImageCategoryIndex === -1) return;
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const selectImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];

  const nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);
  const selectedCategoriesIds = ids || Object.keys(newProjectInfo.selectedCategories);
  for (let i = 0; i < selectedCategoriesIds.length; i++) {
    const id = selectedCategoriesIds[i];
    nonManipulatingDeleteCategory(selectImageCategory.imageCategoryMap, nextSelectedCategories, id);
  }

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories(nextSelectedCategories);
};

// eslint-disable-next-line
const moveCategoryToGroup = (
  newProjectInfo: any,
  ids: string[],
  toGroup: any,
  dragInfo: any,
): void => {
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
  const { imageCategoryMap } = selectedImageCategory;
  const nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);

  nonManipulatingChangeCategoriesParent(
    imageCategoryMap,
    ids,
    toGroup,
    dragInfo,
    nextSelectedCategories,
  );
  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories(nextSelectedCategories);
};

const moveGroupToGroup = (
  t: TFunction,
  // eslint-disable-next-line
  newProjectInfo: any,
  fromGroupId: string,
  toGroupId: string,
  // eslint-disable-next-line
  dragInfo: any,
  // eslint-disable-next-line
  enqueueSnackbar: any,
): void => {
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
  const { imageCategoryMap } = selectedImageCategory;
  const nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);

  const groupChildrenOfToGroup = imageCategoryMap[toGroupId].children.filter((item: any) =>
    Object.prototype.hasOwnProperty.call(imageCategoryMap[item], 'children'),
  );

  for (let i = 0; i < groupChildrenOfToGroup.length; i++) {
    if (imageCategoryMap[fromGroupId].id === imageCategoryMap[groupChildrenOfToGroup[i]].id)
      continue;

    if (imageCategoryMap[groupChildrenOfToGroup[i]].name === imageCategoryMap[fromGroupId].name) {
      enqueueSnackbar(NEW_PROJECT_CAN_NOT_MOVE_DUPLICATE_NAME_GROUP({ t }), {
        variant: 'warning',
      });
      return;
    }
  }

  if (!dragInfo) {
    const fromGroup = imageCategoryMap[fromGroupId];
    const toGroup = imageCategoryMap[toGroupId];

    const hasSubgroupInFromGroup = !!(
      fromGroup.children[0] && imageCategoryMap[fromGroup.children[0]].children
    );
    const hasSubgroupInToGroup = !!(
      toGroup.children[0] && imageCategoryMap[toGroup.children[0]].children
    );
    const toGroupDepth = nonManipulatingCalDepth(imageCategoryMap, toGroupId);

    if (hasSubgroupInFromGroup) return;
    if (toGroupDepth !== 0) return;

    if (!hasSubgroupInToGroup) {
      nonManipulatingChangeCategoriesParent(
        imageCategoryMap,
        cloneDeep(toGroup.children),
        'root',
        null,
        nextSelectedCategories,
      );
    }
  }

  nonManipulatingChangeGroupParent(
    imageCategoryMap,
    fromGroupId,
    toGroupId,
    dragInfo,
    nextSelectedCategories,
  );

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories(nextSelectedCategories);
};

// eslint-disable-next-line
const createImageCategory = (newProjectInfo: any): any => {
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const id = uuidv4();

  const nextImageCategoryMap: any = cloneDeep(initialImageCategoryMap);
  const sameHierarchyNameList = nextImageCategories.map((imageCategory: any) => ({
    name: imageCategory.imageCategoryMap.root.name,
  }));
  nextImageCategoryMap.root.name = ProjectUtils.getNewName(
    'Untitled Image Category',
    sameHierarchyNameList,
  );

  nextImageCategories.push({
    id,
    type: PROPERTY_TYPE.MULTIPLE_SELECTION.value,
    perFrame: false,
    required: false,
    imageCategoryMap: nextImageCategoryMap,
    freeResponseInfo: cloneDeep(initialFreeResponse),
  });

  newProjectInfo.setSelectedImageCategoryIndex(nextImageCategories.length - 1);
  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories({});
  newProjectInfo.setSelectedCategoryGroupId('');
  newProjectInfo.setCreateState('IMAGE_CATEGORY');
};

// eslint-disable-next-line
const copyImageCategory = (newProjectInfo: any) => {
  if (newProjectInfo.selectedImageCategoryIndex === -1) return;

  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  const id = uuidv4();

  const selectImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
  const nextImageCategoryMap: any = cloneDeep(selectImageCategory.imageCategoryMap);

  const sameHierarchyNameList = nextImageCategories.map((imageCategory: any) => ({
    name: imageCategory.imageCategoryMap.root.name,
  }));
  nextImageCategoryMap.root.name = ProjectUtils.getNewName(
    selectImageCategory.imageCategoryMap.root.name,
    sameHierarchyNameList,
  );

  nextImageCategories.splice(newProjectInfo.selectedImageCategoryIndex + 1, 0, {
    id,
    type: selectImageCategory.type,
    imageCategoryMap: nextImageCategoryMap,
    freeResponseInfo: cloneDeep(selectImageCategory.freeResponseInfo),
  });

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setCreateState('IMAGE_CATEGORY');
};

// eslint-disable-next-line
const deleteImageCategory = (newProjectInfo: any) => {
  if (newProjectInfo.selectedImageCategoryIndex === -1) return;
  const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
  nextImageCategories.splice(newProjectInfo.selectedImageCategoryIndex, 1);

  if (newProjectInfo.imageCategories.length - 1 === newProjectInfo.selectedImageCategoryIndex) {
    if (newProjectInfo.selectedImageCategoryIndex === 0) {
      newProjectInfo.setSelectedImageCategoryIndex(-1);
    } else {
      newProjectInfo.setSelectedImageCategoryIndex(newProjectInfo.selectedImageCategoryIndex - 1);
    }
  }

  newProjectInfo.setImageCategories(nextImageCategories);
  newProjectInfo.setSelectedCategories({});
  newProjectInfo.setSelectedCategoryGroupId('');
};

export default {
  createGroup,
  deleteGroup,
  moveCategoryToGroup,
  moveGroupToGroup,
  deleteCategory,
  nonManipulatingIsChildOfParent,
  createImageCategory,
  copyImageCategory,
  deleteImageCategory,
};
