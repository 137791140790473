import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { useRouteInfo } from '../../../contexts/RouteContext';
import Data from './Data';
import helper from './helper';
import Label from './Label';
import Upload from './Upload';

const useStyles = makeStyles(theme => ({
  toast: {
    position: 'fixed',
    maxWidth: '80vw',
    minHeight: '46px',
    transition: 'all 200ms ease 150ms',
    opacity: 0.9,
    // @ts-ignore: this works
    backgroundColor: theme.palette.navy.main,
    borderRadius: '4px',
    padding: '12px 20px',
    boxSizing: 'border-box',
  },
  message: {
    fontSize: '12px',
    color: '#fff',
    fontWeight: 500,
    cursor: 'normal',
  },
  cancelText: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 500,
    marginLeft: '10px',
    marginTop: '2px',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  viewText: {
    color: '#5A7BFF',
    fontSize: '10px',
    fontWeight: 500,
    marginLeft: '10px',
    marginTop: '2px',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  closeIcon: {
    marginTop: '3px',
    marginLeft: '10px',
    cursor: 'pointer',
  },
}));

interface Props {
  toast: any;
}

const Layout: React.FC<Props> = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const routeInfo = useRouteInfo();
  const { toast } = props;
  const { type, action, options } = toast;
  const { order, state, setState } = options;
  const [isCancel, setIsCancel] = useState(false);
  // init -> start prepare -> prepare -> end prepare  -> start progress  -> progress -> end progress  -> close
  // cancel
  useEffect(() => {
    setTimeout(() => {
      setState('startPrepare');
    }, 0);
    // eslint-disable-next-line
  }, []);

  const handleClickCancel = () => {
    setIsCancel(true);
  };

  const handleClickClose = () => {
    setState('close');
  };

  const handleClickView = () => {
    setState('close');

    const { pathname, search } = options.routeInfo.history.location;
    let url = pathname;
    switch (type) {
      case 'label':
        url += search;
        break;
      case 'data':
        if (action === 'assign') {
          url = `/${routeInfo.urlMatchInfo.accountName}/label/project/${options.projectId}/labels`;
        } else if (action === 'delete') {
          url += search;
        }
        break;
      default:
        break;
    }

    routeInfo.history.push(url);
  };

  const getToast = () => {
    switch (type) {
      case 'label':
        if (action === 'upload') {
          return (
            <Upload
              classes={classes}
              toast={toast}
              isCancel={isCancel}
              handleClickCancel={handleClickCancel}
              handleClickClose={handleClickClose}
            />
          );
        }
        return (
          <Label
            classes={classes}
            toast={toast}
            isCancel={isCancel}
            handleClickCancel={handleClickCancel}
            handleClickClose={handleClickClose}
            handleClickView={handleClickView}
          />
        );
      case 'data':
        if (action === 'upload') {
          return (
            <Upload
              classes={classes}
              toast={toast}
              isCancel={isCancel}
              handleClickCancel={handleClickCancel}
              handleClickClose={handleClickClose}
            />
          );
        }
        return (
          <Data
            classes={classes}
            action={action}
            toast={toast}
            isCancel={isCancel}
            handleClickCancel={handleClickCancel}
            handleClickClose={handleClickClose}
            handleClickView={handleClickView}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MUI.Box
      className={classes.toast}
      style={{
        right: helper.getRight(state),
        bottom: helper.getBottom(order),
      }}
    >
      {getToast()}
    </MUI.Box>
  );
};

export default Layout;
