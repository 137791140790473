import { format } from 'date-fns';
import { omit } from 'lodash';

import { ChartDatum } from '../../../../apps/Curate/components/datasets/dataset/analytics/charts/types';
import { getStatDisplayName } from './chartSpecs';
import { GroupByField } from './fields';
import { DataSpec } from './type';

export const getDownloadFilename = (
  projectName: string,
  dataSpec: DataSpec,
  groupBy: GroupByField,
) => {
  const chartName = getStatDisplayName(dataSpec, groupBy);
  return `Chart_${projectName}_${chartName}_${format(new Date(), 'yyyy-MM-dd H:mm:ss')}`;
};

export function transformDownload(data: ChartDatum[]): Omit<ChartDatum, 'color'>[] {
  return data.map(d => omit(d, ['color']));
}
