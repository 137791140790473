import React from 'react';

import * as MUI from '@mui/material';

import { lastReviewedAt } from '../../../../utils/filter/labelFilter';
import FilterDate from './components/FilterDate';
import FilterDropdown from './components/FilterDropdown';
import { FilterTypeProps } from './types';

const FilterLastReviewedAt: React.FC<FilterTypeProps> = props => {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={lastReviewedAt.conditions}
          value={filter.condition}
          onChangeCurrentRow={onChangeCurrentRow}
          onValueChange={onValueChange}
        />
      </MUI.Grid>
      <MUI.Grid item>
        <FilterDate
          condition={filter.condition}
          onValueChange={onValueChange}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
          index={index}
          objKey="options"
          value={filter.options as any}
        />
      </MUI.Grid>
    </>
  );
};

export default FilterLastReviewedAt;
