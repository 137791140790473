import LabelInterfaceUtils, { LabelInterface } from '../../../../../utils/LabelInterfaceUtils';

export type Mapper = Record<string, string>;

export const getIdToNameMap = (list: { id: string; name: string }[]) => {
  return list.reduce((acc: Mapper, datum: { id: string; name: string }) => {
    acc[datum.id] = datum.name;
    return acc;
  }, {} as Mapper);
};

export const getClassIdToNameMap = (labelInterface: LabelInterface): Mapper => {
  return getIdToNameMap(LabelInterfaceUtils.getObjectClasses(labelInterface));
};
