import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Modal } from '@superb-ai/norwegian-forest';
import { omit } from 'lodash';

import { useAssetsInfo } from '../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import CommandsService from '../../../services/CommandsService';
import ProjectService from '../../../services/ProjectService';
import { AssignDialogContext } from './Context';
import DataContainer from './DataContainer';
import DatasetContainer from './DatasetContainer';

const useStyles = makeStyles(() => ({
  modalSize: {
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '0px 40px 0px 40px',
    height: '680px',
  },
}));

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  projectName: string;
};

const AssignDialog: React.FC<Props> = ({ isOpen, setIsOpen, projectName }) => {
  const { t } = useTranslation();
  const { selectedDataset, setSelectedDataset } = React.useContext(AssignDialogContext);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [dataset, setDataset] = useState<any>();
  const [params, setParams] = useState<Record<string, any>>({});
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const assetsInfo = useAssetsInfo();
  const commandContext = useLabelCommandContext();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const classes = useStyles();

  const handleClose = () => {
    setSelectedDataset('');
    setIsOpen(false);
  };

  const handleBack = () => {
    setSelectedDataset('');
  };

  //

  const handleAssignToProject = async () => {
    const project = await ProjectService.getProjectByName({
      projectName: projectName || '',
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const apiParams = isAllChecked
      ? omit(params, ['page', 'pageSize', 'ordering', 'searchAfter', 'searchBefore'])
      : {
          [selectedDataset === '' ? 'groupIn' : 'idIn']: checkedList,
        };
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'ASSETS_ASSIGN',
      projectId: project.id,
      params: apiParams,
      actionInfo: {
        projectId: project.id,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
    handleClose();
  };

  const totalCount = !isAllChecked
    ? checkedList.length
    : selectedDataset === ''
    ? assetsInfo.totalDatasetCount
    : assetsInfo.totalCount;

  return (
    <Modal
      open={isOpen}
      title={t('data.button.assignToProject')}
      mainButton={{
        text:
          selectedDataset === ''
            ? t('data.upload.assignDatasets', { dataCount: totalCount })
            : t('data.upload.assignAssets', { dataCount: totalCount }),
        onClick: handleAssignToProject,
        disabled: totalCount === 0,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClose,
      }}
    >
      <Box className={classes.modalSize}>
        {selectedDataset === '' ? (
          <DatasetContainer
            checkedDatasets={checkedList}
            setCheckedDatasets={setCheckedList}
            isAllDatasetsChecked={isAllChecked}
            setIsAllDatasetsChecked={setIsAllChecked}
            {...{ params, setParams }}
          />
        ) : (
          <DataContainer
            {...{ selectedDataset, handleBack, setDataset }}
            checkedAssets={checkedList}
            setCheckedAssets={setCheckedList}
            isAllAssetsChecked={isAllChecked}
            setIsAllAssetsChecked={setIsAllChecked}
            apiParams={params}
            setApiParams={setParams}
          />
        )}
      </Box>
    </Modal>
  );
};

export default AssignDialog;
