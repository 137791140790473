import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
    '&.dragEntered': {
      background: '#fff6f6',
      opacity: 0.5,
    },
  },
}));

// eslint-disable-next-line
const GroupItemDropPanel = (props: any): React.ReactElement | null => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { group, depth, hasSubgroup, imageCategoryMap } = props;
  const newProjectInfo = useNewProjectInfo();
  const [isDragEnter, setIsDragEnter] = useState(false);

  if (!newProjectInfo.dragState || newProjectInfo.dragState.id === group.id) return null;

  const handleDragEnter = () => {
    if (newProjectInfo.dragState.type === 'imageCategory') {
      if (hasSubgroup) return;
    } else if (newProjectInfo.dragState.type === 'imageCategoryGroup') {
      const fromGroup = imageCategoryMap[newProjectInfo.dragState.id];
      const hasSubgroupInFromGroup = !!(
        fromGroup.children[0] && imageCategoryMap[fromGroup.children[0]].children
      );

      if (hasSubgroupInFromGroup) return;
      if (depth !== 0) return;
    }
    setIsDragEnter(true);
  };

  const handleDragLeave = () => {
    setIsDragEnter(false);
  };

  const handleDrop = () => {
    if (newProjectInfo.dragState.type === 'imageCategory') {
      if (hasSubgroup) return;
      const ids = Object.keys(newProjectInfo.selectedCategories);
      helper.moveCategoryToGroup(newProjectInfo, ids, group.id, null);
    } else if (newProjectInfo.dragState.type === 'imageCategoryGroup') {
      helper.moveGroupToGroup(
        t,
        newProjectInfo,
        newProjectInfo.dragState.id,
        group.id,
        null,
        enqueueSnackbar,
      );
    }

    newProjectInfo.setDragState(null);
    setIsDragEnter(false);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${isDragEnter ? 'dragEntered' : ''} `}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default GroupItemDropPanel;
