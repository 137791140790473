import { useState } from 'react';

import { FilterInfo } from '../../../label/filter/types';

const useReportFilters = () => {
  const [filters, setFilters] = useState<FilterInfo[]>([
    { filterBy: '', condition: '', options: [] },
  ]);
  return {
    filters,
    setFilters,
  };
};

export default useReportFilters;
