import { addMilliseconds, differenceInMilliseconds } from 'date-fns';

import { parseDate } from '../../../utils/date';
import { DatasetResult, GetSlicesParams } from '../services/DatasetService';
import { DiagnosisDetail } from '../services/DiagnosisModelService';
import {
  AUTO_CURATE,
  Command,
  CommandStatus,
  CommandType,
  hasParamDiagnosisId,
  hasParamProjectId,
  hasParamSliceId,
  isAutoCurateMislabelsParam,
  isAutoCurateToLabelOrEdgeCaseParam,
  isAutoCurateTrainValParam,
  PREPARE_DOWNLOAD,
  UncancellableCommandTypes,
  UnretriableCommandTypes,
} from '../types/commandTypes';
import { DownloadInfo } from '../types/downloadTypes';
import { downloadName } from '../../../components/pages/analytics/chartContainers/labelingTime/helper';

export function getAutoCurateSliceIds<T extends CommandType>(command: Command<T>) {
  let sliceIds = {};
  if (isAutoCurateTrainValParam(command.param)) {
    sliceIds = {
      ...sliceIds,
      val: command.param.config.valSliceId,
      train: command.param.config.trainSliceId,
    };
    if (command.param.config.mislabelSliceId) {
      sliceIds = { ...sliceIds, mislabel: command.param.config.mislabelSliceId };
    }
  } else if (isAutoCurateMislabelsParam(command.param)) {
    sliceIds = { ...sliceIds, result: command.param.config.resultSliceId };
  } else if (isAutoCurateToLabelOrEdgeCaseParam(command.param)) {
    sliceIds = { ...sliceIds, result: command.param.config.resultSliceId };
  }
  return sliceIds;
}

type CommandConfigParams<T extends CommandType> = {
  command: Command<T>;
  getDataset: (data: { datasetId: string; fromPublicDatasets: boolean }) => Promise<DatasetResult>;
  getSlice: (data: {
    datasetId: string;
    fromPublicDatasets: boolean;
    sliceId: string;
  }) => Promise<DatasetResult>;
  getProject: (data: { projectId: string }) => Promise<any>;
  getDiagnosisDetail: (data: {
    datasetId: string;
    fromPublicDatasets: boolean;
    diagnosisId: string;
  }) => Promise<DiagnosisDetail>;
  getDownload: (data: { jobId: string }) => Promise<DownloadInfo>;
};

export async function getCommandConfigs<T extends CommandType>({
  command,
  getDataset,
  getSlice,
  getProject,
  getDiagnosisDetail,
  getDownload,
}: CommandConfigParams<T>) {
  const datasetId = command.param?.datasetId;
  const fromPublicDatasets = false; // we don't show jobs for public datasets

  const datasetInfo = await getDataset({ datasetId, fromPublicDatasets: fromPublicDatasets });

  const sliceInfo =
    hasParamSliceId(command.param) && command.param.sliceId
      ? await getSlice({
          datasetId,
          fromPublicDatasets: fromPublicDatasets,
          sliceId: command.param.sliceId,
        })
      : undefined;

  let autoCurateSlices = undefined;
  if (command.jobType === AUTO_CURATE) {
    const autoCurateSliceIds = getAutoCurateSliceIds(command);
    const sliceIds = Object.entries(autoCurateSliceIds || {}) as [string, string][];
    const autoCurateSlicesResult = await Promise.all(
      sliceIds.map(async ([sliceType, sliceId]) => {
        const result = await getSlice({
          datasetId,
          sliceId,
          fromPublicDatasets: fromPublicDatasets,
        });
        return { [sliceType]: result };
      }),
    );
    autoCurateSlices = autoCurateSlicesResult
      ? autoCurateSlicesResult.reduce((acc, _) => {
          return { ...acc, ..._ };
        }, {} as Record<'train' | 'val' | 'mislabel' | 'result', { id: string; name: string }>)
      : undefined;
  }

  const diagnosisInfo = hasParamDiagnosisId(command.param)
    ? await getDiagnosisDetail({
        datasetId,
        diagnosisId: command.param?.diagnosisId,
        fromPublicDatasets: fromPublicDatasets,
      })
    : undefined;

  const projectInfo = hasParamProjectId(command.param)
    ? await getProject({ projectId: command.param?.projectId })
    : undefined;

  const downloadInfo =
    command.jobType === PREPARE_DOWNLOAD && getIsJobSuccess(command)
      ? await getDownload({ jobId: command.id })
      : undefined;

  return {
    datasetName: datasetInfo?.name,
    sliceName: sliceInfo?.name,
    modelName: diagnosisInfo?.modelName,
    projectName: projectInfo?.name,
    downloadUrl: downloadInfo?.zip_download_url,
    downloadName: downloadInfo?.download_name,
    autoCurateSlices,
  };
}

export type FailReason = { count: number; code: string };

export const InProgressStatuses: CommandStatus[] = ['PENDING', 'IN_PROGRESS', 'CANCELING'];
export const DoneStatuses: CommandStatus[] = ['COMPLETE', 'FAILED', 'CANCELED'];
export const PlanningStatuses: CommandStatus[] = ['PENDING'];

export const RetriableStatuses: CommandStatus[] = ['FAILED', 'CANCELED'];

export function formatNameEmphasize(name: string, format: 'bold' | 'quotes') {
  if (format === 'quotes') {
    return `"${name}"`; // Adds quotes
  }
  return `<strong>${name}</strong>`;
}

export function isCommandCancellable<T extends CommandType>(command: Command<T>): boolean {
  return (
    command.status === 'IN_PROGRESS' &&
    command.totalCount !== 0 &&
    !UncancellableCommandTypes.includes(command.jobType)
  );
}
export function isCommandRetriable<T extends CommandType>(command: Command<T>): boolean {
  const failCount = command.result?.failCount || 0;
  const isPartiallyFailed = failCount > 0;
  return (
    (isPartiallyFailed || RetriableStatuses.includes(command.status)) &&
    !UnretriableCommandTypes.includes(command.jobType)
  );
}

/**
 * Returns a Date of the expected end time calculated based on start time and current progress.
 */
export function estimateTimeLeft<T extends CommandType>(command: Command<T>): null | Date {
  if (!command.progress) {
    return null;
  }
  const applyStart = parseDate(command.createdAt);
  const diff = differenceInMilliseconds(new Date(), applyStart);
  const timeLeft = (diff / command.progress) * (command.totalCount - command.progress);
  if (timeLeft <= 0 || isNaN(timeLeft)) {
    return null;
  }
  return addMilliseconds(new Date(), timeLeft);
}

/**
 * Returns the next interval to use for exponential backoff.
 * This curve yields every value 2 times before doubling in the next step.
 */
export function exponentialBackoffTime(step: number, base = 1000): number {
  return base * 2 ** Math.floor(step / 2);
}

export function getIsJobSuccess<T extends CommandType>(command: Command<T>): boolean {
  return command.status === 'COMPLETE' && command.totalCount > 0 && command.result?.failCount === 0;
}
