import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Copy, CurateLogo, Pencil } from '@superb-ai/icons';
import { Box, Input, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { Button, Icon } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { getNamingRuleErrorMessage } from '../../../../../configs/NamingRulesConfig';
import { EDIT_PROJECT_BEFORE_ALERT } from '../../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ProjectService from '../../../../../services/ProjectService';
import UserRoleUnion from '../../../../../union/UserRoleUnion';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';

const getIsEditDisable = (project: { labelInterface: Record<string, any>; workapp: string }) => {
  const { labelInterface, workapp } = project;

  if (workapp === 'image-default') {
    const { objects } = labelInterface;
    for (let i = 0; i < objects.length; i++) {
      const curShape = Object.keys(objects[i].info.shapes)[0];

      if (curShape === 'tiltedbox') {
        return true;
      }
    }

    return false;
  }

  if (
    workapp === 'image-siesta' ||
    workapp === 'video-siesta' ||
    workapp === 'pointclouds-siesta'
  ) {
    return false;
  }

  return true;
};

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const {
    history,
    urlMatchInfo: { accountName, projectId },
  } = routeInfo;
  const [isEditDisable] = useState(getIsEditDisable(projectInfo.project));
  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);

  const connectedCurateDatasetId = projectInfo.project.curateDatasetId;

  const openEditProjectPage = () => {
    if (connectedCurateDatasetId) {
      history.push(
        `/${accountName}/curate/dataset/${connectedCurateDatasetId}/settings#edit-synced-label-project=${projectId}`,
      );
      return;
    }
    history.push(`/${accountName}/label/project/${projectId}/edit_project/create_project`);
  };

  const handleClickEditProject = () => {
    openModal({
      ...EDIT_PROJECT_BEFORE_ALERT({ t }),
      mainButton: {
        text: t('button.ok'),
        onClick: () => {
          openEditProjectPage();
          closeModal();
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: closeModal,
      },
    });
  };

  const handleClickDuplicate = () => {
    setDuplicateProjectName(`Copy of ${projectInfo.project.name}`.slice(0, 50));
    setIsOpenDuplicateModal(true);
  };

  const handleChangeDuplicateProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDuplicateProjectName(e.target.value);
  };

  const handleClickCreateDuplicateProject = async () => {
    if (isRequesting) return;

    const trimName = duplicateProjectName.trim();

    const errorMessages = getNamingRuleErrorMessage({
      str: trimName,
      target: 'project',
      canOnlyUseASCII: true,
    });
    if (errorMessages.length !== 0) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      enqueueSnackbar(errorMessages[0], { variant: 'warning' });
      return;
    }

    try {
      setIsRequesting(true);
      await ProjectService.getProjectByName({
        projectName: trimName,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      enqueueSnackbar([t('projectSettings.configuration.errorSameNameExists')], {
        variant: 'warning',
      });
      setIsRequesting(false);
      return;
    } catch (err: any) {
      setIsRequesting(false);
    }

    try {
      await ProjectService.duplicateProject({
        name: trimName,
        description: projectInfo.project.description,
        workapp: projectInfo.project.workapp,
        labelInterface: projectInfo.project.labelInterface,
        isPublic: projectInfo.project.isPublic,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setIsRequesting(false);
      setIsOpenDuplicateModal(false);
      enqueueSnackbar([t('projectSettings.configuration.succesfullyDuplicated')], {
        variant: 'success',
      });
    } catch (err: any) {
      setIsRequesting(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClickCreateDuplicateProject();
    }
  };

  const handleClickCancel = () => {
    setIsOpenDuplicateModal(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <Typography variant="headline5">{t('projectSettings.configuration.title')}</Typography>
        </Box>
        {!UserRoleUnion.isCollaborator(authInfo.role) &&
          (connectedCurateDatasetId ? (
            <Button
              style={{ marginLeft: 'auto' }}
              variant="stroke"
              size="m"
              onClick={handleClickEditProject}
            >
              <Icon icon={CurateLogo} />
              {t('labels.button.editInCurate')}
            </Button>
          ) : (
            <Box display="flex" ml="auto">
              <Box mr={1}>
                <Button
                  color="gray"
                  variant="stroke"
                  size="m"
                  disabled={isEditDisable}
                  onClick={handleClickEditProject}
                >
                  <Icon icon={Pencil} />
                  {t('button.edit')}
                </Button>
              </Box>
              <Box>
                <Button color="gray" variant="stroke" size="m" onClick={handleClickDuplicate}>
                  <Icon icon={Copy} />
                  {t('projectSettings.configuration.button.duplicate')}
                </Button>
              </Box>
            </Box>
          ))}
      </Box>
      <MUI.Modal open={isOpenDuplicateModal}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius
        >
          <Box width="400px" p={4} display="flex" flexDirection="column" backgroundColor="#fff">
            <Box mb={2}>
              <Typography variant="headline6">
                {t('projectSettings.configuration.duplicateSettings')}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body3">
                {t('projectSettings.configuration.duplicateSettingsExplanation')}
              </Typography>
            </Box>
            <Box mb={2}>
              <Input
                ref={inputRef}
                style={{ width: '100%' }}
                value={duplicateProjectName}
                autoFocus
                onKeyDown={handleKeyDown}
                onChange={handleChangeDuplicateProjectName}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box mr={1}>
                <Button size="m" variant="text" color="gray" onClick={handleClickCancel}>
                  {t('button.cancel')}
                </Button>
              </Box>
              <Box>
                <Button size="m" color="gray" onClick={handleClickCreateDuplicateProject}>
                  {t('button.ok')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </MUI.Modal>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
};

export default Layout;
