import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Icon,
  OverflowItemCount,
  TagChip,
  Tooltip,
  Typography,
} from '@superb-ai/norwegian-forest';
import debounce from 'debounce-promise';

import { useGetProjectList } from '../../../../hooks/GetProjectList';
import { NewRole } from '../../../../types/memberTypes';
import { ProjectData } from '../../../../types/projectTypes';
import RoleChip from '../../RoleChip';
import ProjectsAsyncTable from '../ProjectsAsyncTable';
import ProjectSearchBar from '../ProjectSearchBar';

type Props = {
  selectedProjects: ProjectData[];
  setSelectedProjects: Dispatch<SetStateAction<ProjectData[]>>;
  /**
   * Temporarily removed select all projects due to performance
   */
  // isTotalProjectsSelected: boolean;
  // setIsTotalProjectsSelected: Dispatch<SetStateAction<boolean>>;
  totalCount: number;
  setTotalCount: Dispatch<SetStateAction<number>>;
  membersToAdd: Record<NewRole, string[]> | undefined;
};

const SelectProjects: React.FC<Props> = ({
  selectedProjects,
  setSelectedProjects,
  // isTotalProjectsSelected,
  // setIsTotalProjectsSelected,
  totalCount,
  setTotalCount,
  membersToAdd,
}) => {
  const { t } = useTranslation();
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKey, setSearchKey] = useState('');

  const getProjectList = useGetProjectList();

  const loadProjects = async ({ value }: { value: string }) => {
    setIsLoading(true);
    const projectRes = await getProjectList({ nameIcontains: value });
    setProjects(projectRes.results);
    setTotalCount(projectRes.count);
    setIsLoading(false);
  };

  useEffect(() => {
    loadProjects({ value: '' });
  }, []);

  const debounceLoadProjects = debounce(async (value: string) => {
    await loadProjects({ value });
  }, 500);

  const handleChangeSearchKey = async (value: string) => {
    await debounceLoadProjects(value);
    setSearchKey(value);
  };

  const handleRemoveSelectedProject = (item: ProjectData) => () => {
    setSelectedProjects(
      selectedProjects.filter(
        project => (project.projectId || project.id) !== (item.projectId || item.id),
      ),
    );
  };

  return (
    <>
      <Box
        themedBackgroundColor={['grey', 60]}
        height={76}
        py={1}
        px={2}
        borderRadius
        overflow="auto"
      >
        {membersToAdd?.admin && (
          <>
            <Box display="flex" alignItems="center">
              <RoleChip memberRole="admin" />
              <Tooltip
                placement="right"
                anchorEl={
                  <Box display="inline-flex" height="14px" alignItems="center" ml={0.5}>
                    <Icon name="infoCircleOutline" color={['grey', 500]} />
                  </Box>
                }
              >
                {t('invite.messages.adminsHaveAccessToAllProjects')}
              </Tooltip>
            </Box>
            <Box mt={0.5}>
              <Typography variant="body5">{membersToAdd.admin.join(', ')}</Typography>
            </Box>
          </>
        )}
        {membersToAdd?.manager && (
          <Box mt={membersToAdd.admin ? 1 : 0}>
            <RoleChip memberRole="manager" />
            <Box mt={0.5}>
              <Typography variant="body5">{membersToAdd.manager.join(', ')}</Typography>
            </Box>
          </Box>
        )}
        {membersToAdd?.labeler && (
          <Box mt={membersToAdd.manager ? 1 : 0}>
            <RoleChip memberRole="labeler" />
            <Box mt={0.5}>
              <Typography variant="body5">{membersToAdd.labeler.join(', ')}</Typography>
            </Box>
          </Box>
        )}
        {membersToAdd?.reviewer && (
          <Box mt={membersToAdd.manager ? 1 : 0}>
            <RoleChip memberRole="reviewer" />
            <Box mt={0.5}>
              <Typography variant="body5">{membersToAdd.reviewer.join(', ')}</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box mt={1}>
        <ProjectSearchBar height={32} onChangeSearchKey={handleChangeSearchKey} />
      </Box>
      <Box mt={1}>
        <ProjectsAsyncTable
          hasTotalSelect={false}
          selectable
          isLoading={isLoading}
          totalCount={totalCount}
          columns={['project', 'labelCount', 'createdAt']}
          searchKey={searchKey}
          projects={projects}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      </Box>

      <div
        ref={boxRef}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          overflow: 'hidden',
          width: '488px',
          height: '28px',
          gap: '4px',
          borderTop: '1px solid #eee',
          paddingTop: '4px',
          boxSizing: 'border-box',
        }}
      >
        {boxRef.current && (
          <OverflowItemCount
            container={boxRef}
            maxLines={1}
            overflowLabel={count => (
              <TagChip size="xxs" color="cloud">
                +{count}
              </TagChip>
            )}
          >
            {selectedProjects.map(project => (
              <TagChip
                size="xxs"
                color="cloud"
                key={project.projectId || project.id}
                onDelete={handleRemoveSelectedProject(project)}
              >
                {project.name}
              </TagChip>
            ))}
          </OverflowItemCount>
        )}
      </div>
    </>
  );
};

export default SelectProjects;
