import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { map } from 'lodash';

import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';

const useStyles = makeStyles(() => ({
  box: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  table: {
    '& .MuiTableRow-root': {
      marginBottom: '3px',
      verticalAlign: 'top',
      minHeight: '25px',
    },
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
    '& .MuiTableCell-body': {
      fontSize: '11px',
      color: '#212121',
      padding: '3px 0px',
      '& p': {
        fontSize: '11px',
        color: '#212121',
      },

      '&:nth-child(1)': {
        fontWeight: 500,
        width: '80px',
      },
      '&:nth-child(2)': {
        paddingLeft: '6px',
      },
    },
  },
  className: {
    color: '#ff625a !important',
  },
  propertiesTable: {
    width: '100%',
    marginTop: '4px',
    '& .MuiTableRow-root': {
      marginBottom: '3px',
      verticalAlign: 'top',
    },
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
    '& .MuiTableCell-body': {
      fontSize: '11px',
      color: '#635c5c',
      padding: '0px',
      textAlign: 'center',
      height: '20px',
      lineHeight: '20px',
      '&:nth-child(1)': {
        width: '50%',
        padding: '0px 10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        borderRight: 'solid 1px #ededed',
      },
      '&:nth-child(2)': {
        width: '50%',
        padding: '0px 10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    '&:nth-child(2n+1)': {
      backgroundColor: 'rgba(216, 216, 216, 0.13)',
    },
    '&:nth-child(2n)': {
      backgroundColor: '#fff',
    },
  },
}));

const AnnotationInformation = (): React.ReactElement => {
  const classes = useStyles();

  const labelInformationInfo = useLabelInformationInfo();

  const { clickedAnnotationInfo, hoveredAnnotation } = labelInformationInfo;
  const [info, setInfo] = useState({
    id: null,
    class: null,
    shape: null,
    properties: null,
  });

  useEffect(() => {
    if (!clickedAnnotationInfo && !hoveredAnnotation) return;
    if (clickedAnnotationInfo) {
      setInfo(clickedAnnotationInfo.annotation);
    }

    if (hoveredAnnotation) {
      if (clickedAnnotationInfo) return;
      setInfo(hoveredAnnotation);
    }
  }, [clickedAnnotationInfo, hoveredAnnotation]);

  return (
    <MUI.Box className={classes.box}>
      <MUI.Table className={classes.table}>
        <MUI.TableBody>
          <MUI.TableRow>
            <MUI.TableCell>Class</MUI.TableCell>
            <MUI.TableCell className={classes.className}>{info?.class || ''}</MUI.TableCell>
          </MUI.TableRow>
          <MUI.TableRow>
            <MUI.TableCell>Type</MUI.TableCell>
            <MUI.TableCell>{info?.shape ? map(info.shape, (value, key) => key) : ''}</MUI.TableCell>
          </MUI.TableRow>
          <MUI.TableRow>
            <MUI.TableCell>#</MUI.TableCell>
            <MUI.TableCell>{info?.id || ''}</MUI.TableCell>
          </MUI.TableRow>
          <MUI.TableRow>
            <MUI.TableCell>Properties</MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableBody>
      </MUI.Table>
      <MUI.Table className={classes.propertiesTable}>
        <MUI.TableBody>
          {info?.properties
            ? map(info.properties, (property: any, index) => {
                const { name } = property;
                let value = '';

                if (typeof property.value === 'string' || typeof property.value === 'number') {
                  value = property.value;
                } else {
                  value = property.value.join(', ');
                }
                return (
                  <MUI.TableRow className={classes.propertiesTable} key={index}>
                    <MUI.Tooltip title={name}>
                      <MUI.TableCell>{name}</MUI.TableCell>
                    </MUI.Tooltip>
                    <MUI.Tooltip title={value}>
                      <MUI.TableCell>{value}</MUI.TableCell>
                    </MUI.Tooltip>
                  </MUI.TableRow>
                );
              })
            : ''}
        </MUI.TableBody>
      </MUI.Table>
    </MUI.Box>
  );
};

export default AnnotationInformation;
