import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { startCase } from 'lodash';

import AssetsService from '../services/AssetsService';
import { AssetData, DatasetData } from '../types/dataTypes';
import ProjectUtils from '../utils/ProjectUtils';
import { useAuthInfo } from './AuthContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

// TODO: move to shared file
type SelectOptionBase = { label: string; value: string };
type SelectOptions = { label: string; options: SelectOptionBase[] }[] | SelectOptionBase[];

// prettier-ignore
type AssetsContextProps =
  StateGetterSetter<['assets', 'setAssets'], any[]> &
  StateGetterSetter<['totalCount', 'setTotalCount'], number> &
  StateGetterSetter<['datasets', 'setDatasets'], any[]> &
  StateGetterSetter<['totalDatasetCount', 'setTotalDatasetCount'], number> &
  StateGetterSetter<['datasetsOptions', 'setDatasetsOptions'], SelectOptions> &
  {
    updateAssets(params?: any, refresh?: boolean): Promise<{ count: number; results: AssetData[] } | undefined>;
    updateDatasets(params?: any, hasAllOption?: boolean): Promise<void>;
    updateProjectDatasets(params?: any): Promise<void>;
    updateDatasetName(params: any): Promise<void>;
  };

export const AssetsContext = React.createContext({} as AssetsContextProps);

export const AssetsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [assets, setAssets] = useState<any[]>([]);
  const [datasets, setDatasets] = useState<any[]>([]);
  const [totalDatasetCount, setTotalDatasetCount] = useState(0);
  const [datasetsOptions, setDatasetsOptions] = useState<SelectOptions>([]);
  const [totalCount, setTotalCount] = useState(0);

  const updateAssets = async (params: any, refresh = false) => {
    // When refresh is true, don't reset the previous data before fetching
    if (!refresh) {
      setAssets([]);
    }

    const assets = await AssetsService.getAssets({
      params,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    if (assets) {
      setAssets(assets.results);
      setTotalCount(assets.count);
    }

    return assets;
  };

  const updateDatasets = async (params: any = undefined, hasAllOption = true) => {
    setDatasets([]);
    const datasets = await AssetsService.getDatasets({
      params,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    if (!datasets) return;

    const datasetOptions = (datasets.results as any[]).map((dataset: DatasetData) => ({
      value: dataset.group,
      label: dataset.group,
    }));

    // prettier-ignore
    const allOptions: SelectOptions = hasAllOption ? [
      {
        label: 'all',
        options: [{ label: '[ all datasets ]', value: '[ all datasets ]' }],
      },
      {
        label: 'datasets',
        options: datasetOptions,
      },
    ] : datasetOptions;

    setDatasets(datasets.results);
    setTotalDatasetCount(datasets.count);
    setDatasetsOptions(allOptions);
  };

  const updateProjectDatasets = async (params: any) => {
    setDatasets([]);
    const datasets = await AssetsService.getProjectDatasets({
      params,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    if (!datasets) return;

    const datasetsOptions = (datasets.results as any[]).map((dataset: DatasetData) => ({
      value: dataset.group,
      label: dataset.group,
    }));

    setDatasets(datasets.results);
    setTotalDatasetCount(datasets.count);
    setDatasetsOptions(datasetsOptions);
  };

  const updateDatasetName = async ({ curName, newName, isGuest, urlInfo, origin }: any) => {
    await AssetsService.updateDatasetName({
      curName,
      newName,
      isGuest,
      urlInfo,
    });
    await updateDatasets();
  };

  useEffect(() => {
    if (!window.CommandBar) return;

    const getAssets = async (query: string) => {
      const res = await AssetsService.getAssets({
        params: { keyIcontains: query },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      return res.results.map(asset => ({
        ...asset,
        commandDescription: `${startCase(ProjectUtils.getProjectDataType(asset.info?.type))} in ${
          asset.group
        }`,
      }));
    };

    window.CommandBar.addContext('getAssetsByKey', [], {
      searchOptions: {
        searchFunction: getAssets,
      },
    });

    return () => {
      window.CommandBar.removeContext('getAssetsByKey');
    };
  }, [authInfo, routeInfo]);

  return (
    <AssetsContext.Provider
      value={{
        assets,
        setAssets,
        totalCount,
        setTotalCount,
        datasets,
        setDatasets,
        totalDatasetCount,
        setTotalDatasetCount,
        datasetsOptions,
        setDatasetsOptions,
        updateAssets,
        updateDatasets,
        updateProjectDatasets,
        updateDatasetName,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};

export const useAssetsInfo = (): AssetsContextProps => {
  return React.useContext(AssetsContext);
};
