import { useQuery } from '@tanstack/react-query';

import { DateRange } from '../components/pages/account/billingAndUsage/types';
import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import BillingService, {
  FeatureMeteringMetrics,
  FeatureMeteringSelector,
  FeaturesMeteringResponse,
} from '../services/BillingService';
import { FIVE_MINUTES, TWENTY_SECONDS } from '.';

type SerializeSelector<
  T extends { product: string; feature: string },
  Sep extends string,
> = T extends { product: infer P; feature: infer F }
  ? P extends string
    ? F extends string
      ? `${P}${Sep}${F}`
      : never
    : never
  : never;
type UnserializeSelector<
  T extends string,
  Sep extends string,
> = T extends `${infer P}${Sep}${infer F}` ? { product: P; feature: F } : never;

export type SerializedMeteringSelector = SerializeSelector<FeatureMeteringSelector, ':'>;

export function serializeMeteringSelector<F extends FeatureMeteringSelector>({
  product,
  feature,
}: F) {
  return `${product}:${feature}` as SerializeSelector<F, ':'>;
}
export function unserializeMeteringSelector<S extends SerializedMeteringSelector>(key: S) {
  const [product, feature] = key.split(':');
  return { product, feature } as UnserializeSelector<S, ':'>;
}

// Support for legacy API response. Remove after migrating backend.
const legacyFeatureToSelector = {
  AutoLabelAPI: { product: 'common', feature: 'ai-credit' },
  UserSeat: { product: 'common', feature: 'user-seat' },
  DataStorage: { product: 'label', feature: 'data-volume' },
} as const;
function convertLegacy(metering: FeaturesMeteringResponse[number]) {
  if (typeof metering.feature === 'string') {
    return metering;
  }
  const legacyMetering = metering as unknown as Omit<
    FeaturesMeteringResponse[number],
    'feature'
  > & {
    feature: { name: keyof typeof legacyFeatureToSelector };
  };
  return {
    ...legacyMetering,
    ...legacyFeatureToSelector[legacyMetering.feature.name],
  };
}

function meteringToMap(data: FeaturesMeteringResponse) {
  return new Map(
    data.map(d => {
      const _d = convertLegacy(d);
      return [serializeMeteringSelector(_d), _d];
    }),
  );
}

export function useMeteringQuery({ staleTime = TWENTY_SECONDS }: { staleTime?: number } = {}) {
  const params = useApiDefaultParams();
  const fetcher = async () => {
    const data = await BillingService.getFeaturesMetering({
      ...params,
    });
    return meteringToMap(data);
  };
  return useQuery(['metering', params.urlInfo.accountName], fetcher, {
    staleTime,
    cacheTime: FIVE_MINUTES,
  });
}

function getDefaultMetering<S extends SerializedMeteringSelector>(key: S) {
  return {
    ...unserializeMeteringSelector(key),
    onDemand: false,
    quantity: 0,
    maxQuantity: 0,
  };
}

/**
 * Convenience hook to read a specific metering value
 */
export function useMetering<S extends SerializedMeteringSelector>(selector: S) {
  const { data, isLoading } = useMeteringQuery();
  type Ret = FeatureMeteringMetrics & UnserializeSelector<S, ':'>;
  const metering = (data?.get(selector) as Ret) ?? getDefaultMetering(selector);
  return {
    ...metering,
    leftQuantity: metering.maxQuantity - metering.quantity,
    isLoading,
  };
}

/**
 * Create a function to check metering values, typed to match a specific product/feature.
 * This ensures that another metering value cannot be used as a parameter.
 */
export function meteringLogic<
  S extends SerializedMeteringSelector,
  V extends Record<string, any> = never,
>(
  cb: (
    metering: FeatureMeteringMetrics & UnserializeSelector<S, ':'> & { leftQuantity: number },
    values?: V,
  ) => boolean,
) {
  return cb;
}

export type Dependencies = {
  dateRange: DateRange;
  aggregationUnit: 'daily' | 'monthly';
};

export function useAICreditUsage({ aggregationUnit, dateRange }: Dependencies) {
  const params = useApiDefaultParams();

  return useQuery({
    queryKey: ['ai-credit', params.urlInfo.accountName, aggregationUnit, dateRange],
    queryFn: () => {
      return BillingService.getAICreditUsage({
        ...params,
        params: {
          from: dateRange.from,
          to: dateRange.to,
          aggregate: aggregationUnit,
        },
      });
    },
    staleTime: TWENTY_SECONDS,
  });
}
