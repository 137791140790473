import React, { createContext, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { StateGetterSetter } from '../../../contexts/types';
import { useDatasetObjectCountQuery } from '../queries/datasetObjectQueries';
import { GRID_VIEW, VIEW, ViewMode } from '../types/viewTypes';
import { useObjectFilterContext } from './ObjectFilterContext';
import { useQueryContext } from './QueryContext';
import { useSliceContext } from './SliceContext';

// prettier-ignore
type ContextProps = { totalCount: number | undefined; semanticSearchTotalCount: number|undefined} &
  StateGetterSetter<['viewAsImage', 'setViewAsImage'], boolean>;

const Context = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { queryString } = useQueryContext();
  const { sliceInfo } = useSliceContext(); // TODO: check if slice name is needed in object
  const { appliedFilters } = useObjectFilterContext();
  const [viewAsImage, setViewAsImage] = useState(false);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;

  const objectQuery = useDatasetObjectCountQuery({
    datasetId,
    queryString,
    sliceName: sliceInfo?.name || '',
    appliedFilters,
    clipEmbedExists: view === 'semantic_search' ? true : undefined,
  });

  const semanticSearchObjectQuery = useDatasetObjectCountQuery({
    datasetId,
    queryString,
    sliceName: sliceInfo?.name || '',
    appliedFilters,
    clipEmbedExists: view === 'semantic_search' ? true : undefined,
  });

  return {
    totalCount: objectQuery?.data?.count,
    semanticSearchTotalCount: semanticSearchObjectQuery?.data?.count,
    viewAsImage,
    setViewAsImage,
  };
};

export const useObjectScopeContext = (): ContextProps => {
  return useContext(Context);
};

export const ObjectScopeProvider: React.FC = ({ children }) => {
  const objectScopeInfo = useProvider();
  return <Context.Provider value={objectScopeInfo}>{children}</Context.Provider>;
};
