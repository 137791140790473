import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Box, LoadingIndicator, makePrintIfChanged, Typography } from '@superb-ai/ui';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import ExportService from '../../../../services/ExportService';
import { formatDateTodayYesterday } from '../../../../utils/date';
import HistoryInfo from './HistoryInfo';

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const { data, hasNextPage, fetchNextPage, isLoading, refetch } = useInfiniteQuery(
    ['exports', routeInfo.urlMatchInfo.projectId],
    ({ pageParam = 1 }) =>
      ExportService.getExportHistories({
        projectId: routeInfo.urlMatchInfo.projectId,
        params: { page: pageParam },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      }),
    {
      getNextPageParam: ({ count }, pages) =>
        count > pages.length * 10 ? pages.length + 1 : undefined,
    },
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const printIfChanged = makePrintIfChanged();

  return (
    <>
      <InfiniteScroll
        pageStart={1}
        hasMore={hasNextPage}
        loadMore={() => fetchNextPage()}
        initialLoad={false}
        loader={<LoadingIndicator />}
      >
        {data?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.results.map((history, index) => (
              <Fragment key={history.id}>
                {printIfChanged(
                  formatDateTodayYesterday(history.createdAt),
                  <Box mb={2}>
                    <Typography variant="l-regular">
                      {formatDateTodayYesterday(history.createdAt)}
                    </Typography>
                  </Box>,
                )}
                <HistoryInfo index={index} history={history} refetch={() => refetch()} />
              </Fragment>
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
      {!isLoading && !data?.pages?.[0]?.count && (
        <Box textAlign="center">
          <img
            style={{ width: 600 }}
            alt=""
            src="https://asset.superb-ai.com/suite/clip-list-is-empty.png"
          />
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h2">{t('export.history.messages.noExportHistory')}</Typography>
            <Typography variant="l-regular">{t('export.history.messages.exportLabels')}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Layout;
