import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import RegexUtils from '../../../../../utils/RegexUtils';

const useStyles = makeStyles(theme => ({
  slider: {
    '& .MuiSlider-thumb': {
      width: '12px',
      height: '12px',
      marginTop: '-5px',
      marginLeft: '-6px',
      '&::after': {
        top: '-2px',
        left: '-2px',
        right: '-2px',
        bottom: '-2px',
      },
      '&.MuiSlider-active, &:hover': {
        boxShadow: '0px 0px 0px 8px rgba(255, 98, 90, 0.16)',
      },
    },
    '& .MuiSlider-mark': {
      height: '4px',
      marginTop: '-1px',
      backgroundColor: theme.palette.primary.main,
      '&.MuiSlider-markActive': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  input: {
    width: '28px',
    fontSize: '13px',
    border: '1px solid #eee',
    borderRadius: '4px',
    '& .MuiInputBase-input': {
      padding: '1px 2px',
      textAlign: 'center',
    },
  },
}));

interface Props {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  currentValue: number;
  setCurrentValue: (value: number) => void;
  Icon: JSX.Element;
  sliderLabel: string | JSX.Element;
}

const TunerSlider: React.FC<Props> = props => {
  const { min, max, step, defaultValue, currentValue, setCurrentValue, Icon, sliderLabel } = props;
  const classes = useStyles();

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(String(Math.floor(currentValue)));
  }, [currentValue]);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(`${newValue as number}`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '-') {
      setValue(String(inputValue));
      return;
    }
    if (!RegexUtils.IS_INTEGER(inputValue) && inputValue !== '' && inputValue !== '.') {
      return;
    }
    const numInputValue = parseInt(inputValue, 10);
    if (!Number.isNaN(numInputValue)) {
      if (numInputValue < min) {
        setValue(String(min));
        setCurrentValue(min);
        return;
      }
      if (numInputValue > max) {
        setValue(String(max));
        setCurrentValue(max);
        return;
      }
      setCurrentValue(numInputValue);
    }
    setValue(String(inputValue));
  };

  const handleInputBlur = (event: React.FocusEvent) => {
    const inputValue = parseInt((event.target as HTMLInputElement).value, 10);
    if (Number.isNaN(inputValue)) {
      setValue(String(defaultValue));
      setCurrentValue(defaultValue);
    }
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    const numInputValue = parseInt(value, 10);

    if (event.key === 'ArrowUp' && numInputValue < max) {
      setValue(String(Math.floor((numInputValue + step) / step) * step));
    }
    if (event.key === 'ArrowDown' && numInputValue > min) {
      setValue(String(Math.ceil((numInputValue - step) / step) * step));
    }
  };

  const handleInputKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') {
      setCurrentValue(parseInt(value, 10));
    }
  };

  const handleSetCurrentValue = (event: any, newValue: number | number[]) => {
    setCurrentValue(newValue as number);
  };

  const handleSliderKeyUp = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <MUI.Box display="flex" alignItems="center">
        <MUI.Box display="flex" alignItems="center" mr="4px">
          {Icon}
        </MUI.Box>
        {sliderLabel}
      </MUI.Box>
      <MUI.Box display="flex" alignItems="center" mt="2px">
        <MUI.Slider
          className={classes.slider}
          defaultValue={defaultValue}
          value={Number(value)}
          min={min}
          max={max}
          step={step}
          marks={[{ value: 0 }]}
          onChange={handleSliderChange}
          onKeyUp={handleSliderKeyUp}
          onChangeCommitted={handleSetCurrentValue}
        />
        <MUI.Box ml="16px" display="flex" alignItems="center">
          <MUI.InputBase
            className={classes.input}
            value={value}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            onKeyUp={handleInputKeyUp}
          />
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default TunerSlider;
