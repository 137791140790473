import { Box, Link, LoadingIndicator, RadioButtons, Typography } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';
import { Select } from '@superb-ai/ui';
import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IntegrationsService from '../../../../../services/IntegrationsService';
import { SourceIntegrationRes } from '../../../../../types/integrationTypes';
import { SourceIntegrationTarget } from '../../../../pages/account/integrations/types';
import { CloudUploadContextProps } from '../../types';
import { cloudStorageOptions } from './config';
import { UploadStepInfo } from './type';

const SelectCloudStorage = ({
  t,
  isEnabled,
  cloudInfos,
}: {
  t: TFunction;
  isEnabled: boolean;
  cloudInfos: Pick<
    CloudUploadContextProps,
    | 'cloudStorageType'
    | 'setCloudStorageType'
    | 'cloudIntegrationId'
    | 'setCloudIntegrationId'
    | 'cloudBucketName'
    | 'setCloudBucketName'
    | 'setIntegratedCloudPrefix'
  >;
}): UploadStepInfo => {
  const {
    cloudStorageType,
    setCloudStorageType,
    cloudIntegrationId,
    setCloudIntegrationId,
    cloudBucketName,
    setCloudBucketName,
    setIntegratedCloudPrefix,
  } = cloudInfos;
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();

  const integrationLinkText = {
    S3: t('data.upload.addS3Integration'),
    GS: t('data.upload.addGoogleStorageIntegration'),
    ABS: t('data.upload.addAzureBlobStorage'),
  }[cloudStorageType];

  const integrationsQuery = useQuery({
    queryKey: ['integrations', routeInfo.urlMatchInfo.accountName, cloudStorageType],
    queryFn: () =>
      IntegrationsService.getIntegrationsByMethod({
        methodName: cloudStorageType,
        urlInfo: routeInfo.urlMatchInfo,
        isGuest: authInfo.isGuest,
      }),
    select: data => data.data as SourceIntegrationRes[],
    enabled: isEnabled && !!cloudStorageType,
  });

  const handleChangeOption = (value: SourceIntegrationTarget) => {
    setCloudStorageType(value);
  };

  function getIntegrationOptions(
    integrations: SourceIntegrationRes[],
    type: SourceIntegrationTarget,
  ) {
    const integrationName = {
      S3: 's3',
      GS: 'storage',
      ABS: 'azure',
    }[type];

    return integrations.map(integration => ({
      value: integration.integrationId,
      label: integration.info?.bucket
        ? `${integration.alias} (${integrationName}://${integration.info.bucket})`
        : integration.alias,
    }));
  }

  const handleIntegrationSelect = (integrationId: string) => {
    const integration = integrationsQuery.data?.find(i => i.integrationId === integrationId);
    if (integration?.info?.statusCheck === 'fail') {
      enqueueSnackbar(t('data.upload.integrationFailedMessage'), {
        variant: 'error',
      });
      return;
    }

    setCloudBucketName(integration?.info?.bucket || 'bucket');

    if (integration?.info?.prefix) {
      setIntegratedCloudPrefix(integration.info.prefix);
    } else {
      setIntegratedCloudPrefix(null);
    }

    setCloudIntegrationId(integrationId);
  };

  const summary = {
    S3: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <img src="/static/image/amazon_s3.png" width="12px" alt="s3 logo" />
        </Box>
        Amazon S3
      </Box>
    ),
    GS: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <img src="/static/image/gcp.png" width="16px" alt="gcp logo" />
        </Box>
        Google Storage
      </Box>
    ),
    ABS: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <img src="/static/image/abs.png" width="16px" alt="abs logo" />
        </Box>
        Azure Blob Storage
      </Box>
    ),
  }[cloudStorageType];

  return {
    title: t('data.upload.cloudStorage.title'),
    isButtonEnabled: cloudBucketName !== '',
    summary,
    content: (
      <>
        <RadioButtons
          options={cloudStorageOptions as Option<SourceIntegrationTarget>[]}
          value={cloudStorageType}
          onChange={handleChangeOption}
        />
        {integrationsQuery.isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" height={72}>
            <LoadingIndicator size="s" color={['grey', 500]} />
          </Box>
        )}
        {!integrationsQuery.isLoading &&
          (integrationsQuery.data?.length ? (
            <>
              <Box mt={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="label" themedColor="textDefault">
                    {t('data.upload.cloudStorage.selectIntegration')}
                  </Typography>
                  <Link
                    variant="body3"
                    themedColor="secondary"
                    underlined
                    to={`/${routeInfo.urlMatchInfo.accountName}/integrations/${cloudStorageType}`}
                  >
                    {t('data.upload.cloudStorage.manageIntegrations')}
                  </Link>
                </Box>
              </Box>
              <Box mt={1} display="flex">
                <Select
                  aria-label="select-integration"
                  placeholder={t('curate.datasets.cloudUpload.selectIntegrationPlaceholder')}
                  data={getIntegrationOptions(integrationsQuery.data, cloudStorageType)}
                  value={cloudIntegrationId}
                  onChangeValue={handleIntegrationSelect}
                />
              </Box>
            </>
          ) : (
            <Box mt={2} p={2} themedBackgroundColor={['grey', 60]}>
              <Typography variant="label" themedColor="textDefault">
                {t('data.upload.cloudStorage.addYourIntegration')}
              </Typography>
              <Box mt={0.5}>
                <Typography variant="body4" themedColor={['grey', 500]}>
                  {t('data.upload.cloudStorage.description.0')}
                  <br />
                  {t('data.upload.cloudStorage.description.1')}
                  <br />
                  2.{' '}
                  <Link
                    underlined
                    themedColor="secondary"
                    to={`/${routeInfo.urlMatchInfo.accountName}/integrations/${cloudStorageType}/add`}
                  >
                    {integrationLinkText}
                  </Link>
                  .
                  <br />
                  {t('data.upload.cloudStorage.description.3')}
                </Typography>
              </Box>
            </Box>
          ))}
      </>
    ),
  };
};

export default SelectCloudStorage;
