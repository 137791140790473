import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

type Props = {
  textComponent: React.ReactNode;
  style: React.CSSProperties;
};
export const InfoBanner = ({ textComponent, style }: Props) => {
  return (
    <Box
      display="flex"
      textAlign="left"
      alignItems="center"
      justifyContent="flex-start"
      mt={1.5}
      mx={1}
      px={1.5}
      py={1}
      gap={0.25}
      style={style}
      backgroundColor={'secondary-opacity-100'}
    >
      <Icon icon={InfoCircleOutline} color="secondary-400" />
      <Typography variant="m-medium" color="secondary-400">
        {textComponent}
      </Typography>
    </Box>
  );
};
