import { useEffect } from 'react';

import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import SelectCsvUploadType from '../steps/SelectCsvUploadType';
import UrlUpload from '../steps/UrlUpload';

const URL = (t: TFunction) => {
  const fileInfo = useUploadInfo();
  const {
    csvUploadMethod,
    setCsvUploadMethod,
    urls,
    setUrls,
    dataset,
    setDataset,
    selectedProject,
    setSelectedProject,
    setIsStepsCompleted,
  } = fileInfo;

  const UPLOAD_TYPE = 'upload_type';
  const URLS = 'urls';

  const initialize = (withUploadMethod?: boolean) => {
    if (withUploadMethod) {
      setCsvUploadMethod('URL');
    }
    setUrls([]);
    setDataset('');
    setSelectedProject(null);
    setIsStepsCompleted(false);
  };

  useEffect(() => {
    if (urls.length || dataset || selectedProject) {
      initialize(true);
    }
    // eslint-disable-next-line
  }, [csvUploadMethod]);

  return {
    [UPLOAD_TYPE]: SelectCsvUploadType({
      isButtonEnabled: !!csvUploadMethod,
      hasWarning: !!(urls.length || dataset || selectedProject),
      t,
    }),
    [URLS]: UrlUpload({
      isButtonEnabled: urls.length > 0,
      t,
    }),
  };
};

export default URL;
