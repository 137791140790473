import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import ProjectUtils from '../../../../utils/ProjectUtils';
import AdvancedQA from './AdvancedQA';
import AnnotationType from './AnnotationType';
import DataType from './DataType';
import ProjectDescription from './ProjectDescription';
import ProjectName from './ProjectName';
import SelfAssign from './SelfAssign';

const useStyles = makeStyles(() => ({
  box: {
    width: '450px',
    minWidth: '450px',
    paddingRight: '20px',
    boxSizing: 'content-box',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'visible',
    justifySelf: 'center',
  },
}));

const Layout = (): React.ReactElement => {
  const classes = useStyles();

  // Consensus temporary target
  const newProjectInfo = useNewProjectInfo();
  const consensusLabeling = useFeatureFlag('consensusLabeling');
  const isAvailableAdvancedQA =
    consensusLabeling && ProjectUtils.isAvailableAdvancedQA(newProjectInfo.selectedDataType);

  return (
    <MUI.Box className={classes.box}>
      <DataType />
      <ProjectName />
      <ProjectDescription />
      <AnnotationType />
      {/* public project는 이제 사용되지 않는 기능인듯합니다. tsnoh-20230718 */}
      {/* <PrivateProject /> */}
      <SelfAssign />
      {isAvailableAdvancedQA && <AdvancedQA />}
    </MUI.Box>
  );
};

export default Layout;
