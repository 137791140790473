import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';
import { isEmpty } from 'lodash';

import ServiceUtils from '../../../../../utils/ServiceUtils';
import { ChartStorage } from '../type';
import FilterDetail from './FilterDetail';

const ChartInfoDetail: FC<{ chartDetail: ChartStorage }> = props => {
  const { t } = useTranslation();
  const { filters } = props.chartDetail;

  // TODO: update filters data type so it's undefined or object
  if (!filters || isEmpty(filters)) return <></>;
  const numFilters = Object.keys(filters).length;

  return (
    <Box pt={1} pl={2}>
      {numFilters === 1 ? (
        <FilterDetail filter={ServiceUtils.getParamString(filters)} />
      ) : (
        <Accordion
          summary={t('analytics.customizeAnalytics.chart.appliedFilters') + ` (${numFilters})`}
        >
          <Box display="flex">
            <FilterDetail filter={ServiceUtils.getParamString(filters)} />
          </Box>
        </Accordion>
      )}
    </Box>
  );
};
export default ChartInfoDetail;
