import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';

import {
  dataTypes,
  getDataTypeByAssetType,
} from '../../../../apps/projects/project/data/assetDataTypes';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUsersInfo } from '../../../../contexts/UsersContext';
import { AssetData } from '../../../../types/dataTypes';
import { Display } from '../../../../types/displayTypes';
import { formatDate } from '../../../../utils/date';
import LabelUtils from '../../../../utils/LabelUtils';
import ProjectChip from '../../../elements/ProjectChip';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';
import VideoAssetSize from '../../../elements/VideoAssetSizeChip';

const useStyles = makeStyles(() => ({
  labelWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  },
}));

type Props = {
  asset: AssetData;
  index: number;
  setSelectedAssetId: Dispatch<SetStateAction<string | undefined>>;
  setSelectedAssetIndex: Dispatch<SetStateAction<number>>;
  onClickCheckbox: (
    assetId: string,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  checkedAssets: string[];
  variant: Display;
};

const DataTableViewRow: React.FC<Props> = props => {
  const routeInfo = useRouteInfo();
  const classes = useStyles();
  const usersInfo = useUsersInfo();

  const {
    asset,
    index,
    setSelectedAssetId,
    setSelectedAssetIndex,
    onClickCheckbox,
    checkedAssets,
    variant,
  } = props;

  const isAssetSelected = checkedAssets.includes(asset.id);

  const handleClickRow = () => {
    if (variant === 'modal') return;
    setSelectedAssetId(asset.id);
    setSelectedAssetIndex(index);
  };

  const handleClickProject = (projectId: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    routeInfo.history.push(`/${routeInfo.urlMatchInfo.accountName}/project/${projectId}`);
  };

  const dataType = getDataTypeByAssetType(asset.info?.type);

  let projectChip: ReactNode = null;
  if (variant === 'page') {
    const createdByName = usersInfo?.userObjects[asset.createdBy]?.name || asset.createdBy;
    const createdByTooltip =
      createdByName === asset.createdBy ? asset.createdBy : `${createdByName} (${asset.createdBy})`;

    projectChip = (
      <>
        <MUI.TableCell align="center">
          <TextEllipsisTooltip text={asset.group} justifyContent="center">
            <MUI.Typography>{asset.group}</MUI.Typography>
          </TextEllipsisTooltip>
        </MUI.TableCell>
        <MUI.TableCell align="center">
          <MUI.Box width="400px" display="flex" flexWrap="wrap" justifyContent="center">
            {map(asset.projects, project => (
              <ProjectChip
                key={project.id}
                projectId={project.id}
                projectName={project.name}
                onClickHandler={handleClickProject(project.id)}
              />
            ))}
          </MUI.Box>
        </MUI.TableCell>
        <MUI.TableCell align="center">
          <TextEllipsisTooltip text={createdByTooltip} justifyContent="center">
            <MUI.Typography>{createdByName}</MUI.Typography>
          </TextEllipsisTooltip>
        </MUI.TableCell>
      </>
    );
  } else if (variant === 'modal') {
    projectChip = null;
  } else {
    throw new Error('unhandled variant ');
  }

  const frameCount = LabelUtils.getFrameCount({ asset });
  const duration = LabelUtils.getDuration({ asset });

  return (
    <MUI.TableRow hover onClick={handleClickRow} selected={isAssetSelected}>
      <MUI.TableCell padding="checkbox" align="center">
        <MUI.Checkbox
          color="primary"
          onClick={onClickCheckbox(asset.id)}
          checked={isAssetSelected}
        />
      </MUI.TableCell>
      <MUI.TableCell>
        <div className={classes.labelWithIcon}>
          {dataType && <Icon size="14px" name={dataTypes[dataType]?.icon} />}
          <TextEllipsisTooltip text={asset.key}>
            <MUI.Typography>{asset.key}</MUI.Typography>
          </TextEllipsisTooltip>
          {(frameCount > 0 || duration > 0) && (
            <VideoAssetSize frameCount={frameCount} duration={duration} />
          )}
        </div>
      </MUI.TableCell>
      {projectChip}

      <MUI.TableCell align="center">{formatDate(asset.createdAt)}</MUI.TableCell>
    </MUI.TableRow>
  );
};

export default DataTableViewRow;
