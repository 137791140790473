/* eslint-disable @typescript-eslint/indent */
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty, map, size } from 'lodash';

import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { Display } from '../../../../types/displayTypes';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import { DataDetailNavigationContext } from '../dataDetail/Context';
import DataTableViewHead from './DataTableViewHead';
import DataTableViewRow from './DataTableViewRow';

const useStyles = makeStyles(() => ({
  allSelectedRow: {
    height: '40px',
  },
  allSelectedCell: {
    color: '#635c5c',
    backgroundColor: 'rgba(183, 183, 183, 0.12)',
  },
  textButton: {
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    textTransform: 'initial',
    lineHeight: 1,
    minWidth: 'initial',
    '&:hover': {
      backgroundColor: 'rgba(183, 183, 183, 0.2)',
    },
  },
  noSelectedRow: {
    height: '100px',
    '& td': {
      fontSize: '13px',
      textAlign: 'center',
    },
    '& a': {
      color: '#ff625a',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
}));

type Props = {
  checkedAssets: string[];
  setSelectedAssetId: Dispatch<SetStateAction<string | undefined>>;
  isDesc: boolean;
  isLoading: boolean;
  isAllAssetsChecked: boolean;
  orderBy: string;
  onRequestSort: (property: string, order: 'desc' | 'asc') => void;
  onClickCheckbox: (id: string) => (event: React.MouseEvent<Element, MouseEvent>) => any;
  onClickGlobalCheckbox: () => void;
  onClickSelectAllAssets: (isAllAssetsChecked: boolean) => () => void;
  variant: Display;
};

const TableView: React.FC<Props> = React.memo(
  props => {
    const classes = useStyles();
    const assetsInfo = useAssetsInfo();
    const {
      onClickCheckbox,
      onClickGlobalCheckbox,
      onClickSelectAllAssets,
      checkedAssets,
      setSelectedAssetId,
      isAllAssetsChecked,
      isDesc,
      orderBy,
      onRequestSort,
      isLoading,
      variant,
    } = props;
    const { t } = useTranslation();

    const { setSelectedAssetIndex } = React.useContext(DataDetailNavigationContext);

    const isGlobalChecked =
      assetsInfo.assets.length > 0 && checkedAssets.length === assetsInfo.assets.length;

    let colgroup: ReactNode = null;
    let colSpan = 6;
    if (variant === 'page') {
      colgroup = (
        <colgroup>
          <col width="60" />
          <col style={{ minWidth: 150 }} />
          <col width="150" />
          <col width="370" />
          <col width="150" />
          <col width="150" />
        </colgroup>
      );
    } else if (variant === 'modal') {
      colSpan = 3;
    }

    return (
      <>
        <MUI.Table size="small">
          {colgroup}
          <DataTableViewHead
            {...{ isDesc, orderBy, isGlobalChecked, onClickGlobalCheckbox, onRequestSort, variant }}
          />
          {!isLoading &&
            (isEmpty(assetsInfo.assets) ? (
              <MUI.TableBody>
                <MUI.TableRow className={classes.noSelectedRow}>
                  <MUI.TableCell colSpan={colSpan}>
                    <MUI.Box display="flex" alignItems="center" justifyContent="center">
                      <MUI.Typography variant="body2">{t('data.messages.noItems')}</MUI.Typography>
                    </MUI.Box>
                  </MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableBody>
            ) : (
              <MUI.TableBody>
                {checkedAssets.length === assetsInfo.assets.length &&
                  size(assetsInfo.assets) < assetsInfo.totalCount && (
                    <MUI.TableRow className={classes.allSelectedRow}>
                      <MUI.TableCell
                        className={classes.allSelectedCell}
                        align="center"
                        colSpan={colSpan}
                      >
                        <MUI.Box display="flex" alignItems="center" justifyContent="center">
                          <MUI.Typography variant="body2">
                            {isAllAssetsChecked
                              ? t('data.messages.allItemsInListSelected', {
                                  count: assetsInfo.totalCount,
                                })
                              : t('data.messages.allItemsOnPageSelected', {
                                  count: size(assetsInfo.assets),
                                })}
                          </MUI.Typography>
                          {isAllAssetsChecked ? (
                            <MUI.Button
                              className={classes.textButton}
                              variant="text"
                              color="primary"
                              size="small"
                              onClick={onClickSelectAllAssets(false)}
                            >
                              {t('labels.button.clearSelection')}
                            </MUI.Button>
                          ) : (
                            <MUI.Button
                              className={classes.textButton}
                              variant="text"
                              color="primary"
                              size="small"
                              onClick={onClickSelectAllAssets(true)}
                            >
                              {t('data.button.selectAllItemsInThisList', {
                                count: assetsInfo.totalCount,
                              })}
                              .
                            </MUI.Button>
                          )}
                        </MUI.Box>
                      </MUI.TableCell>
                    </MUI.TableRow>
                  )}
                {map(assetsInfo.assets, (asset, index) => (
                  <DataTableViewRow
                    key={asset.id}
                    {...{
                      asset,
                      index,
                      setSelectedAssetId,
                      setSelectedAssetIndex,
                      onClickCheckbox,
                      checkedAssets,
                      variant,
                    }}
                  />
                ))}
              </MUI.TableBody>
            ))}
        </MUI.Table>
        {isLoading && (
          <CircularProgressBox
            boxProps={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt: '50px',
            }}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.isLoading === nextProps.isLoading &&
      prevProps.checkedAssets === nextProps.checkedAssets &&
      prevProps.isAllAssetsChecked === nextProps.isAllAssetsChecked
    ) {
      return true;
    }
    return false;
  },
);

export default TableView;
