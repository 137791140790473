import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { Box } from '@superb-ai/ui';
import { orderBy as loOrderBy } from 'lodash';

import { rowsPerPageOptions } from '../userStats/config';
import { objectCountsHeader } from './constants/tableHeaders';
import ExportsAnalyticsTableHeader from './ExportsAnalyticsTableHeader';
import ObjectCountsTableRow from './ObjectCountsTableRow';
import { AnnotationsChangedTableProps, ObjectCountsRow } from './types/types';

interface Props {
  rows: ObjectCountsRow[];
  isDataEmpty: boolean;
}

export const disabledButtonProps = {
  nextIconButtonProps: {
    disabled: true,
  },
};

const ObjectCountsTable = ({ rows, isDataEmpty }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [isDesc, setIsDesc] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const getPaginatedRows = (rows: ObjectCountsRow[]) =>
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortRows = (rows: ObjectCountsRow[], isDesc: boolean) => {
    return loOrderBy(rows, orderBy, isDesc ? 'desc' : 'asc');
  };

  const makeRows = (rows: ObjectCountsRow[], header: AnnotationsChangedTableProps[]) => {
    return rows.map(row => <ObjectCountsTableRow key={row.classId} row={row} header={header} />);
  };

  const handleRequestSort = (property: string, order: 'desc' | 'asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" position="relative">
      <Box position="absolute" bottom="100%" right="0">
        <TablePagination
          {...(isDataEmpty ? disabledButtonProps : {})}
          rowsPerPageOptions={rows.length <= 10 ? [rows.length] : rowsPerPageOptions(rows.length)}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('labels.rowsPerPage')}
        />
      </Box>
      <TableContainer>
        <Table style={{ tableLayout: 'auto' }} size="small">
          <ExportsAnalyticsTableHeader
            tableName={'classes-count-table'}
            headers={objectCountsHeader}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {makeRows(getPaginatedRows(sortRows(rows, isDesc)), objectCountsHeader)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ObjectCountsTable;
