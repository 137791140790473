import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '@superb-ai/icons';
import { Box, Button } from '@superb-ai/ui';

import useSectionSize from '../../../../../../components/elements/windowedImageGrid/useSectionSize';
import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import AnnotationTypeFilter from './AnnotationTypeFilter';
import FilterHeader from './components/FilterHeader';
import ObjectClassClusterFilter from './ObjectClassClusterFilter';
import ObjectMetadataFilter from './ObjectMetadataFilter';
import ObjectProjectionFilter from './ObjectProjectionFilter';

export default function ObjectFilterLayout() {
  const { t } = useTranslation();

  const {
    isFilterOpen,
    setIsFilterOpen,
    setAppliedFilters,
    isApplyingFilter,
    initializeAppliedFilter,
    initializeSelectedFilter,
    hasAppliedFilters,
    selectedFilters,
    isFilterChanged,
    isLoadingFilterSchema,
  } = useObjectFilterContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionSize = useSectionSize(containerRef);

  function handleClickApply() {
    setAppliedFilters(selectedFilters);
  }

  if (!isFilterOpen) return <></>;

  return (
    <Box
      ref={containerRef}
      style={{ width: '240px', height: sectionSize ? sectionSize[3] : 0 }}
      borderLeft="1px solid"
      borderColor="gray-150"
      display="flex"
      flexDirection="column"
    >
      <FilterHeader
        label={t('curate.annotationFilter.title')}
        hasAppliedFilters={hasAppliedFilters}
        initializeAppliedFilter={initializeAppliedFilter}
        initializeSelectedFilter={initializeSelectedFilter}
        setIsFilterOpen={setIsFilterOpen}
      />
      <Box
        p={1}
        display="flex"
        flexDirection="column"
        gap={1}
        style={{ height: 'calc(100vh - 92px)' }}
      >
        {isLoadingFilterSchema ? (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ flex: 1 }}>
            <LoadingSpinner color="primary" />
          </Box>
        ) : (
          <>
            <ObjectProjectionFilter />
            <ObjectClassClusterFilter />
            <Box borderBottom="1px solid" borderColor="gray-150" />
            <ObjectMetadataFilter />
            <Box borderBottom="1px solid" borderColor="gray-150" />
            <AnnotationTypeFilter />
          </>
        )}
        <Box p={1} pb={0} mt="auto" borderTop="1px solid" borderColor="gray-150">
          <Button
            variant="strong-fill"
            color="primary"
            style={{ width: '100%' }}
            onClick={handleClickApply}
            loading={isApplyingFilter}
            disabled={!isFilterChanged}
          >
            {t('curate.annotationFilter.applyButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
