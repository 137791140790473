import { IconButton, Tooltip } from '@superb-ai/norwegian-forest';
import { TFunction } from 'i18next';

interface Props {
  size: 's' | 'm' | 'l' | 'xl' | 'xxs' | 'xs' | 'xxxs';
  selected: boolean;
  disabled: boolean;
  handleClick: (chartType: string) => void;
  t: TFunction;
}

export const BarChartIconButton = (props: Props): React.ReactElement => {
  const { size, selected, disabled, handleClick, t } = props;
  return (
    <Tooltip
      anchorEl={
        <span style={{ display: 'inline-flex' }}>
          <IconButton
            size={size}
            icon="chartBar"
            color={selected ? 'primary' : 'lightgrey'}
            variant="stroke"
            disabled={disabled}
            onClick={() => handleClick('bar')}
          />
        </span>
      }
    >
      {t('analytics.icon.barChart')}
    </Tooltip>
  );
};
