import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Link, Modal, Typography } from '@superb-ai/norwegian-forest';
import { PointcloudManifest } from '@superb-ai/siesta';
import { assign, cloneDeep, concat } from 'lodash';
import { useSnackbar } from 'notistack';
import * as path from 'path-browserify';
import { ensureDelay } from 'timed-async';

import {
  AssetDataType,
  dataTypesByProjectType,
  getDataTypeTextKeys,
  useAvailableDataTypes,
} from '../../../apps/projects/project/data/assetDataTypes';
import { getUserManualUrl } from '../../../consts/DocsLink';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { CloudParams, useUploadInfo } from '../../../contexts/UploadContext';
import AnalyticsTracker from '../../../analyticsTracker';
import CommandsService from '../../../services/CommandsService';
import FileService from '../../../services/FileService';
import { FileWithPath } from '../../../utils/FileUtils';
import { removeEmptyValueWithoutString } from '../../../utils/ObjectUtils';
import ProjectUtils from '../../../utils/ProjectUtils';
import { toastEventManager } from '../../../utils/ToastUtils';
import ErrorToast from './ErrorToast';
import UploadType from './stepper';
import { UploadMethod } from './types';
import UploadTypeCards from './uploadTypes/UploadTypeCards';

const UploadLayout = ({ projectId }: { projectId?: string }) => {
  const { i18n, t } = useTranslation();
  const { RAW_DATA_UPLOAD_AND_DOWNLOAD_MANUAL } = getUserManualUrl(i18n.language);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const {
    initialize,
    dataType,
    files,
    urls,
    dataset,
    isUploadModalOpen,
    setIsUploadModalOpen,
    uploadMethod,
    cloudStorageType,
    cloudIntegrationId,
    cloudSaveMethod,
    cloudPrefix,
    fps,
    customFps,
    selectedProject,
    isStepsCompleted,
    manifestFileName,
    videoFramesSourceData,
    setDataType,
  } = useUploadInfo();

  const { enqueueSnackbar } = useSnackbar();
  const projectInfo = useProjectInfo();
  const projectDataType = projectInfo.project
    ? ProjectUtils.getProjectDataType(projectInfo.project.workapp)
    : null;
  const commandContext = useLabelCommandContext();

  const dataTypes = useAvailableDataTypes();
  const [filesWithError, setFilesWithError] = useState<{ dataKey: string; error: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<'close' | 'cancel'>();
  const [supportedDataTypes, setSupportedDataTypes] = useState<AssetDataType[]>(
    Object.keys(dataTypes) as AssetDataType[],
  );
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  useEffect(() => {
    if (!isUploadModalOpen) return;

    const availableDataTypes = Object.keys(dataTypes) as AssetDataType[];
    const nextSupportedDataTypes = !projectDataType
      ? availableDataTypes
      : availableDataTypes.filter(k => dataTypesByProjectType[projectDataType].includes(k));
    setSupportedDataTypes(nextSupportedDataTypes);

    // If there's only one supported type, automatically select it
    if (nextSupportedDataTypes.length === 1) {
      setDataType(nextSupportedDataTypes[0]);
    }
  }, [projectDataType, dataTypes, setDataType, isUploadModalOpen]);

  const trackingContext = {
    type: uploadMethod?.toLowerCase() || '',
    dataType: dataType?.toLowerCase() || '',
  };

  const getVideoAssetList = (files: FileWithPath[]) => {
    const assets: Record<string, FileWithPath[]> = {};
    files.forEach(file => {
      if (!file.path) return;
      const assetKey = file.path.replace(/^\/?(.*)\/.*$/g, '$1'); // path without filename and leading slash
      if (!assets[assetKey]) {
        assets[assetKey] = [];
      }
      assets[assetKey].push(file);
    });
    return assets;
  };

  const getPointcloudAssetList = async (uniqFiles: FileWithPath[]) => {
    const manifestFiles = uniqFiles.filter(file => file.type === 'application/json');
    const assets: Record<string, FileWithPath[]> = {};
    for (const manifestFile of manifestFiles) {
      if (!manifestFile.path) throw new Error('?');
      const manifest = JSON.parse(await manifestFile.text()) as PointcloudManifest;
      const root = path.dirname(manifestFile.path);
      const key = manifest.key;
      const prefix = manifest.manifest.prefix;
      const sequenceFiles = [manifestFile];
      for (const frameInfo of manifest.manifest.frames) {
        const framePath = frameInfo['frame_path'];
        const targetFramePath = path.join(root, prefix, framePath);
        const frameFile = uniqFiles.find(file => targetFramePath === file.path);
        if (!frameFile) throw new Error('no pcd file');
        sequenceFiles.push(frameFile);
        for (const imageInfo of frameInfo['images']) {
          const imagePath = imageInfo['image_path'];
          const targetImagePath = path.join(root, prefix, imagePath);
          const imageFile = uniqFiles.find(file => targetImagePath === file.path);
          if (!imageFile) throw new Error('no image file');
          sequenceFiles.push(imageFile);
        }
      }
      assets[key] = sequenceFiles;
    }
    return assets;
  };

  const uploadFiles = async () => {
    toastEventManager.emit('PUSH', selectedProject ? 'label' : 'data', 'upload', {
      state: 'init',
      routeInfo: cloneDeep(routeInfo),
      dataType,
      files:
        dataType === 'IMAGE'
          ? files
          : dataType === 'POINT_CLOUD_SEQUENCE'
          ? Object.entries(await getPointcloudAssetList(files))
          : Object.entries(getVideoAssetList(files)),
      dataset,
      project: projectId || selectedProject?.id,
    });
  };

  const uploadURLs = async () => {
    setIsLoading(true);
    let filesWithError: { dataKey: string; error: string }[] = [];
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await FileService.uploadWithUrl({
          url: assign(url, { group: dataset }),
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        if (projectId || selectedProject) {
          // eslint-disable-next-line no-await-in-loop
          await FileService.registerUploadedAssetToLabel({
            projectId: projectId || selectedProject?.id || '',
            assetId: res.id,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          });
        }
      } catch (error: any) {
        filesWithError = concat(filesWithError, [{ dataKey: url.dataKey, error: error.message }]);
      }
    }

    if (filesWithError.length > 0) {
      setFilesWithError(filesWithError);
    } else {
      enqueueSnackbar(`Successfully uploaded ${urls.length} files`, { variant: 'success' });
    }

    setIsLoading(false);

    const dataCount = Math.max(0, urls.length - filesWithError.length);
  };

  const uploadFromCloud = async () => {
    const cloudParams = {
      integrationId: cloudIntegrationId,
      prefix: cloudPrefix,
      assetSaveMethod: cloudSaveMethod,
      type: cloudStorageType,
      manifestFileName,
    } as CloudParams;

    const getUploadTypeForCloud = () => {
      switch (dataType) {
        case 'VIDEO_STREAM':
          return 'videostream-presigned-url';
        case 'VIDEO_FRAMES':
          return 'video-presigned-url';
        case 'POINT_CLOUD_SEQUENCE':
          return 'pointclouds-presigned-url';
        case 'IMAGE':
        default:
          return 'img-presigned-url';
      }
    };

    const getFps = (): number | null => {
      if (dataType === 'VIDEO_FRAMES' && videoFramesSourceData === 'video') {
        if (fps !== 'custom') {
          return fps;
        }

        return Number(customFps);
      }
      return null;
    };

    const { type, ...otherCloudParams } = cloudParams;

    try {
      setIsLoading(true);
      await ensureDelay(async () => {
        const res = await (enabledLoki
          ? CommandsService.createCommandV2
          : CommandsService.createCommand)({
          type: 'ASSETS_CLOUD_UPLOAD',
          actionInfo: removeEmptyValueWithoutString({
            ...otherCloudParams,
            assetType: getUploadTypeForCloud(),
            dataset: dataset || '',
            projectId: projectId || selectedProject?.id || null,
            fps: getFps(),
          }),
          params: {},
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        commandContext.registerCommand(res.data.id);
      }, 1000);

      initialize();
      setIsUploadModalOpen(false);
      enqueueSnackbar('Upload has started in the background', { variant: 'success' });
      // There is no Upload Finished event here because the upload is finalized in the backend
    } catch (error: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const getUploadCall = async (uploadMethod: UploadMethod) => {
    AnalyticsTracker.labelDataUploadRequested({
      accountId: authInfo.accountName ?? '',
      uploadType: uploadMethod,
      dataType: dataType,
      ...(uploadMethod === 'CLOUD' && {
        cloudStorage: cloudStorageType,
        cloudSaveMethod: cloudSaveMethod,
      }),
    });
    if (uploadMethod === 'FILE') {
      return uploadFiles();
    }
    if (uploadMethod === 'URL') {
      return uploadURLs();
    }
    if (uploadMethod === 'CLOUD') {
      return uploadFromCloud();
    }
    return;
  };

  const onClickMainButton = async () => {
    if (!uploadMethod) return;
    await getUploadCall(uploadMethod);
    setIsUploadModalOpen(false);
    initialize();
  };

  if (!dataType) {
    return (
      <Modal
        open={isUploadModalOpen}
        title={
          <Box flex={1} display="flex" justifyContent="space-between" alignItems="center" mr={1}>
            <Typography variant="headline4">{t('data.dialogs.upload.title')}</Typography>
            <Link
              themedColor="secondary"
              variant="body3"
              href={RAW_DATA_UPLOAD_AND_DOWNLOAD_MANUAL}
              external
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                AnalyticsTracker.docsLinkClicked({
                  product: 'label',
                  referrer: 'label-upload-data-dialog',
                  accountId: authInfo.accountName ?? '',
                })
              }
            >
              {t('docs.documentation')}
            </Link>
          </Box>
        }
        mainButton={undefined}
        close={{
          onClose: () => {
            setIsUploadModalOpen(false);
            initialize();
          },
          hasCloseButton: true,
        }}
      >
        <UploadTypeCards dataTypes={supportedDataTypes} />
      </Modal>
    );
  }

  return (
    <Modal
      open={isUploadModalOpen}
      title={
        <Box flex={1} display="flex" justifyContent="space-between" alignItems="center" mr={1}>
          <Typography variant="headline4">{t(getDataTypeTextKeys(dataType).title)}</Typography>
          <Link
            themedColor="secondary"
            variant="body3"
            href={RAW_DATA_UPLOAD_AND_DOWNLOAD_MANUAL}
            external
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              AnalyticsTracker.docsLinkClicked({
                product: 'label',
                referrer: 'label-upload-data-dialog',
                accountId: authInfo.accountName ?? '',
              })
            }
          >
            {t('docs.documentation')}
          </Link>
        </Box>
      }
      mainButton={{
        text: uploadMethod !== 'CLI' ? t('data.button.upload') : t('button.cancel'),
        onClick: onClickMainButton,
        isLoading,
        disabled: uploadMethod !== 'CLI' && !isStepsCompleted,
      }}
      subButton={
        uploadMethod !== 'CLI'
          ? {
              text: t('button.cancel'),
              onClick: () => {
                setConfirmation('cancel');
              },
            }
          : undefined
      }
      close={{
        onClose: () => {
          setConfirmation('close');
        },
        hasCloseButton: true,
      }}
      confirmation={{
        open: !!confirmation,
        content: (
          <Box height="44px">
            <Typography variant="body3">
              <Trans t={t} i18nKey="data.dialogs.upload.areYouSureToQuit" />
            </Typography>
          </Box>
        ),
        confirmButtonText: t('button.ok'),
        cancelButtonText: t('button.cancel'),
        onConfirm: () => {
          initialize();
          setIsUploadModalOpen(false);
          setConfirmation(undefined);
        },
        onCancel: () => {
          setConfirmation(undefined);
        },
      }}
    >
      <Box p={3} width={748} height={530}>
        <UploadType />
      </Box>
      <ErrorToast errors={filesWithError} onClose={() => setFilesWithError([])} />
    </Modal>
  );
};

export default UploadLayout;
