import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { css } from '@emotion/react';
import { Box, Card, Chip, Typography } from '@superb-ai/norwegian-forest';

import {
  AssetDataType,
  dataTypes,
  getDataTypeTextKeys,
} from '../../../../apps/projects/project/data/assetDataTypes';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import IconLogoBadge from '../stepper/IconLogoBadge';
import { UploadIcon } from '../types';

const UploadType: React.FC<{ type: AssetDataType }> = ({ type }) => {
  const { tier } = useAuthInfo();
  const { t } = useTranslation();

  const { icon, formats, availableAtTier } = dataTypes[type];
  const { title, description } = getDataTypeTextKeys(type);
  const { setDataType } = useUploadInfo();

  const planLimit = !(tier ? availableAtTier?.(tier) ?? true : true);

  const handleClick = (type: AssetDataType) => () => {
    if (planLimit) return;
    setDataType(type);
  };

  return (
    <Card
      py={4}
      px={2}
      onClick={planLimit ? undefined : handleClick(type)}
      position="relative"
      minHeight="260px"
      themedColor={planLimit ? ['grey', 100] : 'grey'}
      themedBackgroundColor={planLimit ? ['grey', 30] : 'inherit'}
      shadow={0}
      css={css`
        border: ${planLimit ? '1px solid #F5F5F5;' : '1px solid #d4d4d4;'};
      `}
    >
      {planLimit && (
        <Box position="absolute" top="8px" left="8px">
          <Chip color="strawberry">{planLimit}</Chip>
        </Box>
      )}
      <Typography variant="headline6" style={{ textAlign: 'center' }}>
        {t(title)}
      </Typography>
      <Typography variant="body4" style={{ textAlign: 'center' }}>
        {formats.join(', ')}
      </Typography>
      <Box display="flex" justifyContent="center" my={2}>
        <IconLogoBadge iconName={icon as UploadIcon} width={80} disabled={!!planLimit} />
      </Box>
      <Box mt={1}>
        <Typography variant="body3" style={{ textAlign: 'center' }}>
          <Trans t={t} i18nKey={description} />
        </Typography>
      </Box>
    </Card>
  );
};

export default UploadType;
