import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Typography } from '@superb-ai/ui';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { PurposeChip } from '../../components/components';
import {
  useCheckModelNameUniqueQuery,
  useModelDetailQuery,
  useModelListQuery,
  usePublicModelListQuery,
} from '../../queries/modelQueries';
import {
  ModelListData,
  ModelTag,
  MyModel,
  MyModelDetail,
  PublicModel,
  PurposeType,
  TaskType,
} from '../../services/types';
import { useTrainUrlParams } from '../queries';

type SourceType = 'public_model' | 'my_model';
type ContextProps = {
  setSelectedPublicModelId: Dispatch<SetStateAction<string | null>>;
  publicModelId: string | null;
  selectedPublicModel: PublicModel | undefined;
  publicModels: PublicModel[];
  summary: string | EmotionJSX.Element | undefined;
  modelPurpose: PurposeType | null;
  setModelPurpose: Dispatch<SetStateAction<PurposeType | null>>;
  modelSource: SourceType | null;
  setModelSource: Dispatch<SetStateAction<SourceType | null>>;
  taskType: TaskType | 'all_task';
  setTaskType: Dispatch<SetStateAction<TaskType | 'all_task'>>;

  myModelsList: MyModel[];
  myModelId: string | null;
  setSelectedMyModelId: Dispatch<SetStateAction<string | null>>;
  selectedMyModel: MyModelDetail | undefined;
  searchNameFilter: string;
  uniqueNameFilter: string;
  setSearchNameFilter: Dispatch<SetStateAction<string>>;
  setUniqueNameFilter: Dispatch<SetStateAction<string>>;
  isModelNameUnique: boolean;
  myModelsQuery: UseInfiniteQueryResult<ModelListData, unknown>;
  tagFilter: ModelTag[];
  setTagFilter: Dispatch<SetStateAction<ModelTag[]>>;
};

const Context = createContext({} as ContextProps);

const useProvider = () => {
  const params = useTrainUrlParams();
  const [modelPurpose, setModelPurpose] = useState<PurposeType | null>(
    !params.from
      ? null
      : params.from === 'generation'
      ? 'generation'
      : params.from === 'recognition'
      ? 'recognition'
      : null,
  );
  const publicModelsQuery = usePublicModelListQuery({
    params: { modelPurpose: modelPurpose ?? undefined },
  });
  const [selectedPublicModelId, setSelectedPublicModelId] = useState<string | null>(null);

  const [taskType, setTaskType] = useState<TaskType | 'all_task'>('all_task');

  const publicModelId = selectedPublicModelId ?? params.publicModelId;
  const publicModels = publicModelsQuery.data?.pages.flatMap(p => p.data) ?? [];

  const selectedPublicModel = publicModels.find(({ id }) => id === publicModelId);

  const [searchNameFilter, setSearchNameFilter] = useState('');
  const [uniqueNameFilter, setUniqueNameFilter] = useState('');

  const debouncedNameFilter = useDebounce(searchNameFilter, 300);
  const debouncedUniqueNameFilter = useDebounce(uniqueNameFilter, 300);

  const [tagFilter, setTagFilter] = useState<ModelTag[]>([]);
  const [selectedMyModelId, setSelectedMyModelId] = useState<string | null>(null);

  const myModelId = selectedMyModelId ?? params.trainedModelId;

  // detail query는 빈 id를 넘겨주면 list처럼 작동한다
  const myModelQuery = useModelDetailQuery({
    id: myModelId ?? 'none',
    modelPurpose: modelPurpose ?? 'recognition',
    enabled: Boolean(myModelId),
  });
  const selectedMyModel = myModelQuery.data;

  const myModelsQuery = useModelListQuery({
    params: {
      name: debouncedNameFilter,
      status: ['trained'],
      referenceId: params.datasetId ? [params.datasetId] : [],
      tag: tagFilter.map(tag => tag.name),
      task: taskType !== 'all_task' ? [taskType] : undefined,
      modelPurpose: modelPurpose ?? undefined,
    },
  });
  const myModelsList = myModelsQuery.data?.pages.flatMap(p => p.modelList) ?? [];

  const checkModelNameUniqueQuery = useCheckModelNameUniqueQuery({
    name: debouncedUniqueNameFilter,
  });
  const { data: _data } = checkModelNameUniqueQuery;
  const isModelNameUnique = (_data?.pages.flatMap(p => p.modelList) ?? []).length === 0;

  const [modelSource, setModelSource] = useState<SourceType | null>(
    params.trainedModelId ? 'my_model' : params.publicModelId ? 'public_model' : null,
  );

  const summary = (selectedPublicModel || selectedMyModel) && (
    <Box display="flex" gap={0.5} alignItems="center">
      <PurposeChip purpose={modelPurpose} />
      <TextEllipsisBox style={{ width: 186 }}>
        <Typography variant="m-regular">
          {selectedPublicModel && selectedPublicModel.name}
        </Typography>
        <Typography variant="m-regular">
          {selectedMyModel && selectedMyModel.modelSetting.name}
        </Typography>
      </TextEllipsisBox>
    </Box>
  );

  return {
    publicModelId,
    selectedPublicModel,
    setSelectedPublicModelId,
    publicModels,
    summary,
    modelPurpose,
    setModelPurpose,
    modelSource,
    setModelSource,
    taskType,
    setTaskType,

    myModelsList,
    myModelId,
    setSelectedMyModelId,
    selectedMyModel,
    searchNameFilter,
    uniqueNameFilter,
    setSearchNameFilter,
    setUniqueNameFilter,
    isModelNameUnique,
    myModelsQuery,
    tagFilter,
    setTagFilter,
  };
};
export const useBaselineContext = (): ContextProps => {
  return useContext(Context);
};

export const BaselineProvider: React.FC = ({ children }) => {
  const baselineInfo = useProvider();
  return <Context.Provider value={baselineInfo}>{children}</Context.Provider>;
};
