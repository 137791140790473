import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

import { labelId } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterTextField from './components/FilterTextField';
import { FilterTypeProps } from './types';

const FilterPreLabel: React.FC<FilterTypeProps> = props => {
  const { t } = useTranslation();
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={labelId.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      <MUI.Grid item style={{ flex: 1 }}>
        <FilterTextField
          index={index}
          objKey="options"
          isCurrentRow={isCurrentRow}
          onValueChange={onValueChange}
          value={filter.options}
          placeholder={t('labels.filters.labelIdPlaceholder')}
        />
      </MUI.Grid>
    </>
  );
};

export default FilterPreLabel;
