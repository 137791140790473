import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useDiagnosisModelContext } from './DiagnosisModelContext';

export type DiagnosisAnalyticsFilterContextProps = {
  selectedClass: string;
  setSelectedClass: Dispatch<SetStateAction<string>>;
};

const Context = React.createContext({} as DiagnosisAnalyticsFilterContextProps);

const useProvider = () => {
  const [selectedClass, setSelectedClass] = useState<string>('all');

  const { selectedDiagnosis } = useDiagnosisModelContext();

  useEffect(() => {
    setSelectedClass('all');
  }, [selectedDiagnosis]);

  return {
    selectedClass,
    setSelectedClass,
  };
};

export const useDiagnosisAnalyticsFilterContext = (): DiagnosisAnalyticsFilterContextProps => {
  return React.useContext(Context);
};

export const DiagnosisAnalyticsFilterProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
