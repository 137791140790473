import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Pencil } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { formatDate } from '../../../../../../utils/date';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useDatasetModalContext } from '../../../../contexts/ModalContext';
import DatasetSettingsModal from '../modal/DatasetSettingsModal';

export default function DatasetSetting() {
  const { setDatasetSettingsIsOpen } = useDatasetModalContext();
  const { t } = useTranslation();
  const { datasetInfo } = useDatasetContext();

  return (
    <Box p={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="l-strong">{t('curate.dataset.settings.datasetSettings')}</Typography>
        <Button variant="stroke" onClick={() => setDatasetSettingsIsOpen(true)}>
          <Icon icon={Pencil} />
          {t('button.edit')}
        </Button>
      </Box>
      <Box mt={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="m-regular" color="gray-300" style={{ width: '116px' }}>
            {t('curate.dataset.settings.datasetName')}
          </Typography>
          <Typography variant="m-regular">{datasetInfo?.name}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <Typography variant="m-regular" color="gray-300" style={{ width: '116px' }}>
            {t('curate.dataset.settings.description')}
          </Typography>
          <Typography variant="m-regular">{datasetInfo?.description || '-'}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <Typography variant="m-regular" color="gray-300">
            <Trans
              i18nKey="curate.dataset.settings.created"
              t={t}
              values={{
                createdBy: datasetInfo?.createdBy,
                createdAt: datasetInfo?.createdAt ? formatDate(datasetInfo.createdAt) : '',
              }}
              components={{ strong1: <Typography variant="m-strong" /> }}
            />
          </Typography>
        </Box>
      </Box>
      <DatasetSettingsModal />
    </Box>
  );
}
