import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import RawDataDownload from '../../../../../components/pages/settings/general/rawDataDownload';

export default function Layout(): JSX.Element {
  return (
    <Box>
      <RawDataDownload />
    </Box>
  );
}
