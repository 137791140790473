import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
  Check,
  ChevronDown,
  Sort,
  SortAlphabeticalAsc,
  SortAlphabeticalDesc,
  SortNumericAsc,
  SortNumericDesc,
} from '@superb-ai/icons';
import { Card, Popper } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

export default function SortDatasetDropdown({
  sortOrder,
  handleSortOrder,
  sortBy,
  handleSortBy,
}: {
  sortOrder: string;
  handleSortOrder: (order: string) => void;
  sortBy: string;
  handleSortBy: (sort: string) => void;
}): JSX.Element {
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  function getIcon() {
    if (sortBy === 'created_at' || sortBy === 'updated_at') {
      return sortOrder === 'asc' ? SortNumericAsc : SortNumericDesc;
    }
    if (sortBy === 'name') {
      return sortOrder === 'asc' ? SortAlphabeticalAsc : SortAlphabeticalDesc;
    }
    return Sort;
  }

  return (
    <Popper
      open={selectIsOpen}
      sameWidth
      useOutsidePanel
      placement="bottom-start"
      onClickOutside={() => setSelectIsOpen(false)}
      anchorEl={
        <Button
          style={{ width: '160px' }}
          variant="stroke"
          color="gray"
          onClick={() => setSelectIsOpen(true)}
        >
          <Icon icon={getIcon()} size="16px" />
          <Typography variant="m-regular">
            {sortBy === 'created_at'
              ? t('curate.button.dateCreated')
              : sortBy === 'name'
              ? t('curate.button.sortByName')
              : t('curate.button.lastUpdated')}
          </Typography>
          <Icon style={{ marginLeft: 'auto' }} icon={ChevronDown} size="16px" />
        </Button>
      }
    >
      <Card py={0.5}>
        <Box style={{ height: '30px' }} py={1} px={1.5}>
          <Typography variant="s-regular" color="gray-300">
            {t('curate.button.sortBy')}
          </Typography>
        </Box>
        <SelectBox
          onClick={() => {
            handleSortBy('created_at');
            handleSortOrder('desc');
          }}
          selected={sortBy === 'created_at'}
        >
          <Typography
            variant="m-regular"
            color={sortBy === 'created_at' ? 'primary-500' : 'gray-500'}
          >
            {t('curate.button.dateCreated')}
          </Typography>
          {sortBy === 'created_at' && <Icon icon={Check} size="16px" color="primary" />}
        </SelectBox>
        <SelectBox
          onClick={() => {
            handleSortBy('name');
            handleSortOrder('asc');
          }}
          selected={sortBy === 'name'}
        >
          <Typography variant="m-regular" color={sortBy === 'name' ? 'primary-500' : 'gray-500'}>
            {t('curate.button.sortByName')}
          </Typography>
          {sortBy === 'name' && <Icon icon={Check} size="16px" color="primary" />}
        </SelectBox>
        <Box width="100%" style={{ height: '30px' }} py={1} px={1.5} backgroundColor="white">
          <Typography variant="s-regular" color="gray-300">
            {t('curate.button.order')}
          </Typography>
        </Box>
        {sortBy === 'name' ? (
          <>
            <SelectBox onClick={() => handleSortOrder('asc')} selected={sortOrder === 'asc'}>
              <Typography
                variant="m-regular"
                color={sortOrder === 'asc' ? 'primary-500' : 'gray-500'}
              >
                A - Z
              </Typography>

              {sortOrder === 'asc' && <Icon icon={Check} size="16px" color="primary-500" />}
            </SelectBox>
            <SelectBox onClick={() => handleSortOrder('desc')} selected={sortOrder === 'desc'}>
              <Typography
                variant="m-regular"
                color={sortOrder === 'desc' ? 'primary-500' : 'gray-500'}
              >
                Z - A
              </Typography>

              {sortOrder === 'desc' && <Icon icon={Check} size="16px" color="primary-500" />}
            </SelectBox>
          </>
        ) : (
          <>
            <SelectBox onClick={() => handleSortOrder('desc')} selected={sortOrder === 'desc'}>
              <Typography
                variant="m-regular"
                color={sortOrder === 'desc' ? 'primary-500' : 'gray-500'}
              >
                {t('curate.button.newestFirst')}
              </Typography>

              {sortOrder === 'desc' && <Icon icon={Check} size="16px" color="primary-500" />}
            </SelectBox>
            <SelectBox onClick={() => handleSortOrder('asc')} selected={sortOrder === 'asc'}>
              <Typography
                variant="m-regular"
                color={sortOrder === 'asc' ? 'primary-500' : 'gray-500'}
              >
                {t('curate.button.oldestFirst')}
              </Typography>

              {sortOrder === 'asc' && <Icon icon={Check} size="16px" color="primary-500" />}
            </SelectBox>
          </>
        )}
      </Card>
    </Popper>
  );
}

const SelectBox = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 7px 12px 7px 20px;
  background: ${props => (props.selected ? '#fff8f8' : '#ffffff')};
  &:hover {
    background: #fff8f8;
    p {
      color: #ff625a;
    }
  }
`;
