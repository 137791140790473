import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon, Typography } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';
import { isEmpty, map } from 'lodash';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectService from '../../../services/ProjectService';
import { CustomTheme } from '../../../types/muiTypes';
import { formatDateLong } from '../../../utils/date';
import FileUtils from '../../../utils/FileUtils';
import TextEllipsisTooltip from '../../elements/TextEllipsisTooltip';
import GuidelineUploadButton from './GuidelineUploadButton';
import { GuidelineType } from './types';

const useStyle = makeStyles((theme: CustomTheme) => ({
  paperSmall: {
    '&.MuiPaper-root': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  list: {
    marginTop: theme.spacing(1.5),
    '& .MuiListItem-root': {
      fontSize: '12px',
      width: 'calc(100% + 50px)',
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'initial',
      marginRight: theme.spacing(1),
    },
  },
  guidelineTitle: {
    maxWidth: '210px',
  },
  guidelineDate: {
    textAlign: 'right',
  },
}));

type Props = {
  getOverviewInfos: () => Promise<any>;
  className: string;
  guidelines: GuidelineType[];
};

const Guideline: React.FC<Props> = props => {
  const classes = useStyle();
  const theme = useTheme();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const {
    project: { id },
  } = projectInfo;
  const { className, guidelines, getOverviewInfos } = props;
  const { t } = useTranslation();

  const handleClickDownload = (guidelineId: string) => async () => {
    const file = await ProjectService.downloadGuideline({
      projectId: id,
      guidelineId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const filePath = file.downloadUrl;
    FileUtils.downloadViaPath(filePath, file.fileName);
  };

  return (
    <MUI.Paper className={classnames(classes.paperSmall, className)}>
      <MUI.Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="headline4"> {t('overview.guideline.title')}</Typography>
        <GuidelineUploadButton getOverviewInfos={getOverviewInfos} />
      </MUI.Box>
      {isEmpty(guidelines) ? (
        <MUI.Box display="flex" alignItems="center" height="115px">
          <MUI.Typography color="textSecondary" variant="subtitle1">
            {t('overview.guideline.description')}
          </MUI.Typography>
        </MUI.Box>
      ) : (
        <MUI.Box height="108px" overflow="auto" m="0 -25px" p="0 25px">
          <MUI.List className={classes.list}>
            {map(guidelines, guideline => (
              <MUI.ListItem
                key={guideline.id}
                button
                divider
                onClick={handleClickDownload(guideline.id)}
              >
                <MUI.ListItemIcon>
                  <Icon name="download" size="15px" customColor={theme.palette.grey[700]} />
                </MUI.ListItemIcon>
                <MUI.ListItemText className={classes.guidelineTitle}>
                  <TextEllipsisTooltip text={guideline.title}>
                    <MUI.Typography>{guideline.title}</MUI.Typography>
                  </TextEllipsisTooltip>
                </MUI.ListItemText>
                <MUI.ListItemText className={classes.guidelineDate}>
                  <MUI.Typography>{formatDateLong(guideline.updatedAt)}</MUI.Typography>
                </MUI.ListItemText>
              </MUI.ListItem>
            ))}
          </MUI.List>
        </MUI.Box>
      )}
    </MUI.Paper>
  );
};

export default Guideline;
