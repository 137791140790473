import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { Box as NFBox, Card, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { sumBy } from 'lodash';

import AnalyticsTracker from '../../../../../analyticsTracker';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import WorkappUnion from '../../../../../union/WorkappUnion';
import { UserProfileObject } from '../../../../../utils/UserUtils';
import { CopyToClipboardButton } from '../../../../elements/CopyToClipboardButton';
import { CHART } from '../../config/plotConfig';
import { FileFormat } from '../../dataTypes/analyticsDataTypes';
import DownloadDropDown from '../../tools/DownloadDropDown';
import { labelerDefaultConfig } from '../config';
import useLabelerFilter from '../hooks/useLabelerFilter';
import { BottomHeader, JsonObj, LabelerDatum } from '../types';
import {
  ClassesHeaderMapper,
  columnIdToNameMapper,
  getLabelerTableBottomHeader,
  prepForDownload,
} from './helper';
import LabelerColumnFilterDropdown from './LabelerColumnFilterDropdown';
import LabelerTable from './LabelerTable';

interface Props {
  rows: JsonObj[];
  syncState: string;
  handleReportDownload: (
    outputType: FileFormat,
    rows: Record<string, string | number>[],
    headerDisplayName: Record<string, string>,
    name: string,
    labelCount: number,
    reportType: 'reviewer' | 'labeler',
    bottomHeader?: BottomHeader[],
  ) => void;
  emptyMessage: string;
  width: number;
  userProfiles: UserProfileObject;
  objectClassesDisplayNames?: ClassesHeaderMapper;
}

interface getFilteredColumnsParams {
  bottomHeader: JsonObj[];
  showObjectClassColumns: boolean;
  showAnnoTypeColumns: boolean;
  currentTab: string;
}

const LabelerContainer: React.FC<Props> = props => {
  const {
    rows,
    syncState,
    handleReportDownload,
    emptyMessage,
    width,
    userProfiles,
    objectClassesDisplayNames,
  } = props;
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const {
    project: { labelInterface, name, workapp },
  } = projectInfo;

  const { t } = useTranslation();
  const reviewVisible = WorkappUnion.isSiesta(projectInfo?.project?.workapp);
  const chartObj = CHART.labelerThroughput;

  /** Used for table excel download */
  const tableRef = useRef<HTMLTableElement | null>(null);

  const {
    roles,
    showAnnoTypeColumns,
    showObjectClassColumns,
    handleChangeRoles,
    setshowAnnoTypeColumns,
    setShowObjectClassColumns,
  } = useLabelerFilter();
  const [columns, setColumns] = useState<BottomHeader[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(labelerDefaultConfig.tab);

  const [bottomHeader, columnIdToNameMap] = useMemo(() => {
    const bottomHeader = getLabelerTableBottomHeader({
      labelInterface,
      workapp,
      reviewVisible,
      objectClassesDisplayNames,
    });
    return [bottomHeader, columnIdToNameMapper({ bottomHeader, t })];
  }, [labelInterface, workapp, reviewVisible, objectClassesDisplayNames, t]);

  const getFilteredColumns = (params: getFilteredColumnsParams) => {
    const { bottomHeader, showAnnoTypeColumns, showObjectClassColumns, currentTab } = params;
    const swapColors = (color: string) => {
      const colorMap = { '#F4F4F4': '#FAFAFA', '#FAFAFA': '#F4F4F4' };
      return colorMap[color as keyof typeof colorMap];
    };
    const isTabLabelingResult = (currentTab: string) => currentTab === 'labelingResult';
    const isColumnLabelOrFrameCounts = (column: { id: string }) =>
      ['labelCounts', 'frameCounts'].includes(column.id);

    const filterByTab = (column: any) =>
      (isTabLabelingResult(currentTab) && column.group !== 'reviewResults') ||
      (!isTabLabelingResult(currentTab) &&
        (column.group === 'labeler' ||
          isColumnLabelOrFrameCounts(column) ||
          column.group === 'reviewResults'));
    const filterByAnnotationType = (column: any) =>
      showAnnoTypeColumns || column.group !== 'annoType';
    const filterByObjectClass = (column: any) =>
      showObjectClassColumns || column.group !== 'objectClass';
    const updateObjectClassColors = (column: any) =>
      column.group === 'objectClass' ? { ...column, color: swapColors(column?.color) } : column;

    const filteredColumns = bottomHeader
      .filter(filterByTab)
      .filter(filterByAnnotationType)
      .map(updateObjectClassColors)
      .filter(filterByObjectClass);
    return filteredColumns;
  };

  const filteredColumns = useMemo(() => {
    return getFilteredColumns({
      bottomHeader,
      showObjectClassColumns,
      showAnnoTypeColumns,
      currentTab,
    });
  }, [bottomHeader, showObjectClassColumns, showAnnoTypeColumns, currentTab]);

  useEffect(() => {
    setColumns(filteredColumns);
  }, [showObjectClassColumns, showAnnoTypeColumns, currentTab, t, filteredColumns]);

  const filterRows = (rows: LabelerDatum[]) => {
    return rows.filter(user => roles.includes(user.role ?? '') || !user.role);
  };

  const handleDownload = (outputType: FileFormat) => {
    const filteredRows = prepForDownload(filterRows(rows as LabelerDatum[]));
    const reportLabelCount = sumBy(rows, 'labelCounts');
    handleReportDownload(
      outputType,
      filteredRows,
      columnIdToNameMap,
      name,
      reportLabelCount,
      'labeler',
      bottomHeader,
    );
    AnalyticsTracker.userReportDownloaded({
      targetRole: 'labeler',
      labelCount: reportLabelCount,
      accountId: authInfo.accountName ?? '',
    });
  };

  const handleChangeTab = (newTab: string) => {
    setCurrentTab(newTab);
  };

  const handleCopyClipboard = () => {
    const filteredRows = prepForDownload(filterRows(rows as LabelerDatum[]));
    const reportLabelCount = sumBy(rows, 'labelCounts');
    handleReportDownload(
      'copy' as FileFormat,
      filteredRows,
      columnIdToNameMap,
      name,
      reportLabelCount,
      'labeler',
      bottomHeader,
    );
  };

  return (
    <Card mt={3} width={width}>
      <Box
        display="flex"
        p={2}
        pb={1.5}
        pt={1.5}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" justifyContent="start" mt="8px" mb="8px">
          <Typography variant="headline5" themedColor="textDefault">
            <strong>{t(chartObj.title)}</strong>
          </Typography>
        </Box>
        <NFBox
          display="flex"
          maxWidth="800px"
          mr="5px"
          alignItems="center"
          justifyContent="space-between"
          gap={15}
        >
          <CopyToClipboardButton disabled={!rows.length} onClick={handleCopyClipboard} text="" />
          <LabelerColumnFilterDropdown
            currentTab={currentTab}
            showAnnoTypeColumns={showAnnoTypeColumns}
            setshowAnnoTypeColumns={setshowAnnoTypeColumns}
            showObjectClassColumns={showObjectClassColumns}
            setShowObjectClassColumns={setShowObjectClassColumns}
          />
          <DownloadDropDown
            ml={0}
            iconButtonEl={
              <IconButton
                size="s"
                icon="download"
                color="primary"
                variant="strong-fill"
                disabled={rows.length === 0}
              />
            }
            handleDownload={handleDownload}
            options={['xlsx', 'csv']}
            isToggleDisabled={rows.length === 0}
          />
        </NFBox>
      </Box>
      <NFBox pr={2} pl={2} pb={2} pt={0} themedBackgroundColor={['grey', 50]} position="relative">
        {bottomHeader && (
          <LabelerTable
            tableRef={tableRef}
            bottomHeader={columns}
            rows={filterRows(rows as LabelerDatum[])}
            syncState={syncState}
            emptyMessage={emptyMessage}
            userProfiles={userProfiles}
            handleChangeRoles={handleChangeRoles}
            handleChangeTab={handleChangeTab}
            currentTab={currentTab}
          />
        )}
      </NFBox>
    </Card>
  );
};

export default LabelerContainer;
