import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useMultipleInputState } from '../../../hooks/useMultipleInputState';
import { useAuthService } from '../../../services/NewAuthService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import GoToPage from './GoToPage';
import { getAccountNameError, getEmailError } from './helper';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';
import ValidationInput, { blurAllInputs, useValidationInputRefs } from './ValidationInput';

export default function ForgotPasswordLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [info, setInfo] = useMultipleInputState({ accountName: '', email: '' });
  const refs = useValidationInputRefs(['accountName', 'email'] as const);
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { forgotPassword } = useAuthService();
  const { accountName, email } = info;

  useChangeContent('graphic');

  const handleClickSend = async (): Promise<void> => {
    if (isRequesting) return;

    try {
      blurAllInputs(refs);
      setIsRequesting(true);

      await forgotPassword({ email, tenantId: accountName });

      routeInfo.history.push(`/auth/change?tenantId=${btoa(accountName)}&email=${btoa(email)}`);
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          onClickMainButton: () => {
            closeModal();
          },
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  };

  const isValid = () => {
    if (getAccountNameError(t)(accountName)) {
      refs.current.accountName?.focusWithError();
      return false;
    } else if (getEmailError(t)(email)) {
      refs.current.email?.focusWithError();
      return false;
    }

    return true;
  };

  const onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (isValid()) {
      handleClickSend();
    }
  };

  return (
    <>
      <ShortCut page="signIn" />
      <Box as="form" onSubmit={onSubmit} width="100%" gap={2} display="flex" flexDirection="column">
        <Title>{t('auth.title.forgotPassword')}</Title>
        <Typography variant="l-regular">{t('auth.messages.forgotPasswordInstruction')}</Typography>
        <ValidationInput
          refs={refs}
          refKey="accountName"
          validation={getAccountNameError(t)}
          value={accountName}
          label={t('settings.profile.teamName')}
          onChange={e => setInfo('accountName', e)}
        />
        <ValidationInput
          refs={refs}
          refKey="email"
          validation={getEmailError(t)}
          value={email}
          label={t('settings.profile.email')}
          onChange={e => setInfo('email', e)}
        />
        <Button type="submit" size="l" color="primary" variant="strong-fill">
          {t('button.submit')}
        </Button>
        <Box width="100%" display="flex" justifyContent="center">
          <GoToPage to="login">{t('auth.goToSignIn')}</GoToPage>
        </Box>
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
