import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDiagnosisCommonFilterContext } from '../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisGridFilterContext } from '../../../../../contexts/DiagnosisGridFilterContext';
import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import SliceNameInput from '../../../../../elements/NameInput/SliceNameInput';
import {
  useUpdateSliceJobByEvalMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { transformFilterBody } from '../../../../../services/DiagnosisModelService';
import { getView } from '../../../../../utils/routeUtils';
import { CreateSliceRequestParams } from '../CreateSliceModal';
import SliceSummary from './SliceSummary';

export default function DiagnosisCreateSliceModal({
  createSliceModalIsOpen,
  setCreateSliceModalIsOpen,
}: {
  createSliceModalIsOpen: boolean;
  setCreateSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId, diagnosisId } = useParams<{
    accountName: string;
    datasetId: string;
    diagnosisId: string;
  }>();
  const { t } = useTranslation();
  const history = useHistory();
  const viewType = getView(history);
  const { createSlice } = useCurateDatasetService();
  const { selectedData, setSelectedData, setSelectedAllData } = useActionContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { splitIn, sliceId } = useDiagnosisCommonFilterContext();
  const { selectedDiagnosis, diagnosisCount } = useDiagnosisModelContext();

  const selectedImageIds = [
    ...new Set(
      selectedData.reduce((acc: string[] | [], data) => {
        if (!data.imageId) return acc;
        return [...acc, data.imageId];
      }, []),
    ),
  ];

  const { queryString } = useQueryContext();
  const commandContext = useCurateCommandContext();
  const { filterBody, totalEvaluationsCount } = useDiagnosisGridFilterContext();

  const { mutateAsync: startUpdateSliceJob, isLoading: isUpdatingSliceJob } =
    useUpdateSliceJobWithIdsMutation({
      datasetId,
      commandContext,
      selectedImageIds,
    });

  const { mutateAsync: startUpdateSliceJobByEval, isLoading: isUpdatingSliceByEvalJob } =
    useUpdateSliceJobByEvalMutation({
      datasetId,
      commandContext,
      diagnosisId,
    });
  const [isValidName, setIsValidName] = useState<boolean>(false);

  const [createSliceName, setCreateSliceName] = useState<string>('');
  const [createSliceDescription, setCreateSliceDescription] = useState<string>('');

  console.log(new Set(selectedData.map(data => data.imageId)).size);

  const trackDataSliceCreated = useCallback(
    (newSliceId: string, selectedCount: number) => {
      analyticsTracker.dataSliceCreated({
        accountId: accountName,
        datasetId,
        sliceId: newSliceId,
        selectedCount, // undefined if selected all
        viewType,
        dataType: 'image',
        referrer: 'model-diagnosis',
        filters: combineFiltersForTracking(transformFilterBody(filterBody), sliceId, splitIn),
      });
    },
    [accountName, datasetId, filterBody, sliceId, splitIn, viewType],
  );

  async function handleClickCreate({ name, description }: CreateSliceRequestParams) {
    const res = await createSlice({ name, description, datasetId });

    if (selectedData?.length > 0) {
      startUpdateSliceJob({ sliceId: res.id });
    } else {
      startUpdateSliceJobByEval({
        sliceId: res.id,
        sliceName: res.name,
        queryString,
        evaluationFilter: filterBody,
        splitIn,
      });
    }
    if (setSelectedAllData && setSelectedData) {
      setSelectedAllData(false);
      setSelectedData([]);
    }
    return res; // new slice id
  }

  const handleCreateSlice = async () => {
    const selectedCount = selectedData?.length;
    const newSlice = await handleClickCreate({
      name: createSliceName,
      description: createSliceDescription,
    });
    trackDataSliceCreated(newSlice.id, selectedCount);
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
  };

  const handleCloseButton = useCallback(() => {
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
  }, [setCreateSliceModalIsOpen, setCreateSliceName]);

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setCreateSliceDescription(e.target.value);
    },
    [setCreateSliceDescription],
  );

  // const imageToAddToSlice = () => {
  //   // TODO (P1): get image count from api
  //   // if (selectedAllData && evalSummary?.imageCount) return evalSummary.imageCount;
  //   if (selectedData) return new Set(selectedData.map(data => data.imageId)).size;
  //   return 0;
  // };

  return (
    <Modal
      open={createSliceModalIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.createSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleCreateSlice,
        color: 'primary',
        isLoading: isUpdatingSliceJob || isUpdatingSliceByEvalJob,
        disabled: !isValidName || createSliceName === '',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box mt={2} mx={4}>
        <SliceSummary
          selectedObjects={totalEvaluationsCount}
          // imageCount={imageToAddToSlice()}
          pt={2}
          px={2}
        />
      </Box>
      <Box py={2.5} px={4} style={{ minWidth: '520px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="black">
            {t('curate.slices.sliceName')}
          </Typography>
          <Typography variant="m-regular" color="black">
            {`${createSliceName?.length}/50`}
          </Typography>
        </div>
        <SliceNameInput
          placeholder={t('curate.dialogs.placeholder.name')}
          sliceName={createSliceName}
          setSliceName={setCreateSliceName}
          setIsValid={setIsValidName}
          datasetId={datasetId}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="black" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}{' '}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>
          <Typography variant="m-regular" color="black">
            {`${createSliceDescription?.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleDatasetDescription}
          value={createSliceDescription}
        ></StyledTextarea>
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
