/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import * as d3 from 'd3';
import { filter, initial, isUndefined, keys, map, max, size, upperFirst, values } from 'lodash';

import { JsonObj } from '../userStats/types';

const truncateLongText = (text: string, limit = 27): string => {
  return text.length > limit ? `${text.slice(0, limit - 1)}..` : text;
};

interface TooltipDivProps {
  xPos: number;
  yPos: number;
  height: number; // tooltip box height (TODO: deprecate)
  width?: number; // tooltip box default width (for workerStats chart)
  maxLength: number; // max string length to display (ex. object class or category)
  dataLength?: 1 | 2 | 3 | undefined; // num values (rows) to display in tooltip
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
}

export interface TooltipTemplateProps {
  chartName?: string;
  content: JsonObj;
  xPos: number;
  yPos: number;
  width?: number;
  height: number;
  handleClickInspect: any;
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
  checkedStatus?: string[];
  xKeyToDisplayName?: Record<string, string> | undefined;
}

const TooltipDiv: React.FC<TooltipDivProps> = props => {
  const {
    xPos,
    yPos,
    maxLength,
    dataLength,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    height,
    width,
  } = props;

  const getBoxWidth = (maxLength: number): number => {
    const minWidth = 190;
    const maxWidth = 480; // prevent excessive width
    const extraWidthPerChar = 3; // adjust for better scaling

    // Calculate the width based on text length
    const width = minWidth + (maxLength - 19) * extraWidthPerChar;

    // Ensure the width does not exceed the maximum width
    return Math.min(width, maxWidth);
  };

  const getBoxHeight = (numRows: 1 | 2 | 3): number => {
    return {
      1: 80, // category without groups
      2: 100,
      3: 100,
    }[numRows] as number;
  };
  const boxHeight = isUndefined(dataLength) ? height : getBoxHeight(dataLength) ?? 80;
  const boxWidth = width || getBoxWidth(maxLength);

  return (
    <rect
      width={`${boxWidth}px`}
      height={`${boxHeight}px`}
      style={{
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '5px',
      }}
      x={xPos}
      y={yPos}
      rx={2}
      ry={2}
      opacity="0.9"
      fill="#333333"
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
    />
  );
};

const getStyles = (style: 'bold' | 'link' | 'boldLink'): React.CSSProperties => {
  return {
    bold: { fontWeight: 'bold' },
    link: { cursor: 'pointer' },
    boldLink: { cursor: 'pointer', fontWeight: 'bold', color: 'cyan' },
  }[style];
};

const ObjectTemplate = (props: {
  content: JsonObj;
  xPos: number;
  yPos: number;
  handleClickInspect: any;
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
  height: number;
  xKeyToDisplayName?: Record<string, string>;
}) => {
  const {
    content,
    xPos,
    yPos,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    height,
    xKeyToDisplayName,
  } = props;

  const { t } = useTranslation();
  const BOLD = getStyles('bold');
  const LINK = getStyles('link');
  const newYPos = yPos + 15;

  const {
    xValue: [, xValue],
    yValue: [, yValue],
    xFilterValue: [, xFilterValue],
    supplementValue: [, suppValue],
  } = content;
  const dyLocations = [0, 17, 34, 51, 68];
  const clickParams = {
    xValue: xFilterValue,
    suppValue,
  };

  const xDisplayName = xKeyToDisplayName ? xKeyToDisplayName[xValue] : xValue;
  const nameLength = `Name: ${xDisplayName}`.length;

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };
  const maxTextLength = 40;
  const truncatedName = truncateText(xDisplayName, maxTextLength);

  return (
    <>
      <TooltipDiv
        xPos={xPos}
        yPos={yPos}
        maxLength={nameLength}
        height={height}
        handleTooltipMouseEnter={handleTooltipMouseEnter}
        handleTooltipMouseLeave={handleTooltipMouseLeave}
      />
      <text
        dominantBaseline="middle"
        fill="white"
        textAnchor="left"
        x={xPos + 5}
        y={yPos + 7}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[0]}>
          <tspan>{t('analytics.text.name')}: </tspan>
          <tspan style={BOLD}>{truncatedName}</tspan>
        </tspan>
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[1]}>
          <tspan>{t('analytics.text.count')}: </tspan>
          <tspan style={BOLD}> {d3.format(',')(yValue)} </tspan>
        </tspan>
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[2]}>
          <tspan>{t('analytics.text.share')}: </tspan>
          <tspan style={BOLD}> {`${suppValue}%`}</tspan>
        </tspan>
        <tspan
          x={xPos}
          y={yPos + 15}
          dx={15}
          dy={dyLocations[3]}
          onClick={() => handleClickInspect(clickParams)}
          fill="cyan"
          style={LINK}
        >
          {t('analytics.project.viewLabels')}
        </tspan>
      </text>
    </>
  );
};

const CategoryTemplate = (props: {
  content: JsonObj;
  xPos: number;
  yPos: number;
  handleClickInspect: any;
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
  height: number;
  xKeyToDisplayName?: Record<string, string>;
}) => {
  const {
    content,
    xPos,
    yPos,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    height,
    xKeyToDisplayName,
  } = props;
  const { t } = useTranslation();
  const BOLD = getStyles('bold');
  const LINK = getStyles('link');

  const newYPos = yPos + 15;
  const {
    xValue: [, xValue],
    yValue: [, yValue],
    supplementValue: [, suppValue],
    groups: [groupDisplayName, groupNamesById],
  } = content;
  const groupIds = keys(groupNamesById);

  // Make display string for image category group hierarchy
  // ex. Group: Hogwarts > Ravenclaw > Luna Lovegood
  const groupDisplayValue = initial(values(groupNamesById)).join(' / ');
  const groupDepth = size(groupNamesById);
  const xDisplayName = xKeyToDisplayName ? xKeyToDisplayName[xValue] : xValue;

  // has image category group; false implies only image category
  const hasGroup = groupDepth > 1;
  const dyLocations = hasGroup ? [0, 17, 34, 51, 68] : [0, 0, 17, 34, 51];
  const clickParams = { id: xValue, groups: groupIds };
  return (
    <>
      <TooltipDiv
        xPos={xPos}
        yPos={yPos}
        maxLength={max([groupDisplayValue.length, xDisplayName.length])}
        dataLength={groupDepth as 1 | 2 | 3}
        height={height}
        handleTooltipMouseEnter={handleTooltipMouseEnter}
        handleTooltipMouseLeave={handleTooltipMouseLeave}
      />
      <text
        dominantBaseline="middle"
        fill="white"
        textAnchor="left"
        x={xPos + 5}
        y={yPos + 7}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[0]}>
          <tspan>{t('analytics.text.name')}: </tspan>
          <tspan style={BOLD}>{upperFirst(xDisplayName)} </tspan>
        </tspan>
        {hasGroup ? (
          <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[1]}>
            <tspan>{groupDisplayName}: </tspan>
            <tspan style={BOLD}>{groupDisplayValue} </tspan>
          </tspan>
        ) : null}
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[2]}>
          <tspan>{t('analytics.text.count')}: </tspan>
          <tspan style={BOLD}> {d3.format(',')(yValue)} </tspan>
        </tspan>
        <tspan x={xPos} y={newYPos} dx={15} dy={dyLocations[3]}>
          <tspan>{t('analytics.text.share')}: </tspan>
          <tspan style={BOLD}> {`${suppValue}%`}</tspan>
        </tspan>
        <tspan
          x={xPos}
          y={yPos + 15}
          dx={15}
          dy={dyLocations[4]}
          onClick={() => handleClickInspect(clickParams)}
          fill="cyan"
          style={LINK}
        >
          {t('analytics.project.viewLabels')}
        </tspan>
      </text>
    </>
  );
};

// TODO: same as TooltipTemplateProps, but make checkedStatus required
const AssigneeTemplate = (props: TooltipTemplateProps) => {
  const {
    content,
    xPos,
    yPos,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    height,
    width,
    checkedStatus,
  } = props;
  const { t } = useTranslation();

  const { assignee, assignee_name, assignee_display_name } = content.data;

  const BOLD = getStyles('bold');
  const BOLD_LINK = getStyles('boldLink');
  const newYPos = yPos + 15;
  const displayOrder = ['cumSubmitted', 'cumWorking', 'cumSkipped'] as const;
  const filteredStatus = filter(displayOrder, d => !isUndefined(content.data[d]));
  const isLinkDisabled = checkedStatus?.length === 0;

  const getStatusRows = (
    statuses: ('cumSubmitted' | 'cumWorking' | 'cumSkipped')[],
  ): JSX.Element | JSX.Element[] => {
    const dyOptional = {
      0: [34],
      1: [34],
      2: [34, 51],
      3: [34, 51, 68],
    };
    const dy = dyOptional[statuses.length as 0 | 1 | 2 | 3];
    const getDisplayStatus = {
      cumSubmitted: t('labels.status.submitted'),
      cumWorking: t('labels.status.in_progress'),
      cumSkipped: t('labels.status.skipped'),
    };
    if (isLinkDisabled) {
      return (
        <tspan key="span-total" x={xPos} y={newYPos} dx={15} dy={dy[0]}>
          <tspan>{t('analytics.text.total')}: </tspan>
          <tspan style={BOLD}>0</tspan>
        </tspan>
      );
    }
    return map(filteredStatus, (statusKey, i) => {
      const statusFilterKey = {
        cumSubmitted: 'submitted',
        cumSkipped: 'skipped',
        cumWorking: 'in progress',
      }[statusKey];
      const status = getDisplayStatus[statusKey];
      const count = content.data[statusKey];
      return (
        <tspan key={`span-${status}`} x={xPos} y={newYPos} dx={15} dy={dy[i]}>
          <tspan>{status}: </tspan>
          <tspan
            fill="cyan"
            style={BOLD_LINK}
            onClick={() => handleClickInspect(assignee_name, assignee, statusFilterKey)}
          >
            {d3.format(',')(count)}{' '}
            {statusKey === 'cumSubmitted' ? `(${t('analytics.userReports.text.viewLabels')})` : ''}
          </tspan>
        </tspan>
      );
    });
  };

  const dy = [0, 17]; // default info for 0~3 status
  const emailRow = `Email: ${truncateLongText(assignee)}`;
  return (
    <>
      <TooltipDiv
        xPos={xPos}
        yPos={yPos}
        width={width}
        height={height}
        maxLength={emailRow.length}
        handleTooltipMouseEnter={handleTooltipMouseEnter}
        handleTooltipMouseLeave={handleTooltipMouseLeave}
      />
      <text
        dominantBaseline="middle"
        fill="white"
        textAnchor="left"
        x={xPos + 5}
        y={newYPos - 8}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <tspan x={xPos} y={newYPos} dx={15} dy={dy[0]}>
          <tspan>{t('analytics.text.name')}: </tspan>
          <tspan style={BOLD}>{truncateLongText(upperFirst(assignee_display_name))} </tspan>
        </tspan>
        <tspan x={xPos} y={newYPos} dx={15} dy={dy[1]}>
          <tspan>{t('analytics.text.email')}: </tspan>
          <Tooltip title={assignee}>
            <tspan style={BOLD}>{truncateLongText(assignee)}</tspan>
          </Tooltip>
        </tspan>
        {getStatusRows(filteredStatus)}
      </text>
    </>
  );
};

export const TooltipTemplate: React.FC<TooltipTemplateProps> = props => {
  const {
    chartName,
    content,
    xPos,
    yPos,
    width,
    height,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    checkedStatus, // only exists for workerStats
    xKeyToDisplayName,
  } = props;
  const templates = {
    workerStats: AssigneeTemplate,
    objectClassStats: ObjectTemplate,
    imageCategoryStats: CategoryTemplate,
  };

  const params: TooltipTemplateProps = {
    content,
    checkedStatus,
    xPos,
    yPos,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    height,
    width,
    xKeyToDisplayName,
  };
  return templates[chartName as 'workerStats' | 'objectClassStats' | 'imageCategoryStats'](params);
};
