import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Key, Visible, VisibleOff } from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../consts/SnackbarMessage';
import FileUtils from '../../../../utils/FileUtils';

interface Props {
  accessKey: string;
  width?: string;
}

export default function AccessKeyViewer({ accessKey }: Props) {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const hideValue = Array.from(Array(accessKey.length), () => '*').join('');
  const { enqueueSnackbar } = useSnackbar();

  const handleClickCopy = () => {
    FileUtils.copyToClipboard({ value: accessKey });
    enqueueSnackbar(COPY_SUCCESS({ t, label: 'access key' }), { variant: 'success' });
  };

  return (
    <Box display="flex" alignItems="center" width="100%" style={{ maxWidth: 460 }}>
      <Box
        display="flex"
        alignItems="center"
        px={1}
        backgroundColor="gray-100"
        borderRadius="2px"
        style={{ flex: 1 }}
      >
        <Icon icon={Key} size={16} color="gray-400" />
        <Box mx={1} style={{ flex: 1 }}>
          <Typography variant="m-regular" style={{ userSelect: 'none' }}>
            {isHidden ? hideValue : accessKey}
          </Typography>
        </Box>
        {isHidden ? (
          <IconButton
            icon={VisibleOff}
            variant="text"
            onClick={() => setIsHidden(false)}
            color="cloud"
          />
        ) : (
          <IconButton icon={Visible} variant="text" onClick={() => setIsHidden(true)} />
        )}
      </Box>
      <Button variant="text" color="primary" onClick={handleClickCopy}>
        {t('button.copy')}
      </Button>
    </Box>
  );
}
