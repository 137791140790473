import { Dispatch, SetStateAction, useMemo } from 'react';

import { Hide, Show } from '@superb-ai/icons';
import { Box, Icon, IconButton, Typography } from '@superb-ai/ui';

import { getAnnotationIcon } from '../../components/components';
import { AnnotationType } from '../../services/types';
import { AnnotationsInfo } from './SamplePredictionCabinet';

export default function AnnotationColumn({
  annotation,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  objectAnnotationId,
  clickedAnnotationId,
  hoveredAnnotationId,
  handleSelectImage,
  handleClickList,
  handleHoverList,
  handleLeaveList,
}: {
  annotation: AnnotationsInfo;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  objectAnnotationId?: string;
  clickedAnnotationId: string | undefined;
  hoveredAnnotationId: string | undefined;
  handleSelectImage: () => void;
  handleClickList: (id: string) => void;
  handleHoverList: (id: string) => void;
  handleLeaveList: () => void;
}) {
  const cabinetWidth = 288;

  const isFilteredAnnotationsId = useMemo(
    () => filteredAnnotationsIds.includes(annotation.id),
    [filteredAnnotationsIds, annotation],
  );

  const IncludeObjectAnnotatin = useMemo(
    () => annotation.id === objectAnnotationId,
    [annotation.id, objectAnnotationId],
  );

  const isClickedObjectAnnotation = useMemo(
    () => annotation.id === clickedAnnotationId,
    [annotation, clickedAnnotationId],
  );

  const isHoveredObjectAnnotation = useMemo(
    () => annotation.id === hoveredAnnotationId,
    [annotation, hoveredAnnotationId],
  );

  return (
    <Box
      backgroundColor={{
        default: isClickedObjectAnnotation || isHoveredObjectAnnotation ? 'primary-100' : 'white',
        hover: 'gray-100',
      }}
      bb="1px solid"
      borderColor="white"
      onClick={() => handleClickList(annotation.id)}
      onMouseEnter={() => {
        handleHoverList(annotation.id);
      }}
      onMouseLeave={handleLeaveList}
    >
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          pr={4}
          pl={4}
        >
          <Box
            style={{ width: cabinetWidth / 2 }}
            display="flex"
            alignItems="center"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            gap={1}
          >
            <Icon
              icon={getAnnotationIcon(annotation.annotation_type as AnnotationType)}
              color={
                isFilteredAnnotationsId
                  ? 'gray-300'
                  : !IncludeObjectAnnotatin
                  ? 'gray-400'
                  : 'primary-400'
              }
            />
            <Box display="flex">
              <Typography
                variant="m-medium"
                color={
                  isFilteredAnnotationsId
                    ? 'gray-300'
                    : !IncludeObjectAnnotatin
                    ? 'gray-400'
                    : 'primary-400'
                }
              >
                {annotation.annotation_class}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <IconButton
              variant={'text'}
              icon={isFilteredAnnotationsId ? Hide : Show}
              color={isFilteredAnnotationsId ? 'cloud' : 'gray'}
              onClick={e => {
                setFilteredAnnotationsIds(prev => {
                  if (prev.includes(annotation.id)) {
                    const newList = prev.filter(list => list !== annotation.id);
                    return [...newList];
                  } else return [...prev, annotation.id];
                });
                if (isClickedObjectAnnotation && !isFilteredAnnotationsId) handleSelectImage();
                e.stopPropagation();
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
