import * as d3Format from 'd3-format';

export const formatNumber = d3Format.format(',');

export function formatLargeNumber(number: number): string {
  if (number >= 1000) {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}k`;
    }
  }
  return number.toString();
}

export function roundPercent(percent: number, nearest: 'hundredth' | 'int' = 'int'): number {
  if (nearest === 'hundredth') return Math.round(100 * percent) / 100;
  if (percent >= 0 && percent < 1) {
    return Math.ceil(percent);
  } else if (percent >= 99 && percent < 100) {
    return Math.floor(percent);
  }
  return Math.round(percent);
}

/**
 *  Given a numerator and a denominator calculates percent total
 *
 *  If 0 <= ratio < 1, percent = 0
 *  If 99 < ratio, percent = 99
 *  Other we just return percent rounded to nearest integer or specified decimal
 **/
export function calculatePercent(params: {
  numerator: number;
  denominator: number;
  nearest?: 'hundredth' | 'int' | 'exact';
}) {
  const { numerator, denominator, nearest } = params;
  if (denominator == 0 || numerator == 0) return 0;
  if (nearest === 'exact') return (numerator / denominator) * 100;
  return roundPercent((numerator / denominator) * 100, nearest);
}
