import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ButtonGroup,
  Card,
  DropdownList,
  IconButton,
  Typography,
} from '@superb-ai/norwegian-forest';
import { isEmpty, isNull } from 'lodash';

import { DetailViewProvider } from '../../../../contexts/DetailViewContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useContainerSize } from '../../../../hooks/ContainerSizeHook';
import { formatDistanceShort } from '../../../../utils/date';
import FileUtils from '../../../../utils/FileUtils';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import DetailView from '../../../elements/detailView';
import { EMPTY_MESSAGE } from '../config/constants';
// Custom charts
import { AnalyticsSummaryType, FileFormat } from '../dataTypes/analyticsDataTypes';
import SyncButton from '../elements/buttons/SyncButton';
import EmptyPlot from '../elements/EmptyPlot';
import DownloadDropDown from '../tools/DownloadDropDown';
import { transformToAOA } from '../userStats/labelerTable/helper';
import { BaseDatum, JsonObj } from '../userStats/types';
import { getLabelingTimeAtPercentile } from '../utils/statsUtils';
import { displayDistanceTime, getDateDistance } from './helper';
import {
  downloadName,
  getColumnDisplayNames,
  getColumnDisplayOrder,
  preprocessLabelingTableData,
} from './labelingTime/helper';
import { StatsHistogramAPIResponse } from './labelingTime/interface';
import LabelingTimeTable from './labelingTime/LabelingTimeTable';

interface Props {
  setLabelingTimeThreshold: Dispatch<SetStateAction<number>>;
  labelingTimeThreshold?: string | undefined;
  chartObject: JsonObj;
  isLoading: boolean;
  labelCount: number;
  sourceData: StatsHistogramAPIResponse;
  tableData: JsonObj[];
  filter: Record<string, string[] | []>;
  height: number;
  syncTime: Date | string;
  refresh: (summaryType: AnalyticsSummaryType) => void;
}

/**
 * Project Analytics > Chart container
 */

const ChartContainer = (props: Props): React.ReactElement => {
  const projectInfo = useProjectInfo();
  const {
    setLabelingTimeThreshold,
    labelingTimeThreshold,
    sourceData,
    tableData,
    chartObject,
    isLoading,
    labelCount,
    height,
    syncTime,
    refresh,
  } = props;
  const {
    project: { labelInterface, workapp },
    userObjects,
  } = projectInfo;
  const projectName = projectInfo.project.name;
  const { t } = useTranslation();
  const activeUsers = userObjects;
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);

  const [rows, setRows] = useState<JsonObj[]>([]);
  const [dropdownValue, setDropdownValue] = useState<string>('p99');
  const [syncTimeDistance, setSyncTimeDistance] = useState<string>(
    getDateDistance(syncTime, syncTime),
  );
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const formatSyncTimeCallback = useCallback(() => {
    return formatDistanceShort(syncTime);
  }, [syncTime]);

  useEffect(() => {
    setSyncTimeDistance(formatSyncTimeCallback());
  }, [formatSyncTimeCallback]);

  useEffect(() => {
    const labelingTime = getLabelingTimeAtPercentile(sourceData, 'p99');
    if (!isNull(sourceData) && labelingTime) {
      setLabelingTimeThreshold(labelingTime);
    } else {
      if (!isEmpty(tableData)) setRows(preprocessLabelingTableData(tableData));
      else setRows([]);
    }
  }, [sourceData]);

  useEffect(() => {
    if (!isEmpty(tableData)) setRows(preprocessLabelingTableData(tableData));
    else setRows([]);
  }, [tableData]);

  useEffect(() => {
    setIsLoadingData(isLoading);
  }, [isLoading]);

  const getLabelingTimeAtPercentileCallback = useCallback(
    (percentile: string) => {
      return getLabelingTimeAtPercentile(sourceData, percentile);
    },
    [sourceData],
  );

  useEffect(() => {
    const timeout = setInterval(
      () => {
        setSyncTimeDistance(formatDistanceShort(syncTime));
      },
      60000,
      10,
    );
    return () => clearInterval(timeout);
  }, []);

  const dropdownOptions = () => {
    const percentiles = ['p995', 'p99', 'p95'];
    const displayValues = (value: string) => {
      return (
        {
          p995: t('analytics.project.topNPercent', { percent: '0.5' }),
          p99: t('analytics.project.topNPercent', { percent: '1' }),
          p95: t('analytics.project.topNPercent', { percent: '5' }),
        }[value] || value
      );
    };

    return percentiles.map(value => ({
      value: value,
      // label: `${displayValues(value)}: ${getPercentile(sourceData, value)}`,
      label: `${displayValues(value)}`,
    }));
  };

  const handleDropdownSelect = (percentile: string) => {
    setDropdownValue(percentile);
    percentile && setLabelingTimeThreshold(getLabelingTimeAtPercentileCallback(percentile));
  };

  const handleDownload = (outputType: FileFormat) => {
    const filename = downloadName(projectName);
    if (outputType === 'csv') {
      const header = getColumnDisplayOrder(labelInterface, workapp);
      FileUtils.exportToCsv(rows, header, filename);
    } else if (outputType === 'xlsx') {
      if (isEmpty(rows)) return;
      const columnOrder = getColumnDisplayOrder(labelInterface, workapp);
      const columnIdToName = getColumnDisplayNames(labelInterface, workapp, t);
      const transformed = transformToAOA(rows, columnOrder, columnIdToName);

      FileUtils.exportToExcel(transformed, 'array', 'labels', filename);
    }
  };

  /**
   * Given chartType, renders the selected chart Component.
   */
  const makeChart = (
    data: BaseDatum[],
    activeUsers: Record<string, string>,
  ): React.ReactElement => {
    const columns = !isEmpty(data) ? Object.keys(data[0]) : [];

    return (
      <LabelingTimeTable
        cells={columns}
        rows={data}
        users={activeUsers}
        labelInterface={labelInterface}
        workapp={workapp}
        projectLabelCount={labelCount}
      />
    );
  };

  const hasZeroLabels = labelCount === 0 || rows.length === 0;

  /*
   * UI: [Total Button] [Today Button] [SortDropdown] [DonutIcon] [BarIcon] [Download]
   */
  const getRightButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <ButtonGroup gap={1}>
        <DropdownList
          placeholder={t('analytics.project.selectOutlierPercentile')}
          value={dropdownValue}
          options={dropdownOptions()}
          onChange={handleDropdownSelect}
          width={100}
          disabled={hasZeroLabels}
        />
        <DownloadDropDown
          key={`${chartObject.chartName}-${isLoading}`}
          ml={0}
          iconButtonEl={
            <IconButton
              size="s"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={rows.length === 0}
            />
          }
          handleDownload={handleDownload}
          options={chartObject.downloadOptions}
          isToggleDisabled={rows.length === 0}
        />
      </ButtonGroup>
    );
  };

  const refreshData = async () => {
    setIsLoadingData(true);
    await refresh('labeling-time-stat');
    setIsLoadingData(false);
  };

  const getRefreshButton = () => {
    return <SyncButton isLoading={isLoadingData} refreshFn={refreshData} />;
  };

  const dataNotLabeled = Boolean(!labelingTimeThreshold && labelCount);

  return (
    <DetailViewProvider>
      <Card pt={2} mb={1.5} ref={chartContainerRef}>
        <Box
          display="flex"
          pl={2}
          pr={2}
          pb={1.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" alignItems="left" justifyContent="start">
            <Box display="flex" alignItems="center" justifyContent="start">
              <Typography variant="headline5" themedColor="textDefault">
                <strong>{t(chartObject.title)}</strong>
              </Typography>
              {getRefreshButton()}
            </Box>
            <Typography themedColor={['grey', 300]} variant="label">
              {displayDistanceTime(isLoadingData, syncTimeDistance, dataNotLabeled)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box height="28px" mr="5px" display="flex">
              {getRightButtonGroup(hasZeroLabels)}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          themedBackgroundColor={['grey', 50]}
          pt={1}
          justifyContent="center"
          position="relative"
          width={width}
          minHeight={'100px'}
        >
          {isLoadingData ? (
            dataNotLabeled ? (
              <EmptyPlot message={t(EMPTY_MESSAGE.LABEL_DATA)} />
            ) : (
              <CircularProgressBox
                key={chartObject.chartName}
                boxProps={{ mt: 5, mb: 5, width: '100%', height: height - 100 }}
              />
            )
          ) : (
            <Box
              className={`chart-display-area-${chartObject.chartName}`}
              pr={0}
              pl={0}
              pb={2}
              pt={0}
              id={`chart-box-${chartObject.chartName}`}
              themedBackgroundColor={['grey', 50]}
              width="100%"
            >
              {makeChart(rows, activeUsers)}
            </Box>
          )}
        </Box>
      </Card>
      <DetailView />
    </DetailViewProvider>
  );
};

export default ChartContainer;
