import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Typography } from '@superb-ai/norwegian-forest';
import Image from 'next/image';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { getUrl } from '../../../../routes/util';
import Howto from '../../aiAdvancedFeatures/cards/Howto';
import Step from '../../aiAdvancedFeatures/cards/Step';
import TutorialCard from '../../aiAdvancedFeatures/cards/TutorialCard';
import tutorialImage from './tutorial.png';

export default function Tutorial({
  hasMoreThanTwoExports,
  openSettingsModal,
}: {
  hasMoreThanTwoExports: boolean;
  openSettingsModal: () => void;
}) {
  const { accountName } = useParams<{ accountName: string }>();
  const projectInfo = useProjectInfo();
  const history = useHistory();
  const { t } = useTranslation();

  function goTo(path: string) {
    const url = getUrl([accountName, 'project', projectInfo.project.id, path]);
    history.push(url);
  }
  return (
    <TutorialCard
      title={
        <Typography variant="body3" style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          {t('analytics.exportsAnalytics.guide.description')}
        </Typography>
      }
      image={
        <Image
          src={tutorialImage}
          alt="Illustration pointing out bar chart with finger and stick"
        />
      }
    >
      <Howto title={t('analytics.exportsAnalytics.guide.title')}>
        <Step
          number={1}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'analytics.exportsAnalytics.guide.items.0'} />
            </Typography>
          }
          completed={hasMoreThanTwoExports}
          onClick={() => goTo('labels')}
        />
        <Step
          number={2}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'analytics.exportsAnalytics.guide.items.1'} />
            </Typography>
          }
          onClick={openSettingsModal}
          style={{ cursor: hasMoreThanTwoExports ? 'pointer' : 'not-allowed' }}
        />
        <Step
          number={3}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'analytics.exportsAnalytics.guide.items.2'} />
            </Typography>
          }
          style={{ cursor: hasMoreThanTwoExports ? 'pointer' : 'not-allowed' }}
        />
      </Howto>
    </TutorialCard>
  );
}
