import React, { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../../contexts/RouteContext';
import ProjectService from '../../../../../../services/ProjectService';
import { ProjectData } from '../../../../../../types/projectTypes';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  labelProject: ProjectData;
  refetchLabelProjects: () => void;
};

export default function SyncedSettingDialog({ state, labelProject, refetchLabelProjects }: Props) {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  async function handleChangeSyncedSetting() {
    try {
      const { id } = await ProjectService.updateProject({
        projectId: labelProject.id,
        newInfo: {
          curateSyncSettings: {
            isActive: !labelProject.curateSyncSettings?.isActive,
          },
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      await refetchLabelProjects();
      state.hide();
    } catch (e) {}
  }

  return (
    <Dialog
      state={state}
      aria-label="Sync with Curate dialog"
      hideOnClickOutside={false}
      style={{ width: '400px' }}
    >
      <Dialog.Header>
        {labelProject.curateSyncSettings?.isActive
          ? t('curate.dialogs.turnOffSync')
          : t('curate.dialogs.turnOnSync')}
      </Dialog.Header>
      <Dialog.Content>
        <Typography variant="m-regular">
          {labelProject.curateSyncSettings?.isActive ? (
            <Trans
              t={t}
              i18nKey="curate.dialogs.turnOffSyncDescription"
              values={{ projectName: labelProject.name }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="curate.dialogs.turnOnSyncDescription"
              values={{ projectName: labelProject.name }}
            />
          )}
        </Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void state.hide()}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={handleChangeSyncedSetting}>
          {labelProject.curateSyncSettings?.isActive
            ? t('curate.button.turnOff')
            : t('curate.button.turnOn')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
