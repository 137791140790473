import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Box, FormattedNumber } from '@superb-ai/norwegian-forest';
import { concat, filter, includes, isEmpty, map, pickBy } from 'lodash';

import { useAssetsInfo } from '../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUsersInfo } from '../../../contexts/UsersContext';
import ProjectsService from '../../../services/ProjectsService';
import ParamUtils from '../../../utils/ParamUtils';
import { toastEventManager } from '../../../utils/ToastUtils';
import AssignToProjectDialog from '../../pages/data/AssignToProjectDialog';
import DatasetTableView from '../../pages/data/datasetList/DatasetTableView';
import { AssignDialogContext } from './Context';
import DialogPagination from './DialogPagination';

type Props = {
  checkedDatasets: string[];
  setCheckedDatasets: Dispatch<SetStateAction<string[]>>;
  isAllDatasetsChecked: boolean;
  setIsAllDatasetsChecked: Dispatch<SetStateAction<boolean>>;
  params: Record<string, any>;
  setParams: Dispatch<SetStateAction<Record<string, any>>>;
};
const DatasetContainer: React.FC<Props> = ({
  checkedDatasets,
  setCheckedDatasets,
  isAllDatasetsChecked,
  setIsAllDatasetsChecked,
  params,
  setParams,
}) => {
  const { t } = useTranslation();
  const assetsInfo = useAssetsInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const usersInfo = useUsersInfo();
  const [isLoadingDatasets, setIsLoadingDatasets] = useState(false);
  const [isAssignToProjectDialogOpen, setIsAssignToProjectDialogOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('group');
  const [isDesc, setIsDesc] = useState(false);
  const { setSelectedDataset } = React.useContext(AssignDialogContext);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const load = async () => {
    if (!assetsInfo) return;
    const sortParams = pickBy(
      { ordering: !isDesc ? orderBy : '-'.concat(orderBy) },
      v => !isEmpty(v),
    );
    const otherParams = ParamUtils.getApiParamsForDataFilter(routeInfo.params);
    otherParams.page = currentPage.toString();
    const formattedParams = { ...sortParams, ...otherParams };
    setParams(formattedParams);

    setIsLoadingDatasets(true);
    try {
      await assetsInfo.updateDatasets(formattedParams);
    } finally {
      setIsLoadingDatasets(false);
    }
  };

  useEffect(() => {
    setCheckedDatasets([]);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeInfo.params, orderBy, isDesc, currentPage]);

  const reloadAfterDelete = useCallback(toast => {
    if (toast?.options?.state === 'endProgress') {
      setCheckedDatasets([]);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toastEventManager.on('CHANGE_STATE', reloadAfterDelete);
    return () => {
      toastEventManager.off('CHANGE_STATE', reloadAfterDelete);
    };
  }, [reloadAfterDelete]);

  const handleClickGlobalCheckbox = () => {
    if (isAllDatasetsChecked) {
      setIsAllDatasetsChecked(false);
      setCheckedDatasets([]);
      return;
    }

    if (checkedDatasets.length === (assetsInfo.datasets.length as unknown as number)) {
      setCheckedDatasets([]);
      return;
    }
    const group: any = {};
    setCheckedDatasets(map(assetsInfo.datasets, dataset => dataset.group));
  };

  const handleClickCheckbox = (event: React.MouseEvent, groupName: string) => {
    event.stopPropagation();
    if (includes(checkedDatasets, groupName)) {
      if (isAllDatasetsChecked) {
        setIsAllDatasetsChecked(false);
      }
      setCheckedDatasets(filter(checkedDatasets, checkedItem => checkedItem !== groupName));
      return;
    }
    setCheckedDatasets(concat(checkedDatasets, [groupName]));
  };

  const handleClickSelectAllDatasets = (isAllDatasetsChecked: boolean) => () => {
    setIsAllDatasetsChecked(isAllDatasetsChecked);
    if (!isAllDatasetsChecked) {
      setCheckedDatasets([]);
    }
  };

  const handleClickDataset = (id: string) => {
    setSelectedDataset(id);
  };

  const handleRequestSort = (property: string, order: 'asc' | 'desc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const handleCloseAssignToProjectDialog = () => {
    setIsAssignToProjectDialogOpen(false);
  };

  const handleSearchProject = async (inputValue: string) => {
    const projectList = await ProjectsService.getProjects({
      params: {
        nameIcontains: inputValue,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const searchProjectIds = {} as Record<string, string>;
    const resultProjects = map(projectList.results, project => {
      searchProjectIds[project.name] = project.id;
      return { label: project.name, value: project.name };
    });

    return resultProjects;
  };

  return (
    <>
      <MUI.Grid container direction="column" spacing={2}>
        <MUI.Grid item xs={12}>
          <MUI.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="50px"
          >
            <MUI.Typography variant="subtitle1" color="textPrimary">
              Total{' '}
              <FormattedNumber
                value={assetsInfo.totalDatasetCount}
                unit="items"
                unitSingular="item"
              />
            </MUI.Typography>
          </MUI.Box>

          <DatasetTableView
            onClickDataset={handleClickDataset}
            onClickCheckbox={handleClickCheckbox}
            onClickGlobalCheckbox={handleClickGlobalCheckbox}
            onClickSelectAllAssets={handleClickSelectAllDatasets}
            checkedDatasets={checkedDatasets}
            isAllDatasetsChecked={isAllDatasetsChecked}
            isLoadingDatasets={isLoadingDatasets}
            setIsLoadingDatasets={setIsLoadingDatasets}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            variant="modal"
          />
        </MUI.Grid>

        <MUI.Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DialogPagination
              totalCount={assetsInfo.totalDatasetCount}
              {...{ currentPage, setCurrentPage }}
            />
          </Box>
        </MUI.Grid>

        <AssignToProjectDialog
          isAllChecked={isAllDatasetsChecked}
          variant="dataset"
          checkedList={checkedDatasets}
          isOpen={isAssignToProjectDialogOpen}
          onClose={handleCloseAssignToProjectDialog}
          params={params}
          totalCount={assetsInfo.totalDatasetCount}
          handleSearch={handleSearchProject}
        />
      </MUI.Grid>
    </>
  );
};

export default DatasetContainer;
