import { useTranslation } from 'react-i18next';

import {
  CheckFilled,
  ErrorFilled,
  Exit,
  LoadingSpinnerSmall,
  MinusCircleFilled,
  Model,
} from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { formatDistance } from '../../../../../../../../utils/date';
import { DiagnosisDetail } from '../../../../../../services/DiagnosisModelService';
import { isDiagnosisInprogress } from '../types';

export function ModelSelectOption({
  diagnosis,
  isSelected,
  disabled,
  onSelect,
}: {
  diagnosis: DiagnosisDetail;
  isSelected: boolean;
  disabled?: boolean;
  onSelect: (newDiagnosisId: string) => void;
}) {
  const { t } = useTranslation();

  function SourceChip() {
    return (
      <Box
        p={0.5}
        backgroundColor="gray-150"
        color="gray-300"
        borderRadius="2px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon icon={diagnosis.modelSource === 'superb' ? Model : Exit} />
      </Box>
    );
  }

  const StatusIcon = {
    ALL_ACTIVE: <Icon icon={CheckFilled} color="green" />,
    VAL_ACTIVE: <Icon icon={CheckFilled} color="green" />,
    ACTIVE: <Icon icon={CheckFilled} color="green" />,
    INACTIVE: <Icon icon={MinusCircleFilled} color="gray-300" />,
    FAILED: <Icon icon={ErrorFilled} color="red" />,
    ACTIVATING: <Icon icon={LoadingSpinnerSmall} color="yellow" />,
    UPDATING: <Icon icon={LoadingSpinnerSmall} color="yellow" />,
    DEACTIVATING: <Icon icon={LoadingSpinnerSmall} color="yellow" />,
  }[diagnosis.status];

  function ModelInfo() {
    return (
      <Typography
        variant="m-regular"
        color={isDiagnosisInprogress(diagnosis.status) ? 'gray-400' : 'gray-300'}
        ml="auto"
        display="flex"
        alignItems="center"
        gap={1}
      >
        {isDiagnosisInprogress(diagnosis.status)
          ? t(`curate.diagnosis.diagnosisList.status.${diagnosis.status.toLowerCase()}`)
          : formatDistance(diagnosis.updatedAt)}
        {StatusIcon}
      </Typography>
    );
  }

  return (
    <Box
      role="option"
      style={{ ...(disabled ? { opacity: 0.4 } : {}) }}
      display="flex"
      alignItems="center"
      gap={1}
      py={0.5}
      px={1}
      aria-selected={isSelected}
      aria-disabled={disabled}
      tabIndex={0}
      backgroundColor={{
        default: isSelected ? 'gray-100' : undefined,
        hover: 'gray-100',
      }}
      cursor={!disabled ? 'pointer' : undefined}
      onClick={() => !disabled && onSelect(diagnosis.id)}
      onKeyPress={() => !disabled && onSelect(diagnosis.id)}
    >
      {SourceChip()}
      <Typography variant="m-regular">
        {diagnosis.modelName || t('curate.diagnosis.text.modelSourceTag.deleted')}
      </Typography>
      {ModelInfo()}
    </Box>
  );
}
