import { MutableRefObject, useEffect, useState } from 'react';

export const useContainerSize = <T extends HTMLElement>(
  myRef: MutableRefObject<T | null>,
): { width: number; height: number; top: number } => {
  const [size, setSize] = useState({ width: 0, height: 0, top: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current ? myRef.current.offsetWidth : -1,
      height: myRef.current ? myRef.current.offsetHeight : -1,
      top: myRef.current ? myRef.current.offsetTop : -1,
    });

    const handleResize = () => {
      setSize(getDimensions());
    };

    // @ts-ignore: missing definition https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/908
    const observer = new ResizeObserver(handleResize);

    if (myRef.current) {
      setSize(getDimensions());
      observer.observe(myRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [myRef.current]);

  return size;
};
