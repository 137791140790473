import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { SingleValue } from 'react-select';

import { Box, Modal } from '@superb-ai/norwegian-forest';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { Option } from '../../../../../../../types/selectTypes';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useDiagnosisCommonFilterContext } from '../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisGridFilterContext } from '../../../../../contexts/DiagnosisGridFilterContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import {
  useUpdateSliceJobByEvalMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { transformFilterBody } from '../../../../../services/DiagnosisModelService';
import { getView } from '../../../../../utils/routeUtils';
import DiagnosisAddSliceModalSelectSlice from './DiagnosisAddSliceModalSelectSlice';
import SliceSummary from './SliceSummary';

export default function DiagnosisAddSliceModal({
  addSliceModalIsOpen,
  setAddSliceModalIsOpen,
}: {
  addSliceModalIsOpen: boolean;
  setAddSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { datasetId, diagnosisId, accountName } = useParams<{
    accountName: string;
    datasetId: string;
    diagnosisId: string;
  }>();
  const { t } = useTranslation();
  const { queryString } = useQueryContext();
  const { selectedData, setSelectedData, setSelectedAllData } = useActionContext();

  const [selectedSlice, setSelectedSlice] = useState<SingleValue<Option>>();
  const [isLoading, setIsLoading] = useState(false);

  const { getDataset } = useCurateDatasetService();
  const { splitIn, sliceId } = useDiagnosisCommonFilterContext();
  const { setDatasetInfo } = useDatasetContext();
  const { filterBody, totalEvaluationsCount } = useDiagnosisGridFilterContext();
  const commandContext = useCurateCommandContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const history = useHistory();
  const viewType = getView(history);

  const trackDataSliceUpdated = useCallback(
    (selectedSliceId: string, dataCount?: number, selectedCount?: number) => {
      analyticsTracker.dataSliceCreated({
        datasetId,
        accountId: accountName,
        sliceId: selectedSliceId,
        viewType: viewType,
        dataType: 'image',
        dataCount: dataCount,
        selectedCount: selectedCount,
        referrer: 'model-diagnosis',
        filters: combineFiltersForTracking(transformFilterBody(filterBody), sliceId, splitIn),
      });
    },
    [accountName, datasetId, filterBody, sliceId, splitIn, viewType],
  );

  const selectedImageIds = [
    ...new Set(
      selectedData.reduce((acc: string[] | [], data) => {
        if (!data.imageId) return acc;
        return [...acc, data.imageId];
      }, []),
    ),
  ];

  const { mutateAsync: startUpdateSliceJob, isLoading: isUpdatingSliceJob } =
    useUpdateSliceJobWithIdsMutation({
      datasetId,
      commandContext,
      selectedImageIds,
    });

  const { mutateAsync: startUpdateSliceJobByEval, isLoading: isUpdatingSliceByEvalJob } =
    useUpdateSliceJobByEvalMutation({
      datasetId,
      commandContext,
      diagnosisId,
    });

  const handleAddSlice = async () => {
    setIsLoading(true);
    if (!selectedSlice) return;
    try {
      if (!selectedData?.length) {
        startUpdateSliceJobByEval({
          sliceId: selectedSlice.value,
          sliceName: selectedSlice.label,
          queryString,
          evaluationFilter: filterBody,
          splitIn,
        });
        trackDataSliceUpdated(selectedSlice.value, undefined, selectedData?.length);
      } else {
        startUpdateSliceJob({ sliceId: selectedSlice.value });
        trackDataSliceUpdated(selectedSlice.value, selectedImageIds.length);
      }
      const datasetInfo = await getDataset({
        datasetId,
        fromPublicDatasets: showPublicDatasets,
        expand: ['image_count', 'slice_count'],
      });
      setDatasetInfo(datasetInfo);
      if (setSelectedAllData && setSelectedData) {
        setSelectedAllData(false);
        setSelectedData([]);
      }
    } finally {
      setAddSliceModalIsOpen(false);

      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={addSliceModalIsOpen}
      close={{
        onClose: () => {
          setAddSliceModalIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.AddToExistingSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleAddSlice,
        color: 'primary',
        isLoading,
        disabled: !selectedSlice,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setAddSliceModalIsOpen(false);
        },
      }}
    >
      <Box px={4} py={2.5}>
        <SliceSummary
          selectedObjects={totalEvaluationsCount}
          // imageCount={imageToAddToSlice()}
          pt={2}
          px={2}
        />
        <Box mt={2}>
          <DiagnosisAddSliceModalSelectSlice
            selectedSlice={selectedSlice}
            setSelectedSlice={setSelectedSlice}
          />
        </Box>
      </Box>
    </Modal>
  );
}
