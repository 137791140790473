import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { chunk, initial, map, slice } from 'lodash';

import { LabelTag } from '../../../../types/labelTypes';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';

const useStyles = makeStyles(theme => ({
  chip: {
    height: '20px',
    fontSize: '11px',
    borderRadius: '5px',
    width: '100%',
    backgroundColor: '#F5F5F5',
    '& .MuiChip-label': {
      width: '100%',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  },
  more: {
    fontSize: '11px !important',
    color: theme.palette.grey[900],
  },
  tooltip: {
    fontSize: '11px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, .58)',
    padding: '4px 8px',
    borderRadius: '4px',
    textAlign: 'center',
    marginTop: '3px',
    lineHeight: 1.5,
  },
}));

interface Props {
  column: number;
  row: number;
  tags: LabelTag[];
}

const Tags: React.FC<Props> = ({ column, row, tags }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseOver = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const numLimitedTags = slice(tags, 0, column * row);

  if (!tags) return <></>;

  if (tags.length <= column * row) {
    return (
      <>
        {map(chunk(numLimitedTags, column), chunkedTags => (
          <MUI.Grid container spacing={1} key={chunkedTags[0].id}>
            {map(chunkedTags, tag => (
              <MUI.Grid item xs={(12 / column) as any} key={tag.id}>
                <TextEllipsisTooltip text={tag.name}>
                  <MUI.Chip
                    label={tag.name}
                    className={classes.chip}
                    style={{ backgroundColor: tag.info && tag.info.color }}
                  />
                </TextEllipsisTooltip>
              </MUI.Grid>
            ))}
          </MUI.Grid>
        ))}
      </>
    );
  }

  const moreTagsCount = tags.length - column * row + 1;
  return (
    <>
      {map(chunk(initial(numLimitedTags), column), (chunkedTags, index) => (
        <MUI.Grid container spacing={1} key={chunkedTags[0].id}>
          {map(chunkedTags, tag => (
            <MUI.Grid item xs={(12 / column) as any} key={tag.id}>
              <TextEllipsisTooltip text={tag.name}>
                <MUI.Chip
                  label={tag.name}
                  className={classes.chip}
                  style={{ backgroundColor: tag.info && tag.info.color }}
                />
              </TextEllipsisTooltip>
            </MUI.Grid>
          ))}
          {index === row - 1 && (
            <MUI.Grid item xs={(12 / column) as any}>
              <MUI.Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              >
                <MUI.Typography className={classes.more}>
                  {t('labels.editTag.more', { tagCount: moreTagsCount })}
                </MUI.Typography>
                {/* @ts-ignore: saa-680 */}
                <MUI.Popper transition open={open} anchorEl={anchorEl}>
                  {({ TransitionProps }) => (
                    <MUI.Fade {...TransitionProps}>
                      <MUI.Box className={classes.tooltip}>
                        {map(slice(tags, column * row - 1), tag => (
                          <div key={tag.id}>{tag.name}</div>
                        ))}
                      </MUI.Box>
                    </MUI.Fade>
                  )}
                </MUI.Popper>
              </MUI.Box>
            </MUI.Grid>
          )}
        </MUI.Grid>
      ))}
    </>
  );
};

export default Tags;
