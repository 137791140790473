import React, { Dispatch, SetStateAction } from 'react';

import { MemberData } from '../../../../types/memberTypes';
import { MislabelDetectionAction } from '../../../../types/mislabelDetectionTypes';
import { CellComponentProps } from '../../../elements/windowedImageGrid/types';
import ResultImage from './ResultImage';

type ExtraProps = {
  setSelectedItemIndex: Dispatch<SetStateAction<number>>;
  addIssueList: Record<string, MislabelDetectionAction>;
  setAddIssueList: Dispatch<SetStateAction<Record<string, MislabelDetectionAction>>>;
  isItemLoaded: boolean;
  editHistoriesPerItem: Record<
    number,
    MislabelDetectionAction & { created_at: Date; created_by: Date }
  >;
  editors: MemberData[];
  classInfo: { class_name: string; class_id: string };
};

export default function ResultPerClassCell({
  columnIndex,
  rowIndex,
  classInfo,
  results,
  setSelectedItemIndex,
  addIssueList,
  setAddIssueList,
  columns,
  editHistoriesPerItem,
  editors,
}: CellComponentProps<ExtraProps>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const imageInfo = results[currentIndex];

  return (
    <ResultImage
      classInfo={classInfo}
      imageInfo={imageInfo}
      onClickImage={imageUrl => {
        setSelectedItemIndex(currentIndex);
      }}
      addIssueList={addIssueList}
      setAddIssueList={setAddIssueList}
      hasHoverEffect
      // @ts-ignore
      history={editHistoriesPerItem && imageInfo && editHistoriesPerItem[imageInfo.mislabel_rank]}
      editors={editors}
    />
  );
}
