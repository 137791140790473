import {
  Axis,
  BarSeries,
  Chart,
  Position,
  ScaleType,
  SeriesIdentifier,
  Settings,
  Tooltip,
  TooltipAction,
} from '@elastic/charts';
import { Box } from '@superb-ai/ui';
import { truncate } from 'lodash';

import { getCustomTheme, xAxisTickLabel } from './customTheme';
import { ChartDatum } from './types';

export function BarChart({
  data,
  color,
  xAxis,
  yAxis,
  getTooltipActions,
  onElementListeners,
  chartWidth,
}: {
  data: ChartDatum[];
  color: string;
  xAxis?: { label: string };
  yAxis: { label: string };
  getTooltipActions?: () => TooltipAction<ChartDatum, SeriesIdentifier>[];
  onElementListeners?: any;
  chartWidth?: number;
}) {
  const adjustWidth = () => (data.length <= 15 ? chartWidth : data.length * 15);
  return (
    <Box>
      <Chart size={{ width: chartWidth ? `${adjustWidth()}px` : '100%', height: '100%' }}>
        <Settings theme={getCustomTheme(color)} {...onElementListeners} />
        <Tooltip
          type="cross"
          showNullValues
          headerFormatter={() => 'Image Count'}
          {...(getTooltipActions && {
            selectionPrompt: 'Right click to pin tooltip',
            actionPrompt: 'Right click to pin tooltip',
            actions: getTooltipActions(),
          })}
        />
        <Axis
          id="bottom"
          position={Position.Bottom}
          style={{
            tickLabel: xAxisTickLabel({ dataCount: data.length }),
          }}
          showOverlappingTicks
          title={xAxis?.label ?? ''}
          tickFormat={d => truncate(d, { length: 20 })}
        />
        <Axis id="left" title={yAxis.label} position={Position.Left} />
        <BarSeries
          id="bar"
          xScaleType={ScaleType.Ordinal}
          yScaleType={ScaleType.Linear}
          xAccessor="key"
          yAccessors={['count']}
          data={data}
          color={color}
          displayValueSettings={{ showValueLabel: true }}
        />
      </Chart>
    </Box>
  );
}
