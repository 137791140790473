import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaretRight } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import AnnotatedImage, { Annotation } from '../../components/AnnotatedImage';
import { useModelTrainingSamplePredictionsQuery } from '../../queries/modelQueries';
import { annotationTypeResolver, classNameResolver } from '../../recognition-ai/detail/utils';
import { isGenerationModelAITraining } from '../../services/modelTrainingTypeGuards';
import {
  GenerationAIModelDetailTraining,
  ModelStatus,
  SamplePredictionData,
} from '../../services/types';
import { randomColor } from '../../utils/colorUtils';
import { ITERATION_LENGTH } from '../constants';

const imageSideLength = 132;

export const SamplePredictionImages = ({
  modelTraining,
  modelStatus,
  iterationIndex,
}: {
  modelTraining: GenerationAIModelDetailTraining;
  modelStatus: ModelStatus;
  iterationIndex: number;
}) => {
  const { t } = useTranslation();
  const [classNameToColorMap, setClassNameToColorMap] = useState({});

  const { data: referenceAnnotation } = useModelTrainingSamplePredictionsQuery({
    url: modelTraining.modelTrainingReferenceImages.annotations,
    status: modelStatus,
    enabled: Boolean(modelTraining.modelTrainingReferenceImages.annotations),
  });

  const { data: iterationAnnotation } = useModelTrainingSamplePredictionsQuery({
    url: modelTraining.modelTrainingIterations[iterationIndex].annotations,
    status: modelStatus,
    enabled: Boolean(modelTraining.modelTrainingIterations[iterationIndex].annotations),
  });

  const getColorForClassName = (className: string) => {
    if (classNameToColorMap[className]) {
      return classNameToColorMap[className];
    }

    const newColor = randomColor();
    setClassNameToColorMap(prevMap => ({
      ...prevMap,
      [className]: newColor.hex,
    }));

    return newColor;
  };

  const annotations = (annotations: SamplePredictionData, fileName: string): Annotation[] => {
    if (!annotations) return [];

    const imageId = annotations.images.find(img => img.file_name === fileName).id;
    if (!imageId) return [];
    return annotations.annotations
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, annotations.categories);
        const className = classNameResolver(anno, annotations.categories);

        return {
          id: String(anno.id),
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : anno.segmentation,
          roi: { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] },
          type: annotationType,
          color: getColorForClassName(className),
          class: className,
        } as Annotation;
      });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      backgroundColor="gray-100"
      overflow="auto"
      p={2}
    >
      <Box
        display="grid"
        style={{
          gridTemplateColumns: 'minmax(132px, 1fr) 4fr',
          columnGap: 24,
        }}
      >
        <Typography variant="m-medium">{t('model.generativeAi.inputImage')}</Typography>
        <Typography variant="m-medium">{t('model.generativeAi.output')}</Typography>
      </Box>
      {referenceAnnotation &&
        modelTraining.modelTrainingReferenceImages.images.map((refImage, refIdx) => {
          const { width, height } = referenceAnnotation.images.find(
            img => img.file_name === refImage.fileName,
          );
          return (
            <Box
              key={refImage.fileName}
              display="grid"
              style={{ gridTemplateColumns: '1fr 24px 1fr 1fr 1fr 1fr' }}
            >
              <Row
                justifyContent="center"
                backgroundColor={'gray-200'}
                position="relative"
                style={{ height: imageSideLength }}
              >
                <Box style={{ width: imageSideLength, height: imageSideLength }}>
                  <AnnotatedImage
                    srcUrl={refImage.url}
                    alt={refImage.fileName}
                    originalImageSize={[width, height]}
                    annotations={annotations(referenceAnnotation, refImage.fileName)}
                  />
                </Box>
              </Row>
              <Row>
                <Icon icon={CaretRight} size={24} />
              </Row>
              {isGenerationModelAITraining(modelTraining) &&
                modelTraining.modelTrainingIterations[iterationIndex].images.map(
                  (iterImage, iterIdx) => {
                    if (
                      refIdx * ITERATION_LENGTH <= iterIdx &&
                      iterIdx < refIdx * ITERATION_LENGTH + ITERATION_LENGTH
                    )
                      return (
                        <Row
                          key={iterImage.fileName}
                          backgroundColor={'gray-200'}
                          mr={
                            iterIdx <
                            modelTraining.modelTrainingIterations[iterationIndex].images.length - 1
                              ? 1
                              : undefined
                          }
                          style={{ height: imageSideLength }}
                          justifyContent="center"
                        >
                          <Box style={{ width: imageSideLength, height: imageSideLength }}>
                            <AnnotatedImage
                              srcUrl={iterImage.url}
                              alt={iterImage.fileName}
                              originalImageSize={[width, height]}
                              annotations={annotations(iterationAnnotation, iterImage.fileName)}
                            />
                          </Box>
                        </Row>
                      );
                  },
                )}
            </Box>
          );
        })}
    </Box>
  );
};
