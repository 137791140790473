import { MutableRefObject, useCallback, useEffect, useState } from 'react';

function useSectionSize(
  containerRef: MutableRefObject<HTMLDivElement | HTMLElement | undefined | null>,
) {
  const [sectionSize, setSectionSize] =
    useState<[top: number, left: number, width: number, height: number]>();

  const resizeCallback = useCallback(entries => {
    const [entry] = entries;
    if (entry.contentRect) {
      const { innerHeight } = window;
      const { top, left } = entry.target.getBoundingClientRect();
      const { width, y } = entry.contentRect;
      setSectionSize([top, left, width, innerHeight - top - y]);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeCallback);
    if (containerRef?.current) resizeObserver.observe(containerRef?.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef?.current]);

  return sectionSize;
}

export default useSectionSize;
