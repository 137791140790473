import React from 'react';

import { useLabelCompareInfo } from '../../../../../contexts/LabelCompareContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelInformationInfo } from '../../../../../contexts/LabelInformationContext';
import Box from './Box';
import helper from './helper';
import Keypoint from './Keypoint';
import Mask from './Mask';
import Polygon from './Polygon';
import Polyline from './Polyline';
import Tiltedbox from './Tiltedbox';

const Shape = props => {
  const { shape, hoverIndex, setHoverIndex, labelInfo, isCurrent } = props;
  const { type, id } = shape;

  const compareOption = [labelInfo.category, labelInfo.id];
  const annotation = labelInfo.info.annotations[id];

  const labelDetailViewInfo = useLabelDetailViewInfo();
  const {
    isAutoLabelUncertainty,
    isActiveAutoLabelUncertainPanel,
    setIsActiveAutoLabelUncertainPanel,
  } = labelDetailViewInfo;
  const labelInformationContext = useLabelInformationInfo();
  const {
    classes,
    annotations,
    cabinetTabState,
    annotationTypes,
    setClickedAnnotationInfo,
    setHoveredAnnotation,
    clickedAnnotationInfo,
  } = labelInformationContext;
  const labelCompareInfo = useLabelCompareInfo();
  const { activeCompareOptionArr } = labelCompareInfo;

  const isClicked =
    clickedAnnotationInfo?.annotation?.id === id &&
    clickedAnnotationInfo?.compareOption === compareOption;

  if (isCurrent && !annotationTypes[shape.type]?.isActive) return null;
  if (!shape?.class || !classes[shape.class]) return null;
  if (!classes[shape.class]?.isActive) return null;

  const color = helper.getShapeColor({
    activeCompareOptionArr,
    compareOptionColor: labelInfo.color,
    shape,
    classes,
  });

  const handleClickShape = e => {
    if (isActiveAutoLabelUncertainPanel) {
      setIsActiveAutoLabelUncertainPanel(false);
      return;
    }
    e.evt.stopPropagation();

    if (e.evt.metaKey || e.evt.ctrlKey) {
      return;
    }

    if (cabinetTabState === 'issue') return;

    setClickedAnnotationInfo({
      compareOption,
      annotation: annotations[id],
    });
  };

  const handleMouseEnterShape =
    ({ shapeRef, setIsHovered }) =>
    e => {
      if (isActiveAutoLabelUncertainPanel) return;
      e.evt.stopPropagation();

      if (cabinetTabState === 'issue') return;

      if (shapeRef.current.index >= hoverIndex) {
        shapeRef.current.zIndex(hoverIndex);
        setHoverIndex(hoverIndex + 1);
      }

      setIsHovered(true);
      setHoveredAnnotation(annotations[id]);
    };

  const handleMouseLeaveShape =
    ({ setIsHovered }) =>
    () => {
      if (isActiveAutoLabelUncertainPanel) return;
      if (cabinetTabState === 'issue') return;

      setIsHovered(false);
    };

  const getShape = () => {
    switch (type) {
      case 'box':
        return (
          <Box
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isAutoLabelUncertainty={isAutoLabelUncertainty}
            isActiveAutoLabelUncertainPanel={isActiveAutoLabelUncertainPanel}
            difficulty={annotation.difficulty}
            isClicked={isClicked}
          />
        );
      case 'polygon':
        return (
          <Polygon
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isAutoLabelUncertainty={isAutoLabelUncertainty}
            isActiveAutoLabelUncertainPanel={isActiveAutoLabelUncertainPanel}
            difficulty={annotation.difficulty}
            isClicked={isClicked}
          />
        );
      case 'polyline':
        return (
          <Polyline
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isClicked={isClicked}
          />
        );
      case 'tiltedbox':
        return (
          <Tiltedbox
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isClicked={isClicked}
          />
        );
      case 'keypoint':
        return (
          <Keypoint
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isClicked={isClicked}
          />
        );
      case 'mask':
        return (
          <Mask
            shape={shape}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            onClickShape={handleClickShape}
            onMouseEnterShape={handleMouseEnterShape}
            onMouseLeaveShape={handleMouseLeaveShape}
            color={color}
            isClicked={isClicked}
          />
        );

      default:
        return null;
    }
  };

  return getShape();
};

export default Shape;
