import { UnavailableFit } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

interface Props {
  color?: string;
  colorWithOpacity?: string;
  isNull?: boolean;
}

export const Circle = ({ color, colorWithOpacity, isNull }: Props) => {
  if (isNull) {
    return <Icon icon={UnavailableFit} size="8px" color="gray-300" />;
  }
  if (!color)
    return (
      <Box
        backgroundColor={'gray-200'}
        border="1px solid"
        borderColor={'gray-300'}
        style={{ width: '8px', height: '8px', opacity: 0.4 }}
        borderRadius="100%"
      />
    );
  return (
    <Box
      style={{
        backgroundColor: colorWithOpacity,
        border: `1px solid ${color}`,
        width: '8px',
        height: '8px',
      }}
      borderRadius="100%"
    />
  );
};
