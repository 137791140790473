import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Calendar, FileOutline, FolderOpen, LabelLogo, Slice, Trash, User } from '@superb-ai/icons';
import { Box, Chip, Icon, IconButton, Tooltip, Typography } from '@superb-ai/ui';
import { format } from 'date-fns';

import { useUsersQuery } from '../../../../../queries/useUsers';
import { MemberData } from '../../../../../types/memberTypes';
import { DatasetResult } from '../../../services/DatasetService';
import { DatasetPool, SampleDataset } from '../../../types/routeTypes';
import SyncedLabelListTooltip from './SyncedLabelListTooltip';

export default function DatasetListBox({
  dataset,
  setDeleteModalIsOpen,
  handleSelectDataset,
}: {
  dataset: DatasetResult;
  setDeleteModalIsOpen: (isOpen: boolean) => void;
  handleSelectDataset: (id: string) => void;
}): JSX.Element {
  const history = useHistory();
  const { accountName, datasetPool } = useParams<{
    accountName: string;
    datasetPool: DatasetPool;
  }>();
  const { t } = useTranslation();
  const isSampleDataset = datasetPool === SampleDataset;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [createdBy, setCreatedBy] = useState<MemberData>();

  const { data: usersData } = useUsersQuery({ params: { asList: '' }, statusIn: [] });

  useEffect(() => {
    if (!usersData) return;
    const result = usersData.find(user => user.email === dataset.createdBy);
    setCreatedBy(result);
  }, [dataset.createdBy, usersData]);

  const hasSyncedLabelProjects = (dataset?.syncedLabelProjectIds || [])?.length > 0;

  return (
    <Box
      key={dataset.id}
      position="relative"
      marginBottom={1}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      borderBottom="1px solid"
      borderColor="gray-150"
      cursor="pointer"
      onClick={e => {
        e.stopPropagation();
        history.push(`/${accountName}/curate/${datasetPool}/${dataset.id}/explore`);
      }}
      backgroundColor={{ hover: 'gray-opacity-100' }}
    >
      <Box
        position="relative"
        width="100%"
        borderRadius="2px"
        style={{
          paddingTop: '100%',
          backgroundImage: dataset.thumbnailUrl ? `url(${dataset.thumbnailUrl})` : 'grey',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {!dataset.thumbnailUrl && (
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="0"
            left="0"
            flexDirection="column"
          >
            <Icon icon={FolderOpen} size="48px" color="gray-250" />
            <Typography variant="m-strong" color="gray-300">
              {t('curate.datasets.empty')}
            </Typography>
          </Box>
        )}
        {isHovered && (
          <Box
            borderRadius="2px"
            width="100%"
            height="100%"
            position="absolute"
            top="0"
            left="0"
            backgroundColor="gray-opacity-100"
          ></Box>
        )}
        {isSampleDataset && (
          <Box position="absolute" style={{ top: 8, left: 8 }}>
            <Chip>{t('curate.datasets.sampleDataset.chip')}</Chip>
          </Box>
        )}
      </Box>
      <Box py={1.5} px={1}>
        <Box
          style={{
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
          }}
          mb={0.5}
          overflow="hidden"
        >
          <Typography variant="l-strong">{dataset?.name}</Typography>
        </Box>
        <Box
          style={{
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
            minHeight: '36px',
          }}
          overflow="hidden"
          mb={0.5}
        >
          <Typography variant="m-regular">{dataset.description}</Typography>
        </Box>
        <Box mb={1} display="flex">
          <Box
            display="flex"
            gap="small"
            alignItems="center"
            borderRight="1px solid"
            borderColor="gray-150"
            pr={1}
          >
            <Icon icon={FileOutline} color="gray-300" />
            <Typography variant="m-regular">{dataset.imageCount?.toLocaleString()}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="small"
            px={0.5}
            py={0.25}
            ml={0.5}
            borderRadius="2px"
            backgroundColor={{ hover: 'gray-opacity-100' }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              if (dataset?.sliceCount > 0)
                history.push(`/${accountName}/curate/${datasetPool}/${dataset.id}/slice/list`);
            }}
            {...(hasSyncedLabelProjects && {
              borderRight: '1px solid',
              borderColor: 'gray-150',
            })}
          >
            <Icon icon={Slice} color="gray-300" />
            <Typography variant="m-regular" color="primary">
              {dataset?.sliceCount?.toLocaleString()}
            </Typography>
          </Box>
          {hasSyncedLabelProjects && (
            <Tooltip
              placement="bottom"
              content={
                <SyncedLabelListTooltip
                  syncedLabelProjectIds={dataset.syncedLabelProjectIds || []}
                />
              }
            >
              <Box
                display="flex"
                alignItems="center"
                gap="small"
                px={0.5}
                py={0.25}
                ml={0.5}
                borderRadius="2px"
                backgroundColor={{ hover: 'gray-opacity-100' }}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  history.push(`/${accountName}/curate/dataset/${dataset.id}/settings`);
                }}
              >
                <Icon icon={LabelLogo} />
                <Typography variant="m-regular" color="primary">
                  {(dataset.syncedLabelProjectIds || []).length.toLocaleString()}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>
        {!isSampleDataset && (
          <>
            <Box mb={1} display="flex" gap="small">
              <Icon icon={User} color="gray-300" />
              <Typography
                variant="m-regular"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {createdBy?.name || '-'}
              </Typography>
            </Box>
            <Box display="flex" gap="small">
              <Icon icon={Calendar} color="gray-300" />
              <Typography
                variant="m-regular"
                color="gray-300"
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {dataset?.createdAt ? format(new Date(dataset?.createdAt), 'MMM d,yyyy') : '-'}
              </Typography>
              {isHovered && (
                <Box position="absolute" style={{ bottom: '12px', right: '8px' }}>
                  <IconButton
                    size="s"
                    variant="text"
                    color="primary"
                    icon={Trash}
                    onClick={e => {
                      e.stopPropagation();
                      setDeleteModalIsOpen(true);
                      handleSelectDataset(dataset.id);
                    }}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
