import { useTranslation } from 'react-i18next';

import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@superb-ai/icons';
import { Box, IconButton, Select, Typography } from '@superb-ai/ui';

import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useSearchParams } from '../../../../utils/router-utils';

interface Props {
  totalCount: number;
}

const ROWS_PER_PAGE_OPTION = ['10', '25', '50'] as const;
const pageSizeOptions = ROWS_PER_PAGE_OPTION.map(value => ({ label: value, value }));

export default function Pagination({ totalCount }: Props) {
  const { t } = useTranslation();
  const { cursorInfo } = useLabelsInfo();
  const routeInfo = useRouteInfo();
  const params = useSearchParams();
  const pageSize = routeInfo.params.pageSize || 10;
  const page = Number(routeInfo.params.page || 1);
  const totalPage = Math.ceil(totalCount / Number(pageSize));

  const handleChangePage = (action: 'first' | 'prev' | 'next' | 'last') => {
    const pageNumber = (() => {
      switch (action) {
        case 'first':
          return 1;
        case 'last':
          return totalPage;
        case 'next':
          return Math.min(totalPage, page + 1);
        case 'prev':
          return Math.max(1, page - 1);
      }
    })();
    let pageParams: [string, string][] = [['page', `${pageNumber}`]];

    switch (action) {
      case 'last':
        pageParams = [...pageParams, ['searchLast', 'true']];
        break;
      case 'next':
        if (pageNumber === totalPage) {
          pageParams = [...pageParams, ['searchLast', 'true']];
          break;
        }
      // else fall through to next case
      case 'prev': {
        if (pageNumber === 1) {
          break;
        }
        const cursorKey = action === 'prev' ? 'searchBefore' : 'searchAfter';
        pageParams = [
          ...pageParams,
          ...((cursorInfo[action as 'prev' | 'next']?.map(value => [cursorKey, `${value}`]) ??
            []) as [string, string][]),
        ];
        break;
      }
    }

    const searchParams = new URLSearchParams([
      ...params.filter(
        ([key]) => !['searchAfter', 'searchBefore', 'page', 'searchLast'].includes(key),
      ),
      ...pageParams,
    ]);
    routeInfo.history.push(`?${searchParams.toString()}`);
  };

  const handleChangeRowsPerPage = (rowsPerPage: string) => {
    const searchParams = new URLSearchParams([
      ...params.filter(
        ([key]) => !['page', 'searchAfter', 'searchBefore', 'pageSize', 'searchLast'].includes(key),
      ),
      ['pageSize', rowsPerPage],
    ]);
    routeInfo.history.push(`?${searchParams.toString()}`);
  };

  if (totalCount === 0) return <></>;

  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Box display="flex" alignItems="center">
        <Typography color="gray-400" variant="m-regular">
          {t('labels.rowsPerPage')}:
        </Typography>
        <Select
          data={pageSizeOptions}
          value={pageSize}
          variant="text"
          onChangeValue={handleChangeRowsPerPage}
        />
      </Box>
      <Box display="flex" alignItems="center" gap={1.5}>
        <Typography variant="m-regular" color="gray-400">
          1
        </Typography>
        <Box>
          <IconButton
            disabled={page <= 1}
            onClick={() => handleChangePage('first')}
            variant="text"
            icon={FirstPage}
          />
          <IconButton
            disabled={page <= 1}
            onClick={() => handleChangePage('prev')}
            variant="text"
            icon={ChevronLeft}
          />
        </Box>
        <Typography variant="m-regular" color="gray-400">
          {page}
        </Typography>
        <Box>
          <IconButton
            disabled={page >= totalPage}
            onClick={() => handleChangePage('next')}
            variant="text"
            icon={ChevronRight}
          />
          <IconButton
            disabled={page >= totalPage}
            onClick={() => handleChangePage('last')}
            variant="text"
            icon={LastPage}
          />
        </Box>
        <Typography variant="m-regular" color="gray-400">
          {totalPage}
        </Typography>
      </Box>
    </Box>
  );
}
