import React, { ChangeEvent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { LoadingSpinner, Slice } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../../../../contexts/AppContext';
import { useUsersQuery } from '../../../../../../../queries/useUsers';
import { getUrl } from '../../../../../../../routes/util';
import { TrainQueryKeyword } from '../../../../../../Model/train/queries';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import ListSearchInput from '../../../../../elements/ListSearchInput';
import { useSlicesQuery } from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { DatasetPool } from '../../../../../types/routeTypes';
import {
  getNumConvertedParams,
  getQueryParams,
  getSearchQueryRoute,
} from '../../../../../utils/routeUtils';
import Pagination from '../../../Pagination';
import NewSelect from '../../../SortDatasetDropdown';
import CreateEmptySliceModal from '../../modal/CreateEmptySliceModal';
import DeleteSliceModal from '../../modal/DeleteSliceModal';
import SliceEmptyPage from './SliceEmptyPage';
import SliceListBox from './SliceListBox';

export default function SliceListLayout(): JSX.Element {
  const { accountName, datasetId, datasetPool } = useParams<{
    accountName: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();
  const history = useHistory();
  const DEFAULT_PAGE = 1;
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SORT_ORDER = 'desc';
  const DEFAULT_SORT_BY = 'created_at';
  const { page, size, sortOrder, sortBy, search } = getQueryParams(history) as {
    page?: string;
    size?: string;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
    search?: string;
  };

  const { setDatasetInfo } = useDatasetContext();

  const [createSliceModalIsOpen, setCreateSliceModalIsOpen] = useState<boolean>(false);
  const [selectedSliceIds, setSelectedSliceIds] = useState<string[]>([]);
  const [selectedSliceNames, setSelectedSliceNames] = useState<string[]>([]);

  const [selectedDeleteSlice, setSelectedDeleteSlice] = useState<string>('');
  const { deleteSlice, getSliceList, getDataset } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const { t } = useTranslation();
  const { data: usersData } = useUsersQuery({ params: { asList: '' }, statusIn: [] });

  useSetPageTitle(t('curate.dataset.menus.slices'), PAGE_TRACKING_ID.curateDatasetSliceList);

  const params = {
    datasetId,
    page: getNumConvertedParams(DEFAULT_PAGE, page),
    size: getNumConvertedParams(DEFAULT_PAGE_SIZE, size),
    sortOrder,
    sortBy,
    accountName,
    nameContains: search,
  };

  const {
    data: sliceData,
    refetch: refetchSlice,
    isFetching,
  } = useSlicesQuery({
    ...params,
    expand: ['thumbnail_url', 'image_count'],
    fromPublicDatasets: showPublicDatasets,
    queryRefetchOptions: { refetchOnWindowFocus: false },
  });

  const { results: slicesList, count: totalCount } = sliceData || { results: [], count: 0 };

  function handleSearchName(value?: string) {
    history.push(getSearchQueryRoute(history, { page: DEFAULT_PAGE, search: value }));
  }

  const handleSelectDataset = useCallback(
    clickedBoxNum => {
      if (selectedSliceIds.includes(clickedBoxNum)) {
        const newSelectedIds = selectedSliceIds.filter(list => list !== clickedBoxNum);
        setSelectedSliceIds([...newSelectedIds]);
      } else {
        setSelectedSliceIds(prev => [...prev, clickedBoxNum]);
      }
      const sliceName = slicesList.find(x => x.id === clickedBoxNum)?.name ?? '';
      if (selectedSliceNames.includes(sliceName)) {
        const newSelectedNames = selectedSliceNames.filter(list => list !== sliceName);
        setSelectedSliceNames([...newSelectedNames]);
      } else {
        setSelectedSliceNames(prev => [...prev, sliceName]);
      }
    },
    [selectedSliceIds, slicesList, selectedSliceNames],
  );

  function handleSortOrder(sortOrder: string) {
    history.push(getSearchQueryRoute(history, { sortOrder, page: DEFAULT_PAGE }));
  }

  function handleSortBy(sortBy: string) {
    history.push(getSearchQueryRoute(history, { sortBy, page: DEFAULT_PAGE }));
  }

  function handleChangePage(_: any, page: number) {
    history.push(getSearchQueryRoute(history, { page: page + 1 }));
  }

  function handleChangeRowsPerPage(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const rowsPerPage = e.target.value;
    history.push(getSearchQueryRoute(history, { page: DEFAULT_PAGE, size: rowsPerPage }));
  }

  function resetPageParams() {
    history.push(
      getSearchQueryRoute(history, {
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        sortOrder: DEFAULT_SORT_ORDER,
        sortBy: DEFAULT_SORT_BY,
      }),
    );
  }

  const handleClickDelete = async () => {
    await deleteSlice({ datasetId, sliceId: selectedDeleteSlice });
    resetPageParams();
    await refetchSlice();
    const datasetInfo = await getDataset({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
    setSelectedSliceIds([]);
  };

  const handleClickCreate = async () => {
    resetPageParams();
    await refetchSlice();
    const datasetInfo = await getDataset({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
    setSelectedSliceIds([]);
  };

  return (
    <>
      {!!selectedDeleteSlice && (
        <DeleteSliceModal
          deleteSliceModalIsOpen={!!selectedDeleteSlice}
          handleClickDelete={handleClickDelete}
          sliceId={selectedDeleteSlice}
          setSelectedDeleteSlice={setSelectedDeleteSlice}
        />
      )}
      <Box px={4} py={2}>
        <Box display="flex" alignItems="center" width="100%" gap="small">
          <Typography variant="m-regular" color="gray-300">
            Total{' '}
            <Typography variant="m-regular" color="gray">
              {totalCount.toLocaleString()}
            </Typography>
          </Typography>
          <NewSelect
            handleSortOrder={handleSortOrder}
            sortOrder={sortOrder}
            handleSortBy={handleSortBy}
            sortBy={sortBy}
          />
          <Box mr="auto" style={{ width: 300 }}>
            <ListSearchInput
              isFetching={isFetching}
              handleSearchName={handleSearchName}
              defaultValue={search}
              placeholder={t('curate.slices.searchPlaceholder')}
            />
          </Box>

          <Box ml="auto" display="flex" alignItems="center" gap="small">
            <Button
              variant="stroke"
              disabled={showPublicDatasets}
              onClick={() => {
                analyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-curate-dataset',
                });
                history.push(
                  getUrl(
                    [accountName, 'model', 'train'],
                    {},
                    {
                      [TrainQueryKeyword.Slices]: selectedSliceNames.length
                        ? selectedSliceNames.join('*')
                        : undefined,
                      [TrainQueryKeyword.Dataset]: datasetId,
                    },
                  ),
                );
              }}
            >
              {t('model.train.title')}
            </Button>
            <Button
              variant="strong-fill"
              disabled={showPublicDatasets}
              color="primary"
              onClick={() => setCreateSliceModalIsOpen(true)}
            >
              <Icon icon={Slice} />
              {t('curate.button.createEmptySlice')}
            </Button>
            <CreateEmptySliceModal
              createSliceModalIsOpen={createSliceModalIsOpen}
              setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
              onCreate={handleClickCreate}
            />
          </Box>
          {/* <Box display="flex" alignItems="center" justifyContent="space-between">
                <IconButton
                  icon={Grid2X2Big}
                  variant={gridStyle === 'box' ? 'soft-fill' : 'stroke'}
                  color={gridStyle === 'box' ? 'primary' : 'gray'}
                  onClick={() => {
                    setGridStyle('box');
                  }}
                />
                <IconButton
                  icon={List}
                  variant={gridStyle === 'list' ? 'soft-fill' : 'stroke'}
                  color={gridStyle === 'list' ? 'primary' : 'gray'}
                  onClick={() => {
                    setGridStyle('list');
                  }}
                />
              </Box> */}
        </Box>
        <Box width="100%" mt={2}>
          {isFetching ? (
            <Box display="flex" p={1} justifyContent="center" alignItems="center">
              <Icon icon={LoadingSpinner} />
            </Box>
          ) : totalCount ? (
            slicesList.length > 0 ? (
              <Box
                display="grid"
                gap="medium"
                style={{
                  gridTemplateColumns: 'repeat(5, minmax(180px, 1fr))',
                }}
              >
                {slicesList.map(slice => (
                  <SliceListBox
                    key={slice.id}
                    slice={slice}
                    selectedSlice={selectedSliceIds}
                    handleSelectDataset={handleSelectDataset}
                    setSelectedDeleteSlice={setSelectedDeleteSlice}
                    users={usersData || []}
                  />
                ))}
              </Box>
            ) : (
              <SliceEmptyPage handleClickCreate={handleClickCreate} />
            )
          ) : (
            <Box
              p={2}
              mt={2}
              display="flex"
              justifyContent="center"
              backgroundColor="secondary-opacity-100"
            >
              <Typography variant="m-regular" color="blue">
                <Trans t={t} i18nKey={'curate.slices.noSearchResult'} values={{ name: search }} />
              </Typography>
            </Box>
          )}
        </Box>

        {slicesList.length > 0 ? (
          <Box>
            <Pagination
              totalCount={totalCount}
              size={getNumConvertedParams(DEFAULT_PAGE_SIZE, size)}
              page={getNumConvertedParams(DEFAULT_PAGE, page)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              type="SLICE"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
