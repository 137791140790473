import React from 'react';

import { Box } from '@superb-ai/ui';

import DatasetSetting from './DatasetSetting';
import SyncedLabelProject from './SyncedLabelProject';

export default function SettingsLayout() {
  return (
    <Box p={4} display="flex" flexDirection="column" gap={3}>
      <DatasetSetting />
      <SyncedLabelProject />
    </Box>
  );
}
