import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, DropdownList, Icon, Tab, Tabs, Tooltip } from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useNewProjectInfo } from '../../../../../contexts/NewProjectContext';
import ButtonWithTooltip from './ButtonWithTooltip';
import { keypointTemplate, Mode } from './const';
import EdgesEditor from './EdgesEditor';
import { getKeypointTemplates } from './helper';
import ImportCustomKeypoint from './ImportCustomKeypoint';
import JsonEditor from './JsonEditor';
import KeypointVisualizer from './KeypointVisualizer';
import PointsEditor from './PointsEditor';
import { Keypoint } from './type';

type Props = {
  setMode: (mode: Mode) => void;
  setCreatedKeypointId: (id: string) => void;
};

const KeypointDialogCreateLayout: React.FC<Props> = ({ setMode, setCreatedKeypointId }) => {
  const { t } = useTranslation();

  const tabs = {
    useTemplate: { label: t('projects.createProject.keypoint.useTemplate'), value: 'useTemplate' },
    customKeypoint: {
      label: t('projects.createProject.keypoint.customKeypoint'),
      value: 'customKeypoint',
    },
    importFromOtherProjects: {
      label: t('projects.createProject.keypoint.importFromOtherProject'),
      value: 'importFromOtherProjects',
    },
  };

  const [mount, setMount] = useState(false);
  const newProjectInfo = useNewProjectInfo();
  const [tabValue, setTabValue] = useState(tabs['useTemplate'].value);
  const [curKeypoint, setCurKeypoint] = useState<Keypoint | null>(null);
  const [customKeypointName, setCustomKeypointName] = useState('Untitled Keypoint');
  // use template
  const [templates, setTemplates] = useState<Keypoint[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  // custom keypoint
  const [customKeypoint, setCustomKeypoint] = useState<Keypoint | null>(
    cloneDeep(keypointTemplate),
  );
  const [jsonEditorErrorMessage, setJsonEditorErrorMessage] = useState('');
  // import keypoint
  const [selectedProject, setSelectedProject] = useState(null);
  const [importedKeypoint, setImportedKeypoint] = useState<Keypoint | null>(null);
  const [importKeypointErrorMessage, setImportKeypointErrorMessage] = useState('');

  useEffect(() => {
    setMount(true);

    const nextTemplates = getKeypointTemplates(newProjectInfo.keypointList);
    if (nextTemplates.length === 0) {
      setTabValue(tabs['customKeypoint'].value);
      setCurKeypoint(customKeypoint);
      return;
    }

    setTemplates(nextTemplates);
    setCurKeypoint(cloneDeep(nextTemplates[0]));
    setSelectedTemplateId(nextTemplates[0].id);
    // eslint-disable-next-line
  }, []);

  const handleChangeTab = (value: string) => {
    if (tabValue === value) return;

    if (tabValue === tabs['customKeypoint'].value) {
      setCustomKeypoint(curKeypoint);
    } else if (tabValue === tabs['importFromOtherProjects'].value) {
      setImportedKeypoint(curKeypoint);
    }

    if (value === tabs['useTemplate'].value) {
      if (templates.length === 0) return;

      const nextCurKeypoint = templates[0];

      if (nextCurKeypoint) {
        setCurKeypoint(nextCurKeypoint);
        setSelectedTemplateId(nextCurKeypoint.id);
      }
    } else if (value === tabs['customKeypoint'].value) {
      setCurKeypoint(customKeypoint);
    } else if (value === tabs['importFromOtherProjects'].value) {
      setCurKeypoint(importedKeypoint);
    }
    setTabValue(value);
  };

  const handleChangeTemplate = (id: string) => {
    const nextCurKeypoint = templates.find(template => template.id === id);

    if (nextCurKeypoint) {
      setCurKeypoint(nextCurKeypoint);
      setSelectedTemplateId(id);
    }
  };

  const handleClickCancel = () => {
    setMode('SELECT');
  };

  const handleClickCreate = () => {
    if (!curKeypoint) return;
    const nextKeypointList = cloneDeep(newProjectInfo.keypointList);
    const nextKeypoint = cloneDeep(curKeypoint);

    if (tabValue === tabs['customKeypoint'].value) {
      nextKeypoint.id = uuidv4();
    }

    switch (tabValue) {
      case tabs['useTemplate'].value:
        const selectedTemplate = templates.find(t => t.id === selectedTemplateId);
        if (!selectedTemplate) {
          throw new Error('there is no selected template');
        }
        nextKeypoint.name = selectedTemplate.name;
        break;
      case tabs['customKeypoint'].value:
        nextKeypoint.name = customKeypointName;
        break;
      case tabs['importFromOtherProjects'].value:
        break;
    }

    nextKeypointList.push(nextKeypoint);
    newProjectInfo.setKeypointList(nextKeypointList);

    setCreatedKeypointId(nextKeypoint.id);
    setMode('SELECT');
  };

  const getErrorCond = (): boolean => {
    if (!curKeypoint) {
      return true;
    }

    if (tabValue === tabs['customKeypoint'].value && jsonEditorErrorMessage !== '') {
      return true;
    }

    if (tabValue === tabs['importFromOtherProjects'].value && importKeypointErrorMessage) {
      return true;
    }

    return false;
  };

  const getErrorMessage = () => {
    if (tabValue === tabs['customKeypoint'].value) return jsonEditorErrorMessage;

    if (tabValue === tabs['importFromOtherProjects'].value) return importKeypointErrorMessage;

    return '';
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="24px"
    >
      <Box display="flex" alignItems="center" gap="8px">
        <Tabs variant="underlined" color="grey" onChange={handleChangeTab} currentTab={tabValue}>
          {templates.length !== 0 ? (
            <Tab
              key={tabs['useTemplate'].value}
              label={tabs['useTemplate'].label}
              value={tabs['useTemplate'].value}
            />
          ) : (
            <Tooltip
              anchorEl={
                <span>
                  <Tab
                    key={tabs['useTemplate'].value}
                    label={tabs['useTemplate'].label}
                    value={tabs['useTemplate'].value}
                    disabled
                  />
                </span>
              }
            >
              {t('projects.createProject.keypoint.allTemplatesAreInUse')}
            </Tooltip>
          )}
          <Tab
            key={tabs['customKeypoint'].value}
            label={tabs['customKeypoint'].label}
            value={tabs['customKeypoint'].value}
          />
          <Tab
            key={tabs['importFromOtherProjects'].value}
            label={tabs['importFromOtherProjects'].label}
            value={tabs['importFromOtherProjects'].value}
            // @ts-ignore
            minWidth={'100%'}
          />
        </Tabs>
        {tabValue === tabs['useTemplate'].value && (
          <Box display="flex" width="220px">
            {mount && (
              <DropdownList
                placeholder="Select template"
                options={templates.map((keypoint: Keypoint) => ({
                  label: keypoint.name,
                  value: keypoint.id,
                }))}
                value={selectedTemplateId}
                onChange={handleChangeTemplate}
              />
            )}
          </Box>
        )}
      </Box>
      <Box height="500px" display="flex" alginItems="center" gap="16px">
        {tabValue === tabs['customKeypoint'].value && (
          <>
            <Box width="420px" height="100%">
              <JsonEditor
                keypoint={curKeypoint}
                setKeypoint={setCurKeypoint}
                setErrorMessage={setJsonEditorErrorMessage}
                {...{ customKeypointName, setCustomKeypointName }}
              />
            </Box>
            <Box
              width="32px"
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
            >
              <Icon name="arrowRight" size="60px" />
            </Box>
          </>
        )}
        {tabValue === tabs['importFromOtherProjects'].value && (
          <>
            <ImportCustomKeypoint
              keypoint={curKeypoint}
              setKeypoint={setCurKeypoint}
              setErrorMessage={setImportKeypointErrorMessage}
              {...{
                selectedProject,
                setSelectedProject,
              }}
            />
            <Box
              width="32px"
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
            >
              <Icon name="arrowRight" size="60px" />
            </Box>
          </>
        )}
        <Box position="relative" display="flex" gap="16px">
          <Box
            width="400px"
            height="100%"
            display="flex"
            alignItems="center"
            shadow={1}
            borderRadius="8px"
          >
            <KeypointVisualizer width={400} height={400} keypoint={curKeypoint} />
          </Box>
          <Box width="300px" height="100%" display="flex" flexDirection="column" gap="16px">
            <Box width="100%" flex={1} overflow="hidden" shadow={1} borderRadius="8px">
              <PointsEditor keypointId={curKeypoint?.id || ''} points={curKeypoint?.points || []} />
            </Box>
            <Box width="100%" flex={1} overflow="hidden" shadow={1} borderRadius="8px">
              <EdgesEditor keypointId={curKeypoint?.id || ''} edges={curKeypoint?.edges || []} />
            </Box>
          </Box>
          {tabValue === tabs['customKeypoint'].value && jsonEditorErrorMessage !== '' && (
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              top="0px"
              right="0px"
              bottom="0px"
              left="0px"
              themedBackgroundColor={['grey', 50]}
            >
              {jsonEditorErrorMessage}
            </Box>
          )}
          {tabValue === tabs['importFromOtherProjects'].value &&
            importKeypointErrorMessage !== '' && (
              <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                top="0px"
                right="0px"
                bottom="0px"
                left="0px"
                themedBackgroundColor={['grey', 50]}
              >
                {importKeypointErrorMessage}
              </Box>
            )}
        </Box>
      </Box>
      <Box position="absolute" bottom="-44px" right="0" display="flex" gap="8px">
        <Button color="grey" variant="text" onClick={handleClickCancel}>
          {t('button.cancel')}
        </Button>
        <ButtonWithTooltip
          Comp={
            <Button color="grey" onClick={handleClickCreate}>
              {t('button.create')}
            </Button>
          }
          cond={getErrorCond}
          props={{ disabled: true }}
          text={getErrorMessage()}
        />
      </Box>
    </Box>
  );
};

export default KeypointDialogCreateLayout;
