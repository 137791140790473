import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { AppMenu, MenuItemProp, MenuTitle } from '../../../../../menu';
import { useDatasetContext } from '../../../contexts/DatasetContext';
import DatasetSelector from './DatasetSelector';
import MenuDatasetDescription from './MenuDatasetDescription';
import MenuUploadButton from './MenuUploadButton';

export function DatasetMenu({ menuItem }: MenuItemProp): JSX.Element {
  const { datasetInfo } = useDatasetContext();
  const { t } = useTranslation();
  return (
    <>
      <MenuTitle position="sticky" top={'0'} p={0} style={{ zIndex: 5 }}>
        <DatasetSelector value={{ id: datasetInfo?.id || '', name: datasetInfo?.name || '' }} />
      </MenuTitle>

      <Box
        display="flex"
        flexDirection="column"
        borderColor="green-100"
        className="app-menu"
        style={{ flex: 1 }}
      >
        <Box pt={1} px={1} display="flex">
          <MenuUploadButton />
        </Box>
        <Box p={1} display="flex" flexDirection="column">
          <AppMenu items={menuItem.children ?? []} nested exact />
        </Box>
        <Box style={{ marginTop: 'auto' }}>
          <MenuDatasetDescription />
        </Box>
      </Box>
    </>
  );
}
