import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useBlockLayout, useRowSelect, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';

import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { Box, Button } from '@superb-ai/norwegian-forest';
import cn from 'classnames';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { PlotDatum, ScoreType } from './types';

interface Props {
  data: PlotDatum[];
  setSelectedData: Dispatch<SetStateAction<string[]>>;
  scoreType: ScoreType;
  width: number;
}

// TODO (ml)  - how can we set width programmatically?
const StyledWrapper = styled.div`
  width: 450px;
  height: 324px;
  overflow: auto;
  .headerGroup {
    font-weight: 500;

    .th {
      border-bottom: 2px solid #ddd;
    }
    .tr:first-of-type {
      .th {
        border-bottom: 1px solid white;
      }
    }
  }
  .td,
  .th {
    padding: 4px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 36px;
    line-height: 28px;
  }
  .tr.row {
    &:hover,
    &.selected {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #ff625a0a;
      }
    }
  }
`;

const LabelerQaTable: React.FC<Props> = ({ data, setSelectedData, scoreType }) => {
  const {
    urlMatchInfo: { accountName, projectId },
  } = useRouteInfo();
  const history = useHistory();
  const { t } = useTranslation();
  const defaultColumn = useMemo(
    () => ({
      width: 100,
    }),
    [],
  );

  const filteredData = useMemo(() => data.filter(item => item.isFiltered), [data]);

  const ScoreCell = ({ value }: { value: string | null }) => value ?? '-';
  const AssigneeCell = ({ value }: { value: string }) => (value === '' ? 'No Assignee' : value);

  const columns = useMemo(
    () => [
      {
        Header: t('analytics.text.info'),
        columns: [
          {
            Header: t('analytics.text.email'),
            Cell: AssigneeCell,
            accessor: 'email',
            width: 130,
          },
        ],
      },
      {
        Header: t('analytics.qa.score'),
        align: 'center',
        color: '#eee',
        columns: [
          {
            Header: t('analytics.qa.min'),
            Cell: ScoreCell,

            accessor: 'minScore',
            width: 56,
            color: '#eee',
            align: 'center',
          },
          {
            Header: t(`analytics.qa.header.${scoreType}`),
            Cell: ScoreCell,

            accessor: scoreType,
            width: 76,
            color: '#eee',
            align: 'center',
          },
          {
            Header: t('analytics.qa.max'),
            Cell: ScoreCell,

            accessor: 'maxScore',
            width: 56,
            color: '#eee',
            align: 'center',
          },
        ],
      },
      {
        Header: t('text.labels'),
        align: 'center',
        columns: [
          {
            Header: t('analytics.text.submitted'),
            accessor: 'submittedConsensusLabelCount',
            width: 82,
            align: 'center',
          },
        ],
      },
    ],
    [scoreType],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable<PlotDatum>(
    {
      columns,
      data: filteredData,
      defaultColumn,
    },
    useBlockLayout,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 50,
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <Checkbox color="primary" {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: any) => (
            <Checkbox color="primary" {...(row as any).getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    setSelectedData(selectedFlatRows.map(item => item.original.email));
  }, [selectedFlatRows]);

  const headerProps = (props: any, { column }: any) => getStyles(props, column.align, column.color);

  const cellProps = (props: any, { cell }: any) => {
    return getStyles(props, cell.column.align, cell.row.index % 2 === 0 ? '#eee' : 'inherit');
  };

  const getStyles = (props: any, align = 'left', backgroundColor = 'inherit') => [
    props,
    {
      style: {
        backgroundColor,
        textAlign: align,
      },
    },
  ];

  const handleClickInspect = () => {
    /** Hacky way to use Assignee does not exist filter if only `No Assignee` is selected */
    if (selectedFlatRows.length === 1 && selectedFlatRows[0]?.original?.email === 'No Assignee') {
      history.push(
        `/${accountName}/label/project/${projectId}/labels?qa=is any one of,consensus&assignee=does not exist,&status=is+any+one+of%2Csubmitted`,
      );
      return;
    }

    const assigneeListQueryString = selectedFlatRows
      .map(({ original }) => encodeURIComponent(`${original.name} (${original.email})`))
      .join(',');

    history.push(
      `/${accountName}/label/project/${projectId}/labels?qa=is any one of,consensus&assignee=is any one of,${assigneeListQueryString}&status=is+any+one+of%2Csubmitted`,
    );
  };

  const RenderRow = ({ index, style }: any) => {
    const row = rows[index];
    prepareRow(row);

    const selected = Object.keys(selectedRowIds).includes(index.toString());

    return (
      <div
        {...row.getRowProps({
          style,
        })}
        className={cn('tr', 'row', { selected })}
      >
        {row.cells.map(cell => {
          const { key, ...otherProps } = cell.getCellProps(cellProps);
          return (
            <div key={key} {...otherProps} className="td">
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <StyledWrapper {...getTableProps()} className="table">
      <div className="headerGroup">
        {headerGroups.map(headerGroup => {
          const { key, ...otherProps } = headerGroup.getHeaderGroupProps();
          return (
            <div key={key} {...otherProps} className="tr">
              {headerGroup.headers.map(column => {
                const { key, ...otherProps } = column.getHeaderProps(headerProps);
                return (
                  <div key={key} {...otherProps} className="th">
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList height={209} itemCount={rows.length} itemSize={36} width={totalColumnsWidth}>
          {RenderRow}
        </FixedSizeList>
      </div>
      <Box mt={1} display="flex">
        <Button
          variant="stroke"
          disabled={selectedFlatRows.length <= 0}
          onClick={handleClickInspect}
        >
          {t('analytics.qa.inspectInLabelsBtn')}
        </Button>
      </Box>
    </StyledWrapper>
  );
};

export default LabelerQaTable;
