import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import {
  Box as NFBox,
  ButtonGroup,
  Card,
  IconButton,
  Typography,
} from '@superb-ai/norwegian-forest';
import { concat, filter, isEmpty, map, orderBy, reduce } from 'lodash';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import BarChartStackedWithScroll from '../charts/barChart/BarChartStackedWithScroll';
import { SVG_PROPERTY_SCROLL_BAR } from '../config/svgConfig';
import {
  AssigneeLabelStatusDto,
  FileFormat,
  LabelStatusCumulative,
  SortOption,
} from '../dataTypes/analyticsDataTypes';
import EmptyPlot from '../elements/EmptyPlot';
import DownloadDropDown from '../tools/DownloadDropDown';
import FilterStatusDropDown from '../tools/FilterStatusDropDown';
import { addNameAndTotal, sortData, updateSelectedLabelsCount } from '../tools/helper';
import SortDropDown from '../tools/SortDropDown';
import { chartMockData } from '../userStats/mock';
import { JsonObj } from '../userStats/types';
import { downloadDataFile } from './helper';

interface ChartProps {
  chartName: 'workerStats'; // name used in plotConfig
  chartInfo: JsonObj; // chart config info from plotConfig
  labelCount: number;
  isLoading: boolean;
  emptyMessage: string;
  sourceData: AssigneeLabelStatusDto[] | null;
  width: number;
}

/**
 * User Reports > User Label Counts Chart ('workerStats' in plotConfig)
 */
const UserLabelCountsChart: React.FC<ChartProps> = props => {
  const projectInfo = useProjectInfo();
  const { t } = useTranslation();
  const { owners, admins, managers, workers } = projectInfo;

  const projectName = projectInfo.project.name;
  const { sourceData, chartName, chartInfo, isLoading, labelCount, emptyMessage, width } = props;
  const {
    xVariable: [xKey],
    yVariable: [yKey],
    sortX: [sortXKey, sortXName, sortXDirection],
    sortY: [sortYKey, sortYName, sortYDirection],
    chartKind,
    chartDefault,
    statusOptionsDefault,
    buttonSize,
    excelSheetName,
  } = chartInfo;

  const [statusOptions, setStatusOptions] = useState<LabelStatusCumulative[]>(statusOptionsDefault);
  const [sortX, setSortX] = useState({
    key: sortXKey,
    name: sortXName,
    direction: sortXDirection,
    display: false,
  });
  const [sortY, setSortY] = useState({
    key: sortYKey,
    name: sortYName,
    direction: sortYDirection,
    display: true,
  });

  // Labeler email to display name
  const ACTIVE_LABELERS = reduce(
    filter(concat(owners, admins, managers, workers), ['status', 'Active']),
    (acc, d) => {
      const { email } = d;
      acc[email] = d.name; // map email to name
      return acc;
    },
    { unassigned: 'No Assignee', '': 'No Name' } as Record<string, string>,
  );

  /**
   * Right now data is small enough that we filter, sort and map separately.
   */
  const formatData = (data: AssigneeLabelStatusDto[]): JsonObj[] => {
    return addNameAndTotal(data, ACTIVE_LABELERS);
  };

  const [formattedData, setFormattedData] = useState<JsonObj[]>([]);

  useEffect(() => {
    if (sourceData && !isEmpty(sourceData)) {
      setFormattedData(sortData(formatData(sourceData), sortY, sortX, chartName));
    }
    // eslint-disable-next-line
  }, [isLoading]);

  // Reformats data to pass to chart components (supp: email)
  const filterData = (data: JsonObj): JsonObj => {
    const emailKey = 'assignee';

    return {
      data: data,
      keys: [xKey, yKey, emailKey],
      x: map(data, d => d[xKey]),
      y: map(data, d => d[yKey]),
      supp: map(data, d => d[emailKey]),
    };
  };

  /**
   */
  const handleFilterStatus = (newOptions: LabelStatusCumulative[]) => {
    setStatusOptions(newOptions);
    const sorted = orderBy(updateSelectedLabelsCount(formattedData, newOptions), sortY.key, 'desc');
    setFormattedData(sorted);
  };

  // Only applies to bar/donut charts
  const handleSortChange = (sortAxis: 'x' | 'y') => {
    if (sortAxis === 'x') {
      const reverseSortX = {
        ...sortX,
        direction: sortX.direction === 'asc' ? 'desc' : 'asc',
        display: true,
      } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortX, sortY, chartName));
      setSortX(reverseSortX);
      setSortY({ ...sortY, display: false });
    } else if (sortAxis === 'y') {
      const reverseDir = sortY.direction === 'asc' ? 'desc' : 'asc';
      const reverseSortY = { ...sortY, direction: reverseDir, display: true } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortY, sortX, chartName));
      setSortY(reverseSortY);
      setSortX({ ...sortX, display: false });
    }
  };

  /**
   * File name includes project name, date, chart type
   */
  const handleDownload = (outputType: FileFormat) => {
    const outputData = map(formattedData, d => {
      return {
        email: d.assignee,
        name: d.assignee_name,
        submitted_cumulative: d.cumSubmitted,
        skipped_cumulative: d.cumSkipped,
        in_progress_cumulative: d.cumWorking,
      };
    });

    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: outputData,
        projectName,
        chartName: `User ${t(chartInfo.title)}`,
      });
      return;
    }
    downloadDataFile({
      downloadData: outputData,
      projectName,
      chartName: `User ${t(chartInfo.title)}`,
      excelSheetName,
    });
  };

  /**
   * Given chartType, renders the selected chart Component.
   * Bar: If there are more than 16 data elements, renders barChartScrollable instead of regular {t("analytics.icon.barChart")}.
   */
  const makeChart = (formattedData: JsonObj) => {
    /** svg setting */
    const svgInfo = SVG_PROPERTY_SCROLL_BAR(
      { marginLeft: 50, width: width - 10, height: 300 },
      formattedData.data.length,
    );
    /** TODO: handle this better (duplicate code in all chart containers, and dependency in svgConfig) */
    const isScrollable = formattedData.data.length > 30;
    return (
      <BarChartStackedWithScroll
        data={formattedData.data}
        selectedStatus={statusOptions}
        chartInfo={chartInfo}
        chartName={chartName}
        isScrollable={isScrollable}
        xKeyToDisplayName={ACTIVE_LABELERS}
        svgInfo={svgInfo}
        width={width} // consider padding of NFBox that contains chart
      />
    );
  };

  const hasZeroLabels = labelCount === 0 || formattedData.length === 0;

  /**
   * UI [Status Dropdown] [Sort Dropdown]
   */
  const getRightButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <Box mt={1} mb={1} mr="5px" maxWidth="600px">
        <ButtonGroup gap={1}>
          <FilterStatusDropDown
            size={buttonSize.status}
            handleFilterStatus={handleFilterStatus}
            options={statusOptions}
          />
          <SortDropDown
            size={buttonSize.sort}
            sortX={sortX}
            sortY={sortY}
            handleSortChange={handleSortChange}
            isDisabled={hasZeroLabels}
          />
          <DownloadDropDown
            ml={0}
            iconButtonEl={
              <IconButton
                size="s"
                icon="download"
                color="primary"
                variant="strong-fill"
                disabled={formattedData.length === 0}
              />
            }
            handleDownload={handleDownload}
            options={['xlsx', 'csv', 'png']}
            isToggleDisabled={formattedData.length === 0}
          />
        </ButtonGroup>
      </Box>
    );
  };

  return (
    <Card mt={3} width={width}>
      <Box
        display="flex"
        pl={2}
        pr={2}
        pb={1}
        pt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="headline5" themedColor="textDefault">
            <strong>{t(chartInfo.title)}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {getRightButtonGroup(hasZeroLabels)}
        </Box>
      </Box>
      <NFBox
        pt={2}
        pb={2}
        themedBackgroundColor={['grey', 50]}
        position="relative"
        width={width}
        display="flex"
      >
        {isLoading ? (
          <CircularProgressBox
            key={chartName}
            boxProps={{ mt: 5, mb: 5, width: '100%', height: '180px' }}
          />
        ) : (
          <NFBox width="100%">
            {makeChart(filterData(hasZeroLabels ? chartMockData[chartName] : formattedData))}
            {hasZeroLabels && <EmptyPlot chartKind={chartKind} message={emptyMessage} />}
          </NFBox>
        )}
      </NFBox>
    </Card>
  );
};

export default UserLabelCountsChart;
