import { useTranslation } from 'react-i18next';

import {
  Chart,
  Partition,
  PartitionLayout,
  PrimitiveValue,
  Settings,
  ShapeTreeNode,
  Tooltip,
} from '@elastic/charts';
import { Box } from '@superb-ai/norwegian-forest';

import CustomTooltip from './components/CustomTooltip';
import { groupLabelStyle, leafLabelStyle, theme } from './style/treemapStyle';
import { AnnotationChangeType, RGBStrings } from './types/types';
import { colorPalettes, hexToRGB } from './utils/colorScale';
import { TreemapDatum } from './utils/formatter';
import {
  changeTypesOrder,
  getChangeTypeDisplayName,
  orderedAnnotationChangeTypes,
} from './utils/header';
import { arrayToLookup } from './utils/helper';

// get annotation change type from index. Unfortunately, cannot pass it directly
const annotationChangeTypeLookUp = arrayToLookup(
  (d: { index: string }) => d.index,
  orderedAnnotationChangeTypes,
);

const getTypeFromIndex = (d: any) => {
  // TODO: refactor if there is a better way
  return changeTypesOrder[Number(d[1].path[2].value)];
};

const AnnotationsChangedTreemap = ({ data }: { data: TreemapDatum[] }): JSX.Element => {
  const { t } = useTranslation();
  const isDataEmpty =
    data.reduce((acc, cv) => {
      return acc + cv?.value;
    }, 0) === 0;
  const discreteColor =
    (categoricalColors: RGBStrings, opacity = 1) =>
    (d: ShapeTreeNode) => {
      return `rgba(${categoricalColors[d.sortIndex % categoricalColors.length]
        .concat([opacity.toString()])
        .join(',')})`;
    };
  const numberOfClassesInEachType = data.length / orderedAnnotationChangeTypes.length;

  return (
    <Box pt={8} px={1} pb={7} height={isDataEmpty ? '300px' : '500px'} width="100%">
      <Chart>
        <Settings showLegend theme={theme} legendMaxDepth={2} />
        <Tooltip placement="top" customTooltip={CustomTooltip} />
        <Partition
          id="treemap"
          data={data}
          layout={PartitionLayout.treemap}
          valueAccessor={(d: { value: number }) => d.value}
          layers={[
            {
              groupByRollup: (d: { index: string }) => d.index,
              nodeLabel: (d: PrimitiveValue) =>
                getChangeTypeDisplayName(
                  annotationChangeTypeLookUp[d as number].name as AnnotationChangeType,
                  t,
                ),
              fillLabel: groupLabelStyle,
              shape: { fillColor: 'rgba(0,0,0,0)' },
            },
            {
              groupByRollup: (d: { className: string }) => d.className,
              fillLabel: leafLabelStyle,
              shape: {
                fillColor: (d: ShapeTreeNode) =>
                  discreteColor(
                    hexToRGB(
                      colorPalettes(
                        getTypeFromIndex(d.parent.children[d.sortIndex]) as AnnotationChangeType,
                        numberOfClassesInEachType - 1,
                      ),
                      numberOfClassesInEachType,
                    ),
                  )(d),
              },
            },
          ]}
        />
      </Chart>
    </Box>
  );
};

export default AnnotationsChangedTreemap;
