import { Box, Checkbox, Label, LinkTypography } from '@superb-ai/ui';

import {
  KRMarketingAgreementURL,
  PrivaryPolicyURL,
  TermsOfUseURL,
} from '../../../consts/ConsentLink';

export default function PolicyKR({
  info,
  setInfo,
}: {
  info: { krMarketingConsentAgreed?: boolean; krNewsletterConsentAgreed?: boolean };
  setInfo: (key: 'krMarketingConsentAgreed' | 'krNewsletterConsentAgreed', value: boolean) => void;
}) {
  const { krMarketingConsentAgreed, krNewsletterConsentAgreed } = info;
  return (
    <Box>
      <Label>
        <Checkbox
          value={!!krMarketingConsentAgreed}
          onClick={() => setInfo('krMarketingConsentAgreed', !krMarketingConsentAgreed)}
        />
        <Box>
          (필수){' '}
          <LinkTypography color="gray-400" as="a" target="_blank" href={TermsOfUseURL}>
            이용 약관
          </LinkTypography>
          ,{' '}
          <LinkTypography color="gray-400" as="a" target="_blank" href={PrivaryPolicyURL}>
            개인 정보 수집 및 이용
          </LinkTypography>
          ,{' '}
          <LinkTypography color="gray-400" as="a" target="_blank" href={KRMarketingAgreementURL}>
            마케팅·홍보 활용 정책
          </LinkTypography>
          에 동의합니다.
        </Box>
      </Label>
      <Label alignItems="flex-start">
        <Checkbox
          value={!!krNewsletterConsentAgreed}
          onClick={() => setInfo('krNewsletterConsentAgreed', !krNewsletterConsentAgreed)}
        />
        <Box mt={0.5}>
          (선택) 슈퍼브에이아이 혜택 및 소식 알람 수신에 동의합니다. <br /> 슈퍼브 소식, 제품 사용
          팁과 사례, AI 인사이트 등을 보내드립니다.
        </Box>
      </Label>
    </Box>
  );
}
