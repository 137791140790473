import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR } from '../../../consts/ModalMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useWindowEventListener } from '../../../hooks/useWindowEventListener';
import TotpCodeInput from '../../elements/auth/TotpCodeInput';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import { useChangeContent } from './Layout';
import MfaProblems from './MfaProblems';
import Title from './Title';

const translatedParams = (params: Record<string, string>) => ({
  session: params?.session,
  tenantId: atob(params?.tenantId),
  email: atob(params?.email),
});

export default function MfaSignInLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [rememberDevice, _setRememberDevice] = useState(false);
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { tenantId, email, session } = translatedParams(routeInfo.params);

  useChangeContent('graphic');

  useEffect(() => {
    if (!tenantId || !email || !session) {
      routeInfo.history.push('/auth/login');
      return;
    }
  }, [tenantId, email, session]);

  const handleClickSignIn = async (): Promise<void> => {
    if (isRequesting || !userCode || userCode.length !== 6) return;

    try {
      setIsRequesting(true);

      await authInfo.loginWithMfa(tenantId, email, session, userCode, rememberDevice);

      const next = new URLSearchParams(location.search).get('next');
      if (next && next.indexOf('/') === 0 && next.indexOf('/auth') === -1) {
        routeInfo.history.push(next);
      } else {
        routeInfo.history.push(`/${tenantId}`);
      }
    } catch (e: any) {
      if (e.message === 'Wrong password!') {
        openModal(
          DEFAULT_ERROR({
            t,
            message: t('errors.anErrorOccurredTryAgain'),
            onClickMainButton: () => {
              closeModal();
              routeInfo.history.push('/auth/login');
            },
          }),
        );
      } else {
        openModal(
          DEFAULT_ERROR({
            t,
            message: e.message,
            onClickMainButton: () => {
              closeModal();
            },
          }),
        );
      }
    } finally {
      setIsRequesting(false);
    }
  };

  useWindowEventListener(
    'keydown',
    e => {
      if (e.key === 'Enter') {
        handleClickSignIn();
      }
    },
    [handleClickSignIn],
  );

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" gap={1.5}>
        <Title>{t('auth.mfa.verify.title')}</Title>
        <Typography variant="m-regular">{t('auth.mfa.verify.enterCode')}</Typography>
        <Box my={4}>
          <TotpCodeInput value={userCode} onValueChange={setUserCode} />
        </Box>
        <Button
          color="primary"
          variant="strong-fill"
          disabled={!userCode || userCode.length !== 6}
          size="l"
          onClick={handleClickSignIn}
        >
          {t('auth.button.signin')}
        </Button>
        <MfaProblems />
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
