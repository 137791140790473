import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { MinusCircle } from '@superb-ai/icons';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

// import { useSnackbar } from 'notistack';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useDiagnosisModelContext } from '../../../../contexts/DiagnosisModelContext';
import {
  useDeactivateDiagnosisMutation,
  useDiagnosisDetailQuery,
} from '../../../../queries/diagnosisModelQueries';
import { DiagnosisDetail } from '../../../../services/DiagnosisModelService';
import { DiagnosisStatusEnum, isDiagnosisActive } from '../modelDiagnosis/diagnosis/types';
import { useDiagnosisPathname } from '../modelDiagnosis/diagnosis/useGetUrl';

export default function DeactivateDiagnosisModal({
  isModalOpen,
  setIsModalOpen,
  diagnosis,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  diagnosis: DiagnosisDetail;
}): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { datasetId } = useParams<{ datasetId: string }>();
  const diagnosisPathName = useDiagnosisPathname();
  const { setQuotaCount } = useDiagnosisModelContext();

  const commandContext = useCurateCommandContext();
  const { mutateAsync: deactivateDiagnosis, isLoading: loadingDeactivation } =
    useDeactivateDiagnosisMutation(commandContext);

  const { refetch: refetchDiagnosisDetail, isLoading: loadingDiagnosisDetail } =
    useDiagnosisDetailQuery({
      diagnosisId: diagnosis.id,
      datasetId,
      diagnosisStatus: diagnosis?.status,
      queryRefetchOptions: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    });

  const handleClickDeactivate = async () => {
    try {
      if (diagnosis) {
        await deactivateDiagnosis({
          datasetId,
          diagnosisId: diagnosis.id,
          modelId: diagnosis.modelId,
          deleteDiagnosis: !diagnosis.modelName,
          // modelName: diagnosis.modelName,
        });
        await refetchDiagnosisDetail();
        history.push(`/${diagnosisPathName}list`);
        setIsModalOpen(false);
        setQuotaCount(prev => prev - 1);
      }
    } catch (err) {}
  };

  const handleCloseButton = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const modelDescription = (
    <Box
      display="flex"
      backgroundColor={'primary-100'}
      alignItems="center"
      justifyContent="flex-start"
      p={1}
      gap={1}
    >
      <Icon icon={MinusCircle} color="primary" size="16px" />
      <Typography variant="m-regular" color="primary">
        {diagnosis.modelName || t('curate.diagnosis.text.modelSourceTag.deleted')}
      </Typography>
    </Box>
  );

  return (
    <Modal
      open={isModalOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.diagnosis.deactivateModal.title')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleClickDeactivate,
        color: 'primary',
        isLoading: loadingDeactivation || loadingDiagnosisDetail,
        disabled: ![
          DiagnosisStatusEnum.ACTIVE,
          DiagnosisStatusEnum.VAL_ACTIVE,
          DiagnosisStatusEnum.ALL_ACTIVE,
        ].includes(diagnosis.status),
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box display="flex" my={2} mx={4} flexDirection="column" gap={2} style={{ width: '360px' }}>
        <Box>{modelDescription}</Box>
        {diagnosis.modelName && (
          <Typography variant="m-regular" color="black">
            {t('curate.diagnosis.deactivateModal.description')}
          </Typography>
        )}
      </Box>
    </Modal>
  );
}
