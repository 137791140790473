import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { PartitionElementEvent, XYChartElementEvent } from '@elastic/charts';
import { Hide, Show } from '@superb-ai/icons';
import { Box, Button, Icon } from '@superb-ai/ui';
import { snakeCase, sumBy } from 'lodash';

import analyticsTracker from '../../../../../../analyticsTracker';
import { getAnnotationTypeColor } from '../../../../../../components/pages/analytics/exportsAnalytics/utils/colorScale';
import { useFeatureFlag } from '../../../../../../contexts/FeatureFlagContext';
import FileUtils from '../../../../../../utils/FileUtils';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../contexts/ImageScopeContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { getSearchQueryRoute } from '../../../../utils/routeUtils';
import { DropdownLegend } from '../views/embedding/scatterView/components/DropdownLegend';
import { getHistogramTheme } from './charts/customTheme';
import { DonutChart } from './charts/DonutChart';
import { Histogram } from './charts/Histogram';
import {
  annotationCountEQQuery,
  annotationCountGTEQuery,
  annotationCountLTEQuery,
  annotationTypeGTQuery,
  appendQuery,
  getCompareMode,
  getLegendTooltip,
  getParititionKey,
  getTextByDataScope,
  getXYChartKey,
} from './charts/utils';
import { ChartCard } from './components/ChartCard';
import { LegendLabel, SingleLegend } from './components/Legends';
import { NoDataToShow } from './components/NoDataToShow';
import { useAnalyticsContext } from './contexts/AnalyticsContext';
import {
  useStatsAnnotationsQuery,
  useStatsAnnotationTypesQuery,
  useStatsClassificationKeyQuery,
  useStatsClassificationValuesAllQuery,
  useStatsClassQuery,
  useStatsComparedClassificationQuery,
  useStatsComparedClassQuery,
} from './queries/annotationQueries';
import { ClassificationTable } from './table/ClassificationTable';
import { ClassStatsTable } from './table/ClassStatsTable';
import { getHeaderWithImages } from './table/getHeader';
import { DataScope } from './types';
import { getChartColor, getSuiteColor } from './utils/colorScale';
import { formatDownloadData, getDownloadFilePrefix } from './utils/download';
import { CompareOption, getCompareOption } from './utils/utils';

export type ChartSpec = 'objects' | 'annotationsPerImage' | 'annotationTypes' | 'classification';

export function AnnotationsContainer() {
  const { datasetId, accountName } = useParams<{ accountName: string; datasetId: string }>();
  const classificationFlag = useFeatureFlag('curateClassification');
  const history = useHistory();
  const { t } = useTranslation();
  const { datasetInfo } = useDatasetContext();
  const { totalCount } = useImageScopeContext();
  const { queryString, setQueryString, queryStringAndHiddenFilterQueryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { appliedFilters, isFilterOpen } = useImageFilterContext();
  const currentPageSet = sliceInfo ? 'slice' : 'dataset';
  const { isCompareMode, setIsCompareMode, selectedCompareSetId, setSelectedCompareSetId } =
    useAnalyticsContext();
  const selectionName = (currentPageSet === 'dataset' ? datasetInfo?.name : sliceInfo?.name) || '';
  const showCompareMode = getCompareMode(currentPageSet, queryString);
  const [showZeroAnnotations, setShowZeroAnnotations] = useState<boolean>(true);

  const dependencies = {
    datasetId: datasetId,
    fromPublicDatasets: showPublicDatasets,
    ...(currentPageSet === 'slice' && { sliceId: sliceInfo?.id, slice: sliceInfo?.name }),
    queryString: queryStringAndHiddenFilterQueryString,
    totalCount: totalCount || 0,
    appliedFilters,
  };

  const trackChartCompare = useCallback(
    (target: string, interaction: 'click' | 'select-dropdown') => {
      analyticsTracker.curateDataCompared({
        accountId: accountName,
        sliceId: sliceInfo?.id,
        chartName: 'class-distribution',
        comparisonTarget: target.toLowerCase() as 'slice' | 'dataset',
        interactionType: interaction,
        appliedFilters: appliedFilters,
        queryString: queryStringAndHiddenFilterQueryString,
      });
    },
    [accountName, appliedFilters, queryStringAndHiddenFilterQueryString, sliceInfo?.id],
  );
  const comparedParams = { isCompareMode, compareSetId: selectedCompareSetId };
  const {
    isFetching: isFetchingClassStats,
    data: selectionClassStats = { data: [], totalImageCount: 0 },
  } = useStatsClassQuery({ ...dependencies, appliedFilters });

  const {
    isFetching: isFetchingComparedClassStats,
    data: comparedClassStats = { data: [], totalImageCount: 0 },
  } = useStatsComparedClassQuery({
    ...dependencies,
    ...comparedParams,
  });
  const { isFetching: isFetchingAnnotationTypeStats, data: annotationTypeStats = [] } =
    useStatsAnnotationTypesQuery({ ...dependencies, appliedFilters });
  const { isFetching: isFetchingAnnotationStats, data: annotationStats = [] } =
    useStatsAnnotationsQuery({ ...dependencies, appliedFilters });

  const {
    isFetching: isFetchingClassificationKey,
    data: classificationKeyList = { data: [], totalImageCount: 0 },
  } = useStatsClassificationKeyQuery(dependencies);

  const {
    isFetching: isFetchingComparedClassificationKey,
    data: comparedClassificationKeyList = { data: [], totalImageCount: 0 },
  } = useStatsComparedClassificationQuery({
    ...dependencies,
    ...comparedParams,
  });

  const { data: classificationValuesAll, refetch } =
    useStatsClassificationValuesAllQuery(dependencies);

  const handleDownload = (data: any, chartSpec: ChartSpec) => {
    if (chartSpec === 'classification') {
      refetch().then(() => {
        const formatted = formatDownloadData(classificationValuesAll?.data || [], chartSpec);
        FileUtils.exportToCsv(
          formatted as Record<string, any>[],
          null,
          getDownloadFilePrefix(currentPageSet, selectionName, snakeCase(chartSpec), 'csv'),
        );
      });
      return;
    }
    const formatted = formatDownloadData(data, chartSpec);
    FileUtils.exportToCsv(
      formatted as Record<string, any>[],
      null,
      getDownloadFilePrefix(currentPageSet, selectionName, snakeCase(chartSpec), 'csv'),
    );
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: chartSpec,
      feature: 'curate-analytics',
    });
  };

  const selectionLegend = {
    key: 'objects',
    legendText: getTextByDataScope(
      t,
      currentPageSet,
      selectionName,
      queryStringAndHiddenFilterQueryString,
    ),
    hexColor: getChartColor('objects' as DataScope),
    textColor: getSuiteColor('objects' as DataScope),
    visible: true,
  } as LegendLabel;

  const comparedLegend = {
    legendText: getTextByDataScope(t, currentPageSet, selectionName),
    hexColor: getChartColor('objectsAll' as DataScope),
    textColor: getSuiteColor('objectsAll' as DataScope),
    visible: isCompareMode,
    setVisibility: setIsCompareMode,
  } as LegendLabel;

  const compareOptions = [
    { group: 'Slice', id: sliceInfo?.id, name: sliceInfo?.name },
    { group: 'Dataset', id: datasetInfo?.id, name: datasetInfo?.name },
  ] as CompareOption[];

  const getComparedGroup = (id: string) => {
    return getCompareOption(compareOptions, id)?.group ?? 'Dataset';
  };

  const getComparedLegend = () => {
    if (currentPageSet === 'dataset') {
      return (
        <SingleLegend
          tooltipContent={t(`curate.data.${currentPageSet}`)}
          legendLabel={comparedLegend}
          setCompareSetId={setSelectedCompareSetId}
          compareSetId={selectedCompareSetId}
          trackSelectEvent={() => trackChartCompare('dataset', 'click')}
        />
      );
    }
    if (currentPageSet === 'slice') {
      const dropdownLegend = (
        <DropdownLegend
          legend={{
            color: getChartColor(currentPageSet as DataScope),
            selectedOptionColor: getSuiteColor(currentPageSet as DataScope),
            isChecked: isCompareMode,
            handleClickLabel: () => {
              const newMode = !isCompareMode;
              const compareTarget = getComparedGroup(selectedCompareSetId);
              setIsCompareMode(newMode);
              if (newMode === true) {
                trackChartCompare(compareTarget, 'click');
              }
            },
          }}
          options={[
            { group: 'Slice', id: sliceInfo?.id, name: sliceInfo?.name },
            { group: 'Dataset', id: datasetInfo?.id, name: datasetInfo?.name },
          ]}
          selectedOptionId={selectedCompareSetId}
          handleSelectOption={(newId: string) => {
            if (newId !== selectedCompareSetId) {
              !isCompareMode && setIsCompareMode(true);
              setSelectedCompareSetId(newId);
              const compareTarget = getComparedGroup(newId);
              trackChartCompare(compareTarget, 'select-dropdown');
            }
          }}
        />
      );
      return queryStringAndHiddenFilterQueryString ? (
        dropdownLegend
      ) : (
        <SingleLegend
          tooltipContent={t('curate.analytics.dataComparison.compareWithDataset')}
          legendLabel={{ ...comparedLegend, legendText: datasetInfo?.name || '' }}
          setCompareSetId={setSelectedCompareSetId}
          compareSetId={datasetInfo?.id || ''}
          trackSelectEvent={() => trackChartCompare('dataset', 'click')}
        />
      );
    }
    return null;
  };

  const hasZeroAnnotations = annotationStats.some(obj => obj.key === 0);
  const classStatsHeaders = getHeaderWithImages(t);
  const loadingStatsTable = isFetchingClassStats || isFetchingComparedClassStats; // || isFetchingClassificationKey;
  const classTable = (
    <ClassStatsTable
      key="objectClass"
      isCompareMode={isCompareMode}
      dataScope={currentPageSet as DataScope}
      selection={{
        data: selectionClassStats?.data,
        totalAnnotations: sumBy(selectionClassStats?.data, 'annotationCount'),
        totalImages: selectionClassStats?.totalImageCount,
        name: selectionLegend.legendText,
      }}
      compared={{
        data: comparedClassStats?.data,
        totalAnnotations: sumBy(comparedClassStats?.data, 'annotationCount'),
        totalImages: comparedClassStats?.totalImageCount,
        name: getComparedGroup(selectedCompareSetId),
      }}
      headers={classStatsHeaders}
    />
  );
  const classificationTable = (
    <ClassificationTable
      key="classification"
      isCompareMode={isCompareMode}
      dataScope={currentPageSet as DataScope}
      selection={{
        data: classificationKeyList?.data,
        totalImages: classificationKeyList?.totalImageCount,
        name: selectionLegend.legendText,
      }}
      compared={{
        data: comparedClassificationKeyList?.data,
        totalImages: comparedClassificationKeyList?.totalImageCount,
        name: getComparedGroup(selectedCompareSetId),
      }}
    />
  );
  const chartLegend = (
    <Box display="flex" flexDirection={'row'} gap={1}>
      <SingleLegend
        isDisabled
        tooltipContent={getLegendTooltip(t, queryStringAndHiddenFilterQueryString, currentPageSet)}
        legendLabel={selectionLegend}
      />
      {showCompareMode && getComparedLegend()}
    </Box>
  );

  const trackChartClicked = (chartName: string) => {
    analyticsTracker.chartClicked({
      accountId: accountName,
      chartName,
      feature: 'curate-analytics',
    });
  };
  return (
    <Box
      display="flex"
      gap={1}
      width="100%"
      overflow="auto"
      flexDirection={isFilterOpen ? 'column' : 'row'}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        style={{
          width: isFilterOpen ? '100%' : '400px',
          order: isFilterOpen ? 2 : 0,
          height: '600px',
        }}
      >
        <ChartCard
          style={{ height: '50%' }}
          isLoading={isFetchingAnnotationStats}
          chartTopRightArea={
            <Box
              display="flex"
              position="absolute"
              justifyContent="flex-end"
              backgroundColor={'white'}
              right="0"
              mr={1}
              onClick={() => setShowZeroAnnotations(prevState => !prevState)}
            >
              <Button
                size="m"
                variant="text"
                color="gray"
                disabled={!annotationStats.some(obj => obj.key === 0)}
              >
                <Icon icon={showZeroAnnotations && hasZeroAnnotations ? Show : Hide} />
                {t('curate.analytics.action.noAnnotations')}
              </Button>
            </Box>
          }
          chartSpec="annotationsPerImage"
          chartTitle={t('curate.analytics.annotationsPerImage.title')}
          chartComponent={
            <Histogram
              data={
                showZeroAnnotations ? annotationStats : annotationStats.filter(d => d.key !== 0)
              }
              color={getChartColor('annotationsPerImage')}
              theme={getHistogramTheme(getChartColor('annotationsPerImage'))}
              config={{
                tooltipHeader: t('curate.analytics.text.imageCount'),
                axes: {
                  xAxisTitle: t('curate.analytics.annotationsPerImage.count'),
                  yAxisTitle: t('curate.analytics.text.imageCount'),
                },
                series: {
                  seriesId: 'count',
                  xAccessor: 'keyNumeric',
                  yAccessor: 'count',
                },
              }}
              onElementListeners={{
                onBrushEnd: (brushEvent: any) => {
                  const query: string = appendQuery(
                    [
                      annotationCountGTEQuery(brushEvent?.x[0]),
                      annotationCountLTEQuery(brushEvent?.x[1]),
                    ],
                    queryString,
                  );
                  setQueryString(query);
                  history.push(getSearchQueryRoute(history, { query }));
                  trackChartClicked('annotations-per-image');
                },
                onElementClick: (events: XYChartElementEvent[]): void => {
                  const query = appendQuery(
                    annotationCountEQQuery(Number(getXYChartKey(events))),
                    queryString,
                  );
                  setQueryString(query);
                  history.push(getSearchQueryRoute(history, { query }));
                  trackChartClicked('annotations-per-image');
                },
              }}
            />
          }
          data={annotationStats}
          handleDownload={handleDownload}
        />
        <ChartCard
          style={{ height: '50%' }}
          data={annotationTypeStats}
          chartSpec="annotationTypes"
          chartTitle={t('curate.analytics.annotationType.title')}
          isLoading={isFetchingAnnotationTypeStats}
          chartComponent={
            annotationTypeStats.length > 0 ? (
              <DonutChart
                data={annotationTypeStats}
                getColor={getAnnotationTypeColor}
                onElementListeners={{
                  onElementClick: (events: Array<PartitionElementEvent>): void => {
                    const query = appendQuery(
                      annotationTypeGTQuery(getParititionKey(events), 0),
                      queryString,
                    );
                    setQueryString(query);
                    history.push(getSearchQueryRoute(history, { query }));
                    trackChartClicked('annotation-types');
                  },
                }}
              />
            ) : (
              <NoDataToShow />
            )
          }
          handleDownload={handleDownload}
        />
      </Box>
      <Box style={{ flex: 1, order: 1 }}>
        {/* {classificationFlag && (
          <ChartCardWithTabs
            style={{ height: '100%' }}
            isLoading={loadingStatsTable}
            chartSpec="objects"
            chartComponent={{
              objectClass: classTable,
              classification: classificationTable,
            }}
            chartLegend={chartLegend}
            data={{
              objectClass: selectionClassStats?.data,
              classification: classificationKeyList?.data,
            }}
            handleDownload={handleDownload}
          />
        )} */}
        {/* {!classificationFlag && ( */}
        <ChartCard
          style={{ height: '100%', maxHeight: '600px', minHeight: '200px' }}
          isLoading={isFetchingClassStats || isFetchingComparedClassStats}
          chartSpec="objects"
          chartTitle={t('curate.analytics.classStats.title')}
          chartComponent={classTable}
          chartLegend={chartLegend}
          data={selectionClassStats?.data}
          handleDownload={handleDownload}
        />
        {/* )} */}
      </Box>
    </Box>
  );
}
