import React from 'react';
import { Trans } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';

type Props = {
  format?: 'decimal' | 'percent';
  current: number;
  total?: number;
  changes?: number;
  isPercent?: boolean;
};

const substringStyle = {
  fontFamily: 'Inter, sans-serif',
  fontSize: '10px',
  leftMargin: 0,
  lineHeight: 1.5,
  fontWeight: 400,
};

const NumberTracker: React.FC<Props> = ({ current, total, format = 'decimal', changes }) => {
  const formatNum = (num: number) => {
    if (format === 'percent') return `${num}%`;
    return num.toLocaleString('en');
  };

  const getFormattedChanges = (num: number) => {
    if (num >= 0)
      return (
        <Typography variant="body5" themedColor="green">
          ↑{formatNum(num)}
        </Typography>
      );
    if (num < 0)
      return (
        <Typography variant="body5" themedColor="primary">
          ↓{formatNum(Math.abs(num))}
        </Typography>
      );
  };
  const part = formatNum(current || 0);
  const whole = total !== undefined ? formatNum(total) : total;
  return (
    <Box display="flex" alignItems="flex-end" style={{ 'white-space': 'nowrap' }}>
      {whole === undefined && <Typography variant="headline3">{part}</Typography>}
      {whole !== undefined && (
        <Box display={'inline'}>
          <Typography variant="headline3" white-space="nowrap">
            <Trans i18nKey="analytics.qa.text.out-of" part={part} whole={whole}>
              {{ part }}
              <Box display={'inline'} style={substringStyle}>
                out of {{ whole }}
              </Box>
            </Trans>
          </Typography>
        </Box>
      )}
      {changes !== undefined && (
        <Box ml={0.5} mb={0.5}>
          {getFormattedChanges(changes)}
        </Box>
      )}
    </Box>
  );
};

export default NumberTracker;
