import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';

const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    position: 'absolute',
    zIndex: 30,
    top: '180px',
    left: '0',
    transition: '0.4s',
    easing: 'ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.visible': {
      visibility: 'visible',
      opacity: '0.8',
    },
    '&.hidden': {
      visibility: 'hidden',
      opacity: '0',
    },
  },
  textBox: {
    color: '#fff',
    background: '#000',
    borderRadius: '6px',
    padding: '16px 32px',
  },
}));

const DisabledAlert = (): React.ReactElement => {
  const classes = useStyles();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const [timeEvent, setTimeEvent] = useState<any>(null);
  const { isDisabledAlertActive, setIsDisabledAlertActive } = labelDetailViewInfo;

  useEffect(() => {
    if (isDisabledAlertActive) {
      const timer = setTimeout(() => {
        setIsDisabledAlertActive(false);
      }, 1000);

      setTimeEvent(timer);
    } else {
      clearTimeout(timeEvent);
    }

    // eslint-disable-next-line
  }, [isDisabledAlertActive]);

  return (
    <MUI.Box className={`${classes.box} ${isDisabledAlertActive ? 'visible' : 'hidden'}`}>
      <MUI.Box className={classes.textBox}>
        <MUI.Typography variant="subtitle1">Hold space to move image</MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
};

export default DisabledAlert;
