import React, { useEffect, useRef } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NotificationIcon } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import NotificationsContext from '../../../../contexts/NotificationsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import Notifications from './Notifications';

const useStyles = makeStyles(() => ({
  box: {
    background: '#fff',
    borderRadius: '7px',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },
}));

const NotificationMenu: React.FC = () => {
  const classes = useStyles();
  const notificationsInfo = React.useContext(NotificationsContext.Context);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const { isOpen, setIsOpen, unreadCount, hasNew, setHasNew } = notificationsInfo;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [routeInfo]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setHasNew(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (authInfo.isGuest) return <></>;

  return (
    <MUI.Box position="relative">
      <MUI.IconButton color="inherit" onClick={handleToggle} ref={buttonRef}>
        <NotificationIcon count={unreadCount} color="primary" hasNew={hasNew} />
      </MUI.IconButton>
      <MUI.Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MUI.Box className={classes.box} boxShadow={1}>
          <Notifications />
        </MUI.Box>
      </MUI.Popover>
    </MUI.Box>
  );
};

export default NotificationMenu;
