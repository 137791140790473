import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  COLOR_CHIP_TAG_30PERCENT_OPACITY,
  COLOR_CHIP_TAG_60PERCENT_OPACITY,
  pseudoRandomColor,
} from '../../consts/ColorChips';

const useStyles = (props: { color: string; hoverColor: string }) =>
  makeStyles(() => ({
    chip: {
      backgroundColor: props.color,
      color: '#333',
      padding: '4px',
      height: 'initial',
      minWidth: '24px',
      fontSize: '11px',
      fontWeight: 500,
      textAlign: 'center',
      margin: '3px',
      borderRadius: '7px',
      '& .MuiChip-label': {
        width: 'fit-content',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:hover': {
        backgroundColor: props.hoverColor,
      },
    },
  }));

interface Props {
  projectId: string;
  projectName: string;
  onClickHandler: (event: React.MouseEvent) => void;
}

const ProjectChip: React.FC<Props> = ({ projectId, projectName, onClickHandler }) => {
  const classes = useStyles({
    color: pseudoRandomColor(projectName, COLOR_CHIP_TAG_30PERCENT_OPACITY),
    hoverColor: pseudoRandomColor(projectName, COLOR_CHIP_TAG_60PERCENT_OPACITY),
  })();
  return (
    <MUI.Chip
      key={projectId}
      className={classes.chip}
      label={projectName}
      onClick={onClickHandler}
    />
  );
};

export default ProjectChip;
