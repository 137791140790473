import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import ClassPropertyAnnotationCountRange from './ClassPropertyAnnotationCountRange';
import { ClassPropertyDefaultSize3DCuboid } from './ClassPropertyDefaultSize3DCuboid';
import ClassPropertyItem from './ClassPropertyItem';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '630px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
  listContainerStyles: {
    height: '580px',
  },
}));

// eslint-disable-next-line
const ClassPropertyList = (props: any): React.ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const listRef = useRef<HTMLDivElement>();
  const newProjectInfo = useNewProjectInfo();
  const { selectedObjectClass } = props;
  const classProperties = selectedObjectClass ? selectedObjectClass.properties : [];
  const useExperimentalLidar = useFeatureFlag('experimentalLidar');

  const useLidarCubeDefaultSize =
    newProjectInfo.selectedDataType === 'pointclouds' ||
    selectedObjectClass.annotationType === 'CUBOID';
  const [isPressDelete, setIsPressDelete] = useState(false);
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedClassProperty = () => {
    helper.deleteClassProperty(newProjectInfo);
  };

  useEffect(() => {
    if (!isPressDelete) return;
    setIsPressDelete(false);

    deleteSelectedClassProperty();
    // eslint-disable-next-line
  }, [isPressDelete]);

  useEffect(() => {
    newProjectInfo.setSelectedClassPropertyIndex(-1);
    // eslint-disable-next-line
  }, [newProjectInfo.selectedObjectClassIndex]);

  const handleClickList = () => {
    newProjectInfo.setSelectedClassPropertyIndex(-1);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClickAdd = () => {
    helper.createClassProperty(newProjectInfo);
    scrollToBottom();
  };
  const handleClickCopy = () => {
    helper.copyClassProperty(newProjectInfo);
  };
  const handleClickDelete = () => {
    deleteSelectedClassProperty();
  };

  if (!selectedObjectClass) return null;

  return (
    <MUI.Box className={classes.box}>
      <ListContainer
        useHeader
        title={t('projectSettings.configuration.classProperty')}
        disabledStrings={
          classProperties.length === 0
            ? [t('projectSettings.configuration.classPropertyClickHere')]
            : []
        }
        handleClickDisabledBox={handleClickAdd}
        handleClickAdd={handleClickAdd}
        handleClickCopy={handleClickCopy}
        handleClickDelete={handleClickDelete}
        styles={classes.listContainerStyles}
      >
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox} onClick={handleClickList}>
          {classProperties.map((classProperty: any, index: number) => (
            <ClassPropertyItem
              key={`${selectedObjectClass.id}/${classProperty.id}`}
              classProperty={classProperty}
              index={index}
            />
          ))}
        </MUI.Box>
      </ListContainer>
      {useExperimentalLidar && useLidarCubeDefaultSize && (
        <>
          <MUI.Box height="16px" />
          <ClassPropertyDefaultSize3DCuboid selectedObjectClass={selectedObjectClass} />
        </>
      )}
      <MUI.Box height="16px" />
      <ClassPropertyAnnotationCountRange selectedObjectClass={selectedObjectClass} />
    </MUI.Box>
  );
};

export default ClassPropertyList;
