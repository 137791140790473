import React, { useEffect } from 'react';

import * as MUI from '@mui/material';
import { Box } from '@superb-ai/ui';
import { find, isUndefined } from 'lodash';

import DatePicker from '../../../../elements/datePicker/DatePicker';
import { DateRangePicker } from '../../../../elements/datePicker/DateRangePicker';
import { FilterProps } from './types';

type Value = { date?: Date | null; sdate?: Date | null; edate?: Date | null }[];

const FilterDate: React.FC<FilterProps<Value>> = props => {
  const { index, objKey, value, condition, onValueChange, onChangeCurrentRow, isCurrentRow } =
    props;

  useEffect(() => {
    if (condition === 'is') {
      if (find(value, 'date')) return;
      onValueChange(index, objKey, [{ date: new Date() }]);
    } else {
      if (find(value, 'sdate')) return;
      onValueChange(index, objKey, [{ sdate: new Date() }, { edate: new Date() }]);
    }

    // eslint-disable-next-line
  }, [condition]);

  const validateDates = (index: number, objKey: string, value: Value) => {
    const startDate = new Date(value[0].sdate || 0);
    const endDate = new Date(value[1].edate || 0);

    if (isUndefined(value[1].edate) || endDate < startDate) {
      // eslint-disable-next-line no-param-reassign
      value[1].edate = value[0].sdate;
    }
    // return and not call api when value is 'Invalid Date'
    if (Number.isNaN(startDate.getTime()) || Number.isNaN(endDate.getTime())) return;

    onValueChange(index, objKey, value);
  };

  const validateDate = (index: number, objKey: string, value: Value) => {
    // return and not call api when value is 'Invalid Date'
    if (Number.isNaN(new Date(value[0].date || 0).getTime())) return;

    onValueChange(index, objKey, value);
  };

  return (
    <Box
      borderColor="red-400"
      border="1px solid"
      style={{ height: 32 }}
      display="flex"
      alignItems="center"
    >
      {condition === 'is' && (
        <MUI.Grid item>
          <DatePicker
            value={value[0]?.date ?? new Date()}
            onOpen={() => onChangeCurrentRow(index)}
            onChange={date => validateDate(index, objKey, [{ date }])}
            activeStyleEnabled={isCurrentRow}
          />
        </MUI.Grid>
      )}
      {condition === 'is in the range' && (
        <MUI.Grid item>
          <DateRangePicker
            onOpen={() => onChangeCurrentRow(index)}
            value={[
              new Date(value[0]?.sdate ?? new Date()),
              new Date(value[1]?.edate ?? new Date()),
            ]}
            onChange={date =>
              validateDates(index, objKey, [{ sdate: date?.[0] }, { edate: date?.[1] }])
            }
            activeStyleEnabled={isCurrentRow}
            timeEnabled
          />
        </MUI.Grid>
      )}
    </Box>
  );
};

export default FilterDate;
