import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { makeStyles } from '@mui/styles';
import { CheckFilled, Clear, ClearFilled, ErrorFilled, WarningFilled } from '@superb-ai/icons';
import { ProgressBar } from '@superb-ai/norwegian-forest';
import { Box, Icon, IconButton, LoadingIndicator, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';

import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { formatDateTime, formatDistance } from '../../../../utils/date';
import {
  Command,
  CommandStatus,
  CommandText,
  CommandType,
  estimateTimeLeft,
  FailReason,
  getCommandPage,
  getErrorMessage,
  InProgressStatuses,
  isCommandCancellable,
} from '../../../../utils/LabelCommandUtils';
import { formatCount } from '../../../../utils/numberFormat';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';

interface Props {
  items: Command[];
}

function getMainIcon(status: CommandStatus) {
  if (InProgressStatuses.includes(status)) {
    return <LoadingIndicator size="xs" type="spinner-small" color="red" />;
  }
  if (status === 'FINISHED') {
    return <Icon icon={CheckFilled} color="green" />;
  }
  return <Icon icon={ErrorFilled} color="yellow" />;
}

function getDetailIcon(status: CommandStatus) {
  // Never show two success icons
  //if (status === 'FINISHED') {
  //  return <Icon name="checkFilled" color="green" />;
  //}
  if (status === 'FAILED') {
    return <Icon icon={WarningFilled} color="red" />;
  }
  if (status === 'CANCELED') {
    return <Icon icon={ClearFilled} color="cloud" />;
  }
  return <Icon icon={ErrorFilled} color="yellow" />;
}

function timeLeft(command: Command, t: TFunction) {
  if (!InProgressStatuses.includes(command.status)) {
    return '';
  }
  const estimate = estimateTimeLeft(command);
  if (estimate) {
    return t('command.timeLeft', { time: formatDistance(estimate, false) });
  }
  return '';
}

const useStyles = makeStyles({
  errorList: {
    margin: '0 0 0 1.5em',
    listStyle: 'none',
    padding: 0,
    fontSize: '10px',
    textIndent: '-1.5em',
    '& li': {
      '&::before': {
        content: '"•"',
        marginRight: '4px',
      },
    },
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  countList: {
    borderLeft: '3px solid #E4E4E4',
    paddingLeft: 4,
  },
});

function ErrorList({
  items,
  commandType,
}: {
  items: (FailReason | string)[];
  commandType: CommandType;
}): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box backgroundColor="primary-100" p={1}>
      <ul className={classes.errorList}>
        {items.map((item, index) => (
          <li key={index}>{getErrorMessage(t, commandType, item)}</li>
        ))}
      </ul>
    </Box>
  );
}

export default function CommandProgressList({ items }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const commandContext = useLabelCommandContext();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();

  return (
    <Box>
      {items.map(command => {
        const showProgress = InProgressStatuses.includes(command.status);
        const failReasons: FailReason[] = command.result?.failReasons ?? [];
        const showErrorList = failReasons.length > 0;
        const failCount =
          command.result?.failCount ?? failReasons.reduce((a, r) => a + r.count ?? 0, 0);
        const showDetailCounts =
          !showProgress &&
          ((command.status !== 'FINISHED' && command.totalCount !== command.progress) ||
            failCount > 0);
        const successCount =
          command.result?.successCount ?? command.totalCount - failCount ?? command.progress;

        const page = getCommandPage(command);
        const url = page ? `/${accountName}/label/${page}` : '';

        function handleClick() {
          if (url) {
            history.push(url, new Date());
            commandContext.setIsModalVisible(false);
          }
        }

        function handleCancel(e: React.MouseEvent) {
          e.stopPropagation();
          commandContext.cancelCommand(command);
        }

        const commandText = <CommandText command={command} />;

        return (
          <Box
            borderBottom="1px solid"
            borderColor="gray-100"
            p={1.5}
            key={command.id}
            onClick={handleClick}
            className={url ? classes.clickable : ''}
          >
            {command.project && <Typography variant="s-strong">{command.project.name}</Typography>}
            <Box display="flex" alignItems="center" mt={0.5} mb={0.5} gap={0.5}>
              {getMainIcon(command.status)}
              <TextEllipsisTooltip text={commandText} minWidth={0}>
                <Typography variant="m-regular">{commandText}</Typography>
              </TextEllipsisTooltip>
              <Box ml="auto" display="flex" alignItems="center" gap={1}>
                <Typography variant="s-regular">
                  <span style={{ whiteSpace: 'nowrap' }}>{timeLeft(command, t)}</span>
                </Typography>
                {isCommandCancellable(command) && (
                  <IconButton icon={Clear} color="gray" size="xs" onClick={handleCancel} />
                )}
              </Box>
            </Box>

            {showDetailCounts && (
              <Box
                mt={1}
                mb={1}
                className={classes.countList}
                display="grid"
                gap={1}
                style={{ gridTemplateColumns: '16px auto', marginLeft: '18px' }}
              >
                {successCount > 0 && (
                  <>
                    <Icon icon={CheckFilled} color="green" />
                    <Typography variant="s-regular">
                      {t('command.processed')}: {formatCount(successCount)}
                    </Typography>
                  </>
                )}
                {getDetailIcon(command.status)}
                <Typography variant="s-regular">
                  {startCase(
                    (command.status !== 'FINISHED' ? command.status : 'SKIPPED').toLowerCase(),
                  )}
                  : {formatCount(failCount)}
                </Typography>
              </Box>
            )}

            {showErrorList && (
              <Box mt={1} mb={1} style={{ marginLeft: '18px' }}>
                <ErrorList items={command.result.failReasons} commandType={command.type} />
              </Box>
            )}

            {showProgress && (
              <Box style={{ marginLeft: '18px' }}>
                <ProgressBar
                  tooltip
                  height={8}
                  max={command.totalCount || 1}
                  value={command.progress}
                  status={command.status.toLowerCase() as Lowercase<CommandStatus>}
                />
              </Box>
            )}

            <Box
              mt={0.5}
              display="flex"
              justifyContent="space-between"
              style={{ marginLeft: '18px' }}
            >
              <Typography variant="m-regular" color="gray-300">
                {command.createdBy} ·{' '}
                <span title={formatDateTime(command.createdAt)}>
                  {formatDistance(command.createdAt)}
                </span>
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
