import { TFunction } from 'react-i18next';

import { trimStart } from 'lodash';

import {
  IS_INVALID,
  IS_VALID_LENGTH_SHORT,
  PASSWORDS_MUST_MATCH,
  REQUIRED,
} from '../../../../consts/AuthMessage';
import RegexUtils from '../../../../utils/RegexUtils';

const handleChangeCreatePassword =
  (t: TFunction) =>
  ({
    value,
    setPassword,
    passwordErrorMessage,
    setPasswordErrorMessage,
  }: {
    value: string;
    setPassword: (password: string) => void;
    passwordErrorMessage: string;
    setPasswordErrorMessage: (message: string) => void;
  }): boolean => {
    let isError = false;
    setPassword(value);

    if (
      !RegexUtils.IS_LOWER(value) ||
      !RegexUtils.IS_UPPER(value) ||
      !RegexUtils.IS_NUMERIC(value) ||
      !RegexUtils.IS_SPECIAL(value) ||
      value.length < 12
    ) {
      setPasswordErrorMessage(IS_INVALID({ t, name: 'Password' }));
      isError = true;
    } else if (passwordErrorMessage) {
      setPasswordErrorMessage('');
    }

    return isError;
  };

const handleChangeConfirmPassword =
  (t: TFunction) =>
  ({
    password,
    confirmPassword,
    setConfirmPassword,
    confirmPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
  }: {
    password: string;
    confirmPassword: string;
    setConfirmPassword: (password: string) => void;
    confirmPasswordErrorMessage: string;
    setConfirmPasswordErrorMessage: (message: string) => void;
  }): boolean => {
    let isError = false;

    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage(PASSWORDS_MUST_MATCH({ t }));
      isError = true;
    } else if (confirmPasswordErrorMessage) {
      setConfirmPasswordErrorMessage('');
    }
    return isError;
  };

const handleChangeName = ({
  value,
  setName,
  nameErrorMessage,
  setNameErrorMessage,
}: {
  value: string;
  setName: (name: string) => void;
  nameErrorMessage: string;
  setNameErrorMessage: (message: string) => void;
}): string => {
  const trimValue = trimStart(value);
  setName(trimValue);

  if (setNameErrorMessage) {
    if (nameErrorMessage) {
      setNameErrorMessage('');
    }
  }

  return trimValue;
};

const isEmptyField =
  (t: TFunction) =>
  ({
    value,
    setErrorMessage,
    inputRef,
  }: {
    value: string;
    setErrorMessage: (message: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
  }): boolean => {
    if (value === '') {
      setErrorMessage(REQUIRED({ t }));

      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }

      return true;
    }
    return false;
  };

const isValidLength =
  (t: TFunction) =>
  ({
    value,
    min,
    max,
    setErrorMessage,
    inputRef,
  }: {
    value: string;
    min: number;
    max: number;
    setErrorMessage: (message: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
  }): boolean => {
    const { length } = value;
    if (length >= min && length <= max) {
      return true;
    }
    setErrorMessage(IS_VALID_LENGTH_SHORT({ t, min, max }));

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    return false;
  };

const hasErrorMessage = ({
  errorMessage,
  inputRef,
}: {
  errorMessage: string;
  inputRef: React.RefObject<HTMLInputElement>;
}): boolean => {
  if (errorMessage) {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    return true;
  }
  return false;
};

export default {
  handleChangeCreatePassword,
  handleChangeConfirmPassword,
  handleChangeName,
  isEmptyField,
  isValidLength,
  hasErrorMessage,
};
