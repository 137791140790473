import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AutoCurate, LoadingSpinnerSmall } from '@superb-ai/icons';
import { Icon, IconButton, Tooltip } from '@superb-ai/ui';

import { useActionContext } from '../../../../contexts/ActionContext';
import { useEmbeddingMonitoringContext } from '../../../../contexts/EmbeddingMonitoringContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../../elements/SampleDatasetTooltip';
import AutoCurateModal from '../modal/AutoCurateModal';

export default function AutoCurationButton({}: {}) {
  const { t } = useTranslation();
  const [curationModalIsOpen, setCurationModalIsOpen] = useState(false);
  const { canUseEmbeddingFeatures } = useEmbeddingMonitoringContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const { selectedData, deselectedData, setSelectedAllData } = useActionContext();

  function TooltipMessage() {
    if (showPublicDatasets) {
      return (
        <>
          {t('curate.button.autoCurate')} <br /> <SampleDatasetTooltip feature="autoCurate" />
        </>
      );
    }
    if (!canUseEmbeddingFeatures) {
      return (
        <>
          {t('curate.button.autoCurate')} <br /> <Icon icon={LoadingSpinnerSmall} color="primary" />{' '}
          {t('curate.embeddingMonitoring.tooltip')}
        </>
      );
    }
    if (selectedData.length !== 0 || deselectedData.length !== 0) {
      return (
        <>
          {t('curate.button.autoCurate')} <br />
          <Trans t={t} i18nKey={'curate.autoCurate.onlyEntire'} />
        </>
      );
    }
    return t('curate.button.autoCurate');
  }

  return (
    <>
      <Tooltip content={TooltipMessage()} placement="top" hideOnEmptyContent>
        <span>
          <IconButton
            icon={AutoCurate}
            disabled={selectedData.length !== 0 || !canUseEmbeddingFeatures || showPublicDatasets}
            variant="text"
            color="gray"
            onClick={() => {
              setCurationModalIsOpen(true);
              setSelectedAllData(true);
            }}
          />
        </span>
      </Tooltip>
      {curationModalIsOpen && (
        <AutoCurateModal
          curationModalIsOpen={curationModalIsOpen}
          setCurationModalIsOpen={setCurationModalIsOpen}
        />
      )}
    </>
  );
}
