import { cloneDeep } from 'lodash';

export const removeEmptyQueryKeyProjectList = (obj: Record<string, any>) => {
  const newObj = cloneDeep(obj);
  Object.keys(newObj).forEach(key => {
    if (
      newObj[key] === undefined ||
      (key === 'nameIcontains' && newObj[key] === '') ||
      (key === 'page' && newObj[key] === 1) ||
      (key === 'pageSize' && newObj[key] === 10) ||
      key === 'origin'
    )
      delete newObj[key];
  });
  return newObj;
};
