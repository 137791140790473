import { Box, Checkbox, Typography } from '@superb-ai/ui';

import ResetChip from './components/ResetChip';

export function CategoryCheckbox(props: {
  label: string;
  state: boolean | 'mixed';
  onClick: () => void;
  disabled?: boolean;
  appliedCount: number;
  handleReset: () => void;
}) {
  const { label, state, onClick, disabled, appliedCount, handleReset } = props;
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Checkbox disabled={disabled} value={state} onClick={onClick} />
      <Typography variant="m-strong">{label}</Typography>
      {appliedCount > 0 && (
        <ResetChip
          style={{ marginLeft: 'auto' }}
          count={appliedCount}
          onClickResetChip={handleReset}
        />
      )}
    </Box>
  );
}
