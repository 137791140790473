/* eslint-disable react/jsx-key */
import React, { useMemo, useState } from 'react';

import { Badge, Box, Icon, Typography } from '@superb-ai/norwegian-forest';
import { capitalize, uniq, without } from 'lodash';

import { ClassMetrics, CustomAutoLabelType } from '../../../../../types/customAutoLabelTypes';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { Engine } from '../settings/helper';
import CustomAutoLabelTableLayout from './CustomAutoLabelTableLayout';
import { getCurrentEngine } from './utils';

const CategorizationTable: React.FC<{
  searchedCategorizations: any[];
  selectedCategorizations?: string[];
  customAutoLabel?: CustomAutoLabelType;
  onChangeSelection: (list: string[]) => void;
  oldEnginePerProperty: Map<string, Engine | undefined>;
  newEngine: string;
}> = ({
  searchedCategorizations,
  selectedCategorizations,
  customAutoLabel,
  onChangeSelection,
  oldEnginePerProperty,
  newEngine,
}) => {
  const getEngineList = (id: string) => oldEnginePerProperty.get(id)?.name || '-';

  const [sortBy, setSortBy] = useState<string>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>();

  const handleClickRow = (id: string) => () => {
    if (!selectedCategorizations) {
      onChangeSelection([id]);
      return;
    }
    const index = selectedCategorizations.indexOf(id);
    if (index >= 0) {
      onChangeSelection(without(selectedCategorizations, id));
      return;
    }
    onChangeSelection([...selectedCategorizations, id]);
  };

  const categorizationModelDetail = customAutoLabel?.modelDetail?.categorization;
  const classMetricsMap = categorizationModelDetail?.reduce<
    Record<string, { classMetrics: ClassMetrics[]; failMsg?: string }>
  >(
    (acc, detail) => ({
      ...acc,
      [detail.propertyId]: { classMetrics: detail.classMetrics, failMsg: detail.failMsg },
    }),
    {},
  );

  const contents = useMemo(() => {
    return searchedCategorizations.map(category => {
      const classMetrics = classMetricsMap && classMetricsMap[category.id]?.classMetrics;

      return {
        id: category.id,
        failMsg: classMetricsMap && classMetricsMap[category.id]?.failMsg,
        name: category.name,
        type: category.type,
        classMetrics,
        engineList: getEngineList(category.id),
      };
    });
  }, [searchedCategorizations]);

  const sortedContents = useMemo(() => {
    return contents.sort((a, b) => {
      let res = 0;
      if (sortBy === 'Category') {
        res = a.name.localeCompare(b.name);
      }
      if (sortBy === 'Type') {
        res = a.type.localeCompare(b.type);
      }
      if (sortBy === 'Applied Auto Label AI') {
        res = a.engineList.localeCompare(b.engineList);
      }
      return res * (sortOrder === 'desc' ? -1 : 1);
    });
  }, [sortBy, sortOrder, contents]);

  const rows = sortedContents.map(content => {
    const isSelected =
      !!selectedCategorizations && selectedCategorizations?.indexOf(content.id) >= 0;

    return {
      id: content.id,
      isSelected,
      disabled: !content.classMetrics, // + options 비교 필요함
      onClick: handleClickRow(content.id),
      failMsg: classMetricsMap && classMetricsMap[content.id]?.failMsg,
      contents: [
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {capitalize(content.name)}
        </Typography>,
        <Box display="flex" alignItems="center" gap="8px">
          <Badge size="xxxs" color={content.classMetrics ? 'strawberry' : 'inactive'}>
            <Icon
              name={ProjectUtils.getObjectPropertiesAndCategorizationIcon(content.type)}
              size="10px"
            />
          </Badge>
          <Typography style={{ lineHeight: '20px' }} variant="body4">
            {capitalize(content.type)}
          </Typography>
        </Box>,
        <>
          {content.classMetrics?.map(metric => (
            <Typography style={{ lineHeight: '20px' }} variant="body4" key={metric.classId}>
              {metric.className}
            </Typography>
          )) || 'N/A'}
        </>,
        <>
          {content.classMetrics?.map(metric => (
            <Typography style={{ lineHeight: '20px' }} variant="body4" key={metric.classId}>
              {metric.precision}
            </Typography>
          )) || 'N/A'}
        </>,
        <>
          {content.classMetrics?.map(metric => (
            <Typography style={{ lineHeight: '20px' }} variant="body4" key={metric.classId}>
              {metric.recall}
            </Typography>
          )) || 'N/A'}
        </>,
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {content.engineList}
        </Typography>,
        <Typography style={{ lineHeight: '20px' }} variant="body4" themedColor="primary">
          {getCurrentEngine(isSelected, content.engineList, newEngine)}
        </Typography>,
      ],
    };
  });

  const enabledCategorizations = searchedCategorizations.filter(
    category => classMetricsMap && classMetricsMap[category.id],
  );
  const enabledCategorizationIds = enabledCategorizations.map(categorization => categorization.id);
  const selectedAmongEnabled =
    selectedCategorizations?.filter(selected => enabledCategorizationIds.includes(selected)) || [];
  const isAllSelected = selectedAmongEnabled?.length === enabledCategorizations.length;

  const handleCheckAll = () => {
    if (isAllSelected) {
      onChangeSelection(
        selectedCategorizations?.filter(selected => !selectedAmongEnabled.includes(selected)) || [],
      );
      return;
    }
    onChangeSelection(uniq([...(selectedCategorizations || []), ...enabledCategorizationIds]));
  };

  const handleSort = (field: string, ascDesc: 'asc' | 'desc') => {
    setSortBy(field);
    setSortOrder(ascDesc);
  };

  return (
    <CustomAutoLabelTableLayout
      type="categorization"
      cols={
        <>
          <col />
          <col />
          <col />
          <col width="80px" />
          <col width="80px" />
          <col width="176px" />
          <col width="176px" />
        </>
      }
      isAllChecked={isAllSelected}
      onCheckAll={handleCheckAll}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSort={handleSort}
      columns={[
        { name: 'Category', sortable: true },
        { name: 'Type', sortable: true },
        { name: 'Options' },
        { name: 'Precision' },
        { name: 'Recall' },
        { name: 'Applied Auto Label AI', sortable: true },
        { name: 'New Auto-Label AI' },
      ]}
      rows={rows}
      checkedCount={selectedCategorizations?.length || 0}
    />
  );
};

export default CategorizationTable;
