import '@elastic/charts/dist/theme_light.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Box, Button } from '@superb-ai/norwegian-forest';
import { isEmpty } from 'lodash';

import { ExportsAnalyticsProvider } from '../../../contexts/ExportsAnalyticsContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useApiDefaultParams } from '../../../hooks/ApiParamsHook';
import { useExportHistoriesQuery } from '../../../queries/useExportHistoriesQuery';
import {
  useExportsAnalyticsLatestReportQuery,
  useExportsAnalyticsReportIdQuery,
} from '../../../queries/useExportsAnalyticsQuery';
import ExportsAnalyticsService from '../../../services/ExportsAnalyticsService';
import AnnotationsChangedContainer from './exportsAnalytics/AnnotationsChangedContainer';
import { ExportsAnalyticsMetadata } from './exportsAnalytics/ExportsAnalyticsMetadata';
import FailedView from './exportsAnalytics/FailedView';
import GenerateReportModal from './exportsAnalytics/GenerateReportModal';
import ObjectCountsContainer from './exportsAnalytics/ObjectCountsContainer';
import OverallTrendContainer from './exportsAnalytics/OverallTrendContainer';
import PrecisionRecallContainer from './exportsAnalytics/PrecisionRecallContainer';
import ReportVersionDropdown from './exportsAnalytics/ReportVersionDropdown';
import SkeletonProgressUI from './exportsAnalytics/SkeletonProgressUI';
import Tutorial from './exportsAnalytics/Tutorial';
import { ComparisonReport } from './exportsAnalytics/types/types';
import { EXPORTS_PATH } from './paths';
import { getAnalyticsUrl } from './tools/url';

const ExportsAnalyticsTab = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { params } = useRouteMatch<{ reportId: string }>();
  const [versionId, setVersionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changedVersion, setChangedVersion] = useState<{ result: ComparisonReport }>();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/analytics/AnalyticsExportsTab.tsx',
  });
  const queryParams = {
    defaultParams,
    versionId,
    refetch: false,
  };

  const updateUrl = (newVersionId: string) => {
    routeInfo.history.push(getAnalyticsUrl(routeInfo, newVersionId, EXPORTS_PATH));
  };

  useEffect(() => {
    if (params.reportId === versionId) return;
    setVersionId(params.reportId);
  }, [params.reportId, versionId]);

  const { data: exportHistories, isLoading: exportLoading } = useExportHistoriesQuery({
    t,
    ...queryParams,
  });
  const { data: latestVersion, isLoading: latestVersionLoading } =
    useExportsAnalyticsLatestReportQuery(queryParams);
  const { data: specificVersion, isLoading: specificVersionLoading } =
    useExportsAnalyticsReportIdQuery(queryParams);

  const handleVersionChange = async (newVersionId: string) => {
    if (newVersionId !== versionId) {
      setIsLoading(true);
      const res = await getVersion(newVersionId);
      setChangedVersion(res);
      setVersionId(res.result.id);
      updateUrl(res.result.id);
      setIsLoading(false);
    }
  };

  const getVersion = (versionId: string) => {
    return ExportsAnalyticsService.getVersion({
      exportsAnalyticsId: versionId,
      ...defaultParams,
    });
  };

  if (
    isLoading ||
    exportLoading ||
    latestVersionLoading ||
    (versionId && specificVersionLoading) ||
    !exportHistories
  )
    return <SkeletonProgressUI />;

  const selectedVersion = changedVersion || specificVersion || latestVersion;
  if (!versionId && selectedVersion?.result) setVersionId(selectedVersion?.result.id);

  return (
    <ExportsAnalyticsProvider>
      <Box
        mb={2}
        mt={-0.625}
        display="flex"
        alignItems="center"
        height={32}
        zIndex={2}
        position="relative"
      >
        <ReportVersionDropdown
          isDisable={!selectedVersion?.result}
          value={versionId}
          setValue={handleVersionChange}
          selectedVersion={selectedVersion?.result || ({} as any)}
          exportHistories={exportHistories}
        />
        <Box ml="auto">
          <Button
            variant="strong-fill"
            color="primary"
            disabled={exportHistories.length < 2}
            onClick={() => setIsModalOpen(true)}
          >
            {t('analytics.exportsAnalytics.button.generateReport')}
          </Button>
        </Box>
        <GenerateReportModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          histories={exportHistories}
        />
      </Box>
      {isEmpty(latestVersion) || !latestVersion?.result ? (
        <Tutorial
          hasMoreThanTwoExports={exportHistories.length >= 2}
          openSettingsModal={() => setIsModalOpen(true)}
        />
      ) : selectedVersion?.result.state === 'FAILED' ? (
        <FailedView />
      ) : (
        <>
          {exportHistories && selectedVersion && selectedVersion.result.info ? (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <ExportsAnalyticsMetadata
                selectedVersion={selectedVersion.result}
                exportHistories={exportHistories}
              />
              <ObjectCountsContainer selectedVersion={selectedVersion.result} />
              <OverallTrendContainer selectedVersion={selectedVersion.result} />
              <AnnotationsChangedContainer
                selectedVersion={selectedVersion.result}
                exportHistories={exportHistories}
              />
              <PrecisionRecallContainer selectedVersion={selectedVersion.result} />
            </Box>
          ) : (
            <SkeletonProgressUI />
          )}
        </>
      )}
    </ExportsAnalyticsProvider>
  );
};

export default ExportsAnalyticsTab;
