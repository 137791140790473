import React, { useEffect, useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Check, Pencil, Trash } from '@superb-ai/icons';
import { Box, IconButton } from '@superb-ai/ui';
import { replace } from 'lodash';

const getTextFieldMaxWidth = (maxWidth: string, fontSize: string): number => {
  const size = parseInt(maxWidth, 10);
  return size - parseInt(fontSize, 10) * 3;
};

interface StyleProps {
  fontStyles: {
    fontSize: string | number;
    color: React.CSSProperties['color'];
    fontWeight?: React.CSSProperties['fontWeight'];
    fontFamily?: React.CSSProperties['fontFamily'];
  };
  maxWidth: string;
}

interface Props {
  fontStyles?: StyleProps['fontStyles'];
  maxWidth?: StyleProps['maxWidth'];
  text: string;
  useEdit?: boolean;
  useDoubleClickEdit?: boolean;
  useDelete?: boolean;
  parseValue?: (value: string) => string;
  checkRule: (value: string) => Promise<{ result: boolean; name: string }>;
  editText: (value: string) => void;
  deleteText?: () => void;
  isMount?: boolean;
}

const useStyles = makeStyles(() => ({
  box: (props: StyleProps) => ({
    maxWidth: props.maxWidth,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  textField: (props: StyleProps) => ({
    maxWidth: getTextFieldMaxWidth(props.maxWidth, `${props.fontStyles.fontSize}`),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  inputField: (props: StyleProps) => ({
    '& .MuiInput-input': {
      ...props.fontStyles,
      maxWidth: getTextFieldMaxWidth(props.maxWidth, `${props.fontStyles.fontSize}`),
      padding: '0px',
    },
    '&::before': {
      content: 'none',
    },
  }),
}));

const EditableText: React.FC<Props> = props => {
  const {
    // style
    fontStyles = {
      fontSize: '12px',
      color: '#000',
      fontWeight: 'normal',
    } as const,
    maxWidth = '0',

    // value
    text,
    useEdit = true,
    useDoubleClickEdit = true,
    useDelete = false,

    // event
    parseValue = null,
    checkRule,
    editText,
    deleteText,

    isMount,
  } = props;

  const classes = useStyles({
    fontStyles,
    maxWidth,
  });

  const visibleTextRef = useRef<HTMLElement>(null);
  const hiddenTextRef = useRef<HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(text);
  const [confirmedText, setConfirmedText] = useState(text);
  const [textWidth, setTextWidth] = useState(0);

  const editStart = () => {
    setIsEditMode(true);
    if (visibleTextRef.current) {
      setTextWidth(visibleTextRef.current.offsetWidth + 3);
    }

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, 0);
  };

  const confirmEdit = async () => {
    const trimInputValue = inputValue.trim();
    if (!checkRule) {
      await editText(trimInputValue);
      setConfirmedText(trimInputValue);
    } else {
      const { result, name } = await checkRule(trimInputValue);
      setInputValue(name);
      if (result) {
        await editText(name);
        setConfirmedText(name);
      }
    }
    setIsEditMode(false);
  };

  const cancel = () => {
    setInputValue(text);
    setIsEditMode(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickTextField = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleClickEdit = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    editStart();
  };

  const handleClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (deleteText) deleteText();
  };

  const handleClickEditConfirm = (e: React.MouseEvent) => {
    e.stopPropagation();
    confirmEdit();
  };

  const handleBlur = () => {
    confirmEdit();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = (e.target as HTMLInputElement).value;
    const nextValue = parseValue ? parseValue(val) : val;
    setInputValue(nextValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { key } = e;

    switch (key) {
      case 'Enter':
        confirmEdit();
        break;
      case 'Escape':
        cancel();
        break;
      default:
        break;
    }
  };

  const handleDoubleClick = (e: React.MouseEvent) => {
    if (!useDoubleClickEdit) return;
    e.stopPropagation();

    editStart();
  };

  useEffect(() => {
    if (isMount) {
      handleClickEdit();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEditMode && hiddenTextRef.current) {
      // 3을 더하는 이유는 '커서' 의 크기만큼
      setTextWidth(hiddenTextRef.current.offsetWidth + 3);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const getTextField = () => {
    if (!isEditMode) {
      return (
        <MUI.Tooltip title={inputValue}>
          <MUI.Typography
            className={classes.textField}
            ref={visibleTextRef}
            style={{ ...fontStyles }}
            onDoubleClick={handleDoubleClick}
          >
            {confirmedText}
          </MUI.Typography>
        </MUI.Tooltip>
      );
    }
    return (
      <>
        <MUI.Typography
          className={classes.textField}
          ref={hiddenTextRef}
          style={{ ...fontStyles, position: 'fixed', visibility: 'hidden' }}
        >
          {replace(inputValue, / /g, '-')}
        </MUI.Typography>
        <MUI.TextField
          variant="standard"
          className={classes.inputField}
          inputRef={inputRef}
          value={inputValue}
          onChange={handleChange}
          onClick={handleClickTextField}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          style={{
            width: textWidth,
          }}
        />
      </>
    );
  };

  const getIcons = () => {
    if (!isEditMode) {
      if (!isHovered) return null;
      return (
        <>
          {useEdit && (
            <Box ml={0.5}>
              <IconButton variant="text" icon={Pencil} onClick={handleClickEdit} size="s" />
            </Box>
          )}
          {useDelete && (
            <Box ml={0.5}>
              <IconButton variant="text" icon={Trash} onClick={handleClickDelete} size="s" />
            </Box>
          )}
        </>
      );
    }
    return <IconButton variant="text" icon={Check} onClick={handleClickEditConfirm} size="s" />;
  };

  return (
    <MUI.Box
      className={classes.box}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {getTextField()}
      {getIcons()}
    </MUI.Box>
  );
};

export default EditableText;
