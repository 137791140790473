import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import { formatDateTimeLong } from '../../../../../../../../utils/date';
import { CurateImageData } from '../../../../../../services/DatasetService';
import DetailModalCabinetCollapse from '../image/DetailModalCabinetCollapse';

export default function ImageInformation({ imageInfo }: { imageInfo: CurateImageData }) {
  const { t } = useTranslation();

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  return (
    <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageInformation')}>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box position="relative" display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">{t('text.createdBy')}</Typography>
          <Box>
            <p style={{ ...textEllipsisStyle, width: 240 }}>
              <Typography variant="m-regular">{imageInfo.created_by}</Typography>
            </p>
          </Box>
        </Box>
        <Box position="relative" display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">{t('text.createdAt')}</Typography>
          <Box>
            <p style={{ ...textEllipsisStyle, width: 240 }}>
              <Typography variant="m-regular">
                {formatDateTimeLong(imageInfo.created_at)}
              </Typography>
            </p>
          </Box>
        </Box>
        <Box position="relative" display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">{t('text.updatedBy')}</Typography>
          <Box>
            <p style={{ ...textEllipsisStyle, width: 240 }}>
              <Typography variant="m-regular">{imageInfo.updated_by}</Typography>
            </p>
          </Box>
        </Box>
        <Box position="relative" display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">{t('text.updatedAt')}</Typography>
          <Box>
            <p style={{ ...textEllipsisStyle, width: 240 }}>
              <Typography variant="m-regular">
                {formatDateTimeLong(imageInfo.updated_at)}
              </Typography>
            </p>
          </Box>
        </Box>
      </Box>
    </DetailModalCabinetCollapse>
  );
}
