import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, TypographySkeleton } from '@superb-ai/ui';

import { TargetType } from '../types';
import { IntegrationListColumns } from './IntegrationListColumns';

const IntegrationListHeader: React.FC<{ target: TargetType; skeleton?: boolean }> = ({
  target,
  skeleton = false,
}) => {
  const { t } = useTranslation();
  const contents = useMemo(() => {
    return IntegrationListColumns(t)[target];
  }, [t, target]);

  return (
    <tr>
      {contents.map(column => (
        <Box as="th" style={{ width: column[0], textAlign: 'left' }} px={1} pb={2} key={column[1]}>
          {column[1] && skeleton ? (
            <TypographySkeleton variant="m-regular" backgroundColor="gray-150" />
          ) : (
            <Typography variant="m-regular" color="gray-400">
              {column[1]}
            </Typography>
          )}
        </Box>
      ))}
    </tr>
  );
};

export default IntegrationListHeader;
