import { createContext, ReactNode, useContext } from 'react';

import { AddContextOptions } from 'commandbar/build/internal/src/client/AddContextOptions';

import { ProjectListCommand } from './projectListCommand';

interface CommandAddParams<T = unknown> {
  key: string;
  initialValue: T;
  options?: AddContextOptions;
}

export interface CommandBarContext {
  addContext: (params: CommandAddParams) => void;
}

export const CommandBarContext = createContext<CommandBarContext>({
  addContext: () => {},
});

export const CommandBarProvider = ({ children }: { children: ReactNode }) => {
  const addContext = <T,>({ key, initialValue, options }: CommandAddParams<T>) => {
    window.CommandBar.addContext(key, initialValue, options);
  };

  return (
    <CommandBarContext.Provider
      value={{
        addContext,
      }}
    >
      <ProjectListCommand />
      {children}
    </CommandBarContext.Provider>
  );
};

export const useCommandBar = () => useContext(CommandBarContext);
