import React, { KeyboardEventHandler, RefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Clear, Radio, RadioChecked } from '@superb-ai/icons';
import { Box, Icon, IconButton, Input, Typography } from '@superb-ai/ui';
import { cloneDeep, isEmpty } from 'lodash';

import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetAnnotationMetadataValuesQuery } from '../../../../../../queries/datasetObjectQueries';
import { MetadataProperty } from './type';

export default function PropertyValuesList({
  selectedClass,
  currentProperty,
  onChange,
}: {
  selectedClass: string;
  currentProperty?: MetadataProperty;
  onChange: (metadataProperty: MetadataProperty) => void;
}) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { datasetId } = useParams<{ datasetId: string }>();

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();

  const [inputMode, setInputMode] = React.useState<string>();
  const [hoveredOption, setHoveredOption] = React.useState<string>();
  const { data } = useDatasetAnnotationMetadataValuesQuery({
    datasetId,
    annotationClass: selectedClass,
    annotationMetadataKey: currentProperty?.name,
    disabled: currentProperty?.isNew,
    queryString: queryStringWithHiddenFilterAndDeselection,
    sliceName: sliceInfo?.name,
    imageFilters:
      clusterLevel && appliedFilters
        ? {
            cluster_id_in: appliedFilters?.cluster_id_in || [],
            cluster_level: clusterLevel,
          }
        : undefined,
  });

  const options: { name: string; isDefault: boolean }[] = Object.values(
    currentProperty?.options ||
      data?.results.reduce(
        (acc, result, index) => ({ ...acc, [result]: { name: result, isDefault: index === 0 } }),
        {},
      ) ||
      {},
  );

  useEffect(() => {
    if (!currentProperty || !data) return;
    onChange({
      ...currentProperty,
      options: data?.results.reduce(
        (acc, result, index) => ({ ...acc, [result]: { name: result, isDefault: index === 0 } }),
        {},
      ),
    });
  }, [data]);

  function handleToggleDefaultOption(optionName: string) {
    if (!currentProperty?.options) return;
    const newOptions = Object.values(currentProperty.options).reduce((acc, result) => {
      if (result.name === optionName) {
        return { ...acc, [result.name]: { ...result, isDefault: true } };
      }
      return { ...acc, [result.name]: { ...result, isDefault: false } };
    }, {});
    onChange({
      ...currentProperty,
      options: newOptions,
    });
  }

  function handleDeletePropertyOption(optionName: string) {
    if (!currentProperty?.options) return;
    const newOptions = cloneDeep(currentProperty.options);
    if (newOptions) {
      delete newOptions[optionName];
      onChange({
        ...currentProperty,
        options: newOptions,
      });
    }
  }

  function handleAddPropertyOption(optionName: string) {
    if (optionName === '' || !currentProperty) return;
    const newOptions = cloneDeep(currentProperty.options || {});
    if (newOptions) {
      newOptions[optionName] = {
        name: optionName,
        isDefault: false,
      };
      onChange({
        ...currentProperty,
        options: newOptions,
      });
      if (inputRef.current) inputRef.current.value = '';
    }
  }

  function OptionInput({
    inputRef,
    autoFocus,
    onKeyUp,
    value,
  }: {
    inputRef?: RefObject<HTMLInputElement>;
    autoFocus?: boolean;
    onKeyUp: KeyboardEventHandler<HTMLInputElement>;
    value?: string;
  }) {
    return (
      <Input
        autoFocus={autoFocus}
        ref={inputRef}
        defaultValue={value}
        variant="text"
        placeholder={t(
          'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.addOption',
        )}
        boxProps={{ pl: 0, pr: 0 }}
        onKeyUp={onKeyUp}
        onClick={e => e.stopPropagation()}
      />
    );
  }

  return (
    <>
      {options &&
        !isEmpty(options) &&
        options.map(option => (
          <Box
            key={`${selectedClass}-${currentProperty?.name}-${option.name}`}
            display="flex"
            alignItems="center"
            gap={0.5}
            pr={0.5}
            pl={6}
            py={inputMode === option.name ? 0 : 0.5}
            onClick={() => handleToggleDefaultOption(option.name)}
            // onMouseOver={() => setHoveredOption(option.name)}
            // onMouseOut={() => setHoveredOption(undefined)}
          >
            <Icon
              icon={option.isDefault ? RadioChecked : Radio}
              color={option.isDefault ? 'primary-400' : 'gray-400'}
            />
            {/* {inputMode === option.name ? (
              <OptionInput
                autoFocus
                value={option.name}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    handleChangeOptionName(option.name, e.currentTarget.value);
                  }
                }}
              />
            ) : ( */}
            <>
              <Typography variant="m-regular">{option.name}</Typography>
              {option.isDefault && (
                <Typography variant="m-regular" color="gray-300">
                  ({t('forms.default')})
                </Typography>
              )}
              {/* {hoveredOption === option.name && (
                <IconButton
                  icon={Pencil}
                  size="xs"
                  variant="text"
                  onClick={e => {
                    e.stopPropagation();
                    setInputMode(option.name);
                  }}
                />
              )} */}
            </>
            {/* )} */}

            <IconButton
              icon={Clear}
              size="s"
              variant="text"
              style={{ marginLeft: 'auto' }}
              onClick={e => {
                e.stopPropagation();
                handleDeletePropertyOption(option.name);
              }}
            />
          </Box>
        ))}
      <Box display="flex" alignItems="center" p={0.5} pl={6} style={{ height: '32px' }}>
        <Icon icon={Radio} color="gray-300" />
        <OptionInput
          inputRef={inputRef}
          value={undefined}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleAddPropertyOption(e.currentTarget.value);
            }
          }}
        />
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        p={0.5}
        pl={6}
        style={{ height: '32px' }}
        onClick={() => {}}
      >
        <Icon icon={Radio} color="gray-300" />
        <Typography variant="m-regular" color="gray-300">
          {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.addOption')}
        </Typography>
      </Box> */}
    </>
  );
}
