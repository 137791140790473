import { mapKeys, mapValues, orderBy } from 'lodash';

import {
  ClassPerformance,
  ConfusionMatrixDatum,
} from '../../../../../../services/DiagnosisAnalyticsService';
import { MetricDatum, PRCurveDatum } from '../types';
import { formatDecimalPrecisionClassAPs } from './transformer';

export function formatConfusionMatrixDownload(
  data: ConfusionMatrixDatum[],
  columnNames: Record<'predictedClass' | 'trueClass' | 'count', string>,
) {
  return orderBy(
    data.reduce((acc, d: ConfusionMatrixDatum) => {
      acc.push({
        [columnNames.predictedClass]: d[1],
        [columnNames.trueClass]: d[0],
        [columnNames.count]: d[2] ?? 0,
      });
      return acc;
    }, [] as Record<string, string | number>[]),
    [columnNames.predictedClass, columnNames.trueClass],
  );
}

function roundToHundredth(num: number): number {
  return Number(num.toFixed(2));
}

export function formatPerformanceTableDownload(data: ClassPerformance[], sortBy: string) {
  return formatDecimalPrecisionClassAPs(orderBy(data, sortBy, 'asc'));
}

export function roundAndSortDownload<T extends Record<string, any>>({
  data,
  sortBy,
}: {
  data: T[];
  sortBy?: string;
}) {
  let result = data.map(d => {
    return mapValues(d as object, roundToHundredth);
  });
  if (sortBy) {
    result = orderBy(result, sortBy, 'asc');
  }
  return result;
}

export function formatPRCurveDataDownload({
  data,
  sortBy,
}: {
  data: PRCurveDatum[];
  sortBy: string;
}) {
  // swap object key order
  const result = data.map(d => {
    return {
      recall: d.recall,
      precision: d.precision,
      confidence: d.confidence,
    };
  });
  return roundAndSortDownload({ data: result, sortBy });
}

export function roundMetricKeys<T>(data: T[]) {
  return mapKeys(data, (v, k) => roundToHundredth(k));
}

export function mapKeyToRange(data: MetricDatum[]) {
  return data.map(d => {
    const { key, count } = d;
    return {
      key: `${key}-${Number(key + 0.1).toFixed(1)}`,
      count,
    };
  });
}
