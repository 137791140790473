import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { ChevronDown, ChevronUp, FolderOpen, WarningOutline } from '@superb-ai/icons';
import { Card, FetchMore, Tab, Tabs } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, LoadingIndicator, Typography } from '@superb-ai/ui';

import { CurateCommandContext } from '../../../../apps/Curate/contexts/CommandContext';
import {
  Command as CurateCommand,
  CommandType as CurateCommandType,
} from '../../../../apps/Curate/types/commandTypes';
import { LabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { Command as LabelCommand } from '../../../../utils/LabelCommandUtils';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import { CommandProgressFilters } from './CommandProgressFilters';
import { CurateCommandProgressFilters } from './CurateCommandProgressFilters';
import CurateCommandProgressList from './CurateCommandProgressList';
import LabelCommandProgressList from './LabelCommandProgressList';

export default function CommandProgressModal({
  commandContext,
}: {
  commandContext: CurateCommandContext | LabelCommandContext;
}): JSX.Element {
  const { t } = useTranslation();
  const match = useRouteMatch<{ suite: 'label' | 'curate' }>();
  // const [tabValue, setTabValue] = useState<'in_progress' | 'done'>('in_progress');
  const { isArchiveVisible, setIsArchiveVisible, tabValue, setTabValue } = commandContext;

  useEffect(() => {
    // Reset archiveVisible to false when tab or filters change
    setIsArchiveVisible(false);
  }, [tabValue, commandContext.filters, setIsArchiveVisible]);

  useEffect(() => {
    if (match.params.suite === 'label') {
      if (isArchiveVisible) {
        (commandContext as LabelCommandContext).fetchMoreCommands(tabValue, 0);
      } else {
        (commandContext as LabelCommandContext).fetchCommands(tabValue);
      }
    }
    if (match.params.suite === 'curate') {
      if (isArchiveVisible) {
        (commandContext as CurateCommandContext).fetchMoreCommands(tabValue);
      } else {
        (commandContext as CurateCommandContext).fetchCommands(tabValue, null);
      }
    }
  }, [tabValue, commandContext.filters, isArchiveVisible, match.params.suite]);

  const collection = useMemo(() => {
    return tabValue === 'in_progress'
      ? commandContext.commandsInProgress
      : commandContext.commandsDone;
  }, [tabValue, commandContext]);

  function loadMore() {
    return commandContext.fetchMoreCommands(tabValue);
  }

  const DoneArchiveText = t('bulkActions.messages.archivedAfter7Days');

  const operationType =
    tabValue === 'done' ? (isArchiveVisible ? 'recentOrOld' : 'recent') : 'current';

  const projectMessage =
    match.params.suite === 'label' &&
    (commandContext as LabelCommandContext).processedFilters.onlyThisProject &&
    'forThisProject';

  const emptyMessage = (
    <Box p={1}>
      <Box
        backgroundColor="gray-100"
        color="gray-300"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
        style={{ height: 120 }}
      >
        <Icon icon={FolderOpen} size="32px" />
        <Typography variant="m-regular" color="gray-400">
          <Trans
            t={t}
            i18nKey="bulkActions.messages.emptyMessage"
            values={{
              operationType: t(`bulkActions.messages.operationType.${operationType}`),
              projectMessage: projectMessage
                ? t(`bulkActions.messages.projectMessage.${projectMessage}`)
                : '',
            }}
          />
        </Typography>
        {isArchiveVisible && tabValue === 'done' && (
          <Typography variant="m-regular">{DoneArchiveText}</Typography>
        )}
      </Box>
    </Box>
  );

  const loadingMessage = (
    <Box p={1}>
      <Box display="flex" alignItems="center" justifyContent="center" style={{ height: 100 }}>
        <LoadingIndicator />
      </Box>
    </Box>
  );

  const doneArchivedBox = (
    <Box
      backgroundColor="primary-100"
      color="primary"
      py={1.5}
      px={2}
      display="flex"
      alignItems="center"
      gap={0.5}
    >
      <Icon icon={WarningOutline} size="12px" />
      <Typography variant="m-regular">{DoneArchiveText}</Typography>
    </Box>
  );

  const archiveToggle = (
    <Box p={1.5}>
      <Button
        style={{ width: '100%' }}
        variant="stroke"
        size="s"
        color="gray"
        onClick={() => setIsArchiveVisible(!isArchiveVisible)}
      >
        {!isArchiveVisible
          ? t('bulkActions.button.showOlderEntries')
          : t('bulkActions.button.hideOlderEntries')}
        <Icon icon={isArchiveVisible ? ChevronUp : ChevronDown} size="12px" />
      </Button>
    </Box>
  );

  return (
    <Card>
      <Box style={{ width: 440 }}>
        <Tabs
          color="primary"
          variant="underlined"
          onChange={value => setTabValue(value as 'in_progress' | 'done')}
          currentTab={tabValue}
        >
          <Tab
            label={t('bulkActions.status.inProgress')}
            value="in_progress"
            minWidth={'' as any}
          />
          <Tab label={t('bulkActions.status.done')} value="done" minWidth={'' as any} />
          <Box display="flex" mr={1} justifyContent="flex-end" style={{ flex: 100 }}>
            {match.params.suite === 'label' && <CommandProgressFilters />}
            {match.params.suite === 'curate' && <CurateCommandProgressFilters />}
          </Box>
        </Tabs>
        {!collection.isLoading && collection.items.length === 0 ? (
          <>
            {emptyMessage}
            {tabValue === 'done' && match.params.suite !== 'curate' && archiveToggle}
          </>
        ) : collection.items.length > 0 ? (
          <Box overflow={overflowOverlayOrAuto()} style={{ maxHeight: 410 }}>
            {match.params.suite === 'label' && (
              <LabelCommandProgressList items={collection.items as LabelCommand[]} />
            )}
            {match.params.suite === 'curate' && (
              <CurateCommandProgressList
                items={collection.items as CurateCommand<CurateCommandType>[]}
              />
            )}
            <FetchMore hasMore={collection.hasMore} loadMore={loadMore} />
            {tabValue === 'done' && !collection.hasMore && isArchiveVisible && doneArchivedBox}
            {tabValue === 'done' && match.params.suite !== 'curate' && archiveToggle}
          </Box>
        ) : (
          loadingMessage
        )}
      </Box>
    </Card>
  );
}
