import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import getErrorConst from '../../../consts/ErrorConst';
import { useErrorInfo } from '../../../contexts/ErrorContext';
import { StateGetterSetter } from '../../../contexts/types';
import { DatasetResult, useCurateDatasetService } from '../services/DatasetService';
import { ParseOptions } from '../types/querySchemaTypes';
import { usePublicDatasetContext } from './PublicDatasetContextProvider';

// prettier-ignore
type ContextProps = { datasetId: string } &
  StateGetterSetter<['datasetInfo', 'setDatasetInfo'], DatasetResult | undefined> &
  StateGetterSetter<['isLoading', 'setIsLoading'], boolean> &
  StateGetterSetter<['searchFieldMapping', 'setSearchFieldMapping'], ParseOptions>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { t } = useTranslation();
  const { getDataset, getDatasetSearchFieldMapping } = useCurateDatasetService();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { showPublicDatasets } = usePublicDatasetContext();

  const [searchFieldMapping, setSearchFieldMapping] = useState<ParseOptions>({});
  const [datasetInfo, setDatasetInfo] = useState<DatasetResult>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setError } = useErrorInfo();

  useEffect(() => {
    if (!datasetId) return;
    (async () => {
      setIsLoading(true);
      try {
        const datasetInfo = await getDataset({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
          expand: ['image_count', 'slice_count'],
        });
        setDatasetInfo(datasetInfo);
        const newSearchFieldMapping = await getDatasetSearchFieldMapping({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
        });
        setSearchFieldMapping(newSearchFieldMapping);
      } catch (err: any) {
        if (err?.message === getErrorConst(t).NO_PUBLIC_API) {
          setError(err.message);
        }
      }
      setIsLoading(false);
    })();
  }, [datasetId]);

  return {
    datasetInfo,
    setDatasetInfo,
    isLoading,
    setIsLoading,
    datasetId,
    searchFieldMapping,
    setSearchFieldMapping,
  };
};

export const useDatasetContext = (): ContextProps => {
  return React.useContext(Context);
};

export const DatasetProvider: React.FC = ({ children }) => {
  const datasetInfo = useProvider();
  return <Context.Provider value={datasetInfo}>{children}</Context.Provider>;
};
