import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { Command, CommandStatus, CommandType, CurateJobParams } from '../types/commandTypes';
import { DownloadInfo } from '../types/downloadTypes';

const getDownloads: AfterLoginCallback<
  { results: DownloadInfo[]; next_cursor: string | null },
  { limit?: number; cursor?: string; datasetId: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/downloads/?${qs.stringify(
      {
        dataset_id: params.data.datasetId,
        limit: params.data.limit,
        cursor: params.data.cursor,
      },
      { arrayFormat: 'brackets' },
    )}`,
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });

  return data;
};

const getDownload: AfterLoginCallback<DownloadInfo, { jobId: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/downloads/${params.data.jobId}/`,
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });

  return data;
};

const updateDownload: AfterLoginCallback<
  DownloadInfo,
  { jobId: string; downloadName: string }
> = async params => {
  const { jobId, ...otherDataParams } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/curate/batch/downloads/${params.data.jobId}/`,
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
    data: otherDataParams,
  });

  return data;
};

const deleteDownload: AfterLoginCallback<
  { success: boolean },
  { jobId: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: `/curate/batch/downloads/${params.data.jobId}/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export function useDownloadsService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getDownloads: afterLoginFetcher(getDownloads),
    getDownload: afterLoginFetcher(getDownload),
    updateDownload: afterLoginFetcher(updateDownload),
    deleteDownload: afterLoginFetcher(deleteDownload),
  };
}
