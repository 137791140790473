import { useState } from 'react';

import { LoadingIndicator, useDialogState } from '@superb-ai/ui';

import { useDatasetImageQueries } from '../../../Curate/queries/dataQueries';
import { useModelTrainingSamplePredictionsQuery } from '../../queries/modelQueries';
import { isRecognitionAIModelTrainingResult } from '../../services/modelTrainingResultTypeGuards';
import { ModelTrainingEpochs, MyModelDetail } from '../../services/types';
import { DetectionDetailContent } from '../list/CategoryDetectionDetail';
import SamplePredictionDialog from './SamplePredictionDialog';

export const Performance = ({
  data,
  trainingEpochsData,
}: {
  data: MyModelDetail;
  trainingEpochsData: ModelTrainingEpochs | undefined;
}) => {
  const samplePredictionDialogState = useDialogState();
  const recognitionAITrainingResult = data.modelTraining.trainingResult.data;

  const [datasetImageIndex, setDatasetImageIndex] = useState(0);

  const datasetImagesQueries = useDatasetImageQueries({
    datasetId: trainingEpochsData?.referenceId ?? '',
    imageIds: trainingEpochsData?.samplePredictionImageIds ?? [],
    expand: ['annotations'],
  });

  const bestEpochIndex =
    typeof data.modelTraining.bestEpoch === 'number'
      ? data.modelTraining.bestEpoch - 1
      : data.modelTraining.currentEpoch - 1;

  const epochInfo = trainingEpochsData?.modelTrainingEpochs.find(e => e.epoch === bestEpochIndex);

  const samplePredictionQuery = useModelTrainingSamplePredictionsQuery({
    url: epochInfo?.presignedUrl ?? '',
    status: data.status,
  });

  if (
    !recognitionAITrainingResult ||
    !isRecognitionAIModelTrainingResult(recognitionAITrainingResult)
  )
    return <>no training result</>;

  if (!data.modelTraining.totalEpochs) {
    return (
      <DetectionDetailContent
        hasSamplePrediction={Boolean(data.modelTraining.totalEpochs)}
        trainingResult={recognitionAITrainingResult}
        headerColor="gray-100"
        modelName={data.modelSetting.name}
        samplePredictionDialogState={samplePredictionDialogState}
      />
    );
  }

  // totalEpochs가 없다면 view sample prediction 비활성화
  if (samplePredictionQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (datasetImagesQueries.some(_ => _.isLoading)) {
    return <LoadingIndicator />;
  }
  const datasetImages = datasetImagesQueries[datasetImageIndex];
  if (!datasetImages) {
    return (
      <DetectionDetailContent
        hasSamplePrediction={Boolean(data.modelTraining.totalEpochs)}
        trainingResult={recognitionAITrainingResult}
        headerColor="gray-100"
        modelName={data.modelSetting.name}
        samplePredictionDialogState={samplePredictionDialogState}
      />
    );
  }
  const { data: datasetImageInfo } = datasetImages;
  const imageId =
    samplePredictionQuery.data?.images.find(image => image?.label_id === datasetImageInfo?.id)
      ?.id ?? NaN;

  return (
    <>
      <DetectionDetailContent
        hasSamplePrediction={Boolean(data.modelTraining.totalEpochs)}
        trainingResult={recognitionAITrainingResult}
        headerColor="gray-100"
        modelName={data.modelSetting.name}
        samplePredictionDialogState={samplePredictionDialogState}
      />
      {samplePredictionDialogState.visible && trainingEpochsData && (
        <SamplePredictionDialog
          trainingEpochsData={trainingEpochsData}
          state={samplePredictionDialogState}
          datasetImages={datasetImages}
          datasetImagesLength={datasetImagesQueries.length}
          samplePredictions={samplePredictionQuery}
          datasetImageIndex={datasetImageIndex}
          setDatasetImageIndex={setDatasetImageIndex}
          imageId={imageId}
        />
      )}
    </>
  );
};
