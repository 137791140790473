import React from 'react';
import { useTranslation } from 'react-i18next';

import { Reset } from '@superb-ai/icons';
import { Button, Icon } from '@superb-ai/ui';

export default function ResetTotalButton({
  hasAppliedFilters,
  initializeAppliedFilter,
  initializeSelectedFilter,
}: {
  hasAppliedFilters: boolean;
  initializeAppliedFilter: () => void;
  initializeSelectedFilter: () => void;
}) {
  const { t } = useTranslation();

  if (!hasAppliedFilters) return <></>;
  return (
    <Button
      size="s"
      color="primary"
      variant="text"
      onClick={() => {
        initializeAppliedFilter();
        initializeSelectedFilter();
      }}
    >
      <Icon icon={Reset} />
      {t('button.reset')}
    </Button>
  );
}
