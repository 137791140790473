import { ReactNode } from 'react';

import { TextEllipsis } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

import UserAvatar, { UserInfoInUserAvatar } from './UserAvatar';

interface Props {
  userInfo: UserInfoInUserAvatar;
  children?: ReactNode;
}

export default function UserNameWithAvatar({ userInfo, children }: Props) {
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <UserAvatar size={24} userInfo={userInfo} noShadow />
      {children ?? (
        <Typography variant="m-regular">
          <TextEllipsis mode="tail" text={userInfo.name ?? ''} />
        </Typography>
      )}
    </Box>
  );
}
