import { ComponentType } from 'react';

import { Box, Icon, LinkTypography, Typography } from '@superb-ai/ui';
import Link from 'next/link';

export default function SecurityTutorialBottom({
  icon,
  title,
  description,
  learnMoreLink,
  learnMoreText,
  width = '48%',
}: {
  icon: ComponentType;
  title: string | JSX.Element;
  description: string | JSX.Element;
  learnMoreLink: string;
  learnMoreText: string;
  width?: string;
}) {
  return (
    <Box display="flex" backgroundColor="gray-100" style={{ width: width }}>
      <Box display="flex" flexDirection="column" backgroundColor="cloud-100" p={4}>
        <Icon icon={icon} size={16} color="blue" />
        <Box mt={2}>
          {typeof title === 'string' ? (
            <Typography variant="l-strong">{title}</Typography>
          ) : (
            { title }
          )}
        </Box>
        {typeof description === 'string' ? (
          <Typography variant="m-regular">{description}</Typography>
        ) : (
          { description }
        )}
        {/* <Box mt={2}>
          <Link href={learnMoreLink} target="_blank" rel="noopener noreferrer">
            <LinkTypography variant="m-regular">{learnMoreText}</LinkTypography>
          </Link>
        </Box> */}
      </Box>
    </Box>
  );
}
