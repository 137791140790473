import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import { ReactComponent as DenyCrossIcon } from '../../assets/img/deny_cross.svg';
import { ReactComponent as PermitCircleIcon } from '../../assets/img/permit_circle.svg';
import { NewRole } from '../../types/memberTypes';
import { getRoleLabel } from '../../union/UserRoleUnion';

export default function ({ role }: { role: NewRole }) {
  const { t } = useTranslation();

  const items: Partial<Record<NewRole, { description: string; isAllowed?: boolean }[]>> = {
    manager: [
      {
        isAllowed: true,
        description: t('projectMembers.role.description.manager.allow', { joinArrays: ' · ' }),
      },
      {
        description: t('projectMembers.role.description.manager.disallow', { joinArrays: ' · ' }),
      },
    ],
    reviewer: [
      {
        isAllowed: true,
        description: t('projectMembers.role.description.reviewer.allow', { joinArrays: ' · ' }),
      },
      {
        description: t('projectMembers.role.description.reviewer.disallow', { joinArrays: ' · ' }),
      },
    ],
    labeler: [
      {
        isAllowed: true,
        description: t('projectMembers.role.description.labeler.allow', { joinArrays: ' · ' }),
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="column">
      {getRoleLabel(t, role)}
      <Box display="flex" flexDirection="column" mt={0.5} gap="2px">
        {items[role]?.map(({ isAllowed, description }, i) => (
          <Box display="flex" gap="6px" key={i}>
            <Box display="flex" height="15px" alignItems="center">
              {isAllowed ? (
                <PermitCircleIcon style={{ width: '6px', height: '6px' }} />
              ) : (
                <DenyCrossIcon style={{ width: '6px', height: '6px' }} />
              )}
            </Box>
            <Typography variant="body5" themedColor={['grey', 600]}>
              {description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
