import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { LoadingIndicator } from '@superb-ai/ui';

import { AppRouteLayoutProps } from '../../../../menu';
import { useModelDetailQuery } from '../../queries/modelQueries';
import { Endpoints } from '../../recognition-ai/detail/Endpoints';
import { ModelDetails } from '../../recognition-ai/detail/Layout';
import { Overview } from '../../recognition-ai/detail/Overview';
import { PurposeType } from '../../services/types';
import { Performance } from './Performance';
import { Progress } from './Progress';

export const Layout = ({ match }: AppRouteLayoutProps) => {
  const { t } = useTranslation();
  const id = match.params.id;
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const { data } = useModelDetailQuery({ id: id!, modelPurpose: params.purpose });
  if (!data) return <LoadingIndicator mt={2} mb={4} />;

  const tabConfigurations = [
    {
      id: 'overview',
      name: t('model.myModelDetail.overview'),
      onClick: undefined,
      component: <Overview data={data} />,
      visible: true,
    },
    {
      id: 'progress',
      name: t('model.myModels.trainingProgress.progress'),
      onClick: () => {
        // 디폴트 탭이 아닐 때 렌더링이 제대로 되지 않는 문제가 있음
      },
      component: <Progress data={data} />,
      visible: true,
    },
    {
      id: 'performance',
      name: t('model.myModelDetail.performance'),
      onClick: undefined,
      component: <Performance data={data} />,
      visible: data.status === 'trained',
    },
    {
      id: 'endpoints',
      name: `${t('model.endpoints.title')} (${data.endpointsCount})`,
      onClick: undefined,
      component: <Endpoints modelId={data.id} />,
      visible: data.status === 'trained',
    },
  ];

  return (
    <>
      <ModelDetails data={data} tabConfigurations={tabConfigurations} />
    </>
  );
};
