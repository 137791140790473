import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Box, Button, Icon, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { debounce, escapeRegExp } from 'lodash';
import { useSnackbar } from 'notistack';

import { ModalInfo } from '../../../consts/ModalMessage';
import { MemberData } from '../../../types/memberTypes';
import ProjectAccessModal from '../../pages/account/accountMembers/ProjectAccessModal';
import ChangeMemberRoleModal from '../../pages/settings/projectMembers/ChangeMemberRoleModal';
import PopperTooltip from '../PopperTooltip';
import SearchBar from '../SearchBar';
import InviteMembersModal from './InviteMembersModal';
import MembersTable from './MembersTable';
import { MembersColumns } from './types';

type MembersTableProps = {
  getIsDeletable: (member: MemberData) => boolean;
  getMembers: () => Promise<MemberData[]>;
  refetchMembers: () => Promise<void>;
  page: 'project' | 'account';
  deleteMemberSnackbar: string;
  deleteMemberModal: (data: any) => ModalInfo;
  deleteMembersSnackbar: ({ t, count }: { t: TFunction; count: number }) => string;
  deleteMembersModal: (data: any) => ModalInfo;
  onDelete: (member: MemberData) => Promise<void>;
  title: string;
  deleteButtonMsg: string;
  inviteButtonMsg: string;
  columns: MembersColumns[];
  columnsWidth: (number | string)[];
  Filters?: JSX.Element;
};

const ManageMembers: React.FC<MembersTableProps> = ({
  getIsDeletable,
  getMembers,
  refetchMembers,
  page,
  deleteMemberSnackbar,
  deleteMemberModal,
  deleteMembersSnackbar,
  deleteMembersModal,
  onDelete,
  inviteButtonMsg,
  deleteButtonMsg,
  columns,
  columnsWidth,
  Filters,
}) => {
  const { openModal, closeModal } = useAlertModal();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [members, setMembers] = useState<MemberData[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<MemberData[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<MemberData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInviteMembersDialogActive, setIsInviteMembersDialogActive] = useState(false);
  const [isProjectAccessModalOpen, setIsProjectAccessModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [changeRoleTargetMember, setChangeRoleTargetMember] = useState<MemberData>();
  const [currentDeletedProgress, setCurrentDeletedProgress] = useState(0);

  const nonCollaboratorSelected = selectedMembers.filter(
    member => member.tenantRole !== 'Collaborator',
  );
  const isProjectAccessDisabled = !selectedMembers.length || !!nonCollaboratorSelected.length;

  const loadUsers = async () => {
    setIsLoading(true);
    try {
      await refetchMembers();
      const users = await getMembers();

      setMembers(users);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      });
    }
  };

  const handleClickChangeMemberRole = (member: MemberData) => {
    setChangeRoleTargetMember(member);
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line
  }, [getMembers]);

  // Apply search query
  useEffect(() => {
    if (!members.length) {
      setFilteredMembers([]);
      return;
    }
    if (!query) {
      setFilteredMembers([...members]);
      return;
    }
    const regex = new RegExp(escapeRegExp(query), 'i');
    setFilteredMembers(
      members.filter(member => member.name.match(regex) || member.email.match(regex)),
    );
  }, [members, query]);

  const handleClickInviteMembers = () => {
    setIsInviteMembersDialogActive(true);
  };

  const removeMember = (member: MemberData) => async () => {
    await onDelete(member);
    await refetchMembers();
    await loadUsers();
    enqueueSnackbar(deleteMemberSnackbar, { variant: 'success' });
  };

  const handleClickRemoveMember = (member: MemberData) => {
    openModal({
      ...deleteMemberModal(member.name),
      mainButton: {
        text: deleteButtonMsg,
        onClick: removeMember(member),
      },
      subButton: {
        text: t('button.cancel'),
        onClick: closeModal,
      },
    });
  };

  const removeMembers = () => async () => {
    try {
      setIsLoading(true);
      for (const member of selectedMembers) {
        await onDelete(member);
        setCurrentDeletedProgress(v => v + 1);
      }
      await refetchMembers();
      await loadUsers();
      setCurrentDeletedProgress(0);

      enqueueSnackbar(deleteMembersSnackbar({ t, count: selectedMembers.length }), {
        variant: 'success',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickRemoveMembers = () => {
    const selectedMembersCount = selectedMembers.length;
    if (!selectedMembersCount) return;
    openModal({
      ...deleteMembersModal(selectedMembersCount),
      mainButton: {
        text: deleteButtonMsg,
        onClick: removeMembers(),
      },
      subButton: {
        text: t('button.cancel'),
        onClick: closeModal,
      },
    });
  };

  const handleClickManageProjectAccess = (member: MemberData) => {
    setIsProjectAccessModalOpen(true);
    setSelectedMembers([member]);
  };

  const handleSearchInput = debounce((query: string) => {
    setQuery(query);
  }, 200);

  return (
    <>
      <InviteMembersModal
        open={isInviteMembersDialogActive}
        setOpen={setIsInviteMembersDialogActive}
        page={page}
        loadUsers={loadUsers}
        currentMembers={members ?? []}
      />
      {page === 'project' && (
        <ChangeMemberRoleModal
          open={!!changeRoleTargetMember}
          setOpen={setChangeRoleTargetMember}
          member={changeRoleTargetMember}
          loadUsers={loadUsers}
        />
      )}
      {page === 'account' && (
        <ProjectAccessModal
          open={isProjectAccessModalOpen}
          setOpen={setIsProjectAccessModalOpen}
          selectedMembers={selectedMembers}
          loadUsers={loadUsers}
        />
      )}
      <Box display="flex" gap="8px" alignItems="center" mb={2}>
        {Filters}
        <Box width={332}>
          <SearchBar
            placeholder={t('users.filter.searchMemberNameOrEmail')}
            onChange={e => handleSearchInput(e.target.value)}
          />
        </Box>
        <Box ml="auto">
          <Button
            size="l"
            iconPosition="left"
            IconAdornment={<Icon name="plus" />}
            onClick={handleClickInviteMembers}
            data-intercom-target={inviteButtonMsg}
          >
            {inviteButtonMsg}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="body4" themedColor="textDefault">
          {t('users.userCount', { count: members ? members.length : 0 })}
        </Typography>
        <Box display="flex" alignItems="center">
          {page === 'account' && (
            <Box mr={1}>
              <PopperTooltip
                placement="bottom"
                bgColor="#999"
                anchor={
                  <Button
                    iconPosition="left"
                    IconAdornment={<Icon name="folderSetting" />}
                    variant="shadow"
                    disabled={isProjectAccessDisabled}
                    onClick={() => setIsProjectAccessModalOpen(true)}
                  >
                    {t('users.projectAccess')}
                  </Button>
                }
              >
                <Box>{t('users.projectAccessTooltip')}</Box>
              </PopperTooltip>
            </Box>
          )}
          <Box>
            <Button
              iconPosition="left"
              IconAdornment={<Icon name="trash" />}
              variant="shadow"
              disabled={!selectedMembers.length}
              onClick={handleClickRemoveMembers}
            >
              {deleteButtonMsg}
            </Button>
          </Box>
        </Box>
      </Box>
      <MembersTable
        filteredMembers={filteredMembers}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        columns={columns}
        columnsWidth={columnsWidth}
        isLoading={isLoading}
        getIsDeletable={getIsDeletable}
        onClickDelete={handleClickRemoveMember}
        onClickChangeRole={handleClickChangeMemberRole}
        onClickProjectAccess={handleClickManageProjectAccess}
      />
      {isLoading && (
        <Box display="flex" width="100%" justifyContent="center" alignItems="center" mt={2}>
          <Typography variant="body4">{`${currentDeletedProgress} / ${selectedMembers.length}`}</Typography>
        </Box>
      )}
    </>
  );
};

export default ManageMembers;
