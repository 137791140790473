import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { map, some } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import IssuesService from '../../../../services/IssuesService';
import CheckRadio from '../../../elements/CheckRadio';
import CircularProgressDialog from '../../../elements/CircularProgressDialog';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '15px',
    padding: '0px 0px 0px 4px',
    alignItems: 'center',
  },
  button: {
    fontWeight: 400,
    fontSize: '11px',
    padding: '3px 11px',
  },
}));

const calChecked = (selectedCardMap: any): any => {
  const selectedCardMapValueArray = Object.values(selectedCardMap);
  if (selectedCardMapValueArray.length === 0) {
    return false;
  }
  return !some(selectedCardMapValueArray, value => !value);
};

// eslint-disable-next-line
const CabinetIssueStatusController = ({ selectedCardMap, setSelectedCardMap }: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelIssuesInfo = useLabelIssues();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const routeInfo = useRouteInfo();
  const [isRequesting, setIsRequesting] = useState(false);

  const authInfo = useAuthInfo();
  const { issueViewStatus, setIssues, setIssuePanelState, setOpenBadgeInfo } = labelIssuesInfo;
  const {
    history: {
      location: { pathname, search },
    },
  } = routeInfo;
  const checked = calChecked(selectedCardMap);

  const handleClickRadio = (): void => {
    const nextSelectedCardMap = {};
    const keys = Object.keys(selectedCardMap);
    for (let i = 0; i < keys.length; i++) {
      // @ts-ignore: hash에 key 값이 있음을 보장해야함
      nextSelectedCardMap[keys[i]] = !checked;
    }

    setSelectedCardMap(nextSelectedCardMap);
  };

  const handleClickChangeStatus = async (): Promise<void> => {
    if (isRequesting) return;

    if (!some(Object.values(selectedCardMap))) return;
    const nextStatus = issueViewStatus === 'OPEN' ? 'RESOLVED' : 'OPEN';

    try {
      setIsRequesting(true);

      const selectedCards = Object.keys(selectedCardMap).filter(key => selectedCardMap[key]);

      await Promise.all(
        map(selectedCards, card =>
          IssuesService.updateIssue({
            projectId: routeInfo.urlMatchInfo.projectId,
            labelId: labelDetailViewInfo.label.id,
            issueId: card,
            info: {
              status: nextStatus,
            },
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          }),
        ),
      );

      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: labelDetailViewInfo.label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setIssues(nextIssues);
      setIssuePanelState(null);
      setOpenBadgeInfo({
        issue: null,
        ref: null,
      });

      routeInfo.history.push(`${pathname}${search}#label_id=${labelDetailViewInfo.label.id}`);

      setIsRequesting(false);
    } catch (err: any) {
      setIsRequesting(false);
      throw err;
    }
  };

  return (
    <>
      <MUI.Box className={classes.box}>
        <CheckRadio
          color={issueViewStatus === 'OPEN' ? '#5a7bff' : '#ff625a'}
          checked={checked}
          handleClick={handleClickRadio}
        />
        <MUI.Button
          className={classes.button}
          variant="contained"
          color={issueViewStatus === 'OPEN' ? 'secondary' : 'primary'}
          onClick={handleClickChangeStatus}
        >
          {issueViewStatus === 'OPEN' ? 'Resolve' : 'Re-open'}
        </MUI.Button>
      </MUI.Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
};

export default CabinetIssueStatusController;
