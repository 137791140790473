const COMPARE_OPTIONS = {
  CURRENT: 'current',
  MAIN: 'main',
  AUTO_LABEL: 'autoLabel',
  CONSENSUS: 'consensus',
};

const compareOptionArr = [
  {
    label: 'Current',
    value: COMPARE_OPTIONS.CURRENT,
  },
  {
    label: 'Auto-Label',
    value: COMPARE_OPTIONS.AUTO_LABEL,
  },
];

export default {
  COMPARE_OPTIONS,
  compareOptionArr,
};
