import qs from 'qs';

import { IouTypeEnum } from '../../../components/elements/windowedImageGrid/types';
import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import {
  ConfidenceRangeFilter,
  EvaluationFilterSchema,
  EvaluationFilterSchemaSnakeCase,
  IouRangeFilter,
} from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import { DiagnosisStatusEnum } from '../components/datasets/dataset/modelDiagnosis/diagnosis/types';
import { Command, START_DIAGNOSIS, UPDATE_DIAGNOSIS } from '../types/commandTypes';
import { Split } from '../types/evaluationTypes';
import { KeysToCamelCase } from '../types/typeUtils';

function appendQueryParams(endpoint: string, data: Record<string, any> | undefined): string {
  if (!data) return endpoint;
  return `${endpoint}${qs.stringify(data, { arrayFormat: 'brackets', addQueryPrefix: true })}`;
}

export type DiagnosisDependencies = {
  datasetId: string;
  fromPublicDatasets: boolean;
  diagnosisId: string;
  // query?: string;
  filter?: EvaluationFilterSchema;
};

function buildEndpoint(
  params: { datasetId: string; diagnosisId: string },
  path: string,
  fromPublicDatasets: boolean,
): string {
  const { datasetId, diagnosisId } = params;
  return `/curate/model-diagnosis/${
    fromPublicDatasets ? 'public-' : ''
  }datasets/${datasetId}/diagnoses/${diagnosisId}/${path}`;
}

function diagnosisListEndpoint(datasetId: string, fromPublicDatasets: boolean): string {
  return `/curate/model-diagnosis/${
    fromPublicDatasets ? 'public-' : ''
  }datasets/${datasetId}/diagnoses/`;
}

// TODO: remove after start job test
export function transformFilterBody(
  filter?: EvaluationFilterSchema,
): EvaluationFilterSchemaSnakeCase {
  if (!filter) return {};

  const { annotationClassIn, predictionClassIn, evaluationResult, iouRange, confidenceRange } =
    filter;

  const transformRange = <T extends IouRangeFilter | ConfidenceRangeFilter>(
    range: T | undefined,
    includeNullDefault: boolean,
  ) => {
    if (!range) return undefined;
    const { includeNull, min, max } = range;
    return {
      min,
      max,
      include_null: includeNull ?? includeNullDefault,
    };
  };

  return {
    annotation_class_in: annotationClassIn,
    prediction_class_in: predictionClassIn,
    evaluation_result: evaluationResult,
    iou_range: transformRange(iouRange, true),
    confidence_range: transformRange(confidenceRange, true),
  };
}

export type ClassListResponse = {
  classList: (string | null)[];
  targetIou: number;
};

export type DiagnosisSplitSliceOption = { id: string; name: string };
type SplitValues = {
  slices: DiagnosisSplitSliceOption[];
  relatedSlices: DiagnosisSplitSliceOption[];
};
export type DiagnosisSplits = { train: SplitValues; val: SplitValues; test: SplitValues };

export type DiagnosisSchemaResponse = {
  classList: (string | null)[];
  targetIou: number;
  splits: DiagnosisSplits;
};

const getDiagnosisSchema: AfterLoginCallback<
  DiagnosisSchemaResponse,
  DiagnosisDependencies
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, fromPublicDatasets } = params.data;
  const endpoint = buildEndpoint(
    { datasetId, diagnosisId },
    'evaluation-value-filter-schema/',
    fromPublicDatasets,
  );
  const { data } = await apiCallAfterLogin({
    method: 'get',
    // url: appendQueryParams(endpoint, { expand: ['annotation_class', 'prediction_class'] }),
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

const deleteDiagnosis: AfterLoginCallback<
  { success: boolean }, // TODO: update respnse
  Pick<DiagnosisDependencies, 'datasetId' | 'diagnosisId' | 'fromPublicDatasets'>
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, fromPublicDatasets } = params.data;
  const endpoint = buildEndpoint({ datasetId, diagnosisId }, '', fromPublicDatasets);
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

export type ModelSource = 'external' | 'superb';

export type Splits = { [K in Split]: string[] };
export type DiagnosisPredictionCheck = {
  superb_val: boolean;
  superb_train: boolean;
  external_val: boolean;
  external_train: boolean;
};
export type DiagnosisDetail = {
  id: string;
  modelId: string; // Used for linking to model
  modelName: string;
  modelSource: ModelSource;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  status: DiagnosisStatusEnum;
  iouType: IouTypeEnum;
  updatedImageCount?: number;
  deletedImageCount?: number;
  // predictionsExist: boolean;
  splits: { [K in Split]: string[] };
  predictionsCheck?: KeysToCamelCase<DiagnosisPredictionCheck>;
};

export type DiagnosisDetailExpandOption =
  | 'updated_image_count'
  | 'deleted_image_count'
  | 'predictions_check';
const getDiagnosisDetail: AfterLoginCallback<
  DiagnosisDetail,
  {
    datasetId: string;
    diagnosisId: string;
    expand?: DiagnosisDetailExpandOption[];
    fromPublicDatasets: boolean;
  }
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, expand, fromPublicDatasets } = params.data;
  const endpoint = buildEndpoint({ datasetId, diagnosisId }, '', fromPublicDatasets);
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: appendQueryParams(endpoint, { expand }),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

export type DiagnosisListResponse = { count: number; results: DiagnosisDetail[] };
export type DiagnosisListExpandOption = 'predictions_check';
const getDiagnosisList: AfterLoginCallback<
  DiagnosisListResponse,
  {
    datasetId: string;
    page?: number;
    size?: number;
    expand?: DiagnosisListExpandOption[];
    fromPublicDatasets: boolean;
  }
> = async params => {
  if (!params.data) return;
  const endpoint = diagnosisListEndpoint(params.data.datasetId, params.data.fromPublicDatasets);
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: appendQueryParams(endpoint, {
      page: params.data.page,
      size: params.data.size,
      expand: params.data.expand,
    }),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

const getLatestStartDiagnosisJob: AfterLoginCallback<
  Command<typeof START_DIAGNOSIS>,
  { diagnosisId: string }
> = async params => {
  const { diagnosisId } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/start-diagnosis/diagnoses/${diagnosisId}/latest/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

const getLatestUpdateDiagnosisJob: AfterLoginCallback<
  Command<typeof UPDATE_DIAGNOSIS>,
  { diagnosisId: string }
> = async params => {
  const { diagnosisId } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/update-diagnosis/diagnoses/${diagnosisId}/latest/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

export function useDiagnosisModelService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getDiagnosisSchema: afterLoginFetcher(getDiagnosisSchema),
    deleteDiagnosis: afterLoginFetcher(deleteDiagnosis),
    getDiagnosisDetail: afterLoginFetcher(getDiagnosisDetail),
    getDiagnosisList: afterLoginFetcher(getDiagnosisList),
    getLatestStartDiagnosisJob: afterLoginFetcher(getLatestStartDiagnosisJob),
    getLatestUpdateDiagnosisJob: afterLoginFetcher(getLatestUpdateDiagnosisJob),
  };
}
