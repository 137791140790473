import { useQueries, useQuery } from '@tanstack/react-query';

import {
  AnalyticsParams,
  useCurateAnalyticsService,
} from '../../../../../services/AnalyticsService';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { stringifyFilters } from '../../../../../utils/filterUtils';
import { ImageFilterSchema } from '../../filter/types';
import {
  annotationsStatsTransformer,
  annotationTypeStatsTransformer,
  classificationKeyStatsTransformer,
  classificationValueStatsTransformer,
  classStatsTransformer,
} from '../utils/transformer';

export type Dependencies = {
  datasetId: string;
  fromPublicDatasets: boolean;
  sliceId?: string;
  slice?: string;
  queryString?: string;
  totalCount?: number; // in case total image count changes. not strictly necessary tbh
  annotationType?: AnalyticsParams['annotationType'][];
  appliedFilters?: ImageFilterSchema;
  disabled?: boolean;
};
export function useStatsClassQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  totalCount,
  appliedFilters,
}: Dependencies) {
  const { getStatsClass } = useCurateAnalyticsService();
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const stringifiedFilters = stringifyFilters(appliedFilters);

  return useQuery(
    ['stats-class', datasetId, sliceId, queryString, totalCount, stringifiedFilters],
    async () => {
      const result = await getStatsClass({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
        appliedFilters,
      });
      const resultDataCount = await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets,
        size: 0,
        sliceName: slice ? slice : undefined,
        ...(queryString && { query: queryString }),
        appliedFilters,
      });
      const queryImageCount = resultDataCount.count;
      return {
        data: classStatsTransformer(result?.data || [], queryImageCount),
        totalImageCount: queryImageCount,
      };
    },
  );
}

export function useStatsClassWithAnnotationTypeQueries({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  totalCount,
  annotationType = [],
  appliedFilters,
  disabled,
}: Dependencies) {
  const { getStatsClass } = useCurateAnalyticsService();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  const queries = annotationType?.map(type => {
    return {
      queryKey: [
        'stats-class',
        datasetId,
        sliceId,
        queryString,
        totalCount,
        type,
        slice,
        annotationType,
        stringifiedFilters,
      ],
      queryFn: async () => {
        const result = await getStatsClass({
          datasetId: datasetId,
          fromPublicDatasets: fromPublicDatasets,
          slice: slice,
          query: queryString,
          annotationType: type,
          appliedFilters,
        });

        return {
          data: result?.data || [],
        };
      },
      enabled: Boolean(datasetId) && !disabled,
    };
  });

  return useQueries({ queries });
}

// compared requests do not include filter or cluster level
export function useStatsComparedClassQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  totalCount,
  isCompareMode,
  compareSetId,
}: Omit<Dependencies, 'clusterLevel' | 'appliedFilters'> & {
  isCompareMode: boolean;
  compareSetId: string;
}) {
  const { getStatsClass } = useCurateAnalyticsService();
  const { getDatasetBriefDataList } = useCurateDatasetService();

  const filterBySlice = { slice };
  return useQuery(
    ['stats-compared-class', datasetId, sliceId, totalCount, compareSetId],
    async () => {
      const result = await getStatsClass({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        ...(compareSetId === sliceId && filterBySlice),
      });
      const resultDataCount = await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets,
        size: 0,
        ...(compareSetId === sliceId && { sliceName: slice }),
      });
      const queryImageCount = resultDataCount.count;
      return {
        data: classStatsTransformer(result?.data || [], queryImageCount),
        totalImageCount: queryImageCount,
      };
    },
    {
      enabled: isCompareMode || !!queryString,
    },
  );
}

export function useStatsAnnotationTypesQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  appliedFilters,
}: Dependencies) {
  const { getStatsAnnotationTypes } = useCurateAnalyticsService();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  // TODO (moon) check with SH or YH that excluding fromPublicDatasets from query dependency list for all APIs is intentional
  return useQuery({
    queryKey: ['stats-annotation-types', datasetId, sliceId, queryString, stringifiedFilters],
    queryFn: async () => {
      const result = await getStatsAnnotationTypes({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
        appliedFilters,
      });
      return annotationTypeStatsTransformer(result?.data || []);
    },
    refetchOnWindowFocus: false,
  });
}

export function useStatsAnnotationsQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  appliedFilters,
}: Dependencies) {
  const { getStatsAnnotations } = useCurateAnalyticsService();
  const stringifiedFilters = stringifyFilters(appliedFilters);

  return useQuery(
    ['stats-annotations', datasetId, sliceId, queryString, stringifiedFilters],
    async () => {
      const result = await getStatsAnnotations({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
        appliedFilters,
      });
      return annotationsStatsTransformer(result?.histogram || []);
    },
  );
}

// TODO (moon) classification API 연동시, appliedFilters도 추가필요
export function useStatsClassificationKeyQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
}: Dependencies) {
  const { getStatsClassificationKey } = useCurateAnalyticsService();
  return useQuery({
    queryKey: ['stats-classification-key', datasetId, sliceId, queryString],
    queryFn: () => {
      const result = getStatsClassificationKey({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
      });
      return result;
    },
    select: data => classificationKeyStatsTransformer(data),
    enabled: true, // TODO: check
    refetchOnWindowFocus: false,
  });
}

type CompareParams = {
  isCompareMode: boolean;
  compareSetId: string;
};
export function useStatsComparedClassificationQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  totalCount,
  isCompareMode,
  compareSetId,
}: Dependencies & CompareParams) {
  const { getStatsClassificationKey } = useCurateAnalyticsService();
  const filterBySlice = { slice };
  return useQuery({
    queryKey: ['stats-compared-classification-key', datasetId, sliceId, totalCount, compareSetId],
    queryFn: () => {
      const result = getStatsClassificationKey({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        ...(compareSetId === sliceId && filterBySlice),
      });
      return result;
    },
    select: data => classificationKeyStatsTransformer(data),
    enabled: isCompareMode || !!queryString,
  });
}

export function useStatsClassificationValueQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  classificationKey,
}: Dependencies & { classificationKey: string }) {
  const { getStatsClassificationValue } = useCurateAnalyticsService();
  return useQuery({
    queryKey: ['stats-classification-value', datasetId, sliceId, queryString, classificationKey],
    queryFn: () => {
      const result = getStatsClassificationValue({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
        classificationKey: classificationKey ?? 'weather',
      });
      return result;
    },
    select: data => classificationValueStatsTransformer(data),
    enabled: typeof classificationKey !== undefined,
    refetchOnWindowFocus: false,
  });
}

export function useStatsComparedClassificationValueQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
  classificationKey,
  isCompareMode,
  compareSetId,
}: Dependencies & { classificationKey: string } & CompareParams) {
  const { getStatsClassificationValue } = useCurateAnalyticsService();
  const filterBySlice = { slice };
  return useQuery({
    queryKey: [
      'stats-compared-classification-value',
      datasetId,
      sliceId,
      queryString,
      compareSetId,
      classificationKey,
    ],
    queryFn: () => {
      const result = getStatsClassificationValue({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        classificationKey,
        ...(compareSetId === sliceId && filterBySlice),
      });
      if (!result) {
        throw new Error('Failed to fetch data');
      }
      return result;
    },
    enabled: isCompareMode && !!classificationKey && classificationKey !== '',
    select: data => data,
  });
}

export function useStatsClassificationValuesAllQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  slice,
  queryString,
}: Dependencies) {
  const { getStatsClassificationValuesAll } = useCurateAnalyticsService();
  return useQuery({
    queryKey: ['stats-classification-value-all', datasetId, sliceId, queryString],
    queryFn: () => {
      const result = getStatsClassificationValuesAll({
        datasetId: datasetId,
        fromPublicDatasets: fromPublicDatasets,
        slice: slice,
        query: queryString,
      });
      return result;
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });
}
