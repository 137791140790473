import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  box: {
    // width: "100%",
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#303030',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '11px',
    color: '#9b9b9b',
  },
  optional: {
    marginLeft: 4,
    fontWeight: 400,
    fontSize: '11px',
    color: theme.palette.grey[500],
  },
}));

interface Props {
  title?: string;
  subTitle?: string;
  isOptional?: boolean;
}

const Title = (props: Props): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { title = '', subTitle = '', isOptional = false } = props;

  return (
    <MUI.Box className={classes.box}>
      <MUI.Typography className={classes.title}>{title}</MUI.Typography>
      {isOptional && (
        <MUI.Typography className={classes.optional} variant="body2" color="textSecondary">
          ({t('forms.optional')})
        </MUI.Typography>
      )}
      {subTitle && <MUI.Typography className={classes.subTitle}>{subTitle}</MUI.Typography>}
    </MUI.Box>
  );
};

export default Title;
