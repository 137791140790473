import { useEffect, useRef } from 'react';

import { suspend } from 'suspend-react';

/**
 * A promise that rejects after a certain time. The promise can be canceled.
 */
class CancelableTimeout extends Promise<void> {
  timeout?: NodeJS.Timeout;
  constructor(t: number) {
    super((_resolve, reject) => {
      setTimeout(() => {
        // nextTick because `this` isn't available yet
        this.timeout = setTimeout(() => {
          reject(new Error('suspend timeout'));
        }, t);
      });
    });
  }
  cancel() {
    if (this.timeout) clearTimeout(this.timeout);
  }
  static get [Symbol.species](): PromiseConstructor {
    return Promise;
  }
  get [Symbol.toStringTag](): string {
    return 'CancelableTimeout';
  }
}

/**
 * Make a component suspend until a condition is met.
 * Call this AFTER any other dependency effects/hooks that you read in the check function.
 * This doesn't re-render and re-check the condition on its own,
 * it just implements suspense with a timeout.
 * The timeout is just a failsafe, not expected UX.
 * Set the key to something unique. See https://github.com/pmndrs/suspend-react
 */
export function useSuspendUntil(fn: () => boolean, keys: (string | number)[], t = 15000) {
  const promise = useRef<CancelableTimeout>();
  const suspendId = useRef(Symbol());

  useEffect(() => {
    if (!fn()) {
      promise.current = new CancelableTimeout(t);
    }
    return () => {
      promise.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...keys, fn, t]);

  if (!fn() && promise.current) {
    suspend(() => promise.current!, [...keys, suspendId]);
  }
}
