import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    width: '100%',
    height: '6px',
    background: 'none',
    borderRadius: '4px',
    zIndex: 20,
    '&.prev': {
      top: 0,
    },
    '&.next': {
      bottom: 0,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  },
}));

// eslint-disable-next-line
const ObjectClassItemDropBar = (props: any): React.ReactElement | null => {
  const newProjectInfo = useNewProjectInfo();
  const { dir, index } = props;
  const classes = useStyles();

  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.index === index ||
    newProjectInfo.dragState.type !== 'objectClass'
  ) {
    if (isDragEnter) {
      setIsDragEnter(false);
    }
    return null;
  }
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);

    let selectedObjectClassId = '';

    if (nextObjectClasses[newProjectInfo.selectedObjectClassIndex]) {
      selectedObjectClassId = nextObjectClasses[newProjectInfo.selectedObjectClassIndex].id;
    }

    const fromObjectClass = nextObjectClasses.splice(newProjectInfo.dragState.index, 1)[0];

    let idx = index;
    if (dir === 'next') {
      if (newProjectInfo.dragState.index > index) {
        idx += 1;
      }
    }
    nextObjectClasses.splice(idx, 0, fromObjectClass);
    newProjectInfo.setObjectClasses(nextObjectClasses);

    if (selectedObjectClassId) {
      newProjectInfo.setSelectedObjectClassIndex(
        nextObjectClasses.findIndex((objectClass: any) => objectClass.id === selectedObjectClassId),
      );
    }
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default ObjectClassItemDropBar;
