import { Box, Tooltip } from '@superb-ai/ui';

import { FORMAT_COUNT } from '../../../../../../../components/pages/analytics/config/d3Formatters';
import PercentageBar, { ProgressChartDatum } from './PercentageBar';

export default function PercentageBarChart({
  width = '100%',
  height = 16,
  chartDatum,
  showLegend = false,
  showTooltip = true,
}: {
  width?: string;
  height?: number;
  chartDatum: ProgressChartDatum[];
  showLegend?: boolean;
  showTooltip?: boolean;
}) {
  return (
    <Box backgroundColor="gray-200" style={{ borderRadius: '0 2px 2px 0', height, width }}>
      {chartDatum.map(data =>
        showTooltip ? (
          <Tooltip
            key={data.key}
            content={
              <Box>
                <b>{data.name}</b>
                <br />
                {FORMAT_COUNT(data.value)} / {FORMAT_COUNT(data.total)}
                <b> ({data?.share} %)</b>
              </Box>
            }
            placement="top"
          >
            <Box>
              <PercentageBar
                height={height / chartDatum.length}
                chartData={data}
                showLegend={showLegend}
              />
            </Box>
          </Tooltip>
        ) : (
          <PercentageBar
            key={data.key}
            height={height / chartDatum.length}
            chartData={data}
            showLegend={showLegend}
          />
        ),
      )}
    </Box>
  );
}
