import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Icon, Typography } from '@superb-ai/ui';
import { capitalize, without } from 'lodash';

import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { AnnotationType } from '../../../../types/annotationTypes';
import { getAnnotationIcon } from '../views/grid/utils';
import { CategoryCheckbox } from './CategoryCheckbox';

export default function AnnotationTypeFilter() {
  const { t } = useTranslation();
  const {
    selectedAnnotationTypes,
    setSelectedAnnotationTypes,
    appliedFilters,
    setAppliedFilters,
    filterSchema,
  } = useObjectFilterContext();
  const annotationTypeSchema = filterSchema?.annotation_type_in;

  const categoryState: boolean | 'mixed' =
    annotationTypeSchema &&
    selectedAnnotationTypes &&
    annotationTypeSchema.length === selectedAnnotationTypes.length
      ? true
      : selectedAnnotationTypes && selectedAnnotationTypes.length > 0
      ? 'mixed'
      : false;

  function handleClickCategoryCheckbox() {
    if (!annotationTypeSchema) return;
    if (categoryState && categoryState !== 'mixed') {
      setSelectedAnnotationTypes([]);
      return;
    }
    setSelectedAnnotationTypes(annotationTypeSchema);
  }

  function handleClickItemCheckbox(item: string) {
    const newSelectedItems =
      selectedAnnotationTypes && selectedAnnotationTypes.indexOf(item) >= 0
        ? without(selectedAnnotationTypes, item)
        : [...(selectedAnnotationTypes || []), item];

    setSelectedAnnotationTypes(newSelectedItems);
  }

  return (
    <Box display="grid" style={{ gridTemplateRows: 'auto 1fr' }} gap={0.5}>
      <CategoryCheckbox
        label={t('curate.annotationFilter.annotationTypeFilter')}
        state={categoryState}
        onClick={handleClickCategoryCheckbox}
        appliedCount={appliedFilters.annotation_type_in?.length || 0}
        handleReset={() => {
          setAppliedFilters({ ...appliedFilters, annotation_type_in: [] });
          setSelectedAnnotationTypes([]);
        }}
      />
      <Box overflow="auto">
        {annotationTypeSchema?.map(item => (
          <Box key={item} display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              value={
                categoryState && !!selectedAnnotationTypes && selectedAnnotationTypes.includes(item)
              }
              onClick={() => handleClickItemCheckbox(item)}
            />
            <Icon icon={getAnnotationIcon(item as AnnotationType)} />
            <Typography variant="m-regular">{capitalize(item)}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
