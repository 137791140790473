import { merge } from 'lodash';

export const COLOR_CHIP_TAG_DEFAULT = {
  SALMON: '#FE9573',
  GRAPEFRUIT: '#FF625A',
  PALE_ORANGE: '#FFAF5A',
  SUNFLOWER_YELLOW: '#FFCC00',
  GREENY_YELLOW: '#DEF00F',
  KIWI: '#A3EB57',
  AQUA_MARINE: '#4AE2B9',
  SKY_BLUE: '#72BBFF',
  LIGHTISH_BLUE: '#5A7BFF',
  LIGHT_ROYAL_BLUE: '#6648FF',
  BRIGHT_LAVENDER: '#AF48FF',
  WARM_PINK: '#FF4881',
  GREYISH: '#A6A6A6',
};

export const COLOR_CHIP_TAG_10PERCENT_OPACITY = {
  VERY_LIGHT_PINK_TWO: '#FFF4EF',
  VERY_LIGHT_PINK: '#FFEEEE',
  VERY_LIGHT_PINK_THREE: '#FFF6ED',
  OFF_WHITE: '#FFF9E3',
  OFF_WHITE_TWO: '#FAFDE4',
  OFF_WHITE_THREE: '#F3FEED',
  DUCK_EGG_BLUE: '#E8FDF8',
  ICE_BLUE: '#EEF8FF',
  VERY_LIGHT_BLUE: '#EDF1FF',
  PALE_LILAC: '#F0ECFF',
  PALE_LAVENDER: '#FAEBFF',
  LIGHT_PINK: '#FFEBF2',
  WHITE: '#F6F6F6',
};

export const COLOR_CHIP_TAG_30PERCENT_OPACITY = {
  LIGHT_PEACH_TWO: '#FFDED2',
  LIGHT_PEACH: '#FFCDCC',
  PALE: '#FFE5CA',
  PALE_PEACH: '#FFEFAA',
  LIGHT_TAN: '#F3FBAD',
  VERY_PALE_GREEN: '#DDFAC7',
  DUCK_EGG_BLUE_TWO: '#BAF8EA',
  LIGHT_SKY_BLUE: '#CEEBFF',
  LIGHT_PERIWINKLE: '#CBD7FF',
  LIGHT_LAVENDER: '#D4C7FF',
  LIGHT_LILAC: '#EEC6FF',
  PALE_PINK: '#FFC5D9',
  WHITE_TWO: '#E4E4E4',
};

export const COLOR_CHIP_TAG_60PERCENT_OPACITY = {
  PALE_SALMON: '#FFBCA7',
  PEACHY_PINK: '#FF9B99',
  PALE_SALMON_TWO: '#FFCD94',
  LIGHT_GOLD: '#FFE049',
  OFF_YELLOW: '#E7F853',
  PISTACHIO: '#BAF68F',
  TIFFANY_BLUE: '#69F1D4',
  BABY_BLUE: '#9CD7FF',
  CAROLINA_BLUE: '#97AFFF',
  PERIWINKLE: '#A88EFF',
  LILIAC: '#DC8BFF',
  PINK: '#FF89B4',
  PINKISH_GREY: '#CACACA',
};

export const COLOR_CHIP_VARIATION_PALETTE = merge(
  COLOR_CHIP_TAG_10PERCENT_OPACITY,
  COLOR_CHIP_TAG_30PERCENT_OPACITY,
  COLOR_CHIP_TAG_60PERCENT_OPACITY,
);

export const COLOR_CHIP_PALETTE = merge(COLOR_CHIP_TAG_DEFAULT, COLOR_CHIP_VARIATION_PALETTE);

export const SALMON_GRADIENT = {
  SALMON_1: { color: COLOR_CHIP_PALETTE.SALMON, opacity: 1 },
  SALMON_2: { color: COLOR_CHIP_PALETTE.PALE_SALMON, opacity: 1 },
  SALMON_3: { color: '#FFDFD3', opacity: 1 },
  SALMON_4: { color: COLOR_CHIP_PALETTE.SALMON, opacity: 0.1 },
};

export const STATUS_COLORS = {
  SUBMITTED: { color: COLOR_CHIP_TAG_DEFAULT.KIWI, opacity: 1 },
  WORKING: { color: COLOR_CHIP_TAG_DEFAULT.SUNFLOWER_YELLOW, opacity: 1 },
  SKIPPED: { color: COLOR_CHIP_TAG_DEFAULT.GREYISH, opacity: 1 },
};

export const DARK_GRAY = { color: '#909090', opacity: 1 };

export function randomColor(PALLET: Record<string, string> = COLOR_CHIP_PALETTE): string {
  const COLOR_NAME_LIST = Object.keys(PALLET);
  return PALLET[COLOR_NAME_LIST[Math.floor(Math.random() * (COLOR_NAME_LIST.length + 1))]];
}

export function pseudoRandomColor(
  seed: string,
  PALLET: Record<string, string> = COLOR_CHIP_PALETTE,
): string {
  const COLOR_NAME_LIST = Object.keys(PALLET);

  if (!seed || seed.length === 0) {
    return PALLET[COLOR_NAME_LIST[0]];
  }

  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    const charCode = seed.charCodeAt(i);
    hash = (hash << 7) - hash + charCode;
    hash &= hash;
  }
  return PALLET[COLOR_NAME_LIST[Math.abs(hash) % COLOR_NAME_LIST.length]];
}

export function asRGBA(color: string, opacity = 1.0): string {
  const intColor: number[] = [];

  if (color.length === 7 || color.length == 4) {
    const step = (color.length - 1) / 3;
    for (let i = 1; i < color.length; i += step) {
      const val = parseInt(color.slice(i, i + step), 16);
      const maxVal = color.length === 7 ? 255 : 15;
      intColor.push(Math.floor((val / maxVal) * 255.0));
    }
    const res =
      'rgba(' +
      intColor
        .map(e => {
          return e.toFixed(0).toString();
        })
        .join(',') +
      ',' +
      opacity.toString() +
      ')';
    return res;
  } else {
    return color;
  }
}
