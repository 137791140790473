import React from 'react';
import { useHistory, useParams } from 'react-router';

import * as MUI from '@mui/material';
import { Icon } from '@superb-ai/norwegian-forest';

import {
  canManageProject,
  isOwnerOrAdminOrSystemAdmin,
  useAuthInfo,
} from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';

const ProjectConfigSettingButton: React.FC = () => {
  const { accountName } = useParams<{ accountName: string }>();
  const authInfo = useAuthInfo();
  const {
    project: { curateDatasetId, id },
  } = useProjectInfo();
  const history = useHistory();

  const connectedCurateDatasetId = curateDatasetId;

  const handleClickProjectConfigurationSetting = () => {
    if (connectedCurateDatasetId) {
      history.push(
        `/${accountName}/curate/dataset/${connectedCurateDatasetId}/settings#edit-synced-label-project=${id}`,
      );
      return;
    }
    history.push(`/${accountName}/label/project/${id}/settings`);
  };

  if (!canManageProject(authInfo)) return null;
  if (connectedCurateDatasetId && !isOwnerOrAdminOrSystemAdmin(authInfo)) return null;

  return (
    <MUI.IconButton onClick={handleClickProjectConfigurationSetting} size="small">
      <Icon name="settings" color="textDefault" size="16px" />
    </MUI.IconButton>
  );
};

export default ProjectConfigSettingButton;
