import { Box, Tooltip } from '@superb-ai/norwegian-forest';

import UserAvatar from '../../../../elements/UserAvatar';
import { JsonObj } from '../../userStats/types';

function UserAvatarAndName(props: {
  userInfo: JsonObj;
  email: string;
  name?: string;
}): JSX.Element {
  const { userInfo, email, name } = props;
  return (
    <Tooltip
      placement="bottom"
      anchorEl={
        <Box display="flex" alignItems="center">
          <UserAvatar userInfo={userInfo} size={24} noShadow />
          {name || 'No Assignee'}
        </Box>
      }
    >
      {email}
    </Tooltip>
  );
}

export default UserAvatarAndName;
