import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';

import ImageContext from '../../../../../contexts/ImageContext';
import Tuners from './Tuners';

const useStyles = makeStyles(theme => ({
  menuItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  item: {
    padding: '4px 16px 8px',
    fontSize: '13px',
  },
  icon: {
    marginRight: '4px',
  },
}));

interface Props {
  onCloseMenu: () => void;
}

const FloatingMenu: React.FC<Props> = ({ onCloseMenu }) => {
  const classes = useStyles();
  const theme = useTheme();
  const imageInfo = React.useContext(ImageContext.Context);

  const { adjustImage } = imageInfo;

  const hanelClickFitPage = () => {
    adjustImage();
    onCloseMenu();
  };

  return (
    <MUI.Box
      ml="8px"
      boxShadow={theme.shadows[1]}
      p="4px 0 10px"
      bgcolor="#fff"
      width="300px"
      borderRadius="8px"
    >
      <MUI.Box display="flex" justifyContent="flex-end" mr="4px">
        <MUI.IconButton size="small" onClick={onCloseMenu}>
          <Icon name="clear" size="16px" />
        </MUI.IconButton>
      </MUI.Box>
      <Tuners menuClasses={classes} />
      <MUI.Box className={classnames(classes.menuItem, classes.item)} onClick={hanelClickFitPage}>
        <Icon name="zoomOutMap" className={classes.icon} size="12px" />
        Fit Screen (R)
      </MUI.Box>
    </MUI.Box>
  );
};

export default FloatingMenu;
