import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoFilled } from '@superb-ai/icons';
import { Box, Icon, LoadingIndicator, Tooltip, Typography } from '@superb-ai/ui';

import { Row } from '../../../components/elements/Row';
import { formatCount } from '../../../utils/numberFormat';
import { convertSecondsToTime } from '../utils/formatUtils';

type Props = {
  imageCount?: number;
  trainingTimeSeconds?: number;
  creditsPerHour?: number;
  isLoading: boolean;
};

export function Estimates(props: Props) {
  const { imageCount, trainingTimeSeconds, creditsPerHour, isLoading } = props;
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      <LabelAndValue
        label={
          <>
            <Row gap={0.5}>
              <Typography variant="m-regular">{t('model.train.totalImages')}</Typography>
              <Tooltip placement="top-start" content={t('model.train.totalImagesInfoText')}>
                <Icon icon={InfoFilled} />
              </Tooltip>
            </Row>
          </>
        }
        value={typeof imageCount !== 'undefined' ? formatCount(imageCount) : '-'}
        isLoading={isLoading}
      />
      <VerticalDivider />
      <LabelAndValue
        label={
          <>
            <Row gap={0.5}>
              <Typography variant="m-regular">{t('model.train.estimatedTrainingTime')}</Typography>
              <Tooltip placement="top" content={t('model.train.estimatedTrainingTimeInfoText')}>
                <Icon icon={InfoFilled} />
              </Tooltip>
            </Row>
          </>
        }
        value={trainingTimeSeconds ? convertSecondsToTime(trainingTimeSeconds) : '-'}
        isLoading={isLoading}
      />
      <VerticalDivider />
      <LabelAndValue
        label={t('model.train.hourlyRate')}
        value={t('metering.aiCredits', { count: creditsPerHour })}
      />
    </Box>
  );
}

function LabelAndValue({
  label,
  value,
  isLoading = false,
}: {
  label: ReactNode;
  value: ReactNode;
  isLoading?: boolean;
}) {
  // Overlaying the loading indicator above the previous value is a trick to
  // make the UI jump around less while changing settings
  const loadingIndicator = (
    <Box position="absolute" inset="0" backgroundColor="white" display="grid">
      <LoadingIndicator type="spinner-small" size="xs" color="gray-300" />
    </Box>
  );
  return (
    <Box display="flex" gap={2}>
      <Typography variant="m-regular" whiteSpace="nowrap">
        {label}
      </Typography>
      <Typography
        variant="m-medium"
        textAlign="right"
        whiteSpace="nowrap"
        position="relative"
        style={{ minWidth: 40 }}
      >
        {isLoading && loadingIndicator}
        {value}
      </Typography>
    </Box>
  );
}

function VerticalDivider() {
  return <Box backgroundColor="gray-250" style={{ width: '1px', height: '8px' }} />;
}
