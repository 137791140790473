import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, getColor, Icon, Tooltip, Typography } from '@superb-ai/ui';
import capitalize from 'lodash/capitalize';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { HistoryType } from '../../../../types/exportTypes';
import LabelUtils from '../../../../utils/LabelUtils';
import { formatCount } from '../../../../utils/numberFormat';
import GridTable from '../../account/advanced/GridTable';
import Accordion from './Accordion';

interface Props {
  annotations: HistoryType['annotations'];
  totalObjectCount: number;
  totalCategoryCount: number;
}

export default function CountTableAccordion({
  annotations,
  totalCategoryCount,
  totalObjectCount,
}: Props) {
  const { t } = useTranslation();
  const {
    project: { labelInterface },
  } = useProjectInfo();
  const categories = LabelUtils.getCategoriesInAnnotationCount(
    annotations?.category.results ?? [],
    labelInterface,
  );

  return (
    <>
      <Accordion title={`${t('shared.object')} (${totalObjectCount.toLocaleString('es-US')})`}>
        <GridTable>
          <GridTable.Thead>
            <GridTable.Tr px={1.5} py={1.5}>
              <GridTable.Th width="1fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('components.shared.type')}</Typography>
              </GridTable.Th>
              <GridTable.Th width="4fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('users.table.name')}</Typography>
              </GridTable.Th>
              <GridTable.Th width="3fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('export.history.counts')}</Typography>
              </GridTable.Th>
            </GridTable.Tr>
          </GridTable.Thead>
          <GridTable.Tbody>
            {annotations?.objectClass.results.map(({ id, count }) => {
              const { annotationType, name } =
                labelInterface?.objectDetection?.objectClasses.find(
                  ({ id: classId }: { id: string }) => classId === id,
                ) ?? {};

              return (
                <GridTable.Tr px={1.5} py={1} key={id}>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Box gap={1} display="flex" alignItems="center">
                      {LabelUtils.getAnnotationIcon(annotationType) && (
                        <Icon icon={LabelUtils.getAnnotationIcon(annotationType)} />
                      )}
                      <Typography variant="m-regular">{capitalize(annotationType)}</Typography>
                    </Box>
                  </GridTable.Td>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Typography variant="m-regular">{name}</Typography>
                  </GridTable.Td>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Typography variant="m-regular">{formatCount(count)}</Typography>
                  </GridTable.Td>
                </GridTable.Tr>
              );
            })}
          </GridTable.Tbody>
        </GridTable>
      </Accordion>
      <Accordion
        title={`${t('components.shared.categorization')} (${formatCount(totalCategoryCount)})`}
      >
        <GridTable>
          <GridTable.Thead>
            <GridTable.Tr px={1.5} py={1.5}>
              <GridTable.Th width="1fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('components.shared.type')}</Typography>
              </GridTable.Th>
              <GridTable.Th width="1fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('users.table.name')}</Typography>
              </GridTable.Th>
              <GridTable.Th width="3fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('labelInterface.categoryOptions')}</Typography>
              </GridTable.Th>
              <GridTable.Th width="3fr" style={{ background: getColor('gray-100') }}>
                <Typography variant="m-regular">{t('export.history.counts')}</Typography>
              </GridTable.Th>
            </GridTable.Tr>
          </GridTable.Thead>
          <GridTable.Tbody>
            {categories.map(({ id, count }) => {
              const { type, name, options } =
                labelInterface?.categorization?.properties.find(
                  ({ id: categoryId }: { id: string }) => categoryId === id,
                ) ?? {};

              return (
                <GridTable.Tr px={1.5} py={1} key={id}>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Box gap={1} display="flex" alignItems="center">
                      {LabelUtils.getCategoryIcon(type) && (
                        <Icon icon={LabelUtils.getCategoryIcon(type) as ComponentType<{}>} />
                      )}
                      <Typography variant="m-regular">{capitalize(type)}</Typography>
                    </Box>
                  </GridTable.Td>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Typography variant="m-regular">{name}</Typography>
                  </GridTable.Td>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Tooltip
                      content={options?.map(({ name }: { name: string }) => name).join(', ')}
                      placement="top"
                      strategy="fixed"
                    >
                      <Box
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        style={{ maxWidth: '95%' }}
                      >
                        <Typography variant="m-regular">
                          {options?.map(({ name }: { name: string }) => name).join(', ')}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </GridTable.Td>
                  <GridTable.Td style={{ background: getColor('gray-100') }}>
                    <Typography variant="m-regular">{formatCount(count)}</Typography>
                  </GridTable.Td>
                </GridTable.Tr>
              );
            })}
          </GridTable.Tbody>
        </GridTable>
      </Accordion>
    </>
  );
}
