import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';

import { Box, Button, Typography } from '@superb-ai/ui';

import { getParentPath, getUrl } from '../../../../../routes/util';
import getContents from '../contents';
import { TargetType } from '../types';
import DetailInfoTabs from './DetailInfoTabs';
import IntegrationList from './IntegrationList';

const Integration = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const match = useRouteMatch<{ type: TargetType }>();
  const target = match.params.type;

  const content = useMemo(() => {
    return getContents(t, i18n.language)[target];
  }, [t, target]);

  if (!content) {
    return <Redirect to={getParentPath(match.url)} />;
  }

  const { title, description, logo } = content;

  return (
    <Box>
      <Box display="flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border="1px solid"
          borderColor="gray-200"
          style={{ width: '160px', height: '160px' }}
        >
          <Box style={{ width: '72px' }}>{logo}</Box>
        </Box>
        <Box ml={4} display="flex" flexDirection="column" justifyContent="space-between">
          <Box mt={1}>
            <Typography variant="h3">{title}</Typography>
            <Box mt={2}>{description}</Box>
          </Box>
          <Box style={{ width: '98px' }} display="grid">
            <Button
              variant="strong-fill"
              color="primary"
              {...({ as: Link, to: getUrl([match.url, 'add']) } as any)}
            >
              {t('button.add')}
            </Button>
          </Box>
        </Box>
      </Box>
      <IntegrationList target={target} />
      <Box mt={3}>
        <DetailInfoTabs target={target} />
      </Box>
    </Box>
  );
};

export default Integration;
