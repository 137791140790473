import React, { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Download } from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, Tooltip, useDialogState } from '@superb-ai/ui';

import { DeselectedData, SelectedData } from '../../../../contexts/ActionContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../../elements/SampleDatasetTooltip';
import { ClusterLevel } from '../filter/types';
import PrepareDownloadModal from '../modal/PrepareDownloadModal';

export default function PrepareDownloadButton({
  variant = 'icon',
  imageTotalCount,
  selectedData,
  deselectedData,
  sliceName,
  sliceId,
  datasetId,
  datasetName,
  queryString,
  filter,
  onClick,
  buttonProps,
}: {
  variant?: 'text' | 'icon';
  imageTotalCount: number;
  selectedData?: SelectedData[];
  deselectedData?: DeselectedData[];
  sliceName?: string;
  sliceId?: string;
  datasetId: string;
  datasetName: string;
  queryString?: string;
  filter?: {
    cluster_id_in: string[] | [];
    cluster_level: ClusterLevel;
  };
  onClick?: (e: any) => void;
  buttonProps?: ComponentProps<typeof Button>;
}) {
  const { t } = useTranslation();
  const dialog = useDialogState();

  const { showPublicDatasets } = usePublicDatasetContext();

  function TooltipMessage() {
    if (imageTotalCount === 0) {
      return (
        <>
          {variant === 'icon' && (
            <>
              {t('curate.datasets.prepareDownload.modalTitle')}
              <br />
            </>
          )}
          {t('curate.datasets.sampleDataset.buttonTooltip.prepareDownload.noDataTooltip')}
        </>
      );
    }
    if (showPublicDatasets) {
      return (
        <>
          {variant === 'icon' && (
            <>
              {t('curate.datasets.prepareDownload.modalTitle')}
              <br />
            </>
          )}
          <SampleDatasetTooltip feature="prepareDownload" />
        </>
      );
    }
    if (
      (selectedData && selectedData.length !== 0 && selectedData.length !== imageTotalCount) ||
      (deselectedData && deselectedData.length !== 0)
    ) {
      return (
        <>
          {t('curate.datasets.prepareDownload.modalTitle')} <br />
          <Trans
            t={t}
            i18nKey={
              'curate.datasets.sampleDataset.buttonTooltip.prepareDownload.onlyEntireTooltip'
            }
          />
        </>
      );
    }
    return variant === 'icon' && <>{t('curate.datasets.prepareDownload.modalTitle')}</>;
  }
  // TODO: Check model ai credit
  return (
    <Box onClick={onClick}>
      <Tooltip strategy="fixed" content={TooltipMessage()} hideOnEmptyContent placement="top">
        <Box>
          {variant === 'icon' ? (
            <IconButton
              variant="text"
              onClick={e => {
                e.stopPropagation();
                dialog.show();
              }}
              icon={Download}
              disabled={
                showPublicDatasets ||
                imageTotalCount === 0 ||
                (selectedData &&
                  selectedData.length !== 0 &&
                  selectedData.length !== imageTotalCount) ||
                (deselectedData && deselectedData.length !== 0)
              }
              {...buttonProps}
            />
          ) : (
            <Button
              variant="strong-fill"
              color="primary"
              size="m"
              onClick={e => {
                e.stopPropagation();
                dialog.show();
              }}
              disabled={
                showPublicDatasets ||
                imageTotalCount === 0 ||
                (selectedData && selectedData.length !== 0) ||
                (deselectedData && deselectedData.length !== 0)
              }
              {...buttonProps}
            >
              <Icon icon={Download} />
              {t('curate.datasets.prepareDownload.buttonWithMessage')}
            </Button>
          )}
        </Box>
      </Tooltip>
      <PrepareDownloadModal
        dialog={dialog}
        imageTotalCount={imageTotalCount}
        sliceName={sliceName}
        sliceId={sliceId}
        datasetId={datasetId}
        datasetName={datasetName}
        queryString={queryString}
        filter={filter}
      />
    </Box>
  );
}
