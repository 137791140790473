import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@superb-ai/icons';
import { Box, Dropdown, Icon, Input, SelectList, Typography } from '@superb-ai/ui';
import { find } from 'lodash';

import {
  DiagnosisSplits,
  DiagnosisSplitSliceOption,
} from '../../../../../services/DiagnosisModelService';

export default function SliceDropdown({
  size = 'm',
  splits,
  target,
  disabled,
  sliceId,
  setSliceId,
}: {
  splits?: DiagnosisSplits;
  target: 'val' | 'train' | 'all';
  disabled?: boolean;
  size?: 's' | 'm';
  sliceId?: string;
  setSliceId: (sliceId?: string) => void;
}) {
  const { t } = useTranslation();
  const DEFAULT_ALL_SLICE = '*_all_slice';
  const [selectedOption, setSelectedOption] = React.useState<string>(sliceId || DEFAULT_ALL_SLICE);
  const [otherSliceSearch, setOtherSliceSearch] = React.useState<string>('');

  useEffect(() => {
    if (disabled) {
      setSelectedOption(DEFAULT_ALL_SLICE);
    }
  }, [disabled]);

  const getUniqueSliceOptions = (sliceOptions: { label: string; value: string }[][]) => [
    ...new Map(sliceOptions.flat().map(option => [option.value, option])).values(),
  ];

  const getSliceOptions = (targetSplits?: DiagnosisSplitSliceOption[], searchString?: string) =>
    targetSplits?.reduce((filteredOptions, split) => {
      const option = { value: split.id, label: split.name };
      if (!searchString || option.label.toLowerCase().includes(searchString.toLowerCase())) {
        filteredOptions.push(option);
      }
      return filteredOptions;
    }, [] as { value: string; label: string }[]) || [];

  const getModelTrainSliceOptions = (target: string, splits?: DiagnosisSplits) => [
    {
      value: DEFAULT_ALL_SLICE,
      label: `* ${t('curate.diagnosis.filterPanel.allSlices')}`,
    },
    ...(target === 'all'
      ? getUniqueSliceOptions([
          getSliceOptions(splits?.train.slices),
          getSliceOptions(splits?.val.slices),
        ])
      : getSliceOptions(splits?.[target as 'val' | 'train'].slices)),
  ];

  const getRelatedSliceOptions = (target: string, splits?: DiagnosisSplits) =>
    target === 'all'
      ? getUniqueSliceOptions([
          getSliceOptions(splits?.train.relatedSlices, otherSliceSearch),
          getSliceOptions(splits?.val.relatedSlices, otherSliceSearch),
        ])
      : getSliceOptions(splits?.[target as 'val' | 'train'].relatedSlices, otherSliceSearch);

  const modelTrainSliceOptions = getModelTrainSliceOptions(target, splits);
  const relatedSliceOptions = getRelatedSliceOptions(target, splits);

  return (
    <Dropdown
      disabled={disabled}
      hideOnClick
      variant="soft-fill"
      size={size}
      disclosure={
        <Box display="flex" alignItems="center" gap={0.5} overflow="hidden" style={{ width: 140 }}>
          <Typography
            variant="m-regular"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {find([...modelTrainSliceOptions, ...relatedSliceOptions], { value: selectedOption })
              ?.label || ''}
          </Typography>
        </Box>
      }
      style={{ zIndex: 1000 }}
    >
      <Box backgroundColor="white" boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
        <Box px={1.5} py={0.5} display="flex" alignItems="center">
          <Typography variant="s-regular" color="gray-300">
            {t(`curate.diagnosis.filterPanel.selectedSlice.${target}`)}
          </Typography>
        </Box>
        <SelectList
          maxHeight={200}
          data={modelTrainSliceOptions}
          multiple={false}
          value={selectedOption}
          onChangeValue={value => {
            setSelectedOption(value);
            setSliceId(value === DEFAULT_ALL_SLICE ? undefined : value);
          }}
        />
        <Box px={1.5} py={0.5} borderTop="1px solid" borderColor="gray-150">
          <Typography variant="s-regular" color="gray-300">
            {t('curate.diagnosis.filterPanel.otherSlice')}
          </Typography>
        </Box>
        <Box mx={0.5} borderBottom="1px solid" borderColor="gray-150">
          <Input
            type="search"
            onClick={e => e.stopPropagation()}
            variant="text"
            prefix={<Icon icon={Search} />}
            placeholder={t('curate.slices.searchPlaceholder')}
            value={otherSliceSearch}
            onChange={e => setOtherSliceSearch(e.target.value)}
            onBlur={() => setOtherSliceSearch('')}
          />
        </Box>
        <SelectList
          maxHeight={300}
          data={relatedSliceOptions}
          multiple={false}
          value={selectedOption}
          onChangeValue={value => {
            setSelectedOption(value);
            setSliceId(value);
          }}
        />
      </Box>
    </Dropdown>
  );
}
