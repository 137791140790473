export const SampleProjects = [
  {
    displayName: 'Tiny Set-10',
    description: '10 sample images for the Suite demo',
    machineName: 'tiny-set-10',
    itemsCount: 10,
    classesCount: 5,
    tags: ['Image', 'Object Detection', 'Box', 'Polygon', 'Keypoint', 'Categorization'],
  },
  {
    displayName: 'Retail',
    description: 'Detecting products on the display stand',
    machineName: 'retail',
    itemsCount: 1000,
    classesCount: 8,
    tags: ['Custom Auto-Label', 'Image', 'Object Detection', 'Box'],
  },
  {
    displayName: 'Autonomous driving',
    description: 'Caltech pedestrian detection dataset',
    machineName: 'caltech-pedestrian',
    itemsCount: 999,
    classesCount: 2,
    tags: ['Custom Auto-Label', 'Image', 'Object Detection', 'Box'],
  },
  {
    displayName: 'Agriculture',
    description: 'MinneApple: Detecting apples',
    machineName: 'minneapple',
    itemsCount: 1001,
    classesCount: 1,
    tags: ['Custom Auto-Label', 'Image', 'Object Detection', 'Polygon'],
  },
  {
    displayName: 'Industrial surface',
    description: 'Detecting defects in steel pipes',
    machineName: 'industrial-surface',
    itemsCount: 774,
    classesCount: 7,
    tags: ['Custom Auto-Label', 'Image', 'Object Detection', 'Box'],
  },
];
