import { useQuery } from '@tanstack/react-query';

import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import { useMutationWithInvalidation } from '../hooks/useMutationWithInvalidation';
import AccountService from '../services/AccountService';

export const useAccessAccountQuery = () => {
  const params = useApiDefaultParams();

  const fetcher = async (): Promise<GetAccessAccountResponse> =>
    (
      await AccountService.getAllowSystemAdmin({
        ...params,
      })
    ).data.data;
  return useQuery(['accessAccount'], fetcher, {
    refetchOnWindowFocus: false,
  });
};

export interface GetAccessAccountResponse {
  allowSystem: boolean;
}

export const useAccessAccountMutation = () => {
  const params = useApiDefaultParams();
  const fetcher = async () =>
    await AccountService.putAllowSystemAdmin({
      ...params,
    });

  return useMutationWithInvalidation(['accessAccount'], fetcher);
};
