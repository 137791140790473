import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import ExportStatus from '../consts/ExportStatus';
import ExportsAnalyticsService from '../services/ExportsAnalyticsService';

export interface ComparisonReportQueryParams {
  refetch: boolean;
  versionId: string;
  defaultParams: {
    isGuest: boolean;
    urlInfo: Record<string, any>;
  };
}

export const useExportsAnalyticsAllVersionsQuery = ({
  defaultParams,
  refetch,
}: ComparisonReportQueryParams) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const fetcher = async () =>
    await ExportsAnalyticsService.getAllVersions({
      ...defaultParams,
    });
  const res = useQuery([accountName, projectId, 'exportsAnalyticsAllVersions'], fetcher, {
    refetchInterval: refetch ? 50000 : false,
  });
  return res.data?.results.filter(version => version.state !== ExportStatus.CANCELED) || [];
};

export const useExportsAnalyticsLatestReportQuery = ({
  defaultParams,
  refetch,
}: ComparisonReportQueryParams) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const fetcher = async () =>
    await ExportsAnalyticsService.getLatestVersion({
      ...defaultParams,
    });
  return useQuery([accountName, projectId, 'exportsAnalyticsLatestReport'], fetcher, {
    refetchInterval: refetch ? 50000 : false,
  });
};

export const useExportsAnalyticsReportIdQuery = ({
  defaultParams,
  versionId,
}: ComparisonReportQueryParams) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const fetcher = async () =>
    await ExportsAnalyticsService.getVersion({
      exportsAnalyticsId: versionId,
      ...defaultParams,
    });
  return useQuery([accountName, projectId, versionId, 'exportsAnalyticsReportId'], fetcher, {
    enabled: !!versionId,
    refetchInterval: Infinity,
  });
};
