import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';

import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../contexts/AppContext';
import { AppRouteLayoutProps, Page } from '../../menu';
import { joinPaths } from '../../routes/util';
import { evaluate } from '../../utils/functional';
import { IntegrationDetailMenuItem } from './detail/MenuItem';
import { IntegrationsListMenuItem } from './list/MenuItem';
//import Menu from './Menu';

export default function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const match = useRouteMatch();
  const { t } = useTranslation();
  // TODO check if this works
  useSetPageTitle(evaluate(menuItem.title)({ t }), PAGE_TRACKING_ID.accountIntegrations);

  return (
    <Page>
      <Switch>
        <Route
          exact
          path={joinPaths(match.url, IntegrationsListMenuItem.path)}
          // @ts-ignore: saa-680
          component={IntegrationsListMenuItem.component}
        />
        <Route
          path={joinPaths(match.url, IntegrationDetailMenuItem.path)}
          // @ts-ignore: saa-680
          component={IntegrationDetailMenuItem.component}
        />
      </Switch>
    </Page>
  );
}

// TODO @paul: Refactor this to use AppRoutes, but need to use match.url instead of match.path
