import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoCircle } from '@superb-ai/icons';
import { Box, Icon, Tooltip, Typography } from '@superb-ai/ui';
import { map } from 'lodash';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectListInfo } from '../../../hooks/ProjectListInfo';
import { CustomTheme } from '../../../types/muiTypes';
import CircularProgressBox from '../../elements/CircularProgressBox';
import TablePagination from '../../elements/TablePagination';
import { getIsRoleOrderSameOrLess } from '../label/labelList/AssignDialog/utils';
import ProjectTableRow from './ProjectTableRow';

const useStyles = makeStyles<CustomTheme>(() => ({
  tableHead: {
    boxShadow: 'none',
    '& .MuiTableCell-root': {
      borderBottom: 'solid 1px #979797',
      fontSize: '10px',
      paddingBottom: '4px',
      paddingTop: '4px',
    },
  },
  noSelectedRow: {
    height: '100px',
    '& td': {
      fontSize: '13px',
      textAlign: 'center',
    },
    '& a': {
      color: '#ff625a',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
}));

const Layout = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { role, projectRole } = useAuthInfo();
  const canViewProjectStatus = getIsRoleOrderSameOrLess(role ?? projectRole ?? '', 'manager');
  const { projects, isLoading, totalCount } = useProjectListInfo(canViewProjectStatus);

  return (
    <MUI.Box mr="auto" ml="auto">
      <MUI.Grid container spacing={3} direction="column">
        <Box
          display="flex"
          width="100%"
          overflow="auto"
          style={{ paddingTop: 30, paddingLeft: 30 }}
        >
          <MUI.Grid item xs={12} style={{ minWidth: '1200px' }}>
            <MUI.Table width="auto">
              <colgroup>
                <col width="360" />
                <col width="200" />
                <col width="200" />
                {canViewProjectStatus ? (
                  <>
                    <col width="200" />
                    <col width="150" />
                  </>
                ) : (
                  <col width="150" />
                )}
                <col width="140" />
                <col width="140" />
              </colgroup>
              <MUI.TableHead className={classes.tableHead}>
                <MUI.TableRow>
                  {/* <MUI.TableCell padding="checkbox"></MUI.TableCell> */}
                  <MUI.TableCell>
                    <Typography variant="m-regular">{t('projects.table.projectName')}</Typography>
                  </MUI.TableCell>
                  <MUI.TableCell>
                    <Typography variant="m-regular">{t('projects.table.curateSync')}</Typography>
                  </MUI.TableCell>
                  <MUI.TableCell>
                    <Typography variant="m-regular">
                      {t('labelInterface.annotationType')}
                    </Typography>
                  </MUI.TableCell>
                  <MUI.TableCell>
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <Typography variant="m-regular">{t('projects.table.labelCount')}</Typography>
                      {canViewProjectStatus && (
                        <Tooltip
                          content={
                            <Typography>
                              {t('shared.total')} /{' '}
                              <Typography color="yellow">
                                {t('labels.status.in_progress')}
                              </Typography>{' '}
                              /{' '}
                              <Typography color="green">{t('labels.status.submitted')}</Typography>{' '}
                              / <Typography color="cloud">{t('labels.status.skipped')}</Typography>
                            </Typography>
                          }
                          placement="top"
                        >
                          <Icon icon={InfoCircle} size={12} />
                        </Tooltip>
                      )}
                    </Box>
                  </MUI.TableCell>
                  {canViewProjectStatus && (
                    <MUI.TableCell>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <Typography variant="m-regular">{t('labels.status.approved')}</Typography>
                        <Tooltip
                          content={<Typography>{t('projects.submittedApprovedLabels')}</Typography>}
                          placement="top"
                        >
                          <Icon icon={InfoCircle} size={12} />
                        </Tooltip>
                      </Box>
                    </MUI.TableCell>
                  )}
                  <MUI.TableCell align="center">
                    <Typography variant="m-regular">{t('projects.table.createdDate')}</Typography>
                  </MUI.TableCell>
                  <MUI.TableCell align="center">
                    <Typography variant="m-regular">{t('labels.table.lastUpdated')}</Typography>
                  </MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>
              {isLoading ? (
                <MUI.TableBody>
                  <MUI.TableRow className={classes.noSelectedRow}>
                    <MUI.TableCell colSpan={5}>
                      <CircularProgressBox boxProps={{ width: '100%' }} />
                    </MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableBody>
              ) : !isLoading && !projects.length ? (
                <MUI.TableBody>
                  <MUI.TableRow className={classes.noSelectedRow}>
                    <MUI.TableCell colSpan={5}>
                      <MUI.Box display="flex" alignItems="center" justifyContent="center">
                        <MUI.Typography variant="body2">
                          {t('projects.messages.noProjectsFound')}
                        </MUI.Typography>
                      </MUI.Box>
                    </MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableBody>
              ) : (
                <MUI.TableBody>
                  {map(projects, project => (
                    <ProjectTableRow
                      key={project.id}
                      project={project}
                      canViewProjectStatus={canViewProjectStatus}
                    />
                  ))}
                </MUI.TableBody>
              )}
            </MUI.Table>
          </MUI.Grid>
        </Box>

        <MUI.Grid item xs={12}>
          <TablePagination totalCount={totalCount} />
        </MUI.Grid>
      </MUI.Grid>
    </MUI.Box>
  );
};

export default Layout;
