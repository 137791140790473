export const mockTableData = () => {
  return {
    count: 2,
    data: [
      {
        rank: 1,
        reviewRound: 4,
        labelingTime: 71,
        assetGroup: 'Batch A',
        assetKey: 'video-test/video-1000-frames/video-100-frames/high_hopes_same_image_100_frames',
        id: '4de5d3e1-2af0-4179-a0d0-2ccdc1a375ac',
        workAssignee: 'mjlimb+1@superb-ai.com',
        status: 'SUBMITTED',
        categoryCount: 1,
        objectCount: 3,
        autoLabelDifficulty: 2,
        autoLabelStatus: 'COMPLETE',
        assetFrameCount: 100,
        objectAnnotationCount: 3,
      },
      {
        rank: 2,
        reviewRound: 2,
        labelingTime: 42,
        assetGroup: 'Batch B',
        assetKey: 'video-test/four-videos-150-frames/video-2',
        id: '2525c8dd-6be9-478c-9b30-087512dc1ded',
        workAssignee: 'shko@superb-ai.com',
        status: 'SUBMITTED',
        categoryCount: 0,
        objectCount: 4,
        autoLabelDifficulty: 3,
        autoLabelStatus: 'PROCESSING',
        assetFrameCount: 150,
        objectAnnotationCount: 4,
      },
    ],
  };
};
