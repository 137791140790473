import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Dot } from '@superb-ai/icons';
import { Box, Icon, NamedColor, Typography } from '@superb-ai/ui';

import MathUtils from '../../../../../../../../../utils/MathUtils';
import { EvaluationValue } from '../../../../../../../services/diagnosisTypes';
import { AnnotationType } from '../../../../../../../types/annotationTypes';
import { getAnnotationIcon } from '../../../../views/grid/utils';

type Props = Pick<EvaluationValue, 'annotation' | 'prediction'> & {
  evaluations: { confidence: number; iou: number };
};

export default function SelectionClasses({
  annotation,
  prediction,
  evaluations,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  function getAnnotationInfo({
    target,
    type,
    className,
  }: {
    target: 'annotation' | 'prediction';
    type: AnnotationType;
    className: string;
  }) {
    const color = { annotation: 'mint-400', prediction: 'primary-400' }[target] as NamedColor;

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Box backgroundColor={color} style={{ width: '8px', height: '8px' }} borderRadius="100%" />
        <Icon icon={getAnnotationIcon(type)} />
        <Typography variant="m-regular">{className}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {annotation &&
        getAnnotationInfo({
          target: 'annotation',
          type: annotation.annotationType || prediction?.predictionType || 'box', // temporary : predictionType을 서버에서 주면 반영
          className: annotation.annotationClass,
        })}
      {prediction &&
        getAnnotationInfo({
          target: 'prediction',
          type: prediction.predictionType,
          className: prediction.predictionClass,
        })}
      <Typography ml={2} variant="m-regular">
        <Icon icon={Dot} /> {t('curate.diagnosis.grid.confidence')}:{' '}
        {(evaluations.confidence && MathUtils.round(evaluations.confidence, 2)) ?? '-'}
        <br />
        <Icon icon={Dot} /> {t('curate.diagnosis.grid.iou')}:{' '}
        {evaluations.iou > 0 ? MathUtils.round(evaluations.iou, 2) : '-'}
      </Typography>
    </Box>
  );
}
