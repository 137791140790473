import React, { ComponentType } from 'react';

import { CheckFilled, Clear, ClearFilled, InfoFilled, WarningFilled } from '@superb-ai/icons';
import {
  Box,
  Button,
  Icon,
  IconButton,
  LinkTypography,
  NamedColorWeight,
  Typography,
  vars,
} from '@superb-ai/ui';
import { History } from 'history';
import { TFunction } from 'next-i18next';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { openChatWidget } from '../../../../utils/chatWidget';
import { Command, CommandType } from '../../types/commandTypes';
import { InProgressStatuses } from '../../utils/commandUtils';
import { CommandConfig } from './config';
import { FailDetailMessages } from './FailDetailMessages';
import InfoArea from './InfoArea';

export default function enqueueCommandSnackbar<T extends CommandType>({
  key,
  enqueueSnackbar,
  closeSnackbar,
  commandConfig,
  command,
  setIsModalVisible,
  setTabValue,
  t,
  history,
}: {
  key: string;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
  closeSnackbar: (key: SnackbarKey) => void;
  commandConfig: CommandConfig;
  command: Command<T>;
  setIsModalVisible: (value: boolean) => void;
  setTabValue: (value: 'in_progress' | 'done') => void;
  t: TFunction;
  history: History<unknown>;
}) {
  const { link, linkedPageName, message } = commandConfig;
  const { status, result, failReason } = command;

  if (!commandConfig.message) return;

  function getIconAndColor(): { icon: ComponentType; color: NamedColorWeight; colorVar: string } {
    if (InProgressStatuses.includes(status))
      return { icon: InfoFilled, color: 'skyblue-400', colorVar: vars.color['skyblue-400'] };
    if (!result?.failCount && status === 'COMPLETE')
      return { icon: CheckFilled, color: 'green-400', colorVar: vars.color['green-400'] };
    if (failReason) return { icon: ClearFilled, color: 'red-400', colorVar: vars.color['red-400'] };
    return { icon: WarningFilled, color: 'orange-400', colorVar: vars.color['orange-400'] };
  }

  const isJobFailed = status === 'FAILED';
  const successCount = result?.successCount || 0;
  const failCount = result?.failCount || 0;
  const isPartiallyFailed = failCount > 0;

  function getButtonsConfig() {
    if (InProgressStatuses.includes(command.status)) {
      return [
        {
          text: t('button.view'),
          handleClick: () => {
            setIsModalVisible(true);
            setTabValue('in_progress');
          },
        },
      ];
    }
    if (isJobFailed)
      return [
        {
          text: t('text.contactSupport'),
          handleClick: () => {
            openChatWidget();
          },
        },
      ];
    if (isPartiallyFailed) {
      return [
        {
          text: t('text.contactSupport'),
          handleClick: () => {
            openChatWidget();
          },
        },
        {
          text: t('curate.bulkActions.button.openPage', {
            target: linkedPageName
              ? t(`curate.bulkActions.button.openPageTarget.${linkedPageName}`)
              : '',
          }),
          handleClick: () => {
            history.push(link || '', new Date());
          },
        },
      ];
    }
    return [
      {
        text: t('curate.bulkActions.button.openPage', {
          target: linkedPageName
            ? t(`curate.bulkActions.button.openPageTarget.${linkedPageName}`)
            : '',
        }),
        handleClick: () => {
          history.push(link || '', new Date());
        },
      },
    ];
  }

  enqueueSnackbar(key, {
    variant: 'info',
    autoHideDuration: 5000,
    preventDuplicate: true,
    content: key => (
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="gray-500"
        gap={1}
        p={2.5}
        style={{
          width: '420px',
          minHeight: '102px',
          borderLeft: `4px solid ${getIconAndColor().colorVar}`,
        }}
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex">
            <Icon size={24} icon={getIconAndColor().icon} color={getIconAndColor().color} />
            <Box display="flex" ml={1} pt={0.5} flexDirection="column">
              <Box>
                <Typography variant="m-strong" color={'white'}>
                  {message}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ maxHeight: '24px' }}>
            <IconButton
              icon={Clear}
              color="white"
              variant="text"
              onClick={() => closeSnackbar(key)}
            />
          </Box>
        </Box>
        <Box ml={3}>
          {commandConfig.additionalActions && (
            <Box display="flex" gap={0.5}>
              {commandConfig.additionalActions.map(action => (
                <LinkTypography
                  variant="s-regular"
                  color="white"
                  key={action.text}
                  onClick={e => {
                    action.handleClick();
                    closeSnackbar(key);
                  }}
                >
                  {action.text}
                </LinkTypography>
              ))}
            </Box>
          )}
          {isJobFailed && (
            <Typography variant="s-regular">
              {t('curate.bulkActions.failReasons.systemError')}
            </Typography>
          )}
          {isPartiallyFailed && (
            <>
              <InfoArea borderColor="white-400">
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="s-regular" color="white">
                    {t('curate.bulkActions.successCount', {
                      count: successCount,
                    })}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="s-regular" color="white">
                    {t('curate.bulkActions.failCount', { count: failCount })}
                  </Typography>
                </Box>
              </InfoArea>
              <FailDetailMessages textColor="white-400" details={command.result.failDetail} />
              {commandConfig.additionalPartialFailAction && (
                <LinkTypography
                  variant="s-regular"
                  color="white"
                  onClick={() => {
                    commandConfig.additionalPartialFailAction?.handleClick();
                    closeSnackbar(key);
                  }}
                >
                  {commandConfig.additionalPartialFailAction.text}
                </LinkTypography>
              )}
            </>
          )}
          {!isJobFailed && !isPartiallyFailed && commandConfig.additionalMessage && (
            <InfoArea borderColor="white-400" color="white-400">
              {commandConfig.additionalMessage}
            </InfoArea>
          )}
        </Box>
        <Box width="100%" display="flex" justifyContent="flex-end" gap={1}>
          {getButtonsConfig()?.map((button, index) => (
            <Button
              key={index}
              size="m"
              color="white"
              variant="text"
              onClick={() => {
                button.handleClick();
                closeSnackbar(key);
              }}
            >
              {button.text}
            </Button>
          ))}
        </Box>
      </Box>
    ),
  });
}
