import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  Box as BoxIcon,
  CheckCircle,
  ClearOutline,
  Cuboid,
  Download,
  ErrorOutline,
  Keypoints,
  LoadingSpinnerSmall,
  Pencil,
  Polygon,
  Polyline,
  RotatedBox,
  Trash,
} from '@superb-ai/icons';
import { Accordion, Badge, StatusChip, useAlertModal } from '@superb-ai/norwegian-forest';
import { Status } from '@superb-ai/norwegian-forest/dist/types/suite/status';
import { Box, Button, Icon, IconButton, Tooltip, Typography } from '@superb-ai/ui';
import { formatDistanceToNowStrict } from 'date-fns';
import { capitalize, snakeCase } from 'lodash';
import { useSnackbar } from 'notistack';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import AnalyticsTracker from '../../../../../analyticsTracker';
import { DownloadMethod } from '../../../../../analyticsTracker/types';
import {
  useArchiveCustomAutoLabelMutation,
  useCancelCustomAutoLabelRequestMutation,
  useChangeCustomAutoLabelNameMutation,
  useCustomAutoLabelDownloadStatus,
} from '../../../../../queries/customAutoLabel';
import CustomAutoLabelService from '../../../../../services/CustomAutoLabelService';
import ExportService from '../../../../../services/ExportService';
import {
  ClassMetrics,
  CustomAutoLabelStateType,
  CustomAutoLabelType,
} from '../../../../../types/customAutoLabelTypes';
import { formatDateTime, parseDate } from '../../../../../utils/date';
import FileUtils from '../../../../../utils/FileUtils';
import LabelInterfaceUtils, { AnnotationType } from '../../../../../utils/LabelInterfaceUtils';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { Engine } from '../settings/helper';
import AccordionTableLayout from './AccordionTableLayout';
import { ApplyCustomAutoLabelModal } from './ApplyCustomAutoLabelModal';

type Props = {
  customAutoLabel: CustomAutoLabelType;
  appliedCount?: { classes?: number; properties?: number };
  enginesMap: Map<string, Engine>;
  onItemChanged?(): void;
};

const CalStatus = {
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  IN_QUEUE: 'IN_QUEUE',
  READY: 'READY',
  PROCESSING: 'PROCESSING',
  WAITING: 'WAITING',
};

function getCalStateIcon(status: CustomAutoLabelStateType) {
  const colors = {
    [CalStatus.READY]: 'green',
    [CalStatus.CANCELED]: 'gray',
    [CalStatus.FAILED]: 'red',
    [CalStatus.WAITING]: 'yellow',
    [CalStatus.IN_QUEUE]: 'yellow',
    [CalStatus.PROCESSING]: 'yellow',
  } as const;

  const icons = {
    [CalStatus.READY]: CheckCircle,
    [CalStatus.CANCELED]: ClearOutline,
    [CalStatus.FAILED]: ErrorOutline,
    [CalStatus.WAITING]: LoadingSpinnerSmall,
    [CalStatus.IN_QUEUE]: LoadingSpinnerSmall,
    [CalStatus.PROCESSING]: LoadingSpinnerSmall,
  } as const;

  return <Icon icon={icons[status]} color={colors[status]} size={20} />;
}

function CustomAutoLabelItem(props: Props) {
  const { customAutoLabel, appliedCount, enginesMap, onItemChanged } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useAlertModal();
  const routeInfo = useRouteInfo();
  const { accountName } = useParams<{ accountName: string }>();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const calDownload = useFeatureFlag('calDownload');
  const [isRequested, setIsRequested] = useState(false);
  const [isOpenApplyModal, setIsOpenApplyModal] = useState(false);

  const objectClasses = LabelInterfaceUtils.getObjectClasses(customAutoLabel?.labelInterface);
  const categorizationProperties = customAutoLabel.labelInterface?.categorization?.properties || [];
  const categorizationModelDetail = customAutoLabel?.modelDetail?.categorization;
  const classMetricsMap = categorizationModelDetail?.reduce<
    Record<string, { classMetrics: ClassMetrics[]; failMsg?: string }>
  >(
    (acc, detail) => ({
      ...acc,
      [detail.propertyId]: { classMetrics: detail.classMetrics, failMsg: detail.failMsg },
    }),
    {},
  );
  const canUseCAL =
    customAutoLabel?.modelDetail?.classMetrics || customAutoLabel?.modelDetail?.categorization;

  const canUseCALDownload =
    calDownload && ProjectUtils.getProjectDataType(projectInfo.project.workapp) === 'image';

  const canArchive = ['READY', 'FAILED', 'CANCELED'].includes(customAutoLabel?.state);

  const remainingTime = (() => {
    const { processingProgress, processingStartAt, processingUpdatedAt } = customAutoLabel;
    if (!processingProgress || !processingStartAt || processingProgress < 1e-4) return 'loading';
    const startDate = parseDate(processingStartAt);
    const updatedDate = parseDate(processingUpdatedAt);
    const timeDiff = +updatedDate - +startDate;
    const remainingMilliSecs = timeDiff / processingProgress - timeDiff;
    const endDate = new Date(+new Date() + remainingMilliSecs);
    return formatDistanceToNowStrict(endDate.getTime());
  })();

  const appliedItemsText = [
    appliedCount?.classes && t('labelInterface.objectCount', { count: appliedCount.classes }),
    appliedCount?.properties &&
      t('labelInterface.propertyCount', { count: appliedCount.properties }),
  ].filter(a => a);

  const { data: calDownloadStatus, refetch: refetchDownloadStatus } =
    useCustomAutoLabelDownloadStatus({
      customAutoLabel,
    });

  const downloadCustomAutoLabel = async () => {
    const newWindow = window.open('', '_blank', 'noreferrer');
    const res = await CustomAutoLabelService.getCustomAutoLabelDownloadUrl({
      projectId: routeInfo.urlMatchInfo.projectId,
      customAutoLabelId: customAutoLabel.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    if (!newWindow) return;
    newWindow.location = res.url;
  };

  useEffect(() => {
    if (!canUseCALDownload || customAutoLabel.state !== 'READY' || !isRequested) return;

    if (calDownloadStatus?.status === 'READY') {
      downloadCustomAutoLabel();
    }
  }, [canUseCALDownload, customAutoLabel.state, calDownloadStatus, isRequested]);

  const requestDownloadUrl = async () => {
    try {
      setIsRequested(true);
      await CustomAutoLabelService.requestCustomAutoLabelDownloadUrl({
        projectId: routeInfo.urlMatchInfo.projectId,
        customAutoLabelId: customAutoLabel.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      refetchDownloadStatus();
    } catch (_err) {
      setIsRequested(false);
    }
  };

  const handleClickDownload = async () => {
    if (calDownloadStatus?.status === 'NONE') {
      openModal({
        title: t('autoLabel.cal.dialog.downloadCAL'),
        content: (
          <>
            <Typography variant="m-regular">
              <Trans t={t} i18nKey="autoLabel.cal.dialog.downloadCALText" />
            </Typography>
            <Box mt={1}>
              <Typography variant="m-regular" color="primary">
                {t('autoLabel.cal.dialog.downloadCALNote')}
              </Typography>
            </Box>
          </>
        ),
        mainButton: {
          text: t('button.continue'),
          onClick: requestDownloadUrl,
        },
        subButton: {
          text: t('button.cancel'),
          onClick: () => closeModal(),
        },
        canConfirmWithEnter: true,
      });
      return;
    }
    if (calDownloadStatus?.status === 'READY') {
      await downloadCustomAutoLabel();
      return;
    }
    return;
  };

  const { mutate: updateName } = useChangeCustomAutoLabelNameMutation({
    customAutoLabel,
  });

  const changeCustomAutoLabelName = async (promptValue: string) => {
    await updateName({ name: promptValue });
    onItemChanged?.();
    closeModal();
  };

  const handleClickEditName = () => {
    openModal({
      title: t('autoLabel.cal.dialog.renameCustomAutoLabel'),
      content: '',
      mainButton: {
        text: t('button.change'),
        // @ts-ignore: type overlap
        onClick: (promptValue?: string) => changeCustomAutoLabelName(promptValue || ''),
      },
      subButton: {
        text: t('button.cancel'),
        onClick: () => closeModal(),
      },
      prompt: {
        selected: true,
        defaultValue: customAutoLabel.name,
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
      canConfirmWithEnter: true,
    });
  };

  const getLabel = () => {
    const getColor = (state: CustomAutoLabelStateType): Status => {
      switch (state) {
        case 'READY':
          return 'success';
        case 'WAITING':
        case 'PROCESSING':
        case 'IN_QUEUE':
          return 'warning';
        default:
          return snakeCase(state) as 'canceled' | 'failed';
      }
    };

    const convertState = (state: CustomAutoLabelStateType): string => {
      switch (state) {
        case 'CANCELED':
          return t('autoLabel.cal.status.canceled');
        case 'FAILED':
          return t('autoLabel.cal.status.failed');
        case 'IN_QUEUE':
        case 'WAITING':
          return t('autoLabel.cal.status.waiting');
        case 'READY':
          return t('autoLabel.cal.status.ready');
        case 'PROCESSING':
        default:
          return t('autoLabel.cal.status.processing');
      }
    };

    const chip = (
      <StatusChip color={getColor(customAutoLabel.state)}>
        {convertState(customAutoLabel.state)}
      </StatusChip>
    );

    return chip;
  };

  const getDownloadButton = () => {
    if (customAutoLabel.state !== 'READY') {
      return <IconButton icon={Download} color="gray" variant="stroke" disabled />;
    }

    if (!categorizationProperties.length) {
      return (
        <Tooltip
          content={t('autoLabel.cal.button.downloadTooltipOnlyCategorization')}
          placement="bottom-end"
        >
          <Box>
            <IconButton icon={Download} color="gray" variant="stroke" disabled />
          </Box>
        </Tooltip>
      );
    }

    return {
      NONE: (
        <Tooltip
          content={<Trans t={t} i18nKey="autoLabel.cal.button.downloadTooltip" />}
          placement="bottom-end"
        >
          <Box>
            <IconButton
              icon={Download}
              color="gray"
              variant="stroke"
              onClick={handleClickDownload}
            />
          </Box>
        </Tooltip>
      ),
      PROCESSING: (
        <Tooltip
          content={t('autoLabel.cal.button.downloadTooltipPreparing')}
          placement="bottom-end"
        >
          <Box>
            <IconButton icon={LoadingSpinnerSmall} variant="stroke" disabled color="gray" />
          </Box>
        </Tooltip>
      ),
      READY: (
        <IconButton
          icon={Download}
          color="gray"
          variant="strong-fill"
          onClick={handleClickDownload}
        />
      ),
    }[calDownloadStatus?.status ?? 'NONE'];
  };

  const getButton = () => {
    if (customAutoLabel.state === 'WAITING' || customAutoLabel.state === 'IN_QUEUE') {
      return (
        <Button variant="text" color="primary" onClick={handleClickCancel}>
          {t('button.cancel')}
        </Button>
      );
    }
    if (customAutoLabel.state === 'PROCESSING') {
      return (
        <Tooltip content={t('autoLabel.cal.messages.alreadyInProgress')}>
          <Box>
            <Button disabled variant="stroke" onClick={handleClickCancel}>
              {t('button.cancel')}
            </Button>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Box display="flex" gap={0.5}>
        {customAutoLabel.state === 'READY' && (calDownloadStatus?.status ?? 'NONE') !== 'NONE' && (
          <IconButton
            icon={Download}
            color="gray"
            variant="text"
            size="m"
            onClick={handleClickDownload}
          />
        )}
        <IconButton
          icon={Pencil}
          color="gray"
          variant="text"
          size="m"
          onClick={handleClickEditName}
        />
        {canArchive && (
          <IconButton
            icon={Trash}
            color="gray"
            variant="text"
            size="m"
            onClick={handleClickArchive}
          />
        )}
      </Box>
    );
  };

  const handleClickApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenApplyModal(true);
  };

  const { mutate: cancelCustomAutoLabelRequest } = useCancelCustomAutoLabelRequestMutation({
    customAutoLabel,
  });

  const handleClickCancel = async () => {
    await cancelCustomAutoLabelRequest();
    onItemChanged?.();
    AnalyticsTracker.createCustomAutoLabelAiCanceled({
      accountId: accountName,
    });
  };

  const handleClickDownloadExport = async () => {
    if (!customAutoLabel.exportHistory) return;

    const fileInfo = await ExportService.getExportDownloadPresignedUrl({
      projectId: routeInfo.urlMatchInfo.projectId,
      exportId: customAutoLabel.exportHistory.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const filePath = fileInfo.downloadPresignedUrl;
    FileUtils.downloadViaPath(filePath, fileInfo.fileName);

    AnalyticsTracker.labelExportDownloaded({
      accountId: accountName,
      method: DownloadMethod.DOWNLOAD,
      exportId: customAutoLabel.exportHistory.id,
      labelCount: customAutoLabel.exportHistory.downloadLabelCount,
      format: 'default',
    });
  };

  const { mutate: archiveCreateCustomAutoLabel } = useArchiveCustomAutoLabelMutation({
    customAutoLabel,
  });

  const handleClickArchive = async () => {
    openModal({
      title: t('autoLabel.cal.dialog.removeCAL'),
      content: (
        <>
          <Typography variant="m-regular">
            {t('autoLabel.cal.dialog.removeCALText', { name: customAutoLabel.name })}
          </Typography>
          {appliedCount && (
            <Typography variant="m-regular" color="primary">
              {t('autoLabel.appliedToLong', { items: appliedItemsText })}
            </Typography>
          )}
        </>
      ),
      mainButton: {
        text: t('button.remove'),
        onClick: async () => {
          await archiveCreateCustomAutoLabel();
          onItemChanged?.();
          enqueueSnackbar('Custom Auto-Label has been removed.', {
            variant: 'success',
          });
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: () => closeModal(),
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
    });
  };

  const getInfoBox = () => {
    switch (customAutoLabel.state) {
      case 'IN_QUEUE':
      case 'WAITING':
      case 'PROCESSING':
      case 'READY':
        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            py={1.5}
            backgroundColor="gray-100"
            style={{ width: '386px' }}
            gap={3}
          >
            <Typography variant="m-regular" style={{ whiteSpace: 'pre-wrap' }}>
              <Trans t={t} i18nKey="autoLabel.cal.expectedEfficiencyBoost" />
            </Typography>
            {customAutoLabel.state === 'READY' ? (
              <Typography variant="h1" ml={3}>
                {customAutoLabel.boost?.toFixed(1) ?? ''}x
              </Typography>
            ) : (
              <Box ml={5}>
                <Icon icon={LoadingSpinnerSmall} size={16} color="yellow" />
              </Box>
            )}
            <Box display="flex" gap={1}>
              {canUseCALDownload && getDownloadButton()}
              <Button
                disabled={customAutoLabel.state !== 'READY'}
                color="primary"
                variant="strong-fill"
                onClick={handleClickApply}
              >
                {t('button.apply')}
              </Button>
            </Box>
          </Box>
        );
      case 'FAILED':
        return (
          <Box
            display="flex"
            alignItems="flex-start"
            px={2}
            py={1.5}
            backgroundColor="primary-100"
            style={{ width: '386px' }}
            gap={1}
          >
            <Icon
              icon={ErrorOutline}
              size={13}
              color="red"
              style={{ transform: 'translateY(2px)' }}
            />
            <Typography variant="m-regular" color="red">
              {customAutoLabel.reason}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mb={3} boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)">
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={3}
            py={2}
            borderBottom="1px solid"
            borderColor="gray-150"
          >
            <Box display="flex" alignItems="center" gap={1}>
              {getCalStateIcon(customAutoLabel.state)}
              <Typography variant="l-strong">{customAutoLabel.name}</Typography>
              {getLabel()}
              {customAutoLabel.state === 'PROCESSING' && (
                <Typography variant="m-regular">{remainingTime} remaining...</Typography>
              )}
            </Box>
            {getButton()}
          </Box>
          <Box display="flex" ml={1} p={2} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="column" gap={0.5} style={{ marginLeft: '28px' }}>
              {customAutoLabel.exportHistory && (
                <Typography variant="m-regular">
                  {t('autoLabel.cal.fromExport')}
                  {': '}
                  <Tooltip
                    placement="top-start"
                    content={
                      <Box display="flex" alignItems="center">
                        {t('autoLabel.cal.exportedLabelsCount', {
                          count: customAutoLabel.exportHistory.downloadLabelCount,
                        })}
                        {' - '}
                        {FileUtils.getConvertedFileSize(customAutoLabel.exportHistory.fileSize)}
                        <IconButton
                          size="s"
                          color="white"
                          variant="text"
                          icon={Download}
                          onClick={handleClickDownloadExport}
                        />
                      </Box>
                    }
                  >
                    <strong>{customAutoLabel.exportHistory.name || 'Untitled'}</strong>
                  </Tooltip>
                </Typography>
              )}
              <Typography variant="m-regular" color="gray-300">
                {customAutoLabel.createdBy} ({formatDateTime(customAutoLabel.createdAt)})
              </Typography>
            </Box>
            {getInfoBox()}
          </Box>
        </Box>
        {canUseCAL && (
          <Box display="flex" flexDirection="column" py={1.5} px={2} backgroundColor="gray-100">
            <Accordion
              summary={`${t('autoLabel.cal.button.showAllClassesAndCategories')} (${
                objectClasses.length + categorizationProperties.length
              })`}
            >
              <Box
                width="100%"
                pt={1.5}
                display="flex"
                overflow="auto"
                flexDirection="column"
                style={{
                  height: '380px',
                }}
              >
                {objectClasses.length > 0 && (
                  <AccordionTableLayout
                    type="object"
                    width={[152, 144, 80, 80]}
                    rows={(objectClasses || []).map(objectClass => {
                      const metric = (customAutoLabel?.modelDetail?.classMetrics || []).find(
                        classMetric => objectClass.id === classMetric.classId,
                      );
                      if (!objectClass) return { content: ['', '', '', ''] };

                      return {
                        failMsg: customAutoLabel?.modelDetail?.failMsg,
                        content: [
                          <Box key={objectClass.id} display="flex" alignItems="center" gap={1}>
                            <Badge size="xxs" color="cloud">
                              <Icon
                                icon={convertAnnotationType(
                                  objectClass.annotationType as AnnotationType,
                                )}
                                size={12}
                              />
                            </Badge>
                            <Typography variant="m-regular">
                              {objectClass.annotationType}
                            </Typography>
                          </Box>,
                          objectClass.name,
                          metric?.precision || 'N/A',
                          metric?.recall || 'N/A',
                        ],
                      };
                    })}
                    columns={[
                      t('labelInterface.annotationType'),
                      t('autoLabel.cal.table.className'),
                      t('autoLabel.cal.table.precision'),
                      t('autoLabel.cal.table.recall'),
                    ]}
                  />
                )}
                {categorizationProperties.length > 0 && (
                  <AccordionTableLayout
                    type="categorization"
                    columns={[
                      t('labelInterface.categoryType'),
                      t('labelInterface.categoryName'),
                      t('labelInterface.categoryOptions'),
                      t('autoLabel.cal.table.precision'),
                      t('autoLabel.cal.table.recall'),
                    ]}
                    width={[152, 32, 128, 72, 72]}
                    rows={categorizationProperties.map(category => {
                      if (!category) return { content: ['', '', '', ''] };
                      return {
                        failMsg: classMetricsMap && classMetricsMap[category.id]?.failMsg,
                        content: [
                          category.type,
                          capitalize(category.name),
                          <Box key={category.id}>
                            {classMetricsMap
                              ? classMetricsMap[category.id]?.classMetrics.map(metric => {
                                  return (
                                    <Box key={metric.classId}>
                                      <Typography variant="m-regular" whiteSpace="nowrap">
                                        {metric.className}
                                      </Typography>
                                    </Box>
                                  );
                                })
                              : 'N/A'}
                          </Box>,
                          <Box key={category.id}>
                            {(classMetricsMap &&
                              classMetricsMap[category.id]?.classMetrics.map(metric => {
                                return (
                                  <Box key={metric.classId}>
                                    <Typography variant="m-regular" whiteSpace="nowrap">
                                      {metric.precision.toLocaleString('en-US', {
                                        minimumFractionDigits: 1,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </Box>
                                );
                              })) ||
                              'N/A'}
                          </Box>,
                          <Box key={category.id}>
                            {classMetricsMap
                              ? classMetricsMap[category.id]?.classMetrics.map(metric => {
                                  return (
                                    <Box key={metric.classId}>
                                      <Typography variant="m-regular" whiteSpace="nowrap">
                                        {metric.recall.toLocaleString('en-US', {
                                          minimumFractionDigits: 1,
                                          maximumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </Box>
                                  );
                                })
                              : 'N/A'}
                          </Box>,
                        ],
                      };
                    })}
                  />
                )}
              </Box>
            </Accordion>
          </Box>
        )}
      </Box>

      {canUseCAL && (
        <ApplyCustomAutoLabelModal
          open={isOpenApplyModal}
          setOpen={setIsOpenApplyModal}
          customAutoLabel={customAutoLabel}
          enginesMap={enginesMap}
        />
      )}
    </>
  );
}

export default CustomAutoLabelItem;

function convertAnnotationType(name: AnnotationType) {
  switch (name) {
    case 'keypoint':
    case 'keypoints':
      return Keypoints;
    case 'box':
    case 'tiltedbox':
      return BoxIcon;
    case 'cuboid':
    case 'cuboid2D':
      return Cuboid;
    case 'polygon':
    case 'polygons':
      return Polygon;
    case 'polyline':
      return Polyline;
    case 'rbox':
      return RotatedBox;
  }
}
