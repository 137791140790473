import { ReactElement } from 'react';

import {
  Axis,
  Chart,
  CurveType,
  DomainRange,
  LineSeries,
  LineSeriesSpec,
  PartialTheme,
  Position,
  // RectAnnotation,
  ScaleType,
  Settings,
  Tooltip,
} from '@elastic/charts';

export type CustomLineSeriesSpec = Partial<LineSeriesSpec> &
  Pick<LineSeriesSpec, 'id' | 'color' | 'xAccessor' | 'yAccessors'>;

type LineChartProps = {
  data: any[];
  theme: PartialTheme;
  rectAnnotation?: ReactElement;
  vLineAnnotation?: ReactElement;
  getColor: (series: any) => string;
  TooltipHeaderComponent?: (props: { datum: Record<string, any> }) => ReactElement;
  TooltipFooterComponent?: (props: { datum: Record<string, any> }) => ReactElement;
  xDomain?: DomainRange;
  config: {
    showLegend?: boolean;
    axes: {
      xAxisTitle: string;
      yAxisTitle: string;
    };
    series: CustomLineSeriesSpec[];
  };
};

// renders one or more line charts using same source `data`
export default function SingleSourceMultiLineChart(props: LineChartProps) {
  const {
    data,
    theme,
    config,
    TooltipHeaderComponent,
    TooltipFooterComponent,
    xDomain = { min: 0, max: 1 },
    rectAnnotation,
    vLineAnnotation,
  } = props;

  const seriesConfig = config.series;
  const axesConfig = config.axes;

  return (
    <Chart size={{ width: '100%', height: '100%' }}>
      <Settings
        {...(config.showLegend && { showLegend: true })}
        legendPosition={Position.Right}
        theme={theme}
        xDomain={xDomain}
      />
      <Tooltip
        type="cross"
        showNullValues
        {...(TooltipFooterComponent && {
          footer: items => {
            return <TooltipFooterComponent datum={items[0].datum} />;
          },
        })}
        {...(TooltipHeaderComponent && {
          headerFormatter: ({ datum }) => {
            return <TooltipHeaderComponent datum={datum} />;
          },
        })}
      />
      {typeof rectAnnotation !== 'undefined' && rectAnnotation}
      {typeof vLineAnnotation !== 'undefined' && vLineAnnotation}
      <Axis
        id="bottom"
        position={Position.Bottom}
        title={axesConfig.xAxisTitle}
        tickFormat={d => Number(d).toFixed(1)}
        showOverlappingTicks
      />
      <Axis
        id="left"
        title={axesConfig.yAxisTitle}
        position={Position.Left}
        tickFormat={d => Number(d).toFixed(1)}
        domain={{ min: 0, max: 1 }}
        {...{ ticks: 5, style: { ...theme.axes, tickLabel: { padding: 15 } } }}
      />
      {seriesConfig.map(series => {
        return (
          <LineSeries
            key={series.id}
            id={series.id}
            xScaleType={ScaleType.Linear}
            yScaleType={ScaleType.Linear}
            xAccessor={series.xAccessor}
            yAccessors={series.yAccessors}
            curve={CurveType.CURVE_NATURAL}
            color={series.color}
            data={data}
            tickFormat={d => Number(d).toFixed(2)}
          />
        );
      })}
    </Chart>
  );
}
