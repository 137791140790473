import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { RecognitionEndpointInferenceImageData } from '../../../services/ModelService';

type ContextProps = {
  predictionIndex: number | null;
  setPredictionIndex: Dispatch<SetStateAction<number | null>>;
  predictionResults: RecognitionEndpointInferenceImageData[];
  setPredictionResults: Dispatch<SetStateAction<RecognitionEndpointInferenceImageData[]>>;
  filteredPredictionAnnotationsIds: string[];
  setFilteredPredictionAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  annotationSelectedMap: Record<string, boolean>;
  setAnnotationSelectedMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  annotationHoveredMap: Record<string, boolean>;
  setAnnotationHoveredMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  clickedAnnotationId: string | null;
  setClickedAnnotationId: Dispatch<SetStateAction<string | null>>;
  hoveredAnnotatinoId: string | null;
  setHoveredAnnotationId: Dispatch<SetStateAction<string | null>>;
  imageUrls: string[];
  setImageUrls: Dispatch<SetStateAction<string[]>>;
};

const Context = createContext({} as ContextProps);

const useProvider = () => {
  const [predictionIndex, setPredictionIndex] = useState<number | null>(null);
  const [predictionResults, setPredictionResults] = useState<
    RecognitionEndpointInferenceImageData[]
  >([]);
  const [filteredPredictionAnnotationsIds, setFilteredPredictionAnnotationsIds] = useState<
    string[]
  >([]);
  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [annotationHoveredMap, setAnnotationHoveredMap] = useState<Record<string, boolean>>({});
  const [clickedAnnotationId, setClickedAnnotationId] = useState<string | null>(null);
  const [hoveredAnnotatinoId, setHoveredAnnotationId] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  return {
    predictionIndex,
    setPredictionIndex,
    predictionResults,
    setPredictionResults,
    filteredPredictionAnnotationsIds,
    setFilteredPredictionAnnotationsIds,
    annotationSelectedMap,
    setAnnotationSelectedMap,
    annotationHoveredMap,
    setAnnotationHoveredMap,
    clickedAnnotationId,
    setClickedAnnotationId,
    hoveredAnnotatinoId,
    setHoveredAnnotationId,
    imageUrls,
    setImageUrls,
  };
};
export const useRecognitionPredictionTestContext = (): ContextProps => {
  return useContext(Context);
};

export const RecognitionPredictionTestProvider: React.FC = ({ children }) => {
  const recognitionPredictionTestInfo = useProvider();
  return <Context.Provider value={recognitionPredictionTestInfo}>{children}</Context.Provider>;
};
