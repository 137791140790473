import { omit, snakeCase, startCase } from 'lodash';

import { JsonObj, ReportVersions } from '../types';
import { isUserReportV2 } from './labelerData';

export const excludeColumnsFromDownload = (
  rows: JsonObj[],
  exclude_columns: string[],
): JsonObj[] => {
  return rows.map(row => omit(row, exclude_columns));
};

export const transformHeaders = (
  aRow: JsonObj,
  displayNames?: Record<string, string>,
): string[] => {
  let headers = Object.keys(aRow);
  if (displayNames) {
    headers = headers.map(field => displayNames[field] || startCase(field));
  }
  return headers.map(field => snakeCase(field));
};

interface IReportInfo {
  generateTime: string;
  email: string;
  tenant: string;
  projectName: string;
  labelCount: number;
  filter: string;
}
export const getReportInfo = ({
  generateTime,
  email,
  tenant,
  projectName,
  labelCount,
  filter,
}: IReportInfo): (string | number)[][] => {
  return [
    ['Report Time', generateTime],
    ['Request By', email],
    ['Team Name', tenant],
    ['Project', projectName],
    ['Labels', labelCount],
    ['Applied Filter', filter],
  ];
};

export const getReviewerReportSheetName = (apiVersion: ReportVersions) => {
  return isUserReportV2(apiVersion) ? 'Review Labels' : 'Review Complete Labels';
};

export const sheetName = (
  reportType: 'reviewer' | 'labeler',
  labelCount: number,
  apiVersion: ReportVersions,
): string => {
  return reportType === 'reviewer'
    ? `${getReviewerReportSheetName(apiVersion)}: ${labelCount}`
    : `Labeler Summary: ${labelCount} labels`;
};

export const reportName = (
  reportType: 'reviewer' | 'labeler',
  projectName: string,
  generateTime: string,
): string => {
  return reportType === 'labeler'
    ? `${projectName}_user_report_${generateTime}`
    : `${projectName}_${reportType}_report_${generateTime}`;
};

export const objectLength = (obj: JsonObj): number => Object.keys(obj).length;

// source: https://stackoverflow.com/questions/8240637/convert-numbers-to-letters-beyond-the-26-character-alphabet
export function getExcelColumn(index: number): string {
  let letters = '';
  while (index >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index % 26] + letters;
    index = Math.floor(index / 26) - 1;
  }
  return letters;
}
