import React, { useRef } from 'react';

import { makeStyles } from '@mui/styles';
import { CheckFilled, Clear, ClearFilled, InfoFilled, WarningFilled } from '@superb-ai/icons';
import { Icon, IconButton, vars } from '@superb-ai/ui';
import classNames from 'classnames';
import { SnackbarProvider } from 'notistack';

const useStyle = makeStyles(() => {
  return {
    snackbar: {
      fontSize: '12px',
      backgroundColor: `${vars.color['gray-500']} !important`,
      opacity: 1,
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    icon: {
      marginRight: '8px',
    },
    success: { borderLeft: `4px solid ${vars.color['green-400']}` },
    error: { borderLeft: `4px solid ${vars.color['red-400']}` },
    warning: { borderLeft: `4px solid ${vars.color['orange-400']}` },
    info: { borderLeft: `4px solid ${vars.color['skyblue-400']}` },
    containerAnchorOriginTopRight: {
      marginTop: '54px',
    },
  };
});

const StackSnackbar: React.FC<{ hasTopMargin?: boolean }> = ({ children, hasTopMargin = true }) => {
  const classes = useStyle();
  const notistackRef = useRef<SnackbarProvider>(null);

  return (
    // @ts-ignore: saa-680
    <SnackbarProvider
      ref={notistackRef}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      maxSnack={3}
      classes={{
        variantSuccess: classNames(classes.snackbar, classes.success),
        variantError: classNames(classes.snackbar, classes.error),
        variantWarning: classNames(classes.snackbar, classes.warning),
        variantInfo: classNames(classes.snackbar, classes.info),
        ...(hasTopMargin && {
          containerAnchorOriginTopRight: classes.containerAnchorOriginTopRight,
        }),
      }}
      iconVariant={{
        success: <Icon icon={CheckFilled} color="green-400" className={classes.icon} size="20px" />,
        error: <Icon icon={ClearFilled} color="red-400" className={classes.icon} size="20px" />,
        warning: (
          <Icon icon={WarningFilled} color="orange-400" className={classes.icon} size="20px" />
        ),
        info: <Icon icon={InfoFilled} color="skyblue-400" className={classes.icon} size="20px" />,
      }}
      action={key => (
        <IconButton
          variant="text"
          icon={Clear}
          color="white"
          onClick={() => notistackRef.current?.closeSnackbar(key)}
        />
      )}
    >
      {children as React.ReactElement}
    </SnackbarProvider>
  );
};

export default StackSnackbar;
