import { sumBy, upperFirst } from 'lodash';

import { ObjectClass } from '../../../../utils/LabelInterfaceUtils';
import MathUtils from '../../../../utils/MathUtils';
import { ObjectCountResult } from '../interfaces/apiResponse';
import { JsonObj } from '../userStats/types';

/**
 * Project Analytics > Object Counts
 *
 * object-summaries response only includes object classes that have annotations (i.e. count > 0)
 * Add missing object classes from the label interface.
 *
 * @param objectSettings object class setting in project settings
 * @param data annotated object count data (object-summaries response)
 */
export const includeObjectsWithZeroCounts = (
  objectSettings: ObjectClass[],
  data: ObjectCountResult[],
  isFrameBasedProject: boolean,
): ObjectCountResult[] => {
  const isObjectClassInData = (classId: string, className: string) => {
    return data.find(obj => obj.classId === classId || obj.name === className);
  };
  return [
    ...data,
    ...objectSettings.reduce((agg, obj) => {
      if (!isObjectClassInData(obj.id, obj.name)) {
        agg.push({
          classId: obj.id,
          name: obj.name,
          count: 0,
          ...(isFrameBasedProject && { annotationCount: 0 }),
        });
      }
      return agg;
    }, [] as ObjectCountResult[]),
  ];
};

export const addObjectName = (
  objectSettings: ObjectClass[],
  objectResults: ObjectCountResult[],
): ObjectCountResult[] => {
  const addNameField = (agg: Record<string, string>, obj: ObjectClass) => {
    agg[obj.id] = obj.name;
    return agg;
  };
  const objectIdToName = objectSettings.reduce(addNameField, {});
  return objectResults.map(object =>
    object?.classId ? { ...object, name: objectIdToName[object.classId] } : object,
  );
};

/**
 * Aggregate object counts in multiple datasets to a combined result.
 */
// TODO: check is this still necessary after analytics proxy deployment???
export const combineDatasets = (
  results: ObjectCountResult[],
  isFrameBasedProject: boolean,
): ObjectCountResult[] => {
  const uniqueNames: string[] = [];
  return results.reduce((agg, objResult) => {
    const idOrName = objResult?.classId ?? objResult.name; // legacy workapps have only name
    const objIndex = uniqueNames.indexOf(idOrName);
    if (objIndex !== -1) {
      agg[objIndex] = {
        ...objResult,
        count: objResult.count + agg[objIndex].count,
        ...(isFrameBasedProject && {
          annotationCount: (objResult.annotationCount || 0) + (agg[objIndex].annotationCount || 0),
        }),
      };
      return agg;
    }
    uniqueNames.push(idOrName);
    agg.push(objResult);
    return agg;
  }, [] as ObjectCountResult[]);
};

interface SummaryResponse {
  avgTimePerLabel: number;
  count: number;
  results: JsonObj[];
}
export const getAvgTimePerLabel = (summary: SummaryResponse): number => summary?.avgTimePerLabel;

export const addPercentTotalField = (data: ObjectCountResult[]): ObjectCountResult[] => {
  const totalObjectCounts = sumBy(data, 'count');
  return data.map(d => {
    return {
      ...d,
      displayName: upperFirst(d.name),
      objectPercentTotal: MathUtils.calculatePercent({
        numerator: d?.count || 0,
        denominator: totalObjectCounts || 0,
        nearest: 'hundredth',
      }),
      ...(Number.isInteger(d?.annotationCount) && {
        annotationPercentTotal: MathUtils.calculatePercent({
          numerator: d?.annotationCount || 0,
          denominator: sumBy(data, 'annotationCount') || 0,
          nearest: 'hundredth',
        }),
      }),
    };
  });
};

export function preprocessObjectCounts(
  data: ObjectCountResult[],
  objectSettings: ObjectClass[],
  isFrameBasedProject = false,
): ObjectCountResult[] {
  let result = addObjectName(objectSettings, data);
  result = includeObjectsWithZeroCounts(objectSettings, result, isFrameBasedProject);
  result = combineDatasets(result, isFrameBasedProject);
  result = addPercentTotalField(result);
  return result;
}
