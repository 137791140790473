import { Box, Typography } from '@superb-ai/ui';

type CellProps = { name: string; width: string };

export function ClassificationValueCell({ name, width = '120px' }: CellProps): JSX.Element {
  return (
    <Box style={{ left: '12px' }}>
      <Typography
        style={{
          width: width,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        variant="m-medium"
      >
        {name || ''}
      </Typography>
    </Box>
  );
}
