import React from 'react';
import { ListChildComponentProps } from 'react-window';

import { Box, ListItem, Typography } from '@superb-ai/norwegian-forest';

import { formatDateTime } from '../../../../../utils/date';

export default function ExportListItem({
  index,
  style,
  data: { items, selectedItemId, setSelectedItemId, disabledItemId },
}: ListChildComponentProps) {
  const item = items[index];
  const isDisabled = item.id === disabledItemId;
  const isSelected = item.id === selectedItemId;

  const disabledColor = ['grey', 300];
  const selectedColor = 'secondary';

  const getTypoColor = (color?: any) => {
    if (isDisabled) return disabledColor;
    if (isSelected) return selectedColor;
    if (color) return color;
    return 'initial';
  };

  return (
    <div style={style}>
      <ListItem
        padding="0"
        key={item.id}
        selectedBgColor="secondary"
        bgColor="grey"
        onClick={() => {
          if (isDisabled) return;
          setSelectedItemId(item.id);
        }}
        isSelected={isSelected}
        disabled={isDisabled}
      >
        <Box p={1} display="grid" gridTemplateColumns="5fr 2fr 4fr">
          <Box display="flex" alignItems="center">
            <Typography variant="body3" themedColor={getTypoColor()}>
              {item.name || 'Untitled export'}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body3" themedColor={getTypoColor()}>
              {item.downloadLabelCount || 0}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="4px">
            <Typography variant="body3" themedColor={getTypoColor()}>
              {item.createdBy}
            </Typography>
            <Typography variant="body3" themedColor={getTypoColor(['grey', 400])}>
              {formatDateTime(item.createdAt)}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    </div>
  );
}
