import React, { Suspense } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import ActivatingLayout from '../components/pages/newAuth/Activating';
import AlreadyActivatedLayout from '../components/pages/newAuth/AlreadyActivated';
import ChangePasswordLayout from '../components/pages/newAuth/ChangePassword';
import CodeMismatchLayout from '../components/pages/newAuth/CodeMismatch';
import CreateAccountExpiredLayout from '../components/pages/newAuth/CreateAccountExpired';
// import CreateProjectLayout from '../components/pages/newAuth/CreateProject';
import EmailVerifyLayout from '../components/pages/newAuth/EmailVerify';
import ForgotPasswordLayout from '../components/pages/newAuth/ForgotPassword';
import InvalidPageLayout from '../components/pages/newAuth/InvalidPage';
import InvitedSignUpLayout from '../components/pages/newAuth/InvitedSignUp';
import AuthLayout from '../components/pages/newAuth/Layout';
import MfaSetupLayout from '../components/pages/newAuth/MfaSetup';
import MfaSignInLayout from '../components/pages/newAuth/MfaSignIn';
import SignInLayout from '../components/pages/newAuth/SignIn';
import SignupLayout from '../components/pages/newAuth/SignUp';
import SysAdminSignInLayout from '../components/pages/newAuth/SysAdminSignIn';
import VerifiedSignUpLayout from '../components/pages/newAuth/VerifiedSignUp';
import WelcomeLayout from '../components/pages/newAuth/Welcome';
import { Route as RouteType } from './types';

const NewAuthRoute: RouteType = ({ match }) => (
  <AuthLayout>
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route path={`${match.path}/already_activated`} component={AlreadyActivatedLayout} />
        <Route path={`${match.path}/login`} component={SignInLayout} />
        <Route path={`${match.path}/sys_admin_login`} component={SysAdminSignInLayout} />
        <Route path={`${match.path}/sign_up`} component={SignupLayout} />
        <Route path={`${match.path}/check_email`} component={EmailVerifyLayout} />
        <Route path={`${match.path}/invited`} component={InvitedSignUpLayout} />
        <Route path={`${match.path}/verified`} component={VerifiedSignUpLayout} />
        <Route path={`${match.path}/login-mfa`} component={MfaSignInLayout} />
        <Route path={`${match.path}/setup-mfa`} component={MfaSetupLayout} />
        <Route path={`${match.path}/forgot`} component={ForgotPasswordLayout} />
        <Route path={`${match.path}/change`} component={ChangePasswordLayout} />
        {/* <Route path={`${match.path}/create_project`} component={CreateProjectLayout} /> */}
        {/* <Route path={`${match.path}/invite`} component={InviteMemberLayout} /> */}
        <Route path={`${match.path}/activated`} component={ActivatingLayout} />
        <Route path={`${match.path}/code_mismatch`} component={CodeMismatchLayout} />
        <Route path={`${match.path}/expired`} component={CreateAccountExpiredLayout} />
        <Route
          path={`${match.path}/invalid_account`}
          component={() => <InvalidPageLayout origin="account" />}
        />
        <Route
          path={`${match.path}/invalid_user`}
          component={() => <InvalidPageLayout origin="user" />}
        />
        <Route path={`${match.path}/welcome`} component={WelcomeLayout} />
        <Redirect to={`${match.path}/login${location.search}`} />
      </Switch>
    </Suspense>
  </AuthLayout>
);

NewAuthRoute.url = '/auth';

export default NewAuthRoute;
