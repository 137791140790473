import React, { useCallback, useMemo, useState } from 'react';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';

import { atoms } from '@superb-ai/ui';

import { WindowedGridProps } from './types';

function GridCell({
  columnIndex,
  rowIndex,
  style,
  data: { columns, cellGap, CellComponent, ...otherData },
}: GridChildComponentProps) {
  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : Number(style.left) + columnIndex * cellGap,
    ...(style.right && {
      right: columnIndex === columns ? style.right : Number(style.right) + columnIndex * cellGap,
    }),
    paddingBottom: cellGap,
  };

  return (
    <div style={styles}>
      {CellComponent && (
        <CellComponent
          {...{
            columnIndex,
            rowIndex,
            columns,
            ...otherData,
          }}
        />
      )}
    </div>
  );
}

type ScrollState = {
  rowStartIndex: number;
  columnStartIndex: number;
  rowStopIndex: number;
  columnStopIndex: number;
};

export const WindowedGrid: React.FunctionComponent<
  WindowedGridProps<any> & {
    onRender?: (scrollState: ScrollState) => void;
  }
> = ({
  loadedItems,
  width,
  height,
  itemData,
  CellComponent,
  aspectRatio = 9 / 16,
  additionalHeight = 0,
  onRender,
}) => {
  const className = atoms({ overflow: 'auto' });
  const defaultScrollbarWidth = 18;

  const cellGap = 8;
  const columnWidth =
    (width - defaultScrollbarWidth - cellGap * (itemData.columns - 1)) / itemData.columns;
  const rowHeight = columnWidth * aspectRatio + additionalHeight + cellGap;

  const rowCount = Math.ceil((loadedItems.length || 0) / itemData.columns);

  const [scrollState, setScrollState] = useState({
    rowStartIndex: 0,
    columnStartIndex: 0,
    rowStopIndex: 0,
    columnStopIndex: 0,
  });
  const setScrollRowAndColumn = useCallback(
    (
      rowStartIndex: number,
      columnStartIndex: number,
      rowStopIndex: number,
      columnStopIndex: number,
    ) => {
      setScrollState({ rowStartIndex, columnStartIndex, rowStopIndex, columnStopIndex });
    },
    [],
  );

  const scrollItemData = useMemo(
    () => ({
      ...itemData,
      results: loadedItems,
      cellGap,
      CellComponent,
      aspectRatio,
    }),
    [loadedItems],
  );

  return (
    <FixedSizeGrid
      className={className}
      height={height}
      width={width}
      rowHeight={rowHeight}
      columnWidth={columnWidth}
      rowCount={rowCount}
      columnCount={itemData.columns}
      itemData={scrollItemData}
      initialScrollTop={rowHeight * scrollState.rowStartIndex}
      onItemsRendered={({
        visibleRowStartIndex,
        visibleColumnStartIndex,
        visibleRowStopIndex,
        visibleColumnStopIndex,
      }) => {
        onRender &&
          onRender({
            rowStartIndex: visibleRowStartIndex,
            columnStartIndex: visibleColumnStartIndex,
            rowStopIndex: visibleRowStopIndex,
            columnStopIndex: visibleColumnStopIndex,
          });
        setScrollRowAndColumn(
          visibleRowStartIndex,
          visibleColumnStartIndex,
          visibleRowStopIndex,
          visibleColumnStopIndex,
        );
      }}
    >
      {GridCell}
    </FixedSizeGrid>
  );
};
