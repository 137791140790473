import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { CheckFilled, Clear, ClearFilled } from '@superb-ai/icons';
import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Button, Icon, IconButton, LinkTypography, Typography } from '@superb-ai/ui';
import { QueryObserverResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { openChatWidget } from '../../../../../utils/chatWidget';
import DatasetNameInput from '../../../elements/NameInput/DatasetNameInput';
import { DatasetResult, useCurateDatasetService } from '../../../services/DatasetService';
import { InfoCard } from './InfoCard';
export default function CreateDatasetModal({
  isOpen,
  setIsOpen,
  refetchDataset,
  onCreate,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  refetchDataset?: () => Promise<QueryObserverResult<{ count: number; results: DatasetResult[] }>>;
  onCreate?: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const [isDatasetNameValid, setIsDatasetNameValid] = useState<boolean>(false);
  const { createDataset } = useCurateDatasetService();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [newDatasetName, setNewDatasetName] = useState<string>('');
  const [newDatasetDescription, setNewDatasetDescription] = useState<string>('');

  const handleCreateDataset = async () => {
    try {
      const res = await createDataset({ name: newDatasetName, description: newDatasetDescription });
      enqueueSnackbar(
        <>{`${t('bulkActions.messages.createdDataset', { name: newDatasetName })}`}</>,
        {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 10000,
          content: (key, message) => {
            return (
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '420px',
                  height: '101px',
                  background: '#1f1f1f',
                  borderLeft: '4px solid  #82db24',
                  padding: '20px',
                }}
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box display="flex">
                    <Icon icon={CheckFilled} color="green-400" size={24} />
                    <Box display="flex" ml={1} pt={0.5}>
                      <Typography variant="m-strong" color={'white'}>
                        {message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{ maxHeight: '24px' }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      icon={Clear}
                      variant="text"
                      color="white"
                      onClick={() => closeSnackbar(key)}
                    />
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                      size="m"
                      style={{ color: 'white' }}
                      variant="text"
                      onClick={() => {
                        history.push(`/${accountName}/curate/dataset/${res.id}/explore`);
                        closeSnackbar(key);
                      }}
                    >
                      {t('button.view')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          },
        },
      );
    } catch (e) {
      enqueueSnackbar(`${e ?? 'Error'}`, {
        preventDuplicate: true,
        autoHideDuration: 10000,
        content: (key, message) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                width: '420px',
                minHeight: '101px',
                background: '#1f1f1f',
                borderLeft: `${'4px solid #FF3A28'}`,
                padding: '20px',
              }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex">
                  <Icon icon={ClearFilled} color={'red-400'} size={24} />
                  <Box display="flex" ml={1} pt={0.5} flexDirection="column">
                    <Box>
                      <Box mb={1}>
                        <Typography variant="m-strong" color={'white'}>
                          {`Failed to create dataset "${newDatasetName}"`}
                        </Typography>
                      </Box>
                      <Box my={1}>
                        <Typography variant="m-regular" color={'white'}>
                          {message}
                        </Typography>
                      </Box>
                      <Box onClick={() => openChatWidget()}>
                        <LinkTypography variant="m-regular">
                          {t('text.contactSupport')}
                        </LinkTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ maxHeight: '24px' }}>
                  <IconButton
                    icon={Clear}
                    variant="text"
                    color="white"
                    onClick={() => closeSnackbar(key)}
                  />
                </Box>
              </Box>
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}></Box>
            </Box>
          );
        },
      });
    }
    setIsOpen(false);
    refetchDataset && (await refetchDataset());
    onCreate && onCreate();
  };

  const handleCloseButton = useCallback(() => {
    setIsOpen(false);
    setNewDatasetName('');
    setNewDatasetDescription('');
  }, [setIsOpen, setNewDatasetName, setNewDatasetDescription]);

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setNewDatasetDescription(e.target.value);
    },
    [setNewDatasetDescription],
  );

  return (
    <Modal
      open={isOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.createNewDataset')}
      mainButton={{
        text: t('button.okay'),
        onClick: () => handleCreateDataset(),
        color: 'primary',
        disabled: !isDatasetNameValid && newDatasetName.length > 0,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box py={2.5} px={4} minWidth="520px">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="gray">
            {t('curate.data.datasetName')}
          </Typography>
          <Typography variant="m-regular" color="gray">
            {`${newDatasetName?.length}/50`}
          </Typography>
        </div>
        <DatasetNameInput
          placeholder={t('curate.dialogs.placeholder.name')}
          datasetName={newDatasetName}
          setDatasetName={setNewDatasetName}
          setIsValid={setIsDatasetNameValid}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Typography variant="m-regular" color="gray">
            {t('curate.data.description')}
            <span style={{ color: '#B3B3B3' }}>(Optional)</span>
          </Typography>
          <Typography variant="m-regular" color="gray">
            {`${newDatasetDescription?.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.description')}
          onChange={handleDatasetDescription}
          value={newDatasetDescription}
        ></StyledTextarea>
        {InfoCard({ t, i18nKey: 'curate.datasets.createGuide' })}
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
