import React, { ReactElement, useEffect } from 'react';

import { NewProjectProvider } from '../../../contexts/NewProjectContext';
import Layout from './Layout';

let isMount = true;
// eslint-disable-next-line
const Index = (props: any): ReactElement => {
  useEffect(() => {
    isMount = true;
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (isMount) {
        e.returnValue = 'text';
      }
    };

    return () => {
      isMount = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <NewProjectProvider>
      <Layout {...props} />
    </NewProjectProvider>
  );
};

export default Index;
