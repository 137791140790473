import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import ReportExportListRow from './ReportExportListRow';

type Props = {
  items: Record<string, any>[];
  setSelectedItem: Dispatch<SetStateAction<Record<string, any>[]>>;
  selectedItemId: string;
  setSelectedItemId: Dispatch<SetStateAction<string>>;
  disabledExportId?: string;
};

const ReportExportList = ({
  items,
  selectedItemId,
  setSelectedItemId,
  disabledExportId,
  setSelectedItem,
}: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedItemId === disabledExportId) {
      setSelectedItemId('');
    }
  }, [selectedItemId, disabledExportId, setSelectedItemId]);
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      style={{ border: '1px solid #E5E5E5', borderRadius: '2px' }}
    >
      <Box p={1} bb display="grid" gridTemplateColumns="5fr 2fr 4fr 2fr">
        <Box>
          <Typography variant="body5">
            {t('analytics.exportsAnalytics.modal.table.exportName')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body5">
            {t('analytics.exportsAnalytics.modal.table.labelCount')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body5">
            {t('analytics.exportsAnalytics.modal.table.created')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body5">
            {t('analytics.exportsAnalytics.modal.table.fileSize')}
          </Typography>
        </Box>
      </Box>
      <FixedSizeList
        width="100%"
        height={260}
        itemSize={56}
        itemCount={items.length}
        itemData={{
          items,
          selectedItemId,
          setSelectedItemId,
          disabledExportId,
          setSelectedItem,
        }}
      >
        {ReportExportListRow}
      </FixedSizeList>
    </Box>
  );
};

export default ReportExportList;
