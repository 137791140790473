import React, { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';
import { ContainerElement } from 'd3';
import { subDays } from 'date-fns';
import { isEmpty, isNull, isUndefined, map } from 'lodash';

import { COLOR_CHIP_TAG_DEFAULT } from '../../../../../consts/ColorChips';
/** contexts & utils */
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { parseDate } from '../../../../../utils/date';
import DateUtils from '../../../../../utils/DateUtils';
/** constants, configs, types */
import { COLORS } from '../../config/colors';
import { filterLabelList } from '../../config/routeConfig';
import { SvgConfigObject } from '../../config/types';
import DateXAxisBottom from '../../elements/axes/DateXAxisBottom';
import { Tooltip } from '../../elements/TooltipLine';
import YAxis from '../../elements/YAxis';
import { ExtendedScaleTime } from '../../interfaces/d3Types';
/** components */
import AreaGradient from './AreaGradient';
import TotalIndicator from './TotalIndicator';

/**
 * Components
 * - AreaGradient
 * - DateXAxisBottom
 * - YAxis
 * - Legend
 * - Tooltip
 */

/**
 * Line chart is currently tied to these Data parameters
 */

/** Data from Project Progress Chart (chart is tightly coupled with data) */
type LabelData = {
  count: 0;
  date: Date;
  updatedAt?: Date;
  cumSkipped: 0;
  cumSubmitted: 0;
  cumWorking: 0;
  percentSubmitted: 0;
  skipped: 0;
  submitted: 0;
  working: 0;
};

/** Data for tooltip */
interface TooltipContentData {
  xValue: Date;
  yValue: number;
  submitted: number;
  working: number;
  skipped: number;
  data: LabelData;
}

/** Initial point to prepend (handles one point case) */
const LabelDataTemplate: LabelData = {
  count: 0,
  date: new Date(DateUtils.getTodaysDate()),
  cumSkipped: 0,
  cumSubmitted: 0,
  cumWorking: 0,
  percentSubmitted: 0,
  skipped: 0,
  submitted: 0,
  working: 0,
};

interface Props {
  data: LabelData[]; // daily label counts
  xValues: Date[];
  yValues: number[];
  totalLabels: number; // used to make `Total` labels line and adjust y-axis range
  chartName: string;
  isCumulative: boolean; // whether to render data cumulative vs daily
  filter: Record<string, string[] | []>;
  svgInfo: SvgConfigObject;
}

const LineChartWithTooltip: React.FC<Props> = props => {
  const { data, xValues, yValues, totalLabels, chartName, isCumulative, filter, svgInfo } = props;

  const routeInfo = useRouteInfo();
  const { GRAPEFRUIT, GREYISH } = COLOR_CHIP_TAG_DEFAULT;
  const [keepTooltipOpen, setKeepTooltipOpen] = useState(false);
  const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<TooltipContentData>(
    {} as TooltipContentData,
  );
  const gradientId = `line-area-gradient-${chartName}`;
  const LINE_PATH_STYLE = {
    stroke: GRAPEFRUIT,
    strokeWidth: 2.7,
    opacity: 0.7,
    fill: 'none',
  };
  const svgRef = useRef(null);
  let numDays = xValues.length;
  if (xValues.length >= 2) {
    numDays = DateUtils.getNumberOfDatesBetween(xValues.slice(0, 1)[0], xValues.slice(-1)[0]);
  }

  /** Line chart doesn't render for a single point, so prepend data for the previous date. */
  if (data.length === 1) {
    const { updatedAt } = data[0]; // d3 date object
    if (updatedAt) {
      data.unshift({
        ...LabelDataTemplate,
        date: subDays(parseDate(updatedAt), 1),
      });
    }
  }

  // Set scales
  const x: ExtendedScaleTime = d3
    .scaleTime()
    .rangeRound([0, svgInfo.width])
    .domain(d3.extent(data, d => d.date) as Date[]);
  x.scaleType = 'DATE';

  const yOffsetScale = 1.2; // multiple y max by this value

  /** y scale includes yMax (daily) or total labels (cumulative) by default */
  const yMax = d3.max(yValues) as number;

  // still render y-axis for 0 total labels. yMax may be > current total if labels were deleted from the project
  const yThreshold = totalLabels === 0 ? 10 : Math.max(yMax, totalLabels) * yOffsetScale;
  const y = d3
    .scaleLinear()
    .domain(isCumulative ? [0, yThreshold] : [d3.min(yValues) as number, yMax * 1.1])
    .range([svgInfo.height, 0]);

  const yThresholdLoc: number = isCumulative
    ? (y(totalLabels) as number) - 20
    : (y(yMax) as number) - 3;

  const resetLineChartHover = () => {
    d3.select('.focus-line').style('display', 'none');
    d3.select('.focus-circle').style('display', 'none');
    d3.select('.focus-tooltip').style('display', 'none');
    setTooltipContent({} as TooltipContentData);
  };

  /* Returns current data index */
  const bisectDate = d3.bisector((d: LabelData) => {
    return d?.date;
  }).left;

  useEffect(() => {
    /**  On [Total] or [Daily] button click, reset line chart and remove all hover content */
    setKeepTooltipOpen(false);
    resetLineChartHover();
  }, [isCumulative]);

  useEffect(() => {
    const drawHoverLineAndCircle = (
      d: LabelData,
      x: ExtendedScaleTime,
      y: d3.ScaleLinear<number, number, never>,
    ) => {
      const [xLoc, yLoc] = [x(d.date) as number, y(d.count) as number];
      const focusLine = d3.select('.focus-line');
      if (!Number.isNaN(xLoc) && !Number.isNaN(yLoc)) {
        focusLine
          .select('line')
          .attr('x1', xLoc)
          .attr('x2', xLoc)
          .attr('y2', svgInfo.height)
          .attr('y1', Math.min(yThresholdLoc, svgInfo.top))
          .attr('stroke', COLORS.SALMON)
          .attr('opacity', '0.5')
          .attr('border-radius', '7px')
          .attr('box-shadow', '0 2px 10px 0 rgba(169, 169, 169, 0.26)')
          .attr('stroke-dasharray', '3,2')
          .attr('stroke-width', '1px');
        const focusCircle = d3.select('.focus-circle');
        focusCircle.attr('transform', `translate(${xLoc},${yLoc})`);
        focusCircle
          .select('#circle1')
          .attr('r', '9')
          .attr('opacity', '1')
          .attr('fill', COLOR_CHIP_TAG_DEFAULT.GRAPEFRUIT)
          .attr('stroke-width', 1)
          .attr('stroke-opacity', 0.25);
        focusCircle
          .select('#circle2')
          .attr('r', '4.5')
          .attr('opacity', '1')
          .attr('fill', COLORS.WHITE)
          .attr('stroke', COLORS.RED)
          .attr('stroke-width', 1);
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (keepTooltipOpen && !isMouseOverTooltip) {
        return;
      }
      const overlay = d3.select('.overlay').node() as ContainerElement;
      const mouseDate = x.invert(d3.pointer(event, overlay)[0]) as Date;
      /* Current data index */
      const i = bisectDate(data, mouseDate, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      if (!isUndefined(d0) && !isUndefined(d1)) {
        /** @ts-ignore Date comparison works */
        const d = mouseDate - d0.date > d1.date - mouseDate ? d1 : d0;
        drawHoverLineAndCircle(d, x, y);
        setTooltipContent({
          xValue: d.date,
          yValue: d.count,
          submitted: isCumulative ? d.cumSubmitted : d.submitted,
          working: isCumulative ? d.cumWorking : d.working,
          skipped: isCumulative ? d.cumSkipped : d.skipped,
          data: d,
        });
      }
    };

    if (data.length > 0) {
      d3.select('.overlay').on('mousemove', handleMouseMove);
    }
  }, [
    data,
    x,
    y,
    svgInfo,
    totalLabels,
    yThresholdLoc,
    isCumulative,
    bisectDate,
    keepTooltipOpen,
    isMouseOverTooltip,
  ]);

  /** Define the gradient area */
  const area = d3
    .area<LabelData>()
    .defined(d => !Number.isNaN(d.date) && !Number.isNaN(d.count))
    .curve(d3.curveMonotoneX)
    .x(d => x(d.date) as number)
    .y0(y(0))
    .y1(d => y(d.count));

  /** Define line function */
  const valueline = d3
    .line<{
      count: number;
      date: Date;
    }>()
    .defined(d => !Number.isNaN(d.date))
    .curve(d3.curveMonotoneX)
    .x(d => x(d.date))
    .y(d => y(d.count));

  const handleMouseOver = () => {
    if (!keepTooltipOpen) {
      d3.select('.focus-line').style('display', null);
      d3.select('.focus-circle').style('display', null);
      d3.select('.focus-tooltip').style('display', null);
    }
  };

  const handleMouseOut = () => {
    if (!keepTooltipOpen) {
      resetLineChartHover();
    }
  };

  const handleTooltipMouseEnter = () => {
    const currentTooltipContent = tooltipContent;
    setIsMouseOverTooltip(true);
    setKeepTooltipOpen(true);
    setTooltipContent(currentTooltipContent);
  };

  const handleTooltipMouseLeave = () => {
    setIsMouseOverTooltip(false);
    setKeepTooltipOpen(false);
    resetLineChartHover();
  };

  const gTransform = `translate(${svgInfo.left},${svgInfo.top})`;

  /** Consider: is this necessary? */
  const dataSubset = map(data, d => {
    return {
      count: d.count,
      date: d.date,
    };
  });

  const handleSVGClick = () => {
    if (keepTooltipOpen) {
      setKeepTooltipOpen(!keepTooltipOpen);
      setTooltipContent({} as TooltipContentData);
    } else {
      setKeepTooltipOpen(!keepTooltipOpen);
    }
  };

  /**
   * Date updated is used to filter label list
   */
  const handleClickInspect = (date: Date) => {
    const filterInput = { updatedAt: date, ...filter };

    // side effect (reroutes)
    filterLabelList({
      routeInfo,
      filterBy: filterInput, // query object
    });
  };

  return (
    <svg
      id={svgInfo.index}
      width={svgInfo.svgWidth}
      height={svgInfo.svgHeight}
      ref={svgRef}
      style={{ overflow: 'visible' }}
    >
      <AreaGradient gradientId={gradientId} brightness="light" />
      {/* <Legend colorMap={COLOR_MAP} location={{ transformX: chart.svgWidth - 205, transformY: 30 }} /> */}
      {x.scaleType === 'DATE' && (
        <DateXAxisBottom
          bottom={svgInfo.bottom}
          left={svgInfo.left}
          width={svgInfo.width}
          height={svgInfo.svgHeight}
          scale={x}
          xLength={numDays}
        />
      )}
      <YAxis top={svgInfo.top} left={svgInfo.left} width={svgInfo.width} scale={y} />
      <g transform={gTransform} className="outer-group">
        <g className="line-chart">
          {dataSubset && !isNull(valueline(dataSubset)) && (
            <path className="line" d={valueline(dataSubset) as string} style={LINE_PATH_STYLE} />
          )}
          {data && !isNull(area(data)) && (
            <path className="area" d={area(data) as string} fill={`url(#${gradientId})`} />
          )}
          {isCumulative ? (
            <g>
              <line
                className="total-line"
                x1="0"
                y1={y(totalLabels)}
                x2={svgInfo.width}
                y2={y(totalLabels)}
                stroke={GREYISH}
                strokeDasharray="3,2"
                strokeWidth="1px"
                opacity="0.5"
              />
              {!isUndefined(totalLabels) && (
                <TotalIndicator x={svgInfo.width} y={y(totalLabels) as number} />
              )}
            </g>
          ) : null}
        </g>
        <g className="focus-line" style={{ display: 'none' }}>
          <line key="hover-line" />
        </g>
        <g className="focus-circle" style={{ display: 'none' }}>
          <circle id="circle1" />
          <circle id="circle2" />
        </g>
        <g className="focus-tooltip" style={{ display: 'none' }} />
        <rect
          className="overlay"
          x={0}
          y={0}
          width={svgInfo.width}
          height={svgInfo.height}
          fill="none"
          opacity="0"
          pointerEvents="all"
          onMouseOver={() => handleMouseOver()}
          onFocus={() => handleMouseOver()}
          onMouseOut={() => handleMouseOut()}
          onBlur={() => handleMouseOut()}
          onClick={() => handleSVGClick()}
        />
      </g>
      {!isEmpty(tooltipContent) && (
        <Tooltip
          left={svgInfo.left}
          top={svgInfo.top}
          xValue={tooltipContent.xValue}
          yValue={tooltipContent.yValue}
          working={tooltipContent.working}
          skipped={tooltipContent.skipped}
          scales={[x, y]}
          handleClickInspect={handleClickInspect}
          handleTooltipMouseEnter={handleTooltipMouseEnter}
          handleTooltipMouseLeave={handleTooltipMouseLeave}
          total={totalLabels}
          // yOffset={-Math.abs(y(totalLabels))}
        />
      )}
    </svg>
  );
};

export default LineChartWithTooltip;
