import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@superb-ai/norwegian-forest';
import { isUndefined } from 'lodash';

import { ScoreType } from '../../qaAnalytics/types';
import { JsonObj } from '../../userStats/types';

interface TooltipProps {
  yBottom: number; // bottom threshold location for y
  yUp: number; // upper threshold location for y
  scoreMin?: number;
  scoreMax?: number;
  data: JsonObj[];
  scoreType: ScoreType;
  chartHeight: number;
  leftOffset: number;
  // tooltipType?: 'legend' | 'brush';
}

/**
 * Calculate and display selected (legend or brush) data statistics here, including:
 *  - score range
 *  - labeler count
 */
const ChartTooltip: React.FC<TooltipProps> = props => {
  const { t } = useTranslation();
  const { data, yBottom, yUp, scoreMin, scoreMax, scoreType, chartHeight, leftOffset } = props;

  const tooltipRef = useRef<HTMLElement>();
  const [tooltipHeight, setTooltipHeight] = useState<number | null>(null);

  useEffect(() => {
    setTooltipHeight(chartHeight - yUp);
  }, [tooltipRef, yBottom, yUp]);

  /** Calculate summary to display in tooltip **/
  // let scoreSum = 0;
  const totalLabelerCount = data.length;
  let labelerCount = 0;
  // let labelCount = 0;
  for (const datum of data) {
    const currentScore = datum[scoreType];
    if (
      !isUndefined(scoreMin) &&
      !isUndefined(scoreMax) &&
      currentScore >= scoreMin &&
      currentScore <= scoreMax
    ) {
      labelerCount += 1;
      // labelCount += datum.consensusLabelCount;
      // scoreSum += datum['meanScore'] * labelCount;
    }
  }

  // calculate mean iff denominator is > 0
  // const scoreMean = scoreSum >= 0 && labelCount > 0 ? (scoreSum / labelCount).toFixed(1) : 'n/a';

  /** labeler count (percent total labelers) */
  const percentTotal =
    totalLabelerCount === 0 ? 0 : ((100 * labelerCount) / totalLabelerCount).toFixed(1);

  return (
    <>
      <Tooltip
        anchorEl={
          <Box
            ref={tooltipRef}
            position="absolute"
            style={{
              left: leftOffset + 20 || 0,
              bottom: tooltipHeight || 0,
              fontFamily: 'Infer',
            }}
            opacity={1.0}
            themedBackgroundColor="green"
            color="white"
            width={2}
            height={1}
          >
            {' '}
          </Box>
        }
        placement="top-start"
        defaultOpen
      >
        {t('analytics.labelerCount')}: <strong>{labelerCount}</strong> ({percentTotal}%)
        <br />
        {t('analytics.scoreRange')}:
        <strong> {scoreMin === scoreMax ? scoreMin : `${scoreMin} ~ ${scoreMax}`}</strong>
        <br />
      </Tooltip>
    </>
  );
};

export default ChartTooltip;
