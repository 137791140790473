import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { ChevronDown } from '@superb-ai/icons';
import { Card, Popper } from '@superb-ai/norwegian-forest';
import { Button, Icon, Typography } from '@superb-ai/ui';

export default function SortImageDropdown({
  sortOrder,
  handleSortOrder,
  sortBy,
  handleSortBy,
}: {
  sortOrder: 'desc' | 'asc';
  handleSortOrder: (order: 'desc' | 'asc') => void;
  sortBy: string;
  handleSortBy: (sort: string) => void;
}): JSX.Element {
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDropdownClick = (sortType: string, order: 'desc' | 'asc') => {
    handleSortBy(sortType);
    handleSortOrder(order);
    setSelectIsOpen(false);
  };

  const renderButtonText = () => {
    if (sortBy === 'created_at') {
      return sortOrder === 'desc' ? t('curate.button.newestFirst') : t('curate.button.oldestFirst');
    } else {
      return sortOrder === 'desc' ? t('curate.button.mostCommon') : t('curate.button.leastCommon');
    }
  };

  return (
    <Popper
      open={selectIsOpen}
      sameWidth
      useOutsidePanel
      placement="bottom-start"
      onClickOutside={() => setSelectIsOpen(false)}
      anchorEl={
        <Button
          style={{ minWidth: '140px' }}
          variant="text"
          color="gray"
          onClick={() => setSelectIsOpen(true)}
        >
          <Typography whiteSpace="nowrap" variant="m-regular">
            {renderButtonText()}
          </Typography>
          <Icon style={{ marginLeft: 'auto' }} icon={ChevronDown} size="16px" />
        </Button>
      }
    >
      <Card py={0.5}>
        <SelectBox
          onClick={() => handleDropdownClick('created_at', 'desc')}
          selected={sortBy === 'created_at' && sortOrder === 'desc'}
        >
          <Typography
            variant="m-regular"
            color={sortBy === 'created_at' && sortOrder === 'desc' ? 'primary-500' : 'gray-500'}
          >
            {t('curate.button.newestFirst')}
          </Typography>
        </SelectBox>
        <SelectBox
          onClick={() => handleDropdownClick('created_at', 'asc')}
          selected={sortBy === 'created_at' && sortOrder === 'asc'}
        >
          <Typography
            variant="m-regular"
            color={sortBy === 'created_at' && sortOrder === 'asc' ? 'primary-500' : 'gray-500'}
          >
            {t('curate.button.oldestFirst')}
          </Typography>
        </SelectBox>
        <SelectBox
          onClick={() => handleDropdownClick('leaf_cluster_size', 'desc')}
          selected={sortBy === 'leaf_cluster_size' && sortOrder === 'desc'}
        >
          <Typography
            variant="m-regular"
            color={
              sortBy === 'leaf_cluster_size' && sortOrder === 'desc' ? 'primary-500' : 'gray-500'
            }
          >
            {t('curate.button.mostCommon')}
          </Typography>
        </SelectBox>
        <SelectBox
          onClick={() => handleDropdownClick('leaf_cluster_size', 'asc')}
          selected={sortBy === 'leaf_cluster_size' && sortOrder === 'asc'}
        >
          <Typography
            variant="m-regular"
            color={
              sortBy === 'leaf_cluster_size' && sortOrder === 'asc' ? 'primary-500' : 'gray-500'
            }
          >
            {t('curate.button.leastCommon')}
          </Typography>
        </SelectBox>
      </Card>
    </Popper>
  );
}

const SelectBox = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 7px 12px 7px 20px;
  background: ${props => (props.selected ? '#fff8f8' : '#ffffff')};
  &:hover {
    background: #fff8f8;
    p {
      color: #ff625a;
    }
  }
`;
