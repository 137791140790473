import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { TFunction } from 'next-i18next';

import analyticsTracker from '../../../../../../../../analyticsTracker';
import { useDatasetContext } from '../../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetObjectCountQuery } from '../../../../../../queries/datasetObjectQueries';
import { isImageScope, ScopeMode } from '../../../../../../types/viewTypes';
import { getScopeFromRoute } from '../../../../../../utils/routeUtils';
import { LegendLabel, SingleLegend } from '../../../analytics/components/Legends';
import { useCompareModeContext } from '../../../analytics/contexts/CompareModeContext';
import { CompareOption, getCompareOption } from '../../../analytics/utils/utils';
import { DropdownLegend } from './components/DropdownLegend';
import { COMPARED_POINT_LEGEND_COLOR } from './const';
import { useComparedPoints } from './providers/DataProvider';

function getSamplingText({
  sampledCount,
  totalCount,
  scope,
  t,
}: {
  sampledCount?: number;
  totalCount?: number;
  scope: ScopeMode;
  t: TFunction;
}) {
  return t(`curate.embeddings.legend.${scope}CountTooltip`, {
    sampledCount: sampledCount ?? '...',
    totalCount: totalCount ?? '...',
  });
}

function getCompareWithText({ compareWithSlice, t }: { compareWithSlice: boolean; t: TFunction }) {
  const keySuffix = compareWithSlice ? 'Slice' : 'Dataset';
  return t(`curate.analytics.dataComparison.compareWith${keySuffix}`);
}

export const ComparedPointsLegend = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const scope = getScopeFromRoute(history);

  const { datasetId, accountName, sliceId } = useParams<{
    datasetId: string;
    accountName: string;
    sliceId?: string;
  }>();
  const { datasetInfo } = useDatasetContext();
  const { queryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const { hasAppliedFilters: hasObjectAppliedFilters, appliedFilters: objectAppliedFilters } =
    useObjectFilterContext();
  const { appliedFilters: imageAppliedFilters, hasAppliedFilters } = useImageFilterContext();

  const { comparedId, setComparedId, showComparedPoints, setShowComparedPoints } =
    useCompareModeContext();
  const comparedPoints = useComparedPoints();
  const sliceObjectQuery = useDatasetObjectCountQuery({
    datasetId,
    sliceName: sliceInfo?.name || '',
    queryString: '',
  });
  const datasetObjectQuery = useDatasetObjectCountQuery({
    datasetId,
    sliceName: '',
    queryString: '',
  });

  const hasFilters = isImageScope(scope) ? hasAppliedFilters : hasObjectAppliedFilters;
  const hasQueryOrFilter = queryString || hasFilters;
  const currentPageSet = sliceInfo ? 'slice' : 'dataset';
  const isSlice = currentPageSet === 'slice';
  const compareWithDatasetOnly = (isSlice && !hasQueryOrFilter) || (!isSlice && hasQueryOrFilter);

  function getTotalCount({ compareWithSlice }: { compareWithSlice: boolean }) {
    if (scope === 'image') {
      return compareWithSlice ? sliceInfo?.imageCount : datasetInfo?.imageCount;
    } else {
      return compareWithSlice ? sliceObjectQuery?.data?.count : datasetObjectQuery?.data?.count;
    }
  }

  const isSliceSelected = comparedId ? comparedId === sliceInfo?.id : true;
  const totalPointsCount = getTotalCount({ compareWithSlice: isSliceSelected });

  const trackChartCompare = (target: 'slice' | 'dataset', action: 'select-dropdown' | 'click') => {
    analyticsTracker.curateDataCompared({
      accountId: accountName,
      sliceId,
      chartName: `${scope}-embeddings`,
      comparisonTarget: target,
      interactionType: action,
      queryString,
      appliedFilters: isImageScope(scope) ? imageAppliedFilters : objectAppliedFilters,
    });
  };

  if (compareWithDatasetOnly) {
    return (
      <SingleLegend
        tooltipContent={
          showComparedPoints
            ? getSamplingText({
                t,
                scope,
                sampledCount: comparedPoints.length,
                totalCount: totalPointsCount,
              })
            : getCompareWithText({ compareWithSlice: false, t })
        }
        legendLabel={
          {
            visible: showComparedPoints,
            setVisibility: setShowComparedPoints,
            legendText: datasetInfo?.name || '',
            hexColor: COMPARED_POINT_LEGEND_COLOR,
          } as LegendLabel
        }
        setCompareSetId={setComparedId}
        compareSetId={datasetInfo?.id || ''}
        trackSelectEvent={() => trackChartCompare('dataset', 'click')}
      />
    );
  }

  // if slice is selected, compare with slice
  const legendOptions = [
    {
      group: 'Slice',
      id: sliceInfo?.id,
      name: sliceInfo?.name,
      totalCount: sliceInfo?.imageCount,
      optionTooltip: t('curate.analytics.dataComparison.compareWithSlice'),
    },
    {
      group: 'Dataset',
      id: datasetInfo?.id,
      name: datasetInfo?.name,
      totalCount: datasetInfo?.imageCount,
      dropdownTooltip: t('curate.analytics.dataComparison.compareWithDataset'),
    },
  ] as CompareOption[];

  const handleClickComparedLegend = () => {
    const showCompared = !showComparedPoints;
    setShowComparedPoints(showCompared);
    if (showCompared) {
      trackChartCompare(currentPageSet, 'click');
    }
  };

  const getComparedGroup = (options: typeof legendOptions, id: string) =>
    getCompareOption(options, id)?.group ?? 'Dataset';

  const handleSelectOption = (newId: string) => {
    if (!showComparedPoints || comparedId !== newId) {
      setComparedId(newId);
      const compareTarget = getComparedGroup(legendOptions, newId).toLowerCase() as
        | 'slice'
        | 'dataset';
      trackChartCompare(compareTarget, 'select-dropdown');
    }
  };

  return (
    <DropdownLegend
      options={legendOptions}
      selectedOptionId={comparedId ?? ''}
      handleSelectOption={handleSelectOption}
      legend={{
        color: COMPARED_POINT_LEGEND_COLOR,
        selectedOptionColor: 'red-500',
        isChecked: showComparedPoints,
        handleClickLabel: handleClickComparedLegend,
        tooltip: showComparedPoints
          ? getSamplingText({
              t,
              scope,
              sampledCount: comparedPoints.length,
              totalCount: totalPointsCount,
            })
          : getCompareWithText({ t, compareWithSlice: isSliceSelected }),
      }}
    />
  );
};
