import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  Box,
  Link,
  Modal,
  TextEllipsis,
  Typography,
  useAlertModal,
} from '@superb-ai/norwegian-forest';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import AnalyticsTracker from '../../../../../analyticsTracker';
import { getUrl } from '../../../../../routes/util';
import ProjectService from '../../../../../services/ProjectService';
import { CustomAutoLabelType } from '../../../../../types/customAutoLabelTypes';
import {
  CategoryPropertyList,
  ImageLabelInterface,
  ObjectClass,
  VideoLabelInterface,
} from '../../../../../utils/LabelInterfaceUtils';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import SearchBar from '../../../../elements/SearchBar';
import { Engine } from '../settings/helper';
import CategorizationTable from './CategorizationTable';
import ObjectDetectionTable from './ObjectDetectionTable';
import { getEngineInfoPerClass, getEngineInfoPerProperty, getNewLabelInterface } from './utils';

export const ApplyCustomAutoLabelModal = ({
  open,
  setOpen,
  customAutoLabel,
  enginesMap,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  customAutoLabel: CustomAutoLabelType;
  enginesMap: Map<string, Engine>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { accountName } = useParams<{ accountName: string }>();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const history = useHistory();
  const {
    project: { labelInterface: projectLabelInterface, workapp },
    updateProjectInfo,
  } = projectInfo;
  const { labelInterface } = customAutoLabel;
  const objectClasses: ObjectClass[] =
    (labelInterface as ImageLabelInterface)?.objectDetection?.objectClasses ||
    (labelInterface as VideoLabelInterface)?.objectTracking?.objectClasses ||
    [];
  const categorization: CategoryPropertyList = labelInterface?.categorization?.properties || [];

  // get engine info per class or property from project label interface
  const projectObjectEngine = getEngineInfoPerClass(projectLabelInterface, enginesMap);
  const projectCategorizationEngine = getEngineInfoPerProperty(projectLabelInterface, enginesMap);

  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [checkedList, setCheckedList] = useState<{
    objectClass?: string[];
    categorization?: string[];
  }>();
  const checkedCount =
    (checkedList?.objectClass?.length || 0) + (checkedList?.categorization?.length || 0);

  const searchedList = useMemo(
    () => ({
      objectClass: objectClasses.filter(
        objectClass =>
          objectClass.annotationType.toLowerCase().includes(searchValue.toLowerCase()) ||
          objectClass.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
      categorization: categorization.filter(category =>
        category.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    }),
    [labelInterface, searchValue],
  );

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!customAutoLabel?.modelDetail) {
      return;
    }

    setSearchValue('');
  }, [open]);

  const handleClickConfirm = async () => {
    if (checkedCount === 0 || isLoading) {
      return;
    }

    setIsLoading(true);

    AnalyticsTracker.customAutoLabelAiApplied({
      accountId: accountName,
      annotationTypeCount: checkedCount,
    });

    const newLabelInterface = getNewLabelInterface({
      workapp,
      projectLabelInterface,
      checkedCAL: checkedList,
      customAutoLabel,
    });
    await ProjectService.editLabelInterface({
      labelInterface: newLabelInterface,
      isGuest: false,
      urlInfo: routeInfo.urlMatchInfo,
    });

    await updateProjectInfo();

    setIsLoading(false);
    setOpen(false);
    setCheckedList(undefined);

    openModal({
      title: t('customAutoLabelSuccessDialog.title'),
      variant: 'success',
      content: (
        <>
          <Typography variant="body3">{t('customAutoLabelSuccessDialog.content')}</Typography>
          <Box mt={2}>
            <Typography variant="body3">
              👉{' '}
              <Trans
                i18nKey="customAutoLabelSuccessDialog.goToLabels"
                components={{
                  link: (
                    <Link
                      underlined
                      onClick={() => {
                        history.push(
                          getUrl([
                            accountName,
                            'label',
                            'project',
                            projectInfo.project.id,
                            'labels',
                          ]),
                        );
                        closeModal();
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
          <Box mt={0.5}>
            <Typography variant="body3">
              👉{' '}
              <Trans
                i18nKey="customAutoLabelSuccessDialog.goToLabels"
                components={{
                  link: (
                    <Link
                      underlined
                      onClick={() => {
                        history.push(
                          getUrl([
                            accountName,
                            'project',
                            projectInfo.project.id,
                            'auto-label',
                            'settings',
                          ]),
                        );
                        closeModal();
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        </>
      ),
      mainButton: {
        text: t('button.okay'),
        onClick: () => closeModal(),
      },
      canConfirmWithEnter: true,
    });
  };

  const handleClickCancel = () => {
    setOpen(false);
    setCheckedList(undefined);
  };

  return (
    <Modal
      open={open}
      title={t('applyCustomAutoLabelModal.title')}
      close={{
        onClose: handleClickCancel,
        hasCloseButton: true,
      }}
      mainButton={{
        text: t('applyCustomAutoLabelModal.applyButton', { count: checkedCount }),
        onClick: handleClickConfirm,
        disabled: checkedCount === 0,
        isLoading,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClickCancel,
      }}
    >
      <Box width={1000}>
        <Box px={4} mb={3}>
          <Typography variant="body3">{t('applyCustomAutoLabelModal.content')}</Typography>
        </Box>

        <Box px={4}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box maxWidth={580}>
              <Typography variant="headline5">
                <TextEllipsis text={customAutoLabel.name} />
              </Typography>
            </Box>
            <Box width={404}>
              <SearchBar
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder={t('applyCustomAutoLabelModal.searchPlaceholder')}
              />
            </Box>
          </Box>

          <Box width="100%" pt={1.5} display="flex" height={480} flexDirection="column">
            {objectClasses.length > 0 && (
              <ObjectDetectionTable
                modelDetail={customAutoLabel?.modelDetail}
                searchedObjectClasses={searchedList.objectClass}
                selectedObjectClasses={checkedList?.objectClass}
                onChangeSelection={(objectDetectionList: string[]) => {
                  setCheckedList({
                    categorization: checkedList?.categorization,
                    objectClass: objectDetectionList,
                  });
                }}
                oldEnginePerClass={projectObjectEngine}
                newEngine={customAutoLabel.name}
              />
            )}
            {categorization.length > 0 && (
              <CategorizationTable
                searchedCategorizations={searchedList.categorization}
                customAutoLabel={customAutoLabel}
                selectedCategorizations={checkedList?.categorization}
                onChangeSelection={(categorization: string[]) => {
                  setCheckedList({ objectClass: checkedList?.objectClass, categorization });
                }}
                oldEnginePerProperty={projectCategorizationEngine}
                newEngine={customAutoLabel.name}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="1px" themedBackgroundColor={['grey', 80]} mb={3} />
      <CircularProgressDialog isLoading={isLoading} />
    </Modal>
  );
};
