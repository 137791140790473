import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Label, Radio, Tooltip } from '@superb-ai/ui';

import { useDiagnosisCommonFilterContext } from '../../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import { DiagnosisSplits } from '../../../../../../services/DiagnosisModelService';
import { isSuperbModel } from '../diagnosisUnion';
import SliceDropdown from '../SliceDropdown';
import { DiagnosisStatusEnum } from '../types';

export default function SplitInFilter({ splits }: { splits?: DiagnosisSplits }): JSX.Element {
  const { t } = useTranslation();
  const { splitIn, setSplitIn, sliceId, setSliceId } = useDiagnosisCommonFilterContext();
  const { selectedDiagnosis } = useDiagnosisModelContext();

  const isExternalModel = !(selectedDiagnosis && isSuperbModel(selectedDiagnosis));

  const isTrainDisabled =
    selectedDiagnosis?.status === DiagnosisStatusEnum.ACTIVE
      ? isExternalModel
      : selectedDiagnosis?.status !== DiagnosisStatusEnum.ALL_ACTIVE;

  const tooltipMessage = isTrainDisabled
    ? t('curate.diagnosis.filterPanel.trainSetNotActivated')
    : isExternalModel
    ? t('curate.diagnosis.filterPanel.trainOptionDisabledForExternalModel')
    : undefined;

  const isTrainSelected = !splitIn.includes('VAL') && splitIn.includes('TRAIN');
  const isValSelected = splitIn.includes('VAL') && !splitIn.includes('TRAIN');
  const isAllSelected = splitIn.includes('VAL') && splitIn.includes('TRAIN');

  return (
    <>
      <Label display="block">
        <Box display="flex" alignItems="center" color={isValSelected ? 'primary' : undefined}>
          <Radio value={isValSelected} onClick={() => setSplitIn(['VAL'])} />
          {t('curate.diagnosis.filterPanel.validation')}
        </Box>
        <Box ml={3} display="flex">
          <SliceDropdown
            size="s"
            target="val"
            splits={splits}
            disabled={!isValSelected}
            sliceId={isValSelected ? sliceId : undefined}
            setSliceId={setSliceId}
          />
        </Box>
      </Label>
      <Tooltip content={tooltipMessage} hideOnEmptyContent>
        <Label display="block">
          <Box
            display="flex"
            alignItems="center"
            color={isTrainDisabled ? 'gray-300' : isTrainSelected ? 'primary' : undefined}
          >
            <Radio
              value={isTrainSelected}
              onClick={() => setSplitIn(['TRAIN'])}
              disabled={isTrainDisabled}
            />
            {t('curate.diagnosis.filterPanel.train')}
          </Box>
          <Box ml={3} display="flex">
            <SliceDropdown
              size="s"
              target="train"
              splits={splits}
              disabled={!isTrainSelected}
              sliceId={isTrainSelected ? sliceId : undefined}
              setSliceId={setSliceId}
            />
          </Box>
        </Label>
      </Tooltip>
      <Tooltip content={tooltipMessage} hideOnEmptyContent>
        <Label display="block">
          <Box
            display="flex"
            alignItems="center"
            color={isTrainDisabled ? 'gray-300' : isAllSelected ? 'primary' : undefined}
          >
            <Radio
              value={isAllSelected}
              onClick={() => setSplitIn(['VAL', 'TRAIN'])}
              disabled={isTrainDisabled}
            />
            {t('curate.diagnosis.filterPanel.trainAndValidation')}
          </Box>
          <Box ml={3} display="flex">
            <SliceDropdown
              size="s"
              target="all"
              splits={splits}
              disabled={!isAllSelected}
              sliceId={isAllSelected ? sliceId : undefined}
              setSliceId={setSliceId}
            />
          </Box>
        </Label>
      </Tooltip>
    </>
  );
}
