import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import MyAnalyticsTab from '../../../../../components/pages/analytics/MyAnalyticsTab';
import { isLabeler } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectMyAnalyticsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectMyAnalytics,
  path: 'my-analytics',
  title: ({ t }) => t('analytics.myAnalytics.title'),
  component: MyAnalyticsTab,
  icon: 'dashboard',
  isVisible({ authInfo }) {
    return isLabeler(authInfo) || authInfo?.isGuest || false;
  },
  isEnabled({ authInfo }) {
    return isLabeler(authInfo) || authInfo?.isGuest || false;
  },
});
