import React from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

import { css } from '@emotion/core';
import { Box, IconButton, TextEllipsis } from '@superb-ai/norwegian-forest';
import { concat, slice } from 'lodash';

import { useUploadInfo } from '../../../contexts/UploadContext';

const DragAndDropCsvList: React.FC = () => {
  const fileInfo = useUploadInfo();

  const handleRemoveUrl = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    fileInfo.setUrls(concat(slice(fileInfo.urls, 0, index), slice(fileInfo.urls, index + 1)));
  };

  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
    const url = fileInfo.urls[index];
    return (
      <div style={style}>
        <Box
          key={url.url}
          display="flex"
          sx={css`
            font-family: Inter;
            font-size: 12px;
          `}
          bb
        >
          <Box width="146px" p={1.5} boxSizing="border-box" br>
            <TextEllipsis text={url.dataKey || ''} />
          </Box>
          <Box width="414px" p={1.5} boxSizing="border-box">
            <TextEllipsis text={url.url || ''} />
          </Box>
          <Box width="20px" display="flex" alignItems="center">
            <IconButton
              size="xxs"
              icon="clear"
              color="grey"
              onClick={e => handleRemoveUrl(e, index)}
            />
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Box height="252px">
      <Box
        display="flex"
        themedBackgroundColor={['grey', 50]}
        sx={css`
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
        `}
        bb
      >
        <Box width="146px" p={1} boxSizing="border-box" br>
          Datakey
        </Box>
        <Box width="434px" p={1} boxSizing="border-box">
          URL
        </Box>
      </Box>
      <List itemCount={fileInfo.urls.length} itemSize={35} width={612} height={220}>
        {Row}
      </List>
    </Box>
  );
};

export default DragAndDropCsvList;
