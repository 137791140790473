import React from 'react';

import { Tooltip } from '@superb-ai/norwegian-forest';

const ButtonWithTooltip = ({
  Comp,
  cond,
  text,
  props,
}: {
  Comp: JSX.Element;
  cond: () => boolean;
  text: string;
  props?: any;
}): any => {
  if (cond && cond()) {
    return <Tooltip anchorEl={<span>{React.cloneElement(Comp, props)}</span>}>{text}</Tooltip>;
  }
  return Comp;
};

export default ButtonWithTooltip;
