import { ComponentProps } from 'react';

import { Clear, InfoCircleOutline, WarningOutline } from '@superb-ai/icons';
import { Icon, IconButton, Paragraph } from '@superb-ai/ui';

const TypeToColorIcon = {
  info: ['secondary', InfoCircleOutline],
  danger: ['primary', WarningOutline],
} as const;

type Props = {
  type?: keyof typeof TypeToColorIcon;
  onClose?: () => void;
  fill?: 'strong' | 'soft';
} & Omit<ComponentProps<typeof Paragraph>, 'type'>;

export function AlertBox({ type, children, onClose, fill = 'soft', ...props }: Props) {
  const [color, icon] = type ? TypeToColorIcon[type] : ['gray' as const, null];
  return (
    <Paragraph
      backgroundColor={`${color}-${fill === 'strong' ? 400 : 100}`}
      color={fill === 'strong' ? 'white' : color}
      borderColor={`${color}-300`}
      variant="m-regular"
      p={1}
      display="flex"
      gap={0.5}
      {...props}
    >
      {icon && <Icon icon={icon} size="16px" style={{ flexShrink: 0 }} />}
      {children}
      {onClose && (
        <IconButton
          style={{ marginLeft: 'auto' }}
          icon={Clear}
          variant="text"
          size="s"
          onClick={onClose}
        />
      )}
    </Paragraph>
  );
}
