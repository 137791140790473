import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, TextEllipsis, Typography } from '@superb-ai/norwegian-forest';
import { NestableOption } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/Dropdown/type';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AssetsService from '../../../services/AssetsService';

export const DATA_VIEW_DATA = '$data';
export const DATA_VIEW_DATASET = '$dataset';

interface Props extends Omit<React.ComponentProps<typeof DropdownList>, 'value' | 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  defaultOptions: NestableOption[];
}

export default function DataViewSelector(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { value, onChange, defaultOptions, ...otherProps } = props;

  const _defaultOptions = useMemo(
    () => [
      {
        label: t('button.view'),
        children: [
          { label: t('data.views.allData'), value: DATA_VIEW_DATA },
          { label: t('data.views.datasets'), value: DATA_VIEW_DATASET },
        ],
      },
      { label: t('data.views.datasets'), children: defaultOptions },
    ],
    [t, defaultOptions],
  );

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  let mounted = true;
  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  async function load(query: string, page: number): Promise<any[]> {
    setPage(page);
    const params = { page, groupIcontains: query };
    try {
      const { results, count } = await AssetsService.getDatasets({
        params,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      if (!mounted) return [];
      setHasMore(count > results.length);
      return results.map(({ group }: { group: string }) => ({ label: group, value: group }));
    } catch (err: any) {
      if (!mounted) return [];
      setHasMore(false);
      return [];
    }
  }

  function loadMore(query: string): Promise<any[]> {
    return load(query, page + 1);
  }

  function getOptions(query: string): Promise<any[]> {
    return load(query, 1);
  }

  function handleChange(dataset: string) {
    onChange(dataset);
  }

  function formatSelected(label: string) {
    return (
      <Box flex={1} overflow="hidden" py={0.5} pr={0.5}>
        <Typography variant="headline6">
          <TextEllipsis text={label} />
        </Typography>
      </Box>
    );
  }

  return (
    <DropdownList
      placeholder={t('button.view')}
      options={_defaultOptions}
      value={value}
      hasMore={hasMore}
      loadMore={loadMore}
      hasSearch
      getOptions={getOptions}
      onChange={handleChange}
      width={175}
      variant="text"
      formatSelected={formatSelected}
      anchorIcon="chevronBigDown"
      maxHeight={300}
      {...otherProps}
    />
  );
}
