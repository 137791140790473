import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Icon, Modal, Typography } from '@superb-ai/norwegian-forest';
interface Props {
  variant?: 'success' | 'error' | 'warning';
  title: string;
  message?: string;
  isDialogOpen: boolean;
  confirmButtonMessage: string;
  cancelButtonMessage: string;
  onClickConfirm(): void;
  onClickCancel(): void;
  hasCancelButton?: boolean;
}

const ReconfirmDialog: React.FC<Props> = props => {
  const {
    children,
    title,
    message,
    isDialogOpen,
    confirmButtonMessage,
    cancelButtonMessage,
    onClickConfirm,
    onClickCancel,
    variant,
    hasCancelButton = true,
  } = props;

  const { t } = useTranslation();

  const getDialogIcon = () => {
    switch (variant) {
      case 'success':
        return <Icon name="checkCircle" color="primary" size="24px" />;
      case 'error':
        return <Icon name="errorOutline" color="primary" size="24px" />;
      case 'warning':
        return <Icon name="warningOutline" color="primary" size="24px" />;
      default:
        return <></>;
    }
  };

  return (
    <Modal
      open={isDialogOpen}
      title={
        <>
          <Typography variant="headline4" themedColor="primary">
            {title}
          </Typography>
          {getDialogIcon()}
        </>
      }
      mainButton={{
        text: confirmButtonMessage || t('button.confirm'),
        onClick: onClickConfirm,
        color: variant === 'warning' ? 'primary' : undefined,
      }}
      subButton={
        hasCancelButton
          ? {
              text: cancelButtonMessage || t('button.cancel'),
              onClick: onClickCancel,
            }
          : undefined
      }
      close={{
        onClose: onClickCancel,
        canCloseWithExit: true,
      }}
    >
      <Box px={4} py={1}>
        {message ? <Typography variant="body3">{message}</Typography> : children}
      </Box>
    </Modal>
  );
};

export default ReconfirmDialog;
