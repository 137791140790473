import React, { useState } from 'react';

import { StateGetterSetter } from './types';

// prettier-ignore
type LabelDetailViewContextProps =
  StateGetterSetter<['isOpen', 'setIsOpen'], boolean> &
  StateGetterSetter<['isLoading', 'setIsLoading'], boolean> &
  StateGetterSetter<['label', 'setLabel'], any | null> &
  StateGetterSetter<['index', 'setIndex'], number | null> &
  StateGetterSetter<['isDisabledAlertActive', 'setIsDisabledAlertActive'], boolean> &
  StateGetterSetter<['isAutoLabelUncertainty', 'setIsAutoLabelUncertainty'], boolean> &
  StateGetterSetter<['autoLabelUncertaintyCount', 'setAutoLabelUncertaintyCount'], number> &
  StateGetterSetter<['isActiveAutoLabelUncertainPanel', 'setIsActiveAutoLabelUncertainPanel'], boolean>;

export const LabelDetailViewContext = React.createContext({} as LabelDetailViewContextProps);

export const LabelDetailViewProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [label, setLabel] = useState<string | null>(null);
  const [index, setIndex] = useState<number | null>(null);
  const [isDisabledAlertActive, setIsDisabledAlertActive] = useState(false);
  const [isAutoLabelUncertainty, setIsAutoLabelUncertainty] = useState(false);
  const [autoLabelUncertaintyCount, setAutoLabelUncertaintyCount] = useState(-1);
  const [isActiveAutoLabelUncertainPanel, setIsActiveAutoLabelUncertainPanel] = useState(false);

  return (
    <LabelDetailViewContext.Provider
      value={{
        isOpen,
        setIsOpen,
        isLoading,
        setIsLoading,
        label,
        setLabel,
        index,
        setIndex,
        isDisabledAlertActive,
        setIsDisabledAlertActive,
        isAutoLabelUncertainty,
        setIsAutoLabelUncertainty,
        autoLabelUncertaintyCount,
        setAutoLabelUncertaintyCount,
        isActiveAutoLabelUncertainPanel,
        setIsActiveAutoLabelUncertainPanel,
      }}
    >
      {children}
    </LabelDetailViewContext.Provider>
  );
};

export const useLabelDetailViewInfo = (): LabelDetailViewContextProps => {
  return React.useContext(LabelDetailViewContext);
};
