import React from 'react';

import * as MUI from '@mui/material';
import { Box } from '@superb-ai/norwegian-forest';

import { formatCount } from '../../../../utils/numberFormat';
import LabelBadgeStatus from '../../../elements/LabelBadgeStatus';

interface Props {
  counts: {
    labelingInProgressCount: number;
    submittedLabelsCount: number;
    skippedLabelsCount: number;
  };
  direction: 'column' | 'row';
}

export default function ExportDialogStatusTable({ counts, direction }: Props): JSX.Element {
  const { labelingInProgressCount, submittedLabelsCount, skippedLabelsCount } = counts;
  const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: direction === 'row' ? 'column' : 'row',
    gap: '8px',
  };

  return (
    <Box
      display="grid"
      width="100%"
      gridTemplateColumns={direction === 'column' ? '1fr' : '1fr 1fr 1fr'}
      flexDirection={direction}
      themedBackgroundColor={['grey', 60]}
      gap="6px"
      p={1}
      borderRadius
    >
      <Box {...itemProps}>
        <LabelBadgeStatus key="working-label-badge" status="WORKING" />
        <MUI.Typography variant="h6" color="primary">
          {formatCount(labelingInProgressCount)}
        </MUI.Typography>
      </Box>
      <Box {...itemProps}>
        <LabelBadgeStatus key="submitted-label-badge" status="SUBMITTED" />
        <MUI.Typography variant="h6" color="primary">
          {formatCount(submittedLabelsCount)}
        </MUI.Typography>
      </Box>
      <Box {...itemProps}>
        <LabelBadgeStatus key="skipped-label-badge" status="SKIPPED" />
        <MUI.Typography variant="h6" color="primary">
          {formatCount(skippedLabelsCount)}
        </MUI.Typography>
      </Box>
    </Box>
  );
}
