import React, { useRef } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import ImageCategoryItemDropBar from './ImageCategoryItemDropBar';
import ImageCategoryItemEditableText from './ImageCategoryItemEditableText';
import ImageCategoryItemSelect from './ImageCategoryItemSelect';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    minHeight: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff6f6',
    },
    '&.selected': {
      background: '#ffeeee',
    },
    transition: '0.3s',
  },
  index: {
    width: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: '#797979',
  },
  colorChip: {
    width: '30px',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreInformationBox: {
    position: 'relative',
    width: '0px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  name: {
    width: '150px',
    marginLeft: '20px',
  },
  typeSelectBox: {
    width: '130px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  useAutoLabelingBox: {
    width: '30px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  emojiBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    borderRadius: '3px',
    background: '#f7f7f7',
  },
  emoji: {
    fontSize: '11px',
  },
}));

const imageCategoryHeight = 40;
const renderingSpareHeight = 100;

// eslint-disable-next-line
const ObjectClassItem = (props: any): React.ReactElement => {
  const classes = useStyles();
  const imageCategoryRef = useRef<HTMLDivElement>();
  const { imageCategory, index, scrollTop, listHeight } = props;
  const newProjectInfo = useNewProjectInfo();
  const isSelected = newProjectInfo.selectedImageCategoryIndex === index;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setTimeout(() => {
      if (newProjectInfo.selectedImageCategoryIndex === index) {
        return;
      }
      newProjectInfo.setSelectedImageCategoryIndex(index);
      newProjectInfo.setSelectedCategories({});
      newProjectInfo.setSelectedCategoryGroupId('');
    }, 0);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (!imageCategoryRef || !imageCategoryRef.current) return;

    newProjectInfo.setDragState({ type: 'imageCategory', index });
    imageCategoryRef.current.style.cursor = 'grabbing';
    if (!e?.dataTransfer) return;
    e.dataTransfer.setData('text', index);
    e.dataTransfer.setDragImage(imageCategoryRef.current, -10, -10);
  };

  const handleDragEnd = () => {
    if (!imageCategoryRef || !imageCategoryRef.current) return;
    newProjectInfo.setDragState(null);
    imageCategoryRef.current.style.cursor = 'pointer';
  };

  return (
    <MUI.Box position="relative">
      {index === 0 ? <ImageCategoryItemDropBar dir="prev" index={index} /> : null}
      <MUI.Box
        {...{ ref: imageCategoryRef }}
        className={`${classes.box} ${isSelected ? 'selected' : ''} `}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable
      >
        <MUI.Box className={classes.name}>
          <ImageCategoryItemEditableText imageCategory={imageCategory} index={index} />
        </MUI.Box>
        <MUI.Box className={classes.typeSelectBox}>
          <ImageCategoryItemSelect imageCategory={imageCategory} index={index} />
        </MUI.Box>
      </MUI.Box>
      <ImageCategoryItemDropBar dir="next" index={index} />
    </MUI.Box>
  );
};

export default ObjectClassItem;
