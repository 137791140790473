import { map, orderBy, reduce, sumBy } from 'lodash';

import { ObjectClass } from '../../../../../utils/LabelInterfaceUtils';
import MathUtils from '../../../../../utils/MathUtils';
import { Property } from '../../../../elements/projectConfiguration/utils';
import { ObjectCountResult } from '../../interfaces/apiResponse';
import { JsonObj } from '../../userStats/types';
import { DonutDatum, ObjectProperty, PropertyCount } from './interface';

const propOptionTemplate = (classId: string, property: Property) => {
  const optionTemplate = (option: { id: string; name: string }) => {
    return {
      classId: classId,
      propertyId: property?.id,
      propertyName: property?.name,
      propertyOptionId: `${property?.id}#${option?.id}`,
      optionId: option?.id,
      optionName: option?.name,
      count: 0,
    };
  };
  return {
    ...property,
    options: property?.options ? property.options.map(option => optionTemplate(option)) : [],
  };
};

export const formatCountablePropertyOptions = (
  classes: ObjectClass[],
): Record<string, ObjectProperty[]> => {
  const result = reduce(
    classes,
    (acc, objClass) => {
      return {
        ...acc,
        [objClass?.id]: objClass?.properties
          .filter(p => p.type !== 'free response')
          .map(prop => propOptionTemplate(objClass?.id, prop)),
      };
    },
    {},
  );
  return result;
};

const addOpenField = (classes: ObjectClass[]): ObjectClass[] => {
  return map(classes, v => {
    return {
      ...v,
      properties: v?.properties.map(prop => {
        return { ...prop, open: true }; // default: open all property
      }),
    };
  });
};

export const getPropertyTree = (classes: ObjectClass[]): Record<string, ObjectProperty[]> => {
  const addedOpen = addOpenField(classes);
  const result = formatCountablePropertyOptions(addedOpen);
  return result;
};

export const getDefaultSelectedClass = (
  objectCount: ObjectCountResult[],
  objectSettings: ObjectClass[],
) => {
  return objectCount ? objectCount[0]?.classId : objectSettings[0]?.id;
};

export const getOptionToCountMap = (data: PropertyCount[]): Record<string, number> => {
  return reduce(
    data,
    (acc, option) => {
      return { ...acc, [option.propertyOptionId]: option?.count };
    },
    {},
  );
};

const updateOptionCountAndPercent = (
  properties: ObjectProperty[],
  data: PropertyCount[],
): ObjectProperty[] => {
  const optionToCountMap = getOptionToCountMap(data);
  const getOptionCount = (propertyOptionId: string): number =>
    optionToCountMap[propertyOptionId] ?? 0;
  const getOptionPercent = (propertyOptionId: string, options: { count: number }[]) =>
    MathUtils.calculatePercent({
      numerator: getOptionCount(propertyOptionId),
      denominator: sumBy(options, 'count'),
      nearest: 'hundredth',
    });
  let result = map(properties, prop => {
    return {
      ...prop,
      options: map(prop?.options, option => {
        return {
          ...option,
          count: getOptionCount(option?.propertyOptionId),
        };
      }),
    };
  }) as ObjectProperty[];

  result = map(result, prop => {
    return {
      ...prop,
      options: map(prop?.options, option => {
        return { ...option, percent: getOptionPercent(option?.propertyOptionId, prop?.options) };
      }),
    };
  });

  return result;
};

interface FnParams {
  propertySetting: JsonObj;
  data: PropertyCount[];
  classId: string;
}

export const combinePropSettingWithData = (params: FnParams): Record<string, ObjectProperty[]> => {
  const { propertySetting, data, classId } = params;
  const selectedClassProps = propertySetting[classId];
  const combined: Record<string, ObjectProperty[]> = {
    ...propertySetting,
    [classId]: updateOptionCountAndPercent(selectedClassProps, data),
  };
  return combined;
};

export const convertPropertyToChartData = (property: ObjectProperty): DonutDatum[] => {
  const datum = (option: PropertyCount) => {
    return {
      id: option?.propertyOptionId,
      name: option?.optionName as string,
      count: option?.count,
      percent: option?.percent,
    };
  };
  const convertedToDatum = property?.options.map(option => datum(option));
  const sorted = orderBy(convertedToDatum, 'count', 'desc');
  return sorted;
};

export const getPropertyById = (properties: ObjectProperty[], propertyId: string) => {
  return properties.find(p => p.id === propertyId);
};

type LegendObject = {
  title?: string;
  hasMarker?: boolean;
  fixedTopMargin?: boolean;
};
type LegendObjectProps = LegendObject & { dataLength: number };

export const getClassLegendObject = (props: LegendObjectProps): LegendObject => {
  const { dataLength, hasMarker = true } = props;

  return {
    title: `Class (${dataLength})`,
    hasMarker,
  };
};

export interface PropertyLegendObject extends LegendObject {
  numProps: number;
  // data: (ObjectProperty & { open: boolean })[];
  data: ObjectProperty[];
}

export const getPropertyLegendObject = (data: ObjectProperty[]): PropertyLegendObject => {
  return {
    title: 'Property',
    hasMarker: false,
    numProps: data.length,
    data,
  };
};
