import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Download, LinkExternal } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import FileUtils from '../../../../utils/FileUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';
import { openWorkapp } from '../../../../utils/WorkappUtils';
import DisabledTooltip from '../../../elements/DisabledTooltip';

const useStyles = makeStyles(theme => ({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
    padding: '0px 8px 8px 8px',
    justifyContent: 'flex-end',
  },
  innerBox: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    background: '#fff',
    borderRadius: '0px 0px 6px 6px',
  },
  footerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    height: '36px',
    borderRadius: '7px',
    background: '#fff',
    cursor: 'pointer',
    fontSize: '11px',
    fontWeight: 500,
    padding: '0px',
    boxShadow: theme.shadows[2],
    '&.download': {
      color: '#ff9772',
    },
    '&.edit': {
      color: '#5A7BFF',
    },
    '&.disabled': {
      color: '#e0e0e0',
    },
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  editIcon: {
    color: '#5A7BFF',
    marginRight: '5px',
    width: '15px',
    height: '15px',
    '&.disabled': {
      color: '#e0e0e0',
    },
  },
}));

const CabinetFooter = (): React.ReactElement => {
  const classes = useStyles();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const { label } = labelDetailViewInfo;
  const { role } = authInfo;
  const isCollaborator = UserRoleUnion.isCollaborator(role);

  const handleClickDownload = (): void => {
    if (isCollaborator) return;
    const getAnnotationResult = () => {
      if (!label.info) return {};
      if (!label.info.result) return {};
      return label.info.result;
    };

    const resultJSON = {
      dataKey: label.asset.key,
      annotationResult: getAnnotationResult(),
    };

    FileUtils.exportToJson(ServiceUtils.toSnakeCaseKeys(resultJSON), `${label.asset.key}.json`);
  };

  const handleClickEditLabel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openWorkapp({
      workapp: projectInfo.project.workapp,
      urlMatchInfo: routeInfo.urlMatchInfo as { accountName: string; projectId: string },
      labelId: label.id,
      appEnv: 'new-tab',
    });
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box className={classes.innerBox}>
        <MUI.Tooltip title={isCollaborator ? 'Admin Only' : ''}>
          <MUI.Box>
            <MUI.Button
              className={`${classes.footerButton} ${isCollaborator ? 'disabled' : 'download'}`}
              onClick={handleClickDownload}
              disabled={isCollaborator}
            >
              <Icon
                icon={Download}
                size={15}
                color={!isCollaborator ? 'primary' : 'gray-300'}
                style={{ marginRight: '2px' }}
              />
              <MUI.Typography>DOWNLOAD</MUI.Typography>
            </MUI.Button>
          </MUI.Box>
        </MUI.Tooltip>
        {!authInfo.isGuest ? (
          <MUI.Button className={`${classes.footerButton} edit`} onClick={handleClickEditLabel}>
            <Box mr={0.5}>
              <Icon icon={LinkExternal} color="blue" />
            </Box>
            <MUI.Typography>EDIT LABEL</MUI.Typography>
          </MUI.Button>
        ) : (
          <DisabledTooltip
            anchor={
              <MUI.Button
                className={`${classes.footerButton}  disabled`}
                onClick={handleClickEditLabel}
                disabled
              >
                <Box mr={0.5}>
                  <Icon icon={LinkExternal} color="gray-300" />
                </Box>
                <MUI.Typography>EDIT LABEL</MUI.Typography>
              </MUI.Button>
            }
            placement="top"
            isLoggedIn={authInfo.isLoggedIn}
          />
        )}
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetFooter;
