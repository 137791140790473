import { useTranslation } from 'react-i18next';

import { Box, NamedColorWeight, Typography } from '@superb-ai/ui';

import { PredictionSetType } from '../types';

interface Props {
  inferenceType: PredictionSetType;
  modelRunName?: string;
}

// TODO: move to colors?
export const predictionColors = {
  groundTruth: ['mint-400', '#4AE2B9'],
  prediction: ['primary-400', '#FF625A'],
  base: ['primary-400', '#FF625A'],
  compare: ['secondary-400', '#FFB800'],
} as Record<PredictionSetType, [NamedColorWeight, string]>;

export default function ModelBadge(props: Props): JSX.Element {
  const { inferenceType, modelRunName } = props;
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" gap={1}>
        <Box
          backgroundColor={predictionColors[inferenceType][0] as any}
          style={{ width: '8px', height: '8px' }}
          borderRadius="100%"
        />
        <Typography variant="m-strong">
          {modelRunName ?? t(`curate.diagnosis.filterPanel.${inferenceType}`)}
        </Typography>
      </Box>
    </Box>
  );
}
