import React from 'react';

import * as MUI from '@mui/material';
import { MinusCircle, Plus } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';
import { head, isEmpty, values } from 'lodash';

import { excludeFilters, FilterKey } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterAnnotationCount from './FilterAnnotationCount';
import FilterAssignee from './FilterAssignee';
import FilterAutoLabelDifficulty from './FilterAutoLabelDifficulty';
import FilterAutoLabelRequest from './FilterAutoLabelRequest';
import FilterClass from './FilterClass';
import FilterConsistencyScore from './FilterConsistencyScore';
import FilterCreatedUpdatedDate from './FilterCreatedUpdatedDate';
import FilterDataKey from './FilterDataKey';
import FilterDataset from './FilterDataset';
import FilterIssue from './FilterIssue';
import FilterLabelId from './FilterLabelId';
import FilterLastLabeledAt from './FilterLastLabeledAt';
import FilterLastLabeledBy from './FilterLastLabeledBy';
import FilterLastReviewedAt from './FilterLastReviewedAt';
import FilterLastReviewedBy from './FilterLastReviewedBy';
import FilterQA from './FilterQA';
import FilterReview from './FilterReview';
import FilterReviewRound from './FilterReviewRound';
import FilterStatus from './FilterStatus';
import FilterTag from './FilterTag';
import { FilterProps, FilterTypeProps } from './types';

const filterComponents: Record<
  Exclude<FilterKey, (typeof excludeFilters)[number]>,
  React.FC<FilterTypeProps<any>>
> = {
  status: FilterStatus,
  annotation: FilterClass,
  labelTag: FilterTag,
  dataset: FilterDataset,
  assignee: FilterAssignee,
  issues: FilterIssue,
  dateAdded: FilterCreatedUpdatedDate,
  lastUpdated: FilterCreatedUpdatedDate,
  consistencyScore: FilterConsistencyScore,
  reviewRound: FilterReviewRound,
  autoLabelRequest: FilterAutoLabelRequest,
  autoLabelDifficulty: FilterAutoLabelDifficulty,
  qa: FilterQA,
  review: FilterReview,
  reviewer: FilterAssignee,
  labelId: FilterLabelId,
  dataKey: FilterDataKey,
  lastLabeledAt: FilterLastLabeledAt,
  lastReviewedAt: FilterLastReviewedAt,
  lastLabeledBy: FilterLastLabeledBy,
  lastReviewedBy: FilterLastReviewedBy,
  annotationCount: FilterAnnotationCount,
} as const;

const canMultipleSelectFilters = ['labelTag', 'annotationCount'];

const Filter = ({
  index,
  filter,
  isCurrentRow,
  onChangeCurrentRow,
  onValueChange,
  onAddFilter,
  onRemoveFilter,
  totalFilters,
  filters,
  selectedFilters,
  filterByList,
}: FilterProps) => {
  if (!filters) return <></>;
  const availableFilters = filters.flatMap(({ key }) => {
    const isAvailable =
      canMultipleSelectFilters.includes(key) ||
      key === filter.filterBy ||
      selectedFilters.findIndex(({ filterBy: target }) => key === target) === -1;
    return isAvailable ? key : [];
  });

  const FilterComponent = filterComponents[
    filter.filterBy as keyof typeof filterComponents
  ] as React.FC<FilterTypeProps<any>>;

  const hasOnlyDefaultFilter = selectedFilters.length === 1 && selectedFilters[0].filterBy === '';
  const hasAnyFilter = selectedFilters.length >= 1 && !hasOnlyDefaultFilter;

  return (
    <MUI.Grid container alignItems="center" spacing={1}>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="filterBy"
          isCurrentRow={isCurrentRow}
          options={filterByList ?? availableFilters}
          value={filter.filterBy}
          onChangeCurrentRow={onChangeCurrentRow}
          onValueChange={onValueChange}
        />
      </MUI.Grid>
      {FilterComponent && (
        <FilterComponent
          index={index}
          filter={filter}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
          onValueChange={onValueChange}
        />
      )}
      {hasAnyFilter && (
        <>
          <MUI.Grid item>
            <IconButton
              variant="text"
              icon={MinusCircle}
              size="m"
              onClick={() => onRemoveFilter(index)}
              disabled={hasOnlyDefaultFilter}
            />
          </MUI.Grid>
          <MUI.Grid item style={{ minWidth: '40px', position: 'relative' }}>
            {!isEmpty(head(values(filter))) && index + 1 === totalFilters && (
              <IconButton
                variant="text"
                icon={Plus}
                size="m"
                onClick={onAddFilter}
                color="primary"
              />
            )}
          </MUI.Grid>
        </>
      )}
    </MUI.Grid>
  );
};

export default Filter;
