import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { Modal } from '@superb-ai/norwegian-forest';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { LABELS_DO_NOT_EXIST, UNKNOWN_ERROR } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';
import ParamUtils from '../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../utils/router-utils';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import EditTagsDialogEditableSelect from './EditTagsDialogEditableSelect';

interface EditTagsDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  checkedLabels: any[];
  setCheckedLabels: any;
  isAllLabelsChecked: boolean;
  getLabels: any;
}
const EditTagsDialog: React.FC<EditTagsDialogProps> = props => {
  const commandContext = useLabelCommandContext();
  const urlSearchParams = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isIncludedTagDeleted, setIsIncludedTagDeleted] = useState(false);
  const [tagsToAdd, setTagsToAdd] = useState<string[]>([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const { enqueueSnackbar } = useSnackbar();
  const { tagIds } = projectInfo;
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;
  const { t } = useTranslation();

  const { isOpen, setIsOpen, checkedLabels, setCheckedLabels, isAllLabelsChecked, getLabels } =
    props;

  const filterApiParams = ParamUtils.getApiParamsForFilter({
    filterParams: urlSearchParams,
    tagIds,
    workApp: projectInfo.project.workapp,
  });
  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };
  const actionParams = !isAllLabelsChecked
    ? { idIn: checkedLabels }
    : { ...filterApiParams, ...searchParams };

  const handleClickClose = () => {
    if (isLoading) return;

    if (isIncludedTagDeleted) {
      getLabels();
    }
    setIsOpen(false);
    setTagsToAdd([]);
    setTagsToRemove([]);
  };

  const handleClickOkay = async () => {
    if (isLoading) return;
    const tagActionInfo = { add: tagsToAdd, remove: tagsToRemove };
    if (isEmpty(tagsToAdd) && isEmpty(tagsToRemove)) {
      if (isIncludedTagDeleted) {
        getLabels();
      }
      setIsOpen(false);
      return;
    }
    setIsLoading(true);
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'LABELS_EDIT_TAGS',
        projectId: routeInfo.urlMatchInfo.projectId,
        params: actionParams,
        actionInfo: tagActionInfo,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
      }
      enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
    }

    setIsLoading(false);
    setIsOpen(false);
    setTagsToAdd([]);
    setTagsToRemove([]);
    getLabels();
    setCheckedLabels([]);
  };

  return (
    <Modal
      open={isOpen}
      title={t('labels.button.editTags')}
      close={{
        onClose: handleClickClose,
        hasCloseButton: true,
      }}
      mainButton={{
        text: t('button.save'),
        onClick: handleClickOkay,
        isLoading,
        disabled: !(tagsToAdd.length > 0 || tagsToRemove.length > 0),
      }}
    >
      <Box width="350px" margin="0 auto">
        {isLoading ? (
          <CircularProgressBox
            boxProps={{
              height: '248px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        ) : (
          <EditTagsDialogEditableSelect
            params={actionParams}
            tagsToAdd={tagsToAdd}
            setTagsToAdd={setTagsToAdd}
            tagsToRemove={tagsToRemove}
            setTagsToRemove={setTagsToRemove}
            setIsIncludedTagDeleted={setIsIncludedTagDeleted}
          />
        )}
      </Box>
    </Modal>
  );
};

export default EditTagsDialog;
