import { RefObject, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { IMAGE_SCOPE, isImageScope, SCOPE, ScopeMode } from '../../../../../types/viewTypes';
import SuperClusterLevelSelector from '../../filter/ClusterCenters/SuperClusterLevelSelector';
import { ScatterTools } from './ScatterTools';
import { BasePointsLegend } from './scatterView/BasePointsLegend';
import { ComparedPointsLegend } from './scatterView/ComparedPointsLegend';
import { useViewMode } from './scatterView/providers/ViewModeProvider';

export const ScatterActionBar = ({
  width,
  height,
  chartAreaRef,
  setChartAreaSize,
}: {
  width: number | string;
  height: number | string;
  chartAreaRef: RefObject<HTMLDivElement>;
  setChartAreaSize: (size: { width: number; height: number }) => void;
}) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const scope = (searchParams.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;

  const { queryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const { hasAppliedFilters: hasObjectAppliedFilters } = useObjectFilterContext();
  const hasFiltersForCompareLegend = !isImageScope(scope) && hasObjectAppliedFilters;
  const currentPageSet = sliceInfo ? 'slice' : 'dataset';

  const scatterViewMode = useViewMode();
  const hasCompareMode = Boolean(
    scatterViewMode === 'points' &&
      (currentPageSet === 'slice' || queryString || hasFiltersForCompareLegend),
  );

  // TODO: remove
  // const hasBasePoints = !isErrorUpdateScatterJob && currentJobProgress?.status === 'COMPLETE';

  useEffect(() => {
    if (!chartAreaRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!chartAreaRef.current) {
        return;
      }
      const { width, height } = chartAreaRef.current.getBoundingClientRect();
      setChartAreaSize({
        width: width,
        height: height,
      });
    });

    observer.observe(chartAreaRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const ScatterActionBar = (
    <Box display="flex" width="100%" alignItems="center" gap={1}>
      {scope === IMAGE_SCOPE ? (
        <Box my={0.5}>
          <SuperClusterLevelSelector />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <ScatterTools />
          </Box>
        </>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
        style={{ marginLeft: 'auto' }}
      >
        <Box gap={1.5} display="flex" flexDirection="row">
          <BasePointsLegend
            hasCompareMode={hasCompareMode}
            hasFilters={hasFiltersForCompareLegend}
          />
          {hasCompareMode && <ComparedPointsLegend />}
        </Box>
      </Box>
    </Box>
  );

  return ScatterActionBar;
};
