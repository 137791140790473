import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

export type ExportFormat = 'default' | 'yolo' | 'coco';
export function getExportFormatOptions(t: TFunction): {
  value: ExportFormat;
  shortLabel: string;
  label: ReactNode;
  disabled?: boolean;
}[] {
  return [
    {
      value: 'default',
      shortLabel: 'Superb AI',
      label: (
        <>
          {t('exportFormatOption.superbAI.title')}
          <br />
          {t('exportFormatOption.superbAI.description')}
        </>
      ),
      disabled: true,
    },
    {
      value: 'coco',
      shortLabel: 'COCO',
      label: (
        <>
          {t('exportFormatOption.coco.title')}
          <br />
          {t('exportFormatOption.coco.description')}
        </>
      ),
    },
    {
      value: 'yolo',
      shortLabel: 'YOLO',
      label: (
        <>
          {t('exportFormatOption.yolo.title')}
          <br />
          {t('exportFormatOption.yolo.description')}
        </>
      ),
    },
  ];
}
