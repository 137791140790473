import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import IssuesService from '../../../../services/IssuesService';
import helper from './helper';

const useStyles = makeStyles(() => ({
  tabs: {
    minHeight: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    background: '#eee',
    position: 'sticky',
    top: '0px',
    padding: '8px 8px 0px 8px',
    zIndex: 5,
  },
  tab: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    fontSize: '14px',
    cursor: 'pointer',
    color: '#797979',
    '&.selected': {
      color: '#ff625a',
      background: '#FFF',
      borderRadius: '6px 6px 0px 0px',
    },
    '&:hover': {
      color: '#ff625a',
    },
  },
}));

const getIsSelected = (tab: string, state: string) => {
  return tab === state ? 'selected' : '';
};

const CabinetTab = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelIssuesInfo = useLabelIssues();
  const { label } = labelDetailViewInfo;
  const { cabinetTabState, setCabinetTabState } = labelInformationInfo;
  const { issues, setIssues, setIssuePanelState, setOpenBadgeInfo } = labelIssuesInfo;
  const {
    history: {
      location: { pathname, search },
    },
  } = routeInfo;

  const handleChangeTab = (nextTab: string) => async (): Promise<void> => {
    if (cabinetTabState === nextTab) return;

    setCabinetTabState(nextTab);

    if (nextTab === 'information') {
      setIssuePanelState(null);
      setOpenBadgeInfo({ issue: null, ref: null });
      const hash = `#label_id=${label.id}`;
      routeInfo.history.push(`${pathname}${search}${hash}`);
    } else if (nextTab === 'issue') {
      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
    }
  };

  if (authInfo.isGuest) {
    return (
      <MUI.Box className={classes.tabs}>
        <MUI.Box
          className={`${classes.tab} ${getIsSelected('information', cabinetTabState)}`}
          onClick={handleChangeTab('information')}
        >
          Information
        </MUI.Box>
        <MUI.Box
          className={`${classes.tab} ${getIsSelected('compare', cabinetTabState)}`}
          onClick={handleChangeTab('compare')}
        >
          Compare
        </MUI.Box>
      </MUI.Box>
    );
  }

  return (
    <MUI.Box className={classes.tabs}>
      <MUI.Box
        className={`${classes.tab} ${getIsSelected('information', cabinetTabState)}`}
        onClick={handleChangeTab('information')}
      >
        Information
      </MUI.Box>
      <MUI.Box
        className={`${classes.tab} ${getIsSelected('issue', cabinetTabState)}`}
        onClick={handleChangeTab('issue')}
      >
        {`Issue (${helper.getTotalOfOpenIssue(issues)})`}
      </MUI.Box>
      <MUI.Box
        className={`${classes.tab} ${getIsSelected('compare', cabinetTabState)}`}
        onClick={handleChangeTab('compare')}
      >
        Compare
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetTab;
