export type ProjectionInParam = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};
export const CLUSTER_LEVEL_2_SIZE_8 = '2'; // 2^(1+1)= 2^2 = 4 clusters
export const CLUSTER_LEVEL_3_SIZE_16 = '3'; // 2^(1+1)= 2^2 = 4 clusters

export type ClusterFilterSchema = {
  cluster_level_in?: ClusterLevel[];
  cluster_id_in?: (string | null)[];
};

export type ImageClusterFilterSchema = {
  cluster_id_in: (string | null)[];
  cluster_level: ClusterLevel;
};

export type ClassClusterParam = {
  annotation_class: string;
  cluster_id_in: (string | null)[];
  cluster_level: ClusterLevel;
};

export type ClassCluster = {
  id: string;
  annotation_count: number;
};

type AnnotationClassClustersIn = {
  cluster_level?: ClusterLevel;
  annotation_class_cluster_in?: ClassClusterParam[];
};
export type FilterSchema = {
  annotation_class_in?: string[];
  annotation_type_in?: string[];
  metadata_all_values_in?: string[];
  metadata_in?: Record<string, string[]>;
};

export type MetadataKeys = { metadata_keys?: string[] };
export type ProjectionFilterSchema = {
  projection_in?: ProjectionInParam | undefined;
};

type MetadataCardinality = { metadata_cardinality: Record<string, number> };
type AnnotationClassClusterLevelIn = {
  annotation_class_cluster_level_in: Record<string, ClusterLevel[]>;
};
export type FilterSchemaWithExpands = FilterSchema &
  MetadataCardinality &
  AnnotationClassClusterLevelIn;

export interface ImageFilterSchema extends ClusterFilterSchema, ProjectionFilterSchema {
  cluster_level?: ClusterLevel;
  leaf_cluster_score?: number;
}

export type SuperClusters = Record<string, { id: string }>;

export type AnnotationFilterSchema = FilterSchema &
  MetadataKeys &
  AnnotationClassClustersIn &
  ProjectionFilterSchema;

export type FilterSchemaWithMetadataCardinality = FilterSchema & MetadataCardinality;

export type ClusterLevel = '0' | '1' | '2' | '3' | 'leaf';
export type ClusterLevelValues = '2' | '4' | '8' | '16' | 'leaf';
export const CLUSTER_LEVELS: Record<ClusterLevel, ClusterLevelValues> = {
  '0': '2',
  '1': '4',
  '2': '8',
  '3': '16',
  leaf: 'leaf',
};

type ClusterExpandParams = 'cluster_level';
export type ImageFilterExpandParams = ClusterExpandParams[];

export type ImageClusterParam = string[] | null;
