import { FilterKey, LabelFilter } from '../../../../utils/filter/labelFilter';

export interface Option {
  value: string;
  label: string;
  children?: Option[];
  parents?: Option[];
}

export interface FilterInfo {
  filterBy: FilterKey | '';
  condition: string;
  // options?: FilterOption<PossibleOptionValue>[];
  options?: any[];
}

export interface FilterTypeProps<Value = any> {
  index: number;
  filter: FilterInfo;
  isCurrentRow: boolean;
  onChangeCurrentRow: (index: number) => void;
  onValueChange: (index: number, objKey: string, value: Value) => void;
}

export interface FilterProps<Value = any> extends FilterTypeProps<Value> {
  onAddFilter: () => void;
  onRemoveFilter: (index: number) => void;
  totalFilters: number;
  filters?: readonly LabelFilter[];
  selectedFilters: readonly FilterInfo[];
  filterByList?: readonly FilterKey[];
}

export const MAX_OPTIONS_SIZE = 30;
