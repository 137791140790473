import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Button, Icon, Typography } from '@superb-ai/norwegian-forest';
import axios, { CancelTokenSource } from 'axios';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import ExportService from '../../../../services/ExportService';
import MislabelDetectionService from '../../../../services/MislabelDetectionService';
import HistoryItemContainer from '../HistoryItemContainer';
import MislabelDetectionInfo from './MislabelDetectionInfo';
import MislabelDetectionModal from './settingModal/MislabelDetectionModal';
import Tutorial from './Tutorial';

export default function History() {
  const params = useParams<{ accountName: string; projectId: string }>();
  const authInfo = useAuthInfo();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyIds, setHistoryIds] = useState<{ id: string }[]>([]);
  const [hasExport, setHasExport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource>();

  const MISLABEL_DETECTION = t('advancedAIFeatures.mislabelDetection.button.new');

  const loadMislabelDetectionHistoriesRes = async () => {
    try {
      const mislabelDetectionHistoriesRes =
        await MislabelDetectionService.getMislabelDetectionHistories({
          projectId: params.projectId,
          params: {},
          isGuest: authInfo.isGuest,
          urlInfo: { accountName: params.accountName, projectId: params.projectId },
        });
      setHistoryIds(mislabelDetectionHistoriesRes.mislabelDetections.reverse());
    } catch (err: any) {}
  };

  const deleteMislabelDetectionHistory = async (id: string) => {
    try {
      await MislabelDetectionService.deleteMislabelDetection({
        projectId: params.projectId,
        mislabelDetectionId: id,
        isGuest: authInfo.isGuest,
        urlInfo: { accountName: params.accountName, projectId: params.projectId },
      });
      await loadMislabelDetectionHistoriesRes();
    } catch (err: any) {}
  };

  const loadMislabelDetectionHistory = async (id: string) => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const results = await MislabelDetectionService.getMislabelDetectionHistory({
      cancelToken: source.token,
      projectId: params.projectId,
      mislabelDetectionId: id,
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: params.accountName, projectId: params.projectId },
    });

    setCancelSource(source);

    return results;
  };

  useEffect(() => {
    (async () => {
      const exportHistoriesRes = await ExportService.getExportHistories({
        projectId: params.projectId,
        params: {},
        isGuest: authInfo.isGuest,
        urlInfo: { accountName: params.accountName, projectId: params.projectId },
      });
      if (exportHistoriesRes.count > 0) {
        setHasExport(true);
        await loadMislabelDetectionHistoriesRes();
      }
      setIsLoading(false);
    })();
  }, []);

  const newMislabelDetectionButton = () => {
    return (
      <Button
        IconAdornment="plus"
        size="l"
        onClick={() => {
          setIsModalOpen(true);
        }}
        disabled={!hasExport}
      >
        {MISLABEL_DETECTION}
      </Button>
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" height="400px" alignItems="center" justifyContent="center">
        <Icon name="loadingSpinner" size="40px" color="primary" />
      </Box>
    );
  }

  return (
    <>
      {historyIds.length > 0 ? (
        <>
          <Box display="flex" alignItems="center">
            <Typography variant="headline5" style={{ lineHeight: 1 }}>
              {t('advancedAIFeatures.mislabelDetection.history.title')}
            </Typography>
            <Box ml="auto">{newMislabelDetectionButton()}</Box>
          </Box>
          <Box mt={2}>
            {historyIds.map(id => (
              <HistoryItemContainer
                key={id.id}
                id={id.id}
                loadHistory={loadMislabelDetectionHistory}
                cancelSource={cancelSource}
                deleteHistory={deleteMislabelDetectionHistory}
                InfoBoard={MislabelDetectionInfo}
                infoBoardContainerBoxProps={{ ml: 7, px: 3, py: 2 }}
                type="mislabel-detection"
              />
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="headline5" style={{ lineHeight: 1 }}>
              {t('advancedAIFeatures.mislabelDetection.history.title')}
            </Typography>
            <Box ml="auto">{newMislabelDetectionButton()}</Box>
          </Box>
          <Tutorial hasExports={hasExport} openSettingsModal={() => setIsModalOpen(true)} />
        </>
      )}
      <MislabelDetectionModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        loadList={loadMislabelDetectionHistoriesRes}
      />
    </>
  );
}
