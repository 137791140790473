import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';

export type MetadataPropertyType = 'free-response' | 'multiple-choice';

export type MetadataProperty = {
  name: string;
  isRequired: boolean;
  isNew: boolean;
  type: MetadataPropertyType;
  isOnlyNumber?: boolean;
  options?: Record<string, { name: string; isDefault: boolean }>;
};

export type AnnotationClass = {
  class: string;
  color: string;
  isNew: boolean;
  properties?: Record<string, MetadataProperty>;
};

export type ClassAndProperties = Record<CurateAnnotationType, Record<string, AnnotationClass>>;

export const APPROVE_LABEL = 'APPROVE_LABEL';
export const MANUAL = 'MANUAL';
export type AutoSyncType = (typeof APPROVE_LABEL | typeof MANUAL)[number];
