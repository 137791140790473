import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../contexts/AppContext';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useNewProjectInfo } from '../../../contexts/NewProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectService from '../../../services/ProjectService';
import WorkappUnion from '../../../union/WorkappUnion';
import ServiceUtils from '../../../utils/ServiceUtils';
import { overflowOverlayOrAuto } from '../../../utils/style';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import helper from './helper';
import { EDIT_PROJECT_PATH } from './paths';
import Section from './Section';
import shyCreditHelper from './shyCreditHelper';
import Sidebar from './Sidebar';

const useStyles = makeStyles(() => ({
  box: {
    position: 'fixed',
    overflow: overflowOverlayOrAuto(),
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1299,
    backgroundColor: '#fff',
    '@media (min-height:0px)': {
      alignItems: 'unset',
    },
    '@media (min-height:800px)': {
      alignItems: 'center',
    },
    '@media (min-width:0px)': {
      justifyContent: 'unset',
    },
    '@media (min-width:1300px)': {
      justifyContent: 'center',
    },
  },
  innerBox: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    minWidth: '1300px',
    maxWidth: '1500px',
    height: '800px',
    maxHeight: '800px',
    overflow: overflowOverlayOrAuto(),
    padding: '50px 70px',
    gridTemplateColumns: '150px 1fr minmax(0, 150px)',
    gridTemplateRows: '100%',
  },
}));

// eslint-disable-next-line
const Layout = (props: any): React.ReactElement | null => {
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState<'loading' | 'goToAutoLabel' | 'done'>('loading');
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const newProjectInfo = useNewProjectInfo();
  const { setProjectId } = newProjectInfo;
  const {
    urlMatchInfo: { accountName, projectId },
  } = routeInfo;
  const location = useLocation();
  const { pathname, search } = location;

  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = {
    mode: urlSearchParams.get('mode'),
  };

  // page title is not displayed but used to trigger the page event
  useSetPageTitle(
    'New Project',
    isEditPage ? PAGE_TRACKING_ID.labelEditProject : PAGE_TRACKING_ID.labelCreateProject,
  );
  useEffect(() => {
    if (projectId && isEditPage) {
      (async () => {
        const resultOfGetProject = await ProjectService.getProject({
          projectId,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });

        if (WorkappUnion.isImageDefault(resultOfGetProject.workapp)) {
          helper.convertLabelInterfaceToNewProjectInfo({
            projectInfo: ServiceUtils.toSnakeCaseKeys(resultOfGetProject),
            newProjectInfo,
          });
        } else if (WorkappUnion.isSiesta(resultOfGetProject.workapp)) {
          shyCreditHelper.setNewProjectInfo({
            project: resultOfGetProject,
            newProjectInfo,
          });
        }

        setProjectId(projectId);
        if (!params.mode) {
          routeInfo.history.replace(
            `/${routeInfo.urlMatchInfo.accountName}/label/project/${projectId}/edit_project/create_project${search}`,
          );
          setLoadingState('done');
        }
      })();
    } else {
      routeInfo.history.replace(`/${accountName}/label/new_project/create_project${search}`);
      setLoadingState('done');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MUI.Box className={classes.box}>
        <MUI.Box className={classes.innerBox}>
          <Sidebar />
          <Section {...props} />
        </MUI.Box>
      </MUI.Box>
      <CircularProgressDialog isLoading={loadingState !== 'done'} />
    </>
  );
};

export default Layout;
