import { z } from 'zod';

import { useSearchParamsObj } from '../../../utils/router-utils';

type TabType = 'overview' | 'progress' | 'performance' | 'endpoints';
export type DisplayPanelType = 'my-model' | 'model-hub';

export const RecognitionQueryKeyword = {
  DetailTab: 'detail-tab',
  ListTab: 'list-tab',
};

export const listQuery = {
  modelHub: 'model-hub',
  myModel: 'my-model',
};

const stringOrNull = z.string().nullable().default(null);

export function useModelUrlParams() {
  const searchParams = useSearchParamsObj();

  const detailTab = stringOrNull.parse(
    searchParams?.get(RecognitionQueryKeyword.DetailTab),
  ) as TabType | null;
  const listTab = stringOrNull.parse(
    searchParams?.get(RecognitionQueryKeyword.ListTab),
  ) as DisplayPanelType | null;

  return {
    detailTab,
    listTab,
  };
}
