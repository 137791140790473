import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, concat, last, map } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import NotificationsContext from '../../../../contexts/NotificationsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import NotificationsService from '../../../../services/NotificationsService';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import Notification from './Notification';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '35px',
  },
  markAllText: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#b3b3b3',
    marginRight: '6px',
    cursor: 'pointer',
    padding: '1px 4px',
    '&:hover': {
      background: '#d1d1d1',
      color: '#635c5c',
      borderRadius: '6px',
    },
  },
  gearIcon: {
    color: 'grey',
    width: '16px',
    height: '16px',
    marginRight: '11px',
    cursor: 'pointer',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '620px',
    overflow: overflowOverlayOrAuto(),
  },
});

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const notificationsInfo = React.useContext(NotificationsContext.Context);
  const { isOpen, notifications, setNotifications, unreadCount, setUnreadCount } =
    notificationsInfo;
  const [hasMorePage, setHasMorePage] = useState(true);

  const handleClickMarkAll = async () => {
    if (unreadCount <= 0) return;
    await NotificationsService.markAllAsRead({
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const nextNotifications = cloneDeep(notifications);
    for (let i = 0; i < nextNotifications.length; i++) {
      const noti = nextNotifications[i];
      if (!noti.checked) noti.checked = true;
    }

    setNotifications(nextNotifications);
    setUnreadCount(0);
  };

  const handleLoadMore = async () => {
    if (!isOpen) return;

    if (notifications.length === 0) {
      setHasMorePage(false);
      return;
    }
    const lastCreatedAt = last(notifications).createdAt;
    const res = await NotificationsService.getNotifications({
      lastCreatedAt,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const { noti } = res;
    if (noti.length !== 0) {
      setNotifications(concat(notifications, noti));
    } else {
      setHasMorePage(false);
    }
  };

  return (
    <>
      <MUI.Box className={classes.header}>
        <MUI.Typography className={classes.markAllText} onClick={handleClickMarkAll}>
          {t('notifications.button.markAllAsRead')}
        </MUI.Typography>
      </MUI.Box>
      <MUI.Box className={classes.body}>
        <InfiniteScroll
          useWindow={false}
          pageStart={1}
          hasMore={hasMorePage}
          loadMore={handleLoadMore}
          threshold={10}
          style={{ width: '100%' }}
          loader={
            <CircularProgressBox key={0} boxProps={{ mt: 2, mb: 2 }} circularProps={{ size: 20 }} />
          }
        >
          {notifications.length === 0 ? (
            <Notification notification={{ type: 'welcome', payload: {} }} />
          ) : (
            map(notifications, notification => (
              <Notification key={notification.id} notification={notification} />
            ))
          )}
        </InfiniteScroll>
      </MUI.Box>
    </>
  );
};

export default Notifications;
