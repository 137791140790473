import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  Alarm,
  ChevronBigRight,
  ClearOutline,
  Copy,
  PlayCircleOutline,
  Trash,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  extendComponent,
  Icon,
  IconButton,
  LinkTypography,
  LoadingIndicator,
  SegmentedControl,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { COPY_SUCCESS, MODEL_ENDPOINT_DELETED } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { AppRouteLayoutProps } from '../../../../menu';
import { getUrl } from '../../../../routes/util';
import { formatDateTime } from '../../../../utils/date';
import FileUtils from '../../../../utils/FileUtils';
import {
  ActivateTooltip,
  BorderBox,
  PurposeChip,
  textEllipsisStyle,
} from '../../components/components';
import {
  useDeleteEndpointMutation,
  useModelDetailQuery,
  useModelEndpointDetailQuery,
  usePauseEndpointMutation,
  usePublicModelQuery,
} from '../../queries/modelQueries';
import { RecognitionAIDetailMenuItem } from '../../recognition-ai/detail/MenuItem';
import { Endpoint, EndpointStatus, PurposeType } from '../../services/types';
import { EndpointsSettingsDialog } from '../EndpointsSettingsDialog';
import { ResumeEndpointDialog } from '../ResumeEndpointDialog';
import { SchedulePauseDialog } from '../SchedulePauseDialog';
import { GenerationPredictionTestProvider } from './contexts/GenerationPredictionTestContext';
import { RecognitionPredictionTestProvider } from './contexts/RecognitionPredictionTestContext';
import { GenerationPredictionTest } from './GenerationPredictionTest';
import { RecognitionPredictionTest } from './RecognitionPredictionTest';
import { getSnippet } from './snippet';

export function Layout({ match }: AppRouteLayoutProps) {
  const id = match.params.id;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteEndpoint } = useDeleteEndpointMutation();
  const { mutate: pauseEndpoint } = usePauseEndpointMutation();
  const endpointDetailQuery = useModelEndpointDetailQuery({ id: id! });
  const { data: endpointDetailData } = endpointDetailQuery;
  const endpointDetail = endpointDetailData;
  const router = useRouter();
  const schedulePauseDialogState = useDialogState();
  const settingDialogState = useDialogState();
  const resumeEndpointDialogState = useDialogState();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [displayPanel, setDisplayPanel] = useState<'visualize' | 'snippet'>('visualize');

  const { data: model, isLoading: isModelLoading } = useModelDetailQuery({
    id: endpointDetail ? endpointDetail.model.id : '',
    modelPurpose: 'generation',
  });
  const handleClickCopy = (value: string) => {
    FileUtils.copyToClipboard({
      value,
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label: value }), {
      variant: 'success',
    });
  };

  const getStatusChipColor = (status: EndpointStatus) => {
    switch (status) {
      case 'starting':
        return 'yellow';
      case 'running':
        return 'green';
      case 'pausing':
        return 'orange';
      case 'paused':
        return 'cloud';
      case 'failed':
        return 'red';
      default:
        return;
    }
  };

  if (!endpointDetail) {
    return <LoadingIndicator />;
  }

  const handleClickResume = () => {
    resumeEndpointDialogState.show();
  };

  const renderStatusButton = (status: EndpointStatus) => {
    if (status === 'running') {
      return (
        <>
          <Button onClick={() => pauseEndpoint(id!)}>
            <Icon icon={ClearOutline} color={'primary'} />
            {t('model.endpoints.pause')}
          </Button>
        </>
      );
    } else if (status === 'starting') {
      return (
        <>
          <Button disabled>
            <Icon icon={PlayCircleOutline} color={'primary'} />
            {t('shared.resume')}
          </Button>
        </>
      );
    } else if (status === 'paused') {
      return (
        <>
          <Button onClick={handleClickResume}>
            <Icon icon={PlayCircleOutline} color={'primary'} />
            {t('shared.resume')}
          </Button>
        </>
      );
    } else {
      return (
        <>
          {/* <Button onClick={handleClickResume}>
            <Icon icon={Refresh} color={'primary'} />
            {t('shared.retry')}
          </Button> */}
        </>
      );
    }
  };

  const statusUpdatedAt = (status: EndpointStatus, updatedAt: number) => {
    switch (status) {
      case 'starting':
      case 'running':
        return (
          <Trans
            t={t}
            i18nKey={'model.endpoints.startedAt'}
            values={{
              time: updatedAt ? format(new Date(updatedAt), 'M/d/yy h:mm a') : '-',
            }}
          />
        );
      case 'paused':
      case 'pausing':
        return (
          <Trans
            t={t}
            i18nKey={'model.endpoints.pausedAt'}
            values={{
              time: updatedAt ? format(new Date(updatedAt), 'M/d/yy h:mm a') : '-',
            }}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Box style={{ minWidth: 1008 }}>
      <Row mb={1}>
        <Typography
          variant="h2"
          mr={1}
          onClick={() => handleClickCopy(endpointDetail.endpointSetting.name)}
          cursor="pointer"
        >
          {endpointDetail.endpointSetting.name}
        </Typography>

        {
          <ActivateTooltip
            content={statusUpdatedAt(endpointDetail.status, endpointDetail.updatedAt)}
            placement="top-start"
            activate={endpointDetail.status !== 'failed'}
          >
            <Chip color={getStatusChipColor(endpointDetail.status)}>
              {capitalize(endpointDetail.status)}
            </Chip>
          </ActivateTooltip>
        }
        <Row ml={'auto'} gap={0.5}>
          {endpointDetail.status === 'running' && (
            <Tooltip
              placement="top"
              content={
                endpointDetail.endpointScheduling.length > 0 ? (
                  <Trans
                    t={t}
                    i18nKey={'model.endpoints.schedulePauseAt'}
                    values={{
                      time: format(
                        new Date(endpointDetail.endpointScheduling[0].eventAt),
                        'M/d/yy h:mm a',
                      ),
                    }}
                  />
                ) : undefined
              }
              hideOnEmptyContent
            >
              <Row>
                <Button onClick={schedulePauseDialogState.show}>
                  <Icon icon={Alarm} color={'primary'} />
                  {t('model.endpoints.schedulePause')}
                </Button>
              </Row>
            </Tooltip>
          )}
          {renderStatusButton(endpointDetail.status)}
          <Button onClick={settingDialogState.show}>{t('settings.title')}</Button>
          <IconButton
            icon={Trash}
            color="primary"
            disabled={endpointDetail.status !== 'paused'}
            onClick={() => {
              deleteEndpoint(id!);
              router.back();
              enqueueSnackbar(MODEL_ENDPOINT_DELETED({ t }), {
                variant: 'success',
              });
            }}
          />
        </Row>
      </Row>
      <Box mb={2.5}>
        <Row mb={1}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.endpoints.url')}
          </Typography>
          <Box backgroundColor={'gray-200'} mx={1} style={{ height: 6, width: 1 }} />
          {endpointDetail.url ? (
            <>
              <Typography
                variant="m-regular"
                color="black"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                onClick={() => {
                  handleClickCopy(endpointDetail.url);
                }}
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
                cursor={'pointer'}
              >
                {endpointDetail.url}
              </Typography>
              {isHovered && <Icon icon={Copy} size={12} style={{ marginLeft: 2, minWidth: 12 }} />}
            </>
          ) : (
            <Typography variant="m-regular" color="black">
              -
            </Typography>
          )}
          <Row ml="auto" justifyContent="flex-end" style={{ width: 400 }}>
            <Typography variant="m-regular" color={'gray-300'} style={{ ...textEllipsisStyle }}>
              <Trans t={t} i18nKey={'model.endpoints.createdBy'}>
                Created by {{ createdBy: endpointDetail.createdBy }} (
                {{ createdAt: formatDateTime(new Date(endpointDetail.createdAt)) }})
              </Trans>
            </Typography>
          </Row>
        </Row>
        {endpointDetail.status === 'failed' && (
          <Row backgroundColor={'primary-100'} style={{ height: 34 }}>
            <Typography color={'red-400'} pl={1.5} variant="m-regular">
              {t('model.endpoints.unexpectedError')}
            </Typography>
          </Row>
        )}
      </Box>
      <Row bb="1px solid" borderColor={'gray-150'} style={{ height: 32 }}>
        <Tab
          bb={displayPanel === 'visualize' ? '2px solid' : undefined}
          onClick={() => setDisplayPanel('visualize')}
        >
          <Typography
            variant={displayPanel === 'visualize' ? 'm-strong' : 'm-regular'}
            color={displayPanel === 'visualize' ? 'primary' : undefined}
          >
            {t('model.endpoints.visualize')}
          </Typography>
        </Tab>
        <Tab
          bb={displayPanel === 'snippet' ? '2px solid' : undefined}
          onClick={() => setDisplayPanel('snippet')}
        >
          <Typography
            variant={displayPanel === 'snippet' ? 'm-strong' : 'm-regular'}
            color={displayPanel === 'snippet' ? 'primary' : undefined}
          >
            {t('model.endpoints.collapseTitle.sdkSnippet')}
          </Typography>
        </Tab>
      </Row>
      <RecognitionPredictionTestProvider>
        <GenerationPredictionTestProvider model={model} isModelLoading={isModelLoading}>
          <Box pt={1.5}>
            {displayPanel === 'visualize' && <Visualize endpointDetail={endpointDetail} />}
            {displayPanel === 'snippet' && (
              <SdkSnippet
                deployUrl={endpointDetail.url}
                purpose={endpointDetail.baselineModel.purpose}
              />
            )}
          </Box>
        </GenerationPredictionTestProvider>
      </RecognitionPredictionTestProvider>
      {schedulePauseDialogState.visible && (
        <SchedulePauseDialog id={endpointDetail.id} state={schedulePauseDialogState} />
      )}
      {settingDialogState.visible && model && (
        <EndpointsSettingsDialog
          state={settingDialogState}
          endpointId={id!}
          endpointName={endpointDetail.endpointSetting.name}
          model={model}
          modelName={endpointDetail.model.name}
          status={endpointDetail.status}
        />
      )}
      {resumeEndpointDialogState.visible && (
        <ResumeEndpointDialog
          state={resumeEndpointDialogState}
          from="resume"
          endpointId={endpointDetail.id}
        />
      )}
    </Box>
  );
}

const Tab = extendComponent(Row, {
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: { hover: 'gray-100' },
  borderColor: 'primary',
  height: '100%',
  px: 1.5,
});

const SdkSnippet = ({ deployUrl, purpose }: { deployUrl: string; purpose: PurposeType }) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { accountName } = authInfo;
  const [codeLanguage, setCodeLanguage] = useState<'python' | 'curl' | 'js'>('python');

  const recAiOptions = [
    {
      label: t('model.endpoints.python'),
      value: 'python' as typeof codeLanguage,
    },
    {
      label: t('model.endpoints.cURL'),
      value: 'curl' as typeof codeLanguage,
    },
    {
      label: t('model.endpoints.javascript'),
      value: 'js' as typeof codeLanguage,
    },
  ];

  const genAiOptions = [
    {
      label: t('model.endpoints.python'),
      value: 'python' as typeof codeLanguage,
    },
  ];

  return (
    <>
      <SegmentedControl
        options={purpose === 'recognition' ? recAiOptions : genAiOptions}
        value={codeLanguage}
        onChangeValue={v => setCodeLanguage(v)}
        buttonProps={{ square: true, size: 'm', style: { width: 93 } }}
      />
      <Box mt={1} borderRadius="2px" border="1px solid" borderColor={'gray-150'}>
        {codeLanguage === 'python' && (
          <Code>{getSnippet(deployUrl, 'python', accountName ?? 'TEAM_NAME', purpose)}</Code>
        )}
        {codeLanguage === 'curl' && (
          <Code>{getSnippet(deployUrl, 'curl', accountName ?? 'TEAM_NAME', purpose)}</Code>
        )}
        {codeLanguage === 'js' && (
          <Code>{getSnippet(deployUrl, 'js', accountName ?? 'TEAM_NAME', purpose)}</Code>
        )}
      </Box>
    </>
  );
};

function Code({ children }: { children: ReactNode }) {
  return (
    <Box p={2.5} overflow="auto" style={{ height: 360 }}>
      <code style={{ whiteSpace: 'pre-wrap' }}>{children}</code>
    </Box>
  );
}

const Visualize = ({ endpointDetail }: { endpointDetail: Endpoint }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const { data: publicModelData, isLoading } = usePublicModelQuery({
    id: endpointDetail.baselineModel.id,
  });
  const [isHovered1, setIsHovered1] = useState<boolean>(false);
  const [isHovered2, setIsHovered2] = useState<boolean>(false);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <BorderBox p={2.5}>
        <Row mb={1.5}>
          <Typography variant="l-strong">{t('model.endpoints.modelInformation')}</Typography>
        </Row>
        <Box display="grid" style={{ gridTemplateColumns: '1fr 1fr', columnGap: 12 }}>
          <Row gap={2} style={{ ...textEllipsisStyle }}>
            <Typography variant="m-regular" color={'gray-300'} style={{ minWidth: 'max-content' }}>
              {t('model.endpoints.deployedModel')}
            </Typography>
            <Row gap={0.5} style={{ ...textEllipsisStyle }}>
              <PurposeChip
                purpose={publicModelData?.data.purpose}
                color={publicModelData?.data.purpose === 'generation' ? 'secondary' : 'primary'}
              />
              <LinkTypography
                variant="m-regular"
                onClick={() => {
                  history.push(
                    getUrl(
                      [
                        `/${accountName}/model/${endpointDetail.baselineModel.purpose}`,
                        RecognitionAIDetailMenuItem.path,
                      ],
                      {
                        id: endpointDetail.model.id,
                      },
                    ),
                  );
                }}
                style={{ ...textEllipsisStyle }}
                color="black"
                underline={isHovered1}
                onMouseEnter={() => {
                  setIsHovered1(true);
                }}
                onMouseLeave={() => {
                  setIsHovered1(false);
                }}
              >
                {endpointDetail.model.name}
              </LinkTypography>
              <Icon icon={ChevronBigRight} />
            </Row>
          </Row>
          <Row gap={2} style={{ ...textEllipsisStyle }}>
            <Typography variant="m-regular" color={'gray-300'} style={{ minWidth: 'max-content' }}>
              {t('model.endpoints.trainedBy')}
            </Typography>
            <Row gap={0.5} style={{ ...textEllipsisStyle }}>
              <Chip fill="soft">{publicModelData?.data.source}</Chip>
              <Link
                href={publicModelData?.data.sourceUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ ...textEllipsisStyle }}
              >
                <Typography
                  variant="m-regular"
                  color="gray-400"
                  underline={isHovered2}
                  onMouseEnter={() => {
                    setIsHovered2(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered2(false);
                  }}
                >
                  {endpointDetail.baselineModel.name}
                </Typography>
              </Link>
              <Icon icon={ChevronBigRight} />
            </Row>
          </Row>
        </Box>
      </BorderBox>
      {endpointDetail.baselineModel.modelArch === 'reco' ? (
        <GenerationPredictionTest endpointDetail={endpointDetail} />
      ) : (
        <RecognitionPredictionTest
          endpointId={endpointDetail.id}
          endpointStatus={endpointDetail.status}
        />
      )}
    </Box>
  );
};
