import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import SelectCloudPrefix from '../steps/SelectCloudPrefix';
import SelectCloudStorage from '../steps/SelectCloudStorage';
import SelectStorageMethod from '../steps/SelectStorageMethod';
import VerifyCloudStorage from '../steps/VerifyCloudStorage';

const Cloud = (t: TFunction) => {
  const {
    dataType,
    uploadMethod,
    cloudStorageType,
    setCloudStorageType,
    cloudIntegrationId,
    setCloudIntegrationId,
    cloudBucketName,
    setCloudBucketName,
    integratedCloudPrefix,
    setIntegratedCloudPrefix,
    cloudPrefix,
    setCloudPrefix,
  } = useUploadInfo();

  const CLOUD_STORAGE = 'cloud_storage';
  const FOLDER = 'folder';
  const STORAGE_METHOD = 'storage_method';

  return {
    [CLOUD_STORAGE]: SelectCloudStorage({
      t,
      isEnabled: uploadMethod === 'CLOUD',
      cloudInfos: {
        cloudStorageType,
        setCloudStorageType,
        cloudIntegrationId,
        setCloudIntegrationId,
        cloudBucketName,
        setCloudBucketName,
        setIntegratedCloudPrefix,
      },
    }),
    [FOLDER]:
      dataType === 'POINT_CLOUD_SEQUENCE'
        ? VerifyCloudStorage(t)
        : SelectCloudPrefix({
            t,
            cloudInfos: {
              cloudBucketName,
              integratedCloudPrefix,
              cloudIntegrationId,
              cloudStorageType,
              cloudPrefix,
              setCloudPrefix,
            },
          }),
    [STORAGE_METHOD]: SelectStorageMethod(t),
  };
};

export default Cloud;
