import { Directory, FileEntry, FileType } from 'react-aspen';

import { IFileTreeXHandle } from './types';

// Properly typed Object.keys
const keys = <O extends Record<PropertyKey, any>>(o: O): (keyof O)[] =>
  Object.keys(o) as (keyof O)[];

interface Options {
  selectableTypes?: FileType[];
}

export class KeyboardHotkeys {
  private hotkeyActions = {
    ArrowUp: () => this.jumpToPrevItem(),
    ArrowDown: () => this.jumpToNextItem(),
    ArrowRight: () => this.expandOrJumpToFirstChild(),
    ArrowLeft: () => this.collapseOrJumpToFirstParent(),
    Space: () => this.toggleDirectoryExpand(),
    Enter: () => this.selectFileOrToggleDirState(),
    Home: () => this.jumpToFirstItem(),
    End: () => this.jumpToLastItem(),
    Escape: () => this.resetSteppedOrSelectedItem(),
  };

  constructor(private readonly fileTreeX: IFileTreeXHandle, private readonly options: Options) {}

  public handleKeyDown = (ev: React.KeyboardEvent): boolean | null => {
    if (!this.fileTreeX.hasDirectFocus()) {
      return false;
    }
    const { code } = ev.nativeEvent;
    const k = keys(this.hotkeyActions);
    if (k.indexOf(code as (typeof k)[number]) !== -1) {
      ev.preventDefault();
      this.hotkeyActions[code as (typeof k)[number]]();
      return true;
    }
    return null;
  };

  private jumpToFirstItem = (): void => {
    const { root } = this.fileTreeX.getModel();
    this.fileTreeX.setActiveFile(root.getFileEntryAtIndex(0));
  };

  private jumpToLastItem = (): void => {
    const { root } = this.fileTreeX.getModel();
    this.fileTreeX.setActiveFile(root.getFileEntryAtIndex(root.branchSize - 1));
  };

  private getNextSelectableItem(startIdx: number, increment: number): FileEntry | null {
    const { root } = this.fileTreeX.getModel();
    let file: FileEntry | null = null;
    let isSelectable: boolean;
    let idx = startIdx;
    do {
      idx += increment;
      file = root.getFileEntryAtIndex(idx);
      isSelectable =
        !!file &&
        (!this.options.selectableTypes || this.options.selectableTypes.indexOf(file.type) !== -1);
    } while (file && !isSelectable && idx + increment <= root.branchSize && idx + increment >= 0);
    return file;
  }

  private jumpToNextItem = (): void => {
    const { root } = this.fileTreeX.getModel();

    const selectedFile = this.fileTreeX.getActiveFile();
    if (!selectedFile) {
      this.jumpToFirstItem();
      return;
    }

    const idx = root.getIndexAtFileEntry(selectedFile);
    if (idx + 1 > root.branchSize) {
      this.jumpToFirstItem();
      return;
    }
    if (idx > -1) {
      const file = this.getNextSelectableItem(idx, 1);
      if (!file) this.jumpToFirstItem();
      else this.fileTreeX.setActiveFile(file);
    }
  };

  private jumpToPrevItem = (): void => {
    const { root } = this.fileTreeX.getModel();

    const selectedFile = this.fileTreeX.getActiveFile();
    if (!selectedFile) {
      this.jumpToLastItem();
      return;
    }

    const idx = root.getIndexAtFileEntry(selectedFile);
    if (idx - 1 < 0) {
      this.jumpToLastItem();
      return;
    }
    if (idx > -1) {
      const file = this.getNextSelectableItem(idx, -1);
      if (!file) this.jumpToLastItem();
      else this.fileTreeX.setActiveFile(file);
    }
  };

  private expandOrJumpToFirstChild(): void {
    const currentPseudoActive = this.fileTreeX.getActiveFile();
    if (currentPseudoActive && currentPseudoActive.type === FileType.Directory) {
      if ((currentPseudoActive as Directory).expanded) {
        this.jumpToNextItem();
        return;
      }
      this.fileTreeX.openDirectory(currentPseudoActive as Directory);
    }
  }

  private collapseOrJumpToFirstParent(): void {
    const currentPseudoActive = this.fileTreeX.getActiveFile();
    if (currentPseudoActive) {
      if (
        currentPseudoActive.type === FileType.Directory &&
        (currentPseudoActive as Directory).expanded
      ) {
        this.fileTreeX.closeDirectory(currentPseudoActive as Directory);
        return;
      }
      this.fileTreeX.setActiveFile(currentPseudoActive.parent);
    }
  }

  private selectFileOrToggleDirState = (): void => {
    const currentPseudoActive = this.fileTreeX.getActiveFile();
    if (!currentPseudoActive) {
      return;
    }
    if (currentPseudoActive.type === FileType.Directory) {
      this.fileTreeX.setActiveFile(currentPseudoActive as FileEntry);
      this.fileTreeX.toggleDirectory(currentPseudoActive as Directory);
    }
  };

  private toggleDirectoryExpand = (): void => {
    const currentPseudoActive = this.fileTreeX.getActiveFile();
    if (!currentPseudoActive) {
      return;
    }
    if (currentPseudoActive.type === FileType.Directory) {
      this.fileTreeX.toggleDirectory(currentPseudoActive as Directory);
    }
  };

  private resetSteppedOrSelectedItem = (): void => {
    const currentPseudoActive = this.fileTreeX.getActiveFile();
    if (currentPseudoActive) {
      this.resetSteppedItem();
      return;
    }
    this.fileTreeX.setActiveFile(null);
  };

  private resetSteppedItem = () => {
    this.fileTreeX.setActiveFile(null);
  };
}
