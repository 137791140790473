import { TFunction } from 'i18next';

import { getIsExpired } from '../components/pages/account/advanced/PersonalAccessKey/util';
import { parseDate } from '../utils/date';
import { useCommandBar } from '../utils/external';
import { getExpirationDistance, getExpirationDistanceKey } from './utils';

export const SUBSCRIPTION_WARNING_DAYS = 10;
export const TRIAL_WARNING_DAYS = 3;

export const trackSubscriptionExpired = (t: TFunction, expirationDate: string) => {
  if (!useCommandBar || !window.CommandBar) console.error('CommandBar not found');
  if (!expirationDate) return;
  if (getIsExpired(expirationDate)) {
    const eventProps = {
      title: t('commandBar.subscriptionExpired.title'),
      content: t('commandBar.subscriptionExpired.content'),
      buttonText: t('commandBar.subscriptionExpired.buttonText'),
      expirationDate,
    };
    addEventMetadata(eventProps);
    window.CommandBar.trackEvent('subscriptionExpired', eventProps);
  } else {
    // const daysToExpiration = formatDistance(new Date(), parseDate(expirationDate));
    const daysToExpiration = getExpirationDistance(parseDate(expirationDate));
    const translationKey = getExpirationDistanceKey(parseDate(expirationDate));
    const translated = t(translationKey, { timeDistance: daysToExpiration });

    const eventProps = {
      title: t('commandBar.subscriptionExpiring.title', { daysToExpiration: translated }),
      content: t('commandBar.subscriptionExpiring.content'),
      buttonText: t('commandBar.subscriptionExpiring.buttonText'),
      expirationDate,
    };
    addEventMetadata(eventProps);
    window.CommandBar.trackEvent('subscriptionExpiring', eventProps);
  }
};

export const trackTrialEnded = (t: TFunction, endDate: string) => {
  if (!useCommandBar || !window.CommandBar) console.error('CommandBar not found');
  if (!endDate) return;
  if (getIsExpired(endDate)) {
    const eventProps = {
      title: t('commandBar.trialEnded.title'),
      content: t('commandBar.trialEnded.content'),
      buttonText: t('commandBar.trialEnded.buttonText'),
      expirationDate: endDate,
    };
    addEventMetadata(eventProps);
    window.CommandBar.trackEvent('trialEnded', eventProps);
  } else {
    const daysToExpiration = getExpirationDistance(parseDate(endDate));
    const translationKey = getExpirationDistanceKey(parseDate(endDate));
    const translated = t(translationKey, { timeDistance: daysToExpiration });
    const eventProps = {
      title: t('commandBar.trialEnding.title', { daysToExpiration: translated }),
      content: t('commandBar.trialEnding.content'),
      buttonText: t('commandBar.trialEnding.buttonText'),
      expirationDate: endDate,
    };
    addEventMetadata(eventProps);
    window.CommandBar.trackEvent('trialEnding', eventProps);
  }
};

function addEventMetadata(eventProps: {
  title: string;
  content: string;
  buttonText: string;
  expirationDate: string;
}) {
  window.CommandBar.addMetadata('title', eventProps.title);
  window.CommandBar.addMetadata('content', eventProps.content);
  window.CommandBar.addMetadata('buttonText', eventProps.buttonText);
  window.CommandBar.addMetadata('expirationDate', eventProps.expirationDate);
}
