import { TFunction } from 'next-i18next';

import { ProjectConfig } from '../../../../utils/LabelInterfaceUtils';
import { getSpbColorBlindFriendlyColorsString } from '../charts/donutChart/donutChartColors';
import { CategoryFields, GroupByField, LabelFields, ObjectFields } from './fields';
import { ChartType, DataEnum, DataSpec } from './type';

export const getStatDisplayName = (dataSpec: DataSpec, groupBy: GroupByField): string =>
  CustomChartSpec[dataSpec][groupBy].title;
/** Data Spec => supported data spec options */
export const CustomizeDataOptions = (t: TFunction, config: ProjectConfig) => {
  const { hasObjects, hasCategories, hasFrames } = config;

  const options = [
    { value: DataEnum.labelCount, label: t('analytics.customizeAnalytics.text.labelCounts') },
  ];

  if (!hasFrames && hasObjects) {
    options.push({
      value: DataEnum.objectCount,
      label: t('analytics.customizeAnalytics.text.objectCounts'),
    });
  }

  if (hasFrames && hasObjects) {
    options.push(
      {
        value: DataEnum.objectCount,
        label: t('analytics.customizeAnalytics.text.objectCountsByLabel'),
      },
      {
        value: DataEnum.objectAnnotationCount,
        label: t('analytics.customizeAnalytics.text.objectCountsByFrame'),
      },
    );
  }

  if (!hasFrames && hasCategories) {
    options.push({
      value: DataEnum.categoryCount,
      label: t('analytics.customizeAnalytics.text.categoryCounts'),
    });
  }

  if (hasFrames && hasCategories) {
    options.push({
      value: DataEnum.categoryCount,
      label: t('analytics.customizeAnalytics.text.categoryCountsByLabel'),
    });
  }

  return options;
};

/** Options for creating custom chart */
export const CustomizeLabelGroupByOptions = (t: TFunction) => {
  return [
    { value: LabelFields.status, label: t('analytics.customizeAnalytics.text.status') },
    { value: LabelFields.labelTag, label: t('analytics.customizeAnalytics.text.labelTag') },
    {
      value: LabelFields.assetGroup,
      label: t('analytics.customizeAnalytics.text.assetGroup'),
    },
  ];
};

export const CustomizeObjectGroupByOptions = (t: TFunction) => {
  return [
    { value: ObjectFields.classIds, label: t('analytics.customizeAnalytics.text.className') },
    {
      value: ObjectFields.labelTag,
      label: t('analytics.customizeAnalytics.text.labelTag'),
      disabled: true,
    },
  ];
};

export const CustomizeCategoryGroupByOptions = (t: TFunction) => {
  return [
    {
      value: CategoryFields.categoryIds,
      label: t('analytics.customizeAnalytics.text.categoryName'),
    },
  ];
};

export function getGroupByOptions(spec: DataSpec, t: TFunction) {
  return {
    [DataEnum.labelCount]: CustomizeLabelGroupByOptions(t),
    [DataEnum.objectCount]: CustomizeObjectGroupByOptions(t),
    [DataEnum.objectAnnotationCount]: CustomizeObjectGroupByOptions(t),
    [DataEnum.categoryCount]: CustomizeCategoryGroupByOptions(t),
  }[spec];
}

export const getDefaultGroupBy = (dataSpec: DataSpec): GroupByField => {
  return {
    [DataEnum.labelCount]: LabelFields.status,
    [DataEnum.objectCount]: ObjectFields.classIds,
    [DataEnum.objectAnnotationCount]: ObjectFields.classIds,
    [DataEnum.categoryCount]: CategoryFields.categoryIds,
  }[dataSpec] as GroupByField;
};

export function validateGroupByOptions(dataSpec: DataSpec, groupBy?: GroupByField) {
  if (!groupBy) return false;
  const fieldsMap = {
    [DataEnum.labelCount]: LabelFields,
    [DataEnum.objectCount]: ObjectFields,
    [DataEnum.objectAnnotationCount]: ObjectFields,
    [DataEnum.categoryCount]: CategoryFields,
  };
  return Object.values(fieldsMap[dataSpec]).includes(groupBy);
}

const CompositionCharts = ['bar', 'donut', 'treemap'] as ChartType[];
// const ComparisonCharts = ['bar'];

/** Chart specifications by data & groupBy */
export const CustomChartSpec = {
  [DataEnum.labelCount]: {
    [LabelFields.status]: {
      title: 'label_counts_by_status', // used for donwload
      text: {
        SUBMITTED: 'Submitted',
        SKIPPED: 'Skipped',
        WORKING: 'In Progress',
      },
      colors: {
        SUBMITTED: '#4CAF50',
        WORKING: '#FFC107',
        SKIPPED: '#CDCDCD',
      },
      defaultChart: 'treemap' as ChartType,
      supportedCharts: CompositionCharts,
    },
    [LabelFields.assetGroup]: {
      title: 'label_counts_by_dataset',
      text: {},
      colors: getSpbColorBlindFriendlyColorsString, // TODO: change palette
      defaultChart: 'treemap' as ChartType,
      supportedCharts: CompositionCharts,
    },
    [LabelFields.labelTag]: {
      title: 'label_counts_by_tag',
      text: {},
      colors: getSpbColorBlindFriendlyColorsString,
      defaultChart: 'treemap',
      supportedCharts: CompositionCharts,
    },
  },
  [DataEnum.categoryCount]: {
    [CategoryFields.categoryIds]: {
      title: 'category_counts',
      text: {},
      colors: getSpbColorBlindFriendlyColorsString, // TODO: change palette
      defaultChart: 'bar' as ChartType,
      supportedCharts: CompositionCharts,
    },
  },
  [DataEnum.objectAnnotationCount]: {
    [ObjectFields.classIds]: {
      title: 'object_counts_by_frame',
      text: {},
      colors: [],
      defaultChart: 'bar' as ChartType,
      supportedCharts: CompositionCharts,
    },
  },
  [DataEnum.objectCount]: {
    [ObjectFields.classIds]: {
      title: 'object_counts',
      text: {},
      colors: [],
      defaultChart: 'bar' as ChartType,
      supportedCharts: CompositionCharts,
    },
  },
} as Record<DataSpec, Record<GroupByField, any>>;

/** Get default chart type for statistics */
export const getDefaultChartType = (dataSpec: DataSpec, groupBy: GroupByField): ChartType => {
  if (!CustomChartSpec[dataSpec][groupBy]) return 'bar';
  return CustomChartSpec[dataSpec][groupBy].defaultChart as ChartType;
};
