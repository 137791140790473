import { Box, Typography } from '@superb-ai/ui';
import React from 'react';
import { ImagesJobImagesFieldParam } from '../../../../types/commandTypes';
import { Trans, useTranslation } from 'react-i18next';
import { parseQueryStringToGetClusterInfo } from '../../../../utils/filterUtils';
import { useParams } from 'react-router';
import { useImageLeavesInSuperClustersQuery } from '../views/embedding/queries/embeddingQueries';
import { ClusterLevel } from '../filter/types';

export default function DownloadFilterInfo({
  imageFilter,
  isOpen,
}: {
  imageFilter: ImagesJobImagesFieldParam;
  isOpen?: boolean;
}) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();

  const { remainingQuery, parsed } = parseQueryStringToGetClusterInfo(imageFilter.query) || {
    remainingQuery: undefined,
    parsed: undefined,
  };

  // Collect all superClusterIds for the query
  const superClusterIds = parsed
    .filter(
      item =>
        item.type === 'SelectedSuperClusters' || item.type === 'SuperClustersWithDeselectedLeaves',
    )
    .flatMap(item =>
      item.type === 'SelectedSuperClusters' ? item.superClusterIds : [item.superClusterId],
    );

  const { data: leavesInCurrentLevelSuperbCluster } = useImageLeavesInSuperClustersQuery({
    datasetId,
    sliceName: imageFilter.slice,
    superClusterIds,
    clusterLevel: parsed.filter(
      item =>
        item.type === 'SelectedSuperClusters' || item.type === 'SuperClustersWithDeselectedLeaves',
    )[0]?.clusterLevel as ClusterLevel,
    fromPublicDatasets: false,
    disabled: !isOpen || !superClusterIds.length,
  });

  // Function to calculate total cluster count
  function calculateTotalClusterCountWithParsedQuery(
    parsedResults: any[],
    leavesInSuperClusters: { id: string; size: number; superClusterId: string }[],
  ) {
    let totalLeafCount = 0;

    const totalSuperClusterCount = leavesInSuperClusters?.length || 0;

    parsedResults.forEach(item => {
      switch (item.type) {
        case 'SelectedLeafClusters':
          totalLeafCount += item.leafClusterIds.length;
          break;
        case 'SelectedSuperClusters':
          break;
        case 'SuperClustersWithDeselectedLeaves':
          totalLeafCount -= item.leafClusterIds.length;
          break;
        default:
          break;
      }
    });

    return totalSuperClusterCount + totalLeafCount;
  }

  if (!imageFilter.query || !isOpen) return;

  const totalClusterCount = calculateTotalClusterCountWithParsedQuery(
    parsed,
    leavesInCurrentLevelSuperbCluster,
  );

  return (
    <>
      {remainingQuery && (
        <Box display="grid" color="gray" style={{ gridTemplateColumns: '120px 1fr' }}>
          <Typography p={1} variant="m-medium">
            {t('curate.downloads.query')}
          </Typography>
          <Typography p={1} variant="m-regular">
            {remainingQuery}
          </Typography>
        </Box>
      )}
      {!!totalClusterCount && (
        <Box display="grid" color="gray" style={{ gridTemplateColumns: '120px 240px 1fr' }}>
          <Typography p={1} variant="m-medium">
            Filters
          </Typography>
          <Typography p={1} variant="m-regular">
            {t('curate.imageFilter.aiSelectedImages')}
          </Typography>
          <Typography p={1} variant="m-regular">
            <Trans
              t={t}
              i18nKey={'curate.filter.selectedCount'}
              values={{ count: totalClusterCount }}
              components={{ emphasize1: <></> }}
            />
          </Typography>
        </Box>
      )}
    </>
  );
}
