import { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Dot } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { formatNumber } from '../../../../../utils/numberUtils';

const SliceSummaryRow = ({
  text,
  count,
  ...otherProps
}: {
  text: string;
  count: number;
  textStyle?: ComponentProps<typeof Typography>;
}) => {
  return (
    <Box display="flex" alignItems="center" pr={2}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Icon icon={Dot} color="black" size={24} />
        <Typography variant="m-regular" {...otherProps?.textStyle}>
          {text}
        </Typography>
      </Box>
      <Box ml="auto">
        <Typography variant="m-regular" color={'primary'}>
          {formatNumber(count)}
        </Typography>
      </Box>
    </Box>
  );
};

interface SliceSummaryProps extends ComponentProps<typeof Box> {
  selectedObjects: number;
  imageCount?: number;
}

const SliceSummary: FunctionComponent<SliceSummaryProps> = props => {
  const { selectedObjects, imageCount, ...otherProps } = props;
  const { t } = useTranslation();

  return (
    <Box
      backgroundColor={'gray-100'}
      p={2}
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      <Typography variant="m-regular">{t('curate.diagnosis.sliceModal.infoText')}</Typography>
      <Box display="flex" flexDirection="column" mt={2} gap={0}>
        <SliceSummaryRow
          text={t('curate.diagnosis.sliceModal.selectedObjects')}
          count={selectedObjects}
        />
        {imageCount && (
          <SliceSummaryRow
            text={t('curate.diagnosis.sliceModal.imagesToAddToSlice')}
            count={imageCount}
          />
        )}
      </Box>
    </Box>
  );
};

export default SliceSummary;
