import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import ExportHistory from '../../../../../components/pages/export/exportHistory';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export default CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectExportHistory,
  path: 'history',
  title: ({ t }) => t('export.history.title'),
  component: ExportHistory,
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
