import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Expand, GenerateImage, Sync } from '@superb-ai/icons';
import { Box, Button, Checkbox, Chip, Icon, vars } from '@superb-ai/ui';
import { filter } from 'lodash';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';
import { sleep } from '../../../../../../../../utils/SpbUtils';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import AnnotatedImage from '../../../../../../elements/AnnotatedImage/AnnotatedImage';
import { CurateAnnotationCamel } from '../../../../../../services/DatasetService';
import { GRID_VIEW, VIEW, ViewMode } from '../../../../../../types/viewTypes';
import { convertDeselectedImageIdsToQueryString } from '../../../../../../utils/filterUtils';
import { ClusterLevel } from '../../../filter/types';

type ExtraProps = {
  setOpenDetailImageIndex: (index: number) => void;
  clusterLevel: ClusterLevel;
};
export default function ImageCell({
  columnIndex,
  rowIndex,
  columns,
  results,
  clusterLevel,
  setOpenDetailImageIndex,
}: CellComponentProps<ExtraProps>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const imageInfo = results[currentIndex];
  const [imageSize, setImageSize] = useState<[number, number]>(); // [width, height]
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const { deselectedData, setDeselectedData, selectedData, setSelectedData, selectedAllData } =
    useActionContext();
  const { totalCount } = useImageScopeContext();
  const { t } = useTranslation();
  const { imageClusters } = useImageFilterContext();
  const { setHiddenQueryString } = useQueryContext();
  const imageClusterMap: Record<string, string> = imageClusters.reduce(
    (acc, cluster) => ({ ...acc, [cluster.id]: cluster.color }),
    {},
  );
  const history = useHistory();
  const { location: search } = history;
  const params = new URLSearchParams(search.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;

  const isSelected = useMemo(() => {
    if (selectedAllData) {
      // If all data is selected, check if the image is not in deselectedData
      return !(filter(deselectedData, ['id', imageInfo?.id]).length > 0);
    }
    // Otherwise, check if the image is in selectedData
    return filter(selectedData, ['id', imageInfo?.id]).length > 0;
  }, [selectedData, deselectedData, imageInfo?.id, selectedAllData]);

  useEffect(() => {
    if (!imageInfo) return;
    const img = new Image();
    img.src = imageInfo.thumbnailUrl;
    img.onload = function () {
      setImageSize([img.naturalWidth, img.naturalHeight]);
    };
  }, [imageInfo]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await sleep(50);
      setLoading(false);
    })();
  }, [columns]);

  if (!imageInfo || currentIndex > (totalCount || 0) - 1 || !imageSize) {
    return <></>;
  }

  function toggleSelect() {
    if (selectedAllData) {
      if (isSelected) {
        // Deselect the image
        const newDeselectedData = deselectedData?.length
          ? [...deselectedData, { id: imageInfo.id, thumbnailUrl: imageInfo.thumbnailUrl }]
          : [{ id: imageInfo.id, thumbnailUrl: imageInfo.thumbnailUrl }];
        setHiddenQueryString(
          convertDeselectedImageIdsToQueryString(newDeselectedData.map(image => image.id)),
        );
        setDeselectedData(newDeselectedData);
      } else {
        // Remove from deselected data
        const newDeselectedData = deselectedData?.filter(data => data.id !== imageInfo.id);
        setHiddenQueryString(
          convertDeselectedImageIdsToQueryString(
            newDeselectedData?.length ? newDeselectedData.map(image => image.id) : [],
          ),
        );
        setDeselectedData(newDeselectedData?.length ? newDeselectedData : []);
      }
    } else {
      if (isSelected) {
        // Deselect the image
        const newSelectedData = selectedData?.filter(data => data.id !== imageInfo.id);
        setSelectedData(newSelectedData?.length ? newSelectedData : []);
      } else {
        // Select the image
        const newSelectedData = selectedData?.length
          ? [...selectedData, { id: imageInfo.id, thumbnailUrl: imageInfo.thumbnailUrl }]
          : [{ id: imageInfo.id, thumbnailUrl: imageInfo.thumbnailUrl }];
        setSelectedData(newSelectedData);
      }
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      position="relative"
      backgroundColor="gray-100"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {imageSize && (
        <>
          {!loading && (
            <AnnotatedImage
              srcUrl={imageInfo.thumbnailUrl}
              originalImageSize={[
                imageInfo.metadata.width as number,
                imageInfo.metadata.height as number,
              ]}
              alt={imageInfo.label_id}
              imgStyle={{
                ...(imageSize[0] / imageSize[1] >= 16 / 9 ? { width: '100%' } : { height: '100%' }),
                display: 'block' /* remove extra space below image */,
              }}
              annotations={imageInfo.annotations?.map((anno: CurateAnnotationCamel) => ({
                type: anno.annotationType,
                coordinate: anno.annotationValue,
                id: anno.id,
              }))}
            />
          )}
          <Box
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            backgroundColor="gray-opacity-300"
            style={{
              visibility: hover || isSelected ? 'visible' : 'hidden',
              ...(isSelected
                ? { outline: `2px solid ${vars.color.primary}`, outlineOffset: '-2px' }
                : {}),
            }}
            onClick={toggleSelect}
            cursor="pointer"
          >
            <Box position="absolute" style={{ top: 0, left: 0 }} mr="auto">
              <Checkbox value={isSelected} onClick={toggleSelect} />
            </Box>
            {view === 'semantic_search' &&
              selectedData &&
              selectedData[selectedData.length - 1]?.id === imageInfo.id && (
                <Box position="absolute" style={{ top: 0, right: 0 }} mr="auto">
                  <Chip style={{ whiteSpace: 'nowrap' }}>유사도 검색 이미지</Chip>
                </Box>
              )}
          </Box>
          <Box
            position="absolute"
            display="flex"
            flexDirection="column"
            gap={0.25}
            style={{ top: '2px', right: '2px' }}
          >
            {imageInfo.labelProjectSyncExists && (
              <Box
                backgroundColor="gray-opacity-400"
                borderRadius="2px"
                display="flex"
                alignItems="center"
                p={0.25}
              >
                <Icon size="12px" color="green" icon={Sync} />
              </Box>
            )}
            {imageInfo.isSynthetic && (
              <Box
                backgroundColor="gray-opacity-400"
                borderRadius="2px"
                display="flex"
                alignItems="center"
                p={0.25}
              >
                <Icon size="12px" color="white" icon={GenerateImage} />
              </Box>
            )}
          </Box>
          {hover && (
            <Box position="absolute" style={{ bottom: 4, right: 4 }}>
              <Button
                variant="stroke"
                color="primary"
                size="s"
                onClick={e => {
                  e.stopPropagation();
                  setOpenDetailImageIndex(currentIndex);
                }}
              >
                <Icon icon={Expand} />
                {t('curate.button.detail')}
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
