import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import { isBoolean } from 'lodash';

import { ClusterLevel, ImageFilterSchema } from '../components/datasets/dataset/filter/types';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateDatasetObjectService } from '../services/DatasetObjectService';
import { useCurateDatasetService } from '../services/DatasetService';
import { convertSelectedLeafClustersToQueryString, stringifyFilters } from '../utils/filterUtils';

type Props = {
  datasetId: string;
  fromPublicDatasets?: boolean;
  queryString: string;
  sliceName?: string | null | undefined;
  pageSize?: number;
  clusterLevel?: ClusterLevel;
  appliedFilters?: ImageFilterSchema;
  sortBy?: string[];
  sortOrder?: string[];
  disabled?: boolean;
  imageSimilarTo?: string;
  clipSimilarTo?: number[];
  clipEmbedExists?: boolean;
};

export function useDatasetsBriefDataQuery({
  datasetId,
  fromPublicDatasets,
  queryString,
  sliceName,
  appliedFilters,
  pageSize = 20,
  sortBy,
  sortOrder,
  disabled,
  imageSimilarTo,
  clipSimilarTo,
  clipEmbedExists,
}: Props) {
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const { postGetObjectList } = useCurateDatasetObjectService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  return useInfiniteQuery({
    queryKey: [
      'curate-dataset-data-brief',
      datasetId,
      queryString,
      sliceName,
      stringifiedFilters,
      sortBy,
      sortOrder,
      imageSimilarTo,
      clipSimilarTo,
      clipEmbedExists,
    ],
    queryFn: async ({ pageParam }) => {
      const imageInfo = await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        searchAfter: pageParam,
        expand: [
          'thumbnail_url',
          'super_clusters',
          'label_project_sync_exists',
          'leaf_cluster',
          'dataset_total_count',
        ],
        query: queryString,
        size: pageSize,
        sliceName: sliceName ? sliceName : undefined,
        appliedFilters,
        sortBy,
        sortOrder,
        imageSimilarTo,
        clipSimilarTo,
        clipEmbedExists,
      });
      const imageIdIn = imageInfo?.results.map(list => list.id);

      const anno = await postGetObjectList({
        dataset_id: datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        slice: sliceName || undefined,
        search_after: [],
        query: queryString,
        size: 100,
        ...(imageIdIn && { image_id_in: imageIdIn }),
      });

      const pageArray = Array.from(Array(Math.floor(anno.count / 100)).keys());
      let annoLast = anno.last;
      const totalAnno = await pageArray.reduce(
        async (promise: any, _) => {
          const result = await promise;
          const newList = await postGetObjectList({
            dataset_id: datasetId,
            fromPublicDatasets: isBoolean(fromPublicDatasets)
              ? fromPublicDatasets
              : showPublicDatasets,
            slice: sliceName || undefined,
            query: queryString,
            search_after: [...annoLast],
            ...(imageIdIn && { image_id_in: imageIdIn }),
            size: 100,
          });
          annoLast = newList.last;
          return [...result, ...newList.results];
        },
        [...anno.results],
      );

      const imageWithAnno = imageInfo?.results.map(list => {
        const objects = totalAnno.filter(
          (annoList: { imageId: string }) => annoList.imageId === list.id,
        );
        return { ...list, annotations: [...objects] };
      });
      return { ...imageInfo, results: [...imageWithAnno] };
    },
    getNextPageParam: ({ last }) => last,
    enabled: Boolean(datasetId) && !disabled,
    refetchOnWindowFocus: false,
  });
}

export function useChunkedLeafClusterImagesQuery({
  datasetId,
  fromPublicDatasets,
  chunk,
  disabled,
}) {
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const queryString = convertSelectedLeafClustersToQueryString(chunk);

  return useQuery({
    queryKey: ['curate-chunked-leaf-images', datasetId, chunk, queryString],
    queryFn: async () => {
      return await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets: fromPublicDatasets ?? showPublicDatasets,
        expand: ['thumbnail_url', 'leaf_cluster', 'super_clusters'],
        size: chunk.length * 2,
        appliedFilters: {
          leaf_cluster_score: 1,
        },
        query: queryString,
      });
    },
    enabled: Boolean(datasetId) && !disabled && (chunk || []).length > 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export function useDatasetImageQuery({
  datasetId,
  fromPublicDatasets,
  imageId,
  expand,
}: {
  datasetId: string;
  fromPublicDatasets?: boolean;
  imageId: string;
  expand?: string[];
}) {
  const { getImageData } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQuery({
    queryKey: ['curate-dataset-image-detail', datasetId, imageId],
    queryFn: () => {
      return getImageData({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        imageId,
        expand,
      });
    },
    enabled: Boolean(datasetId && imageId),
  });
}

export function useDatasetImageQueries({
  datasetId,
  fromPublicDatasets,
  imageIds,
  expand,
}: {
  datasetId: string;
  fromPublicDatasets?: boolean;
  imageIds: string[];
  expand?: string[];
}) {
  const { getImageData } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQueries({
    queries: imageIds.map(imageId => {
      return {
        queryKey: ['curate-dataset-image-detail', datasetId, imageId, expand],
        queryFn: () => {
          return getImageData({
            datasetId,
            fromPublicDatasets: isBoolean(fromPublicDatasets)
              ? fromPublicDatasets
              : showPublicDatasets,
            imageId,
            expand,
          });
        },
        refetchOnWindowFocus: false,
        enabled: Boolean(datasetId && imageId),
      };
    }),
  });
}

export function useDatasetDataCountQuery({
  datasetId,
  fromPublicDatasets,
  queryString,
  sliceName,
  appliedFilters,
  disabled,
  clipEmbedExists,
}: Props) {
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const stringifiedFilters = stringifyFilters(appliedFilters);

  return useQuery({
    queryKey: [
      'curate-data-count',
      datasetId,
      fromPublicDatasets,
      queryString,
      sliceName,
      stringifiedFilters,
      clipEmbedExists,
    ],
    queryFn: () => {
      return getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        size: 0,
        sliceName: sliceName ? sliceName : undefined,
        ...(queryString && { query: queryString }),
        appliedFilters,
        clipEmbedExists,
      });
    },
    enabled: Boolean(datasetId) && !disabled,
    refetchOnWindowFocus: false,
  });
}

export function useImageDataByImageKey({
  datasetId,
  imageKey,
  expand,
  fromPublicDatasets,
  disabled,
}: {
  datasetId: string;
  imageKey: string;
  expand?: string[];
  fromPublicDatasets?: boolean;
  disabled?: boolean;
}) {
  const { getImageDataByImageKey } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQuery({
    queryKey: ['curate-dataset-image-data', datasetId, imageKey, fromPublicDatasets],
    queryFn: () => {
      return getImageDataByImageKey({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        imageKey,
        expand,
      });
    },
    enabled: Boolean(datasetId && imageKey) && !disabled,
  });
}
