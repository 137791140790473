import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ButtonGroup,
  Card,
  IconButton,
  Typography,
  useContainerSize,
} from '@superb-ai/norwegian-forest';
import { isEmpty, isNull, map, sum, upperFirst } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { formatDistanceShort } from '../../../../utils/date';
import CircularProgressBox from '../../../elements/CircularProgressBox';
// Custom charts
import BarChartScrollable from '../charts/barChart/BarChartScrollable';
import { getSpbDefaultColors } from '../charts/donutChart/donutChartColors';
import DonutChartWithLegend from '../charts/donutChart/DonutChartWithLegend';
import { EMPTY_MESSAGE } from '../config/constants';
import { SVG_PROPERTY_DONUT, SVG_PROPERTY_SCROLL_BAR } from '../config/svgConfig';
import {
  AnalyticsSummaryType,
  BarChartNameChoice,
  FileFormat,
  SortOption,
} from '../dataTypes/analyticsDataTypes';
import { BarChartIconButton } from '../elements/buttons/BarChartIconButton';
import { DonutChartIconButton } from '../elements/buttons/DonutChartIconButton';
import SyncButton from '../elements/buttons/SyncButton';
import EmptyPlot from '../elements/EmptyPlot';
import DownloadDropDown from '../tools/DownloadDropDown';
import { addCategorizationShare, sortData } from '../tools/helper';
import SortDropDown from '../tools/SortDropDown';
import { chartMockData } from '../userStats/mock';
import { JsonObj } from '../userStats/types';
import {
  displayDistanceTime,
  downloadDataFile,
  downloadImageFile,
  getDateDistance,
} from './helper';

const CategoryCountChart = (props: {
  chartInfo: JsonObj; // chart display info from plotConfig
  isLoading: boolean;
  labelCount: number;
  sourceData: JsonObj[];
  filter: Record<string, string[] | []>;
  propertyName?: string;
  height: number;
  syncTime: Date;
  refresh: (summaryType: AnalyticsSummaryType) => void;
}): React.ReactElement => {
  const chartName = 'imageCategoryStats';
  const projectInfo = useProjectInfo();
  const projectName = projectInfo.project.name;
  const { t } = useTranslation();
  const {
    sourceData,
    chartInfo,
    isLoading,
    labelCount,
    filter,
    propertyName,
    height,
    syncTime,
    refresh,
  } = props;
  const {
    xVariable: [xKey],
    yVariable: [yKey],
    xFilterKey,
    tooltipKeyMap,
    chartKind,
    chartDefault,
    buttonSize,
    excelSheetName,
    downloadOptions,
  } = chartInfo;

  /** Ref */
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);

  const [chartType, setChartType] = useState(chartDefault);

  /** Sort */
  const [sortX, setResortX] = useState({
    ...chartInfo.sortX,
    display: false,
  } as SortOption);
  const [sortY, setResortY] = useState({
    ...chartInfo.sortY,
    display: true,
  } as SortOption);
  const [formattedData, setFormattedData] = useState<JsonObj[]>([]);
  const [syncTimeDistance, setSyncTimeDistance] = useState<string>(
    getDateDistance(syncTime, syncTime),
  );

  const formatDataCallback = useCallback(() => {
    const transformed = addCategorizationShare(sourceData);
    return sortData(transformed, sortY, sortX, chartName);
  }, [sourceData]);

  useEffect(() => {
    if (!isEmpty(sourceData)) {
      setFormattedData(formatDataCallback());
    }
    setSyncTimeDistance(formatDistanceShort(syncTime));
    // We don't want to re-render on every sort, only for initial load.
    // eslint-disable-next-line
  }, [sourceData, formatDataCallback]);

  useEffect(() => {
    const timeout = setInterval(
      () => {
        setSyncTimeDistance(formatDistanceShort(syncTime));
      },
      60000,
      10,
    );
    return () => clearInterval(timeout);
  }, []);

  // supp (supplementary variable) is object share (percent total count)
  const filterData = (data: JsonObj): JsonObj => {
    return {
      data,
      x: map(data, d => d[xFilterKey]),
      y: map(data, d => d[yKey]),
      supp: map(data, d => d.percentTotal),
      xDisplay: map(data, d => d[xKey]),
      groups: map(data, d => d.groups), // key indicator
    };
  };

  const handleSortChange = (sortAxis: 'x' | 'y') => {
    if (sortAxis === 'x') {
      const reverseSortX = {
        ...sortX,
        direction: sortX.direction === 'asc' ? 'desc' : 'asc',
        display: true,
      } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortX, sortY, chartName));
      setResortX(reverseSortX);
      setResortY({ ...sortY, display: false });
    } else if (sortAxis === 'y') {
      const reverseDir = sortY.direction === 'asc' ? 'desc' : 'asc';
      const reverseSortY = { ...sortY, direction: reverseDir, display: true } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortY, sortX, chartName));
      setResortY(reverseSortY);
      setResortX({ ...sortX, display: false });
    }
  };

  /**
   * Can download 'csv' , 'xlsx', and 'png'
   * TODO (mlimb): preserve sort
   */
  const handleDownload = (outputType: FileFormat) => {
    if (outputType === 'png') {
      downloadImageFile({
        projectName,
        chartDisplayName: chartInfo.title,
        chartName,
        chartType,
        filter,
      });
      return;
    }
    const outputData = map(formattedData, d => {
      return {
        category_name: d.name,
        count: d.count,
        percent_total: d.percentTotal,
      };
    });

    const chartDownloadName = chartInfo.title + `_${propertyName}`;
    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: outputData,
        projectName,
        chartName: chartDownloadName,
        filter,
      });
      return;
    }
    downloadDataFile({
      downloadData: outputData,
      projectName,
      chartName: chartDownloadName,
      excelSheetName,
      filter,
    });
    AnalyticsTracker.chartDownloaded({
      accountId: projectInfo.project.accountId,
      chartName: t(chartInfo.title),
      chartType: chartType,
      fileExtension: outputType,
      feature: 'label-analytics',
    });
  };

  const getEmptyMessage = (isInitDataNull: boolean, labelCount: number): string => {
    if (isInitDataNull && labelCount === 0) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    }
    if (labelCount > 0) {
      return t(EMPTY_MESSAGE.ANNOTATE_IMAGE_CATEGORIES);
    }
    // API returned [] or null (0 labels with status), but there's > 0 total labels.
    return t(EMPTY_MESSAGE.SUBMIT_LABELS);
  };

  /**
   * Given chartType, renders the selected chart Component.
   */
  const colorMap = getSpbDefaultColors(map(formattedData, d => d.id));
  const makeChart = (formattedData: JsonObj, chartType: 'donut' | 'bar'): React.ReactElement => {
    if (chartType === 'donut') {
      const svgInfo = SVG_PROPERTY_DONUT({ width, height: height - 90 });
      return (
        <DonutChartWithLegend
          data={formattedData.data}
          xValues={formattedData.x}
          yValues={formattedData.y}
          totalCounts={sum(formattedData.y)}
          chartName={chartName}
          // filter={filter}
          svgInfo={svgInfo}
          getDatumColor={(datum: JsonObj) => colorMap(datum?.name) as string}
          handleClickDatum={undefined}
        />
      );
    }
    const isScrollable = formattedData.data.length > 30;
    const svgInfo = SVG_PROPERTY_SCROLL_BAR(
      {
        width,
        height: height - 80,
      },
      formattedData?.x?.length,
    );
    return (
      <BarChartScrollable
        groups={formattedData.groups}
        xValues={formattedData.x}
        yValues={formattedData.y}
        sValues={formattedData.supp}
        totalCounts={sum(formattedData.y)}
        tooltipKeyMap={tooltipKeyMap}
        chartName={chartName as BarChartNameChoice}
        isScrollable={isScrollable}
        filter={filter}
        svgInfo={svgInfo}
        hasTooltip
        hoveredIndex={-1}
        handleClickDatum={undefined}
      />
    );
  };

  const hasZeroLabels = labelCount === 0 || formattedData.length === 0;
  /**
   * UI: [SortDropdown] [DonutIcon] [BarIcon]
   */
  const getRightButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <ButtonGroup gap={1}>
        <SortDropDown
          size={buttonSize.sort}
          sortX={sortX}
          sortY={sortY}
          handleSortChange={handleSortChange}
          isDisabled={hasZeroLabels}
        />
        <DonutChartIconButton
          size={buttonSize.donut}
          selected={chartType === 'donut'}
          disabled={hasZeroLabels}
          handleClick={(chart: string) => setChartType(chart)}
          t={t}
        />
        <BarChartIconButton
          size={buttonSize.bar}
          selected={chartType === 'bar'}
          disabled={hasZeroLabels}
          handleClick={(chart: string) => setChartType(chart)}
          t={t}
        />
        <DownloadDropDown
          key={`${chartName}-${isLoading}`}
          ml={0}
          iconButtonEl={
            <IconButton
              size="s"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={formattedData.length === 0}
            />
          }
          handleDownload={handleDownload}
          options={downloadOptions} // TODO: uncomment after fix
          isToggleDisabled={formattedData.length === 0}
        />
      </ButtonGroup>
    );
  };

  const refreshData = async () => {
    await refresh('category-summaries');
  };

  const getRefreshButton = () => {
    return <SyncButton isLoading={isLoading} refreshFn={refreshData} />;
  };

  return (
    <Card pt={2} mb={4} ref={chartContainerRef} key={`${propertyName}`}>
      <Box display="flex" pl={2} pr={2} pb={1.5} alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="left" justifyContent="start">
          <Box display="flex" alignItems="center" justifyContent="start">
            <Typography variant="headline5" themedColor="textDefault">
              <strong>
                {propertyName
                  ? `${t(chartInfo.title)} - ${upperFirst(propertyName)}`
                  : `${t(chartInfo.title)}`}
              </strong>
            </Typography>
            {getRefreshButton()}
          </Box>
          <Typography themedColor={['grey', 300]} variant="label">
            {displayDistanceTime(isLoading, syncTimeDistance)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box height="28px" mr="5px" display="flex">
            {getRightButtonGroup(hasZeroLabels)}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        themedBackgroundColor={['grey', 50]}
        pt={1.5}
        justifyContent="center"
        position="relative"
      >
        {isLoading ? (
          <CircularProgressBox
            key={chartName}
            boxProps={{ mt: 5, mb: 5, width: '100%', height: '180px' }}
          />
        ) : (
          <Box id={chartName} justifyContent="center">
            {' '}
            {makeChart(
              filterData(hasZeroLabels ? chartMockData[chartName] : formattedData),
              chartType,
            )}
            {hasZeroLabels && (
              <EmptyPlot
                chartKind={chartKind}
                message={getEmptyMessage(isNull(sourceData), labelCount)}
              />
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CategoryCountChart;
