/* eslint-disable @typescript-eslint/indent */
import { TFunction } from 'react-i18next';

import { scaleLinear } from 'd3-scale';
import {
  camelCase,
  concat,
  first,
  isEmpty,
  last,
  map,
  mapValues,
  partialRight,
  reduce,
  upperFirst,
} from 'lodash';

import { MemberData } from '../../../../../types/memberTypes';
import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import LabelInterfaceUtils, {
  AnnotationType,
  ImageLabelInterface,
  LabelInterface,
  LegacyLabelInterface,
  ObjectClass,
  VideoLabelInterface,
} from '../../../../../utils/LabelInterfaceUtils';
import { UserProfileObject } from '../../../../../utils/UserUtils';
import { BaseDatum, BottomHeader, JsonObj, LabelerDatum, LabelerTopHeaderCell } from '../types';

// Column names (if used more than once)
export const REJECTION_HISTORY_PERCENT = 'rejectedPercent';
export const REVIEW_HISTORY_PERCENT = 'reviewedPercent';
export const AVG_REVIEW_ROUND = 'avgReviewRounds';
export const REJECTED_COUNTS = 'rejectedCounts';
export const LABEL_COUNTS = 'labelCounts';
export const REJECTION_HISTORY_COUNT = 'rejectedHistory';
export const TOTAL_REVIEWED = 'totalReviewed';
const SUM_REVIEW_ROUNDS = 'sumReviewRounds';
export const TIME_PER_LABEL = 'timePerLabelSec';

export const USER_REPORT_HIDDEN_COLUMNS = [
  TOTAL_REVIEWED,
  REJECTION_HISTORY_COUNT,
  SUM_REVIEW_ROUNDS,
];

/** Colors for User Report Table */
interface ColorProps {
  lightToDark: boolean;
}
function colorGreenRange(props: ColorProps): string[] {
  const { lightToDark } = props;
  const DARK_GRADIENT = 'rgba(163, 235, 87, 1)';
  const LIGHT_GRADIENT = 'rgba(163, 235, 87, 0)';
  return lightToDark ? [LIGHT_GRADIENT, DARK_GRADIENT] : [DARK_GRADIENT, LIGHT_GRADIENT];
}

function colorRedRange(props: ColorProps): string[] {
  const { lightToDark } = props;
  const DARK_GRADIENT = 'rgba(255, 98, 90, 1)';
  const LIGHT_GRADIENT = 'rgba(255, 98, 90, 0)';
  return lightToDark ? [LIGHT_GRADIENT, DARK_GRADIENT] : [DARK_GRADIENT, LIGHT_GRADIENT];
}

/** Alternate cell colors in User Report for readability */
const CELL_COLORS = ['#FAFAFA', '#F4F4F4'];

const columnGroupText = (key: string): string => {
  const mapper = {
    labeler: 'analytics.userReports.labeler',
    summary: 'analytics.userReports.summary',
    objectClass: 'analytics.userReports.objectClass',
    annoType: 'text.annoType',
    reviewResults: 'analytics.userReports.reviewResults',
  };
  return mapper[key as keyof typeof mapper];
};

const groups = {
  labeler: 'labeler',
  summary: 'summary',
  objectClass: 'objectClass',
  annoType: 'annoType',
  reviewResults: 'reviewResults',
};
const columnGroupKey = (key: string): string => {
  return groups[key as keyof typeof groups];
};

const columns = {
  name: 'analytics.text.name',
  email: 'analytics.text.email',
  role: 'projectMembers.role.role',
  labels: 'text.labels',
  progress: 'analytics.userReports.progress',
  categoryCounts: 'text.categories',
  objectCounts: 'text.objects',
  timespentSec: 'analytics.userReports.timeSec',
  timePerLabelSec: 'analytics.userReports.timePerLabelSec',
  approved: 'analytics.userReports.approved',
  rejected: 'analytics.userReports.rejected',
  pendingReview: 'review.pendingReview',
  reviewHistoryPercent: 'analytics.userReports.reviewHistoryPercent',
  rejectHistoryPercent: 'analytics.userReports.rejectHistoryPercent',
  avgReviewRound: 'analytics.userReports.avgReviewRound',
  // video only
  frames: 'analytics.asset.frames',
  frameProgress: 'analytics.userReports.frameProgress',
  framewiseObjectCount: 'analytics.userReports.framewiseObjectCount', // 'Total Objects'
};
const columnText = (key: string): string => {
  return columns[key as keyof typeof columns];
};

const INFO_CELL = {
  id: '',
  name: '',
  numeric: false,
  group: columnGroupKey('labeler'),
  color: CELL_COLORS[0],
};
const SUMMARY_CELL = {
  id: '',
  name: '',
  numeric: true,
  group: columnGroupKey('summary'),
  color: CELL_COLORS[1],
};

function summaryCell(classId: string, displayNameFn: (id: string) => string = columnText) {
  return { ...SUMMARY_CELL, id: classId, name: displayNameFn(classId) };
}
const ANNOTATION_TYPE_CELL = {
  id: '',
  name: '',
  numeric: false,
  group: columnGroupKey('annoType'),
  color: CELL_COLORS[0],
};
const OBJECT_CELL = {
  id: '',
  name: '',
  annoType: '',
  numeric: true,
  group: columnGroupKey('objectClass'),
  color: CELL_COLORS[1],
};

/** Review Group: rename column header group here! */
const REVIEW_CELL = {
  id: '',
  name: '',
  numeric: true,
  group: columnGroupKey('reviewResults'),
  color: CELL_COLORS[0],
};

// anno type
const TYPE_TO_DISPLAY_NAME: Record<AnnotationType, string> = {
  box: 'Box',
  cuboid: 'Cuboid',
  cuboid2D: '2D Cuboid',
  cuboid2d: '2D Cuboid',
  keypoint: 'Keypoint',
  keypoints: 'Keypoints',
  polyline: 'Polyline',
  polygon: 'Polygon',
  polygons: 'Polygon Group', // TODO (mlimb) - remove when deprecated
  rbox: 'Rotated Box',
  tiltedbox: 'Tilted Box',
};
export function getAnnoTypeDisplayName(annoType: AnnotationType) {
  return TYPE_TO_DISPLAY_NAME[annoType] ?? annoType;
}

export const addLegacyImageAnnotations = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  defaultHeader: BottomHeader[],
  labelInterface: LegacyLabelInterface,
): BottomHeader[] => {
  let header = defaultHeader;
  const hasObject = LabelInterfaceUtils.hasObjectLegacy(labelInterface);
  const hasCategory = LabelInterfaceUtils.hasCategoryLegacy(labelInterface);
  if (!hasObject && !hasCategory) return [];
  const objectNames = [] as BottomHeader[];

  if (hasObject) header = concat(header, summaryCell('objectCounts'));
  if (hasCategory) header = concat(header, summaryCell('categoryCounts'));
  // header = _.concat(header, [summaryCell('timespentSec'), summaryCell(TIME_PER_LABEL)]);
  const annotationTypes = new Set();

  if (hasObject) {
    for (let i = 0; i < labelInterface?.objects.length; i++) {
      const obj = labelInterface?.objects[i];
      const displayObjName = obj?.name;
      const displayObjType = Object.keys(obj?.info?.shapes)[0].toUpperCase();
      const objName = camelCase(displayObjName.toLowerCase());
      const objType = camelCase(displayObjType.toLowerCase()) as AnnotationType;
      objectNames[i] = {
        ...OBJECT_CELL,
        id: objName,
        name: displayObjName,
        annoType: objType,
      };
      if (!annotationTypes.has(objType)) {
        annotationTypes.add(objType);
      }
    }
  }

  // ex. ['Box Counts', 'Polygon Counts', etc.]
  const annoTypeHeader = Array.from(annotationTypes).map(annoType => {
    const annoTypeHead = TYPE_TO_DISPLAY_NAME[annoType as AnnotationType] as string;
    return {
      ...ANNOTATION_TYPE_CELL,
      id: `${camelCase(annoTypeHead)}Type`,
      name: annoTypeHead,
    };
  });

  return concat(header, annoTypeHeader, objectNames);
};

const displayNameWeakMap = new WeakMap();
function objectClassDisplayNames(labelInterface: LabelInterface): Record<string, string> {
  const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
  if (!displayNameWeakMap.has(labelInterface)) {
    displayNameWeakMap.set(
      labelInterface,
      objects.reduce((agg: Record<string, string>, item: ObjectClass) => {
        const classId = camelCase(item?.name.toLowerCase());
        const displayName = item?.name;
        agg[classId] = displayName;
        return agg;
      }, {}),
    );
  }
  return displayNameWeakMap.get(labelInterface);
}

const objectTypeWeakMap = new WeakMap<any, Map<any, any>>();
function getObjectType(classId: string, labelInterface: LabelInterface): AnnotationType {
  const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
  if (
    objectTypeWeakMap.has(labelInterface) &&
    objectTypeWeakMap.get(labelInterface)?.has(classId)
  ) {
    return objectTypeWeakMap.get(labelInterface)?.get(classId) as AnnotationType;
  } else {
    if (objectTypeWeakMap.has(labelInterface)) {
      const objectType = objects
        .find(({ name }) => name.toLowerCase() === classId)
        ?.annotationType.toLowerCase();
      const map = objectTypeWeakMap.get(labelInterface);
      map?.set(classId, objectType);
      return objectType as AnnotationType;
    } else {
      const objectType = objects
        .find(({ name }) => name.toLowerCase() === classId)
        ?.annotationType.toLowerCase();
      const map = new Map();
      map.set(classId, objectType);
      objectTypeWeakMap.set(labelInterface, map);
      return objectType as AnnotationType;
    }
  }
}

export const sharedColumnNames = (): Record<string, string> => {
  return {
    name: columnText('name'),
    email: columnText('email'),
    role: columnText('role'),
    labelCounts: columnText('labels'),
    progress: columnText('progress'),
    timespentSec: columnText('timespentSec'),
    [TIME_PER_LABEL]: columnText('timePerLabelSec'),
    objectCounts: columnText('objectCounts'),
    categoryCounts: columnText('categoryCounts'),
  };
};

const defaultColumnsWeakMap = new WeakMap();
export const imageColumnsFn = (columnId: string, labelInterface: ImageLabelInterface): string => {
  if (!defaultColumnsWeakMap.has(labelInterface)) {
    defaultColumnsWeakMap.set(labelInterface, {
      ...(objectClassDisplayNames(labelInterface) || {}),
      ...sharedColumnNames(),
      objectCounts: columnText('framewiseObjectCount'), // framewise object counts: Total Objects
      frameCounts: columnText('frames'),
      frameProgress: columnText('frameProgress'),
    });
  }

  return defaultColumnsWeakMap.get(labelInterface)[columnId];
};

export const videoSiestaDisplayName = (
  columnId: string,
  labelInterface: VideoLabelInterface,
): string => {
  if (!defaultColumnsWeakMap.has(labelInterface)) {
    defaultColumnsWeakMap.set(labelInterface, {
      ...(objectClassDisplayNames(labelInterface) || {}),
      ...sharedColumnNames(),
      objectCounts: columnText('framewiseObjectCount'), // framewise object counts: Total Objects
      frameCounts: columnText('frames'),
      frameProgress: columnText('frameProgress'),
    });
  }

  return defaultColumnsWeakMap.get(labelInterface)[columnId];
};

const getObjectClassColsFromLabelInterface = (
  labelInterface: LabelInterface,
  displayNameFn: (id: string) => string,
) => {
  const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
  const objectTypeFn = partialRight(getObjectType, labelInterface);

  return objects.map(obj =>
    objectCell({
      obj,
      displayNameFn,
      objectTypeFn,
    }),
  );
};

// When object_classes_display_names is included in the API response, use it
// to generate object class columns. Otherwise, use the object classes from the label interface
export type ClassesHeaderMapper = Record<string, { annotationType: AnnotationType; name: string }>;

const getObjectClassColsFromMapper = (mapper: ClassesHeaderMapper) => {
  const classes = Object.keys(mapper);

  return classes.map(classKey => {
    return {
      ...OBJECT_CELL,
      id: classKey,
      name: mapper[classKey].name,
      annoType: mapper[classKey].annotationType,
    };
  });
};

const getAnnoTypeCols = (labelInterface: LabelInterface) => {
  const types = LabelInterfaceUtils.getAnnotationTypes(labelInterface);
  return types.map(type => {
    return annoTypeCell(type);
  });
};

interface FnProps {
  header: BottomHeader[];
  isVideo: boolean;
  hasCategory: boolean;
  hasObject: boolean;
  hasTime: boolean;
  displayNameFn: (id: string) => string;
  labelInterface: LabelInterface;
  objectClassesDisplayNames?: Record<string, { annotationType: AnnotationType; name: string }>;
}

const updateHeader = (props: FnProps): BottomHeader[] => {
  const {
    header,
    isVideo,
    hasCategory,
    hasObject,
    hasTime,
    displayNameFn,
    labelInterface,
    objectClassesDisplayNames,
  } = props;

  let result = header;
  if (isVideo)
    result = concat(
      result, //summaryCell('frameProgress', displayNameFn),
      summaryCell('frameCounts', displayNameFn),
    );
  if (hasObject) result = concat(result, summaryCell('objectCounts', displayNameFn));
  if (hasCategory) result = concat(result, summaryCell('categoryCounts', displayNameFn));
  if (hasTime) {
    result = concat(result, summaryCell('timespentSec', displayNameFn));
    result = concat(result, summaryCell(TIME_PER_LABEL, displayNameFn));
  }
  if (getAnnoTypeCols(labelInterface)) {
    result = concat(result, getAnnoTypeCols(labelInterface));
  }
  if (hasObject) {
    const objCols = objectClassesDisplayNames
      ? getObjectClassColsFromMapper(objectClassesDisplayNames)
      : getObjectClassColsFromLabelInterface(labelInterface, displayNameFn);
    result = concat(result, objCols);
  }
  return result;
};

interface ObjFnProps {
  obj: ObjectClass;
  displayNameFn: (classId: string) => string;
  objectTypeFn: (classId: string) => AnnotationType;
}

const objectCell = (props: ObjFnProps) => {
  const { obj, displayNameFn, objectTypeFn } = props;
  const className = camelCase(obj.name.toLowerCase());
  return {
    ...OBJECT_CELL,
    id: className,
    name: displayNameFn(className),
    annoType: objectTypeFn(className),
  };
};

const annoTypeCell = (annoTypeSetting: AnnotationType) => {
  function formatId(type: string) {
    if (type === 'rbox') return 'rotatedBoxType'; // special case
    return `${camelCase(type)}Type`;
  }
  return {
    ...ANNOTATION_TYPE_CELL,
    id: formatId(annoTypeSetting),
    name: getAnnoTypeDisplayName(annoTypeSetting),
  };
};

// For Download
const getColumnIdToName = (columns: BottomHeader[]): JsonObj => {
  return reduce(
    columns,
    (acc, item) => {
      acc[item.id] = item.group === 'objectClass' ? `${item.name} (${item.annoType})` : item.name;
      return acc;
    },
    {} as any,
  );
};

const translateColumns = (columnIdToName: JsonObj, t: TFunction): JsonObj => {
  // hacky way to check that string is i18n translatable
  return mapValues(columnIdToName, col => (col && col.includes('.') ? t(col) : col));
};

/**
 * Generate user report columns for video-siesta workapp.
 */
export const addVideoAnnotations = (
  defaultHeader: BottomHeader[],
  labelInterface: VideoLabelInterface,
  objectClassesDisplayNames?: ClassesHeaderMapper,
): BottomHeader[] => {
  const header = defaultHeader;

  const hasObject = LabelInterfaceUtils.videoHasObject(labelInterface);
  const hasCategory = LabelInterfaceUtils.hasCategory(labelInterface);
  if (!hasObject && !hasCategory) {
    return [];
  }
  const displayNameFn = partialRight(videoSiestaDisplayName, labelInterface);
  return updateHeader({
    header: header,
    isVideo: true,
    hasCategory,
    hasObject,
    hasTime: true,
    labelInterface,
    displayNameFn,
    objectClassesDisplayNames,
  });
};

export const addImageSiestaAnnotations = (
  defaultHeader: BottomHeader[],
  labelInterface: ImageLabelInterface,
  objectClassesDisplayNames: ClassesHeaderMapper,
): BottomHeader[] => {
  const header = defaultHeader;
  const hasObject = LabelInterfaceUtils.hasObject(labelInterface);
  const hasCategory = LabelInterfaceUtils.hasCategory(labelInterface);
  if (!hasObject && !hasCategory) return [];

  const displayNameFn = partialRight(imageColumnsFn, labelInterface);
  return updateHeader({
    header: header,
    isVideo: false,
    hasCategory,
    hasObject,
    hasTime: true,
    labelInterface,
    displayNameFn,
    objectClassesDisplayNames,
  });
};

/**
 * Returns labeler's review column headers. `id`s are keys from Tricolore service.
 * Labeler's Approved + Rejected + Pending Review  labeler's total `Labels`.
 */
const addLabelerReviewStats = (colHeader: BottomHeader[]): BottomHeader[] => {
  const reviewColumns = [
    { ...REVIEW_CELL, id: camelCase('Approved Counts'), name: columnText('approved') },
    { ...REVIEW_CELL, id: camelCase('Rejected Counts'), name: columnText('rejected') },
    { ...REVIEW_CELL, id: camelCase('Unreviewed Counts'), name: columnText('pendingReview') },
    { ...REVIEW_CELL, id: REVIEW_HISTORY_PERCENT, name: columnText('reviewHistoryPercent') },
    { ...REVIEW_CELL, id: REJECTION_HISTORY_PERCENT, name: columnText('rejectHistoryPercent') },
    { ...REVIEW_CELL, id: AVG_REVIEW_ROUND, name: columnText('avgReviewRound') },
  ];

  return concat(colHeader, reviewColumns);
};

/**
 * User Report currently supports these workapps:
 *   - video-siesta
 *   - image-siesta
 *   - image-default (will be deprecated, likely in 2021) 2/26/2021
 *   - Note: does not support custom workapps (Ex. image-ssf)
 */
interface FnParams {
  labelInterface: LabelInterface | VideoLabelInterface | LegacyLabelInterface;
  showAnnotationType?: boolean; // user customization on annotation type visibility
  reviewVisible?: boolean; // TODO (ml) - remove if review feature flag if removed
  workapp?: WorkApp;
  t?: (path: string) => string;
  objectClassesDisplayNames?: ClassesHeaderMapper;
}

export const getLabelerTableBottomHeader = (params: FnParams): BottomHeader[] => {
  const { labelInterface, workapp, reviewVisible, objectClassesDisplayNames } = params;
  const displayNames = sharedColumnNames();
  let header = [] as BottomHeader[];
  const defaultHeader = [
    { ...INFO_CELL, id: 'name', name: displayNames['name'] },
    { ...INFO_CELL, id: 'email', name: displayNames['email'], hidden: true },
    { ...INFO_CELL, id: 'role', name: displayNames['role'] },
    { ...SUMMARY_CELL, id: 'progress', name: displayNames['progress'] },
    { ...SUMMARY_CELL, id: 'labelCounts', name: displayNames['labelCounts'] },
  ];

  if (!workapp || !isWorkappSupported(workapp)) return [];

  if (WorkappUnion.isVideoSiesta(workapp)) {
    header = addVideoAnnotations(
      defaultHeader,
      labelInterface as VideoLabelInterface,
      objectClassesDisplayNames,
    );
  } else if (WorkappUnion.isPointcloudsSiesta(workapp)) {
    header = addVideoAnnotations(
      defaultHeader,
      labelInterface as VideoLabelInterface,
      objectClassesDisplayNames,
    );
  } else if (WorkappUnion.isImageDefault(workapp)) {
    header = addLegacyImageAnnotations(defaultHeader, labelInterface as LegacyLabelInterface);
  } else if (WorkappUnion.isImageSiesta(workapp)) {
    header = addImageSiestaAnnotations(
      defaultHeader,
      labelInterface as ImageLabelInterface,
      objectClassesDisplayNames,
    );
  }

  if (reviewVisible) header = addLabelerReviewStats(header);
  return header;
};

export const getLabelerTableTopHeader = (bottomHeader: BottomHeader[]): LabelerTopHeaderCell[] => {
  /** Count header for each group */
  const columnsInGroup = {
    [columnGroupKey('labeler')]: 0,
    [columnGroupKey('summary')]: 0,
    [columnGroupKey('annoType')]: 0,
    [columnGroupKey('objectClass')]: 0,
    [columnGroupKey('reviewResults')]: 0,
  };
  bottomHeader.forEach(cell => {
    if (cell?.hidden) return;
    if (cell?.group) {
      columnsInGroup[cell.group as keyof typeof columnsInGroup] += 1;
    }
  });

  return Object.entries(columnsInGroup).flatMap(([group, numColumns], i) => {
    return numColumns > 0
      ? {
          groupKey: group,
          displayName: columnGroupText(group),
          count: numColumns,
          color: CELL_COLORS[i % 2],
        }
      : [];
  });
};

export const columnIdToNameMapper = (params: {
  bottomHeader: BottomHeader[];
  t: TFunction<'translation', undefined>;
}) => {
  const { bottomHeader, t } = params;

  /** Make mapping of column id to names (used for download) */
  return translateColumns(getColumnIdToName(bottomHeader), t);
};

/**
 * To each object in array of row objects, add backgroundColor attribute.
 */
export const addColor = (sortedRows: JsonObj[], isDesc: boolean): JsonObj[] => {
  if (isEmpty(sortedRows) || sortedRows.length < 5) {
    return sortedRows;
  }
  const start = first(sortedRows)?.labelCounts;
  const end = last(sortedRows)?.labelCounts;

  // green, desc
  const colorScaleDesc = scaleLinear()
    .domain([start, end])
    // @ts-ignore: Incorrect range data type (string is fine)
    .range(colorGreenRange({ lightToDark: false }));

  // red, asc
  const colorScaleAsc = scaleLinear()
    .domain([start, end])
    // @ts-ignore: Incorrect range data type (string is fine)
    .range(colorRedRange({ lightToDark: false }));

  return map(sortedRows, row => {
    return {
      ...row,
      backgroundColor: isDesc ? colorScaleDesc(row.labelCounts) : colorScaleAsc(row.labelCounts),
    };
  });
};

export const addMemberNameAndRole = (
  members: JsonObj[],
  userEmailToProfile: UserProfileObject,
): JsonObj[] => {
  const getRole = (profile: MemberData): string => profile?.projectRole || profile?.role;

  return members.map(member => {
    const profile = userEmailToProfile[member.email];
    const role: string = getRole(profile);
    return {
      ...member,
      name: (profile?.name || member.email) as string,
      role: role === 'worker' ? 'labeler' : role,
    };
  });
};

export const isReportState = (resp: JsonObj, status: string): boolean => {
  return resp?.result?.state === status;
};

export function transformToAOA(
  rows: JsonObj[],
  columnOrder: string[],
  bottomHeader: JsonObj,
): BaseDatum[] {
  const result = rows.map(row => {
    return columnOrder.map(field => row[field] || 0);
  });

  return [bottomHeader, ...result];
}

export function transformToAOANoHeader(rows: JsonObj[], columnOrder: string[]): BaseDatum[] {
  return rows.map(row => {
    return columnOrder.map(field => row[field] || 0);
  });
}

export const isWorkappSupported = (workapp: WorkApp) => {
  return (
    WorkappUnion.isImageDefault(workapp) ||
    WorkappUnion.isImageSiesta(workapp) ||
    WorkappUnion.isVideoSiesta(workapp) ||
    WorkappUnion.isPointcloudsSiesta(workapp)
  );
};

export const prepForDownload = (rows: LabelerDatum[]): LabelerDatum[] => {
  return rows.map(row => {
    return { ...row, role: row?.role ? upperFirst(row?.role) : 'n/a' };
  });
};
