import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import * as MUI from '@mui/material';
import { trim, trimStart } from 'lodash';

import { getNamingRuleErrorMessage } from '../../../../configs/NamingRulesConfig';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useDebounce } from '../../../../hooks/DebounceHook';
import ProjectService from '../../../../services/ProjectService';
import Input from '../Input';
import { EDIT_PROJECT_PATH } from '../paths';
import Title from '../Title';

const ProjectName = (): React.ReactElement => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const newProjectInfo = useNewProjectInfo();
  const {
    projectName,
    setProjectName,
    isRequiredCheckDuplicateName,
    setIsRequiredCheckDuplicateName,
    projectNameErrorMessages,
    setProjectNameErrorMessages,
  } = newProjectInfo;

  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;
  const projectNameInputRef = useRef(null);
  const debouncedSearchTerm = useDebounce(projectName, 300);

  const checkProjectNameDuplicated = async () => {
    if (projectNameErrorMessages.length > 0) return;

    const parsedValue = trim(debouncedSearchTerm);

    if (isEditPage && parsedValue === projectName) {
      setIsRequiredCheckDuplicateName(false);
      return;
    }

    if (parsedValue.length < 4 || parsedValue.length > 50) return;

    try {
      await ProjectService.getProjectByName({
        projectName: parsedValue,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setProjectNameErrorMessages(['You already have a project with the same name.']);
      setTimeout(() => {
        setIsRequiredCheckDuplicateName(false);
      }, 0);
    } catch (err: any) {
      setIsRequiredCheckDuplicateName(false);
    }
  };

  useEffect(() => {
    checkProjectNameDuplicated();
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isRequiredCheckDuplicateName) setIsRequiredCheckDuplicateName(true);
    const { value } = e.target;

    const parsedValue = trimStart(value);
    setProjectName(parsedValue);
    setProjectNameErrorMessages(
      getNamingRuleErrorMessage({ str: parsedValue, target: 'project', canOnlyUseASCII: true }),
    );
  };

  return (
    <MUI.Box mb="40px">
      <Title title={t('projects.table.projectName')} />
      <MUI.Box mb="8px" />
      <Input
        inputRef={projectNameInputRef}
        value={projectName}
        errorMessages={projectNameErrorMessages}
        onChange={handleChange}
        placeholder={t('projectSettings.configuration.namePlaceholder')}
      />
    </MUI.Box>
  );
};

export default ProjectName;
