import { map, pick } from 'lodash';

import { AssigneeLabelStatusDto } from '../dataTypes/analyticsDataTypes';
import { JsonObj } from './types';

export const matchV1ChartData = (labeler: JsonObj): AssigneeLabelStatusDto => {
  return {
    assignee: labeler?.email || 'N/a',
    cumSubmitted: labeler.submitted || 0,
    cumSkipped: labeler.skipped || 0,
    cumWorking: labeler.working || 0,
  };
};

export const extractV2ChartData = (resp: JsonObj): JsonObj => {
  const chartColumns = ['email', 'submitted', 'skipped', 'working'];
  const data = resp?.result?.info?.data;
  return map(data, labeler => matchV1ChartData(pick(labeler, chartColumns)));
};

export const getReportChartData = (resp: JsonObj): AssigneeLabelStatusDto[] => {
  // TODO: add explicit versioning (v1, v2)
  return resp?.result?.info?.dataSummary?.results || extractV2ChartData(resp) || [];
};
