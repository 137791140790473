import React from 'react';

import { Box, IconButton, Tooltip, Typography } from '@superb-ai/norwegian-forest';

type Props = {
  overview: JSX.Element;
  title: string;
  titleButton?: JSX.Element;
  infoMessage?: JSX.Element | string;
};

export default function DescriptionAndReport({
  overview,
  title,
  titleButton,
  infoMessage,
}: React.PropsWithChildren<Props>) {
  return (
    <>
      <Box py={2} bb display="flex" alignItems="center">
        <Typography variant="headline3">{title}</Typography>
        <Box display="flex" alignItems="center" gap="8px" ml="auto">
          {infoMessage && (
            <Tooltip anchorEl={<IconButton icon="infoCircleOutline" color="grey" />}>
              {infoMessage}
            </Tooltip>
          )}
          {titleButton}
        </Box>
      </Box>
      <Box py={2.5}>{overview}</Box>
    </>
  );
}
