import { useParams } from 'react-router';

import { AppRouteLayoutProps, AppRoutes, PageWithMenu } from '../../../../../menu';
import { DatasetProvider } from '../../../contexts/DatasetContext';
import { EmbeddingMonitoringProvider } from '../../../contexts/EmbeddingMonitoringContext';
import { DatasetModalProvider } from '../../../contexts/ModalContext';
import { PublicDatasetProvider } from '../../../contexts/PublicDatasetContextProvider';
import { SampleDataset } from '../../../types/routeTypes';
import { DatasetMenu } from './Menu';
import SampleDatasetPage from './SampleDatasetPage';

export default function DatasetRoute({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const { datasetPool } = useParams<{ datasetPool: string }>();

  return (
    <PublicDatasetProvider>
      <DatasetProvider>
        <EmbeddingMonitoringProvider>
          <DatasetModalProvider>
            {datasetPool === SampleDataset ? (
              <SampleDatasetPage menu={<DatasetMenu menuItem={menuItem} />}>
                <AppRoutes items={menuItem.children ?? []} />
              </SampleDatasetPage>
            ) : (
              <PageWithMenu
                menu={<DatasetMenu menuItem={menuItem} />}
                pageBoxProps={{ p: 0, mb: 0 }}
              >
                <AppRoutes items={menuItem.children ?? []} />
              </PageWithMenu>
            )}
          </DatasetModalProvider>
        </EmbeddingMonitoringProvider>
      </DatasetProvider>
    </PublicDatasetProvider>
  );
}
