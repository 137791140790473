import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import helper from './helper';
import ImageCategoryItem from './ImageCategoryItem';

const useStyles = makeStyles(() => ({
  box: {
    width: '310px',
    height: '100%',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
}));

const ObjectClassList = (): React.ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const listRef = useRef<HTMLDivElement>();
  const [isPressDelete, setIsPressDelete] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedImageCategory = () => {
    if (newProjectInfo.selectedImageCategoryIndex === -1) return;
    if (Object.keys(newProjectInfo.selectedCategories).length !== 0) return;
    if (newProjectInfo.selectedCategoryGroupId !== '') return;

    helper.deleteImageCategory(newProjectInfo);
  };

  useEffect(() => {
    if (!isPressDelete) return;
    setIsPressDelete(false);

    deleteSelectedImageCategory();
    // eslint-disable-next-line
  }, [isPressDelete]);

  const handleClickList = () => {
    newProjectInfo.setSelectedImageCategoryIndex(-1);
    newProjectInfo.setSelectedCategories({});
    newProjectInfo.setSelectedCategoryGroupId('');
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;

      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClickAdd = () => {
    if (newProjectInfo.selectedDataType === 'image' && newProjectInfo.imageCategories.length >= 1) {
      enqueueSnackbar('You can only add 1 categorization task using current image tool', {
        variant: 'warning',
      });
      return;
    }

    helper.createImageCategory(newProjectInfo);
    scrollToBottom();
  };

  const handleClickDelete = () => {
    if (newProjectInfo.selectedImageCategoryIndex === -1) return;
    helper.deleteImageCategory(newProjectInfo);
  };

  const handleScroll = () => {
    if (!listRef || !listRef.current) return;

    let nextScrollTop = listRef.current.scrollTop;
    nextScrollTop = typeof nextScrollTop === 'number' ? nextScrollTop : 0;
    setScrollTop(nextScrollTop);
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer
        useHeader
        title={
          WorkappUnion.isImageSequence(newProjectInfo.selectedDataType)
            ? t('projectSettings.configuration.videoCategory')
            : t('projectSettings.configuration.imageCategory')
        }
        disabledStrings={
          newProjectInfo.imageCategories.length === 0
            ? [t('projectSettings.configuration.imageCategoryClickHere')]
            : []
        }
        handleClickDisabledBox={handleClickAdd}
        handleClickAdd={handleClickAdd}
        // handleClickCopy={handleClickCopy}
        handleClickDelete={handleClickDelete}
        handleScroll={handleScroll}
      >
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox} onClick={handleClickList}>
          {newProjectInfo.imageCategories.map((imageCategory: any, index: number) => (
            <ImageCategoryItem
              key={imageCategory.id}
              index={index}
              imageCategory={imageCategory}
              scrollTop={scrollTop}
              listHeight={600}
            />
          ))}
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default ObjectClassList;
