import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Box } from '@superb-ai/norwegian-forest';
import cn from 'classnames';

import { useAuthInfo } from '../../contexts/AuthContext';

export interface UserInfoInUserAvatar {
  avatarUrl?: string | null;
  name?: string | null;
  email?: string | null;
  status?: string;
}

interface Props {
  userInfo: UserInfoInUserAvatar;
  size: number;
  iconSize?: number;
  margin?: string | number;
  noShadow?: boolean;
  color?: string;
}
interface StyleProps {
  size: number;
  margin: string | number;
  boxShadow: string;
}

const useStyles = makeStyles(() => ({
  avatar: (props: StyleProps) => ({
    boxShadow: props.boxShadow,
    width: props.size,
    minWidth: props.size,
    height: props.size,
    margin: props.margin,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    borderRadius: '100%',
  }),
  textAvatar: (props: StyleProps) => ({
    fontSize: `${Math.ceil(props.size * 0.6)}px`,
    lineHeight: `${props.size}px`,
    color: '#333',
    boxSizing: 'border-box',
    position: 'relative',
    textAlign: 'center',
  }),
}));

function hashCode(str: string): number {
  return Math.abs(
    str.split('').reduce((prevHash, currVal) => (prevHash + currVal.charCodeAt(0)) | 0, 0),
  );
}

const colors = [
  'coral',
  'carrot',
  'yellow',
  'lime',
  'green',
  'aqua',
  'skyblue',
  'cobalt',
  'violet',
  'purple',
  'pink',
  'mint',
];

const UserAvatar: React.FC<Props> = ({ userInfo, size, margin, noShadow, color }) => {
  const authInfo = useAuthInfo();
  const theme = useTheme();
  const classes = useStyles({
    size,
    margin: typeof margin === 'undefined' ? `0 ${size * 0.4}px 0 0` : margin,
    boxShadow: noShadow ? 'none' : theme.shadows[3],
  });

  const user = !userInfo ? authInfo : userInfo;
  const { avatarUrl, name, email } = user;
  const isPending = (user as Props['userInfo']).status === 'Invited';

  if (avatarUrl) {
    return <MUI.Avatar className={cn(classes.avatar, 'user-avatar')} src={avatarUrl} />;
  }

  const initial = isPending ? '' : (name || email || '').charAt(0).toLocaleUpperCase();
  // Color based on hash of email
  const colorIndex = hashCode(email || '') % colors.length;
  const bgColor = color ?? [isPending ? 'cloud' : colors[colorIndex], 30];

  return (
    <Box
      themedBackgroundColor={bgColor}
      className={cn(classes.avatar, classes.textAvatar, 'user-avatar')}
    >
      {initial}
    </Box>
  );
};

export default UserAvatar;
