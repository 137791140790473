import { PAGE_TRACKING_ID } from '../analyticsTracker/pageIds';
import Plan from '../components/pages/plan';
import { CreateMenuItem } from '../menu/MenuItem';
import { AppsMenuItem } from './Apps/AppsMenuItem';
import { CurateMenuItem } from './Curate/components/CurateMenuItem';
import { IntegrationsMenuItem } from './integrations/MenuItem';
import { LabelMenuItem } from './LabelMenuItem';
import Layout from './Layout';
import { ModelMenuItem } from './Model/ModelMenuItem';
import { SettingsMenuItem } from './settings/MenuItem';
import { UsersMenuItem } from './users/MenuItem';

const PlanMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountPlan,
  title: 'Plan',
  path: 'plan',
  component: Plan,
  isVisible: false,
});

export const AccountMenu = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.account,
  path: '',
  component: Layout,
  children: [
    LabelMenuItem,
    CurateMenuItem,
    ModelMenuItem,
    AppsMenuItem,
    UsersMenuItem,
    IntegrationsMenuItem,
    SettingsMenuItem,
    PlanMenuItem,
  ],
});
