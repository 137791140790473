import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';

type OptionType = {
  id: string;
  name: string;
  checked: boolean;
};

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    justifyContent: 'flex-start',
  },
  radio: {
    padding: '2px 0px 2px 0px ',
    marginRight: '8px',
  },
  defaultText: {
    color: '#ff625a',
    fontSize: '8px',
    marginRight: '8px',
  },
  deleteButton: {},
  deleteIcon: {
    width: '12px',
    height: '12px',
    color: '#616161',
  },
}));

// eslint-disable-next-line
const ClassPropertyItemMultipleItem = ({
  options,
  option,
  type,
  propertyIndex,
  index,
}: {
  options: OptionType[];
  option: OptionType;
  type: string;
  propertyIndex: number;
  index: number;
}): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Option name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const sameHierarchyNameList = options.map((item: any) => item.name);

    return parentHelper.checkNameRule({
      t,
      type: 'Class property name',
      prevValue: option.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
    });
  };

  const editName = (value: string) => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    objectClass.properties[propertyIndex].options.MULTIPLE_CHOICE[index].name = value;
    objectClass.properties[propertyIndex].options.MULTIPLE_SELECTION[index].name = value;
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  const handleClickDelete = () => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    objectClass.properties[propertyIndex].options.MULTIPLE_CHOICE.splice(index, 1);
    objectClass.properties[propertyIndex].options.MULTIPLE_SELECTION.splice(index, 1);
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  const getCheckbox = () => {
    const handleClick = () => {
      const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
      const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];

      const options = objectClass.properties[propertyIndex].options[type];

      if (type === 'MULTIPLE_CHOICE') {
        if (options[index].checked) {
          options[index].checked = false;
        } else {
          for (let i = 0; i < options.length; i++) {
            if (i === index) {
              options[i].checked = true;
            } else {
              options[i].checked = false;
            }
          }
        }
      } else {
        options[index].checked = !options[index].checked;
      }

      newProjectInfo.setObjectClasses(nextObjectClasses);
    };

    if (type === 'MULTIPLE_CHOICE') {
      return (
        <MUI.Radio
          className={classes.radio}
          color="primary"
          checked={option.checked}
          size="small"
          onClick={handleClick}
        />
      );
    }
    return (
      <MUI.Checkbox
        className={classes.radio}
        color="primary"
        checked={option.checked}
        size="small"
        onClick={handleClick}
      />
    );
  };

  return (
    <MUI.Box key={option.id} className={classes.option}>
      {getCheckbox()}
      <MUI.Box mr="10px" width="250px">
        <EditableText
          text={option.name}
          maxWidth="250px"
          fontStyles={{
            fontSize: '12px',
            color: '#4a4a4a',
          }}
          parseValue={parseValue}
          checkRule={checkNameRule}
          editText={editName}
          useDelete={options.length > 1}
          deleteText={handleClickDelete}
        />
      </MUI.Box>
      {option.checked ? (
        <MUI.Typography className={classes.defaultText}>
          ({t('projects.createProject.default')})
        </MUI.Typography>
      ) : null}
    </MUI.Box>
  );
};

export default ClassPropertyItemMultipleItem;
