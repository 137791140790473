import React from 'react';

import { Chip } from '@superb-ai/ui';

export const VISITED_NEW_FEATURES = 'visited_new_features';
export const NEW_FEATURE_MODEL_DIAGNOSIS = 'model_diagnosis';

export function getIsFeatureVisited({ feature }: { feature: string }) {
  const visitedNewFeatures: string[] = JSON.parse(
    localStorage.getItem(VISITED_NEW_FEATURES) || '[]',
  );
  const isFeatureVisited = visitedNewFeatures.includes(feature);

  return isFeatureVisited;
}

export default function NewFeaturesChip({ feature }: { feature: string }) {
  const isFeatureVisited = getIsFeatureVisited({ feature });
  if (isFeatureVisited) return <></>;
  return <Chip color="skyblue-100">new</Chip>;
}
