import React from 'react';

import * as MUI from '@mui/material';

import { assignee } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterAssigneeWithSearch from './FilterAssigneeWithSearch';
import { FilterTypeProps } from './types';

const FilterAssignee = ({
  index,
  filter,
  isCurrentRow,
  onChangeCurrentRow,
  onValueChange,
}: FilterTypeProps) => {
  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={assignee.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      {(filter.condition === 'is any one of' || filter.condition === 'none of') && (
        <MUI.Grid item style={{ flex: 1 }}>
          <FilterAssigneeWithSearch
            index={index}
            filter={filter}
            isCurrentRow={isCurrentRow}
            onValueChange={onValueChange}
            onChangeCurrentRow={onChangeCurrentRow}
          />
        </MUI.Grid>
      )}
    </>
  );
};

export default FilterAssignee;
