import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, Box } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import FilterDetail from '../../export/exportHistory/FilterDetail';
import { VerticalDivider } from './components/VerticalDivider';

interface Props {
  baseFilter: string | undefined;
  compareFilter: string | undefined;
}

export function Filter({ filter }: { filter: string | undefined }) {
  const { t } = useTranslation();
  return filter ? (
    <FilterDetail filter={filter} showApplyButton={false} />
  ) : (
    <Typography mt={0.5} variant="m-regular" color="gray-300">
      {typeof filter !== 'undefined'
        ? t('analytics.exportsAnalytics.noFilters')
        : t('analytics.exportsAnalytics.informationNotFound')}
    </Typography>
  );
}

export function ExportsAnalyticsAppliedFilter({ baseFilter, compareFilter }: Props) {
  const { t } = useTranslation();

  return (
    <Box py={1} px={2} backgroundColor="#fbfbfb">
      <div style={{ textAlign: 'center', marginRight: '8px' }}>
        <Accordion summary={t('export.history.appliedFilters')}>
          <Box display="grid" style={{ gridTemplateColumns: '1fr 1px 1fr' }} gap={3} pb={1}>
            <Filter filter={baseFilter} />
            <VerticalDivider />
            <Filter filter={compareFilter} />
          </Box>
        </Accordion>
      </div>
    </Box>
  );
}
