import { AxiosError } from 'axios';

import ServiceUtils from '../utils/ServiceUtils';
import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '.';

// refer to: https://linear.app/superb-ai/issue/SE-951/rafiki-ip-whitelist를-위한-model-and-api-추가
const getIpWhitelistEnabled: AfterLoginCallback<AllowIpWhitelistResponse> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/auth/tenants/allow_ip_whitelist',
    hasPublicApi: false,
    ...params,
  });

  return data as AllowIpWhitelistResponse;
};

const setIpWhitelistEnabled: AfterLoginCallback<AllowIpWhitelistResponse> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'put',
    url: '/auth/tenants/allow_ip_whitelist',
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const getMyIpAddress: AfterLoginCallback<GetMyIPResponse> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/auth/tenants/ip-whitelist/my-ip',
    hasPublicApi: false,
    ...params,
  });

  return data;
};

export type AddIpWhitelistParams = {
  ip: string;
  description?: string;
};
const addIpWhitelist: AfterLoginCallback<AddIpWhitelistResponse, AddIpWhitelistParams> = async ({
  data: { ip, description },
  ...params
}) => {
  const errorHandling = (err: AxiosError<any>) => {
    if (err.response?.data.code === '200412') {
      throw new Error('Not support Free and Team tier and flagsmith');
    } else if (err.response?.data.code === '200409') {
      throw new Error('Already exist');
    } else if (err.response?.data.code === '200400') {
      throw new Error('Invalid IP address or description');
    } else {
      throw err;
    }
  };

  const body = {
    ip,
    ...(description && description.length > 0 && { description }),
  };

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/ip-whitelist',
    errorHandling,
    data: body,
    hasPublicApi: false,
    ...params,
  });

  return data;
};
const getIpWhitelist: AfterLoginCallback<
  GetIpWhitelistResponse,
  { limit?: number; lastEvaluatedKey?: string }
> = async ({ data: { limit, lastEvaluatedKey }, ...params }) => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/auth/tenants/ip-whitelist?${ServiceUtils.getParamString({
      limit,
      lastEvaluatedKey,
    })}`,
    hasPublicApi: false,
    ...params,
  });

  return data;
};
const getIpWhitelistById: AfterLoginCallback<GetIpWhitelistByIdResponse, { id: string }> = async ({
  data: { id },
  ...params
}) => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/auth/tenants/ip-whitelist/${id}`,
    hasPublicApi: false,
    ...params,
  });

  return data;
};

export type UpdateIpWhitelistByIdParams = {
  id: string;
  description?: string;
  status?: string;
};
const updateIpWhitelistById: AfterLoginCallback<
  UpdateIpWhitelistByIdResponse,
  UpdateIpWhitelistByIdParams
> = async ({ data: { id, description, status }, ...params }) => {
  const body = {
    ...(description && { description }),
    ...(status && { status }),
  };
  if (Object.keys(body).length === 0) {
    return;
  }

  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/auth/tenants/ip-whitelist/${id}`,
    hasPublicApi: false,
    data: body,
    ...params,
  });

  return data;
};
const deleteIpWhitelistById: AfterLoginCallback<
  DeleteIpWhitelistByIdResponse,
  { id: string }
> = async ({ data: { id }, ...params }) => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: `/auth/tenants/ip-whitelist/${id}`,
    hasPublicApi: false,
    ...params,
  });

  return data;
};

export function useIpWhitelistService() {
  const { afterLoginFetcher } = useFetcher();

  return {
    getIpWhitelistEnabled: afterLoginFetcher(getIpWhitelistEnabled),
    getMyIpAddress: afterLoginFetcher(getMyIpAddress),
    setIpWhitelistEnabled: afterLoginFetcher(setIpWhitelistEnabled),
    addIpWhitelist: afterLoginFetcher(addIpWhitelist),
    getIpWhitelist: afterLoginFetcher(getIpWhitelist),
    getIpWhitelistById: afterLoginFetcher(getIpWhitelistById),
    updateIpWhitelistById: afterLoginFetcher(updateIpWhitelistById),
    deleteIpWhitelistById: afterLoginFetcher(deleteIpWhitelistById),
  };
}

export type AllowIpWhitelistResponse = {
  data: {
    allowIpWhitelist: boolean;
  };
  message: string;
  code: string;
};

/*
{
  "tenant_id": "65465_test",
  "id": "01HK7S0S2SGA51RR6CP04JY985",
  "description": "test",
  "status": "Active",
  "ip": "121.138.214.3/32",
  "info": {
    "is_all_resources": true,
    "include_resources": [],
    "exclude_resources": []
  },
  "created_at": "2024-01-03T13:43:15.801986Z",
  "created_by": "mscha+3@superb-ai.com",
  "updated_at": "2024-01-03T13:43:15.801986Z",
  "updated_by": "mscha+3@superb-ai.com"
}
*/
export type IpWhitelistItem = {
  tenantId: string;
  id: string;
  description: string;
  status: 'Active' | 'Inactive';
  ip: string;
  info: {
    isAllResources: boolean;
    includeResources: string[];
    excludeResources: string[];
  };
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
};

export type AddIpWhitelistResponse = {
  message: 'Created';
  data: IpWhitelistItem;
  code: '200201';
};
export type GetIpWhitelistResponse = {
  data: {
    data: IpWhitelistItem[];
    lastEvaluatedKey: {
      tenantId: {
        s: string;
      };
      uniqueKey: {
        s: string;
      };
    } | null;
    totalCount: number;
  };
  message: 'Success';
  code: '200200';
};
export type UpdateIpWhitelistByIdResponse = {
  data: IpWhitelistItem;
  message: 'Success';
  code: '200200';
};
export type DeleteIpWhitelistByIdResponse = {
  message: string;
  code: '200204';
};
export type GetIpWhitelistByIdResponse = {
  data: IpWhitelistItem;
  message: 'Success';
  code: '200200';
};
export type GetMyIPResponse = {
  data: {
    myIp: string;
  };
  message: 'Success';
  code: '200200';
};
