import React, { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { VerticalStepper } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';
import { isEmpty } from 'lodash';

import { useExportsAnalyticsInfo } from '../../../../../contexts/ExportsAnalyticsContext';
import { HistoryType } from '../../../../../types/exportTypes';
import { formatDateTime } from '../../../../../utils/date';
import { colors } from '../utils/colorScale';
import GenerateModalReportNameInput from './GenerateModalReportNameInput';
import ReportExportList from './ReportExportList';
import ReportIoUThreshold from './ReportIoUThreshold';

interface Props {
  data: HistoryType[];
}

function SelectedExportInfo({ selectedExport }: { selectedExport: HistoryType }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box display="grid">
      <Typography variant="m-regular" color={'gray-300'}>
        {t('analytics.exportsAnalytics.stepper.name')}
        {`: ${selectedExport['name']}`}
      </Typography>
      <Typography variant="m-regular" color={'gray-300'}>
        {t('analytics.exportsAnalytics.stepper.labelCount')}: {selectedExport['labelCount']}
      </Typography>
      <Typography variant="m-regular" color={'gray-300'}>
        {t('analytics.exportsAnalytics.stepper.created')}: {selectedExport['createdBy']},{' '}
        {formatDateTime(selectedExport['createdAt'] as Date)}
      </Typography>
    </Box>
  );
}
const ReportStepper = ({ data }: Props) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completedStep, setCompletedStep] = useState<number>(0);
  const lastStepsIndex = 4; // last step index
  const {
    reportName,
    setReportName,
    boxValue,
    polygonValue,
    ioUOption,
    selectedCompareExportId,
    setSelectedCompareExportId,
    selectedBaseExportId,
    setSelectedBaseExportId,
    recommededOption,
    setSelectedCompareExport,
    selectedCompareExport,
    setSelectedBaseExport,
    selectedBaseExport,
    setIsStepsCompleted,
  } = useExportsAnalyticsInfo();

  const onClickStep =
    (stepIndex: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      setActiveStep(stepIndex);
    };

  const onClickPrev =
    (stepIndex: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setActiveStep(stepIndex - 1);
    };

  const onClickNext =
    (stepIndex: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setActiveStep(stepIndex + 1);
      if (stepIndex > (completedStep || -1)) {
        setCompletedStep(stepIndex);
      }
    };

  useEffect(() => {
    setIsStepsCompleted(activeStep === lastStepsIndex);
  }, [activeStep, lastStepsIndex, setIsStepsCompleted]);

  return (
    <VerticalStepper.Steps>
      <VerticalStepper.Step
        index={0}
        activeStepIndex={activeStep}
        completedStepIndex={completedStep + 1}
        onClickStep={onClickStep}
        title={t('analytics.exportsAnalytics.stepper.name')}
        summary={
          <Typography variant="m-regular" color={'gray-300'}>
            {reportName}
          </Typography>
        }
      >
        <GenerateModalReportNameInput name={reportName} setName={setReportName} />
        <VerticalStepper.StepButtons>
          <VerticalStepper.MainButton disabled={!reportName.length} onClick={onClickNext(0)} />
        </VerticalStepper.StepButtons>
      </VerticalStepper.Step>
      <VerticalStepper.Step
        activeStepIndex={activeStep}
        completedStepIndex={completedStep}
        onClickStep={onClickStep}
        index={1}
        // @ts-ignore: this works, NF typing is wrong
        title={
          <Trans
            t={t}
            i18nKey="analytics.exportsAnalytics.stepper.baseExportTitle"
            components={{
              color: <Typography color={colors.base} />,
            }}
          />
        }
        summary={
          !isEmpty(selectedBaseExport) ? (
            <SelectedExportInfo selectedExport={selectedBaseExport as HistoryType} />
          ) : (
            <Fragment></Fragment>
          )
        }
      >
        <ReportExportList
          items={data}
          selectedItemId={selectedBaseExportId}
          setSelectedItemId={setSelectedBaseExportId}
          setSelectedItem={setSelectedBaseExport}
        />
        <VerticalStepper.StepButtons>
          <VerticalStepper.PrevButton color="grey" onClick={onClickPrev(1)}>
            {t('shared.previous')}
          </VerticalStepper.PrevButton>
          <VerticalStepper.MainButton
            onClick={onClickNext(1)}
            disabled={!selectedBaseExportId.length}
          />
        </VerticalStepper.StepButtons>
      </VerticalStepper.Step>
      <VerticalStepper.Step
        activeStepIndex={activeStep}
        completedStepIndex={completedStep}
        onClickStep={onClickStep}
        index={2}
        // @ts-ignore: this works, NF typing is wrong
        title={
          <Trans
            t={t}
            i18nKey="analytics.exportsAnalytics.stepper.compareExportTitle"
            components={{
              color: <Typography color={colors.compare} />,
            }}
          />
        }
        summary={
          !isEmpty(selectedCompareExport) ? (
            <SelectedExportInfo selectedExport={selectedCompareExport as HistoryType} />
          ) : (
            <></>
          )
        }
      >
        <ReportExportList
          items={data}
          selectedItemId={selectedCompareExportId}
          setSelectedItemId={setSelectedCompareExportId}
          setSelectedItem={setSelectedCompareExport}
          disabledExportId={selectedBaseExportId}
        />
        <VerticalStepper.StepButtons>
          <VerticalStepper.PrevButton color="grey" onClick={onClickPrev(2)}>
            {t('shared.previous')}
          </VerticalStepper.PrevButton>
          <VerticalStepper.MainButton
            onClick={onClickNext(2)}
            disabled={!selectedCompareExportId.length}
          >
            {t('button.done')}
          </VerticalStepper.MainButton>
        </VerticalStepper.StepButtons>
      </VerticalStepper.Step>
      <VerticalStepper.Step
        index={3}
        activeStepIndex={activeStep}
        completedStepIndex={completedStep}
        onClickStep={onClickStep}
        title={t('analytics.exportsAnalytics.stepper.autoLabelPerformance')}
        isOptional
        summary={
          <Box display="grid">
            <Typography variant="m-regular" color={'gray-300'}>
              {ioUOption} {ioUOption === 'Recommended' && `(${recommededOption})`}
            </Typography>
            {ioUOption && (
              <Typography variant="m-regular" color={'gray-300'}>
                Box = {boxValue}, Polygon = {polygonValue}
              </Typography>
            )}
          </Box>
        }
      >
        <ReportIoUThreshold />
        <VerticalStepper.StepButtons>
          <VerticalStepper.PrevButton color="grey" onClick={onClickPrev(3)}>
            {t('shared.previous')}
          </VerticalStepper.PrevButton>
          <VerticalStepper.MainButton
            onClick={onClickNext(3)}
            disabled={Number.isNaN(boxValue) || Number.isNaN(polygonValue)}
          >
            {!ioUOption ? t('button.skip') : t('button.next')}
          </VerticalStepper.MainButton>
        </VerticalStepper.StepButtons>
      </VerticalStepper.Step>
    </VerticalStepper.Steps>
  );
};

export default ReportStepper;
