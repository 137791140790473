import { AnnotationType } from '../../../../../../utils/LabelInterfaceUtils';

export type DataScope =
  | 'objects'
  | 'objectsAll'
  | 'metadataKey'
  | 'metadataValue'
  | 'all'
  | 'dataset'
  | 'slice';

// API response

export type MetadataType = 'String' | 'Number';

export interface MetadataValueDatum {
  value: string;
  count: number;
}

export type MetadataKeyValueDatum = Record<string, AnalyticsResponse<MetadataValueDatum>>;

export type MetadataValueResponse = AnalyticsResponse<MetadataValueDatum> & {
  totalImagecount?: number;
  sumOtherDocCount?: number;
};

export interface MetadataKeyDatum {
  // alias: string; Not included
  key: string;
  type: MetadataType;
  imageCount: number;
  valueCount: number;
}

export type MetadataKeyResponse = AnalyticsResponse<MetadataKeyDatum> & {
  totalImageCount?: number;
};

export interface AnnotationTypeStatsDatum {
  annotationType: AnnotationType;
  count: number;
}

export interface ClassStatsDatum {
  // alias: string; Not included
  name: string;
  annotationCount: number;
  imageCount: number;
  annotationShare?: number;
  imageShare?: number;
}

export interface AnalyticsResponse<T> {
  count: number;
  data: Array<T>;
}

export interface HistogramDatum {
  key: string;
  keyNumeric?: number;
  count: number;
}

export interface AnnotationStatsResponse {
  summaryStats: {
    total: number;
    mean: number;
  };
  histogram: HistogramDatum[];
}

/** Download */
export type AnnotationStatsDownload = {
  annotations_per_image: string;
  image_count: number;
};

export type AnnotationTypesDownload = {
  annotation_type: string;
  annotation_count: number;
};

export type ClassStatsDownload = {
  name: string;
  object_count: number;
  object_share?: number;
  image_count: number;
  image_share?: number;
};

// TODO: fix after API
export interface ClassificationKeyDatum {
  // alias: string; Not included
  name: string;
  type: MetadataType;
  imageCount: number;
  imageShare?: number; // added by the client
}

export type ClassificationKeyResponse = {
  totalImageCount: number;
  data: ClassificationKeyDatum[];
};

export interface ClassificationValueDatum {
  value: string;
  count: number;
  share?: number; // added by the client
}

export type ClassificationValueResponse = {
  data: ClassificationValueDatum[];
  totalImageCount: number;
};

export type ClassificationValueItem = Omit<ClassificationValueDatum, 'share'> & { name: string };
export type ClassificationValueAllResponse = {
  data: ClassificationValueItem[];
  totalImageCount: number;
};
export const ClassificationOptions = {
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  FREE_RESPONSE: 'free response',
};
export type ClassificationType = 'single-select' | 'multi-select' | 'free response';
// classification END
