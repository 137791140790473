import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import flagsmith from 'flagsmith';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { CalAvailabilityProvider } from '../../../contexts/CalAvailabilityContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { FilterProvider } from '../../../contexts/FilterContext';
import { LabelCompareProvider } from '../../../contexts/LabelCompareContext';
import { LabelDetailViewProvider } from '../../../contexts/LabelDetailViewContext';
import { LabelInformationProvider } from '../../../contexts/LabelInformationContext';
import { LabelIssuesProvider } from '../../../contexts/LabelIssuesContext';
import { LabelsProvider } from '../../../contexts/LabelsContext';
import { ProjectProvider, useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { SemanticSearchProvider } from '../../../contexts/SemanticSearchContext';
import LabelsService from '../../../services/LabelsService';
import { useCommandBar } from '../../../utils/external';
import LabelUtils from '../../../utils/LabelUtils';
import Page from './Page';

export default function ({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const { t } = useTranslation();
  const { projectId, accountName } = useParams<{ projectId: string; accountName: string }>();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { urlMatchInfo, setUrlMatchInfo } = routeInfo;
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  useEffect(() => {
    setUrlMatchInfo({ ...urlMatchInfo, projectId });

    return () => {
      setUrlMatchInfo({ accountName: urlMatchInfo.accountName });
    };

    // eslint-disable-next-line
  }, [projectId, urlMatchInfo.accountName, urlMatchInfo.projectId]);

  useEffect(() => {
    flagsmith.setTraits({
      projectId,
      tenantProjectId: `${accountName}/${projectId}`,
    });

    if (useCommandBar) {
      window.CommandBar?.addContext('projectId', projectId);

      const getLabelsWithAssetKey = async (query: string) => {
        if (!projectId) return [];
        const res = await (enabledLoki ? LabelsService.getLabelsV2 : LabelsService.getLabels)({
          params: { [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: query },
          projectId,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        return res.results.map(label => ({
          ...label,
          commandDescription: `${t('analytics.text.label')} (${t(
            LabelUtils.getStatusText(label.status),
          ).toLowerCase()})`,
          query,
        }));
      };

      window.CommandBar?.addContext('getLabelsByKey', [], {
        searchOptions: {
          searchFunction: getLabelsWithAssetKey,
        },
      });
    }

    return () => {
      flagsmith.setTraits({
        projectId: '',
        tenantProjectId: '',
      });
      if (useCommandBar) {
        window.CommandBar?.removeContext('projectId');
        window.CommandBar?.removeContext('getLabelsByKey');
      }
    };
  }, [projectId, authInfo.projectRole]);

  useEffect(() => {
    if (projectId && useCommandBar) {
      window.CommandBar?.addContext('projectRole', authInfo.projectRole);
    }
    return () => {
      if (useCommandBar) {
        window.CommandBar?.removeContext('projectRole');
      }
    };
  }, [projectId, authInfo.projectRole]);

  return (
    <ProjectProvider>
      <LabelsProvider>
        <FilterProvider>
          <LabelDetailViewProvider>
            <LabelInformationProvider>
              <LabelCompareProvider>
                <LabelIssuesProvider>
                  <SemanticSearchProvider>
                    <CalAvailabilityProvider>
                      <Page>{children}</Page>
                    </CalAvailabilityProvider>
                  </SemanticSearchProvider>
                </LabelIssuesProvider>
              </LabelCompareProvider>
            </LabelInformationProvider>
          </LabelDetailViewProvider>
        </FilterProvider>
      </LabelsProvider>
    </ProjectProvider>
  );
}
