import { ScaleOrdinal } from 'd3';
import { TFunction } from 'next-i18next';

import LabelInterfaceUtils, { LabelInterface } from '../../../../utils/LabelInterfaceUtils';
import { getClassAnnotationType } from '../chartContainers/helper';
import { getSiestaObjectClassColors } from '../charts/donutChart/donutChartColors';
import { Mapper } from '../userStats/types';
import { CustomChartSpec } from './chartSpecs';
import { CategoryFields, LabelFields, ObjectFields } from './fields';
import { DataSpec, TonyChartResponse } from './type';

const labelStatusPalette = CustomChartSpec.labelCount.status.colors;

export const getLabelStatusColor = (status: string) => {
  if (!labelStatusPalette) return 'green';
  return labelStatusPalette[status as keyof typeof labelStatusPalette];
};

export function transformDefault(response: TonyChartResponse) {
  return response;
}

type GetOrdinalColors = (values: string[]) => ScaleOrdinal<string, string>;

export function transformTags(response: TonyChartResponse, tagIdToName: Mapper) {
  const colorFn = CustomChartSpec.labelCount[LabelFields.labelTag].colors as GetOrdinalColors;
  const colorMap = colorFn(response.data.map(d => String(d.key)));
  return {
    ...response,
    data: response.data.map(d => {
      return { ...d, color: colorMap(String(d.key)), key: tagIdToName[d.key] as string };
    }),
  };
}

export function transformAssetGroup(response: TonyChartResponse) {
  const colorFn = CustomChartSpec.labelCount[LabelFields.assetGroup].colors as GetOrdinalColors;
  const colorMap = colorFn(response.data.map(d => String(d.key)));
  return {
    ...response,
    data: response.data.map(d => {
      return { ...d, color: colorMap(String(d.key)) };
    }),
  };
}

function transformLabelStatus(response: TonyChartResponse) {
  const colorMap = CustomChartSpec.labelCount.status.colors as Mapper;
  const textMap = CustomChartSpec.labelCount.status.text as Mapper;
  const transformed = {
    ...response,
    data: response.data.map(d => {
      return { ...d, color: colorMap[d.key], key: textMap[d.key] };
    }),
  };
  return transformed;
}

export function transformObjectCounts(
  response: TonyChartResponse,
  idToName: Mapper,
  dataSpec: DataSpec,
  labelInterface: LabelInterface,
) {
  const objectClasses = LabelInterfaceUtils.getObjectClasses(labelInterface);
  const colorMap = getSiestaObjectClassColors(labelInterface);
  const annotationTypeMap = getClassAnnotationType(objectClasses);
  return {
    ...response,
    data: response.data.map(d => {
      return {
        ...d,
        key: idToName?.[d.key] ?? (d.key as string),
        color: colorMap[d.key],
        annotationType: annotationTypeMap[d?.key],
      };
    }),
  };
}

function getCategoryIdToNameMap(labelInterface: LabelInterface) {
  const categoryGroupMap = LabelInterfaceUtils.getCategoryPropertyInfo(labelInterface);
  const categoryIdMap = {} as Record<string, { name: string; categoryGroupId: string }>;

  for (const [categoryGroupId, info] of Object.entries(categoryGroupMap)) {
    info.options.forEach(option => {
      categoryIdMap[option.id] = {
        name: option.name,
        categoryGroupId: categoryGroupId,
      };
    });
  }
  return categoryIdMap;
}

export function transformCategoryCounts(
  response: TonyChartResponse,
  idToName: Mapper,
  dataSpec: DataSpec,
  labelInterface: LabelInterface,
) {
  const colorFn = CustomChartSpec.categoryCount[CategoryFields.categoryIds]
    .colors as GetOrdinalColors;
  const colorMap = colorFn(response.data.map(d => String(d.key)));
  const categoryIdToInfo = getCategoryIdToNameMap(labelInterface);
  return {
    ...response,
    data: response.data.map(d => {
      return {
        ...d,
        color: colorMap(String(d.key)),
        id: d.key,
        key: categoryIdToInfo[d.key]?.name ?? (d.key as string),
        group: categoryIdToInfo[d.key]?.categoryGroupId,
      };
    }),
  };
}

export function TransformFunctionMap(field: string) {
  switch (field) {
    case LabelFields.labelTag:
      return transformTags;
    case LabelFields.assetGroup:
      return transformAssetGroup;
    case LabelFields.status:
      return transformLabelStatus;
    case ObjectFields.classIds:
      return transformObjectCounts;
    case CategoryFields.categoryIds:
      return transformCategoryCounts;
    default:
      return transformDefault;
  }
}

export function getDonutChartLegendTitle(t: TFunction, field: string) {
  switch (field) {
    case LabelFields.labelTag:
      return t('analytics.customizeAnalytics.chart.tags');
    case LabelFields.assetGroup:
      return t('analytics.customizeAnalytics.chart.datasets');
    case LabelFields.status:
      return t('analytics.customizeAnalytics.chart.status');
    case ObjectFields.classIds:
      return t('analytics.customizeAnalytics.chart.classes');
    case CategoryFields.categoryIds:
      return t('analytics.customizeAnalytics.chart.categories');
    default:
      return 'Values';
  }
}
