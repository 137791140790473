import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@superb-ai/norwegian-forest';

import styles from './GoToLabelsButton.module.scss';

interface Props {
  handleOnClick: () => void;
}

export function GoToLabelsButton(props: Props): JSX.Element {
  const { handleOnClick } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const clickBtnProps = {
    color: 'grey',
    size: 'xxs',
    iconPosition: 'left',
    onClick: () => handleOnClick(),
    variant: 'shadow',
  } as const;

  const changeIsHovered = (newState: boolean) => {
    setIsHovered(newState);
  };

  return (
    <div className={styles.button}>
      <Button
        {...clickBtnProps}
        IconAdornment={<Icon name="linkExternal" />}
        onMouseOut={() => changeIsHovered(!isHovered)}
      >
        {t('labels.title')}
      </Button>
    </div>
  );
}
