import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { Layout } from './Layout';

export const IntroMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelIntro,
  path: 'intro',
  title: ({ t }) => t('model.title'),
  component: Layout,
});
