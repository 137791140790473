import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { Layout } from './Layout';

export const TrainModelMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelTrainNewModel,
  path: 'train',
  title: ({ t }) => t('model.train.title'),
  component: Layout,
  children: [],
  isVisible: false,
  icon: 'dashboard',
});
