import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../../../contexts/AppContext';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import ListIsEmpty from '../../ListIsEmpty';
import DataSectionContainer from '../DataSectionContainer';
import ObjectFilterLayout from '../filter/ObjectFilterLayout';
import ViewContentsArea from '../views/ViewContentsArea';

export default function ExploreObjectLayout(): JSX.Element {
  const { t } = useTranslation();
  const { datasetInfo, isLoading } = useDatasetContext();

  useSetPageTitle(t('curate.dataset.menus.explore'), PAGE_TRACKING_ID.curateDatasetExplore);

  if (!datasetInfo) return <></>;

  return (
    <Box display="flex">
      <Box style={{ flex: 1, minWidth: 0 }}>
        <DataSectionContainer
          isLoading={isLoading}
          isEmpty={(datasetInfo.imageCount || 0) <= 0}
          EmptyView={<ListIsEmpty listType="dataset" />}
          ViewContents={<ViewContentsArea />}
        />
      </Box>
      {(datasetInfo.imageCount || 0) > 0 && <ObjectFilterLayout />}
    </Box>
  );
}
