/**
 * @file pageIds.ts
 *
 * This file contains the page tracking IDs for Segment / Mixpanel tracking.
 *
 * Convention:
 *  account-<page-name> for account-level pages (e.g. account-settings)
 *  curate-dataset-<page-name> for curate dataset-level pages (e.g. curate-dataset-settings)
 *  label-project-<page-name> for label project-level pages (e.g. label-project-settings)
 *  label-data-<page-name> for label data-level pages (e.g. label-data-list)
 *  model-<page-name> for model-level pages (e.g. model-overview)
 */
export const PAGE_TRACKING_ID = {
  accountPlan: 'account-plan',
  account: 'account',
  accountIntegrations: 'account-integrations',
  accountIntegrationDetail: 'account-integration-detail',
  accountIntegrationList: 'account-integration-list',
  accountSettings: 'account-settings',
  accountAccessSettings: 'account-access-settings',
  accountMembers: 'account-members', //TODO: check
  accountBillingAndUsage: 'account-billing-and-usage',
  accountSecurity: 'account-security',
  accountProfile: 'account-profile',
  accountUsers: 'account-users',

  curateInitialPage: 'curate-initial-page',
  curateDatasets: 'curate-datasets',
  curateDataset: 'curate-dataset',
  curateDatasetList: 'curate-dataset-list',
  curateSampleDatasets: 'curate-sample-datasets',
  curateSampleDatasetList: 'curate-sample-dataset-list',
  curateTutorial: 'curate-tutorial',
  curateDatasetExplore: 'curate-dataset-explore',
  curateDatasetModelDiagnosis: 'curate-dataset-model-diagnosis',
  curateDatasetSettings: 'curate-dataset-settings',
  curateDatasetSlice: 'curate-dataset-slice',
  curateDatasetSliceList: 'curate-dataset-slice-list',
  curateDatasetAutoCurateReports: 'curate-auto-curate-reports', // TODO: check
  curateModelDiagnosis: 'curate-model-diagnosis',
  curateModelDiagnosisList: 'curate-model-diagnosis-list',

  modelAutoLabelAutoLabelAI: 'model-auto-label-auto-label-ai',
  modelEndpointDetail: 'model-endpoints-detail',
  modelEndpointList: 'model-endpoints-list',
  modelGenerativeAI: 'model-generative-ai',
  modelGenerativeAIList: 'model-generative-ai-list',
  modelGenerativeAIDetail: 'model-generative-ai-detail',
  modelIntro: 'model-intro',
  modelOverview: 'model-overview',
  modelRecognitionAI: 'model-recognition-ai',
  modelRecognitionAIList: 'model-recognition-ai-list',
  modelRecognitionAIDetail: 'model-recognition-ai-detail',
  modelTrainNewModel: 'model-train-new-model',
  modelAutoLabelAI: 'model-auto-label-ai',

  labelInitialPage: 'label-initial-page',
  labelNewProject: 'label-new-project',
  labelEditProject: 'label-edit-project',
  labelDataList: 'label-data-list',
  labelCreateProject: 'label-create-project',
  labelProjectList: 'label-project-list',
  labelProjectAdvancedAI: 'label-project-advanced-ai',
  labelProjectAnalytics: 'label-project-analytics',
  labelProjectCustomCharts: 'label-project-custom-charts',
  labelProjectExportCompare: 'label-project-export-compare',
  labelProjectMyAnalytics: 'label-project-my-analytics',
  labelProjectQAAnalytics: 'label-project-qa-analytics',
  labelProjectUserReports: 'label-project-user-reports',
  labelProjectAutoLabelSettings: 'label-project-auto-label-settings',
  labelProjectDataList: 'label-project-data-list',
  labelProjectExport: 'label-project-export',
  labelProjectExportHistory: 'label-project-export-history',
  labelProjectRawDataDownload: 'label-project-raw-data-download',
  labelProjectLabelList: 'label-project-label-list',
  labelProjectMembers: 'label-project-members',
  labelProjectOverview: 'label-project-overview',
  labelProjectSettings: 'label-project-settings',
  labelProjectAutoLabelIntro: 'label-project-auto-label-intro',
  labelProjectCustomAutoLabel: 'label-project-custom-auto-label',
  labelMislabelDetectionDeprecated: 'label-mislabel-detection-deprecated',
  labelCurationDeprecated: 'label-curation-deprecated',
  labelSemanticSearchDeprecated: 'label-semantic-search-deprecated',

  appsOverview: 'apps-overview',
};
