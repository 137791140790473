import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationsService from '../services/NotificationsService';
import SocketService from '../services/SocketService';
import ServiceUtils from '../utils/ServiceUtils';
import { useAuthInfo } from './AuthContext';
import NotificationsContext from './NotificationsContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

type ContextProps = StateGetterSetter<['socket', 'setSocket'], any>;

const Context = React.createContext({} as ContextProps);

const SocketProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [socket, setSocket] = useState<any>(null);

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const notificationsInfo = React.useContext(NotificationsContext.Context);
  const { accountName, email } = authInfo;
  const { setNotifications, setUnreadCount, setNewNotification } = notificationsInfo;

  useEffect(() => {
    if (authInfo.isGuest) return;

    (async () => {
      const res = await NotificationsService.getNotifications({
        lastCreatedAt: Date.now(),
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const { noti, unread } = res;
      setNotifications(noti || []);
      setUnreadCount(unread || 0);

      const socket = SocketService.connect({ accountName: routeInfo.urlMatchInfo.accountName });
      setSocket(socket);
      SocketService.subscribe(socket, accountName as string, email as string);
      socket.on('message', (msg: string) => {
        setNewNotification(ServiceUtils.toCamelCaseKeys(JSON.parse(msg)));
      });
    })();
    // eslint-disable-next-line
  }, [authInfo.isGuest]);

  return (
    <Context.Provider
      value={{
        socket,
        setSocket,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default {
  Context,
  Provider: SocketProvider,
};
