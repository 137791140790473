import { Trans, useTranslation } from 'react-i18next';

import { WarningOutline } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

export default function PersonalAccessKeyWarning() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      backgroundColor="primary-100"
      p={1}
      width="100%"
      gap={1}
    >
      <Box>
        <Icon icon={WarningOutline} size={16} color="primary-400" />
      </Box>
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey="settings.advanced.personalAccessKeyWraning"
          components={{ highlight: <Typography color="primary-400" variant="m-medium" /> }}
        >
          {t('settings.advanced.personalAccessKeyWraning')}
        </Trans>
      </Typography>
    </Box>
  );
}
