import * as ShyCredit from '@superb-ai/shy-credit';

import { CustomAutoLabelType } from '../../../../../types/customAutoLabelTypes';
import { LabelInterface } from '../../../../../types/projectTypes';
import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import {
  CategoryPropertyList,
  ImageLabelInterface,
  ObjectClass,
  VideoLabelInterface,
} from '../../../../../utils/LabelInterfaceUtils';
import { Engine } from '../settings/helper';

export function getEngineInfoPerClass(
  projectLabelInterface: LabelInterface,
  enginesMap: Map<string, Engine>,
) {
  const projectObjectClasses: ObjectClass[] =
    (projectLabelInterface as ImageLabelInterface)?.objectDetection?.objectClasses ||
    (projectLabelInterface as VideoLabelInterface)?.objectTracking?.objectClasses;

  const projectObjectEngine = new Map(
    projectObjectClasses?.map(objectClass => [
      objectClass.id,
      objectClass.aiClassMap?.map(ai => enginesMap.get(ai.engineId)) ?? [],
    ]),
  );

  return projectObjectEngine;
}

export const getEngineInfoPerProperty = (
  projectLabelInterface: ImageLabelInterface | VideoLabelInterface,
  enginesMap: Map<string, Engine>,
) => {
  // get engine info per class or property from project label interface
  const projectCategorization: CategoryPropertyList =
    projectLabelInterface?.categorization?.properties;
  const projectCategorizationEngine = new Map(
    projectCategorization?.map(property => [
      property.id,
      enginesMap.get(property.aiProperty?.engineId || ''),
    ]),
  );
  return projectCategorizationEngine;
};

export const getNewLabelInterface = ({
  workapp,
  projectLabelInterface,
  checkedCAL,
  customAutoLabel,
}: {
  workapp: WorkApp;
  projectLabelInterface: LabelInterface;
  checkedCAL?: { objectClass?: string[]; categorization?: string[] };
  customAutoLabel: CustomAutoLabelType;
}) => {
  if (WorkappUnion.isImageSiesta(workapp)) {
    const labelInterfaceDef =
      ShyCredit.Models.ImageV2.LabelResultDef.fromJSONObject(projectLabelInterface);
    const objectDetectionDef = ShyCredit.Models.ImageV2.ObjectDetectionDef.fromJSONObject(
      projectLabelInterface.objectDetection,
    );
    const categorizationDef = ShyCredit.Models.ImageV2.CategorizationDef.fromJSONObject(
      projectLabelInterface.categorization,
    );

    const newLabelInterface = {
      ...labelInterfaceDef,
      categorization: {
        ...categorizationDef,
        properties: categorizationDef.properties.map(property => {
          if (checkedCAL?.categorization && checkedCAL.categorization.indexOf(property.id) >= 0) {
            return {
              ...property,
              aiProperty: {
                engineId: customAutoLabel.id,
                propertyId: property.id,
              },
            };
          }
          return property;
        }),
      },
      objectDetection: {
        ...objectDetectionDef,
        objectClasses: objectDetectionDef.objectClasses.map(objectClass => {
          if (checkedCAL?.objectClass && checkedCAL.objectClass.indexOf(objectClass.id) >= 0) {
            return {
              ...objectClass,
              aiClassMap: [
                {
                  engineId: customAutoLabel.id,
                  classIds: [objectClass.id],
                },
              ],
            };
          }
          return objectClass;
        }),
      },
    };

    return newLabelInterface;
  } else if (WorkappUnion.isVideoSiesta(workapp)) {
    const labelInterfaceDef =
      ShyCredit.Models.Video.LabelResultDef.fromJSONObject(projectLabelInterface);
    const objectTrackingDef = ShyCredit.Models.Video.ObjectTrackingDef.fromJSONObject(
      projectLabelInterface.objectDetection,
    );

    const newLabelInterface = {
      ...labelInterfaceDef,
      objectTracking: {
        ...objectTrackingDef,
        objectClasses: objectTrackingDef.objectClasses.map(objectClass => {
          if (checkedCAL?.objectClass && checkedCAL.objectClass.indexOf(objectClass.id) >= 0) {
            return {
              ...objectClass,
              aiClassMap: [
                {
                  engineId: customAutoLabel.id,
                  classIds: [objectClass.id],
                },
              ],
            };
          }
          return objectClass;
        }),
      },
    };

    return newLabelInterface;
  }
};

export const getCurrentEngine = (isSelected: boolean, engineList: string, newEngine: string) => {
  if (!isSelected) return '';
  if (newEngine === engineList) {
    return 'No difference';
  }
  return newEngine;
};

export const isAutoLabelStatus = (autoLabelStatus: string) => {
  return ['PROCESSING', 'COMPLETE', 'FAILED'].includes(autoLabelStatus);
};
