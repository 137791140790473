import { Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import {
  SUBSCRIPTION_WARNING_DAYS,
  trackSubscriptionExpired,
  trackTrialEnded,
  TRIAL_WARNING_DAYS,
} from '../analyticsTracker/commandBar';
import { AccountMenu } from '../apps/AccountMenu';
import { getIsExpiredOrExpiring } from '../components/pages/account/advanced/PersonalAccessKey/util';
import ErrorPage from '../components/pages/error/ErrorPage';
import { AppContextProvider } from '../contexts/AppContext';
import { useAuthInfo } from '../contexts/AuthContext';
import { useErrorInfo } from '../contexts/ErrorContext';
import NotificationsContext from '../contexts/NotificationsContext';
import { useRouteInfo } from '../contexts/RouteContext';
import SocketContext from '../contexts/SocketContext';
import { UsersProvider } from '../contexts/UsersContext';
import { AppRoutes } from '../menu';
import { usePlanExpiration } from '../queries/useSubscriptionQuery';
import TierUnion from '../union/TierUnion';
import { Route as RouteType } from './types';
import { getUrl } from './util';

const URL_MAGIC_ACCOUNT = '@account';

const Main: RouteType<{ accountName: string }> = ({ match }) => {
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const errorInfo = useErrorInfo();
  const history = useHistory();
  const { urlMatchInfo, setUrlMatchInfo } = routeInfo;
  const { t } = useTranslation();

  const plan = usePlanExpiration();

  const trackExpirationCallback = useCallback(() => {
    if (plan && TierUnion.isTeamOrHigher(plan.tier)) {
      if (plan.isTrialPlan && getIsExpiredOrExpiring(plan.trialEndDate, TRIAL_WARNING_DAYS)) {
        trackTrialEnded(t, plan.trialEndDate);
      } else if (getIsExpiredOrExpiring(plan.expirationDate, SUBSCRIPTION_WARNING_DAYS)) {
        trackSubscriptionExpired(t, plan.expirationDate);
      }
    }
  }, [plan, t]);

  useEffect(() => {
    trackExpirationCallback();
  }, [trackExpirationCallback]);

  const accountNameForUrl = (() => {
    if (match.params.accountName && match.params.accountName !== URL_MAGIC_ACCOUNT) {
      return match.params.accountName;
    }
    if (urlMatchInfo.accountName && urlMatchInfo.accountName !== URL_MAGIC_ACCOUNT) {
      return urlMatchInfo.accountName;
    }
    return authInfo.accountName;
  })();

  const isUrlAccountNameChanged =
    !!urlMatchInfo.accountName && accountNameForUrl !== urlMatchInfo.accountName;

  useEffect(() => {
    if (!authInfo.isInitialized) return;

    setUrlMatchInfo({ accountName: accountNameForUrl });

    const isMyAccount = authInfo.accountName === accountNameForUrl;
    if (isMyAccount) {
      authInfo.setIsGuest(false);
    }

    if (!authInfo.isLoggedIn && !accountNameForUrl) {
      const next =
        history.location.pathname && history.location.pathname !== '/null'
          ? history.location.pathname + history.location.search
          : undefined;
      history.push(getUrl('/auth/login', {}, { next }));
    }

    // eslint-disable-next-line consistent-return
    return () => {
      setUrlMatchInfo({});
    };

    // eslint-disable-next-line
  }, [authInfo.isInitialized, accountNameForUrl, authInfo.isLoggedIn]);

  const routerSwitch = () => {
    if (!match.params.accountName) {
      return <Redirect to={authInfo.accountName ? `/${authInfo.accountName}` : '/auth'} />;
    }

    if (match.params.accountName === URL_MAGIC_ACCOUNT && authInfo.accountName) {
      const nextLocation = {
        ...history.location,
        pathname: history.location.pathname.replace(URL_MAGIC_ACCOUNT, authInfo.accountName),
      };
      return <Redirect to={nextLocation} />;
    }

    if (errorInfo.error) return <ErrorPage />;

    if (!urlMatchInfo.accountName || isUrlAccountNameChanged) {
      return <LinearProgress />;
    }

    const urls = history.location.pathname.split('/');
    function getLabelingUrl() {
      if (urls[2] === 'labeling') {
        urls[2] = 'label';
        const newPath = urls.join('/');
        return `${newPath}`;
      }
      urls[0] = urls[1];
      urls[1] = 'label';
      const newPath = urls.join('/');
      return `/${newPath}`;
    }

    const accountMenuPaths = AccountMenu.children?.map(item => item.path);
    if (!accountMenuPaths?.includes(urls[2])) {
      return <Redirect to={getLabelingUrl()} />;
    }

    return (
      <Switch>
        <AppRoutes items={[AccountMenu]} />
        <Redirect path={match.path} to={getLabelingUrl()} />
      </Switch>
    );
  };

  if (!authInfo.isInitialized) return null;

  return (
    <AppContextProvider>
      <Suspense fallback={<LinearProgress />}>
        <NotificationsContext.Provider>
          <SocketContext.Provider>
            <UsersProvider>{routerSwitch()}</UsersProvider>
          </SocketContext.Provider>
        </NotificationsContext.Provider>
      </Suspense>
    </AppContextProvider>
  );
};
Main.url = '/:accountName';

export default Main;
