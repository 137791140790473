import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useProjectInfo } from '../../contexts/ProjectContext';
import StringUtils from '../../utils/StringUtils';
import TextEllipsisTooltip from './TextEllipsisTooltip';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles(() => ({
  email: {
    textAlign: 'right',
    width: '100%',
  },
}));

interface Props {
  data?: { label: string };
}

const FilterWithSearch: React.FC<Props> = props => {
  const classes = useStyles();
  const projectInfo = useProjectInfo();
  const { data } = props;

  const formattedOptionValue = StringUtils.splitValueWithParentheses(data?.label || '');
  const userInfo = projectInfo.userObjects[formattedOptionValue.innerValue];

  return (
    <MUI.Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="100%"
    >
      <UserAvatar size={16} userInfo={userInfo} />
      <TextEllipsisTooltip text={formattedOptionValue.preValue} width="40%" mr="4px">
        <MUI.Typography variant="body1">{formattedOptionValue.preValue}</MUI.Typography>
      </TextEllipsisTooltip>
      <TextEllipsisTooltip text={formattedOptionValue.innerValue} width="50%">
        <MUI.Typography className={classes.email} variant="body1" color="textSecondary">
          {formattedOptionValue.innerValue}
        </MUI.Typography>
      </TextEllipsisTooltip>
    </MUI.Box>
  );
};

export default FilterWithSearch;
