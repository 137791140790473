import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, Icon, Input, useTheme } from '@superb-ai/norwegian-forest';

import { getRoleLabel, ProjectRoles } from '../../../union/UserRoleUnion';

type Props = {
  hasRoleSelect?: boolean;
  selectedRole?: string;
  setSelectedRole?: Dispatch<SetStateAction<string>>;
  onChangeSearchKey: (value: string) => Promise<void> | void;
  height?: number;
};

function formatSelected(label: string, hasNonDefaultValue: boolean) {
  return <Box themedColor={!hasNonDefaultValue ? ['primary', 600] : undefined}>{label}</Box>;
}

const ProjectSearchBar: React.FC<Props> = ({
  hasRoleSelect,
  onChangeSearchKey,
  selectedRole,
  setSelectedRole,
  height,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const roleOptions = ProjectRoles.map(r => ({
    value: r,
    label: t('projectMembers.role.inviteAsRole', { role: getRoleLabel(t, r) }),
  }));

  return (
    <Box
      pl={1}
      mb={1}
      width="100%"
      height={height && height >= 0 ? height : 42}
      display="flex"
      alignItems="center"
      backgroundColor={theme.nfPalette.grey[60]}
      borderRadius
    >
      <Box flex={1}>
        <Input
          variant="text"
          color="grey"
          Adornment={<Icon name="search" size="1em" />}
          adornmentPosition="left"
          placeholder={t('users.dialogs.projectAccess.searchProjects')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeSearchKey(event.target.value)
          }
        />
      </Box>
      {hasRoleSelect && (
        <Box width="130px" display="flex" alignItems="flex-end">
          <DropdownList
            color="lightgrey"
            onChange={value => {
              if (!setSelectedRole) return;
              setSelectedRole(value);
            }}
            options={roleOptions}
            size="m"
            value={selectedRole || ''}
            variant="text"
            placeholder={t('users.dialogs.projectAccess.selectRole')}
            formatSelected={formatSelected}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProjectSearchBar;
