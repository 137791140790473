import React, { useEffect, useState } from 'react';

import { css } from '@emotion/core';
import { Box, IconButton, RadioButtons } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import FileUtils from '../../../../../utils/FileUtils';
import SyntaxHighlighter from '../../SyntaxHighlighter';
import { UploadStepInfo } from './type';

const UploadCmd = (t: TFunction): UploadStepInfo => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useProjectInfo();

  const RAW_DATA = 'raw_data';
  const WITH_LABEL = 'with_label';
  const [method, setMethod] = useState<'raw_data' | 'with_label'>(RAW_DATA);

  useEffect(() => {
    if (project) {
      setMethod(WITH_LABEL);
      return;
    }
  }, []);

  const codeString = (method: 'raw_data' | 'with_label') =>
    ({
      [RAW_DATA]: `$ cd folder-with-files\n$ spb upload dataset\nProject Name: ${
        project ? project.name : '<my-project>'
      }\nDataset Name: <my-dataset>`,
      [WITH_LABEL]: `$ cd folder-with-files\n$ spb upload dataset --include-label\nProject Name: ${
        project ? project.name : '<my-project>'
      }\nDataset Name: <my-dataset>`,
    }[method]);

  const handleClickCopy = () => {
    FileUtils.copyToClipboard({ value: codeString(method) });
    enqueueSnackbar(COPY_SUCCESS({ t, label: '' }), { variant: 'success' });
  };

  return {
    title: t('data.button.upload'),
    isButtonEnabled: true,
    content: (
      <>
        <RadioButtons
          columns={2}
          options={[
            { label: t('data.upload.uploadRawData'), value: RAW_DATA },
            { label: t('data.upload.uploadRawDataAndLabel'), value: WITH_LABEL },
          ]}
          value={method}
          onChange={value => setMethod(value)}
        />
        <Box
          position="relative"
          sx={css`
            & code {
              font-size: 12px;
            }
          `}
        >
          <SyntaxHighlighter>{codeString(method)}</SyntaxHighlighter>
          <Box position="absolute" top={10} right={10}>
            <IconButton icon="copy" color="backgroundColor" size="xs" onClick={handleClickCopy} />
          </Box>
        </Box>
      </>
    ),
  };
};

export default UploadCmd;
