import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import Layout from '../../../components/pages/account/myProfile/Layout';
import { CreateMenuItem } from '../../../menu/MenuItem';

export const ProfileMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountProfile,
  path: 'profile',
  title: ({ t }) => t('settings.profile.title'),
  component: Layout,
  icon: 'user',
  isVisible({ authInfo }) {
    return !authInfo?.isGuest;
  },
});
