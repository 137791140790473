import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DropdownMenu, DropdownMenuItem, IconButton } from '@superb-ai/norwegian-forest';
import { Checkbox, getColor } from '@superb-ai/ui';

import { LabelData } from '../../../../types/labelTypes';
import { AscDesc } from '../../../../types/muiTypes';
import TableSortLabel from '../../../elements/TableSortLabel';

const useStyles = makeStyles(() => ({
  dropdownContainer: {
    '& > span': {
      display: 'flex',
      transform: 'translate(-8px, 0)',
      '& > button': {
        flex: 0,
      },
    },
  },
}));

interface HeaderProps {
  isDesc: boolean;
  orderBy: any;
  onRequestSort: (property: string, ascDesc: AscDesc) => void;
  onClickGlobalCheckbox: () => void;
  advancedQaVisible: boolean;
  reviewVisible: boolean;
  onExpandAll: (expand: boolean) => void;
  hasExpandableRows: boolean;
  labelCount: number;
  checkedLabels: string[];
  isAllLabelsChecked: boolean;
  visibleLabels: LabelData[];
}

const ThumbnailViewHeader: React.FC<HeaderProps> = props => {
  const { t } = useTranslation();
  const {
    isDesc,
    orderBy,
    onRequestSort,
    onClickGlobalCheckbox,
    advancedQaVisible,
    reviewVisible,
    onExpandAll,
    hasExpandableRows,
    labelCount,
    checkedLabels,
    isAllLabelsChecked,
    visibleLabels,
  } = props;
  const classes = useStyles();

  const checkedLabelCount = visibleLabels.filter(({ id }) => checkedLabels.includes(id)).length;
  const isChecked = isAllLabelsChecked || checkedLabelCount >= labelCount;
  const isMixed = checkedLabelCount < labelCount && checkedLabelCount > 0;

  const getChecked = () => {
    if (labelCount <= 0) {
      return false;
    }

    return isChecked ? true : isMixed ? 'mixed' : false;
  };

  return (
    <>
      <colgroup>
        <col width="35" />
        <col width="110" />
        <col style={{ minWidth: 125 }} />
        <col width="110" />
        <col width="160" />
        <col width="115" />
        <col width="125" />
        <col width="90" />
        <col width="80" />
        {advancedQaVisible && <col width="80" />}
        <col width="110" />
      </colgroup>
      <MUI.TableHead
        style={{ boxShadow: 'none', borderBottom: `1px solid ${getColor('gray-250')}` }}
      >
        <MUI.TableRow>
          <MUI.TableCell style={{ padding: 0, paddingLeft: 4 }}>
            <Checkbox
              onClick={onClickGlobalCheckbox}
              disabled={isAllLabelsChecked || labelCount <= 0}
              value={getChecked()}
            />
          </MUI.TableCell>
          <MUI.TableCell className={classes.dropdownContainer}>
            {hasExpandableRows && (
              <DropdownMenu
                width="120px"
                placement="bottom-start"
                AnchorElement={
                  <IconButton icon="caretDown" round variant="text" color="textDefault" size="xs" />
                }
              >
                <DropdownMenuItem onClick={() => onExpandAll(true)}>
                  {t('labels.button.expandAll')}
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onExpandAll(false)}>
                  {t('labels.button.collapseAll')}
                </DropdownMenuItem>
              </DropdownMenu>
            )}
          </MUI.TableCell>
          <MUI.TableCell>
            <TableSortLabel
              property="asset__key"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('labels.table.dataKey')}
            </TableSortLabel>
          </MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="asset__group"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('labels.table.dataset')}
            </TableSortLabel>
          </MUI.TableCell>
          <MUI.TableCell align="center"> {t('labels.table.labelTag')}</MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="work_assignee"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('labels.table.assignee')}
            </TableSortLabel>
          </MUI.TableCell>

          <MUI.TableCell align="center">
            <TableSortLabel
              property="last_updated_at"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
              defaultOrder="desc"
            >
              {t('labels.table.lastUpdated')}
            </TableSortLabel>
          </MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="auto_label_info__tags__difficulty"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
              defaultOrder="desc"
            >
              {t('labels.table.autoLabelStatus')}
            </TableSortLabel>
          </MUI.TableCell>
          {!reviewVisible && (
            <MUI.TableCell align="center">{t('labels.table.issues')}</MUI.TableCell>
          )}
          {reviewVisible && (
            <MUI.TableCell align="center">
              <TableSortLabel
                property="review_round"
                onRequestSort={onRequestSort}
                isDesc={isDesc}
                orderBy={orderBy}
                defaultOrder="desc"
              >
                {t('labels.table.reviews')}
              </TableSortLabel>
            </MUI.TableCell>
          )}
          {advancedQaVisible && (
            <MUI.TableCell align="center">
              <TableSortLabel
                property="consistency_score"
                onRequestSort={onRequestSort}
                isDesc={isDesc}
                orderBy={orderBy}
                defaultOrder="desc"
              >
                {t('labels.table.qa')}
              </TableSortLabel>
            </MUI.TableCell>
          )}
          <MUI.TableCell align="center">
            <TableSortLabel
              property="status"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('labels.table.labelStatus')}
            </TableSortLabel>
          </MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableHead>
    </>
  );
};

export default ThumbnailViewHeader;
