import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Icon,
  IconButton,
  Link,
  Typography,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { concat } from 'lodash';

import { DataListMenuItem } from '../../../apps/projects/project/data/list/MenuItem';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useCurrentPlan } from '../../../queries/useSubscriptionQuery';
import { getUrl } from '../../../routes/util';
import FileService, { FileInfo } from '../../../services/FileService';
import { FileWithPath } from '../../../utils/FileUtils';
import { sleep } from '../../../utils/SpbUtils';
import helper from './helper';

interface Props {
  classes: Record<string, string>;
  toast: { options: any; type: 'label' | 'data' };
  isCancel: boolean;
  handleClickCancel: () => void;
  handleClickClose: () => void;
}

const Upload: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { toast, isCancel, handleClickCancel, handleClickClose } = props;
  const {
    options: { files, dataset, project },
  } = toast;

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const rootPath = authInfo.accountName || '';
  const currentPlan = useCurrentPlan();
  const { openModal, closeModal } = useAlertModal();

  const [message, setMessage] = useState('');
  const [uploadIndex, setUploadIndex] = useState(-1);
  const [isFinished, setIsFinished] = useState(false);
  const [filesWithError, setFilesWithError] = useState<any[]>([]);

  const isFailed = filesWithError.length > 0;

  const fileCount = files.length.toLocaleString('en');
  const errorCount = filesWithError.length.toLocaleString('en');

  const startPrepare = async () => {
    setMessage('Preparing upload...');
    setTimeout(() => {
      toast.options.setState('endPrepare');
    }, 1000);
  };

  const endPrepare = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    toast.options.setState('startProgress');
  };

  const startProgress = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    setUploadIndex(0);
  };

  const endProgress = async () => {
    if (isFailed) {
      setMessage(`Failed to upload ${errorCount} data (${errorCount} / ${fileCount})`);

      const errorCounts: Record<string, number> = {};
      for (const fileWithError of filesWithError) {
        if (fileWithError.error) {
          errorCounts[fileWithError.error] = (errorCounts[fileWithError.error] ?? 0) + 1;
        }
      }
      // TODO: track failure in BE
      // for (const [reason, count] of Object.entries(errorCounts)) {
      //   AnalyticsTracker.uploadFailed({ reason, count });
      // }
    } else {
      setMessage(`Successfully uploaded ${fileCount} files`);
    }

    // const dataCount = Math.max(0, files.length - filesWithError.length);

    setIsFinished(true);
  };

  const cancel = async () => {
    setMessage('Canceled uploading');

    await sleep(3000);
    toast.options.setState('close');
    await sleep(0);
    toast.options.deleteToast();
  };

  const close = async () => {
    if (isCancel) return;

    await sleep(0);
    toast.options.deleteToast();
  };

  // start prepare -> prepare (use effect) -> end prepare -> start progress -> progress (use effect) -> end progress -> close
  // cancel
  useEffect(
    () => {
      switch (toast.options.state) {
        case 'startPrepare':
          startPrepare();
          break;
        case 'endPrepare':
          endPrepare();
          break;
        case 'startProgress':
          startProgress();
          break;
        case 'endProgress':
          endProgress();
          break;
        case 'cancel':
          cancel();
          break;
        case 'close':
          close();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [toast.options.state],
  );

  const uploadAndTrackImage = async (file: FileWithPath) => {
    const fileInfo = {
      key: file.path?.normalize(),
      group: dataset,
      fileName: file.name.normalize(),
      fileSize: file.size,
    } as FileInfo;

    try {
      const res = await FileService.uploadFile({
        fileInfo,
        file,
        isFree: currentPlan === 'FREE',
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      // Assign to project if user uploaded from certain project or selected target project
      if (project) {
        await FileService.registerUploadedAssetToLabel({
          projectId: project,
          assetId: res.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      }
    } catch (error: any) {
      if (error.message === 'Exceeded limits' || error.message === 'Server Error') {
        setFilesWithError(concat(filesWithError, file));
        toast.options.setState('cancel');
      }
      setFilesWithError([
        ...filesWithError,
        {
          path: file.path,
          error: error.message === 'Duplicated' ? 'Duplicated data key' : error.message,
        },
      ]);
    } finally {
      setUploadIndex(uploadIndex + 1);
    }
  };

  const uploadAndTrackImageSequence = async (key: string, sequence: FileWithPath[]) => {
    try {
      const fileInfos = sequence.map(file => ({
        fileName: file.name,
        fileSize: file.size,
        file,
      }));

      const { id: assetId, batch } = await FileService.uploadImageSequenceFiles({
        dataType: 'video',
        fileInfos,
        key,
        group: dataset,
        isFree: currentPlan === 'FREE',
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      batch.onFileComplete((fileInfo, response) => {
        if (response.error) {
          setFilesWithError([
            ...filesWithError,
            {
              path: `${key}/${fileInfo.file.name}`,
              error: `${response.error}`,
            },
          ]);
        }
      });
      await batch;

      // Assign to project if user uploaded from certain project or selected target project
      if (project) {
        await FileService.registerUploadedAssetToLabel({
          projectId: project,
          assetId,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      }
    } catch (error: any) {
      setFilesWithError([
        ...filesWithError,
        {
          path: key,
          error: error.message === 'Duplicated' ? 'Duplicated data key' : error.message,
        },
      ]);
    } finally {
      setUploadIndex(uploadIndex + 1);
    }
  };

  const uploadAndTrackPointcloudSequence = async (key: string, sequence: FileWithPath[]) => {
    try {
      const fileInfos = sequence.map(file => ({
        fileName: file.name,
        fileSize: file.size,
        file,
      }));
      const { id: assetId, batch } = await FileService.uploadPointcloudSequenceFiles({
        dataType: 'pointclouds',
        fileInfos,
        key,
        group: dataset,
        isFree: currentPlan === 'FREE',
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      batch.onFileComplete((fileInfo, response) => {
        if (response.error) {
          setFilesWithError([
            ...filesWithError,
            {
              path: `${key}/${fileInfo.file.name}`,
              error: `${response.error}`,
            },
          ]);
        }
      });
      await batch;

      // Assign to project if user uploaded from certain project or selected target project
      if (project) {
        await FileService.registerUploadedAssetToLabel({
          projectId: project,
          assetId,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      }
    } catch (error: any) {
      setFilesWithError([
        ...filesWithError,
        {
          path: key,
          error: error.message === 'Duplicated' ? 'Duplicated data key' : error.message,
        },
      ]);
    } finally {
      setUploadIndex(uploadIndex + 1);
    }
  };

  useEffect(
    () => {
      if (uploadIndex === -1) return;

      if (isCancel) {
        toast.options.setState('cancel');
        return;
      }

      if (uploadIndex >= files.length) {
        toast.options.setState('endProgress');
        return;
      }

      (async () => {
        setMessage(`Uploading data... (${(uploadIndex + 1).toLocaleString('en')} / ${fileCount})`);
        if (toast.options.dataType === 'VIDEO_FRAMES') {
          const [key, sequence] = files[uploadIndex];
          await uploadAndTrackImageSequence(key, sequence);
          return;
        }
        if (toast.options.dataType === 'POINT_CLOUD_SEQUENCE') {
          const [key, sequence] = files[uploadIndex];
          await uploadAndTrackPointcloudSequence(key, sequence);
          return;
        }
        await uploadAndTrackImage(files[uploadIndex]);
      })();
    },
    // eslint-disable-next-line
    [uploadIndex],
  );

  const handleClickRoute = () => {
    if (project) {
      routeInfo.history.push(getUrl([rootPath, 'label', 'project', project, 'labels']));
    } else {
      routeInfo.history.push(getUrl([rootPath, 'label', DataListMenuItem.path], {}, { dataset }));
    }
    closeModal();
  };

  const handleClickView = () => {
    handleClickClose();
    if (isFailed) {
      openModal({
        title: t('failedToUploadModal.title', { errorCount }),
        content: (
          <Box
            maxHeight="140px"
            my={2}
            style={{ overflowY: 'overlay' }}
            display="grid"
            gridTemplateColumns="18px  auto"
            rowGap="5px"
          >
            {filesWithError.map(item => (
              <>
                <Icon name="dot" size="18px" />
                <Typography variant="body3">
                  {item.error} ({item.path})
                </Typography>
              </>
            ))}
          </Box>
        ),
        variant: 'error',
        mainButton: {
          text: t('failedToUploadModal.viewDataset'),
          onClick: handleClickRoute,
        },
        subButton: {
          text: t('failedToUploadModal.ignore'),
          onClick: () => closeModal(),
        },
        close: {
          onClose: () => closeModal(),
          canCloseWithExit: true,
          canClickOutside: true,
        },
      });
    } else {
      handleClickRoute();
    }
  };

  const getToastIcon = () => {
    if (isCancel) return <Icon name="clear" size="20px" color="yellow" />;
    if (!isFinished) return <Icon name="loadingSpinner" size="20px" color="secondary" />;
    if (isFailed) return <Icon name="clear" size="20px" color="strawberry" />;
    return <Icon name="check" size="20px" color="green" />;
  };

  return (
    <>
      <Box display="flex" alignItems="center" height="100%">
        <Box display="flex" alignItems="center" mr={1}>
          {getToastIcon()}
        </Box>
        <Typography variant="body4" themedColor="backgroundColor">
          {message}
        </Typography>
        {isFinished && (
          <Box display="flex" alignItems="center" ml={2}>
            <Link
              underlined
              themedColor={isFailed ? 'strawberry' : 'green'}
              variant="body4"
              onClick={handleClickView}
            >
              View
            </Link>
          </Box>
        )}
        {helper.getIsCancelActive(toast.options.state) ? (
          <Box display="flex" alignItems="center" ml={2}>
            <Link underlined themedColor="secondary" variant="body4" onClick={handleClickCancel}>
              Cancel
            </Link>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" ml={1}>
            <IconButton icon="clear" color="backgroundColor" size="s" onClick={handleClickClose} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Upload;
