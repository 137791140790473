import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, isEmpty, join, set } from 'lodash';
import { useSnackbar } from 'notistack';

import { FILTER_OPTION_LIMIT } from '../../../../../consts/SnackbarMessage';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import FilterClassDropdownListItem from './FilterClassDropdownListItem';

const useStyles = makeStyles(theme => ({
  subHeader: {
    fontSize: '10px',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
  },
}));

const convertImageToVideo = (annoType: string, workapp: WorkApp): string => {
  if (WorkappUnion.isVideoApp(workapp) && annoType === 'Image Category') {
    return 'Video Category';
  }

  return annoType;
};

interface Props {
  index: number;
  onValueChange: (index: number, objKey: string, options: string[]) => void;
  onChangeCurrentRow: (index: number) => void;
  objKey: string;
  selectedOptions: string[];
  searchedAnnoOptions: any;
}

const FilterClassDropdownList = ({
  index,
  objKey,
  onValueChange,
  selectedOptions,
  searchedAnnoOptions,
  onChangeCurrentRow,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const [flatAnnoOptions, setFlatAnnoOptions] = useState(projectInfo.flatAnnoOptions);
  const copiedFlatAnnoOptions = cloneDeep(flatAnnoOptions);

  const isEveryChildChecked = (node: any) => {
    const filteredCheckedChildren = node.children.filter((child: any) => {
      const { status } = copiedFlatAnnoOptions[child.value];
      return status === 'checked';
    });
    const filteredIndeterminateChildren = node.children.filter((child: any) => {
      const { status } = copiedFlatAnnoOptions[child.value];
      return status === 'indeterminate';
    });
    if (filteredCheckedChildren.length === node.children.length) return 'checked';
    if (filteredCheckedChildren.length === 0 && filteredIndeterminateChildren.length === 0)
      return 'empty';
    return 'indeterminate';
  };

  const toggleParentStatus = (node: any) => {
    const flatAnnoOption = copiedFlatAnnoOptions[node.value];

    if (flatAnnoOption.isChild) {
      set(flatAnnoOption, 'status', isEveryChildChecked(flatAnnoOption));
      toggleParentStatus(flatAnnoOption.parent);
    } else {
      set(flatAnnoOption, 'status', isEveryChildChecked(flatAnnoOption));
    }

    setFlatAnnoOptions(copiedFlatAnnoOptions);
  };

  const toggleChecked = (
    node: any,
    checkedOptions: any,
    status: any,
    onlyDisplay = false,
    isCascade = false,
    checkUpward = true,
  ) => {
    if (!node) return;
    const flatAnnoOption = copiedFlatAnnoOptions[node.value];
    set(flatAnnoOption, 'status', status);
    setFlatAnnoOptions(copiedFlatAnnoOptions);
    if (flatAnnoOption?.isLeaf && !onlyDisplay) {
      const formattedValue = flatAnnoOption.parentValues
        ? flatAnnoOption.parentValues.concat(flatAnnoOption.value).join(';')
        : flatAnnoOption.value;
      const indexOfSelectedOptions = checkedOptions.indexOf(formattedValue);

      if (indexOfSelectedOptions === -1 || (isCascade && status === 'checked')) {
        checkedOptions.push(formattedValue);
      } else {
        checkedOptions.splice(checkedOptions.indexOf(formattedValue), 1);
      }
    } else if (node.children) {
      flatAnnoOption.children.forEach((child: any) => {
        toggleChecked(child, checkedOptions, status, onlyDisplay, true, true);
      });
    }

    if (checkUpward && flatAnnoOption.isChild) {
      toggleParentStatus(flatAnnoOption.parent);
    }

    return checkedOptions;
  };

  let count = 0;
  let checkedNodeCount = 0;
  const getClickedNodesCount = (node: any) => {
    if (!node.children) {
      const item = node.parentValues ? join(node.parentValues.concat(node.value), ';') : node.value;
      if (selectedOptions.indexOf(item) !== -1) {
        checkedNodeCount += 1;
      }
      count += 1;
      return;
    }
    node.children.forEach((child: any) => {
      getClickedNodesCount(child);
    });
  };

  const handleClickCheck = (value: any) => () => {
    const clickedNode = flatAnnoOptions[value];
    const status = () => {
      if (clickedNode.status === 'checked') return 'empty';
      else return 'checked';
    };

    getClickedNodesCount(clickedNode);

    const max = 30;
    if (selectedOptions.length + (count - checkedNodeCount) > max) {
      enqueueSnackbar(FILTER_OPTION_LIMIT({ t, max }), { variant: 'info' });
      count = 0;
      checkedNodeCount = 0;
      return;
    }
    const checkedOptions = toggleChecked(clickedNode, selectedOptions, status());
    onValueChange(index, objKey, checkedOptions);
  };

  useEffect(() => {
    onChangeCurrentRow(index);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    selectedOptions.forEach(option => {
      const singleOptionValueList = option.split(';');
      const singleOptionValue = singleOptionValueList[singleOptionValueList.length - 1];
      const checkedOptions = toggleChecked(
        copiedFlatAnnoOptions[singleOptionValue],
        selectedOptions,
        'checked',
        true,
      );
      onValueChange(index, objKey, checkedOptions);
    });
    // eslint-disable-next-line
  }, [routeInfo.params]);

  return (
    <MUI.List disablePadding>
      {isEmpty(searchedAnnoOptions) ? (
        <MUI.Box textAlign="center" pt={0.8} pb={0.8}>
          <MUI.Typography variant="subtitle1" color="textSecondary">
            {t('dropdown.noOptions')}
          </MUI.Typography>
        </MUI.Box>
      ) : (
        searchedAnnoOptions.map((annoItems: any) => (
          <MUI.Box key={annoItems.annoType}>
            <MUI.ListSubheader className={classes.subHeader} disableSticky>
              {convertImageToVideo(annoItems.annoType, projectInfo.project.workapp)}
            </MUI.ListSubheader>
            {annoItems.children.map((annoItem: any) => (
              <FilterClassDropdownListItem
                key={annoItem.value}
                hierarchy={1}
                item={annoItem}
                flatAnnoOptions={flatAnnoOptions}
                status={
                  flatAnnoOptions[annoItem.value] ? flatAnnoOptions[annoItem.value].status : 'empty'
                }
                onClickCheck={handleClickCheck}
              />
            ))}
          </MUI.Box>
        ))
      )}
    </MUI.List>
  );
};

export default FilterClassDropdownList;
