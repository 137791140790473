import React from 'react';

import { Badge, Card, Icon } from '@superb-ai/norwegian-forest';

type Props = React.ComponentProps<typeof Card> & {
  iconName?: React.ComponentProps<typeof Icon>['name'];
};

export default function Banner({ iconName, children, ...cardProps }: Props) {
  return (
    <Card
      my={2}
      {...cardProps}
      display="flex"
      p={3}
      justifyContent="center"
      alignItems="center"
      gap="8px"
    >
      {iconName && (
        <Badge color="textSecondary" variant="stroke" size="xs">
          <Icon name={iconName} />
        </Badge>
      )}
      {children}
    </Card>
  );
}
