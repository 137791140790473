import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box as BoxIcon, Clear, Plus, Polygon } from '@superb-ai/icons';
import { Box, Icon, IconButton, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';

export default function AnnotationTypeButton({
  annotationType,
  classCount,
  isAdded,
  isSelected,
  onAddOrDelete,
  setIsSelected,
}: {
  annotationType: CurateAnnotationType;
  classCount?: number;
  isAdded?: boolean;
  isSelected: boolean;
  onAddOrDelete: (type: 'add' | 'delete') => void;
  setIsSelected: (isSelected: boolean) => void;
}) {
  const { t } = useTranslation();

  function handleClick() {
    setIsSelected(true);
    if (isAdded) return;
    onAddOrDelete('add');
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      px={1}
      py={0.5}
      cursor="pointer"
      backgroundColor={{ default: isSelected ? 'gray-100' : undefined, hover: 'gray-100' }}
      onClick={handleClick}
    >
      <Icon icon={isAdded ? (annotationType === 'box' ? BoxIcon : Polygon) : Plus} />
      {isAdded ? (
        <>
          <Typography variant="m-regular">
            {t(
              `curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.typeTarget.${annotationType}`,
            )}
          </Typography>
          <Typography variant="m-regular" color="cloud">
            ({classCount || 0})
          </Typography>
          <IconButton
            variant="text"
            icon={Clear}
            size="s"
            style={{ marginLeft: 'auto' }}
            onClick={e => {
              e.stopPropagation();
              onAddOrDelete('delete');
            }}
          />
        </>
      ) : (
        <Typography color="cloud" variant="m-medium" style={{ lineHeight: '24px' }}>
          {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.add', {
            typeTarget: annotationType,
          })}
        </Typography>
      )}
    </Box>
  );
}
