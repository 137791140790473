import React, { useMemo, useState } from 'react';

import { Box, List, ListItem } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import { MemberData } from '../../../../../types/memberTypes';
import { MislabelDetectionAction } from '../../../../../types/mislabelDetectionTypes';
import { formatDistance } from '../../../../../utils/date';
import AvatarWithInfo from '../../../../elements/AvatarWithInfo';
import UserAvatar from '../../../../elements/UserAvatar';
import Popper from './Popper';

type Props = {
  history?: (MislabelDetectionAction & { created_at: Date; created_by: string })[];
  editors?: MemberData[];
};
export default function HistoryPopper({ history, editors }: React.PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false);

  const descHistory = useMemo(() => history && [...history].reverse(), [history]);
  const recentEditor = useMemo(
    () => editors?.find(editor => descHistory && editor.email === descHistory[0].created_by),
    [descHistory, editors],
  );

  if (!history) return <></>;

  return (
    <Popper
      open={open}
      anchorElement={
        <Box onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          {recentEditor && <UserAvatar noShadow userInfo={recentEditor} size={24} margin={0} />}
        </Box>
      }
    >
      <Box width={240}>
        <List margin="0">
          {descHistory?.map(item => {
            const editor = editors?.find(editor => editor.email === descHistory[0].created_by);
            const messages = item.params?.message.split('\n');
            return (
              <ListItem key={item.mislabel_rank}>
                <Box display="flex" justifyContent="space-between">
                  {editor && <AvatarWithInfo userInfo={editor} withEmail />}
                </Box>
                <Box ml={5} mt={0.5}>
                  <Typography display="block" variant="s-regular" style={{ color: '#B3B3B3' }}>
                    {formatDistance(new Date(item.created_at))}
                  </Typography>
                  <Box mt={0.5} />
                  {messages?.map(message => (
                    <Typography key={message} display="block" variant="m-regular">
                      {message}
                    </Typography>
                  ))}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Popper>
  );
}
