import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, IconButton, Modal, Typography } from '@superb-ai/norwegian-forest';

import ExportDialogStatusBoard from '../ExportDialogStatusBoard';

interface Props {
  isOpen: boolean;
  handleClickClose: () => void;
  statusBoardLoaded: boolean;
  confirmedStatusBoard: boolean;
  checkedLabels: any[];
  filterApiParams: Record<string, any>;
  isAllLabelsChecked: boolean;
  onStatusBoardLoad: (
    params: Record<
      'submittedLabelsCount' | 'labelingInProgressCount' | 'skippedLabelsCount',
      number
    >,
  ) => void;
  addLabelListStatusFilter: () => void;
  handleClickConfirmStatusBoard: () => void;
}

export default function ({
  isOpen,
  handleClickClose,
  statusBoardLoaded,
  confirmedStatusBoard,
  checkedLabels,
  filterApiParams,
  addLabelListStatusFilter,
  handleClickConfirmStatusBoard,
  isAllLabelsChecked,
  onStatusBoardLoad,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen}>
      <Box height="0px" pt={1.25} pr={1.25} style={{ textAlign: 'right' }}>
        <IconButton icon="clear" size="m" onClick={handleClickClose} round color="textSecondary" />
      </Box>
      <Box py={2.5} display="flex" justifyContent="center">
        <Typography
          variant="headline4"
          themedColor="primary"
          style={{ visibility: statusBoardLoaded ? 'visible' : 'hidden' }}
        >
          {t('labels.assignDialog.areYouSure')}
        </Typography>
      </Box>
      <Box px={4}>
        <Typography
          variant="body3"
          style={{
            textAlign: 'center',
            fontWeight: 500,
            marginBottom: '2.5em',
            visibility: statusBoardLoaded ? 'visible' : 'hidden',
          }}
        >
          {t('labels.assignDialog.warning')}
        </Typography>
        <Box m="0 auto">
          {!confirmedStatusBoard && (
            <ExportDialogStatusBoard
              checkedLabels={checkedLabels}
              filterApiParams={filterApiParams}
              isAllLabelsChecked={isAllLabelsChecked}
              onLoad={onStatusBoardLoad}
              showChangeTo="labelingInProgressCount"
            />
          )}
        </Box>
        <Box width="374px" mt={4} style={{ visibility: statusBoardLoaded ? 'visible' : 'hidden' }}>
          <Typography variant="body4" style={{ textAlign: 'center' }}>
            <Trans
              i18nKey="labels.assignDialog.description"
              components={{
                LinkTypography: (
                  <button
                    type="button"
                    onClick={addLabelListStatusFilter}
                    style={{
                      border: '0',
                      background: 'none',
                      padding: 0,
                      font: 'inherit',
                      color: '#5A7BFF',
                      borderBottom: '1px solid #5a7bff',
                      cursor: 'pointer',
                    }}
                  />
                ),
              }}
            />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" pt={3}>
        <Box display="flex" gap="8px">
          <Button variant="text" color="primary" onClick={handleClickClose}>
            {t('button.cancel')}
          </Button>
          <Button variant="strong-fill" color="primary" onClick={handleClickConfirmStatusBoard}>
            {t('button.next')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
