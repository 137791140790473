import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import { useMetadataAnalyticsContext } from './contexts/MetadataContext';
import MetadataKeyTableRow from './table/MetadataKeyTableRow';
import { MetadataKeyDatum } from './types';
import { filterSystemMetadata, metadataKeySortTransformer } from './utils/transformer';

export const columnWidths = {
  key: '120px',
  valueCount: '80px',
  imageCount: '80px',
};

export default function MetadataKeyTableArea() {
  const { t } = useTranslation();
  const { selectionKeyData, comparedKeyData, showSystemMetadata } = useMetadataAnalyticsContext();

  const transformedSelectionData = filterSystemMetadata(
    metadataKeySortTransformer(selectionKeyData?.data || []),
    showSystemMetadata,
  );

  return (
    <Box overflow="auto" height="100%" style={{ width: '100%' }} mr={1}>
      <Box
        px={1}
        py={1.5}
        borderBottom="1px solid"
        borderColor="gray-200"
        position="sticky"
        top="0"
        backgroundColor="white"
        style={{ zIndex: 1 }}
        display="flex"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" width="100%">
          <Typography variant="m-medium" style={{ width: columnWidths.key }}>
            {t('curate.analytics.metadataStats.metadata')} ({transformedSelectionData.length})
          </Typography>
          <Typography
            ml={3}
            style={{ width: columnWidths.valueCount, textAlign: 'right' }}
            variant="m-medium"
          >
            {t('curate.analytics.text.valueCount')}
          </Typography>
          <Typography mx={5} style={{ flex: 1, textAlign: 'left' }} variant="m-medium">
            {t('curate.analytics.text.share')}
          </Typography>
          <Typography
            ml="auto"
            style={{ width: columnWidths.imageCount, textAlign: 'right' }}
            variant="m-medium"
          >
            {t('curate.analytics.text.imageCount')}
          </Typography>
        </Box>
      </Box>
      {transformedSelectionData.map((data, index) => (
        <MetadataKeyTableRow
          index={index}
          key={data.key}
          data={data}
          selectionDataTotalCount={selectionKeyData?.totalImageCount || 0}
          comparedData={comparedKeyData?.data?.find(
            (comparedData: MetadataKeyDatum) => comparedData.key === data.key,
          )}
          comparedDataTotalCount={comparedKeyData?.totalImageCount || 0}
        />
      ))}
    </Box>
  );
}
