import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';
import ExportHistoryMenuItem from './history/MenuItem';
import Layout from './Layout';
import { RawDataDownloadMenuItem } from './raw-data/MenuItem';

export const ProjectExportMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectExport,
  path: 'export',
  title: ({ t }) => t('export.title'),
  component: Layout,
  icon: 'exports',
  children: [ExportHistoryMenuItem, RawDataDownloadMenuItem],
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
