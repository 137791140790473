import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as ShyCredit from '@superb-ai/shy-credit';
import { cloneDeep, some } from 'lodash';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { ANNOTATION_TYPE } from '../../../../consts/NewProjectConst';
import { NEW_PROJECT_CAN_ONLY_ONE_KEYPOINT } from '../../../../consts/SnackbarMessage';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';

const useStyles = makeStyles(() => ({
  annotationSelect: {
    width: '100%',
    borderBottom: 'solid 0.5px #9b9b9b ',
    overflow: 'hidden',
    borderRadius: '5px 5px 0px 0px',
    '& .MuiSelect-selectMenu': {
      color: '#4a4a4a',
      fontSize: '11px',
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 0px',
    },
  },
}));

// eslint-disable-next-line
const ObjectClassItemSelect = (props: any): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { objectClass, index, setIsOpenMoreInformation } = props;

  const handleChangeSelect = (e: any) => {
    e.stopPropagation();

    const { value } = e.target;

    if (value === objectClass.annotationType) return;

    const sameHierarchyNameList = newProjectInfo.objectClasses
      .filter((x: any) => {
        return x.annotationType === value;
      })
      .map((item: any) => item.name);

    const isDuplicated = some(
      sameHierarchyNameList,
      (item, idx: number) => item === objectClass.name && idx !== index,
    );

    if (isDuplicated) {
      // tsnoh-nameDuplicateInObjectClass
      enqueueSnackbar(
        t('consts.snackbarMessage.nameDuplicateInObjectClass', {
          name: objectClass.name,
          annotationType: objectClass.annotationType,
        }),
        {
          variant: 'warning',
        },
      );

      return;
    }

    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const nextObject = nextObjectClasses[index];
    nextObject.annotationType = value;

    if (objectClass.annotationType === ANNOTATION_TYPE.KEYPOINT.value) {
      delete nextObject.keypointInfo;
    } else if (value === ANNOTATION_TYPE.KEYPOINT.value) {
      if (
        WorkappUnion.isImageDefault(newProjectInfo.selectedWorkapp) &&
        some(
          newProjectInfo.objectClasses,
          objectClass => objectClass.annotationType === ANNOTATION_TYPE.KEYPOINT.value,
        )
      ) {
        enqueueSnackbar(NEW_PROJECT_CAN_ONLY_ONE_KEYPOINT({ t }), {
          variant: 'warning',
        });
        return;
      }

      newProjectInfo.setSelectedObjectClassIndex(index);
      const getTemplate = () => {
        if (
          newProjectInfo.selectedWorkapp === 'image-siesta' ||
          newProjectInfo.selectedWorkapp === 'video-siesta'
        ) {
          return cloneDeep(newProjectInfo.keypointList[0]) || null;
        }

        return ShyCredit.Models.Image.KeypointDef.listTemplates()[0];
      };

      nextObject.keypointInfo = {
        id: uuidv4(),
        template: getTemplate(),
        workapp: newProjectInfo.selectedWorkapp,
      };

      setIsOpenMoreInformation(true);
      newProjectInfo.setIsKeypointDialogOpen(true);
    }

    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  return (
    <MUI.Select
      className={classes.annotationSelect}
      variant="standard"
      value={objectClass.annotationType}
      onChange={handleChangeSelect}
    >
      <MUI.MenuItem value="SELECT_TYPE">{t('projects.createProject.selectType')}</MUI.MenuItem>
      {Object.keys(newProjectInfo.selectedAnnotationTypes)
        .filter(annotationType => annotationType !== ANNOTATION_TYPE.IMAGE_CATEGORY.value)
        .map(annotationType => {
          return (
            <MUI.MenuItem key={annotationType} value={annotationType}>
              {ANNOTATION_TYPE[annotationType as keyof typeof ANNOTATION_TYPE]?.label ??
                annotationType}
            </MUI.MenuItem>
          );
        })}
    </MUI.Select>
  );
};

export default ObjectClassItemSelect;
