import React, { useState } from 'react';

import { StateGetterSetter } from '../../../../../../../contexts/types';

type NameAndId = { name: string; id: string };
type GenerateSyntheticImageContextProps = {
  isStepsCompleted: boolean;
  initialize: () => void;
} & StateGetterSetter<['generativeModel', 'setGenerativeModel'], NameAndId | undefined> &
  StateGetterSetter<['applicableImagesCount', 'setApplicableImagesCount'], number> &
  StateGetterSetter<['imagesMultiplier', 'setImagesMultiplier'], number> &
  StateGetterSetter<['slice', 'setSlice'], string> &
  StateGetterSetter<['isSliceNameValid', 'setIsSliceNameValid'], boolean>;

export const GenerateSyntheticImageContext = React.createContext(
  {} as GenerateSyntheticImageContextProps,
);

export const DEFAULT_IMAGES_MULTIPLIER = 2;

export const GenerateSyntheticImageProvider: React.FC = ({ children }) => {
  const [generativeModel, setGenerativeModel] = useState<NameAndId>();
  const [applicableImagesCount, setApplicableImagesCount] = useState(0);
  const [imagesMultiplier, setImagesMultiplier] = useState(DEFAULT_IMAGES_MULTIPLIER);
  const [slice, setSlice] = useState<string>('');
  const [isSliceNameValid, setIsSliceNameValid] = useState<boolean>(false);

  const initialize = () => {
    setGenerativeModel(undefined);
  };
  const isStepsCompleted =
    !!generativeModel && slice !== '' && isSliceNameValid && applicableImagesCount > 0;

  return (
    <GenerateSyntheticImageContext.Provider
      value={{
        initialize,
        isStepsCompleted,
        generativeModel,
        setGenerativeModel,
        applicableImagesCount,
        setApplicableImagesCount,
        imagesMultiplier,
        setImagesMultiplier,
        slice,
        setSlice,
        isSliceNameValid,
        setIsSliceNameValid,
      }}
    >
      {children}
    </GenerateSyntheticImageContext.Provider>
  );
};

export const useGenerateSyntheticImageInfo = (): GenerateSyntheticImageContextProps => {
  return React.useContext(GenerateSyntheticImageContext);
};
