import { Route, Switch } from 'react-router';

import AwsPayment from '../components/pages/payments/aws/Layout';

export default function PaymentRoutes() {
  return (
    <Switch>
      <Route path="/payments/aws" component={AwsPayment} />
    </Switch>
  );
}
