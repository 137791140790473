import React from 'react';

import { Box, Icon, RadioButtons, Typography } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';
import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { UploadMethod } from '../../types';
import { UPLOAD_METHODS_CONFIG } from './config';
import { UploadStepInfo } from './type';

const SelectUploadMethod = ({
  isButtonEnabled,
  options,
  hasWarning,
  t,
}: {
  isButtonEnabled: boolean;
  options: Option<UploadMethod>[];
  hasWarning: boolean;
  t: TFunction;
}): UploadStepInfo => {
  const fileInfo = useUploadInfo();
  const { uploadMethod, setUploadMethod } = fileInfo;

  const handleChangeOption = (value: UploadMethod) => {
    setUploadMethod(value);
    // TODO(shko): reset following options
  };

  const summary = uploadMethod ? (
    <Box
      display="flex"
      alignItems="center"
      themedColor={['grey', 500]}
      style={{ fontSize: '12px' }}
    >
      <Box display="flex" alignItems="center" mr={0.5}>
        <Icon name={UPLOAD_METHODS_CONFIG[uploadMethod].icon} />
      </Box>
      <Typography>{UPLOAD_METHODS_CONFIG[uploadMethod].title}</Typography>
    </Box>
  ) : (
    ''
  );

  return {
    title: t('data.upload.selectUploadMethod'),
    isButtonEnabled,
    summary,
    content: (
      <>
        {hasWarning && (
          <Box
            themedBackgroundColor={['primary', 100]}
            themedColor="primary"
            display="flex"
            alignItems="center"
            p={1}
            mb={1.5}
          >
            <Icon name="warningOutline" size="12px" />
            <Box display="flex" alignItems="center" ml={0.5}>
              <Typography variant="body3">
                {t('data.upload.selectUploadMethodDescription')}
              </Typography>
            </Box>
          </Box>
        )}
        <RadioButtons
          options={options}
          value={uploadMethod || 'CLOUD'}
          onChange={handleChangeOption}
        />
      </>
    ),
  };
};

export default SelectUploadMethod;
