import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

/**
 * For optimistic updates, add a new data item to the first page of InfitieQuery data.
 */
export function addDataToPagedQueryData<
  TData extends { count: number; results: TDataObj[] },
  TDataObj,
>({
  queryKey,
  queryClient,
  data,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
  data: TDataObj;
}) {
  return queryClient.setQueryData<InfiniteData<TData>>(
    queryKey,
    (queryData = { pages: [], pageParams: [] }) => {
      const page0 = queryData.pages.shift() ?? ({ count: 0, results: [] } as unknown as TData);
      page0.count += 1;
      page0.results = [data, ...page0.results];
      return {
        ...queryData,
        pages: [page0, ...queryData.pages],
      };
    },
  );
}
