import { useTranslation } from 'react-i18next';

import { Dot } from '@superb-ai/icons';
import { Icon, Tooltip, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../types/curationTypes';
import { AnnotationType } from '../../../../../../../utils/LabelInterfaceUtils';
import { AnnotationTypeIconMap } from '../../modelDiagnosis/diagnosis/analytics/const';

type SupportedAnnoTypes = Extract<AnnotationType, CurateAnnotationType>;

export function ClassNameCell({
  className,
  annoType,
}: {
  className: string;
  annoType?: SupportedAnnoTypes;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {annoType && (
        <Icon icon={AnnotationTypeIconMap[annoType] ?? Dot} color={'gray-400'} size="12px" />
      )}

      <Tooltip content={t('curate.analytics.action.clickToApplyQuery')} placement="right">
        <Typography
          style={{
            width: '84px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          variant="m-medium"
        >
          {className || ''}
        </Typography>
      </Tooltip>
    </>
  );
}
