import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

import {
  convertCategorizationToObject,
  convertPropertyToObject,
} from '../../elements/projectConfiguration/utils';
import ImageCategoryItem from './LegacyOverviewImageCategoryItem';

interface Props {
  isSiesta?: boolean;
  categorization: any;
  fontSize: number;
  color: string;
}

/**
 imageCategoryMap
 key:id
 value:{
   children:[],
   id:string,
   isGroup:boolean,
   name:string,
   parent:string|null
 }
 imageCategory
 */
const LegacyOverviewImageCategoryList: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isSiesta = false, categorization, fontSize, color } = props;
  const [imageCategoryMap] = useState(
    !isSiesta
      ? (convertCategorizationToObject(categorization) as any)
      : (convertPropertyToObject(categorization) as any),
  );

  if (categorization?.type === 'free response') {
    return (
      <MUI.Typography style={{ fontSize: `${fontSize}px`, color }}>
        {`${categorization.name} | ${t('projectSettings.propertyType.freeResponse')}`}
      </MUI.Typography>
    );
  }

  if (!imageCategoryMap?.root) return null;

  return (
    <ImageCategoryItem
      depth={0}
      isSiesta={isSiesta}
      imageCategoryMap={imageCategoryMap}
      imageCategory={imageCategoryMap.root}
      fontSize={fontSize}
      color={color}
      type={categorization?.type}
    />
  );
};

export default LegacyOverviewImageCategoryList;
