import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Modal, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { Chip, Select, ToggleButton } from '@superb-ai/ui';
import { format } from 'date-fns';
import { isEmpty, omit } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import {
  getNamingRuleErrorMessage,
  getDefaultFileName,
} from '../../../../configs/NamingRulesConfig';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useCalAvailabilityInfo } from '../../../../contexts/CalAvailabilityContext';
import { useFeatureFlag, useFeatureFlags } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';
import { formatCount } from '../../../../utils/numberFormat';
import ReconfirmDialog from '../../../elements/ReconfirmDialog';
import { ExportFormat, getExportFormatOptions } from '../../export/exportHistory/formats';
import ExportDialogStatusBoard from './ExportDialogStatusBoard';

interface DialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  checkedLabels: any[];
  setCheckedLabels: Dispatch<SetStateAction<any[]>>;
  totalCount: number;
  isAllLabelsChecked: boolean;
  filterApiParams: any;
  variant: 'export' | 'cal';
}

const ExportDialog: React.FC<DialogProps> = props => {
  const { t } = useTranslation();
  const {
    isOpen,
    setIsOpen,
    checkedLabels,
    totalCount,
    isAllLabelsChecked,
    filterApiParams,
    variant,
  } = props;

  const [isResultDialogOpen, setIsResultDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState<any>('');
  const [exportName, setExportName] = useState('');
  const [exportNameErrorMessages, setExportNameErrorMessages] = useState<string[]>([]);
  const [exportWithCAL, setExportWithCAL] = useState(variant === 'cal');
  const [exportFormat, setExportFormat] = useState<ExportFormat>('default');

  const { getFlag } = useFeatureFlags();
  const exportFormatFlag = getFlag('exportFormats');
  const formatsEnabled = exportFormatFlag.enabled;
  const enabledFormats: ExportFormat[] = JSON.parse(exportFormatFlag.value || '[]');

  const commandContext = useLabelCommandContext();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();

  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const calInfo = useCalAvailabilityInfo();
  const {
    canUseCustomAutoLabel,
    getIsOverLabelCountForCreatingCAL,
    requiredLabelCountForCreatingCAL,
  } = calInfo;

  useEffect(() => {
    setExportName(getDefaultFileName(projectInfo.project.name));
    setExportNameErrorMessages([]);
  }, [projectInfo, isOpen]);

  const getFilterParamRaw = () => {
    if (!isEmpty(checkedLabels) && !isAllLabelsChecked) {
      return { idIn: checkedLabels };
    }
    return omit(filterApiParams, [
      'ordering',
      'searchAfter',
      'searchBefore',
      'searchLast',
      'page',
      'pageSize',
    ]);
  };

  const handleClickMessageClose = () => {
    setIsOpen(false);
    setIsResultDialogOpen(false);
    setExportWithCAL(variant === 'cal');
  };

  function getLabelCount() {
    if (!isEmpty(checkedLabels) && !isAllLabelsChecked) {
      return checkedLabels.length;
    }
    return totalCount;
  }

  function getExportMeta() {
    if (!(exportWithCAL || exportFormat !== 'default')) return {};
    return {
      post_execute: {
        ...(exportWithCAL ? { custom_auto_label: { name: exportName } } : {}),
        ...(exportFormat !== 'default' ? { transform: { type: exportFormat } } : {}),
      },
    };
  }

  const handleClickExport = async () => {
    const exportInfo = () => {
      return {
        name: exportName,
        labelCount: getLabelCount(),
        meta: getExportMeta(),
      };
    };

    setIsLoading(true);

    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'LABELS_EXPORT',
        params: getFilterParamRaw() || undefined,
        actionInfo: exportInfo(),
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);

      AnalyticsTracker.labelExportRequested({
        accountId: authInfo?.accountName ?? '',
        filterBy: Object.keys(getFilterParamRaw()),
        labelCount: getLabelCount(),
        format: exportFormat,
        createCAL: exportWithCAL,
      });

      const calMessage = exportWithCAL ? t('labels.exportDialog.calMessage') : '';

      setResultMessage(
        t('labels.exportDialog.successMessage', {
          labelCount: formatCount(getLabelCount(), 'label'),
          calMessage,
        }),
      );
    } catch (err: any) {
      if (err.message === 'Not Found') {
        setResultMessage(t('labels.exportDialog.labelsDoNotExist'));
      }
      // setResultMessage('Error: Unknown error', { variant: 'error' });
      setResultMessage(t('labels.exportDialog.unknownError'));
    }
    setIsLoading(false);
    setIsResultDialogOpen(true);
  };

  const handleClickViewExportHistory = () => {
    routeInfo.history.push(
      `/${routeInfo.urlMatchInfo.accountName}/project/${routeInfo.urlMatchInfo.projectId}/export/history`,
    );
  };

  const title = variant === 'cal' ? t('labels.cal.title') : t('labels.export.title');

  if (isResultDialogOpen) {
    return (
      <ReconfirmDialog
        isDialogOpen={isOpen}
        title={title}
        confirmButtonMessage={t('labels.exportDialog.viewExportHistory')}
        cancelButtonMessage={t('shared.close')}
        onClickConfirm={handleClickViewExportHistory}
        onClickCancel={handleClickMessageClose}
        message={resultMessage}
        variant="success"
      />
    );
  }

  const labelCount = getLabelCount();
  const calEnabled = getIsOverLabelCountForCreatingCAL(labelCount);
  const calTooltip = calEnabled
    ? t('labels.exportDialog.calDescription')
    : t('labels.exportDialog.cannotCreateCALWarning', {
        labelCount: requiredLabelCountForCreatingCAL,
      });

  const ExportFormatOptions = getExportFormatOptions(t);

  return (
    <Modal
      mainButton={{
        // @ts-ignore - count only allow number
        text: t('labels.exportDialog.exportButton', { count: formatCount(getLabelCount()) }),
        onClick: handleClickExport,
        isLoading,
        disabled: !exportName || !!exportNameErrorMessages.length,
      }}
      close={{
        onClose: handleClickMessageClose,
        hasCloseButton: true,
        canCloseWithExit: true,
      }}
      subButton={{ text: 'Cancel', onClick: handleClickMessageClose }}
      open={isOpen}
      title={title}
      size="m"
    >
      <Box mx={4} mt={3} display="flex" flexDirection="column" gap="24px">
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="label">{t('labels.exportDialog.labelStatus')}</Typography>
          <ExportDialogStatusBoard
            checkedLabels={checkedLabels}
            filterApiParams={filterApiParams}
            isAllLabelsChecked={isAllLabelsChecked}
            title=""
            direction="row"
            variant="compact"
          />
        </Box>

        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="label">
            {variant === 'cal' ? t('autoLabel.cal.title') : t('export.title')} {t('shared.name')}
          </Typography>
          <Input
            value={exportName}
            onChange={e => {
              const name = e.target.value;
              setExportName(name);
              setExportNameErrorMessages(
                getNamingRuleErrorMessage({ str: name, target: 'export' }),
              );
            }}
          />
          <Box>
            {exportNameErrorMessages &&
              exportNameErrorMessages.map((errorMessage: string) => (
                <Typography key={errorMessage} variant="body5" themedColor="primary">
                  * {errorMessage}
                </Typography>
              ))}
          </Box>
        </Box>

        {formatsEnabled && variant !== 'cal' && (
          <Box display="flex" flexDirection="column" gap="8px">
            <Box>
              <Typography variant="label">{t('export.guide.format.title')}</Typography>
              <Typography variant="body4">
                {t('labels.exportDialog.annotationInformation')}
              </Typography>
            </Box>
            <Box display="flex">
              <Select
                multiple
                data={ExportFormatOptions.filter(
                  ({ value }) => value === 'default' || enabledFormats.includes(value),
                )}
                value={
                  exportFormat && exportFormat !== 'default'
                    ? ['default' as const, exportFormat]
                    : ['default' as const]
                }
                onChangeValue={format => {
                  setExportFormat(format.filter(f => f !== 'default').pop() as ExportFormat);
                }}
                formatValue={v => (
                  <Chip color="gray" fill="strong" whiteSpace="nowrap">
                    {ExportFormatOptions.find(({ value }) => value === v)?.shortLabel ?? v}
                  </Chip>
                )}
              />
            </Box>
          </Box>
        )}

        {canUseCustomAutoLabel && variant !== 'cal' && (
          <Tooltip
            placement="right"
            anchorEl={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => calEnabled && setExportWithCAL(!exportWithCAL)}
                themedBackgroundColor={['grey', 60]}
                p={2}
              >
                <Typography
                  variant="body3"
                  themedColor={calEnabled ? 'textDefault' : ['grey', 200]}
                  style={{ cursor: 'default' }}
                >
                  {t('labels.exportDialog.withCreateCAL')}
                </Typography>
                <ToggleButton
                  size="xs"
                  color="green"
                  disabled={!calEnabled}
                  checked={exportWithCAL}
                />
              </Box>
            }
          >
            <Box maxWidth={280}>{calTooltip}</Box>
          </Tooltip>
        )}

        {variant === 'cal' && (
          <Box>
            <Typography variant="body3">{t('labels.exportDialog.createCALDescription')}</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ExportDialog;
