import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, Icon, Typography } from '@superb-ai/norwegian-forest';
import { debounce, isEmpty, lowerCase, snakeCase } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import AdvancedQaService from '../../../../services/AdvancedQaService';
import UserUtils from '../../../../utils/UserUtils';
import { getError } from '../../error/error';
import { downloadDataFile } from '../chartContainers/helper';
import ScatterPlot from '../charts/scatterPlot/ScatterPlot';
import { EMPTY_MESSAGE } from '../config/constants';
import { CHART } from '../config/plotConfig';
// services & context
// configs, types
import { ScatterPlotSvgConfig, SvgInfoScatterPlot } from '../config/types';
import { FileFormat } from '../dataTypes/analyticsDataTypes';
import EmptyPlot from '../elements/EmptyPlot';
import DownloadDropDown from '../tools/DownloadDropDown';
import {
  filterSelectedUsers,
  omitColumns,
  preprocessQALabelers,
  resetQALabelerData,
  translateDownloadColumns,
} from './helper';
import LabelerQASearch from './LabelerQASearch';
import LabelerQATable from './LabelerQATable';
import { ScoreTypeDropDownList } from './ScoreTypeDropDownList';
import { PlotDatum, ScoreType } from './types';

export const getSvgInfo = ({
  mt,
  mb,
  ml,
  mr,
  width,
  height,
  datumDefaultColor,
  datumDefaultOpacity,
  //   datumHoverColor,
  //   datumHoverOpacity,
  datumBrushedColor,
  datumBrushedOpacity,
}: SvgInfoScatterPlot): ScatterPlotSvgConfig => {
  return {
    left: ml,
    right: mr,
    top: mt,
    bottom: mb,
    width: width - ml - mr,
    height: height - mt - mb,
    svgWidth: width,
    svgHeight: height,
    datumDefaultColor: datumDefaultColor,
    datumDefaultOpacity: datumDefaultOpacity,
    datumBrushedColor: datumBrushedColor,
    datumBrushedOpacity: datumBrushedOpacity,
    // datumHoverColor: datumHoverColor,
    // datumHoverOpacity: datumHoverOpacity,
  };
};

interface ContainerProps {
  hasSubmittedConsensusLabels: boolean;
  hasConsensusLabels: boolean;
  hasAnyLabels: boolean;
  width: number;
}

const LabelerQAContainer: React.FC<ContainerProps> = props => {
  const { hasAnyLabels, hasConsensusLabels, hasSubmittedConsensusLabels, width } = props;
  const scatterPlotSvgInfo = getSvgInfo({
    mt: 20,
    mb: 20,
    ml: 30,
    mr: 30,
    // hard coding width (not sure how to set width programmatically on react-table)
    width: width - 520 > 0 ? width - 520 : 520,
    height: 320,
    datumDefaultColor: 'purple',
    datumDefaultOpacity: 0.5,
    datumBrushedColor: 'hotpink',
    datumBrushedOpacity: 1,
  });

  const {
    chartName,
    chartTitle,
    xVariable: [xKey, xLabelName],
    yVariable: [, yLabelName],
    defaultScoreType,
    downloadNames,
  } = CHART['consensusQALabelerStats'];
  const projectInfo = useProjectInfo();
  const projectName = projectInfo?.project?.name;
  const projectId = projectInfo?.project?.id;

  const authInfo = useAuthInfo();
  const { isGuest } = authInfo;

  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { urlMatchInfo } = routeInfo;
  const labelersInfo = UserUtils.getActiveLabelers(projectInfo);
  const [data, setData] = useState<PlotDatum[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const debounceChangeData = debounce((data: PlotDatum[]) => {
    setData(data);
  }, 200);

  /**
   *  Score Type Selector (dropdown)
   */
  const [scoreType, setScoreType] = useState<ScoreType>(defaultScoreType as ScoreType);
  const scoreTypeOptions = [
    { label: t('analytics.qa.medianScore'), value: 'medianScore' },
    { label: t('analytics.qa.meanScore'), value: 'meanScore' },
  ];

  const changeScoreType = (scoreType: string) => {
    setScoreType(scoreType as ScoreType);
    if (data) {
      // reset data
      setData(resetQALabelerData(data));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const defaultCallArgs = {
          t,
          projectId,
          isGuest,
          urlInfo: urlMatchInfo,
          origin,
        };
        const [labelerSummary] = await Promise.all([
          AdvancedQaService.getConsensusLabelerSummary(defaultCallArgs),
        ]);
        setData(preprocessQALabelers(labelerSummary, labelersInfo));
      } catch (error) {
        reportError({ message: getError(error) });
      }
    })();
  }, []);

  const handleDownload = (outputType: FileFormat) => {
    const selectedUsers = filterSelectedUsers(selectedData, data);
    const downloadReady = translateDownloadColumns(omitColumns(selectedUsers), downloadNames, t);
    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: downloadReady,
        projectName,
        chartName,
      });
      return;
    }
    downloadDataFile({
      downloadData: downloadReady,
      projectName,
      chartName: 'labeler_consensus_summary',
      excelSheetName: snakeCase(lowerCase(t('analytics.qa.text.consistencyScore'))), //#'consistency_score',
    });
  };

  /** Display message for 0 label or 0 submitted consensus labels */
  const getEmptyMessage = () => {
    if (!hasAnyLabels) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    } else if (!hasConsensusLabels) {
      return t(EMPTY_MESSAGE.ADD_CONSENSUS_LABEL);
    } else if (!hasSubmittedConsensusLabels) {
      return t(EMPTY_MESSAGE.SUBMIT_CONSENSUS_LABEL);
    }
    return t(EMPTY_MESSAGE.ASSIGN_CONSENSUS_LABELS);
  };

  if (!data) return <></>;

  return (
    <Card mt={3} mb={10} height={'588px'} width={width}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl={3}
        pt={2}
        pb={2}
        pr={3}
      >
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="headline5" themedColor="textDefault">
            {t(chartTitle)}
          </Typography>
          <DownloadDropDown
            key={'download-dropdown'}
            handleDownload={handleDownload}
            options={['csv', 'xlsx']}
            isToggleDisabled={isEmpty(data)}
          />
        </Box>
        <Box display="flex" mr="5px" alignItems="center" justifyContent="space-between">
          <ScoreTypeDropDownList
            options={scoreTypeOptions}
            changeScoreType={changeScoreType}
            currentScoreType={scoreType}
          />
          {/* {getRightButtonGroup()} */}
        </Box>
      </Box>
      <div id="consensus-labeler-analytics">
        <Box
          display="flex"
          p={2}
          pl={4}
          pr={4}
          alignItems="center"
          justifyContent="center"
          themedBackgroundColor={['primary', 100]}
          themedColor="primary"
          position="relative"
        >
          <Icon name="warningOutline" size="14px" />{' '}
          <Box ml={0.5} display="flex" alignItems="center">
            <Typography variant="body4">
              {t('analytics.qa.text.labelerChartWarningBanner')}
            </Typography>
          </Box>
        </Box>
        <Box p={2} height={'460px'} themedBackgroundColor={['grey', 50]} position="relative">
          <Box pl={1} pr={1}>
            <LabelerQASearch data={data} onDebounceChangeData={debounceChangeData} t={t} />
          </Box>
          <Box display="flex" height={50} justifyContent="space-between" />
          <Box display="flex" justifyContent="flex-start">
            <Box>
              <ScatterPlot
                data={data}
                svgInfo={scatterPlotSvgInfo}
                xKey={xKey}
                yKey={scoreType}
                xLabelName={t(xLabelName)}
                yLabelName={t(yLabelName)}
                setData={setData}
                onDebounceChangeData={debounceChangeData}
                selectedData={selectedData}
                scoreType={scoreType}
              />
            </Box>
            {(isEmpty(data) || !hasSubmittedConsensusLabels) && (
              <EmptyPlot
                marginTop={scatterPlotSvgInfo.top + 75}
                svgHeight={scatterPlotSvgInfo.svgHeight + 10}
                // svgWidth={scatterPlotSvgInfo.width + 4} // 4 is for vertical legend width
                message={getEmptyMessage()}
              />
            )}
            <Box className="labeler-qa-table-container" width={'450px'}>
              <LabelerQATable
                data={data}
                setSelectedData={setSelectedData}
                scoreType={scoreType}
                width={width}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </Card>
  );
};

export default LabelerQAContainer;
