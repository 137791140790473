import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { clone, isArray, isEmpty, set, size, some } from 'lodash';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import {
  FILTER_OPTION_LIMIT,
  PLEASE_ENTER_ALL_REQUIRED_FIELDS,
} from '../../../../consts/SnackbarMessage';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { allFilters, FilterKey } from '../../../../utils/filter/labelFilter';
import ParamUtils from '../../../../utils/ParamUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';
import Filter from '../../label/filter/Filter';
import { FilterInfo, MAX_OPTIONS_SIZE } from '../../label/filter/types';
import useReportFilters from './hooks/useReportFilters';

interface Props {
  filter: string;
  setFilter: (filter: string) => void;
}

const UserReportFilters: FilterKey[] = [
  'status',
  'labelTag',
  'dataset',
  'assignee',
  'review',
  'reviewRound',
  'reviewer',
  'labelId',
  'lastLabeledAt',
  'lastReviewedAt',
];

const ReportFilters: React.FC<Props> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentRow, setCurrentRow] = useState(0);
  const { filters, setFilters } = useReportFilters();

  const projectInfo = useProjectInfo();
  const { tagIds } = projectInfo;

  /**
   * filter (str)
   * - status_in[]=SUBMITTED&status_in[]=WORKING
   * - status_in: ["WORKING", 'SUBMITTED"]
   *
   * paramObj (Object) ex. {statusIn: ["WORKING", "SUBMITTED"]}
   */
  useEffect(() => {
    const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
    if (paramsObj.workAssigneeIn) {
      // Reconstruct assignee name that got lost in the filterUrl
      const workAssigneeIn = paramsObj.workAssigneeIn as string[];
      paramsObj.workAssigneeIn = workAssigneeIn.map(
        a => `${projectInfo?.userObjects?.[a]?.name || a} (${a})`,
      );
    }
    const filterList = ParamUtils.getFiltersFromApiParams(paramsObj, tagIds);

    if (!isEmpty(filterList)) {
      setFilters(filterList);
    } else {
      setFilters([{ filterBy: '', condition: '', options: [] }]);
    }
    // eslint-disable-next-line
  }, [filter]);

  /**
   * filters ex. [
   *   {filterBy: "status", condition: "is any one of", options: [{value: "submitted", label: "submitted"}, {..}]},
   *   {filterBy: "label tag", condition: "contains all of", options: [{value: "hopper", label: "hopper"}]}
   *
   * 1. searchParmams: status=is+any+one+of%2Csubmitted%2Cin+progress
   * 2. queryObjects: {status: "is any one of,submitted,skipped }
   * 3. {statusIn: ["WORKING", "SUBMITTED"]}
   * 4.
   */
  const applyFilterChange = (filters: FilterInfo[]) => {
    const filterParams = ParamUtils.getUrlParamsForFilters(filters);
    const searchParamStr = new URLSearchParams(filterParams).toString();
    const searchParamObj = qs.parse(searchParamStr) as Record<string, string>;
    const apiParamsObj = ParamUtils.getApiParamsForFilter({
      filterParams: searchParamObj,
      tagIds,
      workApp: projectInfo.project.workapp,
    });
    const filterUrl = ServiceUtils.getParamString(apiParamsObj);
    setFilter(filterUrl);
  };

  const handleAddFilter = () => {
    if (size(filters) === 5) return;

    if (some(filters, { filterBy: '' })) {
      enqueueSnackbar(PLEASE_ENTER_ALL_REQUIRED_FIELDS({ t }), {
        variant: 'warning',
      });
      return;
    }

    setFilters([...filters, { filterBy: '', condition: '', options: [] }]);
    setCurrentRow(filters.length);
  };

  const handleRemoveFilter = (index: number) => {
    let newFilters;
    if (filters.length === 1) {
      newFilters = [{ filterBy: '' as const, condition: '', options: [] }];
    } else {
      newFilters = [...filters.slice(0, index), ...filters.slice(index + 1)];
    }
    setFilters(newFilters);
    setCurrentRow(newFilters.length - 1);
    applyFilterChange(newFilters);
  };

  const handleChangeCurrentRow = (index: number) => {
    setCurrentRow(index);
  };

  const handleValueChange = (index: number, key: string, value: string) => {
    if (value === '' && index !== 0) {
      handleRemoveFilter(index);
      return;
    }

    if (isArray(value) && value.length > MAX_OPTIONS_SIZE) {
      enqueueSnackbar(FILTER_OPTION_LIMIT({ t, max: MAX_OPTIONS_SIZE }), { variant: 'info' });
      return;
    }

    const isOptionlessFilter = (filterBy: string, condition: string) => {
      // filters that should be applied even if no options were selected
      return (
        ['dateAdded', 'lastUpdated'].indexOf(filterBy) !== -1 ||
        (filterBy === 'issues' && condition.startsWith('no')) ||
        condition === 'exists' ||
        condition === 'does not exist' ||
        condition === 'is empty' ||
        condition === 'is not empty'
      );
    };

    const copiedFilters = set(clone(filters), [index, key], value);

    if (key === 'filterBy') {
      const defaultCondition = allFilters[value as FilterKey]?.conditions?.[0] ?? '';
      set(copiedFilters, [index, 'condition'], defaultCondition);
      set(copiedFilters, [index, 'options'], []);
    }

    setFilters(copiedFilters);

    if (
      copiedFilters.every(
        filter =>
          isOptionlessFilter(filter.filterBy, filter.condition) ||
          (filter.options && filter.options.length > 0),
      )
    ) {
      applyFilterChange(copiedFilters);
    }
  };

  return (
    <>
      {filters.map((filter, index) => (
        <Filter
          key={filter.filterBy}
          index={index}
          filter={filter}
          filters={UserReportFilters.map(v => allFilters[v])}
          selectedFilters={filters}
          totalFilters={filters.length}
          isCurrentRow={index === currentRow}
          onChangeCurrentRow={handleChangeCurrentRow}
          onValueChange={handleValueChange}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
        />
      ))}
    </>
  );
};

export default ReportFilters;
