import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Chip, IconButton, Typography } from '@superb-ai/norwegian-forest';

import { MemberData, NewRole, Role } from '../../../../types/memberTypes';
import RoleChip from '../../RoleChip';
import AddMembersInput from './AddMembersInput';

type Props = {
  onChangeMembers: (role: NewRole, emails: string[]) => void;
  membersToAdd: Record<NewRole, string[]> | undefined;
  setMembersToAdd: Dispatch<SetStateAction<Record<NewRole, string[]> | undefined>>;
  duplicatedMembers: string[];
  currentMembers: MemberData[];
  boxProps?: Partial<React.ComponentProps<typeof Box>>;
  roles?: ('admin' | 'manager' | 'labeler' | 'collaborator')[];
  placeholder?: string;
};

const AddMembers: React.FC<Props> = ({
  onChangeMembers,
  membersToAdd,
  setMembersToAdd,
  duplicatedMembers,
  currentMembers,
  roles,
  boxProps = {},
  placeholder,
}) => {
  const { t } = useTranslation();
  const membersPerRole = membersToAdd && Object.entries(membersToAdd);
  const isMembersToAddNotSelected = !membersToAdd || Object.keys(membersToAdd).length === 0;

  const handleDeleteMember = (role: Role, index: number, isDuplicated: boolean) => () => {
    if (!membersToAdd) return;

    const remainingList = [
      ...membersToAdd[role].slice(0, index),
      ...membersToAdd[role].slice(index + 1),
    ];
    if (remainingList.length > 0) {
      setMembersToAdd({
        ...membersToAdd,
        [role]: [...membersToAdd[role].slice(0, index), ...membersToAdd[role].slice(index + 1)],
      });
      return;
    }

    const copiedMembersToAdd = { ...membersToAdd };
    delete copiedMembersToAdd[role];
    setMembersToAdd(copiedMembersToAdd);
  };

  return (
    <Box height={400} display="flex" flexDirection="column" {...boxProps}>
      <AddMembersInput
        membersToAdd={membersToAdd}
        onChangeMembers={onChangeMembers}
        currentMembers={currentMembers}
        roles={roles}
        placeholder={placeholder}
      />
      {!isMembersToAddNotSelected ? (
        <Box mt={2} flex={1} overflow="auto">
          {membersPerRole?.map(([role, emails]) =>
            emails.map((email, index) => {
              const isDuplicated =
                duplicatedMembers.length > 0 && duplicatedMembers.indexOf(email) >= 0;
              return (
                <Box
                  key={email}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Box display="flex" alignItems="center" ml={1} width={320}>
                    <Chip color={isDuplicated ? 'strawberry' : 'cloud'}>{email}</Chip>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <RoleChip memberRole={role as Role} />
                  </Box>
                  <IconButton
                    icon="clear"
                    color="grey"
                    size="xxs"
                    onClick={handleDeleteMember(role as Role, index, isDuplicated)}
                  />
                </Box>
              );
            }),
          )}
        </Box>
      ) : (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: 'center' }}
        >
          <Typography variant="body3" themedColor={['grey', 400]}>
            <Trans t={t} i18nKey="invite.members.typeOrPasteEmailAddresses" />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AddMembers;
