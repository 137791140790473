import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, MultiValue } from 'react-select';

import { Select } from '@superb-ai/norwegian-forest';
import { trim } from 'lodash';
import { useSnackbar } from 'notistack';

import RangeConst from '../../../../../../../consts/RangeConst';
import { Option } from '../../../../../../../types/selectTypes';
import { useCurateDatasetService } from '../../../../../services/DatasetService';

export default function SelectProjectTags({
  projectId,
  selectedProjectTagsOption,
  setSelectedProjectTagsOption,
}: {
  projectId?: string;
  selectedProjectTagsOption?: MultiValue<Option>;
  setSelectedProjectTagsOption: Dispatch<SetStateAction<MultiValue<Option> | undefined>>;
}) {
  const { getProjectTags } = useCurateDatasetService();
  const { t } = useTranslation();

  const [tagOptions, setTagOptions] = useState<Option[]>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedProjectTagsOption([]);

    if (!projectId) {
      return;
    }

    (async () => {
      const results = await getProjectTags({ projectId });
      setTagOptions(results.map(result => ({ label: result.name, value: result.id })));
    })();
  }, [projectId]);

  const handleAddTags = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'create-option' && actionMeta.option?.__isNew__) {
      const trimValue = trim(actionMeta.option.value);
      const isLengthValid =
        trimValue.length >= RangeConst.TAG.min && trimValue.length <= RangeConst.TAG.max;

      if (!isLengthValid) {
        enqueueSnackbar(t('labels.editTag.tagNamingRule'), {
          variant: 'warning',
        });
        return;
      }
    }
    setSelectedProjectTagsOption(newValue);
  };

  return (
    <Select.Creatable
      isMulti
      isClearable
      isDisabled={!projectId}
      createOptionPosition="first"
      blurInputOnSelect
      placeholder={t('curate.slices.sendToLabel.applyTagsPlaceholder')}
      createLabelIcon="plus"
      options={tagOptions}
      onChange={handleAddTags}
      value={selectedProjectTagsOption}
    />
  );
}
