import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronLeft, ChevronRight, Copy, Hide, Show } from '@superb-ai/icons';
import { Box, IconButton, Input, Range, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { COPY_SUCCESS } from '../../../../consts/SnackbarMessage';
import FileUtils from '../../../../utils/FileUtils';
import { CurateAnnotation, CurateImageData } from '../../../Curate/services/DatasetService';
import { textEllipsisStyle } from '../../components/components';
import {
  AnnotationType,
  PredictionAnnotationType,
  SamplePredictionData,
} from '../../services/types';
import AnnotationColumn from './AnnotationColumn';
import AnnotationsCabinetCollapse from './AnnotationsCabinetCollapse';
import SamplePredictionCabinetCollapse from './SamplePredictionCabinetCollapse';
import { annotationTypeResolver, classNameResolver } from './utils';

export type AnnotationsInfo = {
  id: string;
  annotation_class: string;
  annotation_type: PredictionAnnotationType;
  [key: string]: any;
};
export type FormattedAnnotation = {
  id: string;
  name: string;
  count: number;
  annotations: AnnotationsInfo[];
  type: AnnotationType;
};

type Props = {
  imageInfo: CurateImageData;
  clickedAnnotationId: string | undefined;
  hoveredAnnotationId: string | undefined;
  minEpoch?: number;
  maxEpoch?: number;
  predictionAnnotation: SamplePredictionData['annotations'] | undefined;
  groundTruthAnnotation: CurateAnnotation[] | undefined;
  categories: SamplePredictionData['categories'] | undefined;
  filteredPredictionAnnotationsIds: string[];
  setFilteredPredictionAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  filteredGroundTruthAnnotationsIds: string[];
  setFilteredGroundTruthAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  epochsRangeIndex?: number;
  setEpochsRangeIndex?: Dispatch<SetStateAction<number>>;
  handleSelectImage: () => void;
  handleClickList: (id: string) => void;
  handleHoverList: (id: string) => void;
  handleLeaveList: () => void;
};

export default function SamplePredictionCabinet({
  imageInfo,
  clickedAnnotationId,
  hoveredAnnotationId,
  handleSelectImage,
  handleClickList,
  minEpoch,
  maxEpoch,
  predictionAnnotation,
  groundTruthAnnotation,
  categories,
  filteredPredictionAnnotationsIds,
  setFilteredPredictionAnnotationsIds,
  filteredGroundTruthAnnotationsIds,
  setFilteredGroundTruthAnnotationsIds,
  epochsRangeIndex,
  setEpochsRangeIndex,
  handleHoverList,
  handleLeaveList,
}: Props) {
  const { t } = useTranslation();
  const [copyButtonVisible, setCopyButtonVisible] = useState<
    'image_key' | 'image_id' | 'image_url'
  >();
  const { enqueueSnackbar } = useSnackbar();
  const showBestEpoch = minEpoch && maxEpoch && epochsRangeIndex && setEpochsRangeIndex;

  const formattedPredictionAnnotations =
    predictionAnnotation &&
    !!predictionAnnotation.length &&
    categories &&
    predictionAnnotation.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
      const annotationType = annotationTypeResolver(annotation, categories);
      const annotationClass = classNameResolver(annotation, categories);
      const key = `${annotation.category_id}-${annotation.image_id}-${annotationType}`;
      return {
        ...acc,
        [key]: {
          type: annotationType,
          id: key,
          name: annotationClass ?? '',
          count: (acc[key]?.count || 0) + 1,
          annotations: [
            ...(acc[key]?.annotations.length > 0 ? acc[key]?.annotations : []),
            {
              id: annotation.id,
              annotation_class: annotationClass,
              annotation_type: annotationType,
            },
          ],
        },
      };
    }, {});

  const formattedGroundTruthAnnotation =
    groundTruthAnnotation &&
    !!groundTruthAnnotation.length &&
    groundTruthAnnotation.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
      const key = `${annotation.annotation_class}-${annotation.image_id}-${annotation.annotation_type}`;
      return {
        ...acc,
        [key]: {
          type: annotation.annotation_type,
          id: annotation.id,
          name: annotation.annotation_class,
          count: (acc[key]?.count || 0) + 1,
          annotations: [
            ...(acc[key]?.annotations.length > 0 ? acc[key]?.annotations : []),
            {
              id: annotation.id,
              annotation_class: annotation.annotation_class,
              annotation_type: annotation.annotation_type,
            },
          ],
        },
      };
    }, {});

  const cabinetWidth = 288;

  const handleClickCopy = () => {
    if (!copyButtonVisible) return;
    FileUtils.copyToClipboard({
      value: {
        image_key: imageInfo.key,
        image_id: imageInfo.id,
        image_url: imageInfo.image_url,
      }[copyButtonVisible],
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label: startCase(copyButtonVisible) }), {
      variant: 'success',
    });
  };

  return (
    <Box pl={2} overflow="auto" height="100%">
      {showBestEpoch && (
        <SamplePredictionCabinetCollapse title={'Epochs'}>
          <Row width="100%" py={0.5} style={{ height: 32 }}>
            <Box mx={1} style={{ width: '100%' }}>
              <Range
                min={minEpoch}
                max={maxEpoch}
                value={epochsRangeIndex}
                onChange={e => setEpochsRangeIndex(Number(e.target.value))}
              />
            </Box>
            <IconButton
              icon={ChevronLeft}
              size="s"
              variant="text"
              disabled={epochsRangeIndex === minEpoch}
              onClick={() => {
                setEpochsRangeIndex(prev => {
                  if (prev === minEpoch) {
                    return prev;
                  } else {
                    return prev - 1;
                  }
                });
              }}
            />
            <Row height="100%" style={{ width: 36 }}>
              <Input
                size="s"
                value={epochsRangeIndex}
                onChange={e => {
                  const nextValue = Number(e.target.value);
                  if (Number.isNaN(nextValue)) {
                    return;
                  }
                  if (nextValue < minEpoch || nextValue > maxEpoch) {
                    return;
                  }
                  setEpochsRangeIndex(nextValue);
                }}
                onFocus={e => {
                  e.target.select();
                }}
                style={{ width: '100%', height: '100%' }}
              />
            </Row>
            <IconButton
              size="s"
              variant="text"
              icon={ChevronRight}
              disabled={epochsRangeIndex === maxEpoch}
              onClick={() => {
                setEpochsRangeIndex(prev => {
                  if (prev === maxEpoch) {
                    return prev;
                  } else {
                    return prev + 1;
                  }
                });
              }}
            />
          </Row>
        </SamplePredictionCabinetCollapse>
      )}
      {!!formattedPredictionAnnotations && (
        <SamplePredictionCabinetCollapse
          disabled={Object.keys(formattedPredictionAnnotations).length === 0}
          title={
            <Row justifyContent="space-between" width="100%">
              <Row gap={1}>
                <Box
                  backgroundColor={'primary'}
                  style={{ width: 5, height: 5, borderRadius: 2.5 }}
                />
                <Typography variant="l-strong">
                  {t('model.myModelDetail.predictionAnnotation')}
                </Typography>
              </Row>
              <IconButton
                disabled={Object.keys(formattedPredictionAnnotations).length === 0}
                inactive={Object.keys(formattedPredictionAnnotations).length === 0}
                icon={
                  filteredPredictionAnnotationsIds.length === predictionAnnotation.length
                    ? Hide
                    : Show
                }
                color={
                  filteredPredictionAnnotationsIds.length === predictionAnnotation.length
                    ? 'cloud'
                    : 'gray'
                }
                variant="text"
                onClick={e => {
                  e.stopPropagation();
                  if (filteredPredictionAnnotationsIds.length > 0) {
                    setFilteredPredictionAnnotationsIds([]);
                  } else {
                    setFilteredPredictionAnnotationsIds(predictionAnnotation.map(x => x.id) ?? []);
                  }
                }}
              />
            </Row>
          }
        >
          <Box display="flex" flexDirection="column" backgroundColor="gray-100">
            {Object.values(formattedPredictionAnnotations).map(annotation => (
              <AnnotationsCabinetCollapse
                annotation={annotation}
                filteredAnnotationsIds={filteredPredictionAnnotationsIds}
                setFilteredAnnotationsIds={setFilteredPredictionAnnotationsIds}
                key={annotation.id}
                clickedAnnotationId={clickedAnnotationId}
                hoveredAnnotationId={hoveredAnnotationId}
              >
                {annotation.annotations.map(list => {
                  return (
                    <AnnotationColumn
                      annotation={list}
                      key={list.id}
                      filteredAnnotationsIds={filteredPredictionAnnotationsIds}
                      setFilteredAnnotationsIds={setFilteredPredictionAnnotationsIds}
                      clickedAnnotationId={clickedAnnotationId}
                      hoveredAnnotationId={hoveredAnnotationId}
                      handleSelectImage={handleSelectImage}
                      handleClickList={handleClickList}
                      handleHoverList={handleHoverList}
                      handleLeaveList={handleLeaveList}
                    />
                  );
                })}
              </AnnotationsCabinetCollapse>
            ))}
          </Box>
        </SamplePredictionCabinetCollapse>
      )}
      {!!formattedGroundTruthAnnotation && (
        <SamplePredictionCabinetCollapse
          title={
            <Row justifyContent="space-between" width="100%">
              <Row gap={1}>
                <Box
                  backgroundColor={'mint-400'}
                  style={{ width: 5, height: 5, borderRadius: 2.5 }}
                />
                <Typography variant="l-strong">
                  {t('model.myModelDetail.groundTruthAnnotation')}
                </Typography>
              </Row>
              <IconButton
                icon={
                  filteredGroundTruthAnnotationsIds.length === groundTruthAnnotation.length
                    ? Hide
                    : Show
                }
                color={
                  filteredGroundTruthAnnotationsIds.length === groundTruthAnnotation.length
                    ? 'cloud'
                    : 'gray'
                }
                variant="text"
                onClick={e => {
                  e.stopPropagation();
                  if (filteredGroundTruthAnnotationsIds.length > 0) {
                    setFilteredGroundTruthAnnotationsIds([]);
                  } else {
                    setFilteredGroundTruthAnnotationsIds(
                      groundTruthAnnotation.map(x => x.id) ?? [],
                    );
                  }
                }}
              />
            </Row>
          }
        >
          <Box display="flex" flexDirection="column" backgroundColor="gray-100">
            {Object.values(formattedGroundTruthAnnotation).map(annotation => (
              <AnnotationsCabinetCollapse
                annotation={annotation}
                filteredAnnotationsIds={filteredGroundTruthAnnotationsIds}
                setFilteredAnnotationsIds={setFilteredGroundTruthAnnotationsIds}
                key={annotation.id}
                clickedAnnotationId={clickedAnnotationId}
                hoveredAnnotationId={hoveredAnnotationId}
              >
                {annotation.annotations.map(list => {
                  return (
                    <AnnotationColumn
                      annotation={list}
                      key={list.id}
                      filteredAnnotationsIds={filteredGroundTruthAnnotationsIds}
                      setFilteredAnnotationsIds={setFilteredGroundTruthAnnotationsIds}
                      clickedAnnotationId={clickedAnnotationId}
                      hoveredAnnotationId={hoveredAnnotationId}
                      handleSelectImage={handleSelectImage}
                      handleClickList={handleClickList}
                      handleHoverList={handleHoverList}
                      handleLeaveList={handleLeaveList}
                    />
                  );
                })}
              </AnnotationsCabinetCollapse>
            ))}
          </Box>
        </SamplePredictionCabinetCollapse>
      )}

      <SamplePredictionCabinetCollapse title={t('model.myModelDetail.properties')} isLast>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_key')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('model.myModelDetail.imageKey')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular">{imageInfo.key}</Typography>
              </p>
              {copyButtonVisible === 'image_key' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
          <Box
            pt={0.5}
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_id')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('model.myModelDetail.imageId')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular">{imageInfo.id}</Typography>
              </p>
              {copyButtonVisible === 'image_id' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
          <Box
            pt={0.5}
            position="relative"
            onMouseEnter={() => setCopyButtonVisible('image_url')}
            onMouseLeave={() => setCopyButtonVisible(undefined)}
            style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">{t('model.myModelDetail.imageUrl')}</Typography>
            <Box>
              <p style={{ ...textEllipsisStyle, width: 240 }}>
                <Typography variant="m-regular">{imageInfo.image_url}</Typography>
              </p>
              {copyButtonVisible === 'image_url' && (
                <IconButton
                  onClick={handleClickCopy}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  icon={Copy}
                  variant="text"
                  size="s"
                />
              )}
            </Box>
          </Box>
        </Box>
      </SamplePredictionCabinetCollapse>
    </Box>
  );
}
