import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, DualRange, Input, Label, Tooltip, Typography } from '@superb-ai/ui';
import { clamp } from 'lodash';

import MathUtils from '../../../../../../../../utils/MathUtils';
import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';
import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';

export default function IOURangeFilter() {
  const { t } = useTranslation();
  const { filterBody, updateSelectedFilter } = useDiagnosisGridFilterContext();
  const { targetIou } = useDiagnosisModelContext();
  const { iouRange } = filterBody;

  const MIN_VALUE = targetIou;
  const MAX_VALUE = 1;

  function handleChangeValue(
    nextValueObject: { min: number } | { max: number } | { includeNull: boolean },
  ) {
    const nextIouRange = {
      min: iouRange?.min || MIN_VALUE,
      max: iouRange?.max || MAX_VALUE,
      includeNull: iouRange?.includeNull || false,
    };

    updateSelectedFilter({
      ...filterBody,
      ...{ iouRange: { ...nextIouRange, ...nextValueObject } },
    });
  }

  const defaultIncludeNull = !iouRange || !!iouRange?.includeNull;

  return (
    <Box display="grid" gap={0.5}>
      <Typography variant="m-strong">{t('curate.diagnosis.filterPanel.iouRange')}</Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Input
          boxProps={{ style: { width: '56px', paddingRight: 0 } }}
          type="number"
          step="0.01"
          value={iouRange?.min || MIN_VALUE}
          onChange={e =>
            handleChangeValue({
              min: MathUtils.round(
                clamp(Number(e.target.value), MIN_VALUE, filterBody.iouRange?.max || MAX_VALUE),
                2,
              ),
            })
          }
        />
        <Box style={{ flex: 1 }}>
          <DualRange
            step={0.05}
            min={MIN_VALUE}
            max={MAX_VALUE}
            values={[iouRange?.min || MIN_VALUE, iouRange?.max || MAX_VALUE]}
            onValueChange={values =>
              handleChangeValue({
                min: MathUtils.round(values[0], 2),
                max: MathUtils.round(values[1], 2),
              })
            }
            onValueInput={values => {
              handleChangeValue({
                min: MathUtils.round(values[0], 2),
                max: MathUtils.round(values[1], 2),
              });
            }}
          />
        </Box>
        <Input
          boxProps={{ style: { width: '56px', paddingRight: 0 } }}
          type="number"
          step="0.01"
          value={iouRange?.max || MAX_VALUE}
          onChange={e =>
            handleChangeValue({
              max: MathUtils.round(
                clamp(Number(e.target.value), filterBody.iouRange?.min || MIN_VALUE, MAX_VALUE),
                2,
              ),
            })
          }
        />
      </Box>
      <Tooltip content={t('curate.diagnosis.filterPanel.iouIncludeNullMessage', { targetIou })}>
        <Label>
          <Checkbox
            value={defaultIncludeNull}
            onClick={() => handleChangeValue({ includeNull: !defaultIncludeNull })}
          />
          {t('curate.diagnosis.text.includeNull')}
        </Label>
      </Tooltip>
    </Box>
  );
}
