import React from 'react';

import { Box, Icon } from '@superb-ai/norwegian-forest';

import { UploadIcon } from '../types';

const IconLogoBadge: React.FC<{ iconName: UploadIcon; width: number; disabled?: boolean }> = ({
  iconName,
  width,
  disabled,
}) => (
  <Box
    width={width}
    height={width}
    display="flex"
    alignItems="center"
    justifyContent="center"
    themedBackgroundColor={['grey', 60]}
    style={{ borderRadius: '50%' }}
  >
    <Icon
      size={`${width / 2}px`}
      color={disabled ? ['grey', 200] : ['grey', 500]}
      name={iconName}
    />
  </Box>
);

export default IconLogoBadge;
