import { Trans, useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, Button, IconButton, Modal, Typography } from '@superb-ai/norwegian-forest';

import ExportDialogStatusBoard from '../ExportDialogStatusBoard';

interface Props {
  isOpen: boolean;
  handleClickClose: () => void;
  statusBoardLoaded: boolean;
  confirmedStatusBoard: boolean;
  checkedLabels: any[];
  filterApiParams: Record<string, any>;
  isAllLabelsChecked: boolean;
  onStatusBoardLoad: (
    params: Record<
      'submittedLabelsCount' | 'labelingInProgressCount' | 'skippedLabelsCount',
      number
    >,
  ) => void;
  addLabelListStatusFilter: () => void;
  handleClickConfirmStatusBoard: () => void;
}

export default function RequestReviewAlert({
  isOpen,
  handleClickClose,
  statusBoardLoaded,
  confirmedStatusBoard,
  checkedLabels,
  filterApiParams,
  addLabelListStatusFilter,
  handleClickConfirmStatusBoard,
  isAllLabelsChecked,
  onStatusBoardLoad,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen}>
      <Box height="0px" pt={1.25} pr={1.25} style={{ textAlign: 'right' }}>
        <IconButton icon="clear" size="m" onClick={handleClickClose} round color="textSecondary" />
      </Box>
      <Box py={2.5} display="flex" justifyContent="center">
        <Typography
          variant="headline4"
          themedColor="primary"
          style={{ visibility: statusBoardLoaded ? 'visible' : 'hidden' }}
        >
          {t('labels.assignDialog.areYouSure')}
        </Typography>
      </Box>
      <Box px={4}>
        <Box
          width="340px"
          mx="auto"
          style={{ visibility: statusBoardLoaded ? 'visible' : 'hidden' }}
        >
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            <Trans t={t} i18nKey={'labels.assignDialog.reviewerWarning'} />
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          my={2}
          style={{ visibility: statusBoardLoaded ? 'visible' : 'hidden' }}
        >
          <LinkButton type="button" onClick={addLabelListStatusFilter}>
            <Typography variant="body3">{t('labels.assignDialog.reviewerDescription')}</Typography>
          </LinkButton>
        </Box>
        <Box
          width="274px"
          mx="auto"
          themedBackgroundColor={['grey', 60]}
          p={2}
          style={{ boxSizing: 'border-box' }}
          borderRadius
        >
          {!confirmedStatusBoard && (
            <ExportDialogStatusBoard
              checkedLabels={checkedLabels}
              filterApiParams={filterApiParams}
              isAllLabelsChecked={isAllLabelsChecked}
              onLoad={onStatusBoardLoad}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" pt={3}>
        <Box display="flex" gap="8px">
          <Button variant="text" color="primary" onClick={handleClickClose}>
            {t('button.cancel')}
          </Button>
          <Button variant="strong-fill" color="primary" onClick={handleClickConfirmStatusBoard}>
            {t('button.next')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

const LinkButton = styled.button`
  background: none;
  color: #5a7bff;
  cursor: pointer;
  border: none;
  position: relative;

  text-decoration: underline;
  text-underline-offset: 0.2em;
`;
