import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ChevronRight } from '@superb-ai/icons';
import { Box, Icon, LinkTypography } from '@superb-ai/ui';

export default function GoToPage({ children, to }: { children: ReactNode; to: string }) {
  return (
    <Box display="flex" alignItems="center">
      <LinkTypography
        variant="m-regular"
        display="flex"
        alignItems="center"
        as={Link}
        color="primary"
        {...{ to: `/auth/${to}` }}
      >
        {children} <Icon icon={ChevronRight} size={16} style={{ transform: 'translateY(1px)' }} />
      </LinkTypography>
    </Box>
  );
}
