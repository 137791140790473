import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Copy, Plus, PlusCircle, Trash } from '@superb-ai/icons';
import { Box, Icon, IconButton } from '@superb-ai/ui';
import { throttle } from 'lodash';

import { overflowOverlayOrAuto } from '../../../utils/style';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '7px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 0.5px #dedede',
  },
  headerLeft: {
    marginLeft: '24px',
  },
  headerRight: {
    display: 'flex',
    marginRight: '12px',
  },
  title: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#ff625a',
  },
  children: {
    flex: 1,
    overflow: overflowOverlayOrAuto(),
  },
  disabledBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,

    background: 'rgba( 150,150,150, 0.1)',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba( 150,150,150, 0.2)',
    },
  },
  disabledAddIcon: {
    width: '140px',
    height: '140px',
    color: '#c0c0c0',
  },
  disabledText: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#c0c0c0',
  },
}));

// eslint-disable-next-line
const ListContainer = (props: any): React.ReactElement => {
  const classes = useStyles();
  const {
    useHeader = false,
    title = '',
    styles = '',
    handleClickAdd = null,
    handleClickCopy = null,
    handleClickDelete = null,
    handleScroll = () => {},
    disabledStrings = [],
    handleClickDisabledBox = null,
    children,
  } = props;

  const getHeader = () => {
    if (!useHeader) return null;

    return (
      <MUI.Box className={classes.header}>
        <MUI.Box className={classes.headerLeft}>
          <MUI.Typography className={classes.title}>{title}</MUI.Typography>
        </MUI.Box>
        <MUI.Box className={classes.headerRight}>
          {handleClickAdd && (
            <MUI.Tooltip title="Add">
              <Box ml={1}>
                <IconButton
                  icon={Plus}
                  size="s"
                  variant="stroke"
                  color="primary"
                  onClick={handleClickAdd}
                />
              </Box>
            </MUI.Tooltip>
          )}
          {handleClickCopy && (
            <MUI.Tooltip title="Copy">
              <Box ml={1}>
                <IconButton icon={Copy} size="s" variant="stroke" onClick={handleClickCopy} />
              </Box>
            </MUI.Tooltip>
          )}
          {handleClickDelete && (
            <MUI.Tooltip title="Delete">
              <Box ml={1}>
                <IconButton icon={Trash} size="s" variant="stroke" onClick={handleClickDelete} />
              </Box>
            </MUI.Tooltip>
          )}
        </MUI.Box>
      </MUI.Box>
    );
  };

  const getDisabledBox = () => {
    if (disabledStrings.length === 0) return null;

    return (
      <MUI.Box className={classes.disabledBox} onClick={handleClickDisabledBox}>
        <Icon icon={PlusCircle} size={140} color="gray-300" />
        {disabledStrings.map((item: any) => (
          <MUI.Typography key={item} className={classes.disabledText}>
            {item}
          </MUI.Typography>
        ))}
      </MUI.Box>
    );
  };

  return (
    <MUI.Box className={`${classes.box} ${styles}`} boxShadow={1}>
      {getHeader()}
      <MUI.Box className={classes.children} onScroll={throttle(handleScroll, 10)}>
        {children}
      </MUI.Box>
      {getDisabledBox()}
    </MUI.Box>
  );
};

export default ListContainer;
