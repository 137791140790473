import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, map, slice } from 'lodash';

import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { convertHashToObject } from '../../../../utils/SpbUtils';
import CheckRadio from '../../../elements/CheckRadio';
import CabinetIssueCardItem from './CabinetIssueCardItem';

const useStyles = makeStyles(() => ({
  box: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '15px',
    cursor: 'pointer',
    padding: '0px 0px 0px 4px',
  },
  left: {},
  indexText: {
    color: '#b0b0b0',
    fontSize: '10px',
    fontWeight: 500,
  },
  right: {
    width: '224px',
    padding: '14px 12px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderTop: 'solid 1px #fff',
  },
  repliesBox: {
    width: '100%',
    marginTop: '12px',
  },
  repliesText: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#ff9772',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

// eslint-disable-next-line
const CabinetIssueCard = ({
  issue,
  cardRef,
  checked,
  selectedCardMap,
  setSelectedCardMap,
}: any): React.ReactElement | null => {
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const labelIssuesInfo = useLabelIssues();
  const [isClickReplies, setIsClickReplies] = useState(false);

  const { openBadgeInfo, issueViewStatus } = labelIssuesInfo;
  const {
    hash,
    history: {
      location: { pathname, search },
    },
  } = routeInfo;
  const { issueComments } = issue;

  const handleClickReplies = () => {
    setIsClickReplies(true);
  };

  const handleClickCard = (): void => {
    const { labelId } = convertHashToObject(hash);
    const nextHash = `#label_id=${labelId}&thread_id=${issue.id}&thread_state=thread`;
    routeInfo.history.push(`${pathname}${search}${nextHash}`);
  };

  const handleClickRadio = (): void => {
    const nextSelectedCardMap = cloneDeep(selectedCardMap);
    nextSelectedCardMap[issue.id] = !checked;
    setSelectedCardMap(nextSelectedCardMap);
  };

  // TODO (tsnoh): backend 에서 마지막 comment를 지웠을 때, comment는 0이되어도 thread 는 삭제되지 않아서 오는 경우가 생김
  if (issueComments.length === 0) return null;

  return (
    <MUI.Box {...{ ref: cardRef }} className={classes.box}>
      <MUI.Box className={classes.left}>
        <CheckRadio
          color={issueViewStatus === 'OPEN' ? '#5a7bff' : '#ff625a'}
          checked={checked}
          handleClick={handleClickRadio}
        />
      </MUI.Box>
      <MUI.Box
        className={`${classes.right}`}
        style={
          openBadgeInfo.issue && issue.threadNumber === openBadgeInfo.issue.threadNumber
            ? { borderTop: `solid 1px ${issue.info.color}` }
            : undefined
        }
        boxShadow={1}
        onClick={handleClickCard}
      >
        <CabinetIssueCardItem comment={issueComments[0]} order="first" />
        {issueComments.length >= 3 && !isClickReplies ? (
          <MUI.Box className={classes.repliesBox}>
            <MUI.Typography className={classes.repliesText} onClick={handleClickReplies}>{`${
              issueComments.length - 2
            } replies`}</MUI.Typography>
          </MUI.Box>
        ) : (
          map(slice(issueComments, 1, issueComments.length - 1), (comment: any) => (
            <CabinetIssueCardItem key={comment.id} comment={comment} order="last" />
          ))
        )}
        {issueComments.length >= 2 && (
          <CabinetIssueCardItem comment={issueComments[issueComments.length - 1]} order="last" />
        )}
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetIssueCard;
