import React from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { addMilliseconds, differenceInMilliseconds } from 'date-fns';

import { commandErrors, commandFallbackErrors } from '../consts/errorMessages';
import { parseDate } from './date';
import { formatCount as c } from './numberFormat';
import ParamUtils from './ParamUtils';
import StringUtils from './StringUtils';

export const CommandTypes = [
  'LABELS_AUTO_LABEL',
  'LABELS_EDIT_TAGS',
  'LABELS_AI_EDIT_TAGS',
  'LABELS_AI_APPLY_CHANGES',
  'LABELS_INITIALIZE',
  'LABELS_SKIP',
  'LABELS_SUBMIT',
  'LABELS_DELETE',
  'LABELS_ASSIGN_LABELER',
  'LABELS_UNASSIGN_LABELER',
  'LABELS_ASSIGN_REVIEWER',
  'LABELS_UNASSIGN_REVIEWER',
  'LABELS_CONSENSUS_SETTING',
  'LABELS_EXPORT',
  'ASSETS_ASSIGN',
  'ASSETS_DOWNLOAD',
  'ASSETS_DELETE',
  'ASSETS_UPDATE_GROUP',
  'ASSETS_CLOUD_UPLOAD',
  'PROJECTS_IMPORT',
  'EXPORTS_ANALYTICS',
  'IMPORT_FROM_CURATE',
  'LABELS_CURATE_SYNC',
] as const;

export type CommandType = (typeof CommandTypes)[number];

export const UncancellableCommandTypes = ['LABELS_AUTO_LABEL', 'ASSETS_DOWNLOAD', 'LABELS_EXPORT'];

export function getCommandPage(command: Command): string | null {
  switch (command.type) {
    case 'ASSETS_ASSIGN':
      const _query = ParamUtils.getQueryParamFromApiFilter(command.params?.filter ?? '');
      const query = _query.indexOf('label+id') === -1 ? _query : ''; // cannot filter label list by asset id
      return command.params?.projectId
        ? `project/${command.params.projectId}/labels?${query}`
        : null;
    case 'ASSETS_DELETE':
      return 'data';
    case 'ASSETS_DOWNLOAD':
      return `project/${command.params.projectId}/export/raw-data`;
    case 'ASSETS_UPDATE_GROUP':
      return `data?dataset=${encodeURIComponent(command.params?.group)}`;
    case 'PROJECTS_IMPORT':
      return command.result?.projectId ? `project/${command.result.projectId}` : 'project/list';
    case 'LABELS_EXPORT':
      return command.project ? `project/${command.project.id}/export/history` : null;
    case 'EXPORTS_ANALYTICS':
      return command.params.exportsAnalyticsId && command.status !== 'CANCELED'
        ? `project/${command.project?.id}/analytics/export-compare/${command.params.exportsAnalyticsId}`
        : `project/${command.project?.id}/analytics/export-compare`;
    case 'IMPORT_FROM_CURATE':
    case 'LABELS_CURATE_SYNC':
      return command.project?.id
        ? `project/${command.project?.id}/labels`
        : // `project/${command.project.id}/labels?dataset=is+any+one+of%2C${command.params?.sliceName}`
          'project/list';

    default:
      if (command.type.startsWith('LABELS_')) {
        return command.project
          ? `project/${command.project.id}/labels?${ParamUtils.getQueryParamFromApiFilter(
              command.params?.filter ?? '',
            )}`
          : null;
      }
      return command.project ? `project/${command.project.id}` : null;
  }
}

export const commandStatuses = [
  'WAITING',
  'PLANNING',
  'PLANNED',
  'APPLYING',
  'FINISHED',
  'CANCELED',
  'FAILED',
] as const;
export type CommandStatus = (typeof commandStatuses)[number];

export type FailReason = { count: number; code: string };

export function getErrorMessage(
  t: TFunction,
  commandType: CommandType,
  error: FailReason | string,
): string {
  if (typeof error === 'string') {
    return error;
  }
  const errorMessage =
    commandErrors(t)[commandType]?.[error.code] ??
    commandFallbackErrors(t)[error.code] ??
    commandFallbackErrors(t).UNKNOWN;
  if (error.count) {
    return `${errorMessage} (${c(error.count)})`;
  }
  return errorMessage;
}

export const InProgressStatuses: CommandStatus[] = ['WAITING', 'PLANNING', 'PLANNED', 'APPLYING'];
export const DoneStatuses: CommandStatus[] = ['FINISHED', 'CANCELED', 'FAILED'];
export const PlanningStatuses: CommandStatus[] = ['WAITING', 'PLANNING'];

export interface Command {
  applyingEndAt: null | string;
  applyingStartAt: null | string;
  canceledAt: null | string;
  canceledBy: string;
  createdAt: string;
  createdBy: string;
  failedAt: null | string;
  finishedAt: null | string;
  id: string;
  params: Record<string, any>;
  planningEndAt: null | string;
  planningStartAt: null | string;
  progress: number;
  project: null | { id: string; name: string };
  result: any;
  status: CommandStatus;
  totalCount: number;
  type: CommandType;
  updatedAt: string;
}

export function isCommandCancellable(command: Command): boolean {
  return (
    InProgressStatuses.includes(command.status) && !UncancellableCommandTypes.includes(command.type)
  );
}

function getDefaultCommandText(command: { type: Command['type']; totalCount: number }): string {
  const commandType = StringUtils.parseConstToFirstUpper(command.type);
  return command.totalCount ? `${commandType} (${command.totalCount})` : commandType;
}

export function CommandText<
  C extends {
    status: Command['status'];
    type: Command['type'];
    totalCount: number;
  },
>({ command }: { command: C }): JSX.Element {
  const { t, i18n } = useTranslation();

  const isPlanning = PlanningStatuses.includes(command.status);

  if (!isPlanning && i18n.exists(`bulkActions.texts.${command.type}_count`)) {
    return <Trans t={t} i18nKey={`bulkActions.texts.${command.type}_count`} values={{ command }} />;
  } else if (i18n.exists(`bulkActions.texts.${command.type}`)) {
    return <Trans t={t} i18nKey={`bulkActions.texts.${command.type}`} values={{ command }} />;
  }

  return <>{getDefaultCommandText(command)}</>;
}

/**
 * Returns a Date of the expected end time calculated based on start time and current progress.
 */
export function estimateTimeLeft(command: Command): null | Date {
  if (!command.applyingStartAt || !command.progress) {
    return null;
  }
  const applyStart = parseDate(command.applyingStartAt);
  const diff = differenceInMilliseconds(new Date(), applyStart);
  const timeLeft = (diff / command.progress) * (command.totalCount - command.progress);
  if (timeLeft <= 0 || isNaN(timeLeft)) {
    return null;
  }
  return addMilliseconds(new Date(), timeLeft);
}

/**
 * Returns the next interval to use for exponential backoff.
 * This curve yields every value 2 times before doubling in the next step.
 */
export function exponentialBackoffTime(step: number, base = 1000): number {
  return base * 2 ** Math.floor(step / 2);
}
