import { createContext, ReactNode, useContext, useState } from 'react';

import { FilterKey } from '../utils/filter/labelFilter';
import { FilterValue } from '../utils/filter/types';
import { StateGetterSetter } from './types';

type FilterContextProps = StateGetterSetter<
  ['selectedFilters', 'setSelectedFilters'],
  FilterValue<FilterKey | ''>[]
>;

const FilterContext = createContext({} as FilterContextProps);

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterValue<FilterKey | ''>[]>([
    { filterBy: '', condition: '', options: [] },
  ]);

  return (
    <FilterContext.Provider
      value={{
        selectedFilters,
        setSelectedFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterInfo = (): FilterContextProps => {
  return useContext(FilterContext);
};
