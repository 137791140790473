import { EditFilter } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';

import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import SliceActionButton from '../../../actionButton/SliceActionButton';

export default function ObjectEmbeddingActions() {
  const { isFilterOpen, setIsFilterOpen, hasAppliedFilters } = useObjectFilterContext();

  return (
    <>
      <SliceActionButton />
      <IconButton
        icon={EditFilter}
        badge={hasAppliedFilters}
        badgeProps={{ style: { width: '6px', height: '6px' } }}
        color="gray"
        variant="soft-fill"
        size="m"
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      />
    </>
  );
}
