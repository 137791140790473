import React from 'react';

import { Card } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';

import { arcGenerator } from './utils/helper';

const SkeletonProgressOverallTrend = () => {
  const svgInfo = {
    svgWidth: 318,
    svgHeight: 300,
    radius: 100,
    innerRadius: 0,
  };
  const gTransform = `translate(${svgInfo.svgWidth / 2},${svgInfo.svgHeight / 2.5})`;
  const arc = arcGenerator(svgInfo.innerRadius, svgInfo.radius);

  const titleSkeleton = (
    <Box display="flex" alignItems="center" justifyContent="flex-start" my={2}>
      <Box
        display="flex"
        alignItems="center"
        backgroundColor="gray-150"
        style={{ height: '16px', width: '168px', borderRadius: '32px' }}
      />
    </Box>
  );

  const staticPieStyle = {
    stroke: 'white',
    strokeWidth: '1px',
    height: 300,
    width: 300,
  };

  const staticPieData = {
    innerRadius: 0,
    outerRadius: 150,
  };

  const dynamicPieData = [
    { fill: '#DCDCDC', startAngle: 0, endAngle: 4 },
    { fill: '#E8E8E8', startAngle: 4, endAngle: 5 },
    { fill: '#F5F5F5', startAngle: 5, endAngle: 6.3 },
  ];

  return (
    <Box mb={4}>
      <Card mt={3}>
        <Box
          display="flex"
          px={3}
          py={1.5}
          alignItems="center"
          justifyContent="space-between"
          style={{ height: '70px' }}
        >
          {titleSkeleton}
        </Box>

        <Box p={3} style={{ background: '#FBFBFB' }}>
          <Box display="grid" style={{ gridTemplateColumns: '35% 30% 35%' }}>
            <Box>
              {titleSkeleton}
              {Array.from({ length: 2 }, (_, k) => (
                <Box
                  key={k}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    backgroundColor="gray-150"
                    style={{ height: '8px', width: '349px', borderRadius: '32px' }}
                  />
                </Box>
              ))}
            </Box>
            <svg
              style={{
                width: svgInfo.svgWidth,
                height: svgInfo.svgHeight / 1.2,
              }}
            >
              <g transform={gTransform}>
                {Array.from({ length: 3 }, (_, k) => (
                  <path
                    key={k}
                    fill={dynamicPieData[k].fill}
                    style={staticPieStyle}
                    d={
                      arc({
                        startAngle: dynamicPieData[k].startAngle,
                        endAngle: dynamicPieData[k].endAngle,
                        ...staticPieData,
                      }) as string
                    }
                  />
                ))}
              </g>
            </svg>
            <Box display="block" style={{ marginTop: '100px' }}>
              {Array.from({ length: 5 }, (_, k) => (
                <Box
                  key={k}
                  display="flex"
                  alignItems="center"
                  backgroundColor="gray-150"
                  mb={2}
                  style={{ height: '8px', width: '330px', borderRadius: '32px' }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default SkeletonProgressOverallTrend;
