import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  CheckFilled,
  Clear,
  ClearFilled,
  ErrorFilled,
  Refresh,
  Unavailable,
  WarningFilled,
} from '@superb-ai/icons';
import { ProgressBar } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, IconButton, LoadingIndicator, Typography } from '@superb-ai/ui';
import { capitalize, lowerCase } from 'lodash';

import { useProjectQuery } from '../../../../queries/useProjectQuery';
import { openChatWidget } from '../../../../utils/chatWidget';
import { formatDateTime, formatDistance } from '../../../../utils/date';
import { useCurateCommandContext } from '../../contexts/CommandContext';
import { useDatasetQuery } from '../../queries/datasetQueries';
import { useDiagnosisDetailQuery } from '../../queries/diagnosisModelQueries';
import { useAutoCurateSlicesQuery, useSliceQuery } from '../../queries/sliceQueries';
import {
  Command,
  CommandStatus,
  CommandType,
  hasParamDestinationSliceId,
  hasParamDiagnosisId,
  hasParamEvaluationFiltersDiagnosisId,
  hasParamProjectId,
  hasParamSliceId,
  hasSnakeParamSliceId,
  PREPARE_DOWNLOAD,
} from '../../types/commandTypes';
import {
  getAutoCurateSliceIds,
  getIsJobSuccess,
  InProgressStatuses,
  isCommandCancellable,
  isCommandRetriable,
} from '../../utils/commandUtils';
import { getConfigPerJobType } from './config';
import { FailDetailMessages, FailReasonBanner } from './FailDetailMessages';
import InfoArea from './InfoArea';
import { useDownloadQuery } from '../../queries/downloadsQueries';

export default function CommandListItem<T extends CommandType>({
  command,
}: {
  command: Command<T>;
}) {
  const commandContext = useCurateCommandContext();
  const { t, i18n } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const history = useHistory();

  const { data: datasetInfo, isLoading: isLoadingDatasetInfo } = useDatasetQuery({
    datasetId: command.param?.datasetId,
    fromPublicDatasets: false,
  });

  const { data: sliceInfo } = useSliceQuery({
    datasetId: command.param?.datasetId,
    sliceId: hasParamSliceId(command.param)
      ? command.param.sliceId
      : hasSnakeParamSliceId(command.param)
      ? command.param.slice_id
      : hasParamDestinationSliceId(command.param)
      ? command.param.destinationSliceId
      : undefined,
    fromPublicDatasets: false,
  });

  const autoCurateSliceIds = getAutoCurateSliceIds(command);
  const autoCurateSlicesResult = useAutoCurateSlicesQuery({
    datasetId: command.param?.datasetId,
    slices: autoCurateSliceIds,
  });
  const autoCurateSlices = autoCurateSlicesResult
    ? autoCurateSlicesResult.reduce((acc, _) => {
        return { ...acc, ..._.data };
      }, {} as Record<'train' | 'val' | 'mislabel' | 'result', { id: string; name: string }>)
    : undefined;

  const { data: diagnosisInfo } = useDiagnosisDetailQuery({
    datasetId: command.param?.datasetId,
    diagnosisId: hasParamDiagnosisId(command.param)
      ? command.param.diagnosisId
      : hasParamEvaluationFiltersDiagnosisId(command.param)
      ? command.param.evaluationFilters?.diagnosisId
      : undefined,
  });

  const { data: projectInfo } = useProjectQuery({
    projectId: hasParamProjectId(command.param) ? command.param?.projectId : undefined,
  });

  const { data: downloadInfo } = useDownloadQuery({
    jobId: command.id,
    disabled: !(command.jobType === PREPARE_DOWNLOAD && getIsJobSuccess(command)),
  });

  const commandConfig = getConfigPerJobType({
    t,
    language: i18n.language,
    history,
    accountName,
    command,
    datasetName: datasetInfo?.name,
    sliceName: sliceInfo?.name,
    modelName: diagnosisInfo?.modelName,
    projectName: projectInfo?.name,
    format: 'bold',
    autoCurateSlices,
    downloadUrl: downloadInfo?.zip_download_url,
    downloadName: downloadInfo?.download_name,
  });

  function getMainIcon(
    status: CommandStatus,
    failCount?: number,
    failReason?: string,
    totalCount?: number,
  ) {
    if (InProgressStatuses.includes(status)) {
      return <LoadingIndicator size="xs" type="spinner-small" color="yellow" />;
    }
    if (status === 'CANCELED') return <Icon icon={Unavailable} color="gray-300" size={14} />;
    if (status === 'CANCELING')
      return <LoadingIndicator size="xs" type="spinner-small" color="yellow" />;
    if (!failCount && status === 'COMPLETE') {
      if (!totalCount) return <Icon icon={ClearFilled} color="red" size={14} />; // No success, no fail
      return <Icon icon={CheckFilled} color="green" size={14} />;
    }
    if (failReason) return <Icon icon={ClearFilled} color="red" size={14} />;
    return <Icon icon={WarningFilled} color="orange" size={14} />;
  }

  function getPartialFailedDetailIcon(status: CommandStatus, failCount: number) {
    // Never show two success icons
    if (status === 'COMPLETE' && !failCount) {
      return <Icon icon={CheckFilled} color="green" size={14} />;
    }
    if (status === 'FAILED' || (status === 'COMPLETE' && failCount)) {
      return <Icon icon={ClearFilled} color="red" size={14} />;
    }
    // if (status === 'CANCELED') {
    //   return <Icon icon="clearFilled" color="cloud" />;
    // }
    return <Icon icon={ErrorFilled} color="red" size={14} />;
  }

  const showProgress = InProgressStatuses.includes(command.status);
  const isJobFailed = command.status === 'FAILED';

  const successCount = command.result?.successCount || 0;
  const failCount = command.result?.failCount || 0;
  const isPartiallyFailed = failCount > 0;

  function handleCancel(e: React.MouseEvent) {
    e.stopPropagation();
    commandContext.cancelCommand(command);
  }

  function handleRetry(e: React.MouseEvent) {
    e.stopPropagation();
    commandContext.retryCommand(command);
  }

  const isClickable = commandConfig.link; // && not deleted dataset

  function handleClick(e: React.MouseEvent) {
    if (!isClickable) return;
    history.push(commandConfig.link || '', new Date());
    commandContext.setIsModalVisible(false);
  }

  return (
    <Box
      key={command.id}
      display="flex"
      flexDirection="column"
      gap={1}
      borderBottom="1px solid"
      borderColor="gray-100"
      backgroundColor={{ hover: isClickable ? 'gray-100' : undefined }}
      cursor={isClickable ? 'pointer' : undefined}
      p={1.5}
      onClick={handleClick}
    >
      {command.param && (
        <Typography variant="s-strong">
          {datasetInfo?.name || t('bulkActions.messages.deletedDataset')}
        </Typography>
      )}
      <Box display="flex" gap={0.5}>
        <Box display="flex" alignItems="center" style={{ maxHeight: '24px' }}>
          {getMainIcon(command.status, failCount, command.failReason, command.totalCount)}
        </Box>
        <Typography variant="m-regular" style={{ paddingTop: '2px' }}>
          {commandConfig.message || capitalize(lowerCase(command.jobType))}
        </Typography>
        {isCommandCancellable(command) && (
          <Box ml="auto">
            <IconButton icon={Clear} color="gray" size="s" onClick={handleCancel} />
          </Box>
        )}
        {isCommandRetriable(command) && (
          <Box ml="auto">
            <IconButton icon={Refresh} color="gray" size="s" variant="text" onClick={handleRetry} />
          </Box>
        )}
      </Box>
      <Box ml={2} display="flex" flexDirection="column" gap={1}>
        {showProgress && (
          <ProgressBar
            tooltip
            height={8}
            color="yellow"
            max={command.totalCount || 1}
            value={command.totalCount === 0 ? 0 : command.progress}
            status={
              (command.status === 'PENDING'
                ? 'WAITING'
                : command.status === 'COMPLETE'
                ? 'SUCCESS'
                : command.status
              ).toLowerCase() as Lowercase<'WAITING' | 'FAILED' | 'IN_PROGRESS' | 'SUCCESS'>
            }
          />
        )}
        {commandConfig.additionalActions && (
          <Box display="flex" gap={0.5}>
            {commandConfig.additionalActions.map(action => (
              <Button
                key={action.text}
                variant="stroke"
                color="gray"
                size="s"
                style={{ flex: 1 }}
                onClick={e => {
                  action.handleClick();
                  e.stopPropagation();
                }}
                disabled={action.disabled}
              >
                {action.text}
              </Button>
            ))}
          </Box>
        )}
        {isJobFailed && <FailReasonBanner />}
        {isPartiallyFailed && (
          <>
            <InfoArea>
              <Box display="flex" alignItems="center" gap={0.5}>
                <Icon icon={CheckFilled} color="green" />
                <Typography variant="s-regular">
                  {t('curate.bulkActions.successCount', {
                    count: successCount,
                  })}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={0.5}>
                {getPartialFailedDetailIcon(command.status, failCount)}
                <Typography variant="s-regular">
                  {t('curate.bulkActions.failCount', { count: failCount })}
                </Typography>
              </Box>
            </InfoArea>
            <Box backgroundColor="primary-100" color="primary-400" p={1}>
              <FailDetailMessages
                details={command.result.failDetail}
                datasetName={datasetInfo?.name || ''}
                sliceName={sliceInfo?.name}
              />
            </Box>
          </>
        )}
        {!isJobFailed && !isPartiallyFailed && commandConfig.additionalMessage && (
          <InfoArea>{commandConfig.additionalMessage}</InfoArea>
        )}
        {(isJobFailed || isPartiallyFailed) && (
          <Box display="flex" gap={0.5}>
            {isPartiallyFailed && command.logDownloadUrl && (
              <Button
                variant="stroke"
                color="gray"
                size="s"
                style={{ flex: 1 }}
                onClick={e => {
                  window.open(command.logDownloadUrl, '_blank', 'noreferrer');
                }}
              >
                {t('curate.bulkActions.button.downloadLogs')}
              </Button>
            )}
            {commandConfig.additionalPartialFailAction && (
              <Button
                variant="stroke"
                color="gray"
                size="s"
                style={{ flex: 1 }}
                onClick={e => {
                  commandConfig.additionalPartialFailAction?.handleClick();
                  e.stopPropagation();
                }}
              >
                {commandConfig.additionalPartialFailAction.text}
              </Button>
            )}

            <Button
              variant="stroke"
              color="gray"
              size="s"
              style={{ flex: 1 }}
              onClick={e => {
                openChatWidget();
                e.stopPropagation();
              }}
            >
              {t('text.contactSupport')}
            </Button>
          </Box>
        )}
        <Box mt={0.5} display="flex" justifyContent="space-between">
          <Typography variant="m-regular" color="gray-300">
            {command.createdBy} ·{' '}
            <span title={formatDateTime(command.createdAt)}>
              {formatDistance(command.createdAt)}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
