import { useState } from 'react';

import constate from 'constate';

import { DrawSelectionAreaMode } from '../modes/DrawSelectionArea';
import { SelectionMode } from '../modes/Selection';
import { SelectionGroupMode } from '../modes/SelectionGroup';

export type Mode = SelectionMode | DrawSelectionAreaMode | SelectionGroupMode;

function _useMode({}: {}) {
  const DEFAULT_SCATTER_MODE = 'selection';
  const [mode, setMode] = useState<Mode>({
    modeName: DEFAULT_SCATTER_MODE,
    state: {
      phase: 'idle',
    },
  });

  return { mode, setMode };
}

export const [ModeProvider, useMode, useSetMode] = constate(
  _useMode,
  ({ mode }) => mode,
  ({ setMode }) => setMode,
);
