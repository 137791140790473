import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Button, Icon } from '@superb-ai/norwegian-forest';

import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../contexts/AppContext';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { getUrl } from '../../../../routes/util';
import MislabelDetectionService from '../../../../services/MislabelDetectionService';
import {
  MislabelDetection,
  MislabelDetectionResultsPerClass,
} from '../../../../types/mislabelDetectionTypes';
import FileUtils from '../../../../utils/FileUtils';
import { db } from '../HistoryTable';
import ResultViewLayout from '../ResultViewLayout';
import DetailModal from './DetailModal';
import { getValidIndexedDbInfo } from './indexedDbUtils';
import MislabelDetectionInfo from './MislabelDetectionInfo';
import ResultViewList from './ResultViewList';

export default function ResultView() {
  const { t } = useTranslation();
  useSetPageTitle(
    t('advancedAIFeatures.mislabelDetection.title'),
    PAGE_TRACKING_ID.labelMislabelDetectionDeprecated,
  );

  const params = useParams<{ accountName: string; projectId: string; resultId: string }>();
  const authInfo = useAuthInfo();

  const [info, setInfo] = useState<MislabelDetection>();
  const [results, setResults] = useState<MislabelDetectionResultsPerClass[]>();

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const [selectedClass, setSelectedClass] = useState<MislabelDetectionResultsPerClass>();
  const [filteredClass, setFilteredClass] = useState<string[]>(['All classes']);

  const filteredResults =
    filteredClass[0] === 'All classes'
      ? results
      : results?.filter(result => filteredClass.indexOf(result.class_id) >= 0);

  const handleCloseModal = () => {
    setSelectedItemIndex(undefined);
    setSelectedClass(undefined);
  };

  useEffect(() => {
    (async () => {
      const dbMislabelHistoryTable = await getValidIndexedDbInfo({
        db: db.mislabelHistoryTable,
        id: params.resultId,
      });
      if (dbMislabelHistoryTable) {
        const { results, ...info } = dbMislabelHistoryTable;
        setInfo(info);
        setResults(results);
        return;
      }

      const res = await MislabelDetectionService.getMislabelDetectionHistory({
        projectId: params.projectId,
        mislabelDetectionId: params.resultId,
        isGuest: authInfo.isGuest,
        urlInfo: { accountName: params.accountName, projectId: params.projectId },
      });
      setInfo(res);

      const resultUrl = res.resultUrl;
      const resultJSON = await FileUtils.readUrlJson(resultUrl);
      setResults(resultJSON);

      await db.mislabelHistoryTable.put({
        ...res,
        results: resultJSON,
        savedAt: new Date(),
      });
    })();
  }, []);

  if (!info || !results) {
    return (
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Icon name="loadingSpinner" size="40px" color="primary" />
      </Box>
    );
  }

  return (
    <ResultViewLayout
      overview={<MislabelDetectionInfo info={info} />}
      title={info.name}
      titleButton={
        <Button
          variant="stroke"
          color="grey"
          IconAdornment="fileOutline"
          onClick={() => window.open(info.reportUrl)}
        >
          {t('advancedAIFeatures.mislabelDetection.button.insightReport')}
        </Button>
      }
      previousPageName={t('advancedAIFeatures.mislabelDetection.history.title')}
      previousPageUrl={getUrl([
        params.accountName,
        'label',
        'project',
        params.projectId,
        'advanced-ai-features',
        'mislabel-detection',
      ])}
      infoMessage={<Trans t={t} i18nKey={'advancedAIFeatures.mislabelDetection.result.info'} />}
    >
      {filteredResults && (
        <ResultViewList
          results={filteredResults}
          columns={5}
          setSelectedItem={setSelectedItemIndex}
          setSelectedClass={setSelectedClass}
        />
      )}
      {selectedItemIndex && selectedItemIndex >= 0 && handleCloseModal && selectedClass && (
        <DetailModal
          isOpen={selectedItemIndex >= 0}
          onClose={handleCloseModal}
          index={selectedItemIndex}
          setIndex={setSelectedItemIndex}
          classInfo={selectedClass}
        />
      )}
    </ResultViewLayout>
  );
}
