import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartBarHorizontal, InfoCircleOutline, List } from '@superb-ai/icons';
import { Card } from '@superb-ai/norwegian-forest';
import { Box, Icon, SegmentedControl, Typography } from '@superb-ai/ui';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import EmptyPlot from '../elements/EmptyPlot';
import ObjectCountsGroupedBarChart from './ObjectCountsGroupedBarChart';
import ObjectCountsTable from './ObjectCountsTable';
import { ComparisonReport } from './types/types';
import { colors } from './utils/colorScale';
import {
  formatCount,
  guideMessage,
  hasZeroAnnotations,
  objectCountsTransformer,
} from './utils/helper';

interface Props {
  selectedVersion: ComparisonReport;
}

type ViewMode = 'grouped-bar' | 'table';

const ExportBadge = ({ type, count }: { type: 'compare' | 'base'; count: number }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" gap={0.5}>
        <Box
          backgroundColor={colors[type]}
          style={{ width: '8px', height: '8px' }}
          borderRadius="100%"
        />
        <Typography variant="m-strong" color={colors[type]} whiteSpace="nowrap">
          {t('shared.total')} {t(`analytics.exportsAnalytics.${type}`)}
        </Typography>
      </Box>
      <Typography display="flex" variant="h1" whiteSpace="nowrap">
        {formatCount(count)}
      </Typography>
    </Box>
  );
};

const ObjectCountsContainer = ({ selectedVersion }: Props) => {
  const { t } = useTranslation();
  const {
    project: { labelInterface },
  } = useProjectInfo();

  const rows = objectCountsTransformer(selectedVersion, labelInterface);
  const [viewMode, setViewMode] = useState<ViewMode>('table');

  const makeChart = (viewMode: string) => {
    if (viewMode === 'grouped-bar') {
      return <ObjectCountsGroupedBarChart rows={rows} />;
    } else {
      return <ObjectCountsTable rows={rows} isDataEmpty={isDataEmpty} />;
    }
  };

  const onChangeViewMode = (mode: ViewMode) => {
    setViewMode(mode);
  };

  const isDataEmpty = hasZeroAnnotations(rows);

  return (
    <Card mt={3}>
      <Box
        display="flex"
        px={3}
        py={3}
        alignItems="center"
        position="relative"
        borderBottom="1px solid"
        borderColor="gray-100"
      >
        <Typography variant="h3">{t('analytics.exportsAnalytics.objectCounts.title')}</Typography>
        <Box position="absolute" style={{ right: '24px' }}>
          <SegmentedControl
            buttonProps={{
              square: true,
            }}
            color="primary-400"
            value={viewMode}
            onChangeValue={onChangeViewMode}
            options={[
              {
                label: <Icon icon={List} />,
                value: 'table',
              },
              {
                label: <Icon icon={ChartBarHorizontal} />,
                value: 'grouped-bar',
              },
            ]}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        backgroundColor="secondary-100"
        color="secondary"
        py={1}
        px={3}
        gap={0.5}
      >
        <Icon icon={InfoCircleOutline} size="16px" />
        <Typography variant="m-regular">
          {t('analytics.exportsAnalytics.compareWarning')}
        </Typography>
      </Box>
      <Box p={6} display="flex" gap={8} style={{ backgroundColor: '#FBFBFB' }} position="relative">
        <Box display="flex" flexDirection="column" gap={2}>
          <ExportBadge type="base" count={rows.at(-1)?.queryCount ?? 0} />
          <ExportBadge type="compare" count={rows.at(-1)?.refCount ?? 0} />
        </Box>
        {makeChart(viewMode)}
        {isDataEmpty && (
          <EmptyPlot
            message={t(guideMessage(selectedVersion))}
            svgHeight={rows.length * 50}
            marginTop={15}
          />
        )}
      </Box>
    </Card>
  );
};

export default ObjectCountsContainer;
