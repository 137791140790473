/*
 * Defines chart display setting for charts in Analytics.
 * Used in Plot.jsx
 *
 * Chart names currently include: 3/11/2020
 *  1- projectProgress
 *  2- objectClassStats
 *  3- imageCategoryStats
 *  4- workerStats
 *
 * How to Update:
 * =============
 * 1) Add new object when new chart is added.
 *
 * Important:
 * =========
 * Keys in (ex. objectClassStats) correspond to keys
 * in Layout.jsx (for rendering Plot)
 * in Plot.jsx (for formatting data)
 * in config/emptyPlotMessage.js (for picking no data message)
 */

/**
 * Analytics button size -> Set size of all chart buttons here
 *  ex. (donut, bar, total, daily, sort, visibility, excludes download)
 */

type ButtonSize = 'm' | 's' | 'xs' | 'xl' | 'xxs' | 'l' | undefined;
const BUTTON_SIZE: ButtonSize = 'm';

/**
 * Typography -> Set Typography style for all chart headings here
 */
// TODO (mlimb): const headerStyle = '...'

export const CHART = {
  projectProgress: {
    title: 'analytics.project.charts.projectProgress', // 'Project Progress',
    index: 1,
    chartKind: 'short',
    chartDefault: 'line',
    xFilterKey: 'date',
    xVariable: ['date', 'Date'], // (original variable name, display name)
    yVariable: ['cumSubmitted', 'Label Count'], // needs update if filter option is expanded
    chartType: 'line',
    sortX: ['date', 'Date', 'asc'], // meaningless if sort button is disabled
    sortY: ['cumSubmitted', 'Labels Count', 'asc'],
    buttonSize: { total: BUTTON_SIZE, daily: BUTTON_SIZE },
    excelSheetName: 'project_label_counts',
    downloadOptions: ['xlsx', 'csv', 'png'],
  },
  objectClassStats: {
    title: 'analytics.project.charts.objectCounts',
    index: 2,
    chartKind: 'regular',
    chartDefault: 'donut',
    xFilterKey: 'classId',
    xVariable: ['displayName', 'analytics.project.sort.object.x'],
    yVariable: ['count', 'analytics.project.sort.object.y'],
    sortX: ['displayName', 'analytics.project.sort.object.x', 'asc'],
    sortY: ['count', 'analytics.project.sort.object.y', 'desc'],
    tooltipKeyMap: {
      x: 'analytics.text.name',
      y: 'analytics.text.count',
      share: 'analytics.text.share',
      xFilter: 'Name (original)',
    },
    buttonSize: { sort: BUTTON_SIZE, donut: BUTTON_SIZE, bar: BUTTON_SIZE },
    excelSheetName: 'object_counts',
    downloadOptions: ['xlsx', 'csv', 'png'],
  },
  imageCategoryStats: {
    title: 'analytics.project.charts.categoryCounts',
    index: 3,
    chartKind: 'regular',
    chartDefault: 'bar',
    xFilterKey: 'name',
    xVariable: ['displayName', 'analytics.project.sort.category.x'],
    yVariable: ['count', 'analytics.project.sort.category.y'],
    sortX: { key: 'displayName', name: 'analytics.project.sort.category.x', direction: 'asc' },
    sortY: { key: 'count', name: 'analytics.project.sort.category.y', direction: 'desc' },
    tooltipKeyMap: {
      x: 'analytics.text.name',
      y: 'analytics.text.count',
      share: 'analytics.text.share',
      xFilter: 'Name (original)',
    },
    buttonSize: { sort: BUTTON_SIZE, donut: BUTTON_SIZE, bar: BUTTON_SIZE },
    excelSheetName: 'category_counts',
    downloadOptions: ['xlsx', 'csv'],
  },
  workerStats: {
    title: 'analytics.userReports.chart.userLabelCounts',
    index: 3,
    chartKind: 'regular',
    chartDefault: 'bar',
    xFilterKey: 'assigneeName',
    xVariable: ['assignee', 'Assignee'],
    yVariable: ['cumSubmitted', 'Labels Count'],
    sVariable: ['assignee', 'Email'],
    sortX: ['assignee_display_name', 'analytics.userReports.sort.userLabels.x', 'asc'],
    sortY: ['currentTotal', 'analytics.userReports.sort.userLabels.y', 'desc'],
    downloadNames: {
      assigneeId: 'assignee_email',
      name: 'assigneeName',
      cumSubmitted: 'submitted_cumulative',
      cumSkipped: 'skipped_cumulative',
      cumInProgress: 'in_progress_cumulative',
    },
    tooltipKeyMap: {
      x: 'analytics.text.name',
      email: 'analytics.text.email',
      y: 'analytics.text.submitted',
    },
    filterKeys: ['assignee', 'assignee_display_name', 'assignee_name', 'date', 'total'],
    statusOptionsDefault: ['cumSubmitted', 'cumWorking', 'cumSkipped'],
    buttonSize: { sort: BUTTON_SIZE, status: BUTTON_SIZE },
    excelSheetName: 'label_counts',
    downloadOptions: ['xlsx', 'csv', 'png'],
  },
  consensusQAStats: {
    // title: 'Consensus Score Histogram',
    title: 'analytics.qa.charts.projectScore',
    chartName: 'consistency_score_histogram',
    chartConfigName: 'consensusQAStats',
    index: 5,
    chartKind: 'big', // TODO (mlimb) - remove after test, if not needed
    chartDefault: 'bar',
    xFilterKey: '',
    xVariable: ['scoreBin', 'analytics.qa.text.consistencyScorePerc'], // TODO: update x-values to show score range
    yVariable: ['labelCount', 'analytics.text.count'],
    // sVariable: ['', ''], // TODO (mlimb) - remove after test
    sortX: ['score', 'Score', 'asc'],
    sortY: ['count', 'Label Count', 'desc'],
    downloadNames: {
      xKey: 'scoreBin',
      yKey: 'labelCount',
      xDisplayName: 'score_bin',
      yDisplayName: 'label_count',
    },
    tooltipKeyMap: {
      x: 'Score (%)',
      y: 'Label',
    },
    // filterKeys: [], // TODO (mlimb) - remove after test
    buttonSize: { sort: BUTTON_SIZE, status: BUTTON_SIZE },
    excelSheetName: 'score_summary',
    downloadOptions: ['xlsx', 'csv', 'png'],
  },
  consensusQALabelerStats: {
    chartTitle: 'analytics.qa.charts.userScore',
    chartName: 'user_consistency_score',
    // chartConfigName: 'consensusQAStats',
    index: 6,
    chartKind: 'big', // TODO (mlimb) - remove after test, if not needed
    chartDefault: 'scatter',
    xFilterKey: '',
    xVariable: ['submittedConsensusLabelCount', 'analytics.qa.text.submittedConsensusLabelCount'],
    yVariable: ['medianScore', 'analytics.qa.score'],
    defaultScoreType: 'medianScore',
    scoreTypeOptions: ['analytics.qa.meanScore', 'analytics.qa.medianScore'],
    downloadNames: {
      email: 'analytics.text.email',
      submittedConsensusLabelCount: 'analytics.qa.text.submittedConsensusLabelCount',
      maxScore: 'analytics.qa.maxScore',
      medianScore: 'analytics.qa.medianScore',
      minScore: 'analytics.qa.minScore',
      meanScore: 'analytics.qa.meanScore',
      consensusLabelCount: 'analytics.qa.text.consensusLabelCount',
    },
    tooltipKeyMap: {
      x: 'Submitted Consensus Label Count',
      y: 'Consistency Score (median)',
    },
    // filterKeys: [], // TODO (mlimb) - remove after test
    buttonSize: { sort: BUTTON_SIZE, status: BUTTON_SIZE },
    excelSheetName: 'user_score_summary',
    // downloadOptions: ['xlsx', 'csv', 'png'],
    downloadOptions: ['xlsx', 'csv'],
  },
  labelingTime: {
    title: 'analytics.project.charts.labelingTime',
    chartName: 'top_labeling_times',
    containerHeight: 520,
    index: 6,
    chartDefault: 'table',
    xFilterKey: '',
    xVariable: ['submittedConsensusLabelCount', 'Submitted Consensus Label Count'],
    yVariable: ['medianScore', 'Score'], // median or average
    buttonSize: { sort: BUTTON_SIZE, status: BUTTON_SIZE },
    excelSheetName: 'user_score_summary',
    downloadOptions: ['xlsx'], // , 'csv'],
  },
  labelerThroughput: {
    title: 'analytics.userReports.chart.labelerSummary',
  },
  reviewerSummary: {
    title: 'analytics.userReports.chart.reviewerSummary',
  },
};

/**
 * User Reports > Annotations Summary Table
 */
export const workerStatsTable = {
  buttonSize: { visibility: BUTTON_SIZE },
};
