import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear, Search } from '@superb-ai/icons';
import { Icon, IconButton, Input } from '@superb-ai/ui';
import { debounce } from 'lodash';

export default function ListSearchInput({
  isFetching,
  handleSearchName,
  defaultValue,
  placeholder,
}: {
  isFetching: boolean;
  handleSearchName: (name?: string) => void;
  defaultValue?: string;
  placeholder?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Input
      ref={inputRef}
      variant="soft-fill"
      color="gray-opacity"
      prefix={<Icon icon={Search} color="gray" />}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={debounce(e => handleSearchName(e.target.value), 500)}
      suffix={
        <IconButton
          variant="text"
          size="s"
          icon={Clear}
          color="gray"
          onClick={() => {
            handleSearchName(undefined);
            inputRef?.current && (inputRef.current.value = '');
          }}
        />
      }
    />
  );
}
