import { Component } from 'react';

import * as Sentry from '@sentry/nextjs';

import ErrorPage from './components/pages/error/ErrorPage';
import ServiceUtils from './utils/ServiceUtils';

export default class ErrorBoundary extends Component<any, { hasError: boolean }> {
  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: Record<string, any>): void {
    if (process.env.NODE_ENV === 'production') {
      Sentry.setTag('app-group', 'suite');
      Sentry.withScope(scope => {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key]);
        });
        ServiceUtils.logSentry(error, 'fatal');
      });
    }
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return <ErrorPage />;
    }
    return <>{props.children}</>;
  }
}
