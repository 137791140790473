type EntriesType = [PropertyKey, unknown][] | ReadonlyArray<readonly [PropertyKey, unknown]>;

type DeepWritable<OBJ_T> = { -readonly [P in keyof OBJ_T]: DeepWritable<OBJ_T[P]> };
type UnionToIntersection<UNION_T> = (UNION_T extends any ? (k: UNION_T) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

type UnionObjectFromArrayOfPairs<ARR_T extends EntriesType> =
  DeepWritable<ARR_T> extends (infer R)[]
    ? R extends [infer key, infer val]
      ? { [prop in key & PropertyKey]: val }
      : never
    : never;
type MergeIntersectingObjects<ObjT> = { [key in keyof ObjT]: ObjT[key] };
type EntriesToObject<ARR_T extends EntriesType> = MergeIntersectingObjects<
  UnionToIntersection<UnionObjectFromArrayOfPairs<ARR_T>>
>;

export const objectFromEntries = <ARR_T extends EntriesType>(
  arr: ARR_T,
): EntriesToObject<ARR_T> => {
  return Object.fromEntries(arr) as EntriesToObject<ARR_T>;
};

type ObjectType = Record<PropertyKey, unknown>;
type PickByValue<OBJ_T, VALUE_T> = Pick<
  OBJ_T,
  { [K in keyof OBJ_T]: OBJ_T[K] extends VALUE_T ? K : never }[keyof OBJ_T]
>;
type ObjectEntries<OBJ_T> = {
  [K in keyof OBJ_T]: [keyof PickByValue<OBJ_T, OBJ_T[K]>, OBJ_T[K]];
}[keyof OBJ_T][];

export const objectEntries = <OBJ_T extends ObjectType>(obj: OBJ_T): ObjectEntries<OBJ_T> => {
  return Object.entries(obj) as ObjectEntries<OBJ_T>;
};

export const reverseKeyValue = <A extends keyof any, B extends keyof any>(
  obj: Record<A, B>,
): Record<B, A> => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));
};

export const getKeyByValue = <T extends string | number | symbol>(
  obj: Record<T, any>,
  targetValue: T,
) => {
  return Object.entries(obj).find(([_key, value]) => value === targetValue)?.[0];
};

export const removeEmptyValue = <T extends string | number | symbol>(obj: Record<T, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => value));
};

export const removeEmptyValueWithoutString = <T extends string | number | symbol>(
  obj: Record<T, any>,
) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_key, value]) => typeof value === 'string' || value),
  );
};

export function mapEntryKeys<
  E extends EntriesType,
  Keys extends Record<E[number][0], (value: any) => any>,
>(entries: E, keys: Keys) {
  // @ts-ignore
  return entries.flatMap(([key, value]) => keys[key as keyof Keys]?.(value) ?? []) as ReturnType<
    ObjectEntries<Keys>[number][1]
  >[];
}

// This code came from https://github.com/fregante/many-keys-weakmap.
const baseMap = Symbol('baseMap');

class Value {
  value: any;

  constructor(value: any) {
    this.value = value;
  }
}

function getLastMap(
  { [baseMap]: map }: Record<typeof baseMap, WeakMap<any, any>>,
  keys: any[],
  create?: boolean,
) {
  if (!Array.isArray(keys)) {
    throw new TypeError('The keys parameter must be an array');
  }

  for (const key of keys) {
    if (!map.has(key)) {
      if (create) {
        map.set(key, new WeakMap());
      } else {
        return undefined;
      }
    }

    map = map.get(key);
  }

  return map;
}

export class ManyKeysWeakMap<K extends object, V> extends WeakMap<K[], V> {
  [baseMap]: WeakMap<K, V>;

  constructor() {
    super();
    this[baseMap] = new WeakMap();

    // eslint-disable-next-line prefer-rest-params
    const [pairs] = arguments; // WeakMap compat
    if (pairs === null || pairs === undefined) {
      return;
    }

    if (typeof pairs[Symbol.iterator] !== 'function') {
      throw new TypeError(
        typeof pairs + ' is not iterable (cannot read property Symbol(Symbol.iterator))',
      );
    }

    for (const [keys, value] of pairs) {
      this.set(keys, value);
    }
  }

  set(keys: any[], value: any) {
    const lastMap = getLastMap(this, keys, true);
    lastMap?.set(Value, value);
    return this;
  }

  get(keys: any[]) {
    const lastMap = getLastMap(this, keys);
    return lastMap ? lastMap.get(Value) : undefined;
  }

  has(keys: any[]) {
    const lastMap = getLastMap(this, keys);
    return Boolean(lastMap) && Boolean(lastMap?.has(Value));
  }

  delete(keys: any[]) {
    const lastMap = getLastMap(this, keys);
    return Boolean(lastMap) && Boolean(lastMap?.delete(Value));
  }

  get [Symbol.toStringTag]() {
    return 'ManyKeysWeakMap';
  }
}
