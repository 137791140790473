import { useEffect, useRef } from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { ClassPerformance } from '../../../../../../../services/DiagnosisAnalyticsService';
import { PERFORMANCE_TABLE_COLORS } from '../colorScale';
import { HeaderCell } from './HeaderCell';
import { NumberCell } from './NumberCell';
import styles from './PerformanceTable.module.scss';

type Props = {
  data: ClassPerformance[];
  selectedClass: string;
};

const PerformanceTable = ({ data, selectedClass }: Props) => {
  const tableRowRef = useRef<(HTMLTableRowElement | null)[]>([]);
  const tableContainerRef = useRef<HTMLTableRowElement | null>(null);
  const { apColumns, apColumnsWithOpactity } = PERFORMANCE_TABLE_COLORS;

  useEffect(() => {
    const index = data.findIndex(d => d.className === selectedClass);
    const headerOffsetY = 40;

    if (tableContainerRef?.current) {
      if (selectedClass === 'all') {
        tableContainerRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
      if (tableRowRef.current && tableRowRef.current[index]) {
        tableContainerRef.current.scrollTo({
          top: (tableRowRef.current[index] as HTMLTableRowElement).offsetTop - headerOffsetY,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [tableRowRef, selectedClass, data]);

  return (
    <Box ref={tableContainerRef} overflow="auto" width="100%" style={{ maxHeight: '300px' }}>
      <table width="100%" className={styles.customTable}>
        <thead style={{ position: 'sticky', zIndex: 1, top: 0 }}>
          <tr>
            <th
              className="widest"
              style={{ backgroundColor: 'white', textAlign: 'left', paddingLeft: '5px' }}
            >
              <HeaderCell columnKey="className" />
            </th>
            <th style={{ backgroundColor: apColumns }}>
              <HeaderCell columnKey="ap" />
            </th>
            <th style={{ backgroundColor: apColumns }}>
              <HeaderCell columnKey="ap50" />
            </th>
            <th className="numeric">
              <HeaderCell columnKey="precision" />
            </th>
            <th className="numeric">
              <HeaderCell columnKey="recall" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: ClassPerformance, index: number) => {
            const isSelectedRow = item.className === selectedClass;
            return (
              <tr
                key={item.className}
                ref={el => {
                  if (el) {
                    tableRowRef.current[index] = el;
                  }
                }}
                style={{
                  backgroundColor: isSelectedRow ? 'rgba(0, 0, 0, 0.04)' : 'white',
                  color: isSelectedRow ? 'rgba(255, 98, 90, 1)' : 'black',
                }}
              >
                <td style={{ textAlign: 'left', paddingLeft: '5px' }}>
                  <Typography
                    variant={isSelectedRow ? 'm-strong' : 'm-regular'}
                    color={isSelectedRow ? 'red-400' : 'black'}
                  >
                    {item.className}
                  </Typography>
                </td>
                <td
                  style={{
                    backgroundColor: isSelectedRow ? apColumns : apColumnsWithOpactity,
                  }}
                >
                  <NumberCell value={item.ap} columnKey="ap" />
                </td>
                <td
                  style={{
                    backgroundColor: isSelectedRow ? apColumns : apColumnsWithOpactity,
                  }}
                >
                  <NumberCell value={item.ap50} columnKey="ap50" />
                </td>
                <td className="numeric">
                  <NumberCell value={item.precision} columnKey="precision" />
                </td>
                <td
                  className="numeric"
                  style={{
                    backgroundColor: isSelectedRow ? apColumns : 'white',
                  }}
                >
                  <NumberCell value={item.recall} columnKey="recall" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default PerformanceTable;
