import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import ClassGroupItem from './ClassGroupItem';
import ClassGroupListDropZone from './ClassGroupListDropZone';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '630px',
    height: '100%',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
}));

const ClassGroupList = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const listRef = useRef<HTMLDivElement>();
  const newProjectInfo = useNewProjectInfo();
  const { root } = newProjectInfo.objectClassGroupMap;
  const rootObjectClassGroups = root.children.filter(
    (item: any) => newProjectInfo.objectClassGroupMap[item].children,
  );
  const [isPressDelete, setIsPressDelete] = useState(false);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedClassGroup = () => {
    helper.deleteObjectClassGroup(newProjectInfo);
  };

  useEffect(() => {
    if (!isPressDelete) return;
    deleteSelectedClassGroup();
    setIsPressDelete(false);
    // eslint-disable-next-line
  }, [isPressDelete]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;

      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClickAdd = () => {
    helper.createObjectClassGroup(newProjectInfo, [], 'root');
    scrollToBottom();
  };

  const handleClickDelete = () => {
    deleteSelectedClassGroup();
  };
  const handleClickCreateGroup = () => {
    const childIds = Object.keys(newProjectInfo.selectedObjectClassIds);
    helper.createObjectClassGroup(newProjectInfo, childIds, 'root');
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer
        useHeader
        title={t('projects.createProject.classGroup')}
        disabledStrings={
          rootObjectClassGroups.length === 0
            ? [t('projects.createProject.clickToCreateClassGroup')]
            : []
        }
        handleClickDisabledBox={handleClickCreateGroup}
        handleClickAdd={handleClickAdd}
        handleClickDelete={handleClickDelete}
      >
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox}>
          <ClassGroupListDropZone />
          {rootObjectClassGroups.map((id: string, index: number) => (
            <ClassGroupItem
              key={id}
              id={id}
              index={index}
              depth={0}
              rootObjectClassGroups={rootObjectClassGroups}
            />
          ))}
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default ClassGroupList;
