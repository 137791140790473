import { Box, Typography } from '@superb-ai/ui';

export default function ActionTutorialHeader({
  title,
  description,
  action,
}: {
  title: string | JSX.Element;
  description: string | JSX.Element;
  action?: JSX.Element;
}) {
  return (
    <Box
      backgroundColor="secondary-100"
      justifyContent="center"
      display="flex"
      style={{ minHeight: '120px' }}
    >
      <Box
        display="flex"
        backgroundColor="secondary-100"
        justifyContent="space-between"
        alignItems="center"
        p={4}
        style={{ width: '100%', maxWidth: '1324px' }}
      >
        <Box>
          <Typography variant="l-strong">{title}</Typography>
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="m-regular">{description}</Typography>
          </Box>
        </Box>
        {action}
      </Box>
    </Box>
  );
}
