import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { AlertDialog, Typography, useDialogState } from '@superb-ai/ui';

import analyticsTracker from '../../../../analyticsTracker';
import { useModelEarlyCompleteMutation } from '../../queries/modelQueries';

export const CompleteTrainingDialog = ({
  state,
  modelId,
  currentEpoch,
  totalEpochs,
}: {
  state: ReturnType<typeof useDialogState>;
  modelId: string;
  currentEpoch: number;
  totalEpochs: number;
}) => {
  const { t } = useTranslation();
  const { mutate: earlyComplete } = useModelEarlyCompleteMutation();
  const { params } = useRouteMatch<{ accountName: string }>();

  return (
    <AlertDialog
      state={state}
      hideOnClickOutside={false}
      hideOnEsc={false}
      style={{ width: 520 }}
      title={t('model.completeTrainingDialog.title')}
      mainButton={{
        label: t('model.completeTrainingDialog.complete'),
        onClick() {
          analyticsTracker.modelCancelCompleteRequested({
            accountId: params.accountName,
            requestedAction: 'early-complete',
            currentEpoch,
            totalEpochs,
          });
          earlyComplete({ id: modelId });
          state.setVisible(false);
        },
      }}
      subButton={{
        label: t('shared.cancel'),
        onClick() {
          state.setVisible(false);
        },
      }}
    >
      <Typography variant="m-regular">{t('model.completeTrainingDialog.content')}</Typography>
    </AlertDialog>
  );
};
