import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { UserApiKey } from '../../../../../services/UserApiKeyService';
import GridTable from '../GridTable';
import { PersonalAccessKeyTableRow } from './PersonalAccessKeyTableRow';

interface Props {
  data: UserApiKey[];
  openConfirmDialog: (id: string) => void;
  openEditDialog: (id: string) => void;
}

export default function PersonalAccessKeyTable({ data, openConfirmDialog, openEditDialog }: Props) {
  const { t } = useTranslation();
  return (
    <Box overflow="auto" style={{ maxHeight: 230 }}>
      <GridTable>
        <GridTable.Thead>
          <GridTable.Tr>
            <GridTable.Th width="minmax(0, 3fr)">
              {t('settings.advanced.accessKeyName')}
            </GridTable.Th>
            <GridTable.Th width="8fr"></GridTable.Th>
            <GridTable.Th width="3fr">{t('settings.advanced.created')}</GridTable.Th>
            <GridTable.Th width="3fr">{t('settings.advanced.expiryDate')}</GridTable.Th>
            <GridTable.Th width="80px">{t('labels.table.labelStatus')}</GridTable.Th>
            <GridTable.Th width="60px"></GridTable.Th>
          </GridTable.Tr>
        </GridTable.Thead>
        <GridTable.Tbody>
          {data.map(key => (
            <PersonalAccessKeyTableRow
              key={key.apiKeyId}
              data={key}
              openConfirmDialog={() => openConfirmDialog(key.apiKeyId)}
              openEditDialog={() => openEditDialog(key.apiKeyId)}
            />
          ))}
        </GridTable.Tbody>
      </GridTable>
    </Box>
  );
}
