import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { Box, Icon, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import cs from 'classnames';

import { useContainerSize } from '../../../../../hooks/ContainerSizeHook';
import { MemberData } from '../../../../../types/memberTypes';
import RoleChip from '../../../../elements/RoleChip';
import TableSortLabel from '../../../../elements/TableSortLabel';
import UserAvatar from '../../../../elements/UserAvatar';

export interface MembersTableProps {
  members: MemberData[];
  columns: string[];
  selectable?: boolean;
  selectedMembers?: MemberData[];
  onToggleSelectMember?: (member: MemberData) => void;
  onToggleSelectAll?: () => void;
  isSelectedAll?: boolean;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  onRequestSort?: (field: string, ascDesc: 'asc' | 'desc') => void;
}

const MembersTable = ({
  selectable,
  columns,
  members,
  selectedMembers,
  onToggleSelectMember,
  isSelectedAll,
  onToggleSelectAll,
  sortBy,
  sortOrder,
  onRequestSort,
}: MembersTableProps) => {
  const { t } = useTranslation();

  const ColToLabel: Record<string, string> = {
    name: t('labels.assignDialog.selectMemberTableHeader.name'),
    email: t('labels.assignDialog.selectMemberTableHeader.email'),
    tenantRole: t('labels.assignDialog.selectMemberTableHeader.tenantRole'),
    submittedCount: t('labels.assignDialog.selectMemberTableHeader.submittedCount'),
    inProgressCount: t('labels.assignDialog.selectMemberTableHeader.inProgressCount'),
    newLabelsCount: t('labels.assignDialog.selectMemberTableHeader.newLabelsCount'),
    reviewedCount: t('labels.assignDialog.selectMemberTableHeader.reviewedCount'),
    unreviewedCount: t('labels.assignDialog.selectMemberTableHeader.unreviewedCount'),
  };

  // Observe container size changes
  // because react-window's FixedSizeList needs to know the available height.
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { height: headerHeight } = useContainerSize(headerRef);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { width, height } = useContainerSize(componentRef);

  const hasColumn = (column: string) => columns.indexOf(column) !== -1;

  const hasNewLabelsCol = hasColumn('newLabelsCount');

  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
    const item = members[index];
    const isSelected =
      selectedMembers && selectedMembers.findIndex(m => m.email === item.email) !== -1;

    const handleKey = (event: React.KeyboardEvent) => {
      if (onToggleSelectMember && event.key === ' ') {
        const elem = event.currentTarget;
        if (elem && elem.parentNode) {
          // A11y: Re-focus element after re-render
          const targetIndex = Array.from(elem.parentNode.children).indexOf(elem);
          setTimeout(() => {
            if (componentRef.current) {
              const newElement = componentRef.current.children[1].children[0].children[
                targetIndex
              ] as HTMLDivElement;
              newElement.focus();
            }
          }, 100);
        }
        onToggleSelectMember(item);
      }
    };

    const handleClick = () => {
      if (onToggleSelectMember) onToggleSelectMember(item);
    };

    const handleCheckboxClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onToggleSelectMember) onToggleSelectMember(item);
    };

    // prettier-ignore
    const selectableProps = selectable ? {
      role: 'option',
      tabIndex: 0,
      'aria-selected': isSelected,
      onKeyPress: handleKey,
      onClick: handleClick,
    } : {};

    return (
      <Box
        {...selectableProps}
        style={style}
        className={cs('tableRow', { isSelected, selectable })}
      >
        {selectable && (
          <Typography variant="body3" className="checkbox-cell">
            <Checkbox
              color="secondary"
              tabIndex={-1}
              onClick={handleCheckboxClick}
              checked={isSelected}
            />
          </Typography>
        )}
        <Typography variant="body3">
          <UserAvatar userInfo={item} size={24} noShadow />
          {item.name}
        </Typography>
        <Typography variant="body3">{item.email}</Typography>
        <Typography
          variant="body3"
          style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}
        >
          <RoleChip memberRole={(item.projectRole || item.tenantRole)?.toLowerCase()} />
        </Typography>
        {hasColumn('submittedCount') && (
          <Typography variant="body3">{item.submittedCount}</Typography>
        )}
        {hasColumn('reviewedCount') && (
          <Typography variant="body3">{item.reviewedCount}</Typography>
        )}
        {hasColumn('inProgressCount') && (
          <Typography variant="body3">{item.inProgressCount}</Typography>
        )}
        {hasColumn('unreviewedCount') && (
          <Typography variant="body3">{item.unreviewedCount}</Typography>
        )}
        {hasNewLabelsCol && (
          <Typography variant="body3" className="highlightNumber">
            {item.newLabelsCount}
          </Typography>
        )}
      </Box>
    );
  };

  const numericalColumns = [
    'submittedCount',
    'inProgressCount',
    'reviewedCount',
    'unreviewedCount',
  ];

  return (
    <TableBox height="100%" className={cs({ hasNewLabelsCol })} role="listbox" ref={componentRef}>
      <Box
        mb={0.5}
        className="tableRow"
        ref={headerRef}
        style={{ boxShadow: 'inset 0 -1px 0 0px #EFEFEF', fontWeight: 500 }}
      >
        {selectable && (
          <span className="checkbox-cell">
            <Checkbox
              color="secondary"
              onClick={() => onToggleSelectAll && onToggleSelectAll()}
              checked={isSelectedAll}
            />
          </span>
        )}
        {columns.map(colName => (
          <Box key={colName}>
            {typeof sortBy !== 'undefined' && typeof onRequestSort !== 'undefined' ? (
              <TableSortLabel
                property={colName}
                onRequestSort={onRequestSort}
                isDesc={sortOrder === 'desc'}
                orderBy={sortBy}
                defaultOrder={numericalColumns.indexOf(colName) !== -1 ? 'desc' : 'asc'}
              >
                <Typography variant="headline7">{ColToLabel[colName] || colName}</Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="headline7">{ColToLabel[colName] || colName}</Typography>
            )}
            {colName === 'newLabelsCount' && (
              <Box ml={0.5} display="inline-flex">
                <Tooltip
                  placement="bottom-end"
                  anchorEl={
                    <Box ml={1} display="inline-flex">
                      <Icon name="infoCircleOutline" size="16px" customColor="#333333" />
                    </Box>
                  }
                >
                  <Box style={{ whiteSpace: 'normal', textAlign: 'left', lineHeight: 1.4 }}>
                    {t('labels.assignDialog.duplicateWarning')}
                  </Box>
                </Tooltip>
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {members.length === 0 && (
        <Box className="tableRow">
          <span className="emptyTableInfo">{t('labels.assignDialog.noMembersFound')}</span>
        </Box>
      )}
      <List itemCount={members.length} itemSize={36} width={width} height={height - headerHeight}>
        {Row}
      </List>
    </TableBox>
  );
};

const TableBox = styled(Box)`
  & .tableRow {
    line-height: 36px;
    display: grid;
    grid-template-columns: 46px 1fr 1.2fr 110px 120px 120px;

    & .selectable {
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: inset 0 0 1px 0 blue;
      }
    }

    & .isSelected,
    & .selectable:hover {
      background-color: #f3f5ff;
    }

    & > * {
      padding: 0 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }

    & > :nth-last-child(1),
    & > :nth-last-child(2) {
      text-align: right;
      justify-content: flex-end;
    }

    & .checkbox-cell {
      padding: 0 10px;

      & + * {
        padding-left: 0;
      }
    }

    & .highlightNumber {
      color: #ff625a;
      font-weight: 700;
    }

    & .emptyTableInfo {
      grid-column: 1 / span 5;
      justify-content: flex-start;
      color: #777;
    }
  }

  &.hasNewLabelsCol .tableRow {
    grid-template-columns: 1fr 1.2fr 110px 120px 120px;
  }
`;

export default MembersTable;
