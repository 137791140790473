import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalStepper } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import { UploadMethod } from '../types';
import CLI from './contents/CLI';
import Cloud from './contents/Cloud';
import File from './contents/File';
import URL from './contents/URL';
import { uploadMethods } from './steps/config';
import ProjectAssign from './steps/ProjectAssign';
import SelectDataset from './steps/SelectDataset';
import SelectUploadMethod from './steps/SelectUploadMethod';

const Image: React.FC = () => {
  const { t } = useTranslation();
  const fileInfo = useUploadInfo();
  const { uploadMethod, files, dataset, selectedProject, setIsStepsCompleted } = fileInfo;

  const routeInfo = useRouteInfo();
  const projectId = routeInfo.urlMatchInfo.projectId;

  const UPLOAD_METHOD = 'upload_method';

  const DATASET = 'dataset';
  const PROJECT = 'project';

  const [projectAssignButtonText, setProjectAssignButtonText] = useState<string>(t('button.skip'));

  const stepPerUploadMethods = {
    CLOUD: Cloud(t),
    FILE: File(t),
    URL: URL(t),
    CLI: CLI(t),
  }[uploadMethod];

  const stepsPerUploadMethod = {
    [UPLOAD_METHOD]: SelectUploadMethod({
      isButtonEnabled: !!uploadMethod,
      options: Object.values(uploadMethods()) as Option<UploadMethod>[],
      hasWarning: !!(files.length || dataset || selectedProject),
      t,
    }),
    ...stepPerUploadMethods,
  };

  const selectDatasetStep = {
    [DATASET]: SelectDataset(t),
  };

  const assignToProjectStep = {
    [PROJECT]: ProjectAssign(t),
  };

  const steps = { ...stepsPerUploadMethod, ...selectDatasetStep };
  const imageUploadSteps = (uploadMethod: UploadMethod) =>
    uploadMethod === 'CLI'
      ? stepsPerUploadMethod
      : projectId
      ? steps
      : { ...steps, ...assignToProjectStep };

  const lastStepButton = {
    text: projectId ? t('button.done') : projectAssignButtonText,
    onClick: () => {
      setIsStepsCompleted(true);
    },
  };

  useEffect(() => {
    if (selectedProject) {
      setProjectAssignButtonText(t('button.done'));
    } else {
      setProjectAssignButtonText(t('button.skip'));
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <VerticalStepper.Simple
      cardProps={{ maxHeight: 482, overflow: 'auto' }}
      steps={imageUploadSteps(uploadMethod)}
      lastStepButton={lastStepButton}
    />
  );
};

export default Image;
