import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import ProjectConst from '../../../../consts/ProjectConst';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import Title from '../Title';
import AnnotationTypeButton from './AnnotationTypeButton';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '55px',
  },
  annotationTypeInner: {
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: 'repeat(4, min-content)',
    justifyContent: 'space-between',
  },
}));

const AnnotationType = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const lidar = useFeatureFlag('lidar');
  const dataTypes = ProjectConst.dataTypes(lidar)[newProjectInfo.selectedDataType];
  const annotationTypes = dataTypes?.annotations ?? [];

  if (annotationTypes.length === 0) return null;

  return (
    <MUI.Box className={classes.box}>
      <Title title={t('labelInterface.annotationType')} />
      <MUI.Box mb="8px" />
      <MUI.Box className={classes.annotationTypeInner}>
        {annotationTypes.map((annotationType: any) => (
          <AnnotationTypeButton key={annotationType.type} annotationType={annotationType} />
        ))}
      </MUI.Box>
    </MUI.Box>
  );
};

export default AnnotationType;
