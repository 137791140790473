import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { CurateAnnotation, CurateImageData } from '../../../../../../services/DatasetService';
import { FormattedAnnotation } from '../../../../../../types/detailViewTypes';
import AnnotationCollapse from '../AnnotationCollapse';
import AnnotationsCabinetCollapse from '../AnnotationsCabinetCollapse';
import ImageInformation from '../detailCabinet/ImageInformation';
import ImageMetadata from '../detailCabinet/ImageMetadata';
import ImageProperties from '../detailCabinet/ImageProperties';
import SyntheticImageInfo from '../detailCabinet/SyntheticImageInfo';
import { excludedAnnotationMetadataKeys, excludedImageMetadataKeys } from './const';
import DetailModalCabinetCollapse from './DetailModalCabinetCollapse';
type Props = {
  imageInfo: CurateImageData;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  clickedAnnotation: CurateAnnotation | null;
  handleSelectImage: () => void;
  closeModal?: () => void;
};

export default function DetailModalCabinet({
  imageInfo,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  clickedAnnotation,
  handleSelectImage,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  const { showPublicDatasets } = usePublicDatasetContext();

  if (!imageInfo) return <></>;

  const metadataEntries = Object.entries(imageInfo.metadata).filter(
    ([key]) => !excludedImageMetadataKeys.includes(key),
  );
  const formattedAnnotations =
    imageInfo.annotations &&
    !!imageInfo.annotations.length &&
    imageInfo.annotations.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
      const key = `${annotation.annotation_class}-${annotation.annotation_type}`;
      return {
        ...acc,
        [key]: {
          type: annotation.annotation_type,
          id: annotation.id,
          name: annotation.annotation_class,
          count: (acc[key]?.count || 0) + 1,
          annotations: [
            ...(acc[key]?.annotations.length > 0 ? acc[key]?.annotations : []),
            {
              id: annotation.id,
              annotation_class: annotation.annotation_class,
              annotation_type: annotation.annotation_type,
              _width: annotation.roi?.width ? Math.floor(annotation.roi?.width) : 0,
              _height: annotation.roi?.height ? Math.floor(annotation.roi?.height) : 0,
              ...annotation?.metadata,
              created_at: annotation.created_at,
              created_by: annotation.created_by,
            },
          ],
        },
      };
    }, {});

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  return (
    <Box pl={2} overflow="auto" height="100%" style={{ width: 288 }}>
      <ImageProperties imageInfo={imageInfo} />
      {imageInfo.is_synthetic && (
        <SyntheticImageInfo imageInfo={imageInfo} closeModal={closeModal} />
      )}
      <ImageMetadata imageInfo={imageInfo} />
      {!!formattedAnnotations && (
        <DetailModalCabinetCollapse title="Annotation">
          <Box display="flex" flexDirection="column" backgroundColor="gray-100">
            {Object.values(formattedAnnotations).map((annotation, index) => (
              <AnnotationsCabinetCollapse
                index={index}
                metadataEntries={metadataEntries}
                annotation={annotation}
                filteredAnnotationsIds={filteredAnnotationsIds}
                setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                key={annotation.id}
                clickedAnnotation={clickedAnnotation}
              >
                {annotation.annotations.map(list => {
                  const keys = Object.keys(list).filter(
                    listName => !excludedAnnotationMetadataKeys.includes(listName),
                  );

                  return (
                    <AnnotationCollapse
                      annotation={list}
                      key={list.id}
                      filteredAnnotationsIds={filteredAnnotationsIds}
                      setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                      clickedAnnotation={clickedAnnotation}
                      handleSelectImage={handleSelectImage}
                    >
                      <Box display="flex" flexDirection="column" mt={0.5}>
                        {keys.map((key, index) => (
                          <Box
                            key={list.id + key}
                            display="flex"
                            alignItems="center"
                            p={1}
                            pl={3}
                            pt={index === 0 ? 0 : 1}
                            backgroundColor={
                              list.id === clickedAnnotation?.id ? 'primary-100' : 'white'
                            }
                          >
                            <Box pl={1.5} style={{ ...textEllipsisStyle, width: '50%' }}>
                              <Typography
                                variant="m-medium"
                                color={
                                  filteredAnnotationsIds.includes(list.id) ? 'gray-300' : 'gray-400'
                                }
                              >
                                {key}
                              </Typography>
                            </Box>
                            <Box style={{ ...textEllipsisStyle, width: '50%' }}>
                              <Typography
                                variant="m-regular"
                                color={
                                  filteredAnnotationsIds.includes(list.id) ? 'gray-300' : 'gray-400'
                                }
                              >
                                {list[key]}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </AnnotationCollapse>
                  );
                })}
              </AnnotationsCabinetCollapse>
            ))}
          </Box>
        </DetailModalCabinetCollapse>
      )}
      {!showPublicDatasets && <ImageInformation imageInfo={imageInfo} />}
    </Box>
  );
}
