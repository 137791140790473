import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, Button, Chip, Input, Typography, useDialogState } from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { formatNumber } from '../../../../utils/numberFormat';
import RegexUtils from '../../../../utils/RegexUtils';
import { MAXIMUM_MODEL_NAME_LENGTH, MINIMUM_MODEL_NAME_LENGTH } from '../../constant';
import { MEMO_MAX_LENGTH } from '../../recognition-ai/detail/EditMemoDialog';
import { useBaselineContext } from '../contexts/BaselineContext';
import { useDatasetClassContext } from '../contexts/DatasetClassContext';
import { useModelSettingContext } from '../contexts/ModelSettingContext';
import { EditTagDialog } from '../EditTagDialog';

export const ModelSettingStep = () => {
  const { t } = useTranslation();
  const { selectedPublicModel, selectedMyModel, setUniqueNameFilter } = useBaselineContext();
  const { datasetName } = useDatasetClassContext();

  useEffect(() => {
    const defaultModelName = selectedMyModel
      ? selectedMyModel.modelSetting.name
      : selectedPublicModel
      ? `${selectedPublicModel.name}-${datasetName}`
      : '';
    setUniqueNameFilter(defaultModelName);
  }, [datasetName, selectedPublicModel, selectedMyModel, setUniqueNameFilter]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ModelName />
      <Tag />
      <Memo />
    </Box>
  );
};

const ModelName = () => {
  const { t } = useTranslation();
  const { uniqueNameFilter, setUniqueNameFilter, isModelNameUnique } = useBaselineContext();
  const invalideNameLength =
    uniqueNameFilter.length > MAXIMUM_MODEL_NAME_LENGTH ||
    MINIMUM_MODEL_NAME_LENGTH > uniqueNameFilter.length;

  return (
    <Box>
      <Row justifyContent="space-between" mb={1}>
        <Typography variant="m-strong">{t('model.train.modelName')}</Typography>
        <Typography
          variant="s-regular"
          color={invalideNameLength ? 'red-400' : undefined}
          ml={'auto'}
          mb={'auto'}
        >
          {`${uniqueNameFilter.length} / ${MAXIMUM_MODEL_NAME_LENGTH}`}
        </Typography>
      </Row>
      <Input
        defaultValue={uniqueNameFilter}
        onChange={e => {
          setUniqueNameFilter(e.currentTarget.value);
        }}
      />
      {!isModelNameUnique && (
        <Typography variant="s-regular" color={'red-400'}>
          {t('model.train.uniqueModelName')}
        </Typography>
      )}
      <Row>
        <Box>
          {invalideNameLength && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                <Trans
                  t={t}
                  i18nKey={'model.train.maximumNameLength'}
                  values={{ min: MINIMUM_MODEL_NAME_LENGTH, max: MAXIMUM_MODEL_NAME_LENGTH }}
                />
              </Typography>
            </Box>
          )}
          {RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter) && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                {t('model.train.notIncludeSpecialCharacter')}
              </Typography>
            </Box>
          )}
        </Box>
      </Row>
    </Box>
  );
};

const Tag = () => {
  const { t } = useTranslation();
  const dialogState = useDialogState();
  const { selectedTags } = useModelSettingContext();

  return (
    <Box>
      <Typography variant="m-strong">{t('model.myModels.tag')}</Typography>{' '}
      <Typography variant="m-regular">({t('model.train.optional')})</Typography>
      <Row width="100%" display="grid" gap={1} mt={1} style={{ gridTemplateColumns: '1fr 79px' }}>
        <Row
          width="100%"
          height="100%"
          border="1px solid"
          borderRadius="2px"
          borderColor={'gray-200'}
          px={1}
        >
          {selectedTags.map(tag => {
            return (
              <Chip key={tag.name} color={tag.color} name={tag.name} mr={1}>
                {tag.name}
              </Chip>
            );
          })}
        </Row>
        <Button variant="strong-fill" onClick={() => dialogState.show()}>
          {t('model.myModelDetail.editTag')}
        </Button>
      </Row>
      {dialogState.visible && <EditTagDialog state={dialogState} />}
    </Box>
  );
};

const Memo = () => {
  const { t } = useTranslation();
  const { memo, setMemo } = useModelSettingContext();
  return (
    <Box>
      <Row justifyContent="space-between">
        <Box>
          <Typography variant="m-strong"> {t('model.myModelDetail.memo')}</Typography>{' '}
          <Typography variant="m-regular">({t('model.train.optional')})</Typography>
        </Box>
        <Typography variant="m-regular" color={memo.length === MEMO_MAX_LENGTH ? 'red' : undefined}>
          {formatNumber(memo.length)}/{formatNumber(MEMO_MAX_LENGTH)}
        </Typography>
      </Row>
      <Textarea
        placeholder={t('model.editMemoDialog.placeholder')}
        value={memo}
        onChange={e => {
          if (e.target.value.length > MEMO_MAX_LENGTH) return;
          setMemo(e.target.value);
        }}
      />
    </Box>
  );
};

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  borderradius: 2px;
  height: 188px;
  padding-block: 8px;
  padding-inline: 12px;
  margin-top: 8px;
  &::placeholder {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b3b3b3;
  }
`;
