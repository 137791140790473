import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, vars } from '@superb-ai/ui';

import { useNoScrollHeight } from '../../../../../../../hooks/NoScrollHeightHook';
import { IMAGE_SCOPE, OBJECT_SCOPE } from '../../../../../types/viewTypes';
import { getScopeFromRoute } from '../../../../../utils/routeUtils';
import { useChartAreaContext } from '../../analytics/contexts/ChartAreaContext';
import { ImagePreviewArea } from './components/ImagePreviewArea';
import { ObjectScatterArea } from './ObjectScatterArea';
import { ScatterArea } from './ScatterArea';

export default function Scatter2DContainer() {
  const { t } = useTranslation();
  const { chartAreaSize, setChartAreaSize } = useChartAreaContext();
  const history = useHistory();
  const scope = getScopeFromRoute(history);
  const containerBoxRef = useRef<HTMLDivElement | null>(null);
  const calculatedContainerHeight = useNoScrollHeight(containerBoxRef);
  const chartAreaRef = useRef<HTMLDivElement | null>(null);

  const [isGridAreaExpanded, setIsGridAreaExpanded] = useState(false);
  const gridAreaWidth = isGridAreaExpanded === false ? '332px' : '538px';

  const cardHeight = calculatedContainerHeight || '100%';
  const commonStyle = {
    boxSizing: 'border-box',
    border: `1px solid ${vars.color['gray-200']}`,
  } as const;

  return (
    <Box ref={containerBoxRef} position="relative" pr={2}>
      <Box display="flex">
        {scope === OBJECT_SCOPE && (
          <ImagePreviewArea
            cardStyle={{
              ...commonStyle,
              borderRadius: '8px 0px 0px 8px',
            }}
            width={gridAreaWidth}
            height={cardHeight}
            isGridAreaExpanded={isGridAreaExpanded}
            setIsGridAreaExpanded={setIsGridAreaExpanded}
          />
        )}
        {scope === IMAGE_SCOPE ? (
          <ScatterArea
            chartAreaRef={chartAreaRef}
            cardStyle={{
              ...commonStyle,
              borderRadius: '8px',
            }}
            width={'100%'}
            height={cardHeight}
            chartAreaSize={chartAreaSize}
            setChartAreaSize={setChartAreaSize}
          />
        ) : (
          <ObjectScatterArea
            chartAreaRef={chartAreaRef}
            cardStyle={{
              ...commonStyle,
              borderLeft: 'none',
              borderRadius: '0px 8px 8px 0px',
            }}
            width={`calc(100% - ${gridAreaWidth})`}
            height={cardHeight}
            chartAreaSize={chartAreaSize}
            setChartAreaSize={setChartAreaSize}
          />
        )}
      </Box>
    </Box>
  );
}
