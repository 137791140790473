import { useState } from 'react';

import { NumericType, TextType } from '@superb-ai/icons';
import { Box, Icon, NamedColorWeight, Typography } from '@superb-ai/ui';

import MathUtils from '../../../../../../../utils/MathUtils';
import PercentageBarChart from '../charts/PercentageBarChart';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { useMetadataAnalyticsContext } from '../contexts/MetadataContext';
import { columnWidths } from '../MetadataKeyTableArea';
import { MetadataKeyDatum, MetadataType } from '../types';
import { chartColors } from '../utils/colorScale';

export default function MetadataTableRow({
  index,
  data,
  comparedData,
  selectionDataTotalCount,
  comparedDataTotalCount,
}: {
  index: number;
  data: MetadataKeyDatum;
  selectionDataTotalCount: number;
  comparedDataTotalCount: number;
  comparedData?: MetadataKeyDatum;
}) {
  const [hoverOnRow, setHoverOnRow] = useState(false);
  const { selectedMetadataKey, setSelectedMetadataKey } = useMetadataAnalyticsContext();
  const { isCompareMode } = useAnalyticsContext();
  const widths = columnWidths;

  function getMetadataTypeIcon(type: MetadataType) {
    return {
      Number: <Icon icon={NumericType} />,
      String: <Icon icon={TextType} />,
    }[type];
  }

  const percentage = MathUtils.calculatePercent({
    numerator: data.imageCount,
    denominator: selectionDataTotalCount,
  });
  const comparedPercentage = !comparedData
    ? 0
    : MathUtils.calculatePercent({
        numerator: comparedData.imageCount,
        denominator: comparedDataTotalCount,
      });

  const renderMetric = (
    title: string,
    value: string | number,
    comparedValue: string | number | undefined,
    width: string,
    style?: React.CSSProperties,
  ) => {
    return (
      <Box
        key={title}
        gap={1}
        flexDirection="column"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="m-medium" style={{ width, textAlign: 'right', ...style }}>
          {value}
        </Typography>
        {isCompareMode && comparedValue && (
          <Typography variant="m-medium" style={{ width, textAlign: 'right', ...style }}>
            {comparedValue}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      backgroundColor={index % 2 === 0 ? 'gray-100' : 'white'}
      width="100%"
      style={{ minHeight: '48px' }}
    >
      <Box
        px={1}
        py={1.5}
        display="flex"
        alignItems="center"
        style={{ minHeight: '48px' }}
        gap={0.5}
        onMouseEnter={() => setHoverOnRow(true)}
        onMouseLeave={() => setHoverOnRow(false)}
        cursor={'pointer'}
        backgroundColor={
          selectedMetadataKey === data.key
            ? 'primary-opacity-100'
            : hoverOnRow
            ? 'gray-opacity-100'
            : undefined
        }
        onClick={e => {
          e.stopPropagation();
          setSelectedMetadataKey(data.key);
        }}
      >
        {getMetadataTypeIcon(data.type)}
        <Typography
          variant="m-medium"
          style={{
            width: widths.key,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {data.key}
        </Typography>
        {renderMetric(
          'valueCount',
          data.valueCount.toLocaleString('en'),
          comparedData?.valueCount.toLocaleString('en'),
          widths.valueCount,
          { marginRight: 10 } as const,
        )}
        <Box pl={4} pr={2} style={{ flex: 1 }} display="flex" flexDirection="column" gap={1}>
          <PercentageBarChart
            showLegend
            chartDatum={[
              {
                key: 'metadata_name-current',
                name: data.key,
                total: selectionDataTotalCount,
                value: data.imageCount,
                share: percentage,
                barColor: chartColors['metadataKey'][0] as NamedColorWeight,
              },
            ]}
          />
          {isCompareMode && (
            <PercentageBarChart
              showLegend
              chartDatum={[
                {
                  key: 'metadata_name-current',
                  name: data.key,
                  total: comparedDataTotalCount,
                  value: comparedData?.imageCount || 0,
                  share: comparedPercentage,
                  barColor: chartColors['comparedKey'][0] as NamedColorWeight,
                },
              ]}
            />
          )}
        </Box>
        {renderMetric(
          'imageCount',
          data.imageCount.toLocaleString('en'),
          comparedData?.imageCount?.toLocaleString('en'),
          widths.imageCount,
        )}
      </Box>
    </Box>
  );
}
