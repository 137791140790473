import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import {
  Alarm,
  ChevronRight,
  Clear,
  Copy,
  LoadingSpinnerAlt,
  MoreVertical,
  Plus,
  Search,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  extendComponent,
  Icon,
  IconButton,
  Input,
  LinkTypography,
  Popover,
  Select,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { ReactComponent as ListIsEmptySvg } from '../../../../assets/img/list_is_empty.svg';
import { Row } from '../../../../components/elements/Row';
import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import {
  COPY_SUCCESS,
  MODEL_ENDPOINT_DELETED,
  MODEL_ENDPOINT_STATUS_PAUSED,
} from '../../../../consts/SnackbarMessage';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { hasSufficientModelEndpointVolume } from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import { getUrl } from '../../../../routes/util';
import FileUtils from '../../../../utils/FileUtils';
import { PurposeChip } from '../../components/components';
import { GenerationAIDetailsMenuItem } from '../../gen-ai/details/MenuItem';
import { useIntersect } from '../../hooks';
import { GENERATION_MODEL, MODEL_ENDPOINTS, RECOGNITION_MODEL } from '../../path';
import {
  useDeleteEndpointMutation,
  useDeployedModelListQuery,
  useEndpointFilterQuery,
  useModelDetailQuery,
  useModelEndpointQueries,
  useModelEndpointsQuery,
  usePauseEndpointMutation,
  usePublicModelQuery,
} from '../../queries/modelQueries';
import { RecognitionAIDetailMenuItem } from '../../recognition-ai/detail/MenuItem';
import { Endpoint, EndpointStatus, PurposeType } from '../../services/types';
import { useTrainUrlParams } from '../../train/queries';
import { CreateEndpointDialog } from '../CreateEndpointDialog';
import { EndpointsDetailMenuItem } from '../detail/MenuItem';
import { EndpointsSettingsDialog } from '../EndpointsSettingsDialog';
import { ResumeEndpointDialog } from '../ResumeEndpointDialog';
import { SchedulePauseDialog } from '../SchedulePauseDialog';

export function Layout() {
  const { t } = useTranslation();
  const params = useTrainUrlParams();
  const [searchEndpointNameFilter, setSearchEndpointNameFilter] = useState('');
  const debouncedEndpointNameFilter = useDebounce(searchEndpointNameFilter, 300);
  const [modelPurpose, setModelPurpose] = useState<PurposeType | null>(params.from ?? null);
  const { data, isLoading, fetchNextPage, hasNextPage } = useModelEndpointsQuery({
    params: { name: debouncedEndpointNameFilter, modelPurpose: modelPurpose ?? undefined },
  });
  const endpointList = data?.pages.flatMap(p => p.endpointList) ?? [];
  const statusChangingEndpointIds = endpointList
    .filter(endpoint => endpoint.status === 'starting' || endpoint.status === 'pausing')
    .map(endpoint => endpoint.id);
  const statusChangingEndpointListQueries = useModelEndpointQueries({
    ids: statusChangingEndpointIds,
  });

  const endpointQuantity = useMetering('model:endpoint');
  const canUseModelEndpoint = endpointQuantity.maxQuantity > 0;
  const isEndpointMaxCount = !hasSufficientModelEndpointVolume(endpointQuantity);
  const deployedModelsQuery = useDeployedModelListQuery();
  const { data: endpointFilter } = useEndpointFilterQuery({});
  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const [selectedDeployedModelId, setSelectedDeployedModelId] = useState<{
    name: string;
    id: string;
  } | null>(null);

  const statusChangingEndpointList = statusChangingEndpointListQueries
    .filter(_ => _.data)
    .map(_ => _.data) as Endpoint[];

  const deployedModelSelectOptions = endpointFilter
    ? endpointFilter.modelList
        .map(model => {
          return {
            label: model.name,
            value: { id: model.id, name: model.name },
          };
        })
        .filter(model => model.label.toLowerCase().includes(searchModelNameFilter.toLowerCase()))
    : [];

  useEffect(() => {
    if (!deployedModelsQuery.data) return;
    if (!params.deployedModelId) return;
    const deployedModel = deployedModelSelectOptions.find(
      x => x.value.id === params.deployedModelId,
    );
    setSelectedDeployedModelId(deployedModel?.value ?? null);
  }, [deployedModelsQuery.data]);

  const getMergedEndpointList = () => {
    const map = new Map<string, Endpoint>();
    for (const statusChangingEndpoint of statusChangingEndpointList) {
      map.set(statusChangingEndpoint.id, statusChangingEndpoint);
    }
    const mergedArray: Endpoint[] = [];
    for (const endpoint of endpointList) {
      const statusChangingEndpoint = map.get(endpoint.id);

      if (statusChangingEndpoint) {
        mergedArray.push(statusChangingEndpoint);
      } else {
        mergedArray.push(endpoint);
      }
    }

    return selectedDeployedModelId
      ? mergedArray.filter(x => x.model.id === selectedDeployedModelId.id)
      : mergedArray;
  };

  if (!debouncedEndpointNameFilter && !modelPurpose && endpointList.length === 0) {
    return (
      <ListIsEmpty>
        <CreateEndpointDialog
          disclosure={
            <Button
              size="l"
              color="primary"
              variant="strong-fill"
              disabled={isEndpointMaxCount || !canUseModelEndpoint}
              style={{ width: 'max-content' }}
            >
              <Icon icon={Plus} size={20} />
              {t('model.endpoints.createEndpoint')}
            </Button>
          }
        />
      </ListIsEmpty>
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center" width="100%" mb={3}>
        <Row gap={1}>
          <Box style={{ width: 240 }}>
            <Input
              color="gray"
              size="m"
              variant="soft-fill"
              prefix={<Icon icon={Search} />}
              placeholder={t('model.endpoints.searchInputPlaceholder')}
              value={searchEndpointNameFilter}
              onChange={e => setSearchEndpointNameFilter(e.target.value)}
            />
          </Box>
          <Box display="flex" style={{ width: 170 }}>
            <Select
              value={modelPurpose}
              placeholder={t('model.endpoints.selectModelPurposePlaceholder')}
              data={[
                { label: t('model.generativeAi.title'), value: 'generation' as PurposeType },
                { label: t('model.train.recognitionAI'), value: 'recognition' as PurposeType },
              ]}
              onChangeValue={v => setModelPurpose(v)}
              formatValue={v => (
                <Row>
                  <Box>
                    <Typography>
                      {v === 'generation'
                        ? t('model.generativeAi.title')
                        : t('model.train.recognitionAI')}
                    </Typography>
                  </Box>
                  <IconButton
                    icon={Clear}
                    variant="text"
                    size="s"
                    onClick={e => {
                      e.stopPropagation();
                      setModelPurpose(null);
                    }}
                  />
                </Row>
              )}
            />
          </Box>
          <Box display="flex" style={{ width: 200 }}>
            <Select
              value={selectedDeployedModelId}
              data={deployedModelSelectOptions}
              placeholder={t('model.endpoints.selectDeployedModelPlaceholder')}
              onChangeValue={v => {
                setSelectedDeployedModelId(v);
              }}
              prefix={
                <Box
                  p={0.5}
                  onClick={e => e.stopPropagation()}
                  borderBottom="1px solid"
                  borderColor="gray-200"
                >
                  <Input
                    size="m"
                    variant="text"
                    prefix={<Icon icon={Search} />}
                    placeholder={t('model.train.modelSearchPlaceholder')}
                    value={searchModelNameFilter}
                    onChange={e => setSearchModelNameFilter(e.target.value)}
                  />
                </Box>
              }
              formatValue={v => (
                <Row>
                  <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    <Typography>{v.name}</Typography>
                  </Box>
                  <IconButton
                    icon={Clear}
                    variant="text"
                    size="s"
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedDeployedModelId(null);
                    }}
                  />
                </Row>
              )}
            />
          </Box>
        </Row>
        <Row marginLeft="auto" display="flex" alignItems="center" gap={3}>
          <EndpointCounts
            activeCount={endpointQuantity.quantity}
            maxCount={endpointQuantity.maxQuantity}
          />
          <Tooltip
            strategy="fixed"
            placement="top-end"
            hideOnEmptyContent
            content={isEndpointMaxCount ? t('model.endpoints.disableCreateEndpoint') : undefined}
          >
            <Box>
              <CreateEndpointDialog
                disclosure={
                  <Button
                    size="l"
                    color="primary"
                    variant="strong-fill"
                    disabled={isEndpointMaxCount || !canUseModelEndpoint}
                    style={{ width: 'max-content' }}
                  >
                    <Icon icon={Plus} size={20} />
                    {t('model.endpoints.createEndpoint')}
                  </Button>
                }
              />
            </Box>
          </Tooltip>
        </Row>
      </Box>
      <EndpointListTable
        endpointList={getMergedEndpointList()}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
      />
    </>
  );
}

export const EndpointListTable = ({
  endpointList,
  fetchNextPage,
  hasNextPage,
  isLoading,
  showDeployedModelColumn = true,
}: {
  endpointList: Endpoint[];
  fetchNextPage: ReturnType<typeof useModelEndpointsQuery>['fetchNextPage'];
  hasNextPage: ReturnType<typeof useModelEndpointsQuery>['hasNextPage'];
  isLoading: ReturnType<typeof useModelEndpointsQuery>['isLoading'];
  showDeployedModelColumn?: boolean;
}) => {
  return (
    <>
      <EndpointList
        endpointsList={endpointList}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        showDeployedModelColumn={showDeployedModelColumn}
      />
    </>
  );
};

const EndpointCounts = ({ activeCount, maxCount }: { activeCount: number; maxCount: number }) => {
  const { t } = useTranslation();
  return (
    <Row style={{ width: 'max-content' }}>
      <Typography variant="m-medium" mr={0.5}>
        {t('model.endpoints.endpointCounts')}
      </Typography>
      <Box backgroundColor={'gray-200'} mx={1.5} style={{ width: 1, height: 8 }} />
      <Typography variant="m-medium">
        {activeCount} / {maxCount}
      </Typography>
    </Row>
  );
};

const EndpointList = ({
  endpointsList,
  fetchNextPage,
  hasNextPage,
  isLoading,
  showDeployedModelColumn = true,
}: {
  endpointsList: Endpoint[];
  fetchNextPage: ReturnType<typeof useModelEndpointsQuery>['fetchNextPage'];
  hasNextPage: ReturnType<typeof useModelEndpointsQuery>['hasNextPage'];
  isLoading: ReturnType<typeof useModelEndpointsQuery>['isLoading'];
  showDeployedModelColumn?: boolean;
}) => {
  const { t } = useTranslation();
  const measureRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isLoading) {
      await fetchNextPage();
    }
  });

  return (
    <>
      <HeaderRow
        style={{
          gridTemplateColumns: showDeployedModelColumn
            ? '56px minmax(149px, 1fr) minmax(192px, 1.5fr) minmax(231px, 1.5fr) 140px 140px 32px'
            : '56px minmax(149px, 1fr) minmax(341px, 2.5fr) 140px 140px 32px',
        }}
      >
        <HeaderLabel>{t('model.train.status')}</HeaderLabel>
        <HeaderLabel>{t('shared.name')}</HeaderLabel>
        <HeaderLabel>{t('model.endpoints.url')}</HeaderLabel>
        {showDeployedModelColumn && <HeaderLabel>{t('model.endpoints.deployedModel')}</HeaderLabel>}
        <HeaderLabel>{t('model.endpoints.createdAt')}</HeaderLabel>
        <HeaderLabel>{t('model.endpoints.startedPausedAt')}</HeaderLabel>
      </HeaderRow>
      {endpointsList.length > 0 ? (
        endpointsList.map(data => {
          return (
            <EndPointListItem
              key={data.id}
              data={data}
              showDeployedModelColumn={showDeployedModelColumn}
            />
          );
        })
      ) : (
        <Row justifyContent="center" py={5} style={{ height: 32 }}>
          <Typography variant="l-regular">{t('model.endpoints.noEndpointFound')}</Typography>
        </Row>
      )}
      {hasNextPage && (
        <Row ref={measureRef} width="100%" style={{ height: 50, justifyContent: 'center' }}>
          <Icon icon={LoadingSpinnerAlt} size={32} />
        </Row>
      )}
    </>
  );
};

const HeaderLabel = extendComponent(Typography, {
  variant: 'm-medium',
});

const HeaderRow = extendComponent(Row, {
  display: 'grid',
  px: 1.5,
  bb: '1px solid',
  borderColor: 'gray-400',
  style: {
    height: 32,
    gap: '20px',
  },
});

const EndPointListItem = ({
  data,
  showDeployedModelColumn = true,
}: {
  data: Endpoint;
  showDeployedModelColumn?: boolean;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { url, params } = useRouteMatch<{ accountName: string }>();
  const { mutate: deleteEndpoint } = useDeleteEndpointMutation();
  const { mutate: pauseEndpoint } = usePauseEndpointMutation();
  const [isHovered, setIsHovered] = useState<string | null>(null);

  const endpointQuantity = useMetering('model:endpoint');
  const isEndpointMaxCount = !hasSufficientModelEndpointVolume(endpointQuantity);
  const settingDialogState = useDialogState();
  const schedulePauseDialogState = useDialogState();
  const resumeEndpointDialogState = useDialogState();
  const { data: baselineModel } = usePublicModelQuery({ id: data.baselineModel.id });
  const { data: model, isLoading: isModelLoading } = useModelDetailQuery({
    id: data.model.id,
    modelPurpose: data.baselineModel.purpose,
  });

  const getStatusChipColor = (status: EndpointStatus) => {
    switch (status) {
      case 'starting':
        return 'yellow';
      case 'running':
        return 'green';
      case 'pausing':
        return 'orange';
      case 'paused':
        return 'cloud';
      case 'failed':
        return 'red';
      default:
        return;
    }
  };

  const handleClickCopy = (value: string, label: string) => {
    FileUtils.copyToClipboard({
      value,
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label }), {
      variant: 'success',
    });
  };

  const statusUpdatedAt = (status: EndpointStatus, updatedAt: number) => {
    switch (status) {
      case 'starting':
      case 'running':
        return (
          <Trans
            t={t}
            i18nKey={'model.endpoints.startedAt'}
            values={{
              time: updatedAt ? format(new Date(updatedAt), 'M/d/yy h:mm a') : '-',
            }}
          />
        );
      case 'paused':
      case 'pausing':
        return (
          <Trans
            t={t}
            i18nKey={'model.endpoints.pausedAt'}
            values={{
              time: updatedAt ? format(new Date(updatedAt), 'M/d/yy h:mm a') : '-',
            }}
          />
        );
      default:
        return undefined;
    }
  };

  const handleClickEndpointDetail = () => {
    history.push(
      getUrl([params.accountName, MODEL_ENDPOINTS, EndpointsDetailMenuItem.path], {
        id: data.id,
      }),
    );
  };

  const cannotDelete = data.status !== 'failed' && data.status !== 'paused';
  const cannotTestEndpoint = data.status !== 'running';

  return (
    <TableRow
      key={data.id}
      onMouseEnter={() => {
        setIsHovered(data.id);
      }}
      onMouseLeave={() => {
        setIsHovered(null);
      }}
      backgroundColor={{
        default: undefined,
        hover: 'gray-100',
      }}
      style={{
        gridTemplateColumns: showDeployedModelColumn
          ? '56px minmax(149px, 1fr) minmax(192px, 1.5fr) minmax(231px, 1.5fr) 140px 140px 32px'
          : '56px minmax(149px, 1fr) minmax(341px, 2.5fr) 140px 140px 32px',
      }}
    >
      <TableRowCell>
        <Tooltip
          content={statusUpdatedAt(data.status, data.updatedAt)}
          placement="top-start"
          hideOnEmptyContent
        >
          <Chip color={getStatusChipColor(data.status)} style={{ width: 56 }}>
            {t(`model.status.${data.status}`)}
          </Chip>
        </Tooltip>
      </TableRowCell>

      <TableRowCell>
        <TextEllipsisBox>
          <LinkTypography
            variant="m-medium"
            color={'black-400'}
            underline={false}
            onClick={handleClickEndpointDetail}
          >
            {data.endpointSetting.name}
          </LinkTypography>
        </TextEllipsisBox>
        {isHovered === data.id && <Icon icon={ChevronRight} size={16} style={{ minWidth: 16 }} />}
      </TableRowCell>

      <TableRowCell>
        {data.url ? (
          <>
            <Typography
              variant="m-regular"
              color="black"
              cursor={'pointer'}
              onClick={e => {
                e.stopPropagation();
                handleClickCopy(data.url, 'URL');
              }}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {data.url}
            </Typography>
            {isHovered === data.id && <Icon icon={Copy} style={{ minWidth: 12, marginLeft: 8 }} />}
          </>
        ) : (
          <Typography variant="m-regular" color="black">
            -
          </Typography>
        )}
      </TableRowCell>
      {showDeployedModelColumn && (
        <TableRowCell>
          <LinkTypography
            variant="m-regular"
            underline={false}
            color="black"
            onClick={() => {
              history.push(
                getUrl(
                  data.baselineModel.purpose === 'recognition'
                    ? [params.accountName, RECOGNITION_MODEL, RecognitionAIDetailMenuItem.path]
                    : [params.accountName, GENERATION_MODEL, GenerationAIDetailsMenuItem.path],
                  {
                    id: data.model.id,
                  },
                ),
              );
            }}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {baselineModel && (
              <PurposeChip
                purpose={baselineModel.data.purpose}
                color={baselineModel.data.purpose === 'generation' ? 'secondary' : 'primary'}
              />
            )}
            {data.model.name}
          </LinkTypography>
        </TableRowCell>
      )}

      <TableRowCell>
        <TextEllipsisBox>
          <Typography variant="m-regular">
            {data.createdAt ? format(new Date(data.createdAt), 'M/d/yy h:mm a') : '-'}
          </Typography>
        </TextEllipsisBox>
      </TableRowCell>

      <Tooltip
        content={
          data.endpointScheduling && data.endpointScheduling.length > 0 ? (
            <Trans
              t={t}
              i18nKey={'model.endpoints.schedulePauseAt'}
              values={{
                time: data.endpointScheduling[0].eventAt
                  ? format(new Date(data.endpointScheduling[0].eventAt), 'M/d/yy h:mm a')
                  : '-',
              }}
            />
          ) : undefined
        }
        placement="top"
        hideOnEmptyContent
      >
        <TableRowCell>
          <TextEllipsisBox>
            <Typography variant="m-regular">
              {format(
                data.statusUpdatedAt ? new Date(data.statusUpdatedAt) : new Date(data.createdAt),
                'M/d/yy h:mm a',
              )}
            </Typography>
          </TextEllipsisBox>
          {data.endpointScheduling && data.endpointScheduling.length > 0 && (
            <Icon icon={Alarm} color={'primary'} style={{ marginLeft: 4 }} />
          )}
        </TableRowCell>
      </Tooltip>

      <Popover
        fixed
        hideOnClick
        disclosure={<IconButton icon={MoreVertical} variant="text" />}
        style={{ zIndex: 1 }}
      >
        <Box
          boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
          py={0.5}
          onClick={e => e.stopPropagation()}
          backgroundColor={'white'}
          display="flex"
          flexDirection={'column'}
        >
          {data.status === 'paused' && (
            <PopoverItem
              onClick={() => {
                resumeEndpointDialogState.show();
              }}
            >
              <Typography variant="m-regular">{t('shared.resume')}</Typography>
            </PopoverItem>
          )}
          {data.status === 'failed' && (
            <PopoverItem
              style={{ opacity: isEndpointMaxCount ? 0.4 : 1 }}
              onClick={() => {
                resumeEndpointDialogState.show();
              }}
            >
              <Typography variant="m-regular">{t('shared.retry')}</Typography>
            </PopoverItem>
          )}
          {data.status === 'running' && (
            <PopoverItem
              onClick={() => {
                pauseEndpoint(data.id);
                enqueueSnackbar(MODEL_ENDPOINT_STATUS_PAUSED({ t }), {
                  variant: 'info',
                });
              }}
            >
              <Typography variant="m-regular">{t('model.endpoints.pause')}</Typography>
            </PopoverItem>
          )}
          {data.status === 'running' && (
            <PopoverItem
              onClick={() => {
                schedulePauseDialogState.show();
              }}
            >
              <Typography variant="m-regular">{t('model.endpoints.schedulePause')}</Typography>
            </PopoverItem>
          )}
          <PopoverItem
            onClick={() => {
              settingDialogState.show();
            }}
          >
            <Typography variant="m-regular">{t('model.endpoints.settings')}</Typography>
          </PopoverItem>
          <Tooltip
            content={
              cannotTestEndpoint ? (
                <Box style={{ width: 220 }}>{t('model.endpoints.cannotTestEndpointTooltip')}</Box>
              ) : undefined
            }
            placement="left-start"
            hideOnEmptyContent
          >
            <PopoverItem
              backgroundColor={{
                default: 'white',
                hover: cannotTestEndpoint ? 'white' : 'gray-100',
              }}
              onClick={() => {
                if (cannotTestEndpoint) {
                  return;
                }
                handleClickEndpointDetail();
              }}
            >
              <Typography
                variant="m-regular"
                color={cannotTestEndpoint ? 'gray-opacity-300' : 'gray-400'}
              >
                {t('model.endpoints.visualize')}
              </Typography>
            </PopoverItem>
          </Tooltip>
          <Tooltip
            content={
              cannotDelete ? (
                <Box style={{ width: 300 }}>{t('model.endpoints.canDeleteModel')}</Box>
              ) : undefined
            }
            placement="left-start"
            hideOnEmptyContent
          >
            <PopoverItem
              onClick={() => {
                if (cannotDelete) {
                  return;
                }
                deleteEndpoint(data.id);
                enqueueSnackbar(MODEL_ENDPOINT_DELETED({ t }), {
                  variant: 'success',
                });
              }}
              backgroundColor={{
                default: 'white',
                hover: cannotDelete ? 'white' : 'gray-100',
              }}
            >
              <Typography
                variant="m-regular"
                color={cannotDelete ? 'primary-opacity-300' : 'primary-400'}
              >
                {t('model.endpoints.delete')}
              </Typography>
            </PopoverItem>
          </Tooltip>
        </Box>
      </Popover>
      {schedulePauseDialogState.visible && (
        <SchedulePauseDialog id={data.id} state={schedulePauseDialogState} />
      )}
      {settingDialogState.visible && model && (
        <EndpointsSettingsDialog
          state={settingDialogState}
          endpointId={data.id}
          endpointName={data.endpointSetting.name}
          model={model}
          modelName={data.model.name}
          status={data.status}
        />
      )}
      {resumeEndpointDialogState.visible && (
        <ResumeEndpointDialog
          state={resumeEndpointDialogState}
          from="resume"
          endpointId={data.id}
        />
      )}
    </TableRow>
  );
};

const PopoverItem = extendComponent(Box, {
  display: 'flex',
  alignItems: 'center',
  variant: 'text',
  px: 1.5,
  backgroundColor: {
    default: 'white',
    hover: 'gray-100',
  },
  cursor: 'pointer',
  style: {
    height: 32,
  },
});

const TableRow = extendComponent(Row, {
  display: 'grid',
  p: 1.5,
  bb: '1px solid',
  borderColor: 'gray-200',
  style: {
    height: 56,
    gap: '20px',
  },
});

const TableRowCell = extendComponent(Row, {});

export const ListIsEmpty = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
      <Box display="flex" flexDirection="column" textAlign="center">
        <ListIsEmptySvg />
        <Typography variant="h3" mb={3}>
          <Trans t={t} i18nKey={'model.endpoints.emptyList'} />
        </Typography>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
