import { CSSProperties, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DataSeriesDatum } from '@elastic/charts';
import { Box } from '@superb-ai/ui';

import GenericDonutChartWithLegend from '../../../../../components/pages/analytics/charts/donutChart/GenericDonutChartWithLegend';
import Barchart from '../../../../../components/pages/analytics/customAnalytics/charts/Barchart';
import Treemap, {
  getTreemapTheme,
} from '../../../../../components/pages/analytics/customAnalytics/charts/Treemap';
import { getDefaultChartType } from '../../../../../components/pages/analytics/customAnalytics/chartSpecs';
import {
  getColorFunc,
  getDataColor,
  makeColorFnFromField,
} from '../../../../../components/pages/analytics/customAnalytics/color';
import {
  CategoryFields,
  GroupByField,
  IdField,
  LabelFields,
  ObjectFields,
} from '../../../../../components/pages/analytics/customAnalytics/fields';
import {
  getDonutChartLegendTitle,
  TransformFunctionMap,
} from '../../../../../components/pages/analytics/customAnalytics/transformer';
import {
  ChartType,
  ChartTypeEnum,
  DataSpec,
  TonyChartResponse,
} from '../../../../../components/pages/analytics/customAnalytics/type';
import { JsonObj, Mapper } from '../../../../../components/pages/analytics/userStats/types';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { LabelInterface } from '../../../../../utils/LabelInterfaceUtils';
import { ChartDatum } from '../../../../Curate/components/datasets/dataset/analytics/charts/types';
import { LoadingIndicatorDiv } from '../../../../Curate/components/datasets/dataset/analytics/components/LoadingIndicatorDiv';
import NoResultsView from '../../../../Curate/components/datasets/dataset/scatterView/NoResultsView';
import { getClassIdToNameMap, getIdToNameMap } from './analyticsUtils';

type Props = {
  data: TonyChartResponse | undefined;
  isFetching: boolean;
  dataSpec: DataSpec;
  groupBy: GroupByField;
  style?: CSSProperties;
  previewAreaColor?: string;
  chartType?: ChartType;
};

const colors = {
  dialog: '#F5F5F5',
  previewArea: '#E8E8E8',
};

export const PreviewChartArea = ({
  data,
  isFetching,
  dataSpec,
  groupBy,
  style,
  previewAreaColor,
  chartType,
}: Props) => {
  const { t } = useTranslation();
  const chartAreaRef = useRef<HTMLDivElement>(null);
  const projectInfo = useProjectInfo();
  const { labelInterface } = projectInfo.project;
  const tagIdToName = getIdToNameMap(projectInfo.tags);
  const classIdToName = getClassIdToNameMap(projectInfo.project.labelInterface);
  const categoryIdToName = projectInfo.categorizationIdToName;

  const getIdToNameOrNone = (groupBy: GroupByField) => {
    const mapper: Record<IdField, Mapper> = {
      [LabelFields.labelTag]: tagIdToName,
      [ObjectFields.classIds]: classIdToName,
      [CategoryFields.categoryIds]: categoryIdToName,
    };
    return groupBy in mapper ? mapper[groupBy as IdField] : {};
  };

  const transformFn = TransformFunctionMap(groupBy) as (
    response: typeof data,
    mapper?: Mapper,
    dataSpec?: DataSpec,
    labelInterface?: LabelInterface,
  ) => any; // TODO: add type

  const transformed = data
    ? transformFn(data, getIdToNameOrNone(groupBy), dataSpec, labelInterface)
    : undefined;
  const previewAreaBackground = previewAreaColor ?? colors.previewArea;

  // donut
  function ChartMapper() {
    if (!data) return;
    const selectedChartType: ChartType = chartType ?? getDefaultChartType(dataSpec, groupBy);

    const colorFn =
      groupBy === LabelFields.assetGroup || groupBy === CategoryFields.categoryIds
        ? getColorFunc(groupBy, transformed.data.map((d: ChartDatum) => d.key) as string[])
        : makeColorFnFromField(transformed.data);

    const charts = {
      [ChartTypeEnum.BAR]: (
        <Barchart
          data={transformed.data}
          config={{
            yKey: 'count',
            xKey: 'key',
            styleAccessor: (datum: DataSeriesDatum) =>
              groupBy === LabelFields.status
                ? (colorFn(datum.x as string) as string)
                : getDataColor(dataSpec),
          }}
        />
      ),
      [ChartTypeEnum.DONUT]: (
        <GenericDonutChartWithLegend
          idKey="key"
          countKey="count"
          data={transformed.data}
          chartName={'donut chart'}
          containerHeight={chartAreaRef.current?.clientHeight + 80 ?? 0}
          containerWidth={chartAreaRef.current?.clientWidth ?? 0}
          getDatumColor={(datum: JsonObj) => {
            return colorFn(datum.key) as string;
          }}
          legendTitle={getDonutChartLegendTitle(t, groupBy)}
        />
      ),
      [ChartTypeEnum.TREEMAP]: (
        <Treemap
          theme={getTreemapTheme({ color: previewAreaBackground })}
          data={transformed.data}
        />
      ),
    } as Record<ChartType, JSX.Element>;
    return charts[selectedChartType];
  }
  const isDataEmpty = typeof transformed === 'undefined' || !transformed.data.length;
  return (
    <Box
      display="flex"
      ref={chartAreaRef}
      justifyContent="center"
      alignItems="center"
      p={2}
      style={{ ...style, backgroundColor: previewAreaBackground }}
      gap={1}
    >
      {isFetching ? (
        <LoadingIndicatorDiv />
      ) : !isFetching && isDataEmpty ? (
        <NoResultsView text={t('analytics.customizeAnalytics.text.pleaseUpdateData')} />
      ) : (
        ChartMapper()
      )}
    </Box>
  );
};
