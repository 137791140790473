import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ProjectSteps } from '../../../configs/NewProjectStepsConfig';
import { useNewProjectInfo } from '../../../contexts/NewProjectContext';
import Emoji from '../../elements/Emoji';

const useStyles = makeStyles({
  box: {
    width: '100%',
    borderRadius: '7px',
    background: '#f8f8f8',
    marginBottom: '30px',
  },
  innerBox: {
    padding: '20px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    fontWeight: 800,
    fontSize: '12px',
    color: '#333',
  },
  bodyText: {
    fontWeight: 300,
    fontSize: '10px',
    color: '#333',
    marginTop: '10px',
  },
});

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const {
    currentStep: { settingPage },
  } = newProjectInfo;

  const getTip = () => {
    switch (settingPage) {
      default:
      case ProjectSteps.CREATE_PROJECT:
        return (
          <MUI.Box>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.createProject')}
            </MUI.Typography>
          </MUI.Box>
        );
      case ProjectSteps.IMAGE_CATEGORY:
        return (
          <MUI.Box>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.configureCategories')}
            </MUI.Typography>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.organizeCategories')}
            </MUI.Typography>
          </MUI.Box>
        );
      case ProjectSteps.CLASS_PROPERTY:
        return (
          <MUI.Box>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.configureObjectClasses')}
            </MUI.Typography>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.addObjectProperties')}
            </MUI.Typography>
          </MUI.Box>
        );
      case ProjectSteps.CLASS_GROUP:
        return (
          <MUI.Box>
            <MUI.Typography className={classes.bodyText}>
              {t('projectSettings.hints.organizeObjectClasses')}
            </MUI.Typography>
          </MUI.Box>
        );
    }
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box className={classes.innerBox}>
        <MUI.Box className={classes.header}>
          <Emoji symbol="👋" />
          <MUI.Typography className={classes.headerText}>
            {t('projectSettings.hints.tip')}
          </MUI.Typography>
        </MUI.Box>
        {getTip()}
      </MUI.Box>
    </MUI.Box>
  );
};

export default Layout;
