import { makeStyles } from '@mui/styles';

export const useAnalyticsTableStyles = makeStyles(() => ({
  tableWrap: {
    position: 'relative',
  },
  tableContainer: {
    overflowX: 'scroll',
    width: '100%',
    boxShadow: 'none',
    '&::-webkit-scrollbar': {
      display: 'block',
      webkitAppearance: 'none',
      width: '0.7em',
      height: '7px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(207,207,207,0.5)',
      webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      borderRadius: '7px',
    },
  },
  emptyRow: {
    height: '60px',
    paddingTop: '42px',
    paddingBottom: '42px',
    backgroundColor: '#f8f8f8',
    alignItems: 'center',
    '&.spiral': {
      alignItems: 'left',
    },
  },
}));

export const headerCellStyle = {
  padding: '0px 0px 0px 0px',
  color: 'inherit',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '11px',
  //   lineHeight: '16px',
  textDecoration: 'inherit',
  '&:link': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  '&:hover': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
};
