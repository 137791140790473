export type PolylinePoints = [number, number];

export const generatePolylinePath = (
  positionA: PolylinePoints,
  positionB: PolylinePoints,
  positionC: PolylinePoints,
) => {
  const startPoint = positionA.join(',');
  const inflectionPoint = positionB.join(',');
  const endPoint = positionC.join(',');
  return startPoint + ' ' + inflectionPoint + ' ' + endPoint;
};

export function placeOnLeftOrRight(midAngle: number) {
  // multiply by 1 or -1 to place "text" to the right or left of the midangle
  return midAngle < Math.PI ? 1 : -1;
}

// increase to lengthen horizontal part of polyline
export const SCALE_POLYLINE_LENGTH = 0.5;

// increase to move text away from the polyline point C
export const OFFSET_POLYLINE_TEXT_POSITION = 0.72;

// decrease to move text further away from the polyline
export const OFFSET_POLYLINE_TEXT = -30;

// increase to move percent text to the right
export const OFFSET_POLYLINE_PERCENT = 25;
