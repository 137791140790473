import { isEmpty } from 'lodash';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { CurateAnnotationType } from '../../../types/curationTypes';
import { AnnotationType } from '../../../utils/LabelInterfaceUtils';
import {
  AnalyticsResponse,
  AnnotationStatsResponse,
  ClassificationKeyDatum,
  ClassificationKeyResponse,
  ClassificationOptions,
  ClassificationValueAllResponse,
  ClassificationValueResponse,
  MetadataKeyResponse,
  MetadataValueResponse,
} from '../components/datasets/dataset/analytics/types';
import {
  ClusterLevel,
  ImageFilterSchema,
  ProjectionInParam,
} from '../components/datasets/dataset/filter/types';
import { KeysToSnakeCase } from '../types/typeUtils';
import { buildDatasetAnalyticsEndpoint } from './utils';

// TODO (moon) remove if not used
// function appendQueryParams(endpoint: string, data: Record<string, any> | undefined): string {
//   if (!data) return endpoint;
//   return `${endpoint}${qs.stringify(data, { arrayFormat: 'brackets', addQueryPrefix: true })}`;
// }

type SupportedAnnotationType = Extract<AnnotationType, CurateAnnotationType> | 'category';

export type AnalyticsParams = {
  datasetId: string;
  fromPublicDatasets: boolean;
  slice?: string;
  query?: string;
  histogramInterval?: string;
  cardinalityThreshold?: number;
  key?: string; // metadata key
  limit?: number; // metadata value limit
  annotationType?: SupportedAnnotationType;
  appliedFilters?: ImageFilterSchema | undefined;
};

export type RequestBody = KeysToSnakeCase<
  Omit<AnalyticsParams, 'datasetId' | 'fromPublicDatasets' | 'appliedFilters'>
> & {
  cluster_id_in?: string[];
  projection_in?: ProjectionInParam;
  cluster_level?: ClusterLevel;
};
type QueryParams = Omit<AnalyticsParams, 'datasetId'>;

function buildRequestBody(params: QueryParams): RequestBody {
  const {
    slice,
    query,
    histogramInterval,
    cardinalityThreshold,
    key,
    limit,
    annotationType,
    appliedFilters,
  } = params;
  const body: RequestBody = {};
  if (slice) body.slice = slice;
  if (query) body.query = query;
  if (histogramInterval) body.histogram_interval = histogramInterval;
  if (cardinalityThreshold) body.cardinality_threshold = cardinalityThreshold;
  if (key) body.key = key;
  if (limit) body.limit = limit;
  if (annotationType) body.annotation_type = annotationType;
  if (
    appliedFilters?.cluster_level &&
    appliedFilters?.cluster_id_in &&
    appliedFilters.cluster_id_in.length > 0
  ) {
    body.cluster_id_in = appliedFilters.cluster_id_in;
    body.cluster_level = appliedFilters.cluster_level;
  }
  if (appliedFilters && !isEmpty(appliedFilters?.projection_in)) {
    body.projection_in = appliedFilters.projection_in;
  }
  return body;
}

export const METADATA_CARDINALITY_THRESHOLD = 100; // filter out free response (i.e metadata key with too many values)
const getStatsMetadataKey: AfterLoginCallback<
  MetadataKeyResponse,
  AnalyticsParams
> = async params => {
  if (!params.data) return { data: [], count: 0 };
  const { slice, query, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/metadata-key',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    cardinalityThreshold: METADATA_CARDINALITY_THRESHOLD,
    fromPublicDatasets,
    appliedFilters,
  });
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsMetadataValue: AfterLoginCallback<
  MetadataValueResponse,
  AnalyticsParams & { metadataKey: string }
> = async params => {
  if (!params.data) return { data: [], count: 0 };
  const { slice, query, metadataKey, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/metadata-value',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    key: metadataKey,
    limit: METADATA_CARDINALITY_THRESHOLD,
    fromPublicDatasets,
    appliedFilters,
  }) as RequestBody;

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsClass: AfterLoginCallback<AnalyticsResponse<any>, AnalyticsParams> = async params => {
  if (!params.data) return;
  const { slice, query, annotationType, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/class-count',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    fromPublicDatasets,
    slice,
    query,
    annotationType,
    appliedFilters,
  });
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsAnnotationTypes: AfterLoginCallback<
  AnalyticsResponse<any>,
  AnalyticsParams
> = async params => {
  if (!params.data) return;
  const { slice, query, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/annotation-type',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    fromPublicDatasets,
    slice,
    query,

    appliedFilters,
  });
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsAnnotations: AfterLoginCallback<
  AnnotationStatsResponse,
  AnalyticsParams
> = async params => {
  if (!params.data) return;
  const { slice, query, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/annotation-count/',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    histogramInterval: '1',
    fromPublicDatasets,

    appliedFilters,
  });
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsClassificationKey: AfterLoginCallback<
  ClassificationKeyResponse,
  AnalyticsParams
> = async params => {
  if (!params.data) return { data: [], totalImageCount: 0 };
  const { slice, query, fromPublicDatasets, appliedFilters } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/classification-key',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    // cardinalityThreshold: METADATA_CARDINALITY_THRESHOLD,
    fromPublicDatasets,
    appliedFilters,
  });
  const comparedMock: ClassificationKeyResponse = {
    totalImageCount: 20000,
    data: [
      { name: 'weather', type: ClassificationOptions.SINGLE_SELECT, imageCount: 20000 },
      {
        name: 'quality',
        type: ClassificationOptions.SINGLE_SELECT,
        imageCount: 19000,
      },
      { name: 'background color', type: ClassificationOptions.SINGLE_SELECT, imageCount: 20000 },
    ] as ClassificationKeyDatum[],
  };
  const mock: ClassificationKeyResponse = {
    totalImageCount: 13000,
    data: [
      { name: 'weather', type: ClassificationOptions.SINGLE_SELECT, imageCount: 8000 },
      {
        name: 'quality',
        type: ClassificationOptions.SINGLE_SELECT,
        imageCount: 12000,
      },
      { name: 'background color', type: ClassificationOptions.SINGLE_SELECT, imageCount: 11000 },
    ] as ClassificationKeyDatum[],
  };
  if (query || slice) return mock;
  return comparedMock;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsClassificationValue: AfterLoginCallback<
  ClassificationValueResponse,
  AnalyticsParams & { classificationKey: string }
> = async params => {
  if (!params.data) return { data: [], totalImageCount: 0 };
  const { slice, query, classificationKey, fromPublicDatasets } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/classification-value',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    key: classificationKey,
    fromPublicDatasets,
    // limit: METADATA_CARDINALITY_THRESHOLD,
  });

  const mock: Record<string, ClassificationValueResponse> = {
    weather: {
      totalImageCount: 13000,
      data: [
        { value: 'cloudy', count: 3000 },
        { value: 'sunny', count: 5000 },
        { value: 'rainy', count: 1000 },
        { value: 'snowy', count: 1000 },
        { value: 'stormy', count: 1500 },
        { value: 'foggy', count: 1500 },
      ],
    },
    quality: {
      totalImageCount: 12000,
      data: [
        { value: 'high', count: 10000 },
        { value: 'medium', count: 2900 },
        { value: 'low', count: 100 },
      ],
    },
    'background color': {
      totalImageCount: 11000,
      data: [
        { value: 'white', count: 10000 },
        { value: 'not white', count: 1000 },
      ],
    },
  };
  return mock[classificationKey as keyof typeof mock];

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

const getStatsClassificationValuesAll: AfterLoginCallback<
  ClassificationValueAllResponse,
  AnalyticsParams
> = async params => {
  if (!params.data) return { data: [], totalImageCount: 0 };
  const { slice, query, fromPublicDatasets } = params.data;
  const endpoint = buildDatasetAnalyticsEndpoint(
    params.data.datasetId,
    '/stats/classification-value/all/',
    fromPublicDatasets,
  );
  const requestBody = buildRequestBody({
    slice,
    query,
    fromPublicDatasets,
  });

  const mock: ClassificationValueAllResponse = {
    totalImageCount: 36000,
    data: [
      { name: 'weather', value: 'cloudy', count: 3000 },
      { name: 'weather', value: 'sunny', count: 5000 },
      { name: 'weather', value: 'rainy', count: 1000 },
      { name: 'weather', value: 'snowy', count: 1000 },
      { name: 'weather', value: 'stormy', count: 1500 },
      { name: 'weather', value: 'foggy', count: 1500 },
      { name: 'quality', value: 'high', count: 10000 },
      { name: 'quality', value: 'medium', count: 2900 },
      { name: 'quality', value: 'low', count: 100 },
      { name: 'background color', value: 'white', count: 10000 },
      { name: 'background color', value: 'not white', count: 1000 },
    ],
  };
  return mock;

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return data;
};

export function useCurateAnalyticsService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getStatsClass: afterLoginFetcher(getStatsClass),
    getStatsAnnotationTypes: afterLoginFetcher(getStatsAnnotationTypes),
    getStatsMetadataKey: afterLoginFetcher(getStatsMetadataKey),
    getStatsMetadataValue: afterLoginFetcher(getStatsMetadataValue),
    getStatsAnnotations: afterLoginFetcher(getStatsAnnotations),
    getStatsClassificationKey: afterLoginFetcher(getStatsClassificationKey),
    getStatsClassificationValue: afterLoginFetcher(getStatsClassificationValue),
    getStatsClassificationValuesAll: afterLoginFetcher(getStatsClassificationValuesAll),
  };
}
