import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, Icon, Typography, useTheme } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useAuthService } from '../../../../services/NewAuthService';
import EditableUserAvatar from '../../../elements/EditableUserAvatar';
import RoleChip from '../../../elements/RoleChip';
import NameEditor from './NameEditor';

const Hr = styled.hr`
  min-width: 1px;
  min-height: 100%;
  margin: 0 16px;
  border: none;
  // *Require Theme Color Provider
  background: #e5e5e5;
`;

const NameBox = styled(Box)`
  position: relative;

  &:hover div {
    display: inherit;
  }
`;

const PaddingBox = styled(Box)`
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  padding-left: 6px;
`;

const PencilBox = styled(Box)<{ themedColor: string }>`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    // *Require Theme Color Provider
    background: #f5f5f5;
  }
`;

const UserDetail: React.FC = () => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const { uploadAvatar } = useAuthService();

  const handleUploadImage = async (avatar: string): Promise<string> => {
    const result = await uploadAvatar({ avatar });
    const url = `${result.data.avatarUrl}?t=${new Date().getTime()}`;
    authInfo.setAvatarUrl(url);
    return url;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      py={4}
      alignItems="center"
      gap="8px"
      shadow={1}
    >
      <Box
        width="80px"
        height="80px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ borderRadius: '50%', border: `1px solid ${theme.nfPalette.grey[200]}` }}
      >
        <EditableUserAvatar
          size={70}
          iconSize={12}
          margin="0"
          userInfo={authInfo}
          uploadImage={handleUploadImage}
          title={t('settings.profile.changeAvatar')}
          isPersonalAvatar
        />
      </Box>
      <Box mb={1} />
      {isEditing ? (
        <NameEditor
          givenName={authInfo.firstName ?? ''}
          familyName={authInfo.lastName ?? ''}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <NameBox display="flex" gap="6px" alignItems="center">
            <Typography variant="headline5">
              {authInfo.firstName} {authInfo.lastName}
            </Typography>
            <PaddingBox>
              <PencilBox onClick={() => setIsEditing(true)}>
                <Icon name="pencil" size="12px" />
              </PencilBox>
            </PaddingBox>
          </NameBox>
          <Typography variant="body2" themedColor={['grey', 700]}>
            {authInfo.email}
          </Typography>
          <Box display="flex" justifyContent="center" gap="8px">
            <Box display="flex" alignItems="center">
              <Typography variant="body3" themedColor={['grey', 400]}>
                {t('settings.profile.teamName')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body3" themedColor={['grey', 700]}>
                <strong>{authInfo.accountName}</strong>
              </Typography>
            </Box>
            <Hr />
            <Box display="flex" alignItems="center">
              <Typography variant="body3" themedColor={['grey', 400]}>
                {t('settings.profile.role')}
              </Typography>
            </Box>
            {authInfo.role && (
              // TODO: need to check size
              <RoleChip
                memberRole={authInfo.role === 'system admin' ? 'admin' : authInfo.role}
                // size="xxs"
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserDetail;
