import ToastContainer from '../components/layouts/ToastContainer';
import { AssetsProvider } from '../contexts/AssetsContext';
import { LabelCommandContextProvider } from '../contexts/LabelCommandContext';
import ToastContext from '../contexts/ToastContext';
import { UploadProvider } from '../contexts/UploadContext';
import { AppRouteLayoutProps, AppRoutes } from '../menu';

export default function LabelLayout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  return (
    <LabelCommandContextProvider>
      <UploadProvider>
        <ToastContext.Provider>
          <AssetsProvider>
            <AppRoutes items={menuItem.children ?? []} />
            <ToastContainer />
          </AssetsProvider>
        </ToastContext.Provider>
      </UploadProvider>
    </LabelCommandContextProvider>
  );
}
