import { useRouteMatch } from 'react-router';

import { Box, IconButton, Popper } from '@superb-ai/norwegian-forest';

import { CurateCommandContext } from '../../../../apps/Curate/contexts/CommandContext';
import {
  Command as CurateCommand,
  CommandType as CurateCommandType,
} from '../../../../apps/Curate/types/commandTypes';
import { InProgressStatuses as CurateInProgressStatuses } from '../../../../apps/Curate/utils/commandUtils';
import { LabelCommandContext } from '../../../../contexts/LabelCommandContext';
import {
  Command as LabelingCommand,
  InProgressStatuses as LabelingInProgressStatuses,
} from '../../../../utils/LabelCommandUtils';
import CommandProgressModal from './CommandProgressModal';

export default function CommandProgressMenu({
  commandContext,
}: {
  commandContext: LabelCommandContext | CurateCommandContext;
}): JSX.Element {
  const { isModalVisible, setIsModalVisible, commandsInProgress, previousCommandsInProgress } =
    commandContext;
  const match = useRouteMatch<{ suite: 'label' | 'curate' }>();

  const isActive = commandsInProgress.items.some(command => {
    if (match.params.suite === 'label')
      return LabelingInProgressStatuses.includes((command as LabelingCommand).status);
    if (match.params.suite === 'curate')
      return CurateInProgressStatuses.includes(
        (command as CurateCommand<CurateCommandType>).status,
      );
  });

  // const isActive =
  // match.params.suite === 'label'
  //   ? commandsInProgress.items.some(command =>
  //       LabelingInProgressStatuses.includes((command as LabelingCommand).status),
  //     )
  //   : previousCommandsInProgress.items.some(command =>
  //       CurateInProgressStatuses.includes((command as CurateCommand).status),
  //     );

  const anchorElement = (
    <IconButton
      round
      size="l"
      onClick={() => setIsModalVisible(true)}
      icon={isActive ? 'loadingSpinnerAlt' : 'loadingSpinnerAltStatic'}
      color={isActive ? 'primary' : 'grey'}
    />
  );

  return (
    <>
      <Popper
        open={isModalVisible}
        anchorEl={anchorElement}
        positionFixed
        offset={1}
        placement="bottom"
        useOutsidePanel
        onClickOutside={() => setIsModalVisible(false)}
        zIndex={1299}
      >
        {isModalVisible && (
          <Box mr={2}>
            <CommandProgressModal commandContext={commandContext} />
          </Box>
        )}
      </Popper>
    </>
  );
}
