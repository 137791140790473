import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Chip, Icon, IconName, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { GetThemeColorInput } from '@superb-ai/norwegian-forest/dist/theme';
import classnames from 'classnames';
import { capitalize } from 'lodash';

import { overflowOverlayOrAuto } from '../../../../../utils/style';
import TableSortLabel from '../../../../elements/TableSortLabel';

type Props = {
  type: 'object' | 'categorization';
  cols: JSX.Element;
  onSort: (field: string, ascDesc: 'asc' | 'desc') => void;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  columns: { name: string; sortable?: boolean }[];
  rows: {
    id: string;
    isSelected: boolean;
    disabled: boolean;
    onClick: () => void;
    failMsg?: string;
    contents: (string | number | JSX.Element | undefined)[];
  }[];
  onCheckAll: () => void;
  isAllChecked: boolean;
  checkedCount: number;
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    position: 'relative',
    borderCollapse: 'separate',
    borderSpacing: 0,

    fontSize: 12,
    '& strong': {
      fontWeight: 500,
    },
    '& td': {
      fontSize: '11px',
      padding: '10px',
      textAlign: 'left',
      verticalAlign: 'top',
      // '&:nth-last-child(3), &:nth-last-child(4)': {
      //   textAlign: 'right',
      // },
      '&:last-child': {
        paddingRight: '20px',
      },
    },
    '& thead': {
      position: 'sticky',
      // TODO (shko): should find better solution
      top: -0.4,
      backgroundColor: '#FBFBFB',
    },
    '& th': {
      fontSize: '11px',
      lineHeight: 1,
      fontWeight: 'normal',
      textAlign: 'left',
      verticalAlign: 'center',
      padding: '10px',
      whiteSpace: 'nowrap',
      // '&:nth-last-child(3), &:nth-last-child(4)': {
      //   textAlign: 'right',
      // },
      '&:last-child': {
        paddingRight: '20px',
      },
    },
    '& tbody tr': {
      cursor: 'pointer',
      '&:hover, &.selected': { backgroundColor: '#fff6f6' },
      '&.disabled': {
        color: '#ADADAD',
        cursor: 'auto',
        '&:hover': { backgroundColor: 'transparent' },
      },
    },
  },
  icon: { cursor: 'pointer' },
});

const CustomAutoLabelTableLayout: React.FC<Props> = ({
  cols,
  type,
  columns,
  rows,
  isAllChecked,
  onCheckAll,
  checkedCount,
  sortBy,
  sortOrder,
  onSort,
}) => {
  const classes = useStyles();

  function getIconProperties(
    isSelected: boolean,
    disabled?: boolean,
  ): { name: IconName; color: GetThemeColorInput } {
    if (isSelected) {
      return {
        name: 'checkboxChecked',
        color: 'primary',
      };
    }
    return {
      name: 'checkbox',
      color: disabled ? ['grey', 300] : 'textDefault',
    };
  }

  return (
    <>
      <Box bt bb px={1.5} py={1} display="flex" alignItems="center">
        <Typography variant="headline7">{capitalize(type)}</Typography>
        {checkedCount > 0 && (
          <Box ml={1} display="flex" alignItems="center">
            <Chip color="cloud">{checkedCount}</Chip>
          </Box>
        )}
      </Box>
      <Box minHeight="78px" flexGrow={1} overflow={overflowOverlayOrAuto()}>
        <table className={classes.table}>
          <colgroup>
            <col width="12px" />
            {cols}
          </colgroup>
          <thead>
            <tr>
              <th>
                <Box height="18px" display="flex" alignItems="center" onClick={onCheckAll}>
                  <Icon
                    className={classes.icon}
                    size="16px"
                    {...getIconProperties(!!isAllChecked)}
                  />
                </Box>
              </th>
              {columns.map(column => (
                <th key={column.name}>
                  {column.sortable ? (
                    <TableSortLabel
                      property={column.name}
                      onRequestSort={(property, nextOrder) => {
                        onSort(property, nextOrder);
                      }}
                      isDesc={sortOrder === 'desc'}
                      orderBy={sortBy}
                      defaultOrder={
                        column.name === 'Precision' || column.name === 'Recall' ? 'desc' : 'asc'
                      }
                    >
                      {column.name}
                    </TableSortLabel>
                  ) : (
                    column.name
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => {
              const rowContent = (
                <tr
                  className={classnames({ selected: row.isSelected, disabled: row.disabled })}
                  key={index}
                  onClick={() => {
                    if (row.disabled) return;
                    row.onClick();
                  }}
                >
                  <td>
                    <Box height="20px" display="flex" alignItems="center">
                      <Icon size="16px" {...getIconProperties(row.isSelected, row.disabled)} />
                    </Box>
                  </td>
                  {row.contents.map((content, index) => (
                    <td key={`row-content-${index}`}>{content}</td>
                  ))}
                </tr>
              );
              if (row.failMsg) {
                return (
                  <Tooltip key={row.id} placement="auto-start" anchorEl={rowContent}>
                    {row.failMsg}
                  </Tooltip>
                );
              }
              return rowContent;
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
};
export default CustomAutoLabelTableLayout;
