import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { Icon, Select } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';

import RangeConst from '../../../consts/RangeConst';
import { DATASET_NAME_RANGE_RULE } from '../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import AssetsService from '../../../services/AssetsService';
import { Option, PageAdditional } from '../../../types/selectTypes';
import UserRoleUnion from '../../../union/UserRoleUnion';
import StringUtils from '../../../utils/StringUtils';
import newProjectHelper from '../../pages/newProject/helper';

const DatasetSelect: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const fileInfo = useUploadInfo();

  const [value, setValue] = useState<Option | undefined>();
  const { setDataset, dataset } = fileInfo;
  const { enqueueSnackbar } = useSnackbar();

  const getDatasetFromCurrentPath = async (inputValue: string, page: number) => {
    if (UserRoleUnion.isManager(authInfo.projectRole)) {
      const datasetList = await AssetsService.getProjectDatasets({
        params: {
          groupIcontains: inputValue,
          page,
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      return datasetList;
    }
    const datasetList = await AssetsService.getDatasets({
      params: {
        groupIcontains: inputValue,
        page,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    return datasetList;
  };

  useEffect(() => {
    if (!dataset) return;
    setValue({ value: dataset, label: dataset });
  }, [dataset]);

  const handleAddDataset = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    const value = newValue?.value && newValue.value.trim();

    if (newValue?.__isNew__ && value) {
      if (
        !StringUtils.isWithinRangeLength(value, RangeConst.DATASET.min, RangeConst.DATASET.max) &&
        value !== ''
      ) {
        enqueueSnackbar(
          DATASET_NAME_RANGE_RULE({ t, min: RangeConst.DATASET.min, max: RangeConst.DATASET.max }),
          {
            variant: 'warning',
          },
        );
        return;
      }

      const parsedValue = newProjectHelper.parseNameValue(
        t,
        value,
        'Dataset name',
        enqueueSnackbar,
      );
      if (value !== parsedValue) return;
    } else {
    }

    setDataset(value);
    setValue(value ? { value, label: value } : undefined);
  };

  const handleLoadDataset: LoadOptions<Option, GroupBase<Option>, PageAdditional> = async (
    search,
    _prevOptions,
    additional,
  ) => {
    const page = additional?.page || 1;

    const datasetList = await getDatasetFromCurrentPath(search, page);
    const result = map(datasetList.results, result => ({
      label: result.group,
      value: result.group,
    }));

    const pageSize = 10;
    const hasMore = Math.ceil(datasetList.count / pageSize) > page;

    return {
      options: result,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Select.AsyncCreatable
      isClearable
      createOptionPosition="first"
      blurInputOnSelect
      placeholder="Select or Create a dataset"
      createLabelIcon="folderPlus"
      getOptionLabelAdornment={() => <Icon name="folderOutline" />}
      loadOptions={handleLoadDataset}
      onChange={handleAddDataset}
      value={value}
      isMulti={false}
    />
  );
};

export default DatasetSelect;
