import { Dot, RadioChecked, SelectMultiple } from '@superb-ai/icons';
import { Icon, Typography } from '@superb-ai/ui';

import { ClassificationOptions, ClassificationType } from '../types';

type CellProps = {
  name: string;
  type: ClassificationType;
};
export function ClassificationNameCell({ name, type }: CellProps): JSX.Element {
  const iconMap = {
    [ClassificationOptions.SINGLE_SELECT]: RadioChecked,
    [ClassificationOptions.MULTI_SELECT]: SelectMultiple,
    // [ClassificationOptions.FREE_RESPONSE]: TextLeftAlign, # enable when supported
  };
  return (
    <>
      {type && <Icon icon={iconMap[type] ?? Dot} color={'gray-400'} size="12px" />}
      <Typography
        style={{
          width: '120px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        variant="m-medium"
      >
        {name || ''}
      </Typography>
    </>
  );
}
