import { useTranslation } from 'react-i18next';

import { Axis, BarSeries, Chart, Position, ScaleType, Settings, Tooltip } from '@elastic/charts';

import CustomTooltip from './components/CustomTooltip';
import { ObjectCountsRow } from './types/types';

interface Props {
  rows: ObjectCountsRow[];
}

const ObjectCountsGroupedBarChart = ({ rows }: Props) => {
  const { t } = useTranslation();

  return (
    <Chart size={{ width: '100%', height: `${rows.length * 50}px` }}>
      <Settings
        rotation={90}
        theme={{
          scales: {
            barsPadding: 0.5,
          },
        }}
      />
      <Tooltip customTooltip={CustomTooltip} />
      <Axis id="bottom" position={Position.Bottom} tickFormat={d => Math.round(d).toString()} />
      <Axis id="left" position={Position.Left} />
      <BarSeries
        id="bar"
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor="x"
        yAccessors={['y']}
        color={['#82DB24', '#3479FF']}
        splitSeriesAccessors={['g']}
        data={rows.flatMap(row => [
          {
            x: row.className,
            y: row.queryCount,
            g: t('analytics.exportsAnalytics.base'),
          },
          {
            x: row.className,
            y: row.refCount,
            g: t('analytics.exportsAnalytics.compare'),
          },
        ])}
      />
    </Chart>
  );
};

export default ObjectCountsGroupedBarChart;
