import { adaptV4Theme, createTheme, Theme as MuiTheme } from '@mui/material/styles';

declare module '@mui/styles' {
  // eslint-disable-next-line
  interface DefaultTheme extends MuiTheme {}
}

const ColorTheme = {
  palette: {
    primary: {
      light2: '#fff9f9',
      light: '#ffeceb',
      main: '#ff625a',
      dark: '#e55851',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ebeeff',
      main: '#5a7bff',
      contrastText: '#ffffff',
    },
    error: {
      light: '#fff8e4',
      main: '#ffcc00',
    },
    green: {
      light: '#EDFBDD',
      main: '#a3eb57',
      dark: '#81d027',
    },
    orange: {
      light: '#fff3f2',
      main: '#ff9772',
      dark: '#ff8041',
    },
    navy: {
      main: '#242d37',
    },
  },
};

const ShadowsTheme = {
  shadows: [
    'none',
    '2px 4px 10px rgba(0, 0, 0, 0.1)',
    '4px 6px 14px rgba(0, 0, 0, 0.14)',
    '0 2px 4px 0 rgba(156, 156, 156, 0.5)',
    '0 2px 4px 0 rgba(176, 176, 176, 0.5)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
  ],
};

// Disabled focus trap for Intercom product tour editor
const disableEnforceFocus = typeof window !== 'undefined' && window.name === 'Product Tour Editor';

const Theme = createTheme(
  adaptV4Theme({
    ...ColorTheme,
    ...ShadowsTheme,
    typography: {
      fontFamily: 'Inter',
    },
    spacing: 10,
    overrides: {
      // @ts-ignore: this works
      MuiSkeleton: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        // @ts-ignore: this works?
        animate: {
          animation: 'MuiSkeleton-keyframes-animate 2.5s ease-in-out infinite',
        },
      },
      MuiInput: {
        underline: {
          '&.Mui-disabled:before': {
            borderBottomStyle: 'none',
          },
          '&::after': {
            borderBottom: 'none',
          },
          '&::before': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent',
          },
          '& + .MuiFormHelperText-contained': {
            margin: '8px 0px 0px 0px',
          },
        },
        input: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiInputLabel: {
        filled: {
          transform: 'translateY(24px) scale(1)',
          '& + .MuiInput-formControl': {
            marginTop: '16px',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translateY(10px) scale(0.75)',
          },
          '&.MuiInputLabel-marginDense': {
            transform: 'translateY(20px) scale(1)',
            '&.MuiInputLabel-shrink.MuiInputLabel-animated': {
              transform: 'translateY(8px) scale(0.75)',
            },
          },
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '12px',
        },
      },
      MuiSelect: {
        select: {
          textAlign: 'center',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: '7px',
        },
        root: {
          boxShadow: ShadowsTheme.shadows[2],
        },
      },
      MuiList: {
        padding: {
          paddingTop: '5px',
          paddingBottom: '5px',
        },
      },
      MuiMenuItem: {
        root: {
          minHeight: '35px',
          fontSize: '12px',
          '@media (min-width: 600px)': {
            minHeight: '35px',
          },
        },
      },
      MuiButton: {
        root: {
          color: '#4e4e4e',
          textTransform: 'capitalize',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          minWidth: 'initial',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
        contained: {
          borderRadius: '2px',
          backgroundColor: '#ffffff',
          boxShadow: ShadowsTheme.shadows[2],
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        containedPrimary: {
          backgroundColor: ColorTheme.palette.primary.main,
          color: '#fff',
        },
        outlined: {
          borderRadius: '2px',
          border: '1px solid #ababab',
          paddingTop: '3px',
          paddingBottom: '3px',
        },
      },
      MuiCheckbox: {
        root: {
          padding: '3px',
        },
      },
      MuiTabs: {
        indicator: {
          height: '3px',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 'normal',
          fontSize: '13px',
          '@media (min-width: 960px)': {
            fontSize: '13px',
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
      MuiTypography: {
        root: {
          fontWeight: 'normal',
          wordWrap: 'break-word',
        },
        body1: {
          fontSize: '11px',
        },
        body2: {
          fontSize: '12px',
        },
        subtitle1: {
          fontSize: '13px',
          fontWeight: 'normal',
        },
        subtitle2: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
        h1: {
          fontSize: '22px',
          fontWeight: 400,
        },
        h2: {
          fontSize: '22px',
          fontWeight: 600,
        },
        h3: {
          fontSize: '16px',
          fontWeight: 500,
        },
        h4: {
          fontSize: '13px',
          fontWeight: 500,
        },
        h5: {
          fontSize: '12px',
          fontWeight: 500,
        },
        h6: {
          fontSize: '11px',
          fontWeight: 500,
          lineHeight: 1.5,
        },
      },
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '40px',
        },
      },
      MuiListItemText: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: '1px solid #f8f8f8',
          '&:first-child': {
            borderTop: '1px solid #f8f8f8',
          },
        },
      },
      MuiListSubheader: {
        root: {
          lineHeight: '2rem',
          fontSize: '12px',
        },
        sticky: {
          backgroundColor: '#fff',
          lineHeight: 1.5,
        },
      },
      MuiAvatar: {
        root: {
          textTransform: 'uppercase',
          width: '30px',
          height: '30px',
        },
      },
      MuiBadge: {
        badge: {
          height: '13px',
          minWidth: '13px',
          fontSize: '9px',
        },
      },
      MuiChip: {
        root: {
          height: '20px',
          fontSize: '12px',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.07)',
        },
      },
      MuiTable: {
        root: {
          tableLayout: 'fixed',
          whiteSpace: 'nowrap',
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          padding: '15px 20px',
          '& .MuiTypography-body1': {
            fontSize: '12px',
          },
        },
        sizeSmall: {
          padding: '12px 8px',
          '&:last-child': {
            paddingRight: '8px',
          },
          '&.MuiTableCell-paddingCheckbox': {
            padding: '0px 8px',
          },
          '&.MuiTableCell-head': {
            padding: '8px',
          },
        },
        head: {
          fontSize: 'inherit',
        },
      },
      MuiTableHead: {
        root: {
          boxShadow: ShadowsTheme.shadows[2],
          fontSize: '12px',
          borderRadius: '4px',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
      MuiTableRow: {
        root: {
          fontSize: '12px',
          '&.MuiTableRow-hover': {
            cursor: 'pointer',
          },
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'rgba(255, 98, 90, 0.04)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 98, 90, 0.04)',
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '&.MuiTableSortLabel-active': {
            color: 'rgba(0, 0, 0, 0.54)',
            '&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon.MuiTableSortLabel-icon':
              {
                color: 'rgba(0, 0, 0, 0.54)',
              },
          },
        },
        icon: {
          width: '11px',
          height: '11px',
          marginRight: 0,
        },
      },
      MuiGrid: {
        'direction-xs-column': {
          '& .MuiGrid-grid-xs-12': {
            width: '100%',
          },
        },
      },
      MuiSvgIcon: {
        root: {
          width: '20px',
          height: '20px',
        },
      },
      MuiIcon: {
        fontSizeSmall: {
          fontSize: '13px',
        },
      },
      MuiDialogTitle: {
        root: {
          textAlign: 'center',
          padding: '20px 35px',
          '& .MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 'normal',
            color: ColorTheme.palette.primary.main,
          },
        },
      },
      MuiDialogContent: {
        root: {
          padding: '0 35px',
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'center',
          padding: '25px 35px 35px',
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '12px',
          fontWeight: 500,
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: '12px',
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: ColorTheme.palette.navy.main,
          opacity: 0.9,
        },
      },
      MuiTooltip: {
        tooltipPlacementBottom: {
          marginTop: '2px !important',
        },
        tooltipPlacementTop: {
          marginBottom: '2px !important',
        },
        tooltipPlacementRight: {
          marginLeft: '8px !important',
        },
        tooltipPlacementLeft: {
          marginRight: '8px !important',
        },
      },
      MuiStepConnector: {
        vertical: {
          marginLeft: '3px',
        },
      },
      MuiStepLabel: {
        root: {
          '&.Mui-active': {
            fontWeight: 'bold',
          },
        },
        label: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
      // MuiStepLabel: {
      //   vertical: {
      //     alignItems: "flex-start",
      //     "& .MuiStepLabel-labelContainer": {
      //       marginTop: "3px"
      //     }
      //   }
      // }
      MuiAccordion: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          lineHeight: 1.5,
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: 'initial',
          width: 'fit-content',
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 'initial',
          },
        },
        content: {
          margin: 0,
          display: 'block',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          display: 'block',
          padding: 0,
        },
      },
    },
    props: {
      MuiModal: {
        disableEnforceFocus,
      },
    },
  }),
);

export default Theme;
