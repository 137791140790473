import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import FreeResponseItem from './FreeResponseItem';

const useStyles = makeStyles(() => ({
  box: {
    width: '310px',
    height: '156px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
  optionBox: {
    position: 'absolute',
    bottom: '12px',
    right: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  toggleBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  toggleText: {
    fontSize: '11px',
    color: '#9b9b9b',
    marginRight: '4px',
  },
}));

// eslint-disable-next-line
const FreeResponseList = ({ imageCategory }: any): React.ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { required, perFrame } = imageCategory;

  const listRef = useRef<HTMLDivElement>();
  const newProjectInfo = useNewProjectInfo();
  const [isImageSequence] = useState(WorkappUnion.isImageSequence(newProjectInfo.selectedDataType));

  const handleChangeRequired = () => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const curImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    curImageCategory.required = !required;
    newProjectInfo.setImageCategories(nextImageCategories);
  };
  const handleChangePerFrame = () => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const curImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    curImageCategory.perFrame = !perFrame;
    newProjectInfo.setImageCategories(nextImageCategories);
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer useHeader title={t('projectSettings.propertyType.freeResponse')}>
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox}>
          <FreeResponseItem {...{ imageCategory }} />
        </MUI.Box>
        <MUI.Box className={classes.optionBox}>
          <MUI.Box className={classes.toggleBox}>
            <MUI.Typography className={classes.toggleText}>{t('forms.required')}</MUI.Typography>
            <MUI.Switch
              size="small"
              color="primary"
              checked={required}
              onChange={handleChangeRequired}
            />
          </MUI.Box>
          {isImageSequence && (
            <MUI.Box className={classes.toggleBox}>
              <MUI.Typography className={classes.toggleText}>
                {t('projectSettings.configuration.perFrame')}
              </MUI.Typography>
              <MUI.Switch
                size="small"
                color="primary"
                checked={perFrame}
                onChange={handleChangePerFrame}
              />
            </MUI.Box>
          )}
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default FreeResponseList;
