import {
  QueryObserverOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { isBoolean, split } from 'lodash';

import FileService from '../../../services/FileService';
import FileUtils from '../../../utils/FileUtils';
import {
  AnnotationFilterSchema,
  FilterSchema,
  ImageFilterSchema,
} from '../components/datasets/dataset/filter/types';
import { EvaluationFilterSchema } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import { CurateCommandContext } from '../contexts/CommandContext';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateCommandsService } from '../services/CommandsService';
import { useCurateDatasetService } from '../services/DatasetService';
import { transformFilterBody } from '../services/DiagnosisModelService';
import { UpdateSliceByEvaluationParam } from '../types/commandTypes';
import { Split } from '../types/evaluationTypes';
import { formatAnnotationFilter, formatImageFilter } from '../utils/filterUtils';

type Params = {
  datasetId: string | null;
  fromPublicDatasets?: boolean;
  page?: number;
  size?: number;
  sortOrder?: 'desc' | 'asc';
  sortBy?: string;
  name?: string;
  nameContains?: string;
  nameIcontains?: string;
  enabled?: boolean;
  expand?: string[];
  queryRefetchOptions?: Pick<
    QueryObserverOptions,
    'refetchOnMount' & 'refetchOnWindowFocus' & 'refetchInterval'
  >;
};

export function useSlicesInfiniteQuery({
  datasetId,
  fromPublicDatasets,
  size = 10,
  sortOrder = 'desc',
  sortBy = 'updated_at',
  nameContains,
  enabled,
  expand,
}: Params) {
  const { getSliceList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useInfiniteQuery({
    queryKey: [
      'curate-dataset-slices',
      datasetId,
      fromPublicDatasets,
      size,
      sortBy,
      sortOrder,
      nameContains,
    ],
    queryFn: ({ pageParam }) => {
      return getSliceList({
        datasetId: `${datasetId}`,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        page: pageParam,
        size,
        sort_order: sortOrder,
        sort_by: sortBy,
        name_contains: nameContains,
        expand,
      });
    },
    getNextPageParam: ({ count }, pages) => {
      return count > pages.length * size ? pages.length + 1 : undefined;
    },
    enabled,
  });
}

export const sliceQueryKey = 'curate-dataset-slice';
export function useSliceQuery({
  datasetId,
  fromPublicDatasets,
  sliceId,
  expand,
  enabled = true,
}: {
  datasetId?: string;
  fromPublicDatasets?: boolean;
  sliceId?: string;
  expand?: string[];
  enabled?: boolean;
}) {
  const { getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [sliceQueryKey, datasetId, sliceId, expand],
    queryFn: () => {
      if (!datasetId || !sliceId) return;
      return getSlice({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        sliceId,
        expand,
      });
    },
    enabled: enabled && !!datasetId && !!sliceId,
  });
}

export function useSliceQueries({
  datasetId,
  fromPublicDatasets,
  sliceIds,
  expand,
  enabled = true,
}: {
  datasetId?: string;
  fromPublicDatasets?: boolean;
  sliceIds?: string[];
  expand?: string[];
  enabled?: boolean;
}) {
  const { getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQueries({
    queries: (sliceIds ?? []).map(sliceId => ({
      queryKey: [autoCurateSliceKey, datasetId, sliceId],
      queryFn: async () => {
        if (!datasetId || !sliceId) return null;
        const sliceInfo = await getSlice({
          datasetId,
          fromPublicDatasets: isBoolean(fromPublicDatasets)
            ? fromPublicDatasets
            : showPublicDatasets,
          sliceId,
          expand,
        });
        return sliceInfo;
      },
      enabled: enabled && !!datasetId && !!sliceId,
      refetchOnWindowFocus: false,
    })),
  });
}

export function useSlicesQuery({
  datasetId,
  page,
  size = 10,
  sortOrder = 'desc',
  sortBy = 'created_at',
  nameContains,
  nameIcontains,
  name,
  expand,
  enabled,
  fromPublicDatasets,
  queryRefetchOptions,
}: Params) {
  const { getSliceList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [
      'curate-datasets',
      page,
      size,
      sortBy,
      sortOrder,
      nameContains,
      nameIcontains,
      name,
      expand,
      fromPublicDatasets,
    ],
    queryFn: () => {
      return getSliceList({
        ...{
          datasetId,
          page,
          size,
          sort_order: sortOrder,
          sort_by: sortBy,
          name_contains: nameContains,
          fromPublicDatasets: isBoolean(fromPublicDatasets)
            ? fromPublicDatasets
            : showPublicDatasets,
          name_icontains: nameIcontains,
          name,
        },
        expand,
      });
    },
    enabled,
    ...queryRefetchOptions,
  });
}

export function useSliceQueriesWithNames({
  datasetId,
  fromPublicDatasets,
  size = 10,
  sortOrder = 'desc',
  sortBy = 'updated_at',
  names,
  enabled,
  expand,
}: {
  datasetId: string | null;
  fromPublicDatasets?: boolean;
  size?: number;
  sortOrder?: 'desc' | 'asc';
  sortBy?: string;
  names: string[];
  enabled?: boolean;
  expand?: string[];
}) {
  const { getSliceList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQueries({
    queries: names.map(name => {
      return {
        queryKey: [
          'curate-dataset-slices',
          datasetId,
          fromPublicDatasets,
          size,
          sortBy,
          sortOrder,
          name,
        ],
        queryFn: () =>
          getSliceList({
            datasetId: `${datasetId}`,
            fromPublicDatasets: isBoolean(fromPublicDatasets)
              ? fromPublicDatasets
              : showPublicDatasets,
            page: 1,
            size,
            sort_order: sortOrder,
            sort_by: sortBy,
            name,
            expand,
          }),
        enabled,
      };
    }),
  });
}

export const autoCurateSliceKey = 'auto-curate-slices';
export function useAutoCurateSlicesQuery({
  datasetId,
  fromPublicDatasets,
  slices,
  expand,
  enabled = true,
}: {
  datasetId?: string;
  fromPublicDatasets?: boolean;
  slices?: Record<string, string>;
  expand?: string[];
  enabled?: boolean;
}) {
  const { getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const sliceIds = Object.entries(slices || {});

  return useQueries({
    queries: sliceIds.map(([sliceType, sliceId]) => ({
      queryKey: [autoCurateSliceKey, datasetId, fromPublicDatasets, sliceId],
      queryFn: async () => {
        if (!datasetId || !sliceId) return null;
        const sliceInfo = await getSlice({
          datasetId,
          fromPublicDatasets: isBoolean(fromPublicDatasets)
            ? fromPublicDatasets
            : showPublicDatasets,
          sliceId,
          expand,
        });
        return { [sliceType]: sliceInfo };
      },
      enabled: enabled && !!datasetId && !!sliceId,
    })),
  });
}

export function useUpdateSliceJobWithIdsMutation({
  datasetId,
  selectedImageIds,
  commandContext,
}: {
  datasetId: string;
  selectedImageIds: string[];
  commandContext: CurateCommandContext;
}) {
  const { getJobPresignedUrl, postJob } = useCurateCommandsService();
  const postFn = async (
    datasetId: string,
    sliceId: string,
    selectedImageIds: string[],
    remove?: boolean,
  ) => {
    if (!sliceId || selectedImageIds.length === 0) return;
    const ids = selectedImageIds.map(list => {
      return { id: list };
    });
    const jsonToUpload = JSON.stringify(ids);
    const jsonFileSize = FileUtils.getJsonFileSize(jsonToUpload);
    const presignedUrlRes = await getJobPresignedUrl({ file_size: jsonFileSize });
    await FileService.uploadPresignedJSON({
      uploadUrl: presignedUrlRes.uploadUrl,
      jsonFile: jsonToUpload,
    });
    const jobParams = {
      dataset_id: datasetId,
      images: {
        ids: selectedImageIds,
        keys: [], // always empty
      },
      slice_id: sliceId,
      remove,
    };
    const jobResult = await postJob({
      job_type: 'UPDATE_SLICE',
      param: { ...jobParams, images: { ...jobParams.images, param_id: presignedUrlRes.id } },
    });
    return { jobResult, jobParams };
  };
  return useMutation({
    mutationFn: ({ sliceId, remove }: { sliceId: string; remove?: boolean }) =>
      postFn(datasetId, sliceId, selectedImageIds, remove),
    onSuccess: async (data, params) => {
      if (data && data?.jobResult?.id) {
        await commandContext.registerCommand(data.jobResult.id, data.jobParams);
      }
    },
  });
}

type UpdateSliceJobParams = {
  datasetId: string;
  commandContext: CurateCommandContext;
};
export function useUpdateSliceJobByEvalMutation({
  datasetId,
  diagnosisId,
  commandContext,
}: {
  datasetId: string;
  diagnosisId: string;
  commandContext: CurateCommandContext;
}) {
  function getJobParams(params: {
    datasetId: string;
    sliceId: string;
    diagnosisId: string;
    evaluationFilter: EvaluationFilterSchema;
    splitIn?: Split[];
  }): UpdateSliceByEvaluationParam {
    const { datasetId, sliceId, evaluationFilter, diagnosisId, splitIn } = params;

    return {
      dataset_id: datasetId,
      slice_id: sliceId,
      evaluation_filters: {
        diagnosis_id: diagnosisId,
        ...(splitIn && { split_in: splitIn }),
        ...(evaluationFilter && transformFilterBody(evaluationFilter)),
      },
    };
  }

  const { postJob } = useCurateCommandsService();
  const postFn = async (
    sliceId: string,
    evaluationFilter: EvaluationFilterSchema,
    splitIn?: Split[],
  ) => {
    if (!sliceId || !diagnosisId) return;
    const jobParams = getJobParams({
      datasetId,
      diagnosisId,
      sliceId,
      evaluationFilter,
      splitIn,
    });

    const result = await postJob({
      job_type: 'UPDATE_SLICE_BY_EVALUATIONS',
      param: { ...jobParams },
    });
    return { result, jobParams };
  };
  return useMutation({
    mutationFn: ({
      sliceId,
      evaluationFilter,
      splitIn,
    }: {
      sliceId: string;
      sliceName: string | undefined;
      queryString: string;
      evaluationFilter: EvaluationFilterSchema;
      splitIn?: Split[];
    }) => postFn(sliceId, evaluationFilter, splitIn),
    onSuccess: async (data, params) => {
      if (data && data?.result?.id) await commandContext.registerCommand(data.result.id);
    },
  });
}

export function useUpdateSliceJobByAnnosMutation({
  datasetId,
  commandContext,
  annotationFilter,
  queryString,
}: UpdateSliceJobParams & {
  queryString: string;
  annotationFilter: AnnotationFilterSchema;
}) {
  function getJobParams(params: {
    datasetId: string;
    sliceId: string;
    queryString?: string;
    annotation_filters: FilterSchema;
  }) {
    const { datasetId, queryString, sliceId, annotation_filters } = params;
    return {
      dataset_id: datasetId,
      slice_id: sliceId,
      annotation_filters: {
        ...(queryString && queryString !== '' && { query: queryString }),
        ...annotation_filters,
      },
    };
  }

  const { postJob } = useCurateCommandsService();
  const postFn = async (sliceId: string) => {
    if (!sliceId) return;
    const jobParams = getJobParams({
      datasetId,
      sliceId,
      queryString,
      ...formatAnnotationFilter(annotationFilter),
    });
    const result = await postJob({
      job_type: 'UPDATE_SLICE_BY_ANNOTATIONS',
      param: { ...jobParams },
    });
    return { result, jobParams };
  };
  return useMutation({
    mutationFn: ({ sliceId }: { sliceId: string }) => postFn(sliceId),
    onSuccess: async (data, params) => {
      if (data && data?.result?.id) await commandContext.registerCommand(data.result.id);
    },
  });
}

export function useCreateEmptySliceJobMutation({
  datasetId,
}: Pick<UpdateSliceJobParams, 'datasetId'>) {
  const { createSlice } = useCurateDatasetService();
  const queryClient = useQueryClient();
  const mutationFn = ({ name, description }: { name: string; description: string }) =>
    createSlice({ name, description, datasetId });
  return useMutation(mutationFn, {
    onError: error => {
      console.error('Error creating slice', error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['curate-dataset-slice', 'curate-dataset-slices'],
      });
    },
  });
}

export function useUpdateSliceByQueryJobMutation({
  datasetId,
  commandContext,
  appliedFilters,
  queryString,
  sliceName,
}: UpdateSliceJobParams & {
  queryString: string;
  appliedFilters: ImageFilterSchema;
  sliceName?: string;
}) {
  const { postJob } = useCurateCommandsService();
  const getJobParams = (sliceId: string, remove?: boolean) => {
    return {
      dataset_id: datasetId,
      image_filters: {
        ...(sliceName && { slice: sliceName }),
        ...(queryString && queryString !== '' && { query: queryString }),
        ...formatImageFilter(appliedFilters),
      },
      slice_id: sliceId,
      remove,
    };
  };

  const mutationFn = async ({ sliceId, remove }: { sliceId: string; remove?: boolean }) => {
    const jobParams = getJobParams(sliceId, remove);
    const jobResult = await postJob({
      job_type: 'UPDATE_SLICE_BY_QUERY',
      param: jobParams,
    });
    return { jobResult, jobParams };
  };

  return useMutation(mutationFn, {
    onSuccess: async (data, variables) => {
      if (data && data?.jobResult?.id) {
        await commandContext.registerCommand(data.jobResult.id, data.jobParams);
      }
    },
    onError: error => {
      console.error('Error creating slice', error);
    },
  });
}
