import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { groupBy, map } from 'lodash';

import getErrorConst from '../consts/ErrorConst';
import ProjectService from '../services/ProjectService';
import { MemberData } from '../types/memberTypes';
import { ProjectData } from '../types/projectTypes';
import WorkappUnion from '../union/WorkappUnion';
import ProjectUtils from '../utils/ProjectUtils';
import UserUtils from '../utils/UserUtils';
import { useAuthInfo } from './AuthContext';
import { useErrorInfo } from './ErrorContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

// prettier-ignore
type ProjectContextProps =
  StateGetterSetter<['project', 'setProject'], ProjectData | any> &
  StateGetterSetter<['owners', 'setOwners'], any> &
  StateGetterSetter<['admins', 'setAdmins'], any> &
  StateGetterSetter<['collaborators', 'setCollaborators'], any> &
  StateGetterSetter<['managers', 'setManagers'], any> &
  StateGetterSetter<['workers', 'setWorkers'], any> &
  StateGetterSetter<['reviewers', 'setReviewers'], any> &
  StateGetterSetter<['assigneeOptions', 'setAssigneeOptions'], any> &
  StateGetterSetter<['annoOptions', 'setAnnoOptions'], any> &
  StateGetterSetter<['mentionUserOptions', 'setMentionUserOptions'], any[]> &
  StateGetterSetter<['flatAnnoOptions', 'setFlatAnnoOptions'], any> &
  StateGetterSetter<['categorizationIdToName', 'setCategorizationIdToName'], any> &
  StateGetterSetter<['categorizationLabelInterface', 'setCategorizationLabelInterface'], any> &
  StateGetterSetter<['tags', 'setTags'], any> &
  StateGetterSetter<['tagIds', 'setTagIds'], any> &
  StateGetterSetter<['tagOptions', 'setTagOptions'], any> &
  StateGetterSetter<['groups', 'setGroups'], any> &
  StateGetterSetter<['classes', 'setClasses'], any> &
  StateGetterSetter<['userObjects', 'setUserObjects'], any> &
  StateGetterSetter<['useAutoLabel', 'setUseAutoLabel'], boolean> &
  {
    updateMembers(projectId: string): Promise<void>;
    updateTags(projectId: string): Promise<void>;
    updateProjectInfo(): Promise<void>;
    resetProject(): void;
    loaded: boolean;
  };

export const ProjectContext = React.createContext({} as ProjectContextProps);

export const ProjectProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [project, setProject] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [owners, setOwners] = useState<MemberData[]>([]);
  const [admins, setAdmins] = useState<MemberData[]>([]);
  const [managers, setManagers] = useState<MemberData[]>([]);
  const [workers, setWorkers] = useState<any[]>([]);
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [collaborators, setCollaborators] = useState<any[]>([]);
  const [assigneeOptions, setAssigneeOptions] = useState<any[]>([]);
  const [categorizationIdToName, setCategorizationIdToName] = useState<any>(null);
  const [categorizationLabelInterface, setCategorizationLabelInterface] = useState<any>(null);
  const [tags, setTags] = useState<any[]>([]);
  const [tagIds, setTagIds] = useState({});
  const [tagOptions, setTagOptions] = useState<any[]>([]);
  const [groups, setGroups] = useState({});
  const [classes, setClasses] = useState({});
  const [userObjects, setUserObjects] = useState({});
  const [mentionUserOptions, setMentionUserOptions] = useState<any[]>([]);
  const [useAutoLabel, setUseAutoLabel] = useState(false);

  const [annoOptions, setAnnoOptions] = useState<any[]>([]);
  const [flatAnnoOptions, setFlatAnnoOptions] = useState({});
  const authInfo = useAuthInfo();
  const errorInfo = useErrorInfo();

  const routeInfo = useRouteInfo();
  const {
    history,
    urlMatchInfo: { accountName, projectId },
  } = routeInfo;

  const updateTags = async (projectId: string) => {
    const tags = await ProjectService.getTags({
      projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const tagIds: Record<string, string> = {};
    const tagOptions = tags.map((tag: { name: string; id: string }) => {
      tagIds[tag.name] = tag.id;
      return { value: tag.name, label: tag.name };
    });
    setTags(tags);
    setTagIds(tagIds);
    setTagOptions(tagOptions);
  };

  const updateMembers = async (projectId: string) => {
    const [owners, admins, collaborators] = await ProjectService.getUsers({
      projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    setOwners(owners.users);
    setAdmins(admins.users);
    setCollaborators(collaborators.users);

    const collaboratorsByRole = groupBy(collaborators.users, u => u.projectRole) as Record<
      'manager' | 'worker' | 'reviewer',
      MemberData[]
    >;
    setManagers(collaboratorsByRole.manager ?? []);
    setWorkers(collaboratorsByRole.worker ?? []);
    setReviewers(collaboratorsByRole.reviewer ?? []);

    const nextUserObjects = UserUtils.createUserObjects({
      owners: owners.users,
      admins: admins.users,
      managers: collaboratorsByRole.manager,
      workers: collaboratorsByRole.worker,
      reviewers: collaboratorsByRole.reviewer,
    });
    setUserObjects(nextUserObjects);

    const mentionOptions: any[] = [];
    const assigneeOptions = [
      {
        label: 'Owner',
        options: map(owners.users, owner => {
          mentionOptions.push({
            id: owner.email,
            display: owner.name,
            avatarUrl: owner.avatarUrl,
            title: 'owner',
          });
          return {
            value: `${owner.name} (${owner.email})`,
            label: `${owner.name} (${owner.email})`,
          };
        }),
      },
      {
        label: 'Admin',
        options: map(admins.users, user => {
          mentionOptions.push({
            id: user.email,
            display: user.name,
            avatarUrl: user.avatarUrl,
            title: 'admin',
          });
          return {
            value: `${user.name} (${user.email})`,
            label: `${user.name} (${user.email})`,
          };
        }),
      },
      {
        label: 'Manager',
        options: map(collaboratorsByRole.manager, user => {
          mentionOptions.push({
            id: user.email,
            display: user.name,
            avatarUrl: user.avatarUrl,
            title: 'manager',
          });
          return {
            value: `${user.name} (${user.email})`,
            label: `${user.name} (${user.email})`,
          };
        }),
      },
      {
        label: 'Reviewer',
        options: map(collaboratorsByRole.reviewer, user => {
          mentionOptions.push({
            id: user.email,
            display: user.name,
            avatarUrl: user.avatarUrl,
            title: 'reviewer',
          });
          return {
            value: `${user.name} (${user.email})`,
            label: `${user.name} (${user.email})`,
          };
        }),
      },
      {
        label: 'Labeler',
        options: map(collaboratorsByRole.worker, user => {
          mentionOptions.push({
            id: user.email,
            display: user.name,
            avatarUrl: user.avatarUrl,
            title: 'worker',
          });
          return {
            value: `${user.name} (${user.email})`,
            label: `${user.name} (${user.email})`,
          };
        }),
      },
    ];

    setAssigneeOptions(assigneeOptions);
    setMentionUserOptions(mentionOptions);
  };

  const updateProjectInfo = async () => {
    try {
      const project = await ProjectService.getProject({
        projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setProject(project);

      await updateTags(project.id);

      setLoaded(true);

      if (!authInfo.isGuest) {
        await updateMembers(project.id);
      }

      const {
        workapp,
        labelInterface: { categorization },
      } = project;

      if (WorkappUnion.isImageDefault(workapp)) {
        setUseAutoLabel(
          ProjectUtils.getUseAutoLabel(project.workapp, project.labelInterface.objects),
        );

        if (project?.labelInterface?.categorization) {
          setCategorizationLabelInterface(
            ProjectUtils.convertToCategorization(project.labelInterface.categorization),
          );
        }

        const objectAnnoOptions = map(project.labelInterface.objects, object => ({
          label: `${object.name} | ${capitalizeFirstLetter(object.annotationType)}`,
          value: object.id,
        }));

        if (!project.labelInterface.categorization) {
          setAnnoOptions([{ annoType: 'Object Class', children: objectAnnoOptions }]);
          setFlatAnnoOptions(
            ProjectUtils.flattenCategorizationNodes({}, [
              { annoType: 'Object Class', children: objectAnnoOptions },
            ]),
          );
        } else {
          const categorizationIdToName: Record<string, string> = {};
          const parentChildrenMap: Record<any, any> = {};
          categorization.wordMap.forEach((word: any) => {
            parentChildrenMap[word.id] = word.children;
            categorizationIdToName[word.id] = word.name;
          });

          const formattedCategorization = ProjectUtils.getChildrenCategories(
            parentChildrenMap.root,
            [],
            [],
            parentChildrenMap,
            categorizationIdToName,
          );

          setCategorizationIdToName(categorizationIdToName);
          setAnnoOptions([
            { annoType: 'Object Class', children: objectAnnoOptions },
            {
              annoType: 'Image Category',
              children: formattedCategorization,
            },
          ]);
          setFlatAnnoOptions(
            ProjectUtils.flattenCategorizationNodes({}, [
              { annoType: 'Object Class', children: objectAnnoOptions },
              {
                annoType: 'Image Category',
                children: formattedCategorization,
              },
            ]),
          );
        }
      } else {
        // video-siesta
        // todo (tsnoh) categorization
        const objectDef =
          project.labelInterface.objectDetection || project.labelInterface.objectTracking;
        const categorizationDef = project.labelInterface.categorization;

        setUseAutoLabel(
          ProjectUtils.getUseAutoLabel(project.workapp, [
            objectDef.objectClasses,
            categorizationDef.properties,
          ]),
        );

        const objectAnnoOptions = map(objectDef.objectClasses, object => ({
          label: `${object.name} | ${capitalizeFirstLetter(object.annotationType)}`,
          value: object.id,
        }));

        if (categorization?.properties.length === 0) {
          setAnnoOptions([{ annoType: 'Object Class', children: objectAnnoOptions }]);
          setFlatAnnoOptions(
            ProjectUtils.flattenCategorizationNodes({}, [
              { annoType: 'Object Class', children: objectAnnoOptions },
            ]),
          );
        } else {
          const childrenOfRoots: string[] = [];
          const categorizationIdToName: Record<string, string> = {};
          const parentChildrenMap: Record<any, any> = {};

          type Option = {
            id: string;
            name: string;
            children?: Option[];
          };
          type Property = {
            id: string;
            name: string;
            type: 'checkbox' | 'radio' | 'free response';
            options: Option[];
          };

          const traversalTree = (option: Option) => {
            parentChildrenMap[option.id] = option.children
              ? option.children.map(option => option.id)
              : [];
            categorizationIdToName[option.id] = option.name;
            option.children?.forEach(traversalTree);
          };

          categorization.properties
            .filter((property: Property) => property.type !== 'free response')
            .forEach((property: Property) => {
              const childrenIds = property.options.map(option => option.id);
              parentChildrenMap[property.id] = childrenIds;
              categorizationIdToName[property.id] = property.name;
              property.options.forEach(traversalTree);
              childrenOfRoots.push(property.id);
            });

          const formattedCategorization = ProjectUtils.getChildrenCategories(
            childrenOfRoots,
            [],
            [],
            parentChildrenMap,
            categorizationIdToName,
          );

          setCategorizationIdToName(categorizationIdToName);
          setAnnoOptions([
            { annoType: 'Object Class', children: objectAnnoOptions },
            {
              annoType: 'Image Category',
              children: formattedCategorization,
            },
          ]);
          setFlatAnnoOptions(
            ProjectUtils.flattenCategorizationNodes({}, [
              { annoType: 'Object Class', children: objectAnnoOptions },
              {
                annoType: 'Image Category',
                children: formattedCategorization,
              },
            ]),
          );
        }
      }
    } catch (err: any) {
      if (err.message === getErrorConst(t).PAGE_NOT_FOUND || err.message === 'Not Found') {
        errorInfo.setError(getErrorConst(t).PAGE_NOT_FOUND);
      }
    }
  };

  const resetProject = () => {
    setProject(null);
    setOwners([]);
    setAdmins([]);
    setManagers([]);
    setWorkers([]);
    setReviewers([]);
    setCollaborators([]);
    setAssigneeOptions([]);
    setAnnoOptions([]);
    setTags([]);
    setTagIds({});
    setTagOptions([]);
    setGroups({});
    setClasses({});
    setUseAutoLabel(false);
  };

  useEffect(() => {
    if (!projectId) return;

    (async () => {
      try {
        await updateProjectInfo();
      } catch (err: any) {
        if (err.response && err.response.status === 403) {
          history.push(`/${accountName}/label/projects`);
          return;
        }
        if (err.response && err.response.status === 404) {
          history.push(`/${accountName}/label/projects`);
          return;
        }
        throw err;
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      resetProject();
    };
    // eslint-disable-next-line
  }, [projectId]);

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
        owners,
        setOwners,
        admins,
        setAdmins,
        collaborators,
        setCollaborators,
        managers,
        setManagers,
        workers,
        setWorkers,
        reviewers,
        setReviewers,
        assigneeOptions,
        setAssigneeOptions,
        annoOptions,
        setAnnoOptions,
        mentionUserOptions,
        setMentionUserOptions,
        flatAnnoOptions,
        setFlatAnnoOptions,
        categorizationIdToName,
        setCategorizationIdToName,
        categorizationLabelInterface,
        setCategorizationLabelInterface,
        tags,
        setTags,
        tagIds,
        setTagIds,
        tagOptions,
        setTagOptions,
        groups,
        setGroups,
        classes,
        setClasses,
        userObjects,
        setUserObjects,
        useAutoLabel,
        setUseAutoLabel,
        updateMembers,
        updateTags,
        updateProjectInfo,
        resetProject,
        loaded,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectInfo = (): ProjectContextProps => {
  return React.useContext(ProjectContext);
};

function capitalizeFirstLetter(string: string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}
