import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  circularDialog: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflow: 'hidden',
      scroll: 'none',
    },
  },
}));

interface Props {
  isLoading: boolean;
}

const CircularProgressDialog: React.FC<Props> = ({ isLoading }) => {
  const classes = useStyles();
  return (
    <MUI.Dialog className={classes.circularDialog} open={isLoading}>
      <MUI.CircularProgress disableShrink />
    </MUI.Dialog>
  );
};

export default CircularProgressDialog;
