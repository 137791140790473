import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ChevronDown, Clear, FileJson, Image, LinkExternal } from '@superb-ai/icons';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Icon,
  IconButton,
  Label,
  LinkTypography,
  Radio,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import AnalyticsTracker from '../../../../../../analyticsTracker';
import { getDefaultFileName } from '../../../../../../configs/NamingRulesConfig';
import { getUrl } from '../../../../../../routes/util';
import { useActionContext } from '../../../../contexts/ActionContext';
import { SelectedSliceOption } from '../../../../contexts/UploadContext';
import { useCurateCreateJobMutation } from '../../../../queries/commandQueries';
import { useDatasetObjectCountQuery } from '../../../../queries/datasetObjectQueries';
import { PREPARE_DOWNLOAD } from '../../../../types/commandTypes';
import { DownloadAnnotationFormat } from '../../../../types/downloadTypes';
import { convertSelectedImageIdsToQueryString } from '../../../../utils/filterUtils';
import DownloadNameInput from '../downloads/DownloadNameInput';
import { ClusterLevel } from '../filter/types';

export default function PrepareDownloadModal({
  dialog,
  imageTotalCount,
  sliceName,
  sliceId,
  datasetId,
  datasetName,
  queryString,
  filter,
}: {
  dialog: ReturnType<typeof useDialogState>;
  imageTotalCount: number;
  datasetId: string;
  datasetName: string;
  sliceName?: string;
  sliceId?: string;
  queryString?: string;
  filter?: {
    cluster_id_in: string[] | [];
    cluster_level: ClusterLevel;
  };
}) {
  type DownloadTarget = 'IMAGES' | 'ANNOTATIONS';
  const { mutate: createJob } = useCurateCreateJobMutation();

  const { accountName } = useParams<{ accountName: string }>();
  const { selectedData, setSelectedAllData, selectedAllData } = useActionContext();

  const { t, i18n } = useTranslation();
  const [name, setName] = useState<string>(getDefaultFileName(sliceName || datasetName || ''));
  const [nameErrorMessages, setNameErrorMessages] = useState<string[]>([]);
  const [downloadTarget, setDownloadTarget] = useState<DownloadTarget[]>(['IMAGES', 'ANNOTATIONS']);
  const [selectedSlice, setSelectedSlice] = useState<SelectedSliceOption | undefined>(undefined);
  const [downloadFormat, setDownloadFormat] = useState<DownloadAnnotationFormat>('CURATE');
  const [annotationFormatDropdownOpen, setAnnotationFormatDropdownOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const [portalStyle, setPortalStyle] = useState({});

  const objectQuery = useDatasetObjectCountQuery({
    datasetId,
    queryString,
    sliceName: sliceName || '',
    appliedFilters: filter,
  });

  const objectTotalCount = objectQuery.data?.count || 0;

  useEffect(() => {
    if (name.length === 0) setNameErrorMessages([]);
  }, [name]);

  useEffect(() => {
    if (dialog.visible) {
      if (!selectedAllData && setSelectedAllData) setSelectedAllData(true);
      setName(getDefaultFileName(sliceName || datasetName || ''));
      setNameErrorMessages([]);
      setSelectedSlice(undefined);
      if (objectTotalCount === 0) {
        setDownloadTarget(['IMAGES']);
      } else {
        setDownloadTarget(['IMAGES', 'ANNOTATIONS']);
      }
    }
  }, [dialog.visible]);

  function handleCheckboxClick(target: DownloadTarget) {
    setDownloadTarget(prev => {
      if (prev.includes(target)) {
        return prev.filter(t => t !== target);
      }
      return [...prev, target];
    });
  }

  async function handleClickStart() {
    try {
      await createJob({
        data: {
          dataset_id: datasetId,
          download_name: name,
          download_type: downloadTarget.length === 2 ? 'ALL' : downloadTarget[0],
          image_filters: {
            ...{
              slice: sliceName
                ? sliceName
                : selectedSlice
                ? selectedSlice.name
                : sliceName || undefined,
            },
            ...(selectedData && selectedData.length > 0
              ? {
                  query: convertSelectedImageIdsToQueryString(selectedData.map(data => data.id)),
                }
              : {
                  query: queryString,
                  ...filter,
                }),
          },
          download_format: downloadTarget.includes('ANNOTATIONS') ? downloadFormat : undefined,
        },
        jobType: PREPARE_DOWNLOAD,
      });
      dialog.hide();
    } finally {
      AnalyticsTracker.prepareDownloadRequested({
        datasetId,
        accountId: accountName,
        sliceId,
      });
    }
  }

  useEffect(() => {
    if (annotationFormatDropdownOpen && anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect();
      setPortalStyle({
        position: 'absolute',
        top: `${rect.bottom}px`,
        left: `${rect.left}px`,
        width: `${rect.width + 60}px`,
      });
    }
    const handleClickOutside = event => {
      if (
        portalRef.current &&
        !portalRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        setAnnotationFormatDropdownOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [annotationFormatDropdownOpen]);

  const AnnotationTypeRadio = useCallback(() => {
    const options = ['CURATE', 'YOLOV5'].map(value => (
      <Label key={value} borderRadius="2px" backgroundColor={{ checked: 'primary-100' }}>
        <Box py={0.5} px={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Radio
              value={value === downloadFormat}
              onClick={() => {
                setDownloadFormat(value as DownloadAnnotationFormat);
                setAnnotationFormatDropdownOpen(false);
              }}
            />
            <Typography variant="m-regular">
              {value === 'CURATE' ? 'Superb AI' : 'YOLOv5'}
            </Typography>
            <Chip color="cloud">{value === 'CURATE' ? 'JSON' : 'TXT'}</Chip>
          </Box>
          <Box ml={4}>
            <Trans
              t={t}
              i18nKey={`curate.datasets.prepareDownload.downloadFormat.${value}_description`}
              components={{ emphasize1: <Typography style={{ fontStyle: 'italic' }} /> }}
            />
            <br />
            <Typography color="gray-300">
              {t(`curate.datasets.prepareDownload.downloadFormat.${value}_uploadDescription`)}
            </Typography>
          </Box>
        </Box>
      </Label>
    ));

    return (
      <Box
        borderRadius="2px"
        backgroundColor="white"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
        py={0.5}
        // border="1px solid"
        // borderColor={{ default: 'gray-200', checked: 'primary' }}
        // backgroundColor={{ checked: 'primary-100' }}
        // flexDirection="column"
      >
        {options}
      </Box>
    );
  }, [downloadFormat, setDownloadFormat, setAnnotationFormatDropdownOpen, t]);

  const CheckboxWithLabel = useCallback(
    (target: DownloadTarget) => {
      const includesAnnotation = downloadTarget.includes('ANNOTATIONS');
      const isDisabled = target === 'ANNOTATIONS' && objectTotalCount === 0;
      return (
        <Box>
          <Label
            borderRadius="2px"
            border="1px solid"
            color={isDisabled ? 'gray-300' : 'gray'}
            borderColor={{ default: 'gray-200', checked: 'primary' }}
            backgroundColor={{ checked: 'primary-100' }}
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box p={0.5}>
              <Box display="flex" alignItems="center" gap={0.5}>
                <Checkbox
                  disabled={isDisabled}
                  value={downloadTarget.includes(target)}
                  onClick={e => handleCheckboxClick(target)}
                />
                <Icon icon={target === 'IMAGES' ? Image : FileJson} />
                <Typography variant="m-regular">
                  {t(`curate.datasets.prepareDownload.dataType.${target}`, {
                    count: (target === 'IMAGES' ? imageTotalCount : objectTotalCount) || 0,
                  })}
                </Typography>
              </Box>
              <Box ml={4}>
                <Trans
                  t={t}
                  i18nKey={`curate.datasets.prepareDownload.dataType.${target}Description`}
                  components={{ emphasize1: <Typography style={{ fontStyle: 'italic' }} /> }}
                />
              </Box>
            </Box>
            {target === 'ANNOTATIONS' && (
              <Box p={1} width="100%" borderTop="1px solid" borderColor="gray-200">
                <Box display="flex" alignItems="center" gap={3}>
                  <Typography variant="m-regular" whiteSpace="nowrap">
                    {t('curate.datasets.prepareDownload.format')}
                  </Typography>
                  <Box
                    ref={anchorRef}
                    py={1}
                    px={2}
                    display="flex"
                    alignItems="center"
                    cursor={includesAnnotation ? 'pointer' : undefined}
                    width="100%"
                    color={includesAnnotation ? 'gray' : 'gray-300'}
                    backgroundColor="gray-100"
                    onClick={e => {
                      if (!includesAnnotation) return;
                      e.preventDefault();
                      setAnnotationFormatDropdownOpen(!annotationFormatDropdownOpen);
                    }}
                  >
                    {downloadFormat === 'CURATE' ? 'Superb AI' : 'YOLOv5'}
                    <Icon style={{ marginLeft: 'auto' }} icon={ChevronDown} size={12} />
                  </Box>
                </Box>
                {annotationFormatDropdownOpen &&
                  createPortal(
                    <Box ref={portalRef} style={{ zIndex: 1300, ...portalStyle }}>
                      <AnnotationTypeRadio />
                    </Box>,
                    document.body,
                  )}
              </Box>
            )}
          </Label>
        </Box>
      );
    },
    [
      downloadFormat,
      downloadTarget,
      imageTotalCount,
      objectTotalCount,
      t,
      annotationFormatDropdownOpen,
      AnnotationTypeRadio,
      handleCheckboxClick,
      portalStyle,
    ],
  );

  return (
    <Dialog
      state={dialog}
      aria-label={t('curate.datasets.prepareDownload.modalTitle')}
      hideOnClickOutside={false}
    >
      <Dialog.Header>
        <Box display="flex" alignItems="center" gap={0.5}>
          {t('curate.datasets.prepareDownload.modalTitle')}
        </Box>
      </Dialog.Header>
      <Dialog.Content overflow="overlay" style={{ width: '460px' }}>
        {dialog.visible && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="m-medium">
                {t('curate.datasets.prepareDownload.name')}
              </Typography>
              <DownloadNameInput
                name={name}
                setName={setName}
                nameErrorMessages={nameErrorMessages}
                setNameErrorMessages={setNameErrorMessages}
                inputProps={{
                  suffix: name.length ? (
                    <IconButton
                      variant="text"
                      size="s"
                      icon={Clear}
                      color="gray"
                      onClick={() => {
                        setName('');
                      }}
                    />
                  ) : undefined,
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="m-medium">
                {t('curate.datasets.prepareDownload.selectDataType')}
              </Typography>
              <Box display="flex" flexDirection="column" gap={1}>
                {CheckboxWithLabel('IMAGES')}
                {CheckboxWithLabel('ANNOTATIONS')}
              </Box>
            </Box>
            {/* {!sliceInfo && (
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="m-medium">
                  {t('curate.datasets.cloudUpload.selectSlice')}
                  <Typography color="gray-300"> ({t('curate.data.optional')})</Typography>
                </Typography>
                <SliceAsyncCombobox
                  {...{ datasetId, selectedSlice, isCreatable: false, setSelectedSlice }}
                />
              </Box>
            )} */}
          </Box>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Box width="100%" display="flex" alignItems="center" gap={0.5}>
          <LinkTypography
            display="flex"
            alignItems="center"
            gap={0.5}
            variant="m-regular"
            onClick={e => {
              e.preventDefault();
              window.open(getUrl([accountName, 'curate', 'dataset', datasetId, 'downloads']));
            }}
          >
            {t('curate.datasets.prepareDownload.viewDownloads')}
            <Icon icon={LinkExternal} />
          </LinkTypography>
          <Button variant="text" onClick={() => void dialog.hide()} style={{ marginLeft: 'auto' }}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="strong-fill"
            color="primary"
            disabled={
              nameErrorMessages.length > 0 || name.length === 0 || downloadTarget.length === 0
            }
            onClick={handleClickStart}
          >
            {t('curate.datasets.prepareDownload.button')}
          </Button>
        </Box>
      </Dialog.Actions>
    </Dialog>
  );
}
