import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useDebounce } from '../../../../../../../hooks/DebounceHook';
import { useDiagnosisCommonFilterContext } from '../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisGridFilterContext } from '../../../../../contexts/DiagnosisGridFilterContext';
import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useEvaluationSummaryQuery } from '../../../../../queries/diagnosisEvaluationValueQueries';
import { CountBadge } from '../../analytics/components/CountBadge';
import { CardProps } from './analytics/cards/ConfusionMatrixCard';
import { getAnnotationGroupColor } from './analytics/colorScale';
import { VerticalDivider } from './analytics/elements/VerticalDivider';
import { DiagnosisModelBadge } from './ModelBadge';

const EvaluationSummary = ({
  diagnosis,
  additionalInfo,
}: CardProps & { additionalInfo?: JSX.Element }) => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { t } = useTranslation();
  const { filterBody } = useDiagnosisGridFilterContext();
  const { selectedDiagnosis } = useDiagnosisModelContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { splitIn, sliceId } = useDiagnosisCommonFilterContext();
  const debouncedFilterBody = useDebounce(filterBody, 300);

  const query = useEvaluationSummaryQuery({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    diagnosisId: diagnosis.id,
    filter: debouncedFilterBody,
    modelSource: selectedDiagnosis?.modelSource,
    splitIn,
    sliceId,
  });
  const summary = query?.data;

  return (
    <Box display="flex" height="100%" gap={2}>
      <DiagnosisModelBadge diagnosis={diagnosis} size={'m'} />
      <VerticalDivider ml={2} style={{ height: '40px' }} />
      <CountBadge
        name={t('curate.diagnosis.evaluationSummary.predictionCount')}
        count={summary?.predictionCount}
        color={getAnnotationGroupColor('predictionCount')}
        size={'m'}
      />
      <VerticalDivider ml={2} style={{ height: '40px' }} />
      <CountBadge
        name={t('curate.diagnosis.evaluationSummary.annotationCount')}
        count={summary?.annotationCount}
        color={getAnnotationGroupColor('annotationCount')}
        size={'m'}
      />
      {additionalInfo && additionalInfo}
    </Box>
  );
};

export default EvaluationSummary;
