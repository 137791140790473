import { useTranslation } from 'react-i18next';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { useRouteInfo } from '../../../contexts/RouteContext';

export default function ShortCut({ page }: { page: 'signIn' | 'signUp' }) {
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();

  return (
    <Box position="absolute" style={{ top: '50px', right: '50px' }}>
      <Typography variant="s-regular" color="gray-300">
        <Box display="flex" alignItems="center" gap={1}>
          {page === 'signIn' ? (
            <>
              {t('auth.alreadyHaveAnAccount')}
              <LinkTypography
                variant="s-strong"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => routeInfo.history.push('/auth/login')}
              >
                {t('auth.signIn').toLocaleUpperCase()}
              </LinkTypography>
            </>
          ) : (
            <>
              {t('auth.dontHaveAnAccount')}
              <LinkTypography
                variant="s-strong"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => routeInfo.history.push('/auth/sign_up')}
              >
                {t('auth.signUp').toLocaleUpperCase()}
              </LinkTypography>
            </>
          )}
        </Box>
      </Typography>
    </Box>
  );
}
