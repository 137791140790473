import { TFunction } from 'react-i18next';

export const CURATE_WHAT_TO_LABEL = 'CURATE_WHAT_TO_LABEL';
export const SPLIT_TRAIN_VAL = 'SPLIT_TRAIN_VAL';
export const FIND_EDGE_CASES = 'FIND_EDGE_CASES';
export const FIND_MISLABELS = 'FIND_MISLABELS';

export const IMAGE = 'IMAGE';
export const OBJECT = 'OBJECT';

export type EmbeddingType = typeof IMAGE | typeof OBJECT;
export type CurationType =
  | typeof CURATE_WHAT_TO_LABEL
  | typeof SPLIT_TRAIN_VAL
  | typeof FIND_EDGE_CASES
  | typeof FIND_MISLABELS;

export const CURATION_TYPE_CONFIG = (t: TFunction) => {
  return {
    CURATE_WHAT_TO_LABEL: { text: t('consts.autoCurateConst.curateWhatToLabel') },
    SPLIT_TRAIN_VAL: { text: t('consts.autoCurateConst.splitTrainVal') },
    FIND_EDGE_CASES: { text: t('consts.autoCurateConst.findEdgeCases') },
    FIND_MISLABELS: { text: t('consts.autoCurateConst.findMislabels') },
  };
};

export const DELETED_SLICE = 'Deleted Slice';
