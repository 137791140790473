import { AnnotationsChangedTableProps } from '../types/types';

export const objectCountsHeader: AnnotationsChangedTableProps[] = [
  {
    id: 'className',
    name: '',
    color: '#F5F5F5',
  },
  {
    id: 'queryCount', // Base export
    name: 'analytics.exportsAnalytics.queryCount',
    color: '#F5F5F5',
  },
  {
    id: 'refCount', // Compare export
    name: 'analytics.exportsAnalytics.refCount',
    color: '#F5F5F5',
  },
  {
    id: 'changes',
    name: 'analytics.exportsAnalytics.changeInAnnotations',
    color: '#F5F5F5',
  },
];

export const annotationsChangedHeader: AnnotationsChangedTableProps[] = [
  {
    id: 'class_name',
    name: '',
    color: '#FBFBFB',
  },
  {
    id: 'no_change',
    name: 'analytics.exportsAnalytics.noChange',
    color: '#F5F5F5',
    textColor: 'gray-300',
  },
  {
    id: 'added',
    name: 'analytics.exportsAnalytics.addedInCompare',
    color: '#F5F5F5',
    textColor: 'violet-400',
  },
  {
    id: 'deleted',
    name: 'analytics.exportsAnalytics.deletedInCompare',
    color: '#F5F5F5',
    textColor: 'red-400',
  },
  {
    id: 'edited',
    name: 'analytics.exportsAnalytics.editedInCompare',
    color: '#F5F5F5',
    textColor: 'yellow-500',
  },
  {
    id: 'changed_class',
    name: 'analytics.exportsAnalytics.changedClassInCompare',
    color: '#F5F5F5',
    textColor: 'mint-400',
  },
];

export const annotationChangesHeader = (): AnnotationsChangedTableProps[] => {
  // TODO: translate
  return [
    { id: 'className', name: 'Class Name' },
    { id: 'noChange', name: 'No Change' },
    { id: 'added', name: 'Added' },
    { id: 'deleted', name: 'Deleted' },
    { id: 'queryEdited', name: 'Edited Shape' },
    { id: 'queryFixedClass', name: 'Changed Class' },
  ];
};
