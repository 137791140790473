import { useEffect, useState } from 'react';

import constate from 'constate';
import { Texture } from 'pixi.js';

import { EmbeddingDatum, ObjectEmbeddingDatum } from '../../types';

export type Point<T> = T extends 'image' ? EmbeddingDatum : ObjectEmbeddingDatum;

export type SelectionBoxType = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

function _useData<T>(props: { points: Point<T>[]; comparedPoints: Point<T>[] }) {
  const [points, setPoints] = useState<Point<T>[]>(props.points);
  const [comparedPoints, setComparedPoints] = useState<Point<T>[]>(props.comparedPoints);
  const [selectionAreaBoxes, setSelectionAreaBoxes] = useState<SelectionBoxType[]>([]);
  const [filteredPoints, setFilteredPoints] = useState<Point<T>[]>([]);

  useEffect(() => {
    setPoints(props.points);
    // prefetch
    // props.points.forEach(point => {
    //   fetchImage({
    //     url: point.smallImageThumbnailUrl || '',
    //   });
    // });
  }, [props.points]);

  useEffect(() => {
    setComparedPoints(props.comparedPoints);
  }, [props.comparedPoints]);

  useEffect(() => {
    if (selectionAreaBoxes.length === 0) {
      // setSelectedImages([]);
      setFilteredPoints([]);

      return;
    }
    const firstSelectionAreaBox = selectionAreaBoxes[0];
    const selectedPoints = points.filter(point => {
      return (
        point.x >= firstSelectionAreaBox.x &&
        point.x <= firstSelectionAreaBox.x + firstSelectionAreaBox.width &&
        point.y >= firstSelectionAreaBox.y &&
        point.y <= firstSelectionAreaBox.y + firstSelectionAreaBox.height
      );
    });
    // setSelectedImages(selectedPoints);
    setFilteredPoints(selectedPoints);
    // }, [points, selectionAreaBoxes, setSelectedImages]);
  }, [points, selectionAreaBoxes]);

  return {
    points,
    setPoints,
    comparedPoints,
    setComparedPoints,
    selectionAreaBoxes,
    setSelectionAreaBoxes,
    filteredPoints,
    setFilteredPoints,
  };
}

export const [
  DataProvider,
  usePoints,
  useSetPoints,
  useComparedPoints,
  useSetComparedPoints,
  useSelectionAreaBoxes,
  useSetSelectionAreaBoxes,
  useFilteredPoints,
  useSetFilteredPoints,
] = constate(
  _useData<'image' | 'object'>,
  ({ points }) => points,
  ({ setPoints }) => setPoints,
  ({ comparedPoints }) => comparedPoints,
  ({ setComparedPoints }) => setComparedPoints,
  ({ selectionAreaBoxes }) => selectionAreaBoxes,
  ({ setSelectionAreaBoxes }) => setSelectionAreaBoxes,
  ({ filteredPoints }) => filteredPoints,
  ({ setFilteredPoints }) => setFilteredPoints,
);

export async function fetchImage({ url }: { url: string }) {
  if (url) {
    Texture.from(url);
  }
  // return new Promise<HTMLImageElement>(async (resolve, reject) => {
  //   const img = new Image();
  //   img.crossOrigin = 'anonymous';
  //   img.addEventListener('load', () => {
  //     resolve(img);
  //     img.remove();
  //   });

  //   img.addEventListener('error', event => {
  //     reject(event);
  //   });

  //   img.addEventListener('abort', event => {
  //     reject(event);
  //   });

  //   img.src = url;
  // }).catch(err => {
  //   return 'thumbnail_image';
  // });
}
