import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Icon, Tooltip, Typography } from '@superb-ai/ui';

import { formatCount } from '../charts/utils';

const Circle = ({ color }: { color: string }) => (
  <div style={{ width: 8, height: 8, borderRadius: 4, backgroundColor: color }} />
);

type TotalCount = {
  selection: { color: string; count: number; name: string };
  compared?: { color: string; count: number; name: string };
};

export function TotalCountBanner({
  text,
  totalCounts,
  infoText,
}: {
  text: string;
  totalCounts: TotalCount;
  infoText?: string;
}) {
  const { selection, compared } = totalCounts;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="2px"
      backgroundColor="gray-100"
      px={1}
      py={0.5}
      style={{ height: '44px', flex: 1 }}
    >
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant="m-medium">{text}</Typography>
        {infoText && (
          <Tooltip key={infoText} content={infoText} placement="top">
            <Box display="flex" alignItems="center">
              <Icon color="black" icon={InfoCircleOutline} size="s" style={{ margin: '2px' }} />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Circle color={selection.color} />
        <Tooltip content={selection.name} placement="top">
          <Typography variant="m-medium">{formatCount(selection.count)}</Typography>
        </Tooltip>
        {compared && (
          <>
            <Typography color="gray-300"> | </Typography>
            <Circle color={compared.color} />
            <Tooltip content={compared.name} placement="top">
              <Typography variant="m-medium">{formatCount(compared.count)}</Typography>
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  );
}
