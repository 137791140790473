import { AppRouteLayoutProps, AppRoutes } from '../../../menu';
import { CurateCommandContextProvider } from '../contexts/CommandContext';

export default function CurateLayout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  localStorage.setItem('last_visit_page', 'curate');

  return (
    <CurateCommandContextProvider>
      <AppRoutes items={menuItem.children ?? []} />
    </CurateCommandContextProvider>
  );
}
