import { Button, Dialog, Typography, useDialogState } from '@superb-ai/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadsService } from '../../../../services/DownloadsService';

export default function DeleteDownloadModal({
  onConfirm,
  dialog,
}: {
  onConfirm: () => Promise<void>;
  dialog: ReturnType<typeof useDialogState>;
}) {
  const { t } = useTranslation();

  return (
    <Dialog state={dialog} aria-label={t('curate.downloads.deleteModal.title')}>
      <Dialog.Header>{t('curate.downloads.deleteModal.title')}</Dialog.Header>
      <Dialog.Content>
        <Typography variant="m-regular">{t('curate.downloads.deleteModal.description')}</Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => dialog.hide()}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={onConfirm}>
          {t('button.delete')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
