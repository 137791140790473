import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Icon,
  Input,
  Modal,
  RadioButtons,
  TextEllipsis,
  Typography,
} from '@superb-ai/norwegian-forest';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useCreateCustomAutoLabelMutation } from '../../../../../queries/customAutoLabel';
import ExportService from '../../../../../services/ExportService';
import { HistoryType } from '../../../../../types/exportTypes';
import { formatDateTime } from '../../../../../utils/date';
import { formatCount } from '../../../../../utils/numberFormat';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';

function getDefaultName(projectName: string, length = 50) {
  const prefix = 'Custom Auto-Label AI';
  const l = prefix.length + 3;
  return `${prefix} (${projectName.substring(0, length - l)})`;
}

export const CreateCustomAutoLabelModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const projectInfo = useProjectInfo();
  const [name, setName] = useState(getDefaultName(projectInfo.project.name));
  const [historyLoadingState, setHistoryLoadingState] = useState<'idle' | 'load' | 'done'>('idle');
  const [histories, setHistories] = useState<HistoryType[]>([]);
  const [selectedHistory, setSelectedHistory] = useState<HistoryType | null>(null);
  const scrollBottomDetectorRef = useRef<HTMLDivElement | null>(null);
  const [detectedCount, setDetectedCount] = useState(-1);
  const [page, setPage] = useState(1);

  const { mutate: createCustomAutoLabel, isLoading } = useCreateCustomAutoLabelMutation({
    name,
    selectedHistory,
    onSuccess() {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (!open) {
      setName(getDefaultName(projectInfo.project.name));
      setHistoryLoadingState('idle');
      setHistories([]);
      setSelectedHistory(null);
      setPage(1);
      return;
    }

    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          setDetectedCount(c => c + 1);
        }
      });
    });

    if (scrollBottomDetectorRef.current) {
      io.observe(scrollBottomDetectorRef.current);
    }

    return () => {
      if (scrollBottomDetectorRef.current) {
        io.unobserve(scrollBottomDetectorRef.current);
      }
    };
  }, [open, projectInfo.project.name]);

  // load
  useEffect(() => {
    if (!open || historyLoadingState !== 'idle') {
      return;
    }

    (async () => {
      setHistoryLoadingState('load');
      const { count, results } = await loadHistories(page);
      setHistories(histories => histories.concat(results));

      if (page === 1) {
        setSelectedHistory(results[0]);
      }

      if (count / 10 >= page && count !== page * 10) {
        setPage(p => p + 1);
        setHistoryLoadingState('idle');
        return;
      }

      setHistoryLoadingState('done');
    })();
  }, [detectedCount]);

  const loadHistories = async (page: number) => {
    const exportHistories = await ExportService.getExportHistories({
      projectId: routeInfo.urlMatchInfo.projectId,
      params: { calTrainableOnly: true, page },
      isGuest: false,
      urlInfo: routeInfo.urlMatchInfo,
    });

    return exportHistories;
  };

  const handleClickCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={t('autoLabel.cal.button.createCustomAutoLabel')}
      close={{
        onClose: handleClickCancel,
        hasCloseButton: true,
      }}
      mainButton={{
        text: t('button.create'),
        onClick: () => createCustomAutoLabel(),
        disabled: !Boolean(selectedHistory),
        isLoading,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClickCancel,
      }}
    >
      <Box px={4} width={520}>
        <Box display="flex" flexDirection="column" mt={4} gap="8px">
          <Typography variant="body3">{t('autoLabel.cal.calName')}</Typography>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            size="l"
            variant="filled"
            autoFocus
          />
        </Box>
        <Box display="flex" flexDirection="column" mt={3} gap="8px">
          <Typography variant="body3">{t('projects.settings.exportHistory')}</Typography>
          <Box height="260px" maxHeight="260px" overflow="auto">
            <RadioButtons
              options={histories.map(history => ({
                label: (
                  <Box width="100%" display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography>{history.name}</Typography>
                      <Typography>{`#${history.seqNo}`}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" minWidth={0}>
                      <Box minWidth={0} flex={1} width={100}>
                        <Typography>
                          <TextEllipsis
                            text={`${formatCount(history.labelCount, 'label')} · ${
                              history.createdBy
                            }`}
                          />
                        </Typography>
                      </Box>
                      <Typography whiteSpace="nowrap">
                        {formatDateTime(history.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                ),
                value: history.id,
              }))}
              value={selectedHistory ? selectedHistory.id : ''}
              onChange={historyId => {
                const history = histories.find(h => h.id === historyId);
                if (!history) {
                  throw new Error('no history found');
                }

                if (selectedHistory && selectedHistory.id === history.id) {
                  setSelectedHistory(null);
                }

                setSelectedHistory(history);
              }}
            />

            <Box
              ref={scrollBottomDetectorRef}
              width="100%"
              height="60px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {historyLoadingState === 'load' && (
                <Icon name="loadingDots" color="primary" size="l" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="1px" themedBackgroundColor={['grey', 80]} mb={3} />
      <CircularProgressDialog isLoading={isLoading} />
    </Modal>
  );
};
