import React from 'react';

import { isUndefined } from 'lodash';

import { ClickedEventProps } from '../chartContainers/types';
import { ExtendedScaleBand } from '../interfaces/d3Types';
import { JsonObj } from '../userStats/types';
import { TooltipTemplate } from './TooltipTemplates';

/**
 * Tooltip for Any Bar Chart (not stacked)
 *  - objectClassStats
 *  - categoryCountStats
 */

const TOOLTIP_STYLE: React.CSSProperties = {
  textAnchor: 'initial',
  fontFamily: 'Inter',
  fontSize: '12px',
  padding: '2px',
};

/**
 * @param {string} chartType 'bar' | 'scrollBar'
 * @param {int} numStatus
 */
function adjustTooltipYOffset(
  chartType: 'bar' | 'scrollBar',
  chartName: 'objectClassStats' | 'imageCategoryStats',
) {
  const yOffset = {
    objectClassStats: { scrollBar: -117, bar: -105 },
    imageCategoryStats: { scrollBar: -140, bar: -122 },
  };
  return yOffset[chartName][chartType];
}

interface Props {
  left: number;
  top: number;
  chartType: 'bar' | 'scrollBar';
  chartName: 'objectClassStats' | 'imageCategoryStats'; // TODO: move out to types
  content: JsonObj;
  scales: [ExtendedScaleBand, d3.ScaleLinear<number, number, never>];
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
  filter: Record<string, string[] | []>;
  xKeyToDisplayName?: Record<string, string>;
  handleClickInspect?: ClickedEventProps;
}

const getTooltipBoxWidth = (maxTextLength: number): number => {
  /** Use text length as thresholds to adjust tooltip width */
  if (maxTextLength > 35) return 260;
  if (maxTextLength > 30) return 260;
  if (maxTextLength > 25) return 220;
  if (maxTextLength > 20) return 200;
  return 180;
};

export const Tooltip: React.FC<Props> = props => {
  const {
    left,
    top,
    content,
    scales,
    chartType,
    chartName,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    xKeyToDisplayName,
    handleClickInspect,
  } = props;
  const xScale = scales[0];
  const yScale = scales[1];
  const [, xValue] = content.xValue;
  const [, yValue] = content.yValue;

  // Quick fix to accommodate both scroll and regular bar
  // Two chart types have different margins
  const yOffset = adjustTooltipYOffset(chartType, chartName);
  const yPos = yScale(yValue) + yOffset;
  const xPos = xScale(xValue) as number;

  // center of tooltip rectangle aligns with bar
  // const getTooltipXPos = (x, width) => {
  //   return x - (width - xScale.bandwidth()) / 2;
  // };

  const height = chartName === 'imageCategoryStats' ? 100 : 80;

  // this is incredibly convoluted and need a fix
  // we're checking xValue length here to get tooltip width -> x position and in tooltip template to calculate actual width
  // TODO (ml) - calculate xPos, width at the same place OR pass down xDisplayName length
  const xDisplayName =
    xKeyToDisplayName && xKeyToDisplayName[xValue] ? xKeyToDisplayName[xValue] : xValue;
  const width = getTooltipBoxWidth(xDisplayName.length);
  /** Align center of tooltip rectangle with bar center */
  const xOffset = (width - xScale.bandwidth()) / 2;
  const xInBound = xPos - xOffset;

  return (
    <g transform={`translate(${left}, ${top})`} style={TOOLTIP_STYLE}>
      {!Number.isNaN(xInBound) &&
        !Number.isNaN(yPos) &&
        !Number.isNaN(width) &&
        !Number.isNaN(height) &&
        !isUndefined(content) &&
        TooltipTemplate({
          content,
          xPos: xInBound,
          yPos,
          handleClickInspect,
          handleTooltipMouseEnter,
          handleTooltipMouseLeave,
          // width,
          height,
          chartName,
          xKeyToDisplayName,
        })}
    </g>
  );
};

export default Tooltip;
