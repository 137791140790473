import { CamelizedProperty } from 'humps';
import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { ProjectData, ProjectTag } from '../../../types/projectTypes';
import { toSnakeCaseKeys } from '../../../utils/ServiceUtils';
import {
  ClusterLevel,
  ImageFilterExpandParams,
  ImageFilterSchema,
  ProjectionInParam,
  SuperClusters,
} from '../components/datasets/dataset/filter/types';
import { AnnotationType, AnnotationTypeCoordinate } from '../types/annotationTypes';
import { ITotalSchema } from '../types/querySchemaTypes';
import { KeysToCamelCase } from '../types/typeUtils';
import { buildDatasetQueryEndpoint } from './utils';

const embeddingSteps = ['embed', 'clustering', 'cluster_assign', 'done'] as const;
type EmbeddingStep = (typeof embeddingSteps)[number];

export type EmbeddingStatus = {
  stage: EmbeddingStep;
  state: 'pending' | 'in_progress' | 'finished';
  remainingTime?: number;
  progress: number;
};

export type DatasetResult = {
  id: string;
  description: string;
  name: string;
  imageCount?: number;
  sliceCount: number;
  thumbnailUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy?: string;
  embeddingStatus?: EmbeddingStatus;
  syncedLabelProjectIds?: string[];
};

export type GetDatasetParams = {
  page?: number;
  size?: number;
  sort_order?: string;
  sort_by?: string;
  name?: string;
  name_contains?: string;
  name_icontains?: string;
  expand?: string[];
  fromPublicDatasets: boolean;
  id_in?: string[];
};

const createDataset: AfterLoginCallback<
  any,
  { name: string; description: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/curate/dataset-core/datasets/',
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const updateDataset: AfterLoginCallback<
  any,
  { name: string; description: string; datasetId: string }
> = async params => {
  if (!params.data) return;
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/curate/dataset-core/datasets/${params.data.datasetId}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...{ ...params, data: { name: params.data.name, description: params.data.description } },
  });

  return data;
};

const getDatasetList: AfterLoginCallback<
  { count: number; results: DatasetResult[] },
  GetDatasetParams
> = async params => {
  const { fromPublicDatasets, ...queryParam } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(`?${qs.stringify({ ...queryParam } || {})}`, fromPublicDatasets),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const deleteDataset: AfterLoginCallback<any, { datasetId: string }> = async params => {
  if (!params.data) return;
  try {
    const response = await apiCallAfterLogin({
      method: 'delete',
      url: `/curate/dataset-core/datasets/${params.data.datasetId}`,
      hasPublicApi: false,
      isCurateUrl: true,
      ...params,
    });
    if (response.status !== 204) throw new Error('Delete job failed');
    return response.data;
  } catch (err) {
    throw new Error('Delete job failed');
  }
};

const getDataset: AfterLoginCallback<
  DatasetResult,
  { datasetId: string; fromPublicDatasets: boolean; expand?: string[] }
> = async params => {
  if (!params.data) return;
  const { datasetId, fromPublicDatasets, ...queryParam } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}?${qs.stringify(
        {
          expand: queryParam.expand,
        },
        { arrayFormat: 'brackets' },
      )}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export type DatasetBriefData = {
  id: string;
  source: string;
  thumbnailUrl: string;
  smallThumbnailUrl?: string;
  isSynthetic: boolean;
  metadata: {
    width: number;
    height: number;
  };
  leafCluster: {
    id: string;
    size: number;
  };
  superClusters?: SuperClusters;
};
export type DatasetBriefDataResult = {
  count: number;
  results: DatasetBriefData[];
  last: string[];
  datasetTotalCount?: number;
  syntheticTotalCount?: number;
  clipEmbedTotalCount?: number;
};

const getDatasetBriefDataList: AfterLoginCallback<
  DatasetBriefDataResult,
  {
    datasetId: string;
    fromPublicDatasets: boolean;
    searchAfter?: string[];
    sliceName?: string;
    expand?: string[];
    query?: string;
    size?: number;
    idIn?: string[];
    embeddingReady?: boolean;
    appliedFilters?: ImageFilterSchema;
    sortBy?: string[];
    sortOrder?: string[];
    imageSimilarTo?: string;
    clipSimilarTo?: number[];
    clipEmbedExists?: boolean;
  }
> = async params => {
  if (!params.data) return;
  const { datasetId, fromPublicDatasets, ...body } = params.data;
  const { projection_in, cluster_id_in, cluster_level, leaf_cluster_score } =
    params.data?.appliedFilters ?? {};

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${datasetId}/images/_search`, fromPublicDatasets),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: {
      search_after: body.searchAfter,
      slice: params.data.sliceName,
      expand: params.data.expand,
      embeddingReady: params.data.embeddingReady,
      size: params.data.size || 0,
      cluster_level,
      leaf_cluster_score,
      ...(params.data.query && params.data.query !== '' && { query: params.data.query }),
      ...(params.data.idIn && { id_in: params.data.idIn }),
      ...(projection_in && { projection_in }),
      ...(cluster_level && cluster_id_in && cluster_id_in.length > 0 && { cluster_id_in }),
      ...(params.data.sortBy && { sort_by: params.data.sortBy }),
      ...(params.data.sortOrder && { sort_order: params.data.sortOrder }),
      ...(params.data.imageSimilarTo && {
        image_similar_to: params.data.imageSimilarTo,
      }),
      ...(params.data.clipSimilarTo && {
        clip_similar_to: params.data.clipSimilarTo,
      }),
      ...(params.data.clipEmbedExists && {
        clip_embed_exists: params.data.clipEmbedExists,
      }),
    },
  });

  return data;
};

const getImageDataByImageKey: AfterLoginCallback<
  { count: number; results: CamelizedProperty<CurateImageData>[] },
  { datasetId: string; imageKey: string; fromPublicDatasets: boolean; expand?: string[] }
> = async params => {
  const { datasetId, imageKey, fromPublicDatasets, expand } = params.data;

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${datasetId}/images/_search`, fromPublicDatasets),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: {
      key: imageKey,
      expand,
    },
  });

  return data;
};

export type CurateAnnotation = {
  annotation_class: string;
  annotation_type: AnnotationType;
  annotation_value: AnnotationTypeCoordinate<AnnotationType>;
  created_at: Date;
  created_by: string;
  dataset_id: string;
  id: string;
  image_id: string;
  metadata: Record<string, any>;
  updated_at: Date;
  updated_by: string;
  roi?: { x: number; y: number; width: number; height: number };
  _object_type: string;
};

export type CurateAnnotationCamel = KeysToCamelCase<CurateAnnotation>;

export type CurateImageData = {
  created_at: Date;
  created_by: string;
  updated_at: Date;
  updated_by: string;
  id: string;
  dataset_id: string;
  key: string;
  source: string;
  metadata: Record<string, any>;
  is_synthetic: boolean;
  annotations?: CurateAnnotation[];
  image_url: string;
  synced_label_project_ids?: string[];
};

const getImageData: AfterLoginCallback<
  CurateImageData,
  { datasetId: string; fromPublicDatasets: boolean; imageId: string; expand?: string[] }
> = async params => {
  if (!params.data) return;

  const { datasetId, fromPublicDatasets, imageId, ...otherParamData } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/images/${imageId}/?${qs.stringify(toSnakeCaseKeys(otherParamData || {}), {
        arrayFormat: 'brackets',
      })}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });

  return data;
};

export type GetSlicesParams = {
  page?: number;
  size?: number;
  sort_order?: string;
  sort_by?: string;
  name?: string;
  name_contains?: string;
  datasetId: string;
  fromPublicDatasets: boolean;
  expand?: string[];
};

const getSliceList: AfterLoginCallback<
  { count: number; results: DatasetResult[] },
  GetSlicesParams
> = async params => {
  if (!params.data) return;
  const {
    page,
    size,
    sort_order,
    sort_by,
    name,
    name_contains,
    datasetId,
    expand,
    fromPublicDatasets,
  } = params.data;
  const newParams = {
    page,
    size,
    sort_order,
    sort_by,
    name,
    name_contains,
    expand,
  };
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/slices?${qs.stringify(toSnakeCaseKeys(newParams))}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const getSlice: AfterLoginCallback<
  DatasetResult,
  { datasetId: string; fromPublicDatasets: boolean; sliceId: string; expand?: string[] }
> = async params => {
  if (!params.data) return;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${params.data.datasetId}/slices/${params.data.sliceId}?${qs.stringify(
        {
          expand: params.data.expand,
        },
        { arrayFormat: 'brackets' },
      )}`,
      params.data.fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export type SliceResult = {
  createdAt: Date;
  createdBy: string;
  datasetId: string;
  description: string;
  id: string;
  name: string;
  updatedAt: Date;
  updatedBy: string;
};
const createSlice: AfterLoginCallback<
  {
    createdAt: Date;
    createdBy: string;
    datasetId: string;
    description: string;
    id: string;
    name: string;
    updatedAt: Date;
    updatedBy: string;
  },
  { name: string; description: string; datasetId: string; projectionIn?: ProjectionInParam }
> = async params => {
  if (!params.data) return;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/curate/dataset-core/datasets/${params.data.datasetId}/slices`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...{
      ...params,
      data: {
        name: params.data.name,
        description: params.data.description,
      },
    },
  });

  return data;
};

const updateSlice: AfterLoginCallback<
  any,
  { description: string; datasetId: string; sliceId: string; projectionIn?: ProjectionInParam }
> = async params => {
  if (!params.data) return;
  const requestBody = {
    description: params.data.description,
  };
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/curate/dataset-core/datasets/${params.data.datasetId}/slices/${params.data.sliceId}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...{ ...params, data: requestBody },
  });

  return data;
};

const deleteSlice: AfterLoginCallback<
  any,
  { datasetId: string; sliceId: string }
> = async params => {
  if (!params.data) return;
  try {
    const response = await apiCallAfterLogin({
      method: 'delete',
      url: `/curate/dataset-core/datasets/${params.data.datasetId}/slices/${params.data.sliceId}`,
      hasPublicApi: false,
      isCurateUrl: true,
      ...params,
    });
    if (response.status !== 204) throw new Error('Delete job failed');
    return response.data;
  } catch (err) {
    throw new Error('Delete job failed');
  }
};

// const deleteData: AfterLoginCallback<any, { datasetId: string; imageId: string; }> = async params => {
//   const { data } = await apiCallAfterLogin({
//     method: 'delete',
//     url: `/curate/datasets/${params.data.datasetId}`,
//     hasPublicApi: false,
//     isCurateUrl: true,
//     ...params,
//   });

//   return data;
// };

const getDatasetSchemas: AfterLoginCallback<
  ITotalSchema,
  { datasetId: string; fromPublicDatasets: boolean }
> = async params => {
  if (!params.data) return;
  const { datasetId, fromPublicDatasets } = params.data;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(`${datasetId}/query-schema/images-keys`, fromPublicDatasets),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });

  return data;
};

const getDatasetSchemasValues: AfterLoginCallback<
  any,
  { datasetId: string; fromPublicDatasets: boolean; queryKey: string }
> = async params => {
  if (!params.data) return;
  const { datasetId, fromPublicDatasets, queryKey } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/query-schema/images-values?key=${queryKey}&limit=250`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const getDatasetSearchFieldMapping: AfterLoginCallback<
  any,
  { datasetId: string; fromPublicDatasets: boolean }
> = async params => {
  if (!params.data) return;
  const { datasetId, fromPublicDatasets } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/search-field-mappings/aliases`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });

  return data;
};

const getProjects: AfterLoginCallback<
  { count: number; results: ProjectData[] },
  { page: number; nameIcontains: string; isCurateSynced?: boolean; curateDatasetIdIn?: string[] }
> = async params => {
  if (!params.data) return;
  const { page, nameIcontains, curateDatasetIdIn, isCurateSynced } = params.data;

  const queryParam = {
    page,
    data_type: 'image',
    ...(nameIcontains.length > 0 && { name_icontains: nameIcontains }),
    curate_dataset_id_in: curateDatasetIdIn,
    is_curate_synced: isCurateSynced,
  };

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/projects/?${qs.stringify({ ...queryParam } || {}, { arrayFormat: 'brackets' })}`,

    hasPublicApi: false,
    ...params,
  });

  return data;
};

const getProjectTags: AfterLoginCallback<ProjectTag[], { projectId: string }> = async params => {
  if (!params.data) return;
  const { projectId } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/tags/`,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const getProject: AfterLoginCallback<any, { projectId: string }> = async params => {
  const { projectId } = params.data;
  const res = await apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/`,
    hasPublicApi: true,
    ...params,
  });

  return res.data;
};

interface ISendToLabelParams {
  correlationId: string;
  datasetId: string;
  datasetName: string;
  sliceId?: string;
  sliceName?: string;
  tags?: string[];
  query?: string;
  clusterLevel?: ClusterLevel;
  clusterIdIn?: string[];
  withAnnotations?: boolean;
  overrideAnnotations?: boolean;
}

const postSendToLabel: AfterLoginCallback<
  any,
  {
    projectId: string;
    params: ISendToLabelParams;
  }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/commands/import_from_curate/',
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const getSendToLabelProgress: AfterLoginCallback<any, { commandId: string }> = async params => {
  const { commandId } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/commands/${commandId}/progress`,
    hasPublicApi: false,
    ...params,
  });

  return data;
};

const getSearchDatasetName: AfterLoginCallback<
  { count: number; results: any },
  { name: string }
> = async params => {
  if (!params.data) return;
  const { name } = params.data;
  const newParams = {
    name,
  };
  const { data } = await apiCallAfterLogin({
    method: 'HEAD',
    url: `/curate/dataset-query/validate/dataset?${qs.stringify(toSnakeCaseKeys(newParams))}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

const getSearchSliceName: AfterLoginCallback<
  { count: number; results: any },
  { name: string; datasetId: string }
> = async params => {
  if (!params.data) return;
  const { name, datasetId } = params.data;
  const newParams = {
    name,
  };
  const { data } = await apiCallAfterLogin({
    method: 'HEAD',
    url: `/curate/dataset-query/validate/datasets/${datasetId}/slice?${qs.stringify(
      toSnakeCaseKeys(newParams),
    )}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export type ImageFilterSchemaParams = {
  datasetId: string;
  sliceName?: string;
  expand?: ImageFilterExpandParams;
  fromPublicDatasets: boolean;
};
const getImageFilterSchema: AfterLoginCallback<
  { image_filters: ImageFilterSchema },
  ImageFilterSchemaParams
> = async params => {
  if (!params.data) return;
  const { datasetId, sliceName, expand, fromPublicDatasets } = params.data;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/image-filter-schema?${qs.stringify(
        {
          ...(sliceName && { slice: sliceName }),
          expand,
        },
        { arrayFormat: 'brackets' },
      )}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });
  return data;
};

export function useCurateDatasetService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    createDataset: afterLoginFetcher(createDataset),
    updateDataset: afterLoginFetcher(updateDataset),
    getDatasetList: afterLoginFetcher(getDatasetList),
    deleteDataset: afterLoginFetcher(deleteDataset),
    getDataset: afterLoginFetcher(getDataset),
    getDatasetBriefDataList: afterLoginFetcher(getDatasetBriefDataList),
    getImageData: afterLoginFetcher(getImageData),
    getSliceList: afterLoginFetcher(getSliceList),
    createSlice: afterLoginFetcher(createSlice),
    updateSlice: afterLoginFetcher(updateSlice),
    getSlice: afterLoginFetcher(getSlice),
    deleteSlice: afterLoginFetcher(deleteSlice),
    getDatasetSchemas: afterLoginFetcher(getDatasetSchemas),
    getDatasetSchemasValues: afterLoginFetcher(getDatasetSchemasValues),
    getDatasetSearchFieldMapping: afterLoginFetcher(getDatasetSearchFieldMapping),
    getProjects: afterLoginFetcher(getProjects),
    getProject: afterLoginFetcher(getProject),
    getProjectTags: afterLoginFetcher(getProjectTags),
    postSendToLabel: afterLoginFetcher(postSendToLabel),
    getSendToLabelProgress: afterLoginFetcher(getSendToLabelProgress),
    getSearchDatasetName: afterLoginFetcher(getSearchDatasetName),
    getSearchSliceName: afterLoginFetcher(getSearchSliceName),
    getImageFilterSchema: afterLoginFetcher(getImageFilterSchema),
    getImageDataByImageKey: afterLoginFetcher(getImageDataByImageKey),
  };
}
