import { Box } from '@superb-ai/ui';

import CommandListItem from '../../../../apps/Curate/elements/Command/CommandListItem';
import { Command, CommandType } from '../../../../apps/Curate/types/commandTypes';

interface Props {
  items: Command<CommandType>[];
}

export default function CurateCommandProgressList({ items }: Props): JSX.Element {
  return (
    <Box>
      {items.map(command => (
        <CommandListItem key={command.id} command={command} />
      ))}
    </Box>
  );
}
