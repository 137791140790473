import { useParams } from 'react-router';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  DeleteChartParams,
  GetDashboardParams,
  PatchDashboardParams,
  useCustomAnalyticsService,
} from '../../../../services/CustomAnalyticsService';
import { validateGroupByOptions } from './chartSpecs';
import { GroupByField } from './fields';
import { ChartStorage, DashboardSetting, DataSpec } from './type';

export interface AnalyticsQueryParams {
  dataSpec: DataSpec;
  groupBy?: GroupByField;
  filters?: Record<string, string | string[]>;
}

export const useGetChartDataQuery = ({ dataSpec, groupBy, filters }: AnalyticsQueryParams) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { getChartData } = useCustomAnalyticsService();
  const queryFn = () =>
    getChartData({
      projectId,
      data: dataSpec,
      groupBy,
      ...(filters && { apiParams: filters }),
    });

  return useQuery({
    queryKey: ['get-customized-chart-data', accountName, projectId, dataSpec, groupBy, filters],
    queryFn: queryFn,
    enabled: validateGroupByOptions(dataSpec, groupBy),
    retry: 3,
  });
};

export const useAddChartMutation = ({
  dashboardId,
  chartData,
}: Omit<PatchDashboardParams, 'tenant' | 'projectId'>) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { addChart } = useCustomAnalyticsService();
  const client = useQueryClient();

  return useMutation({
    mutationFn: () => addChart({ tenant: accountName, projectId, dashboardId, chartData }),
    onSuccess: () => {
      // TODO: invalidate get dasbhoard query
      client.invalidateQueries(['get-dashboard']);
    },
    onError: error => {
      // error action
    },
    retry: 2,
  });
};

export const useGetDashboardQuery = ({
  dashboardId,
}: Omit<GetDashboardParams, 'tenant' | 'projectId'>) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { getDashboard } = useCustomAnalyticsService();

  return useQuery({
    queryKey: ['get-dashboard', accountName, projectId, dashboardId],
    queryFn: () => getDashboard({ tenant: accountName, projectId, dashboardId }),
    // refetchOnWindowFocus: false,
    retry: 2,
  });
};

export const useDeleteChartMutation = ({
  dashboardId,
  chartId,
}: Omit<DeleteChartParams, 'tenant' | 'projectId'>) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { deleteChart } = useCustomAnalyticsService();
  const client = useQueryClient();

  return useMutation({
    mutationFn: () => deleteChart({ tenant: accountName, projectId, dashboardId, chartId }),
    onSuccess: () => {
      // success action
      client.invalidateQueries(['get-dashboard']);
    },
    onError: error => {},
    retry: 2,
  });
};

export const useUpdateChartMutation = ({ dashboardId }: { dashboardId: string }) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { editChart } = useCustomAnalyticsService();
  const client = useQueryClient();

  return useMutation({
    mutationFn: ({ chartData, chartId }: { chartData: ChartStorage; chartId: string }) =>
      editChart({ tenant: accountName, projectId, dashboardId, chartData, chartId }),
    onSuccess: () => {
      // success action
      client.invalidateQueries(['get-dashboard']);
    },
    onError: error => {},
    retry: 2,
  });
};

export const useEditDashboardMutation = ({ dashboardId }: { dashboardId: string }) => {
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { editDashboard } = useCustomAnalyticsService();
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ viewType }: DashboardSetting) =>
      editDashboard({ tenant: accountName, projectId, dashboardId, viewType }),
    onSuccess: () => {
      client.invalidateQueries(['get-dashboard']);
    },
    retry: 2,
  });
};
