import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { MultiValue, SingleValue } from 'react-select';

import { WarningOutline } from '@superb-ai/icons';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';
import { v4 as uuidv4 } from 'uuid';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { AlertBox } from '../../../../../../../components/elements/AlertBox';
import { hasSufficientLabelVolume } from '../../../../../../../queries/meteringLogic';
import { useMetering } from '../../../../../../../queries/useMeteringQuery';
import { Option } from '../../../../../../../types/selectTypes';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import SendToLabelCompleteModal from '../SendToLabelCompleteModal';
import SelectProject from './SelectProject';
import SelectProjectTags from './SelectProjectTags';
export default function SendToLabelModal({
  sendToLabelIsOpen,
  setSendToLabelIsOpen,
}: {
  sendToLabelIsOpen: boolean;
  setSendToLabelIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName } = useParams<{ accountName: string }>();
  const { sliceInfo } = useSliceContext();
  const { datasetInfo } = useDatasetContext();
  const { totalCount: totalImageCount } = useImageScopeContext();
  const totalCount = totalImageCount || 0;
  const { selectedData, selectedAllData, setSelectedAllData } = useActionContext();
  const { postSendToLabel } = useCurateDatasetService();
  const { t } = useTranslation();

  const { queryString } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sendToLabelCompleteIsOpen, setSendToLabelCompleteIsOpen] = useState<boolean>(false);
  const [selectedProjectOption, setSelectedProjectOption] = useState<SingleValue<Option>>();
  const [selectedProjectTagsOption, setSelectedProjectTagsOption] = useState<MultiValue<Option>>();
  const selectedDataCount = selectedAllData ? totalCount : selectedData.length;

  const metering = useMetering('label:data-volume');
  const isVolumeSufficient = hasSufficientLabelVolume(metering, {
    minimum: selectedDataCount,
  });

  useEffect(() => {
    if (selectedDataCount === 0) {
      setSelectedAllData(true);
    }
  }, []);

  const handleSendToLabel = useCallback(async () => {
    if (datasetInfo && selectedProjectOption) {
      const id = uuidv4();
      setIsLoading(true);
      await postSendToLabel({
        projectId: selectedProjectOption.value,
        params: {
          correlationId: id,
          datasetId: datasetInfo.id,
          datasetName: datasetInfo.name,
          tags: selectedProjectTagsOption?.map(option => option.label) || [],
          query: queryString,
          clusterLevel,
          clusterIdIn: appliedFilters?.cluster_id_in,
          sliceId: sliceInfo?.id,
          sliceName: sliceInfo?.name,
        },
      });
      setIsLoading(false);
      setSelectedAllData(false);
    }
  }, [
    selectedProjectOption,
    selectedProjectTagsOption,
    datasetInfo,
    queryString,
    clusterLevel,
    appliedFilters,
    sliceInfo,
    postSendToLabel,
    setSelectedAllData,
  ]);

  const trackSendToLabel = useCallback(() => {
    analyticsTracker.sendToLabelRequested({
      accountId: accountName,
      datasetId: datasetInfo?.id ?? '',
      sliceId: sliceInfo?.id ?? 'NOT_SELECTED',
      dataCount: selectedDataCount,
      dataType: 'image',
      syncType: 'send-to-label',
    });
  }, [accountName, datasetInfo?.id, selectedDataCount, sliceInfo?.id]);

  return (
    <>
      <Modal
        open={sendToLabelIsOpen}
        close={{
          onClose: () => {
            setSendToLabelIsOpen(false);
          },
          canClickOutside: true,
          canCloseWithExit: true,
          hasCloseButton: true,
        }}
        title={t('curate.dialogs.sendToLabel')}
        mainButton={{
          text: t('button.send'),
          onClick: () => {
            handleSendToLabel();
            setSendToLabelIsOpen(false);
            setSendToLabelCompleteIsOpen(true);
            trackSendToLabel();
          },
          color: 'primary',
          isLoading,
          disabled: !selectedProjectOption,
        }}
        subButton={{
          text: t('button.cancel'),
          onClick: () => {
            setSendToLabelIsOpen(false);
          },
        }}
      >
        <Box style={{ width: '520px' }} px={4} py={2.5}>
          <Box backgroundColor="primary-100" color="primary" p={2} display="flex" gap={1}>
            <Icon icon={WarningOutline} size={20} />
            <Typography variant="m-regular">
              {t('curate.slices.sendToLabel.warningMessage')}
            </Typography>
          </Box>
          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="m-regular">
              {t('curate.slices.sendToLabel.selectProject')}
            </Typography>
            <SelectProject
              isCurateSynced={false}
              selectedProjectOption={selectedProjectOption}
              setSelectedProjectOption={setSelectedProjectOption}
            />
          </Box>
          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <Typography variant="m-regular">
              {t('curate.slices.sendToLabel.applyTags')}
              <Typography color="gray-300"> ({t('forms.optional')})</Typography>
            </Typography>
            <SelectProjectTags
              projectId={selectedProjectOption?.value}
              selectedProjectTagsOption={selectedProjectTagsOption}
              setSelectedProjectTagsOption={setSelectedProjectTagsOption}
            />
          </Box>
          {!isVolumeSufficient && (
            <Box mt={2} style={{ width: '464px' }}>
              <AlertBox type="danger">
                <span>
                  {t('curate.slices.sendToLabel.labelVolumeNotSufficient', {
                    count: metering.leftQuantity,
                  })}
                </span>
              </AlertBox>
            </Box>
          )}
        </Box>
      </Modal>

      <SendToLabelCompleteModal
        sendToLabelCompleteIsOpen={sendToLabelCompleteIsOpen}
        setSendToLabelCompleteIsOpen={setSendToLabelCompleteIsOpen}
        selectedProjectName={selectedProjectOption?.label || ''}
        selectedProjectId={selectedProjectOption?.value || ''}
      />
    </>
  );
}
