import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, getColor, Typography } from '@superb-ai/ui';

interface Props {
  setParams: (params: Record<string, any> | null) => void;
}

const Textarea = styled.textarea`
  padding: 8px 12px;
  background-color: ${getColor('gray-100')};
  border: none;
  resize: none;
  height: 156px;
  border-radius: 2px;

  &:focus {
    box-shadow: inset 0 0 0 1.5px ${getColor('primary')};
  }
`;

export default function EditAuthenticationGoogleCloud({ setParams }: Props) {
  const { t } = useTranslation();
  const [serviceAccountJSON, setServiceAccountJSON] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (serviceAccountJSON) {
      try {
        const json = JSON.parse(serviceAccountJSON);
        setParams(json);
        setErrorMessage('');
      } catch (e) {
        setErrorMessage(t('integrations.googleCloud.invalidJSONFormat'));
      }
    } else {
      setParams(null);
    }
  }, [serviceAccountJSON]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="m-strong">{t('integrations.googleCloud.serviceAccountJSON')}</Typography>
      <Typography variant="m-regular">
        {t('integrations.add.googleCloud.serviceAccountJSONDescription')}
      </Typography>
      <Textarea value={serviceAccountJSON} onChange={e => setServiceAccountJSON(e.target.value)} />
      {errorMessage && (
        <Typography variant="s-regular" color="primary">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
