import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ArrowRight } from '@superb-ai/icons';
import { Box, Button, Icon, LinkTypography, Typography } from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import { getUserManualUrl } from '../../../../../../consts/DocsLink';
import { DatasetPool } from '../../../../types/routeTypes';
import emptyImage from './img_empty state_auto-curate_history.png';

export default function CurationHistoryEmptyPage() {
  const { accountName, datasetId, datasetPool } = useParams<{
    accountName: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const { CURATE_AUTO_CURATE_MANUAL } = getUserManualUrl(i18n.language);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={7}>
      <Image
        src={emptyImage}
        alt="Illustration showing a robot holding the hand of a person"
        width={600}
      />
      <Box mt={0.5}>
        <Typography variant="h2" style={{ fontSize: '20px' }}>
          <>{t('curate.autoCurate.emptyAutoCurationHistory')}</>
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={0.5}
      >
        <Typography variant="m-regular" style={{ color: '#B3B3B3' }}>
          <Trans t={t} i18nKey={'curate.autoCurate.autoHistoryGuide'} />
        </Typography>
        <Typography variant="m-regular" color="gray-300">
          (
          <Link href={CURATE_AUTO_CURATE_MANUAL} target="_blank" rel="noopener noreferrer">
            <LinkTypography variant="m-regular">
              {t('curate.autoCurate.learnMoreAutoCurate')}
            </LinkTypography>
          </Link>
          )
        </Typography>
      </Box>
      <Box display="flex" mt={2}>
        <Box ml={1}>
          <Button
            variant="strong-fill"
            color="primary"
            size="m"
            onClick={() =>
              history.push(`/${accountName}/curate/${datasetPool}/${datasetId}/explore`)
            }
          >
            <Icon icon={ArrowRight} />
            {t('curate.button.exploreData')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
