import { Trans, useTranslation } from 'react-i18next';

import { InfoFilled } from '@superb-ai/icons';
import { Card, Input, Slider } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

import {
  CURATE_WHAT_TO_LABEL,
  CurationType,
  EmbeddingType,
  FIND_EDGE_CASES,
  IMAGE,
  OBJECT,
  SPLIT_TRAIN_VAL,
} from '../../../../../../../../consts/AutoCurateConst';
import { formatCount } from '../../../../../../../../utils/numberFormat';

export default function NumberOfImageSteps({
  curateImageNumber,
  handleCurateImageNumber,
  samplingMethod,
  unlabeledDataCount,
  labeledDataCount,
  handleCurateImagePercent,
  curateImagePercent,
  embeddingType,
}: {
  curateImageNumber: number;
  handleCurateImageNumber: (value: number) => void;
  unlabeledDataCount: number;
  labeledDataCount: number;
  samplingMethod: CurationType;
  handleCurateImagePercent: (value: number) => void;
  curateImagePercent: number;
  embeddingType: EmbeddingType;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{
          height: '32px',
          marginBottom: samplingMethod === SPLIT_TRAIN_VAL ? '20px' : '0px',
        }}
      >
        {samplingMethod !== SPLIT_TRAIN_VAL ? (
          <Box
            style={{
              width: '380px',
              marginRight: '10px',
            }}
          >
            <Box
              style={{
                width: '370px',
                paddingLeft: '10px',
              }}
            >
              <Slider
                min={0}
                max={embeddingType === 'IMAGE' ? unlabeledDataCount : labeledDataCount}
                value={curateImageNumber}
                variant="bigThumb"
                onChange={value => {
                  handleCurateImageNumber(value);
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              width: '180px',
              marginRight: '10px',
            }}
          >
            <Box style={{ width: '179px' }} display="flex">
              <Box mr={1} display="flex" alignItems="center" style={{ width: '111px' }}>
                <Typography variant="m-regular">
                  {t('curate.autoCurate.numberOfImageSteps.validationSetRatio')}
                </Typography>
              </Box>
              <Input
                value={curateImagePercent}
                onChange={e => {
                  handleCurateImagePercent(+e.target.value);
                }}
                style={{ maxHeight: '24px', width: '24px' }}
                size="s"
              />
              <Box display="flex" alignItems="center" ml={0.5}>
                <Typography variant="m-regular">%</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          {samplingMethod === SPLIT_TRAIN_VAL ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '270px', maxHeight: '24px', marginRight: '4px' }}
            >
              <Typography variant="m-regular">
                {t('curate.autoCurate.numberOfImageSteps.numberOfValidationSetData')}
              </Typography>
              <Input
                value={curateImageNumber}
                onChange={e => {
                  handleCurateImageNumber(+e.target.value);
                }}
                style={{ maxHeight: '24px', width: '70px' }}
                size="s"
              />
            </Box>
          ) : (
            <Box style={{ width: '100px', maxHeight: '24px', marginRight: '8px' }}>
              <Input
                value={curateImageNumber}
                onChange={e => {
                  handleCurateImageNumber(+e.target.value);
                }}
                style={{ maxHeight: '24px' }}
                size="s"
              />
            </Box>
          )}
          <Box>
            <Typography variant="m-medium">
              /{' '}
              {`${formatCount(
                samplingMethod === SPLIT_TRAIN_VAL || embeddingType === OBJECT
                  ? labeledDataCount
                  : unlabeledDataCount,
              )}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      {samplingMethod === SPLIT_TRAIN_VAL && (
        <Box>
          <Card
            style={{
              background: '#F8F8FF',
              padding: '12px',
              boxShadow: 'none',
              display: 'flex',
              margin: '8px 0',
            }}
          >
            <Box display="flex">
              <Box mr={0.5} style={{ paddingTop: '1px' }}>
                <Icon icon={InfoFilled} color="secondary-400" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="m-regular" color={'secondary-400'}>
                  <Trans t={t} i18nKey={'curate.autoCurate.splitValidationSetRatio'} />
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
      {((samplingMethod === CURATE_WHAT_TO_LABEL && labeledDataCount > 0) ||
        (samplingMethod !== CURATE_WHAT_TO_LABEL && unlabeledDataCount > 0)) && (
        <Box>
          {(samplingMethod === CURATE_WHAT_TO_LABEL && embeddingType === IMAGE) ||
          (samplingMethod === FIND_EDGE_CASES && embeddingType === IMAGE) ? (
            <>
              {labeledDataCount > 0 && (
                <Card
                  style={{
                    background: '#FFF8F8',
                    padding: '12px',
                    boxShadow: 'none',
                    display: 'flex',
                    margin: '8px 0',
                  }}
                >
                  <Box display="flex">
                    <Box mr={0.5} style={{ paddingTop: '1px' }}>
                      <Icon icon={InfoFilled} color="primary-400" />
                    </Box>
                    <Box display="flex">
                      <Typography variant="m-regular" color={'primary-400'}>
                        <Trans t={t} i18nKey={'curate.autoCurate.labeledDataInfo'}>
                          <>This dataset includes </>
                          <Typography variant="m-strong" color={'primary-400'}>
                            {{ labeled: labeledDataCount }}
                          </Typography>
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              )}
            </>
          ) : (
            <>
              {unlabeledDataCount > 0 && (
                <Card
                  style={{
                    background: '#FFF8F8',
                    padding: '12px',
                    boxShadow: 'none',
                    display: 'flex',
                    margin: '8px 0',
                  }}
                >
                  <Box display="flex">
                    <Box mr={0.5} style={{ paddingTop: '1px' }}>
                      <Icon icon={InfoFilled} color="primary-400" />
                    </Box>
                    <Box display="flex">
                      <Typography variant="m-regular" color={'primary-400'}>
                        <Trans t={t} i18nKey={'curate.autoCurate.unlabeledDataInfo'}>
                          <>This dataset includes </>
                          <Typography variant="m-strong" color={'primary-400'}>
                            {{ unlabeled: unlabeledDataCount }}
                          </Typography>
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
