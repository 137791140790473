import { useAuthInfo } from '../../../../contexts/AuthContext';
import { AppRouteLayoutProps, AppRoutes } from '../../../../menu';
import { ProjectAutoLabelIntroMenuItem } from './intro/MenuItem';

export default function Layout({ menuItem }: AppRouteLayoutProps) {
  const authInfo = useAuthInfo();

  const items =
    authInfo.tier === 'FREE' ? [ProjectAutoLabelIntroMenuItem] : menuItem.children ?? [];

  return <AppRoutes items={items} />;
}
