import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Button, Icon, IconButton, Tooltip, Typography } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useCalAvailabilityInfo } from '../../../../../contexts/CalAvailabilityContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ExportService from '../../../../../services/ExportService';
import WorkappUnion from '../../../../../union/WorkappUnion';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import SearchBar from '../../../../elements/SearchBar';
import { getAppliedEnginesCount } from '../settings/helper';
import { CreateCustomAutoLabelModal } from './CreateCustomAutoLabelModal';
import CustomAutoLabelList from './CustomAutoLabelList';

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelInterface = projectInfo.project.labelInterface;
  const appliedEnginesCount = getAppliedEnginesCount(labelInterface);

  const calInfo = useCalAvailabilityInfo();
  const { canUseCustomAutoLabel, requiredLabelCountForCreatingCAL } = calInfo;

  const [isLoading, setIsLoading] = useState(true);
  const [historiesOfCreatingCALCount, setHistoriesOfCreatingCALCount] = useState<null | number>(
    null,
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [isHeaderDisable, setIsHeaderDisable] = useState(false);
  const [isOpenCreateCustomAutoLabelModal, setIsOpenCreateCustomAutoLabelModal] = useState(false);

  const loadHistories = async () => {
    const exportHistories = await ExportService.getExportHistories({
      projectId: routeInfo.urlMatchInfo.projectId,
      params: { calTrainableOnly: true },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    setHistoriesOfCreatingCALCount(exportHistories.count);
  };

  useEffect(() => {
    (async () => {
      await loadHistories();
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  const handleClickCreateCustomAutoLabel = () => {
    if (WorkappUnion.isImageDefault(projectInfo.project.workapp)) {
      history.push(
        `/${routeInfo.urlMatchInfo.accountName}/project/${routeInfo.urlMatchInfo.projectId}/export/history`,
      );
      return;
    }

    setIsOpenCreateCustomAutoLabelModal(true);
  };

  if (isLoading) {
    return <CircularProgressDialog isLoading />;
  }

  return (
    <>
      {historiesOfCreatingCALCount === 0 && !isHeaderDisable && (
        <>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            themedBackgroundColor={['primary', 100]}
            themedColor="primary"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="40px"
            px={4}
            mb={1.5}
          >
            <Box
              display="flex"
              alignItems="center"
              ml={0.5}
              justifyContent="space-between"
              gap="4px"
            >
              <Icon name="warningOutline" size="14px" />
              <Typography variant="headline6">
                {t('autoLabel.cal.labelExportRequired', {
                  count: requiredLabelCountForCreatingCAL,
                })}
              </Typography>
            </Box>
            <IconButton
              icon="clear"
              size="s"
              onClick={() => {
                setIsHeaderDisable(true);
              }}
            />
          </Box>
          <Box mb={4} />
        </>
      )}

      <Box width="auto" mx="auto" display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2.5}>
          <Box width="300px">
            <SearchBar
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={t('autoLabel.cal.button.searchCAL')}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="8px">
            {!canUseCustomAutoLabel ? null : historiesOfCreatingCALCount === 0 ? (
              <Tooltip
                placement="bottom-start"
                anchorEl={
                  <Box>
                    <Button
                      size="l"
                      IconAdornment={<Icon name="plus" />}
                      iconPosition="left"
                      disabled
                    >
                      {t('autoLabel.cal.button.createCustomAutoLabel')}
                    </Button>
                  </Box>
                }
              >
                {t('autoLabel.cal.labelExportRequired', {
                  count: requiredLabelCountForCreatingCAL,
                })}
              </Tooltip>
            ) : (
              <Box>
                <Button
                  size="l"
                  IconAdornment={<Icon name="plus" />}
                  iconPosition="left"
                  onClick={handleClickCreateCustomAutoLabel}
                >
                  {t('autoLabel.cal.button.createCustomAutoLabel')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <CustomAutoLabelList
          searchValue={searchValue}
          appliedCount={appliedEnginesCount}
          hasExports={!!historiesOfCreatingCALCount}
        />
        <CreateCustomAutoLabelModal
          open={isOpenCreateCustomAutoLabelModal}
          setOpen={setIsOpenCreateCustomAutoLabelModal}
        />
      </Box>
    </>
  );
};

export default Layout;
