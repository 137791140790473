import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { trimStart } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import Title from '../Title';

const useStyles = makeStyles(() => ({
  curLengthBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  curLength: {
    transition: '0.3s',
    '&.over': {
      color: '#ff625a',
    },
  },
  input: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
  },
}));

const ProjectDescription = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { description, setDescription } = newProjectInfo;
  const descriptionInputRef = useRef(null);
  const [isOver, setIsOver] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = trimStart(e.target.value);
    if (parsedValue.length > 200) {
      setDescription(parsedValue.slice(0, 200));
      setIsOver(true);
      setTimeout(() => {
        setIsOver(false);
      }, 300);
      return;
    }

    setDescription(parsedValue);
  };

  return (
    <MUI.Box mb="20px">
      <Title title={t('projectSettings.configuration.projectDescription')} isOptional />
      <MUI.Box mb="8px" />
      <MUI.TextField
        className={classes.input}
        variant="outlined"
        // multiline
        fullWidth
        inputRef={descriptionInputRef}
        value={description}
        onChange={handleChange}
        placeholder={t('projectSettings.configuration.projectDescriptionPlaceholder')}
      />
      <MUI.Box className={classes.curLengthBox}>
        <MUI.Typography
          className={`${classes.curLength} ${isOver ? 'over' : ''} `}
        >{`${description.length}/200`}</MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
};

export default ProjectDescription;
