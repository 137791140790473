import React, { useEffect, useState } from 'react';

import { Box } from '@superb-ai/norwegian-forest';
import { isEmpty, map, sum } from 'lodash';

import BarChartScrollable from '../../charts/barChart/BarChartScrollable';
import DonutLegend from '../../charts/donutChart/DonutLegend';
import { GetDonutColorFn } from '../../charts/donutChart/types';
import { SvgConfigScrollBarObject } from '../../config/types';
import { BarChartNameChoice } from '../../dataTypes/analyticsDataTypes';
import { JsonObj } from '../../userStats/types';
import {
  convertPropertyToChartData,
  getClassLegendObject,
  getPropertyById,
  getPropertyLegendObject,
} from './helper';
import { DonutDatum, ObjectProperty } from './interface';
import { PropertyTree } from './PropertyTree';
import styles from './PropertyTree.module.scss';

interface Props {
  chartName: BarChartNameChoice;
  data: JsonObj[];
  selectedClassProperties: ObjectProperty[];
  xValues: string[];
  yValues: number[];
  sValues: (number | undefined)[];
  tooltipKeyMap: Record<string, string>;
  filter: Record<string, string[] | []>;
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigScrollBarObject;
  handleSelectClass: (id: string) => void;
  selectedClassIndex: number;
  selectedClassId: string;
  getDatumColor: GetDonutColorFn;
  getPropertyColor: (id: string) => string[];
}

const hasScroll = (dataLength: number, numVisibleBars: number) => dataLength >= numVisibleBars;
const PropertyScrollBarChartWithLegend = (props: Props): React.ReactElement => {
  const {
    chartName,
    data,
    filter,
    svgInfo,
    selectedClassProperties,
    selectedClassIndex,
    handleSelectClass,
    getDatumColor,
    getPropertyColor,
  } = props;
  const [hoveredClassIndex, setHoveredClassIndex] = useState<number>(0);
  const [hoveredPropertyOptionIndex, setHoveredPropertyOptionIndex] = useState<number>(-1);
  const [visualizedProperty, setVisualizedProperty] = useState<ObjectProperty>();
  const [chartData, setChartData] = useState<DonutDatum[]>();

  useEffect(() => {
    setHoveredClassIndex(selectedClassIndex > 0 ? selectedClassIndex : 0);
  }, []);

  useEffect(() => {
    if (isEmpty(selectedClassProperties)) return;
    const initProperty = selectedClassProperties[0];
    setChartData(convertPropertyToChartData(initProperty));
    setVisualizedProperty(initProperty);
  }, [selectedClassProperties]);

  const highlightClass = (i: number, status: 'in' | 'out') => {
    status === 'in' ? setHoveredClassIndex(i) : setHoveredClassIndex(selectedClassIndex);
  };

  const handleMouseOverOption = (index: number, property: ObjectProperty) => {
    setHoveredPropertyOptionIndex(index);
    handleDisplayPropertyInChart(property);
  };
  const handleMouseOutOption = (property: ObjectProperty) => {
    setHoveredPropertyOptionIndex(-1);
    if (isEmpty(selectedClassProperties)) return;
    const currentProperty = getPropertyById(selectedClassProperties, property?.id);
    if (currentProperty) handleDisplayPropertyInChart(currentProperty);
  };

  const handleDisplayPropertyInChart = (datum: ObjectProperty) => {
    const selectedProperty = getPropertyById(selectedClassProperties, datum?.id);
    setVisualizedProperty(selectedProperty);
    if (selectedProperty) setChartData(convertPropertyToChartData(selectedProperty));
  };

  const handleClassLegendClick = async (datum: JsonObj, i: number) => {
    setHoveredClassIndex(i);
    // setHoveredClassId(datum?.classId);
    await handleSelectClass(datum?.classId);
  };

  const propObject = getPropertyLegendObject(selectedClassProperties);

  const xValues = map(chartData, property => property?.name);
  const yValues = map(chartData, property => property?.count);
  const sValues = map(chartData, property => property?.percent);
  const propertyColor = visualizedProperty && getPropertyColor(visualizedProperty?.id);

  return (
    <Box
      id={`${chartName}-container`}
      gap={2}
      style={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        paddingBottom: '2px',
        minWidth: '0px',
        minHeight: '280px',
        marginLeft: '10px',
        marginTop: '10px',
      }}
    >
      <Box
        display="flex"
        style={{
          flexDirection: 'column',
          marginLeft: '15px',
        }}
      >
        <DonutLegend
          id={`${chartName}-class-bar-legend`}
          chartName={chartName}
          chartObj={getClassLegendObject({ dataLength: data.length, hasMarker: false })}
          data={data}
          idKey="name"
          countKey="count"
          shareKey="percentTotal"
          getColor={getDatumColor}
          groupValues={[]}
          highlightSlice={highlightClass}
          handleOnClick={handleClassLegendClick}
          fixedTopMargin={false}
          showButton={false}
          selectedIndex={hoveredClassIndex}
        />
      </Box>
      <Box display="flex" className={styles.scroll} style={{ marginLeft: '10px' }}>
        {visualizedProperty && (
          <PropertyTree
            propObject={getPropertyLegendObject(selectedClassProperties)}
            maxHeight={300}
            maxWidth={500}
            handleMouseEnterProperty={handleDisplayPropertyInChart}
            handleMouseOverOption={handleMouseOverOption}
            handleMouseOutOption={handleMouseOutOption}
            hoveredPropertyOptionIndex={hoveredPropertyOptionIndex}
            selectedProperty={visualizedProperty}
          />
        )}
      </Box>
      <Box display="flex" style={{ marginRight: '20px' }}>
        {propObject?.numProps > 0 && visualizedProperty && (
          <BarChartScrollable
            xValues={xValues}
            yValues={yValues}
            sValues={sValues}
            totalCounts={sum(yValues)}
            chartName={chartName as BarChartNameChoice}
            isScrollable={hasScroll(xValues.length, 18)}
            filter={filter}
            svgInfo={{
              ...svgInfo,
              ...(propertyColor && {
                innerPadding: 0.4,
                outerPadding: 0.3,
                barDefaultColor: propertyColor[0],
                barHoverColor: propertyColor[1],
              }),
            }}
            tooltipKeyMap={{}}
            handleOnHover={(index: number) => setHoveredPropertyOptionIndex(index)}
            hoveredIndex={hoveredPropertyOptionIndex}
            rotateXLabel={visualizedProperty?.options?.length >= 6 ? true : false}
          />
        )}
        {propObject?.numProps === 0 && (
          <svg width={svgInfo.svgWidth} height={svgInfo.svgHeight}></svg>
        )}
      </Box>
      <div id="outer" style={{ display: 'none' }} />
    </Box>
  );
};

export default PropertyScrollBarChartWithLegend;
