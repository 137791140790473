import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList } from '@superb-ai/norwegian-forest';

import { Filters } from './types';

interface Props {
  filters: Filters;
  setFilters: (filters: Filters) => void;
}

const MemberFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const statusOptions = [
    { label: t('all'), value: '' },
    { label: t('pending'), value: 'Invited' },
    { label: t('active'), value: 'Active' },
  ];

  const roleOptions = [
    { label: t('all'), value: '' },
    { label: t('projectMembers.role.owner'), value: 'owner' },
    { label: t('projectMembers.role.admin'), value: 'admin' },
    { label: t('projectMembers.role.manager'), value: 'manager' },
    { label: t('projectMembers.role.reviewer'), value: 'reviewer' },
    { label: t('projectMembers.role.labeler'), value: 'worker' },
  ];

  const handleStatusFilterChange = (status: string) => {
    setFilters({ ...filters, status });
  };

  const handleRoleFilterChange = (role: string) => {
    setFilters({ ...filters, role });
  };

  return (
    <Box width="400px" display="grid" gridTemplateColumns="1fr 1fr" gap="8px">
      <DropdownList
        placeholder={t('users.filter.filterByStatus')}
        value={filters.status}
        options={statusOptions}
        onChange={handleStatusFilterChange}
      />
      <DropdownList
        placeholder={t('users.filters.filterByRole')}
        value={filters.role}
        options={roleOptions}
        onChange={handleRoleFilterChange}
      />
    </Box>
  );
};

export default MemberFilters;
