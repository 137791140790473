import { TFunction } from 'react-i18next';

import AmazonS3 from './AmazonS3';
import AzureBlobStorage from './AzureBlobStorage';
import GoogleCloudStorage from './GoogleCloudStorage';
import SlackWebhooks from './SlackWebhooks';

function getContents(t: TFunction, language: string) {
  return {
    S3: AmazonS3(t, language),
    GS: GoogleCloudStorage(t, language),
    ABS: AzureBlobStorage(t, language),
    SLACK: SlackWebhooks(t, language),
  };
}

export default getContents;
