import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { LoadingSpinner } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

import { IHistory } from '../../../../../../types/autoCurateTypes';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useAutoCurateService } from '../../../../services/AutoCurateService';
import CurationHistoryEmptyPage from './CurationHistoryEmptyPage';
import CurationHistoryItem from './CurationHistoryItem';

export default function CurationLayout() {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { getCurationHistory } = useAutoCurateService();
  const [curationHistory, setCurationHistory] = useState<IHistory[]>([]);
  const { showPublicDatasets } = usePublicDatasetContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const historyInfo = await getCurationHistory({ datasetId, showPublicDatasets });
      const completedResult = historyInfo.results.filter(
        (history: IHistory) => history?.job?.status === 'COMPLETE',
      );
      setCurationHistory(completedResult);
      setIsLoading(false);
    })();
  }, [datasetId]);

  return (
    <Box pt={2} px={4}>
      {isLoading ? (
        <>
          <Box display="flex" mt={8} justifyContent="center" alignItems="center">
            <Icon icon={LoadingSpinner} />
          </Box>
        </>
      ) : (
        <>
          {curationHistory.length > 0 ? (
            curationHistory.map(list => <CurationHistoryItem key={list.jobId} list={list} />)
          ) : (
            <CurationHistoryEmptyPage />
          )}
        </>
      )}
    </Box>
  );
}
