import { CSSProperties, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Box as UIBox, Typography } from '@superb-ai/ui';

import { GeoJSONPolygon } from '../../../../types/advancedAIFeaturesTypes';
import { hexToRgba } from '../../components/datasets/dataset/views/embedding/scatterView/utils/color';
import {
  AnnotationType,
  AnnotationTypeCoordinate,
  Box,
  ModelDiagnosisAnnotationType,
  Polygon,
} from '../../types/annotationTypes';
import { Placements } from './type';

type Annotation = {
  id: string;
  coordinate?: AnnotationTypeCoordinate<AnnotationType>;
  base64Data?: string;
  roi: AnnotationTypeCoordinate<Box>;
  type: AnnotationType | ModelDiagnosisAnnotationType;
  class?: string;
  color?: string;
  chipPlacement?: Placements;
  isDashed?: boolean;
};
type Props = {
  alt: string;
  srcUrl: string;
  annotations?: Annotation[];
  annotationColorMap?: Record<string, string>;
  imgStyle?: CSSProperties;
  originalImageSize: [number, number]; // [width, height]
  roi: AnnotationTypeCoordinate<Box>;
  chipColor?: string;
  hasColoredOutline?: boolean;
  color?: string;
  chipPrefix?: JSX.Element;
};

export default function CroppedAnnotatedImage({
  annotations,
  alt,
  srcUrl,
  originalImageSize,
  roi,
  chipColor,
  hasColoredOutline,
  color,
  chipPrefix,
}: Props) {
  const defaultRenderInfo = {
    rate: 1,
    roiOffsetX: 0,
    roiOffsetY: 0,
    imageLeft: 0,
    imageTop: 0,
    imageWidth: 0,
    imageHeight: 0,
    hasAnnotationChip: true,
  };

  const [renderInfo, setRenderInfo] = useState<{
    rate: number;
    roiOffsetX: number;
    roiOffsetY: number;
    imageLeft: number;
    imageTop: number;
    imageWidth: number;
    imageHeight: number;
    hasAnnotationChip: boolean;
  }>(defaultRenderInfo);
  const containerRef = useRef<HTMLDivElement>(null);

  const strokeWidth = 2;
  const defaultColor = '#05FF00';

  function getImageRateAndOffset(target: HTMLDivElement) {
    const { clientWidth, clientHeight } = target;
    const maxHeightForMargin = 80;
    const annotationsSectionMargin = (clientHeight || 0) > maxHeightForMargin ? [20, 30] : [8, 8]; // [marginX, marginY]
    if (!annotations) return;

    const originalAnnotationWidth = roi.width || 0;
    const originalAnnotationHeight = roi.height || 0;

    const annotationArea = [
      clientWidth - annotationsSectionMargin[0] * 2,
      clientHeight - annotationsSectionMargin[1] * 2,
    ]; // [width, height]

    if (
      originalAnnotationWidth / originalAnnotationHeight >
      annotationArea[0] / annotationArea[1]
    ) {
      const rate = annotationArea[0] / originalAnnotationWidth;
      const roiOffsetX = annotationsSectionMargin[0];
      const roiOffsetY =
        annotationsSectionMargin[1] + (annotationArea[1] - originalAnnotationHeight * rate) / 2;
      setRenderInfo({
        rate,
        roiOffsetX,
        roiOffsetY,
        imageLeft: roiOffsetX - roi.x * rate,
        imageTop: roiOffsetY - roi.y * rate,
        imageWidth: originalImageSize[0] * rate,
        imageHeight: originalImageSize[1] * rate,
        hasAnnotationChip: clientHeight > maxHeightForMargin,
      });
    } else {
      const rate = annotationArea[1] / originalAnnotationHeight;
      const roiOffsetX =
        annotationsSectionMargin[0] + (annotationArea[0] - originalAnnotationWidth * rate) / 2;
      const roiOffsetY = annotationsSectionMargin[1];
      setRenderInfo({
        rate,
        roiOffsetX,
        roiOffsetY,
        imageLeft: roiOffsetX - roi.x * rate,
        imageTop: roiOffsetY - roi.y * rate,
        imageWidth: originalImageSize[0] * rate,
        imageHeight: originalImageSize[1] * rate,
        hasAnnotationChip: clientHeight > maxHeightForMargin,
      });
    }
  }
  const resizeCallback = useCallback(
    entries => {
      const [entry] = entries;
      getImageRateAndOffset(entry.target);
    },
    [alt],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeCallback);
    if (containerRef?.current) resizeObserver.observe(containerRef?.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [alt]);

  const drawAnnotation = (annotation: Annotation) => {
    if (annotation.type === 'box' && annotation.coordinate) {
      const coordinate = annotation.coordinate as AnnotationTypeCoordinate<Box>;
      return (
        <rect
          key={annotation.id}
          x={renderInfo.roiOffsetX + (coordinate.x - roi.x) * renderInfo.rate}
          y={renderInfo.roiOffsetY + (coordinate.y - roi.y) * renderInfo.rate}
          width={coordinate.width * renderInfo.rate || 1}
          height={coordinate.height * renderInfo.rate || 1}
          stroke={color || annotation.color || defaultColor}
          strokeWidth={annotation.isDashed ? strokeWidth / 2 : strokeWidth}
          fill="transparent"
          {...(annotation.isDashed && { strokeDasharray: '4' })}
        />
      );
    }
    if (annotation.type === 'polygon' && annotation.coordinate) {
      const { points } = annotation.coordinate as AnnotationTypeCoordinate<Polygon>;
      return (points as GeoJSONPolygon).map((polygon, index) => (
        <path
          key={`${annotation.id}-${index}`}
          fillRule="evenodd"
          d={polygon.reduce(
            (polygonAcc, polygonAndHoles) =>
              polygonAcc +
              'M' +
              polygonAndHoles.reduce(
                (acc, paths, index) =>
                  acc +
                  `${paths.x * renderInfo.rate + renderInfo.imageLeft} ${
                    paths.y * renderInfo.rate + renderInfo.imageTop
                  }${index === polygonAndHoles.length - 1 ? 'Z ' : ' '}`,
                '',
              ),
            '',
          )}
          stroke={color || annotation.color || defaultColor}
          strokeWidth={annotation.isDashed ? strokeWidth / 2 : strokeWidth}
          fill="transparent"
          {...(annotation.isDashed && { strokeDasharray: '4' })}
        />
      ));
    }
    return <></>;
  };

  const drawMask = (annotation: Annotation) => {
    if (annotation.type === 'mask' && annotation.base64Data) {
      const binaryData = Uint8Array.from(atob(annotation.base64Data), c => c.charCodeAt(0));
      const blob = new Blob([binaryData], { type: 'image/png' });

      // Create an Image from Blob
      const url = URL.createObjectURL(blob);
      return (
        <>
          <rect
            width={renderInfo.imageWidth}
            height={renderInfo.imageHeight}
            fill={hexToRgba(annotation.color || '', 0.5)}
            mask={`url(#${annotation.id})`}
          />
          <mask id={annotation.id}>
            <image
              href={url}
              x={renderInfo.imageLeft}
              y={renderInfo.imageTop}
              width={renderInfo.imageWidth}
              height={renderInfo.imageHeight}
            />
          </mask>
        </>
      );
    }
  };

  function AnnotationClassChip(annotation: Annotation) {
    if (!containerRef.current) return <></>;
    const classChipHeight = 20;
    const placements = (annotation.chipPlacement || 'top-left').split('-');

    const annotationTopOffset =
      renderInfo.roiOffsetY + (annotation.roi.y - roi.y) * renderInfo.rate;
    const annotationBottomOffset =
      renderInfo.roiOffsetY +
      (roi.y + roi.height - (annotation.roi.y + annotation.roi.height)) * renderInfo.rate;
    const annotationLeftOffset =
      renderInfo.roiOffsetX + (annotation.roi.x - roi.x) * renderInfo.rate;
    const annotationRightOffset =
      renderInfo.roiOffsetX +
      (roi.x + roi.width - (annotation.roi.x + annotation.roi.width)) * renderInfo.rate;

    const top = annotationTopOffset - (classChipHeight + strokeWidth / 2);
    const bottom = annotationBottomOffset - (classChipHeight + strokeWidth / 2);
    const left = annotationLeftOffset - strokeWidth + strokeWidth / 2;
    const right = annotationRightOffset - strokeWidth + strokeWidth / 2;

    return (
      <UIBox
        key={annotation.id}
        position="absolute"
        backgroundColor="gray-opacity-400"
        color="white"
        px={0.5}
        borderRadius="2px"
        style={{
          ...(placements[0] === 'top'
            ? { top: renderInfo.hasAnnotationChip ? `${top}px` : '0px' }
            : { bottom: renderInfo.hasAnnotationChip ? `${bottom}px` : '0px' }),
          ...(annotationLeftOffset < containerRef.current.clientWidth / 2
            ? { left: renderInfo.hasAnnotationChip ? `${left}px` : '0px' }
            : { right: renderInfo.hasAnnotationChip ? `${right}px` : '0px' }),
        }}
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {chipPrefix || (
          <UIBox
            style={{
              width: '6px',
              height: '6px',
              backgroundColor: color || annotation.color || defaultColor,
            }}
            borderRadius="100%"
          />
        )}
        <Typography
          variant="s-strong"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          style={{ lineHeight: `${classChipHeight}px` }}
        >
          {annotation.class}
        </Typography>
      </UIBox>
    );
  }

  return (
    <UIBox overflow="hidden" width="100%" height="100%" position="relative" ref={containerRef}>
      {containerRef.current && annotations && (
        <>
          {' '}
          <img
            alt={alt}
            src={srcUrl}
            style={{
              position: 'absolute',
              top: `${renderInfo.imageTop}px`,
              left: `${renderInfo.imageLeft}px`,
              width: `${renderInfo.imageWidth}px`,
            }}
          />
          <svg
            style={{
              width: containerRef.current.clientWidth,
              height: containerRef.current.clientHeight,
              position: 'absolute',
            }}
          >
            {annotations.map(annotation => {
              return annotation.type === 'mask' ? drawMask(annotation) : drawAnnotation(annotation);
            })}
          </svg>
          {annotations.map(annotation => annotation.class && AnnotationClassChip(annotation))}
          {chipColor && (
            <>
              <UIBox
                position="absolute"
                style={{
                  right: '4px',
                  top: '4px',
                  width: '6px',
                  height: '6px',
                  backgroundColor: chipColor,
                }}
                mr="auto"
                borderRadius="4px"
              ></UIBox>
              {hasColoredOutline && (
                <UIBox
                  position="absolute"
                  width="100%"
                  height="100%"
                  style={{
                    outline: `1px solid ${chipColor}`,
                    outlineOffset: '-1px',
                  }}
                ></UIBox>
              )}
            </>
          )}
        </>
      )}
    </UIBox>
  );
}
