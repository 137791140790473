import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon, Tooltip } from '@superb-ai/norwegian-forest';
import { concat, without } from 'lodash';

import { LabelTag } from '../../../../types/labelTypes';

const useStyles = makeStyles(theme => ({
  listItem: {
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(255, 98, 90, 0.04)',
    },
  },
  deleteConfirmListItem: {
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  deleteConfirmButton: {
    // fontSize: '12px',
    // fontWeight: 400,
    // padding: '1px 5px',
    margin: '0 2px',
  },
}));

interface Props {
  tag: LabelTag;
  isTagAdded: boolean;
  isIncluded: boolean;
  isPartiallyIncluded: boolean | null;
  onClickDeleteTag: (tagId: string) => void;
  showDeleteConfirm: any;
  setShowDeleteConfirm: Dispatch<SetStateAction<string | null>>;
  tagsToAdd: any;
  setTagsToAdd: Dispatch<SetStateAction<string[]>>;
  tagsToRemove: any;
  setTagsToRemove: Dispatch<SetStateAction<string[]>>;
  setIsIncludedTagDeleted: Dispatch<SetStateAction<boolean>>;
  isCanEditTag: boolean;
}

const EditTagsDialogEditableSelectItem = ({
  tag,
  isTagAdded,
  isIncluded,
  isPartiallyIncluded,
  onClickDeleteTag,
  showDeleteConfirm,
  setShowDeleteConfirm,
  tagsToAdd,
  setTagsToAdd,
  tagsToRemove,
  setTagsToRemove,
  setIsIncludedTagDeleted,
  isCanEditTag,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [indeterminate, setIndeterminate] = useState<boolean>(Boolean(isPartiallyIncluded));
  const [checked, setChecked] = useState<boolean>(
    Boolean(isTagAdded || isIncluded || isPartiallyIncluded),
  );

  const addedTagExcluded = without(tagsToAdd, tag.id);
  const addedTagIncluded = concat(tagsToAdd, [tag.id]);
  const removedTagExcluded = without(tagsToRemove, tag.id);

  const handleClickDeleteButton = () => {
    setShowDeleteConfirm(tag.id);
  };

  // ex. tagId: 606024a3-2583-4cda-ab4c-8ed95bc6362f
  const handleDeleteTag = (tagId: string) => () => {
    onClickDeleteTag(tagId);
    setShowDeleteConfirm(null);

    if (isTagAdded) {
      setTagsToAdd(addedTagExcluded);
    }

    // if (isIncluded) {
    setIsIncludedTagDeleted(true);
    // }
  };

  const handleSelectTag = () => {
    if (!checked) {
      setChecked(true);
      setTagsToRemove(removedTagExcluded);
      if (isPartiallyIncluded) {
        setIndeterminate(true);
        return;
      }
      if (!isIncluded) {
        setTagsToAdd(addedTagIncluded);
        return;
      }
      return;
    }

    if (indeterminate) {
      setIndeterminate(false);
      setTagsToAdd(addedTagIncluded);
      return;
    }

    if (isPartiallyIncluded || isIncluded) {
      setTagsToRemove(concat(tagsToRemove, tag.id));
    }

    setChecked(false);
    setTagsToAdd(addedTagExcluded);
  };

  return (
    <>
      {showDeleteConfirm === tag.id ? (
        <MUI.ListItem className={classes.deleteConfirmListItem} dense selected>
          <MUI.ListItemText
            id={tag.id}
            primary={t('labels.editTag.delete', { tagName: tag.name })}
          />
          <MUI.ListItemSecondaryAction>
            <MUI.IconButton
              className={classes.deleteConfirmButton}
              size="small"
              onClick={handleDeleteTag(tag.id)}
            >
              <Icon name="check" size="15px" />
            </MUI.IconButton>
            <MUI.IconButton
              className={classes.deleteConfirmButton}
              size="small"
              onClick={() => setShowDeleteConfirm(null)}
            >
              <Icon name="clear" size="15px" />
            </MUI.IconButton>
          </MUI.ListItemSecondaryAction>
        </MUI.ListItem>
      ) : (
        <MUI.ListItem
          className={classes.listItem}
          button
          dense
          selected={checked}
          onClick={handleSelectTag}
        >
          <MUI.ListItemIcon>
            <MUI.Checkbox
              checked={checked}
              indeterminate={indeterminate}
              color="primary"
              tabIndex={-1}
              disableRipple
              onClick={handleSelectTag}
            />
          </MUI.ListItemIcon>
          <MUI.ListItemText id={tag.id} primary={tag.name} />
          <MUI.ListItemSecondaryAction>
            {isCanEditTag && (
              <Tooltip
                anchorEl={
                  <span>
                    <MUI.IconButton size="small" onClick={handleClickDeleteButton}>
                      <Icon name="trash" size="18px" customColor={theme.palette.grey[700]} />
                    </MUI.IconButton>
                  </span>
                }
              >
                {t('labels.editTag.tagRemoveRule')}
              </Tooltip>
            )}
          </MUI.ListItemSecondaryAction>
        </MUI.ListItem>
      )}
    </>
  );
};

export default EditTagsDialogEditableSelectItem;
