import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDown, Plus } from '@superb-ai/icons';
import {
  Box,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLabel,
} from '@superb-ai/norwegian-forest';
import { Button, Icon } from '@superb-ai/ui';

import AnalyticsTracker from '../../../analyticsTracker';
import { DataTypeGroup } from '../../../consts/ProjectConst';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import TierUnion from '../../../union/TierUnion';
import UserRoleUnion from '../../../union/UserRoleUnion';
import UpgradePlanTooltip from '../../elements/UpgradePlanTooltip';
import DuplicateProjectModal from './DuplicateProjectModal';
import SampleProjectModal from './SampleProjectModal';

const OwnerOrAdminNewProjectButton: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [isSampleProjectModalOpen, setIsSampleProjectModalOpen] = useState(false);
  const isEnterprise = TierUnion.isEnterprise(authInfo.tier);
  const isTeam = TierUnion.isTeam(authInfo.tier);
  const lidar = useFeatureFlag('lidar');

  const handleClickNewProject = (dataType: DataTypeGroup) => () => {
    routeInfo.history.push({
      pathname: `/${routeInfo.urlMatchInfo.accountName}/label/new_project/create_project`,
      state: { prevPath: routeInfo.history.location.pathname },
      search: `?data_type=${dataType}`,
    });
    AnalyticsTracker.createProjectButtonClicked({
      dataType,
      accountId: authInfo?.accountName ?? '',
    });
  };

  const handleClickDuplicate = () => {
    setIsDuplicateDialogOpen(true);
  };

  const handleClickSampleProject = () => {
    setIsSampleProjectModalOpen(true);
  };

  return (
    <>
      <DropdownMenu
        width={172}
        placement="bottom-start"
        AnchorElement={
          <Button
            variant="strong-fill"
            color="primary"
            size="l"
            data-intercom-target="New Project"
            style={{ whiteSpace: 'nowrap' }}
          >
            <Icon icon={Plus} />
            {t('projects.button.newProject')}{' '}
            <Box display="inline-flex" alignItems="center" ml={2}>
              <Icon icon={ChevronDown} />
            </Box>
          </Button>
        }
      >
        <DropdownMenuLabel>{t('projectSettings.configuration.dataType')}</DropdownMenuLabel>
        <DropdownMenuItem iconName="imageOutline" onClick={handleClickNewProject('image')}>
          {t('shared.image')}
        </DropdownMenuItem>
        {isTeam || isEnterprise ? (
          <DropdownMenuItem iconName="playCircleOutline" onClick={handleClickNewProject('video')}>
            {t('components.CustomShortcut.video')}
          </DropdownMenuItem>
        ) : (
          <UpgradePlanTooltip
            anchorEl={
              <span>
                <DropdownMenuItem disabled iconName="playCircleOutline">
                  {t('components.CustomShortcut.video')}
                </DropdownMenuItem>
              </span>
            }
            variant="not_supported"
            placement="left"
          />
        )}
        {lidar &&
          (isEnterprise ? (
            <DropdownMenuItem iconName="lidar" onClick={handleClickNewProject('lidar')}>
              {t('data.types.point_cloud_sequence.title')}
            </DropdownMenuItem>
          ) : (
            <UpgradePlanTooltip
              anchorEl={
                <span>
                  <DropdownMenuItem disabled iconName="lidar">
                    {t('data.types.point_cloud_sequence.title')}
                  </DropdownMenuItem>
                </span>
              }
              variant="not_supported"
              placement="left"
            />
          ))}
        <Box bb />
        <DropdownMenuItem iconName="copy" onClick={handleClickDuplicate}>
          {t('projectSettings.configuration.button.duplicate')}
        </DropdownMenuItem>
        <DropdownMenuItem
          iconName="folderPlus"
          onClick={handleClickSampleProject}
          data-intercom-target="Sample Project"
        >
          {t('projects.button.sampleProject')}
        </DropdownMenuItem>
      </DropdownMenu>
      <DuplicateProjectModal
        isOpen={isDuplicateDialogOpen}
        onClose={() => setIsDuplicateDialogOpen(false)}
      />
      {isSampleProjectModalOpen && (
        <SampleProjectModal
          isOpen={isSampleProjectModalOpen}
          onClose={() => setIsSampleProjectModalOpen(false)}
        />
      )}
    </>
  );
};

const NewProjectButton: React.FC = () => {
  const authInfo = useAuthInfo();

  const { role } = authInfo;
  const isOwnerOrAdmin = UserRoleUnion.isOwner(role) || UserRoleUnion.isAdmin(role);

  if (authInfo.isGuest) return <></>;

  if (isOwnerOrAdmin) {
    return <OwnerOrAdminNewProjectButton />;
  }

  return <></>;
};

export default NewProjectButton;
