import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import * as MUI from '@mui/material';
import { Box, Button, FormattedNumber, Typography } from '@superb-ai/norwegian-forest';
import { concat, filter, find, includes, isEmpty, map, pickBy } from 'lodash';

import { useAssetsInfo } from '../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useGetProjectList } from '../../../hooks/GetProjectList';
import { useProjectListInfo } from '../../../hooks/ProjectListInfo';
import { useSearchedProjectQuery } from '../../../queries/useProjectListQuery';
import { getUrl, joinPaths } from '../../../routes/util';
import ProjectsService from '../../../services/ProjectsService';
import ParamUtils from '../../../utils/ParamUtils';
import AssignToProjectDialog from '../../pages/data/AssignToProjectDialog';
import { DataDetailNavigationProvider } from '../../pages/data/dataDetail/Context';
import { DataDetailRoute } from '../../pages/data/dataDetail/Route';
import DataTableView from '../../pages/data/dataList/DataTableView';
import DialogPagination from './DialogPagination';

type Props = {
  selectedDataset?: string;
  handleBack?: () => void;
  checkedAssets: string[];
  setCheckedAssets: Dispatch<SetStateAction<string[]>>;
  isAllAssetsChecked: boolean;
  setIsAllAssetsChecked: Dispatch<SetStateAction<boolean>>;
  apiParams: Record<string, any>;
  setApiParams: Dispatch<SetStateAction<Record<string, any>>>;
  setDataset: Dispatch<SetStateAction<any>>;
};

const DataContainer = ({
  selectedDataset,
  handleBack,
  checkedAssets,
  setCheckedAssets,
  isAllAssetsChecked,
  setIsAllAssetsChecked,
  apiParams,
  setApiParams,
  setDataset,
}: Props) => {
  const { t } = useTranslation();
  const assetsInfo = useAssetsInfo();
  const authInfo = useAuthInfo();
  const projectsInfo = useProjectListInfo();
  const routeInfo = useRouteInfo();
  const { path } = useRouteMatch();
  const { pathname, search } = useLocation();
  const url = `${pathname}${search}`;
  const [isLoadingAssets, setIsLoadingAssets] = useState(true);
  const [isAssignToProjectDialogOpen, setIsAssignToProjectDialogOpen] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string>();
  const [orderBy, setOrderBy] = useState<'desc' | 'asc' | '-desc' | '-asc'>('desc');
  const [isDesc, setIsDesc] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getProjectList = useGetProjectList();

  const {
    params,
    params: { project },
  } = routeInfo;

  const { data: searchedProjectIds } = useSearchedProjectQuery();

  useEffect(() => {
    params.dataset = selectedDataset;
    setDataset(params.dataset);
  }, [params.dataset, selectedDataset]);

  useEffect(() => {
    if (!selectedAssetId) return;
    const nextUrl = getUrl(
      [path, DataDetailRoute.path],
      { assetId: selectedAssetId },
      routeInfo.location.search,
    );
    if (nextUrl !== url) {
      routeInfo.history.push(nextUrl);
    }
    setSelectedAssetId('');
  }, [selectedAssetId, path, url, routeInfo]);

  const getProjectIdByName = async () => {
    if (
      project === '[ all projects ]' ||
      project === '[ assigned to a project ] ' ||
      project === '[ not assigned to any project ]'
    )
      return '';

    if (searchedProjectIds[project]) return searchedProjectIds[project].id;

    const projectList = await getProjectList({ nameIcontains: project });
    const foundProject = find(projectList.results, ['name', project]);

    return foundProject?.id;
  };

  useEffect(() => {
    if (!assetsInfo) return;
    assetsInfo.updateDatasets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!assetsInfo || !projectsInfo) return;
    (async () => {
      setCheckedAssets([]);
      setIsLoadingAssets(true);

      const projectIdByName = (await getProjectIdByName()) as string;

      const sortParam = pickBy(
        { ordering: isDesc ? orderBy : '-'.concat(orderBy) },
        v => !isEmpty(v),
      );
      const filterParams = ParamUtils.getApiParamsForDataFilter(params, projectIdByName);
      filterParams.page = currentPage.toString();
      const formattedParams = { ...sortParam, ...filterParams };
      setApiParams(formattedParams);
      await assetsInfo.updateAssets(formattedParams);
      setIsLoadingAssets(false);
    })();

    // eslint-disable-next-line
  }, [params.dataset, params.project, params.page, params.key, orderBy, isDesc, currentPage]);

  const handleClickGlobalCheckbox = () => {
    if (isAllAssetsChecked) {
      setIsAllAssetsChecked(false);
      setCheckedAssets([]);
      return;
    }

    if (checkedAssets.length === assetsInfo.assets.length) {
      setCheckedAssets([]);
      return;
    }
    setCheckedAssets(map(assetsInfo.assets, asset => asset.id));
  };

  const handleClickCheckbox = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (includes(checkedAssets, id)) {
      if (isAllAssetsChecked) {
        setIsAllAssetsChecked(false);
      }
      setCheckedAssets(filter(checkedAssets, checkedAsset => checkedAsset !== id));
      return;
    }
    setCheckedAssets(concat(checkedAssets, [id]));
  };

  const handleClickSelectAllAssets = (isAllAssetsChecked: boolean) => () => {
    setIsAllAssetsChecked(isAllAssetsChecked);
    if (!isAllAssetsChecked) {
      setCheckedAssets([]);
    }
  };

  const handleCloseAssignToProjectDialog = () => {
    setIsAssignToProjectDialogOpen(false);
  };

  const handleRequestSort = (property: any, order: 'desc' | 'asc' | '-desc' | '-asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const handleSearchProject = async (inputValue: string) => {
    const projectList = await ProjectsService.getProjects({
      params: {
        nameIcontains: inputValue,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const searchProjectIds = {} as Record<string, string>;
    const resultProjects = map(projectList.results, project => {
      searchProjectIds[project.name] = project.id;
      return { label: project.name, value: project.name };
    });

    return resultProjects;
  };

  return (
    <DataDetailNavigationProvider>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="headline3">{params.dataset}</Typography>
        </Box>
        <Box>
          <Button onClick={handleBack}>Back</Button>
        </Box>
      </Box>
      <MUI.Grid container direction="column">
        <MUI.Grid item xs={12}>
          <MUI.Grid container spacing={1}></MUI.Grid>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="50px"
          >
            <MUI.Typography variant="subtitle1" color="textPrimary">
              Total{' '}
              {isLoadingAssets ? (
                '...'
              ) : (
                <FormattedNumber value={assetsInfo.totalCount} unit="items" unitSingular="item" />
              )}
            </MUI.Typography>
          </MUI.Box>
          <DataTableView
            setSelectedAssetId={setSelectedAssetId}
            onClickCheckbox={handleClickCheckbox}
            onClickGlobalCheckbox={handleClickGlobalCheckbox}
            onClickSelectAllAssets={handleClickSelectAllAssets}
            checkedAssets={checkedAssets}
            isAllAssetsChecked={isAllAssetsChecked}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isLoading={isLoadingAssets}
            variant="modal"
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <DialogPagination
            totalCount={assetsInfo.totalCount}
            {...{ currentPage, setCurrentPage }}
          />
        </MUI.Grid>
        <AssignToProjectDialog
          isAllChecked={isAllAssetsChecked}
          variant="data"
          checkedList={checkedAssets}
          isOpen={isAssignToProjectDialogOpen}
          onClose={handleCloseAssignToProjectDialog}
          params={apiParams}
          dataset={params.dataset}
          totalCount={assetsInfo.totalCount}
          handleSearch={handleSearchProject}
        />
      </MUI.Grid>
      <Switch>
        {/* @ts-ignore: saa-680 */}
        <Route path={joinPaths(path, DataDetailRoute.path)} component={DataDetailRoute} />
      </Switch>
    </DataDetailNavigationProvider>
  );
};

export default DataContainer;
