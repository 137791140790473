import { ComponentProps } from 'react';

import {
  Chart,
  Heatmap,
  HeatmapBandsColorScale,
  RecursivePartial,
  Settings,
  Theme,
  Tooltip,
} from '@elastic/charts';
import { Box, Typography } from '@superb-ai/ui';
import * as d3Format from 'd3-format';

import { ConfusionMatrixDatum } from '../../../../../../../services/DiagnosisAnalyticsService';
import { formatLargeNumber } from '../../../../../../../utils/numberUtils';
import { HistogramOptionalProps } from '../../../../analytics/charts/Histogram';
import { HorizontalDivider } from '../elements/HorizontalDivider';
import { ConfusionMatrixTooltip } from './ConfusionMatrixTooltip';

const defaultColorScale: HeatmapBandsColorScale = {
  type: 'bands',
  bands: [
    { start: -Infinity, end: 0.2, color: '#E5E9FF' },
    { start: 0, end: 0.4, color: '#BECAFF' },
    { start: 0.4, end: 0.6, color: '#97ACFF' },
    { start: 0.6, end: 0.8, color: '#708CFF' },
    { start: 0.8, end: Infinity, color: '#3479FF' },
  ],
};
interface Props {
  data: ConfusionMatrixDatum[];
  chartHeight: string;
  chartWidth: string;
  colorScale: HeatmapBandsColorScale;
  xAxis?: {
    title: string;
  };
  yAxis?: { title: string };
  theme: RecursivePartial<Theme>;
  onElementListeners?: any;
  optional?: HistogramOptionalProps;
  heatmapProps?: Partial<ComponentProps<typeof Heatmap>>;
  chartStyle?: ComponentProps<typeof Box>['style'];
}

export const ConfusionMatrix = ({
  data,
  chartWidth,
  chartHeight,
  xAxis,
  yAxis,
  colorScale,
  theme,
  onElementListeners,
  optional,
  heatmapProps,
  chartStyle,
}: Props): JSX.Element => {
  return (
    <Box
      style={{
        marginTop: '10px',
        width: chartWidth,
        height: chartHeight,
        ...chartStyle,
      }}
    >
      <Chart>
        <Settings showLegend={false} brushAxis="undefined" theme={theme} {...onElementListeners} />
        <Tooltip
          placement="top"
          customTooltip={ConfusionMatrixTooltip}
          {...(optional?.tooltipFooterText && {
            footer: () => (
              <Box width="100%">
                <HorizontalDivider mb={0.5} />
                <Typography variant="m-regular" mb={0} color="cloud-400">
                  {optional?.tooltipFooterText}
                </Typography>
              </Box>
            ),
          })}
        />
        {/*
          Would like to move the axis to the top which isn't possible at the moment.
          https://github.com/elastic/elastic-charts/issues/1952
          <Axis id="left" title="Object Class" position={Position.Left} />
        */}
        <Heatmap
          id="HEATMAP"
          colorScale={colorScale ? colorScale : defaultColorScale}
          data={data}
          xAccessor={d => (d as ConfusionMatrixDatum)[1]} // preducted
          yAccessor={d => (d as ConfusionMatrixDatum)[0]} // actual
          valueAccessor={d => d[2] as number}
          valueFormatter={value => {
            if (value === 0) return '.';
            else if (value < 0) return value.toFixed(0);
            else if (value > 1000) {
              return formatLargeNumber(value);
            }
            return d3Format.format(',')(value);
          }}
          yAxisTitle={yAxis?.title || 'Ground Truths'}
          xAxisTitle={xAxis?.title || 'Predictions'}
          {...heatmapProps}
        />
      </Chart>
    </Box>
  );
};
