import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { SettingsPerRole } from './config';

export default function Layout(): JSX.Element {
  const { projectRole } = useAuthInfo();

  if (!projectRole) return <></>;
  return <Box>{SettingsPerRole[projectRole]}</Box>;
}
