import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { ChevronRight, InfoCircleOutline } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Icon,
  LinkTypography,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { format } from 'date-fns';
import Link from 'next/link';

import { Row } from '../../../../components/elements/Row';
import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import { getUrl } from '../../../../routes/util';
import { formatCount } from '../../../../utils/numberFormat';
import { textEllipsisStyle } from '../../components/components';
import { CURATE_DATASET } from '../../path';
import { MyModel, MyModelDetail, TrainingSetInfo } from '../../services/types';

export const DatasetDialog = ({
  datasetName,
  state,
  modelData,
}: {
  datasetName: string;
  state: ReturnType<typeof useDialogState>;
  modelData: MyModel;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const isManualSplit = modelData.trainingSet.validationSetList.length > 0;
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Dialog state={state} hideOnClickOutside={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.datasetDialog.title')}
      </Dialog.Header>
      <Typography variant="m-strong">{t('curate.data.dataset')}</Typography>
      <Row
        style={{ marginTop: -8 }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <LinkTypography
          variant="m-regular"
          color={'gray-400'}
          underline={isHovered}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          onClick={() => {
            history.push(
              getUrl([params.accountName, CURATE_DATASET, modelData.trainingSet.referenceId]),
            );
          }}
        >
          {datasetName}
        </LinkTypography>
        <Icon icon={ChevronRight} />
        {modelData.baselineModel.purpose === 'recognition' && (
          <Box ml="auto">
            {isManualSplit ? (
              <Chip color="primary">{t('model.train.manualSplit')}</Chip>
            ) : (
              <Tooltip content={t('model.datasetDialog.randomSplitInfo')} placement="top-end">
                <Chip color="secondary">
                  {t('model.train.randomSplit')}
                  <Icon icon={InfoCircleOutline} color="secondary" />
                </Chip>
              </Tooltip>
            )}
          </Box>
        )}
      </Row>
      <TrainSetContent modelData={modelData} />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.close')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const TrainSetContent = ({ modelData }: { modelData: MyModel }) => {
  const { t } = useTranslation();
  const isManualSplit = modelData.trainingSet.validationSetList.length > 0;

  return (
    <>
      {isManualSplit ? (
        <>
          <Row style={{ marginBottom: -8 }}>
            <Typography variant="m-strong">{t('model.train.trainSetSlice')}</Typography>
            <Typography variant="m-regular">
              &nbsp;({modelData.trainingSet.trainingSetList.length})
            </Typography>
          </Row>
          <DatasetSlices
            datasetId={modelData.trainingSet.referenceId}
            trainingSetSlices={modelData.trainingSet.trainingSetList}
            height={184}
          />
          <Row style={{ marginBottom: -8 }}>
            <Typography variant="m-strong">{t('model.train.validationSetSlice')}</Typography>
            <Typography variant="m-regular">
              &nbsp;({modelData.trainingSet.validationSetList.length})
            </Typography>
          </Row>
          <DatasetSlices
            datasetId={modelData.trainingSet.referenceId}
            trainingSetSlices={modelData.trainingSet.validationSetList}
            height={184}
          />
        </>
      ) : (
        <>
          <Row style={{ marginBottom: -8 }}>
            <Typography variant="m-strong">{t('model.datasetDialog.datasetSlice')}</Typography>
            <Typography variant="m-regular">
              &nbsp;({modelData.trainingSet.trainingSetList.length})
            </Typography>
          </Row>
          <DatasetSlices
            datasetId={modelData.trainingSet.referenceId}
            trainingSetSlices={modelData.trainingSet.trainingSetList}
            height={166}
          />
          {modelData.trainingSet.autoTrainingSet && modelData.trainingSet.autoValidationSet && (
            <Box backgroundColor={'secondary-100'} borderRadius="2px" p={1.5}>
              <AutoSliceSet
                modelData={modelData}
                autoTrainSetSlice={modelData.trainingSet.autoTrainingSet}
                autoValidationSetSlice={modelData.trainingSet.autoValidationSet}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export const AutoSliceSet = ({
  modelData,
  autoTrainSetSlice,
  autoValidationSetSlice,
}: {
  modelData: MyModel | MyModelDetail;
  autoTrainSetSlice: TrainingSetInfo;
  autoValidationSetSlice: TrainingSetInfo;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ accountName: string }>();
  const [isAutoTrainSetSliceHovered, setIsAutoTrainSetSliceHovered] = useState<boolean>(false);
  const [isAutoValidationSetSliceHovered, setIsAutoValidationSetSliceHovered] =
    useState<boolean>(false);
  return (
    <>
      <Typography variant="m-strong">{t('model.train.trainSetSlice')}</Typography>
      <Row
        border="1px solid"
        borderRadius="2px"
        borderColor="gray-200"
        style={{ height: 40 }}
        mt={1}
        mb={2}
        backgroundColor={'white'}
        p={1.5}
        justifyContent="space-between"
        onMouseEnter={() => {
          setIsAutoTrainSetSliceHovered(true);
        }}
        onMouseLeave={() => {
          setIsAutoTrainSetSliceHovered(false);
        }}
      >
        <LinkTypography
          variant="m-regular"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={'black'}
          underline={isAutoTrainSetSliceHovered}
          onClick={() => {
            history.push(
              getUrl([`/${params.accountName}${CURATE_DATASET}/:datasetId/slice/:sliceId`], {
                datasetId: modelData.trainingSet.referenceId,
                sliceId: modelData.trainingSet.autoTrainingSet?.id ?? '',
              }),
            );
          }}
        >
          {modelData.trainingSet.autoTrainingSet?.name}
        </LinkTypography>
        <Box style={{ width: 'max-content' }}>
          <Typography variant="m-regular" color="gray-300">
            <Trans
              t={t}
              i18nKey={'model.myModels.sliceInfo'}
              values={{
                createdAt: autoTrainSetSlice.updatedAt
                  ? format(new Date(autoTrainSetSlice.updatedAt), 'MMM d, yyyy, h:mma')
                  : '-',
                images: formatCount(autoTrainSetSlice.imageCount ?? 0),
              }}
            />
          </Typography>
        </Box>
      </Row>
      <Typography variant="m-strong">{t('model.train.validationSetSlice')}</Typography>
      <Row
        border="1px solid"
        borderRadius="2px"
        borderColor="gray-200"
        style={{ height: 40 }}
        mt={1}
        backgroundColor={'white'}
        p={1.5}
        justifyContent="space-between"
        onMouseEnter={() => {
          setIsAutoValidationSetSliceHovered(true);
        }}
        onMouseLeave={() => {
          setIsAutoValidationSetSliceHovered(false);
        }}
      >
        <LinkTypography
          variant="m-regular"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={'black'}
          underline={isAutoValidationSetSliceHovered}
          onClick={() => {
            history.push(
              getUrl([`/${params.accountName}${CURATE_DATASET}/:datasetId/slice/:sliceId`], {
                datasetId: modelData.trainingSet.referenceId,
                sliceId: modelData.trainingSet.autoValidationSet?.id ?? '',
              }),
            );
          }}
        >
          {modelData.trainingSet.autoValidationSet?.name}
        </LinkTypography>
        <Typography variant="m-regular" color="gray-300">
          <Trans t={t} i18nKey={'model.myModels.sliceInfo'}>
            {{
              createdAt: autoValidationSetSlice.updatedAt
                ? format(new Date(autoValidationSetSlice.updatedAt), 'MMM d, yyyy, h:mma')
                : '-',
            }}{' '}
            / {{ images: formatCount(autoValidationSetSlice?.imageCount ?? 0) }} images
          </Trans>
        </Typography>
      </Row>
    </>
  );
};

export const DatasetSlices = ({
  datasetId,
  trainingSetSlices,
  height,
  newTab,
}: {
  datasetId: string;
  trainingSetSlices: TrainingSetInfo[];
  height: number;
  newTab?: boolean;
}) => {
  return (
    <Box
      px={0.5}
      borderColor={'gray-150'}
      borderRadius="2px"
      border="1px solid"
      overflow="auto"
      py={0.5}
      style={{ height, width: '100%' }}
    >
      {trainingSetSlices.map(slice => {
        return (
          <DatasetSliceRow key={slice.id} slice={slice} datasetId={datasetId} newTab={newTab} />
        );
      })}
    </Box>
  );
};

const DatasetSliceRow = ({
  slice,
  datasetId,
  newTab,
}: {
  slice: TrainingSetInfo;
  datasetId: string;
  newTab?: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Row
      key={slice.name}
      style={{ height: 32 }}
      px={1}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <TextEllipsisBox mr="auto" style={{ maxWidth: 180 }}>
        {newTab ? (
          <Link
            href={getUrl([`/${accountName}${CURATE_DATASET}/:datasetId/slice/:sliceId`], {
              datasetId: datasetId,
              sliceId: slice.id,
            })}
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...textEllipsisStyle }}
          >
            <Typography
              underline={isHovered}
              cursor="pointer"
              color="gray-400"
              variant="m-regular"
              style={{ ...textEllipsisStyle }}
            >
              {slice.name}
            </Typography>
          </Link>
        ) : (
          <LinkTypography
            underline={isHovered}
            cursor="pointer"
            color="gray-400"
            variant="m-regular"
            style={{ ...textEllipsisStyle }}
            onClick={() => {
              history.push(
                getUrl([`/${accountName}${CURATE_DATASET}/:datasetId/slice/:sliceId`], {
                  datasetId: datasetId,
                  sliceId: slice.id,
                }),
              );
            }}
          >
            {slice.name}
          </LinkTypography>
        )}
      </TextEllipsisBox>
      <Box ml="auto" style={{ width: 'max-content' }}>
        <Typography variant="m-regular" color={'gray-300'}>
          <Trans t={t} i18nKey={'model.myModels.sliceInfo'}>
            {{
              createdAt: slice.updatedAt
                ? format(new Date(slice.updatedAt), 'MMM d, yyyy, h:mma')
                : '-',
              images: formatCount(slice.imageCount ?? 0),
            }}
          </Trans>
        </Typography>
      </Box>
    </Row>
  );
};
