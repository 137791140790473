import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { CheckFilled } from '@superb-ai/icons';
import { Box, IconButton, Input, Modal } from '@superb-ai/norwegian-forest';
import { Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { sleep } from '../../../../../../utils/SpbUtils';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { DatasetResult, useCurateDatasetService } from '../../../../services/DatasetService';
export default function DeleteSliceModal({
  deleteSliceModalIsOpen,
  handleClickDelete,
  sliceId,
  setSelectedDeleteSlice,
}: {
  deleteSliceModalIsOpen: boolean;
  handleClickDelete: () => void;
  setSelectedDeleteSlice: (id: string) => void;
  sliceId: string;
}): JSX.Element {
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [deleteConfirmInput, setDeleteConfirmInput] = useState<string>('');

  const [sliceInfo, setSliceInfo] = useState<DatasetResult>();

  const { getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const handleDeleteConfirmInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDeleteConfirmInput(e.target.value);
    },
    [setDeleteConfirmInput],
  );
  const handleDeleteDataset = useCallback(() => {
    handleClickDelete();

    enqueueSnackbar(
      <>{`${t('bulkActions.messages.deleteSlice', { name: sliceInfo?.name ?? '' })}`}</>,
      {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 10000,
        content: (key, message) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                width: '420px',
                height: '101px',
                background: '#1f1f1f',
                borderLeft: '4px solid  #82db24',
                padding: '20px',
              }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex">
                  <Icon icon={CheckFilled} color="green-400" size={24} />
                  <Box display="flex" ml={1} pt={0.5}>
                    <Typography variant="m-strong" color={'white'}>
                      {message}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{ maxHeight: '24px' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    icon="clear"
                    color="backgroundColor"
                    style={{ padding: '0 8px' }}
                    onClick={() => closeSnackbar(key)}
                  />
                </Box>
              </Box>
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    size="m"
                    style={{ color: 'white' }}
                    variant="text"
                    onClick={() => {
                      history.push(`/${accountName}/curate/dataset/${datasetId}/slice/list`);
                      closeSnackbar(key);
                    }}
                  >
                    {t('button.view')}
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        },
      },
    );

    setSelectedDeleteSlice('');
  }, [
    sliceInfo,
    setSelectedDeleteSlice,
    handleClickDelete,
    enqueueSnackbar,
    accountName,
    closeSnackbar,
    history,
    datasetId,
    t,
  ]);

  useEffect(() => {
    (async () => {
      if (sliceId && datasetId) {
        const sliceInfo = await getSlice({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
          sliceId,
          expand: ['image_count'],
        });
        setSliceInfo(sliceInfo);
      } else {
        await sleep(100);
      }
    })();
    return () => {
      setSliceInfo(undefined);
    };
  }, [sliceId]);

  return (
    <Modal
      open={deleteSliceModalIsOpen}
      close={{
        onClose: () => {
          setSelectedDeleteSlice('');
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.deleteSlice')}
      mainButton={{
        text: t('button.confirm'),
        onClick: () => handleDeleteDataset(),
        color: 'primary',
        disabled: deleteConfirmInput !== 'DELETE',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setSelectedDeleteSlice('');
        },
      }}
    >
      <Box py={2.5} px={4}>
        {/* {sliceInfo?.imageCount && sliceInfo?.imageCount > 0 ? (
          <Card
            style={{
              background: '#FFF8F8',
              padding: '8px',
              boxShadow: 'none',
              display: 'flex',
              marginBottom: '16px',
            }}
          >
            <Box mr={1}>
              <Icon name="warningOutline" size="13px" color={['primary', 600]} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex">
                <Typography variant="m-regular">
                  <Trans t={t} i18nKey={'curate.slices.deleteInfos'}>
                    <>There are </>
                    <Typography variant="m-medium" color="primary-400" style={{ margin: '0 2px' }}>
                      {{ imageCount: sliceInfo.imageCount.toLocaleString() }}
                    </Typography>
                    <>. When you delete this slice, all images are also deleted.</>
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </Card>
        ) : (
          <></>
        )} */}
        <Box display="flex" flexDirection="column" mb={1.5}>
          <Typography variant="m-regular" color="gray-400">
            <Trans t={t} i18nKey={'curate.slices.deleteWarnings'} />
          </Typography>
        </Box>
        <Input
          color="grey"
          placeholder={t('curate.dialogs.placeholder.confirm')}
          variant="filled"
          onChange={handleDeleteConfirmInput}
        />
      </Box>
    </Modal>
  );
}
