import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Reset } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

export default function ResetChip({
  onClickResetChip,
  count,
  style,
}: {
  onClickResetChip: () => void;
  count: number;
  style: CSSProperties;
}) {
  const { t } = useTranslation();
  return (
    <Box
      style={{ ...style, borderRadius: '10px', height: '18px' }}
      backgroundColor="primary-100"
      cursor="pointer"
      onClick={onClickResetChip}
      display="flex"
      alignItems="center"
      px={1}
      gap={0.5}
    >
      <Typography variant="s-regular">{t('curate.filter.appliedChip', { count })}</Typography>
      <Box style={{ width: '1px', height: '12px' }} backgroundColor="primary-300" />
      <Icon size="10px" icon={Reset} color="primary" />
    </Box>
  );
}
