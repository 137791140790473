import { meteringLogic, SerializedMeteringSelector } from './useMeteringQuery';

// Create a meteringLogic that checks if leftQuantity > minimum quantity or if onDemand is enabled.
function minimumQuantity<S extends SerializedMeteringSelector>(
  _selector: S, // the selector creates type safety (i.e. other metering values cannot be used)
  defaultMinimum: number, // the minimum to be used when no specific minimum value is passed
) {
  return meteringLogic<S, { minimum: number }>(
    ({ leftQuantity, onDemand }, v) => onDemand || leftQuantity >= (v?.minimum ?? defaultMinimum),
  );
}

export const ONE_CREDIT = 1000; // 1 AI Credit == 1000 units

export const hasSufficientUserSeats = minimumQuantity('common:user-seat', 1);
export const hasSufficientCurateVolume = minimumQuantity('curate:data-volume', 1);
export const hasSufficientLabelVolume = minimumQuantity('label:data-volume', 1);
export const hasSufficientModelEndpointVolume = minimumQuantity('model:endpoint', 1);
export const hasSufficientAiCredits = minimumQuantity('common:ai-credit', ONE_CREDIT);
