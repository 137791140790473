import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';

const getCurationHistory: AfterLoginCallback<
  any,
  { datasetId: string; showPublicDatasets?: boolean }
> = async params => {
  if (!params.data) return;
  const { datasetId, showPublicDatasets } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/auto-curate/${
      showPublicDatasets ? 'public-' : ''
    }datasets/${datasetId}/`,
    hasPublicApi: false,
    isCurateUrl: true,

    ...params,
  });

  return data;
};

const curationReportDownload: AfterLoginCallback<
  any,
  { jobId: string; resultType: string }
> = async params => {
  if (!params.data) return;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/curate/batch/jobs/${params.data.jobId}/additional-results/${params.data.resultType}`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });

  return data;
};

export function useAutoCurateService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getCurationHistory: afterLoginFetcher(getCurationHistory),
    curationReportDownload: afterLoginFetcher(curationReportDownload),
  };
}
