/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles';

import {
  COLOR_CHIP_TAG_10PERCENT_OPACITY,
  COLOR_CHIP_TAG_DEFAULT,
} from '../../../../../consts/ColorChips';

const { DUCK_EGG_BLUE, VERY_LIGHT_PINK, VERY_LIGHT_BLUE, OFF_WHITE_THREE } =
  COLOR_CHIP_TAG_10PERCENT_OPACITY;
const { KIWI } = COLOR_CHIP_TAG_DEFAULT;

const FONT_TYPE_INTER = 'Inter';

export const useCardStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    whiteSpace: 'nowrap',
    boxShadow: theme.shadows[2],
    alignItems: 'stretch',
    height: '38px',
    marginBottom: '17px',
    '&.last': {
      marginBottom: '0',
    },
  },
  percentCard: {
    borderRadius: '6px',
    backgroundColor: OFF_WHITE_THREE,
    padding: '7px',
  },
  totalCard: {
    borderRadius: '6px',
    transform: 'rotate(-25deg)',
    padding: '7px',
    zIndex: 1,
  },
  totalCardBackground: {
    borderRadius: '6px',
    backgroundColor: VERY_LIGHT_PINK,
    padding: '7px',
  },
  cardText: {
    paddingLeft: '7px',
    paddingBottom: '5px',
    '&.percent': {
      paddingBottom: '10px',
    },
  },
  submittedCard: {
    borderRadius: '6px',
    backgroundColor: DUCK_EGG_BLUE,
    padding: '7px',
  },
  dateCard: {
    borderRadius: '6px',
    backgroundColor: VERY_LIGHT_BLUE,
    padding: '7px',
  },
  iconGrid: {
    marginBottom: '2px',
  },
  cardTitle: {
    fontFamily: FONT_TYPE_INTER,
    fontSize: '11px',
    fontWeight: 500,
    color: '#979797',
  },
  cardValue: {
    fontFamily: FONT_TYPE_INTER,
    fontSize: '15px',
    fontWeight: 600,
    color: '#333333',
    '&.percent': {
      color: KIWI,
    },
  },
}));
