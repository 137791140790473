const getRight = (toastState: string): string => {
  switch (toastState) {
    case 'init':
    case 'close':
      return '-100vw';
    default:
      return '6em';
  }
};

const getBottom = (order: number): number => {
  const marginBottom = 20;
  const intervalHeight = 60;

  return marginBottom + intervalHeight * order;
};

const getIsCancelActive = (toastState: string): boolean => {
  switch (toastState) {
    case 'init':
    case 'startPrepare':
    case 'prepare':
    case 'endPrepare':
    case 'startProgress':
    case 'progress':
      return true;
    case 'endProgress':
    case 'cancel':
    case 'close':
    default:
      return false;
  }
};

const getIsCountVisible = (toastState: string): boolean => {
  switch (toastState) {
    case 'startProgress':
    case 'progress':
    case 'waitForTaskCompletion':
      return true;
    case 'init':
    case 'startPrepare':
    case 'prepare':
    case 'endPrepare':
    case 'endProgress':
    case 'cancel':
    case 'close':
    default:
      return false;
  }
};

export default {
  getRight,
  getBottom,
  getIsCancelActive,
  getIsCountVisible,
};
