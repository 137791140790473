import React, { useState } from 'react';

import { clamp } from 'lodash';

import ImageFilter from '../consts/ImageFilter';
import ImageUtils from '../utils/ImageUtils';
import { useLabelDetailViewInfo } from './LabelDetailViewContext';
import StageSizeContext from './StageSizeContext';
import { StateGetterSetter } from './types';

const ControlMethods = ['INCREASE', 'DECREASE', 'SET', 'RESET'] as const;
type ControlMethod = (typeof ControlMethods)[number];
interface ImageSize {
  x: number;
  y: number;
  width: number;
  height: number;
  rate: number;
}

// prettier-ignore
type ContextProps =
  StateGetterSetter<['image', 'setImage'], HTMLImageElement | null> &
  StateGetterSetter<['x', 'setX'], number> &
  StateGetterSetter<['y', 'setY'], number> &
  StateGetterSetter<['rate', 'setRate'], number> &
  StateGetterSetter<['width', 'setWidth'], number> &
  StateGetterSetter<['height', 'setHeight'], number> &
  {
    brightness: number;
    controlBrightness(method: ControlMethod, value?: number): void;
    contrast: number;
    controlContrast(method: ControlMethod, value?: number): void;
    setImageByUrl(source: string): void;
    adjustImage(): void;
    initialImageSize: ImageSize | null;
  };

const Context = React.createContext({} as ContextProps);

const ImageProvider: React.FC = ({ children }) => {
  const stageInfo = React.useContext(StageSizeContext.Context);
  const labelDetailViewInfo = useLabelDetailViewInfo();

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [rate, setRate] = useState(1);
  const [initialImageSize, setInitialImageSize] = useState<ImageSize | null>(null);
  const [brightness, setBrightness] = useState(ImageFilter.INIT_BRIGHTNESS);
  const [contrast, setContrast] = useState(ImageFilter.INIT_CONTRAST);

  const controlBrightness = (method: ControlMethod, value?: number) => {
    switch (method) {
      case 'INCREASE':
        setBrightness(
          clamp(
            brightness + ImageFilter.OFFSET_BRIGHTNESS,
            ImageFilter.MIN_BRIGHTNESS,
            ImageFilter.MAX_BRIGHTNESS,
          ),
        );
        break;
      case 'DECREASE':
        setBrightness(
          clamp(
            brightness - ImageFilter.OFFSET_BRIGHTNESS,
            ImageFilter.MIN_BRIGHTNESS,
            ImageFilter.MAX_BRIGHTNESS,
          ),
        );
        break;
      case 'SET':
        if (typeof value === 'number') {
          setBrightness(clamp(value, ImageFilter.MIN_BRIGHTNESS, ImageFilter.MAX_BRIGHTNESS));
        }
        break;
      case 'RESET':
        setBrightness(
          clamp(
            ImageFilter.INIT_BRIGHTNESS,
            ImageFilter.MIN_BRIGHTNESS,
            ImageFilter.MAX_BRIGHTNESS,
          ),
        );
        break;
      default:
        break;
    }
  };

  const controlContrast = (method: ControlMethod, value?: number) => {
    switch (method) {
      case 'INCREASE':
        setContrast(
          clamp(
            contrast + ImageFilter.OFFSET_CONTRAST,
            ImageFilter.MIN_CONTRAST,
            ImageFilter.MAX_CONTRAST,
          ),
        );
        break;
      case 'DECREASE':
        setContrast(
          clamp(
            contrast - ImageFilter.OFFSET_CONTRAST,
            ImageFilter.MIN_CONTRAST,
            ImageFilter.MAX_CONTRAST,
          ),
        );
        break;
      case 'SET':
        if (typeof value === 'number') {
          setContrast(clamp(value, ImageFilter.MIN_CONTRAST, ImageFilter.MAX_CONTRAST));
        }
        break;
      case 'RESET':
        setContrast(
          clamp(ImageFilter.INIT_CONTRAST, ImageFilter.MIN_CONTRAST, ImageFilter.MAX_CONTRAST),
        );
        break;
      default:
        break;
    }
  };

  const setImageByUrl = (source: string) => {
    const headers: Record<string, string> = {
      mode: 'cors',
      credentials: 'omit',
    };
    // Hotfix -> Temporary Presigned url check
    if (
      source?.includes &&
      !source.includes('suite-asset.superb-ai.com') &&
      !source.includes('stage-saba-asset.superb-ai.com') &&
      !source.includes('suite-asset.dev.superb-ai.com')
    ) {
      headers.cache = 'no-cache';
    }

    fetch(source, headers)
      .then(response => {
        const nextImage = new Image();
        nextImage.src = response.url;
        nextImage.crossOrigin = 'Anonymous';
        nextImage.onload = () => {
          const { image, x, y, width, height, rate, brightness, contrast } =
            ImageUtils.getInitImageContextInfo(nextImage, stageInfo, labelDetailViewInfo);
          setInitialImageSize({ x, y, width, height, rate });
          setImage(image);
          setX(x);
          setY(y);
          setRate(rate);
          // rate를 마지막에 set하면 이미지가 width, height 걸려있는 상태로 render 후 rate에 맞게 조정되어서
          // 흔들리는 현상이 생긴다.

          setWidth(width);
          setHeight(height);

          controlBrightness('SET', brightness);
          controlContrast('SET', contrast);
        };
      })
      // eslint-disable-next-line
      .catch(err => { });
  };

  const adjustImage = () => {
    if (!initialImageSize) return;
    setX(initialImageSize.x);
    setY(initialImageSize.y);
    setRate(initialImageSize.rate);
  };

  return (
    <Context.Provider
      value={{
        image,
        setImage,
        x,
        setX,
        y,
        setY,
        rate,
        setRate,
        width,
        setWidth,
        height,
        setHeight,
        brightness,
        controlBrightness,
        contrast,
        controlContrast,

        setImageByUrl,
        adjustImage,
        initialImageSize,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default {
  Context,
  Provider: ImageProvider,
};
