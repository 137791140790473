import React, { useEffect } from 'react';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';
import Layout from './Layout';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Index = (props: any): React.ReactElement => {
  const newProjectInfo = useNewProjectInfo();

  useEffect(() => {
    helper.updateObjectClasses(newProjectInfo);
    newProjectInfo.setSelectedObjectClassIndex(-1);
    newProjectInfo.setSelectedClassPropertyIndex(-1);

    // eslint-disable-next-line
  }, []);

  return <Layout {...props} />;
};

export default Index;
