import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Input } from '@superb-ai/norwegian-forest';
import { debounce } from 'lodash';

import { getUrl } from '../../routes/util';
import SearchBar from './SearchBar';

interface Props extends React.ComponentProps<typeof Input> {
  queryParam?: string;
}

export default function SearchInput(props: Props): JSX.Element {
  const { queryParam = 'search', ...otherProps } = props;
  const history = useHistory();
  const searchParams = Object.fromEntries(new URLSearchParams(history.location.search));
  const [query, setQuery] = useState(searchParams[queryParam] || '');

  const updateUrl = (query: string) => {
    history.push(
      getUrl(
        history.location.pathname,
        {},
        { ...searchParams, page: 1, [queryParam]: query || undefined },
      ),
    );
  };

  const updateUrlDebounced = debounce(updateUrl, 300);

  useEffect(() => {
    return () => {
      updateUrlDebounced.cancel();
    };
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setQuery(value);
    updateUrlDebounced(value);
  }

  return <SearchBar value={query} onChange={handleChange} {...otherProps} />;
}
