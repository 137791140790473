const imageFilter = {
  MIN_BRIGHTNESS: -1,
  MAX_BRIGHTNESS: 1,
  INIT_BRIGHTNESS: 0,
  OFFSET_BRIGHTNESS: 0.05,

  MIN_CONTRAST: -100,
  MAX_CONTRAST: 100,
  INIT_CONTRAST: 0,
  OFFSET_CONTRAST: 5,
};

export default imageFilter;
