import React from 'react';

import { CheckCircle, CheckFilled } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

interface Props {
  size?: string;
  color?: string;
  checked?: boolean;
  handleClick: (event: React.MouseEvent) => void;
}

const CheckRadio: React.FC<Props> = props => {
  const { size = '18px', color = '#ff625a', checked = false, handleClick } = props;

  return (
    <Icon
      icon={checked ? CheckCircle : CheckFilled}
      style={{ color: checked ? color : '#d9d9d9', width: size, height: size }}
      onClick={handleClick}
    />
  );
};

export default CheckRadio;
