import React, { useState } from 'react';

import { GeoJSONPolygon } from '../../../types/advancedAIFeaturesTypes';
import {
  MislabelDetectionAnnotationType,
  MislabelDetectionAnnotationTypeCoordinate,
} from '../../../types/mislabelDetectionTypes';

type Props = {
  alt: string;
  srcUrl: string;
  annotation?: {
    classId: string;
    type: MislabelDetectionAnnotationType;
    coordinate: MislabelDetectionAnnotationTypeCoordinate<MislabelDetectionAnnotationType>;
    annotationId: string;
  };
  style?: Record<string, string>;
  annotationColorMap?: Record<string, string>;
};
export default function SingleAnnotatedImage({
  annotation,
  alt,
  srcUrl,
  style,
  annotationColorMap,
}: Props) {
  const [imageSizeRate, setImageSizeRate] = useState<number>();
  const [imageOffset, setImageOffset] = useState<[number, number]>(); // [offsetLeft, offsetTop]

  const drawAnnotation = () => {
    if (!annotation || !imageSizeRate || !imageOffset) return;
    if (annotation.type === 'box' && annotation.coordinate) {
      const coordinate = annotation.coordinate as MislabelDetectionAnnotationTypeCoordinate<'box'>;
      return (
        <rect
          key={annotation.coordinate.map(e => e.toString()).join(',')}
          x={coordinate[0] * imageSizeRate + imageOffset[0]}
          y={coordinate[1] * imageSizeRate + imageOffset[1]}
          width={coordinate[2] * imageSizeRate || 1}
          height={coordinate[3] * imageSizeRate || 1}
          style={{
            stroke: annotationColorMap ? annotationColorMap[annotation.classId] : '#05FF00',
            strokeWidth: '2px',
            fill: '#05FF0022',
          }}
        />
      );
    }
    const coordinate =
      annotation.coordinate as MislabelDetectionAnnotationTypeCoordinate<'polygon'>;

    return (coordinate as GeoJSONPolygon).map((polygon, index) => {
      return (
        <path
          key={`${annotation.annotationId}-${index}`}
          fillRule="evenodd"
          d={polygon.reduce(
            (polygonAcc, polygonAndHoles) =>
              polygonAcc +
              'M' +
              polygonAndHoles.reduce(
                (acc, paths, index) =>
                  acc +
                  `${paths.x * imageSizeRate + imageOffset[0]} ${
                    paths.y * imageSizeRate + imageOffset[1]
                  }${index === polygonAndHoles.length - 1 ? 'Z ' : ' '}`,
                '',
              ),
            '',
          )}
          style={{
            stroke: annotationColorMap ? annotationColorMap[annotation.classId] : '#05FF00',
            strokeWidth: '2px',
            fill: '#05FF0022',
          }}
        />
      );
    });
  };

  return (
    <>
      <img
        alt={alt}
        src={srcUrl}
        style={
          style || {
            maxWidth: '100%',
            maxHeight: '100%',
          }
        }
        onLoad={event => {
          const target = event.target as HTMLImageElement;
          const naturalImageWidth = target.naturalWidth;
          const clientImageWidth = target.clientWidth;
          setImageSizeRate(clientImageWidth / naturalImageWidth);

          const imageOffsetLeft = target.offsetLeft;
          const imageOffsetTop = target.offsetTop;
          setImageOffset([imageOffsetLeft, imageOffsetTop]);
        }}
      />
      {annotation && annotation.coordinate && imageSizeRate && imageOffset && (
        <svg
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
        >
          {drawAnnotation()}
        </svg>
      )}
    </>
  );
}
