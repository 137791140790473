import React from 'react';
import { useTranslation } from 'react-i18next';

import { Check, Checkbox, CheckboxChecked, ChevronDown } from '@superb-ai/icons';
import { Box, Dropdown, Icon, SelectList, Typography } from '@superb-ai/ui';
import { compact } from 'lodash';

import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import {
  SUPER_CLUSTER_COLORS_8,
  SUPER_CLUSTER_COLORS_16,
} from '../../views/embedding/scatterView/const';
import { CLUSTER_LEVELS, ClusterLevel } from '../types';

function Circle({ color, stroked }: { color: string; stroked: boolean }) {
  return (
    <Box
      borderRadius="100%"
      border="1px solid"
      cursor="pointer"
      borderColor="gray"
      style={{
        backgroundColor: color,
        width: '8px',
        height: '8px',
        ...(!stroked && { borderColor: color }),
      }}
    />
  );
}

export default function SuperClusterLevelSelector() {
  const { t } = useTranslation();
  const {
    clusterLevel,
    setClusterLevel,
    imageClusters,
    selectedSuperClusters,
    setSelectedSuperClusters,
    handleSelectSuperCluster,
    handleSelectAllSuperClusters,
    setShowSelectedOnly,
  } = useImageFilterContext();

  function ClusterLevelIndicator({ clusterLevel }: { clusterLevel: ClusterLevel }) {
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        {(clusterLevel === '2'
          ? SUPER_CLUSTER_COLORS_8
          : clusterLevel === '3'
          ? SUPER_CLUSTER_COLORS_16
          : []
        ).map(color => (
          <Circle color={color} stroked={false} key={`8-${color}`} />
        ))}
      </Box>
    );
  }

  const dataList = [
    {
      label: (
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
          <ClusterLevelIndicator clusterLevel="2" />
          {CLUSTER_LEVELS['2']}
        </Box>
      ),
      value: '2',
    },
    {
      label: (
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
          <ClusterLevelIndicator clusterLevel="3" />
          {CLUSTER_LEVELS['3']}
        </Box>
      ),
      value: '3',
    },
  ];

  function ClusterSelector({ id, color, share }: { id: string; color: string; share: number }) {
    return (
      <Box
        position="relative"
        backgroundColor={{ default: 'gray-100', hover: 'gray-150' }}
        cursor="pointer"
        py={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={0.5}
        onClick={() => {
          handleSelectSuperCluster(id);
          setShowSelectedOnly(true);
        }}
      >
        <Circle color={color} stroked={selectedSuperClusters?.includes(id)} />
        <Typography variant="s-regular">{share} %</Typography>
        {selectedSuperClusters?.includes(id) && (
          <Box
            position="absolute"
            backgroundColor="gray-opacity-250"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon color="white" icon={Check} />
          </Box>
        )}
      </Box>
    );
  }

  const superClusters = imageClusters?.filter(cluster => cluster.id !== null);
  const isAllSuperClustersSelected =
    (superClusters?.length || 0) === compact(selectedSuperClusters || []).length;

  return (
    <Dropdown
      fixed
      useDefaultDisclosureSetup={false}
      disclosure={
        <Box>
          <Box
            borderRadius="9999px"
            backgroundColor="gray-100"
            border="1px solid"
            borderColor="gray-200"
            py={0.5}
            pl={1}
            pr={0.5}
            cursor="pointer"
            display="flex"
            alignItems="center"
            gap={0.5}
          >
            <ClusterLevelIndicator clusterLevel={clusterLevel} />
            <Icon icon={ChevronDown} color="gray-400" />
          </Box>
        </Box>
      }
    >
      <Box
        backgroundColor="white"
        borderRadius="2px"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
        py={0.5}
      >
        <SelectList
          data={dataList}
          value={clusterLevel}
          onChangeValue={value => {
            setClusterLevel(value);
            setSelectedSuperClusters([]);
          }}
        />

        <Box
          borderTop="1px solid"
          borderColor="gray-150"
          backgroundColor={{ default: 'gray-100', hover: 'gray-150' }}
          py={1}
          px={1.5}
          display="flex"
          alignItems="center"
          gap={0.5}
          cursor="pointer"
          onClick={() => {
            handleSelectAllSuperClusters(
              superClusters.map(cluster => cluster.id),
              !isAllSuperClustersSelected,
            );
            setShowSelectedOnly(true);
          }}
        >
          <Icon
            color={isAllSuperClustersSelected ? 'primary' : 'gray'}
            icon={isAllSuperClustersSelected ? CheckboxChecked : Checkbox}
          />
          <Typography variant="m-regular">{t('curate.filter.selectAll')}</Typography>
        </Box>
        <Box
          borderTop="1px solid"
          borderColor="gray-150"
          backgroundColor="gray-150"
          display="grid"
          style={{ gridTemplateColumns: 'repeat(4, 1fr)', gap: '1px' }}
        >
          {superClusters.map((cluster, index) => (
            <ClusterSelector
              id={cluster.id}
              color={cluster.color}
              share={cluster.share}
              key={cluster.id}
            />
          ))}
        </Box>
        {/* <Box
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="gray-150"
          py={1}
          px={1.5}
          display="flex"
          alignItems="center"
          gap={0.5}
          cursor="pointer"
          backgroundColor={{ hover: 'gray-100' }}
          onClick={() => {
            handleToggleVisibility(null);
          }}
        >
          <Icon icon={visibleSuperClusters?.includes(null) ? Visible : VisibleOff} />
          <Icon icon={UnavailableSmall} color="gray-300" />
          <Typography variant="m-regular">{t('curate.filter.unprocessed')}</Typography>
          <Typography variant="m-regular" style={{ marginLeft: 'auto' }}>
            {imageClusters[imageClusters.length - 1]?.share || 0} %
          </Typography>
        </Box> */}
      </Box>
    </Dropdown>
  );
}
