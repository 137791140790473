import {
  CURATE_WHAT_TO_LABEL,
  CurationType,
  EmbeddingType,
  FIND_EDGE_CASES,
  FIND_MISLABELS,
  SPLIT_TRAIN_VAL,
} from '../../../consts/AutoCurateConst';
import {
  ClusterLevel,
  FilterSchema,
  ProjectionInParam,
} from '../components/datasets/dataset/filter/types';
import { EvaluationFilterSchemaSnakeCase } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import { DownloadAnnotationFormat, DownloadType } from './downloadTypes';
import { Split } from './evaluationTypes';
import { NestedKeysToCamelCase } from './typeUtils';

export const IMAGE_IMPORT = 'IMAGE_IMPORT';
export const ANNOTATION_IMPORT = 'ANNOTATION_IMPORT';
export const UPDATE_SLICE = 'UPDATE_SLICE';
export const UPDATE_SLICE_BY_QUERY = 'UPDATE_SLICE_BY_QUERY';
export const UPDATE_SLICE_BY_EVALUATIONS = 'UPDATE_SLICE_BY_EVALUATIONS';
export const UPDATE_SLICE_BY_ANNOTATIONS = 'UPDATE_SLICE_BY_ANNOTATIONS';
export const DELETE_IMAGES = 'DELETE_IMAGES';
export const DELETE_IMAGES_BY_QUERY = 'DELETE_IMAGES_BY_QUERY';
export const AUTO_CURATE = 'AUTO_CURATE';
export const IMPORT_FROM_LABELING = 'IMPORT_FROM_LABELING';
export const UPDATE_IMAGE_SCATTER = 'UPDATE_IMAGE_SCATTER';
export const UPDATE_ANNOTATION_SCATTER = 'UPDATE_ANNOTATION_SCATTER';
export const START_DIAGNOSIS = 'START_DIAGNOSIS';
export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS';
export const DEACTIVATE_DIAGNOSIS = 'DEACTIVATE_DIAGNOSIS';
export const CLOUD_UPLOAD = 'CLOUD_UPLOAD';
export const GENERATE_SYNTHETIC_IMAGES = 'GENERATE_SYNTHETIC_IMAGES';
export const CLOUD_ANNOTATION_UPLOAD = 'CLOUD_ANNOTATION_UPLOAD';
export const PREPARE_DOWNLOAD = 'PREPARE_DOWNLOAD';

export const CommandTypes = [
  IMAGE_IMPORT,
  ANNOTATION_IMPORT,
  UPDATE_SLICE,
  UPDATE_SLICE_BY_QUERY,
  UPDATE_SLICE_BY_EVALUATIONS,
  UPDATE_SLICE_BY_ANNOTATIONS,
  DELETE_IMAGES,
  DELETE_IMAGES_BY_QUERY,
  AUTO_CURATE,
  IMPORT_FROM_LABELING,
  UPDATE_IMAGE_SCATTER,
  UPDATE_ANNOTATION_SCATTER,
  START_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
  DEACTIVATE_DIAGNOSIS,
  CLOUD_UPLOAD,
  GENERATE_SYNTHETIC_IMAGES,
  CLOUD_ANNOTATION_UPLOAD,
  PREPARE_DOWNLOAD,
] as const;

export type CommandType = (typeof CommandTypes)[number];
export const UncancellableCommandTypes = [
  START_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
  DEACTIVATE_DIAGNOSIS,
  GENERATE_SYNTHETIC_IMAGES,
  AUTO_CURATE,
];
export const UnretriableCommandTypes = [
  START_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
  DEACTIVATE_DIAGNOSIS,
  GENERATE_SYNTHETIC_IMAGES,
  AUTO_CURATE,
];

export const commandStatuses = [
  'PENDING',
  'IN_PROGRESS',
  'COMPLETE',
  'CANCELING',
  'CANCELED',
  'FAILED',
] as const;
export type CommandStatus = (typeof commandStatuses)[number];

type EmptyObject = Record<string, never>;
export interface ImagesJobImagesFieldParam {
  slice?: string;
  query?: string;
  projection_in?: ProjectionInParam;
  cluster_level?: ClusterLevel;
  cluster_id_in?: string[];
}

export interface UploadImagesJobParam {
  dataset_id: string;
  images: { param_id: string };
}
export interface UploadAnnotationsJobParam {
  dataset_id: string;
  annotations: { param_id: string };
}

export interface ImportFromLabelJobParam {
  correlation_id: string;
  dataset_id: string;
  filter: string;
  policy: 'ALL';
  project_id: string;
  slice_id: string;
}

export interface DeleteImagesJobParam {
  dataset_id: string;
  images: {
    ids: string[];
    keys: [];
    param_id: string;
  };
}

export interface DeleteImagesByQueryJobParam {
  dataset_id: string;
  image_filters: ImagesJobImagesFieldParam | EmptyObject;
}

export interface UpdateSliceJobParam {
  dataset_id: string;
  images: {
    slice?: string;
    ids: string[];
    keys?: never[];
    param_id: string;
  };
  slice_id: string;
  remove?: boolean;
}

export interface UpdateSliceByQueryJobParam {
  dataset_id: string;
  images: ImagesJobImagesFieldParam | EmptyObject;
  slice_id: string;
  remove?: boolean;
}

export type SendToCurateJobParam = {
  dataset_id: string;
  slice_id: string;
  project_id: string;
  filter: string;
  correlation_id: string;
  policy: 'ALL' | 'ONLY_UPDATE';
};

export type AutoCurateTrainValJobParam = {
  num_val_images: number;
  val_slice_id: string;
  train_slice_id: string;
  mislabel_slice_id?: string;
  annotation_classes: string[];
};

export type AutoCurateMislabelsJobParam = {
  result_slice_id: string;
  annotation_classes: string[];
};

export type AutoCurateToLabelOrEdgeCaseParam = {
  embedding_type: EmbeddingType;
  num_images: number;
  result_slice_id: string;
  annotation_classes: string[];
};

// prettier-ignore
export type AutoCurateConfigParam<T extends CurationType> =
  T extends typeof CURATE_WHAT_TO_LABEL ? AutoCurateToLabelOrEdgeCaseParam:
  T extends typeof SPLIT_TRAIN_VAL ? AutoCurateTrainValJobParam:
  T extends typeof FIND_EDGE_CASES ? AutoCurateToLabelOrEdgeCaseParam :
  T extends typeof FIND_MISLABELS ? AutoCurateMislabelsJobParam :
  never;

export type AutoCurateJobParam<T extends CurationType> = {
  dataset_id: string;
  curation_type?: T;
  config: AutoCurateConfigParam<T>;
  images: ImagesJobImagesFieldParam | EmptyObject;
};

export type UpdateImageScatterParam = {
  dataset_id: string;
};

export type UpdateAnnotationScatterParam = {
  dataset_id: string;
};

export type DiagnosisStartOrUpdateJobParam = {
  dataset_id: string;
  diagnosis_id: string;
  model_id: string;
  annotation_type: string;
  model_source: string;
  skip_train?: boolean;
};

export type DeactivateDiagnosisJobParam = {
  dataset_id: string;
  diagnosis_id: string;
  model_id: string;
  delete_diagnosis?: boolean;
};

export type UpdateSliceByEvaluationParam = {
  dataset_id: string;
  slice_id: string;
  evaluation_filters: EvaluationFilterSchemaSnakeCase & {
    split_in?: Split[];
    diagnosis_id: string;
  };
};

export type UpdateSliceByAnnotationParam = {
  dataset_id: string;
  slice_id: string;
  annotation_filters: FilterSchema & { query?: string };
  remove?: boolean;
};

export type CloudUploadParam = {
  dataset_id: string;
  integration_id: string;
  slice_id?: string;
  prefix?: string;
  include_annotations?: boolean;
};

export type CloudAnnotationUploadParam = {
  dataset_id: string;
  integration_id: string;
  prefix?: string;
};

export type GenerateSyntheticImagesParam = {
  model_id: string;
  generation_rule: { multiple: number };
  dataset_id: string;
  image_filters: ImagesJobImagesFieldParam | EmptyObject;
  destination_slice_id: string;
};

export type PrepareDownloadParam = {
  dataset_id: string;
  download_name: string;
  download_type: DownloadType;
  download_format: DownloadAnnotationFormat;
  image_filters: ImagesJobImagesFieldParam | EmptyObject;
};

// prettier-ignore
export type CurateJobParams<T extends CommandType> =
  T extends typeof IMAGE_IMPORT ? UploadImagesJobParam:
  T extends typeof ANNOTATION_IMPORT ? UploadAnnotationsJobParam:
  T extends typeof IMPORT_FROM_LABELING ? ImportFromLabelJobParam :
  T extends typeof DELETE_IMAGES ? DeleteImagesJobParam:
  T extends typeof DELETE_IMAGES_BY_QUERY ? DeleteImagesByQueryJobParam:
  T extends typeof UPDATE_SLICE ? UpdateSliceJobParam :
  T extends typeof UPDATE_SLICE_BY_QUERY ? UpdateSliceByQueryJobParam :
  T extends typeof UPDATE_SLICE_BY_EVALUATIONS ? UpdateSliceByEvaluationParam : //
  T extends typeof UPDATE_SLICE_BY_ANNOTATIONS ? UpdateSliceByAnnotationParam : //
  T extends typeof AUTO_CURATE ? AutoCurateJobParam<CurationType> :
  T extends typeof UPDATE_IMAGE_SCATTER ? UpdateImageScatterParam :
  T extends typeof UPDATE_ANNOTATION_SCATTER ? UpdateAnnotationScatterParam :
  T extends typeof START_DIAGNOSIS ? DiagnosisStartOrUpdateJobParam :
  T extends typeof UPDATE_DIAGNOSIS ? DiagnosisStartOrUpdateJobParam :
  T extends typeof DEACTIVATE_DIAGNOSIS ? DeactivateDiagnosisJobParam :
  T extends typeof CLOUD_UPLOAD ? CloudUploadParam :
  T extends typeof GENERATE_SYNTHETIC_IMAGES ? GenerateSyntheticImagesParam :
  T extends typeof CLOUD_ANNOTATION_UPLOAD ? CloudAnnotationUploadParam :
  T extends typeof PREPARE_DOWNLOAD ? PrepareDownloadParam :
  never;

export interface Command<T extends CommandType> {
  createdAt: string;
  createdBy: string;
  id: string;
  jobType: T;
  paramId: string;
  progress: number;
  result: {
    affectedCount?: number;
    failCount: number;
    failDetail: Record<string, number>;
    successCount: number;
  };
  param: NestedKeysToCamelCase<CurateJobParams<T>>;
  failReason?: string;
  status: CommandStatus;
  totalCount: number;
  updatedAt: string;
  logDownloadUrl?: string;
}

export function hasSnakeParamSliceId(
  param: any,
): param is UpdateSliceByEvaluationParam | CloudUploadParam | UpdateSliceByQueryJobParam {
  return typeof param.slice_id === 'string';
}

export function hasParamSliceId(
  param: any,
): param is NestedKeysToCamelCase<
  UpdateSliceByEvaluationParam | CloudUploadParam | UpdateSliceByQueryJobParam
> {
  return typeof param.sliceId === 'string';
}

export function hasParamDestinationSliceId(
  param: any,
): param is NestedKeysToCamelCase<GenerateSyntheticImagesParam> {
  return typeof param.destinationSliceId === 'string';
}

export function hasParamEvaluationFiltersDiagnosisId(
  param: any,
): param is NestedKeysToCamelCase<UpdateSliceByEvaluationParam> {
  return param.evaluationFilters && param.evaluationFilters.diagnosisId;
}

export function hasParamDiagnosisId(
  param: any,
): param is NestedKeysToCamelCase<DiagnosisStartOrUpdateJobParam | DeactivateDiagnosisJobParam> {
  return typeof param.diagnosisId === 'string';
}

export function hasParamProjectId(
  param: any,
): param is NestedKeysToCamelCase<SendToCurateJobParam | ImportFromLabelJobParam> {
  return typeof param.projectId === 'string';
}

export function isAutoCurateTrainValParam(
  param: any,
): param is NestedKeysToCamelCase<AutoCurateJobParam<typeof SPLIT_TRAIN_VAL>> {
  return param.curationType === SPLIT_TRAIN_VAL;
}

export function isAutoCurateMislabelsParam(
  param: any,
): param is NestedKeysToCamelCase<AutoCurateJobParam<typeof FIND_MISLABELS>> {
  return param.curationType === FIND_MISLABELS;
}

export function isAutoCurateToLabelOrEdgeCaseParam(
  param: any,
): param is NestedKeysToCamelCase<
  AutoCurateJobParam<typeof CURATE_WHAT_TO_LABEL> | AutoCurateJobParam<typeof FIND_EDGE_CASES>
> {
  return param.curationType === CURATE_WHAT_TO_LABEL || param.curationType === FIND_EDGE_CASES;
}
