import React from 'react';

import { LinkTypography, Typography } from '@superb-ai/ui';
import { History } from 'history';
import { TFunction, Trans } from 'next-i18next';

export default function AutoCurateAdditionalInfo({
  t,
  history,
  onClickLink,
  accountName,
  datasetId,
  autoCurateSlices,
}: {
  t: TFunction;
  history: History<unknown>;
  onClickLink?: () => void;
  accountName: string;
  datasetId: string;
  autoCurateSlices?: Record<'train' | 'val' | 'mislabel' | 'result', { id: string; name: string }>;
}) {
  if (!autoCurateSlices) return <></>;
  return (
    <ul>
      {Object.entries(autoCurateSlices).map(([key, value]) => {
        return (
          <li key={value.id}>
            <Typography variant="s-regular">
              <Trans
                t={t}
                i18nKey={`curate.bulkActions.messages.autoCurate.${key}`}
                values={{ sliceName: value.name }}
                components={{
                  link1: (
                    <LinkTypography
                      color="secondary"
                      onClick={e => {
                        history.push(
                          `/${accountName}/curate/dataset/${datasetId}/slice/${value.id}`,
                          new Date(),
                        );
                        onClickLink && onClickLink();
                        e.stopPropagation();
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </li>
        );
      })}
    </ul>
  );
}
