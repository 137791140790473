import { useEffect, useState } from 'react';

import { Box } from '@superb-ai/ui';
import Color from 'color';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';

export default function ImageCell({
  columnIndex,
  rowIndex,
  results,
  columns,
}: CellComponentProps<{}>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const [imageSize, setImageSize] = useState<[number, number]>(); // [width, height]
  const result = results[currentIndex];

  useEffect(() => {
    if (!result?.imageThumbnailUrl) return;
    const img = new Image();
    img.src = result.imageThumbnailUrl;
    img.onload = function (onLoadResult) {
      const img = onLoadResult.target as HTMLImageElement;
      setImageSize([img.naturalWidth, img.naturalHeight]);
    };
  }, []);

  if (!imageSize || !result?.imageThumbnailUrl) return <></>;
  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      backgroundColor="gray-100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        outline: `1px solid ${Color(result.color).hex()}`,
        outlineOffset: '-1px',
      }}
    >
      <Box
        position="absolute"
        style={{
          right: '4px',
          top: '4px',
          width: '6px',
          height: '6px',
          backgroundColor: Color(result.color).hex(),
        }}
        mr="auto"
        borderRadius="4px"
      ></Box>
      <img
        alt={result.image_id}
        src={result.imageThumbnailUrl}
        style={{
          ...(imageSize[0] / imageSize[1] < 16 / 9 ? { height: '100%' } : { width: '100%' }),
        }}
      />
    </Box>
  );
}
