import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ValueType from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { WindowedMenuList } from 'react-windowed-select';

import { Box, Select } from '@superb-ai/norwegian-forest';
import { some } from 'lodash';

import { isOwnerOrAdmin, useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUsersQuery } from '../../../../queries/useUsers';
import { MemberData, Role } from '../../../../types/memberTypes';
import { Option } from '../../../../types/selectTypes';
import RegexUtils from '../../../../utils/RegexUtils';
import UserUtils from '../../../../utils/UserUtils';
import UserAvatar from '../../UserAvatar';
import { extractEmails, extractLastString } from './utils';

type Props = {
  membersToAdd: Record<Role, string[]> | undefined;
  selectedMemberEmails: string[];
  setSelectedMemberEmails: Dispatch<SetStateAction<string[]>>;
  currentMembers: MemberData[];
  placeholder?: string;
};

const customFilter = (
  {
    value,
    label,
  }: FilterOptionOption<{
    value: string;
    label: string;
  }>,
  inputValue: string,
) => {
  const query = inputValue.toLowerCase();
  const isIncludesByQuery = (target: string) => target.toLowerCase().includes(query);
  return isIncludesByQuery(value) || isIncludesByQuery(label);
};

const MembersSelect = ({
  membersToAdd,
  currentMembers,
  selectedMemberEmails,
  setSelectedMemberEmails,
  placeholder = 'email1@example.com, email2@example.com, ...',
}: Props) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { urlMatchInfo } = useRouteInfo();
  const { admins, owners } = useProjectInfo();

  const [collaborators, setCollaborators] = useState<MemberData[]>([]);
  const [emailInput, setEmailInput] = useState('');

  const selectedValues = selectedMemberEmails.map(email => ({ value: email, label: email }));
  const hasMenu = !!urlMatchInfo.projectId && isOwnerOrAdmin(authInfo);
  const totalMembersArray = membersToAdd ? Object.values(membersToAdd).flat(1) : [];
  const { data: usersData } = useUsersQuery({
    params: { asList: '', additionalFields: ['project_list'] },
    statusIn: [],
  });

  const getSelectedMembers = (emails: string[]) => {
    const nextSelectedMembers = [...selectedMemberEmails];

    for (let i = 0; i < emails.length; i++) {
      if (!nextSelectedMembers.includes(emails[i])) {
        nextSelectedMembers.push(emails[i]);
      }
    }

    setSelectedMemberEmails(nextSelectedMembers);
  };

  const addSelectedMembers = () => {
    if (!RegexUtils.IS_EMAIL(emailInput)) return;
    getSelectedMembers([emailInput]);
    setEmailInput('');
  };

  const handleChangeInput = (inputValue: string) => {
    const isPaste = inputValue.length - emailInput.length > 1;

    if (isPaste) {
      const emails = extractEmails(inputValue);
      const lastString = extractLastString(inputValue);

      if (RegexUtils.IS_EMAIL(lastString)) {
        emails.push(lastString);
        setEmailInput('');
      } else {
        setEmailInput(lastString || '');
      }

      if (emails.length !== 0) getSelectedMembers(emails);
      return;
    }

    if (some(inputValue, char => char === ' ' || char === ',' || char === ';')) {
      addSelectedMembers();
      return;
    }

    setEmailInput(inputValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = e;
    if (key === 'Enter') {
      addSelectedMembers();
      e.preventDefault();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    addSelectedMembers();
    e.preventDefault();
  };

  useEffect(() => {
    if (!usersData) return;
    const collaborators = UserUtils.filterByRole(usersData, 'collaborator');
    setCollaborators(collaborators);
  }, []);

  const collaboratorsMap = collaborators.reduce((acc, col) => {
    return { ...acc, [col.email]: col };
  }, {}) as Record<string, MemberData>;

  const options = hasMenu
    ? collaborators.flatMap(collaborator => {
        if (
          currentMembers.findIndex(member => member.email === collaborator.email) < 0 &&
          totalMembersArray.findIndex(member => member === collaborator.email) < 0
        )
          return [
            {
              value: collaborator.email,
              label: collaborator.name,
            },
          ];

        return [];
      })
    : [];

  const hasFilteredOptions = useMemo(
    () =>
      options?.some(option =>
        customFilter(option as FilterOptionOption<{ label: string; value: string }>, emailInput),
      ),
    [emailInput],
  );

  //@ts-ignore
  const handleAddOption = (newValue: ValueType<{ value: string; label: string }, true>) => {
    setSelectedMemberEmails(
      newValue
        ? //@ts-ignore
          newValue.flatMap((value: SelectOptionBase) => {
            if (
              value.__isNew__ &&
              (!RegexUtils.IS_EMAIL(value.value) ||
                admins.findIndex((member: { email: any }) => member.email === value.value) >= 0 ||
                owners[0].email === value.value)
            )
              return [];
            return [value.value];
          })
        : [],
    );
  };

  const selectProps = hasMenu
    ? {
        options,
        getOptionLabelAdornment: (option: Option) => (
          <UserAvatar size={16} userInfo={collaboratorsMap[option.value]} noShadow />
        ),
        getOptionLabel: (option: Option) => `${option.label} (${option.value})`,
        createLabelPhrase: t('invite.button.invite'),
        components: { MenuList: WindowedMenuList },
      }
    : {
        onKeyDown: handleKeyDown,
        menuIsOpen: false,
        components: { DropdownIndicator: null },
      };

  return (
    <Box width={340}>
      <Select.Creatable
        {...selectProps}
        isMulti
        isClearable
        variant="fill"
        onChange={handleAddOption}
        onInputChange={handleChangeInput}
        onBlur={handleBlur}
        value={selectedValues}
        inputValue={emailInput}
        placeholder={placeholder}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        isValidNewOption={() => !!emailInput && !hasFilteredOptions}
      />
    </Box>
  );
};

export default MembersSelect;
