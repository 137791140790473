import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { RouteConfig } from '../../../../routes/types';
const Layout = lazy(() => import('./Layout'));

export interface Params {
  assetId: string;
}

const DataDetailRoute: RouteConfig<Params> = ({ match }) => (
  <Route path={match.path} component={Layout} />
);
DataDetailRoute.path = ':assetId';

export { DataDetailRoute };
