import React, { FC, useState } from 'react';

import { format } from 'date-fns';

import { StateGetterSetter } from './types';

// prettier-ignore
export type ExportsAnalyticsContextProps =
  StateGetterSetter<['isModalOpen', 'setIsModalOpen'], boolean> &
  StateGetterSetter<['isStepsCompleted', 'setIsStepsCompleted'], boolean> &
  StateGetterSetter<['selectedCompareExportId', 'setSelectedCompareExportId'], string> &
  StateGetterSetter<['selectedBaseExportId', 'setSelectedBaseExportId'], string> &
  StateGetterSetter<['boxValue', 'setBoxValue'],  number> &
  StateGetterSetter<['polygonValue', 'setPolygonValue'], number> &
  StateGetterSetter<['reportName', 'setReportName'], string> &
  StateGetterSetter<['ioUOption', 'setIoUOption'], IOUOption> &
  StateGetterSetter<['confirmation', 'setConfirmation'], 'close' | 'cancel' | undefined> &
  StateGetterSetter<['recommededOption', 'setRecommededOption'], RecommendedOption> &
  StateGetterSetter<['selectedCompareExport', 'setSelectedCompareExport'], Record<string, any>> &
  StateGetterSetter<['selectedBaseExport', 'setSelectedBaseExport'], Record<string, any>> &
  { initialize: () => void };

export type IOUOption = 'Recommended' | 'Custom' | '';
export type RecommendedOption = 'High-quality Labeling' | 'Model Evaluation';
export const ExportsAnalyticsContext = React.createContext({} as ExportsAnalyticsContextProps);

export const ExportsAnalyticsProvider: FC = ({ children }) => {
  const defaultName = `Export_Comparison_Report_${format(new Date(), 'yyyy-MM-dd H:mm:ss')}`;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isStepsCompleted, setIsStepsCompleted] = useState<boolean>(false);
  const [selectedCompareExportId, setSelectedCompareExportId] = useState<string>('');
  const [selectedBaseExportId, setSelectedBaseExportId] = useState<string>('');
  const [boxValue, setBoxValue] = useState<number>(0.8);
  const [polygonValue, setPolygonValue] = useState<number>(0.9);
  const [confirmation, setConfirmation] = useState<'close' | 'cancel'>();
  const [reportName, setReportName] = useState(defaultName);
  const [ioUOption, setIoUOption] = useState<IOUOption>('');
  const [recommededOption, setRecommededOption] =
    useState<RecommendedOption>('High-quality Labeling');
  const [selectedCompareExport, setSelectedCompareExport] = useState<Record<string, any>>({});
  const [selectedBaseExport, setSelectedBaseExport] = useState<Record<string, any>>({});

  const initialize = () => {
    setIsModalOpen(false);
    setIsStepsCompleted(false);
    setBoxValue(0.8);
    setPolygonValue(0.9);
    setSelectedBaseExportId('');
    setSelectedCompareExportId('');
    setReportName(defaultName);
    setIoUOption('Recommended');
    setSelectedCompareExport({});
    setSelectedBaseExport({});
    setConfirmation(undefined);
  };

  return (
    <ExportsAnalyticsContext.Provider
      value={{
        initialize,
        setIsModalOpen,
        isModalOpen,
        confirmation,
        setConfirmation,
        isStepsCompleted,
        setIsStepsCompleted,
        setSelectedCompareExportId,
        selectedCompareExportId,
        setSelectedBaseExportId,
        selectedBaseExportId,
        setBoxValue,
        boxValue,
        setPolygonValue,
        polygonValue,
        setReportName,
        reportName,
        setIoUOption,
        ioUOption,
        setRecommededOption,
        recommededOption,
        setSelectedCompareExport,
        selectedCompareExport,
        setSelectedBaseExport,
        selectedBaseExport,
      }}
    >
      {children}
    </ExportsAnalyticsContext.Provider>
  );
};

export const useExportsAnalyticsInfo = (): ExportsAnalyticsContextProps => {
  return React.useContext(ExportsAnalyticsContext);
};
