import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Typography, vars } from '@superb-ai/ui';

import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useCurateDatasetService } from '../../../../services/DatasetService';
import { IAdvancedQuery, ISelectedQuery, ITotalSchema } from '../../../../types/querySchemaTypes';
import AddGroup from './AddGroup';
import OperatorBox from './OperatorBox';
import QueryBoxEvent from './QueryBoxEvent';
import QueryOperatorMenu from './QueryOperatorMenu';
import QuerySchemasKeyMenu from './QuerySchemasKeyMenu';
import QueryTypeMenu from './QueryTypeMenu';
import QueryValueMenu from './QueryValueMenu';

export default function QueryBox({
  advancedQuery,
  depth,
  boxNum,
  anchorEl,
  setAnchorEl,
  handleOpen,
  updateQuery,
  updateQueryGroup,
  deleteQuery,
  turnIntoFilter,
  wrapInGroup,
  updateOperator,
  updateQueryContent,
  selectedQueryList,
  setSelectedQueryList,
  handleQueryValue,
  duplicateQuery,
}: {
  advancedQuery: IAdvancedQuery;
  depth: number;
  boxNum: number;
  anchorEl: string | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<string | null>>;
  handleOpen: (e: React.MouseEvent, id: string) => void;
  updateQuery: (boxNum: number) => void;
  updateQueryGroup: (boxNum: number) => void;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  turnIntoFilter: (boxNum: number, listIndex: number) => void;
  wrapInGroup: (boxNum: number, listIndex: number) => void;
  updateOperator: (boxNum: number, operator: string) => void;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  handleQueryValue: (value: string | string[], boxNum: number, index: number) => void;
  duplicateQuery: (boxNum: number, listIndex: number) => void;
}) {
  const { datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { showPublicDatasets } = usePublicDatasetContext();
  const [totalSchemas, setTotalSchemas] = useState<ITotalSchema>();

  const [hoverButtonList, setHoverButtonList] = useState<string[]>([]);

  const { getDatasetSchemas } = useCurateDatasetService();

  const handleBoxTheme = useCallback((depth: number) => {
    if (depth === 1) return { border: undefined, background: 'white', padding: '4px' };
    else if (depth == 2)
      return {
        border: `1px solid ${vars.color['gray-opacity-150']}`,
        background: '#F9F9F9',
        padding: '8px 40px 4px 8px',
      };
    else if (depth == 3)
      return {
        border: `1px solid ${vars.color['gray-opacity-150']}`,
        background: '#F3F3F3',
        padding: '8px 40px 4px 8px',
      };
    else
      return {
        border: `1px solid ${vars.color['gray-opacity-150']}`,
        background: '#EDEDED',
        padding: '8px 40px 4px 8px',
      };
  }, []);

  const boxTheme = useMemo(() => {
    return handleBoxTheme(depth);
  }, [depth, handleBoxTheme]);

  const totalBoxList = useMemo(() => {
    return [...advancedQuery?.query, ...(advancedQuery?.children ?? [])].sort(
      (a, b) => a.boxIndex - b.boxIndex,
    );
  }, [advancedQuery]);

  useEffect(() => {
    (async () => {
      const datasetInfo = await getDatasetSchemas({
        datasetId,
        fromPublicDatasets: showPublicDatasets,
      });
      setTotalSchemas(datasetInfo);
    })();
  }, []);

  return (
    <>
      {advancedQuery.query?.length > 0 ||
      (advancedQuery.children && advancedQuery?.children?.length > 0) ? (
        <div
          style={{
            display: 'flex',
            border: `${boxTheme.border}`,
            background: `${boxTheme.background}`,
            padding: `${boxTheme.padding}`,
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {totalBoxList.map((list, index) => {
            return (
              <Box key={index} mb={1} style={{ opacity: '1' }} display="flex">
                {list?.hasOwnProperty('content') ? (
                  <>
                    <Box display="flex" position="relative">
                      {index === 0 ? (
                        <Box
                          mr={0.5}
                          style={{ maxHeight: '32px', padding: '7px 10px', width: '48px' }}
                        >
                          <Typography variant="m-regular">Where</Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                      {index === 1 ? (
                        <OperatorBox
                          handleOpen={handleOpen}
                          boxNum={boxNum}
                          advancedQuery={advancedQuery}
                          anchorEl={anchorEl}
                          updateOperator={updateOperator}
                        />
                      ) : (
                        <></>
                      )}
                      {index > 1 ? (
                        <Box
                          mr={0.5}
                          style={{ maxHeight: '32px', padding: '7px 10px', width: '48px' }}
                          boxSizing={'border-box'}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="m-regular">{advancedQuery.operator}</Typography>
                        </Box>
                      ) : (
                        <></>
                      )}

                      <Box display="flex">
                        <QueryTypeMenu
                          hoverButtonList={hoverButtonList}
                          boxNum={boxNum}
                          index={index}
                          setHoverButtonList={setHoverButtonList}
                          list={list}
                          deleteQuery={deleteQuery}
                          selectedQueryList={selectedQueryList}
                          setSelectedQueryList={setSelectedQueryList}
                          updateQueryContent={updateQueryContent}
                          isSelected={
                            selectedQueryList.filter(
                              list => list.boxNum === boxNum.toString() + (index + 1),
                            ).length > 0
                          }
                        />
                        {selectedQueryList.filter(
                          list => list.boxNum === boxNum.toString() + (index + 1),
                        ).length > 0 ? (
                          <QuerySchemasKeyMenu
                            hoverButtonList={hoverButtonList}
                            boxNum={boxNum}
                            index={index}
                            setHoverButtonList={setHoverButtonList}
                            list={list}
                            deleteQuery={deleteQuery}
                            selectedQueryList={selectedQueryList}
                            setSelectedQueryList={setSelectedQueryList}
                            isSelected={
                              selectedQueryList.filter(
                                list => list.boxNum === boxNum.toString() + (index + 1),
                              ).length > 0 &&
                              selectedQueryList
                                .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                                .hasOwnProperty('queryKey')
                            }
                            updateQueryContent={updateQueryContent}
                            totalSchemas={totalSchemas as ITotalSchema}
                          />
                        ) : (
                          <></>
                        )}
                        {selectedQueryList.filter(
                          list => list.boxNum === boxNum.toString() + (index + 1),
                        ).length > 0 &&
                        (selectedQueryList
                          .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                          .hasOwnProperty('queryKey') ||
                          (selectedQueryList
                            .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                            .hasOwnProperty('queryType') &&
                            selectedQueryList.filter(
                              list => list.boxNum === boxNum.toString() + (index + 1),
                            )[0].queryType === 'slice')) ? (
                          <QueryOperatorMenu
                            hoverButtonList={hoverButtonList}
                            boxNum={boxNum}
                            index={index}
                            setHoverButtonList={setHoverButtonList}
                            list={list}
                            deleteQuery={deleteQuery}
                            selectedQueryList={selectedQueryList}
                            setSelectedQueryList={setSelectedQueryList}
                            updateQueryContent={updateQueryContent}
                            isSelected={
                              selectedQueryList.filter(
                                list => list.boxNum === boxNum.toString() + (index + 1),
                              ).length > 0 &&
                              selectedQueryList
                                .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                                .hasOwnProperty('queryOperator')
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {selectedQueryList.filter(
                          list => list.boxNum === boxNum.toString() + (index + 1),
                        ).length > 0 &&
                        selectedQueryList
                          .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                          .hasOwnProperty('queryOperator') ? (
                          <QueryValueMenu
                            hoverButtonList={hoverButtonList}
                            boxNum={boxNum}
                            index={index}
                            setHoverButtonList={setHoverButtonList}
                            list={list}
                            deleteQuery={deleteQuery}
                            selectedQueryList={selectedQueryList}
                            setSelectedQueryList={setSelectedQueryList}
                            updateQueryContent={updateQueryContent}
                            handleQueryValue={handleQueryValue}
                            isSelected={
                              selectedQueryList.filter(
                                list => list.boxNum === boxNum.toString() + (index + 1),
                              ).length > 0 &&
                              selectedQueryList
                                .filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                                .hasOwnProperty('queryValue')
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </Box>

                      <QueryBoxEvent
                        boxNum={boxNum}
                        listIndex={list.boxIndex}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleOpen={handleOpen}
                        deleteQuery={deleteQuery}
                        turnIntoFilter={turnIntoFilter}
                        wrapInGroup={wrapInGroup}
                        duplicateQuery={duplicateQuery}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box display="flex">
                      {index === 0 ? (
                        <Box
                          mr={0.5}
                          style={{ maxHeight: '32px', padding: '7px 10px', width: '48px' }}
                        >
                          <Typography variant="m-regular">Where</Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                      {index === 1 ? (
                        <OperatorBox
                          handleOpen={handleOpen}
                          boxNum={boxNum}
                          advancedQuery={advancedQuery}
                          anchorEl={anchorEl}
                          updateOperator={updateOperator}
                        />
                      ) : (
                        <></>
                      )}
                      {index > 1 ? (
                        <Box
                          mr={0.5}
                          style={{ maxHeight: '32px', padding: '7px 10px', width: '48px' }}
                          boxSizing={'border-box'}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="m-regular">{advancedQuery.operator}</Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                      <QueryBox
                        advancedQuery={list as IAdvancedQuery}
                        depth={depth + 1}
                        boxNum={10 * boxNum + (index + 1)}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleOpen={handleOpen}
                        updateQuery={updateQuery}
                        updateQueryGroup={updateQueryGroup}
                        deleteQuery={deleteQuery}
                        turnIntoFilter={turnIntoFilter}
                        wrapInGroup={wrapInGroup}
                        updateOperator={updateOperator}
                        updateQueryContent={updateQueryContent}
                        selectedQueryList={selectedQueryList}
                        setSelectedQueryList={setSelectedQueryList}
                        handleQueryValue={handleQueryValue}
                        duplicateQuery={duplicateQuery}
                      />
                    </Box>
                  </>
                )}
              </Box>
            );
          })}
          <AddGroup
            handleOpen={handleOpen}
            boxNum={boxNum}
            anchorEl={anchorEl}
            updateQuery={updateQuery}
            updateQueryGroup={updateQueryGroup}
          />
        </div>
      ) : (
        <AddGroup
          handleOpen={handleOpen}
          boxNum={boxNum}
          anchorEl={anchorEl}
          updateQuery={updateQuery}
          updateQueryGroup={updateQueryGroup}
        />
      )}
    </>
  );
}
