import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { capitalize } from 'lodash';

type Row = { content: (string | number | JSX.Element | undefined)[]; failMsg?: string };
type Props = {
  type: 'object' | 'categorization';
  columns: string[];
  rows: Row[];
  width: number[];
};

const useStyles = makeStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: 12,
    '& strong': {
      fontWeight: 500,
    },
    '& td': {
      lineHeight: 1.5,
      fontSize: '11px',
      color: '#6f6f6f',
      padding: '10px',
      textAlign: 'left',
      verticalAlign: 'top',
      '&:nth-last-child(2), &:last-child': {
        textAlign: 'right',
      },
    },
    '& th': {
      fontSize: '12px',
      lineHeight: 1,
      fontWeight: 'normal',
      textAlign: 'left',
      verticalAlign: 'center',
      padding: '10px',
      top: 0,
      backgroundColor: '#FBFBFB',
      whiteSpace: 'nowrap',
      '&:nth-last-child(2), &:last-child': {
        textAlign: 'right',
      },
      borderBottom: '1px solid #e6e6e6',
    },
  },
});

const AccordionTableLayout: React.FC<Props> = ({ type, columns, rows, width }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box themedBackgroundColor={['grey', 60]} px={1.5} py={1} mb={1}>
        <Typography variant="headline7">
          {capitalize(
            type === 'object' ? t('labelInterface.object') : t('autoLabel.settings.categorization'),
          )}
        </Typography>
      </Box>
      <Box width="640px">
        <table className={classes.table}>
          <colgroup>
            {width.map((i, index) => (
              <col key={index} width={`${width}px`} />
            ))}
          </colgroup>

          <thead>
            <tr>
              {columns.map(column => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => {
              const rowContent = (
                <tr key={index}>
                  {row.content.map((content, index) => (
                    <td key={`row-content-${index}`}>
                      <Box minHeight="20px" display="inline-flex" alignItems="center">
                        {content}
                      </Box>
                    </td>
                  ))}
                </tr>
              );
              if (row.failMsg) {
                return (
                  <Tooltip key={row.failMsg} placement="auto-start" anchorEl={rowContent}>
                    {row.failMsg}
                  </Tooltip>
                );
              }
              return rowContent;
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
};
export default AccordionTableLayout;
