import { TFunction } from 'react-i18next';

import * as ShyCredit from '@superb-ai/shy-credit';
import { decamelize } from 'humps';

import { Keypoint } from './type';

export const getKeypointTemplates = (keypointList: Keypoint[]): any[] => {
  const keypointIds = keypointList.map(keypoint => keypoint.id);
  const templates = ShyCredit.Models.ImageV2.KeypointDef.listTemplates();
  return templates.filter(template => !keypointIds.includes(template.id));
};

// eslint-disable-next-line
export const validate = (obj: any, keys: { value: string; type: string }[]) => {
  const result = {
    key: '',
    type: '',
  };

  keys.some(key => {
    const { value, type } = key;

    if (obj[value] === undefined) {
      result.key = value;
      result.type = 'key';
      return true;
    }

    if (type === 'array' && !Array.isArray(obj[value])) {
      result.key = value;
      result.type = 'type';
      return true;
    }

    if (type !== 'array' && typeof obj[value] !== type) {
      result.key = value;
      result.type = 'type';
      return true;
    }

    if (type === 'string' && value === '') {
      result.key = value;
      result.type = 'key';
      return true;
    }

    return false;
  });

  return result;
};

export const createErrorMessage = (
  result: { key: string; type: string },
  keys: { value: string; type: string }[],
  prefix = '',
  t: TFunction,
): string => {
  if (result.type === 'key') {
    return t('projects.createProject.keypoint.requiredError', {
      key: `${prefix}${decamelize(result.key)}`,
    });
  }

  if (result.type === 'type') {
    return t('projects.createProject.keypoint.mustBeError', {
      key: `${prefix}${decamelize(result.key)}`,
      type: keys.find(key => key.value === result.key)?.type,
    });
  }

  return '';
};
