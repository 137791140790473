import { canManageProject } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';
import { ProjectAnalyticsCustomizeMenuItem } from './customize-analytics/MenuItem';
import { ProjectAnalyticsExportsAnalyticsMenuItem } from './exports-analytics/MenuItem';
import Layout from './Layout';
import { ProjectAnalyticsProjectMenuItem } from './project/MenuItem';
import { ProjectAnalyticsQAMenuItem } from './qa/MenuItem';
import { ProjectAnalyticsUserReportsMenuItem } from './user-reports/MenuItem';

// label project analytics menu group title
export const ProjectAnalyticsMenuItem = CreateMenuItem({
  // pageId: PAGE_TRACKING_ID.label-project-top-analytics', // not tracked
  path: 'analytics',
  title: ({ t }) => t('analytics.title'),
  component: Layout,
  icon: 'analytics',
  children: [
    ProjectAnalyticsCustomizeMenuItem,
    ProjectAnalyticsProjectMenuItem,
    ProjectAnalyticsUserReportsMenuItem,
    ProjectAnalyticsExportsAnalyticsMenuItem,
    ProjectAnalyticsQAMenuItem,
  ],
  isVisible({ authInfo }) {
    return canManageProject(authInfo) || authInfo?.isGuest || false;
  },
  isEnabled({ authInfo }) {
    return canManageProject(authInfo) || authInfo?.isGuest || false;
  },
});
