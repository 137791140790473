import React, { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import { Check, Clear } from '@superb-ai/icons';
import { Box, Button, extendComponent, Icon, Typography } from '@superb-ai/ui';

import AnalyticsTracker from '../../../../analyticsTracker';
import { TrainModelStep } from '../../../../analyticsTracker/types';
import { Row } from '../../../../components/elements/Row';
import { PurposeType } from '../../services/types';

export type Step = {
  title: string;
  description: string;
  content: JSX.Element | string;
  isOptional?: boolean;
  summary?: JSX.Element | string;
  isButtonEnabled?: boolean;
  startingStepIndex?: boolean;
  onClickNext?: () => void;
  // event tracker variable
  eventTrackerParams: {
    step: TrainModelStep;
    modelPurpose?: PurposeType;
    source?: string;
  };
};

export const TrainModelStepper = ({
  steps,
  lastStepButton,
  startingStepIndex = 0,
  footer,
  ...props
}: {
  /** Object of steps' title and content */
  steps: Record<string, Step>;
  /** Last step button text, click event, and loading state */
  lastStepButton?: { text: string; onClick?: () => void; isLoading?: boolean };
  startingStepIndex?: number;
  footer: ReactNode;
} & ComponentProps<typeof Box>) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const history = useHistory();
  const stepsEntries = Object.entries(steps) as [string, Step][];
  const totalSteps = stepsEntries.length;
  const [activeStepIndex, setActiveStepIndex] = React.useState(startingStepIndex);
  const [completedSteps, setCompletedSteps] = React.useState<Array<string>>(
    stepsEntries.filter((_, idx) => idx < startingStepIndex).map(x => x[0]),
  );
  const completedStepsIdx = completedSteps.length - 1;
  const HEADER_HEIGHT = 48;
  const FOOTER_HEIGHT = 80;

  const onChangeStepIndex = (index: number, step: string) => {
    setActiveStepIndex(index + 1);
    if (completedSteps.indexOf(step) < 0) {
      setCompletedSteps(completedSteps.concat([step]));
    }
  };

  const onClickStep =
    (stepIndex: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      if (stepIndex < startingStepIndex) return;
      setActiveStepIndex(stepIndex);
    };

  const onClickPrev =
    (stepIndex: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setActiveStepIndex(stepIndex - 1);
    };

  const onClickNext =
    (stepIndex: number, step: string, onClick?: () => void) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      onClick && onClick();
      onChangeStepIndex(stepIndex, step);
      AnalyticsTracker.modelTrainingStepCompleted({
        accountId: params.accountName,
        ...stepsEntries[activeStepIndex][1].eventTrackerParams,
      });
    };

  const onClickLastButton = async () => {
    if (lastStepButton?.onClick) {
      AnalyticsTracker.modelTrainingStepCompleted({
        accountId: params.accountName,
        ...stepsEntries[activeStepIndex][1].eventTrackerParams,
      });

      await lastStepButton.onClick();
    }
  };

  return (
    <>
      <Box
        height="100%"
        display="grid"
        style={{
          gridTemplateRows: `${HEADER_HEIGHT}px 1fr`,
          gridTemplateColumns: '339px 1fr',
        }}
      >
        <Row
          br="1px solid"
          bb="1px solid"
          borderColor={'gray-150'}
          px={2}
          style={{ gridArea: '1 / 1 / 2 / 2' }}
        >
          <Typography variant="l-strong">{t('model.train.title')}</Typography>
        </Row>
        <Row
          px={2}
          bb="1px solid"
          borderColor={'gray-150'}
          justifyContent="space-between"
          style={{ gridArea: '1 / 2 / 2 / 3' }}
        >
          <Typography variant="l-strong">{stepsEntries[activeStepIndex][1].description}</Typography>
          <Button variant="soft-fill" onClick={() => history.goBack()}>
            <Icon icon={Clear} />
            {t('model.train.cancel')}
          </Button>
        </Row>
        <Box br="1px solid" px={2} borderColor={'gray-150'} style={{ gridArea: '2 / 1 / 3 / 2' }}>
          {stepsEntries.map(([key, step], idx) => {
            const active = activeStepIndex === idx;
            const disabled =
              (activeStepIndex === null ||
                idx > (completedStepsIdx >= 0 ? completedStepsIdx : -1) + 1) &&
              !active;
            const completed = idx <= (completedStepsIdx || 0);
            return (
              <Step
                key={key}
                isLast={idx === totalSteps - 1}
                value={idx + 1}
                title={step.title}
                isOptional={step.isOptional}
                inactive={disabled}
                completed={completed && step.isButtonEnabled}
                isPreCompleted={idx < startingStepIndex}
                onClick={disabled || active ? undefined : onClickStep(idx)}
                summary={step.summary}
              />
            );
          })}
        </Box>
        <Box display="flex" flexDirection="column" style={{ gridArea: '2 / 2 / 3 / 3' }}>
          <Box
            py={3}
            overflow="auto"
            style={{
              paddingInline: 162,
              height: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
            }}
          >
            <Box style={{ minWidth: 776 }}>{stepsEntries[activeStepIndex][1].content}</Box>
          </Box>
          <Footer>
            {footer}
            <Row gap={1}>
              {activeStepIndex > 0 && startingStepIndex !== activeStepIndex && (
                <Button
                  disabled={lastStepButton?.isLoading}
                  onClick={onClickPrev(activeStepIndex)}
                  variant="text"
                >
                  {t('shared.previous')}
                </Button>
              )}
              {lastStepButton && activeStepIndex === stepsEntries.length - 1 ? (
                <Button
                  variant="strong-fill"
                  color={'primary'}
                  disabled={
                    !stepsEntries[activeStepIndex][1].isButtonEnabled || lastStepButton.isLoading
                  }
                  onClick={onClickLastButton}
                >
                  {lastStepButton.text}
                </Button>
              ) : (
                <Button
                  variant="strong-fill"
                  color={'black'}
                  disabled={!stepsEntries[activeStepIndex][1].isButtonEnabled}
                  onClick={onClickNext(activeStepIndex, stepsEntries[activeStepIndex][0])}
                >
                  {t('model.train.next')}
                </Button>
              )}
            </Row>
          </Footer>
        </Box>
      </Box>
    </>
  );
};

const Step = ({
  isLast,
  value,
  title,
  isOptional,
  inactive,
  completed,
  isPreCompleted,
  summary,
  onClick,
}: {
  isLast: boolean;
  value: number | string;
  title: string;
  inactive?: boolean;
  completed?: boolean;
  isOptional?: boolean;
  isPreCompleted?: boolean;
  summary?: JSX.Element | string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}) => {
  const renderBadge = () => {
    if (completed) {
      if (isPreCompleted) {
        return (
          <Badge borderColor={'gray-400'}>
            <Icon icon={Check} color={'gray-400'} />
          </Badge>
        );
      } else {
        return (
          <Badge borderColor={inactive ? 'gray-250' : 'green-400'}>
            <Icon icon={Check} color={inactive ? 'gray-250' : 'green-400'} />
          </Badge>
        );
      }
    } else {
      return (
        <Badge
          backgroundColor={inactive ? 'gray-250' : 'green-400'}
          borderColor={inactive ? 'gray-250' : 'green-400'}
        >
          <Typography color={'white'} variant="m-regular">
            {value}
          </Typography>
        </Badge>
      );
    }
  };

  return (
    <Row onClick={onClick} cursor="pointer" position="relative" style={{ height: 80 }}>
      <Box display="flex" alignItems="center" gap={1}>
        {renderBadge()}
        <Box>
          <Typography variant="m-medium">{title}</Typography>
          {isOptional && (
            <Box display="flex" alignItems="center" ml={0.5}>
              <Typography variant="m-medium" color="gray-250">
                (Optional)
              </Typography>
            </Box>
          )}
          {summary && <Box>{summary}</Box>}
        </Box>
      </Box>
      {!isLast && (
        <Box
          position="absolute"
          borderLeft="1px solid"
          borderColor="gray-250"
          ml={2}
          style={{ width: 1, height: 48, top: 56 }}
        >
          {/* Gray line */}
        </Box>
      )}
    </Row>
  );
};

const Badge = extendComponent(Box, {
  style: { width: 32, height: 32, borderRadius: 16 },
  border: '1px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function Footer({ children }: { children: ReactNode }) {
  return (
    <Box
      display="flex"
      width="100%"
      backgroundColor="gray-100"
      gap={2}
      py={3}
      justifyContent="space-between"
      style={{ paddingInline: 162 }}
    >
      {children}
    </Box>
  );
}
