import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalStepper } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import { UploadMethod } from '../types';
import CLI from './contents/CLI';
import Cloud from './contents/Cloud';
import File from './contents/File';
import { uploadMethods } from './steps/config';
import ProjectAssign from './steps/ProjectAssign';
import SelectDataset from './steps/SelectDataset';
import SelectSourceData from './steps/SelectSourceData';
import SelectUploadMethod from './steps/SelectUploadMethod';

type VideoFramesUploadMethods = Exclude<UploadMethod, 'URL'>;
const VideoFrames: React.FC = () => {
  const { t } = useTranslation();
  const fileInfo = useUploadInfo();
  const {
    uploadMethod,
    files,
    dataset,
    selectedProject,
    setIsStepsCompleted,
    videoFramesSourceData,
  } = fileInfo;
  const videoFramesUploadMethods = uploadMethods().filter(method => method.value !== 'URL');

  const routeInfo = useRouteInfo();
  const projectId = routeInfo.urlMatchInfo.projectId;

  const UPLOAD_METHOD = 'upload_method';
  const SOURCE_DATA = 'source_data';

  const DATASET = 'dataset';
  const PROJECT = 'project';

  const [projectAssignButtonText, setProjectAssignButtonText] = useState<string>(t('button.skip'));

  const stepsPerUploadMethods = (uploadMethod: VideoFramesUploadMethods) =>
    ({
      CLOUD: Cloud(t),
      FILE: File(t),
      CLI: CLI(t),
    }[uploadMethod]);

  const stepsPerUploadMethod = (uploadMethod: VideoFramesUploadMethods) =>
    videoFramesSourceData === 'image-sequence'
      ? {
          [UPLOAD_METHOD]: SelectUploadMethod({
            isButtonEnabled: !!uploadMethod,
            options: Object.values(videoFramesUploadMethods) as Option<VideoFramesUploadMethods>[],
            hasWarning: !!(files.length || dataset || selectedProject),
            t,
          }),
          ...stepsPerUploadMethods(uploadMethod),
        }
      : stepsPerUploadMethods('CLOUD');

  const selectSourceData = {
    [SOURCE_DATA]: SelectSourceData(t),
  };

  const selectDatasetStep = {
    [DATASET]: SelectDataset(t),
  };

  const assignToProjectStep = {
    [PROJECT]: ProjectAssign(t),
  };

  const steps = (uploadMethod: VideoFramesUploadMethods) => ({
    ...selectSourceData,
    ...stepsPerUploadMethod(uploadMethod),
    ...selectDatasetStep,
  });
  const videoFramesUploadSteps = (uploadMethod: VideoFramesUploadMethods) =>
    uploadMethod === 'CLI'
      ? stepsPerUploadMethod(uploadMethod)
      : projectId
      ? steps(uploadMethod)
      : { ...steps(uploadMethod), ...assignToProjectStep };

  const lastStepButton = projectId
    ? {
        text: t('button.done'),
        onClick: () => {
          setIsStepsCompleted(true);
        },
      }
    : {
        text: projectAssignButtonText,
        onClick: () => {
          setIsStepsCompleted(true);
        },
      };

  useEffect(() => {
    if (selectedProject) {
      setProjectAssignButtonText(t('button.done'));
    } else {
      setProjectAssignButtonText(t('button.skip'));
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <VerticalStepper.Simple
      cardProps={{ maxHeight: 482, overflow: 'auto' }}
      steps={videoFramesUploadSteps(uploadMethod as VideoFramesUploadMethods)}
      lastStepButton={lastStepButton}
    />
  );
};

export default VideoFrames;
