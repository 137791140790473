import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { PartitionElementEvent, ShapeTreeNode, XYChartElementEvent } from '@elastic/charts';
import { ChartBar, ChartDonut, Hide, Show, WarningOutline } from '@superb-ai/icons';
import { DropdownList } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, SegmentedControl, Typography } from '@superb-ai/ui';

import {
  CURATE_COLOR,
  getSpbColorBlindFriendlyColors,
} from '../../../../../../components/pages/analytics/charts/donutChart/donutChartColors';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { METADATA_CARDINALITY_THRESHOLD } from '../../../../services/AnalyticsService';
import { PageSet } from '../../../../types/routeTypes';
import { getSearchQueryRoute } from '../../../../utils/routeUtils';
import { BarChart } from './charts/BarChart';
import { CompareBarCharts } from './charts/CompareBarCharts';
import { DonutChart } from './charts/DonutChart';
import {
  appendQuery,
  formatCount,
  getAxisInfo,
  getParititionKey,
  getTextByDataScope,
  getXYChartKey,
  metadataEQQuery,
} from './charts/utils';
import { LegendLabel } from './components/Legends';
import { LoadingIndicatorDiv } from './components/LoadingIndicatorDiv';
import { NoDataToShow } from './components/NoDataToShow';
import { useAnalyticsContext } from './contexts/AnalyticsContext';
import { useMetadataAnalyticsContext } from './contexts/MetadataContext';
import {
  useStatsComparedMetadataValueQuery,
  useStatsMetadataValueQuery,
} from './queries/metadataQueries';
import { DataScope, MetadataKeyDatum } from './types';
import { getChartColor, getSuiteColor } from './utils/colorScale';
import {
  filterSystemMetadata,
  isMetadataTextType,
  metadataKeySortTransformer,
  transformValueData,
} from './utils/transformer';

export function MetadataChartArea() {
  const history = useHistory();
  const { datasetInfo } = useDatasetContext();
  const { queryString, setQueryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();

  const currentPageSet = (sliceInfo?.id ? 'slice' : 'dataset') as PageSet;
  const currentPageName =
    (currentPageSet === 'dataset' ? datasetInfo?.name : sliceInfo?.name) ?? '';
  const [chartType, setChartType] = useState('donut');

  const { t } = useTranslation();
  const {
    selectedMetadataKey,
    setSelectedMetadataKey,
    isKeySelected,
    selectionKeyData,
    getValueQueryDependencies,
    showSystemMetadata,
    setShowSystemMetadata,
  } = useMetadataAnalyticsContext();
  const { isCompareMode, setIsCompareMode, selectedCompareSetId } = useAnalyticsContext();

  const filteredKeys = filterSystemMetadata(
    metadataKeySortTransformer(selectionKeyData?.data || []),
    showSystemMetadata,
  );

  const transformedSelectionData = useMemo(() => {
    return filterSystemMetadata(
      metadataKeySortTransformer(selectionKeyData?.data || []),
      showSystemMetadata,
    );
  }, [selectionKeyData, showSystemMetadata, currentPageName]);

  useEffect(() => {
    setSelectedMetadataKey(transformedSelectionData[0]?.key || '');
  }, [transformedSelectionData, setSelectedMetadataKey]);

  useEffect(() => {
    if (isCompareMode) setChartType('bar');
  }, [isCompareMode]);

  const {
    data: selectionValueData = { count: 0, data: [] },
    isFetching: isFetchingSelectionValue,
  } = useStatsMetadataValueQuery(getValueQueryDependencies(selectedMetadataKey));

  const { data: comparedValueData = { count: 0, data: [] }, isFetching: isFetchingComparedValue } =
    useStatsComparedMetadataValueQuery(getValueQueryDependencies(selectedMetadataKey));

  // TODO:  3 variables are used to represent is key is selected, clean up
  const currentDataScope = isKeySelected ? 'metadataValue' : 'metadataKey';
  const selectedKeyDatum = transformedSelectionData.find(d => d.key === selectedMetadataKey);

  const chartInfo = {
    xAxis: getAxisInfo(t('curate.analytics.metadataStats.metadata')),
    yAxis: getAxisInfo(t('curate.analytics.text.imageCount')),
  };

  const getSingleModeChart = (metadataKey: string) => {
    const transformedData = transformValueData(selectionValueData?.data || []);
    const colorPalette = getSpbColorBlindFriendlyColors(transformedData.length);
    if (!selectedKeyDatum) return null;
    if (transformedData.length === 0) return <NoDataToShow />;
    if (chartType === 'bar') {
      return (
        <BarChart
          chartWidth={Math.max((window.innerWidth * 0.89) / 2, 400)}
          data={transformedData}
          color={getChartColor(metadataKey ? 'metadataValue' : 'metadataKey')}
          xAxis={getAxisInfo(t('curate.analytics.metadataStats.metadata'))}
          yAxis={getAxisInfo(t('curate.analytics.text.imageCount'))}
          // getTooltipActions={ metadataKey ? getValueChartActions(t, metadataKey) : () => [];}
          onElementListeners={
            metadataKey && {
              onElementClick: (events: Array<XYChartElementEvent>): void => {
                const query = appendQuery(
                  metadataEQQuery(metadataKey, getXYChartKey(events)),
                  queryString,
                );
                setQueryString(query);
                history.push(getSearchQueryRoute(history, { query }));
              },
            }
          }
        />
      );
    }
    return (
      <Box mr={2} width="100%" style={{ marginLeft: '-12px' }}>
        <DonutChart
          data={transformedData}
          customSettings={{ showLegendExtra: false }}
          onElementListeners={
            metadataKey && {
              onElementClick: (events: Array<PartitionElementEvent>): void => {
                const query = appendQuery(
                  metadataEQQuery(metadataKey, getParititionKey(events)),
                  queryString,
                );
                setQueryString(query);
                history.push(getSearchQueryRoute(history, { query }));
              },
            }
          }
          getColor={(key: string, datum: ShapeTreeNode | undefined) => {
            if (!datum) return CURATE_COLOR;
            return colorPalette(String(datum.sortIndex as number)) as string;
          }}
        />
      </Box>
    );
  };

  const selectionLegend = {
    key: 'metadataSelection',
    legendText: getTextByDataScope(t, currentPageSet, currentPageName, queryString),
    hexColor: getChartColor(currentDataScope),
    textColor: getSuiteColor(currentDataScope),
    visible: true, // always visible
  } as LegendLabel;

  // TODO: refactor later
  const comparedText = getTextByDataScope(
    t,
    currentPageSet,
    (sliceInfo?.id === selectedCompareSetId ? sliceInfo?.name : datasetInfo?.name) ?? '',
  );

  const comparedLegend = {
    key: 'metadataCompared',
    legendText: comparedText,
    hexColor: getChartColor(currentPageSet as DataScope),
    textColor: getSuiteColor(currentPageSet as DataScope),
    visible: isCompareMode,
    setVisibility: setIsCompareMode,
  } as LegendLabel;

  const getCompareModeCharts = (metadataKey: string) => {
    const transformedData = transformValueData(selectionValueData?.data || []);
    const transformedComparedData = transformValueData(comparedValueData?.data || []);

    return (
      <CompareBarCharts
        series={[
          {
            ...chartInfo,
            id: selectionLegend.legendText,
            data: transformedData,
            color: getChartColor(metadataKey ? 'metadataValue' : 'metadataKey'),
          },
          {
            ...chartInfo,
            id: comparedLegend.legendText,
            data: transformedComparedData,
            color: getChartColor('all' as DataScope),
          },
        ]}
      />
    );
  };

  const showSelectionProgress = isFetchingSelectionValue;
  const showComparedProgress = isFetchingComparedValue;

  const getSystemMetadataToggle = () => {
    return (
      <Box
        ml="auto"
        mr={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        onClick={() => setShowSystemMetadata(prevState => !prevState)}
      >
        <Button size="m" variant="text" color="gray">
          <Icon icon={showSystemMetadata ? Show : Hide} />
          {t('curate.analytics.action.systemMetadata')}
        </Button>
      </Box>
    );
  };

  const chartOptions = [
    { label: <Icon icon={ChartBar} />, value: 'bar' },
    { label: <Icon icon={ChartDonut} />, value: 'donut', disabled: isCompareMode },
  ];

  const getKeyDropdown = (filteredKeys: MetadataKeyDatum[]) => {
    const dropdownOptions = filteredKeys.map(d => {
      return {
        label: d.key,
        value: d.key,
      };
    });
    const getOptions = async (query: string) => {
      if (!query) return dropdownOptions;
      // Just an example. Use a more robust search than this.
      return dropdownOptions.filter(option => option.label.match(new RegExp(query, 'i')));
    };
    return (
      <DropdownList
        hasSearch
        value={selectedMetadataKey}
        options={dropdownOptions}
        getOptions={getOptions}
        onChange={setSelectedMetadataKey}
      />
    );
  };

  const isLoading =
    (!isCompareMode && showSelectionProgress) || (isCompareMode && showComparedProgress);

  const chart = isCompareMode
    ? getCompareModeCharts(selectedMetadataKey)
    : getSingleModeChart(selectedMetadataKey);

  const highValueCountBanner = (
    <Box
      display="flex"
      px={2}
      backgroundColor="primary-100"
      alignItems="center"
      style={{ height: '32px', fontSize: '12px' }}
    >
      <Icon icon={WarningOutline} color="primary-400" />
      <Typography variant="m-regular" color="primary-400" ml={1}>
        {t('curate.analytics.action.metadataValueCountWarning', {
          threshold: METADATA_CARDINALITY_THRESHOLD,
          valueCount: selectedKeyDatum?.valueCount
            ? String(formatCount(selectedKeyDatum.valueCount))
            : 'N/A',
        })}
      </Typography>
    </Box>
  );

  return (
    <Box width="100%" height="100%">
      <Box display="flex" alignItems="center" style={{ height: '60px' }}>
        {isMetadataTextType(selectedKeyDatum) ? (
          <Button size="s" color="primary">
            <Icon icon={ChartDonut} />
            {/* <Icon icon={ChartBar} style={{ transform: 'rotate(90deg)' }} /> */}
          </Button>
        ) : (
          <SegmentedControl
            options={chartOptions}
            value={chartType}
            onChangeValue={setChartType}
            buttonProps={{
              color: 'primary',
              size: 's',
            }}
          />
        )}
        <Box display="flex" ml={1.5} mb={1} mt={1} style={{ width: '200px' }}>
          {getKeyDropdown(filteredKeys)}
        </Box>
        {getSystemMetadataToggle()}
      </Box>
      {selectedKeyDatum && selectedKeyDatum?.valueCount > 100 && highValueCountBanner}
      <Box display="flex" overflow="auto" width="100%" style={{ height: 'calc(100% - 100px)' }}>
        {isLoading ? <LoadingIndicatorDiv /> : chart}
      </Box>
    </Box>
  );
}
