import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Modal, RadioButtons, Typography, useTheme } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import ProjectService from '../../../../services/ProjectService';
import { MemberData } from '../../../../types/memberTypes';
import UserUtils from '../../../../utils/UserUtils';
import Emoji from '../../../elements/Emoji';
import RoleChip from '../../../elements/RoleChip';
import RoleLabel from '../../../elements/RoleLabel';
import UserAvatar from '../../../elements/UserAvatar';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<MemberData | undefined>>;
  member: MemberData | undefined;
  loadUsers: () => Promise<void>;
};

const useStyles = makeStyles({
  radioButton: {
    '& button': {
      padding: '12px 32px !important',
      whiteSpace: 'normal',
    },
  },
  radioButtonContent: {
    display: 'inline-block',
    marginTop: '4px',
    color: '#6e6e6e',
    lineHeight: 1.5,
  },
});

const ChangeMemberRoleModal: React.FC<Props> = ({ open, setOpen, member, loadUsers }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const [selectedRole, setSelectedRole] = useState<{ label: JSX.Element; value: string }>();

  const roleOptions = [
    {
      label: <RoleLabel role="manager" />,
      value: 'manager',
    },
    {
      label: <RoleLabel role="reviewer" />,
      value: 'reviewer',
    },
    {
      label: <RoleLabel role="labeler" />,
      value: 'labeler',
    },
  ];

  const getSelectedRole = (role: string) => {
    return roleOptions.find(option => option.value === role) || roleOptions[0];
  };

  useEffect(() => {
    const convertedRole = UserUtils.convertUserRole(member?.projectRole || '');

    setSelectedRole({
      label: getSelectedRole(convertedRole),
      value: convertedRole,
    } as any);

    return () => {
      setSelectedRole(undefined);
    };
    // eslint-disable-next-line
  }, [open]);

  if (!member) return <></>;

  const isOtherRoleSelected =
    selectedRole && selectedRole.value !== UserUtils.convertUserRole(member.projectRole || '');

  const onClickSave = async () => {
    if (!isOtherRoleSelected) {
      setOpen(undefined);
      return;
    }

    const convertedRole = UserUtils.convertUserRole(selectedRole?.value || '');

    await ProjectService.updateUserRole({
      userId: member.email,
      nextRole: convertedRole,
      projectId: projectInfo.project?.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    setOpen(undefined);
    loadUsers();
  };

  return (
    <Modal
      open={open}
      title={t('projectMembers.dialogs.changeRole.title')}
      mainButton={{
        text: t('button.save'),
        onClick: onClickSave,
      }}
      close={{
        onClose: () => setOpen(undefined),
        hasCloseButton: true,
      }}
    >
      <Box
        backgroundColor={theme.nfPalette.grey[60]}
        py={2}
        px={3}
        width="480px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <UserAvatar noShadow userInfo={member} size={24} />
          <Box>
            <Typography variant="body5" themedColor="grey">
              {member.name}
            </Typography>
            <Typography variant="body3">{member.email}</Typography>
          </Box>
        </Box>
        {isOtherRoleSelected && (
          <Box display="flex" alignItems="center">
            <RoleChip memberRole={member.projectRole?.toLowerCase()} />
            <Box ml={1} mr={1} mb={0.5}>
              <Emoji symbol="👉" />
            </Box>
            <RoleChip memberRole={selectedRole?.value?.toLowerCase()} />
          </Box>
        )}
      </Box>
      <Box className={classes.radioButton}>
        <RadioButtons
          variant="gapless"
          onChange={role => setSelectedRole({ label: getSelectedRole(role), value: role } as any)}
          options={roleOptions}
          value={selectedRole?.value || ''}
        />
      </Box>
    </Modal>
  );
};

export default ChangeMemberRoleModal;
