import { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Dialog, Input, Typography, useDialogState } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { MODEL_NAME_CHANGED } from '../../../../consts/SnackbarMessage';
import RegexUtils from '../../../../utils/RegexUtils';
import { MAXIMUM_MODEL_NAME_LENGTH, MINIMUM_MODEL_NAME_LENGTH } from '../../constant';
import { useCheckModelNameUniqueQuery, useEditModelMutation } from '../../queries/modelQueries';

export const RenameDialog = ({
  disclosure,
  id,
  name,
}: {
  disclosure: ReactElement;
  id: string;
  name: string;
}) => {
  const state = useDialogState();

  return (
    <Dialog state={state} disclosure={disclosure} hideOnClickOutside={false} style={{ width: 520 }}>
      {state.visible && <DialogContent id={id} name={name} state={state} />}
    </Dialog>
  );
};

const DialogContent = ({
  state,
  id,
  name: _name,
}: {
  state: ReturnType<typeof useDialogState>;
  id: string;
  name: string;
}) => {
  const { t } = useTranslation();
  const { mutate } = useEditModelMutation();
  const [uniqueNameFilter, setUniqueNameFilter] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const checkModelNameUniqueQuery = useCheckModelNameUniqueQuery({ id, name: uniqueNameFilter });
  const { data: _data } = checkModelNameUniqueQuery;
  const isModelNameUnique = (_data?.pages.flatMap(p => p.modelList) ?? []).length === 0;

  const invalideNameLength =
    uniqueNameFilter.length > MAXIMUM_MODEL_NAME_LENGTH ||
    MINIMUM_MODEL_NAME_LENGTH > uniqueNameFilter.length;

  useEffect(() => {
    if (uniqueNameFilter) {
      return;
    }
    setUniqueNameFilter(_name);
  }, [_name, setUniqueNameFilter, uniqueNameFilter]);

  return (
    <>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.renameMyModelDialog.title')}
      </Dialog.Header>
      <Input
        value={uniqueNameFilter ? uniqueNameFilter : _name}
        onChange={e => setUniqueNameFilter(e.target.value)}
      />
      {!isModelNameUnique && (
        <Typography variant="s-regular" color={'red-400'} style={{ marginTop: -12 }}>
          {t('model.renameMyModelDialog.warning')}
        </Typography>
      )}
      <Row>
        <Box>
          {invalideNameLength && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                <Trans
                  t={t}
                  i18nKey={'model.train.maximumNameLength'}
                  values={{ min: MINIMUM_MODEL_NAME_LENGTH, max: MAXIMUM_MODEL_NAME_LENGTH }}
                />
              </Typography>
            </Box>
          )}
          {RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter) && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                {t('model.train.notIncludeSpecialCharacter')}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          variant="s-regular"
          color={invalideNameLength ? 'red-400' : undefined}
          ml={'auto'}
          mb={'auto'}
        >
          {`${uniqueNameFilter.length} / ${MAXIMUM_MODEL_NAME_LENGTH}`}
        </Typography>
      </Row>
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.close')}
        </Button>
        <Button
          onClick={async () => {
            await mutate({ id, name: uniqueNameFilter });
            state.setVisible(false);
            enqueueSnackbar(MODEL_NAME_CHANGED({ t }), {
              variant: 'success',
            });
          }}
          color="primary"
          variant="strong-fill"
          disabled={
            _name === uniqueNameFilter ||
            !uniqueNameFilter ||
            !isModelNameUnique ||
            invalideNameLength ||
            RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter)
          }
        >
          {t('shared.apply')}
        </Button>
      </Dialog.Actions>
    </>
  );
};
