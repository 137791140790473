import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DELETE_MEMBER_FROM_ACCOUNT,
  DELETE_MEMBERS_FROM_ACCOUNT,
} from '../../../../consts/ModalMessage';
import {
  DELETE_USER_FROM_ACCOUNT,
  DELETE_USERS_FROM_ACCOUNT,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUsersInfo } from '../../../../contexts/UsersContext';
import { useUsersQuery } from '../../../../queries/useUsers';
import UsersService from '../../../../services/UsersService';
import { MemberData } from '../../../../types/memberTypes';
import UserUtils from '../../../../utils/UserUtils';
import ManageMembers from '../../../elements/manageMembers';
import { Filters } from '../../../elements/manageMembers/types';
import {
  getIsOwner,
  getIsOwnerOrAdmin,
  getIsRoleOrderSameOrGreater,
} from '../../label/labelList/AssignDialog/utils';
import AccountMemberFilters from './AccountMemberFilters';

const AccountMembers: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const usersInfo = useUsersInfo();

  const [filters, setFilters] = useState<Filters>({ status: '', role: '', projects: [''] });
  const [projects, setProjects] = useState<Map<string, string>>(new Map());

  const { data: usersData, refetch: refetchUserData } = useUsersQuery({
    params: { asList: '', additionalFields: ['project_list'] },
    statusIn: filters.status ? [filters.status] : [],
  });

  const refetchMembers = useCallback(async () => {
    const res = await refetchUserData();
  }, [refetchUserData]);

  useEffect(() => {
    usersInfo.updateMembers();
    // eslint-disable-next-line
  }, []);

  const getIsDeletable = (member: MemberData) => {
    return (
      !getIsOwner(member) &&
      getIsRoleOrderSameOrGreater(
        member.projectRole || member.tenantRole || '',
        authInfo.projectRole || authInfo.role || undefined,
      )
    );
  };

  const parseProjectsFromUsers = (users: MemberData[]) => {
    const projects = new Map<string, string>();
    users.forEach(user => {
      user.projects?.forEach(({ name, projectId }) => {
        if (!projects.has(projectId)) {
          projects.set(projectId, name);
        }
      });
    });
    setProjects(projects);
    // Verify that only valid projects are in the selection
    if (filters.projects[0] !== 'no' && filters.projects[0] !== '') {
      const filteredProjects = filters.projects.filter(projectId => projects.has(projectId));
      if (filters.projects.length !== filteredProjects.length) {
        setFilters({ ...filters, projects: filteredProjects });
      }
    }
  };

  /**
   * Returns a filter predicate function that checks if user has any of the given projects
   */
  const userHasProjectFilter = (projects: string[]) => {
    return (user: MemberData) =>
      user.projects?.some(({ projectId }) => projects.includes(projectId));
  };

  const getAccountMembers = useMemo(
    () => async () => {
      if (!usersData) return [];
      let users = usersData;
      parseProjectsFromUsers(users);
      // Apply project filters (not supported by API yet)
      if (filters.projects.length > 0) {
        if (filters.projects[0] === 'no') {
          users = users.filter(user => user.projects?.length === 0 && !getIsOwnerOrAdmin(user));
        } else if (filters.projects[0] !== '') {
          users = users.filter(userHasProjectFilter(filters.projects));
        }
      }
      // Apply role filter (not supported by API yet)
      users = UserUtils.filterByRole(users, filters.role);
      return users;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, usersData, authInfo, routeInfo],
  );

  const handleDelete = async (member: MemberData) => {
    await UsersService.deleteUser({
      userEmail: member.email,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  };

  const FiltersElement = (
    <AccountMemberFilters filters={filters} setFilters={setFilters} projects={projects} />
  );

  return (
    <ManageMembers
      getIsDeletable={getIsDeletable}
      getMembers={getAccountMembers}
      refetchMembers={refetchMembers}
      Filters={FiltersElement}
      page="account"
      deleteMemberSnackbar={DELETE_USER_FROM_ACCOUNT({ t })}
      deleteMemberModal={(name: string) => DELETE_MEMBER_FROM_ACCOUNT({ t, name })}
      deleteMembersSnackbar={DELETE_USERS_FROM_ACCOUNT}
      deleteMembersModal={(count: number) => DELETE_MEMBERS_FROM_ACCOUNT({ t, count })}
      onDelete={handleDelete}
      title={t('users.title')}
      inviteButtonMsg={t('users.button.inviteToTeam')}
      deleteButtonMsg={t('button.delete')}
      columns={['name', 'email', 'projects', 'role', 'mfaStatus', 'createdAt', 'accountMenuArea']}
      columnsWidth={['33%', '33%', 160, 120, 76, 120, 60]}
    />
  );
};

export default AccountMembers;
