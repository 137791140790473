import {
  MutationFunction,
  MutationKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

export const useMutationWithInvalidation = <TData, TError, TVariables extends void, TContext>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    '_defaulted' | 'variables'
  >,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(mutationKey);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
