import { TFunction } from 'i18next';
import { orderBy, pick } from 'lodash';

import { JsonObj } from '../../userStats/types';
import { AnnotationChangeType, AnnotationsChangedRow } from '../types/types';
import { getPrecisionText, getRecallText } from './precisionRecallTexts';

export const pieChartDataFormatter = (obj: JsonObj) => {
  const entries = Object.entries(obj);
  const formatted = entries.map(([key, value], _) => {
    return {
      value,
      key,
    };
  });
  return orderBy(formatted, 'value', 'desc');
};

export function heatmapDataFormatter(
  rowData: JsonObj[],
  t: TFunction,
): [string, string, number, string][] {
  return rowData.flatMap(data => [
    [data['Class Name'], 'Precision', data['Precision'], t(getPrecisionText(data['Precision']))],
    [data['Class Name'], 'Recall', data['Recall'], t(getRecallText(data['Recall']))],
  ]);
}

export type TreemapDatum = {
  index: string;
  className: string;
  value: number;
  changeType?: string;
};

export const treemapDataFormatter = (rowData: AnnotationsChangedRow[]): TreemapDatum[] => {
  function getIndexFromChangeType(changeType: AnnotationChangeType): number {
    const indexer = {
      added: 0,
      deleted: 1,
      edited: 2,
      changedClass: 3,
      noChange: 4,
    };
    return indexer[changeType as keyof typeof indexer];
  }
  const pickedRows = rowData.map(row =>
    pick(row, ['classId', 'className', 'added', 'deleted', 'edited', 'changedClass', 'noChange']),
  );

  const treeMapData: TreemapDatum[] = [];
  pickedRows.forEach(row => {
    for (const [key, value] of Object.entries(row)) {
      if (key === 'className' || key === 'classId') continue;
      const box: TreemapDatum = {
        index: String(getIndexFromChangeType(key as AnnotationChangeType)),
        className: row['className'] as string,
        value: value as number,
        changeType: key as string,
      };
      treeMapData.push(box);
    }
  });
  return treeMapData;
};
