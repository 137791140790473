import React, { useState } from 'react';

export const useMultipleInputState = <T extends Record<PropertyKey, any>>(initialState: T) => {
  const [state, setState] = useState(initialState);

  const changeState = (key: keyof T, e: React.ChangeEvent<HTMLInputElement> | string | boolean) => {
    setState(prev => ({
      ...prev,
      [key]: (typeof e === 'string' || typeof e === 'boolean' ? e : e.target.value) as T[keyof T],
    }));
  };

  return [state, changeState] as const;
};
