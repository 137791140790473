import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box as IconBox, Polygon } from '@superb-ai/icons';
import { Box, Button, Dialog, Icon, RealkitDialogStateReturn, Typography } from '@superb-ai/ui';
import { capitalize, compact } from 'lodash';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { VerticalStepper } from '../../../../../../../components/composition/VerticalStepper';
import StackSnackbar from '../../../../../../../components/elements/StackSnackbar';
import { useAuthInfo } from '../../../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../../../contexts/RouteContext';
import ProjectService from '../../../../../../../services/ProjectService';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { convertSelectedImageIdsToQueryString } from '../../../../../utils/filterUtils';
import { getSelectedImageCount } from '../../analytics/utils/utils';
import AnnotationTypeAndClass from './AnnotationTypeAndClass';
import { convertToLabelResultDef } from './AnnotationTypeAndClass/helper';
import { APPROVE_LABEL, ClassAndProperties, MANUAL } from './AnnotationTypeAndClass/type';
import ApplyTags from './ApplyTags';
import AutoSync from './AutoSync';
import ProjectNameAndDescription from './ProjectNameAndDescription';
import SelfAssign from './SelfAssign';

export default function SyncWithNewLabelProjectModal({
  dialog,
}: {
  dialog: RealkitDialogStateReturn;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { datasetInfo } = useDatasetContext();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const { selectedData, deselectedData, selectedAllData, setSelectedAllData } = useActionContext();
  const { totalCount } = useImageScopeContext();

  const { postSendToLabel } = useCurateDatasetService();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { accountName } = useParams<{ accountName: string }>();

  const [projectName, setProjectName] = useState('');
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [isTagValid, setIsTagValid] = useState(true);
  const [projectDescription, setProjectDescription] = useState('');
  const [curateAnnotations, setCurateAnnotations] = useState<ClassAndProperties>();
  const [selfAssign, setSelfAssign] = useState(true);
  const [autoSync, setAutoSync] = useState(false);
  const [appliedTags, setAppliedTags] = useState<string>('');
  const [isStepsCompleted, setIsStepsCompleted] = useState(false);

  const queryString = compact([
    selectedData && selectedData.length
      ? convertSelectedImageIdsToQueryString(selectedData.map(i => i.id))
      : '',
    queryStringWithHiddenFilterAndDeselection,
  ]).join(' AND ');

  function initializeState() {
    setProjectName('');
    setProjectDescription('');
    setIsProjectNameValid(false);
    setIsTagValid(true);
    setCurateAnnotations(undefined);
    setSelfAssign(true);
    setAutoSync(false);
    setAppliedTags('');
  }

  useEffect(() => {
    if (!dialog.visible) {
      initializeState();
    } else {
      if (!selectedAllData && selectedData.length === 0) setSelectedAllData(true);
    }
  }, [dialog.visible]);

  async function handleCreateProjectAndSync() {
    if (!datasetInfo || !isProjectNameValid || !curateAnnotations) return;
    const labelInterface = convertToLabelResultDef(curateAnnotations);

    try {
      const createdProject = await ProjectService.createProject({
        newProjectInfo: {
          selectedWorkapp: 'image-siesta',
          projectName,
          description: projectDescription,
          allowSelfAssign: selfAssign,
          allowAdvancedQa: false,
          curateDatasetId: datasetInfo.id,
          curateSyncSettings: {
            isActive: true,
            operation: autoSync ? APPROVE_LABEL : MANUAL,
            datasetName: datasetInfo.name,
          },
        },
        labelInterface,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      const correlationId = uuidv4();

      await postSendToLabel({
        projectId: createdProject.id,
        params: {
          correlationId,
          datasetId: datasetInfo.id,
          datasetName: datasetInfo.name,
          query: queryString,
          clusterLevel,
          clusterIdIn: appliedFilters?.cluster_id_in,
          sliceId: sliceInfo?.id,
          sliceName: sliceInfo?.name,
          tags: appliedTags !== '' ? [appliedTags] : [],
          withAnnotations: true,
        },
      });

      analyticsTracker.sendToLabelRequested({
        accountId: accountName,
        dataType: 'image',
        dataCount: getSelectedImageCount(selectedAllData, selectedData, deselectedData, totalCount),
        datasetId: datasetInfo.id,
        sliceId: sliceInfo?.id ?? 'NOT_SELECTED',
        projectId: createdProject.id,
        correlationId,
        syncType: 'sync-with-new-project',
      });

      dialog.hide();
      enqueueSnackbar(
        t('curate.dialogs.labelSyncComplete', {
          labelSyncType: t('curate.dialogs.labelSyncType.sync'),
          projectName,
        }),
        {
          variant: 'success',
          action: key => (
            <Button
              variant="text"
              color="white"
              onClick={() => {
                history.push(`/${accountName}/label/project/${createdProject.id}/overview`);
                closeSnackbar(key);
              }}
            >
              {t('button.view')}
            </Button>
          ),
        },
      );
    } catch (error) {
      console.error('Error while creating project and syncing with dataset', error);
    }
  }

  const steps = {
    project: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.project.title'),
      isButtonEnabled: isProjectNameValid && projectName.length > 0,
      isOptional: false,
      summary: `${projectName}${projectDescription ? `, ${projectDescription}` : ''}`,
      content: (
        <ProjectNameAndDescription
          projectName={projectName}
          setProjectName={setProjectName}
          projectDescription={projectDescription}
          setProjectDescription={setProjectDescription}
          setIsProjectNameValid={setIsProjectNameValid}
        />
      ),
    },
    annotationTypeAndClass: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.title'),
      isButtonEnabled:
        Object.keys(curateAnnotations?.box || {}).length +
          Object.keys(curateAnnotations?.polygon || {}).length >
        0,
      isOptional: false,
      summary: (
        <Box display="flex" alignItems="center" color="gray-300" gap={0.5}>
          {Object.entries(curateAnnotations || {}).map(([key, values], index) => (
            <Typography key={key} variant="m-regular" display="flex" alignItems="center" gap={0.5}>
              <Icon icon={key === 'box' ? IconBox : Polygon} />
              {capitalize(
                key === 'box'
                  ? t(
                      'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.typeTarget.box',
                    )
                  : t(
                      'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.typeTarget.polygon',
                    ),
              )}{' '}
              ({(Object.keys(values).length || 0).toLocaleString()}{' '}
              {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.classes')})
              {index !== Object.entries(curateAnnotations || {}).length - 1 && ', '}
            </Typography>
          ))}
        </Box>
      ),
      content: (
        <AnnotationTypeAndClass
          curateAnnotations={curateAnnotations}
          setCurateAnnotations={setCurateAnnotations}
        />
      ),
    },
    selfAssign: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.title'),
      isButtonEnabled: true,
      isOptional: false,
      summary: selfAssign
        ? t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.on.title')
        : t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.off.title'),
      content: <SelfAssign selfAssign={selfAssign} setSelfAssign={setSelfAssign} />,
    },
    autoSync: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.autoSync.title'),
      isButtonEnabled: true,
      isOptional: false,
      summary: autoSync
        ? t('curate.datasets.syncWithNewLabelProject.steps.autoSync.on')
        : t('curate.datasets.syncWithNewLabelProject.steps.autoSync.off'),
      content: <AutoSync autoSync={autoSync} setAutoSync={setAutoSync} />,
    },
    applyTags: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.applyTags.title'),
      isButtonEnabled: isTagValid,
      isOptional: true,
      summary: appliedTags,
      content: (
        <ApplyTags
          appliedTags={appliedTags}
          setAppliedTags={setAppliedTags}
          setIsTagValid={setIsTagValid}
        />
      ),
    },
  };

  return (
    <>
      <Dialog state={dialog} aria-label="Upload dialog" hideOnClickOutside={false}>
        <Dialog.Header>{t('curate.datasets.syncWithNewLabelProject.modalTitle')}</Dialog.Header>
        <Dialog.Content
          border="1px solid"
          borderColor="gray-150"
          borderRadius="2px"
          overflow="overlay"
          style={{ height: '482px', width: '720px' }}
        >
          <StackSnackbar hasTopMargin={false}>
            {dialog.visible && (
              <VerticalStepper
                steps={steps}
                lastStepButton={{
                  text: appliedTags !== '' ? t('button.okay') : t('button.skip'),
                  onClick: () => setIsStepsCompleted(true),
                }}
              />
            )}
          </StackSnackbar>
        </Dialog.Content>
        <Dialog.Actions>
          <Button variant="text" onClick={() => void dialog.hide()}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="strong-fill"
            color="primary"
            disabled={!isStepsCompleted}
            onClick={handleCreateProjectAndSync}
          >
            {t('curate.datasets.syncWithNewLabelProject.button')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
