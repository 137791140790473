import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetsBriefDataQuery } from '../../../../../../queries/dataQueries';
import GridLayout from '../GridLayout';
import DetailModal from './DetailModal';
import ImageCell from './ImageCell';

export default function ImageGridContents() {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { totalCount } = useImageScopeContext();
  const { queryStringAndHiddenFilterQueryString } = useQueryContext();

  const { sliceInfo } = useSliceContext();
  const { setSampleThumbnailUrls, sortBy, sortOrder } = useActionContext();
  const { appliedFilters } = useImageFilterContext();

  const [openDetailIndex, setOpenDetailIndex] = useState<number>();
  const pageSize = 20;

  const dataQuery = useDatasetsBriefDataQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name || '',
    pageSize,
    appliedFilters,
    sortBy: [sortBy, 'clusters_search.level_leaf.id', 'clusters_search.level_leaf.score', 'id'],
    sortOrder: [sortOrder, sortOrder, 'desc', 'desc'],
  });

  // TODO: useEffect is not ideal here.
  // It would be nicer to refactor sampleThumbnailUrls to derive its state from query data.
  useEffect(() => {
    if (!dataQuery.data?.pages[0].results) return;
    setSampleThumbnailUrls(
      dataQuery.data.pages[0].results
        .slice(0, 4)
        .map(({ id, thumbnailUrl }) => ({ id, thumbnailUrl })),
    );
  }, [dataQuery.data, setSampleThumbnailUrls]);

  const loadedItems = dataQuery.data?.pages.flatMap(p => p.results) ?? [];

  return (
    <GridLayout
      queryResult={dataQuery}
      DetailModal={
        openDetailIndex !== undefined ? (
          <DetailModal
            pageSize={pageSize}
            totalCount={totalCount || 0}
            // queries
            loadMoreItem={async () => {
              await dataQuery.fetchNextPage();
            }}
            loadedItems={loadedItems}
            openImageIndex={openDetailIndex}
            setOpenImageIndex={setOpenDetailIndex}
          />
        ) : (
          <></>
        )
      }
      ImageCell={ImageCell}
      setOpenIndex={setOpenDetailIndex}
    />
  );
}
