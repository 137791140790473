import React, { useEffect, useState } from 'react';

import { pickBy } from 'lodash';

import { StateGetterSetter } from '../../../contexts/types';
import MathUtils from '../../../utils/MathUtils';
import { DIAGNOSIS_DISPLAY_NULL_VALUE } from '../components/datasets/dataset/modelDiagnosis/diagnosis/analytics/transformer';
import {
  ConfidenceRangeFilter,
  EvaluationResult,
  IouRangeFilter,
} from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import useDiagnosisViewSearchParam from '../hooks/useViewSearchParam';
import { useModelDiagnosisEvaluationValuesCountQuery } from '../queries/diagnosisEvaluationValueQueries';
import { useParams } from 'react-router';
import { usePublicDatasetContext } from './PublicDatasetContextProvider';
import { useDiagnosisModelContext } from './DiagnosisModelContext';
import { useDiagnosisCommonFilterContext } from './DiagnosisCommonFilterContext';

type FilterState = Partial<{
  annotationClassIn?: (string | null)[];
  predictionClassIn?: (string | null)[];
  evaluationResult?: EvaluationResult[];
  iouRange?: IouRangeFilter;
  confidenceRange?: ConfidenceRangeFilter;
}>;

type ContextProps = FilterState &
  StateGetterSetter<['isFilterOpen', 'setIsFilterOpen'], boolean> & {
    filterBody: FilterState;
    totalEvaluationsCount: number;
  } & {
    updateSelectedFilter: (newState: Partial<FilterState>) => void;
    initSelectedFilter: () => void;
  };

const Context = React.createContext({} as ContextProps);

function getClassOrNull(value: string) {
  return value !== DIAGNOSIS_DISPLAY_NULL_VALUE ? value : null;
}

const useProvider = () => {
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const { showPublicDatasets } = usePublicDatasetContext();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<FilterState>({});
  const { searchParams } = useDiagnosisViewSearchParam();
  const { selectedDiagnosis } = useDiagnosisModelContext();
  const { splitIn, sliceId } = useDiagnosisCommonFilterContext();

  const updateSelectedFilter = (newState: Partial<FilterState>) => {
    setSelectedFilter(prevState => pickBy({ ...prevState, ...newState }, v => v !== undefined));
  };

  function initSelectedFilter() {
    setSelectedFilter({});
  }
  const { data } = useModelDiagnosisEvaluationValuesCountQuery({
    diagnosisId,
    datasetId,
    filter: selectedFilter,
    splitIn,
    sliceId,
    fromPublicDatasets: showPublicDatasets,
    modelSource: selectedDiagnosis.modelSource,
  });

  useEffect(() => {
    const { evaluation_result, prediction, annotation, metric, min, max } = searchParams;
    if (!(evaluation_result || prediction || annotation || metric || min || max)) return;

    const rangeFilter = {
      min: MathUtils.round(Number(min || 0), 2),
      max: MathUtils.round(Number(max || 1), 2),
    };

    const filter = {
      ...(evaluation_result && {
        evaluationResult: [evaluation_result] as EvaluationResult[],
      }),
      ...(annotation &&
        annotation !== 'all' && { annotationClassIn: [getClassOrNull(annotation)] }),
      ...(prediction &&
        prediction !== 'all' && { predictionClassIn: [getClassOrNull(prediction)] }),
      ...(metric === 'iou' && {
        iouRange: {
          ...rangeFilter,
          includeNull: evaluation_result === 'FP' || evaluation_result === 'FN', // default
        },
      }),
      ...(metric === 'confidence' && {
        confidenceRange: {
          ...rangeFilter,
          includeNull: evaluation_result === 'FP' || evaluation_result === 'FN',
        },
      }),
    };

    setSelectedFilter(filter);
  }, []);

  return {
    isFilterOpen,
    setIsFilterOpen,
    filterBody: selectedFilter,
    initSelectedFilter,
    updateSelectedFilter,
    totalEvaluationsCount: data?.count || 0,
  };
};

export const useDiagnosisGridFilterContext = (): ContextProps => {
  return React.useContext(Context);
};

export const DiagnosisGridFilterProvider: React.FC = ({ children }) => {
  const diagnosisInfo = useProvider();
  return <Context.Provider value={diagnosisInfo}>{children}</Context.Provider>;
};
