import { Trans, useTranslation } from 'react-i18next';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { PrivaryPolicyURL, TermsOfUseURL } from '../../../consts/ConsentLink';

export default function Policy() {
  const { t } = useTranslation();

  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography variant="s-regular" color="gray-300">
        <Trans t={t} i18nKey="auth.messages.youAgreeToPolicy">
          By creating your Superb AI Account you agree to our
          <br />
          <LinkTypography color="gray-400" as="a" target="_blank" href={TermsOfUseURL}>
            Terms of Use
          </LinkTypography>{' '}
          and{' '}
          <LinkTypography color="gray-400" as="a" target="_blank" href={PrivaryPolicyURL}>
            Privacy Policy
          </LinkTypography>
          .
        </Trans>
      </Typography>
    </Box>
  );
}
