import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@superb-ai/norwegian-forest';

const ResultImageObserverContainer: React.FC<{ aspectRatio?: number }> = ({
  children,
  aspectRatio = 9 / 16,
}) => {
  const itemRef = useRef<Element>();
  const [intersect, setIntersect] = useState(false);
  const [size, setSize] = useState([0, 0]); // width, height
  const height = size[0] * aspectRatio;

  const intersectCallback = useCallback(entries => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIntersect(true);
    }
  }, []);

  const resizeCallback = useCallback(entries => {
    const [entry] = entries;
    if (entry.contentRect) {
      setSize([entry.contentRect.width, entry.contentRect.height]);
    }
  }, []);

  useEffect(() => {
    if (!itemRef.current) return;
    const intersectObserver = new IntersectionObserver(intersectCallback);
    intersectObserver.observe(itemRef.current);
    return () => {
      intersectObserver.disconnect();
    };
  }, [intersectCallback]);

  useEffect(() => {
    if (!itemRef.current) return;
    const resizeObserver = new ResizeObserver(resizeCallback);
    resizeObserver.observe(itemRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeCallback]);

  return (
    <Box ref={itemRef} height={`${height || 150}px`}>
      {intersect && children}
    </Box>
  );
};

export default ResultImageObserverContainer;
