import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Skeleton from '@mui/material/Skeleton';
import {
  Box,
  Button,
  IconButton,
  Link,
  StatusChip,
  Tooltip,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { Status } from '@superb-ai/norwegian-forest/dist/types/suite/status';
import { Typography } from '@superb-ai/ui';
import { CancelTokenSource } from 'axios';
import { snakeCase } from 'lodash';

import { AdvancedAIFeaturesStatus } from '../../../types/advancedAIFeaturesTypes';
import { Curation } from '../../../types/curationTypes';
import {
  MISLABEL_DETECTION_FAIL_MESSAGES,
  MislabelDetection,
  MislabelDetectionFailMessageType,
} from '../../../types/mislabelDetectionTypes';
import { SemanticSearch } from '../../../types/semanticsearchTypes';
import { openChatWidget } from '../../../utils/chatWidget';
import FileUtils from '../../../utils/FileUtils';
import { db } from './HistoryTable';
import { getValidIndexedDbInfo } from './mislabelDetection/indexedDbUtils';
import RequestProgress from './RequestProgress';

export type HistoryItemProps = {
  id: string;
  loadHistory: (id: string) => Promise<MislabelDetection | Curation | SemanticSearch>;
  deleteHistory?: (id: string) => Promise<void>;
  cancelSource?: CancelTokenSource;
  InfoBoard: ({ info }: { info: MislabelDetection | Curation | SemanticSearch }) => JSX.Element;
  infoBoardContainerBoxProps?: React.ComponentProps<typeof Box>;
  type: 'mislabel-detection' | 'curation' | 'semantic-search';
};
export default function HistoryItem({
  id,
  loadHistory,
  InfoBoard,
  deleteHistory,
  infoBoardContainerBoxProps,
  cancelSource,
  type,
}: HistoryItemProps) {
  type AdvancedAIFeatures = MislabelDetection | Curation | SemanticSearch;
  const history = useHistory();
  const [info, setInfo] = useState<AdvancedAIFeatures>();
  const [isHovered, setIsHovered] = useState(false);
  const [failureType, setFailureType] = useState<{
    type: MislabelDetectionFailMessageType;
    detail?: string[];
  }>();
  const { openModal, closeModal } = useAlertModal();
  const { t } = useTranslation();

  const getInfoFromDB = {
    'mislabel-detection': async () => {
      const dbMislabelHistoryTable = await getValidIndexedDbInfo({
        db: db.mislabelHistoryTable,
        id,
      });

      if (dbMislabelHistoryTable) {
        const { results, ...info } = dbMislabelHistoryTable;
        return info;
      }
    },
    curation: async () => {
      const dbCurationHistoryTable = await db.curationTable.get({ id });
      if (dbCurationHistoryTable) {
        const { results, ...info } = dbCurationHistoryTable;
        return info;
      }
    },
    'semantic-search': async () => {
      const dbSemanticSearchHistoryTable = await db.semanticSearchTable.get({ id });
      if (dbSemanticSearchHistoryTable) {
        const { results, ...info } = dbSemanticSearchHistoryTable;
        return info;
      }
    },
  }[type];

  type PutInfoFromDBFunction = <T extends AdvancedAIFeatures>(
    res: T,
    resultJSON: any,
  ) => Promise<void>;

  type PutInfoFromDB = {
    'mislabel-detection': PutInfoFromDBFunction;
    curation: PutInfoFromDBFunction;
    'semantic-search': PutInfoFromDBFunction;
  };

  const putInfoToDB = (res: AdvancedAIFeatures, resultJSON: any) =>
    ({
      'mislabel-detection': async () => {
        await db.mislabelHistoryTable.put({
          ...(res as MislabelDetection),
          results: resultJSON,
          savedAt: new Date(),
        });
      },
      curation: async () => {
        await db.curationTable.put({
          ...(res as Curation),
          results: resultJSON,
        });
      },
      'semantic-search': async () => {
        await db.semanticSearchTable.put({
          ...(res as SemanticSearch),
          results: resultJSON,
        });
      },
    }[type]);

  useEffect(() => {
    (async () => {
      const dbInfo = await getInfoFromDB();
      if (dbInfo) {
        setInfo(dbInfo);
        return;
      }

      const res = await loadHistory(id);
      setInfo(res);

      if (res?.resultUrl && res.status === 'SUCCESS') {
        const { resultUrl } = res;
        const resultJSON = await FileUtils.readUrlJson(resultUrl);
        await putInfoToDB(res, resultJSON)();
      }

      if (res?.resultUrl && res.status === 'FAIL') {
        const { resultUrl } = res;
        const resultJSON = await FileUtils.readUrlJson(resultUrl);
        setFailureType({
          type: MISLABEL_DETECTION_FAIL_MESSAGES.includes(resultJSON.error)
            ? resultJSON.error
            : 'Unexpected',
          detail: resultJSON.error === 'ClassMismatch' ? resultJSON.detail : undefined,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (!info) return;

    const { status } = info;

    if (status === 'FAIL' || status === 'SUCCESS') return;

    const handleHistory = async () => {
      try {
        const res = await loadHistory(id);
        setInfo(res);
      } catch (err: any) {
        if (cancelSource) {
          cancelSource.cancel();
        }
      }
    };
    const reloadHistory = setTimeout(handleHistory, 10000);

    return () => {
      clearTimeout(reloadHistory);
    };
    // eslint-disable-next-line
  }, [info]);

  const onDelete = async () => {
    openModal({
      title: 'Remove History',
      content: (
        <>
          {info && (
            <Typography variant="m-regular">
              Are you sure you want to remove &quot;{info.name}&quot;? This action cannot be undone.
            </Typography>
          )}
        </>
      ),
      mainButton: {
        text: 'Remove',
        onClick: async () => {
          if (!deleteHistory) return;
          await deleteHistory(id);
        },
      },
      subButton: {
        text: 'Cancel',
        onClick: () => closeModal(),
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
    });
  };

  const getLabel = () => {
    const getColor = (state: AdvancedAIFeaturesStatus): Status => {
      switch (state) {
        case 'SUCCESS':
          return 'success';
        case 'PROCESSING':
          return 'in_progress';
        case 'PENDING':
          return 'in_queue';
        case 'FAIL':
          return 'failed';
        default:
          return snakeCase(state) as 'waiting' | 'in_queue' | 'canceled' | 'failed';
      }
    };

    const convertState = (state: AdvancedAIFeaturesStatus): string => {
      switch (state) {
        case 'FAIL':
          return 'Request failed';
        case 'PENDING':
          return 'Request has been queued';
        case 'SUCCESS':
          return 'Ready';
        case 'PROCESSING':
        default:
          return 'In progress';
      }
    };

    const getFailureMessage = {
      'mislabel-detection': () => {
        const messageComponent = (
          <Box display="flex" alignItems="center" gap="4px">
            {t(`advancedAIFeatures.mislabelDetection.history.failMessage.${failureType?.type}`)}
            {failureType?.type === 'Unexpected' && (
              <Link variant="bold4" themedColor="primary" onClick={() => openChatWidget()}>
                → {t('advancedAIFeatures.mislabelDetection.history.failMessage.contactUs')}
              </Link>
            )}
          </Box>
        );

        if (failureType?.type === 'ClassMismatch' && failureType.detail) {
          return (
            <Tooltip placement="right" anchorEl={messageComponent}>
              {failureType.detail.join(', ')}
            </Tooltip>
          );
        }

        return messageComponent;
      },
      curation: () => <></>,
      'semantic-search': () => <></>,
    };

    return (
      <Box display="flex" alignItems="center" gap="8px">
        {info?.status && (
          <StatusChip color={getColor(info?.status)}>{convertState(info?.status)}</StatusChip>
        )}
        {info?.status === 'FAIL' && failureType && (
          <Typography variant="m-regular" color="red" style={{ color: '#ff625a' }}>
            {getFailureMessage[type]()}
          </Typography>
        )}
      </Box>
    );
  };

  if (!info) {
    return (
      <Box display="flex" flexDirection="column" shadow={1} mb={3}>
        <Box display="flex" alignItems="center" px={3} py={2} bb>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="60px"
            height="60px"
            pr={2}
          >
            <Skeleton variant="circular" width="44px" height="44px" />
          </Box>
          <Box>
            <Skeleton width="60px" />
            <Skeleton width="100px" />
          </Box>
        </Box>
        <Box ml={7.5} px={3} py={2}>
          <Skeleton />
          <Skeleton width="80%" />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" shadow={1} mb={3}>
      <Box
        display="flex"
        alignItems="center"
        px={3}
        py={2}
        bb
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="60px"
          height="60px"
          pr={2}
        >
          <RequestProgress progressInfo={info.progress} state={info.status} />
        </Box>
        <Box>
          {getLabel()}
          <Box mt={0.5} display="flex" alignItems="center" gap="8px" height="24px">
            <Typography variant="l-strong">{info.name}</Typography>
            {isHovered && (
              <IconButton icon="trash" color="textDefault" size="xs" onClick={onDelete} />
            )}
          </Box>
        </Box>
        {info.status === 'SUCCESS' && type === 'semantic-search' ? (
          <Box ml="auto" display="flex" alignItems="center" gap="10px">
            <Button
              {...({
                as: Link,
                to: history.location.pathname + '/new?search-again-id=' + info.id,
              } as any)}
              className="search_again_buttons"
              color="grey"
              variant="stroke"
            >
              Search Again
            </Button>
            <Button
              {...({ as: Link, to: history.location.pathname + '/results/' + info.id } as any)}
              className="result_buttons"
              color="grey"
            >
              {t('advancedAIFeatures.mislabelDetection.button.viewHistory')}
            </Button>
          </Box>
        ) : (
          <Box ml="auto" display="flex" alignItems="center">
            <Button
              {...({ as: Link, to: history.location.pathname + '/results/' + info.id } as any)}
              className="result_buttons"
              color="grey"
              disabled={info.status !== 'SUCCESS'}
            >
              {t('advancedAIFeatures.mislabelDetection.button.viewHistory')}
            </Button>
          </Box>
        )}
      </Box>
      <Box {...infoBoardContainerBoxProps}>
        <InfoBoard info={info} />
      </Box>
    </Box>
  );
}
