import { Fragment } from 'react';
import { useLocation } from 'react-router';

import { Box, Typography } from '@superb-ai/ui';

import { ExtendedBaseMenuItem } from '../../../menu/AppMenu';
import AppsMenuItem from './AppsMenuItem';

export default function AppsMenu({
  visibleItems,
  wrapItem,
}: {
  visibleItems: ExtendedBaseMenuItem[];
  wrapItem: (item: JSX.Element, menuItem: ExtendedBaseMenuItem) => JSX.Element;
}) {
  const location = useLocation();
  // group menuItems by group name
  const menuItemsByGroup = visibleItems.reduce((acc, item) => {
    const group = item.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(item);
    return acc;
  }, {} as Record<string, ExtendedBaseMenuItem[]>);

  const currentPath = location.pathname.split('/')[2];

  return (
    <nav style={{ height: '100%' }}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <Box>
          <Typography variant="s-strong" color="gray-300" whiteSpace="nowrap">
            <span className="group-label">Platform</span>
          </Typography>
          <ul className="menu-list">
            {menuItemsByGroup.suite.map(item => (
              <Fragment key={item.path}>
                {wrapItem(
                  <AppsMenuItem item={item} currentPath={currentPath} group="suite" />,
                  item,
                )}
              </Fragment>
            ))}
          </ul>
        </Box>
        <Box>
          <Typography variant="s-strong" color="gray-300" whiteSpace="nowrap">
            <span className="group-label">Account</span>
          </Typography>
          <ul className="menu-list">
            {menuItemsByGroup.account.map(item => (
              <Fragment key={item.path}>
                {wrapItem(
                  <AppsMenuItem item={item} currentPath={currentPath} group="account" />,
                  item,
                )}
              </Fragment>
            ))}
          </ul>
        </Box>
      </Box>
    </nav>
  );
}
