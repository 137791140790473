import { isEmpty, isObject } from 'lodash';

import {
  AnnotationFilterSchema,
  ClusterLevel,
  ImageClusterFilterSchema,
  ImageFilterSchema,
  ProjectionInParam,
} from '../components/datasets/dataset/filter/types';
import { EvaluationFilterSchema } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import { AnnotationType } from '../types/annotationTypes';

function stringifyObjectFilter(filter: any): string[] {
  if (isObject(filter))
    return Object.entries(filter).flatMap(([key, value]) => {
      return `${key}/${stringifyObjectFilter(value)}`;
    });
  return filter;
}

export function stringifyFilters(
  appliedFilters: EvaluationFilterSchema | AnnotationFilterSchema | ImageFilterSchema | undefined,
): string {
  if (!appliedFilters || isEmpty(appliedFilters)) return '';
  const filterValues = appliedFilters
    ? Object.values(appliedFilters)
        .filter(value => value !== undefined)
        .flatMap(filter => stringifyObjectFilter(filter))
    : [];
  const stringifiedFilters = filterValues.join('&-&');
  return stringifiedFilters;
}

export function formatAnnotationFilter(filters?: AnnotationFilterSchema):
  | {
      annotation_filters: AnnotationFilterSchema | {};
      projection_in?: ProjectionInParam;
      cluster_level?: ClusterLevel;
    }
  | { annotation_filters: {} } {
  if (!filters) return { annotation_filters: {} };

  return {
    annotation_filters: {
      metadata_in: filters?.metadata_in || {},
      ...(filters?.metadata_all_values_in &&
        filters.metadata_all_values_in.length && {
          metadata_all_values_in: filters.metadata_all_values_in,
        }),
      ...(filters?.annotation_type_in &&
        filters.annotation_type_in.length && {
          annotation_type_in: filters.annotation_type_in,
        }),
      ...(filters?.annotation_class_in &&
        filters.annotation_class_in.length && {
          annotation_class_in: filters.annotation_class_in,
        }),
      ...(filters?.annotation_class_cluster_in &&
        filters.annotation_class_cluster_in.length && {
          annotation_class_cluster_in: filters.annotation_class_cluster_in,
        }),
    },
    ...(filters?.projection_in && {
      projection_in: filters.projection_in,
    }),
    cluster_level: filters.cluster_level,
  };
}

export function formatImageClusterFilter(
  filters?: ImageClusterFilterSchema | undefined,
): ImageClusterFilterSchema | undefined {
  if (!filters) return undefined;
  return {
    cluster_id_in: filters?.cluster_id_in,
    cluster_level: filters.cluster_level,
  };
}

export function formatImageFilter(filters?: ImageFilterSchema | undefined): ImageFilterSchema | {} {
  if (!filters) return {};
  return {
    ...(filters?.projection_in && {
      projection_in: filters.projection_in,
    }),
    ...(filters?.cluster_id_in?.length &&
      filters?.cluster_level && {
        cluster_id_in: filters.cluster_id_in,
      }),
    cluster_level: filters.cluster_level,
  };
}

export function convertSelectedImageIdsToQueryString(selectedImageIds: string[]) {
  return `images.id in ${JSON.stringify(selectedImageIds)}`;
}

export function convertDeselectedImageIdsToQueryString(deselectedImageIds: string[]) {
  if (deselectedImageIds.length === 0) return '';
  return `images.id not in ${JSON.stringify(deselectedImageIds)}`;
}

export function convertSelectedClassesToQueryString(
  classes: { name: string; type: AnnotationType }[],
) {
  const conditions = classes.map(
    item => `annotations."${item.name}".count > 0 AND annotations.type.${item.type}.count > 0`,
  );
  return conditions.join(' OR ');
}

export function convertSelectedSuperClustersToQueryString(
  superClusterIds: string[],
  clusterLevel: ClusterLevel,
) {
  return `images.super_cluster.level_${clusterLevel} in ${JSON.stringify(superClusterIds)}`;
}
export function convertSelectedLeafClustersToQueryString(leafClusterIds: string[]) {
  return `images.leaf_cluster in ${JSON.stringify(leafClusterIds)}`;
}

export function convertSuperClustersWithDeselectedLeavesToQueryString(
  superClusterId: string,
  clusterLevel: ClusterLevel,
  leafClusterIds: string[],
) {
  return `(images.super_cluster.level_${clusterLevel} = "${superClusterId}" AND images.leaf_cluster not in ${JSON.stringify(
    leafClusterIds,
  )})`;
}
export function convertDeselectedLeafClustersToQueryString(leafClusterIds: string[]) {
  return `images.leaf_cluster not in ${JSON.stringify(leafClusterIds)}`;
}

export function parseQueryStringToGetClusterInfo(queryString: string) {
  const matchers = [
    {
      regex: /images\.super_cluster\.level_(\d+) in (\[.*?\])/,
      parse: (match: RegExpMatchArray) => ({
        type: 'SelectedSuperClusters',
        superClusterIds: parseJsonArray(match[2]),
        clusterLevel: parseInt(match[1]),
      }),
    },
    {
      regex: /images\.leaf_cluster in (\[.*?\])/,
      parse: (match: RegExpMatchArray) => ({
        type: 'SelectedLeafClusters',
        leafClusterIds: parseJsonArray(match[1]),
      }),
    },
    {
      regex:
        /images\.super_cluster\.level_(\d+) = "(.*?)" AND images\.leaf_cluster not in (\[.*?\])/,
      parse: (match: RegExpMatchArray) => ({
        type: 'SuperClustersWithDeselectedLeaves',
        superClusterId: match[2],
        clusterLevel: parseInt(match[1]),
        leafClusterIds: parseJsonArray(match[3]),
      }),
    },
    {
      regex: /images\.leaf_cluster not in (\[.*?\])/,
      parse: (match: RegExpMatchArray) => ({
        type: 'DeselectedLeafClusters',
        leafClusterIds: parseJsonArray(match[1]),
      }),
    },
  ];

  // Function to parse JSON arrays safely
  const parseJsonArray = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      throw new Error('Invalid JSON string in query');
    }
  };

  // Function to clean up remaining query string
  const cleanRemainingQuery = (remainingQuery: string) => {
    return remainingQuery
      .replace(/^(AND|OR)\s+|\s+(AND|OR)$/gi, '')
      .replace(/\(\s*\)/g, '')
      .trim();
  };

  // Function to find and parse the first matching pattern
  const findAndParse = (query: string) => {
    for (const matcher of matchers) {
      const match = query.match(matcher.regex);
      if (match) {
        let remainingQuery = query.replace(match[0], '').trim();
        remainingQuery = cleanRemainingQuery(remainingQuery);
        return {
          parsed: matcher.parse(match),
          remainingQuery,
        };
      }
    }
    return null;
  };

  // Initialize the parsed results array and process the main query string
  const parsedResults = [];
  let remainingQuery = queryString;

  while (remainingQuery) {
    const result = findAndParse(remainingQuery);
    if (result) {
      parsedResults.push(result.parsed);
      remainingQuery = result.remainingQuery;
    } else {
      break;
    }
  }

  // Return the parsed results and any remaining query
  return {
    parsed: parsedResults,
    remainingQuery: cleanRemainingQuery(remainingQuery),
  };
}
