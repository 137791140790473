import { sortBy } from 'lodash';

import { ExtendedScaleBand } from '../../interfaces/d3Types';

/**
 * Re-Bins (or aggregates) data to target binSize: 1, 5, 10
 * Source data is always a 1-unit bin.
 *
 * Mapping of scores in 1-unit bins to those in 5-unit bins:
 *  0 -> 0
 *  1 ~ 5 -> 5
 *  6 ~ 10 -> 10
 *  ...
 *  91 ~ 95 -> 95
 *  96 ~ 100 -> 100
 *
 * We don't have a use case to bin from higher unit bin to a lower
 * unit bin, as input data is always preserved, and reBinData is
 * called just before render and download in Histogram.
 */

export type BinSizeOptions = 1 | 5 | 10;
export type ScoreBinInfo = { scoreBin: number; labelCount: number };

export const reBinData = (data: ScoreBinInfo[], targetBin: BinSizeOptions): ScoreBinInfo[] => {
  const reBinnedData = [];
  if (targetBin === 1) {
    return data;
  }
  let temp = { scoreBin: 0, labelCount: 0 };
  for (const [i, scoreObj] of sortBy(data, 'scoreBin').entries()) {
    if (i % targetBin === 0) {
      if (i === 0) {
        reBinnedData.push(scoreObj);
        temp = { scoreBin: targetBin, labelCount: 0 };
      } else {
        // add previous data
        temp['labelCount'] += scoreObj['labelCount'];
        reBinnedData.push(temp);
        temp = { scoreBin: i + targetBin, labelCount: 0 };
      }
    } else {
      temp['labelCount'] += scoreObj['labelCount'];
    }
  }
  return reBinnedData;
};

/**
 * d3.scaleBand does not have invert method by default, so we implement it in order
 * to get start and end x values for d3 brushX event.
 */
export function scaleBandInvert(xValue: number, xScale: ExtendedScaleBand): number {
  const domain = xScale.domain() as number[];
  const paddingOuter = xScale(domain[0]) || 0;

  const eachBand = xScale.step();
  const index = Math.floor((xValue - paddingOuter) / eachBand);
  return domain[Math.max(0, Math.min(index, domain.length - 1))];
}
