import React from 'react';

import { CaretDown, CaretRight } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';

// eslint-disable-next-line
const calHasMoreInformation = (objectClass: any) => {
  if (objectClass.annotationType === 'KEYPOINT') {
    return true;
  }
  return false;
};

// eslint-disable-next-line
const ObjectClassItemMoreInformationToggle = (props: any): React.ReactElement | null => {
  const { objectClass, isOpenMoreInformation, setIsOpenMoreInformation } = props;
  const hasMoreInformation = calHasMoreInformation(objectClass);

  if (!hasMoreInformation) return null;
  const handleClickArrow = () => {
    setIsOpenMoreInformation(!isOpenMoreInformation);
  };

  return (
    <IconButton
      icon={isOpenMoreInformation ? CaretDown : CaretRight}
      size="s"
      variant="text"
      onClick={handleClickArrow}
      color="gray"
    />
  );
};

export default ObjectClassItemMoreInformationToggle;
