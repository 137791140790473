import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Icon, Typography } from '@superb-ai/norwegian-forest';
import { Checkbox, ToggleButton } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';
import { ensureDelay } from 'timed-async';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ProjectService from '../../../../../services/ProjectService';
import ProjectUtils from '../../../../../utils/ProjectUtils';

export default function AdvancedSettings() {
  const { t } = useTranslation();
  const {
    project: { workapp, labelInterface },
    updateProjectInfo,
  } = useProjectInfo();
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const projectType = ProjectUtils.getProjectDataType(workapp);
  const isOnlyCategorizationProject =
    labelInterface.objectTracking?.annotationTypes.length === 1 &&
    labelInterface.objectTracking?.annotationTypes[0] === 'image category';

  const [loadingTarget, setLoadingTarget] = useState<'tracking' | 'frequency filter'>();

  const objectTrackingOn = labelInterface.aiAdvancedSettings?.tracking ?? true;
  const frequencyFilterOn = labelInterface.aiAdvancedSettings?.frequencyFilter ?? true;

  // Show advanced settings only when this project is for video object tracking
  if (projectType !== 'video' || isOnlyCategorizationProject) return <></>;

  const handleToggleSettings = (type: 'tracking' | 'frequency filter') => async () => {
    const newLabelInterface =
      type === 'tracking'
        ? {
            ...labelInterface,
            aiAdvancedSettings: {
              tracking: !objectTrackingOn,
              frequencyFilter: !objectTrackingOn,
            },
          }
        : {
            ...labelInterface,
            aiAdvancedSettings: { tracking: objectTrackingOn, frequencyFilter: !frequencyFilterOn },
          };

    try {
      setLoadingTarget(type);
      await ensureDelay(async () => {
        await ProjectService.editLabelInterface({
          labelInterface: newLabelInterface,
          isGuest: false,
          urlInfo: routeInfo.urlMatchInfo,
        });
        await updateProjectInfo();
      }, 2500);
      enqueueSnackbar('Successfully changed Auto-Label settings.', {
        variant: 'success',
      });
    } finally {
      setLoadingTarget(undefined);
    }
  };

  return (
    <Box>
      <Typography variant="headline5" style={{ lineHeight: 1 }}>
        {t('autoLabel.aiAdvancedFeatures.title')}
      </Typography>
      <Box mt={2} shadow={1}>
        <Box py={1.5} px={3.5} bb display="flex" alignItems="center">
          <Box>
            <Typography variant="headline6">
              {t('autoLabel.aiAdvancedFeatures.content.objectTracking')}
            </Typography>
            <Typography variant="body3">
              {t('autoLabel.aiAdvancedFeatures.content.1')}
              <br />
              {t('autoLabel.aiAdvancedFeatures.content.2')}
            </Typography>
          </Box>
          <Box ml="auto">
            {loadingTarget === 'tracking' ? (
              <Box width="22px" display="flex" justifyContent="center">
                <Icon name="loadingSpinner" />
              </Box>
            ) : (
              <ToggleButton checked={objectTrackingOn} onClick={handleToggleSettings('tracking')} />
            )}
          </Box>
        </Box>
        <Box
          py={1.5}
          px={3.5}
          themedBackgroundColor={['grey', 50]}
          display="flex"
          alignItems="center"
        >
          {loadingTarget ? (
            <Box
              height="24px"
              width="24px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon name="loadingSpinner" size="16px" />
            </Box>
          ) : (
            <Checkbox
              value={frequencyFilterOn}
              disabled={!objectTrackingOn}
              onClick={handleToggleSettings('frequency filter')}
            />
          )}
          <Box
            pl={0.5}
            onClick={() => {
              if (!objectTrackingOn || loadingTarget) return;
              handleToggleSettings('frequency filter')();
            }}
          >
            <Typography
              variant="headline7"
              themedColor={objectTrackingOn ? 'textDefault' : ['grey', 200]}
              style={{ cursor: 'default' }}
            >
              {t('autoLabel.aiAdvancedFeatures.footer.frequencyFilter')}
            </Typography>
          </Box>
          <Typography
            style={{ marginLeft: '24px' }}
            variant="body3"
            themedColor={objectTrackingOn ? 'textDefault' : ['grey', 200]}
          >
            {t('autoLabel.aiAdvancedFeatures.footer.description')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
