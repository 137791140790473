import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLeft, ArrowRight, ChevronDown, Clear, Copy, Hide, Visible } from '@superb-ai/icons';
import {
  Box,
  Dialog,
  Icon,
  IconButton,
  LoadingIndicator,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import dynamic from 'next/dynamic';
import { useSnackbar } from 'notistack';

import { Row } from '../../../components/elements/Row';
import { PixiDetailAnnotation } from '../../../components/pages/aiAdvancedFeatures/Shapes';
import { COPY_SUCCESS } from '../../../consts/SnackbarMessage';
import FileUtils from '../../../utils/FileUtils';
import { useDatasetImageQueries } from '../../Curate/queries/dataQueries';
import { AnnotationTypeCoordinate, Polygon } from '../../Curate/types/annotationTypes';
import { getAnnotationIcon, textEllipsisStyle } from '../components/components';
import { FormattedAnnotation } from '../recognition-ai/detail/SamplePredictionCabinet';
import { annotationTypeResolver, classNameResolver } from '../recognition-ai/detail/utils';
import { GenerationAIModelDetailTraining, SamplePredictionData } from '../services/types';
import { colorPalette } from '../utils/colorUtils';
import { ITERATION_LENGTH } from './constants';
const PixiDetailImage = dynamic({
  loader: () => import('../recognition-ai/detail/PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});

export const SamplePredictionDialog = ({
  state,
  modelTraining,
  iterationIndex,
  referenceAnnotation,
  iterationAnnotation,
  isRefLoading,
  isIterLoading,
  datasetId,
  header,
  modelName,
  comparisonModelTraining,
  comparisonModelId,
  comparisonModelName,
  comparisionIterationAnnotation,
  isCompareLoading,
  title,
}: {
  state: ReturnType<typeof useDialogState>;
  modelTraining: GenerationAIModelDetailTraining;
  iterationIndex: number;
  referenceAnnotation: SamplePredictionData;
  iterationAnnotation: SamplePredictionData;
  isRefLoading: boolean;
  isIterLoading: boolean;
  datasetId?: string;
  header?: ReactNode;
  modelName?: string;
  comparisonModelTraining?: GenerationAIModelDetailTraining;
  comparisonModelId?: string;
  comparisonModelName?: string;
  comparisionIterationAnnotation?: SamplePredictionData;
  isCompareLoading?: boolean;
  title?: ReactNode;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [annotationHoveredMap, setAnnotationHoveredMap] = useState<Record<string, boolean>>({});
  const [filteredPredictionAnnotationsIds, setFilteredPredictionAnnotationsIds] = useState<
    string[]
  >([]);
  const [referenceIndex, setReferenceIndex] = useState(0);
  const [clickedAnnotationId, setClickedAnnotationId] = useState(null);
  const [hoveredAnnotatinoId, setHoveredAnnotatinoId] = useState(null);
  const annotationColorMap: Record<string, string> = Object.fromEntries(
    referenceAnnotation.categories.map((category, idx) => [
      category.name,
      category.color ?? colorPalette[idx % colorPalette.length].hex,
    ]),
  );
  const datasetImagesQueries = useDatasetImageQueries({
    datasetId,
    imageIds: modelTraining.additionalInfo.samplePredictionImageIds,
  });
  const imageId = modelTraining.additionalInfo.samplePredictionImageIds.find(id =>
    modelTraining.modelTrainingReferenceImages.images[referenceIndex].fileName.includes(id),
  );

  const dataset = datasetImagesQueries
    .filter(query => !query.isLoading)
    .flatMap(query => query.data)
    .find(dataset => dataset.id === imageId);

  const sampleDetailType = comparisonModelId
    ? 'performance'
    : title && !header
    ? 'baseline-sample'
    : 'progress';

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(annotation.id);
        return {
          [annotation.id]: true,
        };
      }
    });
  }, []);

  const handleHoverShape = useCallback(
    (annotation: PixiDetailAnnotation) => {
      if (Object.keys(annotationHoveredMap)[0] === annotation.id) return;
      setAnnotationHoveredMap(prevState => {
        if (prevState[annotation.id]) {
          setHoveredAnnotatinoId('');
          return {};
        } else {
          setHoveredAnnotatinoId(annotation.id);
          return {
            [annotation.id]: true,
          };
        }
      });
    },
    [annotationHoveredMap],
  );

  const handleLeaveShape = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotatinoId(null);
  }, []);

  const handleClickList = (id: string) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };
  const handleHoverList = (id: string) => {
    if (Object.keys(annotationHoveredMap)[0] === id) return;
    setAnnotationHoveredMap(prevState => {
      if (prevState[id]) {
        setHoveredAnnotatinoId('');
        return {};
      } else {
        setHoveredAnnotatinoId(id);
        return {
          [id]: true,
        };
      }
    });
  };

  const handleLeaveList = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotatinoId(null);
  }, []);

  const referenceAnnotations = () => {
    if (!referenceAnnotation) return [];
    const fileName = modelTraining.modelTrainingReferenceImages.images[referenceIndex].fileName;
    const categories = referenceAnnotation.categories;
    const imageId = referenceAnnotation.images.find(img => img.file_name === fileName).id;
    return referenceAnnotation.annotations
      .filter(anno => !filteredPredictionAnnotationsIds.includes(anno.id))
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, categories);
        return {
          type: annotationType,
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : (anno.segmentation as AnnotationTypeCoordinate<Polygon>['points']),
          id: anno.id,
          className: classNameResolver(anno, categories),
          color: annotationColorMap[classNameResolver(anno, categories)],
        };
      }) as PixiDetailAnnotation[];
  };
  const iterationAnnotations = (annotaions: SamplePredictionData, fileName: string) => {
    if (!annotaions) return [];
    const categories = annotaions.categories;
    const imageId = annotaions.images.find(img => img.file_name === fileName).id;

    return annotaions.annotations
      .filter(anno => !filteredPredictionAnnotationsIds.includes(anno.ref_ann_id))
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, categories);
        return {
          type: annotationType,
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : (anno.segmentation as AnnotationTypeCoordinate<Polygon>['points']),
          id: anno.ref_ann_id,
          className: classNameResolver(anno, categories),
          color: annotationColorMap[classNameResolver(anno, categories)],
        };
      }) as PixiDetailAnnotation[];
  };

  const formattedAnnotations = (fileName: string) => {
    return referenceAnnotation.annotations
      .filter(anno => anno.file_name === fileName)
      .reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
        const annotationType = annotationTypeResolver(annotation, referenceAnnotation.categories);
        const annotationClass = classNameResolver(annotation, referenceAnnotation.categories);
        const key = `${annotation.category_id}-${annotation.image_id}-${annotationType}`;
        return {
          ...acc,
          [key]: {
            type: annotationType,
            id: key,
            name: annotationClass ?? '',
            count: (acc[key]?.count || 0) + 1,
            annotations: [
              ...(acc[key]?.annotations.length > 0 ? acc[key]?.annotations : []),
              {
                id: annotation.id,
                annotation_class: annotationClass,
                annotation_type: annotationType,
              },
            ],
          },
        };
      }, {});
  };

  const handleClickCopy = (value: string, label: string) => {
    FileUtils.copyToClipboard({
      value,
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label }), {
      variant: 'success',
    });
  };

  if (isRefLoading) {
    return <LoadingIndicator />;
  }

  if (datasetId && !dataset) {
    return <LoadingIndicator />;
  }

  return (
    <Dialog state={state} style={{ width: 1016, padding: 0 }} hideOnClickOutside={false}>
      <Box height="100%" display="grid" style={{ gridTemplateRows: '54px 1fr' }}>
        <Row backgroundColor={'gray-400'} px={2} py={2.5} justifyContent="space-between">
          <Typography variant="h3" color={'white'}>
            {title ? title : t('model.generativeAi.modelSample')}
          </Typography>
          <Row>
            {header && (
              <>
                {header}
                <Box mx={1.5} backgroundColor={'gray-300'} style={{ width: 1, height: 8 }} />
              </>
            )}

            <IconButton icon={Clear} variant="text" color="white" onClick={() => state.hide()} />
          </Row>
        </Row>
        <Box height="100%" display="grid" style={{ gridTemplateColumns: '448px 1fr' }}>
          <Box borderRight="1px solid" height="100%" borderColor="gray-200" p={2.5}>
            <Row justifyContent="center" mb={1.5}>
              <Typography variant="l-strong">
                {dataset && dataset.key ? dataset.key : 'Input image'}
              </Typography>
              <Row ml="auto" style={{ width: 'max-content' }}>
                <IconButton
                  color="gray"
                  icon={ArrowLeft}
                  variant="text"
                  disabled={referenceIndex === 0}
                  onClick={() => {
                    setReferenceIndex(prev => prev - 1);
                  }}
                />
                <Typography variant="l-strong" color="gray-400">
                  {referenceIndex + 1} / {modelTraining.modelTrainingReferenceImages.images.length}
                </Typography>
                <IconButton
                  color="gray"
                  icon={ArrowRight}
                  variant="text"
                  disabled={
                    referenceIndex === modelTraining.modelTrainingReferenceImages.images.length - 1
                  }
                  onClick={() => {
                    setReferenceIndex(prev => prev + 1);
                  }}
                />
              </Row>
            </Row>
            <Box backgroundColor="gray-100" mb={1} style={{ height: 260 }}>
              {/* Pixi image */}
              {modelTraining.modelTrainingReferenceImages.images[referenceIndex] &&
                modelTraining.modelTrainingReferenceImages.images[referenceIndex].url && (
                  <PixiDetailImage
                    imgUrl={modelTraining.modelTrainingReferenceImages.images[referenceIndex].url}
                    samplePredictionAnnotations={referenceAnnotations()}
                    annotationSelectedMap={annotationSelectedMap}
                    annotationHoveredMap={annotationHoveredMap}
                    onSelectImage={handleSelectImage}
                    onSelectShape={handleSelectShape}
                    onHoverShape={handleHoverShape}
                    onLeaveShape={handleLeaveShape}
                  />
                )}
            </Box>
            <Tabs>
              <TabList>
                <Tab>{t('model.generativeAi.annotation')}</Tab>
                {sampleDetailType !== 'baseline-sample' && (
                  <Tab>{t('model.generativeAi.properties')}</Tab>
                )}
              </TabList>
              <TabPanel>
                <Box overflow="auto" style={{ height: 218 }}>
                  {!!formattedAnnotations &&
                    Object.values(
                      formattedAnnotations(
                        modelTraining.modelTrainingReferenceImages.images[referenceIndex].fileName,
                      ),
                    ).map(anno => {
                      return (
                        <AnnotationClassesCollapse
                          key={anno.id}
                          formattedAnnotation={anno}
                          filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
                          setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
                          handleClickList={handleClickList}
                          handleHoverList={handleHoverList}
                          handleLeaveList={handleLeaveList}
                          clickedAnnotationId={clickedAnnotationId}
                          hoveredAnnotatinoId={hoveredAnnotatinoId}
                          annotationColorMap={annotationColorMap}
                        />
                      );
                    })}
                </Box>
              </TabPanel>
              {sampleDetailType !== 'baseline-sample' && (
                <TabPanel>
                  <Box p={1} display="flex" flexDirection="column" gap={2}>
                    <Box style={{ ...textEllipsisStyle }}>
                      <Typography variant="m-medium">
                        {t('model.myModelDetail.imageKey')}
                      </Typography>
                      <br />
                      <Typography variant="m-regular">{dataset.key}</Typography>
                    </Box>
                    <Box style={{ ...textEllipsisStyle }}>
                      <Typography variant="m-medium">{t('model.myModelDetail.imageId')}</Typography>
                      <br />
                      <Typography variant="m-regular">{imageId}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="m-medium">
                        {t('model.myModelDetail.imageUrl')}
                      </Typography>
                      <br />
                      <Row>
                        <Box style={{ ...textEllipsisStyle }}>
                          <Typography variant="m-regular">{dataset.image_url}</Typography>
                        </Box>
                        <IconButton
                          icon={Copy}
                          onClick={e => {
                            e.stopPropagation();
                            handleClickCopy(
                              modelTraining.modelTrainingReferenceImages.images[referenceIndex].url,
                              'URL',
                            );
                          }}
                          variant="text"
                          size="s"
                        />
                      </Row>
                    </Box>
                  </Box>
                </TabPanel>
              )}
            </Tabs>
          </Box>
          {isIterLoading ? (
            <Row backgroundColor={'gray-100'} justifyContent="center">
              <LoadingIndicator />
            </Row>
          ) : (
            <Box backgroundColor={'gray-100'} p={2.5}>
              {sampleDetailType === 'performance' ? (
                <Performance
                  iterationAnnotation={iterationAnnotation}
                  comparisonAnnotation={comparisionIterationAnnotation}
                  modelTraining={modelTraining}
                  comparisonModelTraining={comparisonModelTraining}
                  referenceIndex={referenceIndex}
                  annotationSelectedMap={annotationSelectedMap}
                  annotationHoveredMap={annotationHoveredMap}
                  samplePredictionAnnotations={iterationAnnotations}
                  handleSelectImage={handleSelectImage}
                  handleSelectShape={handleSelectShape}
                  handleHoverShape={handleHoverShape}
                  handleLeaveShape={handleLeaveShape}
                  modelName={modelName}
                  comparisonModelName={comparisonModelName}
                  isCompareLoading={isCompareLoading}
                />
              ) : (
                <Progress
                  iterationAnnotation={iterationAnnotation}
                  modelTraining={modelTraining}
                  referenceIndex={referenceIndex}
                  iterationIndex={iterationIndex}
                  annotationSelectedMap={annotationSelectedMap}
                  annotationHoveredMap={annotationHoveredMap}
                  samplePredictionAnnotations={iterationAnnotations}
                  handleSelectImage={handleSelectImage}
                  handleSelectShape={handleSelectShape}
                  handleHoverShape={handleHoverShape}
                  handleLeaveShape={handleLeaveShape}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export const AnnotationClassesCollapse = ({
  formattedAnnotation,
  filteredPredictionAnnotationsIds,
  setFilteredPredictionAnnotationsIds,
  handleClickList,
  handleHoverList,
  handleLeaveList,
  clickedAnnotationId,
  hoveredAnnotatinoId,
  annotationColorMap,
}: {
  formattedAnnotation: FormattedAnnotation;
  filteredPredictionAnnotationsIds: string[];
  setFilteredPredictionAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  handleClickList: (id: string) => void;
  handleHoverList: (id: string) => void;
  handleLeaveList: () => void;
  clickedAnnotationId: string;
  hoveredAnnotatinoId: string;
  annotationColorMap: Record<string, string>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const allHide = formattedAnnotation.annotations.every(anno =>
    filteredPredictionAnnotationsIds.includes(anno.id),
  );

  return (
    <Box key={formattedAnnotation.id}>
      <Row
        justifyContent="space-between"
        width="100%"
        cursor="pointer"
        onClick={() => setIsOpen(prev => !prev)}
        backgroundColor={{
          hover: 'gray-100',
          default: formattedAnnotation.annotations.find(anno => anno.id === hoveredAnnotatinoId)
            ? 'gray-100'
            : formattedAnnotation.annotations.find(anno => anno.id === clickedAnnotationId)
            ? 'primary-100'
            : undefined,
        }}
        pl={1.5}
        pr={0.5}
      >
        <Row gap={0.5}>
          <Icon
            icon={getAnnotationIcon(formattedAnnotation.type)}
            color={
              colorPalette.find(color => color.hex === annotationColorMap[formattedAnnotation.name])
                ?.token
            }
          />
          <Typography variant="m-medium" color={allHide ? 'cloud' : undefined}>
            {formattedAnnotation.name} ({formattedAnnotation.annotations.length})
          </Typography>
        </Row>
        <IconButton
          icon={allHide ? Hide : Visible}
          variant="text"
          color={allHide ? 'cloud' : undefined}
          style={{ marginLeft: 'auto' }}
          onClick={e => {
            e.stopPropagation();
            if (allHide) {
              setFilteredPredictionAnnotationsIds(prev =>
                prev.filter(
                  id => !formattedAnnotation.annotations.map(anno => anno.id).includes(id),
                ),
              );
            } else {
              setFilteredPredictionAnnotationsIds(prev => [
                ...prev,
                ...formattedAnnotation.annotations.map(anno => anno.id),
              ]);
            }
          }}
        />
        <Box
          style={{
            transition: 'all 0.1s ease-in-out',
            ...(isOpen ? {} : { transform: 'rotate(-90deg)' }),
          }}
        >
          <IconButton
            icon={ChevronDown}
            onClick={e => {
              e.stopPropagation();
              setIsOpen(prev => !prev);
            }}
            variant="text"
          />
        </Box>
      </Row>
      {isOpen && (
        <Box>
          {formattedAnnotation.annotations.map(anno => {
            const isExistFilterdId = filteredPredictionAnnotationsIds.find(id => id === anno.id);
            return (
              <Row
                key={anno.id}
                justifyContent="space-between"
                cursor="pointer"
                onClick={() => {
                  if (isExistFilterdId) return;
                  handleClickList(anno.id);
                }}
                onMouseEnter={() => {
                  handleHoverList(anno.id);
                }}
                onMouseLeave={handleLeaveList}
                style={{ paddingLeft: 20, paddingRight: 36 }}
                backgroundColor={{
                  hover: 'gray-100',
                  default:
                    hoveredAnnotatinoId === anno.id
                      ? 'gray-100'
                      : clickedAnnotationId === anno.id
                      ? 'primary-100'
                      : undefined,
                }}
              >
                <Row gap={0.5}>
                  <Icon
                    icon={getAnnotationIcon(formattedAnnotation.type)}
                    color={
                      colorPalette.find(
                        color => color.hex === annotationColorMap[formattedAnnotation.name],
                      ).token
                    }
                  />
                  <Typography variant="m-medium" color={isExistFilterdId ? 'cloud' : undefined}>
                    {anno.annotation_class}
                  </Typography>
                </Row>
                <IconButton
                  variant="text"
                  icon={isExistFilterdId ? Hide : Visible}
                  color={isExistFilterdId ? 'cloud' : undefined}
                  onClick={e => {
                    e.stopPropagation();
                    if (isExistFilterdId) {
                      setFilteredPredictionAnnotationsIds(prev => {
                        return prev.filter(id => id !== anno.id);
                      });
                    } else {
                      setFilteredPredictionAnnotationsIds(prev => [...prev, anno.id]);
                    }
                  }}
                />
              </Row>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const Progress = ({
  iterationAnnotation,
  modelTraining,
  referenceIndex,
  iterationIndex,
  annotationSelectedMap,
  annotationHoveredMap,
  samplePredictionAnnotations,
  handleSelectImage,
  handleSelectShape,
  handleHoverShape,
  handleLeaveShape,
}: {
  iterationAnnotation: SamplePredictionData;
  modelTraining: GenerationAIModelDetailTraining;
  referenceIndex: number;
  iterationIndex: number;
  annotationSelectedMap: Record<string, boolean>;
  annotationHoveredMap: Record<string, boolean>;
  samplePredictionAnnotations: (
    annotaions: SamplePredictionData,
    fileName: string,
  ) => PixiDetailAnnotation[];
  handleSelectImage: () => void;
  handleSelectShape: (annotation: PixiDetailAnnotation) => void;
  handleHoverShape: (annotation: PixiDetailAnnotation) => void;
  handleLeaveShape: (annotation: PixiDetailAnnotation) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row style={{ height: 32 }}>
        <Typography variant="l-strong">{t('model.generativeAi.output')}</Typography>
      </Row>
      <Box
        display="grid"
        mt={1.5}
        style={{
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '260px 260px',
          columnGap: 8,
          rowGap: 8,
        }}
      >
        {Array(ITERATION_LENGTH)
          .fill(null)
          .map((_, index) => (
            <Box
              backgroundColor={'gray-200'}
              key={
                modelTraining.modelTrainingIterations[iterationIndex].images[
                  referenceIndex * ITERATION_LENGTH + index
                ].url
              }
            >
              <PixiDetailImage
                imgUrl={
                  modelTraining.modelTrainingIterations[iterationIndex].images[
                    referenceIndex * ITERATION_LENGTH + index
                  ].url
                }
                samplePredictionAnnotations={samplePredictionAnnotations(
                  iterationAnnotation,
                  modelTraining.modelTrainingIterations[iterationIndex].images[
                    referenceIndex * ITERATION_LENGTH + index
                  ].fileName,
                )}
                annotationSelectedMap={annotationSelectedMap}
                annotationHoveredMap={annotationHoveredMap}
                onSelectImage={handleSelectImage}
                onSelectShape={handleSelectShape}
                onHoverShape={handleHoverShape}
                onLeaveShape={handleLeaveShape}
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

const Performance = ({
  iterationAnnotation,
  comparisonAnnotation,
  modelTraining,
  comparisonModelTraining,
  referenceIndex,
  annotationSelectedMap,
  annotationHoveredMap,
  samplePredictionAnnotations,
  handleSelectImage,
  handleSelectShape,
  handleHoverShape,
  handleLeaveShape,
  modelName,
  comparisonModelName,
  isCompareLoading,
}: {
  iterationAnnotation: SamplePredictionData;
  comparisonAnnotation: SamplePredictionData;
  modelTraining: GenerationAIModelDetailTraining;
  comparisonModelTraining: GenerationAIModelDetailTraining;
  referenceIndex: number;
  annotationSelectedMap: Record<string, boolean>;
  annotationHoveredMap: Record<string, boolean>;
  samplePredictionAnnotations: (
    annotaions: SamplePredictionData,
    fileName: string,
  ) => PixiDetailAnnotation[];
  handleSelectImage: () => void;
  handleSelectShape: (annotation: PixiDetailAnnotation) => void;
  handleHoverShape: (annotation: PixiDetailAnnotation) => void;
  handleLeaveShape: (annotation: PixiDetailAnnotation) => void;
  modelName: string;
  comparisonModelName: string;
  isCompareLoading: boolean;
}) => {
  const boxSize = 206;
  const { t } = useTranslation();
  if (typeof isCompareLoading === 'boolean' && isCompareLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Box display="grid" style={{ gridTemplateRows: '275px 275px', rowGap: 12 }}>
      <Box
        backgroundColor={'white'}
        border="1px solid"
        borderRadius="2px"
        borderColor="gray-200"
        p={2.5}
        overflow="auto"
        style={{ width: 528 }}
      >
        <Row mb={1.5}>
          <Typography variant="s-strong">{t('model.generativeAi.currentModel')}</Typography>
          <Box backgroundColor={'gray-300'} mx={0.5} style={{ width: 1, height: 4 }} />
          <Typography variant="s-regular">{modelName}</Typography>
        </Row>
        <Row gap={1} overflow="auto" style={{ height: boxSize }}>
          {Array(ITERATION_LENGTH)
            .fill(null)
            .map((_, index) => (
              <Box
                style={{ minWidth: boxSize, height: boxSize }}
                key={
                  modelTraining.modelTrainingIterations[
                    modelTraining.modelTrainingIterations.length - 1
                  ].images[referenceIndex * ITERATION_LENGTH + index].url
                }
              >
                <PixiDetailImage
                  imgUrl={
                    modelTraining.modelTrainingIterations[
                      modelTraining.modelTrainingIterations.length - 1
                    ].images[referenceIndex * ITERATION_LENGTH + index].url
                  }
                  samplePredictionAnnotations={samplePredictionAnnotations(
                    iterationAnnotation,
                    modelTraining.modelTrainingIterations[
                      modelTraining.modelTrainingIterations.length - 1
                    ].images[referenceIndex * ITERATION_LENGTH + index].fileName,
                  )}
                  annotationSelectedMap={annotationSelectedMap}
                  annotationHoveredMap={annotationHoveredMap}
                  onSelectImage={handleSelectImage}
                  onSelectShape={handleSelectShape}
                  onHoverShape={handleHoverShape}
                  onLeaveShape={handleLeaveShape}
                />
              </Box>
            ))}
        </Row>
      </Box>
      <Box
        backgroundColor={'white'}
        border="1px solid"
        borderRadius="2px"
        borderColor="gray-200"
        p={2.5}
        overflow="auto"
        style={{ width: 528 }}
      >
        <Row mb={1.5}>
          <Typography variant="s-strong">{t('model.generativeAi.comparisonModel')}</Typography>
          <Box backgroundColor={'gray-300'} mx={0.5} style={{ width: 1, height: 4 }} />
          <Typography variant="s-regular">{comparisonModelName}</Typography>
        </Row>
        <Row gap={1} overflow="auto" style={{ height: boxSize }}>
          {Array(ITERATION_LENGTH)
            .fill(null)
            .map((_, index) => (
              <Box
                key={
                  comparisonModelTraining.modelTrainingIterations[
                    comparisonModelTraining.modelTrainingIterations.length - 1
                  ].images[referenceIndex * ITERATION_LENGTH + index].url
                }
                style={{ minWidth: boxSize, height: boxSize }}
              >
                <PixiDetailImage
                  imgUrl={
                    comparisonModelTraining.modelTrainingIterations[
                      comparisonModelTraining.modelTrainingIterations.length - 1
                    ].images[referenceIndex * ITERATION_LENGTH + index].url
                  }
                  samplePredictionAnnotations={samplePredictionAnnotations(
                    comparisonAnnotation,
                    comparisonModelTraining.modelTrainingIterations[
                      comparisonModelTraining.modelTrainingIterations.length - 1
                    ].images[referenceIndex * ITERATION_LENGTH + index].fileName,
                  )}
                  annotationSelectedMap={annotationSelectedMap}
                  annotationHoveredMap={annotationHoveredMap}
                  onSelectImage={handleSelectImage}
                  onSelectShape={handleSelectShape}
                  onHoverShape={handleHoverShape}
                  onLeaveShape={handleLeaveShape}
                />
              </Box>
            ))}
        </Row>
      </Box>
    </Box>
  );
};
