import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@superb-ai/ui';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { getNameError, getPasswordError } from '../helper';
import LabeledInput from '../LabeledInput';
import { useChangeContent } from '../Layout';
import ValidationInput, { useValidationInputRefs } from '../ValidationInput';

interface Props {
  handleContinue: () => void;
  info: Record<'givenName' | 'familyName' | 'password', string>;
  changeInfo: (key: 'givenName' | 'familyName' | 'password', value: string) => void;
}

export default function UserInfo({ handleContinue, info, changeInfo }: Props) {
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { email } = routeInfo.params;
  const { givenName, familyName, password } = info;
  const refs = useValidationInputRefs(['givenName', 'familyName', 'password'] as const);

  useChangeContent('testimonialIntutivo');

  const getIsValid = (): boolean => {
    if (getNameError(t)(givenName)) {
      refs.current.givenName?.focusWithError();
      return false;
    } else if (getNameError(t)(familyName)) {
      refs.current.familyName?.focusWithError();
      return false;
    } else if (getPasswordError(t)(password)) {
      refs.current.password?.focusWithError();
      return false;
    }

    return true;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (getIsValid()) {
      handleContinue();
    }
  };

  useEffect(() => {
    refs.current.givenName?.origin?.focus();
  }, []);

  return (
    <Box width="100%" as="form" onSubmit={onSubmit} display="flex" flexDirection="column" gap={2}>
      <LabeledInput label={t('settings.profile.workEmail')} value={atob(email)} disabled />
      <Box width="100%" display="flex" gap={1}>
        <ValidationInput
          refs={refs}
          refKey="givenName"
          autoComplete="given-name"
          validation={getNameError(t)}
          onChange={e => changeInfo('givenName', e.target.value)}
          value={givenName}
          label={t('settings.profile.firstName')}
          placeholder="Johnny"
        />
        <ValidationInput
          refs={refs}
          refKey="familyName"
          autoComplete="family-name"
          validation={getNameError(t)}
          onChange={e => changeInfo('familyName', e.target.value)}
          value={familyName}
          label={t('settings.profile.lastName')}
          placeholder="Appleseed"
        />
      </Box>
      <ValidationInput
        type="password"
        refs={refs}
        refKey="password"
        autoComplete="new-password"
        validation={getPasswordError(t)}
        onChange={e => changeInfo('password', e.target.value)}
        value={password}
        label={t('settings.profile.password')}
        placeholder="Shhh..."
        info={t('settings.profile.passwordRestriction')}
      />
      <Button
        type="submit"
        variant="strong-fill"
        color="primary"
        size="l"
        style={{ width: '100%' }}
      >
        {t('button.continue')}
      </Button>
    </Box>
  );
}
