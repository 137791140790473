import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { CheckCircle, FileOutline } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import AnalyticsTracker from '../../../../../../analyticsTracker';
import {
  CURATION_TYPE_CONFIG,
  CurationType,
  DELETED_SLICE,
  SPLIT_TRAIN_VAL,
} from '../../../../../../consts/AutoCurateConst';
import { IHistory } from '../../../../../../types/autoCurateTypes';
import { formatDateTime } from '../../../../../../utils/date';
import { formatCount } from '../../../../../../utils/numberFormat';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useAutoCurateService } from '../../../../services/AutoCurateService';
import { DatasetResult, useCurateDatasetService } from '../../../../services/DatasetService';
import { DatasetPool } from '../../../../types/routeTypes';
import AppliedQueryTabs from './AppliedQueryTabs';

type DeletedSliceType = {
  name: string;
  id: string;
  imageCount: number;
};

export default function CurationHistoryItem({ list }: { list: IHistory }) {
  const { accountName, datasetId, datasetPool } = useParams<{
    accountName: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { curationReportDownload } = useAutoCurateService();
  const { datasetInfo } = useDatasetContext();
  const { getSliceList, getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const datasetName = datasetInfo?.name || '';

  const [originSlice, setOriginSlice] = useState<string>('');
  const [resultSlice, setResultSlice] = useState<DatasetResult | DeletedSliceType>();
  const [trainSlice, setTrainSlice] = useState<DatasetResult | DeletedSliceType>();
  const [mislabelSlice, setMislabelSlice] = useState<DatasetResult | DeletedSliceType | string>();
  const downloadReport = useCallback(
    (jobId: string, resultType: string) => {
      (async () => {
        const res = await curationReportDownload({ jobId, resultType });
        window.open(res.downloadUrl);
      })();
    },
    [curationReportDownload],
  );

  const { images: curationInfo, curationType: sliceCurationType } = list.job.param;
  const curationType = sliceCurationType as CurationType;

  const moveToSlice = useCallback(
    id => history.push(`/${accountName}/curate/${datasetPool}/${datasetId}/slice/${id}`),
    [accountName, datasetId, history],
  );

  useEffect(() => {
    (async () => {
      if (curationInfo?.slice) {
        const res = await getSliceList({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
          name: curationInfo?.slice,
        });
        if (res?.results && res.results.length > 0) {
          setOriginSlice(res.results[0].id);
        }
      }
    })();
  }, [curationInfo?.slice]);

  useEffect(() => {
    (async () => {
      const {
        curationType,
        config: { valSliceId, resultSliceId, trainSliceId, mislabelSliceId },
      } = list.job.param;

      let result;
      if (curationType === SPLIT_TRAIN_VAL) {
        try {
          result = await getSlice({
            datasetId,
            fromPublicDatasets: showPublicDatasets,
            sliceId: valSliceId as string,
            expand: ['image_count'],
          });
        } catch (e) {
          result = { name: DELETED_SLICE, id: valSliceId ?? '', imageCount: 0 };
        }
      } else {
        try {
          result = await getSlice({
            datasetId,
            fromPublicDatasets: showPublicDatasets,
            sliceId: resultSliceId as string,
            expand: ['image_count'],
          });
        } catch (e) {
          result = { name: DELETED_SLICE, id: resultSliceId ?? '', imageCount: 0 };
        }
      }

      let train;
      if (curationType === SPLIT_TRAIN_VAL && trainSliceId) {
        try {
          train = await getSlice({
            datasetId,
            fromPublicDatasets: showPublicDatasets,
            sliceId: trainSliceId as string,
            expand: ['image_count'],
          });
        } catch (e) {
          train = { name: DELETED_SLICE, id: trainSliceId ?? '', imageCount: 0 };
        }
      } else train = { name: '', id: '', imageCount: 0 };

      let mislabel;
      if (curationType === SPLIT_TRAIN_VAL && mislabelSliceId) {
        try {
          mislabel = await getSlice({
            datasetId,
            fromPublicDatasets: showPublicDatasets,
            sliceId: mislabelSliceId as string,
            expand: ['image_count'],
          });
        } catch (e) {
          mislabel = { name: DELETED_SLICE, id: mislabelSliceId ?? '', imageCount: 0 };
        }
      } else mislabel = '';
      setResultSlice(result);
      setTrainSlice(train);
      setMislabelSlice(mislabel);
    })();
  }, [list]);

  return (
    <Box style={{ minHeight: '138px' }}>
      <Box
        display="flex"
        flexDirection="column"
        mb={3}
        style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 2px 4px 10px' }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
          style={{ borderBottom: '1px solid rgb(229, 229, 229)' }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Icon icon={CheckCircle} size={18} color="green-400" />
              <Box ml={1}>
                <Typography variant="l-strong">
                  {`${sliceCurationType ? CURATION_TYPE_CONFIG(t)[curationType].text : ''} `}
                </Typography>
              </Box>
            </Box>
            <Box ml={3} mt={0.5} display="flex" px={0.5}>
              <Typography variant="m-regular">Curated on : </Typography>
              <Typography
                variant="m-regular"
                style={{
                  fontWeight: '500',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                  margin: '0 3px',
                }}
                onClick={() => {
                  if (curationInfo?.slice && originSlice) moveToSlice(originSlice);
                  else history.push(`/${accountName}/curate/${datasetPool}/${datasetId}/explore`);
                }}
              >
                {` ${curationInfo?.slice ? curationInfo?.slice : datasetName} `}
              </Typography>
              <Typography variant="m-regular">
                {curationInfo?.slice ? ' Slice' : ' Dataset'}
              </Typography>
            </Box>
          </Box>
          {!showPublicDatasets && (
            <Box display="flex">
              <Typography variant="m-regular" color="gray-300">
                {list.job?.createdBy} ∙ {formatDateTime(list.job?.createdAt)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" px={3} py={2} justifyContent="space-between" alignItems="center">
            <Box>
              {sliceCurationType === SPLIT_TRAIN_VAL ? (
                <Box display="flex">
                  <Box display="flex" alignItems="center" mb={0.5}>
                    <Box mr={1}>
                      <Typography variant="m-regular">
                        Train{' '}
                        {` (${
                          trainSlice && trainSlice.imageCount
                            ? trainSlice?.imageCount > 1
                              ? `${formatCount(trainSlice?.imageCount)} images`
                              : '1 image'
                            : 0
                        })`}
                      </Typography>
                    </Box>
                    {trainSlice && (
                      <Typography
                        variant={
                          trainSlice.name !== DELETED_SLICE && trainSlice?.imageCount
                            ? 'm-medium'
                            : 'm-regular'
                        }
                        style={{
                          textDecorationLine:
                            trainSlice?.name !== DELETED_SLICE && trainSlice?.imageCount
                              ? 'underline'
                              : '',
                          cursor: trainSlice?.name !== DELETED_SLICE ? 'pointer' : '',
                        }}
                        onClick={() => {
                          if (trainSlice?.name !== DELETED_SLICE) moveToSlice(trainSlice.id);
                        }}
                      >
                        {trainSlice.imageCount ? (
                          trainSlice?.name ?? ''
                        ) : (
                          <>
                            {trainSlice?.name === DELETED_SLICE ? (
                              <Typography variant="m-medium" color="gray-300">
                                {trainSlice?.name}
                              </Typography>
                            ) : (
                              <Trans t={t} i18nKey={'curate.autoCurate.resultSliceText'}>
                                <>There are no </>
                                <Typography>{{ case: 'trained' }}</Typography>
                              </Trans>
                            )}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>
                  {trainSlice && typeof trainSlice !== 'string' && (
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <Box
                        borderRight="1px solid"
                        borderColor="gray-200"
                        style={{ height: '12px' }}
                        mx={2}
                      />
                      <Box mr={1}>
                        <Typography variant="m-regular">
                          Validation{' '}
                          {` (${
                            resultSlice?.imageCount
                              ? resultSlice?.imageCount > 1
                                ? `${formatCount(resultSlice?.imageCount)} images`
                                : '1 image'
                              : 0
                          })`}
                        </Typography>
                      </Box>
                      {resultSlice && (
                        <Typography
                          variant={resultSlice?.imageCount ? 'm-medium' : 'm-regular'}
                          style={{
                            textDecorationLine:
                              resultSlice.name !== DELETED_SLICE && resultSlice?.imageCount
                                ? 'underline'
                                : '',
                            cursor: resultSlice?.name !== DELETED_SLICE ? 'pointer' : '',
                          }}
                          onClick={() => {
                            if (resultSlice?.name !== DELETED_SLICE) moveToSlice(resultSlice.id);
                          }}
                        >
                          {resultSlice?.imageCount ? (
                            resultSlice?.name ?? ''
                          ) : (
                            <>
                              {resultSlice.name === DELETED_SLICE ? (
                                <Typography variant="m-medium" color="gray-300">
                                  {resultSlice.name}
                                </Typography>
                              ) : (
                                <Trans t={t} i18nKey={'curate.autoCurate.resultSliceText'}>
                                  <>There are no </>
                                  <Typography>{{ case: 'trained' }}</Typography>
                                </Trans>
                              )}
                            </>
                          )}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {mislabelSlice && typeof mislabelSlice !== 'string' && (
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <Box
                        borderRight="1px solid"
                        borderColor="gray-200"
                        style={{ height: '12px' }}
                        mx={2}
                      />
                      <Box mr={1}>
                        <Typography variant="m-regular">
                          Mislabel
                          {` (${
                            mislabelSlice.imageCount
                              ? mislabelSlice?.imageCount > 1
                                ? `${formatCount(mislabelSlice?.imageCount)} images`
                                : '1 image'
                              : 0
                          })`}
                        </Typography>
                      </Box>
                      <Typography
                        variant={resultSlice?.imageCount ? 'm-medium' : 'm-regular'}
                        style={{
                          textDecorationLine:
                            mislabelSlice?.name !== DELETED_SLICE && resultSlice?.imageCount
                              ? 'underline'
                              : '',
                          cursor: mislabelSlice?.name !== DELETED_SLICE ? 'pointer' : '',
                        }}
                        onClick={() => {
                          if (mislabelSlice?.name !== DELETED_SLICE) moveToSlice(mislabelSlice.id);
                        }}
                      >
                        {mislabelSlice.imageCount ? (
                          mislabelSlice?.name ?? ''
                        ) : (
                          <>
                            {mislabelSlice?.name === DELETED_SLICE ? (
                              <Typography variant="m-medium" color="gray-300">
                                {mislabelSlice?.name}
                              </Typography>
                            ) : (
                              <Trans t={t} i18nKey={'curate.autoCurate.resultSliceText'}>
                                <>There are no </>
                                <Typography>{{ case: 'mislabeled' }}</Typography>
                              </Trans>
                            )}
                          </>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box display="flex">
                  <Box mr={1}>
                    <Typography variant="m-regular">
                      {'Result '}
                      {`(${
                        resultSlice?.imageCount
                          ? resultSlice?.imageCount > 1
                            ? `${formatCount(resultSlice?.imageCount)} images`
                            : '1 image'
                          : 0
                      })`}
                    </Typography>
                  </Box>
                  {resultSlice && (
                    <Typography
                      variant={resultSlice?.imageCount ? 'm-medium' : 'm-regular'}
                      style={{
                        textDecorationLine:
                          resultSlice?.name !== DELETED_SLICE && resultSlice?.imageCount
                            ? 'underline'
                            : '',
                        cursor: resultSlice?.name !== DELETED_SLICE ? 'pointer' : '',
                      }}
                      onClick={() => {
                        if (resultSlice?.name !== DELETED_SLICE) moveToSlice(resultSlice.id);
                      }}
                    >
                      {resultSlice?.imageCount ? (
                        resultSlice?.name ?? ''
                      ) : (
                        <>
                          {resultSlice?.name === DELETED_SLICE ? (
                            <Typography variant="m-medium" color="gray-300">
                              {resultSlice?.name}
                            </Typography>
                          ) : (
                            <>
                              <Trans t={t} i18nKey={'curate.autoCurate.resultSliceText'}>
                                <>There are no </>
                                {sliceCurationType === 'FIND_EDGE_CASES' ? (
                                  <Typography>{{ case: 'edge case' }}</Typography>
                                ) : sliceCurationType === 'FIND_MISLABELS' ? (
                                  <Typography>{{ case: 'mislabel case' }}</Typography>
                                ) : (
                                  <Typography>{{ case: '' }}</Typography>
                                )}
                              </Trans>
                            </>
                          )}
                        </>
                      )}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            <Box>
              <Button
                variant="stroke"
                size="m"
                color="primary"
                onClick={() => {
                  downloadReport(list.jobId, 'AUTO_CURATE_REPORT');
                  AnalyticsTracker.autoCurateReportDownloaded({
                    accountId: accountName,
                    datasetId,
                  });
                }}
                disabled={list.job.status !== 'COMPLETE'}
              >
                <Icon icon={FileOutline} />
                <Typography>{t('curate.button.report')}</Typography>
              </Button>
            </Box>
          </Box>
          {curationInfo?.query ? <AppliedQueryTabs query={curationInfo?.query} /> : <></>}
        </Box>
      </Box>
    </Box>
  );
}
