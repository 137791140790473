import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, StatusLight, Typography } from '@superb-ai/norwegian-forest';

import { LabelStatus } from '../../types/labelTypes';
import LabelUtils from '../../utils/LabelUtils';

interface Props {
  status: LabelStatus;
}

const LabelBadgeStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const statusColor = LabelUtils.getStatusColorName(status);
  const statusText =
    t(`labels.status.${statusColor}`) ||
    (statusColor ? statusColor.replace('_', ' ').toUpperCase() : '');

  return (
    <Box display="flex" alignItems="center">
      <StatusLight color={statusColor} />
      <Box ml={1}>
        <Typography variant="label">{statusText}</Typography>
      </Box>
    </Box>
  );
};

export default LabelBadgeStatus;
