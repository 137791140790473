import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import {
  Box as BoxIcon,
  CheckFilled,
  ClearFilled,
  InfoCircle,
  LinkExternal,
  PinFilled,
  Polygon,
  Search,
} from '@superb-ai/icons';
import { Box, Chip, Icon, IconButton, Input, Select, Typography } from '@superb-ai/ui';
import { TFunction, Trans } from 'next-i18next';

import { Step } from '../../../../../../../../components/composition/VerticalStepper';
import { useDebounce } from '../../../../../../../../hooks/DebounceHook';
import { getUrl } from '../../../../../../../../routes/util';
import { formatDateTime } from '../../../../../../../../utils/date';
import { GenerativeAIMenuItem } from '../../../../../../../Model/gen-ai/MenuItem';
import { useModelListQuery } from '../../../../../../../Model/queries/modelQueries';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetDataCountQuery } from '../../../../../../queries/dataQueries';
import { useDatasetAnnotationClassesQuery } from '../../../../../../queries/datasetObjectQueries';
import {
  convertSelectedClassesToQueryString,
  convertSelectedImageIdsToQueryString,
} from '../../../../../../utils/filterUtils';

const SelectGenerativeAIModel = (
  t: TFunction,
  isDialogOpen: boolean,
  {
    generativeModel,
    setGenerativeModel,
    setApplicableImagesCount,
  }: {
    generativeModel?: { name: string; id: string };
    setGenerativeModel: Dispatch<SetStateAction<{ name: string; id: string } | undefined>>;
    setApplicableImagesCount: Dispatch<SetStateAction<number>>;
  },
): Step => {
  const { datasetId, accountName } = useParams<{
    datasetId: string;
    accountName: string;
  }>();
  const history = useHistory();

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const { selectedData } = useActionContext();
  const { totalCount: totalImageCount } = useImageScopeContext();

  const { data: annotationClassesData } = useDatasetAnnotationClassesQuery({
    datasetId,
    disabled: !isDialogOpen || !selectedData,
    sliceName: sliceInfo?.name,
    ...((selectedData || []).length > 0
      ? {
          queryString: convertSelectedImageIdsToQueryString(
            (selectedData || []).map(data => data.id),
          ),
        }
      : {
          queryString: queryStringWithHiddenFilterAndDeselection,
          imageFilters:
            clusterLevel && appliedFilters
              ? {
                  cluster_id_in: appliedFilters?.cluster_id_in || [],
                  cluster_level: clusterLevel,
                }
              : undefined,
        }),
  });

  const debouncedModelNameFilter = useDebounce(searchModelNameFilter, 300);
  const {
    data: modelListData,
    isLoading: isLoadingModelList,
    fetchNextPage: fetchNextModelListPage,
    hasNextPage: hasNextModelListPage,
  } = useModelListQuery({
    params: {
      name: debouncedModelNameFilter,
      status: ['trained'],
      referenceId: [datasetId],
      modelPurpose: 'generation',
    },
    enabled: isDialogOpen,
  });
  const modelList = useMemo(
    () => modelListData?.pages.flatMap(p => p.modelList),
    [modelListData?.pages],
  );
  const modelListOptions = useMemo(
    () =>
      modelList?.map(model => ({
        label: (
          <Box display="flex" alignItems="center" gap={1}>
            {model.pinnedAt && <Icon icon={PinFilled} />}
            <Typography
              style={{ maxWidth: 220 }}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {model.modelSetting.name}
            </Typography>
            <IconButton
              role="button"
              size="xs"
              variant="text"
              color="black"
              icon={LinkExternal}
              onClick={() =>
                history.push(getUrl([accountName, 'model', GenerativeAIMenuItem.path, model.id]))
              }
            />
            <Typography color="gray-300" style={{ marginLeft: 'auto' }}>
              {t('text.createdAtTime', { time: formatDateTime(new Date(model.createdAt)) })}
            </Typography>
          </Box>
        ),
        value: model.id,
      })),
    [modelList],
  );

  const selectedModel = modelList?.find(data => data.id === generativeModel?.id);
  const modelAnnotationClassList = selectedModel?.modelSetting.annotationClassList || [];
  const selectedImagesAnnotationClasses = annotationClassesData?.annotationClasses || [];

  const [includedAnnotationClasses, notIncludedAnnotationClasses] =
    selectedImagesAnnotationClasses.reduce<
      [typeof selectedImagesAnnotationClasses, typeof selectedImagesAnnotationClasses]
    >(
      (result, annotationClass) => {
        const isIncluded = modelAnnotationClassList.some(
          modelAnnotationClass =>
            modelAnnotationClass.name === annotationClass.name &&
            modelAnnotationClass.type === annotationClass.annotationType,
        );

        if (isIncluded) {
          result[0].push(annotationClass);
        } else {
          result[1].push(annotationClass);
        }

        return result;
      },
      [[], []],
    );

  function filteredSelectedQueryString() {
    if ((selectedData || []).length > 0)
      return (
        '(' + convertSelectedImageIdsToQueryString(selectedData.map(data => data.id)) + ') AND '
      );
    if (queryStringWithHiddenFilterAndDeselection)
      return '(' + queryStringWithHiddenFilterAndDeselection + ') AND ';
    return '';
  }

  const { data: filteredImagesCount } = useDatasetDataCountQuery({
    datasetId,
    queryString: `${filteredSelectedQueryString()}(${convertSelectedClassesToQueryString(
      includedAnnotationClasses.map(annotationClass => ({
        name: annotationClass.name,
        type: annotationClass.annotationType,
      })),
    )})`,
    sliceName: sliceInfo?.name || '',
    appliedFilters,
    disabled: includedAnnotationClasses.length <= 0,
  });

  const applicableImagesCount = filteredImagesCount?.count || 0;

  useEffect(() => {
    setApplicableImagesCount(applicableImagesCount);
  }, [applicableImagesCount]);

  return {
    title: t('curate.datasets.generateSyntheticImages.steps.selectModel.title'),
    isButtonEnabled: generativeModel !== undefined && applicableImagesCount > 0,
    summary: generativeModel?.name || '',
    content: (
      <Box>
        <Box display="flex">
          <Select
            data={modelListOptions}
            multiple={false}
            hasNextPage={hasNextModelListPage}
            isLoading={isLoadingModelList}
            fetchNextPage={fetchNextModelListPage}
            value={generativeModel?.id || null}
            onChangeValue={value => {
              const modelItem = (modelList || []).find(data => data.id === value);
              setGenerativeModel({ name: modelItem?.modelSetting.name || '', id: value });
            }}
            placeholder={t(
              'curate.datasets.generateSyntheticImages.steps.selectModel.selectModelPlaceholder',
            )}
            prefix={
              <Box p={0.5} borderBottom="1px solid" borderColor="gray-100" display="flex">
                <Input
                  type="search"
                  variant="text"
                  prefix={<Icon icon={Search} />}
                  placeholder={t(
                    'curate.datasets.generateSyntheticImages.steps.selectModel.searchModelPlaceholder',
                  )}
                  onChange={e => setSearchModelNameFilter(e.target.value)}
                  style={{ flex: 1 }}
                />
              </Box>
            }
          />
        </Box>
        {generativeModel && (
          <>
            <Box display="flex" mt={1}>
              <Box style={{ width: '4px' }} backgroundColor="gray-200" borderRadius="4px" />
              <Box display="flex" flexDirection="column" gap={1} ml={1} width="100%">
                {includedAnnotationClasses.length > 0 && (
                  <Box borderRadius="2px" width="100%" border="1px solid" borderColor="gray-200">
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      backgroundColor="gray-100"
                      p={1}
                    >
                      <Icon icon={CheckFilled} color="green" />
                      <Typography variant="m-medium">
                        <Trans
                          t={t}
                          i18nKey="curate.datasets.generateSyntheticImages.steps.selectModel.availableClasses"
                          values={{
                            count: includedAnnotationClasses.length,
                          }}
                          components={{
                            additionalText1: <Typography variant="m-medium" color="gray-300" />,
                          }}
                        />
                      </Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={0.5} p={1}>
                      {includedAnnotationClasses.map(annotationClass => (
                        <Chip key={annotationClass.name} color="secondary">
                          <Icon
                            icon={annotationClass.annotationType === 'box' ? BoxIcon : Polygon}
                          />{' '}
                          {annotationClass.name}
                        </Chip>
                      ))}
                    </Box>
                  </Box>
                )}
                {notIncludedAnnotationClasses.length > 0 && (
                  <Box borderRadius="2px" width="100%" border="1px solid" borderColor="gray-200">
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      backgroundColor="gray-100"
                      p={1}
                    >
                      <Icon icon={ClearFilled} color="cloud" />
                      <Typography variant="m-medium">
                        <Trans
                          t={t}
                          i18nKey="curate.datasets.generateSyntheticImages.steps.selectModel.unavailableClasses"
                          values={{
                            count: notIncludedAnnotationClasses.length,
                          }}
                          components={{
                            additionalText1: <Typography variant="m-medium" color="gray-300" />,
                          }}
                        />
                      </Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={0.5} p={1}>
                      {notIncludedAnnotationClasses.map(annotationClass => (
                        <Chip key={annotationClass.name} color="gray">
                          <Icon
                            icon={annotationClass.annotationType === 'box' ? BoxIcon : Polygon}
                          />{' '}
                          {annotationClass.name}
                        </Chip>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mt={1} py={1} borderTop="1px solid" borderColor="gray-200">
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                backgroundColor="secondary-100"
                color="secondary"
                p={1}
              >
                <Icon icon={InfoCircle} />
                <Typography variant="s-regular">
                  {t('curate.datasets.generateSyntheticImages.steps.selectModel.info')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <Typography variant="m-medium">
                  {t('curate.datasets.generateSyntheticImages.steps.selectModel.applicableImages')}
                </Typography>
                <Typography variant="m-medium" color="primary" style={{ marginLeft: 'auto' }}>
                  {applicableImagesCount}
                </Typography>
                <Typography variant="m-medium" color="gray-300">
                  {' '}
                  /{' '}
                  {(selectedData || []).length > 0 ? (selectedData || []).length : totalImageCount}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    ),
  };
};

export default SelectGenerativeAIModel;
