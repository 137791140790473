import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import UsersTab from '../../../../../components/pages/analytics/AnalyticsUsersTab';
import { canManageProject } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectAnalyticsUserReportsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectUserReports,
  path: 'user-reports/:reportId?',
  title: ({ t }) => t('analytics.userReports.title'),
  component: UsersTab,
  isVisible({ authInfo }) {
    return canManageProject(authInfo) || authInfo?.isGuest || false;
  },
  isEnabled({ authInfo }) {
    return canManageProject(authInfo);
  },
});
