import { Box, ImageCategory, Keypoints, Polygon, Polyline } from '@superb-ai/icons';

type AnnotationType =
  | 'box'
  | 'bbox'
  | 'polygon'
  | 'mask'
  | 'keypoint'
  | 'keypoints'
  | 'image_category';

export function getAnnotationIcon(annotationTypes: AnnotationType) {
  return {
    bbox: Box,
    box: Box,
    polygon: Polygon,
    mask: Polygon,
    polyline: Polyline,
    keypoint: Keypoints,
    keypoints: Keypoints,
    image_category: ImageCategory,
  }[annotationTypes];
}
