import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DELETE_ISSUE } from '../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import ImageContext from '../../../../contexts/ImageContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import StageSizeContext from '../../../../contexts/StageSizeContext';
import { useModal } from '../../../../hooks/ModalHooks';
import IssuesService from '../../../../services/IssuesService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import AutoLabelUncertainAlertBar from './AutoLabelUncertainAlertBar';
import DisabledAlert from './DisabledAlert';
import FloatingButton from './floatingButtonMenu/FloatingButton';
import ImageDragHandler from './ImageDragHandler';
import KonvaStage from './KonvaStage';
import Menu from './Menu';
import SuiteImage from './SuiteImage';
import Thread from './thread';

const useStyles = makeStyles(() => ({
  canvasSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
  },
  badgeDiv: {
    position: 'absolute',
  },
  panel: {
    position: 'relative',
  },
  imageAdjustSection: {
    position: 'absolute',
    left: '25px',
    bottom: '5px',
  },
}));

const ImageSection = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openModal } = useModal();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelIssuesInfo = useLabelIssues();
  const stageSizeInfo = useContext(StageSizeContext.Context);
  const imageInfo = useContext(ImageContext.Context);

  const { email, projectRole } = authInfo;

  const { label, isAutoLabelUncertainty, autoLabelUncertaintyCount } = labelDetailViewInfo;
  const { cabinetTabState } = labelInformationInfo;
  const {
    setIssues,
    issueViewStatus,
    issuePanelState,
    setIssuePanelState,
    openBadgeInfo,
    setOpenBadgeInfo,
  } = labelIssuesInfo;

  const { adjustImage } = imageInfo;
  const [panelPosInfo, setPanelPosInfo] = useState({
    top: 0,
    left: 0,
    pos: {},
  });
  const [isPressRKey, setIsPressRKey] = useState(false);

  const isOwnerOrAdmin = UserRoleUnion.isOwner(projectRole) || UserRoleUnion.isAdmin(projectRole);

  const getIsCanDelete = (): boolean => {
    if (!openBadgeInfo.issue) return false;
    if (email === openBadgeInfo.issue.createdBy || isOwnerOrAdmin) return true;
    return false;
  };

  const updatePanelPos = (x: number, y: number, panelState: string): void => {
    let offsetWidth = 0;
    let offsetHeight = 0;
    let nextPos = {};

    switch (panelState) {
      case null:
        break;
      case 'create':
      case 'thread':
        offsetWidth = 320;
        offsetHeight = 360;
        break;
      case 'option':
        offsetWidth = 200;
        offsetHeight = 150;
        break;
      default:
        break;
    }

    if (x + offsetWidth < stageSizeInfo.width) {
      if (y + offsetHeight < stageSizeInfo.height) {
        nextPos = {
          left: '20px',
          top: '-10px',
        };
      } else {
        nextPos = {
          left: '20px',
          bottom: '-10px',
        };
      }
    } else if (y + offsetHeight < stageSizeInfo.height) {
      nextPos = {
        right: '20px',
        top: '-10px',
      };
    } else {
      nextPos = {
        right: '20px',
        bottom: '-10px',
      };
    }

    setPanelPosInfo({
      top: y,
      left: x,
      pos: nextPos,
    });
  };

  const handleClickDeleteConfirm = async () => {
    await IssuesService.deleteIssue({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      issueId: openBadgeInfo.issue.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const nextIssues = await IssuesService.getIssues({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    setIssues(nextIssues);
    setIssuePanelState(null);
    setOpenBadgeInfo({ issue: null, ref: null });
  };

  const handleClickDelete = (): void => {
    openModal({
      modalMessage: DELETE_ISSUE,
      mainButtonData: {
        text: 'DELETE',
        handleClick: handleClickDeleteConfirm,
      },
      subButtonData: true,
    });
  };
  const handleClickStatus = async () => {
    const nextStatus = openBadgeInfo.issue.status === 'OPEN' ? 'RESOLVED' : 'OPEN';

    await IssuesService.updateIssue({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      issueId: openBadgeInfo.issue.id,
      info: {
        status: nextStatus,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const nextIssues = await IssuesService.getIssues({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    setIssues(nextIssues);
    setOpenBadgeInfo({ issue: null, ref: null });
    setIssuePanelState(null);
  };

  useEffect(() => {
    const downHandler = (e: any) => {
      e.stopPropagation();
      switch (e.code) {
        case 'KeyR':
          setIsPressRKey(true);
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const downHandler = (e: any) => {
      e.stopPropagation();
      switch (e.code) {
        case 'BracketLeft':
          imageInfo.controlBrightness('DECREASE');
          break;
        case 'BracketRight':
          imageInfo.controlBrightness('INCREASE');
          break;
        case 'Semicolon':
          imageInfo.controlContrast('DECREASE');
          break;
        case 'Quote':
          imageInfo.controlContrast('INCREASE');
          break;
        case 'Period':
          imageInfo.controlBrightness('RESET');
          imageInfo.controlContrast('RESET');
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [imageInfo]);

  useEffect(() => {
    if (!isPressRKey) return;
    adjustImage();
    setIsPressRKey(false);

    // eslint-disable-next-line
  }, [isPressRKey]);

  const menuItems = [
    {
      title: getIsCanDelete() ? 'Delete thread' : '',
      onClick: handleClickDelete,
    },
    {
      title: issueViewStatus === 'OPEN' ? 'Resolve thread' : 'Re-open thread',
      onClick: handleClickStatus,
    },
  ];

  return (
    <>
      <ImageDragHandler>
        <MUI.Box className={classes.canvasSection}>
          {isAutoLabelUncertainty ? (
            <AutoLabelUncertainAlertBar count={autoLabelUncertaintyCount} />
          ) : null}
          <KonvaStage>
            <SuiteImage updatePanelPos={updatePanelPos} />
          </KonvaStage>
          {cabinetTabState === 'issue' && (
            <MUI.Box
              className={classes.badgeDiv}
              style={{ top: panelPosInfo.top, left: panelPosInfo.left }}
            >
              <MUI.Box className={classes.panel}>
                {issuePanelState === 'create' && (
                  <Thread isCreate issue={openBadgeInfo.issue} pos={panelPosInfo.pos} />
                )}

                {issuePanelState === 'thread' && (
                  <Thread isCreate={false} issue={openBadgeInfo.issue} pos={panelPosInfo.pos} />
                )}
                {issuePanelState === 'option' && (
                  <Menu pos={panelPosInfo.pos} menuItems={menuItems} />
                )}
              </MUI.Box>
            </MUI.Box>
          )}
          <MUI.Box className={classes.imageAdjustSection}>
            <FloatingButton />
          </MUI.Box>
        </MUI.Box>
      </ImageDragHandler>
      <DisabledAlert />
    </>
  );
};

export default ImageSection;
