import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, DropdownSelect } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';

import { ANNOTATION_TYPE } from '../../../consts/NewProjectConst';
import ProjectConst from '../../../consts/ProjectConst';
import { getUrl } from '../../../routes/util';

const QueryParamName = 'annotationType';

// Searchable annotation types
// C.f. civet src/projects/modelz/project_search.py
const AnnotationTypes = [
  'category',
  'box',
  'rbox',
  'polyline',
  'polygon',
  'keypoint',
  'cuboid2d',
  'cuboid',
] as const;

function getLabel(type: string) {
  return (
    <Box display="flex" alignItems="center" gap="6px">
      {
        ProjectConst.annotationIcons[
          type.toUpperCase() as keyof typeof ProjectConst.annotationIcons
        ]
      }
      {ANNOTATION_TYPE[type.toUpperCase() as keyof typeof ANNOTATION_TYPE]?.label ??
        startCase(type)}
    </Box>
  );
}

const options = AnnotationTypes.map(value => ({
  value,
  label: getLabel(value),
}));

export default function AnnotationTypeFilter(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const searchParams = Object.fromEntries(new URLSearchParams(history.location.search));

  function onChangeFilter(type: string[]) {
    history.push(
      getUrl(
        history.location.pathname,
        {},
        { ...searchParams, page: 1, [QueryParamName]: type?.join(',') || undefined },
      ),
    );
  }

  const value = searchParams[QueryParamName]?.split(',').filter(t => t) || [];

  return (
    <Box display="flex" width={200}>
      <DropdownSelect
        variant="stroke"
        value={value}
        options={options as any}
        placeholder={t('projects.filter.annotationType')}
        onChange={onChangeFilter}
      />
    </Box>
  );
}
