import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@superb-ai/norwegian-forest';

import { ObjectPropertiesAndCategoriesType } from '../../../types/projectTypes';
import ProjectUtils from '../../../utils/ProjectUtils';

const getPl = (isSiesta: boolean, depth: number, isGroup: boolean) => {
  if (isSiesta) {
    if (depth === 0) {
      return 0;
    }
    return isGroup ? 3 : 4;
  }
  if (depth <= 1) {
    return 0;
  }
  return isGroup ? 3 : 4;
};

const ImageCategoryItem: React.FC<{
  isSiesta: boolean;
  imageCategoryMap: any;
  imageCategory: {
    id: string;
    options?: string[];
    children?: string[];
    name: string;
    isGroup: boolean;
  };
  depth: any;
  type?: ObjectPropertiesAndCategoriesType;
}> = props => {
  const { t } = useTranslation();
  const { isSiesta, imageCategoryMap, imageCategory, depth, type } = props;
  const { id, name, isGroup } = imageCategory;
  const [isOpen, setIsOpen] = useState(id === 'root');

  const getCurImageCategory = () => {
    if (id === 'root') return null;

    const getArrow = () => {
      const children = imageCategory.options || imageCategory.children || [];

      if (children.length === 0) return null;
      const handleClickArrow = () => {
        setIsOpen(!isOpen);
      };

      return (
        <IconButton
          round
          icon={isOpen ? 'caretDown' : 'caretRight'}
          onClick={handleClickArrow}
          size="xs"
          color="grey"
        />
      );
    };

    return (
      <Box display="flex" alignItems="center" gap="4px">
        <Box flex="0 0 auto">{getArrow()}</Box>
        <Typography variant="body2" themedColor="text">
          {name}
        </Typography>
        <Typography variant="body2" themedColor="text">
          {type ? ` | ${t(ProjectUtils.convertObjectPropertiesAndCategorizationType(type))}` : ''}
        </Typography>
      </Box>
    );
  };
  const getChildren = () => {
    const children = imageCategory.options || imageCategory.children || [];

    if (children.length === 0 || !isOpen) return null;

    return children.map((child: any) => {
      const childId = typeof child === 'string' ? child : child.id;

      return (
        <ImageCategoryItem
          key={childId}
          isSiesta={isSiesta}
          depth={depth + 1}
          imageCategoryMap={imageCategoryMap}
          imageCategory={imageCategoryMap[childId]}
        />
      );
    });
  };

  return (
    <Box pl={getPl(isSiesta, depth, isGroup)} pt={0.5} pb={0.5}>
      {getCurImageCategory()}
      {getChildren()}
    </Box>
  );
};

export default ImageCategoryItem;
