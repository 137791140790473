import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight, ChartTreemap, List } from '@superb-ai/icons';
import { Card } from '@superb-ai/norwegian-forest';
import { Box, Icon, SegmentedControl, Typography } from '@superb-ai/ui';
import { orderBy } from 'lodash';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { HistoryType } from '../../../../types/exportTypes';
import EmptyPlot from '../elements/EmptyPlot';
import AnnotationsChangedTable from './AnnotationsChangedTable';
import AnnotationsChangedTreemap from './AnnotationsChangedTreemap';
import { ExportBadge } from './components/ExportBadge';
import { annotationChangesHeader } from './constants/tableHeaders';
import { AnnotationsChangedRow, ComparisonReport } from './types/types';
import { treemapDataFormatter } from './utils/formatter';
import {
  annotationsChangedTransfromer,
  getClassDisplayInfo,
  guideMessage,
  hasZeroAnnotationChanges,
  overallTrendTransformer,
} from './utils/helper';

type ViewMode = 'treemap' | 'table';

interface Props {
  selectedVersion: ComparisonReport;
  exportHistories: HistoryType[];
}

const AnnotationsChangedContainer = ({ selectedVersion, exportHistories }: Props) => {
  const { t } = useTranslation();
  const {
    project: { labelInterface },
  } = useProjectInfo();
  const [viewMode, setViewMode] = useState<ViewMode>('treemap');
  //@ts-ignore
  const rowData: AnnotationsChangedRow[] = annotationsChangedTransfromer(
    selectedVersion,
    getClassDisplayInfo(labelInterface),
  ).data;
  const header = annotationChangesHeader();
  const treemapData = treemapDataFormatter(rowData);

  const isDataEmpty = hasZeroAnnotationChanges(overallTrendTransformer(selectedVersion));
  const makeChart = (viewMode: string) => {
    if (viewMode === 'treemap') {
      return <AnnotationsChangedTreemap data={treemapData} />;
    } else {
      return (
        <AnnotationsChangedTable
          rows={orderBy(rowData, 'className', 'asc')}
          header={header}
          isDataEmpty={isDataEmpty}
        />
      );
    }
  };

  const onChangeViewMode = (mode: ViewMode) => {
    setViewMode(mode);
  };

  const compareExport = exportHistories.find(
    history => history.id === selectedVersion?.info.metadata.refExportId,
  );
  const baseExport = exportHistories.find(
    history => history.id === selectedVersion?.info.metadata.queryExportId,
  );

  return (
    <Card mt={3}>
      <Box display="flex" p={3} alignItems="center" borderBottom="1px solid" borderColor="gray-100">
        <Box>
          <Typography variant="h3">
            {t('analytics.exportsAnalytics.report.sheetName.annotationChanges')}
          </Typography>
          <Box display="flex" alignItems="center" gap={1} mt={0.5}>
            <ExportBadge type="base" name={baseExport?.name} />
            <Icon icon={ArrowRight} />
            <ExportBadge type="compare" name={compareExport?.name} />
          </Box>
        </Box>
        <Box ml="auto">
          <SegmentedControl
            buttonProps={{
              square: true,
            }}
            color="primary"
            value={viewMode}
            onChangeValue={onChangeViewMode}
            options={[
              {
                label: <Icon icon={ChartTreemap} />,
                value: 'treemap',
              },
              {
                label: <Icon icon={List} />,
                value: 'table',
              },
            ]}
          />
        </Box>
      </Box>
      <Box pr={4} pl={4} pb={3} style={{ backgroundColor: '#FBFBFB' }} position="relative">
        {makeChart(viewMode)}
        {isDataEmpty && (
          <EmptyPlot
            message={t(guideMessage(selectedVersion))}
            svgHeight={rowData.length * 20}
            marginTop={50}
          />
        )}
      </Box>
    </Card>
  );
};

export default AnnotationsChangedContainer;
