import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Button, useDialogState } from '@superb-ai/ui';

import CurateDiagnosisHubspotFormDialog from '../../../../../../../../components/HubspotForms/CurateDiagnosisHubspotFormDialog';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../../../../../../consts/ModalMessage';
import AnalyticsTracker from '../../../../../../../../analyticsTracker';

export default function ContactUsButton() {
  const { t } = useTranslation();
  const state = useDialogState();
  const eventId = 'suite-model-diagnosis-contact-sales-form';
  const { accountName } = useParams<{ accountName: string }>();
  const { openModal, closeModal } = useAlertModal();

  return (
    <>
      <Button
        size="l"
        variant="text"
        onClick={() => {
          AnalyticsTracker.contactUsClicked({ referrer: eventId, accountId: accountName });
          state.show();
        }}
      >
        {t('text.contactUs')}
      </Button>
      <CurateDiagnosisHubspotFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
    </>
  );
}
