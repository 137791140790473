import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoCircleOutline } from '@superb-ai/icons';
import { Tooltip } from '@superb-ai/norwegian-forest';
import { Box, Icon } from '@superb-ai/ui';
import cn from 'classnames';

import { FilterKey } from '../../../../../utils/filter/labelFilter';
import { FilterProps } from './types';

const useStyles = makeStyles({
  formControl: {
    minWidth: 80,
    width: '100%',
    height: '32px',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '2px',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
  },
  select: {
    fontSize: '13px',
    border: 'solid 1px #BCBCBC',
    color: '#333333',
    borderRadius: '2px',
    boxSizing: 'border-box',
    height: '32px',
    minWidth: '150px',
    '& .MuiSelect-select': {
      textAlign: 'left',
      padding: '7px 30px 7px 10px',
    },
    '& .MuiSelect-icon': {
      color: '#333333',
      width: '15px',
      padding: '2px 8px',
    },
  },
  currentRow: {
    color: '#ff625a',
    borderColor: '#ff625a',
    '& .MuiSvgIcon-root': {
      color: '#ff625a',
    },
  },
  filterBy: {
    width: '170px',
  },
});

interface Props extends FilterProps<string> {
  options?: readonly string[];
}

const FilterDropdown = ({
  value,
  index,
  objKey,
  isCurrentRow,
  options = [],
  onValueChange,
  onChangeCurrentRow,
}: Props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // For transition in MUI
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onOpen = () => {
    onChangeCurrentRow(index);
    changeIsOpenState(true);
  };

  const changeIsOpenState = (nextState: boolean) => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (nextState === false) {
      timerRef.current = setTimeout(() => {
        setIsOpen(nextState);
      }, 140);
    } else setIsOpen(nextState);
  };

  const getInformationTooltip = (filterKey: FilterKey) => {
    if (objKey !== 'filterBy') return null;

    const i18nKey = `labels.filters.description.${filterKey}`;
    const isVisible = i18n.exists(i18nKey) && !isOpen && value === filterKey;

    if (!isVisible) return null;

    return (
      <Tooltip
        anchorEl={
          <Box position="absolute" left="100%" style={{ top: 'calc(50% - 8px)' }}>
            <Icon icon={InfoCircleOutline} size={16} />
          </Box>
        }
      >
        {t(i18nKey)}
      </Tooltip>
    );
  };

  return (
    <MUI.FormControl className={classes.formControl}>
      <MUI.Select
        variant="standard"
        displayEmpty
        value={value}
        onChange={event => onValueChange(index, objKey, event.target.value as string)}
        onOpen={onOpen}
        onClose={() => changeIsOpenState(false)}
        className={cn(classes.select, {
          [classes.currentRow]: isCurrentRow,
          [classes.filterBy]: objKey === 'filterBy',
        })}
      >
        {objKey === 'filterBy' && (
          <MUI.MenuItem value="">{t('labels.filters.filterBy.title')}</MUI.MenuItem>
        )}
        {options.map(option => (
          <MUI.MenuItem key={option} value={option}>
            <Box width="100%" height="100%" position="relative">
              {t(`labels.filters.${objKey}.${option}`, option)}
              {getInformationTooltip(option as FilterKey)}
            </Box>
          </MUI.MenuItem>
        ))}
      </MUI.Select>
    </MUI.FormControl>
  );
};

export default FilterDropdown;
