import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, NamedColor, Tooltip } from '@superb-ai/ui';

import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import PercentageBarChart from '../charts/PercentageBarChart';
import { appendQuery, classificationEQQuery } from '../charts/utils';
import { LoadingIndicatorDiv } from '../components/LoadingIndicatorDiv';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import {
  useStatsClassificationValueQuery,
  useStatsComparedClassificationValueQuery,
} from '../queries/annotationQueries';
import { ClassificationValueDatum } from '../types';
import { getSuiteColor } from '../utils/colorScale';
import { ClassificationValueCell } from './ClassificationValueCell';
import { CountCell } from './CountCell';
import { getClassificationHeaders } from './utils';

type ClassificationTableRowProps = {
  classificationKey: string;
};
export function ClassificationTableValueDetail({ classificationKey }: ClassificationTableRowProps) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { isCompareMode, selectedCompareSetId } = useAnalyticsContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { data: values, isFetching: isFetchingValues } = useStatsClassificationValueQuery({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    classificationKey,
  });
  const { data: comparedValues, isFetching: isFetchingComparedValues } =
    useStatsComparedClassificationValueQuery({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      classificationKey,
      compareSetId: selectedCompareSetId,
      isCompareMode,
    });
  const rows = !values
    ? []
    : values.data.map((datum: ClassificationValueDatum) => {
        return (
          <ClassificationTableValueRow
            key={datum.value}
            classificationKey={classificationKey}
            queried={{
              totalCount: values.totalImageCount,
              datum,
            }}
            compared={{
              totalCount: comparedValues?.totalImageCount || 0,
              datum: comparedValues?.data.find(
                v => v.value === datum.value,
              ) as ClassificationValueDatum,
            }}
          />
        );
      });
  const loading = isFetchingValues || isFetchingComparedValues;
  return (
    <Box>
      {loading && <LoadingIndicatorDiv />}
      {!loading && <Box>{rows}</Box>}
    </Box>
  );
}

function ClassificationTableValueRow(props: {
  classificationKey: string; // classification key
  queried: {
    totalCount: number;
    datum: ClassificationValueDatum;
  };
  compared: {
    totalCount: number;
    datum: ClassificationValueDatum;
  };
}) {
  const { t } = useTranslation();
  const headers = getClassificationHeaders(t);

  const { isCompareMode } = useAnalyticsContext();
  const { queryString, setQueryString } = useQueryContext();
  const { queried, compared, classificationKey } = props;
  const [hoverOnRow, setHoverOnRow] = useState(false);
  const color = getSuiteColor('classification') as NamedColor;
  const comparedColor = 'red-500';
  const NAME_COLUMN_WIDTH = '170px'; // increase to decrease progress bar width

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      style={{ minHeight: '34px' }}
      onMouseEnter={() => setHoverOnRow(true)}
      onMouseLeave={() => setHoverOnRow(false)}
      cursor="pointer"
      p={1}
      backgroundColor={hoverOnRow ? 'gray-opacity-200' : 'gray-opacity-100'}
      onClick={() => {
        const query = appendQuery(
          classificationEQQuery(classificationKey, queried.datum?.value),
          queryString,
        );
        setQueryString(query);
        // TODO: enable once API is ready
        // history.push(getSearchQueryRoute(history, { query }));
      }}
    >
      <Tooltip content={t('curate.analytics.action.clickToApplyQuery')} placement="right">
        <Box
          display="flex"
          alignItems="center"
          style={{ width: NAME_COLUMN_WIDTH, marginLeft: '31px' }}
          gap={0.5}
        >
          <ClassificationValueCell name={queried.datum?.value} width={'120px'} />
        </Box>
      </Tooltip>
      <Box style={{ flex: 1 }} display="flex" flexDirection="column" gap={0.5}>
        <PercentageBarChart
          showLegend={false}
          chartDatum={[
            {
              key: queried.datum?.value + '-images',
              name: queried.datum?.value,
              total: queried.totalCount, // image count
              value: queried.datum?.count,
              share: queried.datum?.share || 0,
              barColor: color,
            },
          ]}
        />
        {isCompareMode && (
          <PercentageBarChart
            showLegend={false}
            chartDatum={[
              {
                key: compared.datum?.value + '-images',
                name: compared.datum?.value,
                total: compared.totalCount || 0,
                value: compared.datum?.count || 0,
                barColor: comparedColor,
                share: compared.datum?.share,
              },
            ]}
          />
        )}
      </Box>
      <CountCell
        width={headers[2]?.width as string}
        count={queried.datum?.share || 0}
        {...(isCompareMode ? { comparedCount: compared.datum?.share || 0 } : {})}
      />
      <CountCell
        width={headers[3]?.width as string}
        count={queried.datum.count || 0}
        {...(isCompareMode ? { comparedCount: compared.datum?.count || 0 } : {})}
      />
    </Box>
  );
}
