import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useDatasetContext } from '../../../../../../contexts/DatasetContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useObjectScopeContext } from '../../../../../../contexts/ObjectScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { getScopeFromRoute } from '../../../../../../utils/routeUtils';
import { LegendLabel, SingleLegend } from '../../../analytics/components/Legends';
import { BASE_POINT_LEGEND_COLOR } from './const';
import { usePoints } from './providers/DataProvider';
import { getSliceOrDatasetName } from './utils/utils';

export const BasePointsLegend = ({
  hasCompareMode,
  hasFilters,
}: {
  hasCompareMode: boolean;
  hasFilters: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const scope = getScopeFromRoute(history);

  const { queryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const { datasetInfo } = useDatasetContext();
  const points = usePoints();

  // tODO: make this better later
  const { totalCount: baseImageCount } = useImageScopeContext();
  const { totalCount: baseObjectCount } = useObjectScopeContext();

  const currentPageSet = sliceInfo ? 'slice' : 'dataset';
  const sliceOrDatasetName = getSliceOrDatasetName(currentPageSet, datasetInfo, sliceInfo);

  const sampledPointsCount = points?.length ?? '...';
  const totalPointsCount = scope === 'image' ? baseImageCount : baseObjectCount;
  const hasAppliedQueryOrFilters = Boolean(queryString || hasFilters);

  const samplingInfoText = t(`curate.embeddings.legend.${scope}CountTooltip`, {
    sampledCount: (sampledPointsCount || '...').toLocaleString(),
    totalCount: (totalPointsCount || '...').toLocaleString(),
  });

  return (
    <SingleLegend
      hasCompareMode={hasCompareMode}
      isDisabled
      tooltipContent={samplingInfoText}
      legendLabel={
        {
          legendText: hasAppliedQueryOrFilters
            ? t('curate.analytics.dataScope.queried.results')
            : sliceOrDatasetName,
          hexColor: BASE_POINT_LEGEND_COLOR,
          visible: true,
        } as LegendLabel
      }
    />
  );
};
