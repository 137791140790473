import { NamedColorWeight } from '@superb-ai/ui';

const METADATA_COMPARED_COLOR = ['red-500', '#B42A1E'];
const METADATA_COLOR = ['blue-400', '#3479FF'];

export const chartColors = {
  metadataKey: METADATA_COLOR,
  metadataValue: METADATA_COLOR,
  all: METADATA_COMPARED_COLOR,
  dataset: METADATA_COMPARED_COLOR,
  slice: METADATA_COMPARED_COLOR,
  comparedKey: METADATA_COMPARED_COLOR,
  comparedValue: METADATA_COMPARED_COLOR,
  objects: ['skyblue-500', '#357F9E'],
  objectsAll: ['red-500', '#B42A1E'],
  annotationsPerImage: ['violet-500', '#312094'],
  scatter: ['red-500', '#FF3A28'],
  classification: ['mint-500', '#3AA7A7'],
  classificationAll: ['red-500', '#B42A1E'],
} as Record<string, (NamedColorWeight | string)[]>;

type chartKey = keyof typeof chartColors;

export function getSuiteColor(key: chartKey): NamedColorWeight {
  return (chartColors[key] ? chartColors[key][0] : '') as NamedColorWeight;
}

export function getChartColor(key: chartKey): string {
  return chartColors[key] ? chartColors[key][1] : '';
}
