// modelTrainingResuleTypeGuards.ts
/**
 * myModel.modelTraining의 타입을 확인하고,
 * 해당 타입에 따라 안전하게 프로퍼티에 접근할 수 있도록 돕는 유틸리티 함수입니다.
 * 이 함수들을 사용하여 타입 안전성을 보장하는 것이 좋습니다.
 */

import { GenerationAITrainingResult, RecognitionAITrainingResult } from './types';

export function isRecognitionAIModelTrainingResult(
  trainingResult: RecognitionAITrainingResult | GenerationAITrainingResult,
): trainingResult is RecognitionAITrainingResult {
  return 'overallPerformance' in trainingResult;
}

export function isGenerationModelAITrainingResult(
  trainingResult: RecognitionAITrainingResult | GenerationAITrainingResult,
): trainingResult is GenerationAITrainingResult {
  return 'classInfo' in trainingResult;
}
