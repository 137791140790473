import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Card, Typography } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import UserRoleUnion from '../../../../../union/UserRoleUnion';
import WorkappUnion from '../../../../../union/WorkappUnion';
import { formatDate } from '../../../../../utils/date';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import ImageCategoryList from '../../../../elements/projectConfiguration/ImageCategoryList';
import ImageCategoryListContainer from '../../../../elements/projectConfiguration/ImageCategoryListContainer';
import ObjectClassList from '../../../../elements/projectConfiguration/ObjectClassList';
import { getAllowSelfAssign } from '../../../newProject/helper';
import BodyAnnotationType from './BodyAnnotationType';
import BodyDataType from './BodyDataType';
import Header from './Header';

const Layout: FC = () => {
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();
  const { t } = useTranslation();

  const { project } = projectInfo;
  const { name, createdAt, createdBy, description, workapp, labelInterface } = project;
  const { categorization } = labelInterface;
  const allowAdvancedQa = project?.settings?.allowAdvancedQa || false;
  const allowSelfAssign = getAllowSelfAssign(project?.settings?.numMaxSelfAssign);
  const consensusLabeling = useFeatureFlag('consensusLabeling');
  const isAvailableAdvancedQA =
    consensusLabeling && ProjectUtils.isAvailableAdvancedQA(project.workapp);

  const getImageCategory = () => {
    if (!categorization) return null;

    const label = WorkappUnion.isVideoApp(project.workapp)
      ? t('projectSettings.configuration.videoCategory')
      : t('projectSettings.configuration.imageCategory');
    return (
      <ConfigurationItem label={label}>
        <ImageCategoryListContainer>
          {WorkappUnion.isImageDefault(workapp) && categorization && (
            <ImageCategoryList categorization={categorization} />
          )}
          {WorkappUnion.isSiesta(workapp) &&
            !!categorization?.properties?.length &&
            categorization?.properties?.map((property: any, idx: number) => (
              <ImageCategoryList isSiesta categorization={property} key={idx} />
            ))}
        </ImageCategoryListContainer>
        {WorkappUnion.isSiesta(workapp) && !categorization?.properties?.length && (
          <Typography variant="body2">–</Typography>
        )}
      </ConfigurationItem>
    );
  };

  return (
    <Card p={3} mb={3}>
      <Header />
      <Box
        mb={3}
        display="grid"
        style={{ gridTemplateColumns: 'max-content 1fr', gap: '24px 16px' }}
      >
        <ConfigurationItem label={t('projectSettings.configuration.name')}>
          <Typography variant="body2">{name}</Typography>
        </ConfigurationItem>
        <ConfigurationItem label={t('projectSettings.configuration.description')}>
          <Typography variant="body2">{description || '-'}</Typography>
        </ConfigurationItem>
        <ConfigurationItem label={t('projectSettings.configuration.dataType')}>
          <BodyDataType workapp={workapp} />
        </ConfigurationItem>
        <ConfigurationItem label={t('projectSettings.configuration.annotationTypes')}>
          <BodyAnnotationType labelInterface={labelInterface} />
        </ConfigurationItem>
        <ConfigurationItem label={t('projectSettings.configuration.objectClass')}>
          <ObjectClassList project={project} fontSize={14} color="#333" />
        </ConfigurationItem>
        {getImageCategory()}
        <ConfigurationItem label={t('selfAssign.title')}>
          <Typography variant="body2">
            {allowSelfAssign ? t('status.enabled') : t('status.disabled')}
          </Typography>
        </ConfigurationItem>
        {isAvailableAdvancedQA &&
          (UserRoleUnion.isAdmin(authInfo.role) || UserRoleUnion.isOwner(authInfo.role)) && (
            <ConfigurationItem label={t('projectSettings.configuration.advancedQA')}>
              <Typography variant="body2">
                {allowAdvancedQa ? t('status.enabled') : t('status.disabled')}
              </Typography>
            </ConfigurationItem>
          )}
      </Box>
      <Typography variant="body3" themedColor={['grey', 300]}>
        <Trans t={t} i18nKey="projectSettings.configuration.createdByOn">
          Created by <strong>{{ creator: createdBy }}</strong> on{' '}
          <strong>{{ date: `${formatDate(createdAt)}` }}</strong>.
        </Trans>
      </Typography>
    </Card>
  );
};

export default Layout;

const ConfigurationItem: FC<{ label: string }> = ({ children, label }) => {
  return (
    <>
      <Typography variant="body2" themedColor={['grey', 500]}>
        {label}
      </Typography>
      <Box style={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}>{children}</Box>
    </>
  );
};
