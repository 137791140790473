/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Directory, FileEntry, ItemType } from 'react-aspen';

import { Icon, IconButton } from '@superb-ai/norwegian-forest';
import cn from 'classnames';

import TreeItem from './TreeItem';

class FileTreeItem extends TreeItem {
  static RenderHeight = 28; // height of one row, after CSS applied

  private get label() {
    const { item } = this.props;
    return (item as FileEntry).fileName;
  }

  private get icon(): JSX.Element {
    const { item, itemType } = this.props;
    if (itemType === ItemType.Directory) {
      return <></>;
    }
    const type = (item as FileEntry).getMetadata('type');
    const color = (item as FileEntry).getMetadata('color');
    return <Icon name={type || 'file'} customColor={color} />;
  }

  public render(): JSX.Element {
    const { item, itemType, decorations } = this.props;

    const isDirExpanded = itemType === ItemType.Directory && (item as Directory).expanded;
    const fileOrDir = itemType === ItemType.File ? 'file' : 'directory';
    const isInfo = itemType === ItemType.File && (item as FileEntry).getMetadata('isInfo');

    const isSelected = decorations && decorations.classlist.indexOf('active') !== -1;
    const isMixed = decorations && decorations.classlist.indexOf('mixed') !== -1;
    const isDisabled = decorations && decorations.classlist.indexOf('disabled') !== -1;
    const isSelectable = this.props.isSelectable && !isInfo && !isDisabled;

    const handleClick = () => {
      if (isDisabled) return;
      this.handleClick();
    };

    return (
      <div
        role="option"
        aria-selected={isSelected}
        tabIndex={-1}
        className={cn(
          ['file-entry', 'object-entry'],
          fileOrDir,
          { isSelectable },
          decorations && decorations.classlist,
          isInfo ? 'info' : '',
          `depth-${item.depth}`,
        )}
        data-depth={item.depth}
        onClick={handleClick}
        onKeyPress={this.handleKey}
      >
        {fileOrDir === 'directory' && (
          <span className={cn('directory-toggle', isDirExpanded ? 'open' : '')}>
            <IconButton
              round
              size="xxs"
              color="textDefault"
              icon="chevronDown"
              onClick={e => {
                e.stopPropagation();
                this.handleClickDirectoryToggle();
              }}
            />
          </span>
        )}
        {this.props.showCheckbox && this.props.isSelectable && (
          <span className="file-icon toggle-icon">
            <Icon
              name={isSelected ? (isMixed ? 'checkboxMixed' : 'checkboxChecked') : 'checkbox'}
              color={isSelected ? 'secondary' : 'textDefault'}
            />
          </span>
        )}
        <span className="file-icon">{!isInfo && this.props.showIcon && this.icon}</span>
        <span className="file-label">{this.label}</span>
        {this.props.isVisibilityToggle && !isInfo && (
          <span>
            <IconButton
              round
              size="xxs"
              color="textDefault"
              icon={decorations.classlist.indexOf('hidden') === -1 ? 'visible' : 'visibleOff'}
              onClick={this.toggleVisibility}
              className="visibility-toggle"
            />
          </span>
        )}
        {this.props.isDeletable && !isInfo && (
          <span>
            <IconButton
              round
              size="xxs"
              color="textDefault"
              icon="clear"
              onClick={this.handleDelete}
            />
          </span>
        )}
      </div>
    );
  }
}

export default FileTreeItem;
