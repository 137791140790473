import { useTranslation } from 'react-i18next';

import { WarningOutline } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

export const RefreshPageBanner = ({
  target,
}: {
  target: 'Classes' | 'Categories';
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Box
      p={1.5}
      display="flex"
      backgroundColor="primary-200"
      justifyContent="center"
      alignItems="center"
      gap={0.5}
    >
      <Icon icon={WarningOutline} color="primary" />
      <Typography color="primary" variant="l-strong">
        {t(`analytics.project.pleaseRefreshToGetUpdated${target}`)}
      </Typography>
    </Box>
  );
};
