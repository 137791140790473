import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { camelCase, chain, isEmpty, map, size } from 'lodash';

import TodoListUrls, { TodoList } from '../../../consts/TodoListUrls';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import UserRoleUnion from '../../../union/UserRoleUnion';

const useStyle = makeStyles({
  link: {
    fontWeight: 500,
  },
});

type Props = {
  text: string;
  i18nKey: string;
  checked: boolean;
  onAssignExistingData: () => void;
};

const TodoText: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { text, checked, onAssignExistingData, i18nKey } = props;
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { setIsUploadModalOpen } = useUploadInfo();

  const textList = text
    .split('[')
    .flatMap(t => {
      if (isEmpty(t)) return null;
      const list = t.split(']');
      if (isEmpty(list[1])) {
        return { type: 'link', content: list[0] };
      }
      const parentheses = list[1].split('(');
      if (size(parentheses) > 1) {
        return [
          { type: 'link', content: list[0] },
          { type: 'text', content: parentheses[0] },
          { type: 'secondaryText', content: `(${parentheses[1]}` },
        ];
      }
      return [
        { type: 'link', content: list[0] },
        { type: 'text', content: list[1] },
      ];
    })
    .filter(Boolean);

  const handleClickLink = (content: string) => () => {
    if (UserRoleUnion.isWorker(authInfo.projectRole)) return;
    const target = camelCase(content) as TodoList;
    if (
      (target === 'uploadNewData' || target === 'assignExistingData') &&
      UserRoleUnion.isManager(authInfo.projectRole)
    )
      return;
    if (target === 'assignExistingData') {
      onAssignExistingData();
      return;
    }
    if (target === 'uploadNewData') {
      setIsUploadModalOpen(true);
      return;
    }

    const url = TodoListUrls(routeInfo.urlMatchInfo.accountName, routeInfo.urlMatchInfo.projectId)[
      target
    ];
    routeInfo.history.push(url);
  };

  return (
    <Trans t={t} i18nKey={i18nKey}>
      {map(textList, (text: { content: string; type: 'secondaryText' | 'link' | 'text' }) => {
        if (text.type === 'link') {
          return (
            <MUI.Link
              className={checked ? classes.link : ''}
              key={text.content}
              variant="body2"
              color={checked ? 'textPrimary' : 'secondary'}
              onClick={handleClickLink(text.content)}
              underline="hover"
            >
              {text.content}
            </MUI.Link>
          );
        }
        if (text.type === 'secondaryText') {
          return (
            <MUI.Typography key={text.content} display="inline" color="primary" variant="body2">
              {text.content}
            </MUI.Typography>
          );
        }
        return (
          <MUI.Typography key={text.content} display="inline" variant="body2">
            {text.content}
          </MUI.Typography>
        );
      })}
    </Trans>
  );
};

export default TodoText;
