import React from 'react';
import { TFunction, Trans } from 'react-i18next';

import { Typography } from '@superb-ai/norwegian-forest';
import { LinkTypography } from '@superb-ai/ui';

import { getUserManualUrl } from '../../../../../consts/DocsLink';

function AzureBlobStorage(t: TFunction, language: string) {
  const { AZURE_BLOB_STORAGE_INTEGRATIONS_MANUAL } = getUserManualUrl(language);
  return {
    title: 'Azure Blob Storage',
    docsLink: AZURE_BLOB_STORAGE_INTEGRATIONS_MANUAL,
    summary: <Trans t={t} i18nKey="integration.azure.summary" />,
    description: (
      <>
        <Typography variant="body3">
          <Trans t={t} i18nKey="integration.azure.description" />
        </Typography>
      </>
    ),
    logo: <img src="/static/image/abs.png" width="100%" alt="s3 logo" />,
    addUrl: '/account/integrations/ABS/add',
    tabs: {
      before: (
        <Typography variant="body3">
          <strong>{t('integration.azure.tabs.before.1')}</strong>
          <br />
          {t('integration.azure.tabs.before.1-1')}
          <br />
          <br />
          <strong>{t('integration.azure.tabs.before.2')}</strong>
          <br />
          {t('integration.azure.tabs.before.2-1')}
          <br />
          {t('integration.azure.tabs.before.2-2')}
          <br />
          <br />
          <strong>{t('integration.needHelp')} </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  href={AZURE_BLOB_STORAGE_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
      troubleshooting: (
        <Typography variant="body3">
          <strong>{t('integration.azure.tabs.troubleshooting.1')}</strong>
          <br />
          {t('integration.azure.tabs.troubleshooting.1-1')}
          <br />
          {t('integration.azure.tabs.troubleshooting.1-2')}
          <br />
          <br />
          <strong>{t('integration.needHelp')} </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  href={AZURE_BLOB_STORAGE_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
    },
  };
}

export default AzureBlobStorage;
