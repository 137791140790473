import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, DropdownList, Tooltip } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useGetProjectList } from '../../../hooks/GetProjectList';
import { ProjectData } from '../../../types/projectTypes';
import UserRoleUnion from '../../../union/UserRoleUnion';

interface Props extends Omit<React.ComponentProps<typeof DropdownList>, 'value' | 'onChange'> {
  value: { name: string; id: string };
  onChange: (value: ProjectData) => void;
}

export default function ProjectSelector({ value, onChange, ...otherProps }: Props): JSX.Element {
  const { t } = useTranslation();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [page, setPage] = useState(1);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [loadedProjects, setLoadedProjects] = useState<Record<string, ProjectData>>({});
  const defaultProjects = useMemo(
    () => (value ? [{ label: value.name, value: value.id }] : []),
    [value],
  );

  const getProjectList = useGetProjectList();

  let mounted = true;
  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  const addToLoadedProjects = (projects: any[]) => {
    for (const project of projects) {
      if (!loadedProjects[project.id]) {
        loadedProjects[project.id] = project;
      }
    }
    setLoadedProjects(loadedProjects);
  };

  async function loadProjects(query: string, page: number): Promise<any[]> {
    setPage(page);
    const params = { page, nameIcontains: query };
    try {
      const { results, count } = await getProjectList({
        ...params,
        origin: 'apps/projects/project/ProjectSelector',
      });
      if (!mounted) return [];
      setHasMoreProjects(count > results.length);
      addToLoadedProjects(results);
      const options = results.map(project => ({ label: project.name, value: project.id }));
      // Sort so that current project appears first.
      options.sort((a, b) => (a.value === value.id ? -1 : b.value === value.id ? 1 : 0));
      return options;
    } catch (err: any) {
      if (!mounted) return [];
      setHasMoreProjects(false);
      return [];
    }
  }

  function loadMoreProjects(query: string): Promise<any[]> {
    return loadProjects(query, page + 1);
  }

  function getProjectsOptions(query: string): Promise<any[]> {
    return loadProjects(query, 1);
  }

  function handleChange(projectId: string) {
    onChange(loadedProjects[projectId]);
  }

  const { role } = authInfo;
  const isOwnerOrAdmin = UserRoleUnion.isOwner(role) || UserRoleUnion.isAdmin(role);

  const handleClickNewProjectButton = () => {
    routeInfo.history.push({
      pathname: `/${routeInfo.urlMatchInfo.accountName}/label/new_project/create_project`,
      state: { prevPath: routeInfo.history.location.pathname },
    });
  };

  const createProjectButton = (
    <Box bt display="flex" height={36}>
      <Button
        IconAdornment="plus"
        variant="text"
        color="tertiary"
        onClick={handleClickNewProjectButton}
      >
        {t('projects.createNewProject')}
        <span style={{ marginLeft: 'auto' }}></span>
      </Button>
    </Box>
  );

  return (
    <DropdownList
      placeholder={t('integration.addIntegration.slack.step.selectProject.title')}
      searchText={t('components.shared.search')}
      options={defaultProjects}
      value={value.id}
      hasMore={hasMoreProjects}
      loadMore={loadMoreProjects}
      hasSearch
      getOptions={getProjectsOptions}
      onChange={handleChange}
      Footer={isOwnerOrAdmin && createProjectButton}
      formatSelected={label => (
        <Tooltip
          placement="right"
          anchorEl={
            <Typography
              display="block"
              variant="l-strong"
              textAlign="left"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {label}
            </Typography>
          }
        >
          {label}
        </Tooltip>
      )}
      {...otherProps}
    />
  );
}
