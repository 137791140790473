import React from 'react';

import { Box } from '@superb-ai/ui';

const ImageCategoryListContainer: React.FC<{
  maxHeight?: number;
}> = props => {
  const { children, maxHeight = 200 } = props;

  return (
    <Box style={{ maxHeight, backgroundColor: '#FBFBFB' }} overflow="auto">
      {children}
    </Box>
  );
};

export default ImageCategoryListContainer;
