import * as d3 from 'd3';
import { ScaleOrdinal } from 'd3';
import { map } from 'lodash';

import { COLOR_CHIP_TAG_30PERCENT_OPACITY } from '../../../../../consts/ColorChips';
import LabelInterfaceUtils, {
  LabelInterface,
  ObjectClass,
} from '../../../../../utils/LabelInterfaceUtils';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { BaseDatum } from '../../userStats/types';

/* eslint-disable import/prefer-default-export */

const { WHITE_TWO } = COLOR_CHIP_TAG_30PERCENT_OPACITY;
/**
 *
 * @param {array} objects Object classes info from label interface.
 *
 * From projectInfo['project']['labelInterface']['objects'] where each object contains info roughly contains:
 *
 * ex. {
 *   info:
 *     aiClassMap: [],
 *     max: 0,
 *     min: 0,
 *     properties: [],
 *     shapes: {box: {defaultValue: {color: 'FF625A'}} },
 *     name: 'person'
 *   }
 *
 * Returns object mapping object names to matching colors.
 *
 * Notes: Defaults to grey color if object name is undefined. This happens when all object/category
 * count sum to 0, as we mock the data to be 1 to generate the chart.
 * */

type ObjectColorsMap = Record<string, string>;

// non siesta is depreacted
export const getObjectClassColors = (objects: BaseDatum[]): ObjectColorsMap => {
  return objects.reduce(
    (result, object) => {
      const shape = ProjectUtils.getShape(object);
      const { color } = object.info.shapes[shape].defaultValue;
      result[object.name] = color;
      return result;
    },
    { undefined: WHITE_TWO },
  );
};

/**
 * @param {array} objects objectClasses objects from projectInfo
 *
 * Sample object:
 *   {
 *     annotationType: "box"
 *     color: "#A3EB57"
 *     constraints: {}
 *     id: "5deaa5ba-cbbe-41e4-a164-46c923141a09"
 *     name: "Cheetah"
 *     properties: []
 *   }
 *
 * Returns object mapping object names to matching colors.
 *
 * Notes: Defaults to grey color if object name is undefined. This happens when all object/category
 * count sum to 0, as we mock the data to be 1 to generate the chart.
 */
export const getSiestaObjectClassColors = (labelInterface: LabelInterface): ObjectColorsMap => {
  const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
  return objects.reduce(
    (result: ObjectColorsMap, object: ObjectClass) => {
      result[object['id']] = object.color;
      return result;
    },
    { undefined: WHITE_TWO },
  );
};

/**
 *
 * Returns custom color scale parametrized by array indices. Array index is used instead of value
 * so that distinct data units have separate color, even if their values are same.
 */
export const getSpbDefaultColors = (
  array: string[] | number[],
): ScaleOrdinal<string, Range | unknown> => {
  const AIColors = [
    '#ff625a',
    '#fe9573',
    '#ffcc00',
    '#a3eb57',
    '#4ae2b9',
    '#5a7bff',
    '#6648ff',
    '#a6a6a6',
  ];
  let colors = array.length >= 7 ? AIColors : AIColors.slice(0, array.length);
  if (array.length <= 4) {
    colors = ['#a3eb57', '#4ae2b9', '#5a7bff', '#ffcc00'];
  }

  const indices = map(array, (d, index) => index.toString());
  return d3.scaleOrdinal().domain(indices).range(colors);
};

export const CURATE_COLOR = '#3479FF';
const light = [
  '#FFD2D2',
  '#FFE0C3',
  '#FFF0B1',
  '#FAFFBE',
  '#E6FFDD',
  '#E6FFDD',
  '#CBFFF1',
  '#CFF1FF',
  '#DBE7FF',
  '#DFD8FB',
  '#EECAFF',
  '#FFD6F1',
  '#F4F4F4',
];
const normal = [
  '#FF3A28',
  '#FF792E',
  '#FFDE33',
  '#D3F354',
  '#82DB24',
  '#4AE2B9',
  '#4ECAFF',
  '#3479FF',
  '#702DFF',
  '#BF36FF',
  '#FF4881',
  '#A6A6A6',
];

const dark = [
  '#B42A1E',
  '#BB5821',
  '#9F8526',
  '#88A123',
  '#547D46',
  '#3AA7A7',
  '#357F9E',
  '#385588',
  '#312094',
  '#6D438D',
  '#9C2C52',
  '#4E4E4E',
];
export const colorPalette = [
  normal[7], // '#3479FF' - Eighth color from 'normal' array
  light[0], // '#FFD2D2' - First color from 'light' array
  normal[3], // '#D3F354' - Fourth color from 'normal' array
  dark[4], // '#547D46' - Fifth color from 'dark' array
  light[2], // '#FFF0B1' - Third color from 'light' array
  normal[9], // '#BF36FF' - Tenth color from 'normal' array
  dark[6], // '#357F9E' - Seventh color from 'dark' array
  light[4], // '#E6FFDD' - Fifth color from 'light' array
  normal[5], // '#4AE2B9' - Sixth color from 'normal' array
  light[6], // '#CBFFF1' - Seventh color from 'light' array
  normal[1], // '#FF792E' - Second color from 'normal' array
  dark[8], // '#312094' - Ninth color from 'dark' array
  light[8], // '#DBE7FF' - Ninth color from 'light' array
  normal[0], // '#FF3A28' - First color from 'normal' array
  dark[2], // '#9F8526' - Third color from 'dark' array
  light[10], // '#EECAFF' - Eleventh color from 'light' array
  normal[4], // '#82DB24' - Fifth color from 'normal' array
  dark[0], // '#B42A1E' - First color from 'dark' array
  light[12], // '#F4F4F4' - Thirteenth color from 'light' array
  normal[6], // '#4ECAFF' - Seventh color from 'normal' array
  dark[9], // '#6D438D' - Tenth color from 'dark' array
  light[3], // '#FAFFBE' - Fourth color from 'light' array
  normal[2], // '#FFDE33' - Third color from 'normal' array
  dark[7], // '#385588' - Eighth color from 'dark' array
  light[1], // '#FFE0C3' - Second color from 'light' array
  normal[10], // '#BF36FF' - Eleventh color from 'normal' array
  dark[11], // '#4E4E4E' - Twelfth color from 'dark' array
  light[5], // '#E6FFDD' - Sixth color from 'light' array
];

export const getSpbColorBlindFriendlyColors = (n: number): ScaleOrdinal<string, string> => {
  const indices = Array.from({ length: n }, (_, index) => String(index));
  return d3.scaleOrdinal<string>().domain(indices).range(colorPalette);
};

export const getSpbColorBlindFriendlyColorsString = (
  keys: string[],
): ScaleOrdinal<string, string> => {
  return d3.scaleOrdinal<string>().domain(keys).range(colorPalette);
};
