import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon, useAlertModal } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';
import { has } from 'lodash';
import { useSnackbar } from 'notistack';

import AnalyticsTracker from '../../../analyticsTracker';
import { ProjectSteps } from '../../../configs/NewProjectStepsConfig';
import { EDIT_PROJECT_AFTER_ALERT, FINISH_CREATE_PROJECT } from '../../../consts/ModalMessage';
import { NAME_DUPLICATE } from '../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useNewProjectInfo } from '../../../contexts/NewProjectContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectService from '../../../services/ProjectService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import helper from './helper';
import { EDIT_PROJECT_PATH } from './paths';
import shyCreditHelper from './shyCreditHelper';

const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '5px',
  },
  button: {
    width: '77px',
    maxWidth: '77px',
    height: '34px',
    padding: '0px',
    fontSize: '13px',
  },
  buttonRight: {
    marginLeft: '4px',
  },
  buttonIcon: {
    marginRight: '4px',
  },
  tooltip: {
    '& .MuiTooltip-popper': {
      top: '0 !important',
      fontSize: '15px !important',
    },
  },
  tooltipText: {
    fontSize: '13px',
  },
}));

const Layout = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { openModal, closeModal } = useAlertModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const routeInfo = useRouteInfo();
  const { projectId } = useParams<{ projectId?: string }>();
  const authInfo = useAuthInfo();
  const newProjectInfo = useNewProjectInfo();
  const { currentStep, steps } = newProjectInfo;
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;
  const { updateProjectInfo } = useProjectInfo();

  const tooltipTitle = helper.checkProcedure(newProjectInfo);

  const isPrevButtonDisabled = (() => {
    if (currentStep.activeStep > 0) return false;
    if (currentStep.innerStep > 0) return false;
    return true;
  })();

  const isNextButtonDisabled = steps.length === 1 || tooltipTitle !== '';

  const isFinishButton = (() => {
    if (steps.length === 1) return false;
    if (currentStep.activeStep < steps.length - 1) return false;
    if (
      has(currentStep, 'innerStep') &&
      currentStep.innerStep < steps[currentStep.activeStep].contents.length - 1
    )
      return false;
    return true;
  })();

  const editProject = async () => {
    let labelInterface;
    if (newProjectInfo.selectedWorkapp === 'image-default') {
      labelInterface = helper.createLabelInterfaceForImage(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'image-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'video-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'pointclouds-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    }

    try {
      const { id } = await ProjectService.editProjectWithNewProjectInfo({
        newProjectInfo,
        labelInterface,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      await updateProjectInfo();

      routeInfo.history.push(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${id}/settings?refresh=true`,
      );
    } catch (err: any) {
      if (err.message === 'Duplicated') {
        enqueueSnackbar(NAME_DUPLICATE({ t, name: 'Project name' }), {
          variant: 'warning',
        });
        newProjectInfo.setCurrentStep(ProjectSteps.CREATE_PROJECT);
      }
    }
  };

  const createNewProject = async () => {
    let labelInterface;
    if (newProjectInfo.selectedWorkapp === 'image-default') {
      labelInterface = helper.createLabelInterfaceForImage(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'image-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'video-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    } else if (newProjectInfo.selectedWorkapp === 'pointclouds-siesta') {
      labelInterface = shyCreditHelper.createLabelInterfaceForImageSequence(newProjectInfo);
    }

    try {
      const { id } = await ProjectService.createProject({
        newProjectInfo,
        labelInterface,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      AnalyticsTracker.projectCreated({
        accountId: authInfo?.accountName ?? '',
        dataType: newProjectInfo.selectedDataType,
        categoriesCount: newProjectInfo.imageCategories.length,
        autoLabelObjectClassCount: 0,
        objectClassCount: newProjectInfo.objectClasses.length,
        projectId: id,
      });

      routeInfo.history.push(`/${routeInfo.urlMatchInfo.accountName}/label/project/${id}/`);
    } catch (err: any) {
      if (err.message === 'Duplicated') {
        enqueueSnackbar(NAME_DUPLICATE({ t, name: 'Project name' }), {
          variant: 'warning',
        });
        newProjectInfo.setCurrentStep(ProjectSteps.CREATE_PROJECT);
      }
    }
  };

  const handleClickFinish = () => {
    if (isEditPage) {
      openModal({
        ...EDIT_PROJECT_AFTER_ALERT({ t, projectName: newProjectInfo.projectName }),
        mainButton: {
          text: t('button.save'),
          onClick: () => {
            editProject();
            closeModal();
          },
        },
        subButton: { text: t('button.cancel'), onClick: closeModal },
      });
    } else {
      openModal({
        ...FINISH_CREATE_PROJECT({ t, projectName: newProjectInfo.projectName }),
        mainButton: {
          text: t('button.create'),
          onClick: () => {
            createNewProject();
            closeModal();
          },
        },
        subButton: { text: t('button.cancel'), onClick: closeModal },
      });
    }
  };

  const handleClickPrev = () => {
    setIsLoading(true);
    setTimeout(() => {
      helper.handleClickPrev(newProjectInfo, routeInfo, pathname, projectId);
      setIsLoading(false);
    }, 0);
  };

  const handleClickNext = () => {
    if (isFinishButton) {
      handleClickFinish();
    } else {
      setIsLoading(true);
      setTimeout(() => {
        helper.handleClickNext(newProjectInfo, routeInfo, pathname, projectId);
        setIsLoading(false);
      }, 0);
    }
  };

  return (
    <>
      <MUI.Box className={classes.box}>
        <MUI.Button
          className={classes.button}
          color="primary"
          onClick={handleClickPrev}
          disabled={isPrevButtonDisabled}
        >
          <Icon
            className={classes.buttonIcon}
            name="chevronLeft"
            size="13px"
            customColor={
              isPrevButtonDisabled ? theme.palette.grey[400] : theme.palette.primary.main
            }
          />
          {t('button.back')}
        </MUI.Button>
        <MUI.Tooltip
          className={classes.tooltip}
          title={tooltipTitle ? <span className={classes.tooltipText}>{tooltipTitle}</span> : ''}
          placement="right"
        >
          <span>
            <MUI.Button
              className={classnames(classes.button, classes.buttonRight)}
              variant="contained"
              color="primary"
              onClick={handleClickNext}
              disabled={isNextButtonDisabled}
            >
              {!isFinishButton ? t('button.next') : t('projects.createProject.finish')}
            </MUI.Button>
          </span>
        </MUI.Tooltip>
      </MUI.Box>
      <CircularProgressDialog isLoading={isLoading} />
    </>
  );
};

export default Layout;
