import { orderBy } from 'lodash';

import { formatDateTimeDownload } from '../../../../../../../utils/date';
import { METADATA_CARDINALITY_THRESHOLD } from '../../../../../services/AnalyticsService';
import { PageSet } from '../../../../../types/routeTypes';
import { ChartSpec } from '../AnnotationsContainer';
import { ChartDatum } from '../charts/types';
import {
  AnnotationStatsDownload,
  AnnotationTypesDownload,
  ClassificationValueItem,
  ClassStatsDatum,
  ClassStatsDownload,
  MetadataKeyDatum,
} from '../types';

export const getDownloadFilePrefix = (
  viewType: PageSet,
  currentPageName: string,
  chartName: string,
  fileType: string,
): string => {
  const currentDateTime = formatDateTimeDownload(new Date());
  return `${viewType}_${currentPageName}_${chartName}_${currentDateTime}`;
};

function formatAnnotationStats(data: ChartDatum[]): AnnotationStatsDownload[] {
  return data.map(({ key, count }) => {
    return {
      annotations_per_image: key as string,
      image_count: count,
    };
  });
}

function formatAnnotationTypes(data: ChartDatum[]): AnnotationTypesDownload[] {
  return data.map(({ key, count }) => {
    return {
      annotation_type: key as string,
      annotation_count: count,
    };
  });
}

function formatClassStats(data: ClassStatsDatum[]): ClassStatsDownload[] {
  return data.map(({ annotationCount, annotationShare, imageCount, imageShare, name }) => {
    return {
      name,
      object_count: annotationCount,
      object_share: annotationShare,
      image_count: imageCount,
      image_share: imageShare,
    };
  });
}

export function formatDownloadData(
  data: any[],
  chartSpec: ChartSpec | 'classification',
): Record<string, any>[] {
  if (chartSpec === 'annotationsPerImage') return formatAnnotationStats(data);
  if (chartSpec === 'annotationTypes') return formatAnnotationTypes(data);
  if (chartSpec === 'objects') return formatClassStats(data);
  if (chartSpec === 'classification') return formatClassification(data) as Record<string, any>[];
  return data;
}

export function formatKeyDownload(data: MetadataKeyDatum[]) {
  return orderBy(
    data.map(({ valueCount, ...datum }) => {
      return {
        ...datum,
        valueCount: valueCount > METADATA_CARDINALITY_THRESHOLD ? 'Infinity (> 100)' : valueCount,
      };
    }),
    ['imageCount', 'type', 'valueCount'],
    ['desc', 'desc', 'desc'],
  );
}

export function formatClassification(data: ClassificationValueItem[]) {
  return orderBy(data, ['name', 'count'], ['asc', 'desc']);
}
