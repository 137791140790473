export function addCredentiallessAttributeToIframe(
  iframeContainerDivId: string,
  newIframeId: string,
) {
  const container = document.getElementById(iframeContainerDivId);
  const prevIframe = container?.querySelector('iframe');
  if (!prevIframe) return;
  const currentSrc = prevIframe.src;
  prevIframe.remove();
  const newIframe = document.createElement('iframe');
  newIframe.id = newIframeId;
  newIframe.setAttribute('credentialless', 'true');
  newIframe.setAttribute('src', currentSrc);
  container?.appendChild(newIframe);
}
