import { TFunction } from 'react-i18next';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useAuthInfo } from '../contexts/AuthContext';
import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import AuthService from '../services/AuthService';
import { useAuthService } from '../services/NewAuthService';
import { FIVE_MINUTES, ONE_MINUTE } from '.';

export type GetApiKeyResponse = {
  data: {
    key: string;
    createdAt: string;
    expiredAt: string | null;
    isExpired: boolean;
  };
};

export const useApiKeyQuery = () => {
  const { accountName } = useAuthInfo();
  const { getApiKey } = useAuthService();
  const fetcher = async () => (await getApiKey()).data;

  return useQuery([accountName, 'apiKey'], fetcher, {
    staleTime: ONE_MINUTE,
    cacheTime: FIVE_MINUTES,
  });
};

export const useApiKeyRequestMutation = () => {
  const { accountName } = useAuthInfo();
  const { requestApiKey } = useAuthService();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const fetcher = async () => (await requestApiKey()).data;

  return useMutation([accountName, 'apiKey'], fetcher, {
    onSuccess(data) {
      queryClient.setQueryData(['apiKey'], data);
    },
    onError(error: Error) {
      enqueueSnackbar(`${error.message ?? error}`);
    },
  });
};

export const useApiKeyDeleteMutation = (t: TFunction) => {
  const { accountName } = useAuthInfo();
  const params = useApiDefaultParams();
  const queryClient = useQueryClient();
  const fetcher = async () =>
    await AuthService.deleteApiKey({
      ...params,
    });

  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.removeQueries([accountName, 'apiKey']);
    },
  });
};
