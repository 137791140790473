import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { Box as NFBox, Card, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { sumBy } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import AnalyticsTracker from '../../../../analyticsTracker';
import { UserProfileObject } from '../../../../utils/UserUtils';
import { CopyToClipboardButton } from '../../../elements/CopyToClipboardButton';
import { CHART } from '../config/plotConfig';
import { FileFormat } from '../dataTypes/analyticsDataTypes';
import DownloadDropDown from '../tools/DownloadDropDown';
import { reviewerDefaultConfig as Config } from './config';
import { prepForDownload } from './labelerTable/helper';
import { getBottomHeader, getProjectConfig, reviewerColumnIdToName } from './reviewerTable/helper';
import ReviewerTable from './reviewerTable/ReviewerTable';
import { BottomHeader, JsonObj, LabelerDatum, ReportVersions } from './types';
import { ReviewerTableTab } from './union';

interface Props {
  rows: JsonObj[];
  reviewedLabelCount: number;
  syncState: string;
  apiVersion: ReportVersions;
  handleReportDownload: (
    outputType: FileFormat,
    rows: Record<string, string | number>[],
    headerDisplayName: Record<string, string>,
    name: string,
    labelCount: number,
    reportType: 'reviewer' | 'labeler',
    bottomHeader?: BottomHeader[],
  ) => void;
  emptyMessage: string;
  width: number;
  userProfiles: UserProfileObject;
}

/**
 * Note: Analytics Tab is ONLY exposed to owner / managers
 */
const ReviewerContainer: React.FC<Props> = props => {
  const {
    rows,
    reviewedLabelCount,
    syncState,
    apiVersion,
    handleReportDownload,
    emptyMessage,
    width,
    userProfiles,
  } = props;
  const tableRef = useRef<HTMLTableElement | null>(null);
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const {
    project: { name, labelInterface },
  } = projectInfo;
  const projectConfig = getProjectConfig(labelInterface);
  const chartObj = CHART.reviewerSummary;

  /** Segment Tracking */
  const { t } = useTranslation();

  /** Table Headers */
  const reviewerBottomHeader = useMemo(
    () => getBottomHeader(apiVersion, projectConfig),
    [apiVersion, projectConfig],
  );

  const [roles, setRoles] = useState<string[]>(Config.roles);
  const [currentTab, setCurrentTab] = useState<string>(Config.tab);

  // TODO: update type
  const [columns, setColumns] = useState<BottomHeader[]>([]);

  const filteredColumns = useMemo(() => {
    let result = reviewerBottomHeader as BottomHeader[];
    if (currentTab === 'tasks') {
      result = result.filter(column => column?.group === 'reviewTasks' || column?.group === 'info');
    }
    if (currentTab === 'reviewResult') {
      result = result.filter(column => column?.group !== 'reviewTasks');
    }
    return result;
  }, [currentTab, reviewerBottomHeader]);

  const filterRows = (rows: JsonObj[]): LabelerDatum[] => {
    return rows.filter(user => roles.includes(user.role) || !user.role) as LabelerDatum[];
  };

  useEffect(() => {
    setColumns(filteredColumns);
  }, [currentTab]);

  const handleChangeRoles = (newValue: string[]) => {
    setRoles(newValue);
  };

  /** Support: csv and excel */
  const handleDownload = (outputType: FileFormat) => {
    const columnIdToName = reviewerColumnIdToName({ t, projectConfig });
    handleReportDownload(
      outputType,
      filterRows(rows),
      columnIdToName,
      name,
      reviewedLabelCount,
      'reviewer',
      reviewerBottomHeader as BottomHeader[],
    );
    AnalyticsTracker.userReportDownloaded({
      targetRole: 'reviewer',
      labelCount: reviewedLabelCount,
      accountId: authInfo.accountName ?? '',
    });
  };

  const handleCopyClipboard = () => {
    const filteredRows = prepForDownload(filterRows(rows));
    const reportLabelCount = sumBy(rows, 'labelCounts');
    const columnIdToName = reviewerColumnIdToName({ projectConfig, t });
    handleReportDownload(
      'copy' as FileFormat,
      filteredRows,
      columnIdToName,
      name,
      reportLabelCount,
      'reviewer',
      columns as BottomHeader[],
    );
  };

  const handleChangeTab = (newTab: ReviewerTableTab) => {
    setCurrentTab(newTab);
  };

  const customizeColumns = (columns: BottomHeader[]) => {
    return columns.filter(column => column?.hidden !== true);
  };

  return (
    <Card mt={3} width={width}>
      <Box display="flex" p={2} pb={2} pt={2} alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="start">
          <Typography variant="headline5" themedColor="textDefault">
            <strong>{t(chartObj.title)}</strong>
          </Typography>
        </Box>
        <NFBox
          display="flex"
          maxWidth="800px"
          mr="5px"
          alignItems="center"
          justifyContent="space-between"
          gap={15}
        >
          <CopyToClipboardButton disabled={!rows.length} onClick={handleCopyClipboard} text="" />
          <DownloadDropDown
            ml={0}
            iconButtonEl={
              <IconButton
                size="s"
                icon="download"
                color="primary"
                variant="strong-fill"
                disabled={rows.length === 0}
              />
            }
            handleDownload={handleDownload}
            options={['xlsx', 'csv']}
            isToggleDisabled={rows.length === 0}
          />
        </NFBox>
      </Box>
      <NFBox pb={2} pl={2} pr={2} pt={0} themedBackgroundColor={['grey', 50]} position="relative">
        {reviewerBottomHeader && (
          <ReviewerTable
            tableRef={tableRef}
            bottomHeader={customizeColumns(columns)}
            rows={filterRows(rows)}
            syncState={syncState}
            emptyMessage={emptyMessage}
            userProfiles={userProfiles}
            handleChangeRoles={handleChangeRoles}
            handleChangeTab={handleChangeTab}
            currentTab={currentTab}
          />
        )}
      </NFBox>
    </Card>
  );
};

export default ReviewerContainer;
