import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as ShyCredit from '@superb-ai/shy-credit';
import { cloneDeep, map } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
  },
  header: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#303030',
  },
  section: {
    width: '650px',
    height: '510px',
    display: 'flex',
    padding: '0px 30px 30px 30px',
    justifyContent: 'space-between',
  },
  sectionLeft: {
    width: '380px',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  formControl: {
    width: '280px',
    height: '30px',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '7px',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    border: 'solid 1px #ff625a',
    padding: '0px 10px 0px 3px',
    textAlign: 'left',
    '& .MuiInputBase-inputSelect': {
      color: '#ff625a',
      fontSize: '13px',
      fontWeight: 500,
    },
    '& .MuiSelect-icon': {
      top: 'auto',
      color: '#ff625a',
    },
    '& .MuiInput-underline': {
      '&::before': {
        content: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
  },
  select: {
    '& .MuiSelect-root': {
      padding: '7px 30px 7px 10px',
    },
  },
  customMenuItem: {
    color: '#c0c0c0',
    '&:hover': {
      background: 'none',
    },
  },
  imgBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  img: {
    maxWidth: '380px',
    maxHeight: '430px',
  },
  helperText: {
    fontSize: '10px',
    color: '#635c5c',
  },
  sectionRight: {
    width: '220px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  nameListBox: {
    width: '180px',
    height: '390px',
    borderRadius: '4px',
    padding: '20px',
    overflow: overflowOverlayOrAuto(),
  },
  nameItemBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 5px',
    borderBottom: 'solid 1px #c0c0c0',
    height: '24px',
  },
  nameIndexText: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#635c5c',
    width: '25px',
  },
  nameText: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#c0c0c0',
    maxWidth: '140px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const ObjectClassKeypointDialog = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number>(0);
  const [curKeypointInfo, setCurKeypointInfo] = useState<any>({});

  const objectClass = newProjectInfo.objectClasses[newProjectInfo.selectedObjectClassIndex];

  const getKeypointList = () => {
    return ShyCredit.Models.Image.KeypointDef.listTemplates();
  };
  const [keypointList] = useState(getKeypointList());

  useEffect(() => {
    if (!newProjectInfo.isKeypointDialogOpen || !objectClass) return;
    setCurKeypointInfo(cloneDeep(objectClass.keypointInfo));

    const idx = keypointList.findIndex(
      (temp: any) => temp.name === objectClass?.keypointInfo?.template?.name,
    );

    setSelectedTemplateIndex(Number(idx));
    // eslint-disable-next-line
  }, [newProjectInfo.isKeypointDialogOpen]);

  const handleClickBack = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setCurKeypointInfo({});
    newProjectInfo.setIsKeypointDialogOpen(false);
  };

  const handleChange = (e: any) => {
    e.stopPropagation();

    const { value } = e.target;
    const digitizeValue = Number(value);
    if (selectedTemplateIndex === digitizeValue || keypointList.length === digitizeValue) return;

    const nextCurKeypointInfo = cloneDeep(curKeypointInfo);
    nextCurKeypointInfo.template = keypointList[digitizeValue];

    setCurKeypointInfo(nextCurKeypointInfo);
    setSelectedTemplateIndex(digitizeValue);
  };

  const handleClickOk = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    nextObjectClasses[newProjectInfo.selectedObjectClassIndex].keypointInfo = curKeypointInfo;

    newProjectInfo.setObjectClasses(nextObjectClasses);
    newProjectInfo.setIsKeypointDialogOpen(false);
  };

  const checkNameRule = async (newValue: string) => {
    return parentHelper.checkNameRule({
      t,
      type: 'Object class name',
      prevValue: objectClass.name,
      nextValue: newValue,
      sameHierarchyNameList: [],
      index: -1,
      enqueueSnackbar,
    });
  };

  const handleClickEditConfirmKeypointName = (index: number) => (inputValue: string) => {
    const nextCurKeypointInfo: any = cloneDeep(curKeypointInfo);
    const keypointDef =
      nextCurKeypointInfo.template.keypointDef || nextCurKeypointInfo.template.keypointDetectioon;
    keypointDef.points[index].name = inputValue;
    setCurKeypointInfo(nextCurKeypointInfo);
  };

  const getSelect = () => {
    return (
      <MUI.FormControl className={classes.formControl}>
        <MUI.Select
          variant="standard"
          className={classes.select}
          displayEmpty
          value={selectedTemplateIndex}
          onChange={handleChange}
        >
          {map(keypointList, (template: any, index) => (
            <MUI.MenuItem key={template.name} value={index}>
              {helper.parseKeypointTemplateName(template)}
            </MUI.MenuItem>
          ))}
          <MUI.MenuItem className={classes.customMenuItem} value={keypointList.length}>
            Create Custom Template (Contact us)
          </MUI.MenuItem>
        </MUI.Select>
      </MUI.FormControl>
    );
  };

  const getPointNameList = () => {
    if (!curKeypointInfo.template) return null;

    const keypointDef =
      curKeypointInfo.template.keypointDef || curKeypointInfo.template.keypointDetection;

    return (
      <MUI.Box className={classes.nameListBox} boxShadow={1}>
        {map(keypointDef.points, (point: any, index: number) => (
          <MUI.Box className={classes.nameItemBox} key={point.name}>
            <MUI.Box className={classes.nameIndexText}>{index + 1}</MUI.Box>
            <EditableText
              text={point.name}
              maxWidth="150px"
              fontStyles={{
                fontSize: '12px',
                color: '#c0c0c0',
              }}
              checkRule={checkNameRule}
              editText={handleClickEditConfirmKeypointName(index)}
            />
          </MUI.Box>
        ))}
      </MUI.Box>
    );
  };

  return (
    <MUI.Dialog
      maxWidth={false}
      open={newProjectInfo.isKeypointDialogOpen}
      onClose={handleClickBack}
    >
      <MUI.Box className={classes.box}>
        <MUI.Box className={classes.header}>
          <MUI.Typography className={classes.headerText}>
            {t('projects.createProject.keypoint.keypointOption')}
          </MUI.Typography>
        </MUI.Box>
        <MUI.Box className={classes.section}>
          <MUI.Box className={classes.sectionLeft}>
            {getSelect()}
            <MUI.Box className={classes.imgBox}>
              <img className={classes.img} alt="" src={keypointList[selectedTemplateIndex].img} />
              <MUI.Typography className={classes.helperText}>
                * {t('projects.createProject.keypointInformation')}
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
          <MUI.Box className={classes.sectionRight}>
            {getPointNameList()}
            <MUI.Box>
              <MUI.Button
                style={{ width: '77px' }}
                variant="contained"
                color="primary"
                onClick={handleClickOk}
              >
                {t('button.ok')}
              </MUI.Button>
            </MUI.Box>
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </MUI.Dialog>
  );
};

export default ObjectClassKeypointDialog;
