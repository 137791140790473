import { isEmpty } from 'lodash';

import { ColorBy } from '../../../../contexts/ColorModeContext';
import { ClusterWithDisplayInfo } from '../views/embedding/queries/embeddingQueries';
import { hexToRgba } from '../views/embedding/scatterView/utils/color';
import { IdToInfoMap } from './SearchableMultiSelectFilter';
import { AnnotationFilterSchema, ImageFilterSchema } from './types';

export function getHighlightedText(text: string, highlight: string) {
  // Split text on highlight term, include term itself into parts, ignore case
  const reg = new RegExp(`(${highlight})`, 'gi');
  const parts = (text || '').split(reg);

  return (
    <span style={{ lineBreak: 'anywhere' }}>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <b key={`${part}-${index}`}>{part}</b>
        ) : (
          part
        ),
      )}
    </span>
  );
}

export function getTextContains(text: string, searchKey: string) {
  return text.toLowerCase().includes(searchKey.toLowerCase());
}

export function searchResults<T>(options: T[], searchKey: string) {
  return options.filter(option => getTextContains(String(option), searchKey));
}

export function searchResultsWithKey<T>(options: T[], optionKey: keyof T, searchKey: string) {
  return options.filter(option => getTextContains(String(option[optionKey]), searchKey));
}

export function getCategoryState(
  displayedItems: string[] | undefined,
  selectedSuperClusters: string[] | undefined,
) {
  if (!displayedItems) return false;
  const allSelected = displayedItems.length === selectedSuperClusters?.length;
  const someSelected = selectedSuperClusters && selectedSuperClusters?.length > 0;
  return allSelected ? true : someSelected ? 'mixed' : false;
}

export function mapClusterIdToInfo(
  clusters: ClusterWithDisplayInfo[],
  colorBy: ColorBy,
  prefix: string,
  noneText: string,
) {
  const clusterDisplayName = (prefix: string, index: number): string => `${prefix} ${index}`;
  return clusters.reduce((acc, cluster, i) => {
    const id = cluster.id;
    acc[id] = {
      ...cluster,
      name: id === null ? noneText : clusterDisplayName(prefix, i + 1),
      color: colorBy === 'cluster' ? hexToRgba(cluster.color, 1) : undefined,
      colorWithOpacity: colorBy === 'cluster' ? hexToRgba(cluster.color, 0.4) : undefined,
    };
    return acc;
  }, {} as IdToInfoMap);
}

export function getIsObjectClassOrClusterDisabled(
  appliedFilters: AnnotationFilterSchema,
  annotationClass: string,
) {
  const isClassApplied =
    !isEmpty(appliedFilters.annotation_class_in) ||
    !isEmpty(appliedFilters.annotation_class_cluster_in);
  const isCurrentClassApplied =
    appliedFilters.annotation_class_cluster_in
      ?.map(c => c.annotation_class)
      .includes(annotationClass) || appliedFilters.annotation_class_in?.includes(annotationClass);

  const isCollapseDisabled = isClassApplied && !isCurrentClassApplied;

  return isCollapseDisabled;
}

export function getIsImageClusterDisabled(appliedFilters: ImageFilterSchema, clusterId: string) {
  const isItemApplied = !isEmpty(appliedFilters.cluster_id_in);
  const isCurrentItemApplied = appliedFilters?.cluster_id_in?.includes(clusterId);
  const isCollapseDisabled = isItemApplied && !isCurrentItemApplied;
  return isCollapseDisabled;
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex value
  const bigint = parseInt(hex, 16);

  // Extract the RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGBA components as an object
  return { r, g, b };
}
