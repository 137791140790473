import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloudUp } from '@superb-ai/icons';
import { Button, Dialog, Icon, Tooltip, useDialogState } from '@superb-ai/ui';

import { usePublicDatasetContext } from '../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../elements/SampleDatasetTooltip';
import UploadModal from './modal/UploadModal';

export default function MenuUploadButton() {
  const { t } = useTranslation();
  const dialog = useDialogState();
  const { showPublicDatasets } = usePublicDatasetContext();

  function TooltipMessage() {
    if (showPublicDatasets) return <SampleDatasetTooltip feature="cloudUpload" />;
    return undefined;
  }

  return (
    <>
      <Dialog.Disclosure {...dialog}>
        {(disclosureProps: any) => (
          <Tooltip content={TooltipMessage()} placement="bottom" hideOnEmptyContent>
            <span style={{ flex: 1, display: 'flex' }}>
              <Button
                disabled={showPublicDatasets}
                style={{ flex: 1 }}
                color="primary"
                {...disclosureProps}
              >
                <Icon icon={CloudUp} />
                {t('curate.datasets.cloudUpload.button')}
              </Button>
            </span>
          </Tooltip>
        )}
      </Dialog.Disclosure>
      <UploadModal dialog={dialog} hasSliceStep />
    </>
  );
}
