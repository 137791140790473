import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';
import TierUnion from '../../../../union/TierUnion';
import ProjectUtils from '../../../../utils/ProjectUtils';
import Layout from './Layout';
import { ProjectMislabelDetectionMenuItem } from './mislabelDetection/MenuItem';

export const ProjectAdvancedAIFeaturesMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectAdvancedAI,
  path: 'advanced-ai-features',
  title: ({ t }) => t('advancedAIFeatures.title'),
  component: Layout,
  icon: 'advancedAI',
  children: [ProjectMislabelDetectionMenuItem],
  isVisible({ authInfo, featureFlags, project }) {
    const dataType = project && ProjectUtils.getProjectDataType(project.workapp);
    // const annotationTypeValid = project.labelInterface?.objectDetection?.annotationTypes.every(
    //   elem => ['bbox', 'polygon'].includes(elem),
    // );
    const projectValid = dataType === 'image';
    // && annotationTypeValid;
    const authValid = isOwnerOrAdminOrSystemAdmin(authInfo) || authInfo?.isGuest || false;
    const featureFlagValid =
      !!featureFlags?.mislabelDetectionBeta?.enabled || !!featureFlags?.embeddingStoreBeta?.enabled;
    return authValid && featureFlagValid && projectValid;
  },
  isEnabled({ authInfo }) {
    return !!authInfo && !TierUnion.isFree(authInfo.tier);
  },
  disabledMessage() {
    return 'This feature is not supported in your plan.';
  },
});
