import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

export const CURATE_WHAT_TO_LABEL_EMBEDDING_TYPE_CONFIG = {
  IMAGE: {
    value: 'IMAGE',
  },
  OBJECT: {
    value: 'OBJECT',
  },
};

export const embeddingTypes_curate_label = (['IMAGE', 'OBJECT'] as const).map(embeddingType => ({
  Label: () => {
    const { t } = useTranslation();
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Typography>
            {t(`curate.autoCurate.curateWhatToLabelEmbeddingType.${embeddingType}.title`)}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography>
            {t(`curate.autoCurate.curateWhatToLabelEmbeddingType.${embeddingType}.description`)}
          </Typography>
        </Box>
      </Box>
    );
  },
  value: CURATE_WHAT_TO_LABEL_EMBEDDING_TYPE_CONFIG[embeddingType].value,
}));

export const FIND_MISLABELS_EMBEDDING_TYPE_CONFIG = {
  IMAGE: {
    value: 'IMAGE',
  },
  OBJECT: {
    value: 'OBJECT',
  },
};

export const embeddingTypes_find_mislabels = (['IMAGE', 'OBJECT'] as const).map(embeddingType => ({
  Label: () => {
    const { t } = useTranslation();
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Typography>
            {t(`curate.autoCurate.findMislabelsEmbeddingType.${embeddingType}.title`)}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography>
            {t(`curate.autoCurate.findMislabelsEmbeddingType.${embeddingType}.description`)}
          </Typography>
        </Box>
      </Box>
    );
  },
  value: FIND_MISLABELS_EMBEDDING_TYPE_CONFIG[embeddingType].value,
}));

export const SAMPLING_METHOD_CONFIG = {
  CURATE_WHAT_TO_LABEL: {
    value: 'CURATE_WHAT_TO_LABEL',
  },
  SPLIT_TRAIN_VAL: {
    value: 'SPLIT_TRAIN_VAL',
  },
  FIND_EDGE_CASES: {
    value: 'FIND_EDGE_CASES',
  },
  FIND_MISLABELS: {
    value: 'FIND_MISLABELS',
  },
};

export const curateMethodObject = (
  ['CURATE_WHAT_TO_LABEL', 'SPLIT_TRAIN_VAL', 'FIND_EDGE_CASES', 'FIND_MISLABELS'] as const
).map(sampleMethod => ({
  Label: () => {
    const { t } = useTranslation();
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography>{t(`curate.autoCurate.samplingMethod.${sampleMethod}.title`)}</Typography>
          </Box>
        </Box>
        <Box mt={1} display="flex" flexDirection="column">
          <Typography>
            {t(`curate.autoCurate.samplingMethod.${sampleMethod}.description`)}
          </Typography>
        </Box>
      </Box>
    );
  },
  value: SAMPLING_METHOD_CONFIG[sampleMethod].value,
}));
