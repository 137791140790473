import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AutoLabel,
  CheckCircle,
  ClearOutline,
  ErrorOutline,
  LoadingSpinnerSmall,
} from '@superb-ai/icons';
import {
  Box,
  ButtonGroup,
  Chip,
  IconButton,
  Input,
  StatusChip,
  Tooltip,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import AnalyticsTracker from '../../../../analyticsTracker';
import { getNamingRuleErrorMessage } from '../../../../configs/NamingRulesConfig';
import ExportStatus from '../../../../consts/ExportStatus';
import {
  EXPORT_NAME_CHANGE_ERROR,
  EXPORT_NAME_CHANGE_SUCCESS,
  EXPORT_NAME_EMPTY_ERROR,
  EXPORT_SUCCESS,
  LABELS_DO_NOT_EXIST,
  NAME_DUPLICATE,
  UNKNOWN_ERROR,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useCalAvailabilityInfo } from '../../../../contexts/CalAvailabilityContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';
import CustomAutoLabelService from '../../../../services/CustomAutoLabelService';
import ExportService from '../../../../services/ExportService';
import { ExportStateType, HistoryType } from '../../../../types/exportTypes';
import { formatDistanceShort, formatTime, parseDate } from '../../../../utils/date';
import FileUtils from '../../../../utils/FileUtils';
import { ExportDownloadDialog } from './ExportDownloadDialog';
import { ExportFormat } from './formats';
import HistoryInfoDetail from './HistoryInfoDetail';

type Props = { index: number; history: HistoryType; refetch: () => Promise<any> };

function getExportStateColorLegacy(exportState: ExportStateType) {
  switch (exportState) {
    case ExportStatus.CANCELED:
      return 'canceled';
    case ExportStatus.FAILED:
      return 'failed';
    case ExportStatus.IN_QUEUE:
    case ExportStatus.WAITING:
      return 'waiting';
    case ExportStatus.READY_FOR_DOWNLOAD:
      return 'success';
    case ExportStatus.PROCESSING:
      return 'in_progress';
    default:
      return 'in_progress';
  }
}

function getExportStateColor(exportState: ExportStateType) {
  switch (exportState) {
    case ExportStatus.CANCELED:
      return 'gray';
    case ExportStatus.FAILED:
      return 'red';
    case ExportStatus.READY_FOR_DOWNLOAD:
      return 'green';
    default:
      return 'yellow';
  }
}

function getExportFormatColor(format: string) {
  switch (format) {
    case 'yolo':
      return 'skyblue';
    case 'coco':
      return 'violet';
    default:
      return 'gray';
  }
}

function getExportStateIcon(exportState: ExportStateType) {
  switch (exportState) {
    case ExportStatus.CANCELED:
      return ClearOutline;
    case ExportStatus.FAILED:
      return ErrorOutline;
    case ExportStatus.READY_FOR_DOWNLOAD:
      return CheckCircle;
    default:
      return LoadingSpinnerSmall;
  }
}

const HistoryInfo: React.FC<Props> = props => {
  const { t } = useTranslation();
  const reactRouterDomHistory = useHistory();
  const [isRequesting, setIsRequesting] = useState(false);
  const { openModal, closeModal } = useAlertModal();
  const { enqueueSnackbar } = useSnackbar();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();

  const calInfo = useCalAvailabilityInfo();
  const {
    canUseCustomAutoLabel,
    requiredLabelCountForCreatingCAL,
    getIsOverLabelCountForCreatingCAL,
  } = calInfo;

  const historyNameRef = useRef<HTMLInputElement>(null);
  const { history, refetch } = props;
  const [historyName, setHistoryName] = useState(history.name);
  const [tempHistoryName, setTempHistoryName] = useState<string>('');
  const [historyNameChangeButtonOver, isHistoryNameChangeButtonOver] = useState(false);
  const [historyNameChangeActive, isHistoryNameChangeActive] = useState(false);
  const [deleteButtonOver, isDeleteButtonOver] = useState(false);
  const [newState, setNewState] = useState(null);
  const [historyInfo, setHistoryInfo] = useState<HistoryType>();
  const currentHistory = historyInfo || history;
  const { id, seqNo, labelCount, downloadLabelCount, state, fileSize, createdAt, createdBy } =
    currentHistory;
  const exportState = newState || state;
  const commandContext = useLabelCommandContext();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const customAutoLabelNameMaxLength = 50;
  const defaultCustomAutoLabelNamePrefix = 'Untitled Custom Auto-Label AI';
  const defaultCustomAutoLabelNameExtraLen =
    customAutoLabelNameMaxLength - defaultCustomAutoLabelNamePrefix.length - 3;
  const defaultCustomAutoLabelName = `${defaultCustomAutoLabelNamePrefix} (${projectInfo.project.name.substring(
    0,
    defaultCustomAutoLabelNameExtraLen,
  )})`;
  const isOverLabelCountForCreatingCAL = getIsOverLabelCountForCreatingCAL(labelCount);
  const isOverDownloadLabelCountForCreatingCAL =
    getIsOverLabelCountForCreatingCAL(downloadLabelCount);

  const customAutoLabelDisabled =
    !isOverDownloadLabelCountForCreatingCAL || exportState !== ExportStatus.READY_FOR_DOWNLOAD;

  const isProcessing =
    exportState === ExportStatus.IN_QUEUE ||
    exportState === ExportStatus.WAITING ||
    exportState === ExportStatus.PROCESSING;

  const hasTransform = !!currentHistory.meta?.postExecute?.transform;

  const isWaitingForTransform =
    currentHistory.meta?.transformCommand &&
    ['PROCESSING', 'WAITING', 'IN_QUEUE'].includes(currentHistory.meta.transformCommand.status);

  const displayedExportState = isWaitingForTransform ? 'PROCESSING' : exportState;

  const exportStateLabel = isWaitingForTransform
    ? t('export.converting')
    : t(
        `export.status.${
          exportState === ExportStatus.IN_QUEUE ? ExportStatus.WAITING : exportState
        }`,
      );

  // TODO @paul: refactor using react-query and refetchInterval (c.f. CAL history)
  useEffect(() => {
    const { state } = currentHistory;

    // Keep refreshing as long as export is processing or transforming
    if (
      !isWaitingForTransform &&
      (state === ExportStatus.READY_FOR_DOWNLOAD ||
        state === ExportStatus.CANCELED ||
        state === ExportStatus.FAILED)
    )
      return;

    setTimeout(async () => {
      const newHistory = await ExportService.getExportHistory({
        projectId: routeInfo.urlMatchInfo.projectId,
        exportId: id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setHistoryInfo(newHistory);
    }, 5000);

    // eslint-disable-next-line
  }, [historyInfo]);

  const handleClickCreateCustomAutoLabel = () => {
    const createCustomAutoLabel = async (promptValue: string) => {
      const name = promptValue.substring(0, customAutoLabelNameMaxLength);
      if (isRequesting) return;
      setIsRequesting(true);

      try {
        await CustomAutoLabelService.requestCreateCustomAutoLabel({
          projectId: routeInfo.urlMatchInfo.projectId,
          name,
          exportHistoryId: id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });

        AnalyticsTracker.createCustomAutoLabelAiInitiated({
          referrer: 'export',
          exportId: id,
          labelCount: downloadLabelCount,
          accountId: authInfo.accountName ?? '',
        });

        const handleClickView = () => {
          reactRouterDomHistory.push(
            `/${routeInfo.urlMatchInfo.accountName}/project/${routeInfo.urlMatchInfo.projectId}/export/custom-auto-label`,
          );
        };
        enqueueSnackbar(t('autoLabel.cal.createSuccess'), {
          persist: false,
          variant: 'success',
          action: (
            <Button variant="text" size="s" color="white" onClick={handleClickView}>
              {t('button.view')}
            </Button>
          ),
        });

        closeModal();
      } catch (err: any) {
        if (err.message === 'Duplicated') {
          enqueueSnackbar(NAME_DUPLICATE({ t, name }), { variant: 'warning' });
        }
      }

      setIsRequesting(false);
    };

    openModal({
      title: t('autoLabel.cal.button.createCustomAutoLabel'),
      content: t('export.history.dialog.trainAutoLabelUsingTheseLabels'),
      mainButton: {
        text: t('button.create'),
        // @ts-ignore: type overlap
        onClick: (promptValue?: string) => createCustomAutoLabel(promptValue || ''),
      },
      subButton: {
        text: t('button.cancel'),
        onClick: () => closeModal(),
      },
      prompt: {
        selected: true,
        defaultValue: defaultCustomAutoLabelName,
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
      canConfirmWithEnter: true,
    });
  };

  const handleClickReload = () => {
    refetch();
  };

  const handleClickDelete = async () => {
    openModal({
      title: t('export.history.dialog.deleteExport'),
      variant: 'warning',
      content: (
        <Typography variant="m-regular">
          <p>{t('export.history.dialog.deleteExportText')}</p>
          <p>{t('export.history.dialog.deleteExportCalText')}</p>
        </Typography>
      ),
      mainButton: {
        text: t('button.delete'),
        onClick: () => {
          handleClickCancel();
          closeModal();
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: () => {
          closeModal();
        },
      },
    });
  };

  const handleClickCancel = async () => {
    try {
      const newHistory = await ExportService.cancelExport({
        projectId: routeInfo.urlMatchInfo.projectId,
        exportId: id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      if (
        exportState === ExportStatus.READY_FOR_DOWNLOAD ||
        exportState === ExportStatus.CANCELED ||
        exportState === ExportStatus.FAILED
      ) {
        refetch();
      } else {
        setNewState(newHistory.state);
      }
    } catch (err: any) {
      if (!err.message) return;
      if (err.message === 'Duplicated') {
        openModal({
          title: t('export.history.dialog.cannotCancelDuplicatedTitle'),
          variant: 'warning',
          content: t('export.history.dialog.cannotCancelDuplicated'),
          mainButton: {
            text: t('button.ok'),
            onClick: handleClickReload,
          },
        });
      }
    }
  };

  const handleClickRetry = async () => {
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'LABELS_EXPORT',
        actionInfo: {
          labelCount: history.labelCount,
          filter: history.filter ?? undefined,
          meta: history.meta?.postExecute ? { postExecute: history.meta.postExecute } : undefined,
        },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);

      AnalyticsTracker.labelExportRequested({
        accountId: authInfo.accountName ?? '',
        filterBy: Object.keys(history.filterQ),
        labelCount: history.labelCount,
        format: history.meta?.postExecute?.transform?.type ?? 'default',
        createCAL: !!history.meta?.postExecute?.customAutoLabel ?? false,
      });

      refetch();
      enqueueSnackbar(EXPORT_SUCCESS({ t, labelsCount: history.labelCount }), {
        variant: 'success',
      });
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
      }
      enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
    }
  };

  const handleHistoryNameChange = async () => {
    if (historyName === null || historyName === '') {
      enqueueSnackbar(EXPORT_NAME_EMPTY_ERROR({ t }), { variant: 'error' });
      isHistoryNameChangeActive(false);
      setHistoryName(tempHistoryName);
      return;
    }

    const errorMessages = getNamingRuleErrorMessage({
      str: historyName,
      target: 'export',
    });

    if (errorMessages.length !== 0) {
      enqueueSnackbar(errorMessages[0], { variant: 'warning' });
      isHistoryNameChangeActive(false);
      setHistoryName(tempHistoryName);
      return;
    }

    try {
      const newHistory = await ExportService.changeExportName({
        projectId: routeInfo.urlMatchInfo.projectId,
        exportId: id,
        name: historyName,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      enqueueSnackbar(EXPORT_NAME_CHANGE_SUCCESS({ t, historyName: newHistory.name }), {
        variant: 'success',
      });
      isHistoryNameChangeActive(false);
      setHistoryName(newHistory.name);
    } catch (err: any) {
      setHistoryName(tempHistoryName);
      enqueueSnackbar(EXPORT_NAME_CHANGE_ERROR({ t }), { variant: 'error' });
    }
  };

  const turnOnHistoryNameChangeForm = () => {
    setTempHistoryName(historyName);
    isHistoryNameChangeActive(!historyNameChangeActive);
    isHistoryNameChangeButtonOver(false);
  };

  const remainingTime = (() => {
    const { processingProgress, processingStartAt, processingUpdatedAt } = currentHistory;
    if (!processingProgress || !processingStartAt || processingProgress < 1e-4) return '';
    const startDate = parseDate(processingStartAt);
    const updatedDate = parseDate(processingUpdatedAt);
    const timeDiff = +updatedDate - +startDate;
    const remainingMilliSecs = timeDiff / processingProgress - timeDiff;
    const endDate = new Date(+new Date() + remainingMilliSecs);
    return `${formatDistanceShort(endDate)} left`;
  })();

  const selectedFormats: ExportFormat[] = history.meta?.postExecute?.transform?.type
    ? ['default', history.meta?.postExecute?.transform?.type as ExportFormat]
    : ['default'];
  const readyToDownloadFormats: ExportFormat[] =
    history.meta?.postExecute?.transform?.type &&
    currentHistory?.meta?.transformCommand?.status === 'FINISHED'
      ? [...selectedFormats]
      : ['default'];

  return (
    <Box mb={3}>
      {/* Header */}
      <Box shadow={2}>
        <Box
          display="flex"
          alignItems="center"
          height={64}
          px={2}
          bb
          borderColor="#ECECEC"
          gap="8px"
        >
          <Icon
            size={20}
            icon={getExportStateIcon(displayedExportState)}
            color={getExportStateColor(displayedExportState)}
          />
          <Box>
            {!historyNameChangeActive && (
              <Typography
                variant="l-strong"
                style={{
                  cursor: 'pointer',
                  letterSpacing: 'normal',
                  wordSpacing: 'normal',
                }}
                onDoubleClick={turnOnHistoryNameChangeForm}
              >
                {historyName ? historyName : t('export.history.untitledExport')}
              </Typography>
            )}
            {historyNameChangeActive && (
              <Input
                type="text"
                value={historyName}
                autoFocus
                placeholder={t('export.history.untitledExport')}
                variant="text"
                size="l"
                style={{
                  width: 500,
                  padding: 0,
                }}
                ref={historyNameRef}
                onChange={event => setHistoryName(event.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter' && !event.nativeEvent.isComposing)
                    handleHistoryNameChange();
                }}
                onBlur={() => handleHistoryNameChange()}
              />
            )}
          </Box>
          {!historyNameChangeActive && (
            <StatusChip color={getExportStateColorLegacy(displayedExportState)}>
              {exportStateLabel}
            </StatusChip>
          )}
          {exportState === ExportStatus.PROCESSING && (
            <Typography variant="m-regular">{remainingTime}</Typography>
          )}
          <Box ml="auto">
            {exportState === ExportStatus.READY_FOR_DOWNLOAD && (
              <IconButton
                color={
                  historyNameChangeActive || historyNameChangeButtonOver ? 'primary' : 'textDefault'
                }
                icon="pencil"
                onClick={turnOnHistoryNameChangeForm}
                round
                size="xs"
                variant="text-simple"
                onMouseOver={() => isHistoryNameChangeButtonOver(true)}
                onMouseLeave={() => isHistoryNameChangeButtonOver(false)}
                style={{ marginRight: '1em' }}
              />
            )}
            {(exportState === ExportStatus.READY_FOR_DOWNLOAD ||
              exportState === ExportStatus.FAILED ||
              exportState === ExportStatus.CANCELED) && (
              <IconButton
                color={deleteButtonOver ? 'primary' : 'textDefault'}
                icon="trash"
                onClick={handleClickDelete}
                round
                size="xs"
                variant="text-simple"
                onMouseOver={() => isDeleteButtonOver(true)}
                onMouseLeave={() => isDeleteButtonOver(false)}
              />
            )}
            {isProcessing && (
              <Button variant="text" color="primary" onClick={handleClickCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </Box>
        </Box>

        {/* Body */}
        <Box
          display="grid"
          alignItems="center"
          gridTemplateColumns="220px auto max-content"
          p={2}
          gap="16px"
          ml={3.5}
        >
          {/* Label info, created by */}
          <Box display="flex" flexDirection="column" gap="8px">
            <Box>
              <Typography as={Box} variant="m-regular">
                {t('labels.labelCount', { count: labelCount })}
                {exportState === ExportStatus.READY_FOR_DOWNLOAD &&
                  labelCount !== downloadLabelCount && (
                    <>
                      {' → '}
                      <Tooltip
                        anchorEl={
                          <span>{t('labels.labelCount', { count: downloadLabelCount })}</span>
                        }
                      >
                        {t('export.history.messages.exactNumberIsDifferent')}
                      </Tooltip>
                    </>
                  )}
                {exportState === ExportStatus.READY_FOR_DOWNLOAD &&
                  `, ${FileUtils.getConvertedFileSize(fileSize)}`}
              </Typography>
            </Box>
            <Typography variant="m-regular" color="gray-300">
              {createdBy}
            </Typography>
          </Box>

          {/* Format */}
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="m-regular">{t('export.formats')}</Typography>
            <Box display="flex" gap="4px">
              <Chip color="cloud">Superb AI</Chip>

              {hasTransform && history.meta?.postExecute?.transform?.type && (
                <Chip color={getExportFormatColor(history.meta.postExecute.transform.type)}>
                  {history.meta.postExecute.transform.type.toUpperCase()}
                </Chip>
              )}
              {/* TODO @paul: Add Format button and dialog */}
            </Box>
          </Box>

          {/* Actions */}
          <Box display="flex" flexDirection="column" alignItems="flex-end" gap="12px">
            <Typography variant="m-regular" color="gray-300">
              #{seqNo.toLocaleString('en')}, {formatTime(createdAt)}
            </Typography>
            <ButtonGroup gap={1}>
              {canUseCustomAutoLabel && (
                <>
                  {!customAutoLabelDisabled ? (
                    <Button
                      variant="strong-fill"
                      color="secondary"
                      onClick={handleClickCreateCustomAutoLabel}
                    >
                      <Box display="flex">
                        <Icon icon={AutoLabel} />
                      </Box>
                      {t('autoLabel.cal.button.createCustomAutoLabel')}
                    </Button>
                  ) : (
                    <Tooltip
                      anchorEl={
                        <span>
                          <Button variant="strong-fill" color="secondary" disabled>
                            <Box display="flex">
                              <Icon icon={AutoLabel} />
                            </Box>
                            {t('autoLabel.cal.button.createCustomAutoLabel')}
                          </Button>
                        </span>
                      }
                      placement="bottom"
                    >
                      {isProcessing && history.meta?.postExecute?.customAutoLabel
                        ? t('export.history.messages.calWillBeCreatedAfterExport')
                        : (isProcessing && !isOverLabelCountForCreatingCAL) ||
                          (!isProcessing && !isOverDownloadLabelCountForCreatingCAL)
                        ? t('export.history.messages.nLabelsRequiredToCreateCAL', {
                            labelCount: requiredLabelCountForCreatingCAL,
                          })
                        : t('export.history.messages.exportShouldBeReadyToCreateCAL')}
                    </Tooltip>
                  )}
                </>
              )}
              {exportState === ExportStatus.READY_FOR_DOWNLOAD && (
                <>
                  <ExportDownloadDialog
                    exportId={id}
                    labelCount={downloadLabelCount}
                    fileSize={fileSize}
                    selectedFormats={selectedFormats}
                    readyToDownloadFormats={readyToDownloadFormats}
                  />
                </>
              )}
              {exportState !== ExportStatus.READY_FOR_DOWNLOAD &&
                exportState !== ExportStatus.FAILED && (
                  <Button variant="strong-fill" color="primary" disabled>
                    {t('analytics.text.download')}
                  </Button>
                )}
              {exportState === ExportStatus.FAILED && (
                <Button variant="strong-fill" color="primary" onClick={handleClickRetry}>
                  {t('button.retry')}
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </Box>
        {history.filter && <HistoryInfoDetail history={history} />}
      </Box>
    </Box>
  );
};

export default HistoryInfo;
