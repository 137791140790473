import { useTranslation } from 'react-i18next';

import { Box, Select } from '@superb-ai/ui';

import { getClassOptions } from '../SinglePredictionClassFilter';

export type ClassItem = {
  label: string;
  value: string;
};
interface Props {
  classList: (string | null)[] | [];
  selectedClasses: string[];
  handleSelectClasses: (selectedClasses: string[]) => void;
}

const MultiSelectclassDropdown = (props: Props) => {
  const { classList, selectedClasses, handleSelectClasses } = props;
  const { t } = useTranslation();

  const classOptions = getClassOptions(classList, t).filter(d => d.value !== 'all');
  return (
    <Box display="flex" style={{ width: '160px' }}>
      <Select
        placeholder="Select classes"
        multiple
        color="gray"
        data={classOptions}
        variant="soft-fill"
        onChangeValue={value => handleSelectClasses(value)}
        size="s"
        value={selectedClasses ?? []}
      />{' '}
    </Box>
  );
};

export default MultiSelectclassDropdown;
