import React from 'react';
import QRCode from 'react-qr-code';

import { createPath } from 'history';
import { stringify } from 'qs';

export function TtopQrCode({
  secret,
  label,
  issuer = 'Superb AI',
}: {
  secret: string;
  label: string;
  issuer?: string;
}) {
  const value = createPath({
    pathname: `otpauth://totp/${encodeURIComponent(label)}`,
    search: stringify({ secret, issuer }),
  });
  return <QRCode value={value} size={140} />;
}
