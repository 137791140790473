import { MislabelDetection, MislabelDetectionImageInfo } from '../types/mislabelDetectionTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

const requestMislabelDetection: ApiCall<
  {
    projectId: string;
    data: { name: string; querySetId: string; referenceSetId: string };
  },
  any
> = async args => {
  const { projectId, data, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/mislabel-detections/`,
    // can add config data
    data: { ...data, config: {} },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getMislabelDetectionHistories: ApiCall<
  { projectId: string; params: Params },
  { mislabelDetections: { id: string }[] }
> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/mislabel-detections/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getMislabelDetectionHistory: ApiCall<
  { projectId: string; mislabelDetectionId: string },
  MislabelDetection
> = async args => {
  const { projectId, mislabelDetectionId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/mislabel-detections/${mislabelDetectionId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getMislabelDetectionGetThumbnail: ApiCall<
  {
    projectId: string;
    mislabelDetectionId: string;
    data: MislabelDetectionImageInfo;
  },
  { url: string }
> = async args => {
  const { projectId, mislabelDetectionId, isGuest, urlInfo, data } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/mislabel-detections/${mislabelDetectionId}/thumbnails/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteMislabelDetection: ApiCall<
  {
    projectId: string;
    mislabelDetectionId: string;
  },
  any
> = async args => {
  const { projectId, mislabelDetectionId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/mislabel-detections/${mislabelDetectionId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const requestEditMislabelDetection: ApiCall<
  {
    projectId: string;
    mislabelDetectionId: string;
    data: {
      class_id: string;
      file_size: number;
    };
  },
  {
    id: string;
    uploadUrl: string;
  }
> = async args => {
  const { projectId, mislabelDetectionId, isGuest, urlInfo, data } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/mislabel-detections/${mislabelDetectionId}/changes/`,
    hasPublicApi: false,
    data,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getMislabelDetectionEditHistory: ApiCall<
  { projectId: string; mislabelDetectionId: string; classId: string },
  { changes: { id: string; downloadUrl: string }[] }
> = async args => {
  const { projectId, mislabelDetectionId, classId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/mislabel-detections/${mislabelDetectionId}/changes/classes/${classId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  requestMislabelDetection,
  getMislabelDetectionHistories,
  getMislabelDetectionHistory,
  getMislabelDetectionGetThumbnail,
  deleteMislabelDetection,

  requestEditMislabelDetection,
  getMislabelDetectionEditHistory,
};
