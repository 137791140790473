import { Endpoint } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { EndpointsDetailMenuItem } from './detail/MenuItem';
import { Layout } from './Layout';
import { EndpointsListMenuItem } from './list/MenuItem';

export const EndpointsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelEndpointList,
  path: 'endpoints',
  title: ({ t }) => t('model.endpoints.title'),
  component: Layout,
  children: [EndpointsListMenuItem, EndpointsDetailMenuItem],
  icon: <Icon icon={Endpoint} />,
});
