import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { makeStyles } from '@mui/styles';
import { Button } from '@superb-ai/norwegian-forest';
import { Box, Checkbox, Tooltip } from '@superb-ai/ui';
import cs from 'classnames';

import { useGetProjectList } from '../../../hooks/GetProjectList';
import { ProjectData } from '../../../types/projectTypes';
import { formatDate } from '../../../utils/date';
import { formatCount } from '../../../utils/numberFormat';
import CircularProgressBox from '../CircularProgressBox';

const useStyles = makeStyles(() => ({
  projectsTable: {
    height: '100%',
    fontSize: '12px',
    '& .tableRow': {
      lineHeight: '36px',
      display: 'grid',
      gridTemplateColumns: '46px 1fr 120px 120px',
      '&.selectable': {
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
      },
      '&.isSelected, &.selectable:hover': {
        backgroundColor: '#fffbfb',
      },
      '& > *': {
        padding: '0 18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
      },
      '& > :nth-last-child(1)': {
        textAlign: 'right',
        justifyContent: 'flex-end',
      },
      '& > :nth-last-child(2)': {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      '& .checkbox-cell': {
        padding: '0 10px',
        '& + *': {
          paddingLeft: '0',
        },
      },
      '& .emptyTableInfo': {
        gridColumn: '1 / span 5',
        justifyContent: 'flex-start',
        color: '#777',
      },
    },
  },
  tableHeader: {
    boxShadow: 'inset 0 -1px 0 0px #EFEFEF',
    fontWeight: 500,
  },
  allocationToolip: {
    whiteSpace: 'normal',
    textAlign: 'left',
    lineHeight: 1.4,
  },
  totalSelectMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '36px',
  },
}));

export interface ProjectsTableProps {
  projects: ProjectData[];
  totalCount: number;
  columns: string[];
  selectable?: boolean;
  unSelectableProjects?: ProjectData[];
  isLoading: boolean;
  selectedProjects: ProjectData[];
  setSelectedProjects: Dispatch<SetStateAction<ProjectData[]>>;
  isTotalProjectsSelected?: boolean;
  setIsTotalProjectsSelected?: Dispatch<SetStateAction<boolean>>;
  searchKey: string;
  hasTotalSelect?: boolean;
}

const ColToLabel: Record<string, string> = {
  project: 'users.dialogs.projectAccess.project',
  labelCount: 'projects.table.labelCount',
  createdAt: 'projects.table.createdDate',
};

const ProjectsTable: React.FC<ProjectsTableProps> = props => {
  const { t } = useTranslation();
  const {
    selectable,
    columns,
    projects,
    unSelectableProjects,
    selectedProjects,
    setSelectedProjects,
    isTotalProjectsSelected,
    setIsTotalProjectsSelected,
    totalCount,
    isLoading,
    searchKey,
    hasTotalSelect = true,
  } = props;

  const classes = useStyles();

  const [loadedProjects, setLoadedProjects] = useState<ProjectData[]>([]);
  const [hasMorePage, setHasMorePage] = useState(true);

  const getProjectList = useGetProjectList();

  const isSelectedAll =
    isTotalProjectsSelected ||
    (!!selectedProjects.length && selectedProjects.length === loadedProjects.length);

  const handleToggleSelectAll = () => {
    if (isSelectedAll) {
      setSelectedProjects([]);
      return;
    }
    setSelectedProjects(loadedProjects);
  };

  useEffect(() => {
    setLoadedProjects(projects);
  }, [projects]);

  const Row: React.FC<{ item: ProjectData }> = ({ item }) => {
    const isSelected =
      isTotalProjectsSelected ||
      (selectedProjects && selectedProjects.findIndex(m => m.id === item.id) !== -1);
    const isDisabled =
      unSelectableProjects && unSelectableProjects.findIndex(m => m.projectId === item.id) !== -1;

    const handleToggleSelectProject = () => {
      if (isDisabled) return;
      if (isSelected) {
        if (isTotalProjectsSelected && setIsTotalProjectsSelected) {
          setIsTotalProjectsSelected(false);
        }
        setSelectedProjects(selectedProjects.filter(project => project.id !== item.id));
        return;
      }
      setSelectedProjects(selectedProjects.concat(item));
    };

    const handleCheckboxClick = (e: React.MouseEvent) => {
      if (isDisabled) return;
      e.stopPropagation();
      handleToggleSelectProject();
    };
    // prettier-ignore
    const selectableProps = selectable ? {
      role: 'option',
      tabIndex: 0,
      'aria-selected': isSelected,
      onClick: handleToggleSelectProject,
    } : {};

    return (
      <div {...selectableProps} className={cs('tableRow', { isSelected, selectable: !isDisabled })}>
        {selectable && (
          <span className="checkbox-cell">
            {isDisabled ? (
              <Tooltip
                placement="bottom"
                content={t('users.dialogs.projectAccess.userAlreadyInProject')}
              >
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Checkbox color="primary" tabIndex={-1} disabled value />
                </Box>
              </Tooltip>
            ) : (
              <Checkbox
                color="primary"
                tabIndex={-1}
                onClick={handleCheckboxClick}
                value={isSelected}
              />
            )}
          </span>
        )}
        <span>{item.name}</span>
        <span>{formatCount(item.labelCount)}</span>
        <span>{formatDate(item.createdAt)}</span>
      </div>
    );
  };

  const maxPage = Math.ceil(totalCount / 10);

  const handleLoadMore = useCallback(
    async (nextPage: number) => {
      const params = { page: nextPage, nameIcontains: searchKey };
      if (nextPage <= maxPage) {
        setHasMorePage(false);
        const projectsRes = await getProjectList(params);
        if (projectsRes) setLoadedProjects(prev => prev.concat(projectsRes.results));
      } else setHasMorePage(false);
    },
    [searchKey, maxPage],
  );

  useEffect(() => {
    if (loadedProjects.length) setHasMorePage(true);
  }, [loadedProjects]);

  const handleClearTotalSelect = () => {
    if (!setIsTotalProjectsSelected) return;
    setIsTotalProjectsSelected(false);
    setSelectedProjects([]);
  };

  const isTotalSelectAreaVisible =
    hasTotalSelect && isSelectedAll && !isTotalProjectsSelected && totalCount > 10;

  return (
    <div className={classes.projectsTable} role="listbox">
      <div className={cs(classes.tableHeader, 'tableRow')}>
        {selectable && (
          <span className="checkbox-cell">
            <Checkbox color="primary" onClick={handleToggleSelectAll} value={isSelectedAll} />
          </span>
        )}
        {columns.map(colName => (
          <span key={colName}>{ColToLabel[colName] ? t(ColToLabel[colName]) : colName}</span>
        ))}
      </div>
      {isTotalSelectAreaVisible && (
        <div className={classes.totalSelectMessage}>
          <Box>
            {t('users.dialogs.projectAccess.selectedAllInList', { count: loadedProjects.length })}
          </Box>
          <Box>
            <Button
              style={{ textTransform: 'none' }}
              variant="text"
              color="primary"
              size="s"
              onClick={() => {
                if (!setIsTotalProjectsSelected) return;
                setIsTotalProjectsSelected(true);
              }}
            >
              {t('users.dialogs.projectAccess.selectAllProjects', { count: totalCount })}
            </Button>
          </Box>
        </div>
      )}
      {isTotalProjectsSelected && (
        <div className={classes.totalSelectMessage}>
          <Box>{t('users.dialogs.projectAccess.selectedAll', { count: totalCount })}</Box>
          <Box>
            <Button
              style={{ textTransform: 'none' }}
              variant="text"
              color="primary"
              size="s"
              onClick={handleClearTotalSelect}
            >
              {t('labels.button.clearSelection')}.
            </Button>
          </Box>
        </div>
      )}
      <Box
        style={{
          height: isTotalSelectAreaVisible || isTotalProjectsSelected ? '176px' : '212px',
        }}
        overflow="overlay"
      >
        {isLoading ? (
          <CircularProgressBox boxProps={{ mt: 5, mb: 5 }} />
        ) : (
          <InfiniteScroll
            useWindow={false}
            pageStart={1}
            hasMore={hasMorePage}
            loadMore={handleLoadMore}
            threshold={10}
            loader={
              <CircularProgressBox
                key={0}
                boxProps={{ mt: 2, mb: 2, display: 'inline-flex', width: '100%' }}
                circularProps={{ size: 20 }}
              />
            }
          >
            {loadedProjects.map(project => (
              <Row key={project.id} item={project} />
            ))}
          </InfiniteScroll>
        )}
        {!isLoading && loadedProjects.length === 0 && (
          <div className="tableRow">
            <div className="emptyTableInfo">{t('users.dialogs.projectAccess.noProjectsFound')}</div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default ProjectsTable;
