import React, { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { MultiValue, SingleValue } from 'react-select';

import { WarningOutline } from '@superb-ai/icons';
import { Box, Button, Dialog, Icon, Typography } from '@superb-ai/ui';
import { compact } from 'lodash';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { useProjectQuery } from '../../../../../../../queries/useProjectQuery';
import { Option } from '../../../../../../../types/selectTypes';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { convertSelectedImageIdsToQueryString } from '../../../../../utils/filterUtils';
import { getSelectedImageCount } from '../../analytics/utils/utils';
import SelectProject from '../SendToLabelModal/SelectProject';
import SelectProjectTags from '../SendToLabelModal/SelectProjectTags';

type Props = {
  dialog: NonNullable<ComponentProps<typeof Dialog>['state']>;
};

export default function AddToSyncedProjectDialog({ dialog }: Props) {
  const { t } = useTranslation();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const { datasetInfo } = useDatasetContext();
  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const { selectedData, selectedAllData, deselectedData, setSelectedAllData } = useActionContext();
  const { totalCount } = useImageScopeContext();

  const [selectedProjectOption, setSelectedProjectOption] = useState<SingleValue<Option>>();
  const [selectedProjectTagsOption, setSelectedProjectTagsOption] = useState<MultiValue<Option>>();

  const { postSendToLabel } = useCurateDatasetService();

  const { data } = useProjectQuery({ projectId: selectedProjectOption?.value });

  const queryString = compact([
    selectedData && selectedData.length
      ? convertSelectedImageIdsToQueryString(selectedData.map(i => i.id))
      : '',
    queryStringWithHiddenFilterAndDeselection,
  ]).join(' AND ');

  useEffect(() => {
    if (!selectedAllData && selectedData.length === 0) setSelectedAllData(true);
  }, [dialog.visible]);

  async function handleCreateProjectAndSync() {
    if (!selectedProjectOption) return;
    const correlationId = uuidv4();

    await postSendToLabel({
      projectId: selectedProjectOption?.value,
      params: {
        correlationId,
        datasetId: datasetId,
        datasetName: datasetInfo?.name || '',
        query: queryString,
        clusterLevel,
        clusterIdIn: appliedFilters?.cluster_id_in,
        sliceId: sliceInfo?.id,
        sliceName: sliceInfo?.name,
        tags: selectedProjectTagsOption?.map(option => option.label) || [],
        withAnnotations: true,
      },
    });

    analyticsTracker.sendToLabelRequested({
      accountId: accountName,
      datasetId,
      sliceId: sliceInfo?.id ?? 'NOT_SELECTED',
      projectId: selectedProjectOption?.value,
      correlationId,
      syncType: 'add-to-synced-project',
      dataCount: getSelectedImageCount(selectedAllData, selectedData, deselectedData, totalCount),
      dataType: 'image',
    });

    dialog.hide();
    enqueueSnackbar(
      t('curate.dialogs.labelSyncComplete', {
        labelSyncType: t('curate.dialogs.labelSyncType.sync'),
        projectName: data.name,
      }),
      {
        variant: 'success',
        action: key => (
          <Button
            variant="text"
            color="white"
            onClick={() => {
              history.push(`/${accountName}/label/project/${data.id}/overview`);
              closeSnackbar(key);
            }}
          >
            {t('button.view')}
          </Button>
        ),
      },
    );
  }

  return (
    <Dialog
      state={dialog}
      aria-label="Sync with Curate dialog"
      hideOnClickOutside={false}
      style={{ width: '520px' }}
    >
      <Dialog.Header>{t('curate.dialogs.labelSyncType.addToProject')}</Dialog.Header>
      <Dialog.Content>
        <Box backgroundColor="primary-100" color="primary" p={1} display="flex" gap={1}>
          <Icon icon={WarningOutline} size={24} />
          <Typography variant="m-regular" style={{ marginTop: '3px' }}>
            {t('curate.dialogs.labelSyncType.addToProjectDescription')}
          </Typography>
        </Box>{' '}
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="m-regular">
            {t('curate.slices.sendToLabel.selectProject')}
          </Typography>
          <SelectProject
            isCurateSynced
            datasetId={datasetId}
            selectedProjectOption={selectedProjectOption}
            setSelectedProjectOption={setSelectedProjectOption}
          />
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Typography variant="m-regular">
            {t('curate.slices.sendToLabel.applyTags')}
            <Typography color="gray-300"> ({t('forms.optional')})</Typography>
          </Typography>
          <SelectProjectTags
            projectId={selectedProjectOption?.value}
            selectedProjectTagsOption={selectedProjectTagsOption}
            setSelectedProjectTagsOption={setSelectedProjectTagsOption}
          />
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void dialog.hide()}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={handleCreateProjectAndSync}>
          {t('button.add')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
