import { SuiteLogo } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu';
import { Layout } from './Layout';

export const AppsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.appsOverview, // not tracked
  path: 'apps',
  group: 'suite',
  icon: <SuiteLogo type="apps" />,
  component: Layout,
  title: ({ t }) => t('apps.title'),
  isEnabled: ({ featureFlags }) => featureFlags?.appsPlatform?.enabled ?? false,
  disabledMessage: ({ t }) => t('consts.featureMessage.contactUs'),
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
