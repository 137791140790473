import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';

import { useNewProjectInfo } from '../../../../../contexts/NewProjectContext';
import { Mode } from './const';
import KeypointDialogCreateLayout from './KeypointDialogCreateLayout';
import KeypointDialogSelectLayout from './KeypointDialogSelectLayout';

const KeypointDialog = (): JSX.Element => {
  const { t } = useTranslation();
  const newProjectInfo = useNewProjectInfo();
  const [mode, setMode] = useState<Mode>('SELECT');
  const [createdKeypointId, setCreatedKeypointId] = useState('');

  useEffect(() => {
    // init
    if (!newProjectInfo.isKeypointDialogOpen) return;
    setMode('SELECT');

    // eslint-disable-next-line
  }, [newProjectInfo.isKeypointDialogOpen]);

  return (
    <Modal
      open={newProjectInfo.isKeypointDialogOpen}
      title={t('projects.createProject.keypoint.keypointOption')}
      mainButton={{
        text: '',
        onClick: (): null => {
          return null;
        },
      }}
    >
      <Box position="relative" pt={1} pr={4} pb={1} pl={4}>
        {(mode === 'SELECT' || mode === 'EDIT') && (
          <KeypointDialogSelectLayout
            editMode={mode === 'EDIT'}
            {...{ setMode, createdKeypointId, setCreatedKeypointId }}
          />
        )}
        {mode === 'CREATE' && <KeypointDialogCreateLayout {...{ setMode, setCreatedKeypointId }} />}
      </Box>
    </Modal>
  );
};

export default KeypointDialog;
