import React, { useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';

type ContextProps = StateGetterSetter<['selectedDataset', 'setSelectedDataset'], string>;

export const AssignDialogContext = React.createContext({} as ContextProps);

export const AssignDialogProvider: React.FC = ({ children }) => {
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  return (
    <AssignDialogContext.Provider
      value={{
        selectedDataset,
        setSelectedDataset,
      }}
    >
      {children}
    </AssignDialogContext.Provider>
  );
};
