import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import styled from '@emotion/styled';
import { ChevronBigRight } from '@superb-ai/icons';
import { Box, Button as NFButton } from '@superb-ai/norwegian-forest';
import { Icon, Typography } from '@superb-ai/ui';

import AnalyticsTracker from '../../../analyticsTracker';
import { useCurateCommandContext } from '../../../apps/Curate/contexts/CommandContext';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthInfo } from '../../../contexts/AuthContext';
import HelpHubLauncher from '../../../contexts/commandBar/HelpHubLauncher';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../contexts/LabelCommandContext';
import ProfileMenu from '../../../menu/ProfileMenu';
import { getUrl } from '../../../routes/util';
import UserRoleUnion from '../../../union/UserRoleUnion';
import { useCommandBar } from '../../../utils/external';
import StorylaneTutorial from '../../elements/Storylane';
import { getIsRoleOrderSameOrLess } from '../../pages/label/labelList/AssignDialog/utils';
import CommandBarLauncher from './commands/CommandBarLauncher';
import CommandProgressMenu from './commands/CommandProgressMenu';
import NotificationMenu from './notifications/NotificationMenu';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;

  display: flex;
  justify-content: space-between;

  padding: 0 32px;
  line-height: 50px;
  height: 50px;
  box-sizing: border-box;

  box-shadow: inset 0 -1px 0 #e5e5e5;
  background-color: #fff;
`;

function GuestMenu() {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const history = useHistory();

  if (authInfo.isLoggedIn) {
    const to = `/${authInfo.accountName}`;
    return (
      <NFButton
        {...{ as: Link, to }}
        variant="strong-fill"
        color="primary"
        onClick={() =>
          AnalyticsTracker.returnToAccountClicked({ accountId: authInfo.accountName ?? '' })
        }
      >
        {t('auth.button.returnToMyAccount')}
      </NFButton>
    );
  }
  const to = getUrl('/auth/login', {}, { next: history.location.pathname });
  return (
    <NFButton
      {...{ as: Link, to }}
      variant="strong-fill"
      color="primary"
      onClick={() =>
        AnalyticsTracker.signInClicked({
          referrer: document.referrer,
          accountId: authInfo.accountName ?? '',
        })
      }
    >
      {t('auth.button.signin')}
    </NFButton>
  );
}

function UserMenu() {
  const { i18n } = useTranslation();
  const authInfo = useAuthInfo();
  const match = useRouteMatch<{ suite: 'label' | 'curate' }>();
  const labelingCommandContext = useLabelCommandContext();
  const curateCommandContext = useCurateCommandContext();

  // show helphub for beta users or users with korean platform language setting
  const useHelpHubBeta = useFeatureFlag('commandbarHelphubBeta') || i18n.language === 'ko';

  let hasPermission = false;
  if (authInfo.role) {
    if (UserRoleUnion.isCollaborator(authInfo.role)) {
      hasPermission = UserRoleUnion.isManager(authInfo.projectRole) ? true : false;
    } else {
      hasPermission = true;
    }
  }

  const showCommandProgressMenu = useMemo(
    () =>
      (match.params.suite === 'label' &&
        getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'Reviewer')) ||
      // available role can be different in curate suite
      (match.params.suite === 'curate' &&
        getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'Reviewer')),
    [authInfo.projectRole, authInfo.role, match.params.suite],
  );

  return (
    <>
      {useCommandBar && (
        <Box mr={1} width={230}>
          <CommandBarLauncher />
        </Box>
      )}
      {useHelpHubBeta && hasPermission && <HelpHubLauncher />}
      {showCommandProgressMenu && (
        <>
          {match.params.suite === 'curate' && (
            <CommandProgressMenu commandContext={curateCommandContext} />
          )}
          {match.params.suite === 'label' && (
            <CommandProgressMenu commandContext={labelingCommandContext} />
          )}
        </>
      )}
      <NotificationMenu />
      <ProfileMenu />
    </>
  );
}

export default function PageHeader({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const { pageTitle, breadcrumb } = useAppContext();
  const authInfo = useAuthInfo();
  const location = window.navigator.language?.match(/(ko|en|ja)/) || ['en'];
  const lang = window.localStorage.i18nextLng || 'en';
  const currentLang = lang || location[0];

  return (
    <StyledHeader>
      <Box mr="auto" display="inline-flex" alignItems="center" gap="4px">
        {breadcrumb.map((item, idx) => (
          <React.Fragment key={idx}>
            <Box
              style={{
                maxWidth: '214px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Link to={item.path}>
                <Typography variant="h3" color="gray-300">
                  {item.title}
                </Typography>
              </Link>
            </Box>
            <Icon icon={ChevronBigRight} color="gray-300" />
          </React.Fragment>
        ))}
        <Box
          style={{
            maxWidth: '250px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant="h3">{pageTitle || 'No Title'}</Typography>
        </Box>
      </Box>
      {children}
      <Box ml={5} display="flex" alignItems="center" gap={1}>
        <Box mr={1} display="flex" alignItems="center">
          <StorylaneTutorial lang={currentLang} />
        </Box>
        {authInfo.isGuest ? <GuestMenu /> : <UserMenu />}
      </Box>
    </StyledHeader>
  );
}
