import { differenceInMilliseconds, formatISO9075 } from 'date-fns';
import { findKey } from 'lodash';

import WorkappUnion, { WorkApp } from '../../union/WorkappUnion';
import { parseDate } from '../date';
import DateUtils from '../DateUtils';
import { getKeyByValue, mapEntryKeys, reverseKeyValue } from '../ObjectUtils';
import StringUtils from '../StringUtils';
import {
  createFilter,
  FromUrlParamFn,
  PossibleApiValue,
  PossibleOptionValue,
  ToUrlParamFn,
} from './types';

const { fullname2Email, email2Fullname } = (() => {
  const emails = new Map<string, string>();
  return {
    fullname2Email: (fullname: string): string => {
      const key = getKeyByValue(Object.fromEntries(emails), fullname);
      if (key) return key;
      const email = StringUtils.splitValueWithParentheses(fullname).innerValue || fullname;
      emails.set(email, fullname);
      return email;
    },
    email2Fullname: (email: string): string => {
      return emails.get(email) ?? email;
    },
  };
})();

export const page = createFilter({
  key: 'page',
  toApiParams({ options }) {
    if (!options) return null;
    return [['page', options[0] as number]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      page: value => ({ condition: '', options: [value as number] }),
    });
  },
});

export const searchAfter = createFilter({
  key: 'searchAfter',
  toApiParams({ options }) {
    if (!options) return null;
    return [['searchAfter', `${options[0]}`]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      searchAfter: value => ({ condition: '', options: [value] }),
    });
  },
});

export const searchBefore = createFilter({
  key: 'searchBefore',
  toApiParams({ options }) {
    if (!options) return null;
    return [['searchBefore', `${options[0]}`]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      searchBefore: value => ({ condition: '', options: [value] }),
    });
  },
});

export const searchLast = createFilter({
  key: 'searchLast',
  toApiParams({ options }) {
    if (!options) return null;
    return [['searchLast', `${options[0]}`]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      searchLast: value => ({ condition: '', options: [value] }),
    });
  },
});

export const pageSize = createFilter({
  key: 'pageSize',
  toApiParams({ options }) {
    if (!options) return null;
    return [['pageSize', options[0] as number]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      pageSize: value => ({ condition: '', options: [value as number] }),
    });
  },
});

// For loki
// const dataKeyCondition2ApiKey = {
//   contains: 'assetKeyContains',
//   matches: 'assetKeyIn',
//   'starts with': 'assetKeyStartsWith',
//   'ends with': 'assetKeyEndsWith',
// };
const dataKeyCondition2ApiKey = {
  contains: 'assetKeyIcontains',
  matches: 'assetKeyIn',
  'starts with': 'assetKeyStartsWith',
  'ends with': 'assetKeyEndsWith',
};
const dataApiKey2KeyCondition = reverseKeyValue(dataKeyCondition2ApiKey);

export const dataKey = createFilter({
  key: 'dataKey',
  conditions: ['contains', 'matches', 'starts with', 'ends with'],
  toApiParams({ condition, options }) {
    if (!condition || !options) return null;
    return [[dataKeyCondition2ApiKey[condition], options[0] as string]];
  },
  fromApiParams(apiParams) {
    const mapper = Object.fromEntries(
      Object.entries(dataApiKey2KeyCondition).map(([apiKey, condition]) => [
        apiKey,
        (value: PossibleApiValue) => ({
          condition,
          options: [`${value}`],
        }),
      ]),
    );
    return mapEntryKeys(apiParams, mapper);
  },
});

type RenderStatusOption = 'in progress' | 'skipped' | 'submitted';
type ApiStatusOption = 'working' | 'skipped' | 'submitted';

export const status = createFilter({
  key: 'status',
  conditions: ['is any one of'],
  options: ['in progress', 'skipped', 'submitted'],
  toApiParams({ options }) {
    if (!options) return null;
    const mappedOptions = options.map(v =>
      (v as string).replace('in progress', 'working').toUpperCase(),
    );

    return [['statusIn', mappedOptions as Uppercase<ApiStatusOption>[]]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      statusIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(v =>
          `${v}`.toLowerCase().replace('working', 'in progress'),
        ) as RenderStatusOption[],
      }),
    });
  },
});

export const review = createFilter({
  key: 'review',
  conditions: ['is any one of', 'does not exist'],
  options: ['approve', 'reject'],
  toApiParams({ condition, options }): [string, boolean | ('APPROVE' | 'REJECT')[]][] {
    if (!condition || !options) return [];
    if (condition === 'does not exist') {
      return [['isUnreviewed', true]];
    }
    return [['reviewIn', options.map(v => (v as string).toUpperCase() as 'APPROVE' | 'REJECT')]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      isUnreviewed: () => ({
        condition: 'does not exist' as const,
        options: [],
      }),
      reviewIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(
          v => `${v}`.toLowerCase() as 'approve' | 'reject',
        ),
      }),
    });
  },
});

export const reviewRound = createFilter({
  key: 'reviewRound',
  conditions: ['≥', '≤', 'is in the range'],
  options: [...new Array(51).keys()].map(i => `${i}`),
  toApiParams({ condition, options }) {
    if (!condition || !options) return null;
    switch (condition) {
      case 'is in the range':
        return [
          ['reviewRoundGte', Number(options[0])],
          ['reviewRoundLte', Number(options[1])],
        ];
      case '≥':
        return [['reviewRoundGte', Number(options[0])]];
      case '≤':
        return [['reviewRoundLte', Math.max(0, Number(options[0]))]];
    }
  },
  fromApiParams(apiParams) {
    const reviewRoundGte = apiParams.find(([key]) => key === 'reviewRoundGte');
    const reviewRoundLte = apiParams.find(([key]) => key === 'reviewRoundLte');
    if (reviewRoundGte && reviewRoundLte) {
      return [
        {
          condition: 'is in the range',
          options: [`${reviewRoundGte[1]}`, `${reviewRoundLte[1]}`],
        },
      ];
    }
    if (reviewRoundGte) {
      return [
        {
          condition: '≥',
          options: [`${reviewRoundGte[1]}`],
        },
      ];
    }
    if (reviewRoundLte) {
      return [
        {
          condition: '≤',
          options: [`${reviewRoundLte[1]}`],
        },
      ];
    }
  },
});

export const assignee = createFilter({
  key: 'assignee',
  conditions: ['is any one of', 'none of', 'does not exist'],
  toApiParams({ condition, options = [] }): [string, boolean | string[]][] {
    if (condition === 'does not exist') {
      return [['isUnassigned', true]];
    }
    if (condition === 'is any one of') {
      if (options.includes('all users')) {
        return [['isUnassigned', false]];
      }
      return [['workAssigneeIn', options.map(v => fullname2Email(`${v}`))]];
    }
    if (options.includes('all users')) {
      return [['isUnassigned', true]];
    }
    return [['workAssigneeNoneOf', options.map(v => fullname2Email(`${v}`))]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      isUnassigned: value =>
        value
          ? {
              condition: 'does not exist' as const,
              options: [],
            }
          : {
              condition: 'is any one of' as const,
              options: ['all users'],
            },
      workAssigneeIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
      workAssigneeNoneOf: value => ({
        condition: 'none of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
    });
  },
});

export const reviewer = createFilter({
  key: 'reviewer',
  conditions: ['is any one of', 'none of', 'does not exist'],
  toApiParams({ condition, options = [] }): [string, boolean | string[]][] {
    if (!condition) return [];
    if (condition === 'does not exist') {
      return [['isReviewerUnassigned', true]];
    }
    if (condition === 'is any one of') {
      if (options.includes('all users')) {
        return [['isReviewerUnassigned', false]];
      }
      return [['reviewerIn', options.map(v => fullname2Email(v as string))]];
    }
    if (options.includes('all users')) {
      return [['isReviewerUnassigned', true]];
    }
    return [['reviewerNoneOf', options.map(v => fullname2Email(v as string))]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      isReviewerUnassigned: value =>
        value
          ? {
              condition: 'does not exist' as const,
              options: [],
            }
          : {
              condition: 'is any one of' as const,
              options: ['all users'],
            },
      reviewerIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
      reviewerNoneOf: value => ({
        condition: 'none of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
    });
  },
});

// For loki
// const labelTagApiParamByFilterValue = {
//   'contains all of': 'tagAll',
//   'contains any one of': 'tagAny',
//   'contains only': 'tagOnly',
//   'contains none of': 'tagNoneOf',
//   'is empty': 'tagExist',
//   'is not empty': 'tagExist',
// } as const;
const labelTagApiParamByFilterValue = {
  'contains all of': 'tagsAll',
  'contains any one of': 'tagsAny',
  'contains only': 'tagsOnly',
  'contains none of': 'tagsNotAny',
  'is empty': 'tagsExist',
  'is not empty': 'tagsExist',
} as const;
type LabelTagApiParamsKeys = keyof typeof labelTagApiParamByFilterValue;
const filterValueByApiParam = reverseKeyValue(labelTagApiParamByFilterValue) as Record<
  (typeof labelTagApiParamByFilterValue)[LabelTagApiParamsKeys],
  LabelTagApiParamsKeys
>;

export const labelTag = createFilter({
  key: 'labelTag',
  conditions: [
    'contains all of',
    'contains any one of',
    'contains only',
    'contains none of',
    'is empty',
    'is not empty',
  ],
  toApiParams(
    { condition, options = [] },
    { tagIds }: { tagIds: Record<string, string> },
  ): [string, boolean | string[]][] {
    if (!condition || !tagIds) return [];
    switch (condition) {
      case 'is empty':
        return [['tagsExist', false]];
      case 'is not empty':
        return [['tagsExist', true]];
    }
    if (Array.isArray(options)) {
      return [
        [labelTagApiParamByFilterValue[condition], options.map(option => tagIds[option as string])],
      ];
    }
    return [];
  },
  fromApiParams(apiParams, { tagIds }: { tagIds: Record<string, string> }) {
    const mapper = Object.fromEntries(
      Object.entries(filterValueByApiParam).map(([apiKey, condition]) => [
        apiKey,
        (value: string[]) => ({
          condition,
          options: (value ?? []).flatMap(id => findKey(tagIds, tag => tag === id)),
        }),
      ]),
    );
    return mapEntryKeys(apiParams, {
      ...mapper,
      // For loki
      // tagExist: (value: PossibleApiValue) =>
      tagsExist: (value: PossibleApiValue) =>
        value ? { condition: 'is not empty' as const } : { condition: 'is empty' as const },
    });
  },
});

export const annotation = createFilter({
  key: 'annotation',
  conditions: [
    'contains all of',
    'does not contain all of',
    'contains none of',
    'exists',
    'does not exist',
  ],
  toApiParams({ condition, options = [] }, { workApp }: { workApp: WorkApp }) {
    const optionsPerWorkApp =
      workApp && WorkappUnion.isSiesta(workApp)
        ? options.map(item => {
            const idList = (item as string).split(';');
            return idList.pop();
          })
        : options;

    const getReplacedOptions = (): PossibleApiValue => {
      return optionsPerWorkApp.map(option => (option as string).replace(/;/g, ','));
    };

    switch (condition) {
      case 'contains all of':
        return [['classIdAll', getReplacedOptions()]];
      case 'does not contain all of':
        return [['classIdNotAll', getReplacedOptions()]];
      case 'contains none of':
        return [['classIdNoneOf', getReplacedOptions()]];
      case 'exists':
        return [['classExists', true]];
      case 'does not exist':
        return [['classExists', false]];
    }
  },
  fromApiParams(apiParams) {
    const getReplacedOptions = (value: (number | string)[] | undefined) => {
      return (value ?? []).map(v => `${v}`.split(',').join(';'));
    };

    return mapEntryKeys(apiParams, {
      // For loki
      // classAll: (value: PossibleApiValue) => ({
      //   condition: 'contains all of' as const,
      //   options: Array.isArray(value) ? getReplacedOptions(value) : [],
      // }),
      // classNotAll: (value: PossibleApiValue) => ({
      //   condition: 'does not contain all of' as const,
      //   options: Array.isArray(value) ? getReplacedOptions(value) : [],
      // }),
      // classNoneOf: (value: PossibleApiValue) => ({
      //   condition: 'contains none of' as const,
      //   options: Array.isArray(value) ? getReplacedOptions(value) : [],
      // }),
      // classExists: (value: PossibleApiValue) => ({
      //   condition: value ? ('exists' as const) : ('does not exist' as const),
      //   options: [],
      // }),
      classIdAll: (value: PossibleApiValue) => ({
        condition: 'contains all of' as const,
        options: Array.isArray(value) ? getReplacedOptions(value) : [],
      }),
      classIdNotAll: (value: PossibleApiValue) => ({
        condition: 'does not contain all of' as const,
        options: Array.isArray(value) ? getReplacedOptions(value) : [],
      }),
      classIdNoneOf: (value: PossibleApiValue) => ({
        condition: 'contains none of' as const,
        options: Array.isArray(value) ? getReplacedOptions(value) : [],
      }),
      classExists: (value: PossibleApiValue) => ({
        condition: value ? ('exists' as const) : ('does not exist' as const),
        options: [],
      }),
    });
  },
  toUrlParam({ condition, options }) {
    if (!condition || !options) return null;
    return [condition, ...options].join(',');
  },
  fromUrlParam: v => v,
});

export const qa = createFilter({
  key: 'qa',
  conditions: ['is any one of'],
  options: ['consensus', 'default'],
  toApiParams({ options }) {
    if (!options) return null;
    return [['labelTypeIn', options.map(value => value.toUpperCase())]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      labelTypeIn: (value: PossibleApiValue) => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(
          value => `${value}`.toLowerCase() as 'consensus' | 'default',
        ),
      }),
    });
  },
});

export const consistencyScore = createFilter({
  key: 'consistencyScore',
  conditions: ['≥', '≤', 'is in the range'],
  options: [...new Array(101).keys()].map(i => `${i}`),
  toApiParams({ condition, options }) {
    if (!condition || !options) return [];
    switch (condition) {
      case 'is in the range':
        return [
          ['consistencyScoreGte', Number(options[0])],
          ['consistencyScoreLte', Number(options[1])],
        ];
      case '≥':
        return [['consistencyScoreGte', Number(options[0])]];
      case '≤':
        return [['consistencyScoreLte', Number(options[0])]];
    }
  },
  fromApiParams(apiParams) {
    const consistencyScoreGte = apiParams.find(([key]) => key === 'consistencyScoreGte');
    const consistencyScoreLte = apiParams.find(([key]) => key === 'consistencyScoreLte');
    if (consistencyScoreGte && consistencyScoreLte) {
      return [
        {
          condition: 'is in the range' as const,
          options: [`${consistencyScoreGte[1]}`, `${consistencyScoreLte[1]}`],
        },
      ];
    }
    if (consistencyScoreGte) {
      return [
        {
          condition: '≥' as const,
          options: [`${consistencyScoreGte[1]}`],
        },
      ];
    }
    if (consistencyScoreLte) {
      return [
        {
          condition: '≤' as const,
          options: [`${consistencyScoreLte[1]}`],
        },
      ];
    }
  },
});

export const annotationCount = createFilter({
  key: 'annotationCount',
  conditions: ['≥', '≤', '=', 'is in the range'],
  toApiParams({ condition, options }) {
    if (!condition || !options) return [];
    switch (condition) {
      case 'is in the range':
        return [
          ['classCountGte', [[options[0], options[2]].join(';')]],
          ['classCountLte', [[options[1], options[2]].join(';')]],
        ];
      case '=':
        return [['classCountEq', [options.join(';')]]];
      case '≥':
        return [['classCountGte', [options.join(';')]]];
      case '≤':
        return [['classCountLte', [options.join(';')]]];
    }
  },
  fromApiParams(apiParams) {
    const classCountGte = apiParams.find(([key]) => key === 'classCountGte');
    const classCountLte = apiParams.find(([key]) => key === 'classCountLte');
    const classCountEq = apiParams.find(([key]) => key === 'classCountEq');

    if (classCountGte && classCountLte) {
      const gte = `${(classCountGte[1] as string[])?.[0]}`.split(';');
      const lte = `${(classCountLte[1] as string[])?.[0]}`.split(';');
      return [
        {
          condition: 'is in the range' as const,
          options: [gte[0], lte[0], gte[1]],
        },
      ];
    }
    if (classCountGte) {
      return [
        {
          condition: '≥' as const,
          options: [...`${(classCountGte[1] as string[])?.[0]}`.split(';')],
        },
      ];
    }
    if (classCountLte) {
      return [
        {
          condition: '≤' as const,
          options: [...`${(classCountLte[1] as string[])?.[0]}`.split(';')],
        },
      ];
    }
    if (classCountEq) {
      return [
        {
          condition: '=' as const,
          options: [...`${(classCountEq[1] as string[])?.[0]}`.split(';')],
        },
      ];
    }
  },
});

export const issues = createFilter({
  key: 'issues',
  conditions: [
    'open by any one of',
    'resolved by any one of',
    'by any one of',
    // issue에 type이 필요 없어서 제거해도 될듯
    // 'open type any of',
    'no open issues',
    'no issues',
  ],
  // 옵션도 필요없을듯
  options: ['MISLABEL_DETECTION', 'REVIEW_REJECT', 'DEFAULT'] as string[],
  toApiParams({ condition, options = [] }): [string, boolean | string[]][] {
    const createByUserApiParam = (key: string): [string, boolean | string[]][] => {
      if (options.includes('all users')) {
        return [[key, true]];
      } else {
        return [[`${key}ByIn`, options.map(option => fullname2Email(option))]];
      }
    };
    switch (condition) {
      case 'open by any one of':
        return createByUserApiParam('containsOpenIssue');
      case 'resolved by any one of':
        return createByUserApiParam('containsResolvedIssue');
      case 'by any one of':
        return createByUserApiParam('containsIssue');
      // case 'open type any of':
      //   return [['containsOpenIssueTypeIn', options]];
      case 'no open issues':
        return [['containsOpenIssue', false]];
      case 'no issues':
        return [['containsIssue', false]];
    }
  },
  fromApiParams(apiParams) {
    const createFilter =
      <T extends string>(condition: T) =>
      (value: PossibleApiValue) => {
        if (Array.isArray(value)) {
          return { condition, options: value.map(v => email2Fullname(`${v}`)) };
        } else if (value) {
          return { condition, options: ['all users'] };
        } else if (condition === 'by any one of') {
          return { condition: 'no issues' as const, options: [] };
        } else {
          return { condition: 'no open issues' as const, options: [] };
        }
      };

    return mapEntryKeys(apiParams, {
      containsOpenIssueByIn: createFilter('open by any one of'),
      containsOpenIssue: createFilter('open by any one of'),
      containsIssueByIn: createFilter('by any one of'),
      containsIssue: createFilter('by any one of'),
      // containsOpenIssueTypeIn: (value: PossibleApiValue) => ({
      //   condition: 'open type any of' as const,
      //   options: Array.isArray(value)
      //     ? (value as ('MISLABEL_DETECTION' | 'REVIEW_REJECT' | 'DEFAULT')[])
      //     : [],
      // }),
      containsResolvedIssueByIn: (value: PossibleApiValue) => ({
        condition: 'resolved by any one of' as const,
        options: Array.isArray(value) ? value.map(v => email2Fullname(`${v}`)) : ['all users'],
      }),
      containsResolvedIssue: (value: PossibleApiValue) => ({
        condition: 'resolved by any one of' as const,
        options: Array.isArray(value) ? value.map(v => email2Fullname(`${v}`)) : ['all users'],
      }),
    });
  },
});

/**
 * For Date Added & Last Updated
 */
const dateString2ISO = (
  date: string | Date,
  option?: Parameters<typeof formatISO9075>[1],
  hours?: number,
) => {
  if (typeof hours !== 'undefined') return formatISO9075(new Date(date).setHours(hours));
  else return formatISO9075(new Date(date), option);
};

export const date2ISOUTC = (
  date: string | Date,
  option?: Parameters<typeof formatISO9075>[1],
  hours?: number,
) => {
  const d = parseDate(date);
  if (typeof hours !== 'undefined') d.setHours(hours);
  return formatISO9075(DateUtils.getDateSubtractedTimezone(d), option);
};

export type ToUrlParamsDataProps =
  | {
      condition: 'is';
      options: [{ date: string | Date }];
    }
  | {
      condition: 'is in the range';
      options: [{ sdate: string | Date }, { edate: string | Date }];
    };

// in local, Date has timezone information, So toUrlParam function just format date to isoString
const toUrlParamForDate = ({ condition, options }: ToUrlParamsDataProps) => {
  if (!options || !options.length) {
    return undefined;
  } else if (condition === 'is' && options[0].date) {
    return [condition, dateString2ISO(options[0].date, { representation: 'date' })].join(',');
  } else if (condition === 'is in the range' && options[0].sdate && options[1].edate) {
    return [condition, dateString2ISO(options[0].sdate), dateString2ISO(options[1].edate)].join(
      ',',
    );
  }
};

// in local, Date has timezone information, So fromUrlParam function just parse date to string
export const fromUrlParamForDate = ({
  condition,
  options,
}: {
  condition: 'is' | 'is in the range';
  options?: PossibleOptionValue[];
}): ToUrlParamsDataProps | undefined => {
  if (!Array.isArray(options) || !options.length) {
    return undefined;
  } else if (condition === 'is') {
    return {
      condition,
      options: [{ date: parseDate(`${options[0]}`) }],
    };
  } else if (condition === 'is in the range') {
    return {
      condition,
      options: [{ sdate: parseDate(`${options[0]}`) }, { edate: parseDate(`${options[1]}`) }],
    };
  }
};

export const dateAdded = createFilter({
  key: 'dateAdded',
  conditions: ['is', 'is in the range'],
  // Api Server recieve only UTC Date time. So toApiParam function should be change date to UTCISOString.
  toApiParams({ condition, options }) {
    if (!condition || !options) return [];
    switch (condition) {
      case 'is':
        return [
          // Date in url is in local time, need to convert to UTC datetime and format
          ['createdAtGte', date2ISOUTC(options[0] as string, {}, 0)],
          ['createdAtLt', date2ISOUTC(options[0] as string, {}, 24)],
        ];
      case 'is in the range':
        return [
          // Datetime in url is in UTC already, just format
          ['createdAtGte', date2ISOUTC(options[0] as string)],
          ['createdAtLt', date2ISOUTC(options[1] as string)],
        ];
    }
  },
  // Api Server send only UTC Date time. So fromApiParam function should be add timezone offset to received date time.
  fromApiParams(apiParams) {
    const createdAtGte = apiParams.find(([key]) => key === 'createdAtGte');
    const createdAtLt = apiParams.find(([key]) => key === 'createdAtLt');
    if (!createdAtGte || !createdAtLt) {
      return [];
    }
    const localTimeGte = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(createdAtGte[1] as string)),
    );
    const localTimeLt = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(createdAtLt[1] as string)),
    );
    const diff = differenceInMilliseconds(
      parseDate(localTimeLt as string),
      parseDate(localTimeGte as string),
    );
    if (diff === 86400000)
      return [
        {
          condition: 'is' as const,
          options: [localTimeGte] as [string], // TODO check if correct
        },
      ];
    return [
      {
        condition: 'is in the range' as const,
        options: [localTimeGte, localTimeLt] as [string, string],
      },
    ];
  },
  toUrlParam: toUrlParamForDate as ToUrlParamFn<'is' | 'is in the range'>,
  fromUrlParam: fromUrlParamForDate as FromUrlParamFn<'is' | 'is in the range'>,
});

export const lastUpdated = createFilter({
  key: 'lastUpdated',
  conditions: ['is', 'is in the range'],
  // Api Server recieve only UTC Date time. So toApiParam function should be change date to UTCISOString.
  toApiParams({ condition, options }) {
    if (!condition || !options) return null;
    switch (condition) {
      case 'is':
        return [
          // Date in url is in local time, need to convert to UTC datetime and format
          ['lastUpdatedAtGte', date2ISOUTC(options[0] as string, {}, 0)],
          ['lastUpdatedAtLt', date2ISOUTC(options[0] as string, {}, 24)],
        ];
      case 'is in the range':
        return [
          // Datetime in url is in UTC already, just format
          ['lastUpdatedAtGte', date2ISOUTC(options[0] as string)],
          ['lastUpdatedAtLt', date2ISOUTC(options[1] as string)],
        ];
    }
  },
  // Api Server send only UTC Date time. So fromApiParam function should be add timezone offset to received date time.
  fromApiParams(apiParams) {
    const lastUpdatedAtGte = apiParams.find(([key]) => key === 'lastUpdatedAtGte');
    const lastUpdatedAtLt = apiParams.find(([key]) => key === 'lastUpdatedAtLt');
    if (!lastUpdatedAtGte || !lastUpdatedAtLt) {
      return [];
    }
    const localTimeGte = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastUpdatedAtGte[1] as string)),
    );
    const localTimeLt = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastUpdatedAtLt[1] as string)),
    );
    const diff = differenceInMilliseconds(
      parseDate(localTimeLt as string),
      parseDate(localTimeGte as string),
    );
    if (diff === 86400000)
      return [
        {
          condition: 'is' as const,
          options: [localTimeGte] as [string],
        },
      ];
    return [
      {
        condition: 'is in the range' as const,
        options: [localTimeGte, localTimeLt] as [string, string],
      },
    ];
  },
  toUrlParam: toUrlParamForDate as ToUrlParamFn<'is' | 'is in the range'>,
  fromUrlParam: fromUrlParamForDate as FromUrlParamFn<'is' | 'is in the range'>,
});

export const dataset = createFilter({
  key: 'dataset',
  conditions: ['is any one of'],
  toApiParams({ condition, options }) {
    if (!condition || !options) return null;
    switch (condition) {
      case 'is any one of':
        return [['assetGroupIn', options as string[]]];
    }
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      assetGroupIn: (value: string[]) => ({
        condition: 'is any one of' as const,
        options: value,
      }),
    });
  },
});

export const autoLabelRequest = createFilter({
  key: 'autoLabelRequest',
  conditions: ['is any one of', 'is empty'],
  options: ['processing', 'failed', 'complete'],
  toApiParams({ condition, options = [] }) {
    if (!condition) return null;
    switch (condition) {
      case 'is any one of':
        return [['autoLabelStatusIn', options.map(option => option.toUpperCase())]];
      case 'is empty':
        return [['autoLabelStatusIn', ['']]];
    }
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      autoLabelStatusIn: (value: string[]) =>
        value.length <= 1 && value[0] === ''
          ? { condition: 'is empty' as const }
          : {
              condition: 'is any one of' as const,
              options: (value ?? []).map(
                value => value.toLowerCase() as 'processing' | 'failed' | 'complete',
              ),
            },
    });
  },
});

enum Difficulty {
  'easy' = 1,
  'medium',
  'hard',
}
const difficulty = { 1: 'easy', 2: 'medium', 3: 'hard' } as const;

function isDifficulty(n: PossibleApiValue): n is '1' | '2' | '3' {
  return `${n}` === '1' || `${n}` === '2' || `${n}` === '3';
}
export const autoLabelDifficulty = createFilter({
  key: 'autoLabelDifficulty',
  conditions: ['is any one of'],
  options: ['easy', 'medium', 'hard'],
  toApiParams({ options }) {
    if (!options) return null;
    return [['autoLabelDifficultyIn', options.map(option => Difficulty[option])]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      autoLabelDifficultyIn: (value: PossibleApiValue) => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).flatMap(v =>
          isDifficulty(v) ? difficulty[v] : [],
        ),
      }),
    });
  },
});

export const labelId = createFilter({
  key: 'labelId',
  conditions: ['matches'],
  toApiParams({ options }) {
    if (!options) return null;
    return [['idIn', options as string[]]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      idIn: (value: PossibleApiValue) => ({
        condition: 'matches' as const,
        options: Array.isArray(value) ? value : [],
      }),
    });
  },
});

export const lastLabeledAt = createFilter({
  key: 'lastLabeledAt',
  conditions: ['is', 'is in the range'],
  // Api Server recieve only UTC Date time. So toApiParam function should be change date to UTCISOString.
  toApiParams({ condition, options }) {
    if (!condition || !options) return [];
    switch (condition) {
      case 'is':
        return [
          // Date in url is in local time, need to convert to UTC datetime and format
          ['lastLabeledAtGte', date2ISOUTC(options[0] as string, {}, 0)],
          ['lastLabeledAtLt', date2ISOUTC(options[0] as string, {}, 24)],
        ];
      case 'is in the range':
        return [
          // Datetime in url is in UTC already, just format
          ['lastLabeledAtGte', date2ISOUTC(options[0] as string)],
          ['lastLabeledAtLt', date2ISOUTC(options[1] as string)],
        ];
    }
  },
  // Api Server send only UTC Date time. So fromApiParam function should be add timezone offset to received date time.
  fromApiParams(apiParams) {
    const lastLabeledAtGte = apiParams.find(([key]) => key === 'lastLabeledAtGte');
    const lastLabeledAtLt = apiParams.find(([key]) => key === 'lastLabeledAtLt');
    if (!lastLabeledAtGte || !lastLabeledAtLt) {
      return [];
    }
    const localTimeGte = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastLabeledAtGte[1] as string)),
    );
    const localTimeLt = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastLabeledAtLt[1] as string)),
    );
    const diff = differenceInMilliseconds(
      parseDate(localTimeLt as string),
      parseDate(localTimeGte as string),
    );
    if (diff === 86400000)
      return [
        {
          condition: 'is' as const,
          options: [localTimeGte] as [string],
        },
      ];
    return [
      {
        condition: 'is in the range' as const,
        options: [localTimeGte, localTimeLt] as [string, string],
      },
    ];
  },
  toUrlParam: toUrlParamForDate as ToUrlParamFn<'is' | 'is in the range'>,
  fromUrlParam: fromUrlParamForDate as FromUrlParamFn<'is' | 'is in the range'>,
});

export const lastReviewedAt = createFilter({
  key: 'lastReviewedAt',
  conditions: ['is', 'is in the range'],
  // Api Server recieve only UTC Date time. So toApiParam function should be change date to UTCISOString.
  toApiParams({ condition, options }) {
    if (!condition || !options) return [];
    switch (condition) {
      case 'is':
        return [
          // Date in url is in local time, need to convert to UTC datetime and format
          ['lastReviewedAtGte', date2ISOUTC(options[0] as string, {}, 0)],
          ['lastReviewedAtLt', date2ISOUTC(options[0] as string, {}, 24)],
        ];
      case 'is in the range':
        return [
          // Datetime in url is in UTC already, just format
          ['lastReviewedAtGte', date2ISOUTC(options[0] as string)],
          ['lastReviewedAtLt', date2ISOUTC(options[1] as string)],
        ];
    }
  },
  // Api Server send only UTC Date time. So fromApiParam function should be add timezone offset to received date time.
  fromApiParams(apiParams) {
    const lastReviewedAtGte = apiParams.find(([key]) => key === 'lastReviewedAtGte');
    const lastReviewedAtLt = apiParams.find(([key]) => key === 'lastReviewedAtLt');
    if (!lastReviewedAtGte || !lastReviewedAtLt) {
      return [];
    }
    const localTimeGte = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastReviewedAtGte[1] as string)),
    );
    const localTimeLt = dateString2ISO(
      DateUtils.getDateAppliedTimezoneOffset(new Date(lastReviewedAtLt[1] as string)),
    );
    const diff = differenceInMilliseconds(
      parseDate(localTimeLt as string),
      parseDate(localTimeGte as string),
    );
    if (diff === 86400000)
      return [
        {
          condition: 'is' as const,
          options: [localTimeGte] as [string],
        },
      ];
    return [
      {
        condition: 'is in the range' as const,
        options: [localTimeGte, localTimeLt] as [string, string],
      },
    ];
  },
  toUrlParam: toUrlParamForDate as ToUrlParamFn<'is' | 'is in the range'>,
  fromUrlParam: fromUrlParamForDate as FromUrlParamFn<'is' | 'is in the range'>,
});

export const lastLabeledBy = createFilter({
  key: 'lastLabeledBy',
  conditions: ['is any one of'],
  toApiParams({ options = [] }): [string, boolean | string[]][] {
    return [['lastLabeledByIn', options.map(v => fullname2Email(`${v}`))]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      lastLabeledByIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
    });
  },
});

export const lastReviewedBy = createFilter({
  key: 'lastReviewedBy',
  conditions: ['is any one of'],
  toApiParams({ options = [] }): [string, boolean | string[]][] {
    return [['lastReviewedByIn', options.map(v => fullname2Email(`${v}`))]];
  },
  fromApiParams(apiParams) {
    return mapEntryKeys(apiParams, {
      lastReviewedByIn: value => ({
        condition: 'is any one of' as const,
        options: (Array.isArray(value) ? value : []).map(v => email2Fullname(`${v}`)),
      }),
    });
  },
});

export const excludeFilters = [
  'page',
  'pageSize',
  'searchAfter',
  'searchBefore',
  'searchLast',
] as const;

export const allFilters = {
  searchAfter,
  searchBefore,
  searchLast,
  page,
  pageSize,
  status,
  assignee,
  labelTag,
  review,
  reviewer,
  reviewRound,
  dataKey,
  dataset,
  annotation,
  autoLabelDifficulty,
  autoLabelRequest,
  lastUpdated,
  dateAdded,
  issues,
  qa,
  consistencyScore,
  labelId,
  lastLabeledAt,
  lastReviewedAt,
  lastLabeledBy,
  lastReviewedBy,
  annotationCount,
} as const;

export type FilterKey = keyof typeof allFilters;
export type LabelFilter = (typeof allFilters)[FilterKey];

export const labelFilters = {
  // https://uploads.linear.app/7a4dae92-23ed-4574-9ace-a779b686fa95/3d093ebb-2455-42a4-8ab0-0b03b09aae7a/f2e2260e-efa0-4324-a2ff-1e58a86d711f
  get 'system admin'() {
    return this.owner;
  },
  get admin() {
    return this.owner;
  },
  owner: [
    status,
    assignee,
    labelTag,
    review,
    reviewer,
    reviewRound,
    dataKey,
    dataset,
    annotation,
    autoLabelDifficulty,
    autoLabelRequest,
    lastUpdated,
    dateAdded,
    issues,
    qa,
    consistencyScore,
    labelId,
    lastLabeledAt,
    annotationCount,
  ],
  manager: [
    status,
    labelTag,
    assignee,
    dataKey,
    dataset,
    review,
    reviewer,
    reviewRound,
    annotation,
    autoLabelRequest,
    lastUpdated,
    dateAdded,
    issues,
    labelId,
    lastLabeledAt,
    annotationCount,
  ],
  reviewer: [
    status,
    labelTag,
    assignee,
    dataKey,
    review,
    reviewRound,
    annotation,
    lastUpdated,
    issues,
    labelId,
    lastLabeledAt,
    annotationCount,
  ],
  worker: [
    status,
    dataKey,
    labelTag,
    review,
    issues,
    lastUpdated,
    annotation,
    labelId,
    lastLabeledAt,
    annotationCount,
  ],
  get collaborator() {
    return this.worker;
  },
  get labeler() {
    return this.worker;
  },
} as const;

const civet2LokiConditionMap: Record<string, string> = {
  tagsAll: 'tagAll',
  tagsAny: 'tagAny',
  tagsOnly: 'tagOnly',
  tagsNotAll: 'tagNotAll',
  tagsNotAny: 'tagNoneOf',
  tagsExist: 'tagExist',
  classIdAll: 'classIdAll',
  classIdNotAll: 'classIdNotAll',
  classIdNoneOf: 'classIdNoneOf',
  assetKeyIcontains: 'assetKeyContains',
  isUnassigned: 'workAssigneeExists',
  isReviewerUnassigned: 'reviewerExists',
  isUnreviewed: 'reviewExists',
  containsOpenIssueByIn: 'openIssueByIn',
  containsResolvedIssueByIn: 'resolvedIssueByIn',
  containsResolvedIssue: 'resolvedIssueExists',
  containsIssueByIn: 'issueByIn',
  containsOpenIssue: 'openIssueExists',
  containsOpenIssueTypeIn: 'openIssueTypeIn',
  containsIssue: 'issueExists',
};

const loki2CivetConditionMap: Record<string, string> = Object.fromEntries(
  Object.entries(civet2LokiConditionMap).map(([key, value]) => [value, key]),
);

export function translateCivetCondition2LokiCondition(condition: string) {
  return civet2LokiConditionMap[condition] ?? condition;
}

export function translateLokiCondition2CivetCondition(condition: string) {
  return loki2CivetConditionMap[condition] ?? condition;
}
