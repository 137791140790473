import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { parse } from '@superb-ai/dql';
import { Copy, InfoCircleOutline, Reset } from '@superb-ai/icons';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, IconButton, Tooltip, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import analyticsTracker from '../../../../../../analyticsTracker';
import { AlertBox } from '../../../../../../components/elements/AlertBox';
import RegexUtils from '../../../../../../utils/RegexUtils';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { IAdvancedQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';
import { IMAGE_SCOPE, SCOPE, ScopeMode } from '../../../../types/viewTypes';
import { convertString } from '../advancedQuery/advancedQueryUtils';
import CreateQueryTab from '../advancedQuery/CreateQueryTab';
import { useAdvancedQuery } from '../advancedQuery/useQuery';
type Props = {
  open: boolean;
  handleClose: (advancedQuery: IAdvancedQuery, selectedQueryList: ISelectedQuery[]) => void;
  handleApply: (
    queryString: string,
    advancedQuery: IAdvancedQuery,
    selectedQueryList: ISelectedQuery[],
  ) => void;
  memoAdvancedQuery: IAdvancedQuery;
  memoSelectedQueryList: ISelectedQuery[];
  isApplied: boolean;
};

export default function AdvancedQueryModal({
  open,
  handleClose,
  handleApply,
  memoAdvancedQuery,
  memoSelectedQueryList,
  isApplied,
}: Props) {
  const history = useHistory();

  const { location: search } = history;
  const { datasetId, accountName } = useParams<{ accountName: string; datasetId: string }>();
  const { sliceInfo } = useSliceContext();
  const params = new URLSearchParams(search.search);
  const scope = (params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;
  const searchParams = new URLSearchParams(history.location.search);
  const view = searchParams.get('view') || 'grid';

  const { searchFieldMapping } = useDatasetContext();
  const { queryString, setQueryString } = useQueryContext();
  const { t } = useTranslation();
  const {
    advancedQuery,
    setAdvancedQuery,
    anchorEl,
    selectedQueryList,
    setAnchorEl,
    handleOpen,
    updateQuery,
    updateQueryGroup,
    deleteQuery,
    turnIntoFilter,
    wrapInGroup,
    updateOperator,
    updateQueryContent,
    setSelectedQueryList,
    handleQueryValue,
    duplicateQuery,
    handleReset,
  } = useAdvancedQuery();

  const { enqueueSnackbar } = useSnackbar();
  const [isOpenQueryWarning, setIsOpenQueryWarning] = useState<boolean>(
    isApplied && queryString !== convertString(advancedQuery),
  );

  const hasDateValueInSelectedQueryList = useMemo(
    () =>
      selectedQueryList.some(query => {
        return query.queryKeyType === 'Date';
      }),
    [selectedQueryList],
  );

  const [queryErrorInModal, setQueryErrorInModal] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const handleError = useCallback(() => {
    (async () => {
      const inputErrorList =
        selectedQueryList.length > 0
          ? selectedQueryList
              .map(list => {
                if (list.queryKeyType === 'Date') {
                  return RegexUtils.IS_DATE_TYPE(list.queryValue as string);
                } else if (list.queryKeyType === 'String' || list.queryKeyType === 'Categorical') {
                  return true;
                } else if (list.queryKeyType === 'Number') {
                  return RegexUtils.IS_NUMBER_TYPE(list.queryValue as string);
                } else return false;
              })
              .reduce((acc, cur) => acc && cur)
          : true;

      if (!inputErrorList) {
        setQueryErrorInModal(t('curate.advancedQuery.errorMessages.invalidQuery'));
        setShowError(true);
      } else {
        if (convertString(advancedQuery).length > 0) {
          try {
            parse(convertString(advancedQuery), {
              searchScope: scope === IMAGE_SCOPE ? 'image' : 'annotation',
              aliasMapping: searchFieldMapping,
            });
            setQueryErrorInModal('');
            const result = convertString(advancedQuery);
            setQueryString(result);
            handleApply(result, advancedQuery, selectedQueryList);
          } catch (e) {
            // if (e instanceof Error) {
            //   setQueryErrorInModal(e?.message);
            // } else setQueryErrorInModal('Invalid query!');
            setQueryErrorInModal(t('curate.advancedQuery.errorMessages.incompleteQuery'));
            setShowError(true);
          }
        } else {
          if (selectedQueryList.length === 0) {
            setQueryErrorInModal(t('curate.advancedQuery.errorMessages.incompleteQuery'));
            setShowError(true);
          } else setQueryErrorInModal('');
        }
      }
    })();
  }, [
    advancedQuery,
    searchFieldMapping,
    setQueryErrorInModal,
    setShowError,
    setQueryString,
    queryErrorInModal,
    selectedQueryList,
    convertString,
    handleApply,
  ]);

  useEffect(() => {
    setAdvancedQuery({ ...memoAdvancedQuery });
    setSelectedQueryList(memoSelectedQueryList);
    if (isApplied) setIsOpenQueryWarning(queryString !== convertString(memoAdvancedQuery));
  }, [memoAdvancedQuery, memoSelectedQueryList]);

  return (
    <Modal
      open={open}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color={'primary-400'}>
            {t('curate.advancedQuery.button.queryBuilder')}
          </Typography>

          <Tooltip
            content={
              <Typography variant="m-regular" color="gray-300">
                <Trans t={t} i18nKey={'curate.advancedQuery.queryBuilder'}>
                  <>
                    With Query Builder, you can easily create complex queries to find the images you
                    need. Start by adding filters for metadata, annotations, and slices. Combine
                    filters using AND/OR operators to refine your search. Need help getting started?
                    Check out our{' '}
                  </>
                  <strong
                    style={{ fontWeight: '400', textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    documentation
                  </strong>
                </Trans>
              </Typography>
            }
            placement="bottom-start"
          >
            <IconButton color="black" icon={InfoCircleOutline} variant="text" />
          </Tooltip>
        </Box>
      }
      close={{
        onClose: () => handleClose(advancedQuery, selectedQueryList),
        canCloseWithExit: true,
      }}
      mainButton={{
        text: 'Apply',
        onClick: async () => {
          analyticsTracker.queryBuilderApplied({
            isSampleQuery: false,
            queryString,
            accountId: accountName,
            datasetId,
            sliceId: sliceInfo?.id,
            scope,
            viewType: view,
          });
          handleError();
        },
        color: 'primary',
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => handleClose(advancedQuery, selectedQueryList),
      }}
    >
      <Box pt={2.5} style={{ minWidth: '1200px' }}>
        <Box
          style={{ height: '600px' }}
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="gray-150"
        >
          <Box display="flex" height="100%">
            <Box
              height="100%"
              borderRight="1px solid"
              borderColor="gray-150"
              overflow="auto"
              style={{ flex: 1 }}
            >
              <Box display="flex" justifyContent="flex-end" my={1} mx={2}>
                <Button
                  variant="stroke"
                  color="gray"
                  disabled={convertString(advancedQuery).length < 2}
                  size="s"
                  onClick={handleReset}
                >
                  <Icon icon={Reset} />
                  {t('curate.advancedQuery.button.reset')}
                </Button>
              </Box>
              {isOpenQueryWarning && (
                <AlertBox
                  type="danger"
                  mx={2}
                  style={{ flex: 1 }}
                  onClose={() => setIsOpenQueryWarning(false)}
                >
                  {t('curate.advancedQuery.changeInfo')}
                </AlertBox>
              )}
              <CreateQueryTab
                advancedQuery={advancedQuery}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleOpen={handleOpen}
                updateQuery={updateQuery}
                updateQueryGroup={updateQueryGroup}
                deleteQuery={deleteQuery}
                turnIntoFilter={turnIntoFilter}
                wrapInGroup={wrapInGroup}
                updateOperator={updateOperator}
                updateQueryContent={updateQueryContent}
                selectedQueryList={selectedQueryList}
                setSelectedQueryList={setSelectedQueryList}
                handleQueryValue={handleQueryValue}
                duplicateQuery={duplicateQuery}
              />
            </Box>
            <Box style={{ width: '412px', height: '600px' }} display="flex" flexDirection="column">
              <Box display="flex" justifyContent="flex-end" my={1} mx={2}>
                <Button
                  variant="stroke"
                  color="gray"
                  size="s"
                  onClick={() => {
                    window.navigator.clipboard.writeText(convertString(advancedQuery)).then(() => {
                      enqueueSnackbar('Completed copy', {
                        variant: 'success',
                        anchorOrigin: { horizontal: 'right', vertical: 'top' },
                      });
                    });
                  }}
                >
                  <Icon icon={Copy} />
                  {t('curate.advancedQuery.button.copy')}
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                my={1}
                mx={2}
                style={{ flex: 1 }}
              >
                <Box>
                  {(advancedQuery.query && advancedQuery.query.length > 0) ||
                  (advancedQuery.children && advancedQuery.children.length > 0) ? (
                    <Typography width="100%" style={{ wordBreak: 'break-all' }}>
                      {convertString(advancedQuery)}
                    </Typography>
                  ) : (
                    <Typography variant="m-regular" color="gray-300">
                      {t('curate.advancedQuery.noQueryYet')}{' '}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" gap={0.5} flexDirection="column">
                  {hasDateValueInSelectedQueryList && (
                    <AlertBox type="info" border="1px solid" style={{ maxHeight: '160px' }}>
                      {t('curate.advancedQuery.infoMessages.dateFormat')}
                    </AlertBox>
                  )}
                  {showError && queryErrorInModal && (
                    <AlertBox
                      type="danger"
                      border="1px solid"
                      style={{
                        maxHeight: '160px',
                      }}
                    >
                      {queryErrorInModal}
                    </AlertBox>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
