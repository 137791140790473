import { WorkApp } from '../../../../../union/WorkappUnion';

export const calSupportedWorkapps: WorkApp[] = [
  'image-default',
  'image-siesta',
  'video-siesta',
  'pointclouds-siesta',
];

export const curateSupportedWorkapps: WorkApp[] = ['image-siesta'];
