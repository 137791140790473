import React, { ComponentProps } from 'react';

import { Box, Input } from '@superb-ai/ui';

import { useCurateDatasetService } from '../../services/DatasetService';
import NameInput from './NameInput';

export default function SliceNameInput({
  sliceName,
  setSliceName,
  setIsValid,
  datasetId,
  ...otherProps
}: {
  sliceName: string;
  setSliceName: (name: string) => void;
  setIsValid: (isValid: boolean) => void;
  datasetId?: string | null;
  disabled?: boolean;
  placeholder?: string;
  boxProps?: ComponentProps<typeof Box>;
} & ComponentProps<typeof Input>) {
  const { getSearchSliceName } = useCurateDatasetService();

  return (
    <NameInput
      target="slice"
      name={sliceName}
      setName={setSliceName}
      onSearchName={async (name: string) => {
        if (!datasetId) return;
        await getSearchSliceName({ datasetId, name });
      }}
      setIsValid={setIsValid}
      {...otherProps}
    />
  );
}
