import React, { CSSProperties, useEffect, useRef } from 'react';

import * as d3 from 'd3';

import { DARK_GRAY } from '../../../../consts/ColorChips';
import { formatPercentGivenCountAndTotal } from '../config/d3Formatters';

interface YAxisProps {
  top: number; // top margin in pixels
  left: number; // left margin in pixels
  width: number;
  scale: d3.ScaleLinear<number, number, never>;
  isSecondAxis?: boolean;
  totalCounts?: number;
  yDisplayName?: string; // Display name for y Label (ex. Object Class or Assignee Name)
  labelFontWeight?: 'bold' | 'normal';
  textFontSize?: string;
  labelFontSize?: string;
  labelXOffset?: number;
  labelYOffset?: number;
  numTicks?: number;
}

const YAxis: React.FC<YAxisProps> = props => {
  const axis = useRef(null);
  const {
    top,
    left,
    width,
    scale,
    isSecondAxis,
    totalCounts,
    labelFontWeight,
    yDisplayName,
    labelFontSize,
    textFontSize,
    labelXOffset,
    labelYOffset,
    numTicks,
  } = props;

  useEffect(() => {
    d3.select(axis.current)
      .call(
        // @ts-ignore: this works
        (isSecondAxis === undefined
          ? d3.axisLeft(scale)
          : d3
              .axisRight(scale)
              .tickFormat(d =>
                formatPercentGivenCountAndTotal(d as number, totalCounts as number, true),
              )
        )
          .tickSize(-width)
          .ticks(numTicks || 5),
      )
      .call(g => g.selectAll('path').attr('stroke', 'lightgray'))
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke', 'lightgray')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-dasharray', '2,2'),
      )
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick text'));
  });

  const textStyle: CSSProperties = {
    fontSize: textFontSize || '12px',
    fontFamily: 'Inter',
    textAnchor: 'start',
    color: DARK_GRAY.color,
  };

  const labelStyle: CSSProperties = {
    fontFamily: 'Inter; sans-serif',
    fontSize: labelFontSize || '12px',
    fontWeight: labelFontWeight || 'bold',
    textAnchor: 'middle',
    color: DARK_GRAY.color,
  };

  const xTransform = isSecondAxis ? left + width : left;
  // centeredLabel text dx {-top - height / 2}

  const xLabelLoc = labelXOffset === undefined ? left + 27 : left + labelXOffset;
  const yLabelLoc = labelYOffset === undefined ? 19 : labelYOffset;

  return (
    <g>
      <g
        className="axis-y"
        style={textStyle}
        ref={axis}
        transform={`translate(${xTransform}, ${top})`}
      />
      {yDisplayName ? (
        <text style={labelStyle} dy={yLabelLoc} dx={xLabelLoc}>
          {yDisplayName}
        </text>
      ) : null}
    </g>
  );
};

export default YAxis;
