import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import { Box, Button, Icon, Modal, Typography } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import {
  AVATAR_UPLOAD_EXCEED_SIZE,
  FILE_UPLOAD_TYPE_NOT_SUPPORTED,
} from '../../consts/SnackbarMessage';
import { useAuthInfo } from '../../contexts/AuthContext';
import { useAuthService } from '../../services/NewAuthService';
import UserAvatar from './UserAvatar';

const HoverBox = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.palette.grey[400]};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.grey[50]};
  }
`;

const useStyles = makeStyles(() => ({
  editableWrap: {
    display: 'inline-flex',
    position: 'relative',
    '& .icon-wrap': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'none',
      background: '#00000099',
      borderRadius: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    '&:hover .icon-wrap': {
      display: 'inline-flex',
    },
  },
  onDrop: {
    borderRadius: '100%',
    boxShadow: '0 0 0 2px red',
  },
}));

interface Props extends React.ComponentProps<typeof UserAvatar> {
  uploadImage: (imageData: string) => Promise<string>;
  title: string;
  isPersonalAvatar?: boolean;
}

function readFileAsDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = error => reject(error);
  });
}

function getFileAsBase64(dataUrl: string): string {
  let encoded = dataUrl.toString().replace(/^data:(.*,)?/, '');
  if (encoded.length % 4 > 0) {
    encoded += '='.repeat(4 - (encoded.length % 4));
  }
  return encoded;
}

export default function EditableUserAvatar({
  uploadImage,
  title,
  isPersonalAvatar,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const authInfo = useAuthInfo();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAvatar } = useAuthService();

  async function handleFiles(files: File[]) {
    const file = files[0];
    if (!file) {
      enqueueSnackbar(FILE_UPLOAD_TYPE_NOT_SUPPORTED({ t }), { variant: 'warning' });
      return;
    }
    if (file.size > 1000000) {
      enqueueSnackbar(AVATAR_UPLOAD_EXCEED_SIZE({ t }), { variant: 'warning' });
      return;
    }
    if (file.type.split('/')[1] !== 'jpeg' && file.type.split('/')[1] !== 'png') {
      enqueueSnackbar(FILE_UPLOAD_TYPE_NOT_SUPPORTED({ t }), { variant: 'warning' });
      return;
    }
    const image = await readFileAsDataURL(file);
    setImage(image);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFiles,
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxSize: 1000000,
  });

  const [image, setImage] = useState<null | string>(null);

  useEffect(() => {
    setImage(props.userInfo.avatarUrl ?? null);
  }, [props.userInfo]);

  async function onConfirm() {
    if (image && image !== props.userInfo.avatarUrl) {
      setIsLoading(true);

      try {
        const url = await uploadImage(getFileAsBase64(image));
        setImage(url);
      } catch (e: any) {
        enqueueSnackbar('Upload failed', { variant: 'error' });
      } finally {
        setIsLoading(false);
        setIsOpen(false);
      }
    }
  }

  async function removeAvatar() {
    setIsLoading(true);

    try {
      await deleteAvatar();
      setImage(null);
      authInfo.setAvatarUrl(null);
    } catch (e: any) {
      enqueueSnackbar('Upload failed', { variant: 'error' });
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  }

  return (
    <>
      <div className={classes.editableWrap} onClick={() => setIsOpen(true)}>
        <UserAvatar
          {...{
            noShadow: true,
            color: '#fff',
            margin: 0,
            ...props,
          }}
        />
        <span className="icon-wrap">
          <Icon
            name="pencil"
            size={
              props.iconSize
                ? `${props.iconSize}px`
                : `${Math.min(24, Math.round(props.size / 1.6))}px`
            }
            color="background"
          />
          {props.iconSize && (
            <Box ml={0.5}>
              <Typography variant="body4" themedColor="backgroundColor">
                {t('button.edit')}
              </Typography>
            </Box>
          )}
        </span>
      </div>
      <Modal
        open={isOpen}
        title={
          <Typography variant="headline4" themedColor="primary">
            {title}
          </Typography>
        }
        mainButton={{
          text: t('button.save'),
          onClick: onConfirm,
          isLoading,
          disabled: !image,
          color: 'primary',
        }}
        subButton={{
          text: t('button.cancel'),
          onClick: () => setIsOpen(false),
        }}
        close={{
          onClose: () => setIsOpen(false),
          hasCloseButton: true,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" pt={5} px={4}>
          <UserAvatar
            {...{
              noShadow: true,
              color: '#fff',
              margin: 0,
              size: 100,
              userInfo: {
                ...props.userInfo,
                avatarUrl: image,
              },
            }}
          />
          <Box mb={2.5} />
          <HoverBox
            display="flex"
            width="100%"
            py={3.5}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            borderRadius
            {...(getRootProps() as Partial<React.ComponentProps<typeof Box>>)}
          >
            <Icon name="upload" size="16px" color={['grey', 400]} />
            <Typography variant="body3" textAlign="center" themedColor={['grey', 400]}>
              <Trans t={t} i18nKey="settings.profile.uploadFileDescription" />
            </Typography>
            <input {...getInputProps()} />
          </HoverBox>
          <Box mb={1} />
          {isPersonalAvatar && (
            <Button size="xs" color="backgroundColor" variant="strong-fill" onClick={removeAvatar}>
              {t('button.remove')}
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
}
