import React, { useEffect, useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';
import { TargetMetric } from '../components/datasets/dataset/views/analytics/AnalyticsContents';
import { stringifyFilters } from '../utils/filterUtils';
import { useDiagnosisGridFilterContext } from './DiagnosisGridFilterContext';
import { useQueryContext } from './QueryContext';
// prettier-ignore
export type SelectedData = { id: string; imageId?: string } & Record<string, any>;
export type DeselectedData = { id: string; imageId?: string } & Record<string, any>;

type ContextProps = StateGetterSetter<['selectedData', 'setSelectedData'], SelectedData[]> &
  StateGetterSetter<['deselectedData', 'setDeselectedData'], SelectedData[]> &
  StateGetterSetter<['semanticSearchData', 'setSemanticSearchData'], SelectedData[]> &
  StateGetterSetter<['selectedAllData', 'setSelectedAllData'], boolean> &
  StateGetterSetter<
    ['sampleThumbnailUrls', 'setSampleThumbnailUrls'],
    { id: string; thumbnailUrl: string }[]
  > &
  StateGetterSetter<['columnsCount', 'setColumnsCount'], number> &
  StateGetterSetter<['sortOrder', 'setSortOrder'], 'desc' | 'asc'> &
  StateGetterSetter<['sortBy', 'setSortBy'], string> &
  StateGetterSetter<['targetMetric', 'setTargetMetric'], TargetMetric>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [selectedData, setSelectedData] = useState<SelectedData[]>([]);
  const [semanticSearchData, setSemanticSearchData] = useState<SelectedData[]>([]);
  const [deselectedData, setDeselectedData] = useState<DeselectedData[]>([]);
  const [selectedAllData, setSelectedAllData] = useState<boolean>(false);
  const [sampleThumbnailUrls, setSampleThumbnailUrls] = useState<
    { id: string; thumbnailUrl: string }[]
  >([]);
  const defaultColumns = 5;
  // columnsCount, targetMetric may need other context
  const [columnsCount, setColumnsCount] = useState(defaultColumns);
  const [targetMetric, setTargetMetric] = useState<TargetMetric>('annotation');

  const DEFAULT_SORT_ORDER = 'desc';
  const DEFAULT_SORT_BY = 'created_at';

  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>(DEFAULT_SORT_ORDER);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);

  const { queryString } = useQueryContext();
  const { filterBody } = useDiagnosisGridFilterContext();
  const stringifiedDiagnosisFilter = stringifyFilters(filterBody);

  useEffect(() => {
    setSelectedData([]);
    setSelectedAllData(false);
  }, [queryString, stringifiedDiagnosisFilter]);

  return {
    selectedData,
    setSelectedData,
    deselectedData,
    setDeselectedData,
    selectedAllData,
    setSelectedAllData,
    sampleThumbnailUrls,
    setSampleThumbnailUrls,
    columnsCount,
    setColumnsCount,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    targetMetric,
    setTargetMetric,
    semanticSearchData,
    setSemanticSearchData,
  };
};

export const useActionContext = (): ContextProps => {
  return React.useContext(Context);
};

export const ActionProvider: React.FC = ({ children }) => {
  const datasetInfo = useProvider();
  return <Context.Provider value={datasetInfo}>{children}</Context.Provider>;
};
