import { mapKeys, pick, startCase } from 'lodash';

import { HistoryType } from '../../../../../types/exportTypes';
import { LabelInterface } from '../../../../../utils/LabelInterfaceUtils';
import ServiceUtils from '../../../../../utils/ServiceUtils';
import { JsonObj } from '../../userStats/types';
import { ComparisonReport, IoUSetting, ObjectCountsRowExcel } from '../types/types';
import {
  annotationsChangedTransfromer,
  getClassDisplayInfo,
  objectCountsTransformer,
  overallTrendTransformer,
} from './helper';

const toTitleCaseKeys = (data: JsonObj[]): JsonObj[] => {
  return data.map(obj => {
    return mapKeys(obj, (value, key) => startCase(key.replaceAll('_', ' ')));
  });
};

export const objectCountsDownload = (
  report: ComparisonReport,
  labelInterface: LabelInterface,
): JsonObj[] => {
  const transformed = objectCountsTransformer(report, labelInterface);
  const result: ObjectCountsRowExcel[] = transformed.map(row => {
    const result = {
      ...row,
      baseCount: row['queryCount'],
      basePercentage: row['queryPercentage'],
      compareCount: row['refCount'],
      comparePercentage: row['refPercentage'],
      changeInAnnotationCount: row['changes'],
    };
    return pick(result, [
      'className',
      'baseCount',
      'basePercentage',
      'compareCount',
      'comparePercentage',
      'changeInAnnotationCount',
    ]);
  });
  return toTitleCaseKeys(ServiceUtils.toSnakeCaseKeys(result));
};

export const metaDataDownload = (
  report: ComparisonReport,
  exportHistories: HistoryType[],
): [string, string | number | Date][] => {
  const refExport = exportHistories.find(
    history => history.id === report?.info.metadata.refExportId,
  );
  const queryExport = exportHistories.find(
    history => history.id === report?.info.metadata.queryExportId,
  );
  const formatIoU = (thresholds: IoUSetting[]) => {
    return thresholds.reduce((acc, cv) => {
      if (cv.annotationType === 'keypoint') return acc;
      if (acc) acc += ', ';
      return `${acc}${cv?.annotationType} (${cv.threshold})`;
    }, '');
  };
  const result = {
    'Report Name': report.name,
    Project: report.info.metadata.project.name,
    'Base Export': queryExport?.name,
    'Compare Export': refExport?.name,
    ...(report.info.metadata?.processedCount && {
      'Compared Label Count': report.info.metadata?.processedCount,
    }),
    'Requested By': report.createdBy, // TODO: show name
    Date: report.info.metadata.requestedDate, // TODO: format local date
    'IoU thresholds': formatIoU(report.info.metadata.iouThresholds),
  } as JsonObj;

  return Object.keys(result).map(k => {
    return [k, result[k]];
  });
};

export const overallTrendDownload = (report: ComparisonReport) => {
  const transformed = overallTrendTransformer(report);
  const header = {
    noChange: 'No Change',
    added: 'Added',
    deleted: 'Deleted',
    edited: 'Edited Shape',
    changedClass: 'Changed Class',
  };
  return [
    mapKeys(transformed, (value, key) => {
      return header[key as keyof typeof header];
    }),
  ];
};

export const annotationsChangedDownload = (
  report: ComparisonReport,
  labelInterface: LabelInterface,
) => {
  const classDisplayInfo = getClassDisplayInfo(labelInterface);
  const transformed = annotationsChangedTransfromer(report, classDisplayInfo).data;

  return transformed.map(row => {
    return {
      'Class Name': row.className || '',
      'No Change': row.noChange,
      Added: row.added,
      Deleted: row.deleted,
      'Edited Shape': row.edited,
      'Changed Class': row.changedClass,
    };
  });
};
