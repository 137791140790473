import { imageSize } from 'image-size';
import { findLastIndex, floor, gt, isNull, round } from 'lodash';

import ImageFilter from '../consts/ImageFilter';

interface Dimensions {
  width: number;
  height: number;
}
interface ImageContextInfo extends Dimensions {
  image: HTMLImageElement;
  x: number;
  y: number;
  rate: number;
  brightness: number;
  contrast: number;
}
interface ImageBlobInfo extends Dimensions {
  orientation?: number;
  type?: string;
}

const getImageSizeFromBlob = (file: Blob, bufferSize = 512 * 1024): Promise<ImageBlobInfo> => {
  return new Promise((resolve, reject) => {
    const header = file.slice(0, bufferSize);
    const reader = new FileReader();
    reader.onload = () => {
      if (!reader.result || typeof reader.result === 'string') return;
      const buffer = Buffer.from(new Uint8Array(reader.result)) as any;
      try {
        const dimensions = imageSize(buffer);
        if (typeof dimensions?.width !== 'undefined' && typeof dimensions.height !== 'undefined') {
          resolve(dimensions as ImageBlobInfo);
        } else {
          throw new Error('could not determine image size');
        }
      } catch (e: any) {
        reject(e);
      }
    };
    reader.readAsArrayBuffer(header);
  });
};

const getImageSizeOnCanvas = (width: number, height: number, rate: number): Dimensions => ({
  width: width * rate,
  height: height * rate,
});

const getInitImageContextInfo = (
  image: HTMLImageElement,
  stageInfo: Dimensions,
  labelDetailViewInfo: { label: any },
): ImageContextInfo => {
  const { width, height } = image;
  const { label } = labelDetailViewInfo;

  if (isNull(stageInfo)) {
    return {
      image,
      x: 0,
      y: 0,
      width,
      height,
      rate: 100,
      brightness: ImageFilter.INIT_BRIGHTNESS,
      contrast: ImageFilter.INIT_CONTRAST,
    };
  }

  const rate = gt(width, height)
    ? round((stageInfo.width - 20) / width, 2)
    : round((stageInfo.height - 20) / height, 2);

  const initBrightness = (): number => {
    if (label?.info?.imageInfo?.brightness) {
      const {
        info: {
          imageInfo: { brightness },
        },
      } = label;
      ImageFilter.INIT_BRIGHTNESS = brightness;
    } else {
      ImageFilter.INIT_BRIGHTNESS = 0;
    }

    return ImageFilter.INIT_BRIGHTNESS;
  };

  const initContrast = (): number => {
    if (label?.info?.imageInfo?.contrast) {
      const {
        info: {
          imageInfo: { contrast },
        },
      } = label;
      ImageFilter.INIT_CONTRAST = contrast;
    } else {
      ImageFilter.INIT_CONTRAST = 0;
    }

    return ImageFilter.INIT_CONTRAST;
  };

  return {
    image,
    x: floor((stageInfo.width - 20 - width * rate) / 2) + 10,
    y: floor((stageInfo.height - 20 - height * rate) / 2) + 10,
    width,
    height,
    rate,
    brightness: initBrightness(),
    contrast: initContrast(),
  };
};

const getFormat = (name: string): string => {
  const index = findLastIndex(name, char => char === '.');
  return name.slice(index + 1);
};

const loadImage = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', () => {
      reject(new Error(`Failed to load image's URL: ${url}`));
    });
    img.src = url;
  });
};

const loadImages = async (images: Array<any>): Promise<void> => {
  for (let i = 0; i < images.length; i++) {
    const headers: Record<string, string> = {
      mode: 'cors',
      credentials: 'omit',
    };

    if (
      !images[i].includes('suite-asset.superb-ai.com') &&
      !images[i].includes('stage-saba-asset.superb-ai.com') &&
      !images[i].includes('suite-asset.dev.superb-ai.com')
    ) {
      headers.cache = 'no-cache';
    }

    fetch(images[i], headers)
      .then(() => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = images[i];
      })
      // eslint-disable-next-line
      .catch(err => {});
  }
};

export default {
  getImageSizeFromBlob,
  getImageSizeOnCanvas,
  getInitImageContextInfo,
  getFormat,
  loadImage,
  loadImages,
};
