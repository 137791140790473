import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { overflowOverlayOrAuto } from '../../../../utils/style';
import CabinetAnnotationType from './CabinetAnnotationType';
import CabinetClassList from './CabinetClassList';
import CabinetLabelInformation from './CabinetLabelInformation';

const useStyles = makeStyles(() => ({
  box: {
    flexGrow: 1,
    background: '#fff',
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px 6px 0px 0px',
  },
  footerButtonBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '90px',
    width: '100%',
  },
  // cabinet
  cabinetHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cabinetTitle: {
    fontSize: '13px',
    color: '#212121',
    fontWeight: 500,
  },
  cabinetSwitchLabel: {
    fontSize: '10px',
    color: '#635c5c',
  },
  cabinetContent: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: overflowOverlayOrAuto(),
    overflowX: 'hidden',
  },
}));

const CabinetInformation = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <MUI.Box className={classes.box}>
      <CabinetAnnotationType cabinetStyles={classes} />
      <MUI.Divider />
      <CabinetClassList cabinetStyles={classes} />
      <MUI.Divider />
      <CabinetLabelInformation cabinetStyles={classes} />
    </MUI.Box>
  );
};

export default CabinetInformation;
