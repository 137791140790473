import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { AssetDataType } from '../apps/projects/project/data/assetDataTypes';
import {
  CloudUploadContextProps,
  DEFAULT_UPLOAD_METHOD,
  UploadMethod,
} from '../components/elements/newUpload/types';
import { SourceIntegrationTarget } from '../components/pages/account/integrations/types';
import { FileWithPath } from '../utils/FileUtils';
import { StateGetterSetter } from './types';

export type CloudSaveMethod = 'copy' | 'link';

export type CsvUploadMethod = 'URL' | 'CSV';
export interface CloudParams {
  integrationId: string;
  prefix: string;
  assetSaveMethod: CloudSaveMethod;
  type: SourceIntegrationTarget;
  manifestFileName: string | null;
}

export interface CsvFormat {
  dataKey: string;
  url: string;
}

// prettier-ignore
export type UploadContextProps =
  StateGetterSetter<['isUploadModalOpen', 'setIsUploadModalOpen'], boolean> &
  StateGetterSetter<['isStepsCompleted', 'setIsStepsCompleted'], boolean> &
  StateGetterSetter<['uploadMethod', 'setUploadMethod'], UploadMethod> &
  StateGetterSetter<['files', 'setFiles'], FileWithPath[]> &
  StateGetterSetter<['urls', 'setUrls'], CsvFormat[]> &
  StateGetterSetter<['dataset', 'setDataset'], string | undefined> &
  StateGetterSetter<['selectedProject', 'setSelectedProject'], { name?: string; id: string } | null> &
  CloudUploadContextProps &
  StateGetterSetter<['dataType', 'setDataType'], AssetDataType | null> &
  StateGetterSetter<['csvUploadMethod', 'setCsvUploadMethod'], CsvUploadMethod> &
  StateGetterSetter<['videoFramesSourceData', 'setVideoFramesSourceData'], 'image-sequence' | 'video'> &
  StateGetterSetter<['fps', 'setFps'], 'custom' | number> &
  StateGetterSetter<['customFps', 'setCustomFps'], number> &
  StateGetterSetter<['manifestFileName', 'setManifestFileName'], string | null> &
  StateGetterSetter<['cloudSaveMethod', 'setCloudSaveMethod'], CloudSaveMethod> &
  { initialize: () => void };

export const UploadContext = React.createContext({} as UploadContextProps);

export const UploadProvider: React.FC = ({ children }) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isStepsCompleted, setIsStepsCompleted] = useState(false);
  const [uploadMethod, setUploadMethod] = useState<UploadMethod>(DEFAULT_UPLOAD_METHOD);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [dataType, setDataType] = useState<AssetDataType | null>(null);
  const [urls, setUrls] = useState<CsvFormat[]>([]);
  const [dataset, setDataset] = useState<string>();
  const [selectedProject, setSelectedProject] = useState<{ name?: string; id: string } | null>(
    null,
  );
  const [fps, setFps] = useState<'custom' | number>(1);
  const [customFps, setCustomFps] = useState(1);
  const [videoFramesSourceData, setVideoFramesSourceData] = useState<'video' | 'image-sequence'>(
    'video',
  );
  const [cloudStorageType, setCloudStorageType] = useState('S3' as SourceIntegrationTarget);
  const [cloudIntegrationId, setCloudIntegrationId] = useState<string | null>(null);
  const [cloudPrefix, setCloudPrefix] = useState<string | null>(null);
  const [cloudSaveMethod, setCloudSaveMethod] = useState('copy' as CloudSaveMethod);
  const [cloudBucketName, setCloudBucketName] = useState('');
  const [integratedCloudPrefix, setIntegratedCloudPrefix] = useState<string | null>(null);
  const [csvUploadMethod, setCsvUploadMethod] = useState('URL' as CsvUploadMethod);
  const [manifestFileName, setManifestFileName] = useState<string | null>(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '#upload') {
      setIsUploadModalOpen(true);
    }
  }, [hash]);

  const initialize = () => {
    setIsStepsCompleted(false);
    setUploadMethod(DEFAULT_UPLOAD_METHOD);
    setFiles([]);
    setDataType(null);
    setUrls([]);
    setDataset('');
    setSelectedProject(null);
    setFps(1);
    setCustomFps(1);
    setVideoFramesSourceData('video');
    setCloudStorageType('S3' as SourceIntegrationTarget);
    setCloudIntegrationId(null);
    setCloudPrefix(null);
    setCloudSaveMethod('copy' as CloudSaveMethod);
    setCloudBucketName('');
    setIntegratedCloudPrefix(null);
    setCsvUploadMethod('URL' as CsvUploadMethod);
    setManifestFileName(null);
  };

  return (
    <UploadContext.Provider
      value={{
        initialize,
        isUploadModalOpen,
        setIsUploadModalOpen,
        isStepsCompleted,
        setIsStepsCompleted,
        uploadMethod,
        setUploadMethod,
        files,
        setFiles,
        urls,
        setUrls,
        dataset,
        setDataset,
        selectedProject,
        setSelectedProject,
        dataType,
        setDataType,
        fps,
        setFps,
        customFps,
        setCustomFps,
        videoFramesSourceData,
        setVideoFramesSourceData,
        cloudStorageType,
        setCloudStorageType,
        cloudIntegrationId,
        setCloudIntegrationId,
        cloudPrefix,
        setCloudPrefix,
        cloudSaveMethod,
        setCloudSaveMethod,
        cloudBucketName,
        setCloudBucketName,
        integratedCloudPrefix,
        setIntegratedCloudPrefix,
        csvUploadMethod,
        setCsvUploadMethod,
        manifestFileName,
        setManifestFileName,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadInfo = (): UploadContextProps => {
  return React.useContext(UploadContext);
};
