import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Paragraph } from '@superb-ai/ui';
import Image from 'next/image';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { getUrl } from '../../../routes/util';

export function TrainModelDialogSuccess() {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { accountName } = routeInfo.urlMatchInfo;

  return (
    <Box
      position="fixed"
      top="0"
      bottom="0"
      left="0"
      right="0"
      style={{ zIndex: 1200 }}
      backgroundColor="white"
      p={5}
    >
      <Box mx="auto" display="flex" flexDirection="column" height="100%" style={{ width: 824 }}>
        <Paragraph variant="h2" mb={2} ml={2} display="flex">
          {t('model.train.title')}
        </Paragraph>

        <Box
          display="grid"
          placeItems="center"
          placeContent="center"
          style={{ flex: '100%' }}
          gap={2}
          border="1px solid"
          borderColor="gray-150"
          p={4}
        >
          <Paragraph variant="h1" textAlign="center">
            {t('model.train.trainingStarted')} 🤩
          </Paragraph>
          <Paragraph textAlign="center">
            <Trans t={t} i18nKey="model.train.successText" />
          </Paragraph>
          <Box mb={2}>
            <Image width="302" height="226" src={'/static/image/success.png'} alt="" />
          </Box>
          <Button
            size="l"
            variant="strong-fill"
            color="primary"
            {...({ as: Link, to: getUrl([accountName, 'model', 'models', 'list']) } as any)}
          >
            {t('model.train.viewTrainingProgress')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
