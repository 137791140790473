import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '@superb-ai/ui';
import { chunk, filter, find, includes, isEmpty, trim } from 'lodash';
import { useSnackbar } from 'notistack';

import { COLOR_CHIP_VARIATION_PALETTE, randomColor } from '../../../../consts/ColorChips';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService, { LabelTagStatus } from '../../../../services/LabelsService';
import ProjectService from '../../../../services/ProjectService';
import { LabelTag } from '../../../../types/labelTypes';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import EditTagsDialogEditableSelectItem from './EditTagsDialogEditableSelectItem';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '5px 10px',
    width: '330px',
  },
  textField: {
    width: '100%',
  },
  noList: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

interface Props {
  params: any;
  tagsToAdd: any;
  setTagsToAdd: Dispatch<SetStateAction<string[]>>;
  tagsToRemove: any;
  setTagsToRemove: any;
  setIsIncludedTagDeleted: any;
}

const EditTagsDialogEditableSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    params,
    tagsToAdd,
    setTagsToAdd,
    tagsToRemove,
    setTagsToRemove,
    setIsIncludedTagDeleted,
  } = props;

  const { t } = useTranslation();
  const [isLoadingLabelsTags, setIsLoadingLabelsTags] = useState(false);
  const [isLoadingProjectTags, setIsLoadingProjectTags] = useState(false);
  const [input, setInput] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [tagStatus, setTagStatus] = useState<Record<string, LabelTagStatus['status']>>({});
  const [resultTags, setResultTags] = useState<LabelTag[]>([]);
  const [helperText, setHelperText] = useState('');

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);

  const role = authInfo.projectRole || authInfo.role;
  const isCanEditTag = !(UserRoleUnion.isWorker(role) || UserRoleUnion.isReviewer(role));

  const getTagsInLabels = async (chuckedParams?: Record<string, string[]>) => {
    setIsLoadingLabelsTags(true);
    const paramsForApi = chuckedParams || params;

    try {
      const TAG_SPLICE_COUNT = 10;
      const promises: ReturnType<typeof LabelsService.getLabelTagStatus>[] = [];
      const tagIds = Object.values(projectInfo.tagIds);

      for (let i = 0; i < tagIds.length; i += TAG_SPLICE_COUNT) {
        promises.push(
          LabelsService.getLabelTagStatus({
            projectId: routeInfo.urlMatchInfo.projectId,
            params: { ...paramsForApi, tagIdIn: tagIds.slice(i, i + TAG_SPLICE_COUNT) },
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          }),
        );
      }

      const tagStatus = [...(await Promise.all(promises)).flatMap(({ results }) => results)];
      setTagStatus(Object.fromEntries(tagStatus.map(({ tagId, status }) => [tagId, status])));
    } finally {
      setIsLoadingLabelsTags(false);
    }
  };

  const getTagsInProject = async () => {
    projectInfo.setTags([]);
    setIsLoadingProjectTags(true);
    try {
      await projectInfo.updateTags(routeInfo.urlMatchInfo.projectId);
      setIsLoadingProjectTags(false);
    } catch (error: any) {
      setIsLoadingProjectTags(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setResultTags(projectInfo.tags);
    (async () => {
      // should divide params if param array size is bigger than 50
      if (params.idIn && params.idIn.length > 50) {
        const chunkedIds = chunk(params.idIn, 50);
        chunkedIds.map(async chunk => {
          await getTagsInLabels({ ...params, idIn: chunk });
        });
        return;
      }
      await getTagsInLabels();
    })();

    // eslint-disable-next-line
  }, [projectInfo.tags]);

  const handleCreateTag = async (input: string) => {
    const tagInfo = {
      name: trim(input),
      info: {
        color: randomColor(COLOR_CHIP_VARIATION_PALETTE),
      },
    };

    await ProjectService.createTag({
      projectId: routeInfo.urlMatchInfo.projectId,
      tagInfo,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    await getTagsInProject();
  };

  const handleClickDeleteTag = async (tagId: string) => {
    try {
      await ProjectService.deleteTag({
        projectId: routeInfo.urlMatchInfo.projectId,
        tagId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      await getTagsInProject();
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(t('labels.editTag.tagDoesNotExist'), { variant: 'error' });
      } else if (err.message === 'Precondition Required') {
        enqueueSnackbar(t('labels.editTag.tagRemoveRule'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`${err}`, { variant: 'error' });
      }
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const trimValue = trim(value);

    const isLengthValid = trimValue.length >= 1 && trimValue.length <= 20;

    const resultTags = filter(projectInfo.tags, tag => {
      const { name } = tag;
      return name.includes(value);
    });

    if (!isLengthValid) {
      setHelperText(t('labels.editTag.tagNamingRule'));
    } else {
      setHelperText('');
    }
    setResultTags(resultTags);
    setInput(value);
  };

  const handleInputKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (
      isCanEditTag &&
      event.key === 'Enter' &&
      !find(resultTags, tag => tag.name === input) &&
      helperText === ''
    ) {
      handleCreateTag(input);
      setInput('');
    }
  };

  const getSubheader = () => {
    if (isEmpty(input)) {
      return t('labels.editTag.allTags');
    }

    if (helperText === '' && isCanEditTag) {
      if (isEmpty(resultTags)) return `${t('labels.editTag.pressEnterToCreate')} "${input}"`;
      else
        return `${t('labels.editTag.searchResults')} | ${t(
          'labels.editTag.pressEnterToCreate',
        )} "${input}"`;
    } else if (helperText === '') {
      if (!isEmpty(resultTags)) return t('labels.editTag.searchResults');
    }

    return helperText;
  };

  return (
    <>
      <MUI.Paper className={classes.paper}>
        <Input
          value={input}
          onChange={handleChangeInput}
          onKeyPress={handleInputKeyPress}
          ref={inputRef}
          placeholder={t('labels.editTag.searchInputPlaceholder')}
          disabled={isLoadingLabelsTags || isLoadingProjectTags}
        />
      </MUI.Paper>
      <MUI.Box mt={1} height="200px" overflow="auto">
        {isLoadingLabelsTags || isLoadingProjectTags ? (
          <MUI.Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <MUI.CircularProgress />
          </MUI.Box>
        ) : (
          <MUI.List>
            <MUI.ListSubheader>{getSubheader()}</MUI.ListSubheader>
            {isEmpty(projectInfo.tags) ? (
              <MUI.Typography className={classes.noList} variant="body2" color="textSecondary">
                {t('labels.editTag.noOptions')}
              </MUI.Typography>
            ) : (
              resultTags.map(tag => {
                return (
                  <EditTagsDialogEditableSelectItem
                    key={tag.id}
                    tag={tag}
                    isTagAdded={includes(tagsToAdd, tag.id)}
                    isIncluded={tagStatus[tag.id] === 'ALL'}
                    isPartiallyIncluded={tagStatus[tag.id] === 'PARTIAL'}
                    onClickDeleteTag={handleClickDeleteTag}
                    showDeleteConfirm={showDeleteConfirm}
                    setShowDeleteConfirm={setShowDeleteConfirm}
                    tagsToAdd={tagsToAdd}
                    setTagsToAdd={setTagsToAdd}
                    tagsToRemove={tagsToRemove}
                    setTagsToRemove={setTagsToRemove}
                    setIsIncludedTagDeleted={setIsIncludedTagDeleted}
                    isCanEditTag={isCanEditTag}
                  />
                );
              })
            )}
          </MUI.List>
        )}
      </MUI.Box>
    </>
  );
};

export default EditTagsDialogEditableSelect;
