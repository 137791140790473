import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import * as MUI from '@mui/material';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectsService from '../../../services/ProjectsService';
import { Params } from './path';

const URL_MAGIC_LATEST = '@latest';

export default function ProjectPage({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();
  const match = useRouteMatch<Params>();
  const { history } = routeInfo;

  async function getLatestProjectId(): Promise<null | string> {
    const params = {
      pageSize: 1,
    };
    const { results } = await ProjectsService.getProjects({
      params,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    return results[0]?.id ?? null;
  }

  useEffect(() => {
    if (match.params.projectId === URL_MAGIC_LATEST) {
      getLatestProjectId().then(latestProjectId => {
        const nextLocation = latestProjectId
          ? {
              ...history.location,
              pathname: history.location.pathname.replace(URL_MAGIC_LATEST, latestProjectId),
            }
          : `/${authInfo.accountName}`;
        history.replace(nextLocation);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.projectId, authInfo.isGuest]);

  const projectId = routeInfo.urlMatchInfo.projectId;
  if (
    !projectInfo.project ||
    match.params.projectId !== projectId ||
    match.params.projectId === URL_MAGIC_LATEST
  )
    return <MUI.LinearProgress />;

  return <>{children}</>;
}
