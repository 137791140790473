import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../../../../consts/SnackbarMessage';
import FileUtils from '../../../../../../../../utils/FileUtils';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { CurateAnnotation, CurateImageData } from '../../../../../../services/DatasetService';
import { FormattedAnnotation } from '../../../../../../types/detailViewTypes';
import AnnotationCollapse from '../AnnotationCollapse';
import AnnotationsCabinetCollapse from '../AnnotationsCabinetCollapse';
import ImageInformation from '../detailCabinet/ImageInformation';
import ImageMetadata from '../detailCabinet/ImageMetadata';
import ImageProperties from '../detailCabinet/ImageProperties';
import SyntheticImageInfo from '../detailCabinet/SyntheticImageInfo';
import { excludedAnnotationMetadataKeys } from '../image/const';
import DetailModalCabinetCollapse from '../image/DetailModalCabinetCollapse';

type Props = {
  imageInfo: CurateImageData;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  objectAnnotationId: string;
  clickedAnnotation: CurateAnnotation | null;
  handleSelectImage: () => void;
  closeModal: () => void;
};

export default function ObjectDetailModalCabinet({
  imageInfo,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  objectAnnotationId,
  clickedAnnotation,
  handleSelectImage,
  closeModal,
}: Props) {
  const { t } = useTranslation();
  const [copyButtonVisible, setCopyButtonVisible] = useState<
    'image_key' | 'image_id' | 'image_url'
  >();
  const { enqueueSnackbar } = useSnackbar();
  const { showPublicDatasets } = usePublicDatasetContext();
  if (!imageInfo) return <></>;

  const metadataEntries = Object.entries(imageInfo.metadata);
  const formattedAnnotations =
    imageInfo.annotations &&
    !!imageInfo.annotations.length &&
    imageInfo.annotations.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
      const key = `${annotation.annotation_class}-${annotation.annotation_type}`;
      return {
        ...acc,
        [key]: {
          type: annotation.annotation_type,
          id: annotation.id,
          name: annotation.annotation_class,
          count: (acc[key]?.count || 0) + 1,
          annotations: [
            ...(acc[key]?.annotations.length > 0 ? acc[key]?.annotations : []),
            {
              id: annotation.id,
              annotation_class: annotation.annotation_class,
              annotation_type: annotation.annotation_type,
              _width: annotation.roi?.width ? Math.floor(annotation.roi?.width) : 0,
              _height: annotation.roi?.height ? Math.floor(annotation.roi?.height) : 0,
              ...annotation?.metadata,
              ...(!showPublicDatasets && {
                created_at: annotation.created_at,
                created_by: annotation.created_by,
              }),
            },
          ],
        },
      };
    }, {});
  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const handleClickCopy = () => {
    if (!copyButtonVisible) return;
    FileUtils.copyToClipboard({
      value: {
        image_key: imageInfo.key,
        image_id: imageInfo.id,
        image_url: imageInfo.image_url,
      }[copyButtonVisible],
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label: startCase(copyButtonVisible) }), {
      variant: 'success',
    });
  };

  return (
    <Box pl={2} overflow="auto" height="100%" style={{ width: 288 }}>
      {!!formattedAnnotations && objectAnnotationId && (
        <DetailModalCabinetCollapse title="Annotations">
          <Box display="flex" flexDirection="column" backgroundColor="gray-100">
            <>
              {Object.values(formattedAnnotations)
                .filter(list => {
                  const ids = list?.annotations.map(list => list.id);
                  return ids.includes(objectAnnotationId);
                })
                .map((annotation, index) => (
                  <AnnotationsCabinetCollapse
                    index={index}
                    metadataEntries={metadataEntries}
                    annotation={annotation}
                    filteredAnnotationsIds={filteredAnnotationsIds}
                    setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                    key={annotation.id}
                    objectAnnotationId={objectAnnotationId}
                    clickedAnnotation={clickedAnnotation}
                  >
                    <>
                      {annotation.annotations
                        .filter(list => list.id === objectAnnotationId)
                        .map(list => {
                          const keys = Object.keys(list).filter(
                            listName => !excludedAnnotationMetadataKeys.includes(listName),
                          );
                          return (
                            <AnnotationCollapse
                              annotation={list}
                              key={list.id}
                              filteredAnnotationsIds={filteredAnnotationsIds}
                              setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                              objectAnnotationId={objectAnnotationId}
                              clickedAnnotation={clickedAnnotation}
                              handleSelectImage={handleSelectImage}
                            >
                              <Box display="flex" flexDirection="column">
                                {keys.map((key, index) => (
                                  <Box
                                    key={list.id + key}
                                    display="flex"
                                    alignItems="center"
                                    p={1}
                                    pl={3}
                                    pt={index === 0 ? 0 : 1}
                                    backgroundColor={
                                      list.id === clickedAnnotation?.id ? 'primary-100' : 'white'
                                    }
                                  >
                                    <Box pl={1.5} style={{ ...textEllipsisStyle, width: '50%' }}>
                                      <Typography
                                        variant="m-medium"
                                        color={
                                          filteredAnnotationsIds.includes(list.id)
                                            ? 'gray-300'
                                            : 'gray-400'
                                        }
                                      >
                                        {key}
                                      </Typography>
                                    </Box>
                                    <Box style={{ ...textEllipsisStyle, width: '50%' }}>
                                      <Typography
                                        variant="m-regular"
                                        color={
                                          filteredAnnotationsIds.includes(list.id)
                                            ? 'gray-300'
                                            : 'gray-400'
                                        }
                                      >
                                        {list[key]}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </AnnotationCollapse>
                          );
                        })}
                    </>
                    <>
                      {annotation.annotations
                        .filter(list => list.id !== objectAnnotationId)
                        .map(list => {
                          const keys = Object.keys(list).filter(
                            listName =>
                              !(
                                listName === 'id' ||
                                listName === 'annotation_class' ||
                                listName === 'annotation_type'
                              ),
                          );
                          return (
                            <AnnotationCollapse
                              annotation={list}
                              key={list.id}
                              filteredAnnotationsIds={filteredAnnotationsIds}
                              setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                              objectAnnotationId={objectAnnotationId}
                              clickedAnnotation={clickedAnnotation}
                              handleSelectImage={handleSelectImage}
                            >
                              <Box display="flex" flexDirection="column">
                                {keys.map((key, index) => (
                                  <Box
                                    key={list.id + key}
                                    display="flex"
                                    alignItems="center"
                                    p={1}
                                    pl={3}
                                    pt={index === 0 ? 0 : 1}
                                  >
                                    <Box pl={1.5} style={{ ...textEllipsisStyle, width: '50%' }}>
                                      <Typography
                                        variant="m-medium"
                                        color={
                                          filteredAnnotationsIds.includes(list.id)
                                            ? 'gray-300'
                                            : 'gray-400'
                                        }
                                      >
                                        {key}
                                      </Typography>
                                    </Box>
                                    <Box style={{ ...textEllipsisStyle, width: '50%' }}>
                                      <Typography
                                        variant="m-regular"
                                        color={
                                          filteredAnnotationsIds.includes(list.id)
                                            ? 'gray-300'
                                            : 'gray-400'
                                        }
                                      >
                                        {list[key]}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </AnnotationCollapse>
                          );
                        })}
                    </>
                  </AnnotationsCabinetCollapse>
                ))}
            </>
            <>
              {Object.values(formattedAnnotations)
                .filter(list => {
                  const ids = list?.annotations.map(list => list.id);
                  return !ids.includes(objectAnnotationId);
                })
                .map((annotation, index) => (
                  <AnnotationsCabinetCollapse
                    index={index}
                    metadataEntries={metadataEntries}
                    annotation={annotation}
                    filteredAnnotationsIds={filteredAnnotationsIds}
                    setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                    key={annotation.id}
                    objectAnnotationId={objectAnnotationId}
                    clickedAnnotation={clickedAnnotation}
                  >
                    {annotation.annotations.map(list => {
                      const keys = Object.keys(list).filter(
                        listName =>
                          !(
                            listName === 'id' ||
                            listName === 'annotation_class' ||
                            listName === 'annotation_type'
                          ),
                      );
                      return (
                        <AnnotationCollapse
                          annotation={list}
                          key={list.id}
                          filteredAnnotationsIds={filteredAnnotationsIds}
                          setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                          objectAnnotationId={objectAnnotationId}
                          clickedAnnotation={clickedAnnotation}
                          handleSelectImage={handleSelectImage}
                        >
                          <Box display="flex" flexDirection="column">
                            {keys.map((key, index) => (
                              <Box
                                key={list.id + key}
                                display="flex"
                                alignItems="center"
                                p={1}
                                pl={3}
                                pt={index === 0 ? 0 : 1}
                              >
                                <Box pl={1.5} style={{ ...textEllipsisStyle, width: '50%' }}>
                                  <Typography
                                    variant="m-medium"
                                    color={
                                      filteredAnnotationsIds.includes(list.id)
                                        ? 'gray-300'
                                        : 'gray-400'
                                    }
                                  >
                                    {key}
                                  </Typography>
                                </Box>
                                <Box style={{ ...textEllipsisStyle, width: '50%' }}>
                                  <Typography
                                    variant="m-regular"
                                    color={
                                      filteredAnnotationsIds.includes(list.id)
                                        ? 'gray-300'
                                        : 'gray-400'
                                    }
                                  >
                                    {list[key]}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </AnnotationCollapse>
                      );
                    })}
                  </AnnotationsCabinetCollapse>
                ))}
            </>
          </Box>
        </DetailModalCabinetCollapse>
      )}
      <ImageProperties imageInfo={imageInfo} />
      <SyntheticImageInfo imageInfo={imageInfo} closeModal={closeModal} />
      <ImageMetadata imageInfo={imageInfo} />
      {!showPublicDatasets && <ImageInformation imageInfo={imageInfo} />}
    </Box>
  );
}
