import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Chip, OverflowItemCount, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import cn from 'classnames';
import { startCase } from 'lodash';

import LabelInterfaceUtils, { Category } from '../../../../../../utils/LabelInterfaceUtils';

const useStyles = makeStyles({
  optionsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    lineHeight: 1,
    transition: 'all .2s',

    '&.collapsed': {
      maxHeight: '1.1em',
      overflow: 'hidden',
    },
    '&.expanded': {
      maxHeight: '200px',
      '& .expandBtn': {
        display: 'none',
      },
    },
  },
});

export default function CategoryRow({
  item,
  disabled,
  formatMatch = text => text,
}: {
  item: Category;
  disabled?: boolean;
  formatMatch?: (text: string, format: (text: string) => React.ReactNode) => React.ReactNode;
}): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const optionsRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const options = LabelInterfaceUtils.getLeafNodes(item.options ?? []);

  const boxProps = {
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '1fr 2fr 100px',
    width: '100%',
  };

  const content = (
    <>
      <Box my={0.25}>
        <Typography variant="headline7">
          {formatMatch(item.name, m => (
            <span style={{ background: '#5a7bff', color: '#fff' }}>{m}</span>
          ))}
        </Typography>
      </Box>

      <div
        ref={optionsRef}
        className={cn(classes.optionsBox, isExpanded ? 'expanded' : 'collapsed')}
      >
        <OverflowItemCount
          container={optionsRef}
          maxLines={1}
          overflowLabel={count => (
            <Typography
              as="span"
              variant="body4"
              style={{ marginLeft: 6 }}
              className="expandBtn"
              onClick={e => {
                setIsExpanded(true);
                e.stopPropagation();
              }}
            >
              +{count}
            </Typography>
          )}
        >
          {options.map((option, idx) => (
            <Typography as="span" variant="body3" key={idx}>
              {formatMatch(option.name, m => (
                <span style={{ background: '#5a7bff', color: '#fff' }}>{m}</span>
              ))}
            </Typography>
          ))}
          {isExpanded && (
            <Typography
              as="span"
              variant="body4"
              style={{ marginLeft: 6 }}
              onClick={e => {
                setIsExpanded(false);
                e.stopPropagation();
              }}
            >
              {t('shared.close')}
            </Typography>
          )}
        </OverflowItemCount>
      </div>

      <Box display="flex" justifyContent="flex-end">
        <Chip color="grey">{startCase(item.type)}</Chip>
      </Box>
    </>
  );
  if (disabled) {
    return (
      <Tooltip
        placement="bottom-start"
        anchorEl={
          <Box {...boxProps} opacity={0.4}>
            {content}
          </Box>
        }
      >
        {t('autoLabel.settings.messages.notAnExactCategoryMatch')}
      </Tooltip>
    );
  }
  return <Box {...boxProps}>{content}</Box>;
}
