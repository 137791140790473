import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, NamedColor, Typography } from '@superb-ai/ui';

import { SerializedMeteringSelector, useMeteringQuery } from '../../../../queries/useMeteringQuery';
import { formatCount } from '../../../../utils/numberFormat';
import { AiCreditMetering } from './AiCreditMetering';
import { ChartCard } from './aiCreditUsage/ChartCard';
import { MeteringBar } from './MeteringBar';
import { convertMeteringUnit, MeteringColors, MeteringSelectorToI18n } from './styles';

type Props = {
  className: Record<string, string>;
};

function BarAndValue(
  props: ComponentProps<typeof MeteringBar> & {
    quantity: number;
    color: NamedColor;
  },
) {
  const { t } = useTranslation();
  const { quantity, color, ...barProps } = props;
  return (
    <Box>
      <MeteringBar {...barProps} />
      <Box display="flex" alignItems="center" mt={2} gap={1}>
        <Chip color={color} variant="m-strong">
          {formatCount(quantity)}
        </Chip>
        <Typography variant="m-regular">
          {t('settings.billing.ofCountUsed', { formatCount: formatCount(barProps.maxQuantity) })}
        </Typography>
      </Box>
    </Box>
  );
}

const MeteringBox: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { data: metering } = useMeteringQuery({ staleTime: 0 });
  const isUsingCuratePlatform = metering?.get('curate:data-volume');

  const showSelectors: Exclude<SerializedMeteringSelector, 'model:endpoint'>[] = [
    'common:user-seat',
    'label:data-volume',
    ...(isUsingCuratePlatform ? ['curate:data-volume' as const] : []),
    'common:ai-credit',
  ];

  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {showSelectors.map(selector => {
        const data = metering?.get(selector);
        const isAiCredit = selector === 'common:ai-credit';
        const maxQuantity = convertMeteringUnit(data?.maxQuantity, selector);
        // TODO: add values and markers for ai credit
        const values = [
          {
            label: t(MeteringSelectorToI18n[selector]),
            value: convertMeteringUnit(data?.quantity, selector),
            color: MeteringColors[selector],
          },
        ];
        const barAndValue = (
          <BarAndValue
            values={values}
            quantity={convertMeteringUnit(data?.quantity, selector)}
            maxQuantity={maxQuantity}
            color={MeteringColors[selector]}
          />
        );
        return (
          <Box
            key={selector}
            display="flex"
            p={2.5}
            boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
            style={{ flex: 1, minWidth: isAiCredit ? '100%' : 300 }}
          >
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              justifyContent="space-between"
              gap={2}
            >
              <Typography variant="l-strong">{t(MeteringSelectorToI18n[selector])}</Typography>

              {selector === 'common:ai-credit' ? (
                <>
                  <ChartCard
                    chartTitle={t('settings.billing.cumulativeUsage')}
                    chartComponent={
                      <Box width="100%" pb={2}>
                        {barAndValue}
                      </Box>
                    }
                  ></ChartCard>
                  <AiCreditMetering />
                </>
              ) : (
                <>{barAndValue}</>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MeteringBox;
