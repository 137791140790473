import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, SegmentedControl } from '@superb-ai/ui';

import { useColorModeContext } from '../../../../contexts/ColorModeContext';
import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { CategoryCheckbox } from './CategoryCheckbox';
import ObjectClassFilter from './ObjectClassFilter';
import ObjectClusterFilter from './ObjectClusterFilter';
import { getCategoryState } from './utils';

export default function ObjectClassClusterFilter() {
  const { t } = useTranslation();
  const label = t('curate.annotationFilter.classFilter');

  const {
    filterSchema,
    appliedFilters,
    setAppliedFilters,
    selectedClasses,
    setSelectedClasses,
    selectedSuperClusters,
    setSelectedSuperClusters,
    classClusterFilterTab,
    setClassClusterFilterTab,
  } = useObjectFilterContext();

  const { setColorByMode } = useColorModeContext();
  const classSchema = filterSchema?.annotation_class_in;
  const isClusterTab = classClusterFilterTab === 'cluster';
  const categoryState: boolean | 'mixed' = getCategoryState(classSchema, selectedClasses);

  const options = [
    {
      label: `${t('curate.annotationFilter.class')} (${selectedClasses?.length || 0})`,
      value: 'class',
    },
    {
      label: `${t('curate.annotationFilter.classCluster')} (${
        Object.values(selectedSuperClusters || {}).flatMap(v => v.cluster_id_in).length || 0
      })`,
      value: 'cluster',
    },
  ];

  function handleClickCategoryCheckbox() {
    if (categoryState && categoryState !== 'mixed') {
      setSelectedClasses([]);
      return;
    }
    classSchema && setSelectedClasses(classSchema);
  }

  return (
    <Box
      overflow="auto"
      display="grid"
      style={{ gridTemplateRows: 'auto auto 1fr', flex: 1 }}
      gap={0.5}
    >
      <CategoryCheckbox
        disabled={isClusterTab}
        label={label}
        state={categoryState}
        onClick={handleClickCategoryCheckbox}
        appliedCount={
          appliedFilters?.annotation_class_in?.length ||
          appliedFilters?.annotation_class_cluster_in?.flatMap(c => c.cluster_id_in)?.length ||
          0
        }
        handleReset={() => {
          setAppliedFilters({
            ...appliedFilters,
            annotation_class_in: [],
            annotation_class_cluster_in: [],
          });
          setSelectedClasses([]);
          setSelectedSuperClusters([]);
        }}
      />
      <SegmentedControl
        style={{ width: '222px' }}
        value={classClusterFilterTab}
        onChangeValue={(value: 'class' | 'cluster') => {
          setClassClusterFilterTab(value);
          setColorByMode(value);
        }}
        options={options}
      />
      {isClusterTab ? <ObjectClusterFilter /> : <ObjectClassFilter />}
    </Box>
  );
}
