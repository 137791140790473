import { MemberData } from '../types/memberTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

// TODO: refine API response types

export interface GetUsersParams extends Params {
  asList?: '';
  additionalFields?: string[];
}

const getUsers: ApiCall<
  { params?: GetUsersParams; statusIn?: string[] },
  MemberData[]
> = async args => {
  const { isGuest, urlInfo, params, statusIn } = args;
  const statusInFilter =
    statusIn && statusIn.length ? `&${statusIn.map(s => `status__in[]=${s}`).join('&')}` : '';
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/users/?${ServiceUtils.getParamString(params)}${statusInFilter}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteUser: ApiCall<{ userEmail: string }, any> = async args => {
  const { userEmail, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/auth/users/${userEmail}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  getUsers,
  deleteUser,
};
