import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Popper,
  RadioButtons,
  Typography,
} from '@superb-ai/norwegian-forest';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { hasSufficientUserSeats } from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import { MemberData, NewRole } from '../../../../types/memberTypes';
import RoleLabel from '../../RoleLabel';
import MembersSelect from './MembersSelect';

type Props = {
  membersToAdd: Record<NewRole, string[]> | undefined;
  onChangeMembers: (role: NewRole, emails: string[]) => void;
  currentMembers: MemberData[];
  roles?: ('admin' | 'manager' | 'labeler' | 'collaborator' | 'reviewer')[];
  placeholder?: string;
};

const DefaultRoles = ['admin', 'manager', 'labeler', 'reviewer'];

const AddMembersInput: React.FC<Props> = ({
  membersToAdd,
  onChangeMembers,
  currentMembers,
  roles = DefaultRoles,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<{ label: JSX.Element; value: NewRole }>();
  const [roleSelectOpen, setRoleSelectOpen] = useState(false);
  const [selectedMemberEmails, setSelectedMemberEmails] = useState<string[]>([]);

  const { urlMatchInfo } = useRouteInfo();
  const isAdminSeatLeft = hasSufficientUserSeats(useMetering('common:user-seat'), {
    minimum: selectedMemberEmails.length + (membersToAdd?.admin || []).length,
  });

  const allRoleOptions = [
    {
      label: (
        <span>
          {t('projectMembers.role.admin')}

          <br />
          <span
            style={{
              display: 'inline-block',
              marginTop: '4px',
              color: isAdminSeatLeft ? '#6e6e6e' : '#ccc',
              fontWeight: 'normal',
              lineHeight: 1.5,
            }}
          >
            {t('inviteMembersModal.AdminDescription')}
          </span>
        </span>
      ),
      disabled: !isAdminSeatLeft,
      value: 'admin',
    },
    {
      label: <RoleLabel role="manager" />,
      value: 'manager',
    },
    {
      label: <RoleLabel role="reviewer" />,
      value: 'reviewer',
    },
    {
      label: <RoleLabel role="labeler" />,
      value: 'labeler',
    },
    {
      label: (
        <span>
          {t('role.collaborator')} <br />
          <span
            style={{
              display: 'inline-block',
              marginTop: '4px',
              color: '#6e6e6e',
              fontWeight: 'normal',
              lineHeight: 1.5,
            }}
          >
            {t('inviteMembersModal.CollaboratorDescription')}
          </span>
        </span>
      ),
      value: 'collaborator',
    },
  ].filter(opt => roles.indexOf(opt.value) !== -1);

  const roleOptions = urlMatchInfo.projectId ? allRoleOptions.slice(1) : allRoleOptions;

  const getSelectedRole = (role: string) => {
    return roleOptions.find(option => option.value === role) || roleOptions[0];
  };

  const handleSelectRole = (role: string) => {
    setSelectedRole({ label: getSelectedRole(role), value: role } as any);
    setRoleSelectOpen(false);
  };

  const handleClickAdd = () => {
    if (!selectedRole?.value) return;
    onChangeMembers(selectedRole.value, selectedMemberEmails);
    setSelectedRole(undefined);
    setSelectedMemberEmails([]);
  };

  return (
    <Box display="flex" alignItems="flex-start" gap="8px">
      <MembersSelect
        membersToAdd={membersToAdd}
        currentMembers={currentMembers}
        selectedMemberEmails={selectedMemberEmails}
        setSelectedMemberEmails={setSelectedMemberEmails}
        placeholder={placeholder}
      />
      <Popper
        open={roleSelectOpen}
        placement="bottom-start"
        useOutsidePanel
        onClickOutside={() => setRoleSelectOpen(false)}
        anchorEl={
          <Box height={32} width={100} themedBackgroundColor={['grey', 60]} display="flex">
            <Button
              variant="text"
              color="grey"
              onClick={() => {
                setRoleSelectOpen(true);
              }}
            >
              <Typography variant="body3">
                {selectedRole
                  ? getRoleTranslation(t, selectedRole.value)
                  : t('projects.pleaseSelectRole')}
              </Typography>
              <Icon name="chevronDown" />
            </Button>
          </Box>
        }
      >
        <Card width="298px">
          <RadioButtons
            variant="gapless"
            onChange={handleSelectRole}
            options={roleOptions}
            value={selectedRole?.value || ''}
          />
        </Card>
      </Popper>
      <IconButton
        icon="plus"
        variant="strong-fill"
        color="grey"
        disabled={!selectedRole || selectedMemberEmails.length <= 0}
        onClick={handleClickAdd}
      />
    </Box>
  );
};

export default AddMembersInput;

function getRoleTranslation(t: TFunction, role: NewRole) {
  switch (role) {
    case 'admin':
      return t('projectMembers.role.admin');
    case 'manager':
      return t('projectMembers.role.manager');
    case 'labeler':
      return t('projectMembers.role.labeler');
    case 'reviewer':
      return t('projectMembers.role.reviewer');
    case 'collaborator':
      return t('projectMembers.role.collaborator');
  }
}
