import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, Icon, Input } from '@superb-ai/norwegian-forest';
import { debounce, escapeRegExp } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ProjectService from '../../../../../services/ProjectService';
import { MemberData } from '../../../../../types/memberTypes';
import CircularProgressBox from '../../../../elements/CircularProgressBox';
import MembersTable from './MembersTable';
import SelectedMembersList from './SelectedMembersList';
import { getMembersSortFunction } from './utils';
interface SelectMembersProps {
  selectedMembers: MemberData[];
  setSelectedMembers: (members: MemberData[]) => void;
  type: 'labeler' | 'reviewer';
}

const roles = ['owner', 'admin', 'manager', 'reviewer', 'labeler'] as const;

type RoleFilter = typeof roles | 'all';

const SelectMembers: React.FC<SelectMembersProps> = ({
  selectedMembers,
  setSelectedMembers,
  type,
}) => {
  const { t } = useTranslation();
  const [members, setMembers] = useState<MemberData[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<MemberData[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('tenantRole');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortedMembers, setSortedMembers] = useState(members);
  const [roleFilter, setRoleFilter] = useState<RoleFilter>('all');

  const onRequestSort = (field: string, ascDesc: 'asc' | 'desc') => {
    setSortBy(field);
    setSortOrder(ascDesc);
  };

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  // Load users on first render
  const loadUsers = async () => {
    setIsLoading(true);
    const additionalFields =
      type === 'labeler'
        ? ['in_progress_count', 'submitted_count']
        : ['reviewed_count', 'unreviewed_count'];
    const exceptMembersRole = type === 'labeler' ? ['reviewer'] : ['worker'];
    try {
      const users = await ProjectService.getUsersAsList({
        projectId: routeInfo.urlMatchInfo.projectId,
        additionalFields,
        exceptMembersRole,
        statusIn: ['Active'],
        roleNotIn: [type === 'labeler' ? 'reviewer' : 'worker'],
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const filteredUsers =
        type === 'reviewer' ? users.filter(user => user.projectRole !== 'worker') : users;
      setMembers(filteredUsers);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Apply search query
  useEffect(() => {
    if (!members.length) return;
    let filteredMembers = [...members];
    if (roleFilter && roleFilter !== 'all') {
      filteredMembers = filteredMembers.filter(member => {
        const role = member.projectRole || member.tenantRole;
        const changedRole = role === 'worker' ? 'labeler' : role;
        return (`${changedRole}`.toLocaleLowerCase() as unknown as typeof roles) === roleFilter;
      });
    }
    if (query && query !== '') {
      const regex = new RegExp(escapeRegExp(query), 'i');
      filteredMembers = filteredMembers.filter(
        member => member.name.match(regex) || member.email.match(regex),
      );
    }
    setFilteredMembers(filteredMembers);
  }, [members, query, roleFilter]);

  // Apply sort
  useEffect(() => {
    const sortedMembers = [...filteredMembers];
    sortedMembers.sort(getMembersSortFunction(sortBy, sortOrder === 'desc'));
    setSortedMembers(sortedMembers);
  }, [filteredMembers, sortBy, sortOrder]);

  const onToggleSelectMember = (member: MemberData): void => {
    const isSelected = selectedMembers.findIndex(m => m.email === member.email) !== -1;
    if (!isSelected) {
      setSelectedMembers([...selectedMembers, member]);
    } else {
      setSelectedMembers(selectedMembers.filter(m => m.email !== member.email));
    }
    setIsSelectedAll(false);
  };

  const onToggleSelectAll = (): void => {
    if (!isSelectedAll) {
      setSelectedMembers([...sortedMembers]);
      setIsSelectedAll(true);
    } else {
      setSelectedMembers([]);
      setIsSelectedAll(false);
    }
  };

  useEffect(() => {
    if (selectedMembers.length === 0 && isSelectedAll) {
      setIsSelectedAll(false);
    }
  }, [selectedMembers, isSelectedAll]);

  const handleRemoveMember = ({ email }: MemberData): void => {
    setSelectedMembers(selectedMembers.filter(m => m.email !== email));
  };

  const handleSearchInput = debounce((query: string) => {
    setQuery(query);
  }, 100);

  const columns =
    type === 'labeler'
      ? ['name', 'email', 'tenantRole', 'submittedCount', 'inProgressCount']
      : ['name', 'email', 'tenantRole', 'reviewedCount', 'unreviewedCount'];

  const notInRole = type === 'labeler' ? ('reviewer' as const) : ('labeler' as const);
  const roleOptions = [
    { label: t('all'), value: 'all' },
    ...roles
      .filter(value => value !== notInRole)
      .map(role => ({ label: t(`projectMembers.role.${role}`), value: role })),
  ];

  return (
    <>
      <Box display="flex" pt={4} px={4} pb={2} gap="8px">
        <Box display="flex" width="174px">
          <DropdownList
            variant="soft-fill"
            size="l"
            value={roleFilter as string}
            onChange={role => setRoleFilter(role as RoleFilter)}
            options={roleOptions}
          />
        </Box>
        <Box flex={1}>
          <Input
            Adornment={<Icon name="search" color={['grey', 300]} />}
            placeholder={t('users.filter.searchMemberNameOrEmail')}
            size="l"
            type="text"
            variant="filled"
            width="100%"
            onChange={e => handleSearchInput(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        px={4}
        pb={0.2}
        flex={1}
        style={{ overflow: 'hidden', boxShadow: 'inset 0 -1px 0 0 #EFEFEF' }}
      >
        {isLoading ? (
          <CircularProgressBox
            delay={500}
            boxProps={{
              height: '135px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        ) : (
          <MembersTable
            members={sortedMembers}
            columns={columns}
            selectable
            selectedMembers={selectedMembers}
            onToggleSelectMember={onToggleSelectMember}
            onToggleSelectAll={onToggleSelectAll}
            isSelectedAll={isSelectedAll}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onRequestSort={onRequestSort}
          />
        )}
      </Box>
      {selectedMembers.length > 0 && (
        <Box px={6.25} pt={3} style={{ fontSize: '.8em' }}>
          <Box mb={1.25} style={{ fontWeight: 500 }}>
            {t('labels.assignDialog.selected', { memberCount: selectedMembers.length })}
          </Box>
          <SelectedMembersList
            selectedMembers={selectedMembers}
            onRemoveMember={handleRemoveMember}
          />
        </Box>
      )}
    </>
  );
};

export default SelectMembers;
