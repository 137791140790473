import { useParams } from 'react-router-dom';

import { DatasetPool } from '../../../../../types/routeTypes';

export function useDiagnosisPathname() {
  const { accountName, suite, datasetId, datasetPool } = useParams<{
    accountName: string;
    suite: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();

  return `${accountName}/${suite}/${datasetPool}/${datasetId}/model-diagnosis/`;
}
