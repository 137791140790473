import React from 'react';

import styles from './ProgressBar.module.scss';

interface Props {
  color: string;
  completed?: number;
}
const ProgressBar = ({ color, completed }: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.progressBar}
        style={{ backgroundColor: color, width: `${completed}%` }}
      >
        <span className={styles.percentageText}>{`${completed || 0}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
