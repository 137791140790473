import { useHistory } from 'react-router';

import { ActionProvider } from '../../../../contexts/ActionContext';
import {
  ANALYTICS_VIEW,
  GRID_VIEW,
  IMAGE_SCOPE,
  SCATTER_VIEW,
  SCOPE,
  ScopeMode,
  SEMANTIC_SEARCH_VIEW,
  VIEW,
  ViewMode,
} from '../../../../types/viewTypes';
import { AnalyticsProvider } from '../analytics/contexts/AnalyticsContext';
import { ChartAreaProvider } from '../analytics/contexts/ChartAreaContext';
import { CompareModeProvider } from '../analytics/contexts/CompareModeContext';
import { ImageScatterProvider } from '../analytics/contexts/ImageScatterContext';
import { ObjectScatterProvider } from '../analytics/contexts/ObjectScatterContext';
import ActionTopBar from './ActionTopBar';
import AnalyticsContents from './analytics/AnalyticsContents';
import AnalyticsLeftSection from './analytics/AnalyticsLeftSection';
import ImageScatterContents from './embedding/ImageScatterContents';
import ObjectScatterContents from './embedding/ObjectScatterContents';
import ScatterPointThumbnailMode from './embedding/ScatterPointThumbnailMode';
import { ViewModeProvider } from './embedding/scatterView/providers/ViewModeProvider';
import ImageEmbeddingActions from './grid/image/EmbeddingActions';
import ImageGridActions from './grid/image/GridActions';
import ImageGridLeftSection from './grid/image/GridLeftSection';
import ImageGridRightSection from './grid/image/GridRightSection';
import ImageGridContents from './grid/image/ImageGridContents';
import SemanticSearchContents from './grid/image/SemanticSearchContents';
import ObjectGridActions from './grid/object/GridActions';
import ObjectGridLeftSection from './grid/object/GridLeftSection';
import ObjectEmbeddingActions from './grid/object/ObjectEmbeddingActions';
import ObjectGridContents from './grid/object/ObjectGridContents';
import ObjectSemanticSearchContents from './grid/object/ObjectSemanticSearchContents';

export default function ViewContentsArea() {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;
  const scope = (params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;

  const ImageContents = {
    [GRID_VIEW]: <ImageGridContents />,
    [SEMANTIC_SEARCH_VIEW]: <SemanticSearchContents />,
    [ANALYTICS_VIEW]: (
      <AnalyticsProvider>
        <AnalyticsContents />
      </AnalyticsProvider>
    ),
    [SCATTER_VIEW]: (
      <ChartAreaProvider>
        <CompareModeProvider>
          <ImageScatterProvider>
            <ImageScatterContents />
          </ImageScatterProvider>
        </CompareModeProvider>
      </ChartAreaProvider>
    ),
  }[view];

  const ImageLeftSection = {
    [GRID_VIEW]: <ImageGridLeftSection />,
    [ANALYTICS_VIEW]: <AnalyticsLeftSection />,
    [SCATTER_VIEW]: <ScatterPointThumbnailMode />,
  }[view];

  const ImageRightSection = {
    [GRID_VIEW]: <ImageGridRightSection />,
    [ANALYTICS_VIEW]: null,
    [SCATTER_VIEW]: null,
  }[view];

  const ImageActions = {
    [GRID_VIEW]: <ImageGridActions />,
    [ANALYTICS_VIEW]: <ImageEmbeddingActions />,
    [SCATTER_VIEW]: <ImageEmbeddingActions />,
    [SEMANTIC_SEARCH_VIEW]: <ImageEmbeddingActions />,
  }[view];

  const ObjectContents = {
    [GRID_VIEW]: <ObjectGridContents />,
    [SCATTER_VIEW]: (
      <ChartAreaProvider>
        <CompareModeProvider>
          <ObjectScatterProvider>
            <ObjectScatterContents />
          </ObjectScatterProvider>
        </CompareModeProvider>
      </ChartAreaProvider>
    ),
    [SEMANTIC_SEARCH_VIEW]: <ObjectSemanticSearchContents />,
  }[view as typeof GRID_VIEW | typeof SCATTER_VIEW];

  const ObjectLeftSection = {
    [GRID_VIEW]: <ObjectGridLeftSection />,
    [ANALYTICS_VIEW]: <AnalyticsLeftSection />,
    [SCATTER_VIEW]: null,
  }[view];

  const ObjectActions = {
    [GRID_VIEW]: <ObjectGridActions />,
    [ANALYTICS_VIEW]: null,
    [SCATTER_VIEW]: <ObjectEmbeddingActions />,
    [SEMANTIC_SEARCH_VIEW]: <ObjectEmbeddingActions />,
  }[view];

  const Contents = {
    image: (
      <ActionProvider>
        <ViewModeProvider>
          <ActionTopBar
            LeftSection={ImageLeftSection}
            Actions={ImageActions}
            RightSection={ImageRightSection}
          >
            {ImageContents}
          </ActionTopBar>
        </ViewModeProvider>
      </ActionProvider>
    ),
    object: (
      <ActionProvider>
        <ActionTopBar LeftSection={ObjectLeftSection} Actions={ObjectActions}>
          {ObjectContents}
        </ActionTopBar>
      </ActionProvider>
    ),
  }[scope];

  return Contents;
}
