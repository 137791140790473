import { createContext, CSSProperties, ReactNode, useContext, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Color, getColor, Typography } from '@superb-ai/ui';

interface Props {
  columnWidthList: string[];
  addColumn: (width: string) => void;
}

const GridTableContext = createContext<Props>({
  columnWidthList: [],
  addColumn: () => {},
});

// Add Custom Header
export default function GridTable({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) {
  const [columnWidthList, setColumnWidthList] = useState<string[]>([]);

  const addColumn = (width: string) => {
    setColumnWidthList(prev => [...prev, width]);
  };

  useEffect(() => {
    return () => setColumnWidthList([]);
  }, []);

  return (
    <GridTableContext.Provider
      value={{
        columnWidthList,
        addColumn,
      }}
    >
      <Table style={style} columnWidthList={columnWidthList}>
        {children}
      </Table>
    </GridTableContext.Provider>
  );
}

const Table = styled.table<{ columnWidthList: string[] }>`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  height: 100%;
  position: relative;
  grid-template-columns: ${({ columnWidthList }) => columnWidthList.join(' ')};
`;

export const useGridTable = () => useContext(GridTableContext);

const GridTableThead = styled.thead<{ position: 'sticky' | 'relative'; bbColor?: string }>`
  display: contents;
  & th {
    position: ${({ position }) => position};
  }

  ${({ bbColor }) =>
    bbColor &&
    css`
      & th {
        border-bottom: 1px solid ${bbColor};
      }
    `}
`;

GridTable.Thead = function Thead({
  children,
  style,
  position = 'sticky',
  borderBottomColor,
}: {
  children: ReactNode;
  style?: CSSProperties;
  position?: 'sticky' | 'relative';
  borderBottomColor?: string;
}) {
  return (
    <GridTableThead position={position} style={style} bbColor={borderBottomColor}>
      {children}
    </GridTableThead>
  );
};

const GridTableTbody = styled.tbody<{ gap?: number }>`
  display: contents;

  ${({ gap }) =>
    gap &&
    css`
      & td {
        padding: ${8 * gap}px 0;
      }

      & tr:not(:last-child) td {
        padding-bottom: ${4 * gap}px;
      }

      & tr:not(:first-child) td {
        padding-top: ${4 * gap}px;
      }
    `}
`;

GridTable.Tbody = function Tbody({ children, gap }: { children: ReactNode; gap?: number }) {
  return <GridTableTbody gap={gap}>{children}</GridTableTbody>;
};

const GridTableTr = styled.tr<{
  px?: number;
  py?: number;
  bgColor?: string;
  hoverBgColor?: string;
}>`
  display: contents;

  ${({ px }) =>
    px &&
    css`
      & td:first-of-type {
        padding-left: ${px * 8}px;
      }

      & td:last-of-type {
        padding-right: ${px * 8}px;
      }

      & th:first-of-type {
        padding-left: ${px * 8}px;
      }

      & th:last-of-type {
        padding-right: ${px * 8}px;
      }
    `}

  ${({ py }) =>
    py &&
    css`
      & td {
        padding-top: ${py * 8}px;
        padding-bottom: ${py * 8}px;
      }

      & th {
        padding-top: ${py * 8}px;
        padding-bottom: ${py * 8}px;
      }
    `}

  ${({ bgColor }) =>
    bgColor &&
    css`
      & td {
        background-color: ${bgColor};
      }

      & th {
        background-color: ${bgColor};
      }
    `}

  &:hover {
    ${({ hoverBgColor }) =>
      hoverBgColor &&
      css`
        & td {
          background-color: ${hoverBgColor};
        }

        & th {
          background-color: ${hoverBgColor};
        }
      `}
  }
`;

GridTable.Tr = function Tr({
  children,
  style,
  px,
  py,
  backgroundColor,
  hoverBackgroundColor,
}: {
  children: ReactNode;
  style?: CSSProperties;
  px?: 0.5 | 1 | 1.5 | 2 | 3 | 4;
  py?: 0.5 | 1 | 1.5 | 2 | 3 | 4;
  backgroundColor?: Color;
  hoverBackgroundColor?: Color;
}) {
  return (
    <GridTableTr
      style={style}
      px={px}
      py={py}
      bgColor={backgroundColor && getColor(backgroundColor)}
      hoverBgColor={hoverBackgroundColor && getColor(hoverBackgroundColor)}
    >
      {children}
    </GridTableTr>
  );
};

const GridTableTd = styled.td<{ px?: number }>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  background: white;

  ${({ px }) =>
    px &&
    css`
      &:first-of-type {
        padding-left: ${px * 8}px;
      }

      &:last-of-type {
        padding-right: ${px * 8}px;
      }
    `}
`;

GridTable.Td = function Td({
  children,
  style,
  px = 3,
}: {
  children?: ReactNode;
  style?: CSSProperties;
  px?: number;
}) {
  return (
    <GridTableTd px={px} style={style}>
      {children}
    </GridTableTd>
  );
};

GridTable.TdText = function Td({
  children,
  style,
}: {
  children?: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <GridTableTd style={style}>
      <Typography variant="m-regular">{children}</Typography>
    </GridTableTd>
  );
};

export const GridTableTh = styled.th<{
  px?: number;
  hideBorder?: boolean;
  firstLastPadding?: number;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  white-space: nowrap;
  top: 0;
  background: white;
  z-index: 1000;

  ${({ px, firstLastPadding }) =>
    px &&
    css`
      &:first-of-type {
        padding-left: ${firstLastPadding || px * 8}px;
      }

      &:last-of-type {
        padding-right: ${firstLastPadding || px * 8}px;
      }
    `}

  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      &::after {
        content: '';
        top: 100%;
        width: 100%;
        height: 1px;
        background: #e8e8e8;
        position: absolute;
        left: 0;
      }
    `}
`;

GridTable.Th = function Th({
  width = '1fr',
  children,
  style,
  px = 3,
  hideBorder,
  firstLastPadding,
}: {
  width?: string;
  children?: ReactNode;
  style?: CSSProperties;
  px?: number;
  hideBorder?: boolean;
  firstLastPadding?: number;
}) {
  const { addColumn } = useGridTable();

  useEffect(() => {
    addColumn(width);
  }, []);

  return (
    <GridTableTh px={px} style={style} hideBorder={hideBorder} firstLastPadding={firstLastPadding}>
      <Typography variant="m-medium">{children}</Typography>
    </GridTableTh>
  );
};
