import { TFunction, Trans } from 'react-i18next';

import { Box, Icon, IconName, Tooltip } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';
import { Typography } from '@superb-ai/ui';
import { i18n } from 'next-i18next';

import { AssetDataType } from '../../../../../apps/projects/project/data/assetDataTypes';
import { TargetType } from '../../../../pages/account/integrations/types';
import { UPLOAD_METHODS } from '../../types';

export const UPLOAD_METHODS_CONFIG = {
  CLOUD: {
    get title() {
      return i18n?.t('cloud');
    },
    icon: 'cloudOutline' as IconName,
    value: 'CLOUD',
    get description() {
      return i18n?.t('data.upload.uploadMethodsConfig.cloudDescription');
    },
  },
  FILE: {
    get title() {
      return i18n?.t('file');
    },
    icon: 'uploadFile' as IconName,
    value: 'FILE',
    get description() {
      return i18n?.t('data.upload.uploadMethodsConfig.fileDescription');
    },
  },
  URL: {
    title: 'URL',
    icon: 'link2' as IconName,
    value: 'URL',
    get description() {
      return i18n?.t('data.upload.uploadMethodsConfig.urlDescription');
    },
  },
  CLI: {
    title: 'CLI',
    icon: 'windowTerminal' as IconName,
    value: 'CLI',
    get description() {
      return i18n?.t('data.upload.uploadMethodsConfig.cliDescription');
    },
  },
};

export const uploadMethods = () =>
  UPLOAD_METHODS.map(uploadMethod => ({
    label: (
      <Box>
        <Box display="flex" alignItems="center">
          <Icon name={UPLOAD_METHODS_CONFIG[uploadMethod].icon} />
          <Box ml={0.5}>{UPLOAD_METHODS_CONFIG[uploadMethod].title}</Box>
        </Box>
        <Box mt={1}>
          <Typography>{UPLOAD_METHODS_CONFIG[uploadMethod].description}</Typography>
        </Box>
      </Box>
    ),
    value: UPLOAD_METHODS_CONFIG[uploadMethod].value,
  }));

export const cloudStorageOptions = [
  {
    label: (
      <Box>
        <Box display="flex" alignItems="center">
          <img src="/static/image/amazon_s3.png" width="16px" alt="s3 logo" />
          <Box display="flex" alignItems="center" ml={0.5}>
            AWS S3
          </Box>
        </Box>
      </Box>
    ),
    value: 'S3',
  },
  {
    label: (
      <Box>
        <Box display="flex" alignItems="center">
          <img src="/static/image/gcp.png" width="16px" alt="gcp logo" />
          <Box display="flex" alignItems="center" ml={0.5}>
            Google Storage
          </Box>
        </Box>
      </Box>
    ),
    value: 'GS',
  },
  {
    label: (
      <Box>
        <Box display="flex" alignItems="center">
          <img src="/static/image/abs.png" width="16px" alt="gcp logo" />
          <Box display="flex" alignItems="center" ml={0.5}>
            Azure Blob Storage
          </Box>
        </Box>
      </Box>
    ),
    value: 'ABS',
  },
] as Option<TargetType>[];

export const storageMethodOptions = (
  dataType: AssetDataType | null,
  videoFramesSourceData: 'image-sequence' | 'video',
  t: TFunction,
): { label: JSX.Element; value: string; disabled?: boolean }[] => {
  const linkOption = (
    <Box>
      {t('data.upload.yourOwnStorage')}
      <Box mt={1}>
        <Typography color="gray-300">{t('data.upload.ownStorageDescription')}</Typography>
      </Box>
    </Box>
  );

  const linkEnabled =
    dataType === 'IMAGE' ||
    (dataType === 'VIDEO_FRAMES' && videoFramesSourceData === 'image-sequence');

  return [
    {
      value: 'copy',
      label: (
        <Box>
          {t('data.upload.superbAIStorage')}
          <Box mt={1}>
            <Typography color="gray-300">{t('data.upload.superbAIStorageDescription')}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      value: 'link',
      disabled: !linkEnabled,
      label: !linkEnabled ? (
        <Tooltip placement="bottom-start" anchorEl={linkOption}>
          {t('data.upload.notSupported')}
        </Tooltip>
      ) : (
        linkOption
      ),
    },
  ];
};

export const getVideoFramesSourceDataOptions = (t: TFunction) =>
  [
    {
      label: (
        <Box>
          <Box display="flex" alignItems="center">
            <Icon name="videoFrames" />
            <Box display="flex" alignItems="center" ml={0.5}>
              {t('analytics.projectType.video')} (MP4)
            </Box>
          </Box>
          <Box mt={1}>
            <Box display="flex" alignItems="center">
              <Typography color="gray-300">
                <Trans
                  i18nKey="data.upload.videoDescription"
                  components={{ bold: <Typography color="black" /> }}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
      value: 'video',
    },
    {
      label: (
        <Box>
          <Box display="flex" alignItems="center">
            <Icon name="imageSequence" />
            <Box display="flex" alignItems="center" ml={0.5}>
              {t('data.upload.imageSequence')}
            </Box>
          </Box>
          <Box mt={1}>
            <Typography>
              <Box display="flex" alignItems="center">
                <Typography color="gray-300">
                  <Trans
                    i18nKey="data.upload.imageSequenceDescription.1"
                    components={{ bold: <Typography color="black" /> }}
                  />
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography color="gray-300">
                  <Trans
                    i18nKey="data.upload.imageSequenceDescription.2"
                    components={{ bold: <Typography color="black" /> }}
                  />
                </Typography>
              </Box>
            </Typography>
          </Box>
        </Box>
      ),
      value: 'image-sequence',
    },
  ] as Option<'video' | 'image-sequence'>[];
