import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Data } from '@superb-ai/icons';
import { Box, Button, Icon } from '@superb-ai/ui';
import { isEmpty } from 'lodash';
import qs from 'qs';

import SearchInput from '../../../components/elements/SearchInput';
import OldLayout from '../../../components/pages/projects/Layout';
import NewProjectButtonNew from '../../../components/pages/projects/NewProjectButtonNew';
import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useProjectListInfo } from '../../../hooks/ProjectListInfo';
import { Page } from '../../../menu';
import { getUrl } from '../../../routes/util';
import AnnotationTypeFilter from './AnnotationTypeFilter';
import EmptyProject from './EmptyProject';
import ProjectTypeFilter from './ProjectTypeFilter';

export default function Layout(): JSX.Element {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { accountName } = useParams<{ accountName: string }>();
  const isAuthorized = !authInfo.isGuest && isOwnerOrAdminOrSystemAdmin(authInfo);

  const projectListInfo = useProjectListInfo();

  const hasProject = projectListInfo.totalCount > 0 || !isEmpty(routeInfo.params);

  const isProjectEmpty = !hasProject && !authInfo.isGuest;

  useEffect(() => {
    if (projectListInfo.isLoading) return;
    const {
      history: { location },
    } = routeInfo;
    // If showing empty project page add hash '#project=empty' or '#data=empty' to url as an identifier
    const hash = {
      project: isProjectEmpty ? 'empty' : undefined,
    };

    if (location.hash === '' && !hash.project) return;

    routeInfo.history.replace({
      pathname: location.pathname,
      search: location.search,
      hash: `#${qs.stringify(hash)}`,
    });
  }, [routeInfo.hash, projectListInfo.isLoading, isProjectEmpty]);

  if (!projectListInfo.isLoading && isProjectEmpty) {
    return (
      <Page>
        <EmptyProject />
      </Page>
    );
  }

  return (
    <Page>
      <Box display="flex" alignItems="center" mb={2} gap={1}>
        <ProjectTypeFilter />
        <AnnotationTypeFilter />
        <Box mr="auto" style={{ width: '300px' }}>
          <SearchInput placeholder={t('projects.filter.searchForProjectName')} />
        </Box>
        {isAuthorized && (
          <Box display="flex" gap={1}>
            <Button
              color="primary"
              variant="stroke"
              size="l"
              onClick={() => routeInfo.history.push(getUrl([accountName, 'label', 'data']))}
              style={{ whiteSpace: 'nowrap' }}
            >
              <Icon icon={Data} />
              {t('projects.button.manageData')}
            </Button>
            <NewProjectButtonNew />
          </Box>
        )}
      </Box>
      <OldLayout />
    </Page>
  );
}
