import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty, map, size } from 'lodash';

import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { Display } from '../../../../types/displayTypes';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import DatasetTableViewHead from './DatasetTableViewHead';
import DatasetTableViewRow from './DatasetTableViewRow';

const useStyles = makeStyles(() => ({
  allSelectedRow: {
    height: '40px',
  },
  allSelectedCell: {
    color: '#635c5c',
    backgroundColor: 'rgba(183, 183, 183, 0.12)',
  },
  textButton: {
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    textTransform: 'initial',
    lineHeight: 1,
    minWidth: 'initial',
    '&:hover': {
      backgroundColor: 'rgba(183, 183, 183, 0.2)',
    },
  },
  noSelectedRow: {
    height: '100px',
    '& td': {
      fontSize: '13px',
      textAlign: 'center',
    },
    '& a': {
      color: '#ff625a',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
}));

type Props = {
  onClickDataset: (id: string) => void;
  onClickCheckbox: (event: React.MouseEvent, groupName: string) => void;
  onClickSelectAllAssets: (isAllAssetsChecked: boolean) => () => void;
  onClickGlobalCheckbox: () => void;
  isAllDatasetsChecked: boolean;
  checkedDatasets: string[];
  isLoadingDatasets: boolean;
  setIsLoadingDatasets: Dispatch<SetStateAction<boolean>>;
  isDesc: boolean;
  orderBy: string;
  onRequestSort: (property: string, order: 'asc' | 'desc') => void;
  variant: Display;
};

const DatasetTableView: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const assetsInfo = useAssetsInfo();
  const {
    onClickDataset,
    onClickCheckbox,
    onClickSelectAllAssets,
    onClickGlobalCheckbox,
    isAllDatasetsChecked,
    checkedDatasets,
    isLoadingDatasets,
    setIsLoadingDatasets,
    isDesc,
    orderBy,
    onRequestSort,
    variant = 'normal',
  } = props;

  let colgroup: ReactNode = null;
  const colSpan = 4;
  if (variant === 'page') {
    colgroup = (
      <colgroup>
        <col width="60" />
        <col />
        <col width="250" />
        <col width="200" />
      </colgroup>
    );
  }

  return (
    <>
      <MUI.Table size="small">
        {colgroup}
        <DatasetTableViewHead
          onClickGlobalCheckbox={onClickGlobalCheckbox}
          isAllChecked={
            !isEmpty(checkedDatasets) && checkedDatasets.length === assetsInfo.datasets.length
          }
          isDesc={isDesc}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
        />
        {!isLoadingDatasets &&
          (isEmpty(assetsInfo.datasets) ? (
            <MUI.TableBody>
              <MUI.TableRow className={classes.noSelectedRow}>
                <MUI.TableCell colSpan={colSpan}>
                  <MUI.Box display="flex" alignItems="center" justifyContent="center">
                    <MUI.Typography variant="body2">
                      You don&apos;t have any items at the moment.
                    </MUI.Typography>
                  </MUI.Box>
                </MUI.TableCell>
              </MUI.TableRow>
            </MUI.TableBody>
          ) : (
            <MUI.TableBody>
              {checkedDatasets.length === assetsInfo.datasets.length && (
                <MUI.TableRow className={classes.allSelectedRow}>
                  <MUI.TableCell
                    className={classes.allSelectedCell}
                    align="center"
                    colSpan={colSpan}
                  >
                    <MUI.Box display="flex" alignItems="center" justifyContent="center">
                      <MUI.Typography variant="body2">
                        {isAllDatasetsChecked
                          ? t('data.messages.allItemsInListSelected', {
                              count: assetsInfo.totalDatasetCount,
                            })
                          : t('data.messages.allItemsOnPageSelected', {
                              count: size(assetsInfo.datasets),
                            })}
                      </MUI.Typography>
                      {isAllDatasetsChecked ? (
                        <MUI.Button
                          className={classes.textButton}
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={onClickSelectAllAssets(false)}
                        >
                          {t('labels.button.clearSelection')}
                        </MUI.Button>
                      ) : (
                        <MUI.Button
                          className={classes.textButton}
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={onClickSelectAllAssets(true)}
                        >
                          {t('data.button.selectAllItemsInThisList', {
                            count: assetsInfo.totalDatasetCount,
                          })}
                          .
                        </MUI.Button>
                      )}
                    </MUI.Box>
                  </MUI.TableCell>
                </MUI.TableRow>
              )}
              {map(assetsInfo.datasets, dataset => (
                <DatasetTableViewRow
                  key={dataset.group}
                  dataset={dataset}
                  onClickDataset={onClickDataset}
                  onClickCheckbox={onClickCheckbox}
                  checkedDatasets={checkedDatasets}
                  setIsLoadingDatasets={setIsLoadingDatasets}
                />
              ))}
            </MUI.TableBody>
          ))}
      </MUI.Table>
      {isLoadingDatasets && (
        <CircularProgressBox
          boxProps={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: '50px',
          }}
        />
      )}
    </>
  );
};

export default DatasetTableView;
