/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, Icon } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import NotificationsContext from '../../../../contexts/NotificationsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import NotificationsService from '../../../../services/NotificationsService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import { formatDistance } from '../../../../utils/date';
import NotificationsUtils from '../../../../utils/NotificationsUtils';
import Mentions from '../../../elements/mentions/Mentions';
import { NotificationObject, UploadNotificationObject } from './types';
import UploadNotification from './UploadNotification';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'stretch',
    color: '#635c5c',
    borderBottom: 'solid 1px  #e6e6e6',
    padding: '14px 0px',
    cursor: 'pointer',
    '&:nth-child(1)': {
      borderTop: 'solid 1px #e6e6e6',
    },
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    '&:hover': {
      background: '#FAFAFA',
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '35px',
    height: '100%',
    marginRight: '10px',
  },
  emoji: {
    fontSize: '16px',
    width: '16px',
    height: '16px',
  },
  // center
  center: {
    width: '310px',
    paddingTop: '2px',
  },
  messageBox: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '2px',
    maxWidth: '310px',
  },
  messageText: {
    color: '#635c5c',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    '&.bold': {
      fontWeight: '500',
    },
  },
  fromNowText: {
    fontSize: '11px',
    color: '#9b9b9b',
    marginBottom: '2px',
  },
  issueBox: {
    display: 'flex',
  },
  issueLeft: {
    width: '20px',
    paddingTop: '2px',
  },
  threadNumberAvatar: {
    marginRight: '8px',
    '&.MuiAvatar-root': {
      width: '15px',
      height: '15px',
      fontSize: '10px',
    },
  },
  issueRight: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  issueRightText: {
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#635c5c',
    fontSize: '12px',
    '&.first': {},
    '&.name': {
      color: '#9b9b9b',
      fontSize: '9px',
    },
    '&.reply': {
      textDecoration: 'underline',
    },
  },
  // right
  right: {
    display: 'flex',
    flexDirection: 'column',
    width: '35px',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  newTextBox: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  circle: {
    background: '#ff9772',
    borderRadius: '50%',
    width: '4px',
    height: '4px',
    marginRight: '3px',
  },
  newText: {
    fontSize: '11px',
    color: '#ff9772',
  },
  //
  subType: {
    display: 'inline',
    borderRadius: '7.5px',
    padding: '3px 8px',
    fontSize: '10px',
    marginLeft: '4px',
    '&.started': {
      color: '#a6a6a6',
      background: '#f4f4f4',
    },
    '&.succeed': {
      color: '#a3eb57',
      background: '#EDFBDD',
    },
    '&.failed': {
      color: '#ffcc00',
      background: '#fff8e0',
    },
    '&.canceled': {
      color: '#ff625a',
      background: '#ffeceb',
    },
  },
  buttonBox: {},
  labelBox: {
    position: 'absolute',
    left: -45,
    right: -45,
    paddingLeft: '45px',
    background: '#fbfbfb',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    color: '#333',
    fontSize: 11,
    marginLeft: '4px',
  },
}));

interface Props {
  notification: NotificationObject;
}

const Notification: React.FC<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelsInfo = useLabelsInfo();
  const history = useHistory();
  const notificationsInfo = React.useContext(NotificationsContext.Context);
  const { unreadCount, setUnreadCount } = notificationsInfo;

  const {
    notification,
    notification: {
      id,
      type,
      checked,
      createdAt,
      payload,
      payload: {
        projectId,
        fromFirstName,
        labelId,
        threadId,
        threadNumber,
        commentId,
        labelName,
        projectName,
        labelCount,
        fileCount,
        firstComment,
        replyComment,
        threadColor,
        subType,
        exportSeqNum,
        usageType, // analytics
        status,
      },
    },
  } = props;

  const handleClickNotification = async () => {
    if (type === 'welcome') return;

    if (!checked && id) {
      await NotificationsService.readNotification({
        ids: [id],
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      notification.checked = true;
      setUnreadCount(unreadCount - 1);
    }

    let pathname;
    let hash;
    let searchParams;

    pathname = `/${authInfo.accountName}/label/project/${projectId}`;

    switch (type) {
      case 'invite':
        history.push(pathname);
        break;
      case 'assign':
        if (UserRoleUnion.isWorker(authInfo.projectRole)) {
          pathname += '/labels';
          if (
            routeInfo?.location?.pathname?.split('/')[4] === 'labels' &&
            routeInfo.history.location.search === ''
          ) {
            labelsInfo.getLabels();
          } else {
            history.push(pathname);
          }
        } else {
          searchParams = new URLSearchParams({
            status: 'is any one of,in progress',
            assignee: `is any one of, ${authInfo.name} (${authInfo.email})`,
          });
          pathname = `${pathname}/labels?${searchParams.toString()}`;

          if (routeInfo.location.search === `?${searchParams.toString()}`) {
            labelsInfo.getLabels();
          } else {
            history.push(pathname);
          }
        }
        break;
      case 'thread':
        searchParams = new URLSearchParams({
          'label id': `matches,${labelId}`,
        });
        pathname = `${pathname}/labels?${searchParams.toString()}`;
        hash = `#label_id=${labelId}&thread_id=${threadId}&thread_state=thread`;
        history.push(`${pathname}${hash}`);

        break;
      case 'comment':
        searchParams = new URLSearchParams({
          'label id': `matches,${labelId}`,
        });
        pathname = `${pathname}/labels?${searchParams.toString()}`;
        hash = `#label_id=${labelId}&thread_id=${threadId}&thread_state=thread&comment_id=${commentId}`;
        history.push(`${pathname}${hash}`);
        break;
      case 'export':
        // TODO: Refresh page if already on history page?
        history.push(`${pathname}/export/history`);
        break;
      case 'userstats':
        if (usageType === 'analytics') {
          history.push(`${pathname}/analytics/user-reports`);
        }
        break;
      case 'archive':
        history.push(`${pathname}/export/raw-data`);
        break;
      // custom auto label
      case 'custom_auto_label':
        history.push(`${pathname}/auto-label/cal`);
        break;
      default:
        break;
    }
    notificationsInfo.setIsOpen(false);
  };

  const getEmoji = () => {
    let emoji;

    switch (type) {
      case 'invite':
        emoji = '🎉';
        break;
      case 'assign':
        emoji = '👉';
        break;
      case 'thread':
        emoji = '🗒';
        break;
      case 'comment':
        emoji = '💬';
        break;
      case 'export':
      case 'archive':
        emoji = '🚀';
        break;
      case 'welcome':
        emoji = '👋';
        break;
      case 'userstats':
        emoji = '👥️';
        break;
      case 'custom_auto_label':
        if (payload?.state === 'failed') {
          emoji = '❗️';
        } else {
          emoji = '✔️';
        }
        break;
      default:
        break;
    }

    return <MUI.Typography className={classes.emoji}>{emoji}</MUI.Typography>;
  };

  const getMessage = () => {
    switch (type) {
      case 'welcome':
        return (
          <MUI.Box className={classes.messageBox}>
            <Trans t={t} i18nKey="notifications.welcome">
              <MUI.Typography className={classes.messageText}>Hello</MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ name: `${authInfo.firstName} ${authInfo.lastName}` }}!
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}>Welcome</MUI.Typography>
              <MUI.Typography className={classes.messageText}>to</MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                Superb Platform!
              </MUI.Typography>
            </Trans>
          </MUI.Box>
        );
      case 'invite':
        return (
          <MUI.Box className={classes.messageBox}>
            <Trans t={t} i18nKey="notifications.invitedYouTo">
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ fromFirstName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> invited you to </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ projectName }}
              </MUI.Typography>
            </Trans>
          </MUI.Box>
        );
      case 'assign':
        return (
          <MUI.Box className={classes.messageBox}>
            <Trans t={t} i18nKey="notifications.assignedLabelsToYou">
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ fromFirstName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> assigned </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ labelCount: t('labels.labelCount', { count: labelCount }) }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> to you in </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ projectName }}
              </MUI.Typography>
            </Trans>
          </MUI.Box>
        );
      case 'thread':
        return (
          <MUI.Box className={classes.messageBox}>
            <Trans t={t} i18nKey="notifications.createdAnIssueOn">
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ fromFirstName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> created an issue on </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ labelName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> in </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ projectName }}
              </MUI.Typography>
            </Trans>
          </MUI.Box>
        );
      case 'comment':
        return (
          <MUI.Box className={classes.messageBox}>
            <Trans t={t} i18nKey="notifications.repliedToAnIssueOn">
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ fromFirstName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}>
                {' '}
                replied to an issue on{' '}
              </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ labelName }}
              </MUI.Typography>
              <MUI.Typography className={classes.messageText}> in </MUI.Typography>
              <MUI.Typography className={`${classes.messageText} bold`}>
                {{ projectName }}
              </MUI.Typography>
            </Trans>
          </MUI.Box>
        );
      case 'export':
        return (
          <MUI.Box className={classes.messageBox}>
            <MUI.Typography className={`${classes.messageText} bold`}>
              {t('export.title')} #{exportSeqNum}{' '}
            </MUI.Typography>
            <span className={`${classes.subType} ${subType}`}>
              {NotificationsUtils.convertExportArchiveSubTypeToI18n(subType!, t)}
            </span>
          </MUI.Box>
        );
      case 'archive':
        return (
          <MUI.Box className={classes.messageBox}>
            <MUI.Typography className={`${classes.messageText} bold`}>
              {t('export.raw-data.archive')}
            </MUI.Typography>{' '}
            <span className={`${classes.subType} ${subType}`}>
              {NotificationsUtils.convertExportArchiveSubTypeToI18n(subType!, t)}
            </span>
          </MUI.Box>
        );
      case 'userstats':
        // eslint-disable-next-line no-case-declarations
        if (status === 'failed') {
          return (
            <MUI.Box className={classes.messageBox}>
              <MUI.Typography className={classes.messageText}>
                <Trans
                  i18nKey="notifications.userstats.failed"
                  components={{
                    bold: <span className={`${classes.messageText} bold`} />,
                  }}
                  values={{ email: authInfo.email, projectName }}
                />
              </MUI.Typography>
              <span className={`${classes.subType} failed`}>
                {t('autoLabel.cal.status.failed')}
              </span>
            </MUI.Box>
          );
        }
        if (status === 'started') {
          return (
            <MUI.Box className={classes.messageBox}>
              <MUI.Typography className={classes.messageText}>
                <Trans
                  i18nKey="notifications.userstats.started"
                  components={{
                    bold: <span className={`${classes.messageText} bold`} />,
                  }}
                  values={{ email: authInfo.email, projectName }}
                />
              </MUI.Typography>
              <span className={`${classes.subType} ${status}`}>
                {NotificationsUtils.convertExportArchiveSubTypeToI18n(status, t)}
              </span>
            </MUI.Box>
          );
        }
        if (status === 'finished') {
          return (
            <>
              <MUI.Box className={classes.messageBox}>
                <MUI.Typography className={classes.messageText}>
                  <Trans
                    i18nKey="notifications.userstats.finished"
                    components={{
                      bold: <span className={`${classes.messageText} bold`} />,
                    }}
                    values={{ email: authInfo.email, projectName }}
                  />
                </MUI.Typography>
                <span className={`${classes.subType} succeed`}>
                  {t('labels.autoLabelRequest.complete')}
                </span>
              </MUI.Box>
            </>
          );
        }
        return null;
      case 'custom_auto_label':
        if (payload?.state === 'failed') {
          return (
            <MUI.Box className={classes.messageBox}>
              <Trans t={t} i18nKey="notifications.failedToCreateCustomAutoLabel">
                <MUI.Typography className={`${classes.messageText} bold`}>
                  Failed to create a Custom Auto-Label AI
                </MUI.Typography>
                <MUI.Typography className={`${classes.messageText}`}>
                  {' '}
                  for {{ projectName }}
                </MUI.Typography>
              </Trans>
            </MUI.Box>
          );
        }
        return (
          <MUI.Box className={classes.messageBox}>
            <MUI.Typography className={`${classes.messageText} bold`}>
              {t('notifications.newCustomAutoLabelReadyForUse')}
            </MUI.Typography>
          </MUI.Box>
        );

      default:
        return null;
    }
  };

  const getCreatedAt = () => {
    switch (type) {
      case 'thread':
      case 'comment':
        return (
          <MUI.Typography className={classes.fromNowText}>
            {payload.createdAt && formatDistance(payload.createdAt)}
          </MUI.Typography>
        );
      case 'assign':
      case 'export':
      case 'userstats':
      case 'custom_auto_label':
        return (
          <MUI.Typography className={classes.fromNowText}>
            {createdAt && formatDistance(createdAt)}
          </MUI.Typography>
        );
      case 'invite':
      default:
        return null;
    }
  };

  const getIssueContent = () => {
    if (type !== 'thread' && type !== 'comment') return null;
    return (
      <MUI.Box className={classes.issueBox}>
        <MUI.Box className={classes.issueLeft}>
          <MUI.Avatar className={classes.threadNumberAvatar} style={{ background: threadColor }}>
            {threadNumber}
          </MUI.Avatar>
        </MUI.Box>
        <MUI.Box className={classes.issueRight}>
          <Mentions value={firstComment} boxProps={{ width: '100%' }} />
          {type === 'comment' && (
            <>
              <MUI.Typography className={`${classes.issueRightText} name`}>
                {t('notifications.someoneReplied', { fromFirstName })}
              </MUI.Typography>
              <Mentions value={replyComment} boxProps={{ width: '100%' }} />
            </>
          )}
        </MUI.Box>
      </MUI.Box>
    );
  };

  const getExportContext = () => {
    if (type !== 'export') return null;
    return (
      <MUI.Box className={classes.messageBox}>
        <Trans t={t} i18nKey="notifications.objectsInProject">
          <MUI.Typography className={`${classes.messageText} bold`}>
            {{ objectCount: t('labels.labelCount', { count: labelCount }) }}
          </MUI.Typography>
          <MUI.Typography className={classes.messageText}> in </MUI.Typography>
          <MUI.Typography className={`${classes.messageText} bold`}>
            {{ projectName }}
          </MUI.Typography>
        </Trans>
      </MUI.Box>
    );
  };

  const getArchiveContext = () => {
    if (type !== 'archive') return null;
    if (!fileCount) return null;
    return (
      <MUI.Box className={classes.messageBox}>
        <Trans t={t} i18nKey="notifications.objectsInProject">
          <MUI.Typography className={`${classes.messageText} bold`}>
            {' '}
            {{ objectCount: t('export.raw-data.fileCount', { count: fileCount }) }}
          </MUI.Typography>
          <MUI.Typography className={classes.messageText}> in </MUI.Typography>
          <MUI.Typography className={`${classes.messageText} bold`}>
            {{ projectName }}
          </MUI.Typography>
        </Trans>
      </MUI.Box>
    );
  };

  const getLabel = () => {
    if (type === 'custom_auto_label') {
      return (
        <MUI.Box position="relative" width="100%" height="34px" mt="8px" mb="8px">
          <MUI.Box className={classes.labelBox}>
            <Icon size="s" name="autoLabel" color="secondary" />
            <MUI.Typography className={classes.labelText}>{payload.name}</MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      );
    }

    return null;
  };

  const getButton = () => {
    if (type === 'custom_auto_label') {
      if (payload.state === 'failed') {
        return (
          <MUI.Box className={classes.buttonBox}>
            <Button variant="soft-fill-stroke" color="grey">
              {t('notifications.button.goToCustomAutoLabel')}
            </Button>
          </MUI.Box>
        );
      }
      return (
        <MUI.Box className={classes.buttonBox}>
          <Button variant="soft-fill-stroke" color="secondary">
            {t('notifications.button.applyCustomAutoLabel')}
          </Button>
        </MUI.Box>
      );
    }
    return null;
  };

  if (type === 'upload') {
    return (
      <UploadNotification
        parentClasses={classes}
        accountName={authInfo.accountName!}
        notification={notification as UploadNotificationObject}
      />
    );
  }

  return (
    <MUI.Box className={classes.box} onClick={handleClickNotification}>
      <MUI.Box className={classes.left}>{getEmoji()}</MUI.Box>
      <MUI.Box className={classes.center}>
        {getMessage()}
        {getArchiveContext()}
        {getExportContext()}
        {getCreatedAt()}
        {getIssueContent()}
        {getLabel()}
        {getButton()}
      </MUI.Box>
      <MUI.Box className={classes.right}>
        {type !== 'welcome' && !checked && (
          <MUI.Box className={classes.newTextBox}>
            <MUI.Box className={classes.circle} />
            <MUI.Typography className={classes.newText}>{t('notifications.new')}</MUI.Typography>
          </MUI.Box>
        )}
      </MUI.Box>
    </MUI.Box>
  );
};

export default Notification;
