import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import styled from '@emotion/styled';
import { Button, Dialog, useDialogState } from '@superb-ai/ui';

import { useEditModelMemoMuataion, useModelDetailQuery } from '../../queries/modelQueries';
import { PurposeType } from '../../services/types';

export const MEMO_MAX_LENGTH = 1000;

export const EditMemoDialog = ({
  id,
  state,
}: {
  id: string;
  state: ReturnType<typeof useDialogState>;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const [memo, setMemo] = useState('');
  const { mutate } = useEditModelMemoMuataion();
  const { data } = useModelDetailQuery({ id, modelPurpose: params.purpose });

  useEffect(() => {
    if (!data) return;
    setMemo(data.memo);
  }, []);

  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.editMemoDialog.title')}
      </Dialog.Header>
      <Dialog.Content>{t('model.editMemoDialog.content')}</Dialog.Content>
      <Textarea
        placeholder={t('model.editMemoDialog.placeholder')}
        value={memo}
        onChange={e => {
          if (e.target.value.length > MEMO_MAX_LENGTH) return;
          setMemo(e.target.value);
        }}
      />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          onClick={() => {
            mutate({ modelId: id, memo });
            state.hide();
          }}
        >
          {t('shared.apply')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  borderradius: 2px;
  height: 188px;
  padding-block: 8px;
  padding-inline: 12px;
  &::placeholder {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b3b3b3;
  }
`;
