import React, { useContext, useEffect, useRef, useState } from 'react';
import { Group, Layer, Rect } from 'react-konva';

import { map } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import ImageContext from '../../../../contexts/ImageContext';
import { useLabelCompareInfo } from '../../../../contexts/LabelCompareContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import Badge from './shapes/Badge';
import Shape from './shapes/Shape';
import SuiteImageKonva from './SuiteImageKonva';

let tx = 0;
let ty = 0;
let mouseDownTargetId: string | null = null;
let isMouseMove = false;
let isSpaceMoved = false;

// eslint-disable-next-line
const SuiteImage = ({ updatePanelPos }: any) => {
  const imageLayerRef = useRef<any>();
  const imageRef = useRef<any>();
  const [hoverIndex, setHoverIndex] = useState(1);
  const [isSpacePressed, setIsSpacePressed] = useState(false);
  const [mouseState, setMouseState] = useState(null);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelCompareInfo = useLabelCompareInfo();
  const labelIssuesInfo = useLabelIssues();
  const labelsInfo = useLabelsInfo();
  const imageInfo = useContext(ImageContext.Context);
  const { imageUrlsByAssetId } = labelsInfo;
  const {
    history: {
      location: { pathname, search },
    },
  } = routeInfo;
  const { x, y, setX, setY, width, height, rate, setImageByUrl, brightness, contrast } = imageInfo;

  const {
    label,
    index,
    isDisabledAlertActive,
    setIsDisabledAlertActive,
    isAutoLabelUncertainty,
    isActiveAutoLabelUncertainPanel,
    setIsActiveAutoLabelUncertainPanel,
  } = labelDetailViewInfo;

  const { activeCompareOptionArr } = labelCompareInfo;

  const {
    hoveredAnnotation,
    setHoveredAnnotation,
    cabinetTabState,
    setCabinetTabState,
    clickedAnnotationInfo,
    setClickedAnnotationInfo,
  } = labelInformationInfo;

  const {
    issues,
    issueViewStatus,
    nextIssue,
    issuePanelState,
    setIssuePanelState,
    openBadgeInfo,
    setOpenBadgeInfo,
  } = labelIssuesInfo;

  useEffect(() => {
    const downHandler = (e: any) => {
      if (e.code === 'Space') {
        setIsSpacePressed(true);
        document.body.style.cursor = 'move';
      }
    };

    const upHandler = (e: any) => {
      if (e.code === 'Space') {
        setIsSpacePressed(false);
        document.body.style.cursor = 'default';
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
      document.body.style.cursor = 'default';
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!imageLayerRef || !imageLayerRef.current) return;
    if (isSpacePressed) return;
    if (mouseState === 2) return;

    imageLayerRef.current.getStage().x(x);
    imageLayerRef.current.getStage().y(y);
    imageLayerRef.current.getStage().scale({ x: rate, y: rate });
    imageLayerRef.current.getStage().batchDraw();

    if (openBadgeInfo?.ref?.current) {
      updatePanelPos(
        x + openBadgeInfo.ref.current.x() * rate,
        y + openBadgeInfo.ref.current.y() * rate,
        issuePanelState,
      );
    }
    // eslint-disable-next-line
  }, [imageLayerRef.current, rate]);

  useEffect(() => {
    if (!label || index === null) return;
    if (!imageUrlsByAssetId[label.asset.id]) return;
    if (imageRef.current) return;
    // getExpireTimeFromSignedUrl(imageUrlsByAssetId[label.asset.id]);

    setImageByUrl(imageUrlsByAssetId[label.asset.id]);
    // eslint-disable-next-line
  }, [imageUrlsByAssetId, label, imageRef.current]);

  const handleMouseDownLayer = (e: any) => {
    if (!imageRef?.current?.attrs?.width) return;
    e.evt.stopPropagation();
    mouseDownTargetId = e.target.attrs.id;

    if (mouseDownTargetId === 'badge') return;

    const { layerX, layerY } = e.evt;
    tx = layerX;
    ty = layerY;
    isMouseMove = false;
    isSpaceMoved = false;
    setMouseState(e.evt.button);
  };

  const moveImage = (e: any): any => {
    const { layerX, layerY } = e.evt;

    const deltaX = layerX - tx;
    const deltaY = layerY - ty;

    tx = layerX;
    ty = layerY;

    const nextX = imageInfo.x + deltaX;
    const nextY = imageInfo.y + deltaY;

    setX(nextX);
    setY(nextY);

    if (openBadgeInfo?.ref?.current?.x) {
      updatePanelPos(
        x + openBadgeInfo.ref.current.x() * rate + deltaX,
        y + openBadgeInfo.ref.current.y() * rate + deltaY,
        issuePanelState,
      );
    }
    if (imageLayerRef.current) {
      imageLayerRef.current.getStage().x(nextX);
      imageLayerRef.current.getStage().y(nextY);

      imageLayerRef.current.x(0);
      imageLayerRef.current.y(0);
      imageLayerRef.current.getLayer().batchDraw();
    }
  };

  const calIsMove = (e: any) => {
    const { layerX, layerY } = e.evt;

    const deltaX = layerX - tx;
    const deltaY = layerY - ty;

    tx = layerX;
    ty = layerY;
    if (deltaX * deltaX + deltaY * deltaY > 20) {
      isMouseMove = true;
    }
  };

  const handleMouseMoveLayer = (e: any) => {
    if (!imageRef?.current?.attrs?.width) return;
    if (mouseState === null) return;
    if (mouseDownTargetId === 'badge') return;

    if (mouseState === 0) {
      if (isSpacePressed) {
        moveImage(e);
        if (!isSpaceMoved) isSpaceMoved = true;
        return;
      }

      if (!isMouseMove) {
        calIsMove(e);
        return;
      }

      if (!isDisabledAlertActive) {
        setIsDisabledAlertActive(true);
      }
      return;
    }

    if (mouseState === 2) {
      moveImage(e);
    }
  };

  const createIssue = (e: any) => {
    const { offsetX, offsetY } = e.evt;

    const x = (offsetX - imageInfo.x) / imageInfo.rate;
    const y = (offsetY - imageInfo.y) / imageInfo.rate;
    nextIssue.info.target.point.x = x;
    nextIssue.info.target.point.y = y;

    setOpenBadgeInfo({
      issue: nextIssue,
      ref: null,
    });
    updatePanelPos(offsetX, offsetY, 'create');

    setIssuePanelState('create');
  };

  const handleMouseUpLayer = (e: any) => {
    if (!imageRef?.current?.attrs?.width) return;
    setMouseState(null);

    // cmd + click으로 issue 생성하기
    if (e.evt.metaKey || e.evt.ctrlKey) {
      if (authInfo.isGuest) return;

      if (cabinetTabState === 'information') {
        isSpaceMoved = false;
        isMouseMove = false;
        setCabinetTabState('issue');
        createIssue(e);
        return;
      }
    }

    if (
      cabinetTabState === 'information' ||
      isMouseMove ||
      mouseDownTargetId === 'badge' ||
      isSpaceMoved ||
      mouseState === 2
    ) {
      isSpaceMoved = false;
      isMouseMove = false;
      return;
    }

    // 1. close thread, option, create
    switch (issuePanelState) {
      case null:
        createIssue(e);
        break;
      case 'create':
      case 'thread':
      case 'option':
        setIssuePanelState(null);
        routeInfo.history.push(`${pathname}${search}${`#label_id=${label.id}`}`);
        break;
      default:
        break;
    }
  };

  const handleMouseLeaveLayer = () => {
    setMouseState(null);
    isMouseMove = false;
    mouseDownTargetId = null;
  };

  const handleContextMenu = (e: any) => {
    e.evt.preventDefault();
  };

  if (!imageInfo.image) {
    return (
      <Layer>
        <Group />
      </Layer>
    );
  }

  const getAnnotations = () => {
    return activeCompareOptionArr.map((item: any) =>
      item.info.shapes.map((shape: any) => (
        <Shape
          key={shape.id}
          labelInfo={item}
          shape={shape}
          hoverIndex={hoverIndex}
          setHoverIndex={setHoverIndex}
          isCurrent={item.category === 'current'}
        />
      )),
    );
  };

  const getIssueBadges = () => {
    if (cabinetTabState !== 'issue') return null;
    return map(
      issues,
      issue =>
        issue.status === issueViewStatus && (
          <Badge key={issue.id} isCreate={false} issue={issue} updatePanelPos={updatePanelPos} />
        ),
    );
  };

  const getCreateIssueBadge = () => {
    if (cabinetTabState !== 'issue' || issuePanelState !== 'create') return null;

    return <Badge isCreate issue={nextIssue} updatePanelPos={updatePanelPos} />;
  };

  const getActiveAutoLabelUncertainPanel = () => {
    if (!isAutoLabelUncertainty || !isActiveAutoLabelUncertainPanel) return null;

    const handleClickAutoLabelUncertainPanel = (e: any) => {
      e.evt.stopPropagation();

      setIsActiveAutoLabelUncertainPanel(false);
    };
    return (
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="#000"
        opacity={0.5}
        onClick={handleClickAutoLabelUncertainPanel}
        cursor="pointer"
      />
    );
  };

  if (!activeCompareOptionArr) return <></>;

  return (
    <Layer
      id="layer"
      ref={imageLayerRef}
      onMouseDown={handleMouseDownLayer}
      onMouseMove={handleMouseMoveLayer}
      onMouseUp={handleMouseUpLayer}
      onMouseLeave={handleMouseLeaveLayer}
      onContextMenu={handleContextMenu}
    >
      <SuiteImageKonva
        {...{
          imageRef,
          setHoverIndex,
          width,
          height,
          clickedAnnotationInfo,
          setClickedAnnotationInfo,
          hoveredAnnotation,
          setHoveredAnnotation,
          cabinetTabState,
          brightness,
          contrast,
        }}
        image={imageInfo.image}
      />
      {getAnnotations()}
      {getIssueBadges()}
      {getCreateIssueBadge()}
      {getActiveAutoLabelUncertainPanel()}
    </Layer>
  );
};

export default SuiteImage;
