import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

export const NoDataToShow = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      mx="auto"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      style={{
        color: 'gray',
        fontSize: '12px',
      }}
    >
      {t('curate.analytics.text.noDataToDisplay')}
    </Box>
  );
};
