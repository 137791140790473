import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';

import MathUtils from '../../../../utils/MathUtils';
import { getOpacity } from '../tools/helper';
import { AnnotationChangeType } from './types/types';
import { getTextColor } from './utils/colorScale';
import { getChangeTypeDisplayName } from './utils/header';
import { arcGenerator, getPieceAngleInDegree, midPointCalculator } from './utils/helper';
import {
  generatePolylinePath,
  OFFSET_POLYLINE_PERCENT,
  OFFSET_POLYLINE_TEXT,
  OFFSET_POLYLINE_TEXT_POSITION,
  placeOnLeftOrRight,
  PolylinePoints,
  SCALE_POLYLINE_LENGTH,
} from './utils/pieChartHelper';

export interface PieProps {
  value: number;
  key: string;
}

interface Props {
  data: PieProps[];
  getColor: (changeType: AnnotationChangeType) => string;
  hoveredIndex: number;
  highlightPiece: (i: number, status?: 'in' | 'out', piece?: d3.PieArcDatum<PieProps>) => void;
}

const OverallTrendPieChart = ({ getColor, data, hoveredIndex, highlightPiece }: Props) => {
  const { t } = useTranslation();
  const svgInfo = {
    svgWidth: 418,
    svgHeight: 340,
    radius: 145,
    innerRadius: 0,
  };

  const gTransform = `translate(${svgInfo.svgWidth / 2},${svgInfo.svgHeight / 2})`;
  const arc = arcGenerator(svgInfo.innerRadius, svgInfo.radius);
  const innerArcForLabelsPosition = arcGenerator(svgInfo.radius * 0.9, svgInfo.radius * 0.9);
  const outerArcForLabelsPosition = arcGenerator(svgInfo.radius * 1.1, svgInfo.radius * 1.1);

  const pieData = d3.pie<PieProps>().value(d => d.value)(data);
  const total = data.reduce((sum, item) => (sum += item.value), 0);

  const getPieces = (pieces: d3.PieArcDatum<PieProps>[], hoveredIndex: number) => {
    return pieces.map((piece, i) => {
      const percent = MathUtils.calculatePercent({
        numerator: data[i].value,
        denominator: total,
        nearest: 'hundredth',
      });
      const changeType = piece.data.key as AnnotationChangeType;
      const pieceColor = getColor(changeType);
      const darken = (color: string): string => d3.rgb(color).darker(0.3).toString();
      const pieceAngle = getPieceAngleInDegree(piece.startAngle, piece.endAngle);
      const displayChangeType = getChangeTypeDisplayName(changeType, t);
      const defaultObject: d3.DefaultArcObject = {
        innerRadius: svgInfo.innerRadius,
        outerRadius: svgInfo.radius,
        startAngle: piece.startAngle,
        endAngle: piece.endAngle,
      };

      const posA = innerArcForLabelsPosition.centroid(defaultObject);
      const posB = outerArcForLabelsPosition.centroid(defaultObject);
      const midpoint = midPointCalculator(arc.centroid(defaultObject), posA);
      const midAngle = piece.startAngle + (piece.endAngle - piece.startAngle) / 2;
      const posC: PolylinePoints = [
        svgInfo.radius * SCALE_POLYLINE_LENGTH * placeOnLeftOrRight(midAngle),
        posB[1],
      ];
      const posD: PolylinePoints = [
        svgInfo.radius * OFFSET_POLYLINE_TEXT_POSITION * placeOnLeftOrRight(midAngle),
        posB[1],
      ];
      const points = generatePolylinePath(posA, posB, posC);

      return (
        <>
          <path
            key={`${piece.data.key}` + `${piece.index}`}
            fill={hoveredIndex === i ? darken(pieceColor) : pieceColor}
            stroke="white"
            strokeWidth="1px"
            height={300}
            width={300}
            onMouseOver={() => highlightPiece(i, 'in', piece)}
            onMouseOut={() => highlightPiece(i, 'out', piece)}
            opacity={getOpacity(i, hoveredIndex)}
            d={
              arc({
                startAngle: piece.startAngle,
                endAngle: piece.endAngle,
                innerRadius: svgInfo.innerRadius,
                outerRadius: svgInfo.radius,
              }) as string
            }
          />
          {pieceAngle > 36 && (
            <g>
              <text
                key={`type-${piece.data.key}` + `${piece.index}`}
                style={{
                  fill: `${getTextColor(pieceColor)}`,
                  pointerEvents: 'none',
                  transform: `translateX(${
                    pieceAngle > 90 ? arc.centroid(defaultObject)[0] : midpoint[0]
                  }px) translateY(${
                    pieceAngle > 90 ? arc.centroid(defaultObject)[1] - 10 : midpoint[1] - 10
                  }px)  `,
                  textAnchor: 'middle',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                {displayChangeType}
              </text>
              <text
                key={`percent-${piece.data.key}` + `${piece.index}`}
                style={{
                  fill: `${getTextColor(pieceColor)}`,
                  pointerEvents: 'none',
                  transform: `translateX(${
                    pieceAngle > 90 ? arc.centroid(defaultObject)[0] : midpoint[0]
                  }px) translateY(${
                    pieceAngle > 90 ? arc.centroid(defaultObject)[1] + 10 : midpoint[1] + 10
                  }px)  `,
                  textAnchor: 'middle',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                {percent} %
              </text>
            </g>
          )}
          {/* polyline */}
          {pieceAngle <= 36 && hoveredIndex === i && (
            <g>
              <text
                key={`polyline-text-${piece.data.key}` + `${piece.index}`}
                style={{
                  transform: `translateX(${posD[0] + OFFSET_POLYLINE_TEXT}px) translateY(${
                    posD[1] + 5
                  }px)`,
                  textAnchor: `${midAngle < Math.PI ? 'start' : 'end'}`,
                }}
              >
                {displayChangeType}
              </text>
              <text
                key={`polyline-percent-${piece.data.key}` + `${piece.index}`}
                style={{
                  transform: `translateX(${posD[0] + OFFSET_POLYLINE_PERCENT}px) translateY(${
                    posD[1] + 5
                  }px)`,
                  textAnchor: `${midAngle < Math.PI ? 'start' : 'end'}`,
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                {percent} %
              </text>
              <polyline
                style={{
                  stroke: '#333333',
                  strokeWidth: '1px',
                  fill: 'none',
                }}
                points={points}
              />
            </g>
          )}
        </>
      );
    });
  };

  return (
    <div id="overall-trend-pie-chart" style={{ margin: 'auto' }}>
      <svg
        style={{
          overflow: 'visible',
          width: svgInfo.svgWidth,
          height: svgInfo.svgHeight,
        }}
        id={'overall-trend-pie-chart-svg'}
      >
        <g transform={gTransform} className="pie-chart">
          {getPieces(pieData, hoveredIndex)}
        </g>
      </svg>
    </div>
  );
};

export default OverallTrendPieChart;
