import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@superb-ai/icons';
import { Box, Icon, Input, Typography } from '@superb-ai/ui';
import { isEmpty, trim } from 'lodash';

import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';
import AnnotationTypeButton from './AnnotationTypeButton';
import { ClassAndProperties } from './type';

export default function AnnotationTypeSection({
  curateAnnotations,
  setCurateAnnotations,
  selectedAnnotationType,
  setSelectedAnnotationType,
}: {
  curateAnnotations?: ClassAndProperties;
  setCurateAnnotations: Dispatch<SetStateAction<ClassAndProperties | undefined>>;
  selectedAnnotationType?: CurateAnnotationType;
  setSelectedAnnotationType: Dispatch<SetStateAction<CurateAnnotationType | undefined>>;
}) {
  const { t } = useTranslation();

  const annotationTypes = ['box', 'polygon'] as CurateAnnotationType[];
  const [searchedAnnotationTypes, setSearchedAnnotationTypes] =
    useState<CurateAnnotationType[]>(annotationTypes);

  function checkIsAdded(annotationType: CurateAnnotationType) {
    return curateAnnotations && Object.keys(curateAnnotations).includes(annotationType);
  }

  function handleSearchAnnotationType(value: string) {
    const searchedAnnotationTypes = annotationTypes.filter(annotationType =>
      annotationType.includes(value),
    );
    setSearchedAnnotationTypes(searchedAnnotationTypes);
  }

  return (
    <Box style={{ width: '140px' }} height="100%" borderRight="1px solid" borderColor="gray-200">
      <Box p={0.5} borderBottom="1px solid" borderColor="gray-200">
        <Typography variant="s-strong" color="gray-300">
          {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.annotationType')}
        </Typography>
      </Box>
      <Box borderBottom="1px solid" borderColor="gray-200">
        <Input
          variant="text"
          prefix={<Icon icon={Search} />}
          placeholder={t(
            'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.annotationTypeSearchPlaceholder',
          )}
          onChange={e => handleSearchAnnotationType(trim(e.target.value))}
        />
      </Box>
      <Box>
        {searchedAnnotationTypes.map(annotationType => (
          <AnnotationTypeButton
            key={annotationType}
            annotationType={annotationType}
            isAdded={checkIsAdded(annotationType)}
            classCount={
              (curateAnnotations?.[annotationType] &&
                Object.keys(curateAnnotations[annotationType]).length) ||
              0
            }
            isSelected={selectedAnnotationType === annotationType}
            onAddOrDelete={(type: 'add' | 'delete') => {
              if (type === 'add') {
                setCurateAnnotations(prev => {
                  const prevState = prev || ({} as ClassAndProperties);
                  return {
                    ...prevState,
                    [annotationType]: {},
                  };
                });
              } else {
                setCurateAnnotations(prev => {
                  const prevState = prev || ({} as ClassAndProperties);
                  const newProjectClassAndProperty = { ...prevState };

                  if (newProjectClassAndProperty[annotationType]) {
                    delete newProjectClassAndProperty[annotationType];
                  }
                  setSelectedAnnotationType(
                    isEmpty(newProjectClassAndProperty)
                      ? undefined
                      : (Object.keys(newProjectClassAndProperty)[0] as CurateAnnotationType),
                  );
                  return newProjectClassAndProperty;
                });
              }
            }}
            setIsSelected={(isSelected: boolean) => {
              if (isSelected) {
                setSelectedAnnotationType(annotationType);
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
