import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR, RESEND_ACCOUNT_ACTIVATION_LINK } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useAuthService } from '../../../services/NewAuthService';
import GoToPage from './GoToPage';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';

export default function CreateAccountExpiredLayout() {
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { resendCode } = useAuthService();
  const { code, email, tenantId } = routeInfo.params;

  useChangeContent('graphic');

  useEffect(() => {
    if (!code) {
      routeInfo.history.push('/auth/login');
    }
  }, []);

  const handleClickResendCode = async () => {
    try {
      await resendCode({ email: atob(email), tenantUuid: tenantId });
      openModal(RESEND_ACCOUNT_ACTIVATION_LINK({ t, closeModal }));
    } catch (e: any) {
      openModal(DEFAULT_ERROR({ t, message: e.message, onClickMainButton: closeModal }));
    }
  };

  return (
    <>
      <ShortCut page="signIn" />
      <Box width="100%" display="flex" flexDirection="column" gap={2}>
        <Title>{t('auth.title.expired')}</Title>
        <Box display="flex" flexDirection="column">
          <Typography variant="m-regular">{t('auth.messages.activationLinkHasExpired')}</Typography>
          {routeInfo.params.code === '201412' ? (
            <Typography variant="m-regular">
              {t('auth.messages.accountAlreadyActivated')}
            </Typography>
          ) : (
            <Typography variant="m-regular">
              <Trans t={t} i18nKey="auth.messages.clickToResendActivationLink">
                <LinkTypography color="secondary" onClick={handleClickResendCode}>
                  Click here to resend
                </LinkTypography>{' '}
                account activation link.
              </Trans>
            </Typography>
          )}
        </Box>
        <GoToPage to="login">{t('auth.goToSignIn')}</GoToPage>
      </Box>
    </>
  );
}
