import { ComponentProps, forwardRef, ReactNode } from 'react';

import { Box, Input, PasswordInput, Typography } from '@superb-ai/ui';

interface Props extends ComponentProps<typeof Input> {
  label?: string;
  info?: ReactNode;
  error?: ReactNode;
}

export default forwardRef<HTMLInputElement | null, Props>(function LabeledInput(
  { label, info, error, ref: _ref, type, placeholder, ...props },
  ref,
) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={1}
      style={{ minWidth: 0 }}
    >
      {(label || error) && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          {(label || error) && (
            <Typography variant="m-medium" color="gray-400">
              {label}
            </Typography>
          )}
          {error && (
            <Typography variant="s-regular" color="primary">
              {error}
            </Typography>
          )}
        </Box>
      )}
      {type === 'password' ? (
        <PasswordInput
          variant="stroke"
          color="gray"
          size="l"
          placeholder={label}
          type={type}
          {...props}
          ref={ref}
        />
      ) : (
        <Input
          variant="stroke"
          color="gray"
          size="l"
          placeholder={placeholder || label}
          type={type}
          {...props}
          ref={ref}
        />
      )}
      {info && (
        <Typography color="gray-400" variant="m-regular">
          {info}
        </Typography>
      )}
    </Box>
  );
});
