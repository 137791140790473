import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Download } from '@superb-ai/icons';
import { Box, IconButton, Tooltip, Typography } from '@superb-ai/ui';

import { ChartSpec } from '../AnnotationsContainer';
import { LoadingIndicatorDiv } from './LoadingIndicatorDiv';

const divStyle = {
  width: '100%',
  height: '600px',
  boxSizing: 'border-box',
  border: '1px solid #DCDCDC',
  borderRadius: '8px',
} as const;

export const ChartCard = ({
  data,
  isLoading,
  handleDownload,
  chartSpec,
  chartComponent,
  chartTitle,
  chartLegend,
  chartTopRightArea,
  style,
}: {
  data: any[];
  isLoading: boolean;
  handleDownload: (data: any[], chartSpec: ChartSpec) => void;
  chartComponent?: ReactElement;
  chartLegend?: ReactElement;
  chartSpec: ChartSpec;
  chartTitle: string;
  chartTopRightArea?: ReactElement;
  style?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      style={{ ...divStyle, ...style }}
      flexDirection="column"
      alignItems="flex-start"
      gap={0.5}
    >
      <Box
        display="flex"
        width="100%"
        p={1}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        borderBottom="1px solid"
        borderColor="gray-250"
      >
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="l-strong" color="gray-400" pr={0.5}>
              {chartTitle}
            </Typography>
            <Tooltip content={t('curate.analytics.action.downloadCSV')} placement="bottom">
              <IconButton
                color="black"
                disabled={data.length === 0}
                icon={Download}
                onClick={() => handleDownload(data, chartSpec)}
                variant="text"
              />
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" gap={0.5}>
            {chartLegend}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        px={1}
        py={1}
        width="100%"
        height="100%"
        position="relative"
        overflow="auto"
      >
        {isLoading ? <LoadingIndicatorDiv /> : chartComponent}
        {chartTopRightArea && chartTopRightArea}
      </Box>
    </Box>
  );
};
