import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { CHART_NAME_EMPTY_ERROR } from '../../../../../consts/SnackbarMessage';

type Props = {
  defaultTitle: string;
  width: number;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
};

export const EditableTitle = (props: Props) => {
  const { t } = useTranslation();
  const { defaultTitle, width, editMode, setEditMode } = props;
  const { enqueueSnackbar } = useSnackbar();
  const titleRef = useRef<HTMLInputElement>(null);
  const [chartName, setChartName] = useState<string>(defaultTitle);

  const activateEditTitle = () => {
    setChartName(chartName);
    setEditMode(!editMode);
    // isHistoryNameChangeButtonOver(false);
  };

  const handleTitleChange = () => {
    if (chartName === null || chartName === '') {
      enqueueSnackbar(CHART_NAME_EMPTY_ERROR({ t }), { variant: 'error' });
      setEditMode(false);
      setChartName(defaultTitle);
      return;
    }
    // TODO: change chart name and persist
    setEditMode(false);
  };

  return (
    <>
      {!editMode ? (
        <Typography
          m={1}
          variant="m-strong"
          style={{
            cursor: 'pointer',
            letterSpacing: 'normal',
            wordSpacing: 'normal',
          }}
          onDoubleClick={activateEditTitle}
        >
          {chartName}
        </Typography>
      ) : (
        <Input
          type="text"
          value={chartName}
          autoFocus
          placeholder={chartName}
          variant="text"
          size="m"
          maxLength={50}
          style={{
            // width: '10px',
            fontWeight: '600',
            padding: 0,
          }}
          ref={titleRef}
          onChange={event => setChartName(event.target.value)}
          onKeyDown={event => {
            if (event.key === 'Enter') handleTitleChange();
          }}
          onBlur={() => handleTitleChange()}
        />
      )}
    </>
  );
};
