import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@superb-ai/norwegian-forest';
import copy from 'copy-to-clipboard';

type Props = { text: string } & Omit<React.ComponentProps<typeof IconButton>, 'icon'>;

export function CopyToClipboardButton({ text, ...btnProps }: Props) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  function onClick() {
    copy(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const button = (
    <IconButton
      color="lightgrey"
      variant="stroke"
      size="s"
      icon={copied ? 'check' : 'copy'}
      onClick={onClick}
      {...btnProps}
    />
  );

  return <Tooltip anchorEl={button}>{t('analytics.userReports.button.copyToClipboard')}</Tooltip>;
}
