import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { isOwnerOrAdmin } from '../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../menu/MenuItem';
import { AccessKeysMenuItem } from './keys/MenuItem';
import Layout from './Layout';
import { AccessManageMemberMenuItem } from './manage/MenuItem';

export const AccessMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountAccessSettings,
  path: 'access',
  title: ({ t }) => t('settings.advanced.title'),
  component: Layout,
  icon: 'link2',
  isVisible({ authInfo }) {
    return isOwnerOrAdmin(authInfo);
  },
  isEnabled({ authInfo }) {
    return isOwnerOrAdmin(authInfo);
  },
  children: [AccessKeysMenuItem, AccessManageMemberMenuItem],
});
