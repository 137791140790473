import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, Typography } from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useNewProjectInfo } from '../../../../../contexts/NewProjectContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ProjectsService from '../../../../../services/ProjectsService';
import { Keypoint } from './type';

type Option = {
  label: string;
  value: string;
};

type Props = {
  keypoint: Keypoint | null;
  setKeypoint: (keypoint: Keypoint | null) => void;
  selectedProject: null | any;
  setSelectedProject: (project: any) => void;
  setErrorMessage: (message: string) => void;
};

const ImportCustomAutoLabel: React.FC<Props> = ({
  selectedProject,
  setSelectedProject,
  keypoint,
  setKeypoint,
  setErrorMessage,
}) => {
  const { t } = useTranslation();
  const [loadedProjects, setLoadedProjects] = useState<any>({});
  const [loadedKeypoints, setLoadedKeypoints] = useState<any>({});
  const [projects] = useState(
    selectedProject ? [{ label: selectedProject.name, value: selectedProject.id }] : [],
  );
  const [keypoints, setKeypoints] = useState<Option[]>(
    keypoint ? [{ label: keypoint.name, value: keypoint.id }] : [],
  );
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const newProjectInfo = useNewProjectInfo();
  const { keypointList } = newProjectInfo;
  const [curProjectId] = useState(projectInfo?.project?.id || '');
  const [page, setPage] = useState(1);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);

  useEffect(() => {
    if (!selectedProject) {
      setErrorMessage(t('projects.createProject.keypoint.pleaseSelectProject'));
      return;
    }

    if (!keypoint) {
      setErrorMessage(t('projects.createProject.keypoint.pleaseSelectKeypoint'));
      return;
    }

    setErrorMessage('');
  }, [selectedProject, keypoint]);

  const handleChangeProject = (projectId: string) => {
    const nextSelectedProject = loadedProjects[projectId];
    setSelectedProject(nextSelectedProject);
    setErrorMessage(t('projects.createProject.keypoint.changingProject'));

    const keypoints: Keypoint[] =
      nextSelectedProject?.labelInterface?.objectDetection?.keypoints ||
      nextSelectedProject?.labelInterface?.objectTracking?.keypoints ||
      [];
    const filteredKeypoints = keypoints.filter(
      keypoint => !keypointList.some((keypointItem: Keypoint) => keypointItem.id === keypoint.id),
    );
    setLoadedKeypoints(
      filteredKeypoints.reduce((acc: any, keypoint) => {
        acc[keypoint.id] = keypoint;
        return acc;
      }, {}),
    );
    setKeypoints(filteredKeypoints.map(keypoint => ({ label: keypoint.name, value: keypoint.id })));
    setKeypoint(null);
  };

  const addToLoadedProjects = (projects: any[]) => {
    const nextLoadedProjects = cloneDeep(loadedProjects);
    for (const project of projects) {
      if (!nextLoadedProjects[project.id]) {
        nextLoadedProjects[project.id] = project;
      }
    }
    setLoadedProjects(nextLoadedProjects);
  };

  const loadMoreProjects = async (query: string): Promise<any> => {
    const nextPage = page + 1;
    const params = { page: nextPage, nameIcontains: query };
    try {
      const projectsLoaded = await ProjectsService.getProjects({
        params,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setPage(nextPage);
      addToLoadedProjects(projectsLoaded.results);
      return projectsLoaded.results
        .filter(project => project.id !== curProjectId)
        .map(project => ({ label: project.name, value: project.id }));
    } catch (err: any) {
      setHasMoreProjects(false);
      return [];
    }
  };

  const getProjectsOptions = async (query: string): Promise<any> => {
    try {
      const projectsLoaded = await ProjectsService.getProjects({
        params: { page: 1, nameIcontains: query },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setHasMoreProjects(true);
      addToLoadedProjects(projectsLoaded.results);
      return projectsLoaded.results
        .filter(project => project.id !== curProjectId)
        .map(project => ({ label: project.name, value: project.id }));
    } catch (err: any) {
      setHasMoreProjects(false);
      return [];
    }
  };

  const handleChangeKeypoint = (keypointId: string) => {
    const nextImportedKeypoint = loadedKeypoints[keypointId];
    setKeypoint(nextImportedKeypoint);
  };

  return (
    <Box
      width="220px"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="16px"
    >
      <Typography variant="headline6">{t('integration.listColumn.project')}</Typography>
      <Box width="100%" display="flex">
        <div style={{ width: '100%', display: 'flex' }} onKeyDown={e => e.stopPropagation()}>
          <DropdownList
            placeholder={t('projects.selectProject')}
            options={projects}
            value={selectedProject?.id || ''}
            hasMore={hasMoreProjects}
            loadMore={loadMoreProjects}
            hasSearch
            getOptions={getProjectsOptions}
            onChange={handleChangeProject}
          />
        </div>
      </Box>
      <Typography variant="headline6">{t('projects.createProject.keypoint.title')}</Typography>
      <Box width="100%" display="flex">
        <div style={{ width: '100%', display: 'flex' }} onKeyDown={e => e.stopPropagation()}>
          <DropdownList
            placeholder={t('projects.createProject.keypoint.selectKeypoint')}
            options={keypoints}
            value={keypoint?.id || ''}
            onChange={handleChangeKeypoint}
          />
        </div>
      </Box>
    </Box>
  );
};

export default ImportCustomAutoLabel;
