import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';

import { useNewProjectInfo } from '../../../contexts/NewProjectContext';

const useStyles = makeStyles(theme => ({
  box: {},
  stepper: {
    padding: 0,
  },
  stepLabel: {
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      color: theme.palette.grey[800],
    },
  },
  contents: {
    fontWeight: 400,
    fontSize: '10px',
  },
  optional: {
    fontWeight: 400,
    color: theme.palette.grey[300],
    fontSize: '10px',
    marginLeft: '6px',
  },
  root: {
    color: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 22,
    width: 12,
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 7,
    height: 7,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

const Connector = () => <></>;

// eslint-disable-next-line
const StepIcon = (props: any): React.ReactElement => {
  const { active, completed } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classnames(classes.root, { [classes.active]: active })}>
      {completed ? (
        <Icon name="check" size="12px" customColor={theme.palette.primary.main} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

const Layout = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const newProjectInfo = useNewProjectInfo();
  const { steps, currentStep } = newProjectInfo;
  const active = currentStep.activeStep;

  return (
    <MUI.Box className={classes.box}>
      <MUI.Stepper
        className={classes.stepper}
        orientation="vertical"
        activeStep={active}
        connector={<Connector />}
      >
        {steps.map((step: any) => (
          <MUI.Step key={step.activeStep}>
            <MUI.StepLabel className={classes.stepLabel} StepIconComponent={StepIcon}>
              {t(step.title)}
            </MUI.StepLabel>
            <MUI.Box
              marginLeft="5.5px"
              padding="2px 0 20px 14px"
              borderLeft={
                step.activeStep === steps.length - 1
                  ? 'solid 1px transparent'
                  : `solid 1px ${
                      active > step.activeStep
                        ? theme.palette.primary.main
                        : theme.palette.grey[200]
                    }`
              }
            >
              {step.contents?.map((content: any) => (
                <div key={content.title}>
                  <MUI.Typography display="inline" className={classes.contents}>
                    {t(content.title)}
                  </MUI.Typography>
                  {content.isOptional && (
                    <MUI.Typography className={classes.optional} display="inline">
                      ({t('forms.optional')})
                    </MUI.Typography>
                  )}
                </div>
              ))}
            </MUI.Box>
          </MUI.Step>
        ))}
      </MUI.Stepper>
    </MUI.Box>
  );
};

export default Layout;
