import { camelCase, startCase } from 'lodash';

import { excludeFilters } from './labelFilter';
import { FilterValue, FromApiParamsFn, FromUrlParamFn, ToApiParamsFn, ToUrlParamFn } from './types';

export function Option<V extends string>(value: V, label: string = value) {
  return { value, label };
}

/**
 * "pageSize" => "page size"
 */
export function keyToUrlFormat(key: string) {
  return startCase(key).toLocaleLowerCase();
}

/**
 * "page size" => "pageSize"
 */
export function keyFromUrlFormat(key: string) {
  return camelCase(key);
}

export const toUrlParamDefault: ToUrlParamFn<string, { value: string }> = ({
  condition,
  options = [],
}) => {
  return [condition, ...options.map(({ value }) => value)].join(',');
};

export const fromUrlParamDefault: FromUrlParamFn<string, { value: string }> = ({
  condition,
  options = [],
}) => ({
  condition,
  // Just use the value as a label for now. We translate it to the correct label later during rendering.
  options: options.map(option => Option(`${option}`)),
});

/**
 * Converts FilterValues ({ filterBy, condition, options }[]) to URL params ([string, string][])
 * by using either a default or custom toUrlParam function for each filter key.
 */
export function getUrlParamsForFilters<FilterKey extends string>(
  allFilters: Record<FilterKey, { toUrlParam?: ToUrlParamFn<any, any> }>,
  filterInfo: FilterValue[],
): [string, string][] {
  return filterInfo.flatMap(({ filterBy, condition, options = [] }) => {
    if (!filterBy) return [];
    const toUrlParam =
      allFilters[filterBy as FilterKey]?.toUrlParam ?? (toUrlParamDefault as ToUrlParamFn<string>);
    const urlParamValue = toUrlParam({ condition, options });
    return urlParamValue ? [[keyToUrlFormat(filterBy), urlParamValue]] : [];
  });
}

/**
 * Converts URL params ([string, string][]) to FilterValues ({ filterBy, condition, options }[])
 * by using either a default or custom fromUrlParam function for each filter key.
 */
export function getFiltersFromUrlParams<FilterKey extends string>(
  allFilters: Record<FilterKey, { fromUrlParam?: FromUrlParamFn<any, any> }>,
  params: [string, string][],
): FilterValue[] {
  return params.flatMap(([filterBy, param]) => {
    const changedFilterBy = keyFromUrlFormat(filterBy) as FilterKey;
    const [condition, ...options] = param.split(',');
    const fromUrlParam = allFilters[changedFilterBy]?.fromUrlParam ?? fromUrlParamDefault;
    const result = fromUrlParam({ condition, options });
    return result ? [{ filterBy: changedFilterBy, ...result }] : [];
  });
}

/**
 * Converts URL params ([string, string][]) to API params ([string, any][])
 * by using toApiParams function for each filter key.
 */
export function getApiParamsForFilter<FilterKey extends string>(
  allFilters: Record<FilterKey, { toApiParams: ToApiParamsFn }>,
  {
    filterParams,
    meta = {},
  }: {
    filterParams: [string, string][];
    meta?: Record<string, any>;
  },
): [any, any][] {
  return filterParams.flatMap(([key, value]) => {
    const changedKey = keyFromUrlFormat(key) as FilterKey;

    let splitedParams = `${value}`.split(',');

    if ((excludeFilters as unknown as FilterKey).includes(changedKey)) {
      splitedParams = [changedKey, value];
    }

    return (
      allFilters[changedKey]?.toApiParams(
        {
          condition: splitedParams[0],
          options: splitedParams.slice(1),
        },
        meta,
      ) ?? []
    );
  });
}

/**
 * Converts API params ([string, string][]) to render filters ({ filterBy, condition, options }[])
 * by trying every available filter's fromApiParams and then fromUrlParam'ing the results.
 */
export function getFiltersFromApiParams<FilterKey extends string>(
  allFilters: Record<
    FilterKey,
    { key: FilterKey; fromUrlParam?: FromUrlParamFn<any, any>; fromApiParams: FromApiParamsFn }
  >,
  apiParams: [string, any][],
  meta: Record<string, any> = {},
): FilterValue<FilterKey>[] {
  if (!Object.keys(apiParams).length) return [];
  const filters = Object.values(allFilters) as { key: FilterKey; fromApiParams: FromApiParamsFn }[];
  return filters.flatMap(filter => {
    const filterValues = filter?.fromApiParams(apiParams, meta) ?? [];
    return filterValues.flatMap(({ condition, options }) => {
      const fromUrlParam = allFilters[filter.key]?.fromUrlParam ?? fromUrlParamDefault;
      const result = fromUrlParam({ condition, options });
      return result ? [{ filterBy: filter.key, ...result }] : [];
    });
  });
}
