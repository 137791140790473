import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@superb-ai/norwegian-forest';

import Emoji from './Emoji';

const useStyles = makeStyles(() => ({
  emoji: {
    marginRight: '4px',
  },
}));

interface Props {
  anchor: JSX.Element;
  placement?: React.ComponentProps<typeof Tooltip>['placement'];
  isLoggedIn?: boolean;
  message?: string;
}

const DisabledTooltip: React.FC<Props> = ({
  anchor,
  placement = 'right',
  isLoggedIn = false,
  message,
}) => {
  const classes = useStyles();
  const getContent = () => {
    if (isLoggedIn) {
      return (
        <>
          <Emoji symbol="👉" className={classes.emoji} />
          {message || 'Available for project users only.'}
        </>
      );
    }
    return (
      <>
        <Emoji symbol="👉" className={classes.emoji} />
        <MUI.Link underline="always" href="/auth/login">
          Sign in
        </MUI.Link>{' '}
        to unlock this feature.
      </>
    );
  };

  return (
    <Tooltip anchorEl={anchor} placement={placement}>
      {getContent()}
    </Tooltip>
  );
};

export default DisabledTooltip;
