import React from 'react';
import { useParams } from 'react-router';

import { useMetering } from '../../../queries/useMeteringQuery';
import { useDatasetsQuery } from '../queries/datasetQueries';
import { DatasetPool, SampleDataset } from '../types/routeTypes';

type ContextProps = {
  showPublicDatasets: boolean;
  hasOwnDataset: boolean;
  isCurateNotEnabled: boolean;
};

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { datasetPool } = useParams<{ datasetPool: DatasetPool }>();
  const showPublicDatasets = datasetPool === SampleDataset;

  const { data: myDatasetsInfo } = useDatasetsQuery({
    size: 1,
    fromPublicDatasets: false,
    queryRefetchOptions: { refetchOnWindowFocus: false },
  });
  const hasOwnDataset = (myDatasetsInfo?.count || 0) > 0;
  const isCurateNotEnabled = useMetering('curate:data-volume').maxQuantity <= 0;

  return {
    showPublicDatasets,
    hasOwnDataset,
    isCurateNotEnabled,
  };
};

export const usePublicDatasetContext = (): ContextProps => {
  return React.useContext(Context);
};

export const PublicDatasetProvider: React.FC = ({ children }) => {
  const showPublicDatasets = useProvider();
  return <Context.Provider value={showPublicDatasets}>{children}</Context.Provider>;
};
