import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { CheckFilled, Clear, WarningOutline } from '@superb-ai/icons';
import { Card, Modal } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, IconButton, Input, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { useDiagnosisListQuery } from '../../../../queries/diagnosisModelQueries';
import { DatasetResult } from '../../../../services/DatasetService';
import { DiagnosisStatusEnum } from '../modelDiagnosis/diagnosis/types';

export default function DeleteModal({
  deleteModalIsOpen,
  setDeleteModalIsOpen,
  setDeleteConfirmInput,
  deleteConfirmInput,
  handleClickDelete,
  selected,
  datasetList,
}: {
  deleteModalIsOpen: boolean;
  setDeleteModalIsOpen: (isOpen: boolean) => void;
  setDeleteConfirmInput: (str: string) => void;
  deleteConfirmInput: string;
  handleClickDelete: (selected: string) => void;
  selected: string[];
  datasetList: DatasetResult[];
}): JSX.Element {
  const { accountName } = useParams<{ accountName: string; datasetId: string }>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const selectedDataset = useMemo(() => {
    return datasetList.find(data => data.id === selected[0]);
  }, [datasetList, selected]);

  const { data: diagnosisListResult } = useDiagnosisListQuery({
    datasetId: selectedDataset?.id,
    queryRefetchOptions: { refetchOnWindowFocus: false },
  });

  if (!selectedDataset) return <></>;

  const diagnosisAllList = diagnosisListResult?.pages.flatMap(page => page.results);
  const diagnosisCount =
    diagnosisAllList?.filter(d => d.status !== DiagnosisStatusEnum.INACTIVE).length || 0;
  const imageCount = selectedDataset.imageCount || 0;
  const sliceCount = selectedDataset.sliceCount || 0;

  type TargetKey = '' | 'images' | 'diagnoses' | 'imagesAndDiagnoses';

  const getDeleteWarningTargetKey = () => {
    let key = '';
    if (imageCount > 0) key += 'images';
    if (diagnosisCount > 0) key += key ? 'AndDiagnoses' : 'diagnoses';

    return key as TargetKey;
  };

  function getDeleteWarningBanner() {
    if (getDeleteWarningTargetKey() === '') return '';
    const target = t(
      `curate.datasets.deleteModal.deleteWarningBannerTargets.${
        getDeleteWarningTargetKey() as string
      }`,
      { imageCount, diagnosisCount, sliceCount },
    );
    return (
      <Trans
        t={t}
        i18nKey="curate.datasets.deleteModal.deleteWarningBanner"
        values={{ target }}
        components={{ 1: <Typography color="primary" /> }}
      />
    );
  }

  function getDeleteWarning() {
    if (getDeleteWarningTargetKey() === '') return '';
    const target = t(
      `curate.datasets.deleteModal.deleteWarningTargets.${getDeleteWarningTargetKey() as string}`,
    );
    return t('curate.datasets.deleteModal.deleteWarning', { target });
  }

  const cannotDeleteDataset = imageCount > 0 || diagnosisCount > 0;

  const handleDeleteDataset = async () => {
    if (!selected.length) return;
    try {
      await handleClickDelete(selected[0]);
      enqueueSnackbar(
        <>{`${t('bulkActions.messages.deleteDataset', {
          name: selectedDataset?.name ?? '',
        })}`}</>,
        {
          variant: 'success',
          preventDuplicate: true,
          content: (key, message) => {
            return (
              <Box
                display="flex"
                flexDirection="column"
                style={{
                  width: '420px',
                  height: '101px',
                  background: '#1f1f1f',
                  borderLeft: '4px solid  #82db24',
                  padding: '20px',
                }}
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Box display="flex">
                    <Icon icon={CheckFilled} color="green-400" size={24} />
                    <Box display="flex" ml={1} pt={0.5}>
                      <Typography variant="m-strong" color={'white'}>
                        {message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{ maxHeight: '24px' }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      icon={Clear}
                      variant="text"
                      color="white"
                      onClick={() => closeSnackbar(key)}
                    />
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                      size="m"
                      style={{ color: 'white' }}
                      variant="text"
                      onClick={() => {
                        history.push(`/${accountName}/curate/dataset/list`);
                        closeSnackbar(key);
                      }}
                    >
                      {t('button.view')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          },
        },
      );
    } catch (error) {
      enqueueSnackbar(t('curate.datasets.deleteModal.failedToDelete', { target: 'dataset' }), {
        variant: 'error',
      });
    } finally {
      setDeleteModalIsOpen(false);
    }
  };

  return (
    <Modal
      open={deleteModalIsOpen}
      close={{
        onClose: () => {
          setDeleteModalIsOpen(false);
          setDeleteConfirmInput('');
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.deleteDatasetTitle')}
      mainButton={{
        text: cannotDeleteDataset ? t('button.okay') : t('button.confirm'),
        onClick: () => (cannotDeleteDataset ? setDeleteModalIsOpen(false) : handleDeleteDataset()),
        // 현재에는 이미지가 없는 데이터셋만 삭제 가능
        color: 'primary',
        disabled: cannotDeleteDataset || deleteConfirmInput !== 'DELETE',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setDeleteModalIsOpen(false);
          setDeleteConfirmInput('');
        },
      }}
    >
      <Box py={1} px={4} style={{ maxWidth: 420 }}>
        {cannotDeleteDataset && (
          <Card
            style={{
              background: '#FFF8F8',
              padding: '8px',
              boxShadow: 'none',
              display: 'flex',
              marginBottom: '16px',
            }}
          >
            <Box display="flex" gap={1}>
              <Icon icon={WarningOutline} size="16px" color="primary-400" />
              <Typography variant="m-regular">{getDeleteWarningBanner()}</Typography>
            </Box>
          </Card>
        )}
        <Typography variant="m-regular" color="gray-400">
          {!cannotDeleteDataset ? (
            sliceCount > 0 ? (
              <Trans
                t={t}
                i18nKey={'curate.datasets.deleteModal.deleteWarningDeleteDatasetWithSlice'}
                values={{ sliceCount }}
                components={{ 1: <Typography color="primary" /> }}
              />
            ) : (
              t('curate.datasets.deleteModal.deleteMessage', { target: 'dataset' })
            )
          ) : (
            getDeleteWarning()
          )}
        </Typography>
        {!cannotDeleteDataset && (
          <Box mt={2}>
            <Input
              placeholder={t('curate.datasets.deleteModal.confirmPlaceholder')}
              variant="soft-fill"
              color="cloud"
              onChange={event => {
                setDeleteConfirmInput(event.target.value);
              }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
