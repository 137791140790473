import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { Select } from '@superb-ai/norwegian-forest';

import DataTypeConst from '../../consts/DataTypeConst';
import { useGetProjectList } from '../../hooks/GetProjectList';
import { useProjectPagination } from '../../hooks/ProjectPaginationHook';
import { Option, PageAdditional } from '../../types/selectTypes';
import ProjectUtils from '../../utils/ProjectUtils';

const ProjectSelect: React.FC<{
  onSelect: (option: SingleValue<Option>) => void;
  additionalParams?: Record<string, any>;
}> = ({ additionalParams, onSelect }) => {
  const { t } = useTranslation();
  const getProjectList = useGetProjectList();
  const params = useProjectPagination();

  const handleLoadOptions: LoadOptions<Option, GroupBase<Option>, PageAdditional> = async (
    search,
    prevOptions,
    additional,
  ) => {
    const page = additional?.page || 1;

    const pageSize = 10;
    const results = await getProjectList({
      ...params,
      ...additionalParams,
      nameIcontains: search,
      page,
      pageSize,
    });
    const hasMore = Math.ceil(results.count / pageSize) > page;

    return {
      options: results.results.map(item => {
        const projectType = ProjectUtils.getProjectDataType(item.workapp);
        return {
          label: item.name,
          value: item.id,
          icon: DataTypeConst.getDataTypeIcon(t, projectType),
        };
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Select.Async
      placeholder={t('assignToProjectDialog.selectProjectPlaceholder')}
      getOptionLabelAdornment={option => {
        return option.icon;
      }}
      maxMenuHeight={200}
      loadOptions={handleLoadOptions}
      onChange={onSelect}
    />
  );
};

export default ProjectSelect;
