import { EndpointListTable, ListIsEmpty } from '../../endpoints/list/Layout';
import { useModelEndpointQueries, useModelEndpointsQuery } from '../../queries/modelQueries';
import { Endpoint } from '../../services/types';

export const Endpoints = ({ modelId }: { modelId: string }) => {
  const { data: endpointData, isLoading, hasNextPage, fetchNextPage } = useModelEndpointsQuery({});
  const endpointList = endpointData?.pages.flatMap(p => p.endpointList) ?? [];
  const statusChangingEndpointIds = endpointList
    .filter(endpoint => endpoint.status === 'starting' || endpoint.status === 'pausing')
    .map(endpoint => endpoint.id);
  const statusChangingEndpointListQueries = useModelEndpointQueries({
    ids: statusChangingEndpointIds,
  });

  const statusChangingEndpointList = statusChangingEndpointListQueries
    .filter(_ => _.data)
    .map(_ => _.data) as Endpoint[];

  const getMergedEndpointList = () => {
    const map = new Map<string, Endpoint>();
    for (const statusChangingEndpoint of statusChangingEndpointList) {
      map.set(statusChangingEndpoint.id, statusChangingEndpoint);
    }
    const mergedArray: Endpoint[] = [];
    for (const endpoint of endpointList) {
      const statusChangingEndpoint = map.get(endpoint.id);

      if (statusChangingEndpoint) {
        mergedArray.push(statusChangingEndpoint);
      } else {
        mergedArray.push(endpoint);
      }
    }

    return mergedArray.filter(x => x.model.id === modelId);
  };

  if (getMergedEndpointList().length === 0) {
    return <ListIsEmpty />;
  }

  return (
    <EndpointListTable
      endpointList={getMergedEndpointList()}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      showDeployedModelColumn={false}
    />
  );
};
