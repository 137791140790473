import { Box as BoxIcon, Polygon as PolygonIcon } from '@superb-ai/icons';

export const LOW_PRECISION_THRESHOLD = 0.5;
export const LOW_RECALL_THRESHOLD = 0.5;
export const LOW_AP_THRESHOLD = 50;

export const AnnotationTypeIconMap = {
  box: BoxIcon,
  polygon: PolygonIcon,
};
