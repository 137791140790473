import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
  Box,
  Button,
  Card,
  Icon,
  Link,
  Menu,
  MenuItem,
  Popper,
  Typography,
} from '@superb-ai/norwegian-forest';
import { capitalize } from 'lodash';

import AnalyticsTracker from '../analyticsTracker';
import UserAvatar from '../components/elements/UserAvatar';
import { getUserManualUrl } from '../consts/DocsLink';
import { isSystemAdmin, useAuthInfo } from '../contexts/AuthContext';
import { useRouteInfo } from '../contexts/RouteContext';
import { useCurrentPlan } from '../queries/useSubscriptionQuery';
import { getUrl } from '../routes/util';
import TierUnion from '../union/TierUnion';
import { getRoleLabel } from '../union/UserRoleUnion';

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 16px;
  padding: 6px;
  margin-right: -6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const ProfileMenu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { SUITE_MANUAL } = getUserManualUrl(i18n.language);

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const rootPath = authInfo.accountName || '';

  const currentPlan = useCurrentPlan();

  const [open, setOpen] = useState(false);

  const handleClickProfile = () => {
    routeInfo.history.push(getUrl([rootPath, 'settings', 'profile']));
    setOpen(false);
  };

  const handleClickUpgrade = () => {
    AnalyticsTracker.upgradePlanClicked({
      accountId: rootPath,
      referrer: 'profile menu',
    });
    routeInfo.history.push(getUrl([rootPath, 'plan']));
    setOpen(false);
  };

  const handleClickUserManual = () => {
    window.open(SUITE_MANUAL);
    setOpen(false);
  };

  const handleClickLogout = () => {
    authInfo.resetLoginInfos();
    routeInfo.history.push('/auth');
  };

  const handleClickTeam = () => {
    routeInfo.history.push(getUrl([rootPath, 'settings']));
    setOpen(false);
  };

  return (
    <>
      <Popper
        open={open}
        positionFixed
        useOutsidePanel
        onClickOutside={() => setOpen(false)}
        anchorEl={
          <ButtonBox onClick={() => setOpen(!open)} role="button">
            <UserAvatar noShadow size={24} margin="0 10px 0 0" userInfo={authInfo} />
            <Icon name="chevronDown" />
          </ButtonBox>
        }
        offset={1}
        placement="bottom-end"
      >
        <Card width="250px">
          <Box py={1} px={1.5} bb boxSizing="content-box">
            <Box
              py={1}
              px={1.5}
              display="flex"
              alignItems="center"
              onClick={handleClickProfile}
              className="clickable"
            >
              <UserAvatar noShadow size={36} userInfo={authInfo} />
              <Box>
                <Typography variant="headline6">{authInfo.name}</Typography>
                <Typography variant="body3">{authInfo.email}</Typography>
              </Box>
            </Box>
          </Box>
          <Box py={1} px={1.5} bb boxSizing="content-box">
            <Box
              className="clickable"
              onClick={handleClickTeam}
              p={1}
              display="grid"
              gridTemplateColumns="20px 70px auto"
              alignItems="center"
              rowGap="5px"
              themedColor={['grey', 500]}
              lineHeight="16px !important"
            >
              <Icon name="dot" size="20px" />
              <Typography variant="body3">{t('settings.profile.team')}</Typography>
              <Typography variant="body3" themedColor="textDefault">
                {routeInfo.urlMatchInfo.accountName}
              </Typography>

              {currentPlan && !isSystemAdmin(authInfo) && (
                <>
                  <Icon name="dot" size="20px" />
                  <Typography variant="body3">{t('settings.billing.plan')}</Typography>
                  <Typography variant="body3" themedColor="textDefault">
                    <Link
                      to={getUrl([rootPath, 'settings', 'billing'])}
                      onClick={() => setOpen(false)}
                    >
                      {capitalize(currentPlan)}
                    </Link>
                  </Typography>
                </>
              )}

              <Icon name="dot" size="20px" />
              <Typography variant="body3">{t('settings.profile.role')}</Typography>
              <Typography variant="body3" themedColor="textDefault">
                {authInfo.role && getRoleLabel(t, authInfo.role)}
              </Typography>
            </Box>
            {!TierUnion.isEnterprise(authInfo.tier) && !isSystemAdmin(authInfo) && (
              <Box mt={1} display="flex">
                <Button variant="soft-fill" onClick={handleClickUpgrade}>
                  {t('settings.billing.button.upgradeNow')}
                </Button>
              </Box>
            )}
          </Box>
          <Box py={1} px={1.5} bb boxSizing="content-box">
            <Menu>
              <MenuItem
                icon="menuBook"
                title={t('export.guide.format.usermanualBtn')}
                onClick={handleClickUserManual}
                css={''}
              />
              <MenuItem
                icon="exit"
                title={t('auth.button.logOut')}
                onClick={handleClickLogout}
                css={''}
              />
            </Menu>
          </Box>
        </Card>
      </Popper>
    </>
  );
};

export default ProfileMenu;
