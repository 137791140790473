import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import {
  ArrowDown,
  ArrowUp,
  ArrowVertical,
  InfoFilled,
  LoadingSpinnerAlt,
  Radio,
  RadioChecked,
  SearchSmallPlus,
  WarningOutline,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  extendComponent,
  Icon,
  IconButton,
  LoadingIndicator,
  Select,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { formatNumber } from '../../../../utils/numberFormat';
import AnnotatedImage, { Annotation } from '../../components/AnnotatedImage';
import { BorderBox } from '../../components/components';
import { RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER } from '../../constant';
import { useIntersect } from '../../hooks';
import {
  useModelDetailQuery,
  useModelHistoryQuery,
  useModelTrainingSamplePredictionsQuery,
} from '../../queries/modelQueries';
import { annotationTypeResolver, classNameResolver } from '../../recognition-ai/detail/utils';
import { isGenerationModelAITrainingResult } from '../../services/modelTrainingResultTypeGuards';
import { isGenerationModelAITraining } from '../../services/modelTrainingTypeGuards';
import {
  GenerationAIModelDetailTraining,
  GenerationAITrainingResult,
  ModelStatus,
  MyModelDetail,
  PurposeType,
  SamplePredictionData,
} from '../../services/types';
import { randomColor } from '../../utils/colorUtils';
import { SamplePredictionDialog } from '../SamplePredictionDialog';
import { SamplePredictionImages } from './SamplePredictionImages';

export const Performance = ({ data }: { data: MyModelDetail }) => {
  const { t } = useTranslation();
  return (
    <>
      {isGenerationModelAITraining(data.modelTraining) && (
        <BorderBox mb={1}>
          <Box px={2.5} py={2} bb="1px solid" borderColor="gray-150">
            <Typography variant="l-strong">{t('model.generativeAi.modelSample')}</Typography>
          </Box>
          {!data.modelTraining.modelTrainingReferenceImages &&
          data.modelTraining.modelTrainingIterations.length === 0 ? (
            <Row
              justifyContent="center"
              backgroundColor={'gray-100'}
              textAlign="center"
              style={{ height: 506 }}
            >
              <Typography variant="l-regular" color={'gray-300'}>
                <Trans t={t} i18nKey={'model.myModelDetail.samplePrediction.beforeOneEpoch'} />
              </Typography>
            </Row>
          ) : (
            <ModelSample
              modelId={data.id}
              modelStatus={data.status}
              modelName={data.modelSetting.name}
              modelTraining={data.modelTraining}
              datasetId={data.trainingSet.referenceId}
            />
          )}
        </BorderBox>
      )}
      <TrainingResult data={data} />
    </>
  );
};

const ModelSample = ({
  modelId,
  modelStatus,
  modelName,
  modelTraining,
  datasetId,
}: {
  modelId: string;
  modelStatus: ModelStatus;
  modelName: string;
  modelTraining: GenerationAIModelDetailTraining;
  datasetId: string;
}) => {
  const { t } = useTranslation();
  const dialogState = useDialogState();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const [selectedComparisonModelId, setSelectedComparisonModelId] = useState<string | null>(null);
  const { hasNextPage, isLoading, fetchNextPage, data } = useModelHistoryQuery({
    params: { id: modelId },
  });
  const { data: comparisonModel } = useModelDetailQuery({
    id: selectedComparisonModelId ? selectedComparisonModelId : '',
    modelPurpose: params.purpose,
    enabled: Boolean(selectedComparisonModelId),
  });

  const measureRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isLoading) {
      await fetchNextPage();
    }
  });

  const { data: referenceAnnotation, isLoading: isRefLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: modelTraining.modelTrainingReferenceImages.annotations,
      enabled: Boolean(modelTraining.modelTrainingReferenceImages.annotations),
    });

  const { data: iterationAnnotation, isLoading: isIterLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: modelTraining.modelTrainingIterations[modelTraining.modelTrainingIterations.length - 1]
        .annotations,
      enabled: Boolean(
        modelTraining.modelTrainingIterations[modelTraining.modelTrainingIterations.length - 1]
          .annotations,
      ),
    });

  const { data: comparisionIterationAnnotation, isLoading: isCompareLoading } =
    useModelTrainingSamplePredictionsQuery({
      url:
        comparisonModel &&
        comparisonModel.modelTraining &&
        isGenerationModelAITraining(comparisonModel.modelTraining)
          ? comparisonModel.modelTraining.modelTrainingIterations[
              comparisonModel.modelTraining.modelTrainingIterations.length - 1
            ].annotations
          : '',
      enabled: Boolean(
        comparisonModel &&
          comparisonModel.modelTraining &&
          isGenerationModelAITraining(comparisonModel.modelTraining) &&
          comparisonModel.modelTraining.modelTrainingIterations[
            comparisonModel.modelTraining.modelTrainingIterations.length - 1
          ].annotations,
      ),
    });

  const modelHistory = data?.pages.flatMap(x => x.modelHistoryList) ?? [];
  const modelHistoryExceptCurrentModel = modelHistory.filter(model => model.id !== modelId);

  const handleClickPreviousModelRow = (id: string) => {
    if (selectedComparisonModelId && selectedComparisonModelId === id) {
      setSelectedComparisonModelId(null);
      return;
    }
    setSelectedComparisonModelId(id);
  };

  return (
    <Box display="grid" style={{ height: 506, gridTemplateColumns: '260px 1fr' }}>
      <Box
        br="1px solid"
        borderColor="gray-150"
        display="grid"
        style={{ gridTemplateRows: '1fr 72px' }}
      >
        <Box bb="1px solid" borderColor="gray-150">
          <Row gap={0.5} mb={1} px={2.5} pt={2.5}>
            <Typography variant="m-medium">
              {t('model.generativeAi.comparePreviousModels')}
            </Typography>
            <Tooltip placement="top" content={t('model.generativeAi.comparePreviousModelsInfo')}>
              <Icon icon={InfoFilled} />
            </Tooltip>
          </Row>
          <Box overflow="auto">
            {modelHistoryExceptCurrentModel.length > 0 ? (
              <>
                {modelHistoryExceptCurrentModel.map(model => {
                  const isSelected =
                    selectedComparisonModelId && selectedComparisonModelId === model.id;
                  return (
                    <Row
                      style={{ height: 32 }}
                      key={model.id}
                      gap={0.5}
                      px={2.5}
                      backgroundColor={isSelected ? 'primary-100' : undefined}
                      onClick={() => {
                        handleClickPreviousModelRow(model.id);
                      }}
                      cursor="pointer"
                    >
                      <Icon
                        icon={isSelected ? RadioChecked : Radio}
                        size={16}
                        color={isSelected ? 'primary' : undefined}
                        style={{ minWidth: 'max-content' }}
                      />
                      <Typography
                        variant="m-regular"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        color={isSelected ? 'primary-400' : undefined}
                      >
                        {model.name}
                      </Typography>
                    </Row>
                  );
                })}
              </>
            ) : (
              <NoPreviousModel />
            )}
            {hasNextPage && (
              <Row ref={measureRef} width="100%" style={{ height: 32, justifyContent: 'center' }}>
                <Icon icon={LoadingSpinnerAlt} size={16} />
              </Row>
            )}
          </Box>
        </Box>
        <Row p={2.5} width="100%">
          <Button
            color="primary"
            onClick={() => {
              dialogState.show();
            }}
            style={{ marginLeft: 'auto' }}
          >
            <Icon icon={SearchSmallPlus} />
            {t('model.generativeAi.viewSampleDetail')}
          </Button>
        </Row>
      </Box>
      {selectedComparisonModelId &&
      comparisonModel &&
      isGenerationModelAITraining(comparisonModel.modelTraining) ? (
        <SamplePredictionComparison
          modelStatus={modelStatus}
          currentModelTraining={modelTraining}
          currentModelName={modelName}
          comparisonModelTraining={comparisonModel.modelTraining}
          comparisonModelName={comparisonModel.modelSetting.name}
        />
      ) : (
        <SamplePredictionImages
          modelTraining={modelTraining}
          modelStatus={modelStatus}
          iterationIndex={modelTraining.modelTrainingIterations.length - 1}
        />
      )}
      {dialogState.visible && (
        <SamplePredictionDialog
          state={dialogState}
          modelTraining={modelTraining}
          comparisonModelId={selectedComparisonModelId}
          modelName={modelName}
          iterationIndex={modelTraining.modelTrainingIterations.length - 1}
          referenceAnnotation={referenceAnnotation}
          iterationAnnotation={iterationAnnotation}
          isRefLoading={isRefLoading}
          isIterLoading={isIterLoading}
          datasetId={datasetId}
          isCompareLoading={isCompareLoading}
          comparisionIterationAnnotation={comparisionIterationAnnotation}
          comparisonModelTraining={
            comparisonModel &&
            comparisonModel.modelTraining &&
            isGenerationModelAITraining(comparisonModel.modelTraining)
              ? comparisonModel.modelTraining
              : undefined
          }
          comparisonModelName={
            comparisonModel && comparisonModel.modelSetting
              ? comparisonModel.modelSetting.name
              : undefined
          }
          header={
            <Box display="flex" style={{ width: 260 }}>
              <Select
                // backgroundColor="gray-500"
                variant="strong-fill"
                color="gray-500"
                value={selectedComparisonModelId}
                data={modelHistoryExceptCurrentModel.map(x => ({ value: x.id, label: x.name }))}
                onChangeValue={v => setSelectedComparisonModelId(v)}
                isLoading={isLoading}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                placeholder={t('model.generativeAi.selectComparisonModelPlaceholder')}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

const SamplePredictionComparison = ({
  modelStatus,
  currentModelTraining,
  currentModelName,
  comparisonModelTraining,
  comparisonModelName,
}: {
  modelStatus: ModelStatus;
  currentModelTraining: GenerationAIModelDetailTraining;
  currentModelName: string;
  comparisonModelTraining: GenerationAIModelDetailTraining;
  comparisonModelName: string;
}) => {
  const { t } = useTranslation();
  const iterationIndex = currentModelTraining.modelTrainingIterations.length - 1;
  const { data: referenceAnnotation } = useModelTrainingSamplePredictionsQuery({
    url: currentModelTraining.modelTrainingReferenceImages.annotations,
    enabled: Boolean(currentModelTraining.modelTrainingReferenceImages.annotations),
  });

  const [classNameToColorMap, setClassNameToColorMap] = useState({});
  const [selectedReferenceModel, setSelectedReferenceModel] = useState<{
    fileName: string;
    url: string;
    width: number;
    height: number;
    idx: number;
  } | null>(null);

  const { data: currentIterationAnnotation } = useModelTrainingSamplePredictionsQuery({
    url: currentModelTraining.modelTrainingIterations[iterationIndex].annotations,
    status: modelStatus,
    enabled: Boolean(currentModelTraining.modelTrainingIterations[iterationIndex].annotations),
  });

  const { data: comparisonIterationAnnotation } = useModelTrainingSamplePredictionsQuery({
    url: comparisonModelTraining.modelTrainingIterations[iterationIndex].annotations,
    status: modelStatus,
    enabled: Boolean(comparisonModelTraining.modelTrainingIterations[iterationIndex].annotations),
  });

  const getColorForClassName = (className: string) => {
    if (classNameToColorMap[className]) {
      return classNameToColorMap[className];
    }

    const newColor = randomColor();
    setClassNameToColorMap(prevMap => ({
      ...prevMap,
      [className]: newColor.hex,
    }));

    return newColor;
  };

  const annotations = (annotations: SamplePredictionData, fileName: string): Annotation[] => {
    if (!annotations) return [];

    const imageId = annotations.images.find(img => img.file_name === fileName).id;
    return annotations.annotations
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, annotations.categories);
        const className = classNameResolver(anno, annotations.categories);

        return {
          id: anno.id,
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : anno.segmentation,
          roi: { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] },
          type: annotationType,
          color: getColorForClassName(className),
          class: className,
        } as Annotation;
      });
  };

  useEffect(() => {
    if (!referenceAnnotation) return;
    const { fileName, url } = currentModelTraining.modelTrainingReferenceImages.images[0];
    const { width, height } = referenceAnnotation.images.find(img => img.file_name === fileName);
    setSelectedReferenceModel({ fileName, url, width, height, idx: 0 });
  }, [currentModelTraining.modelTrainingReferenceImages.images, referenceAnnotation]);

  if (!selectedReferenceModel) {
    return <LoadingIndicator />;
  }

  return (
    <Box
      backgroundColor={'gray-100'}
      p={2}
      display="grid"
      style={{ gridTemplateColumns: '199px 1fr', columnGap: 12 }}
    >
      <ModelSampleBorderBox>
        <Typography variant="s-strong">{t('model.generativeAi.inputImage')}</Typography>
        <Box backgroundColor={'gray-100'} mt={1.5} style={{ height: 159 }}>
          {selectedReferenceModel && (
            <AnnotatedImage
              srcUrl={selectedReferenceModel.url}
              alt={selectedReferenceModel.fileName}
              originalImageSize={[selectedReferenceModel.width, selectedReferenceModel.height]}
              annotations={annotations(referenceAnnotation, selectedReferenceModel.fileName)}
            />
          )}
        </Box>
        <Box style={{ height: 1 }} backgroundColor={'gray-150'} my={2} />
        <Box
          overflow="auto"
          display="grid"
          style={{ gridTemplateColumns: '1fr 1fr', columnGap: 4, rowGap: 4, maxHeight: 211 }}
        >
          {referenceAnnotation &&
            currentModelTraining.modelTrainingReferenceImages.images.map((refImage, refIdx) => {
              const { width, height } = referenceAnnotation.images.find(
                img => img.file_name === refImage.fileName,
              );
              const isSelected = refIdx === selectedReferenceModel.idx;
              return (
                <Box
                  key={refImage.fileName}
                  border={isSelected ? '2px solid' : undefined}
                  borderColor={isSelected ? 'primary' : undefined}
                  cursor="pointer"
                  style={{ width: 77, height: 77 }}
                  onClick={() => {
                    setSelectedReferenceModel({
                      fileName: refImage.fileName,
                      url: refImage.url,
                      width,
                      height,
                      idx: refIdx,
                    });
                  }}
                >
                  <AnnotatedImage
                    srcUrl={refImage.url}
                    alt={refImage.fileName}
                    originalImageSize={[width, height]}
                    annotations={annotations(referenceAnnotation, refImage.fileName)}
                  />
                </Box>
              );
            })}
        </Box>
      </ModelSampleBorderBox>
      <Box display="grid" style={{ gridTemplateRows: '1fr 1fr', rowGap: 12 }}>
        <ModelSampleBorderBox overflow="hidden">
          <Row mb={1.5}>
            <Typography variant="s-strong">{t('model.generativeAi.currentModel')}</Typography>
            <Box backgroundColor={'gray-300'} mx={0.5} style={{ width: 1, height: 4 }} />
            <Typography variant="s-regular">{currentModelName}</Typography>
          </Row>
          <Box
            display="grid"
            overflow="auto"
            style={{
              gridTemplateColumns:
                'minmax(159px, 1fr) minmax(159px, 1fr) minmax(159px, 1fr) minmax(159px, 1fr)',
              columnGap: 8,
            }}
          >
            {currentIterationAnnotation &&
              currentModelTraining.modelTrainingIterations[iterationIndex].images.map(
                (iterImage, iterIdx) => {
                  const { width, height } = currentIterationAnnotation.images.find(
                    img => img.file_name === iterImage.fileName,
                  );
                  if (
                    selectedReferenceModel.idx * 4 <= iterIdx &&
                    iterIdx <= selectedReferenceModel.idx * 4 + 3
                  )
                    return (
                      <Box
                        key={iterImage.fileName}
                        backgroundColor={'gray-100'}
                        style={{ height: 159 }}
                      >
                        <AnnotatedImage
                          srcUrl={iterImage.url}
                          alt={iterImage.fileName}
                          originalImageSize={[width, height]}
                          annotations={annotations(currentIterationAnnotation, iterImage.fileName)}
                        />
                      </Box>
                    );
                },
              )}
          </Box>
        </ModelSampleBorderBox>
        <ModelSampleBorderBox overflow="hidden">
          <Row mb={1.5}>
            <Typography variant="s-strong">{t('model.generativeAi.comparisonModel')}</Typography>
            <Box backgroundColor={'gray-300'} mx={0.5} style={{ width: 1, height: 4 }} />
            <Typography variant="s-regular">{comparisonModelName}</Typography>
          </Row>
          <Box
            display="grid"
            overflow="auto"
            style={{
              gridTemplateColumns:
                'minmax(159px, 1fr) minmax(159px, 1fr) minmax(159px, 1fr) minmax(159px, 1fr)',
              columnGap: 8,
            }}
          >
            {comparisonIterationAnnotation &&
              comparisonModelTraining.modelTrainingIterations[iterationIndex].images.map(
                (iterImage, iterIdx) => {
                  const { width, height } = comparisonIterationAnnotation.images.find(
                    img => img.file_name === iterImage.fileName,
                  );
                  if (
                    selectedReferenceModel.idx * 4 <= iterIdx &&
                    iterIdx <= selectedReferenceModel.idx * 4 + 3
                  )
                    return (
                      <Box
                        key={iterImage.fileName}
                        backgroundColor={'gray-100'}
                        style={{ height: 159 }}
                      >
                        <AnnotatedImage
                          srcUrl={iterImage.url}
                          alt={iterImage.fileName}
                          originalImageSize={[width, height]}
                          annotations={annotations(
                            comparisonIterationAnnotation,
                            iterImage.fileName,
                          )}
                        />
                      </Box>
                    );
                },
              )}
          </Box>
        </ModelSampleBorderBox>
      </Box>
    </Box>
  );
};

const ModelSampleBorderBox = extendComponent(Box, {
  backgroundColor: 'white',
  p: 2.5,
  border: '1px solid',
  borderColor: 'gray-200',
  borderRadius: '2px',
});

const NoPreviousModel = () => {
  const { t } = useTranslation();
  return (
    <Row
      textAlign="center"
      flexDirection="column"
      justifyContent="center"
      backgroundColor={'gray-100'}
      borderRadius="2px"
      mt={1.5}
      style={{ height: 148 }}
      mx={2.5}
    >
      <Icon icon={WarningOutline} color={'gray-300'} size={24} style={{ marginBottom: 4 }} />
      <Typography variant="m-regular" color="gray-300">
        <Trans t={t} i18nKey={'model.generativeAi.noPreviousModel'} />
      </Typography>
    </Row>
  );
};

type SortType = 'none' | 'asc' | 'desc';
type SortOptions = {
  [Key in keyof Omit<GenerationAITrainingResult['classInfo'][number], 'annoType'>]: SortType;
};

const TrainingResult = ({ data }: { data: MyModelDetail }) => {
  const { t } = useTranslation();

  const trainingResult = data.modelTraining.trainingResult.data;

  if (!trainingResult || !isGenerationModelAITrainingResult(trainingResult))
    return <>no training result</>;

  const countClassesMeetingRecommendation = trainingResult.classInfo.filter(
    info => info.actualTrainCount >= RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER,
  ).length;

  return (
    <BorderBox p={2.5}>
      <Row justifyContent="space-between" mb={1}>
        <Typography variant="l-strong">{t('model.generativeAi.classList')}</Typography>
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={'model.train.recommendedQuantityCount'}
            values={{
              countClassesMeetingRecommendation,
              countClasses: trainingResult.classInfo.length,
            }}
          />
        </Typography>
      </Row>
      <Row py={1} px={1.5} gap={0.5} backgroundColor={'gray-100'} mb={1}>
        <Icon icon={InfoFilled} />
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={'model.generativeAi.classListMessage'}
            values={{ quantity: RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER }}
          />
        </Typography>
      </Row>
      <Box display="grid" style={{ gridTemplateColumns: '1fr 1fr', columnGap: 8 }}>
        <ClassList
          title={
            <Trans
              t={t}
              i18nKey={'model.generativeAi.metClasses'}
              values={{ counts: countClassesMeetingRecommendation }}
            />
          }
          trainingResult={trainingResult}
          filterCallback={(info: GenerationAITrainingResult['classInfo'][number]) =>
            info.actualTrainCount >= RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER
          }
        />
        <ClassList
          title={
            <Trans
              t={t}
              i18nKey={'model.generativeAi.notMetClasses'}
              values={{
                counts: trainingResult.classInfo.length - countClassesMeetingRecommendation,
              }}
            />
          }
          trainingResult={trainingResult}
          filterCallback={(info: GenerationAITrainingResult['classInfo'][number]) =>
            info.actualTrainCount < RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER
          }
        />
      </Box>
    </BorderBox>
  );
};

const ClassList = ({
  title,
  trainingResult,
  filterCallback,
}: {
  title: ReactNode;
  trainingResult: GenerationAITrainingResult;
  filterCallback: (info: GenerationAITrainingResult['classInfo'][number]) => boolean;
}) => {
  const { t } = useTranslation();
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    className: 'asc',
    actualTrainCount: 'none',
  });
  const handleClickSort = (sortType: keyof SortOptions) => {
    setSortOptions(prevSortOptions => {
      const updatedOptions: SortOptions = {
        ...prevSortOptions,
        [sortType]:
          prevSortOptions[sortType] === 'none'
            ? 'asc'
            : prevSortOptions[sortType] === 'asc'
            ? 'desc'
            : 'asc',
      };

      for (const key in updatedOptions) {
        if (key !== sortType) {
          updatedOptions[key as keyof SortOptions] = 'none';
        }
      }

      return updatedOptions;
    });
  };

  const getArrowIcon = (sortType: keyof SortOptions) => {
    if (sortOptions[sortType] === 'none') {
      return ArrowVertical;
    } else if (sortOptions[sortType] === 'asc') {
      return ArrowUp;
    } else {
      return ArrowDown;
    }
  };

  const findNotNoneProperties = (options: SortOptions): (keyof SortOptions)[] => {
    const nonNoneProperties: (keyof SortOptions)[] = [];

    for (const key in options) {
      if (options[key as keyof SortOptions] !== 'none') {
        nonNoneProperties.push(key as keyof SortOptions);
      }
    }
    return nonNoneProperties;
  };

  const sortClassPerformance = () => {
    const sortStandard = findNotNoneProperties(sortOptions);

    if (sortStandard.length > 0)
      return (
        a: (typeof trainingResult)['classInfo'][number],
        b: (typeof trainingResult)['classInfo'][number],
      ) => {
        if (typeof a[sortStandard[0]] === 'string' && typeof b[sortStandard[0]] === 'string') {
          const x = a[sortStandard[0]] as string;
          const y = b[sortStandard[0]] as string;
          if (sortOptions[sortStandard[0]] === 'asc') {
            return x.localeCompare(y);
          } else if (sortOptions[sortStandard[0]] === 'desc') {
            return y.localeCompare(x);
          } else {
            return 0;
          }
        } else if (
          typeof a[sortStandard[0]] === 'number' &&
          typeof b[sortStandard[0]] === 'number'
        ) {
          const x = a[sortStandard[0]] as number;
          const y = b[sortStandard[0]] as number;
          if (sortOptions[sortStandard[0]] === 'asc') {
            return y - x;
          } else if (sortOptions[sortStandard[0]] === 'desc') {
            return x - y;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      };
    else
      return (
        a: (typeof trainingResult)['classInfo'][number],
        b: (typeof trainingResult)['classInfo'][number],
      ) => 0;
  };

  // TODO: remove after upgrage node version
  // @ts-ignore
  const sortedClassInfo = trainingResult.classInfo.toSorted(sortClassPerformance());
  return (
    <BorderBox p={2}>
      <Typography variant="m-strong">{title}</Typography>
      <Box mt={1}>
        <Row justifyContent="space-between">
          <Row ml={1}>
            <Typography variant="m-medium">{t('model.generativeAi.name')}</Typography>
            <IconButton
              icon={getArrowIcon('className')}
              onClick={() => handleClickSort('className')}
              variant="text"
              size="s"
              color={sortOptions.className === 'none' ? 'cloud' : undefined}
            />
          </Row>
          <Row>
            <Typography variant="m-medium">{t('model.generativeAi.annotationCounts')}</Typography>
            <IconButton
              icon={getArrowIcon('actualTrainCount')}
              onClick={() => handleClickSort('actualTrainCount')}
              variant="text"
              size="s"
              color={sortOptions.actualTrainCount === 'none' ? 'cloud' : undefined}
            />
          </Row>
        </Row>
        <Box backgroundColor={'gray-200'} style={{ height: 1 }} />
        <Box overflow="auto" style={{ height: 272 }}>
          {sortedClassInfo.filter(filterCallback).length > 0 ? (
            sortedClassInfo.filter(filterCallback).map(info => {
              return (
                <Row key={info.className} py={1} pl={1} pr={3} justifyContent="space-between">
                  <Typography variant="m-regular">{info.className}</Typography>
                  <Typography
                    variant="m-regular"
                    color={
                      info.actualTrainCount < RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER
                        ? 'red-400'
                        : undefined
                    }
                  >
                    {formatNumber(info.actualTrainCount)}
                  </Typography>
                </Row>
              );
            })
          ) : (
            <Row width="100%" justifyContent="center" pt={1}>
              <Typography variant="m-regular" color="gray-300">
                {t('model.generativeAi.noClasses')}
              </Typography>
            </Row>
          )}
        </Box>
      </Box>
    </BorderBox>
  );
};
