import React, { PropsWithChildren } from 'react';

import { Box, NamedColorWeight } from '@superb-ai/ui';

export default function InfoArea({
  children,
  color,
  borderColor,
}: PropsWithChildren<{ borderColor?: NamedColorWeight; color?: NamedColorWeight }>) {
  return (
    <Box
      pl={1}
      borderLeft="2px solid"
      borderColor={borderColor || 'gray-150'}
      color={color}
      gap={0.5}
    >
      <ul>{children}</ul>
    </Box>
  );
}
