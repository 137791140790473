import React from 'react';

import { makeStyles } from '@mui/styles';
import cn from 'classnames';

import AutoCompleteSelect from '../../../../elements/AutoCompleteSelect';
import AutoCompleteSelectAssigneeOption from '../../../../elements/AutoCompleteSelectAssigneeOption';
import { FilterProps } from './types';

const useStyles = ({ width }: { width: string | number }) =>
  makeStyles({
    textField: {
      paddingTop: '1px',
      borderRadius: '2px',
      border: 'solid 1px #BCBCBC',
      color: '#333333',
      minHeight: '32px',
      '& .AutoSelect__control': {
        width,
        color: '#333333',
        border: 'none',
        '& .AutoSelect__placeholder': {
          color: '#333333',
        },
        '& .AutoSelect__indicator': {
          color: '#333333',
        },
      },
      '& .AutoSelect__value-container--is-multi': {
        borderRadius: '7px',
      },
    },
    currentRowTextField: {
      borderColor: '#ff625a',
      color: '#ff625a',
      '& .AutoSelect__control': {
        color: '#ff625a',
        '& .AutoSelect__placeholder': {
          color: '#ff625a',
        },
        '& .AutoSelect__indicator': {
          color: '#ff625a',
        },
        '& .AutoSelect__indicator-separator': {
          backgroundColor: '#ff625a',
        },
      },
    },
    filterByStatus: {
      width: '350px',
    },
  });

interface Option {
  label: string;
  value: string;
  data: string;
}
interface Props extends FilterProps<any> {
  className?: string;
  filterBy?: string;
  options?: any[];
  asynchronous?: boolean;
  defaultOptions?: any[];
  onAsyncSearch?: (inputValue: string) => Promise<{ label: any; value: any }[]>;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  filterOption?: (option: Option, rawInput: string) => boolean;
}

const FilterWithSearch: React.FC<Props> = props => {
  const {
    index,
    isCurrentRow,
    onChangeCurrentRow,
    onValueChange,
    objKey,
    className,
    isMulti = true,
    closeMenuOnSelect = false,
    ...otherProps
  } = props;

  const classes = useStyles({
    width: '100%',
  })();

  const handleChangeValue = (value: any) => {
    onValueChange(index, objKey, value);
  };

  if (otherProps.filterBy === 'assignee') {
    return (
      <AutoCompleteSelect
        {...otherProps}
        isMulti={isMulti}
        className={cn(className, classes.textField, {
          [classes.currentRowTextField]: isCurrentRow,
        })}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleChangeValue}
        onFocus={() => onChangeCurrentRow(index)}
        optionComponent={<AutoCompleteSelectAssigneeOption />}
      />
    );
  }

  return (
    <AutoCompleteSelect
      {...otherProps}
      isMulti={isMulti}
      className={cn(className, classes.textField, { [classes.currentRowTextField]: isCurrentRow })}
      closeMenuOnSelect={closeMenuOnSelect}
      onChange={handleChangeValue}
      onFocus={() => onChangeCurrentRow(index)}
    />
  );
};

export default FilterWithSearch;
