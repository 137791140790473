import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  Box,
  Chip,
  Input,
  Modal,
  RadioButtons,
  Select,
  Slider,
  Typography,
  useAlertModal,
  VerticalStepper,
} from '@superb-ai/norwegian-forest';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { getUrl } from '../../../../../routes/util';
import CurationService from '../../../../../services/CurationService';
import LabelInterfaceUtils from '../../../../../utils/LabelInterfaceUtils';
import { formatCount } from '../../../../../utils/numberFormat';
import ServiceUtils from '../../../../../utils/ServiceUtils';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  checkedLabels: any[];
  setCheckedLabels: Dispatch<SetStateAction<any[]>>;
  totalCount: number;
  isAllLabelsChecked: boolean;
  filterApiParams: any;
};

export default function CurationModal({
  isOpen,
  setIsOpen,
  checkedLabels,
  totalCount,
  isAllLabelsChecked,
  filterApiParams,
}: Props) {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();
  const urlParams = useParams<{ accountName: string; projectId: string }>();
  const defaultName = `Curation_${format(new Date(), 'yyyy-MM-dd H:mm:ss')}`;
  const { openModal, closeModal } = useAlertModal();
  const history = useHistory();
  const params = useParams<{ accountName: string; projectId: string }>();

  const objectClasses = LabelInterfaceUtils.getObjectClasses(projectInfo.project.labelInterface);

  const classesOptions = objectClasses.map(objClass => ({
    label: objClass.name,
    value: objClass.id,
  }));

  const maxLabelsCount =
    !isEmpty(checkedLabels) && !isAllLabelsChecked ? checkedLabels.length : totalCount;

  const [name, setName] = useState(defaultName);
  const [selectedClasses, setSelectedClasses] = useState<{ label: string; value: string }[]>();
  const [curationType, setCurationType] = useState<'test_training_set' | 'edge_case'>(
    'test_training_set',
  );
  const [isStepsFinished, setIsStepsFinished] = useState(false);
  const [curationResultCount, setCurationResultCount] = useState(1);

  const handleCurationResultCountInput = (event: React.FormEvent<HTMLInputElement>) => {
    const strValue = (event.target as HTMLInputElement).value;
    if (strValue === '') {
      setCurationResultCount(1);
    } else {
      const value = parseInt(strValue, 10);
      if (!Number.isNaN(value)) {
        setCurationResultCount(Math.max(1, Math.min(value, maxLabelsCount)));
      }
    }
  };

  const getFilterParamRaw = () => {
    if (!isEmpty(checkedLabels) && !isAllLabelsChecked) {
      return { idIn: checkedLabels };
    }
    return filterApiParams;
  };

  useEffect(() => {
    if (!isOpen) {
      setName(defaultName);
      setSelectedClasses(undefined);
      return;
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickRun = async () => {
    await CurationService.requestCuration({
      projectId: urlParams.projectId,
      data: {
        name,
        filter: ServiceUtils.getParamString(getFilterParamRaw()) || undefined,
        labelCount: curationResultCount,
        curationType,
        config: {
          classIds: selectedClasses?.map(e => {
            return e.value;
          }),
        },
      },
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: urlParams.accountName, projectId: urlParams.projectId },
    });
    setIsOpen(false);
    openModal({
      title: `Successfully applied to ${formatCount(maxLabelsCount, 'label')}.`,
      content: '',
      variant: 'success',
      mainButton: {
        text: 'View Curation History',
        onClick: () => {
          history.push(
            getUrl([
              params.accountName,
              'label',
              'project',
              params.projectId,
              'advanced-ai-features',
              'curation',
            ]),
          );
        },
      },
      subButton: {
        text: 'Close',
        onClick: () => closeModal(),
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      close={{
        onClose: handleClose,
        hasCloseButton: true,
      }}
      title={
        <Typography themedColor="primary" variant="headline4">
          Curation{' '}
        </Typography>
      }
      mainButton={{
        text: 'Run',
        onClick: handleClickRun,
        disabled: !isStepsFinished,
        // isLoading: isRequesting,
      }}
      subButton={{
        text: 'Cancel',
        onClick: handleClose,
      }}
    >
      <Box p={3} width={748} height={530}>
        <VerticalStepper.Simple
          cardProps={{ maxHeight: 482, overflow: 'auto' }}
          steps={{
            curation_name: {
              title: 'Curation Name',
              content: (
                <>
                  <Typography variant="body5">Name</Typography>
                  <Input
                    focussed
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </>
              ),
              isButtonEnabled: !!name,
              summary: name,
            },
            select_curation_type: {
              title: 'Select Curation Type',
              content: (
                <>
                  <RadioButtons
                    options={[
                      {
                        label: (
                          <Box>
                            Test/Training set curation
                            <Box mt={1}>
                              <Typography>
                                Sample and curate labeled data with general distribution.{' '}
                              </Typography>
                            </Box>
                          </Box>
                        ),
                        value: 'test_training_set',
                      },
                      {
                        label: (
                          <Box>
                            Edge case detection
                            <Box mt={1}>
                              <Typography>
                                Sample labeled data that are likely edge cases.{' '}
                              </Typography>
                            </Box>
                          </Box>
                        ),
                        disabled: true,
                        value: 'edge_case',
                      },
                    ]}
                    value={curationType}
                    onChange={value => {
                      setCurationType(value);
                    }}
                  />

                  {curationType === 'test_training_set' && (
                    <Box mt={2}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="label">
                          Desired Number of Curation Result Images
                        </Typography>
                        <Box width={70} ml="auto">
                          <Input
                            value={curationResultCount}
                            onChange={handleCurationResultCountInput}
                            size="xs"
                          />
                        </Box>
                      </Box>
                      <Slider
                        min={1}
                        max={maxLabelsCount}
                        value={curationResultCount}
                        onChange={setCurationResultCount}
                        variant="bigThumb"
                      />
                    </Box>
                  )}
                </>
              ),
              isButtonEnabled: !!curationType,
              summary: curationType,
            },
            select_classes: {
              title: 'Select Classes',
              content: (
                <>
                  <Select.Basic
                    isMulti
                    options={classesOptions}
                    value={selectedClasses}
                    onChange={value => {
                      setSelectedClasses(value as { label: string; value: string }[]);
                    }}
                  />
                </>
              ),
              isOptional: true,
              isButtonEnabled: true,
              summary: (
                <Box display="flex" gap="4px">
                  {selectedClasses?.map(selectedClass => (
                    <Chip key={selectedClass.value}>{selectedClass.label}</Chip>
                  ))}
                </Box>
              ),
            },
          }}
          lastStepButton={{ text: 'Done', onClick: () => setIsStepsFinished(true) }}
        />
      </Box>
    </Modal>
  );
}
