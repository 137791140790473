import { SemanticSearch } from '../types/semanticsearchTypes';
import { FileWithPath } from '../utils/FileUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

export interface ImageUploadUrlsResp {
  images: {
    uploadId: string;
    uploadUrl: string;
  }[];
}

const getImageUploadUrls: ApiCall<
  { projectId: string; imageSizes: number[] },
  ImageUploadUrlsResp
> = async args => {
  const { projectId, imageSizes, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/similarity-search/upload-images/`,
    data: {
      images: imageSizes.map(e => {
        return { file_size: e };
      }),
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data as ImageUploadUrlsResp;
};

const uploadImage = async (args: { url: string; file: FileWithPath }): Promise<boolean> => {
  const { url, file } = args;
  await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      /* Make sure *NO Content-Type HEADER* included !!!
         Otherwise, we may not be able to pass s3 signature verification. */
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: await file.arrayBuffer(),
  });
  return true;
};

export interface ImageQuery {
  type: 'suite-data' | 'upload-data';
  dataset?: string;
  dataKey?: string;
  uploadId?: string;
  assetId?: string;
  roi?: number[];
  size?: number[];
  upload_id?: string | undefined;
}

export interface SearchRequest {
  imageQueries: (ImageQuery | null)[];
  textQueries: string[];
}

interface CreateSearchRequestResp {
  id: string;
}

const createSearchRequest: ApiCall<
  { projectId: string; searchRequest: SearchRequest },
  CreateSearchRequestResp
> = async args => {
  const { projectId, searchRequest, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/similarity-search/requests/`,
    data: searchRequest,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const listSearchRequest: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/similarity-search/requests/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export interface SearchResult {
  id: string;
  createdAt: string;
  createdBy: string;
  imageQueries: ImageQuery[];
  textQueries: string[];
  status: string;
  resultUrl: string;
}

const getSearchRequest: ApiCall<
  { projectId: string; searchId: string },
  SemanticSearch
> = async args => {
  const { projectId, searchId, isGuest, urlInfo, cancelToken } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/similarity-search/requests/${searchId}/`,
    hasPublicApi: false,
    isGuest,
    cancelToken,
    urlInfo,
  });
  return res.data;
};

const deleteSearchRequest: ApiCall<{ projectId: string; searchId: string }, any> = async args => {
  const { projectId, searchId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/similarity-search/requests/${searchId}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const thumbnailUrlCache: Record<string, string> = {};

const getSemanticSearchThumbnailUrls: ApiCall<
  {
    projectId: string;
    semanticSearchId: string;
    keys: string[];
  },
  Record<string, string>
> = async args => {
  const { projectId, semanticSearchId: semanticSearchId, isGuest, urlInfo, keys } = args;

  const unknownKeys: string[] = [];

  keys.forEach(key => {
    if (!thumbnailUrlCache[key]) {
      unknownKeys.push(key);
    }
  });

  if (unknownKeys.length > 0) {
    const res = await AuthService.apiCallAfterLogin({
      method: 'post',
      url: `/projects/${projectId}/similarity-search/results/${semanticSearchId}/image-urls/`,
      data: {
        keys: unknownKeys,
      },
      hasPublicApi: false,
      isGuest,
      urlInfo,
    });

    const urls = res.data.urls;
    urls.forEach((url: string, idx: number) => {
      thumbnailUrlCache[unknownKeys[idx]] = url;
    });
  }

  const result: Record<string, string> = {};
  keys.forEach(key => {
    result[key] = thumbnailUrlCache[key];
  });

  return result;
};

export default {
  getImageUploadUrls,
  uploadImage,
  createSearchRequest,
  listSearchRequest,
  getSearchRequest,
  deleteSearchRequest,
  getSemanticSearchThumbnailUrls,
};
