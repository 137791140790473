import React from 'react';
import { useTranslation } from 'react-i18next';

const makeIndicatorPolygon = (x: number, y: number): React.SVGProps<SVGPathElement> => {
  const GREYISH = '#929292';
  const theta = '270';
  return (
    <path
      d="M 0 0 L 10 8 l 0 40 H -10 v -40 z"
      transform={`translate(${x}, ${y}) rotate(${theta})`}
      fill={GREYISH}
      stroke={GREYISH}
      strokeWidth="1"
    />
  );
};

interface LocationProps {
  x: number;
  y: number;
}

const TotalIndicator: React.FC<LocationProps> = ({ x, y }) => {
  const { t } = useTranslation();
  const fontFamily = 'Inter';
  const fontSize = '12px';
  const fontWeight = 500;

  return (
    <>
      {makeIndicatorPolygon(x, y)}
      <text
        className="total-text"
        fill="white"
        x={x + 14}
        y={y + 5}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {t('analytics.text.total')}
      </text>
    </>
  );
};

export default TotalIndicator;
