import React, { useEffect, useRef, useState } from 'react';

import { isEmpty, reduce } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';

interface VerticalLegendProps {
  tickSize: number;
  width: number;
  height: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  ticks?: number[];
  tickFormat?: string;
  setValues: (value: number[]) => void;
  gradientId?: string;
  clickedLegendRange: number[];
  setTooltipLocation: (value: number[]) => void;
}

export const VerticalLegend = ({
  tickSize = 0,
  width = 200,
  height = 44 + tickSize,
  mt = 18,
  mb = 16 + tickSize,
  ml = 0,
  tickFormat,
  setValues,
  gradientId,
  clickedLegendRange,
  setTooltipLocation,
}: VerticalLegendProps): JSX.Element => {
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  const axisRef = useRef<SVGSVGElement>(null);
  const thresholds = [
    [76, 100],
    [26, 75],
    [0, 25],
  ];
  const thresholdsToIndex = reduce(
    thresholds,
    (agg, threshold, index) => {
      agg[threshold.toString()] = index;
      return agg;
    },
    {} as Record<string, number>,
  );

  const [hoveredLegendIndex, setHoveredLegendIndex] = useState<number>(
    (!isEmpty(clickedLegendRange) && thresholdsToIndex[clickedLegendRange.toString()]) || -1,
  );

  useEffect(() => {
    // Remove brush if legend is deselected via clicking brush area
    if (isEmpty(clickedLegendRange)) {
      setHoveredLegendIndex(-1);
    }
  }, [clickedLegendRange]);

  // @ts-ignore this works
  const tickAdjust = g => g.selectAll('.tick line').remove(); // remove tick lines

  useEffect(() => {}, [
    axisRef,
    // x,
    ml,
    mt,
    mb,
    height,
    tickFormat,
    tickSize,
    tickAdjust,
  ]);

  /** Handle Mouse Events over the legend */
  const handleMouseEnter = (i: number) => {
    // i: [0, 1, 2]
    // d: [0~25, 26~75, 76~100]
    /** set score range */
    setHoveredLegendIndex(i);
    setTooltipLocation(thresholds[i]);
  };

  const handleMouseLeave = () => {
    setHoveredLegendIndex(-1);
    setTooltipLocation([]);
  };

  const handleClick = (i: number) => {
    const selected = thresholds[i];
    setValues(selected);
    setHoveredLegendIndex(i);
  };

  const gTransform = `translate(${ml - width}, ${mt})`;

  const data = [
    {
      key: 'highest scores',
      y: 0,
      height: height * 0.25,
    },
    {
      key: 'middle scores',
      y: height * 0.25,
      height: height * 0.5,
    },
    {
      key: 'lowest scores',
      y: height * 0.75,
      height: height * 0.25 + 2, // 2 is offset required to cover x-axis area
    },
  ];

  const getLegendColor = (hoveredLegendIndex: number, i: number): string => {
    const GREY = '#E5E5E5';
    // if legend is clicked, only color clicked legend
    if (!isEmpty(clickedLegendRange)) {
      const current = thresholds[i];
      const isClicked =
        current[0] === clickedLegendRange[0] && current[1] === clickedLegendRange[1];
      return isClicked ? `url(#${gradientId})` : GREY;
    }
    // if legend is hovered, only color hovered legend
    return hoveredLegendIndex >= 0 && hoveredLegendIndex !== i ? GREY : `url(#${gradientId})`;
  };

  return (
    <g>
      <g transform={gTransform} ref={axisRef}>
        {data.map((d, i) => {
          return (
            <rect
              key={d.y + d.key}
              y={d.y}
              width={width}
              height={d.height}
              fill={getLegendColor(hoveredLegendIndex, i)}
              stroke={getLegendColor(hoveredLegendIndex, i)}
              strokeWidth={1}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={() => handleMouseLeave()}
              onClick={() => handleClick(i)}
              cursor="pointer"
            />
          );
        })}
      </g>
    </g>
  ) as JSX.Element;
};
