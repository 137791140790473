import { Box, ToggleButton, Typography } from '@superb-ai/ui';

import { Role } from './types';

export default function MfaTeam({
  mfaRoles,
  onChangeMfaRoles,
}: {
  mfaRoles: Role[];
  onChangeMfaRoles: (value: Role[]) => void;
}) {
  function toggleRole(role: Role) {
    onChangeMfaRoles(
      mfaRoles.includes(role) ? mfaRoles.filter(r => r != role) : [...mfaRoles, role],
    );
  }

  return (
    <Box display="flex" flexDirection="column" p={4} gap={1} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="m-regular">Owner, Admin</Typography>
        <ToggleButton
          size="s"
          checked={mfaRoles.includes('Admin')}
          onClick={() => {
            toggleRole('Admin');
          }}
        />
      </Box>
      <hr style={{ color: '#F5F5F5' }} />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="m-regular">Collaborator</Typography>
        <ToggleButton
          size="s"
          checked={mfaRoles.includes('Collaborator')}
          onClick={() => {
            toggleRole('Collaborator');
          }}
        />
      </Box>
    </Box>
  );
}
