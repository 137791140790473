import AuthService from './AuthService';
import { ApiCall } from './types';

const getNotifications: ApiCall<{ lastCreatedAt: string | number | Date }, any> = async args => {
  const { lastCreatedAt, isGuest, urlInfo } = args;
  const date = new Date(lastCreatedAt).toISOString();
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/notifications/?last_created_at=${date}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data.data;
};

const readNotification: ApiCall<{ ids: string[] }, any> = async args => {
  const { ids, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'put',
    url: '/notifications/',
    data: { ids },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const markAllAsRead: ApiCall<unknown, any> = async args => {
  const { isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: '/notifications/',
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

export default {
  getNotifications,
  readNotification,
  markAllAsRead,
};
