import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, Icon as NFIcon } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';

import DropDownMenu from '../../../elements/DropDownMenu';

const useStyles = makeStyles(() => ({
  menuPopper: {
    '&.disabled': {
      // color: '#c0c0c0',
      // backgroundColor: 'rgba(169, 169, 169, 0.2)',
    },
    '&:hover': {
      // backgroundColor: '#ebebeb',
    },
  },
  dropdownRow: {
    width: '95px',
  },
}));

interface SortOption {
  key: string;
  name: string;
  direction: 'asc' | 'desc';
  display: boolean;
}

declare type Props = {
  handleSortChange: (sortAxis: 'x' | 'y') => void;
  sortX: SortOption; // ex. {key: 'count', name: 'Objects Count', direction: 'asc', display: false}
  sortY: SortOption;
  isDisabled: boolean;
  size: 's' | 'xxs' | 'xs' | 'm' | 'l' | 'xl' | undefined;
};

const SortDropDown = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sortX, sortY, handleSortChange, isDisabled, size } = props;

  return (
    <DropDownMenu>
      <Button
        key="anchor"
        className={classnames(classes.menuPopper, { disabled: isDisabled })}
        color="lightgrey"
        disabled={isDisabled}
        IconAdornment={<NFIcon name="sort" />}
        iconPosition="left"
        style={{ cursor: isDisabled ? 'default' : 'pointer' }}
        size={size}
        variant="stroke"
      >
        {t('analytics.sort')}
      </Button>
      <MUI.Box key="popper">
        <MUI.MenuItem onClick={() => handleSortChange('y')}>
          <MUI.Box className={classes.dropdownRow}>{t(sortY.name)}</MUI.Box>
          <MUI.Box>
            {sortY.display &&
              (sortY.direction === 'asc' ? (
                <NFIcon name="sortNumericDesc" color={['grey', 400]} size="15px" />
              ) : (
                <NFIcon name="sortNumericAsc" color={['grey', 400]} size="15px" />
              ))}
          </MUI.Box>
        </MUI.MenuItem>
        <MUI.MenuItem key={2} onClick={() => handleSortChange('x')}>
          <MUI.Box className={classes.dropdownRow}>{t(sortX.name)}</MUI.Box>
          <MUI.Box>
            {sortX.display &&
              (sortX.direction === 'asc' ? (
                <NFIcon name="sortAlphabetDesc" color={['grey', 400]} size="15px" />
              ) : (
                <NFIcon name="sortAlphabetAsc" color={['grey', 400]} size="15px" />
              ))}
          </MUI.Box>
        </MUI.MenuItem>
      </MUI.Box>
      )
    </DropDownMenu>
  );
};

export default SortDropDown;
