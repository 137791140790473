import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import Security from '../../../components/pages/account/security/Layout';
import { isMfaRequired } from '../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../menu/MenuItem';

export const SecurityMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountSecurity,
  path: 'security',
  title: ({ t }) => t('settings.security.title'),
  component: Security,
  icon: 'shield',
  isVisible({ authInfo, featureFlags }) {
    return authInfo?.role === 'owner' || isMfaRequired(authInfo);
  },
  isEnabled({ authInfo }) {
    return authInfo?.role === 'owner' || isMfaRequired(authInfo);
  },
});
