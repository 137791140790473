import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { ChevronDown } from '@superb-ai/icons';
import { Box, Collapse, IconButton, Typography } from '@superb-ai/ui';

export default function SamplePredictionCabinetCollapse({
  title,
  isLast,
  children,
  disabled,
}: PropsWithChildren<{ title: string | ReactNode; isLast?: boolean; disabled?: boolean }>) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  return (
    <Box
      pb={open ? 1.5 : 1}
      px={1}
      mb={1}
      borderBottom={isLast ? undefined : '1px solid'}
      borderColor={isLast ? undefined : 'gray-200'}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        mb={0.5}
      >
        {typeof title === 'string' ? (
          <Typography variant="l-strong">{title}</Typography>
        ) : (
          <>{title}</>
        )}
        <Box
          display="flex"
          alignItems="center"
          style={{
            transition: 'all 0.1s ease-in-out',
            ...(open ? {} : { transform: 'rotate(-90deg)' }),
          }}
        >
          <IconButton
            icon={ChevronDown}
            variant="text"
            inactive={disabled}
            disabled={disabled}
            onClick={() => setOpen(!open)}
          />
        </Box>
      </Box>
      <Collapse open={open}>{children}</Collapse>
    </Box>
  );
}
