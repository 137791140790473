// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { WindowedMenuList } from 'react-windowed-select';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import classNames from 'classnames';
import debounce from 'debounce-promise';

const useStyles = makeStyles({
  select: {
    fontSize: '13px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& .AutoSelect__control': {
      backgroundColor: 'transparent',
    },
    '& .AutoSelect__control--is-focused': {
      borderColor: '#ff625a !important',
    },
    '& .AutoSelect__indicator': {
      '& svg': {
        width: '13px',
        height: '13px',
      },
      '&[class$=indicatorContainer]': {
        padding: '6px',
      },
    },
    '& .select-option': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '22px',
    },
  },
});

const AutoCompleteSelectStyles = {
  control: styles => ({
    ...styles,
    minHeight: 'initial',
    boxShadow: 'none',
    cursor: 'text',
  }),
  indicator: styles => ({
    ...styles,
    padding: '6px',
    color: '#797979',
    '& svg': {
      width: '13px',
      height: '13px',
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#797979',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#797979',
    paddingLeft: '2px',
    paddingRight: '2px',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#333',
    },
  }),
  input: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: styles => ({
    ...styles,
    boxShadow: '0 2px 6px 0 rgba(208, 208, 208, 0.5)',
    marginTop: '8px',
  }),
};

const AutoCompleteSelectTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: 'rgba(0, 0, 0, 0.3)',
      primary25: 'rgba(0, 0, 0, 0.14)',
      primary50: 'rgba(0, 0, 0, 0.14)',
    },
  };
};

const Option = props => {
  const { selectProps, data, innerProps, children } = props;

  if (selectProps.optionComponent) {
    const childrenWithProps = React.Children.map(selectProps.optionComponent, child =>
      React.cloneElement(child, { data }),
    );

    return (
      <MUI.MenuItem component="div" className="select-option" {...innerProps}>
        {childrenWithProps}
      </MUI.MenuItem>
    );
  }

  return (
    <MUI.MenuItem component="div" className="select-option" {...innerProps}>
      {children}
    </MUI.MenuItem>
  );
};

const Menu = props => {
  const { children, className, cx, getStyles, innerRef, innerProps } = props;

  return (
    <div
      style={getStyles('menu', props)}
      className={cx({ menu: true }, className)}
      {...innerProps}
      ref={innerRef}
    >
      {children}
    </div>
  );
};

const components = {
  Option,
  Menu,
  MenuList: WindowedMenuList,
};

const AutoCompleteSelect = React.forwardRef<HTMLElement, any>((props, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    className,
    creatable,
    asynchronous,
    asyncCreatable,
    styles,
    onAsyncSearch,
    ...otherProps
  } = props;

  if (typeof window === 'undefined') return null;

  if (creatable) {
    return (
      <CreatableSelect
        {...otherProps}
        ref={ref}
        className={classNames(classes.select, className)}
        components={{ Menu, Option }}
        maxMenuHeight={230}
        classNamePrefix="AutoSelect"
        theme={theme => AutoCompleteSelectTheme(theme)}
        placeholder={t('curate.advancedQuery.placeholder.select')}
        styles={{ ...AutoCompleteSelectStyles, ...styles }}
      />
    );
  }

  if (asynchronous) {
    return (
      <AsyncSelect
        {...otherProps}
        ref={ref}
        className={classNames(classes.select, className)}
        components={{ Menu, Option }}
        classNamePrefix="AutoSelect"
        maxMenuHeight={230}
        theme={theme => AutoCompleteSelectTheme(theme)}
        styles={{ ...AutoCompleteSelectStyles, ...styles }}
        placeholder={t('curate.advancedQuery.placeholder.select')}
        loadOptions={debounce(
          async inputValue => {
            if (!onAsyncSearch) return {};
            const result = await onAsyncSearch(inputValue);
            return result;
          },
          1000,
          { leading: true },
        )}
      />
    );
  }

  if (asyncCreatable) {
    return (
      <AsyncCreatableSelect
        {...otherProps}
        ref={ref}
        className={classNames(classes.select, className)}
        createOptionPosition="first"
        components={components}
        maxMenuHeight={230}
        classNamePrefix="AutoSelect"
        theme={theme => AutoCompleteSelectTheme(theme)}
        styles={{ ...AutoCompleteSelectStyles, ...styles }}
        placeholder={t('curate.advancedQuery.placeholder.select')}
        loadOptions={debounce(
          async inputValue => {
            if (!onAsyncSearch) return {};
            const result = await onAsyncSearch(inputValue);
            return result;
          },
          1000,
          { leading: true },
        )}
      />
    );
  }

  return (
    <Select
      {...otherProps}
      ref={ref}
      className={classNames(classes.select, className)}
      components={components}
      maxMenuHeight={230}
      classNamePrefix="AutoSelect"
      placeholder={t('curate.advancedQuery.placeholder.select')}
      theme={theme => AutoCompleteSelectTheme(theme)}
      styles={{ ...AutoCompleteSelectStyles, ...styles }}
    />
  );
});

export default AutoCompleteSelect;
