import { Box, Button, Typography } from '@superb-ai/ui';

export default function SecurityTutorialTop({
  title,
  description,
  buttonText,
  buttonLink,
}: {
  title: string | JSX.Element;
  description: string | JSX.Element;
  buttonText: string;
  buttonLink: () => void;
}) {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      backgroundColor="secondary-100"
      style={{ minHeight: '120px' }}
    >
      <Box
        display="flex"
        backgroundColor="secondary-100"
        justifyContent="space-between"
        alignItems="center"
        p={4}
        style={{ width: '100%', maxWidth: '1324px' }}
      >
        <Box>
          <Typography variant="h3">{title}</Typography>
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="m-regular">{description}</Typography>
          </Box>
          <Box mt={2}>
            <Button variant="strong-fill" onClick={buttonLink}>
              {buttonText}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ minWidth: '400px', minHeight: '150px' }}
        >
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img alt="tutorial" src="https://asset.superb-ai.com/suite/security_tutorial.png" />
        </Box>
      </Box>
    </Box>
  );
}
