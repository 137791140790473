import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Icon, Link, Slider, Typography } from '@superb-ai/norwegian-forest';

import { getUserManualUrl } from '../../../../../consts/DocsLink';

const useStyles = makeStyles(() => ({
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    '& > div:first-child': {
      marginTop: 'auto',
    },
  },
  labelAndValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    marginBottom: '15px',
    '& .label': {
      fontWeight: 500,
      display: 'flex',
      gap: '10px',
    },
    '& .value': {
      color: '#A6A6A6',
      marginLeft: 'auto',
      '& input': {
        textAlign: 'right',
        width: '45px',
        border: '1px solid #E5E5E5',
        padding: '0 5px',
        lineHeight: 1.8,
        borderRadius: '3px',
        color: '#333',
        fontSize: 'inherit',
      },
    },
  },
  arrowIcon: {
    marginLeft: 'auto',
    visibility: 'hidden',
    display: 'flex',
    '&.visible': {
      visibility: 'visible',
    },
    '& svg': {
      transform: 'rotate(180deg)',
      transition: 'transform .2s',
    },
    '&.desc svg': {
      transform: 'rotate(0deg)',
    },
  },
  methodOptions: {
    '& button': {
      alignItems: 'flex-start',
      paddingTop: '12px',
      lineHeight: 1,
      paddingBottom: '15px',
    },
  },
  methodOption: {
    textAlign: 'left',
    paddingTop: '1px',
    '& svg': {
      height: '38px !important',
      width: 'auto !important',
      marginTop: '10px',
      marginLeft: '0px !important',
      fill: '#aaa',
    },
  },
}));

interface ConsensusSettingsProps {
  uniqLabelsCount: number;
  labelSubsampleCount: number;
  setLabelSubsampleCount: (count: number) => void;
  consensusCount: number;
  setConsensusCount: (count: number) => void;
}

const ConsensusSettings: React.FC<ConsensusSettingsProps> = props => {
  const {
    uniqLabelsCount,
    labelSubsampleCount,
    setLabelSubsampleCount,
    consensusCount,
    setConsensusCount,
  } = props;
  const classes = useStyles();
  const currentProportion = Math.round((labelSubsampleCount / uniqLabelsCount) * 100);

  const { i18n } = useTranslation();
  const { CONSENSUS_MANUAL } = getUserManualUrl(i18n.language);

  const handleSliderInput =
    (setter: (val: number) => void, max?: number) => (event: React.FormEvent<HTMLInputElement>) => {
      const strValue = (event.target as HTMLInputElement).value;
      if (strValue === '') {
        setter(0);
      } else {
        const value = parseInt(strValue, 10);
        if (!Number.isNaN(value)) {
          setter(Math.max(0, Math.min(value, max ?? Infinity)));
        }
      }
    };

  const handleSetByProportion = (value: number) => {
    setLabelSubsampleCount(Math.round((value / 100) * uniqLabelsCount));
  };

  const handleSetConsensusCount = (value: number) => {
    // TODO (shko): check current consensus count and set minimum selectable count
    setConsensusCount(value);
  };

  return (
    <div className={classes.settings}>
      <div>
        <Typography variant="body3">
          Have multiple members label the data. You can then compare and merge those labels into one
          main label.{' '}
          <Link href={CONSENSUS_MANUAL} target="_blank" themedColor="secondary" external>
            Learn more
          </Link>
        </Typography>
      </div>
      <div>
        <div className={classes.labelAndValue}>
          <span className="label">Proportion of selected data</span>
          <span className="value">
            <span style={{ color: '#000' }}>{currentProportion}%</span> ({labelSubsampleCount} /{' '}
            {uniqLabelsCount})
          </span>
        </div>
        <Slider
          min={0}
          max={100}
          value={currentProportion}
          onChange={handleSetByProportion}
          variant="bigThumb"
        />
      </div>
      <div>
        <div className={classes.labelAndValue}>
          <span className="label">How many times do you want to label the data?</span>
          <span className="value">
            <input
              type="text"
              value={consensusCount}
              onChange={handleSliderInput(handleSetConsensusCount)}
            />
          </span>
        </div>
        <Slider
          min={0}
          max={10}
          value={consensusCount}
          onChange={handleSetConsensusCount}
          variant="bigThumb"
        />
        <Box
          themedBackgroundColor={['primary', 100]}
          themedColor="primary"
          display="flex"
          alignItems="top"
          p={1}
          mt={1.5}
          mb={1}
        >
          <Box display="flex" mt={0.5}>
            <Icon name="warningOutline" size="12px" />
          </Box>
          <Box ml={0.5}>
            <Typography variant="body3">
              Once you apply consensus labeling, you can increase but not reduce the number of
              consensus labels.
            </Typography>
            <Box mt={1} />
            <Typography variant="body3">
              If you’ve already labeled the data, its annotations will be copied to the consensus
              labels as well.
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ConsensusSettings;
