import { groupBy, has, map, orderBy, reduce, reverse, upperFirst } from 'lodash';

import LabelInterfaceUtils, {
  Category,
  CategoryLeafNode,
  CategoryPropertyList,
  Mapper,
} from '../../../../utils/LabelInterfaceUtils';
import { IWordMap, SortOption } from '../dataTypes/analyticsDataTypes';
import { formatCategoryData2011 } from '../tools/helper';
import { BaseDatum, JsonObj } from '../userStats/types';
import { catAggregateDatasets2011 } from './CategorySummaryTransformers2011';

export const sortData = (data: JsonObj[], sortOne: SortOption, sortTwo: SortOption): JsonObj[] => {
  return orderBy(data, [sortOne.key, sortTwo.key], [sortOne.direction, sortTwo.direction]);
};

interface CategoryCountDatum {
  categoryId: string;
  count: number;
}

export function convertArrayToMap(data: CategoryCountDatum[]): Mapper<number> {
  return data.reduce((agg, category) => {
    return { ...agg, [category.categoryId]: category.count } as Mapper<number>;
  }, {} as Mapper<number>);
}
/**
 *
 * @param data /GET category-summaries response data
 * @param counter Category config from the project setting
 * @returns Category nodes, including updated count from `data`.
 *
 * Result is array (properties) of arrays (its nodes).
 *
 * Outer array is category properties [property 1, property 2,..] where each property is
 * an array of nodes: [
 *   {id: 'sunny-category-id', name: 'sunny', count: 2, propertyId: 'category-property1-id'},
 *   {id: 'rainy-category-id', name: 'rainy', count: 1, propertyId: 'category-property1-id'},
 *   ...
 * ]
 */
export function transformCategoryData(
  data: CategoryCountDatum[],
  nodes: CategoryLeafNode[][],
): BaseDatum[] {
  // Map categoryId to count
  const remapped = convertArrayToMap(data);

  // Using categories in label interface, update count from response
  const interim = [];
  for (const propertyGroup of nodes) {
    for (const node of propertyGroup) {
      interim.push({ ...node, count: remapped[node?.id] ?? node.count });
    }
  }

  // Group category nodes by property id
  const transformed = map(
    groupBy(interim, node => node.propertyId),
    (v, _) => v,
  );
  return transformed;
}

export interface LegacyCategoryLeafNode {
  id: string;
  name: string;
  group: string;
  propertyId: string;
  propertyName: string;
  count?: number;
}

/** Legacy workapp category count */
export const combineDataAndWordMap = (
  data: Mapper<number>,
  hierarchy: Mapper<string[]>,
  wordMap: IWordMap[],
): BaseDatum[] => {
  const names: Mapper<string> = wordMap.reduce((agg, node) => {
    return { ...agg, [node.id]: node.name };
  }, {});

  return wordMap
    .filter(d => d.id !== 'root')
    .filter(d => !(d.isGroup === true && d.parent === 'root'))
    .flatMap(node => {
      if (node.parent !== 'root' || node.id !== 'root') {
        const parents = reverse(hierarchy[node.id]) as string[]; // parent first
        const parentNameMap = reduce(
          parents,
          (result, nodeId) => {
            return {
              ...result,
              [nodeId]: upperFirst(names[nodeId]),
            };
          },
          {},
        );
        return {
          ...node,
          category_id: node.id,
          displayName: upperFirst(names[node.id]),
          parentName: upperFirst(names[node.parent]),
          count: data[node.id] ?? 0,
          groupName: parents.length > 1 ? names[parents[0]] : undefined,
          subGroupName: parents.length > 2 ? names[parents[2]] : undefined,
          groups: parentNameMap || {},
          groupIds: parents || [],
        };
      }
    });
};

const getLastElementFromSplit = (categoryPath: string, delimiter: string) => {
  return categoryPath.split(delimiter).slice(-1)[0];
};

/** Aggregate by dataset, sort by category count */
export const transformLegacyCategoryData = (
  data: CategoryCountDatum[],
  chartInfo: JsonObj,
  wordMap: IWordMap[], // make custom type
): JsonObj[] => {
  const { sortX, sortY } = chartInfo;

  // Map categoryId to count
  const remapped = data.reduce((agg, catPath) => {
    return {
      ...agg,
      [getLastElementFromSplit(catPath.categoryId, ',')]: catPath.count,
    } as Mapper<number>;
  }, {} as Mapper<number>);

  // Map categoryId to direct parent
  const mapper = wordMap.reduce((agg, d) => {
    return { ...agg, [d.id]: d.parent };
  }, {}) as Mapper<string>;

  // Map categoryId to parents
  const hierarchy = wordMap.reduce((agg, d) => {
    const temp = [d.id];
    if (d.parent === 'root') {
      return agg;
    }
    if (d.parent) {
      temp.push(d.parent);
    }
    if (has(mapper, d.parent) && mapper[d.parent] !== 'root') {
      temp.push(mapper[d.parent]);
    }
    return { ...agg, [d.id]: temp };
  }, {});

  const transformed = combineDataAndWordMap(remapped, hierarchy, wordMap);
  return sortData(transformed, sortY, sortX);
};

/**
 * Adds groups (mapping of category group id to name) to category data
 **/
export const formatSiestaCategoryData = (
  data: JsonObj[],
  propertyInfo: Category,
  sortX: SortOption,
  sortY: SortOption,
): JsonObj[] => {
  const getGroupsFrom = reduce(
    propertyInfo?.options,
    (agg, option) => {
      agg[option?.id] = option?.parents;
      return agg;
    },
    {} as JsonObj,
  );

  // const flatData = _.map(data?.data, option => {
  const flatData = map(data, option => {
    const assetGroup = option?.assetGroup; // TODO: check this works
    return {
      ...option,
      assetGroup,
      groups: getGroupsFrom[option?.id] || [],
    };
  });

  return sortData(flatData, sortY, sortX);
};

export const preprocessCategoryCount = (
  data: { count: number; results: CategoryCountDatum[] },
  isSiestaWorkapp: boolean,
  analyticsProxyEnabled = true,
  additionalInfo: {
    plotConfig: JsonObj;
    categoryMap: CategoryPropertyList | IWordMap[];
  },
) => {
  const { plotConfig, categoryMap } = additionalInfo;
  if (analyticsProxyEnabled && isSiestaWorkapp) {
    const countableCategories = (categoryMap as CategoryPropertyList).filter(
      (c: Category) => c?.type !== 'free response',
    );
    const categoryNodes = LabelInterfaceUtils.getLeafNodesByProperty(countableCategories);
    return transformCategoryData(data?.results, categoryNodes);
  } else if (analyticsProxyEnabled && !isSiestaWorkapp) {
    return transformLegacyCategoryData(data?.results, plotConfig, categoryMap as IWordMap[]);
  } else if (!analyticsProxyEnabled && isSiestaWorkapp) {
    return catAggregateDatasets2011(data?.results);
  } else {
    return formatCategoryData2011(
      data?.results,
      plotConfig,
      'imageCategoryStats',
      categoryMap as IWordMap[],
    );
  }
};
