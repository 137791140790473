import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { canManageProject } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';
import Layout from './Layout';

export const ProjectSettingsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectSettings,
  path: 'settings',
  title: ({ t }) => t('projectSettings.title'),
  component: Layout,
  icon: 'settings',
  isVisible({ authInfo }) {
    return canManageProject(authInfo);
  },
  isEnabled({ authInfo }) {
    return canManageProject(authInfo);
  },
});
