import { ComponentProps, Fragment } from 'react';

import { Box, Button, Icon, IconButton, Tooltip } from '@superb-ai/ui';

import { ButtonDef } from './types';

export function renderButton(
  { icon, color, onClick, label, disabled, tooltip }: ButtonDef,
  extraProps?: ComponentProps<typeof Button>,
) {
  const element = (
    <Box whiteSpace="nowrap" as="span">
      {!label && icon ? (
        <IconButton
          icon={icon}
          size="m"
          disabled={disabled}
          onClick={onClick}
          color={color}
          {...extraProps}
        />
      ) : (
        <Button
          size="m"
          disabled={disabled}
          onClick={onClick}
          color={color}
          style={{ padding: '0 12px', whiteSpace: 'nowrap' }}
          {...extraProps}
        >
          {icon && <Icon icon={icon} color="primary" />}
          {label}
        </Button>
      )}
    </Box>
  );
  if (disabled && tooltip) {
    return (
      <Tooltip content={<span>{tooltip}</span>} placement="bottom" key={label}>
        {element}
      </Tooltip>
    );
  }
  return <Fragment key={label}>{element}</Fragment>;
}

export function renderMenuItem(
  { icon, color, onClick, label, disabled, tooltip }: ButtonDef,
  extraProps?: ComponentProps<typeof Button>,
) {
  const element = (
    <span style={{ display: 'grid' }}>
      <Button
        variant="text"
        size="m"
        disabled={disabled}
        onClick={onClick}
        color={color}
        style={{ padding: '0 12px', justifyContent: 'left', fontWeight: 'normal' }}
        {...extraProps}
      >
        {icon && <Icon icon={icon} />}
        {label}
      </Button>
    </span>
  );
  if (disabled && tooltip) {
    return (
      <Tooltip content={tooltip} placement="left" key={label}>
        {element}
      </Tooltip>
    );
  }
  return <Fragment key={label}>{element}</Fragment>;
}
