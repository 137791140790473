import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Button, useDialogState } from '@superb-ai/ui';

import Tutorial from '../../../components/elements/Tutorial';
import ModelHubspotFormDialog from '../../../components/HubspotForms/ModelHubspotFormDialog';
import { getUserManualUrl } from '../../../consts/DocsLink';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../consts/ModalMessage';
import AnalyticsTracker from '../../../analyticsTracker';
import Page from '../../../menu/Page';
import ActionTutorialHeader from '../../Curate/components/datasets/tutorial/ActionTutorialHeader';
import baselineImage from './asset/baseline-image.png';
import deployImage from './asset/deploy-image.png';
import sliceImage from './asset/slice-image.png';

export const Layout = () => {
  const { i18n, t } = useTranslation();
  const { MODEL_BASELINE_MANUAL, MODEL_TRAINING_SET_MANUAL, MODEL_ENDPOINTS_MANUAL } =
    getUserManualUrl(i18n.language);

  const tutorialContents = [
    {
      imageSrc: baselineImage,
      title: t('model.intro.baseline.title'),
      description: t('model.intro.baseline.content'),
      link: MODEL_BASELINE_MANUAL,
    },
    {
      imageSrc: sliceImage,
      title: t('model.intro.slice.title'),
      description: t('model.intro.slice.content'),
      link: MODEL_TRAINING_SET_MANUAL,
    },
    {
      imageSrc: deployImage,
      title: t('model.intro.deploy.title'),
      description: t('model.intro.deploy.content'),
      link: MODEL_ENDPOINTS_MANUAL,
    },
  ];

  return (
    <Page boxProps={{ p: 0 }}>
      <Header />
      <Tutorial contents={tutorialContents} />
    </Page>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const state = useDialogState();
  const eventId = 'suite-model-contact-sales-form';
  const { accountName } = useParams<{ accountName: string }>();
  const { openModal, closeModal } = useAlertModal();

  return (
    <>
      <ActionTutorialHeader
        title={t('model.intro.header.title')}
        description={<Trans t={t} i18nKey="model.intro.header.content" />}
        action={
          <Button
            variant="strong-fill"
            color="gray-500"
            size="l"
            onClick={() => {
              AnalyticsTracker.contactUsClicked({ referrer: eventId, accountId: accountName });
              state.show();
            }}
          >
            {t('text.contactUs')}
          </Button>
        }
      />
      <ModelHubspotFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
    </>
  );
};
