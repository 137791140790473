import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

const ImageGrid: React.FC<{ columns?: number }> = ({ children, columns }) => {
  return (
    <Box mt={1.5} display="grid" gridTemplateColumns={`repeat(${columns || 4}, 1fr)`} gap="8px">
      {children}
    </Box>
  );
};

export default ImageGrid;
