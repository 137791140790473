import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteConfig } from '../../../../../routes/types';
import { getParentPath, getUrl } from '../../../../../routes/util';

const GSIntegrationAdd = lazy(() => import('./GoogleCloudStorage'));
const S3IntegrationAdd = lazy(() => import('./AmazonS3'));
const AzureBlobStorageAdd = lazy(() => import('./AzureBlobStorage'));
const SlackWebhooksIntegrationAdd = lazy(() => import('./SlackWebhooks'));

const AddIntegrationRoute: RouteConfig = ({ match }) => (
  <Switch>
    <Route exact path={getUrl(match.path, { type: 'S3' })} component={S3IntegrationAdd} />
    <Route exact path={getUrl(match.path, { type: 'GS' })} component={GSIntegrationAdd} />
    <Route exact path={getUrl(match.path, { type: 'ABS' })} component={AzureBlobStorageAdd} />
    <Route
      exact
      path={getUrl(match.path, { type: 'SLACK' })}
      component={SlackWebhooksIntegrationAdd}
    />
    <Redirect to={getParentPath(match.url, -2)} />;
  </Switch>
);
AddIntegrationRoute.path = 'add';

export { AddIntegrationRoute };
