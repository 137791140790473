import { format, formatISO9075 } from 'date-fns';
import { groupBy, map, orderBy, range, sumBy } from 'lodash';

import { getUTCDate, parseDate } from '../../../../utils/date';
import { LabelingProgressDatum } from '../dataTypes/analyticsDataTypes';

const extractDateOnly = (date: Date | string): Date =>
  parseDate(format(parseDate(date), 'yyyy-MM-dd'));

const addLabelCountDifferences = (data: LabelingProgressDatum[]): LabelingProgressDatum[] => {
  return map(range(data.length), x => {
    return {
      ...data[x],
      submitted: x === 0 ? 0 : data[x].cumSubmitted - data[x - 1].cumSubmitted,
      working: x === 0 ? 0 : data[x].cumWorking - data[x - 1].cumWorking,
      skipped: x === 0 ? 0 : data[x].cumSkipped - data[x - 1].cumSkipped,
    };
  });
};

const filterByDatasets = (
  data: LabelingProgressDatum[],
  selectedDatasets: string[],
): LabelingProgressDatum[] =>
  data.filter(d => d?.assetGroup && selectedDatasets.includes(d.assetGroup));

const combineDatasets = (data: LabelingProgressDatum[]) => {
  return map(groupBy(data, 'date'), datumByDataset => {
    return {
      ...datumByDataset[0],
      cumSkipped: sumBy(datumByDataset, 'cumSkipped'),
      cumSubmitted: sumBy(datumByDataset, 'cumSubmitted'),
      cumWorking: sumBy(datumByDataset, 'cumWorking'),
    };
  });
};

const formatLabelingProgressData = (
  data: LabelingProgressDatum[],
  selectedDatasets: string[],
  chartInfo: Record<string, any>,
): LabelingProgressDatum[] => {
  const yKey: keyof LabelingProgressDatum = chartInfo.yVariable[0];

  const convertDataFormat = (data: LabelingProgressDatum[]): LabelingProgressDatum[] => {
    return data.reduce((acc, d) => {
      const prevData = acc[acc.length - 1];
      const currentDate = new Date(d.date);
      const prevDate = new Date(prevData?.date);
      const date = extractDateOnly(d.date);
      if (currentDate.toDateString() === prevDate?.toDateString()) {
        return [
          ...acc.slice(0, acc.length - 1),
          {
            ...d,
            cumSkipped: d.cumSkipped + prevData.cumSkipped,
            cumSubmitted: d.cumSubmitted + prevData.cumSubmitted,
            cumWorking: d.cumWorking + prevData.cumWorking,
            skipped: (d?.skipped || 0) + (prevData?.skipped || 0),
            submitted: (d?.submitted || 0) + (prevData?.submitted || 0),
            working: (d?.working || 0) + (prevData?.working || 0),
            date,
            count: (d[yKey] as number) + (prevData[yKey] as number),
          },
        ];
      }
      return [...acc, { ...d, date, count: d[yKey] as number }];
    }, [] as LabelingProgressDatum[]);
  };
  let result = selectedDatasets.length
    ? combineDatasets(filterByDatasets(data, selectedDatasets))
    : data;
  result = convertDataFormat(result);
  return addLabelCountDifferences(result);
};

function getLatestData(data: LabelingProgressDatum[]): LabelingProgressDatum {
  return orderBy(data, [datum => datum.date], ['desc'])[0];
}

const filterByToday = () => {
  const getTodaysDateInEpochSeconds = () =>
    formatISO9075(parseDate(getUTCDate(new Date())), {
      representation: 'date',
    });
  return {
    last_reviewed_at_gte: getTodaysDateInEpochSeconds(),
    last_reviewed_at_lt: getTodaysDateInEpochSeconds(),
  };
};

function getFilteredLabelCount(lastDatum: LabelingProgressDatum, totalLabelCount: number) {
  return (
    (lastDatum && lastDatum.cumSkipped + lastDatum.cumSubmitted + lastDatum.cumWorking) ||
    totalLabelCount
  );
}

export default {
  formatLabelingProgressData,
  filterByDatasets,
  getLatestData,
  filterByToday,
  getFilteredLabelCount,
};
