import * as d3Format from 'd3-format';
/**
 * Group of d3 format functions
 */
export const FORMAT_PERCENT = d3Format.format('.0%');
export const roundToHundredth = d3Format.format('.2f');
export const roundToHundredthPercent = d3Format.format('.2%');

export const formatPercentGivenCountAndTotal = (
  count: number,
  total: number,
  addPercent: boolean,
): string => {
  if (addPercent) {
    return total === 0 ? FORMAT_PERCENT(0) : FORMAT_PERCENT(count / total);
  }
  return total === 0 ? '0' : roundToHundredth((count * 100) / total);
};

// format count above bars (1000 -> 1,000)
export const FORMAT_COUNT = d3Format.format(',');

/**
 * References
 * - http://bl.ocks.org/mstanaland/6106487
 * - http://bl.ocks.org/ChandrakantThakkarDigiCorp/6489ffd64504d90fdd6f36535e5dd3fd
 * - https://bl.ocks.org/mbostock/9764126
 */
