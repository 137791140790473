import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { Card } from '@superb-ai/norwegian-forest';
import { startsWith } from 'lodash';

type Props = {
  anchorElement: JSX.Element;
  open: boolean;
};

export default function Popper({ children, open, anchorElement }: React.PropsWithChildren<Props>) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      { name: 'offset', options: { offset: [0, 8] } },
    ],
  });

  const arrowStyle = () => {
    if (!attributes.popper) return;
    if (startsWith(attributes.popper['data-popper-placement'], 'top'))
      return { bottom: '-12px', borderBottomColor: 'transparent' };
    if (startsWith(attributes.popper['data-popper-placement'], 'bottom')) {
      return { top: '-12px', borderTopColor: 'transparent' };
    }
  };

  return (
    <>
      {anchorElement && React.cloneElement(anchorElement, { ref: setReferenceElement })}
      {open && (
        <>
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 1000 }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div
              ref={setArrowElement}
              style={{
                ...styles.arrow,
                border: ' 6px solid #ffffff',
                left: ' 0',
                borderLeftColor: ' transparent',
                borderRightColor: ' transparent',
                ...arrowStyle(),
              }}
            ></div>
            <Card>{children}</Card>
          </div>
        </>
      )}
    </>
  );
}
