import { FC } from 'react';

import { UserReportProvider } from '../../../contexts/UserReportContext';
import UserReportLayout from './userStats/UserReportLayout';

const UsersTab: FC = () => {
  return (
    <UserReportProvider>
      <UserReportLayout />
    </UserReportProvider>
  );
};

export default UsersTab;
