import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, DualRange, Input, Label, Typography } from '@superb-ai/ui';
import { clamp } from 'lodash';

import MathUtils from '../../../../../../../../utils/MathUtils';
import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';

export default function ConfidenceRangeFilter() {
  const { t } = useTranslation();
  const MIN_VALUE = 0;
  const MAX_VALUE = 1;
  const { filterBody, updateSelectedFilter } = useDiagnosisGridFilterContext();
  const { confidenceRange } = filterBody;

  function handleChangeValue(
    nextValueObject: { min: number } | { max: number } | { includeNull: boolean },
  ) {
    const nextConfidenceRange = {
      min: confidenceRange?.min || MIN_VALUE,
      max: confidenceRange?.max || MAX_VALUE,
      includeNull:
        confidenceRange?.includeNull ?? (confidenceRange?.includeNull === false ? false : true),
    };

    updateSelectedFilter({
      ...filterBody,
      ...{ confidenceRange: { ...nextConfidenceRange, ...nextValueObject } },
    });
  }

  const defaultIncludeNull = !confidenceRange || !!confidenceRange?.includeNull;
  return (
    <Box display="grid" gap={0.5}>
      <Typography variant="m-strong">
        {t('curate.diagnosis.filterPanel.confidenceRange')}
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Input
          boxProps={{ style: { width: '56px', paddingRight: 0 } }}
          type="number"
          step="0.01"
          value={confidenceRange?.min || MIN_VALUE}
          onChange={e =>
            handleChangeValue({
              min: MathUtils.round(
                clamp(
                  Number(e.target.value),
                  MIN_VALUE,
                  filterBody.confidenceRange?.max || MAX_VALUE,
                ),
                2,
              ),
            })
          }
        />
        <Box style={{ flex: 1 }}>
          <DualRange
            step={0.05}
            min={MIN_VALUE}
            max={MAX_VALUE}
            values={[confidenceRange?.min || MIN_VALUE, confidenceRange?.max || MAX_VALUE]}
            onValueChange={values =>
              handleChangeValue({
                min: MathUtils.round(values[0], 2),
                max: MathUtils.round(values[1], 2),
              })
            }
            onValueInput={values => {
              handleChangeValue({
                min: MathUtils.round(values[0], 2),
                max: MathUtils.round(values[1], 2),
              });
            }}
          />
        </Box>
        <Input
          boxProps={{ style: { width: '56px', paddingRight: 0 } }}
          type="number"
          step="0.01"
          value={confidenceRange?.max || MAX_VALUE}
          onChange={e =>
            handleChangeValue({
              max: MathUtils.round(
                clamp(
                  Number(e.target.value),
                  filterBody.confidenceRange?.min || MIN_VALUE,
                  MAX_VALUE,
                ),
                2,
              ),
            })
          }
        />
      </Box>
      <Label>
        <Checkbox
          value={defaultIncludeNull}
          onClick={() => handleChangeValue({ includeNull: !defaultIncludeNull })}
        />
        {t('curate.diagnosis.text.includeNull')}
      </Label>
    </Box>
  );
}
