import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as MUI from '@mui/material';
import { Box, Icon, StackedBarChart, Typography } from '@superb-ai/norwegian-forest';
import { SegmentedControl } from '@superb-ai/ui';
import { lowerCase, map } from 'lodash';

import { LabelReview } from '../../../consts/ReviewConst';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { LabelStatus } from '../../../types/labelTypes';
import LabelUtils from '../../../utils/LabelUtils';
import { formatCount } from '../../../utils/numberFormat';
import LabelBadgeStatus from '../../elements/LabelBadgeStatus';
import StatusPieChart from './charts/StatusPieChart';
import { LabelStatusCount, ReviewCount } from './types';

type Props = {
  className: string;
  status: LabelStatusCount[];
  review?: ReviewCount[][];
  showViewMode?: boolean;
  onSetViewMode?(viewMode: ViewMode): void;
};

const ReviewCountCard = ({
  item,
  ...props
}: { item: ReviewCount } & React.ComponentProps<typeof Box>) => {
  const { t } = useTranslation();
  return (
    <Box
      bl
      themedBorderColor={item.color}
      p={1.5}
      themedBackgroundColor={['grey', 50]}
      style={{ borderLeftWidth: 2, borderRadius: 2 }}
      className="clickable"
      {...props}
    >
      <Box mb={2}>
        <Typography variant="headline7">{t(item.displayName)}</Typography>
      </Box>
      <Typography variant="label" style={{ textAlign: 'right' }}>
        {formatCount(item.value)}
      </Typography>
    </Box>
  );
};

interface ReviewCountChartProps {
  counts: ReviewCount[];
  handleClickStatus(status: LabelStatus, review?: LabelReview): void;
}

const ReviewCountChart = ({ counts, handleClickStatus }: ReviewCountChartProps) => {
  const sum = counts.reduce((a, b) => a + b.value, 0);
  const getFormatValue = (value: number) => `${value} (${Math.round((value / sum) * 100)}%)`;

  return (
    <StackedBarChart.Chart height={12}>
      {counts.flatMap(byReviewAction =>
        byReviewAction.value > 0
          ? [
              <StackedBarChart.Bar
                key={byReviewAction.status + byReviewAction.name}
                value={byReviewAction.value}
                color={byReviewAction.color}
                formatValue={getFormatValue}
                onClick={() => handleClickStatus(byReviewAction.status, byReviewAction.name)}
              />,
            ]
          : [],
      )}
    </StackedBarChart.Chart>
  );
};

type ViewMode = 'label' | 'frame';
const DefaultViewMode = 'label';

const Status: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { className, status, review, onSetViewMode, showViewMode = false } = props;
  const totalCount = status ? status.reduce((a, curStatus) => a + curStatus.value, 0) : 0;
  const [viewMode, setViewMode] = useState<ViewMode>(DefaultViewMode);
  const viewModeOptions = [
    { label: t('overview.labelingStatus.byLabel'), value: 'label' as const },
    { label: t('overview.labelingStatus.byFrame'), value: 'frame' as const },
  ];

  useEffect(() => {
    onSetViewMode?.(viewMode);
  }, [onSetViewMode, viewMode]);

  const history = useHistory();
  const routeInfo = useRouteInfo();

  const handleClickStatus = (status: LabelStatus | 'total', review?: LabelReview) => {
    const url = `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels`;
    if (status === 'total') {
      history.push(url);
      return;
    }

    const curStatus = lowerCase(LabelUtils.getStatusKey(status));
    const params = new URLSearchParams();
    params.append('status', `is any one of,${curStatus}`);
    if (review) {
      const reviewName = lowerCase(review);
      if (reviewName === 'unreviewed') {
        params.append('review', 'does not exist');
      } else {
        params.append('review', `is any one of,${reviewName}`);
      }
    }
    history.push(`${url}?${params}`);
  };

  return (
    <MUI.Paper className={className}>
      <Box display="flex" alignItems="center">
        <Typography variant="headline4">{t('overview.labelingStatus.title')}</Typography>
        {showViewMode && (
          <Box ml="auto" width={140}>
            <SegmentedControl
              flexWidth
              options={viewModeOptions}
              value={viewMode}
              onChangeValue={setViewMode}
              buttonProps={{ size: 's' }}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <Box my={1}>
          <StatusPieChart
            status={status}
            totalCount={totalCount}
            colorPickerFn={LabelUtils.getStatusColor}
          />
        </Box>

        {review && (
          <Box flex={1} ml={4} mr={1} display="flex" gap="12px">
            {review.map((byStatus, idx) => (
              <Box key={idx} flex={1} display="flex" flexDirection="column" gap="12px">
                <LabelBadgeStatus status={byStatus[0].status} />

                <ReviewCountChart counts={byStatus} handleClickStatus={handleClickStatus} />

                {byStatus.map(byReviewAction => (
                  <ReviewCountCard
                    key={byReviewAction.status + byReviewAction.name}
                    item={byReviewAction}
                    onClick={() => handleClickStatus(byReviewAction.status, byReviewAction.name)}
                  />
                ))}
              </Box>
            ))}
          </Box>
        )}

        {!review && (
          <Box ml={4}>
            {map(status, curStatus => (
              <Box
                key={curStatus.name}
                mb={1}
                width="139px"
                display="flex"
                justifyContent="space-between"
                onClick={() => handleClickStatus(curStatus.name)}
                style={{ cursor: 'pointer' }}
              >
                <LabelBadgeStatus status={curStatus.name} />
                <MUI.Typography variant="h6" color="primary">
                  {formatCount(curStatus.value)}
                </MUI.Typography>
              </Box>
            ))}
            <Box
              bt
              pt={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleClickStatus('total')}
              style={{ cursor: 'pointer' }}
            >
              <MUI.Box display="flex" alignItems="center">
                <Box mr={0.5}>
                  <Icon name="arrowRight" size="12px" color="primary" />
                </Box>
                <Typography variant="label">{t('analytics.text.total')}</Typography>
              </MUI.Box>
              <MUI.Typography variant="h6" color="primary">
                {formatCount(totalCount)}
              </MUI.Typography>
            </Box>
          </Box>
        )}
      </Box>
    </MUI.Paper>
  );
};

export default Status;
