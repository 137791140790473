import {
  Box,
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  ToggleButton,
} from '@superb-ai/norwegian-forest';

import { useCurateCommandContext } from '../../../../apps/Curate/contexts/CommandContext';

function ActionItem({
  filterKey,
  label,
}: {
  filterKey: keyof typeof commandContext.filters;
  label: string;
}) {
  const commandContext = useCurateCommandContext();
  const value = commandContext.filters[filterKey];
  return (
    <DropdownMenuItem
      onClick={e => {
        commandContext.setFilters({ ...commandContext.filters, [filterKey]: !value });
        e.stopPropagation();
      }}
    >
      <Box display="flex" gap="16px" alignItems="center" justifyContent="space-between">
        <span>{label}</span>
        <ToggleButton
          color={value ? 'grey' : 'backgroundColor'}
          value={value}
          style={{ background: value ? '#82DB24' : 'grey' }}
        />
      </Box>
    </DropdownMenuItem>
  );
}

export function CurateCommandProgressFilters(): JSX.Element {
  return (
    <DropdownMenu
      AnchorElement={<IconButton round size="s" icon="moreHorizontal" color="textDefault" />}
      offset={0}
    >
      <ActionItem filterKey="onlyMy" label="Only your actions" />
    </DropdownMenu>
  );
}
