import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Expand, Sync, UnavailableFit } from '@superb-ai/icons';
import { Box, Button, Checkbox, Chip, Icon, vars } from '@superb-ai/ui';
import { filter } from 'lodash';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useObjectClusterContext } from '../../../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../../contexts/ObjectScopeContext';
import AnnotatedImage from '../../../../../../elements/AnnotatedImage/AnnotatedImage';
import CroppedAnnotatedImage from '../../../../../../elements/AnnotatedImage/CroppedAnnotatedImage';
import { GRID_VIEW, VIEW, ViewMode } from '../../../../../../types/viewTypes';

type ExtraProps = {
  setOpenDetailImageIndex: (index: number) => void;
};
export default function ImageCell({
  columnIndex,
  rowIndex,
  columns,
  results,
  setOpenDetailImageIndex,
}: CellComponentProps<ExtraProps>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const annotationInfo = results[currentIndex];
  const [imageSize, setImageSize] = useState<[number, number]>(); // [width, height]
  const [hover, setHover] = useState(false);
  const { selectedData, setSelectedData, selectedAllData } = useActionContext();
  const { totalCount, viewAsImage } = useObjectScopeContext();
  const { t } = useTranslation();
  const { clusterColorMap } = useObjectClusterContext();
  const { classClusterFilterTab, classNameColorMap, selectedAnnotationClassInClusterTab } =
    useObjectFilterContext();
  const history = useHistory();
  const { location: search } = history;
  const params = new URLSearchParams(search.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;

  const isSelected = useMemo(
    () => filter(selectedData, ['id', annotationInfo?.id]).length > 0 || selectedAllData,
    [selectedData, annotationInfo?.id, selectedAllData],
  );
  const thumbnailUrl = annotationInfo && annotationInfo?.imageThumbnailUrl;

  useEffect(() => {
    if (!thumbnailUrl) return;
    const img = new Image();
    img.src = thumbnailUrl;
    img.onload = function () {
      setImageSize([img.naturalWidth, img.naturalHeight]);
    };
  }, [annotationInfo]);

  if (!annotationInfo || currentIndex > (totalCount || 0) - 1) {
    return <></>;
  }

  const isViewingClustersInSelectedClass =
    classClusterFilterTab === 'cluster' &&
    annotationInfo.annotationClass === selectedAnnotationClassInClusterTab;

  function toggleSelect() {
    if (isSelected) {
      const newSelectedData = selectedData?.filter(data => {
        return data.id !== annotationInfo.id;
      });
      if (newSelectedData?.length) setSelectedData([...newSelectedData]);
      else setSelectedData([]);
      return;
    }
    if (selectedData?.length)
      setSelectedData([
        ...selectedData,
        { id: annotationInfo.id, thumbnailUrl, imageId: annotationInfo.imageId },
      ]);
    else
      setSelectedData([{ id: annotationInfo.id, thumbnailUrl, imageId: annotationInfo.imageId }]);
  }

  const annotations = [
    {
      class: annotationInfo.annotationClass,
      type: annotationInfo.annotationType,
      coordinate: annotationInfo.annotationValue,
      roi: annotationInfo.roi,
      id: annotationInfo.id,
      isDashed:
        classClusterFilterTab === 'cluster' &&
        annotationInfo.annotationClass !== selectedAnnotationClassInClusterTab,
    },
  ];

  const annotationColor =
    classClusterFilterTab === 'class'
      ? (classNameColorMap && classNameColorMap[annotationInfo.annotationClass]) || undefined
      : isViewingClustersInSelectedClass
      ? (clusterColorMap || {})[annotationInfo.clusterId] || vars.color['gray-300']
      : vars.color['gray-100'];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      position="relative"
      backgroundColor="gray-100"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {thumbnailUrl && (
        <>
          {imageSize &&
            (viewAsImage ? (
              <AnnotatedImage
                color={annotationColor}
                srcUrl={thumbnailUrl}
                alt={annotationInfo.id}
                annotations={annotations}
                originalImageSize={[
                  annotationInfo.originalImageSize?.width || 100,
                  annotationInfo.originalImageSize?.height || 100,
                ]}
              />
            ) : (
              <CroppedAnnotatedImage
                color={annotationColor}
                srcUrl={thumbnailUrl}
                alt={annotationInfo.id}
                annotations={annotations}
                chipPrefix={
                  isViewingClustersInSelectedClass && !annotationInfo.clusterId ? (
                    <Icon icon={UnavailableFit} color="gray-300" size="6px" />
                  ) : undefined
                }
                originalImageSize={[
                  annotationInfo.originalImageSize?.width || 100,
                  annotationInfo.originalImageSize?.height || 100,
                ]}
                roi={annotationInfo.roi}
              />
            ))}
          {/* {classClusterFilterTab === 'cluster' && annotationInfo.clusterId && (
            <Box
              position="absolute"
              style={{
                right: '6px',
                top: '6px',
                width: '6px',
                height: '6px',
                backgroundColor:
                  (clusterColorMap || {})[annotationInfo.clusterId] || vars.color['gray-250'],
              }}
              mr="auto"
              borderRadius="4px"
            ></Box>
          )} */}
          {annotationInfo.labelProjectSyncExists && (
            <Box
              position="absolute"
              backgroundColor="gray-opacity-400"
              mr="auto"
              borderRadius="2px"
              display="flex"
              alignItems="center"
              gap={0.25}
              p={0.25}
              style={{ right: '4px', top: '4px' }}
            >
              <Icon color="green" icon={Sync} />
            </Box>
          )}
          <Box
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            backgroundColor="gray-opacity-300"
            style={{
              visibility: hover || isSelected ? 'visible' : 'hidden',
              ...(isSelected ? { outline: '3px solid #ff625a', outlineOffset: '-3px' } : {}),
            }}
            onClick={toggleSelect}
            cursor="pointer"
          >
            <Box position="absolute" style={{ top: 0, left: 0 }} mr="auto">
              <Checkbox value={isSelected} onClick={toggleSelect} />
            </Box>
            {view === 'semantic_search' &&
              selectedData &&
              selectedData[selectedData.length - 1]?.id === annotationInfo.id && (
                <Box position="absolute" style={{ top: 0, right: 0 }} mr="auto">
                  <Chip style={{ whiteSpace: 'nowrap' }}>유사도 검색 오브젝트</Chip>
                </Box>
              )}

            {hover && (
              <Box position="absolute" style={{ bottom: 4, right: 4 }} ml="auto">
                <Button
                  variant="stroke"
                  color="primary"
                  size="s"
                  onClick={e => {
                    e.stopPropagation();
                    setOpenDetailImageIndex(currentIndex);
                  }}
                >
                  <Icon icon={Expand} />
                  {t('curate.button.detail')}
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
