import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Modal, Typography } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { LABELS_DO_NOT_EXIST, UNKNOWN_ERROR } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';
import { formatCount } from '../../../../utils/numberFormat';
import ParamUtils from '../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../utils/router-utils';
import ExportDialogStatusBoard from './ExportDialogStatusBoard';

interface UnassignDialogProps {
  checkedLabels: any[];
  setCheckedLabels: any;
  isAllLabelsChecked: boolean;
  isOpen: boolean;
  checkedLabelsCount: number;
  setIsOpen: (open: boolean) => void;
  getLabels: any;
  type: 'labeler' | 'reviewer';
}

const typeToCommand = {
  labeler: 'LABELS_UNASSIGN_LABELER',
  reviewer: 'LABELS_UNASSIGN_REVIEWER',
} as const;

const UnassignDialog: React.FC<UnassignDialogProps> = props => {
  const {
    checkedLabels,
    checkedLabelsCount,
    setCheckedLabels,
    isAllLabelsChecked,
    isOpen,
    setIsOpen,
    getLabels,
    type,
  } = props;

  const { t } = useTranslation();

  const texts: Record<UnassignDialogProps['type'], Record<'title' | 'description', string>> = {
    labeler: {
      title: t('labels.button.unassignLabeler'),
      description: t('labels.unassign.unassignDescription'),
    },
    reviewer: {
      title: t('labels.button.cancelReviewRequest'),
      description: t('labels.unassign.reviewerDescription'),
    },
  };

  const urlSearchParams = useSearchParams();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const commandContext = useLabelCommandContext();
  const { tagIds } = projectInfo;

  const [isLoading, setIsLoading] = useState(false);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };
  const filterApiParams = useMemo(
    () =>
      ParamUtils.getApiParamsForFilter({
        filterParams: urlSearchParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      }),
    [routeInfo.params, tagIds],
  );
  const actionParams = !isAllLabelsChecked
    ? { idIn: checkedLabels }
    : { ...filterApiParams, ...searchParams };

  const init = () => {
    setIsLoading(false);
  };

  const handleClickClose = () => {
    init();
    setIsOpen(false);
  };

  const handleClickConfirmStatusBoard = async () => {
    setIsLoading(true);
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: typeToCommand[type],
        projectId: routeInfo.urlMatchInfo.projectId,
        params: actionParams,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);

      init();
      setIsOpen(false);
      setCheckedLabels([]);
      getLabels();
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
      }
      enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
    }
  };

  return (
    <Modal
      open={isOpen}
      title={
        <Typography variant="headline4" themedColor="primary">
          {texts[type].title}
        </Typography>
      }
      mainButton={{
        text: t('labels.unassign.apply', { labelCount: formatCount(checkedLabelsCount) }),
        onClick: handleClickConfirmStatusBoard,
        isLoading: isLoading,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClickClose,
      }}
      close={{
        canClickOutside: true,
        canCloseWithExit: true,
        onClose: handleClickClose,
      }}
    >
      <Box mx={4} my={2}>
        <ExportDialogStatusBoard
          checkedLabels={checkedLabels}
          filterApiParams={filterApiParams}
          isAllLabelsChecked={isAllLabelsChecked}
          title=""
          direction="row"
        />
      </Box>
      <Box mx={4} mb={4}>
        <Typography variant="body3">{texts[type].description}</Typography>
      </Box>
    </Modal>
  );
};

export default UnassignDialog;
