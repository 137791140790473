import React, { useEffect, useRef, useState } from 'react';

type ContextProps = {
  width: number;
  height: number;
  onResize(): void;
};

const Context = React.createContext({} as ContextProps);

const StageSizeProvider: React.FC = ({ children }) => {
  const stageRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  const handleResize = () => {
    if (stageRef.current) {
      setWidth(stageRef.current.clientWidth);
      setHeight(stageRef.current.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={stageRef}
      style={{ width: '100%', flex: 1, position: 'relative', overflow: 'hidden' }}
    >
      <Context.Provider value={{ width, height, onResize: handleResize }}>
        {children}
      </Context.Provider>
    </div>
  );
};

export default {
  Context,
  Provider: StageSizeProvider,
};
