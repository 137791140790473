import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Chip, Input, RadioButtons, Slider, Typography } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';

import ParamUtils from '../../../../../utils/ParamUtils';
import { ReactComponent as SvgMethodEquitable } from './method-equitable.svg';
import { ReactComponent as SvgMethodEven } from './method-even.svg';

const useStyles = makeStyles(() => ({
  settings: {
    height: '100%',
    maxHeight: '380px',
    width: '100%',
    maxWidth: '440px',
    margin: '32px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '25px',
  },
  labelAndValue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    marginBottom: '15px',
    '& .label': {
      fontWeight: 500,
      display: 'flex',
      gap: '10px',
    },
    '& .value': {
      color: '#A6A6A6',
      marginLeft: 'auto',
      '& input': {
        textAlign: 'right',
        width: '45px',
        border: '1px solid #E5E5E5',
        padding: '0 5px',
        lineHeight: 1.8,
        borderRadius: '3px',
        color: '#333',
        fontSize: 'inherit',
      },
    },
  },
  arrowIcon: {
    marginLeft: 'auto',
    visibility: 'hidden',
    display: 'flex',
    '&.visible': {
      visibility: 'visible',
    },
    '& svg': {
      transform: 'rotate(180deg)',
      transition: 'transform .2s',
    },
    '&.desc svg': {
      transform: 'rotate(0deg)',
    },
  },
  methodOptions: {
    '& button': {
      alignItems: 'flex-start',
      paddingTop: '12px',
      lineHeight: 1,
      paddingBottom: '15px',
    },
  },
  methodOption: {
    textAlign: 'left',
    paddingTop: '1px',
    '& svg': {
      height: '38px !important',
      width: 'auto !important',
      marginTop: '10px',
      marginLeft: '0px !important',
      fill: '#aaa',
    },
  },
}));

interface DistributionSettingsProps {
  checkedLabelsCount: number;
  labelSubsampleCount: number;
  setLabelSubsampleCount: (count: number) => void;
  priorityField: string;
  setPriorityField: (field: string) => void;
  method: 'equal' | 'proportional';
  setMethod: (method: 'equal' | 'proportional') => void;
  filterParams: Record<string, any>;
}

const DistributionSettings: React.FC<DistributionSettingsProps> = props => {
  const { t } = useTranslation();
  const {
    checkedLabelsCount,
    labelSubsampleCount,
    setLabelSubsampleCount,
    method,
    setMethod,
    filterParams,
  } = props;
  const classes = useStyles();

  const filters = ParamUtils.getFiltersFromApiParams(filterParams);

  const methodOptions = [
    {
      value: 'equal',
      label: (
        <span className={classes.methodOption}>
          {t('labels.assignDialog.equal')}
          <br />
          <SvgMethodEven />
        </span>
      ),
    },
    {
      value: 'proportional',
      label: (
        <span className={classes.methodOption}>
          {t('labels.assignDialog.proportional')}
          <br />
          <SvgMethodEquitable />
        </span>
      ),
    },
  ];

  const handleLabelCountInput = (event: React.FormEvent<HTMLInputElement>) => {
    const strValue = (event.target as HTMLInputElement).value;
    if (strValue === '') {
      setLabelSubsampleCount(0);
    } else {
      const value = parseInt(strValue, 10);
      if (!Number.isNaN(value)) {
        setLabelSubsampleCount(Math.max(0, Math.min(value, checkedLabelsCount)));
      }
    }
  };

  return (
    <div className={classes.settings}>
      <Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="label">{t('labels.assignDialog.numberOfLabels')}</Typography>
          <Box width={70} ml="auto">
            <Input value={labelSubsampleCount} onChange={handleLabelCountInput} size="xs" />
          </Box>
        </Box>
        <Slider
          min={0}
          max={checkedLabelsCount}
          value={labelSubsampleCount}
          onChange={setLabelSubsampleCount}
          variant="bigThumb"
        />
      </Box>
      <Box>
        <Typography variant="label">{t('labels.assignDialog.appliedFilters')}</Typography>
        <Box display="flex" flexWrap="wrap" gap="4px" mt={1}>
          {typeof filterParams.idIn !== 'undefined' ? (
            <Chip>{t('labels.assignDialog.selectedLabels')}</Chip>
          ) : !filters.length ? (
            <Chip>{t('labels.allLabels')}</Chip>
          ) : (
            filters.map((filter: any) => (
              <Chip key={filter.filterBy}>{startCase(filter.filterBy)}</Chip>
            ))
          )}
        </Box>
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant="label">{t('labels.assignDialog.distributionMethod')}</Typography>
        </Box>
        <RadioButtons
          columns={2}
          options={methodOptions}
          value={method}
          onChange={val => setMethod(val as any)}
        />
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="12px">
          <Box p={1}>
            <Typography
              variant="body4"
              themedColor={method === 'equal' ? 'primary' : ['grey', 400]}
            >
              {t('labels.assignDialog.equalDescription')}
            </Typography>
          </Box>
          <Box p={1}>
            <Typography
              variant="body4"
              themedColor={method === 'proportional' ? 'primary' : ['grey', 400]}
            >
              {t('labels.assignDialog.proportionalDescription')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default DistributionSettings;
