import React from 'react';

import { RadioButtons } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';

import { CloudSaveMethod, useUploadInfo } from '../../../../../contexts/UploadContext';
import { storageMethodOptions } from './config';
import { UploadStepInfo } from './type';

const SelectStorageMethod = (t: TFunction): UploadStepInfo => {
  const { dataType, cloudSaveMethod, setCloudSaveMethod, videoFramesSourceData } = useUploadInfo();

  const handleChangeFileHandling = (val: string) => {
    setCloudSaveMethod(val as CloudSaveMethod);
  };

  return {
    title: t('data.upload.selectDataStorageMethod'),
    summary:
      cloudSaveMethod === 'copy'
        ? t('data.upload.superbAIStorage')
        : t('data.upload.yourOwnStorage'),
    isButtonEnabled: !!cloudSaveMethod,
    content: (
      <>
        <RadioButtons
          options={storageMethodOptions(dataType, videoFramesSourceData, t)}
          value={cloudSaveMethod}
          onChange={handleChangeFileHandling}
        />
      </>
    ),
  };
};

export default SelectStorageMethod;
