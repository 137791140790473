import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import Layout from '../../../../../components/pages/aiAdvancedFeatures/autoLabel/settings/Layout';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectAutoLabelSettingsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectAutoLabelSettings,
  path: 'settings',
  title: ({ t }) => t('autoLabel.settings.title'),
  component: Layout,
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
