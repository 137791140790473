interface Params {
  tenantUuid: string;
  email: string;
  code: string;
}

export const getUrlByErrorCodeOnSignUp = (errorCode: string, params: Params) => {
  const { code, email, tenantUuid } = params;

  switch (errorCode) {
    case '201412':
    case '200412':
      return `/auth/already_activated?code=${code}`;
    case '201404':
      return `/auth/invalid_user?code=${code}`;
    case '012400':
      return `/auth/expired?tenantId=${tenantUuid}&email=${btoa(email)}&code=${code}`;
    case '011400':
    case '003400':
      return `/auth/code_mismatch?tenantId=${tenantUuid}&email=${btoa(email)}`;
    default:
      return '/auth/something_went_wrong';
  }
};
