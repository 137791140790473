import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { CloudUp } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import Image from 'next/image';

import { getUserManualUrl } from '../../../../consts/DocsLink';
import { getUrl } from '../../../../routes/util';
import { DatasetExploreMenuItem } from './dataset/explore/MenuItem';
import UploadModal from './dataset/modal/UploadModal';
import { SliceListMenuItem } from './dataset/slices/list/MenuItem';
import { SlicesMenuItem } from './dataset/slices/MenuItem';
import emptyImage from './listIsEmpty.png';
import { CurateDatasetsMenuItem } from './MenuItem';

export default function ListIsEmpty({ listType }: { listType: string }) {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { datasetId, accountName, suite } = useParams<{
    datasetId: string;
    accountName: string;
    suite: string;
  }>();
  const { CURATE_SDK_MANUAL, CURATE_SDK_WORKFLOW_MANUAL } = getUserManualUrl(i18n.language);
  const dialog = useDialogState();

  const currentDatasetUrl = getUrl([accountName, suite, CurateDatasetsMenuItem.path, datasetId]);

  const handleClickLink = {
    dataset: () => history.push(getUrl([currentDatasetUrl, DatasetExploreMenuItem.path])),
    sliceList: () =>
      history.push(getUrl([currentDatasetUrl, SlicesMenuItem.path, SliceListMenuItem.path])),
    cloudUpload: () => dialog.show(),
    sdk: () => window.open(CURATE_SDK_WORKFLOW_MANUAL, '_blank', 'noreferrer'),
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Image src={emptyImage} alt="Illustration showing a robot holding the hand of a person" />
      <Typography variant="h2">
        {listType === 'dataset' ? (
          <>{t('curate.datasets.startDataset')}</>
        ) : (
          <>{t('curate.datasets.startSlice')}</>
        )}
      </Typography>
      <Typography my={2} variant="m-medium" color="gray-300" textAlign="center">
        {listType === 'dataset' ? (
          <Trans
            t={t}
            i18nKey={'curate.datasets.uploadGuide'}
            components={{
              link1: (
                <LinkTypography color="secondary" as="a" onClick={handleClickLink['cloudUpload']} />
              ),
              link2: <LinkTypography color="secondary" as="a" onClick={handleClickLink['sdk']} />,
            }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey={'curate.datasets.createSliceGuide'}
            components={{
              link1: (
                <LinkTypography color="secondary" as="a" onClick={handleClickLink['dataset']} />
              ),
              link2: (
                <LinkTypography color="secondary" as="a" onClick={handleClickLink['sliceList']} />
              ),
              link3: (
                <LinkTypography color="secondary" as="a" onClick={handleClickLink['cloudUpload']} />
              ),
              link4: <LinkTypography color="secondary" as="a" onClick={handleClickLink['sdk']} />,
            }}
          />
        )}
      </Typography>
      <Dialog.Disclosure {...dialog}>
        {(disclosureProps: any) => (
          <Button variant="strong-fill" color="primary" {...disclosureProps}>
            <Icon icon={CloudUp} />
            {t('curate.datasets.cloudUpload.button')}
          </Button>
        )}
      </Dialog.Disclosure>
      <UploadModal dialog={dialog} hasSliceStep={listType === 'dataset'} />
      <Box mt={1} />
      <LinkTypography
        variant="s-strong"
        color="primary"
        onClick={() => window.open(CURATE_SDK_MANUAL, '_blank', 'noreferrer')}
      >
        {t('curate.tutorial.learnHowToUseTheSDK')}
      </LinkTypography>
    </Box>
  );
}
