import React, { useEffect } from 'react';

import { css } from '@emotion/core';
import { Box, Button, Icon, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { useApiKeyQuery, useApiKeyRequestMutation } from '../../../../../queries/useApiKeyQuery';
import { useAuthService } from '../../../../../services/NewAuthService';
import FileUtils from '../../../../../utils/FileUtils';
import SyntaxHighlighter from '../../SyntaxHighlighter';
import { UploadStepInfo } from './type';

const AccessKey = (t: TFunction): UploadStepInfo => {
  const authInfo = useAuthInfo();
  const { data: apiKeyInfo } = useApiKeyQuery();
  const { mutate: requestApiKey } = useApiKeyRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { uploadMethod } = useUploadInfo();
  const { getApiKey } = useAuthService();

  const codeString = `$ spb configure\nSuite Team Name : ${authInfo.accountName}\nAccess Key : ${
    apiKeyInfo?.key ?? '<Copy and Paste Access Key>'
  }\n$ spb configure --list`;

  const handleClickRequestApiKey = async () => {
    const resultOfGetApiKey = await requestApiKey();
  };

  const handleClickCopy = () => {
    if (apiKeyInfo) {
      FileUtils.copyToClipboard({ value: apiKeyInfo.key });
      enqueueSnackbar(COPY_SUCCESS({ t, label: 'Access Key' }), { variant: 'success' });
    }
  };

  useEffect(() => {
    if (uploadMethod !== 'CLI') return;
    (async () => {
      const { data } = await getApiKey();

      if (!data) {
        requestApiKey();
      }
    })();
    // eslint-disable-next-line
  }, [uploadMethod]);

  return {
    title: t('integrations.accessKey'),
    isButtonEnabled: true,
    content: (
      <Box>
        <Typography variant="body3" themedColor={['grey', 500]}>
          {t('data.upload.accessKeyDescription')}
        </Typography>
        {apiKeyInfo ? (
          <>
            <Box
              themedBackgroundColor={['grey', 60]}
              px={2}
              py={1}
              mt={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Box mr={1} display="flex" alignItems="center">
                  <Icon name="key" />
                </Box>
                <Typography variant="body4">{apiKeyInfo.key}</Typography>
              </Box>
              <IconButton icon="copy" color="grey" size="xs" onClick={handleClickCopy} />
            </Box>
            <Box
              mt={1}
              sx={css`
                & code {
                  font-size: 12px;
                }
              `}
            >
              <SyntaxHighlighter>{codeString}</SyntaxHighlighter>
            </Box>
          </>
        ) : (
          <Box mt={1}>
            <Button color="grey" onClick={handleClickRequestApiKey}>
              {t('data.upload.generateAccessKey')}
            </Button>
          </Box>
        )}
      </Box>
    ),
  };
};

export default AccessKey;
