import React, { useRef } from 'react';

import { map } from 'lodash';

import { JsonObj } from '../userStats/types';

const GRAY = '#909090';
const textStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
};

const getXLocations = (n: 1 | 2 | 3): Record<string, number[]> => {
  return {
    3: { textX: [12, 117, 222], rectX: [-7.5, 97.5, 202.5] },
    2: { textX: [117, 222], rectX: [97.5, 202.5] },
    1: { textX: [222], rectX: [202.5] },
  }[n];
};

interface LegendProps {
  variables: JsonObj[];
  location: Record<string, number>;
}
/**
 * @param {array} variables List of objects with line names and matching colors
 * @param {object} location Transform object to specify legend location
 *
 * variables ex. {text: 'submitted', color: 'red', opacity: 0.5}
 * location ex. {transformX: [int], transformY: [int]}
 */
const Legend: React.FC<LegendProps> = props => {
  const { variables, location } = props;
  const legend = useRef(null);
  const { textX, rectX } = getXLocations(variables.length as 1 | 2 | 3);
  const rects = map(variables, (d, i) => {
    return (
      <rect
        key={`legend-key-${i}-${d.text}`}
        x={rectX[i]}
        y={-5.2 - 13 / 2}
        width="9px"
        height="9px"
        opacity={d.opacity}
        fill={d.color}
      />
    );
  });

  const texts = map(variables, (d, i) => {
    return (
      <text
        key={`legend-text-${i}-${d.text}`}
        x={textX[i]}
        y={0 - 13 / 2}
        fill={GRAY}
        textAnchor="left"
        alignmentBaseline="middle"
        opacity={d.opacity}
        style={textStyle}
      >
        {d.text}
      </text>
    );
  });

  return (
    <g
      ref={legend}
      className="line-legend"
      transform={`translate(${location.transformX}, ${location.transformY})`}
    >
      {rects}
      {texts}
    </g>
  );
};

export default Legend;
