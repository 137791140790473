import React, { useState } from 'react';

import { StateGetterSetter } from './types';

type ContextProps = StateGetterSetter<['error', 'setError'], any>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [error, setError] = useState(false);

  return {
    error,
    setError,
  };
};

export const useErrorInfo = (): ContextProps => {
  return React.useContext(Context);
};

export const ErrorProvider: React.FC = ({ children }) => {
  const errorInfo = useProvider();
  return <Context.Provider value={errorInfo}>{children}</Context.Provider>;
};
