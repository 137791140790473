import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Exit, Model } from '@superb-ai/icons';
import { Box, Chip, Icon, Typography } from '@superb-ai/ui';
import { lowerCase } from 'lodash';

import { RECOGNITION_MODEL } from '../../../../../../Model/path';
import { DiagnosisDetail } from '../../../../../services/DiagnosisModelService';
import { isSuperbModel } from './diagnosisUnion';

export const DiagnosisModelBadge = ({
  diagnosis,
  size = 'l',
}: {
  diagnosis: DiagnosisDetail;
  size: 'l' | 'm' | 's';
}) => {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const title = t('curate.diagnosis.text.modelSource');
  const text = t(`curate.diagnosis.text.modelSourceTag.${lowerCase(diagnosis?.modelSource)}`);
  const icon = isSuperbModel(diagnosis) ? Model : Exit;
  const modelChip = () => {
    const isDeletedModel = !diagnosis.modelName;
    if (isDeletedModel)
      return <Chip color="gray-100">{t('curate.diagnosis.text.modelSourceTag.deleted')}</Chip>;
    const ModelChip = (
      <Chip color="gray-100">
        {icon && <Icon size={size} color="gray-300" icon={icon} />}
        {text}
      </Chip>
    );
    return isSuperbModel(diagnosis) ? (
      <Link to={`/${accountName}${RECOGNITION_MODEL}/${diagnosis.modelId}`}>{ModelChip}</Link>
    ) : (
      ModelChip
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant={`${size}-regular`} color={'gray-300'} whiteSpace="nowrap">
          {title}
        </Typography>
      </Box>
      {modelChip()}
    </Box>
  );
};
