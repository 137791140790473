import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import ThreadFooterInput from './ThreadFooterInput';
import ThreadFooterOptions from './ThreadFooterOptions';
import { CommentProps, ThreadProps } from './types';

const useStyles = makeStyles({
  threadFooter: {},
});

type Props = Omit<ThreadProps, 'pos'> & Pick<CommentProps, 'indexOfEditingComment'>;

const ThreadFooter: React.FC<Props> = props => {
  const classes = useStyles();
  const { isCreate, issue, indexOfEditingComment } = props;
  const [footerMode, setFooterMode] = useState<'input' | 'option'>(isCreate ? 'input' : 'option');

  useEffect(() => {
    if (indexOfEditingComment === null) return;
    setFooterMode('option');
  }, [indexOfEditingComment]);

  return (
    <MUI.Box className={`${classes.threadFooter}`}>
      {footerMode === 'input' ? (
        <ThreadFooterInput isCreate={isCreate} issue={issue} setFooterMode={setFooterMode} />
      ) : (
        <ThreadFooterOptions
          isCreate={isCreate}
          issue={issue}
          setFooterMode={setFooterMode}
          indexOfEditingComment={indexOfEditingComment}
        />
      )}
    </MUI.Box>
  );
};

export default ThreadFooter;
