import React, { ComponentType } from 'react';
import {
  ExtractRouteParams,
  match,
  RouteComponentProps,
  useParams,
  useRouteMatch,
} from 'react-router';

import { Icon } from '@superb-ai/norwegian-forest';
import { TFunction } from 'i18next';

import { DatasetResult } from '../apps/Curate/services/DatasetService';
import { BreadcrumbItem } from '../contexts/AppContext';
import { AuthContextProps } from '../contexts/AuthContext';
import { FeatureFlags } from '../contexts/FeatureFlagContext';
import { ProjectData } from '../types/projectTypes';
import { Evaluatable } from '../utils/functional';

export interface MenuItemContext {
  authInfo?: AuthContextProps;
  project?: ProjectData;
  featureFlags?: Partial<FeatureFlags>;
  datasetInfo?: DatasetResult;
  t: TFunction;
}

export interface BaseMenuItem<Path extends string = string, C = BaseMenuItem<string, any>> {
  pageId?: string; // unique id used for tracking page view
  path: Path;
  matchParam?: string;
  title?: Evaluatable<string | JSX.Element, [MenuItemContext]>;
  icon?: React.ComponentProps<typeof Icon>['name'] | JSX.Element | ComponentType;
  children?: C[];
  group?: string;
  // Disabled items are in-accessible by routing (but might be shown in menus with a disabled state);
  isEnabled?: Evaluatable<boolean, [MenuItemContext]>;
  // Invisible items are hidden in menus (but accessible by routing).
  isVisible?: Evaluatable<boolean, [MenuItemContext]>;
  isChildrenVisible?: Evaluatable<boolean, [MenuItemContext]>;
  disabledMessage?: Evaluatable<string | JSX.Element | undefined, [MenuItemContext]>;
  // Routes that have lower-level pages that are not part of the menu,
  // in these cases the "active" menu item match should be non-exact.
  hasLowerLevelPage?: Evaluatable<boolean, [MenuItemContext]>;
  breadcrumb?: Evaluatable<BreadcrumbItem[], [MenuItemContext]>;
  exact?: boolean;
  isNew?: boolean;
  redirectTo?: Evaluatable<string | undefined, [MenuItemContext]>;
}

export interface MenuItem<Path extends string = string> extends BaseMenuItem<Path, MenuItem> {
  component: React.ComponentType<AppRouteLayoutProps<string>>;
}

export type MenuItemProp<Path extends string = string> = { menuItem: MenuItem<Path> };

export type AppRouteLayoutProps<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>,
> = MenuItemProp<Path> & RouteComponentProps<Params>;

export function CreateMenuItem<P extends string>(menuItem: MenuItem<P>): MenuItem<P> {
  return menuItem;
}

export function useMenuItemParams<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>,
>(_menuItem: MenuItem<Path>): Params {
  return useParams<Params>();
}

export function useMenuItemMatch<
  Path extends string = string,
  Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>,
>(_menuItem: MenuItem<Path>): match<Params> {
  return useRouteMatch<Params>();
}
