import React from 'react';

import { CustomTooltipProps } from '@elastic/charts';

export default function MakeHeatmapTooltip(data: [any, any, any, string][]) {
  return function CustomTooltip({ values }: CustomTooltipProps) {
    function getHeatmapContent() {
      const x = values.find(({ label }) => label === 'X Value');
      const y = values.find(({ label }) => label === 'Y Value');
      const v = values.find(({ label }) => label === 'HEATMAP');
      const explanation = data[x?.datum.originalIndex][3];
      return (
        <>
          {y?.formattedValue}
          <br />
          {x?.formattedValue}: {v?.formattedValue}
          <br />
          {explanation}
        </>
      );
    }

    return (
      <div
        style={{
          backgroundColor: '#101010D8',
          fontSize: '12px',
          padding: '8px',
          lineHeight: '1.5',
          color: 'white',
          width: '100%',
        }}
      >
        {values.length === 1 ? values[0].formattedValue : getHeatmapContent()}
      </div>
    );
  };
}
