import React from 'react';
import OtpInput from 'react-otp-input';

const inputStyle = {
  width: 32,
  height: 50,
  border: '1px solid #D4D4D4',
  boxSizing: 'border-box',
  borderRadius: 2,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: 30,
  textAlign: 'center',
  color: '#333333',
};

const containerStyle = {
  gap: 7,
};

export default function TotpCodeInput({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange(value: string): void;
}) {
  return (
    // @ts-ignore: saa-680
    <OtpInput
      value={value}
      onChange={onValueChange}
      numInputs={6}
      inputStyle={inputStyle}
      containerStyle={containerStyle}
      isInputNum
      shouldAutoFocus
    />
  );
}
