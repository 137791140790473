import { Box, Typography } from '@superb-ai/ui';

import { FORMAT_COUNT } from '../../config/d3Formatters';

type Row = Record<string, any>;

export function DataCell(row: Row, column: keyof Row): JSX.Element {
  const value = column !== undefined ? row[column] : '';
  return (
    <Box
      display="flex"
      ml={0.5}
      style={{
        fontVariantNumeric: 'tabular-nums',
        justifyContent: 'flex-end',
        fontSize: '12px',
        ...(column === 'Total' && { fontWeight: 600 }),
      }}
    >
      {value === 0 && (
        <Typography color="gray-300" variant="m-strong">
          {'––'}
        </Typography>
      )}
      {Number.isInteger(value) && value !== 0 && (
        <Typography color="black" variant="m-regular">
          {FORMAT_COUNT(value as number)}
        </Typography>
      )}
    </Box>
  );
}
