import React from 'react';

import { Badge, Box, Icon, Typography } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';

import { getIconName } from './modal/components';

interface Props {
  type: string;
  label?: string;
}

export function TypeIconLabel({ type, label = startCase(type) }: Props): JSX.Element {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <Badge color="strawberry" size="xxs">
        <Icon name={getIconName(type)} size="10px" />
      </Badge>
      <Typography variant="body4">{label}</Typography>
    </Box>
  );
}
