import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@superb-ai/ui';

import { ReactComponent as GithubIcon } from '../../../../assets/img/github_icon.svg';
import EmptyCardContent from './SectionContent';

export default function Description() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      width="100%"
      boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
      p={3}
      borderRadius="2px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <EmptyCardContent type="Description" />
        <Button
          variant="stroke"
          color="gray"
          onClick={() => window.open('https://github.com/Superb-AI-Suite/spb-cli')}
        >
          <GithubIcon />
          <Typography variant="m-strong">{t('settings.advanced.suiteCli')}</Typography>
        </Button>
      </Box>
    </Box>
  );
}
