import React, { useMemo } from 'react';

import { Clear } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { IAdvancedQuery, IQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';

export default function QueryOperatorMenu({
  hoverButtonList,
  boxNum,
  index,
  setHoverButtonList,
  list,
  deleteQuery,
  selectedQueryList,
  setSelectedQueryList,
  updateQueryContent,
  isSelected = false,
}: {
  hoverButtonList: string[];
  boxNum: number;
  index: number;
  setHoverButtonList: React.Dispatch<React.SetStateAction<string[]>>;
  list: IAdvancedQuery | IQuery;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
  isSelected?: boolean;
}) {
  const selectedQuery = useMemo(
    () => selectedQueryList.filter(list => list.boxNum === boxNum.toString() + (index + 1))[0],
    [selectedQueryList, boxNum, index],
  );

  return (
    <DropdownMenu
      width={152}
      AnchorElement={
        <Button style={{ padding: 0, border: 'none' }} variant="text">
          {isSelected ? (
            <Box
              style={{
                padding: '5px 8px',
                background: '#FFFFFF',
                boxSizing: 'border-box',
                borderTop: '1px solid #DCDCDC',
                borderBottom: '1px solid #DCDCDC',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <Typography color={'secondary-400'}>
                {`${
                  selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  )[0].queryOperator
                }`}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{
                background: '#FFFFFF',
                border: '1px solid #DCDCDC',
                borderRadius: '2px',
                maxHeight: '32px',
                minWidth: '104px',
                cursor: 'pointer',
              }}
              onMouseEnter={() => {
                if (!hoverButtonList.includes(boxNum.toString() + (index + 1)))
                  setHoverButtonList(() => [...hoverButtonList, boxNum.toString() + (index + 1)]);
              }}
              onMouseLeave={() => {
                const newHoverButtonList = hoverButtonList.filter(
                  list => list !== boxNum.toString() + (index + 1),
                );
                setHoverButtonList(() => newHoverButtonList);
              }}
            >
              <Box
                style={{
                  width: '80px',
                  padding: '5px 8px',
                  background: `${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? '#F5F5F5'
                      : '#FFFFFF'
                  }`,
                  boxSizing: 'border-box',
                  borderRight: `${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? '1px solid #DCDCDC'
                      : 'none'
                  }`,
                }}
              >
                <Typography
                  variant="m-regular"
                  color={`${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? 'gray-400'
                      : 'gray-300'
                  }`}
                >
                  Select...
                </Typography>
              </Box>
              <Box
                mx={0.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => deleteQuery(boxNum, list.boxIndex)}
                style={{ width: '24px', background: '#FFFFFF' }}
              >
                <Icon icon={Clear} color={'primary-400'} size="12px" />
              </Box>
            </Box>
          )}
        </Button>
      }
      placement="bottom-start"
    >
      <div
        style={{
          position: 'absolute',
          top: '-8px',
          background: '#fff',
          width: '100%',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          borderRadius: '2px',
        }}
      >
        {selectedQuery.queryKeyType === 'Categorical' ? (
          <DropdownMenuItem
            onClick={() => {
              const hasQuery = selectedQueryList.filter(
                list => list.boxNum === boxNum.toString() + (index + 1),
              );

              const newQueryList = selectedQueryList.filter(
                list => list.boxNum !== boxNum.toString() + (index + 1),
              );

              updateQueryContent(boxNum, index, {
                ...hasQuery[0],
                queryOperator: '=',
              });
              setSelectedQueryList(() => [
                ...newQueryList,
                {
                  ...hasQuery[0],
                  queryOperator: '=',
                },
              ]);
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="m-regular">=</Typography>
            </Box>
          </DropdownMenuItem>
        ) : (
          <>
            {selectedQuery.queryKeyType === 'String' ? (
              <>
                {['=', '!='].map(operator => (
                  <DropdownMenuItem
                    key={operator}
                    onClick={() => {
                      const hasQuery = selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      );

                      const newQueryList = selectedQueryList.filter(
                        list => list.boxNum !== boxNum.toString() + (index + 1),
                      );
                      updateQueryContent(boxNum, index, {
                        ...hasQuery[0],
                        queryOperator: operator,
                      });
                      setSelectedQueryList(() => [
                        ...newQueryList,
                        {
                          ...hasQuery[0],
                          queryOperator: operator,
                        },
                      ]);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="m-regular">{operator}</Typography>
                    </Box>
                  </DropdownMenuItem>
                ))}
                {['in', 'not in'].map(operator => (
                  <DropdownMenuItem
                    key={operator}
                    onClick={() => {
                      const hasQuery = selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      );

                      const newQueryList = selectedQueryList.filter(
                        list => list.boxNum !== boxNum.toString() + (index + 1),
                      );
                      updateQueryContent(boxNum, index, {
                        ...hasQuery[0],
                        queryOperator: operator,
                      });
                      setSelectedQueryList(() => [
                        ...newQueryList,
                        {
                          ...hasQuery[0],
                          queryOperator: operator,
                        },
                      ]);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="m-regular">{operator}</Typography>
                    </Box>
                  </DropdownMenuItem>
                ))}
                {selectedQuery.queryKey === 'key' &&
                  [
                    // '~=', TODO: make this work
                    'starts with',
                    'ends with',
                    'contains',
                  ].map(operator => (
                    <DropdownMenuItem
                      key={operator}
                      onClick={() => {
                        const hasQuery = selectedQueryList.filter(
                          list => list.boxNum === boxNum.toString() + (index + 1),
                        );

                        const newQueryList = selectedQueryList.filter(
                          list => list.boxNum !== boxNum.toString() + (index + 1),
                        );
                        updateQueryContent(boxNum, index, {
                          ...hasQuery[0],
                          queryOperator: operator,
                        });
                        setSelectedQueryList(() => [
                          ...newQueryList,
                          {
                            ...hasQuery[0],
                            queryOperator: operator,
                          },
                        ]);
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="m-regular">{operator}</Typography>
                      </Box>
                    </DropdownMenuItem>
                  ))}
              </>
            ) : (
              <>
                {['=', '>', '<', '<=', '>='].map(operator => (
                  <DropdownMenuItem
                    key={operator}
                    onClick={() => {
                      const hasQuery = selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      );

                      const newQueryList = selectedQueryList.filter(
                        list => list.boxNum !== boxNum.toString() + (index + 1),
                      );
                      updateQueryContent(boxNum, index, {
                        ...hasQuery[0],
                        queryOperator: operator,
                      });
                      setSelectedQueryList(() => [
                        ...newQueryList,
                        {
                          ...hasQuery[0],
                          queryOperator: operator,
                        },
                      ]);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="m-regular">{operator}</Typography>
                    </Box>
                  </DropdownMenuItem>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </DropdownMenu>
  );
}
