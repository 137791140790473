import { map, orderBy, reduce } from 'lodash';

import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import LabelInterfaceUtils, { LabelInterface } from '../../../../../utils/LabelInterfaceUtils';
import { JsonObj } from '../../userStats/types';

const formatHeaderCell = (columnKeys: string[], displayNameMap: Record<string, string>) => {
  const getCell = (columnKey: string, displayNames: Record<string, string>) => {
    return {
      id: columnKey,
      name: displayNames[columnKey],
      color: '#FAFAFA', // IMPLEMENT by index?
    };
  };
  return columnKeys.map(key => getCell(key, displayNameMap));
};

interface ProjectConfigProps {
  hasCategory: boolean;
  hasObject: boolean;
  hasReview?: boolean;
  isAutoLabelConfigured?: boolean;
  isQAConfigured?: boolean;
  isVideo?: boolean;
}

export function getColumnDisplayInfo({
  hasCategory,
  hasObject,
  hasReview,
  isAutoLabelConfigured,
  isVideo,
}: ProjectConfigProps): string[][] {
  const columns = [
    // [serverName, displayName]
    ['rank', 'analytics.text.rank'],
    ['labelingTime', 'analytics.label.labelingTime'],
    ['assetGroup', 'analytics.asset.assetGroup'],
    ['assetKey', 'analytics.asset.assetKey'],
    ['workAssignee', 'analytics.text.name'],
  ];
  if (isVideo) columns.push(['assetFrameCount', 'analytics.asset.frames']);
  if (hasCategory) columns.push(['categoryCount', 'analytics.text.categoryCount']);
  if (hasObject) columns.push(['objectCount', 'analytics.text.objectCount']);
  if (isVideo) columns.push(['objectAnnotationCount', 'analytics.text.objectAnnotationCount']);
  if (isAutoLabelConfigured)
    columns.push(['autoLabelDifficulty', 'analytics.label.autoLabelDifficulty']);
  if (hasReview) columns.push(['reviewRound', 'analytics.label.reviewRounds']);
  return columns;
}

export function getColumnKeys(columns: string[][]) {
  return map(columns, column => column[0]);
}

export function getHeaderColumnDisplayOrder(columns: string[][]) {
  return map(columns, column => column[1]);
}

export function getColumnDisplayNameMap(columns: string[][]) {
  return reduce(
    columns,
    (agg, column) => {
      const key = column[0];
      const displayName = column[1];
      return { ...agg, [key]: displayName };
    },
    {},
  );
}

const getProjectConfig = (labelInterface: LabelInterface, workapp: WorkApp): ProjectConfigProps => {
  const hasCategory = LabelInterfaceUtils.hasCategory(labelInterface);
  const hasObject = LabelInterfaceUtils.hasObject(labelInterface);
  const isVideo = WorkappUnion.isVideoSiesta(workapp);
  const isAutoLabelConfigured = LabelInterfaceUtils.isAutoLabelConfigured(labelInterface);
  const hasReview = WorkappUnion.isVideoSiesta(workapp) || WorkappUnion.isImageSiesta(workapp);
  return {
    hasCategory,
    hasObject,
    isVideo,
    isAutoLabelConfigured,
    hasReview,
  };
};

export function getHeaderCells(labelInterface: LabelInterface, workapp: WorkApp) {
  const columnDisplayInfo = getColumnDisplayInfo(getProjectConfig(labelInterface, workapp));
  const columnKeys = getColumnKeys(columnDisplayInfo);
  const displayNameMap = getColumnDisplayNameMap(columnDisplayInfo);
  return formatHeaderCell(columnKeys, displayNameMap);
}

export const getColumnDisplayOrder = (labelInterface: LabelInterface, workapp: WorkApp) => {
  const columnDisplayInfo = getColumnDisplayInfo(getProjectConfig(labelInterface, workapp));
  return getColumnKeys(columnDisplayInfo);
};

export const getColumnDisplayNames = (
  labelInterface: LabelInterface,
  workapp: WorkApp,
  t: (arg0: string) => string,
) => {
  const columnDisplayInfo = getColumnDisplayInfo(getProjectConfig(labelInterface, workapp));
  const convertedi18n = map(columnDisplayInfo, column => [column[0], t(column[1])]);
  return Object.values(getColumnDisplayNameMap(convertedi18n));
};

export const downloadName = (projectName: string) => {
  const currentTime = new Date();
  const displayTime = currentTime.toLocaleString().replace(',', '_');
  return `${projectName}_top_labeling_time_${displayTime}`;
};

export const addRank = (data: JsonObj[]) => {
  return data.map((item: JsonObj, i: number) => {
    return { ...item, rank: i + 1 };
  });
};

export const preprocessLabelingTableData = (data: JsonObj[]) => {
  const sorted = orderBy(data, ['labelingTime'], ['desc']);
  return addRank(sorted);
};
