import * as d3 from 'd3';
import { isNull, range, snakeCase } from 'lodash';
import { unparse } from 'papaparse';
// @ts-ignore: module without type declaration
import saveSvgAsPng from 'save-svg-as-png';

import {
  getExcelColumn,
  objectLength,
} from '../components/pages/analytics/userStats/labelerTable/excel';
import { JsonObj } from '../components/pages/analytics/userStats/types';
import { Counter } from './collections';
import ImageUtils from './ImageUtils';
import { concurrent } from './SpbUtils';

export interface FileWithPath extends File {
  readonly path?: string;
}
interface TreeData {
  name: string;
  file?: FileWithPath;
  children: TreeData[];
}

/**
 * Convert a list of upload files to a tree
 * @param files
 */
export function fileListToTree(files: FileWithPath[]): TreeData[] {
  const result: TreeData[] = [];
  const level: Record<string, any> = { result };
  files.forEach(file => {
    let path = file.path || file.name;
    if (path[0] !== '/') path = `/${path}`; // always need absolute paths for tree
    path.split('/').reduce((r, name) => {
      if (!r[name]) {
        // eslint-disable-next-line no-param-reassign
        r[name] = { result: [] };
        const data: TreeData = { name, children: r[name].result };
        if (file.name === name) {
          data.file = file;
        }
        r.result.push(data);
      }
      return r[name];
    }, level);
  });
  return result;
}

/**
 * Search tree for a node given its path.
 * @param tree single start node
 * @param searchPath path elements (e.g. path.split('/'))
 * @param accumPath recursion accumulator, leave empty.
 */
export function searchTree(
  tree: TreeData,
  searchPath: string[],
  accumPath: string[] = [],
): TreeData | null {
  if (accumPath.join('/') === searchPath.join('/')) return tree;
  if (searchPath.length === 0) return tree;
  const searchPathPart = searchPath.shift();
  // eslint-disable-next-line no-restricted-syntax
  for (const child of tree.children) {
    if (searchPathPart === child.name) {
      return searchTree(child, searchPath, [...accumPath, child.name]);
    }
  }
  return null;
}

/**
 * Checks image sizes for all files and raises exceptions
 * 1. if not all sizes are the same
 * 2. if expected width and height are given, if not all sizes match
 * @param files
 * @param expected (optional)
 */
export async function ensureDimensions(
  files: File[],
  expected?: { width: number; height: number },
): Promise<void> {
  const dimensions = await concurrent(files, file => ImageUtils.getImageSizeFromBlob(file), 100);
  const dimensionStrings = dimensions.flatMap(dim => (dim ? `${dim.width}x${dim.height}` : []));
  // Compare with expected (optional)
  if (typeof expected !== 'undefined') {
    const { width, height } = expected;
    const firstMismatch = dimensionStrings.findIndex(dim => dim !== `${width}x${height}`);
    if (firstMismatch !== -1) {
      throw new Error(
        `Images should all have a size of ${width}x${height}, but ${files[firstMismatch].name} is ${dimensionStrings[firstMismatch]}.`,
      );
    }
  }
  // Find mismatched with most common dimension
  const counts = new Counter(dimensionStrings);
  if (counts.size > 1) {
    const countsSorted = counts.sorted();
    const firstMismatch = dimensionStrings.findIndex(dim => dim !== countsSorted[0][0]);
    throw new Error(
      `Images should all have the same dimensions. Most of your images have a size of ${countsSorted[0][0]}, ` +
        `but ${files[firstMismatch].name} is ${dimensionStrings[firstMismatch]}.`,
    );
  }
}

const getConvertedFileSize = (fileSize: number): string => {
  if (fileSize >= 1024 * 1024 * 1024) {
    return Math.round(fileSize / (1024 * 1024 * 1024))
      .toString()
      .concat(' ', 'GB');
  }
  if (fileSize >= 1024 * 1024) {
    return Math.round(fileSize / (1024 * 1024))
      .toString()
      .concat(' ', 'MB');
  }
  if (fileSize >= 1024) {
    return Math.round(fileSize / 1024)
      .toString()
      .concat(' ', 'KB');
  }

  return fileSize > 0 ? fileSize.toString().concat(' ', 'byte') : '0 Byte';
};

export const readUrlJson = (jsonUrl: string) => {
  const response = fetch(jsonUrl);
  return response.then(res => res.json());
};

const getJsonFileSize = (jsonFile: string) => new TextEncoder().encode(jsonFile).length;

const exportToJson = (objectData: Record<string, any>, filename: string): void => {
  const contentType = 'application/json;charset=utf-8;';
  const a = document.createElement('a');
  a.download = filename;
  a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(objectData))}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * @param {array} arrayData Array of objects to convert to csv
 * @param {array} cols Column names to use as headers
 * @param {string} filename without csv extension
 *
 * Danger: assumes that order of keys in objects are consistent
 *
 * ex.
 * [
 *  {'email': 'lt@ru.edu, 'assignee': 'Leo Tolstoy', 'submitted': 10},
 *  {'email': 'ac@fr.edu, 'assignee': 'Albert Camus, 'submitted': 10}
 * ]
 */
const exportToCsv = <T extends string>(
  arrayData: Record<T, any>[],
  cols: T[] | null,
  filename: string,
  topHeader?: string,
): void => {
  const fields = cols || Object.keys(arrayData[0]).map(snakeCase);
  const data = arrayData.map(obj => Object.values(obj));
  let csv = unparse({ fields, data }, { escapeFormulae: true });
  if (topHeader) csv = topHeader + csv;
  const contentType = 'text/csv;charset=utf-8';
  const url = `data:${contentType},${encodeURI(csv)}`;
  downloadViaPath(url, `${filename}.csv`);
};

/**
 * @param {Array} excelData list of data objects
 * @param {String} sheetName Excel sheet name
 *
 * ex. array of arrays [add example here]
 * ex. json: {sheetName1 : [{name: 'Book', count: 10}, {name: 'Pen', count: 33}], ...}
 *
 * Uses sheet.js: https://lovemewithoutall.github.io/it/json-to-excel/
 */
const exportToExcel = (
  excelData: any[],
  dataType: 'json' | 'array',
  sheetName: string,
  filename: string,
  reportInfo?: any,
): void => {
  import('xlsx').then(XLSX => {
    if (dataType === 'array') {
      const workbook = XLSX.utils.book_new();
      workbook.Props = {
        Title: `${filename}.xlsx`,
        CreatedDate: new Date(),
      };
      const numCols = objectLength(excelData[0]);
      if (reportInfo) {
        const sheetInfo = XLSX.utils.aoa_to_sheet(reportInfo);
        setColumnWidths(sheetInfo, numCols, [{ wpx: 100 }, { wpx: 150 }]);
        XLSX.utils.book_append_sheet(workbook, sheetInfo, 'Info');
      }
      const sheetData = XLSX.utils.aoa_to_sheet(excelData);
      setColumnWidths(sheetData, numCols);
      XLSX.utils.book_append_sheet(workbook, sheetData, sheetName);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
      return;
    }
    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(excelData);
    // add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, sheetData, sheetName);
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  });
};

export type SheetFormat = { data: JsonObj[]; sheetName: string };
export type MetadataFormat = {
  data: [string, string | number | Date][];
  sheetName: string;
};

const downloadMultipleExcelSheets = (
  data: SheetFormat[],
  filename: string,
  metadata?: MetadataFormat,
): void => {
  import('xlsx').then(XLSX => {
    const workbook = XLSX.utils.book_new();
    if (metadata) {
      const sheetMeta = XLSX.utils.aoa_to_sheet(metadata?.data);
      setColumnWidths(sheetMeta, 2, [{ wpx: 150 }, { wpx: 250 }]);
      XLSX.utils.book_append_sheet(workbook, sheetMeta, metadata?.sheetName);
    }

    for (const sheetData of data) {
      const sheet = XLSX.utils.json_to_sheet(sheetData?.data);
      if (sheetData?.data[0]) {
        const cols = Object.keys(sheetData?.data[0]).length;
        setColumnWidths(sheet, cols);
        XLSX.utils.book_append_sheet(workbook, sheet, sheetData?.sheetName);
      }
    }
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  });
};

// Excel helper functions
const setColumnWidths = (ws: any, numCols: number, defaultWidths?: Record<string, any>[]) => {
  if (defaultWidths) {
    ws['!cols'] = defaultWidths;
  } else {
    ws['!cols'] = range(numCols).map(_ => {
      return { width: 15 };
    });
  }
  return ws;
};

const formatHeader = (ws: any, numCols: number, displayName: Record<string, string>): any => {
  for (const idx of range(numCols)) {
    const excelCol = `${getExcelColumn(idx)}1`;
    ws[excelCol].v = displayName[ws[excelCol].v];
  }
  return ws;
};

// user report only
const exportTableToExcel = (
  rows: Record<string, number | string>[],
  headerDisplayNames: Record<string, string>,
  reportInfo: (string | number)[][],
  sheetName: string,
  filename: string,
): void => {
  import('xlsx').then(XLSX => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: `${filename}.xlsx`,
      CreatedDate: new Date(),
    };
    wb.SheetNames.push(sheetName);
    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(rows);

    // add worksheets to workbook
    const sheetInfo = XLSX.utils.aoa_to_sheet(reportInfo);
    const numCols = objectLength(rows[0]);
    setColumnWidths(sheetInfo, numCols, [{ wpx: 100 }, { wpx: 150 }]);
    setColumnWidths(sheetData, numCols);

    formatHeader(sheetData, numCols, headerDisplayNames);

    XLSX.utils.book_append_sheet(workbook, sheetInfo, 'Export Info');
    XLSX.utils.book_append_sheet(workbook, sheetData, sheetName);

    XLSX.writeFile(workbook, `${filename}.xlsx`);
    return;
  });
};

/**
 * @param {string} parentId Svg parent DOM's id (chart name for an)
 * @param {string} filename PNG filename
 *
 * Refer components/pages/analytics/Plot.jsx for example function call
 * Note: For Analytics Plots, parentId is same as chartName defined in plotConfig.js
 */
const exportSvgToPng = (parentId: string, filename: string): void => {
  // loading only if this function is called. might cause delay
  const options = {
    excludeUnusedCss: true,
    backgroundColor: 'white',
  };
  const svgNode = d3.select(`#${parentId}`).select('svg').node();
  if (!isNull(svgNode)) {
    saveSvgAsPng.saveSvgAsPng(svgNode, filename, options);
  }
};

const downloadViaPath = (filePath: string, fileName: string): void => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = filePath;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const copyToClipboard = ({ value }: { value: string }): void => {
  navigator.clipboard.writeText(value);
};

const downloadUrl = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default {
  getConvertedFileSize,
  ensureDimensions,
  exportToCsv,
  exportToJson,
  readUrlJson,
  getJsonFileSize,
  exportToExcel,
  exportTableToExcel,
  exportSvgToPng,
  downloadViaPath,
  copyToClipboard,
  downloadUrl,
  downloadMultipleExcelSheets,
};
