import React, { useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useProjectInfo } from '../../../contexts/ProjectContext';
import MentionSelectList from './MentionSelectList';
import MentionTag from './MentionTag';

interface StyleProps {
  inputVisibility: string;
  textEllipsis: boolean;
  highlighterVisibility: string;
}
const useStyles = (props: StyleProps) =>
  makeStyles(theme => ({
    mentionInput: {
      padding: '0',
      fontSize: '11px',
      color: '#635c5c',
      '&__input': {
        visibility: props.inputVisibility,
        border: 'none',
        padding: 0,
        width: '100%',
        color: '#635c5c',
        '&:focus': {
          outline: 'none',
        },
      },
      '&__highlighter': {
        position: 'relative',
        visibility: props.highlighterVisibility,
        color: '#635c5c',
        whiteSpace: props.textEllipsis && 'nowrap !important',
        textOverflow: props.textEllipsis && 'ellipsis !important',
        '&__substring': {
          visibility: props.highlighterVisibility,
          color: '#635c5c',
        },
        '& strong': {
          fontWeight: 500,
          fontStyle: 'italic',
          color: (theme.palette as any).green.main,
          backgroundColor: (theme.palette as any).green.light,
        },
      },
      '&__suggestions': {
        position: 'absolute',
        width: '254px',
        boxShadow: theme.shadows[1],
        borderRadius: '4px',
        padding: '4px 0',
        maxHeight: '194px',
        overflow: 'auto',
        zIndex: 10000,
      },
    },
  }));

type Props = Omit<React.ComponentProps<typeof MentionsInput>, 'children'> & {
  isEdit?: boolean;
  boxProps?: React.ComponentProps<typeof Box>;
  textEllipsis?: boolean;
};

const Mentions: React.FC<Props> = props => {
  const { isEdit = false, boxProps, textEllipsis = false, ...otherProps } = props;
  const projectInfo = useProjectInfo();
  const mentionUserList = projectInfo?.project ? projectInfo.mentionUserOptions : [];
  const boxRef = useRef();

  const classes = useStyles({
    highlighterVisibility: isEdit ? 'hidden !important' : 'visible !important',
    inputVisibility: isEdit ? 'visible !important' : 'hidden !important',
    textEllipsis,
  })();

  const handleDisplay = (id: string, display: string) => {
    if (isEdit) {
      return `@${display}`;
    }
    return <MentionTag id={id} display={display} />;
  };

  const renderSuggestion = (
    entry: any,
    search: any,
    highlightedDisplay: any,
    index: any,
    focused: any,
  ) => {
    return <MentionSelectList entry={entry} focused={focused} />;
  };

  return (
    <Box {...({ ref: boxRef } as any)} {...boxProps}>
      <MentionsInput
        {...otherProps}
        className={`${classes.mentionInput} ${textEllipsis ? 'textEllipsis' : ''} `}
        autoFocus
        suggestionsPortalHost={boxRef.current}
        allowSuggestionsAboveCursor
      >
        <Mention
          trigger="@"
          data={mentionUserList}
          appendSpaceOnAdd
          displayTransform={handleDisplay as any}
          renderSuggestion={renderSuggestion}
        />
      </MentionsInput>
    </Box>
  );
};

export default Mentions;
