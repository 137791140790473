import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ChartBar, Grid2X2Big } from '@superb-ai/icons';
import { Box, Icon, SegmentedControl } from '@superb-ai/ui';
import qs from 'qs';

import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useDiagnosisSchema } from '../../../../../queries/diagnosisModelQueries';
import { HorizontalDivider } from './analytics/elements/HorizontalDivider';
import { DiagnosisViewMode } from './analytics/types';
import EvaluationSummary from './EvaluationSummary';

export function ViewsPanel({
  children,
  additionalInfoOnEvaluationSummarySection,
}: PropsWithChildren<{ additionalInfoOnEvaluationSummarySection?: JSX.Element }>) {
  const history = useHistory();
  const { location: search } = history;
  const { t } = useTranslation();
  const params = new URLSearchParams(search.search);
  const view = (params.get('view') || 'analytics') as DiagnosisViewMode;

  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const { selectedDiagnosis } = useDiagnosisModelContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const metadataQuery = useDiagnosisSchema({
    diagnosisId,
    fromPublicDatasets: showPublicDatasets,
    datasetId,
  });

  const handleChangeView = (value: DiagnosisViewMode) => {
    history.push(
      `${history.location.pathname}?${qs.stringify({
        view: value as string,
      })}`,
    );
  };

  const viewOptions = [
    {
      label: (
        <>
          <Icon icon={ChartBar} />
        </>
      ),
      value: 'analytics',
    },
    {
      label: (
        <>
          <Icon icon={Grid2X2Big} />
        </>
      ),
      value: 'grid',
    },
  ];
  return (
    <Box>
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          // width="100%"
          flexDirection="row"
          gap={1}
        >
          <SegmentedControl
            buttonProps={{ size: 'm', square: true }}
            value={view}
            options={viewOptions}
            onChangeValue={handleChangeView}
          />
        </Box>
        {children}
      </Box>
      <Box mt={1.5}>
        <HorizontalDivider />
      </Box>
      <Box mt={1}>
        {selectedDiagnosis && (
          <EvaluationSummary
            diagnosis={selectedDiagnosis}
            additionalInfo={additionalInfoOnEvaluationSummarySection}
          />
        )}
      </Box>
    </Box>
  );
}
