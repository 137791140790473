import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { InfiniteData, QueryObserverResult } from '@tanstack/react-query';

import { StateGetterSetter } from '../../../contexts/types';
import { DEFAULT_EVALUATION_THRESHOLDS } from '../components/datasets/dataset/modelDiagnosis/const';
import { getActiveOrDeactivatingDiagnosisCount } from '../components/datasets/dataset/modelDiagnosis/diagnosisUnion';
import { useDiagnosisListQuery, useDiagnosisSchema } from '../queries/diagnosisModelQueries';
import { DiagnosisDetail, DiagnosisListResponse } from '../services/DiagnosisModelService';
import { usePublicDatasetContext } from './PublicDatasetContextProvider';

export type JobInitiatedTimestamp = {
  jobType: 'UPDATE_DIAGNOSIS' | 'START_DIAGNOSIS';
  timeStamp: number;
};

type ContextProps = StateGetterSetter<
  ['selectedDiagnosis', 'setSelectedDiagnosis'],
  DiagnosisDetail | undefined
> &
  // StateGetterSetter<['diagnosisList', 'setDiagnosisList'], DiagnosisDetail[] | undefined> &
  StateGetterSetter<['comparedDiagnosis', 'setComparedDiagnosis'], DiagnosisDetail | undefined> &
  StateGetterSetter<['quotaCount', 'setQuotaCount'], number> &
  StateGetterSetter<
    ['jobInitiatedTimestamp', 'setJobInitiatedTimestamp'],
    JobInitiatedTimestamp | undefined
  > & {
    diagnosisList?: DiagnosisDetail[];
    diagnosisCount: number;
    refetchDiagnosisList: () => Promise<QueryObserverResult<InfiniteData<DiagnosisListResponse>>>;
    isLoadingDiagnosisList: boolean;
    targetIou: number;
  };
const Context = React.createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{
    datasetId: string;
  }>();
  const {
    data: diagnosisListResult,
    isLoading: isLoadingDiagnosisList,
    refetch: refetchDiagnosisList,
    fetchNextPage: fetchNextDiagnoses,
  } = useDiagnosisListQuery({
    datasetId,
    expand: ['predictions_check'],
  });

  const diagnosisAllList = diagnosisListResult?.pages.flatMap(page => page.results);
  const diagnosisCount = diagnosisListResult?.pages[0].count || 0;
  const activeOrDeactivatingDiagnosisCount = getActiveOrDeactivatingDiagnosisCount(
    diagnosisAllList || [],
  );
  const { showPublicDatasets } = usePublicDatasetContext();
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<DiagnosisDetail | undefined>();
  const [comparedDiagnosis, setComparedDiagnosis] = useState<DiagnosisDetail | undefined>(
    undefined,
  );
  const [quotaCount, setQuotaCount] = useState(0);

  const metadataQuery = useDiagnosisSchema({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    diagnosisId: selectedDiagnosis?.id || '',
  });

  useEffect(() => {
    if (!diagnosisAllList) return;
    if (diagnosisAllList.length < diagnosisCount) {
      fetchNextDiagnoses();
    }
  }, [diagnosisAllList, diagnosisCount]);

  useEffect(() => {
    setQuotaCount(activeOrDeactivatingDiagnosisCount);
  }, [activeOrDeactivatingDiagnosisCount]);

  // Temp job timestamp to compare with the latest job created time
  const [jobInitiatedTimestamp, setJobInitiatedTimestamp] = useState<JobInitiatedTimestamp>();

  return {
    diagnosisList: diagnosisAllList,
    // setDiagnosisList,
    quotaCount,
    setQuotaCount,
    diagnosisCount,
    selectedDiagnosis,
    setSelectedDiagnosis,
    comparedDiagnosis,
    setComparedDiagnosis,
    isLoadingDiagnosisList,
    refetchDiagnosisList,
    jobInitiatedTimestamp,
    setJobInitiatedTimestamp,
    targetIou: metadataQuery.data?.targetIou ?? DEFAULT_EVALUATION_THRESHOLDS.iouThreshold,
  };
};

export const useDiagnosisModelContext = (): ContextProps => {
  return React.useContext(Context);
};

export const DiagnosisModelProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
