import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ChevronDown, Data, ImageAlt, Lidar, PlayCircleOutline } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import UploadLayout from '../../../components/elements/newUpload/Layout';
import SampleProjectModal from '../../../components/pages/projects/SampleProjectModal';
import { useAssetsInfo } from '../../../contexts/AssetsContext';
import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import EventTracker from '../../../analyticsTracker';
import { getUrl } from '../../../routes/util';
import TierUnion from '../../../union/TierUnion';
import UploadDataImage from './assets/uploading-files-from-the-computer.png';
import CreateProjectImage from './assets/working-on-business-project.png';

export default function EmptyProjectOrDataset() {
  const { t } = useTranslation();
  const history = useHistory();
  const routeInfo = useRouteInfo();
  const uploadInfo = useUploadInfo();
  const authInfo = useAuthInfo();
  const { assets, updateAssets } = useAssetsInfo();

  const isCollaborator = !isOwnerOrAdminOrSystemAdmin(authInfo);
  const isEnterprise = TierUnion.isEnterprise(authInfo.tier);

  const [isSampleProjectModalOpen, setIsSampleProjectModalOpen] = useState(false);
  const isHasAsset = (assets?.length ?? 0) > 0;
  const accountId = authInfo.accountName ?? '';

  const handleClickNewImageProject = () => {
    history.push({
      pathname: `/${routeInfo.urlMatchInfo.accountName}/label/new_project/create_project`,
      state: { prevPath: history.location.pathname },
      search: '?data_type=image',
    });
    EventTracker.createProjectButtonClicked({ dataType: 'image', accountId: accountId });
  };

  const handleClickNewVideoProject = () => {
    history.push({
      pathname: `/${routeInfo.urlMatchInfo.accountName}/label/new_project/create_project`,
      state: { prevPath: history.location.pathname },
      search: '?data_type=video',
    });
    EventTracker.createProjectButtonClicked({ dataType: 'video', accountId: accountId });
  };

  const handleClickNewLidarProject = () => {
    history.push({
      pathname: `/${routeInfo.urlMatchInfo.accountName}/label/new_project/create_project`,
      state: { prevPath: history.location.pathname },
      search: '?data_type=lidar',
    });
    EventTracker.createProjectButtonClicked({ dataType: 'lidar', accountId: accountId });
  };

  const handleClickUpload = () => {
    uploadInfo.setIsUploadModalOpen(true);
    EventTracker.labelUploadDialogOpened({ referrer: 'label-empty-project', accountId: accountId });
  };

  const handleClickSampleProject = () => {
    setIsSampleProjectModalOpen(true);
  };

  useEffect(() => {
    updateAssets();
  }, []);

  return (
    <Box
      width="100%"
      mt={8}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography textAlign="center" variant="h2" style={{ whiteSpace: 'pre-wrap' }}>
        {isHasAsset ? (
          <Trans
            i18nKey="projects.emptyTitleWithData"
            components={{ highlight: <Typography color="primary" /> }}
          />
        ) : (
          <Trans
            i18nKey="projects.emptyTitle"
            components={{ highlight: <Typography color="primary" /> }}
          />
        )}
      </Typography>
      {!isHasAsset && (
        <Typography mt={1.5} textAlign="center" variant="l-regular">
          {t('projects.emptyDescription')}
        </Typography>
      )}
      <Box mt={5}>
        {isCollaborator ? (
          <Box mt={6} display="flex" justifyContent="center">
            <img src="/static/image/clip-hardworking-man.png" width="288px" alt="empty image" />
          </Box>
        ) : (
          <Box display="flex" gap={5}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRadius="8px"
              boxShadow="4px 6px 14px rgba(0, 0, 0, 0.14)"
              py={6}
              gap={3}
              style={{ width: '504px' }}
            >
              <Typography variant="h2">{t('projects.createProjectTitle')}</Typography>
              <img width="260px" src={CreateProjectImage.src} />
              <Box display="flex" gap={1.5}>
                <Button
                  size="l"
                  color="primary"
                  variant="stroke"
                  onClick={handleClickSampleProject}
                >
                  {t('projects.trySampleProject')}
                </Button>
                <DropdownMenu
                  width={187}
                  offset={0}
                  placement="bottom-start"
                  AnchorElement={
                    <Button size="l" variant="strong-fill" color="primary">
                      {t('projects.createNewProject')} <Icon icon={ChevronDown} />
                    </Button>
                  }
                >
                  <DropdownMenuItem onClick={handleClickNewImageProject}>
                    <Box display="flex" alignItems="flex-start" gap={0.5}>
                      <Icon size={16} icon={ImageAlt} />
                      <Typography variant="m-regular">Image</Typography>
                    </Box>
                  </DropdownMenuItem>
                  {isEnterprise && (
                    <>
                      <DropdownMenuItem onClick={handleClickNewVideoProject}>
                        <Box display="flex" alignItems="flex-start" gap={0.5}>
                          <Icon size={16} icon={PlayCircleOutline} />
                          <Typography variant="m-regular">Video</Typography>
                        </Box>
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleClickNewLidarProject}>
                        <Box display="flex" alignItems="flex-start" gap={0.5}>
                          <Icon size={16} icon={Lidar} />
                          <Typography variant="m-regular">Point Cloud Sequence</Typography>
                        </Box>
                      </DropdownMenuItem>
                    </>
                  )}
                </DropdownMenu>
              </Box>
            </Box>
            {!isHasAsset && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                borderRadius="8px"
                boxShadow="4px 6px 14px rgba(0, 0, 0, 0.14)"
                py={6}
                style={{ width: '504px' }}
                gap={3}
              >
                <Typography variant="h2">{t('data.button.uploadData')}</Typography>
                <img width="290px" src={UploadDataImage.src} />
                <Button
                  size="l"
                  variant="strong-fill"
                  color="primary"
                  style={{ width: '124px' }}
                  onClick={handleClickUpload}
                >
                  {t('data.button.uploadData')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {isSampleProjectModalOpen && (
        <SampleProjectModal
          isOpen={isSampleProjectModalOpen}
          onClose={() => setIsSampleProjectModalOpen(false)}
        />
      )}
      {isHasAsset && (
        <Box position="absolute" style={{ top: '32px', right: '32px' }}>
          <Button
            onClick={() =>
              routeInfo.history.push(getUrl([routeInfo.urlMatchInfo.accountName, 'label', 'data']))
            }
            color="primary"
            size="l"
          >
            <Icon icon={Data} /> {t('projects.button.manageData')}
          </Button>
        </Box>
      )}
      <UploadLayout />
    </Box>
  );
}
