import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon, Typography } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';
import { map } from 'lodash';

import Emoji from '../../../consts/EmojiConst';
import TodoText from './TodoText';
import { TodoType } from './types';

const useStyle = makeStyles(theme => ({
  paperSmall: {
    '&.MuiPaper-root': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  list: {
    marginTop: theme.spacing(1.5),
    '& .MuiListItemIcon-root': {
      minWidth: '25px',
    },
    '& .MuiListItem-root': {
      fontSize: '12px',
      width: 'calc(100% + 50px)',
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
}));

type Props = {
  className: string;
  todos: TodoType[];
  setIsDataAssignDialogOpen: Dispatch<SetStateAction<boolean>>;
};
const Todo: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = MUI.useTheme();
  const {
    palette: { grey, secondary },
  } = theme;
  const { className, todos, setIsDataAssignDialogOpen } = props;

  return (
    <MUI.Paper className={classnames(classes.paperSmall, className)}>
      <Typography variant="headline4">
        {Emoji.SPEECH_BALLOON} {t('overview.todo.title')}
      </Typography>
      <MUI.List className={classes.list}>
        {map(todos, todo => (
          <MUI.ListItem key={todo.seqNo} divider>
            <MUI.ListItemIcon>
              <Icon
                name="check"
                size="15px"
                customColor={todo.checked ? secondary.main : grey[300]}
              />
            </MUI.ListItemIcon>
            <MUI.ListItemText>
              <TodoText
                i18nKey={`overview.todo.items.item${todo.seqNo}`}
                text={todo.text}
                checked={todo.checked}
                onAssignExistingData={() => setIsDataAssignDialogOpen(true)}
              />
            </MUI.ListItemText>
          </MUI.ListItem>
        ))}
      </MUI.List>
    </MUI.Paper>
  );
};

export default Todo;
