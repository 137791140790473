import React, { createContext, useContext, useEffect, useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';
import { ClusterLevel } from '../components/datasets/dataset/filter/types';

export type ContextProps = {} & StateGetterSetter<
  ['clusterColorMap', 'setClusterColorMap'],
  Record<string, string> | undefined
> &
  StateGetterSetter<['clusterLevel', 'setClusterLevel'], ClusterLevel | undefined> &
  StateGetterSetter<['hoveredFilterGroup', 'setHoveredFilterGroup'], string | null> &
  StateGetterSetter<['hoveredDotGroup', 'setHoveredDotGroup'], string | null>;

const Context = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const [clusterColorMap, setClusterColorMap] = useState<Record<string, string>>(); // key: clusterId, value: color
  const [clusterLevel, setClusterLevel] = useState<ClusterLevel>();
  const [hoveredFilterGroup, setHoveredFilterGroup] = useState<string | null>(null);
  const [hoveredDotGroup, setHoveredDotGroup] = useState<string | null>(null);

  useEffect(() => {
    setHoveredFilterGroup(null);
  }, [clusterLevel]);

  return {
    clusterColorMap,
    setClusterColorMap,
    clusterLevel,
    setClusterLevel,
    hoveredFilterGroup,
    setHoveredFilterGroup,
    hoveredDotGroup,
    setHoveredDotGroup,
  };
};

export const useObjectClusterContext = (): ContextProps => {
  return useContext(Context);
};

export const ObjectClusterProvider: React.FC = ({ children }) => {
  const filterInfo = useProvider();
  return <Context.Provider value={filterInfo}>{children}</Context.Provider>;
};
