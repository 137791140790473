import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Clear, Copy } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  IconButton,
  Input,
  LinkTypography,
  PasswordInput,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import Link from 'next/link';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import { S3IntegrationResType } from '../../../../../types/integrationTypes';
import FileUtils from '../../../../../utils/FileUtils';
import { generatePolicy } from '../utils/s3-policy';

interface Props {
  prefix: string;
  bucket: string;
  setParams: (params: Record<string, any> | null) => void;
  s3delegateInfo?: S3IntegrationResType;
}

export default function EditAuthenticationS3({ bucket, prefix, setParams, s3delegateInfo }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [accessKey, setAccessKey] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');

  const delegateIntegrationInfo = useMemo(() => {
    if (!s3delegateInfo || !s3delegateInfo.info.isDelegate) return undefined;
    return s3delegateInfo;
  }, [s3delegateInfo]);

  const externalId = useMemo(() => {
    return delegateIntegrationInfo?.auth.externalId;
  }, [delegateIntegrationInfo?.auth.externalId]);

  const [awsRoleName, setAwsRoleName] = useState(delegateIntegrationInfo?.auth.roleName);

  const policy = useMemo(() => {
    const formattedPrefix = (() => {
      const trimmedPrefix = (prefix ?? '').trim();
      if (trimmedPrefix !== '' && trimmedPrefix[trimmedPrefix.length - 1] !== '/')
        return `${trimmedPrefix}/`;
      if (trimmedPrefix[0] === '/') return trimmedPrefix.substr(1);
      return trimmedPrefix;
    })();

    return generatePolicy({ bucketName: bucket, bucketPrefix: formattedPrefix });
  }, [bucket, prefix]);
  const policyDialogState = useDialogState();

  const onClickCopyAndClose = () => {
    FileUtils.copyToClipboard({ value: policy });
    policyDialogState.hide();
  };

  useEffect(() => {
    if (!delegateIntegrationInfo) {
      if (!accessKey || !secretAccessKey) {
        setParams(null);
      } else {
        setParams({ accessKey, secretAccessKey });
      }
    } else {
      setParams({
        bucketAccountId: delegateIntegrationInfo.auth.bucketAccountId,
        externalId: delegateIntegrationInfo.auth.externalId,
        roleName: awsRoleName,
      });
    }
  }, [accessKey, delegateIntegrationInfo, secretAccessKey]);

  // access key
  if (!delegateIntegrationInfo) {
    return (
      <>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="m-strong">{t('integrations.howToIAMSettings')}</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            backgroundColor="secondary-100"
          >
            <Box as="ol" style={{ listStyleType: 'decimal', listStylePosition: 'inside' }}>
              <li>
                <Typography variant="m-regular">
                  <Trans
                    t={t}
                    i18nKey="integrations.add.s3.IAMPolicyDescription.1"
                    components={{
                      LinkTypography: (
                        <LinkTypography
                          as="a"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://console.aws.amazon.com/iam/home"
                        />
                      ),
                    }}
                  />
                </Typography>
              </li>
              <li>
                <Typography variant="m-regular">
                  {t('integrations.add.s3.IAMPolicyDescription.2')}
                </Typography>
              </li>
              <li>
                <Typography variant="m-regular">
                  {t('integrations.add.s3.IAMPolicyDescription.3')}
                </Typography>
              </li>
              <li>
                <Typography variant="m-regular">
                  {t('integrations.add.s3.IAMPolicyDescription.4')}
                </Typography>
              </li>
            </Box>
            <Box mr={2}>
              <Button variant="strong-fill" color="secondary" onClick={policyDialogState.show}>
                {t('integrations.generateIAMPolicy')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="grid" gap={2} style={{ gridTemplateColumns: '1fr 1fr' }}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="m-strong">{t('integrations.accessKey')}</Typography>
            <Input value={accessKey} onChange={e => setAccessKey(e.target.value)} />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="m-strong">{t('integrations.secretAccessKey')}</Typography>
            <PasswordInput
              value={secretAccessKey}
              onChange={e => setSecretAccessKey(e.target.value)}
            />
          </Box>
        </Box>
        {policyDialogState.visible && (
          <Dialog state={policyDialogState}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Dialog.Title>{t('integrations.s3.generatePolicy')}</Dialog.Title>
                <IconButton icon={Clear} variant="text" onClick={policyDialogState.hide} />
              </Box>
              <Typography variant="m-regular">
                {t('integrations.s3.generatePolicyDescription')}
              </Typography>
              <Box
                mt={3}
                p={2}
                backgroundColor="gray-100"
                overflow="auto"
                style={{ width: '456px', height: '256px' }}
              >
                <Typography as="pre" variant="m-regular">
                  {policy}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="strong-fill" color="primary" onClick={onClickCopyAndClose}>
                  {t('integrations.s3.copyAndClose')}
                </Button>
              </Box>
            </Box>
          </Dialog>
        )}
      </>
    );
  }

  // delegation
  return (
    <>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="m-strong">{t('integrations.howToIAMSettings')}</Typography>
        <Box
          display="flex"
          flexDirection="column"
          px={1.5}
          py={2}
          backgroundColor="gray-100"
          borderRadius="2px"
        >
          <Typography variant="m-regular">
            1.{' '}
            <Trans
              i18nKey="integrations.add.s3.IAMDelegateDescription.1"
              components={{
                Link: (
                  <Link
                    href="https://console.aws.amazon.com/iam/home#/policies"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      color: '#5A7BFF',
                      textDecoration: 'underline',
                    }}
                  />
                ),
                bold: <span style={{ fontWeight: 'bold' }} />,
              }}
            />
          </Typography>

          <Box mb={2.5} pl={1.5} py={0.5}>
            <Button variant="stroke" color="gray" onClick={policyDialogState.show}>
              {t('integrations.add.s3.generateIAMPolicy')}
            </Button>
          </Box>

          <Typography variant="m-regular">
            2.{' '}
            <Trans
              i18nKey="integrations.add.s3.IAMDelegateDescription.2"
              components={{
                Link: (
                  <Link
                    href="https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/roles/create"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      color: '#5A7BFF',
                      textDecoration: 'underline',
                    }}
                  />
                ),
              }}
            />
          </Typography>

          <Box display="flex" flexDirection="column" pl={2} pb={2.5}>
            <Typography variant="m-regular">
              •{' '}
              <Trans
                i18nKey="integrations.add.s3.IAMDelegateDescription.1-1"
                components={{
                  bold: <span style={{ fontWeight: 'bold' }} />,
                }}
              />
            </Typography>
            <Typography variant="m-regular">
              •{' '}
              <Trans
                i18nKey="integrations.add.s3.IAMDelegateDescription.1-3"
                components={{
                  bold: <span style={{ fontWeight: 'bold' }} />,
                }}
              />
            </Typography>
            <Box display="flex" gap={1} pl={1.5} py={0.5}>
              <Input disabled value={externalId} style={{ cursor: 'text' }} />
              <Button
                variant="stroke"
                color="gray"
                onClick={() => {
                  if (externalId) {
                    FileUtils.copyToClipboard({ value: externalId });
                    enqueueSnackbar(COPY_SUCCESS({ t, label: externalId }), {
                      variant: 'success',
                    });
                  }
                }}
              >
                <Icon icon={Copy} width="24px" /> {t('integrations.add.s3.copy')}
              </Button>
            </Box>
            <Typography variant="m-regular">
              •{' '}
              <Trans
                i18nKey="integrations.add.s3.IAMDelegateDescription.1-4"
                components={{
                  bold: <span style={{ fontWeight: 'bold' }} />,
                }}
              />
            </Typography>
          </Box>

          <Typography variant="m-regular">
            3. {t('integrations.add.s3.IAMDelegateDescription.3')}{' '}
          </Typography>

          <Typography variant="m-regular">
            4.{' '}
            <Trans
              i18nKey="integrations.add.s3.IAMDelegateDescription.4-edit"
              components={{
                bold: <span style={{ fontWeight: 'bold' }} />,
              }}
            />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          <Typography variant="m-strong">
            {t('integrations.add.s3.IAMDelegateDescription.awsRoleName_label')}
          </Typography>
          <Input
            value={awsRoleName}
            placeholder={t('integrations.add.s3.IAMDelegateDescription.awsRoleName_label')}
            onChange={e => setAwsRoleName(e.target.value)}
          />
        </Box>
      </Box>
      {policyDialogState.visible && (
        <Dialog state={policyDialogState}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Dialog.Title>{t('integrations.s3.generatePolicy')}</Dialog.Title>
              <IconButton icon={Clear} variant="text" onClick={policyDialogState.hide} />
            </Box>
            <Typography variant="m-regular">
              {t('integrations.s3.generatePolicyDescription')}
            </Typography>
            <Box
              mt={3}
              p={2}
              backgroundColor="gray-100"
              overflow="auto"
              style={{ width: '456px', height: '256px' }}
            >
              <Typography as="pre" variant="m-regular">
                {policy}
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button variant="strong-fill" color="primary" onClick={onClickCopyAndClose}>
                {t('integrations.s3.copyAndClose')}
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
}
