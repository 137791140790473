import { TFunction } from 'next-i18next';

import SelectCloudPrefix from '../../../../../../../components/elements/newUpload/stepper/steps/SelectCloudPrefix';
import SelectCloudStorage from '../../../../../../../components/elements/newUpload/stepper/steps/SelectCloudStorage';
import { useUploadInfo } from '../../../../../contexts/UploadContext';
import SelectDataTypeStep from './steps/SelectDataTypeStep';
import SelectSliceStep from './steps/SelectSliceStep';

const CloudImageUpload = (
  t: TFunction,
  language: string,
  datasetId?: string,
  hasSliceStep?: boolean,
) => {
  const CLOUD_STORAGE = 'cloud_storage';
  const FOLDER = 'folder';
  const SLICE = 'slice';
  const DATA_TYPE = 'data_type';
  const {
    cloudStorageType,
    setCloudStorageType,
    cloudIntegrationId,
    setCloudIntegrationId,
    cloudBucketName,
    setCloudBucketName,
    integratedCloudPrefix,
    setIntegratedCloudPrefix,
    cloudPrefix,
    setCloudPrefix,
    selectedSlice,
    setSelectedSlice,
    uploadTarget,
    setUploadTarget,
  } = useUploadInfo();

  return {
    [DATA_TYPE]: SelectDataTypeStep({
      t,
      language,
      cloudInfos: {
        uploadTarget,
        setUploadTarget,
      },
    }),
    [CLOUD_STORAGE]: SelectCloudStorage({
      t,
      isEnabled: true,
      cloudInfos: {
        cloudStorageType,
        setCloudStorageType,
        cloudIntegrationId,
        setCloudIntegrationId,
        cloudBucketName,
        setCloudBucketName,
        setIntegratedCloudPrefix,
      },
    }),
    [FOLDER]: SelectCloudPrefix({
      t,
      cloudInfos: {
        cloudBucketName,
        integratedCloudPrefix,
        cloudIntegrationId,
        cloudStorageType,
        cloudPrefix,
        setCloudPrefix,
      },
    }),
    ...(hasSliceStep &&
      uploadTarget.includes('image') && {
        [SLICE]: SelectSliceStep({
          t,
          datasetId,
          cloudInfos: { selectedSlice, setSelectedSlice },
        }),
      }),
  };
};

export default CloudImageUpload;
