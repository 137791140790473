import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid2X1Big, Grid2X2Big, Search } from '@superb-ai/icons';
import { Box, Icon, Input, SegmentedControl, Typography } from '@superb-ai/ui';

import { DEFAULT_DASHBOARD } from '../../../services/CustomAnalyticsService';
import { useEditDashboardMutation, useGetDashboardQuery } from './customAnalytics/customQueries';
import { ChartCard } from './customAnalytics/elements/ChartCard';
import NoChartsView from './customAnalytics/elements/NoChartsView';
import { ViewType } from './customAnalytics/type';
import { orderCharts } from './customAnalytics/utils';
import styles from './styles/AnalyticsCustomizeTab.module.scss';

// const getDashboardName = () => 'My Dashboard';

export const ButtonSize = 'm';
const AnalyticsCustomizeTab: FC = () => {
  const { t } = useTranslation();
  const { data: dashboard, isFetching } = useGetDashboardQuery({
    dashboardId: DEFAULT_DASHBOARD,
  });
  const [viewType, setViewType] = useState<ViewType>(dashboard?.setting?.viewType ?? 'full');
  const editDashboard = useEditDashboardMutation({ dashboardId: DEFAULT_DASHBOARD });

  const viewOptions = [
    { label: <Icon icon={Grid2X1Big} />, value: 'full' as ViewType },
    { label: <Icon icon={Grid2X2Big} />, value: 'half' as ViewType },
  ];

  const handleSearchInput = (searched: string) => {
    // TODO: implement search. reference SelectMembers.tsx
  };

  const changeViewType = (value: ViewType) => {
    setViewType(value);
    editDashboard.mutate({ viewType: value });
  };

  const SearchBar = (
    <Typography variant="l-regular" style={{ lineHeight: 1 }}>
      <Input
        style={{ width: '230px' }}
        prefix={<Icon icon={Search} />}
        placeholder={t('analytics.customizeAnalytics.action.search')}
        disabled // TODO: remove after impelementation
        onChange={e => handleSearchInput(e.target.value)}
      />
    </Typography>
  );

  const ViewTypeSelector = (
    <Box ml="auto" gap={3}>
      <Typography variant="m-regular" mr={1}>
        {t('analytics.customizeAnalytics.text.viewType')}
      </Typography>
      <SegmentedControl
        options={viewOptions}
        value={viewType}
        onChangeValue={changeViewType}
        buttonProps={{
          color: 'gray',
          size: ButtonSize,
          square: true,
        }}
      />
    </Box>
  );
  const hasNoCharts =
    typeof dashboard === 'undefined' || (dashboard && dashboard?.charts?.length === 0);
  const sortedCharts = orderCharts(dashboard?.charts || []);

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        {SearchBar}
        {ViewTypeSelector}
      </Box>
      <Box className={styles.container}>
        {isFetching || hasNoCharts ? (
          <NoChartsView style={{ marginTop: '20%' }} />
        ) : (
          sortedCharts?.map(chart => (
            <ChartCard
              key={chart.chartId + chart.name}
              style={{
                width: '100%',
                flexBasis: `calc(${viewType === 'full' ? '100' : '50'}% - 5px)`,
                flexShrink: 2,
                boxSizing: 'border-box',
                marginTop: '5px',
                marginBottom: '5px',
              }}
              chartId={chart?.chartId as string}
              chartTitle={chart.name}
              chartData={chart}
            />
          ))
        )}
      </Box>
    </>
  );
};

export default AnalyticsCustomizeTab;
