import { ComponentProps, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, Input, Label, RadioGroup, Typography } from '@superb-ai/ui';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  CustomizeDataOptions,
  getDefaultChartType,
  getDefaultGroupBy,
  getGroupByOptions,
} from '../../../../../components/pages/analytics/customAnalytics/chartSpecs';
import { dialogColors } from '../../../../../components/pages/analytics/customAnalytics/color';
import {
  useAddChartMutation,
  useGetChartDataQuery,
} from '../../../../../components/pages/analytics/customAnalytics/customQueries';
import {
  groupByDisplayName,
  GroupByField,
} from '../../../../../components/pages/analytics/customAnalytics/fields';
import {
  ChartStorage,
  DataEnum,
  DataSpec,
  FilterObject,
} from '../../../../../components/pages/analytics/customAnalytics/type';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { DEFAULT_DASHBOARD } from '../../../../../services/CustomAnalyticsService';
import FilterUtils from '../../../../../utils/FilterUtils';
import LabelInterfaceUtils from '../../../../../utils/LabelInterfaceUtils';
import { formatNumber } from '../../../../../utils/numberFormat';
import { PreviewChartArea } from './PreviewChartArea';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  checkedLabels: any[];
  setCheckedLabels: Dispatch<SetStateAction<any[]>>;
  totalCount: number;
  isAllLabelsChecked: boolean;
  filterApiParams?: FilterObject;
};

const getDefaultChartName = (dataName: string, groupBy: GroupByField) =>
  `${dataName} ${groupByDisplayName(groupBy)}`;

export function DialogContent({
  state,
  checkedLabels,
  totalCount,
  isAllLabelsChecked,
  filterApiParams,
}: Props) {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();
  const {
    project: { labelInterface, workapp },
  } = projectInfo;
  const projectConfig = LabelInterfaceUtils.getProjectConfig(workapp, labelInterface);
  const { enqueueSnackbar } = useSnackbar();

  const [dataSpec, setDataSpec] = useState<DataSpec>(DataEnum.labelCount);
  const [groupBy, setGroupBy] = useState<GroupByField>(getDefaultGroupBy(dataSpec));
  const DataOptions = CustomizeDataOptions(t, projectConfig);
  const [chartName, setChartName] = useState<string>(getDefaultChartName('Label Counts', groupBy));

  useEffect(() => {
    const dataDisplayName = DataOptions.find(d => d.value === dataSpec)?.label ?? '';
    setChartName(getDefaultChartName(dataDisplayName, groupBy));
  }, [dataSpec, groupBy]);

  const hasFilters = !isEmpty(filterApiParams);
  const hasCheckedLabels = !isEmpty(checkedLabels) && !isAllLabelsChecked;
  const filterObject = FilterUtils.getFilterObject(
    filterApiParams,
    checkedLabels,
    isAllLabelsChecked,
  );
  const checkedLabelCount = checkedLabels.length || totalCount;

  const dependencies = {
    dataSpec,
    groupBy,
    ...((hasFilters || hasCheckedLabels) && { filters: filterObject }),
  };
  const query = useGetChartDataQuery(dependencies);
  const data = query.data;
  const isFetching = query?.isFetching;

  const chartData: ChartStorage = {
    name: chartName,
    data: dataSpec,
    groupBy,
    filters: filterObject,
    chartType: getDefaultChartType(dataSpec, groupBy),
    totalLabelCount: data?.totalLabelCount,
    ...(dataSpec === DataEnum.objectCount && {
      totalAnnotationCount: data?.totalAnnotationCount,
    }),
  };
  const addChart = useAddChartMutation({ dashboardId: DEFAULT_DASHBOARD, chartData });

  useEffect(() => {
    // @ts-ignore
    setGroupBy(getDefaultGroupBy(dataSpec) ?? '');
  }, [dataSpec]);

  function createChart() {
    try {
      addChart.mutate();
      // TODO: link to tab
      enqueueSnackbar('Chart created successfully!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(
        <>
          Error creating chart!!!.
          <br />
          {`${e}`}
        </>,
        { variant: 'error' },
      );
      return;
    }
  }

  const handleSelectData = (value: DataSpec) => setDataSpec(value);
  const handleSelectGroupBy = (value: GroupByField) => setGroupBy(value);
  return (
    <>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('analytics.customizeAnalytics.title')}
      </Dialog.Header>
      <Dialog.Content style={{ width: 720, height: 525, marginBottom: '25px' }}>
        <Box display="flex" height="100%" gap={2} flexDirection="column">
          <Label vertical>
            <Typography variant="m-regular">
              {t('analytics.customizeAnalytics.text.chartName')}
            </Typography>
            <Input value={chartName} onInput={e => setChartName(e.currentTarget.value)} />
          </Label>
          <Box
            display="flex"
            width="100%"
            height="100%"
            flexDirection="row"
            p={4}
            mb={2}
            style={{
              backgroundColor: dialogColors.dialog,
            }}
          >
            <Box display="flex" mt={4} style={{ width: '35%' }} flexDirection="column" gap={2}>
              <Label>
                <Typography variant="m-strong">
                  {t('analytics.customizeAnalytics.text.selected')}
                </Typography>
                <Typography color="primary">{formatNumber(checkedLabelCount)}</Typography>
              </Label>
              <Label vertical>
                <Typography variant="m-strong">
                  {t('analytics.customizeAnalytics.text.data')}
                </Typography>
                <RadioGroup
                  onChangeValue={handleSelectData}
                  size={30}
                  options={DataOptions}
                  value={dataSpec}
                />
              </Label>
              <Label vertical>
                <Typography variant="m-strong">
                  {t('analytics.customizeAnalytics.text.groupBy')}
                </Typography>
                <RadioGroup
                  onChangeValue={handleSelectGroupBy}
                  size={30}
                  options={getGroupByOptions(dataSpec, t)}
                  value={groupBy}
                />
              </Label>
            </Box>
            <PreviewChartArea
              data={data}
              isFetching={isFetching}
              dataSpec={dataSpec}
              groupBy={groupBy}
              style={{ width: '75%' }}
            />
          </Box>
        </Box>
        <Dialog.Actions>
          <Button variant="text" onClick={() => void state.hide()}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="strong-fill"
            color="primary"
            disabled={!data || !chartName || !groupBy || !dataSpec || data.data.length === 0}
            loading={isFetching}
            // loadingText={t('button.sending')}
            onClick={() => createChart()}
          >
            {t('button.ok')}
          </Button>
        </Dialog.Actions>
      </Dialog.Content>
    </>
  );
}

export function VisualizeAnalyticsDialog(props: Props) {
  return <Dialog state={props.state}>{props.state.visible && <DialogContent {...props} />}</Dialog>;
}
