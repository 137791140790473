import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { CheckFilled } from '@superb-ai/icons';
import { Box, IconButton, Input, Modal } from '@superb-ai/norwegian-forest';
import { Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import RegexUtils from '../../../../../../utils/RegexUtils';
import { useDatasetModalContext } from '../../../../contexts/ModalContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { useCurateDatasetService } from '../../../../services/DatasetService';

export default function SliceSettingsModal(): JSX.Element {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName, datasetId, sliceId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId: string;
  }>();
  const { sliceInfo, setSliceInfo } = useSliceContext();
  const { setSliceSettingsIsOpen, sliceSettingsIsOpen } = useDatasetModalContext();

  const { updateSlice } = useCurateDatasetService();

  const sliceName = sliceInfo?.name || '';
  const [newSliceName, setNewSliceName] = useState<string>(sliceName);

  const [newSliceDescription, setNewSliceDescription] = useState<string>(
    sliceInfo?.description || '',
  );
  const [isValidName, setIsValidName] = useState<boolean>(true);

  const handleUpdateDataset = async () => {
    if (!(newSliceName && datasetId && sliceId)) return;
    const res = await updateSlice({
      datasetId,
      description: newSliceDescription,
      sliceId: sliceId,
    });
    setSliceInfo({ ...sliceInfo, ...res, description: newSliceDescription });
    enqueueSnackbar(
      <>{`${t('bulkActions.messages.updateSliceSetting', { name: newSliceName })}`}</>,
      {
        variant: 'success',
        key: `create-slice-${name}`,
        preventDuplicate: true,
        autoHideDuration: 10000,
        content: (key, message) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                width: '420px',
                height: '101px',
                background: '#1f1f1f',
                borderLeft: '4px solid  #82db24',
                padding: '20px',
              }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box display="flex">
                  <Icon icon={CheckFilled} color="green-400" size={24} />
                  <Box display="flex" ml={1} pt={0.5}>
                    <Typography style={{ color: 'white', fontWeight: '600', fontSize: '12px' }}>
                      {message}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{ maxHeight: '24px' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    icon="clear"
                    color="backgroundColor"
                    style={{ padding: '0 8px' }}
                    onClick={() => closeSnackbar(key)}
                  />
                </Box>
              </Box>
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button
                    size="m"
                    style={{ color: 'white' }}
                    variant="text"
                    onClick={() => {
                      history.push(`/${accountName}/curate/dataset/${datasetId}/slice/${res.id}`);
                      closeSnackbar(key);
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        },
      },
    );
    setSliceSettingsIsOpen(false);
    setNewSliceName('');
    setNewSliceDescription('');
    setIsValidName(false);
  };

  const handleCloseButton = useCallback(() => {
    setSliceSettingsIsOpen(false);
    setNewSliceName('');
    setNewSliceDescription('');
    setIsValidName(false);
  }, [setSliceSettingsIsOpen, setNewSliceName, setNewSliceDescription, setIsValidName]);

  const handleSliceName = useCallback(
    e => {
      const {
        target: { value },
      } = e;
      setNewSliceName(value);
      if (
        value.length > 3 &&
        value.length < 51 &&
        RegexUtils.IS_ASCII(value) &&
        !RegexUtils.HAS_SPECIAL_SYMBOLS(value)
      )
        setIsValidName(true);
      else setIsValidName(false);
    },
    [setNewSliceName, setIsValidName],
  );

  const handleSliceDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setNewSliceDescription(e.target.value);
    },
    [setNewSliceDescription],
  );

  return (
    <Modal
      open={sliceSettingsIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.sliceSettings')}
      mainButton={{
        text: 'Ok',
        onClick: handleUpdateDataset,
        color: 'primary',
        disabled: !isValidName,
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => handleCloseButton(),
      }}
    >
      <Box py={2.5} px={4} minWidth="520px">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="gray-400">
            {t('curate.slices.sliceName')}
          </Typography>
          <Typography variant="m-regular" color="gray-400">
            {`${sliceName?.length}/50`}
          </Typography>
        </div>
        <Input
          color="grey"
          placeholder={t('curate.dialogs.placeholder.name')}
          variant="stroke"
          onChange={handleSliceName}
          value={newSliceName}
          disabled
        />
        {!isValidName && sliceName.length > 0 && (
          <Box style={{ boxSizing: 'border-box' }} p={0.5}>
            <Typography variant="s-regular" color="primary-400" style={{ padding: '0 2px' }}>
              <Trans t={t} i18nKey={'curate.slices.sliceNameGuide'} />
            </Typography>
          </Box>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="gray-400" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}{' '}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>
          <Typography variant="m-regular" color="gray-400">
            {`${newSliceDescription.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleSliceDescription}
          value={newSliceDescription}
        ></StyledTextarea>
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
