import { Box, IconButton, Input, Typography } from '@superb-ai/ui';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNamingRuleErrorMessage } from '../../../../../../configs/NamingRulesConfig';
import { Clear } from '@superb-ai/icons';

export default function DownloadNameInput({
  name,
  setName,
  nameErrorMessages,
  setNameErrorMessages,
  inputProps,
}: {
  name: string;
  setName: (name: string) => void;
  nameErrorMessages: string[];
  setNameErrorMessages: (nameErrorMessages: string[]) => void;
  inputProps?: ComponentProps<typeof Input>;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (name.length === 0) setNameErrorMessages([]);
  }, [name]);

  return (
    <Box position="relative">
      <Input
        placeholder={t('curate.datasets.prepareDownload.namePlaceholder')}
        value={name}
        onChange={e => {
          const name = e.target.value;
          setNameErrorMessages(
            getNamingRuleErrorMessage({
              str: name,
              target: t('curate.datasets.prepareDownload.download'),
            }),
          );
          setName(name);
        }}
        {...inputProps}
      />
      {nameErrorMessages.length > 0 && (
        <Box
          mt={0.25}
          position="absolute"
          width="100%"
          p={1}
          border="1px solid"
          borderColor="primary-400"
          backgroundColor="primary-100"
          borderRadius="2px"
          display="flex"
          flexDirection="column"
          gap={0.25}
        >
          {nameErrorMessages.map(reason => (
            <Typography key={reason} variant="m-regular" color="primary">
              {reason}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
}
