import { TFunction } from 'react-i18next';

export const REQUIRED = ({ t }: { t: TFunction }): string => t('auth.errors.required');

export const IS_INVALID = ({ t, name }: { t: TFunction; name: string }): string =>
  t('auth.errors.nameIsInvalid', { name });

export const IS_PERSONAL = ({ t }: { t: TFunction }): string => t('auth.errors.isPersonalEmail');

export const IS_VALID_LENGTH_SHORT = ({
  t,
  min,
  max,
}: {
  t: TFunction;
  min: number;
  max: number;
}): string => t('auth.errors.validLength', { min, max });

export const PLEASE_CHECK_THE_RULES_BELOW = ({ t }: { t: TFunction }): string =>
  t('auth.errors.checkRulesBelow');

export const PASSWORDS_MUST_MATCH = ({ t }: { t: TFunction }): string =>
  t('auth.errors.passwordMustMatch');
