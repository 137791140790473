import { MutableRefObject, useEffect, useState } from 'react';

export const useNoScrollHeight = <T extends HTMLElement>(
  myRef: MutableRefObject<T | null>,
): string | undefined => {
  const [calculatedContainerHeight, setCalculatedContainerHeight] = useState<string>(); // [containerHeight, contentAreaHeight]

  useEffect(() => {
    if (!myRef.current) return;
    const offsetTop = myRef.current.getClientRects()[0].top;
    const defaultPaddingBottom = 32;
    const borderHeight = 2;

    setCalculatedContainerHeight(
      `calc(100vh - ${offsetTop + defaultPaddingBottom + borderHeight}px)`,
    );
  }, [myRef.current]);

  return calculatedContainerHeight;
};
