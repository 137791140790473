import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { compact, uniq } from 'lodash';
import qs from 'qs';

import { StateGetterSetter } from '../../../contexts/types';
import { useDebounce } from '../../../hooks/DebounceHook';
import {
  CLUSTER_LEVEL_2_SIZE_8,
  CLUSTER_LEVELS,
  ClusterLevel,
  ImageFilterSchema,
  ProjectionInParam,
} from '../components/datasets/dataset/filter/types';
import {
  ClusterWithDisplayInfo,
  useImageLeafClustersQuery,
  useImageLeavesInSuperClustersQuery,
  useImageSuperClustersQuery,
} from '../components/datasets/dataset/views/embedding/queries/embeddingQueries';
import { useImageFilterSchemaQuery } from '../queries/datasetQueries';
import { ImageLeafCluster } from '../services/EmbeddingService';
import {
  convertSelectedLeafClustersToQueryString,
  convertSelectedSuperClustersToQueryString,
  convertSuperClustersWithDeselectedLeavesToQueryString,
  parseQueryStringToGetClusterInfo,
  stringifyFilters,
} from '../utils/filterUtils';
import { getQueryParams } from '../utils/routeUtils';
import { FilterContextProps, useFilterContext } from './FilterContext';
import { usePublicDatasetContext } from './PublicDatasetContextProvider';
import { useQueryContext } from './QueryContext';
import { useSliceContext } from './SliceContext';

type ContextProps = {
  filterSchema: ImageFilterSchema | undefined;
  hasAppliedFilters: boolean;
  isLoadingFilterSchema: boolean;
  imageClusters: ClusterWithDisplayInfo[];
  isLoadingImageClusters: boolean;
  leafImageClusters: ImageLeafCluster[];
  isLoadingLeafImageClusters: boolean;
  selectedFilters: ImageFilterSchema;
  initializeAppliedFilter: () => void;
  initializeSelectedFilter: () => void;
  isFilterChanged: boolean;
  handleSelectSuperCluster: (superClusterId: string, selectAll?: boolean) => void;
  handleSelectOrDeselectLeafCluster: (leafClusterId: string) => void;
  handleSelectAllSuperClusters: (superClusters: string[], selectAll: boolean) => void;
  selectedLeafIds: string[];
} & FilterContextProps &
  StateGetterSetter<['appliedFilters', 'setAppliedFilters'], ImageFilterSchema> &
  StateGetterSetter<['clusterLevel', 'setClusterLevel'], ClusterLevel | undefined> &
  StateGetterSetter<
    ['selectedProjectionIn', 'setSelectedProjectionIn'],
    ProjectionInParam | undefined
  > &
  StateGetterSetter<['selectedSuperClusters', 'setSelectedSuperClusters'], string[]> &
  StateGetterSetter<['selectedLeafClusters', 'setSelectedLeafClusters'], string[]> &
  StateGetterSetter<['deselectedLeafClusters', 'setDeselectedLeafClusters'], string[]> &
  StateGetterSetter<['showSelectedOnly', 'setShowSelectedOnly'], boolean>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const defaultSchema: ImageFilterSchema = {
    cluster_id_in: [],
  };

  const history = useHistory();

  const { sliceInfo } = useSliceContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { setHiddenFilterQueryString, hiddenFilterQueryString, queryString } = useQueryContext();

  const [clusterLevel, setClusterLevel] = useState<ClusterLevel>(CLUSTER_LEVEL_2_SIZE_8);
  const [selectedSuperClusters, setSelectedSuperClusters] = useState<string[]>([]);
  const [selectedLeafClusters, setSelectedLeafClusters] = useState<string[]>([]);
  const [deselectedLeafClusters, setDeselectedLeafClusters] = useState<string[]>([]);
  const [selectedProjectionIn, setSelectedProjectionIn] = useState<ProjectionInParam>();
  const [appliedFilters, setAppliedFilters] = useState<ImageFilterSchema>(defaultSchema);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  useEffect(() => {
    const queryParams = getQueryParams(history);
    if (queryParams.filter_query) {
      const { filter_query, ...otherParams } = queryParams;
      const { remainingQuery, parsed } = parseQueryStringToGetClusterInfo(
        queryParams.filter_query,
      ) || {
        remainingQuery: undefined,
        parsed: undefined,
      };

      if (parsed) {
        const newSelectedSuperClusters = [];
        const newSelectedLeafClusters = [];
        const newDeselectedLeafClusters = [];

        parsed.forEach(item => {
          switch (item.type) {
            case 'SelectedSuperClusters':
              newSelectedSuperClusters.push(...item.superClusterIds);
              break;
            case 'SelectedLeafClusters':
              newSelectedLeafClusters.push(...item.leafClusterIds);
              break;
            case 'SuperClustersWithDeselectedLeaves':
              newSelectedSuperClusters.push(item.superClusterId);
              newDeselectedLeafClusters.push(...item.leafClusterIds);
              break;
            default:
              break;
          }
        });

        setSelectedSuperClusters(newSelectedSuperClusters);
        setSelectedLeafClusters(newSelectedLeafClusters);
        setDeselectedLeafClusters(newDeselectedLeafClusters);
      }

      history.replace(
        `${history.location.pathname}?${qs.stringify({
          ...otherParams,
          query: remainingQuery,
        })}`,
      );
    }
  }, [history]);

  const { data: imageClusters, isLoading: isLoadingImageClusters } = useImageSuperClustersQuery({
    datasetId,
    clusterLevel,
    sliceName: sliceInfo?.name,
    fromPublicDatasets: showPublicDatasets,
  });

  const { data: leavesInCurrentLevelSuperbCluster } = useImageLeavesInSuperClustersQuery({
    datasetId,
    sliceName: sliceInfo?.name,
    fromPublicDatasets: showPublicDatasets,
    superClusterIds: imageClusters?.map(cluster => cluster.id) || [],
    clusterLevel,
  });

  const leavesInSelectedSuperCluster = useMemo(() => {
    if (!leavesInCurrentLevelSuperbCluster) return {};
    return leavesInCurrentLevelSuperbCluster.reduce((acc, cluster) => {
      if (!cluster.id) return acc;
      const superClusterId = cluster.superClusterId;
      if (selectedSuperClusters.includes(superClusterId)) {
        if (!acc[superClusterId]) acc[superClusterId] = [];
        acc[superClusterId].push(cluster.id);
      }
      return acc;
    }, {});
  }, [selectedSuperClusters, leavesInCurrentLevelSuperbCluster]);

  const leafToSuperClusterMap = useMemo(() => {
    if (!leavesInCurrentLevelSuperbCluster) return {};
    return leavesInCurrentLevelSuperbCluster.reduce((acc, cluster) => {
      if (cluster.superClusterId) {
        acc[cluster.id] = cluster.superClusterId;
      }
      return acc;
    }, {});
  }, [leavesInCurrentLevelSuperbCluster]);

  const mapSuperClusterToDeselectedLeafClusters = useCallback(
    deselectedLeafClusters => {
      return deselectedLeafClusters.reduce((acc, leafClusterId) => {
        const superClusterId = leafToSuperClusterMap[leafClusterId];
        if (superClusterId) {
          if (!acc[superClusterId]) acc[superClusterId] = [];
          acc[superClusterId].push(leafClusterId);
        }
        return acc;
      }, {});
    },
    [leafToSuperClusterMap],
  );

  useEffect(() => {
    setAppliedFilters(prevFilters => ({
      ...prevFilters,
      cluster_id_in: selectedSuperClusters,
    }));
  }, [selectedSuperClusters]);

  // useEffect(() => {
  //   setSelectedSuperClusters([]);
  // }, [clusterLevel]);

  const clusterQueryString = useCallback(() => {
    const selectedLeafClustersQuery = selectedLeafClusters.length
      ? convertSelectedLeafClustersToQueryString(compact(selectedLeafClusters))
      : '';

    const superClusterDeselectedMap =
      mapSuperClusterToDeselectedLeafClusters(deselectedLeafClusters);

    const deselectedLeafClustersQueries = superClusterDeselectedMap
      ? Object.keys(superClusterDeselectedMap).map(superClusterId =>
          convertSuperClustersWithDeselectedLeavesToQueryString(
            superClusterId,
            clusterLevel,
            superClusterDeselectedMap[superClusterId],
          ),
        )
      : [];

    const filteredSelectedSuperClusters = selectedSuperClusters.filter(
      superClusterId => !Object.keys(superClusterDeselectedMap || {}).includes(superClusterId),
    );

    const selectedSuperClustersQuery =
      filteredSelectedSuperClusters.length &&
      filteredSelectedSuperClusters.length !== Number(CLUSTER_LEVELS[clusterLevel])
        ? convertSelectedSuperClustersToQueryString(filteredSelectedSuperClusters, clusterLevel)
        : '';

    return [selectedLeafClustersQuery, ...deselectedLeafClustersQueries, selectedSuperClustersQuery]
      .filter(Boolean)
      .join(' OR ');
  }, [
    selectedLeafClusters,
    deselectedLeafClusters,
    mapSuperClusterToDeselectedLeafClusters,
    selectedSuperClusters,
    clusterLevel,
  ]);

  const debouncedQueryString = useDebounce(clusterQueryString(), 300);

  useEffect(() => {
    setHiddenFilterQueryString(debouncedQueryString);
  }, [setHiddenFilterQueryString, debouncedQueryString]);

  const { data: filterSchemaData, isLoading: isLoadingFilterSchema } = useImageFilterSchemaQuery({
    datasetId,
    sliceName: sliceInfo?.name,
    expand: ['cluster_level'],
    fromPublicDatasets: showPublicDatasets,
  });

  const { data: leafImageClusters, isLoading: isLoadingLeafImageClusters } =
    useImageLeafClustersQuery({
      datasetId,
      sliceName: sliceInfo?.name,
      fromPublicDatasets: showPublicDatasets,
      queryString,
    });

  const selectedLeafIds = useMemo(() => {
    const leafIds = new Set([
      ...selectedLeafClusters,
      ...selectedSuperClusters.flatMap(id =>
        (leavesInSelectedSuperCluster[id] || []).filter(id => !deselectedLeafClusters.includes(id)),
      ),
    ]);
    if (selectedSuperClusters.includes(null)) leafIds.add(null);
    return Array.from(leafIds);
  }, [
    selectedLeafClusters,
    deselectedLeafClusters,
    selectedSuperClusters,
    leavesInSelectedSuperCluster,
  ]);

  const handleSelectSuperCluster = useCallback(
    (superClusterId: string, selectAll?: boolean) => {
      const superClustersIncludingDeselectedLeaves = new Set(
        deselectedLeafClusters.map(leafClusterId => leafToSuperClusterMap[leafClusterId]),
      );

      setSelectedSuperClusters(prev => {
        let newSelectedSuperClusters;
        if (
          !selectAll &&
          prev.includes(superClusterId) &&
          !superClustersIncludingDeselectedLeaves.has(superClusterId)
        ) {
          newSelectedSuperClusters = prev.filter(id => id !== superClusterId);
        } else {
          newSelectedSuperClusters = uniq([...prev, superClusterId]);
        }

        // Update deselected leaf clusters for the super cluster
        const superClusterLeafIds = leavesInSelectedSuperCluster[superClusterId] || [];
        setSelectedLeafClusters(prev => prev.filter(i => !superClusterLeafIds.includes(i)));
        setDeselectedLeafClusters(prev => prev.filter(i => !superClusterLeafIds.includes(i)));

        return newSelectedSuperClusters;
      });
    },
    [deselectedLeafClusters, leafToSuperClusterMap, leavesInSelectedSuperCluster],
  );

  const handleSelectOrDeselectLeafCluster = useCallback(
    (leafClusterId: string | null) => {
      const superClusterId = leafToSuperClusterMap[leafClusterId];

      setSelectedLeafClusters(prevSelectedLeafClusters => {
        const selectedLeafSet = new Set(prevSelectedLeafClusters);
        const deselectedLeafSet = new Set(deselectedLeafClusters);

        if (selectedLeafSet.has(leafClusterId)) {
          selectedLeafSet.delete(leafClusterId);
        } else if (deselectedLeafSet.has(leafClusterId)) {
          deselectedLeafSet.delete(leafClusterId);
        } else {
          if (selectedSuperClusters.includes(superClusterId)) {
            deselectedLeafSet.add(leafClusterId);

            const superClusterLeaves = leavesInSelectedSuperCluster[superClusterId] || [];
            const allDeselected = superClusterLeaves.every(leaf => deselectedLeafSet.has(leaf));

            if (allDeselected) {
              const newSelectedSuperClusters = selectedSuperClusters.filter(
                id => id !== superClusterId,
              );
              setSelectedSuperClusters(newSelectedSuperClusters);
              superClusterLeaves.forEach(leaf => deselectedLeafSet.delete(leaf));
            }
          } else {
            selectedLeafSet.add(leafClusterId);
          }
        }

        if (deselectedLeafClusters.length !== deselectedLeafSet.size) {
          setDeselectedLeafClusters(deselectedLeafSet.size ? Array.from(deselectedLeafSet) : []);
        }

        return selectedLeafSet.size ? Array.from(selectedLeafSet) : [];
      });
    },
    [
      deselectedLeafClusters,
      leafToSuperClusterMap,
      selectedSuperClusters,
      leavesInSelectedSuperCluster,
    ],
  );

  useEffect(() => {
    setAppliedFilters(prevFilters => ({ ...prevFilters }));
  }, [clusterLevel]);

  const { isApplyingFilter, setIsApplyingFilter, isFilterOpen, setIsFilterOpen } =
    useFilterContext();

  const hasAppliedFilters = !!(hiddenFilterQueryString && hiddenFilterQueryString.length > 0);

  const selectedFilters = useMemo(
    () => ({
      cluster_id_in: selectedSuperClusters,
      cluster_level: clusterLevel,
      ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
    }),
    [clusterLevel, selectedSuperClusters, selectedProjectionIn],
  );

  const initializeAppliedFilter = useCallback(() => {
    setAppliedFilters(defaultSchema);
  }, []);

  const initializeSelectedFilter = useCallback(() => {
    setSelectedSuperClusters([]);
    setSelectedLeafClusters([]);
    setDeselectedLeafClusters([]);
    setClusterLevel(CLUSTER_LEVEL_2_SIZE_8);
    setSelectedProjectionIn(undefined);
  }, []);

  useEffect(() => {
    if (
      selectedLeafClusters.length === 0 &&
      deselectedLeafClusters.length === 0 &&
      selectedSuperClusters.length === 0
    ) {
      setShowSelectedOnly(false);
    }
  }, [selectedLeafClusters, deselectedLeafClusters, selectedSuperClusters]);

  const handleSelectAllSuperClusters = useCallback(
    (superClusters: string[], selectAll: boolean) => {
      if (showSelectedOnly) {
        initializeSelectedFilter();
      } else {
        superClusters.forEach(clusterId => handleSelectSuperCluster(clusterId, selectAll));
      }
    },
    [handleSelectSuperCluster, initializeSelectedFilter, showSelectedOnly],
  );

  const isFilterChanged = useMemo(
    () => stringifyFilters(appliedFilters) !== stringifyFilters(selectedFilters),
    [appliedFilters, selectedFilters],
  );

  return {
    filterSchema: filterSchemaData,
    isApplyingFilter,
    setIsApplyingFilter,
    isFilterOpen,
    setIsFilterOpen,
    appliedFilters,
    setAppliedFilters,
    hasAppliedFilters,
    selectedSuperClusters,
    setSelectedSuperClusters,
    selectedLeafClusters,
    setSelectedLeafClusters,
    deselectedLeafClusters,
    setDeselectedLeafClusters,
    isLoadingFilterSchema,
    clusterLevel,
    setClusterLevel,
    selectedProjectionIn,
    setSelectedProjectionIn,
    imageClusters: imageClusters ?? [],
    isLoadingImageClusters,
    selectedFilters,
    initializeAppliedFilter,
    initializeSelectedFilter,
    isFilterChanged,
    leafImageClusters: leafImageClusters ?? [],
    selectedLeafIds,
    isLoadingLeafImageClusters,
    handleSelectOrDeselectLeafCluster,
    handleSelectSuperCluster,
    showSelectedOnly,
    setShowSelectedOnly,
    handleSelectAllSuperClusters,
  };
};

export const useImageFilterContext = (): ContextProps => {
  return React.useContext(Context);
};

export const ImageFilterProvider: React.FC = ({ children }) => {
  const filterInfo = useProvider();
  return <Context.Provider value={filterInfo}>{children}</Context.Provider>;
};
