export function getRecallText(value: number) {
  if (value < 0.2) {
    return 'analytics.exportsAnalytics.heatmap.recall.low';
  }
  if (value < 0.8) {
    return 'analytics.exportsAnalytics.heatmap.recall.medium';
  }
  return 'analytics.exportsAnalytics.heatmap.recall.high';
}

export function getPrecisionText(value: number) {
  if (value < 0.2) {
    return 'analytics.exportsAnalytics.heatmap.precision.low';
  }
  if (value < 0.8) {
    return 'analytics.exportsAnalytics.heatmap.precision.medium';
  }
  return 'analytics.exportsAnalytics.heatmap.precision.high';
}
