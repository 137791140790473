import { CustomAutoLabelType } from '../types/customAutoLabelTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

const getCustomAutoLabels: ApiCall<
  { projectId: string; page: number },
  {
    count: number;
    results: CustomAutoLabelType[];
  }
> = async args => {
  const { projectId, page, isGuest, urlInfo } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/custom_auto_labels/?${ServiceUtils.getParamString({ page })}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const requestCreateCustomAutoLabel: ApiCall<
  { projectId: string; name: string; exportHistoryId: string },
  CustomAutoLabelType
> = async args => {
  const { projectId, name, exportHistoryId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/custom_auto_labels/`,
    data: {
      name,
      exportHistoryId,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCustomAutoLabelDownloadStatus: ApiCall<
  { projectId: string; customAutoLabelId: string },
  { status: 'NONE' | 'PROCESSING' | 'READY' }
> = async args => {
  const { projectId, customAutoLabelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/download/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCustomAutoLabelDownloadUrl: ApiCall<
  { projectId: string; customAutoLabelId: string },
  any
> = async args => {
  const { projectId, customAutoLabelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/download/read-url/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const requestCustomAutoLabelDownloadUrl: ApiCall<
  { projectId: string; customAutoLabelId: string },
  any
> = async args => {
  const { projectId, customAutoLabelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/download/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const updateCustomAutoLabel: ApiCall<
  { projectId: string; customAutoLabelId: string; name: string },
  any
> = async args => {
  const { projectId, customAutoLabelId, name, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/`,
    data: {
      name,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteCreateCustomAutoLabel: ApiCall<
  { projectId: string; customAutoLabelId: string },
  any
> = async args => {
  const { projectId, customAutoLabelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const archiveCreateCustomAutoLabel: ApiCall<
  { projectId: string; customAutoLabelId: string },
  any
> = async args => {
  const { projectId, customAutoLabelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/custom_auto_labels/${customAutoLabelId}/archive/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type CALState = 'WAITING' | 'IN_QUEUE' | 'PROCESSING' | 'READY' | 'CANCELED' | 'FAILED';

const getCustomAutoLabelsInAccount: ApiCall<
  { page?: number; pageSize?: number; idIn?: string[]; stateIn?: CALState[] },
  any
> = async args => {
  const { page = 1, pageSize = 50, stateIn, idIn, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/custom_auto_labels/?${ServiceUtils.getParamString({
      page,
      pageSize,
      idIn,
      stateIn,
    })}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  getCustomAutoLabels,
  requestCreateCustomAutoLabel,
  updateCustomAutoLabel,
  getCustomAutoLabelDownloadStatus,
  getCustomAutoLabelDownloadUrl,
  requestCustomAutoLabelDownloadUrl,
  deleteCreateCustomAutoLabel,
  archiveCreateCustomAutoLabel,
  getCustomAutoLabelsInAccount,
};
