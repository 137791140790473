import AuthService from './AuthService';
import { ApiCall } from './types';

interface RequestUserStatsArgs {
  projectId: string;
  filterInfo: { filterUrl?: string; labelCount: number };
  usageType: string;
}
interface RequestUserStatsResult {
  labelCount: number;
  filter: string;
  usageType: string;
  data: Record<string, any>;
}
/**
 *
 * @param {string} projectId
 * @param {object} filterInfo
 * @param {boolean} isGuest
 * @param {object} urlInfo Has info such as project_id and tenant_id
 * @param {string} usageType Usage type for user statistics data
 *
 *  Usage type:
 *   - email (data file requested from label list)
 *   - analytics (data requested from analytics to view in Suite)
 *
 * Returns data in Response config. Ex.
 * "{"filter":"id_in[]=9a37822d-eef2-49f7-a4b5-993ec592a3d1"}"
 */
const requestUserStats: ApiCall<RequestUserStatsArgs, RequestUserStatsResult> = async args => {
  const { projectId, filterInfo, isGuest, urlInfo, usageType } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/user_reports/`,
    data: {
      filter: filterInfo?.filterUrl,
      labelCount: filterInfo.labelCount,
      usageType,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  // TODO: do we need to check if config and data exists?
  const data = JSON.parse(res.config.data);
  return {
    labelCount: data.label_count,
    filter: data.filter,
    usageType: data.usageType,
    data: res.data,
  };
};

// eslint-disable-next-line
const getLatest: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  try {
    const res = await AuthService.apiCallAfterLogin({
      method: 'get',
      url: `/projects/${projectId}/user_reports/latest/`,
      data: {},
      hasPublicApi: false,
      isGuest,
      urlInfo,
    });
    return res.data;
    // eslint-disable-next-line
  } catch (err: any) {}
};

// eslint-disable-next-line
const getVersion: ApiCall<{ projectId: string; reportId: string }, any> = async args => {
  const { projectId, reportId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/user_reports/${reportId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

// eslint-disable-next-line
const getList: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/user_reports/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  requestUserStats,
  getLatest,
  getList,
  getVersion,
};
