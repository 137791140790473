import React from 'react';

import AccountMembers from '../../components/pages/account/accountMembers';
import { Page } from '../../menu';

export default function Layout(): JSX.Element {
  return (
    <Page>
      <AccountMembers />
    </Page>
  );
}
