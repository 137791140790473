import PreLabelStatus from '../../../../elements/PreLabelStatus';

// TODO (ml) - move to correct place
export type AutoLabelStatus = 'PROCESSING' | 'COMPLETE' | 'FAILED';
interface Props {
  status: AutoLabelStatus;
}

export const AutoLabelStatusIcon = ({ status }: Props) => {
  const transformStatus = (autoLabelStatus: string): string | null => {
    const mapper = {
      PROCESSING: 'doing',
      COMPLETE: 'done',
      FAILED: 'failed',
    };
    return mapper[autoLabelStatus as keyof typeof mapper];
  };
  return <PreLabelStatus type="auto-label" status={transformStatus(status) || ''} />;
};
