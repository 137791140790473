import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions, reduceGroupedOptions } from 'react-select-async-paginate';

import { Icon, Select } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';
import { DateRange } from 'rsuite/esm/DateRangePicker';

import {
  AssetDataType,
  dataTypes,
  getDataTypeTextKeys,
  useAvailableDataTypes,
} from '../../../../apps/projects/project/data/assetDataTypes';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import {
  useSearchedProjectMutation,
  useUpdateProjectList,
} from '../../../../queries/useProjectListQuery';
import { useUsersQuery } from '../../../../queries/useUsers';
import { getUrl } from '../../../../routes/util';
import { MemberData } from '../../../../types/memberTypes';
import { Option, PageAdditional } from '../../../../types/selectTypes';
import { DateRangePicker } from '../../../elements/datePicker/DateRangePicker';
import UserAvatar from '../../../elements/UserAvatar';

function toOption<V extends string>(value: V, label: string = value) {
  return { label, value };
}

export default function DataFilters(): JSX.Element {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { params } = routeInfo;
  const projectInfo = useProjectInfo();
  const { accountName } = useParams<{ accountName: string; projectId: string }>();

  const availableDataTypes = useAvailableDataTypes();

  const baseUrl = [accountName, 'label', 'data'];

  const { mutateAsync: getProjectList } = useUpdateProjectList();
  const { mutate: updateSearchedProjectIds } = useSearchedProjectMutation();
  const { data: usersData } = useUsersQuery({ params: { asList: '' }, statusIn: [] });

  const projectFilter = params.project ? toOption(params.project) : undefined;
  const uploaderFilter = params.uploader ? toOption(params.uploader) : undefined;
  const dataTypeFilter = params.dataType
    ? toOption(params.dataType, t(getDataTypeTextKeys(params.dataType as AssetDataType).title))
    : undefined;

  const userMap = new Map<string, MemberData>();
  const userOptions = usersData?.reduce((acc: Option[], user) => {
    userMap.set(user.email, user);

    // Only owners and admins can be the uploader
    if (user.tenantRole === 'Owner' || user.tenantRole === 'Admin') {
      return [...acc, { label: user.name, value: user.email }];
    }
    return acc;
  }, []);

  const createdDateFilter = (): [Date, Date] | null => {
    if (params.createdDate) {
      return [new Date(params.createdDate), new Date(params.createdDate)];
    }
    if (params.createdStartDate && params.createdEndDate) {
      return [new Date(params.createdStartDate), new Date(params.createdEndDate)];
    }
    return null;
  };

  const handleChangeDataTypeFilter = (value: SingleValue<Option>) => {
    delete params.page;
    if (!value) {
      delete params.dataType;
    }
    const args = value ? { ...params, dataType: value.value } : { ...params };
    const url = getUrl(baseUrl, {}, args);
    routeInfo.history.push(url);
  };

  const handleChangeProjectFilter = (value: SingleValue<Option>) => {
    delete params.page;
    const args = { ...params, project: value?.value || '' };
    const url = getUrl(baseUrl, {}, args);
    routeInfo.history.push(url);
  };

  const handleChangeUploaderFilter = (value: SingleValue<Option>) => {
    delete params.page;
    if (!value) {
      delete params.uploader;
    }
    const args = value ? { ...params, uploader: value.value } : { ...params };
    const url = getUrl(baseUrl, {}, args);
    routeInfo.history.push(url);
  };

  // if picked on day -> created date, if picked range -> created start date, created end date
  const handleChangeDateFilter = (value: DateRange | null) => {
    delete params.page;
    if ((!value?.[0] && !value?.[1]) || !value) {
      delete params.createdStartDate;
      delete params.createdEndDate;
      delete params.createdDate;

      routeInfo.history.push(getUrl(baseUrl, {}, params));
      return;
    }

    const hasSameDate = value?.[0]?.getTime() === value?.[1]?.getTime();

    if (hasSameDate) {
      delete params.createdStartDate;
      delete params.createdEndDate;
    } else {
      delete params.createdDate;
    }

    const args =
      value?.[0] && value?.[1]
        ? hasSameDate
          ? { ...params, createdDate: value?.[0] }
          : { ...params, createdStartDate: value?.[0], createdEndDate: value?.[1] }
        : { ...params };

    const url = getUrl(baseUrl, {}, args);
    routeInfo.history.push(url);
  };

  useEffect(() => {
    projectInfo.project && handleChangeProjectFilter(toOption(projectInfo.project.name));
  }, []);

  const handleSearchProject: LoadOptions<Option, GroupBase<Option>, PageAdditional> = async (
    inputValue,
    prevOptions,
    additional,
  ) => {
    const page = additional?.page || 1;
    const projectList = await getProjectList({
      nameIcontains: inputValue,
      page,
      origin: 'components/pages/data/Filters.tsx',
    });
    const searchProjectIds = {} as Record<string, string>;
    const resultProjects = projectList.results.map(project => {
      searchProjectIds[project.name] = project.id;
      return { label: project.name, value: project.name, type: 'Projects' };
    });

    updateSearchedProjectIds(resultProjects);

    const pageSize = 10;
    const hasMore = Math.ceil(projectList.count / pageSize) > page;

    const grouped =
      prevOptions.length <= 0
        ? [
            {
              label: t('data.filterOptions'),
              options: [
                { label: t('data.allProjects'), value: '[ all projects ]', type: 'Filter Options' },
                {
                  label: t('data.assignedToProject'),
                  value: '[ assigned to a project ]',
                  type: 'Filter Options',
                },
                {
                  label: t('data.notAssignedToProject'),
                  value: '[ not assigned to any project ]',
                  type: 'Filter Options',
                },
              ],
            },
            { label: t('projects.title'), options: resultProjects },
          ]
        : [{ label: t('projects.title'), options: resultProjects }];

    return { options: grouped, hasMore, additional: { page: page + 1 } };
  };

  function getDataTypesOptions() {
    const keys = Object.keys(availableDataTypes) as AssetDataType[];
    return keys.map((type: AssetDataType) => toOption(type, t(getDataTypeTextKeys(type).title)));
  }

  return (
    <>
      <Box
        display="grid"
        alignItems="center"
        style={{
          gridTemplateColumns: '200px 200px 200px max-content',
        }}
        gap={1}
        mb={1}
      >
        <Select.Async
          value={projectFilter}
          placeholder={t('data.filters.filterByProject')}
          loadOptions={handleSearchProject}
          onChange={handleChangeProjectFilter}
          reduceOptions={reduceGroupedOptions}
        />
        <Select.Basic
          placeholder={t('data.filters.filterByDataType')}
          options={getDataTypesOptions()}
          getOptionLabelAdornment={option => (
            <Icon name={dataTypes[option.value as AssetDataType]?.icon} />
          )}
          onChange={handleChangeDataTypeFilter}
          value={dataTypeFilter}
        />
        <Select.Basic
          placeholder={t('data.filters.filterByUploader')}
          options={userOptions}
          getOptionLabelAdornment={(option: Option) => {
            const userInfo = userMap?.get(option.value);
            return (
              <UserAvatar
                size={16}
                userInfo={{
                  avatarUrl: userInfo?.avatarUrl || '',
                  email: userInfo?.email || '',
                  name: userInfo?.name || '',
                  status: userInfo?.status || '',
                }}
                noShadow
              />
            );
          }}
          getOptionLabel={(option: Option) => `${option.label} (${option.value})`}
          onChange={handleChangeUploaderFilter}
          value={uploaderFilter}
        />
        <Box
          display="flex"
          alignItems="center"
          border="1px solid"
          borderColor="gray-300"
          borderRadius="2px"
          style={{ height: '32px' }}
        >
          <DateRangePicker
            onChange={handleChangeDateFilter}
            value={createdDateFilter()}
            onClean={() => handleChangeDateFilter(null)}
          />
        </Box>
      </Box>
    </>
  );
}
