import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import ManageMembers from '../../../../components/pages/account/advanced/ManageMembers/Layout';
import { isOwner } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu/MenuItem';

// deprecated??
export const AccessManageMemberMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountMembers,
  path: 'manage',
  title: ({ t }) => t('settings.advanced.manageMembers'),
  component: ManageMembers,
  isVisible: false,
  isEnabled({ authInfo }) {
    return isOwner(authInfo);
  },
  breadcrumb: ({ t, authInfo }) => [
    { title: t('settings.advanced.title'), path: `/${authInfo?.accountName}/settings/access` },
  ],
});
