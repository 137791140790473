import React, { useEffect, useState } from 'react';

import UsersService from '../services/UsersService';
import UserRoleUnion from '../union/UserRoleUnion';
import { useCommandBar } from '../utils/external';
import UserUtils from '../utils/UserUtils';
import { useAuthInfo } from './AuthContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

// prettier-ignore
type UsersContextProps =
  StateGetterSetter<['owners', 'setOwners'], any[]> &
  StateGetterSetter<['admins', 'setAdmins'], any[]> &
  StateGetterSetter<['collaborators', 'setCollaborators'], any[]> &
  StateGetterSetter<['userObjects', 'setUserObjects'], Record<string, any>> &
  {
    updateMembers(): Promise<void>;
  };

const UsersContext = React.createContext({} as UsersContextProps);

export const UsersProvider: React.FC = ({ children }) => {
  const [owners, setOwners] = useState<any[]>([]);
  const [admins, setAdmins] = useState<any[]>([]);
  const [collaborators, setCollaborators] = useState<any[]>([]);
  // 어느 프로젝트에도 속하지 않은 collaborator
  const [userObjects, setUserObjects] = useState({});
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  useEffect(() => {
    if (!authInfo.isGuest) {
      updateMembers();
    }
  }, [authInfo.isGuest]);

  useEffect(() => {
    if (!useCommandBar) return;
    if (!collaborators) return;
    window.CommandBar?.addContext('collaboratorCount', collaborators.length);
  }, [collaborators]);

  const updateMembers = async () => {
    if (!UserRoleUnion.isCollaborator(authInfo.role)) {
      const users = await UsersService.getUsers({
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setOwners(users[0].users);
      setAdmins(users[1].users);
      setCollaborators(users[2].users);

      const nextUserObjects = UserUtils.createUserObjects({
        owners: users[0].users,
        admins: users[1].users,
        collaborators: users[2].users,
      });
      setUserObjects(nextUserObjects);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        owners,
        setOwners,
        admins,
        setAdmins,
        collaborators,
        setCollaborators,
        updateMembers,
        userObjects,
        setUserObjects,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersInfo = (): UsersContextProps => {
  return React.useContext(UsersContext);
};
