import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Plus, Trash } from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, LoadingIndicator, Typography } from '@superb-ai/ui';

import {
  useApiKeyDeleteMutation,
  useApiKeyQuery,
  useApiKeyRequestMutation,
} from '../../../../queries/useApiKeyQuery';
import { formatDateTime } from '../../../../utils/date';
import ReconfirmDialog from '../../../elements/ReconfirmDialog';
import AccessKeyViewer from './AccessKeyViewer';
import EmptyCardContent from './SectionContent';

export default function TeamAccessKey() {
  const { t } = useTranslation();
  const { mutate: requestApiKey, isLoading: isRequesting } = useApiKeyRequestMutation();
  const { mutate: deleteApiKey, isLoading: isDeleting } = useApiKeyDeleteMutation(t);
  const { data: apiKeyInfo, isLoading } = useApiKeyQuery();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const isKeyNotCreated = !apiKeyInfo?.key && !isLoading;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        style={{ minHeight: 32 }}
      >
        <Typography variant="h3">{t('settings.advanced.teamAccessKey')}</Typography>
        {isKeyNotCreated && (
          <Button variant="strong-fill" color="primary" onClick={() => requestApiKey()}>
            <Icon icon={Plus} /> {t('settings.advanced.newTeamAccessKey')}
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
        p={3}
        borderRadius="2px"
        style={{ minHeight: 88 }}
      >
        {isLoading || isDeleting || isRequesting ? (
          <LoadingIndicator />
        ) : !apiKeyInfo ? (
          <EmptyCardContent type="Team Access Key" />
        ) : (
          <>
            <AccessKeyViewer accessKey={apiKeyInfo?.key ?? ''} />
            <Box display="flex" width="100%" alignItems="center" mx={1}>
              <Typography variant="m-regular" color="gray-400">
                {t('settings.advanced.created')} {formatDateTime(apiKeyInfo.createdAt)}
              </Typography>
            </Box>
            <IconButton
              icon={Trash}
              color="gray"
              variant="text"
              onClick={() => setIsConfirmDialogOpen(true)}
            />
          </>
        )}
        <ReconfirmDialog
          title={t('settings.advanced.deleteAccessKey')}
          variant="warning"
          cancelButtonMessage={t('button.cancel')}
          confirmButtonMessage={t('button.delete')}
          isDialogOpen={isConfirmDialogOpen}
          onClickCancel={() => setIsConfirmDialogOpen(false)}
          onClickConfirm={() => {
            deleteApiKey();
            setIsConfirmDialogOpen(false);
          }}
        >
          <Typography variant="m-regular">
            <Trans
              t={t}
              i18nKey={'settings.advanced.deleteTeamAccessKeyWarning'}
              values={{ accessKeyName: name }}
              components={{ bold: <Typography variant="m-strong" /> }}
            />
          </Typography>
        </ReconfirmDialog>
      </Box>
    </Box>
  );
}
