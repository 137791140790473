import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { useDialogState } from '@superb-ai/ui';

import Tutorial from '../../../../../components/elements/Tutorial';
import TrialFormDialog from '../../../../../components/HubspotForms/CurateHubspotFormDialog';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../../../consts/ModalMessage';
import StartUserTutorialHeader from './StartUserTutorialHeader';
import { getTutorialContents } from './tutorialContents';

export default function NewTutorial({
  setCreateDatasetModalIsOpen,
}: {
  setCreateDatasetModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { i18n, t } = useTranslation();

  const eventId = 'suite-curate-contact-sales-form';
  const { openModal, closeModal } = useAlertModal();
  const state = useDialogState();

  return (
    <>
      <StartUserTutorialHeader setCreateDatasetModalIsOpen={setCreateDatasetModalIsOpen} />
      <Tutorial contents={getTutorialContents(t, i18n.language)} fullWidth />
      <TrialFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
    </>
  );
}
