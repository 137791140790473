export type PossibleValue = string | string[] | boolean | number;

export interface Filter<Key extends string, Value extends PossibleValue> {
  key: Key;
  defaultValue?: Value[];
  toApiParams(value: string | number, projectId?: string): [string, PossibleValue][];
}

// option에 parent condition 추가
export function createFilter<Key extends string, Value extends PossibleValue>(
  filter: Filter<Key, Value>,
): Filter<Key, Value> {
  return filter;
}
