import { StateGetterSetter } from '../../../contexts/types';
import { SourceIntegrationTarget } from '../../pages/account/integrations/types';

export const UPLOAD_METHODS = ['CLOUD', 'FILE', 'URL', 'CLI'] as const;
export const DEFAULT_UPLOAD_METHOD = 'CLOUD';
export type UploadMethod = (typeof UPLOAD_METHODS)[number];

export type UploadIcon = 'fileImageOutline' | 'cloudOutline' | 'link2' | 'windowTerminal';

// prettier-ignore
export type CloudUploadContextProps =
  StateGetterSetter<['cloudStorageType', 'setCloudStorageType'], SourceIntegrationTarget> &
  StateGetterSetter<['cloudIntegrationId', 'setCloudIntegrationId'], string | null> &
  StateGetterSetter<['cloudPrefix', 'setCloudPrefix'], string | null> &
  StateGetterSetter<['cloudBucketName', 'setCloudBucketName'], string>&
  StateGetterSetter<['integratedCloudPrefix', 'setIntegratedCloudPrefix'], string | null>;
