import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { joinPaths } from '../../../../routes/util';
import { db } from '../HistoryTable';
import History from './History';
import ResultPerClassView from './ResultPerClassView';
import ResultView from './ResultView';

export default function Layout(): JSX.Element {
  const match = useRouteMatch();

  useEffect(() => {
    return () => {
      (async () => {
        await db.mislabelHistoryTable.clear();
      })();
    };
  }, []);

  return (
    <Switch>
      <Route exact path={match.path} component={History} />
      <Route exact path={joinPaths(match.path, 'results', ':resultId')} component={ResultView} />
      <Route
        path={joinPaths(match.path, 'results', ':resultId', 'class', ':classId')}
        component={ResultPerClassView}
      />
    </Switch>
  );
}
