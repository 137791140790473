import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

export type UserApiKey = {
  apiKeyId: string;
  tenantId: string;
  email: string;
  name: string;
  createdAt: string;
  expirationDate: string | null;
  status: 'Active' | 'Inactive';
  // permission: 'Read' | 'Read/Write';
  key: string;
};

const getUserApiKeys: ApiCall<unknown, { data: UserApiKey[] }> = async ({ isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: '/auth/api-keys',
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const createUserApiKey: ApiCall<{ data: Partial<UserApiKey> }, { data: UserApiKey }> = async ({
  data,
  isGuest,
  urlInfo,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/api-keys',
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const updateUserApiKey: ApiCall<
  { data: Partial<UserApiKey> & { apiKeyId: string } },
  { data: UserApiKey }
> = async ({ data, isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/auth/api-keys/${data.apiKeyId}`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteUserApiKey: ApiCall<{ apiKeyId: string }, { data: UserApiKey }> = async ({
  apiKeyId,
  isGuest,
  urlInfo,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/auth/api-keys/${apiKeyId}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type TenantUserApiKeyOrderBy =
  | 'created_at'
  | '-created_at'
  | 'expiration_date'
  | '-expiration_date'
  | 'status'
  | '-status';
export type TenantUserApiParams = {
  email?: string;
  limit?: number;
  lastEvaluatedKey?: string;
  orderBy?: TenantUserApiKeyOrderBy;
  status?: 'Active' | 'Inactive';
};

const getTenantUserApiKeys: ApiCall<
  TenantUserApiParams & { accountName: string },
  { totalCount: number; lastEvaluatedKey: string; objects: UserApiKey[] }
> = async ({
  accountName,
  email,
  status,
  limit = 10,
  lastEvaluatedKey,
  orderBy = '-created_at',
  isGuest,
  urlInfo,
}) => {
  const params = {
    email,
    status,
    limit,
    lastEvaluatedKey,
    orderBy,
  };
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/auth/api-keys/tenants/${accountName}?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return (
    res.data.data || {
      totalCount: 0,
      objects: [],
    }
  );
};

export default {
  getUserApiKeys,
  createUserApiKey,
  updateUserApiKey,
  deleteUserApiKey,
  getTenantUserApiKeys,
};
