import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@superb-ai/ui';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import RegexUtils from '../../../../utils/RegexUtils';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '630px',
    minHeight: '56px',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bold: {
    fontSize: '13px',
    color: '#4a4a4a',
    display: 'inline',

    '&.className': {
      maxWidth: '120px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  light: {
    fontSize: '11px',
    color: '#c0c0c0',
    '&.optional': {
      display: 'inline',
      margin: '0px 6px 0px 4px',
      whiteSpace: 'nowrap',
    },
  },
  input: {
    width: '55px',
    borderBottom: 'solid 1px #c0c0c0',
    color: '#4a4a4a',
    '& .MuiInput-input': {
      textAlign: 'center',
      padding: '3px 0px',
      '&::placeholder': {
        color: '#c0c0c0',
      },
    },
  },
}));

const parseInputValue = (str: string | number) => {
  if (typeof str === 'number' || str === '') return str;

  let result = '';

  for (let i = 0; i < str.length; i++) {
    const curChar = str[i];
    if (RegexUtils.IS_ONLY_NUMERIC(curChar)) {
      result += curChar;
    }
  }

  return parseInt(result, 10);
};

// eslint-disable-next-line
const ClassPropertyAnnotationCountRange = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { selectedObjectClass } = props;

  const [min, setMin] = useState<number | ''>('');
  const [max, setMax] = useState<number | ''>('');

  useEffect(() => {
    if (!selectedObjectClass) {
      setMin('');
      setMax('');
      return;
    }
    setMin(parseInputValue(selectedObjectClass.minCount));
    setMax(parseInputValue(selectedObjectClass.maxCount));
  }, [selectedObjectClass]);

  const handleChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedObjectClass) return;
    const parsedValue = parseInputValue(e.target.value);
    setMin(parsedValue);
    helper.changeAnnotationCountRange(newProjectInfo, parsedValue, max);
  };
  const handleChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedObjectClass) return;
    const parsedValue = parseInputValue(e.target.value);
    setMax(parsedValue);
    helper.changeAnnotationCountRange(newProjectInfo, min, parsedValue);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
      borderRadius="2px"
      style={{
        minHeight: '56px',
      }}
    >
      <MUI.Box display="flex" alignItems="flex-end" minWidth="400px" ml="24px">
        <MUI.Typography className={classes.bold}>
          <Trans
            i18nKey="projects.createProject.numberOfObjectClass"
            values={{ objectClassName: selectedObjectClass.name }}
            components={{
              ObjectClassName: <MUI.Typography className={`${classes.bold} className`} />,
            }}
          />
        </MUI.Typography>
        <MUI.Typography className={`${classes.light} optional`}>
          ({t('curate.data.optional')})
        </MUI.Typography>
      </MUI.Box>
      <MUI.Box display="flex" alignItems="center" mr="24px">
        <MUI.Typography className={classes.bold}>{t('analytics.qa.min')}</MUI.Typography>
        <MUI.Input
          className={classes.input}
          placeholder={t('components.ViewerRightDrawerLabelInformationTags.none')}
          value={min}
          onChange={handleChangeMin}
          onKeyDown={e => e.stopPropagation()}
        />
        <MUI.Typography>&nbsp;-&nbsp;</MUI.Typography>
        <MUI.Typography className={classes.bold}>{t('analytics.qa.max')}</MUI.Typography>
        <MUI.Input
          className={classes.input}
          placeholder={t('components.ViewerRightDrawerLabelInformationTags.none')}
          value={max}
          onChange={handleChangeMax}
          onKeyDown={e => e.stopPropagation()}
        />
      </MUI.Box>
    </Box>
  );
};

export default ClassPropertyAnnotationCountRange;
