import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import { Box, Input, Modal, Stepper, Typography } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { getNamingRuleErrorMessage } from '../../../configs/NamingRulesConfig';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import ProjectService from '../../../services/ProjectService';
import { Option } from '../../../types/selectTypes';
import ProjectSelect from '../../elements/ProjectSelect';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DuplicateProjectModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);

  const Steps = {
    target: {
      label: 'Choose Project',
    },
    name: {
      label: 'Change Project Name',
    },
  } as const;
  type Step = keyof typeof Steps;

  const [selectedProject, setSelectedProject] = useState<{ value: string; label: string }>();
  const [activeStep, setActiveStep] = useState<Step>('target');
  const [duplicateProjectName, setDuplicateProjectName] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);

  const { isOpen, onClose } = props;

  const handleChangeSelect = (option: SingleValue<Option>) => {
    if (!option) return;
    setSelectedProject(option);
    setDuplicateProjectName(`Copy of ${option.label}`.slice(0, 50));
  };

  const handleClose = () => {
    setSelectedProject(undefined);
    setActiveStep('target');
    setDuplicateProjectName('');
    setIsRequesting(false);
    onClose();
  };

  const handleChangeDuplicateProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDuplicateProjectName(e.target.value);
  };

  const handleClickDuplicateProject = async () => {
    if (!selectedProject) return;

    const trimName = duplicateProjectName.trim();

    const errorMessages = getNamingRuleErrorMessage({
      str: trimName,
      target: 'project',
      canOnlyUseASCII: true,
    });

    if (errorMessages.length !== 0) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      enqueueSnackbar(errorMessages[0], { variant: 'warning' });
      return;
    }

    setIsRequesting(true);

    // check if project name is duplicated
    try {
      await ProjectService.getProjectByName({
        projectName: trimName,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      enqueueSnackbar(['You already have a project with the same name.'], { variant: 'warning' });
      setIsRequesting(false);
      return;
    } catch (err) {}

    try {
      // get project info by selected project name
      const selectedProjectInfo = await ProjectService.getProjectByName({
        projectName: selectedProject.label,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      // duplicate the selected project
      await ProjectService.duplicateProject({
        name: trimName,
        description: selectedProjectInfo.description,
        workapp: selectedProjectInfo.workapp,
        labelInterface: selectedProjectInfo.labelInterface,
        isPublic: selectedProjectInfo.isPublic,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      handleClose();
    } finally {
      setIsRequesting(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClickDuplicateProject();
    }
  };

  const MainButton = {
    target: {
      text: 'Next',
      onClick: () => setActiveStep('name'),
    },
    name: {
      text: 'Duplicate',
      onClick: handleClickDuplicateProject,
      isLoading: isRequesting,
    },
  };

  const SubButton = {
    target: {
      text: 'Cancel',
      onClick: handleClose,
    },
    name: {
      text: 'Previous',
      onClick: () => setActiveStep('target'),
    },
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Typography variant="headline4" themedColor="primary">
            Duplicate Project
          </Typography>
        }
        mainButton={{
          ...MainButton[activeStep],
          disabled: !selectedProject,
        }}
        subButton={SubButton[activeStep]}
        close={{
          onClose: handleClose,
          hasCloseButton: true,
        }}
      >
        <Box px={4} mb={2}>
          <Typography variant="body3">
            Create a new project with a selected project’s settings.
          </Typography>
        </Box>
        <Box mt={3} px={4} py={2} themedBackgroundColor={['grey', 50]}>
          <Box width={280}>
            <Stepper steps={Steps} activeStep={activeStep} />
          </Box>
        </Box>
        <Box mx={4} mt={3} mb={3} width={360} height={32}>
          {activeStep === 'target' ? (
            <ProjectSelect onSelect={handleChangeSelect} />
          ) : (
            <Input
              ref={inputRef}
              style={{ width: '100%' }}
              value={duplicateProjectName}
              autoFocus
              onKeyDown={handleKeyDown}
              onChange={handleChangeDuplicateProjectName}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DuplicateProjectModal;
