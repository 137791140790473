import {
  ColorVariant,
  HeatmapStyle,
  LineSeriesStyle,
  PartialTheme,
  RecursivePartial,
} from '@elastic/charts';

import { chartColors } from '../colorScale';

export const lineChartTheme = (): PartialTheme => {
  return {
    chartMargins: {
      left: 4,
      right: 4,
      top: 8,
      bottom: 2,
    },
    axes: {
      axisTitle: {
        fontSize: 12,
        fontFamily: 'Inter',
        fill: chartColors.axisTitle,
        padding: 10,
      },
      tickLabel: {
        fontSize: 12,
        fontFamily: 'Inter',
        // padding: 20,
      },
      tickLine: {
        size: 4,
      },
    },
    background: {
      color: '#fff',
    },
    lineSeriesStyle: {
      line: {
        // stroke: strokesolor,
        strokeWidth: 2,
        // opacity: 0.1
      },
      point: {
        fill: ColorVariant.Series,
        stroke: ColorVariant.None,
        radius: 1,
      },
    } as LineSeriesStyle,
  };
};

export const getHeatmapStyle = (n: number) => {
  return {
    // maxRowHeight: 400,
    // maxColumnWidth: 10,
    brushMask: {
      visible: false,
    },
    brushArea: {
      visible: true,
      fill: chartColors.brushArea,
      strokeWidth: 7,
      stroke: chartColors.brushStroke,
    },
    brushTool: {
      visible: false,
    },
    grid: {
      stroke: {
        width: 0,
      },
      cellWidth: {
        min: 50,
        max: 67,
      },
      cellHeight: {
        min: 34,
        max: 68,
      },
    },
    cell: {
      maxWidth: 'fill',
      label: {
        fontWeight: 500,
        fontFamily: 'Inter',
        textColor: chartColors.text,
        minFontSize: 10,
        maxFontSize: 12,
        visible: true,
        useGlobalMinFontSize: false,
      },
      border: {
        stroke: 'transparent',
        strokeWidth: 2,
      },
    },
    xAxisLabel: {
      fontSize: 12,
      visible: true,
      textColor: chartColors.text,
      rotation: n > 100 ? 45 : 0,
      padding: { bottom: 5, top: 5 },
    },
    yAxisLabel: {
      fontSize: 12,
      visible: true,
      textColor: chartColors.text,
      padding: { left: 7, right: 4 },
      width: { max: 200 },
    },
    maxLegendHeight: 100,
  } as RecursivePartial<HeatmapStyle>;
};

/** Distribution Charts */
export const getBarSeriesTheme = (color: string): PartialTheme =>
  ({
    barSeriesStyle: {
      // rectBorder: {
      //   visible: true,
      //   strokeOpacity: 0.9,
      //   stroke: '#FF625A',
      //   strokeWidth: 1,
      // },
      displayValue: {
        fontSize: 12,
        fontFamily: "Inter, Roboto, 'Open Sans', Arial, sans-serif",
        fontStyle: 'normal',
        fill: color,
        offsetY: 13,
      },
    },
  } as const);
export const getDistributionHistogramTheme = (color: string): PartialTheme => ({
  ...getBarSeriesTheme(color),
  chartMargins: {
    top: 27,
    right: 10,
    left: 0,
    bottom: 10,
  },
  scales: {
    histogramPadding: 0.05,
    barsPadding: 0,
  },
  axes: {
    tickLabel: {
      fontSize: 12,
      fontFamily: 'Inter',
      padding: 5,
    },
    tickLine: {
      size: 2,
    },
    axisTitle: {
      fontSize: 12,
      fill: chartColors.axisTitle,
    },
  },
});
