import { Curation } from '../types/curationTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

const requestCuration: ApiCall<
  {
    projectId: string;
    data: {
      name: string;
      filter?: string;
      curationType: string;
      labelCount: number;
      config?: { classIds?: string[] };
    };
  },
  any
> = async args => {
  const { projectId, data, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/curations/`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCurationHistories: ApiCall<
  { projectId: string; params: Params },
  { curations: { id: string }[] }
> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/curations/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCurationHistory: ApiCall<
  { projectId: string; curationId: string },
  Curation
> = async args => {
  const { projectId, curationId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/curations/${curationId}/`,
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteCurationHistory: ApiCall<
  { projectId: string; curationId: string },
  any
> = async args => {
  const { projectId, curationId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/curations/${curationId}/`,
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const thumbnailUrlCache: Record<string, string> = {};

const getCurationThumbnailUrls: ApiCall<
  {
    projectId: string;
    curationId: string;
    keys: string[];
  },
  Record<string, string>
> = async args => {
  const { projectId, curationId: curationId, isGuest, urlInfo, keys } = args;

  const unknownKeys: string[] = [];
  keys.forEach(key => {
    if (thumbnailUrlCache[key] === undefined) {
      unknownKeys.push(key);
    }
  });
  if (unknownKeys.length > 0) {
    const res = await AuthService.apiCallAfterLogin({
      method: 'post',
      url: `/projects/${projectId}/curations/${curationId}/image-urls/`,
      data: {
        keys,
      },
      hasPublicApi: false,
      isGuest,
      urlInfo,
    });

    const urls = res.data.urls;
    urls.forEach((url: string, idx: number) => {
      thumbnailUrlCache[unknownKeys[idx]] = url;
    });
  }

  const result: Record<string, string> = {};
  keys.forEach(key => {
    result[key] = thumbnailUrlCache[key];
  });
  return result;
};

export default {
  getCurationHistories,
  getCurationHistory,
  requestCuration,
  deleteCurationHistory,
  getCurationThumbnailUrls,
};
