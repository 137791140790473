import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Icon, Select, Tooltip } from '@superb-ai/ui';

import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';
import { searchResults } from '../../../filter/utils';
import { DIAGNOSIS_DISPLAY_NULL_VALUE } from '../analytics/transformer';
import ModelBadge from '../modelInfo/ModelBadge';
import DropdownSearchInput from './DropdownSearchInput';

export default function GroundTruthClassFilter({
  classList,
}: {
  classList: (string | null)[] | [];
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const filterClassList = searchResults([...classList], searchValue);
  const classOptions = filterClassList.map(c => ({
    value: c || null,
    label: c || DIAGNOSIS_DISPLAY_NULL_VALUE,
  }));

  const {
    filterBody,
    filterBody: { annotationClassIn },
    updateSelectedFilter,
  } = useDiagnosisGridFilterContext();

  function handleChangeValue(value: string[]) {
    updateSelectedFilter({
      ...filterBody,
      ...{ annotationClassIn: value.length ? value : undefined },
    });
  }

  return (
    <Box display="grid" gap={0.5}>
      <Box display="flex" gap={1} alignItems="center">
        <ModelBadge inferenceType="groundTruth" />
        <Tooltip placement="top" content={t('curate.diagnosis.filterPanel.groundTruthTooltip')}>
          <Icon icon={InfoCircleOutline} />
        </Tooltip>
      </Box>
      <Select
        multiple
        prefix={
          <DropdownSearchInput
            placeholder={t('curate.diagnosis.filterPanel.searchGTClass')}
            setSearchValue={setSearchValue}
          />
        }
        placeholder={t('curate.diagnosis.filterPanel.selectGTClass')}
        data={classOptions}
        value={annotationClassIn || []}
        onChangeValue={handleChangeValue}
      />{' '}
    </Box>
  );
}
