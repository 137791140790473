import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ChevronRight } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  Input,
  LoadingIndicator,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { CamelizedProperty } from 'humps';
import Link from 'next/link';

import { Row } from '../../../../components/elements/Row';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { getUrl } from '../../../../routes/util';
import { useImageDataByImageKey } from '../../../Curate/queries/dataQueries';
import { useDatasetQuery } from '../../../Curate/queries/datasetQueries';
import { CurateImageData } from '../../../Curate/services/DatasetService';
import { textEllipsisStyle } from '../../components/components';
import { CURATE_DATASET } from '../../path';
import { DatasetSlices } from '../../recognition-ai/list/DatasetDialog';
import { useGenerationPredictionTestContext } from './contexts/GenerationPredictionTestContext';

export const SelectTestDataDialog = ({
  state,
  setDatasetImage,
}: {
  state: ReturnType<typeof useDialogState>;
  setDatasetImage: Dispatch<SetStateAction<CamelizedProperty<CurateImageData> | null>>;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ accountName: string }>();
  const { model, isModelLoading } = useGenerationPredictionTestContext();
  const [imageKey, setImageKey] = useState('');
  const debouncedImageKey = useDebounce(imageKey, 300);
  const { data: dataset, isLoading: isDatasetLoading } = useDatasetQuery({
    datasetId: model ? model.trainingSet.referenceId : '',
    enabled: Boolean(model && model.trainingSet),
  });
  const { data: image } = useImageDataByImageKey({
    datasetId: dataset ? dataset.id : '',
    imageKey: debouncedImageKey,
    expand: ['annotations'],
    disabled: !dataset,
  });

  const isExistImage = Boolean(image && image.count > 0);

  const [isHovered, setIsHovered] = useState(false);

  if (isModelLoading || isDatasetLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Dialog state={state} style={{ width: 520 }} hideOnClickOutside={false}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.selectTestDataDialog.title')}
      </Dialog.Header>
      <Typography variant="m-regular" style={{ marginTop: -16 }}>
        {t('model.selectTestDataDialog.description')}
      </Typography>
      <Dialog.Content>
        <Box display="flex" flexDirection="column" gap={1} mb={1.5}>
          <Typography variant="m-strong">{t('model.myModelDetail.imageKey')}</Typography>
          <Input
            value={imageKey}
            onChange={e => setImageKey(e.target.value)}
            style={{ width: '100%' }}
          />
          {!isExistImage && (
            <Typography variant="s-regular" color="red" style={{ marginTop: -8 }}>
              {t('model.endpoints.invalidImageKey')}
            </Typography>
          )}
        </Box>
        <Box borderRadius="4px" backgroundColor={'gray-100'} p={2}>
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Typography variant="m-strong">
              {t('model.selectTestDataDialog.trainedDataset')}
            </Typography>
            <Row>
              <Link
                href={getUrl([params.accountName, CURATE_DATASET, model.trainingSet.referenceId])}
                target="_blank"
                rel="noopener noreferrer"
                style={{ ...textEllipsisStyle }}
              >
                <Typography
                  onMouseEnter={() => {
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                  }}
                  variant="m-regular"
                  color={'gray-400'}
                  underline={isHovered}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {dataset.name}
                </Typography>
              </Link>
              <Icon icon={ChevronRight} />
            </Row>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="m-strong">
              {t('model.selectTestDataDialog.sliceList')} (
              {model.trainingSet.trainingSetList.length})
            </Typography>
            <Box backgroundColor={'white'}>
              <DatasetSlices
                datasetId={dataset.id}
                trainingSetSlices={model.trainingSet.trainingSetList}
                height={218}
                newTab
              />
            </Box>
          </Box>
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          variant="strong-fill"
          color="primary"
          disabled={!isExistImage}
          onClick={() => {
            if (!(isExistImage && image.results && image.results[0])) {
              return;
            }
            setDatasetImage(image.results[0]);
            state.setVisible(false);
          }}
        >
          {t('shared.apply')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
