import React, { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../../contexts/RouteContext';
import ProjectService from '../../../../../../services/ProjectService';
import { ProjectData } from '../../../../../../types/projectTypes';
import { APPROVE_LABEL, MANUAL } from '../modal/SyncWithLabel/AnnotationTypeAndClass/type';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  labelProject: ProjectData;
  refetchLabelProjects: () => void;
};

export default function AutoSyncSettingDialog({
  state,
  labelProject,
  refetchLabelProjects,
}: Props) {
  const { t } = useTranslation();
  const isAutoSyncEnabled = labelProject.curateSyncSettings.operation === APPROVE_LABEL;

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  async function handleChangeSyncedSetting() {
    try {
      const { id } = await ProjectService.updateProject({
        projectId: labelProject.id,
        newInfo: {
          curateSyncSettings: {
            operation: isAutoSyncEnabled ? MANUAL : APPROVE_LABEL,
          },
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      await refetchLabelProjects();
      state.hide();
    } catch (e) {}
  }

  return (
    <Dialog
      state={state}
      aria-label="Sync with Curate dialog"
      hideOnClickOutside={false}
      style={{ width: '400px' }}
    >
      <Dialog.Header>
        {!isAutoSyncEnabled
          ? t('curate.dialogs.activateAutoSync')
          : t('curate.dialogs.deactivateAutoSync')}
      </Dialog.Header>
      <Dialog.Content>
        <Typography variant="m-regular">
          {!isAutoSyncEnabled ? (
            <Trans
              t={t}
              i18nKey="curate.dialogs.activateAutoSyncDescription"
              values={{ projectName: labelProject.name }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="curate.dialogs.deactivateAutoSyncDescription"
              values={{ projectName: labelProject.name }}
            />
          )}
        </Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void state.hide()}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={handleChangeSyncedSetting}>
          {!isAutoSyncEnabled ? t('button.activate') : t('button.deactivate')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
