import {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ChevronDown, Hide, Show } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../types/curationTypes';
import { CurateAnnotation } from '../../../../../services/DatasetService';
import SyncedProject from './image/SyncedProject';
import { getAnnotationIcon } from './utils';

type AnnotationsInfo = {
  id: string;
  _width: number;
  _height: number;
  annotation_class: string;
  annotation_type: CurateAnnotationType;
  [key: string]: any;
};

export default function AnnotationCollapse({
  annotation,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  objectAnnotationId,
  clickedAnnotation,
  handleSelectImage,
  children,
}: PropsWithChildren<{
  annotation: AnnotationsInfo;
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  objectAnnotationId?: string;
  clickedAnnotation: CurateAnnotation | null;
  handleSelectImage: () => void;
}>) {
  const [open, setOpen] = useState(!filteredAnnotationsIds.includes(annotation.id));
  const cabinetWidth = 288;
  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const isFilteredAnnotationsId = useMemo(
    () => filteredAnnotationsIds.includes(annotation.id),
    [filteredAnnotationsIds, annotation],
  );

  const IncludeObjectAnnotation = useMemo(
    () => annotation.id === objectAnnotationId,
    [annotation, annotation],
  );

  const isClickedObjectAnnotation = useMemo(
    () => annotation.id === clickedAnnotation?.id,
    [annotation, clickedAnnotation],
  );

  useEffect(() => {
    if (annotation.id === clickedAnnotation?.id) setOpen(true);
  }, [clickedAnnotation]);

  return (
    <Box
      backgroundColor={isClickedObjectAnnotation ? 'primary-100' : 'white'}
      bb="1px solid"
      borderColor="white"
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          onClick={() => {
            if (!isFilteredAnnotationsId) setOpen(!open);
            else setOpen(false);
          }}
          py={1}
          pr={1.5}
          pl={3}
        >
          <Box
            style={{ ...textEllipsisStyle, width: cabinetWidth / 2 }}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Icon
              icon={getAnnotationIcon(annotation.annotation_type)}
              color={
                isFilteredAnnotationsId
                  ? 'gray-300'
                  : !IncludeObjectAnnotation
                  ? 'gray-400'
                  : 'primary-400'
              }
            />
            <Box display="flex">
              <Typography
                variant="m-medium"
                color={
                  isFilteredAnnotationsId
                    ? 'gray-300'
                    : !IncludeObjectAnnotation
                    ? 'gray-400'
                    : 'primary-400'
                }
              >
                {annotation.annotation_class}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box
              display="flex"
              mr={2}
              onClick={e => {
                setFilteredAnnotationsIds(prev => {
                  if (prev.includes(annotation.id)) {
                    const newList = prev.filter(list => list !== annotation.id);
                    return [...newList];
                  } else return [...prev, annotation.id];
                });
                if (isClickedObjectAnnotation && !isFilteredAnnotationsId) handleSelectImage();
                e.stopPropagation();
              }}
            >
              <Icon
                icon={isFilteredAnnotationsId ? Hide : Show}
                color={isFilteredAnnotationsId ? 'gray-300' : 'gray-400'}
              />
            </Box>
            <Box
              display="flex"
              style={{
                transition: 'all 0.1s ease-in-out',
                ...(open ? {} : { transform: 'rotate(-90deg)' }),
              }}
            >
              <Icon icon={ChevronDown} color={isFilteredAnnotationsId ? 'gray-300' : 'gray-400'} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Collapse open={open}>
        {annotation._label_project_id && <SyncedProject annotationInfo={annotation} open={open} />}
        {children}
      </Collapse>
    </Box>
  );
}
