import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import ImageCategoryItem from './ImageCategoryItem';
import { convertCategorizationToObject, convertPropertyToObject } from './utils';

const ImageCategoryList: React.FC<{
  isSiesta?: boolean;
  categorization: any;
}> = props => {
  const { t } = useTranslation();
  const { isSiesta = false, categorization } = props;
  const [imageCategoryMap] = useState(
    !isSiesta
      ? (convertCategorizationToObject(categorization) as any)
      : (convertPropertyToObject(categorization) as any),
  );

  if (categorization?.type === 'free response') {
    return (
      <Box
        display="flex"
        alignItems="center"
        my={0.5}
        style={{ height: '25px', paddingLeft: '28px' }}
      >
        <Typography variant="body2" themedColor="text">{`${categorization.name} | ${t(
          'projectSettings.propertyType.freeResponse',
        )}`}</Typography>
      </Box>
    );
  }

  if (!imageCategoryMap?.root) return null;

  return (
    <ImageCategoryItem
      depth={0}
      isSiesta={isSiesta}
      imageCategoryMap={imageCategoryMap}
      imageCategory={imageCategoryMap.root}
      type={categorization?.type}
    />
  );
};

export default ImageCategoryList;
