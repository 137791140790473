import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import Route from '../../../../../components/pages/aiAdvancedFeatures/mislabelDetection/Route';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';
import TierUnion from '../../../../../union/TierUnion';

export const ProjectMislabelDetectionMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelMislabelDetectionDeprecated,
  path: 'mislabel-detection',
  title: ({ t }) => t('advancedAIFeatures.mislabelDetection.title'),
  component: Route,
  isVisible({ authInfo, featureFlags }) {
    const featureFlagValid = !!featureFlags?.mislabelDetectionBeta?.enabled;
    const authValid = isOwnerOrAdminOrSystemAdmin(authInfo) || authInfo?.isGuest || false;
    return authValid && featureFlagValid;
  },
  isEnabled({ authInfo }) {
    return !!authInfo && !TierUnion.isFree(authInfo.tier);
  },
  disabledMessage() {
    return 'This feature is not supported in your plan.';
  },
  hasLowerLevelPage: true,
});
