import * as PIXI from 'pixi.js';

import { ClusterLevel } from '../../../../filter/types';
import { SCATTER_SORTED_COLORS, SUPER_CLUSTER_COLORS_8, SUPER_CLUSTER_COLORS_16 } from '../const';

export function convertColorStringToNumber(colorString: string) {
  function isValidColorString(colorString: string) {
    const expression = /^#[0-9A-F]{6}$/i;
    return expression.test(colorString);
  }
  if (!isValidColorString(colorString)) {
    return 0x000000;
  }
  return parseInt(colorString.substr(1), 16);
}

export const pointColor = {
  query: ['#3A60FA', PIXI.utils.string2hex('#3A60FA')],
  slice: ['#FFB600', PIXI.utils.string2hex('#FFB600')],
  dataset: ['#B42A1E', PIXI.utils.string2hex('#B42A1E')],
};

export function getPointColor(hasQueryOrFilter: boolean, isSlice: boolean, isNumber = true) {
  const index = isNumber ? 1 : 0;
  if (hasQueryOrFilter) {
    return pointColor.query[index];
  }
  if (isSlice) {
    return pointColor.slice[index];
  }
  return pointColor.dataset[index];
}

export type Colors = string;
export function generateFilterDotColors(
  items: string[],
  clusterLevel: ClusterLevel,
): Record<string, Colors> {
  const colorPalette =
    clusterLevel === '2'
      ? SUPER_CLUSTER_COLORS_8
      : clusterLevel === '3'
      ? SUPER_CLUSTER_COLORS_16
      : SCATTER_SORTED_COLORS;
  const nColors = colorPalette.length;
  const colorMap = {} as Record<string, Colors>;
  for (const [i, id] of items.entries()) {
    const color = colorPalette[i % nColors];
    colorMap[id] = color;
  }
  return colorMap;
}

export function hexToDecimal(hexColor: string): number {
  const cleanHex = hexColor.replace(/^#/, ''); // Remove the '#' if it exists
  return parseInt(cleanHex, 16);
}

export function hexToRgba(hex: string, opacity: number): string {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex value
  const bigint = parseInt(hex, 16);

  // Extract the RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Ensure opacity is in the valid range [0, 1]
  const validOpacity = Math.min(1, Math.max(0, opacity));

  // Return the RGBA
  return validOpacity < 1 ? `rgba(${r}, ${g}, ${b}, ${validOpacity})` : `rgb(${r}, ${g}, ${b})`;
}
