import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuthInfo } from '../contexts/AuthContext';
import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import BillingService from '../services/BillingService';
import { Plan } from '../types/planTypes';
import UserRoleUnion from '../union/UserRoleUnion';
import { FIVE_MINUTES } from '.';

export function useSubscriptionQuery() {
  const params = useApiDefaultParams();
  const authInfo = useAuthInfo();

  return useQuery({
    queryKey: ['subscription', authInfo.accountName],
    queryFn: () =>
      BillingService.getSubscription({
        ...params,
      }),
    staleTime: FIVE_MINUTES, // We don't expect the subscription info to change often
    enabled: !authInfo.isGuest && !UserRoleUnion.isCollaborator(authInfo.role), // Data not available for guests or collaborators
  });
}

export function useAwsPaymentMutation() {
  const queryClient = useQueryClient();
  const params = useApiDefaultParams();
  const authInfo = useAuthInfo();

  return useMutation({
    mutationFn: (token: string) =>
      BillingService.enablePaymentAws({
        ...params,
        token,
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['subscription', authInfo.accountName] });
    },
  });
}

export function useCurrentPlan() {
  const { data } = useSubscriptionQuery();
  const planName = data?.tier.name;
  return planName ? (planName.toUpperCase() as Uppercase<Plan>) : null;
}

export function usePlanExpiration() {
  const { data } = useSubscriptionQuery();
  const planName = data?.tier.name;
  return planName
    ? {
        tier: planName.toLowerCase() as Plan,
        expirationDate: data?.endDate,
        isTrialPlan: Boolean(data?.trialEnd),
        trialEndDate: data?.trialEnd,
      }
    : null;
}
