import React, { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';
import { map, range, zip } from 'lodash';

import { ClickedEventProps } from '../../chartContainers/types';
import { SvgConfigScrollBarObject } from '../../config/types';
import { BarChartNameChoice } from '../../dataTypes/analyticsDataTypes';
import { BaseDatum, BaseJsonObj } from '../../userStats/types';
import BarChart from './BarChart';
import ScrollSelector from './ScrollSelector';

/**
 * Scrollable Bar Chart Component
 *  - Y axis
 *  - X Axis
 *  - Main (detail) bar chart
 *  - Full bar chart (overview), scrollable
 *  - Scroll with handles
 *
 * Design: https://app.zeplin.io/project/5c1769f15cdc42508490f36c/screen/5e7c3d2b174d7e8373d232c5
 */
const calculateScrollBars = (
  xValues: string[],
  yValues: number[],
  svgInfo: SvgConfigScrollBarObject,
) => {
  const scrollChart = { ...svgInfo, bottom: 0 };
  // uses full svgWidth
  const xScale = d3
    .scaleBand()
    .domain(xValues)
    .range([scrollChart.left, scrollChart.svgWidth - scrollChart.right])
    .paddingInner(0.5);

  // Question- why does the top range subtract chart.top margin?
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(yValues) as number])
    .range([
      scrollChart.svgHeightWithScroll,
      scrollChart.svgHeightWithScroll - scrollChart.scrollHeight + 10,
    ]);

  const scrollBars = map(zip(xValues, yValues), (d, index) => {
    const id = index;
    const x = xScale(d[0] as string);
    const y = yScale(d[1] as number);
    const width = xScale.bandwidth();
    // const width = chart.svgWidth / xValues.length;

    // @ts-ignore Object is possibly 'undefined'
    const height = scrollChart.svgHeightWithScroll - yScale(d[1] as number);
    const fill = '#fe9573';

    return {
      id,
      index,
      x,
      y,
      height,
      width,
      fill,
    };
  });
  return scrollBars;
};

interface Props {
  chartName: BarChartNameChoice;
  xValues: string[];
  yValues: number[];
  sValues: (string | number | undefined)[];
  email?: string[];
  groups?: string[];
  totalCounts: number;
  tooltipKeyMap?: BaseJsonObj;
  isScrollable: boolean;
  filter: Record<string, string[] | []>;
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigScrollBarObject;
  handleOnHover?: (index: number) => void;
  hoveredIndex: number;
  rotateXLabel?: boolean;
  hasTooltip?: boolean;
  handleClickDatum?: ClickedEventProps;
}

const BarChartScrollable: React.FC<Props> = props => {
  const {
    xValues,
    yValues,
    sValues,
    totalCounts,
    tooltipKeyMap,
    chartName,
    groups,
    isScrollable,
    filter,
    xKeyToDisplayName,
    svgInfo,
    handleOnHover,
    hoveredIndex,
    rotateXLabel,
    hasTooltip,
    handleClickDatum,
  } = props;
  const svgStyle = {
    overflow: 'visible',
    width: svgInfo.svgWidth,
    height: svgInfo.svgHeightFull,
  };

  const [isScrolling, setIsScrolling] = useState(false);
  const [sliceStart, setSliceStart] = useState(0);
  const scrollInitX = svgInfo.left;
  const [scrollX, setScrollX] = useState(svgInfo.left);
  let temporaryScrollX = scrollX;

  const scrollRef = useRef(null);

  /**
   * Upon mouse event over scroller, repositions scroll selector
   * and sets sliceStart (bar index where scroll selector starts at)
   * to render the main bar chart from sliceStart.
   */
  useEffect(
    () => {
      d3.select(scrollRef.current).call(
        // @ts-ignore this works
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        d3.drag().on('drag', handleDrag).on('end', handleMouseUp),
      );
    },
    // eslint-disable-next-line
  );

  /**
   * Given d3 selection of scroll (group), returns current x and y positions as an array.
   *
   * ex. if "transform(200, 400)" -> [200, 400]
   */
  const handleDrag = (event: { dx: number }) => {
    setIsScrolling(true);
    if (event.dx !== 0) {
      temporaryScrollX += event.dx;

      const leftBound = svgInfo.scrollLeftBound;
      const rightBound = svgInfo.scrollRightBound;
      // new way with scroll handles (4/5/2020)
      // change svg group that contains scroll and scroll handles
      // let scroll = d3.select(scrollRef.current);

      // get current Y value (will be constant)
      const currentY = svgInfo.svgHeight + 10; // getXY(scroll).y;

      if (temporaryScrollX > rightBound) {
        temporaryScrollX = rightBound;
      } else if (temporaryScrollX < leftBound) {
        temporaryScrollX = leftBound;
      }

      d3.select(scrollRef.current).attr('transform', `translate(${temporaryScrollX}, ${currentY})`);

      const newSlice = Math.abs(
        Math.floor((temporaryScrollX - svgInfo.left) / svgInfo.scrollBandWidth),
      );
      setSliceStart(newSlice);
    }
  };

  const handleMouseUp = () => {
    setScrollX(temporaryScrollX);
    setIsScrolling(false);
  };

  const getSubset = (values: BaseDatum[], indexFrom: number, indexTo: number) => {
    return values.slice(indexFrom, indexTo);
  };

  // subset of scrollBars that is selected to be rendered!
  const sliceEnd = svgInfo.sliceWidth;
  const xValuesSubset = isScrollable
    ? getSubset(xValues, sliceStart, sliceStart + sliceEnd)
    : xValues;
  const yValuesSubset = isScrollable
    ? getSubset(yValues, sliceStart, sliceStart + sliceEnd)
    : yValues;
  const sValuesSubset = isScrollable
    ? getSubset(sValues, sliceStart, sliceStart + sliceEnd)
    : sValues;
  const barsBelowScroll = range(sliceStart, sliceStart + sliceEnd);

  // const xDisplayValuesSubset = _.map(xValuesSubset, d => _.upperFirst(d));
  let groupSubset: BaseDatum[] = [];
  if (chartName === 'imageCategoryStats' && groups) {
    groupSubset = isScrollable ? getSubset(groups, sliceStart, sliceStart + sliceEnd) : groups;
  }
  // Saving for Tooltip
  // const yPercValuesSubset = _.isUndefined(yPercValues) ? undefined : yPercValues.slice(
  //   sliceStart,
  //   sliceStart + svgInfo.sliceWidth
  // );
  // const scales = getScrollBarScales(xDisplayValuesSubset, yValues, svgInfo);

  // TODO (mlimb): refactor getScrollScales from calculate Scroll bars
  const scrollBars = calculateScrollBars(xValues, yValues, svgInfo);
  const getBarChart = () => {
    return (
      <BarChart
        xValues={xValuesSubset}
        yValues={yValuesSubset}
        sValues={sValuesSubset}
        totalCounts={totalCounts}
        tooltipKeyMap={tooltipKeyMap}
        chartName={chartName}
        groups={groupSubset}
        isScrolling={isScrolling}
        filter={filter}
        xKeyToDisplayName={xKeyToDisplayName}
        svgInfo={svgInfo}
        handleOnHover={handleOnHover}
        hasTooltip={hasTooltip}
        hoveredIndex={hoveredIndex}
        rotateXLabel={rotateXLabel}
        handleClickDatum={handleClickDatum}
      />
    );
  };

  const getBarChartWithScroll = () => {
    return (
      <svg id={`bar-chart-scrollable-svg-${chartName}`} style={svgStyle}>
        <g>
          <g className="chart">
            {getBarChart()}
            <g
              key="groupScrollSelectorBox"
              transform={`translate(0, ${svgInfo.scrollBarTopPadding + 5})`}
            >
              <rect
                key="scrollSelectorBox"
                fill="#fbfbfb"
                rx={4}
                ry={4}
                x={scrollInitX - 25}
                y={svgInfo.svgHeight}
                width={svgInfo.left + svgInfo.width}
                height={svgInfo.scrollHeight + 40}
              />
              {totalCounts !== 0 &&
                scrollBars.map(d => (
                  <rect
                    key={`scrollbar-${d.id}`}
                    x={d.x}
                    y={d.y}
                    width={d.width}
                    height={d.height}
                    fill={d3.rgb(d.fill) as unknown as string}
                    opacity={barsBelowScroll.includes(d.index) ? '1' : '0.5'}
                  />
                ))}
              <rect
                key="scrollBottom"
                x={scrollInitX}
                y={svgInfo.svgHeightWithScroll}
                width={svgInfo.width}
                height={7}
                style={{
                  borderRadius: '2px',
                }}
                fill="#efefef"
                opacity={0.7}
              />
              <g
                key="groupScrollRef"
                ref={scrollRef}
                transform={`translate(${scrollInitX}, ${svgInfo.svgHeight + 10})`}
                style={{
                  cursor: 'move',
                }}
              >
                <ScrollSelector svgInfo={svgInfo} />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };
  return !isScrollable ? getBarChart() : getBarChartWithScroll();
};

export default BarChartScrollable;
