import { Trans, useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { Button, Dialog, Typography, useDialogState } from '@superb-ai/ui';

import { getUrl } from '../../../../routes/util';
import { TrainQueryKeyword } from '../../train/queries';

export const CannotDeleteModelDialog = ({
  state,
  modelId,
  endpointsCount,
}: {
  state: ReturnType<typeof useDialogState>;
  modelId: string;
  endpointsCount: number;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();

  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.cannotDeleteModelDialog.title')}
      </Dialog.Header>
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={'model.cannotDeleteModelDialog.content'}
          values={{ counts: endpointsCount }}
        />
      </Typography>
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          {...({
            as: Link,
            to: getUrl(
              [params.accountName, 'model', 'endpoints', 'list'],
              {},
              { [TrainQueryKeyword.DeployedModel]: modelId },
            ),
          } as any)}
        >
          {t('model.cannotDeleteModelDialog.mainButton')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
