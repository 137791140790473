import { Color } from '@superb-ai/ui';

export const colorPalette: { hex: string; token: Color }[] = [
  { hex: '#ff625a', token: 'primary' }, //
  { hex: '#5A7BFF', token: 'secondary' }, //
  { hex: '#ff3a28', token: 'red' }, //
  { hex: '#ff792e', token: 'orange' }, //
  { hex: '#ffde33', token: 'yellow' }, //
  { hex: '#d3f354', token: 'lime' }, //
  { hex: '#82db24', token: 'green' }, //
  { hex: '#4ae2b9', token: 'mint' }, //
  { hex: '#4ecaff', token: 'skyblue' }, //
  { hex: '#3479ff', token: 'blue' }, //
  { hex: '#702dff', token: 'violet' }, //
  { hex: '#bf36ff', token: 'purple' }, //
  { hex: '#ff4881', token: 'pink' }, //
  { hex: '#a6a6a6', token: 'cloud' }, //
];

/**
 * @param brightness '-100', '-400', '-500'과 같은 밝기
 * @returns superb-ai/ui에 정의된 컬러
 */
export function randomColorTokenByBrightness(brightness?: string) {
  return brightness
    ? (`${
        colorPalette[Math.floor(Math.random() * colorPalette.length)].token
      }-${brightness}` as Color)
    : (colorPalette[Math.floor(Math.random() * colorPalette.length)].token as Color);
}

export function randomColor() {
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
}
