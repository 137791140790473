import { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';
import { AxiosError } from 'axios';

import AnalyticsTracker from '../../../../analyticsTracker';
import { DEFAULT_ERROR } from '../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useAuthService } from '../../../../services/NewAuthService';
import { identify, identifyAndGroupOnSignUp } from '../../../../utils/SegmentIdentifyGroup';
import Policy from '../Policy';
import Title from '../Title';
import CompanyInfo from './CompanyInfo';
import { getUrlByErrorCodeOnSignUp } from './errorCodeHandler';
import UserInfo from './UserInfo';

interface UrlParams {
  region: string;
  code: string;
  tenantId: string;
  email: string;
}

const translatedParams = (params: UrlParams) => ({
  ...params,
  tenantUuid: atob(params.tenantId),
  email: atob(params.email),
});

export default function VerifiedSignUpLayout() {
  const [page, setPage] = useState<'userInfo' | 'companyInfo'>('userInfo');
  const [session, setSession] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [info, setInfo] = useState({
    givenName: '',
    familyName: '',
    password: '',
    companyName: '',
    accountName: '',
    krMarketingConsentAgreed: undefined,
    krNewsletterConsentAgreed: undefined,
  });
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const params = translatedParams(routeInfo.params as UrlParams);
  const { createAccountV2, confirmAccountV2 } = useAuthService();
  const { region, code, tenantUuid, email } = params;
  const { closeModal, openModal } = useAlertModal();
  const { t, i18n } = useTranslation();
  const utmParams = useCookies(['spb_lead'])[0]['spb_lead']?.parameters;
  const language = window.navigator.language.split('-')[0];

  const confirmAccount = async () => {
    try {
      const { data } = await confirmAccountV2({ tenantUuid, email, region, code });
      setSession(data.session);
      AnalyticsTracker.signupStepCompleted({ step: 'verification' });
    } catch (e: unknown) {
      const response = (e as AxiosError<any>).response;
      const code = response?.data.code;

      routeInfo.history.replace(getUrlByErrorCodeOnSignUp(code, params));
    }
  };

  useEffect(() => {
    if (email) identify({ email });
    if (tenantUuid && email && region && code) confirmAccount();
  }, [tenantUuid, email, region, code]);

  const changeInfo = (key: keyof typeof info, value: string) => {
    if (key === 'accountName' && value !== '' && !/^[a-z][a-z0-9_-]*$/.test(value)) {
      return;
    }

    setInfo(prev => ({ ...prev, [key]: value }));
  };

  const routeToWelcomePage = () => {
    routeInfo.history.push('/auth/welcome');
  };
  const handleSignup = async () => {
    const {
      accountName,
      password,
      companyName,
      familyName,
      givenName,
      krMarketingConsentAgreed,
      krNewsletterConsentAgreed,
    } = info;

    try {
      setIsRequesting(true);

      const { data } = await createAccountV2({
        tenantUuid,
        session,
        tenantId: accountName,
        email,
        newPassword: password,
        affiliation: companyName,
        familyName,
        givenName,
      });

      identifyAndGroupOnSignUp({
        ...info,
        email,
        country: data.country,
        plan: 'FREE',
        utmParams,
        krMarketingConsentAgreed,
        krNewsletterConsentAgreed,
      });

      AnalyticsTracker.signupStepCompleted({ step: 'account', accountId: accountName });

      await authInfo.finishLogin(data.idToken, false);

      setIsRequesting(false);
      routeToWelcomePage();
    } catch (e: any) {
      const message = e?.message ?? 'Error';

      openModal(
        DEFAULT_ERROR({
          t,
          message,
          onClickMainButton: () => {
            closeModal();
            setIsRequesting(false);
          },
        }),
      );
    }
  };

  const handleContinue = () => {
    switch (page) {
      case 'userInfo':
        AnalyticsTracker.signupStepCompleted({ step: 'name' });
        setPage('companyInfo');
        break;
      case 'companyInfo':
        handleSignup();
        break;
    }
  };

  const onClickPrevButton = () => {
    setPage('userInfo');
  };

  const pageToComponent: Record<typeof page, ReactNode> = {
    userInfo: <UserInfo handleContinue={handleContinue} info={info} changeInfo={changeInfo} />,
    companyInfo: (
      <CompanyInfo
        isRequesting={isRequesting}
        onClickPrevButton={onClickPrevButton}
        handleContinue={handleContinue}
        info={info}
        changeInfo={changeInfo}
      />
    ),
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2}>
      <Title>{t('auth.title.completeSignUp')}</Title>
      {pageToComponent[page]}
      {language !== 'ko' && <Policy />}
    </Box>
  );
}
