import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Expand, WarningFilled } from '@superb-ai/icons';
import { Box, Button, Checkbox, Icon, Typography, vars } from '@superb-ai/ui';
import { filter } from 'lodash';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';
import MathUtils from '../../../../../../../../utils/MathUtils';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import AnnotatedImage from '../../../../../../elements/AnnotatedImage/AnnotatedImage';
import CroppedAnnotatedImage from '../../../../../../elements/AnnotatedImage/CroppedAnnotatedImage';
import { Placements } from '../../../../../../elements/AnnotatedImage/type';
import { AnnotationType } from '../../../../../../types/annotationTypes';

type ExtraProps = {
  setOpenDetailIndex: (index: number) => void;
  viewAsImage: boolean;
  infoAreaHeight: number;
  totalCount: number;
};
export default function ImageCell({
  columnIndex,
  rowIndex,
  columns,
  results,
  setOpenDetailIndex,
  viewAsImage,
  infoAreaHeight,
  totalCount,
}: CellComponentProps<ExtraProps>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const result = results[currentIndex];
  const [imageSize, setImageSize] = useState<[number, number]>(); // [width, height]
  const [hover, setHover] = useState(false);
  const { selectedData, setSelectedData, selectedAllData } = useActionContext();
  const { t } = useTranslation();

  const isSelected = useMemo(() => {
    if (!result) return false;
    return filter(selectedData, ['id', result.id]).length > 0 || selectedAllData;
  }, [selectedData, result, selectedAllData]);

  const thumbnailUrl = result?.imageThumbnailUrl;

  useEffect(() => {
    if (!thumbnailUrl) return;
    const img = new Image();
    img.src = thumbnailUrl;
    img.onload = function () {
      setImageSize([img.naturalWidth, img.naturalHeight]);
    };
  }, [thumbnailUrl]);

  if (!result || currentIndex > (totalCount || 0) - 1) return <></>;

  const annotationValue = result.annotation?.annotationValue;
  const predictionValue = result.prediction?.predictionValue;

  const annotationRoi = result.annotation?.roi;
  const predictionRoi = result.prediction?.roi;

  const minX = Math.min(annotationRoi?.x ?? Infinity, predictionRoi?.x ?? Infinity);
  const minY = Math.min(annotationRoi?.y ?? Infinity, predictionRoi?.y ?? Infinity);

  const roundedConfidence = result.prediction
    ? MathUtils.round(result.prediction?.confidence, 2)
    : '-';
  const roundedIou = result.iou ? MathUtils.round(result.iou, 2) : '-';

  const roi = {
    x: minX,
    y: minY,
    width:
      Math.max(
        (annotationRoi?.x ?? 0) + (annotationRoi?.width ?? 0),
        (predictionRoi?.x ?? 0) + (predictionRoi?.width ?? 0),
      ) - minX,
    height:
      Math.max(
        (annotationRoi?.y ?? 0) + (annotationRoi?.height ?? 0),
        (predictionRoi?.y ?? 0) + (predictionRoi?.height ?? 0),
      ) - minY,
  };

  function toggleSelect() {
    if (!thumbnailUrl) return;
    if (isSelected) {
      const newSelectedData = selectedData?.filter(data => {
        return data.id !== result.id;
      });
      if (newSelectedData?.length) setSelectedData([...newSelectedData]);
      else setSelectedData([]);
      return;
    }
    if (selectedData?.length)
      setSelectedData([...selectedData, { id: result.id, thumbnailUrl, imageId: result.imageId }]);
    else setSelectedData([{ id: result.id, thumbnailUrl, imageId: result.imageId }]);
  }

  const isPredictionPositionTop =
    annotationValue?.y + annotationValue?.height > predictionValue?.y + predictionValue?.height;

  const annotations = [
    {
      class: result.annotation?.annotationClass,
      type: (typeof annotationValue === 'string' ? 'mask' : 'box') as AnnotationType, // TODO: branch by iouType in diagnosis detail
      coordinate: annotationValue,
      base64Data: annotationValue,
      roi: annotationRoi,
      id: result.annotation?.id,
      color: typeof annotationValue === 'string' ? '#4ae2b9' : vars.color.mint,
      chipPlacement: `${isPredictionPositionTop ? 'bottom' : 'top'}-left` as Placements,
    },
    {
      class: result.prediction?.predictionClass,
      type: (typeof predictionValue === 'string' ? 'mask' : 'box') as AnnotationType,
      coordinate: predictionValue,
      base64Data: predictionValue,
      roi: predictionRoi,
      id: result.id,
      color: typeof predictionValue === 'string' ? '#ff625a' : vars.color.primary,
      chipPlacement: `${isPredictionPositionTop ? 'top' : 'bottom'}-left` as Placements,
    },
  ];

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        style={{
          height: `calc(100% - ${infoAreaHeight}px)`,
        }}
        backgroundColor="gray-100"
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {thumbnailUrl ? (
          <>
            {imageSize &&
              (viewAsImage ? (
                <AnnotatedImage
                  srcUrl={thumbnailUrl}
                  alt={result.id}
                  annotations={annotations}
                  originalImageSize={[
                    result.originalImageSize?.width || 100,
                    result.originalImageSize?.height || 100,
                  ]}
                />
              ) : (
                <CroppedAnnotatedImage
                  srcUrl={thumbnailUrl}
                  alt={result.id}
                  annotations={annotations}
                  originalImageSize={[
                    result.originalImageSize?.width || 100,
                    result.originalImageSize?.height || 100,
                  ]}
                  roi={roi}
                />
              ))}
            <Box
              position="absolute"
              left="0"
              top="0"
              bottom="0"
              right="0"
              backgroundColor="gray-opacity-300"
              style={{
                visibility: hover || isSelected ? 'visible' : 'hidden',
                ...(isSelected ? { outline: '3px solid #ff625a', outlineOffset: '-3px' } : {}),
              }}
              onClick={toggleSelect}
              cursor="pointer"
            >
              <Box position="absolute" style={{ top: 4, left: 4 }} mr="auto">
                <Checkbox disabled={!thumbnailUrl} value={isSelected} onClick={toggleSelect} />
              </Box>
              {hover && (
                <Box position="absolute" style={{ bottom: 4, right: 4 }} ml="auto">
                  <Button
                    variant="stroke"
                    color="primary"
                    size="s"
                    disabled={!thumbnailUrl}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDetailIndex(currentIndex);
                    }}
                  >
                    <Icon icon={Expand} />
                    {t('curate.button.detail')}
                  </Button>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={0.5}
            color="gray-300"
          >
            <Icon size="24px" icon={WarningFilled} />
            <Typography variant="m-medium">{t('status.notFound')}</Typography>
          </Box>
        )}
      </Box>
      <Box
        borderBottom="1px solid"
        borderColor="gray-100"
        px={0.25}
        py={0.5}
        style={{ height: infoAreaHeight }}
      >
        <Typography
          variant="m-strong"
          color="gray-300"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {result.split === 'TRAIN'
            ? t('curate.diagnosis.grid.train')
            : t('curate.diagnosis.grid.validation')}
        </Typography>
        <br />
        <Typography
          variant="m-regular"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {columns < 5 ? (
            `${t('curate.diagnosis.grid.confidence')}: ${roundedConfidence} / ${t(
              'curate.diagnosis.grid.iou',
            )}: ${roundedIou}`
          ) : (
            <>
              {t('curate.diagnosis.grid.confidence')}: {roundedConfidence} <br />
              {t('curate.diagnosis.grid.iou')}: {roundedIou}
            </>
          )}
        </Typography>
      </Box>
    </>
  );
}
