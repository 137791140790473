import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, useDialogState } from '@superb-ai/ui';

import Tutorial from '../../../../../components/elements/Tutorial';
import TrialFormDialog from '../../../../../components/HubspotForms/CurateHubspotFormDialog';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../../../consts/ModalMessage';
import AnalyticsTracker from '../../../../../analyticsTracker';
import { Page } from '../../../../../menu';
import { getUrl } from '../../../../../routes/util';
import { SampleDataset } from '../../../types/routeTypes';
import ActionTutorialHeader from './ActionTutorialHeader';
import { getTutorialContents } from './tutorialContents';

export default function Layout() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const eventId = 'suite-curate-contact-sales-form';
  const { openModal, closeModal } = useAlertModal();
  const state = useDialogState();

  return (
    <Page boxProps={{ p: 0 }}>
      <ActionTutorialHeader
        title={t('curate.tutorial.freeUserHeader.title')}
        description={<Trans t={t} i18nKey={'curate.tutorial.freeUserHeader.description'} />}
        action={
          <Box display="flex" gap={1}>
            <Button
              color="gray"
              size="l"
              onClick={() => {
                AnalyticsTracker.contactUsClicked({ referrer: eventId, accountId: accountName });
                state.show();
              }}
            >
              {t('text.contactUs')}
            </Button>
            <Button
              variant="strong-fill"
              color="gray"
              size="l"
              onClick={() => {
                history.push(getUrl([accountName, 'curate', SampleDataset]));
              }}
            >
              {t('curate.tutorial.button.exploreSampleDataset')}
            </Button>
          </Box>
        }
      />
      <Tutorial contents={getTutorialContents(t, i18n.language)} />
      <TrialFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
    </Page>
  );
}
