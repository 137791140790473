import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../../../contexts/RouteContext';
import ProjectService from '../../../../../../../services/ProjectService';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import NameInput from '../../../../../elements/NameInput/NameInput';

export default function ProjectNameAndDescription({
  projectName,
  setProjectName,
  projectDescription,
  setProjectDescription,
  setIsProjectNameValid,
}: {
  projectName: string;
  setProjectName: (value: string) => void;
  projectDescription: string;
  setProjectDescription: (value: string) => void;
  setIsProjectNameValid: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const { datasetInfo } = useDatasetContext();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  return (
    <>
      <Typography variant="m-regular">
        {t('curate.datasets.syncWithNewLabelProject.steps.project.name')}
      </Typography>
      <NameInput
        target="project"
        name={projectName}
        setName={setProjectName}
        onSearchName={async (name: string) => {
          await ProjectService.getProjectByName({
            projectName: name,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          });
        }}
        setIsValid={setIsProjectNameValid}
        placeholder={t('curate.datasets.syncWithNewLabelProject.steps.project.placeholder', {
          datasetName: datasetInfo?.name,
        })}
      />
      <Box mt={1} />
      <Typography variant="m-regular">
        {t('curate.datasets.syncWithNewLabelProject.steps.project.description')}
      </Typography>
      <Input
        value={projectDescription}
        onChange={e => setProjectDescription(e.target.value)}
        placeholder={t('curate.datasets.syncWithNewLabelProject.steps.project.placeholder', {
          datasetName: datasetInfo?.name,
        })}
      />
    </>
  );
}
