import React from 'react';

import { Divider } from '@mui/material';
import { Box } from '@superb-ai/ui';

const SkeletonProgressContainer = () => {
  const exportSkeleton = (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Box
          display="flex"
          alignItems="center"
          backgroundColor="gray-150"
          style={{ height: '16px', width: '83px', borderRadius: '32px' }}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          backgroundColor="gray-150"
          style={{ width: '217px', height: '16px', borderRadius: '32px' }}
        />
      </Box>
    </>
  );

  const appliedFilterSkeleton = (
    <>
      {Array.from({ length: 4 }, (v, k) => (
        <Box
          key={`outer-box-${k}`}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box
            display="flex"
            alignItems="center"
            backgroundColor="gray-150"
            style={{ height: '8px', width: '100%', borderRadius: '32px' }}
          />
        </Box>
      ))}
    </>
  );

  return (
    <Box mb={4}>
      <Box pt={1} px={1} boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)">
        <Box
          style={{
            height: '40px',
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="gray-100"
          borderRadius="2px"
        />

        <Box display="flex" alignItems="center" style={{ height: '103px' }}>
          <Box ml={3} mr={7} style={{ height: '46px', flex: 1 }}>
            {exportSkeleton}
          </Box>
          <Divider style={{ width: '1px', height: '48px', backgroundColor: '#DCDCDC' }} />
          <Box mr={3} ml={7} style={{ height: '46px', flex: 1 }}>
            {exportSkeleton}
          </Box>
        </Box>
      </Box>

      <Box boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)">
        <Box display="flex" alignItems="center" style={{ height: '195px', background: '#FBFBFB' }}>
          <Box ml={3} mr={7} mt={3} style={{ flex: 1 }}>
            {appliedFilterSkeleton}
          </Box>
          <Divider style={{ width: '1px', height: '128px', backgroundColor: '#DCDCDC' }} />
          <Box ml={7} mr={3} mt={3} style={{ flex: 1 }}>
            {appliedFilterSkeleton}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonProgressContainer;
