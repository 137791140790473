import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Box, Button, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { concat, filter, includes, isEmpty, map, omit, pickBy } from 'lodash';

import { DELETE_DATASET } from '../../../../consts/ModalMessage';
import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { getUrl } from '../../../../routes/util';
import CommandsService from '../../../../services/CommandsService';
import ProjectsService from '../../../../services/ProjectsService';
import ParamUtils from '../../../../utils/ParamUtils';
import { toastEventManager } from '../../../../utils/ToastUtils';
import TablePagination from '../../../elements/TablePagination';
import AssignToProjectDialog from '../AssignToProjectDialog';
import DatasetTableView from './DatasetTableView';

const DatasetContainer: React.FC = () => {
  const { t } = useTranslation();
  const [isLoadingDatasets, setIsLoadingDatasets] = useState(false);
  const [isAssignToProjectDialogOpen, setIsAssignToProjectDialogOpen] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('created_at');
  const [isDesc, setIsDesc] = useState(true);
  const [isAllDatasetsChecked, setIsAllDatasetsChecked] = useState(false);
  const [checkedDatasets, setCheckedDatasets] = useState<string[]>([]);
  const [params, setParams] = useState<Record<string, any>>({});

  const { openModal, closeModal } = useAlertModal();
  const assetsInfo = useAssetsInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const commandContext = useLabelCommandContext();
  const isNoDatasetChecked = isEmpty(assetsInfo.datasets) || isEmpty(checkedDatasets);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = lokiFlag;

  const baseUrl = [routeInfo.urlMatchInfo.accountName, 'label', 'data'];

  const load = async () => {
    if (!assetsInfo) return;
    const sortParams = pickBy(
      { ordering: !isDesc ? orderBy : '-'.concat(orderBy) },
      v => !isEmpty(v),
    );
    const otherParams = ParamUtils.getApiParamsForDataFilter(routeInfo.params);
    const formattedParams = { ...sortParams, ...otherParams };
    setParams(formattedParams);

    setIsLoadingDatasets(true);
    try {
      await assetsInfo.updateDatasets(formattedParams);
    } finally {
      setIsLoadingDatasets(false);
    }
  };

  useEffect(() => {
    setCheckedDatasets([]);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeInfo.params, orderBy, isDesc]);

  const deleteDataset = async () => {
    const apiParams: Record<string, any> = isAllDatasetsChecked
      ? omit(params, ['ordering', 'pageSize', 'page'])
      : {
          groupIn: checkedDatasets,
        };
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'ASSETS_DELETE',
      params: apiParams,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
  };

  const reloadAfterDelete = useCallback(toast => {
    if (toast?.options?.state === 'endProgress') {
      setCheckedDatasets([]);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toastEventManager.on('CHANGE_STATE', reloadAfterDelete);
    return () => {
      toastEventManager.off('CHANGE_STATE', reloadAfterDelete);
    };
  }, [reloadAfterDelete]);

  const handleClickGlobalCheckbox = () => {
    if (isAllDatasetsChecked) {
      setIsAllDatasetsChecked(false);
      setCheckedDatasets([]);
      return;
    }

    if (checkedDatasets.length === assetsInfo.datasets.length) {
      setCheckedDatasets([]);
      return;
    }
    setCheckedDatasets(map(assetsInfo.datasets, dataset => dataset.group));
  };

  const handleClickCheckbox = (event: React.MouseEvent, groupName: string) => {
    event.stopPropagation();
    if (includes(checkedDatasets, groupName)) {
      if (isAllDatasetsChecked) {
        setIsAllDatasetsChecked(false);
      }
      setCheckedDatasets(filter(checkedDatasets, checkedItem => checkedItem !== groupName));
      return;
    }
    setCheckedDatasets(concat(checkedDatasets, [groupName]));
  };

  const handleClickSelectAllDatasets = (isAllDatasetsChecked: boolean) => () => {
    setIsAllDatasetsChecked(isAllDatasetsChecked);
    if (!isAllDatasetsChecked) {
      setCheckedDatasets([]);
    }
  };

  const handleClickDataset = (id: string) => {
    const nextUrl = getUrl(
      baseUrl,
      {},
      {
        dataset: id,
        project: '[ all projects ]',
      },
    );
    routeInfo.history.push(nextUrl);
  };

  const handleRequestSort = (property: string, order: 'asc' | 'desc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const handleClickAddToProject = () => {
    setIsAssignToProjectDialogOpen(true);
  };

  const handleCloseAssignToProjectDialog = () => {
    setIsAssignToProjectDialogOpen(false);
  };

  const handleClickDeleteItem = () => {
    openModal({
      ...DELETE_DATASET({
        t,
        count: isAllDatasetsChecked ? assetsInfo.totalDatasetCount : checkedDatasets.length,
      }),
      mainButton: {
        text: t('button.delete'),
        onClick: () => {
          deleteDataset();
          closeModal();
        },
      },
      subButton: { text: t('button.cancel'), onClick: closeModal },
    });
  };

  const handleSearchProject = async (inputValue: string) => {
    const projectList = await ProjectsService.getProjects({
      params: {
        nameIcontains: inputValue,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const searchProjectIds = {} as Record<string, string>;
    const resultProjects = map(projectList.results, project => {
      searchProjectIds[project.name] = project.id;
      return { label: project.name, value: project.name };
    });

    return resultProjects;
  };

  return (
    <>
      <MUI.Grid container direction="column" spacing={2}>
        <MUI.Grid item xs={12}>
          <MUI.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="50px"
          >
            <Typography variant="body4" themedColor="textDefault">
              {t('charts.totalCount', {
                objectCount: isLoadingDatasets
                  ? '...'
                  : t('charts.itemCount', { count: assetsInfo.totalDatasetCount }),
              })}
            </Typography>

            <Box display="flex" alignItems="center" gap="8px">
              <Button
                variant="shadow"
                color="primary"
                IconAdornment="listAssign"
                disabled={isNoDatasetChecked}
                onClick={handleClickAddToProject}
              >
                {t('data.button.assignToProject')}
              </Button>
              <Button
                variant="shadow"
                color="primary"
                IconAdornment="trash"
                disabled={isNoDatasetChecked}
                onClick={handleClickDeleteItem}
              >
                {t('data.button.deleteItem')}
              </Button>
            </Box>
          </MUI.Box>

          <DatasetTableView
            onClickDataset={handleClickDataset}
            onClickCheckbox={handleClickCheckbox}
            onClickGlobalCheckbox={handleClickGlobalCheckbox}
            onClickSelectAllAssets={handleClickSelectAllDatasets}
            checkedDatasets={checkedDatasets}
            isAllDatasetsChecked={isAllDatasetsChecked}
            isLoadingDatasets={isLoadingDatasets}
            setIsLoadingDatasets={setIsLoadingDatasets}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            variant="page"
          />
        </MUI.Grid>
        <TablePagination totalCount={assetsInfo.totalDatasetCount} />

        <AssignToProjectDialog
          isAllChecked={isAllDatasetsChecked}
          variant="dataset"
          checkedList={checkedDatasets}
          isOpen={isAssignToProjectDialogOpen}
          onClose={handleCloseAssignToProjectDialog}
          params={params}
          totalCount={assetsInfo.totalDatasetCount}
          handleSearch={handleSearchProject}
        />
      </MUI.Grid>
    </>
  );
};

export default DatasetContainer;
