import { useTranslation } from 'react-i18next';

import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import AnalyticsTracker from '../analyticsTracker';
import { NAME_CHANGE_SUCCESS, NAME_DUPLICATE } from '../consts/SnackbarMessage';
import { useAuthInfo } from '../contexts/AuthContext';
import { useRouteInfo } from '../contexts/RouteContext';
import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import CustomAutoLabelService from '../services/CustomAutoLabelService';
import { CustomAutoLabelType } from '../types/customAutoLabelTypes';
import { addDataToPagedQueryData } from './queryDataUtil';

export const CustomAutoLabelsQueryKey = 'custom-auto-labels';

export function useGetCustomAutoLabelsQuery() {
  const params = useApiDefaultParams();
  const routeInfo = useRouteInfo();
  const projectId = routeInfo.urlMatchInfo.projectId;
  return useInfiniteQuery(
    [CustomAutoLabelsQueryKey, projectId],
    ({ pageParam = 1 }) =>
      CustomAutoLabelService.getCustomAutoLabels({
        ...params,
        projectId,
        page: pageParam,
      }),
    {
      getNextPageParam: ({ count }, pages) =>
        count > pages.length * 10 ? pages.length + 1 : undefined,
    },
  );
}

export function useChangeCustomAutoLabelNameMutation(props: { customAutoLabel: { id: string } }) {
  const { t } = useTranslation();
  const { customAutoLabel } = props;
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const params = useApiDefaultParams();

  const fetcher = ({ name }: { name: string }) =>
    CustomAutoLabelService.updateCustomAutoLabel({
      ...params,
      projectId: routeInfo.urlMatchInfo.projectId,
      customAutoLabelId: customAutoLabel.id,
      name,
    });

  return useMutation(fetcher, {
    onError: (err: Error) => {
      if (err.message === 'Duplicated') {
        enqueueSnackbar(NAME_DUPLICATE({ t, name: 'Name' }), { variant: 'warning' });
      }
    },
    onSuccess: () => {
      enqueueSnackbar(NAME_CHANGE_SUCCESS({ t }), {
        variant: 'success',
      });
    },
  });
}

export function useCancelCustomAutoLabelRequestMutation(props: {
  customAutoLabel: { id: string };
}) {
  const { customAutoLabel } = props;
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const params = useApiDefaultParams();

  const fetcher = () =>
    CustomAutoLabelService.deleteCreateCustomAutoLabel({
      ...params,
      projectId: routeInfo.urlMatchInfo.projectId,
      customAutoLabelId: customAutoLabel.id,
    });

  return useMutation(fetcher, {
    onError: (err: Error) => {
      if (err.message === 'Duplicated') {
        enqueueSnackbar('Request already in progress', {
          variant: 'warning',
        });
      }
    },
    onSuccess: () => {
      enqueueSnackbar('Request canceled', {
        variant: 'success',
      });
    },
  });
}

export function useArchiveCustomAutoLabelMutation(props: { customAutoLabel: { id: string } }) {
  const { customAutoLabel } = props;
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const params = useApiDefaultParams();

  const fetcher = () =>
    CustomAutoLabelService.archiveCreateCustomAutoLabel({
      ...params,
      projectId: routeInfo.urlMatchInfo.projectId,
      customAutoLabelId: customAutoLabel.id,
    });

  return useMutation(fetcher, {
    onSuccess: () => {
      enqueueSnackbar('Custom Auto-Label has been removed.', {
        variant: 'success',
      });
    },
  });
}

interface CreateCALProps {
  name: string;
  selectedHistory: { id: string; labelCount: number } | null;
  onSuccess?: UseMutationOptions<CustomAutoLabelType | undefined>['onSuccess'];
}

export function useCreateCustomAutoLabelMutation(props: CreateCALProps) {
  const { t } = useTranslation();
  const { name, selectedHistory, onSuccess } = props;
  const queryClient = useQueryClient();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const params = useApiDefaultParams();

  const projectId = routeInfo.urlMatchInfo.projectId;

  const fetcher = async () => {
    if (!selectedHistory) {
      return;
    }

    const res = await CustomAutoLabelService.requestCreateCustomAutoLabel({
      ...params,
      projectId,
      name,
      exportHistoryId: selectedHistory.id,
    });

    AnalyticsTracker.createCustomAutoLabelAiInitiated({
      accountId: authInfo?.accountName ?? '',
      // Optional로 되어있는데, 값을 안넣어주면 에러 발생
      // baseline_model: '',
      referrer: 'cal',
      exportId: selectedHistory.id,
      labelCount: selectedHistory.labelCount,
    });

    enqueueSnackbar(t('autoLabel.cal.createSuccess'), {
      persist: false,
      variant: 'success',
    });

    return res;
  };

  return useMutation(fetcher, {
    onError: (err: Error) => {
      if (err.message === 'Duplicated') {
        enqueueSnackbar(NAME_DUPLICATE({ t, name: 'Name' }), { variant: 'warning' });
      }
    },
    onSuccess: (data, variables, context) => {
      if (data) {
        addDataToPagedQueryData({
          queryClient,
          data,
          queryKey: [CustomAutoLabelsQueryKey, projectId],
        });
      }
      onSuccess?.(data, variables, context);
    },
  });
}

export function useCustomAutoLabelDownloadStatus({
  customAutoLabel,
}: {
  customAutoLabel: { id: string; state: string };
}) {
  const params = useApiDefaultParams();
  const routeInfo = useRouteInfo();
  return useQuery(
    ['custom-auto-label-download-status', customAutoLabel?.id],
    () =>
      CustomAutoLabelService.getCustomAutoLabelDownloadStatus({
        ...params,
        projectId: routeInfo.urlMatchInfo.projectId,
        customAutoLabelId: customAutoLabel?.id,
      }),
    {
      enabled: customAutoLabel?.state === 'READY',
      refetchInterval: data => (data?.status === 'PROCESSING' ? 10000 : false),
    },
  );
}
