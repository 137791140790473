import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MinusCircle } from '@superb-ai/icons';
import { Button, Icon, Tooltip } from '@superb-ai/ui';

import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { DiagnosisDetail } from '../../../../../services/DiagnosisModelService';
import DeactivateDiagnosisModal from '../../modal/DeactivateDiagnosisModal';

export default function DeactivateDiagnosisButton(
  props: {
    selectedDiagnosis: DiagnosisDetail;
    showIcon?: boolean;
  } & React.ComponentProps<typeof Button>,
) {
  const { t } = useTranslation();
  const { selectedDiagnosis, showIcon, ...otherProps } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { showPublicDatasets } = usePublicDatasetContext();

  function TooltipMessage() {
    if (showPublicDatasets) {
      return undefined;
    }
    return t('curate.diagnosis.text.clickDeactivateToRemoveDiagnosis');
  }

  return (
    <>
      {isModalOpen && (
        <DeactivateDiagnosisModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          diagnosis={selectedDiagnosis}
        />
      )}
      <Tooltip content={TooltipMessage()} placement="bottom" hideOnEmptyContent>
        <span>
          <Button
            variant="stroke"
            color="primary"
            onClick={() => setIsModalOpen(true)}
            disabled={!selectedDiagnosis.id || showPublicDatasets}
            {...otherProps}
          >
            {showIcon && <Icon icon={MinusCircle} color="primary" size="m" />}
            {t('curate.diagnosis.action.deactivateModel')}
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
