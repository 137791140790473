import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import NewProject from '../../components/pages/newProject';
import { isOwnerOrAdminOrSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu';

export const NewProjectMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelNewProject, // not sure this is used
  path: 'new_project',
  title: 'New Project',
  component: NewProject,
  isVisible: false,
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});

export const EditProjectMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelEditProject, // not sure this is used
  path: 'edit_project',
  title: 'Edit Project Configuration',
  component: NewProject,
  isVisible: false,
  isEnabled({ authInfo, project }) {
    // if (project?.curateDatasetId) return false;
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  redirectTo({ authInfo, project }) {
    if (!authInfo || !project || !project.curateDatasetId) return undefined;
    return `/${authInfo.accountName}/curate/dataset/${project.curateDatasetId}/settings#edit-synced-label-project=${project.id}`;
  },
});
