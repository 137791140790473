import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Clear } from '@superb-ai/icons';
import { Box, RadioButtons } from '@superb-ai/norwegian-forest';
import { IconButton } from '@superb-ai/ui';
import { assign, size } from 'lodash';
import { useSnackbar } from 'notistack';

import { LABELS_DO_NOT_EXIST, UNKNOWN_ERROR } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';
import WorkappUnion from '../../../../union/WorkappUnion';
import ParamUtils from '../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../utils/router-utils';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import ExportDialogStatusBoard from './ExportDialogStatusBoard';

const useStyles = makeStyles(() => ({
  select: {
    marginTop: '20px',
    border: '1px solid #000',
    borderRadius: '5px',
    width: '180px',
    fontSize: '12px',
    '& .MuiInputBase-inputSelect': {
      paddingRight: 0,
    },

    '& .MuiSelect-icon': {
      width: '15px',
      padding: '2px 4px',
    },
  },
  formControl: {
    width: '240px',
    textAlign: 'left',
    marginTop: '20px',
    fontSize: '13px',
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  formControlLabel: {
    marginRight: 0,
  },
  overflowVisible: {
    overflowY: 'visible',
    '& .MuiDialog-paper': {
      overflowY: 'visible',
    },
  },
}));

interface ChangeStatusDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  checkedLabels: any[];
  setCheckedLabels: any;
  totalCount: number;
  getLabels: any;
  isAllLabelsChecked: boolean;
}

const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = props => {
  const { t } = useTranslation();
  const {
    isOpen,
    setIsOpen,
    checkedLabels,
    setCheckedLabels,
    totalCount,
    getLabels,
    isAllLabelsChecked,
  } = props;

  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const urlSearchParams = useSearchParams();
  const { tagIds } = projectInfo;

  const classes = useStyles();
  const [status, setStatus] = useState<'' | 'initialized' | 'submitted' | 'skipped'>('');
  const [labels, setLabels] = useState('keep');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const commandContext = useLabelCommandContext();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };

  const statusToExportDialogKey = {
    initialized: 'labelingInProgressCount',
    skipped: 'skippedLabelsCount',
    submitted: 'submittedLabelsCount',
  } as const;

  const statusToCommandType = {
    initialized: 'LABELS_INITIALIZE',
    skipped: 'LABELS_SKIP',
    submitted: 'LABELS_SUBMIT',
  } as const;

  const filterApiParams = useMemo(
    () =>
      ParamUtils.getApiParamsForFilter({
        filterParams: urlSearchParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      }),
    [routeInfo.params, tagIds],
  );

  const initVariable = () => {
    setStatus('');
    setLabels('keep');
  };

  const handleClickAction = async () => {
    if (status === '') return;

    const actionParams = !isAllLabelsChecked
      ? { idIn: checkedLabels }
      : { ...filterApiParams, ...searchParams };
    const actionInfo = {};

    if (labels === 'delete') {
      assign(actionInfo, { resetInfo: true });
    } else if (labels === 'keepAutoLabel') {
      assign(actionInfo, { applyAutoLabel: true });
    }

    setIsLoading(true);
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: statusToCommandType[status],
        projectId: routeInfo.urlMatchInfo.projectId,
        params: actionParams,
        actionInfo,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
      }
      enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
    }
    setIsLoading(false);

    setIsOpen(false);
    initVariable();
    setCheckedLabels([]);
    getLabels();
  };

  const handleClickClose = () => {
    setIsOpen(false);
    initVariable();
  };

  const handleSelectStatus = (
    e: MUI.SelectChangeEvent<'skipped' | 'submitted' | 'initialized'>,
  ) => {
    setStatus(e.target.value as 'skipped' | 'submitted' | 'initialized');
  };

  return (
    <MUI.Dialog open={isOpen} fullWidth maxWidth="xs">
      <MUI.Box height="0px" pt="10px" pr="10px" textAlign="right">
        <IconButton variant="text" icon={Clear} size="m" onClick={handleClickClose} />
      </MUI.Box>
      <MUI.DialogTitle>Change Status</MUI.DialogTitle>
      <MUI.DialogContent className={classes.overflowVisible}>
        {isLoading ? (
          <CircularProgressBox
            boxProps={{
              height: '71px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        ) : (
          <MUI.Box textAlign="center">
            <MUI.Box color="#ff625a" textAlign="center">
              <MUI.Typography variant="subtitle1" color="inherit">
                {t('labels.changeStatus.labelsSelected', {
                  labelCount: isAllLabelsChecked ? totalCount : size(checkedLabels),
                })}
              </MUI.Typography>
            </MUI.Box>
            <MUI.Select
              variant="standard"
              className={classes.select}
              value={status}
              displayEmpty
              onChange={handleSelectStatus}
            >
              <MUI.MenuItem value="">
                <em>{t('labels.changeStatus.selectStatus')}</em>
              </MUI.MenuItem>
              <MUI.MenuItem value="initialized">
                {t('labels.changeStatus.changeToInProgress')}
              </MUI.MenuItem>
              <MUI.MenuItem value="submitted">
                {t('labels.changeStatus.changeToSubmitted')}
              </MUI.MenuItem>
              <MUI.MenuItem value="skipped">
                {t('labels.changeStatus.changeToSkipped')}
              </MUI.MenuItem>
            </MUI.Select>

            <Box minHeight="70px" mt={3} mb={2}>
              {status && (
                <ExportDialogStatusBoard
                  checkedLabels={checkedLabels}
                  filterApiParams={filterApiParams}
                  isAllLabelsChecked={isAllLabelsChecked}
                  showChangeTo={statusToExportDialogKey[status]}
                />
              )}
            </Box>

            {status === 'initialized' && (
              <>
                <MUI.Box color="#ff9772">
                  <MUI.Typography color="inherit" display="inline">
                    *{t('labels.changeStatus.assigneeInformation')}
                  </MUI.Typography>
                </MUI.Box>
                <Box mt={2}>
                  <RadioButtons
                    value={labels}
                    onChange={setLabels}
                    options={[
                      { label: t('labels.changeStatus.keepExistingAnnotations'), value: 'keep' },
                      !WorkappUnion.isSiesta(projectInfo.project.workapp)
                        ? {
                            label: t('labels.changeStatus.keepAutolabeledAnnotationOnly'),
                            value: 'keepAutoLabel',
                          }
                        : [],
                      { label: t('labels.changeStatus.deleteAllAnnotations'), value: 'delete' },
                    ].flat()}
                  />
                </Box>
              </>
            )}
          </MUI.Box>
        )}
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button
          variant="contained"
          color="primary"
          onClick={handleClickAction}
          disabled={isLoading}
        >
          {t('button.apply')}
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
};

export default ChangeStatusDialog;
