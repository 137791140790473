import { useHistory } from 'react-router';

import { Box } from '@superb-ai/ui';
import { isEmpty } from 'lodash';

import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { GRID_VIEW, SCATTER_VIEW } from '../../../../types/viewTypes';
import { getView, routeView } from '../../../../utils/routeUtils';
import ProjectionFilter from './components/ProjectionFilter';
import { AnnotationFilterSchema } from './types';

export default function ObjectProjectionFilter() {
  const { selectedProjectionIn, appliedFilters, setAppliedFilters } = useObjectFilterContext();
  const history = useHistory();
  const view = getView(history);
  const handleReset = () => {
    const { projection_in: _projection_in, ...otherAppliedFilters } = appliedFilters || {};
    // TODO: type error 아래 처럼 해결했는데 괜찮은지 확인 필요
    setAppliedFilters({
      ...(otherAppliedFilters as AnnotationFilterSchema),
    });
  };

  const hasSelectedArea = selectedProjectionIn !== undefined && !isEmpty(selectedProjectionIn);
  const isApplied = !!appliedFilters?.projection_in;

  const handleReturn = () => {
    routeView(history, SCATTER_VIEW);
  };

  return isApplied || hasSelectedArea ? (
    <>
      <ProjectionFilter
        isApplied={isApplied}
        handleReset={handleReset}
        handleReturnToPage={view === GRID_VIEW ? handleReturn : undefined}
      />
      <Box borderBottom="1px solid" borderColor="gray-150" />
    </>
  ) : (
    <></>
  );
}
