import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IntegrationsService from '../../../../../services/IntegrationsService';
import WebhooksService from '../../../../../services/WebhooksService';
import {
  IntegrationResType,
  S3IntegrationResType,
  SLACKIntegrationResType,
} from '../../../../../types/integrationTypes';
import EditAuthenticationDialog from '../editIntegration/EditAuthenticationDialog';
import { TargetType } from '../types';
import IntegrationListHeader from './IntegrationListHeader';
import IntegrationListRowContents from './IntegrationListRowContents';

const IntegrationList = ({ target }: { target: TargetType }) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { openModal, closeModal } = useAlertModal();
  const [selectedIntegration, setSelectedIntegration] = useState<Exclude<
    IntegrationResType,
    SLACKIntegrationResType
  > | null>(null);

  const queryClient = useQueryClient();

  const integrationsQuery = useQuery({
    queryKey: ['integrations', routeInfo.urlMatchInfo.accountName, target],
    queryFn: () =>
      IntegrationsService.getIntegrationsByMethod({
        methodName: target,
        urlInfo: routeInfo.urlMatchInfo,
        isGuest: authInfo.isGuest,
      }),
    select: data => data.data,
  });

  const deleteIntegration = useMutation({
    mutationFn: ({ integrationId, method }: { integrationId: string; method: TargetType }) =>
      IntegrationsService.deleteIntegration({
        integrationId,
        urlInfo: routeInfo.urlMatchInfo,
        isGuest: authInfo.isGuest,
        method,
      }),
    onSuccess: (_, { method }) => {
      queryClient.resetQueries({
        queryKey: ['integrations', routeInfo.urlMatchInfo.accountName, method],
      });
    },
  });

  const handleClickCheck = async (integration: IntegrationResType) => {
    if (integration.method === 'SLACK') {
      const contentInfo = integration as SLACKIntegrationResType;
      try {
        await WebhooksService.sendTestMessage({
          projectName: contentInfo.info.project.name,
          projectId: contentInfo.info.project.id,
          type: integration.method,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      } catch (err: any) {
        openModal({
          title: "That address doesn't look right",
          content: (
            <>
              <Typography variant="body3">{t('integrations.worngUrlWarning')}</Typography>
            </>
          ),
          variant: 'error',
          mainButton: {
            text: 'Okay',
            onClick: () => closeModal(),
          },
        });
      }
    }
  };

  const handleClickDelete = ({ integrationId, method }: IntegrationResType) => {
    openModal({
      content:
        method === 'SLACK' ? (
          <Typography variant="body3">{t('integrations.integrationDeleteWarning')}</Typography>
        ) : (
          <>
            <Typography variant="body3">{t('integrations.integrationDeleteWarning')}</Typography>
            <Box backgroundColor="gray-100" mt={1} mb={1} p={2}>
              <Typography variant="headline7" themedColor="grey">
                {t('errors.warning')}!
              </Typography>
              <Typography variant="body4" themedColor="grey">
                {t('integrations.dataReadonlyDescription')}
              </Typography>
            </Box>
            <Typography variant="body3">{t('integrations.undoActionWarning')}</Typography>
          </>
        ),
      title: 'Remove Integration?',
      mainButton: {
        text: 'Okay',
        // @ts-ignore: type overlap
        onClick: (promptValue?: string) => {
          if (promptValue !== 'REMOVE') return;
          deleteIntegration.mutate(
            { integrationId, method },
            {
              onSuccess() {
                closeModal();
              },
            },
          );
        },
      },
      subButton: {
        text: 'Cancel',
        onClick: () => closeModal(),
      },
      prompt: {
        placeholder: 'Type “REMOVE” to confirm',
        selected: true,
      },
    });
  };

  const list = integrationsQuery.isLoading ? (
    <>
      <IntegrationListHeader target={target} skeleton />
      <IntegrationListHeader target={target} skeleton />
      <IntegrationListHeader target={target} skeleton />
    </>
  ) : (
    integrationsQuery.data?.map((integration: IntegrationResType) => (
      <tr key={integration.integrationId}>
        <IntegrationListRowContents
          integrationInfo={integration}
          handleDelete={() => handleClickDelete(integration)}
          handleCheck={() => handleClickCheck(integration)}
          handleEdit={() => {
            setSelectedIntegration(
              integration as Exclude<IntegrationResType, SLACKIntegrationResType>,
            );
          }}
          method={integration.method}
        />
      </tr>
    ))
  );

  return (
    <Box mt={3}>
      <Accordion summary={t('integration.listTitle')} defaultOpen>
        <Box style={{ backgroundColor: '#fbfbfb' }} p={2}>
          <table style={{ width: '100%' }}>
            <IntegrationListHeader target={target} />
            {list}
          </table>
        </Box>
      </Accordion>
      {selectedIntegration && (
        <EditAuthenticationDialog
          status={selectedIntegration.info.statusCheck}
          bucket={selectedIntegration.info.bucket}
          prefix={selectedIntegration.info.prefix}
          name={selectedIntegration.alias}
          integrationId={selectedIntegration.integrationId}
          method={selectedIntegration.method}
          close={() => setSelectedIntegration(null)}
          s3delegateInfo={
            selectedIntegration.method === 'S3' && selectedIntegration.info.isDelegate
              ? (selectedIntegration as S3IntegrationResType)
              : undefined
          }
        />
      )}
    </Box>
  );
};

export default IntegrationList;
