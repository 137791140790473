import React from 'react';

import * as MUI from '@mui/material';

import { status } from '../../../../utils/filter/labelFilter';
import { useOptions } from '../../../../utils/filter/useOptions';
import FilterDropdown from './components/FilterDropdown';
import FilterWithSearch from './components/FilterWithSearch';
import { FilterTypeProps } from './types';

const FilterStatus: React.FC<FilterTypeProps> = props => {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  const options = useOptions({ options: status.options, i18nKeyPrefix: 'labels.status' });
  const selectedOptions = useOptions({ options: filter.options, i18nKeyPrefix: 'labels.status' });

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={status.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      <MUI.Grid item style={{ flex: 1 }}>
        <FilterWithSearch
          index={index}
          objKey="options"
          filterBy={filter.filterBy}
          options={options}
          value={selectedOptions}
          onValueChange={onValueChange}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
    </>
  );
};

export default FilterStatus;
