import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';

import { useModalContext } from '../../contexts/ModalContext';
import CircularProgressDialog from './CircularProgressDialog';
import Emoji from './Emoji';

const moveAnimationTimingFunction = 'ease-in-out';
const moveAnimationDuration = '0.35s';
const moveStartTime = 0.05;
const moveInterval = 0.05;

const useStyles = makeStyles(() => ({
  modal: {
    '&.open': {
      '& .MuiPaper-root': {
        animationName: '$appearBox',
        animation: '0.4s',
        animationTimingFunction: 'linear',
        animationFillMode: 'forwards',
      },
    },
    '&.close': {
      '& .MuiPaper-root': {
        animationName: '$disappearBox',
        animation: '0.4s',
        animationTimingFunction: 'linear',
        animationFillMode: 'forwards',
      },
    },
  },
  box: {
    minWidth: '320px',
    padding: '50px 52px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emoji: {
    opacity: 0,
    fontSize: '18px',

    animationName: '$move',
    animation: moveAnimationDuration,
    animationTimingFunction: moveAnimationTimingFunction,
    animationFillMode: 'forwards',
    animationDelay: `${moveStartTime}s`,
  },
  title: {
    opacity: 0,
    fontSize: '18px',
    color: '#FF625A',
    marginBottom: '10px',

    animationName: '$move',
    animation: moveAnimationDuration,
    animationTimingFunction: moveAnimationTimingFunction,
    animationFillMode: 'forwards',
    animationDelay: `${moveStartTime + moveInterval * 1}s`,
  },
  contentsBox: {
    opacity: 0,
    width: '100%',
    textAlign: 'center',
    marginBottom: '30px',

    animationName: '$move',
    animation: moveAnimationDuration,
    animationTimingFunction: moveAnimationTimingFunction,
    animationFillMode: 'forwards',
    animationDelay: `${moveStartTime + moveInterval * 2}s`,
  },
  content: {
    display: 'inline',
    fontSize: '12px',
    color: '#333',
    '&.link': {
      color: '#5a7bff',
      textDecoration: 'underline',
      marginRight: '2px',
    },
    '&.strong': {
      fontWeight: '500',
    },
  },
  buttonBox: {
    opacity: 0,
    display: 'flex',
    gap: '8px',

    animationName: '$move',
    animation: moveAnimationDuration,
    animationTimingFunction: moveAnimationTimingFunction,
    animationFillMode: 'forwards',
    animationDelay: `${moveStartTime + moveInterval * 3}s`,
  },
  button: {
    minWidth: '80px',
    height: '28px',
    padding: '0px',
    margin: '0px 6px',
    fontSize: '13px',
  },

  '@keyframes appearBox': {
    '0%': {
      transform: 'scale(0.8)',
    },
    '50%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes disappearBox': {
    '0%': {
      opacity: 1,
      transform: 'translate(0%,0%)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate(0%,100%)',
    },
  },
  '@keyframes move': {
    '0%': {
      opacity: 0,
      transform: 'translate(0%,30%)',
    },
    '50%': {
      transform: 'translate(0%,-10%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0%,-0%)',
    },
  },
}));

const Modal: React.FC = () => {
  const classes = useStyles();
  const modalInfo = useModalContext();
  const { isOpen, setIsOpen, messageObject, buttonObject } = modalInfo;
  const { emoji = '', title = '', contents = [] } = messageObject;
  const { mainButton, subButton = null } = buttonObject;

  const [isLoading, setIsLoading] = useState(false);

  const getContents = () => {
    return (
      <MUI.Box className={classes.contentsBox}>
        {map(contents, (content, index) => {
          const { type = 'plain', text, link, handleClick } = content;

          if (!text) {
            return <br key={index} />;
          }
          if (type === 'link') {
            return (
              <MUI.Link
                key={index}
                className={`${classes.content} ${type}`}
                href={link || null}
                onClick={handleClick || null}
              >
                {text}
              </MUI.Link>
            );
          }
          return (
            <MUI.Typography key={index} className={`${classes.content} ${type}`}>
              {text}&nbsp;
            </MUI.Typography>
          );
        })}
      </MUI.Box>
    );
  };

  const getButtons = () => {
    const handleClickButton = (superHandleClick: () => void) => async () => {
      if (superHandleClick) {
        setIsLoading(true);
        await superHandleClick();
        setIsLoading(false);
      }

      setIsOpen(false);
    };

    return (
      <MUI.Box className={classes.buttonBox}>
        {subButton && (
          <Button
            color="primary"
            variant="stroke"
            onClick={handleClickButton(subButton.handleClick)}
          >
            {subButton.text ? subButton.text : 'CANCEL'}
          </Button>
        )}
        {mainButton && (
          <Button
            color="primary"
            variant="strong-fill"
            onClick={handleClickButton(mainButton.handleClick)}
          >
            {mainButton.text ? mainButton.text : 'OK'}
          </Button>
        )}
      </MUI.Box>
    );
  };

  return (
    <MUI.Dialog
      open={isOpen}
      className={`${classes.modal} ${isOpen ? 'open' : 'close'}`}
      transitionDuration={500}
    >
      <MUI.Box className={classes.box}>
        <MUI.Typography className={classes.emoji}>
          <Emoji symbol={emoji} />
        </MUI.Typography>
        <MUI.Typography className={classes.title}>{title}</MUI.Typography>
        {getContents()}
        {getButtons()}
      </MUI.Box>
      <CircularProgressDialog isLoading={isLoading} />
    </MUI.Dialog>
  );
};

export default Modal;
