import * as d3 from 'd3';

import { AnnotationType } from '../../../../../utils/LabelInterfaceUtils';
import { AnnotationChangeType, RGBStrings } from '../types/types';

export const colors = {
  base: 'green',
  compare: 'secondary',
} as const;

export const hexToRGB = (
  palettes: d3.ScaleLinear<string, string, never>,
  num: number,
): RGBStrings => {
  return Array.from({ length: num }, (_, k) => {
    return palettes(k)
      .replace(/[^\d,]/g, '')
      .split(',');
  }) as RGBStrings;
};
const CHANGE_TYPE_COLORS = {
  noChange: ['#383838', '#A6A6A6', '#F5F5F5'],
  added: ['#000099', '#702DFF', '#EBEBFF'],
  deleted: ['#800B00', '#FF3A28', '#FFE1DE'],
  edited: ['#806A00', '#FFDE33', '#FFF9DE'],
  changedClass: ['#0A525C', '#4AE2B9', '#DFECF2'],
};
/* Treemap */
export const colorPalettes = (changeType: AnnotationChangeType, num: number) =>
  d3
    .scaleLinear<string>()
    .domain([0, Math.ceil(num / 2), num])
    .range(CHANGE_TYPE_COLORS[changeType]);

export const exportColors = {
  base: '#82DB24',
  queryCount: '#82DB24',
  compare: '#5A7BFF',
  refCount: '#5A7BFF',
};

/* Table & Pie Chart */
export function colorForChangeType(changeType: AnnotationChangeType): string {
  return CHANGE_TYPE_COLORS[changeType] ? CHANGE_TYPE_COLORS[changeType][1] : '';
}

export function getChangeTypeTextColor(changeType: AnnotationChangeType): string {
  if (changeType === 'edited') return CHANGE_TYPE_COLORS[changeType][0];
  return CHANGE_TYPE_COLORS[changeType] ? CHANGE_TYPE_COLORS[changeType][1] : '';
}

export const getTextColor = (pieceColor: string) => {
  const lightColors = [CHANGE_TYPE_COLORS.edited[1], CHANGE_TYPE_COLORS.noChange[1]];
  return lightColors.includes(pieceColor) ? 'black' : 'white';
};

export const getAnnotationTypeColor = (annotationType: AnnotationType): string => {
  const annotationTypeColors = {
    box: '#FFDE33', // yellow-400
    polygon: '#FF792E', // orange-400
  } as Record<AnnotationType, string>;
  return annotationTypeColors[annotationType];
};
