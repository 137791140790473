import { t } from 'i18next';

import { NEW_PROJECT_PAGE_TYPE } from '../consts/NewProjectConst';
import WorkappUnion from '../union/WorkappUnion';

export const ProjectSteps = {
  CREATE_PROJECT: 'create_project',
  IMAGE_CATEGORY: 'image_category',
  CLASS_PROPERTY: 'class_property',
  CLASS_GROUP: 'class_group',
};

export const ProjectStepsErrorMessage = {
  // create project
  get CREATE_PROJECT() {
    return t('projects.createProject.error.fillAllRequiredFields');
  },
  get IMAGE_CATEGORY_CLASS() {
    return t('projects.createProject.error.addAllImage');
  },
  get IMAGE_CATEGORY_GROUP() {
    return t('projects.createProject.error.addAllObject');
  },
  // image category
  get AT_LEAST_ONE_CATEGORY_IS_REQUIRED() {
    return t('projects.createProject.error.leastOneCategory');
  },
  get CATEGORY_MUST_BELONG_TO_GROUP() {
    return t('projects.createProject.error.categoryMustBelongToGroup');
  },
  get GROUP_MUST_HAVE_AT_LEAST_ONE_CATEGORY() {
    return t('projects.createProject.error.groupMustHaveAtLeastOneCategory');
  },

  // object detection
  get AT_LEAST_ONE_OBJECT_CLASS_IS_REQUIRED() {
    return t('projects.createProject.error.atLeastOneObjectClass');
  },
  get SELECT_ANNOTATION_TYPE() {
    return t('projects.createProject.error.selectAnnotationType');
  },
  get ANNOTATION_PER_IMAGE_RANGE_WRONG() {
    return t('projects.createProject.error.annotationPerImageRange');
  },

  get GROUP_MUST_HAVE_AT_LEAST_ONE_OBJECT_CLASS() {
    return t('projects.createProject.groupMustHave');
  },
  get OBJECT_CLASS_MUST_BELONG_TO_GROUP() {
    return t('projects.createProject.error.objectClassMustBeloneToGroup');
  },
};

export const getProjectSteps = ({
  dataType,
  hasObjectClass = false,
  hasImageCategory = false,
}: {
  dataType?: string;
  hasObjectClass?: boolean;
  hasImageCategory?: boolean;
}): any[] => {
  if (hasObjectClass && hasImageCategory) {
    return [
      {
        title: 'projectSettings.steps.createProject',
        settingPage: ProjectSteps.CREATE_PROJECT,
        activeStep: 0,
      },
      {
        title: WorkappUnion.isImageSequence(dataType || '')
          ? 'projectSettings.configuration.videoCategory'
          : 'projectSettings.configuration.imageCategory',
        settingPage: ProjectSteps.IMAGE_CATEGORY,
        contents: [
          { title: 'labelInterface.category', isOptional: false },
          { title: 'projectSettings.steps.categoryGroup', isOptional: true },
        ],
        activeStep: 1,
      },
      {
        title: WorkappUnion.isImageSequence(dataType || '')
          ? 'projectSettings.steps.objectTracking'
          : 'projectSettings.steps.objectDetection',
        settingPage: NEW_PROJECT_PAGE_TYPE.OBJECT_DETECTION,
        hasInnerSteps: true,
        contents: [
          {
            title: 'projectSettings.steps.classAndProperty',
            settingPage: ProjectSteps.CLASS_PROPERTY,
            isOptional: false,
            activeStep: 2,
            innerStep: 0,
          },
          {
            title: 'projectSettings.steps.classGroup',
            settingPage: ProjectSteps.CLASS_GROUP,
            isOptional: true,
            activeStep: 2,
            innerStep: 1,
          },
        ],
        activeStep: 2,
      },
    ];
  }

  if (hasImageCategory) {
    return [
      {
        title: 'projectSettings.steps.createProject',
        settingPage: ProjectSteps.CREATE_PROJECT,
        activeStep: 0,
      },
      {
        title: WorkappUnion.isImageSequence(dataType || '')
          ? 'projectSettings.configuration.videoCategory'
          : 'projectSettings.configuration.imageCategory',
        settingPage: ProjectSteps.IMAGE_CATEGORY,
        contents: [
          { title: 'labelInterface.category', isOptional: false },
          { title: 'projectSettings.steps.categoryGroup', isOptional: true },
        ],
        activeStep: 1,
      },
    ];
  }

  if (hasObjectClass) {
    return [
      {
        title: 'projectSettings.steps.createProject',
        settingPage: ProjectSteps.CREATE_PROJECT,
        activeStep: 0,
      },
      {
        title: WorkappUnion.isImageSequence(dataType || '')
          ? 'projectSettings.steps.objectTracking'
          : 'projectSettings.steps.objectDetection',
        settingPage: NEW_PROJECT_PAGE_TYPE.OBJECT_DETECTION,
        hasInnerSteps: true,
        contents: [
          {
            title: 'projectSettings.steps.classAndProperty',
            settingPage: ProjectSteps.CLASS_PROPERTY,
            isOptional: false,
            activeStep: 1,
            innerStep: 0,
          },
          {
            title: 'projectSettings.steps.classGroup',
            settingPage: ProjectSteps.CLASS_GROUP,
            isOptional: true,
            activeStep: 1,
            innerStep: 1,
          },
        ],
        activeStep: 1,
      },
    ];
  }
  return [
    {
      title: 'projectSettings.steps.createProject',
      settingPage: ProjectSteps.CREATE_PROJECT,
      activeStep: 0,
    },
  ];
};
