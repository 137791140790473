import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';

import { AppMenu, AppRouteLayoutProps, AppRoutes, MenuTitle, PageWithMenu } from '../../menu';

export default function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <PageWithMenu
      menu={
        <Box position="sticky" top={0}>
          <MenuTitle title={t('settings.title')} />
          <AppMenu
            items={menuItem.children ?? []}
            nested
            itemProps={{ separator: true, css: '' }}
          />
        </Box>
      }
    >
      <AppRoutes items={menuItem.children ?? []} />
    </PageWithMenu>
  );
}
