import { FileArchive } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { CreateMenuItem } from '../../../../../../menu';
import Layout from './Layout';

export const DownloadsMenuItem = CreateMenuItem({
  path: 'downloads',
  icon: <Icon icon={FileArchive} />,
  title: ({ t }) => t('curate.dataset.menus.downloads'),
  component: Layout,
});
