import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Bulb, Clear } from '@superb-ai/icons';
import { Popper } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, IconButton } from '@superb-ai/ui';
import Script from 'next/script';

import analyticsTracker from '../../analyticsTracker';
import { useAuthInfo } from '../../contexts/AuthContext';

export const StorylaneURL: Record<
  'ko' | 'en' | 'ja',
  {
    label?: string;
    curate?: string;
    model?: string;
  }
> = {
  ko: {
    label: 'https://app.storylane.io/demo/dz451vbwrjee',
    curate: 'https://app.storylane.io/demo/nheav8l9fajg',
    model: 'https://app.storylane.io/share/7bxjtbtfyn3a',
  },
  en: {
    label: 'https://app.storylane.io/share/ahj44omehbwm',
    curate: 'https://app.storylane.io/share/9v0fe5uhmdcn',
    model: 'https://app.storylane.io/share/7wkpkhdco9sc',
  },
  ja: {
    label: 'https://app.storylane.io/share/apvjwyb1rwhc',
    curate: 'https://app.storylane.io/share/pune0urtf6bc',
    model: 'https://app.storylane.io/share/uo0sng0rzhrs',
  },
};

// ja가 맞나? jp인가?
export default function StorylaneTutorial({ lang }: { lang: 'ko' | 'en' | 'ja' }) {
  const { t } = useTranslation();
  const [isFirstTime, setIsFirstTime] = useState(false);
  const params = useParams<{ suite: 'label' | 'curate' | 'model' }>();
  const suite = params?.suite;
  const { firstName, lastName, accountName } = useAuthInfo();

  useEffect(() => {
    setIsFirstTime(localStorage.getItem('spb_f_in_l') !== 'true');
  }, []);

  const onClose = () => {
    setIsFirstTime(false);
    localStorage.setItem('spb_f_in_l', 'true');
  };

  const trackStorylaneClick = () => {
    analyticsTracker.storylaneClicked({
      accountId: accountName ?? '',
      product: params.suite,
    });
  };

  const storylaneUrl = StorylaneURL?.[lang]?.[suite];
  if (!storylaneUrl) return null;

  const buttonText = {
    label: t('storylane.label.browsingProductButton'),
    curate: t('storylane.curate.browsingProductButton'),
    model: t('storylane.model.browsingProductButton'),
  }[suite];

  const tooltipText = {
    label: t('storylane.label.isYourFirstTimeUsing'),
    curate: t('storylane.curate.isYourFirstTimeUsing'),
    model: t('storylane.model.isYourFirstTimeUsing'),
  }[suite];

  return (
    <>
      <Script src="/storylane.js" />
      <StaticTooltip
        close={onClose}
        open={isFirstTime}
        disclosure={
          <Button
            variant="strong-fill"
            color="secondary"
            onClick={() => {
              //@ts-ignore
              Storylane.Play({
                type: 'popup',
                demo_type: 'image',
                width: 2560,
                height: 1464.5502645502645,
                scale: '0.95',
                demo_url: `${storylaneUrl}?${new URLSearchParams(
                  [
                    firstName ? ['first_name', firstName] : null,
                    lastName ? ['last_name', lastName] : null,
                    accountName ? ['company_name', accountName] : null,
                  ].filter(v => v) as [string, string][],
                ).toString()}`,
                padding_bottom: 'calc(57.21% + 27px)',
              });
              trackStorylaneClick();
            }}
          >
            <Icon icon={Bulb} color="white" />
            {buttonText}
          </Button>
        }
      >
        <Box display="block" textAlign="left" style={{ color: 'white' }}>
          {tooltipText}
          <br />
          {t('storylane.checkOutThePowerfulFeatures')}
        </Box>
      </StaticTooltip>
    </>
  );
}

function StaticTooltip({
  children,
  disclosure,
  open,
  close,
}: {
  children: ReactNode;
  disclosure: ReactElement;
  open: boolean;
  close: () => void;
}) {
  return (
    <Popper open={open} placement="bottom" offset={1} anchorEl={disclosure}>
      <Box backgroundColor="gray-opacity-400" borderRadius="2px" position="relative" p={3}>
        <Box
          position="absolute"
          style={{
            left: '50%',
            top: '-10px',
            border: 'solid 5px transparent',
            borderBottom: 'solid 5px #000000cc',
          }}
        />
        <Box position="absolute" style={{ top: '4px', right: '4px' }}>
          <IconButton variant="text" icon={Clear} size="s" color="white" onClick={close} />
        </Box>
        {children}
      </Box>
    </Popper>
  );
}
