import { ComponentProps, ReactNode, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { ChevronLeft, ChevronRight, InfoFilled, LoadingSpinnerSmall, Plus } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  extendComponent,
  Icon,
  LinkTypography,
  LoadingIndicator,
  SegmentedControl,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@superb-ai/ui';
import { format } from 'date-fns';

import { Row } from '../../../components/elements/Row';
import { getUrl } from '../../../routes/util';
import { ModelStatusChip, PurposeChip } from '../components/components';
import { EndpointsDetailMenuItem } from '../endpoints/detail/MenuItem';
import { GenerationAIDetailsMenuItem } from '../gen-ai/details/MenuItem';
import {
  GENERATION_MODEL,
  GENERATION_MODEL_LIST,
  MODEL_ENDPOINTS,
  MODEL_ENDPOINTS_LIST,
  MODEL_TRAIN,
  RECOGNITION_MODEL,
  RECOGNITION_MODEL_LIST,
} from '../path';
import { useModelOverviewQuery } from '../queries/modelQueries';
import { RecognitionAIDetailMenuItem } from '../recognition-ai/detail/MenuItem';
import {
  formatDownloadTime,
  getTotalEstimatedTime,
  MAX_PIN_COUNT,
  MyModelListItem,
} from '../recognition-ai/list/Layout';
import {
  listQuery,
  RecognitionQueryKeyword as RecognitionQueryKeyword,
} from '../recognition-ai/queries';
import { OverviewData, PurposeType } from '../services/types';
import { fromQuery, TrainQueryKeyword } from '../train/queries';
import AnalyticsTracker from '../../../analyticsTracker';

export function Layout() {
  const { data, isLoading } = useModelOverviewQuery({});
  if (!data || isLoading) {
    return <LoadingIndicator />;
  }

  const noHeader = data.runningEndpointList.length === 0 && data.trainingModelList.length === 0;

  return (
    <>
      {!noHeader && <Header data={data} />}
      {/* <HowToUseModel /> */}
      <Dashboard data={data} />
      <PinnedModel />
    </>
  );
}

const Header = ({ data }: { data: OverviewData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<'training_model' | 'running_endpoint'>(
    'training_model',
  );

  const isMinIndex = !currentIndex;
  const isMaxIndex =
    selectedTab === 'training_model'
      ? currentIndex + 1 === data.trainingModelList.length
      : currentIndex + 1 === data.runningEndpointList.length;
  const maxIndex =
    selectedTab === 'training_model'
      ? data.trainingModelList.length - 1
      : data.runningEndpointList.length - 1;

  const increaseIndex = useCallback(() => {
    if (isMaxIndex) {
      setCurrentIndex(0);
      return;
    }
    setCurrentIndex(prev => prev + 1);
  }, [isMaxIndex]);

  const decreaseIndex = () => {
    if (isMinIndex) {
      setCurrentIndex(maxIndex);
      return;
    }
    setCurrentIndex(prev => prev - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      increaseIndex();
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [increaseIndex]);

  useEffect(() => {
    if (data && data.trainingModelList.length === 0 && data.runningEndpointList.length > 0) {
      setSelectedTab('running_endpoint');
    }
  }, [data]);

  return (
    <Box
      p={1.5}
      mb={3}
      backgroundColor={'gray-100'}
      bb="1px solid"
      borderColor="gray-150"
      style={{ marginTop: -32, marginInline: -32 }}
    >
      <Row justifyContent="space-between" mb={2}>
        <SegmentedControl
          buttonProps={{ color: 'primary', size: 's' }}
          onChangeValue={value => {
            setSelectedTab(value as any);
            setCurrentIndex(0);
          }}
          options={[
            {
              label: t('model.overview.trainingModel'),
              value: 'training_model',
              disabled: data.trainingModelList.length === 0,
              tooltip: data.trainingModelList.length === 0 && t('model.overview.noModelTooltip'),
            },
            {
              label: t('model.overview.runningEndpoint'),
              value: 'running_endpoint',
              disabled: data.runningEndpointList.length === 0,
              tooltip:
                data.runningEndpointList.length === 0 && t('model.overview.noEndpointTooltip'),
            },
          ]}
          value={selectedTab}
        />
        <Box
          backgroundColor={'gray-opacity-400'}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ borderRadius: 36, height: 24, width: 72 }}
        >
          <Icon
            icon={ChevronLeft}
            color={'white'}
            size={12}
            cursor={'pointer'}
            onClick={() => {
              decreaseIndex();
            }}
          />
          <Typography variant="s-regular" color="white" mx={0.5}>
            {currentIndex + 1} /{' '}
            {selectedTab === 'training_model'
              ? data.trainingModelList.length
              : data.runningEndpointList.length}
          </Typography>
          <Icon
            icon={ChevronRight}
            cursor={'pointer'}
            color={'white'}
            size={12}
            onClick={() => {
              increaseIndex();
            }}
          />
        </Box>
      </Row>
      {selectedTab === 'training_model' && data.trainingModelList.length > 0 && (
        <Row mt={0.5} justifyContent="space-between">
          <Row gap={1}>
            <Icon icon={LoadingSpinnerSmall} color={'yellow'} />
            <Row
              cursor="pointer"
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: accountName,
                  clickedLink: 'click-training-model',
                });
                data.trainingModelList[currentIndex].baselineModel.purpose === 'generation'
                  ? history.push(
                      getUrl([accountName, GENERATION_MODEL, GenerationAIDetailsMenuItem.path], {
                        id: data.trainingModelList[currentIndex].id,
                      }),
                    )
                  : history.push(
                      getUrl([accountName, RECOGNITION_MODEL, RecognitionAIDetailMenuItem.path], {
                        id: data.trainingModelList[currentIndex].id,
                      }),
                    );
              }}
            >
              <Typography variant="m-regular">
                {data.trainingModelList[currentIndex].modelSetting.name}
              </Typography>
              <Icon icon={ChevronRight} />
            </Row>
            <ModelStatusChip
              status={data.trainingModelList[currentIndex].status}
              endpointsCount={data.trainingModelList[currentIndex].endpointsCount}
            />
            <Typography variant="s-regular">
              {formatDownloadTime(
                t,
                getTotalEstimatedTime(data.trainingModelList[currentIndex]),
                data.trainingModelList[currentIndex].status,
              )}
            </Typography>
          </Row>
          <Row gap={1.5}>
            <PurposeChip
              purpose={data.trainingModelList[currentIndex].baselineModel.purpose}
              color={
                data.trainingModelList[currentIndex].baselineModel.purpose === 'generation'
                  ? 'secondary'
                  : 'primary'
              }
            />
            <Typography variant="m-regular" color="gray-300">
              {t('model.endpoints.createdAt')}
            </Typography>
            <Typography variant="m-regular">
              {format(data.trainingModelList[currentIndex].createdAt, 'L/d/yy h:mma')}
            </Typography>
          </Row>
        </Row>
      )}
      {selectedTab === 'running_endpoint' && data.runningEndpointList.length > 0 && (
        <Row mt={0.5} justifyContent="space-between">
          <Row gap={1}>
            <Chip color="green">{t('model.status.running')}</Chip>
            <Row
              cursor="pointer"
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: accountName,
                  clickedLink: 'click-running-endpoint',
                });
                history.push(
                  getUrl([accountName, MODEL_ENDPOINTS, EndpointsDetailMenuItem.path], {
                    id: data.runningEndpointList[currentIndex].id,
                  }),
                );
              }}
            >
              <Typography variant="m-regular">
                {data.runningEndpointList[currentIndex].endpointSetting.name}
              </Typography>
              <Icon icon={ChevronRight} />
            </Row>
          </Row>
          <Row gap={1.5}>
            <PurposeChip
              purpose={data.runningEndpointList[currentIndex].baselineModel.purpose}
              color={
                data.runningEndpointList[currentIndex].baselineModel.purpose === 'generation'
                  ? 'secondary'
                  : 'primary'
              }
            />
            <Typography variant="m-regular" color="gray-300">
              {t('model.myModels.startedAt')}
            </Typography>
            <Typography variant="m-regular">{format(new Date(), 'L/d/yy h:ma')}</Typography>
          </Row>
        </Row>
      )}
    </Box>
  );
};

const HowToUseModel = () => {
  const { t } = useTranslation();
  const [openRow, setOpenRow] = useState({
    first: true,
    second: false,
    third: false,
  });

  return (
    <Box mb={3}>
      <Typography variant="h3">{t('model.overview.howToUseModel.title')}</Typography>
      <Box display="grid" mt={1.5} style={{ gridTemplateColumns: '404px 1fr', columnGap: 16 }}>
        <Box backgroundColor={'gray-100'} style={{ width: 404, height: 226 }} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <ToggleRow
            open={openRow.first}
            borderBottom={'1px solid'}
            title={t('model.overview.howToUseModel.firstRowTitle')}
            content={
              <Typography variant="m-regular">
                <Trans
                  t={t}
                  i18nKey={'model.overview.howToUseModel.firstRowContent'}
                  components={{
                    link1: <LinkTypography variant="m-regular" onClick={() => {}} />,
                  }}
                />
              </Typography>
            }
            onClick={() => setOpenRow({ first: true, second: false, third: false })}
          />
          <ToggleRow
            open={openRow.second}
            borderBottom={'1px solid'}
            title={t('model.overview.howToUseModel.secondRowTitle')}
            content={
              <Typography variant="m-regular">
                <Trans
                  t={t}
                  i18nKey={'model.overview.howToUseModel.firstRowContent'}
                  components={{
                    link1: <LinkTypography variant="m-regular" onClick={() => {}} />,
                  }}
                />
              </Typography>
            }
            onClick={() => setOpenRow({ first: false, second: true, third: false })}
          />
          <ToggleRow
            open={openRow.third}
            borderBottom={undefined}
            title={t('model.overview.howToUseModel.thirdRowTitle')}
            content={
              <Typography variant="m-regular">
                <Trans
                  t={t}
                  i18nKey={'model.overview.howToUseModel.firstRowContent'}
                  components={{
                    link1: <LinkTypography variant="m-regular" onClick={() => {}} />,
                  }}
                />
              </Typography>
            }
            onClick={() => setOpenRow({ first: false, second: false, third: true })}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ToggleRow = ({
  open,
  title,
  content,
  borderBottom,
  onClick,
}: {
  open: boolean;
  title: string;
  content: ReactNode;
  borderBottom: ComponentProps<typeof Box>['bb'];
  onClick: () => void;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bb={borderBottom}
      px={2}
      borderColor={'gray-150'}
      onClick={onClick}
      cursor="pointer"
      style={{ height: open ? 98 : 64 }}
    >
      <Row display="flex" justifyContent="space-between">
        <Typography variant="l-strong" color={open ? 'primary-400' : undefined}>
          {title}
        </Typography>
        {!open && <Icon icon={Plus} color="primary" size={24} />}
      </Row>
      {open && <Box mt={0.5}>{content}</Box>}
    </Box>
  );
};

const Dashboard = ({ data }: { data: OverviewData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ accountName: string }>();

  return (
    <Box mb={3}>
      <Typography variant="h3">{t('model.overview.dashboard.title')}</Typography>
      <Box display="grid" mt={1.5} style={{ gridTemplateColumns: '1fr 1fr', columnGap: 12 }}>
        <DashboardContainer>
          <Row justifyContent="space-between" mb={1}>
            <Typography variant="m-strong">{t('model.myModels.title')}</Typography>
            <Button
              variant="soft-fill"
              size="s"
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-recognition-train-new-model',
                });
                AnalyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-overview',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_TRAIN],
                    {},
                    {
                      [TrainQueryKeyword.From]: fromQuery.recognitionAI,
                    },
                  ),
                );
              }}
            >
              {t('model.myModels.trainNewModel')}
            </Button>
          </Row>
          <Row>
            <DashboardSection
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-recognition-model-hub',
                });
                history.push(
                  getUrl(
                    [params.accountName, RECOGNITION_MODEL_LIST],
                    {},
                    { [RecognitionQueryKeyword.ListTab]: listQuery.modelHub },
                  ),
                );
              }}
            >
              <span style={{ fontSize: 40, fontWeight: 600, marginBottom: 8 }}>
                {data.recognitionModelList.modelHub}
              </span>
              <Typography variant="s-strong">{t('model.myModels.modelHub')}</Typography>
            </DashboardSection>
            <Box backgroundColor={'gray-300'} mx={2} style={{ width: 1, height: 16 }} />
            <DashboardSection
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-recognition-my-model',
                });
                history.push(getUrl([params.accountName, RECOGNITION_MODEL_LIST]));
              }}
            >
              <span style={{ fontSize: 40, fontWeight: 600, marginBottom: 8, color: '#702DFF' }}>
                {data.recognitionModelList.modelCounts}
              </span>
              <Typography variant="s-strong" color="violet-400">
                {t('model.train.myModel')}
              </Typography>
            </DashboardSection>
            <Box backgroundColor={'gray-300'} mx={2} style={{ width: 1, height: 16 }} />
            <DashboardSection
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-recognition-endpoints',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_ENDPOINTS_LIST],
                    {},
                    {
                      [TrainQueryKeyword.From]: fromQuery.recognitionAI,
                    },
                  ),
                );
              }}
            >
              <span style={{ fontSize: 40, fontWeight: 600, marginBottom: 8, color: '#3479FF' }}>
                {data.recognitionModelList.endpointCounts}
              </span>
              <Typography variant="s-strong" color="blue-400">
                {t('model.endpoints.title')}
              </Typography>
            </DashboardSection>
          </Row>
        </DashboardContainer>
        <DashboardContainer>
          <Row justifyContent="space-between" mb={1}>
            <Typography variant="m-strong">{t('model.generativeAi.title')}</Typography>
            <Button
              variant="soft-fill"
              size="s"
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-generative-train-new-model',
                });
                AnalyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-overview',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_TRAIN],
                    {},
                    {
                      [TrainQueryKeyword.From]: fromQuery.genAI,
                    },
                  ),
                );
              }}
            >
              {t('model.myModels.trainNewModel')}
            </Button>
          </Row>
          <Row>
            <DashboardSection
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-generative-model-list',
                });
                history.push(getUrl([params.accountName, GENERATION_MODEL_LIST]));
              }}
            >
              <span style={{ fontSize: 40, fontWeight: 600, marginBottom: 8, color: '#702DFF' }}>
                {data.generationModelList.modelCounts}
              </span>
              <Typography variant="s-strong" color="violet-400">
                {t('model.overview.dashboard.modelList')}
              </Typography>
            </DashboardSection>
            <Box backgroundColor={'gray-300'} mx={2} style={{ width: 1, height: 16 }} />
            <DashboardSection
              onClick={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-generative-endpoints',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_ENDPOINTS_LIST],
                    {},
                    {
                      [TrainQueryKeyword.From]: fromQuery.genAI,
                    },
                  ),
                );
              }}
            >
              <span style={{ fontSize: 40, fontWeight: 600, marginBottom: 8, color: '#3479FF' }}>
                {data.generationModelList.endpointCounts}
              </span>
              <Typography variant="s-strong" color="blue-400">
                {t('model.endpoints.title')}
              </Typography>
            </DashboardSection>
          </Row>
        </DashboardContainer>
      </Box>
    </Box>
  );
};

const DashboardContainer = extendComponent(Box, {
  b: '1px solid',
  borderColor: 'gray-150',
  px: 2.5,
  py: 2,
});

const DashboardSection = extendComponent(Box, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: { hover: 'gray-100' },
  cursor: 'pointer',
  style: { flex: '1 1 0', height: 100, borderRadius: 6 },
});

const PinnedModel = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={1.5}>
        <Typography variant="h3">{t('model.overview.pinnedModel.title')}</Typography>
      </Box>
      <Tabs>
        <TabList variant="fill">
          <Tab>{t('model.myModels.title')}</Tab>
          <Tab>{t('model.generativeAi.title')}</Tab>
        </TabList>
        <TabPanel>
          <PinnedRecognitionAIModels />
        </TabPanel>
        <TabPanel>
          <PinnedGenerativeAIModels />
        </TabPanel>
      </Tabs>
    </>
  );
};

const PinnedRecognitionAIModels = () => {
  const { params } = useRouteMatch<{ accountName: string }>();

  const { data, isLoading } = useModelOverviewQuery({});

  if (!data || isLoading) {
    return (
      <Box mt={3}>
        <LoadingIndicator />
      </Box>
    );
  }

  const pinnedRecognitionAIList = data.recognitionModelList.pinnedModelList;
  const pinDisable = pinnedRecognitionAIList.length === MAX_PIN_COUNT;

  return (
    <>
      {pinnedRecognitionAIList.length > 0 ? (
        pinnedRecognitionAIList.map(model => {
          return (
            <MyModelListItem
              key={model.id}
              data={model}
              pinDisable={pinDisable}
              backgroundColor={undefined}
              path={`/${params.accountName}${RECOGNITION_MODEL}`}
              hasPerformance
              onClickTitle={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-pinned-recognition-model',
                });
              }}
            />
          );
        })
      ) : (
        <NoPinnedModel purpose="recognition" />
      )}
    </>
  );
};

const PinnedGenerativeAIModels = () => {
  const { params } = useRouteMatch<{ accountName: string }>();
  const { data, isLoading } = useModelOverviewQuery({});

  if (!data || isLoading) {
    return (
      <Box mt={3}>
        <LoadingIndicator />
      </Box>
    );
  }

  const pinnedGererativeAIList = data.generationModelList.pinnedModelList;
  const pinDisable = pinnedGererativeAIList.length === MAX_PIN_COUNT;

  return (
    <>
      {pinnedGererativeAIList.length > 0 ? (
        pinnedGererativeAIList.map(model => {
          return (
            <MyModelListItem
              key={model.id}
              data={model}
              pinDisable={pinDisable}
              backgroundColor={undefined}
              path={`/${params.accountName}${GENERATION_MODEL}`}
              hasPerformance={false}
              onClickTitle={() => {
                AnalyticsTracker.overviewLinkClicked({
                  accountId: params.accountName,
                  clickedLink: 'click-pinned-generation-model',
                });
              }}
            />
          );
        })
      ) : (
        <NoPinnedModel purpose="generation" />
      )}
    </>
  );
};

const NoPinnedModel = ({ purpose }: { purpose: PurposeType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{
    accountName: string;
  }>();
  return (
    <Row backgroundColor={'gray-100'} px={1.5} mt={1} gap={0.5} style={{ height: 34 }}>
      <Icon icon={InfoFilled} size={16} />
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={'model.overview.pinnedModel.emptyList'}
          components={{
            link1: (
              <LinkTypography
                variant="m-regular"
                onClick={() => {
                  purpose === 'recognition'
                    ? history.push(getUrl([accountName, RECOGNITION_MODEL_LIST]))
                    : history.push(getUrl([accountName, GENERATION_MODEL_LIST]));
                }}
              />
            ),
          }}
        />
      </Typography>
    </Row>
  );
};
