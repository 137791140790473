import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import {
  Box,
  IconButton,
  Label,
  Modal,
  OverflowItemCount,
  Tab,
  Tabs,
  Typography,
} from '@superb-ai/norwegian-forest';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  MANAGE_MEMBER_INVITE_USER_TO_PROJECT,
  MANAGE_REMOVE_USER_FROM_PROJECTS,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUsersInfo } from '../../../../contexts/UsersContext';
import ProjectService from '../../../../services/ProjectService';
import { MemberData } from '../../../../types/memberTypes';
import { ProjectData } from '../../../../types/projectTypes';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import ProjectsAsyncTableContainer from './ProjectsAsyncTableContainer';
import ProjectsTableContainer from './ProjectsTableContainer';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedMembers: MemberData[];
  loadUsers: () => Promise<void>;
};

const useStyles = makeStyles({
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    border: '1px solid #E4E4E4',
    '& span': {
      color: '#333',
    },
    overflow: 'hidden',
    height: '24px',
  },
});
type TabValue = 'add' | 'remove';
const ProjectAccessModal: React.FC<Props> = ({ open, setOpen, selectedMembers, loadUsers }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authInfo = useAuthInfo();
  const usersInfo = useUsersInfo();
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();

  const boxRef = useRef<HTMLDivElement | null>(null);

  const [tabValue, setTabValue] = useState<TabValue>('add');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedProjects, setSelectedProjects] = useState<ProjectData[]>([]);
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isTotalProjectsSelected, setIsTotalProjectsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectsOfSelectedMembers, setProjectsOfSelectedMembers] = useState<ProjectData[]>([]);

  const isNotBulk = selectedMembers.length === 1;

  useEffect(() => {
    const projectsOfSelectedMembers = uniqBy(
      selectedMembers.flatMap(member => (member?.projects || []) as ProjectData[]),
      'projectId',
    );
    setProjectsOfSelectedMembers(projectsOfSelectedMembers);

    // eslint-disable-next-line
  }, [open]);

  const handleClose = () => {
    setTabValue('add');
    setSelectedRole('');
    setSelectedProjects([]);
    setTotalProjectCount(0);
    setIsConfirmOpen(false);
    setIsTotalProjectsSelected(false);
    setOpen(false);
  };

  const initialize = () => {
    setSelectedProjects([]);
    setIsConfirmOpen(false);
    setIsTotalProjectsSelected(false);
  };

  const handleChangeTab = (value: TabValue) => {
    setTabValue(value);
    initialize();
  };

  const handleClickNext = () => {
    if (tabValue === 'add' && selectedRole === '') {
      enqueueSnackbar(t('account.accountMembers.snackbar.selectRole'), { variant: 'error' });
      return;
    }
    if (!selectedProjects.length) {
      enqueueSnackbar(t('account.accountMembers.snackbar.selectProject'), { variant: 'error' });
      return;
    }
    setIsConfirmOpen(true);
  };

  const handleClickAdd = async () => {
    if (selectedProjects.length === 0) return;
    setIsConfirmOpen(false);
    try {
      setIsLoading(true);
      await Promise.all(
        selectedProjects.map(project =>
          Promise.all(
            selectedMembers.map((member: MemberData) =>
              ProjectService.updateUserRole({
                projectId: project.id,
                userId: member.email || '',
                nextRole: selectedRole,
                isGuest: authInfo.isGuest,
                urlInfo: routeInfo.urlMatchInfo,
              }),
            ),
          ),
        ),
      );

      await usersInfo.updateMembers();

      enqueueSnackbar(
        MANAGE_MEMBER_INVITE_USER_TO_PROJECT({
          t,
        }),
        { variant: 'success' },
      );

      await loadUsers();
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  const handleClickRemove = async () => {
    if (selectedProjects.length === 0) return;
    setIsConfirmOpen(false);
    try {
      setIsLoading(true);
      await Promise.all(
        selectedProjects.map(project =>
          Promise.all(
            selectedMembers.map((member: MemberData) =>
              ProjectService.deleteUserRole({
                projectId: project.projectId || project.id,
                userId: member.email,
                isGuest: authInfo.isGuest,
                urlInfo: routeInfo.urlMatchInfo,
              }),
            ),
          ),
        ),
      );
      // eslint-disable-next-line no-empty
    } catch (err: any) {
    } finally {
      await usersInfo.updateMembers();
      await loadUsers();
      enqueueSnackbar(
        MANAGE_REMOVE_USER_FROM_PROJECTS({
          t,
        }),
        { variant: 'success' },
      );
      handleClose();
      setIsLoading(false);
    }
  };

  const handleClickConfirm = () => {
    if (tabValue === 'add') {
      handleClickAdd();
    } else {
      handleClickRemove();
    }
  };

  const handleRemoveSelectedProject = (item: ProjectData) => () => {
    setSelectedProjects(
      selectedProjects.filter(
        project => (project.projectId || project.id) !== (item.projectId || item.id),
      ),
    );
  };

  const handleClearSelectedProject = () => {
    setSelectedProjects([]);
    setIsTotalProjectsSelected(false);
  };

  const CustomLabel: React.FC<{ onDelete?: () => void }> = ({ children, onDelete }) => (
    <span className={classes.chip}>
      <Label bgColor="transparent" fontSize="12px">
        {children}
      </Label>
      {onDelete && (
        <IconButton size="xs" icon="clear" variant="text" color="textDefault" onClick={onDelete} />
      )}
    </span>
  );

  const selectedProjectsCount = isTotalProjectsSelected
    ? totalProjectCount
    : selectedProjects.length;
  const ConfirmationContent = (() => {
    if (tabValue === 'add') {
      return (
        <Box height="44px">
          <Typography variant="body3">
            <Trans t={t} i18nKey="users.dialogs.projectAccess.addUsersToSelectedProjectsConfirm">
              Add{' '}
              <strong>
                {{ userCount: t('users.userCount', { count: selectedMembers?.length }) }}
              </strong>{' '}
              to the selected <strong>{{ projectCount: selectedProjectsCount }}</strong> projects?
            </Trans>
          </Typography>
        </Box>
      );
    }
    return (
      <Box height="44px">
        <Typography variant="body3">
          <Trans t={t} i18nKey="users.dialogs.projectAccess.removeUsersFromSelectedProjectsConfirm">
            Remove{' '}
            <strong>
              {{ userCount: t('users.userCount', { count: selectedMembers?.length }) }}
            </strong>{' '}
            from the selected <strong>{{ projectCount: selectedProjectsCount }}</strong> projects?
          </Trans>
        </Typography>
      </Box>
    );
  })();

  return (
    <Modal
      open={open}
      title={t('users.button.manageProjectAccess')}
      mainButton={{
        text: t('button.next'),
        onClick: handleClickNext,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClose,
      }}
      close={{
        onClose: handleClose,
        hasCloseButton: true,
      }}
      confirmation={{
        open: isConfirmOpen,
        content: ConfirmationContent,
        confirmButtonText: t(`button.${tabValue}`),
        cancelButtonText: t('button.cancel'),
        onConfirm: handleClickConfirm,
        onCancel: () => setIsConfirmOpen(false),
      }}
    >
      {isLoading ? (
        <CircularProgressBox boxProps={{ mt: 2 }} />
      ) : (
        <>
          <Box pr={4} pl={4} width="676px">
            <Typography variant="body3">
              {tabValue === 'add' ? (
                <Trans t={t} i18nKey="users.dialogs.projectAccess.addUsersToSelectedProjects">
                  Add{' '}
                  <strong>
                    {{ userCount: t('users.userCount', { count: selectedMembers?.length }) }}
                  </strong>{' '}
                  to the selected projects.
                </Trans>
              ) : (
                <Trans t={t} i18nKey="users.dialogs.projectAccess.removeUsersFromSelectedProjects">
                  Remove{' '}
                  <strong>
                    {{ userCount: t('users.userCount', { count: selectedMembers?.length }) }}
                  </strong>{' '}
                  from the selected projects.
                </Trans>
              )}
            </Typography>
            <Box pt={4}>
              <Tabs
                variant="switch"
                color="grey"
                onChange={v => handleChangeTab(v as TabValue)}
                currentTab={tabValue}
              >
                <Tab label={t('users.dialogs.projectAccess.addToProjects')} value="add" />
                <Tab label={t('users.dialogs.projectAccess.removeFromProjects')} value="remove" />
              </Tabs>
            </Box>
            {tabValue === 'remove' ? (
              <ProjectsTableContainer
                projects={projectsOfSelectedMembers}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
              />
            ) : (
              <ProjectsAsyncTableContainer
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                selectedProjects={selectedProjects}
                unSelectableProjects={isNotBulk ? projectsOfSelectedMembers : undefined}
                setSelectedProjects={setSelectedProjects}
                isTotalProjectsSelected={isTotalProjectsSelected}
                setIsTotalProjectsSelected={setIsTotalProjectsSelected}
                totalCount={totalProjectCount}
                setTotalCount={setTotalProjectCount}
                tab={tabValue}
                open={open}
              />
            )}
          </Box>
          <div
            ref={boxRef}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              overflow: 'hidden',
              width: '676px',
              height: '68px',
              gap: '5px',
              borderTop: '1px solid #eee',
              padding: '8px 40px 0',
              boxSizing: 'border-box',
            }}
          >
            {boxRef.current && (
              <OverflowItemCount
                container={boxRef}
                maxLines={2}
                overflowLabel={count => <CustomLabel>+{count}</CustomLabel>}
              >
                {isTotalProjectsSelected ? (
                  <CustomLabel onDelete={handleClearSelectedProject}>
                    {t('users.dialogs.projectAccess.all')}
                  </CustomLabel>
                ) : (
                  selectedProjects.map(project => (
                    <CustomLabel
                      key={project.projectId || project.id}
                      onDelete={handleRemoveSelectedProject(project)}
                    >
                      {project.name}
                    </CustomLabel>
                  ))
                )}
              </OverflowItemCount>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ProjectAccessModal;
