import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { SingleValue } from 'react-select';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Input, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { Option } from '../../../../../../../types/selectTypes';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../contexts/ObjectScopeContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useDatasetObjectCountQuery } from '../../../../../queries/datasetObjectQueries';
import { useUpdateSliceJobWithIdsMutation } from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { getView } from '../../../../../utils/routeUtils';
import DiagnosisAddSliceModalSelectSlice from './DiagnosisAddSliceModalSelectSlice';
import SliceSummary from './SliceSummary';

export default function SemanticSearchObjectAddSliceModal({
  addSliceModalIsOpen,
  setAddSliceModalIsOpen,
}: {
  addSliceModalIsOpen: boolean;
  setAddSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId, sliceId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId: string;
  }>();
  const { t } = useTranslation();
  const { queryString } = useQueryContext();
  const { setSelectedData, selectedAllData, setSelectedAllData, semanticSearchData } =
    useActionContext();
  const { totalCount } = useObjectScopeContext();

  const [selectedSlice, setSelectedSlice] = useState<SingleValue<Option>>();
  const [isLoading, setIsLoading] = useState(false);

  const { getDataset } = useCurateDatasetService();
  const { setDatasetInfo } = useDatasetContext();
  const commandContext = useCurateCommandContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { appliedFilters } = useObjectFilterContext();
  const history = useHistory();
  const viewType = getView(history);

  const trackDataSliceUpdated = useCallback(
    (selectedSliceId: string, dataCount?: number, selectedCellCount?: number) => {
      analyticsTracker.dataSliceUpdated({
        datasetId,
        accountId: accountName,
        sliceId: selectedSliceId,
        viewType: viewType,
        dataType: 'image',
        dataCount: dataCount,
        selectedCount: selectedCellCount,
        referrer: 'explore-object',
        filters: combineFiltersForTracking(appliedFilters, sliceId),
        queryString,
      });
    },
    [accountName, appliedFilters, datasetId, queryString, sliceId, viewType],
  );

  const selectedImageIdsSet = semanticSearchData.reduce((acc, data) => {
    if (data.imageId) {
      acc.add(data.imageId);
    }
    return acc;
  }, new Set<string>());

  const selectedImageIds = Array.from(selectedImageIdsSet);
  const selectedObjects = semanticSearchData.length;

  const [numImagesToAdd, setNumImagesToAdd] = useState<number>(selectedObjects);

  const { data: annotations, isLoading: isLoadingAnnotationCount } = useDatasetObjectCountQuery({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    queryString,
    appliedFilters,
  });

  const { mutateAsync: startUpdateSliceJob, isLoading: isUpdatingSliceJob } =
    useUpdateSliceJobWithIdsMutation({
      datasetId,
      commandContext,
      selectedImageIds: selectedImageIds.slice(0, numImagesToAdd),
    });

  const handleAddSlice = async () => {
    setIsLoading(true);
    if (!selectedSlice) return;
    try {
      startUpdateSliceJob({ sliceId: selectedSlice.value });
      trackDataSliceUpdated(selectedSlice.value, selectedImageIds.length, selectedImageIds?.length);

      const datasetInfo = await getDataset({
        datasetId,
        fromPublicDatasets: showPublicDatasets,
        expand: ['image_count', 'slice_count'],
      });
      setDatasetInfo(datasetInfo);
      if (setSelectedAllData && setSelectedData) {
        setSelectedAllData(false);
        setSelectedData([]);
      }
    } finally {
      setAddSliceModalIsOpen(false);

      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={addSliceModalIsOpen}
      close={{
        onClose: () => {
          setAddSliceModalIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.AddToExistingSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleAddSlice,
        color: 'primary',
        isLoading,
        disabled: !selectedSlice,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setAddSliceModalIsOpen(false);
        },
      }}
    >
      <Box px={4} py={2.5}>
        {annotations && (
          <SliceSummary
            selectedObjects={selectedObjects}
            imageCount={selectedImageIds?.length}
            pt={2}
            px={2}
            mb={2}
          />
        )}
        <Box pb={0.5} display="flex" justifyContent="space-between">
          <Typography variant="m-regular" color="black">
            {t('curate.dialogs.AllSlicesCount', { count: totalCount })}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="s-regular" color="black">
              # images to add:
            </Typography>
            <Input
              defaultValue={selectedImageIds?.length}
              onChange={e => setNumImagesToAdd(Number(e.target.value))}
              type="number"
              boxProps={{ style: { width: '64px', height: '20px' } }}
            />
          </Box>
        </Box>
        <DiagnosisAddSliceModalSelectSlice
          selectedSlice={selectedSlice}
          setSelectedSlice={setSelectedSlice}
        />
      </Box>
    </Modal>
  );
}
