import { CategoryPropertyList } from '../../../../utils/LabelInterfaceUtils';
import { ObjectCountResult } from '../interfaces/apiResponse';

export function checkIfClassNameIsUndefined(classList: ObjectCountResult[]): boolean {
  return classList.some(classItem => classItem['name'] === undefined);
}

export function checkIfCategoryNameIsUndefined(
  categoryData: { categoryId: string; count: number }[],
  categoryConfig: CategoryPropertyList,
): boolean {
  // free response does not have 'options' and are excluded from this check
  const config: { id: string }[] = categoryConfig
    .filter(d => d.type !== 'free response')
    .flatMap(d => d.options);
  const configuredCategories = new Set(config.flatMap(d => d.id));

  const annotatedCategories = categoryData.flatMap(d => d.categoryId);
  return annotatedCategories.filter(x => !configuredCategories.has(x))?.length > 0;
}
