import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Chip,
  Link,
  LoadingIndicator,
  Modal,
  Tooltip,
  Typography,
} from '@superb-ai/norwegian-forest';
import { cloneDeep, intersection } from 'lodash';
import { useSnackbar } from 'notistack';

import { getUserManualUrl } from '../../../../../../consts/DocsLink';
import ProjectConst from '../../../../../../consts/ProjectConst';
import { useAuthInfo } from '../../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../../contexts/RouteContext';
import { useUsersInfo } from '../../../../../../contexts/UsersContext';
import AnalyticsTracker from '../../../../../../analyticsTracker';
import { getUrl } from '../../../../../../routes/util';
import CustomAutoLabelService from '../../../../../../services/CustomAutoLabelService';
import ProjectService from '../../../../../../services/ProjectService';
import { CustomAutoLabelType } from '../../../../../../types/customAutoLabelTypes';
import WorkappUnion from '../../../../../../union/WorkappUnion';
import LabelInterfaceUtils, {
  Category,
  LabelInterface,
  ObjectClass,
  treeIterator,
} from '../../../../../../utils/LabelInterfaceUtils';
import { overflowOverlayOrAuto } from '../../../../../../utils/style';
import Emoji from '../../../../../elements/Emoji';
import { Engine, getEngineLists, getPretrainedEngines } from '../helper';
import CategoryRow from './CategoryRow';
import { EngineList, ItemList } from './components';
import SelectCategories from './SelectCategories';
import SelectClasses from './SelectClasses';

const useStyles = makeStyles({
  card: {
    borderRadius: '3px',
    border: '1px solid #E5E5E5',
  },
  cardTitle: {
    fontSize: 14,
    lineHeight: '44px',
    fontWeight: 500,
  },
  engineChip: {
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block !important',
  },
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function CardTitle({ title, children }: { title: string; children?: ReactNode }) {
  const classes = useStyles();
  return (
    <Box bb px={1.5} display="flex" gap="6px" alignItems="center">
      <div className={classes.cardTitle}>{title}</div>
      {children}
    </Box>
  );
}

function Step({ title, tooltip }: { title: ReactNode; tooltip?: ReactNode }) {
  const content = <Chip>{title}</Chip>;
  return (
    <Box>
      {tooltip ? (
        <Tooltip anchorEl={<span>{content}</span>} placement="bottom-start">
          {tooltip}
        </Tooltip>
      ) : (
        content
      )}
    </Box>
  );
}

export default function AutoLabelSettingsModal(props: Props): JSX.Element {
  const { i18n, t } = useTranslation();
  const { CAL_MANUAL } = getUserManualUrl(i18n.language);
  const classes = useStyles();

  const usersInfo = useUsersInfo();
  const projectInfo = useProjectInfo();
  const { project } = projectInfo;

  const supportsCategory = WorkappUnion.isImageSiesta(project.workapp);

  const [labelInterface, setLabelInterface] = useState<LabelInterface>(project.labelInterface);

  useEffect(() => {
    setLabelInterface(cloneDeep(project.labelInterface));
  }, [project.labelInterface]);

  const objectClasses: ObjectClass[] = useMemo(() => {
    return LabelInterfaceUtils.getObjectClasses(labelInterface).filter(cls =>
      ProjectConst.AUTO_LABEL_TYPES.includes(cls.annotationType),
    );
  }, [labelInterface]);
  const categories: Category[] = useMemo(
    () => labelInterface.categorization?.properties,
    [labelInterface],
  );

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();

  const { isOpen, onClose } = props;

  const [isRequesting, setIsRequesting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [selectedItem, setSelectedItem] = useState<null | {
    id: string;
    type: 'category' | 'class';
  }>(null);

  const [isLoadingEngines, setIsLoadingEngines] = useState(false);
  const [engines, setEngines] = useState<Engine[]>([]);
  const [availableEngines, setAvailableEngines] = useState<Engine[]>([]);
  const [unavailableEngines, setUnavailableEngines] = useState<Engine[]>([]);
  const [selectedEngine, setSelectedEngine] = useState<Engine | null>(null);

  const [showUnavailableEngines, setShowUnavailableEngines] = useState(false);

  // Get actual selected item object
  const selectedItemInfo = useMemo(() => {
    if (!selectedItem) {
      return null;
    }
    if (selectedItem.type === 'class') {
      return objectClasses.find(c => c.id === selectedItem.id);
    }
    return categories.find(c => c.id === selectedItem.id);
  }, [objectClasses, categories, selectedItem]);

  // Calculate previously selected ids for the 3rd step
  const selectedIds = useMemo<string[]>(() => {
    if (!selectedEngine || !selectedItem) {
      return [];
    }
    if (selectedItem.type === 'class') {
      const objClass = selectedItemInfo as ObjectClass;
      return objClass.aiClassMap?.[0]?.engineId === selectedEngine?.id
        ? objClass?.aiClassMap[0].classIds
        : [];
    }
    if (selectedItem.type === 'category') {
      const category = selectedItemInfo as Category;
      return category.aiProperty?.propertyId ? [category.aiProperty.propertyId] : [];
    }
    return [];
  }, [selectedItem, selectedEngine, selectedItemInfo, labelInterface]);

  // Get all classIds from the current engine that are mapped to any class in the project.
  // This is used to disallow mapping the same engine class to multiple project classes.
  const mappedClassIds = useMemo<string[]>(() => {
    if (!selectedEngine) return [];

    // Get all engine class ids
    const objCls = LabelInterfaceUtils.getObjectClasses(selectedEngine.labelInterface);
    const objClsFlat = objCls.flatMap(cls => (cls.children ? [...treeIterator(cls)] : cls));
    const engineObjClsIds = objClsFlat.map(cls => cls.id);

    // For pretrained, the duplicate check only applies to object classes of same annotation type.
    const objClsSameType =
      selectedEngine.project.id === 'pretrained'
        ? objectClasses.filter(
            cls => cls.annotationType === (selectedItemInfo as ObjectClass).annotationType,
          )
        : objectClasses;

    // Find intersection of mapped applicable object classes and engine classes
    const objClsIds = objClsSameType.flatMap(cls =>
      cls.aiClassMap?.[0]?.engineId === selectedEngine.id
        ? intersection(cls.aiClassMap[0].classIds ?? [], engineObjClsIds)
        : [],
    );

    // Fix any
    return [...new Set(objClsIds)] as any;
  }, [selectedEngine, labelInterface, objectClasses, selectedItem, selectedItemInfo]);

  useEffect(() => {
    if (objectClasses.length) {
      setSelectedItem({ type: 'class', id: objectClasses[0].id });
    } else if (categories.length) {
      setSelectedItem({ type: 'category', id: categories[0].id });
    }
  }, [project]);

  const getReadyCustomAutoLabels = async () => {
    let page = 1;
    const pageSize = 50;
    const nextCustomAutoLabels: CustomAutoLabelType[] = [];
    let count = 0;
    do {
      const res = await CustomAutoLabelService.getCustomAutoLabelsInAccount({
        page,
        pageSize,
        stateIn: ['READY'],
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      nextCustomAutoLabels.push(...(res.results as CustomAutoLabelType[]));
      page += 1;
      count = res.count;
    } while (page * pageSize < count);
    return nextCustomAutoLabels;
  };

  /**
   * Load engine info
   */
  async function loadEngines() {
    setIsLoadingEngines(true);
    const pretrainedEngines = getPretrainedEngines(labelInterface);
    const calEngines = await getReadyCustomAutoLabels();
    setEngines([...pretrainedEngines, ...calEngines]);
    setIsLoadingEngines(false);
  }
  useEffect(() => {
    loadEngines();
  }, []);

  /**
   * Update visible engines
   */
  useEffect(() => {
    if (!selectedItem) return;

    const [availableEngines, unavailableEngines] = getEngineLists({
      engines,
      selectedItem,
      objectClasses,
      categories,
      projectId: projectInfo.project.id,
    });
    setAvailableEngines(availableEngines);
    setUnavailableEngines(unavailableEngines);

    // If previously selected engine is not part of available engines or none was selected,
    // reset to currently configured one or first availabl
    let defaultEngineId = (selectedItemInfo as ObjectClass)?.aiClassMap?.[0]?.engineId;
    if (!defaultEngineId) defaultEngineId = (selectedItemInfo as Category)?.aiProperty?.engineId;
    if (
      !defaultEngineId &&
      selectedEngine &&
      availableEngines.find(engine => engine.id === selectedEngine.id)
    )
      defaultEngineId = selectedEngine.id;

    setSelectedEngine(
      availableEngines.find(eng => eng.id === defaultEngineId) ?? availableEngines[0] ?? null,
    );
    setShowUnavailableEngines(false);
  }, [engines, objectClasses, categories, selectedItemInfo]);

  function handleClose() {
    onClose();
  }

  async function save() {
    setIsRequesting(true);
    await ProjectService.editLabelInterface({
      labelInterface,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    // Tracking
    const mappedCategories = LabelInterfaceUtils.getObjectClasses(labelInterface).filter(
      cls => cls.aiClassMap?.[0]?.engineId,
    ).length;
    const mappedClasses = (labelInterface?.categorization?.properties ?? []).filter(
      prop => prop.aiProperty,
    ).length;
    AnalyticsTracker.autoLabelConfigured({
      accountId: authInfo.accountName ?? '',
      projectId: routeInfo.urlMatchInfo.projectId,
      mappedCategories,
      mappedClasses,
    });

    await projectInfo.updateProjectInfo();
    setIsRequesting(false);
    setTimeout(() => {
      enqueueSnackbar(t('autoLabel.settings.messages.succesfullyChangedSettings'), {
        variant: 'success',
      });
    }, 0);
    onClose();
  }

  const onChangeClassIds = useCallback(
    (classIds: string[]) => {
      if (!selectedItem || !selectedEngine) return;
      setIsChanged(true);

      const objClass = objectClasses.find(cls => cls.id === selectedItem.id);
      if (!objClass) return;
      const aiClassMap = !classIds?.length
        ? []
        : [
            {
              classIds,
              engineId: selectedEngine.id,
            },
          ];
      // @ts-ignore: legacy workapp
      if (objClass.info) {
        // @ts-ignore: legacy workapp
        objClass.info.aiClassMap = aiClassMap;
      } else {
        objClass.aiClassMap = aiClassMap;
      }
      setLabelInterface({ ...labelInterface });
    },
    [objectClasses, labelInterface, selectedItem, selectedEngine],
  );

  const onChangeCategoryIds = useCallback(
    (ids: string[]) => {
      if (!selectedItem || !selectedEngine) return;
      setIsChanged(true);

      const category = categories.find(cls => cls.id === selectedItem.id);
      if (!category) return;
      category.aiProperty = ids[0]
        ? {
            simple: true,
            propertyId: ids[0],
            engineId: selectedEngine.id,
          }
        : undefined;
      setLabelInterface({ ...labelInterface });
    },
    [categories, labelInterface, selectedItem, selectedEngine],
  );

  // Reset button is only enabled when selectedItem has any AI classes/proprties.
  const canReset = useMemo(() => {
    if (selectedItem?.type === 'class') {
      const objClass = objectClasses.find(cls => cls.id === selectedItem.id);
      // @ts-ignore: legacy workapp
      return !!(objClass?.aiClassMap?.length ?? objClass?.info?.aiClassMap?.length);
    }
    if (selectedItem?.type === 'category') {
      const category = categories.find(cls => cls.id === selectedItem.id);
      return !!category?.aiProperty;
    }
    return false;
  }, [selectedItem, labelInterface, categories, objectClasses]);

  function resetSelectedItem() {
    if (selectedItem?.type === 'class') {
      onChangeClassIds([]);
    }
    if (selectedItem?.type === 'category') {
      onChangeCategoryIds([]);
    }
  }

  const engineDisabledText =
    selectedItem?.type === 'class'
      ? t('autoLabel.settings.messages.aiDoesNotMatchSelectedClass')
      : t('autoLabel.settings.messages.aiDoesNotMatchSelectedCategory');

  function getStep3Content() {
    if (!selectedItemInfo) {
      return (
        <Box m={1.5}>
          <Typography variant="body3">
            {t('autoLabel.settings.mapping.selectClassOrCategory')}
          </Typography>
        </Box>
      );
    }
    if (isLoadingEngines) {
      return (
        <Box m={1.5}>
          <Typography variant="body3">{t('button.loading')}</Typography>
        </Box>
      );
    }
    if (!selectedEngine) {
      const nonAvailableText = [
        t('autoLabel.settings.messages.noMatchingAiForItem', { name: selectedItemInfo.name }),
        selectedItem?.type === 'class'
          ? t('autoLabel.settings.messages.aiHasToMatchClass')
          : t('autoLabel.settings.messages.aiHasToMatchCategory'),
      ].join(' ');

      return (
        <Box m={1.5}>
          <Typography variant="body3">{nonAvailableText}</Typography>
          <Typography variant="body3">
            <Trans t={t} i18nKey="autoLabel.settings.messages.forHelpConsultManual">
              For help setting up Custom Auto-Labeling, please consult{' '}
              <Link
                to={CAL_MANUAL}
                themedColor="secondary"
                external
                target="_blank"
                rel="noopener noreferrer"
              >
                our manual
              </Link>
              .
            </Trans>
          </Typography>
        </Box>
      );
    }
    if (selectedItem?.type === 'class') {
      return (
        <SelectClasses
          selectedItem={selectedItemInfo as ObjectClass}
          engine={selectedEngine}
          value={selectedIds}
          onChange={onChangeClassIds}
          mappedClassIds={mappedClassIds}
        />
      );
    }
    if (selectedItem?.type === 'category') {
      return (
        <SelectCategories
          selectedItem={selectedItemInfo as Category}
          engine={selectedEngine}
          value={selectedIds}
          onChange={onChangeCategoryIds}
        />
      );
    }
    return <></>;
  }

  const showHideUnavailableButton = (
    <Box mx={1.5} mt={1} mb={2}>
      <Button
        size="xs"
        variant="soft-fill"
        color="grey"
        onClick={() => setShowUnavailableEngines(u => !u)}
      >
        {showUnavailableEngines
          ? t('autoLabel.settings.mapping.hideUnavailableEngines')
          : t('autoLabel.settings.mapping.showUnavailableEngines')}
      </Button>
    </Box>
  );

  return (
    <>
      <Modal
        open={isOpen}
        close={{
          onClose: handleClose,
          canClickOutside: false,
          canCloseWithExit: true,
        }}
        title={
          <Typography variant="headline4">
            {' '}
            {t('autoLabel.settings.mapping.settingText')}
          </Typography>
        }
        mainButton={{
          text: t('button.save'),
          onClick: () => {
            save();
          },
          disabled: !isChanged,
          isLoading: isRequesting,
        }}
        subButton={{
          text: t('button.cancel'),
          onClick: handleClose,
        }}
      >
        <Box
          width="80vw"
          maxWidth="1800px"
          minWidth="1000px"
          height="calc(100vh - 180px)"
          minHeight="450px"
          maxHeight="900px"
          display="flex"
        >
          <Box mx={4} mt={1} display="flex" width="100%">
            <Box display="grid" gridTemplateColumns="1fr 1fr 2fr" gap={16} width="100%">
              <Box display="flex" flexDirection="column" minHeight={0}>
                <Step
                  title={t('autoLabel.settings.mapping.stepN', { n: 1 })}
                  tooltip={t('autoLabel.settings.mapping.step1Tooltip')}
                />
                <Box
                  className={classes.card}
                  mt={1}
                  flex={1}
                  minHeight={0}
                  display="flex"
                  flexDirection="column"
                >
                  <CardTitle title={t('labelInterface.objectClasses')} />
                  <Box minHeight={45} overflow={overflowOverlayOrAuto()} bb>
                    {objectClasses?.length ? (
                      <ItemList
                        items={objectClasses}
                        selectedId={selectedItem?.id}
                        onClickItem={id => setSelectedItem({ type: 'class', id })}
                      />
                    ) : (
                      <Box p={1.5}>
                        <Typography variant="body4" themedColor="cloud">
                          {t('autoLabel.settings.messages.noClassesConfigured')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <CardTitle title={t('labelInterface.categories')} />

                  <Box minHeight={45} overflow={overflowOverlayOrAuto()}>
                    {supportsCategory && categories?.length ? (
                      <ItemList
                        items={categories}
                        selectedId={selectedItem?.id}
                        onClickItem={id => setSelectedItem({ type: 'category', id })}
                      />
                    ) : (
                      <Box p={1.5}>
                        <Typography variant="body4" themedColor="cloud">
                          {supportsCategory
                            ? t('autoLabel.settings.messages.noCategoriesConfigured')
                            : t('autoLabel.settings.messages.unsupportedCategoryProject')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" minHeight={0}>
                <Step
                  title={t('autoLabel.settings.mapping.stepN', { n: 2 })}
                  tooltip={t('autoLabel.settings.mapping.step2Tooltip')}
                />
                <Box
                  className={classes.card}
                  mt={1}
                  minHeight={0}
                  flex={1}
                  display="flex"
                  flexDirection="column"
                >
                  <CardTitle title={t('autoLabel.autoLabelAI')} />
                  {isLoadingEngines ? (
                    <Box minHeight={45} display="flex" justifyContent="center" p={2}>
                      <LoadingIndicator />
                    </Box>
                  ) : engines?.length ? (
                    <Box minHeight={45} overflow={overflowOverlayOrAuto()}>
                      {availableEngines.length > 0 && (
                        <EngineList
                          title={t('autoLabel.settings.mapping.availableEngines')}
                          items={availableEngines}
                          selectable
                          selectedId={selectedEngine?.id}
                          onClickItem={engine => setSelectedEngine(engine)}
                        />
                      )}
                      {unavailableEngines.length > 0 && (
                        <>
                          {showHideUnavailableButton}
                          {showUnavailableEngines && (
                            <>
                              <EngineList
                                title={t('autoLabel.settings.mapping.unavailableEngines')}
                                items={unavailableEngines}
                                tooltipText={engineDisabledText}
                              />
                              {showHideUnavailableButton}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box p={1.5}>
                      <Typography variant="body4" themedColor="cloud">
                        {t('autoLabel.settings.messages.noAIsAvailable')}
                      </Typography>
                    </Box>
                  )}
                  <Box p={1} mt="auto" bt>
                    <Typography variant="body4">
                      <Emoji symbol="👉" />{' '}
                      <Link
                        underlined
                        to={getUrl([
                          routeInfo.urlMatchInfo.accountName,
                          'label',
                          'project',
                          routeInfo.urlMatchInfo.projectId,
                          'auto-label',
                          'cal',
                        ])}
                      >
                        {t('autoLabel.settings.mapping.goToCal')}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" minHeight={0}>
                <Step
                  title={t('autoLabel.settings.mapping.stepN', { n: 3 })}
                  tooltip={t('autoLabel.settings.mapping.step3Tooltip')}
                />
                {selectedItem?.type === 'category' && selectedItemInfo && (
                  <>
                    <Box className={classes.card} mt={1} mb={1}>
                      <CardTitle title={t('autoLabel.settings.mapping.selectedCategory')} />
                      <Box
                        themedBackgroundColor={['secondary', 50]}
                        themedColor="secondary"
                        p={1.5}
                      >
                        <CategoryRow item={selectedItemInfo as Category} />
                      </Box>
                    </Box>
                  </>
                )}

                <Box
                  className={classes.card}
                  mt={1}
                  minHeight={0}
                  flex={1}
                  display="flex"
                  flexDirection="column"
                >
                  <CardTitle
                    title={`${
                      selectedItem?.type === 'class'
                        ? t('labelInterface.objectClasses')
                        : t('labelInterface.categories')
                    }${selectedEngine ? ' from' : ''}`}
                  >
                    {selectedEngine && (
                      <>
                        <Chip color="cobalt" size="m" className={classes.engineChip}>
                          {selectedEngine.name}
                        </Chip>
                        <Box ml="auto">
                          <Button
                            variant="text"
                            onClick={() => resetSelectedItem()}
                            disabled={!canReset}
                          >
                            {t('button.remove')}
                          </Button>
                        </Box>
                      </>
                    )}
                  </CardTitle>

                  {getStep3Content()}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
