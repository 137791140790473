import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Icon, Link, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { AlertModalProps } from '@superb-ai/norwegian-forest/dist/components/composition/AlertModal/types';
import cn from 'classnames';
import { concat, isEmpty, some, uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { parse } from 'papaparse';

import {
  CSV_UPLOAD_EMPTY_ROW,
  CSV_UPLOAD_SAME_DATA_KEY,
  CSV_UPLOAD_SAME_DATA_KEY_WITH_INFO,
  CSV_UPLOAD_TYPE_NOT_SUPPORTED,
  CSV_UPLOAD_WRONG_HEADER_FORMAT,
} from '../../../consts/SnackbarMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { CsvFormat, useUploadInfo } from '../../../contexts/UploadContext';
import { useMetering } from '../../../queries/useMeteringQuery';
import DragAndDropCsvList from './DragAndDropCsvList';

const useStyles = makeStyles(() => ({
  uploadIcon: {
    marginRight: '5px',
    color: '#4d4d4d',
  },
  boxOverflow: {
    overflowY: 'scroll',
  },
  dropzone: {
    '&.isDragActive': {
      backgroundColor: '#ff615a12',
    },
  },
}));

const DragAndDropCsv: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useAlertModal();
  const routeInfo = useRouteInfo();
  const fileInfo = useUploadInfo();
  const { leftQuantity: dataCapacity } = useMetering('label:data-volume');

  const readCsv = (acceptedFiles: File[]) => {
    const reader = new FileReader();
    let emptyCsvRowCount = 0;
    let hasSameDataKey: string | null | undefined = null;

    reader.readAsBinaryString(acceptedFiles[0]);
    reader.onload = () => {
      const results = parse<CsvFormat>(reader.result as any, {
        header: true,
        skipEmptyLines: true,
      });
      const { data } = results;

      if (!data || !data[0]) return;
      if (data[0].dataKey === undefined || data[0].url === undefined) {
        enqueueSnackbar(CSV_UPLOAD_WRONG_HEADER_FORMAT({ t }), { variant: 'error' });
        return;
      }
      data.every(row => {
        if (row.dataKey === '' || row.url === '') {
          ++emptyCsvRowCount;
        }
        if (some(fileInfo.urls, ['dataKey', row.dataKey])) {
          hasSameDataKey = row.dataKey;
        }
        if (hasSameDataKey) {
          return false;
        }
        return true;
      });

      if (emptyCsvRowCount) {
        enqueueSnackbar(CSV_UPLOAD_EMPTY_ROW({ t, emptyCsvRowCount }), {
          variant: 'error',
        });
        emptyCsvRowCount = 0;
        return;
      }

      if (hasSameDataKey) {
        enqueueSnackbar(CSV_UPLOAD_SAME_DATA_KEY_WITH_INFO({ t, hasSameDataKey }), {
          variant: 'error',
        });
        hasSameDataKey = null;
        return;
      }

      if (uniqBy(data, 'dataKey').length < data.length) {
        enqueueSnackbar(CSV_UPLOAD_SAME_DATA_KEY({ t }), { variant: 'error' });
        return;
      }

      if (dataCapacity - data.length < 0) {
        openModal({
          variant: 'warning',
          content: (
            <>
              <Typography variant="body3">
                You’ve reached your usage limit. <br />
                Please upgrade your plan to continue using Suite.
              </Typography>
              <Link
                variant="body3"
                underlined
                themedColor="secondary"
                onClick={() => window.open(`//${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`)}
              >
                Learn about our pricing plans here.
              </Link>
            </>
          ),
          title: 'Upgrade Plan',
          mainButton: {
            text: 'UPGRADE',
            onClick: () => {
              routeInfo.history.push(`/${routeInfo.urlMatchInfo.accountName}/plan`);
              closeModal();
            },
          },
          subButton: {
            text: 'Close',
            onClick: () => closeModal(),
          },
        } as AlertModalProps);
      }

      fileInfo.setUrls(concat(fileInfo.urls, data));
    };
  };

  const handleAddUrls = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (!isEmpty(rejectedFiles)) {
      enqueueSnackbar(CSV_UPLOAD_TYPE_NOT_SUPPORTED({ t }), { variant: 'error' });
      return;
    }

    readCsv(acceptedFiles);
  };

  const onClickDropzone = () => {
    // TODO (moon) replace with uploadData event
    // AnalyticsTracker.selectCsvFileClicked({
    //   action: 'Clicked',
    //   category: 'upload',
    //   type: 'image-csv',
    // });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => handleAddUrls(acceptedFiles, rejectedFiles),
    accept: {
      'text/comma-separated-values': [],
      'text/csv': [],
      'application/csv': [],
      'application/excel': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/vnd.msexcel': ['.csv'],
    },
    // maxSize: 1,
  });

  return (
    <Box width={600} height={270} border="1px dashed #ddd">
      {isEmpty(fileInfo.urls) ? (
        <Box
          {...(getRootProps({
            onClick: onClickDropzone,
          }) as Partial<React.ComponentProps<typeof Box>>)}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          themedBackgroundColor={['grey', 50]}
          className={cn('clickable', classes.dropzone, { isDragActive })}
        >
          <input {...getInputProps()} />
          <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
            <Icon name="cloudUp" size="62px" color={['grey', 500]} />
          </Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            Drag & Drop to upload file or Click here
          </Typography>
          <Typography variant="body4" themedColor="secondary">
            Only support CSV files
          </Typography>
        </Box>
      ) : (
        <Box {...({ ...getRootProps(), onFocus: undefined, onClick: undefined } as any)} m={1}>
          <input {...getInputProps()} />
          <DragAndDropCsvList />
        </Box>
      )}
    </Box>
  );
};

export default DragAndDropCsv;
