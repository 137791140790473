import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { ReactComponent as IpWhitelistIcon } from '../../../../assets/img/ip_whitelist.svg';
import { ReactComponent as MfaIcon } from '../../../../assets/img/mfa_shield.svg';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { openChatWidget } from '../../../../utils/chatWidget';
import SecurityTutorialBottom from './SecurityTutorialBottom';
import SecurityTutorialTop from './SecurityTutorialTop';

export default function SecurityTutorial({ isEnterprise }: { isEnterprise: boolean }) {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { accountName } = routeInfo.urlMatchInfo;

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <SecurityTutorialTop
        title={t('settings.security.securityTutorialTitle')}
        description={t('settings.security.securityTutorialDescription')}
        buttonText={
          isEnterprise
            ? t('settings.security.securityTutorialButtonEnterprise')
            : t('settings.security.securityTutorialButton')
        }
        buttonLink={
          isEnterprise
            ? () => openChatWidget()
            : () => window.open(`https://${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`)
        }
      />

      <Box display="flex" justifyContent="space-between">
        <SecurityTutorialBottom
          icon={MfaIcon}
          title={t('settings.security.securityTutorialMfaTitle')}
          description={t('settings.security.securityTutorialMfaDescription')}
          learnMoreLink="https://www.superb-ai.com"
          learnMoreText={t('settings.security.securityTutorialMfaLearnMore')}
        />
        <SecurityTutorialBottom
          icon={IpWhitelistIcon}
          title={t('settings.security.securityTutorialIpWhitelistTitle')}
          description={t('settings.security.securityTutorialIpWhitelistDescription')}
          learnMoreLink="https://www.superb-ai.com"
          learnMoreText={t('settings.security.securityTutorialIpWhitelistLearnMore')}
        />
      </Box>
    </Box>
  );
}
