import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, useDialogState } from '@superb-ai/ui';

import { SchedulePause } from '../components/components';
import {
  useEndpointSchedulingMutation,
  useModelEndpointDetailQuery,
} from '../queries/modelQueries';
import { EndpointScheduling } from '../services/types';

export const SchedulePauseDialog = ({
  id,
  state,
}: {
  id: string;
  state: ReturnType<typeof useDialogState>;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [schedulingTimeList, setSchedulingTimeList] = useState<EndpointScheduling[]>([]);
  const { mutate } = useEndpointSchedulingMutation();
  const { data } = useModelEndpointDetailQuery({ id });

  useEffect(() => {
    if (!data || !data.endpointScheduling.length) return;
    setSchedulingTimeList(data.endpointScheduling);
    setOpen(true);
  }, [data]);

  return (
    <Dialog state={state} hideOnClickOutside={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.endpoints.schedulePause')}
      </Dialog.Header>
      <SchedulePause
        open={open}
        setOpen={setOpen}
        schedulingTimeList={schedulingTimeList}
        setSchedulingTimeList={setSchedulingTimeList}
      />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          onClick={() => {
            if (open) {
              mutate({
                id,
                endpointScheduling: schedulingTimeList,
              });
            } else {
              mutate({
                id,
                endpointScheduling: [],
              });
            }
            state.setVisible(false);
          }}
          color="primary"
          variant="strong-fill"
        >
          {t('shared.apply')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
