import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem, Popover } from '@mui/material';
import { Box, Card, Icon, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { RESEND_INVITATION, UNKNOWN_ERROR } from '../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AccountService from '../../../services/AccountService';
import { MemberData } from '../../../types/memberTypes';

type MemberSettingMenuProps = {
  member: MemberData;
  isDeletable: boolean;
  isUserNotActive: boolean;
  onClickDelete: (member: MemberData) => void;
  target: 'Project' | 'Team';
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const MemberSettingMenu: React.FC<MemberSettingMenuProps> = ({
  isDeletable,
  isUserNotActive,
  onClickDelete,
  member,
  target,
  children,
  anchorEl,
  setAnchorEl,
}) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useProjectInfo();

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClickDelete(member);
  };

  const handleClickResendEmail = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    try {
      if (target === 'Project') {
        await AccountService.resendProjectInvitation({
          email: member.email,
          projectId: project.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      } else {
        await AccountService.resendInvitation({
          email: member.email,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      }
      enqueueSnackbar(RESEND_INVITATION({ t, email: member.email }), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
    }
  };

  if (!isDeletable) return <></>;

  return (
    <>
      <IconButton
        variant="text"
        color="lightgrey"
        size="l"
        onClick={handleOpen}
        icon="moreVertical"
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card>
          <MenuItem onClick={handleClickDelete}>
            <Box mr={0.5} display="flex" alignItems="center">
              <Icon name="trash" color="primary" />
            </Box>
            <Typography color="primary" variant="body5">
              {t(`projectMembers.button.deleteUserFrom${target}`)}
            </Typography>
          </MenuItem>
          {isUserNotActive && (
            <MenuItem onClick={handleClickResendEmail}>
              <Box mr={0.5} display="flex" alignItems="center">
                <Icon name="email" />
              </Box>
              <Typography variant="body5">{t('projectMembers.button.resendEmail')}</Typography>
            </MenuItem>
          )}{' '}
          {children}
        </Card>
      </Popover>
    </>
  );
};

export default MemberSettingMenu;
