import { useTranslation } from 'react-i18next';

import { Typography } from '@superb-ai/ui';

import { useDiagnosisModelContext } from '../../../../../../../contexts/DiagnosisModelContext';
import { AP_THRESHOLD_COLOR } from '../colorScale';
import { isColumnClassName } from './PerformanceTableChartArea';

type Props = {
  columnKey: string;
};

export const HeaderCell = (props: Props) => {
  const { t } = useTranslation();
  const { columnKey } = props;
  const { targetIou } = useDiagnosisModelContext();
  const realColumnKey = columnKey === 'ap' || columnKey === 'ap50' ? 'apAbbr' : columnKey;
  const displayName = t(`curate.diagnosis.classMetrics.${realColumnKey}`);

  const iouThreshold = {
    ap: '(%) @0.5:0.95',
    ap50: '(%) @0.5',
    precision: ` @${targetIou}`,
    recall: ` @${targetIou}`,
  }[columnKey];

  const tooltipContent = !isColumnClassName(columnKey)
    ? t(`curate.diagnosis.classMetrics.columnTooltip.${columnKey}`)
    : undefined;

  return (
    // <Tooltip hideOnEmptyContent content={tooltipContent} placement="top">
    <Typography variant="m-regular">
      {displayName}
      <text
        style={{
          alignItems: 'center',
          fontSize: '11px',
          color: AP_THRESHOLD_COLOR,
          letterSpacing: '0.5px',
        }}
      >
        {iouThreshold ?? ''}
      </text>
    </Typography>
    // </Tooltip>
  );
};
