import { CreateMenuItem } from '../../../menu';
import { EditProjectMenuItem } from '../../new-project/MenuItem';
import { ProjectAdvancedAIFeaturesMenuItem } from './advancedAiFeatures/MenuItem';
import { ProjectAnalyticsMenuItem } from './analytics/MenuItem';
import { ProjectMyAnalyticsMenuItem } from './analytics/myAnalytics/MenuItem';
import { ProjectAutoLabelMenuItem } from './autoLabel/MenuItem';
import { ProjectExportMenuItem } from './export/MenuItem';
import { ProjectLabelsMenuItem } from './labels/MenuItem';
import { Layout } from './Layout';
import { ProjectMembersMenuItem } from './members/MenuItem';
import { ProjectOverviewMenuItem } from './overview/MenuItem';
import { path } from './path';
import { ProjectSettingsMenuItem } from './settings/MenuItem';

export const ProjectMenuItem = CreateMenuItem({
  path,
  component: Layout,
  children: [
    ProjectOverviewMenuItem,
    ProjectLabelsMenuItem,
    ProjectAutoLabelMenuItem,
    ProjectAdvancedAIFeaturesMenuItem,
    ProjectExportMenuItem,
    ProjectAnalyticsMenuItem,
    ProjectMyAnalyticsMenuItem,
    ProjectMembersMenuItem,
    ProjectSettingsMenuItem,
    EditProjectMenuItem,
  ],
});
