import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';

import { Box } from '@superb-ai/norwegian-forest';
import { atoms } from '@superb-ai/ui';

import { MislabelDetectionResultsPerClass } from '../../../../types/mislabelDetectionTypes';
import ResultViewListItem from './ResultViewListItem';

export default function ResultViewList({
  results,
  columns = 5,
  setSelectedItem,
  setSelectedClass,
}: {
  results: MislabelDetectionResultsPerClass[];
  columns?: number;
  setSelectedItem: Dispatch<SetStateAction<number | undefined>>;
  setSelectedClass: Dispatch<SetStateAction<MislabelDetectionResultsPerClass | undefined>>;
}) {
  const [sectionSize, setSectionSize] = useState<[number, number, number, number]>(); // [top, left, width, height]
  const measuredRef = useRef<Element>();

  const resizeCallback = useCallback(entries => {
    const [entry] = entries;
    if (entry.contentRect) {
      const { innerHeight } = window;
      const { top, left } = entry.target.getBoundingClientRect();
      const { width, y } = entry.contentRect;
      setSectionSize([top, left, width, innerHeight - top - y * 2]);
    }
  }, []);

  useEffect(() => {
    if (!measuredRef.current) return;
    const resizeObserver = new ResizeObserver(resizeCallback);
    resizeObserver.observe(measuredRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const className = atoms({ overflow: 'auto' });
  const gap = 12;
  const titleSize = 24;
  const yMargin = 24;

  const getItemSize = (index: number) =>
    sectionSize
      ? results[index].class_instance_num && results[index].class_instance_num < 5
        ? titleSize + yMargin + (sectionSize[2] - gap * (columns - 1)) / columns
        : titleSize + yMargin + ((sectionSize[2] - gap * (columns - 1)) / columns) * 2 + gap
      : 0;

  return (
    <Box py={2} px={4} ref={measuredRef}>
      {sectionSize && (
        <VariableSizeList
          className={className}
          width={sectionSize[2]}
          height={sectionSize[3]}
          itemSize={getItemSize}
          itemCount={results.length}
          itemData={{ results, columns, setSelectedItem, setSelectedClass }}
        >
          {ResultViewListItem}
        </VariableSizeList>
      )}
    </Box>
  );
}
