import { map, range } from 'lodash';

import { ScoreBinInfo } from '../charts/barChart/histogramUtil';
import { D3TimeParse } from '../tools/d3Helper';

function gaussianRandom(number: number) {
  let x;
  if (number > 95) {
    x = Math.round(number * Math.random() * 1);
  } else if (number > 90) {
    x = Math.round(number * Math.random() * 100);
  } else if (number > 80) {
    x = Math.round(number * Math.random() * 150 + 20);
  } else if (number > 70) {
    x = Math.round(number * Math.random() * 100);
  } else if (number > 60) {
    x = Math.round(number * Math.random() * 80);
  } else if (number > 50) {
    x = Math.round(number * Math.random() * 20);
  } else if (number > 30 && number < 35) {
    x = Math.round(number * Math.random() * 170);
  } else {
    x = Math.round(number * Math.random() * 10);
  }
  return x;
}

export function getMockChartPercents(): number[] {
  return [0, 0];
}
export function getMockChartScores(): number[] {
  return [0, 0];
}

export function getMockChartDates(): Date[] {
  return [new Date('2021-01-01'), new Date('2021-01-02')];
}

function getMockChartData(chartName: string) {
  if (chartName === 'projectProgress') {
    return [
      {
        cumSkipped: 0,
        cumSubmitted: 0,
        cumWorking: 0,
        date: D3TimeParse.daily('2020-12-24'),
        submitted: 0,
      },
      {
        cumSkipped: 0,
        cumSubmitted: 0,
        cumWorking: 0,
        date: D3TimeParse.daily('2020-12-25'),
        submitted: 0,
      },
      {
        cumSkipped: 0,
        cumSubmitted: 0,
        cumWorking: 0,
        date: D3TimeParse.daily('2020-12-26'),
        submitted: 0,
      },
      {
        cumSkipped: 0,
        cumSubmitted: 0,
        cumWorking: 0,
        date: D3TimeParse.daily('2020-12-27'),
        submitted: 0,
      },
      {
        cumSkipped: 0,
        cumSubmitted: 0,
        cumWorking: 0,
        date: D3TimeParse.daily('2020-12-28'),
        submitted: 0,
      },
    ];
  }
  if (chartName === 'objectClassStats') {
    return [
      {
        count: 30,
        date: '2020-11-24',
        displayName: 'Car',
        name: 'Car',
        percentTotal: 30,
      },
      {
        count: 30,
        date: '2020-11-24',
        displayName: 'Person',
        name: 'Person',
        percentTotal: 30,
      },
      {
        count: 40,
        date: '2020-11-24',
        displayName: 'Animal',
        name: 'Animal',
        percentTotal: 40,
      },
    ];
  }
  if (chartName === 'imageCategoryStats') {
    return [
      // TODO (mlimb): add mock data
    ];
  }
  if (chartName === 'workerStats') {
    return [
      {
        assignee: 'Obi-Wan Kenobi',
        assignee_display_name: 'Obi-Wan Kenobi',
        assignee_name: 'Obi-Wan Kenobi',
        cumSkipped: 0,
        cumSubmitted: 6,
        cumWorking: 20,
        currentTotal: 26,
        date: '2020-11-23',
        total: 26,
      },
      {
        assignee: 'Leia Skywalker',
        assignee_display_name: 'Leia Skywalker',
        assignee_name: 'Leia Skywalker',
        cumSkipped: 0,
        cumSubmitted: 4,
        cumWorking: 4,
        currentTotal: 8,
        date: '2020-11-23',
        total: 8,
      },
      {
        assignee: 'Han Solo',
        assignee_display_name: 'Han Solo',
        assignee_name: 'Han Solo',
        cumSkipped: 1,
        cumSubmitted: 0,
        cumWorking: 1,
        currentTotal: 2,
        date: '2020-11-23',
        total: 2,
      },
    ];
  }
  return [];
}

export const chartMockData = {
  projectProgress: getMockChartData('projectProgress'),
  objectClassStats: getMockChartData('objectClassStats'),
  imageCategoryStats: getMockChartData('imageCategoryStats'),
  workerStats: getMockChartData('workerStats'),
};

export function getQAChartMockData(): ScoreBinInfo[] {
  return map(range(0, 101, 1), d => {
    return { scoreBin: d, labelCount: gaussianRandom(d) } as ScoreBinInfo;
  });
}

export const mockTableData = [
  {
    name: 'Obi-Wan Kenobi',
    email: 'obi-wan@ai.com',
    role: 'Admin',
    labelCounts: 3000,
    objectCounts: 6000,
    frameCounts: 0,
    timespentSec: 360000,
    timePerLabelSec: 120,
    boxType: 6000,
    progress: 25,
  },
  {
    name: 'Leia Skywalker',
    email: 'lea@ai.com',
    role: 'Manager',
    labelCounts: 2000,
    objectCounts: 4000,
    frameCounts: 0,
    timespentSec: 1400006,
    timePerLabelSec: 70,
    boxType: 4000,
    progress: 80,
  },
  {
    name: 'Han Solo',
    email: 'solo@ai.com',
    role: 'Labeler',
    labelCounts: 1500,
    objectCounts: 900,
    frameCounts: 0,
    timespentSec: 97500,
    timePerLabelSec: 65,
    boxType: 900,
    progress: 50,
  },
];
