import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR, RESEND_ACCOUNT_ACTIVATION_LINK } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useAuthService } from '../../../services/NewAuthService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import GoToPage from './GoToPage';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';

export default function CodeMismatchLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { openModal, closeModal } = useAlertModal();
  const { resendCode } = useAuthService();
  const { tenantId, email } = routeInfo.params;

  useChangeContent('graphic');

  useEffect(() => {
    if (!tenantId || !email) {
      routeInfo.history.push('/auth/login');
    }
  }, [tenantId, email]);

  const handleClickResendCode = async (): Promise<void> => {
    if (isRequesting) return;

    try {
      setIsRequesting(true);
      await resendCode({ email: atob(email), tenantUuid: tenantId });
      openModal(RESEND_ACCOUNT_ACTIVATION_LINK({ t, closeModal }));
    } catch (e: any) {
      openModal(DEFAULT_ERROR({ t, message: e.message, onClickMainButton: closeModal }));
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <>
      <ShortCut page="signIn" />
      <Box width="100%" display="flex" flexDirection="column" gap={2}>
        <Title>{t('auth.title.invalidActivationLink')}</Title>
        <Typography variant="m-regular">
          <Trans t={t} i18nKey="auth.messages.clickToResendActivationLink">
            <LinkTypography color="secondary" onClick={handleClickResendCode}>
              Click here to resend
            </LinkTypography>{' '}
            account activation link.
          </Trans>
        </Typography>
        <GoToPage to="login">{t('auth.goToSignIn')}</GoToPage>
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
