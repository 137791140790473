import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import ImageContext from '../../../../contexts/ImageContext';
import StageSizeContext from '../../../../contexts/StageSizeContext';
import ImageSection from './ImageSection';

const useStyles = makeStyles(() => ({
  imageSection: {
    width: '100%',
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  footer: {
    width: '100%',
    background: 'white',
    height: '20px',
  },
}));

const ImageLayout = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <StageSizeContext.Provider>
        <ImageContext.Provider>
          <ImageSection />
        </ImageContext.Provider>
      </StageSizeContext.Provider>
      <MUI.Box className={classes.footer} />
    </>
  );
};

export default ImageLayout;
