import { Trans, useTranslation } from 'react-i18next';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import FailedViewImage from './assets/failed_view.png';

export default function FailedView() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
      position="absolute"
      top="0"
      left="0"
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        <Image src={FailedViewImage} width={356} height={356} alt="FailedViewImage" />
        <Typography variant="h2" color="gray-400">
          {t('analytics.exportsAnalytics.failed')}
        </Typography>
      </Box>
      <Typography variant="m-regular" color="gray-300">
        <Trans
          t={t}
          i18nKey="analytics.exportsAnalytics.pleaseContact"
          components={{
            email: (
              <LinkTypography
                color="secondary"
                as="a"
                href="mailto: support@superb-ai.com"
                variant="m-regular"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
}
