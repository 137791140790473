import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import FilterClassDropdownListItem from './FilterClassDropdownListItem';

const useStyles = makeStyles(theme => ({
  subHeader: {
    fontSize: '10px',
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
  },
}));

const convertImageToVideo = (annoType: string, workapp: WorkApp): string => {
  if (WorkappUnion.isVideoApp(workapp) && annoType === 'Image Category') {
    return 'Video Category';
  }

  return annoType;
};

interface Props {
  index: number;
  onValueChange: (index: number, objKey: string, option: string) => void;
  objKey: string;
  onChangeCurrentRow: (index: number) => void;
  selectedOption: { label: string; value: string };
  searchedAnnoOptions: any;
}

const FilterClassDropdownList = ({
  index,
  objKey,
  onValueChange,
  selectedOption,
  searchedAnnoOptions,
  onChangeCurrentRow,
}: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const projectInfo = useProjectInfo();
  const [flatAnnoOptions] = useState(projectInfo.flatAnnoOptions);

  const handleClickCheck = (value: any) => () => {
    onValueChange(index, objKey, value);
  };

  useEffect(() => {
    onChangeCurrentRow(index);
    // eslint-disable-next-line
  }, []);

  return (
    <MUI.List disablePadding>
      {isEmpty(searchedAnnoOptions) ? (
        <MUI.Box textAlign="center" pt={0.8} pb={0.8}>
          <MUI.Typography variant="subtitle1" color="textSecondary">
            {t('dropdown.noOptions')}
          </MUI.Typography>
        </MUI.Box>
      ) : (
        searchedAnnoOptions.map((annoItems: any) => (
          <MUI.Box key={annoItems.annoType}>
            <MUI.ListSubheader className={classes.subHeader} disableSticky>
              {convertImageToVideo(annoItems.annoType, projectInfo.project.workapp)}
            </MUI.ListSubheader>
            {annoItems.children.map((annoItem: any) => (
              <FilterClassDropdownListItem
                key={annoItem.value}
                hierarchy={1}
                item={annoItem}
                flatAnnoOptions={flatAnnoOptions}
                selectedOption={selectedOption}
                onClickCheck={handleClickCheck}
              />
            ))}
          </MUI.Box>
        ))
      )}
    </MUI.List>
  );
};

export default FilterClassDropdownList;
