import { useTranslation } from 'react-i18next';

import {
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  TextEllipsis,
  Tooltip,
  Typography,
} from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';

import {
  ABSIntegrationResType,
  GSIntegrationResType,
  IntegrationResType,
  S3IntegrationResType,
  SLACKIntegrationResType,
} from '../../../../../types/integrationTypes';
import { formatDate } from '../../../../../utils/date';
import { TargetType } from '../types';
import IntegrationListRowStandardLayout from './IntegrationListRowStandardLayout';

type Props = {
  integrationInfo: IntegrationResType;
  handleDelete: () => void;
  handleCheck?: () => void;
  handleEdit: () => void;
  method: TargetType;
};

const IntegrationListRowContents = ({
  integrationInfo,
  handleDelete,
  handleCheck,
  handleEdit,
  method,
}: Props) => {
  const contentInfo = {
    S3: integrationInfo as S3IntegrationResType,
    GS: integrationInfo as GSIntegrationResType,
    ABS: integrationInfo as ABSIntegrationResType,
    SLACK: integrationInfo as SLACKIntegrationResType,
  };

  const { t } = useTranslation();

  if (method === 'S3') {
    const { isDelegate } = contentInfo['S3'].info;

    return (
      <IntegrationListRowStandardLayout
        integration={contentInfo['S3']}
        editButtonTitle={isDelegate ? t('integrations.editRoleName') : undefined}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      >
        {/* bucket name */}
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis
              text={`${contentInfo['S3'].info.bucket}
          ${contentInfo['S3'].info.prefix ? ` > ${contentInfo['S3'].info.prefix}` : ''}`}
              userSelect="all"
            />
          </Typography>
        </Box>
        {/* role name */}
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3" whiteSpace="nowrap">
            {contentInfo['S3'].auth.roleName}
          </Typography>
        </Box>
        {/* external id */}
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            {isDelegate ? contentInfo['S3'].auth.externalId : contentInfo['S3'].auth.accessKey}
          </Typography>
        </Box>
      </IntegrationListRowStandardLayout>
    );
  }

  if (method === 'GS') {
    return (
      <IntegrationListRowStandardLayout
        integration={contentInfo['GS']}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      >
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis
              text={`${contentInfo['GS'].info.bucket}
          ${contentInfo['GS'].info.prefix ? ` > ${contentInfo['GS'].info.prefix}` : ''}`}
              userSelect="all"
            />
          </Typography>
        </Box>
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis
              text={(contentInfo['GS'] as GSIntegrationResType).auth.clientEmail}
              userSelect="all"
            />
          </Typography>
        </Box>
      </IntegrationListRowStandardLayout>
    );
  }

  if (method === 'ABS') {
    return (
      <IntegrationListRowStandardLayout
        integration={contentInfo['ABS']}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      >
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis text={`${contentInfo['ABS'].info.bucket}`} userSelect="all" />
          </Typography>
        </Box>
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis
              text={(contentInfo['ABS'] as ABSIntegrationResType).auth.accountKey}
              userSelect="all"
            />
          </Typography>
        </Box>
      </IntegrationListRowStandardLayout>
    );
  }

  if (method === 'SLACK') {
    return (
      <>
        <Box as="td" pl={1} pr={1}>
          <Tooltip
            anchorEl={
              <Typography variant="body3">
                <TextEllipsis text={contentInfo['SLACK'].info.project.name} userSelect="all" />
              </Typography>
            }
          >
            {contentInfo['SLACK'].info.project.name}
          </Tooltip>
        </Box>
        <Box as="td" pl={1} pr={1}>
          <Tooltip
            anchorEl={
              <Typography variant="body3">
                <TextEllipsis text={contentInfo['SLACK'].info.url} userSelect="all" />
              </Typography>
            }
          >
            {contentInfo['SLACK'].info.url}
          </Tooltip>
        </Box>
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis text={formatDate(contentInfo['SLACK'].createdAt)} userSelect="all" />
          </Typography>
        </Box>
        <Box as="td" pl={1} pr={1}>
          <Typography variant="body3">
            <TextEllipsis text={contentInfo['SLACK'].createdBy} userSelect="all" />
          </Typography>
        </Box>
        <Box as="td" pl={1}>
          <DropdownMenu
            AnchorElement={<IconButton color="textDefault" icon="moreHorizontal" />}
            placement="bottom-end"
          >
            <DropdownMenuItem onClick={handleCheck}>
              {t('integrations.sendTestMessage')}
            </DropdownMenuItem>
            <DropdownMenuItem color="primary" onClick={handleDelete}>
              {t('button.remove')}
            </DropdownMenuItem>
          </DropdownMenu>
        </Box>
      </>
    );
  }

  return <></>;
};

export default IntegrationListRowContents;
