import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import GoToPage from './GoToPage';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';

export default function AlreadyActivatedLayout() {
  const { t } = useTranslation();

  useChangeContent('graphic');

  return (
    <>
      <ShortCut page="signIn" />
      <Box width="100%" display="flex" flexDirection="column" gap={1}>
        <Title>{t('auth.title.activated')}</Title>
        <Typography variant="m-regular">{t('auth.messages.accountAlreadyActivated')}</Typography>
        <GoToPage to="login">{t('auth.goToSignIn')}</GoToPage>
      </Box>
    </>
  );
}
