import { Box, LoadingIndicator, Typography } from '@superb-ai/ui';

export const LoadingIndicatorDiv = ({
  color,
  style,
  message,
}: {
  color?: React.ComponentProps<typeof LoadingIndicator>['color'];
  style?: React.CSSProperties;
  message?: string;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      width="100%"
      height="100%"
      style={style}
    >
      <LoadingIndicator style={{ width: '48px' }} color={color || 'gray-300'} />
      {message && (
        <Typography variant="m-regular" color="gray-300">
          {message}
        </Typography>
      )}
    </Box>
  );
};
