import { camelCase } from 'lodash';
import qs from 'qs';

import { WorkApp } from '../union/WorkappUnion';
import {
  getApiParamsForFilter,
  getFiltersFromApiParams,
  getFiltersFromUrlParams,
  getUrlParamsForFilters,
} from './filter/convert';
import { allFilters as dataFilters } from './filter/dataFilter';
import { allFilters as labelFilters } from './filter/labelFilter';
import { FilterValue } from './filter/types';
import ServiceUtils from './ServiceUtils';

const getUrlParamsForLabelFilters = (filterInfo: FilterValue[]): [string, string][] => {
  return getUrlParamsForFilters(labelFilters, filterInfo);
};

const getLabelFiltersFromParams = (params: [string, string][]): FilterValue[] => {
  return getFiltersFromUrlParams(labelFilters, params);
};

const getApiParamsForLabelFilter = ({
  filterParams,
  tagIds = {},
  workApp,
}: {
  filterParams: Record<string, string> | [string, string][];
  tagIds?: Record<string, any>;
  workApp?: WorkApp;
}): Record<string, any> => {
  const entries = Array.isArray(filterParams) ? filterParams : Object.entries(filterParams);
  const objectFromEntriesWithSameKey = (entries: [PropertyKey, any][]) => {
    const temp: Record<PropertyKey, any[]> = {};
    entries.forEach(([key, value]) => {
      if (temp[key]) {
        temp[key].push(value);
      } else {
        temp[key] = [value];
      }
    });
    return Object.fromEntries(
      Object.entries(temp).map(([key, value]) => [key, value.length === 1 ? value[0] : value]),
    );
  };

  return objectFromEntriesWithSameKey(
    getApiParamsForFilter(labelFilters, {
      filterParams: entries,
      meta: {
        workApp,
        tagIds,
      },
    }),
  );
};

const getLabelFiltersFromApiParams = (
  apiParams: Record<string, any>,
  tagIds?: Record<string, string>,
) => {
  return getFiltersFromApiParams(labelFilters, Object.entries(apiParams), { tagIds });
};

const getApiParamsForDataFilter = (params: Record<string, string>, projectId?: string): any => {
  return Object.fromEntries(
    Object.entries(params)
      .map(([key, value]) => {
        return dataFilters[camelCase(key) as keyof typeof dataFilters]?.toApiParams(
          value,
          projectId,
        );
      })
      .filter(v => v)
      .flat(1),
  );
};

/**
 * Shortcut to go from api param formatted filter string to URL param
 * Example:
 * 'status_in[]=submitted' -> 'status=is any of,submitted'
 */
const getQueryParamFromApiFilter = (filter: string, tagIds?: Record<string, string>): string => {
  // ex. filter = 'status_in[]=submitted'
  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  // ex. paramsObj = { statusIn: ['submitted'] }
  const filters = getLabelFiltersFromApiParams(paramsObj, tagIds);
  // ex. filters = { status: ['is any of', 'submitted'] }
  const filterParams = getUrlParamsForLabelFilters(filters);
  // ex. filterParams = ['status', 'is any of,submitted']
  return new URLSearchParams(filterParams).toString();
};

const updateParsedQueryString = (paramsObj: qs.ParsedQs, key: string, value: any): qs.ParsedQs => {
  if (key === 'workAssigneeIn') {
    return value === 'No Assignee'
      ? { ...paramsObj, isUnassigned: 'does not exist' }
      : { ...paramsObj, workAssigneeIn: [value] };
  } else if (key === 'reviewerIn') {
    return value === 'No Assignee'
      ? { ...paramsObj, isUnassigned: 'does not exist' }
      : { ...paramsObj, reviewerIn: [value] };
  } else {
    return paramsObj;
  }
};

// Used in User Report row click events to add labeler or reviewer filter to paramsObj
const getQueryParamFromParamsObj = (
  paramsObj: qs.ParsedQs,
  tagIds?: Record<string, string>,
): string => {
  // ex. paramsObj = { statusIn: ['submitted'] }
  const filters = getLabelFiltersFromApiParams(paramsObj, tagIds);
  // ex. filters = { status: ['is any of', 'submitted'] }
  const filterParams = getUrlParamsForLabelFilters(filters);
  // ex. filterParams = ['status', 'is any of,submitted']
  const queryParams = new URLSearchParams(filterParams).toString();
  // ex. queryParams = status=is+any+one+of%2Csubmitted
  return queryParams;
};

export default {
  getUrlParamsForFilters: getUrlParamsForLabelFilters,
  getFiltersFromParams: getLabelFiltersFromParams,
  getApiParamsForFilter: getApiParamsForLabelFilter,
  getFiltersFromApiParams: getLabelFiltersFromApiParams,
  getApiParamsForDataFilter,
  getQueryParamFromApiFilter,
  updateParsedQueryString,
  getQueryParamFromParamsObj,
};
