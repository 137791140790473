import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { Select } from '@superb-ai/norwegian-forest';

import { Option, PageAdditional } from '../../../../../../../types/selectTypes';
import { useCurateDatasetService } from '../../../../../services/DatasetService';

export default function SelectProject({
  datasetId,
  selectedProjectOption,
  setSelectedProjectOption,
  isCurateSynced,
}: {
  datasetId?: string;
  selectedProjectOption?: SingleValue<Option>;
  setSelectedProjectOption: Dispatch<SetStateAction<SingleValue<Option> | undefined>>;
  isCurateSynced: boolean;
}): JSX.Element {
  const { getProjects } = useCurateDatasetService();
  const { t } = useTranslation();

  const handleLoadProject: LoadOptions<Option, GroupBase<Option>, PageAdditional> = async (
    search,
    _prevOptions,
    additional,
  ) => {
    const page = additional?.page || 1;

    const projectList = await getProjects({
      nameIcontains: search,
      page,
      isCurateSynced,
      ...(datasetId && { curateDatasetIdIn: [datasetId] }),
    });
    const result = projectList.results.map(result => ({
      label: result.name,
      value: result.id,
    }));

    const pageSize = 10;
    const hasMore = Math.ceil(projectList.count / pageSize) > page;

    return {
      options: result,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleAddProject = (newValue: SingleValue<Option>) => {
    setSelectedProjectOption(newValue);
  };
  return (
    <Select.Async
      isClearable
      blurInputOnSelect
      placeholder={t('curate.slices.sendToLabel.selectProjectPlaceholder')}
      loadOptions={handleLoadProject}
      onChange={handleAddProject}
      value={selectedProjectOption}
      isMulti={false}
    />
  );
}
