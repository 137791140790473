import { ReactElement } from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { LoadingIndicatorDiv } from '../../../../../apps/Curate/components/datasets/dataset/analytics/components/LoadingIndicatorDiv';

export const ChartCard = ({
  isLoading,
  chartComponent,
  chartTitle,
  style,
  datePicker,
}: {
  isLoading?: boolean;
  chartComponent?: ReactElement;
  chartTitle: string;
  style?: React.CSSProperties;
  datePicker?: ReactElement;
}) => {
  return (
    <Box
      display="flex"
      style={style}
      flexDirection="column"
      gap={0.5}
      border="1px solid"
      borderColor="gray-150"
      borderRadius="2px"
    >
      <Box
        display="grid"
        style={{ gridTemplateColumns: '1fr auto 1fr' }}
        width="100%"
        p={1}
        alignItems="center"
        position="relative"
        borderBottom="1px solid"
        borderColor="gray-150"
        justifyContent="space-between"
      >
        <Typography m={1} variant="m-strong" pr={0.5}>
          {chartTitle}
        </Typography>
        <Box>{datePicker}</Box>
      </Box>
      <Box
        display="flex"
        p={2}
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? <LoadingIndicatorDiv /> : chartComponent}
      </Box>
    </Box>
  );
};
