import React from 'react';

import { Box, DropdownList } from '@superb-ai/norwegian-forest';

import { ScoreType } from './types';

interface DropDownProps {
  options: { label: string; value: string }[];
  changeScoreType: (value: string) => void;
  currentScoreType: ScoreType;
}

export const ScoreTypeDropDownList: React.FC<DropDownProps> = props => {
  const { options, changeScoreType, currentScoreType } = props;
  const handleChange = (value: string) => {
    changeScoreType(value);
  };

  return (
    <>
      <Box display="flex" mb={1} mt={1} width="175px">
        <DropdownList
          color="lightgrey"
          onChange={handleChange}
          options={options}
          size="m"
          value={currentScoreType}
          variant="stroke"
        />
      </Box>
    </>
  );
};
