import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ChevronRight, Search } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Icon,
  LoadingIndicator,
  Paragraph,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { getUrl } from '../../../../routes/util';
import { formatDateLong } from '../../../../utils/date';
import { GenerationAIDetailsMenuItem } from '../../gen-ai/details/MenuItem';
import { GENERATION_MODEL, RECOGNITION_MODEL } from '../../path';
import { useModelHistoryQuery } from '../../queries/modelQueries';
import { ModelHistory } from '../../services/types';
import { RecognitionAIDetailMenuItem } from '../detail/MenuItem';

export function PreviousModelDialog({
  id,
  state,
}: {
  id: string;
  state: ReturnType<typeof useDialogState>;
}) {
  const { t } = useTranslation();

  return (
    <Dialog state={state} hideOnClickOutside={false}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.modelLogDialog.title')}
      </Dialog.Header>
      <Dialog.Content style={{ width: 450 }}>
        <PreviousModelList id={id} height={230} />
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void state.hide()}>
          {t('shared.close')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export const PreviousModelList = ({ id, height }: { id: string; height: number }) => {
  const { t } = useTranslation();

  const { data, isLoading, hasNextPage, fetchNextPage } = useModelHistoryQuery({ params: { id } });
  const modelHistory = data?.pages.flatMap(x => x.modelHistoryList) ?? [];
  if (modelHistory.length === 0) {
    return <LoadingIndicator />;
  }
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        border="1px solid"
        borderColor="gray-150"
        overflow="auto"
        pt={2}
        borderRadius="2px"
        style={{ height }}
      >
        {isLoading && <LoadingIndicator mt={2} mb={4} />}
        <Box as="ul" position="relative">
          {modelHistory.map((item, index) => (
            <HistoryRow key={item.id} item={item} isLast={index === 0} />
          ))}
          <Box
            position="absolute"
            inset="0"
            borderLeft="1px solid"
            borderColor="gray-200"
            mx={2.5}
            mt={1.5}
            mb={4}
            style={{ width: 1 }}
          >
            {/* Gray line */}
          </Box>
          {!hasNextPage && <BaselineRow item={modelHistory[0]} />}
        </Box>
        {hasNextPage && (
          <Row width="100%" justifyContent="center" mb={2}>
            <Button variant="stroke" size={'s'} onClick={() => fetchNextPage()}>
              <Icon icon={Search} size={12} />
              <Typography variant="s-regular">{t('model.modelLogDialog.viewMore')}</Typography>
            </Button>
          </Row>
        )}
      </Box>
    </Box>
  );
};

function HistoryRow({ item, isLast }: { item: ModelHistory; isLast: boolean }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      as="li"
      pl={2}
      mb={2}
      display="flex"
      alignItems="baseline"
      gap={1.5}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Box
        style={{ width: 6, height: 6, transform: 'translate(1.5px, -1px)', zIndex: 1 }}
        backgroundColor="white"
        border="1px solid"
        borderColor={isLast ? 'secondary' : undefined}
        borderRadius="100%"
      />
      <Box>
        <Row>
          <Paragraph
            variant="m-regular"
            color={item.deletedAt ? 'gray-300' : 'gray'}
            cursor={isHovered && !item.deletedAt ? 'pointer' : undefined}
            underline={isHovered && !item.deletedAt && true}
            onClick={() => {
              if (item.deletedAt) return;
              history.push(
                getUrl(
                  item.baselineModel.purpose === 'recognition'
                    ? [accountName, RECOGNITION_MODEL, RecognitionAIDetailMenuItem.path]
                    : [accountName, GENERATION_MODEL, GenerationAIDetailsMenuItem.path],
                  {
                    id: item.id,
                  },
                ),
              );
            }}
          >
            {item.name}
          </Paragraph>
          {item.deletedAt && <Chip ml={1}>{t('shared.deleted')}</Chip>}
        </Row>
        <Paragraph variant="m-regular" color="gray-300" mt={0.5}>
          {`${item.createdBy} / ${formatDateLong(new Date(item.createdAt))}`}
        </Paragraph>
      </Box>
    </Box>
  );
}

function BaselineRow({ item }: { item: ModelHistory }) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      as="li"
      pl={2}
      mb={2}
      display="flex"
      alignItems="baseline"
      gap={1.5}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Box
        style={{ width: 6, height: 6, transform: 'translate(1.5px, -1px)', zIndex: 1 }}
        backgroundColor="white"
        border="1px solid"
        borderColor={'gray'}
        borderRadius="100%"
      />
      <Box>
        <Row>
          <Chip mr={1}>{item.baselineModel.source}</Chip>
          <Paragraph
            variant="m-regular"
            color={'gray'}
            cursor={'pointer'}
            underline={isHovered ? true : false}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(item.baselineModel.sourceUrl, '_blank', 'noreferrer');
            }}
          >
            {item.baselineModel.name}
          </Paragraph>
          {isHovered && <Icon icon={ChevronRight} />}
        </Row>
        <Paragraph variant="m-regular" color="gray-300" mt={0.5}>
          {t('model.myModels.baselineModel')}
        </Paragraph>
      </Box>
    </Box>
  );
}
