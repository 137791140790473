import React from 'react';

import SkeletonProgressContainer from './SkeletonProgressContainer';
import SkeletonProgressOverallTrend from './SkeletonProgressOverallTrend';
import { ProgressAnimation } from './style/progressAnimationStyle';

const SkeletonProgressUI = () => {
  return (
    <>
      <SkeletonProgressContainer />
      <SkeletonProgressOverallTrend />
      <ProgressAnimation />
    </>
  );
};

export default SkeletonProgressUI;
