import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, NamedColorWeight, Typography } from '@superb-ai/ui';

export function FailDetailMessages({
  details,
  textColor,
  datasetName,
  sliceName,
}: {
  details: Record<string, number>;
  textColor?: NamedColorWeight;
  datasetName?: string;
  sliceName?: string;
}) {
  const { t } = useTranslation();
  const items = Object.entries(details);

  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Typography variant="s-regular" color={textColor || 'primary-400'}>
            •{' '}
            {t(`curate.bulkActions.failReasons.${item[0]}`, {
              count: item[1],
              datasetName,
              sliceName,
            })}
          </Typography>
        </li>
      ))}
    </ul>
  );
}

export function FailReasonBanner() {
  const { t } = useTranslation();
  return (
    <Box backgroundColor="primary-100" color="primary-400" p={1}>
      <Typography variant="s-regular">
        • {t('curate.bulkActions.failReasons.systemError')}
      </Typography>
    </Box>
  );
}
