export type AggregationUnit = 'daily' | 'monthly';
export type ProductType = 'label' | 'curate' | 'model';
export type ProductByFeature =
  | 'autoLabel'
  | 'embeddings'
  | 'modelEndpoints'
  | 'modelTraining'
  | 'imageSynthesis';

export const SupportedProductTypes: ProductByFeature[] = [
  'autoLabel',
  'embeddings',
  'imageSynthesis',
  'modelTraining',
  'modelEndpoints',
];

export type UsageDetail = {
  triggerProduct: ProductType;
  type: string; // 'train', 'endpoint', 'auto-label', 'custom-auto-label', 'embedding'
  count: number;
};

export type UsageHistory = {
  unit: string; // date, name of month
  totalCount: number;
  details: UsageDetail[];
};

export type UsageResponse = {
  aggregate: AggregationUnit;
  histories: UsageHistory[];
};

export type UsageByProductFeature = Pick<UsageHistory, 'unit'> & {
  productByFeature: ProductByFeature;
  count: number;
  group?: string;
};

export type DateRange = { from: string; to: string };
