import {
  ComponentProps,
  ComponentType,
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import {
  ArrowDown,
  ArrowUp,
  ArrowVertical,
  CheckCircle,
  ChevronRight,
  InfoFilled,
  LoadingSpinnerAlt,
  LoadingSpinnerSmall,
  MoreVertical,
  PinFilled,
  PinOutline,
  Radio,
  Search,
} from '@superb-ai/icons';
import {
  Atoms,
  Box,
  Button,
  Chip,
  extendComponent,
  Icon,
  IconButton,
  Input,
  LoadingIndicator,
  Popover,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { format } from 'date-fns';
import { kebabCase } from 'lodash';
import Link from 'next/link';
import { TFunction } from 'next-i18next';

import analyticsTracker from '../../../../analyticsTracker';
import { ReactComponent as ListIsEmptySvg } from '../../../../assets/img/list_is_empty.svg';
import { Row } from '../../../../components/elements/Row';
import { getUserManualUrl } from '../../../../consts/DocsLink';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { hasSufficientModelEndpointVolume } from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import { getParentPath, getUrl } from '../../../../routes/util';
import { ModelStatusChip, TaskTypeChip, textEllipsisStyle } from '../../components/components';
import { useIntersect } from '../../hooks';
import { CURATE_DATASET, MODEL_TRAIN, RECOGNITION_MODEL } from '../../path';
import {
  useModelDetailQuery,
  useModelListQuery,
  useModelPinMutation,
  useModelUnpinMutation,
  usePublicModelQuery,
} from '../../queries/modelQueries';
import { isRecognitionAIModelTrainingResult } from '../../services/modelTrainingResultTypeGuards';
import {
  ModelListData,
  ModelListSortOrder,
  ModelStatus,
  MyModel,
  PurposeType,
  TaskType,
  TrainingStage,
} from '../../services/types';
import { SplitType } from '../../train/contexts/DatasetClassContext';
import { fromQuery, TrainQueryKeyword as TrainQueryKeyword } from '../../train/queries';
import { convertSecondsToTime } from '../../utils/formatUtils';
import { CancelTrainingDialog } from '../detail/CancelTrainingDialog';
import { RecognitionAIDetailMenuItem } from '../detail/MenuItem';
import { EditTagDialog } from '../EditTagDialog';
import {
  DisplayPanelType,
  RecognitionQueryKeyword as ModelQueryKeyword,
  RecognitionQueryKeyword,
  useModelUrlParams,
} from '../queries';
import { CannotDeleteModelDialog } from './CannotDeleteModelDialog';
import { ConfirmDeleteModelDialog } from './ConfirmDeleteModelDialog';
import { CreateEndpointDialog } from './CreateEndpointDialog';
import { DatasetDialog } from './DatasetDialog';
import {
  DatasetTypePopover,
  PopoverContrainer,
  PopoverItem,
  PreviousModelPopover,
  StatusPopover,
  TagPopover,
} from './FilterPopover';
import { PreviousModelDialog } from './PreviousModelDialog';
import { PublicModelPanel } from './PublicModel';
import { RenameDialog } from './RenameDialog';

export const MAX_PIN_COUNT = 5;

export function Layout() {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const modelUrlParams = useModelUrlParams();
  const history = useHistory();
  const [displayPanel, setDisplayPanel] = useState<DisplayPanelType>(
    modelUrlParams.listTab ?? 'my-model',
  );
  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Row bb="1px solid" borderColor={'gray-150'} style={{ height: 40 }}>
          <Tab
            bb={displayPanel === 'my-model' ? '2px solid' : undefined}
            onClick={() => setDisplayPanel('my-model')}
          >
            <Typography
              variant={displayPanel === 'my-model' ? 'l-strong' : 'l-regular'}
              color={displayPanel === 'my-model' ? 'primary' : undefined}
            >
              {t('model.train.myModel')}
            </Typography>
          </Tab>
          <Tab
            bb={displayPanel === 'model-hub' ? '2px solid' : undefined}
            onClick={() => setDisplayPanel('model-hub')}
          >
            <Typography
              variant={displayPanel === 'model-hub' ? 'l-strong' : 'l-regular'}
              color={displayPanel === 'model-hub' ? 'primary' : undefined}
            >
              {t('model.myModels.modelHubTitle')}
            </Typography>
          </Tab>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="strong-fill"
            color="primary"
            onClick={() => {
              analyticsTracker.trainModelEntered({
                accountId: params.accountName,
                referrer: 'entered-from-model-list',
              });
              history.push(
                getUrl(
                  [params.accountName, MODEL_TRAIN],
                  {},
                  {
                    [TrainQueryKeyword.From]: fromQuery.recognitionAI,
                  },
                ),
              );
            }}
          >
            {t('model.myModels.trainNewModel')}
          </Button>
        </Row>
        {displayPanel === 'my-model' && <MyModelPanel setDisplayPanel={setDisplayPanel} />}
        {displayPanel === 'model-hub' && <PublicModelPanel />}
      </Box>
    </>
  );
}

const Tab = extendComponent(Row, {
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: { hover: 'gray-100' },
  borderColor: 'primary',
  height: '100%',
  style: { width: 130 },
});

const MyModelPanel = ({
  setDisplayPanel,
}: {
  setDisplayPanel: Dispatch<SetStateAction<DisplayPanelType>>;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType; accountName: string }>();

  const [datasetIdFilter, setDatasetIdFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<ModelStatus[]>([]);
  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const [datasetTaskFilter, setDatasetTaskFilter] = useState<TaskType[]>([]);
  const [modelHubFilter, setModelHubFilter] = useState<string[]>([]);
  const [previousModelNameFilter, setPreviousModelNameFilter] = useState<string[]>([]);
  const [previouseModelName, setPreviouseModelName] = useState<string>('');
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [tagName, setTagName] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [hasEndpoints, setHasEndpoints] = useState(false);
  const [createdAtSort, setCreatedAtSort] = useState<ModelListSortOrder>('desc');

  const debouncedModelNameFilter = useDebounce(searchModelNameFilter, 300);

  const myModelsQuery = useModelListQuery({
    params: {
      name: debouncedModelNameFilter,
      status: statusFilter,
      task: datasetTaskFilter,
      referenceId: datasetIdFilter,
      modelHub: modelHubFilter,
      previousModel: previousModelNameFilter,
      tag: tagFilter,
      sortOrder: createdAtSort,
      modelPurpose: 'recognition',
    },
  });

  const { data: myModelData, fetchNextPage, hasNextPage, isLoading } = myModelsQuery;
  const myModelList = useMemo(
    () =>
      myModelData?.pages
        .flatMap(p => p.modelList)
        .filter(model => (hasEndpoints ? model.endpointsCount > 0 : true)) ?? [],
    [hasEndpoints, myModelData?.pages],
  );

  const noFilter =
    datasetIdFilter.length === 0 &&
    statusFilter.length === 0 &&
    !searchModelNameFilter &&
    datasetTaskFilter.length === 0 &&
    modelHubFilter.length === 0 &&
    previousModelNameFilter.length === 0 &&
    tagFilter.length === 0 &&
    !hasEndpoints;

  const isModelListEmpty = noFilter && myModelList.length === 0 && !isLoading;
  const hasPerformance = params.purpose === 'recognition';

  if (isModelListEmpty) {
    return <ListIsEmpty setDisplayPanel={setDisplayPanel} />;
  }

  return (
    <>
      <Row mb={1} mt={1.5}>
        <Box display="grid" gap={1.5} style={{ width: 357 }}>
          <Input
            color={'gray'}
            prefix={<Icon icon={Search} />}
            value={searchModelNameFilter}
            variant="soft-fill"
            placeholder={t('model.myModels.searchInputPlaceholder')}
            onChange={e => setSearchModelNameFilter(e.target.value)}
          />
        </Box>
      </Row>
      <MyModelListHeader
        statusFilter={statusFilter}
        hasEndpoints={hasEndpoints}
        datasetName={datasetName}
        datasetTaskFilter={datasetTaskFilter}
        datasetIdFilter={datasetIdFilter}
        modelHubFilter={modelHubFilter}
        previousModelNameFilter={previousModelNameFilter}
        previouseModelName={previouseModelName}
        tagFilter={tagFilter}
        tagName={tagName}
        createdAtSort={createdAtSort}
        setDatasetIdFilter={setDatasetIdFilter}
        setStatusFilter={setStatusFilter}
        setSearchModelNameFilter={setSearchModelNameFilter}
        setDatasetTaskFilter={setDatasetTaskFilter}
        setModelHubFilter={setModelHubFilter}
        setPreviousModelNameFilter={setPreviousModelNameFilter}
        setPreviouseModelName={setPreviouseModelName}
        setTagFilter={setTagFilter}
        setTagName={setTagName}
        setDatasetName={setDatasetName}
        setHasEndpoints={setHasEndpoints}
        setCreatedAtSort={setCreatedAtSort}
        hasPerformance={hasPerformance}
      />
      <MyModelList
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        myModelList={myModelList}
        path={`/${params.accountName}${RECOGNITION_MODEL}`}
        hasPerformance={hasPerformance}
      />
    </>
  );
};

export const MyModelListHeader = ({
  statusFilter,
  hasEndpoints,
  datasetName,
  datasetTaskFilter,
  datasetIdFilter,
  modelHubFilter,
  previousModelNameFilter,
  previouseModelName,
  tagFilter,
  tagName,
  createdAtSort,
  setDatasetIdFilter,
  setStatusFilter,
  setSearchModelNameFilter,
  setDatasetTaskFilter,
  setModelHubFilter,
  setPreviousModelNameFilter,
  setPreviouseModelName,
  setTagFilter,
  setTagName,
  setDatasetName,
  setHasEndpoints,
  setCreatedAtSort,
  hasPerformance,
}: {
  statusFilter: ModelStatus[];
  hasEndpoints: boolean;
  datasetName: string;
  datasetTaskFilter: TaskType[];
  datasetIdFilter: string[];
  modelHubFilter: string[];
  previousModelNameFilter: string[];
  previouseModelName: string;
  tagFilter: string[];
  tagName: string;
  createdAtSort: ModelListSortOrder;
  setDatasetIdFilter: Dispatch<SetStateAction<string[]>>;
  setStatusFilter: Dispatch<SetStateAction<ModelStatus[]>>;
  setSearchModelNameFilter: Dispatch<SetStateAction<string>>;
  setDatasetTaskFilter: Dispatch<SetStateAction<TaskType[]>>;
  setModelHubFilter: Dispatch<SetStateAction<string[]>>;
  setPreviousModelNameFilter: Dispatch<SetStateAction<string[]>>;
  setPreviouseModelName: Dispatch<SetStateAction<string>>;
  setTagFilter: Dispatch<SetStateAction<string[]>>;
  setTagName: Dispatch<SetStateAction<string>>;
  setDatasetName: Dispatch<SetStateAction<string>>;
  setHasEndpoints: Dispatch<SetStateAction<boolean>>;
  setCreatedAtSort: Dispatch<SetStateAction<ModelListSortOrder>>;
  hasPerformance: boolean;
}) => {
  const { t } = useTranslation();

  const resetAllFilter = () => {
    setDatasetIdFilter([]);
    setStatusFilter([]);
    setSearchModelNameFilter('');
    setDatasetTaskFilter([]);
    setModelHubFilter([]);
    setPreviousModelNameFilter([]);
    setPreviouseModelName('');
    setTagFilter([]);
    setTagName('');
    setDatasetName('');
    setHasEndpoints(false);
  };

  const getCreatedAtIcon = (createdAtSort: ModelListSortOrder) => {
    switch (createdAtSort) {
      case 'asc':
        return ArrowUp;
      case 'desc':
        return ArrowDown;
      default:
        return ArrowVertical;
    }
  };

  const handleClickCreateAtSort = (createdAtSort: ModelListSortOrder) => {
    if (createdAtSort === 'asc') {
      return setCreatedAtSort('desc');
    } else if (createdAtSort === 'desc') {
      return setCreatedAtSort('asc');
    } else {
      return setCreatedAtSort('asc');
    }
  };
  return (
    <Row
      display="grid"
      gap={1}
      px={1}
      bb="1px solid"
      borderColor={'gray-400'}
      style={{
        height: 32,
        gridTemplateColumns: hasPerformance
          ? '24px minmax(260px, 2.6fr) minmax(135px, 1.35fr) minmax(135px, 1.35fr) 150px 88px 87px 57px'
          : '24px minmax(310px, 3.1fr) minmax(160px, 1.6fr) minmax(160px, 1.6fr) 150px 117px 57px',
      }}
    >
      <Box></Box>
      <Row gap={0.25}>
        <Typography variant="m-medium">{t('model.myModels.nameStatus')}</Typography>
        <StatusPopover
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          hasEndpoints={hasEndpoints}
          setHasEndpoints={setHasEndpoints}
        />
      </Row>
      <Row gap={0.25}>
        <Typography variant="m-medium">{t('model.myModels.datasetTask')}</Typography>
        <DatasetTypePopover
          datasetName={datasetName}
          setDatasetName={setDatasetName}
          datasetTaskFilter={datasetTaskFilter}
          setDatasetTaskFilter={setDatasetTaskFilter}
          datasetIdFilter={datasetIdFilter}
          setDatasetIdFilter={setDatasetIdFilter}
        />
      </Row>
      <Row gap={0.25}>
        <Typography variant="m-medium">{t('model.modelLogDialog.title')}</Typography>
        <PreviousModelPopover
          modelHubFilter={modelHubFilter}
          setModelHubFilter={setModelHubFilter}
          previousModelNameFilter={previousModelNameFilter}
          setPreviousModelNameFilter={setPreviousModelNameFilter}
          previouseModelName={previouseModelName}
          setPreviouseModelName={setPreviouseModelName}
        />
      </Row>
      <Row gap={0.25}>
        <Typography variant="m-medium">{t('model.myModels.tag')}</Typography>
        <TagPopover
          tagFilter={tagFilter}
          setTagFilter={setTagFilter}
          tagName={tagName}
          setTagName={setTagName}
        />
      </Row>
      {hasPerformance && (
        <Row>
          <Typography variant="m-medium" mr={0.5}>
            {t('model.myModelDetail.performance')}
          </Typography>
        </Row>
      )}
      <Row gap={0.25}>
        <Typography variant="m-medium">{t('model.endpoints.createdAt')}</Typography>
        <IconButton
          icon={getCreatedAtIcon(createdAtSort)}
          onClick={() => handleClickCreateAtSort(createdAtSort)}
          variant="text"
          size="s"
        />
      </Row>
      <Row>
        <Tooltip content={t('model.myModels.resetAllTooltip')} placement="top-end">
          <Button variant="text" size={'s'} onClick={resetAllFilter}>
            <Typography variant="s-regular">{t('model.myModels.resetAll')}</Typography>
          </Button>
        </Tooltip>
      </Row>
    </Row>
  );
};

export const MyModelList = ({
  hasNextPage,
  isLoading,
  fetchNextPage,
  myModelList,
  path,
  hasPerformance,
}: {
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ModelListData, unknown>>;
  myModelList: MyModel[];
  path: string;
  hasPerformance: boolean;
}) => {
  const { t } = useTranslation();

  const measureRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isLoading) {
      await fetchNextPage();
    }
  });
  const pinDisable = myModelList.filter(model => model.pinnedAt).length === MAX_PIN_COUNT;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" style={{ marginTop: 40 }}>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <>
      {myModelList.length > 0 ? (
        myModelList.map(data => {
          return (
            <MyModelListItem
              key={data.id}
              data={data}
              pinDisable={pinDisable}
              backgroundColor={data.pinnedAt ? 'secondary-100' : undefined}
              path={path}
              hasPerformance={hasPerformance}
            />
          );
        })
      ) : isLoading ? (
        <Box display="flex" justifyContent="center" style={{ marginTop: 40 }}>
          <LoadingIndicator />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" style={{ marginTop: 40 }}>
          <Typography variant="l-regular">{t('model.myModels.noModelFound')}</Typography>
        </Box>
      )}
      {hasNextPage && (
        <Row ref={measureRef} width="100%" style={{ height: 50, justifyContent: 'center' }}>
          <Icon icon={LoadingSpinnerAlt} size={32} />
        </Row>
      )}
    </>
  );
};

export const MyModelListItem = ({
  data,
  pinDisable,
  backgroundColor,
  path,
  hasPerformance,
  onClickTitle,
}: {
  data: MyModel;
  pinDisable: boolean;
  backgroundColor: ComponentProps<typeof Box>['backgroundColor'];
  path: string;
  hasPerformance: boolean;
  onClickTitle?: () => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: publicModelData } = usePublicModelQuery({ id: data.baselineModel.id });

  const trainingSetDialogState = useDialogState();
  const modelLogDialogState = useDialogState();
  const cancelTrainingDialogState = useDialogState();
  const editTagDialogState = useDialogState();
  const createEndpointDialogState = useDialogState();
  const cannotDeleteModelDialogState = useDialogState();
  const confirmDeleteModelDialogState = useDialogState();

  const trainingResult = data.modelTraining.trainingResult.data;

  const { mutate: pinMutate } = useModelPinMutation();
  const { mutate: unpinMutate } = useModelUnpinMutation();

  const previouseModelName = data.parentModel ? data.parentModel.name : publicModelData?.data.name;
  const canEarlyComplete =
    data.modelTraining.currentEpoch > 0 &&
    typeof data.modelTraining.totalEpochs === 'number' &&
    data.modelTraining.currentEpoch < data.modelTraining.totalEpochs &&
    data.status === 'training';

  const handleClickPin = (id: string) => {
    if (data.pinnedAt) {
      unpinMutate(id);
    } else {
      pinMutate(id);
    }
  };
  return (
    <>
      <ModelListItemRow
        backgroundColor={backgroundColor}
        style={{
          gridTemplateColumns: hasPerformance
            ? '24px minmax(260px, 2.6fr) minmax(135px, 1.35fr) minmax(135px, 1.35fr) 150px 88px 120px 24px'
            : '24px minmax(310px, 3.1fr) minmax(160px, 1.6fr) minmax(160px, 1.6fr) 150px 150px 24px',
        }}
      >
        <ModelListItemRowCell>
          <Tooltip
            hideOnEmptyContent
            content={!data.pinnedAt && pinDisable ? t('model.myModels.disablePin') : undefined}
            placement="top"
          >
            <Box>
              <IconButton
                disabled={!data.pinnedAt && pinDisable}
                icon={data.pinnedAt ? PinFilled : PinOutline}
                onClick={() => {
                  handleClickPin(data.id);
                }}
                variant="text"
                size="s"
              />
            </Box>
          </Tooltip>
        </ModelListItemRowCell>
        <ModelListItemRowCell>
          <Box width="100%">
            <Row mb={0.5}>
              <Tooltip
                content={
                  <Box>
                    <Typography variant="m-regular">
                      {data.modelSetting.name}
                      {canEarlyComplete && (
                        <>
                          <br />• {t('model.myModelDetail.canCompleteEarlyInfo')}
                        </>
                      )}
                    </Typography>
                  </Box>
                }
                strategy="fixed"
                placement="top-start"
              >
                <Row
                  cursor="pointer"
                  style={{ width: 'min-content', ...textEllipsisStyle }}
                  onClick={() => {
                    onClickTitle?.();
                    history.push(
                      getUrl(
                        [path, RecognitionAIDetailMenuItem.path],
                        {
                          id: data.id,
                        },
                        {
                          [ModelQueryKeyword.DetailTab]:
                            data.status === 'pending' ||
                            data.status === 'training' ||
                            data.status === 'stopped'
                              ? 'progress'
                              : 'overview',
                        },
                      ),
                    );
                  }}
                >
                  <TextEllipsisTypography variant="m-regular">
                    {data.modelSetting.name}
                  </TextEllipsisTypography>
                  <Icon icon={ChevronRight} size={16} style={{ minWidth: 'max-content' }} />
                </Row>
              </Tooltip>
            </Row>
            <Row gap={1}>
              <Tooltip
                hideOnEmptyContent
                content={data.failReason}
                strategy="fixed"
                placement="bottom"
              >
                <Box>
                  <ModelStatusChip
                    status={data.status}
                    endpointsCount={data.endpointsCount}
                    prefix={
                      data.status === 'training' || data.status === 'pending' ? (
                        <Icon icon={LoadingSpinnerSmall} color={'yellow-500'} />
                      ) : undefined
                    }
                    suffix={
                      (data.status === 'failed' || data.status === 'canceled') &&
                      data.failReason && <Icon icon={InfoFilled} />
                    }
                  />
                </Box>
              </Tooltip>
              <Row gap={0.5} style={{ ...textEllipsisStyle }}>
                <TextEllipsisTypography variant="s-regular">
                  {formatDownloadTime(t, getTotalEstimatedTime(data), data.status)}
                </TextEllipsisTypography>
                {(data.status === 'pending' ||
                  data.status === 'training' ||
                  data.status === 'stopped') && <InfoTooltip data={data} />}
              </Row>
            </Row>
          </Box>
        </ModelListItemRowCell>
        <ModelListItemRowCell>
          <Box width="100%">
            <Row mb={0.5}>
              <Tooltip content={data.trainingSet.referenceName} strategy="fixed" placement="top">
                <Row
                  onClick={() => {
                    trainingSetDialogState.show();
                  }}
                  cursor="pointer"
                  style={{ width: 'min-content', ...textEllipsisStyle }}
                >
                  <TextEllipsisTypography variant="m-regular">
                    {data.trainingSet.referenceName}
                  </TextEllipsisTypography>
                  <Icon icon={ChevronRight} size={16} style={{ minWidth: 'max-content' }} />
                </Row>
              </Tooltip>
            </Row>
            <TaskTypeChip taskType={data.baselineModel.task} />
          </Box>
        </ModelListItemRowCell>
        <ModelListItemRowCell>
          <Box width="100%">
            <Row mb={0.5}>
              <Tooltip content={previouseModelName} strategy="fixed" placement="top-start">
                <Row
                  cursor="pointer"
                  style={{ width: 'min-content', ...textEllipsisStyle }}
                  onClick={() => {
                    modelLogDialogState.show();
                  }}
                >
                  <TextEllipsisTypography variant="m-regular">
                    {previouseModelName}
                  </TextEllipsisTypography>
                  <Icon icon={ChevronRight} size={16} style={{ minWidth: 'max-content' }} />
                </Row>
              </Tooltip>
            </Row>
            <Chip>{data.baselineModel.source}</Chip>
          </Box>
        </ModelListItemRowCell>
        <ModelListItemRowCell>
          <Box overflow="hidden" style={{ height: data.modelTag.length < 3 ? 22 : 44 }}>
            {data.modelTag.map((tag, idx) => {
              if (data.modelTag.length > 4 && idx === 3) {
                return (
                  <Tooltip
                    key="more-tags"
                    placement="top"
                    strategy="fixed"
                    content={data.modelTag
                      .filter((tag, idx) => idx >= 3)
                      .map(tag => tag.name)
                      .join(' / ')}
                  >
                    <Typography variant="m-regular">...{data.modelTag.length - 3} more</Typography>
                  </Tooltip>
                );
              } else {
                return (
                  <Chip
                    key={tag.name}
                    color={tag.color}
                    mr={0.5}
                    mb={0.5}
                    style={{
                      width: 63,
                    }}
                  >
                    <Typography variant="s-regular" style={{ ...textEllipsisStyle }}>
                      {tag.name}
                    </Typography>
                  </Chip>
                );
              }
            })}
          </Box>
        </ModelListItemRowCell>
        {hasPerformance && (
          <ModelListItemRowCell>
            {data.status === 'trained' &&
            trainingResult &&
            isRecognitionAIModelTrainingResult(trainingResult) ? (
              <>
                {/* TODO: mAP일 때만 렌더링됨 */}
                <Tooltip content={t('model.myModels.mAPTooltip')} placement="top" strategy="fixed">
                  <Row>
                    <Typography variant="m-regular">mAP</Typography>&nbsp;
                    <Row
                      cursor="pointer"
                      onClick={() => {
                        history.push(
                          getUrl(
                            [path, RecognitionAIDetailMenuItem.path],
                            {
                              id: data.id,
                            },
                            {
                              [ModelQueryKeyword.DetailTab]: 'performance',
                            },
                          ),
                        );
                      }}
                    >
                      <Typography variant="m-strong">
                        {(trainingResult.overallPerformance.ap * 100).toFixed(1)}%
                      </Typography>
                      <Icon icon={ChevronRight} size={16} style={{ minWidth: 'max-content' }} />
                    </Row>
                  </Row>
                </Tooltip>
              </>
            ) : (
              '-'
            )}
          </ModelListItemRowCell>
        )}
        <ModelListItemRowCell>
          <Typography variant="m-regular">
            {data.createdAt ? format(new Date(data.createdAt), 'M/d/yy h:mm a') : '-'}
          </Typography>
        </ModelListItemRowCell>
        <ModelListItemRowCell>
          <Popover
            fixed
            hideOnClick
            disclosure={<IconButton icon={MoreVertical} variant="text" size="s" />}
            style={{ zIndex: 1 }}
          >
            <MyModelListItemPopover
              data={data}
              cancelTrainingDialogState={cancelTrainingDialogState}
              editTagDialogState={editTagDialogState}
              createEndpointDialogState={createEndpointDialogState}
              cannotDeleteModelDialogState={cannotDeleteModelDialogState}
              confirmDeleteModelDialogState={confirmDeleteModelDialogState}
            />
          </Popover>
        </ModelListItemRowCell>
      </ModelListItemRow>
      {trainingSetDialogState.visible && (
        <DatasetDialog
          datasetName={data.trainingSet.referenceName ?? ''}
          state={trainingSetDialogState}
          modelData={data}
        />
      )}
      {modelLogDialogState.visible && (
        <PreviousModelDialog id={data.id} state={modelLogDialogState} />
      )}
      {cancelTrainingDialogState.visible && (
        <CancelTrainingDialog
          modelId={data.id}
          state={cancelTrainingDialogState}
          currentEpoch={data.modelTraining.currentEpoch}
          totalEpochs={data.modelTraining.totalEpochs}
        />
      )}
      {editTagDialogState.visible && <EditTagDialog id={data.id} state={editTagDialogState} />}
      {createEndpointDialogState.visible && (
        <CreateEndpointDialog
          id={data.id}
          publicModelId={data.baselineModel.id}
          modelName={data.modelSetting.name}
          state={createEndpointDialogState}
        />
      )}
      {cannotDeleteModelDialogState.visible && (
        <CannotDeleteModelDialog
          state={cannotDeleteModelDialogState}
          modelId={data.id}
          endpointsCount={data.endpointsCount}
        />
      )}
      {confirmDeleteModelDialogState.visible && (
        <ConfirmDeleteModelDialog state={confirmDeleteModelDialogState} modelId={data.id} />
      )}
    </>
  );
};

export const InfoTooltip = ({ data }: { data: MyModel }) => {
  const { t } = useTranslation();

  const getInfoTooltipContent = (status: ModelStatus) => {
    switch (status) {
      case 'pending':
        return t('model.myModels.findingInstance');
      case 'training':
        return <TrainingProgressTable id={data.id} modelTraining={data.modelTraining} />;
      case 'stopped':
        return t('model.myModels.completingTooltip');
      default:
        return null;
    }
  };

  return (
    <Tooltip placement="bottom" strategy="fixed" content={getInfoTooltipContent(data.status)}>
      <Icon icon={InfoFilled} size="xs" />
    </Tooltip>
  );
};
const ModelListItemRow = extendComponent(Box, {
  display: 'grid',
  px: 1,
  gap: 1,
  bb: '1px solid',
  borderColor: 'gray-200',
  style: {
    height: 74,
  },
});

const ModelListItemRowCell = extendComponent(Row, {});

const TrainingProgressTable = ({
  id,
  modelTraining,
}: {
  id: string;
  modelTraining: MyModel['modelTraining'];
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const { data } = useModelDetailQuery({ id: id!, modelPurpose: params.purpose });

  const getProgress = (
    trainingStep: TrainingStage,
  ): { color: Atoms['color']; icon: ComponentType; content: string | ReactElement } => {
    // In progress
    const trainingStatus = modelTraining.trainingHistory.find(
      x => x.trainingStage === trainingStep,
    );
    if (!trainingStatus) {
      // nullish state
      return {
        color: 'gray-400',
        icon: LoadingSpinnerSmall,
        content: '-',
      };
    }
    // Done
    else if (trainingStatus.startedAt && trainingStatus.endedAt) {
      const realTrainingSeconds = Math.floor(
        (trainingStatus.endedAt - trainingStatus.startedAt) / 1000,
      );
      return {
        color: 'green-400',
        icon: CheckCircle,
        content: (
          <Trans i18nKey={'model.myModels.trainingProgress.done'} t={t}>
            {{ time: convertSecondsToTime(realTrainingSeconds) }}
          </Trans>
        ),
      };
      // Scheduled
    } else if (!trainingStatus.startedAt && !trainingStatus.endedAt) {
      return {
        color: 'cloud-400',
        icon: Radio,
        content: t('model.myModels.trainingProgress.scheduled'),
      };
    } else if (trainingStatus.startedAt && !trainingStatus.endedAt) {
      return {
        color: 'yellow-400',
        icon: LoadingSpinnerSmall,
        content: `${modelTraining.progress}%`,
      };
    } else {
      // nullish state
      return {
        color: 'gray-400',
        icon: LoadingSpinnerSmall,
        content: '-',
      };
    }
  };

  const getEstimatedTime = (
    trainingHistory: MyModel['modelTraining']['trainingHistory'][number],
  ) => {
    // in progress
    if (trainingHistory.startedAt && !trainingHistory.endedAt) {
      const remainingTime =
        trainingHistory.estimatedTime -
        (new Date().getTime() - modelTraining.progressUpdatedAt) / 1000;
      if (remainingTime < 60) {
        return t('model.myModels.lessThanMinute');
      }
      return (
        <Trans t={t} i18nKey={'model.myModels.trainingProgress.totalEstimatedTime'}>
          {{ time: convertSecondsToTime(remainingTime) }}
        </Trans>
      );
      // scheduled
    } else if (!trainingHistory.startedAt && !trainingHistory.endedAt) {
      return (
        <Trans t={t} i18nKey={'model.myModels.trainingProgress.estimated'}>
          {{ time: `${convertSecondsToTime(trainingHistory.estimatedTime)}` }}
        </Trans>
      );
      // done
    } else {
      return '-';
    }
  };
  if (!modelTraining.trainingHistory) return null;

  return (
    <Box>
      <Box
        display="grid"
        borderBottom="1px solid"
        mb={0.5}
        pb={0.5}
        style={{
          gridTemplateColumns: '84px 126px 120px',
          columnGap: 12,
          borderColor: 'rgba(255, 255, 255, 0.2)',
        }}
      >
        <Typography variant="m-regular">{t('model.myModels.trainingProgress.steps')}</Typography>
        <Typography variant="m-regular">{t('model.myModels.trainingProgress.progress')}</Typography>
        <Typography variant="m-regular">
          {t('model.myModels.trainingProgress.remainingTime')}
        </Typography>
      </Box>
      <Box
        display="grid"
        mt={0.5}
        style={{
          gridTemplateColumns: '84px 126px 120px',
          columnGap: 12,
        }}
      >
        <Typography variant="m-regular">
          {t('model.myModels.trainingProgress.loadingModel')}
        </Typography>
        <Row gap={0.5}>
          <Icon
            icon={getProgress('loading_model').icon}
            color={getProgress('loading_model').color}
          />
          <Typography variant="m-regular" color={getProgress('loading_model').color}>
            {getProgress('loading_model').content}
          </Typography>
        </Row>
        <Typography variant="m-regular">
          {getEstimatedTime(modelTraining.trainingHistory[0])}
        </Typography>
        <Typography variant="m-regular">
          {t('model.myModels.trainingProgress.loadingData')}
        </Typography>
        <Row gap={0.5}>
          <Icon icon={getProgress('loading_data').icon} color={getProgress('loading_data').color} />
          <Typography variant="m-regular" color={getProgress('loading_data').color}>
            {getProgress('loading_data').content}
          </Typography>
        </Row>
        <Typography variant="m-regular">
          {getEstimatedTime(modelTraining.trainingHistory[1])}
        </Typography>
        <Typography variant="m-regular">{t('model.myModels.trainingProgress.training')}</Typography>
        <Row gap={0.5}>
          <Icon icon={getProgress('training').icon} color={getProgress('training').color} />
          <Typography variant="m-regular" color={getProgress('training').color}>
            {getProgress('training').content}
          </Typography>
          {!data?.modelTraining.trainingHistory[3].endedAt && (
            <Typography variant="m-regular" color={getProgress('training').color}>
              ({data?.modelTraining.currentEpoch}/{data?.modelTraining.totalEpochs})
            </Typography>
          )}
        </Row>
        <Typography variant="m-regular">
          {getEstimatedTime(modelTraining.trainingHistory[2])}
        </Typography>
        <Typography variant="m-regular">
          {t('model.myModels.trainingProgress.finishing')}
        </Typography>
        <Row gap={0.5}>
          <Icon icon={getProgress('finishing').icon} color={getProgress('finishing').color} />
          <Typography variant="m-regular" color={getProgress('finishing').color}>
            {getProgress('finishing').content}
          </Typography>
        </Row>
        <Typography variant="m-regular">
          {getEstimatedTime(modelTraining.trainingHistory[3])}
        </Typography>
      </Box>
    </Box>
  );
};

const MyModelListItemPopover = ({
  data,
  cancelTrainingDialogState,
  editTagDialogState,
  createEndpointDialogState,
  cannotDeleteModelDialogState,
  confirmDeleteModelDialogState,
}: {
  data: MyModel;
  cancelTrainingDialogState: ReturnType<typeof useDialogState>;
  editTagDialogState: ReturnType<typeof useDialogState>;
  createEndpointDialogState: ReturnType<typeof useDialogState>;
  cannotDeleteModelDialogState: ReturnType<typeof useDialogState>;
  confirmDeleteModelDialogState: ReturnType<typeof useDialogState>;
}) => {
  const { t } = useTranslation();
  const { params, url } = useRouteMatch<{ accountName: string; purpose: PurposeType }>();
  const history = useHistory();
  const endpointQuantity = useMetering('model:endpoint');
  const isEndpointMaxCount = !hasSufficientModelEndpointVolume(endpointQuantity);

  const curateDatasetId = data.trainingSet.referenceId;
  const splitType =
    data.trainingSet.validationSetList.length > 0
      ? ('manual' as SplitType)
      : ('random' as SplitType);
  const { params: purposeParams } = useRouteMatch<{ purpose: PurposeType }>();

  const trackDiagnoseModelButtonClicked = () => {
    analyticsTracker.diagnoseModelButtonClicked({
      accountId: params.accountName,
      datasetId: curateDatasetId,
      modelId: data.id,
      baselineSource: data.baselineModel.source,
      baselineName: data.baselineModel.name,
      task: kebabCase(data.baselineModel?.task),
    });
  };

  return (
    <PopoverContrainer onClick={e => e.stopPropagation()}>
      {data.status === 'trained' && (
        <>
          <Tooltip
            strategy="fixed"
            placement="top-end"
            hideOnEmptyContent
            content={isEndpointMaxCount ? t('model.endpoints.disableCreateEndpoint') : undefined}
          >
            <PopoverItem onClick={() => createEndpointDialogState.show()}>
              <Typography variant="m-regular">{t('model.endpoints.createEndpoint')}</Typography>
            </PopoverItem>
          </Tooltip>

          <Tooltip
            hideOnEmptyContent
            content={
              data.baselineModel.deleted ? (
                <Box style={{ width: 400 }}>
                  <Trans t={t} i18nKey={'model.myModels.deprecatedMoreTrain'} />
                </Box>
              ) : undefined
            }
            placement="left"
            strategy="fixed"
          >
            <PopoverItem
              onClick={() => {
                if (data.baselineModel.deleted) return;
                analyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-model-list',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_TRAIN],
                    {},
                    {
                      [TrainQueryKeyword.From]: params.purpose,
                      [TrainQueryKeyword.Split]: splitType,
                      [TrainQueryKeyword.Trained]: data.id,
                    },
                  ),
                );
              }}
            >
              <Typography
                variant="m-regular"
                color={data.baselineModel.deleted ? 'cloud-400' : undefined}
              >
                {t('model.myModels.trainMore')}
              </Typography>
            </PopoverItem>
          </Tooltip>
          {data.modelSetting.annotationClassList.some(x => x.type === 'box') &&
            purposeParams.purpose === 'recognition' && (
              <PopoverItem
                onClick={() => {
                  history.push(
                    getUrl(
                      [
                        params.accountName,
                        CURATE_DATASET,
                        curateDatasetId,
                        'model-diagnosis',
                        'list',
                      ],
                      {},
                      {
                        [TrainQueryKeyword.From]: 'model',
                        model_id: data.id,
                      },
                    ),
                  );
                  trackDiagnoseModelButtonClicked();
                }}
              >
                <Typography variant="m-regular">{t('model.myModels.diagnose')}</Typography>
              </PopoverItem>
            )}
          <RenameDialog
            id={data.id}
            name={data.modelSetting.name}
            disclosure={
              <PopoverItem>
                <Typography variant="m-regular">{t('model.myModels.rename')}</Typography>
              </PopoverItem>
            }
          />
        </>
      )}
      {(data.status === 'pending' || data.status === 'training') && (
        <PopoverItem onClick={() => cancelTrainingDialogState.show()}>
          <Typography variant="m-regular">{t('shared.cancel')}</Typography>
        </PopoverItem>
      )}
      {data.status === 'training' && (
        <Tooltip
          hideOnEmptyContent
          placement="left"
          strategy="fixed"
          content={
            data.modelTraining.currentEpoch > 1 ? (
              <Box style={{ width: 500 }}>{t('model.myModelDetail.canCompleteEarlyInfo')}</Box>
            ) : undefined
          }
        >
          <PopoverItem
            onClick={() => {
              history.push(
                getUrl(
                  [getParentPath(url), RecognitionAIDetailMenuItem.path],
                  {
                    id: data.id,
                  },
                  { [RecognitionQueryKeyword.DetailTab]: 'progress' },
                ),
              );
            }}
          >
            <Typography variant="m-regular">{t('model.myModelDetail.viewProgress')}</Typography>
          </PopoverItem>
        </Tooltip>
      )}

      <PopoverItem
        onClick={() => {
          editTagDialogState.show();
        }}
      >
        <Typography variant="m-regular">{t('model.myModelDetail.editTag')}</Typography>
      </PopoverItem>
      {(data.status === 'trained' || data.status === 'canceled' || data.status === 'failed') && (
        <PopoverItem
          onClick={() => {
            if (data.endpointsCount > 0) {
              cannotDeleteModelDialogState.show();
            } else {
              confirmDeleteModelDialogState.show();
            }
          }}
        >
          <Typography variant="m-regular" color="primary-400">
            {t('shared.delete')}
          </Typography>
        </PopoverItem>
      )}
    </PopoverContrainer>
  );
};

const ListIsEmpty = ({
  setDisplayPanel,
}: {
  setDisplayPanel: Dispatch<SetStateAction<DisplayPanelType>>;
}) => {
  const { t, i18n } = useTranslation();
  const { MODEL_HOW_TO_TRAIN } = getUserManualUrl(i18n.language);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Box display="flex" flexDirection="column" textAlign="center" style={{ marginTop: -150 }}>
        <ListIsEmptySvg />
        <Typography variant="h3">{t('model.myModels.listIsEmpty.line1')}</Typography>
        <Typography variant="h3" mb={3}>
          {t('model.myModels.listIsEmpty.line2')}
        </Typography>
        <Row justifyContent="center" gap={1}>
          <Link target="_blank" rel="noopener noreferrer" href={MODEL_HOW_TO_TRAIN}>
            <Button variant="strong-fill" size="l">
              {t('model.myModels.listIsEmpty.button1')}
            </Button>
          </Link>
          <Button
            color="primary"
            variant="strong-fill"
            size="l"
            onClick={() => {
              setDisplayPanel('model-hub');
            }}
          >
            {t('model.myModels.listIsEmpty.button2')}
          </Button>
        </Row>
      </Box>
    </Box>
  );
};

export const formatDownloadTime = (
  t: TFunction,
  totalEstimatedSeconds: number,
  status: MyModel['status'],
) => {
  if (status === 'pending') {
    return (
      <Trans t={t} i18nKey={'model.myModels.pendingEstimatedTime'}>
        estimated time: {{ time: convertSecondsToTime(totalEstimatedSeconds) }}
      </Trans>
    );
  }

  if (status === 'stopped') {
    return t('model.myModels.completingEstimatedTime');
  }

  if (status === 'training') {
    if (totalEstimatedSeconds < 60) {
      return t('model.myModels.lessThanMinute');
    }
    return (
      <Trans t={t} i18nKey={'model.myModels.trainingProgress.totalEstimatedTime'}>
        {{ time: convertSecondsToTime(totalEstimatedSeconds) }} remaining...
      </Trans>
    );
  }
};

const TextEllipsisTypography = extendComponent(Typography, {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

// 현재 진행중인 stage의 remainingTime + 대기중인 stage의 estimatedTime
export const getTotalEstimatedTime = (data: MyModel) => {
  if (!data.modelTraining.trainingHistory) return NaN;
  const wipStage = data.modelTraining.trainingHistory.find(
    stage => stage.startedAt && !stage.endedAt,
  );
  if (!wipStage) return data.modelTraining.totalEstimatedTime;
  const wipStageRemainingTime =
    wipStage.estimatedTime - (new Date().getTime() - data.modelTraining.progressUpdatedAt) / 1000;

  const standByStages = data.modelTraining.trainingHistory.filter(stage => !stage.startedAt);
  const standByStagesEstimatedTime = standByStages.reduce((acc, cur) => acc + cur.estimatedTime, 0);

  return wipStageRemainingTime + standByStagesEstimatedTime;
};
