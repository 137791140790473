import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Box, Chip, Icon, Typography } from '@superb-ai/norwegian-forest';
import cn from 'classnames';
import { groupBy } from 'lodash';

import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import WorkappUnion from '../../../union/WorkappUnion';
import { getAutoLabelConfig } from '../../../utils/LabelInterfaceUtils';
import { Engine, fetchEngineInfos } from '../aiAdvancedFeatures/autoLabel/settings/helper';
import AutoLabelGuide from './AutoLabelGuide';

const useStyles = makeStyles({
  table: {
    width: '100%',
    position: 'relative',
    borderCollapse: 'collapse',
    fontSize: 12,
    lineHeight: 1.5,

    '& td, & th': {
      padding: '0 8px 8px 0',
      verticalAlign: 'top',
    },
    '& th': {
      fontWeight: 500,
      textAlign: 'left',
      paddingLeft: '4px',
    },
  },
});

interface Props {
  className: string;
}

export default function AutoLabelConfig({ className }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { project } = useProjectInfo();
  const authInfo = useAuthInfo();
  const canConfigure = isOwnerOrAdminOrSystemAdmin(authInfo);
  const routeInfo = useRouteInfo();
  const labelInterface = project.labelInterface;

  const [enginesMap, setEnginesMap] = useState<Map<string, Engine>>(new Map());

  const { t } = useTranslation();

  useEffect(() => {
    if (!canConfigure) return;
    fetchEngineInfos({ t, labelInterface, authInfo, routeInfo }).then(setEnginesMap);
  }, [labelInterface, canConfigure, t]);

  if (!canConfigure) return <></>;

  const { categories, objectClasses } = getAutoLabelConfig(labelInterface);
  const classesByEngine = Object.entries(
    groupBy(objectClasses, cls => cls.aiClassMap?.[0]?.engineId),
  );
  const categoriesByEngine = Object.entries(groupBy(categories, cat => cat.aiProperty?.engineId));
  const isConfigured = objectClasses.length > 0 || categories.length > 0;

  function gotoSettings() {
    history.push('./auto-label/settings');
  }

  const color = !isConfigured && canConfigure ? 'secondary' : 'textDefault';

  if (!canConfigure) return null;

  return isConfigured ? (
    <Box
      className={cn(className, 'clickable')}
      width="100%"
      mb={2}
      onClick={() => canConfigure && gotoSettings()}
      maxHeight={230}
      display="flex"
      flexDirection="column"
      minHeight={0}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="headline4" themedColor={color}>
          {t('autoLabel.configuration.title')}
        </Typography>
        <Icon name="settings" size="16px" color="textDefault" />
      </Box>
      <Box flex={1} overflow="auto">
        <table className={classes.table}>
          <tbody>
            {classesByEngine?.length > 0 && (
              <>
                <tr>
                  <td colSpan={2}>
                    <Chip>{t('projectSettings.configuration.objectClass')}</Chip>
                  </td>
                </tr>
                {classesByEngine.map(
                  ([engineId, classes]) =>
                    engineId && (
                      <tr key={engineId}>
                        <th>{enginesMap.get(engineId)?.name ?? 'Unknown'}</th>
                        <td>
                          {classes.map(cls => (
                            <div key={cls.id}>{cls.name}</div>
                          ))}
                        </td>
                      </tr>
                    ),
                )}
              </>
            )}
            {categoriesByEngine?.length > 0 && (
              <>
                <tr>
                  <td colSpan={2}>
                    <Chip>
                      {WorkappUnion.isVideoApp(project.workapp)
                        ? t('projectSettings.configuration.videoCategory')
                        : t('projectSettings.configuration.imageCategory')}
                    </Chip>
                  </td>
                </tr>
                {categoriesByEngine.map(
                  ([engineId, categories]) =>
                    engineId && (
                      <tr key={engineId}>
                        <th>{enginesMap.get(engineId)?.name ?? 'Unknown'}</th>
                        <td>
                          {categories.map(cat => (
                            <div key={cat.id}>{cat.name}</div>
                          ))}
                        </td>
                      </tr>
                    ),
                )}
              </>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  ) : (
    <Box width="100%" mb={2}>
      <AutoLabelGuide />
    </Box>
  );
}
