import { useQuery } from '@tanstack/react-query';

import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import UsersService, { GetUsersParams } from '../services/UsersService';

export const useUsersQuery = (args?: { params?: GetUsersParams; statusIn?: string[] }) => {
  const defaultParams = useApiDefaultParams();

  const fetcher = async () =>
    await UsersService.getUsers({
      ...defaultParams,
      ...args,
    });

  return useQuery(['users', defaultParams.urlInfo.accountName, args], fetcher, {
    staleTime: 60000,
  });
};
