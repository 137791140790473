import React from 'react';
import { useHistory } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { IMAGE_SCOPE, SCOPE, ScopeMode } from '../../../../../../types/viewTypes';
import GridColumnsRange from '../GridColumnsRange';

export default function GridLeftSection() {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const scope = (params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;
  const minColumns = scope === 'object' ? 3 : 1;
  const maxColumns = 6;
  const defaultColumns = 5;

  const { columnsCount, setColumnsCount } = useActionContext();

  return (
    <Box style={{ maxWidth: '120px' }}>
      <GridColumnsRange
        isPopoverMode
        minColumns={minColumns}
        maxColumns={maxColumns}
        columnsCount={columnsCount}
        defaultColumns={defaultColumns}
        setColumnsCount={setColumnsCount}
      />
    </Box>
  );
}
