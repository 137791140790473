import { Badge, Box, Tooltip } from '@superb-ai/norwegian-forest';

import ProjectConst from '../../../consts/ProjectConst';

interface Props {
  items: { label: string; value: string }[];
}

function getAnnotationIcon(type: string) {
  return (
    ProjectConst.annotationIcons[type as keyof typeof ProjectConst.annotationIcons] ??
    ProjectConst.annotationIcons.box
  );
}

export function AnnotationTypeIconList({ items }: Props) {
  return (
    <Box display="flex">
      {items.map(({ label, value }) => (
        <Tooltip
          key={value}
          anchorEl={
            <Box mr={0.5}>
              <Badge color="strawberry" size="xxs">
                {getAnnotationIcon(value)}
              </Badge>
            </Box>
          }
          placement="bottom"
        >
          {label}
        </Tooltip>
      ))}
    </Box>
  );
}
