import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Error, LoadingSpinnerSmall } from '@superb-ai/icons';
import {
  Box,
  DropdownList,
  IconButton,
  TextEllipsis,
  Typography,
} from '@superb-ai/norwegian-forest';
import { Icon } from '@superb-ai/ui';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useApiDefaultParams } from '../../../../hooks/ApiParamsHook';
import { useExportsAnalyticsAllVersionsQuery } from '../../../../queries/useExportsAnalyticsQuery';
import { HistoryType } from '../../../../types/exportTypes';
import FileUtils, { SheetFormat } from '../../../../utils/FileUtils';
import { FileFormat } from '../dataTypes/analyticsDataTypes';
import DownloadDropDown from '../tools/DownloadDropDown';
import { OptionRenderer } from '../userStats/ReportVersionDropdown';
import { ComparisonReport } from './types/types';
import {
  annotationsChangedDownload,
  metaDataDownload,
  objectCountsDownload,
  overallTrendDownload,
} from './utils/download';
import { metricsTransformer } from './utils/helper';

interface DropdownProps {
  isDisable: boolean;
  value: string;
  setValue: (version: string) => void;
  selectedVersion: ComparisonReport;
  exportHistories: HistoryType[];
}
const ReportVersionDropdown = ({
  value,
  setValue,
  isDisable,
  selectedVersion,
  exportHistories,
}: DropdownProps) => {
  const { t } = useTranslation();
  const {
    project: { labelInterface },
  } = useProjectInfo();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/analytics/ReportVersionDropdown.tsx',
  });

  const queryParams = {
    defaultParams,
    versionId: value,
    refetch: true,
  };
  const versions = useExportsAnalyticsAllVersionsQuery(queryParams);
  const options = useMemo(
    () =>
      versions.map(version => ({
        value: version.id,
        label: version.name,
      })),
    [versions],
  );

  const handleDownload = (outputType: FileFormat) => {
    if (outputType !== 'xlsx') return;
    const metadata = {
      data: metaDataDownload(selectedVersion, exportHistories),
      sheetName: t('analytics.exportsAnalytics.report.sheetName.info'),
    };
    const sheets: SheetFormat[] = [
      {
        data: objectCountsDownload(selectedVersion, labelInterface),
        sheetName: t('analytics.exportsAnalytics.report.sheetName.objectCounts'),
      },
      {
        data: overallTrendDownload(selectedVersion),
        sheetName: t('analytics.exportsAnalytics.report.sheetName.overallTrend'),
      },
      {
        data: annotationsChangedDownload(selectedVersion, labelInterface),
        sheetName: t('analytics.exportsAnalytics.report.sheetName.annotationChanges'),
      },
      metricsTransformer(selectedVersion, t('analytics.exportsAnalytics.report.sheetName.metrics')),
    ];
    const fileName = selectedVersion.name;
    FileUtils.downloadMultipleExcelSheets(sheets, fileName, metadata);
  };

  const getVersionForValue = useMemo(
    () => (value: string) => versions.find(v => v.id === value),
    [versions],
  );

  const optionRenderer: OptionRenderer = useMemo(
    () => option => {
      if (!option.value) return <></>;
      const version = getVersionForValue(option.value);
      return (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box overflow="hidden">
              <Typography variant="label">
                <TextEllipsis text={option.label} />
              </Typography>
            </Box>
            {(version?.state === 'IN_PROGRESS' || version?.state === 'IN_QUEUE') && (
              <Icon color="gray-400" icon={LoadingSpinnerSmall} size={16} />
            )}
            {version?.state === 'FAILED' && <Icon color="primary-400" icon={Error} size={16} />}
          </Box>
        </>
      );
    },
    [getVersionForValue],
  );

  return (
    <Box display="flex" gap="8px">
      <Box display="flex" width="360px">
        <DropdownList
          hasSearch
          disabled={isDisable}
          placeholder={t('analytics.exportsAnalytics.selectExportsAnalyticsReport')}
          value={value}
          onChange={setValue}
          options={options}
          optionRenderer={optionRenderer}
          width={360}
        />
      </Box>
      <Box
        display="flex"
        maxWidth="800px"
        mr="5px"
        alignItems="center"
        justifyContent="space-between"
        gap={15}
      >
        <DownloadDropDown
          ml={0}
          iconButtonEl={
            <IconButton
              size="m"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={isDisable || selectedVersion.state === 'FAILED'}
            />
          }
          handleDownload={handleDownload}
          options={['xlsx']}
          isToggleDisabled={isDisable}
        />
      </Box>
    </Box>
  );
};

export default ReportVersionDropdown;
