import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import helper from './helper';
import ObjectClassItem from './ObjectClassItem';
import ObjectClassListDropZone from './ObjectClassListDropZone';

const useStyles = makeStyles(() => ({
  box: {
    width: '420px',
    height: '100%',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    height: '100%',
    width: '77px',
    borderRadius: '0px',
  },
  objectClassBox: {
    position: 'relative',
    flex: 1,
    padding: '6px 24px',
    overflow: overflowOverlayOrAuto(),
  },
  objectClassInnerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '600px',
  },
  createClassGroupButton: {
    width: '100%',
    height: '46px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    fontSize: '14px',
    color: '#fff',
    borderRadius: '0px',
    textTransform: 'none',
  },
}));

const ObjectClassList = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { root } = newProjectInfo.objectClassGroupMap;
  const rootObjectClasses = root.children.filter(
    (item: string) => !newProjectInfo.objectClassGroupMap[item].children,
  );
  const selectedClassCount = Object.keys(newProjectInfo.selectedObjectClassIds).length;

  const handleClickCreateClassGroup = () => {
    const childIds = Object.keys(newProjectInfo.selectedObjectClassIds);
    helper.createObjectClassGroup(newProjectInfo, childIds, 'root');
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer useHeader title={t('projectSettings.configuration.objectClass')}>
        <MUI.Box className={classes.innerBox}>
          <MUI.Box className={classes.objectClassBox}>
            <ObjectClassListDropZone />
            {rootObjectClasses.map((id: string, index: number) => (
              <ObjectClassItem key={id} id={id} index={index} depth={0} />
            ))}
          </MUI.Box>

          <MUI.Button
            className={classes.createClassGroupButton}
            onClick={handleClickCreateClassGroup}
            disabled={selectedClassCount === 0}
            variant="contained"
            color="primary"
          >
            {t('projectSettings.configuration.createClassGroup')}
            {` (${t('projects.createProject.selected', { count: selectedClassCount })})`}
          </MUI.Button>
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default ObjectClassList;
