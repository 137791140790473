import React, { useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import ColorChipSelectBox from '../../../elements/ColorChipSelectBox';
import ObjectClassItemDropBar from './ObjectClassItemDropBar';
import ObjectClassItemEditableText from './ObjectClassItemEditableText';
import ObjectClassItemMoreInformation from './ObjectClassItemMoreInformation';
import ObjectClassItemMoreInformationToggle from './ObjectClassItemMoreInformationToggle';
import ObjectClassItemSelect from './ObjectClassItemSelect';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    minHeight: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff6f6',
    },
    '&.selected': {
      background: '#ffeeee',
    },
    transition: '0.3s',
  },
  index: {
    width: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    color: '#797979',
  },
  colorChip: {
    width: '30px',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreInformationBox: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    width: '160px',
    padding: '10px',
  },
  annotationSelectBox: {
    width: '150px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emojiBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    borderRadius: '3px',
    background: '#f7f7f7',
  },
  emoji: {
    fontSize: '11px',
  },
}));

const objectHeight = 40;
const renderingSpareHeight = 100;

// eslint-disable-next-line
const ObjectClassItem = (props: any): React.ReactElement => {
  const classes = useStyles();
  const objectClassRef = useRef<HTMLDivElement>();
  const { objectClass, index, scrollTop, listHeight } = props;
  const newProjectInfo = useNewProjectInfo();
  const [isOpenMoreInformation, setIsOpenMoreInformation] = useState(true);
  const isSelected = newProjectInfo.selectedObjectClassIndex === index;
  const curTop = index * objectHeight;
  const isVisible =
    curTop >= scrollTop - renderingSpareHeight &&
    curTop <= scrollTop + listHeight + renderingSpareHeight;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setTimeout(() => {
      newProjectInfo.setSelectedObjectClassIndex(index);
    }, 0);
  };

  const handleClickColorChip = (color: string) => {
    if (color === objectClass.color) return;

    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    nextObjectClasses[index].color = color;
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (!objectClassRef || !objectClassRef.current) return;

    newProjectInfo.setDragState({ type: 'objectClass', index });
    objectClassRef.current.style.cursor = 'grabbing';
    if (!e?.dataTransfer) return;
    e.dataTransfer.setData('text', index);
    e.dataTransfer.setDragImage(objectClassRef.current, -10, -10);
  };

  const handleDragEnd = () => {
    if (!objectClassRef || !objectClassRef.current) return;
    newProjectInfo.setDragState(null);
    objectClassRef.current.style.cursor = 'pointer';
  };

  /*
  TODO: This doesn't work and is a bad way to do things anyway

  if (!isVisible) {
    return (
      <MUI.Box minHeight="40px" display="flex" alignItems="center" justifyContent="center">
        <Skeleton width="94%" height="30px" />
      </MUI.Box>
    );
  }

  */

  return (
    <MUI.Box position="relative">
      {index === 0 ? <ObjectClassItemDropBar dir="prev" index={index} /> : null}
      <MUI.Box
        {...{ ref: objectClassRef }}
        className={`${classes.box} ${isSelected ? 'selected' : ''} `}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable
      >
        <MUI.Box className={classes.index}>{index + 1}</MUI.Box>
        <MUI.Box className={classes.colorChip}>
          <ColorChipSelectBox
            direction="row"
            chipSize="12px"
            selectedColor={objectClass.color}
            handleClickColorChip={handleClickColorChip}
          />
        </MUI.Box>
        <MUI.Box className={classes.moreInformationBox}>
          <ObjectClassItemMoreInformationToggle
            objectClass={objectClass}
            isOpenMoreInformation={isOpenMoreInformation}
            setIsOpenMoreInformation={setIsOpenMoreInformation}
          />
        </MUI.Box>
        <MUI.Box className={classes.name}>
          <ObjectClassItemEditableText objectClass={objectClass} index={index} />
        </MUI.Box>
        <MUI.Box className={classes.annotationSelectBox}>
          <ObjectClassItemSelect
            objectClass={objectClass}
            index={index}
            setIsOpenMoreInformation={setIsOpenMoreInformation}
          />
        </MUI.Box>
      </MUI.Box>

      <ObjectClassItemDropBar dir="next" index={index} />
      {isOpenMoreInformation ? (
        <ObjectClassItemMoreInformation objectClass={objectClass} index={index} />
      ) : null}
    </MUI.Box>
  );
};

export default ObjectClassItem;
