import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import LanguageSelector from './LanguageSelector';

export default function Language() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      py={3.5}
      px={3}
    >
      <Typography variant="headline5">{t('settings.profile.interfaceLanguage')}</Typography>
      <LanguageSelector />
    </Box>
  );
}
