import { useEffect } from 'react';

import { startCase } from 'lodash';

import { useGetProjectList } from '../../hooks/GetProjectList';
import { useCommandBar as useCommandBarBool } from '../../utils/external';
import ProjectUtils from '../../utils/ProjectUtils';
import { useCommandBar } from '.';

export const ProjectListCommand = () => {
  const { addContext } = useCommandBar();
  const getProjectList = useGetProjectList();

  useEffect(() => {
    if (useCommandBarBool && window.CommandBar) {
      const projectTypeLabel = (workapp: string) => {
        const projectType = ProjectUtils.getProjectDataType(workapp);
        if (projectType === 'image') return 'Image project';
        if (projectType === 'video') return 'Video project';
        if (projectType) return `${startCase(projectType)} project`;
        return '';
      };

      const getProjects = async (query: string) => {
        const res = await getProjectList({
          nameIcontains: query,
          origin: 'contexts/ProjectsContext.jsx',
        });
        return res.results.map(project => ({
          ...project,
          commandDescription: `${projectTypeLabel(project.workapp)} with ${
            project.labelCount
          } labels`,
        }));
      };

      addContext({
        key: 'getProjects',
        initialValue: [],
        options: {
          searchOptions: {
            searchFunction: getProjects,
          },
        },
      });
    }
  }, []);

  return <></>;
};
