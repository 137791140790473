import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Box as BoxIcon,
  ErrorFilled,
  Exit,
  LinkExternal,
  LoadingSpinnerSmall,
  Model,
  Polygon,
} from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, ToggleButton, Tooltip, Typography } from '@superb-ai/ui';

import OverflowTooltip from '../../../../../../../components/composition/OverflowTooltip';
import { openChatWidget } from '../../../../../../../utils/chatWidget';
import { formatDistance } from '../../../../../../../utils/date';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import {
  useDeactivateDiagnosisMutation,
  useDiagnosisDetailQuery,
  useStartOrUpdateDiagnosisMutation,
} from '../../../../../queries/diagnosisModelQueries';
import { DiagnosisDetail, Splits } from '../../../../../services/DiagnosisModelService';
import { Split } from '../../../../../types/evaluationTypes';
import { MAX_DIAGNOSIS_QUOTA } from '../const';
import {
  DiagnosisStatusEnum,
  isDiagnosisActive,
  isDiagnosisFailed,
  isDiagnosisInactive,
  isDiagnosisInprogress,
} from '../diagnosis/types';
import { useDiagnosisPathname } from '../diagnosis/useGetUrl';
import TrainValSelect from './TrainValSelect';

export default function ListItem({
  diagnosis,
  index,
}: {
  diagnosis: DiagnosisDetail;
  index: number;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const diagnosisPathName = useDiagnosisPathname();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const [currentDiagnosis, setCurrentDiagnosis] = useState<DiagnosisDetail>(diagnosis);

  function getNonEmptyKeys(splits: Splits): Split[] {
    return (Object.keys(splits) as Split[]).filter((key: Split) => splits[key].length > 0);
  }

  function getSplitInPerStatus(status: DiagnosisStatusEnum): Split[] {
    if (status === DiagnosisStatusEnum.INACTIVE) {
      return [
        'VAL',
        ...(currentDiagnosis.predictionsCheck?.[`${currentDiagnosis.modelSource}Train`]
          ? (['TRAIN'] as Split[])
          : []),
      ];
    }
    if (status === DiagnosisStatusEnum.VAL_ACTIVE) return ['VAL'];
    if (status === DiagnosisStatusEnum.ALL_ACTIVE) return ['VAL', 'TRAIN'];
    if (status === DiagnosisStatusEnum.ACTIVE) {
      return ['VAL', ...(currentDiagnosis.modelSource === 'superb' ? (['TRAIN'] as Split[]) : [])];
    }
    return [];
  }

  const [splitIn, setSplitIn] = useState<Split[]>(getSplitInPerStatus(diagnosis.status));

  const commandContext = useCurateCommandContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { quotaCount, setQuotaCount } = useDiagnosisModelContext();
  const { refetchDiagnosisList } = useDiagnosisModelContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const isModelDeleted = !currentDiagnosis.modelName;

  const maxQuotaReached = quotaCount >= MAX_DIAGNOSIS_QUOTA;
  const enabled =
    isDiagnosisActive(currentDiagnosis.status) &&
    !(isModelDeleted && isDiagnosisInactive(currentDiagnosis.status));
  const noValPredictionExists = !(
    currentDiagnosis.predictionsCheck?.superbVal || currentDiagnosis.predictionsCheck?.externalVal
  );

  const {
    data: diagnosisResult,
    refetch: refetchDiagnosisDetail,
    isLoading: isLoadingDiagnosis,
  } = useDiagnosisDetailQuery({
    diagnosisId: currentDiagnosis.id,
    datasetId,
    diagnosisStatus: diagnosis?.status,
    queryRefetchOptions: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: (data: DiagnosisDetail) =>
        data && isDiagnosisInprogress(data.status) ? 5000 : false,
    },
    handleError: async err => {
      if (err.message === 'Not Found') {
        await refetchDiagnosisList();
      }
    },
    expand: ['predictions_check'],
  });

  useEffect(() => {
    if (!diagnosisResult) return;
    setCurrentDiagnosis(diagnosisResult);
  }, [diagnosisResult]);

  const { mutateAsync: startDiagnosis, isLoading: loadingActivation } =
    useStartOrUpdateDiagnosisMutation(commandContext);

  const { mutateAsync: deactivateDiagnosis, isLoading: loadingDeactivation } =
    useDeactivateDiagnosisMutation(commandContext);
  const handleActivateModel = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      await startDiagnosis({
        datasetId,
        diagnosisId: currentDiagnosis.id,
        modelId: currentDiagnosis.modelId,
        jobName: 'START_DIAGNOSIS',
        annotationType: currentDiagnosis.iouType === 'bbox' ? 'box' : 'polygon',
        modelSource: currentDiagnosis.modelSource,
        createdAt: currentDiagnosis.createdAt,
        skipTrain: !splitIn.includes('TRAIN'),
      });
      await refetchDiagnosisDetail();
      setQuotaCount(prev => prev + 1);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickDeactivate = async (e: any) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      await deactivateDiagnosis({
        datasetId,
        diagnosisId: diagnosis.id,
        modelId: diagnosis.modelId,
        deleteDiagnosis: !diagnosis.modelName,
      });
      await refetchDiagnosisDetail();
      setQuotaCount(prev => prev - 1);
    } catch (err: any) {
    } finally {
      setIsLoading(false);
    }
  };

  const activationOrUpdateDisabled = isModelDeleted || maxQuotaReached || noValPredictionExists;

  function TooltipMessage() {
    if (showPublicDatasets) {
      return undefined;
    }
    return maxQuotaReached
      ? t('curate.diagnosis.text.maxQuotaReached')
      : isModelDeleted
      ? t('curate.diagnosis.text.modelDeleted')
      : noValPredictionExists
      ? t('curate.diagnosis.text.noPredictionExists')
      : undefined;
  }

  const StatusButton = {
    [DiagnosisStatusEnum.ACTIVE]: (
      <>
        <TrainValSelect
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          disabled
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <ToggleButton checked disabled={showPublicDatasets} onClick={handleClickDeactivate} />
      </>
    ),
    [DiagnosisStatusEnum.VAL_ACTIVE]: (
      <>
        <TrainValSelect
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          disabled
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <ToggleButton checked disabled={showPublicDatasets} onClick={handleClickDeactivate} />
      </>
    ),
    [DiagnosisStatusEnum.ALL_ACTIVE]: (
      <>
        <TrainValSelect
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          disabled
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <ToggleButton checked disabled={showPublicDatasets} onClick={handleClickDeactivate} />
      </>
    ),
    [DiagnosisStatusEnum.INACTIVE]: (
      <>
        <TrainValSelect
          disabled={activationOrUpdateDisabled || showPublicDatasets}
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <Tooltip hideOnEmptyContent content={TooltipMessage()}>
          <span>
            <ToggleButton
              checked={false}
              disabled={activationOrUpdateDisabled || showPublicDatasets}
              onClick={handleActivateModel}
            />
          </span>
        </Tooltip>
      </>
    ),
    [DiagnosisStatusEnum.FAILED]: <Icon icon={ErrorFilled} color="red" />,
    [DiagnosisStatusEnum.ACTIVATING]: (
      <>
        <TrainValSelect
          disabled
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <Icon icon={LoadingSpinnerSmall} color="yellow" />
      </>
    ),
    [DiagnosisStatusEnum.UPDATING]: (
      <>
        <TrainValSelect
          disabled
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <Icon icon={LoadingSpinnerSmall} color="yellow" />
      </>
    ),
    [DiagnosisStatusEnum.DEACTIVATING]: (
      <>
        <TrainValSelect
          disabled
          splitIn={splitIn}
          setSplitIn={setSplitIn}
          predictionsCheck={currentDiagnosis.predictionsCheck}
        />
        <Icon icon={LoadingSpinnerSmall} color="yellow" />
      </>
    ),
  }[currentDiagnosis.status as DiagnosisStatusEnum];

  const ModelSourceIcon = (
    <Box display="flex" borderRadius="2px" backgroundColor="gray-150" color="gray-300" p={0.5}>
      <Icon icon={currentDiagnosis.modelSource === 'superb' ? Model : Exit} size="16px" />
    </Box>
  );

  function getAnnotationTypeIcon() {
    if (currentDiagnosis.iouType === 'bbox') return <Icon icon={BoxIcon} size="12px" />;
    if (currentDiagnosis.iouType === 'segm') return <Icon icon={Polygon} size="12px" />;
    return <></>;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="2px"
      border="1px solid"
      borderColor="gray-150"
      px={1.5}
      py={1}
      gap={1.5}
      cursor={enabled ? 'pointer' : undefined}
      backgroundColor={{
        hover: enabled ? 'gray-100' : undefined,
      }}
      disabled={!enabled}
      onClick={e => {
        if (!enabled) return;
        history.push(`/${diagnosisPathName}${currentDiagnosis.id}`);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {ModelSourceIcon}
      <Box>
        <Box display="flex" alignItems="center" gap={0.5}>
          {getAnnotationTypeIcon()}
          <OverflowTooltip
            typographyProps={{ style: { maxWidth: 232 }, variant: 'm-regular' }}
            text={currentDiagnosis.modelName || t('curate.diagnosis.text.modelSourceTag.deleted')}
          />
          {!isModelDeleted && currentDiagnosis.modelSource === 'superb' && (
            <Link
              onClick={e => {
                e.stopPropagation();
              }}
              style={{ display: 'flex', alignItems: 'center' }}
              to={`/${accountName}/model/recognition-ai/${currentDiagnosis.modelId}`}
            >
              <IconButton icon={LinkExternal} variant="text" size="xs" />
            </Link>
          )}
        </Box>
        <Typography variant="s-regular" color="gray-300">
          {formatDistance(currentDiagnosis.updatedAt)}
        </Typography>
      </Box>
      <Box display="flex" ml="auto" alignItems="center" gap={0.5}>
        {isLoading || isLoadingDiagnosis || loadingActivation || loadingDeactivation ? (
          <>
            <Typography variant="m-regular" color="gray-200">
              {t('button.loading')}
            </Typography>
            <Icon icon={LoadingSpinnerSmall} color="yellow" />
          </>
        ) : isHovered && isDiagnosisFailed(currentDiagnosis.status) ? (
          <>
            <Button onClick={() => openChatWidget()}>{t('text.contactUs')}</Button>
            {!isModelDeleted && (
              <Button
                disabled={activationOrUpdateDisabled || showPublicDatasets}
                color="primary"
                onClick={handleActivateModel}
              >
                {t('button.retry')}
              </Button>
            )}
          </>
        ) : (
          <>{StatusButton}</>
        )}
      </Box>
    </Box>
  );
}
