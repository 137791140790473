import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { Skeleton } from '@mui/material';
import { Box, Button, Card, Icon, useAlertModal } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';
import { capitalize, chunk } from 'lodash';

import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../contexts/AppContext';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useUsersQuery } from '../../../../queries/useUsers';
import { getUrl } from '../../../../routes/util';
import CommandsService from '../../../../services/CommandsService';
import FileService from '../../../../services/FileService';
import MislabelDetectionService from '../../../../services/MislabelDetectionService';
import { MemberData } from '../../../../types/memberTypes';
import {
  MislabelDetectionAction,
  MislabelDetectionActionHistory,
  MislabelDetectionAnnotationType,
  MislabelDetectionResult,
  MislabelDetectionResultsPerClass,
} from '../../../../types/mislabelDetectionTypes';
import FileUtils from '../../../../utils/FileUtils';
import StackedUserAvatar from '../../../elements/StackedUserAvatar';
import InfiniteWindowedGridContainer from '../../../elements/windowedImageGrid/InfiniteWindowedGridContainer';
import Breadcrumbs from '../Breadcrumbs';
import { db } from '../HistoryTable';
import DetailModal from './DetailModal';
import { getValidIndexedDbInfo } from './indexedDbUtils';
import ResultPerClassCell from './ResultPerClassCell';

export default function ResultPerClassView() {
  const params = useParams<{
    accountName: string;
    projectId: string;
    resultId: string;
    classId: string;
  }>();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const commandContext = useLabelCommandContext();

  const { openModal, closeModal } = useAlertModal();

  useSetPageTitle('Mislabel Detection', PAGE_TRACKING_ID.labelMislabelDetectionDeprecated);

  const [classResults, setClassResults] = useState<MislabelDetectionResultsPerClass>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [editHistories, setEditHistories] =
    useState<(MislabelDetectionActionHistory & { id: string })[]>();
  const [editors, setEditors] = useState<MemberData[]>();
  const [addIssueList, setAddIssueList] = useState<Record<string, MislabelDetectionAction>>(); // { mislabel_rank: MislabelDetectionAction }
  const [loadedItems, setLoadedItems] = useState<
    MislabelDetectionResult<MislabelDetectionAnnotationType>[]
  >([]);
  const isOnEditMode = !!addIssueList;

  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const columns = 5;
  const pageSize = columns * 4;

  const chunkedResults = useMemo(() => {
    if (!classResults) return [];
    return chunk(classResults.results, pageSize);
  }, [classResults]);

  const boxRef = useRef<HTMLDivElement>();
  const { data: users } = useUsersQuery({ params: { asList: '' }, statusIn: [] });

  useEffect(() => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (isOnEditMode) {
        e.returnValue = 'text';
      }
    };
  }, [isOnEditMode]);

  const onClickConfirmEdit = async () => {
    const issueList =
      addIssueList &&
      Object.values(addIssueList).map(issue => {
        const { label_id, annotation_id, mislabel_rank, change_type, bbox, params } = issue;
        return {
          label_id,
          annotation_id,
          mislabel_rank,
          change_type,
          bbox,
          params,
        };
      });

    const jsonFile = JSON.stringify({
      items: issueList,
      created_at: Date(),
      created_by: authInfo.email,
      name: authInfo.name,
    });

    const jsonFileSize = FileUtils.getJsonFileSize(jsonFile);

    if (!classResults) return;

    const uploadUrlRes = await MislabelDetectionService.requestEditMislabelDetection({
      projectId: params.projectId,
      mislabelDetectionId: params.resultId,
      data: {
        class_id: classResults.class_id,
        file_size: jsonFileSize,
      },
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: params.accountName, projectId: params.projectId },
    });

    await FileService.uploadPresignedJSON({
      uploadUrl: uploadUrlRes.uploadUrl,
      jsonFile,
    });

    const commandResponse = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'LABELS_AI_APPLY_CHANGES',
      projectId: params.projectId,
      params: {},
      actionInfo: {
        mislabel_detection_id: params.resultId,
        class_id: classResults.class_id,
        change_id: uploadUrlRes.id,
      },
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: params.accountName, projectId: params.projectId },
    });

    commandContext.registerCommand(commandResponse.data.id);

    setAddIssueList(undefined);

    await getEditHistory(classResults, users);
  };

  const onClickResetEdit = () => {
    openModal({
      title: 'Reset',
      content:
        "Are you sure you want to reset the changes you've made? This action cannot be undone.",
      variant: 'success',
      mainButton: {
        text: 'Reset',
        onClick: () => {
          setAddIssueList(undefined);
        },
      },
      subButton: {
        text: 'Close',
        onClick: () => closeModal(),
      },
      close: {
        onClose: () => closeModal(),
        canCloseWithExit: true,
        canClickOutside: true,
      },
    });
  };

  const loadMoreItems = async (startIndex: number, stopIndex: number) => {
    if (!classResults) return;
    const currentPage = Math.ceil((startIndex * columns) / pageSize);
    const items = await Promise.all(
      chunkedResults[currentPage].map(async result => {
        const response = await MislabelDetectionService.getMislabelDetectionGetThumbnail({
          projectId: params.projectId,
          mislabelDetectionId: params.resultId,
          data: {
            labelId: result.label_id,
            index: result.index.toString(),
            resolution: result.resolution,
          },
          isGuest: authInfo.isGuest,
          urlInfo: { accountName: params.accountName, projectId: params.projectId },
        });
        return { ...result, url: response.url };
      }),
    );

    if (currentPage === chunkedResults.length - 1) {
      setHasNextPage(false);
    }

    setLoadedItems([...loadedItems, ...items]);
  };

  const handleCloseModal = () => {
    setSelectedItemIndex(undefined);
  };

  const getEditHistory = async (
    classResult: MislabelDetectionResultsPerClass,
    totalUsers?: MemberData[],
  ) => {
    const historiesRes = await MislabelDetectionService.getMislabelDetectionEditHistory({
      projectId: params.projectId,
      mislabelDetectionId: params.resultId,
      classId: classResult.class_id,
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: params.accountName, projectId: params.projectId },
    });

    const editHistoryResultUrl = await Promise.all(
      historiesRes.changes.map(async history => {
        const historyResultJSON = await FileUtils.readUrlJson(history.downloadUrl);
        return { ...historyResultJSON, id: history.id };
      }),
    );

    setEditHistories(editHistoryResultUrl);

    const editHistoriesPerUser = editHistoryResultUrl.reduce((acc, history) => {
      if (!history.created_by) return acc;
      if (acc[history.created_by]) {
        return { ...acc, [history.created_by]: [...acc[history.created_by], history] };
      }
      return { ...acc, [history.created_by]: [history] };
    }, {});

    const editors = Object.keys(editHistoriesPerUser).reduce<any>((acc, email) => {
      const userInfo = (totalUsers || users)?.find(user => user.email === email);
      return [...acc, userInfo];
    }, []);

    setEditors(editors);
  };

  useEffect(() => {
    (async () => {
      const dbMislabelHistoryTable = await getValidIndexedDbInfo({
        db: db.mislabelHistoryTable,
        id: params.resultId,
      });
      let classResult;
      if (dbMislabelHistoryTable) {
        const { results } = dbMislabelHistoryTable;
        classResult = results.find(result => result.class_id === params.classId);
      } else {
        const res = await MislabelDetectionService.getMislabelDetectionHistory({
          projectId: params.projectId,
          mislabelDetectionId: params.resultId,
          isGuest: authInfo.isGuest,
          urlInfo: { accountName: params.accountName, projectId: params.projectId },
        });
        const resultJSON = await FileUtils.readUrlJson(res.resultUrl);
        classResult = resultJSON.find((result: any) => result.class_id === params.classId);
        await db.mislabelHistoryTable.put({
          ...res,
          results: resultJSON,
          savedAt: new Date(),
        });
      }

      setClassResults(classResult);

      await getEditHistory(classResult, users);
    })();
  }, []);

  const editHistoriesPerItem:
    | Record<number, MislabelDetectionAction & { created_at: Date; created_by: Date }>
    | undefined = useMemo(() => {
    if (!editHistories) return;
    return editHistories.reduce((acc, history) => {
      const { items, created_by, created_at } = history;
      const itemsPerRank = items.reduce((itemAcc: Record<string, string>, item) => {
        const { mislabel_rank } = item;
        if (itemAcc[mislabel_rank]) {
          return {
            ...itemAcc,
            [mislabel_rank]: [...itemAcc[mislabel_rank], { ...item, created_at, created_by }],
          };
        }
        return { ...itemAcc, [mislabel_rank]: [{ ...item, created_at, created_by }] };
      }, acc);

      return itemsPerRank;
    }, {});
  }, [editHistories]);

  return (
    <>
      <Breadcrumbs
        previousPageName="Mislabel Detection Result"
        previousPageUrl={getUrl([
          params.accountName,
          'label',
          'project',
          params.projectId,
          'advanced-ai-features',
          'mislabel-detection',
          'results',
          params.resultId,
        ])}
      />
      {classResults ? (
        <Box display="flex" alignItems="center" gap="12px" height="32px">
          <Typography variant="h1">{`${capitalize(
            classResults?.class_name,
          )} (${classResults.results.length.toLocaleString('en')})`}</Typography>
          {editors && editors.length > 0 && (
            <Box
              themedBackgroundColor={['grey', 60]}
              p={0.5}
              pl={1.5}
              style={{ borderRadius: '100px' }}
              display="flex"
              alignItems="center"
              gap="4px"
            >
              <Typography variant="s-regular">Edited by</Typography>
              <StackedUserAvatar userInfos={editors} size={24} />
            </Box>
          )}
        </Box>
      ) : (
        <Skeleton height="32px" width="280px" variant="rectangular" />
      )}
      <Box
        ref={boxRef}
        mt={2}
        style={{
          marginLeft: '-32px',
          marginRight: '-32px',
          marginBottom: '-76px',
        }}
        backgroundColor="#f3f3f3"
      >
        <Box pt={2} px={4}>
          <Card p={2} display="flex" alignItems="center">
            <Icon name="messageCircle" color="primary" />
            <Box display="flex" alignItems="center" ml={1} pr={1.5} br>
              <Typography variant="m-strong">Add Issues</Typography>
            </Box>
            <Box display="flex" flex={1} alignItems="center" gap="16px" pr={0.5}>
              <Box display="flex" alignItems="center" gap="8px" px={1}>
                <Typography variant="m-regular">Redraw Annotation</Typography>
                <Typography variant="h3">
                  {addIssueList
                    ? Object.values(addIssueList)
                        .filter(item => item.issue_type?.includes('redraw'))
                        .length.toLocaleString('en')
                    : 0}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="8px" px={1}>
                <Typography variant="m-regular">Change Class</Typography>
                <Typography variant="h3">
                  {addIssueList
                    ? Object.values(addIssueList)
                        .filter(item => item.issue_type?.includes('change_class'))
                        .length.toLocaleString('en')
                    : 0}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="8px" px={1}>
                <Typography variant="m-regular">Delete Annotation</Typography>
                <Typography variant="h3">
                  {addIssueList
                    ? Object.values(addIssueList)
                        .filter(item => item.issue_type?.includes('delete'))
                        .length.toLocaleString('en')
                    : 0}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="8px" px={1}>
                <Typography variant="m-regular">Other</Typography>
                <Typography variant="h3">
                  {addIssueList
                    ? Object.values(addIssueList)
                        .filter(item => item.issue_type?.includes('other'))
                        .length.toLocaleString('en')
                    : 0}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="4px">
              <Button variant="text" onClick={onClickResetEdit} disabled={!isOnEditMode}>
                Reset
              </Button>
              <Button onClick={onClickConfirmEdit} disabled={!isOnEditMode}>
                Confirm
              </Button>
            </Box>
          </Card>
        </Box>
        <InfiniteWindowedGridContainer
          isLoading={!classResults}
          loadItems={loadMoreItems}
          loadedItems={loadedItems}
          hasNextPage={hasNextPage}
          CellComponent={ResultPerClassCell}
          boxProps={{ px: 4, py: 2 }}
          aspectRatio={1}
          itemData={{
            addIssueList,
            setAddIssueList,
            setSelectedItemIndex,
            ...(classResults && {
              classInfo: { class_name: classResults.class_name, class_id: classResults.class_id },
            }),
            columns: 5,
            editHistoriesPerItem,
            editors,
          }}
        />
      </Box>
      {classResults && selectedItemIndex && selectedItemIndex >= 0 && (
        <DetailModal
          isOpen={selectedItemIndex >= 0}
          onClose={handleCloseModal}
          index={selectedItemIndex}
          setIndex={setSelectedItemIndex}
          classInfo={classResults}
        />
      )}
    </>
  );
}
