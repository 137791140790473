import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card } from '@superb-ai/norwegian-forest';
import { Box, Paragraph, Typography } from '@superb-ai/ui';

import EmptyPlot from '../elements/EmptyPlot';
import OverallTrendPieChart from './OverallTrendPieChart';
import OverallTrendPieChartLegend from './OverallTrendPieChartLegend';
import { ComparisonReport } from './types/types';
import { colorForChangeType } from './utils/colorScale';
import { pieChartDataFormatter } from './utils/formatter';
import { guideMessage, hasZeroAnnotationChanges, overallTrendTransformer } from './utils/helper';

interface Props {
  selectedVersion: ComparisonReport;
}

const OverallTrendContainer = ({ selectedVersion }: Props) => {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const highlightPiece = (i: number, status?: 'in' | 'out') => {
    status === 'in' ? setHoveredIndex(i) : setHoveredIndex(-1);
  };

  const rawData = overallTrendTransformer(selectedVersion);
  const pieData = pieChartDataFormatter(rawData);

  return (
    <Card mt={3}>
      <Box display="flex" p={3} alignItems="center" borderBottom="1px solid" borderColor="gray-100">
        <Typography variant="h3">{t('analytics.exportsAnalytics.overallTrend.title')}</Typography>
      </Box>
      <Box>
        <Box p={3} style={{ backgroundColor: '#FBFBFB' }} position="relative">
          <Box display="grid" style={{ gridTemplateColumns: '25% 45% 30%' }}>
            <Box mt={1}>
              <Paragraph variant="m-strong">
                ⭐️ {t('analytics.exportsAnalytics.heatmap.explanationTitle')}
              </Paragraph>
              <Paragraph variant="m-regular">
                <Trans t={t} i18nKey="analytics.exportsAnalytics.overallTrend.explanationText" />
              </Paragraph>
            </Box>
            <OverallTrendPieChart
              data={pieData}
              getColor={colorForChangeType}
              hoveredIndex={hoveredIndex}
              highlightPiece={highlightPiece}
            />
            <OverallTrendPieChartLegend
              data={pieData}
              selectedIndex={hoveredIndex}
              getColor={colorForChangeType}
              highlightPiece={highlightPiece}
            />
          </Box>
          {hasZeroAnnotationChanges(rawData) && (
            <EmptyPlot
              key="overall-trend-message"
              message={t(guideMessage(selectedVersion))}
              svgHeight={300}
              marginTop={30}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default OverallTrendContainer;
