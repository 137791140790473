import { useState } from 'react';

import { labelerDefaultConfig } from '../config';

const useLabelerFilter = () => {
  const [roles, setRoles] = useState<string[]>(labelerDefaultConfig.roles);
  const [showAnnoTypeColumns, setshowAnnoTypeColumns] = useState<boolean>(true);
  const [showObjectClassColumns, setShowObjectClassColumns] = useState<boolean>(true);

  const handleChangeRoles = (newValue: string[]) => {
    setRoles(newValue);
  };

  return {
    roles,
    showAnnoTypeColumns,
    setshowAnnoTypeColumns,
    showObjectClassColumns,
    setShowObjectClassColumns,
    handleChangeRoles,
  };
};

export default useLabelerFilter;
