import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ArrowRight, SliceAdd } from '@superb-ai/icons';
import { Box, Button, Icon, LinkTypography, Typography } from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import { getUserManualUrl } from '../../../../../../../consts/DocsLink';
import CreateEmptySliceModal from '../../modal/CreateEmptySliceModal';
import emptyImage from './img_empty_state_slice.png';

export default function SliceEmptyPage({
  handleClickCreate,
}: {
  handleClickCreate: () => Promise<void>;
}) {
  const { i18n, t } = useTranslation();
  const { CURATE_CREATE_SLICE_MANUAL } = getUserManualUrl(i18n.language);
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const history = useHistory();
  const [createSliceModalIsOpen, setCreateSliceModalIsOpen] = useState<boolean>(false);

  return (
    <>
      {createSliceModalIsOpen && (
        <CreateEmptySliceModal
          createSliceModalIsOpen={createSliceModalIsOpen}
          setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
          onCreate={handleClickCreate}
        />
      )}
      <Box display="flex" alignItems="center" flexDirection="column" mt={1}>
        <Image
          src={emptyImage}
          alt="Illustration showing a robot holding the hand of a person"
          width={600}
        />
        <Box mt={0.5}>
          <Typography variant="h2" style={{ fontSize: '20px' }}>
            <>{t('curate.slices.startSlice')}</>
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={0.5}
        >
          <Typography variant="m-regular" style={{ color: '#B3B3B3' }}>
            <Trans t={t} i18nKey={'curate.slices.uploadGuide'} />
          </Typography>
          <Typography variant="m-regular" color="gray-300">
            (
            <Link href={CURATE_CREATE_SLICE_MANUAL} target="_blank" rel="noopener noreferrer">
              <LinkTypography variant="m-regular">
                {t('curate.slices.learnMoreSlice')}
              </LinkTypography>
            </Link>
            )
          </Typography>
        </Box>
        <Box display="flex" mt={2}>
          <Box>
            <Button
              variant="stroke"
              color="primary"
              size="m"
              onClick={() => {
                setCreateSliceModalIsOpen(true);
              }}
            >
              <Icon icon={SliceAdd} />
              {t('curate.button.createEmptySlice')}
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              variant="strong-fill"
              color="primary"
              size="m"
              onClick={() => history.push(`/${accountName}/curate/dataset/${datasetId}/explore`)}
            >
              <Icon icon={ArrowRight} />
              {t('curate.button.exploreData')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
