import React from 'react';

import { Box, Icon, IconButton, Typography } from '@superb-ai/norwegian-forest';

const ErrorToast: React.FC<{
  errors: { dataKey: string; error: string }[];
  onClose: () => void;
}> = ({ errors, onClose }) => {
  if (errors.length <= 0) return <></>;
  return (
    <Box
      position="fixed"
      right="20px"
      bottom="20px"
      themedBackgroundColor="grey"
      minWidth="240px"
      p={2}
      borderRadius
    >
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" fontSize="14px" mr={1}>
          <Icon name="errorOutline" color="primary" />
        </Box>
        <Typography themedColor="background" variant="body3">
          Upload Error(s)
        </Typography>
        <Box ml="auto">
          <IconButton icon="clear" size="xs" style={{ color: '#fff' }} onClick={onClose} />
        </Box>
      </Box>
      <Box ml={3} mt={1} maxHeight="160px" overflow="auto">
        {errors.map(item => (
          <Typography key={item.dataKey} variant="body3" themedColor="background">
            {item.dataKey}: {item.error}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default ErrorToast;
