import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../../contexts/ObjectScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetObjectsInfiniteQuery } from '../../../../../../queries/datasetObjectQueries';
import GridLayout from '../GridLayout';
import ImageCell from './ImageCell';
import ObjectDetailModal from './ObjectDetailModal';

export default function ObjectGridContents() {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { totalCount } = useObjectScopeContext();
  const { queryString } = useQueryContext();

  const { sliceInfo } = useSliceContext();
  const { setSampleThumbnailUrls } = useActionContext();
  const { appliedFilters, setIsApplyingFilter } = useObjectFilterContext();

  const [openDetailIndex, setOpenDetailIndex] = useState<number>();
  const pageSize = 20;

  //It is expected to call the api by distinguishing it here with the scope variable.
  const objectDataQuery = useDatasetObjectsInfiniteQuery({
    datasetId,
    queryString,
    sliceName: sliceInfo?.name || '',
    pageSize,
    appliedFilters,
  });

  // TODO: useEffect is not ideal here.
  // It would be nicer to refactor sampleThumbnailUrls to derive its state from query data.
  useEffect(() => {
    if (!objectDataQuery.data?.pages[0].results) return;
    setSampleThumbnailUrls(
      objectDataQuery.data.pages[0].results
        .slice(0, 4)
        .map(result => ({ id: result.id, thumbnailUrl: result.imageThumbnailUrl || '' })),
    );
  }, [objectDataQuery.data, setSampleThumbnailUrls]);

  useEffect(() => {
    setIsApplyingFilter(objectDataQuery.isLoading);
  }, [objectDataQuery.isLoading, setIsApplyingFilter]);

  const loadedItems = objectDataQuery.data?.pages.flatMap(p => p.results) ?? [];

  return (
    <GridLayout
      queryResult={objectDataQuery}
      DetailModal={
        openDetailIndex !== undefined ? (
          <ObjectDetailModal
            pageSize={pageSize}
            totalCount={totalCount || 0}
            loadMoreItem={async () => {
              await objectDataQuery.fetchNextPage();
            }}
            loadedItems={loadedItems}
            openImageIndex={openDetailIndex}
            setOpenImageIndex={setOpenDetailIndex}
          />
        ) : (
          <></>
        )
      }
      ImageCell={ImageCell}
      setOpenIndex={setOpenDetailIndex}
    />
  );
}
