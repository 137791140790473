import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight, Check, Clear, Trash } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  Color,
  Dialog,
  Icon,
  IconButton,
  Input,
  LoadingIndicator,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import { Row } from '../../../components/elements/Row';
import {
  useCreateModelTagMutation,
  useDeleteModelTagMutation,
  useModelTagsQuery,
} from '../queries/modelQueries';
import { ModelTag } from '../services/types';
import { randomColorTokenByBrightness } from '../utils/colorUtils';
import { useModelSettingContext } from './contexts/ModelSettingContext';

export const EditTagDialog = ({ state }: { state: ReturnType<typeof useDialogState> }) => {
  const { t } = useTranslation();
  const { data: modelTags } = useModelTagsQuery({});
  const { mutate } = useCreateModelTagMutation();
  const { selectedTags, setSelectedTags } = useModelSettingContext();

  const [tagSearchText, setTagSearchText] = useState('');
  const [chipColor, setChipColor] = useState<Color>('red');

  const searchedTagList =
    modelTags?.modelTags.filter(tag => (tagSearchText ? tag.name.includes(tagSearchText) : true)) ??
    [];
  const isExistTag = searchedTagList.find(tag => tag.name === tagSearchText);

  const showCreateTagRow = !isExistTag && tagSearchText;

  const handleClickTagRow = (tag: ModelTag) => {
    if (selectedTags.find(_ => _.name === tag.name)) return;
    setSelectedTags(prev => [...prev, tag]);
  };

  const handleClickDeselectTags = (tag: ModelTag) => {
    setSelectedTags(prev => prev.filter(_ => _.name !== tag.name));
  };

  const handleClickCreateTagRow = () => {
    const newTag = { name: tagSearchText, color: chipColor };
    mutate(newTag);
    setSelectedTags(prev => [...prev, newTag]);
  };

  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.myModelDetail.editTag')}
      </Dialog.Header>
      {!modelTags ? (
        <LoadingIndicator />
      ) : (
        <Row display="grid" style={{ gridTemplateColumns: '1fr 16px 1fr', columnGap: 4 }}>
          <Box
            display="flex"
            flexDirection="column"
            border="1px solid"
            borderColor={'gray-150'}
            style={{ height: 340 }}
          >
            <Box p={1} bb="1px solid" borderColor={'gray-150'}>
              <Input
                value={tagSearchText}
                onChange={e => {
                  if (e.target.value.length > 20) return;
                  setTagSearchText(e.target.value);
                }}
                color="gray"
                variant="soft-fill"
                onFocus={() => setChipColor(randomColorTokenByBrightness())}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleClickCreateTagRow();
                    setTagSearchText('');
                  }
                }}
              />
              {tagSearchText.length >= 20 && (
                <Typography variant="s-regular" color="red-400">
                  {t('model.editTagDialog.warningMessage')}
                </Typography>
              )}
            </Box>
            {!modelTags ? (
              <Row justifyContent="center" mt={5}>
                <LoadingIndicator />
              </Row>
            ) : (
              <Box overflow="auto" height="100%" style={{ flex: '1 1 auto' }}>
                <Typography color="gray-300" variant="s-regular" mx={1}>
                  {t('model.editTagDialog.selectOption')}
                </Typography>
                {searchedTagList.map(x => {
                  return <TagRow key={x.name} tag={x} onClick={handleClickTagRow} />;
                })}
              </Box>
            )}
            {showCreateTagRow && (
              <Row
                py={0.5}
                px={1}
                gap={1}
                backgroundColor={'gray-100'}
                cursor="pointer"
                width="100%"
                onClick={() => {
                  handleClickCreateTagRow();
                }}
              >
                <Typography variant="s-regular" color="gray-300">
                  {t('model.editTagDialog.create')}&nbsp;
                </Typography>
                <Chip color={chipColor}>{tagSearchText}</Chip>
              </Row>
            )}
          </Box>
          <Icon icon={ArrowRight} size={16} />
          <SelectedTagsSection selectedTags={selectedTags} onDeselect={handleClickDeselectTags} />
        </Row>
      )}
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.close')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const SelectedTagsSection = ({
  selectedTags,
  onDeselect,
}: {
  selectedTags: ModelTag[];
  onDeselect: (tag: ModelTag) => void;
}) => {
  return (
    <Box border="1px solid" borderColor={'gray-150'} p={1} style={{ height: 340 }}>
      {selectedTags.map(tag => {
        return (
          <Chip key={tag.name} color={tag.color} name={tag.name} mr={1} mb={0.5}>
            {tag.name}
            <Icon icon={Clear} onClick={() => onDeselect(tag)} />
          </Chip>
        );
      })}
    </Box>
  );
};

const TagRow = ({ tag, onClick }: { tag: ModelTag; onClick: (tag: ModelTag) => void }) => {
  const { t } = useTranslation();

  const { mutate } = useDeleteModelTagMutation();
  const [toggleDelete, setToggleDelete] = useState(false);

  const handleClickTagDelete = () => {
    setToggleDelete(prev => !prev);
    mutate(tag.name);
  };

  return (
    <Box
      cursor="pointer"
      onClick={e => {
        e.stopPropagation();
        onClick(tag);
      }}
    >
      <Row
        key={tag.name}
        py={0.5}
        px={1}
        justifyContent="space-between"
        backgroundColor={{ hover: 'gray-100' }}
      >
        <Chip color={tag.color}>{tag.name}</Chip>
        <IconButton
          variant="text"
          icon={Trash}
          color="primary"
          size="s"
          onClick={e => {
            e.stopPropagation();
            setToggleDelete(true);
          }}
        />
      </Row>
      {toggleDelete && (
        <Row backgroundColor={'primary-400'} px={1} justifyContent="space-between">
          <Typography variant="s-regular" color="white">
            {t('model.editTagDialog.deleteTag')}
          </Typography>
          <Row>
            <IconButton
              icon={Check}
              variant="text"
              size="s"
              color="white"
              onClick={e => {
                e.stopPropagation();
                handleClickTagDelete();
                setToggleDelete(false);
              }}
            />
            <IconButton
              icon={Clear}
              variant="text"
              size="s"
              color="white"
              onClick={e => {
                e.stopPropagation();
                setToggleDelete(false);
              }}
            />
          </Row>
        </Row>
      )}
    </Box>
  );
};
