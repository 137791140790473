import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Accordion, Box } from '@superb-ai/norwegian-forest';
import { capitalize } from 'lodash';

import LabelConst from '../../../../consts/LabelConst';
import { useLabelCompareInfo } from '../../../../contexts/LabelCompareContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import CabinetCompareItem from './CabinetCompareItem';

const useStyles = makeStyles(() => ({
  box: { width: '100%' },
  innerBox: {
    padding: '8px',
  },
}));

const CabinetCompareList = (): React.ReactElement => {
  type LabelType = 'main' | 'consensus';
  const labelInformationInfo = useLabelInformationInfo();
  const labelCompareInfo = useLabelCompareInfo();
  const labelTypeOrder = { main: 1, consensus: 2 };
  const {
    labelAnnotationInfos: { current, autoLabel },
  } = labelInformationInfo;
  const { relatedLabels } = labelCompareInfo;
  const relatedLabelList = relatedLabels && Object.values(relatedLabels);
  const sortedRelatedLabelList = relatedLabelList?.sort((a: any, b: any) => {
    if (labelTypeOrder[a.type as LabelType] > labelTypeOrder[b.type as LabelType]) return 1;
    if (a.numberId > b.numberId) return 1;
    return -1;
  });

  const classes = useStyles();

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box className={classes.innerBox}>
        <CabinetCompareItem
          option={{
            label: capitalize(current.type),
            value: current,
            title: LabelConst.COMPARE_OPTIONS.CURRENT,
          }}
        />
        <CabinetCompareItem
          option={{
            label: 'Auto-Label',
            value: autoLabel,
            title: LabelConst.COMPARE_OPTIONS.AUTO_LABEL,
          }}
        />
        <Box bb mb={2} />
        {sortedRelatedLabelList && (
          <>
            <Accordion summary="Related Labels" defaultOpen>
              {relatedLabelList.map((label: any) => {
                const option = {
                  label: capitalize(label.type),
                  value: label,
                  type: 'relatedLabel',
                  title: label.id,
                };
                return <CabinetCompareItem selectable option={option} key={label.id} />;
              })}
            </Accordion>
          </>
        )}
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetCompareList;
