import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Skeleton from '@mui/material/Skeleton';
import { Box } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import MislabelDetectionService from '../../../../services/MislabelDetectionService';
import { MemberData } from '../../../../types/memberTypes';
import {
  MislabelDetectionAction,
  MislabelDetectionAnnotationType,
  MislabelDetectionResult,
} from '../../../../types/mislabelDetectionTypes';
import SingleAnnotatedImage from '../SingleAnnotatedImage';
import AddIssuesPopper from './postActions/AddIssuesPopper';
import HistoryPopper from './postActions/HistoryPopper';

type Props = {
  classInfo: { class_id: string; class_name: string };
  imageInfo: MislabelDetectionResult<MislabelDetectionAnnotationType> & { url?: string };
  onClickImage: (imageUrl: string) => void;
  addIssueList?: Record<string, MislabelDetectionAction>;
  setAddIssueList?: Dispatch<SetStateAction<Record<string, MislabelDetectionAction>>>;
  hasHoverEffect?: boolean;
  aspectRatio?: number;
  history?: (MislabelDetectionAction & { created_at: Date; created_by: string })[];
  editors?: MemberData[];
};

export default function ResultImage<T extends MislabelDetectionAnnotationType>({
  classInfo,
  imageInfo,
  onClickImage,
  addIssueList,
  setAddIssueList,
  hasHoverEffect,
  aspectRatio = 1,
  history,
  editors,
}: Props) {
  const { t } = useTranslation();
  const params = useParams<{ accountName: string; projectId: string; resultId: string }>();
  const authInfo = useAuthInfo();
  const [imageSize, setImageSize] = useState<[number, number]>(); // [width, height]
  const isSelected = imageInfo && addIssueList && !!addIssueList[imageInfo.mislabel_rank];

  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [isPopperVisible, setIsPopperVisible] = useState(false);

  useEffect(() => {
    if (!imageInfo) return;
    if (imageInfo.url) {
      setUrl(imageInfo.url);
      return;
    }

    (async () => {
      setLoading(true);
      const response = await MislabelDetectionService.getMislabelDetectionGetThumbnail({
        projectId: params.projectId,
        mislabelDetectionId: params.resultId,
        data: {
          labelId: imageInfo.label_id,
          index: imageInfo.index.toString(),
          resolution: imageInfo.resolution,
        },
        isGuest: authInfo.isGuest,
        urlInfo: { accountName: params.accountName, projectId: params.projectId },
      });
      setUrl(response.url);
      setLoading(false);
    })();
  }, [imageInfo]);

  useEffect(() => {
    if (!url) return;
    const img = new Image();
    img.src = url;
    img.onload = function (onLoadResult) {
      const img = onLoadResult.target as HTMLImageElement;
      setImageSize([img.naturalWidth, img.naturalHeight]);
    };
  }, [url]);

  if (!url) return <></>;

  if (loading) {
    return <Skeleton height="100%" variant="rectangular" />;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      className={hasHoverEffect && 'clickable'}
      onClick={() => onClickImage(url)}
      width="100%"
      height="100%"
      backgroundColor="white"
      onMouseEnter={() => {
        if (!hasHoverEffect || isPopperVisible) return;
        setHover(true);
      }}
      onMouseLeave={() => {
        if (!hasHoverEffect || isPopperVisible) return;
        setHover(false);
      }}
    >
      {imageSize && (
        // eslint-disable-next-line @next/next/no-img-element
        <>
          <SingleAnnotatedImage
            srcUrl={url}
            alt={imageInfo.label_id}
            annotation={{
              classId: classInfo.class_id,
              type: imageInfo.annotation_type,
              coordinate: imageInfo.annot_new,
              annotationId: imageInfo.annotation_id,
            }}
            style={{
              ...(imageSize[0] / imageSize[1] < aspectRatio
                ? { height: '100%' }
                : { width: '100%' }),
              display: 'block' /* remove extra space below image */,
            }}
          />
          {hasHoverEffect && (
            <>
              <Box
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  visibility: hover || isSelected ? 'visible' : 'hidden',
                  ...(isSelected ? { outline: '3px solid #ff625a', outlineOffset: '-3px' } : {}),
                }}
              />
              <Box position="absolute" bottom={4} right={4} ml="auto">
                {setAddIssueList && (
                  <AddIssuesPopper
                    classInfo={classInfo}
                    imageInfo={imageInfo}
                    addIssueList={addIssueList}
                    setAddIssueList={setAddIssueList}
                    hover={hover}
                    setHover={setHover}
                    isPopperVisible={isPopperVisible}
                    setIsPopperVisible={setIsPopperVisible}
                  />
                )}
              </Box>
              {history && (
                <Box position="absolute" bottom={4} left={4}>
                  <HistoryPopper history={history} editors={editors} />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
