import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Typography } from '@superb-ai/norwegian-forest';
import Image from 'next/image';

import { useCalAvailabilityInfo } from '../../../../../contexts/CalAvailabilityContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { getUrl } from '../../../../../routes/util';
import Howto from '../../cards/Howto';
import Step from '../../cards/Step';
import TutorialCard from '../../cards/TutorialCard';
import tutorialImage from './tutorial.png';

const stepsUrls = ['labels', 'export', 'auto-label/settings'];

export default function Tutorial({ hasExports }: { hasExports: boolean }) {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const projectInfo = useProjectInfo();
  const history = useHistory();
  const { requiredLabelCountForCreatingCAL } = useCalAvailabilityInfo();
  function goTo(path: string) {
    const url = getUrl([accountName, 'label', 'project', projectInfo.project.id, path]);
    history.push(url);
  }

  return (
    <TutorialCard
      title={
        <Typography variant="body2" textAlign="center">
          {t('autoLabel.cal.guide.description')}
        </Typography>
      }
      image={
        <Image
          style={{ maxWidth: '100%', height: 'auto' }}
          src={tutorialImage}
          alt="Illustration showing a robot and a person making a drawing on a blackboard"
        />
      }
    >
      <Howto title={t('autoLabel.cal.guide.title')}>
        <Step
          number={1}
          text={
            <Typography variant="body3">
              <Trans
                t={t}
                i18nKey={'autoLabel.cal.guide.items.0'}
                count={requiredLabelCountForCreatingCAL}
              />
            </Typography>
          }
          completed={hasExports}
          onClick={() => goTo(stepsUrls[0])}
        />
        <Step
          number={2}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'autoLabel.cal.guide.items.1'} />
            </Typography>
          }
          completed={hasExports}
          onClick={() => goTo(stepsUrls[1])}
        />
        <Step
          number={3}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'autoLabel.cal.guide.items.2'} />
            </Typography>
          }
          completed={hasExports}
          onClick={() => goTo(stepsUrls[2])}
        />
      </Howto>
    </TutorialCard>
  );
}
