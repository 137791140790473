import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ArrowRight, Download, SliceAdd } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';
import PrepareDownloadModal from '../modal/PrepareDownloadModal';
import PrepareDownloadButton from '../actionButton/PrepareDownloadButton';
import { useDatasetDataCountQuery } from '../../../../queries/dataQueries';
import { useDatasetObjectCountQuery } from '../../../../queries/datasetObjectQueries';
import { useDatasetContext } from '../../../../contexts/DatasetContext';

export default function EmptyPage() {
  const { i18n, t } = useTranslation();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { datasetInfo } = useDatasetContext();
  const history = useHistory();
  const dialog = useDialogState();
  const dataQuery = useDatasetDataCountQuery({
    datasetId,
    queryString: '',
  });

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column" mt={1}>
        <Image
          src="https://asset.superb-ai.com/suite/clip-list-is-empty.png"
          alt="empty page illustration"
          width={600}
          height={400}
        />
        <Box mt={0.5}>
          <Typography variant="h2" style={{ fontSize: '20px' }}>
            <>{t('curate.downloads.empty.title')}</>
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={0.5}
        >
          <Typography variant="m-regular" color="gray-300">
            <Trans
              t={t}
              i18nKey={'curate.downloads.empty.description'}
              components={{
                link1: (
                  <LinkTypography
                    color="gray"
                    variant="m-regular"
                    onClick={() =>
                      history.push(`/${accountName}/curate/dataset/${datasetId}/explore`)
                    }
                  />
                ),
                link2: (
                  <LinkTypography
                    color="gray"
                    variant="m-regular"
                    onClick={() =>
                      history.push(`/${accountName}/curate/dataset/${datasetId}/slice/list`)
                    }
                  />
                ),
              }}
            />
          </Typography>
        </Box>
        <Box mt={2}>
          <PrepareDownloadButton
            variant="text"
            imageTotalCount={dataQuery.data?.count || 0}
            datasetId={datasetId}
            datasetName={datasetInfo.name}
          />
        </Box>
      </Box>
    </>
  );
}
