import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Icon, Typography } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles({
  step: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
});

interface Props extends React.ComponentProps<typeof Box> {
  number: number;
  text?: string | JSX.Element;
  completed?: boolean;
}

export default function Step({ number, text, children, completed = false, ...boxProps }: Props) {
  const classes = useStyles();

  return (
    <Box
      {...boxProps}
      className={classes.step}
      display="flex"
      alignItems="center"
      gap="8px"
      py={2.5}
      pl={2}
      pr={6}
    >
      <Box minWidth={24} display="flex" justifyContent="center">
        <Typography variant="headline5">
          {' '}
          {completed ? <Icon name="check" color="green" /> : number}
        </Typography>
      </Box>
      <Box flex={1}>
        {text && typeof text === 'string' ? (
          <Typography variant="headline6">{text}</Typography>
        ) : (
          text
        )}
        {children}
      </Box>
    </Box>
  );
}
