import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Gauge,
  Typography,
} from '@superb-ai/norwegian-forest';
import * as d3 from 'd3';
import { subDays } from 'date-fns';
import { isEmpty, omit, reverse } from 'lodash';

import AnalyticsTracker from '../../../analyticsTracker';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useContainerSize } from '../../../hooks/ContainerSizeHook';
// services & context
import AdvancedQaService, {
  ConsensusHistogramData,
  ConsensusLabelsSummaryAPI,
  ConsensusProgressHistoryDatum,
} from '../../../services/AdvancedQaService';
import LabelsService from '../../../services/LabelsService';
import DateUtils from '../../../utils/DateUtils';
import MathUtils from '../../../utils/MathUtils';
import { getError } from '../error/error';
import { downloadDataFile } from './chartContainers/helper';
import Histogram from './charts/barChart/Histogram';
import { BinSizeOptions, reBinData, ScoreBinInfo } from './charts/barChart/histogramUtil';
// constants, configs, types and mock data
import { EMPTY_MESSAGE } from './config/constants';
import { CHART } from './config/plotConfig';
import { SvgConfigObject, SvgInfoBarChart } from './config/types';
import { FileFormat } from './dataTypes/analyticsDataTypes';
// components & utils
import EmptyPlot from './elements/EmptyPlot';
import LabelQACard from './elements/LabelQACard';
import NumberTracker from './elements/NumberTracker';
import LabelerQAContainer from './qaAnalytics/LabelerQAContainer';
import DownloadDropDown from './tools/DownloadDropDown';
import { getQAChartMockData } from './userStats/mock';

export const getHistogramSvgInfo = ({
  mt,
  mb,
  ml,
  mr,
  width,
  height,
  barHoverColor,
  barDefaultColor,
}: SvgInfoBarChart): SvgConfigObject => {
  return {
    left: ml,
    right: mr,
    top: mt,
    bottom: mb,
    width: width - ml - mr,
    height: height - mt - mb,
    svgWidth: width,
    svgHeight: height,
    barHoverColor: barHoverColor,
    barDefaultColor: barDefaultColor,
  };
};

const LabelQATab: React.FC = () => {
  const projectInfo = useProjectInfo();
  const projectName = projectInfo?.project?.name;

  const analyticsProxy = useFeatureFlag('analyticsProxy');

  const authInfo = useAuthInfo();
  const { isGuest } = authInfo;

  const routeInfo = useRouteInfo();
  const { urlMatchInfo } = routeInfo;

  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);
  const { t } = useTranslation();
  const consensusChartInfo = CHART.consensusQAStats;
  const NF_PURPLE = '#AF48FF';
  const histogramSvgInfo = getHistogramSvgInfo({
    mt: 20,
    mb: 20,
    ml: 30,
    mr: 50,
    width: width || 1200,
    height: 302,
    barDefaultColor: NF_PURPLE,
    barHoverColor: d3.rgb(NF_PURPLE).darker(0.5) as unknown as string,
  });

  const { title, chartName, xVariable, yVariable } = consensusChartInfo;
  const [xKey, xName] = xVariable;
  const [yKey, yName] = yVariable;
  const {
    project: { labelCount, uniqueLabelCount },
  } = projectInfo;
  const projectId = projectInfo.project.id;
  const [consensusSummary, setConsensusSummary] = useState<ConsensusHistogramData>();
  const [consensusProgressSummary, setConsensusProgressSummary] = useState(
    {} as ConsensusLabelsSummaryAPI,
  );
  const [consensusProgressHistory, setConsensusProgressHistory] = useState(
    [] as ConsensusProgressHistoryDatum[],
  );
  const [uniqueConsensusLabelCount, setUniqueConsensusLabelCount] = useState(0);
  const [binSize, setBinSize] = useState<BinSizeOptions>(10);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  useEffect(() => {
    (async () => {
      try {
        const defaultCallArgs = {
          t,
          projectId,
          isGuest,
          urlInfo: urlMatchInfo,
          origin,
        };
        if (analyticsProxy) {
          // v2 proxy
          const [summary, uniqueConsensusLabelCount] = await Promise.all([
            AdvancedQaService.getConsensusLabelsSummary(defaultCallArgs),
            (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
              ...defaultCallArgs,
              params: {
                labelTypeIn: ['CONSENSUS'],
                groupAssets: true,
              },
            }),
          ]);
          setConsensusSummary({
            scoreDistribution: summary.scoreDistribution,
            maxCount: summary.maxCount,
          });
          setConsensusProgressSummary(
            omit(summary, ['scoreDistribution', 'maxCount']) as ConsensusLabelsSummaryAPI,
          );
          setUniqueConsensusLabelCount(uniqueConsensusLabelCount);
        } else {
          // v1
          const [summary, [progressSummary, progressHistory], uniqueConsensusLabelCount] =
            await Promise.all([
              AdvancedQaService.getConsensusSummary(defaultCallArgs),
              AdvancedQaService.getConsensusProgressSummary(defaultCallArgs),
              (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
                ...defaultCallArgs,
                params: {
                  labelTypeIn: ['CONSENSUS'],
                  groupAssets: true,
                },
              }),
            ]);
          setConsensusSummary({
            scoreDistribution: summary.scoreDistribution,
            maxCount: summary.maxCount,
          });
          setConsensusProgressSummary(progressSummary);
          setConsensusProgressHistory(progressHistory);
          setUniqueConsensusLabelCount(uniqueConsensusLabelCount);
        }
      } catch (error) {
        reportError({ message: getError(error) });
      }
    })();
    // eslint-disable-next-line
  }, []);

  /**
   * Download - Supports 'csv' , 'xlsx', and 'png'
   */
  const handleDownload = (outputType: FileFormat) => {
    const data =
      binSize === 1
        ? (consensusSummary?.scoreDistribution as ScoreBinInfo[])
        : reBinData(consensusSummary?.scoreDistribution as ScoreBinInfo[], binSize);
    // const chartType = 'histogram';
    // TODO (ml) - fix image download -> somehow svg node is none
    // if (outputType === 'png') {
    //   downloadImageFile({
    //     projectName,
    //     chartDisplayName: 'Consistency Score Summary',
    //     chartName,
    //     chartType,
    //   });
    //   return;
    // }
    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: data,
        projectName,
        chartName,
      });
      return;
    }
    downloadDataFile({
      downloadData: data,
      projectName,
      chartName: 'consistency_score_summary',
      excelSheetName: 'score_summary',
    });
    AnalyticsTracker.chartDownloaded({
      accountId: projectInfo.project.accountId,
      fileExtension: outputType,
      chartName: 'consistency_score_summary',
      chartType: 'histogram',
      feature: 'label-analytics',
    });
  };

  const handleBinSizeChange = (binSize: BinSizeOptions) => {
    setBinSize(binSize);
  };

  const getRightButtonGroup = (): React.ReactElement => {
    return (
      <ButtonGroup orientation="horizontal">
        <Button
          color={'grey'}
          onClick={() => handleBinSizeChange(1)}
          style={{
            flex: 0,
          }}
          variant={binSize === 1 ? 'strong-fill' : 'stroke'}
        >
          1
        </Button>
        <Button
          color={'grey'}
          onClick={() => handleBinSizeChange(5)}
          style={{
            flex: 0,
          }}
          variant={binSize === 5 ? 'strong-fill' : 'stroke'}
        >
          5
        </Button>
        <Button
          color={'grey'}
          onClick={() => handleBinSizeChange(10)}
          style={{
            flex: 0,
          }}
          variant={binSize === 10 ? 'strong-fill' : 'stroke'}
        >
          10
        </Button>
        {/* <Box>
          <div>
            <IconButton
              size={'m'}
              icon="chartBar"
              color={chartDefault === 'bar' ? 'primary' : 'lightgrey'}
              variant="stroke"
              // disabled={hasZeroLabels}
              // onClick={() => setChartType('donut')}
            />
          </div>
        </Box> */}
      </ButtonGroup>
    );
  };

  const getEmptyMessage = (hasConsensusLabels: boolean, hasAnyLabels: boolean) => {
    if (!hasAnyLabels) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    }
    if (!hasConsensusLabels) {
      return t(EMPTY_MESSAGE.ADD_CONSENSUS_LABEL);
    }

    // has consensus labels but no submitted consensus labels
    return t(EMPTY_MESSAGE.SUBMIT_CONSENSUS_LABEL);
  };

  const {
    consensusLabelCount,
    submittedConsensusLabelCount,
    averageNumVotes,
    averageScore,
    submittedPercent,
    oneWeekChange,
  } = consensusProgressSummary;

  const trend = reverse(consensusProgressHistory);
  const today = new Date();
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

  // has no submitted consensus labels
  const hasZeroSubmittedConsensus = !submittedConsensusLabelCount;

  // Prepend data for a single point, since line chart doesn't display one point
  if (trend?.length === 1) {
    const currentDate = trend[0]?.date; // ex. Thu Apr 22 2021 09:00:00 GMT+0900 (Korean Standard Time)

    if (currentDate) {
      trend.unshift({
        averageScore: 0.0,
        submittedPercent: 0.0,
        date: subDays(currentDate, 1),
      } as ConsensusProgressHistoryDatum);
    }
  }

  return (
    <>
      <Box display="flex" width="100%" gap={1} ref={chartContainerRef}>
        <Box width="calc(25% - 4px)" br p={2}>
          <LabelQACard
            title={t('analytics.qa.dataWithConsensusLabeling')}
            information={
              <NumberTracker
                current={uniqueConsensusLabelCount || 0}
                total={uniqueLabelCount || 0}
              />
            }
            visualized={
              <CircularProgress
                percentage={
                  MathUtils.calculatePercent({
                    numerator: uniqueConsensusLabelCount,
                    denominator: uniqueLabelCount,
                  }) || 0
                }
                color="purple"
              />
            }
            tooltipText={t('analytics.qa.text.percentConsensusData', {
              percent:
                MathUtils.calculatePercent({
                  numerator: uniqueConsensusLabelCount,
                  denominator: uniqueLabelCount,
                }) || 0,
            })}
          />
        </Box>
        <Box width="calc(25% + 4px)" br p={2}>
          <LabelQACard
            title={t('analytics.qa.avgConsensusLabels')}
            information={<NumberTracker current={averageNumVotes || 0} />}
            visualized={
              <Gauge
                max={10}
                value={averageNumVotes ? Math.round(averageNumVotes) : 0}
                thickness={24}
                totalSize={78}
                color="purple"
                roundCorner
              />
            }
            tooltipText={t('analytics.qa.text.consensusLabelsPerData', {
              count: Math.round(averageNumVotes) | 0,
            })}
          />
        </Box>
        <Box width="calc(25% + 4px)" br p={2} position="relative">
          <LabelQACard
            title={t('analytics.qa.submittedConsensusLabels')}
            information={
              <NumberTracker
                format="percent"
                current={10}
                total={100}
                changes={oneWeekChange?.submittedPercent}
                isPercent
              />
            }
            visualized={<></>}
            additionalInfo={
              analyticsProxy
                ? ''
                : `Compared to last week (${DateUtils.getDayOfTheWeek(lastWeek, true)})`
            }
            // tooltipText={t('analytics.qa.text.pastWeek')}
          />
        </Box>
        <Box width="calc(25% - 4px)" p={2} position="relative">
          <LabelQACard
            title={t('analytics.qa.meanConsistencyScore')}
            information={
              <NumberTracker
                format="percent"
                current={averageScore}
                total={100}
                changes={
                  oneWeekChange?.averageScore
                    ? MathUtils.roundPercent(oneWeekChange?.averageScore)
                    : undefined
                }
              />
            }
            visualized={<></>}
            additionalInfo={
              analyticsProxy
                ? ''
                : `Compared to last week (${DateUtils.getDayOfTheWeek(lastWeek, true)})`
            }
            // tooltipText={t('analytics.qa.text.pastWeek')}
          />
        </Box>
      </Box>
      <Card mt={3} mb={4} width={width}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={2}
          pb={3}
          pr={3}
          pl={3}
        >
          <Box>
            <Box display="flex" alignItems="center" justifyContent="start">
              <Typography variant="headline5" themedColor="textDefault">
                {t(title)}
              </Typography>
              <DownloadDropDown
                key={'download-dropdown'}
                handleDownload={handleDownload}
                options={['csv', 'xlsx']}
                isToggleDisabled={isEmpty(consensusSummary?.scoreDistribution)}
              />
            </Box>
            <NumberTracker current={submittedConsensusLabelCount} total={consensusLabelCount} />
          </Box>
          <Box display="flex" mr="5px" alignItems="center" justifyContent="space-between">
            {getRightButtonGroup()}
          </Box>
        </Box>
        <div id="consensus-histogram-box">
          <Box
            display="flex"
            justifyContent="center"
            p={2}
            themedBackgroundColor={['grey', 50]}
            position="relative"
            width={width}
          >
            <Histogram
              chartName={chartName}
              svgInfo={histogramSvgInfo}
              data={
                hasZeroSubmittedConsensus
                  ? getQAChartMockData()
                  : reBinData(consensusSummary?.scoreDistribution || [], binSize)
              }
              yLabelName={t(yName)}
              xLabelName={t(xName)}
              xKey={xKey}
              yKey={yKey}
              totalCounts={consensusSummary?.maxCount || 100}
              binSize={binSize}
            />
            {hasZeroSubmittedConsensus && (
              <EmptyPlot
                svgHeight={histogramSvgInfo.svgHeight}
                message={getEmptyMessage(consensusLabelCount > 0, labelCount > 0)}
              />
            )}
          </Box>
        </div>
      </Card>
      <LabelerQAContainer
        hasSubmittedConsensusLabels={submittedConsensusLabelCount > 0}
        hasConsensusLabels={consensusLabelCount > 0}
        hasAnyLabels={labelCount > 0}
        width={width}
      />
    </>
  );
};

export default LabelQATab;
