import { ComponentProps, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Check, Data, Slice } from '@superb-ai/icons';
import {
  Box,
  Dropdown,
  Icon,
  NamedColorWeight,
  Paragraph,
  Tooltip,
  Typography,
} from '@superb-ai/ui';
import { toLower } from 'lodash';

import Checkbox from '../../../../analytics/components/Checkbox';

export type Legend = {
  isChecked: boolean;
  color: string;
  selectedOptionColor?: NamedColorWeight;
  handleClickLabel: () => void;
  tooltip?: string;
};

export type DropdownOption = {
  group: string;
  id: string | undefined;
  name: string | undefined;
  totalCount?: number | undefined | string;
};

type Props = {
  options: DropdownOption[];
  handleSelectOption: (optionId: string) => void;
  selectedOptionId: string;
  legend: Legend;
} & ComponentProps<typeof Box>;

export function DropdownLegend({
  options,
  selectedOptionId,
  handleSelectOption,
  legend,
  ...props
}: Props) {
  const { t } = useTranslation();
  const { color, isChecked, handleClickLabel } = legend;
  const selected = options.find(d => d.id === selectedOptionId) ?? options[0];
  const selectedIcon = <Icon size={'s'} icon={selected?.group === 'Slice' ? Slice : Data} />;

  const onSelectOption = (event: SyntheticEvent, newId: string) => {
    event.stopPropagation();
    handleSelectOption(newId);
  };

  const handleCheckboxChange = (event: SyntheticEvent) => {
    event.stopPropagation();
    handleClickLabel();
  };

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Tooltip hideOnEmptyContent content={legend?.tooltip} placement="top">
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          style={{
            height: '32px',
            cursor: 'pointer',
          }}
          onClick={handleCheckboxChange}
          {...props}
        >
          {
            <Dropdown
              tabIndex={0}
              color={'gray-opacity'}
              disclosure={
                <Box display="flex" flexDirection={'row'} gap={0.5} alignItems="center">
                  <Checkbox
                    checkboxColor={color}
                    checkMarkColor="white"
                    checked={isChecked}
                    onClick={() => handleClickLabel()}
                  />
                  <Box display="flex" flexDirection={'row'} gap={0.25} alignItems="center">
                    {selectedIcon}
                    {selected?.name ?? options[0]?.name}
                  </Box>
                </Box>
              }
              size="l"
              variant="text"
            >
              <Box display="flex" flexDirection="column" style={{ lineHeight: '10px' }}>
                {options.map(
                  ({ group, id, name }) =>
                    id && (
                      <Box
                        key={group}
                        py={1}
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography pl={1.5} variant={'m-medium'} color="gray-300">
                          {t(`curate.data.${toLower(group)}`)}
                        </Typography>
                        <Tooltip
                          content={t(`curate.analytics.dataComparison.compareWith${group}`)}
                          placement="right"
                        >
                          <Paragraph
                            key={id}
                            mb={0.5}
                            px={2}
                            py={1}
                            variant="l-regular"
                            color={
                              id === selectedOptionId ? legend.selectedOptionColor : 'gray-400'
                            }
                            onClick={event => onSelectOption(event, id)}
                            backgroundColor={{ hover: 'primary-200' }}
                            style={{
                              cursor: 'pointer',
                              lineHeight: '16px',
                            }}
                          >
                            {'  '}
                            {name}
                            {id === selected?.id && (
                              <Icon style={{ marginLeft: '5px' }} icon={Check} />
                            )}
                          </Paragraph>
                        </Tooltip>
                      </Box>
                    ),
                )}
              </Box>
            </Dropdown>
          }
        </Box>
      </Tooltip>
    </Box>
  );
}
