import { SvgConfigDonutObject, SvgConfigObject, SvgConfigScrollBarObject } from './types';

/**
 * Specifies SVG margins used to render charts.
 *
 * Retrieved by Each chart, which has ownership of SVG margin information.
 */

/**
 * @param {chart} object chart config, including, margins, width, svgWidth, etc.
 * @param {dataLength} int length of data (both x and y should have same length)
 *
 * Returns object with default values for scroll bar chart setting.
 *
 * Future TODO: allow user to configure `sliceWidth` by dynamically adjusting scroll size
 * */
const getScrollSetting = (
  chart: {
    left: number;
    right: number;
    top: number;
    bottom: number;
    width: number;
    height: number;
    svgWidth: number;
    svgHeight: number;
  },
  dataLength: number,
) => {
  // Sets # of bars under scroll selector & is rendered in detailed chart
  const sliceWidth = dataLength <= 30 ? Math.floor(dataLength / 3) : 20;
  const scrollSelectorWidth = Math.round((sliceWidth / dataLength) * chart.width);

  return {
    sliceWidth, // very confusing. rename this variable (? numSelectedSlices?)
    scrollBandWidth: chart.width / dataLength,
    scrollHeight: 60,
    scrollLeftBound: chart.left,
    scrollRightBound: chart.svgWidth - chart.right - scrollSelectorWidth,
    scrollSelectorWidth,
    scrollBarTopPadding: 18,
  };
};

/**
 *
 * @param {string} chartKind one of 'short' 'regular'
 * @param {boolean} isScrollable
 * @param {int} dataLength
 */

export const SVG_PROPERTY = (
  chartKind: 'short' | 'regular',
  width?: number,
  height?: number,
): SvgConfigObject => {
  const margin = { top: 35, bottom: 50, right: 60, left: 60 };

  const SVG_WIDTH =
    width ||
    {
      short: 690,
      regular: 930,
    }[chartKind];

  const SVG_HEIGHT =
    height ||
    {
      short: 283,
      regular: 295,
    }[chartKind];

  const config = {
    left: margin.left,
    right: margin.right,
    top: margin.top,
    bottom: margin.bottom,
    width: SVG_WIDTH - margin.left - margin.right,
    height: SVG_HEIGHT - margin.top - margin.bottom,
    svgWidth: SVG_WIDTH,
    svgHeight: SVG_HEIGHT,
    barHoverColor: '#FF625A',
    barDefaultColor: '#FE9573',
  };

  // BAR, LINE, etc.
  return config;
};
interface SvgBarConfig {
  innerPadding?: number;
  outerPadding?: number;
}
type SvgScrollBarProps = Partial<SvgConfigScrollBarObject> & {
  width: number;
  height: number;
} & SvgBarConfig;

export const SVG_PROPERTY_SCROLL_BAR = (
  config: SvgScrollBarProps,
  dataLength = -1,
): SvgConfigScrollBarObject => {
  const margin = {
    top: config?.marginTop ?? 30,
    right: config?.marginRight ?? 70,
    bottom: config?.marginBottom ?? 75,
    left: config?.marginLeft ?? 65,
  };

  const SVG_WIDTH = config?.width;
  const SVG_HEIGHT = config?.height ?? 295;

  const container = {
    left: margin.left,
    right: margin.right,
    top: margin.top,
    bottom: margin.bottom,
    width: SVG_WIDTH - margin.left - margin.right,
    height: SVG_HEIGHT - margin.top - margin.bottom,
    svgWidth: SVG_WIDTH,
    svgHeight: SVG_HEIGHT,
  };

  const scrollSetting = getScrollSetting(container, dataLength);
  const { scrollHeight, scrollBarTopPadding } = scrollSetting;
  return {
    ...container,
    ...scrollSetting,
    barSliceWidth: 7,
    barHoverColor: '#FF625A',
    barDefaultColor: '#FE9573',
    // barPadding: 20,
    innerPadding: 0.5,
    outerPadding: 0.9,
    svgHeightWithScroll: scrollHeight + scrollBarTopPadding + container.svgHeight,
    svgHeightFull: 420,
  };
};

type SvgDonutProps = Partial<SvgConfigDonutObject> & { width: number; height: number };

export const SVG_PROPERTY_DONUT = (config?: SvgDonutProps): SvgConfigDonutObject => {
  const margin = {
    top: config?.marginTop ?? 20,
    right: config?.marginRight ?? 25,
    bottom: config?.marginBottom ?? 30,
    left: config?.marginLeft ?? 20,
  };
  const SVG_WIDTH = config?.width ? config?.width / 2 : 540;
  const SVG_HEIGHT = config?.height || 300;
  const LEGEND_HEIGHT = config?.height ? config?.height - 20 : 260;
  const LEGEND_WIDTH = 360;
  const svgProperty = {
    left: margin.left,
    right: margin.right,
    top: margin.top,
    bottom: margin.bottom,
    width: SVG_WIDTH - margin.left - margin.right,
    height: SVG_HEIGHT - margin.top - margin.bottom,
    svgWidth: SVG_WIDTH,
    svgHeight: SVG_HEIGHT,
    legendWidth: LEGEND_WIDTH,
    legendHeight: LEGEND_HEIGHT,
  };

  return {
    ...svgProperty,
    arcThickness: 14,
    radius: Math.min(svgProperty.width, svgProperty.height) / 2,
    innerRadius: Math.min(svgProperty.width, svgProperty.height) / 2 - 25,
  };
};
