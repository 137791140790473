import React, { ReactElement } from 'react';

import { Box, Tooltip, Typography } from '@superb-ai/norwegian-forest';

type Props = {
  title: string;
  information: ReactElement;
  visualized: ReactElement;
  additionalInfo?: string;
  tooltipText?: string;
};
const LabelQACard: React.FC<Props> = ({
  title,
  information,
  visualized,
  additionalInfo,
  tooltipText,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        height="72px"
        maxWidth="134px"
        display="flex"
        flexDirection="column"
        flexWrap="noWrap"
        justifyContent="space-between"
      >
        <Box mb={0.5}>
          <Typography variant="body5" themedColor={['grey', 400]} whiteSpace="nowrap">
            {title}
          </Typography>
        </Box>
        {information}
        {additionalInfo && (
          <Typography variant="body5" themedColor={['grey', 400]} whiteSpace="nowrap">
            {additionalInfo}
          </Typography>
        )}
      </Box>
      {!tooltipText ? (
        <>{visualized} </>
      ) : (
        <Tooltip anchorEl={<span>{visualized}</span>}>{tooltipText}</Tooltip>
      )}
    </Box>
  );
};

export default LabelQACard;
