import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { Location } from 'history';

import { useAppContext } from '../contexts/AppContext';
import { useAuthInfo } from '../contexts/AuthContext';
import { useFeatureFlags } from '../contexts/FeatureFlagContext';
import { useProjectInfo } from '../contexts/ProjectContext';
import { getUrl, joinPaths } from '../routes/util';
import { evaluate } from '../utils/functional';
import { MenuItem } from './MenuItem';

interface Props {
  items: MenuItem[];
  defaultUrl?: string;
  trackLastUrl?: boolean;
}

/**
 * AppRoutes renders all given children menu item routes as a Switch.
 * Always redirects to one of its menu items; either defaultUrl or the first menu item.
 * If trackLastUrl is active, keeps track of last visited URL within this context and
 * restores that url as the default redirect.
 * An item's `component` is rendered with a `menuItem` prop refering back to the same menu item.
 * This is a way of dependency injection while avoiding circular imports between MenuItems and Layout.
 */
export default function AppRoutes({ items, defaultUrl, trackLastUrl = false }: Props): JSX.Element {
  const match = useRouteMatch();
  const history = useHistory();
  const { project } = useProjectInfo();
  const authInfo = useAuthInfo();
  const { t } = useTranslation();
  const { flags } = useFeatureFlags();

  const { setLastUrl, getLastUrl, setPageTitle, setPageId, setBreadcrumb } = useAppContext();
  const lastUrl = trackLastUrl && getLastUrl(match.url);

  const context = useMemo(
    () => ({
      authInfo,
      project,
      featureFlags: flags,
      t,
    }),
    [authInfo, project, t, flags],
  );

  // Only render enabled items, i.e. user cannot access disabled routes.
  const enabledItems = items.filter(item => evaluate(item.isEnabled)(context) !== false);
  function getDefaultUrl() {
    if (lastUrl && lastUrl !== match.url) {
      return lastUrl;
    }
    return defaultUrl ?? getUrl([match.url, enabledItems[0].path]);
  }

  function updateLocation(location: Location) {
    const activeMenuItem = items.find(item =>
      getUrlMatched(joinPaths(match.url, item.path), location.pathname),
    );
    if (activeMenuItem && activeMenuItem.title) {
      ReactDOM.unstable_batchedUpdates(() => {
        setPageTitle(evaluate(activeMenuItem.title)(context));
        setPageId(activeMenuItem.pageId);
      });
    }
    if (activeMenuItem) {
      setBreadcrumb(evaluate(activeMenuItem.breadcrumb)(context) ?? []);
    }
    if (trackLastUrl && location.pathname.indexOf(match.url) === 0) {
      setLastUrl(match.url, location.pathname);
    }
  }

  useEffect(() => {
    updateLocation(history.location);
    return history.listen(updateLocation);
  }, [context]);

  return (
    <Switch>
      {enabledItems.map(menuItem => {
        const fullPath = joinPaths(match.path, menuItem.matchParam || menuItem.path);
        const redirectTo = evaluate(menuItem.redirectTo)(context);
        if (!!redirectTo) {
          return <Redirect key={menuItem.path} from={fullPath} to={redirectTo} />;
        }

        return (
          <Route
            key={menuItem.path}
            path={fullPath}
            exact={menuItem.exact}
            // @ts-ignore: saa-680
            render={props => <menuItem.component {...props} menuItem={menuItem} />}
          />
        );
      })}
      <Redirect to={getDefaultUrl()} />
    </Switch>
  );
}

export function getUrlMatched(originPathname: string, currentPathname: string) {
  const normalizedOriginPathname = originPathname.replace(/\/+$/, '');
  const normalizedCurrentPathname = currentPathname.replace(/\/+$/, '');

  const segmentsOrigin = normalizedOriginPathname.split('/');
  const segmentsCurrent = normalizedCurrentPathname.split('/');

  for (let i = 0; i < segmentsCurrent.length; i++) {
    if (i >= segmentsOrigin.length) {
      return false;
    }

    if (segmentsOrigin[i] === segmentsCurrent[i]) {
      continue;
    }

    if (segmentsOrigin[i].startsWith(':') && segmentsCurrent[i] !== '') {
      continue;
    }

    return false;
  }

  return true;
}
