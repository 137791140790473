import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Box, Button, Card, Icon, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import CommandsService from '../../../../../services/CommandsService';
import ProjectService from '../../../../../services/ProjectService';
import { formatDistance } from '../../../../../utils/date';
import FileUtils from '../../../../../utils/FileUtils';
import { formatCount } from '../../../../../utils/numberFormat';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';

const Layout: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const commandContext = useLabelCommandContext();
  const { t } = useTranslation();

  const [archive, setArchive] = useState<any>(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isPooling, setIsPooling] = useState(false);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const getArchiveInfo = async () => {
    try {
      const res = await ProjectService.getArchive({
        projectId: projectInfo.project.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      return res;
    } catch (err: any) {
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsRequesting(true);

        const nextArchive = await getArchiveInfo();
        setArchive(nextArchive);

        if (nextArchive.state !== 'READY_FOR_DOWNLOAD' && nextArchive?.state !== 'FAILED') {
          setIsPooling(true);
        }
        setIsRequesting(false);
      } catch (err: any) {
        setIsRequesting(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isPooling) return;

    const timer = setInterval(() => {
      (async () => {
        const res = await getArchiveInfo();
        setArchive(res);

        if (res?.state === 'READY_FOR_DOWNLOAD' || res?.state === 'FAILED') {
          setIsPooling(false);
        }
      })();
    }, 5000);

    // eslint-disable-next-line
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [isPooling]);

  const handleClickDownload = async () => {
    if (isRequesting) return;

    setIsRequesting(true);
    try {
      const { downloadSignedUrl } = await ProjectService.createArchiveReadUrl({
        projectId: projectInfo.project.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      FileUtils.downloadUrl(downloadSignedUrl);
    } catch (err: any) {
      if (err?.message === 'Not Found') {
        enqueueSnackbar('Synchronize first', { variant: 'warning' });
      }
    }
    setIsRequesting(false);
  };

  const handleClickCopyLink = async () => {
    if (isRequesting) return;

    try {
      const { downloadSignedUrl } = await ProjectService.createArchiveReadUrl({
        projectId: projectInfo.project.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      FileUtils.copyToClipboard({ value: downloadSignedUrl });

      enqueueSnackbar(COPY_SUCCESS({ t, label: 'archive link' }), { variant: 'success' });
    } catch (err: any) {
      if (err?.message === 'Not Found') {
        enqueueSnackbar('Synchronize first', { variant: 'warning' });
      }
    }
  };

  const handleClickSync = async () => {
    if (isRequesting) return;
    setIsRequesting(true);
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'ASSETS_DOWNLOAD',
        params: null,
        projectId: projectInfo.project.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);
      if (archive) {
        archive.processingProgress = 0;
      }
      setIsPooling(true);
      setIsRequesting(false);
    } catch (err: any) {
      if (err?.message === 'Already request') {
        enqueueSnackbar('Already Requested', { variant: 'warning' });
      }
      setIsRequesting(false);
    }
  };

  return (
    <>
      <Card p={3} mb={3}>
        <Box mb={2}>
          <Typography variant="body3">
            <Trans t={t} i18nKey="export.raw-data.intro" />
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box mr={1}>
            {isPooling ||
            (archive && archive.state !== 'READY_FOR_DOWNLOAD' && archive?.state !== 'FAILED') ? (
              <Box position="relative" display="inline-flex">
                <MUI.CircularProgress
                  style={{ top: 0, left: 0, width: '26px', height: '26px' }}
                  color="secondary"
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body5">{`${Math.round(
                    (archive?.processingProgress || 0) * 100,
                  )}%`}</Typography>
                </Box>
              </Box>
            ) : (
              <Button
                size="m"
                IconAdornment={<Icon name="sync" />}
                iconPosition="left"
                color="secondary"
                variant="stroke"
                onClick={handleClickSync}
                disabled={isPooling}
              >
                {' '}
                {t('export.raw-data.updateBtn')}{' '}
              </Button>
            )}
          </Box>
          <Box mr={1}>
            <Button
              size="m"
              IconAdornment={<Icon name="download" />}
              iconPosition="left"
              color="grey"
              onClick={handleClickDownload}
              disabled={isPooling || archive?.state !== 'READY_FOR_DOWNLOAD'}
            >
              {' '}
              {t('export.raw-data.downloadBtn')}{' '}
            </Button>
          </Box>
          <Box mr={1}>
            <IconButton
              round
              size="xs"
              color="grey"
              variant="text"
              icon="link"
              disabled={isPooling || archive?.state !== 'READY_FOR_DOWNLOAD'}
              onClick={handleClickCopyLink}
            />
          </Box>
        </Box>
        {archive && archive.state === 'READY_FOR_DOWNLOAD' && (
          <Box mt={1}>
            <Typography variant="body4">
              {t('export.raw-data.lastUpdated')}: {t(formatDistance(archive.updatedAt))}
            </Typography>
          </Box>
        )}
        {archive && archive.state === 'FAILED' && (
          <Box mt={1}>
            <Typography variant="body5" themedColor="primary">
              {t('export.raw-data.errorMsg')}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2}>
          <Box>
            <Typography variant="body4">
              {archive && (
                <>
                  {t('export.raw-data.totalFileCount')}
                  {': '}
                  {formatCount(archive.downloadFileCount || 0)}
                  <br />
                </>
              )}
              {t('export.raw-data.totalDataCount')}
              {': '}
              {formatCount(projectInfo.project.uniqueLabelCount)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="4px">
            <Icon name="errorOutline" color="primary" />
            <Typography variant="body5">{t('export.raw-data.changeAlert')}</Typography>
          </Box>
        </Box>
      </Card>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
};

export default Layout;
