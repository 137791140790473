import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box } from '@superb-ai/norwegian-forest';

import CommentBody from './CommentBody';
import CommentHeader from './CommentHeader';
import { CommentProps, ThreadProps } from './types';

const useStyles = makeStyles(() => ({
  comment: {
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
}));

const Comment: React.FC<Partial<CommentProps> & Omit<ThreadProps, 'pos'>> = props => {
  const classes = useStyles();

  const {
    isCreate,
    issue,
    comment,
    index,
    indexOfEditingComment,
    setIndexOfEditingComment,
    isFocused = false,
  } = props;

  return (
    <Box
      className={classes.comment}
      style={isFocused ? { borderTop: `solid 1px ${issue.info.color}` } : null}
    >
      <CommentHeader
        issue={issue}
        index={index}
        isCreate={isCreate}
        comment={comment}
        indexOfEditingComment={indexOfEditingComment}
        setIndexOfEditingComment={setIndexOfEditingComment}
      />
      {!isCreate && (
        <CommentBody
          issue={issue}
          index={index}
          comment={comment}
          indexOfEditingComment={indexOfEditingComment}
          setIndexOfEditingComment={setIndexOfEditingComment}
        />
      )}
    </Box>
  );
};

export default Comment;
