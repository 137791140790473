import React, { useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import {
  Box,
  FileTree,
  FileTreeModel,
  FileType,
  getFileNameSortComparator,
  getTreeModel,
  Icon,
  IItem,
  Link,
  Typography,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { AlertModalProps } from '@superb-ai/norwegian-forest/dist/components/composition/AlertModal/types';
import { PointcloudManifest } from '@superb-ai/siesta';
import cn from 'classnames';
import { intersectionBy, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import * as path from 'path-browserify';

import { FILE_UPLOAD_TYPE_NOT_SUPPORTED } from '../../../consts/SnackbarMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import { useCurrentPlan } from '../../../queries/useSubscriptionQuery';
import TierUnion from '../../../union/TierUnion';
import { fileListToTree, FileWithPath, searchTree } from '../../../utils/FileUtils';

const useStyles = makeStyles(() => ({
  uploadIcon: {
    marginRight: '5px',
    color: '#4d4d4d',
  },
  dropzone: {
    '&.isDragActive': {
      backgroundColor: '#ff615a12',
    },
  },
}));

const DragAndDropPointCloud: React.FC<{ dataCapacity: number }> = props => {
  const { t } = useTranslation();
  const { dataCapacity } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // const { openModal } = useModal();
  const { openModal, closeModal } = useAlertModal();

  const { files, setFiles } = useUploadInfo();
  const routeInfo = useRouteInfo();

  const [filesForTree, setFilesForTree] = useState<any[]>([]);
  const [treeModel, setTreeModel] = useState<FileTreeModel | null>(null);
  const updateTimeout = useRef<null | NodeJS.Timeout>(null);

  const currentPlan = useCurrentPlan();
  const fileSizeLimit = TierUnion.isFree(currentPlan) ? 1 : 20;
  const acceptedFileExtensions = ['pcd', 'bin', 'png', 'jpg', 'json'];

  const handleClickReplaceFiles = (uniqFiles: any[]) => () => {
    setFiles(uniqFiles);
  };

  const checkDuplicatedFiles = (acceptedFiles: any[], uniqFiles: any[]) => {
    const sameFilesCount = acceptedFiles.length + files.length - uniqFiles.length;

    if (sameFilesCount > 0) {
      openModal({
        variant: 'warning',
        content: (
          <Typography variant="body3">
            Items with the same name already exist. <br />
            Would you like to replace them?
          </Typography>
        ),
        title: 'Already Exists',
        mainButton: {
          text: 'Replace',
          onClick: handleClickReplaceFiles(uniqFiles),
        },
        subButton: {
          text: 'Cancel',
          onClick: () => closeModal(),
        },
      });
    }
  };

  const sortComparator = getFileNameSortComparator();

  const getPointcloudAssetList = async (uniqFiles: FileWithPath[]) => {
    const manifestFiles = uniqFiles.filter(file => file.type === 'application/json');
    const assets: Record<string, FileWithPath[]> = {};
    for (const manifestFile of manifestFiles) {
      if (!manifestFile.path) throw new Error('?');
      const manifest = JSON.parse(await manifestFile.text()) as PointcloudManifest;
      const root = path.dirname(manifestFile.path);
      const key = manifest.key;
      const prefix = manifest.manifest.prefix;
      const sequenceFiles = [manifestFile];
      for (const frameInfo of manifest.manifest.frames) {
        const framePath = frameInfo['frame_path'];
        const targetFramePath = path.join(root, prefix, framePath);
        const frameFile = uniqFiles.find(file => targetFramePath === file.path);
        if (!frameFile) throw new Error('no pcd file');
        sequenceFiles.push(frameFile);
        for (const imageInfo of frameInfo['images']) {
          const imagePath = imageInfo['image_path'];
          const targetImagePath = path.join(root, prefix, imagePath);
          const imageFile = uniqFiles.find(file => targetImagePath === file.path);
          if (!imageFile) throw new Error('no image file');
          sequenceFiles.push(imageFile);
        }
      }
      assets[key] = sequenceFiles;
    }
    return assets;
  };

  const handleAddFiles = async (
    origAcceptedFiles: FileWithPath[],
    rejectedFiles: FileRejection[],
  ) => {
    const acceptedFiles = origAcceptedFiles
      // Filter again (necessary for folder selection which might have files such as .DS_Store)
      .filter(file => acceptedFileExtensions.indexOf(file.name.split('.').pop()!) !== -1)
      // Sort to match display in tree
      .sort((a, b) => sortComparator(a.path || a.name, b.path || b.name));
    const uniqFiles = intersectionBy([...files, ...acceptedFiles], 'path');

    if (!isEmpty(rejectedFiles)) {
      enqueueSnackbar(FILE_UPLOAD_TYPE_NOT_SUPPORTED({ t }), { variant: 'error' });
      return;
    }
    const assets = await getPointcloudAssetList(uniqFiles);
    let sequenceError: any = [];
    Object.values(assets).forEach(files => {
      if (files.length <= 1) {
        sequenceError = sequenceError.concat(['1']);
      }
    });

    if (sequenceError.length > 0) {
      enqueueSnackbar('For pointcloud sequence upload, files need to be contained in a folder.', {
        variant: 'error',
      });
      return;
    }

    if (!isEmpty(files)) {
      checkDuplicatedFiles(acceptedFiles, uniqFiles);
    }

    if (dataCapacity - acceptedFiles.length < 0) {
      openModal({
        variant: 'warning',
        content: (
          <>
            <Typography variant="body3">
              You’ve reached your usage limit. <br />
              Please upgrade your plan to continue using Suite.
            </Typography>
            <Link
              variant="body3"
              underlined
              themedColor="secondary"
              onClick={() => window.open(`//${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`)}
            >
              Learn about our pricing plans here.
            </Link>
          </>
        ),
        title: 'Upgrade Plan',
        mainButton: {
          text: 'UPGRADE',
          onClick: () => {
            routeInfo.history.push(`/${routeInfo.urlMatchInfo.accountName}/plan`);
            closeModal();
          },
        },
        subButton: {
          text: 'Close',
          onClick: () => closeModal(),
        },
      } as AlertModalProps);
    }

    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }

    updateTimeout.current = setTimeout(() => {
      setFiles(uniqFiles);
      setFilesForTree(uniqFiles);
    }, 50);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleAddFiles,
    accept: {},
    useFsAccessApi: false, // See https://github.com/react-dropzone/react-dropzone/issues/1245
  });

  useEffect(() => {
    if (files?.length === 0 && filesForTree?.length > 0) {
      // Reflect state change in tree to display initial message
      setFilesForTree([]);
    }
    // eslint-disable-next-line
  }, [files]);

  const handleTreeDelete = (path: string) => {
    // Reflect deletion from tree in context
    // This does not reload the tree
    const newFiles = files.filter(file => {
      if (!file.path) return true;
      // When selecting (and removing) folders, file paths are absolute
      const absPathMatch = file.path.startsWith(`${path}/`) || file.path === path;
      // When selecting (and removing) single files, their paths are relative
      const relPathMatch = file.path[0] !== '/' && file.path === path.slice(1);
      return !(absPathMatch || relPathMatch);
    });

    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }
    updateTimeout.current = setTimeout(() => {
      setFiles(newFiles);
    }, 150);
  };

  useEffect(() => {
    if (!filesForTree.length) return;
    const fileTree = fileListToTree(filesForTree);
    const getItems = async (path: string): Promise<IItem[]> => {
      if (!fileTree) return [];
      const rootNode = fileTree.find(node => node.name === '');
      if (!rootNode) return [];
      const node = searchTree(rootNode, path.replace(/\/$/, '').replace(/^\//, '').split('/'));
      if (!node) return [];
      return node.children.map(
        (node): IItem => ({
          name: node.name,
          type: node.children.length ? FileType.Directory : FileType.File,
          metadata: {
            size: node.file?.size,
          },
        }),
      );
    };
    setTreeModel(getTreeModel(getItems));
    // eslint-disable-next-line
  }, [filesForTree]);

  function getPromptText() {
    return 'Click Here or Drag & Drop folder(s) containing pointcloud, image sequences, and manifest';
  }

  // For video, only allow selecting directories and not files
  const additionalInputProps = { directory: '', webkitdirectory: '' } as any;

  return (
    <>
      <Box width={600} height={270} border="1px dashed #ddd">
        {filesForTree.length === 0 ? (
          <Box
            {...(getRootProps() as Partial<React.ComponentProps<typeof Box>>)}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            themedBackgroundColor={['grey', 50]}
            className={cn('clickable', classes.dropzone, { isDragActive })}
          >
            <input {...getInputProps(additionalInputProps)} />
            <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
              <Icon name="cloudUp" size="62px" color={['grey', 500]} />
            </Box>
            <Typography variant="body4" themedColor={['grey', 500]}>
              {getPromptText()}
            </Typography>
            <Typography variant="body4" themedColor="secondary">
              Support PCD, BIN pointcloud files, and JPG, PNG, BMP image files under {fileSizeLimit}
              MB
            </Typography>
          </Box>
        ) : (
          <Box
            {...({ ...getRootProps(), onFocus: undefined, onClick: undefined } as any)}
            className={cn(classes.dropzone, { isDragActive })}
            p={1}
          >
            <Box
              p={1}
              width="100%"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="1px dashed #ddd"
              themedBackgroundColor={['grey', 50]}
              onClick={open}
              className="clickable"
            >
              <Box display="flex" alignItems="center" mr={1}>
                <Icon name="cloudUp" color="secondary" />
              </Box>
              <Typography variant="body3" themedColor="secondary">
                Upload more files
              </Typography>
            </Box>
            <input {...getInputProps(additionalInputProps)} />
            {treeModel && (
              <FileTree
                height={222}
                width={582}
                model={treeModel}
                onDelete={handleTreeDelete}
                expandAll
                deletableTypes={[FileType.File, FileType.Directory]}
                selectableTypes={[]}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DragAndDropPointCloud;
