import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trash } from '@superb-ai/icons';
import { Box, IconButton, ToggleButton, Typography, useDialogState } from '@superb-ai/ui';
import { format } from 'date-fns';

import {
  useDeleteIpWhitelistByIdMutation,
  useUpdateIpWhitelistByIdMutation,
} from '../../../../queries/useIpWhitelistQuery';
import { GetIpWhitelistResponse, IpWhitelistItem } from '../../../../services/IpWhitelistService';
import IpWhitelistActivateDialog from './IpWhitelistActivateDialog';
import IpWhitelistAdder from './IpWhitelistAdder';
import IpWhitelistRemoveDialog from './IpWhitelistRemoveDialog';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type RowData = {
  id: string;
  ip: string;
  description: string | null;
  createdAt: string;
  status: 'Active' | 'Inactive';
};

function createData(obj: IpWhitelistItem): RowData {
  return {
    id: obj.id,
    ip: obj.ip,
    description: obj.description,
    createdAt: format(new Date(obj.createdAt), 'MMM dd, yyyy, HH:mm:ss'),
    status: obj.status,
  };
}

//https://mui.com/material-ui/react-table/
export default function IpWhitelistTable({
  data,
  ipFilter,
  isLoading,
}: {
  data?: {
    pages: GetIpWhitelistResponse[];
  };
  ipFilter: string;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const deactiveDialogState = useDialogState();
  const removeDialogState = useDialogState();

  const { mutate: updateIpWhitelistById } = useUpdateIpWhitelistByIdMutation();
  const { mutate: deleteIpWhitelistById } = useDeleteIpWhitelistByIdMutation();

  const [rowsData, setRowsData] = useState<{
    [key: string]: RowData;
  }>({});
  const [rowsInteraction, setRowsInteraction] = useState<string[]>([]);

  useEffect(() => {
    const nextRowsData: { [key: string]: RowData } = {};
    data?.pages.map(page => {
      page.data.data.map(obj => {
        if (ipFilter !== '' && !obj.ip.startsWith(ipFilter)) {
        } else {
          const rowData = createData(obj);
          nextRowsData[obj.id] = rowData;
        }
      });
    });
    setRowsData(nextRowsData);
  }, [data, ipFilter]);

  return (
    <>
      <TableContainer>
        <Table aria-label="ip-whitelist-table" size="small" stickyHeader>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              borderBottom: '1px solid #E0E0E0',
              boxShadow: 'none',
            }}
          >
            <TableRow>
              <TableCell sx={{ width: '200px' }}>
                <Box display="flex" alignItems="center">
                  <Typography variant="m-medium" color="gray-400" ml={0.5}>
                    {t('settings.security.ipWhitelistTableIpColumn')}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" sx={{ minWidth: '400px' }}>
                <Typography variant="m-medium" color="gray-400">
                  {t('settings.security.ipWhitelistTableDescriptionColumn')}
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ width: '240px' }}>
                {t('settings.security.ipWhitelistTableCreatedAtColumn')}
              </TableCell>
              <TableCell align="center" sx={{ width: '120px' }}>
                {t('settings.security.ipWhitelistTableEnabledColumn')}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: '60px',
                }}
              ></TableCell>
            </TableRow>
            <IpWhitelistAdder />
          </TableHead>
          <TableBody>
            {Object.values(rowsData).map((row, index) => (
              <StyledTableRow hover key={index}>
                <TableCell component="th" scope="row">
                  <Box display="flex" alignItems="center">
                    <Typography variant="m-medium" color="gray-400" ml={0.5}>
                      {row.ip}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">{row.createdAt}</TableCell>
                <TableCell align="center">
                  <ToggleButton
                    size="s"
                    checked={row.status === 'Active'}
                    onChange={e => {
                      if (e.target.checked) {
                        updateIpWhitelistById({ id: row.id, status: 'Active' });
                      } else {
                        setRowsInteraction([row.id]);
                        deactiveDialogState.show();
                      }
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    variant="text"
                    size="m"
                    icon={Trash}
                    onClick={() => {
                      setRowsInteraction([row.id]);
                      removeDialogState.show();
                    }}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <IpWhitelistActivateDialog
        dialogState={deactiveDialogState}
        deactivate={() => {
          if (rowsInteraction.length !== 1) {
            return;
          }
          updateIpWhitelistById({ id: rowsInteraction[0], status: 'Inactive' });
          setRowsInteraction([]);
        }}
        cancel={() => {
          setRowsInteraction([]);
        }}
      />
      <IpWhitelistRemoveDialog
        dialogState={removeDialogState}
        number={rowsInteraction.length}
        remove={() => {
          rowsInteraction.map(id => {
            deleteIpWhitelistById({ id });
          });
          setRowsInteraction([]);
        }}
        cancel={() => {
          setRowsInteraction([]);
        }}
      />
    </>
  );
}
