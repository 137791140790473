import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { DropdownList } from '@superb-ai/norwegian-forest';
import { Box, LoadingIndicator, Typography } from '@superb-ai/ui';

import { useTenantUserApiKeysQuery } from '../../../../../queries/userAccessKey';
import { Option } from '../../../../../types/selectTypes';
import UserListDropdown from '../../../../elements/UserListDropdown';
import PersonalAccessKeyConfirmDialog from '../PersonalAccessKey/PersonalAccessKeyConfirmDialog';
import PersonalAccessKeyDialog from '../PersonalAccessKey/PersonalAccessKeyDialog';
import ManageMembersTable from './ManageMembersTable';

const StatusOptions = [
  { value: '', label: 'All Access Keys' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
] as const;

type StatusOption = (typeof StatusOptions)[number]['value'];

function findDataInPages<T extends { apiKeyId: string }>(pages: { objects: T[] }[], id: string) {
  for (const page of pages) {
    for (const key of page.objects) {
      if (key.apiKeyId === id) return key;
    }
  }
}

export default function Layout() {
  const { t } = useTranslation();
  const [editDialogId, setEditDialogId] = useState<string | null>(null);
  const [confirmDialogId, setConfirmDialogId] = useState<string | null>(null);
  const [userFilter, setUserFilter] = useState<Option<string> | null>(null);
  const [statusFilter, setStatusFilter] = useState<StatusOption>('');

  const { data, isLoading, hasNextPage, fetchNextPage } = useTenantUserApiKeysQuery({
    status: statusFilter ? statusFilter : undefined,
    email: userFilter ? userFilter.value : undefined,
    orderBy: '-created_at',
  });

  const editDialogData =
    data?.pages && editDialogId ? findDataInPages(data?.pages, editDialogId) : undefined;
  const confirmDialogData =
    data?.pages && confirmDialogId ? findDataInPages(data?.pages, confirmDialogId) : undefined;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={1} mb={3} alignItems="center">
        <Box style={{ width: 300 }}>
          <UserListDropdown
            placeholder={t('settings.advanced.filterByUser')}
            value={userFilter}
            onChange={option => setUserFilter(option)}
          />
        </Box>
        <Box display="flex" style={{ width: 140 }}>
          <DropdownList
            value={statusFilter}
            options={StatusOptions as unknown as Option<string>[]}
            onChange={value => setStatusFilter(value as StatusOption)}
            color="grey"
            variant="soft-fill"
            placeholder={t('users.filter.filterByStatus')}
          />
        </Box>
        {isLoading && <LoadingIndicator size="xsmall" />}
      </Box>

      <InfiniteScroll
        hasMore={hasNextPage}
        loadMore={() => fetchNextPage()}
        initialLoad={false}
        loader={<LoadingIndicator />}
      >
        <ManageMembersTable
          data={data}
          openConfirmDialog={id => setConfirmDialogId(id)}
          openEditDialog={id => setEditDialogId(id)}
        />
      </InfiniteScroll>

      {!isLoading && !data?.pages?.[0]?.objects?.length && (
        <Box p={3}>
          <Typography variant="m-regular" color="cloud">
            {t('settings.advanced.noAccessKeysFound')}
          </Typography>
        </Box>
      )}

      <PersonalAccessKeyDialog
        type="edit"
        data={editDialogData}
        isOpen={editDialogId !== null}
        handleClose={() => setEditDialogId(null)}
        context="Manage Members"
      />
      <PersonalAccessKeyConfirmDialog
        closeConfirmDialog={() => setConfirmDialogId(null)}
        data={confirmDialogData}
        context="Manage Members"
      />
    </Box>
  );
}
