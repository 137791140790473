import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Shield } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { TotpAssociateDialog } from '../../../elements/auth/TotpAssociateDialog/TotpAssociateDialog';

export default function MfaUser({
  disabled,
  mfaUserOn,
  onChangeMfaUserOn,
}: {
  disabled: boolean;
  mfaUserOn: boolean;
  onChangeMfaUserOn: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useAlertModal();
  const [dialogOpen, setDialogOpen] = useState(false);

  function turnOn() {
    setDialogOpen(true);
  }

  function turnOff() {
    openModal({
      title: t('settings.security.mfaDeactivateDialog.title'),
      content: t('settings.security.mfaDeactivateDialog.text'),
      mainButton: {
        text: t('settings.security.deactivateMfa'),
        onClick: () => {
          closeModal();
          onChangeMfaUserOn(false);
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: closeModal,
      },
    });
  }
  function handleButtonClick() {
    if (mfaUserOn) turnOff();
    else turnOn();
  }

  function onSuccess() {
    onChangeMfaUserOn(true);
    enqueueSnackbar(t('settings.security.mfaEnableSuccess'), {
      variant: 'success',
    });
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={4}
        gap={2}
        style={{ opacity: !disabled ? 1.0 : 0.25 }}
      >
        <Box
          backgroundColor="gray-100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '100%',
          }}
        >
          <Icon icon={Shield} size="16px" />
        </Box>
        <Typography variant="m-strong">
          {!mfaUserOn ? t('settings.security.mfaIsOff') : t('settings.security.mfaIsOn')}
        </Typography>
        <Button size="m" color="primary" variant="strong-fill" onClick={handleButtonClick}>
          {!mfaUserOn ? t('settings.security.setupMfa') : t('settings.security.deactivateMfa')}
        </Button>
      </Box>
      <TotpAssociateDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSuccess={onSuccess}
      />
    </>
  );
}
