import React, { CSSProperties, useEffect, useRef } from 'react';

import * as d3 from 'd3';

import { DARK_GRAY } from '../../../../consts/ColorChips';
import { ExtendedScaleBand } from '../interfaces/d3Types';
import { getDisplayNameFromKey, shortenAndCapitalize } from '../tools/helper';

interface XAxisProps {
  bottom: number; // bottom margin in pixels
  left: number; // left margin in pixels
  width: number;
  height: number;
  scale: ExtendedScaleBand;
  scaleType?: string;
  // hoveredIndex?: number; // not used - use this to highlight x-axis label of selected bar
  xLength: number; // number of variables in X axis (used to decide axis display)
  xDisplayName?: string; // Display name for X Label
  xKeyToDisplayName?: Record<string, string>; // maps x keys to their display names
  xMaxLength?: number;
  labelFontWeight?: 'normal' | 'bold';
  rotateXLabel?: boolean;
  labelFontSize?: string;
  labelXOffset?: number;
  labelYOffset?: number;
  textFontSize?: string;
  tickValues?: number[];
}

/**
 * xKeyToDisplayName exists only for user label counts chart, where we map
 * user email to full name for display (ex. {'ml@ai.com': 'Moon Limb', 'seohee@ai.com': 'Seohee Ko'})
 */
const XAxis: React.FC<XAxisProps> = props => {
  const {
    bottom,
    left,
    width,
    height,
    scale,
    scaleType,
    xLength,
    xDisplayName,
    xKeyToDisplayName,
    xMaxLength,
    rotateXLabel,
    labelFontWeight,
    labelFontSize,
    labelXOffset,
    labelYOffset,
    textFontSize,
    tickValues,
  } = props;
  const axis = useRef(null);

  useEffect(() => {
    /** Set xAxis */
    let xAxis;
    if (scaleType === 'LINEAR') {
      if (tickValues) {
        xAxis = d3.axisBottom(scale).tickValues(tickValues).tickSize(0);
      } else {
        xAxis = d3.axisBottom(scale).tickSize(0);
      }
    } else {
      xAxis = d3
        .axisBottom(scale)
        .tickSize(0)
        .ticks(10)
        // @ts-ignore this works
        .tickFormat(x => shortenAndCapitalize(getDisplayNameFromKey(x, xKeyToDisplayName), 19));
    }

    d3.select(axis.current)
      // @ts-ignore this works
      .call(xAxis)
      .call(g => g.selectAll('path').attr('stroke', 'lightgray'))
      .attr('stroke-width', '2px')
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke', DARK_GRAY.color)
          .attr('stroke-width', 1)
          .attr('stroke-opacity', 0.5),
      )
      .call(g => {
        if (
          rotateXLabel ||
          (xLength >= 10 && scale.scaleType === 'BAND') ||
          (xLength >= 7 && xMaxLength && xMaxLength > 10)
        ) {
          g.selectAll('text')
            .style('text-anchor', 'start')
            .attr('dx', '.35em')
            .attr('dy', '.55em')
            .attr('transform', 'rotate(35)');
        } else {
          g.selectAll('text').style('text-anchor', 'middle').attr('dy', '1.2em');
        }
      });
  });

  const textStyle: CSSProperties = {
    fontSize: textFontSize || '12px',
    fontFamily: 'Inter',
    textAnchor: 'start',
    color: DARK_GRAY.color,
  };

  const labelStyle = {
    fontFamily: 'Inter; sans-serif',
    fontSize: labelFontSize || '12px',
    fontWeight: labelFontWeight || 'bold',
    textAnchor: 'middle',
    color: DARK_GRAY.color,
  } as React.CSSProperties;

  /** Set X-Axis label location here (Quality Assurance > Histogram chart only) */
  /** In the middle of x-axis */
  // const centeredXLabelLoc = width / 2;

  /** Close to x-axis right edge */
  const xLabelLoc = labelXOffset === undefined ? width - 90 : width - labelXOffset;
  const yLabelLoc = labelYOffset || 37;

  return (
    <g transform={`translate(${left}, ${height - bottom + 0.5})`}>
      <g className="axis-x" style={textStyle} ref={axis} />
      {xDisplayName ? (
        <g>
          <text style={labelStyle} transform={`translate(${xLabelLoc},${yLabelLoc})`}>
            {xDisplayName}
          </text>
        </g>
      ) : null}
    </g>
  );
};

export default XAxis;
