import { useLocation } from 'react-router-dom';

function useSearchParams() {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const hasSearchParam = (paramName: string) => {
    return urlSearchParams.get(paramName);
  };

  const getSearchParam = (paramName: string, defaultValue: string) => {
    const params = urlSearchParams;
    return params.get(paramName) || defaultValue;
  };

  return { urlSearchParams, getSearchParam, hasSearchParam };
}

function useDiagnosisViewSearchParam(defaultView = 'analytics') {
  const { urlSearchParams, getSearchParam } = useSearchParams();
  return {
    searchParams: Object.fromEntries(urlSearchParams),
    getSearchParam,
    view: getSearchParam('view', defaultView),
  };
}

export default useDiagnosisViewSearchParam;
