import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Chip, Icon, Modal, Typography } from '@superb-ai/norwegian-forest';
import { format } from 'date-fns';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import ExportService from '../../../../../services/ExportService';
import MislabelDetectionService from '../../../../../services/MislabelDetectionService';
import { HistoryType } from '../../../../../types/exportTypes';
import ExportList from './ExportList';
import MislabelDetectionNameInput from './MislabelDetectionNameInput';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  loadList: () => void;
};

export default function MislabelDetectionModal({ isOpen, setIsOpen, loadList }: Props) {
  const authInfo = useAuthInfo();
  const urlParams = useParams<{ accountName: string; projectId: string }>();
  const defaultName = `Mislabel Detection_${format(new Date(), 'yyyy-MM-dd H:mm:ss')}`;

  const [name, setName] = useState(defaultName);
  const [querySetId, setQuerySetId] = useState('');
  const [referenceSetId, setReferenceSetId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [histories, setHistories] = useState<HistoryType[]>([]);

  const loadHistories = async () => {
    const histories = [];
    let page = 0;
    let count = 0;
    const pageSize = 50;

    do {
      const res = await ExportService.getExportHistories({
        projectId: urlParams.projectId,
        params: { pageSize },
        isGuest: authInfo.isGuest,
        urlInfo: { accountName: urlParams.accountName, projectId: urlParams.projectId },
      });
      histories.push(...res.results);
      page += 1;
      count = res.count;
    } while (page * pageSize < count);

    setHistories(histories);
  };

  useEffect(() => {
    if (!isOpen) {
      setName(defaultName);
      setReferenceSetId('');
      setQuerySetId('');
      return;
    }

    (async () => {
      setIsLoading(true);
      await loadHistories();
      setIsLoading(false);
    })();
  }, [isOpen]);

  const handleClose = () => {
    //initialize
    setIsOpen(false);
  };

  const handleClickRun = async () => {
    await MislabelDetectionService.requestMislabelDetection({
      projectId: urlParams.projectId,
      data: {
        name,
        querySetId,
        referenceSetId,
      },
      isGuest: authInfo.isGuest,
      urlInfo: { accountName: urlParams.accountName, projectId: urlParams.projectId },
    });
    setIsOpen(false);
    await loadList();
  };

  return (
    <Modal
      open={isOpen}
      close={{
        onClose: handleClose,
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={
        <Typography themedColor="primary" variant="headline4">
          Mislabel Detection
        </Typography>
      }
      mainButton={{
        text: 'Run',
        onClick: handleClickRun,
        disabled: querySetId === '' && referenceSetId === '',
        // isLoading: isRequesting,
      }}
      subButton={{
        text: 'Cancel',
        onClick: handleClose,
      }}
    >
      <Box width="80vw" maxWidth="1800px" minWidth="1000px" height="608px" py={2} px={4}>
        <MislabelDetectionNameInput name={name} setName={setName} />
        {isLoading ? (
          <Icon name="loadingSpinner" size="20px" />
        ) : (
          <Box mt={2} display="flex" gap="16px">
            <Box flex={1} display="flex" flexDirection="column" gap="4px" height="540px">
              <Box>
                <Chip color="cloud">Step1</Chip>
              </Box>
              {isLoading ? (
                <Icon name="loadingSpinner" size="20px" />
              ) : (
                <ExportList
                  title="Select a query set to apply mislabel detection to."
                  items={histories}
                  selectedItemId={querySetId}
                  setSelectedItemId={setQuerySetId}
                />
              )}
            </Box>
            <Box flex={1} display="flex" flexDirection="column" gap="4px" height="540px">
              <Box>
                <Chip color="cloud">Step2</Chip>
              </Box>
              <ExportList
                title="Select a reference set to use for mislabel detection."
                items={histories}
                selectedItemId={referenceSetId}
                setSelectedItemId={setReferenceSetId}
                disabledItemId={querySetId}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
