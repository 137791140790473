import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { GenerateImage } from '@superb-ai/icons';
import { Dialog, IconButton, LinkTypography, Tooltip, useDialogState } from '@superb-ai/ui';
import qs from 'qs';

import { hasSufficientCurateVolume } from '../../../../../../queries/meteringLogic';
import { useMetering } from '../../../../../../queries/useMeteringQuery';
import { getUrl } from '../../../../../../routes/util';
import { openChatWidget } from '../../../../../../utils/chatWidget';
import { useModelListQuery } from '../../../../../Model/queries/modelQueries';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useEmbeddingMonitoringContext } from '../../../../contexts/EmbeddingMonitoringContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { SampleDatasetTooltip } from '../../../../elements/SampleDatasetTooltip';
import CreateSliceModal from '../modal/CreateSliceModal';
import GenerateSyntheticImagesModal from '../modal/GenerateSyntheticImagesModal';

export default function GenerateImagesButton({ sliceName }: { sliceName?: string }) {
  const { t } = useTranslation();
  const dialog = useDialogState();
  const { datasetId, accountName } = useParams<{ datasetId: string; accountName: string }>();

  const history = useHistory();

  const { showPublicDatasets } = usePublicDatasetContext();
  const { selectedData, setSelectedData, setSelectedAllData } = useActionContext();
  const { sliceInfo } = useSliceContext();

  const meteringDataVolume = useMetering('curate:data-volume');

  const isCurateVolumeSufficient = hasSufficientCurateVolume(meteringDataVolume, {
    minimum: 10,
  });

  const [createSliceModalIsOpen, setCreateSliceModalIsOpen] = useState<boolean>(false);

  const { objectTotalCount } = useEmbeddingMonitoringContext();
  const hasLabeledData = objectTotalCount > 0;

  const { data: modelListData } = useModelListQuery({
    params: {
      status: ['trained'],
      referenceId: [datasetId],
      modelPurpose: 'generation',
      size: 0,
    },
    enabled: !showPublicDatasets && hasLabeledData,
  });
  const hasGenModelList = (modelListData?.pages[0].modelList || []).length > 0;

  function TooltipMessage() {
    if (showPublicDatasets) {
      return (
        <>
          {t('curate.datasets.generateSyntheticImages.modalTitle')}
          <br />
          <SampleDatasetTooltip feature="generateImages" />
        </>
      );
    }
    if (!hasLabeledData) {
      return (
        <span>
          {t('curate.datasets.generateSyntheticImages.modalTitle')}
          <br />
          <Trans t={t} i18nKey="curate.datasets.generateSyntheticImages.buttonNoObjectTooltip" />
        </span>
      );
    }
    if (!hasGenModelList) {
      return (
        <span>
          {t('curate.datasets.generateSyntheticImages.modalTitle')}
          <br />
          <Trans
            t={t}
            i18nKey="curate.datasets.generateSyntheticImages.buttonNoGenAITooltip"
            components={{
              link1: (
                <LinkTypography
                  onClick={() => {
                    history.push(
                      `${getUrl([accountName, 'model', 'train'])}?${qs.stringify({
                        from: 'generation',
                        dataset: datasetId,
                        slice: sliceInfo?.name,
                      })}`,
                    );
                  }}
                />
              ),
            }}
          />
        </span>
      );
    }
    if (!isCurateVolumeSufficient) {
      return (
        <span>
          {t('curate.datasets.generateSyntheticImages.modalTitle')}
          <br />
          <Trans
            t={t}
            i18nKey="labels.sendToCurate.volumeWarning"
            values={{ leftQuantity: meteringDataVolume.leftQuantity }}
          />
          <LinkTypography onClick={() => openChatWidget()}> {t('text.contactUs')}</LinkTypography>
        </span>
      );
    }
    if (selectedData.length > 100) {
      return (
        <span>
          {t('curate.datasets.generateSyntheticImages.modalTitle')}
          <br />
          <Trans
            t={t}
            i18nKey="curate.datasets.generateSyntheticImages.buttonSelectedOverLimitTooltip"
            values={{ leftQuantity: meteringDataVolume.leftQuantity }}
            components={{
              link1: <LinkTypography onClick={() => setCreateSliceModalIsOpen(true)} />,
              link2: (
                <LinkTypography
                  onClick={() => {
                    setSelectedData([]);
                    setSelectedAllData(true);
                  }}
                />
              ),
            }}
          />
          <CreateSliceModal
            createSliceModalIsOpen={createSliceModalIsOpen}
            setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
          />
        </span>
      );
    }
    return t('curate.datasets.generateSyntheticImages.modalTitle');
  }
  // TODO: Check model ai credit
  return (
    <>
      <Dialog.Disclosure {...dialog}>
        {(disclosureProps: any) => (
          <Tooltip content={TooltipMessage()} hideOnEmptyContent placement="top">
            <span>
              <IconButton
                variant="text"
                onClick={() => {
                  dialog.show();
                }}
                icon={GenerateImage}
                disabled={
                  !isCurateVolumeSufficient ||
                  showPublicDatasets ||
                  selectedData.length > 100 ||
                  !hasGenModelList ||
                  !hasLabeledData
                }
              />
            </span>
          </Tooltip>
        )}
      </Dialog.Disclosure>
      <GenerateSyntheticImagesModal dialog={dialog} />
    </>
  );
}
