import React, { ReactElement, useRef } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Clear, Minus } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import CategoryItemDropBar from './CategoryItemDropBar';
import helper from './helper';

const useStyles = makeStyles(() => ({
  outerBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 6px 4px 14px',
    margin: '6px',
    borderRadius: '6px',
    background: '#fff',
    boxSizing: 'border-box',
    border: 'solid 1px #fff',
    '&.selected': {
      border: 'solid 1px #ff625a',
    },
    cursor: 'pointer',
  },
  nameText: {
    fontSize: '11px',
    color: '#202020',
    marginRight: '6px',
  },
  dropBox: {
    width: '6px',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '6px',
    '&.left': {
      left: -10,
    },
    '&.next': {
      right: -10,
    },
    '&.entered': {
      background: '#f8f8f8',
    },
  },
}));

interface Props {
  index: number;
  category: any;
  imageCategoryMap: any;
}

const CategoryItem = ({ index, category, imageCategoryMap }: Props): ReactElement => {
  const categoryRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const isSelected = newProjectInfo.selectedCategories[category.id];

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    let nextSelectedCategories: { [id: string]: number } = {};

    if (e.metaKey || e.ctrlKey) {
      nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);

      if (newProjectInfo.selectedCategories[category.id]) {
        delete nextSelectedCategories[category.id];
      } else {
        nextSelectedCategories[category.id] = new Date().getTime();
      }
    } else if (e.shiftKey) {
      nextSelectedCategories = cloneDeep(newProjectInfo.selectedCategories);
      const parent = imageCategoryMap[category.parent];
      const siblings = parent.children.filter((item: string) => !imageCategoryMap[item].children);

      let front = -1;
      let cur = -1;
      let rear = -1;
      for (let i = 0; i < siblings.length; i++) {
        if (siblings[i] === category.id) {
          cur = i;
        } else if (nextSelectedCategories[siblings[i]]) {
          if (cur === -1) {
            front = i;
          } else {
            rear = i;
            break;
          }
        }
      }

      front = front !== -1 ? front : cur;
      rear = rear !== -1 ? rear : cur;
      for (let i = front; i <= rear; i++) {
        nextSelectedCategories[siblings[i]] = new Date().getTime();
      }

      // const siblings = imageCategoryMap[category.parent]
      // return;
    } else if (newProjectInfo.selectedCategories[category.id]) {
      return;
    } else {
      nextSelectedCategories[category.id] = new Date().getTime();
    }
    newProjectInfo.setSelectedCategories(nextSelectedCategories);

    if (newProjectInfo.selectedCategoryGroupId) {
      newProjectInfo.setSelectedCategoryGroupId('');
    }
  };

  const handleClickDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    helper.deleteCategory(newProjectInfo, [category.id]);
  };

  const handleClickRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    helper.moveCategoryToGroup(newProjectInfo, [category.id], 'root', null);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setTimeout(() => {
      if (!categoryRef || !categoryRef.current) return;

      newProjectInfo.setDragState({ type: 'imageCategory', id: category.id });
      categoryRef.current.style.cursor = 'grabbing';
      if (!e?.dataTransfer) return;
      e.dataTransfer.setData('text', category.id);
      e.dataTransfer.setDragImage(categoryRef.current, -10, -10);
    }, 0);
  };

  const handleDragEnd = () => {
    newProjectInfo.setDragState(null);
    if (!categoryRef || !categoryRef.current) return;
    categoryRef.current.style.cursor = 'pointer';
  };

  return (
    <MUI.Box className={classes.outerBox}>
      {index === 0 ? (
        <CategoryItemDropBar dir="prev" id={category.id} imageCategoryMap={imageCategoryMap} />
      ) : null}
      <MUI.Box
        {...{ ref: categoryRef }}
        className={`${classes.box} category ${isSelected ? 'selected' : ''}`}
        boxShadow={1}
        onMouseDown={handleMouseDown}
        draggable={!!isSelected}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <MUI.Typography className={classes.nameText}>{category.name}</MUI.Typography>

        {category.parent === 'root' ? (
          <IconButton
            icon={Clear}
            size="s"
            onMouseDown={e => e.stopPropagation()}
            onClick={handleClickDelete}
            variant="text"
          />
        ) : (
          <IconButton
            icon={Minus}
            size="s"
            onMouseDown={e => e.stopPropagation()}
            onClick={handleClickRemove}
            color="primary"
            variant="text"
          />
        )}
      </MUI.Box>
      <CategoryItemDropBar dir="next" id={category.id} imageCategoryMap={imageCategoryMap} />
    </MUI.Box>
  );
};

export default CategoryItem;
