import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { TableSortLabelProps } from '../../types/muiTypes';

const useStyles = makeStyles({
  box: {
    '& .MuiTableSortLabel-icon': {
      position: 'absolute',
      right: '-14px',
    },
  },
});

function next(isDesc: boolean) {
  return isDesc ? 'asc' : 'desc';
}

/**
 *
 * @param {string} children Table column display name
 * @param {string} property Unique column identifier (usually lowercase column name)
 * @param {boolean} isDesc Current sort order. 'desc' or 'asc'
 * @param {string} orderBy Column/property by which to order data.
 * @param {string} defaultOrder Default to 'desc' or 'asc' when sorting a new column. Defaults to 'desc'.
 * @param {fn} onRequestSort Handler for setting new sort order.
 */
const TableSortLabel: React.FC<TableSortLabelProps> = props => {
  const classes = useStyles();

  const { children, property, isDesc, orderBy, onRequestSort, defaultOrder = 'asc' } = props;
  const isActive = orderBy === property;

  const handleClickSortLabel = () => {
    const nextOrder = isActive ? next(isDesc) : defaultOrder;
    onRequestSort(property, nextOrder);
  };

  return (
    <MUI.TableSortLabel
      className={classes.box}
      active={isActive}
      direction={!isActive ? defaultOrder : isDesc ? 'desc' : 'asc'}
      onClick={handleClickSortLabel}
    >
      {children}
    </MUI.TableSortLabel>
  );
};

export default TableSortLabel;
