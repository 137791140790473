import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

import { Display } from '../../../../types/displayTypes';
import TableSortLabel from '../../../elements/TableSortLabel';

type Props = {
  onClickGlobalCheckbox: () => void;
  isGlobalChecked: boolean;
  isDesc: boolean;
  orderBy: string;
  onRequestSort: (property: string, order: 'desc' | 'asc') => void;
  variant: Display;
};

const DataTableViewHead: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isDesc, orderBy, isGlobalChecked, onRequestSort, onClickGlobalCheckbox, variant } = props;

  let tableCell: ReactNode = null;
  if (variant === 'page') {
    tableCell = (
      <>
        <MUI.TableCell align="center">{t('labels.table.dataset')}</MUI.TableCell>
        <MUI.TableCell align="center">{t('data.table.projects')}</MUI.TableCell>
        <MUI.TableCell align="center">{t('data.table.uploader')}</MUI.TableCell>
      </>
    );
  } else if (variant === 'modal') {
    tableCell = null;
  } else {
    throw new Error('unhandled variant ');
  }

  return (
    <>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell align="center" padding="checkbox">
            <MUI.Checkbox
              color="primary"
              onClick={onClickGlobalCheckbox}
              checked={isGlobalChecked}
            />
          </MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="key"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('labels.table.dataKey')}
            </TableSortLabel>
          </MUI.TableCell>
          {tableCell}
          <MUI.TableCell align="center">
            <TableSortLabel
              property="created_at"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
              defaultOrder="desc"
            >
              {t('projects.table.createdDate')}
            </TableSortLabel>
          </MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableHead>
    </>
  );
};

export default DataTableViewHead;
