import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import FileService from '../../../services/FileService';
import FileUtils from '../../../utils/FileUtils';
import { useCurateCommandContext } from '../contexts/CommandContext';
import { useCurateCommandsService } from '../services/CommandsService';
import { CommandType, CurateJobParams, UpdateSliceJobParam } from '../types/commandTypes';

/**
 * Creating a Job in one mutation.
 * This calls getJobPresignedUrl(), uploads the data JSON, and then postJob().
 * When successful, adds the job to the CommandContext.
 */
export function useCurateCreateJobMutation() {
  const { getJobPresignedUrl, postJob } = useCurateCommandsService();
  const commandContext = useCurateCommandContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation({
    async mutationFn({ data, jobType }: { data: Record<string, any>; jobType: CommandType }) {
      const shouldUploadJson = jobType === 'UPDATE_SLICE';
      if (shouldUploadJson) {
        const ids = data.images.ids.map((list: string) => {
          return { id: list };
        });
        const newDataJson = [...ids];
        const jsonFile = JSON.stringify(newDataJson);
        const jsonFileSize = FileUtils.getJsonFileSize(jsonFile);
        const presignedUrlRes = await getJobPresignedUrl({ file_size: jsonFileSize });
        await FileService.uploadPresignedJSON({
          uploadUrl: presignedUrlRes.uploadUrl,
          jsonFile,
        });
        const result = await postJob({
          job_type: jobType,
          param: {
            ...data,
            images: { ...data.images, param_id: presignedUrlRes.id },
          } as UpdateSliceJobParam,
        });
        return result;
      } else {
        const result = await postJob({
          job_type: jobType,
          param: { ...data } as CurateJobParams<typeof jobType>,
        });
        return result;
      }
    },
    onSuccess(result, variables) {
      // This can be undefined in case we create a job outside of the Curate context
      commandContext?.registerCommand?.(result.id, { ...variables.data } as CurateJobParams<
        typeof result.jobType
      >);

      return result;
    },
    onError(error: { message: string }) {
      if (error.message === 'Duplicated') {
        enqueueSnackbar(t('curate.embeddings.button.updateDuplicatedMessage'), {
          variant: 'info',
        });
      }
    },
  });
}
