import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LinkTypography } from '@superb-ai/ui';

import { ColorBy, useColorModeContext } from '../../../../contexts/ColorModeContext';
import { useObjectClusterContext } from '../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { hexToRgba } from '../views/embedding/scatterView/utils/color';
import SearchableMultiSelectFilter, { IdToInfoMap } from './SearchableMultiSelectFilter';
import { getIsObjectClassOrClusterDisabled } from './utils';

// export type IdToInfoMap = Record<string, { color?: string; colorWithOpacity?: string }>;

export function mapClassToColors(
  items: string[],
  colorMap: Record<string, string>,
  colorBy: ColorBy,
) {
  if (!colorMap) return;
  return items.reduce((acc, item) => {
    const color = colorMap[item];
    acc[item] = {
      color: colorBy === 'class' ? hexToRgba(color, 1) : undefined,
      colorWithOpacity: colorBy === 'class' ? hexToRgba(color, 0.4) : undefined,
    };
    return acc;
  }, {} as IdToInfoMap);
}
export default function ObjectClassFilter() {
  const { t } = useTranslation();
  const {
    filterSchema,
    selectedClasses,
    setSelectedClasses,
    setSelectedSuperClusters,
    classNameColorMap,
    appliedFilters,
    setAppliedFilters,
  } = useObjectFilterContext();
  const { hoveredDotGroup, setHoveredDotGroup, hoveredFilterGroup, setHoveredFilterGroup } =
    useObjectClusterContext();
  const classSchema = filterSchema?.annotation_class_in;
  const { colorByMode } = useColorModeContext();
  const classToColorMap = mapClassToColors(classSchema ?? [], classNameColorMap, colorByMode);
  const getIsCheckboxDisabled = (annotationClass: string) =>
    getIsObjectClassOrClusterDisabled(appliedFilters, annotationClass);

  function getTooltipContent() {
    return (
      <Trans
        t={t}
        i18nKey="curate.annotationFilter.resetFilters"
        components={{
          link1: (
            <LinkTypography
              onClick={() => {
                setAppliedFilters({
                  ...appliedFilters,
                  annotation_class_in: [],
                  annotation_class_cluster_in: [],
                });
              }}
              variant="m-regular"
              color="white"
            />
          ),
        }}
      />
    );
  }

  return (
    <SearchableMultiSelectFilter
      displayedItems={classSchema}
      selectedItems={selectedClasses}
      onClickCheckbox={items => {
        setSelectedSuperClusters(undefined);
        setSelectedClasses(items);
      }}
      idToInfoMap={classToColorMap}
      hoveredDotGroup={hoveredDotGroup}
      setHoveredDotGroup={setHoveredDotGroup}
      hoveredFilterGroup={hoveredFilterGroup}
      setHoveredFilterGroup={setHoveredFilterGroup}
      getIsDisabled={getIsCheckboxDisabled}
      getTooltipContent={getTooltipContent}
    />
  );
}
