import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Box, Select } from '@superb-ai/ui';
import { orderBy } from 'lodash';

import { DiagnosisAnalyticsFilterContextProps } from '../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { searchResults } from '../../../filter/utils';
import DropdownSearchInput from '../filter/DropdownSearchInput';
import { DIAGNOSIS_DISPLAY_NULL_VALUE } from './transformer';

export type ClassItem = {
  label: string;
  value: string;
};
interface Props {
  classList: (string | null)[] | [];
  selectedClass: DiagnosisAnalyticsFilterContextProps['selectedClass'];
  setSelectedClass: DiagnosisAnalyticsFilterContextProps['setSelectedClass'];
}
export const getClassOptions = (classList: (string | null)[], t: TFunction): ClassItem[] => {
  let result: ClassItem[] = classList.reduce((acc, item) => {
    acc.push({
      label: item ?? DIAGNOSIS_DISPLAY_NULL_VALUE,
      value: item,
    });
    return acc;
  }, [] as ClassItem[]);
  result = orderBy(result, 'label', 'asc');
  result.unshift({ value: 'all', label: t('curate.diagnosis.text.allPredictedClass') });
  return result;
};

const SinglePredictionClassFilter = (props: Props) => {
  const { classList, selectedClass, setSelectedClass } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const filterClassList = searchResults([...classList], searchValue);
  const classOptions = getClassOptions(filterClassList, t);

  function handleChangeValue(value: string) {
    setSelectedClass(value);
  }

  return (
    <Box ml="auto" display="flex" style={{ width: '220px' }}>
      <Select
        multiple={false}
        prefix={
          <DropdownSearchInput
            placeholder={t('curate.diagnosis.filterPanel.searchPredictionClass')}
            setSearchValue={setSearchValue}
          />
        }
        placeholder={t('curate.diagnosis.filterPanel.selectPredictionClass')}
        data={classOptions}
        value={selectedClass ?? null}
        onChangeValue={handleChangeValue}
      />{' '}
    </Box>
  );
};

export default SinglePredictionClassFilter;
