import { cloneDeep, sortBy } from 'lodash';

import { IAdvancedQuery, IQuery } from '../../../../types/querySchemaTypes';

export const sortUpdateList = (advancedQuery: IAdvancedQuery) => {
  const totalList = [
    ...(cloneDeep(advancedQuery?.query) ?? []),
    ...(cloneDeep(advancedQuery?.children) ?? []),
  ];
  const sortedList = sortBy(totalList, 'boxIndex');
  const unsortedList = sortedList.filter((list, index) => list.boxIndex !== index);

  if (unsortedList.length > 0) {
    const boxIndex = unsortedList[0].boxIndex - 1;
    const sortedChildren = advancedQuery.children?.map(list =>
      list.boxIndex > boxIndex ? { ...list, boxIndex: list.boxIndex - 1 } : list,
    );
    const sortedQuery = advancedQuery.query?.map(list =>
      list.boxIndex > boxIndex ? { ...list, boxIndex: list.boxIndex - 1 } : list,
    );
    advancedQuery.query = [...(sortedQuery ?? [])];
    if (sortedChildren?.length) advancedQuery.children = [...sortedChildren];
    return {
      ...advancedQuery,
      query: [...(sortedQuery ?? [])],
      children: sortedChildren && sortedChildren?.length > 0 ? [...sortedChildren] : null,
    };
  } else return advancedQuery;
};

export const clearList = (query: IAdvancedQuery) => {
  const newQuery = query.children?.filter(
    list =>
      (list.query?.length && list.query?.length > 0) ||
      (list.children?.length && list.children?.length !== 0),
  );
  return { ...query, children: newQuery || null };
};

export const checkChildrenProps = (advancedQuery: IAdvancedQuery): IAdvancedQuery => {
  let newAdvancedQuery = cloneDeep(advancedQuery);
  newAdvancedQuery = clearList(newAdvancedQuery);

  if (newAdvancedQuery.children && newAdvancedQuery.children?.length > 0) {
    const newChildren = newAdvancedQuery.children?.map((list: IAdvancedQuery) =>
      checkChildrenProps(list),
    );

    const hasChildren = newChildren.filter(list => list.children);

    if (hasChildren.length < 1) {
      return { ...newAdvancedQuery, children: [...newChildren] };
    } else if (newChildren.length > 1 && newChildren.length === newAdvancedQuery.children.length)
      return { ...newAdvancedQuery, children: [...newChildren] };
    else if (
      newChildren.length === 1 &&
      newChildren[0].children &&
      newAdvancedQuery.children[0].children &&
      newChildren[0].children?.length === newAdvancedQuery.children[0].children?.length
    ) {
      return { ...newAdvancedQuery, children: [...newChildren] };
    } else if (
      newChildren.length === 1 &&
      newChildren[0].query.length === 0 &&
      !newChildren[0].children
    ) {
      return { ...newAdvancedQuery, children: null };
    } else return checkChildrenProps({ ...newAdvancedQuery, children: newChildren || null });
  } else return newAdvancedQuery;
};

export const boxNumToBoxDepthInfo = (boxNum: number): number[] => {
  return boxNum
    .toString()
    .split('')
    .map(arrIdx => +arrIdx - 1);
};

export const searchTarget = (
  advancedQuery: IAdvancedQuery,
  boxDepthInfo: number[],
): IAdvancedQuery | IQuery => {
  const target = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const newQuery: IAdvancedQuery = cloneDeep(nextQuery as IAdvancedQuery);
      const totalList = [
        ...(cloneDeep(newQuery?.query) ?? []),
        ...(cloneDeep(newQuery?.children) ?? []),
      ];
      const sortedList = sortBy(totalList, 'boxIndex');
      return { ...cloneDeep(sortedList[queryIndex] as IAdvancedQuery) };
    },
    advancedQuery,
  );

  return target;
};

export const updateNewTargetQuery = (
  original: IAdvancedQuery,
  boxDepthInfo: number[],
  newTarget: IAdvancedQuery,
): IAdvancedQuery => {
  const changeTarget = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const targetChildren = (nextQuery as IAdvancedQuery)?.children?.filter(
        list => list.boxIndex === queryIndex,
      );
      return (targetChildren as IAdvancedQuery[])[0];
    },
    original,
  );
  if ((newTarget as IAdvancedQuery)?.query)
    (changeTarget as IAdvancedQuery).query = newTarget.query;

  return original;
};

export const updateNewTargetGroup = (
  original: IAdvancedQuery,
  boxDepthInfo: number[],
  newTarget: IAdvancedQuery,
): IAdvancedQuery => {
  const changeTarget = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const targetChildren = (nextQuery as IAdvancedQuery)?.children?.filter(
        list => list.boxIndex === queryIndex,
      );
      return (targetChildren as IAdvancedQuery[])[0];
    },
    original,
  );

  if ((newTarget as IAdvancedQuery)?.children)
    (changeTarget as IAdvancedQuery).children = newTarget.children;
  return original;
};

export const updateNewTargetDelete = (
  original: IAdvancedQuery,
  boxDepthInfo: number[],
  newTarget: IAdvancedQuery,
  boxIndex: number,
): IAdvancedQuery => {
  const changeTarget = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const targetChildren = (nextQuery as IAdvancedQuery)?.children?.filter(
        list => list.boxIndex === queryIndex,
      );
      return (targetChildren as IAdvancedQuery[])[0];
    },
    original,
  );

  if ((newTarget as IAdvancedQuery)?.query)
    (changeTarget as IAdvancedQuery).query = newTarget.query;

  const sortedChildren = changeTarget.children?.map(list =>
    list.boxIndex > boxIndex ? { ...list, boxIndex: list.boxIndex - 1 } : list,
  );
  const sortedquery = changeTarget.query?.map(list =>
    list.boxIndex > boxIndex ? { ...list, boxIndex: list.boxIndex - 1 } : list,
  );
  changeTarget.query = [...(sortedquery ?? [])];
  if (sortedChildren) changeTarget.children = sortedChildren;
  return original;
};

export const updateNewTargetAll = (
  original: IAdvancedQuery,
  boxDepthInfo: number[],
  newTarget: IAdvancedQuery,
): IAdvancedQuery => {
  const changeTarget = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const targetChildren = (nextQuery as IAdvancedQuery)?.children?.filter(
        list => list.boxIndex === queryIndex,
      );
      return (targetChildren as IAdvancedQuery[])[0];
    },
    original,
  );
  if (
    newTarget.children?.length === 1 &&
    newTarget?.children[0].query.length === 0 &&
    !newTarget.children[0].children
  ) {
    (changeTarget as IAdvancedQuery).children = null;
    (changeTarget as IAdvancedQuery).query = newTarget.query.map(list =>
      list.boxIndex > (newTarget?.children as IAdvancedQuery[])[0].boxIndex
        ? { ...list, boxIndex: list.boxIndex - 1 }
        : list,
    );
  } else {
    (changeTarget as IAdvancedQuery).children = newTarget.children;
    (changeTarget as IAdvancedQuery).query = newTarget.query;
  }
  return original;
};

export const updateNewOperator = (
  original: IAdvancedQuery,
  boxDepthInfo: number[],
  newTarget: IAdvancedQuery,
): IAdvancedQuery => {
  const changeTarget = boxDepthInfo.reduce(
    (nextQuery: IAdvancedQuery, queryIndex: number): IAdvancedQuery => {
      const targetChildren = (nextQuery as IAdvancedQuery)?.children?.filter(
        list => list.boxIndex === queryIndex,
      );
      return (targetChildren as IAdvancedQuery[])[0];
    },
    original,
  );

  (changeTarget as IAdvancedQuery).operator = newTarget.operator;
  return original;
};

export const convertString = (advancedQuery: IAdvancedQuery): string => {
  const totalBoxList = [...(advancedQuery?.query ?? []), ...(advancedQuery?.children ?? [])].sort(
    (a, b) => a.boxIndex - b.boxIndex,
  );
  const result = totalBoxList.map(list => {
    if (list.hasOwnProperty('content') && list.hasOwnProperty('selectedQuery')) {
      return `(${(list as IQuery)?.selectedQuery?.queryType ?? ''}${
        (list as IQuery)?.selectedQuery?.queryKey
          ? `.${(list as IQuery)?.selectedQuery?.queryKey}`
          : ''
      } ${(list as IQuery)?.selectedQuery?.queryOperator ?? ''} ${
        (list as IQuery)?.selectedQuery?.queryValue ?? ''
      })`;
    } else {
      const stringResult = convertString(list as IAdvancedQuery);

      return `(${stringResult})`;
    }
  });

  return result.join(` ${advancedQuery.operator ?? 'AND'} `);
};

export const queryConvertToString = (query: IQuery): string => {
  return `(${(query as IQuery)?.selectedQuery?.queryType ?? ''}${
    (query as IQuery)?.selectedQuery?.queryKey
      ? `.${(query as IQuery)?.selectedQuery?.queryKey}`
      : ''
  } ${(query as IQuery)?.selectedQuery?.queryOperator ?? ''} ${
    (query as IQuery)?.selectedQuery?.queryValue ?? ''
  })`;
};

export const updateSelectedQueryInAdvancedQuery = (original: IAdvancedQuery): IAdvancedQuery => {
  let newOriginal = original;

  const newQuery = newOriginal.query.map(list => {
    if (list.selectedQuery)
      return {
        ...list,
        selectedQuery: { ...list.selectedQuery, boxNum: (10 + (list.boxIndex + 1)).toString() },
      };
    else return { ...list };
  });

  newOriginal = { ...newOriginal, query: [...newQuery] };

  if (!!newOriginal.children && newOriginal.children?.length > 0) {
    const newChildren = newOriginal.children.map(child => {
      const newChildQuery = child.query.map(list => {
        if (list.selectedQuery)
          return {
            ...list,
            selectedQuery: {
              ...list.selectedQuery,
              boxNum: (100 + (child.boxIndex + 1) * 10 + (list.boxIndex + 1)).toString(),
            },
          };
        else return { ...list };
      });
      let newChildChildren;
      if (child.children) {
        newChildChildren = child.children.map(childChildren => {
          const newchildChildrenQuery = childChildren.query.map(listQuery => {
            if (listQuery.selectedQuery)
              return {
                ...listQuery,
                selectedQuery: {
                  ...listQuery.selectedQuery,
                  boxNum: (
                    1000 +
                    ((child.boxIndex + 1) * 100 +
                      (childChildren.boxIndex + 1) * 10 +
                      (listQuery.boxIndex + 1))
                  ).toString(),
                },
              };
            else return { ...listQuery };
          });
          let newChildChildrenChildren;
          if (childChildren.children) {
            newChildChildrenChildren = childChildren.children.map(childChildrenChildren => {
              const newchildChildrenChildrenQuery = childChildrenChildren.query.map(
                childListQuery => {
                  if (childListQuery.selectedQuery)
                    return {
                      ...childListQuery,
                      selectedQuery: {
                        ...childListQuery.selectedQuery,
                        boxNum: (
                          10000 +
                          ((child.boxIndex + 1) * 1000 +
                            (childChildren.boxIndex + 1) * 100 +
                            (childChildrenChildren.boxIndex + 1) * 10) +
                          childListQuery.boxIndex +
                          1
                        ).toString(),
                      },
                    };
                  else return { ...childListQuery };
                },
              );
              return {
                ...childChildrenChildren,
                query: [...newchildChildrenChildrenQuery],
              };
            });
          }
          return {
            ...childChildren,
            query: [...newchildChildrenQuery],
            children: newChildChildrenChildren ? [...newChildChildrenChildren] : null,
          };
        });
      }
      return {
        ...child,
        query: [...newChildQuery],
        children: newChildChildren ? [...newChildChildren] : null,
      };
    });

    newOriginal = { ...newOriginal, children: [...newChildren] };
  }

  return newOriginal;
};
