import { useRouteInfo } from '../../../../contexts/RouteContext';
import { getUrl } from '../../../../routes/util';
import { ANALYTICS_PATH, USERS_PATH } from '../paths';

type RouteInfo = ReturnType<typeof useRouteInfo>;

export function getAnalyticsUrl(
  routeInfo: RouteInfo,
  reportId?: string,
  path: string = USERS_PATH,
): string {
  const pathSegments = [
    routeInfo.urlMatchInfo.accountName,
    'label',
    'project',
    routeInfo.urlMatchInfo.projectId,
    ANALYTICS_PATH,
    path,
  ];
  const params = { reportId: reportId ?? '' };
  return getUrl(pathSegments, params);
}
