/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, IconButton, useLocalStorage } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import CommandsService from '../services/CommandsService';
import { daysAgo } from '../utils/date';
import {
  Command,
  CommandStatus,
  CommandText,
  DoneStatuses,
  exponentialBackoffTime,
  getCommandPage,
  InProgressStatuses,
} from '../utils/LabelCommandUtils';
import { isOwnerOrAdmin, useAuthInfo } from './AuthContext';
import { useRouteInfo } from './RouteContext';

interface Collection<T> {
  items: T[];
  hasMore: boolean;
  isLoading: boolean;
  count: number;
  loadedPage: number;
}

const initialCollection = {
  items: [],
  hasMore: false,
  isLoading: false,
  count: 0,
  loadedPage: 1,
};

type Filters = { onlyThisProject: boolean; onlyMy: boolean };

export interface LabelCommandContext {
  commandsInProgress: Collection<Command>;
  commandsDone: Collection<Command>;
  fetchCommands(status: 'in_progress' | 'done', page?: number): Promise<void>;
  fetchMoreCommands(status: 'in_progress' | 'done', nextPage?: number): Promise<void>;
  cancelCommand(command: Command): Promise<void>;
  filters: Filters;
  processedFilters: Filters;
  setFilters(filters: Filters): void;
  registerCommand(id: string): void;
  isModalVisible: boolean;
  setIsModalVisible(isVisible: boolean): void;
  isArchiveVisible: boolean;
  setIsArchiveVisible(isVisible: boolean): void;
  tabValue: 'in_progress' | 'done';
  setTabValue(tabValue: 'in_progress' | 'done'): void;
  previousCommandsInProgress: Collection<Command>;
}

const LabelCommandContext = React.createContext({} as LabelCommandContext);

type Progress = { progress: number; totalCount: number; status: CommandStatus };

/**
 * Cache for update info. This state doesn't need to be reactive, so we keep it
 * as a WeakMap, which has the added benefit that data can be GC'ed as soon as the
 * command object is removed.
 */
type CommandUpdateInfo = {
  lastProgress: Progress;
  timeout: NodeJS.Timeout | null;
  unchangedCount: number;
};
const updateInfo = new WeakMap<Command, CommandUpdateInfo>();

function getUpdateInfo(command: Command): CommandUpdateInfo {
  const info = updateInfo.get(command);
  if (info) {
    return info;
  }
  const { progress, totalCount, status } = command;
  // For commands that have not been updated in a while, start exponential backoff higher
  const unchangedCount = Math.min(
    7,
    Math.floor((+new Date() - +new Date(command.updatedAt)) / 30000),
  );
  const initialInfo = {
    lastProgress: { progress, totalCount, status },
    timeout: null,
    unchangedCount,
  };
  updateInfo.set(command, initialInfo);
  return initialInfo;
}

function hasProgressChanged(a: Progress, b: Progress) {
  return a.progress !== b.progress || a.totalCount !== b.totalCount || a.status !== b.status;
}

export const LabelCommandContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  // The refs are for internal use, so that timeout callbacks always have the latest state
  const _commandsInProgress = useRef<Collection<Command>>({ ...initialCollection });
  const _commandsDone = useRef<Collection<Command>>({ ...initialCollection });
  // The state is for external use, so that components can update when state changes
  const [commandsInProgress, setCommandsInProgress] = useState<Collection<Command>>({
    ...initialCollection,
  });
  const [previousCommandsInProgress, setPreviousCommandsInProgress] = useState<Collection<Command>>(
    {
      ...initialCollection,
    },
  );
  const [commandsDone, setCommandsDone] = useState<Collection<Command>>({ ...initialCollection });
  const [filters, setFilters] = useLocalStorage<Filters>('commandFilter', {
    onlyThisProject: true,
    onlyMy: true,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isArchiveVisible, setIsArchiveVisible] = useState(false);
  const [tabValue, setTabValue] = useState<'in_progress' | 'done'>('in_progress');

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  // Irregardless of user's preference, non-Admins can only view their own actions inside of projects.
  const processedFilters: Filters = useMemo(() => {
    return {
      onlyThisProject:
        routeInfo.urlMatchInfo.projectId && (filters.onlyThisProject || !isOwnerOrAdmin(authInfo)),
      onlyMy: filters.onlyMy || !isOwnerOrAdmin(authInfo),
    };
  }, [filters, authInfo]);

  function showDoneSnackbar(command: Command) {
    const message = (
      <span>
        {startCase(command.status.toLowerCase())}: <CommandText command={command} />
      </span>
    );
    const key = `command-${command.id}-done`;
    const page = getCommandPage(command);
    const url = page ? `/${authInfo.accountName}/label/${page}` : '';
    enqueueSnackbar(message, {
      variant: command.status === 'FINISHED' ? 'success' : 'warning',
      key,
      preventDuplicate: true,
      autoHideDuration: 10000,
      action: (
        <>
          {url && (
            <Button
              variant="text"
              color="backgroundColor"
              onClick={() => history.push(url, new Date())}
            >
              {t('button.view')}
            </Button>
          )}
          <IconButton icon="clear" color="backgroundColor" onClick={() => closeSnackbar(key)} />
        </>
      ),
    });
    // Close the start toast if it is still there
    closeSnackbar(`command-${command.id}-start`);
  }

  function showStartSnackbar(command: Command) {
    const message = (
      <span>
        {t('consts.snackbarMessage.prefix.started')}: <CommandText command={command} />
      </span>
    );
    const key = `command-${command.id}-start`;
    enqueueSnackbar(message, {
      variant: 'success',
      key,
      preventDuplicate: true,
      autoHideDuration: 7000,
      action: (
        <>
          <Button variant="text" color="backgroundColor" onClick={() => setIsModalVisible(true)}>
            {t('button.view')}
          </Button>
          <IconButton icon="clear" color="backgroundColor" onClick={() => closeSnackbar(key)} />
        </>
      ),
    });
  }

  /**
   * Fetch command info by id and update state.
   * This is called twice in a normal lifecycle:
   * - once on creation by the user
   * - once when status changes from InProgress to Done
   * This only affects commandsInProgress.
   */
  async function registerCommand(id: string) {
    const data = await CommandsService.getCommand({
      id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const command = _commandsInProgress.current!.items.find(c => c.id === data.id);
    if (command) {
      Object.assign(command, data);
    } else {
      _commandsInProgress.current!.items = [data, ..._commandsInProgress.current!.items];
    }
    setCommandsInProgress({ ..._commandsInProgress.current! });
    const isDone = !InProgressStatuses.includes(data.status);
    if (isDone) {
      showDoneSnackbar(data);
    } else {
      showStartSnackbar(data);
    }
  }

  async function fetchProgress(command: Command) {
    const updateInfo = getUpdateInfo(command);

    const progress = await CommandsService.getCommandProgress({
      id: command.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    // If progress is unchanged, increase a counter for exponential backoff
    const hasChanged = hasProgressChanged(updateInfo.lastProgress, progress);
    updateInfo.unchangedCount = hasChanged ? 0 : updateInfo.unchangedCount + 1;
    updateInfo.lastProgress = progress;

    const isDone = !InProgressStatuses.includes(progress.status);

    if (hasChanged) {
      // Update state
      Object.assign(command, progress);
      setCommandsInProgress({ ..._commandsInProgress.current! });
    }

    if (!isDone) {
      // As long as command is running, keep requesting progress
      scheduleFetchProgress(command);
    } else {
      // Fetch one last full update (this contains the result)
      registerCommand(command.id);
    }
  }

  function scheduleFetchProgress(command: Command, immediate = false) {
    const updateInfo = getUpdateInfo(command);
    if (updateInfo.timeout) {
      clearTimeout(updateInfo.timeout);
    }
    const time = immediate ? 0 : exponentialBackoffTime(updateInfo.unchangedCount);
    updateInfo.timeout = setTimeout(() => fetchProgress(command), time);
  }

  useEffect(() => {
    // Add new updaters
    commandsInProgress.items.forEach(command => {
      if (InProgressStatuses.includes(command.status)) {
        scheduleFetchProgress(command);
      }
    });

    return () => {
      // Cleanup updaters for previously processed items
      commandsInProgress.items.forEach(command => {
        const timeout = updateInfo.get(command)?.timeout;
        if (timeout) {
          clearTimeout(timeout);
        }
      });
    };
  }, [commandsInProgress]);

  async function fetchCommands(status: 'in_progress' | 'done', page = 1) {
    // When a non-Admin exits a project, projectId is unset, causing a re-render and
    // fetchCommands to run. This check ensures we only make team-wide calls when we're allowed to.
    if (!routeInfo.urlMatchInfo.projectId && !isOwnerOrAdmin(authInfo)) {
      return;
    }

    const setter = status === 'in_progress' ? setCommandsInProgress : setCommandsDone;
    const previous =
      status === 'in_progress' ? _commandsInProgress.current! : _commandsDone.current!;

    if (page === 1) {
      setter({
        isLoading: true,
        items: [],
        hasMore: false,
        count: 0,
        loadedPage: 0,
      });
    } else {
      setter({
        ...previous,
        isLoading: true,
      });
    }

    const nowMinusThreeDays = daysAgo(3);
    const nowMinusSevenDays = daysAgo(7);

    const params = {
      projectId: processedFilters.onlyThisProject ? routeInfo.urlMatchInfo.projectId : undefined,
      createdByIn: processedFilters.onlyMy ? [`${authInfo.email}`] : [],
      statusIn: status === 'in_progress' ? InProgressStatuses : DoneStatuses,
      createdAtGte:
        status === 'done' && !isArchiveVisible
          ? nowMinusThreeDays.toISOString()
          : nowMinusSevenDays.toISOString(),
      page,
    };

    try {
      const result = await CommandsService.getCommands({
        params,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const newIds = result.results.map(item => item.id);
      const previousDeduplicated = previous.items.filter(item => !newIds.includes(item.id));
      const next = page === 1 ? result.results : [...previousDeduplicated, ...result.results];
      Object.assign(previous, {
        items: next,
        count: result.count,
        hasMore: result.count > next.length,
        isLoading: false,
        loadedPage: page,
      });
      setter(previous);
    } catch (_) {
      Object.assign(previous, {
        hasMore: false,
        isLoading: false,
        loadedPage: page,
      });
    }
  }

  function fetchMoreCommands(status: 'in_progress' | 'done', nextPage = 1) {
    const previous =
      status === 'in_progress' ? _commandsInProgress.current! : _commandsDone.current!;
    return fetchCommands(status, previous.loadedPage + nextPage);
  }

  async function cancelCommand(command: Command) {
    try {
      await CommandsService.sendCommandAction({
        id: command.id,
        action: 'cancel',
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      scheduleFetchProgress(command, true);
    } catch (e: any) {
      const msg = `${e.message}` === 'Duplicated' ? 'Already canceled.' : `${e}`;
      enqueueSnackbar(`Could not cancel command. ${msg}`, {
        variant: 'warning',
      });
    }
  }

  useEffect(() => {
    fetchCommands('in_progress');
  }, []);

  return (
    <LabelCommandContext.Provider
      value={{
        commandsInProgress,
        commandsDone,
        fetchCommands,
        fetchMoreCommands,
        registerCommand,
        cancelCommand,
        filters,
        processedFilters,
        setFilters,
        isModalVisible,
        setIsModalVisible,
        isArchiveVisible,
        setIsArchiveVisible,
        tabValue,
        setTabValue,
        previousCommandsInProgress,
      }}
    >
      {children}
    </LabelCommandContext.Provider>
  );
};

export const useLabelCommandContext = (): LabelCommandContext => {
  return React.useContext(LabelCommandContext);
};
