import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dialog, Typography, useDialogState } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { MODEL_ENDPOINT_STATUS_RESUME } from '../../../consts/SnackbarMessage';
import { SchedulePause } from '../components/components';
import { ENDPOINT_DEPLOYMENT_CREDIT } from '../constant';
import { useResumeEndpointMutation } from '../queries/modelQueries';
import { EndpointScheduling } from '../services/types';

export const ResumeEndpointDialog = ({
  state,
  from,
  endpointId,
}: {
  state: ReturnType<typeof useDialogState>;
  from: 'retry' | 'resume';
  endpointId: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: resumeEndpoint } = useResumeEndpointMutation();

  const [schedulingTimeList, setSchedulingTimeList] = useState<EndpointScheduling[]>([]);
  const [open, setOpen] = useState(false);
  return (
    <Dialog state={state} hideOnClickOutside={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {from === 'resume'
          ? t('model.resumeEndpointDialog.resumeTitle')
          : t('model.resumeEndpointDialog.retryTitle')}
      </Dialog.Header>
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={'model.resumeEndpointDialog.content'}
          values={{ credit: ENDPOINT_DEPLOYMENT_CREDIT }}
        />
      </Typography>
      <SchedulePause
        open={open}
        setOpen={setOpen}
        schedulingTimeList={schedulingTimeList}
        setSchedulingTimeList={setSchedulingTimeList}
      />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          onClick={() => {
            resumeEndpoint(
              { id: endpointId, endpointScheduling: open ? schedulingTimeList : [] },
              {
                onSuccess: () => {
                  enqueueSnackbar(MODEL_ENDPOINT_STATUS_RESUME({ t }), {
                    variant: 'info',
                  });
                  state.setVisible(false);
                },
              },
            );
          }}
          color="primary"
          variant="strong-fill"
        >
          {from === 'resume' ? t('shared.resume') : t('shared.retry')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
