import { SuiteLogo } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../menu/MenuItem';
import CurateLayout from './CurateLayout';
import { CurateDatasetsMenuItem, CurateSampleDatasetsMenuItem } from './datasets/MenuItem';
import { CurateTutorialMenuItem } from './datasets/tutorial/MenuItem';

export const CurateMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateInitialPage, // not tracked
  path: 'curate',
  matchParam: ':suite(curate)',
  group: 'suite',
  icon: <SuiteLogo type="curate" />,
  title: ({ t }) => t('curate.title'),
  component: CurateLayout,
  children: [CurateDatasetsMenuItem, CurateSampleDatasetsMenuItem, CurateTutorialMenuItem],
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
});
