import { Box, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import noDataImage from './noData.png';
import { ScopeMode } from '../../../../types/viewTypes';

export const NoResultsView = ({ scope, text }: { scope?: ScopeMode; text?: string }) => {
  const curateText = `Please update query to see the 2D projections of ${scope} embeddings`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      gap={1}
      style={{ transform: 'translateY(-40px)' }}
    >
      <Image width="86" height="86" src={noDataImage} alt="Illustration showing empty data." />
      <Typography variant="h2" color="gray-400">
        No results matching given query
      </Typography>
      <Typography variant="m-regular" color="gray-300">
        {text ?? curateText}
      </Typography>
    </Box>
  );
};

export default NoResultsView;
