import io from 'socket.io-client';

type Socket = ReturnType<typeof io>;

const connect = ({ accountName }: { accountName: string }): Socket => {
  return io(`https://${process.env.NEXT_PUBLIC_API_HOST}/?tenant_room_id=${accountName}`, {
    path: '/websocket',
  });
};
const subscribe = (socket: Socket, accountName: string, email: string): void => {
  socket.emit('subscribe', `${accountName}:${email}`);
};

const unsubscribe = (socket: Socket, accountName: string, email: string): void => {
  socket.emit('unsubscribe', `${accountName}:${email}`);
};

export default { connect, subscribe, unsubscribe };
