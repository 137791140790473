import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, TabList, TabPanel, Tabs } from '@superb-ai/ui';

import getContents from '../contents';
import { TargetType } from '../types';

export default function DetailInfosTab({ target }: { target: TargetType }) {
  const { t, i18n } = useTranslation();
  const contents = useMemo(() => {
    return getContents(t, i18n.language)[target];
  }, [t, target]);

  return (
    <Tabs>
      <TabList color="gray" variant="inline">
        <Tab>{t('integration.beforeYouStart')}</Tab>
        <Tab>{t('integration.troubleshooting')}</Tab>
      </TabList>
      <Box px={1.5} py={2}>
        <TabPanel>{contents.tabs?.before}</TabPanel>
        <TabPanel>{contents.tabs?.troubleshooting}</TabPanel>
      </Box>
    </Tabs>
  );
}
