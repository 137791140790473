import React, { useEffect, useRef, useState } from 'react';

import { Box, IconButton, Input, Typography } from '@superb-ai/norwegian-forest';
import { concat, slice } from 'lodash';
import { useSnackbar } from 'notistack';

import { CsvFormat, useUploadInfo } from '../../../contexts/UploadContext';
import RegexUtils from '../../../utils/RegexUtils';

const NewUrlInput: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { urls, setUrls } = useUploadInfo();
  const [dataKey, setDataKey] = useState('');
  const [url, setUrl] = useState('');
  const dataKeyInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setDataKey('');
    setUrl('');
  }, [urls]);

  const handleAddUrl = () => {
    if (dataKey === '') {
      enqueueSnackbar('Please enter data key', { variant: 'warning' });
      return;
    }

    if (url === '' || !RegexUtils.IS_URL(url)) {
      enqueueSnackbar('Please enter valid url', { variant: 'warning' });
      return;
    }

    const addedDataKeyList = urls.map(item => item.dataKey);
    if (addedDataKeyList.indexOf(dataKey) >= 0) {
      enqueueSnackbar('Duplicated data key', { variant: 'warning' });
      return;
    }

    setUrls([{ dataKey, url } as CsvFormat].concat(urls));
    dataKeyInputRef?.current?.focus();
  };

  return (
    <>
      <Input
        ref={dataKeyInputRef}
        value={dataKey}
        onChange={event => setDataKey(event.target.value)}
      />
      <Input value={url} onChange={event => setUrl(event.target.value)} />
      <IconButton color="grey" variant="strong-fill" icon="plus" onClick={handleAddUrl} />
    </>
  );
};

const UrlItem: React.FC<{ url: CsvFormat; index: number }> = ({ url, index }) => {
  const { urls, setUrls } = useUploadInfo();

  const handleRemoveUrl = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    setUrls(concat(slice(urls, 0, index), slice(urls, index + 1)));
  };

  const handleChangeDataKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDataKey = event.target.value;
    setUrls(
      concat(slice(urls, 0, index), { dataKey: newDataKey, url: url.url }, slice(urls, index + 1)),
    );
  };

  const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = event.target.value;
    setUrls(
      concat(slice(urls, 0, index), { dataKey: url.dataKey, url: newUrl }, slice(urls, index + 1)),
    );
  };

  return (
    <>
      <Input value={url.dataKey} onChange={handleChangeDataKey} />
      <Input value={url.url} onChange={handleChangeUrl} />
      <IconButton color="grey" icon="clear" onClick={event => handleRemoveUrl(event, index)} />
    </>
  );
};

const AddUrls: React.FC = () => {
  const { urls } = useUploadInfo();

  return (
    <Box
      display="grid"
      gridTemplateColumns="160px auto 32px"
      gap={4}
      maxHeight={200}
      overflow="auto"
    >
      <Typography variant="body4">Data Key</Typography>
      <Typography variant="body4">URL</Typography>
      <Box />
      <NewUrlInput />
      {urls.map((url, index) => (
        <UrlItem key={url.dataKey} url={url} index={index} />
      ))}
    </Box>
  );
};

export default AddUrls;
