import { orderBy, sumBy } from 'lodash';

import MathUtils from '../../../../../../../utils/MathUtils';
import { METADATA_CARDINALITY_THRESHOLD } from '../../../../../services/AnalyticsService';
import { ChartDatum } from '../charts/types';
import {
  ClassificationKeyDatum,
  ClassificationKeyResponse,
  ClassificationValueDatum,
  ClassificationValueResponse,
  ClassStatsDatum,
  HistogramDatum,
  MetadataKeyDatum,
  MetadataValueDatum,
} from '../types';

type JsonObj = Record<string, any>;

/** Image Metadata */
export function transformKeyData(data: MetadataKeyDatum[]): ChartDatum[] {
  if (!data.length) return [];
  const formatted = Object.entries(
    data.reduce((acc, curr) => {
      acc[curr.key] = (acc[curr.key] || 0) + curr.imageCount; // images containing metadata
      return acc;
    }, {} as JsonObj),
  );
  return orderBy(
    formatted.map(([key, count]) => ({ key, count })),
    'count',
    'desc',
  );
}

export function filterSystemMetadata(
  data: MetadataKeyDatum[],
  showSystemMetadata?: boolean,
): MetadataKeyDatum[] {
  if (!data.length) return data;
  return showSystemMetadata ? data : data.filter(item => !item.key.startsWith('_'));
}

export function metadataKeySortTransformer(data: MetadataKeyDatum[]): MetadataKeyDatum[] {
  const systemMetadata = data
    .filter(item => item.key.startsWith('_'))
    .sort((a, b) => b.imageCount - a.imageCount);
  const userMetadata = data
    .filter(item => !item.key.startsWith('_'))
    .sort((a, b) => b.imageCount - a.imageCount);
  // .sort((a, b) => a.valueCount - b.valueCount);
  return userMetadata.concat(systemMetadata);
}

export function transformValueData(data: MetadataValueDatum[]): ChartDatum[] {
  // const difference = totalImageCount - sumBy(data, 'count');
  const transformed = data.map(d => {
    return { key: d.value, count: d.count };
  });
  // transformed.push({ key: 'Not Defined', count: difference });
  return orderBy(transformed, 'count', 'desc');
}

export function getValueOptions(data: MetadataKeyDatum[], metadataKey: string): number {
  return data.find(d => d.key === metadataKey)?.valueCount || 0;
}

/** Annotation analytics **/
type AnnotationTypeDatum = {
  annotationType: string;
  count: number;
};
/**
 * Transforms an array of annotation type data into an array of chart data,
 * where each datum represents an annotation type and its count.
 *
 * @param {AnnotationTypeDatum[]} data - The annotation type data to transform.
 * @returns {ChartData[]} An array of chart data representing the transformed data.
 */
export const annotationTypeStatsTransformer = (data: AnnotationTypeDatum[]): ChartDatum[] => {
  if (sumBy(data, 'count') === 0) {
    return [];
  }
  return data.map(({ annotationType, count }) => ({ key: annotationType, count }));
};

export const classStatsTransformer = (data: any[], totalImages: number): ClassStatsDatum[] => {
  const totalAnnotations = sumBy(data, 'annotationCount');
  const sortedData = orderBy(data, ['annotationCount', 'imageCount'], ['desc', 'desc']);

  return (
    sortedData.map(datum => {
      return {
        ...datum,
        annotationShare: MathUtils.calculatePercent({
          numerator: datum?.annotationCount,
          denominator: totalAnnotations,
          nearest: 'hundredth',
        }),
        imageShare: MathUtils.calculatePercent({
          numerator: datum?.imageCount,
          denominator: totalImages,
          nearest: 'hundredth',
        }),
      };
    }) || []
  );
};

function interpolateArray(arr: HistogramDatum[]) {
  const minKey = Math.min(...arr.map(obj => parseInt(obj.key, 10)));
  const maxKey = Math.max(...arr.map(obj => parseInt(obj.key, 10)));

  // Create a new array with all the missing keys
  const result = [];
  for (let i = minKey; i <= maxKey; i++) {
    const obj = arr.find(item => parseInt(item.key, 10) === i);
    result.push(
      obj ? { ...obj, keyNumeric: Number(obj.key) } : { key: i, keyNumeric: i, count: 0 },
    );
  }
  return result;
}

export const annotationsStatsTransformer = (data: any[]): ChartDatum[] => {
  return interpolateArray(data);
};

export const isMetadataTextType = (key?: MetadataKeyDatum) => {
  if (!key) return false;
  return key?.type === 'String' && key?.valueCount > METADATA_CARDINALITY_THRESHOLD;
};

export const classificationKeyStatsTransformer = (response: ClassificationKeyResponse) => {
  const addShare = response.data.map((datum: ClassificationKeyDatum) => {
    datum['imageShare'] = MathUtils.calculatePercent({
      numerator: datum?.imageCount,
      denominator: response.totalImageCount,
    });
    return datum;
  });
  const sorted = orderBy(addShare, ['imageCount'], ['desc']);
  return {
    ...response,
    data: sorted,
  } as ClassificationKeyResponse;
};

export const classificationValueStatsTransformer = (response: ClassificationValueResponse) => {
  const addShare = response.data.map((datum: ClassificationValueDatum) => {
    datum['share'] = MathUtils.calculatePercent({
      numerator: datum?.count,
      denominator: response.totalImageCount,
    });
    return datum;
  });
  const sorted = orderBy(addShare, ['count'], ['desc']);
  return {
    ...response,
    data: sorted,
  } as ClassificationValueResponse;
};
