import { differenceInDays, endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import { daysAgo, parseDate } from './date';

interface DateRange {
  dateFrom: Date;
  dateTo: Date;
}

const getConvertedDateFormatForParam = (date: Date, timeOption: boolean): string => {
  if (timeOption) {
    return ''.concat(
      `${date.getFullYear()}`,
      '-',
      date.getMonth() + 1 > 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`,
      '-',
      date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`,
      ' ',
      `${date.getHours()}`,
      ':',
      `${date.getMinutes()}`,
      ':',
      `${date.getSeconds()}`,
    );
  }
  return ''.concat(
    `${date.getFullYear()}`,
    '-',
    date.getMonth() + 1 > 9 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`,
    '-',
    date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`,
    ' 00:00:00',
  );
};

const getDateAppliedTimezone = (date: string | Date | number): Date => {
  const utcAppliedTimeZone = new Date(date);
  return utcAppliedTimeZone;
};

const getDateAppliedTimezoneOffset = (date: string | Date): Date => {
  const localTime = new Date();
  const timezoneOffset = localTime.getTimezoneOffset();
  const appliedDate = new Date(new Date(date).getTime() - timezoneOffset * 60 * 1000);
  return appliedDate;
};

const getDateSubtractedTimezone = (date: string | Date | number): Date => {
  const utc = new Date(date);
  const timeZoneOffset = utc.getTimezoneOffset();
  const subtractedTimeZone = new Date(utc.getTime() + timeZoneOffset * 60 * 1000);
  return subtractedTimeZone;
};

const getTodaysDate = (): string => {
  return format(new Date(), 'yyyy-MM-dd');
};

const getYesterday = (): Date => {
  return daysAgo(1);
};

const getDayOfTheWeek = (date: Date, abbreviated?: boolean): string => {
  const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeek = week[date.getDay()];
  return abbreviated ? dayOfWeek.slice(0, 3) : dayOfWeek;
};

const getLastSevenDays = (): DateRange => {
  return {
    dateFrom: daysAgo(7),
    dateTo: daysAgo(1),
  };
};

const getLastThirtyDays = (): DateRange => {
  return {
    dateFrom: daysAgo(30),
    dateTo: daysAgo(1),
  };
};

const getLastMonth = (): DateRange => {
  const lastMonth = subMonths(new Date(), 1);
  return {
    dateFrom: startOfMonth(lastMonth),
    dateTo: endOfMonth(lastMonth),
  };
};

const getThisMonth = (): DateRange => {
  const today = new Date();
  return {
    dateFrom: startOfMonth(today),
    dateTo: endOfMonth(today),
  };
};

const getNumberOfDatesBetween = (dateFrom: string | Date, dateTo: string | Date): number => {
  return differenceInDays(parseDate(dateTo), parseDate(dateFrom));
};

const getFormatVideoTimeDuration = (durationSeconds: number) => {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = Math.round(durationSeconds % 60);
  return [
    hours,
    minutes > 9 ? minutes : hours ? '0' + minutes : minutes || '0',
    seconds > 9 ? seconds : '0' + seconds,
  ]
    .filter(Boolean)
    .join(':');
};

const getFormattedDate = (date: string | Date): string => {
  return format(parseDate(date, true), 'yyyy/MM/dd');
};

export default {
  getConvertedDateFormatForParam,
  getDateAppliedTimezone,
  getDateAppliedTimezoneOffset,
  getDateSubtractedTimezone,
  getDayOfTheWeek,
  getTodaysDate,
  getYesterday,
  getLastSevenDays,
  getLastThirtyDays,
  getLastMonth,
  getThisMonth,
  getNumberOfDatesBetween,
  getFormatVideoTimeDuration,
  getFormattedDate,
};
