import React, { ComponentProps, useRef } from 'react';

import { Box } from '@superb-ai/ui';

import useSectionSize from '../components/elements/windowedImageGrid/useSectionSize';
import PageWithMenu from './PageWithMenu';

interface Props {
  menu: JSX.Element;
  extraHeader?: JSX.Element;
  pageBoxProps?: ComponentProps<typeof Box>;
  banner?: JSX.Element;
}

export default function PageWithMenuAndBanner({
  menu,
  extraHeader,
  children,
  banner,
  pageBoxProps,
}: React.PropsWithChildren<Props>): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionSize = useSectionSize(containerRef);

  return (
    <PageWithMenu menu={menu} extraHeader={extraHeader} pageBoxProps={pageBoxProps}>
      <Box
        ref={containerRef}
        position="relative"
        style={sectionSize && { height: sectionSize[3], width: sectionSize[2] }}
      >
        <Box
          position="sticky"
          ref={ref}
          display="flex"
          alignItems="center"
          backgroundColor="skyblue-100"
          px={4}
          py={2}
        >
          {banner}
        </Box>
        <Box
          position="absolute"
          overflow="auto"
          style={{ top: ref.current?.clientHeight || 0, bottom: 0, left: 0, right: 0 }}
        >
          {children}
        </Box>
      </Box>
    </PageWithMenu>
  );
}
