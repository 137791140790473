import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button, IconButton, Typography } from '@superb-ai/norwegian-forest';
type Props = {
  isEditTagModalOpen?: boolean;
  setIsEditTagModalOpen?: Dispatch<SetStateAction<boolean>>;
  EditTagModal?: JSX.Element;
  Filter?: JSX.Element;
  onSelectItems: () => void;
  canEditTag: boolean;
  selectedAllItems: boolean;
  subTitle?: JSX.Element;
};
export default function ResultActions({
  EditTagModal,
  setIsEditTagModalOpen,
  Filter,
  onSelectItems,
  canEditTag,
  selectedAllItems,
  subTitle,
}: React.PropsWithChildren<Props>) {
  const handleClickSelectAll = () => {
    onSelectItems();
  };

  return (
    <>
      <Box
        style={{
          marginLeft: '-32px',
          marginRight: '-32px',
          backgroundColor: '#f5f5f5',
        }}
        px={4}
        py={2.5}
        pb={0}
      >
        <Box display="flex" alignItems="center" gap="8px">
          {Filter}
          <Box display="flex" alignItems="center">
            <IconButton
              icon={!selectedAllItems ? 'checkbox' : 'checkboxChecked'}
              color={!selectedAllItems ? 'grey' : 'primary'}
              round
              size="xs"
              onClick={handleClickSelectAll}
            />
            <Typography variant="body4">Select All</Typography>
          </Box>
          <Box ml="auto">
            <Button
              variant="shadow"
              size="s"
              IconAdornment="tag"
              onClick={() => {
                if (!setIsEditTagModalOpen) return;
                setIsEditTagModalOpen(true);
              }}
              disabled={!canEditTag}
            >
              Edit Tags
            </Button>
          </Box>
        </Box>
      </Box>
      {subTitle && <Box pt={2}>{subTitle}</Box>}
      {EditTagModal}
    </>
  );
}
