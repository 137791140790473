import { useTranslation } from 'react-i18next';

import { AlertDialog, useDialogState } from '@superb-ai/ui';

type DialogStateReturn = ReturnType<typeof useDialogState>;

export default function IpWhitelistRemoveDialog({
  dialogState,
  number,
  remove,
  cancel,
}: {
  dialogState: DialogStateReturn;
  number: number;
  remove: () => void;
  cancel: () => void;
}) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      state={dialogState}
      title={`${t('settings.security.ipWhitelistRemoveTitle')} ${number} ${
        number === 1 ? t('settings.security.ipAddress_one') : t('settings.security.ipAddress_other')
      }`}
      mainButton={{
        label: t('settings.security.ipWhitelistRemoveConfirm'),
        onClick() {
          dialogState.hide();
          remove();
        },
      }}
      subButton={{
        label: t('settings.security.ipWhitelistRemoveCancel'),
        onClick() {
          dialogState.hide();
          cancel();
        },
      }}
    >
      {t('settings.security.ipWhitelistRemoveText')}
    </AlertDialog>
  );
}
