import React, { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, Typography } from '@superb-ai/norwegian-forest';
import cn from 'classnames';

export const StyledModalConfirmation = styled.div(({ theme }: any) => {
  return css`
    background-color: ${theme.palette.primary[100]};
    color: ${theme.palette.primary[700]};
    padding: 8px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    transition: 0.12s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &.open {
      transform: translateY(0);
    }
  `;
});

// Add variant(error, warning,...) and close button if reused
type Props = { content: string; open: boolean; onClose: () => void; autoHideDuration?: number };

const ModalSnackbar: React.FC<Props> = ({ content, open, onClose, autoHideDuration = 5000 }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      const timeId = setTimeout(() => {
        setIsOpen(false);
        onClose();
      }, autoHideDuration);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [open]);

  return (
    <StyledModalConfirmation className={cn({ open: isOpen })}>
      <Icon name="warningOutline" size="12px" />
      <Typography variant="body4" textAlign="center">
        {content}
      </Typography>
    </StyledModalConfirmation>
  );
};

export default ModalSnackbar;
