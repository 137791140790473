import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import analyticsTracker from '../../../../../../analyticsTracker';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import SliceNameInput from '../../../../elements/NameInput/SliceNameInput';
import { useCreateEmptySliceJobMutation } from '../../../../queries/sliceQueries';
import { SliceResult, useCurateDatasetService } from '../../../../services/DatasetService';
import { MAXIMUM_NAME_LENGTH } from '../../../../utils/nameUtils';
import { getView } from '../../../../utils/routeUtils';
import { ImageFilterSchema } from '../filter/types';
import { CreateSliceSnackbarContent } from './components/CreateSliceSnackbarContent';

export type CreateSliceRequestParams = {
  name: string;
  description: string;
  appliedFilters?: ImageFilterSchema;
};

export default function CreateEmptySliceModal({
  createSliceModalIsOpen,
  setCreateSliceModalIsOpen,
  onCreate,
}: {
  createSliceModalIsOpen: boolean;
  setCreateSliceModalIsOpen: (isOpen: boolean) => void;
  onCreate?: () => Promise<any>;
}): JSX.Element {
  const { accountName, datasetId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId?: string;
  }>();
  const { t } = useTranslation();
  const { getDataset } = useCurateDatasetService();

  const history = useHistory();
  const viewType = getView(history);

  const { setDatasetInfo } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const [isValidName, setIsValidName] = useState<boolean>(false);
  const [createSliceName, setCreateSliceName] = useState<string>('');
  const [createSliceDescription, setCreateSliceDescription] = useState<string>('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { mutateAsync: createEmptySliceJob, isLoading: isCreatingSliceJob } =
    useCreateEmptySliceJobMutation({
      datasetId,
    });

  const triggerCreatedSnackbar = useCallback(
    (result: SliceResult, name: string) => {
      enqueueSnackbar(<>{`${t('bulkActions.messages.createdSlice', { name })}`}</>, {
        variant: 'success',
        key: `create-slice-${name}`,
        preventDuplicate: true,
        autoHideDuration: 10000,
        content: (key, message) => {
          return CreateSliceSnackbarContent(
            message,
            closeSnackbar,
            key,
            history,
            accountName,
            datasetId,
            result,
          );
        },
      });
    },
    [accountName, closeSnackbar, datasetId, enqueueSnackbar, history, t],
  );

  const trackDataSliceCreated = useCallback(
    (newSliceId: string, dataCount: number) => {
      analyticsTracker.dataSliceCreated({
        accountId: accountName,
        datasetId,
        sliceId: newSliceId,
        viewType: viewType,
        dataCount: dataCount ?? 0,
        dataType: 'image',
        referrer: 'explore-image',
      });
    },
    [accountName, datasetId, viewType],
  );

  const updateDataset = useCallback(async () => {
    const datasetInfo = await getDataset({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
  }, [datasetId, getDataset, setDatasetInfo, showPublicDatasets]);

  async function handleCreateSlice() {
    const createdSlice = await createEmptySliceJob({
      name: createSliceName,
      description: createSliceDescription,
    });
    // only trigger created snackbar if it's empty slice
    triggerCreatedSnackbar(createdSlice, createSliceName);
    updateDataset();
    onCreate && (await onCreate());

    trackDataSliceCreated(createdSlice.id, 0);
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
    setIsValidName(false);
  }

  const handleCloseButton = useCallback(() => {
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
    setIsValidName(false);
  }, [setCreateSliceModalIsOpen, setCreateSliceName, setCreateSliceDescription, setIsValidName]);

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setCreateSliceDescription(e.target.value);
    },
    [setCreateSliceDescription],
  );

  return (
    <Modal
      open={createSliceModalIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.button.createEmptySlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleCreateSlice,
        color: 'primary',
        isLoading: isCreatingSliceJob,
        disabled: !isValidName || createSliceName === '',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box py={2.5} px={4} style={{ minWidth: '520px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="black">
            {t('curate.slices.sliceName')}
          </Typography>
          <Typography variant="m-regular" color="black">
            {`${createSliceName.length}/${MAXIMUM_NAME_LENGTH}`}
          </Typography>
        </div>
        <SliceNameInput
          sliceName={createSliceName}
          setSliceName={setCreateSliceName}
          setIsValid={setIsValidName}
          datasetId={datasetId}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="black" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}{' '}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>

          <Typography variant="m-regular" color="black">
            {`${createSliceDescription.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleDatasetDescription}
          value={createSliceDescription}
        ></StyledTextarea>
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
