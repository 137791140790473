import React, { useEffect } from 'react';
import { Group, Image } from 'react-konva';

import Konva from 'konva';

const SuiteImage = React.memo(
  (props: any): any => {
    const {
      imageRef,
      image,
      setHoverIndex,
      width,
      height,
      setClickedAnnotationInfo,
      setHoveredAnnotation,
      brightness,
      contrast,
    } = props;

    useEffect(() => {
      if (!imageRef?.current) return;
      if (!imageRef?.current?.attrs?.width) return;

      imageRef.current.cache();
      imageRef.current.getLayer().batchDraw();
      // eslint-disable-next-line
    }, [imageRef?.current?.attrs?.width]);

    const handleOnClickImage = () => {
      setClickedAnnotationInfo(null);
    };

    const handleMouseEnterImage = () => {
      setHoveredAnnotation(null);
      setHoverIndex(1);
    };

    return (
      <Group>
        {/* Image에 event를 등록하면, props에 변경사항이 생겨도 반영되지 않음. */}
        <Image
          id="image"
          alt="Label image"
          image={image}
          x={0}
          y={0}
          width={width}
          height={height}
          filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
          brightness={brightness}
          contrast={contrast}
          onClick={handleOnClickImage}
          onMouseEnter={handleMouseEnterImage}
          ref={imageRef}
        />
      </Group>
    );
  },
  (prev, next) => {
    if (prev.image !== next.image) return false;
    if (prev.brightness !== next.brightness) return false;
    if (prev.contrast !== next.contrast) return false;

    if (next.imageRef?.current?.isCached()) return true;
    return false;
  },
);

export default SuiteImage;
