import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CurveSendTo, LabelLogo, Sync, SyncProject } from '@superb-ai/icons';
import { Link } from '@superb-ai/norwegian-forest';
import {
  Box,
  Chip,
  Dialog,
  Icon,
  IconButton,
  LinkTypography,
  SelectList,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import { hasSufficientLabelVolume } from '../../../../../../queries/meteringLogic';
import { useMetering } from '../../../../../../queries/useMeteringQuery';
import { useActionContext } from '../../../../contexts/ActionContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../../elements/SampleDatasetTooltip';
import SendToLabelModal from '../modal/SendToLabelModal';
import AddToSyncedProjectDialog from '../modal/SyncWithLabel/AddToSyncedProjectDialog';
import SyncWithNewLabelProjectModal from '../modal/SyncWithLabel/SyncWithNewLabelProjectModal';
import { useSyncedLabelProjectsQuery } from '../settings/synedLabelProjectQueries';

export default function LinkLabelActionButton() {
  const { t } = useTranslation();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();

  const { selectedData, setSelectedAllData } = useActionContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { data } = useSyncedLabelProjectsQuery({ datasetId });

  const canUseLabel = hasSufficientLabelVolume(useMetering('label:data-volume'));
  const syncWithNewProjectDialog = useDialogState();
  const addToSyncedProjectDialog = useDialogState();

  const [sendToLabelIsOpen, setSendToLabelIsOpen] = useState<boolean>(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  function getSharedTooltipContent() {
    if (showPublicDatasets) return <SampleDatasetTooltip feature="sendToLabel" />;
    if (!canUseLabel)
      return (
        <Typography variant="m-regular">
          {t('curate.slices.sendToLabel.labelNotEnabled')}{' '}
          <Link to={`/${accountName}/label`}>
            <LinkTypography>{t('curate.button.learnMore')}</LinkTypography>
          </Link>
        </Typography>
      );
    return undefined;
  }

  const syncOptions = [
    {
      value: 'syncWithNewProject',
      label: (
        <Tooltip content={getSharedTooltipContent()} hideOnEmptyContent placement="left">
          <Dialog.Disclosure
            {...syncWithNewProjectDialog}
            onClick={() => setShowDropdown(false)}
            disabled={!canUseLabel || showPublicDatasets}
          >
            <Box display="flex" alignItems="center" gap={0.5}>
              <Icon icon={Sync} />
              {t('curate.button.syncWithNewProject')}
            </Box>
          </Dialog.Disclosure>
        </Tooltip>
      ),
      disabled: !canUseLabel || showPublicDatasets,
    },
    {
      value: 'addToSyncedProject',
      label: (
        <Tooltip
          content={
            getSharedTooltipContent() ||
            (data?.count === 0 && (
              <Typography variant="m-regular">
                <Trans t={t} i18nKey={'curate.slices.sendToLabel.noSyncedProject'} />
              </Typography>
            )) ||
            undefined
          }
          hideOnEmptyContent
          placement="left"
        >
          <Dialog.Disclosure
            {...addToSyncedProjectDialog}
            disabled={!canUseLabel || showPublicDatasets}
            onClick={() => setShowDropdown(false)}
          >
            <Box display="flex" alignItems="center" gap={0.5}>
              <Icon icon={SyncProject} />
              {t('curate.button.addToSyncedProject')}
            </Box>
          </Dialog.Disclosure>
        </Tooltip>
      ),
      disabled: !canUseLabel || showPublicDatasets || data?.count === 0,
    },
  ];

  const copyOptions = [
    {
      value: 'copyToLabel',
      label: (
        <Tooltip
          content={
            getSharedTooltipContent() ||
            (selectedData.length !== 0 && (
              <Typography variant="m-regular">
                <Trans t={t} i18nKey={'curate.slices.sendToLabel.onlyEntireSlice'} />
              </Typography>
            )) ||
            undefined
          }
          hideOnEmptyContent
          placement="left"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            onClick={() => {
              if (selectedData.length !== 0 || !canUseLabel || showPublicDatasets) return;
              setSendToLabelIsOpen(true);
              setSelectedAllData(true);
              setShowDropdown(false);
            }}
          >
            <Icon icon={CurveSendTo} />
            {t('curate.button.sendToLabel')}
          </Box>
        </Tooltip>
      ),
      disabled: selectedData.length !== 0 || !canUseLabel || showPublicDatasets,
    },
  ];

  // sliceInfo 없을 때 처리 필요
  return (
    <>
      <Box position="relative" ref={dropdownRef}>
        <Tooltip
          strategy="fixed"
          content={t('curate.button.group.syncOrCopyToLabel')}
          placement="top"
          hideOnEmptyContent
        >
          <div>
            <IconButton icon={LabelLogo} variant="text" onClick={() => setShowDropdown(true)} />
          </div>
        </Tooltip>
        {showDropdown && (
          <Box
            position="absolute"
            backgroundColor="white"
            boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
            style={{ zIndex: 1000, width: '200px' }}
          >
            <Box px={1.5} py={0.5} display="flex" alignItems="center">
              <Typography variant="s-regular" color="gray-300">
                {t('curate.button.group.syncToLabel')}
              </Typography>
              <Chip ml="auto" color="secondary">
                {t('text.recommended')}
              </Chip>
            </Box>
            <SelectList data={syncOptions} multiple={false} value={''} />
            <Box px={1.5} py={0.5} borderTop="1px solid" borderColor="gray-150">
              <Typography variant="s-regular" color="gray-300">
                {t('curate.button.group.copyImagesToLabel')}
              </Typography>
            </Box>
            <SelectList data={copyOptions} multiple={false} value={''} />
          </Box>
        )}
      </Box>

      <SendToLabelModal
        sendToLabelIsOpen={sendToLabelIsOpen}
        setSendToLabelIsOpen={setSendToLabelIsOpen}
      />

      <SyncWithNewLabelProjectModal dialog={syncWithNewProjectDialog} />
      <AddToSyncedProjectDialog dialog={addToSyncedProjectDialog} />
    </>
  );
}
