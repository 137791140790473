import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Box, Typography } from '@superb-ai/norwegian-forest';

import { useProjectInfo } from '../../../contexts/ProjectContext';
import { formatCount } from '../../../utils/numberFormat';

type Props = {
  className: string;
};

function RoleCount({ label, count }: { label: string; count: number }) {
  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography textAlign="center" variant="headline4" themedColor="primary">
        {formatCount(count)}
      </Typography>
      <Typography textAlign="center" variant="body4">
        {label}
      </Typography>
    </Box>
  );
}

const MemberRoles: React.FC<Props> = props => {
  const { className } = props;
  const projectInfo = useProjectInfo();
  const { owners, admins, managers, reviewers, workers } = projectInfo;
  const { t } = useTranslation();

  return (
    <MUI.Paper className={className}>
      <Typography variant="headline4">{t('overview.memberRoles.title')}</Typography>

      <Box
        display="grid"
        gridTemplateColumns={'1fr 1px 1fr 1px 1fr 1px 1fr'}
        alignItems="center"
        gap="8px"
        mt={4}
        mb={2}
      >
        <RoleCount label={t('projectMembers.role.admin')} count={owners.length + admins.length} />
        <Box themedBackgroundColor={['grey', 100]} width={1} height={30}></Box>
        <RoleCount label={t('projectMembers.role.manager')} count={managers.length} />
        <Box themedBackgroundColor={['grey', 100]} width={1} height={30}></Box>
        <RoleCount label={t('projectMembers.role.reviewer')} count={reviewers.length} />
        <Box themedBackgroundColor={['grey', 100]} width={1} height={30}></Box>
        <RoleCount label={t('projectMembers.role.labeler')} count={workers.length} />
      </Box>
    </MUI.Paper>
  );
};

export default MemberRoles;
