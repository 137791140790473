import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { ChevronLeft, ChevronRight, ErrorOutline, Expand } from '@superb-ai/icons';
import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  LoadingIndicator,
  Range,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import dynamic from 'next/dynamic';

import analyticsTracker from '../../../../analyticsTracker';
import { Row } from '../../../../components/elements/Row';
import { PixiDetailAnnotation } from '../../../../components/pages/aiAdvancedFeatures/Shapes';
import { useDatasetImageQueries } from '../../../Curate/queries/dataQueries';
import { AnnotationTypeCoordinate, Polygon } from '../../../Curate/types/annotationTypes';
import { useModelTrainingSamplePredictionsQueries } from '../../queries/modelQueries';
import { ModelStatus, ModelTrainingEpochs } from '../../services/types';
import SamplePredictionDialog from './SamplePredictionDialog';
import { annotationTypeResolver, classNameResolver } from './utils';

const containerHeight = 268;
const PixiDetailImage = dynamic({
  loader: () => import('./PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});
export const SamplePrediction = ({
  trainingEpochsData,
  dialogState,
  hasTrainingProcess,
  minEpoch,
  maxEpoch,
  status,
}: {
  trainingEpochsData: ModelTrainingEpochs;
  dialogState: ReturnType<typeof useDialogState>;
  hasTrainingProcess: boolean;
  minEpoch: number;
  maxEpoch: number;
  status: ModelStatus;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const [epochsRangeIndex, setEpochsRangeIndex] = useState(maxEpoch);
  const [datasetImageIndex, setDatasetImageIndex] = useState(0);
  const datasetImagesQueries = useDatasetImageQueries({
    datasetId: trainingEpochsData.referenceId,
    imageIds: trainingEpochsData.samplePredictionImageIds ?? [],
    expand: ['annotations'],
  });
  const presignedUrl = trainingEpochsData.modelTrainingEpochs.map(epoch => epoch.presignedUrl);
  const samplePredictionsQueries = useModelTrainingSamplePredictionsQueries({ urls: presignedUrl });

  const samplePredictions = samplePredictionsQueries[epochsRangeIndex - 1];
  if (!samplePredictions || !hasTrainingProcess) {
    return <BeforeOneEpoch />;
  }

  const categories = samplePredictions.data?.categories;
  if (!categories) {
    return <BeforeOneEpoch />;
  }

  const datasetImages = datasetImagesQueries[datasetImageIndex];
  const { data: datasetImageInfo, isLoading: isDatasetImagesLoading } = datasetImages;
  const { data: samplePredictionData, isLoading: isSamplePredictionLoading } = samplePredictions;

  const imageId =
    samplePredictionData?.images.find(image => image?.label_id === datasetImageInfo?.id)?.id ?? NaN;

  const samplePredictionAnnotations = () => {
    if (!datasetImageInfo || !samplePredictionData) {
      return [];
    }
    const categories = samplePredictionData.categories;
    return samplePredictionData.annotations
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, categories);
        return {
          type: annotationType,
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : (anno.segmentation as AnnotationTypeCoordinate<Polygon>['points']),
          id: anno.id,
          className: classNameResolver(anno, categories),
          color: '#FF625A',
        };
      }) as PixiDetailAnnotation[];
  };

  // 로딩 중일 때: 로딩 인디케이터
  if (
    datasetImagesQueries[datasetImageIndex].isLoading ||
    isSamplePredictionLoading ||
    isDatasetImagesLoading
  ) {
    return (
      <Row
        backgroundColor={'gray-100'}
        flexDirection="column"
        justifyContent="center"
        style={{ height: containerHeight }}
      >
        <LoadingIndicator />
      </Row>
    );
  }
  // 에폭이 돌지 않았을 때: Before
  if (trainingEpochsData.modelTrainingEpochs.length === 0) {
    return <BeforeOneEpoch />;
  }

  const datasetImage = datasetImagesQueries[datasetImageIndex].data;
  if (!datasetImage || !datasetImageInfo) {
    return <NotFoundImage />;
  }

  return (
    <Box>
      <Box style={{ height: 308 }}>
        <Box backgroundColor={'gray-100'} position="relative" style={{ height: containerHeight }}>
          <PixiDetailImage
            imgUrl={datasetImageInfo.image_url}
            samplePredictionAnnotations={samplePredictionAnnotations()}
          />
          <Box
            position="absolute"
            left={'0'}
            top={'0'}
            bottom={'0'}
            right={'0'}
            // onClick={toggleSelect}
          >
            <Box
              backgroundColor={'gray-opacity-400'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              style={{ borderRadius: 36, height: 24, width: 72, right: 8, top: 8 }}
            >
              <Icon
                icon={ChevronLeft}
                color={datasetImageIndex === 0 ? 'cloud' : 'white'}
                cursor="pointer"
                size={12}
                onClick={() =>
                  setDatasetImageIndex(prev => {
                    if (prev === 0) {
                      return prev;
                    } else {
                      return prev - 1;
                    }
                  })
                }
              />
              <Typography variant="s-regular" color="white" mx={0.5}>
                {datasetImageIndex + 1} / {datasetImagesQueries.length}
              </Typography>
              <Icon
                icon={ChevronRight}
                cursor={'pointer'}
                color={datasetImageIndex === datasetImagesQueries.length - 1 ? 'cloud' : 'white'}
                size={12}
                onClick={() =>
                  setDatasetImageIndex(prev => {
                    if (prev === datasetImagesQueries.length - 1) {
                      return prev;
                    } else {
                      return prev + 1;
                    }
                  })
                }
              />
            </Box>
            <Box position="absolute" style={{ bottom: 4, right: 4 }} ml="auto">
              <Button
                variant="stroke"
                color="primary"
                size="s"
                onClick={e => {
                  analyticsTracker.modelDetailClicked({
                    accountId: params.accountName,
                    clickedButton: 'click-progress-sample-prediction-detail',
                    modelStatus: status,
                  });
                  dialogState.show();
                }}
              >
                <Icon icon={Expand} />
                {t('model.myModelDetail.samplePrediction.viewDetail')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Row width="100%" px={1.5} py={1} style={{ height: 40 }}>
          <Row style={{ width: 'max-content' }}>
            <Typography style={{ width: 'max-content' }} variant="m-medium">
              {t('model.myModelDetail.epochs')}
            </Typography>
          </Row>
          <Box mx={1} style={{ width: '100%' }}>
            <Range
              min={minEpoch}
              max={maxEpoch}
              value={epochsRangeIndex}
              onChange={e => setEpochsRangeIndex(Number(e.target.value))}
            />
          </Box>
          <IconButton
            icon={ChevronLeft}
            size="s"
            variant="text"
            disabled={epochsRangeIndex === minEpoch}
            onClick={() => {
              setEpochsRangeIndex(prev => {
                if (prev === minEpoch) {
                  return prev;
                } else {
                  return prev - 1;
                }
              });
            }}
          />
          <Row height="100%" style={{ width: 36 }}>
            <Input
              size="s"
              value={epochsRangeIndex}
              onChange={e => {
                const nextValue = Number(e.target.value);
                if (Number.isNaN(nextValue)) {
                  return;
                }
                if (nextValue < minEpoch || nextValue > maxEpoch) {
                  return;
                }
                setEpochsRangeIndex(nextValue);
              }}
              onFocus={e => {
                e.target.select();
              }}
              style={{ width: '100%', height: '100%' }}
            />
          </Row>
          <IconButton
            size="s"
            variant="text"
            icon={ChevronRight}
            disabled={epochsRangeIndex === maxEpoch}
            onClick={() => {
              setEpochsRangeIndex(prev => {
                if (prev === maxEpoch) {
                  return prev;
                } else {
                  return prev + 1;
                }
              });
            }}
          />
        </Row>
      </Box>

      {dialogState.visible && (
        <SamplePredictionDialog
          trainingEpochsData={trainingEpochsData}
          state={dialogState}
          datasetImages={datasetImages}
          datasetImagesLength={datasetImagesQueries.length}
          samplePredictions={samplePredictions}
          epochsRangeIndex={epochsRangeIndex}
          setEpochsRangeIndex={setEpochsRangeIndex}
          datasetImageIndex={datasetImageIndex}
          setDatasetImageIndex={setDatasetImageIndex}
          minEpoch={minEpoch}
          maxEpoch={maxEpoch}
          imageId={imageId}
        />
      )}
    </Box>
  );
};

const BeforeOneEpoch = () => {
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      backgroundColor={'gray-100'}
      borderRadius="2px"
      border={'1px solid'}
      borderColor={'gray-200'}
      style={{ height: 308 }}
    >
      <Typography variant="m-regular" color="gray-300">
        <Trans t={t} i18nKey={'model.myModelDetail.samplePrediction.beforeOneEpoch'} />
      </Typography>
    </Box>
  );
};

const NotFoundImage = () => {
  const { t } = useTranslation();
  return (
    <Row
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      gap={1.5}
      backgroundColor={'gray-100'}
      style={{ height: containerHeight }}
    >
      <Icon icon={ErrorOutline} color="red-400" size={32} />
      <Typography variant="m-regular">{t('model.myModelDetail.deletedImage')}</Typography>
    </Row>
  );
};
