import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card } from '@superb-ai/norwegian-forest';
import { Box, Paragraph, Typography } from '@superb-ai/ui';

import EmptyPlot from '../elements/EmptyPlot';
import { PrecisionRecallChart } from './PrecisionRecallChart';
import { ComparisonReport } from './types/types';
import { heatmapDataFormatter } from './utils/formatter';
import { guideMessage, hasZeroAnnotations, metricsTransformer } from './utils/helper';

interface Props {
  selectedVersion: ComparisonReport;
}

const PrecisionRecallContainer = ({ selectedVersion }: Props) => {
  const { t } = useTranslation();
  const rowData = metricsTransformer(
    selectedVersion,
    t('analytics.exportsAnalytics.report.sheetName.metrics'),
  ).data;
  const isDataEmpty = hasZeroAnnotations(selectedVersion?.info?.project?.classesCounts || []);
  const heatmapData = heatmapDataFormatter(rowData, t);

  return (
    <Card mt={3}>
      <Box p={3} borderBottom="1px solid" borderColor="gray-100">
        <Typography variant="h3">{t('analytics.exportsAnalytics.heatmap.title')}</Typography>
      </Box>
      <Box p={3} display="flex" position="relative" style={{ backgroundColor: '#FBFBFB' }}>
        <Box style={{ width: '50%', maxWidth: '400px' }}>
          <Paragraph variant="m-strong">
            ⭐️ {t('analytics.exportsAnalytics.heatmap.explanationTitle')}
          </Paragraph>
          <Paragraph variant="m-regular">
            <Trans t={t} i18nKey="analytics.exportsAnalytics.heatmap.explanationText" />
          </Paragraph>
        </Box>
        <Box style={{ flex: 1 }} position="relative">
          <PrecisionRecallChart data={heatmapData} numColumns={['precision', 'recall'].length} />
        </Box>
        {isDataEmpty && (
          <EmptyPlot
            message={t(guideMessage(selectedVersion))}
            svgHeight={rowData.length * 35}
            marginTop={20}
          />
        )}
      </Box>
    </Card>
  );
};

export default PrecisionRecallContainer;
