export type TodoList =
  | 'uploadNewData'
  | 'assignExistingData'
  | 'uploadALabelingGuideline'
  | 'inviteCollaborators';

type TodoListUrls = Record<TodoList, string>;

export default (accountName: string, projectId: string): TodoListUrls => ({
  uploadNewData: `/${accountName}/label/project/${projectId}`,
  assignExistingData: `/${accountName}/label/data`,
  uploadALabelingGuideline: `/${accountName}/label/project/${projectId}/overview?guideline_uploader=open`,
  inviteCollaborators: `/${accountName}/label/project/${projectId}/members`,
});
