import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findIndex } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  COMMENT_NOT_FOUND,
  ISSUE_NOT_FOUND,
  LABEL_NOT_FOUND,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { DetailViewProvider } from '../../../../contexts/DetailViewContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import IssuesService from '../../../../services/IssuesService';
import LabelsService from '../../../../services/LabelsService';
import WorkappUnion from '../../../../union/WorkappUnion';
import LabelUtils from '../../../../utils/LabelUtils';
import { convertHashToObject } from '../../../../utils/SpbUtils';
import DetailView from '../../../elements/detailView';
import Layout from './Layout';

const Label: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const labelsInfo = useLabelsInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelIssuesInfo = useLabelIssues();

  const { getLabels, setIsDesc, setOrderBy } = labelsInfo;

  const {
    hash,
    history: {
      location: { search, pathname, state },
    },
  } = routeInfo;
  const { project, loaded: projectInfoLoaded } = projectInfo;

  const {
    label,
    setLabel,
    setIndex,
    isOpen,
    setIsOpen,
    setIsAutoLabelUncertainty,
    setIsActiveAutoLabelUncertainPanel,
    setAutoLabelUncertaintyCount,
  } = labelDetailViewInfo;

  const {
    setClickedAnnotationInfo,
    setHoveredAnnotation,
    setCabinetTabState,
    updateLabelInformation,
  } = labelInformationInfo;

  const {
    issues,
    setIssues,
    setIssueViewStatus,
    issuePanelState,
    setIssuePanelState,
    setOpenBadgeInfo,
    setEmitSelectedThreadId,
    setFocusedCommentId,
  } = labelIssuesInfo;

  const [beforeSearch, setBeforeSearch] = useState<string | null>(null);
  const [beforeState, setBeforeState] = useState<unknown | undefined>();
  const [beforeHash, setBeforeHash] = useState('');
  const [beforeLabelId, setBeforeLabelId] = useState<string | null>('');

  const closeThread = () => {
    setIssuePanelState(null);
    setOpenBadgeInfo({ issue: null, ref: null });
  };

  const closeDetailView = () => {
    if (issuePanelState) {
      closeThread();
    }

    setIsOpen(false);
    labelDetailViewInfo.setIsLoading(true);
    setIndex(null);
    setLabel(null);
    setIssues([]);
    setClickedAnnotationInfo(null);
    setHoveredAnnotation(null);
    setCabinetTabState('information');
    setBeforeLabelId(null);
    setIsAutoLabelUncertainty(false);
    setAutoLabelUncertaintyCount(-1);
    setIsActiveAutoLabelUncertainPanel(false);
  };

  const openThread = async (
    threadId: string,
    commentId: string,
    threadState: any,
    issues: any[],
  ) => {
    setCabinetTabState('issue');
    const nextIssueIndex = findIndex(issues, issue => issue.id === threadId);

    if (nextIssueIndex === -1) {
      enqueueSnackbar(ISSUE_NOT_FOUND({ t }), { variant: 'error' });
      return;
    }

    const nextIssue = issues[nextIssueIndex];
    const { status } = nextIssue;

    setIssuePanelState(threadState);
    setIssueViewStatus(status);
    setEmitSelectedThreadId(threadId);

    // comment
    if (commentId) {
      const { issueComments } = nextIssue;
      const nextCommentIndex = findIndex(issueComments, (comment: any) => comment.id === commentId);

      if (nextCommentIndex === -1) {
        enqueueSnackbar(COMMENT_NOT_FOUND({ t }), { variant: 'error' });
        return;
      }

      setFocusedCommentId(commentId);
    }
  };

  const openDetailView = async (projectId: string, nextLabels: any[], hash: string) => {
    const { labelId, threadId, commentId, threadState } = convertHashToObject(hash);
    const labelChanged = labelId !== beforeLabelId;
    let nextLabel;
    let nextIssues;

    if (labelChanged) {
      setClickedAnnotationInfo(null);
      setHoveredAnnotation(null);

      const nextLabelIndex = findIndex(nextLabels, label => label.id === labelId);
      if (nextLabelIndex === -1) {
        enqueueSnackbar(LABEL_NOT_FOUND({ t }), { variant: 'error' });

        routeInfo.history.push(`${pathname}${search}`);
        return;
      }

      // open
      labelDetailViewInfo.setIsLoading(true);
      if (!isOpen) setIsOpen(true);

      // 1. get label
      nextLabel = await LabelsService.getLabel({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: nextLabels[nextLabelIndex].id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setLabel(nextLabel);
      setIndex(nextLabelIndex);
      const resultOfGetIsAutoLabelUncertaintyInfo =
        LabelUtils.getIsAutoLabelUncertaintyInfo(nextLabel);
      setIsAutoLabelUncertainty(resultOfGetIsAutoLabelUncertaintyInfo.isAutoLabelUncertainty);
      setAutoLabelUncertaintyCount(resultOfGetIsAutoLabelUncertaintyInfo.autoLabelUncertaintyCount);
      setIsActiveAutoLabelUncertainPanel(
        resultOfGetIsAutoLabelUncertaintyInfo.isAutoLabelUncertainty,
      );

      updateLabelInformation(nextLabel, project.workapp);
      setBeforeLabelId(labelId);
    } else {
      nextLabel = label;
      nextIssues = issues;
    }

    if (!authInfo.isGuest) {
      nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: nextLabel.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
    }

    labelDetailViewInfo.setIsLoading(false);

    if (!threadId || authInfo.isGuest) {
      closeThread();
    } else {
      await openThread(threadId, commentId, threadState, nextIssues);
    }
  };

  useEffect(() => {
    // Need to wait for projectInfo to perform search (e.g. using tag ids)
    if (!projectInfoLoaded || project.id !== routeInfo.urlMatchInfo.projectId) return;
    (async () => {
      let nextLabels = [] as any[];
      let nextIsDesc;
      let nextOrderBy = '';

      if (routeInfo?.params?.ordering) {
        const splittedOrdering = routeInfo.params.ordering.split('-');
        if (splittedOrdering.length === 1) {
          nextIsDesc = false;
          nextOrderBy = routeInfo.params.ordering;
        } else {
          nextIsDesc = true;
          // eslint-disable-next-line
          nextOrderBy = splittedOrdering[1];
        }
      }

      setIsDesc(nextIsDesc as boolean);
      setOrderBy(nextOrderBy);

      if (search !== beforeSearch || state !== beforeState) {
        setBeforeSearch(search);
        setBeforeState(state);
        const response = await getLabels();
        nextLabels = response?.labels;
      } else {
        nextLabels = labelsInfo.labels;
      }

      // 다른 프로젝트에서 noti를 눌러서 올 경우 - (2/2)
      if (!nextLabels || nextLabels.length === 0) {
        if (!hash) {
          closeDetailView();
        }
        return;
      }

      if (hash !== beforeHash) {
        setBeforeHash(hash);
        if (hash && WorkappUnion.isImageDefault(project.workapp)) {
          await openDetailView(routeInfo.urlMatchInfo.projectId, nextLabels, hash);
        } else {
          closeDetailView();
        }
      }
    })();

    // eslint-disable-next-line
  }, [search, hash, state, project, projectInfoLoaded]);

  return (
    <DetailViewProvider>
      <Layout />
      <DetailView />
    </DetailViewProvider>
  );
};

export default Label;
