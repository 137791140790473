import { useTranslation } from 'react-i18next';

import {
  QueryObserverOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { isBoolean } from 'lodash';
import { useSnackbar } from 'notistack';

import { DiagnosisStatusEnum } from '../components/datasets/dataset/modelDiagnosis/diagnosis/types';
import { QUERY_KEY } from '../const/QueryKey';
import { CurateCommandContext } from '../contexts/CommandContext';
import { JobInitiatedTimestamp } from '../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateCommandsService } from '../services/CommandsService';
import {
  DiagnosisDependencies,
  DiagnosisDetail,
  DiagnosisDetailExpandOption,
  DiagnosisListExpandOption,
  useDiagnosisModelService,
} from '../services/DiagnosisModelService';
import {
  Command,
  DeactivateDiagnosisJobParam,
  DiagnosisStartOrUpdateJobParam,
  START_DIAGNOSIS,
  UPDATE_DIAGNOSIS,
} from '../types/commandTypes';
import { KeysToCamelCase } from '../types/typeUtils';

export function useDiagnosisSchema(dependencies: Required<Omit<DiagnosisDependencies, 'filter'>>) {
  const { datasetId, fromPublicDatasets, diagnosisId } = dependencies;
  const { getDiagnosisSchema } = useDiagnosisModelService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [QUERY_KEY.diagnosisMetadataClassList, datasetId, diagnosisId],
    queryFn: async () => {
      return await getDiagnosisSchema({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
      });
    },
    enabled: !!datasetId && !!diagnosisId,
    refetchOnWindowFocus: false,
  });
}

export function useDiagnosisDetailQuery(dependencies: {
  datasetId?: string;
  fromPublicDatasets?: boolean;
  diagnosisId?: string;
  expand?: DiagnosisDetailExpandOption[];
  handleSuccess?: (data: DiagnosisDetail) => void;
  handleError?: (error: any) => void;
  diagnosisStatus?: DiagnosisStatusEnum;
  queryRefetchOptions?: Pick<
    QueryObserverOptions,
    'refetchOnMount' & 'refetchOnWindowFocus' & 'refetchInterval'
  >;
}) {
  const {
    datasetId,
    diagnosisId,
    fromPublicDatasets,
    handleSuccess,
    handleError,
    diagnosisStatus,
    queryRefetchOptions,
    expand,
  } = dependencies;
  const defaultExpand =
    diagnosisStatus === DiagnosisStatusEnum.ACTIVE
      ? (['updated_image_count', 'deleted_image_count'] as DiagnosisDetailExpandOption[])
      : undefined;
  const { getDiagnosisDetail } = useDiagnosisModelService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [QUERY_KEY.diagnosisDetail, datasetId, diagnosisId || '', expand?.join(', ') || ''],
    queryFn: async () => {
      if (!diagnosisId || !datasetId) return;
      return await getDiagnosisDetail({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
        expand: [...(defaultExpand || []), ...(expand || [])],
      });
    },
    enabled: !!(datasetId && diagnosisId),
    onSuccess: data => {
      if (!data) return;
      handleSuccess && handleSuccess(data);
    },
    onError: err => {
      handleError && handleError(err);
    },
    ...queryRefetchOptions,
  });
}

export function useDiagnosisListQuery(dependencies: {
  datasetId?: string;
  fromPublicDatasets?: boolean;
  size?: number;
  queryRefetchOptions?: Pick<
    QueryObserverOptions,
    'refetchOnMount' & 'refetchOnWindowFocus' & 'refetchInterval'
  >;
  expand?: DiagnosisListExpandOption[];
}) {
  const { datasetId, fromPublicDatasets, size = 10, queryRefetchOptions, expand } = dependencies;
  const { getDiagnosisList } = useDiagnosisModelService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.diagnosisList, datasetId],
    queryFn: async ({ pageParam }) => {
      if (!datasetId) throw new Error('datasetId is required');
      const result = await getDiagnosisList({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        page: pageParam,
        size,
        expand,
      });
      return result;
    },
    getNextPageParam: (response, pages) => {
      const count = response?.count || 0;
      return count > pages.length * size ? pages.length + 1 : undefined;
    },
    enabled: !!datasetId,
    ...queryRefetchOptions,
  });
}

export type StartOrUpdateDiagnosisFnParams = KeysToCamelCase<DiagnosisStartOrUpdateJobParam> & {
  jobName: 'START_DIAGNOSIS' | 'UPDATE_DIAGNOSIS';
  createdAt: string;
};

export function useStartOrUpdateDiagnosisMutation(commandContext: CurateCommandContext) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { postJob } = useCurateCommandsService();
  const postFn = async (params: StartOrUpdateDiagnosisFnParams) => {
    const jobParams = {
      dataset_id: params.datasetId,
      model_id: params.modelId,
      diagnosis_id: params.diagnosisId,
      annotation_type: params.annotationType,
      model_source: params.modelSource,
      skip_train: params.skipTrain,
    };
    const jobResult = await postJob({
      job_type: params.jobName,
      param: jobParams,
    });
    return { jobResult, jobParams };
  };
  return useMutation({
    mutationFn: (params: StartOrUpdateDiagnosisFnParams) => {
      return postFn(params);
    },
    onSuccess: async (data, params) => {
      if (data && data?.jobResult?.id) {
        await commandContext.registerCommand(data.jobResult.id);
      }
    },
    onError(error: { message: string }) {
      if (error.message === 'Duplicated') {
        enqueueSnackbar(t('curate.embeddings.button.updateDuplicatedMessage'), {
          variant: 'info',
        });
      }
    },
  });
}

export type DeactivateDiagnosisFnParams = KeysToCamelCase<DeactivateDiagnosisJobParam>;
export function useDeactivateDiagnosisMutation(commandContext: CurateCommandContext) {
  const { postJob } = useCurateCommandsService();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const postFn = async (params: DeactivateDiagnosisFnParams) => {
    const { datasetId, diagnosisId, modelId, deleteDiagnosis } = params;
    const jobParams = {
      dataset_id: datasetId,
      diagnosis_id: diagnosisId,
      delete_diagnosis: deleteDiagnosis,
      model_id: modelId,
    };
    const jobName = 'DEACTIVATE_DIAGNOSIS';
    const jobResult = await postJob({
      job_type: jobName,
      param: jobParams,
    });
    return { jobResult, jobParams };
  };
  return useMutation({
    mutationFn: async (params: DeactivateDiagnosisFnParams) => await postFn(params),
    onSuccess: async (data, params) => {
      if (data && data?.jobResult?.id) {
        // await commandContext.registerCommand(data.jobResult.id);
      }
    },
    onError(error: { message: string }) {
      if (error.message === 'Duplicated') {
        enqueueSnackbar(t('curate.embeddings.button.updateDuplicatedMessage'), {
          variant: 'info',
        });
      }
    },
  });
}

export function useLatestStartOrUpdateDiagnosisJob(dependencies: {
  diagnosisId: string;
  jobInitiatedTimestamp?: JobInitiatedTimestamp;
  currentStatus?: DiagnosisStatusEnum;
  handleSuccess?: (data: Command<typeof START_DIAGNOSIS | typeof UPDATE_DIAGNOSIS>) => void;
}) {
  const { diagnosisId, jobInitiatedTimestamp, handleSuccess, currentStatus } = dependencies;
  const { getLatestStartDiagnosisJob, getLatestUpdateDiagnosisJob } = useDiagnosisModelService();
  return useQuery({
    queryKey: [QUERY_KEY.diagnosisStartOrUpdateDiagnosisJob, diagnosisId],
    queryFn: async () => {
      if (
        currentStatus === DiagnosisStatusEnum.ACTIVATING ||
        jobInitiatedTimestamp?.jobType === 'START_DIAGNOSIS'
      ) {
        const startJob = await getLatestStartDiagnosisJob({ diagnosisId });
        return startJob;
      }
      if (
        currentStatus === DiagnosisStatusEnum.UPDATING ||
        jobInitiatedTimestamp?.jobType === 'UPDATE_DIAGNOSIS'
      ) {
        const updateJob = await getLatestUpdateDiagnosisJob({ diagnosisId });
        return updateJob;
      }
      return null;
    },
    refetchInterval: data => {
      const createdAtTimestamp = data ? new Date(data.createdAt).getTime() : 0;
      return data?.status === 'IN_PROGRESS' ||
        data?.status === 'PENDING' ||
        (jobInitiatedTimestamp && jobInitiatedTimestamp.timeStamp > createdAtTimestamp)
        ? 5000
        : false;
    },
    retry: false,
    enabled: !!diagnosisId,
    onSuccess: data => {
      if (!data) return;
      handleSuccess && handleSuccess(data);
    },
  });
}
