import { Box } from '@superb-ai/ui';

import { isOwner, useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import TierUnion from '../../../../union/TierUnion';
import IpWhitelist from './IpWhitelist';
import Mfa from './Mfa';
import SecurityTutorial from './SecurityTutorial';

export default function SecurityLayout() {
  const authInfo = useAuthInfo();
  const mfaFlag = useFeatureFlag('mfa');
  const ipWhitelistFlag = useFeatureFlag('ipWhitelist');
  const isEnterprise = TierUnion.isEnterprise(authInfo.tier);
  const owner = isOwner(authInfo);

  if (!isEnterprise || (!mfaFlag && !ipWhitelistFlag)) {
    return <SecurityTutorial isEnterprise={isEnterprise} />;
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6}>
        {mfaFlag && <Mfa />}
        {isEnterprise && owner && ipWhitelistFlag && <IpWhitelist />}
      </Box>
    </>
  );
}
