import { TFunction } from 'i18next';

import { AnnotationChangeType } from '../types/types';

export const changeTypesOrder = ['added', 'deleted', 'edited', 'changedClass', 'noChange'];

export function getChangeTypeDisplayName(changeType: AnnotationChangeType, t: TFunction): string {
  const displayNames = {
    added: t('analytics.exportsAnalytics.added'),
    deleted: t('analytics.exportsAnalytics.deleted'),
    changedClass: t('analytics.exportsAnalytics.fixedClass'),
    edited: t('analytics.exportsAnalytics.edited'),
    noChange: t('analytics.exportsAnalytics.noChange'),
  };
  return displayNames[changeType as keyof typeof displayNames];
}

export const orderedAnnotationChangeTypes = changeTypesOrder.map((changeType, index) => {
  return { index: String(index), name: changeType };
});
