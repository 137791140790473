import { CustomTooltipProps } from '@elastic/charts';

export default function CustomTooltip({ values }: CustomTooltipProps) {
  return (
    <div
      style={{
        backgroundColor: '#101010D8',
        fontSize: '12px',
        padding: '8px',
        lineHeight: '1.5',
        color: 'white',
        width: '100%',
      }}
    >
      <>
        {values.map(({ formattedValue, label }, i) => (
          <div key={i}>
            {label && `${label}:`} {formattedValue}
            <br />
          </div>
        ))}
      </>
    </div>
  );
}
