import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/ui';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import cubeDefault from '../assets/3d_cube_default_size.png';
import helper from './helper';

export function ClassPropertyDefaultSize3DCuboid(props: any) {
  const { t } = useTranslation();
  const { selectedObjectClass } = props;
  const newProjectInfo = useNewProjectInfo();
  const { x, y, z } = selectedObjectClass?.config?.defaultSize || { x: 1, y: 1, z: 1 };
  const [defaultSize, setDefaultSize] = useState({
    x,
    y,
    z,
  });

  useEffect(() => {
    setDefaultSize({
      x,
      y,
      z,
    });
  }, [selectedObjectClass.id]);

  const handleChangeInput = (axis: 'x' | 'y' | 'z') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedObjectClass) return;
    const parsedValue = e.target.value;

    const nextDefaultValue = {
      ...defaultSize,
      [axis]: parsedValue,
    };

    setDefaultSize(nextDefaultValue);
    helper.changeDefaultSize(newProjectInfo, nextDefaultValue);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
      borderRadius="2px"
      style={{
        height: '94px',
      }}
      boxSizing="border-box"
    >
      <Box display="flex" flexDirection="column" p={2} style={{ flex: 1 }} gap={1.5}>
        <Box display="flex" gap={1}>
          <Typography variant="m-regular">{t('projects.createProject.3dcuboidSetting')}</Typography>
          <Typography variant="m-regular" color="gray-300">
            ({t('projects.createProject.unit')} : {t('projects.createProject.meter')})
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box style={{ width: '120px' }}>
            <Input
              prefix="X"
              type="number"
              value={defaultSize.x}
              onChange={handleChangeInput('x')}
              onKeyDown={e => e.stopPropagation()}
              size="s"
            />
          </Box>
          <Box style={{ width: '120px' }}>
            <Input
              prefix="Y"
              type="number"
              value={defaultSize.y}
              onChange={handleChangeInput('y')}
              onKeyDown={e => e.stopPropagation()}
              size="s"
            />
          </Box>
          <Box style={{ width: '120px' }}>
            <Input
              prefix="Z"
              type="number"
              value={defaultSize.z}
              onChange={handleChangeInput('z')}
              onKeyDown={e => e.stopPropagation()}
              size="s"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" style={{ width: '174px' }}>
        <img
          src={cubeDefault.src}
          width="100%"
          height="100%"
          style={{
            width: '174px',
            height: '94px',
          }}
        />
      </Box>
    </Box>
  );
}
