import { TFunction } from 'i18next';

import { Box, Card, Icon, Typography } from '@superb-ai/norwegian-forest';
import { Trans } from 'react-i18next';

export function InfoCard({ t, i18nKey }: { t: TFunction; i18nKey: string }) {
  return (
    <Card
      style={{
        background: '#F8F8FF',
        padding: '12px',
        marginTop: '16px',
        boxShadow: 'none',
        display: 'flex',
      }}
    >
      <Box mr={0.5}>
        <Icon name="infoFilled" size="13px" color={['secondary', 600]} />
      </Box>
      <Box>
        <Typography variant="body4" themedColor={['secondary', 600]}>
          <Trans t={t} i18nKey={i18nKey} />
        </Typography>
      </Box>
    </Card>
  );
}
