export const COMPARED_POINT_LEGEND_COLOR = '#949494';
export const BASE_POINT_LEGEND_COLOR = '#949494';

export const DEFAULT_POINT_COLOR = 0xc4c4c4;
export const DEFAULT_BACKGROUND_POINT_COLOR = 0x949494;

// opacity
export const SELECTED_GROUP_POINT_FILL_ALPHA = 1;
export const HOVERED_GROUP_POINT_FILL_ALPHA = 0.8;
export const DEFAULT_GROUP_POINT_FILL_ALPHA = 1;
export const NON_HOVERED_GROUP_POINT_FILL_ALPHA = 0.25;
export const NON_SELECTED_CLASS_POINT_FILL_ALPHA = 0.2;

export const SUPER_CLUSTER_COLORS_8 = [
  '#A20000',
  '#FF4242',
  '#458A00',
  '#69D300',
  '#002BA7',
  '#2F6AFF',
  '#8C6D00',
  '#F7C100',
];

export const SUPER_CLUSTER_COLORS_16 = [
  '#A20000',
  '#FF4242',
  '#FF8989',
  '#FFA8A8',
  '#458A00',
  '#69D300',
  '#97ED41',
  '#CBF3A3',
  '#002BA7',
  '#2F6AFF',
  '#6D97FF',
  '#A6BFFF',
  '#8C6D00',
  '#F7C100',
  '#FFD74A',
  '#F7DF8B',
];

export const SCATTER_SORTED_COLORS = [
  '#FF6666',
  '#3366FF',
  '#66CC00',
  '#9900CC',
  '#CC0000',
  '#FF9900',
  '#CC66FF',
  '#336600',
  '#CCCC00',
  '#CC3300',
  '#3300FF',
  '#CC9900',
  '#669999',
  '#CC3399',
  '#FF3300',
  '#33CCFF',
  '#FF9966',
  '#6600FF',
  '#33FF33',
  '#FF6699',
  '#666666',
  '#CC99CC',
  '#006633',
  '#99CCCC',
  '#330099',
  '#6666CC',
  '#99FF00',
  '#33CC99',
  '#990033',
  '#9999CC',
  '#CCCC66',
  '#FF9999',
  '#00CC66',
  '#999900',
  '#9933CC',
  '#993399',
  '#66FFFF',
  '#666600',
  '#9900CC',
  '#CC0066',
  '#000066',
  '#66CC99',
  '#CC0033',
  '#00CCCC',
  '#660066',
  '#9966CC',
  '#66FF99',
  '#339900',
  '#FF66FF',
  '#CC9999',
  '#FF33CC',
  '#33FF66',
  '#CC3333',
  '#99CC00',
  '#336600',
  '#666633',
  '#0033FF',
  '#3333CC',
  '#996633',
  '#6600CC',
  '#33CCCC',
  '#6600FF',
  '#FF9933',
  '#663399',
  '#00FF33',
  '#66FF33',
  '#6699FF',
  '#CC33FF',
  '#660000',
  '#330000',
  '#000000',
  '#336633',
  '#3300CC',
  '#CC6666',
  '#CC9933',
  '#00FF00',
  '#3300FF',
  '#669966',
  '#996600',
  '#003366',
  '#FF3333',
  '#66CC66',
  '#66FFCC',
  '#FF3300',
  '#003399',
  '#FFCC33',
  '#CC6600',
  '#000099',
  '#CC99FF',
  '#FF6666',
  '#33CC66',
  '#33CCFF',
  '#0033CC',
  '#996699',
  '#FF33FF',
  '#0099FF',
  '#003300',
  '#999999',
  '#FF9900',
  '#FF0066',
  '#0066CC',
  '#0099CC',
  '#33FF99',
  '#CC6699',
  '#99CC33',
  '#33CC33',
  '#0000FF',
  '#66FF66',
  '#993333',
  '#339933',
  '#333333',
  '#990000',
  '#FF00FF',
  '#FF9966',
  '#99CC99',
  '#0000CC',
  '#FF3366',
  '#333366',
  '#669900',
  '#9900FF',
  '#333300',
  '#FF6633',
  '#669933',
  '#660033',
  '#FF0099',
  '#CCCC33',
  '#3399CC',
  '#3366FF',
  '#CC00CC',
  '#336666',
  '#330033',
  '#66CCCC',
  '#003333',
  '#3366CC',
  '#009999',
  '#CC3366',
  '#990066',
  '#3333FF',
  '#33CC00',
  '#00FF66',
  '#009933',
  '#009900',
  '#CC6633',
  '#CCCC99',
  '#CC0000',
  '#339966',
  '#CC00FF',
  '#99CCFF',
  '#00CC33',
  '#339999',
  '#CC9966',
  '#009966',
  '#00CC00',
  '#00FFFF',
  '#006666',
  '#6699CC',
  '#666699',
  '#66CC00',
  '#99FFFF',
  '#6633CC',
  '#330066',
  '#FF99CC',
  '#FF99FF',
  '#FF0000',
  '#336699',
  '#993300',
  '#99FF66',
  '#CC3300',
  '#33FF00',
  '#FF0033',
  '#33FFCC',
  '#FFCC99',
  '#6633FF',
  '#00CCFF',
  '#CCFF99',
  '#66CCFF',
  '#00CC99',
  '#FFFF00',
  '#CC9900',
  '#00FFCC',
  '#FF3399',
  '#9933FF',
  '#999966',
  '#9999FF',
  '#CCFF00',
  '#663366',
  '#FF66CC',
  '#00FF99',
  '#FFCCCC',
  '#FFCC66',
  '#CC33CC',
  '#3399FF',
  '#66CC33',
  '#66FF00',
  '#CC0099',
  '#993366',
  '#006600',
  '#9966FF',
  '#CC66CC',
  '#333399',
  '#996666',
  '#6666FF',
  '#0066FF',
  '#FF00CC',
  '#660099',
  '#FF6600',
  '#33FFFF',
  '#663300',
  '#663333',
  '#99CC66',
  '#000033',
  '#990099',
  '#006699',
  '#669999',
  '#999933',
  '#CC3399',
  '#CC66FF',
];
