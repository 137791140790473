import React, { useCallback, useMemo, useState } from 'react';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { atoms } from '@superb-ai/ui';

import { InfiniteWindowedGridProps } from './types';

function GridCell({
  columnIndex,
  rowIndex,
  style,
  data: { columns, cellGap, CellComponent, ...otherData },
}: GridChildComponentProps) {
  const styles = {
    ...style,
    left: columnIndex === 0 ? style.left : Number(style.left) + columnIndex * cellGap,
    right: style.right
      ? columnIndex === columns
        ? style.right
        : Number(style.right) + columnIndex * cellGap
      : 0,
    paddingBottom: cellGap,
  };

  return (
    <div style={styles}>
      {CellComponent && (
        <CellComponent
          {...{
            columnIndex,
            rowIndex,
            columns,
            ...otherData,
          }}
        />
      )}
    </div>
  );
}

export const InfiniteWindowedGrid: React.FunctionComponent<InfiniteWindowedGridProps<any>> = ({
  hasNextPage,
  loadItems,
  loadedItems,
  width,
  height,
  CellComponent,
  itemData,
  aspectRatio = 9 / 16,
  additionalHeight = 0,
}) => {
  const className = atoms({ overflow: 'auto' });
  const defaultScrollbarWidth = 18;

  const cellGap = 8;
  const columnWidth =
    (width - defaultScrollbarWidth - cellGap * (itemData.columns - 1)) / itemData.columns;
  const rowHeight = columnWidth * aspectRatio + additionalHeight + cellGap;

  const rowCount = hasNextPage
    ? Math.ceil((loadedItems.length || 0) / itemData.columns) + 1
    : Math.ceil((loadedItems.length || 0) / itemData.columns);

  const isRowItemLoaded = (index: number) =>
    !hasNextPage || !!loadedItems[index * itemData.columns];
  const isCellItemLoaded = (index: number) => !hasNextPage || !!loadedItems[index];

  const [scrollState, setScrollState] = useState({
    rowIndex: 0,
    columnIndex: 0,
  });
  const setScrollRowAndColumn = useCallback((rowIndex: number, columnIndex: number) => {
    setScrollState({ rowIndex, columnIndex });
  }, []);

  const infiniteScrollItemData = useMemo(
    () => ({
      ...itemData,
      isItemLoaded: isCellItemLoaded,
      results: loadedItems,
      cellGap,
      CellComponent,
    }),
    [isCellItemLoaded, loadedItems],
  );

  return (
    <InfiniteLoader isItemLoaded={isRowItemLoaded} itemCount={rowCount} loadMoreItems={loadItems}>
      {({ onItemsRendered, ref }) => (
        <FixedSizeGrid
          className={className}
          height={height}
          width={width}
          rowHeight={rowHeight}
          columnWidth={columnWidth}
          rowCount={rowCount}
          columnCount={itemData.columns}
          itemData={infiniteScrollItemData}
          initialScrollTop={rowHeight * scrollState.rowIndex}
          onItemsRendered={({
            visibleRowStartIndex,
            visibleColumnStartIndex,
            visibleRowStopIndex,
            overscanRowStopIndex,
            overscanRowStartIndex,
          }) => {
            setScrollRowAndColumn(visibleRowStartIndex, visibleColumnStartIndex);
            onItemsRendered({
              overscanStartIndex: overscanRowStartIndex,
              overscanStopIndex: overscanRowStopIndex,
              visibleStartIndex: visibleRowStartIndex,
              visibleStopIndex: visibleRowStopIndex,
            });
          }}
          ref={ref}
        >
          {GridCell}
        </FixedSizeGrid>
      )}
    </InfiniteLoader>
  );
};
