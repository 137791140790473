export function generatePolicy({ bucketName, bucketPrefix }: Record<string, string>): string {
  const policy = {
    Version: '2012-10-17',
    Statement: [
      {
        Effect: 'Allow',
        Action: [
          's3:GetBucketPolicyStatus',
          's3:GetObjectAcl',
          's3:GetObject',
          's3:GetBucketCORS',
          's3:ListBucket',
          's3:GetBucketAcl',
          's3:GetBucketLocation',
          's3:GetBucketPolicy',
        ],
        Resource: [
          `arn:aws:s3:::${bucketName}`,
          `arn:aws:s3:::${bucketName}/${bucketPrefix !== '' ? `${bucketPrefix}` : ''}*`,
        ],
      },
    ],
  };
  return JSON.stringify(policy, null, 2);
}

const corsPolicy = [
  {
    AllowedHeaders: ['*'],
    AllowedMethods: ['GET', 'HEAD'],
    AllowedOrigins: ['*.superb-ai.com'],
    ExposeHeaders: [],
    MaxAgeSeconds: 3000,
  },
];

export const cors = JSON.stringify(corsPolicy, null, 2);
