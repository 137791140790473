import React, { ReactElement } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@superb-ai/ui';

const useStyles = makeStyles(() => ({
  textEllipsis: {
    minWidth: 20,
    maxWidth: '100%',
    '&, & .MuiTypography-root, & *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

type Props = React.ComponentProps<typeof MUI.Box> & {
  text?: string | JSX.Element;
  children: ReactElement;
};

export default function TextEllipsisTooltip(props: Props) {
  const classes = useStyles();
  const { children, text = '', ...otherProps } = props;

  return (
    <MUI.Box {...otherProps} display="flex" alignItems="center" className={classes.textEllipsis}>
      <Tooltip content={text} placement="bottom">
        {children}
      </Tooltip>
    </MUI.Box>
  );
}
