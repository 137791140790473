/**
 * Sets syncStatus in LabelerContainer.jsx
 *
 * syncStatus determines UI state, and may be:
 *    INIT - default state at page render
 *    INIT_REQUESTED - data requested (initial request via Request button)
 *    SYNCED - synced with previous data
 *    SYNC_REQUESTED - requested current data, ie resync
 *    SYNCED - synced with new data (bc user can resync anytime, syncStatus is never `Finished`)
 *    FAILED - user stats request failed (not used)
 *
 * This differs from `status` in user stats json file, which may be:
 *   INIT - not used
 *   IN_PROGRESS - data is being processed
 *   FINISHED - file is complete
 */

export default {
  // NOT_SYNCED: 'NOT_SYNCED',
  INIT: 'INIT',
  INIT_REQUESTED: 'INIT_REQUESTED',
  SYNCED: 'SYNCED',
  SYNC_REQUESTED: 'SYNC_REQUESTED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED', // server state
  IN_PROGRESS: 'IN_PROGRESS', // server state
};
