import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import getErrorConst from '../../../consts/ErrorConst';
import { useErrorInfo } from '../../../contexts/ErrorContext';
import NotFound from './NotFound';
import SomethingWentWrong from './SomethingWentWrong';

const useStyles = makeStyles(() => ({
  rootBox: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
  },
  navBox: {
    width: '100%',
    height: '70px',
    '& img': {
      width: '104px',
      margin: '30px 0 0 60px',
    },
  },
  imageBox: {
    width: '100%',
    height: '360px',
    textAlign: 'center',
    '& img': {
      width: '576px',
    },
  },
  contentsBox: {
    width: '100%',
    height: '280px',
    textAlign: 'center',
  },
  footerBox: {
    width: '100%',
    height: '50px',
    position: 'relative',
    textAlign: 'center',
  },
  contactBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      width: '11px',
      color: '#4a4a4a',
    },
  },
  copyrightBox: {
    position: 'absolute',
    top: '7px',
    right: '100px',
  },
  headerText: {
    fontSize: '38px',
    color: '#ff625a',
    fontWeight: 400,
  },
  contentsText: {
    marginTop: '8px',
    fontSize: '18px',
    color: '#4a4a4a',
    fontWeight: 400,
  },
  buttonText: {
    fontSize: '14px',
    color: '#4a4a4a',
    margin: '10px',
    fontWeight: 400,
  },
  contactText: {
    fontSize: '11px',
    color: '#5a7bff',
    textDecoration: 'underline',
    marginLeft: '10px',
    fontWeight: 400,
    cursor: 'pointer',
  },
  copyrightText: {
    fontSize: '9px',
    color: '#cacaca',
    fontWeight: 400,
    cursor: 'pointer',
  },
  actionButton: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 rgba(169, 169, 169, 0.26)',
    backgroundColor: '#fff',
    '& .MuiSvgIcon-root': {
      width: '15px',
      color: '#4a4a4a',
    },
  },
  buttonGroup: {
    marginTop: '20px',
    '& button:nth-child(2)': {
      marginLeft: '10px',
    },
  },
}));

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const errorInfo = useErrorInfo();

  useEffect(() => {
    return () => {
      errorInfo.setError(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MUI.Box className={classes.rootBox}>
        <MUI.Box className={classes.navBox}>
          <img alt="logo" src="https://asset.superb-ai.com/assets/img/new_logo.png" />
        </MUI.Box>
        {errorInfo.error === getErrorConst(t).PAGE_NOT_FOUND ||
        errorInfo.error === getErrorConst(t).NO_PUBLIC_API ? (
          <NotFound parentClasses={classes} />
        ) : (
          <SomethingWentWrong parentClasses={classes} />
        )}
        <MUI.Box className={classes.footerBox}>
          <MUI.Box className={classes.copyrightBox}>
            <MUI.Typography
              className={classes.copyrightText}
              onClick={() => {
                window.open('https://icons8.com/illustrations');
              }}
            >
              illustration by Ouch.pics
            </MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default ErrorPage;
