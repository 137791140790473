import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';

import { formatNumber } from '../../../../utils/numberFormat';
import { ExtendedScaleTime } from '../interfaces/d3Types';
import { formatPercent } from '../tools/d3Helper';

const getStyles = (style: 'bold' | 'link' | 'boldLink'): CSSProperties => {
  return {
    bold: { fontWeight: 'bold' },
    link: { cursor: 'pointer' },
    boldLink: { cursor: 'pointer', fontWeight: 'bold', color: 'cyan' },
  }[style];
};

/**
 * @param {int} numRows Number of rows to display in tooltip
 */
const getTooltipDeltaYs = () => {
  return [2, 18, 34, 50, 66];
};

const getTooltipWidth = (totalLabels: number): number => {
  if (totalLabels < 10 ** 3) {
    return 185;
  }
  if (totalLabels < 10 ** 4) {
    return 200;
  }
  if (totalLabels < 10 ** 5) {
    return 225;
  } // 10 k
  return 250; // 100k oor greater
};

interface Props {
  left: number;
  top: number;
  xValue: Date;
  yValue: number;
  working: number;
  skipped: number;
  scales: [ExtendedScaleTime, d3.ScaleLinear<number, number, never>];
  handleClickInspect: (date: Date) => void;
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
  total: number;
}

export const Tooltip: React.FC<Props> = props => {
  const {
    left,
    top,
    xValue,
    yValue,
    working,
    skipped,
    scales,
    handleClickInspect,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    total,
  } = props;
  const xScale = scales[0];
  const { t } = useTranslation();

  const BOLD = getStyles('bold');
  const LINK = getStyles('link');
  const TOOLTIP_STYLE: CSSProperties = {
    textAnchor: 'start',
    width: '100px',
    height: '75px',
    fontFamily: 'Inter',
    fontSize: '12px',
    padding: '2px',
  };

  const textBoxWidth = getTooltipWidth(total);

  const formatTime = (x: Date): string => {
    return d3.timeFormat('%Y-%m-%d')(x);
  };
  const formattedX = formatTime(xValue);

  // const origY = yScale(yValue) + yOffset; // old method - tooltip appears right above data point
  const origY = -45;
  const origX = xScale(xValue);
  const DYS = getTooltipDeltaYs();
  const Y_HEIGHT = origY + 20;

  // Allow tooltip to overflow (outside of Plot)
  const xInBound = origX;
  const [percentTotal] = formatPercent(yValue, total);

  return (
    <g transform={`translate(${left - textBoxWidth / 2}, ${top})`} style={TOOLTIP_STYLE}>
      <rect
        className="line-chart-tooltip-rect"
        width={`${textBoxWidth}`}
        height="90px"
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '5px',
        }}
        x={xInBound}
        y={origY + 5}
        rx={2}
        ry={2}
        fill="#333333"
        opacity="0.9"
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      />
      <text
        fill="white"
        textAnchor="center"
        x={xInBound + 5}
        y={origY + 7}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
      >
        <tspan key={formattedX} x={xInBound} y={Y_HEIGHT} dx={15} dy={DYS[0]}>
          <tspan>{t('analytics.text.date')}: </tspan>
          <tspan style={BOLD}>{formattedX} </tspan>
        </tspan>
        <tspan key={`${formattedX}submitted`} x={xInBound} y={Y_HEIGHT} dx={15} dy={DYS[1]}>
          <tspan>{t('labels.status.submitted')}: </tspan>
          <tspan style={BOLD}>{formatNumber(yValue)}</tspan>
          <tspan>{` of ${total} (${percentTotal})`}</tspan>
        </tspan>
        <tspan key={`${formattedX}working`} x={xInBound} y={Y_HEIGHT} dx={15} dy={DYS[2]}>
          <tspan>{t('labels.status.in_progress')}: </tspan>
          <tspan style={BOLD}>{formatNumber(working)}</tspan>
        </tspan>
        <tspan key={`${formattedX}skipped`} x={xInBound} y={Y_HEIGHT} dx={15} dy={DYS[3]}>
          <tspan>{t('labels.status.skipped')}: </tspan>
          <tspan style={BOLD}>{formatNumber(skipped)}</tspan>
        </tspan>
        <tspan
          x={xInBound}
          y={Y_HEIGHT}
          dx={15}
          dy={DYS[4]}
          onClick={() => handleClickInspect(xValue)}
          fill="cyan"
          style={LINK}
        >
          {t('analytics.project.viewLabels')}
        </tspan>
      </text>
    </g>
  );
};

export default Tooltip;
