import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ScopeMode } from '../../../../../types/viewTypes';
import { InfoBanner } from './components/InfoBanner';

type Props = {
  scope: ScopeMode;
  samplingPointCount: number;
  totalPointCount: number;
  style: React.CSSProperties;
};

export const ScatterSamplingInfoBanner = ({
  scope,
  samplingPointCount,
  totalPointCount,
  style,
}: Props) => {
  const { t } = useTranslation();
  return (
    <InfoBanner
      textComponent={
        <Trans
          t={t}
          i18nKey={'curate.embeddings.text.samplingBanner'}
          values={{
            sampledCount: samplingPointCount.toLocaleString('en'),
            totalCount: totalPointCount.toLocaleString('en'),
            target:
              scope === 'image'
                ? t('curate.embeddings.text.target.images')
                : t('curate.embeddings.text.target.object'),
          }}
        />
      }
      style={style}
    />
  );
};
