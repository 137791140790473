import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import axios from 'axios';

import { db } from '../components/pages/aiAdvancedFeatures/HistoryTable';
import AssetsService from '../services/AssetsService';
import SemanticSearchService, { ImageQuery } from '../services/SemanticSearchService';
import { SemanticSearch, SemanticSearchResult } from '../types/semanticsearchTypes';
import { useAuthInfo } from './AuthContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

export interface TextEntry {
  text: string;
}

export interface ImageQueryEntry {
  id: string;
  type: 'FILE' | 'DATAKEY' | 'URL' | 'LABELID' | 'ASSETID' | 'UPLOADID';
  src?: File | string;
  rois?: number[][];
  roi?: number[];
  score?: number;
  dataset?: string;
  dataKey?: string;
  labelId?: string;
  assetId?: string;
  uploadId?: string;
  size?: number[];
  url?: string;
  group?: any;
  key?: string;
}

type SemanticSearchContextProps = StateGetterSetter<
  ['textQueries', 'setTextQueries'],
  TextEntry[]
> &
  StateGetterSetter<['imageQueryEntries', 'setImageQueryEntries'], ImageQueryEntry[]> &
  StateGetterSetter<['foundEntries', 'setFoundEntries'], ImageQueryEntry[]> &
  StateGetterSetter<['selectedEntries', 'setSelectedEntries'], Set<number>> & {
    canSearch: boolean;
    loadResultFromSearchId: (queryRes: SemanticSearch) => Promise<void>;
    loadQueryFromSearchId: (searchId: string) => Promise<SemanticSearch | null>;
    getAssetIdFromDataKey: (dataset: string, dataKey: string) => Promise<string | null>;
    getPresignedUrlFromAssetId: (assetId: string) => Promise<string | null>;
    getURLFromImageQueryEntry: (ie: ImageQueryEntry) => Promise<string>;
    waitForSearchResult: (
      searchId: string,
      interval?: number,
      timeout?: number,
    ) => Promise<'SUCCESS' | 'TIMEOUT' | 'FAILED'>;
    cancelWaiting: () => void;
    initialize: () => void;
    convertImageQueryToEntry: (
      imageQuery: ImageQuery,
      thumbnailUrls: Record<string, string>,
    ) => ImageQueryEntry;
    loadThumbnails: (
      searchId: string,
      imageQueries: ImageQuery[],
    ) => Promise<Record<string, string>>;
    loadThumbnailUrls: (searchId: string, keys: string[]) => Promise<Record<string, string>>;
  };

const SemanticSearchContext = React.createContext({} as SemanticSearchContextProps);
const cacheAssetIdByDatakey: Record<string, string> = {};
const cachePresignedUrlByAssetId: Record<string, string> = {};

export const SemanticSearchProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const params = useParams<{ projectId: string }>();
  const [textQueries, setTextQueries] = useState<TextEntry[]>([]);
  const [imageQueryEntries, setImageQueryEntries] = useState<ImageQueryEntry[]>([]);
  const [foundEntries, setFoundEntries] = useState<ImageQueryEntry[]>([]);
  const [selectedEntries, setSelectedEntries] = useState<Set<number>>(new Set());

  const canSearch =
    (textQueries?.length > 0 || imageQueryEntries?.length > 0) && params.projectId !== null;
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const initialize = () => {
    setTextQueries([]);
    setImageQueryEntries([]);
    setFoundEntries([]);
    setSelectedEntries(new Set());
  };

  const getAssetIdFromDataKey = async (
    dataset: string,
    dataKey: string,
  ): Promise<string | null> => {
    const cacheKey = `${dataset}/${dataKey}`;
    let assetId = cacheAssetIdByDatakey[cacheKey];
    if (!assetId) {
      const assets = await AssetsService.getAssets({
        params: {
          group_in: [dataset],
          key_in: [dataKey],
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      if (assets.results.length !== 1) return null;
      if (assets.results[0].info.type !== 'img-presigned-url') return null;
      assetId = assets.results[0].id;
    }
    cacheAssetIdByDatakey[cacheKey] = assetId;
    return assetId;
  };

  const getPresignedUrlFromAssetId = async (assetId: string): Promise<string | null> => {
    let presignedUrl = cachePresignedUrlByAssetId[assetId];
    if (!presignedUrl) {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      const assetRes = await AssetsService.getAsset({
        cancelToken: source.token,
        assetId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      presignedUrl = assetRes.info.url;
    }
    cachePresignedUrlByAssetId[assetId] = presignedUrl;
    return presignedUrl;
  };

  const getURLFromImageQueryEntry = async (ie: ImageQueryEntry): Promise<string> => {
    let presignedUrl = '';

    switch (ie.type) {
      case 'FILE':
        const url = URL.createObjectURL(ie.src as File);
        return url;
      case 'DATAKEY':
        if (ie.src && ie.src !== '') {
          return ie.src as string;
        }

        const assetId =
          ie.assetId || (await getAssetIdFromDataKey(ie.dataset as string, ie.dataKey as string));
        if (!assetId) return '';

        presignedUrl = (await getPresignedUrlFromAssetId(assetId)) as string;
        return presignedUrl || '';
      case 'LABELID':
        return (ie.src as string) || '';
      case 'UPLOADID':
        return (ie.src as string) || '';
      default:
        return (ie.src as string) || '';
    }
  };

  const sleep = async (seconds: number): Promise<void> => {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  };

  const stopSignal: Record<string, boolean> = {
    stop: false,
  };

  const waitForSearchResult = async (
    searchId: string,
    interval?: number,
    timeout?: number,
  ): Promise<'SUCCESS' | 'TIMEOUT' | 'FAILED'> => {
    if (interval === undefined) interval = 1.0;

    stopSignal.stop = false;
    const startedAt = new Date();
    while (!stopSignal.stop) {
      if (!params.projectId) {
        return 'FAILED';
      }
      const resp = await SemanticSearchService.getSearchRequest({
        projectId: params.projectId,
        searchId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      if (resp.status === 'SUCCESS') return 'SUCCESS';
      await sleep(interval);
      if (timeout) {
        const now = new Date();
        const elapsedTime = (now.getTime() - startedAt.getTime()) / 1000;
        if (elapsedTime > timeout) return 'TIMEOUT';
      }
    }
    if (stopSignal.stop) return 'TIMEOUT';
    return 'SUCCESS';
  };

  const cancelWaiting = () => {
    stopSignal.stop = true;
    return;
  };

  const loadThumbnailUrls = async (
    searchId: string,
    keys: string[],
  ): Promise<Record<string, string>> => {
    const urlMap = await SemanticSearchService.getSemanticSearchThumbnailUrls({
      projectId: params.projectId,
      semanticSearchId: searchId,
      keys,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    return urlMap;
  };

  const loadThumbnails = async (searchId: string, imageQueries: ImageQuery[]) => {
    const thumbnailUrls = await loadThumbnailUrls(
      searchId,
      imageQueries.map(iq => {
        if (iq.type === 'suite-data') {
          return `query_image/${iq.dataset}/${iq.dataKey}`;
        } else {
          return `query_image/${iq.uploadId}`;
        }
      }),
    );

    return thumbnailUrls;
  };

  const convertImageQueryToEntry = (
    imageQuery: ImageQuery,
    thumbnailUrls: Record<string, string>,
  ): ImageQueryEntry => {
    if (imageQuery.type === 'suite-data') {
      return {
        id: (Math.random() * 1000000).toFixed(0).toString(),
        type: 'DATAKEY',
        dataset: imageQuery.dataset,
        dataKey: imageQuery.dataKey,
        assetId: imageQuery.assetId,
        roi: imageQuery.roi,
        src: thumbnailUrls[`query_image/${imageQuery.dataset}/${imageQuery.dataKey}`] || '',
        size: imageQuery.size,
      };
      // } else if (imageQuery.type === 'upload-data')
    } else {
      return {
        id: (Math.random() * 1000000).toFixed(0).toString(),
        type: 'UPLOADID',
        uploadId: imageQuery.uploadId,
        src: thumbnailUrls[`query_image/${imageQuery.uploadId}`] || '',
        roi: imageQuery.roi,
        size: imageQuery.size,
      };
    }
  };

  const loadQueryFromSearchId = async (searchId: string): Promise<SemanticSearch | null> => {
    if (!params.projectId) return null;
    let queryInfo: SemanticSearch;
    const dbSemanticSearchTable = await db.semanticSearchTable.get({ id: searchId });
    if (dbSemanticSearchTable) {
      const { results, ...info } = dbSemanticSearchTable;
      const newInfo = { ...info, imageQueries: [...results.query.image_queries] };
      queryInfo = newInfo;
    } else {
      let resp = await SemanticSearchService.getSearchRequest({
        projectId: params.projectId,
        searchId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      if (resp.resultUrl) {
        const resultResp = (await axios.get(resp.resultUrl)).data as SemanticSearchResult;
        await db.semanticSearchTable.put({
          ...resp,
          results: resultResp,
        });
        resp = { ...resp, imageQueries: [...resultResp.query.image_queries] };
      }
      queryInfo = resp;
    }

    const thumbnailUrls = await loadThumbnails(searchId, queryInfo.imageQueries);
    const imageQueryEntries = queryInfo.imageQueries.map(
      imageQuery => convertImageQueryToEntry(imageQuery, thumbnailUrls) ?? [],
    );
    setImageQueryEntries(imageQueryEntries as ImageQueryEntry[]);
    setTextQueries(
      queryInfo.textQueries.map((tq, idx) => {
        return {
          id: (Math.random() * 1000000).toFixed(0).toString(),
          text: tq,
        };
      }),
    );

    return queryInfo;
  };

  const loadResultFromSearchId = async (queryRes: SemanticSearch) => {
    if (!params.projectId) return;

    let resultResp: SemanticSearchResult;
    const dbSemanticSearchTable = await db.semanticSearchTable.get({ id: queryRes.id });
    if (dbSemanticSearchTable) {
      const { results, ...info } = dbSemanticSearchTable;
      resultResp = results;
    } else {
      resultResp = (await axios.get(queryRes.resultUrl)).data as SemanticSearchResult;

      await db.semanticSearchTable.put({
        ...queryRes,
        results: resultResp,
      });
    }

    const thumbnailKeys: string[] = [];

    /**
     * query_image: thumbnail of query images
     * whole_image: thumbnail of dataset images
     */
    resultResp.query?.image_queries.length > 0 &&
      resultResp.query.image_queries.forEach(e => {
        if (e.type == 'suite-data') {
          thumbnailKeys.push(`query_image/${e.dataset}/${e.data_key}`);
        } else {
          thumbnailKeys.push(`query_image/${e.upload_id}`);
        }
      });
    resultResp.result?.forEach(e => {
      thumbnailKeys.push(`whole_image/${e.label_id}`);
    });

    const thumbnailUrls = await loadThumbnailUrls(queryRes.id, thumbnailKeys);

    const foundEntries = resultResp.result?.map((e, idx) => {
      return {
        id: new Date().getUTCMilliseconds().toString() + `-${idx}`,
        type: 'LABELID',
        labelId: e.label_id,
        rois: e.rois,
        src: thumbnailUrls[`whole_image/${e.label_id}`] || '',
        size: e.img_size,
      } as ImageQueryEntry;
    });
    const imageQueryEntries =
      resultResp.query &&
      resultResp.query.image_queries.map(imageQuery =>
        convertImageQueryToEntry(
          {
            type: imageQuery.type,
            dataset: imageQuery.dataset,
            dataKey: imageQuery.data_key,
            uploadId: imageQuery.upload_id,
            assetId: imageQuery.asset_id,
            roi: imageQuery.roi,
            size: imageQuery.img_size,
          },
          thumbnailUrls,
        ),
      );
    setImageQueryEntries(imageQueryEntries);
    setFoundEntries(foundEntries);
  };

  return (
    <SemanticSearchContext.Provider
      value={{
        initialize,
        waitForSearchResult,
        cancelWaiting,
        loadQueryFromSearchId,
        loadResultFromSearchId,
        textQueries,
        setTextQueries,
        imageQueryEntries,
        setImageQueryEntries,
        foundEntries,
        setFoundEntries,
        selectedEntries,
        setSelectedEntries,
        canSearch,
        getAssetIdFromDataKey,
        getPresignedUrlFromAssetId,
        getURLFromImageQueryEntry,
        convertImageQueryToEntry,
        loadThumbnails,
        loadThumbnailUrls,
      }}
    >
      {children}
    </SemanticSearchContext.Provider>
  );
};

export const useSemanticSearchInfo = (): SemanticSearchContextProps => {
  return React.useContext(SemanticSearchContext);
};
