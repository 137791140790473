export default {
  THREAD: '🗒',
  INVITE: '🎉',
  ASSIGN: '👉',
  DIZZY: '💫',
  COMMENT: '💬',
  RIGHT_POINTING: '👉',
  SPEECH_BALLOON: '💬',
  QUESTION_MARK: '❓',
  CHECK: '✔️',
};
