import React from 'react';

import { Box, Button, Typography } from '@superb-ai/ui';

import { IAdvancedQuery } from '../../../../types/querySchemaTypes';

export default function OperatorBox({
  handleOpen,
  boxNum,
  advancedQuery,
  anchorEl,
  updateOperator,
}: {
  handleOpen: (e: React.MouseEvent, id: string) => void;
  boxNum: number;
  advancedQuery: IAdvancedQuery;
  anchorEl: string | null;
  updateOperator: (boxNum: number, operator: string) => void;
}) {
  return (
    <Box position="relative">
      <Box
        mr={0.5}
        border="1px solid"
        borderColor={'gray-200'}
        backgroundColor={'white'}
        style={{ maxHeight: '32px', padding: '7px 10px', width: '48px' }}
        boxSizing={'border-box'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="2px"
        cursor="pointer"
        onClick={e => handleOpen(e, boxNum.toString() + 'operator')}
      >
        <Typography variant="m-regular">{advancedQuery.operator}</Typography>
      </Box>
      {anchorEl === boxNum.toString() + 'operator' && (
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '32px',
            width: '70px',
            height: '65px',
            zIndex: '999',
            background: '#ffffff',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            boxSizing: 'border-box',
          }}
        >
          <Button
            variant="text"
            color="gray"
            style={{
              padding: '4px 12px',
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={() => updateOperator(boxNum, 'AND')}
          >
            <Typography variant="m-regular">AND</Typography>
          </Button>
          <Button
            variant="text"
            color="gray"
            style={{
              padding: '4px 12px',
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={() => updateOperator(boxNum, 'OR')}
          >
            <Typography variant="m-regular">OR</Typography>
          </Button>
        </div>
      )}
    </Box>
  );
}
