import { useEffect, useState } from 'react';
import { ListChildComponentProps } from 'react-window';

import { Box } from '@superb-ai/norwegian-forest';
import { Icon } from '@superb-ai/ui';
import * as d3Format from 'd3-format';
import memoize from 'memoize-one';

import { getAnnotationTypeIcon } from '../../chartContainers/helper';
import { GoToLabelsButton } from '../../elements/GoToLabelsButton';
import { textStyle } from './DonutLegend';

const COLUMN_STYLE = {
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'initial',
};

const TEXT_OVERFLOW_STYLE = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const createItemData = memoize(
  (
    items,
    idKey,
    displayNameKey,
    countKey,
    shareKey,
    colorKey,
    selectedIndex,
    getDatumColor,
    handleOnClick,
    handleOnHover,
    showButton,
  ) => ({
    items,
    idKey,
    displayNameKey,
    countKey,
    shareKey,
    colorKey,
    selectedIndex,
    getDatumColor,
    handleOnClick,
    handleOnHover,
    showButton,
  }),
);

export const DonutLegendRow = ({
  index,
  data: {
    items,
    idKey,
    displayNameKey,
    countKey,
    shareKey,
    colorKey,
    selectedIndex,
    getDatumColor,
    handleOnClick,
    handleOnHover,
    showButton,
  },
  style,
}: ListChildComponentProps) => {
  const itemData = createItemData(
    items,
    idKey,
    countKey,
    displayNameKey,
    shareKey,
    colorKey,
    selectedIndex,
    getDatumColor,
    handleOnClick,
    handleOnHover,
    showButton,
  );

  const item = itemData.items[index];
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>(-1);

  useEffect(() => {
    setHoveredRowIndex(selectedIndex);
  }, []);

  useEffect(() => {
    setHoveredRowIndex(selectedIndex);
  }, [selectedIndex]);

  const handleMouseOver = (id: string) => {
    handleOnHover(index, 'in');
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    handleOnHover(index, 'out');
    setIsHovered(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ ...style, height: '23px', overflowX: 'hidden' }}
    >
      <Box
        display="flex"
        position="relative"
        height={'23px'}
        onClick={() => handleOnClick && handleOnClick(index)}
        onMouseEnter={() => handleMouseOver(item[idKey])}
        onMouseLeave={() => handleMouseOut()}
        marginBottom="2px"
        pr={0.5}
        key={item?.[idKey]}
        borderRadius="4px"
        backgroundColor={hoveredRowIndex === index || isHovered ? '#FFE7E7' : 'transparent'}
        style={{
          ...textStyle,
          cursor: handleOnClick ? 'pointer' : 'default',
        }}
        width={'100%'}
      >
        <svg viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
          <circle cx={3} cy={3} r={1.6} opacity="1" fill={getDatumColor?.(item)} />
        </svg>
        <Box display="flex" ml={0.5} alignItems="center">
          {item?.annotationType ? <Icon icon={getAnnotationTypeIcon(item.annotationType)} /> : null}{' '}
        </Box>
        <Box
          display="flex"
          ml={0.7}
          flex={1}
          alignItems="center"
          overflow="hidden"
          justifySelf="flex-start"
        >
          <Box style={{ ...COLUMN_STYLE, ...TEXT_OVERFLOW_STYLE }}>{item?.[idKey] ?? '..'}</Box>
        </Box>
        <Box
          alignItems="center"
          style={{
            ...COLUMN_STYLE,
            fontVariantNumeric: 'tabular-nums',
            justifyContent: 'flex-end',
            marginLeft: '8px',
          }}
        >
          <span>{d3Format.format(',')(item[countKey])}</span>
        </Box>
        <Box
          alignItems="center"
          style={{
            ...COLUMN_STYLE,
            fontVariantNumeric: 'tabular-nums',
            justifyContent: 'flex-end',
            marginLeft: '8px',
            width: '20%',
          }}
        >
          <span>{Number(item[shareKey]).toFixed(2)} %</span>
        </Box>
        <Box
          style={{
            width: '100%',
            top: '1.5px',
            left: '140px',
            zIndex: 2,
            justifyContent: 'center',
            position: 'absolute',
          }}
        >
          {isHovered && showButton && (
            <GoToLabelsButton handleOnClick={() => handleOnClick(index)} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
