import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import Body from './Body';

const Layout: React.FC = () => {
  return (
    <Box>
      <Body />
    </Box>
  );
};

export default Layout;
