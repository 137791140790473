import React, { CSSProperties, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import { Chip, Tooltip } from '@superb-ai/norwegian-forest';
import { ColorChipsColor } from '@superb-ai/norwegian-forest/dist/theme';
import { DefaultColor } from '@superb-ai/norwegian-forest/dist/theme/color';
import { camelCase, endsWith, includes, isUndefined } from 'lodash';

import { AccountRole, NewRole } from '../../../../types/memberTypes';
import { formatDuration } from '../../../../utils/date';
import { UserProfileObject } from '../../../../utils/UserUtils';
import RoleChip from '../../../elements/RoleChip';
import UserAvatarAndName from '../charts/table/UserAvatarAndName';
import ProgressBar from '../elements/ProgressBar';
import {
  AVG_REVIEW_ROUND,
  LABEL_COUNTS,
  REJECTED_COUNTS,
  REJECTION_HISTORY_COUNT,
  REJECTION_HISTORY_PERCENT,
  REVIEW_HISTORY_PERCENT,
  TOTAL_REVIEWED,
} from './labelerTable/helper';
import { BottomHeader, JsonObj, LabelerDatum } from './types';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const getAnnoCount = (row: LabelerDatum, columnId: string): number => {
  let count = row[columnId] as number;
  if (isUndefined(count) && endsWith(columnId, 'Type')) {
    // hotfix: temporary fix to support previous version of user report column names
    count = row[columnId.slice(0, -4)] as number;
  }

  return count ?? undefined;
};

const getChipColor = (column: string, value: number): ColorChipsColor | DefaultColor => {
  if (column === REJECTION_HISTORY_PERCENT) {
    return value > 50 ? 'primary' : 'cobalt';
  }
  if (column === REVIEW_HISTORY_PERCENT) {
    return value > 50 ? 'green' : 'strawberry';
  }
  return 'grey';
};

const historyColumns = [REJECTION_HISTORY_PERCENT, REVIEW_HISTORY_PERCENT];

const NameCell = (
  row: LabelerDatum,
  columnId: string,
  profiles: UserProfileObject,
): JSX.Element => {
  const name = row[columnId] as string;
  const email = row?.email as string;
  return UserAvatarAndName({ userInfo: profiles[email], email: email, name: name });
};

const DataCell = (row: LabelerDatum, columnId: string): JSX.Element => {
  const annoCount = getAnnoCount(row, columnId);
  if (includes(historyColumns, columnId)) {
    return (
      <Chip color={getChipColor(columnId, annoCount)}>
        {isUndefined(annoCount) ? '-' : `${annoCount} %`}
      </Chip>
    );
  }
  return <>{annoCount || '-'}</>;
};

const ProgressBarCell = (
  email: string,
  color = 'rgb(163, 235, 87)',
  progress?: number,
): JSX.Element => {
  return <ProgressBar key={email} color={color} completed={progress} />;
};

const TimeCell = (value: number): JSX.Element => {
  return <>{formatDuration(value)}</>;
};

const getTooltipText = (row: JsonObj, columnId: string): string | null => {
  if (includes(historyColumns, columnId)) {
    return {
      [REVIEW_HISTORY_PERCENT]: `${row[TOTAL_REVIEWED] ?? 0} reviewed out of ${
        row[LABEL_COUNTS]
      } total labels `,
      [REJECTION_HISTORY_PERCENT]: `${
        row[REJECTION_HISTORY_COUNT] ?? 0
      } with rejection history out of ${row[TOTAL_REVIEWED] ?? 0} reviewed `,
    }[columnId] as string;
  }
  return null;
};

const Cell = (row: LabelerDatum, columnId: string, userProfiles: UserProfileObject) => {
  const mapper = {
    name: NameCell(row, columnId, userProfiles),
    role: <RoleChip memberRole={row[columnId] as NewRole & AccountRole} />,
  };
  if (columnId in mapper) return mapper[columnId as keyof typeof mapper];
  return DataCell(row, columnId);
};

interface RowProps {
  row: JsonObj;
  handleRowClick: (email: string) => void;
  bottomHeader: BottomHeader[];
  userProfiles: UserProfileObject;
  progressBarColor: string;
}
const UserReportRenderRow = ({
  row,
  handleRowClick,
  bottomHeader,
  userProfiles,
  progressBarColor,
}: RowProps) => {
  const [isHover, setIsHover] = useState(false);

  const getColor = (row: JsonObj, columnId: string) => {
    if (columnId === REJECTED_COUNTS && getAnnoCount(row as LabelerDatum, columnId) > 0) {
      return { color: '#ff625a' } as CSSProperties;
    }
    if (columnId === camelCase(AVG_REVIEW_ROUND) && row[columnId] > 3) {
      return { fontWeight: 'bold', color: '#ff625a' } as CSSProperties;
    }
    return { backgroundColor: 'none' };
  };
  return (
    // @ts-ignore: saa-680
    <StyledTableRow
      hover
      role="checkbox"
      key={row.email}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => handleRowClick(row.email)}
    >
      {bottomHeader.map(column => {
        if (column?.hidden) return;
        const columnId = column.id;
        const tooltipText = getTooltipText(row, columnId);
        const columnEl = (
          <TableCell
            key={columnId + row[columnId]}
            style={getColor(row, columnId) as CSSProperties}
          >
            {columnId === 'progress'
              ? ProgressBarCell(row['email'], progressBarColor, row?.progress)
              : columnId === 'timePerLabelSec' || columnId === 'timespentSec'
              ? TimeCell(row[columnId])
              : //: columnId === 'frameProgress'
                //? ProgressBarCell(row['email'], progressBarColor, row?.frameProgress)
                Cell(row as LabelerDatum, columnId, userProfiles)}
          </TableCell>
        );
        return (
          <React.Fragment key={`${row.email}-${columnId}`}>
            {tooltipText && (
              <Tooltip placement="top-start" anchorEl={columnEl}>
                {getTooltipText(row, columnId)}
              </Tooltip>
            )}
            {!tooltipText && columnEl}
          </React.Fragment>
        );
      })}
    </StyledTableRow>
  );
};

export default UserReportRenderRow;
