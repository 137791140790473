import React, { createContext, useContext, useState } from 'react';

import { StateGetterSetter } from '../../../../../../../contexts/types';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['isCompareMode', 'setIsCompareMode'], boolean>
  & StateGetterSetter<['selectedCompareSetId', 'setSelectedCompareSetId'], string>;

const Context = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const [isCompareMode, setIsCompareMode] = useState<boolean>(false);
  const [selectedCompareSetId, setSelectedCompareSetId] = useState<string>('');

  return {
    isCompareMode,
    setIsCompareMode,
    selectedCompareSetId,
    setSelectedCompareSetId,
  };
};

export const useAnalyticsContext = (): ContextProps => {
  return useContext(Context);
};

export const AnalyticsProvider: React.FC = ({ children }) => {
  const metadataInfo = useProvider();
  return <Context.Provider value={metadataInfo}>{children}</Context.Provider>;
};
