import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ArrowLeft, ChartBar, Clear, LinkExternal, Search } from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, Input, Typography } from '@superb-ai/ui';
import Link from 'next/link';

import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { NoResultsFound } from '../../../../../../../components/elements/NoResultsFound';
import { getUserManualUrl } from '../../../../../../../consts/DocsLink';
import { useSetPageTitle } from '../../../../../../../contexts/AppContext';
import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import useDiagnosisViewSearchParam from '../../../../../hooks/useViewSearchParam';
import { MAX_DIAGNOSIS_QUOTA } from '../const';
import { ModelDiagnosisTutorial } from '../diagnosis/tutorial/Tutorial';
import { isDiagnosisActive } from '../diagnosis/types';
import { useDiagnosisPathname } from '../diagnosis/useGetUrl';
import { isMaxQuotaReached } from '../diagnosisUnion';
import ListItem from './ListItem';

export default function ModelDiagnosisListLayout() {
  const { i18n, t } = useTranslation();
  const { searchParams } = useDiagnosisViewSearchParam();
  const [searchInput, setSearchInput] = useState('');
  const { diagnosisList, diagnosisCount, quotaCount } = useDiagnosisModelContext();
  const diagnosisPathName = useDiagnosisPathname();
  const history = useHistory();
  const hasDiagnoses = diagnosisList && diagnosisList.length > 0;

  useSetPageTitle(
    t('curate.dataset.menus.modelDiagnosis'),
    PAGE_TRACKING_ID.curateModelDiagnosisList,
  );

  const filterDiagnosisList = diagnosisList?.filter(diagnosis =>
    (diagnosis.modelName || t('curate.diagnosis.text.modelSourceTag.deleted'))
      .toLowerCase()
      .includes((searchInput || '').toLowerCase()),
  );

  const itemSelectedByID =
    searchParams?.diagnosis_id &&
    diagnosisList?.filter(item => item.id === searchParams.diagnosis_id);

  function handleSearch(value: string) {
    setSearchInput(value);
  }

  useEffect(() => {
    if (!diagnosisList) return;
    const { model_id } = searchParams;
    if (model_id) {
      const selected = diagnosisList.find(diagnosis => diagnosis.modelId === model_id);
      if (!selected) {
        return;
      }
      if (isDiagnosisActive(selected.status)) {
        history.replace(`/${diagnosisPathName}${selected.id}`);
        return;
      }
      history.replace(`/${diagnosisPathName}list?diagnosis_id=${selected.id}`);
      return;
    }
  }, [diagnosisList, history.location.pathname]);

  if (!hasDiagnoses) return <ModelDiagnosisTutorial />;

  const { CURATE_MODEL_DIAGNOSIS_INTRO } = getUserManualUrl(i18n.language);

  return (
    <Box height="100%" p={4}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <Icon icon={ChartBar} color="mint" size="53.3px" />
        <Typography variant="h2">
          {t('curate.diagnosis.text.letsDiagnose')} ({quotaCount}/{MAX_DIAGNOSIS_QUOTA})
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography variant="m-regular">
            {isMaxQuotaReached(diagnosisList)
              ? t('curate.diagnosis.text.maxQuotaReached')
              : t('curate.diagnosis.text.chooseModelToDiagnose')}
          </Typography>
          <Link
            href={CURATE_MODEL_DIAGNOSIS_INTRO}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'underline',
            }}
          >
            <Typography variant="m-regular" style={{ marginRight: '2.5px' }}>
              Learn more
            </Typography>
            <Icon icon={LinkExternal} />
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={2}
          gap={1}
          overflow="auto"
          style={{ width: '552px' }}
        >
          {!itemSelectedByID && (
            <Input
              variant="soft-fill"
              color="gray"
              prefix={<Icon icon={Search} />}
              suffix={
                searchInput && (
                  <IconButton
                    variant="text"
                    size="s"
                    icon={Clear}
                    onClick={() => handleSearch('')}
                  />
                )
              }
              placeholder={
                (diagnosisList?.length || 0) < diagnosisCount
                  ? t('button.loading')
                  : t('curate.diagnosis.diagnosisList.searchPlaceholder')
              }
              onChange={e => handleSearch(e.target.value)}
              value={searchInput}
            />
          )}
          {!filterDiagnosisList || filterDiagnosisList.length <= 0 ? (
            <NoResultsFound
              mt={5}
              titleKey="curate.diagnosis.diagnosisList.text.noResultsFound"
              explanationKey="curate.diagnosis.diagnosisList.text.noResultFoundDiagnosis"
              explanationValues={{ search: searchInput }}
            />
          ) : itemSelectedByID ? (
            <>
              {itemSelectedByID.map((diagnosis, index) => (
                <ListItem key={diagnosis.id} diagnosis={diagnosis} index={index} />
              ))}
              <Box display="flex" justifyContent="center">
                <Button
                  color="black"
                  variant="strong-fill"
                  onClick={() => history.push(`/${diagnosisPathName}list`)}
                >
                  <Icon icon={ArrowLeft} />
                  {t('curate.diagnosis.diagnosisList.text.viewAllList')}
                </Button>
              </Box>
            </>
          ) : (
            filterDiagnosisList?.map((diagnosis, index) => (
              <ListItem key={diagnosis.id} diagnosis={diagnosis} index={index} />
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
}
