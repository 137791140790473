import React, { useState } from 'react';
import { Image } from 'react-konva';

import ImageContext from '../../../../../contexts/ImageContext';

const Mask = props => {
  const imageInfo = React.useContext(ImageContext.Context);

  const { width, height } = imageInfo;
  const { shape } = props;
  const [maskImage, setMaskImage] = useState(null);

  useEffect(() => {
    const nextMaskImage = new Image();
    nextMaskImage.src = shape.info.dataUrl;
    nextMaskImage.crossOrigin = 'Anonymous';
    nextMaskImage.onload = () => {
      setMaskImage(nextMaskImage);
    };
    // eslint-disable-next-line
  }, []);

  if (!maskImage) return null;
  return (
    <Image id="mask" image={maskImage} x={0} y={0} width={width} height={height} opacity={0.5} />
  );
};

export default Mask;
