import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../menu';
import { DatasetMenuItem, SampleDatasetMenuItem } from './dataset/MenuItem';
import { DatasetsListMenuItem, SampleDatasetsListMenuItem } from './list/MenuItem';
import Route from './Route';

export const CurateDatasetsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasets,
  path: 'dataset',
  matchParam: ':datasetPool(dataset)',
  group: 'datasetPool',
  component: Route,
  title: ({ t }) => t('curate.datasets.title'),
  isVisible: true,
  children: [DatasetsListMenuItem, DatasetMenuItem],
});

export const CurateSampleDatasetsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateSampleDatasets,
  path: 'sample-dataset',
  matchParam: ':datasetPool(sample-dataset)',
  group: 'datasetPool',
  component: Route,
  title: ({ t }) => t('curate.datasets.title'),
  isVisible: true,
  children: [SampleDatasetsListMenuItem, SampleDatasetMenuItem],
});
