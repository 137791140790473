import { Box } from '@superb-ai/ui';

import { useDiagnosisCommonFilterContext } from '../../../../../../contexts/DiagnosisCommonFilterContext';
import { DiagnosisDetail } from '../../../../../../services/DiagnosisModelService';
import ConfusionMatrixCard from './cards/ConfusionMatrixCard';
import DistributionChartCard from './cards/DistributionChartCard';
import PerformanceCurveCard from './cards/PerformanceCurveCard';
import { PerformanceTableCard } from './cards/PerformanceTableCard';
import PRCurveCard from './cards/PRCurveCard';

type Props = {
  selectedDiagnosis: DiagnosisDetail;
};
export default function ChartsLayout({ selectedDiagnosis }: Props): JSX.Element {
  const { splitIn } = useDiagnosisCommonFilterContext();
  return (
    <Box display="flex" flexDirection="row" width="100%">
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <Box display="flex" flexDirection="column" mr={1} mb={3} gap={1}>
          <ConfusionMatrixCard diagnosis={selectedDiagnosis} />
          {!splitIn.includes('TRAIN') && (
            <>
              <PerformanceTableCard diagnosis={selectedDiagnosis} />
              <PRCurveCard diagnosis={selectedDiagnosis} />
              <PerformanceCurveCard diagnosis={selectedDiagnosis} />
              <DistributionChartCard diagnosis={selectedDiagnosis} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
