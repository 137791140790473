import { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, vars } from '@superb-ai/ui';
import dynamic from 'next/dynamic';

import { useDebounce } from '../../../../../../../hooks/DebounceHook';
import { useObjectClusterContext } from '../../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../contexts/ObjectScopeContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { getScopeFromRoute } from '../../../../../utils/routeUtils';
import { LoadingIndicatorDiv } from '../../analytics/components/LoadingIndicatorDiv';
import { useObjectScatterContext } from '../../analytics/contexts/ObjectScatterContext';
import { ClassClusterParam } from '../../filter/types';
import NoDataView from '../../scatterView/NoDataView';
import NoResultsView from '../../scatterView/NoResultsView';
import { ScatterSamplingInfoBanner } from './SampledInfoBanner';
import { ScatterActionBar } from './ScatterActionBar';
import { usePoints } from './scatterView/providers/DataProvider';

const ScatterPlotRenderer = dynamic({
  loader: () => import('./scatterView/ScatterPlotRenderer').then(x => x.ScatterPlotRenderer),
  ssr: false,
});
export const ObjectScatterArea = ({
  width,
  height,
  cardStyle,
  chartAreaRef,
  chartAreaSize,
  setChartAreaSize,
}: {
  width: number | string;
  height: number | string;
  cardStyle: React.CSSProperties;
  chartAreaRef: RefObject<HTMLDivElement>;
  chartAreaSize?: { width: number; height: number };
  setChartAreaSize: (size: { width: number; height: number }) => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const points = usePoints();
  const scope = getScopeFromRoute(history);
  const { totalCount } = useObjectScopeContext();
  const {
    data: dataPoints,
    isFetching,
    isLoadingProjections: isLoadingObjectProjections,
    isLoadingProjectionsCompare: isLoadingObjectProjectionsCompare,
  } = useObjectScatterContext();
  const { setHoveredDotGroup, hoveredDotGroup, hoveredFilterGroup, clusterLevel } =
    useObjectClusterContext();
  const {
    selectedSuperClusters,
    setSelectedSuperClusters,
    classClusterFilterTab,
    selectedClasses,
    setSelectedClasses,
  } = useObjectFilterContext();

  const isLoading = isLoadingObjectProjections || isLoadingObjectProjectionsCompare;
  const { queryString } = useQueryContext();
  const { hasAppliedFilters } = useObjectFilterContext();
  const hasAppliedQueryOrFilters = Boolean(queryString || hasAppliedFilters);
  const debouncedChartAreaSize = useDebounce(chartAreaSize, 200);

  useEffect(() => {
    if (!chartAreaRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!chartAreaRef.current) {
        return;
      }
      const { width, height } = chartAreaRef.current.getBoundingClientRect();
      setChartAreaSize({
        width: width,
        height: height,
      });
    });

    observer.observe(chartAreaRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSelectGroups = (newGroups: ClassClusterParam[] | string[] | undefined) => {
    if (classClusterFilterTab === 'cluster') {
      setSelectedSuperClusters(newGroups as ClassClusterParam[]);
    } else {
      setSelectedClasses(newGroups as string[]);
    }
  };

  const handleHoverDotGroup = (groupId: string | null) => {
    setHoveredDotGroup && setHoveredDotGroup(groupId);
  };

  const getChartArea = () => {
    if (dataPoints && dataPoints.length === 0) {
      // TODO: bc there can be a lag between original datapoints and points
      if (hasAppliedQueryOrFilters) return <NoResultsView scope={scope} />;
      return <NoDataView scope={scope} />;
    }
    const BANNER_HEIGHT = 30;
    return (
      <Box>
        {debouncedChartAreaSize && (
          <ScatterSamplingInfoBanner
            scope={scope}
            samplingPointCount={points.length}
            totalPointCount={totalCount ?? 0}
            style={{ lineHeight: BANNER_HEIGHT + 'px' }}
          />
        )}
        <ScatterPlotRenderer
          canvasSize={[
            debouncedChartAreaSize?.width || 0,
            debouncedChartAreaSize ? debouncedChartAreaSize.height - BANNER_HEIGHT : 0,
          ]}
          hoveredGroup={hoveredDotGroup || hoveredFilterGroup}
          handleHoverGroup={handleHoverDotGroup}
          filterTab={classClusterFilterTab}
          selectedObjectGroups={
            classClusterFilterTab === 'cluster' ? selectedSuperClusters : selectedClasses ?? []
          }
          clusterLevel={clusterLevel}
          handleSelectObjectGroups={handleSelectGroups}
        />
      </Box>
    );
  };
  return (
    <Box
      display="flex"
      style={{ ...cardStyle, width, height }}
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box
        display="flex"
        width="100%"
        p={0.5}
        alignItems="center"
        justifyContent="space-between"
        style={{ boxShadow: `inset 0 -1px 0 ${vars.color['gray-250']}` }}
      >
        <ScatterActionBar
          width={width}
          height={height}
          chartAreaRef={chartAreaRef}
          setChartAreaSize={setChartAreaSize}
        />
      </Box>
      <Box
        ref={chartAreaRef}
        display="flex"
        width="100%"
        height="100%"
        position="relative"
        overflow="hidden"
      >
        {dataPoints === undefined || isFetching || isLoading ? (
          <LoadingIndicatorDiv message={t('curate.embeddings.text.loading')} />
        ) : (
          getChartArea()
        )}
      </Box>
    </Box>
  );
};
