import React from 'react';
import { SingleValue } from 'react-select';

import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { Option } from '../../../../../types/selectTypes';
import ProjectSelect from '../../../ProjectSelect';
import { UploadStepInfo } from './type';

const ProjectAssign = (t: TFunction): UploadStepInfo => {
  const { selectedProject, setSelectedProject, dataType } = useUploadInfo();

  const handleSelectProject = (props: SingleValue<Option>) => {
    const value = props ? props.value.trim() : '';
    setSelectedProject({ name: props?.label, id: value });
  };

  const getDataType = () => {
    switch (dataType) {
      case 'VIDEO_FRAMES':
      case 'VIDEO_STREAM':
        return 'video';
      case 'POINT_CLOUD_SEQUENCE':
        return 'pointclouds';
      case 'IMAGE':
      default:
        return 'image';
    }
  };

  return {
    title: t('data.upload.projectAssign'),
    isButtonEnabled: true,
    isOptional: true,
    summary: selectedProject?.name || '',
    content: (
      <ProjectSelect
        additionalParams={{ dataType: getDataType() }}
        onSelect={handleSelectProject}
      />
    ),
  };
};

export default ProjectAssign;
