import { createContext, useCallback, useContext, useMemo } from 'react';

import { calSupportedWorkapps } from '../apps/projects/project/autoLabel/cal/support';
import TierUnion from '../union/TierUnion';
import { useAuthInfo } from './AuthContext';
import { useFeatureFlag } from './FeatureFlagContext';
import { useProjectInfo } from './ProjectContext';

export const CalAvailabilityContext = createContext(
  {} as {
    canUseCustomAutoLabel: boolean;
    requiredLabelCountForCreatingCAL: number;
    getIsOverLabelCountForCreatingCAL: (labelCount: number) => boolean;
  },
);
export const CalAvailabilityProvider: React.FC = ({ children }) => {
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();

  const pointCloudsAutoLabelFlag = useFeatureFlag('pointCloudsAutoLabel');
  const createCustomAutoLabelWithoutLimitationFlag = useFeatureFlag(
    'createCustomAutoLabelWithoutLimitation',
  );

  const workapp = useMemo(() => {
    if (!projectInfo.project) return null;
    return projectInfo.project.workapp;
  }, [projectInfo.project]);

  const isVideoProject = useMemo(() => {
    if (!workapp) return false;
    return workapp === 'video-siesta';
  }, [workapp]);

  const isPointcloudsProject = useMemo(() => {
    if (!workapp) return false;
    return workapp === 'pointclouds-siesta';
  }, [workapp]);

  const canUseCustomAutoLabel = useMemo(() => {
    if (!workapp) return false;
    return (
      TierUnion.isTeamOrHigher(authInfo.tier) &&
      calSupportedWorkapps.includes(workapp) &&
      (isPointcloudsProject ? pointCloudsAutoLabelFlag : true)
    );
  }, [authInfo.tier, isPointcloudsProject, pointCloudsAutoLabelFlag, workapp]);

  const requiredLabelCountForCreatingCAL = useMemo(() => {
    return createCustomAutoLabelWithoutLimitationFlag
      ? 0
      : isVideoProject || isPointcloudsProject
      ? 10
      : 100;
  }, [createCustomAutoLabelWithoutLimitationFlag, isPointcloudsProject, isVideoProject]);

  const getIsOverLabelCountForCreatingCAL = useCallback(
    (labelCount: number) => {
      return labelCount >= requiredLabelCountForCreatingCAL;
    },
    [requiredLabelCountForCreatingCAL],
  );

  return (
    <CalAvailabilityContext.Provider
      value={{
        canUseCustomAutoLabel,
        requiredLabelCountForCreatingCAL,
        getIsOverLabelCountForCreatingCAL,
      }}
    >
      {children}
    </CalAvailabilityContext.Provider>
  );
};

export const useCalAvailabilityInfo = () => {
  return useContext(CalAvailabilityContext);
};
