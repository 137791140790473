import React from 'react';
import { Trans } from 'react-i18next';

import { Link, Typography } from '@superb-ai/norwegian-forest';
import { History } from 'history';
import { TFunction } from 'i18next';
import { startCase } from 'lodash';

interface MsgContent {
  text?: string;
  type?: string;
  link?: string;
  handleClick?: () => void;
}
interface MsgInfo {
  emoji: string;
  title: string;
  contents: MsgContent[];
}

const VARIANT = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
} as const;

export interface ModalInfo {
  variant: (typeof VARIANT)[keyof typeof VARIANT];
  title: string;
  content: JSX.Element;
  mainButton?: any;
  subButton?: any;
}

const EMOJI = {
  ERROR: '😵',
  WARNING: '🤔',
  INFO: '🧐',
  SUCCESS: '🥳',
};

type MsgFn<Props = unknown> = (props: Props & { t: TFunction }) => ModalInfo;

export const DEFAULT_ERROR: MsgFn<{
  message: string;
  onClickMainButton: () => void;
  title?: string;
}> = ({ t, message, onClickMainButton, title = t('errors.error') }) => ({
  variant: VARIANT.ERROR,
  title: `${title}`,
  content: <Typography variant="body3">{message}</Typography>,
  mainButton: {
    text: t('button.ok'),
    onClick: onClickMainButton,
  },
});

export const INVITATION_SESSION_EXPIRED: MsgFn<{ title: string }> = ({ t, title }) => ({
  variant: VARIANT.ERROR,
  title,
  content: <Typography variant="body3">{t('errors.tryAgain')}</Typography>,
});

export const REMOVE_MEMBER_FROM_PROJECT: MsgFn<{ name: string }> = ({ t, name }) => ({
  variant: VARIANT.INFO,
  title: t('projectMembers.dialogs.remove.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectMembers.dialogs.remove.text">
        Do you want to remove <strong>{{ name }}</strong> from the project?
      </Trans>
    </Typography>
  ),
});

export const REMOVE_MEMBERS_FROM_PROJECT: MsgFn<{ count: number }> = ({ t, count }) => ({
  variant: VARIANT.INFO,
  title: t('projectMembers.dialogs.removeMultiple.title', { count }),
  content: (
    <Typography variant="body3">{t('projectMembers.dialogs.removeMultiple.text')}</Typography>
  ),
});

export const DELETE_MEMBER_FROM_ACCOUNT: MsgFn<{ name: string }> = ({ t, name }) => ({
  variant: VARIANT.WARNING,
  title: t('users.dialogs.delete.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="users.dialogs.delete.text">
        Do you want to delete <strong>{{ name }}</strong> from the team?
      </Trans>
    </Typography>
  ),
});

export const DELETE_MEMBERS_FROM_ACCOUNT: MsgFn<{ count: number }> = ({ t, count }) => ({
  variant: VARIANT.INFO,
  title: t('users.dialogs.deleteMultiple.title', { count }),
  content: <Typography variant="body3">{t('users.dialogs.deleteMultiple.text')}</Typography>,
});

export const RESEND_CHANGE_PASSWORD: MsgFn<{ onClickMainButton: () => void }> = ({
  t,
  onClickMainButton,
}) => ({
  variant: VARIANT.INFO,
  title: t('auth.button.resend'),
  content: <Typography variant="body3">{t('auth.messages.resentVerificationCode')}</Typography>,
  mainButton: {
    text: t('button.ok'),
    onClick: onClickMainButton,
  },
});

export const RESEND_ACCOUNT_ACTIVATION_LINK: MsgFn<{ closeModal: () => void }> = ({
  t,
  closeModal,
}) => ({
  variant: VARIANT.SUCCESS,
  title: t('auth.messages.success'),
  content: (
    <Typography variant="body3">
      {t('auth.messages.weSentYouAnEmail')}
      <br />
      {t('auth.messages.activateIn24Hours')}
    </Typography>
  ),
  mainButton: {
    text: t('button.ok'),
    onClick: closeModal,
  },
});

export const DELETE_ASSET: MsgFn<{ count: number }> = ({ t, count }) => ({
  variant: VARIANT.WARNING,
  title: t('data.dialogs.deleteAsset.title', { count }),
  content: (
    <Typography variant="body3">
      {t('data.dialogs.deleteAsset.text', { count })}
      <br />
      {t('data.dialogs.deletedDataCannotBeRestored')}
    </Typography>
  ),
});

export const DELETE_DATASET: MsgFn<{ count: number }> = ({ t, count }) => ({
  variant: VARIANT.WARNING,
  title: t('data.dialogs.deleteDataset.title', { count }),
  content: (
    <Typography variant="body3">
      {t('data.dialogs.deleteDataset.text', { count })}
      <br />
      {t('data.dialogs.deletedDataCannotBeRestored')}
    </Typography>
  ),
});

export const DATASET_NAME_DUPLICATE: MsgFn<{
  currentDatasetName: string;
  newDatasetName: string;
}> = ({ t, currentDatasetName, newDatasetName }) => ({
  variant: VARIANT.WARNING,
  title: t('data.dialogs.datasetNameDuplicate.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="data.dialogs.datasetNameDuplicate.text">
        A dataset with this name already exists.
        <br />
        If you confirm, &quot;{{ currentDatasetName }}&quot; will be joined with &quot;
        {{ newDatasetName }}&quot; as long as there are no assets with duplicate data keys.
      </Trans>
    </Typography>
  ),
});

export const FINISH_CREATE_PROJECT: MsgFn<{ projectName: string }> = ({ t, projectName }) => ({
  variant: VARIANT.INFO,
  title: t('projectSettings.dialogs.create.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectSettings.dialogs.create.text">
        Finish creating &quot;{{ projectName }}&quot;?
      </Trans>
    </Typography>
  ),
});

export const EDIT_PROJECT_AFTER_ALERT: MsgFn<{ projectName: string }> = ({ t, projectName }) => ({
  variant: VARIANT.WARNING,
  title: t('projectSettings.dialogs.edit.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectSettings.dialogs.edit.text">
        Finish creating &quot;{{ projectName }}&quot;?
      </Trans>
    </Typography>
  ),
});

export const EDIT_PROJECT_BEFORE_ALERT: MsgFn = ({ t }) => ({
  variant: VARIANT.WARNING,
  title: t('errors.warning'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectSettings.dialogs.beforeEdit.text" />
    </Typography>
  ),
});

export const QUIT_CREATE_PROJECT: MsgFn = ({ t }) => ({
  variant: VARIANT.WARNING,
  title: t('errors.quit'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectSettings.dialogs.quitCreate.text" />
    </Typography>
  ),
});

export const DELETE_LABEL: MsgFn<{ count: number }> = ({ t, count }) => ({
  variant: VARIANT.WARNING,
  title: t('labels.dialogs.delete.title', { count }),
  content: (
    <Typography variant="body3">
      {t('labels.dialogs.delete.text', { count })}
      <br />
      {t('labels.dialogs.delete.exactNumberMayDiffer')}
    </Typography>
  ),
});

export const CANNOT_DELETE_PROJECT: MsgFn<{ onClickLink(): void }> = ({ t, onClickLink }) => ({
  variant: VARIANT.WARNING,
  title: t('projectSettings.dialogs.cannotDelete.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="projectSettings.dialogs.cannotDelete.text">
        Only an empty project can be deleted.
        <br />
        Please delete{' '}
        <Link themedColor="secondary" underlined onClick={onClickLink}>
          all labels
        </Link>{' '}
        and then try again.
      </Trans>
    </Typography>
  ),
});

export const NEW_PROJECT_DELETE_CATEGORY_GROUP: MsgFn = ({ t }) => ({
  variant: VARIANT.WARNING,
  title: t('projectSettings.dialogs.deleteCategoryGroup.title'),
  content: (
    <Typography variant="body3">{t('projectSettings.dialogs.deleteCategoryGroup.text')}</Typography>
  ),
});

export const UPGRADE_PLAN: MsgFn<{
  history: History;
  accountName: string;
  closeModal: () => void;
}> = ({ t, history, accountName, closeModal }) => ({
  variant: VARIANT.INFO,
  title: t('errors.upgradePlan.title'),
  content: (
    <>
      <Typography variant="body3">
        <Trans t={t} i18nKey="errors.upgradePlan.text" />
      </Typography>
      <Link
        variant="body3"
        href={`//${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`}
        external
        rel="noopener noreferrer"
        target="blank"
      >
        {t('errors.upgradePlan.learnMore')}
      </Link>
    </>
  ),
  mainButton: {
    text: t('errors.upgradePlan.upgrade'),
    onClick: () => {
      history.push(`/${accountName}/plan`);
    },
  },
  subButton: { text: t('button.cancel'), onClick: closeModal },
});

export const REQUEST_UPGRADING_PLAN: MsgFn<{ plan: string }> = ({ t, plan }) => ({
  variant: VARIANT.INFO,
  title: t('settings.billing.dialogs.upgradePlan.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="settings.billing.dialogs.upgradePlan.text">
        Do you want to upgrade to the <strong>{{ plan: startCase(plan.toLowerCase()) }}</strong>{' '}
        plan?
      </Trans>
    </Typography>
  ),
});

export const REQUEST_DOWNGRADING_PLAN: MsgFn<{ plan: string }> = ({ t, plan }) => ({
  variant: VARIANT.INFO,
  title: t('settings.billing.dialogs.downgradePlan.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="settings.billing.dialogs.downgradePlan.text">
        Do you want to downgrade to the <strong>{{ plan: startCase(plan.toLowerCase()) }}</strong>{' '}
        plan?
      </Trans>
    </Typography>
  ),
});

export const REQUEST_14_DAY_TRIAL: MsgFn = ({ t }) => ({
  variant: VARIANT.INFO,
  title: t('settings.billing.dialogs.teamTrial.title'),
  content: (
    <Typography variant="body3">
      <Trans t={t} i18nKey="settings.billing.dialogs.teamTrial.text" />
    </Typography>
  ),
  mainButton: {
    text: t('settings.billing.dialogs.request'),
  },
});

export const REQUEST_CHANGE_PLAN_SUCCESS: MsgFn<{ closeModal: () => void }> = ({
  t,
  closeModal,
}) => ({
  variant: VARIANT.SUCCESS,
  title: t('auth.messages.success'),
  content: (
    <Typography variant="body3">{t('settings.billing.dialogs.teamTrial.successText')}</Typography>
  ),
  mainButton: {
    text: t('button.ok'),
    onClick: closeModal,
  },
});

export const SKIP_INVITE: MsgFn = ({ t }) => ({
  variant: VARIANT.INFO,
  title: t('auth.dialogs.skipInvite.title'),
  content: <Typography variant="body3">{t('auth.dialogs.skipInvite.text')}</Typography>,
});

export const SKIP_CREATE_SAMPLE_PROJECT: MsgFn = ({ t }) => ({
  variant: VARIANT.INFO,
  title: t('auth.dialogs.skipSampleProject.title'),
  content: <Typography variant="body3">{t('auth.dialogs.skipSampleProject.text')}</Typography>,
});

// Legacy detail view
export const DELETE_ISSUE = (): MsgInfo => ({
  emoji: EMOJI.INFO,
  title: 'Delete',
  contents: [
    {
      text: 'Deleted issues cannot be restored',
    },
  ],
});
