import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Checkbox, NamedColor } from '@superb-ai/ui';
import { orderBy } from 'lodash';

import { useDiagnosisAnalyticsFilterContext } from '../../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import {
  ClassPerformance,
  OverallPerformance,
} from '../../../../../../../services/DiagnosisAnalyticsService';
import SortDropdown, { PerformanceTableSortBy } from '../../grid/SortDropdown';
import { SortOrder } from '../../types';
import MeanAvpMiniCard, { MetricInfo } from '../charts/MeanAvpMiniCard';
import { LOW_AP_THRESHOLD, LOW_PRECISION_THRESHOLD, LOW_RECALL_THRESHOLD } from '../const';
import PerformanceTable from './PerformanceTable';

export function isColumnClassName(columnKey: string): boolean {
  return columnKey === 'className';
}

export type FormattedClassPerformance = {
  [P in keyof ClassPerformance]: string;
};

export type PerformanceTableProps = {
  overallAPs?: OverallPerformance;
  data: ClassPerformance[];
};

export default function PerformanceTableChartArea(props: PerformanceTableProps) {
  const { data, overallAPs } = props;
  const { t } = useTranslation();

  const { selectedClass } = useDiagnosisAnalyticsFilterContext();

  const [filterByLowAP, setFilterByLowAP] = useState(false);
  const [filterByLowPrecision, setFilterByLowPrecision] = useState(false);
  const [filterByLowRecall, setFiilterByLowRecall] = useState(false);
  const [sortBy, setSortBy] = useState<PerformanceTableSortBy>('className');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const filterMetrics = (data: ClassPerformance[]) => {
    let result = data;
    if (filterByLowPrecision) {
      result = result.filter(datum => datum.precision < LOW_PRECISION_THRESHOLD);
    }
    if (filterByLowRecall) {
      result = result.filter(datum => datum.recall < LOW_RECALL_THRESHOLD);
    }
    if (filterByLowAP)
      result = result.filter(datum => datum.ap < LOW_AP_THRESHOLD || datum.ap50 < LOW_AP_THRESHOLD);
    return result;
  };

  const sortMetrics = (data: ClassPerformance[], sortBy: PerformanceTableSortBy) => {
    return orderBy(data, sortBy, sortOrder);
  };

  const sortedMetrics = sortMetrics(filterMetrics(data), sortBy);
  const isLowPrecisionDisabled = sortedMetrics.every(
    datum => datum.precision >= LOW_PRECISION_THRESHOLD,
  );
  const isLowRecallDisabled = sortedMetrics.every(datum => datum.recall >= LOW_RECALL_THRESHOLD);
  const isLowAPDisabled = sortedMetrics.every(datum => datum.ap >= LOW_AP_THRESHOLD);

  const TopRightArea = (
    <Box display="flex" ml="auto" gap={1} mr={2} style={{ borderRadius: '2px' }}>
      <Button
        disabled={isLowAPDisabled}
        variant={filterByLowAP ? 'soft-fill' : 'stroke'}
        onClick={() => setFilterByLowAP(!filterByLowAP)}
      >
        <Checkbox value={filterByLowAP} />
        {t('curate.diagnosis.classMetrics.lowAP')}
      </Button>
      <Button
        disabled={isLowPrecisionDisabled}
        variant={filterByLowPrecision ? 'soft-fill' : 'stroke'}
        onClick={() => setFilterByLowPrecision(!filterByLowPrecision)}
      >
        <Checkbox value={filterByLowPrecision} />
        {t('curate.diagnosis.classMetrics.lowPrecision')}
      </Button>
      <Button
        disabled={isLowRecallDisabled}
        variant={filterByLowRecall ? 'soft-fill' : 'stroke'}
        onClick={() => setFiilterByLowRecall(!filterByLowRecall)}
      >
        <Checkbox value={filterByLowRecall} />
        {t('curate.diagnosis.classMetrics.lowRecall')}
      </Button>
      <SortDropdown
        dropdownItems={[
          {
            key: 'className',
            label: t('curate.diagnosis.classMetrics.classNameFull'),
          },
          {
            key: 'ap',
            label: t('curate.diagnosis.classMetrics.apAbbr') + '@0.5:0.95',
          },
          {
            key: 'ap50',
            label: t('curate.diagnosis.classMetrics.ap50'),
          },
          {
            key: 'precision',
            label: t('curate.diagnosis.classMetrics.precision'),
          },
          {
            key: 'recall',
            label: t('curate.diagnosis.classMetrics.recall'),
          },
        ]}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    </Box>
  );
  return (
    <Box display="flex" flexDirection="column" height="100%" mb={2}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <MeanAvpMiniCard
          metric={
            {
              name: t('curate.diagnosis.metric.meanAveragePrecisionAbbr'),
              threshold: '@0.5:0.95',
              tooltipText: t('curate.diagnosis.classMetrics.meanAP50To95Info'),
              value: overallAPs?.ap,
            } as MetricInfo
          }
          color={'primary' as NamedColor}
          mt={1}
          mb={1.5}
          ml={1.5}
          mr={0.5}
        />
        <MeanAvpMiniCard
          metric={
            {
              name: t('curate.diagnosis.metric.meanAveragePrecisionAbbr'),
              threshold: '@0.5',
              tooltipText: t('curate.diagnosis.classMetrics.meanAP50Info'),
              value: overallAPs?.ap50,
            } as MetricInfo
          }
          color={'primary' as NamedColor}
          mt={1}
          mb={1.5}
          ml={1.5}
          mr={0.5}
        />
        {TopRightArea}
      </Box>
      <Box flexDirection="column" mx={1.5} mb={3} mt={1} mr={3}>
        <PerformanceTable data={sortedMetrics} selectedClass={selectedClass} />
      </Box>
    </Box>
  );
}
