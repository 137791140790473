import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Pencil } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../../../../contexts/AppContext';
import { useDatasetModalContext } from '../../../../../contexts/ModalContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import ListIsEmpty from '../../../ListIsEmpty';
import DataSectionContainer from '../../DataSectionContainer';
import FilterLayout from '../../filter/ObjectFilterLayout';
import SliceSettingsModal from '../../modal/SliceSettingsModal';
import ViewContentsArea from '../../views/ViewContentsArea';

export default function SliceObjectLayout(): JSX.Element {
  const { t } = useTranslation();
  const { sliceId } = useParams<{
    sliceId?: string;
  }>();

  const { sliceSettingsIsOpen, setSliceSettingsIsOpen } = useDatasetModalContext();

  const { sliceInfo, isLoading } = useSliceContext();

  const [hoverSliceInfo, setHoverSliceInfo] = useState<boolean>(false);

  useSetPageTitle(t('curate.dataset.menus.slice'), PAGE_TRACKING_ID.curateDatasetSlice);

  if (!sliceInfo) return <></>;

  const SliceObjectNameBar = (
    <Box
      display="flex"
      alignItems="center"
      onMouseEnter={() => setHoverSliceInfo(true)}
      onMouseLeave={() => setHoverSliceInfo(false)}
    >
      <p
        style={{
          maxWidth: '600px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          minWidth: '150px',
        }}
      >
        <Typography variant="l-strong">{sliceInfo?.name || ''}</Typography>
        <br />
        <Typography variant="m-regular">{sliceInfo?.description || '...'}</Typography>
      </p>
      {hoverSliceInfo && (
        <Button
          style={{ marginLeft: '12px' }}
          onClick={() => {
            setSliceSettingsIsOpen(true);
          }}
          color="gray"
          variant="stroke"
          size="m"
        >
          <Icon icon={Pencil} />
          {t('curate.button.edit')}
        </Button>
      )}
    </Box>
  );
  return (
    <Box>
      <Box display="flex">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Box
            pt={2}
            pl={4}
            pr={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {SliceObjectNameBar}
          </Box>
          <DataSectionContainer
            isLoading={isLoading}
            isEmpty={(sliceInfo?.imageCount || 0) <= 0}
            EmptyView={<ListIsEmpty listType={sliceInfo?.name || ''} />}
            ViewContents={<ViewContentsArea />}
          />
        </Box>
        <FilterLayout />
      </Box>
      {sliceSettingsIsOpen && <SliceSettingsModal />}
    </Box>
  );
}
