import { meanBy, sumBy } from 'lodash';

import MathUtils from '../../../../../utils/MathUtils';
import { LabelerDatum, LabelerLabelsDatum, ReviewerDatum } from '../types';

export const calculateAvgObjectCountPerLabel = (rows: LabelerDatum[]): number => {
  return MathUtils.calculateRatio({
    numerator: sumBy(rows, 'objectCounts'),
    denominator: sumBy(rows, 'labelCounts'),
  });
};

export const calculateAvgTimePerLabelByLabeler = (rows: LabelerDatum[]): number => {
  return meanBy(rows, 'timePerLabelSec');
};

export const calculateAvgTimePerFrameByLabeler = (rows: LabelerDatum[]): number => {
  const avgFrame = rows.reduce(
    (row, cv) => {
      row.totalFrameCount += cv['frameCounts'] as number;
      row.totalLabelingTime += cv['timespentSec'] as number;
      return row;
    },
    {
      totalFrameCount: 0,
      totalLabelingTime: 0,
    },
  );

  return avgFrame.totalFrameCount / avgFrame.totalLabelingTime;
};

export const calculateLabelingProgress = (data: LabelerLabelsDatum[]): number => {
  const sumSubmittedAndSkipped = (agg: number, item: LabelerLabelsDatum) =>
    agg + item['cumSubmitted'] + item['cumSkipped'];
  const sumAllLabels = (agg: number, item: LabelerLabelsDatum) =>
    agg + item['cumSubmitted'] + item['cumSkipped'] + item['cumWorking'];

  return MathUtils.calculatePercent({
    numerator: data.reduce((agg, item) => sumSubmittedAndSkipped(agg, item), 0),
    denominator: data.reduce((agg, item) => sumAllLabels(agg, item), 0),
  });
};

export const calculateReviewProgress = (data: ReviewerDatum[]): number => {
  return MathUtils.calculatePercent({
    numerator: sumBy(data, 'approved'),
    denominator: sumBy(data, 'reviewAssigned'),
  });
};

export const calculateLabelsRequestedForReview = (
  data: ReviewerDatum[],
  totalLabelCount: number,
) => {
  return MathUtils.calculatePercent({
    numerator: sumBy(data, 'reviewAssigned'),
    denominator: totalLabelCount,
  });
};
