import { Box, Icon, RadioButtons, Typography } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';
import { TFunction } from 'next-i18next';

import { CsvUploadMethod, useUploadInfo } from '../../../../../contexts/UploadContext';
import { UploadStepInfo } from './type';

const SelectCsvUploadType = ({
  isButtonEnabled,
  hasWarning,
  t,
}: {
  isButtonEnabled: boolean;
  hasWarning: boolean;
  t: TFunction;
}): UploadStepInfo => {
  const { csvUploadMethod, setCsvUploadMethod } = useUploadInfo();

  const csvUploadOptions = [
    {
      label: (
        <Box>
          <Box display="flex" alignItems="center">
            <Icon name="link2" />
            <Box display="flex" alignItems="center" ml={0.5}>
              URL
            </Box>
          </Box>
          <Box mt={1}>
            <Typography themedColor={['grey', 300]}>{t('data.upload.enterFileURLS')}</Typography>
          </Box>
        </Box>
      ),
      value: 'URL',
    },
    {
      label: (
        <Box>
          <Box display="flex" alignItems="center">
            <Icon name="fileCsv" />
            <Box display="flex" alignItems="center" ml={0.5}>
              CSV
            </Box>
          </Box>
          <Box mt={1}>
            <Typography themedColor={['grey', 300]}>{t('data.upload.uploadCSVFile')}</Typography>
          </Box>
        </Box>
      ),
      value: 'CSV',
    },
  ] as Option<CsvUploadMethod>[];

  const summary = {
    URL: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <Icon name="link2" />
        </Box>
        <Typography>URL</Typography>
      </Box>
    ),
    CSV: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <Icon name="fileCsv" />
        </Box>
        <Typography>CSV</Typography>
      </Box>
    ),
  }[csvUploadMethod];

  return {
    title: t('data.upload.uploadType.title'),
    isButtonEnabled: isButtonEnabled,
    summary,
    content: (
      <>
        {hasWarning && (
          <Box
            themedBackgroundColor={['primary', 100]}
            themedColor="primary"
            display="flex"
            alignItems="center"
            p={1}
            mb={1.5}
          >
            <Icon name="warningOutline" size="12px" />
            <Box display="flex" alignItems="center" ml={0.5}>
              <Typography variant="body3">{t('data.upload.uploadType.description')}</Typography>
            </Box>
          </Box>
        )}
        <RadioButtons
          options={csvUploadOptions}
          value={csvUploadMethod}
          onChange={(value: CsvUploadMethod) => {
            setCsvUploadMethod(value);
          }}
        />
      </>
    ),
  };
};

export default SelectCsvUploadType;
