import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Button, Typography } from '@superb-ai/ui';
import { addMonths, format } from 'date-fns';

import { isOwner, useAuthInfo } from '../../../../contexts/AuthContext';
import {
  useAwsPaymentMutation,
  useSubscriptionQuery,
} from '../../../../queries/useSubscriptionQuery';
import { Plan } from '../../../../types/planTypes';
import { useSearchParamsObj } from '../../../../utils/router-utils';
import { ReactComponent as MarketplaceLogo } from '../assets/marketplace_logo.svg';
import {
  AlreadyPaid,
  AwsPaymentEnableError,
  ErrorHandler,
  PaymentInvalid,
  UserRoleInvalid,
} from './ErrorHandler';

export default function AwsPayment() {
  const history = useHistory();
  const searchParams = useSearchParamsObj();
  // searchParams.get('token') 으로 가져오면 url decode 된 string이 들어와서 token이 깨짐 때문에 직접 파싱해야함
  const token = history.location.search.match(/token=[^&]+/gi)?.[0].split('=')[1];
  const account = searchParams.get('account');
  const authInfo = useAuthInfo();
  const { t } = useTranslation();
  const { accountName, isInitialized, isLoggedIn, setIsGuest } = authInfo;
  const { data, isLoading } = useSubscriptionQuery();
  const { mutateAsync: enablePayment, isLoading: isEnableLoading } = useAwsPaymentMutation();
  const [error, setError] = useState<null | AwsPaymentEnableError>(null);

  useEffect(() => {
    setIsGuest(false);

    if (isInitialized && !isLoggedIn) {
      history.push(`/auth/login?next=/payments/aws${encodeURIComponent(history.location.search)}`);
    }
  }, [isInitialized, isLoggedIn]);

  if (!isInitialized || !isLoggedIn || isLoading) {
    return <></>;
  }

  if (error) return <ErrorHandler error={error} />;

  if (!token) return <PaymentInvalid />;

  if (!isOwner(authInfo)) return <UserRoleInvalid />;

  if (
    ['TEAM', 'ENTERPRISE'].includes(data?.tier.name.toUpperCase() as Plan) &&
    data?.status === 'active'
  )
    return <AlreadyPaid />;

  const enableAwsPayment = async () => {
    try {
      await enablePayment(decodeURIComponent(token));
      history.replace(`/${accountName}/settings/billing`);
    } catch (e: any) {
      // error code reference: https://www.notion.so/superbai/AWS-Marketplace-Enablement-60e5143ce00747578b897091e239a925
      if (e.response?.body) {
        const errorCode = e.response.body.errorCode as
          | 'payment.invalid'
          | 'tenant.invalid'
          | 'tenant.alreadyPaid'
          | 'internal.externalCommunication'
          | 'internal.resourceNotFound'
          | 'notDefined';

        switch (errorCode) {
          case 'payment.invalid':
            setError('paymentInvalid');
            return;
          case 'tenant.alreadyPaid':
            setError('alreadyPaid');
            return;
          case 'internal.externalCommunication':
          case 'internal.resourceNotFound':
          case 'notDefined':
          case 'tenant.invalid':
          default:
            setError('serviceError');
            return;
        }
      }
    }
  };

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="gray-100"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        backgroundColor="white"
        style={{ padding: '50px', width: '680px' }}
        display="flex"
        flexDirection="column"
      >
        <Box mb={2}>
          <img width="90px" src="https://asset.superb-ai.com/assets/img/new_logo.png" />
        </Box>
        <Box mb={2}>
          <Typography variant="h2" display="flex" alignItems="center" gap={1.5}>
            <Trans
              i18nKey="payments.billWith"
              components={{ MarketplaceLogo: <MarketplaceLogo /> }}
            />
          </Typography>
        </Box>
        <Box mb={1.5}>
          <Typography variant="l-regular" display="flex" alignItems="center" gap={1.5}>
            {t('payments.description', { date: format(addMonths(new Date(), 12), 'MM/dd/yyyy') })}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="l-regular" display="flex" alignItems="center" gap={1.5}>
            {t('payments.linkDescription')}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          px={3}
          py={2.5}
          mb={3}
          borderRadius="2px"
          backgroundColor="gray-100"
        >
          <Box display="flex" alignItems="center" gap={5}>
            <Typography variant="l-regular" style={{ width: '140px' }}>
              {t('payments.superbAiTeam')}
            </Typography>
            <Typography variant="l-strong">{accountName}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={5}>
            <Typography variant="l-regular" style={{ width: '140px' }}>
              {t('payments.awsAccountId')}
            </Typography>
            <Typography variant="l-strong">{account}</Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="l"
            variant="strong-fill"
            color="primary"
            style={{ width: '120px' }}
            loading={isEnableLoading}
            onClick={enableAwsPayment}
          >
            {t('button.confirm')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
