import { WindowCheck } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { Layout } from './Layout';

export const OverviewMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelOverview,
  path: 'overview',
  title: ({ t }) => t('model.overview.title'),
  component: Layout,
  children: [],
  icon: <Icon icon={WindowCheck} />,
});
