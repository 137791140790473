import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CaretDown, CaretRight, Folder, FolderOutline, Plus } from '@superb-ai/icons';
import { Box, Button, Icon } from '@superb-ai/ui';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';
import CategoryItem from './CategoryItem';
import GroupItemDropBar from './GroupItemDropBar';
import GroupItemDropPanel from './GroupItemDropPanel';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff6f6',
    },
    '&.selected': {
      background: '#ffeeee',
    },
  },
  iconButton: {
    padding: '0px',
    width: '20px',
    height: '20px',
    marginRight: '2px',
  },
  createSubgroupButton: {
    position: 'absolute',
    right: 12,
    padding: '6px 12px 6px 10px',
    fontWeight: 400,
    color: '#9e9e9e',
    fontSize: '10px',
    borderRadius: '7px',
  },
  subcategoryBox: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '18px',
  },
  subgroupBox: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
interface Props {
  index: number;
  group: any;
  imageCategory: any;
  imageCategoryMap: any;
  depth: number;
}

const GroupItem = ({
  index,
  group,
  imageCategory,
  imageCategoryMap,
  depth,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const groupRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const [isOpen, setIsOpen] = useState(true);
  const hasSubgroup = group.children[0] && imageCategoryMap[group.children[0]].children;
  const { id } = group;
  const isSelected = newProjectInfo.selectedCategoryGroupId === id;
  const pl = (depth + 1) * 20;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleClickGroup = () => {
    if (!isSelected) {
      newProjectInfo.setSelectedCategoryGroupId(id);
    }
    newProjectInfo.setSelectedCategories({});
  };

  const handleClickArrow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Category group name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const parent = imageCategoryMap[group.parent];
    const sameHierarchyNameList = parent.children
      .filter((childId: string) => imageCategoryMap[childId].children)
      .map((childId: string) => imageCategoryMap[childId].name);

    return parentHelper.checkNameRule({
      t,
      type: 'Category group name',
      prevValue: group.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
    });
  };

  const editName = (newValue: string) => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    const { imageCategoryMap } = selectedImageCategory;
    imageCategoryMap[id].name = newValue;
    newProjectInfo.setImageCategories(nextImageCategories);
  };

  const handleClickCreateSubgroupButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const nextChildren = cloneDeep(group.children);

    helper.createGroup(newProjectInfo, id, nextChildren, true, hasSubgroup);
  };

  const getCreateSubgroupButton = () => {
    if (!isHovered) return null;
    if (depth !== 0) return null;
    return (
      <Button variant="stroke" size="s" onClick={handleClickCreateSubgroupButton}>
        <Icon icon={Plus} />
        {t('projects.createProject.createSubgroup')}
      </Button>
    );
  };

  const getChildren = () => {
    if (!isOpen) return null;
    if (hasSubgroup) {
      return (
        <MUI.Box className={classes.subgroupBox}>
          {group.children.map((groupId: string, index: number) => (
            <GroupItem
              key={groupId}
              index={index}
              group={imageCategoryMap[groupId]}
              {...{ imageCategory, imageCategoryMap }}
              depth={depth + 1}
            />
          ))}
        </MUI.Box>
      );
    }
    return (
      <MUI.Box className={classes.subcategoryBox} paddingLeft={`${pl + 40}px`}>
        {group.children.map((categoryId: string, index: number) => (
          <CategoryItem
            key={categoryId}
            index={index}
            category={imageCategoryMap[categoryId]}
            imageCategoryMap={imageCategoryMap}
          />
        ))}
      </MUI.Box>
    );
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (groupRef && groupRef.current) {
      newProjectInfo.setDragState({ type: 'imageCategoryGroup', id });
      groupRef.current.style.cursor = 'grabbing';
      if (!e?.dataTransfer) return;
      e.dataTransfer.setData('text', id);
      e.dataTransfer.setDragImage(groupRef.current, -10, -10);
    }
  };

  const handleDragEnd = () => {
    if (groupRef && groupRef.current) {
      newProjectInfo.setDragState(null);
      groupRef.current.style.cursor = 'pointer';
    }
  };

  return (
    <MUI.Box position="relative">
      {index === 0 ? (
        <GroupItemDropBar
          dir="prev"
          id={id}
          depth={depth}
          pl={pl}
          imageCategoryMap={imageCategoryMap}
        />
      ) : null}
      <MUI.Box
        {...{ ref: groupRef }}
        className={`${classes.box} ${isSelected ? 'selected' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleClickGroup}
        draggable
      >
        <MUI.Box width={pl} />
        <MUI.IconButton className={classes.iconButton} onClick={handleClickArrow}>
          {isOpen ? (
            <Icon icon={CaretDown} size={18} color="gray-400" />
          ) : (
            <Icon icon={CaretRight} size={18} color="gray-400" />
          )}
        </MUI.IconButton>
        <Box mr={2}>
          {isOpen ? (
            <Icon icon={FolderOutline} size={18} color="gray-400" />
          ) : (
            <Icon icon={Folder} size={18} color="gray-400" />
          )}
        </Box>
        <EditableText
          text={group.name}
          maxWidth={depth === 0 ? `${190 - pl}px` : '280px'}
          fontStyles={{
            fontSize: '13px',
            color: '#797979',
            fontWeight: 500,
          }}
          parseValue={parseValue}
          checkRule={checkNameRule}
          editText={editName}
          isMount={newProjectInfo.createState === 'IMAGE_CATEGORY_GROUP'}
        />
        {getCreateSubgroupButton()}
        <GroupItemDropPanel
          group={group}
          depth={depth}
          hasSubgroup={hasSubgroup}
          imageCategoryMap={imageCategoryMap}
        />
      </MUI.Box>
      {getChildren()}
      <GroupItemDropBar
        dir="next"
        id={id}
        depth={depth}
        pl={pl}
        imageCategoryMap={imageCategoryMap}
      />
    </MUI.Box>
  );
};

export default GroupItem;
