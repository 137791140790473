import React from 'react';

import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Icon, Typography } from '@superb-ai/norwegian-forest';
import { LoadingIndicator } from '@superb-ai/ui';

import {
  AdvancedAIFeaturesProgress,
  AdvancedAIFeaturesStatus,
} from '../../../types/advancedAIFeaturesTypes';
import { formatPositiveDistanceShort } from '../../../utils/date';

const useStyle = makeStyles(() => ({
  exportProcessing: {
    color: '#FFCC00',
  },
}));

type Props = {
  progressInfo: AdvancedAIFeaturesProgress;
  state: AdvancedAIFeaturesStatus;
};

const RequestProgress: React.FC<Props> = props => {
  const { progressInfo, state } = props;
  const classes = useStyle();

  const currentTime = new Date();

  const getElapsedTime = (processingStartedAt: number) => {
    return currentTime.getTime() - processingStartedAt;
  };

  const getProgress = () => {
    if (!progressInfo) return 0;
    if (state !== 'PROCESSING') return 0;
    const { processingStartedAt, estimatedElapsedTime } = progressInfo;

    const progress = getElapsedTime(processingStartedAt) / estimatedElapsedTime;
    if (progress >= 0.99) return 0.99;
    return progress;
  };

  const remainingTime = (() => {
    if (!progressInfo) return;
    if (state !== 'PROCESSING') return;
    const { processingStartedAt, estimatedElapsedTime } = progressInfo;
    const processingProgress = getProgress();

    if (!processingProgress || !processingStartedAt || processingProgress < 1e-4) return 'loading';

    const remainingMilliSecs = estimatedElapsedTime - getElapsedTime(processingStartedAt);
    const endDate = new Date(+new Date() + remainingMilliSecs);

    return formatPositiveDistanceShort(endDate);
  })();

  const percentage = Math.round(getProgress() * 100);

  switch (state) {
    case 'FAIL':
      return <Icon name="error" color="primary" size="48px" />;
    case 'PENDING':
      return <LoadingIndicator size="m" color="yellow" />;
    case 'SUCCESS':
      return <Icon name="check" color="green" size="48px" />;
    case 'PROCESSING':
      if (percentage === 0) {
        return <LoadingIndicator size="m" color="yellow" />;
      }

      return (
        <Box position="relative" display="inline-flex">
          <CircularProgress
            className={classes.exportProcessing}
            variant="determinate"
            size="56px"
            thickness={4}
            value={percentage}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <Typography
                style={{ textAlign: 'center', lineHeight: 1 }}
                variant="headline7"
                themedColor="yellow"
              >{`${percentage}%`}</Typography>
              <Typography
                style={{ textAlign: 'center' }}
                themedColor={['grey', 500]}
                variant="body5"
              >
                {remainingTime}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

export default RequestProgress;
