import { WindowCheck } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../../menu/MenuItem';
import Layout from './Layout';

export const DatasetExploreMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetExplore,
  path: 'explore',
  icon: <Icon icon={WindowCheck} />,
  title: ({ t }) => t('curate.dataset.menus.explore'),
  component: Layout,
});
