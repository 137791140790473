import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SubdirectoryArrowRight } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { formatDistance } from '../../../../utils/date';
import Mentions from '../../../elements/mentions/Mentions';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';
import UserAvatar from '../../../elements/UserAvatar';

const useStyles = makeStyles(() => ({
  box: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    '&.last': {
      marginTop: '8px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  nameText: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#303030',
    maxWidth: '90px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  roleChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    height: '15px',
    padding: '1px 4px',
  },
  dateText: {
    fontSize: '11px',
    color: '#a6a6a6',
  },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#635c5c',
    fontSize: '12px',
    '&.first': {
      paddingRight: '14px',
    },
    '&.last': {
      padding: '0px 0px 0px 20px',
    },
  },
  message: {
    '&.MuiTypography-root': {
      whiteSpace: 'unset',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      maxHeight: '30px',
      maxWidth: '250px',
    },
  },
}));

// eslint-disable-next-line
const CabinetIssueCardItem = (props: any): React.ReactElement => {
  const classes = useStyles();
  const projectInfo = useProjectInfo();

  const { comment, order } = props;
  const { createdBy, lastUpdatedAt, message } = comment;
  const userObject = projectInfo.userObjects[createdBy];
  const name = userObject ? userObject.name : createdBy;

  return (
    <MUI.Box className={`${classes.box} ${order}`}>
      <MUI.Box className={classes.header}>
        <MUI.Box className={classes.headerLeft}>
          {order !== 'first' && (
            <Icon
              icon={SubdirectoryArrowRight}
              size={12}
              color="gray-500"
              style={{ marginRight: '2px', marginLeft: '7px' }}
            />
          )}
          <UserAvatar size={16} noShadow userInfo={userObject} />
          <TextEllipsisTooltip text={name} maxWidth="100%">
            <MUI.Typography className={classes.nameText}>{name} </MUI.Typography>
          </TextEllipsisTooltip>
        </MUI.Box>
        <MUI.Box>
          <MUI.Typography className={classes.dateText}>
            {formatDistance(lastUpdatedAt)}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
      <MUI.Box className={`${classes.section} ${order}`}>
        <Mentions boxProps={{ width: '100%' }} value={message} />
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetIssueCardItem;
