import {
  ComponentProps,
  forwardRef,
  MutableRefObject,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import LabeledInput from './LabeledInput';

interface CustomRef {
  origin: HTMLInputElement | null;
  focusWithError: () => void;
}

type ObjectRef = MutableRefObject<Record<string, CustomRef | null>>;

interface Props<T extends ObjectRef> extends ComponentProps<typeof LabeledInput> {
  validation: (value: string) => string | null;
  refs?: T;
  refKey?: keyof T['current'];
  placeholder?: string;
}

export default forwardRef(function ValidationInput<T extends ObjectRef>(
  { validation, refKey, refs, ...props }: Props<T>,
  ref: Ref<unknown>,
) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(validation(e.target.value));
    props?.onChange?.(e);
  };

  useImperativeHandle(
    refs,
    () => ({
      ...refs?.current,
      [refKey ?? props.label ?? '']: {
        origin: inputRef.current,
        focusWithError: () => {
          setError(validation(`${props.value}`));
          inputRef.current?.focus();
        },
      },
    }),
    [refKey],
  );

  useImperativeHandle(ref, () => inputRef);

  return <LabeledInput error={error ?? undefined} {...props} ref={inputRef} onChange={onChange} />;
});

export const useValidationInputRefs = <T extends readonly string[]>(keys: T) => {
  type Refs = Record<T[number], CustomRef | null>;
  const refs = useRef<Refs>(Object.fromEntries(keys.map(key => [key, null])) as Refs);

  return refs;
};

export const blurAllInputs = <T extends readonly string[]>(
  refs: React.MutableRefObject<Record<T[number], CustomRef | null>>,
) => {
  (Object.values(refs.current) as (CustomRef | null)[]).forEach(customRef => {
    if (customRef) {
      customRef.origin?.blur();
    }
  });
};
