import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router';

import {
  ChevronRight,
  InfoFilled,
  LoadingSpinnerAlt,
  PinFilled,
  PinOutline,
  Radio,
  RadioChecked,
  Search,
} from '@superb-ai/icons';
import {
  Box,
  Chip,
  extendComponent,
  Icon,
  Input,
  Select,
  Tooltip,
  Typography,
} from '@superb-ai/ui';
import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';

import { Row } from '../../../../components/elements/Row';
import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import { getUrl } from '../../../../routes/util';
import { TaskTypeChip } from '../../components/components';
import { useIntersect } from '../../hooks';
import { GENERATION_BASELINE_MODEL } from '../../path';
import { useModelTagsQuery, usePublicModelListQuery } from '../../queries/modelQueries';
import { RecognitionAIDetailMenuItem } from '../../recognition-ai/detail/MenuItem';
import { isRecognitionAIModelTrainingResult } from '../../services/modelTrainingResultTypeGuards';
import { PublicModel } from '../../services/types';
import { useBaselineContext } from '../contexts/BaselineContext';
import { useDatasetClassContext } from '../contexts/DatasetClassContext';

export function BaselineModelStep() {
  const { t } = useTranslation();

  const {
    modelPurpose,
    setModelPurpose,
    modelSource,
    setModelSource,
    setSelectedPublicModelId,
    setSelectedMyModelId,
    setTaskType,
  } = useBaselineContext();
  const { setSplitType } = useDatasetClassContext();
  const modelPurposeOptions: { value: typeof modelPurpose; label: string }[] = [
    {
      value: 'recognition' as const,
      label: t('model.train.recognitionAI'),
    },
    {
      value: 'generation' as const,
      label: t('model.train.generativeAI'),
    },
  ];

  const modelSourceOptions: { value: typeof modelSource; label: string }[] = [
    {
      value: 'public_model' as const,
      label: t('model.train.selectFromModelHub'),
    },
    {
      value: 'my_model' as const,
      label: t('model.train.selectFromMyModel'),
    },
  ];

  // 상위항목 변경 시 하위항목 초기화
  const resetUnderPurposeStage = () => {
    setModelSource(null);
    setSelectedPublicModelId(null);
    setSelectedMyModelId(null);
    setTaskType('all_task');
    setSplitType(null);
  };

  // 상위항목 변경 시 하위항목 초기화
  const resetUnderSourceStage = () => {
    setSelectedPublicModelId(null);
    setTaskType('all_task');
    setSelectedMyModelId(null);
    setSplitType(null);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="grid" mb={2} style={{ gridTemplateColumns: '1fr 1fr', columnGap: 8 }}>
          <Box>
            <Typography variant="m-strong">{t('model.train.purpose')}</Typography>
            <Box display="flex" mt={1}>
              <Select
                value={modelPurpose}
                data={modelPurposeOptions}
                onChangeValue={v => {
                  resetUnderPurposeStage();
                  setModelPurpose(v);
                }}
                placeholder={t('model.endpoints.selectModelPurposePlaceholder')}
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="m-strong">{t('model.train.source')}</Typography>
            <Box display="flex" mt={1}>
              <Select
                disabled={!modelPurpose}
                data={modelSourceOptions}
                value={modelSource}
                onChangeValue={v => {
                  resetUnderSourceStage();
                  setModelSource(v);
                }}
                placeholder={t('model.train.selectModelSource')}
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" style={{ flex: '1 1 auto' }}>
          <Typography variant="m-strong">{t('model.myModels.baselineModel')}</Typography>
          <BaselineModelSelect />
        </Box>
      </Box>
    </>
  );
}

const BaselineModelSelect = () => {
  const { t } = useTranslation();
  const baselineModelQuery = usePublicModelListQuery({
    params: {
      modelPurpose: 'generation',
    },
  });
  const baselineModelList = baselineModelQuery.data?.pages.flatMap(p => p.data) ?? [];

  const { modelSource, taskType, modelPurpose } = useBaselineContext();
  const taskTypeOptions: { value: typeof taskType; label: string }[] = [
    { value: 'all_task', label: t('model.train.allTask') },
    { value: 'object_detection', label: t('model.datasetType.object_detection') },
    { value: 'instance_segmentation', label: t('model.datasetType.instance_segmentation') },
  ];

  if (!modelSource) {
    return (
      <BaselineModelContainer
        display="flex"
        alignItems="center"
        backgroundColor={'gray-100'}
        textAlign="center"
        justifyContent="center"
      >
        <Typography variant="m-regular" color="gray-300">
          <Trans t={t} i18nKey={'model.train.selectPurposeSource'} />
        </Typography>
      </BaselineModelContainer>
    );
  }
  if (modelPurpose === 'generation' && modelSource === 'public_model') {
    return (
      <>
        {baselineModelList.map(data => {
          return <TemperalGenerationPublicModelRow data={data} key={data.id} />;
        })}
      </>
    );
  }
  return (
    <BaselineModelContainer p={1}>
      {modelSource === 'public_model' && <TrainNewModel taskTypeOptions={taskTypeOptions} />}
      {modelSource === 'my_model' && <TrainByMyModel taskTypeOptions={taskTypeOptions} />}
    </BaselineModelContainer>
  );
};

const TrainNewModel = ({
  taskTypeOptions,
}: {
  taskTypeOptions: { value: typeof taskType; label: string }[];
}) => {
  const { t } = useTranslation();
  const { publicModels, taskType, setTaskType } = useBaselineContext();
  const [searchText, setSearchText] = useState('');

  const filteredBaselineModels = publicModels
    .filter(m => (taskType !== 'all_task' ? m.task === taskType : true))
    .filter(m => m.name.toUpperCase().includes(searchText.toUpperCase()));

  return (
    <>
      <Row display="grid" mb={1} style={{ gridTemplateColumns: '1fr 192px', columnGap: 8 }}>
        <Input
          prefix={<Icon icon={Search} />}
          color="gray"
          variant="soft-fill"
          placeholder={t('model.train.modelSearchPlaceholder')}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <Select value={taskType} data={taskTypeOptions} onChangeValue={v => setTaskType(v)} />
      </Row>
      <Box overflow="auto" style={{ height: 644 }}>
        {filteredBaselineModels.map(data => {
          return <PublicModelRow key={data.id} data={data} />;
        })}
      </Box>
    </>
  );
};

const BaselineModelContainer = extendComponent(Box, {
  mt: 1,
  border: '1px solid',
  borderColor: 'gray-200',
  mb: 3,
  style: { height: 700, minWidth: 776 },
});

const PublicModelRow = ({ data }: { data: PublicModel }) => {
  const { t } = useTranslation();
  const { publicModelId, setSelectedPublicModelId } = useBaselineContext();
  const isSelected = publicModelId === data.id;

  const boldNumbersAndEscapeHtml = (input: string): React.ReactNode => {
    const result: React.ReactNode[] = [];
    for (let i = 0; i < input.length; i++) {
      if (!isNaN(Number(input[i]))) {
        result.push(<b key={i}>{input[i]}</b>);
      } else {
        result.push(escapeHtml(input[i]));
      }
    }
    return result;
  };

  const escapeHtml = (unsafe: string): string => {
    return unsafe.replace(/[&<"']/g, m => {
      switch (m) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#039;';
        default:
          return m;
      }
    });
  };

  const resolveI18nKey = (data: PublicModel, i18nKey: string) => {
    // 서버에서 먼저 추가된 baseline model의 성능에 관한 값이 i18n으로 준비되지 않았을 때 서버 값을 직접 참조
    if (t(i18nKey) === i18nKey) {
      // property: description, inferenceSpeed, throughput, maximumInputSize, performance, etc...
      const property = i18nKey.split('.')[4] as keyof PublicModel;
      return data[property] as string;
    } else {
      // i18n이 준비되어있을 경우 그대로 번역
      return t(i18nKey);
    }
  };
  return (
    <Box
      border="1px solid"
      borderColor={isSelected ? 'primary-400' : 'gray-200'}
      p={1.5}
      mb={1}
      cursor="pointer"
      onClick={() => setSelectedPublicModelId(data.id)}
      style={{ borderRadius: '1px', height: 113 }}
    >
      <Row>
        <Icon
          icon={isSelected ? RadioChecked : Radio}
          size={16}
          color={isSelected ? 'primary-400' : undefined}
          style={{ marginRight: 4 }}
        />
        <Link
          href={data.sourceUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Typography variant="m-medium" color="gray-400">
            {data.name}
          </Typography>
        </Link>
        <Icon icon={ChevronRight} size={16} />
        <Box ml={'auto'}>
          <TaskTypeChip taskType={data.task} />
        </Box>
      </Row>
      <Box style={{ paddingLeft: 20 }}>
        <Typography variant="m-regular">
          {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.description`)}
        </Typography>
        <Row mt={0.5} gap={1}>
          <Chip color="secondary">{data.source}</Chip>
          <Typography variant="m-regular">
            {boldNumbersAndEscapeHtml(
              resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.performance`),
            )}
          </Typography>
        </Row>
        <Row mt={0.5} gap={1.5}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.inferenceSpeed')}
          </Typography>{' '}
          <Row gap={0.5}>
            <Typography variant="m-regular" mr={0.5}>
              {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.inferenceSpeed`)}
            </Typography>
            <Tooltip content={<Box>{t('model.baselineModels.tooltip')}</Box>} placement="top">
              <Icon icon={InfoFilled} />
            </Tooltip>
          </Row>
          <Box backgroundColor="gray-300" style={{ height: 8, width: 1 }} />
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.throughput')}
          </Typography>
          <Typography variant="m-regular">
            {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.throughput`)}
          </Typography>
          <Box backgroundColor="gray-300" style={{ height: 8, width: 1 }} />
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.maximumInputSize')}
          </Typography>
          <Typography variant="m-regular">
            {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.maximumInputSize`)}
          </Typography>
        </Row>
      </Box>
    </Box>
  );
};

// gen ai 2개 이상 추가될 경우 삭제 예정
const TemperalGenerationPublicModelRow = ({ data }: { data: PublicModel }) => {
  const { t } = useTranslation();
  const { publicModelId, setSelectedPublicModelId } = useBaselineContext();
  const { params } = useRouteMatch<{ accountName: string }>();

  const boldNumbersAndEscapeHtml = (input: string): React.ReactNode => {
    const result: React.ReactNode[] = [];
    for (let i = 0; i < input.length; i++) {
      if (!isNaN(Number(input[i]))) {
        result.push(<b key={i}>{input[i]}</b>);
      } else {
        result.push(escapeHtml(input[i]));
      }
    }
    return result;
  };

  const escapeHtml = (unsafe: string): string => {
    return unsafe.replace(/[&<"']/g, m => {
      switch (m) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#039;';
        default:
          return m;
      }
    });
  };

  const resolveI18nKey = (data: PublicModel, i18nKey: string) => {
    // 서버에서 먼저 추가된 baseline model의 성능에 관한 값이 i18n으로 준비되지 않았을 때 서버 값을 직접 참조
    if (t(i18nKey) === i18nKey) {
      // property: description, inferenceSpeed, throughput, maximumInputSize, performance, etc...
      const property = i18nKey.split('.')[4] as keyof PublicModel;
      return data[property] as string;
    } else {
      // i18n이 준비되어있을 경우 그대로 번역
      return t(i18nKey);
    }
  };

  useEffect(() => {
    setSelectedPublicModelId(data.id);
  }, [data.id, setSelectedPublicModelId]);
  return (
    <Box
      key={data.id}
      border="1px solid"
      borderColor={'primary-400'}
      p={1.5}
      mb={1}
      mt={1}
      style={{ borderRadius: '1px', height: 113 }}
    >
      <Row mb={0.5}>
        <Image
          src={'/static/image/ReCo-logo.png'}
          width={16}
          height={16}
          alt=""
          style={{ marginRight: 4 }}
        />
        <Link
          href={getUrl([params.accountName, GENERATION_BASELINE_MODEL])}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Row>
            <Typography variant="m-medium" color="gray-400">
              {data.name}
            </Typography>
            <Icon icon={ChevronRight} size={16} color={'gray'} />
          </Row>
        </Link>
        <Box ml={'auto'}>
          <TaskTypeChip taskType={data.task} />
        </Box>
      </Row>
      <Box>
        <Typography variant="m-regular">
          {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.description`)}
        </Typography>
        <Row mt={0.5}>
          <Chip color="secondary">{data.source}</Chip>
        </Row>
        <Row mt={0.5} gap={1.5}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.inferenceSpeed')}
          </Typography>{' '}
          <Row gap={0.5}>
            <Typography variant="m-regular" mr={0.5}>
              {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.inferenceSpeed`)}
            </Typography>
            <Tooltip content={<Box>{t('model.baselineModels.tooltip')}</Box>} placement="top">
              <Icon icon={InfoFilled} />
            </Tooltip>
          </Row>
          <Box backgroundColor="gray-300" style={{ height: 8, width: 1 }} />
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.throughput')}
          </Typography>
          <Typography variant="m-regular">
            {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.throughput`)}
          </Typography>
          <Box backgroundColor="gray-300" style={{ height: 8, width: 1 }} />
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.imageResolution')}
          </Typography>
          <Typography variant="m-regular">
            {resolveI18nKey(data, `model.baselineModels.info.${data.modelArch}.imageResolution`)}
          </Typography>
        </Row>
      </Box>
    </Box>
  );
};

const TrainByMyModel = ({
  taskTypeOptions,
}: {
  taskTypeOptions: { value: typeof taskType; label: string }[];
}) => {
  const { t } = useTranslation();
  const { accountName } = useParams<{
    accountName: string;
  }>();
  const {
    taskType,
    setTaskType,
    myModelsList,
    selectedMyModel,
    setSelectedMyModelId,
    searchNameFilter,
    setSearchNameFilter,
    tagFilter,
    setTagFilter,
    myModelsQuery,
  } = useBaselineContext();
  const { setSplitType } = useDatasetClassContext();

  const { data: tags } = useModelTagsQuery({});
  const [tagName, setTagName] = useState('');

  const tagOptions = tags?.modelTags
    .filter(tag => (tagName ? tag.name.toUpperCase().includes(tagName.toUpperCase()) : true))
    .map(tag => {
      return {
        value: tag,
        label: tag.name,
      };
    });
  const { hasNextPage, isLoading, fetchNextPage } = myModelsQuery;
  const measureRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isLoading) {
      await fetchNextPage();
    }
  });

  return (
    <>
      <Row display="grid" mb={1} style={{ gridTemplateColumns: '1fr 192px 192px', columnGap: 8 }}>
        <Input
          prefix={<Icon icon={Search} />}
          color="gray"
          variant="soft-fill"
          placeholder={t('model.train.modelSearchPlaceholder')}
          value={searchNameFilter}
          onChange={e => setSearchNameFilter(e.target.value)}
        />
        <Select value={taskType} data={taskTypeOptions} onChangeValue={v => setTaskType(v)} />
        <Select
          multiple
          value={tagFilter}
          data={tagOptions}
          onChangeValue={v => setTagFilter(v)}
          placeholder={t('model.train.selectTag')}
          formatValue={v => (
            <Chip fill="soft" color={v.color} whiteSpace="nowrap">
              {v.name}
            </Chip>
          )}
          prefix={
            <Box
              m={0.5}
              border="1px solid"
              borderRadius="2px"
              borderColor="gray-100"
              display="flex"
            >
              <Input
                type="search"
                variant="text"
                prefix={<Icon icon={Search} />}
                placeholder={t('model.train.searchByTag')}
                value={tagName}
                onChange={e => setTagName(e.target.value)}
                style={{ flex: 1 }}
              />
            </Box>
          }
        />
      </Row>
      <Box overflow="auto" style={{ height: 644 }}>
        {myModelsList.map(data => {
          const isSelected = selectedMyModel?.id === data.id;
          const trainingResult = data.modelTraining.trainingResult.data;

          return (
            <Box
              key={data.id}
              border="1px solid"
              borderColor={isSelected ? 'primary-400' : 'gray-200'}
              p={1.5}
              mb={1}
              cursor="pointer"
              onClick={() => {
                setSelectedMyModelId(data.id);
                setSplitType(data.trainingSet.validationSetList.length > 0 ? 'manual' : 'random');
              }}
              style={{ borderRadius: '1px', height: 68 }}
            >
              <Row mb={1}>
                <Icon
                  icon={isSelected ? RadioChecked : Radio}
                  size={16}
                  color={isSelected ? 'primary-400' : undefined}
                  style={{ marginRight: 4 }}
                />

                <Link
                  href={getUrl(
                    [`/${accountName}/model/recognition`, RecognitionAIDetailMenuItem.path],
                    {
                      id: data.id,
                    },
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  <Row>
                    <Typography variant="m-medium" color="gray-400">
                      {data.modelSetting.name}
                    </Typography>
                    <Icon icon={ChevronRight} size={16} color="gray" />
                  </Row>
                </Link>
                <Row ml={'auto'} gap={1}>
                  <Icon
                    icon={data.pinnedAt ? PinFilled : PinOutline}
                    color={data.pinnedAt ? 'secondary' : undefined}
                    size={16}
                  />
                  <TaskTypeChip taskType={data.baselineModel.task} />
                </Row>
              </Row>
              <Row gap={1.5} style={{ paddingLeft: 20 }}>
                <Row gap={1}>
                  <Typography variant="m-regular" color="gray-300">
                    {t('model.train.dataset')}
                  </Typography>
                  <TextEllipsisBox style={{ width: 140 }}>
                    <Typography variant="m-regular">{data.trainingSet.referenceName}</Typography>
                  </TextEllipsisBox>
                </Row>
                {data.baselineModel.purpose === 'recognition' &&
                  trainingResult &&
                  isRecognitionAIModelTrainingResult(trainingResult) && (
                    <Row gap={1}>
                      <Typography variant="m-regular" color="gray-300">
                        mAP
                      </Typography>
                      <Box style={{ width: 32 }}>
                        <Typography variant="m-strong">
                          {(trainingResult.overallPerformance.ap * 100).toFixed(1)}
                        </Typography>
                      </Box>
                    </Row>
                  )}
                {data.modelTag.length > 0 && (
                  <Row gap={1.5}>
                    <Typography variant="m-regular" color="gray-300">
                      {t('model.myModels.tag')}
                    </Typography>
                    <Row gap={0.5}>
                      {data.modelTag.map((tag, idx) => {
                        if (data.modelTag.length > 3 && idx === 3) {
                          return <>...</>;
                        }
                        if (idx < 3) {
                          return (
                            <Chip
                              key={tag.name}
                              color={tag.color}
                              style={{
                                width: 63,
                              }}
                            >
                              <Typography
                                variant="s-regular"
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {tag.name}
                              </Typography>
                            </Chip>
                          );
                        }
                      })}
                    </Row>
                  </Row>
                )}
                <Box ml={'auto'}>
                  <Typography variant="m-regular" color="gray-300">
                    <Trans
                      t={t}
                      i18nKey={'model.train.createdAt'}
                      values={{ time: format(data.createdAt, 'MM/dd/yy hh:mma') }}
                    />
                  </Typography>
                </Box>
              </Row>
            </Box>
          );
        })}
        {hasNextPage && (
          <Row ref={measureRef} width="100%" style={{ height: 50, justifyContent: 'center' }}>
            <Icon icon={LoadingSpinnerAlt} size={32} />
          </Row>
        )}
      </Box>
    </>
  );
};
