import React, { useEffect, useState } from 'react';

import { StateGetterSetter } from '../../../../../../../contexts/types';
import { useQueryContext } from '../../../../../contexts/QueryContext';

type ContextProps = StateGetterSetter<['comparedId', 'setComparedId'], string | undefined> &
  StateGetterSetter<['showComparedPoints', 'setShowComparedPoints'], boolean>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { queryString } = useQueryContext();
  const [comparedId, setComparedId] = useState<string>();
  const [showComparedPoints, setShowComparedPoints] = useState<boolean>(true);

  useEffect(() => {
    setShowComparedPoints(false);
  }, [queryString]);

  useEffect(() => {
    if (!showComparedPoints) setShowComparedPoints(true);
    // exluding showComparedPoints from dependency list is intentional
    // and necessary for legend to work
  }, [comparedId]);

  return {
    comparedId,
    setComparedId,
    showComparedPoints,
    setShowComparedPoints,
  };
};

export const useCompareModeContext = (): ContextProps => {
  return React.useContext(Context);
};

export const CompareModeProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
