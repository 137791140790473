export const QUERY_KEY = {
  diagnosisList: 'diagnosis-list',
  diagnosisDetail: 'diagnosis-detail',
  diagnosisMetadataClassList: 'diagnosis-metadata-class-list',
  evaluationSummary: 'evaluation-summary',
  diagnosisJobProgress: 'diagnosis-job-progress',
  diagnosisAnalyticsPerformanceTable: 'diagnosis-analytics-performance-table',
  diagnsoisAnalyticsConfusionMatrix: 'diagnosis-analytics-confusion-matrix',
  diagnosisConfusionMatrix: 'diagnosis-confusion-matrix',
  diagnosisStartOrUpdateDiagnosisJob: 'diagnosis-start-or-update-diagnosis-job',
};
