import Dexie, { Table } from 'dexie';

import { Curation, CurationResult } from '../../../types/curationTypes';
import {
  MislabelDetection,
  MislabelDetectionResultsPerClass,
} from '../../../types/mislabelDetectionTypes';
import { SemanticSearch, SemanticSearchResult } from '../../../types/semanticsearchTypes';

export type MislabelHistory = MislabelDetection & {
  results: MislabelDetectionResultsPerClass[];
  savedAt: Date;
};

type CurationHistory = Curation & {
  results: CurationResult;
};

type SemanticSearchHistory = SemanticSearch & {
  results: SemanticSearchResult;
};

export class HistoryTable extends Dexie {
  mislabelHistoryTable!: Table<MislabelHistory>;
  curationTable!: Table<CurationHistory>;
  semanticSearchTable!: Table<SemanticSearchHistory>;

  constructor() {
    super('AdvancedAIResultsDB');
    this.version(1).stores({
      mislabelHistoryTable: 'id, name',
      curationTable: 'id, name',
      semanticSearchTable: 'id, name',
    });
  }
}

export const db = new HistoryTable();
