import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { AddIntegrationRoute } from '../../../components/pages/account/integrations/addIntegration/Route';
import Integration from '../../../components/pages/account/integrations/detail/Integration';
import { joinPaths } from '../../../routes/util';

export default function Layout(): JSX.Element {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={match.path} component={Integration} />

      <Route
        path={joinPaths(match.path, AddIntegrationRoute.path)}
        // @ts-ignore: saa-680
        component={AddIntegrationRoute}
      />
    </Switch>
  );
}
