import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Calendar, ChevronLeft, ChevronRight } from '@superb-ai/icons';
import { Icon, IconButton } from '@superb-ai/ui';
import { clone, isEmpty, pickBy, set, toInteger } from 'lodash';
import { useSnackbar } from 'notistack';

import { FIRST_LABEL, LAST_LABEL } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService from '../../../../services/LabelsService';
import LabelUtils from '../../../../utils/LabelUtils';
import ParamUtils from '../../../../utils/ParamUtils';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';
import CabinetLayout from './CabinetLayout';
import ImageLayout from './ImageLayout';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '80%',
      height: '92%',
      minWidth: '1400px',
      minHeight: '600px',
      background: 'none',
      boxShadow: 'none',
      overflow: 'hidden',
      maxHeight: 'none',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  arrowBox: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  content: {
    flex: '11',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '7px',
    overflow: 'hidden',
    alignItems: 'center',
    background: '#FFF',
  },
  loadingBox: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'relative',
    width: '100%',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F9F9F9',
    borderBottom: 'solid 1px #dedede',
  },
  headerContext: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '15px',
    color: '#635c5c',
    fontWeight: 300,
    marginRight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  statusBox: {
    minWidth: '120px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingTop: '2px',
  },
  timeBox: {
    position: 'absolute',
    right: '8px',
    background: '#999',
    color: '#fff',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    fontWeight: 400,
    fontSize: '12px',
  },
  badge: {
    fontSize: '11px',
    width: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: '4px',
  },
  status: {
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: 1,
  },
  section: {
    width: '100%',
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
  },
  leftSection: {
    position: 'relative',
    flex: '1',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  rightSection: {
    width: '300px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
    overflow: overflowOverlayOrAuto(),
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '64px',
    fontSize: '64px',
    color: '#dedede',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
  },
}));

const displayTimespent = (usertime: any[]): string => {
  let timespent = 0;

  if (usertime) {
    timespent = usertime.reduce((acc, item) => (item.timespent ? acc + item.timespent : acc), 0);
  }

  let s = 0;
  let m = 0;
  let h = 0;
  s = timespent % 60;
  m = Math.floor(timespent / 60);

  if (m >= 60) {
    h = Math.floor(m / 60);
    m %= 60;
  }

  const appendZeroPrefix = (num: number) => {
    return num >= 10 ? num : `0${num}`;
  };

  return `${appendZeroPrefix(h)} : ${appendZeroPrefix(m)} : ${appendZeroPrefix(s)}`;
};

const Layout = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelsInfo = useLabelsInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const projectInfo = useProjectInfo();
  const { tagIds } = projectInfo;
  const { label, isLoading, setIsLoading, index, isOpen } = labelDetailViewInfo;
  const { issuePanelState, setIssuePanelState, setOpenBadgeInfo } = labelIssuesInfo;
  const { pathname, search } = routeInfo.history.location;
  const { labels, setLabels, isDesc, orderBy } = labelsInfo;

  useEffect(() => {
    if (!label) return () => {};

    routeInfo.setUrlMatchInfo({ ...routeInfo.urlMatchInfo, labelId: label.id });

    return () => {
      const { labelId: _, ...rest } = routeInfo.urlMatchInfo;
      routeInfo.setUrlMatchInfo(rest);
    };

    // eslint-disable-next-line
  }, [label]);

  const handleClickClose = (): void => {
    // TODO (tsnoh): detail view 바로 닫기?
    if (issuePanelState) {
      setIssuePanelState(null);
      setOpenBadgeInfo({ issue: null, ref: null });
    } else {
      routeInfo.history.push(`${pathname}${search}`);
    }
  };

  const handleClickPrev = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    if (index === null) return;
    if (e) {
      e.stopPropagation();
    }
    if (labels.length <= 1) return;

    const nextIndex = index - 1;
    if (nextIndex >= 0) {
      if (labels[nextIndex]?.id) {
        const nextHash = `#label_id=${labels[nextIndex].id}`;
        routeInfo.history.push(`${pathname}${search}${nextHash}`);
      } else {
        routeInfo.history.push(`${pathname}${search}`);
      }
    } else {
      const curPage = routeInfo.params.page ? toInteger(routeInfo.params.page) : 1;
      if (curPage === 1) {
        enqueueSnackbar(FIRST_LABEL({ t }), { variant: 'warning' });
        return;
      }
      setLabels([]);
      setIsLoading(true);
      const nextPage = curPage - 1;
      const sortParam = pickBy(
        { ordering: !isDesc ? orderBy : '-'.concat(orderBy) },
        v => !isEmpty(v),
      );
      const nextParams = set(clone(routeInfo.params), 'page', nextPage);
      const nextApiParams = ParamUtils.getApiParamsForFilter({
        filterParams: nextParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      });
      const { results } = await LabelsService.getLabels({
        params: { ...nextApiParams, ...sortParam },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const rowsPerPage = routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10;
      const nextLabelCount = results.length === rowsPerPage ? rowsPerPage : results.length;
      const nextLabel = results[nextLabelCount - 1];
      const nextHash = nextLabel ? `#label_id=${nextLabel.id}` : '';
      const searchParams = new URLSearchParams(set(clone(routeInfo.params), 'page', nextPage));
      routeInfo.history.push(`?${searchParams.toString()}${nextHash}`);
    }
  };

  const handleClickNext = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;
    if (index === null) return;
    if (e) {
      e.stopPropagation();
    }
    if (labels.length <= 1) return;

    const rowsPerPage = routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10;
    const nextIndex = index + 1;
    if (nextIndex < labels.length && nextIndex < rowsPerPage) {
      if (labels[nextIndex]?.id) {
        const nextHash = `#label_id=${labels[nextIndex].id}`;
        routeInfo.history.push(`${pathname}${search}${nextHash}`);
      } else {
        routeInfo.history.push(`${pathname}${search}`);
      }
    } else {
      const curPage = routeInfo.params.page ? toInteger(routeInfo.params.page) : 1;
      const totalPage = Math.ceil(labelsInfo.totalCount / rowsPerPage);
      if (curPage === totalPage) {
        enqueueSnackbar(LAST_LABEL({ t }), { variant: 'warning' });
        return;
      }
      setLabels([]);
      setIsLoading(true);
      const nextPage = curPage + 1;
      const sortParam = pickBy(
        { ordering: !isDesc ? orderBy : '-'.concat(orderBy) },
        v => !isEmpty(v),
      );
      const nextParams = set(clone(routeInfo.params), 'page', nextPage);
      const nextApiParams = ParamUtils.getApiParamsForFilter({
        filterParams: nextParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      });
      const { results } = await LabelsService.getLabels({
        params: { ...nextApiParams, ...sortParam },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const nextLabel = results[0];

      const nextHash = nextLabel?.id ? `#label_id=${nextLabel.id}` : '';
      const searchParams = new URLSearchParams(set(clone(routeInfo.params), 'page', nextPage));
      routeInfo.history.push(`?${searchParams.toString()}${nextHash}`);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (!isOpen || isLoading) return;
    const { key } = e;

    if (key === 'Escape') {
      handleClickClose();
    } else if (key === 'ArrowLeft') {
      handleClickPrev();
    } else if (key === 'ArrowRight') {
      handleClickNext();
    }
  };

  return (
    <MUI.Dialog
      className={classes.dialog}
      open={isOpen}
      fullWidth
      onBackdropClick={handleClickClose}
      onKeyUp={handleKeyUp}
    >
      <MUI.Box className={classes.container}>
        <MUI.Box className={classes.arrowBox} onClick={handleClickClose}>
          <IconButton icon={ChevronLeft} size="xl" onClick={handleClickPrev} />
        </MUI.Box>
        <MUI.Box className={classes.content}>
          {isLoading || !label ? (
            <MUI.Box className={classes.loadingBox}>
              <MUI.CircularProgress />
            </MUI.Box>
          ) : (
            <>
              <MUI.Box className={classes.header}>
                <MUI.Box className={classes.headerContext}>
                  <TextEllipsisTooltip text={label.asset.key as string}>
                    <MUI.Typography className={classes.fileName}>{label.asset.key}</MUI.Typography>
                  </TextEllipsisTooltip>
                  <MUI.Box className={classes.statusBox}>
                    <MUI.Box
                      className={classes.badge}
                      style={{
                        backgroundColor: LabelUtils.getStatusColor(label.status),
                      }}
                    />
                    <MUI.Typography className={classes.status}>
                      {t(LabelUtils.getStatusText(label.status))}
                    </MUI.Typography>
                  </MUI.Box>
                  <MUI.Box className={classes.timeBox}>
                    <Icon
                      icon={Calendar}
                      size={14}
                      style={{ marginRight: '6px', marginTop: '2px' }}
                    />
                    {displayTimespent(label?.info?.tags?.usersTime)}
                  </MUI.Box>
                </MUI.Box>
              </MUI.Box>
              <MUI.Box className={classes.section}>
                <MUI.Box className={classes.leftSection}>
                  <ImageLayout />
                </MUI.Box>
                <MUI.Box className={classes.rightSection}>
                  <CabinetLayout />
                </MUI.Box>
              </MUI.Box>
            </>
          )}
        </MUI.Box>
        <MUI.Box className={classes.arrowBox} onClick={handleClickClose}>
          <IconButton icon={ChevronRight} size="xl" onClick={handleClickNext} />
        </MUI.Box>
      </MUI.Box>
    </MUI.Dialog>
  );
};

export default Layout;
