import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ButtonGroup,
  Card,
  IconButton,
  Typography,
  useContainerSize,
} from '@superb-ai/norwegian-forest';
import { isArray, isEmpty, isNull, map, reduce, sum, upperFirst } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
// Segment tracking
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { formatDistanceShort } from '../../../../utils/date';
import { DataType } from '../../../../utils/LabelInterfaceUtils';
import CircularProgressBox from '../../../elements/CircularProgressBox';
// Custom charts
import BarChartScrollable from '../charts/barChart/BarChartScrollable';
import { getSpbDefaultColors } from '../charts/donutChart/donutChartColors';
import DonutChartWithLegend from '../charts/donutChart/DonutChartWithLegend';
import { routeToLabelsWithCategory } from '../charts/donutChart/utils';
import { EMPTY_MESSAGE } from '../config/constants';
import { SVG_PROPERTY_DONUT, SVG_PROPERTY_SCROLL_BAR } from '../config/svgConfig';
import {
  AnalyticsSummaryType,
  BarChartNameChoice,
  FileFormat,
  SortOption,
} from '../dataTypes/analyticsDataTypes';
import { BarChartIconButton } from '../elements/buttons/BarChartIconButton';
import { DonutChartIconButton } from '../elements/buttons/DonutChartIconButton';
import SyncButton from '../elements/buttons/SyncButton';
import EmptyPlot from '../elements/EmptyPlot';
import DownloadDropDown from '../tools/DownloadDropDown';
import { addCategorizationShare, sortData } from '../tools/helper';
import SortDropDown from '../tools/SortDropDown';
import { chartMockData } from '../userStats/mock';
import { JsonObj } from '../userStats/types';
import { displayDistanceTime, downloadDataFile, downloadImageFile } from './helper';

/**
 * Project Analytics > Category Counts Chart for siesta workapps
 *
 * ('imageCategoryStats' in plotConfig)
 */
const CategoryCountChart = (props: {
  chartInfo: JsonObj; // chart display info from plotConfig
  isLoading: boolean;
  labelCount: number;
  sourceData: JsonObj[];
  filter?: Record<string, string[] | []>;
  propertyName?: string;
  dataType: DataType;
  height: number;
  syncTime: Date;
  refresh: (summaryType: AnalyticsSummaryType) => void;
  // propertyIdToInfo?: Record<string, CategoryPropertyInfo>;
}): React.ReactElement => {
  const chartName = 'imageCategoryStats';
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const projectName = projectInfo.project.name;
  const {
    sourceData,
    chartInfo,
    isLoading,
    labelCount,
    filter = {},
    propertyName,
    dataType,
    height,
    syncTime,
    refresh,
  } = props;

  const {
    xVariable: [xKey],
    yVariable: [yKey],
    tooltipKeyMap,
    chartKind,
    chartDefault,
    buttonSize,
    excelSheetName,
    downloadOptions,
  } = chartInfo;

  const [chartType, setChartType] = useState(chartDefault);
  const { t } = useTranslation();

  /** Container Ref */
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);

  /** Sort */
  const [sortX, setSortX] = useState({
    ...chartInfo.sortX,
    display: false,
  } as SortOption);
  const [sortY, setSortY] = useState({
    ...chartInfo.sortY,
    display: true,
  } as SortOption);

  const [formattedData, setFormattedData] = useState<JsonObj[] | []>([]);
  const [syncTimeDistance, setSyncTimeDistance] = useState<string>(formatDistanceShort(syncTime));
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const xKeyToDisplayName = reduce(
    formattedData,
    (agg, category) => {
      const categoryId = category?.id;
      agg[categoryId] = category?.name;
      return agg;
    },
    {} as Record<string, string>,
  );

  const formatDataCallback = useCallback(() => {
    return addCategorizationShare(sourceData);
  }, [sourceData]);

  useEffect(() => {
    if (!isEmpty(sourceData)) {
      const formattedData = formatDataCallback();
      setFormattedData(sortData(formattedData, sortY, sortX, chartName));
    }
    setSyncTimeDistance(formatDistanceShort(syncTime));
    // We don't want to re-render on every sort, only for initial load.
    // eslint-disable-next-line
  }, [sourceData, formatDataCallback]);

  useEffect(() => {
    setIsLoadingData(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const timeout = setInterval(
      () => {
        setSyncTimeDistance(formatDistanceShort(syncTime));
      },
      60000,
      10,
    );
    return () => clearInterval(timeout);
  }, []);

  // supp (supplementary variable) is object share (percent total count)
  const filterData = (data: JsonObj): JsonObj => {
    return {
      data,
      x: map(data, d => d['id']),
      y: map(data, d => d[yKey]),
      supp: map(data, d => d.percentTotal),
      xDisplay: map(data, d => d[xKey]),
      groups: map(data, d => d.groups), // for keys (ids) are for label list filtering and values (names) for tooltip display
    };
  };

  const handleSortChange = (sortAxis: 'x' | 'y') => {
    if (sortAxis === 'x') {
      const reverseSortX = {
        ...sortX,
        direction: sortX.direction === 'asc' ? 'desc' : 'asc',
        display: true,
      } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortX, sortY, chartName));
      setSortX(reverseSortX);
      setSortY({ ...sortY, display: false });
    } else if (sortAxis === 'y') {
      const reverseDir = sortY.direction === 'asc' ? 'desc' : 'asc';
      const reverseSortY = { ...sortY, direction: reverseDir, display: true } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortY, sortX, chartName));
      setSortY(reverseSortY);
      setSortX({ ...sortX, display: false });
    }
  };

  const handleClickDatum = (clicked: JsonObj) => {
    // TODO (@moonlimb) fix this later
    const categoryGroups = isArray(clicked?.groups) ? clicked.groups : Object.keys(clicked.groups);

    if (categoryGroups) {
      routeToLabelsWithCategory({
        datum: { id: clicked.xValue },
        groupIds: categoryGroups,
        routeInfo,
        filter,
      });
    }
  };

  /**
   * Can download 'csv' , 'xlsx', and 'png'
   * TODO (mlimb): preserve sort
   */
  const handleDownload = (outputType: FileFormat) => {
    if (outputType === 'png') {
      downloadImageFile({
        projectName,
        chartDisplayName: t(chartInfo.title),
        chartName,
        chartType,
        filter,
      });
      return;
    }
    const outputData = map(formattedData, d => {
      return {
        category_name: d.name,
        count: d.count,
        percent_total: d.percentTotal,
      };
    });

    const chartDownloadName = t(chartInfo.title) + `_${propertyName}`;
    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: outputData,
        projectName,
        chartName: chartDownloadName,
        filter,
      });
      return;
    }
    downloadDataFile({
      downloadData: outputData,
      projectName,
      chartName: chartDownloadName,
      excelSheetName,
      filter,
    });
    AnalyticsTracker.chartDownloaded({
      accountId: projectInfo.project.accountId,
      chartType: chartType,
      chartName: t(chartInfo.title),
      fileExtension: outputType,
      feature: 'label-analytics',
    });
  };

  const handleSelectChart = (newChartType: string) => {
    setChartType(newChartType);
  };

  const getEmptyMessage = (isInitDataNull: boolean, labelCount: number): string => {
    if (isInitDataNull && labelCount === 0) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    }
    if (labelCount > 0) {
      return t(EMPTY_MESSAGE.ANNOTATE_IMAGE_CATEGORIES);
    }
    // API returned [] or null (0 labels with status), but there's > 0 total labels.
    return t(EMPTY_MESSAGE.SUBMIT_LABELS);
  };

  // configure donut chart colors
  const colorMap = getSpbDefaultColors(map(formattedData, d => d.name));
  const getDatumColor = (datum: JsonObj) => {
    return colorMap(datum.name) as string;
  };

  /**
   * Given chartType, renders the selected chart Component.
   */
  const makeChart = (formattedData: JsonObj, chartType: 'donut' | 'bar'): React.ReactElement => {
    if (chartType === 'donut') {
      const svgInfo = SVG_PROPERTY_DONUT({ width, height: height - 70 });
      return (
        <DonutChartWithLegend
          data={formattedData.data}
          xValues={formattedData.x}
          yValues={formattedData.y}
          totalCounts={sum(formattedData.y)}
          chartName={chartName}
          xKeyToDisplayName={xKeyToDisplayName}
          svgInfo={svgInfo}
          getDatumColor={getDatumColor}
          handleClickDatum={handleClickDatum}
        />
      );
    }
    const isScrollable = formattedData.data.length > 30;
    const svgInfo = SVG_PROPERTY_SCROLL_BAR(
      { width, height: height - 80 },
      formattedData?.x?.length,
    );
    return (
      <BarChartScrollable
        groups={formattedData.groups}
        xValues={formattedData.x}
        yValues={formattedData.y}
        sValues={formattedData.supp}
        totalCounts={sum(formattedData.y)}
        tooltipKeyMap={tooltipKeyMap}
        chartName={chartName as BarChartNameChoice}
        isScrollable={isScrollable}
        filter={filter}
        xKeyToDisplayName={xKeyToDisplayName}
        svgInfo={svgInfo}
        hasTooltip
        hoveredIndex={-1}
        handleClickDatum={handleClickDatum}
      />
    );
  };

  const hasZeroLabels = labelCount === 0 || formattedData.length === 0;
  /**
   * UI: [SortDropdown] [DonutIcon] [BarIcon]
   */
  const getRightButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <ButtonGroup gap={1}>
        <SortDropDown
          size={buttonSize.sort}
          sortX={sortX}
          sortY={sortY}
          handleSortChange={handleSortChange}
          isDisabled={hasZeroLabels}
        />
        <DonutChartIconButton
          size={buttonSize.donut}
          selected={chartType === 'donut'}
          disabled={hasZeroLabels}
          handleClick={handleSelectChart}
          t={t}
        />
        <BarChartIconButton
          size={buttonSize.bar}
          selected={chartType === 'bar'}
          disabled={hasZeroLabels}
          handleClick={handleSelectChart}
          t={t}
        />
        <DownloadDropDown
          key={`${chartName}-${isLoading}`}
          ml={0}
          iconButtonEl={
            <IconButton
              size="s"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={formattedData.length === 0}
            />
          }
          handleDownload={handleDownload}
          options={downloadOptions}
          isToggleDisabled={formattedData.length === 0}
        />
      </ButtonGroup>
    );
  };

  const refreshData = async () => {
    setIsLoadingData(true);
    await refresh('category-summaries');
    setIsLoadingData(false);
  };

  const getRefreshButton = () => {
    return <SyncButton isLoading={isLoadingData} refreshFn={refreshData} />;
  };

  return (
    <Card key={`${propertyName}`} pt={2} mb={1.5} ref={chartContainerRef}>
      <Box display="flex" pl={2} pr={2} pb={1.5} alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="left" justifyContent="start">
          <Box display="flex" alignItems="center" justifyContent="start">
            <Typography variant="headline5" themedColor="textDefault">
              <strong>
                {propertyName
                  ? `${t(`analytics.projectType.${dataType}`)} ${t(chartInfo.title)} - ${upperFirst(
                      propertyName,
                    )}`
                  : `${t(chartInfo.title)}`}
              </strong>
            </Typography>
            {getRefreshButton()}
          </Box>
          <Typography themedColor={['grey', 300]} variant="label">
            {displayDistanceTime(isLoading, syncTimeDistance)}
          </Typography>
        </Box>
        <Box display="flex" mr="5px" alignItems="center" justifyContent="space-between">
          {getRightButtonGroup(hasZeroLabels)}
        </Box>
      </Box>
      <Box
        display="flex"
        themedBackgroundColor={['grey', 50]}
        pt={1.5}
        justifyContent="center"
        position="relative"
      >
        {isLoadingData ? (
          <CircularProgressBox
            key={chartName}
            boxProps={{ mt: 5, mb: 5, width: '100%', height: '180px' }}
          />
        ) : (
          <div id={chartName}>
            {makeChart(
              filterData(hasZeroLabels ? chartMockData[chartName] : formattedData),
              chartType,
            )}
            {hasZeroLabels && (
              <EmptyPlot
                chartKind={chartKind}
                message={getEmptyMessage(isNull(sourceData), labelCount)}
              />
            )}
          </div>
        )}
      </Box>
    </Card>
  );
};

export default CategoryCountChart;
