import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

export default function SendToLabelCompleteModal({
  sendToLabelCompleteIsOpen,
  setSendToLabelCompleteIsOpen,
  selectedProjectName,
  selectedProjectId,
}: {
  sendToLabelCompleteIsOpen: boolean;
  setSendToLabelCompleteIsOpen: (isOpen: boolean) => void;
  selectedProjectName: string;
  selectedProjectId: string;
}): JSX.Element {
  const { accountName } = useParams<{ accountName: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Modal
      open={sendToLabelCompleteIsOpen}
      close={{
        onClose: () => {
          setSendToLabelCompleteIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.sendToLabelStarted')}
      mainButton={{
        text: t('curate.slices.sendToLabel.goToProjectButton'),
        onClick: () => {
          setSendToLabelCompleteIsOpen(false);
          history.push(`/${accountName}/label/project/${selectedProjectId}/overview`);
        },
        color: 'primary',
      }}
      subButton={{
        text: t('button.close'),
        onClick: () => {
          setSendToLabelCompleteIsOpen(false);
        },
      }}
    >
      <Box style={{ minWidth: '520px' }} px={4} py={2.5}>
        <Typography variant="m-regular">
          {t('curate.dialogs.labelSyncComplete', {
            labelSyncType: t('curate.dialogs.labelSyncType.send'),
            projectName: selectedProjectName,
          })}
        </Typography>
      </Box>
    </Modal>
  );
}
