import React, { Dispatch, SetStateAction } from 'react';

import { Box, Select, Typography } from '@superb-ai/ui';
import { TFunction, Trans } from 'next-i18next';

import { Step } from '../../../../../../../../components/composition/VerticalStepper';

const SelectImagesMultiplier = (
  t: TFunction,
  isDialogOpen: boolean,
  {
    imagesMultiplier,
    setImagesMultiplier,
    applicableImagesCount,
  }: {
    imagesMultiplier: number;
    setImagesMultiplier: Dispatch<SetStateAction<number>>;
    applicableImagesCount: number;
  },
): Step => {
  return {
    title: t('curate.datasets.generateSyntheticImages.steps.numberOfImages.title'),
    isButtonEnabled: imagesMultiplier * applicableImagesCount > 0,
    summary: t('curate.datasets.generateSyntheticImages.steps.numberOfImages.summary', {
      count: imagesMultiplier * applicableImagesCount,
    }),
    content: (
      <Box>
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={
              'curate.datasets.generateSyntheticImages.steps.numberOfImages.descriptionLine1'
            }
            values={{ count: applicableImagesCount }}
          />
          <Box display="inline" mx={1}>
            <Select
              data={Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: i + 1 }))}
              value={imagesMultiplier}
              onChangeValue={setImagesMultiplier}
            />
          </Box>
          <Trans
            t={t}
            i18nKey={
              'curate.datasets.generateSyntheticImages.steps.numberOfImages.descriptionLine2'
            }
          />
          <br />
          <Trans
            t={t}
            i18nKey={
              'curate.datasets.generateSyntheticImages.steps.numberOfImages.descriptionLine3'
            }
            values={{ count: applicableImagesCount * imagesMultiplier }}
            components={{ emphasize1: <Typography color="primary" /> }}
          />
        </Typography>
      </Box>
    ),
  };
};

export default SelectImagesMultiplier;
