import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../analyticsTracker/utils';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import SliceNameInput from '../../../../elements/NameInput/SliceNameInput';
import { useDatasetDataCountQuery } from '../../../../queries/dataQueries';
import {
  useCreateEmptySliceJobMutation,
  useUpdateSliceByQueryJobMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../services/DatasetService';
import { MAXIMUM_NAME_LENGTH } from '../../../../utils/nameUtils';
import { getView } from '../../../../utils/routeUtils';
import { InfoCard } from '../../list/InfoCard';
import { useObjectScatterContext } from '../analytics/contexts/ObjectScatterContext';
import { ImageFilterSchema } from '../filter/types';

export type CreateSliceRequestParams = {
  name: string;
  description: string;
  appliedFilters?: ImageFilterSchema;
};

export default function CreateSliceModal({
  createSliceModalIsOpen,
  setCreateSliceModalIsOpen,
  onCreate,
}: {
  createSliceModalIsOpen: boolean;
  setCreateSliceModalIsOpen: (isOpen: boolean) => void;
  onCreate?: () => Promise<any>;
}): JSX.Element {
  const { accountName, datasetId, sliceId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId?: string;
  }>();
  const { t } = useTranslation();
  const { getDataset } = useCurateDatasetService();
  const { sampledCount } = useObjectScatterContext();
  const {
    selectedData,
    setSelectedData,
    selectedAllData,
    setSelectedAllData,
    sampleThumbnailUrls,
  } = useActionContext();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const scope = searchParams.get('scope') || 'image';
  const viewType = getView(history);

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, hasAppliedFilters } = useImageFilterContext();

  const commandContext = useCurateCommandContext();
  const { setDatasetInfo } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  useEffect(() => {
    if (!selectedAllData && selectedData.length === 0) setSelectedAllData(true);
  }, []);

  const dataQuery = useDatasetDataCountQuery({
    datasetId,
    queryString: queryStringWithHiddenFilterAndDeselection,
    sliceName: '',
    appliedFilters,
  });

  const selectedDataCount = (dataQuery.data && dataQuery.data.count) || 0;
  const [isValidName, setIsValidName] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createSliceName, setCreateSliceName] = useState<string>('');
  const [createSliceDescription, setCreateSliceDescription] = useState<string>('');

  const selectedImageIds = selectedData?.map(image => image.id) ?? [];

  const { mutateAsync: startUpdateSliceJobWithIds, isLoading: isUpdatingSliceJob } =
    useUpdateSliceJobWithIdsMutation({
      datasetId,
      commandContext,
      selectedImageIds,
    });

  const { mutateAsync: createEmptySliceJob, isLoading: isCreatingSliceJob } =
    useCreateEmptySliceJobMutation({
      datasetId,
    });

  const { mutateAsync: startUpdateSliceByQueryJob, isLoading: isUpdatingSliceByQueryJob } =
    useUpdateSliceByQueryJobMutation({
      datasetId,
      sliceName: sliceInfo?.name,
      commandContext,
      appliedFilters,
      queryString: queryStringWithHiddenFilterAndDeselection,
    });

  const trackDataSliceCreated = useCallback(
    (newSliceId: string, dataCount: number) => {
      analyticsTracker.dataSliceCreated({
        accountId: accountName,
        filters: combineFiltersForTracking(appliedFilters, sliceId),
        sliceId: newSliceId,
        datasetId,
        viewType: viewType,
        dataCount: dataCount ?? 0,
        dataType: 'image',
        referrer: 'explore-image',
        queryString: queryStringWithHiddenFilterAndDeselection,
      });
    },
    [
      accountName,
      appliedFilters,
      datasetId,
      queryStringWithHiddenFilterAndDeselection,
      sliceId,
      viewType,
    ],
  );

  const resetSelection = useCallback(() => {
    if (setSelectedAllData && setSelectedData) {
      setSelectedAllData(false);
      setSelectedData([]);
    }
  }, [setSelectedAllData, setSelectedData]);

  const updateDataset = useCallback(async () => {
    const datasetInfo = await getDataset({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
  }, [datasetId, getDataset, setDatasetInfo, showPublicDatasets]);

  const handleClickCreate = useCallback(
    async ({ name, description }: CreateSliceRequestParams) => {
      try {
        setIsLoading(true);
        const createdSlice = await createEmptySliceJob({ description, name });
        if (!selectedData?.length) {
          await startUpdateSliceByQueryJob({ sliceId: createdSlice.id });
        } else {
          await startUpdateSliceJobWithIds({ sliceId: createdSlice.id });
        }
        updateDataset();
        onCreate && (await onCreate());
        return createdSlice;
      } catch (e: any) {
        // console.log('handle error');
      } finally {
        setIsLoading(false);
        resetSelection();
      }
    },
    [
      createEmptySliceJob,
      updateDataset,
      selectedData,
      onCreate,
      startUpdateSliceByQueryJob,
      startUpdateSliceJobWithIds,
      resetSelection,
    ],
  );

  const handleCreateSlice = async () => {
    const slice = await handleClickCreate({
      name: createSliceName,
      description: createSliceDescription,
      appliedFilters,
    });

    trackDataSliceCreated(slice.id, selectedData?.length || selectedDataCount);
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
    setIsValidName(false);
  };

  const handleCloseButton = useCallback(() => {
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
    setIsValidName(false);
  }, [setCreateSliceModalIsOpen, setCreateSliceName, setCreateSliceDescription, setIsValidName]);

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setCreateSliceDescription(e.target.value);
    },
    [setCreateSliceDescription],
  );

  const imageDataList = selectedAllData ? sampleThumbnailUrls : selectedData?.slice(0, 4);

  const SelectedCountBanner = (
    <Typography variant="m-regular">
      {selectedAllData ? (
        <Trans t={t} i18nKey="curate.dialogs.selectedAll">
          Selected
          <Typography color="primary">All</Typography>
        </Trans>
      ) : (
        <Trans
          t={t}
          i18nKey="curate.dialogs.selectedCount"
          values={{ count: selectedData?.length || 0 }}
        >
          Selected
          <Typography color="primary">{{ count: selectedData?.length || 0 }}</Typography>
        </Trans>
      )}
    </Typography>
  );

  const ScatterCountBanner = (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={1}>
        <Typography variant="m-regular">
          <Trans t={t} i18nKey="curate.dialogs.selectedCount" values={{ count: selectedDataCount }}>
            Selected
            <Typography color="primary">{{ count: selectedDataCount }}</Typography>
          </Trans>
        </Typography>
        <Typography variant="m-regular">
          <Trans t={t} i18nKey="curate.dialogs.sampledCount" values={{ count: sampledCount }}>
            Sampled
            <Typography color="primary">{{ count: sampledCount }}</Typography>
          </Trans>
        </Typography>
      </Box>
      <Box>{InfoCard({ t, i18nKey: `curate.embeddings.createSliceModalInfo.${scope}` })}</Box>
    </Box>
  );

  return (
    <Modal
      open={createSliceModalIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.createSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleCreateSlice,
        color: 'primary',
        isLoading,
        disabled: !isValidName || createSliceName === '',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box py={2.5} px={4} style={{ minWidth: '520px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="black">
            {t('curate.slices.sliceName')}
          </Typography>
          <Typography variant="m-regular" color="black">
            {`${createSliceName.length}/${MAXIMUM_NAME_LENGTH}`}
          </Typography>
        </div>
        <SliceNameInput
          sliceName={createSliceName}
          setSliceName={setCreateSliceName}
          setIsValid={setIsValidName}
          datasetId={datasetId}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="black" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}{' '}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>

          <Typography variant="m-regular" color="black">
            {`${createSliceDescription.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleDatasetDescription}
          value={createSliceDescription}
        ></StyledTextarea>
        <Box mt={1}>
          {sampledCount && <>{ScatterCountBanner}</>}
          {(sampledCount && selectedAllData) ||
            (selectedData?.length > 0 && (
              <>
                {SelectedCountBanner}
                {ImageThumbnails()}
              </>
            ))}
        </Box>
      </Box>
    </Modal>
  );

  function ImageThumbnails() {
    return (
      <Box display="flex" mt={1}>
        {imageDataList.map(list => (
          <Box
            key={list.id}
            mr={1}
            style={{
              width: '107px',
              height: '62px',
              verticalAlign: 'bottom',
              lineHeight: '0',
            }}
            position="relative"
            borderRadius="2px"
            overflow="hidden"
            boxSizing="border-box"
            backgroundColor="gray-100"
            display="flex"
            justifyContent="center"
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={list.thumbnailUrl}
                alt=""
                style={{
                  display: 'block',
                  height: '100%',
                  borderRadius: '2px',
                }}
              />
            </div>
          </Box>
        ))}
      </Box>
    );
  }
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
