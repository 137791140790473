import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import Language from './Language';
import Password from './Password';
import UserDetail from './UserDetail';

const MyProfile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="headline5">{t('settings.profile.userDetails')}</Typography>
      <Box mb={2} />
      <UserDetail />
      <Box mb={4} />
      <Language />
      <Box width="100%" height="1px" themedBackgroundColor={['grey', 100]} />
      <Password />
    </Box>
  );
};

export default MyProfile;
