import { useTranslation } from 'react-i18next';

import { TooltipValue } from '@elastic/charts';
import { Box, Typography } from '@superb-ai/ui';
import { capitalize } from 'lodash';

import { formatCount } from '../../../../analytics/charts/utils';
import { VerticalDivider } from '../elements/VerticalDivider';

export const ConfusionMatrixTooltip = (props: { values: TooltipValue[] }) => {
  const { t } = useTranslation();
  const { values } = props;
  const datum = values[0].datum as { originIndex: number; value: number; x: string; y: string };
  const seriesColor = values[2]?.color || 'white';

  const groundTruth = datum.y;
  const prediction = datum.x;
  const count = datum.value;

  const readableCountColors = [
    'rgba(255, 181, 176, 1)',
    'rgba(255, 143, 135, 1)',
    'rgba(255, 98, 90, 1)',
  ];
  return (
    <Box
      display="flex"
      backgroundColor={'white'}
      borderRadius="2px"
      pr={0.5}
      height="100%"
      style={{
        borderColor: 'white',
        width: '280px',
        maxWidth: '200px',
      }}
      flexDirection="row"
      gap={1.5}
    >
      <VerticalDivider style={{ height: '100%', width: '6px', backgroundColor: seriesColor }} />
      <Box mt={0.25} mb={0}>
        <Box display="flex" flexDirection="column" gap={0.25} mb={0.25}>
          <Box alignItems="center" justifyContent="space-between">
            {t('curate.diagnosis.filterPanel.groundTruth')}:{' '}
            <Typography ml="auto" variant="m-strong">
              {groundTruth}
            </Typography>
          </Box>
          <Box justifyContent="space-between" width="100%">
            {t('curate.diagnosis.filterPanel.prediction')}:{' '}
            <Typography variant="m-strong">{prediction}</Typography>
          </Box>
          <Box>
            {capitalize(t('curate.diagnosis.chart.confusionMatrix.count'))}:{' '}
            <Typography
              variant="m-strong"
              style={{ color: readableCountColors.includes(seriesColor) ? seriesColor : 'gray' }}
            >
              {count ? formatCount(count) : '0'}{' '}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
