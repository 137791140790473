import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDown, ChevronRight, Clear, Plus } from '@superb-ai/icons';
import { Box, Chip, Icon, IconButton, Input, Typography } from '@superb-ai/ui';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { getClassPropertyNamingRulesErrorMessages } from './helper';
import PropertyMenu from './PropertyMenu';
import PropertyValuesList from './PropertyValuesList';
import { MetadataProperty } from './type';

export default function PropertiesList({
  selectedClass,
  originalProperties,
  metadataProperties,
  onChangeProperties,
}: {
  selectedClass: string;
  originalProperties?: Record<string, MetadataProperty>;
  metadataProperties?: Record<string, MetadataProperty>;
  onChangeProperties: (metadataProperties: Record<string, MetadataProperty>) => void;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedProperty, setSelectedProperty] = useState<string>();

  function handleChangeProperty(metadataProperty: MetadataProperty) {
    if (!metadataProperties) return;
    const newProperties = cloneDeep(metadataProperties);
    if (newProperties) {
      newProperties[metadataProperty.name] = metadataProperty;
      onChangeProperties(newProperties);
    }
  }

  function handleDeleteProperty(propertyName: string) {
    if (!metadataProperties) return;
    const newProperties = cloneDeep(metadataProperties);
    if (newProperties) {
      delete newProperties[propertyName];
      onChangeProperties(newProperties);
    }
  }

  function handleAddProperty(propertyName: string) {
    if (propertyName === '') return;
    if (!metadataProperties) return;
    const error = getClassPropertyNamingRulesErrorMessages('property', propertyName, t);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
    const newProperties = cloneDeep(metadataProperties);

    if (newProperties.hasOwnProperty(propertyName)) {
      enqueueSnackbar(
        t(
          'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.duplicatedProperty',
          { propertyName },
        ),
        { variant: 'error' },
      );
      return;
    }

    if (newProperties) {
      newProperties[propertyName] = {
        name: propertyName,
        isNew: !originalProperties?.[propertyName],
        isRequired: false,
        type: 'free-response',
      };
      onChangeProperties(newProperties);
      setSelectedProperty(propertyName);
      if (inputRef.current) inputRef.current.value = '';
    }
  }

  if (!metadataProperties) return <></>;

  return (
    <>
      {Object.values(metadataProperties).map(metadata => {
        const isOpenDisabled = metadata.type === 'free-response';
        const isSelected = selectedProperty === metadata.name;
        return (
          <>
            <Box
              key={`${selectedClass}-${metadata.name}`}
              display="flex"
              alignItems="center"
              gap={0.5}
              p={0.5}
              pl={3}
              onClick={() => {
                if (isSelected) {
                  setSelectedProperty(undefined);
                } else {
                  setSelectedProperty(metadata.name);
                }
              }}
            >
              <Icon
                icon={isSelected && !isOpenDisabled ? ChevronDown : ChevronRight}
                color={isOpenDisabled ? 'gray-300' : 'gray-400'}
              />
              <Typography variant="m-regular">{metadata.name}</Typography>
              {!metadata?.isNew && (
                <Chip color="secondary">
                  {t(
                    'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.annotationsIncluded',
                  )}
                </Chip>
              )}
              {metadata.isRequired && <Typography color="primary"> *</Typography>}
              <PropertyMenu
                style={{ marginLeft: 'auto' }}
                onChange={handleChangeProperty}
                property={metadata}
              />
              <IconButton
                icon={Clear}
                size="s"
                variant="text"
                onClick={e => {
                  e.stopPropagation();
                  handleDeleteProperty(metadata.name);
                }}
              />
            </Box>
            {isSelected && metadata.type === 'multiple-choice' && (
              <PropertyValuesList
                selectedClass={selectedClass}
                currentProperty={metadata}
                onChange={handleChangeProperty}
              />
            )}
          </>
        );
      })}
      <Box
        display="flex"
        alignItems="center"
        p={0.5}
        pl={3}
        style={{ height: '32px' }}
        onClick={() => setSelectedProperty(undefined)}
      >
        <Icon icon={Plus} color="gray-400" />
        <Input
          ref={inputRef}
          variant="text"
          placeholder={t(
            'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.addNewProperty',
          )}
          boxProps={{ p: 0 }}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleAddProperty(e.currentTarget.value);
            }
          }}
        />
      </Box>
    </>
  );
}
