import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import ProjectTab from '../../../../../components/pages/analytics/AnalyticsProjectTab';
import { canManageProject } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectAnalyticsProjectMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectAnalytics, // TODO: how does this compare with ProjectAnalyticsMenuItem
  path: 'project',
  title: ({ t }) => t('analytics.project.title'),
  component: ProjectTab,
  isVisible({ authInfo }) {
    return canManageProject(authInfo) || authInfo?.isGuest || false;
  },
});
