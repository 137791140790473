import { omit } from 'lodash';

import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import { JsonObj, LabelerDatum } from '../types';

// TODO: remove after merge with reviewer branch (alread has this)
export const isUserReportV2 = (apiVersion: number): boolean => {
  return apiVersion === 2;
};

export const getColumnsToRemove = (workapp: WorkApp, hasCategory: boolean) => {
  const removeColumns = ['submitted', 'skipped', 'working', 'notSubmitted']; //, 'box'];
  const excludeFrames = (workapp: WorkApp) =>
    !(WorkappUnion.isVideoSiesta(workapp) || WorkappUnion.isPointcloudsSiesta(workapp));

  // TODO: do this on server side => this is hard to maintain..
  if (excludeFrames(workapp)) removeColumns.push('frameCounts');
  if (!hasCategory) removeColumns.push('categoryCounts');
  return removeColumns;
};

export const trimLabelerTableData = (
  resp: JsonObj,
  apiVersion: number,
  workapp: WorkApp,
  hasCategory: boolean,
): LabelerDatum[] => {
  const removeColumns = getColumnsToRemove(workapp, hasCategory);

  if (isUserReportV2(apiVersion)) {
    return resp.result.info?.data.map((labeler: JsonObj) => {
      return omit(labeler, removeColumns);
    });
  }

  return resp.result.info?.data;
};
