export const MODEL_TRAIN = '/model/train';
export const RECOGNITION_MODEL_LIST = '/model/recognition/list';
export const RECOGNITION_MODEL = '/model/recognition';
export const GENERATION_MODEL_LIST = '/model/generation/list';
export const GENERATION_MODEL = '/model/generation';
export const GENERATION_BASELINE_MODEL = '/model/generation/baseline-model';
export const MODEL_ENDPOINTS_LIST = '/model/endpoints/list';
export const MODEL_ENDPOINTS = '/model/endpoints';

export const CURATE_DATASET = '/curate/dataset';
