import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Clear, EditFilter, Reset } from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, Typography } from '@superb-ai/ui';
import { isEmpty } from 'lodash';

import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { useDiagnosisSchema } from '../../../../../../queries/diagnosisModelQueries';
import ConfidenceRangeFilter from './ConfidenceRangeFilter';
import EvaluationResultFilter from './EvaluationResultFilter';
import GroundTruthClassFilter from './GroundTruthClassFilter';
import IOURangeFilter from './IOURangeFilter';
import PredictionClassFilter from './PredictionClassFilter';
import SplitInFilter from './SplitInFilter';

export default function FilterLayout(): JSX.Element {
  const { t } = useTranslation();
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const { isFilterOpen, setIsFilterOpen, filterBody, initSelectedFilter } =
    useDiagnosisGridFilterContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const { data } = useDiagnosisSchema({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    diagnosisId,
  });

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  if (!isFilterOpen) {
    return <></>;
  }

  return (
    <Box
      style={{ width: '240px' }}
      borderLeft="1px solid"
      borderColor="gray-150"
      display="flex"
      flexDirection="column"
    >
      <Box
        p={1}
        display="flex"
        alignItems="center"
        gap={1}
        borderBottom="1px solid"
        borderColor="gray-150"
      >
        <Icon icon={EditFilter} />
        <Typography variant="m-strong">{t('curate.diagnosis.filterPanel.filters')}</Typography>
        <Box gap={1} display="flex" alignItems="center" ml="auto">
          {!isEmpty(filterBody) && (
            <Button size="s" color="primary" variant="text" onClick={initSelectedFilter}>
              <Icon icon={Reset} />
              {t('button.reset')}
            </Button>
          )}
          <IconButton variant="text" icon={Clear} size="s" onClick={handleCloseFilter} />
        </Box>
      </Box>
      <Box p={1} display="flex" flexDirection="column" gap={1}>
        <SplitInFilter splits={data?.splits} />
        <Box borderBottom="1px solid" borderColor="gray-150" />
        <EvaluationResultFilter />
        <Box borderBottom="1px solid" borderColor="gray-150" />
        <PredictionClassFilter classList={data?.classList || []} />
        <Box borderBottom="1px solid" borderColor="gray-150" />
        <GroundTruthClassFilter classList={data?.classList || []} />
        <Box borderBottom="1px solid" borderColor="gray-150" />
        <IOURangeFilter />
        <Box borderBottom="1px solid" borderColor="gray-150" />
        <ConfidenceRangeFilter />
      </Box>
    </Box>
  );
}
