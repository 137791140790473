import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SliceAction, SliceAdd } from '@superb-ai/icons';
import { Box, Icon, IconButton, SelectList, Tooltip, Typography } from '@superb-ai/ui';

import { useRouteInfo } from '../../../../../../contexts/RouteContext';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../../elements/SampleDatasetTooltip';
import {
  GRID_VIEW,
  IMAGE_SCOPE,
  SCOPE,
  ScopeMode,
  VIEW,
  ViewMode,
} from '../../../../types/viewTypes';
import AddSliceModal from '../modal/AddSliceModal';
import DiagnosisAddSliceModal from '../modal/components/DiagnosisAddSliceModal';
import DiagnosisCreateSliceModal from '../modal/components/DiagnosisCreateSliceModal';
import ObjectAddSliceModal from '../modal/components/ObjectAddSliceModal';
import ObjectCreateSliceModal from '../modal/components/ObjectCreateSliceModal';
import SemanticSearchObjectAddSliceModal from '../modal/components/SemanticSearchObjectAddSliceModal';
import CreateSliceModal from '../modal/CreateSliceModal';
import SemanticSearchAddSliceModal from '../modal/SemanticSearchAddSliceModal';
import { MODEL_DIAGNOSIS_PATH } from '../modelDiagnosis/MenuItem';

export default function SliceActionButton() {
  const { t } = useTranslation();

  const { datasetInfo } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { history } = useRouteInfo();
  const params = new URLSearchParams(history.location.search);
  const { diagnosisId } = useParams<{ diagnosisId: string }>();
  const isSemanticSearchView = ((params.get(VIEW) || GRID_VIEW) as ViewMode) === 'semantic_search';
  const isDiagnosisView = history.location.pathname.includes(MODEL_DIAGNOSIS_PATH);
  const isObjectScope = ((params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode) === 'object';

  const [createSliceModalIsOpen, setCreateSliceModalIsOpen] = useState<boolean>(false);
  const [addSliceModalIsOpen, setAddSliceModalIsOpen] = useState<boolean>(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const canUseAddToExistingSlice = !!datasetInfo?.sliceCount;
  function TooltipMessage() {
    if (showPublicDatasets) {
      if (diagnosisId) {
        // Model Diagnosis
        return (
          <>
            {t('curate.datasets.generateSyntheticImages.modalTitle')}
            <br />
            <SampleDatasetTooltip feature="diagnosisSlice" />
          </>
        );
      } else {
        // Explore / Slice
        return (
          <>
            {t('curate.datasets.generateSyntheticImages.modalTitle')}
            <br />
            <SampleDatasetTooltip feature="slice" />
          </>
        );
      }
    }
    return t('curate.button.createSlice');
  }

  const sliceOptions = [
    {
      value: 'createSlice',
      label: (
        <Box display="flex" alignItems="center" gap="small">
          <Icon icon={SliceAction} />
          <Typography variant="m-regular">{t('curate.button.createSlice')}</Typography>
        </Box>
      ),
    },
    {
      value: 'addToExistingSlice',
      label: (
        <Box display="flex" alignItems="center" gap="small">
          <Icon icon={SliceAdd} />
          <Typography variant="m-regular">{t('curate.button.addToExistingSlice')}</Typography>
        </Box>
      ),
      disabled: !canUseAddToExistingSlice,
    },
  ];

  return (
    <>
      {addSliceModalIsOpen &&
        (isSemanticSearchView ? (
          isObjectScope ? (
            <SemanticSearchObjectAddSliceModal
              addSliceModalIsOpen={addSliceModalIsOpen}
              setAddSliceModalIsOpen={setAddSliceModalIsOpen}
            />
          ) : (
            <SemanticSearchAddSliceModal
              addSliceModalIsOpen={addSliceModalIsOpen}
              setAddSliceModalIsOpen={setAddSliceModalIsOpen}
            />
          )
        ) : isDiagnosisView ? (
          <DiagnosisAddSliceModal
            addSliceModalIsOpen={addSliceModalIsOpen}
            setAddSliceModalIsOpen={setAddSliceModalIsOpen}
          />
        ) : isObjectScope ? (
          <ObjectAddSliceModal
            addSliceModalIsOpen={addSliceModalIsOpen}
            setAddSliceModalIsOpen={setAddSliceModalIsOpen}
          />
        ) : (
          <AddSliceModal
            addSliceModalIsOpen={addSliceModalIsOpen}
            setAddSliceModalIsOpen={setAddSliceModalIsOpen}
          />
        ))}
      {createSliceModalIsOpen &&
        (isDiagnosisView ? (
          <DiagnosisCreateSliceModal
            createSliceModalIsOpen={createSliceModalIsOpen}
            setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
          />
        ) : isObjectScope ? (
          <ObjectCreateSliceModal
            createSliceModalIsOpen={createSliceModalIsOpen}
            setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
          />
        ) : (
          <CreateSliceModal
            createSliceModalIsOpen={createSliceModalIsOpen}
            setCreateSliceModalIsOpen={setCreateSliceModalIsOpen}
          />
        ))}
      {
        <Box position="relative" ref={dropdownRef}>
          <Tooltip strategy="fixed" content={TooltipMessage()} placement="top" hideOnEmptyContent>
            <div>
              <IconButton
                icon={SliceAction}
                variant="text"
                color="gray"
                disabled={showPublicDatasets}
                onClick={() => setShowDropdown(true)}
              />
            </div>
          </Tooltip>
          {showDropdown && (
            <Box
              position="absolute"
              backgroundColor="white"
              boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
              style={{ zIndex: 1000, width: '172px' }}
            >
              <SelectList
                data={isSemanticSearchView ? sliceOptions.slice(1) : sliceOptions}
                multiple={false}
                value={undefined}
                onChangeValue={(value: string) => {
                  if (value === 'createSlice') setCreateSliceModalIsOpen(true);
                  if (value === 'addToExistingSlice' && canUseAddToExistingSlice)
                    setAddSliceModalIsOpen(true);
                }}
              />
            </Box>
          )}
        </Box>
      }
    </>
  );
}
