import React from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLeft } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { useRouteInfo } from '../../../contexts/RouteContext';

const AccessAuthorizationPage: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();

  return (
    <Box
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '70px',
        }}
      >
        {/*eslint-disable-next-line @next/next/no-img-element*/}
        <img
          alt="logo"
          src="https://asset.superb-ai.com/assets/img/new_logo.png"
          style={{
            width: '104px',
            margin: '30px 0 0 60px',
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box
          style={{
            width: '100%',
            height: '422px',
            textAlign: 'center',
          }}
        >
          {/*eslint-disable-next-line @next/next/no-img-element*/}
          <img
            alt="symbol"
            src="https://asset.superb-ai.com/suite/access_authorization.png"
            style={{
              width: '422px',
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            width: '554px',
          }}
        >
          <Typography variant="h2" color="gray-400">
            {t('settings.security.accessAuthorizationErrorTitle')}
          </Typography>
          <Typography variant="m-regular" color="gray-300" mt={1} textAlign="center">
            {t('settings.security.accessAuthorizationErrorText')}
          </Typography>
          <Box mt={2} display="flex">
            <Button
              variant="text"
              color="primary"
              style={{
                marginRight: '4px',
              }}
              onClick={() => routeInfo.history.goBack()}
            >
              <Icon icon={ArrowLeft} />
              <Typography>{t('settings.security.accessAuthorizationErrorGoback')}</Typography>
            </Button>
            <Button
              variant="strong-fill"
              color="primary"
              onClick={() => {
                window.open('https://superb-ai.com/forms/contact-us/');
              }}
            >
              {/* <Icon icon={Email} /> */}
              <Typography>{t('settings.security.accessAuthorizationErrorContact')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccessAuthorizationPage;
