import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import AnalyticsCustomizeTab from '../../../../../components/pages/analytics/AnalyticsCustomizeTab';
import { canManageProject } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectAnalyticsCustomizeMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectCustomCharts,
  path: 'custom-charts',
  title: ({ t }) => t('analytics.customizeAnalytics.menuItem'),
  component: AnalyticsCustomizeTab,
  isVisible({ authInfo, featureFlags }) {
    const featureFlagValid = !!featureFlags?.customizeAnalytics?.enabled;
    const authValid = canManageProject(authInfo) || false;
    return featureFlagValid && authValid;
  },
  isEnabled({ authInfo }) {
    return canManageProject(authInfo);
  },
});
