import { Box, Typography } from '@superb-ai/norwegian-forest';

import { CopyToClipboardButton } from '../CopyToClipboardButton';
import { TtopQrCode } from './TotpSecretQrCode';

export function TotpSecret({ secret, label }: { secret: string; label: string }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box p={2} backgroundColor="#fff">
        <TtopQrCode secret={secret} label={label} />
      </Box>
      <Box display="flex" alignItems="center" gap="8px">
        <Typography variant="bold3">{secret}</Typography>
        <CopyToClipboardButton text={secret} />
      </Box>
    </Box>
  );
}
