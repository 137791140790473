import { useTranslation } from 'react-i18next';

import { Chart, Heatmap, Settings, Tooltip } from '@elastic/charts';
import { Box } from '@superb-ai/ui';
import produce from 'immer';

import MakeHeatmapTooltip from './components/HeatmapTooltip';
import { heatmapStyle } from './style/heatmapStyle';

interface Props {
  data: [string, string, number, string][];
  numColumns: number;
}

const getChartHeight = (dataCount: number, numColumns: number): number => {
  const defaultMinHeight = 150;
  return Math.max((dataCount / numColumns) * 36, defaultMinHeight);
};

export const PrecisionRecallChart = ({ data, numColumns }: Props): JSX.Element => {
  const { t } = useTranslation();

  const translatedData = data.map(data =>
    produce(data, data => {
      data[1] = t(`analytics.exportsAnalytics.${data[1]}`);
      return data;
    }),
  );

  return (
    <Box style={{ height: `${getChartHeight(data.length, numColumns)}px` }} overflow="auto">
      <Chart>
        <Settings
          showLegend
          legendPosition="right"
          legendSize={130}
          flatLegend
          brushAxis="both"
          theme={{ heatmap: heatmapStyle, legend: { margin: 24 } }}
        />
        <Tooltip placement="top" customTooltip={MakeHeatmapTooltip(data)} />
        {/*
          Would like to move the axis to the top which isn't possible at the moment.
          https://github.com/elastic/elastic-charts/issues/1952
          <Axis id="top" title="Metrics" position={Position.Top} />
          <Axis id="left" title="Object Class" position={Position.Left} />
        */}
        <Heatmap
          id="HEATMAP"
          colorScale={{
            type: 'bands',
            bands: [
              { start: -Infinity, end: 0.2, color: '#E5E9FF' },
              { start: 0, end: 0.4, color: '#BECAFF' },
              { start: 0.4, end: 0.6, color: '#97ACFF' },
              { start: 0.6, end: 0.8, color: '#708CFF' },
              { start: 0.8, end: Infinity, color: '#3479FF' },
            ],
          }}
          data={translatedData}
          xAccessor={d => d[1]}
          yAccessor={d => d[0]}
          valueAccessor={d => d[2] as number}
          valueFormatter={value => (value === 0 ? '0' : value.toFixed(2))}
          xSortPredicate="alphaAsc"
          yAxisTitle={t('labelInterface.objectClass')}
          xAxisTitle={t('analytics.exportsAnalytics.report.sheetName.metrics')}
        />
      </Chart>
    </Box>
  );
};
