import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';
import { getColorsOf400 } from '../../../../../../../../utils/ColorUtils';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetAnnotationClassesQuery } from '../../../../../../queries/datasetObjectQueries';
import { AnnotationClassWithType } from '../../../../../../services/DatasetObjectService';
import { convertSelectedImageIdsToQueryString } from '../../../../../../utils/filterUtils';
import AnnotationTypeSection from './AnnotationTypeSection';
import ObjectClassSection from './ObjectClassSection';
import { ClassAndProperties } from './type';

export default function AnnotationTypeAndClass({
  curateAnnotations,
  setCurateAnnotations,
  isEditMode,
}: {
  curateAnnotations?: ClassAndProperties;
  setCurateAnnotations: Dispatch<SetStateAction<ClassAndProperties | undefined>>;
  isEditMode?: boolean;
}) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const [originalCurateAnnotations, setOriginalCurateAnnotations] = useState<ClassAndProperties>();

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const { selectedData } = useActionContext();

  const { data } = useDatasetAnnotationClassesQuery({
    datasetId,
    disabled: isEditMode,
    sliceName: sliceInfo?.name,
    ...(selectedData && selectedData.length > 0 && selectedData.length <= 100
      ? {
          queryString: convertSelectedImageIdsToQueryString(selectedData.map(data => data.id)),
        }
      : {
          queryString: queryStringWithHiddenFilterAndDeselection,
          imageFilters:
            clusterLevel && appliedFilters
              ? {
                  cluster_id_in: appliedFilters?.cluster_id_in || [],
                  cluster_level: clusterLevel,
                }
              : undefined,
        }),
  });

  const [selectedAnnotationType, setSelectedAnnotationType] = useState<CurateAnnotationType>();

  useEffect(() => {
    if (!curateAnnotations) return;
    setSelectedAnnotationType(Object.keys(curateAnnotations)[0] as CurateAnnotationType);
  }, []);

  useEffect(() => {
    const colors = getColorsOf400();
    if (data?.annotationClasses) {
      const curateAnnotations = data.annotationClasses.reduce(
        (acc: ClassAndProperties, annotationClass: AnnotationClassWithType, index) => ({
          ...acc,
          [annotationClass.annotationType]: {
            ...(acc[annotationClass.annotationType] || {}),
            [annotationClass.name]: {
              class: annotationClass.name,
              isNew: false,
              color: colors[index % colors.length],
            },
          },
        }),
        {} as ClassAndProperties,
      );
      setCurateAnnotations(curateAnnotations);
      setOriginalCurateAnnotations(curateAnnotations);
      setSelectedAnnotationType(Object.keys(curateAnnotations)[0] as CurateAnnotationType);
    }
  }, [data?.annotationClasses]);

  return (
    <>
      <Typography variant="s-regular">
        {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.description')}
      </Typography>
      <Box
        mt={1}
        border="1px solid"
        borderColor="gray-200"
        width="100%"
        display="flex"
        alignItems="center"
        style={{ height: '240px' }}
      >
        <AnnotationTypeSection
          curateAnnotations={curateAnnotations}
          setCurateAnnotations={setCurateAnnotations}
          selectedAnnotationType={selectedAnnotationType}
          setSelectedAnnotationType={setSelectedAnnotationType}
        />
        <ObjectClassSection
          originalCurateAnnotations={originalCurateAnnotations}
          curateAnnotations={curateAnnotations}
          setCurateAnnotations={setCurateAnnotations}
          selectedAnnotationType={selectedAnnotationType}
        />
      </Box>
    </>
  );
}
