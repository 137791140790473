import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Link } from '@superb-ai/norwegian-forest';
import { ProgressBar, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { SerializedMeteringSelector, useMeteringQuery } from '../../../../queries/useMeteringQuery';
import { getUrl } from '../../../../routes/util';
import { formatCount } from '../../../../utils/numberFormat';
import { convertMeteringUnit, MeteringColors, MeteringSelectorToI18n } from './styles';

export function MeteringMini({
  show,
}: {
  show: Exclude<SerializedMeteringSelector, 'model:endpoint'>[];
}) {
  const { t } = useTranslation();
  const { data: metering } = useMeteringQuery();
  const { accountName } = useParams<{ accountName: string }>();
  const authInfo = useAuthInfo();

  function clickFirstLink(e: React.MouseEvent<HTMLDivElement>) {
    e.currentTarget.getElementsByTagName('a')[0]?.click();
  }

  // TODO: move this business logic somewhere else
  const selectorsToShow =
    authInfo.tier === 'FREE' ? show.filter(selector => selector !== 'common:ai-credit') : show;

  return (
    <Box display="flex" flexDirection="column">
      {selectorsToShow.map(selector => {
        const meteringInfo = metering?.get(selector);
        const ratio = (meteringInfo?.quantity ?? 0) / (meteringInfo?.maxQuantity ?? 1);
        return (
          <Box
            key={selector}
            display="flex"
            flexDirection="column"
            p={1}
            borderRadius
            gap="8px"
            className="clickable"
            onClick={clickFirstLink}
          >
            <Typography variant="s-strong">
              <Link to={getUrl([accountName, 'label', 'settings', 'billing'])}>
                {t(MeteringSelectorToI18n[selector])}
              </Link>
            </Typography>
            <ProgressBar
              showExceeding={meteringInfo?.onDemand}
              value={ratio}
              color={MeteringColors[selector]}
              size="small"
            />
            <Typography variant="m-regular">
              {formatCount(convertMeteringUnit(meteringInfo?.quantity, selector))} /{' '}
              <span style={{ color: '#7B7B7B' }}>
                {formatCount(convertMeteringUnit(meteringInfo?.maxQuantity, selector))}
              </span>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
