import { CSSProperties } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { Box } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';
import { TFunction } from 'i18next';
import { camelCase } from 'lodash';

import TableSortLabel from '../../../elements/TableSortLabel';
import { AnnotationChangeType, AnnotationsChangedTableProps } from './types/types';
import { exportColors, getChangeTypeTextColor } from './utils/colorScale';

interface HeaderProps {
  tableName: string;
  headers: AnnotationsChangedTableProps[];
  isDesc: boolean;
  orderBy: string;
  onRequestSort: (property: string, order: 'desc' | 'asc') => void;
}

const getTransComponent = (column: string, t: TFunction) => {
  if (column.endsWith('queryCount')) {
    return (
      <Trans i18nKey={column}>
        # in <strong style={{ color: exportColors['base'] }}>Base</strong> Export
      </Trans>
    );
  } else if (column.endsWith('refCount')) {
    return (
      <Trans i18nKey={column}>
        # in <strong style={{ color: exportColors['compare'] }}>Compare</strong> Export
      </Trans>
    );
  }
  return t(column);
};

const ExportsAnalyticsTableHeader = (props: HeaderProps) => {
  const { tableName, headers, isDesc, onRequestSort, orderBy } = props;
  const { t } = useTranslation();

  const topHeaderStyle: CSSProperties = {
    borderBottom: '1px solid #CFCFCF',
  };

  return (
    <TableHead style={{ boxShadow: 'none' }}>
      <TableRow key={`table-header-${tableName}`} style={topHeaderStyle}>
        {headers.map(header => (
          <TableCell
            key={`${header.id}-${header.name}-cell`}
            align="right"
            style={{
              backgroundColor: header.color,
              color: getChangeTypeTextColor(camelCase(header.id) as AnnotationChangeType),
              lineHeight: 1,
              textAlign: 'right',
              padding: '12px',
            }}
          >
            <TableSortLabel
              key={`${header.id}-${header.name}-sortLabel`}
              property={header.id}
              isDesc={isDesc}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            >
              <Box display="flex" alignItems="center" ml={0.5}>
                <Typography variant="m-medium">
                  {header.name && getTransComponent(header.name, t)}
                </Typography>
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ExportsAnalyticsTableHeader;
