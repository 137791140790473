import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { AppRouteLayoutProps } from '../../../../../../menu';
import { joinPaths } from '../../../../../../routes/util';
import SliceListLayout from './list/Layout';
import Layout from './slice/Layout';

export default function SlicesRoute({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={joinPaths(match.path, 'list')} component={SliceListLayout} />
      <Route path={joinPaths(match.path, ':sliceId')} component={Layout} />
      <Redirect to={joinPaths(match.path, 'list')} />
    </Switch>
  );
}
