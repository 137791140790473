import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { AnnotationFilterSchema, ClusterLevel } from '../components/datasets/dataset/filter/types';
import {
  GeoTileAggConfig,
  ImageTileRequestBody,
  ImageTileResponse,
  ObjectTileRequestBody,
  ObjectTileResponse,
  ScatterParams,
} from '../components/datasets/dataset/views/embedding/types';
import { Command, UPDATE_ANNOTATION_SCATTER, UPDATE_IMAGE_SCATTER } from '../types/commandTypes';
import { ScopeMode } from '../types/viewTypes';
import { formatAnnotationFilter } from '../utils/filterUtils';
import { buildDatasetQueryEndpoint } from './utils';

export type EmbeddingParams = {
  datasetId: string;
  fromPublicDatasets: boolean;
  slice?: string;
  query?: string;
  scope: ScopeMode;
};

function buildEndpoint(path: string, fromPublicDatasets: boolean) {
  return `/curate/dataset-analytics/${fromPublicDatasets ? 'public-' : ''}datasets/${path}`;
}

const getLatestUpdateScatterJob: AfterLoginCallback<
  Command<typeof UPDATE_IMAGE_SCATTER | typeof UPDATE_ANNOTATION_SCATTER>,
  EmbeddingParams
> = async params => {
  const { datasetId, fromPublicDatasets, scope } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/curate/batch/jobs/update-${scope === 'object' ? 'annotation' : scope}-scatter/${
      fromPublicDatasets ? 'public-' : ''
    }datasets/${datasetId}/latest/`,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

const getImageScatterTiles: AfterLoginCallback<
  ImageTileResponse,
  ScatterParams & GeoTileAggConfig
> = async params => {
  const {
    datasetId,
    fromPublicDatasets,
    tilePrecision,
    maxNumDocs,
    size,
    searchAfter,
    slice,
    query,
    clusterLevel,
  } = params.data;
  const url = buildEndpoint(`${datasetId}/scatter/image-tiles/_search`, fromPublicDatasets);

  const requestBody: ImageTileRequestBody = {
    size,
    maxNumDocs,
    tilePrecision,
    clusterLevel,
    ...(searchAfter && { searchAfter }),
    ...(slice && { slice }),
    ...(query && { query }),
  };

  const result = await apiCallAfterLogin({
    method: 'post',
    url,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: requestBody,
  });
  return result.data;
};

const getObjectScatterTiles: AfterLoginCallback<
  ObjectTileResponse,
  ScatterParams & GeoTileAggConfig & { appliedFilters?: AnnotationFilterSchema }
> = async params => {
  const {
    datasetId,
    fromPublicDatasets,
    tilePrecision,
    maxNumDocs,
    size,
    searchAfter,
    slice,
    query,
    appliedFilters,
    clusterLevel,
  } = params.data;
  const url = buildEndpoint(`${datasetId}/scatter/annotation-tiles/_search`, fromPublicDatasets);

  const requestBody: ObjectTileRequestBody = {
    size,
    max_num_docs: maxNumDocs,
    cluster_level: clusterLevel,
    tile_precision: tilePrecision,
    ...(searchAfter && { search_after: searchAfter }),
    ...(slice && { slice }),
    ...(query && { query }),
    ...(appliedFilters && formatAnnotationFilter(appliedFilters)),
  } as ObjectTileRequestBody;

  const result = await apiCallAfterLogin({
    method: 'post',
    url,
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: requestBody,
  });
  return result.data;
};

export type ClusterLevelsResponse = {
  highLevel: number;
  lowLevel: number;
};

export type Cluster = { id: string | null; size?: number; annotationCount?: number };
export type ImageCluster = { id: string | null; size: number };
export type ImageLeafCluster = [string, number];
type ClusterListResponse = {
  results: ImageCluster[];
};
type LeafClusterListResponse = {
  results: ImageLeafCluster[];
};

export type GetClustersRequestParams = {
  datasetId: string;
  clusterLevel?: ClusterLevel;
  sliceName?: string;
  fromPublicDatasets: boolean;
  queryString?: string;
};
const getImageClusters: AfterLoginCallback<
  ClusterListResponse,
  GetClustersRequestParams
> = async params => {
  const { datasetId, clusterLevel, sliceName, fromPublicDatasets } = params.data;

  const url = buildDatasetQueryEndpoint(`${datasetId}/image-clusters/_search`, fromPublicDatasets);
  const requestBody: { cluster_level?: ClusterLevel } | Record<never, never> = {
    ...(clusterLevel && { cluster_level: clusterLevel }),
    ...(sliceName && { slice: sliceName }),
  };

  const result = await apiCallAfterLogin({
    method: 'post',
    url,
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: requestBody,
  });
  return result.data;
};

const getImageLeafClusters: AfterLoginCallback<
  LeafClusterListResponse,
  Omit<GetClustersRequestParams, 'clusterLevel'>
> = async params => {
  const { datasetId, sliceName, fromPublicDatasets, queryString } = params.data;

  const url = buildDatasetQueryEndpoint(`${datasetId}/image-clusters/_search`, fromPublicDatasets);
  const requestBody: { cluster_level?: ClusterLevel } | Record<never, never> = {
    cluster_level: 'leaf',
    ...(sliceName && { slice: sliceName }),
    ...(queryString && { query: queryString }),
  };

  const result = await apiCallAfterLogin({
    method: 'post',
    url,
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: requestBody,
  });
  return result.data;
};

export function useDataOpsEmbeddingService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getLatestUpdateScatterJob: afterLoginFetcher(getLatestUpdateScatterJob),
    getImageScatterTiles: afterLoginFetcher(getImageScatterTiles),
    getObjectScatterTiles: afterLoginFetcher(getObjectScatterTiles),
    getImageClusters: afterLoginFetcher(getImageClusters),
    getImageLeafClusters: afterLoginFetcher(getImageLeafClusters),
  };
}
