import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@superb-ai/norwegian-forest';

import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ParamUtils from '../../../../../utils/ParamUtils';
import { JsonObj } from '../../userStats/types';
import { BinSizeOptions } from './histogramUtil';

interface StampProps {
  brushElement: SVGGElement;
  brushStart: number;
  brushEnd: number;
  scoreMin: number;
  scoreMax: number;
  data: JsonObj[];
  totalLabelCount: number;
  binSize: BinSizeOptions;
}

/**
 * Calculate and display brushed data statistics here, including:
 *  - score range
 *  - mean score
 *  - label count
 */
const Stamp: React.FC<StampProps> = props => {
  const { data, brushElement, brushStart, brushEnd, scoreMin, scoreMax, totalLabelCount } = props;

  const routeInfo = useRouteInfo();
  const stampRef = useRef<HTMLElement>();
  const { t } = useTranslation();
  const [stampPosition, setStampPosition] = useState<{ left: number; bottom: number }>();
  const [brushInfo, setBrushInfo] = useState({ labelCount: 0, scoreSum: 0 });

  useEffect(() => {
    if (!stampRef.current) return;
    const outerBoxLeft = document.getElementById('consensus-histogram-box')?.offsetLeft || 0;
    const brushBoxLeft = brushElement.getBoundingClientRect().left;
    const stampBoxWidth = stampRef.current.getBoundingClientRect().width;
    const bottomOffset = 52;

    setStampPosition({
      left: brushBoxLeft - outerBoxLeft + (brushStart + brushEnd) / 2 - stampBoxWidth / 2,
      bottom: bottomOffset,
    });
  }, [stampRef, brushElement, brushStart, brushEnd, brushInfo]);

  const boundedScoreMin = Math.max(scoreMin, 0);
  const boundedScoreMax = Math.min(scoreMax, 100);

  useEffect(() => {
    // given score min and max, get brushed data
    let scoreSum = 0;
    let labelCount = 0;
    for (const datum of data) {
      if (datum.scoreBin >= boundedScoreMin && datum.scoreBin <= boundedScoreMax) {
        labelCount += datum.labelCount;
        scoreSum += datum.scoreBin * datum.labelCount;
      }
    }

    setBrushInfo({ scoreSum, labelCount });
  }, [data, boundedScoreMin, boundedScoreMax]);

  // calculate mean iff denominator is > 0
  const meanScore =
    brushInfo.scoreSum >= 0 && brushInfo.labelCount > 0
      ? (brushInfo.scoreSum / brushInfo.labelCount).toFixed(1)
      : 'n/a';
  const percentTotal = ((100 * brushInfo.labelCount) / totalLabelCount).toFixed(1);

  const handleButtonClick = () => {
    const filterParams = ParamUtils.getUrlParamsForFilters([
      {
        condition: 'is in the range',
        filterBy: 'consistency score',
        options: [
          { value: scoreMin, label: boundedScoreMin },
          { value: scoreMax, label: boundedScoreMax },
        ],
      },
    ]);
    routeInfo.history.push(
      `/${routeInfo.urlMatchInfo.accountName}/project/${
        routeInfo.urlMatchInfo.projectId
      }/labels?${new URLSearchParams({
        ...filterParams,
      }).toString()}`,
    );
  };
  return (
    <Box
      ref={stampRef}
      position="absolute"
      style={{
        left: stampPosition?.left || 0,
        bottom: stampPosition?.bottom || 0,
        fontFamily: 'Infer',
      }}
      opacity={0.9}
      borderRadius="2px"
      themedBackgroundColor={['grey', 800]}
      color="white"
      p={1}
    >
      <Typography variant="body3">
        {`${t('analytics.text.labelCount')}: `}
        <strong>
          {brushInfo.labelCount} ({percentTotal}%){' '}
        </strong>
        <br />
        {`${t('analytics.qa.scores')}: `}
        <strong>
          {boundedScoreMin === boundedScoreMax
            ? boundedScoreMin
            : `${boundedScoreMin} ~ ${boundedScoreMax}`}
        </strong>
        <br />
        {`${t('analytics.qa.meanScore')}: `} <strong>{meanScore}</strong>
      </Typography>
      <Box mt={2} justifyContent="center" display="flex">
        <Button size="xs" onClick={handleButtonClick}>
          {t('analytics.project.viewLabels')}
        </Button>
      </Box>
    </Box>
  );
};

export default Stamp;
