import React, { ComponentProps } from 'react';

import { Input } from '@superb-ai/ui';

import { useCurateDatasetService } from '../../services/DatasetService';
import NameInput from './NameInput';

export default function DatasetNameInput({
  datasetName,
  setDatasetName,
  setIsValid,
  ...inputProps
}: {
  datasetName: string;
  setDatasetName: (name: string) => void;
  setIsValid: (isValid: boolean) => void;
  disabled?: boolean;
  placeholder?: string;
} & ComponentProps<typeof Input>) {
  const { getSearchDatasetName } = useCurateDatasetService();

  return (
    <NameInput
      target="dataset"
      name={datasetName}
      setName={setDatasetName}
      onSearchName={async (name: string) => {
        await getSearchDatasetName({ name });
      }}
      setIsValid={setIsValid}
      {...inputProps}
    />
  );
}
