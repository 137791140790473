import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { CurateAnnotationType } from '../../../types/curationTypes';
import {
  AnnotationFilterSchema,
  ClassCluster,
  ClusterLevel,
  FilterSchema,
  FilterSchemaWithExpands,
  ImageClusterFilterSchema,
} from '../components/datasets/dataset/filter/types';
import { AnnotationType, AnnotationTypeCoordinate } from '../types/annotationTypes';
import { KeysToCamelCase } from '../types/typeUtils';
import { formatAnnotationFilter, formatImageClusterFilter } from '../utils/filterUtils';
import { buildDatasetQueryEndpoint } from './utils';

type _DatasetObjects = {
  created_at: Date;
  created_by: string;
  updated_at: Date;
  updated_by: string;
  id: string; // annotation id
  dataset_id: string;
  image_id: string;
  annotation_class: string;
  annotation_type: AnnotationType;
  metadata: Record<string, any>[];
  annotation_value: AnnotationTypeCoordinate<AnnotationType>;
  is_image_synthetic: boolean;
  roi: { x: number; y: number; width: number; height: number };
  image_thumbnail_url?: string;
  small_image_thumbnail_url?: string;
  original_image_size?: {
    width: number;
    height: number;
  };
};

export type DatasetObjects = KeysToCamelCase<_DatasetObjects>;

export type DatasetObjectsResult = {
  count: number;
  last: string[];
  results: DatasetObjects[];
  datasetTotalCount?: number;
  syntheticTotalCount?: number;
  clipEmbedTotalCount?: number;
};

export type PostGetObjectRequest = {
  slice?: string; // slice name
  query?: string;
  id_in?: string[];
  annotation_filters?: AnnotationFilterSchema;
  search_after?: string[];
  sort_by?: string[];
  sort_order?: string[];
  size?: number;
  expand?: (
    | 'image_thumbnail_url'
    | 'original_image_size'
    | 'small_image_thumbnail_url'
    | 'dataset_total_count'
    | 'cluster_id'
    | 'label_project_sync_exists'
    | 'synthetic_total_count'
  )[];
  embedding_ready?: boolean;
  cluster_level?: ClusterLevel;
  objectSimilarTo?: string;
  clipSimilarTo?: number[];
  clipEmbedExists?: boolean;
};

const postGetObjectList: AfterLoginCallback<
  DatasetObjectsResult,
  { dataset_id: string; fromPublicDatasets: boolean } & PostGetObjectRequest
> = async params => {
  if (!params.data) return;
  const { dataset_id, annotation_filters, fromPublicDatasets, clipEmbedExists, ...body } =
    params.data;
  const formattedAnnotationFilters = formatAnnotationFilter(annotation_filters);
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${dataset_id}/annotations/_search`, fromPublicDatasets),
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: {
      ...body,
      ...formattedAnnotationFilters,
      ...(params.data.objectSimilarTo && {
        object_similar_to: params.data.objectSimilarTo,
      }),
      ...(params.data.clipSimilarTo && {
        clip_similar_to: params.data.clipSimilarTo,
      }),
      ...(params.data.clipEmbedExists && {
        clip_embed_exists: params.data.clipEmbedExists,
      }),
    },
  });
  return data;
};

const getFilterSchema: AfterLoginCallback<
  { annotation_filters: FilterSchemaWithExpands },
  {
    datasetId: string;
    fromPublicDatasets: boolean;
    sliceName?: string;
    expand?: (
      | 'annotation_class'
      | 'annotation_type'
      | 'metadata_cardinality'
      | 'annotation_class_cluster_level'
    )[];
    // | 'class_cluster'
    clusterLevel?: ClusterLevel;
    metadataKeys?: string[];
    metadataInSize?: number;
  }
> = async params => {
  if (!params.data) return;
  const {
    datasetId,
    sliceName,
    expand,
    metadataKeys,
    metadataInSize,
    fromPublicDatasets,
    clusterLevel,
  } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/annotation-filter-schema-v2?${qs.stringify(
        {
          slice: sliceName,
          expand,
          metadata_in: metadataKeys,
          metadata_in_size: metadataInSize,
          cluster_level: clusterLevel,
        },
        { arrayFormat: 'brackets' },
      )}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });
  return data;
};

const getAnnotationClusterFilterSchemaPerClass: AfterLoginCallback<
  { clusters: ClassCluster[] },
  {
    datasetId: string;
    fromPublicDatasets: boolean;
    annotationClass: string;
    clusterLevel: ClusterLevel;
    sliceName?: string;
  }
> = async params => {
  if (!params.data) return;
  const { annotationClass, clusterLevel, datasetId, fromPublicDatasets, sliceName } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: buildDatasetQueryEndpoint(
      `${datasetId}/cluster-ids-by-annotation-class?${qs.stringify(
        {
          annotation_class: annotationClass,
          cluster_level: clusterLevel,
          slice: sliceName,
        },
        { arrayFormat: 'brackets' },
      )}`,
      fromPublicDatasets,
    ),
    hasPublicApi: false,
    isCurateUrl: true,
    transformResponseToCamelCase: false,
    ...params,
  });
  return data;
};

export type AnnotationClassWithType = {
  annotationType: CurateAnnotationType;
  name: string;
};

const postGetAnnotationClasses: AfterLoginCallback<
  { annotationClasses: AnnotationClassWithType[] },
  {
    datasetId: string;
    slice?: string;
    query?: string;
    imageFilters?: ImageClusterFilterSchema;
    annotationFilters?: FilterSchema;
  }
> = async params => {
  const { datasetId, ...requestBody } = params.data;
  const { annotationFilters, imageFilters, ...otherParams } = requestBody;
  const formattedAnnotationFilters = formatAnnotationFilter(annotationFilters);
  const formattedImageFilters = formatImageClusterFilter(imageFilters);

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${datasetId}/annotation-classes/_search`, false),
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: {
      ...otherParams,
      annotation_filters: formattedAnnotationFilters.annotation_filters,
      image_filters: formattedImageFilters,
    },
  });

  return data;
};

const postGetAnnotationMetadataKeys: AfterLoginCallback<
  { metadataKeys: string[] },
  {
    datasetId: string;
    annotation_class: string;
    slice?: string;
    query?: string;
    imageFilters?: ImageClusterFilterSchema;
    annotationFilters?: FilterSchema;
  }
> = async params => {
  const { datasetId, ...requestBody } = params.data;
  const { annotationFilters, imageFilters, ...otherParams } = requestBody;
  const formattedAnnotationFilters = formatAnnotationFilter(annotationFilters);
  const formattedImageFilters = formatImageClusterFilter(imageFilters);

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${datasetId}/annotation-metadata-keys/_search`, false),
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: {
      ...otherParams,
      annotation_filters: formattedAnnotationFilters.annotation_filters,
      image_filters: formattedImageFilters,
    },
  });

  return data;
};

const postGetAnnotationMetadataValues: AfterLoginCallback<
  { count: number; results: string[] },
  {
    datasetId: string;
    annotation_class: string;
    annotation_metadata_key: string;
    slice?: string;
    query?: string;
    imageFilters?: ImageClusterFilterSchema;
    annotationFilters?: FilterSchema;
  }
> = async params => {
  const { datasetId, ...requestBody } = params.data;
  const { annotationFilters, imageFilters, ...otherParams } = requestBody;
  const formattedAnnotationFilters = formatAnnotationFilter(annotationFilters);
  const formattedImageFilters = formatImageClusterFilter(imageFilters);

  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: buildDatasetQueryEndpoint(`${datasetId}/annotation-metadata-values/_search`, false),
    hasPublicApi: false,
    isCurateUrl: true,
    transformRequestToSnakeCase: false,
    ...params,
    data: {
      ...otherParams,
      annotation_filters: formattedAnnotationFilters.annotation_filters,
      image_filters: formattedImageFilters,
    },
  });

  return data;
};

export function useCurateDatasetObjectService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    postGetObjectList: afterLoginFetcher(postGetObjectList),
    getFilterSchema: afterLoginFetcher(getFilterSchema),
    getAnnotationClusterFilterSchemaPerClass: afterLoginFetcher(
      getAnnotationClusterFilterSchemaPerClass,
    ),
    postGetAnnotationClasses: afterLoginFetcher(postGetAnnotationClasses),
    postGetAnnotationMetadataKeys: afterLoginFetcher(postGetAnnotationMetadataKeys),
    postGetAnnotationMetadataValues: afterLoginFetcher(postGetAnnotationMetadataValues),
  };
}
