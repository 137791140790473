import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';

import { Button, ButtonGroup, IconButton, Tooltip } from '@superb-ai/norwegian-forest';

import UploadLayout from '../../../components/elements/newUpload/Layout';
import { getIsRoleOrderSameOrLess } from '../../../components/pages/label/labelList/AssignDialog/utils';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import AnalyticsTracker from '../../../analyticsTracker';
import UserRoleUnion from '../../../union/UserRoleUnion';
import { assertUrlMatchInfo, openWorkapp } from '../../../utils/WorkappUtils';
import { Params } from './path';

export default function MenuButtons(): JSX.Element | null {
  const { t } = useTranslation();
  const match = useRouteMatch<Params>();

  const { accountName } = useParams<{ accountName: string }>();
  const routeInfo = useRouteInfo();
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();
  const { setIsUploadModalOpen } = useUploadInfo();

  const handleClickStartLabeling = useCallback(() => {
    assertUrlMatchInfo(routeInfo.urlMatchInfo);
    openWorkapp({
      workapp: projectInfo.project.workapp,
      urlMatchInfo: routeInfo.urlMatchInfo,
      labelId: null,
      appEnv: 'new-tab',
      mode: 'edit',
    });
    AnalyticsTracker.startLabelingClicked({
      accountId: accountName,
    });
  }, [routeInfo.urlMatchInfo, projectInfo.project.workapp, authInfo.accountName]);

  const handleClickStartReview = useCallback(() => {
    openWorkapp({
      workapp: projectInfo.project.workapp,
      urlMatchInfo: routeInfo.urlMatchInfo as { accountName: string; projectId: string },
      labelId: null,
      appEnv: 'new-tab',
      mode: 'view',
    });
    AnalyticsTracker.startReviewClicked({
      accountId: accountName,
      dataType: projectInfo.project.dataType,
    });
  }, [projectInfo.project.workapp, routeInfo.urlMatchInfo]);

  useEffect(() => {
    window.CommandBar?.addCallback('startLabeling', () => handleClickStartLabeling());
    window.CommandBar?.addCallback('startReview', () => handleClickStartReview());
    return () => {
      window.CommandBar?.removeCallback('startLabeling');
      window.CommandBar?.removeCallback('startReview');
    };
  }, [handleClickStartLabeling, handleClickStartReview]);

  if (authInfo.isGuest) {
    return null;
  }

  const canUpload = getIsRoleOrderSameOrLess(authInfo.projectRole || authInfo.role || '', 'Admin');
  const canReview = getIsRoleOrderSameOrLess(
    authInfo.projectRole || authInfo.role || '',
    'Reviewer',
  );
  const canLabel = !UserRoleUnion.isReviewer(authInfo.projectRole);

  const withTooltip = (elem: JSX.Element, text: string, expand = false) => {
    return (
      <Tooltip
        anchorEl={
          <span style={{ display: 'inline-flex', width: expand ? '100%' : 'auto' }}>{elem}</span>
        }
        placement="bottom-start"
      >
        {text}
      </Tooltip>
    );
  };

  return (
    <ButtonGroup gap={0.5}>
      {canUpload &&
        withTooltip(
          <IconButton
            onClick={() => {
              AnalyticsTracker.labelUploadDialogOpened({
                referrer: 'label-project-menu',
                accountId: accountName,
              });
              setIsUploadModalOpen(true);
            }}
            icon="upload"
            variant="stroke"
            color="primary"
            size="m"
            disabled={projectInfo.project.curateDatasetId}
          />,
          projectInfo.project.curateDatasetId
            ? t('data.button.uploadDisabledInSyncedProjectTooltip')
            : t('data.button.uploadTooltip'),
        )}
      {canLabel &&
        withTooltip(
          <Button
            IconAdornment="pencil"
            variant={canUpload || canReview ? 'stroke' : 'strong-fill'}
            color="primary"
            size="m"
            onClick={handleClickStartLabeling}
            style={{ padding: '0 5px' }}
          >
            {t('labels.button.label')}
          </Button>,
          t('labels.button.labelTooltip'),
          true,
        )}
      {canReview &&
        withTooltip(
          <Button
            IconAdornment="fileFind"
            variant="stroke"
            color="primary"
            size="m"
            onClick={handleClickStartReview}
            style={{ padding: '0 5px' }}
          >
            {t('labels.button.review')}
          </Button>,
          t('labels.button.reviewTooltip'),
          true,
        )}
      <UploadLayout projectId={match.params.projectId} />
    </ButtonGroup>
  );
}
