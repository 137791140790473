export const GRID_VIEW = 'grid';
export const ANALYTICS_VIEW = 'analytics';
export const SEMANTIC_SEARCH_VIEW = 'semantic_search';
export const SCATTER_VIEW = '2d';
export const IMAGE_SCOPE = 'image';
export const OBJECT_SCOPE = 'object';

export type ViewMode =
  | typeof GRID_VIEW
  | typeof ANALYTICS_VIEW
  | typeof SCATTER_VIEW
  | typeof SEMANTIC_SEARCH_VIEW;
export const VIEWS: ViewMode[] = [GRID_VIEW, ANALYTICS_VIEW, SCATTER_VIEW, SEMANTIC_SEARCH_VIEW];

export type ScopeMode = typeof IMAGE_SCOPE | typeof OBJECT_SCOPE;
export const SCOPES: ScopeMode[] = [IMAGE_SCOPE, OBJECT_SCOPE];

export const VIEW = 'view';
export const SCOPE = 'scope';
export const isImageScope = (scope: ScopeMode) => scope === IMAGE_SCOPE;
