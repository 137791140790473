import { cloneElement } from 'react';

import cn from 'classnames';

import { ExtendedBaseMenuItem } from '../../../menu/AppMenu';

export default function AppsMenuItem({
  item,
  currentPath,
  group,
}: {
  item: ExtendedBaseMenuItem;
  currentPath: string;
  group: 'suite' | 'account';
}) {
  const isSuiteAppsGroup = group === 'suite';

  return (
    <span className="menu-item">
      <span className="menu-item-content">
        <span
          className={cn('icon-wrap', 'variant-icon', {
            [`suite-type-${item.path}`]: isSuiteAppsGroup,
            [`${group}-menu-group`]: true,
          })}
        >
          {item.icon &&
            cloneElement(item.icon as JSX.Element, {
              size: isSuiteAppsGroup ? '24px' : '20px',
              isInverted: isSuiteAppsGroup && currentPath === item.path ? true : false,
            })}
        </span>
        {/* menu가 최소화 됐을 때, 한국어로 설정되어있으면 height가 커진다. */}
        <div data-page-id={item.pageId} className="label" style={{ height: '13.7px' }}>
          {item.title}
        </div>
      </span>
    </span>
  );
}
