import React from 'react';
import { useHistory } from 'react-router';

import { Box, Button } from '@superb-ai/norwegian-forest';

type Props = {
  previousPageName: string;
  previousPageUrl: string;
};
export default function Breadcrumbs({
  previousPageName,
  previousPageUrl,
}: React.PropsWithChildren<Props>) {
  const history = useHistory();

  return (
    <Box mb={2}>
      <Button
        variant="text-simple"
        IconAdornment="arrowLeft"
        onClick={() => history.push(previousPageUrl)}
      >
        {previousPageName}
      </Button>
    </Box>
  );
}
