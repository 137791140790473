import React from 'react';
import { useHistory, useParams } from 'react-router';

import { LinkExternal } from '@superb-ai/icons';
import { Box, Icon, LinkTypography } from '@superb-ai/ui';

import { useProjectsQuery } from '../../../../../queries/useProjectQuery';
import { getUrl } from '../../../../../routes/util';

export default function SyncedLabelListTooltip({
  syncedLabelProjectIds,
}: {
  syncedLabelProjectIds: string[];
}) {
  const { accountName } = useParams<{ accountName: string }>();
  const history = useHistory();

  const { data } = useProjectsQuery({ projectIds: syncedLabelProjectIds });

  return (
    <Box>
      {data?.map(project => (
        <Box key={project.id}>
          <LinkTypography
            variant="m-regular"
            color="white"
            display="flex"
            alignItems="center"
            gap={0.5}
            onClick={e => {
              e.stopPropagation();
              history.push(getUrl([accountName, 'label', 'project', project.id]));
            }}
          >
            {project.name}
            <Icon icon={LinkExternal} />
          </LinkTypography>
        </Box>
      ))}
    </Box>
  );
}
