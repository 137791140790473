import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreVertical } from '@superb-ai/icons';
import { Popper } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

export default function QueryBoxEvent({
  boxNum,
  listIndex,
  anchorEl,
  setAnchorEl,
  handleOpen,
  deleteQuery,
  turnIntoFilter,
  wrapInGroup,
  duplicateQuery,
}: {
  boxNum: number;
  listIndex: number;
  anchorEl: string | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<string | null>>;
  handleOpen: (e: React.MouseEvent, id: string) => void;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  turnIntoFilter: (boxNum: number, listIndex: number) => void;
  wrapInGroup: (boxNum: number, listIndex: number) => void;
  duplicateQuery: (boxNum: number, listIndex: number) => void;
}) {
  const { t } = useTranslation();
  const handleDuplicateQuery = useCallback(() => {
    duplicateQuery(boxNum, listIndex);
  }, [boxNum, listIndex, duplicateQuery]);

  const isPopperOpen = useMemo(() => {
    return anchorEl === (10 * boxNum + (listIndex + 1)).toString();
  }, [anchorEl, boxNum, listIndex]);

  return (
    <div style={{ position: 'absolute', top: '8px', right: '-20px', cursor: 'pointer' }}>
      <Popper
        open={isPopperOpen}
        anchorEl={
          <Box
            position="relative"
            onClick={e => {
              handleOpen(e, (10 * boxNum + (listIndex + 1)).toString());
            }}
          >
            <Icon icon={MoreVertical} />
          </Box>
        }
        offset={1}
        placement={'bottom'}
        useOutsidePanel
        onClickOutside={() => setAnchorEl(null)}
      >
        <div
          style={{
            position: 'absolute',
            right: '-70px',
            width: '104px',
            height: `${boxNum < 1000 ? '136px' : '102px'}`,
            zIndex: '1302',
            background: '#ffffff',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            boxSizing: 'border-box',
          }}
        >
          <Button
            variant="text"
            color="gray"
            style={{
              padding: '4px 12px',
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              turnIntoFilter(boxNum, listIndex);
            }}
          >
            <Typography variant="m-regular">{t('curate.button.turnIntoFilter')}</Typography>
          </Button>
          {boxNum < 1000 ? (
            <Button
              variant="text"
              color="gray"
              style={{
                padding: '4px 12px',
                width: '100%',
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              onClick={() => {
                wrapInGroup(boxNum, listIndex);
              }}
            >
              <Typography variant="m-regular">{t('curate.button.wrapInGroup')}</Typography>
            </Button>
          ) : (
            <></>
          )}

          <Button
            variant="text"
            color="gray"
            style={{
              padding: '4px 12px',
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={handleDuplicateQuery}
          >
            <Typography variant="m-regular">{t('curate.button.duplicate')}</Typography>
          </Button>
          <Button
            variant="text"
            color="gray"
            style={{
              padding: '4px 12px',
              width: '100%',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={() => deleteQuery(boxNum, listIndex)}
          >
            <Typography variant="m-regular" color={'primary-400'}>
              {t('curate.button.delete')}
            </Typography>
          </Button>
        </div>
      </Popper>
    </div>
  );
}
