import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    height: '40px',
    background: '#000',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
    zIndex: 10,
  },
  icon: {},
  typo: {
    fontSize: '16px',
    color: '#fff',
  },
}));
// eslint-disable-next-line
const AutoLabelUncertainAlertBar = (props: any): React.ReactElement => {
  const { count } = props;
  const classes = useStyles();

  return (
    <MUI.Box className={classes.box}>
      <Icon name="warningOutline" customColor="#ffcc00" size="20px" />;
      <MUI.Typography className={classes.typo}>
        {`${count} auto-labeled annotation(s) need your review.`}
      </MUI.Typography>
    </MUI.Box>
  );
};

export default AutoLabelUncertainAlertBar;
