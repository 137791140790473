import { useMemo } from 'react';

import { IconName } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { Flag, useFeatureFlags } from '../../../../contexts/FeatureFlagContext';
import TierUnion, { Tier } from '../../../../union/TierUnion';
import { DataType } from '../../../../utils/LabelInterfaceUtils';

export const AssetDataTypes = [
  'IMAGE',
  'VIDEO_FRAMES',
  'VIDEO_STREAM',
  'POINT_CLOUD_SEQUENCE',
] as const;
export type AssetDataType = (typeof AssetDataTypes)[number];

export type CloudAssetType =
  | 'img-presigned-url'
  | 'video-presigned-url'
  | 'pointclouds-presigned-url'
  | 'videostream-presigned-url';
export type AssetType = 'img-url' | CloudAssetType;

interface DataTypeDefinition {
  value: AssetDataType;
  icon: IconName;
  formats: string[];
  assetTypes: AssetType[];
  availableAtTier?: (tier: Tier) => boolean;
  requiredFeatureFlags?: Flag[];
}

export const dataTypes: Record<AssetDataType, DataTypeDefinition> = {
  IMAGE: {
    value: 'IMAGE',
    assetTypes: ['img-url', 'img-presigned-url'],
    icon: 'imageOutline',
    formats: ['.png', '.jpg', '.bmp'],
  },

  VIDEO_FRAMES: {
    value: 'VIDEO_FRAMES',
    assetTypes: ['video-presigned-url'],
    icon: 'videoFrames',
    formats: ['.png', '.jpg', '.bmp', '.mp4 (100 MB)'],
    availableAtTier: tier => TierUnion.isTeamOrHigher(tier),
  },

  VIDEO_STREAM: {
    value: 'VIDEO_STREAM',
    assetTypes: ['videostream-presigned-url'],
    icon: 'videoStream',
    formats: ['.mp4', '.wmv', '.avi', '.mpeg', '.mpg', '.mkv', '.webm'],
    availableAtTier: tier => TierUnion.isTeamOrHigher(tier),
    requiredFeatureFlags: ['videoStream'],
  },

  POINT_CLOUD_SEQUENCE: {
    value: 'POINT_CLOUD_SEQUENCE',
    assetTypes: ['pointclouds-presigned-url'],
    icon: 'lidar',
    formats: ['.json', '.png', '.jpg', '.bmp', '.pcd', '.bin'],
    availableAtTier: tier => TierUnion.isEnterprise(tier),
    requiredFeatureFlags: ['lidar'],
  },
};

export const dataTypesByProjectType: Record<DataType, AssetDataType[]> = {
  image: ['IMAGE'],
  video: ['VIDEO_FRAMES', 'VIDEO_STREAM'],
  pointclouds: ['POINT_CLOUD_SEQUENCE'],
};

export function getDataTypeByAssetType(assetType: AssetType): AssetDataType | undefined {
  return AssetDataTypes.find(key => dataTypes[key].assetTypes.includes(assetType));
}

/**
 * Returns only the dataTypes which are available in the current context (tier and feature flags)
 */
export function useAvailableDataTypes(): Partial<Record<AssetDataType, DataTypeDefinition>> {
  const { getFlag } = useFeatureFlags();
  const { tier } = useAuthInfo();

  const availableDataTypes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(dataTypes).filter(([_, dataType]) => {
          if (!tier) return false;
          const hasFlags =
            dataType.requiredFeatureFlags?.every(flag => getFlag(flag).enabled) ?? true;
          const hasTier = dataType.availableAtTier?.(tier) ?? true;
          return hasFlags && hasTier;
        }),
      ),
    [getFlag, tier],
  );

  return availableDataTypes;
}

export function getDataTypeTextKeys(type: AssetDataType) {
  return {
    title: `data.types.${type.toLowerCase()}.title`,
    description: `data.types.${type.toLowerCase()}.description`,
  };
}
