import { useQueryClient } from '@tanstack/react-query';

import { useUpdateProjectList } from '../queries/useProjectListQuery';
import { ProjectData } from '../types/projectTypes';
import { removeEmptyQueryKeyProjectList } from '../utils/ProjectList';

export const useGetProjectList = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateProjectList();

  const getProjectList = async (params: Record<string, any>) => {
    const data = queryClient.getQueryData(['projectList', removeEmptyQueryKeyProjectList(params)]);

    if (data)
      return data as {
        count: number;
        results: ProjectData[];
      };

    return mutateAsync(params);
  };

  return getProjectList;
};
