import { PropsWithChildren, useState } from 'react';

import { ChevronDown } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

export default function DetailModalCabinetCollapse({
  title,
  children,
}: PropsWithChildren<{ title: string | JSX.Element }>) {
  const [open, setOpen] = useState(true);

  return (
    <Box py={1.5} borderBottom="1px solid" borderColor="gray-200">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setOpen(!open)}
        cursor="pointer"
        mb={0.5}
      >
        <Typography variant="l-strong">{title}</Typography>
        <Box
          display="flex"
          alignItems="center"
          style={{
            transition: 'all 0.1s ease-in-out',
            ...(open ? {} : { transform: 'rotate(-90deg)' }),
          }}
        >
          <Icon icon={ChevronDown} />
        </Box>
      </Box>
      <Collapse open={open}>{children}</Collapse>
    </Box>
  );
}
