import { ComponentProps, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Check } from '@superb-ai/icons';
import { Box, Dropdown, Icon, Paragraph, Tooltip, Typography } from '@superb-ai/ui';

import Checkbox from './Checkbox';

export type LegendLabel = {
  key: string;
  legendText: string;
  hexColor: string;
  visible: boolean;
  textColor?: ComponentProps<typeof Dropdown>['color'];
  setVisibility?: (value: boolean) => void;
};

export type SingleLegendProps = {
  legendLabel: LegendLabel;
  isDisabled?: boolean;
  tooltipContent?: string;
  compareSetId?: string;
  setCompareSetId?: (setId: string) => void;
  hasCompareMode?: boolean;
  trackSelectEvent?: () => void;
} & ComponentProps<typeof Box>;

export type LegendProps = SingleLegendProps & {
  legendTooltip?: string;
  dataList: { group?: string; id: string | undefined; name: string | undefined }[];
  compareSetId: string;
  setCompareSetId: (setId: string) => void;
} & ComponentProps<typeof Box>;

export function SingleLegend({
  hasCompareMode,
  legendLabel,
  tooltipContent,
  isDisabled,
  compareSetId,
  setCompareSetId,
  trackSelectEvent,
  ...props
}: SingleLegendProps) {
  const handleClick = (prevState: boolean, setVisibility?: (state: boolean) => void) => {
    const newState = !prevState;
    if (newState === true && trackSelectEvent) {
      trackSelectEvent();
    }
    if (setVisibility) setVisibility(newState);
    !isDisabled && setCompareSetId && compareSetId && setCompareSetId(compareSetId);
  };

  const { key, legendText, hexColor, visible, setVisibility } = legendLabel;
  const textLimit = 24;
  const isTruncated = legendText.length > textLimit;

  const handleBoxClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    setVisibility && handleClick(visible, setVisibility);
  };

  const handleCheckboxChange = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setVisibility && handleClick(visible, setVisibility);
  };

  return (
    <Box
      key={key}
      display="flex"
      alignItems="center"
      gap={1}
      mr={1}
      style={{ cursor: isDisabled ? 'default' : 'pointer' }}
      onClick={handleBoxClick}
      {...props}
    >
      <Tooltip
        content={
          isTruncated ? (
            <div style={{ whiteSpace: 'pre-line' }}>{`${legendText}. \n ${tooltipContent}`}</div>
          ) : (
            tooltipContent
          )
        }
        placement="top"
      >
        <Box
          display="flex"
          flexDirection={'row'}
          gap={1}
          style={{
            whiteSpace: isTruncated ? 'nowrap' : 'normal',
            overflow: 'hidden',
            textOverflow: isTruncated ? 'ellipsis' : 'clip',
          }}
        >
          <Checkbox
            isDisabled={isDisabled}
            key={key + 'checkbox'}
            checkboxColor={hexColor}
            checkMarkColor="white"
            checked={visible}
            onClick={handleCheckboxChange}
          />
          {isTruncated ? legendText.slice(0, textLimit) + '...' : legendText}
        </Box>
      </Tooltip>
    </Box>
  );
}

export function MultiLegend({
  legendTooltip,
  legendLabel,
  dataList,
  compareSetId,
  setCompareSetId,
  ...props
}: LegendProps) {
  const defaultId = dataList[0]?.id;
  const handleClickLabel = (visible: boolean, setVisibility?: (state: boolean) => void) => {
    if (setVisibility) setVisibility(!visible);
    setCompareSetId && setCompareSetId(!compareSetId ? (defaultId as string) : '');
  };

  const handleClickItem = (event: SyntheticEvent, newSetId: string) => {
    setVisibility && setVisibility(true);
    if (compareSetId !== newSetId) {
      setCompareSetId && setCompareSetId(newSetId);
    }
    event.stopPropagation();
  };

  const { t } = useTranslation();
  const { key, hexColor, textColor, visible, setVisibility } = legendLabel;

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Tooltip hideOnEmptyContent content={legendTooltip} placement="top">
        <Box
          key={key}
          display="flex"
          alignItems="center"
          gap={1}
          style={{
            height: '32px',
            cursor: setVisibility ? 'pointer' : 'none',
          }}
          onClick={() => handleClickLabel(visible, setVisibility)}
          {...props}
        >
          {
            <Dropdown
              tabIndex={0}
              color={visible ? textColor : 'gray-opacity'}
              disclosure={
                <Box display="flex" flexDirection={'row'} gap={1}>
                  <Checkbox
                    key={key + 'checkbox'}
                    checkboxColor={hexColor}
                    checkMarkColor="white"
                    checked={visible}
                    onClick={() => handleClickLabel(visible, setVisibility)}
                  />
                  {dataList.find(d => d.id === compareSetId)?.name || dataList[0]?.name}
                </Box>
              }
              size="l"
              variant="text"
            >
              <Box display="flex" flexDirection="column" style={{ lineHeight: '10px' }}>
                {dataList.map(
                  ({ group, id, name }) =>
                    id && (
                      <Box
                        key={group}
                        py={1}
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography pl={1.5} variant={'m-medium'} color="gray-300">
                          {group}
                        </Typography>
                        <Tooltip
                          content={t(`curate.analytics.dataComparison.compareWith${group}`)}
                          placement="right"
                        >
                          <Paragraph
                            key={id}
                            mb={0.5}
                            px={2}
                            py={1}
                            variant="l-regular"
                            color={visible && id === compareSetId ? textColor : 'gray-400'}
                            onClick={event => handleClickItem(event, id)}
                            backgroundColor={{ hover: 'primary-200' }}
                            style={{
                              cursor: 'pointer',
                              lineHeight: '16px',
                            }}
                          >
                            {'  '}
                            {name}
                            {visible && id === compareSetId && (
                              <Icon style={{ marginLeft: '5px' }} icon={Check} />
                            )}
                          </Paragraph>
                        </Tooltip>
                      </Box>
                    ),
                )}
              </Box>
            </Dropdown>
          }
        </Box>
      </Tooltip>
    </Box>
  );
}
