import { HeatmapStyle, RecursivePartial } from '@elastic/charts';

import { chartColors } from '../../../../../apps/Curate/components/datasets/dataset/modelDiagnosis/diagnosis/analytics/colorScale';

export const heatmapStyle: RecursivePartial<HeatmapStyle> = {
  brushTool: {
    visible: true,
  },
  grid: {
    stroke: {
      width: 0,
    },
    cellHeight: {
      min: 34,
      max: 68,
    },
  },
  cell: {
    maxWidth: 'fill',
    label: {
      fontWeight: 600,
      textColor: chartColors.text,
      minFontSize: 12,
      visible: true,
      useGlobalMinFontSize: false,
    },
    border: {
      stroke: 'transparent',
      strokeWidth: 1,
    },
  },
  xAxisLabel: {
    fontSize: 12,
    visible: true,
    textColor: chartColors.text,
    padding: { left: 10, right: 10 },
  },
  yAxisLabel: {
    fontSize: 12,
    visible: true,
    textColor: chartColors.text,
    padding: { left: 10, right: 10 },
    width: { max: 200 },
  },
  maxLegendHeight: 100,
};
