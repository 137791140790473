import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronDown } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';
import { findIndex } from 'lodash';

import { DELETE_ISSUE } from '../../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useModal } from '../../../../../hooks/ModalHooks';
import IssuesService from '../../../../../services/IssuesService';
import UserRoleUnion from '../../../../../union/UserRoleUnion';
import { formatDistance } from '../../../../../utils/date';
import UserUtils from '../../../../../utils/UserUtils';
import RoleChip from '../../../../elements/RoleChip';
import TextEllipsisTooltip from '../../../../elements/TextEllipsisTooltip';
import UserAvatar from '../../../../elements/UserAvatar';
import { CommentProps, ThreadProps } from './types';

const useStyles = makeStyles({
  commentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: '3px'
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    fontSize: '12px',
    marginRight: '10px',
    fontWeight: 500,
    maxWidth: '60px',
  },
  removedName: {
    color: '#a6a6a6',
    fontSize: '12px',
    fontWeight: 500,
    maxWidth: '140px',
  },
  dateText: {
    fontSize: '11px',
    color: '#a6a6a6',
    marginRight: '4px',
  },
  outsideClickBox: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'none',
    zIndex: 5,
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '120px',
    alignItems: 'center',
  },
  optionButton: {
    borderRadius: '0px',
    fontSize: '12px',
    color: '#635c5c',
    fontWeight: 400,
    borderBottom: 'solid 1px #e8e8e8',
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    width: '100%',
  },
});

type Props = Pick<ThreadProps, 'isCreate' | 'issue'> &
  CommentProps['comment'] &
  Partial<Pick<CommentProps, 'index' | 'indexOfEditingComment' | 'setIndexOfEditingComment'>>;

const CommentHeader = ({
  isCreate,
  index,
  issue,
  comment,
  indexOfEditingComment,
  setIndexOfEditingComment,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();

  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();

  const { email, projectRole } = authInfo;
  const { label } = labelDetailViewInfo;
  const { setIssues, setOpenBadgeInfo, setIssuePanelState } = labelIssuesInfo;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isCanEdit] = useState(isCreate ? false : email === comment.createdBy);

  const userObject = (() => {
    if (isCreate) {
      return { name: authInfo.name, role: projectRole };
    }

    return UserUtils.getUserObjectForComment(projectInfo.userObjects, comment.createdBy);
  })();

  const isCanDelete = isCreate
    ? false
    : email === comment.createdBy ||
      UserRoleUnion.isOwner(projectRole) ||
      UserRoleUnion.isAdmin(projectRole);

  const handleClickOptions = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickOutside = () => {
    setAnchorEl(null);
  };

  const handleClickEditButton = () => {
    setIndexOfEditingComment(index);
    setAnchorEl(null);
  };

  const handleClickDeleteConfirm = async () => {
    try {
      await IssuesService.deleteComment({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        issueId: issue.id,
        commentId: comment.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);
      if (issue.issueComments.length === 1) {
        setIssuePanelState(null);
        setOpenBadgeInfo({
          issue: null,
          ref: null,
        });
      } else {
        setOpenBadgeInfo({
          issue:
            nextIssues[
              findIndex(nextIssues, (item: any) => item.threadNumber === issue.threadNumber)
            ],
          ref: null,
        });
      }
      setIndexOfEditingComment(null);
      setAnchorEl(null);
    } catch (err: any) {
      setAnchorEl(null);

      throw err;
    }
  };

  const handleClickDeleteCancel = () => {
    setAnchorEl(null);
  };

  const handleClickDeleteButton = () => {
    if (issue.issueComments.length === 1) {
      openModal({
        modalMessage: DELETE_ISSUE,
        mainButtonData: {
          text: 'DELETE',
          handleClick: handleClickDeleteConfirm,
        },
        subButtonData: {
          handleClick: handleClickDeleteCancel,
        },
      });
    } else {
      handleClickDeleteConfirm();
      setAnchorEl(null);
    }
  };

  return (
    <MUI.Box className={classes.commentHeader}>
      <MUI.Box className={classes.leftBox}>
        <MUI.Box display="flex" alignItems="center">
          <UserAvatar size={16} noShadow userInfo={userObject} />
          <TextEllipsisTooltip text={userObject.name}>
            <MUI.Typography className={userObject.role ? classes.name : classes.removedName}>
              {userObject.name}
            </MUI.Typography>
          </TextEllipsisTooltip>
          {userObject.role && <RoleChip memberRole={userObject.role} />}
        </MUI.Box>
        {!isCreate && !(index === indexOfEditingComment) && (
          <MUI.Typography className={classes.dateText}>
            {formatDistance(comment.lastUpdatedAt)}
          </MUI.Typography>
        )}
      </MUI.Box>
      <MUI.Box className={classes.rightBox}>
        {isCanDelete && issue.status === 'OPEN' && (
          <IconButton icon={ChevronDown} onClick={handleClickOptions} size="xs" />
        )}

        {!!anchorEl && (
          <MUI.Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClickOutside}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MUI.Box className={classes.optionBox}>
              {isCanEdit && (
                <MUI.Button className={classes.optionButton} onClick={handleClickEditButton}>
                  Edit comment
                </MUI.Button>
              )}
              <MUI.Button className={classes.optionButton} onClick={handleClickDeleteButton}>
                Delete comment
              </MUI.Button>
            </MUI.Box>
          </MUI.Popover>
        )}
      </MUI.Box>
    </MUI.Box>
  );
};

export default CommentHeader;
