import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { StateGetterSetter } from '../../../contexts/types';
import { getScopeFromRoute } from '../utils/routeUtils';

export type ColorBy = 'cluster' | 'class' | 'none';
export type ContextProps = StateGetterSetter<['colorByMode', 'setColorByMode'], ColorBy> & {
  colorModes: Readonly<ColorBy[]>;
};

const Context = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const history = useHistory();
  const scope = getScopeFromRoute(history);

  const [colorByMode, setColorByMode] = useState<ColorBy>(scope === 'object' ? 'class' : 'cluster');
  const colorModes = ['cluster', ...(scope === 'object' ? ['class'] : []), 'none'] as ColorBy[];

  useEffect(() => {
    if (scope === 'object') {
      setColorByMode('class');
      return;
    }
    setColorByMode('cluster');
  }, [scope]);

  return {
    colorByMode,
    setColorByMode,
    colorModes,
  };
};

export const useColorModeContext = (): ContextProps => {
  return useContext(Context);
};

export const ColorModeProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
