import { Settings } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../../menu';
import SettingsLayout from './Layout';

export const SettingsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetSettings,
  path: 'settings',
  icon: <Icon icon={Settings} />,
  title: ({ t }) => t('curate.dataset.menus.settings'),
  component: SettingsLayout,
});
