import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ToggleButton, Typography } from '@superb-ai/norwegian-forest';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';

const useStyles = makeStyles(() => ({
  box: { width: '100%', marginTop: '40px', paddingBottom: '20px' },
  controllerBox: {
    padding: '0px 15px 0px 15px',
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    background: 'rgba(220, 220, 220, 0.16)',
    marginBottom: '18px',
  },
  controllerText: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#202020',
  },
  helpBox: {
    padding: '0px 0px 0px 15px',
  },
  helpTitleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  helpTitleText: {
    fontWeight: 500,
    fontSize: '13px',
    color: '#ff9772',
  },
  helpIcon: {
    color: '#ff9772',
    width: '14px',
    height: '14px',
    padding: '4px',
    cursor: 'pointer',
  },
  helpContextBox: {},
  helpContentText: {
    fontSize: '12px',
    fontWeight: 300,
    color: '#606060',
  },
}));

const AdvancedQA = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { allowAdvancedQa, setAllowAdvancedQa } = newProjectInfo;
  const history = useHistory();

  // On edit page, allow enabling QA but not disabling
  const projectInfo = useProjectInfo();
  const savedAllowAdvancedQa = projectInfo?.project?.settings?.allowAdvancedQa || false;
  const isEditPage = history.location.pathname.split('/').includes('edit_project');

  const handleChangeSwitch = () => {
    setAllowAdvancedQa(!allowAdvancedQa);
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box mb="10px" className={classes.controllerText}>
        {t('projectSettings.configuration.advancedQA')} (BETA)
      </MUI.Box>
      <MUI.Box mb="10px" className={classes.controllerBox}>
        <Typography variant="body2">{t('projectSettings.configuration.useAdvancedQA')}</Typography>
        <MUI.Box display="flex" alignItems="center">
          <ToggleButton
            disabled={isEditPage && savedAllowAdvancedQa}
            value={allowAdvancedQa}
            onClick={handleChangeSwitch}
          />
        </MUI.Box>
      </MUI.Box>
      <MUI.Typography>{t('projectSettings.configuration.advancedQAExplanation')}</MUI.Typography>
    </MUI.Box>
  );
};

export default AdvancedQA;
