import React from 'react';

import { css } from '@emotion/react';
import { Box } from '@superb-ai/norwegian-forest';

import UserAvatar from './UserAvatar';

interface Props {
  userInfos: {
    avatarUrl?: string | null;
    name?: string | null;
    email?: string | null;
    status?: string;
  }[];
  size: number;
  iconSize?: number;
  margin?: string | number;
  noShadow?: boolean;
  color?: string;
}

const StackedUserAvatar: React.FC<Props> = ({ userInfos, size, noShadow, color }) => {
  return (
    <Box
      display="flex"
      sx={css`
        .user-avatar:not(:first-child) {
          margin-left: ${-(size / 3)}px;
        }
      `}
    >
      {userInfos.map(user => (
        <UserAvatar
          key={user.email}
          userInfo={user}
          size={size}
          noShadow={noShadow}
          color={color}
          margin={0}
        />
      ))}
    </Box>
  );
};

export default StackedUserAvatar;
