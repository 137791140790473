import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Icon, IconButton, Popper } from '@superb-ai/norwegian-forest';
import { capitalize } from 'lodash';

import ProjectUtils from '../../../utils/ProjectUtils';
import TextEllipsisTooltip from '../TextEllipsisTooltip';
import { convertAnnotationTypeName } from './utils';

const useStyles = makeStyles(theme => ({
  badge: (props: { color: string }) => ({
    width: '10px',
    minWidth: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: theme.spacing(1),
    backgroundColor: props.color,
  }),
  iconArrow: {
    cursor: 'pointer',
  },
  text: {},
  keypointBox: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    padding: '10px',
  },
  keypointRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'solid 1px #ddd',
    fontVariant: 'tabular-nums',
  },
  keypointIndex: {
    minWidth: '24px',
    fontSize: '12px',
  },
  keypointName: {
    fontSize: '12px',
  },
}));

const getUseAutoLabel = (isSiesta: boolean, objectClass: any) => {
  if (isSiesta) {
    if (objectClass?.aiClassMap?.length && objectClass.aiClassMap[0].engineId) return true;
  } else if (objectClass?.info?.aiClassMap?.length && objectClass?.info?.aiClassMap[0].engineId)
    return true;
  return false;
};

interface Props {
  isSiesta: boolean;
  objectClass: any;
  keypointInterface: any;
  labelInterface: any;
  fontSize?: number;
  color?: string;
}

const ObjectClassItem: React.FC<Props> = props => {
  const {
    isSiesta,
    objectClass,
    keypointInterface,
    labelInterface,
    fontSize = 12,
    color = '#212121',
  } = props;

  const shape = convertAnnotationTypeName(
    isSiesta ? objectClass.annotationType : ProjectUtils.getShape(objectClass),
  );

  const [useAutoLabel] = useState(getUseAutoLabel(isSiesta, objectClass));
  const classes = useStyles({
    color: isSiesta
      ? objectClass.color
      : objectClass?.info?.shapes[shape]?.defaultValue?.color || '#ff625a',
  });
  const [isKeypointsDisplay, setIsKeypointDisplay] = useState(false);

  const handleClickKeypointArrow = () => {
    setIsKeypointDisplay(!isKeypointsDisplay);
  };

  const getKeypoint = () => {
    const nodes =
      (keypointInterface && keypointInterface[0].nodes) ||
      (labelInterface?.objectTracking?.keypoints &&
        labelInterface.objectTracking.keypoints[0].points) ||
      (labelInterface?.objectDetection?.keypoints &&
        labelInterface.objectDetection.keypoints[0].points);

    if (!nodes) return null;

    const button = (
      <Box display="flex" ml={0.5}>
        <IconButton
          round
          size="xs"
          color="textDefault"
          onClick={handleClickKeypointArrow}
          icon={isKeypointsDisplay ? 'caretUp' : 'caretDown'}
        />
      </Box>
    );

    return (
      <Popper open={isKeypointsDisplay} anchorEl={button}>
        <Box className={classes.keypointBox} shadow={1}>
          {nodes.map((node: any, index: number) => (
            <MUI.Box className={classes.keypointRow} key={`keypoint-${node.name}`}>
              <MUI.Typography className={classes.keypointIndex} style={{ color }}>
                {index + 1}
              </MUI.Typography>
              <MUI.Typography className={classes.keypointName} style={{ color }}>
                {node.name}
              </MUI.Typography>
            </MUI.Box>
          ))}
        </Box>
      </Popper>
    );
  };

  return (
    <>
      <MUI.Box display="flex" alignItems="center" padding="2px" paddingLeft="6px">
        <MUI.Box className={classes.badge} />
        <TextEllipsisTooltip text={capitalize(objectClass.name)}>
          <MUI.Typography style={{ fontSize, color, fontVariant: 'tabular-nums' }}>
            {objectClass.name}
          </MUI.Typography>
        </TextEllipsisTooltip>
        <MUI.Typography style={{ fontSize, color }}>
          &nbsp;&nbsp;|&nbsp;&nbsp;{capitalize(shape)}
        </MUI.Typography>
        {useAutoLabel && (
          <Box display="flex" ml={0.5}>
            <Icon name="autoLabel" color="secondary" size="12px" />
          </Box>
        )}
        {shape === 'keypoint' && getKeypoint()}
      </MUI.Box>
    </>
  );
};

export default ObjectClassItem;
