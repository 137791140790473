import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { RoleChip as Chip } from '@superb-ai/norwegian-forest';

import { getRoleLabel } from '../../union/UserRoleUnion';

interface Props {
  memberRole?: string;
  initial?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
}

const RoleChip: React.FC<Props> = ({ memberRole, initial = false, ..._chipProps }) => {
  const { t } = useTranslation();
  const { children, ...chipProps } = _chipProps;
  const formattedRole = (() => {
    if (!memberRole) return undefined;
    return memberRole.toLowerCase() === 'worker' ? 'labeler' : memberRole.toLowerCase();
  })();

  const role = useMemo(() => {
    if (!formattedRole) {
      return undefined;
    }
    return getRoleLabel(t, formattedRole);
  }, [formattedRole, t]);

  if (initial) {
    return (
      <MUI.Tooltip title={formattedRole || ''}>
        <Chip initial {...chipProps} color={formattedRole as any}>
          {role}
        </Chip>
      </MUI.Tooltip>
    );
  }
  return (
    <Chip {...chipProps} color={formattedRole as any}>
      {role}
    </Chip>
  );
};

export default RoleChip;
