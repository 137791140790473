import React from 'react';

import Layout from './Layout';

// eslint-disable-next-line
const Index = (props: any): React.ReactElement => {
  return <Layout {...props} />;
};

export default Index;
