import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EditFilter, Grid2X2Big, Grid3X3 } from '@superb-ai/icons';
import { Box, Checkbox, Icon, IconButton, Label, LoadingIndicator, Range } from '@superb-ai/ui';

import InfiniteWindowedGridContainer from '../../../../../../../../components/elements/windowedImageGrid/InfiniteWindowedGridContainer';
import { VerticalDivider } from '../../../../../../../../components/pages/analytics/exportsAnalytics/components/VerticalDivider';
import { useDebounce } from '../../../../../../../../hooks/DebounceHook';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useDiagnosisCommonFilterContext } from '../../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';
import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import SelectItemsDropdown from '../../../../../../elements/SelectItemsDropdown';
import { useModelDiagnosisEvaluationValuesQuery } from '../../../../../../queries/diagnosisEvaluationValueQueries';
import SliceActionButton from '../../../actionButton/SliceActionButton';
import FilterLayout from '../filter/Layout';
import { SortBy, SortOrder } from '../types';
import { ViewsPanel } from '../ViewsPanel';
import DetailModal from './DetailModal';
import ImageCell from './ImageCell';
import SortDropdown from './SortDropdown';

export default function GridLayout() {
  const { t } = useTranslation();
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const { selectedDiagnosis } = useDiagnosisModelContext();
  const {
    selectedData,
    setSelectedData,
    deselectedData,
    setDeselectedData,
    selectedAllData,
    setSelectedAllData,
  } = useActionContext();
  const { isFilterOpen, setIsFilterOpen, filterBody } = useDiagnosisGridFilterContext();
  const { splitIn, sliceId } = useDiagnosisCommonFilterContext();
  const [viewAsImage, setViewAsImage] = useState(false);
  const minColumns = 3;
  const maxColumns = 6;
  const defaultColumns = 5;
  const [columnsCount, setColumnsCount] = useState(defaultColumns);
  const [openDetailIndex, setOpenDetailIndex] = useState<number>();
  const infoAreaHeight = columnsCount < 5 ? 44 : 60;
  const [sortBy, setSortBy] = useState<SortBy>('iou');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const debouncedFilterBody = useDebounce(filterBody, 300);

  const queryResult = useModelDiagnosisEvaluationValuesQuery({
    datasetId,
    diagnosisId,
    filter: debouncedFilterBody,
    modelSource: selectedDiagnosis?.modelSource,
    sortBy,
    sortOrder,
    splitIn,
    sliceId,
  });

  const totalCount = queryResult.data?.pages[0]?.count || 0;
  const loadedItems = queryResult.data?.pages.flatMap(p => p.results) ?? [];

  const handleClickFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <Box display="flex" width="100%">
      <Box style={{ flex: 1, minWidth: 0 }}>
        <Box pl={4} pr={isFilterOpen ? 2 : 4} py={2}>
          <ViewsPanel>
            <Box
              width="100%"
              display="flex"
              gap={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" ml={1} gap={1} alignItems="center">
                <VerticalDivider style={{ width: '1px', height: '12px' }} />
                <Box style={{ maxWidth: '120px' }}>
                  <Range
                    min={minColumns}
                    max={maxColumns}
                    value={columnsCount}
                    maxSymbol={<Icon icon={Grid3X3} />}
                    minSymbol={<Icon icon={Grid2X2Big} />}
                    onChange={e => {
                      setColumnsCount(Number(e.target?.value || defaultColumns));
                    }}
                  />
                </Box>
                <VerticalDivider style={{ width: '1px', height: '12px' }} />
                <Label>
                  <Checkbox
                    value={viewAsImage}
                    onClick={() => {
                      setViewAsImage(!viewAsImage);
                    }}
                  />
                  {t('curate.diagnosis.text.viewEntireImage')}
                </Label>
              </Box>
              <Box display="flex" ml={1} gap={1} alignItems="center">
                <SelectItemsDropdown
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  deselectedData={deselectedData}
                  setDeselectedData={setDeselectedData}
                  selectedAllData={selectedAllData}
                  setSelectedAllData={setSelectedAllData}
                />
                <Box
                  mx={0.5}
                  borderRight="1px solid"
                  borderColor="gray-200"
                  style={{ height: '12px' }}
                />
                <SliceActionButton />
                <SortDropdown
                  dropdownItems={[
                    {
                      key: 'iou',
                      label: t('curate.diagnosis.sort.iou'),
                    },
                    {
                      key: 'confidence',
                      label: t('curate.diagnosis.sort.confidence'),
                    },
                  ]}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
                <IconButton
                  style={{ marginLeft: 'auto' }}
                  variant="soft-fill"
                  icon={EditFilter}
                  size="m"
                  color="gray"
                  onClick={handleClickFilter}
                />
              </Box>
            </Box>
          </ViewsPanel>
        </Box>
        <Box pl={4} pr={isFilterOpen ? 2 : 4}>
          {queryResult.isLoading ? (
            <LoadingIndicator mt={5} size="l" />
          ) : (
            <InfiniteWindowedGridContainer
              loadItems={async () => {
                await queryResult.fetchNextPage();
              }}
              loadedItems={loadedItems}
              hasNextPage={Boolean(queryResult.hasNextPage)}
              CellComponent={ImageCell}
              aspectRatio={9 / 16}
              itemData={{
                columns: columnsCount,
                viewAsImage,
                setOpenDetailIndex,
                totalCount,
                infoAreaHeight,
              }}
              additionalHeight={infoAreaHeight}
            />
          )}
        </Box>
      </Box>
      <FilterLayout />

      {openDetailIndex !== undefined && (
        <DetailModal
          pageSize={20}
          totalCount={totalCount || 0}
          // queries
          loadMoreItem={async () => {
            await queryResult.fetchNextPage();
          }}
          loadedItems={loadedItems}
          openImageIndex={openDetailIndex}
          setOpenImageIndex={setOpenDetailIndex}
        />
      )}
    </Box>
  );
}
