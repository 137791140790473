import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';
import { hexToDecimal } from '../components/datasets/dataset/views/embedding/scatterView/utils/color';
import { useImageFilterContext } from './ImageFilterContext';

export type ContextProps = {
  clusterColorMap: Record<string, number>;
} & StateGetterSetter<['hoveredFilterGroup', 'setHoveredFilterGroup'], string | null> &
  StateGetterSetter<['hoveredDotGroup', 'setHoveredDotGroup'], string | null>;
const Context = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const { imageClusters, clusterLevel } = useImageFilterContext();
  const [hoveredFilterGroup, setHoveredFilterGroup] = useState<string | null>(null);
  const [hoveredDotGroup, setHoveredDotGroup] = useState<string | null>(null);

  useEffect(() => {
    setHoveredFilterGroup(null);
  }, [clusterLevel]);

  const clusterColorMap = useMemo(
    () =>
      imageClusters.reduce((acc, item) => {
        acc[item.id] = hexToDecimal(item.color);
        return acc;
      }, {} as Record<string, number>),
    [imageClusters],
  );

  return {
    clusterColorMap,
    hoveredFilterGroup,
    setHoveredFilterGroup,
    hoveredDotGroup,
    setHoveredDotGroup,
  };
};

export const useImageClusterContext = (): ContextProps => {
  return useContext(Context);
};

export const ImageClusterProvider: React.FC = ({ children }) => {
  const filterInfo = useProvider();
  return <Context.Provider value={filterInfo}>{children}</Context.Provider>;
};
