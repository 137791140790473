import React from 'react';
import ReactMarkdown from 'react-markdown';

import { css } from '@emotion/core';
import { Box, useTheme } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';

import SyntaxHighlighter from '../../SyntaxHighlighter';
import { UploadStepInfo } from './type';

const CliInstallation = (t: TFunction): UploadStepInfo => {
  const theme = useTheme();
  const codeString = '$ pip install spb-cli\n$ spb --version';

  return {
    title: t('data.upload.installation'),
    isButtonEnabled: true,
    content: (
      <>
        <Box
          sx={css`
            font-size: 12px;
            font-family: Inter;
            line-height: 16px;
            color: ${theme.nfPalette.grey[600]};
            & p {
              margin: 0;
            }
            & code {
              padding: 1px 2px;
              border-radius: 3px;
              background-color: ${theme.nfPalette.grey[60]};
              font-family: Roboto Mono;
              font-weight: 500;
            }
          `}
        >
          {/* @ts-ignore: saa-680 */}
          <ReactMarkdown source={t('data.upload.cliInstallationDescription')} />
        </Box>
        <Box
          position="relative"
          sx={css`
            & code {
              font-size: 12px;
            }
          `}
        >
          <SyntaxHighlighter>{codeString}</SyntaxHighlighter>
        </Box>
      </>
    ),
  };
};

export default CliInstallation;
