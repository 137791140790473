import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Chip, Icon, Tooltip } from '@superb-ai/ui';
import { lowerCase, snakeCase } from 'lodash';

import analyticsTracker from '../../../../../../../../../analyticsTracker';
import FileUtils from '../../../../../../../../../utils/FileUtils';
import { useDatasetContext } from '../../../../../../../contexts/DatasetContext';
import { useDiagnosisAnalyticsFilterContext } from '../../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { usePerformanceTable } from '../../../../../../../queries/diagnosisAnalyticsQueries';
import { ChartCard } from '../ChartCard';
import { formatPerformanceTableDownload } from '../download';
import PerformanceTableChartArea from '../table/PerformanceTableChartArea';
import { getDisplayClassName, scaleClassAPs, scaleOverallAPs } from '../transformer';
import { CardProps } from './ConfusionMatrixCard';

export const PerformanceTableCard = (props: CardProps) => {
  const { diagnosis } = props;
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const { datasetId } = useDatasetContext();
  const { selectedClass } = useDiagnosisAnalyticsFilterContext();
  const dependencies = {
    datasetId,
    diagnosisId: diagnosis.id,
  };
  const query = usePerformanceTable(dependencies);
  const overallAPs = scaleOverallAPs(query?.data?.metrics?.overallPerformance);
  const classPerformance = scaleClassAPs(query?.data?.metrics?.classPerformance);

  const handleDownload = () => {
    if (!query?.data) return;
    const columnNames = {
      className: snakeCase(t('curate.diagnosis.classMetrics.classNameFull')),
      ap: lowerCase(t('curate.diagnosis.classMetrics.apAbbr')) + '_@0.5:0.95',
      ap50: lowerCase(t('curate.diagnosis.classMetrics.apAbbr')) + '_@0.5',
      precision: lowerCase(t('curate.diagnosis.classMetrics.precision')),
      recall: lowerCase(t('curate.diagnosis.classMetrics.recall')),
      scoreThres: snakeCase(t('curate.diagnosis.classMetrics.scoreThreshold')),
    };
    const timestamp = new Date().toISOString();
    const downloadData = formatPerformanceTableDownload(classPerformance, 'className');
    const fileName = `${diagnosis?.modelName}_${timestamp}_model_performance_by_class`;
    FileUtils.exportToCsv(downloadData, Object.values(columnNames), fileName);
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: 'model-performance-table',
      feature: 'model-diagnosis',
    });
  };
  return (
    <ChartCard
      style={{ height: '450px' }}
      handleDownload={handleDownload}
      isLoading={query?.isLoading ?? true}
      chartTitle={t('curate.diagnosis.classMetrics.title')}
      chartComponent={<PerformanceTableChartArea overallAPs={overallAPs} data={classPerformance} />}
      headerLeftArea={
        <Box display="flex" gap={1} alignItems="center">
          <Tooltip content={<Trans i18nKey="curate.diagnosis.classMetrics.tableInfo" />}>
            <Icon icon={InfoCircleOutline} />
          </Tooltip>
          <Chip color="primary">{getDisplayClassName(selectedClass, t)}</Chip>
        </Box>
      }
    />
  );
};
