import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ButtonGroup,
  Card,
  IconButton,
  Typography,
  useContainerSize,
} from '@superb-ai/norwegian-forest';
import { SegmentedControl } from '@superb-ai/ui';
import { findIndex, isEmpty, isNull, map, sum, upperFirst } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import { formatDistanceShort } from '../../../../utils/date';
import FileUtils from '../../../../utils/FileUtils';
import LabelInterfaceUtils, { ObjectClass } from '../../../../utils/LabelInterfaceUtils';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import BarChartScrollable from '../charts/barChart/BarChartScrollable';
// Custom charts
import {
  getObjectClassColors,
  getSiestaObjectClassColors,
} from '../charts/donutChart/donutChartColors';
import DonutChartWithLegend from '../charts/donutChart/DonutChartWithLegend';
import { routeToLabelsWithObject } from '../charts/donutChart/utils';
import { DIVERGENT_COLOR_PALETTE } from '../config/colors';
import { EMPTY_MESSAGE } from '../config/constants';
import { SVG_PROPERTY_DONUT, SVG_PROPERTY_SCROLL_BAR } from '../config/svgConfig';
import {
  AnalyticsSummaryType,
  BarChartNameChoice,
  FileFormat,
  SortOption,
} from '../dataTypes/analyticsDataTypes';
import { BarChartIconButton } from '../elements/buttons/BarChartIconButton';
import { DonutChartIconButton } from '../elements/buttons/DonutChartIconButton';
import SyncButton from '../elements/buttons/SyncButton';
import EmptyPlot from '../elements/EmptyPlot';
import { ObjectCountResult } from '../interfaces/apiResponse';
import DownloadDropDown from '../tools/DownloadDropDown';
import { sortData } from '../tools/helper';
import SortDropDown from '../tools/SortDropDown';
import { chartMockData } from '../userStats/mock';
import { JsonObj } from '../userStats/types';
import {
  addAnnotationType,
  displayDistanceTime,
  downloadFileName,
  getClassAnnotationType,
  getClassIdToName,
} from './helper';
import { getDefaultSelectedClass, getPropertyTree } from './objectPropertyChart/helper';
import { ObjectProperty, PropertyCount } from './objectPropertyChart/interface';
import PropertyDonutChartWithLegend from './objectPropertyChart/PropertyDonutChartwithLegend';
import PropertyScrollBarChartWithLegend from './objectPropertyChart/PropertyScrollBarChartWithLegend';
import { ShowPropertiesButton } from './objectPropertyChart/ShowPropertiesButton';

interface Props {
  chartInfo: JsonObj; // chart display name matching chartName in plotConfig
  isLoading: boolean;
  objectSettings: ObjectClass[];
  labelCount: number;
  sourceData: ObjectCountResult[];
  filter?: Record<string, string[] | []>; // REFACTOR: move this up component
  height: number;
  syncTime: Date | string;
  refresh: (summaryType: AnalyticsSummaryType) => void;
  getPropertyCount: (classId: string) => Promise<ObjectProperty[]>;
  showViewMode?: boolean;
  onSetViewMode?(viewMode: ViewMode): void;
  countField?: 'annotationCount' | 'count';
}

export type ViewMode = 'label' | 'frame';
const DefaultViewMode = 'label';

/**
 * Project Analytics > Object Counts Chart ('objectClassStats' in plotConfig)
 */
const ObjectCountChart = (props: Props): React.ReactElement => {
  const chartName = 'objectClassStats';
  const projectInfo = useProjectInfo();
  const {
    sourceData,
    objectSettings,
    chartInfo,
    isLoading,
    labelCount,
    filter = {},
    height,
    syncTime,
    refresh,
    getPropertyCount,
    onSetViewMode,
    showViewMode = false,
    countField = 'count',
  } = props;
  const {
    project: { labelInterface, workapp },
  } = projectInfo;
  const projectName = projectInfo.project.name;
  const accountId = projectInfo.project.accountId;
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);
  const propertyIds = objectSettings.flatMap(d => d?.properties).map(d => d?.id);
  const getPropertyColor = (propertyId: string): string[] => {
    const propIndex = propertyIds.findIndex(id => id === propertyId);
    return DIVERGENT_COLOR_PALETTE[propIndex % DIVERGENT_COLOR_PALETTE.length];
  };

  const {
    xVariable: [xKey],
    sortX: [sortXKey, sortXName, sortXDirection],
    sortY: [sortYKey, sortYName, sortYDirection],
    xFilterKey,
    tooltipKeyMap,
    chartKind,
    chartDefault,
    buttonSize,
    excelSheetName,
  } = chartInfo;

  const objectClasses = LabelInterfaceUtils.getObjectClasses(labelInterface);
  const objectAnnotationTypes = getClassAnnotationType(objectClasses);
  const hasProperties = LabelInterfaceUtils.hasObjectProperties(labelInterface);
  const [showProperties, setShowProperties] = useState<boolean>(true);
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>(0);
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [selectedClassProperties, setSelectedClassProperties] = useState<ObjectProperty[]>([]);
  const [chartType, setChartType] = useState(chartDefault);
  const [sortX, setSortX] = useState({
    key: sortXKey,
    name: sortXName,
    direction: sortXDirection,
    display: false,
  });
  const [sortY, setSortY] = useState({
    key: sortYKey,
    name: sortYName,
    direction: sortYDirection,
    display: true,
  });
  const [viewMode, setViewMode] = useState<ViewMode>(DefaultViewMode);
  const viewModeOptions = [
    { label: t('analytics.objectCount.byLabel'), value: 'label' as const },
    { label: t('analytics.objectCount.byFrame'), value: 'frame' as const },
  ];

  useEffect(() => {
    onSetViewMode?.(viewMode);
  }, [onSetViewMode, viewMode, objectSettings]);

  const [formattedData, setFormattedData] = useState<ObjectCountResult[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [syncTimeDistance, setSyncTimeDistance] = useState<string>(formatDistanceShort(syncTime));

  const getAndSetAsyncPropertyCounts = async (classId: string) => {
    const data = await getPropertyCount(classId);
    setSelectedClassIndex(findIndex(formattedData, d => d?.classId === classId));
    setSelectedClassId(classId);
    setSelectedClassProperties(data);
  };

  const renderHighestCountClassProperties = () => {
    const defaultClassId = getDefaultSelectedClass(sourceData, objectSettings) as string;
    setSelectedClassId(defaultClassId);
    getAndSetAsyncPropertyCounts(defaultClassId);
  };

  useEffect(() => {
    if (!isEmpty(sourceData)) {
      setFormattedData(
        addAnnotationType(sortData(sourceData, sortY, sortX, chartName), objectAnnotationTypes),
      );
    }
    setSyncTimeDistance(formatDistanceShort(syncTime));
    if (getPropertyTree(objectSettings)) {
      renderHighestCountClassProperties();
    }
    // We don't want to re-render on every sort, only for initial load.
  }, [objectSettings, sourceData, syncTime]);

  useEffect(() => {
    setIsLoadingData(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsLoadingData(isLoading);
    if (getPropertyTree(objectSettings)) renderHighestCountClassProperties();
    const timeout = setInterval(
      () => {
        setSyncTimeDistance(formatDistanceShort(syncTime));
      },
      60000,
      10,
    );
    return () => clearInterval(timeout);
  }, []);

  // supp (supplementary variable) ->  object share (percent total count)
  const filterData = (data: JsonObj, countField: string): JsonObj => {
    const formatted = map(data, d => {
      return {
        ...d,
        count: d[countField],
        percentTotal: d[countField === 'count' ? 'objectPercentTotal' : 'annotationPercentTotal'],
      };
    });

    return {
      data: formatted,
      x: map(formatted, d => d[xFilterKey]),
      xDisplay: formatted.reduce((acc, d) => {
        acc[d[xFilterKey]] = `${d[xKey]} | ${upperFirst(d.annotationType)}`;
        return acc;
      }, {}),
      y: map(formatted, d => d?.count),
      supp: map(formatted, d => d?.percentTotal),
    };
  };

  const handleSortChange = (sortAxis: 'x' | 'y') => {
    if (sortAxis === 'x') {
      const reverseSortX = {
        ...sortX,
        direction: sortX.direction === 'asc' ? 'desc' : 'asc',
        display: true,
      } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortX, sortY, chartName));
      setSortX(reverseSortX);
      setSortY({ ...sortY, display: false });
    } else if (sortAxis === 'y') {
      const reverseDir = sortY.direction === 'asc' ? 'desc' : 'asc';
      const reverseSortY = { ...sortY, direction: reverseDir, display: true } as SortOption;
      setFormattedData(sortData(formattedData, reverseSortY, sortX, chartName));
      setSortY(reverseSortY);
      setSortX({ ...sortX, display: false });
    }
  };

  const handleClickDatum = (datum: JsonObj) => {
    routeToLabelsWithObject({
      datum,
      routeInfo,
      filter,
    });
  };

  /**
   * Download - Supports 'xlsx'
   */
  const handleDownload = (outputType: FileFormat) => {
    const transformObjectCounts = (data: JsonObj[]): JsonObj[] => {
      return map(data, d => {
        return {
          class_name: d.name,
          annotation_type: d?.annotationType ?? '',
          object_count: d.count,
          percent_total: d.objectPercentTotal,
          ...(d?.annotationCount && {
            annotation_count: d.annotationCount,
            annotation_percent_total: d.annotationPercentTotal,
          }),
        };
      });
    };
    const transformObjectPropertyCounts = (data: JsonObj[], properties: ObjectProperty[]) => {
      const classIdToName = getClassIdToName(data);
      const optionCounts = properties.reduce(
        (acc: PropertyCount[], item: { options: PropertyCount[] }) => [...acc, ...item?.options],
        [],
      );

      return optionCounts.map(
        (d: {
          classId?: string;
          propertyName?: string;
          optionName?: string;
          count: number;
          percent?: number | string;
        }) => {
          return {
            class: classIdToName[d?.classId as string],
            property: d?.propertyName,
            property_option: d?.optionName,
            count: d?.count,
            percent: d?.percent,
          };
        },
      );
    };

    AnalyticsTracker.chartDownloaded({
      accountId: accountId,
      chartName: t(chartInfo.title),
      chartType: chartType,
      fileExtension: outputType,
      feature: 'label-analytics',
    });

    const fileName = downloadFileName({ projectName, chart: t(chartInfo.title), filter });
    const transformedData = transformObjectCounts(formattedData);
    if (outputType === 'csv') {
      FileUtils.exportToCsv(
        transformedData,
        transformedData[0] ? Object.keys(transformedData[0]) : [],
        fileName,
        // topHeader?: string,
      );
      return;
    }

    const sheets = [{ data: transformedData, sheetName: excelSheetName }];
    if (hasProperties) {
      sheets.push({
        data: transformObjectPropertyCounts(formattedData, selectedClassProperties),
        sheetName: 'property_counts',
      });
    }
    formattedData && FileUtils.downloadMultipleExcelSheets(sheets, fileName);
    return;
  };

  const getEmptyMessage = (
    isInitDataNull: boolean,
    labelCount: number,
    chartName: string,
  ): string => {
    // API returned [] or null and there's 0 total labels
    if (isInitDataNull && labelCount === 0) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    }
    if (labelCount > 0 && chartName === 'objectClassStats') {
      return t(EMPTY_MESSAGE.ANNOTATE_OBJECTS);
    }
    // API returned [] or null (0 labels with status), but there's > 0 total labels.
    return t(EMPTY_MESSAGE.SUBMIT_LABELS);
  };

  const getDatumColor = (datum: JsonObj): string => {
    const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
    const colorMap = WorkappUnion.isSiesta(workapp)
      ? getSiestaObjectClassColors(labelInterface)
      : getObjectClassColors(objects);
    return colorMap[datum.classId];
  };

  const handleShowProperties = (newState: boolean) => {
    setShowProperties(newState);
  };

  const handleSelectClass = (classId: string) => {
    getAndSetAsyncPropertyCounts(classId);
  };

  /**
   * Given chartType, renders the selected chart Component.
   */
  const makeChart = (
    formattedData: JsonObj,
    chartType: 'donut' | 'bar',
    showProperties: boolean,
  ): React.ReactElement => {
    if (chartType === 'donut') {
      const svgInfo = SVG_PROPERTY_DONUT({ width, height: height - 70, marginRight: 20 });
      if (hasProperties && !showProperties) {
        return (
          <PropertyDonutChartWithLegend
            data={formattedData.data}
            xValues={formattedData.x}
            yValues={formattedData.y}
            selectedClassProperties={selectedClassProperties || []}
            handleSelectClass={handleSelectClass}
            selectedClassIndex={selectedClassIndex}
            selectedClassId={selectedClassId}
            totalCounts={sum(formattedData.y)}
            chartName={chartName}
            svgInfo={svgInfo}
            getDatumColor={getDatumColor}
            getPropertyCount={getPropertyCount}
            filter={filter}
          />
        );
      }
      return (
        <DonutChartWithLegend
          data={formattedData.data}
          xValues={formattedData.x}
          yValues={formattedData.y}
          totalCounts={sum(formattedData.y)}
          chartName={chartName}
          svgInfo={svgInfo}
          getDatumColor={getDatumColor}
          handleClickDatum={handleClickDatum}
        />
      );
    }
    const isScrollable = formattedData.data.length > 30;
    let svgInfo;
    if (hasProperties && !showProperties) {
      svgInfo = SVG_PROPERTY_SCROLL_BAR(
        {
          marginLeft: 40,
          width: 380,
          height: height - 120,
        },
        formattedData?.x?.length,
      );
      return (
        <Box justifyContent="center">
          <PropertyScrollBarChartWithLegend
            data={formattedData.data}
            xValues={formattedData.x}
            yValues={formattedData.y}
            sValues={formattedData.supp}
            handleSelectClass={handleSelectClass}
            selectedClassIndex={selectedClassIndex}
            selectedClassId={selectedClassId}
            selectedClassProperties={selectedClassProperties}
            getDatumColor={getDatumColor}
            getPropertyColor={getPropertyColor}
            // totalCounts={_.sum(formattedData.y)}
            tooltipKeyMap={tooltipKeyMap}
            chartName={chartName as BarChartNameChoice}
            filter={filter}
            svgInfo={svgInfo}
          />
        </Box>
      );
    } else {
      svgInfo = SVG_PROPERTY_SCROLL_BAR(
        {
          marginLeft: 50,
          width,
          height: height - 90,
        },
        formattedData?.x?.length,
      );
      return (
        <Box justifyContent="center">
          <BarChartScrollable
            xValues={formattedData.x}
            yValues={formattedData.y}
            sValues={formattedData.supp}
            totalCounts={sum(formattedData.y)}
            tooltipKeyMap={tooltipKeyMap}
            xKeyToDisplayName={formattedData.xDisplay}
            chartName={chartName as BarChartNameChoice}
            filter={filter}
            svgInfo={svgInfo}
            isScrollable={isScrollable}
            hasTooltip
            hoveredIndex={0}
          />
        </Box>
      );
    }
  };

  const handleSelectChart = (newChartType: string) => {
    setChartType(newChartType);
  };

  const hasZeroLabels = labelCount === 0 || formattedData.length === 0;

  /**
   * UI: [SortDropdown] [DonutIcon] [BarIcon]
   */
  const getRightButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <ButtonGroup gap={1}>
        {showViewMode && (
          <SegmentedControl
            options={viewModeOptions}
            value={viewMode}
            onChangeValue={setViewMode}
            buttonProps={{
              size: buttonSize.sort,
              style: { height: 28 } /* Temporary until other buttons are updated */,
            }}
          />
        )}
        {showProperties && (
          <SortDropDown
            size={buttonSize.sort}
            sortX={sortX}
            sortY={sortY}
            handleSortChange={handleSortChange}
            isDisabled={hasZeroLabels}
          />
        )}
        <DonutChartIconButton
          size={buttonSize.donut}
          selected={chartType === 'donut'}
          disabled={hasZeroLabels}
          handleClick={handleSelectChart}
          t={t}
        />
        <BarChartIconButton
          size={buttonSize.bar}
          selected={chartType === 'bar'}
          disabled={hasZeroLabels}
          handleClick={handleSelectChart}
          t={t}
        />
        <DownloadDropDown
          key={`${chartName}-${isLoading}`}
          ml={0}
          iconButtonEl={
            <IconButton
              size="s"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={formattedData.length === 0}
            />
          }
          handleDownload={handleDownload}
          options={hasProperties ? ['xlsx'] : ['xlsx', 'csv']}
          isToggleDisabled={formattedData.length === 0}
        />
      </ButtonGroup>
    );
  };

  const refreshData = async () => {
    setIsLoadingData(true);
    await refresh('object-summaries');
    setIsLoadingData(false);
  };

  const getRefreshButton = () => {
    return <SyncButton isLoading={isLoadingData} refreshFn={refreshData} />;
  };

  return (
    <Card pt={2} mb={1.5} ref={chartContainerRef}>
      <Box display="flex" pl={2} pr={2} pb={1.5} alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="column" alignItems="left" justifyContent="start">
          <Box display="flex" alignItems="center" justifyContent="start">
            <Typography variant="headline5" themedColor="textDefault">
              <strong>{t(chartInfo.title)}</strong>
            </Typography>
            {getRefreshButton()}
          </Box>
          <Typography themedColor={['grey', 300]} variant="label">
            {displayDistanceTime(isLoading, syncTimeDistance)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box height="28px" mr="5px" display="flex">
            {getRightButtonGroup(hasZeroLabels)}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        themedBackgroundColor={['grey', 50]}
        pt={1.5}
        justifyContent="center"
        position="relative"
      >
        <Box width="5px"></Box>
        {isLoadingData ? (
          <CircularProgressBox
            key={chartName}
            boxProps={{ mt: 5, mb: 5, width: '100%', height: '180px' }}
          />
        ) : (
          <Box id={chartName} width="100%" flex-grown={3} justifyContent="center">
            {makeChart(
              filterData(hasZeroLabels ? chartMockData[chartName] : formattedData, countField),
              chartType,
              showProperties,
            )}
            {hasZeroLabels && (
              <EmptyPlot
                fullHeight={'100%'}
                chartKind={chartKind}
                message={getEmptyMessage(isNull(sourceData), labelCount, chartName)}
              />
            )}
          </Box>
        )}

        {LabelInterfaceUtils.hasObjectProperties(labelInterface) && (
          <Box width="20px" flexGrow={1} justifyContent={'flex-end'}>
            <ShowPropertiesButton
              showProperties={showProperties}
              handleShowProperties={handleShowProperties}
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ObjectCountChart;
