import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownSelect } from '@superb-ai/norwegian-forest';

import { LabelStatusCumulative } from '../dataTypes/analyticsDataTypes';

declare type Props = {
  handleFilterStatus: (value: LabelStatusCumulative[]) => void;
  options: string[]; // ['cumSubmitted', 'cumSkipped'];
  size: 's' | 'm' | 'l' | 'xl' | undefined;
};

const FilterStatusDropDown = (props: Props): JSX.Element => {
  const { handleFilterStatus, options, size } = props;
  const { t } = useTranslation();

  const defaultStatusOptions = [
    { label: t('analytics.project.cards.submitted'), value: 'cumSubmitted' },
    { label: t('labels.status.skipped'), value: 'cumSkipped' },
    { label: t('labels.status.in_progress'), value: 'cumWorking' },
  ];

  const statusOptions = defaultStatusOptions.flatMap(option =>
    options.includes(option.value) ? option.value : [],
  );
  const handleClick = (selectedStatus: string[]) => {
    handleFilterStatus(selectedStatus as LabelStatusCumulative[]);
  };

  return (
    <Box display="flex" width="232px">
      <DropdownSelect
        placeholder={t('analytics.userReports.selectLabelStatus')}
        color="lightgrey"
        value={statusOptions}
        options={defaultStatusOptions}
        variant="stroke"
        size={size}
        onChange={handleClick}
      />
    </Box>
  );
};

export default FilterStatusDropDown;
