import { TFunction } from 'react-i18next';

const convertExportArchiveSubType = (msg: string): string => {
  switch (msg) {
    case 'started':
      return 'in queue';
    case 'succeed':
      return 'complete';
    case 'failed':
      return 'failed';
    case 'canceled':
      return 'canceled';
    default:
      return msg;
  }
};

const convertExportArchiveSubTypeToI18n = (msg: string, t: TFunction): string => {
  switch (msg) {
    case 'started':
      return t('export.status.IN_QUEUE');
    case 'succeed':
      return t('labels.autoLabelRequest.complete');
    case 'failed':
      return t('autoLabel.cal.status.failed');
    case 'canceled':
      return t('autoLabel.cal.status.canceled');
    default:
      return msg;
  }
};

export default { convertExportArchiveSubType, convertExportArchiveSubTypeToI18n };
