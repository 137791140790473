import React, { useState } from 'react';

import { StateGetterSetter } from './types';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['isOpen', 'setIsOpen'], boolean> &
  StateGetterSetter<['messageObject', 'setMessageObject'], any> &
  StateGetterSetter<['buttonObject', 'setButtonObject'], any>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messageObject, setMessageObject] = useState({});
  const [buttonObject, setButtonObject] = useState({});

  return {
    isOpen,
    setIsOpen,
    messageObject,
    setMessageObject,
    buttonObject,
    setButtonObject,
  };
};

export const useModalContext = (): ContextProps => {
  return React.useContext(Context);
};

export const ModalProvider: React.FC = ({ children }) => {
  const modalInfo = useProvider();
  return <Context.Provider value={modalInfo}>{children}</Context.Provider>;
};
