import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { Box } from '@superb-ai/norwegian-forest';
import { Paragraph } from '@superb-ai/ui';

import { PieProps } from './OverallTrendPieChart';
import OverallTrendPieChartLegendRow from './OverallTrendPieChartLegendRow';
import { AnnotationChangeType } from './types/types';

interface Props {
  data: PieProps[];
  selectedIndex: number;
  getColor: (changeType: AnnotationChangeType) => string;
  highlightPiece: (i: number, status?: 'in' | 'out') => void;
}

const OverallTrendPieChartLegend = ({
  data,
  selectedIndex,
  getColor,
  highlightPiece,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box style={{ marginTop: '50px' }}>
      <Paragraph variant="m-strong" ml={0.5} mb={0.5}>
        {t('analytics.exportsAnalytics.overallTrend.legend.title')}
      </Paragraph>
      <FixedSizeList
        useIsScrolling
        width="20vw"
        height={250}
        itemSize={24}
        itemCount={data.length}
        itemData={{
          items: data,
          getColor: getColor,
          selectedIndex: selectedIndex,
          handleOnHover: highlightPiece,
        }}
      >
        {OverallTrendPieChartLegendRow}
      </FixedSizeList>
    </Box>
  );
};

export default OverallTrendPieChartLegend;
