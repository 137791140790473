import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddGroup as AddGroupIcon, Plus, PlusCircle } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

export default function AddGroup({
  handleOpen,
  boxNum,
  anchorEl,
  updateQuery,
  updateQueryGroup,
}: {
  handleOpen: (e: React.MouseEvent, id: string) => void;
  boxNum: number;
  anchorEl: string | null;
  updateQuery: (boxNum: number) => void;
  updateQueryGroup: (boxNum: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Box position="relative" style={{ maxWidth: '133px' }}>
      <Box
        display="flex"
        width="100%"
        py={1}
        px={1.5}
        cursor="pointer"
        onClick={e => handleOpen(e, boxNum.toString() + 'filter')}
      >
        <Box mr={0.5} display="flex" alignItems="center" justifyContent="center">
          <Icon icon={PlusCircle} />
        </Box>
        <Typography variant="m-strong">Add filter</Typography>
      </Box>
      {anchorEl === boxNum.toString() + 'filter' && (
        <>
          <div
            style={{
              position: 'fixed',
              left: '0',
              top: '0',
              width: '100%',
              height: '100vh',
              zIndex: '1301',
            }}
            onClick={e => handleOpen(e, boxNum.toString() + 'filter')}
          ></div>
          <div
            style={{
              position: 'absolute',
              // top: '-10px',
              // left: '10px',
              left: '0',
              top: '32px',
              width: '133px',
              height: `${boxNum < 1000 ? '72px' : '36px'}`,
              zIndex: '1302',
              background: '#ffffff',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
              borderRadius: '2px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              boxSizing: 'border-box',
            }}
          >
            <Box display="flex" width="100%">
              <Button
                variant="text"
                color="gray"
                style={{
                  width: '100%',
                  padding: '4px 12px',
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  updateQuery(boxNum);
                }}
              >
                <Icon icon={Plus} />
                <Typography variant="m-regular">{t('curate.button.addFilterRule')}</Typography>
              </Button>
            </Box>

            {boxNum < 1000 ? (
              <Box display="flex" width="100%">
                <Button
                  variant="text"
                  color="gray"
                  style={{
                    width: '100%',
                    padding: '4px 12px',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                  onClick={() => {
                    updateQueryGroup(boxNum);
                  }}
                >
                  <Icon icon={AddGroupIcon} />
                  <Typography variant="m-regular">{t('curate.button.addFilterGroup')}</Typography>
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </Box>
  );
}
