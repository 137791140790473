import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: '30px',
    left: '-7px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  text: {
    fontSize: '11px',
    color: '#9b9b9b',
    marginRight: '4px',
  },
}));

// eslint-disable-next-line
const ClassPropertyItemPerFrameToggle = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;

  const handleChangeSwitch = () => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    objectClass.properties[index].perFrame = !objectClass.properties[index].perFrame;
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.Typography className={classes.text}>
        {t('projectSettings.configuration.perFrame')}
      </MUI.Typography>
      <MUI.Switch
        size="small"
        color="primary"
        checked={classProperty.perFrame}
        onChange={handleChangeSwitch}
      />
    </MUI.Box>
  );
};

export default ClassPropertyItemPerFrameToggle;
