import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import styled from '@emotion/styled';
import { Box, Button, Dialog, Typography, useDialogState } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import analyticsTracker from '../../../../analyticsTracker';
import { Row } from '../../../../components/elements/Row';
import { MODEL_CANCELED } from '../../../../consts/SnackbarMessage';
import { useCancelModelMutation } from '../../queries/modelQueries';

export const CancelTrainingDialog = ({
  state,
  modelId,
  currentEpoch,
  totalEpochs,
}: {
  state: ReturnType<typeof useDialogState>;
  modelId: string;
  currentEpoch: number;
  totalEpochs: number;
}) => {
  const { t } = useTranslation();
  const [cancelReason, setCancelReason] = useState('');
  const { mutate: cancelModel } = useCancelModelMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouteMatch<{ accountName: string }>();

  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.cancelTrainingDialog.title')}
      </Dialog.Header>
      <Typography variant="m-regular">{t('model.cancelTrainingDialog.content')}</Typography>
      <Box>
        <Row mb={1}>
          <Typography variant="m-strong">
            {t('model.cancelTrainingDialog.canceledReason')}
          </Typography>
          <Typography variant="m-regular" style={{ marginLeft: 'auto' }}>
            {cancelReason.length} / 100
          </Typography>
        </Row>
        <Textarea
          placeholder={t('model.myModelDetail.cancelReason')}
          value={cancelReason}
          onChange={e => {
            if (e.target.value.length > 100) return;
            setCancelReason(e.target.value);
          }}
        />
      </Box>

      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          disabled={!cancelReason.length}
          onClick={() => {
            analyticsTracker.modelCancelCompleteRequested({
              accountId: params.accountName,
              currentEpoch,
              totalEpochs,
              reason: cancelReason,
              requestedAction: 'cancel',
            });
            cancelModel({ id: modelId, failReason: cancelReason });
            enqueueSnackbar(MODEL_CANCELED({ t }), {
              variant: 'success',
            });
            state.hide();
          }}
        >
          {t('shared.confirm')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  border-color: #dcdcdc;
  height: 52px;
  padding-block: 8px;
  padding-inline: 12px;
  &::placeholder {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b3b3b3;
  }
`;
