import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/norwegian-forest';
import { useQuery } from '@tanstack/react-query';
import { map } from 'lodash';

import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useApiDefaultParams } from '../../../hooks/ApiParamsHook';
import AnalyticsService from '../../../services/AnalyticsService';
import WorkappUnion from '../../../union/WorkappUnion';
import LabelInterfaceUtils, { DataType, ObjectClass } from '../../../utils/LabelInterfaceUtils';
import ProjectUtils from '../../../utils/ProjectUtils';
import CategoryCountChart from './chartContainers/CategoryCountChart';
import ObjectCountChart from './chartContainers/ObjectCountChart';
import {
  combinePropSettingWithData,
  getPropertyTree,
} from './chartContainers/objectPropertyChart/helper';
import { ObjectProperty } from './chartContainers/objectPropertyChart/interface';
import { CHART } from './config/plotConfig';
import * as categoryHelper from './dataTransformers/CategorySummaryTransformer';
import * as objectHelper from './dataTransformers/ObjectSummaryTransformer';
import { SortOption } from './dataTypes/analyticsDataTypes';
import { ObjectCountResult } from './interfaces/apiResponse';
import { BaseDatum } from './userStats/types';

const MyAnalyticsTab: React.FC = () => {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();
  const {
    project: { labelCount, labelInterface, workapp },
  } = projectInfo;
  const projectId = projectInfo.project.id;

  const dataType: DataType = labelInterface?.type ? labelInterface?.type.split('-')[0] : 'image';
  const CONTAINER_HEIGHT = 380;

  // TODO: display error message for legacy workapp => labeler analytics not supported?
  const isSiestaWorkapp = WorkappUnion.isSiesta(workapp);

  const hasObjects =
    LabelInterfaceUtils.hasObject(labelInterface) ||
    LabelInterfaceUtils.videoHasObject(labelInterface);
  const objectSettings = LabelInterfaceUtils.getObjectClasses(labelInterface) as ObjectClass[];
  const hasCategories = LabelInterfaceUtils.hasCategory(labelInterface);
  const categoryHash = LabelInterfaceUtils.getCategoryHash(labelInterface);
  const categoryPropertyIdToInfo = hasCategories
    ? LabelInterfaceUtils.getCategoryPropertyInfo(labelInterface)
    : {};

  const [objectCountData, setObjectCountData] = useState<ObjectCountResult[]>([]);
  const [categoryCountData, setCategoryCountData] = useState<BaseDatum[]>([]);
  const [countField, setCountField] = useState<'count' | 'annotationCount'>('count');
  const CHART_objectCount = CHART.objectClassStats;
  const CHART_categoryCount = CHART.imageCategoryStats;

  const apiArgs = useApiDefaultParams({
    t,
    projectId,
    origin: 'components/pages/analytics/MyAnalyticsTab.tsx',
    apiParams: { project_data_type: labelInterface?.dataType },
  });

  const onSetViewMode = useCallback(
    viewMode => setCountField(viewMode === 'frame' ? 'annotationCount' : 'count'),
    [],
  );

  const objectCountQuery = useQuery(
    ['objectCount'],
    () =>
      AnalyticsService.getAnnotationCounts({
        ...apiArgs,
        summaryType: 'object-summaries',
      }),
    { refetchInterval: 300 * 10 ** 3 }, // 5 min
  );

  const categoryCountQuery = useQuery(
    ['categoryCount'],
    () =>
      AnalyticsService.getAnnotationCounts({
        ...apiArgs,
        summaryType: 'category-summaries',
      }),
    { refetchInterval: 120 * 10 ** 3 }, // 2 min
  );

  useEffect(() => {
    if (hasObjects && objectCountQuery.data) {
      const isFrameBasedProject = ProjectUtils.isFrameBasedProject(labelInterface);
      const preprocessed = objectHelper.preprocessObjectCounts(
        objectCountQuery.data?.results || [],
        objectSettings,
        isFrameBasedProject,
      );
      setObjectCountData(preprocessed);
    }
    // IMPLEMENT: setAvgTimePerLabel(getAvgTimePerLabel(objectCountQuery.data));
  }, [objectCountQuery.data, projectId, objectSettings]);

  useEffect(() => {
    if (hasCategories && categoryCountQuery.data?.results) {
      const preprocessed = categoryHelper.preprocessCategoryCount(
        categoryCountQuery.data,
        isSiestaWorkapp,
        true,
        {
          plotConfig: CHART.imageCategoryStats,
          categoryMap: categoryHash,
        },
      );
      setCategoryCountData(preprocessed);
    }
  }, [categoryCountQuery.data, projectId]);

  function objectCountRefresh() {
    return objectCountQuery.refetch();
  }

  function categoryCountRefresh() {
    return categoryCountQuery.refetch();
  }

  const getPropertyCount = async (classId: string): Promise<ObjectProperty[]> => {
    const { t, ...withoutT } = apiArgs;
    const classPropCount = await AnalyticsService.getPropertyCount({
      ...apiArgs,
      apiParams: { ...withoutT, classId },
    });
    const data = combinePropSettingWithData({
      propertySetting: getPropertyTree(objectSettings),
      data: classPropCount,
      classId,
    })[classId];
    return data;
  };

  return (
    <>
      {isSiestaWorkapp && hasObjects && (
        <Box mb={4}>
          {objectCountData && (
            <ObjectCountChart
              chartInfo={CHART_objectCount}
              objectSettings={objectSettings}
              isLoading={objectCountQuery.isLoading}
              labelCount={labelCount}
              sourceData={objectCountData}
              getPropertyCount={getPropertyCount}
              syncTime={new Date()}
              height={CONTAINER_HEIGHT}
              onSetViewMode={onSetViewMode}
              showViewMode={ProjectUtils.isFrameBasedProject(labelInterface)}
              countField={countField}
              refresh={objectCountRefresh}
            />
          )}
        </Box>
      )}
      {isSiestaWorkapp &&
        hasCategories &&
        map(categoryCountData, property => {
          const propId = property[0]?.propertyId;
          const formatted = categoryHelper.formatSiestaCategoryData(
            property,
            categoryPropertyIdToInfo[propId],
            CHART_categoryCount.sortX as SortOption,
            CHART_categoryCount.sortY as SortOption,
          );
          return (
            <Box mb={4} key={`category-stats-${propId}`}>
              {property && (
                <CategoryCountChart
                  chartInfo={CHART_categoryCount}
                  isLoading={categoryCountQuery.isLoading}
                  labelCount={labelCount}
                  sourceData={formatted}
                  propertyName={property[0]?.propertyName || ''}
                  dataType={dataType}
                  height={CONTAINER_HEIGHT}
                  syncTime={new Date()}
                  refresh={categoryCountRefresh}
                />
              )}
            </Box>
          );
        })}
    </>
  );
};

export default MyAnalyticsTab;
