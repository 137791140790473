import React from 'react';

import {
  COLOR_CHIP_TAG_10PERCENT_OPACITY,
  COLOR_CHIP_TAG_DEFAULT,
} from '../../../../../consts/ColorChips';
// import * as d3 from 'd3';

type GradientProps = {
  gradientId: string; // linearGradient svg element ID name
  brightness: string; // decides saturation level
};

/**
 *
 * SVG element used to display gradient under the line in Line Chart,
 * and in Bars of Bar Chart. We set gradient by choosing start and end
 * colors for <stop> elements. SVG takes care of rendering the gradient
 * between two chosen colors.
 *
 */
const AreaGradient: React.FC<GradientProps> = ({ gradientId, brightness }) => {
  const { SALMON, GRAPEFRUIT } = COLOR_CHIP_TAG_DEFAULT;
  const { WHITE, VERY_LIGHT_PINK_TWO } = COLOR_CHIP_TAG_10PERCENT_OPACITY;
  const gradientOptions = {
    light: {
      start: { stopColor: SALMON, stopOpacity: '0.4' } as unknown as React.CSSProperties,
      end: { stopColor: WHITE, stopOpacity: '0.01' } as unknown as React.CSSProperties,
    },
    dark: {
      start: { stopColor: SALMON, stopOpacity: '0.9' } as unknown as React.CSSProperties,
      end: {
        stopColor: VERY_LIGHT_PINK_TWO,
        stopOpacity: '0.01',
      } as unknown as React.CSSProperties,
    },
    darkHover: {
      start: { stopColor: GRAPEFRUIT, stopOpacity: '1' } as unknown as React.CSSProperties,
      end: { stopColor: GRAPEFRUIT, stopOpacity: '0.1' } as unknown as React.CSSProperties,
    },
  };

  // Gradient style
  const gradient = gradientOptions[brightness as keyof typeof gradientOptions];

  return (
    <defs>
      <linearGradient
        id={gradientId}
        gradientUnits="userSpaceOnUse"
        x1="0%"
        x2="0%"
        y1="30%"
        y2="100%"
      >
        <stop offset="0%" style={gradient.start} />
        <stop offset="100%" style={gradient.end} />
      </linearGradient>
    </defs>
  );
};

export default AreaGradient;
