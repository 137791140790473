import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    width: '100%',
    height: '6px',
    background: 'none',
    borderRadius: '4px',
    zIndex: 10,
    '&.prev': {
      top: -3,
    },
    '&.next': {
      bottom: -3,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  },
}));

interface Props {
  dir: string;
  id: string;
}

const ObjectClassItemDropBar = (props: Props): React.ReactElement | null => {
  const newProjectInfo = useNewProjectInfo();
  const { dir, id } = props;
  const classes = useStyles();

  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.type !== 'objectClass' ||
    newProjectInfo.selectedObjectClassIds[id]
  ) {
    if (isDragEnter) setIsDragEnter(false);
    return null;
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const ids = Object.keys(newProjectInfo.selectedObjectClassIds);
    const dragInfo = { id, dir };

    helper.moveObjectClassesToGroup(
      newProjectInfo,
      ids,
      newProjectInfo.objectClassGroupMap[id].parent,
      dragInfo,
    );

    setIsDragEnter(false);
    newProjectInfo.setDragState(null);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      //   marginLeft={depth === 0 ? 0 : pl + "px"}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default ObjectClassItemDropBar;
