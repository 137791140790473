import React, { createContext, ReactNode, useContext, useState } from 'react';

import { StateGetterSetter } from './types';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['issues', 'setIssues'], any[]> &
  StateGetterSetter<['issueViewStatus', 'setIssueViewStatus'], string> &
  StateGetterSetter<['nextIssue', 'setNextIssue'], any> &
  StateGetterSetter<['issuePanelState', 'setIssuePanelState'], any> &
  StateGetterSetter<['openBadgeInfo', 'setOpenBadgeInfo'], any> &
  StateGetterSetter<['emitSelectedThreadId', 'setEmitSelectedThreadId'], any> &
  StateGetterSetter<['focusedCommentId', 'setFocusedCommentId'], any>;

export const LabelIssuesContext = createContext<ContextProps>({} as ContextProps);

export const LabelIssuesProvider = ({ children }: { children: ReactNode }) => {
  const [issues, setIssues] = useState<any[]>([]);
  const [issueViewStatus, setIssueViewStatus] = useState('OPEN');
  const [nextIssue, setNextIssue] = useState({
    info: {
      color: '#FF625A',
      target: {
        point: {
          x: 0,
          y: 0,
        },
      },
    },
    message: '',
    mentioned: [],
  });
  const [issuePanelState, setIssuePanelState] = useState<any>(null);
  const [openBadgeInfo, setOpenBadgeInfo] = useState({
    issue: null,
    ref: null,
  });

  const [emitSelectedThreadId, setEmitSelectedThreadId] = useState<any>(null);
  const [focusedCommentId, setFocusedCommentId] = useState<any>(null);

  return (
    <LabelIssuesContext.Provider
      value={{
        issues,
        setIssues,
        issueViewStatus,
        setIssueViewStatus,
        nextIssue,
        setNextIssue,
        issuePanelState,
        setIssuePanelState,
        openBadgeInfo,
        setOpenBadgeInfo,
        emitSelectedThreadId,
        setEmitSelectedThreadId,
        focusedCommentId,
        setFocusedCommentId,
      }}
    >
      {children}
    </LabelIssuesContext.Provider>
  );
};

export const useLabelIssues = () => useContext(LabelIssuesContext);
