import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouteConfig } from '../../../../../routes/types';
import { AddIntegrationRoute } from '../addIntegration/Route';
const Integration = lazy(() => import('./Integration'));
import { joinPaths } from '../../../../../routes/util';
import { TargetType } from '../types';

const IntegrationDetailRoute: RouteConfig<{ type: TargetType }> = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={Integration} />
    {/* @ts-ignore: saa-680 */}
    <Route path={joinPaths(match.path, AddIntegrationRoute.path)} component={AddIntegrationRoute} />
  </Switch>
);
IntegrationDetailRoute.path = ':type';

export { IntegrationDetailRoute };
