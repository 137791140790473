import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box as BoxIcon, Cuboid, Keypoints, Polygon, Polyline, RotatedBox } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

import { NewAnnotationType } from '../../../../utils/LabelInterfaceUtils';
import TableSortLabel from '../../../elements/TableSortLabel';
import { BottomHeader, LabelerTopHeaderCell } from './types';

const buttonStyle = {
  padding: '0px 0px 0px 0px',
  color: 'inherit',
  textDecoration: 'inherit',
  '&:link': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  '&:hover': {
    color: 'inherit',
    textDecoration: 'inherit',
  },
};

function UserStatsTableHeader(props: {
  orderBy: string;
  isDesc: boolean;
  onRequestSort: (property: string, order: 'desc' | 'asc') => void;
  bottomHeader: BottomHeader[];
  topHeader: LabelerTopHeaderCell[];
}): JSX.Element {
  const { orderBy, isDesc, onRequestSort, bottomHeader, topHeader } = props;
  const { t } = useTranslation();

  const topHeaderStyle: CSSProperties = {
    borderBottom: 'white',
    borderBottomWidth: '0.7px',
    borderBottomStyle: 'solid',
  };
  const bottomHeaderStyle: CSSProperties = {
    borderBottom: 'lightgray',
    borderBottomWidth: '0.5px',
    borderBottomStyle: 'solid',
  };

  const columnName = (name: string, columnGroup: string) => {
    const notTranslated = ['annoType', 'objectClass'];
    return notTranslated.includes(columnGroup) ? name : t(name);
  };

  const iconMap: Record<NewAnnotationType, any> = {
    box: BoxIcon,
    polygon: Polygon,
    polygons: Polygon,
    cuboid: Cuboid,
    cuboid2D: Cuboid,
    cuboid2d: Cuboid,
    keypoints: Keypoints,
    keypoint: Keypoints,
    polyline: Polyline,
    rbox: RotatedBox,
  };

  return (
    <TableHead>
      <TableRow key="user-report-table-header-top" style={topHeaderStyle}>
        {topHeader &&
          topHeader.map(group => {
            return (
              <TableCell
                key={group.displayName}
                align="center"
                style={{ backgroundColor: group.color, lineHeight: 1 }}
                colSpan={group.count}
              >
                {t(group.displayName)}
              </TableCell>
            );
          })}
      </TableRow>
      <TableRow key="user-report-table-header-bottom" style={bottomHeaderStyle}>
        {bottomHeader.map(cell => (
          <TableCell
            key={`${cell.id}-${cell.name}-cell`}
            align="left"
            style={{ backgroundColor: cell.color, lineHeight: 1 }}
          >
            <TableSortLabel
              key={`${cell.id}-${cell.name}-sortLabel`}
              property={cell.id}
              isDesc={isDesc}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="flex-end"
                gap={1}
              >
                <Typography style={buttonStyle}>{columnName(cell.name, cell.group)}</Typography>
                {cell?.group === 'objectClass' && iconMap[cell.annoType] && (
                  <Box>
                    <Icon icon={iconMap[cell.annoType]} />
                  </Box>
                )}
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default UserStatsTableHeader;
