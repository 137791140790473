import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../menu';
import { Layout } from './Layout';

export const GenerativeAIListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelGenerativeAIList,
  path: 'list',
  title: ({ t }) => t('model.generativeAi.title'),
  component: Layout,
  isVisible: false,
  children: [],
});
