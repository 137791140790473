import { translateCivetCondition2LokiCondition } from '../utils/filter/labelFilter';
import { Command, CommandStatus, CommandType } from '../utils/LabelCommandUtils';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

const getCommands: ApiCall<
  {
    params: { page?: number; projectIdIn?: string[]; createdByIn?: string[]; statusIn?: string[] };
  },
  { results: Command[]; count: number }
> = async args => {
  const { isGuest, urlInfo, params } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/commands/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCommand: ApiCall<{ id: string }, Command> = async args => {
  const { isGuest, urlInfo, id } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/commands/${id}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getCommandProgress: ApiCall<
  { id: string },
  {
    id: string;
    progress: number;
    totalCount: number;
    status: CommandStatus;
  }
> = async args => {
  const { isGuest, urlInfo, id } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/commands/${id}/progress/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const sendCommandAction: ApiCall<{ id: string; action: 'cancel' }, any> = async args => {
  const { isGuest, urlInfo, id, action } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/commands/${id}/actions/${action}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const createCommand: ApiCall<
  {
    type: CommandType;
    projectId?: string;
    params?: Params | null;
    actionInfo?: Record<string, any>;
  },
  { data: { id: string } }
> = async args => {
  const { type, projectId, params, actionInfo, isGuest, urlInfo } = args;

  const {
    page: _page,
    pageSize: _pageSize,
    searchAfter: _searchAfter,
    searchBefore: _searchBefore,
    searchLast: _searchLast,
    ...filteredParams
  } = params ?? {};

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/commands/${type.toLowerCase()}/`,
    data: {
      projectId,
      params: {
        ...(params && Object.keys(params).length
          ? {
              filter: ServiceUtils.getParamString(filteredParams),
            }
          : {}),
        ...actionInfo,
      },
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const createCommandV2: ApiCall<
  {
    type: CommandType;
    projectId?: string;
    params?: Params | null;
    actionInfo?: Record<string, any>;
  },
  { data: { id: string } }
> = async args => {
  const { type, projectId, params, actionInfo, isGuest, urlInfo } = args;
  const convertedParams = {
    ...Object.fromEntries(
      Object.entries(params ?? {}).map(([key, value]) => [
        translateCivetCondition2LokiCondition(key),
        value,
      ]),
    ),
  };

  const {
    page: _page,
    pageSize: _pageSize,
    ordering: _ordering,
    searchAfter: _searchAfter,
    searchBefore: _searchBefore,
    searchLast: _searchLast,
    ...filteredParams
  } = convertedParams ?? {};

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/commands/${type.toLowerCase()}/`,
    data: {
      projectId,
      params: {
        ...(convertedParams && Object.keys(convertedParams).length
          ? {
              filter: ServiceUtils.getParamString(filteredParams),
            }
          : {}),
        ...actionInfo,
      },
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

export default {
  getCommands,
  getCommand,
  getCommandProgress,
  sendCommandAction,
  createCommand,
  createCommandV2,
};
