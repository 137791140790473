import React, { useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';

type ContextProps = StateGetterSetter<['queryError', 'setQueryError'], string> &
  StateGetterSetter<['queryString', 'setQueryString'], string> &
  StateGetterSetter<['hiddenQueryString', 'setHiddenQueryString'], string> &
  StateGetterSetter<['hiddenFilterQueryString', 'setHiddenFilterQueryString'], string> & {
    queryStringWithHiddenFilterAndDeselection: string;
    queryStringAndHiddenFilterQueryString: string;
  };

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [queryError, setQueryError] = useState<string>('');
  const [hiddenQueryString, setHiddenQueryString] = useState<string>('');
  const [hiddenFilterQueryString, setHiddenFilterQueryString] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const queryStringAndHiddenFilterQueryString = [queryString, hiddenFilterQueryString]
    .filter(Boolean)
    .join(' AND ');
  const queryStringWithHiddenFilterAndDeselection = [
    queryString,
    hiddenQueryString,
    hiddenFilterQueryString,
  ]
    .filter(Boolean)
    .join(' AND ');

  return {
    queryError,
    setQueryError,
    queryString,
    setQueryString,
    hiddenQueryString,
    setHiddenQueryString,
    hiddenFilterQueryString,
    setHiddenFilterQueryString,
    queryStringWithHiddenFilterAndDeselection,
    queryStringAndHiddenFilterQueryString,
  };
};

export const useQueryContext = (): ContextProps => {
  return React.useContext(Context);
};

export const QueryProvider: React.FC = ({ children }) => {
  const queryInfo = useProvider();
  return <Context.Provider value={queryInfo}>{children}</Context.Provider>;
};
