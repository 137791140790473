import { Box as BoxIcon, Dot, Polygon as PolygonIcon } from '@superb-ai/icons';
import { Atoms, Box, Icon } from '@superb-ai/ui';

import { AnnotationTypeUpper } from '../../../../../utils/LabelInterfaceUtils';
import { ObjectCountsRow } from '../types/types';

type Input = Partial<Pick<ObjectCountsRow, 'color' | 'annoType' | 'className'>>;
type SupportedAnnotationTypes = Extract<AnnotationTypeUpper, 'BOX' | 'POLYGON'>;

export function ClassNameCell(row: Input, id: string): JSX.Element {
  const iconMap = { BOX: BoxIcon, POLYGON: PolygonIcon } as const;
  const annoType = row?.annoType?.toUpperCase() as SupportedAnnotationTypes;
  const color = row?.color;
  return (
    <Box display="flex" alignItems="center" ml={0.5} gap={2}>
      {annoType && (
        <Icon
          icon={iconMap[annoType] ?? Dot}
          color={(color as Atoms['color']) || 'gray-400'}
          size="12px"
        />
      )}
      {row[id as keyof Input] || ''}
    </Box>
  );
}
