import { v4 as uuidv4 } from 'uuid';

export const NEW_PROJECT_PAGE_TYPE = {
  IMAGE_CATEGORY: 'IMAGE_CATEGORY',
  OBJECT_DETECTION: 'OBJECT_DETECTION',
};

export const ANNOTATION_TYPE = {
  BOX: {
    value: 'BOX',
    label: 'Box',
  },
  CUBOID: {
    value: 'CUBOID',
    label: '3D Cuboid',
  },
  CUBOID2D: {
    value: 'CUBOID2D',
    label: '2D Cuboid',
  },
  ROTATED_BOX: {
    value: 'ROTATED_BOX',
    label: 'Rotated Box',
  },
  RBOX: {
    value: 'ROTATED_BOX',
    label: 'Rotated Box',
  },
  POLYLINE: {
    value: 'POLYLINE',
    label: 'Polyline',
  },
  POLYGON: {
    value: 'POLYGON',
    label: 'Polygon Segmentation',
  },
  KEYPOINT: {
    value: 'KEYPOINT',
    label: 'Keypoint',
  },
  IMAGE_CATEGORY: {
    value: 'IMAGE_CATEGORY',
    label: 'Image Category',
  },
};

export const PROPERTY_TYPE = {
  MULTIPLE_CHOICE: {
    value: 'MULTIPLE_CHOICE',
    label: 'projectSettings.propertyType.multipleChoice',
  },
  MULTIPLE_SELECTION: {
    value: 'MULTIPLE_SELECTION',
    label: 'projectSettings.propertyType.multipleSelection',
  },
  FREE_RESPONSE: {
    value: 'FREE_RESPONSE',
    label: 'projectSettings.propertyType.freeResponse',
  },
};

export const initialFreeResponse = {
  id: '1',
  isOnlyNumber: false,
  value: '',
};

export const initImageCategories = [
  {
    id: uuidv4(),
    type: PROPERTY_TYPE.MULTIPLE_SELECTION.value, // MULTIPLE_CHOICE, MULTIPLE_SELECTION, FREE_RESPONSE
    perFrame: false,
    required: false,
    imageCategoryMap: {
      root: {
        id: 'root',
        name: 'Untitled Image Category',
        parent: null,
        children: [],
      },
    },
    freeResponseInfo: initialFreeResponse,
  },
];

export const initialImageCategoryMap = {
  root: {
    id: 'root',
    name: 'Root',
    parent: null,
    children: [],
  },
};

export const initialObjectClassGroupMap = {
  root: {
    id: 'root',
    name: 'Root',
    parent: null,
    type: null,
    children: [],
  },
};

export const initialMultipleChoice = () => {
  return [
    { id: uuidv4(), name: 'Untitled Option', checked: false },
    { id: uuidv4(), name: 'Untitled Option (1)', checked: false },
  ];
};

export const initialMultipleSelection = () => {
  return [
    { id: uuidv4(), name: 'Untitled Option', checked: false },
    { id: uuidv4(), name: 'Untitled Option (1)', checked: false },
  ];
};

export const keypointImgUrlMap = {
  Face: 'https://asset.superb-ai.com/assets/img/keypoint_saba/facial_keypoints_template_15.png',
};
