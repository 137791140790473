import * as ShyCredit from '@superb-ai/shy-credit';
import { isEqual, partition } from 'lodash';
import { TFunction } from 'next-i18next';

import ProjectConst from '../../../../../consts/ProjectConst';
import { AuthContextProps } from '../../../../../contexts/AuthContext';
import { RouteContextProps } from '../../../../../contexts/RouteContext';
import CustomAutoLabelService from '../../../../../services/CustomAutoLabelService';
import { CustomAutoLabelType } from '../../../../../types/customAutoLabelTypes';
import LabelInterfaceUtils, {
  Category,
  LabelInterface,
  ObjectClass,
  PropertyOption,
} from '../../../../../utils/LabelInterfaceUtils';

export interface Engine {
  id: string;
  name: string;
  labelInterface: LabelInterface;
  project: { id: string; name?: string };
}

function addParents<T extends { id: any; children?: T[]; parent?: T | null }>(
  nodes: T[],
  parent: null | T = null,
): T[] {
  return nodes.map(node => {
    node.parent = parent;
    node.id = `${node.id}`;
    if (node.children?.length) {
      node.children = addParents(node.children, node);
    }
    return node;
  });
}

export function getPretrainedEngines(labelInterface: LabelInterface): Engine[] {
  const { OBJECT_DETECTION_ENGINE_INFOS, POINTCLOUDS_TRACKING_ENGINE_INFOS } = ShyCredit.Common;

  const ENGINE_INFOS =
    labelInterface.type === 'image-siesta' || labelInterface.type === 'video-siesta'
      ? OBJECT_DETECTION_ENGINE_INFOS
      : POINTCLOUDS_TRACKING_ENGINE_INFOS;

  return ENGINE_INFOS.map((info: any) => ({
    id: info.id,
    name: info.name,
    labelInterface: {
      objectDetection: {
        objectClasses: addParents(info.classes),
      },
      type: 'image-siesta',
    },
    project: {
      id: 'pretrained',
    },
  }));
}

/**
 * Partition engine list into available and unavailable engines
 */
export function getEngineLists({
  engines,
  selectedItem,
  projectId,
  objectClasses,
  categories,
}: {
  engines: Engine[];
  selectedItem: { id: string; type: 'class' | 'category' };
  projectId: string;
  objectClasses: ObjectClass[];
  categories: Category[];
}): [Engine[], Engine[]] {
  // First filter the visible engines based on item type
  // Only show engines that have at least one entry for the respective item type
  const visibleEngines = engines.filter(engine => {
    const labelInterface = engine.labelInterface;
    if (selectedItem.type === 'class') {
      const classes = LabelInterfaceUtils.getObjectClasses(labelInterface);
      return engine.project.id === 'pretrained' || classes.length > 0;
    }
    if (selectedItem.type === 'category') {
      return LabelInterfaceUtils.hasCategory(labelInterface);
    }
    return false;
  });

  // Sort the engines so that the current project appears first
  visibleEngines.sort((a, b) =>
    a.project.id === projectId ? -1 : b.project.id === projectId ? 1 : 0,
  );

  // Now check which of the engines actually match the selected class/category
  return partition(visibleEngines, engine => {
    if (selectedItem.type === 'class') {
      if (engine.project.id === 'pretrained') return true;
      // Check that at least one of the annotation types is available in the engine
      const objectClass = objectClasses.find(c => c.id === selectedItem.id);
      if (!objectClass) return false;
      const engineObjectClasses = LabelInterfaceUtils.getObjectClasses(engine.labelInterface);
      const engineAnnotationTypes = new Set(engineObjectClasses.map(cls => cls.annotationType));
      const hasAnnotationTypeOverlap = engineAnnotationTypes.has(objectClass.annotationType);
      return hasAnnotationTypeOverlap;
    }
    if (selectedItem.type === 'category') {
      // Check if any of the properties in this engine match
      const category = categories.find(c => c.id === selectedItem.id);
      if (!category) return false;
      const properties = engine.labelInterface.categorization.properties;
      return properties.some(p => {
        // Only match properties with options. This check eliminates free responses.
        if (!p.options) return false;
        return arePropertyOptionsEqual(category, p);
      });
    }
    return false;
  });
}

function getLeafNodeNames(options: PropertyOption[]) {
  const leafOptions = LabelInterfaceUtils.getLeafNodes(options);
  return new Set(leafOptions.map(o => o.name));
}

export function arePropertyOptionsEqual(a: Category, b: Category): boolean {
  if (!a?.options || !b?.options) return false;
  return isEqual(getLeafNodeNames(a.options), getLeafNodeNames(b.options));
}

export async function fetchEngineInfos({
  t,
  authInfo,
  routeInfo,
  labelInterface,
}: {
  t: TFunction;
  authInfo: AuthContextProps;
  routeInfo: RouteContextProps;
  labelInterface: LabelInterface;
}): Promise<Map<string, Engine>> {
  const pretrainedEngines = getPretrainedEngines(labelInterface);
  const pretrainedEngineIds = pretrainedEngines.map(engine => engine.id);

  const categories: Category[] = labelInterface?.categorization?.properties ?? [];
  const objectClasses: ObjectClass[] = LabelInterfaceUtils.getObjectClasses(labelInterface).filter(
    cls => ProjectConst.AUTO_LABEL_TYPES.includes(cls.annotationType),
  );

  // Filter for applied engines (excluding pretrained)
  const fetchEngineIds: string[] = [
    ...new Set([
      ...objectClasses.flatMap(cls => cls.aiClassMap?.map(ai => ai.engineId) ?? []),
      ...categories.flatMap(cat => cat.aiProperty?.engineId ?? []),
    ]),
  ].filter(id => id && !pretrainedEngineIds.includes(id));

  let page = 1;
  const pageSize = 50;
  const results: CustomAutoLabelType[] = [];
  let count = 0;
  try {
    do {
      const res = await CustomAutoLabelService.getCustomAutoLabelsInAccount({
        page,
        pageSize,
        stateIn: ['READY'],
        idIn: fetchEngineIds,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      results.push(...(res.results as CustomAutoLabelType[]));
      page += 1;
      count = res.count;
    } while (page * pageSize < count);
  } catch (err) {}

  return new Map([...results, ...pretrainedEngines].map(engine => [engine.id, engine]));
}

// get classes and categories for applied engine
export function getAppliedEnginesCount(labelInterface: LabelInterface) {
  const objectClasses: ObjectClass[] = LabelInterfaceUtils.getObjectClasses(labelInterface);
  const properties = labelInterface.categorization?.properties;

  const engineCounts: Record<string, { classes: number; properties: number }> = {};

  objectClasses?.forEach(cls => {
    cls.aiClassMap?.forEach(ai => {
      const classesCount = engineCounts[ai.engineId]?.classes;
      engineCounts[ai.engineId] = {
        ...engineCounts[ai.engineId],
        ...{ classes: (classesCount || 0) + 1 },
      };
    });
  });

  properties?.forEach(cat => {
    const engineId = cat.aiProperty?.engineId || '';
    const catsCount = engineCounts[engineId]?.properties;
    engineCounts[engineId] = { ...engineCounts[engineId], ...{ properties: (catsCount || 0) + 1 } };
  });

  return engineCounts;
}
