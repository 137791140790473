import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@mui/styles';
import { Box } from '@superb-ai/norwegian-forest';
import { findIndex, map } from 'lodash';

import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { overflowOverlayOrAuto } from '../../../../../utils/style';
import Comment from './Comment';
import { CommentProps, ThreadProps } from './types';

const useStyles = makeStyles({
  threadBody: {
    padding: '0px 16px 3px 16px',
    maxHeight: '300px',
    overflow: overflowOverlayOrAuto(),
  },
  chip: {},
});

type Props = ThreadProps & Pick<CommentProps, 'indexOfEditingComment' | 'setIndexOfEditingComment'>;

const ThreadBody = ({
  isCreate,
  issue,
  indexOfEditingComment,
  setIndexOfEditingComment,
}: Props) => {
  const threadBodyRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const labelIssuesInfo = useLabelIssues();
  const { focusedCommentId, setFocusedCommentId } = labelIssuesInfo;

  const scrollToComment = () => {
    let nextTop = 0;
    const commentIndex = findIndex(
      issue.issueComments,
      (comment: any) => comment.id === focusedCommentId,
    );
    if (!threadBodyRef.current) return;
    if (commentIndex === -1) {
      nextTop = threadBodyRef.current.scrollHeight;
    } else {
      nextTop = (threadBodyRef.current.children[commentIndex] as HTMLElement).offsetTop - 31;
    }

    if (threadBodyRef?.current?.scrollTo) {
      threadBodyRef.current.scrollTo({
        top: nextTop,
        behavior: 'smooth',
      });
    }

    if (focusedCommentId !== null) {
      setFocusedCommentId(null);
    }
  };

  useEffect(() => {
    scrollToComment();
    // eslint-disable-next-line
  }, [issue]);

  return (
    <Box ref={threadBodyRef} className={`${classes.threadBody}`}>
      {isCreate ? (
        <Comment isCreate={isCreate} issue={issue} />
      ) : (
        map(issue.issueComments, (comment, index) => (
          <Comment
            isCreate={false}
            key={comment.id}
            issue={issue}
            comment={comment}
            index={index}
            indexOfEditingComment={indexOfEditingComment}
            setIndexOfEditingComment={setIndexOfEditingComment}
            isFocused={comment.id === focusedCommentId}
          />
        ))
      )}
    </Box>
  );
};

export default ThreadBody;
