import { TFunction } from 'i18next';

import { TableHeader } from './StatsTableHeader';

export const getClassStatsHeaders = (t: TFunction): TableHeader[] => {
  const headers = [
    { title: t('curate.analytics.text.name'), width: '100px' },
    {
      title: t('curate.analytics.classStats.objects'),
      infoText: t('curate.analytics.classStats.objectsInfoText'),
    },
    {
      title: t('curate.analytics.text.share'),
      width: '56px',
      justifyContent: 'flex-end',
    },
    { title: t('curate.analytics.text.count'), width: '72px', justifyContent: 'flex-end' },
    {
      title: t('curate.analytics.classStats.images'),
      infoText: t('curate.analytics.classStats.imagesInfoText'),
    },
    { title: t('curate.analytics.text.share'), width: '56px', justifyContent: 'flex-end' },
    { title: t('curate.analytics.text.count'), width: '72px', justifyContent: 'flex-end' },
  ];
  return headers;
};

export const getClassificationHeaders = (t: TFunction): TableHeader[] => {
  const headers = [
    { title: t('curate.analytics.text.name'), width: '200px' },
    {
      title: t('curate.analytics.classStats.images'),
      infoText: 'Images containing classification',
    },
    { title: t('curate.analytics.text.share'), width: '140px', justifyContent: 'flex-end' },
    { title: t('curate.analytics.text.count'), width: '140px', justifyContent: 'flex-end' },
  ];
  return headers;
};
