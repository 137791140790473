import React, { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Check } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import { Box, Button, extendComponent, Icon, Typography } from '@superb-ai/ui';

export type Step = {
  title: string;
  content: JSX.Element | string;
  isOptional?: boolean;
  summary?: JSX.Element | string;
  isButtonEnabled?: boolean;
  startingStepIndex?: boolean;
  onClickNext?: () => void;
};

export const VerticalStepper = ({
  steps,
  lastStepButton,
  startingStepIndex = 0,
  ...props
}: {
  /** Object of steps' title and content */
  steps: Record<string, Step>;
  /** Last step button text, click event, and loading state */
  lastStepButton?: { text: string; onClick?: () => void; isLoading?: boolean };
  startingStepIndex?: number;
} & ComponentProps<typeof Box>) => {
  const { t } = useTranslation();
  const stepsEntries = Object.entries(steps) as [string, Step][];
  const totalSteps = stepsEntries.length;
  const [activeStepIndex, setActiveStepIndex] = React.useState(startingStepIndex);
  const [completedSteps, setCompletedSteps] = React.useState<Array<string>>(
    stepsEntries.filter((_, idx) => idx < startingStepIndex).map(x => x[0]),
  );

  const completedStepsIdx = completedSteps.length - 1;

  const onChangeStepIndex = (index: number, step: string) => {
    setActiveStepIndex(index + 1);
    if (completedSteps.indexOf(step) < 0) {
      setCompletedSteps(completedSteps.concat([step]));
    }
  };

  const onClickStep =
    (stepIndex: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      if (stepIndex < startingStepIndex) return;
      setActiveStepIndex(stepIndex);
    };

  const onClickPrev =
    (stepIndex: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setActiveStepIndex(stepIndex - 1);
    };

  const onClickNext =
    (stepIndex: number, step: string, onClick?: () => void) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      onClick && onClick();
      onChangeStepIndex(stepIndex, step);
    };

  const onClickLastButton = async () => {
    if (lastStepButton?.onClick) {
      await lastStepButton.onClick();
    }
    const step = Object.keys(steps)[activeStepIndex];
    onChangeStepIndex(activeStepIndex, step);
  };

  return (
    <Steps props={props}>
      {stepsEntries.map(([key, step], idx) => {
        return (
          <Step
            key={key}
            index={idx}
            activeStepIndex={activeStepIndex}
            completedStepIndex={completedStepsIdx}
            title={step.title}
            isOptional={step.isOptional}
            onClickStep={onClickStep}
            summary={step.summary}
            isPreCompleted={idx < startingStepIndex}
            isLast={idx === totalSteps - 1}
            isButtonEnabled={step.isButtonEnabled}
          >
            {step.content}
            <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
              {idx > 0 && startingStepIndex !== idx && (
                <Button
                  disabled={lastStepButton?.isLoading}
                  onClick={onClickPrev(idx)}
                  variant="text"
                >
                  {t('shared.previous')}
                </Button>
              )}
              {idx < totalSteps - 1 ? (
                <Button
                  variant="strong-fill"
                  color={'black'}
                  disabled={!step.isButtonEnabled}
                  onClick={onClickNext(idx, key, step.onClickNext)}
                >
                  {t('shared.next')}
                </Button>
              ) : (
                lastStepButton && (
                  <Button
                    variant="strong-fill"
                    color={'black'}
                    disabled={!step.isButtonEnabled || lastStepButton.isLoading}
                    onClick={onClickLastButton}
                  >
                    {lastStepButton.text}
                  </Button>
                )
              )}
            </Box>
          </Step>
        );
      })}
    </Steps>
  );
};

const Steps = ({ props, children }: { props: ComponentProps<typeof Box>; children: ReactNode }) => {
  return (
    <Box {...props} px={3}>
      {children}
    </Box>
  );
};

const Step = ({
  index,
  title,
  activeStepIndex,
  completedStepIndex,
  isOptional,
  summary,
  isPreCompleted,
  isLast,
  children,
  isButtonEnabled,
  onClickStep,
}: {
  index: number;
  title: string;
  activeStepIndex: number;
  completedStepIndex: number | undefined;
  isOptional?: boolean;
  summary?: string | JSX.Element;
  isPreCompleted?: boolean;
  isLast: boolean;
  children: ReactNode;
  isButtonEnabled: boolean | undefined;
  onClickStep: (index: number) => React.MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  const active = activeStepIndex === index;

  const disabled = (activeStepIndex === null || index > (completedStepIndex || -1) + 1) && !active;
  const completed = index <= (completedStepIndex || 0);

  return (
    <StepBox
      isLast={isLast}
      py={3}
      title={title}
      value={index + 1}
      inactive={disabled}
      isOptional={isOptional}
      completed={completed && isButtonEnabled}
      isPreCompleted={isPreCompleted}
      onClick={disabled || active ? undefined : onClickStep(index)}
    >
      {!active &&
        completed &&
        summary &&
        (typeof summary === 'string' ? (
          <Typography variant="m-regular" color="gray-300">
            {summary}
          </Typography>
        ) : (
          summary
        ))}
      <Collapse open={active}>{children}</Collapse>
    </StepBox>
  );
};

const StepBox = ({
  isLast,
  value,
  title,
  isOptional,
  inactive,
  completed,
  children,
  isPreCompleted,
  ...props
}: {
  isLast: boolean;
  // Badge's value
  value: number | string;
  // Box title
  title: string;
  // Step box inactive
  inactive?: boolean;
  // Step completed
  completed?: boolean;
  // Is optional step
  isOptional?: boolean;
  children: ReactNode;
  isPreCompleted?: boolean;
} & ComponentProps<typeof Box>) => {
  const leftMargin = 4; // size of Badge (24) plus gap (8)

  const renderBadge = () => {
    if (completed) {
      if (isPreCompleted) {
        return (
          <Badge borderColor={'gray-400'}>
            <Icon icon={Check} color={'gray-400'} />
          </Badge>
        );
      } else {
        return (
          <Badge borderColor={inactive ? 'gray-250' : 'primary-400'}>
            <Icon icon={Check} color={inactive ? 'gray-250' : 'primary-400'} />
          </Badge>
        );
      }
    } else {
      return (
        <Badge
          backgroundColor={inactive ? 'gray-250' : 'primary-400'}
          borderColor={inactive ? 'gray-250' : 'primary-400'}
        >
          <Typography color={'white'} variant="m-regular">
            {value}
          </Typography>
        </Badge>
      );
    }
  };

  return (
    <Box
      {...props}
      borderBottom={isLast ? undefined : '1px solid'}
      borderColor={'gray-150'}
      cursor={inactive ? undefined : 'pointer'}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {renderBadge()}
        <Box display="flex" alignItems="center">
          <Typography variant="m-medium">{title}</Typography>
          {isOptional && (
            <Box display="flex" alignItems="center" ml={0.5}>
              <Typography variant="m-medium" color="gray-250">
                (Optional)
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box ml={leftMargin}>{children}</Box>
    </Box>
  );
};

const Badge = extendComponent(Box, {
  style: { width: 24, height: 24, borderRadius: 12 },
  border: '1px solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
