import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  ArrowLeft,
  ArrowRight,
  Clear,
  GenerateImage,
  LoadingSpinner,
  WarningFilled,
} from '@superb-ai/icons';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Chip, Icon, IconButton, Typography } from '@superb-ai/ui';
import dynamic from 'next/dynamic';

import { PixiDetailAnnotation } from '../../../../../../../../components/pages/aiAdvancedFeatures/Shapes';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { DatasetObjects } from '../../../../../../services/DatasetObjectService';
import {
  CurateImageData,
  useCurateDatasetService,
} from '../../../../../../services/DatasetService';
import {
  AnnotationTypeCoordinate,
  Box as AnnotationBox,
  Polygon,
} from '../../../../../../types/annotationTypes';
import ObjectDetailModalCabinet from './ObjectDetailModalCabinet';

const PixiDetailImage = dynamic({
  loader: () =>
    import('../../../../../../../../components/pages/aiAdvancedFeatures/PixiDetailImage').then(
      x => x.PixiDetailImage,
    ),
  ssr: false,
});
type Props = {
  pageSize: number;
  totalCount: number;
  loadMoreItem: (startIndex: number, stopIndex?: number) => Promise<void>;
  loadedItems: DatasetObjects[];
  openImageIndex: number;
  setOpenImageIndex: Dispatch<SetStateAction<number | undefined>>;
};

export default function ObjectDetailModal({
  pageSize,
  totalCount,
  loadMoreItem,
  loadedItems,
  openImageIndex,
  setOpenImageIndex,
}: Props) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const [loading, setLoading] = useState(false);
  const [imageInfo, setImageInfo] = useState<CurateImageData>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { getImageData } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [filteredAnnotationsIds, setFilteredAnnotationsIds] = useState<string[]>([]);
  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) return {};

      return {
        [annotation.id]: true,
      };
    });
  }, []);

  const isPrevDisabled = !openImageIndex || loading;
  const isNextDisabled = openImageIndex + 1 >= totalCount || loading;

  function prev() {
    if (isPrevDisabled) return;
    setOpenImageIndex(openImageIndex - 1);
  }

  async function next() {
    if (isNextDisabled) return;
    const nextIndex = openImageIndex + 1;
    if (nextIndex > loadedItems.length - 1) {
      setLoading(true);
      await loadMoreItem(Math.ceil(nextIndex / pageSize));
      setLoading(false);
    }
    setOpenImageIndex(nextIndex);
  }

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'ArrowLeft') {
        prev();
      } else if (key === 'ArrowRight') {
        next();
      }
    };
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
    // eslint-disable-next-line
  }, [openImageIndex, loading]);

  useEffect(() => {
    if (!loadedItems) return;
    (async () => {
      setLoading(true);
      try {
        const response = await getImageData({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
          imageId: loadedItems[openImageIndex].imageId,
          expand: ['annotations'],
        });
        setImageInfo(response);
        const annotationIds = response?.annotations
          ?.map(anno => anno.id)
          .filter(id => id !== loadedItems[openImageIndex].id);

        setFilteredAnnotationsIds(annotationIds ? [...annotationIds] : []);
        setIsNotFound(false);
      } catch (e: any) {
        if (e.response?.status === 404) {
          setIsNotFound(true);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [openImageIndex]);

  const objectAnnotationId = useMemo(
    () => loadedItems[openImageIndex]?.id ?? '',
    [loadedItems, openImageIndex],
  );

  const annotations = useMemo(() => {
    if (!imageInfo) {
      return [];
    }

    return (
      imageInfo.annotations
        ?.filter(anno => !filteredAnnotationsIds.includes(anno.id))
        .map(anno => ({
          type: anno.annotation_type,
          coordinate:
            anno.annotation_type === 'polygon'
              ? (anno.annotation_value as AnnotationTypeCoordinate<Polygon>).points
              : (anno.annotation_value as AnnotationTypeCoordinate<AnnotationBox>),
          id: anno.id,
          className: anno.annotation_class,
        })) || []
    );
  }, [imageInfo, filteredAnnotationsIds]);

  const clickedAnnotation = useMemo(() => {
    const id = Object.keys(annotationSelectedMap)[0];
    const annotation = imageInfo?.annotations?.find(anno => anno.id === id);
    return annotation || null;
  }, [annotationSelectedMap, imageInfo?.annotations]);

  if (!imageInfo) return <></>;

  return (
    <Modal
      open
      close={{
        onClose: () => {
          setOpenImageIndex(undefined);
        },
        canClickOutside: true,
        canCloseWithExit: true,
      }}
      title={
        <Box width="100%" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" gap={1} style={{ flex: 1 }}>
            {imageInfo.is_synthetic && (
              <Chip color="gray" fill="strong" variant="m-regular">
                <Icon icon={GenerateImage} />
                {t('curate.datasets.detailView.synthetic')}
              </Chip>
            )}
            <Typography
              variant="h2"
              color="primary"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              style={{ maxWidth: 600 }}
            >
              {imageInfo.key}
            </Typography>
            <Box display="flex" alignItems="center" ml="auto">
              <IconButton
                color="gray"
                icon={ArrowLeft}
                variant="text"
                disabled={isPrevDisabled}
                onClick={prev}
              />
              <IconButton
                color="gray"
                icon={ArrowRight}
                variant="text"
                disabled={isNextDisabled}
                onClick={next}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" style={{ width: 288 }}>
            <IconButton
              color="gray"
              icon={Clear}
              variant="text"
              onClick={() => setOpenImageIndex(undefined)}
            />
          </Box>
        </Box>
      }
    >
      <Box
        px={4}
        display="flex"
        style={{
          marginBottom: '-12px',
          maxWidth: '1800px',
          minWidth: '1124px',
          height: '600px',
        }}
      >
        {isNotFound ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Icon icon={WarningFilled} color="gray-200" size="40px" />
            <Typography variant="m-regular">This image has been deleted.</Typography>
          </Box>
        ) : (
          <>
            <Box
              style={{ backgroundColor: '#F5F5F5', flex: 1 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              {loading ? (
                <Icon icon={LoadingSpinner} size="40px" />
              ) : (
                <PixiDetailImage
                  alt={imageInfo.id}
                  imgUrl={imageInfo.image_url}
                  annotations={annotations}
                  annotationSelectedMap={annotationSelectedMap}
                  onSelectImage={handleSelectImage}
                  onSelectShape={handleSelectShape}
                />
              )}
            </Box>
            <Box>
              <ObjectDetailModalCabinet
                imageInfo={imageInfo}
                filteredAnnotationsIds={filteredAnnotationsIds}
                setFilteredAnnotationsIds={setFilteredAnnotationsIds}
                objectAnnotationId={objectAnnotationId}
                clickedAnnotation={clickedAnnotation}
                handleSelectImage={handleSelectImage}
                closeModal={() => setOpenImageIndex(undefined)}
              />
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
