import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, map } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  select: {
    background: '#f4f4f4',
    borderRadius: '5px',
    overflow: 'hidden',

    '& .MuiSelect-selectMenu': {
      width: '120px',
      color: '#4a4a4a',
      fontSize: '11px',
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 18px 5px 14px',
    },
    '& div:first-child': {
      padding: '8px 32px 8px 16px',
    },
  },
}));

// eslint-disable-next-line
const ClassPropertyItemSelect = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;

  const handleChangeSelect = (e: any) => {
    const { value } = e.target;
    if (
      value ===
      newProjectInfo.objectClasses[newProjectInfo.selectedObjectClassIndex].properties[index].type
    )
      return;

    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    objectClass.properties[index].type = value;
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  return (
    <MUI.Select
      className={classes.select}
      size="small"
      variant="filled"
      value={classProperty.type}
      onChange={handleChangeSelect}
    >
      {map(Object.keys(classProperty.options), option => (
        <MUI.MenuItem key={option} value={option}>
          {t(`projects.createProject.${option}`)}
        </MUI.MenuItem>
      ))}
    </MUI.Select>
  );
};

export default ClassPropertyItemSelect;
