import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isBoolean } from 'lodash';

import { EvaluationFilterSchema } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import { SortBy, SortOrder } from '../components/datasets/dataset/modelDiagnosis/diagnosis/types';
import { QUERY_KEY } from '../const/QueryKey';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateDatasetService } from '../services/DatasetService';
import {
  EvaluationValueDependencies,
  EvaluationValuesDependencies,
  useDiagnosisEvaluationService,
} from '../services/DiagnosisEvaluationService';
import { ModelSource } from '../services/DiagnosisModelService';
import { Split } from '../types/evaluationTypes';

function stringifyObject(obj: any): string {
  const stringifiedValues = Object.entries(obj).flatMap(([key, values]) => {
    if (Array.isArray(values)) {
      return `(${key}::${values.join('&-&')})`;
    }
    if (values && typeof values === 'object') {
      return stringifyObject(values);
    }
    return `(${key}::${values})`;
  });
  return stringifiedValues.join('&-&');
}

export function useModelDiagnosisEvaluationValuesQuery({
  datasetId,
  fromPublicDatasets,
  diagnosisId,
  filter,
  modelSource,
  sortBy,
  sortOrder,
  splitIn,
  size = 20,
  sliceId,
}: {
  datasetId: string;
  diagnosisId: string;
  fromPublicDatasets?: boolean;
  // query?: string;
  filter?: EvaluationFilterSchema;
  searchAfter?: string[];
  expand?: string[];
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  size?: number;
  modelSource?: ModelSource;
  splitIn?: Split[];
  sliceId?: string;
}) {
  const { postGetEvaluationValues } = useDiagnosisEvaluationService();
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const filterWithThreshold = {
    ...filter,
    ...(modelSource === 'superb' && { filter_by_optimal_confidence: true }),
    ...(modelSource === 'external' && { filter_by_optimal_confidence: false }),
  };
  const stringifiedFilter = stringifyObject(filterWithThreshold);

  return useInfiniteQuery({
    queryKey: [
      'curate-diagnosis-evaluation-values',
      datasetId,
      fromPublicDatasets,
      diagnosisId,
      stringifiedFilter,
      sortBy,
      sortOrder,
      modelSource,
      splitIn,
      sliceId,
    ],
    queryFn: async ({ pageParam }) => {
      const evaluationValues = await postGetEvaluationValues({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
        searchAfter: pageParam,
        filter: filterWithThreshold,
        sortBy,
        sortOrder,
        size,
        splitIn,
        sliceId,
      });
      const imageIdList = evaluationValues.results.map(r => r.imageId);
      const imagesResult = await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        idIn: imageIdList,
        size,
        expand: ['thumbnail_url'],
      });

      const mergedResult = evaluationValues.results.map(result => {
        const image = imagesResult.results.find(image => image.id === result.imageId);
        return {
          ...result,
          imageThumbnailUrl: image?.thumbnailUrl,
          originalImageSize: { width: image?.metadata.width, height: image?.metadata.height },
        };
      });
      return { ...evaluationValues, results: mergedResult };
    },
    getNextPageParam: ({ last }) => last,
    enabled: Boolean(datasetId && diagnosisId),
    refetchOnWindowFocus: false,
  });
}
export function useModelDiagnosisEvaluationValuesCountQuery({
  datasetId,
  fromPublicDatasets,
  diagnosisId,
  filter,
  modelSource,
  splitIn,
  sliceId,
}: {
  datasetId: string;
  diagnosisId: string;
  fromPublicDatasets?: boolean;
  filter?: EvaluationFilterSchema;
  modelSource: ModelSource;
  splitIn?: Split[];
  sliceId?: string;
}) {
  const { postGetEvaluationValues } = useDiagnosisEvaluationService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const filterWithThreshold = {
    ...filter,
    ...(modelSource === 'superb' && { filter_by_optimal_confidence: true }),
    ...(modelSource === 'external' && { filter_by_optimal_confidence: false }),
  };
  const stringifiedFilter = stringifyObject(filterWithThreshold);

  return useQuery({
    queryKey: [
      'curate-diagnosis-evaluation-values-count',
      datasetId,
      fromPublicDatasets,
      diagnosisId,
      stringifiedFilter,
      modelSource,
      splitIn,
      sliceId,
    ],
    queryFn: async ({ pageParam }) => {
      const evaluationValues = await postGetEvaluationValues({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
        searchAfter: pageParam,
        filter: filterWithThreshold,
        size: 1,
        splitIn,
        sortBy: 'iou',
        sortOrder: 'desc',
        sliceId,
      });

      return evaluationValues;
    },
    enabled: Boolean(datasetId && diagnosisId),
    refetchOnWindowFocus: false,
  });
}
export function useModelDiagnosisEvaluationValueQuery({
  datasetId,
  fromPublicDatasets,
  diagnosisId,
  evaluationValueId,
}: EvaluationValueDependencies) {
  const { getEvaluationValue } = useDiagnosisEvaluationService();
  const { getImageData } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQuery({
    queryKey: ['curate-diagnosis-evaluation-value', datasetId, diagnosisId, evaluationValueId],
    queryFn: async () => {
      const evaluationValue = await getEvaluationValue({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
        evaluationValueId,
        expand: ['predictions_in_image'],
      });

      const { imageId } = evaluationValue;
      const imageInfo = await getImageData({ datasetId, fromPublicDatasets, imageId });

      return { ...evaluationValue, image: imageInfo };
    },
    enabled: Boolean(datasetId && diagnosisId && evaluationValueId),
    refetchOnWindowFocus: false,
  });
}

export function useEvaluationSummaryQuery(dependencies: EvaluationValuesDependencies) {
  const { datasetId, fromPublicDatasets, diagnosisId, filter, modelSource, splitIn, sliceId } =
    dependencies;
  const { postEvaluationSummary } = useDiagnosisEvaluationService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const filterWithThreshold = {
    ...filter,
    ...(modelSource === 'superb' && { filter_by_optimal_confidence: true }),
    ...(modelSource === 'external' && { filter_by_optimal_confidence: false }),
  };
  const stringifiedFilter = stringifyObject(filterWithThreshold);
  return useQuery({
    queryKey: [
      QUERY_KEY.evaluationSummary,
      datasetId,
      diagnosisId,
      stringifiedFilter,
      modelSource,
      splitIn,
      sliceId,
    ],
    queryFn: () => {
      return postEvaluationSummary({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        diagnosisId,
        filter: filterWithThreshold,
        splitIn,
        sliceId,
      });
    },
    enabled: Boolean(datasetId && diagnosisId),
    refetchOnWindowFocus: false,
  });
}
