import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ChevronDown } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import {
  Box,
  Checkbox,
  Icon,
  LinkTypography,
  LoadingIndicator,
  Tooltip,
  Typography,
} from '@superb-ai/ui';
import { without } from 'lodash';

import { useObjectClusterContext } from '../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { hexToRgba } from '../views/embedding/scatterView/utils/color';
import { Circle } from './components/Circle';
import { ClassCluster, ClusterLevel } from './types';
import {
  getHighlightedText,
  getIsObjectClassOrClusterDisabled,
  searchResultsWithKey,
} from './utils';

export default function ObjectClusterFilterCollapse({
  isSelectedAnnotationClass,
  handleToggle,
  clusterPerClass,
  annotationClass,
  searchInput,
  maxClusterLevel,
  selectedClusterLevel,
  isLoadingClusterPerClass,
  onChangeClusterLevel,
  hoveredFilterGroup,
  setHoveredFilterGroup,
  hoveredDotGroup,
  setHoveredDotGroup,
}: {
  handleToggle: (annotationClass: string) => void;
  isSelectedAnnotationClass: boolean;
  clusterPerClass?: (ClassCluster & { color: string; share: number })[];
  annotationClass: string;
  searchInput?: string;
  maxClusterLevel: number;
  selectedClusterLevel: ClusterLevel;
  isLoadingClusterPerClass: boolean;
  onChangeClusterLevel: (v: ClusterLevel) => void;
  hoveredFilterGroup?: string | null;
  setHoveredFilterGroup?: React.Dispatch<React.SetStateAction<string | null>>;
  hoveredDotGroup?: string | null;
  setHoveredDotGroup?: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const { t } = useTranslation();
  const {
    selectedSuperClusters,
    setSelectedSuperClusters,
    selectedClasses,
    setSelectedClasses,
    appliedFilters,
    setAppliedFilters,
  } = useObjectFilterContext();
  const { clusterLevel } = useObjectClusterContext();

  function handleClickValueCheckbox(e: MouseEvent<HTMLButtonElement>, value: ClassCluster) {
    if (!clusterLevel) return;
    setSelectedClasses(undefined);
    const oldSelectedValues =
      selectedSuperClusters?.find(cluster => cluster.annotation_class === annotationClass)
        ?.cluster_id_in || [];
    if (selectedSuperClusters && oldSelectedValues.includes(value.id)) {
      const newSelectedValues = without(oldSelectedValues, value.id);
      if (newSelectedValues.length === 0) {
        setSelectedSuperClusters(undefined);
        return;
      }
      setSelectedSuperClusters([
        {
          annotation_class: annotationClass,
          cluster_level: clusterLevel,
          cluster_id_in: newSelectedValues,
        },
      ]);
      return;
    }
    setSelectedSuperClusters([
      {
        annotation_class: annotationClass,
        cluster_level: clusterLevel,
        cluster_id_in: [...oldSelectedValues, value.id],
      },
    ]);
  }
  const handleMouseEnter = (id: string) => {
    setHoveredFilterGroup && setHoveredFilterGroup(id);
    setHoveredDotGroup && setHoveredDotGroup(null);
  };
  const handleMouseLeave = () => {
    setHoveredFilterGroup && setHoveredFilterGroup(null);
    setHoveredDotGroup && setHoveredDotGroup(null);
  };
  const clusterPerClassWithName = clusterPerClass?.map((value, index) => ({
    ...value,
    name: value.id === null ? t('curate.filter.unprocessed') : `${annotationClass} ${index + 1}`,
  }));

  const filteredClusterPerClass = clusterPerClassWithName
    ? searchResultsWithKey(clusterPerClassWithName, 'name', searchInput || '')
    : [];

  const isSelectedFromClassFilter = selectedClasses?.includes(annotationClass);

  const isCollapseDisabled = getIsObjectClassOrClusterDisabled(appliedFilters, annotationClass);
  const disabledTooltipMessage =
    (appliedFilters.annotation_class_in?.length || 0) > 0
      ? 'curate.annotationFilter.resetFilters'
      : appliedFilters.annotation_class_cluster_in?.length || 0 > 0
      ? 'curate.annotationFilter.resetClusterFilters'
      : undefined;
  const TooltipContent = (
    <Trans
      t={t}
      i18nKey={disabledTooltipMessage}
      components={{
        link1: (
          <LinkTypography
            onClick={() => {
              setAppliedFilters({
                ...appliedFilters,
                annotation_class_in: [],
                annotation_class_cluster_in: [],
              });
            }}
            variant="m-regular"
            color="white"
          />
        ),
      }}
    />
  );

  return (
    <>
      <Tooltip
        hideOnEmptyContent
        content={isCollapseDisabled && disabledTooltipMessage ? TooltipContent : undefined}
        placement="bottom-start"
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          backgroundColor={isSelectedFromClassFilter ? 'primary-200' : 'gray-100'}
          p={0.5}
          style={{ marginBottom: '2px' }}
          cursor={isCollapseDisabled ? undefined : 'pointer'}
          onClick={() => {
            !isCollapseDisabled && handleToggle(annotationClass);
          }}
        >
          <Circle />
          <Typography variant="m-regular">
            {getHighlightedText(annotationClass, searchInput || '')}
          </Typography>
          {/* <ClusterLevelDropdown maxSize={maxClusterLevel} selectedSize={selectedClusterLevel} /> */}
          <Box
            display="flex"
            alignItems="center"
            ml="auto"
            style={{
              transition: 'all 0.1s ease-in-out',
              ...(isSelectedAnnotationClass ? {} : { transform: 'rotate(-90deg)' }),
            }}
          >
            <Icon icon={ChevronDown} color={isCollapseDisabled ? 'gray-200' : 'gray-400'} />
          </Box>
        </Box>
      </Tooltip>
      <Collapse open={isSelectedAnnotationClass}>
        <Box ml={2} style={{ marginTop: '-4px' }}>
          {isLoadingClusterPerClass ? (
            <LoadingIndicator size="xs" />
          ) : (
            <>
              {/* <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="s-strong" color="gray-300">
                  {t('curate.imageFilter.numberOfClusters')}
                </Typography>
                <ClusterLevelDropdown
                  maxSize={maxClusterLevel}
                  selectedSize={selectedClusterLevel}
                  clickable
                  onChange={(v: ClusterLevel) => onChangeClusterLevel(v)}
                />
              </Box> */}
              {filteredClusterPerClass?.map(value => {
                const currentClassCuster =
                  selectedSuperClusters &&
                  selectedSuperClusters.find(
                    cluster => cluster.annotation_class === annotationClass,
                  );
                const isClusterOptionDisabled =
                  appliedFilters.annotation_class_cluster_in &&
                  appliedFilters.annotation_class_cluster_in?.length > 0 &&
                  !appliedFilters.annotation_class_cluster_in
                    .flatMap(c => c.cluster_id_in)
                    .includes(value.id);
                return (
                  <Box
                    key={value.id}
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    backgroundColor={
                      hoveredFilterGroup === value.id
                        ? 'primary-100'
                        : hoveredDotGroup
                        ? 'primary-200'
                        : 'white'
                    }
                    onMouseEnter={() => handleMouseEnter(value.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Tooltip
                      hideOnEmptyContent
                      content={
                        disabledTooltipMessage && isClusterOptionDisabled
                          ? TooltipContent
                          : undefined
                      }
                    >
                      <span>
                        <Checkbox
                          disabled={isClusterOptionDisabled}
                          value={
                            !!currentClassCuster
                              ? currentClassCuster.cluster_id_in.includes(value.id)
                              : false
                          }
                          onClick={e => handleClickValueCheckbox(e, value)}
                        />
                      </span>
                    </Tooltip>
                    <Circle
                      isNull={value.id === null}
                      color={value.id === null ? undefined : hexToRgba(value.color, 1)}
                      colorWithOpacity={value.id === null ? undefined : hexToRgba(value.color, 0.4)}
                    />
                    <Typography variant="m-regular">
                      {getHighlightedText(value.name, searchInput || '')}
                    </Typography>
                    <Typography ml="auto" variant="m-regular">
                      {value.share}%
                    </Typography>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Collapse>
    </>
  );
}
