import { ReactElement } from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { VerticalDivider } from '../../../../../../../../components/pages/analytics/exportsAnalytics/components/VerticalDivider';
import { LoadingIndicatorDiv } from '../../../analytics/components/LoadingIndicatorDiv';
import { DownloadButton } from './elements/DownloadButton';

export const SplitChartCard = ({
  charts,
}: {
  charts: {
    title: string;
    component: ReactElement;
    isLoading: boolean;
    topLeftArea?: ReactElement;
    topRightArea?: ReactElement;
    style?: React.CSSProperties;
    handleDownload?: () => void;
  }[];
}) => {
  return (
    <Box
      display="flex"
      style={{
        width: '100%',
        height: '300px',
        boxSizing: 'border-box',
        border: '1px solid #DCDCDC',
        borderRadius: '8px',
      }}
      flexDirection="column"
      alignItems="flex-start"
      gap={1}
    >
      <Box
        display="flex"
        width="100%"
        p={1}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        borderBottom="1px solid"
        borderColor="gray-200"
      >
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" width="100%" flexDirection="row" alignItems="center" my={0.5} ml={1}>
            <Box display="flex" width="50%" alignItems="center" gap={0.25}>
              <Typography variant="l-strong" color="gray-400" pr={0.5}>
                {charts[0].title}
              </Typography>
              {charts[0]?.topLeftArea && charts[0]?.topLeftArea}
              <Box ml="auto" mr={1}>
                {charts[0]?.handleDownload && (
                  <DownloadButton
                    handleDownload={charts[0].handleDownload}
                    isDisabled={charts[0].isLoading === true}
                  />
                )}
              </Box>
            </Box>
            <VerticalDivider mr={2} style={{ width: '1px', height: '26px' }} />
            <Box display="flex" alignItems="center" ml="auto" width="50%" gap={1}>
              <Typography variant="l-strong" color="gray-400" pr={0.5}>
                {charts[1].title}
              </Typography>
              <Box>{charts[1]?.topLeftArea && charts[1]?.topLeftArea}</Box>
              <Box ml="auto" mr={1}>
                {charts[1]?.handleDownload && (
                  <DownloadButton
                    handleDownload={charts[1].handleDownload}
                    isDisabled={charts[1].isLoading === true}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" px={1} py={1} width="100%" height="100%" position="relative">
        <Box display="flex" width="50%" ml={1}>
          {charts[0].isLoading ? <LoadingIndicatorDiv /> : charts[0].component}
        </Box>
        <VerticalDivider mr={2} style={{ width: '1px', height: '213px' }} />
        <Box width="50%">{charts[1].isLoading ? <LoadingIndicatorDiv /> : charts[1].component}</Box>
      </Box>
    </Box>
  );
};
