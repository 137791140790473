import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowLeft, ArrowRight, LoadingSpinner, WarningFilled } from '@superb-ai/icons';
import {
  Box,
  Dialog,
  Icon,
  IconButton,
  LoadingIndicator,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { UseQueryResult } from '@tanstack/react-query';
import dynamic from 'next/dynamic';

import { Row } from '../../../../components/elements/Row';
import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import { PixiDetailAnnotation } from '../../../../components/pages/aiAdvancedFeatures/Shapes';
import { CurateImageData } from '../../../Curate/services/DatasetService';
import {
  AnnotationTypeCoordinate,
  Box as AnnotationBox,
  Polygon,
} from '../../../Curate/types/annotationTypes';
import { ModelTrainingEpochs, SamplePredictionData } from '../../services/types';
import SamplePredictionCabinet from './SamplePredictionCabinet';
import { annotationTypeResolver, classNameResolver } from './utils';

const PixiDetailImage = dynamic({
  loader: () => import('./PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});

export default function SamplePredictionDialog({
  trainingEpochsData,
  state,
  datasetImages,
  datasetImagesLength,
  samplePredictions,
  datasetImageIndex,
  setDatasetImageIndex,
  imageId,
  epochsRangeIndex,
  setEpochsRangeIndex,
  minEpoch,
  maxEpoch,
}: {
  trainingEpochsData: ModelTrainingEpochs;
  state: ReturnType<typeof useDialogState>;
  datasetImages: UseQueryResult<CurateImageData, unknown>;
  datasetImagesLength: number;
  samplePredictions: UseQueryResult<SamplePredictionData, unknown>;
  datasetImageIndex: number;
  setDatasetImageIndex: Dispatch<SetStateAction<number>>;
  imageId: number;
  epochsRangeIndex?: number;
  setEpochsRangeIndex?: Dispatch<SetStateAction<number>>;
  minEpoch?: number;
  maxEpoch?: number;
}) {
  const { data: datasetImageInfo, isLoading: isDatasetImagesLoading } = datasetImages;
  const { data: samplePredictionData, isLoading: isSamplePredictionLoading } = samplePredictions;

  const [filteredPredictionAnnotationsIds, setFilteredPredictionAnnotationsIds] = useState<
    string[]
  >([]);
  const [filteredGroundTruthAnnotationsIds, setFilteredGroundTruthAnnotationsIds] = useState<
    string[]
  >([]);
  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [annotationHoveredMap, setAnnotationHoveredMap] = useState<Record<string, boolean>>({});
  const isPrevDisabled = 0 === datasetImageIndex;
  const isNextDisabled = datasetImagesLength - 1 === datasetImageIndex;

  const presentImageSamplePredictionAnnotations = useMemo(
    () =>
      samplePredictions.data?.annotations.filter(annotation => annotation.image_id === imageId) ??
      [],
    [imageId, samplePredictions.data?.annotations],
  );

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) return {};
      return {
        [annotation.id]: true,
      };
    });
  }, []);

  const handleHoverShape = useCallback(
    (annotation: PixiDetailAnnotation) => {
      if (Object.keys(annotationHoveredMap)[0] === annotation.id) return;
      setAnnotationHoveredMap(() => {
        return {
          [annotation.id]: true,
        };
      });
    },
    [annotationHoveredMap],
  );

  const handleLeaveShape = useCallback(() => {
    setAnnotationHoveredMap({});
  }, []);

  const handleClickList = (id: string) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[id]) return {};
      return {
        [id]: true,
      };
    });
  };
  const handleHoverList = (id: string) => {
    if (Object.keys(annotationHoveredMap)[0] === id) return;
    setAnnotationHoveredMap(() => {
      return {
        [id]: true,
      };
    });
  };

  const handleLeaveList = useCallback(() => {
    setAnnotationHoveredMap({});
  }, []);

  function prev() {
    setDatasetImageIndex(prev => {
      if (prev === 0) {
        return prev;
      } else {
        return prev - 1;
      }
    });
  }

  function next() {
    setDatasetImageIndex(prev => {
      if (prev === datasetImagesLength - 1) {
        return prev;
      } else {
        return prev + 1;
      }
    });
  }

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'ArrowLeft') {
        prev();
      } else if (key === 'ArrowRight') {
        next();
      }
    };
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
  }, []);

  const clickedAnnotationId = useMemo(() => {
    if (!datasetImageInfo) return;
    const id = Object.keys(annotationSelectedMap)[0];
    const annotationId = [
      ...(datasetImageInfo.annotations ?? []),
      ...presentImageSamplePredictionAnnotations,
    ]?.find(anno => anno.id === id)?.id;
    return annotationId;
  }, [annotationSelectedMap, datasetImageInfo, presentImageSamplePredictionAnnotations]);

  const hoveredAnnotationId = useMemo(() => {
    if (!datasetImageInfo) return;
    const id = Object.keys(annotationHoveredMap)[0];
    const annotationId = [
      ...(datasetImageInfo.annotations ?? []),
      ...presentImageSamplePredictionAnnotations,
    ]?.find(anno => anno.id === id)?.id;
    return annotationId;
  }, [annotationHoveredMap, datasetImageInfo, presentImageSamplePredictionAnnotations]);

  if (!datasetImageInfo || isSamplePredictionLoading || isDatasetImagesLoading) {
    return <LoadingIndicator />;
  }

  const filteredGroundTruthAnnotation = datasetImageInfo.annotations?.filter(annotation =>
    trainingEpochsData.annotationClassList.some(
      classItem =>
        classItem.name === annotation.annotation_class &&
        classItem.type === annotation.annotation_type,
    ),
  );

  const samplePredictionAnnotations = () => {
    if (!datasetImageInfo || !samplePredictionData) {
      return [];
    }
    const categories = samplePredictionData.categories;
    return samplePredictionData.annotations
      .filter(anno => !filteredPredictionAnnotationsIds.includes(anno.id))
      .filter(anno => anno.image_id === imageId)
      .map(anno => {
        const annotationType = annotationTypeResolver(anno, categories);
        return {
          type: annotationType,
          coordinate:
            annotationType === 'box'
              ? { x: anno.bbox[0], y: anno.bbox[1], width: anno.bbox[2], height: anno.bbox[3] }
              : (anno.segmentation as AnnotationTypeCoordinate<Polygon>['points']),
          id: anno.id,
          className: classNameResolver(anno, categories),
          color: '#FF625A',
        };
      }) as PixiDetailAnnotation[];
  };

  const groundTruthAnnotations = () => {
    if (!datasetImageInfo) {
      return [];
    }
    return filteredGroundTruthAnnotation
      ?.filter(anno => !filteredGroundTruthAnnotationsIds.includes(anno.id))
      .map(anno => ({
        type: anno.annotation_type,
        coordinate:
          anno.annotation_type === 'polygon'
            ? (anno.annotation_value as AnnotationTypeCoordinate<Polygon>).points
            : (anno.annotation_value as AnnotationTypeCoordinate<AnnotationBox>),
        id: anno.id,
        className: anno.annotation_class,
        color: '#4AE2B9',
      })) as PixiDetailAnnotation[];
  };

  return (
    <Dialog state={state} hideOnClickOutside={false}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        <Row display="flex" alignItems="center" style={{ width: 800 }}>
          <TextEllipsisBox style={{ width: 700 }}>
            <Typography variant="h2" color="primary">
              {datasetImageInfo.key}
            </Typography>
          </TextEllipsisBox>
          <Row ml="auto" style={{ width: 'max-content' }}>
            <IconButton
              color="gray"
              icon={ArrowLeft}
              variant="text"
              disabled={isPrevDisabled}
              onClick={prev}
            />
            <Typography variant="l-strong" color="gray-400">
              {datasetImageIndex + 1} / {datasetImagesLength}
            </Typography>
            <IconButton
              color="gray"
              icon={ArrowRight}
              variant="text"
              disabled={isNextDisabled}
              onClick={next}
            />
          </Row>
        </Row>
      </Dialog.Header>
      <Box
        display="flex"
        style={{
          maxWidth: '1800px',
          minWidth: '1124px',
          height: '600px',
        }}
      >
        {!datasetImageInfo ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Icon icon={WarningFilled} color="gray-200" size="40px" />
            <Typography variant="m-regular">This image has been deleted.</Typography>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
              style={{ backgroundColor: '#F5F5F5', flex: 1 }}
            >
              {isDatasetImagesLoading ? (
                <Icon icon={LoadingSpinner} size="40px" />
              ) : (
                <PixiDetailImage
                  imgUrl={datasetImages.data.image_url}
                  samplePredictionAnnotations={samplePredictionAnnotations()}
                  groundTruthAnnotations={groundTruthAnnotations()}
                  annotationSelectedMap={annotationSelectedMap}
                  annotationHoveredMap={annotationHoveredMap}
                  onSelectImage={handleSelectImage}
                  onSelectShape={handleSelectShape}
                  onHoverShape={handleHoverShape}
                  onLeaveShape={handleLeaveShape}
                />
              )}
            </Box>
            <Box>
              <SamplePredictionCabinet
                imageInfo={datasetImageInfo}
                predictionAnnotation={presentImageSamplePredictionAnnotations}
                groundTruthAnnotation={filteredGroundTruthAnnotation}
                categories={samplePredictions.data?.categories}
                filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
                setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
                filteredGroundTruthAnnotationsIds={filteredGroundTruthAnnotationsIds}
                setFilteredGroundTruthAnnotationsIds={setFilteredGroundTruthAnnotationsIds}
                clickedAnnotationId={clickedAnnotationId}
                hoveredAnnotationId={hoveredAnnotationId}
                handleSelectImage={handleSelectImage}
                epochsRangeIndex={epochsRangeIndex}
                setEpochsRangeIndex={setEpochsRangeIndex}
                minEpoch={minEpoch}
                maxEpoch={maxEpoch}
                handleClickList={handleClickList}
                handleHoverList={handleHoverList}
                handleLeaveList={handleLeaveList}
              />
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
}
