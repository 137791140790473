import { map } from 'lodash';

import LabelConst from '../consts/LabelConst';
import { getLabelTypeColor } from './ColorUtils';
import LabelUtils from './LabelUtils';

// Legacy
// eslint-disable-next-line
const extractInfoOnLabel = (label: any, workapp: string): any => {
  const nextAnnotations: Record<string, any> = {};
  const nextAnnotationTypes: Record<string, any> = {};
  const nextShapes: any[] = [];
  const nextClassesCount: Record<string, any> = {};

  if (
    (workapp === 'image-default' || workapp === 'image-siesta/mask' || workapp === 'image-ssf') &&
    label?.info?.result?.objects
  ) {
    const {
      info: {
        result: { objects },
      },
    } = label;

    map(Object.values(objects), (annotation: any, index: number) => {
      const { id, properties } = annotation;
      nextAnnotations[id] = annotation;

      map(Object.keys(annotation.shape), type => {
        if (!nextAnnotationTypes[type]) {
          nextAnnotationTypes[type] = {
            type,
            isActive: true,
          };
        }

        nextShapes.push({
          id,
          class: annotation.class,
          properties,
          type,
          info: annotation.shape[type],
          index,
        });
      });

      if (!nextClassesCount[annotation.class]) {
        nextClassesCount[annotation.class] = 1;
      } else {
        nextClassesCount[annotation.class]++;
      }
    });
  }

  return {
    annotations: nextAnnotations,
    annotationTypes: nextAnnotationTypes,
    shapes: nextShapes,
    classesCount: nextClassesCount,
  };
};

// eslint-disable-next-line
const convertLabelInfoToSuite = (info: any, workapp: string): any => {
  const nextAnnotations: Record<string, any> = {};
  const nextAnnotationTypes: Record<string, any> = {};
  const nextShapes: any[] = [];
  const nextClassesCount: Record<string, any> = {};

  //  workapp === 'video-default'
  if (
    (workapp === 'image-default' || workapp === 'image-siesta/mask' || workapp === 'image-ssf') &&
    info?.result?.objects
  ) {
    const { result } = info;
    const { objects } = result;

    map(Object.values(objects), (annotation: any, index: number) => {
      const { id, properties } = annotation;
      nextAnnotations[id] = annotation;

      map(Object.keys(annotation.shape), type => {
        if (!nextAnnotationTypes[type]) {
          nextAnnotationTypes[type] = {
            type,
            isActive: true,
          };
        }

        nextShapes.push({
          id,
          class: annotation.class,
          properties,
          type,
          info: annotation.shape[type],
          index,
        });
      });

      if (!nextClassesCount[annotation.class]) {
        nextClassesCount[annotation.class] = 1;
      } else {
        nextClassesCount[annotation.class]++;
      }
    });
  }

  return {
    annotations: nextAnnotations,
    annotationTypes: nextAnnotationTypes,
    shapes: nextShapes,
    classesCount: nextClassesCount,
    // type:
    // color: LabelUtils.getAnnotationInfoColor(compareOption),
  };
};

// 나중에 전부 siesta로 바뀌면 아래것을 쓸 예정.
//  그리고 detail view는 더이상 sahara에 있지 않으므로 다른 context로 옮겨갈
//  필요가 있어보임 혹은 독단적으로 존재하거나.

// eslint-disable-next-line
const extractNewLabelInterface = (labelInterface: any): any => {
  // eslint-disable-next-line
  const { categorization } = labelInterface;

  const objectDef = labelInterface.objectDetection || labelInterface.objectTracking;
  if (!objectDef) {
    return {
      groups: [],
      classes: [],
      categorization: [],
    };
  }

  // eslint-disable-next-line
  const { annotationTypes, keypoints, objectClasses, objectGroups } = objectDef;

  const getGroups = () => {
    const nextGroups: any = {};

    if (!objectGroups || !objectGroups?.length || objectGroups.length === 0) return nextGroups;

    for (let i = 0; i < objectGroups.length; i++) {
      const curGroup = objectGroups[i];
      const nextClasses: any[] = [];

      for (let j = 0; j < curGroup.objectClassIds.length; j++) {
        const curClassId = curGroup.objectClassIds[j];
        const curClass = objectClasses.find((item: any) => item.id === curClassId);
        nextClasses.push(curClass.name);
      }
      nextGroups[curGroup.name] = {
        isActive: true,
        classes: nextClasses,
      };
    }

    return nextGroups;
  };

  const getClasses = () => {
    const getGroupName = (objectClassId: string) => {
      let nextGroupName = '';
      for (let i = 0; i < objectGroups.length; i++) {
        const curObjectGroup = objectGroups[i];

        if (curObjectGroup.objectClassIds.includes(objectClassId)) {
          nextGroupName = curObjectGroup.name;
          break;
        }
      }

      return nextGroupName;
    };

    const nextClasses: any = {};
    for (let i = 0; i < objectClasses.length; i++) {
      const curObjectClass = objectClasses[i];

      nextClasses[curObjectClass.name] = {
        isActive: true,
        color: {},
      };
      const nextGroupName = getGroupName(curObjectClass.id);

      if (nextGroupName) {
        nextClasses[curObjectClass.name].groupName = nextGroupName;
      }

      nextClasses[curObjectClass.name].color[curObjectClass.annotationType] = curObjectClass.color;
    }
    return nextClasses;
  };

  // todo
  const getCategorization = () => {
    return null;
  };

  return {
    groups: getGroups(),
    classes: getClasses(),
    categorization: getCategorization(),
  };
};

// eslint-disable-next-line
const extractLabelInfos = (label: any, workapp: string): any => {
  const {
    info,
    autoLabelInfo,
    uniqueLabelType,
    additionalLabelType,
    id,
    consensusInfo,
    status,
    consistencyScore,
  } = label;
  const labelType = LabelUtils.getLabelType({ uniqueLabelType, additionalLabelType });

  return {
    currentLabelInfo: {
      id,
      info: convertLabelInfoToSuite(info, workapp),
      type: labelType,
      color: getLabelTypeColor('current'),
      category: 'current',
      numberId: consensusInfo?.consensusId,
      status: LabelUtils.getStatusColorName(status),
      consistencyScore,
    },
    autoLabelInfo: {
      id,
      info: convertLabelInfoToSuite(autoLabelInfo, workapp),
      type: LabelConst.COMPARE_OPTIONS.AUTO_LABEL,
      color: getLabelTypeColor(LabelConst.COMPARE_OPTIONS.AUTO_LABEL as 'autoLabel'),
      category: 'autoLabel',
    },
  };
};

// eslint-disable-next-line
const extractRelatedLabelInfos = (label: any, workapp: string): any => {
  const {
    info,
    uniqueLabelType,
    additionalLabelType,
    id,
    consensusInfo,
    status,
    consistencyScore,
  } = label;
  const labelType = LabelUtils.getLabelType({ uniqueLabelType, additionalLabelType });

  return {
    id,
    info: convertLabelInfoToSuite(info, workapp),
    type: labelType,
    category: 'relatedLabel',
    numberId: consensusInfo?.consensusId,
    status: LabelUtils.getStatusColorName(status),
    consistencyScore,
  };
};

// 나중에 전부 siesta로 바뀌면 아래것은 삭제
// eslint-disable-next-line
const extractInfoOnLabelInterface = (labelInterface: any): any => {
  const { objects = {}, groups = {} } = labelInterface;
  const objectsValues: any[] = Object.values(objects);
  const groupsValues: any[] = Object.values(groups);

  const nextGroups: Record<string, any> = {};
  const nextClasses: Record<string, any> = {};
  map(objectsValues, classes => {
    const {
      name,
      info: { shapes },
    } = classes;
    const color: Record<string, any> = {};

    const shapesKeys = Object.keys(shapes);
    map(shapesKeys, shape => {
      color[shape] = shapes[shape].defaultValue.color;

      const content = {
        isActive: true,
        color,
      };

      nextClasses[name] = content;
    });
  });

  if (groupsValues.length !== 0) {
    map(groupsValues, group => {
      const {
        name,
        info: { classes },
      } = group;

      const content = {
        isActive: true,
        classes,
      };

      nextGroups[name] = content;

      map(classes, className => {
        nextClasses[className].groupName = name;
      });
    });
  }

  return {
    groups: nextGroups,
    classes: nextClasses,
    categorization: labelInterface.categorization,
  };
};

export default {
  convertLabelInfoToSuite,
  extractInfoOnLabel,
  extractLabelInfos,
  extractRelatedLabelInfos,
  extractInfoOnLabelInterface,
  extractNewLabelInterface,
};
