import {
  Axis,
  BarSeries,
  Chart,
  PartialTheme,
  Position,
  ScaleType,
  SeriesIdentifier,
  Settings,
  Tooltip,
  TooltipAction,
} from '@elastic/charts';
import { Box, Typography } from '@superb-ai/ui';
import { truncate } from 'lodash';

import { HorizontalDivider } from '../../modelDiagnosis/diagnosis/analytics/elements/HorizontalDivider';
import { xAxisTickLabel } from './customTheme';
import { ChartDatum } from './types';

export type CustomChartConfig = {
  tooltipHeader: string;
  axes: {
    xAxisTitle: string;
    yAxisTitle: string;
  };
  series: {
    seriesId: string;
    xAccessor: string;
    yAccessor: string;
  };
};

export type HistogramOptionalProps = {
  tooltipFooterText?: string;
};

type HistogramProps = {
  data: ChartDatum[];
  color: string;
  config: CustomChartConfig;
  getTooltipActions?: () => TooltipAction<ChartDatum, SeriesIdentifier>[];
  onElementListeners?: any;
  theme?: PartialTheme;
  optional?: HistogramOptionalProps;
};

export const formatCount = (n: number) => {
  if (n < 1000) {
    return n.toString();
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k';
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + ' mil';
  } else {
    return (n / 1000000000).toFixed(1) + ' bil';
  }
};

export function Histogram({
  data,
  color,
  getTooltipActions,
  onElementListeners,
  config,
  theme,
  optional,
}: HistogramProps) {
  // const chartWidth = Math.max(CHART_CONFIG.DEFAULT_CHART_WIDTH, data.length * 15);
  const axes = config?.axes;
  const series = config?.series;
  const tooltipHeader = config.tooltipHeader;
  return (
    <Chart size={{ width: '100%', height: '100%' }}>
      <Settings
        theme={theme} // || getHistogramTheme(color)}
        roundHistogramBrushValues
        allowBrusthingLastHistgramBin
        brushAxis="x"
        {...onElementListeners}
      />
      <Tooltip
        headerFormatter={() => tooltipHeader || 'Count'}
        type="cross"
        showNullValues
        {...(getTooltipActions && {
          selectionPrompt: 'Right click to pin tooltip',
          actionPrompt: 'Ctrl + click to pin tooltip',
          actions: getTooltipActions(),
        })}
        {...(optional?.tooltipFooterText && {
          footer: () => (
            <Box width="100%">
              <HorizontalDivider mb={0.5} />
              <Typography variant="m-regular" mb={0} color="cloud-400">
                {optional?.tooltipFooterText}
              </Typography>
            </Box>
          ),
        })}
      />
      <Axis
        id="bottom"
        position={Position.Bottom}
        style={{
          tickLabel: xAxisTickLabel({ dataCount: data.length, textRotationThreshold: 100 }),
        }}
        ticks={10}
        title={axes?.xAxisTitle ?? ''}
        tickFormat={d => truncate(d, { length: 20 })}
      />
      <Axis
        id="left"
        title={axes?.yAxisTitle ?? 'Count'}
        position={Position.Left}
        tickFormat={(d: any) => {
          return formatCount(Number(d));
        }}
        ticks={10}
      />
      <BarSeries
        id={series?.seriesId ?? 'bar'}
        xScaleType={ScaleType.Linear}
        yScaleType={ScaleType.Linear}
        xAccessor={series?.xAccessor || 'keyNumeric'}
        yAccessors={[series?.yAccessor || 'count']}
        enableHistogramMode
        data={data}
        color={color}
        displayValueSettings={{ showValueLabel: true }}
      />
    </Chart>
  );
}
