import React, { useRef } from 'react';

import { makeStyles } from '@mui/styles';
import { Chip, OverflowItemCount } from '@superb-ai/norwegian-forest';

import { MemberData } from '../../../../../types/memberTypes';
import RoleChip from '../../../../elements/RoleChip';

const useStyles = makeStyles(() => ({
  selectedMembersList: {
    maxHeight: 44, // height of two lines of labels
    position: 'relative',
    gap: 4,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  removeButton: {
    cursor: 'pointer',
    display: 'inline-block',
    userSelect: 'none',
  },
}));

interface SelectedMembersListProps {
  selectedMembers: MemberData[];
  onRemoveMember: (member: MemberData) => void;
}

const SelectedMembersList: React.FC<SelectedMembersListProps> = ({
  selectedMembers,
  onRemoveMember,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();

  return (
    <div ref={ref} className={classes.selectedMembersList}>
      <OverflowItemCount
        container={ref}
        maxLines={2}
        overflowLabel={count => <Chip>+{count}</Chip>}
      >
        {selectedMembers.map(user => (
          <React.Fragment key={user.email}>
            <span
              className={classes.removeButton}
              role="button"
              tabIndex={0}
              onClick={() => onRemoveMember(user)}
              onKeyPress={() => onRemoveMember(user)}
            >
              <RoleChip
                memberRole={(user.projectRole || user.tenantRole)?.toLowerCase()}
                onDelete={() => onRemoveMember(user)}
              >
                {user.name}
              </RoleChip>
            </span>
          </React.Fragment>
        ))}
      </OverflowItemCount>
    </div>
  );
};

export default SelectedMembersList;
