import { differenceInHours } from 'date-fns';
import { Table } from 'dexie';

import { MislabelHistory } from '../HistoryTable';

export async function getValidIndexedDbInfo({
  db,
  id,
}: {
  db: Table<MislabelHistory>;
  id: string;
}) {
  const dbTable = await db.get({ id });
  const now = new Date();
  if (dbTable && differenceInHours(dbTable.savedAt, now) < 1) return dbTable;

  return undefined;
}
