import React, { Dispatch, SetStateAction } from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import Breadcrumbs from './Breadcrumbs';
import DescriptionAndReport from './DescriptionAndReport';
import ResultActions from './ResultActions';

type Props = {
  overview: JSX.Element;
  title: string;
  titleButton?: JSX.Element;
  previousPageName: string;
  previousPageUrl: string;
  Filter?: JSX.Element;
  infoMessage?: string | JSX.Element;
  isEditTagModalOpen?: boolean;
  setIsEditTagModalOpen?: Dispatch<SetStateAction<boolean>>;
  EditTagModal?: JSX.Element;
  onSelectItems?: () => void;
  selectedAllItems?: boolean;
  canEditTag?: boolean;
};
export default function ResultViewLayout({
  children,
  overview,
  title,
  titleButton,
  previousPageName,
  previousPageUrl,
  Filter,
  infoMessage,
  EditTagModal,
  isEditTagModalOpen,
  setIsEditTagModalOpen,
  onSelectItems,
  selectedAllItems,
  canEditTag,
}: React.PropsWithChildren<Props>) {
  return (
    <>
      <Breadcrumbs previousPageName={previousPageName} previousPageUrl={previousPageUrl} />
      <DescriptionAndReport
        infoMessage={infoMessage}
        title={title}
        titleButton={titleButton}
        overview={overview}
      />
      <Box
        style={{
          marginLeft: '-32px',
          marginRight: '-32px',
          marginBottom: '-76px',
          backgroundColor: '#f5f5f5',
        }}
      >
        {canEditTag && onSelectItems && (
          <Box px={4}>
            <ResultActions
              EditTagModal={EditTagModal}
              isEditTagModalOpen={isEditTagModalOpen}
              setIsEditTagModalOpen={setIsEditTagModalOpen}
              canEditTag={canEditTag}
              selectedAllItems={selectedAllItems || false}
              onSelectItems={onSelectItems}
              Filter={Filter}
            />
          </Box>
        )}
        {children}
      </Box>
    </>
  );
}
