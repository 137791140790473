import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, some } from 'lodash';

import { useLabelCompareInfo } from '../../../../contexts/LabelCompareContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';

const useStyles = makeStyles(() => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 400,
    padding: '0px 10px',
    borderRadius: '12.5px',
    cursor: 'pointer',
    margin: '5px',
    color: '#9b9b9b',
    borderColor: '#9b9b9b',
    '&.active': {
      color: '#ff625a',
      borderColor: '#ff625a',
      '&:hover': {
        background: '#ffeceb',
      },
    },
    '&.MuiButton-root': {
      padding: '0px 14px',
    },
  },
}));

// eslint-disable-next-line
const CabinetClassItem = (props: any): React.ReactElement => {
  const className = useStyles();
  const { item, name, updateStates } = props;
  const labelCompareInfo = useLabelCompareInfo();
  const { activeCompareOptionArr } = labelCompareInfo;
  const labelInformationInfo = useLabelInformationInfo();
  const { groups, classes, labelAnnotationInfos } = labelInformationInfo;

  const handleClickClass = (className: string) => () => {
    const nextGroups = cloneDeep(groups);
    const nextClasses = cloneDeep(classes);
    const nextIsActive = !nextClasses[className].isActive;

    if (groups.length !== 0) {
      nextClasses[className].isActive = nextIsActive;

      if (nextClasses[className].groupName) {
        const { groupName } = nextClasses[className];
        if (
          some(groups[groupName].classes, className => nextClasses[className].isActive === true)
        ) {
          nextGroups[groupName].isActive = true;
        } else {
          nextGroups[groupName].isActive = false;
        }
      }
    } else {
      nextClasses[className].isActive = nextIsActive;
    }

    updateStates(nextGroups, nextClasses);
  };

  const getName = (): string => {
    if (!activeCompareOptionArr) return '';

    let count = 0;
    let hasCurrent = false;
    let hasAutoLabel = false;
    for (let i = 0; i < activeCompareOptionArr.length; i++) {
      const item = activeCompareOptionArr[i];
      if (item.category === 'current') {
        hasCurrent = true;
      }
      if (item.category === 'autoLabel') {
        hasAutoLabel = true;
      }
    }

    const key = (() => {
      if (hasCurrent) return 'current';
      if (hasAutoLabel) return 'autoLabel';
      return null;
    })();

    if (!key) {
      return `${name}`;
    }

    const {
      info: { classesCount },
    } = labelAnnotationInfos[key];

    if (classesCount && name in classesCount) {
      count += classesCount[name];
    }
    return `${name} (${count})`;
  };

  return (
    <MUI.Button
      className={`${className.chip} ${item.isActive ? 'active' : ''}`}
      key={name}
      variant="outlined"
      onClick={handleClickClass(name)}
    >
      {getName()}
    </MUI.Button>
  );
};

export default CabinetClassItem;
