import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaretDown, CaretRight, InfoCircle } from '@superb-ai/icons';
import { Box, Button, Chip, Icon, Tooltip, Typography } from '@superb-ai/ui';
import Overflow from 'rc-overflow';

import { useFilterInfo } from '../../../../contexts/FilterContext';
import FilterList from './FilterList';

export default function FilterLayout() {
  const { t } = useTranslation();
  const { selectedFilters, setSelectedFilters } = useFilterInfo();
  const [showLess, setShowLess] = useState(false);

  useEffect(
    () => () => {
      setSelectedFilters([{ filterBy: '', condition: '', options: [] }]);
    },
    [],
  );

  return (
    <Box width="100%" display="flex" justifyContent="space-between" gap={1}>
      {showLess ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          gap={1}
          px={1.5}
          backgroundColor="gray-100"
          whiteSpace="nowrap"
          style={{ height: '32px' }}
        >
          <Icon icon={InfoCircle} size={16} />
          <Typography variant="m-regular" mr={1}>
            {t('labels.filters.filterApplied', { count: selectedFilters.length })}
          </Typography>
          <Overflow
            style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}
            data={selectedFilters}
            renderItem={filter => (
              <Chip color="primary" fill="soft" style={{ height: '20px' }}>
                <Typography overflow="hidden" textOverflow="ellipsis" variant="s-regular">
                  {t(`labels.filters.filterBy.${filter.filterBy}`, filter.filterBy)},{' '}
                  {t(`labels.filters.condition.${filter.condition}`, filter.condition)}
                </Typography>
              </Chip>
            )}
            renderRest={filters => (
              <Tooltip
                content={
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    {filters.map((filter, i) => (
                      <Typography
                        overflow="hidden"
                        textOverflow="ellipsis"
                        variant="s-regular"
                        key={`${i}-${filter.filterBy}`}
                      >
                        {t(`labels.filters.filterBy.${filter.filterBy}`, filter.filterBy)},{' '}
                        {t(`labels.filters.condition.${filter.condition}`, filter.condition)}
                      </Typography>
                    ))}
                  </Box>
                }
              >
                <Chip color="primary" fill="soft" style={{ height: '20px' }}>
                  ...
                </Chip>
              </Tooltip>
            )}
            maxCount={6}
          />
        </Box>
      ) : (
        <Box width="100%" display="flex" flexDirection="column" gap={1}>
          <FilterList />
        </Box>
      )}
      <Box display="flex" alignItems="center" style={{ height: '32px' }}>
        <Button
          size="s"
          variant="text"
          onClick={() => setShowLess(!showLess)}
          disabled={selectedFilters.length <= 1 && selectedFilters?.[0].filterBy === ''}
        >
          <Icon icon={showLess ? CaretRight : CaretDown} />
          <Typography whiteSpace="nowrap" variant="s-regular">
            {showLess ? t('labels.filters.seeAll') : t('labels.filters.seeLess')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
