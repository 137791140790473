import React from 'react';

import { map } from 'lodash';

import ToastContext from '../../contexts/ToastContext';
import Toast from './toast';

const ToastContainer: React.FC = () => {
  const toastInfo = React.useContext(ToastContext.Context);
  const { renderQueue } = toastInfo;

  return (
    <>
      {map(renderQueue, toast => (
        <Toast key={toast.id} toast={toast} />
      ))}
    </>
  );
};

export default ToastContainer;
