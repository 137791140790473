import React, { useEffect, useRef, useState } from 'react';
import { Group, Rect } from 'react-konva';

import Color from 'color';

import ImageContext from '../../../../../contexts/ImageContext';
import { useLabelInformationInfo } from '../../../../../contexts/LabelInformationContext';
import Properties from './Properties';

const Box = props => {
  const {
    shape,
    hoverIndex,
    onClickShape,
    onMouseEnterShape,
    onMouseLeaveShape,
    color,
    isAutoLabelUncertainty,
    isActiveAutoLabelUncertainPanel,
    difficulty,
    isClicked,
  } = props;

  const shapeRef = useRef(null);
  const labelInformationInfo = useLabelInformationInfo();
  const imageInfo = React.useContext(ImageContext.Context);
  const { rate } = imageInfo;

  const isUncertainty = difficulty === 1;

  const { classes, cabinetTabState, isLabelInformationSwitchActive } = labelInformationInfo;

  const { info } = shape;
  const { x, y, width, height } = info;

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!shapeRef.current) return;

    if (isActiveAutoLabelUncertainPanel && isAutoLabelUncertainty) {
      if (isUncertainty) {
        shapeRef.current.zIndex(6);
      } else {
        shapeRef.current.zIndex(4);
      }
      return;
    }

    if (cabinetTabState === 'issue') {
      shapeRef.current.zIndex(1);
    } else if (hoverIndex === 1) {
      shapeRef.current.zIndex(shapeRef.current.parent.children.length - 1);
    }
    // eslint-disable-next-line
  }, [hoverIndex, cabinetTabState]);

  const getColor = () => {
    if (isAutoLabelUncertainty && isUncertainty) {
      return '#ffcc00';
    }

    if (isClicked || isHovered) {
      return Color(color).negate().hex();
    }
    return color;
  };
  const getStrokeWidth = () => {
    if (isAutoLabelUncertainty && isUncertainty) {
      return 4 / rate;
    }
    return 2 / rate;
  };

  // TODO (tsnoh): 임시코드. project에 등록된 class와 다른 class가 있을경우 방어
  if (!classes[shape.class]) return null;

  return (
    <Group ref={shapeRef}>
      {isLabelInformationSwitchActive && (
        <Properties shape={shape} x={x} y={y} width={width} height={height} rate={rate} />
      )}

      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke={getColor()}
        strokeWidth={getStrokeWidth()}
        opacity={1}
        onClick={onClickShape}
        onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
        onMouseLeave={onMouseLeaveShape({ setIsHovered })}
        cursor="pointer"
      />
    </Group>
  );
};

export default Box;
