import { ComponentProps } from 'react';

import {
  Axis,
  BarSeries,
  Chart,
  Position,
  ScaleType,
  SeriesColorAccessorFn,
  Settings,
} from '@elastic/charts';

import { getCustomTheme } from '../../../apps/Curate/components/datasets/dataset/analytics/charts/customTheme';
import { UsageByProductFeature } from '../../pages/account/billingAndUsage/types';
import { formatDate } from './chartUtils';

type StackedBarProps = {
  data: UsageByProductFeature[];
  color: string;
  getColor: SeriesColorAccessorFn;
  xAxis: {
    label?: string;
    xAccessor: string;
    stackAccessor: string;
    dateFormatter: typeof formatDate;
  };
  yAxis: { label?: string; yAccessor: string; domain?: ComponentProps<typeof Axis>['domain'] };
  // getTooltipActions?: () => TooltipAction<ChartDatum, SeriesIdentifier>[];
  chartWidth?: number;
  onElementListeners?: any;
};

const formatCredit = (n: number) => {
  if (n < 1000) {
    return n.toString();
  } else if (n < 1000000) {
    return (n / 1000).toFixed(1) + 'k';
  } else if (n < 1000000000) {
    return (n / 1000000).toFixed(1) + ' mil';
  } else {
    return (n / 1000000000).toFixed(1) + ' bil';
  }
};

export function StackedBarChart({
  data,
  color,
  getColor,
  xAxis,
  yAxis,
  // getTooltipActions,
  onElementListeners,
  chartWidth,
}: StackedBarProps) {
  return (
    <Chart size={{ width: chartWidth ? `${chartWidth - 20}px` : '100%', height: '100%' }}>
      <Settings
        showLegend
        legendPosition={Position.Right}
        theme={getCustomTheme(color, 0.2)}
        {...onElementListeners}
      />
      <Axis
        id="bottom"
        position={Position.Bottom}
        title={xAxis?.label ?? ''}
        tickFormat={d => xAxis.dateFormatter(d)}
      />
      <Axis
        id="left"
        title={yAxis.label}
        position={Position.Left}
        tickFormat={(d: any) => {
          return formatCredit(Number(d));
        }}
        domain={yAxis.domain}
        ticks={6}
      />
      <BarSeries
        id="bars"
        xScaleType={ScaleType.Time}
        timeZone="UTC"
        yScaleType={ScaleType.Linear}
        xAccessor={xAxis?.xAccessor || 'date'}
        yAccessors={[yAxis?.yAccessor || 'count']}
        stackAccessors={[xAxis?.stackAccessor || 'date']}
        splitSeriesAccessors={['group']}
        color={getColor}
        data={data}
      />
    </Chart>
  );
}
