import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Search } from '@superb-ai/icons';
import { Box, Button, Dropdown, Icon, Input, NamedColorWeight, Typography } from '@superb-ai/ui';

import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import { Circle } from '../analytics/elements/Circle';
import { isDiagnosisActive, isDiagnosisDisabled } from '../types';
import { useDiagnosisPathname } from '../useGetUrl';
import { ModelSelectOption } from './ModelSelectOption';

interface Props {
  selectedDiagnosisId?: string;
  handleSelectDiagnosisId: (newDiagnosisId: string) => void;
}

export default function ModelSelectDropdown({
  selectedDiagnosisId,
  handleSelectDiagnosisId,
}: Props) {
  const { t } = useTranslation();
  const { diagnosisList, diagnosisCount, refetchDiagnosisList } = useDiagnosisModelContext();
  const [searchValue, setSearchValue] = useState('');
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const diagnosisPathName = useDiagnosisPathname();

  useEffect(() => {
    if (!visible) return;
    refetchDiagnosisList();
  }, [visible]);

  const filterDiagnosisList = diagnosisList?.filter(
    diagnosis =>
      isDiagnosisActive(diagnosis.status) &&
      (diagnosis.modelName || t('curate.diagnosis.text.modelSourceTag.deleted'))
        .toLowerCase()
        .includes(searchValue.toLowerCase()),
  );

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
  }

  function SelectedOption({ color, children }: { color: NamedColorWeight; children: ReactNode }) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
        <Circle color={color} />
        {children}
      </Box>
    );
  }

  return (
    <Box display="flex" style={{ minWidth: '300px' }}>
      {diagnosisList ? (
        <Dropdown
          disclosure={
            <SelectedOption color="primary-400">
              {diagnosisList.find(option => option.id === selectedDiagnosisId)?.modelName ||
                t('curate.diagnosis.text.modelSourceTag.deleted')}
            </SelectedOption>
          }
          hideOnClick
          onChangeVisible={v => {
            setVisible(v);
          }}
          variant="text"
        >
          <Box p={0.5} borderBottom="1px solid" borderColor="gray-100" display="flex">
            <Input
              type="search"
              variant="text"
              prefix={<Icon icon={Search} />}
              placeholder={
                diagnosisList.length < diagnosisCount
                  ? t('button.loading')
                  : t('curate.diagnosis.diagnosisList.searchPlaceholder')
              }
              onChange={handleSearch}
              disabled={diagnosisList.length < diagnosisCount}
              style={{ flex: 1 }}
            />
          </Box>
          <Box py={0.5} style={{ width: '532px' }}>
            <Box py={0.5} px={1} display="flex" alignItems="center" width="100%">
              <Typography variant="s-regular" color="gray-300">
                {t('curate.diagnosis.diagnosisList.modelName')}
              </Typography>
              <Typography variant="s-regular" color="gray-300" style={{ marginLeft: 'auto' }}>
                {t('curate.diagnosis.diagnosisList.lastUpdated')}
              </Typography>
            </Box>
            <Box style={{ maxHeight: 300 }} overflow="auto">
              {(filterDiagnosisList || []).map(diagnosis => (
                <ModelSelectOption
                  key={diagnosis.id}
                  diagnosis={diagnosis}
                  isSelected={diagnosis.id === selectedDiagnosisId}
                  onSelect={handleSelectDiagnosisId}
                  disabled={isDiagnosisDisabled(diagnosis.status)}
                />
              ))}
            </Box>
          </Box>
          <Button
            variant="soft-fill"
            style={{ width: '100%' }}
            onClick={() => {
              history.push(`/${diagnosisPathName}list`);
            }}
          >
            View all available model diagnostics
          </Button>
        </Dropdown>
      ) : (
        <Typography variant="m-regular">
          {' '}
          {t('curate.diagnosis.diagnosisList.noResultFound')}
        </Typography>
      )}
    </Box>
  );
}
