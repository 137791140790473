import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import {
  ChevronLeft,
  ChevronRight,
  Copy,
  ImageAlt,
  InfoCircleOutline,
  LoadingSpinnerSmall,
  Upload,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@superb-ai/ui';
import imageCompression from 'browser-image-compression';
import dynamic from 'next/dynamic';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { PixiDetailAnnotation } from '../../../../components/pages/aiAdvancedFeatures/Shapes';
import {
  EXCEED_FILE_SIZE,
  FILE_UPLOAD_EXTENDED_TYPE_NOT_SUPPORTED,
  JSON_COPIED,
  UPLOAD_FAILED,
  UPLOAD_ONLY_ONE_IMAGE,
} from '../../../../consts/SnackbarMessage';
import FileUtils from '../../../../utils/FileUtils';
import { AnnotationTypeCoordinate, Polygon } from '../../../Curate/types/annotationTypes';
import { BorderBox } from '../../components/components';
import { AnnotationClassesCollapse } from '../../gen-ai/SamplePredictionDialog';
import { usePostRecognitionEndpointInferenceImageMutation } from '../../queries/modelQueries';
import { FormattedAnnotation } from '../../recognition-ai/detail/SamplePredictionCabinet';
import { RecognitionEndpointInferenceImageData } from '../../services/ModelService';
import { AnnotationType, EndpointStatus } from '../../services/types';
import { colorPalette } from '../../utils/colorUtils';
import { calcPolygon } from '../../utils/maskToGeoJsonPolygon';
import { useRecognitionPredictionTestContext } from './contexts/RecognitionPredictionTestContext';

const PixiDetailImage = dynamic({
  loader: () => import('../../recognition-ai/detail/PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});

export const RecognitionPredictionTest = ({
  endpointId,
  endpointStatus,
}: {
  endpointId: string;
  endpointStatus: EndpointStatus;
}) => {
  const {
    predictionIndex,
    predictionResults,
    setPredictionResults,
    annotationSelectedMap,
    setAnnotationSelectedMap,
    annotationHoveredMap,
    setAnnotationHoveredMap,
    setClickedAnnotationId,
    setHoveredAnnotationId,
  } = useRecognitionPredictionTestContext();

  const { mutate, isLoading } = usePostRecognitionEndpointInferenceImageMutation({
    handleSuccess: data => {
      setPredictionResults(prev => [...prev, data]);
    },
  });
  const inferenctResultWithId = predictionResults[predictionIndex]?.objects.map((obj, idx) => ({
    ...obj,
    id: annotationIdResolver(obj.class, idx, obj.score),
  }));

  const annotationColorMap: Record<string, string> = Object.fromEntries(
    predictionResults[predictionIndex]?.objects.map((obj, idx) => [
      obj.class,
      colorPalette[idx % colorPalette.length].hex,
    ]) ?? [],
  );

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
    setClickedAnnotationId(null);
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(String(annotation.id));
        return {
          [annotation.id]: true,
        };
      }
    });
  }, []);

  const handleHoverShape = useCallback(
    (annotation: PixiDetailAnnotation) => {
      if (Object.keys(annotationHoveredMap)[0] === annotation.id) return;
      setAnnotationHoveredMap(prevState => {
        if (prevState[annotation.id]) {
          setHoveredAnnotationId('');
          return {};
        } else {
          setHoveredAnnotationId(String(annotation.id));
          return {
            [annotation.id]: true,
          };
        }
      });
    },
    [annotationHoveredMap],
  );

  const handleLeaveShape = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotationId(null);
  }, []);

  const handleClickList = (id: string) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };
  const handleHoverList = (id: string) => {
    if (Object.keys(annotationHoveredMap)[0] === id) return;
    setAnnotationHoveredMap(prevState => {
      if (prevState[id]) {
        setHoveredAnnotationId('');
        return {};
      } else {
        setHoveredAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };

  const handleLeaveList = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotationId(null);
  }, []);

  return (
    <BorderBox display="grid" style={{ gridTemplateColumns: 'minmax(732px, 1fr) 276px' }}>
      <InputImages
        data={inferenctResultWithId}
        endpointId={endpointId}
        endpointStatus={endpointStatus}
        mutate={mutate}
        isLoading={isLoading}
        annotationSelectedMap={annotationSelectedMap}
        annotationHoveredMap={annotationHoveredMap}
        handleSelectImage={handleSelectImage}
        handleSelectShape={handleSelectShape}
        handleHoverShape={handleHoverShape}
        handleLeaveShape={handleLeaveShape}
        annotationColorMap={annotationColorMap}
      />
      <Results
        predictionResult={predictionResults[predictionIndex]}
        data={inferenctResultWithId}
        handleClickList={handleClickList}
        handleHoverList={handleHoverList}
        handleLeaveList={handleLeaveList}
        annotationColorMap={annotationColorMap}
      />
    </BorderBox>
  );
};

const InputImages = ({
  data,
  endpointId,
  endpointStatus,
  mutate,
  isLoading,
  annotationSelectedMap,
  annotationHoveredMap,
  handleSelectImage,
  handleSelectShape,
  handleHoverShape,
  handleLeaveShape,
  annotationColorMap,
}: {
  data: {
    class: string;
    score: number;
    mask?: string;
    box?: number[];
    id: string;
  }[];
  endpointId: string;
  endpointStatus: EndpointStatus;
  mutate: ReturnType<typeof usePostRecognitionEndpointInferenceImageMutation>['mutate'];
  isLoading: boolean;
  annotationSelectedMap: Record<string, boolean>;
  annotationHoveredMap: Record<string, boolean>;
  handleSelectImage: () => void;
  handleSelectShape: (annotation: PixiDetailAnnotation) => void;
  handleHoverShape: (annotation: PixiDetailAnnotation) => void;
  handleLeaveShape: (annotation: PixiDetailAnnotation) => void;
  annotationColorMap: Record<string, string>;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    imageUrls,
    setImageUrls,
    predictionIndex,
    setPredictionIndex,
    filteredPredictionAnnotationsIds,
  } = useRecognitionPredictionTestContext();
  const imageMaxSize = 4 * 1024 * 1024;
  const [inputImageAnnotations, setInputImageAnnotations] = useState<PixiDetailAnnotation[]>([]);

  async function handleFiles(files: File[]) {
    if (files.length > 1) {
      enqueueSnackbar(UPLOAD_ONLY_ONE_IMAGE({ t }), { variant: 'error' });
      return;
    }
    const file = files[0];
    const extention = file.type.split('/')[1];

    if (!file) {
      enqueueSnackbar(UPLOAD_FAILED({ t }), { variant: 'error' });
      return false;
    } else if (
      extention !== 'jpeg' &&
      extention !== 'jpg' &&
      extention !== 'bmp' &&
      extention !== 'png'
    ) {
      enqueueSnackbar(FILE_UPLOAD_EXTENDED_TYPE_NOT_SUPPORTED({ t }), { variant: 'error' });
      return false;
    } else if (file.size > imageMaxSize) {
      enqueueSnackbar(EXCEED_FILE_SIZE({ t }), { variant: 'error' });
      return false;
    }

    try {
      await uploadImageInference(file);
      setPredictionIndex(imageUrls.length);
    } catch (error) {
      enqueueSnackbar(UPLOAD_FAILED({ t }), {
        variant: 'error',
      });
      throw error;
    }
  }

  async function uploadImageInference(file: File) {
    try {
      const processedFile = await compressAndRemoveMetadata(file);
      const blob = await convertFileToBlob(processedFile);
      const dataURL = await readFileAsDataURL(processedFile);

      return new Promise<void>((resolve, reject) => {
        mutate(
          {
            id: endpointId,
            imageBlob: blob,
          },
          {
            onSuccess: () => {
              setImageUrls(prev => [...prev, dataURL]);
              resolve();
            },
            onError: error => {
              enqueueSnackbar(UPLOAD_FAILED({ t }), {
                variant: 'error',
              });
            },
          },
        );
      });
    } catch (error) {
      enqueueSnackbar(UPLOAD_FAILED({ t }), {
        variant: 'error',
      });
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFiles,
    multiple: true,
  });

  useEffect(() => {
    if (!data) return;
    const getInputImageAnnotations = async () => {
      const filteredAnnotations = data?.filter(
        anno => !filteredPredictionAnnotationsIds.includes(anno.id),
      );
      const array: PixiDetailAnnotation[] = [];
      for (const anno of filteredAnnotations) {
        const annotationType = ('box' in anno ? 'box' : 'polygon') as AnnotationType;
        if (annotationType === 'box') {
          array.push({
            type: annotationType,
            coordinate: {
              x: anno.box[0],
              y: anno.box[1],
              width: anno.box[2] - anno.box[0],
              height: anno.box[3] - anno.box[1],
            },
            id: anno.id,
            className: anno.class,
            color: annotationColorMap[anno.class],
          });
        } else {
          const polygon = (await calcPolygon(anno.mask)) as AnnotationTypeCoordinate<Polygon>;
          array.push({
            type: annotationType,
            coordinate: polygon.points,
            id: anno.id,
            className: anno.class,
            color: annotationColorMap[anno.class],
          });
        }
      }
      return array;
    };

    getInputImageAnnotations().then(data => {
      setInputImageAnnotations(data);
    });
  }, [annotationColorMap, data, filteredPredictionAnnotationsIds]);

  const handleClickNext = () => {
    if (predictionIndex === imageUrls.length - 1) return;
    setPredictionIndex(prev => prev + 1);
  };

  const handleClickPrev = () => {
    if (predictionIndex === 0) return;
    setPredictionIndex(prev => prev - 1);
  };

  return (
    <Box br="1px solid" borderColor="gray-150" position="relative">
      <Row pl={2.5} style={{ height: 50 }}>
        <Typography variant="l-strong">{t('model.endpoints.inputImages')}</Typography>
      </Row>
      {endpointStatus === 'running' ? (
        imageUrls.length > 0 ? (
          <Box
            backgroundColor={'gray-100'}
            position="relative"
            bt="1px solid"
            bb="1px solid"
            borderColor="gray-150"
            style={{ height: 372, width: '100%' }}
            {...(getRootProps({
              onClick: e => e.stopPropagation(),
              onDragEnter: e => {
                e.preventDefault();
                e.stopPropagation();
              },
            }) as Partial<React.ComponentProps<typeof Box>>)}
          >
            <PixiDetailImage
              imgUrl={imageUrls[predictionIndex]}
              annotationSelectedMap={annotationSelectedMap}
              annotationHoveredMap={annotationHoveredMap}
              onSelectImage={handleSelectImage}
              onSelectShape={handleSelectShape}
              onHoverShape={handleHoverShape}
              onLeaveShape={handleLeaveShape}
              samplePredictionAnnotations={inputImageAnnotations}
            />
            <Box
              position="absolute"
              {...(getRootProps() as Partial<React.ComponentProps<typeof Box>>)}
              style={{ top: 8, right: 8 }}
            >
              <Button variant="stroke" size="s">
                <Icon icon={Upload} />
                {t('model.endpoints.uploadMoreImage')}
              </Button>
              <input {...getInputProps()} />
            </Box>
            <Row
              position="absolute"
              justifyContent="space-between"
              backgroundColor={'gray-400'}
              px={1}
              style={{ bottom: 8, left: 8, width: 74, height: 24, borderRadius: 12 }}
            >
              <Icon
                icon={ChevronLeft}
                color={predictionIndex === 0 ? 'gray-300' : 'white'}
                cursor={predictionIndex === 0 ? undefined : 'pointer'}
                onClick={handleClickPrev}
              />
              <Typography variant="s-regular" color="white">
                {predictionIndex + 1} / {imageUrls.length}
              </Typography>
              <Icon
                icon={ChevronRight}
                color={predictionIndex === imageUrls.length - 1 ? 'gray-300' : 'white'}
                cursor={predictionIndex === imageUrls.length - 1 ? undefined : 'pointer'}
                onClick={handleClickNext}
              />
            </Row>
          </Box>
        ) : (
          <Box
            bt="1px solid"
            bb="1px solid"
            borderColor="gray-150"
            backgroundColor={isDragActive ? 'gray-150' : 'gray-100'}
            cursor="pointer"
            style={{ height: 372 }}
            {...(getRootProps() as Partial<React.ComponentProps<typeof Box>>)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              border={isDragActive ? '1px solid' : undefined}
              borderColor={isDragActive ? 'primary-400' : undefined}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1.5}
                textAlign="center"
              >
                <Icon icon={ImageAlt} size={60} color="gray-300" />
                <Typography variant="l-regular">
                  <Trans t={t} i18nKey={'model.endpoints.uploadImage'} />
                </Typography>
              </Box>
              <input {...getInputProps()} />
            </Box>
          </Box>
        )
      ) : (
        <Box
          bt="1px solid"
          bb="1px solid"
          borderColor="gray-150"
          backgroundColor={'gray-100'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={1.5}
          style={{ height: 372 }}
        >
          <Icon icon={InfoCircleOutline} size={60} color="gray-300" />
          <Typography variant="l-regular">
            {t('model.endpoints.visualizeOnlyRunningEndpoint')}
          </Typography>
        </Box>
      )}
      <Box overflow="auto" style={{ height: 50 }}>
        <Row style={{ width: 'max-content' }}>
          {imageUrls.map((image, idx) => (
            <Box
              key={`${image}-${idx}`}
              overflow="hidden"
              border={idx === predictionIndex ? '2px solid' : undefined}
              borderColor={idx === predictionIndex ? 'primary-400' : undefined}
              onClick={() => setPredictionIndex(idx)}
              style={{ height: 50 }}
            >
              <img alt={image} src={image} width={50} height={50} style={{ objectFit: 'cover' }} />
            </Box>
          ))}
        </Row>
      </Box>
      {isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width={'100%'}
          backgroundColor={'black'}
          style={{ opacity: 0.6, top: 50, height: 424 }}
        >
          <Icon icon={LoadingSpinnerSmall} size={60} color="primary" style={{ marginBottom: 12 }} />
          <Typography variant="l-strong" color="white" style={{ opacity: 'none' }}>
            {t('model.endpoints.visualizeLoading')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const Results = ({
  data,
  predictionResult,
  handleClickList,
  handleHoverList,
  handleLeaveList,
  annotationColorMap,
}: {
  data: {
    class: string;
    score: number;
    mask?: string;
    box?: number[];
    id: string;
  }[];
  predictionResult: RecognitionEndpointInferenceImageData;
  handleClickList: (id: string) => void;
  handleHoverList: (id: string) => void;
  handleLeaveList: () => void;
  annotationColorMap: Record<string, string>;
}) => {
  const { t } = useTranslation();
  const {
    filteredPredictionAnnotationsIds,
    setFilteredPredictionAnnotationsIds,
    clickedAnnotationId,
    hoveredAnnotatinoId,
  } = useRecognitionPredictionTestContext();

  const predictionResults = data
    ? data
        .map((obj, idx) => ({ ...obj, id: annotationIdResolver(obj.class, idx, obj.score) }))
        .reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
          const annotationType = 'box' in annotation ? 'box' : ('polygon' as AnnotationType);
          return {
            ...acc,
            [annotation.class]: {
              type: annotationType,
              id: String(annotation.id),
              name: annotation.class,
              count: (acc[annotation.class]?.count || 0) + 1,
              annotations: [
                ...(acc[annotation.class]?.annotations.length > 0
                  ? acc[annotation.class]?.annotations
                  : []),
                {
                  id: String(annotation.id),
                  annotation_class: annotation.class,
                  annotation_type: annotationType,
                },
              ],
            },
          };
        }, {})
    : {};

  return (
    <Box display="grid" style={{ gridTemplateRows: '50px 1fr' }}>
      <Row pl={2.5}>
        <Typography variant="l-strong">{t('model.endpoints.results')}</Typography>
      </Row>
      <Box borderColor="gray-150">
        <Tabs>
          <TabList variant="fill">
            <Tab style={{ width: '50%' }}>{t('model.myModelDetail.predictionAnnotation')}</Tab>
            <Tab style={{ width: '50%' }}>{t('model.endpoints.json')}</Tab>
          </TabList>
          <Box>
            <TabPanel>
              <PredictionResults
                predictionResults={predictionResults}
                filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
                setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
                handleClickList={handleClickList}
                handleHoverList={handleHoverList}
                handleLeaveList={handleLeaveList}
                clickedAnnotationId={clickedAnnotationId}
                hoveredAnnotatinoId={hoveredAnnotatinoId}
                annotationColorMap={annotationColorMap}
              />
            </TabPanel>
            <TabPanel>
              <JsonResult smaplePredictionResult={predictionResult} />
            </TabPanel>
          </Box>
        </Tabs>
      </Box>
    </Box>
  );
};

const PredictionResults = ({
  predictionResults,
  filteredPredictionAnnotationsIds,
  setFilteredPredictionAnnotationsIds,
  handleClickList,
  handleHoverList,
  handleLeaveList,
  clickedAnnotationId,
  hoveredAnnotatinoId,
  annotationColorMap,
}: {
  predictionResults: Record<string, FormattedAnnotation>;
  filteredPredictionAnnotationsIds: string[];
  setFilteredPredictionAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  handleClickList: (id: string) => void;
  handleHoverList: (id: string) => void;
  handleLeaveList: () => void;
  clickedAnnotationId: string;
  hoveredAnnotatinoId: string;
  annotationColorMap: Record<string, string>;
}) => {
  const { t } = useTranslation();
  return (
    <Box overflow="auto" style={{ height: 380 }}>
      {!!Object.values(predictionResults).length ? (
        Object.values(predictionResults).map(anno => {
          return (
            <AnnotationClassesCollapse
              key={anno.id}
              formattedAnnotation={anno}
              filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
              setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
              handleClickList={handleClickList}
              handleHoverList={handleHoverList}
              handleLeaveList={handleLeaveList}
              clickedAnnotationId={clickedAnnotationId}
              hoveredAnnotatinoId={hoveredAnnotatinoId}
              annotationColorMap={annotationColorMap}
            />
          );
        })
      ) : (
        <Box pt={4} display="flex" justifyContent="center">
          <Typography variant="m-regular">{t('model.endpoints.noPrediction')}</Typography>
        </Box>
      )}
    </Box>
  );
};

const JsonResult = ({
  smaplePredictionResult,
}: {
  smaplePredictionResult: RecognitionEndpointInferenceImageData;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleClickCopy = (value: string) => {
    FileUtils.copyToClipboard({
      value,
    });
    enqueueSnackbar(JSON_COPIED({ t }), {
      variant: 'success',
    });
  };

  return (
    <>
      {smaplePredictionResult ? (
        <Box position="relative">
          <Box
            m={2.5}
            backgroundColor={'gray-100'}
            px={1.5}
            py={1}
            overflow="auto"
            style={{ height: 350, wordBreak: 'break-all' }}
          >
            <Typography variant="m-regular">{JSON.stringify(smaplePredictionResult)}</Typography>
            <Box
              position={'absolute'}
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              style={{
                bottom: 8,
                right: 28,
              }}
            >
              <IconButton
                icon={Copy}
                onClick={() => handleClickCopy(JSON.stringify(smaplePredictionResult))}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box pt={4} display="flex" justifyContent="center">
          <Typography variant="m-regular">{t('model.endpoints.noPrediction')}</Typography>
        </Box>
      )}
    </>
  );
};

function readFileAsDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = error => reject(error);
  });
}

async function convertFileToBlob(file: File): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const img = new window.Image();
    img.onload = () => {
      /**
       * image에 metadata가 있을 경우 aws:core-rule-set:CrossSiteScripting_Body가 발생하는 이슈가 있음
       * metadata를 제거한 이미지로 요청해야함
       */
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to create blob from canvas'));
        }
      }, file.type);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    const reader = new FileReader();
    reader.onload = e => {
      if (e.target && typeof e.target.result === 'string') {
        img.src = e.target.result;
      } else {
        reject(new Error('Failed to read file'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

async function compressAndRemoveMetadata(file: File): Promise<File> {
  const options = {
    useWebWorker: true, // Web Worker 사용 (백그라운드에서 처리)
    preserveExif: false, // EXIF 데이터 제거 (메타데이터 제거)
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
  }
}

const annotationIdResolver = (classname: string, index: number, score: number) => {
  return `${classname}-${index}-${score}`;
};
