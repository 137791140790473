import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useRouteMatch } from 'react-router';

import { Clear } from '@superb-ai/icons';
import { StatusChip, useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, ExternalLink, IconButton, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { DEFAULT_ERROR } from '../../../../../consts/ModalMessage';
import { useApiDefaultParams } from '../../../../../hooks/ApiParamsHook';
import { getParentPath } from '../../../../../routes/util';
import IntegrationsService from '../../../../../services/IntegrationsService';
import { IntegrationStatus, S3IntegrationResType } from '../../../../../types/integrationTypes';
import GridTable from '../../advanced/GridTable';
import getContents from '../contents';
import { SourceIntegrationTarget, TargetType } from '../types';
import EditAuthenticationAzure from './Azure';
import EditAuthenticationGoogleCloud from './GoogleCloud';
import EditAuthenticationS3 from './S3';

interface Props {
  integrationId: string;
  status: IntegrationStatus;
  name: string;
  bucket: string;
  prefix: string;
  method: 'S3' | 'GS' | 'ABS';
  // edit role name을 위해 추가된 props
  s3delegateInfo?: S3IntegrationResType;
  close: () => void;
}

export default function EditAuthenticationDialog({
  integrationId,
  status,
  bucket,
  name,
  method,
  prefix = '',
  s3delegateInfo,
  close,
}: Props) {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useState<Record<string, any> | null>(null);
  const match = useRouteMatch<{ type: TargetType }>();
  const target = match.params.type;
  const apiDefaultParams = useApiDefaultParams();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useAlertModal();

  const content = useMemo(() => {
    return getContents(t, i18n.language)[target];
  }, [t, target]);

  if (!content) {
    return <Redirect to={getParentPath(match.url)} />;
  }

  const { title, logo, docsLink } = content;

  const checkStatus = async ({ info = {} }: { info?: Record<string, any> }) => {
    const response = await IntegrationsService.checkIntegrationStatus({
      ...apiDefaultParams,
      params: {
        method,
        auth: params,
        alias: name,
        info: { ...(info || {}), bucket, ...(prefix && { prefix }) },
      },
    });
    return response;
  };

  const handleApply = async () => {
    if (!params) return;

    if (s3delegateInfo) {
      handleApplyForDelegate();
      return;
    }

    try {
      const status = await checkStatus({});

      if (status.data.message !== 'Success') {
        throw new Error(status.data.message);
      }

      await IntegrationsService.editIntegration({
        ...apiDefaultParams,
        integrationId,
        params: {
          info: { bucket, ...(prefix && { prefix }) },
          auth: params,
        },
      });

      enqueueSnackbar(t('integrations.integrationModificationSuccessful'), {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      close();
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          title: t('integrations.integrationModificationFailed'),
          onClickMainButton: closeModal,
        }),
      );
    }
  };

  const handleApplyForDelegate = async () => {
    try {
      const status = await checkStatus({ info: { isDelegate: true } });

      if (status.data.message !== 'Success') {
        throw new Error(status.data.message);
      }

      await IntegrationsService.editIntegration({
        ...apiDefaultParams,
        integrationId,
        params: {
          info: {
            isDelegate: true,
            bucket,
            ...(prefix && { prefix }),
          },
          auth: params,
        },
      });

      enqueueSnackbar(t('integrations.integrationModificationSuccessful'), {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      close();
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          title: t('integrations.integrationModificationFailed'),
          onClickMainButton: closeModal,
        }),
      );
    }
  };

  const EditAuthencicationComponent = {
    S3: EditAuthenticationS3,
    GS: EditAuthenticationGoogleCloud,
    ABS: EditAuthenticationAzure,
  }[target as SourceIntegrationTarget];

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ zIndex: 1300 }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ width: '1400px' }}
      >
        <Box display="flex" alignItems="flex-start" flexDirection="column" gap={3}>
          <Box display="flex" gap={1.5} alignItems="center">
            <Box style={{ width: '40px', height: '40px' }}>{logo}</Box>
            <Typography variant="h2">
              {t('button.edit')} {title} {t('integrations.authentication')}
            </Typography>
          </Box>
          <Box
            p={4}
            display="flex"
            flexDirection="column"
            boxShadow="4px 6px 14px rgba(0, 0, 0, 0.14)"
            style={{ width: '720px' }}
            gap={2}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="m-strong">{t('integrations.integrationInformation')}</Typography>
              <Box p={2} pb={0} backgroundColor="gray-100">
                <GridTable>
                  <GridTable.Thead>
                    <GridTable.Tr px={1} backgroundColor="gray-100">
                      <GridTable.Th width="1fr">
                        <Typography variant="m-regular" color="gray-300">
                          {t('settings.billing.invoice.columns.status')}
                        </Typography>
                      </GridTable.Th>
                      <GridTable.Th width="2fr">
                        <Typography variant="m-regular" color="gray-300">
                          {t('users.table.name')}
                        </Typography>
                      </GridTable.Th>
                      <GridTable.Th width="3fr">
                        <Typography variant="m-regular" color="gray-300">
                          {t('integrations.bucket')}
                        </Typography>
                      </GridTable.Th>
                    </GridTable.Tr>
                  </GridTable.Thead>
                  <GridTable.Tbody gap={2}>
                    <GridTable.Tr px={1} backgroundColor="gray-100">
                      <GridTable.Td>
                        <StatusChip color={status === 'fail' ? 'failed' : 'success'}>
                          {status === 'success'
                            ? t('integrations.connected')
                            : t('autoLabel.cal.status.failed')}
                        </StatusChip>
                      </GridTable.Td>
                      <GridTable.Td>
                        <Typography variant="m-regular">{name}</Typography>
                      </GridTable.Td>
                      <GridTable.Td>
                        <Typography variant="m-regular">
                          {bucket}
                          {prefix && ` > ${prefix}`}
                        </Typography>
                      </GridTable.Td>
                    </GridTable.Tr>
                  </GridTable.Tbody>
                </GridTable>
              </Box>
            </Box>
            <EditAuthencicationComponent
              prefix={prefix}
              bucket={bucket}
              setParams={params => setParams(params)}
              s3delegateInfo={s3delegateInfo}
            />
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <ExternalLink
                variant="m-regular"
                display="flex"
                alignItems="center"
                gap={0.5}
                target="_blank"
                rel="noopener noreferrer"
                href={docsLink}
                as="a"
              >
                {t('shared.document')}
              </ExternalLink>
              <Button
                variant="strong-fill"
                color="primary"
                onClick={handleApply}
                disabled={!params}
              >
                {t('button.apply')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box position="absolute" top="0" style={{ zIndex: 1301, right: '80px' }}>
          <IconButton icon={Clear} size="m" variant="text" onClick={close} />
        </Box>
      </Box>
    </Box>
  );
}
