import { getFeatureFlag } from '../contexts/FeatureFlagContext';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

type ProjectId = { projectId: string };

const getLabelingProgressSummary: ApiCall<
  ProjectId & {
    groupByDataset: boolean;
    summaryType: string;
    apiParams?: Params;
  },
  any
> = async args => {
  const { projectId, groupByDataset, summaryType, isGuest, urlInfo, apiParams } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  const formattedUrl = groupByDataset
    ? `${summaryType}-dataset/?${ServiceUtils.getParamString(apiParams)}`
    : `${summaryType}/`;

  const url = `/projects/${projectId}/${formattedUrl}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });
  return res.data;
};

const getAnnotationCounts: ApiCall<
  ProjectId & { summaryType: string; apiParams?: Params },
  any
> = async args => {
  const { projectId, summaryType, isGuest, urlInfo, apiParams } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  const formattedUrl = apiParams
    ? `${summaryType}-dataset/?${ServiceUtils.getParamString(apiParams)}`
    : `${summaryType}/`;

  const url = `/projects/${projectId}/${formattedUrl}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });

  return res.data;
};

const getTopLabels: ApiCall<ProjectId & { apiParams?: any }, any> = async args => {
  const { projectId, isGuest, urlInfo, apiParams } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  const path = apiParams ? `top-labels/?${ServiceUtils.getParamString(apiParams)}` : 'top-labels/';
  const url = `/projects/${projectId}/${path}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });

  return res.data.data;
};

const getStatsHistogram: ApiCall<ProjectId & { apiParams?: any }, any> = async args => {
  const { projectId, isGuest, urlInfo, apiParams } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;

  const path = apiParams
    ? `stats-histogram/?${ServiceUtils.getParamString(apiParams)}`
    : 'stats-histogram/';

  const url = `/projects/${projectId}/${path}`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });

  return res.data;
};

const postProgressSummarySync: ApiCall<ProjectId, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  if (!useAnalyticsProxy) return;

  const url = `/projects/${projectId}/progress-summaries/sync/`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });

  return res;
};

const getPropertyCount: ApiCall<ProjectId & { apiParams?: Params }, any> = async args => {
  const { projectId, isGuest, urlInfo, apiParams } = args;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  const params = `${ServiceUtils.getParamString(apiParams)}`;
  const url = `/projects/${projectId}/object-property-count/?${params}`;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: useAnalyticsProxy ? 2 : undefined,
  });

  return res.data['data'];
};

export default {
  getLabelingProgressSummary,
  getAnnotationCounts,
  getTopLabels,
  getStatsHistogram,
  postProgressSummarySync,
  getPropertyCount,
};
