import {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { ChevronDown, Hide, Show } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../types/curationTypes';
import { CurateAnnotation } from '../../../../../services/DatasetService';
import { getAnnotationIcon } from './utils';

type AnnotationsInfo = { _width: number; _height: number; [key: string]: any };
type FormattedAnnotation = {
  type: CurateAnnotationType;
  id: string;
  name: string;
  count: number;
  annotations: AnnotationsInfo[];
};

export default function AnnotationsCabinetCollapse({
  index,
  metadataEntries,
  annotation,
  filteredAnnotationsIds,
  setFilteredAnnotationsIds,
  objectAnnotationId,
  clickedAnnotation,
  children,
}: PropsWithChildren<{
  index: number;
  metadataEntries: [string, any][];
  filteredAnnotationsIds: string[];
  setFilteredAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  annotation: FormattedAnnotation;
  objectAnnotationId?: string;
  clickedAnnotation: CurateAnnotation | null;
}>) {
  const [open, setOpen] = useState(
    annotation.annotations.filter(list => list.id === objectAnnotationId).length > 0,
  );

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const hasUnfilteredAnnotation = useMemo(
    () => annotation.annotations.filter(list => !filteredAnnotationsIds.includes(list.id)),
    [filteredAnnotationsIds, annotation],
  );

  const IncludeObjectAnnotatin = useMemo(
    () => annotation.annotations.filter(list => list.id === objectAnnotationId),
    [objectAnnotationId, annotation],
  );

  const isClickedObjectAnnotation = useMemo(
    () => annotation.annotations.filter(list => list.id === clickedAnnotation?.id),
    [clickedAnnotation, annotation],
  );

  return (
    <Box
      bb="1px solid"
      borderColor="white"
      backgroundColor={isClickedObjectAnnotation.length > 0 ? 'primary-100' : 'gray-100'}
    >
      <Box
        display="flex"
        alignItems="center"
        key={annotation.id}
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setOpen(!open)}
        py={1}
        px={1.5}
        pb={index < metadataEntries.length ? 1 : 0}
      >
        <Box style={{ ...textEllipsisStyle }} display="flex" alignItems="center" gap={1}>
          <Icon
            icon={getAnnotationIcon(annotation.type)}
            color={
              hasUnfilteredAnnotation.length > 0
                ? IncludeObjectAnnotatin.length > 0
                  ? 'primary-400'
                  : 'gray-400'
                : 'gray-300'
            }
          />
          <Box display="flex">
            <Typography
              variant="m-strong"
              color={
                hasUnfilteredAnnotation.length > 0
                  ? IncludeObjectAnnotatin.length > 0
                    ? 'primary-400'
                    : 'gray-400'
                  : 'gray-300'
              }
            >
              {annotation.name}
            </Typography>
            <Typography
              variant="m-strong"
              ml={0.5}
              color={
                hasUnfilteredAnnotation.length > 0
                  ? IncludeObjectAnnotatin.length > 0
                    ? 'primary-400'
                    : 'gray-400'
                  : 'gray-300'
              }
            >{`(${annotation.count})`}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            mr={2}
            onClick={e => {
              setFilteredAnnotationsIds(prev => {
                const annotationIds = annotation.annotations.map(list => list.id);
                const newList = prev.filter(list => !annotationIds.includes(list));
                if (hasUnfilteredAnnotation.length === annotationIds.length)
                  return [...prev, ...annotationIds];
                else if (
                  hasUnfilteredAnnotation.length > 0 &&
                  hasUnfilteredAnnotation.length < annotationIds.length
                )
                  return [...newList, ...annotationIds];
                else return [...newList];
              });
              e.stopPropagation();
            }}
          >
            <Icon
              icon={hasUnfilteredAnnotation.length > 0 ? Show : Hide}
              color={hasUnfilteredAnnotation.length > 0 ? 'gray-400' : 'gray-300'}
            />
          </Box>
          <Box
            display="flex"
            style={{
              transition: 'all 0.1s ease-in-out',
              ...(open ? {} : { transform: 'rotate(-90deg)' }),
            }}
          >
            <Icon
              icon={ChevronDown}
              color={hasUnfilteredAnnotation.length > 0 ? 'gray-400' : 'gray-300'}
            />
          </Box>
        </Box>
      </Box>
      <Collapse open={open}>{children}</Collapse>
    </Box>
  );
}
