import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Clear } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { IconButton } from '@superb-ai/ui';

import { QUIT_CREATE_PROJECT } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { EDIT_PROJECT_PATH } from './paths';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: '50px',
    right: '70px',
  },
}));

// eslint-disable-next-line
const CloseButton = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const { match } = props;
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const { openModal, closeModal } = useAlertModal();
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;

  const handleClick = () => {
    openModal({
      ...QUIT_CREATE_PROJECT({ t }),
      mainButton: {
        text: t('button.quit'),
        onClick: () => {
          if (isEditPage) {
            routeInfo.history.push(
              `/${routeInfo.urlMatchInfo.accountName}/label/project/${match.params.projectId}/settings`,
            );
          } else {
            routeInfo.history.push(`/${routeInfo.urlMatchInfo.accountName}/label/projects`);
          }
          closeModal();
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: closeModal,
      },
    });
  };

  return (
    <MUI.Box className={classes.box}>
      <IconButton variant="text" icon={Clear} size="xl" onClick={handleClick} />
    </MUI.Box>
  );
};

export default CloseButton;
