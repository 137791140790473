import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import HistoryItem, { HistoryItemProps } from './HistoryItem';

export default function HistoryItemContainer({
  id,
  loadHistory,
  infoBoardContainerBoxProps,
  InfoBoard,
  deleteHistory,
  cancelSource,
  type,
}: HistoryItemProps) {
  const itemRef = useRef<HTMLImageElement>();
  const [intersect, setIntersect] = useState(false);

  const callback = useCallback(entries => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIntersect(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callback);
    if (itemRef.current) observer.observe(itemRef.current);
    return () => {
      observer.disconnect();
    };
  }, [callback]);

  return (
    <Box ref={itemRef} minHeight={184}>
      {intersect && (
        <HistoryItem
          id={id}
          loadHistory={loadHistory}
          deleteHistory={deleteHistory}
          cancelSource={cancelSource}
          InfoBoard={InfoBoard}
          infoBoardContainerBoxProps={infoBoardContainerBoxProps}
          type={type}
        />
      )}
    </Box>
  );
}
