import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { Badge, Box, Card, Icon, Typography } from '@superb-ai/norwegian-forest';
import { useQuery } from '@tanstack/react-query';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { getUrl } from '../../../../routes/util';
import IntegrationsService from '../../../../services/IntegrationsService';
import getContents from './contents';
import { IntegrationDetailRoute } from './detail/Route';
import { TargetType } from './types';

const IntegrationItem: React.FC<{ target: TargetType }> = ({ target }) => {
  const { t, i18n } = useTranslation();
  const contents = useMemo(() => {
    return getContents(t, i18n.language)[target];
  }, [t, target]);

  const { title, summary, logo } = contents;
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { history } = routeInfo;
  const match = useRouteMatch();

  const integrationsQuery = useQuery({
    queryKey: ['integrations', routeInfo.urlMatchInfo.accountName, target],
    queryFn: () =>
      IntegrationsService.getIntegrationsByMethod({
        methodName: target,
        urlInfo: routeInfo.urlMatchInfo,
        isGuest: authInfo.isGuest,
      }),
    select: data => data.data,
  });

  const hasIntegrations = Boolean(integrationsQuery.data?.length);

  const handleClick = (target: TargetType) => () => {
    history.push(getUrl([match.url, IntegrationDetailRoute.path], { type: target }));
  };

  return (
    <Card
      p={2.5}
      pl={2}
      display="flex"
      alignItems="flex-start"
      onClick={handleClick(target)}
      position="relative"
    >
      {hasIntegrations && (
        <Box position="absolute" top="12px" left="12px">
          <Badge color="success" size="xxs">
            <Icon name="check" />
          </Badge>
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <Box width="72px">{logo}</Box>
      </Box>
      <Box
        px={1}
        py={0.5}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography variant="headline6">{title}</Typography>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="body3">{summary}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default IntegrationItem;
