import React, { useEffect } from 'react';

import * as MUI from '@mui/material';

import FilterWithSearch from './FilterWithSearch';
import { FilterProps } from './types';

type OneValue = { value: string };
type Value = OneValue[];

interface Props extends FilterProps<Value> {
  options?: string[];
}

const DefaultValueLeft = { value: '0' };
const DefaultValueRight = { value: '100' };

const FilterNumber: React.FC<Props> = props => {
  const {
    index,
    objKey,
    value,
    isCurrentRow,
    condition,
    options = [],
    onValueChange,
    onChangeCurrentRow,
  } = props;
  const [leftValue = DefaultValueLeft, rightValue = DefaultValueRight] = value;

  useEffect(() => {
    // adjust value according to condition
    if (value.length) return;
    if (condition === 'is in the range') {
      onValueChange(index, objKey, [leftValue, rightValue]);
    } else {
      onValueChange(index, objKey, [leftValue]);
    }
    // eslint-disable-next-line
  }, [condition]);

  const handleChangeLeftValue = (index: number, objKey: string, value: OneValue) => {
    onValueChange(index, objKey, [value, rightValue]);
  };

  const handleChangeRightValue = (index: number, objKey: string, value: OneValue) => {
    onValueChange(index, objKey, [leftValue, value]);
  };

  const renderOptions = options.map(value => ({ value, label: `${value}` }));

  return (
    <MUI.Grid container alignItems="center" spacing={1}>
      <MUI.Grid item style={{ flex: 1 }}>
        <FilterWithSearch
          isMulti={false}
          index={index}
          objKey="options"
          options={renderOptions}
          value={renderOptions.filter(opt => opt.value === leftValue.value)}
          onValueChange={handleChangeLeftValue}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
          closeMenuOnSelect
        />
      </MUI.Grid>
      {condition === 'is in the range' && (
        <>
          <MUI.Grid item style={{ color: isCurrentRow ? '#ff625a' : '#ccc' }}>
            –
          </MUI.Grid>
          <MUI.Grid item style={{ flex: 1 }}>
            <FilterWithSearch
              isMulti={false}
              index={index}
              objKey="options"
              options={renderOptions}
              value={renderOptions.filter(opt => opt.value === rightValue.value)}
              onValueChange={handleChangeRightValue}
              isCurrentRow={isCurrentRow}
              onChangeCurrentRow={onChangeCurrentRow}
              closeMenuOnSelect
            />
          </MUI.Grid>
        </>
      )}
    </MUI.Grid>
  );
};

export default FilterNumber;
