import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Box, Chip, Icon, IconButton, Modal, Typography } from '@superb-ai/norwegian-forest';
import axios from 'axios';
import { capitalize } from 'lodash';
import dynamic from 'next/dynamic';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import AssetsService from '../../../../services/AssetsService';
import LabelsService from '../../../../services/LabelsService';
import { GeoJSONPolygon } from '../../../../types/advancedAIFeaturesTypes';
import { LabelData } from '../../../../types/labelTypes';
import { MislabelDetectionResultsPerClass } from '../../../../types/mislabelDetectionTypes';
import ProjectUtils from '../../../../utils/ProjectUtils';
import { getIconName } from '../autoLabel/settings/modal/components';

const PixiDetailImage = dynamic({
  loader: () => import('../PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});

type Props = {
  classInfo: MislabelDetectionResultsPerClass;
  isOpen: boolean;
  onClose: () => void;
  index?: number;
  setIndex: Dispatch<SetStateAction<number | undefined>>;
};
export default function DetailModal({ index, setIndex, classInfo, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  const [labelInfo, setLabelInfo] = useState<LabelData>();

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();

  const isPrevDisabled = !index || loading;
  const isNextDisabled = (index !== undefined && index + 1 >= classInfo.results.length) || loading;
  const results = classInfo.results;
  const info = results && results[index || 0];

  function prev() {
    if (isPrevDisabled) return;
    setIndex(index - 1);
  }

  function next() {
    if (isNextDisabled || index === undefined) return;
    setIndex(index + 1);
  }

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'ArrowLeft') {
        prev();
      } else if (key === 'ArrowRight') {
        next();
      }
    };
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
    // eslint-disable-next-line
  }, [index, loading]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await LabelsService.getLabel({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: info.label_id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setLabelInfo(response);

      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();
      const assetRes = await AssetsService.getAsset({
        cancelToken: source.token,
        assetId: response.asset.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const url = assetRes.info.url;
      // setImageByUrl(url);
      setUrl(url);
      setLoading(false);
    })();
  }, [info]);

  const handleClose = () => {
    onClose();
  };

  const getCoordinate = useCallback(() => {
    if (info.annotation_type === 'polygon') {
      return info.annot_ori as GeoJSONPolygon;
    }
    return info.bbox;
  }, [info.annot_ori, info.annotation_type, info.bbox]);

  const annotations = useMemo(() => {
    return [
      {
        classId: classInfo.class_id,
        type: info.annotation_type,
        coordinate: getCoordinate(),
        id: info.mislabel_rank,
      },
    ];
  }, [classInfo.class_id, getCoordinate, info.annotation_type, info.mislabel_rank]);

  if (!labelInfo || !info) return <></>;

  return (
    <Modal
      open={isOpen}
      close={{
        onClose: handleClose,
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={
        <Box width="100%" display="flex">
          <Box flex={1} display="flex" alignItems="center">
            <Typography variant="headline4" themedColor="primary">
              {labelInfo.asset.key || ''}
            </Typography>
            <Box ml="auto">
              <IconButton
                size="s"
                color="grey"
                icon="arrowLeft"
                disabled={isPrevDisabled}
                onClick={prev}
              />
              <IconButton
                size="s"
                color="grey"
                icon="arrowRight"
                disabled={isNextDisabled}
                onClick={next}
              />
            </Box>
          </Box>
          <Box width={276} />
        </Box>
      }
    >
      <Box
        width="80vw"
        maxWidth="1800px"
        minWidth="1124px"
        height="600px"
        px={4}
        display="flex"
        style={{ marginBottom: '-12px' }}
      >
        <Box
          flex={1}
          style={{
            backgroundColor: '#F5F5F5',
            width: 'calc(100% - 304px)',
            height: '600px',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          {loading || !url ? (
            <Icon name="loadingSpinner" size="40px" />
          ) : (
            <PixiDetailImage imgUrl={url} alt={classInfo.class_name} annotations={annotations} />
          )}
        </Box>
        <Box width={304} pl={2}>
          <Box bb>
            <Box py={1} display="flex" alignItems="center" gap="4px">
              <Icon name={getIconName(info.annotation_type)} color="primary" />
              <Typography variant="headline6">{capitalize(classInfo.class_name)}</Typography>
            </Box>
            {info.properties && info.properties.length > 0 && (
              <Box pb={2}>
                <Typography variant="headline7">Properties</Typography>
                <Box pt={0.5} />
                {info.properties.map(property => (
                  <Box
                    key={property.property_name}
                    display="grid"
                    alignItems="center"
                    gap="4px"
                    gridTemplateColumns="20px 1fr 1fr"
                  >
                    <Box>
                      <Badge size="xxxs" color="cloud">
                        <Icon
                          name={ProjectUtils.getObjectPropertiesAndCategorizationIcon(
                            property.type,
                          )}
                        />
                      </Badge>
                    </Box>
                    <Typography variant="body3">{property.property_name}</Typography>
                    <Typography variant="body3" textAlign="right">
                      {property.option_name}
                    </Typography>{' '}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box>
            <Box py={1}>
              <Typography variant="headline6">Label Information</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Data Key</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.asset.key}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Status</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.asset.key}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Assignee</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.lastUpdatedBy}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Last Updated At</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.lastUpdatedAt}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Review Round</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.reviewRound}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Last Reviewed At</Typography>
              <Box pt={0.5} />
              <Typography variant="body3">{labelInfo.lastReviewedAt}</Typography>
            </Box>
            <Box py={1}>
              <Typography variant="headline7">Tags</Typography>
              <Box pt={0.5} display="flex" gap="4px">
                {labelInfo.tags.map(tag => (
                  <Chip key={tag.id} color={tag.info?.color}>
                    {tag.name}
                  </Chip>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
