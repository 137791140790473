import { useTranslation } from 'react-i18next';

import { Download } from '@superb-ai/icons';
import { IconButton, Tooltip } from '@superb-ai/ui';

type Props = {
  isDisabled: boolean;
  handleDownload: () => void;
};

export const DownloadButton = (props: Props) => {
  const { handleDownload, isDisabled } = props;
  const { t } = useTranslation();
  return (
    <Tooltip content={t('curate.analytics.action.downloadCSV')} placement="bottom">
      <IconButton
        color="black"
        disabled={isDisabled}
        icon={Download}
        onClick={() => handleDownload && handleDownload()}
        variant="text"
      />
    </Tooltip>
  );
};
