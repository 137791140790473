import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/norwegian-forest';
import { LoadingIndicator } from '@superb-ai/ui';
import { isEmpty } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService from '../../../../services/LabelsService';
import Emoji from '../../../elements/Emoji';
import ExportDialogStatusList from './ExportDialogStatusList';
import ExportDialogStatusTable from './ExportDialogStatusTable';

interface OnLoadResult {
  submittedLabelsCount: number;
  skippedLabelsCount: number;
  labelingInProgressCount: number;
}

interface Props {
  filterApiParams: any;
  checkedLabels: any;
  isAllLabelsChecked: boolean;
  onLoad?: (counts: OnLoadResult) => void;
  showChangeTo?: 'labelingInProgressCount' | 'submittedLabelsCount' | 'skippedLabelsCount';
  title?: string;
  direction?: 'row' | 'column';
  variant?: 'table' | 'compact';
}

const ExportDialog = ({
  filterApiParams,
  checkedLabels,
  isAllLabelsChecked,
  onLoad,
  showChangeTo,
  title,
  direction = 'column',
  variant = 'table',
}: Props) => {
  const { t } = useTranslation();
  const [skippedLabelsCount, setSkippedLabelsCount] = useState(0);
  const [labelingInProgressCount, setLabelingInProgressCount] = useState(0);
  const [submittedLabelsCount, setSubmittedLabelsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const getApiParams = (status: string[]) => {
    if (!isEmpty(checkedLabels) && !isAllLabelsChecked) {
      return { idIn: checkedLabels, statusIn: status };
    }
    return {
      ...filterApiParams,
      statusIn: status,
    };
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    const fetchStatuses: string[] =
      filterApiParams.statusIn && filterApiParams.statusIn.length
        ? filterApiParams.statusIn
        : ['SUBMITTED', 'SKIPPED', 'WORKING'];
    const promises = fetchStatuses.map(status =>
      (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
        params: getApiParams([status]),
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      }),
    );
    Promise.all(promises).then(results => {
      if (!isMounted) return;

      const counts = { submittedLabelsCount: 0, skippedLabelsCount: 0, labelingInProgressCount: 0 };

      const submittedIndex = fetchStatuses.indexOf('SUBMITTED');
      if (submittedIndex !== -1) counts.submittedLabelsCount = results[submittedIndex];
      setSubmittedLabelsCount(counts.submittedLabelsCount);

      const workingIndex = fetchStatuses.indexOf('WORKING');
      if (workingIndex !== -1) counts.labelingInProgressCount = results[workingIndex];
      setLabelingInProgressCount(counts.labelingInProgressCount);

      const skippedIndex = fetchStatuses.indexOf('SKIPPED');
      if (skippedIndex !== -1) counts.skippedLabelsCount = results[skippedIndex];
      setSkippedLabelsCount(counts.skippedLabelsCount);

      setIsLoading(false);
      if (onLoad) {
        onLoad(counts);
      }
    });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [
    // skippedLabelsCount, submittedLabelsCount, labelingInProgressCount,
    filterApiParams,
    checkedLabels,
    isAllLabelsChecked,
  ]);

  const counts = { labelingInProgressCount, submittedLabelsCount, skippedLabelsCount };

  const afterChangeCounts = {
    labelingInProgressCount: 0,
    submittedLabelsCount: 0,
    skippedLabelsCount: 0,
  };
  if (showChangeTo) {
    afterChangeCounts[showChangeTo] =
      labelingInProgressCount + submittedLabelsCount + skippedLabelsCount;
  }

  return (
    <>
      {isLoading ? (
        <LoadingIndicator
          size={variant === 'table' ? 'm' : 'xs'}
          style={{ height: title ? '80px' : variant === 'table' ? '57px' : '18px' }}
        />
      ) : (
        <>
          {title && (
            <Box mb={1}>
              <Typography variant="label">{title}</Typography>
            </Box>
          )}

          {variant === 'table' && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <ExportDialogStatusTable counts={counts} direction={direction} />
              {showChangeTo && (
                <>
                  <Box mr={1.25} ml={2}>
                    <Emoji symbol="👉" label="After the change" />
                  </Box>
                  <ExportDialogStatusTable counts={afterChangeCounts} direction={direction} />
                </>
              )}
            </Box>
          )}

          {variant === 'compact' && (
            <>
              <ExportDialogStatusList counts={counts} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ExportDialog;
