import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronBigDown, ChevronBigRight } from '@superb-ai/icons';
import { Box, IconButton, NamedColor } from '@superb-ai/ui';

import PercentageBarChart from '../charts/PercentageBarChart';
import { ClassificationKeyDatum, ClassificationType } from '../types';
import { getSuiteColor } from '../utils/colorScale';
import { ClassificationNameCell } from './ClassificationNameCell';
import { ClassificationTableValueDetail } from './ClassificationTableValueDetail';
import { CountCell } from './CountCell';
import { getClassificationHeaders } from './utils';

export function ClassificationTableRow({
  initialOpen = false,
  datum,
  comparedDatum,
  totalImages,
  totalComparedImages,
  isCompareMode,
}: {
  initialOpen: boolean;
  datum: ClassificationKeyDatum;
  comparedDatum?: ClassificationKeyDatum;
  totalImages: number;
  totalComparedImages?: number;
  isCompareMode: boolean;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);
  const headers = getClassificationHeaders(t);
  const [hoverOnRow, setHoverOnRow] = useState<boolean>(false);
  const color = getSuiteColor('classification') as NamedColor;
  const comparedColor = 'red-500';
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        style={{ minHeight: '34px' }}
        onMouseEnter={() => setHoverOnRow(true)}
        onMouseLeave={() => setHoverOnRow(false)}
        cursor="pointer"
        p={1}
        backgroundColor={hoverOnRow ? 'gray-opacity-100' : undefined}
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center" style={{ width: headers[0]?.width }} gap={0.5}>
          <IconButton
            variant="text"
            size="xs"
            color="gray"
            icon={isOpen ? ChevronBigDown : ChevronBigRight}
            onClick={handleClick}
          />
          <ClassificationNameCell name={datum?.name} type={datum?.type as ClassificationType} />
        </Box>
        <Box style={{ flex: 1 }} display="flex" flexDirection="column" gap={0.5}>
          <PercentageBarChart
            showLegend={false}
            chartDatum={[
              {
                key: datum?.name + '-images',
                name: datum?.name,
                total: totalImages,
                value: datum?.imageCount,
                share: datum?.imageShare || 0,
                barColor: color,
              },
            ]}
          />
          {isCompareMode && (
            <PercentageBarChart
              showLegend={false}
              chartDatum={[
                {
                  key: datum?.name + '-images',
                  name: datum?.name,
                  total: totalComparedImages || 0,
                  value: comparedDatum?.imageCount || 0,
                  barColor: comparedColor,
                  share: comparedDatum?.imageShare,
                },
              ]}
            />
          )}
        </Box>
        <CountCell
          width={headers[2]?.width as string}
          count={datum?.imageShare || 0}
          {...(isCompareMode ? { comparedCount: comparedDatum?.imageShare || 0 } : {})}
        />
        <CountCell
          width={headers[3]?.width as string}
          count={datum.imageCount}
          {...(isCompareMode ? { comparedCount: comparedDatum?.imageCount || 0 } : {})}
        />
      </Box>
      {isOpen && <ClassificationTableValueDetail classificationKey={datum?.name} />}
    </Box>
  );
}
