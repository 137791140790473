import React from 'react';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';

// eslint-disable-next-line
const ObjectClassItemEditableText = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { objectClass, index } = props;

  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Object class name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const sameHierarchyNameList = newProjectInfo.objectClasses
      .filter((x: any) => {
        return x.annotationType === objectClass.annotationType;
      })
      .map((item: any) => item.name);

    return parentHelper.checkNameRuleForObjectClass({
      t,
      type: 'Object class name',
      prevValue: objectClass.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
      annotationType: objectClass.annotationType,
    });
  };

  const editName = (value: string) => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    nextObjectClasses[index].name = value;
    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  return (
    <EditableText
      text={objectClass.name}
      maxWidth="160px"
      fontStyles={{
        fontSize: '13px',
        color: '#797979',
        fontWeight: 500,
      }}
      parseValue={parseValue}
      checkRule={checkNameRule}
      editText={editName}
      isMount={newProjectInfo.createState === 'OBJECT_CLASS'}
    />
  );
};

export default ObjectClassItemEditableText;
