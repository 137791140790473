import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { parse } from '@superb-ai/dql';
import { LoadingSpinner } from '@superb-ai/icons';
import { Box, Icon, Tab, TabList, Tabs, Tooltip, Typography } from '@superb-ai/ui';

import { useDatasetContext } from '../../../contexts/DatasetContext';
import { useEmbeddingMonitoringContext } from '../../../contexts/EmbeddingMonitoringContext';
import { useDatasetModalContext } from '../../../contexts/ModalContext';
import { useQueryContext } from '../../../contexts/QueryContext';
import { IAdvancedQuery, ISelectedQuery } from '../../../types/querySchemaTypes';
import {
  ANALYTICS_VIEW,
  GRID_VIEW,
  IMAGE_SCOPE,
  OBJECT_SCOPE,
  SCOPE,
  ScopeMode,
  VIEW,
  ViewMode,
} from '../../../types/viewTypes';
import { getSearchQueryRoute } from '../../../utils/routeUtils';
import AdvancedQuery from './advancedQuery/AdvancedQuery';
import AdvancedQueryModal from './modal/AdvancedQueryModal';

export default function DataSectionContainer({
  isLoading,
  isEmpty,
  ViewContents,
  EmptyView,
}: {
  isLoading: boolean;
  isEmpty: boolean;
  ViewContents: JSX.Element;
  EmptyView: JSX.Element;
}): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();

  const { datasetInfo, searchFieldMapping } = useDatasetContext();
  const { setQueryError } = useQueryContext();

  const { location: search } = history;
  const params = new URLSearchParams(search.search);
  const { queryModalIsOpen, setQueryModalIsOpen } = useDatasetModalContext();
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;
  const scope = (params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;

  const hasAnalyticsView = scope === IMAGE_SCOPE;

  const { objectTotalCount, canUseEmbeddingFeatures } = useEmbeddingMonitoringContext();
  const isObjectScopeDisabled = objectTotalCount <= 0;

  const [memoAdvancedQuery, setMemoAdvancedQuery] = useState<IAdvancedQuery>({
    query: [],
    boxIndex: 0,
    children: [],
    operator: 'AND',
  });
  const [memoSelectedQueryList, setMemoSelectedQueryList] = useState<ISelectedQuery[]>([]);
  const [isApplied, setIsApplied] = useState<boolean>(false);

  useEffect(() => {
    if (!scope) {
      history.replace(getSearchQueryRoute(history, { scope: IMAGE_SCOPE }));
      return;
    }
    // route to other view if the view is disabled
    if (!hasAnalyticsView && view === ANALYTICS_VIEW) {
      history.replace(getSearchQueryRoute(history, { view: GRID_VIEW }));
      return;
    }
    if (isObjectScopeDisabled && scope === OBJECT_SCOPE) {
      history.replace(getSearchQueryRoute(history, { scope: IMAGE_SCOPE }));
      return;
    }
  }, [search]);

  const handleMemoAdvancedQuery = useCallback(
    (advancedQuery: IAdvancedQuery, selectedQueryList: ISelectedQuery[]) => {
      setMemoAdvancedQuery(advancedQuery);
      setMemoSelectedQueryList(selectedQueryList);
    },
    [setMemoAdvancedQuery, setMemoSelectedQueryList],
  );

  const handleInputQueryCheck = useCallback(
    async (queryString: string) => {
      try {
        parse(queryString, {
          searchScope: scope === IMAGE_SCOPE ? 'image' : 'annotation',
          aliasMapping: searchFieldMapping,
        });
        setQueryError('');
      } catch (e) {
        if (e instanceof Error) {
          setQueryError(e?.message);
        } else setQueryError('Invalid query!');
      }
      if (queryString.length === 0) setQueryError('');
    },
    [searchFieldMapping, setQueryError],
  );

  const handleCheckQueryString = useCallback(
    async (queryString: string) => {
      if (queryString.length > 0) {
        try {
          parse(queryString, {
            searchScope: scope === IMAGE_SCOPE ? 'image' : 'annotation',
            aliasMapping: searchFieldMapping,
          });
          setQueryError('');
        } catch (e) {
          if (e instanceof Error) {
            setQueryError(e?.message);
          } else setQueryError('Invalid query!');
        }
      } else setQueryError('');
      setIsApplied(true);
    },
    [searchFieldMapping, setQueryError, setIsApplied],
  );

  if (!datasetInfo) return <></>;

  const Contents = () => {
    if (isLoading) {
      return (
        <Box display="flex" p={1} justifyContent="center" alignItems="center">
          <Icon icon={LoadingSpinner} />
        </Box>
      );
    }

    if (isEmpty) {
      return EmptyView;
    }

    function handleChangeScopeMode(value: string) {
      if (value === scope) return;
      history.push(getSearchQueryRoute(history, { scope: value }));
    }

    return (
      <>
        <Box display="flex" alignItems="center" gap={1} mb={1} pt={2} pl={4} pr={2}>
          <Box>
            <Tabs onSelectTab={handleChangeScopeMode} selectedId={scope}>
              <TabList color="gray">
                <Tab id={IMAGE_SCOPE}>
                  <Typography>{t('curate.dataset.views.image')}</Typography>
                </Tab>
                <Tooltip
                  hideOnEmptyContent
                  content={
                    isObjectScopeDisabled
                      ? t('curate.dataset.views.objectDisabledMessage')
                      : undefined
                  }
                >
                  <span>
                    <Tab id={OBJECT_SCOPE} disabled={isObjectScopeDisabled}>
                      <Typography>{t('curate.dataset.views.object')}</Typography>
                    </Tab>
                  </span>
                </Tooltip>
              </TabList>
            </Tabs>
          </Box>
          <AdvancedQuery
            setQueryModalIsOpen={setQueryModalIsOpen}
            handleCheckQueryString={handleCheckQueryString}
            handleInputQueryCheck={handleInputQueryCheck}
          />
        </Box>
        {ViewContents}
      </>
    );
  };

  return (
    <>
      {Contents()}
      {queryModalIsOpen && (
        <AdvancedQueryModal
          open={queryModalIsOpen}
          handleClose={(advancedQuery, selectedQueryList) => {
            handleMemoAdvancedQuery(advancedQuery, selectedQueryList);
            setQueryModalIsOpen(false);
          }}
          handleApply={(
            queryString: string,
            advancedQuery: IAdvancedQuery,
            selectedQueryList: ISelectedQuery[],
          ) => {
            handleMemoAdvancedQuery(advancedQuery, selectedQueryList);
            handleCheckQueryString(queryString);
            setQueryModalIsOpen(false);
            history.push(getSearchQueryRoute(history, { query: queryString }));
          }}
          memoAdvancedQuery={memoAdvancedQuery}
          memoSelectedQueryList={memoSelectedQueryList}
          isApplied={isApplied}
        />
      )}
    </>
  );
}
