import React from 'react';
import { useTranslation } from 'react-i18next';
import { createFilter } from 'react-select';

import { makeStyles } from '@mui/styles';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { Option } from '../../../../types/selectTypes';
import FilterWithSearch from './components/FilterWithSearch';
import { FilterTypeProps } from './types';

const useStyles = makeStyles(() => ({
  textField: {
    '& .AutoSelect__control': {
      width: '400px',
    },
  },
}));

interface Props extends FilterTypeProps {
  allUsersVisible?: boolean;
  value?: Option[];
}

const FilterAssigneeWithSearch: React.FC<Props> = ({
  index,
  onValueChange,
  isCurrentRow,
  onChangeCurrentRow,
  filter,
  value,
  allUsersVisible = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const projectInfo = useProjectInfo();

  const options = [
    ...(allUsersVisible
      ? [
          {
            label: t('all'),
            options: [{ label: t('labels.allUsers'), value: 'all users' }],
          },
        ]
      : []),
    ...projectInfo.assigneeOptions.map(
      ({ label, options }: { label: string; options: Option[] }) => ({
        options,
        label: t(`projectMembers.role.${label?.toLowerCase()}`),
      }),
    ),
  ];

  return (
    <FilterWithSearch
      className={classes.textField}
      index={index}
      objKey="options"
      filterBy={filter.filterBy}
      options={options}
      value={value || filter.options}
      onValueChange={onValueChange}
      isCurrentRow={isCurrentRow}
      onChangeCurrentRow={onChangeCurrentRow}
      filterOption={createFilter({
        trim: false,
      })}
    />
  );
};

export default FilterAssigneeWithSearch;
