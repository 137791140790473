import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TextEllipsis, Typography } from '@superb-ai/norwegian-forest';

import { Curation } from '../../../../types/curationTypes';
import { MislabelDetection } from '../../../../types/mislabelDetectionTypes';
import { SemanticSearch } from '../../../../types/semanticsearchTypes';
import { formatDate } from '../../../../utils/date';

type Props = {
  info: MislabelDetection;
};

export default function MislabelDetectionInfo({
  info,
}: {
  info: MislabelDetection | Curation | SemanticSearch;
}): JSX.Element {
  const { t } = useTranslation();
  const mislabelDetectionInfo = info as MislabelDetection;

  return (
    <>
      <Box
        display="grid"
        gap="8px"
        gridTemplateColumns="minmax(160px, auto) minmax(160px, auto) minmax(40px, auto) minmax(120px, auto) minmax(80px, auto)"
      >
        <Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            {t('advancedAIFeatures.mislabelDetection.info.referenceSet')}
          </Typography>
          <Box mt={0.5}>
            <Typography variant="body3">
              <TextEllipsis text={mislabelDetectionInfo.referenceSet.name || 'Untitled export'} />
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            {t('advancedAIFeatures.mislabelDetection.info.querySet')}
          </Typography>
          <Box mt={0.5}>
            <Typography variant="body3">
              <TextEllipsis text={mislabelDetectionInfo.querySet.name || 'Untitled export'} />
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            {t('advancedAIFeatures.mislabelDetection.info.querySetLabelsCount')}
          </Typography>
          <Box mt={0.5}>
            <Typography variant="body3">
              <TextEllipsis
                text={mislabelDetectionInfo.querySet.labelCount.toLocaleString('en-US')}
              />
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            {t('advancedAIFeatures.mislabelDetection.info.createdBy')}
          </Typography>
          <Box mt={0.5}>
            <Typography variant="body3">
              <TextEllipsis text={mislabelDetectionInfo.createdBy} />
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body4" themedColor={['grey', 500]}>
            {t('advancedAIFeatures.mislabelDetection.info.createdDate')}
          </Typography>
          <Box mt={0.5}>
            <Typography variant="body3">
              <TextEllipsis text={formatDate(mislabelDetectionInfo.createdAt)} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
