import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import { Box, Modal, Typography } from '@superb-ai/norwegian-forest';
import { omit } from 'lodash';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AssetsService from '../../../services/AssetsService';
import CommandsService from '../../../services/CommandsService';
import { Option } from '../../../types/selectTypes';
import ProjectSelect from '../../elements/ProjectSelect';

type Props = {
  isAllChecked: boolean;
  variant: 'data' | 'dataset';
  isOpen: boolean;
  onClose: () => void;
  checkedList: string[];
  dataset?: any;
  totalCount: number;
  params: Record<string, any>;
  handleSearch: (inputValue: string) => Promise<{ label: string; value: string }[]>;
};

const AssignToProjectDialog: React.FC<Props> = props => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [selectedProject, setSelectedProject] = useState<string>();
  const commandContext = useLabelCommandContext();

  const [totalDataCount, setTotalDataCount] = useState(0);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = lokiFlag;

  const { isAllChecked, variant, isOpen, onClose, checkedList, totalCount, params } = props;

  const apiParams = isAllChecked
    ? omit(params, ['page', 'pageSize', 'ordering'])
    : {
        [variant === 'dataset' ? 'groupIn' : 'idIn']: checkedList,
      };

  async function fetchDatasetCount() {
    const { count } = await AssetsService.getAssetId({
      params: {
        ...apiParams,
        page: 1,
        pageSize: 0,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    return count;
  }

  useEffect(() => {
    if (!isOpen) return;
    if (variant === 'data') {
      setTotalDataCount(isAllChecked ? totalCount : checkedList.length);
    } else {
      fetchDatasetCount().then(setTotalDataCount);
    }
  }, [variant, totalCount, checkedList, isAllChecked, isOpen]);

  const handleChangeSelect = (option: SingleValue<Option>) => {
    setSelectedProject(option?.value);
  };

  const handleClose = () => {
    setSelectedProject(undefined);
    onClose();
  };

  const handleClickAction = async () => {
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'ASSETS_ASSIGN',
      params: apiParams,
      actionInfo: {
        projectId: selectedProject,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
    handleClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Typography variant="headline4" themedColor="primary">
            {t('assignToProjectDialog.title')}
          </Typography>
        }
        mainButton={{
          text: t('assignToProjectDialog.mainButton', {
            assetCount: totalDataCount.toLocaleString('en'),
          }),
          onClick: handleClickAction,
          disabled: !selectedProject,
        }}
        subButton={{
          text: t('assignToProjectDialog.cancelButton'),
          onClick: handleClose,
        }}
        close={{
          onClose: handleClose,
          hasCloseButton: true,
        }}
      >
        <Box mx={4} mt={2} mb={3}>
          <ProjectSelect onSelect={handleChangeSelect} />
        </Box>
      </Modal>
    </>
  );
};

export default AssignToProjectDialog;
