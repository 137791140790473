import { DiagnosisDetail } from '../../../../services/DiagnosisModelService';
import { MAX_DIAGNOSIS_QUOTA } from './const';
import { DiagnosisStatusEnum, isDiagnosisActive } from './diagnosis/types';

export function getActiveOrDeactivatingDiagnosisCount(diagnosisList: DiagnosisDetail[]) {
  return diagnosisList
    ? diagnosisList.filter(
        d => isDiagnosisActive(d.status) || d.status === DiagnosisStatusEnum.DEACTIVATING,
      ).length
    : 0;
}

export function isNotActivated(diagnosis: DiagnosisDetail | undefined) {
  return (
    diagnosis?.status === DiagnosisStatusEnum.INACTIVE
    // diagnosis?.status === DiagnosisStatusEnum.FAILED
  );
}

export function isMaxQuotaReached(diagnosisList: DiagnosisDetail[] | undefined) {
  if (!diagnosisList) return false;
  return getActiveOrDeactivatingDiagnosisCount(diagnosisList) >= MAX_DIAGNOSIS_QUOTA;
}

export function getDiagnosisFromListById(diagnosisList: DiagnosisDetail[], selectedId: string) {
  if (!diagnosisList) return undefined;
  return diagnosisList.find(d => d.id === selectedId);
}
