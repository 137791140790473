import { QueryObserverOptions, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isBoolean } from 'lodash';

import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { ImageFilterSchemaParams, useCurateDatasetService } from '../services/DatasetService';

export type Dependencies = {
  fromPublicDatasets?: boolean;
  page?: number;
  size?: number;
  sortBy?: string;
  sortOrder?: 'desc' | 'asc';
  nameContains?: string;
  nameIcontains?: string;
  expand?: string[];
  enabled?: boolean;
  queryRefetchOptions?: Pick<
    QueryObserverOptions,
    'refetchOnMount' & 'refetchOnWindowFocus' & 'refetchInterval'
  >;
  idIn?: string[];
};

export const datasetDetailQueryKey = 'curate-dataset';

export function useDatasetQuery({
  datasetId,
  expand,
  enabled,
  fromPublicDatasets,
  queryRefetchOptions,
}: {
  datasetId?: string;
  expand?: string[];
  enabled?: boolean;
  fromPublicDatasets?: boolean;
  queryRefetchOptions?: Pick<
    QueryObserverOptions,
    'refetchOnMount' & 'refetchOnWindowFocus' & 'refetchInterval'
  >;
}) {
  const { getDataset } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [datasetDetailQueryKey, datasetId, expand],
    queryFn: async () => {
      if (!datasetId) throw new Error('datasetId is undefined');
      return await getDataset({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        expand,
      });
    },
    enabled: enabled ?? !!datasetId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...queryRefetchOptions,
  });
}

export function useDatasetsQuery({
  page,
  size = 10,
  sortOrder = 'desc',
  sortBy = 'created_at',
  nameContains,
  nameIcontains,
  expand,
  enabled,
  fromPublicDatasets,
  queryRefetchOptions,
  idIn,
}: Dependencies) {
  const { getDatasetList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useQuery({
    queryKey: [
      'curate-datasets',
      page,
      size,
      sortBy,
      sortOrder,
      nameContains,
      nameIcontains,
      expand,
      fromPublicDatasets,
    ],
    queryFn: () => {
      return getDatasetList({
        ...{
          page,
          size,
          sort_order: sortOrder,
          sort_by: sortBy,
          name_contains: nameContains,
          fromPublicDatasets: isBoolean(fromPublicDatasets)
            ? fromPublicDatasets
            : showPublicDatasets,
          name_icontains: nameIcontains,
          id_in: idIn,
        },
        expand,
      });
    },
    enabled,
    ...queryRefetchOptions,
  });
}

export function useDatasetsInfiniteQuery({
  size = 10,
  sortOrder = 'desc',
  sortBy = 'created_at',
  nameContains,
  nameIcontains,
  enabled,
  fromPublicDatasets,
  queryRefetchOptions,
}: Dependencies) {
  const { getDatasetList } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  return useInfiniteQuery({
    queryKey: [
      'curate-datasets',
      size,
      sortBy,
      sortOrder,
      nameContains,
      nameIcontains,
      fromPublicDatasets,
    ],
    queryFn: ({ pageParam }) => {
      return getDatasetList({
        ...{
          page: pageParam,
          size,
          sort_order: sortOrder,
          sort_by: sortBy,
          name_contains: nameContains,
          fromPublicDatasets: isBoolean(fromPublicDatasets)
            ? fromPublicDatasets
            : showPublicDatasets,
          name_icontains: nameIcontains,
        },
      });
    },
    getNextPageParam: ({ count }, pages) => {
      return count > pages.length * size ? pages.length + 1 : undefined;
    },
    enabled,
    ...queryRefetchOptions,
  });
}

export function useImageFilterSchemaQuery({
  datasetId,
  sliceName,
  expand,
  fromPublicDatasets,
}: ImageFilterSchemaParams) {
  const { getImageFilterSchema } = useCurateDatasetService();
  return useQuery({
    queryKey: ['curate-image-filter-schema', datasetId, sliceName, expand],
    queryFn: async () => {
      const result = await getImageFilterSchema({
        datasetId,
        sliceName,
        expand,
        fromPublicDatasets: fromPublicDatasets,
      });
      return { ...result.image_filters };
    },
    enabled: Boolean(datasetId),
    staleTime: 6 * 10000, // TODO: 60 seconds
  });
}
