import { useTranslation } from 'react-i18next';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useAuthInfo } from '../contexts/AuthContext';
import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import UserApiKeyService, { TenantUserApiParams, UserApiKey } from '../services/UserApiKeyService';

export const useTenantUserApiKeysQuery = ({ limit = 100, ...queryParams }: TenantUserApiParams) => {
  const { accountName } = useAuthInfo();
  const params = useApiDefaultParams({
    origin: 'components/pages/account/advanced/PersonalAccessKey',
    accountName: `${accountName}`,
    limit,
    ...queryParams,
  });

  return useInfiniteQuery(
    ['tenantUserApiKeys', accountName, queryParams],
    ({ pageParam }) =>
      UserApiKeyService.getTenantUserApiKeys({
        ...params,
        lastEvaluatedKey: pageParam,
      }),
    {
      getNextPageParam: ({ lastEvaluatedKey }) => lastEvaluatedKey,
    },
  );
};

export const useUserApiKeysQuery = () => {
  const { accountName, email } = useAuthInfo();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/account/advanced/PersonalAccessKey',
  });
  const fetcher = async () => (await UserApiKeyService.getUserApiKeys(defaultParams)).data;

  return useQuery(['userApiKeys', accountName, email], fetcher, {
    refetchOnWindowFocus: false, // don't refetch, otherwise newly generated key info may be removed too early
  });
};

export const useUserApiKeyCreateMutation = () => {
  const { accountName, email } = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/account/advanced/PersonalAccessKey',
  });
  const queryClient = useQueryClient();
  const fetcher = async (data: Partial<UserApiKey>) =>
    (
      await UserApiKeyService.createUserApiKey({
        ...defaultParams,
        data,
      })
    ).data;

  return useMutation(fetcher, {
    onSuccess(data) {
      queryClient.setQueryData<UserApiKey[]>(
        ['userApiKeys', accountName, email],
        (previousData = []) => [data, ...previousData],
      );
      queryClient.invalidateQueries(['tenantUserApiKeys', accountName]);
    },
    onError(error: Error) {
      enqueueSnackbar(`${error.message ?? error}`, { variant: 'error' });
    },
  });
};

export const useUserApiKeyUpdateMutation = () => {
  const { accountName, email } = useAuthInfo();
  const { t } = useTranslation();
  const queryKey = ['userApiKeys', accountName, email];
  const { enqueueSnackbar } = useSnackbar();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/account/advanced/PersonalAccessKey',
  });
  const queryClient = useQueryClient();
  const fetcher = async (data: Partial<UserApiKey> & { apiKeyId: string }) =>
    (
      await UserApiKeyService.updateUserApiKey({
        ...defaultParams,
        data,
      })
    ).data;

  const updateQueryData = <T extends { apiKeyId: string }>(data: T) => {
    queryClient.setQueryData<UserApiKey[]>(queryKey, (previousData = []) => {
      const key = previousData.find(({ apiKeyId }) => apiKeyId === data.apiKeyId);
      if (key) {
        Object.assign(key, data);
      }
      return previousData;
    });
  };

  return useMutation(fetcher, {
    onMutate: async data => {
      // Update optimistically with user data
      await queryClient.cancelQueries({ queryKey });
      const previousItems = queryClient.getQueryData<UserApiKey[]>(queryKey) ?? [];
      updateQueryData(data);
      return { previousItems };
    },
    onSuccess(data) {
      // Update with server data
      updateQueryData(data);
      queryClient.invalidateQueries(['tenantUserApiKeys', accountName]);
      enqueueSnackbar(t('settings.advanced.updateUserKeySuccess'), { variant: 'success' });
    },
    onError(error: Error, _data, context) {
      if (context?.previousItems) {
        // Rollback optimistic update
        queryClient.setQueryData(queryKey, context.previousItems);
      }
      enqueueSnackbar(`${error.message ?? error}`, { variant: 'error' });
    },
  });
};

export const useUserApiKeyDeleteMutation = () => {
  const { t } = useTranslation();
  const { accountName, email } = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const params = useApiDefaultParams({
    origin: 'components/pages/account/advanced/PersonalAccessKey',
  });
  const queryClient = useQueryClient();
  const fetcher = async ({ apiKeyId }: { apiKeyId: string }) =>
    await UserApiKeyService.deleteUserApiKey({
      ...params,
      apiKeyId,
    });

  return useMutation(fetcher, {
    onSuccess: (_data, { apiKeyId }) => {
      queryClient.setQueryData<UserApiKey[]>(
        ['userApiKeys', accountName, email],
        (previousData = []) => previousData.filter(key => key.apiKeyId !== apiKeyId),
      );
      queryClient.invalidateQueries(['tenantUserApiKeys', accountName]);
      enqueueSnackbar(t('settings.advanced.deleteUserKeySuccess'), { variant: 'success' });
    },
    onError(error: Error) {
      enqueueSnackbar(`${error.message ?? error}`, { variant: 'error' });
    },
  });
};
