import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { CaretLeft } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, IconButton, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR, RESEND_CHANGE_PASSWORD } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useMultipleInputState } from '../../../hooks/useMultipleInputState';
import { useAuthService } from '../../../services/NewAuthService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import { ErrorHandler, getEmptyFieldError, getPasswordError } from './helper';
import LabeledInput from './LabeledInput';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';
import ValidationInput, { blurAllInputs, useValidationInputRefs } from './ValidationInput';

const getCodeError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator(value => {
      if (isNaN(Number(value))) {
        return 'Code must be numeric.';
      } else if (value.length !== 6) {
        return 'code must consist of 6 digits.';
      }

      return null;
    })
    .createInstance();

export default function ChangePasswordLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [info, setInfo] = useMultipleInputState({
    accountName: '',
    email: '',
    code: '',
    password: '',
  });
  const refs = useValidationInputRefs(['code', 'password'] as const);
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { forgotPassword, changePasswordAfterForgotPassword } = useAuthService();
  const { accountName, email, code, password } = info;

  useChangeContent('graphic');

  useEffect(() => {
    const { tenantId, email } = routeInfo.params;

    if (tenantId && email) {
      setInfo('accountName', atob(tenantId));
      setInfo('email', atob(email));
      refs.current.code?.origin?.focus();
    } else {
      routeInfo.history.push('/auth/login');
    }
  }, [routeInfo.params]);

  const handleResend = async (): Promise<void> => {
    if (isRequesting) return;

    try {
      blurAllInputs(refs);
      setIsRequesting(true);

      await forgotPassword({ email, tenantId: accountName });

      openModal(
        RESEND_CHANGE_PASSWORD({
          t,
          onClickMainButton: () => {
            closeModal();
          },
        }),
      );
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          onClickMainButton: () => {
            closeModal();
          },
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  };

  const getIsValid = () => {
    if (isRequesting) {
      return false;
    } else if (getCodeError(t)(code)) {
      refs.current.code?.focusWithError();
      return false;
    } else if (getPasswordError(t)(password)) {
      refs.current.password?.focusWithError();
      return false;
    }

    return true;
  };

  const handleSend = async (): Promise<void> => {
    try {
      setIsRequesting(true);

      await changePasswordAfterForgotPassword({ email, code, password, tenantId: accountName });

      routeInfo.history.push(`/auth/login?tenantId=${btoa(accountName)}&email=${btoa(email)}`);
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          onClickMainButton: () => {
            closeModal();
          },
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (getIsValid()) {
      handleSend();
    }
  };

  return (
    <>
      <ShortCut page="signIn" />
      <Box as="form" onSubmit={onSubmit} display="flex" flexDirection="column" gap={2}>
        <Title>{t('auth.messages.checkYourEmail')}</Title>
        <Typography variant="l-regular">
          {t('auth.messages.enterVerificationCodeAndPassword')}
        </Typography>
        <LabeledInput label="Team Name" disabled value={accountName} />
        <LabeledInput label="Email" disabled value={email} />
        <ValidationInput
          refs={refs}
          refKey="code"
          suffix={
            <Button type="button" onClick={handleResend} size="s" variant="text">
              Resend
            </Button>
          }
          validation={getCodeError(t)}
          label="Verification Code"
          value={code}
          onChange={e => setInfo('code', e)}
        />
        <ValidationInput
          type="password"
          refs={refs}
          refKey="password"
          autoComplete="new-password"
          validation={getPasswordError(t)}
          label="Password"
          value={password}
          onChange={e => setInfo('password', e)}
          info={t('settings.profile.passwordRestriction')}
        />
        <Box display="flex" gap={1}>
          <IconButton
            type="button"
            size="l"
            variant="text"
            icon={CaretLeft}
            onClick={() => history.go(-1)}
          />
          <Button
            type="submit"
            color="primary"
            variant="strong-fill"
            size="l"
            style={{ width: '100%' }}
          >
            {t('settings.profile.changePassword')}
          </Button>
        </Box>
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
