import React from 'react';
import { TFunction, Trans } from 'react-i18next';

import { Typography } from '@superb-ai/norwegian-forest';
import { LinkTypography } from '@superb-ai/ui';

import { getUserManualUrl } from '../../../../../consts/DocsLink';

function GCP(t: TFunction, language: string) {
  const { GOOGLE_CLOUD_STORAGE_INTEGRATIONS_MANUAL } = getUserManualUrl(language);
  return {
    title: 'Google Cloud Storage',
    docsLink: GOOGLE_CLOUD_STORAGE_INTEGRATIONS_MANUAL,
    summary: <Trans t={t} i18nKey="integration.gcp.summary" />,
    description: (
      <>
        <Typography variant="body3">
          <Trans t={t} i18nKey="integration.gcp.description" />
        </Typography>
      </>
    ),
    logo: <img src="/static/image/gcp.png" width="100%" alt="gcp logo" />,
    addUrl: '/account/integrations/GS/add',
    tabs: {
      before: (
        <Typography variant="body3">
          <strong>
            <Trans t={t} i18nKey="integration.gcp.tabs.before.1" />
          </strong>
          <br />
          <Trans t={t} i18nKey="integration.gcp.tabs.before.1-1" />
          <br />
          <Trans t={t} i18nKey="integration.gcp.tabs.before.1-2" />
          <br />
          <br />
          <strong>
            <Trans t={t} i18nKey="integration.gcp.tabs.before.2" />
          </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.gcp.tabs.before.2-1"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.gcp.tabs.before.2-2"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/storage/docs/access-control/using-iam-permissions#bucket-add"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.gcp.tabs.before.2-3"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/storage/docs/configuring-cors"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <br />
          <strong>{t('integration.needHelp')}</strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href={GOOGLE_CLOUD_STORAGE_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
      troubleshooting: (
        <Typography variant="body3">
          <strong>{t('integration.gcp.tabs.troubleshooting.1')}</strong>
          <br />
          {t('integration.gcp.tabs.troubleshooting.1-1')}
          <br />
          <Trans
            t={t}
            i18nKey="integration.gcp.tabs.troubleshooting.1-2"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              link2: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/storage/docs/access-control/using-iam-permissions#bucket-add"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.gcp.tabs.troubleshooting.1-3"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href="https://cloud.google.com/storage/docs/configuring-cors"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <br />
          <strong>{t('integration.needHelp')} </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  color="secondary"
                  href={GOOGLE_CLOUD_STORAGE_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
    },
  };
}

export default GCP;
