import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Modal, Stepper, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { uniq } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import { DEFAULT_ERROR, UPGRADE_PLAN } from '../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUsersInfo } from '../../../../contexts/UsersContext';
import ModalSnackbar from '../../../../modules/ModalSnackbar';
import { useMetering } from '../../../../queries/useMeteringQuery';
import UsersService from '../../../../services/AccountService';
import ProjectService from '../../../../services/ProjectService';
import { MemberData, NewRole } from '../../../../types/memberTypes';
import { ProjectData } from '../../../../types/projectTypes';
import { concurrent } from '../../../../utils/SpbUtils';
import AddMembers from './AddMembers';
import SelectProjects from './SelectProjects';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  page: 'account' | 'project';
  loadUsers: () => Promise<void>;
  currentMembers: MemberData[];
};

const InviteMembersModal: React.FC<Props> = ({
  open,
  setOpen,
  loadUsers,
  currentMembers,
  page,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const usersInfo = useUsersInfo();
  const projectInfo = useProjectInfo();
  const { openModal, closeModal } = useAlertModal();
  const metering = useMetering('common:user-seat');

  const [activeStep, setActiveStep] = useState<Step>('members');

  const [membersToAdd, setMembersToAdd] = useState<Record<NewRole, string[]>>();
  const [selectedProjects, setSelectedProjects] = useState<ProjectData[]>([]);
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [confirmation, setConfirmation] = useState<'close' | 'invite'>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorSnackbarDiffCount, setErrorSnackbarDiffCount] = useState(0);

  const isInvitingOnlyAdmins = !!(
    membersToAdd &&
    !membersToAdd.labeler &&
    !membersToAdd.manager &&
    membersToAdd.admin
  );
  const totalMembersArray = membersToAdd ? Object.values(membersToAdd).flat(1) : [];
  const totalMembersCount = totalMembersArray.length;
  const isMembersToAddNotSelected = !membersToAdd || Object.keys(membersToAdd).length === 0;

  const duplicatedMembers = [
    ...new Set(
      totalMembersArray.filter((item, index) => totalMembersArray.indexOf(item) !== index),
    ),
  ];

  const initialize = () => {
    setActiveStep('members');
    setMembersToAdd(undefined);
    setSelectedProjects(page === 'project' ? [projectInfo.project] : []);
    setTotalProjectCount(0);
    setConfirmation(undefined);
    setIsLoading(false);
  };

  useEffect(() => {
    if (page === 'project') {
      setSelectedProjects([projectInfo.project]);
    }
  }, []);

  useEffect(() => {
    if (history.location.hash === '#invite') {
      setOpen(true);
    }
  }, [history.location.hash]);

  const AllSteps = {
    members: {
      label: t('invite.steps.0'),
    },
    projects: {
      label: t('invite.steps.1'),
      optional: true,
      disabled: isInvitingOnlyAdmins,
    },
  } as const;

  type Step = keyof typeof AllSteps;

  const handleConfirmClose = () => {
    setOpen(false);
    initialize();
  };

  const handleClose = () => {
    if (isMembersToAddNotSelected) {
      handleConfirmClose();
      return;
    }
    setConfirmation('close');
  };

  const handleClickNext = () => {
    setActiveStep('projects');
  };

  const handleClickInvite = () => {
    setConfirmation('invite');
  };

  const inviteWithoutProject = async (membersList: string[], role: 'Admin' | 'Collaborator') => {
    await UsersService.inviteToAccount({
      inviteList: membersList.map(member => ({ email: member, role })),
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  };

  const inviteWithProject = async () => {
    if (isMembersToAddNotSelected) return;
    const updateUserRolesRequests = selectedProjects.reduce((acc, project) => {
      const managersList = (
        membersToAdd?.manager
          ? membersToAdd?.manager.map(member => ({
              userId: member,
              projectId: project.id,
              role: 'Manager',
            }))
          : []
      ) as UpdateUserRolesRequest[];
      const labelersList = (
        membersToAdd?.labeler
          ? membersToAdd?.labeler.map(member => ({
              userId: member,
              projectId: project.id,
              role: 'Labeler',
            }))
          : []
      ) as UpdateUserRolesRequest[];
      const reviewersList = (
        membersToAdd?.reviewer
          ? membersToAdd?.reviewer.map(member => ({
              userId: member,
              projectId: project.id,
              role: 'Reviewer',
            }))
          : []
      ) as UpdateUserRolesRequest[];
      return [...acc, ...managersList, ...labelersList, ...reviewersList];
    }, [] as UpdateUserRolesRequest[]);

    await updateUserRoles(updateUserRolesRequests);
  };

  type UpdateUserRolesRequest = {
    userId: string;
    projectId: string;
    role: 'Labeler' | 'Manager' | 'Reviewer';
  };

  const updateUserRoles = (requests: UpdateUserRolesRequest[]) => {
    const maxConcurrentRequests = 10;
    return concurrent(
      requests,
      ({ userId, projectId, role }) =>
        ProjectService.updateUserRole({
          projectId,
          userId,
          nextRole: role.replace('Labeler', 'Worker'),
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        }),
      maxConcurrentRequests,
    );
  };

  const finishInvite = async () => {
    handleConfirmClose();
    await usersInfo.updateMembers();
    await loadUsers();
  };

  const handleClickConfirmInvite = async () => {
    if (isMembersToAddNotSelected) return;

    setConfirmation(undefined);
    setIsLoading(true);

    try {
      if (membersToAdd?.admin) {
        await inviteWithoutProject(membersToAdd?.admin, 'Admin');

        if (isInvitingOnlyAdmins) {
          return;
        }
      }

      if (selectedProjects.length <= 0) {
        await inviteWithoutProject(
          [
            ...(membersToAdd?.manager || []),
            ...(membersToAdd?.labeler || []),
            ...(membersToAdd?.reviewer || []),
          ],
          'Collaborator',
        );
        return;
      }

      // Invite and assign collaborators to selected projects
      await inviteWithProject();
    } catch (err: any) {
      if (err.message === 'Exceeded limits') {
        setOpen(false);
        setIsLoading(false);
        openModal({
          ...UPGRADE_PLAN({
            t,
            history,
            accountName: routeInfo.urlMatchInfo.accountName,
            closeModal,
          }),
        });
        return;
      }
      openModal(DEFAULT_ERROR({ t, message: err.message, onClickMainButton: closeModal }));
    } finally {
      AnalyticsTracker.invitationsSent({
        accountId: routeInfo.urlMatchInfo.accountName,
        referrer: `${page}-members` as 'account-members' | 'project-members',
        invitationCount: totalMembersCount,
        projectsCount: page === 'account' ? selectedProjects.length : undefined,
      });
      await finishInvite();
    }
  };

  const handleChangeMembersToAdd = (role: NewRole, emails: string[]) => {
    const newEmails = emails.filter(
      email => currentMembers.findIndex(member => member.email === email) < 0,
    );
    const diffCount = emails.length - newEmails.length;
    if (membersToAdd && membersToAdd[role]) {
      setMembersToAdd({
        ...membersToAdd,
        [role]: uniq([...membersToAdd[role], ...newEmails]),
      } as Record<NewRole, string[]>);

      return;
    }
    if (newEmails.length > 0) {
      setMembersToAdd({ ...membersToAdd, [role]: newEmails } as Record<NewRole, string[]>);
    }

    if (diffCount > 0) {
      setErrorSnackbarDiffCount(diffCount);
    }
  };

  const getModalButtons = () => {
    const membersMainButtonDisabled = isMembersToAddNotSelected || duplicatedMembers.length > 0;
    const inviteText = `${t('invite.button.invite')} (${totalMembersCount})`;
    if (activeStep === 'members') {
      if (page === 'account' && !isInvitingOnlyAdmins) {
        return {
          mainButton: {
            text: `${t('button.next')} (${totalMembersCount})`,
            disabled: membersMainButtonDisabled,
            onClick: handleClickNext,
          },
        };
      }
      return {
        mainButton: {
          text: inviteText,
          disabled: membersMainButtonDisabled,
          onClick: handleClickInvite,
          isLoading,
        },
      };
    }
    return {
      mainButton: {
        text: inviteText,
        onClick: handleClickInvite,
        isLoading,
      },
      subButton: {
        text: t('button.back'),
        onClick: () => {
          setActiveStep('members');
        },
        disabled: isLoading,
      },
    };
  };

  const getConfirmationContent = (): {
    content: React.ReactElement;
    confirmButtonText: string;
    onConfirm: () => void;
  } => {
    if (!confirmation) return { content: <></>, confirmButtonText: '', onConfirm: () => {} };
    const i18nValues = {
      memberCount: t('invite.memberCount', { count: totalMembersCount }),
      projectCount: t('projects.projectCount', { count: selectedProjects.length }),
    };
    return {
      close: {
        content: (
          <Box minHeight="44px">
            <Typography variant="body3">
              <Trans t={t} i18nKey="data.dialogs.upload.areYouSureToQuit" />
            </Typography>
          </Box>
        ),
        confirmButtonText: t('button.ok'),
        onConfirm: handleConfirmClose,
      },
      invite: {
        content: (
          <Box minHeight="44px">
            <Typography variant="body3">
              {page === 'account' && selectedProjects.length > 0 ? (
                <Trans t={t} i18nKey="invite.messages.inviteMembersToProject" values={i18nValues} />
              ) : (
                <Trans t={t} i18nKey="invite.messages.inviteMembers" values={i18nValues} />
              )}
            </Typography>
            {page === 'account' && selectedProjects.length <= 0 && !isInvitingOnlyAdmins && (
              <Box mt={0.5}>
                <Typography variant="body4">
                  {t('invite.messages.ifYouDontAssignCollaborators')}
                </Typography>
              </Box>
            )}
          </Box>
        ),
        confirmButtonText: `${t('invite.button.invite')} (${totalMembersCount})`,
        onConfirm: handleClickConfirmInvite,
      },
    }[confirmation];
  };

  const getModalDescription = () => {
    if (page === 'project') {
      return <Trans t={t} i18nKey="invite.messages.canInviteCollaborators" />;
    }
    if (activeStep === 'members') {
      return (
        <Trans
          t={t}
          i18nKey="invite.messages.canInviteAdminsAndCollaborators"
          count={metering.leftQuantity}
        />
      );
    }
    return <>{t('invite.selectProjectsToAssignNewMembers')}</>;
  };

  return (
    <Modal
      open={open}
      title={t('invite.title')}
      {...getModalButtons()}
      close={{
        onClose: handleClose,
        hasCloseButton: true,
      }}
      confirmation={{
        ...getConfirmationContent(),
        open: !!confirmation,
        cancelButtonText: t('button.cancel'),
        onCancel: () => setConfirmation(undefined),
      }}
    >
      <Box px={4} width={520}>
        <Typography variant="body3">{getModalDescription()}</Typography>
      </Box>
      {page === 'account' && (
        <Box mt={3} px={4} py={2} themedBackgroundColor={['grey', 50]}>
          <Box width={320}>
            <Stepper steps={AllSteps} activeStep={activeStep} />
          </Box>
        </Box>
      )}
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <Box px={2} width={520} mt={3}>
          {activeStep === 'members' ? (
            <AddMembers
              onChangeMembers={handleChangeMembersToAdd}
              membersToAdd={membersToAdd}
              setMembersToAdd={setMembersToAdd}
              duplicatedMembers={duplicatedMembers}
              currentMembers={currentMembers}
            />
          ) : (
            <SelectProjects
              membersToAdd={membersToAdd}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              totalCount={totalProjectCount}
              setTotalCount={setTotalProjectCount}
            />
          )}
        </Box>
        <ModalSnackbar
          content={t('invite.messages.didNotAddExistingMembers', { count: errorSnackbarDiffCount })}
          open={!!errorSnackbarDiffCount}
          onClose={() => setErrorSnackbarDiffCount(0)}
        />
      </div>
    </Modal>
  );
};

export default InviteMembersModal;
