import { Trans } from 'react-i18next';

import { TFunction } from 'i18next';

export type ColumnConfig = {
  title: string;
  width?: string;
  justifyContent?: string;
  infoText?: any;
};
export const getHeaderWithImages = (t: TFunction): ColumnConfig[] => {
  return [
    { title: t('curate.analytics.text.name'), width: '100px' },

    {
      title: t('curate.analytics.classStats.objects'),
      infoText: t('curate.analytics.classStats.objectsInfoText'),
    },
    {
      title: t('curate.analytics.text.share'),
      width: '56px',
      justifyContent: 'flex-end',
    },
    { title: t('curate.analytics.text.count'), width: '72px', justifyContent: 'flex-end' },
    {
      title: t('curate.analytics.classStats.images'),
      infoText: (
        <Trans i18nKey="curate.analytics.classStats.imagesInfoText">
          Images containing <strong>at least one</strong> object
        </Trans>
      ) as any,
    },
    { title: t('curate.analytics.text.share'), width: '56px', justifyContent: 'flex-end' },
    { title: t('curate.analytics.text.count'), width: '72px', justifyContent: 'flex-end' },
  ];
};

export const getHeader = (t: TFunction): ColumnConfig[] => {
  return [
    { title: t('curate.analytics.text.name'), width: '100px' },

    {
      title: t('curate.analytics.classStats.objects'),
      infoText: t('curate.analytics.classStats.objectsInfoText'),
    },
    {
      title: t('curate.analytics.text.share'),
      width: '56px',
      justifyContent: 'flex-end',
    },
    { title: t('curate.analytics.text.count'), width: '72px', justifyContent: 'flex-end' },
  ];
};
