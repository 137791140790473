import React, { CSSProperties } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import { Box } from '@superb-ai/norwegian-forest';

import { formatDuration } from '../../../../../utils/date';
import { JsonObj } from '../../userStats/types';
import { AutoLabelDifficultyIcon } from './AutoLabelDifficultyIcon';
import { AutoLabelStatus, AutoLabelStatusIcon } from './AutoLabelStatusIcon';
import UserAvatarAndName from './UserAvatarAndName';

interface RowProps {
  key: string;
  row: JsonObj;
  handleRowClick: (email: string) => void;
  users: JsonObj;
  displayOrder: string[];
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const getColor = () => {
  return { backgroundColor: 'none' };
};

export const AnalyticsTableRow = ({ row, handleRowClick, displayOrder, users }: RowProps) => {
  const getAutoLabelIcon = (status: AutoLabelStatus, difficulty: number) => {
    return (
      <Box display="flex" justifyContent="center">
        <AutoLabelStatusIcon status={status} />
        <AutoLabelDifficultyIcon difficulty={difficulty} tooltipPlacement={'bottom'} />
      </Box>
    );
  };

  const getRowCell = (row: JsonObj, columnId: string, users: JsonObj) => {
    const value = row[columnId];
    if (columnId === 'labelingTime') return `${formatDuration(row['labelingTime'])}`;
    if (columnId === 'workAssignee')
      return UserAvatarAndName({ userInfo: users[value], email: value, name: users[value]?.name });
    if (columnId === 'autoLabelDifficulty')
      return row['autoLabelStatus']
        ? getAutoLabelIcon(row['autoLabelStatus'], value as number)
        : '';
    return value;
  };

  return (
    // @ts-ignore: saa-680
    <StyledTableRow hover role="checkbox" onClick={() => handleRowClick(row.id)}>
      {displayOrder.map(columnId => {
        const cell = (
          <TableCell style={getColor() as CSSProperties}>
            {getRowCell(row, columnId, users)}
          </TableCell>
        );

        return <React.Fragment key={row.id + columnId}>{cell}</React.Fragment>;
      })}
    </StyledTableRow>
  );
};
