import React, { CSSProperties, useEffect, useRef } from 'react';

import * as d3 from 'd3';
import { NumberValue } from 'd3';

import { DARK_GRAY } from '../../../../../consts/ColorChips';
import { ExtendedScaleTime } from '../../interfaces/d3Types';

interface XAxisProps {
  bottom: number; // bottom margin in pixels
  left: number; // left margin in pixels
  width: number;
  height: number;
  scale: ExtendedScaleTime;
  xLength: number; // number of variables in X axis (used to decide axis display)
  xDisplayName?: string; // Display name for X Label
  xMaxLength?: number;
  labelFontWeight?: 'normal' | 'bold';
  rotateXLabel?: boolean;
  labelFontSize?: string;
  labelXOffset?: number;
  labelYOffset?: number;
  textFontSize?: string;
  tickValues?: number[];
}

const DateXAxisBottom: React.FC<XAxisProps> = props => {
  const {
    bottom,
    left,
    width,
    height,
    scale,
    xLength,
    xDisplayName,
    xMaxLength,
    rotateXLabel,
    labelFontWeight,
    labelFontSize,
    labelXOffset,
    labelYOffset,
    textFontSize,
  } = props;
  const axis = useRef(null);

  /** Format time here */
  const formatTime = d3.timeFormat('%b %e') as (
    domainValue: Date | NumberValue,
    index: number,
  ) => string;

  /** if number of data points is < 14, display all x-axis tick values */
  const DATA_LENGTH_THRESHOLD = 14;

  /**
   * Controls number of displayed dates based on data count
   *   + increase to increase the number of displayed dates
   *   - decrease to reduce the number of displayed dates
   */
  const TICK_NUMBER_SCALER = 10;

  /** by default, don't display tick */
  const TICK_SIZE = 0;

  useEffect(() => {
    /** Set xAxis */
    let xAxis;
    if (xLength >= DATA_LENGTH_THRESHOLD) {
      const everyNDays = Math.round(xLength / TICK_NUMBER_SCALER);
      xAxis = d3
        .axisBottom(scale)
        .tickSize(TICK_SIZE)
        .tickFormat(formatTime)
        // @ts-ignore this works
        .ticks(d3.timeDay.filter(d => d3.timeDay.count(0, d) % everyNDays === 0));
    } else {
      xAxis = d3.axisBottom(scale).tickSize(0).tickFormat(formatTime).ticks(d3.timeDay.every(1));
    }

    d3.select(axis.current)
      // @ts-ignore this works
      .call(xAxis)
      .call(g => g.selectAll('path').attr('stroke', 'lightgray'))
      .attr('stroke-width', '2px')
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke', DARK_GRAY.color)
          .attr('stroke-width', 1)
          .attr('stroke-opacity', 0.5),
      )
      .call(g => {
        if (rotateXLabel && (xLength >= 15 || (xLength >= 7 && xMaxLength && xMaxLength > 10))) {
          g.selectAll('text')
            .style('text-anchor', 'start')
            .attr('dx', '.35em')
            .attr('dy', '.55em')
            .attr('transform', 'rotate(35)');
        } else {
          g.selectAll('text').style('text-anchor', 'middle').attr('dy', '1.2em');
        }
      });
  });

  const textStyle: CSSProperties = {
    fontSize: textFontSize || '12px',
    fontFamily: 'Inter',
    textAnchor: 'start',
    color: DARK_GRAY.color,
  };

  const labelStyle: CSSProperties = {
    fontFamily: 'Inter; sans-serif',
    fontSize: labelFontSize || '12px',
    fontWeight: labelFontWeight || 'bold',
    textAnchor: 'middle',
    color: DARK_GRAY.color,
  };

  /** Close to x-axis right edge */
  const xLabelLoc = labelXOffset === undefined ? width - 90 : width - labelXOffset;
  const yLabelLoc = labelYOffset || 37;

  return (
    <g transform={`translate(${left}, ${height - bottom + 0.5})`}>
      <g className="axis-x" style={textStyle} ref={axis} />
      {xDisplayName ? (
        <g>
          <text style={labelStyle} transform={`translate(${xLabelLoc},${yLabelLoc})`}>
            {xDisplayName}
          </text>
        </g>
      ) : null}
    </g>
  );
};

export default DateXAxisBottom;
