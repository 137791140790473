import React, { useCallback, useState } from 'react';

import { cloneDeep } from 'lodash';

import { IAdvancedQuery, IQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';
import {
  boxNumToBoxDepthInfo,
  checkChildrenProps,
  clearList,
  queryConvertToString,
  searchTarget,
  sortUpdateList,
  updateNewOperator,
  updateNewTargetAll,
  updateNewTargetDelete,
  updateNewTargetGroup,
  updateNewTargetQuery,
  updateSelectedQueryInAdvancedQuery,
} from './advancedQueryUtils';

export const useAdvancedQuery = () => {
  const [advancedQuery, setAdvancedQuery] = useState<IAdvancedQuery>({
    query: [],
    boxIndex: 0,
    children: [],
    operator: 'AND',
  });
  const [selectedQueryList, setSelectedQueryList] = useState<ISelectedQuery[]>([]);
  const [anchorEl, setAnchorEl] = useState<string | null>(null);

  const handleReset = useCallback(() => {
    setAdvancedQuery({
      query: [],
      boxIndex: 0,
      children: [],
      operator: 'AND',
    });
    setSelectedQueryList([]);
  }, [setAdvancedQuery, setSelectedQueryList]);

  const handleOpen = useCallback(
    (event: React.MouseEvent, id: string) => {
      event.stopPropagation();
      if (anchorEl === id) setAnchorEl(null);
      else setAnchorEl(id);
    },
    [anchorEl, setAnchorEl],
  );

  const updateSelectedQuery = useCallback(
    (newAdvancedQuery: IAdvancedQuery) => {
      let newSelectedQuery: ISelectedQuery[] = [];
      if (newAdvancedQuery.query && newAdvancedQuery.query.length > 0) {
        const selectedList = newAdvancedQuery.query
          .filter(list => list.selectedQuery)
          .map(list => {
            if (list.selectedQuery)
              return { ...list.selectedQuery, boxNum: (10 + (list.boxIndex + 1)).toString() };
            else return { ...list };
          });
        newSelectedQuery = [...(selectedList as ISelectedQuery[])];
      }

      if (newAdvancedQuery.children && newAdvancedQuery.children?.length > 0) {
        newAdvancedQuery.children.map(childrenList => {
          if (childrenList.query && childrenList.query.length > 0) {
            const selectedListChildren = childrenList.query
              .filter(queryList => queryList.selectedQuery)
              .map(queryList => {
                return {
                  ...queryList.selectedQuery,
                  boxNum: (
                    100 +
                    (childrenList.boxIndex + 1) * 10 +
                    (queryList.boxIndex + 1)
                  ).toString(),
                };
              });

            newSelectedQuery = [...newSelectedQuery, ...(selectedListChildren as ISelectedQuery[])];
          }

          if (childrenList.children && childrenList.children?.length > 0) {
            childrenList.children.map(grandChildrenList => {
              if (grandChildrenList.query && grandChildrenList.query.length > 0) {
                const selectedListGrandChildren = grandChildrenList.query
                  .filter(list => list.selectedQuery)
                  .map(list => {
                    return {
                      ...list.selectedQuery,
                      boxNum: (
                        1000 +
                        (childrenList.boxIndex + 1) * 100 +
                        (grandChildrenList.boxIndex + 1) * 10 +
                        list.boxIndex +
                        1
                      ).toString(),
                    };
                  });

                newSelectedQuery = [
                  ...newSelectedQuery,
                  ...(selectedListGrandChildren as ISelectedQuery[]),
                ];
              }
            });
          }
        });
      }
      setSelectedQueryList(() => [...newSelectedQuery]);
    },
    [setSelectedQueryList],
  );

  const updateQuery = useCallback(
    (boxNum: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);

      if (boxDepthInfo.length < 2) {
        newAdvancedQuery.query.push({
          content: `${boxNum}`,
          boxIndex: newAdvancedQuery.query.length + (newAdvancedQuery.children?.length ?? 0),
        });
        if (!newAdvancedQuery?.operator) newAdvancedQuery.operator = 'AND';
      } else {
        boxDepthInfo.shift();
        const target = searchTarget(newAdvancedQuery, boxDepthInfo);

        if ((target as IAdvancedQuery)?.query)
          (target as IAdvancedQuery)?.query.push({
            content: `${boxNum}`,
            boxIndex:
              (target as IAdvancedQuery)?.query.length +
              ((target as IAdvancedQuery)?.children?.length ?? 0),
          });

        updateNewTargetQuery(newAdvancedQuery, boxDepthInfo, target as IAdvancedQuery);
      }

      setAdvancedQuery(() => ({ ...newAdvancedQuery }));
      setAnchorEl(null);
    },
    [advancedQuery, setAdvancedQuery, setAnchorEl],
  );

  const updateQueryGroup = useCallback(
    (boxNum: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      let newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);
      if (boxDepthInfo.length < 2) {
        if (newAdvancedQuery?.children)
          newAdvancedQuery?.children?.push({
            query: [{ content: `${boxNum}`, boxIndex: 0 }],
            boxIndex: newAdvancedQuery.query.length + (newAdvancedQuery.children?.length ?? 0),
            children: [],
            operator: 'AND',
          });
        else
          newAdvancedQuery = {
            ...newAdvancedQuery,
            children: [
              {
                query: [{ content: `${boxNum}`, boxIndex: 0 }],
                boxIndex: newAdvancedQuery.query.length + (newAdvancedQuery.children?.length ?? 0),
                children: [],
                operator: 'AND',
              },
            ],
          };
      } else if (boxDepthInfo.length < 4) {
        boxDepthInfo.shift();
        let target = searchTarget(newAdvancedQuery, boxDepthInfo);

        if ((target as IAdvancedQuery)?.children)
          (target as IAdvancedQuery)?.children?.push({
            query: [
              {
                content: `${boxNum} ${
                  (target as IAdvancedQuery)?.query.length +
                  ((target as IAdvancedQuery)?.children?.length ?? 0)
                }`,
                boxIndex: 0,
              },
            ],
            children: [],
            operator: 'AND',
            boxIndex:
              (target as IAdvancedQuery)?.query.length +
              ((target as IAdvancedQuery)?.children?.length ?? 0),
          });
        else {
          target = {
            ...target,
            children: [
              {
                query: [
                  {
                    content: `${boxNum}${
                      (target as IAdvancedQuery)?.query.length +
                      ((target as IAdvancedQuery)?.children?.length ?? 0)
                    }`,
                    boxIndex: 0,
                  },
                ],
                children: null,
                operator: 'AND',
                boxIndex:
                  (target as IAdvancedQuery)?.query.length +
                  ((target as IAdvancedQuery)?.children?.length ?? 0),
              },
            ],
          };
        }

        updateNewTargetGroup(newAdvancedQuery, boxDepthInfo, target as IAdvancedQuery);
      }

      setAdvancedQuery(() => ({ ...newAdvancedQuery }));
      setAnchorEl(null);
    },
    [advancedQuery, setAdvancedQuery, setAnchorEl],
  );

  const deleteQuery = useCallback(
    (boxNum: number, boxIndex: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);

      let deleteSelectedQueryNum = '';
      if (boxDepthInfo.length < 2) {
        const targetQuery = (newAdvancedQuery as IAdvancedQuery)?.query?.filter(
          list => list.boxIndex === boxIndex,
        );

        if (targetQuery[0].hasOwnProperty('selectedQuery')) {
          deleteSelectedQueryNum = targetQuery[0].selectedQuery?.boxNum ?? '';
        }
        const newQuery = (newAdvancedQuery as IAdvancedQuery)?.query
          ?.filter(list => list.boxIndex !== boxIndex)
          .map(query =>
            query.boxIndex > boxIndex ? { ...query, boxIndex: query.boxIndex - 1 } : query,
          );
        newAdvancedQuery.query = [...newQuery];
      } else {
        boxDepthInfo.shift();
        const target = searchTarget(newAdvancedQuery, boxDepthInfo);

        if ((target as IAdvancedQuery)?.query) {
          const targetQuery = (target as IAdvancedQuery)?.query?.filter(
            list => list.boxIndex === boxIndex,
          );

          if (targetQuery[0].hasOwnProperty('selectedQuery')) {
            deleteSelectedQueryNum = targetQuery[0].selectedQuery?.boxNum ?? '';
          }
          const newQuery = (target as IAdvancedQuery)?.query
            ?.filter(list => list.boxIndex !== boxIndex)
            .map(query =>
              query.boxIndex > boxIndex ? { ...query, boxIndex: query.boxIndex - 1 } : query,
            );
          (target as IAdvancedQuery).query = [...newQuery];
        }

        updateNewTargetDelete(newAdvancedQuery, boxDepthInfo, target as IAdvancedQuery, boxIndex);
      }

      const newSelectedQuery = selectedQueryList.filter(
        list => list.boxNum !== deleteSelectedQueryNum,
      );
      setSelectedQueryList(newSelectedQuery);

      const result = checkChildrenProps(newAdvancedQuery);
      setAdvancedQuery(() => sortUpdateList(clearList(result)));

      updateSelectedQuery(sortUpdateList(clearList(result)));
      setAnchorEl(null);
    },
    [
      advancedQuery,
      selectedQueryList,
      setAdvancedQuery,
      setAnchorEl,
      setSelectedQueryList,
      updateSelectedQuery,
    ],
  );

  const turnIntoFilter = useCallback(
    (boxNum: number, listIndex: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);

      if (boxDepthInfo.length > 1) {
        boxDepthInfo.shift();
        boxDepthInfo.push(listIndex);
        const target = searchTarget(newAdvancedQuery, boxDepthInfo);
        boxDepthInfo.pop();

        const targetParents = searchTarget(newAdvancedQuery, boxDepthInfo);
        let newTargetParents = cloneDeep(targetParents);

        if ((newTargetParents as IAdvancedQuery).query) {
          const newQuery = (newTargetParents as IAdvancedQuery).query
            .filter(list => list.boxIndex !== target.boxIndex)
            .map(query =>
              query.boxIndex > target.boxIndex ? { ...query, boxIndex: query.boxIndex - 1 } : query,
            )
            .map(list => {
              if (list.boxIndex > target.boxIndex) {
                if (list.selectedQuery)
                  return {
                    ...list,
                    selectedQuery: {
                      ...list.selectedQuery,
                      boxNum: (+(list.selectedQuery as ISelectedQuery).boxNum - 1).toString(),
                    },
                  };
                else return { ...list };
              } else return { ...list };
            });

          newTargetParents = {
            ...newTargetParents,
            query: [...newQuery],
          };
        }

        if ((newTargetParents as IAdvancedQuery)?.children) {
          const newChildren = (newTargetParents as IAdvancedQuery).children
            ?.map(child =>
              child.boxIndex > target.boxIndex ? { ...child, boxIndex: child.boxIndex - 1 } : child,
            )
            .map(list => {
              if (list.boxIndex > target.boxIndex) {
                const newQuery = list.query.map(queryList => {
                  if (queryList.selectedQuery)
                    return {
                      ...queryList,
                      selectedQuery: {
                        ...queryList.selectedQuery,
                        boxNum: queryList.selectedQuery?.boxNum,
                      },
                    };
                  else
                    return {
                      ...queryList,
                    };
                });

                return { ...list, query: [...newQuery] as IQuery[] };
              } else return list;
            });

          newTargetParents = {
            ...newTargetParents,
            children: newChildren && newChildren?.length > 0 ? [...newChildren] : null,
          };
        }

        updateNewTargetAll(newAdvancedQuery, boxDepthInfo, newTargetParents as IAdvancedQuery);

        boxDepthInfo.pop();

        const targetGrandParents = searchTarget(newAdvancedQuery, boxDepthInfo);

        let newTargetGrandParents = cloneDeep(targetGrandParents);

        const hasEmptyChildren = [
          ...((newTargetGrandParents as IAdvancedQuery).children?.filter(
            list => list.query.length === 0 && !list.children,
          ) ?? []),
        ];

        if ((newTargetGrandParents as IAdvancedQuery).query) {
          const newQuery = (newTargetGrandParents as IAdvancedQuery).query.map(query => {
            if (query.boxIndex >= newTargetParents.boxIndex) {
              if (query.selectedQuery)
                return {
                  ...query,
                  boxIndex: hasEmptyChildren?.length > 0 ? query.boxIndex : query.boxIndex + 1,
                  selectedQuery: {
                    ...query.selectedQuery,
                    boxNum: (+(query.selectedQuery as ISelectedQuery)?.boxNum + 1).toString(),
                  },
                };
              else
                return {
                  ...query,
                  boxIndex: hasEmptyChildren?.length > 0 ? query.boxIndex : query.boxIndex + 1,
                };
            } else return query;
          });
          newTargetGrandParents = {
            ...newTargetGrandParents,
            query: [...newQuery],
          };
        }

        if ((newTargetGrandParents as IAdvancedQuery)?.children) {
          const newChildren = (newTargetGrandParents as IAdvancedQuery).children
            ?.filter(list => list.query.length > 0 || list.children)
            .map(child => {
              if (child.boxIndex >= newTargetParents.boxIndex) {
                const newQuery = child.query.map(list => {
                  if (list.selectedQuery)
                    return {
                      ...list,
                      selectedQuery: {
                        ...list.selectedQuery,
                        boxNum: (+(list.selectedQuery as ISelectedQuery).boxNum + 10).toString(),
                      },
                    };
                  else
                    return {
                      ...list,
                    };
                });

                return {
                  ...child,
                  boxIndex: hasEmptyChildren?.length > 0 ? child.boxIndex : child.boxIndex + 1,
                  query: [...newQuery],
                };
              } else return child;
            });

          newTargetGrandParents = {
            ...newTargetGrandParents,
            children: newChildren && newChildren?.length > 0 ? [...newChildren] : null,
          };
        }

        let newTarget = {
          ...target,
        };
        if ((target as IQuery).selectedQuery) {
          newTarget = {
            ...target,
            selectedQuery: {
              ...(target as IQuery).selectedQuery,
              boxNum: Math.floor(
                +((target as IQuery).selectedQuery as ISelectedQuery).boxNum / 10,
              ).toString(),
            },
          };
        }

        (newTargetGrandParents as IAdvancedQuery).query.push({
          ...(newTarget as IQuery),
          boxIndex: targetParents.boxIndex,
        });
        updateNewTargetAll(newAdvancedQuery, boxDepthInfo, newTargetGrandParents as IAdvancedQuery);
        const newFianalAdvancedQuery = updateSelectedQueryInAdvancedQuery(
          newAdvancedQuery as IAdvancedQuery,
        );

        setAdvancedQuery({ ...newFianalAdvancedQuery });
        updateSelectedQuery(newAdvancedQuery);
      }
      setAnchorEl(null);
    },
    [
      advancedQuery,
      setAnchorEl,
      setAdvancedQuery,
      updateSelectedQuery,
      updateSelectedQueryInAdvancedQuery,
    ],
  );

  const wrapInGroup = useCallback(
    (boxNum: number, listIndex: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);

      if (boxDepthInfo.length < 4) {
        boxDepthInfo.shift();
        const target = searchTarget(newAdvancedQuery, boxDepthInfo);

        const newChildrenQuery = (target as IAdvancedQuery).query
          .filter(list => list.boxIndex === listIndex)
          .map(list => {
            if (list.hasOwnProperty('selectedQuery')) {
              const newSelectedQueryList = selectedQueryList.map(selectedList => {
                if (selectedList.boxNum === list.selectedQuery?.boxNum)
                  return {
                    ...selectedList,
                    boxNum: list.selectedQuery.boxNum.toString() + 1,
                  };
                else return { ...selectedList };
              });
              setSelectedQueryList(() => [...newSelectedQueryList]);
              return {
                ...list,
                selectedQuery: {
                  ...list.selectedQuery,
                  boxNum: (list.selectedQuery as ISelectedQuery).boxNum.toString() + 1,
                },
              };
            } else return { ...list };
          });
        const newChildren = {
          query: [
            {
              ...newChildrenQuery[0],
              boxIndex: 0,
            },
          ],
          operator: 'AND',
          children: null,
          boxIndex: listIndex,
        };

        const newQuery = (target as IAdvancedQuery).query.filter(
          list => list.boxIndex !== listIndex,
        );
        const newTarget = {
          ...target,
          query: [...newQuery],
          children: [...((target as IAdvancedQuery).children ?? []), newChildren],
        };
        updateNewTargetAll(newAdvancedQuery, boxDepthInfo, newTarget as IAdvancedQuery);
      }
      setAdvancedQuery({ ...newAdvancedQuery });
      setAnchorEl(null);
    },
    [advancedQuery, setAnchorEl, setAdvancedQuery, selectedQueryList, setSelectedQueryList],
  );

  const updateOperator = useCallback(
    (boxNum: number, operator: string) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);
      boxDepthInfo.shift();
      const target = searchTarget(newAdvancedQuery, boxDepthInfo);
      const newTarget = { ...target, operator: operator };
      updateNewOperator(newAdvancedQuery, boxDepthInfo, newTarget as IAdvancedQuery);
      setAdvancedQuery({ ...newAdvancedQuery });
      setAnchorEl(null);
    },
    [advancedQuery, setAnchorEl, setAdvancedQuery],
  );

  const updateQueryContent = useCallback(
    (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);
      boxDepthInfo.shift();
      const target = searchTarget(newAdvancedQuery, boxDepthInfo);

      const newQuery = (target as IAdvancedQuery).query.map(list => {
        if (list.boxIndex === listIndex) return { ...list, selectedQuery };
        else return { ...list };
      });

      const newTarget = { ...target, query: [...newQuery] };

      updateNewTargetQuery(newAdvancedQuery, boxDepthInfo, newTarget as IAdvancedQuery);
      setAdvancedQuery({ ...newAdvancedQuery });
    },
    [advancedQuery, setAdvancedQuery],
  );

  const handleQueryValue = useCallback(
    (value: string | string[], boxNum: number, index: number) => {
      const hasQuery = selectedQueryList.filter(
        list => list.boxNum === boxNum.toString() + (index + 1),
      );

      const newQueryList = selectedQueryList.filter(
        list => list.boxNum !== boxNum.toString() + (index + 1),
      );

      let queryValue;
      if (Array.isArray(value)) {
        queryValue = JSON.stringify(value);
      } else {
        queryValue = value;
      }

      updateQueryContent(boxNum, index, {
        ...hasQuery[0],
        queryValue,
      });
      setSelectedQueryList(() => [
        ...newQueryList,
        {
          ...hasQuery[0],
          queryValue,
        },
      ]);
    },
    [selectedQueryList, updateQueryContent, setSelectedQueryList],
  );

  const duplicateQuery = useCallback(
    (boxNum: number, listIndex: number) => {
      const boxDepthInfo = boxNumToBoxDepthInfo(boxNum);
      const newAdvancedQuery: IAdvancedQuery = cloneDeep(advancedQuery);
      boxDepthInfo.shift();
      const target = searchTarget(newAdvancedQuery, boxDepthInfo);
      const targetQuery = (target as IAdvancedQuery).query[listIndex];

      window.navigator.clipboard.writeText(queryConvertToString(targetQuery));
      const newSelectedQuery: ISelectedQuery = {
        ...targetQuery?.selectedQuery,
        boxNum: (+(targetQuery?.selectedQuery?.boxNum as string) + 1).toString(),
      };

      if (boxDepthInfo.length < 1) {
        const duplicatedNewQuery = targetQuery.selectedQuery
          ? {
              ...targetQuery,
              boxIndex: newAdvancedQuery.query.length + (newAdvancedQuery.children?.length ?? 0),
              selectedQuery: { ...newSelectedQuery },
            }
          : {
              ...targetQuery,
              boxIndex: newAdvancedQuery.query.length + (newAdvancedQuery.children?.length ?? 0),
            };
        newAdvancedQuery.query.push({
          ...duplicatedNewQuery,
        });
        if (!newAdvancedQuery?.operator) newAdvancedQuery.operator = 'AND';
      } else {
        const target = searchTarget(newAdvancedQuery, boxDepthInfo);
        if ((target as IAdvancedQuery)?.query) {
          const duplicatedNewQuery = targetQuery.selectedQuery
            ? {
                ...targetQuery,
                boxIndex:
                  (target as IAdvancedQuery)?.query.length +
                  ((target as IAdvancedQuery)?.children?.length ?? 0),
                selectedQuery: { ...newSelectedQuery },
              }
            : {
                ...targetQuery,
                boxIndex:
                  (target as IAdvancedQuery)?.query.length +
                  ((target as IAdvancedQuery)?.children?.length ?? 0),
              };

          (target as IAdvancedQuery)?.query.push({
            ...duplicatedNewQuery,
          });
        }

        updateNewTargetQuery(newAdvancedQuery, boxDepthInfo, target as IAdvancedQuery);
      }

      setAdvancedQuery(() => ({ ...newAdvancedQuery }));
      setAnchorEl(null);
      if (targetQuery.selectedQuery)
        setSelectedQueryList(() => [...selectedQueryList, newSelectedQuery]);
      else setSelectedQueryList(() => [...selectedQueryList]);
    },
    [advancedQuery, selectedQueryList, setAdvancedQuery, setAnchorEl, setSelectedQueryList],
  );

  return {
    advancedQuery,
    setAdvancedQuery,
    anchorEl,
    selectedQueryList,
    setAnchorEl,
    handleOpen,
    updateQuery,
    updateQueryGroup,
    deleteQuery,
    turnIntoFilter,
    wrapInGroup,
    updateOperator,
    updateQueryContent,
    setSelectedQueryList,
    handleQueryValue,
    duplicateQuery,
    handleReset,
  };
};
