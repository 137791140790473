import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, Icon, TextEllipsis, Typography } from '@superb-ai/norwegian-forest';
import qs from 'qs';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { formatDateTime } from '../../../../utils/date';
import FilterUtils from '../../../../utils/FilterUtils';
import ParamUtils from '../../../../utils/ParamUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';

export type OptionRenderer = ComponentProps<typeof DropdownList>['optionRenderer'];

export interface Version {
  id: string;
  createdAt: Date;
  filter: string;
  state: 'IN_PROGRESS' | 'FINISHED';
}

interface Props {
  versions: Version[];
  value: string;
  setValue: (version: string) => void;
}

const formatFilter = (filter: string, tagIds: Record<string, string>) => {
  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  const filterList = ParamUtils.getFiltersFromApiParams(paramsObj, tagIds);
  return FilterUtils.formatConcisely(filterList);
};

export default function ReportVersionDropdown(props: Props): JSX.Element {
  const projectInfo = useProjectInfo();
  const { tagIds } = projectInfo;
  const { versions, value, setValue } = props;
  const { t } = useTranslation();

  const getVersionForValue = useMemo(
    () => (value: string) => versions.find(v => v.id === value),
    [versions],
  );

  const options = useMemo(
    () =>
      versions.map(version => ({
        value: version.id,
        label: formatDateTime(version.createdAt),
      })),
    [versions],
  );

  const optionRenderer: OptionRenderer = useMemo(
    () => option => {
      if (!option.value) return <></>;
      const version = getVersionForValue(option.value);
      return (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box overflow="hidden">
              <Typography variant="label">
                <TextEllipsis text={option.label} />
              </Typography>
            </Box>
            {version?.state === 'IN_PROGRESS' && (
              <Icon name="loadingSpinnerSmall" color={['grey', 400]} />
            )}
          </Box>
          <Typography variant="body5" themedColor={['grey', 400]}>
            {version?.filter?.length ? (
              <>
                Filter by <strong>{formatFilter(version.filter, tagIds)}</strong>
              </>
            ) : (
              <strong>{t('analytics.userReports.text.allLabels')}</strong>
            )}
          </Typography>
        </>
      );
    },
    [getVersionForValue],
  );

  return (
    <DropdownList
      placeholder={t('analytics.userReports.dropdown.selectReport')}
      value={value}
      options={options}
      onChange={setValue}
      optionRenderer={optionRenderer}
      width={290}
    />
  );
}
