import { TargetType } from '../components/pages/account/integrations/types';
import { IntegrationResType } from '../types/integrationTypes';
import { Webhook } from '../types/webhooksTypes';
import AuthService from './AuthService';
import ProjectService from './ProjectService';
import { ApiCall, Params } from './types';
import WebhooksService from './WebhooksService';

const getIntegrationsByMethod: ApiCall<
  { methodName: TargetType },
  { data: IntegrationResType[] }
> = async ({ urlInfo, isGuest, methodName }) => {
  if (methodName === 'SLACK') {
    const res = await WebhooksService.getProjectsWebhooks({ urlInfo, isGuest });
    const data = (
      await Promise.all(
        res.data.data.map((item: Webhook) => {
          const { createdAt, createdBy, groupId, url, type } = item;
          const [, projectId] = groupId.split(':');
          return ProjectService.getProject({
            projectId,
            isGuest,
            urlInfo,
          })
            .then(project => ({
              createdAt,
              createdBy,
              integrationId: groupId,
              method: type,
              info: { project: { name: project.name, id: projectId }, url },
            }))
            .catch(_ => {
              // Ignore project not found
              return undefined;
            });
        }),
      )
    ).filter(p => p);
    return {
      ...res.data,
      data,
    };
  }

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/auth/tenants/integrations/methods/${methodName}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const createIntegration: ApiCall<{ params: Params }, any> = async ({
  urlInfo,
  isGuest,
  params,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/integrations',
    data: params,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const editIntegration: ApiCall<{ params: Params } & { integrationId: string }, any> = async ({
  urlInfo,
  isGuest,
  params,
  integrationId,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/auth/tenants/integrations/${integrationId}`,
    data: params,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const checkIntegrationStatus: ApiCall<{ params: Params }, any> = async ({
  urlInfo,
  isGuest,
  params,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/integrations?check_status',
    data: params,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const deleteIntegration: ApiCall<{ integrationId: string; method: TargetType }, any> = async ({
  urlInfo,
  isGuest,
  integrationId,
  method,
}) => {
  if (method === 'SLACK') {
    const res = await WebhooksService.deleteProjectWebhooks({
      type: method,
      projectId: integrationId.split(':')[1],
      isGuest,
      urlInfo,
    });
    return res;
  }
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/auth/tenants/integrations/${integrationId}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

type CreateExternalIdResponse = {
  data: {
    data: { externalId: string };
  };
};
const createExternalId: ApiCall<{}, CreateExternalIdResponse> = async ({ urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/integrations/aws/external-id',
    hasPublicApi: false,
    isGuest: false,
    urlInfo,
  });
  return res;
};

export default {
  getIntegrationsByMethod,
  createIntegration,
  editIntegration,
  checkIntegrationStatus,
  deleteIntegration,
  createExternalId,
};
