import { Dispatch, FC, SetStateAction } from 'react';

import { LoadingSpinner } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

import InfiniteWindowedGridContainer from '../../../../../../../components/elements/windowedImageGrid/InfiniteWindowedGridContainer';
import { CellComponentProps } from '../../../../../../../components/elements/windowedImageGrid/types';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { ClusterLevel } from '../../filter/types';

export default function GridLayout({
  queryResult,
  DetailModal,
  ImageCell,
  setOpenIndex,
}: {
  queryResult: UseInfiniteQueryResult<any, unknown>;
  DetailModal: JSX.Element;
  ImageCell: FC<
    CellComponentProps<{
      columns: number;
      setOpenDetailImageIndex: Dispatch<SetStateAction<number | undefined>>;
      totalCount: number;
      clusterLevel: ClusterLevel;
    }>
  >;
  setOpenIndex: Dispatch<SetStateAction<number | undefined>>;
}) {
  const { columnsCount } = useActionContext();
  const { clusterLevel } = useImageFilterContext();

  const totalCount = queryResult.data?.pages[0]?.count || 0;
  const loadedItems = queryResult.data?.pages.flatMap(p => p.results) ?? [];

  return (
    <>
      {queryResult.isLoading ? (
        <Box display="flex" mt={5} justifyContent="center" alignItems="center">
          <Icon icon={LoadingSpinner} size="32px" />
        </Box>
      ) : (
        <InfiniteWindowedGridContainer
          loadItems={async () => {
            await queryResult.fetchNextPage();
          }}
          loadedItems={loadedItems}
          hasNextPage={Boolean(queryResult.hasNextPage)}
          CellComponent={ImageCell}
          aspectRatio={9 / 16}
          itemData={{
            columns: columnsCount,
            setOpenDetailImageIndex: setOpenIndex,
            totalCount,
            clusterLevel,
          }}
        />
      )}
      {DetailModal}
    </>
  );
}
