import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ScatterToolbarGroup, ScatterToolbarSelect } from '@superb-ai/icons';
import { IconButton, Tooltip } from '@superb-ai/ui';

import { useMode, useSetMode } from './scatterView/providers/ModeProvider';
import { useViewMode } from './scatterView/providers/ViewModeProvider';

type Props = {};

export const ScatterTools = ({}: Props) => {
  const { t } = useTranslation();
  const mode = useMode();
  const setMode = useSetMode();
  const scatterViewMode = useViewMode();

  const handleClickSelect = useCallback(() => {
    setMode({
      modeName: 'selection',
      state: {
        phase: 'idle',
      },
    });
  }, [setMode]);

  const handleClickDrawBox = useCallback(() => {
    setMode({
      modeName: 'drawSelectionArea',
      state: {
        phase: 'idle',
        selectedPoints:
          mode.modeName === 'selection' && mode.state.phase === 'selecting'
            ? mode.state.points
            : [],
      },
    });
  }, [mode, setMode]);

  const handleClickGroup = useCallback(() => {
    setMode({
      modeName: 'selectionGroup',
      state: {
        phase: 'idle',
      },
    });
  }, [mode, setMode]);

  return (
    <>
      <Tooltip
        content={t('curate.embeddings.button.toolbarSelectTooltip')}
        placement="bottom-start"
      >
        <IconButton
          disabled={scatterViewMode === 'thumbnails'}
          icon={ScatterToolbarSelect}
          onClick={handleClickSelect}
          variant={mode.modeName === 'selection' ? 'strong-fill' : 'text'}
          color={mode.modeName === 'selection' ? 'primary' : 'gray'}
        />
      </Tooltip>
      <Tooltip
        content={t('curate.embeddings.button.toolbarSelectGroupTooltip')}
        placement="bottom-start"
      >
        <IconButton
          disabled={scatterViewMode === 'thumbnails'}
          icon={ScatterToolbarGroup}
          onClick={handleClickGroup}
          variant={mode.modeName === 'selectionGroup' ? 'strong-fill' : 'text'}
          color={mode.modeName === 'selectionGroup' ? 'primary' : 'gray-opacity'}
        />
      </Tooltip>
      {/* hide projection selection
      <Tooltip
        content={t('curate.embeddings.button.toolbarDrawSelectionAreaBoxTooltip')}
        placement="bottom-start"
      >
        <IconButton
          icon={ScatterToolbarDrawBox}
          onClick={handleClickDrawBox}
          variant={mode.modeName === 'drawSelectionArea' ? 'strong-fill' : 'text'}
          color={mode.modeName === 'drawSelectionArea' ? 'primary' : 'gray'}
        />
      </Tooltip> */}
    </>
  );
};
