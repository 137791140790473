import { useEffect } from 'react';

import * as MUI from '@mui/material';
import { Box, Input } from '@superb-ai/ui';

import { annotationCount } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterClassDropdown from './FilterClassDropdown/FilterClassDropdown';
import { FilterTypeProps } from './types';

const defaultNumber = { value: 0 };
const defaultString = { value: '' };

export default function FilterAnnotationCount(props: FilterTypeProps) {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  useEffect(() => {
    if (filter.condition === 'is in the range') {
      onValueChange(index, 'options', [
        defaultNumber,
        defaultNumber,
        filter.options?.[2] ?? filter.options?.[1] ?? defaultString,
      ]);
    } else {
      onValueChange(index, 'options', [
        defaultNumber,
        filter.options?.[2] ?? filter.options?.[1] ?? defaultString,
      ]);
    }
  }, [filter.condition]);

  const onChangeClass = (value: any) => {
    if (filter.condition === 'is in the range') {
      const [leftValue = defaultNumber, rightValue = defaultNumber] = filter.options ?? [
        defaultNumber,
        defaultNumber,
      ];
      onValueChange(index, 'options', [leftValue, rightValue, value]);
    } else {
      const [leftValue] = filter.options ?? [0, ''];
      onValueChange(index, 'options', [leftValue, value]);
    }
  };

  const onChangeLeft = (value: any) => {
    if (filter.condition === 'is in the range') {
      const [_leftValue, rightValue = defaultNumber, annotation = defaultString] =
        filter.options ?? [defaultNumber, defaultNumber, defaultString];
      onValueChange(index, 'options', [{ value: `${value}` }, rightValue, annotation]);
    } else {
      const [_leftValue, annotation = defaultString] = filter.options ?? [
        defaultNumber,
        defaultString,
      ];
      onValueChange(index, 'options', [{ value: `${value}` }, annotation]);
    }
  };

  const onChangeRight = (value: any) => {
    if (filter.condition === 'is in the range') {
      const [leftValue = defaultNumber, _rightValue, annotation = defaultString] =
        filter.options ?? [defaultNumber, defaultNumber, defaultString];
      onValueChange(index, 'options', [leftValue, { value: `${value}` }, annotation]);
    }
  };

  return (
    <>
      <MUI.Grid item style={{ flex: 2 }}>
        <FilterClassDropdown
          index={index}
          objKey="options"
          onValueChange={(_i, _obj, value) => onChangeClass(value)}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
          option={filter?.options?.[2] ?? filter?.options?.[1] ?? { value: '' }}
        />
      </MUI.Grid>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={annotationCount.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      <MUI.Grid item style={{ flex: 1 }}>
        {filter.condition === 'is in the range' ? (
          <Box display="flex" alignItems="center" gap={0.5}>
            <Input
              type="number"
              color={isCurrentRow ? 'primary' : 'gray'}
              value={filter?.options?.[0]?.value ?? 0}
              onChange={e => onChangeLeft(e.target.value)}
            />
            ~
            <Input
              type="number"
              color={isCurrentRow ? 'primary' : 'gray'}
              value={filter?.options?.[1]?.value ?? 0}
              onChange={e => onChangeRight(e.target.value)}
            />
          </Box>
        ) : (
          <Input
            type="number"
            color={isCurrentRow ? 'primary' : 'gray'}
            value={filter?.options?.[0]?.value ?? 0}
            onChange={e => onChangeLeft(e.target.value)}
          />
        )}
      </MUI.Grid>
    </>
  );
}
