import { useTranslation } from 'react-i18next';

import { ArrowRight, InfoCircle, ScatterToolbarDrawBox } from '@superb-ai/icons';
import { Box, Icon, Tooltip, Typography } from '@superb-ai/ui';

import ResetChip from './ResetChip';

type Props = {
  isApplied: boolean;
  handleReturnToPage?: () => void;
  handleReset: () => void;
};

export default function ProjectionFilter(props: Props) {
  const { handleReset, handleReturnToPage, isApplied } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant="m-strong">{t('curate.filter.scatterArea')}</Typography>
        <Tooltip content={t('curate.filter.selectedFromScatterView')}>
          <Icon icon={InfoCircle} />
        </Tooltip>
        {isApplied && (
          <ResetChip style={{ marginLeft: 'auto' }} count={1} onClickResetChip={handleReset} />
        )}
      </Box>
      <Box
        p={1}
        backgroundColor="gray-100"
        display="flex"
        alignItems="center"
        gap={0.5}
        onClick={handleReturnToPage}
        cursor={handleReturnToPage ? 'pointer' : undefined}
      >
        <Icon icon={ScatterToolbarDrawBox} />
        <Typography variant="m-regular">{t('curate.filter.selectedArea')}</Typography>
        {handleReturnToPage && <Icon icon={ArrowRight} style={{ marginLeft: 'auto' }} />}
      </Box>
    </>
  );
}
