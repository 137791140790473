import { keyBy } from '../../../utils/collections';

interface Word {
  id: string;
  word: string;
}

export const convertCategorizationToObject = (categorization: {
  wordMap?: any[];
  properties?: any[];
}): Record<string, any> => {
  // video-siesta

  if (categorization.wordMap) {
    return keyBy(categorization.wordMap, category => category.id);
  }

  if (categorization.properties) {
    const root = categorization.properties[0];
    if (root) {
      const result: Record<string, any> = {};
      const find = (node: any) => {
        const children = node?.options || node?.children;
        result[node.id] = node;
        if (!children) return;

        children.forEach((child: Word) => find(child));
      };
      find(root);
      return result;
    }
  }

  return {};
};

type Option = {
  id: string;
  name: string;
  children?: Option[];
};

export interface Property {
  blank?: boolean;
  constraints?: {
    alphabet?: boolean;
    digit?: boolean;
    space?: boolean;
    special?: boolean;
  };
  defaultValue: string | any[]; // TODO: check if string | string[]
  description: string;
  id: string;
  name: string;
  options?: Option[];
  perFrame?: boolean;
  renderValue: boolean;
  required?: boolean;
  type: 'checkbox' | 'radio' | 'free response';
}

export const convertPropertyToObject = (property: Property): Record<string, any> => {
  if (!property.options) {
    return {
      type: 'free response',
      name: property.name,
    };
  }

  const result: Record<string, any> = {
    root: {
      children: property.options.map(option => option.id),
      id: property.id,
      isGroup: true,
      name: property.name,
      parent: null,
    },
  };

  const insertOption = (parentId: string) => (option: Option) => {
    let children: string[] = [];
    let isGroup = false;
    if (option.children) {
      children = option.children.map(option => option.id);
      isGroup = true;
      option.children.forEach(insertOption(option.id));
    }

    result[option.id] = {
      children,
      id: option.id,
      isGroup,
      name: option.name,
      parent: parentId,
    };
  };

  property.options.forEach(insertOption('root'));

  return result;
};

export function convertAnnotationTypeName(name: string): string {
  switch (name) {
    case 'rbox':
      return 'rotated box';
    default:
      return name;
  }
}
