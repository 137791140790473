import { useTranslation } from 'react-i18next';

import { Check, ChevronBigRight } from '@superb-ai/icons';
import { Box, Icon, ProgressBar, Typography } from '@superb-ai/ui';

import { formatDuration } from '../../../utils/date';
import { useEmbeddingMonitoringContext } from '../contexts/EmbeddingMonitoringContext';

const embeddingSteps = ['embed', 'clustering', 'cluster_assign', 'done'] as const;
type EmbeddingStep = (typeof embeddingSteps)[number];

export type EmbeddingStatus = {
  stage: EmbeddingStep;
  state: 'pending' | 'in_progress' | 'finished';
  remainingTime?: number;
  progress: number;
};

export default function EmbeddingMonitoringBanner() {
  const { t } = useTranslation();

  const { datasetEmbeddingStatus } = useEmbeddingMonitoringContext();
  const showBanner = datasetEmbeddingStatus && datasetEmbeddingStatus.stage !== 'done';

  const currentStep = datasetEmbeddingStatus?.stage || 'embed'; // for now, consider embedding generation is stuck in first stage if we can't get embedding status (TODO)
  const remainingTimeDetail =
    datasetEmbeddingStatus?.remainingTime !== undefined
      ? formatDuration(datasetEmbeddingStatus?.remainingTime)
      : t('curate.embeddingMonitoring.calculatingRemainingTime');
  const progress = datasetEmbeddingStatus?.progress || 0;

  function StepChip({
    number,
    status,
  }: {
    number: number;
    status: 'inProgress' | 'default' | 'done';
  }) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="100%"
        backgroundColor={
          status === 'done' ? 'green' : status === 'inProgress' ? 'yellow' : 'transparent'
        }
        color={status === 'default' ? 'gray-300' : 'white'}
        style={{ width: '24px', height: '24px' }}
        border={status === 'default' ? '2px solid' : undefined}
        borderColor={status === 'default' ? 'gray-250' : 'transparent'}
      >
        {status === 'done' ? (
          <Icon icon={Check} />
        ) : (
          <Typography variant="m-regular">{number}</Typography>
        )}
      </Box>
    );
  }
  function ProgressStepCard({
    step,
    progressPercentage,
  }: {
    step: EmbeddingStep;
    progressPercentage: number;
  }) {
    const isCurrentStep = step === currentStep;
    const isDoneStep = embeddingSteps.indexOf(step) < embeddingSteps.indexOf(currentStep);
    return (
      <Box display="flex" alignItems="center" gap={1} style={isCurrentStep ? { flex: 1 } : {}}>
        <StepChip
          number={embeddingSteps.indexOf(step) + 1}
          status={isCurrentStep ? 'inProgress' : isDoneStep ? 'done' : 'default'}
        />
        <Box display="flex" flexDirection="column" gap={0.5} style={{ flex: 1 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gap={0.5}>
            <Typography variant="m-medium">
              {t(
                `curate.embeddingMonitoring.step.${step}.${
                  isCurrentStep ? 'inProgress' : 'default'
                }`,
              )}
            </Typography>
            {isCurrentStep && (
              <Typography variant="m-medium" color="gray-300">
                {remainingTimeDetail}
              </Typography>
            )}
          </Box>
          {isCurrentStep && (
            <ProgressBar
              size="small"
              indeterminate={!progressPercentage}
              value={progressPercentage}
              animated
            />
          )}
        </Box>
      </Box>
    );
  }
  // if (imageTotalCount === imageProcessingCount && objectTotalCount === objectProcessingCount) {
  //   return <></>;
  // }

  return showBanner ? (
    <Box
      backgroundColor="gray-100"
      py={1}
      px={2}
      mt={1}
      display="flex"
      alignItems="center"
      gap={0.5}
      borderRadius="2px"
    >
      <ProgressStepCard step="embed" progressPercentage={progress} />
      <Icon icon={ChevronBigRight} color="gray-250" />
      <ProgressStepCard step="clustering" progressPercentage={progress} />
      <Icon icon={ChevronBigRight} color="gray-250" />
      <ProgressStepCard step="cluster_assign" progressPercentage={progress} />
    </Box>
  ) : (
    <></>
  );
}
