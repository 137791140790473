import { RadioButtons } from '@superb-ai/norwegian-forest';
// import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';
import { Box } from '@superb-ai/ui';

interface Option<V extends string = string> {
  Label: () => JSX.Element;
  value: V;
  disabled?: boolean;
}
import {
  CURATE_WHAT_TO_LABEL,
  CurationType,
  EmbeddingType,
  IMAGE,
  OBJECT,
} from '../../../../../../../../consts/AutoCurateConst';
import {
  embeddingTypes_curate_label,
  embeddingTypes_find_mislabels,
} from '../../UploadModal/steps/config';
export default function EmbeddingTypeSteps({
  embeddingType,
  handleChangeOption,
  labeledDataCount,
  unlabeledDataCount,
  samplingMethod,
}: {
  embeddingType: EmbeddingType;
  handleChangeOption: (value: EmbeddingType) => void;
  labeledDataCount: number;
  unlabeledDataCount: number;
  samplingMethod: CurationType;
}): JSX.Element {
  const options =
    samplingMethod === CURATE_WHAT_TO_LABEL
      ? (Object.values(embeddingTypes_curate_label) as Option<EmbeddingType>[]).map(option => {
          const newLabel = option.Label();
          if (labeledDataCount === 0 && option.value === OBJECT)
            return { ...option, label: newLabel, disabled: true };
          else if (unlabeledDataCount === 0 && option.value === IMAGE)
            return { ...option, label: newLabel, disabled: true };
          else return { ...option, label: newLabel };
        })
      : (Object.values(embeddingTypes_find_mislabels) as Option<EmbeddingType>[]).map(option => {
          const newLabel = option.Label();
          if (labeledDataCount === 0 && option.value === OBJECT)
            return { ...option, label: newLabel, disabled: true };
          else if (unlabeledDataCount === 0 && option.value === IMAGE)
            return { ...option, label: newLabel, disabled: true };
          else return { ...option, label: newLabel };
        });

  return (
    <Box>
      <RadioButtons
        options={options}
        value={embeddingType || IMAGE}
        onChange={handleChangeOption}
      />
    </Box>
  );
}
