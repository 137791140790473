import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import * as MUI from '@mui/material';
import {
  AutoLabel,
  AutolabelCreate,
  ChartVisualize,
  ChevronDown,
  CommentClear,
  ConsensusSplit,
  CurateLogo,
  Curation,
  Transfer,
  Trash,
  UserUnassign,
} from '@superb-ai/icons';
import { Box, ButtonGroup, DropdownMenu, Link, useAlertModal } from '@superb-ai/norwegian-forest';
import {
  Box as BoxV2,
  Button,
  Checkbox,
  Icon,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { concat, filter, includes, isEmpty } from 'lodash';

import { curateSupportedWorkapps } from '../../../../apps/projects/project/autoLabel/cal/support';
import CurationModal from '../../../../apps/projects/project/labels/actions/CurationModal';
import { VisualizeAnalyticsDialog } from '../../../../apps/projects/project/labels/actions/VisualizeAnalyticsDialog';
import SyncWithCurateModal from '../../../../apps/projects/project/labels/SyncWithCurateModal';
import { DELETE_LABEL, UPGRADE_PLAN } from '../../../../consts/ModalMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useCalAvailabilityInfo } from '../../../../contexts/CalAvailabilityContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useLabelsInfo } from '../../../../contexts/LabelsContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import {
  hasSufficientAiCredits,
  hasSufficientCurateVolume,
} from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import { getUrl } from '../../../../routes/util';
import CommandsService from '../../../../services/CommandsService';
import { LabelData } from '../../../../types/labelTypes';
import { AscDesc } from '../../../../types/muiTypes';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import WorkappUnion from '../../../../union/WorkappUnion';
import { openChatWidget } from '../../../../utils/chatWidget';
import { formatCount } from '../../../../utils/numberFormat';
import ParamUtils from '../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../utils/router-utils';
import { renderButton, renderMenuItem } from '../../../elements/buttonMenu/render';
import { ButtonDef } from '../../../elements/buttonMenu/types';
import Emoji from '../../../elements/Emoji';
import TablePagination from '../../../elements/TablePagination';
import FilterLayout from '../filter/FilterLayout';
import AssignDialog from './AssignDialog';
import AutoLabelDialog from './AutoLabelDialog';
import ChangeStatusDialog from './ChangeStatusDialog';
import ConsensusDialog from './ConsensusDialog';
import EditTagsDialog from './EditTagsDialog';
import ExportDialog from './ExportDialog';
import Pagination from './Pagination';
import { SendToCurateDialog } from './SendToCurateDialog';
import ThumbnailView from './ThumbnailView';
import UnassignDialog from './UnassignDialog';

const Layout = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const history = useHistory();
  const labelsInfo = useLabelsInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const commandContext = useLabelCommandContext();
  const projectInfo = useProjectInfo();
  const urlSearchParams = useSearchParams();
  const { tagIds, useAutoLabel } = projectInfo;
  const { allowAdvancedQa } = projectInfo.project.settings;
  const embeddingStoreBeta = useFeatureFlag('embeddingStoreBeta');
  const customizeAnalyticsBeta = useFeatureFlag('customizeAnalytics');

  const calInfo = useCalAvailabilityInfo();
  const {
    requiredLabelCountForCreatingCAL,
    canUseCustomAutoLabel,
    getIsOverLabelCountForCreatingCAL,
  } = calInfo;

  const {
    getLabels,
    isDesc,
    orderBy,
    checkedLabels,
    setCheckedLabels,
    expandedLabels,
    setExpandedLabels,
    isLoadingLabels: isLoading,
    loadingFinishedAt,
    isAllLabelsChecked,
    setIsAllLabelsChecked,
  } = labelsInfo;

  const [isAutoLabelDialogOpen, setIsAutoLabelDialogOpen] = useState(false);
  const [isChangeStatusDialogOpen, setIsChangeStatusDialogOpen] = useState(false);
  const [isEditTagsDialogOpen, setIsEditTagsDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [isCreateCALDialogOpen, setIsCreateCALDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isUnassignDialogOpen, setIsUnassignDialogOpen] = useState(false);
  const [isAssignReviewDialogOpen, setIsAssignReviewDialogOpen] = useState(false);
  const [isUnassignReviewDialogOpen, setIsUnassignReviewDialogOpen] = useState(false);
  const [isConsensusDialogOpen, setIsConsensusDialogOpen] = useState(false);
  const [isCurationDialogOpen, setIsCurationDialogOpen] = useState(false);
  const sendToCurateDialog = useDialogState();
  const syncWithCurateDialog = useDialogState();
  const visualizeAnalyticsDialog = useDialogState();
  const [hasAutoLabelCapacity, setHasAutoLabelCapacity] = useState(true);

  useEffect(() => {
    setIsAllLabelsChecked(false);
  }, []);

  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const isNoLabelChecked = isEmpty(checkedLabels) && !isAllLabelsChecked;
  const isOwnerOrAdmin =
    UserRoleUnion.isOwner(authInfo.role) || UserRoleUnion.isAdmin(authInfo.role);

  const meteringAiCredit = useMetering('common:ai-credit');
  const meteringDataVolume = useMetering('curate:data-volume');

  const getIsButtonDisabled = (allLabelShouldBeChecked: boolean) => {
    if (allLabelShouldBeChecked)
      return !labelsInfo.totalCount || isNoLabelChecked || authInfo.isGuest;
    return !labelsInfo.totalCount || authInfo.isGuest;
  };

  const getIsAutoLabelButtonDisable = () => {
    return getIsButtonDisabled(true) || !useAutoLabel || authInfo.isGuest || !isOwnerOrAdmin;
  };

  const checkedLabelCount = isAllLabelsChecked ? labelsInfo.totalCount : checkedLabels.length;

  const checkedVisibleLabels = labelsInfo.labels.filter(({ id }) => checkedLabels.includes(id));
  const isGlobalChecked =
    checkedVisibleLabels.length > 0 &&
    checkedVisibleLabels.length >=
      Number(routeInfo.params.pageSize ? routeInfo.params.pageSize : '10');

  const handleExpandLabel = async (label: LabelData, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedLabels(expandedLabels.filter(({ id }) => id !== label.id));
      // Remove collapsed labels from checkedLabels
      const removeFromSelection = (labelsInfo.relatedLabelsByAssetId[label.asset.id] || []).flatMap(
        ({ id }) => (id !== label.id ? [id] : []), // keep rootLabel
      );
      setCheckedLabels(checkedLabels.filter(id => removeFromSelection.indexOf(id) === -1));
    } else {
      const results = await labelsInfo.getRelatedLabels([label]);
      if (results.get(label)) {
        setExpandedLabels([...labelsInfo.expandedLabels, label]);
      }
    }
  };

  const handleExpandAll = async (shouldExpand: boolean) => {
    if (!shouldExpand) {
      setExpandedLabels([]);
      return;
    }
    const results = await labelsInfo.getRelatedLabels([...labelsInfo.rootLabels]);
    setExpandedLabels([...results.keys()]);
  };

  const handleClickGlobalCheckbox = () => {
    const pageSize = routeInfo.params.pageSize ? routeInfo.params.pageSize : '10';
    if (isGlobalChecked) {
      setCheckedLabels(
        checkedLabels.filter(id => labelsInfo.labels.findIndex(label => label.id === id) === -1),
      );
      return;
    }
    if (labelsInfo.totalCount <= pageSize) {
      setIsAllLabelsChecked(true);
    }
    setCheckedLabels(labelsInfo.labels.map(label => label.id));
  };

  const handleClickCheckbox = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (includes(checkedLabels, id)) {
      if (isAllLabelsChecked) {
        setIsAllLabelsChecked(false);
      }
      setCheckedLabels(filter(checkedLabels, checkedItem => checkedItem !== id));
      if (isAllLabelsChecked) {
        setIsAllLabelsChecked(false);
      }
      return;
    }

    setCheckedLabels(concat(checkedLabels, [id]));
  };

  const handleClickSelectAllLabels = (isAllLabelsChecked: boolean) => () => {
    setIsAllLabelsChecked(isAllLabelsChecked);
    if (!isAllLabelsChecked) {
      setCheckedLabels([]);
    }
  };

  const handleClickExport = useCallback(() => {
    setIsExportDialogOpen(true);
  }, []);

  const handleClickAutoLabel = () => {
    if (!hasSufficientAiCredits(meteringAiCredit)) {
      const handleCloseAutoLabelLimit = () => {
        setHasAutoLabelCapacity(true);
        closeModal();
      };

      openModal({
        ...UPGRADE_PLAN({
          t,
          history,
          accountName: routeInfo.urlMatchInfo.accountName,
          closeModal: handleCloseAutoLabelLimit,
        }),
      });
      return;
    }

    setIsAutoLabelDialogOpen(true);
  };

  const handleClickEditTags = useCallback(() => {
    setIsEditTagsDialogOpen(true);
  }, []);

  const handleClickAssign = useCallback(() => {
    setIsAssignDialogOpen(true);
  }, []);

  const handleClickAssignReview = useCallback(() => {
    setIsAssignReviewDialogOpen(true);
  }, []);

  const handleClickUnassign = useCallback(() => {
    setIsUnassignDialogOpen(true);
  }, []);

  const handleClickUnassignReview = useCallback(() => {
    setIsUnassignReviewDialogOpen(true);
  }, []);

  const filterApiParams = useMemo(
    () =>
      ParamUtils.getApiParamsForFilter({
        filterParams: urlSearchParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      }),
    [urlSearchParams, tagIds, projectInfo.project.workapp],
  );

  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };

  const actionParams = !isAllLabelsChecked
    ? { idIn: checkedLabels }
    : { ...filterApiParams, ...searchParams };

  const deleteLabels = async () => {
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'LABELS_DELETE',
      projectId: routeInfo.urlMatchInfo.projectId,
      params: actionParams,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
  };

  const handleClickDelete = () => {
    const count = isAllLabelsChecked ? labelsInfo.totalCount : checkedLabels.length;

    openModal({
      ...DELETE_LABEL({ t, count }),
      mainButton: {
        text: t('button.delete'),
        onClick: deleteLabels,
      },
      subButton: { text: t('button.cancel'), onClick: closeModal },
    });
  };

  const handleRequestSort = (property: string, order: AscDesc) => {
    if (checkedLabels.length > 0) {
      setCheckedLabels([]);
    }

    const nextIsDesc = order === 'desc';

    const nextSearchParams = new URLSearchParams([
      ...urlSearchParams.filter(
        ([key]) => !['searchAfter', 'searchBefore', 'page', 'searchLast', 'ordering'].includes(key),
      ),
      ['ordering', nextIsDesc ? `-${property}` : `${property}`],
    ]);

    routeInfo.history.push(`?${nextSearchParams.toString()}`);
  };

  if (!hasAutoLabelCapacity) {
    const handleCloseAutoLabelLimit = () => {
      setHasAutoLabelCapacity(true);
      closeModal();
    };

    openModal({
      ...UPGRADE_PLAN({
        t,
        history,
        accountName: routeInfo.urlMatchInfo.accountName,
        closeModal: handleCloseAutoLabelLimit,
      }),
    });
  }

  useEffect(() => {
    window.CommandBar?.addCallback('labelsExport', () => handleClickExport());
    window.CommandBar?.addCallback('labelsAssign', () => handleClickAssign());
    window.CommandBar?.addCallback('labelsUnassign', () => handleClickUnassign());
    window.CommandBar?.addCallback('labelsTags', () => handleClickEditTags());
    return () => {
      window.CommandBar?.removeCallback('labelsExport');
      window.CommandBar?.removeCallback('labelsAssign');
      window.CommandBar?.removeCallback('labelsUnassign');
      window.CommandBar?.removeCallback('labelsTags');
    };
  }, [handleClickExport, handleClickAssign, handleClickEditTags, handleClickUnassign]);

  const AUTO_LABEL_NOT_CONFIGURED = t('labels.messages.autoLabelNotConfigured');
  const PLEASE_SELECT_LABELS = t('labels.messages.pleaseSelectLabels');
  const ADVANCED_QA_DISABLED = t('labels.messages.advancedQaDisabled');

  const bulkButtonDisabled = getIsButtonDisabled(true);
  const nonBulkButtonDisabled = getIsButtonDisabled(false);
  const advancedQaVisible = allowAdvancedQa && isOwnerOrAdmin;
  const autoLabelDisabled = getIsAutoLabelButtonDisable();
  const reviewVisible = WorkappUnion.isSiesta(projectInfo?.project?.workapp);

  const calSelectMoreLabels = !getIsOverLabelCountForCreatingCAL(checkedLabelCount);
  const calDisabled = calSelectMoreLabels || nonBulkButtonDisabled || !canUseCustomAutoLabel;

  const sendToCurateSupported =
    curateSupportedWorkapps.includes(projectInfo.project.workapp) &&
    !projectInfo.project.curateDatasetId;

  const isCurateVolumeSufficient = hasSufficientCurateVolume(meteringDataVolume, {
    minimum: isAllLabelsChecked ? labelsInfo.totalCount : checkedLabelCount,
  });
  const isCurateDisabled = meteringDataVolume.maxQuantity <= 0;

  const autoLabelSelectLabelsTooltip = (
    <span>
      {PLEASE_SELECT_LABELS} <br />
    </span>
  );
  const autoLabelNotConfiguredTooltip = (
    <span>
      {AUTO_LABEL_NOT_CONFIGURED} <br />
      <Emoji symbol="👉" />{' '}
      <Link
        underlined
        to={getUrl([
          routeInfo.urlMatchInfo.accountName,
          'project',
          routeInfo.urlMatchInfo.projectId,
          'auto-label',
          'settings',
        ])}
      >
        {t('autoLabel.settings.title')}
      </Link>
    </span>
  );
  const curateTooltip =
    !isCurateVolumeSufficient &&
    (isCurateDisabled ? (
      <span>
        {t('labels.dialogs.sendToCurate.curateNotEnabled')}{' '}
        <Link to={`/${authInfo.accountName}/curate`}>
          <LinkTypography>{t('curate.button.learnMore')}</LinkTypography>
        </Link>
      </span>
    ) : (
      <span>
        <Trans
          t={t}
          i18nKey="labels.sendToCurate.volumeWarning"
          values={{ leftQuantity: meteringDataVolume.leftQuantity }}
        />
        <LinkTypography onClick={() => openChatWidget()}> {t('text.contactUs')}</LinkTypography>
      </span>
    ));

  // All possible buttons
  const Buttons: Record<string, ButtonDef> = {
    Curate: {
      label: t('labels.button.curation'),
      icon: Curation,
      onClick: () => setIsCurationDialogOpen(true),
      visible: embeddingStoreBeta,
    },
    ConsensusLabeling: {
      label: t('labels.button.consensusLabeling'),
      icon: ConsensusSplit,
      disabled: bulkButtonDisabled || !allowAdvancedQa,
      onClick: () => setIsConsensusDialogOpen(true),
      tooltip: <span>{ADVANCED_QA_DISABLED}</span>,
      visible: advancedQaVisible,
    },
    AutoLabel: {
      label: t('labels.button.autoLabel'),
      icon: AutoLabel,
      disabled: autoLabelDisabled,
      onClick: handleClickAutoLabel,
      tooltip:
        useAutoLabel && isNoLabelChecked
          ? autoLabelSelectLabelsTooltip
          : autoLabelNotConfiguredTooltip,
      visible: authInfo.tier !== 'FREE',
    },
    Assign: {
      label: t('labels.button.assignToLabeler'),
      disabled: bulkButtonDisabled,
      onClick: handleClickAssign,
    },
    RequestReview: {
      label: t('labels.button.requestReview'),
      disabled: bulkButtonDisabled,
      onClick: handleClickAssignReview,
    },
    EditTags: {
      label: t('labels.button.tag'),
      disabled: bulkButtonDisabled,
      onClick: handleClickEditTags,
    },
    Export: {
      label: t('labels.button.export'),
      disabled: nonBulkButtonDisabled,
      onClick: handleClickExport,
    },
    SendToCurate: {
      label: t('labels.button.sendToCurate'),
      icon: CurateLogo,
      disabled: bulkButtonDisabled || isCurateDisabled || !isCurateVolumeSufficient,
      tooltip: curateTooltip,
      onClick: () => sendToCurateDialog.show(),
      visible: sendToCurateSupported,
    },
    SyncWithCurate: {
      label: t('projects.button.syncWithCurate'),
      icon: CurateLogo,
      disabled: bulkButtonDisabled || isCurateDisabled || !isCurateVolumeSufficient,
      tooltip: curateTooltip,
      onClick: () => syncWithCurateDialog.show(),
      visible: !!projectInfo.project.curateDatasetId,
    },
    Charts: {
      label: t('labels.button.visualize'),
      icon: ChartVisualize,
      onClick: () => visualizeAnalyticsDialog.show(),
      disabled: labelsInfo.totalCount === 0,
      visible: customizeAnalyticsBeta,
    },
    UnassignLabeler: {
      label: t('labels.button.unassignLabeler'),
      icon: UserUnassign,
      disabled: bulkButtonDisabled,
      onClick: handleClickUnassign,
    },
    UnassignReviewer: {
      label: t('labels.button.cancelReviewRequest'),
      icon: CommentClear,
      disabled: bulkButtonDisabled,
      onClick: handleClickUnassignReview,
    },
    ChangeStatus: {
      label: t('labels.button.changeStatus'),
      icon: Transfer,
      disabled: bulkButtonDisabled,
      onClick: () => setIsChangeStatusDialogOpen(true),
    },
    CreateCAL: {
      label: t('labels.button.createCustomAutoLabel'),
      icon: AutolabelCreate,
      disabled: calDisabled,
      onClick: () => setIsCreateCALDialogOpen(true),
      tooltip: (
        <span>
          {calSelectMoreLabels
            ? t('labels.messages.requiresNumberOfLabels', {
                count: requiredLabelCountForCreatingCAL,
              })
            : t('labels.messages.notAvailableInThisProject')}
        </span>
      ),
    },
    Delete: {
      label: '',
      icon: Trash,
      disabled: bulkButtonDisabled,
      onClick: handleClickDelete,
      color: 'primary',
    },
  };

  // Buttons available for user role
  const getAvailableButtons = () => {
    const role = authInfo.projectRole ?? authInfo.role ?? 'labeler';
    if (['owner', 'admin', 'system admin'].includes(role)) {
      return {
        main: [
          Buttons.Charts,
          Buttons.Curate,
          Buttons.ConsensusLabeling,
          Buttons.SendToCurate,
          Buttons.SyncWithCurate,
          Buttons.AutoLabel,
          Buttons.Assign,
          Buttons.RequestReview,
          Buttons.EditTags,
          Buttons.Export,
        ],
        dropdown: [
          Buttons.UnassignLabeler,
          Buttons.UnassignReviewer,
          Buttons.ChangeStatus,
          Buttons.CreateCAL,
        ],
      };
    }
    if (['manager'].includes(role)) {
      return {
        main: [Buttons.Assign, Buttons.RequestReview, Buttons.EditTags],
        dropdown: [Buttons.UnassignLabeler, Buttons.UnassignReviewer, Buttons.ChangeStatus],
      };
    }
    if (['reviewer'].includes(role)) {
      return {
        main: [Buttons.EditTags],
        dropdown: [],
      };
    }
    return { main: [], dropdown: [] };
  };

  function addDefaultDisabledTooltip(def: ButtonDef) {
    const tooltip = !authInfo.isLoggedIn
      ? t('labels.messages.signInToUseFeature')
      : authInfo.isGuest
      ? t('labels.messages.projectMembersOnly')
      : def.tooltip;
    return { ...def, tooltip };
  }

  // Render visible available buttons
  const getButtons = () => {
    const availableButtons = getAvailableButtons();
    if (!availableButtons?.main.length && !availableButtons?.dropdown.length) {
      return null;
    }
    const deleteButtonVisible = ['owner', 'admin', 'system admin'].includes(
      authInfo.projectRole ?? authInfo.role ?? 'labeler',
    );
    return (
      <ButtonGroup gap={0.5}>
        {availableButtons.main.map(
          def => (def.visible ?? true) && renderButton(addDefaultDisabledTooltip(def)),
        )}
        {Boolean(availableButtons.dropdown.length) && (
          <DropdownMenu
            AnchorElement={
              <Button>
                {t('labels.button.more')}
                <Icon icon={ChevronDown} />
              </Button>
            }
            css={{ paddingRight: 0 }}
          >
            {availableButtons.dropdown.map(
              def => (def.visible ?? true) && renderMenuItem(addDefaultDisabledTooltip(def)),
            )}
          </DropdownMenu>
        )}
        {deleteButtonVisible && renderButton(Buttons.Delete)}
      </ButtonGroup>
    );
  };

  return (
    <>
      <MUI.Grid container spacing={2} direction="column">
        <MUI.Grid item xs={12}>
          <FilterLayout />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <BoxV2 display="flex" alignItems="center" gap={0.5}>
              <BoxV2 pl={0.5}>
                <Checkbox
                  value={isAllLabelsChecked ? true : checkedLabelCount > 0 ? 'mixed' : false}
                  onClick={handleClickSelectAllLabels(!isAllLabelsChecked)}
                />
              </BoxV2>
              <BoxV2 display="flex" alignItems="center" gap={1}>
                <Typography variant="m-medium">{t('shared.total')}</Typography>
                <Typography variant="m-regular">{formatCount(labelsInfo.totalCount)}</Typography>
                {labelsInfo.totalUniqueCount !== labelsInfo.totalCount && (
                  <Typography variant="m-regular">
                    {t('labels.labelsForDataItems', {
                      dataItemCount: t('data.dataItemCount', {
                        count: labelsInfo.totalUniqueCount,
                      }),
                    })}
                  </Typography>
                )}
                <BoxV2 backgroundColor="gray-250" style={{ width: '1px', height: '8px' }} />
                <Typography variant="m-medium">{t('curate.button.selected')}</Typography>
                <Typography variant="m-regular">
                  {formatCount(isAllLabelsChecked ? labelsInfo.totalCount : checkedLabelCount)}
                </Typography>
              </BoxV2>
            </BoxV2>
            <Box>{getButtons()}</Box>
          </MUI.Box>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <ThumbnailView
            isDesc={isDesc}
            orderBy={(orderBy as AscDesc) || undefined}
            labels={labelsInfo.rootLabels}
            visibleLabels={labelsInfo.labels}
            labelsByAssetId={labelsInfo.relatedLabelsByAssetId}
            loadingLabels={labelsInfo.loadingLabels}
            expandedLabels={labelsInfo.expandedLabels}
            onExpandLabel={handleExpandLabel}
            onExpandAll={handleExpandAll}
            totalCount={labelsInfo.totalCount}
            checkedLabels={checkedLabels}
            isAllLabelsChecked={isAllLabelsChecked}
            isGlobalChecked={isGlobalChecked}
            isLoading={isLoading}
            loadingFinishedAt={loadingFinishedAt}
            advancedQaVisible={advancedQaVisible}
            reviewVisible={reviewVisible}
            onRequestSort={handleRequestSort}
            onClickCheckbox={handleClickCheckbox}
            onClickGlobalCheckbox={handleClickGlobalCheckbox}
            onClickSelectAllLabels={handleClickSelectAllLabels}
            projectName={projectInfo.project.name}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          {enabledLoki ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              style={{ paddingRight: '60px' }}
            >
              <Pagination totalCount={labelsInfo.totalUniqueCount} />
            </Box>
          ) : (
            <TablePagination totalCount={labelsInfo.totalUniqueCount} />
          )}
        </MUI.Grid>
        <ExportDialog
          isOpen={isExportDialogOpen}
          setIsOpen={setIsExportDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          totalCount={labelsInfo.totalCount}
          isAllLabelsChecked={isAllLabelsChecked}
          filterApiParams={filterApiParams}
          variant="export"
        />
        <ExportDialog
          isOpen={isCreateCALDialogOpen}
          setIsOpen={setIsCreateCALDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          totalCount={labelsInfo.totalCount}
          isAllLabelsChecked={isAllLabelsChecked}
          filterApiParams={filterApiParams}
          variant="cal"
        />
        <EditTagsDialog
          isOpen={isEditTagsDialogOpen}
          setIsOpen={setIsEditTagsDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          getLabels={getLabels}
        />
        <ConsensusDialog
          isOpen={isConsensusDialogOpen}
          setIsOpen={setIsConsensusDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          totalUniqueCount={labelsInfo.totalUniqueCount}
          labels={labelsInfo.rootLabels}
          getLabels={getLabels}
        />
        <AssignDialog
          isOpen={isAssignDialogOpen}
          setIsOpen={setIsAssignDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          checkedLabelsCount={checkedLabelCount}
          getLabels={getLabels}
          type="labeler"
        />
        <AssignDialog
          isOpen={isAssignReviewDialogOpen}
          setIsOpen={setIsAssignReviewDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          checkedLabelsCount={checkedLabelCount}
          getLabels={getLabels}
          type="reviewer"
        />
        <UnassignDialog
          isOpen={isUnassignDialogOpen}
          setIsOpen={setIsUnassignDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          checkedLabelsCount={checkedLabelCount}
          getLabels={getLabels}
          type="labeler"
        />
        <UnassignDialog
          isOpen={isUnassignReviewDialogOpen}
          setIsOpen={setIsUnassignReviewDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          isAllLabelsChecked={isAllLabelsChecked}
          checkedLabelsCount={checkedLabelCount}
          getLabels={getLabels}
          type="reviewer"
        />
        <ChangeStatusDialog
          isOpen={isChangeStatusDialogOpen}
          setIsOpen={setIsChangeStatusDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          totalCount={labelsInfo.totalCount}
          isAllLabelsChecked={isAllLabelsChecked}
          getLabels={getLabels}
        />
        <AutoLabelDialog
          isOpen={isAutoLabelDialogOpen}
          setIsOpen={setIsAutoLabelDialogOpen}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          totalCount={labelsInfo.totalCount}
          isAllLabelsChecked={isAllLabelsChecked}
          setIsAllLabelsChecked={setIsAllLabelsChecked}
          filterApiParams={filterApiParams}
        />
        <SendToCurateDialog
          state={sendToCurateDialog}
          checkedLabels={checkedLabels}
          setCheckedLabels={setCheckedLabels}
          totalCount={labelsInfo.totalCount}
          isAllLabelsChecked={isAllLabelsChecked}
          filterApiParams={filterApiParams}
        />
        <SyncWithCurateModal state={syncWithCurateDialog} filterApiParams={filterApiParams} />
        {embeddingStoreBeta && (
          <CurationModal
            isOpen={isCurationDialogOpen}
            setIsOpen={setIsCurationDialogOpen}
            checkedLabels={checkedLabels}
            setCheckedLabels={setCheckedLabels}
            totalCount={labelsInfo.totalCount}
            isAllLabelsChecked={isAllLabelsChecked}
            filterApiParams={filterApiParams}
          />
        )}
        {customizeAnalyticsBeta && (
          <VisualizeAnalyticsDialog
            state={visualizeAnalyticsDialog}
            checkedLabels={checkedLabels}
            setCheckedLabels={setCheckedLabels}
            totalCount={labelsInfo.totalCount}
            isAllLabelsChecked={isAllLabelsChecked}
            filterApiParams={filterApiParams}
          />
        )}
      </MUI.Grid>
    </>
  );
};

export default Layout;
