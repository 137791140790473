import React, { ReactElement, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '6px',
    height: '24px',
    borderRadius: '4px',
    '&.prev': {
      position: 'absolute',
      left: -6,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  },
}));

interface Props {
  dir: string;
  id: string;
  imageCategoryMap: any;
}

const CategoryItemDropBar = (props: Props): ReactElement => {
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { dir, id, imageCategoryMap } = props;

  const [isDragEnter, setIsDragEnter] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;
    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;
    setIsDragEnter(false);
  };

  const handleDrop = () => {
    if (!newProjectInfo.dragState || newProjectInfo.dragState.type !== 'imageCategory') return;
    const ids = Object.keys(newProjectInfo.selectedCategories);
    const dragInfo = { id, dir };

    helper.moveCategoryToGroup(newProjectInfo, ids, imageCategoryMap[id].parent, dragInfo);

    setIsDragEnter(false);
    newProjectInfo.setDragState(null);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default CategoryItemDropBar;
