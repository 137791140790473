import { getSupportedDataType } from '../../../../consts/DataTypeConst';
import { COMING_SOON } from '../../../../consts/FeatureMessage';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu';
import ProjectUtils from '../../../../utils/ProjectUtils';
import { ProjectCustomAutoLabelMenuItem } from './cal/MenuItem';
import Layout from './Layout';
import { ProjectAutoLabelSettingsMenuItem } from './settings/MenuItem';

export const ProjectAutoLabelMenuItem = CreateMenuItem({
  // name is not necessary for menu item group header
  path: 'auto-label',
  title: ({ t }) => t('autoLabel.title'),
  component: Layout,
  icon: 'autoLabel',
  children: [ProjectAutoLabelSettingsMenuItem, ProjectCustomAutoLabelMenuItem],
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo) || authInfo?.isGuest || false;
  },
  isEnabled({ authInfo, project }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo) && !!project;
  },
  isChildrenVisible({ project, authInfo }) {
    return !!project && authInfo?.tier !== 'FREE';
  },
  disabledMessage({ t, authInfo, project }) {
    if (!isOwnerOrAdminOrSystemAdmin(authInfo)) {
      return undefined;
    }
    return COMING_SOON(
      t,
      t('autoLabel.title'),
      project?.workapp
        ? `${
            getSupportedDataType(t)[ProjectUtils.getProjectDataType(project.workapp)].label
          } projects`
        : undefined,
    );
  },
});
