import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { useMetering } from '../../../../../queries/useMeteringQuery';
import { formatCount } from '../../../../../utils/numberFormat';
import DragAndDropPointCloud from '../../DragAndDropPointCloud';
import { UploadStepInfo } from './type';

const PointcloudSequenceDragAndDrop = (t: TFunction): UploadStepInfo => {
  const { files } = useUploadInfo();
  const { leftQuantity: dataCapacity } = useMetering('label:data-volume');

  return {
    title: t('data.upload.fileUpload.addFiles', {
      fileCount: formatCount(files.length),
      maxDataCount: formatCount(dataCapacity),
    }),
    isButtonEnabled: files.length > 0,
    summary: t('data.upload.fileUpload.filesAdded', { fileCount: formatCount(files.length) }),
    content: <DragAndDropPointCloud dataCapacity={dataCapacity} />,
  };
};

export default PointcloudSequenceDragAndDrop;
