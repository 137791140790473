import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { escapeRegExp } from 'lodash';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useItemSelection({
  multiple = false,
  value,
  onChange,
}: {
  multiple?: boolean;
  value: string[];
  onChange(items: string[]): void;
}) {
  const [selectedItems, _setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    _setSelectedItems(value);
  }, [value]);

  const toggleItem = (id: string, nextState?: boolean) => {
    _setSelectedItems(selectedItems => {
      if (nextState !== true && (selectedItems.includes(id) || nextState === false)) {
        const nextSelection = [...selectedItems];
        const index = nextSelection.indexOf(id);
        nextSelection.splice(index, 1);
        onChange(nextSelection);
        return nextSelection;
      }
      const nextSelection = multiple ? [...selectedItems, id] : [id];
      onChange(nextSelection);
      return nextSelection;
    });
  };

  const isItemSelected = (id: string) => {
    return selectedItems.includes(id);
  };

  const setSelectedItems = (ids: string[]) => {
    _setSelectedItems(ids);
    onChange(ids);
  };

  return {
    selectedItems,
    isItemSelected,
    toggleItem,
    setSelectedItems,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSearch<T>({
  items,
  predicate,
}: {
  items: T[];
  predicate: (term: string) => (item: T) => boolean;
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const visibleItems = useMemo(() => {
    if (!searchTerm) return items;
    return items.filter(predicate(searchTerm));
  }, [items, searchTerm]);

  const formatMatch = useCallback(
    (text: string, matchedPart: (text: string) => ReactNode): ReactNode => {
      if (!searchTerm) return [text];
      const regex = new RegExp(
        `(${escapeRegExp(searchTerm).replaceAll(/ (\w)/g, '|$1').replaceAll(' ', '')})`,
        'i',
      );
      const match = text.match(regex);
      if (!match) return text;
      const parts = text.split(regex);
      return parts.map(part => (part.match(regex) ? matchedPart(part) : part));
    },
    [searchTerm],
  );

  return {
    visibleItems,
    setSearchTerm,
    searchTerm,
    formatMatch,
  };
}
