import { FC } from 'react';

import { BoxProps } from '@superb-ai/norwegian-forest/dist/components/basic/foundation/Box';

export type IouTypeEnum = 'bbox' | 'segm';

export type CellComponentProps<T> = {
  columnIndex: number;
  rowIndex: number;
  columns: number;
  results: any[];
  iouType: IouTypeEnum;
  aspectRatio: number;
} & T;

export const IouTypeToAnnotationMapping: Record<IouTypeEnum, 'box' | 'mask'> = {
  bbox: 'box',
  segm: 'mask',
};

type Props<T> = {
  loadedItems: any[];
  itemData: { columns: number } & Record<string, any>;
  CellComponent?: FC<CellComponentProps<T>>;
  aspectRatio?: number;
  additionalHeight?: number;
};

type SizeProps = {
  width: number;
  height: number;
};

type InfiniteProps<T> = Props<T> & {
  loadItems: (startIndex: number, stopIndex: number) => Promise<void>;
  hasNextPage: boolean;
  additionalHeight?: number;
};

export type InfiniteWindowedGridContainerProps<T> = InfiniteProps<T> & {
  isLoading?: boolean;
  boxProps?: BoxProps;
};

export type InfiniteWindowedGridProps<T> = InfiniteProps<T> & SizeProps;

export type WindowedGridProps<T> = Props<T> & SizeProps;
