import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';
import { isUndefined } from 'lodash';

import { COLOR_CHIP_TAG_DEFAULT } from '../../../../../consts/ColorChips';
import { formatDuration } from '../../../../../utils/date';
import BaseCard from './BaseCard';
import { useCardStyle } from './cardStyle';

export type MiniCardProps = {
  title: string;
  value: number;
  isSiestaWorkapp: boolean;
  labelCount: number;
};

const MiniCard: React.FC<MiniCardProps> = ({ title, value, isSiestaWorkapp, labelCount }) => {
  const { t } = useTranslation();
  const classes = useCardStyle();

  const tooltipMessage = (isSiestaWorkapp: boolean, labelCount: number, avgTime: number) => {
    if (!isSiestaWorkapp) {
      return t('analytics.project.legacyAnnotationAppError');
    }
    if (labelCount > 0 && !avgTime) return t('analytics.project.cards.avgLabelingTime.info');
    return t('analytics.project.averageLabelingTime');
  };

  const labelingTime = (avgTime: number, labelCount: number, isSiestaWorkapp: boolean) => {
    if (!isSiestaWorkapp) return 'N/a';

    if (labelCount > 0 && isUndefined(avgTime)) return 0;
    return `${formatDuration(avgTime)}`;
  };

  return (
    <BaseCard
      icon={
        <Icon
          className={classes.dateCard}
          name="clock"
          size="26px"
          customColor={COLOR_CHIP_TAG_DEFAULT.LIGHTISH_BLUE}
        />
      }
    >
      <Box display="flex" alignItems="center" gap={0.25}>
        <Typography className={classes.cardTitle}>{title}</Typography>
        <Tooltip
          placement="right"
          anchorEl={
            <span style={{ display: 'inline-flex' }}>
              <Icon name="infoCircleOutline" color="cobalt" />
            </span>
          }
        >
          {tooltipMessage(isSiestaWorkapp, labelCount, value)}
        </Tooltip>
      </Box>
      <Typography className={classes.cardValue}>
        {labelingTime(value, labelCount, isSiestaWorkapp)}
      </Typography>
    </BaseCard>
  );
};

export default MiniCard;
