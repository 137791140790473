import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

import { ChevronDown, WarningFilled } from '@superb-ai/icons';
import { Collapse } from '@superb-ai/norwegian-forest';
import { Box, Checkbox, Icon, Tooltip, Typography } from '@superb-ai/ui';
import { omit, without } from 'lodash';

import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { METADATA_CARDINALITY_THRESHOLD } from './const';
import { getHighlightedText } from './utils';

export default function ObjectMetadataFilterCollapse({
  style,
  data,
  index,
  open,
  handleToggle,
}: ListChildComponentProps & {
  handleToggle: (index: number) => void;
  open: boolean;
}) {
  const { searchResultEntries, searchInput } = data as {
    searchResultEntries: [string, { count: number; options: string[] }][];
    searchInput?: string;
  };
  const metadata = searchResultEntries[index];
  const { t } = useTranslation();
  const key = metadata[0] || '';
  const valuesCount = metadata[1].count || 0;
  const values = metadata[1].options || [];

  const canOpenCollapse = valuesCount > 0 && valuesCount <= METADATA_CARDINALITY_THRESHOLD;

  const { selectedMetadata, setSelectedMetadata } = useObjectFilterContext();

  const selectedState =
    selectedMetadata && selectedMetadata[key]
      ? values.length > selectedMetadata[key].length
        ? 'mixed'
        : true
      : false;

  function handleClickKeyCheckbox(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (selectedMetadata && selectedMetadata[key]) {
      setSelectedMetadata(omit(selectedMetadata, key));
      return;
    }
    setSelectedMetadata({ ...selectedMetadata, [key]: values });
  }

  function handleClickValueCheckbox(e: MouseEvent<HTMLButtonElement>, value: string) {
    const oldSelectedValues = (selectedMetadata && selectedMetadata[key]) || [];
    if (oldSelectedValues.indexOf(value) >= 0) {
      const newSelectedValues = without(oldSelectedValues, value);
      setSelectedMetadata(
        newSelectedValues.length
          ? { ...selectedMetadata, [key]: newSelectedValues }
          : omit(selectedMetadata, key),
      );
      return;
    }
    setSelectedMetadata({ ...selectedMetadata, [key]: [...oldSelectedValues, value] });
  }

  return (
    <div style={style}>
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        backgroundColor="gray-100"
        pr={0.5}
        style={{ marginBottom: '2px' }}
        cursor={valuesCount <= METADATA_CARDINALITY_THRESHOLD ? 'pointer' : undefined}
        onClick={() => {
          if (!canOpenCollapse) return;
          handleToggle(index);
        }}
      >
        <Checkbox value={selectedState} onClick={handleClickKeyCheckbox} />
        <Typography variant="m-regular">{getHighlightedText(key, searchInput || '')}</Typography>
        {valuesCount <= METADATA_CARDINALITY_THRESHOLD ? (
          <Box
            display="flex"
            alignItems="center"
            ml="auto"
            style={{
              transition: 'all 0.1s ease-in-out',
              ...(open ? {} : { transform: 'rotate(-90deg)' }),
            }}
          >
            <Icon icon={ChevronDown} color={canOpenCollapse ? 'gray-400' : 'gray-300'} />
          </Box>
        ) : (
          <Tooltip content={t('curate.annotationFilter.tooManyValuesMessage')}>
            <Icon icon={WarningFilled} color="orange" />
          </Tooltip>
        )}
      </Box>
      <Collapse open={open}>
        <Box ml={2} style={{ marginTop: '-8px' }}>
          {values.map(value => (
            <Box key={`${key}-${value}`} display="flex" alignItems="center" gap={0.5}>
              <Checkbox
                value={
                  !!selectedMetadata &&
                  selectedMetadata[key] &&
                  selectedMetadata[key].includes(value)
                }
                onClick={e => handleClickValueCheckbox(e, value)}
              />
              <Typography
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                variant="m-regular"
              >
                {getHighlightedText(value.toString(), searchInput || '')}
              </Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </div>
  );
}
