import React, { useRef } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Minus } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import StringUtils from '../../../../utils/StringUtils';
import helper from './helper';
import ObjectClassItemDropBar from './ObjectClassItemDropBar';

const useStyles = makeStyles(() => ({
  outerBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&.depth-1': {
      padding: '0px 0px 0px 80px',
    },
  },
  box: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 6px 4px 14px',
    margin: '6px 0px',
    borderRadius: '6px',
    background: '#fff',
    boxSizing: 'border-box',
    border: 'solid 1px #fff',
    '&.selected': {
      border: 'solid 1px #ff625a',
    },
    cursor: 'pointer',
  },
  text: {
    fontSize: '11px',
    color: '#202020',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  annotationTypeTextBox: {
    width: '170px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

// eslint-disable-next-line
const CategoryItem = (props: any): React.ReactElement => {
  const objectClassRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { id, index, depth } = props;
  const objectClass = newProjectInfo.objectClassGroupMap[id];
  const isSelected = newProjectInfo.selectedObjectClassIds[id];

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    let nextSelectedObjectClassIds: { [key: string]: number } = {};
    if (e.metaKey || e.ctrlKey) {
      nextSelectedObjectClassIds = cloneDeep(newProjectInfo.selectedObjectClassIds);
      if (newProjectInfo.selectedObjectClassIds[id]) {
        delete nextSelectedObjectClassIds[id];
      } else {
        nextSelectedObjectClassIds[id] = new Date().getTime();
      }
    } else if (e.shiftKey) {
      nextSelectedObjectClassIds = cloneDeep(newProjectInfo.selectedObjectClassIds);
      const parent = newProjectInfo.objectClassGroupMap[objectClass.parent];

      const siblings = parent.children.filter(
        (item: any) => !newProjectInfo.objectClassGroupMap[item].children,
      );

      let front = -1;
      let cur = -1;
      let rear = -1;
      for (let i = 0; i < siblings.length; i++) {
        if (siblings[i] === id) {
          cur = i;
        } else if (nextSelectedObjectClassIds[siblings[i]]) {
          if (cur === -1) {
            front = i;
          } else {
            rear = i;
            break;
          }
        }
      }

      front = front !== -1 ? front : cur;
      rear = rear !== -1 ? rear : cur;
      for (let i = front; i <= rear; i++) {
        nextSelectedObjectClassIds[siblings[i]] = new Date().getTime();
      }
    } else if (newProjectInfo.selectedObjectClassIds[id]) {
      return;
    } else {
      nextSelectedObjectClassIds[id] = new Date().getTime();
    }
    newProjectInfo.setSelectedObjectClassIds(nextSelectedObjectClassIds);
  };

  const handleClickRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    helper.moveObjectClassesToGroup(newProjectInfo, [id], 'root', null);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (!objectClassRef || !objectClassRef.current) return;
    objectClassRef.current.style.cursor = 'grabbing';
    newProjectInfo.setDragState({ type: 'objectClass', id });
    if (!e?.dataTransfer) return;
    e.dataTransfer.setData('text', id);
    e.dataTransfer.setDragImage(objectClassRef.current, -10, -10);
  };

  const handleDragEnd = () => {
    if (!objectClassRef || !objectClassRef.current) return;
    newProjectInfo.setDragState(null);
    objectClassRef.current.style.cursor = 'pointer';
  };

  return (
    <MUI.Box className={`${classes.outerBox} depth-${depth} `}>
      {index === 0 ? <ObjectClassItemDropBar dir="prev" id={id} /> : null}
      <MUI.Box
        {...{ ref: objectClassRef }}
        className={`${classes.box} category ${isSelected ? 'selected' : ''}`}
        boxShadow={1}
        onMouseDown={handleMouseDown}
        draggable={!!isSelected}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <MUI.Typography className={classes.text}>{objectClass.name}</MUI.Typography>
        <MUI.Box className={classes.annotationTypeTextBox}>
          <MUI.Typography className={classes.text}>
            {StringUtils.parseConstToFirstUpper(objectClass.annotationType)}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
      {depth !== 0 ? (
        <IconButton
          icon={Minus}
          size="s"
          variant="text"
          onMouseDown={e => e.stopPropagation()}
          onClick={handleClickRemove}
          color="primary"
          style={{
            position: 'absolute',
            right: '28px',
          }}
        />
      ) : null}
      <ObjectClassItemDropBar dir="next" id={id} />
    </MUI.Box>
  );
};

export default CategoryItem;
