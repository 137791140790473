import { TFunction } from 'react-i18next';

import * as ShyCredit from '@superb-ai/shy-credit';
import { cloneDeep, difference, find, has, isEmpty, some, trimStart, upperFirst } from 'lodash';
import { WithSnackbarProps } from 'notistack';

import {
  getProjectSteps,
  ProjectSteps,
  ProjectStepsErrorMessage,
} from '../../../configs/NewProjectStepsConfig';
import {
  ANNOTATION_TYPE,
  initialFreeResponse,
  initialMultipleChoice,
  initialMultipleSelection,
  initialObjectClassGroupMap,
  initImageCategories,
  keypointImgUrlMap,
  PROPERTY_TYPE,
} from '../../../consts/NewProjectConst';
import {
  NAME_DUPLICATE,
  NAME_RANGE,
  NEW_PROJECT_CLASS_NAME_CAN_NOT_CONTAIN_SPECIAL_SYMBOL,
  OBJECT_CLASS_NAME_COMMA,
} from '../../../consts/SnackbarMessage';
import RegexUtils from '../../../utils/RegexUtils';
import StringUtils from '../../../utils/StringUtils';
import { NEW_PROJECT_PATH } from './paths';

type EnqueueSnackbar = WithSnackbarProps['enqueueSnackbar'];

// eslint-disable-next-line
const handleClickPrev = (
  newProjectInfo: any,
  routeInfo: any,
  pathname: string,
  projectId?: string,
): void => {
  const { steps, currentStep, setCurrentStep } = newProjectInfo;

  const segments = pathname.split('/');
  const isCreatePage = segments[segments.length - 2] === NEW_PROJECT_PATH;

  // innerStep이 있는 경우
  if (has(currentStep, 'innerStep') && currentStep.innerStep > 0) {
    const innerStepsList = find(steps, { activeStep: currentStep.activeStep }).contents;

    if (isCreatePage) {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${
          innerStepsList[currentStep.innerStep - 1].settingPage
        }`,
      );
    } else {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${
          projectId ?? routeInfo.urlMatchInfo.projectId
        }/edit_project/${innerStepsList[currentStep.innerStep - 1].settingPage}`,
      );
    }

    setCurrentStep(innerStepsList[currentStep.innerStep - 1]);
    return;
  }

  const prevStep = steps[currentStep.activeStep - 1];
  if (prevStep.hasInnerSteps) {
    const innerSteps = prevStep.contents;
    if (isCreatePage) {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${
          innerSteps[innerSteps.length - 1].settingPage
        }`,
      );
    } else {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${
          projectId ?? routeInfo.urlMatchInfo.projectId
        }/edit_project/${innerSteps[innerSteps.length - 1].settingPage}`,
      );
    }

    setCurrentStep(innerSteps[innerSteps.length - 1]);
    return;
  }

  if (isCreatePage) {
    routeInfo.history.replace(
      `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${prevStep.settingPage}`,
    );
  } else {
    routeInfo.history.replace(
      `/${routeInfo.urlMatchInfo.accountName}/label/project/${
        projectId ?? routeInfo.urlMatchInfo.projectId
      }/edit_project/${prevStep.settingPage}`,
    );
  }
  setCurrentStep(prevStep);
};

// eslint-disable-next-line
const handleClickNext = (
  newProjectInfo: any,
  routeInfo: any,
  pathname: string,
  projectId?: string,
): void => {
  const { steps, currentStep, setCurrentStep } = newProjectInfo;

  const segments = pathname.split('/');
  const isCreatePage = segments[segments.length - 2] === NEW_PROJECT_PATH;

  if (has(currentStep, 'innerStep')) {
    const innerStepsList = find(steps, {
      activeStep: currentStep.activeStep,
    }).contents;

    const currentStepUrl = innerStepsList[currentStep.innerStep + 1].settingPage;

    if (isCreatePage) {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${currentStepUrl}`,
      );
    } else {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${
          projectId ?? routeInfo.urlMatchInfo.projectId
        }/edit_project/${currentStepUrl}`,
      );
    }

    setCurrentStep(innerStepsList[currentStep.innerStep + 1]);
    return;
  }

  // @ts-ignore
  const nextStep = steps[currentStep.activeStep + 1];
  if (nextStep.hasInnerSteps) {
    const innerSteps = nextStep.contents;

    if (isCreatePage) {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${innerSteps[0].settingPage}`,
      );
    } else {
      routeInfo.history.replace(
        `/${routeInfo.urlMatchInfo.accountName}/label/project/${
          projectId ?? routeInfo.urlMatchInfo.projectId
        }/edit_project/${innerSteps[0].settingPage}`,
      );
    }

    setCurrentStep(innerSteps[0]);
    return;
  }

  if (isCreatePage) {
    routeInfo.history.replace(
      `/${routeInfo.urlMatchInfo.accountName}/label/new_project/${nextStep.settingPage}`,
    );
  } else {
    routeInfo.history.replace(
      `/${routeInfo.urlMatchInfo.accountName}/label/project/${
        projectId ?? routeInfo.urlMatchInfo.projectId
      }/edit_project/${nextStep.settingPage}`,
    );
  }

  setCurrentStep(nextStep);
};

const enqueueSnackbarWithInstance = ((ms: number) => {
  let canEnqueueSnackbar = true;
  type EnqueueSnackbarParameter = Parameters<EnqueueSnackbar>;
  return (enqueueSnackbar: EnqueueSnackbar) => {
    return (...[message, options]: EnqueueSnackbarParameter) => {
      if (!canEnqueueSnackbar) return;
      canEnqueueSnackbar = false;
      enqueueSnackbar(message, options);
      setTimeout(() => {
        canEnqueueSnackbar = true;
      }, ms);
    };
  };
})(1000);

const parseNameValue = (
  t: TFunction,
  str: string,
  type: string,
  enqueueSnackbar: EnqueueSnackbar,
): string => {
  const enqueueSnackbarWithTimeout = enqueueSnackbarWithInstance(enqueueSnackbar);
  const trimStr = trimStart(str);
  let hasSpecialSymbols = false;
  let nextStr = '';

  for (let i = 0; i < trimStr.length; i++) {
    const curChar = trimStr[i];
    if (RegexUtils.HAS_SPECIAL_SYMBOLS(curChar)) {
      hasSpecialSymbols = true;
      continue;
    }
    nextStr += curChar;
  }

  if (hasSpecialSymbols) {
    enqueueSnackbarWithTimeout(
      NEW_PROJECT_CLASS_NAME_CAN_NOT_CONTAIN_SPECIAL_SYMBOL({ t, name: type }),
      { variant: 'warning' },
    );
  }

  return nextStr;
};

const checkNameRule = ({
  t,
  type,
  prevValue,
  nextValue,
  sameHierarchyNameList,
  index,
  enqueueSnackbar,
}: {
  t: TFunction;
  type: string;
  prevValue: string;
  nextValue: string;
  sameHierarchyNameList: any;
  index: number;
  enqueueSnackbar: EnqueueSnackbar;
}): { result: boolean; state: string; name: string } => {
  if (typeof nextValue !== 'string') {
    return { result: false, state: 'typeError', name: prevValue };
  }
  const enqueueSnackbarWithTimeout = enqueueSnackbarWithInstance(enqueueSnackbar);
  const parsedNextValue = nextValue.trim();

  if (parsedNextValue === '') {
    return {
      result: false,
      state: 'empty',
      name: prevValue,
    };
  }

  if (prevValue === parsedNextValue) {
    return { result: false, state: 'noChange', name: prevValue };
  }

  const isDuplicated = some(
    sameHierarchyNameList,
    (item, idx: number) => item === parsedNextValue && idx !== index,
  );

  if (isDuplicated) {
    enqueueSnackbarWithTimeout(NAME_DUPLICATE({ t, name: parsedNextValue }), {
      variant: 'warning',
    });

    return { result: false, state: 'duplicate', name: prevValue };
  }

  const CLASS_NAME_LENGTH_MIN = 1;
  const CLASS_NAME_LENGTH_MAX = 50;

  if (!StringUtils.isWithinRangeLength(nextValue, CLASS_NAME_LENGTH_MIN, CLASS_NAME_LENGTH_MAX)) {
    enqueueSnackbarWithTimeout(
      NAME_RANGE({
        t,
        name: type,
        min: CLASS_NAME_LENGTH_MIN,
        max: CLASS_NAME_LENGTH_MAX,
      }),
      { variant: 'warning' },
    );
    return { result: false, state: 'exceedTextLength', name: prevValue };
  }

  if (nextValue.includes(',')) {
    enqueueSnackbarWithTimeout(OBJECT_CLASS_NAME_COMMA({ t }), { variant: 'warning' });

    return { result: false, state: 'containComma', name: prevValue };
  }

  return { result: true, state: 'success', name: parsedNextValue };
};

const checkNameRuleForObjectClass = ({
  t,
  type,
  prevValue,
  nextValue,
  sameHierarchyNameList,
  index,
  enqueueSnackbar,
  annotationType,
}: {
  t: TFunction;
  type: string;
  prevValue: string;
  nextValue: string;
  sameHierarchyNameList: any;
  index: number;
  enqueueSnackbar: EnqueueSnackbar;
  annotationType: string;
}): { result: boolean; state: string; name: string } => {
  if (typeof nextValue !== 'string') {
    return { result: false, state: 'typeError', name: prevValue };
  }
  const enqueueSnackbarWithTimeout = enqueueSnackbarWithInstance(enqueueSnackbar);
  const parsedNextValue = nextValue.trim();

  if (parsedNextValue === '') {
    return {
      result: false,
      state: 'empty',
      name: prevValue,
    };
  }

  if (prevValue === parsedNextValue) {
    return { result: false, state: 'noChange', name: prevValue };
  }

  const isDuplicated = some(
    sameHierarchyNameList,
    (item, idx: number) => item === parsedNextValue && idx !== index,
  );

  if (isDuplicated) {
    // tsnoh-nameDuplicateInObjectClass
    enqueueSnackbarWithTimeout(
      t('consts.snackbarMessage.nameDuplicateInObjectClass', {
        name: parsedNextValue,
        annotationType: annotationType,
      }),
      {
        variant: 'warning',
      },
    );

    return { result: false, state: 'duplicate', name: prevValue };
  }

  const CLASS_NAME_LENGTH_MIN = 1;
  const CLASS_NAME_LENGTH_MAX = 50;

  if (!StringUtils.isWithinRangeLength(nextValue, CLASS_NAME_LENGTH_MIN, CLASS_NAME_LENGTH_MAX)) {
    enqueueSnackbarWithTimeout(
      NAME_RANGE({
        t,
        name: type,
        min: CLASS_NAME_LENGTH_MIN,
        max: CLASS_NAME_LENGTH_MAX,
      }),
      { variant: 'warning' },
    );
    return { result: false, state: 'exceedTextLength', name: prevValue };
  }

  if (nextValue.includes(',')) {
    enqueueSnackbarWithTimeout(OBJECT_CLASS_NAME_COMMA({ t }), { variant: 'warning' });

    return { result: false, state: 'containComma', name: prevValue };
  }

  return { result: true, state: 'success', name: parsedNextValue };
};

const nonManipulatingIsValidCreateProjectPage = (newProjectInfo: any): string => {
  const {
    projectName,
    isRequiredCheckDuplicateName,
    projectNameErrorMessages,
    selectedDataType,
    selectedAnnotationTypes,
  } = newProjectInfo;

  if (
    projectName === '' ||
    isRequiredCheckDuplicateName ||
    !isEmpty(projectNameErrorMessages) ||
    isEmpty(selectedDataType) ||
    isEmpty(selectedAnnotationTypes)
  ) {
    return ProjectStepsErrorMessage.CREATE_PROJECT;
  }
  return '';
};

const nonManipulatingIsValidImageCategoryPage = (newProjectInfo: any): string => {
  const imageCategoriesMaps = newProjectInfo.imageCategories
    .filter((imageCategory: any) => imageCategory.type !== PROPERTY_TYPE.FREE_RESPONSE.value)
    .map((imageCategory: any) => Object.values(imageCategory.imageCategoryMap));

  if (newProjectInfo.imageCategories.length === 0) {
    return ProjectStepsErrorMessage.AT_LEAST_ONE_CATEGORY_IS_REQUIRED;
  }

  for (let i = 0; i < imageCategoriesMaps.length; i++) {
    const curImageCategoryMap = imageCategoriesMaps[i];

    const categories = curImageCategoryMap.filter((item: any) => !item.children);
    const groups = curImageCategoryMap.filter((item: any) => item.children);

    const hasGroup = groups.length >= 2;

    if (!hasGroup) {
      if (categories.length === 0) {
        return ProjectStepsErrorMessage.AT_LEAST_ONE_CATEGORY_IS_REQUIRED;
      }
    } else {
      if (categories.length === 0) {
        return ProjectStepsErrorMessage.AT_LEAST_ONE_CATEGORY_IS_REQUIRED;
      }

      if (categories.some((item: any) => item.parent === 'root')) {
        return ProjectStepsErrorMessage.CATEGORY_MUST_BELONG_TO_GROUP;
      }

      for (let i = 0; i < groups.length; i++) {
        const curGroup: any = groups[i];
        if (curGroup.id === 'root') continue;
        if (curGroup.children.length === 0) {
          return ProjectStepsErrorMessage.GROUP_MUST_HAVE_AT_LEAST_ONE_CATEGORY;
        }
      }
    }
  }

  return '';
};

const nonManipulatingIsValidClassPropertyPage = (newProjectInfo: any): string => {
  const { objectClasses } = newProjectInfo;
  if (objectClasses.length === 0) {
    return ProjectStepsErrorMessage.AT_LEAST_ONE_OBJECT_CLASS_IS_REQUIRED;
  }

  for (let i = 0; i < objectClasses.length; i++) {
    const curObjectClasses = objectClasses[i];
    const { annotationType, minCount, maxCount } = curObjectClasses;

    if (annotationType === 'SELECT_TYPE') {
      return ProjectStepsErrorMessage.SELECT_ANNOTATION_TYPE;
    }

    if (minCount !== '' && maxCount !== '') {
      if (parseInt(minCount, 10) > parseInt(maxCount, 10)) {
        return ProjectStepsErrorMessage.ANNOTATION_PER_IMAGE_RANGE_WRONG;
      }
    }
  }

  return '';
};

const nonManipulatingIsValidClassGroupPage = (newProjectInfo: any): string => {
  const { objectClassGroupMap } = newProjectInfo;
  const objectClasses = Object.values(objectClassGroupMap);
  const classGroups = objectClasses.filter((item: any) => item.children);
  const useGroup = classGroups.length >= 2;

  if (useGroup) {
    for (let i = 0; i < classGroups.length; i++) {
      const curGroup: any = classGroups[i];
      if (curGroup.id === 'root') continue;
      if (curGroup.children.length === 0) {
        return ProjectStepsErrorMessage.GROUP_MUST_HAVE_AT_LEAST_ONE_OBJECT_CLASS;
      }
    }
  }

  return '';
};

// eslint-disable-next-line
const checkProcedure = (newProjectInfo: any): string => {
  const {
    currentStep: { settingPage },
  } = newProjectInfo;

  switch (settingPage) {
    case ProjectSteps.CREATE_PROJECT:
      return nonManipulatingIsValidCreateProjectPage(newProjectInfo);
    case ProjectSteps.IMAGE_CATEGORY:
      return nonManipulatingIsValidImageCategoryPage(newProjectInfo);
    case ProjectSteps.CLASS_PROPERTY:
      return nonManipulatingIsValidClassPropertyPage(newProjectInfo);
    case ProjectSteps.CLASS_GROUP:
      return nonManipulatingIsValidClassGroupPage(newProjectInfo);
    default:
      return '';
  }
};

export function getAllowSelfAssign(numMaxSelfAssign: number | undefined): boolean {
  if (numMaxSelfAssign === undefined) return true;

  return numMaxSelfAssign !== 0;
}

const convertLabelInterfaceToNewProjectInfo = ({
  projectInfo,
  newProjectInfo,
}: {
  projectInfo: any;
  newProjectInfo: any;
}): void => {
  // data
  const {
    name,
    description,
    label_interface: labelInterface,
    is_public: isPublic,
    settings: { num_max_self_assign: numMaxSelfAssign, allow_advanced_qa: allowAdvancedQa },
  } = projectInfo;
  const allowSelfAssign = getAllowSelfAssign(numMaxSelfAssign);
  const labelResultDef = ShyCredit.Models.Image.LabelResultDef.createFromLegacy(labelInterface);
  const { dataType, imageCategorization, objectDetection } = labelResultDef;
  const { annotationTypes, objectClasses, objectGroups } = objectDetection;
  const hasObjectClass = objectDetection.objectClasses.length !== 0;
  const hasImageCategory = imageCategorization.tasks.length !== 0;

  const getAnnotationTypes = () => {
    const nextAnnotationTypes = Object.fromEntries(
      annotationTypes.map(type => [type.toUpperCase(), true]),
    );
    if (imageCategorization.tasks.length !== 0) {
      nextAnnotationTypes[ANNOTATION_TYPE.IMAGE_CATEGORY.value] = true;
    }
    return nextAnnotationTypes;
  };

  const getImageCategories = (): any => {
    const nextImageCategories = cloneDeep(initImageCategories);
    const curImageCategory = nextImageCategories[0];
    const curImageCategoryMap = curImageCategory.imageCategoryMap;

    if (hasImageCategory) {
      const { id, name } = imageCategorization.tasks[0];
      curImageCategory.id = id;
      curImageCategoryMap.root.name = name;

      const searchChild = (node: any, parentNodeId: string) => {
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        curImageCategoryMap[node.id] = {
          id: node.id,
          name: node.name,
          parent: parentNodeId,
        };

        if (node.children) {
          const nextChildren = [];

          for (let i = 0; i < node.children.length; i++) {
            searchChild(node.children[i], node.id);
            nextChildren.push(node.children[i].id);
          }

          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          curImageCategoryMap[node.id].children = nextChildren;
        }
      };

      // @ts-ignore: hash에 key 값이 있음을 보장해야함
      for (const option of imageCategorization.tasks[0].options) {
        searchChild(option, 'root');
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        curImageCategoryMap.root.children.push(option.id);
      }
    }

    return nextImageCategories;
  };

  const getObjectClasses = (): any => {
    const nextObjectClasses = [];

    const getProperty = (properties: any): any => {
      const nextProperties = [];
      for (let i = 0; i < properties.length; i++) {
        const curProperty = properties[i];

        const nextProperty = {
          id: curProperty.id,
          name: curProperty.name,
          type: '',
          options: {},
          required: false,
        };

        if (curProperty.type === 'Multiple Choice') {
          nextProperty.type = 'MULTIPLE_CHOICE';
          nextProperty.options = {
            MULTIPLE_CHOICE: curProperty.options.map((item: any) => ({
              ...item,
              checked: curProperty.defaultValue.some((value: string) => value === item.id),
            })),
            MULTIPLE_SELECTION: curProperty.options.map((item: any) => ({
              ...item,
              checked: false,
            })),
            FREE_RESPONSE: cloneDeep(initialFreeResponse),
          };
          nextProperty.required = curProperty.required;
        } else if (curProperty.type === 'Multiple Selection') {
          nextProperty.type = 'MULTIPLE_SELECTION';
          nextProperty.options = {
            MULTIPLE_CHOICE: curProperty.options.map((item: any) => ({ ...item, checked: false })),
            MULTIPLE_SELECTION: curProperty.options.map((item: any) => ({
              ...item,
              checked: curProperty.defaultValue.some((value: string) => value === item.id),
            })),
            FREE_RESPONSE: cloneDeep(initialFreeResponse),
          };
          nextProperty.required = curProperty.required;
        } else if (curProperty.type === 'Free Response') {
          nextProperty.type = 'FREE_RESPONSE';
          nextProperty.options = {
            MULTIPLE_CHOICE: initialMultipleChoice(),
            MULTIPLE_SELECTION: initialMultipleSelection(),
            FREE_RESPONSE: {
              id: 1,
              isOnlyNumber: curProperty.config.numbersOnly,
              value: curProperty.defaultValue,
            },
          };
          nextProperty.required = !curProperty.blank;
        }
        nextProperties.push(nextProperty);
      }

      return nextProperties;
    };

    for (let i = 0; i < objectDetection.objectClasses.length; i++) {
      const curObjectClass = objectDetection.objectClasses[i];
      const nextObjectClass = {
        id: curObjectClass.id,
        name: curObjectClass.name,
        annotationType: curObjectClass.annotationType.toUpperCase(),
        color: curObjectClass.color,
        properties: getProperty(curObjectClass.properties),
        minCount: curObjectClass.minCount === undefined ? '' : curObjectClass.minCount,
        maxCount: curObjectClass.maxCount === undefined ? '' : curObjectClass.maxCount,
      };

      if (curObjectClass.annotationType === 'keypoint') {
        const curKeypointDef = objectDetection.keypointDefs.find(
          item => item.id === curObjectClass.keypointInterfaceId,
        );

        const nextKeypointInfo = {
          id: curObjectClass.keypointInterfaceId,
          template: {
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            name: curKeypointDef.name,
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            img: keypointImgUrlMap[curKeypointDef.name],
            keypointDef: curKeypointDef,
          },
          visibilityOption: {
            type: '3_STATES',
            state: 'VISIBLE',
          },
          workapp: 'image-default',
        };

        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        nextObjectClass.keypointInfo = nextKeypointInfo;
      }

      nextObjectClasses.push(nextObjectClass);
    }

    return nextObjectClasses;
  };

  const getObjectClassGroupMap = (): any => {
    const nextObjectClassGroupMap = cloneDeep(initialObjectClassGroupMap);

    if (objectGroups) {
      const inGroupObjectClassIds = [];
      for (let i = 0; i < objectGroups.length; i++) {
        const curGroup = objectGroups[i];

        const nextGroup = {
          id: curGroup.id,
          name: curGroup.name,
          parent: 'root',
          children: curGroup.objectClassIds,
        };
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        nextObjectClassGroupMap[curGroup.id] = nextGroup;

        for (let j = 0; j < curGroup.objectClassIds.length; j++) {
          const curObjectClass = find(
            objectClasses,
            item => item.id === curGroup.objectClassIds[j],
          );

          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          inGroupObjectClassIds.push(curObjectClass.id);

          const nextObjectClass = {
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            id: curObjectClass.id,
            parent: curGroup.id,
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            name: curObjectClass.name,
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            annotationType: curObjectClass.annotationType.toUpperCase(),
          };
          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          nextObjectClassGroupMap[curObjectClass.id] = nextObjectClass;
        }
      }

      const notInGroupObjectClassIds = difference(
        objectClasses.map(item => item.id),
        inGroupObjectClassIds,
      );

      // @ts-ignore: hash에 key 값이 있음을 보장해야함
      nextObjectClassGroupMap.root.children = notInGroupObjectClassIds.concat(
        objectGroups.map(item => item.id),
      );

      for (let i = 0; i < notInGroupObjectClassIds.length; i++) {
        const curObjectClass = find(objectClasses, item => item.id === notInGroupObjectClassIds[i]);
        const nextObjectClass = {
          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          id: curObjectClass.id,
          parent: 'root',
          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          name: curObjectClass.name,
          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          annotationType: curObjectClass.annotationType.toUpperCase(),
        };
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        nextObjectClassGroupMap[curObjectClass.id] = nextObjectClass;
      }
    }

    return nextObjectClassGroupMap;
  };

  // // create project
  newProjectInfo.setProjectName(name);
  newProjectInfo.setIsRequiredCheckDuplicateName(false);
  newProjectInfo.setDescription(description);
  newProjectInfo.setSelectedDataType(dataType);
  newProjectInfo.setSelectedWorkapp('image-default');
  newProjectInfo.setSelectedAnnotationTypes(getAnnotationTypes());
  newProjectInfo.setIsPublic(isPublic);
  newProjectInfo.setAllowAdvancedQa(allowAdvancedQa);
  newProjectInfo.setAllowSelfAssign(allowSelfAssign);
  newProjectInfo.setSteps(
    getProjectSteps({
      dataType,
      hasObjectClass,
      hasImageCategory,
    }),
  );
  newProjectInfo.setImageCategories(getImageCategories());
  newProjectInfo.setObjectClasses(getObjectClasses());
  newProjectInfo.setObjectClassGroupMap(getObjectClassGroupMap());
};

// non manipulating functions

// dataType: image

const nonManipulatingCreateCategoryItem = (item: any): any => {
  const { id } = item;
  const { name } = item;
  return new ShyCredit.Models.Image.CategoryItem({ id, name });
};

const nonManipulatingConvertStrForShyCreditUpperFirst = (str: string): string => {
  return str
    .toLowerCase()
    .split('_')
    .map((item: any) => upperFirst(item))
    .join(' ');
};

const nonManipulatingCreateClassPropertyDef = (property: any): any => {
  const getDefaultValue = () => {
    switch (property.type) {
      case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
      case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
        return property.options[property.type]
          .filter((item: any) => item.checked)
          .map((item: any) => item.id);
      case PROPERTY_TYPE.FREE_RESPONSE.value:
        return property.options[property.type].value;
      default:
        return [];
    }
  };
  const getOptions = () => {
    switch (property.type) {
      case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
      case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
        return property.options[property.type].map((item: any) =>
          nonManipulatingCreateCategoryItem(item),
        );
      default:
        return [];
    }
  };
  const { id } = property;
  const { name } = property;
  const type = nonManipulatingConvertStrForShyCreditUpperFirst(property.type);
  const defaultValue = getDefaultValue();
  const { required } = property;
  const options = getOptions();
  const config = {
    numbersOnly: property.options.FREE_RESPONSE.isOnlyNumber,
    uniqueInLabel: false,
  };

  switch (property.type) {
    case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
    case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
      return new ShyCredit.Models.Image.ChoicePropertyDef({
        id,
        name,
        type: type as 'Multiple Selection' | 'Multiple Choice',
        defaultValue,
        required,
        options,
      });
    case PROPERTY_TYPE.FREE_RESPONSE.value:
      return new ShyCredit.Models.Image.TextPropertyDef({
        id,
        name,
        type: type as 'Free Response',
        defaultValue,
        blank: !required,
        config,
      });
    default:
      return [];
  }
};

const nonManipulatingConvertStrForShyCredit = (str: string): string => {
  return str.split('_').join(' ').toLowerCase();
};

const nonManipulatingCreateObjectClassDef = (objectClass: any, aiClassMap: any): any => {
  const { id } = objectClass;
  const { name } = objectClass;
  const annotationType = nonManipulatingConvertStrForShyCredit(objectClass.annotationType);
  const { color } = objectClass;
  const properties = objectClass.properties.map((property: any) =>
    nonManipulatingCreateClassPropertyDef(property),
  );
  const keypointInterfaceId = !objectClass.keypointInfo ? undefined : objectClass.keypointInfo.id;
  const minCount = objectClass.minCount === '' ? undefined : parseInt(objectClass.minCount, 10);
  const maxCount = objectClass.maxCount === '' ? undefined : parseInt(objectClass.maxCount, 10);

  return new ShyCredit.Models.Image.ObjectClassDef({
    id,
    name,
    annotationType,
    color,
    properties,
    keypointInterfaceId,
    minCount,
    maxCount,
    aiClassMap,
  });
};

const nonManipulatingCreateAIClassMap = (objectClass: any, autoLabelObjectClasses: any[]): any => {
  const nextAIClassMap = [];
  const objectClassId = objectClass.id;

  const autoLabelObjectClass = find(autoLabelObjectClasses, item => item.id === objectClassId);

  if (!autoLabelObjectClass) return [];

  const { autoLabelInfo } = autoLabelObjectClass;

  nextAIClassMap.push({
    engineId: autoLabelInfo.engineId,
    classIds: Object.keys(autoLabelInfo.classIds),
  });

  return nextAIClassMap;
};

const nonManipulatingCreateObjectGroupDef = (classGroup: any): any => {
  const { id } = classGroup;
  const { name } = classGroup;
  const objectClassIds = classGroup.children;

  return new ShyCredit.Models.Image.ObjectGroupDef({ id, name, objectClassIds });
};

const nonManipulatingCreateKeypointDef = (objectClass: any): any => {
  if (!objectClass) return [];

  const { keypointInfo } = objectClass;

  const { id } = keypointInfo;
  const { name } = keypointInfo.template;
  const { points } = keypointInfo.template.keypointDef;
  const { edges } = keypointInfo.template.keypointDef;
  const { symmetry } = keypointInfo.template.keypointDef;
  const { defaultPoints } = keypointInfo.template.keypointDef;

  return [
    new ShyCredit.Models.Image.KeypointDef({
      id,
      name,
      points,
      edges,
      allowValidInvisibles: true,
      symmetry,
      defaultPoints,
    }),
  ];
};

const nonManipulatingCreateObjectDetectionDef = (newProjectInfo: any): any => {
  const annotationTypes = Object.keys(newProjectInfo.selectedAnnotationTypes)
    .filter(item => item !== ANNOTATION_TYPE.IMAGE_CATEGORY.value)
    .map(item => nonManipulatingConvertStrForShyCredit(item));

  const objectClasses = newProjectInfo.objectClasses.map((item: any) =>
    nonManipulatingCreateObjectClassDef(
      item,
      nonManipulatingCreateAIClassMap(item, newProjectInfo.autoLabelObjectClasses),
    ),
  );

  const objectGroups = Object.values(newProjectInfo.objectClassGroupMap)
    .filter((item: any) => item.parent === 'root' && item.children)
    .map(item => nonManipulatingCreateObjectGroupDef(item));

  const keypointDefs = nonManipulatingCreateKeypointDef(
    newProjectInfo.objectClasses.find(
      (item: any) => item.annotationType === ANNOTATION_TYPE.KEYPOINT.value,
    ),
  );

  return new ShyCredit.Models.Image.ObjectDetectionDef({
    annotationTypes,
    objectClasses,
    objectGroups,
    keypointDefs,
  });
};

const nonManipulatingCreateImageCategorizationDef = (newProjectInfo: any): any => {
  if (!newProjectInfo.selectedAnnotationTypes.IMAGE_CATEGORY)
    return new ShyCredit.Models.Image.ImageCategorizationDef({ tasks: [] });

  const getCategory = (imageCategoryMap: any, id: string): any => {
    const curCategory = imageCategoryMap[id];

    const { name } = curCategory;
    const hasChildren = curCategory.children;
    if (hasChildren) {
      if (id === 'root') {
        return curCategory.children.map((item: any) => getCategory(imageCategoryMap, item));
      }
      return new ShyCredit.Models.Image.CategoryGroup({
        id,
        name,
        children: curCategory.children.map((item: any) => getCategory(imageCategoryMap, item)),
      });
    }
    return new ShyCredit.Models.Image.CategoryItem({ id, name });
  };

  const getTask = (imageCategory: any) => {
    switch (imageCategory.type) {
      case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
        return new ShyCredit.Models.Image.ChoicePropertyDef({
          id: imageCategory.id,
          name: imageCategory.imageCategoryMap.root.name,
          type: 'Multiple Selection',
          defaultValue: [],
          required: imageCategory.required,
          options: getCategory(imageCategory.imageCategoryMap, 'root'),
        });
      case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
        return new ShyCredit.Models.Image.ChoicePropertyDef({
          id: imageCategory.id,
          name: imageCategory.imageCategoryMap.root.name,
          type: 'Multiple Choice',
          defaultValue: [],
          required: imageCategory.required,
          options: getCategory(imageCategory.imageCategoryMap, 'root'),
        });
      case PROPERTY_TYPE.FREE_RESPONSE.value:
        return new ShyCredit.Models.Image.TextPropertyDef({
          id: imageCategory.id,
          name: imageCategory.imageCategoryMap.root.name,
          type: 'Free Response',
          defaultValue: imageCategory.freeResponseInfo.value,
          blank: !imageCategory.required,
          config: { numbersOnly: imageCategory.freeResponseInfo.isOnlyNumber },
        });
      default:
        return null;
    }
  };

  return new ShyCredit.Models.Image.ImageCategorizationDef({
    tasks: newProjectInfo.imageCategories.map((imageCategory: any) => getTask(imageCategory)),
  });
};

const nonManipulatingCreateImageLabelInterface = (newProjectInfo: any): any => {
  const type = 'legacyImage';
  const dataType = 'image';
  const objectDetectionDef = nonManipulatingCreateObjectDetectionDef(newProjectInfo);
  const imageCategorizationDef = nonManipulatingCreateImageCategorizationDef(newProjectInfo);

  return new ShyCredit.Models.Image.LabelResultDef({
    type,
    dataType,
    objectDetection: objectDetectionDef,
    imageCategorization: imageCategorizationDef,
  });
};

// eslint-disable-next-line
const createLabelInterfaceForImage = (newProjectInfo: any): any => {
  const { selectedDataType } = newProjectInfo;
  let result = {};

  switch (selectedDataType) {
    case 'image':
    default:
      result = nonManipulatingCreateImageLabelInterface(newProjectInfo).toJSON();
  }

  return result;
};

export default {
  handleClickPrev,
  handleClickNext,
  parseNameValue,
  checkNameRule,
  checkNameRuleForObjectClass,
  checkProcedure,
  convertLabelInterfaceToNewProjectInfo,
  createLabelInterfaceForImage,
};
