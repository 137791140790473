import { fetchUrlAsBlobOnMRAP, LegacyUrl, MRAPUrl } from '../shares/fetchUrlAsBlobOnMRAP';
import { LabelData, ReviewData } from '../types/labelTypes';
import { translateCivetCondition2LokiCondition } from '../utils/filter/labelFilter';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

type LabelTag = {
  createdAt: string;
  createdBy: string;
  id: string;
  info: {
    color: string;
  };
  labelsCount: number;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  name: string;
  project: string;
};

const getLabel: ApiCall<{ projectId: string; labelId: string }, LabelData> = async args => {
  const { projectId, labelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/${labelId}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getLabelReviews: ApiCall<
  {
    projectId: string;
    labelId: string;
    params: { page?: number; ordering?: string; pageSize?: number };
  },
  { results: ReviewData[]; count: number }
> = async args => {
  const { projectId, labelId, isGuest, urlInfo, params } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/${labelId}/reviews/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getLabelCounts: ApiCall<{ projectId: string; params: Params }, number> = async args => {
  const { params, projectId, isGuest, urlInfo } = args;
  params.page = 1;
  params.pageSize = 1;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res?.data?.count || 0;
};

const getLabelCountsV2: ApiCall<{ projectId: string; params: Params }, number> = async args => {
  const { params, projectId, isGuest, urlInfo } = args;
  params.pageSize = 1;
  const translatedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [
      translateCivetCondition2LokiCondition(key),
      value,
    ]),
  );
  const {
    page: _page,
    searchAfter: _searchAfter,
    searchBefore: _searchBefore,
    searchLast: _searchLast,
    ...filteredParams
  } = translatedParams;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/?${ServiceUtils.getParamString(filteredParams)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: 2,
  });

  return res?.data?.count || 0;
};

const getRelatedLabelsList: ApiCall<{ projectId: string; labelId: string }, any> = async args => {
  const { projectId, labelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/${labelId}/related-labels/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getLabels: ApiCall<
  { projectId: string; params: Params },
  { results: LabelData[]; count: number }
> = async args => {
  const { params, projectId, isGuest, urlInfo } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  if (res?.data?.results) {
    const urls: (LegacyUrl | MRAPUrl | null)[] = res.data.results.map((label: LabelData) => {
      if (label.asset.info.type === 'img-url') {
        return label.asset.info.url;
      } else if (label.thumbnail) {
        return label.thumbnail;
      }
      return null;
    });

    const blobUrls: (string | null)[] = await Promise.all(
      urls.map(async url => {
        try {
          if (!url) {
            return null;
          }
          const res = await fetchUrlAsBlobOnMRAP(url);
          return res;
        } catch {
          return null;
        }
      }),
    );

    blobUrls.forEach((url, index) => {
      res.data.results[index].thumbnail = url;
    });
  }

  return res.data;
};

const getLabelsV2: ApiCall<
  { projectId: string; params: Params },
  { results: LabelData[]; count: number; prev: [number, string]; next: [number, string] }
> = async args => {
  const { params, projectId, isGuest, urlInfo } = args;
  const translatedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [
      translateCivetCondition2LokiCondition(key),
      value,
    ]),
  );

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/?${ServiceUtils.getParamString(translatedParams)}`,
    data: translatedParams,
    hasPublicApi: true,
    isGuest,
    urlInfo,
    version: 2,
  });

  if (res?.data?.results) {
    const urls: (LegacyUrl | MRAPUrl | null)[] = res.data.results.map((label: LabelData) => {
      if (label.asset.info.type === 'img-url') {
        return label.asset.info.url;
      } else if (label.thumbnail) {
        return label.thumbnail;
      }
      return null;
    });

    try {
      const blobUrls = await Promise.all(
        urls.map(url => {
          if (!url) {
            return null;
          }
          return fetchUrlAsBlobOnMRAP(url, { useCache: true });
        }),
      );

      blobUrls.forEach((url, index) => {
        res.data.results[index].thumbnail = url;
      });
    } catch (error) {}
  }

  return res.data;
};

const getLabelTags: ApiCall<
  { projectId: string; params: Params },
  {
    labelsCount: number;
    tags: LabelTag[];
  }
> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/tags/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

export interface LabelTagStatus {
  status: 'NONE' | 'PARTIAL' | 'ALL';
  tagId: string;
}

const getLabelTagStatus: ApiCall<
  { projectId: string; params: Params },
  { results: LabelTagStatus[] }
> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/v2/projects/${projectId}/tags/status/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getLabelsId: ApiCall<{ projectId: string; params: Params }, any> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels_id/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return { res };
};

const deleteLabel: ApiCall<{ projectId: string; labelId: string }, any> = async args => {
  const { projectId, labelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/labels/${labelId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return { res };
};

const requestAutoLabel: ApiCall<{ projectId: string; exportInfo: any }, any> = async args => {
  const { projectId, exportInfo, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/labels/auto-label/`,
    data: exportInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type RequestConsensusLabelsRequest = {
  projectId: string;
  params: Params;
  labelCount: number;
  consensusCount: number;
};

const requestConsensusLabels: ApiCall<RequestConsensusLabelsRequest, any> = async args => {
  const { projectId, params, labelCount, consensusCount, isGuest, urlInfo } = args;
  const data = {
    labelCount,
    consensusCount,
    labelInfoCopyMethod: 'copy',
  };
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/labels/consensus/?${ServiceUtils.getParamString(params)}`,
    data,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type TaskStatusResponse = { taskId: string; status: string; result: any };
const getTaskStatus: ApiCall<{ taskId: string }, TaskStatusResponse> = async args => {
  const { taskId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/task-status/${taskId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type IntegrationHistory = {
  createdAt: string;
  createdBy: string;
  dataopsDatasetId: string;
  id: string;
  labelingDataset: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  project: string;
  sliceId: string;
  sliceName: string;
  tenant: string;
  labelCounts: number;
};

const getLabelCurateIntegrationHistories: ApiCall<
  { projectId: string; params: Params },
  { results: IntegrationHistory[]; count: number }
> = async args => {
  const { params, projectId, isGuest, urlInfo } = args;
  const translatedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [
      translateCivetCondition2LokiCondition(key),
      value,
    ]),
  );
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/v2/projects/${projectId}/labels/curate-integration-histories/?${ServiceUtils.getParamString(
      translatedParams,
    )}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getSignedUrl: ApiCall<{ projectId: string; labels: any[] }, any> = async args => {
  const { projectId, labels, isGuest, urlInfo } = args;
  const res = await Promise.all(
    labels.map(async label => {
      if (label.asset.info.type === 'img-presigned-url') {
        try {
          const urlRes = await AuthService.apiCallAfterLogin({
            method: 'post',
            url: `/projects/${projectId}/labels/${label.id}/asset-read-signed-url/`,
            data: {},
            hasPublicApi: true,
            isGuest,
            urlInfo,
          });

          return urlRes.data.url;
        } catch (err: any) {
          if (err.message === 'Not Found') {
            return '/static/image/not_found.png';
          }
        }
      }
      return label.asset.info.url;
    }),
  );
  return res;
};

export default {
  getLabel,
  getLabels,
  getLabelsV2,
  getLabelCounts,
  getLabelCountsV2,
  getRelatedLabelsList,
  getLabelTags,
  getLabelTagStatus,
  getLabelsId,
  deleteLabel,
  requestAutoLabel,
  getTaskStatus,
  requestConsensusLabels,
  getLabelReviews,
  getLabelCurateIntegrationHistories,
  getSignedUrl,
};
