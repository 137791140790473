import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, map, some } from 'lodash';

import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import CabinetClassItem from './CabinetClassItem';

const useStyles = makeStyles(() => ({
  classListBox: {
    position: 'relative',
    flex: '3',
    background: '#fbfbfb',
    padding: '6px 6px',
    overflow: overflowOverlayOrAuto(),
    maxHeight: '240px',
  },
  selectClassListButton: {
    cursor: 'pointer',
    fontSize: '9px',
    fontWeight: 500,
    color: '#212121',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 400,
    padding: '0px 10px',
    borderRadius: '12.5px',
    cursor: 'pointer',
    margin: '5px',
    color: '#9b9b9b',
    borderColor: '#9b9b9b',
    '&.active': {
      color: '#ff625a',
      borderColor: '#ff625a',
      '&:hover': {
        background: '#ffeceb',
      },
    },
    '&.MuiButton-root': {
      padding: '0px 14px',
    },
  },
  groupBox: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '60px',
    overflow: overflowOverlayOrAuto(),
  },
  groupButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '16px',
    marginTop: '4px',
  },
  groupCheckbox: {
    '& .MuiSvgIcon-root': {
      width: '13px',
      height: '13px',
    },
  },
  groupText: {
    fontSize: '11px',
    fontWeight: 300,
    color: '#9b9b9b',
    '&.active': {
      color: '#FF625A',
    },
  },
  maskBox: {
    width: '100%',
    height: '32px',
    display: 'flex',
  },
  maskColorBox: {
    width: '64px',
    height: '100%',
  },
  maskNameBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: overflowOverlayOrAuto(),
    overflowX: 'hidden',
  },
  maskNameText: {
    marginLeft: '10px',
    fontSize: '11px',
  },
}));
// eslint-disable-next-line
const CabinetClassList = (props: any): React.ReactElement => {
  const className = useStyles();
  const { cabinetStyles } = props;
  const labelInformationInfo = useLabelInformationInfo();
  const {
    groups,
    setGroups,
    classes,
    setClasses,
    selectOrUnselectLabel,
    setSelectOrUnselectLabel,
  } = labelInformationInfo;

  const useGroup = !!Object.keys(groups).length;

  const updateStates = (groups: any[], classes: any[]) => {
    setGroups(groups);
    setClasses(classes);

    if (groups.length !== 0) {
      if (
        some(groups, group => group.isActive === true) ||
        some(classes, value => value.isActive === true)
      ) {
        setSelectOrUnselectLabel('Unselect All');
      } else {
        setSelectOrUnselectLabel('Select All');
      }
    } else if (some(classes, value => value.isActive === true)) {
      setSelectOrUnselectLabel('Unselect All');
    } else {
      setSelectOrUnselectLabel('Select All');
    }
  };

  const handleClickSelectOrUnselectLabel = (): void => {
    const nextGroups = cloneDeep(groups);
    const nextClasses = cloneDeep(classes);
    const nextIsActive = selectOrUnselectLabel !== 'Unselect All';

    if (groups.length !== 0) {
      map(Object.values(nextGroups), (group: any) => {
        // eslint-disable-next-line no-param-reassign
        group.isActive = nextIsActive;
      });
    }

    map(Object.values(nextClasses), (classObj: any) => {
      // eslint-disable-next-line no-param-reassign
      classObj.isActive = nextIsActive;
    });

    updateStates(nextGroups, nextClasses);
  };

  const handleClickGroup = (groupName: string) => () => {
    const nextGroups = cloneDeep(groups);
    const nextClasses = cloneDeep(classes);

    const nextIsActive = !nextGroups[groupName].isActive;
    nextGroups[groupName].isActive = nextIsActive;

    map(
      nextGroups[groupName].classes,
      // eslint-disable-next-line no-return-assign
      className => (nextClasses[className].isActive = nextIsActive),
    );

    updateStates(nextGroups, nextClasses);
  };

  return (
    <>
      <MUI.Box className={cabinetStyles.cabinetHeader} mt="12px" mb={useGroup ? '0px' : '8px'}>
        <MUI.Typography className={cabinetStyles.cabinetTitle}>Object class</MUI.Typography>
        <MUI.Typography
          className={className.selectClassListButton}
          onClick={handleClickSelectOrUnselectLabel}
        >
          {selectOrUnselectLabel}
        </MUI.Typography>
      </MUI.Box>
      {useGroup && (
        <MUI.Box className={className.groupBox} mb="2px">
          {map(groups, (group, groupName) => (
            <MUI.Box
              key={groupName}
              className={`${className.groupButton} ${group.isActive ? 'active' : ''}`}
              onClick={handleClickGroup(groupName)}
              mt="8px"
            >
              <MUI.Checkbox
                className={className.groupCheckbox}
                color="primary"
                checked={group.isActive}
              />
              <MUI.Typography
                className={`${className.groupText} ${group.isActive ? 'active' : ''}`}
              >
                {groupName}
              </MUI.Typography>
            </MUI.Box>
          ))}
        </MUI.Box>
      )}
      <MUI.Box className={className.classListBox} mb="20px">
        <MUI.Box className={cabinetStyles.cabinetContent}>
          {map(classes, (item, name) => (
            <CabinetClassItem key={name} item={item} name={name} updateStates={updateStates} />
          ))}
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default CabinetClassList;
