import { AutoCurate } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../../menu';
import CurationLayout from './Layout';

export const CurationMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetAutoCurateReports,
  path: 'curation',
  icon: <Icon icon={AutoCurate} />,
  title: ({ t }) => t('curate.dataset.menus.curationReports'),
  component: CurationLayout,
});
