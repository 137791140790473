import React from 'react';

import { ProjectSteps } from '../../../configs/NewProjectStepsConfig';
import { useNewProjectInfo } from '../../../contexts/NewProjectContext';
import ClassGroup from './classGroup';
import ClassProperty from './classProperty';
import CloseButton from './CloseButton';
import CreateProject from './createNewProject';
import ImageCategory from './imageCategory';

// eslint-disable-next-line
const Layout = (props: any): React.ReactElement => {
  const newProjectInfo = useNewProjectInfo();
  const {
    currentStep: { settingPage },
  } = newProjectInfo;

  const getSettingPage = () => {
    switch (settingPage) {
      case ProjectSteps.CREATE_PROJECT:
      default:
        return <CreateProject />;
      case ProjectSteps.IMAGE_CATEGORY:
        return <ImageCategory />;
      case ProjectSteps.CLASS_PROPERTY:
        return <ClassProperty />;
      case ProjectSteps.CLASS_GROUP:
        return <ClassGroup />;
    }
  };

  return (
    <>
      {getSettingPage()}
      <CloseButton {...props} />
    </>
  );
};

export default Layout;
