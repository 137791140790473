export type TargetMetric = 'metadata' | 'annotation';
import { useRef } from 'react';

import { Box } from '@superb-ai/ui';

import useSectionSize from '../../../../../../../components/elements/windowedImageGrid/useSectionSize';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { AnnotationsContainer } from '../../analytics/AnnotationsContainer';
import { MetadataAnalyticsProvider } from '../../analytics/contexts/MetadataContext';
import { MetadataContainer } from '../../analytics/MetadataContainer';

export default function AnalyticsContents() {
  const { targetMetric } = useActionContext();

  const containerRef = useRef<HTMLDivElement>();
  const sectionSize = useSectionSize(containerRef);

  function getView() {
    return {
      metadata: (
        <MetadataAnalyticsProvider>
          <MetadataContainer />
        </MetadataAnalyticsProvider>
      ),
      annotation: <AnnotationsContainer />,
    }[targetMetric];
  }

  return (
    <Box ref={containerRef}>
      <Box overflow="auto" pb={1} pr={2} style={sectionSize && { height: sectionSize[3] }}>
        {getView()}
      </Box>
    </Box>
  );
}
