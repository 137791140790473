import React from 'react';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import SortImageDropdown from './SortImageDropdown';

export default function GridRightSection() {
  const { sortBy, setSortBy, sortOrder, setSortOrder } = useActionContext();

  function handleSortOrder(newSortOrder: 'desc' | 'asc') {
    setSortOrder(newSortOrder);
  }

  function handleSortBy(newSortBy: string) {
    setSortBy(newSortBy);
  }
  return (
    <SortImageDropdown
      handleSortOrder={handleSortOrder}
      sortOrder={sortOrder}
      handleSortBy={handleSortBy}
      sortBy={sortBy}
    />
  );
}
