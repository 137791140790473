import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Typography } from '@superb-ai/ui';

import { WindowedGrid } from '../../../../../../../components/elements/windowedImageGrid/WindowedGrid';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import ImageCell from './ImageCell';
import { useChunkedLeafClusterImagesQuery } from '../../../../../queries/dataQueries';

export const ClusterCenterGrid = ({
  sortOrder,
  height,
  width,
  setDisplayedItems,
}: {
  sortOrder: 'asc' | 'desc';
  height: number;
  width: number;
  setDisplayedItems: Dispatch<
    SetStateAction<{ id: string; size: number; thumbnailUrl?: string }[]>
  >;
}) => {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { leafImageClusters, showSelectedOnly, selectedLeafIds } = useImageFilterContext();

  const [items, setItems] = useState<{ id: string; size: number; thumbnailUrl?: string }[]>(() =>
    leafImageClusters.map(([id, size]) => ({ id, size })),
  );

  const selectedFilteredSortedClusters = useMemo(() => {
    const filteredList = items.filter(cluster => selectedLeafIds?.includes(cluster.id));
    return sortOrder === 'desc' ? filteredList : [...filteredList].reverse();
  }, [items, selectedLeafIds, sortOrder]);

  const sortedLeafClusters = useMemo(() => {
    return sortOrder === 'desc' ? items : [...items].reverse();
  }, [sortOrder, items]);

  const displayedItems = useMemo(() => {
    return showSelectedOnly ? selectedFilteredSortedClusters : sortedLeafClusters;
  }, [showSelectedOnly, selectedFilteredSortedClusters, sortedLeafClusters]);

  useEffect(() => {
    setDisplayedItems(displayedItems);
    setChunkIndex(0);
  }, [displayedItems.length, displayedItems[0]]);

  const [chunkIndex, setChunkIndex] = useState(0);
  const chunkSize = 30;
  const columns = 3;

  const currentChunk = useMemo(() => {
    return [...displayedItems]
      .slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize)
      .map(item => item.id)
      .filter(item => item);
  }, [displayedItems, chunkIndex, chunkSize]);

  const { data } = useChunkedLeafClusterImagesQuery({
    datasetId,
    chunk: currentChunk,
    fromPublicDatasets: showPublicDatasets,
    disabled: !currentChunk.length,
  });

  const imageInfosChunk = data?.results;

  useEffect(() => {
    if (leafImageClusters) {
      setItems(leafImageClusters.map(([id, size]) => ({ id, size })));
    }
  }, [leafImageClusters]);

  useEffect(() => {
    if (imageInfosChunk) {
      setItems(prevItems => {
        const newItems = [...prevItems];
        newItems.forEach((item, idx) => {
          const newItem = imageInfosChunk.find(newInfo => newInfo.leafCluster.id === item.id);
          if (newItem) {
            newItems[idx] = { ...item, thumbnailUrl: newItem.thumbnailUrl };
          }
        });

        return newItems;
      });
    }
  }, [imageInfosChunk, chunkIndex, chunkSize]);

  const handleRender = async ({ rowStopIndex }) => {
    const itemsRendered = (rowStopIndex + 1) * columns;
    const threshold = (chunkIndex + 1) * chunkSize;
    const loadAheadThreshold = chunkSize / 2; // Load more rows when halfway through the current chunk

    if (
      itemsRendered >= threshold - loadAheadThreshold &&
      imageInfosChunk &&
      imageInfosChunk.length > 0
    ) {
      setChunkIndex(prev => prev + 1);
    }
  };

  if (!displayedItems.length) {
    return (
      <Box mt={4} display="flex" justifyContent="center" style={{ height }}>
        <Typography variant="m-regular" color="gray-300">
          {t('text.noResult')}
        </Typography>
      </Box>
    );
  }

  return (
    <WindowedGrid
      loadedItems={displayedItems}
      width={width}
      height={height}
      itemData={{
        columns,
        infoAreaHeight: 24,
      }}
      CellComponent={ImageCell}
      additionalHeight={24}
      onRender={handleRender}
    />
  );
};
