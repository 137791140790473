import {
  cloneDeep,
  find,
  flatten,
  get,
  has,
  includes,
  lowerFirst,
  map,
  orderBy,
  reduce,
  sumBy,
  toLower,
  upperFirst,
  values,
} from 'lodash';

import { IWordMap, LabelStatusCumulative, SortOption } from '../dataTypes/analyticsDataTypes';
import { BaseDatum, BaseJsonObj, JsonObj } from '../userStats/types';

/**
 *
 * TODO: clean up this file
 * -> move chart related helper functions to donutChart/helper, lineChart/helper
 * -> move endpoint related helper functions to dataTypes/dataHelper /(consider making a class)
 *    rename dataTypes/dataHelper -> analyticsData/helper (helper functions for formatting API data)
 *    rename dataTypes/analyticsDataTypes -> analyticsData/types (types from endpoint and formatted data)
 *
 */

// Used below in addNameAndTotal
const shorten = (username: string): string => {
  const TEXT_LIMIT = 13; // arbitrary limit for readability
  return username.length >= TEXT_LIMIT ? `${username.slice(0, TEXT_LIMIT)}..` : username;
};

// Used below in addNameAndTotal
const convertEmail = (email: string): string => {
  return `em_${toLower(email.split('@')[0])}`;
};

/**
 * Used in Plot -> formatData / workerStats
 */
// eslint-disable-next-line
export const addNameAndTotal = (
  data: JsonObj[],
  activeLabelers: Record<string, string>,
): Record<string, string | number>[] => {
  return map(data, d => {
    const email = d.assignee;

    // use email as name if not in active labeler list
    const nameOrEmail = has(activeLabelers, email) ? activeLabelers[email] : email;
    return {
      ...d,
      assignee: email === 'unassigned' ? 'No Assignee' : email,
      assignee_name: nameOrEmail, // for download
      // display
      assignee_display_name:
        nameOrEmail.indexOf('@') !== -1 ? shorten(convertEmail(nameOrEmail)) : shorten(nameOrEmail),
      total: d.cumSkipped + d.cumWorking + d.cumSubmitted,
      currentTotal: d.cumSkipped + d.cumWorking + d.cumSubmitted,
    };
  });
};

/**
 *
 * @param {Object[]} data
 * @param {string[]} selectedStatus Statuses to include in currentTotal
 */
export const updateSelectedLabelsCount = (
  data: Record<string, string | number>[],
  selectedStatus: LabelStatusCumulative[],
): JsonObj => {
  return map(data, d => {
    const currentTotal = reduce(
      selectedStatus,
      (sum, status) => {
        return sum + (d[status] as number);
      },
      0,
    );
    return {
      ...d,
      currentTotal,
    };
  });
};

// Sort on two keys, x and y, (either 'asc' or 'desc')
// TODO (ml) - break into two functions
export const sortData = <T>(
  data: T[],
  sortOne: SortOption,
  sortTwo: SortOption,
  chartName: string,
): T[] => {
  if (chartName === 'projectProgress') {
    return orderBy(data, [sortTwo.key], [sortTwo.direction]);
  }

  return orderBy(data, [sortOne.key, sortTwo.key], [sortOne.direction, sortTwo.direction]);
};

/** Image Category */
/**
 * @param id category id
 * @param categoryHash  List of category objects, containing id, name, parent, children, and isGroup
 * @returns
 */
const getCategoryNameFromId = (id: string, categoryHash: IWordMap[]) => {
  // return _.get(_.find(categoryHash, { id }), 'word');
  return get(find(categoryHash, { id }), 'name');
};

const recFlatten2011 = (
  currentNode: BaseJsonObj,
  leafNodes: BaseJsonObj[],
  parentNode: BaseJsonObj,
  wordMap: any,
  parentIds: BaseDatum[],
) => {
  if (has(currentNode, 'children')) {
    const { children } = currentNode;
    const parentId = currentNode.id;
    if (!includes(parentIds, parentId)) {
      parentIds.push(parentId);
    }
    for (let i = 0; i < children.length; i++) {
      const childNode = children[i];
      recFlatten2011(childNode, leafNodes, currentNode, wordMap, cloneDeep(parentIds));
    }
  } else {
    // is leaf node
    parentIds.push(currentNode.id); // add self
    const categoryName = getCategoryNameFromId(currentNode.id, wordMap);
    const parentName = getCategoryNameFromId(parentNode.id, wordMap);
    const parentNamesMap = reduce(
      parentIds,
      (result, nodeId) => {
        return {
          ...result,
          [nodeId]: upperFirst(getCategoryNameFromId(nodeId, wordMap)),
        };
      },
      {},
    );
    const parentNames = values(parentNamesMap);
    leafNodes.push({
      category_id: currentNode.id,
      groupName: parentNames.length >= 2 ? parentNames[0] : undefined, // used for download. can be undefined,
      subGroupName: parentNames.length >= 3 ? parentNames[1] : undefined, // used for download
      groups: parentNamesMap,
      groupIds: parentIds,
      name: categoryName,
      parentName: upperFirst(parentName),
      displayName: upperFirst(categoryName),
      count: currentNode.count,
    });
  }
};
export const flattenNodes2011 = (nodes: BaseJsonObj[], wordMap: IWordMap[]) => {
  const leafNodes: BaseJsonObj[] = [];
  const rootNode = { id: 'root' };
  for (let i = 0; i < nodes.length; i++) {
    const currentNode = nodes[i];
    const parentIds: BaseDatum[] = [];
    recFlatten2011(currentNode, leafNodes, rootNode, wordMap, parentIds);
  }
  return leafNodes;
};

export const addCategorizationShare = (data: JsonObj[]): BaseJsonObj[] => {
  const total = sumBy(data, 'count');
  return map(data, d => {
    const numerator = d.count * 100;
    const denominator = total;
    const percent = denominator === 0 ? '0' : (numerator / denominator).toFixed(2);
    return {
      ...d,
      percentTotal: percent,
    };
  });
};

const aggregateCategoryData2011 = (dataResult: JsonObj[], categoryHash: IWordMap[]): JsonObj[] => {
  const datasetResultList = map(dataResult, datasetResult =>
    flattenNodes2011(datasetResult?.data?.category, categoryHash),
  );
  if (datasetResultList.length === 0) {
    return [];
  }
  const flattened = flatten(datasetResultList);
  const aggregated = reduce(
    flattened,
    (agg, categoryInfo) => {
      const catId = categoryInfo?.category_id;
      const catCount = categoryInfo?.count;
      if (catId in agg && 'count' in agg[catId]) {
        return { ...agg, [catId]: { ...agg[catId], count: agg[catId].count + catCount } };
      }
      return { ...agg, [catId]: categoryInfo };
    },
    {} as JsonObj,
  );
  return Object.values(aggregated);
};

export const formatCategoryData2011 = (
  dataResult: BaseDatum[],
  chartInfo: JsonObj,
  chartName: string,
  categoryHash: IWordMap[],
): JsonObj[] => {
  const { sortX, sortY } = chartInfo;
  const aggregated =
    dataResult.length === 1
      ? flattenNodes2011(dataResult[0]?.data?.category, categoryHash)
      : aggregateCategoryData2011(dataResult, categoryHash);
  return sortData(aggregated, sortY, sortX, chartName);
};

export const getOpacity = (i: number, hoveredIndex: number): '1' | '0.5' => {
  if (hoveredIndex === -1 || hoveredIndex === i) {
    return '1';
  }
  return '0.5';
};

/**
 * @param {string} name ex. 'ml@ai.com' or user's display name, if available. Can also be 'unique-category-id'.
 * @param {number} lengthLimit shortens name > lengthLimit -2 with ellipsis
 * @param {Object} mapper Mapping of xKey to xDisplayName
 *
 *  Formats x axis tick labels for display
 * - Capitalize first letter
 * - Shorten long letter
 */
export const shortenAndCapitalize = (name: string, lengthLimit: number): string => {
  return name.length >= lengthLimit
    ? `${name.charAt(0).toUpperCase() + name.slice(1, lengthLimit - 2)}..`
    : name.charAt(0).toUpperCase() + name.slice(1);
};

export const getDisplayNameFromKey = (
  key: string,
  keyToNameMapper: Record<string, string> | undefined,
): string => {
  if (keyToNameMapper && keyToNameMapper[lowerFirst(key)]) {
    return keyToNameMapper[lowerFirst(key)];
  }
  return key;
};
