import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Sync } from '@superb-ai/icons';
import { Box, Button, Icon, LinkTypography, Typography, useDialogState } from '@superb-ai/ui';
import { useQuery } from '@tanstack/react-query';

import SyncedSettingDialog from '../../../apps/Curate/components/datasets/dataset/settings/SyncedSettingDialog';
import { useDatasetQuery } from '../../../apps/Curate/queries/datasetQueries';
import SyncWithCurateModal from '../../../apps/projects/project/labels/SyncWithCurateModal';
import { canManageProject, useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useApiDefaultParams } from '../../../hooks/ApiParamsHook';
import { useLabelingStatusQuery } from '../../../queries/useOverviewQuery';
import { getUrl } from '../../../routes/util';
import ProjectService from '../../../services/ProjectService';
import WorkappUnion from '../../../union/WorkappUnion';
import ProjectUtils from '../../../utils/ProjectUtils';
import { useSuspendUntil } from '../../../utils/suspendUntil';
import AssignDialog from '../../elements/assign/AssignDialog';
import { AssignDialogProvider } from '../../elements/assign/Context';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import AutoLabelConfig from './AutoLabelConfig';
import Guideline from './Guideline';
import helper from './helper';
import MemberRoles from './MemberRoles';
import ProjectConfig from './ProjectConfig';
import Status from './Status';
import Todo from './Todo';
import { FormattedLabelSummary, GuidelineType, LabelStatusCount, ReviewCount } from './types';
import UsefulLinks from './UsefulLinks';

const useStyle = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2.5),
    boxShadow: theme.shadows[2],
    borderRadius: 7,
  },
  statusSection: {
    marginBottom: theme.spacing(2),
  },
}));

type GuestLayoutProps = {
  isLoading: boolean;
  status: LabelStatusCount[];
  guidelines: GuidelineType[];
  getOverviewInfos: () => Promise<any>;
};

const GuestLayout: React.FC<GuestLayoutProps> = props => {
  const classes = useStyle();
  const { isLoading, status, guidelines, getOverviewInfos } = props;

  return (
    <>
      <MUI.Grid container spacing={2} wrap="wrap">
        <MUI.Grid item xs={8} container>
          <MUI.Grid item xs={12} className={classes.statusSection}>
            <Status className={classes.paper} status={status} />
          </MUI.Grid>
          <MUI.Grid item container spacing={2}>
            <MUI.Grid item xs={12}>
              <Guideline
                className={classes.paper}
                guidelines={guidelines}
                getOverviewInfos={getOverviewInfos}
              />
            </MUI.Grid>
          </MUI.Grid>
        </MUI.Grid>
        <MUI.Grid item xs={4} container alignItems="stretch">
          <ProjectConfig className={classes.paper} />
        </MUI.Grid>
      </MUI.Grid>
      <CircularProgressDialog isLoading={isLoading} />
    </>
  );
};

const Layout: React.FC = () => {
  const classes = useStyle();
  const history = useHistory();
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');

  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const { data: datasetData } = useDatasetQuery({ datasetId: projectInfo.project.curateDatasetId });

  // For collaborator, suspend until projectRole is loaded.
  useSuspendUntil(
    () => authInfo.role !== 'collaborator' || authInfo.projectRole !== null,
    [projectInfo?.project?.id, authInfo?.role, authInfo?.projectRole],
  );

  const showTodoList = canManageProject(authInfo);
  const showReview = WorkappUnion.isSiesta(projectInfo?.project?.workapp);

  const [labelStatusCount, setLabelStatusCount] = useState<LabelStatusCount[]>([]);
  const [reviewCount, setReviewCount] = useState<ReviewCount[][]>([]);
  const [isDataAssignDialogOpen, setIsDataAssignDialogOpen] = useState(false);
  const [countField, setCountField] = useState<'count' | 'frameCount'>('count');

  const syncWithCurateDialog = useDialogState();
  const syncSettingDialogState = useDialogState();

  const apiArgs = useApiDefaultParams({
    projectId,
    origin: 'components/pages/overview/Layout.jsx',
  });

  const statusQuery = useLabelingStatusQuery({
    projectDataType: projectInfo?.project?.labelInterface?.dataType,
  });

  const guidelinesQuery = useQuery(['guidelines', projectId], () =>
    ProjectService.getGuidelines(apiArgs),
  );

  const todoListQuery = useQuery(
    ['todoList', projectId],
    () => ProjectService.getTodoList(apiArgs),
    {
      enabled: showTodoList,
    },
  );

  const isLoading =
    statusQuery.isLoading || guidelinesQuery.isLoading || (showTodoList && todoListQuery.isLoading);

  useEffect(() => {
    if (statusQuery.data) {
      const formatted = helper.formatLabelCountData(
        statusQuery.data,
        countField,
      ) as FormattedLabelSummary;
      setLabelStatusCount(formatted.labelStatus);
      setReviewCount(formatted.review);
    }
  }, [statusQuery.data, countField]);

  const onSetViewMode = useCallback(
    viewMode => setCountField(viewMode === 'frame' ? 'frameCount' : 'count'),
    [],
  );

  function refetch() {
    return Promise.all([guidelinesQuery.refetch(), todoListQuery.refetch(), statusQuery.refetch()]);
  }

  if (authInfo.isGuest) {
    const status = labelStatusCount;
    return (
      <GuestLayout
        {...{
          isLoading,
          status,
          guidelines: guidelinesQuery.data?.results ?? [],
          getOverviewInfos: refetch,
        }}
      />
    );
  }

  return (
    <AssignDialogProvider>
      {projectInfo.project.curateSyncSettings && (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          backgroundColor={
            projectInfo.project.curateSyncSettings?.isActive ? 'green-100' : 'red-100'
          }
          py={1}
          px={4}
          mb={3}
          style={{ marginTop: '-32px', marginRight: '-32px', marginLeft: '-32px' }}
        >
          <Icon icon={Sync} />
          <Typography variant="m-strong">
            <Trans
              t={t}
              i18nKey={`overview.banner.${
                projectInfo.project.curateSyncSettings?.isActive
                  ? 'syncedWithCurate'
                  : 'notSyncedWithCurate'
              }`}
              components={{
                Link1: (
                  <LinkTypography
                    onClick={() =>
                      history.push(
                        getUrl([
                          accountName,
                          'curate',
                          'dataset',
                          projectInfo.project.curateDatasetId,
                        ]),
                      )
                    }
                  />
                ),
              }}
              values={{ datasetName: datasetData?.name ?? '' }}
            />
          </Typography>
          {projectInfo.project.curateSyncSettings?.isActive ? (
            <Button
              variant="strong-fill"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                syncWithCurateDialog.show();
              }}
            >
              {t('projects.button.syncAllLabels')}
            </Button>
          ) : (
            <Button
              variant="strong-fill"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                syncSettingDialogState.show();
              }}
            >
              {t('projects.button.syncWithCurate')}
            </Button>
          )}
        </Box>
      )}
      <MUI.Grid container spacing={2} wrap="wrap">
        {showTodoList && (
          <MUI.Grid item xs={6}>
            <Todo
              className={classes.paper}
              todos={todoListQuery.data?.todolist ?? []}
              setIsDataAssignDialogOpen={setIsDataAssignDialogOpen}
            />
          </MUI.Grid>
        )}
        <MUI.Grid item xs={showTodoList ? 6 : 12}>
          <UsefulLinks className={classes.paper} />
        </MUI.Grid>
        <MUI.Grid item xs={12} lg={8} container alignContent="flex-start">
          <MUI.Grid item xs={12} className={classes.statusSection}>
            <Status
              className={classes.paper}
              status={labelStatusCount}
              review={showReview ? reviewCount : undefined}
              onSetViewMode={onSetViewMode}
              showViewMode={ProjectUtils.isFrameBasedProject(projectInfo.project.labelInterface)}
            />
          </MUI.Grid>
          <MUI.Grid item container spacing={2}>
            <MUI.Grid item xs={6}>
              <MemberRoles className={classes.paper} />
            </MUI.Grid>
            <MUI.Grid item xs={6}>
              <Guideline
                className={classes.paper}
                guidelines={guidelinesQuery.data?.results ?? []}
                getOverviewInfos={refetch}
              />
            </MUI.Grid>
          </MUI.Grid>
        </MUI.Grid>
        <MUI.Grid item xs={12} lg={4}>
          <AutoLabelConfig className={classes.paper} />
          <ProjectConfig className={classes.paper} />
        </MUI.Grid>
      </MUI.Grid>
      <AssignDialog
        isOpen={isDataAssignDialogOpen}
        setIsOpen={setIsDataAssignDialogOpen}
        projectName={projectInfo.project.name}
      />
      <CircularProgressDialog isLoading={isLoading} />{' '}
      <SyncWithCurateModal state={syncWithCurateDialog} filterApiParams={{}} />
      <SyncedSettingDialog
        state={syncSettingDialogState}
        labelProject={projectInfo.project}
        refetchLabelProjects={projectInfo.updateProjectInfo}
      />
    </AssignDialogProvider>
  );
};

export default Layout;
