import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import { CaretRight, CircleUp, SearchSmallPlus } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  extendComponent,
  Icon,
  LinkTypography,
  LoadingIndicator,
  Paragraph,
  SegmentedControl,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import analyticsTracker from '../../../../analyticsTracker';
import { Row } from '../../../../components/elements/Row';
import { getUrl } from '../../../../routes/util';
import { MODEL_TRAIN } from '../../path';
import {
  useBaselineModelSamplesDetailQuery,
  useBaselineModelSamplesQuery,
  useModelTrainingSamplePredictionsQuery,
} from '../../queries/modelQueries';
import { BaselineModelSamplesData } from '../../services/ModelService';
import { GenerationAIModelDetailTraining } from '../../services/types';
import { fromQuery, TrainQueryKeyword } from '../../train/queries';
import { SamplePredictionDialog } from '../SamplePredictionDialog';

const RECO_BASELINE_MODEL_ID = '51fd20aa-bdc1-49d5-b1be-4dbc865e0a10';
type ModelSampleCategory = ComponentProps<typeof SegmentedControl>['options'];
const modelSampleCategoryOptions: ModelSampleCategory = [
  { value: 'all', label: 'All' },
  { value: 'safety', label: 'Safety' },
  { value: 'transportation', label: 'Transportation' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'public', label: 'Public' },
  { value: 'smartFarming', label: 'Smart Farming' },
  { value: 'aviation', label: 'Aviation' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'medicine', label: 'Medicine' },
  { value: 'entertainment', label: 'Entertainment' },
];

export const Layout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch<{ accountName: string }>();
  const { data, isLoading } = useBaselineModelSamplesQuery({ id: RECO_BASELINE_MODEL_ID });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Row justifyContent="space-between" mb={1.5}>
        <Box>
          <Row gap={0.5} mb={1}>
            <Image
              src={'/static/image/ReCo-logo.png'}
              width={16}
              height={16}
              alt=""
              style={{ marginRight: 4 }}
            />
            <Typography variant="h3">ReCo</Typography>
            <Chip ml={0.5}>{t('model.datasetType.image_generation')}</Chip>
          </Row>
          <Typography variant={'m-regular'}>
            {t('model.baselineModels.info.reco.description')}
          </Typography>
        </Box>
        <Box mb="auto">
          <Button
            onClick={() => {
              analyticsTracker.trainModelEntered({
                accountId: params.accountName,
                referrer: 'entered-from-model-list',
              });
              history.push(
                getUrl(
                  [params.accountName, MODEL_TRAIN],
                  {},
                  { [TrainQueryKeyword.From]: fromQuery.genAI },
                ),
              );
            }}
          >
            <Icon icon={CircleUp} color="primary" />
            {t('model.train.trainNewModel')}
          </Button>
        </Box>
      </Row>
      <Tabs>
        <TabList color="primary">
          <Tab>{t('model.generativeAi.modelSample')}</Tab>
          <Tab>{t('model.generativeAi.useCase')}</Tab>
        </TabList>
        <Box mt={1}>
          <TabPanel>
            <Box display="flex" flexDirection="column" gap={1}>
              {data.samples.map(sample => {
                return <ModelSample key={sample.id} sample={sample} />;
              })}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box display="flex" justifyContent="center">
              <HowToUse />
            </Box>
          </TabPanel>
        </Box>
      </Tabs>
    </>
  );
};

const ModelSample = ({ sample }: { sample: BaselineModelSamplesData['samples'][number] }) => {
  const { t } = useTranslation();
  const dialogState = useDialogState();

  const boxSize = 132;
  const { data } = useBaselineModelSamplesDetailQuery({
    id: RECO_BASELINE_MODEL_ID,
    domain: sample.id,
  });

  const { data: referenceAnnotation, isLoading: isRefLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: data?.referenceImage.annotations ?? '',
    });

  const { data: iterationAnnotation, isLoading: isIterLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: data?.syntheticImages.annotations ?? '',
    });

  if (isRefLoading) {
    return <LoadingIndicator />;
  }

  const modelTraining: GenerationAIModelDetailTraining = {
    modelTrainingReferenceImages: {
      annotations: data.referenceImage.annotations,
      images: [
        { fileName: data.referenceImage.image.fileName, url: data.referenceImage.image.url },
      ],
    },
    modelTrainingIterations: [
      {
        annotations: data.syntheticImages.annotations,
        images: data.syntheticImages.images,
        iteration: 0,
      },
    ],
    additionalInfo: { samplePredictionImageIds: [] },
  };

  return (
    <>
      {/* <SegmentedControl
        value={selectedModelSampleCategory}
        onChangeValue={(value: ModelSampleCategory[number]) =>
          setSelectedModelSampleCategory(value)
        }
        options={modelSampleCategoryOptions}
        mb={1}
        color="gray-150"
      /> */}
      <ModelSampleRow>
        <Box style={{ width: 244 }}>
          <Row gap={1} mb={1}>
            <Typography
              variant="l-strong"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {t(`model.generativeAi.samples.ReCo.${sample.id}.name`)}
            </Typography>
            <Chip>{t(`model.generativeAi.samples.ReCo.${sample.id}.type`)}</Chip>
          </Row>
          <Box overflow="hidden" style={{ height: 54 }}>
            <Typography variant="m-regular">
              {t(`model.generativeAi.samples.ReCo.${sample.id}.description`)}
            </Typography>
            {sample.source && (
              <Link href={sample.source} target="_blank" rel="noopener noreferrer">
                <Box>
                  <LinkTypography variant="s-regular">
                    {t('model.overview.linkToDataset')}
                  </LinkTypography>
                </Box>
              </Link>
            )}
          </Box>

          <Button color="primary" onClick={() => dialogState.show()} style={{ marginTop: 20 }}>
            <Icon icon={SearchSmallPlus} />
            {t('model.generativeAi.viewMoreSample')}
          </Button>
        </Box>
        <Box display="grid" style={{ gridTemplateColumns: '1fr 24px 1fr 1fr 1fr 1fr' }}>
          <Row backgroundColor={'gray-100'} justifyContent="center" style={{ height: boxSize }}>
            <Image src={sample.referenceImage} alt={sample.name} width={boxSize} height={boxSize} />
          </Row>
          <Row>
            <Icon icon={CaretRight} size={24} style={{ marginInline: 4 }} />
          </Row>
          {sample.syntheticImages.map((image, idx) => {
            return (
              <Row
                key={image}
                backgroundColor={'gray-100'}
                mr={idx < sample.syntheticImages.length - 1 ? 1 : undefined}
                justifyContent="center"
                style={{ height: boxSize }}
              >
                <Image src={image} alt={image} width={boxSize} height={boxSize} />
              </Row>
            );
          })}
        </Box>
      </ModelSampleRow>
      {dialogState.visible && (
        <SamplePredictionDialog
          state={dialogState}
          modelTraining={modelTraining}
          iterationIndex={0}
          referenceAnnotation={referenceAnnotation}
          iterationAnnotation={iterationAnnotation}
          isRefLoading={isRefLoading}
          isIterLoading={isIterLoading}
          title={
            <Row gap={1.5}>
              <Trans
                t={t}
                i18nKey={'model.generativeAi.sampleModelSample'}
                values={{ sampleName: sample.name }}
              />
              <Chip>{sample.type}</Chip>
            </Row>
          }
        />
      )}
    </>
  );
};

const ModelSampleRow = extendComponent(Box, {
  display: 'grid',
  px: 2.5,
  py: 2,
  border: '1px solid',
  borderColor: 'gray-150',
  gap: 2,
  style: {
    height: 172,
    borderRadius: 4,
    gridTemplateColumns: '244px 1fr',
  },
});

const HowToUse = () => {
  const { t, i18n } = useTranslation();
  const thumbnailWidth = 370;
  const chartWidth = 376;
  const chartHeight = 242;

  const videoSrc = `/static/video/${i18n.language}-gen-ai-docs-demo.mp4`;
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ paddingBlock: 58, gap: 60, width: chartWidth * 2 + 8 }}
    >
      <Box display="grid" style={{ gridTemplateColumns: `1fr ${thumbnailWidth}px`, columnGap: 20 }}>
        <Box>
          <Paragraph variant="h1" mb={2}>
            {t('model.generativeAi.howToUse.paragraph1Title')}
          </Paragraph>
          <Typography variant="l-regular">
            {t('model.generativeAi.howToUse.paragraph1TitleContent')}
          </Typography>
        </Box>
        <Box backgroundColor={'gray-150'} style={{ height: 220 }}>
          <Image
            src={'/static/image/thumbnail.png'}
            width={thumbnailWidth}
            height={220}
            alt="thumbnail"
          />
        </Box>
      </Box>
      <Box>
        <Paragraph variant="h2" textAlign="center">
          {t('model.generativeAi.howToUse.paragraph2Title')}
        </Paragraph>
        <Box mt={2.5}>
          <Paragraph variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph2SubTitle1')}
          </Paragraph>
          <Box ml={2} mb={2.5}>
            <Typography variant="l-regular">
              {t('model.generativeAi.howToUse.paragraph2SubTitle1Content')}
            </Typography>
          </Box>
          <Paragraph variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph2SubTitle2')}
          </Paragraph>
          <Box ml={2} mb={2.5}>
            <Typography variant="l-regular">
              {t('model.generativeAi.howToUse.paragraph2SubTitle2Content')}
            </Typography>
          </Box>
          <Paragraph variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph2SubTitle3')}
          </Paragraph>
          <Box ml={2} mb={2.5}>
            <Typography variant="l-regular">
              {t('model.generativeAi.howToUse.paragraph2SubTitle3Content')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Paragraph variant="h2" textAlign="center" mb={2.5}>
          {t('model.generativeAi.howToUse.paragraph3Title')}
        </Paragraph>
        <Paragraph variant="l-regular" mb={3}>
          {t('model.generativeAi.howToUse.paragraph3TitleContent')}
        </Paragraph>
        <Paragraph variant="l-strong" mb={2.5} textAlign="center">
          {t('model.generativeAi.howToUse.paragraph3ChartPrefix')}
        </Paragraph>
        <Box
          display="grid"
          mb={2.5}
          justifyContent="center"
          style={{
            gridTemplateColumns: `${chartWidth}px ${chartWidth}px`,
            gridTemplateRows: `${chartHeight}px ${chartHeight}px ${chartHeight}px`,
            rowGap: 8,
            columnGap: 8,
          }}
        >
          <Image
            src={'/static/image/chart-1.png'}
            width={chartWidth}
            height={chartHeight}
            alt="chart1"
          />
          <Image
            src={'/static/image/chart-2.png'}
            width={chartWidth}
            height={chartHeight}
            alt="chart2"
          />
          <Image
            src={'/static/image/chart-3.png'}
            width={chartWidth}
            height={chartHeight}
            alt="chart3"
          />
          <Image
            src={'/static/image/chart-4.png'}
            width={chartWidth}
            height={chartHeight}
            alt="chart4"
          />
          <Image
            src={'/static/image/chart-5.png'}
            width={chartWidth}
            height={chartHeight}
            alt="chart5"
          />
        </Box>
        <Paragraph variant="l-regular">
          {t('model.generativeAi.howToUse.paragraph3ChartSuffix')}
        </Paragraph>
      </Box>
      <Box>
        <Paragraph variant="h2" textAlign="center">
          {t('model.generativeAi.howToUse.paragraph4Title')}
        </Paragraph>
        <Box my={2.5} backgroundColor="gray-150">
          <video style={{ width: '100%', height: '100%' }} controls>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </Box>
        <OrderListRow>
          <Typography variant="l-strong">1.</Typography>
          <Typography variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph4SubTitle1')}
          </Typography>
        </OrderListRow>
        <Box ml={2} mb={2.5}>
          <Typography variant="l-regular">
            {t('model.generativeAi.howToUse.paragraph4SubTitle1Content')}
          </Typography>
        </Box>
        <OrderListRow>
          <Typography variant="l-strong">2.</Typography>
          <Typography variant="l-strong" mb={2.5}>
            {t('model.generativeAi.howToUse.paragraph4SubTitle2')}
          </Typography>
        </OrderListRow>
        <OrderListRow>
          <Typography variant="l-strong">3.</Typography>
          <Typography variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph4SubTitle3')}
          </Typography>
        </OrderListRow>
        <Box ml={2} mb={2.5}>
          <Typography variant="l-regular">
            {t('model.generativeAi.howToUse.paragraph4SubTitle3Content')}
          </Typography>
        </Box>
        <OrderListRow>
          <Typography variant="l-strong">4.</Typography>
          <Typography variant="l-strong">
            {t('model.generativeAi.howToUse.paragraph4SubTitle4')}
          </Typography>
        </OrderListRow>
      </Box>
    </Box>
  );
};

const OrderListRow = extendComponent(Box, {
  display: 'flex',
  alignItems: 'flex-start',
  gap: 0.5,
});
