import { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as _Link, useRouteMatch } from 'react-router-dom';

import { ChevronBigRight, InfoFilled } from '@superb-ai/icons';
import { Box, Button, extendComponent, Icon, Tooltip, Typography } from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import { default as deta } from '../../../../assets/img/deta_logo.png';
import { default as detr } from '../../../../assets/img/detr_logo.png';
import { default as mask2former } from '../../../../assets/img/mask2former_logo.png';
import { default as yolov6 } from '../../../../assets/img/yolov6_logo.png';
import { getUrl } from '../../../../routes/util';
import { TaskTypeChip } from '../../components/components';
import { usePublicModelListQuery } from '../../queries/modelQueries';
import { PublicModel, PublicModelSource } from '../../services/types';
import { fromQuery, TrainQueryKeyword } from '../../train/queries';

export function PublicModelPanel() {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const baselineModelQuery = usePublicModelListQuery({
    params: {
      modelPurpose: 'recognition',
    },
  });
  const baselineModelList = baselineModelQuery.data?.pages.flatMap(p => p.data) ?? [];

  return (
    <>
      <Box display="flex" flexWrap="wrap" mt={1.5} style={{ gap: 20 }}>
        {baselineModelList.map(data => {
          return (
            <BaselineModelCard data={data} key={data.id}>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: 20, borderTop: '1px solid #E8E8E8' }}
              >
                <Button
                  color="primary"
                  variant="strong-fill"
                  style={{ marginLeft: 'auto' }}
                  {...({
                    as: _Link,
                    to: getUrl(
                      [params.accountName, 'model', 'train'],
                      {},
                      {
                        [TrainQueryKeyword.From]: fromQuery.recognitionAI,
                        [TrainQueryKeyword.Public]: data.id,
                      },
                    ),
                  } as any)}
                >
                  {t('model.baselineModels.train')}
                </Button>
              </Box>
            </BaselineModelCard>
          );
        })}
      </Box>
    </>
  );
}

const BaselineModelContainer = extendComponent(Box, {
  border: '1px solid',
  borderRadius: '2px',
  borderColor: 'gray-150',
  style: { width: 323, minWidth: 323 },
});

export const BaselineModelCard = ({
  data,
  children,
  ...props
}: {
  data: PublicModel;
  children?: ReactNode;
} & Omit<ComponentProps<typeof Box>, 'data'>) => {
  const { t } = useTranslation();

  const getLogo = (companyName: PublicModelSource) => {
    switch (companyName) {
      case 'DETA':
        return <Image src={deta} alt={'deta'} height={20} />;
      case 'YOLOv6':
        return <Image src={yolov6} alt={'yolov6'} height={20} />;
      case 'DETR':
        return <Image src={detr} alt={'yolov6'} height={20} />;
      case 'Mask2Former':
        return <Image src={mask2former} alt={'mask2former'} height={20} />;
      default:
        return;
    }
  };

  const boldNumbersAndEscapeHtml = (input: string): React.ReactNode => {
    const result: React.ReactNode[] = [];
    for (let i = 0; i < input.length; i++) {
      if (!isNaN(Number(input[i]))) {
        result.push(<b key={i}>{input[i]}</b>);
      } else {
        result.push(escapeHtml(input[i]));
      }
    }
    return result;
  };

  const escapeHtml = (unsafe: string): string => {
    return unsafe.replace(/[&<"']/g, m => {
      switch (m) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#039;';
        default:
          return m;
      }
    });
  };

  const resolveI18nKey = (i18nKey: string) => {
    // 서버에서 먼저 추가된 baseline model의 성능에 관한 값이 i18n으로 준비되지 않았을 때 서버 값을 직접 참조
    if (t(i18nKey) === i18nKey) {
      // property: description, inferenceSpeed, throughput, maximumInputSize, performance, etc...
      const property = i18nKey.split('.')[4] as keyof PublicModel;
      return data[property] as string;
    } else {
      // i18n이 준비되어있을 경우 그대로 번역
      return t(i18nKey);
    }
  };

  return (
    <BaselineModelContainer {...props}>
      <Box style={{ padding: '20px' }}>
        <Box display="flex" alignItems="center" mb={1.5}>
          {getLogo(data.source)}
          <Box marginLeft="auto">
            <TaskTypeChip taskType={data.task} color={'secondary'} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Link href={data.sourceUrl} target="_blank" rel="noopener noreferrer">
            <Typography variant="l-strong" color={'black-400'}>
              {data.name}
            </Typography>
          </Link>
          <Icon icon={ChevronBigRight} size={16} />
        </Box>
        <Box mb={2}>
          <Typography variant="m-regular">
            {resolveI18nKey(`model.baselineModels.info.${data.modelArch}.description`)}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="m-regular">
            {boldNumbersAndEscapeHtml(
              resolveI18nKey(`model.baselineModels.info.${data.modelArch}.performance`),
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.inferenceSpeed')}
          </Typography>
          <Box ml="auto" display="flex" alignItems="center">
            <Typography variant="m-regular" mr={0.5}>
              {resolveI18nKey(`model.baselineModels.info.${data.modelArch}.inferenceSpeed`)}
            </Typography>
            <Tooltip content={<Box>{t('model.baselineModels.tooltip')}</Box>} placement="top">
              <Icon icon={InfoFilled} />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.throughput')}
          </Typography>
          <Box ml="auto">
            <Typography variant="m-regular">
              {resolveI18nKey(`model.baselineModels.info.${data.modelArch}.throughput`)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="m-regular" color={'gray-300'}>
            {t('model.baselineModels.maximumInputSize')}
          </Typography>
          <Box ml="auto">
            <Typography variant="m-regular">
              {resolveI18nKey(`model.baselineModels.info.${data.modelArch}.maximumInputSize`)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {children}
    </BaselineModelContainer>
  );
};
