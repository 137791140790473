import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

export default function SendToCurateCompleteDialog({
  sendToCurateCompleteIsOpen,
  setSendToCurateCompleteIsOpen,
  selectedSliceName,
  selectedSliceId,
  selectedDatasetName,
  selectedDatasetId,
  initialize,
}: {
  sendToCurateCompleteIsOpen: boolean;
  setSendToCurateCompleteIsOpen: (isOpen: boolean) => void;
  selectedSliceName?: string | null;
  selectedSliceId: string | null;
  selectedDatasetName: string;
  selectedDatasetId: string;
  initialize: () => void;
}): JSX.Element {
  const { accountName } = useParams<{ accountName: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Modal
      open={sendToCurateCompleteIsOpen}
      close={{
        onClose: () => {
          initialize();
          setSendToCurateCompleteIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('sendToCurateCompleteDialog.title')}
      mainButton={{
        text: selectedSliceId
          ? t('sendToCurateCompleteDialog.goToSliceButton')
          : t('sendToCurateCompleteDialog.goToDatasetButton'),
        onClick: () => {
          setSendToCurateCompleteIsOpen(false);
          if (selectedSliceId)
            history.push(
              `/${accountName}/curate/dataset/${selectedDatasetId}/slice/${selectedSliceId}`,
            );
          else history.push(`/${accountName}/curate/dataset/${selectedDatasetId}/explore`);
        },
        color: 'primary',
      }}
      subButton={{
        text: t('button.close'),
        onClick: () => {
          setSendToCurateCompleteIsOpen(false);
        },
      }}
    >
      <Box style={{ minWidth: '520px' }} px={4} py={2.5}>
        {selectedSliceId ? (
          <Typography variant="m-regular">
            <Trans
              t={t}
              i18nKey={'labels.dialogs.sendToCurate.sendToCurateComplete'}
              values={{ sliceName: selectedSliceName, datasetName: selectedDatasetName }}
              components={{ 1: <Typography color="primary-400" /> }}
            />
          </Typography>
        ) : (
          <Typography variant="m-regular">
            <Trans
              t={t}
              i18nKey={'labels.dialogs.sendToCurate.sendToCurateCompleteWithoutSlice'}
              values={{ datasetName: selectedDatasetName }}
              components={{ 1: <Typography color="primary-400" /> }}
            />
          </Typography>
        )}
      </Box>
    </Modal>
  );
}
