import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

import TextEllipsisTooltip from '../../elements/TextEllipsisTooltip';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  icon: {
    color: '#333',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  rowName: {},
}));

type Type = 'checkbox' | 'radio' | 'free response';

interface Props {
  isSiesta: boolean;
  imageCategoryMap: any;
  imageCategory: {
    id: string;
    options?: string[];
    children?: string[];
    name: string;
    isGroup: boolean;
  };
  depth: any;
  fontSize: number;
  color?: string;
  type?: Type;
}

const convertType = (type: Type) => {
  return {
    checkbox: 'projectSettings.propertyType.multipleSelection',
    radio: 'projectSettings.propertyType.multipleChoice',
    'free response': 'projectSettings.propertyType.freeResponse',
  }[type];
};

const getPl = (isSiesta: boolean, depth: number, isGroup: boolean): string => {
  if (isSiesta) {
    if (depth === 0) {
      return '0px';
    }
    return isGroup ? '20px' : '32px';
  }
  if (depth <= 1) {
    return '0px';
  }
  return isGroup ? '20px' : '32px';
};

const LegacyOverviewImageCategoryItem: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isSiesta,
    imageCategoryMap,
    imageCategory,
    depth,
    fontSize = 12,
    color = '#212121',
    type,
  } = props;
  const { id, name, isGroup } = imageCategory;
  const [isOpen, setIsOpen] = useState(id === 'root');

  const getCurImageCategory = () => {
    if (id === 'root') return null;

    const getArrow = () => {
      const children = imageCategory.options || imageCategory.children || [];

      if (children.length === 0) return null;
      const handleClickArrow = () => {
        setIsOpen(!isOpen);
      };

      return (
        <MUI.IconButton onClick={handleClickArrow} size="small">
          <Icon name={isOpen ? 'caretDown' : 'caretRight'} className={classes.icon} size="14px" />
        </MUI.IconButton>
      );
    };

    return (
      <MUI.Box className={classes.row}>
        {getArrow()}
        <TextEllipsisTooltip text={name}>
          <MUI.Typography className={classes.rowName} style={{ fontSize: `${fontSize}px`, color }}>
            {name}
          </MUI.Typography>
        </TextEllipsisTooltip>
        <MUI.Typography className={classes.rowName} style={{ fontSize: `${fontSize}px`, color }}>
          {type ? ` | ${t(convertType(type))}` : ''}
        </MUI.Typography>
      </MUI.Box>
    );
  };
  const getChildren = () => {
    const children = imageCategory.options || imageCategory.children || [];

    if (children.length === 0 || !isOpen) return null;

    return children.map((child: any) => {
      const childId = typeof child === 'string' ? child : child.id;

      return (
        <LegacyOverviewImageCategoryItem
          key={childId}
          isSiesta={isSiesta}
          depth={depth + 1}
          imageCategoryMap={imageCategoryMap}
          imageCategory={imageCategoryMap[childId]}
          fontSize={fontSize}
          color={color}
        />
      );
    });
  };

  return (
    <MUI.Box className={classes.box} pl={getPl(isSiesta, depth, isGroup)}>
      {getCurImageCategory()}
      {getChildren()}
    </MUI.Box>
  );
};

export default LegacyOverviewImageCategoryItem;
