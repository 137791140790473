import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRight } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';

import useSectionSize from '../windowedImageGrid/useSectionSize';

type Content = {
  imageSrc: string | StaticImageData;
  title: string;
  description: string | JSX.Element;
  link?: string;
};

export default function Tutorial({
  contents,
  fullWidth,
}: {
  contents: Content[];
  fullWidth?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSectionSize = useSectionSize(containerRef);
  const { t } = useTranslation();

  return (
    <Box ref={containerRef}>
      {containerSectionSize && (
        <Box
          display="flex"
          justifyContent="center"
          overflow="auto"
          style={{ width: containerSectionSize[2], height: containerSectionSize[3] }}
        >
          <Box py={4} px={fullWidth ? 1 : 4} style={{ maxWidth: '1324px' }}>
            <Box
              width="100%"
              display="grid"
              gap={3}
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
              }}
            >
              {contents.map(content => (
                <Box
                  key={content.title}
                  p={3}
                  boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
                  borderRadius="2px"
                  display="grid"
                  style={{ gridTemplateRows: 'auto auto 1fr auto' }}
                  gap={2}
                >
                  <Image
                    src={content.imageSrc}
                    alt={content.title}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <Typography variant="l-strong">{content.title}</Typography>
                  <Typography variant="m-regular" style={{ minHeight: '100px' }}>
                    {content.description}
                  </Typography>
                  {content.link && (
                    <Link
                      href={content.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: 'auto' }}
                    >
                      <Button>
                        {t('curate.tutorial.button.learnMore')} <Icon icon={ArrowRight} />
                      </Button>
                    </Link>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
