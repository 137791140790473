import { useEffect } from 'react';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import CircularProgressDialog from '../../elements/CircularProgressDialog';

export default function SysAdminSignInLayout() {
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { id_token: idToken, refresh_token: refreshToken } = routeInfo.params;

  useEffect(() => {
    if (!idToken || !refreshToken) {
      routeInfo.history.replace('/auth/login');
      return;
    }

    localStorage.setItem('spb_user', idToken);
    localStorage.setItem('spb_', refreshToken);

    authInfo.sysAdminLogin(idToken);
    // eslint-disable-next-line
  }, [routeInfo, authInfo]);

  useEffect(() => {
    if (authInfo.accountName) {
      routeInfo.history.push('/');
    }
  }, [authInfo.accountName]);

  return <CircularProgressDialog isLoading />;
}
