import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';

import * as MUI from '@mui/material';
import { Box, Button, Typography, useAlertModal } from '@superb-ai/norwegian-forest';
import { concat, filter, find, includes, isEmpty, map, omit, pickBy } from 'lodash';

import { DELETE_ASSET } from '../../../../consts/ModalMessage';
import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useProjectListInfo } from '../../../../hooks/ProjectListInfo';
import {
  useSearchedProjectMutation,
  useSearchedProjectQuery,
  useUpdateProjectList,
} from '../../../../queries/useProjectListQuery';
import { getUrl, joinPaths } from '../../../../routes/util';
import CommandsService from '../../../../services/CommandsService';
import ProjectsService from '../../../../services/ProjectsService';
import { formatNumber } from '../../../../utils/numberFormat';
import ParamUtils from '../../../../utils/ParamUtils';
import TablePagination from '../../../elements/TablePagination';
import AssignToProjectDialog from '../AssignToProjectDialog';
import { DataDetailNavigationProvider } from '../dataDetail/Context';
import { DataDetailRoute } from '../dataDetail/Route';
import DataTableView from './DataTableView';

const DataContainer = (): React.ReactElement => {
  const { t } = useTranslation();
  const [isAllAssetsChecked, setIsAllAssetsChecked] = useState(false);
  const [checkedAssets, setCheckedAssets] = useState<string[]>([]);
  const [apiParams, setApiParams] = useState<Record<string, any>>({});
  const [isLoadingAssets, setIsLoadingAssets] = useState(true);
  const [isAssignToProjectDialogOpen, setIsAssignToProjectDialogOpen] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string>();
  const [orderBy, setOrderBy] = useState<string>('created_at');
  const [isDesc, setIsDesc] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { openModal, closeModal } = useAlertModal();
  const assetsInfo = useAssetsInfo();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { pathname, search } = useLocation();
  const path = getUrl([routeInfo.urlMatchInfo.accountName, 'label/data']);
  const url = `${pathname}${search}`;
  const commandContext = useLabelCommandContext();
  const { params } = routeInfo;
  const project = `${params.project}`;

  const projectsInfo = useProjectListInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = lokiFlag;

  const { mutateAsync: getProjectList } = useUpdateProjectList();
  const { mutate: updateSearchedProjectIds } = useSearchedProjectMutation();
  const { data: searchedProjectIds } = useSearchedProjectQuery();

  const isNoDataChecked = isEmpty(assetsInfo.assets) || isEmpty(checkedAssets);

  useEffect(() => {
    if (!selectedAssetId) return;
    const nextUrl = getUrl(
      [path, DataDetailRoute.path],
      { assetId: selectedAssetId },
      routeInfo.location.search,
    );
    if (nextUrl !== url) {
      routeInfo.history.push(nextUrl);
    }
    setSelectedAssetId('');
  }, [selectedAssetId, path, url, routeInfo]);

  const getProjectIdByName = async () => {
    if (
      project === '[ all projects ]' ||
      project === '[ assigned to a project ] ' ||
      project === '[ not assigned to any project ]'
    )
      return '';

    if (searchedProjectIds[project]) return searchedProjectIds[project].id;

    const projectList = await ProjectsService.getProjects({
      params: { nameIcontains: project },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    const foundProject = find(projectList.results, ['name', project]);

    return foundProject?.id;
  };

  useEffect(() => {
    if (!assetsInfo) return;
    assetsInfo.updateDatasets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!assetsInfo || !projectsInfo) return;
    (async () => {
      setCheckedAssets([]);
      setIsLoadingAssets(true);

      const projectIdByName = (await getProjectIdByName()) as string;

      const sortParam = pickBy(
        { ordering: !isDesc ? orderBy : '-'.concat(orderBy) },
        v => !isEmpty(v),
      );
      const filterParams = ParamUtils.getApiParamsForDataFilter(params, projectIdByName);
      const formattedParams = { ...sortParam, ...filterParams };
      setApiParams(formattedParams);
      await assetsInfo.updateAssets(formattedParams);
      setIsLoadingAssets(false);
    })();

    // eslint-disable-next-line
  }, [
    params.dataset,
    params.project,
    params.page,
    params.pageSize,
    params.key,
    params.uploader,
    params.dataType,
    params.createdDate,
    params.createdStartDate,
    orderBy,
    isDesc,
    currentPage,
  ]);

  const deleteData = async () => {
    const projectIdByName = (await getProjectIdByName()) as string;
    const apiParams: Record<string, any> = isAllAssetsChecked
      ? omit(ParamUtils.getApiParamsForDataFilter(params, projectIdByName), [
          'ordering',
          'pageSize',
          'page',
        ])
      : {
          idIn: checkedAssets,
        };
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'ASSETS_DELETE',
      params: apiParams,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);
  };

  const handleClickGlobalCheckbox = () => {
    if (isAllAssetsChecked) {
      setIsAllAssetsChecked(false);
      setCheckedAssets([]);
      return;
    }

    if (checkedAssets.length === assetsInfo.assets.length) {
      setCheckedAssets([]);
      return;
    }
    setCheckedAssets(map(assetsInfo.assets, asset => asset.id));
  };

  const handleClickCheckbox = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (includes(checkedAssets, id)) {
      if (isAllAssetsChecked) {
        setIsAllAssetsChecked(false);
      }
      setCheckedAssets(filter(checkedAssets, checkedAsset => checkedAsset !== id));
      return;
    }
    setCheckedAssets(concat(checkedAssets, [id]));
  };

  const handleClickSelectAllAssets = (isAllAssetsChecked: boolean) => () => {
    setIsAllAssetsChecked(isAllAssetsChecked);
    if (!isAllAssetsChecked) {
      setCheckedAssets([]);
    }
  };

  const handleClickAddToProject = () => {
    setIsAssignToProjectDialogOpen(true);
  };

  const handleCloseAssignToProjectDialog = () => {
    setIsAssignToProjectDialogOpen(false);
  };

  const handleRequestSort = (property: any, order: 'desc' | 'asc' | '-desc' | '-asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const handleClickDeleteItem = () => {
    openModal({
      ...DELETE_ASSET({
        t,
        count: isAllAssetsChecked ? assetsInfo.totalCount : checkedAssets.length,
      }),
      mainButton: {
        text: t('button.delete'),
        onClick: () => {
          deleteData();
          closeModal();
        },
      },
      subButton: { text: t('button.cancel'), onClick: closeModal },
    });
  };

  const handleSearchProject = async (inputValue: string) => {
    const projectList = await getProjectList({
      nameIcontains: inputValue,
      origin: 'components/pages/data/DataContainer.jsx',
    });
    const searchProjectIds = {} as Record<string, string>;
    const resultProjects = map(projectList.results, project => {
      searchProjectIds[project.name] = project.id;
      return { label: project.name, value: project.name };
    });

    updateSearchedProjectIds(searchProjectIds);

    return resultProjects;
  };
  return (
    <DataDetailNavigationProvider>
      <MUI.Grid container direction="column">
        <MUI.Grid item xs={12}>
          <MUI.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="50px"
          >
            <Typography variant="body4" themedColor="textDefault">
              {t('data.totalCount', {
                dataCount: isLoadingAssets ? '...' : formatNumber(assetsInfo.totalCount),
              })}
            </Typography>
            <Box display="flex" alignItems="center" gap="8px">
              <Button
                variant="shadow"
                color="primary"
                IconAdornment="listAssign"
                disabled={isNoDataChecked}
                onClick={handleClickAddToProject}
              >
                {t('data.button.assignToProject')}
              </Button>
              <Button
                variant="shadow"
                color="primary"
                IconAdornment="trash"
                disabled={isNoDataChecked}
                onClick={handleClickDeleteItem}
              >
                {t('data.button.deleteItem')}
              </Button>
            </Box>
          </MUI.Box>
          <DataTableView
            setSelectedAssetId={setSelectedAssetId}
            onClickCheckbox={handleClickCheckbox}
            onClickGlobalCheckbox={handleClickGlobalCheckbox}
            onClickSelectAllAssets={handleClickSelectAllAssets}
            checkedAssets={checkedAssets}
            isAllAssetsChecked={isAllAssetsChecked}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isLoading={isLoadingAssets}
            variant="page"
          />
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <TablePagination
            totalCount={assetsInfo.totalCount}
            {...{ currentPage, setCurrentPage }}
          />
        </MUI.Grid>
        <AssignToProjectDialog
          isAllChecked={isAllAssetsChecked}
          variant="data"
          checkedList={checkedAssets}
          isOpen={isAssignToProjectDialogOpen}
          onClose={handleCloseAssignToProjectDialog}
          params={apiParams}
          dataset={params.dataset}
          totalCount={assetsInfo.totalCount}
          handleSearch={handleSearchProject}
        />
      </MUI.Grid>
      <Switch>
        {/* @ts-ignore: saa-680 */}
        <Route path={joinPaths(path, DataDetailRoute.path)} component={DataDetailRoute} />
      </Switch>
    </DataDetailNavigationProvider>
  );
};

export default DataContainer;
