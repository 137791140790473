import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { DateRangePicker as RsuiteDateRangePicker } from 'rsuite';
import { RangeType } from 'rsuite/esm/DateRangePicker';

interface Props extends React.ComponentProps<typeof RsuiteDateRangePicker> {
  timeEnabled?: boolean;
  activeStyleEnabled?: boolean;
}

export const CalendarLocaleTexts = {
  sunday: 'datePicker.sunday',
  monday: 'datePicker.monday',
  tuesday: 'datePicker.tuesday',
  wednesday: 'datePicker.wednesday',
  thursday: 'datePicker.thursday',
  friday: 'datePicker.friday',
  saturday: 'datePicker.saturday',
  ok: 'button.apply',
  hours: 'datePicker.hours',
  minutes: 'datePicker.minutes',
  seconds: 'datePicker.seconds',
} as const;

export const DateRangePicker = ({ timeEnabled, activeStyleEnabled, ...props }: Props) => {
  const { t } = useTranslation();

  const ranges: RangeType[] = [
    {
      label: t('datePicker.today'),
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: t('datePicker.thisWeek'),
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
      label: t('datePicker.thisMonth'),
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
  ];

  const translatedLocaleTexts = Object.fromEntries(
    Object.entries(CalendarLocaleTexts).map(([key, i18nKey]) => [key, t(i18nKey)]),
  );

  return (
    <RsuiteDateRangePicker
      format={`MMM dd, yyyy${timeEnabled ? ', hh:mm aa' : ''}`}
      className={classNames(
        activeStyleEnabled && 'active-rs-picker',
        props.onClean && 'remove-rs-picker',
      )}
      defaultCalendarValue={[startOfDay(new Date()), endOfDay(new Date())]}
      ranges={ranges}
      locale={translatedLocaleTexts}
      onClean={e => {
        e.stopPropagation();
        props.onClean?.(e);
      }}
      preventOverflow
      {...props}
    />
  );
};
