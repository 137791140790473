function getUserManualBaseUrl(language: string) {
  return language === 'ko' ? 'https://ko.docs.superb-ai.com' : 'https://docs.superb-ai.com';
}

export function getUserManualUrl(language: string) {
  const SUITE_MANUAL = getUserManualBaseUrl(language);
  const USER_MANUAL = SUITE_MANUAL + '/docs';
  const DEVELOPER_MANUAL = SUITE_MANUAL + '/reference';

  return {
    SUITE_MANUAL,
    USER_MANUAL,
    DEVELOPER_MANUAL,

    // upload
    RAW_DATA_UPLOAD_AND_DOWNLOAD_MANUAL: USER_MANUAL + '/raw-data-upload-download',
    FILE_UPLOAD_MANUAL: USER_MANUAL + '/file-upload',
    URL_UPLOAD_MANUAL: USER_MANUAL + '/url-upload',
    CLOUD_UPLOAD_MANUAL: USER_MANUAL + '/cloud-upload',
    CLI_UPLOAD_MANUAL: USER_MANUAL + '/cli-upload',
    POINTCLOUD_MANIFEST_MANUAL: USER_MANUAL, // TODO update when article is added

    // auto label
    AUTO_LABEL_MANUAL: USER_MANUAL + '/overview',
    CAL_MANUAL: USER_MANUAL + '/custom-auto-label',

    // auto edit
    AUTO_EDIT_MANUAL: USER_MANUAL + '/auto-edit',

    // integration
    AMAZON_S3_INTEGRATIONS_MANUAL: USER_MANUAL + '/amazon-s3',
    GOOGLE_CLOUD_STORAGE_INTEGRATIONS_MANUAL: USER_MANUAL + '/google-cloud-storage',
    AZURE_BLOB_STORAGE_INTEGRATIONS_MANUAL: USER_MANUAL + '/azure-blob-storage',
    SLACK_INTEGRATIONS_MANUAL: USER_MANUAL + '/slack',

    // export
    EXPORT_FORMAT_MANUAL: USER_MANUAL + '/export-result-format',

    // authentication
    MFA_MANUAL: USER_MANUAL + '/two-factor-authentication-1',

    // consensus labeling
    CONSENSUS_MANUAL: USER_MANUAL, // TODO update when article is added

    // curate
    CURATE_DATA_UPLOAD_MANUAL: USER_MANUAL + '/data-upload',
    CURATE_QUERY_MANUAL: USER_MANUAL + '/query',
    CURATE_AUTO_CURATE_MANUAL: USER_MANUAL + '/auto-curate',
    CURATE_CREATE_SLICE_MANUAL: USER_MANUAL + '/slice',
    CURATE_CLOUD_UPLOAD: USER_MANUAL + '/curate-cloud-upload',
    CURATE_CLOUD_ANNOTATION_UPLOAD: USER_MANUAL + '/annotation-upload',

    CURATE_MODEL_DIAGNOSIS_INTRO: USER_MANUAL + '/what-is-model-diagnosis',
    CURATE_MODEL_DIAGNOSIS_MODEL_INTEGRATION: USER_MANUAL + '/getting-started-with-model-diagnosis',
    CURATE_MODEL_DIAGNOSIS_ANALYTICS_MANUAL: USER_MANUAL + '/model-diagnosis-analytics-view',
    CURATE_MODEL_DIAGNOSIS_GRID_MANUAL: USER_MANUAL + '/model-diagnosis-grid-view',

    SDK_INTRO_MANUAL: DEVELOPER_MANUAL + '/introduction-to-cli-and-sdk',
    CURATE_SDK_MANUAL: DEVELOPER_MANUAL + '/how-to-use-curate-sdk',
    CURATE_SDK_WORKFLOW_MANUAL: DEVELOPER_MANUAL + '/curate-sdk-workflow',

    // model
    MODEL_BASELINE_MANUAL: USER_MANUAL + '/baseline-model',
    MODEL_TRAINING_SET_MANUAL: USER_MANUAL + '/how-to-train-a-model#1-1-select-a-training-set',
    MODEL_ENDPOINTS_MANUAL: USER_MANUAL + '/endpoints',
    MODEL_SPLIT_TRAIN_VALIDATION_SET_MANUAL: USER_MANUAL + '/split-trainvalidation-set',
    MODEL_HOW_TO_TRAIN: USER_MANUAL + '/how-to-train-a-model',
  };
}
