import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Badge, Box, Card } from '@superb-ai/norwegian-forest';
import { map } from 'lodash';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import helper from './helper';

const useStyles = makeStyles(() => ({
  annotationText: {
    fontSize: '11px',
    color: '#303030',
    fontWeight: 500,
    lineHeight: 1,
    textAlign: 'center',
  },
}));

const firstLetterRe = /(\b[a-z](?!\s))/g;

const parsedAnnotationTypeName = (str: string, selectedDataType: string) => {
  if (WorkappUnion.isImageSequence(selectedDataType)) {
    if (str === 'Image') return 'Video';
  }

  return str.replace(firstLetterRe, x => x.toUpperCase());
};

// eslint-disable-next-line
const AnnotationTypeButton = (props: any): React.ReactElement => {
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const lidar = useFeatureFlag('lidar');

  const { annotationType }: { annotationType: any } = props;

  const handleClickAnnotationTypeButton = () => {
    helper.updateAnnotationTypes(newProjectInfo, annotationType.type, lidar);
  };

  return (
    <Card
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="96px"
      height="96px"
      selected={newProjectInfo.selectedAnnotationTypes[annotationType.type]}
      onClick={handleClickAnnotationTypeButton}
      style={{ marginTop: 8 }}
    >
      <Box height="40px" display="flex" alignItems="center">
        <Badge variant="text" color="primary">
          {annotationType.img}
        </Badge>
      </Box>
      <Box height="28px">
        {map(annotationType.label.split(' '), str => (
          <MUI.Typography className={classes.annotationText} key={str}>
            {parsedAnnotationTypeName(str, newProjectInfo.selectedDataType)}
          </MUI.Typography>
        ))}
      </Box>
    </Card>
  );
};

export default AnnotationTypeButton;
