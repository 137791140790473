/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { Circle, Group, Line, Text } from 'react-konva';

import { concat, map } from 'lodash';

import ImageContext from '../../../../../contexts/ImageContext';
import { useLabelInformationInfo } from '../../../../../contexts/LabelInformationContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { ColorUtils } from '../../../../../utils/SpbUtils';

const getPointColor = (pointState, v, color) => {
  if (pointState === 2) {
    if (v === 0) {
      return '#757575';
    }
    if (v === 1) {
      return color || '#e0e0e0';
    }
  } else {
    if (v === 0) {
      return '#757575';
    }
    if (v === 1) {
      return '#e0e0e0';
    }
    if (v === 2) {
      return color || '#757575';
    }
  }

  return '#757575';
};

const KeyPoint = props => {
  const { shape, hoverIndex, onClickShape } = props;

  const shapeRef = useRef(null);
  const labelInformationInfo = useLabelInformationInfo();
  const projectInfo = useProjectInfo();
  const imageInfo = React.useContext(ImageContext.Context);

  const { classes, cabinetTabState, isLabelInformationSwitchActive } = labelInformationInfo;

  const { rate } = imageInfo;
  const { info } = shape;

  const { keypointInterface } = projectInfo.project.labelInterface;

  // TODO (tsnoh): 임시
  const pointState = !keypointInterface[0].pointVisibility
    ? 2
    : keypointInterface[0].pointVisibility.length;

  const { edges, nodes } = keypointInterface[0];
  const fontSize = 12 / rate;
  const rectHeight = 14 / rate;

  useEffect(() => {
    if (!shapeRef.current) return;

    if (cabinetTabState === 'issue') {
      shapeRef.current.zIndex(1);
    } else if (hoverIndex === 1) {
      shapeRef.current.zIndex(shapeRef.current.parent.children.length - 1);
    }
  }, [hoverIndex, cabinetTabState]);

  // TODO (tsnoh): 임시코드. project에 등록된 class와 다른 class가 있을경우 방어
  if (!classes[shape.class]) return null;

  return (
    <Group ref={shapeRef} onClick={onClickShape}>
      {map(edges, (edge, index) => (
        <Line
          key={index}
          lineCap="round"
          lineJoin="round"
          points={concat(info[edge.from].x, info[edge.from].y, info[edge.to].x, info[edge.to].y)}
          stroke={edge.color}
          strokeWidth={2 / rate}
        />
      ))}
      {info.map((info, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Group key={index}>
          {isLabelInformationSwitchActive && (
            <Text
              x={info.x + 5}
              y={info.y - 5}
              wrap="none"
              ellipsis
              fill={ColorUtils.invertColor('#e6e6e6', true)}
              text={nodes[index].name}
              fontSize={fontSize}
              sceneFunc={(context, text) => {
                const { attrs } = text;

                if (!text.textArr[0]) return;
                context.beginPath();
                context.fillStyle = '#e6e6e6';
                context.fillRect(0, 0, text.textWidth + 10, rectHeight);
                context.stroke();
                context.closePath();

                context.beginPath();

                context.setAttr('textBaseline', 'ideographic');
                context.fillStyle = attrs.fill;
                context.font = `${attrs.fontSize}px Inter`;
                context.fillText(text.textArr[0].text, 5, rectHeight);
                context.closePath();
              }}
            />
          )}
          <Circle
            x={info.x}
            y={info.y}
            fill={getPointColor(pointState, info.v, nodes[index].color)}
            width={6 / rate}
            height={6 / rate}
          />
        </Group>
      ))}
    </Group>
  );
};

export default KeyPoint;
