export const excludedAnnotationMetadataKeys = [
  'id',
  'annotation_class',
  'annotation_type',
  '_label_id',
  '_last_synced_at',
  '_label_project_id',
];

export const syntheticImageMetadataKeys = [
  '_reference_image_id',
  '_gen_model_id',
  '_gen_mode',
  '_gen_strength',
];

export const excludedImageMetadataKeys = syntheticImageMetadataKeys;
