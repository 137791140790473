import React from 'react';

import DetailView from '../../../../components/elements/detailView';
import { DetailViewProvider } from '../../../../contexts/DetailViewContext';
import { AppRouteLayoutProps, AppRoutes } from '../../../../menu';

export default function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  return (
    <DetailViewProvider>
      <AppRoutes items={menuItem.children ?? []} />
      <DetailView />
    </DetailViewProvider>
  );
}
