import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import { ProjectData } from '../../../../types/projectTypes';
import ProjectSearchBar from '../../../elements/manageMembers/ProjectSearchBar';
import ProjectsTable from './ProjectsTable';

type Props = {
  selectedRole: string;
  setSelectedRole: Dispatch<SetStateAction<string>>;
  selectedProjects: ProjectData[];
  setSelectedProjects: Dispatch<SetStateAction<ProjectData[]>>;
  projects: ProjectData[];
};

const ProjectsTableContainer: React.FC<Props> = ({
  projects,
  selectedRole,
  setSelectedRole,
  selectedProjects,
  setSelectedProjects,
}) => {
  const [searchedProjects, setSearchedProjects] = useState<ProjectData[]>([]);

  useEffect(() => {
    if (!projects.length) return;
    setSearchedProjects([...projects]);
  }, [projects]);

  const handleChangeSearchKey = (value: string) => {
    const escapeRegExp = (string: string) => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapeRegExp(value), 'i');

    const result = projects.filter(project => project.name.match(regex));
    setSearchedProjects(result);
  };

  return (
    <>
      <Box mt={3}>
        <ProjectSearchBar
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          onChangeSearchKey={handleChangeSearchKey}
        />
      </Box>
      <Box mt={1}>
        <ProjectsTable
          selectable
          columns={['project', 'joinedAt']}
          projects={searchedProjects}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      </Box>
    </>
  );
};

export default ProjectsTableContainer;
