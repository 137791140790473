import {
  PartitionElementEvent,
  SeriesIdentifier,
  TooltipAction,
  XYChartElementEvent,
} from '@elastic/charts';
import copyToClipboard from 'copy-to-clipboard';
import * as d3Format from 'd3-format';
import { TFunction } from 'i18next';

import { PageSet } from '../../../../../types/routeTypes';
import { ChartDatum } from './types';

export const getAxisInfo = (axisLabel: string) => {
  return {
    label: axisLabel,
  };
};

export const formatCount = (n: number): string => d3Format.format(',')(n);

export const addQuotes = (value: string | number) => `"${value}"`;

/** Queries */
export const metadataEQQuery = (key: string, value: string): string =>
  `images.metadata.${addQuotes(key)} = ${addQuotes(value)}`;

/** Annotation Type Count Query */
export const annotationTypeGTQuery = (key: string, value: string | number): string =>
  `annotations.type.${key}.count > ${value}`;

/** Annotation Class Count Query */
export const annotationsGTEQuery = (key: string, value: string | number): string =>
  `annotations.${addQuotes(key)}.count >= ${value}`;

export const annotationsLTEQuery = (key: string, value: string | number): string =>
  `annotations.${addQuotes(key)}.count <= ${value}`;

/** Annotation Count Query */
export const annotationCountGTEQuery = (value: number): string => `annotations.count >= ${value}`;

export const annotationCountLTEQuery = (value: number): string => `annotations.count <= ${value}`;

export const annotationCountEQQuery = (value: number): string => `annotations.count = ${value}`;

// TODO: image classification query placeholders (verify)
export const classificationExistsQuery = (key: string): string => `images.classification.${key}`;

export const classificationEQQuery = (key: string, value: string): string =>
  `images.classification.${addQuotes(key)} = ${addQuotes(value)}`;

export const getXYChartKey = (events: Array<XYChartElementEvent>): string =>
  String(events[0][0]?.x);

export function _opAND(queries: string[] | string) {
  if (typeof queries === 'string') return '(' + queries + ')';
  return '(' + queries.join(' AND ') + ')';
}

// ignore query if it is empty
function getQueryArray(query: string, newQueries: string[]) {
  return query ? [query, ...newQueries] : newQueries;
}

export function updateQuery(
  newQueries: string[],
  currentQueryString: string,
  setQueryString: React.Dispatch<React.SetStateAction<string>>,
) {
  const queryArray = getQueryArray(currentQueryString, newQueries);
  const newQueryString = _opAND(queryArray);
  setQueryString(newQueryString);
}

export function appendQuery(newQueries: string[] | string, currentQueryString: string) {
  const queries = typeof newQueries === 'string' ? [newQueries] : newQueries;
  return _opAND(currentQueryString ? [currentQueryString, ...queries] : newQueries);
}

/** Legend */
export const getTextByDataScope = (
  t: TFunction,
  pageSet: PageSet,
  name: string,
  queryString?: string,
) => {
  const texts = {
    slice: {
      default: name,
      queried: t('curate.analytics.dataScope.queried.results'),
    },
    dataset: {
      default: name,
      queried: t('curate.analytics.dataScope.queried.results'),
    },
  };
  return texts[pageSet][queryString ? 'queried' : 'default'] || '';
};

export const getLegendTooltip = (t: TFunction, queryString: string, pageSet: PageSet) =>
  queryString ? t(`curate.analytics.dataScope.queried.${pageSet}`) : t(`curate.data.${pageSet}`);

/** Metadata Analytics */
export const metadataQueryString = (key: string, value: string) =>
  `images.metadata.${key} = ${value}`;

type SelectedItems = TooltipAction<ChartDatum, SeriesIdentifier>[];

export function getValueChartActions(t: TFunction, metadataKey: string): () => SelectedItems {
  if (typeof metadataKey === undefined || metadataKey === null) return () => [];
  return () => [
    {
      label: s =>
        s.length < 1
          ? t('curate.analytics.action.selectToCopyQuery')
          : t('curate.analytics.action.copyQueryToClipboard'),
      disabled: s => s.length < 1,
      onSelect: s =>
        s[0] &&
        s[0]?.datum?.key &&
        copyToClipboard(metadataQueryString(metadataKey, s[0].datum.key as string)),
    },
  ];
}

/** Annotation Analytics */
export const getParititionKey = (events: Array<PartitionElementEvent>): string =>
  String(events[0][0][0].groupByRollup);

export const ANNOTATIONS_COUNT_FIELD = 'count';

export function getTooltipActions(t: TFunction, key: string): () => SelectedItems {
  if (typeof key === undefined || key === null) return () => [];
  return () => [
    {
      label: s =>
        s.length < 1
          ? t('curate.analytics.action.selectToCopyQuery')
          : t('curate.analytics.action.copyQueryToClipboard'),
      disabled: s => s.length < 1,
      onSelect: s =>
        s[0] &&
        s[0]?.datum?.key &&
        copyToClipboard(annotationCountEQQuery(s[0].datum.key as number)),
    },
  ];
}

export const getCompareMode = (pageSet: PageSet, queryString?: string) =>
  pageSet === 'slice' || (pageSet === 'dataset' && queryString);
