import { set } from 'lodash';

import { fetchUrlAsBlobOnMRAP } from '../shares/fetchUrlAsBlobOnMRAP';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

interface GetAssetsParams extends Params {
  groupIn?: any[];
}
export interface GetAssetsResult {
  count: number;
  results: any[];
}
const getAssets: ApiCall<{ params: GetAssetsParams }, GetAssetsResult> = async args => {
  const { params, isGuest, urlInfo } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/assets/?${ServiceUtils.getParamString(params)}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getAssetId: ApiCall<{ params: GetAssetsParams }, any> = async args => {
  const { params, isGuest, urlInfo } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/assets_id/?${ServiceUtils.getParamString(params)}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getAsset: ApiCall<{ assetId: string }, any> = async args => {
  const { assetId, isGuest, urlInfo, cancelToken } = args;

  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/assets/${assetId}/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
    cancelToken,
  });

  const asset = res.data;

  if (asset.info.type === 'img-presigned-url') {
    try {
      // TODO: MRAP - need test
      const urlRes = await AuthService.apiCallAfterLogin({
        method: 'post',
        url: `/assets/${assetId}/read-signed-url/`,
        hasPublicApi: false,
        isGuest,
        urlInfo,
        cancelToken,
      });

      // for image-default
      // for before-mrap
      if (typeof urlRes.data?.url === 'string') {
        await set(asset, 'info', { ...asset.info, url: urlRes.data.url });
      } else if (urlRes.data.original) {
        // for mrap
        await set(asset, 'info', {
          ...asset.info,
          url: await fetchUrlAsBlobOnMRAP(urlRes.data.original.url, { useCache: true }),
        });
      }

      throw new Error('unhandled url!');
    } catch (err: any) {
      if (err.message === 'Not Found') {
        await set(asset, 'info', { ...asset.info, url: '/static/image/not_found.png' });
        return;
      }
    }
  }

  if (asset.info.type === 'videostream-presigned-url') {
    try {
      const urlRes = await AuthService.apiCallAfterLogin({
        method: 'post',
        url: `/assets/${assetId}/read-custom-signed-url/`,
        hasPublicApi: false,
        isGuest,
        urlInfo,
        cancelToken,
      });
      const { baseUrl, query } = urlRes.data;

      await set(asset, 'info', {
        ...asset.info,
        url: `${baseUrl}video_stream.m3u8`,
        urlQuery: query,
      });
    } catch (err: any) {
      if (err.message === 'Not Found') {
        await set(asset, 'info', { ...asset.info, url: '/static/image/not_found.png' });
      }
    }
  }
  if (asset.info.type === 'video-presigned-url') {
    try {
      if (asset.info.assetSaveMethod === 'link') {
        const urlRes = await AuthService.apiCallAfterLogin({
          method: 'post',
          url: `/assets/${assetId}/read-signed-url/`,
          hasPublicApi: false,
          isGuest,
          urlInfo,
          cancelToken,
        });

        const { url } = urlRes.data;

        for (let i = 0; i < asset.info.fileInfos.length; i++) {
          const fileInfo = asset.info.fileInfos[i];
          fileInfo.url = url[i];
        }
      } else {
        const customSignedUrl = await AuthService.apiCallAfterLogin({
          method: 'post',
          url: `/assets/${assetId}/read-custom-signed-url/`,
          hasPublicApi: false,
          isGuest,
          urlInfo,
          cancelToken,
        });

        const { baseUrl, query } = customSignedUrl.data;

        for (let i = 0; i < asset.info.fileInfos.length; i++) {
          const fileInfo = asset.info.fileInfos[i];

          const getFileName = () => {
            const index = `00000000${i + 1}`.slice(-8);
            const extention = fileInfo.fileName.split('.').slice(-1)[0].toLowerCase();
            return `image_${index}.${extention}`;
          };
          fileInfo.url = `${baseUrl}${getFileName()}?${query}`;
        }
      }
    } catch (err: any) {
      if (err.message === 'Not Found') {
        for (let i = 0; i < asset.info.fileInfos.length; i++) {
          const fileInfo = asset.info.fileInfos[i];

          fileInfo.url = '/static/image/not_found.png';
        }
      }
    }
  }

  if (asset.info.type === 'pointclouds-presigned-url') {
    try {
      const customSignedUrl = await AuthService.apiCallAfterLogin({
        method: 'post',
        url: `/assets/${assetId}/read-custom-signed-url/`,
        hasPublicApi: false,
        isGuest,
        urlInfo,
        cancelToken,
      });

      const { baseUrl, query } = customSignedUrl.data;

      const firstFrame = asset.info.frameInfos[0];

      for (let i = 0; i < firstFrame.imageInfos.length; i++) {
        const fileInfo = firstFrame.imageInfos[i];

        const getFileName = () => {
          const index = `00000000${i + 1}`.slice(-8);
          const extension = fileInfo.imageFileName.split('.').slice(-1)[0].toLowerCase();
          return `frames/frame_00000001/image_00000001_${index}.${extension}`;
        };
        fileInfo.url = `${baseUrl}${getFileName()}?${query}`;
      }
    } catch (err: any) {
      // ignore error
    }
  }

  return res.data;
};

const getDatasets: ApiCall<{ params: Params }, any> = async args => {
  const { params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/assets/groups/?${ServiceUtils.getParamString(params)}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const getProjectDatasets: ApiCall<{ params?: Params }, any> = async args => {
  const { params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${urlInfo.projectId}/assets/groups/?${ServiceUtils.getParamString(params)}`,
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const updateDatasetName: ApiCall<{ curName: string; newName: string }, any> = async args => {
  const { curName, newName, isGuest, urlInfo } = args;
  const encodedCurName = encodeURIComponent(curName);

  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/commands/assets_update_group/',
    data: {
      params: {
        filter: `group_in[]=${encodedCurName}`,
        group: newName,
      },
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const assignDataToProject: ApiCall<
  { projectId: string; params: Record<string, any> },
  any
> = async args => {
  const { projectId, isGuest, urlInfo, params } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/assets/labels/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

const deleteAsset: ApiCall<{ assetId: string }, any> = async args => {
  const { assetId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/assets/${assetId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res.data;
};

export default {
  getAssets,
  getAssetId,
  getAsset,
  getDatasets,
  getProjectDatasets,
  updateDatasetName,
  assignDataToProject,
  deleteAsset,
};
