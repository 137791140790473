import React, { useEffect, useState } from 'react';

import { ProjectData } from '../types/projectTypes';
import { WorkApp } from '../union/WorkappUnion';
import LabelDetailViewUtils from '../utils/LabelDetailViewUtils';
import { useProjectInfo } from './ProjectContext';
import { StateGetterSetter } from './types';

// prettier-ignore
type LabelInformationContextProps =
  StateGetterSetter<['groups', 'setGroups'], any> &
  StateGetterSetter<['classes', 'setClasses'], any> &
  StateGetterSetter<['categorization', 'setCategorization'], any> &
  StateGetterSetter<['annotations', 'setAnnotations'], any> &
  StateGetterSetter<['annotationTypes', 'setAnnotationTypes'], any> &
  StateGetterSetter<['classesCount', 'setClassesCount'], any> &
  StateGetterSetter<['shapes', 'setShapes'], any[]> &
  StateGetterSetter<['labelAnnotationInfos', 'setLabelAnnotationInfos'], any> &
  StateGetterSetter<['clickedAnnotationInfo', 'setClickedAnnotationInfo'], any> &
  StateGetterSetter<['hoveredAnnotation', 'setHoveredAnnotation'], any> &
  StateGetterSetter<['cabinetTabState', 'setCabinetTabState'], any> &
  StateGetterSetter<['selectOrUnselectLabel', 'setSelectOrUnselectLabel'], any> &
  StateGetterSetter<['isLabelInformationSwitchActive', 'setIsLabelInformationSwitchActive'], any> &
  {
    init(labelInterface: ProjectData['labelInterface'], workapp: WorkApp): void;
    updateLabelInformation: (label: any, workapp: WorkApp) => void;
  };

export const LabelInformationContext = React.createContext({} as LabelInformationContextProps);

export const LabelInformationProvider: React.FC = ({ children }) => {
  const projectInfo = useProjectInfo();
  // init by label-interface
  const [groups, setGroups] = useState({});
  const [classes, setClasses] = useState({});
  const [categorization, setCategorization] = useState({});

  // init by label
  const [annotations, setAnnotations] = useState({});
  const [annotationTypes, setAnnotationTypes] = useState({});
  const [classesCount, setClassesCount] = useState({});
  const [shapes, setShapes] = useState<any[]>([]);

  const [labelAnnotationInfos, setLabelAnnotationInfos] = useState({});

  // UI
  const [clickedAnnotationInfo, setClickedAnnotationInfo] = useState(null); // { compareOption: [labelCategory, labelId], annotation: annotationId }
  const [hoveredAnnotation, setHoveredAnnotation] = useState(null);
  const [cabinetTabState, setCabinetTabState] = useState('information');
  const [selectOrUnselectLabel, setSelectOrUnselectLabel] = useState('Unselect All');
  const [isLabelInformationSwitchActive, setIsLabelInformationSwitchActive] = useState(true);

  const init = (labelInterface: ProjectData['labelInterface'], workapp: WorkApp) => {
    if (workapp === 'video-siesta' || workapp === 'image-siesta') {
      const { groups, classes, categorization } =
        LabelDetailViewUtils.extractNewLabelInterface(labelInterface);
      setGroups(groups);
      setClasses(classes);
      setCategorization(categorization);
      return;
    }

    const { groups, classes, categorization } =
      LabelDetailViewUtils.extractInfoOnLabelInterface(labelInterface);
    setGroups(groups);
    setClasses(classes);
    setCategorization(categorization);
  };

  useEffect(() => {
    if (!projectInfo.project) return;
    // video-siesta
    init(projectInfo.project?.labelInterface, projectInfo.project.workapp);

    // eslint-disable-next-line
  }, [projectInfo.project?.id]);

  const updateLabelInformation = (label: any, workapp: any) => {
    if (workapp === 'video-siesta') {
      return;
    }
    // <--Legacy
    const { annotations, annotationTypes, shapes, classesCount } =
      LabelDetailViewUtils.extractInfoOnLabel(label, workapp);

    setAnnotations(annotations);
    setAnnotationTypes(annotationTypes);
    setShapes(shapes);
    setClassesCount(classesCount);
    // -->Legacy

    const { currentLabelInfo, autoLabelInfo } = LabelDetailViewUtils.extractLabelInfos(
      label,
      workapp,
    );
    setLabelAnnotationInfos({
      current: currentLabelInfo,
      autoLabel: autoLabelInfo,
    });
  };

  return (
    <LabelInformationContext.Provider
      value={{
        groups,
        setGroups,
        classes,
        setClasses,
        categorization,
        setCategorization,

        annotations,
        setAnnotations,
        annotationTypes,
        setAnnotationTypes,
        classesCount,
        setClassesCount,
        shapes,
        setShapes,
        labelAnnotationInfos,
        setLabelAnnotationInfos,

        // UI
        clickedAnnotationInfo,
        setClickedAnnotationInfo,
        hoveredAnnotation,
        setHoveredAnnotation,
        cabinetTabState,
        setCabinetTabState,
        selectOrUnselectLabel,
        setSelectOrUnselectLabel,
        isLabelInformationSwitchActive,
        setIsLabelInformationSwitchActive,

        init,
        updateLabelInformation,
      }}
    >
      {children}
    </LabelInformationContext.Provider>
  );
};

export const useLabelInformationInfo = (): LabelInformationContextProps => {
  return React.useContext(LabelInformationContext);
};
