import { NamedColor } from '@superb-ai/ui';

import { ONE_CREDIT } from '../../../../queries/meteringLogic';
import { SerializedMeteringSelector } from '../../../../queries/useMeteringQuery';

export const MeteringColors: Record<
  Exclude<SerializedMeteringSelector, 'model:endpoint'>,
  NamedColor
> = {
  'common:user-seat': 'gray',
  'label:data-volume': 'primary',
  'curate:data-volume': 'violet',
  'common:ai-credit': 'mint',
};

export const MeteringSelectorToI18n: Record<
  Exclude<SerializedMeteringSelector, 'model:endpoint'>,
  string
> = {
  'common:ai-credit': 'metering.autoLabel',
  'common:user-seat': 'metering.userSeats',
  'label:data-volume': 'metering.dataStorage',
  'curate:data-volume': 'metering.curateDataStorage',
};

const MeteringDisplayUnits: Record<
  Exclude<SerializedMeteringSelector, 'model:endpoint'>,
  number
> = {
  'common:ai-credit': ONE_CREDIT,
  'common:user-seat': 1,
  'label:data-volume': 1,
  'curate:data-volume': 1,
};

/**
 * Converting Metering unit to display number.
 * (Some units have a divisor, some do not.)
 */
export function convertMeteringUnit(
  value: number | undefined,
  selector: keyof typeof MeteringDisplayUnits,
) {
  if (!value || value < 0) return 0;
  return Math.floor(value / MeteringDisplayUnits[selector]);
}
