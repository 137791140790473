import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu/MenuItem';
import Layout from './Layout';
import { path } from './path';

export const IntegrationDetailMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountIntegrationDetail,
  path,
  title: 'Integration Detail',
  component: Layout,
  icon: 'link2',
});
