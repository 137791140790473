import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { concat, find, map, without } from 'lodash';
import { useSnackbar } from 'notistack';

import { FILTER_TEXT_ALREADY_SELECTED } from '../../../../../consts/SnackbarMessage';
import { FilterProps } from './types';

const useStyles = makeStyles(theme => ({
  textField: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '13px',
    minWidth: 200,
    minHeight: '32px',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '2px',
    color: '#333333',
    border: 'solid 1px #BCBCBC',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    padding: '2px 10px',
    '& .MuiChip-root': {
      margin: '2px',
      backgroundColor: 'hsl(0,0%,90%)',
      borderRadius: '2px',
    },
    '& .MuiChip-label': {
      fontSize: '11px',
      color: '#333333',
      paddingLeft: '6px',
      paddingRight: '14px',
    },
    '& .MuiChip-deleteIcon': {
      color: '#333333',
      fontSize: '11px',
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'auto',
        color: '#000',
      },
    },
    '& .MuiInputBase-input': {
      marginLeft: '4px',
      padding: '6px 0 2px',
      '&::placeholder': {
        color: '#333333',
      },
    },
  },
  currentRow: {
    minHeight: '32px',
    borderColor: '#ff625a',
    boxShadow: 'none',
    color: '#ff625a',
    '& .MuiSvgIcon-root': {
      color: '#ff625a',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

type Props = Omit<FilterProps<any>, 'onChangeCurrentRow'>;

const FilterTextField: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { objKey, index, onValueChange, value, isCurrentRow, placeholder } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    const inputValue = target.value;
    if (e.key === 'Enter') {
      const hasInputValue = !!find(value, ['value', inputValue]);
      target.value = '';
      if (inputValue === '') return;
      if (hasInputValue) {
        enqueueSnackbar(FILTER_TEXT_ALREADY_SELECTED({ t, inputValue }), {
          variant: 'warning',
        });
        return;
      }
      onValueChange(index, objKey, concat(value, [{ value: inputValue, label: inputValue }]));
    }
  };

  const handleClickDelete = (inputValue: any) => () => {
    onValueChange(index, objKey, without(value, inputValue));
  };

  return (
    <MUI.Box className={`${classes.textField} ${isCurrentRow ? classes.currentRow : ''}`}>
      {map(value, chip => (
        <MUI.Chip
          key={chip.value}
          label={chip.label}
          deleteIcon={<MUI.Icon>clear</MUI.Icon>}
          onDelete={handleClickDelete(chip)}
        />
      ))}
      <MUI.TextField
        variant="standard"
        disabled={!isCurrentRow}
        placeholder={isCurrentRow ? placeholder : ''}
        onKeyDown={handleKeyDown}
      />
    </MUI.Box>
  );
};

export default FilterTextField;
