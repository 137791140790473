import React from 'react';

import * as d3 from 'd3';
import { DefaultArcObject } from 'd3';
import { sum } from 'lodash';

import { ClickedEventProps } from '../../chartContainers/types';
import { SvgConfigDonutObject } from '../../config/types';
import { getOpacity } from '../../tools/helper';
import { JsonObj } from '../../userStats/types';
import DonutCenterTextTemplate from './DonutCenterTextTemplate';
import EmptyDonutCenterText from './EmptyDonutCenterText';
import { GetDonutColorFn } from './types';

const svgStyle = { overflow: 'visible' };

type DonutChartProps = {
  id: string;
  x: number;
  yValues: number[];
  totalCounts: number;
  getCenterText: (params: {
    hoveredIndex: number;
    count: number;
    total: number;
    datum?:
      | JsonObj
      // | {
      //     name: string;
      //     id: string;
      //     count: number;
      //   }
      | undefined;
  }) => JsonObj[];
  getColor: GetDonutColorFn;
  hoveredIndex: number;
  highlightSlice?: (i: number, status?: 'in' | 'out', slice?: JsonObj) => void;
  handleOnClick?: ClickedEventProps;
  chartName: string;
  data?: JsonObj[];
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigDonutObject;
  defaultCenterText?: string;
};

const DonutChart: React.FC<DonutChartProps> = props => {
  const {
    id,
    x,
    yValues,
    totalCounts,
    getColor,
    getCenterText,
    highlightSlice,
    handleOnClick,
    hoveredIndex,
    chartName,
    data,
    xKeyToDisplayName,
    svgInfo,
    defaultCenterText,
  } = props;

  const { radius, innerRadius, arcThickness } = svgInfo;
  const i = hoveredIndex;
  const svgGroupName = `donut-outer-group-${chartName}`;

  const gTransform = `translate(${svgInfo.svgWidth / 4},${svgInfo.svgHeight / 2 - 20})`;
  const arc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(radius - arcThickness);

  const handleMouseEnter = (index: number, slice: JsonObj) => {
    highlightSlice?.(index, 'in', slice);
  };

  const handleMouseLeave = (index: number, slice: JsonObj) => {
    highlightSlice?.(index, 'out', slice);
  };

  let pieData = d3
    .pie()
    .sort(null)
    // @ts-ignore d3 does not recognize d as Datum
    .value(d => d.count)(data || [{}]);
  if (sum(yValues) === 0) {
    // if all data values are 0, use place holder [1] to generate arc
    pieData = d3.pie().sort(null)([1]);
  }

  const getSlice = (data: JsonObj[], hoveredIndex: number) => {
    return data.map((slice, i) => {
      const sliceColor = getColor(slice?.data) as string;
      const key = (arc(slice as DefaultArcObject) as string) + i;
      const sliceClassName = `slice-${slice.value}-${i}`;
      const darken = (color: string): string => d3.rgb(color).darker(0.3).toString();
      return (
        <path
          key={key}
          className={sliceClassName}
          d={arc(slice as DefaultArcObject) as 'string'}
          fill={hoveredIndex === i ? darken(sliceColor) : sliceColor}
          stroke={hoveredIndex === i ? darken(sliceColor) : sliceColor}
          opacity={getOpacity(i, hoveredIndex)}
          pointerEvents={totalCounts === 0 ? 'none' : 'visibleFill'}
          onMouseOver={() => handleMouseEnter(i, slice)}
          onFocus={() => handleMouseEnter(i, slice)}
          onMouseOut={() => handleMouseLeave(i, slice)}
          onBlur={() => handleMouseLeave(i, slice)}
          onClick={() => {
            handleOnClick && handleOnClick(data[i]?.data);
          }}
          style={{ cursor: handleOnClick ? 'pointer' : 'default' }}
        />
      );
    });
  };
  return (
    <div id={id}>
      <svg
        preserveAspectRatio={'none'}
        x={x}
        id={`${id}-svg`}
        width={svgInfo.width}
        height={svgInfo.height}
        style={{
          ...svgStyle,
          width: svgInfo.width > 0 ? svgInfo.width : 0,
          height: svgInfo.height,
        }}
      >
        <g transform={gTransform} className={svgGroupName}>
          {pieData && getSlice(pieData, hoveredIndex)}
          {!defaultCenterText &&
            data &&
            DonutCenterTextTemplate(
              getCenterText({
                hoveredIndex: hoveredIndex,
                count: yValues[i],
                datum: i < 0 ? undefined : data[i],
                total: totalCounts,
              }),
              radius,
              chartName,
              xKeyToDisplayName,
            )}
          {defaultCenterText && EmptyDonutCenterText(defaultCenterText, radius, chartName)}
        </g>
      </svg>
    </div>
  );
};

export default DonutChart;
