import { PurposeType } from '../../services/types';

export function getSnippet(
  endpointUrl: string,
  language: 'python' | 'js' | 'curl',
  teamName: string,
  purpose: PurposeType,
) {
  if (language === 'python') {
    if (purpose === 'recognition') {
      return `import requests
from requests.auth import HTTPBasicAuth

image = open(IMAGE_FILE_PATH, "rb").read()

response = requests.post(
    url="${endpointUrl}",
    auth=HTTPBasicAuth("${teamName}", ACCESS_KEY),
    headers={"Content-Type": "image/jpeg"},
    data=image,
)
print(response.json())
  `;
    } else {
      return `from base64 import b64encode
import json

import requests
from requests.auth import HTTPBasicAuth

image_bytes = open(IMAGE_FILE_PATH, "rb").read()
image_b64encoded = b64encode(image_bytes).decode("UTF-8")
objects = [
    {
        "class": "person",
        "box": [
            100, 100, 200, 200 # x1, y1, x2, y2
        ]
    },
    {
        "class": "car",
        "box": [
            300, 100, 400, 200
        ]
    },
]

response = requests.post(
    url={ENDPOINT_URL},
    auth=HTTPBasicAuth("snoopy", ACCESS_KEY),
    headers={"Content-Type": "application/json"},
    data=json.dumps({
        "image": image_b64encoded,
        "objects": objects,
    }),
)
print(response.json())`;
    }
  } else if (language === 'js') {
    return `const axios = require("axios");
const fs = require("fs");

const image = fs.readFileSync(IMAGE_FILE_PATH);

axios.post("${endpointUrl}", image, {
    headers: { "Content-Type": "image/jpeg" },
    auth: {
      username: "${teamName}",
      password: ACCESS_KEY,
    },
})
.then(function(response) {
    console.log(response.data);
})
.catch(function(error) {
    console.log(error.message);
});
    `;
  } else {
    return `$ curl -X POST "${endpointUrl}" -u "${teamName}":$ACCESS_KEY -H "content-type: image/jpeg" --data-binary @$IMAGE_FILE_PATH`;
  }
}
