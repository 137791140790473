import { TFunction } from 'react-i18next';

export const commandFallbackErrors = (t: TFunction): Record<string, string> => {
  return {
    NOT_FOUND: t('consts.errorMessages.commandFallbackError.notFound'),
    UNKNOWN: t('consts.errorMessages.commandFallbackError.unknown'),
  };
};

export const commandErrors = (t: TFunction): Record<string, Record<string, string>> => {
  return {
    ASSETS_ASSIGN: {
      DUPLICATE: t('consts.errorMessages.assetAssign.duplicate'),
      WRONG_TYPE: t('consts.errorMessages.assetAssign.wrongType'),
    },
  };
};
