import React from 'react';

import * as MUI from '@mui/material';
import { Icon } from '@superb-ai/norwegian-forest';

import ImageFilter from '../../../../../consts/ImageFilter';
import ImageContext from '../../../../../contexts/ImageContext';
import TunerSlider from './TunerSlider';

interface Props {
  menuClasses: Record<string, string>;
}

const FloatingMenu: React.FC<Props> = ({ menuClasses }) => {
  const imageInfo = React.useContext(ImageContext.Context);

  const { controlBrightness, controlContrast } = imageInfo;

  const handleValueChange = (target: 'brightness' | 'contrast') => (value: number) => {
    if (target === 'brightness') {
      controlBrightness('SET', value / 100);
    }
    if (target === 'contrast') {
      controlContrast('SET', value);
    }
  };

  return (
    <>
      <MUI.Box className={menuClasses.item}>
        <TunerSlider
          min={ImageFilter.MIN_BRIGHTNESS * 100}
          max={ImageFilter.MAX_BRIGHTNESS * 100}
          step={ImageFilter.OFFSET_BRIGHTNESS * 100}
          defaultValue={ImageFilter.INIT_BRIGHTNESS * 100}
          currentValue={imageInfo.brightness * 100}
          setCurrentValue={handleValueChange('brightness')}
          sliderLabel="Brightness"
          Icon={<Icon name="sun" size="12px" />}
        />
      </MUI.Box>
      <MUI.Box className={menuClasses.item}>
        <TunerSlider
          min={ImageFilter.MIN_CONTRAST}
          max={ImageFilter.MAX_CONTRAST}
          step={ImageFilter.OFFSET_CONTRAST}
          defaultValue={ImageFilter.INIT_CONTRAST}
          currentValue={imageInfo.contrast}
          setCurrentValue={handleValueChange('contrast')}
          sliderLabel="Contrast"
          Icon={<Icon name="pieChart50" size="12px" />}
        />
      </MUI.Box>
    </>
  );
};

export default FloatingMenu;
