import React, { ComponentProps, useRef } from 'react';

import { Tooltip, Typography } from '@superb-ai/ui';

export default function OverflowTooltip({
  text,
  typographyProps,
  tooltipProps,
}: {
  text: string;
  typographyProps?: ComponentProps<typeof Typography>;
  tooltipProps?: Partial<ComponentProps<typeof Tooltip>>;
}) {
  const textRef = useRef<HTMLDivElement>(null);

  function isOverflowed(element: HTMLDivElement | null) {
    if (!element) return false;
    return element.scrollWidth > element.clientWidth;
  }

  return (
    <Tooltip
      content={isOverflowed(textRef.current) ? text : undefined}
      hideOnEmptyContent
      {...tooltipProps}
    >
      <Typography
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        ref={textRef}
        {...typographyProps}
      >
        {text}
      </Typography>
    </Tooltip>
  );
}
