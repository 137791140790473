import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChevronLeft,
  ChevronRight,
  Copy,
  GenAi,
  GenerateImage,
  InfoCircleOutline,
  LoadingSpinnerSmall,
  Sync,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import axios from 'axios';
import { CamelizedProperty } from 'humps';
import dynamic from 'next/dynamic';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { PixiDetailAnnotation } from '../../../../components/pages/aiAdvancedFeatures/Shapes';
import { JSON_COPIED } from '../../../../consts/SnackbarMessage';
import FileUtils from '../../../../utils/FileUtils';
import { CurateAnnotation } from '../../../Curate/services/DatasetService';
import {
  AnnotationTypeCoordinate,
  Box as AnnotatinoBox,
} from '../../../Curate/types/annotationTypes';
import { BorderBox } from '../../components/components';
import { AnnotationClassesCollapse } from '../../gen-ai/SamplePredictionDialog';
import { FormattedAnnotation } from '../../recognition-ai/detail/SamplePredictionCabinet';
import { Endpoint, EndpointStatus } from '../../services/types';
import { colorPalette } from '../../utils/colorUtils';
import { useGenerationPredictionTestContext } from './contexts/GenerationPredictionTestContext';
import { SelectTestDataDialog } from './SelectTestDataDialog';

const PixiDetailImage = dynamic({
  loader: () => import('../../recognition-ai/detail/PixiDetailImage').then(x => x.PixiDetailImage),
  ssr: false,
});

function isBoxAnnotation(
  anno: CamelizedProperty<CurateAnnotation>,
): anno is CamelizedProperty<CurateAnnotation> & {
  annotation_type: AnnotationTypeCoordinate<AnnotatinoBox>;
  annotationValue: { x: number; y: number; width: number; height: number };
} {
  return anno.annotationType === 'box';
}

export const GenerationPredictionTest = ({ endpointDetail }: { endpointDetail: Endpoint }) => {
  const selectTestDataDialogState = useDialogState();
  const {
    datasetImage,
    setDatasetImage,
    outputIndex,
    datasetImageQueryData,
    inferenceMutate,
    inferenceData,
  } = useGenerationPredictionTestContext();

  const datasetImageObjects = datasetImage?.annotations
    .filter(anno => anno.annotationType === 'box')
    .map(anno => {
      if (isBoxAnnotation(anno))
        return {
          id: anno.id,
          class: anno.annotationClass,
          box: [
            anno.annotationValue.x,
            anno.annotationValue.y,
            anno.annotationValue.x + anno.annotationValue.width,
            anno.annotationValue.y + anno.annotationValue.height,
          ],
        };
    });
  const annotationColorMap: Record<string, string> = Object.fromEntries(
    datasetImageObjects
      ? datasetImageObjects.map((obj, idx) => [
          obj.class,
          colorPalette[idx % colorPalette.length].hex,
        ])
      : [],
  );

  const getImageAsBase64 = async (imageUrl: string): Promise<string> => {
    const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
    return Buffer.from(response.data, 'binary').toString('base64');
  };
  const handleClickMutate = async () => {
    const imageBase64 = await getImageAsBase64(datasetImageQueryData.image_url);
    inferenceMutate({ id: endpointDetail.id, imageBase64, objects: datasetImageObjects });
  };

  const base64ToImageUrl = (base64String: string) => {
    if (!base64String) return;
    const base64Data = base64String.split(',')[1];
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'image/png' });
    return URL.createObjectURL(blob);
  };

  const inputImageAnnotations = datasetImageObjects?.map(annotation => {
    return {
      type: 'box',
      coordinate: {
        x: annotation.box[0],
        y: annotation.box[1],
        width: annotation.box[2] - annotation.box[0],
        height: annotation.box[3] - annotation.box[1],
      },
      id: annotation.id,
      className: annotation.class,
      color: annotationColorMap[annotation.class],
    };
  }) as PixiDetailAnnotation[];

  const inputPredictionResults = datasetImageObjects
    ? datasetImageObjects.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
        return {
          ...acc,
          [annotation.class]: {
            type: 'box',
            id: String(annotation.id),
            name: annotation.class,
            count: (acc[annotation.class]?.count || 0) + 1,
            annotations: [
              ...(acc[annotation.class]?.annotations.length > 0
                ? acc[annotation.class]?.annotations
                : []),
              {
                id: String(annotation.id),
                annotation_class: annotation.class,
                annotation_type: 'box',
              },
            ],
          },
        };
      }, {})
    : {};

  const outputImageAnnotations = inferenceData?.outputs.map(output => {
    return {
      output: output.objects.map((object, idx) => ({
        type: 'box',
        coordinate: {
          x: object.box[0],
          y: object.box[1],
          width: object.box[2] - object.box[0],
          height: object.box[3] - object.box[1],
        },
        id: annotationIdResolver(object.class, idx, object.box),
        className: object.class,
        color: annotationColorMap[object.class],
      })) as PixiDetailAnnotation[],
    };
  })[outputIndex].output;

  const outputPredictionResults = inferenceData
    ? inferenceData.outputs
        .map(output => {
          return {
            output: output.objects.map((object, idx) => ({
              ...object,
              id: annotationIdResolver(object.class, idx, object.box),
            })),
          };
        })
        [outputIndex].output.reduce<Record<string, FormattedAnnotation>>((acc, annotation) => {
          return {
            ...acc,
            [annotation.class]: {
              type: 'box',
              id: String(annotation.id),
              name: annotation.class,
              count: (acc[annotation.class]?.count || 0) + 1,
              annotations: [
                ...(acc[annotation.class]?.annotations.length > 0
                  ? acc[annotation.class]?.annotations
                  : []),
                {
                  id: String(annotation.id),
                  annotation_class: annotation.class,
                  annotation_type: 'box',
                },
              ],
            },
          };
        }, {})
    : {};

  return (
    <BorderBox>
      <Box display="grid" borderColor={'gray-150'} style={{ gridTemplateColumns: '50% 50%' }}>
        <InputImage
          imageUrl={datasetImageQueryData?.image_url}
          selectTestDataDialogState={selectTestDataDialogState}
          inputImageAnnotations={inputImageAnnotations}
          annotationColorMap={annotationColorMap}
          inputJsonResult={JSON.stringify(datasetImageObjects)}
          predictionResults={inputPredictionResults}
          imageName={datasetImage?.key}
          endpointStatus={endpointDetail.status}
        />
        <OutputImage
          imageUrl={base64ToImageUrl(inferenceData?.outputs[outputIndex].image)}
          outputImageAnnotations={outputImageAnnotations}
          annotationColorMap={annotationColorMap}
          inputJsonResult={JSON.stringify(outputPredictionResults)}
          predictionResults={outputPredictionResults}
          status={endpointDetail.status}
          isEnabledTest={Boolean(datasetImageQueryData && endpointDetail.status === 'running')}
          handleClickMutate={handleClickMutate}
        />
      </Box>
      {selectTestDataDialogState.visible && (
        <SelectTestDataDialog state={selectTestDataDialogState} setDatasetImage={setDatasetImage} />
      )}
    </BorderBox>
  );
};

const InputImage = ({
  imageUrl,
  selectTestDataDialogState,
  inputImageAnnotations,
  annotationColorMap,
  inputJsonResult,
  predictionResults,
  imageName,
  endpointStatus,
}: {
  imageUrl: string | null;
  selectTestDataDialogState: ReturnType<typeof useDialogState>;
  inputImageAnnotations: PixiDetailAnnotation[];
  annotationColorMap: Record<string, string>;
  inputJsonResult: string;
  predictionResults: Record<string, FormattedAnnotation>;
  imageName: string | null;
  endpointStatus: EndpointStatus;
}) => {
  const { t } = useTranslation();
  const {
    filteredPredictionAnnotationsIds,
    setFilteredPredictionAnnotationsIds,
    annotationSelectedMap,
    setAnnotationSelectedMap,
    annotationHoveredMap,
    setAnnotationHoveredMap,
    clickedAnnotationId,
    setClickedAnnotationId,
    hoveredAnnotatinoId,
    setHoveredAnnotationId,
  } = useGenerationPredictionTestContext();

  const filteredInputImageAnnotations = inputImageAnnotations?.filter(
    anno => !filteredPredictionAnnotationsIds.includes(String(anno.id)),
  );

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
    setClickedAnnotationId(null);
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(String(annotation.id));
        return {
          [annotation.id]: true,
        };
      }
    });
  }, []);

  const handleHoverShape = useCallback(
    (annotation: PixiDetailAnnotation) => {
      if (Object.keys(annotationHoveredMap)[0] === annotation.id) return;
      setAnnotationHoveredMap(prevState => {
        if (prevState[annotation.id]) {
          setHoveredAnnotationId('');
          return {};
        } else {
          setHoveredAnnotationId(String(annotation.id));
          return {
            [annotation.id]: true,
          };
        }
      });
    },
    [annotationHoveredMap],
  );

  const handleLeaveShape = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotationId(null);
  }, []);

  const handleClickList = (id: string) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };
  const handleHoverList = (id: string) => {
    if (Object.keys(annotationHoveredMap)[0] === id) return;
    setAnnotationHoveredMap(prevState => {
      if (prevState[id]) {
        setHoveredAnnotationId('');
        return {};
      } else {
        setHoveredAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };

  const handleLeaveList = useCallback(() => {
    setAnnotationHoveredMap({});
    setHoveredAnnotationId(null);
  }, []);

  return (
    <Box br="1px solid" borderColor="gray-150" py={2} px={2.5}>
      <Row justifyContent="space-between">
        <Row gap={0.5}>
          <Typography variant="l-strong">{t('model.generativeAi.inputImage')}</Typography>
          {imageName && <Typography variant="m-regular">({imageName})</Typography>}
        </Row>
        {imageUrl && (
          <Button
            variant="strong-fill"
            color="secondary"
            size="s"
            onClick={() => selectTestDataDialogState.show()}
          >
            <Icon icon={Sync} />
            {t('model.endpoints.changeImage')}
          </Button>
        )}
      </Row>
      <Box
        backgroundColor="gray-100"
        mb={1}
        mt={1.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: 282 }}
      >
        {endpointStatus === 'running' ? (
          imageUrl ? (
            <PixiDetailImage
              imgUrl={imageUrl}
              samplePredictionAnnotations={filteredInputImageAnnotations}
              annotationColorMap={annotationColorMap}
              annotationSelectedMap={annotationSelectedMap}
              annotationHoveredMap={annotationHoveredMap}
              onSelectImage={handleSelectImage}
              onSelectShape={handleSelectShape}
              onHoverShape={handleHoverShape}
              onLeaveShape={handleLeaveShape}
            />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                border="2px solid"
                borderColor="secondary-300"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={1.5}
                backgroundColor={'secondary-100'}
                style={{ width: 80, height: 80, borderRadius: 40 }}
              >
                <Icon icon={GenAi} color="secondary" size={40} />
              </Box>
              <Box textAlign="center" mb={1.5} style={{ width: 241 }}>
                <Typography variant="m-strong">
                  {t('model.endpoints.visualizeFromDataset')}
                </Typography>
              </Box>
              <Button
                variant="strong-fill"
                color="secondary"
                onClick={() => selectTestDataDialogState.show()}
              >
                {t('model.endpoints.selectTestData')}
              </Button>
            </Box>
          )
        ) : (
          <Box
            backgroundColor={'gray-100'}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1.5}
            textAlign="center"
          >
            <Icon icon={InfoCircleOutline} size={60} color="gray-300" />
            <Typography variant="l-regular">
              {t('model.endpoints.visualizeOnlyRunningEndpoint')}
            </Typography>
          </Box>
        )}
      </Box>

      <Tabs>
        <TabList>
          <Tab>{t('model.generativeAi.annotation')}</Tab>
          <Tab>{t('model.endpoints.json')}</Tab>
        </TabList>
        <TabPanel>
          <Box overflow="auto" backgroundColor={'white'} style={{ height: 242 }}>
            {!!Object.values(predictionResults).length ? (
              Object.values(predictionResults).map(anno => {
                return (
                  <AnnotationClassesCollapse
                    key={anno.id}
                    formattedAnnotation={anno}
                    filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
                    setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
                    handleClickList={handleClickList}
                    handleHoverList={handleHoverList}
                    handleLeaveList={handleLeaveList}
                    clickedAnnotationId={clickedAnnotationId}
                    hoveredAnnotatinoId={hoveredAnnotatinoId}
                    annotationColorMap={annotationColorMap}
                  />
                );
              })
            ) : (
              <Box pt={4} display="flex" justifyContent="center">
                <Typography variant="m-regular">{t('model.endpoints.noPrediction')}</Typography>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel>
          <JsonResult result={inputJsonResult} />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

const OutputImage = ({
  imageUrl,
  outputImageAnnotations,
  annotationColorMap,
  inputJsonResult,
  predictionResults,
  status,
  isEnabledTest,
  handleClickMutate,
}: {
  imageUrl: string | null;
  outputImageAnnotations: PixiDetailAnnotation[];
  annotationColorMap: Record<string, string>;
  inputJsonResult: string;
  predictionResults: Record<string, FormattedAnnotation>;
  status: EndpointStatus;
  isEnabledTest: boolean;
  handleClickMutate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const {
    filteredPredictionAnnotationsIds,
    setFilteredPredictionAnnotationsIds,
    annotationSelectedMap,
    setAnnotationSelectedMap,
    annotationHoveredMap,
    setAnnotationHoveredMap,
    clickedAnnotationId,
    setClickedAnnotationId,
    hoveredAnnotatinoId,
    inferenceData,
    isInferenceLoading,
    outputIndex,
    setOutputIndex,
  } = useGenerationPredictionTestContext();

  const filteredInputImageAnnotations = outputImageAnnotations?.filter(
    anno => !filteredPredictionAnnotationsIds.includes(String(anno.id)),
  );

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(String(annotation.id));
        return {
          [annotation.id]: true,
        };
      }
    });
  }, []);

  const handleHoverShape = useCallback(
    (annotation: PixiDetailAnnotation) => {
      if (Object.keys(annotationHoveredMap)[0] === annotation.id) return;
      setAnnotationHoveredMap(() => {
        return {
          [annotation.id]: true,
        };
      });
    },
    [annotationHoveredMap],
  );

  const handleLeaveShape = useCallback(() => {
    setAnnotationHoveredMap({});
  }, []);

  const handleClickList = (id: string) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[id]) {
        setClickedAnnotationId('');
        return {};
      } else {
        setClickedAnnotationId(id);
        return {
          [id]: true,
        };
      }
    });
  };
  const handleHoverList = (id: string) => {
    if (Object.keys(annotationHoveredMap)[0] === id) return;
    setAnnotationHoveredMap(() => {
      return {
        [id]: true,
      };
    });
  };

  const handleLeaveList = useCallback(() => {
    setAnnotationHoveredMap({});
  }, []);

  return (
    <Box borderColor="gray-150" py={2} px={2.5} position="relative">
      <Row justifyContent="space-between">
        <Typography variant="l-strong">{t('model.generativeAi.output')}</Typography>
        {inferenceData && (
          <Button
            size="s"
            color="gray"
            variant="strong-fill"
            disabled={!isEnabledTest}
            onClick={handleClickMutate}
          >
            <Icon icon={GenerateImage} />
            {t('model.endpoints.generateTestResult')}
          </Button>
        )}
      </Row>
      <Box
        backgroundColor="gray-100"
        mb={1}
        mt={1.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        style={{ height: 282 }}
      >
        {imageUrl ? (
          <PixiDetailImage
            imgUrl={imageUrl}
            samplePredictionAnnotations={filteredInputImageAnnotations}
            annotationColorMap={annotationColorMap}
            annotationSelectedMap={annotationSelectedMap}
            annotationHoveredMap={annotationHoveredMap}
            onSelectImage={handleSelectImage}
            onSelectShape={handleSelectShape}
            onHoverShape={handleHoverShape}
            onLeaveShape={handleLeaveShape}
          />
        ) : status === 'running' ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              border="2px solid"
              borderColor="gray-200"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={1.5}
              backgroundColor={'white'}
              style={{ width: 80, height: 80, borderRadius: 40 }}
            >
              <Icon icon={GenerateImage} color="cloud" size={40} />
            </Box>
            <Box textAlign="center" mb={1.5} style={{ width: 241 }}>
              <Typography variant="m-strong">{t('model.endpoints.testGenerate')}</Typography>
            </Box>
            <Button variant="strong-fill" disabled={!isEnabledTest} onClick={handleClickMutate}>
              {t('model.endpoints.generateTestResult')}
            </Button>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Icon icon={GenerateImage} color="gray-300" size={40} />
          </Box>
        )}
        {inferenceData && (
          <Row
            position="absolute"
            backgroundColor={'gray-400'}
            style={{ width: 72, height: 24, borderRadius: 12, bottom: 8, right: 8 }}
          >
            <IconButton
              icon={ChevronLeft}
              disabled={outputIndex === 0}
              variant="text"
              color="white"
              size="s"
              onClick={() => {
                if (outputIndex === 0) return;
                setOutputIndex(prev => prev - 1);
              }}
            />
            <Typography variant="s-regular" color="white">
              {outputIndex + 1} / {inferenceData?.outputs.length}
            </Typography>
            <IconButton
              icon={ChevronRight}
              disabled={outputIndex === inferenceData?.outputs.length - 1}
              size="s"
              variant="text"
              color="white"
              onClick={() => {
                if (outputIndex === inferenceData?.outputs.length - 1) return;
                setOutputIndex(prev => prev + 1);
              }}
            />
          </Row>
        )}
      </Box>

      <Tabs>
        <TabList>
          <Tab style={{ backgroundColor: 'transparent' }}>{t('model.generativeAi.annotation')}</Tab>
          <Tab style={{ backgroundColor: 'transparent' }}>{t('model.endpoints.json')}</Tab>
        </TabList>
        <TabPanel>
          <Box overflow="auto" style={{ height: 242 }}>
            {!!Object.values(predictionResults).length ? (
              Object.values(predictionResults).map(anno => {
                return (
                  <AnnotationClassesCollapse
                    key={anno.id}
                    formattedAnnotation={anno}
                    filteredPredictionAnnotationsIds={filteredPredictionAnnotationsIds}
                    setFilteredPredictionAnnotationsIds={setFilteredPredictionAnnotationsIds}
                    handleClickList={handleClickList}
                    handleHoverList={handleHoverList}
                    handleLeaveList={handleLeaveList}
                    clickedAnnotationId={clickedAnnotationId}
                    hoveredAnnotatinoId={hoveredAnnotatinoId}
                    annotationColorMap={annotationColorMap}
                  />
                );
              })
            ) : (
              <Box pt={4} display="flex" justifyContent="center">
                <Typography variant="m-regular">{t('model.endpoints.noPrediction')}</Typography>
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel>
          <JsonResult result={inputJsonResult} />
        </TabPanel>
      </Tabs>
      {isInferenceLoading && (
        <Box
          position="absolute"
          backgroundColor={'black'}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1.5}
          style={{ top: 0, left: 0, opacity: 0.6 }}
        >
          <Icon icon={LoadingSpinnerSmall} size={60} color="primary" />
          <Typography variant="l-strong" color="white" style={{ opacity: 'none' }}>
            {t('model.endpoints.visualizeLoading')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const JsonResult = ({ result }: { result: string }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const handleClickCopy = (value: string) => {
    FileUtils.copyToClipboard({
      value,
    });
    enqueueSnackbar(JSON_COPIED({ t }), {
      variant: 'success',
    });
  };

  return (
    <>
      {result ? (
        <Box position="relative">
          <Box
            backgroundColor={'gray-100'}
            px={1.5}
            py={1}
            mt={1}
            overflow="auto"
            borderRadius="2px"
            style={{ wordBreak: 'break-all', height: 234 }}
          >
            <Typography variant="m-regular">{result}</Typography>
            <Box
              position={'absolute'}
              width={'100%'}
              display={'flex'}
              justifyContent={'flex-end'}
              style={{
                bottom: 8,
                right: 28,
              }}
            >
              <IconButton icon={Copy} onClick={() => handleClickCopy(result)} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box pt={4} display="flex" justifyContent="center">
          <Typography variant="m-regular">{t('model.endpoints.noPrediction')}</Typography>
        </Box>
      )}
    </>
  );
};
const annotationIdResolver = (classname: string, index: number, box: number[]) => {
  return `${classname}-${index}-${box.join(',')}`;
};
