import React from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { Box } from '@superb-ai/norwegian-forest';
import { atoms } from '@superb-ai/ui';

import WorkappUnion from '../../../union/WorkappUnion';
import ObjectClassItem from './ObjectClassItem';
interface Props {
  project: any;
  fontSize?: number;
  color?: string;
  maxHeight?: number;
}

// Todo (tsnoh) legacy. video-siesta에서는 label interface가 달라서 objectClassItem까지 영향을 미친다.
const ObjectClassList: React.FC<Props> = props => {
  const { project, fontSize = 12, color, maxHeight = 200 } = props;

  function ListItem({ index, style, data: { items } }: ListChildComponentProps) {
    const item = items[index];

    return (
      <div style={style}>
        <ObjectClassItem
          isSiesta={WorkappUnion.isSiesta(project.workapp)}
          key={item.name}
          objectClass={item}
          keypointInterface={project.labelInterface.keypointInterface}
          labelInterface={project.labelInterface}
          fontSize={fontSize}
          color={color}
        />
      </div>
    );
  }

  const className = atoms({ overflow: 'auto' });

  const labelInterface = project?.labelInterface;
  const items =
    labelInterface?.objectDetection?.objectClasses ??
    labelInterface?.objectTracking?.objectClasses ??
    labelInterface?.objects ??
    [];

  const itemHeight = fontSize + 11;

  return (
    <Box themedBackgroundColor={['grey', 50]}>
      <FixedSizeList
        width="100%"
        height={Math.min(maxHeight, items.length * itemHeight)}
        itemSize={itemHeight}
        itemCount={items.length}
        itemData={{ items }}
        overscanCount={10}
        className={className}
      >
        {ListItem}
      </FixedSizeList>
    </Box>
  );
};

export default ObjectClassList;
