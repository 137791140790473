import { ComponentProps } from 'react';

import { Box } from '@superb-ai/ui';

export function VerticalDivider(props: ComponentProps<typeof Box>) {
  const { style, ...otherProps } = props;
  const mergedStyle = Object.assign({ width: '1px' }, style);

  return <Box backgroundColor="gray-200" height="100%" style={mergedStyle} {...otherProps} />;
}
