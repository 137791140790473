import React from 'react';

import Layout from './Layout';
import { ThreadProps } from './types';

const index: React.FC<ThreadProps> = props => {
  return <Layout {...props} />;
};

export default index;
