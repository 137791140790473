import React, { useContext } from 'react';
import { Stage } from 'react-konva';

import { AuthContext, useAuthInfo } from '../../../../contexts/AuthContext';
import ImageContext from '../../../../contexts/ImageContext';
import { LabelCompareContext, useLabelCompareInfo } from '../../../../contexts/LabelCompareContext';
import {
  LabelDetailViewContext,
  useLabelDetailViewInfo,
} from '../../../../contexts/LabelDetailViewContext';
import {
  LabelInformationContext,
  useLabelInformationInfo,
} from '../../../../contexts/LabelInformationContext';
import { LabelIssuesContext, useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { LabelsContext, useLabelsInfo } from '../../../../contexts/LabelsContext';
import { ProjectContext, useProjectInfo } from '../../../../contexts/ProjectContext';
import { RouteContext, useRouteInfo } from '../../../../contexts/RouteContext';
import StageSizeContext from '../../../../contexts/StageSizeContext';

// eslint-disable-next-line
const KonvaStage = (props: any): any => {
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelsInfo = useLabelsInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelCompareInfo = useLabelCompareInfo();
  const labelIssuesInfo = useLabelIssues();
  const imageInfo = useContext(ImageContext.Context);
  const stageInfo = useContext(StageSizeContext.Context);

  const { children } = props;

  if (stageInfo.width === 1) return null;

  return (
    <Stage width={stageInfo.width} height={stageInfo.height}>
      <RouteContext.Provider value={routeInfo}>
        <AuthContext.Provider value={authInfo}>
          <ProjectContext.Provider value={projectInfo}>
            <LabelsContext.Provider value={labelsInfo}>
              <LabelDetailViewContext.Provider value={labelDetailViewInfo}>
                <LabelInformationContext.Provider value={labelInformationInfo}>
                  <LabelCompareContext.Provider value={labelCompareInfo}>
                    <LabelIssuesContext.Provider value={labelIssuesInfo}>
                      <StageSizeContext.Context.Provider value={stageInfo}>
                        <ImageContext.Context.Provider value={imageInfo}>
                          {children}
                        </ImageContext.Context.Provider>
                      </StageSizeContext.Context.Provider>
                    </LabelIssuesContext.Provider>
                  </LabelCompareContext.Provider>
                </LabelInformationContext.Provider>
              </LabelDetailViewContext.Provider>
            </LabelsContext.Provider>
          </ProjectContext.Provider>
        </AuthContext.Provider>
      </RouteContext.Provider>
    </Stage>
  );
};

export default KonvaStage;
