import { Link2 } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu/MenuItem';
import { IntegrationDetailMenuItem } from './detail/MenuItem';
import Layout from './Layout';
import { IntegrationsListMenuItem } from './list/MenuItem';

export const IntegrationsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountIntegrations,
  path: 'integrations',
  group: 'account',
  title: ({ t }) => t('integrations.title'),
  component: Layout,
  icon: <Icon icon={Link2} />,
  children: [IntegrationDetailMenuItem, IntegrationsListMenuItem],
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo) || authInfo?.isGuest || false;
  },
});
