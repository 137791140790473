import React from 'react';

import { Box, Checkbox, Tooltip, Typography } from '@superb-ai/ui';

export default function FilterItem({
  id,
  text,
  prefix,
  suffix,
  isSelected,
  isHighlighted,
  isHovered,
  handleHoverItem,
  handleClickItemCheckbox,
  disabled,
  getTooltipContent,
}: {
  id: string;
  text: JSX.Element;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  isSelected: boolean;
  isHighlighted?: boolean;
  isHovered?: boolean;
  handleClickItemCheckbox: (id: string) => void;
  handleHoverItem: (id: string | null) => void;
  disabled?: boolean;
  getTooltipContent?: () => string | JSX.Element;
}) {
  return (
    <Box
      display="grid"
      alignItems="center"
      gap={0.5}
      style={{ gridTemplateColumns: `auto${prefix ? ' auto' : ''} 1fr${suffix ? ' auto' : ''}` }}
      onMouseEnter={() => handleHoverItem(id)}
      onMouseLeave={() => handleHoverItem(null)}
      backgroundColor={{
        hover: isHovered ? 'primary-100' : undefined,
        default: isHighlighted ? 'primary-200' : undefined,
      }}
    >
      <Tooltip
        hideOnEmptyContent
        content={getTooltipContent && disabled ? getTooltipContent() : undefined}
        placement="right"
      >
        <span>
          <Checkbox
            disabled={disabled}
            value={isSelected}
            onClick={() => handleClickItemCheckbox(id)}
          />
        </span>
      </Tooltip>
      {prefix && prefix}
      <Typography variant="m-regular" overflow="hidden" textOverflow="ellipsis">
        {text}
      </Typography>
      {suffix && suffix}
    </Box>
  );
}
