import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';

import getErrorConst from '../consts/ErrorConst';
import { useAuthInfo } from '../contexts/AuthContext';
import { useErrorInfo } from '../contexts/ErrorContext';
import { useRouteInfo } from '../contexts/RouteContext';
import { useProjectListQuery } from '../queries/useProjectListQuery';
import { ProjectData } from '../types/projectTypes';
import { useProjectPagination } from './ProjectPaginationHook';

export interface ProjectListInfo {
  projects: ProjectData[];
  totalCount: number;
  projectsOptions: any[];
  projectIds: Record<string, string>;
  isLoading: boolean;
}

export const useProjectListInfo = (withStatus?: boolean) => {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const errorInfo = useErrorInfo();

  const [projectsOptions, setProjectsOptions] = useState<any[]>([]);
  const [projectIds, setProjectIds] = useState({});

  const params = useProjectPagination();
  const { data: projects, isLoading } = useProjectListQuery({ ...params, withStatus: withStatus });

  useEffect(() => {
    if (!projects) return;
    if (authInfo.isGuest && projects.count <= 0 && isEmpty(routeInfo.params)) {
      errorInfo.setError(getErrorConst(t).PAGE_NOT_FOUND);
    }

    const projectIds: Record<string, string> = {};
    const projectsOptions = [
      {
        label: 'all',
        options: [
          { label: '[ all projects ]', value: '[ all projects ]' },
          { label: '[ assigned to a project ]', value: '[ assigned to a project ]' },
          { label: '[ not assigned to any project ]', value: '[ not assigned to any project ]' },
        ],
      },
      {
        label: 'projects',
        options: projects.results.map(project => {
          projectIds[project.name] = project.id;
          return { label: project.name, value: project.name };
        }),
      },
    ];

    setProjectIds(projectIds);
    setProjectsOptions(projectsOptions);
  }, [projects]);

  return {
    projects: projects?.results ?? [],
    totalCount: projects?.count ?? 0,
    projectsOptions,
    projectIds,
    isLoading,
  };
};
