import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ChevronDown, ChevronRight, Clear, SubRight } from '@superb-ai/icons';
import { Box, Chip, Icon, IconButton, Popover, Typography } from '@superb-ai/ui';

import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';
import { getColorsOf400 } from '../../../../../../../../utils/ColorUtils';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetAnnotationMetadataKeysQuery } from '../../../../../../queries/datasetObjectQueries';
import PropertiesList from './PropertiesList';
import { ClassAndProperties, MetadataProperty } from './type';

export default function ClassPropertiesList({
  classNameInputValue,
  searchedClassList,
  selectedClass,
  setSelectedClass,
  curateAnnotations,
  setCurateAnnotations,
  selectedAnnotationType,
}: {
  classNameInputValue?: string;
  searchedClassList?: string[];
  selectedClass?: string;
  setSelectedClass: Dispatch<SetStateAction<string | undefined>>;
  curateAnnotations?: ClassAndProperties;
  setCurateAnnotations: Dispatch<SetStateAction<ClassAndProperties | undefined>>;
  selectedAnnotationType?: CurateAnnotationType;
}) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const colorOptions = getColorsOf400();

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const annotationClasses =
    curateAnnotations && selectedAnnotationType
      ? curateAnnotations[selectedAnnotationType]
      : undefined;

  const currentProperties =
    (selectedClass &&
      selectedAnnotationType &&
      curateAnnotations?.[selectedAnnotationType]?.[selectedClass]?.properties) ||
    undefined;

  const { data } = useDatasetAnnotationMetadataKeysQuery({
    datasetId,
    annotationClass: selectedClass,
    disabled: !!currentProperties,
    queryString: queryStringWithHiddenFilterAndDeselection,
    sliceName: sliceInfo?.name,
    imageFilters:
      clusterLevel && appliedFilters
        ? {
            cluster_id_in: appliedFilters?.cluster_id_in || [],
            cluster_level: clusterLevel,
          }
        : undefined,
  });

  const metadataProperties: Record<string, MetadataProperty> = useMemo(
    () =>
      data?.metadataKeys.reduce((acc, key) => {
        if (key[0] === '_') return acc;
        return {
          ...acc,
          [key]: {
            name: key,
            isNew: false,
            isRequired: false,
            type: 'free-response',
          },
        };
      }, {}) || {},
    [data?.metadataKeys],
  );

  function handleChangeProjectClassAndProperty(
    metadataProperties: Record<string, MetadataProperty>,
  ) {
    if (!selectedClass || !selectedAnnotationType) return;
    setCurateAnnotations(prev => {
      const prevState = prev || ({} as ClassAndProperties);
      const updatedState = {
        ...prevState,
        [selectedAnnotationType]: {
          ...prevState[selectedAnnotationType],
          [selectedClass]: {
            ...prevState[selectedAnnotationType][selectedClass],
            properties: metadataProperties,
          },
        },
      };
      return updatedState;
    });
  }

  function handleDeleteProjectClass(className: string) {
    if (!curateAnnotations || !selectedAnnotationType) return;
    setCurateAnnotations(prev => {
      const prevState = prev || ({} as ClassAndProperties);
      const newProjectClassAndProperty = { ...prevState };

      if (newProjectClassAndProperty[selectedAnnotationType]) {
        delete newProjectClassAndProperty[selectedAnnotationType][className];
      }

      return newProjectClassAndProperty;
    });
  }

  useEffect(() => {
    if (currentProperties) return;
    handleChangeProjectClassAndProperty(metadataProperties);
  }, [metadataProperties]);

  if (searchedClassList?.length === 0) {
    if (!classNameInputValue || classNameInputValue === '') return <></>;
    return (
      <Box p={0.5} pl={1} display="flex" gap={0.5} overflow="auto">
        <Icon icon={SubRight} />
        <Typography variant="m-regular" overflow="hidden" textOverflow="ellipsis">
          {t(
            'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.createNewObjectClass',
            {
              className: classNameInputValue,
            },
          )}
        </Typography>
      </Box>
    );
  }

  if (!selectedAnnotationType || !curateAnnotations?.[selectedAnnotationType]) return <></>;
  return (
    <>
      {searchedClassList?.map(className => {
        const classes = annotationClasses?.[className];
        const isSelected = selectedClass === className;
        return (
          <Box
            key={className}
            backgroundColor={{
              hover: 'gray-100',
              default: isSelected ? 'gray-100' : undefined,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
              p={0.5}
              onClick={e => {
                if (isSelected) {
                  setSelectedClass(undefined);
                } else {
                  setSelectedClass(className);
                }
              }}
            >
              <Icon icon={isSelected ? ChevronDown : ChevronRight} color={'gray-400'} />
              <Popover
                fixed
                tabIndex={0}
                placement="right"
                offset={0.5}
                style={{ zIndex: 1000 }}
                hideOnClickOutside
                disclosure={
                  <Box
                    style={{ width: '10px', height: '10px', backgroundColor: classes?.color }}
                    borderRadius="100%"
                    border="1px solid"
                    borderColor="white"
                    onClick={e => e.stopPropagation()}
                  />
                }
              >
                <Box
                  backgroundColor="white"
                  boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
                  p={0.5}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  borderRadius="2px"
                >
                  {colorOptions.map((color, index) => (
                    <Box
                      key={`${className}-${color}-${index}`}
                      style={{ width: '10px', height: '10px', backgroundColor: color }}
                      borderRadius="100%"
                      border="1px solid"
                      borderColor={classes?.color === color ? 'gray-400' : 'white'}
                      onClick={e => {
                        e.stopPropagation();
                        setCurateAnnotations(prev => {
                          const prevState = prev || ({} as ClassAndProperties);
                          const updatedState = {
                            ...prevState,
                            [selectedAnnotationType]: {
                              ...prevState[selectedAnnotationType],
                              [className]: {
                                ...prevState[selectedAnnotationType][className],
                                color,
                              },
                            },
                          };
                          return updatedState;
                        });
                      }}
                    />
                  ))}
                </Box>
              </Popover>
              <Typography variant="m-regular">{className}</Typography>
              {classes && !classes?.isNew && (
                <Chip color="secondary">
                  {t(
                    'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.annotationsIncluded',
                  )}
                </Chip>
              )}
              <IconButton
                icon={Clear}
                size="s"
                variant="text"
                style={{ marginLeft: 'auto' }}
                onClick={e => {
                  e.stopPropagation();
                  handleDeleteProjectClass(className);
                }}
              />
            </Box>
            {isSelected && currentProperties && (
              <PropertiesList
                selectedClass={className}
                originalProperties={metadataProperties}
                metadataProperties={currentProperties}
                onChangeProperties={handleChangeProjectClassAndProperty}
              />
            )}
          </Box>
        );
      })}
    </>
  );
}
