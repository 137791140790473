import { format } from 'date-fns';
import { TFunction } from 'next-i18next';

export const productColor = {
  autoLabel: '#FF625A',
  embeddings: '#5A7BFF',
  imageSynthesis: '#BF36FF',
  modelEndpoints: '#82DB24',
  modelTraining: '#FFDE33',
} as Record<string, string>;

export const createColorAccessor = (t: TFunction) => {
  return ({ splitAccessors }) => {
    const translatedGroupKey = splitAccessors.get('group');
    const originalKey = Object.keys(productColor).find(
      key => t(`metering.usageChart.${key}`) === translatedGroupKey,
    );
    return productColor[originalKey];
  };
};

export function formatDate(dateStr: string): string {
  const date = new Date(dateStr);
  return format(date, 'M/d');
}

export function formatMonth(dateStr: string): string {
  const date = new Date(dateStr);
  return format(date, 'yyyy-MM');
}
