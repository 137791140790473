import React, { Dispatch, SetStateAction } from 'react';

import { FileJson, Image } from '@superb-ai/icons';
import { Box, Checkbox, Icon, Label, LinkTypography, Typography } from '@superb-ai/ui';
import { TFunction, Trans } from 'next-i18next';

import { UploadStepInfo } from '../../../../../../../../components/elements/newUpload/stepper/steps/type';
import { getUserManualUrl } from '../../../../../../../../consts/DocsLink';
import { UploadTarget } from '../../../../../../contexts/UploadContext';

const SelectDataTypeStep = ({
  t,
  language,
  cloudInfos,
}: {
  t: TFunction;
  language: string;
  cloudInfos: {
    uploadTarget: UploadTarget;
    setUploadTarget: Dispatch<SetStateAction<UploadTarget>>;
  };
}): UploadStepInfo => {
  const { uploadTarget, setUploadTarget } = cloudInfos;
  const { CURATE_CLOUD_ANNOTATION_UPLOAD } = getUserManualUrl(language); //TODO: change docs url

  function handleCheckboxClick(target: 'image' | 'annotation') {
    setUploadTarget(prev => {
      if (prev.includes(target)) {
        return prev.filter(t => t !== target);
      }
      return [...prev, target];
    });
  }

  function CheckboxWithLabel(target: 'image' | 'annotation') {
    return (
      <Label
        borderRadius="2px"
        p={0.5}
        border="1px solid"
        borderColor={{ default: 'gray-200', checked: 'primary' }}
        color={uploadTarget.includes(target) ? 'primary' : undefined}
      >
        <Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              value={uploadTarget.includes(target)}
              onClick={() => handleCheckboxClick(target)}
            />
            <Icon icon={target === 'image' ? Image : FileJson} />
            <Typography variant="m-strong">
              {t(`curate.datasets.cloudUpload.dataType.${target}`)}
            </Typography>
            {t(`curate.datasets.cloudUpload.dataType.${target}Extension`)}
          </Box>
          <Box ml={4}>
            <Trans
              t={t}
              i18nKey={`curate.datasets.cloudUpload.dataType.${target}Description`}
              components={{ emphasize1: <Typography style={{ fontStyle: 'italic' }} /> }}
            />{' '}
            {target === 'annotation' && (
              <LinkTypography
                onClick={e => {
                  e.preventDefault();
                  window.open(CURATE_CLOUD_ANNOTATION_UPLOAD, '_blank', 'noreferrer');
                }}
              >
                {t(`curate.datasets.cloudUpload.dataType.${target}DocsLink`)}
              </LinkTypography>
            )}
          </Box>
        </Box>
      </Label>
    );
  }

  return {
    title: t('curate.datasets.cloudUpload.selectDataType'),
    isButtonEnabled: true,
    summary: (
      <Typography variant="m-regular" color="gray-300" display="flex" alignItems="center" gap={0.5}>
        {uploadTarget.map((target, index) => (
          <React.Fragment key={target}>
            <Icon icon={target === 'image' ? Image : FileJson} />{' '}
            {t(`curate.datasets.cloudUpload.dataType.${target}`)}
            {index < uploadTarget.length - 1 && ', '}
          </React.Fragment>
        ))}
      </Typography>
    ),
    content: (
      <Box display="flex" flexDirection="column" gap={1}>
        {CheckboxWithLabel('image')}
        {CheckboxWithLabel('annotation')}
      </Box>
    ),
  };
};

export default SelectDataTypeStep;
