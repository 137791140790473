import * as ShyCredit from '@superb-ai/shy-credit';
import { TFunction } from 'next-i18next';

import {
  NAME_RANGE,
  OBJECT_CLASS_NAME_COMMA,
} from '../../../../../../../../consts/SnackbarMessage';
import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';
import StringUtils from '../../../../../../../../utils/StringUtils';
import { ClassAndProperties, MetadataProperty, MetadataPropertyType } from './type';

// convert to shy credit
function convertCategoryItem(option: { name: string; isDefault: boolean }) {
  return new ShyCredit.Models.ImageV2.CategoryItem({ name: option.name });
}

function convertPropertyDef(propName: string, propData: MetadataProperty) {
  const { name, type, isRequired, options } = propData;

  switch (type) {
    case 'multiple-choice':
      const optionArray = Object.values(options || {});
      const defaultOption = optionArray.find(option => option.isDefault);
      return new ShyCredit.Models.ImageV2.ChoicePropertyDef({
        id: propName,
        name,
        description: '',
        type: 'radio',
        defaultValue: defaultOption ? [defaultOption.name] : [],
        required: isRequired,
        options: optionArray.map(option => convertCategoryItem(option)),
      });
    case 'free-response':
      const createConstraints = () => {
        if (propData.isOnlyNumber) {
          return { alphabet: false, digit: true, space: false, special: false };
        }
        return { alphabet: true, digit: true, space: true, special: true };
      };

      return new ShyCredit.Models.ImageV2.TextPropertyDef({
        id: propName,
        name,
        description: '',
        type: 'free response',
        defaultValue: '',
        blank: !isRequired,
        constraints: createConstraints(),
      });
    default:
      throw new Error(`Unsupported property type: ${type}`);
  }
}

export function convertToLabelResultDef(objectData: ClassAndProperties) {
  const createConstraints = ({
    minCount,
    maxCount,
  }: {
    minCount: string;
    maxCount: string;
  }): { minCount?: number; maxCount?: number } => {
    const nextConstraints: { minCount?: number; maxCount?: number } = {
      minCount: 0,
      maxCount: 0,
    };

    if (typeof minCount === 'number') {
      nextConstraints.minCount = minCount;
    } else {
      delete nextConstraints.minCount;
    }

    if (typeof maxCount === 'number') {
      nextConstraints.maxCount = maxCount;
    } else {
      delete nextConstraints.maxCount;
    }

    return nextConstraints;
  };

  const annotationTypes = Object.keys(objectData);
  const convertedObjectClasses = annotationTypes.flatMap(annotationType =>
    Object.entries(objectData[annotationType as CurateAnnotationType]).map(
      ([className, classData]) => {
        const properties = classData.properties
          ? Object.entries(classData.properties).map(([propName, propData]) =>
              convertPropertyDef(propName, propData),
            )
          : [];

        return new ShyCredit.Models.ImageV2.ObjectClassDef({
          name: className,
          annotationType,
          color: classData.color,
          properties,
          constraints: createConstraints({
            minCount: '',
            maxCount: '',
          }),
        });
      },
    ),
  );

  return new ShyCredit.Models.ImageV2.LabelResultDef({
    dataType: 'image',
    objectDetection: new ShyCredit.Models.ImageV2.ObjectDetectionDef({
      annotationTypes,
      objectClasses: convertedObjectClasses,
      objectGroups: [],
      keypoints: [],
    }),
    categorization: new ShyCredit.Models.ImageV2.CategorizationDef({ properties: [] }),
  });
}

// convert to curate
function convertPropertyTypeToMetadataPropertyType(propertyType: string): MetadataPropertyType {
  switch (propertyType) {
    case 'radio':
      return 'multiple-choice';
    case 'free response':
      return 'free-response';
    default:
      throw new Error(`Unsupported property type: ${propertyType}`);
  }
}

function isChoicePropertyDef(
  property: ShyCredit.Models.ImageV2.ChoicePropertyDef | ShyCredit.Models.ImageV2.TextPropertyDef,
): property is ShyCredit.Models.ImageV2.ChoicePropertyDef {
  return property.type === 'radio' || property.type === 'checkbox';
}

function isTextPropertyDef(
  property: ShyCredit.Models.ImageV2.ChoicePropertyDef | ShyCredit.Models.ImageV2.TextPropertyDef,
): property is ShyCredit.Models.ImageV2.TextPropertyDef {
  return property.type === 'free response';
}

export function createClassAndProperties(
  labelResultDef: ShyCredit.Models.ImageV2.LabelResultDef,
): ClassAndProperties {
  const annotationTypes = labelResultDef.objectDetection.annotationTypes;
  const classAndProperties: ClassAndProperties = annotationTypes.reduce((acc, annotationType) => {
    acc[annotationType as CurateAnnotationType] = {};
    return acc;
  }, {} as ClassAndProperties);
  labelResultDef.objectDetection.objectClasses.forEach(
    (objectClass: ShyCredit.Models.ImageV2.ObjectClassDef) => {
      const annotationType = objectClass.annotationType;
      const className = objectClass.name;
      const properties: Record<string, MetadataProperty> = objectClass.properties.reduce(
        (
          acc: Record<string, MetadataProperty>,
          property:
            | ShyCredit.Models.ImageV2.ChoicePropertyDef
            | ShyCredit.Models.ImageV2.TextPropertyDef,
        ) => {
          const propertyName = property.name;
          const propertyType = convertPropertyTypeToMetadataPropertyType(property.type);
          const metadataProperty: MetadataProperty = {
            name: propertyName,
            isRequired:
              (isChoicePropertyDef(property) && property.required) ||
              (isTextPropertyDef(property) && !property.blank) ||
              false,
            isNew: true,
            type: propertyType,
          };
          if (isChoicePropertyDef(property)) {
            metadataProperty.options = property.options.reduce(
              (
                optionsAcc: Record<string, { name: string; isDefault: boolean }>,
                option: ShyCredit.Models.ImageV2.CategoryItem,
              ) => {
                optionsAcc[option.id] = {
                  name: option.name,
                  isDefault: !!property.defaultValue?.includes(option.name),
                };
                return optionsAcc;
              },
              {},
            );
          }
          if (property.type === 'free response') {
            const { constraints } = property as ShyCredit.Models.ImageV2.TextPropertyDef;
            metadataProperty.isOnlyNumber = constraints?.digit && !constraints?.alphabet;
          }
          acc[propertyName] = metadataProperty;
          return acc;
        },
        {},
      );

      if (!annotationType) return;
      classAndProperties[annotationType as CurateAnnotationType][className] = {
        class: className,
        isNew: true,
        properties,
        color: objectClass.color,
      };
    },
  );
  return classAndProperties;
}

const NAME_LENGTH_MIN = 1;
const NAME_LENGTH_MAX = 50;
export function getClassPropertyNamingRulesErrorMessages(
  type: 'class' | 'property',
  name: string,
  t: TFunction,
) {
  if (!StringUtils.isWithinRangeLength(name, NAME_LENGTH_MIN, NAME_LENGTH_MAX)) {
    return NAME_RANGE({
      t,
      name: type,
      min: NAME_LENGTH_MIN,
      max: NAME_LENGTH_MAX,
    });
  }
  if (type === 'class' && name.includes(',')) {
    return OBJECT_CLASS_NAME_COMMA({ t });
  }
  return undefined;
}
