import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Edit, Sync } from '@superb-ai/icons';
import { Box, Icon, IconButton, LinkTypography, Typography } from '@superb-ai/ui';

import { useProjectQuery } from '../../../../../../../../queries/useProjectQuery';
import { getUrl } from '../../../../../../../../routes/util';
import { AnnotationsInfo } from '../../../../../../types/detailViewTypes';

export default function SyncedProject({
  annotationInfo,
  open,
}: {
  annotationInfo: AnnotationsInfo;
  open: boolean;
}) {
  const { accountName } = useParams<{ accountName: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useProjectQuery({
    projectId: annotationInfo._label_project_id,
    disabled: !open,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      p={1}
      ml={3}
      mb={1}
      backgroundColor="green-100"
      style={{ marginTop: '-8px' }}
    >
      <Typography
        variant="m-regular"
        display="flex"
        alignItems="center"
        gap={0.5}
        whiteSpace="nowrap"
      >
        <Icon icon={Sync} size="12px" />
        <Trans
          t={t}
          i18nKey={'curate.datasets.detailView.syncedWithProject'}
          values={{ projectName: data?.name || '' }}
          components={{
            Link1: (
              <LinkTypography
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                onClick={() =>
                  history.push(
                    getUrl([
                      accountName,
                      'label',
                      'project',
                      annotationInfo._label_project_id || '',
                    ]),
                  )
                }
              />
            ),
          }}
        />
        <IconButton
          variant="text"
          size="s"
          icon={Edit}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            window.open(
              `/annotation/image/?app_mode=edit&app_env=new-tab&project_id=${annotationInfo._label_project_id}&label_id=${annotationInfo._label_id}`,
            );
          }}
        />
      </Typography>
      {/* <Typography variant="m-regular">Label ID: {annotationInfo._label_id}</Typography> */}
    </Box>
  );
}
