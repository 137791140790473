import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import CabinetCompare from './CabinetCompare';
import CabinetFooter from './CabinetFooter';
import CabinetInformation from './CabinetInformation';
import CabinetIssue from './CabinetIssue';
import CabinetTab from './CabinetTab';

const useStyles = makeStyles(() => ({
  box: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 8px 0px 8px',
    overflow: overflowOverlayOrAuto(),
  },
}));

const getCabinetSection = (state: string): React.ReactElement | null => {
  switch (state) {
    case 'information':
      return <CabinetInformation />;
    case 'issue':
      return <CabinetIssue />;
    case 'compare':
      return <CabinetCompare />;
    default:
      return null;
  }
};

const CabinetLayout = (): React.ReactElement => {
  const classes = useStyles();
  const labelInformationInfo = useLabelInformationInfo();
  const { cabinetTabState } = labelInformationInfo;

  return (
    <>
      <CabinetTab />
      <MUI.Box className={classes.box}>{getCabinetSection(cabinetTabState)}</MUI.Box>
      <CabinetFooter />
    </>
  );
};

export default CabinetLayout;
