/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useLocalStorage } from '@superb-ai/norwegian-forest';
import { Button } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../contexts/AuthContext';
import { useMetering } from '../../../queries/useMeteringQuery';
import { openChatWidget } from '../../../utils/chatWidget';
import { EvaluationFilterSchemaSnakeCase } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import enqueueCommandSnackbar from '../elements/Command/CommandSnackbar';
import { getConfigPerJobType } from '../elements/Command/config';
import { useCurateCommandsService } from '../services/CommandsService';
import { useCurateDatasetService } from '../services/DatasetService';
import { useDiagnosisModelService } from '../services/DiagnosisModelService';
import { Command, CommandStatus, CommandType, CurateJobParams } from '../types/commandTypes';
import {
  DoneStatuses,
  exponentialBackoffTime,
  getCommandConfigs,
  InProgressStatuses,
} from '../utils/commandUtils';
import { useDownloadsService } from '../services/DownloadsService';
interface Collection<T> {
  items: T[];
  hasMore: boolean;
  isLoading: boolean;
  cursor: string | null;
}

const initialCollection = {
  items: [],
  hasMore: false,
  isLoading: false,
  cursor: 'initial',
};

type Filters = { onlyMy: boolean };

export type DiagnosisJobParam = {
  datasetId: string;
  diagnosisId: string;
  modelId: string;
};

export type UpdateSliceByEvaluationParam = {
  dataset_id: string;
  diagnosis_id: string;
} & EvaluationFilterSchemaSnakeCase;

export interface CurateCommandContext {
  commandsInProgress: Collection<Command<CommandType>>;
  commandsDone: Collection<Command<CommandType>>;
  fetchCommands(status: 'in_progress' | 'done', cursor: string | null): Promise<void>;
  fetchMoreCommands(status: 'in_progress' | 'done'): Promise<void>;
  cancelCommand<T extends CommandType>(command: Command<T>): Promise<void>;
  retryCommand<T extends CommandType>(command: Command<T>): Promise<void>;
  filters: Filters;
  processedFilters: Filters;
  setFilters(filters: Filters): void;
  registerCommand<T extends CommandType>(id: string, dataJson?: CurateJobParams<T>): void;
  isModalVisible: boolean;
  setIsModalVisible(isVisible: boolean): void;
  isArchiveVisible: boolean;
  setIsArchiveVisible(isVisible: boolean): void;
  tabValue: 'in_progress' | 'done';
  setTabValue(tabValue: 'in_progress' | 'done'): void;
  previousCommandsInProgress: Collection<Command<CommandType>>;
}

const CurateCommandContext = React.createContext({} as CurateCommandContext);

type Progress = { progress: number; totalCount: number; status: CommandStatus };

/**
 * Cache for update info. This state doesn't need to be reactive, so we keep it
 * as a WeakMap, which has the added benefit that data can be GC'ed as soon as the
 * command object is removed.
 */
type CommandUpdateInfo = {
  lastProgress: Progress;
  timeout: NodeJS.Timeout | null;
  unchangedCount: number;
};
const updateInfo = new WeakMap<Command<CommandType>, CommandUpdateInfo>();

function getUpdateInfo(command: Command<CommandType>): CommandUpdateInfo {
  const info = updateInfo.get(command);
  if (info) {
    return info;
  }
  const { progress, totalCount, status } = command;
  // For commands that have not been updated in a while, start exponential backoff higher
  const unchangedCount = Math.min(
    7,
    Math.floor((+new Date() - +new Date(command.updatedAt)) / 30000),
  );
  const initialInfo = {
    lastProgress: { progress, totalCount, status },
    timeout: null,
    unchangedCount,
  };
  updateInfo.set(command, initialInfo);
  return initialInfo;
}

function hasProgressChanged(a: Progress, b: Progress) {
  return a.progress !== b.progress || a.totalCount !== b.totalCount || a.status !== b.status;
}

export const CurateCommandContextProvider: React.FC = ({ children }) => {
  const { getDataset, getSlice, getProject, getSliceList } = useCurateDatasetService();
  const { getDiagnosisDetail } = useDiagnosisModelService();
  const meteringCurateDataVolume = useMetering('curate:data-volume');
  const { t, i18n } = useTranslation();
  // The refs are for internal use, so that timeout callbacks always have the latest state
  const _commandsInProgress = useRef<Collection<Command<CommandType>>>({ ...initialCollection });
  const _commandsDone = useRef<Collection<Command<CommandType>>>({ ...initialCollection });
  // The state is for external use, so that components can update when state changes
  const [commandsInProgress, setCommandsInProgress] = useState<Collection<Command<CommandType>>>({
    ...initialCollection,
  });

  const [previousCommandsInProgress, setPreviousCommandsInProgress] = useState<
    Collection<Command<CommandType>>
  >({
    ...initialCollection,
  });
  const [commandsDone, setCommandsDone] = useState<Collection<Command<CommandType>>>({
    ...initialCollection,
  });
  const [filters, setFilters] = useLocalStorage<Filters>('commandFilter', {
    onlyMy: true,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isArchiveVisible, setIsArchiveVisible] = useState(false);
  const [tabValue, setTabValue] = useState<'in_progress' | 'done'>('in_progress');
  const authInfo = useAuthInfo();
  const { getJob, getJobs, cancelJob, retryJob } = useCurateCommandsService();
  const { getDownload } = useDownloadsService();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();

  // Irregardless of user's preference, non-Admins can only view their own actions inside of projects.
  const processedFilters: Filters = useMemo(() => {
    return {
      onlyMy: filters.onlyMy || !isOwnerOrAdminOrSystemAdmin(authInfo),
    };
  }, [filters, authInfo]);

  /**
   * Fetch command info by id and update state.
   * This is called twice in a normal lifecycle:
   * - once on creation by the user
   * - once when status changes from InProgress to Done
   * This only affects commandsInProgress.
   */
  async function registerCommand(id: string, dataJson?: CurateJobParams<CommandType>) {
    const data = await getJob({ jobId: id });

    const command = _commandsInProgress.current!.items.find(c => c.id === data.id);
    if (command) {
      Object.assign(command, data);
    } else {
      if (dataJson)
        _commandsInProgress.current!.items = [
          { ...data, param: { ...dataJson, datasetId: data.param.datasetId } },
          ..._commandsInProgress.current!.items,
        ];
      else _commandsInProgress.current!.items = [data, ..._commandsInProgress.current!.items];
    }

    setCommandsInProgress({ ..._commandsInProgress.current! });

    const commandConfigParams = await getCommandConfigs({
      command: data,
      getDataset,
      getSlice,
      getProject,
      getDiagnosisDetail,
      getDownload,
    });

    if (data.status === 'CANCELED') return; // Hide snackbar for cancel job

    const isDone = DoneStatuses.includes(data.status);
    const key = `command-${data.id}-${isDone ? 'done' : 'start'}`;
    const commandConfig = getConfigPerJobType({
      t,
      language: i18n.language,
      history,
      onClickMessageLink: () => closeSnackbar(key),
      accountName,
      command: data,
      format: 'quotes',
      ...commandConfigParams,
    });

    enqueueCommandSnackbar({
      key,
      enqueueSnackbar,
      closeSnackbar,
      commandConfig,
      command: data,
      setIsModalVisible,
      setTabValue,
      t,
      history,
    });
  }

  async function fetchProgress<T extends CommandType>(command: Command<T>) {
    const updateInfo = getUpdateInfo(command);

    const data = await getJob({ jobId: command.id });

    const progress = { progress: data.progress, totalCount: data.totalCount, status: data.status };

    // If progress is unchanged, increase a counter for exponential backoff
    const hasChanged = hasProgressChanged(updateInfo.lastProgress, progress);
    updateInfo.unchangedCount = hasChanged ? 0 : updateInfo.unchangedCount + 1;
    updateInfo.lastProgress = progress;

    const isDone = DoneStatuses.includes(progress.status);

    if (hasChanged) {
      // Update state
      Object.assign(command, progress);
      setCommandsInProgress({ ..._commandsInProgress.current! });
    }

    if (!isDone) {
      // As long as command is running, keep requesting progress
      scheduleFetchProgress(command);
    } else {
      // Fetch one last full update (this contains the result)
      await registerCommand(command.id);
    }
  }

  function scheduleFetchProgress<T extends CommandType>(command: Command<T>, immediate = false) {
    const updateInfo = getUpdateInfo(command);
    if (updateInfo.timeout) {
      clearTimeout(updateInfo.timeout);
    }
    const time = immediate ? 0 : exponentialBackoffTime(updateInfo.unchangedCount, 3000);
    updateInfo.timeout = setTimeout(() => fetchProgress(command), time);
  }

  useEffect(() => {
    // Add new updaters
    commandsInProgress.items.forEach(command => {
      if (InProgressStatuses.includes(command.status)) {
        scheduleFetchProgress(command);
      }
    });

    if (!isModalVisible || tabValue !== 'in_progress') {
      // Cleanup updaters for previously processed items
      commandsInProgress.items.forEach(command => {
        const timeout = updateInfo.get(command)?.timeout;
        if (timeout) {
          clearTimeout(timeout);
        }
      });
    }

    return () => {
      // Cleanup updaters for previously processed items
      commandsInProgress.items.forEach(command => {
        const timeout = updateInfo.get(command)?.timeout;
        if (timeout) {
          clearTimeout(timeout);
        }
      });
    };
  }, [commandsInProgress, isModalVisible]);

  async function fetchCommands(status: 'in_progress' | 'done', cursor: string | null) {
    // fetchCommands to run. This check ensures we only make team-wide calls when we're allowed to.
    if (!isOwnerOrAdminOrSystemAdmin(authInfo)) return;
    if (cursor !== 'initial' && meteringCurateDataVolume.maxQuantity <= 0) return;

    const setter = status === 'in_progress' ? setCommandsInProgress : setCommandsDone;
    const previous =
      status === 'in_progress' ? _commandsInProgress.current! : _commandsDone.current!;

    if (cursor === null) {
      setter({
        ...previous,
        isLoading: false,
        hasMore: false,
      });
    } else {
      setter({
        ...previous,
        isLoading: true,
      });
    }

    const params = {
      created_by: processedFilters.onlyMy ? `${authInfo.email}` : undefined,
      status_in: status === 'in_progress' ? InProgressStatuses : DoneStatuses,
      cursor: cursor === 'initial' ? null : cursor,
    };

    const result = await getJobs({ params });
    try {
      const newIds = result.results.map((item: any) => item.id);
      const previousDeduplicated = previous.items.filter(item => !newIds.includes(item.id));
      const next = [...(cursor === 'initial' ? [] : previousDeduplicated), ...result.results];
      Object.assign(previous, {
        items: next,
        hasMore: !!result.nextCursor,
        isLoading: !!result.nextCursor,
        cursor: result.nextCursor,
      });
    } catch (_) {
      Object.assign(previous, {
        hasMore: false,
        isLoading: false,
        cursor: null,
        shouldFetchCommand: false,
      });
    } finally {
      setter(previous);
    }
  }

  function fetchMoreCommands(status: 'in_progress' | 'done') {
    const previous =
      status === 'in_progress' ? _commandsInProgress.current! : _commandsDone.current!;
    return fetchCommands(status, previous.cursor);
  }

  async function cancelCommand<T extends CommandType>(command: Command<T>) {
    try {
      await cancelJob({ jobId: command.id });
      scheduleFetchProgress(command, true);
    } catch (e: any) {
      const msg =
        `${e.message}` === 'Duplicated'
          ? t('curate.bulkActions.errorMessages.cancelDuplicated')
          : t('curate.bulkActions.errorMessages.cancelFailed');
      enqueueSnackbar(msg, {
        variant: 'warning',
        action: (
          <Button variant="text" color="white" onClick={() => openChatWidget()}>
            {t('text.contactUs')}
          </Button>
        ),
      });
    }
  }

  async function retryCommand<T extends CommandType>(command: Command<T>) {
    try {
      await retryJob({ jobId: command.id });
      scheduleFetchProgress(command, true);
    } catch (e: any) {
      const msg =
        `${e.message}` === 'Duplicated'
          ? t('curate.bulkActions.errorMessages.retryDuplicated')
          : t('curate.bulkActions.errorMessages.retryFailed');
      enqueueSnackbar(msg, {
        variant: 'warning',
        action: (
          <Button variant="text" color="white" onClick={() => openChatWidget()}>
            {t('text.contactUs')}
          </Button>
        ),
      });
    }
  }

  useEffect(() => {
    fetchCommands('in_progress', 'initial');
  }, []);

  return (
    <CurateCommandContext.Provider
      value={{
        commandsInProgress,
        commandsDone,
        fetchCommands,
        fetchMoreCommands,
        registerCommand,
        cancelCommand,
        retryCommand,
        filters,
        processedFilters,
        setFilters,
        isModalVisible,
        setIsModalVisible,
        isArchiveVisible,
        setIsArchiveVisible,
        tabValue,
        setTabValue,
        previousCommandsInProgress,
      }}
    >
      {children}
    </CurateCommandContext.Provider>
  );
};

export const useCurateCommandContext = (): CurateCommandContext => {
  return React.useContext(CurateCommandContext);
};
