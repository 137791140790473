import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Button, Collapse, Icon } from '@superb-ai/norwegian-forest';
import { Chip, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import qs from 'qs';

import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { formatDate, formatDateTime, parseDate } from '../../../../../utils/date';
import {
  allFilters as labelFilters,
  translateLokiCondition2CivetCondition,
} from '../../../../../utils/filter/labelFilter';
import { getTranslatedOption } from '../../../../../utils/filter/useOptions';
import FilterUtils from '../../../../../utils/FilterUtils';
import ParamUtils from '../../../../../utils/ParamUtils';
import ServiceUtils from '../../../../../utils/ServiceUtils';

const FilterDetail: React.FC<{ filter: string; showApplyButton?: boolean }> = ({
  filter,
  showApplyButton = true,
}) => {
  const { t, i18n } = useTranslation();
  const routeInfo = useRouteInfo();
  const projectInfo = useProjectInfo();
  const { tagIds, categorizationIdToName, flatAnnoOptions } = projectInfo;
  const { projectId, accountName } = useParams<{ projectId: string; accountName: string }>();

  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  const filters = ParamUtils.getFiltersFromApiParams(
    Object.fromEntries(
      Object.entries(paramsObj).map(([key, value]) => [
        translateLokiCondition2CivetCondition(key),
        value,
      ]),
    ),
    tagIds,
  );
  const urlParams = ParamUtils.getUrlParamsForFilters(filters);

  const selectedLabels = (filters.find(f => f.filterBy === 'labelId')?.options ?? []).map(
    ({ value }: any) => value,
  ) as string[];
  const hasNoFiltersAndSearch = filter == '';

  const [showAllLabelIds, setShowAllLabelIds] = useState(false);

  const handleClickFilterSearch = () => {
    if (hasNoFiltersAndSearch) return;
    routeInfo.history.push(
      `/${accountName}/label/project/${projectId}/labels?${new URLSearchParams(
        urlParams,
      ).toString()}`,
    );
  };

  const getSelectedLabelsList = () => {
    if (selectedLabels.length === 1) {
      return <Typography variant="m-regular">{selectedLabels}</Typography>;
    }
    return (
      <>
        <Box
          className="clickable"
          display="flex"
          alignItems="center"
          gap="4px"
          onClick={() => setShowAllLabelIds(!showAllLabelIds)}
        >
          <Typography variant="m-regular">{selectedLabels[0]}</Typography>{' '}
          <Icon name="caretDown" size="13px" />
        </Box>
        <Collapse open={showAllLabelIds}>
          <Box style={{ marginTop: '-8px' }}>
            {selectedLabels.slice(1).map(label => (
              <Typography variant="m-regular" display="block" key={label}>
                {label}
              </Typography>
            ))}
          </Box>
        </Collapse>
      </>
    );
  };

  if (filter === '') return <></>;

  const badgeProps = {
    backgroundColor: 'cloud-100',
  } as const;

  return (
    <Box display="flex" width="100%">
      <Box mt={0.5} display="flex" flexDirection="column" gap="4px">
        {selectedLabels.length ? (
          <Box display="flex" alignItems="start" gap="8px">
            <Typography variant="m-strong">
              {t('analytics.exportsAnalytics.appliedFilter.labelId')}
            </Typography>
            <Box>{getSelectedLabelsList()}</Box>
          </Box>
        ) : (
          <>
            {filters.map(filter => {
              const options = filter.options as any[];
              const shouldTranslate = Boolean(labelFilters[filter.filterBy]?.options?.length);
              const translatedOptions = options.map(option => {
                const translatedOption = !shouldTranslate
                  ? option
                  : getTranslatedOption({
                      option,
                      i18n,
                      i18nKeyPrefix: `labels.filters.option.${filter.filterBy}`,
                    });
                return (
                  <Chip {...badgeProps} key={option.value}>
                    {translatedOption.label}
                  </Chip>
                );
              });

              return (
                <Box key={filter.filterBy} display="flex" gap="6px" alignItems="center">
                  <Typography variant="m-strong">
                    {t(`labels.filters.filterBy.${filter.filterBy}`, startCase(filter.filterBy))}
                  </Typography>
                  <Typography variant="m-regular">
                    {t(`labels.filters.condition.${filter.condition}`, filter.condition)}
                  </Typography>
                  {options && (
                    <>
                      {options[0]?.date && (
                        <Chip {...badgeProps}>{formatDate(parseDate(options[0].date, true))}</Chip>
                      )}
                      {options[0]?.sdate && options[1]?.edate && (
                        <>
                          <Chip {...badgeProps}>
                            {formatDateTime(parseDate(options[0].sdate, true))}
                          </Chip>
                          {' - '}
                          <Chip {...badgeProps}>
                            {formatDateTime(parseDate(options[1].edate, true))}
                          </Chip>
                        </>
                      )}
                      {options[0]?.value && translatedOptions}
                      {filter.filterBy === 'annotation' && (
                        <Chip {...badgeProps}>
                          {FilterUtils.getAnnoFilterCount(
                            options,
                            categorizationIdToName,
                            flatAnnoOptions,
                          )}
                        </Chip>
                      )}
                    </>
                  )}
                </Box>
              );
            })}
          </>
        )}
      </Box>
      {showApplyButton && (
        <Box ml="auto">
          <Button
            IconAdornment="filter"
            color="grey"
            variant="soft-fill"
            size="s"
            onClick={handleClickFilterSearch}
          >
            {t('export.history.button.applyFiltersToLabelList')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FilterDetail;
