import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreHorizontal } from '@superb-ai/icons';
import {
  DropdownMenu,
  DropdownMenuItem,
  StatusChip,
  TextEllipsis,
  Typography,
} from '@superb-ai/norwegian-forest';
import { Box, IconButton } from '@superb-ai/ui';

import { SourceIntegrationRes } from '../../../../../types/integrationTypes';
import { formatDate } from '../../../../../utils/date';

type Props = {
  integration: SourceIntegrationRes;
  handleDelete: () => void;
  editButtonTitle?: string;
  handleEdit: () => void;
  children: ReactNode;
};

const IntegrationListRowStandardLayout = ({
  children,
  integration,
  handleDelete,
  editButtonTitle,
  handleEdit,
}: Props) => {
  const {
    createdAt,
    createdBy,
    alias,
    info: { statusCheck },
  } = integration;
  const { t } = useTranslation();
  const status = statusCheck === 'success' ? 'Connected' : 'Failed';
  const chipStatus = statusCheck === 'fail' ? 'failed' : 'success';

  return (
    <>
      <Box as="td" px={1}>
        <StatusChip color={chipStatus}>{status}</StatusChip>
      </Box>
      <Box as="td" px={1}>
        <Typography variant="body3">
          <TextEllipsis text={alias} userSelect="all" />
        </Typography>
      </Box>
      {children}
      <Box as="td" px={1}>
        <Typography variant="body3">
          <TextEllipsis text={formatDate(createdAt)} userSelect="all" />
        </Typography>
      </Box>
      <Box as="td" px={1}>
        <Typography variant="body3">
          <TextEllipsis text={createdBy} userSelect="all" />
        </Typography>
      </Box>
      <Box as="td" pl={1} pb={2}>
        <DropdownMenu
          AnchorElement={<IconButton icon={MoreHorizontal} variant="text" />}
          placement="bottom-end"
        >
          <DropdownMenuItem onClick={handleEdit}>
            {editButtonTitle || t('integrations.editAuthentication')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDelete}>{t('button.remove')}</DropdownMenuItem>
        </DropdownMenu>
      </Box>
    </>
  );
};

export default IntegrationListRowStandardLayout;
