import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { startCase } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import NotificationsContext from '../../../../contexts/NotificationsContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useCurrentPlan } from '../../../../queries/useSubscriptionQuery';
import NotificationsService from '../../../../services/NotificationsService';
import { formatDistance } from '../../../../utils/date';
import NotificationsUtils from '../../../../utils/NotificationsUtils';
import getContents from '../../../pages/account/integrations/contents';
import { TargetType } from '../../../pages/account/integrations/types';
import { UploadNotificationObject } from './types';

const useStyles = makeStyles(() => ({
  subType: {
    borderRadius: '7.5px',
    padding: '3px 8px',
    fontSize: '10px',
    marginLeft: '4px',
    '&.started': {
      color: '#a6a6a6',
      background: '#f4f4f4',
    },
    '&.succeed': {
      color: '#a3eb57',
      background: '#EDFBDD',
    },
    '&.failed': {
      color: '#ffcc00',
      background: '#fff8e0',
    },
    '&.cancelled': {
      color: '#ff625a',
      background: '#ffeceb',
    },
  },
}));

const getDataUnitDisplayNamePlural = (assetType: string, uploadFailFilesNum: number) => {
  if (assetType === 'img-presigned-url') {
    // image
    return 'images';
  }
  if (uploadFailFilesNum !== -1) {
    // image sequence
    return 'frames';
  }
  // video
  return 'videos';
};

interface Props {
  parentClasses: Record<string, string>;
  notification: UploadNotificationObject;
  accountName: string;
}

type ErrorType =
  | 'DUPLICATED'
  | 'UNKNOWN'
  | 'UNKNOWN_LABEL'
  | 'LIMIT'
  | 'SIZE'
  | 'FORMAT'
  | 'RESOLUTION'
  | 'DUPLICATED_LABEL';

const Notification: React.FC<Props> = ({ parentClasses, notification, accountName }) => {
  const { t, i18n } = useTranslation();
  const {
    id,
    // type, // mlimb - currently always `upload`
    checked,
    createdAt,
    payload: {
      dataset,
      projectId,

      uploadFilesNum,
      uploadFailFilesNum,
      uploadFailFilesReasons = [],

      uploadVideosNum,
      uploadFailVideosNum,
      uploadFailVideosReasons = [],

      uploadSequencesNum,
      uploadFailSequencesNum,
      uploadFailSequencesReasons = [],

      uploadVideoStreamNum,
      uploadFailVideoStreamNum,
      uploadFailVideoStreamReasons = [],

      createLabelsNum,
      createFailLabelsNum,
      createFailLabelsReasons = [],
      sourceStorage: {
        type,
        totalFilesNum,
        totalVideosNum,
        totalSequenceNum,
        totalVideoStreamNum,
        selectedFolderKey,
        assetType,
        assetSaveMethod,
        // integrationAlias,
        // integrationId,
        bucket,
      },
    },
  } = notification;

  const totalNums = [totalFilesNum, totalVideosNum, totalSequenceNum, totalVideoStreamNum];
  const multiTotalNums = [totalVideosNum, totalSequenceNum];
  const singleTotalNums = [totalFilesNum, totalVideoStreamNum];

  const hasZeroLabels = totalNums.every(num => num === 0);

  const uploadedNums = [uploadFilesNum, uploadVideosNum, uploadSequencesNum, uploadVideoStreamNum];
  const multiUploadedNums = [uploadVideosNum, uploadSequencesNum];
  const singleUploadedNums = [uploadFilesNum, uploadVideoStreamNum];

  const failNums = [
    uploadFailFilesNum,
    uploadFailVideosNum,
    uploadFailSequencesNum,
    uploadFailVideoStreamNum,
  ];
  const multiFailNums = [uploadFailVideosNum, uploadFailSequencesNum];
  const singleFailNums = [uploadFailFilesNum, uploadFailVideoStreamNum];

  const hasSuccess = uploadedNums.some(num => num > 0);
  const hasFail = failNums.some(num => num > 0);
  const failReasons = [
    ...uploadFailFilesReasons,
    ...uploadFailVideosReasons,
    ...uploadFailSequencesReasons,
    ...uploadFailVideoStreamReasons,
    ...createFailLabelsReasons,
  ];
  const isMultiAsset = ['video-presigned-url', 'pointclouds-presigned-url'].includes(assetType);

  // const storageType = notification?.payload?.sourceStorage?.type;
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const notificationsInfo = React.useContext(NotificationsContext.Context);
  const { unreadCount, setUnreadCount } = notificationsInfo;

  const history = useHistory();
  const currentPlan = useCurrentPlan();

  const fileLimit = currentPlan === 'FREE' ? 1 : 20;
  const videoFileLimit = 100;

  const dataUnitDisplayNamePlural = getDataUnitDisplayNamePlural(assetType, uploadFailFilesNum);
  const assetTypeDisplayName = {
    'img-presigned-url': t('notifications.displayName.images'),
    'video-presigned-url': t('notifications.displayName.videos'),
    'pointclouds-presigned-url': t('notifications.displayName.pointcloudSequences'),
    'videostream-presigned-url': t('notifications.displayName.videos'),
  }[assetType];
  const assetTypeDisplayNameSingular = {
    'img-presigned-url': t('projects.projectTypes.image'),
    'video-presigned-url': t('projects.projectTypes.video'),
    'pointclouds-presigned-url': t('notifications.displayName.pointcloudSequence'),
    'videostream-presigned-url': t('projects.projectTypes.video'),
  }[assetType];

  const handleClick =
    (from = '') =>
    async () => {
      if (!checked) {
        await NotificationsService.readNotification({
          ids: [id],
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });

        // eslint-disable-next-line
        notification.checked = true;
        setUnreadCount(unreadCount - 1);
      }

      if (from === 'failed_upload') {
        if (projectId) {
          history.push(`/${accountName}/project/${projectId}/labels#upload`);
          return;
        }
        history.push(`/${accountName}/data#upload`);
        return;
      }
      if (projectId) {
        history.push(`/${accountName}/project/${projectId}/labels`);
        return;
      }
      history.push(
        `/${accountName}/data?${new URLSearchParams({
          dataset,
          project: '[ all projects ]',
        }).toString()}`,
      );
    };

  const getFailMessage = (message: string) => {
    const uploadLimitDetail =
      assetType === 'img-presigned-url' ? `${fileLimit}MB` : `${videoFileLimit}MB, 5min`;
    return {
      DUPLICATED: t('notifications.failedMessage.duplicated'),
      UNKNOWN: t('notifications.failedMessage.unknown'),
      UNKNOWN_LABEL: t('notifications.failedMessage.unknown'),
      LIMIT: t('notifications.failedMessage.limit'),
      SIZE: t('notifications.failedMessage.size', { uploadLimitDetail }),
      FORMAT: t('notifications.failedMessage.format'),
      RESOLUTION: t('notifications.failedMessage.resolution'),
      DUPLICATED_LABEL: t('notifications.failedMessage.duplicatedLabel'),
    }[message as ErrorType];
  };

  const successContent = isMultiAsset ? (
    <>
      <span className={`${parentClasses.messageText} bold`}>{multiUploadedNums.find(n => n)}</span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>{multiTotalNums.find(n => n)}</span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}>
        {multiTotalNums.find(n => n) === 1 ? assetTypeDisplayNameSingular : assetTypeDisplayName}{' '}
      </span>
      <span className={`${parentClasses.messageText} bold`}>({uploadFilesNum}</span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>{totalFilesNum}</span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}> {t('notifications.frames')})</span>
    </>
  ) : (
    <>
      <span className={`${parentClasses.messageText} bold`}>{singleUploadedNums.find(n => n)}</span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>
            {singleTotalNums.find(n => n)}
          </span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}>
        {' '}
        {singleTotalNums.find(n => n) === 1 ? assetTypeDisplayNameSingular : assetTypeDisplayName}
      </span>
    </>
  );

  const failContent = isMultiAsset ? (
    <>
      <span className={`${parentClasses.messageText} bold`}>{multiFailNums.find(n => n)}</span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>{multiTotalNums.find(n => n)}</span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}>
        {multiTotalNums.find(n => n) === 1 ? assetTypeDisplayNameSingular : assetTypeDisplayName}{' '}
      </span>
      <span className={`${parentClasses.messageText} bold`}>({uploadFailFilesNum}</span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>{totalFilesNum}</span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}> {t('notifications.frames')})</span>
    </>
  ) : (
    <>
      <span className={`${parentClasses.messageText} bold`}>{singleFailNums.find(n => n)} </span>
      {hasFail && (
        <>
          <span className={`${parentClasses.messageText} bold`}> / </span>
          <span className={`${parentClasses.messageText} bold`}>
            {singleTotalNums.find(n => n)}
          </span>
        </>
      )}
      <span className={`${parentClasses.messageText} bold`}>
        {' '}
        {singleTotalNums.find(n => n) === 1 ? assetTypeDisplayNameSingular : assetTypeDisplayName}
      </span>
    </>
  );

  const getAssignFailContent = () => {
    if (!createFailLabelsNum || createFailLabelsNum <= 0) return <></>;
    return (
      <MUI.Box className={parentClasses.messageBox}>
        <MUI.Typography className={`${parentClasses.messageText} bold`}>
          {t('notifications.assignFailedContent', {
            createFailedLabelsCount: createFailLabelsNum,
            createLabelsCount: createLabelsNum + createFailLabelsNum,
          })}
        </MUI.Typography>
        <MUI.Box className={`${classes.subType} failed`}>
          {startCase(NotificationsUtils.convertExportArchiveSubType('failed'))}
        </MUI.Box>
      </MUI.Box>
    );
  };

  const getFailReason = () => {
    if (
      (isMultiAsset && multiTotalNums.every(num => num === 0)) ||
      (!isMultiAsset && singleTotalNums.every(num => num === 0))
    ) {
      return (
        <MUI.Box className={parentClasses.messageBox}>
          <MUI.Typography className={parentClasses.messageText}>
            {t('notifications.storageWasEmpty')}
          </MUI.Typography>
        </MUI.Box>
      );
    }

    return (
      <>
        {failReasons.map(
          failedReason =>
            failedReason.count > 0 && (
              <MUI.Box key={failedReason.reason} className={parentClasses.messageBox}>
                <MUI.Typography className={parentClasses.messageText}>
                  ∙ {getFailMessage(failedReason.reason)}:{' '}
                </MUI.Typography>
                <MUI.Typography className={`${parentClasses.messageText} bold`}>
                  {failedReason.count} {dataUnitDisplayNamePlural}
                </MUI.Typography>
              </MUI.Box>
            ),
        )}
      </>
    );
  };

  const getAssetCopySuccess = () => {
    return (
      <>
        <MUI.Box className={parentClasses.box} onClick={handleClick()}>
          <MUI.Box className={parentClasses.left}>
            <MUI.Typography className={parentClasses.emoji}>
              <span role="img" aria-label="emoji">
                📤
              </span>
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.center}>
            <MUI.Box className={parentClasses.messageBox}>
              <MUI.Typography className={parentClasses.messageText}>
                <Trans
                  i18nKey="notifications.sucesssUpload"
                  components={{
                    successContent,
                  }}
                  values={{ bucketPath: `${bucket}/${selectedFolderKey}` }}
                />
                <span className={`${classes.subType} succeed`}>
                  {t('labels.autoLabelRequest.complete')}
                </span>
              </MUI.Typography>
            </MUI.Box>
            <MUI.Typography className={parentClasses.fromNowText}>
              {formatDistance(createdAt)}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.right}>
            {!checked && (
              <MUI.Box className={parentClasses.newTextBox}>
                <MUI.Box className={parentClasses.circle} />
                <MUI.Typography className={parentClasses.newText}>
                  {t('notifications.new')}
                </MUI.Typography>
              </MUI.Box>
            )}
          </MUI.Box>
        </MUI.Box>
      </>
    );
  };

  const getAssetCopyFail = () => {
    return (
      <>
        <MUI.Box className={parentClasses.box} onClick={handleClick('failed_upload')}>
          <MUI.Box className={parentClasses.left}>
            <MUI.Typography className={parentClasses.emoji}>
              <span role="img" aria-label="emoji">
                ❗️
              </span>
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.center}>
            <MUI.Box className={parentClasses.messageBox}>
              <MUI.Typography className={`${parentClasses.messageText} bold`}>
                <Trans
                  i18nKey="notifications.uploadFailed"
                  components={{
                    failedContent: failContent,
                  }}
                />
                <span className={`${classes.subType} failed`}>
                  {t('autoLabel.cal.status.failed')}
                </span>
              </MUI.Typography>
            </MUI.Box>
            {getAssignFailContent()}
            {getFailReason()}
            <MUI.Typography className={parentClasses.fromNowText}>
              {formatDistance(createdAt)}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.right}>
            {!checked && (
              <MUI.Box className={parentClasses.newTextBox}>
                <MUI.Box className={parentClasses.circle} />
                <MUI.Typography className={parentClasses.newText}>
                  {t('notifications.new')}
                </MUI.Typography>
              </MUI.Box>
            )}
          </MUI.Box>
        </MUI.Box>
      </>
    );
  };

  const getAssetLinkSuccess = () => {
    return (
      <>
        <MUI.Box className={parentClasses.box} onClick={handleClick()}>
          <MUI.Box className={parentClasses.left}>
            <MUI.Typography className={parentClasses.emoji}>
              <div style={{ width: '24px', marginLeft: '-4px' }}>
                {getContents(t, i18n.language)[type.toUpperCase() as TargetType].logo}
              </div>
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.center}>
            <MUI.Box className={parentClasses.messageBox}>
              <MUI.Typography className={parentClasses.messageText}>
                <Trans
                  i18nKey="notifications.successAssetLink"
                  components={{
                    bold: <span className={`${parentClasses.messageText} bold`} />,
                    successContent,
                  }}
                  values={{ bucket, dataset }}
                />
                <span className={`${classes.subType} succeed`}>
                  {t('labels.autoLabelRequest.complete')}
                </span>
              </MUI.Typography>
            </MUI.Box>
            <MUI.Typography className={parentClasses.fromNowText}>
              {formatDistance(createdAt)}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.right}>
            {!checked && (
              <MUI.Box className={parentClasses.newTextBox}>
                <MUI.Box className={parentClasses.circle} />
                <MUI.Typography className={parentClasses.newText}>
                  {t('notifications.new')}
                </MUI.Typography>
              </MUI.Box>
            )}
          </MUI.Box>
        </MUI.Box>
      </>
    );
  };

  const getAssetLinkFail = () => {
    return (
      <>
        <MUI.Box className={parentClasses.box} onClick={handleClick('failed_upload')}>
          <MUI.Box className={parentClasses.left}>
            <MUI.Typography className={parentClasses.emoji}>
              <span role="img" aria-label="emoji">
                ❗️
              </span>
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.center}>
            <MUI.Box className={parentClasses.messageBox}>
              <MUI.Typography className={parentClasses.messageText}>
                <Trans
                  i18nKey="notifications.assetLinkFailed"
                  components={{
                    bold: <span className={`${parentClasses.messageText} bold`} />,
                    failedContent: failContent,
                  }}
                  values={{ bucket, dataset }}
                />
                <span className={`${classes.subType} failed`}>
                  {t('autoLabel.cal.status.failed')}
                </span>
              </MUI.Typography>
            </MUI.Box>
            {getFailReason()}
            <MUI.Typography className={parentClasses.fromNowText}>
              {formatDistance(createdAt)}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.right}>
            {!checked && (
              <MUI.Box className={parentClasses.newTextBox}>
                <MUI.Box className={parentClasses.circle} />
                <MUI.Typography className={parentClasses.newText}>
                  {t('notifications.new')}
                </MUI.Typography>
              </MUI.Box>
            )}
          </MUI.Box>
        </MUI.Box>
      </>
    );
  };

  const getZeroLabelInfo = () => {
    return (
      <>
        <MUI.Box className={parentClasses.box} onClick={handleClick()}>
          <MUI.Box className={parentClasses.left}>
            <MUI.Typography className={parentClasses.emoji}>
              <span role="img" aria-label="emoji">
                📤
              </span>
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.center}>
            <MUI.Box className={parentClasses.messageBox}>
              <MUI.Typography className={parentClasses.messageText}>
                <Trans
                  i18nKey="notifications.zeroLabelInfo"
                  components={{
                    successContent,
                  }}
                  values={{ bucket: `${bucket}/${selectedFolderKey}` }}
                />
                <span className={`${classes.subType} cancelled`}>
                  {t('autoLabel.cal.status.canceled')}
                </span>
              </MUI.Typography>
            </MUI.Box>
            <MUI.Typography className={parentClasses.fromNowText}>
              {formatDistance(createdAt)}
            </MUI.Typography>
          </MUI.Box>
          <MUI.Box className={parentClasses.right}>
            {!checked && (
              <MUI.Box className={parentClasses.newTextBox}>
                <MUI.Box className={parentClasses.circle} />
                <MUI.Typography className={parentClasses.newText}>
                  {t('notifications.new')}
                </MUI.Typography>
              </MUI.Box>
            )}
          </MUI.Box>
        </MUI.Box>
      </>
    );
  };

  return (
    <>
      {hasSuccess && assetSaveMethod !== 'link' && getAssetCopySuccess()}
      {hasFail && assetSaveMethod !== 'link' && getAssetCopyFail()}
      {hasSuccess && assetSaveMethod === 'link' && getAssetLinkSuccess()}
      {hasFail && assetSaveMethod === 'link' && getAssetLinkFail()}
      {hasZeroLabels && getZeroLabelInfo()}
    </>
  );
};

export default Notification;
