import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { InfoFilled } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  extendComponent,
  Icon,
  Input,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { Row } from '../../../../components/elements/Row';
import { MODEL_ENDPOINT_MAXIMUM_ERROR } from '../../../../consts/SnackbarMessage';
import { getUrl } from '../../../../routes/util';
import RegexUtils from '../../../../utils/RegexUtils';
import { SchedulePause } from '../../components/components';
import {
  ENDPOINT_DEPLOYMENT_CREDIT,
  MAXIMUM_ENDPOINT_NAME_LENGTH,
  MINIMUM_ENDPOINT_NAME_LENGTH,
} from '../../constant';
import {
  useCheckEndpointNameUniqueQuery,
  useCreateEndpointMutation,
  usePublicModelQuery,
} from '../../queries/modelQueries';
import { EndpointScheduling } from '../../services/types';

export const CreateEndpointDialog = ({
  id,
  publicModelId,
  modelName,
  state,
}: {
  id: string;
  publicModelId: string;
  modelName: string;
  state: ReturnType<typeof useDialogState>;
}) => {
  const { t } = useTranslation();
  const { mutate } = useCreateEndpointMutation();
  const [uniqueNameFilter, setUniqueNameFilter] = useState('');
  const { data: _data } = useCheckEndpointNameUniqueQuery({ name: uniqueNameFilter });
  const isEndpointNameUnique = (_data?.pages.flatMap(p => p.endpointList) ?? []).length === 0;
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useRouteMatch<{ accountName: string }>();
  const { data: publicModelData } = usePublicModelQuery({ id: publicModelId });
  // 배열인 이유 복수의 Scheduling이 가능해질 수 있음
  const [schedulingTimeList, setSchedulingTimeList] = useState<EndpointScheduling[]>([]);
  const [open, setOpen] = useState(false);
  const invalideNameLength =
    uniqueNameFilter.length > MAXIMUM_ENDPOINT_NAME_LENGTH ||
    uniqueNameFilter.length < MINIMUM_ENDPOINT_NAME_LENGTH;

  return (
    <Dialog state={state} hideOnClickOutside={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.endpoints.createEndpoint')}
      </Dialog.Header>
      <NotiMessage
        text={
          <Typography variant={'m-regular'} color="secondary-400">
            <Trans
              t={t}
              i18nKey={'model.endpoints.endpointDepolymentCredit'}
              values={{ credit: ENDPOINT_DEPLOYMENT_CREDIT }}
            />
          </Typography>
        }
      />
      <Box backgroundColor={'gray-100'} style={{ padding: '16px 20px' }}>
        <Box display="flex" alignItems="flex-start" mb={1}>
          <Box style={{ width: 64 }}>
            <Typography variant="m-regular" color={'gray-300'}>
              {t('shared.name')}
            </Typography>
          </Box>
          <Name variant="m-medium">{modelName}</Name>
        </Box>
        <Row>
          <Box style={{ width: 64 }}>
            <Typography variant="m-regular" color={'gray-300'}>
              {t('model.myModels.baseline')}
            </Typography>
          </Box>
          <Name variant="m-regular">{publicModelData?.data.name}</Name>
        </Row>
      </Box>
      <Typography variant="m-strong" style={{ marginBottom: -8 }}>
        {t('shared.name')}
      </Typography>
      <NameInput
        uniqueNameFilter={uniqueNameFilter}
        setUniqueNameFilter={setUniqueNameFilter}
        isEndpointNameUnique={isEndpointNameUnique}
        invalideNameLength={invalideNameLength}
      />
      <SchedulePause
        open={open}
        setOpen={setOpen}
        schedulingTimeList={schedulingTimeList}
        setSchedulingTimeList={setSchedulingTimeList}
      />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          disabled={
            !uniqueNameFilter ||
            invalideNameLength ||
            !isEndpointNameUnique ||
            RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter)
          }
          onClick={() => {
            mutate(
              {
                modelId: id,
                endpointName: uniqueNameFilter,
                // open을 SchedulePause 내부에서 관리하지 않는 이유:
                // SchedulePause를 호출한 Dialog에서 open을 참조해야하기 때문.
                // schdulingTimeList이 빈 배열이 아니더라도 open===false라면 schduleing하지 않은 상태로 endpoint를 만들어야함
                endpointScheduling: open ? schedulingTimeList : [],
              },
              {
                onSuccess: () => {
                  enqueueSnackbar(
                    <>
                      <Typography variant="m-regular" mr={1}>
                        {t('model.myModels.createEndpoint.content')}
                      </Typography>
                      <LinkTypography
                        variant="m-regular"
                        as="a"
                        href={getUrl([params.accountName, 'model', 'endpoints'], {}, {})}
                      >
                        {t('model.myModels.createEndpoint.link')}
                      </LinkTypography>
                    </>,
                    {
                      variant: 'success',
                    },
                  );
                  state.setVisible(false);
                },
                onError: () => {
                  enqueueSnackbar(MODEL_ENDPOINT_MAXIMUM_ERROR({ t }), {
                    variant: 'error',
                  });
                  state.setVisible(false);
                },
              },
            );
          }}
          color="primary"
          variant="strong-fill"
        >
          {t('button.create')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const NameInput = ({
  uniqueNameFilter,
  setUniqueNameFilter,
  isEndpointNameUnique,
  invalideNameLength,
}: {
  uniqueNameFilter: string;
  setUniqueNameFilter: Dispatch<SetStateAction<string>>;
  isEndpointNameUnique: boolean;
  invalideNameLength: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        value={uniqueNameFilter}
        onChange={e => setUniqueNameFilter(e.target.value)}
        placeholder={t('model.myModels.placeholder')}
      />
      {!isEndpointNameUnique && (
        <Typography variant="s-regular" color={'red-400'} style={{ marginTop: -12 }}>
          {t('model.train.uniqueEndpointName')}
        </Typography>
      )}
      <Row style={{ marginTop: -12 }}>
        <Box>
          {invalideNameLength && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                <Trans
                  t={t}
                  i18nKey={'model.endpoints.maximumNameLength'}
                  values={{ min: MINIMUM_ENDPOINT_NAME_LENGTH, max: MAXIMUM_ENDPOINT_NAME_LENGTH }}
                />
              </Typography>
            </Box>
          )}
          {RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter) && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                {t('model.endpoints.notIncludeSpecialCharacter')}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          variant="s-regular"
          color={invalideNameLength ? 'red-400' : undefined}
          ml={'auto'}
          mb={'auto'}
        >
          {`${uniqueNameFilter.length} / ${MAXIMUM_ENDPOINT_NAME_LENGTH}`}
        </Typography>
      </Row>
    </>
  );
};

const Name = extendComponent(Typography, {
  color: 'gray-400',
  style: { wordBreak: 'break-all', width: 'calc(100% - 64px)' },
});

const NotiMessage = ({ text }: { text: string | ReactNode }) => {
  return (
    <Row px={1.5} backgroundColor={'secondary-100'} gap={0.5} style={{ height: 34 }}>
      <Icon icon={InfoFilled} color={'secondary-400'} />
      {typeof text === 'string' ? (
        <Typography variant="m-regular" color={'secondary-400'}>
          {text}
        </Typography>
      ) : (
        text
      )}
    </Row>
  );
};
