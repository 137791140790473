import { RadioButtons } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';

interface Option<V extends string = string> {
  Label: () => JSX.Element;
  value: V;
  disabled?: boolean;
}
import {
  CURATE_WHAT_TO_LABEL,
  CurationType,
  FIND_MISLABELS,
  SPLIT_TRAIN_VAL,
} from '../../../../../../../../consts/AutoCurateConst';
import { curateMethodObject } from '../../UploadModal/steps/config';

export default function SamplingMethodSteps({
  samplingMethod,
  handleChangeSamplingMethod,
  labeledDataCount,
}: {
  samplingMethod: CurationType;
  handleChangeSamplingMethod: (value: CurationType) => void;
  labeledDataCount: number;
}): JSX.Element {
  const options = (Object.values(curateMethodObject) as Option<CurationType>[]).map(option => {
    if (labeledDataCount === 0) {
      if (option.value === SPLIT_TRAIN_VAL || option.value === FIND_MISLABELS) {
        const newLabel = option.Label();
        return { ...option, label: newLabel, disabled: true };
      }
    }
    const newLabel = option.Label();
    return { ...option, label: newLabel };
  });
  return (
    <Box>
      <RadioButtons
        options={options}
        value={samplingMethod || CURATE_WHAT_TO_LABEL}
        onChange={handleChangeSamplingMethod}
      />
    </Box>
  );
}
