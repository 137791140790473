import React from 'react';
import { useParams } from 'react-router';

import { useEmbeddingStatusQuery } from '../queries/embeddingStatusQueries';

// prettier-ignore
type EmbeddingStatus = {
  stage: 'embed' | 'clustering' | 'cluster_assign' | 'done';
  state: 'pending' | 'in_progress' | 'finished';
  remainingTime?: number;
  progress: number;
};

type ContextProps = {
  imageTotalCount: number;
  objectTotalCount: number;
  canUseEmbeddingFeatures: boolean;
  datasetEmbeddingStatus?: EmbeddingStatus;
};

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();

  const datasetEmbeddingStatusQuery = useEmbeddingStatusQuery({ datasetId });
  const datasetEmbeddingStatus = datasetEmbeddingStatusQuery.data?.embeddingStatus;
  const imageTotalCount = datasetEmbeddingStatusQuery.data?.imageTotalCount;
  const objectTotalCount = datasetEmbeddingStatusQuery.data?.objectTotalCount;
  const imageProcessingCount = datasetEmbeddingStatusQuery.data?.imageProcessingCount;
  const objectProcessingCount = datasetEmbeddingStatusQuery.data?.objectProcessingCount;
  const imageSyntheticCount = datasetEmbeddingStatusQuery.data?.imageProcessingCount;
  const objectSyntheticCount = datasetEmbeddingStatusQuery.data?.objectProcessingCount;

  const canUseEmbeddingFeatures =
    imageTotalCount * 0.95 <= imageProcessingCount + imageSyntheticCount &&
    objectTotalCount * 0.95 <= objectProcessingCount + objectSyntheticCount;

  return {
    datasetEmbeddingStatus,
    canUseEmbeddingFeatures,
    imageTotalCount: imageTotalCount || 0,
    objectTotalCount: objectTotalCount || 0,
  };
};

export const useEmbeddingMonitoringContext = (): ContextProps => {
  return React.useContext(Context);
};

export const EmbeddingMonitoringProvider: React.FC = ({ children }) => {
  const datasetInfo = useProvider();
  return <Context.Provider value={datasetInfo}>{children}</Context.Provider>;
};
