import { format } from 'date-fns';

export function formatDate(date: string) {
  return format(new Date(date), 'MMM d, yyyy, h:mm a');
}

/**
 * Makes sure number is a multiple of another number. If it isn't, returns the
 * next higher multiple.
 */
export function toMultipleOf(multiplier: number) {
  return (value: number) => Math.ceil(value / multiplier) * multiplier;
}
export const roundToMinutes = toMultipleOf(60);

export function convertSecondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.ceil((seconds % 3600) / 60);

  let result = '';
  if (hours > 0) {
    result += hours + 'h ';
  }
  if (minutes >= 0 || hours > 0) {
    result += minutes + 'm';
  }

  return result.trim();
}
