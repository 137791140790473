import {
  ChangeEvent,
  ComponentProps,
  CSSProperties,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Box as BoxIcon, Compare, GenAi, Polygon, Search } from '@superb-ai/icons';
import {
  Box,
  Chip,
  extendComponent,
  Icon,
  Input,
  ToggleButton,
  Tooltip,
  Typography,
} from '@superb-ai/ui';
import { isAfter, isBefore, isSameMinute } from 'date-fns';
import capitalize from 'lodash/capitalize';

import DatePicker from '../../../components/elements/datePicker/DatePicker';
import { Row } from '../../../components/elements/Row';
import {
  AnnotationType,
  EndpointScheduling,
  ModelStatus,
  PurposeType,
  TaskType,
} from '../services/types';

export const ActivateTooltip = ({
  children,
  activate,
  content,
  placement,
}: {
  children: ReactElement;
  activate: boolean;
  content: ComponentProps<typeof Tooltip>['content'];
  placement?: ComponentProps<typeof Tooltip>['placement'];
}) => {
  return activate ? (
    <Tooltip content={content} placement={placement}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export const SchedulePause = ({
  schedulingTimeList,
  setSchedulingTimeList,
  open,
  setOpen,
}: {
  schedulingTimeList: EndpointScheduling[];
  setSchedulingTimeList: Dispatch<SetStateAction<EndpointScheduling[]>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  function getNearestQuarterHour() {
    const now = new Date();
    const minutes = now.getMinutes();
    const closestQuarterHour = Math.ceil(minutes / 30) * 30;
    if (closestQuarterHour === 60) {
      now.setHours(now.getHours() + 1);
      now.setMinutes(0);
    } else {
      now.setMinutes(closestQuarterHour);
    }
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();
    const hours = now.getHours();
    const minutesResult = now.getMinutes();

    const nearestQuarterHourDate = new Date(year, month, day, hours, minutesResult);
    return nearestQuarterHourDate;
  }
  useEffect(() => {
    if (open && !(schedulingTimeList.length > 0)) {
      // 초기값이 없음에도 오픈했다면 가까운 시간 세팅
      setSchedulingTimeList([{ eventAt: getNearestQuarterHour().getTime(), type: 'pause' }]);
    }
  }, [open]);
  return (
    <Box backgroundColor={'gray-100'} borderRadius="2px" px={2.5} py={2}>
      <Row width="100%">
        <Typography variant="m-regular">{t('model.endpoints.createEndpointAutoPasue')}</Typography>
        <Row ml="auto">
          <ToggleButton size="xs" checked={open} onChange={() => setOpen(prev => !prev)} />
        </Row>
      </Row>
      {open && (
        <Row mt={1.5} border="1px solid" borderColor={'gray-150'} backgroundColor={'white'}>
          <DatePicker
            timeEnabled
            hideMinutes={minute => minute % 15 !== 0}
            cleanable={false}
            // @ts-ignore
            disabledDate={date => isBefore(date, new Date())}
            disabledMinutes={(minute, date) => {
              if (isAfter(date, new Date())) {
                return false;
              } else if (isAfter(date.getHours(), new Date().getHours())) {
                return false;
              } else {
                return (
                  isBefore(minute, new Date().getMinutes()) ||
                  isSameMinute(minute, new Date().getMinutes())
                );
              }
            }}
            value={
              schedulingTimeList.length > 0
                ? new Date(schedulingTimeList[0].eventAt)
                : getNearestQuarterHour()
            }
            style={{ width: '100%' }}
            onOk={date => setSchedulingTimeList([{ eventAt: date.getTime(), type: 'pause' }])}
          />
        </Row>
      )}
    </Box>
  );
};

export default function DropdownSearchInput({
  setSearchValue,
  placeholder,
}: {
  setSearchValue: (value: string) => void;
  placeholder?: string;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    setSearchValue('');
  }, []);

  return (
    <Box p={0.5} borderBottom="1px solid" borderColor="gray-100" display="flex">
      <Input
        type="search"
        variant="text"
        prefix={<Icon icon={Search} />}
        placeholder={placeholder || t('text.search')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(event.target.value);
        }}
        style={{ flex: 1 }}
      />
    </Box>
  );
}

export const getChipColor = (status: ModelStatus) => {
  switch (status) {
    case 'pending':
    case 'training':
    case 'stopped':
      return 'yellow';
    case 'trained':
      return 'green';
    case 'failed':
      return 'red';
    case 'canceled':
    default:
      return 'gray';
  }
};

export const ModelStatusChip = ({
  status,
  endpointsCount,
  prefix,
  suffix,
}: {
  status: ModelStatus;
  endpointsCount: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <Chip fill="soft" color={getChipColor(status)} style={{ width: 'max-content' }}>
      {prefix}
      {t(`model.status.${status}`)}
      {status === 'trained' &&
        endpointsCount > 0 &&
        ` • ${endpointsCount} ${
          endpointsCount > 1 ? t('model.endpoints.title') : t('model.endpoints.singleEndpoint')
        }`}
      {suffix}
    </Chip>
  );
};

export const getAnnotationIcon = (type: AnnotationType) => {
  switch (type) {
    case 'polygon':
      return Polygon;
    case 'box':
    default:
      return BoxIcon;
  }
};

export const ModelAnnotaionTypeChip = ({
  annotationType,
}: {
  annotationType: AnnotationType[];
}) => {
  return (
    <>
      {annotationType.map(type => {
        return (
          <Chip key={type} color="secondary-100">
            <Icon color={'secondary-500'} icon={getAnnotationIcon(type)} size={12} />
            {capitalize(type)}
          </Chip>
        );
      })}
    </>
  );
};

export const TaskTypeChip = ({
  taskType,
  color,
}: {
  taskType: TaskType;
  color?: ComponentProps<typeof Chip>['color'];
}) => {
  const { t } = useTranslation();
  const getTaskTypeName = (taskType: TaskType) => {
    switch (taskType) {
      case 'object_detection':
        return t('model.datasetType.object_detection');
      case 'instance_segmentation':
        return t('model.datasetType.instance_segmentation');
      case 'semantic_segmentation':
        return t('model.datasetType.semantic_segmentation');
      case 'image_generation':
        return t('model.datasetType.image_generation');
      default:
        return 'unknown';
    }
  };

  return <Chip color={color}>{getTaskTypeName(taskType)}</Chip>;
};

export const PurposeChip = ({
  purpose,
  color = undefined,
}: {
  purpose: PurposeType;
  color?: ComponentProps<typeof Chip>['color'];
}) => {
  const { t } = useTranslation();
  return (
    <Chip color={color} mr={0.5}>
      <Icon icon={purpose === 'generation' ? GenAi : Compare} />
      {purpose === 'generation' ? t('model.overview.generative') : t('model.overview.recognition')}
    </Chip>
  );
};

export const BorderBox = extendComponent(Box, {
  border: '1px solid',
  borderColor: 'gray-150',
  borderRadius: '2px',
});

export const textEllipsisStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
} as CSSProperties;
