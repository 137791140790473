import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import { CommentInputProps, CommentProps } from './types';

const useStyles = makeStyles({
  optionBox: {
    display: 'flex',
    borderTop: 'solid 0.5px #e9e9e9',
  },
  optionButton: {
    fontSize: '10px',
    color: '#635c5c',
    padding: '9px 16px',
    borderRadius: '0px',
    '&.reply': {
      color: '#ff9772',
    },
    '&.resolve': {
      color: '#5a7bff',
    },
    '&.reopen': {
      color: '#ff625a',
    },
    '&.disabled': {
      color: '#dedede',
      cursor: 'default',
      '&:hover': {
        background: 'none',
      },
    },
  },
});

type Props = CommentInputProps & Pick<CommentProps, 'indexOfEditingComment'>;

const ThreadFooterOptions = ({ issue, setFooterMode, indexOfEditingComment }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { label } = labelDetailViewInfo;
  const { setIssues, setIssuePanelState, setOpenBadgeInfo } = labelIssuesInfo;
  const [isRequesting, setIsRequesting] = useState(false);
  const {
    history: {
      location: { pathname, search },
    },
  } = routeInfo;

  const isEditComment = indexOfEditingComment !== null;

  const handleClickReply = () => {
    if (issue.status === 'RESOLVED' || isEditComment) return;

    setFooterMode('input');
  };

  const handleClickStatus = async () => {
    if (isEditComment) return;
    if (isRequesting) return;

    const nextStatus = issue.status === 'OPEN' ? 'RESOLVED' : 'OPEN';

    try {
      setIsRequesting(true);
      await IssuesService.updateIssue({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        issueId: issue.id,
        info: { status: nextStatus },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      const nextIssues = await IssuesService.getIssues({
        projectId: routeInfo.urlMatchInfo.projectId,
        labelId: label.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setIssues(nextIssues);

      setIssuePanelState(null);
      setOpenBadgeInfo({
        issue: null,
        ref: null,
      });

      routeInfo.history.push(`${pathname}${search}#label_id=${label.id}`);

      setIsRequesting(false);
    } catch (err: any) {
      setIsRequesting(false);
      throw err;
    }
  };

  const isReplyDisabled = issue?.status === 'RESOLVED' || isEditComment;
  const isResolveDisabled = isEditComment;

  return (
    <>
      <MUI.Box className={classes.optionBox}>
        <MUI.Button
          className={`${classes.optionButton} reply ${isReplyDisabled ? 'disabled' : ''}`}
          onClick={handleClickReply}
          disabled={isReplyDisabled}
        >
          REPLY
        </MUI.Button>

        <MUI.Button
          className={`${classes.optionButton} ${issue.status === 'OPEN' ? 'resolve' : 'reopen'} ${
            isResolveDisabled ? 'disabled' : ''
          } `}
          style={{ marginLeft: 'auto' }}
          onClick={handleClickStatus}
          disabled={isResolveDisabled}
        >
          {issue.status === 'OPEN' ? 'RESOLVE' : 'RE-OPEN'}
        </MUI.Button>
      </MUI.Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
};

export default ThreadFooterOptions;
