import React, { Dispatch, SetStateAction } from 'react';

import { TFunction } from 'next-i18next';

import { UploadStepInfo } from '../../../../../../../../components/elements/newUpload/stepper/steps/type';
import { SelectedSliceOption } from '../../../../../../contexts/UploadContext';
import { SliceAsyncCombobox } from '../../../../../../elements/SliceAsyncCombobox';

const SelectSliceStep = ({
  t,
  cloudInfos,
  datasetId,
}: {
  t: TFunction;
  datasetId?: string;
  cloudInfos: {
    selectedSlice: SelectedSliceOption | undefined;
    setSelectedSlice: Dispatch<SetStateAction<SelectedSliceOption | undefined>>;
  };
}): UploadStepInfo => {
  const { selectedSlice, setSelectedSlice } = cloudInfos;

  return {
    title: t('curate.datasets.cloudUpload.selectSlice'),
    isButtonEnabled: true,
    isOptional: true,
    summary: selectedSlice?.name || '',
    content: datasetId ? (
      <SliceAsyncCombobox {...{ datasetId, isCreatable: true, selectedSlice, setSelectedSlice }} />
    ) : (
      <></>
    ),
  };
};

export default SelectSliceStep;
