import React from 'react';

import * as MUI from '@mui/material';

import { review } from '../../../../utils/filter/labelFilter';
import { useOptions } from '../../../../utils/filter/useOptions';
import FilterDropdown from './components/FilterDropdown';
import FilterWithSearch from './components/FilterWithSearch';
import { FilterTypeProps } from './types';

const FilterReview: React.FC<FilterTypeProps> = props => {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  const options = useOptions({ options: review.options, i18nKeyPrefix: 'labels.status' });
  const selectedOptions = useOptions({ options: filter.options, i18nKeyPrefix: 'labels.status' });

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={review.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      {filter.condition === 'is any one of' && (
        <MUI.Grid item style={{ flex: 1 }}>
          <FilterWithSearch
            index={index}
            objKey="options"
            filterBy={filter.filterBy}
            options={options}
            value={selectedOptions}
            onValueChange={onValueChange}
            isCurrentRow={isCurrentRow}
            onChangeCurrentRow={onChangeCurrentRow}
          />
        </MUI.Grid>
      )}
    </>
  );
};

export default FilterReview;
