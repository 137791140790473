import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import { Box as UIBox } from '@superb-ai/ui';

import { GeoJSONPolygon } from '../../../../types/advancedAIFeaturesTypes';
import { hexToRgba } from '../../components/datasets/dataset/views/embedding/scatterView/utils/color';
import {
  AnnotationType,
  AnnotationTypeCoordinate,
  Box,
  ModelDiagnosisAnnotationType,
  Polygon,
} from '../../types/annotationTypes';
import { Placements } from './type';

type Annotation = {
  id: string;
  coordinate?: AnnotationTypeCoordinate<AnnotationType>;
  base64Data?: string;
  roi: AnnotationTypeCoordinate<Box>;
  type: AnnotationType | ModelDiagnosisAnnotationType;
  class?: string;
  color?: string;
  chipPlacement?: Placements;
  isDashed?: boolean;
};

type Props = {
  alt: string;
  srcUrl: string;
  annotations?: Annotation[];
  annotationColorMap?: Record<string, string>;
  imgStyle?: CSSProperties;
  originalImageSize: [number, number]; // [width, height]
  color?: string;
};

export default function AnnotatedImage({
  annotations,
  alt,
  srcUrl,
  annotationColorMap,
  imgStyle,
  originalImageSize,
  color,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isImageLoaded, setIsImageloaded] = useState(false);
  const [imageInfo, setImageInfo] = useState({
    rate: 1,
    imageWidth: 0,
    imageHeight: 0,
    imageOffsetX: 0,
    imageOffsetY: 0,
  }); // [offsetLeft, offsetTop]

  const strokeWidth = 2;
  const defaultColor = '#05FF00';

  const getImageRateAndOffset = useCallback(
    (container, image) => {
      const { clientWidth: containerClientWidth, clientHeight: containerClientHeight } = container;
      const { clientWidth: clientImageWidth, clientHeight: clientImageHeight } = image;
      const newImageInfo = {
        rate: clientImageWidth / originalImageSize[0],
        imageWidth: clientImageWidth,
        imageHeight: clientImageHeight,
        imageOffsetX: (containerClientWidth - clientImageWidth) / 2,
        imageOffsetY: (containerClientHeight - clientImageHeight) / 2,
      };

      if (
        newImageInfo.rate !== imageInfo.rate ||
        newImageInfo.imageWidth !== imageInfo.imageWidth ||
        newImageInfo.imageHeight !== imageInfo.imageHeight ||
        newImageInfo.imageOffsetX !== imageInfo.imageOffsetX ||
        newImageInfo.imageOffsetY !== imageInfo.imageOffsetY
      ) {
        setImageInfo(newImageInfo);
      }
    },
    [originalImageSize, imageInfo],
  );

  useEffect(() => {
    if (!containerRef.current) return;
    const image = containerRef.current.getElementsByTagName('img')[0];
    if (!image) return;
    getImageRateAndOffset(containerRef.current, image);
  }, [getImageRateAndOffset]);

  const resizeCallback = useCallback(
    entries => {
      const [entry] = entries;
      const imageTarget = entry.target.getElementsByTagName('img')[0];
      if (!imageTarget) return;
      getImageRateAndOffset(entry.target, imageTarget);
    },
    [isImageLoaded],
  );

  useEffect(() => {
    const img = new Image();
    img.src = srcUrl;
    img.onload = () => setIsImageloaded(true);
  }, []);

  useEffect(() => {
    if (!isImageLoaded) return;
    const resizeObserver = new ResizeObserver(resizeCallback);
    if (containerRef?.current) resizeObserver.observe(containerRef?.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [isImageLoaded]);

  const drawAnnotation = (annotation: Annotation) => {
    if (annotation.type === 'box' && annotation.coordinate) {
      const coordinate = annotation.coordinate as AnnotationTypeCoordinate<Box>;
      return (
        <rect
          key={annotation.id}
          x={coordinate.x * imageInfo.rate}
          y={coordinate.y * imageInfo.rate}
          width={coordinate.width * imageInfo.rate || 1}
          height={coordinate.height * imageInfo.rate || 1}
          stroke={color || annotation.color || defaultColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          {...(annotation.isDashed && { strokeDasharray: '4' })}
        />
      );
    }
    if (annotation.type === 'polygon' && annotation.coordinate) {
      const { points } = annotation.coordinate as AnnotationTypeCoordinate<Polygon>;
      return (points as GeoJSONPolygon).map((polygon, index) => {
        return (
          <path
            key={`${annotation.id}-${index}`}
            fillRule="evenodd"
            d={polygon.reduce(
              (polygonAcc, polygonAndHoles) =>
                polygonAcc +
                'M' +
                polygonAndHoles.reduce(
                  (acc, paths, index) =>
                    acc +
                    `${paths.x * imageInfo.rate} ${paths.y * imageInfo.rate}${
                      index === polygonAndHoles.length - 1 ? 'Z ' : ' '
                    }`,
                  '',
                ),
              '',
            )}
            stroke={color || annotation.color || defaultColor}
            strokeWidth={strokeWidth}
            fill="transparent"
            {...(annotation.isDashed && { strokeDasharray: '4' })}
          />
        );
      });
    }
    return <></>;
  };
  const drawMask = (annotation: Annotation) => {
    if (annotation.type === 'mask' && annotation.base64Data) {
      const binaryData = Uint8Array.from(atob(annotation.base64Data), c => c.charCodeAt(0));
      const blob = new Blob([binaryData], { type: 'image/png' });

      // Create an Image from Blob
      const url = URL.createObjectURL(blob);
      return (
        <>
          <rect
            width="100%"
            height="100%"
            fill={hexToRgba(annotation.color || '', 0.5)}
            mask={`url(#${annotation.id})`}
          />
          <mask id={annotation.id}>
            <image href={url} width="100%" height="100%" />
          </mask>
        </>
      );
    }
  };

  return (
    <UIBox overflow="hidden" width="100%" height="100%" position="relative" ref={containerRef}>
      {containerRef.current && (
        <>
          <img
            alt={alt}
            src={srcUrl}
            style={{
              position: 'absolute',
              maxWidth: '100%',
              maxHeight: '100%',
              left: imageInfo.imageOffsetX,
              top: imageInfo.imageOffsetY,
            }}
          />
          {annotations && (
            <svg
              style={{
                left: imageInfo.imageOffsetX,
                top: imageInfo.imageOffsetY,
                width: containerRef.current.clientWidth - imageInfo.imageOffsetX * 2,
                height: containerRef.current.clientHeight - imageInfo.imageOffsetY * 2,
                position: 'absolute',
              }}
            >
              {annotations.map(annotation =>
                annotation.type === 'mask' ? drawMask(annotation) : drawAnnotation(annotation),
              )}
            </svg>
          )}
          {/* {annotations && (
            <>
              <svg width="100%" height="100%">
                <image href={srcUrl} width="100%" height="100%" />
                {annotations.map(annotation =>
                  annotation.type === 'mask' ? drawMask(annotation) : drawAnnotation(annotation),
                )}
              </svg>
            </>
          )} */}
        </>
      )}
    </UIBox>
  );
}
