import { useEffect } from 'react';

import { get, toString } from 'lodash';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { useAuthService } from '../../../services/NewAuthService';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import { useChangeContent } from './Layout';

export default function ActivatingLayout() {
  const routeInfo = useRouteInfo();
  const { confirmAccount } = useAuthService();
  const { tenantId, email, code, region } = routeInfo.params;

  useChangeContent('graphic');

  useEffect(() => {
    if (!tenantId || !email || !code) {
      routeInfo.history.push('/auth/login');
      return;
    }

    (async () => {
      try {
        await confirmAccount({
          tenantId,
          region,
          email: atob(email),
          code,
        });

        routeInfo.history.replace(`/auth/welcome?tenantId=${tenantId}&email=${email}`);
      } catch (e: any) {
        const errCode = toString(get(e.response, 'data.code', '200400'));

        switch (errCode) {
          case '201412': // Already Activated
            routeInfo.history.replace(`/auth/already_activated?code=${code}`);
            break;
          case '200412': // Invalid Account Status
            routeInfo.history.replace(`/auth/invalid_account?code=${code}`);
            break;
          case '201404': // Invalid User Status
            routeInfo.history.replace(`/auth/invalid_user?code=${code}`);
            break;
          case '012400': // Expired Code Exeption
            routeInfo.history.replace(
              `/auth/expired?tenantId=${tenantId}&email=${email}&code=${code}`,
            );
            break;
          case '011400': // Password Mismatch
            routeInfo.history.replace(`/auth/code_mismatch?tenantId=${tenantId}&email=${email}`);
            break;
          default:
            routeInfo.history.replace('/auth/something_went_wrong');
            break;
        }
      }
    })();
  }, []);

  return <CircularProgressDialog isLoading />;
}
