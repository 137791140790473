import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import { IAdvancedQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';
import QueryBox from './QueryBox';

export default function CreateQueryTab({
  advancedQuery,
  anchorEl,
  setAnchorEl,
  handleOpen,
  updateQuery,
  updateQueryGroup,
  deleteQuery,
  turnIntoFilter,
  wrapInGroup,
  updateOperator,
  updateQueryContent,
  selectedQueryList,
  setSelectedQueryList,
  handleQueryValue,
  duplicateQuery,
}: {
  advancedQuery: IAdvancedQuery;
  anchorEl: string | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<string | null>>;
  handleOpen: (e: React.MouseEvent, id: string) => void;
  updateQuery: (boxNum: number) => void;
  updateQueryGroup: (boxNum: number) => void;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  turnIntoFilter: (boxNum: number, listIndex: number) => void;
  wrapInGroup: (boxNum: number, listIndex: number) => void;
  updateOperator: (boxNum: number, operator: string) => void;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  handleQueryValue: (value: string[] | string, boxNum: number, index: number) => void;
  duplicateQuery: (boxNum: number, listIndex: number) => void;
}) {
  return (
    <Box pr={2.5} pl={2}>
      <QueryBox
        advancedQuery={advancedQuery}
        depth={1}
        boxNum={1}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleOpen={handleOpen}
        updateQuery={updateQuery}
        updateQueryGroup={updateQueryGroup}
        deleteQuery={deleteQuery}
        turnIntoFilter={turnIntoFilter}
        wrapInGroup={wrapInGroup}
        updateOperator={updateOperator}
        updateQueryContent={updateQueryContent}
        selectedQueryList={selectedQueryList}
        setSelectedQueryList={setSelectedQueryList}
        handleQueryValue={handleQueryValue}
        duplicateQuery={duplicateQuery}
      />
    </Box>
  );
}
