import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { useDownloadsService } from '../services/DownloadsService';

export function useDownloadsInfiniteListQuery({
  datasetId,
  disabled,
}: {
  datasetId: string;
  disabled?: boolean;
}) {
  const { getDownloads } = useDownloadsService();

  return useInfiniteQuery({
    queryKey: ['curate-dataset-downloads', datasetId],
    queryFn: async ({ pageParam }) => {
      const result = await getDownloads({ cursor: pageParam, datasetId });
      return result;
    },
    getNextPageParam: params => params.next_cursor,
    refetchOnWindowFocus: false,
    enabled: Boolean(datasetId) && !disabled,
  });
}

export function useDownloadQuery({ jobId, disabled }: { jobId: string; disabled?: boolean }) {
  const { getDownload } = useDownloadsService();

  return useQuery({
    queryKey: ['curate-download', jobId],
    queryFn: async () => {
      const result = await getDownload({ jobId });
      return result;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(jobId) && !disabled,
  });
}
