import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewApprove, ReviewPending, ReviewReject, ReviewRequest } from '@superb-ai/icons';
import { FetchMore } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

import { ReviewState } from '../../../../consts/ReviewConst';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService from '../../../../services/LabelsService';
import { LabelData } from '../../../../types/labelTypes';
import { formatDistance } from '../../../../utils/date';

const colorForReviewStatus = (status: ReviewState | 'PENDING') =>
  status === 'APPROVE' ? 'secondary' : status === 'REJECT' ? 'primary' : 'cloud';

export const iconForReviewStatus = (status: ReviewState, hasReviewer = false): JSX.Element =>
  status === 'APPROVE' ? (
    <Icon icon={ReviewApprove} color={colorForReviewStatus(status)} size="20px" />
  ) : status === 'REJECT' ? (
    <Icon icon={ReviewReject} color={colorForReviewStatus(status)} size="20px" />
  ) : status === 'REQUEST' ? (
    <Icon
      icon={hasReviewer ? ReviewRequest : ReviewPending}
      color={colorForReviewStatus(status)}
      size="20px"
    />
  ) : (
    <></>
  );

export const iconForReviewAction = (status: ReviewState | 'PENDING'): JSX.Element =>
  status === 'APPROVE' ? (
    <Icon icon={ReviewApprove} color={colorForReviewStatus(status)} size="16px" />
  ) : status === 'REJECT' ? (
    <Icon icon={ReviewReject} color={colorForReviewStatus(status)} size="16px" />
  ) : status === 'REQUEST' ? (
    <Icon icon={ReviewRequest} color={colorForReviewStatus(status)} size="16px" />
  ) : status === 'PENDING' ? (
    <Icon icon={ReviewPending} color={colorForReviewStatus(status)} size="16px" />
  ) : (
    <></>
  );

interface ReviewRow {
  action: ReviewState;
  reviewer: string;
  date: string | null;
}

function ReviewHistoryRow({ action, reviewer, date }: ReviewRow) {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();

  const reviewerName = projectInfo?.mentionUserOptions?.find(
    (o: any) => o.id === reviewer,
  )?.display;
  const status = reviewer ? action || 'REQUEST' : 'PENDING';
  const dateStr = date ? formatDistance(date) : '';

  if (status === 'REQUEST') return null;

  return (
    <Box width="100%" display="flex" gap={2} alignItems="center">
      <Box display="flex" alignItems="center" gap={0.5} style={{ minWidth: '84px' }}>
        {iconForReviewAction(status)}
        <Typography color={colorForReviewStatus(status)}>
          {t(`labels.review.${status.toLowerCase()}`)}
        </Typography>
      </Box>
      <Typography
        style={{ minWidth: '240px' }}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {reviewer && `${reviewerName ?? t('labels.review.deletedUser')} (${reviewer})`}
      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Typography>{dateStr}</Typography>
      </Box>
    </Box>
  );
}

export function ReviewHistory(props: { label: LabelData; hasMore: boolean }) {
  const { t } = useTranslation();
  const [reviews, setReviews] = useState<{ reviewer: string; action: ReviewState; date: string }[]>(
    [],
  );
  const [hasMore, setHasMore] = useState(props.hasMore);
  const [page, setPage] = useState(1);

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  function fetchReviews(label: LabelData) {
    return LabelsService.getLabelReviews({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      params: { page, ordering: '-reviewed_at' },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  }

  async function loadMore() {
    const response = await fetchReviews(props.label);
    const nextReviews = [
      ...reviews,
      ...response.results.map(result => ({
        reviewer: result.reviewer,
        action: result.reviewAction,
        date: result.reviewedAt || result.createdAt,
      })),
    ];
    setReviews(nextReviews);
    setHasMore(response.count > nextReviews.length);
    setPage(page + 1);
  }

  return (
    <>
      {reviews.length > 1 && (
        <Box
          my={0.5}
          width="100%"
          backgroundColor="gray-300"
          style={{ height: '1px', opacity: 0.1 }}
        />
      )}
      <Box overflow="auto" style={{ maxHeight: 120 }}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          {reviews.map((review, index) => (
            <ReviewHistoryRow key={index} {...review} />
          ))}
        </Box>
        <FetchMore hasMore={hasMore} loadMore={loadMore} />
      </Box>
    </>
  );
}
