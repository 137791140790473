import { Box, NamedColor, Tooltip, Typography } from '@superb-ai/ui';

type Props = {
  values: { label: string; value: number; color: NamedColor }[];
  markers?: { label: string; value: number }[];
  maxQuantity: number;
};

export function MeteringBar(props: Props) {
  const { values, markers, maxQuantity } = props;
  const w = (value: number) => `${(100 * value) / maxQuantity}%`;
  let previous = 0;
  return (
    <Box position="relative">
      <Box borderRadius="2px" overflow="hidden" display="flex" backgroundColor="gray-100">
        {values.map(({ label, value, color }) => (
          <Tooltip key={label} content={label} placement="top">
            <Box
              backgroundColor={color}
              style={{
                width: w(value),
                height: '12px',
                minWidth: value > 0 ? '2px' : 0, // make very small values visible
              }}
            />
          </Tooltip>
        ))}
      </Box>
      {markers?.map(({ label, value }) => {
        const prevValue = previous;
        previous = value;
        return (
          <Box
            key={label}
            position="absolute"
            borderRight="1px solid"
            borderColor="red"
            style={{
              left: w(prevValue),
              bottom: 0,
              height: '16px',
              width: w(value - prevValue),
            }}
          >
            <Typography
              variant="s-regular"
              whiteSpace="nowrap"
              position="absolute"
              /* Use the available space between two markers, align right.
                 In case the space isn't enough, the text gets pushed to the right. */
              style={{ right: 0, left: 0, textAlign: 'right', bottom: '100%', paddingLeft: '5px' }}
            >
              {label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
