import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { Project } from '@superb-ai/siesta';

import ProjectConst from '../../../../../consts/ProjectConst';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { isAutoLabelConfigured } from '../../../../../utils/LabelInterfaceUtils';
import Banner from '../../../../elements/Banner';
import AdvancedSettings from './AdvancedSettings';
import AutoLabelSettingsModal from './modal/SettingsModal';
import SettingsTable from './SettingsTable';
import Tutorial from './Tutorial';

export default function Layout(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pointCloudsAutoLabel = useFeatureFlag('pointCloudsAutoLabel');
  const {
    project,
    project: { labelInterface },
  } = useProjectInfo();

  const disabledAutoLabelOnPointClouds =
    !pointCloudsAutoLabel && project.workapp === 'pointclouds-siesta';
  const routeInfo = useRouteInfo();

  useEffect(() => {
    if (routeInfo.hash === '#mapping' && !isModalOpen) {
      setIsModalOpen(true);
      routeInfo.history.replace({
        pathname: routeInfo.history.location.pathname,
        search: routeInfo.history.location.search,
        hash: '',
      });
    }
  }, [routeInfo, isModalOpen]);

  const { t } = useTranslation();

  function openModal() {
    setIsModalOpen(true);
  }

  /**
   * Enabled when label interface has classes or properties, and...
   */
  const buttonEnabled = getButtonEnabled(project);

  const autoLabelConfigured = isAutoLabelConfigured(labelInterface);

  return (
    <Box display="grid" gap="32px">
      <AdvancedSettings />
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="headline5" style={{ lineHeight: 1 }}>
            {t('autoLabel.settings.mapping.title')}
          </Typography>
          {!disabledAutoLabelOnPointClouds && (
            <Box>
              {buttonEnabled ? (
                <Button IconAdornment="autoLabelSettings" size="l" onClick={openModal}>
                  {t('autoLabel.settings.mapping.settingText')}
                </Button>
              ) : (
                <Tooltip
                  placement="right"
                  anchorEl={
                    <Box display="inline">
                      <Button disabled IconAdornment="autoLabelSettings" size="l">
                        {t('autoLabel.settings.mapping.settingText')}
                      </Button>
                    </Box>
                  }
                >
                  {t('autoLabel.settings.mapping.description')}
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
        {autoLabelConfigured && <SettingsTable />}
        {!autoLabelConfigured && (
          <>
            <Banner iconName="error">
              <Typography variant="body2" themedColor="textDefault">
                {t('autoLabel.notConfigured')}
              </Typography>
            </Banner>
            <Tutorial />
          </>
        )}
      </Box>
      {isModalOpen && (
        <AutoLabelSettingsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      )}
    </Box>
  );
}

function getButtonEnabled(project: Project) {
  const objectClasses = getObjectClasses(project);
  const annotationTypes = objectClasses.annotationTypes;

  if (
    project.workapp === 'image-siesta' &&
    project.labelInterface.categorization?.properties?.length > 0
  ) {
    return true;
  }

  return Object.keys(ProjectConst.CAN_USE_AUTO_LABEL).some(shape =>
    annotationTypes?.includes(shape.toLowerCase()),
  );
}

function getObjectClasses(project: Project) {
  switch (project.workapp) {
    case 'image-siesta':
      return project.labelInterface.objectDetection;
    case 'video-siesta':
      return project.labelInterface.objectTracking;
    case 'pointclouds-siesta':
      return project.labelInterface.objectTracking;
  }
}
