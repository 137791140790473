import AuthService from './AuthService';
import { ApiCall } from './types';

const createIssue: ApiCall<
  { projectId: string; labelId: string; issueInfo: any },
  any
> = async args => {
  const { projectId, labelId, issueInfo, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/labels/${labelId}/issue-comments/`,
    data: issueInfo,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getIssues: ApiCall<{ projectId: string; labelId: string }, any> = async args => {
  const { projectId, labelId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type UpdateCommentArgs = {
  projectId: string;
  labelId: string;
  issueId: string;
  commentId: string;
  info: any;
};
const updateComment: ApiCall<UpdateCommentArgs, any> = async args => {
  const { projectId, labelId, issueId, commentId, info, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/${issueId}/issue-comments/${commentId}/`,
    data: info,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

type DeleteCommentArgs = { projectId: string; labelId: string; issueId: string; commentId: string };
const deleteComment: ApiCall<DeleteCommentArgs, any> = async args => {
  const { projectId, labelId, issueId, commentId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/${issueId}/issue-comments/${commentId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const updateIssue: ApiCall<
  { projectId: string; labelId: string; issueId: string; info: any },
  any
> = async args => {
  const { projectId, labelId, issueId, info, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/${issueId}/`,
    data: info,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const createComment: ApiCall<
  { projectId: string; labelId: string; issueId: string; info: any },
  any
> = async args => {
  const { projectId, labelId, issueId, info, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/${issueId}/issue-comments/`,
    data: info,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const deleteIssue: ApiCall<
  { projectId: string; labelId: string; issueId: string },
  any
> = async args => {
  const { projectId, labelId, issueId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/labels/${labelId}/issue-threads/${issueId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  getIssues,

  createIssue,
  updateIssue,
  deleteIssue,

  createComment,
  deleteComment,
  updateComment,
};
