import qs from 'qs';

import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import { EvaluationFilterSchema } from '../components/datasets/dataset/modelDiagnosis/diagnosis/filterSchema';
import {
  ModelSource,
  SortBy,
  SortOrder,
} from '../components/datasets/dataset/modelDiagnosis/diagnosis/types';
import { Split } from '../types/evaluationTypes';
import { buildStatsEndpoint } from './DiagnosisAnalyticsService';
import {
  EvaluationSummaryResponse,
  EvaluationValue,
  EvaluationValuesResponse,
} from './diagnosisTypes';

export type EvaluationValuesDependencies = {
  datasetId: string;
  diagnosisId: string;
  fromPublicDatasets: boolean;
  // query?: string;
  filter?: EvaluationFilterSchema;
  searchAfter?: string[];
  expand?: string[];
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  size?: number;
  modelSource?: ModelSource;
  splitIn?: Split[];
  sliceId?: string;
};

function buildEndpoint(
  params: { datasetId: string; diagnosisId: string },
  path: string,
  fromPublicDatasets: boolean,
  searchParams?: Record<string, any>,
): string {
  const { datasetId, diagnosisId } = params;
  const urlSearchParam = searchParams
    ? `?${qs.stringify(searchParams, { arrayFormat: 'brackets' })}`
    : '';
  return `/curate/model-diagnosis/${
    fromPublicDatasets ? 'public-' : ''
  }datasets/${datasetId}/diagnoses/${diagnosisId}/${path}${urlSearchParam}`;
}

const postGetEvaluationValues: AfterLoginCallback<
  EvaluationValuesResponse,
  EvaluationValuesDependencies
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, filter, sortBy, sortOrder, fromPublicDatasets, ...body } =
    params.data;
  const endpoint = buildEndpoint(
    { datasetId, diagnosisId },
    'evaluation-values/_search/',
    fromPublicDatasets,
  );
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: {
      ...filter,
      ...body,
      sortBy: [sortBy],
      sortOrder: [sortOrder],
    },
  });
  return data;
};

export type EvaluationValueDependencies = {
  datasetId: string;
  diagnosisId: string;
  fromPublicDatasets: boolean;
  evaluationValueId?: string;
  expand?: string[];
};

const getEvaluationValue: AfterLoginCallback<
  EvaluationValue,
  EvaluationValueDependencies
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, evaluationValueId, fromPublicDatasets, expand } = params.data;
  const endpoint = buildEndpoint(
    { datasetId, diagnosisId },
    `evaluation-values/${evaluationValueId}/`,
    fromPublicDatasets,
    { expand },
  );
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
  });
  return data;
};

const postEvaluationSummary: AfterLoginCallback<
  EvaluationSummaryResponse,
  EvaluationValuesDependencies
> = async params => {
  if (!params.data) return;
  const { datasetId, diagnosisId, fromPublicDatasets, filter, splitIn, sliceId } = params.data;
  const endpoint = buildStatsEndpoint(
    { datasetId, diagnosisId },
    'evaluation-summary/',
    fromPublicDatasets,
  );
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: endpoint,
    hasPublicApi: false,
    isCurateUrl: true,
    ...params,
    data: {
      ...(filter && { ...filter }),
      splitIn,
      sliceId,
    },
  });
  return data;
};

export function useDiagnosisEvaluationService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    postGetEvaluationValues: afterLoginFetcher(postGetEvaluationValues),
    getEvaluationValue: afterLoginFetcher(getEvaluationValue),
    postEvaluationSummary: afterLoginFetcher(postEvaluationSummary),
  };
}
