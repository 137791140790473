import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import RegexUtils from '../../../../utils/RegexUtils';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    display: 'flex',
    width: '270px',
    marginTop: '28px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 50px 10px 14px ',
    },
  },
  inputTypeBox: {
    position: 'absolute',
    top: '7px',
    right: '7px',
    width: '35px',
    height: '20px',
    borderRadius: '6px',
    background: '#f2f2f2',
    padding: '0px',
  },
  typeText: {
    fontSize: '9px',
    color: '#797979',
  },
  icon: {
    color: '#797979',
    width: '7px',
    height: '7px',
    marginRight: '3px',
  },
}));

// eslint-disable-next-line
const FreeResponseItem = ({ imageCategory }: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const {
    freeResponseInfo,
    freeResponseInfo: { isOnlyNumber, value },
  } = imageCategory;

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line
  }, [imageCategory.id]);

  const parseInputValue = (str: string) => {
    let result = '';

    for (let i = 0; i < str.length; i++) {
      const curChar = str[i];
      if (RegexUtils.IS_ONLY_NUMERIC(curChar)) {
        result += curChar;
      }
    }

    return result;
  };

  const handleChangeTextField = (e: any) => {
    e.stopPropagation();
    const { value } = e.target;
    if (value.length === 1 && value === ' ') return;

    let parsedValue = value;

    if (isOnlyNumber) {
      parsedValue = parseInputValue(value);
    }

    freeResponseInfo.value = parsedValue;
    setInputValue(parsedValue);
  };

  const handleClickTextField = (e: React.MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();
  };

  const handelClickType = () => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const selectedImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    selectedImageCategory.freeResponseInfo.isOnlyNumber = !isOnlyNumber;

    if (!isOnlyNumber) {
      const parsedValue = parseInputValue(value);
      selectedImageCategory.freeResponseInfo.value = parsedValue;
      setInputValue(parsedValue);
    }

    newProjectInfo.setImageCategories(nextImageCategories);
  };

  const getTypeText = () => {
    if (isOnlyNumber) {
      return (
        <MUI.Tooltip title={t('projects.createProject.numbersOnly')}>
          <MUI.Typography className={classes.typeText}>#123</MUI.Typography>
        </MUI.Tooltip>
      );
    }
    return (
      <MUI.Tooltip title={t('projects.createProject.textIncludingNumbers')}>
        <MUI.Box display="flex" alignItems="center">
          <img alt="icon" className={classes.icon} src="/static/icon/allText.png" />
          <MUI.Typography className={classes.typeText}>Aa</MUI.Typography>
        </MUI.Box>
      </MUI.Tooltip>
    );
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.TextField
        multiline
        variant="outlined"
        fullWidth
        onClick={handleClickTextField}
        onChange={handleChangeTextField}
        onKeyDown={e => e.stopPropagation()}
        value={inputValue}
        placeholder={t('projects.createProject.freeResponsePlaceholder')}
      />
      <MUI.Button className={classes.inputTypeBox} onClick={handelClickType}>
        {getTypeText()}
      </MUI.Button>
    </MUI.Box>
  );
};

export default FreeResponseItem;
