import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { FilterFilled, FilterOutlined, LoadingSpinnerSmall, Search } from '@superb-ai/icons';
import {
  Box,
  Checkbox,
  extendComponent,
  Icon,
  IconButton,
  Input,
  LoadingIndicator,
  Popover,
  ToggleButton,
  Typography,
} from '@superb-ai/ui';

import { useDebounce } from '../../../../hooks/DebounceHook';
import { useModelFilterChoicesQuery } from '../../queries/modelQueries';
import { ModelStatus, PurposeType, TaskType } from '../../services/types';

export const StatusPopover = ({
  statusFilter,
  setStatusFilter,
  hasEndpoints,
  setHasEndpoints,
}: {
  statusFilter: ModelStatus[];
  setStatusFilter: Dispatch<SetStateAction<ModelStatus[]>>;
  hasEndpoints: boolean;
  setHasEndpoints: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const handleClickStatus = (status: ModelStatus) => {
    setStatusFilter(prevFilter => {
      if (prevFilter.includes(status)) {
        return prevFilter.filter(item => item !== status);
      } else {
        return [...prevFilter, status];
      }
    });
  };

  return (
    <Popover
      fixed
      hideOnClick
      disclosure={
        <IconButton
          icon={statusFilter.length > 0 || hasEndpoints ? FilterFilled : FilterOutlined}
          color={statusFilter.length > 0 || hasEndpoints ? 'secondary' : 'gray'}
          variant="text"
          size="s"
        />
      }
      style={{ zIndex: 1, width: 200 }}
    >
      <PopoverContrainer onClick={e => e.stopPropagation()}>
        <PopoverItem gap={0.5} onClick={() => handleClickStatus('trained')}>
          <Checkbox value={statusFilter.includes('trained')} />
          <Typography variant="m-regular">{t('model.status.trained')}</Typography>
        </PopoverItem>
        <PopoverItem
          gap={0.5}
          onClick={() => {
            handleClickStatus('pending');
            handleClickStatus('training');
            handleClickStatus('stopped');
          }}
        >
          <Checkbox
            value={
              statusFilter.includes('pending') &&
              statusFilter.includes('training') &&
              statusFilter.includes('stopped')
            }
          />
          <Typography variant="m-regular">{t('model.myModels.inProgress')}</Typography>
        </PopoverItem>
        <PopoverItem gap={0.5} onClick={() => handleClickStatus('failed')}>
          <Checkbox value={statusFilter.includes('failed')} />
          <Typography variant="m-regular">{t('model.status.failed')}</Typography>
        </PopoverItem>
        <PopoverItem gap={0.5} onClick={() => handleClickStatus('canceled')}>
          <Checkbox value={statusFilter.includes('canceled')} />
          <Typography variant="m-regular">{t('model.status.canceled')}</Typography>
        </PopoverItem>
        <Box bb="1px solid" borderColor={'gray-150'} my={0.5} />
        <PopoverItem gap={0.5}>
          <Typography variant="m-regular">{t('model.myModels.endpointDeployed')}</Typography>
          <ToggleButton
            style={{ marginLeft: 'auto' }}
            size="xs"
            checked={hasEndpoints}
            onChange={() => setHasEndpoints(prev => !prev)}
          />
        </PopoverItem>
      </PopoverContrainer>
    </Popover>
  );
};

export const DatasetTypePopover = ({
  datasetName,
  setDatasetName,
  datasetTaskFilter,
  setDatasetTaskFilter,
  datasetIdFilter,
  setDatasetIdFilter,
}: {
  datasetName: string;
  setDatasetName: Dispatch<SetStateAction<string>>;
  datasetTaskFilter: TaskType[];
  setDatasetTaskFilter: Dispatch<SetStateAction<TaskType[]>>;
  datasetIdFilter: string[];
  setDatasetIdFilter: Dispatch<SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const debouncedDatasetNameFilter = useDebounce(datasetName, 300);

  const modelFilterChoicesQuery = useModelFilterChoicesQuery({ modelPurpose: params.purpose });

  if (modelFilterChoicesQuery.isLoading) {
    return <LoadingSpinnerSmall />;
  }

  const sourceOptions = modelFilterChoicesQuery.data.dataset
    .filter(dataset => dataset && dataset.name)
    .filter(dataset => dataset.name.includes(debouncedDatasetNameFilter))
    .map(dataset => ({
      value: dataset.id,
      label: dataset.name,
    }));

  const handleClickDatasetType = (datasetType: TaskType) => {
    setDatasetTaskFilter(prevFilter => {
      if (prevFilter.includes(datasetType)) {
        return prevFilter.filter(item => item !== datasetType);
      } else {
        return [...prevFilter, datasetType];
      }
    });
  };

  const handleClickDataset = (datasetId: string) => {
    setDatasetIdFilter(prevFilter => {
      if (prevFilter.includes(datasetId)) {
        return prevFilter.filter(item => item !== datasetId);
      } else {
        return [...prevFilter, datasetId];
      }
    });
  };

  return (
    <Popover
      fixed
      hideOnClick
      disclosure={
        <IconButton
          icon={
            datasetIdFilter.length > 0 || datasetTaskFilter.length > 0
              ? FilterFilled
              : FilterOutlined
          }
          color={datasetIdFilter.length > 0 || datasetTaskFilter.length > 0 ? 'secondary' : 'gray'}
          variant="text"
          size="s"
        />
      }
      style={{ zIndex: 1 }}
    >
      <PopoverContrainer onClick={e => e.stopPropagation()}>
        {params.purpose === 'recognition' && (
          <>
            <PopoverItem backgroundColor={'white'} cursor={undefined}>
              <Typography variant="s-regular" color={'gray-300'}>
                {t('model.myModels.task')}
              </Typography>
            </PopoverItem>
            <PopoverItem gap={0.5} onClick={() => handleClickDatasetType('object_detection')}>
              <Checkbox value={datasetTaskFilter.includes('object_detection')} />
              <Typography variant="m-regular">{t('model.datasetType.object_detection')}</Typography>
            </PopoverItem>
            <PopoverItem gap={0.5} onClick={() => handleClickDatasetType('instance_segmentation')}>
              <Checkbox value={datasetTaskFilter.includes('instance_segmentation')} />
              <Typography variant="m-regular">
                {t('model.datasetType.instance_segmentation')}
              </Typography>
            </PopoverItem>
            {/* <PopoverItem gap={0.5} onClick={() => handleClickDatasetType('semantic_segmentation')}>
          <Checkbox value={datasetTaskFilter.includes('semantic_segmentation')} />
          <Typography variant="m-regular">
            {t('model.datasetType.semantic_segmentation')}
          </Typography>
        </PopoverItem> */}
            <Box bb="1px solid" borderColor={'gray-150'} my={0.5} />
          </>
        )}
        <PopoverItem backgroundColor={'white'} cursor={undefined}>
          <Typography variant="s-regular" color={'gray-300'}>
            {t('model.train.dataset')}
          </Typography>
        </PopoverItem>
        <Box px={0.5} mb={0.5} width="100%">
          <Input
            value={datasetName}
            onChange={e => setDatasetName(e.target.value)}
            prefix={<Icon icon={Search} />}
            placeholder={t('model.myModels.datasetFilterPlaceholder')}
          />
        </Box>
        <Box overflow="auto" style={{ maxHeight: 144, width: 200 }}>
          {modelFilterChoicesQuery.isLoading ? (
            <LoadingIndicator />
          ) : (
            sourceOptions?.map(option => {
              return (
                <PopoverItem
                  key={option.value}
                  gap={0.5}
                  onClick={() => handleClickDataset(option.value)}
                >
                  <Checkbox value={datasetIdFilter.includes(option.value)} />
                  <Typography
                    variant="m-regular"
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                  >
                    {option.label}
                  </Typography>
                </PopoverItem>
              );
            })
          )}
        </Box>
      </PopoverContrainer>
    </Popover>
  );
};

export const PreviousModelPopover = ({
  modelHubFilter,
  setModelHubFilter,
  previousModelNameFilter,
  setPreviousModelNameFilter,
  previouseModelName,
  setPreviouseModelName,
}: {
  modelHubFilter: string[];
  setModelHubFilter: Dispatch<SetStateAction<string[]>>;
  previousModelNameFilter: string[];
  setPreviousModelNameFilter: Dispatch<SetStateAction<string[]>>;
  previouseModelName: string;
  setPreviouseModelName: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();

  const { data, isLoading } = useModelFilterChoicesQuery({ modelPurpose: params.purpose });

  const handleClickModelHub = (baselineModelId: string) => {
    setModelHubFilter(prevFilter => {
      if (prevFilter.includes(baselineModelId)) {
        return prevFilter.filter(item => item !== baselineModelId);
      } else {
        return [...prevFilter, baselineModelId];
      }
    });
  };

  const handleClickPreviousModel = (modelId: string) => {
    setPreviousModelNameFilter(prevFilter => {
      if (prevFilter.includes(modelId)) {
        return prevFilter.filter(item => item !== modelId);
      } else {
        return [...prevFilter, modelId];
      }
    });
  };

  if (isLoading) {
    return <LoadingSpinnerSmall />;
  }

  const searchedPreviousModels = data?.previousModel.filter(model =>
    previouseModelName ? model.name.toLowerCase().includes(previouseModelName) : true,
  );

  return (
    <Popover
      fixed
      hideOnClick
      disclosure={
        <IconButton
          icon={
            modelHubFilter.length > 0 || previousModelNameFilter.length > 0
              ? FilterFilled
              : FilterOutlined
          }
          color={
            modelHubFilter.length > 0 || previousModelNameFilter.length > 0 ? 'secondary' : 'gray'
          }
          variant="text"
          size="s"
          disabled={searchedPreviousModels.length === 0}
        />
      }
      style={{ zIndex: 1 }}
    >
      <PopoverContrainer onClick={e => e.stopPropagation()} style={{ width: 240, maxWidth: 240 }}>
        {params.purpose === 'recognition' && (
          <>
            <PopoverItem backgroundColor={'white'} cursor={undefined}>
              <Typography variant="s-regular" color={'gray-300'}>
                {t('model.myModels.modelHub')}
              </Typography>
            </PopoverItem>
            <Box>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <LoadingSpinnerSmall />
                </Box>
              ) : (
                data?.modelHub.map(option => (
                  <PopoverItem key={option} gap={0.5} onClick={() => handleClickModelHub(option)}>
                    <Checkbox value={modelHubFilter.includes(option)} />
                    <Typography
                      variant="m-regular"
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                    >
                      {option}
                    </Typography>
                  </PopoverItem>
                ))
              )}
            </Box>
            <Box bb="1px solid" borderColor={'gray-150'} my={0.5} />
          </>
        )}
        <PopoverItem backgroundColor={'white'} cursor={undefined}>
          <Typography variant="s-regular" color={'gray-300'}>
            {t('model.modelLogDialog.title')}
          </Typography>
        </PopoverItem>
        <Box px={0.5} mb={0.5} width="100%">
          <Input
            value={previouseModelName}
            onChange={e => setPreviouseModelName(e.target.value.toLowerCase())}
            prefix={<Icon icon={Search} />}
            placeholder={t('model.train.modelSearchPlaceholder')}
          />
        </Box>
        <Box overflow="auto" style={{ maxHeight: 144 }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <LoadingSpinnerSmall />
            </Box>
          ) : (
            searchedPreviousModels?.map(option => {
              return (
                <PopoverItem
                  key={option.id}
                  gap={0.5}
                  onClick={() => handleClickPreviousModel(option.id)}
                >
                  <Checkbox value={previousModelNameFilter.includes(option.id)} />
                  <Typography
                    variant="m-regular"
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                  >
                    {option.name}
                  </Typography>
                </PopoverItem>
              );
            })
          )}
        </Box>
      </PopoverContrainer>
    </Popover>
  );
};

export const TagPopover = ({
  tagFilter,
  setTagFilter,
  tagName,
  setTagName,
}: {
  tagFilter: string[];
  setTagFilter: Dispatch<SetStateAction<string[]>>;
  tagName: string;
  setTagName: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const { data, isLoading } = useModelFilterChoicesQuery({ modelPurpose: params.purpose });

  const handleClickTag = (tag: string) => {
    setTagFilter(prevFilter => {
      if (prevFilter.includes(tag)) {
        return prevFilter.filter(item => item !== tag);
      } else {
        return [...prevFilter, tag];
      }
    });
  };

  if (isLoading) {
    return <LoadingSpinnerSmall />;
  }
  const searchedTags = data?.tag.filter(tag => (tagName ? tag.includes(tagName) : true));

  return (
    <Popover
      fixed
      hideOnClick
      disclosure={
        <IconButton
          icon={tagFilter.length > 0 ? FilterFilled : FilterOutlined}
          color={tagFilter.length > 0 ? 'secondary' : 'gray'}
          variant="text"
          size="s"
          disabled={searchedTags.length === 0}
        />
      }
      style={{ zIndex: 1, width: 200 }}
    >
      <PopoverContrainer onClick={e => e.stopPropagation()}>
        <Box px={0.5} mb={0.5} width="100%">
          <Input
            value={tagName}
            onChange={e => setTagName(e.target.value)}
            prefix={<Icon icon={Search} />}
            placeholder={t('model.train.searchByTag')}
          />
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <LoadingSpinnerSmall />
          </Box>
        ) : (
          searchedTags?.map(tag => {
            return (
              <PopoverItem key={tag} gap={0.5} onClick={() => handleClickTag(tag)}>
                <Checkbox value={tagFilter.includes(tag)} />
                <Typography
                  variant="m-regular"
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                >
                  {tag}
                </Typography>
              </PopoverItem>
            );
          })
        )}
      </PopoverContrainer>
    </Popover>
  );
};

export const PopoverContrainer = extendComponent(Box, {
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  py: 0.5,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '2px',
});

export const PopoverItem = extendComponent(Box, {
  display: 'flex',
  alignItems: 'center',
  variant: 'text',
  px: 1.5,
  backgroundColor: {
    default: 'white',
    hover: 'gray-100',
  },
  cursor: 'pointer',
  pl: 0,
  style: {
    height: 32,
  },
});
