import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { map } from 'lodash';

import { useAssetsInfo } from '../../../../contexts/AssetsContext';
import { useApiDefaultParams } from '../../../../hooks/ApiParamsHook';
import AssetsService from '../../../../services/AssetsService';
import { dataset } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterWithSearch from './components/FilterWithSearch';
import { FilterTypeProps } from './types';

const FilterDataset = ({
  index,
  filter,
  isCurrentRow,
  onChangeCurrentRow,
  onValueChange,
}: FilterTypeProps) => {
  const { t } = useTranslation();
  const assetsInfo = useAssetsInfo();
  const params = useApiDefaultParams();

  useEffect(() => {
    if (assetsInfo) assetsInfo.updateProjectDatasets();
    // eslint-disable-next-line
  }, []);

  const handleSearchDataset = async (inputValue: string) => {
    const datasetList = await AssetsService.getProjectDatasets({
      ...params,
      params: {
        groupIcontains: inputValue,
      },
    });
    const result = map(datasetList.results, result => ({
      label: result.group,
      value: result.group,
    }));
    return result;
  };

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={dataset.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      <MUI.Grid item style={{ flex: 1 }}>
        <FilterWithSearch
          asynchronous
          index={index}
          objKey="options"
          filterBy={filter.filterBy}
          defaultOptions={assetsInfo.datasetsOptions}
          onAsyncSearch={handleSearchDataset}
          value={filter.options}
          onValueChange={onValueChange}
          isCurrentRow={isCurrentRow}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
    </>
  );
};

export default FilterDataset;
