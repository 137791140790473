import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button, IconName } from '@superb-ai/norwegian-forest';

import Popper from './Popper';

type Props = {
  resetButton?: { text?: string; onClick: () => void };
  applyButton: { text?: string; onClick: () => void };
  anchorIcon: IconName;
  anchorButtonText: string;
  hover: boolean;
  setHover: Dispatch<SetStateAction<boolean>>;
  isPopperVisible: boolean;
  setIsPopperVisible: Dispatch<SetStateAction<boolean>>;
  isSelected: boolean;
};

export default function ActionPopper({
  resetButton,
  applyButton,
  children,
  anchorIcon,
  anchorButtonText,
  hover,
  setHover,
  isPopperVisible,
  setIsPopperVisible,
  isSelected,
}: React.PropsWithChildren<Props>) {
  return hover || isPopperVisible || isSelected ? (
    <Popper
      open={isPopperVisible}
      anchorElement={
        <Button
          color="grey"
          IconAdornment={anchorIcon}
          onClick={e => {
            e.stopPropagation();
            setIsPopperVisible(!isPopperVisible);
          }}
        >
          {anchorButtonText}
        </Button>
      }
    >
      <Box width={240}>{children}</Box>
      <Box bt px={1.5} py={1} display="flex">
        <Box>
          {resetButton && (
            <Button variant="text-simple" color="grey" onClick={resetButton.onClick}>
              {resetButton.text || 'Reset'}
            </Button>
          )}
        </Box>
        <Box display="flex" ml="auto" gap="12px" py={0.5}>
          <Button
            variant="text-simple"
            color="grey"
            onClick={() => {
              setIsPopperVisible(false);
              setHover(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="text-simple" onClick={applyButton.onClick}>
            {applyButton.text || 'Apply'}
          </Button>
        </Box>
      </Box>
    </Popper>
  ) : (
    <></>
  );
}
