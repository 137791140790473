import { useEffect, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';

import { Select } from '@superb-ai/norwegian-forest';

import { useUsersQuery } from '../../queries/useUsers';
import { MemberData } from '../../types/memberTypes';
import { Option } from '../../types/selectTypes';
import UserAvatar from './UserAvatar';

interface Props {
  onChange: (newValue: SingleValue<Option<string>>, actionMeta: ActionMeta<Option<string>>) => void;
  value: Option<string> | null;
  placeholder: string;
}

export default function UserListDropdown({ onChange, value, placeholder }: Props) {
  const [userMap, setUserMap] = useState<Map<string, MemberData>>();
  const [userOptions, setUserOptions] = useState<Option[]>();

  const { data: users } = useUsersQuery({ params: { asList: '' } });

  useEffect(() => {
    (async () => {
      if (!users) return;
      const userMap = new Map<string, MemberData>();
      const userOptions = users.reduce((acc: Option[], user) => {
        userMap.set(user.email, user);
        if (user.tenantRole === 'Owner' || user.tenantRole === 'Admin') {
          return [...acc, { label: user.name, value: user.email }];
        }
        return acc;
      }, []);

      setUserOptions([...userOptions]);
      setUserMap(userMap);
    })();
  }, [users]);

  return (
    <Select.Basic
      placeholder={placeholder}
      options={userOptions}
      getOptionLabelAdornment={(option: Option) => {
        const userInfo = userMap?.get(option.value);
        return (
          <UserAvatar
            size={16}
            userInfo={{
              avatarUrl: userInfo?.avatarUrl || '',
              email: userInfo?.email || '',
              name: userInfo?.name || '',
              status: userInfo?.status || '',
            }}
            noShadow
          />
        );
      }}
      getOptionLabel={(option: Option) => `${option.label} (${option.value})`}
      onChange={onChange}
      value={value}
      variant="fill"
      color="lightgrey"
    />
  );
}
