import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import RegexUtils from '../../../../utils/RegexUtils';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    display: 'flex',
    width: '420px',
    marginTop: '40px',
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 50px 10px 14px ',
    },
  },
  inputTypeBox: {
    position: 'absolute',
    top: '16px',
    right: '7px',
    width: '35px',
    height: '20px',
    borderRadius: '6px',
    background: '#f2f2f2',
    padding: '0px',
  },
  typeText: {
    fontSize: '9px',
    color: '#797979',
  },
  icon: {
    color: '#797979',
    width: '7px',
    height: '7px',
    marginRight: '3px',
  },
}));

// eslint-disable-next-line
const ClassPropertyItemFreeResponse = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;
  const { type } = classProperty;
  const { isOnlyNumber, value } = classProperty.options[type];

  const [inputValue, setInputValue] = useState(value);

  const parseInputValue = (str: string) => {
    let result = '';

    for (let i = 0; i < str.length; i++) {
      const curChar = str[i];
      if (RegexUtils.IS_ONLY_NUMERIC(curChar)) {
        result += curChar;
      }
    }

    return result;
  };

  const handleChangeTextField = (e: any) => {
    e.stopPropagation();
    const { value } = e.target;
    if (value.length === 1 && value === ' ') return;

    let parsedValue = value;

    if (isOnlyNumber) {
      parsedValue = parseInputValue(value);
    }

    classProperty.options[type].value = parsedValue;
    setInputValue(parsedValue);
  };

  const handleClickTextField = (e: React.MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();
    newProjectInfo.setSelectedClassPropertyIndex(index);
  };

  const handelClickType = () => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const curClassProperty =
      nextObjectClasses[newProjectInfo.selectedObjectClassIndex].properties[index];
    curClassProperty.options[type].isOnlyNumber = !isOnlyNumber;
    if (!isOnlyNumber) {
      const parsedValue = parseInputValue(value);

      classProperty.options[type].value = parsedValue;
      setInputValue(parsedValue);
    }

    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  const getTypeText = () => {
    if (isOnlyNumber) {
      return (
        <MUI.Tooltip title="Numbers only">
          <MUI.Typography className={classes.typeText}>#123</MUI.Typography>
        </MUI.Tooltip>
      );
    }
    return (
      <MUI.Tooltip title="Text including numbers">
        <MUI.Box display="flex" alignItems="center">
          <img alt="icon" className={classes.icon} src="/static/icon/allText.png" />
          <MUI.Typography className={classes.typeText}>Aa</MUI.Typography>
        </MUI.Box>
      </MUI.Tooltip>
    );
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.TextField
        multiline
        variant="outlined"
        fullWidth
        onClick={handleClickTextField}
        onChange={handleChangeTextField}
        onKeyDown={e => e.stopPropagation()}
        value={inputValue}
        placeholder={t('projects.createProject.freeResponsePlaceholder')}
      />
      <MUI.Button className={classes.inputTypeBox} onClick={handelClickType}>
        {getTypeText()}
      </MUI.Button>
    </MUI.Box>
  );
};

export default ClassPropertyItemFreeResponse;
