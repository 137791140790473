import {
  ComponentProps,
  ReactNode,
  useState,
  cloneElement,
  isValidElement,
  ReactElement,
  Children,
} from 'react';

import { ChevronDown, ChevronRight } from '@superb-ai/icons';
import { Box, Collapse, IconButton, Typography } from '@superb-ai/ui';

// Define a type for child components that can accept the isOpen prop
type AccordionChildProps = {
  isOpen?: boolean;
};

export default function Accordion({
  children,
  title,
  rightContent,
  boxProps,
  defaultOpen = true,
}: {
  children: ReactNode;
  title: string;
  rightContent?: ReactNode;
  boxProps?: ComponentProps<typeof Box>;
  defaultOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderChildrenWithIsOpen = (children: ReactNode) => {
    return Children.map(children, child => {
      if (isValidElement<AccordionChildProps>(child)) {
        return cloneElement(child, { isOpen });
      }
      return child;
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
        px={1}
        backgroundColor="gray-150"
        onClick={() => setIsOpen(!isOpen)}
        cursor="pointer"
        {...boxProps}
      >
        <Box display="flex" alignItems="center" gap={0.5}>
          <IconButton size="s" icon={isOpen ? ChevronDown : ChevronRight} variant="text" />
          <Typography variant="m-medium">{title}</Typography>
        </Box>
        {rightContent}
      </Box>
      <Collapse open={isOpen}>{renderChildrenWithIsOpen(children)}</Collapse>
    </Box>
  );
}
