export const RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER = 1000;
export const RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER = 100;
export const RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE = 20;
export const RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT = 16;
export const RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT = 4;
export const RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER = 100;
export const RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER = 64;

export const ENDPOINT_DEPLOYMENT_CREDIT = 50;
export const TRAINING_CREDIT = 250;

export const MAXIMUM_MODEL_NAME_LENGTH = 50;
export const MINIMUM_MODEL_NAME_LENGTH = 4;
export const MAXIMUM_ENDPOINT_NAME_LENGTH = 50;
export const MINIMUM_ENDPOINT_NAME_LENGTH = 4;

export const MIN_EPOCH = 1;

export const ITERATION_INDEX_COEFFICIENT = 50;
