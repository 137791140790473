import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Collapse, Icon, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import * as d3Format from 'd3-format';
import { lowerCase, map } from 'lodash';

import { PropertyLegendObject } from './helper';
import { ObjectProperty, PropertyCount } from './interface';
import styles from './PropertyTree.module.scss';

const OPTION_COLUMN_STYLE = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const TEXT_OVERFLOW_STYLE = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

interface FnProps {
  option: PropertyCount;
  property: ObjectProperty;
  handleMouseOverOption: (index: number, property: ObjectProperty) => void;
  handleMouseOutOption: (property: ObjectProperty) => void;
  hoveredPropertyOptionIndex: number;
  isSelected: boolean;
  index: number;
}
export const PropertyOption = (props: FnProps): JSX.Element => {
  const {
    index,
    option,
    property,
    handleMouseOverOption,
    handleMouseOutOption,
    hoveredPropertyOptionIndex,
    isSelected,
  } = props;

  const handleMouseOver = () => {
    handleMouseOverOption(index, property);
  };
  const handleMouseOut = () => {
    handleMouseOutOption(property);
  };

  const toFixedSecondDecimal = Number(option.percent).toFixed(2);

  return (
    <Box
      display="flex"
      style={{
        width: '360px',
        marginRight: '3px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.9,
        ...(isSelected &&
          hoveredPropertyOptionIndex === index && {
            backgroundColor: ' #FFE7E7',
          }),
      }}
      width={'100%'}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={() => handleMouseOut()}
    >
      <Box style={{ ...OPTION_COLUMN_STYLE, width: '60%', ...TEXT_OVERFLOW_STYLE }}>
        <Tooltip placement={'top-start'} anchorEl={<span>{option.optionName}</span>}>
          {option.optionName}
        </Tooltip>
      </Box>
      <Box style={{ ...OPTION_COLUMN_STYLE, width: '20%' }}>
        <span>{d3Format.format(',')(option.count)}</span>
      </Box>
      <Box style={{ ...OPTION_COLUMN_STYLE, width: '20%', marginRight: '25px' }}>
        <span>{`${toFixedSecondDecimal} %`}</span>
      </Box>
    </Box>
  );
};

interface ItemProps {
  property: ObjectProperty;
  handleMouseEnterProperty: (datum: ObjectProperty) => void;
  handleMouseOverOption: (index: number, property: ObjectProperty) => void;
  handleMouseOutOption: (property: ObjectProperty) => void;
  hoveredPropertyOptionIndex: number;
  selectedProperty: ObjectProperty;
  options: PropertyCount[];
}

export const PropertyItem = (props: ItemProps): JSX.Element => {
  const {
    property,
    handleMouseEnterProperty,
    handleMouseOverOption,
    handleMouseOutOption,
    hoveredPropertyOptionIndex,
    options,
    selectedProperty,
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const iconState = open ? 'chevronBigDown' : 'chevronBigUp';

  const handlePropertyClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      display="flex"
      style={{
        padding: '2px',
        flexDirection: 'column',
      }}
      justifyContent="flex-start"
    >
      <Button
        key={property?.id}
        IconAdornment={<Icon name={iconState} />}
        color="grey"
        variant="text-simple"
        iconPosition="left"
        onMouseOver={() => handleMouseEnterProperty(property)}
        onMouseOut={() => handleMouseOutOption(property)}
        onClick={() => handlePropertyClick()}
        style={{ justifyContent: 'flex-start', marginTop: '2px' }}
      >
        {property?.name}
        {property?.perFrame && (
          <Typography style={{ paddingLeft: '2px' }} variant="label" themedColor={'cloud'}>
            {` (${lowerCase(t('analytics.text.perFrame'))}`}
          </Typography>
        )}
        {property?.required && (
          <Typography style={{ paddingLeft: '2px' }} variant="label" themedColor={'primary'}>
            {' *'}
          </Typography>
        )}
      </Button>
      <Collapse open={open} key={`collapse-${property.id}`}>
        <Box id="option-box" style={{ padding: '0px 15px' }}>
          {property?.type === 'free response' && (
            <Typography style={{ color: 'grey', marginLeft: '3px' }} variant="label">
              {t('analytics.project.property.freeResponseNotAvailable')}
            </Typography>
          )}
          {options.map((option, index) => {
            return (
              <PropertyOption
                key={option?.propertyOptionId + index}
                index={index}
                option={option}
                property={property}
                handleMouseOverOption={handleMouseOverOption}
                handleMouseOutOption={handleMouseOutOption}
                hoveredPropertyOptionIndex={hoveredPropertyOptionIndex}
                isSelected={selectedProperty?.id === property?.id}
              />
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

interface ListProps {
  propObject: PropertyLegendObject;
  maxHeight: number;
  maxWidth: number;
  handleMouseEnterProperty: (datum: ObjectProperty) => void;
  handleMouseOverOption: (index: number, property: ObjectProperty) => void;
  handleMouseOutOption: (property: ObjectProperty) => void;
  hoveredPropertyOptionIndex: number;
  selectedProperty: ObjectProperty;
}

export const PropertyTree = (props: ListProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    propObject,
    handleMouseEnterProperty,
    handleMouseOverOption,
    handleMouseOutOption,
    hoveredPropertyOptionIndex,
    maxHeight,
    maxWidth,
    selectedProperty,
  } = props;

  const numProps = propObject?.data?.length ?? 0;
  const noPropertyDiv = () => {
    return (
      <Typography themedColor="cloud" variant="body3" whiteSpace="inherit">
        {t('analytics.project.property.noProperty')}
      </Typography>
    );
  };

  return (
    <Box className={styles.container} maxHeight={`${maxHeight}px`} maxWidth={`${maxWidth}px`}>
      <Box className={styles.title}>
        <span>
          {propObject?.title} ({numProps})
        </span>
        <Tooltip
          placement="right"
          anchorEl={
            <Box className={styles.infoIcon}>
              <Icon name="infoCircleOutline" color="secondary" />
            </Box>
          }
        >
          {t('analytics.project.property.excludesFreeResposne')}
        </Tooltip>
      </Box>
      <Box className={styles.propertyList}>
        {numProps === 0 && noPropertyDiv()}
        {numProps > 0 &&
          map(propObject?.data, property => {
            return (
              <Fragment key={property?.id}>
                <PropertyItem
                  property={property}
                  options={property?.options}
                  handleMouseEnterProperty={handleMouseEnterProperty}
                  handleMouseOverOption={handleMouseOverOption}
                  handleMouseOutOption={handleMouseOutOption}
                  hoveredPropertyOptionIndex={hoveredPropertyOptionIndex}
                  selectedProperty={selectedProperty}
                />
              </Fragment>
            );
          })}
      </Box>
    </Box>
  );
};
