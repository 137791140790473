import { PartialTheme, PartitionStyle } from '@elastic/charts';
import { FillLabelConfig, LinkLabelConfig } from '@elastic/charts/dist/utils/themes/partition';

export const getCustomTheme = (color: string, barPadding = 0.1) =>
  ({
    scales: {
      barsPadding: barPadding,
    },
    barSeriesStyle: {
      displayValue: {
        fontSize: 12,
        fontFamily: "Inter, Roboto, 'Open Sans', Arial, sans-serif",
        fontStyle: 'normal',
        fill: color,
        offsetY: 13,
      },
    },
    axes: {
      axisLabel: {
        fontSize: 14,
      },
      tickLabel: {
        fontSize: 12,
      },
    },
  } as const);

export const getHistogramTheme = (color: string): PartialTheme => ({
  ...getCustomTheme(color),
  chartMargins: {
    top: 27,
    right: 10,
    left: 0,
    bottom: 10,
  },
  scales: {
    histogramPadding: 0,
    barsPadding: 0,
  },
});

export const CHART_CONFIG = {
  DEFAULT_CHART_WIDTH: 720,
  DEBOUNCE_DELAY: 20,
};

type DonutThemeParams = { dataCount: number; color?: string; noFillText?: boolean };

const getMaxLinkedLabel = (dataCount: number): number => {
  const DATA_THRESHOLD = 50;
  const MAX_LINKED_LABEL = 10;
  const NO_LINKED_LABEL = 0;
  return dataCount > DATA_THRESHOLD ? NO_LINKED_LABEL : MAX_LINKED_LABEL;
};

export function getDonutChartTheme({ dataCount, color, noFillText }: DonutThemeParams) {
  return {
    background: {
      color: color ?? '#fff',
      fallback: '#fff',
    },
    chartMargins: { top: -40, left: -10, bottom: -40, right: -20 },
    partition: {
      horizontalTextEnforcer: 1,
      emptySizeRatio: 0.5,
      outerSizeRatio: 0.65,
      fontFamily: "Inter, Roboto, 'Open Sans', Arial, sans-serif",
      fillLabel: {
        padding: 2,
        valueFont: {
          fontWeight: 400,
          fontFamily: "Inter, Roboto, 'Open Sans', Arial, sans-serif",
        },
        clipText: false,
      } as FillLabelConfig,
      linkLabel: {
        maximumSection: 20,
        maxCount: getMaxLinkedLabel(dataCount),
      } as Partial<LinkLabelConfig>,
    } as Partial<PartitionStyle>,
  };
}

/** Donut Chart fill text options */
const linkLabelOnly = {
  fontSize: 13,
  maxCount: 40,
  maximumSelection: Infinity,
  maxTextLength: 20,
};

/** Axis Styling */
export const xAxisTickLabel = ({
  dataCount,
  textRotationThreshold = 8,
}: {
  dataCount: number;
  textRotationThreshold?: number;
}) => {
  return {
    ...(dataCount > textRotationThreshold && {
      rotation: 270,
      offset: { x: -10 },
    }),
  };
};
