import { ComponentType } from 'react';

import { Box, Cuboid, Keypoints, Polygon, Polyline, RotatedBox } from '@superb-ai/icons';
import { isDate, isNull, snakeCase } from 'lodash';

import { formatDistanceShort } from '../../../../utils/date';
import DateUtils from '../../../../utils/DateUtils';
import FileUtils from '../../../../utils/FileUtils';
import { AnnotationType } from '../../../../utils/LabelInterfaceUtils';
import { ObjectCountResult } from '../interfaces/apiResponse';
import { JsonObj } from '../userStats/types';

const filterDisplay = (filter?: Record<string, string[] | []>) => {
  if (!filter) return '';

  return Object.entries(filter).map(([filterBy, value]) => {
    if (filterBy === 'assetGroupIn') {
      const formattedFilterBy = 'dataset';
      if (!value || !value.length) return '';
      if (value.length > 2) return `_filter_${formattedFilterBy}:[${value[0]}, ${value[1]}...]`;
      const formattedValue = (value as string[]).reduce(
        (acc, i, idx) => `${acc}${i}${idx < value.length - 1 ? ', ' : ''}`,
        '',
      );
      return `_filter_${formattedFilterBy}:[${formattedValue}]`;
    }
    return '';
  });
};

/**
 * Download Donut Chart + Legend by using a hidden DOM element to append clones of
 * Donut Chart + Legend nodes. Removes the cloned nodes after download.
 *
 * If Legend or Donut Chart config (size, radius, transform..) is changed, hard coded
 * <g> transform values should be updated..
 *
 * Source: https://stackoverflow.com/questions/55286191/combine-two-svg-elements-into-one-using-javascript
 */
export const downloadDonutChartWithLegend = (filename: string, chartName: string): void => {
  const divName = 'outer';
  const svgNS = 'http://www.w3.org/2000/svg';
  const outer = document.getElementById(divName);

  /* get legend content */
  const legend = document.getElementById(`${chartName}-donut-legend`);
  // svg element
  const legendSvg = legend?.getElementsByTagName('svg')[0];

  /* get chart content */
  const donutChart = document.getElementById(`${chartName}-donut-chart`);
  const donutChartSvg = donutChart?.getElementsByTagName('svg')[0];
  const donutChartGroup = donutChartSvg?.firstElementChild?.firstElementChild as SVGSVGElement; // nested g
  // const donutChartGroup = donutChartSvg?.childNodes)[0].getElementsByTagName('g')[0];

  /* create a merged-div to append the merged svgs */
  const merged = document.createElement('div');
  merged.setAttribute('id', 'merged-div');
  outer?.appendChild(merged);

  const mergedSvg = document.createElementNS(svgNS, 'svg');
  mergedSvg.setAttribute('id', 'merged');

  const duplicateChildNodes = (
    newParent: SVGSVGElement,
    prevParent: SVGSVGElement,
  ): SVGSVGElement => {
    // NodeList.prototype.forEach = Array.prototype.forEach;
    const children = prevParent.childNodes;
    children.forEach(item => {
      // cloning to keep original nodes
      const cln = item.cloneNode(true);
      newParent.appendChild(cln);
    });
    return newParent;
  };

  if (
    !(
      legendSvg &&
      !isNull(legendSvg.getAttribute('height')) &&
      donutChartSvg &&
      !isNull(donutChartSvg.getAttribute('height') && !isNull(donutChartSvg.getAttribute('width')))
    )
  ) {
    merged.remove();
    return;
  }

  /* use the larger of DonutChart and DonutLegend's heights */
  const LEGEND_HEIGHT = Number(legendSvg.getAttribute('height'));
  const HEIGHT = Math.max(LEGEND_HEIGHT, Number(donutChartSvg.getAttribute('height'))) + 20;
  const isLegendShort = LEGEND_HEIGHT < HEIGHT - 20;

  const WIDTH = Number(donutChartSvg.getAttribute('width')) * 2;
  mergedSvg.setAttribute('viewBox', `0 0 ${WIDTH} ${HEIGHT}`);
  merged.appendChild(mergedSvg);

  /* merge two svg contents */
  let newDonutChartGroup = document.createElementNS(svgNS, 'g') as SVGSVGElement;
  newDonutChartGroup.setAttribute('transform', 'translate(200, 160)'); // HARD Coding

  /* legend structure is different and need a missing group parent */
  let newLegendG = document.createElementNS(svgNS, 'g') as SVGSVGElement;
  const emptyG = document.createElementNS(svgNS, 'g') as SVGSVGElement;

  if (isLegendShort) {
    /* different alignment if legend is shorter than donut chart height */
    newLegendG.setAttribute('transform', 'translate(500, 60)'); // HARD Coding
  } else {
    newLegendG.setAttribute('transform', 'translate(500, 20)'); // HARD Coding
  }
  newLegendG.appendChild(emptyG);

  if (donutChartGroup) {
    newLegendG = duplicateChildNodes(newLegendG, legendSvg);
    mergedSvg.appendChild(newLegendG);
    newDonutChartGroup = duplicateChildNodes(newDonutChartGroup, donutChartGroup);
    mergedSvg.appendChild(newDonutChartGroup);
    FileUtils.exportSvgToPng('merged-div', `${filename}.png`);
  }

  /* remove temporary div */
  merged.remove();
};

export const downloadFileName = (params: {
  projectName: string;
  chart: string;
  date?: string;
  filter?: Record<string, string[] | []>; // user report only
}) => {
  const { projectName, chart, date, filter } = params;
  const todaysDate = DateUtils.getTodaysDate();
  return `${projectName}_${chart}${filterDisplay(filter)}_${date || todaysDate}`;
};
/**
 * Download 'csv' | 'xlsx'
 * Filename ex. Art_and_Artist_image_category_counts_2021-01-17.csv
 */
export const downloadDataFile = ({
  downloadData,
  projectName,
  chartName,
  excelSheetName,
  filter,
}: {
  downloadData: JsonObj[];
  projectName: string;
  chartName: string;
  excelSheetName?: string;
  filter?: Record<string, string[] | []>;
}): void => {
  const filename = downloadFileName({
    projectName: snakeCase(projectName),
    chart: snakeCase(chartName),
    date: DateUtils.getTodaysDate(),
    filter,
  });

  if (excelSheetName) {
    FileUtils.exportToExcel(downloadData, 'json', excelSheetName, filename);
    return;
  }
  FileUtils.exportToCsv(downloadData, null, filename);
};

export const downloadImageFile = ({
  projectName,
  chartDisplayName,
  chartName,
  chartType,
  filter,
}: {
  projectName: string;
  chartDisplayName: string;
  chartName: string; // internal name ex. workerStats | projectProgress | objectClassStats | categoryStats
  chartType: 'line' | 'donut' | 'bar' | 'histogram';
  filter?: Record<string, string[] | []>;
}): void => {
  const todaysDate = DateUtils.getTodaysDate();
  const projectDisplayName = projectName.split(' ').join('_');

  const filename = `${projectDisplayName}_${snakeCase(chartDisplayName)}${filterDisplay(
    filter,
  )}_${todaysDate}-${chartType}-chart`;

  if (chartType === 'donut') {
    downloadDonutChartWithLegend(filename, chartName);
    return;
  }

  FileUtils.exportSvgToPng(chartName, filename);
};

export const getDateDistance = (
  date: Date | string | undefined,
  defaultDate: Date | string,
): string => {
  return isDate(date) ? formatDistanceShort(date) : String(defaultDate);
};

export const displayDistanceTime = (
  isLoading: boolean,
  syncTimeDistance: string,
  dataNotLabeled?: boolean,
): string => {
  return dataNotLabeled || !isLoading ? `${syncTimeDistance} ago ` : ' Updating ...';
};

export const getClassIdToName = (data: JsonObj[]) => {
  return data.reduce((acc, item) => {
    return { ...acc, [item?.classId]: item?.name };
  }, {});
};

export const getClassAnnotationType = (
  data: { annotationType: string; id: string }[],
): {
  [key: string]: string;
} => {
  return data.reduce((acc, item) => {
    return { ...acc, [item.id]: item.annotationType };
  }, {});
};

export const addAnnotationType = (
  data: ObjectCountResult[],
  annotationTypes: { [key: string]: string },
): ObjectCountResult[] => {
  return data.map(d => {
    if (!d?.classId) return d;
    return { ...d, annotationType: annotationTypes[d.classId] };
  });
};

export const getAnnotationTypeIcon = (annoType: AnnotationType | 'cuboid2d'): ComponentType => {
  return {
    box: Box,
    cuboid: Cuboid, // pointcloud
    cuboid2D: Cuboid, // image
    cuboid2d: Cuboid, // video
    keypoint: Keypoints,
    keypoints: Keypoints, // video
    polygon: Polygon,
    polygons: Polygon, // video-siesta, remove when deprecated
    polyline: Polyline,
    rbox: RotatedBox,
    tiltedbox: 'tiltedbox', // image-default, remove when deprecated
  }[annoType];
};
