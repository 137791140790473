import { PropsWithChildren } from 'react';

import { EditFilter } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/ui';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import SelectItemsDropdown from '../../../../../../elements/SelectItemsDropdown';
import SliceActionButton from '../../../actionButton/SliceActionButton';

export default function GridActions({ children }: PropsWithChildren<{}>) {
  const {
    selectedData,
    setSelectedData,
    deselectedData,
    setDeselectedData,
    selectedAllData,
    setSelectedAllData,
  } = useActionContext();
  const { isFilterOpen, setIsFilterOpen, hasAppliedFilters } = useObjectFilterContext();

  return (
    <>
      <SelectItemsDropdown
        {...{
          selectedData,
          setSelectedData,
          deselectedData,
          setDeselectedData,
          selectedAllData,
          setSelectedAllData,
        }}
      />
      <SliceActionButton />
      <IconButton
        icon={EditFilter}
        badge={hasAppliedFilters}
        badgeProps={{ style: { width: '6px', height: '6px' } }}
        color="gray"
        variant="soft-fill"
        size="m"
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      />
    </>
  );
}
