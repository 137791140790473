import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { Modal } from '@superb-ai/norwegian-forest';
import { Box, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../contexts/ObjectScopeContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import SliceNameInput from '../../../../../elements/NameInput/SliceNameInput';
import { useDatasetObjectCountQuery } from '../../../../../queries/datasetObjectQueries';
import {
  useUpdateSliceJobByAnnosMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { SCATTER_VIEW } from '../../../../../types/viewTypes';
import { getView } from '../../../../../utils/routeUtils';
import { useObjectScatterContext } from '../../analytics/contexts/ObjectScatterContext';
import { AnnotationFilterSchema, CLUSTER_LEVEL_2_SIZE_8 } from '../../filter/types';
import SliceSummary from './SliceSummary';

export type ObjectCreateSliceRequestParams = {
  name: string;
  description: string;
  appliedFilters?: AnnotationFilterSchema;
};

export default function ObjectCreateSliceModal({
  createSliceModalIsOpen,
  setCreateSliceModalIsOpen,
}: {
  createSliceModalIsOpen: boolean;
  setCreateSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId, sliceId } = useParams<{
    accountName: string;
    datasetId: string;
    sliceId?: string;
  }>();
  const { t } = useTranslation();
  const { createSlice } = useCurateDatasetService();
  const { selectedData, setSelectedData, selectedAllData, setSelectedAllData } = useActionContext();
  const { sampledCount } = useObjectScatterContext();
  const { totalCount } = useObjectScopeContext();

  const selectedImageIds = Array.from(
    selectedData.reduce((acc, data) => {
      if (data.imageId) acc.add(data.imageId);
      return acc;
    }, new Set<string>()),
  );
  const history = useHistory();
  const viewType = getView(history);
  const isScatterView = viewType === SCATTER_VIEW;
  const { queryString } = useQueryContext();
  const commandContext = useCurateCommandContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { appliedFilters, hasAppliedFilters } = useObjectFilterContext();

  const { data: annotations, isLoading: isLoadingAnnotationCount } = useDatasetObjectCountQuery({
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    queryString,
    appliedFilters,
  });

  const { mutateAsync: startUpdateSliceJob } = useUpdateSliceJobWithIdsMutation({
    datasetId,
    commandContext,
    selectedImageIds,
  });

  const { mutateAsync: startUpdateSliceJobByAnnos } = useUpdateSliceJobByAnnosMutation({
    datasetId,
    commandContext,
    queryString,
    annotationFilter: appliedFilters || {
      metadata_in: {},
      cluster_level: CLUSTER_LEVEL_2_SIZE_8,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isValidName, setIsValidName] = useState<boolean>(false);

  const [createSliceName, setCreateSliceName] = useState<string>('');
  const [createSliceDescription, setCreateSliceDescription] = useState<string>('');

  // exclude data count for slice created from object scope
  const trackDataSliceCreated = useCallback(
    (newSliceId: string) => {
      analyticsTracker.dataSliceCreated({
        accountId: accountName,
        datasetId,
        sliceId: newSliceId,
        viewType: viewType,
        dataType: 'image',
        referrer: 'explore-object',
        filters: combineFiltersForTracking(appliedFilters, sliceId),
        queryString,
      });
    },
    [accountName, appliedFilters, datasetId, queryString, sliceId, viewType],
  );

  const resetSelection = useCallback(() => {
    if (setSelectedAllData && setSelectedData) {
      setSelectedAllData(false);
      setSelectedData([]);
    }
  }, [setSelectedAllData, setSelectedData]);

  const handleClickCreate = async ({ name, description }: ObjectCreateSliceRequestParams) => {
    try {
      setIsLoading(true);
      const res = await createSlice({ name, description, datasetId });
      if (selectedData?.length > 0) {
        startUpdateSliceJob({ sliceId: res.id });
      } else {
        startUpdateSliceJobByAnnos({ sliceId: res.id });
      }
      return res;
    } finally {
      setIsLoading(false);
      resetSelection();
    }
  };

  const handleCreateSlice = async () => {
    const newSlice = await handleClickCreate({
      name: createSliceName,
      description: createSliceDescription,
      appliedFilters,
    });
    trackDataSliceCreated(newSlice?.id);

    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
  };

  const handleCloseButton = useCallback(() => {
    setCreateSliceModalIsOpen(false);
    setCreateSliceName('');
    setCreateSliceDescription('');
  }, [setCreateSliceModalIsOpen, setCreateSliceName]);

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setCreateSliceDescription(e.target.value);
    },
    [setCreateSliceDescription],
  );

  const selectedObjects = selectedData?.length || annotations?.count || 0;

  return (
    <Modal
      open={createSliceModalIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.createSlice')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleCreateSlice,
        color: 'primary',
        isLoading,
        disabled: !isValidName || createSliceName === '',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box mt={2} mx={4}>
        {annotations && (
          <SliceSummary
            selectedObjects={selectedObjects}
            // imageCount={imageToAddToSlice()}
            pt={2}
            px={2}
          />
        )}
      </Box>
      <Box py={2.5} px={4} style={{ minWidth: '520px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="black">
            {t('curate.slices.sliceName')}
          </Typography>
          <Typography variant="m-regular" color="black">
            {`${createSliceName?.length}/50`}
          </Typography>
        </div>
        <SliceNameInput
          placeholder={t('curate.dialogs.placeholder.name')}
          sliceName={createSliceName}
          setSliceName={setCreateSliceName}
          setIsValid={setIsValidName}
          datasetId={datasetId}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="black" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}{' '}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>
          <Typography variant="m-regular" color="black">
            {`${createSliceDescription?.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleDatasetDescription}
          value={createSliceDescription}
        ></StyledTextarea>
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
