type ToastEvent = 'PUSH' | 'CHANGE' | 'CHANGE_STATE';

type Callback = (...args: any[]) => void;
interface ToastEventManager {
  list: Map<ToastEvent, Callback[]>;
  on(event: ToastEvent, callback: Callback): ToastEventManager;
  off(event: ToastEvent, callback?: Callback): ToastEventManager;
  emit(event: ToastEvent, ...args: any[]): void;
}

const toastEventManager: ToastEventManager = {
  list: new Map(),

  on(event: ToastEvent, callback: Callback): ToastEventManager {
    let callbacks = this.list.get(event);
    if (!callbacks) {
      callbacks = [];
      this.list.set(event, callbacks);
    }
    callbacks.push(callback);
    return this;
  },
  off(event: ToastEvent, callback?: Callback): ToastEventManager {
    if (!callback) {
      // remove all callbacks for event
      this.list.delete(event);
    } else {
      // remove specific callback
      const callbacks = this.list.get(event)?.filter(cb => cb !== callback) || [];
      this.list.set(event, callbacks);
    }
    return this;
  },
  emit(event: ToastEvent, ...args: any[]): void {
    const callbacks = this.list.get(event) || [];
    callbacks.forEach(callback => {
      setTimeout(() => {
        callback(...args);
      }, 0);
    });
  },
};

// eslint-disable-next-line import/prefer-default-export
export { toastEventManager };
