import { cloneDeep } from 'lodash';

import { getProjectSteps } from '../../../../configs/NewProjectStepsConfig';
import { ANNOTATION_TYPE } from '../../../../consts/NewProjectConst';
import ProjectConst from '../../../../consts/ProjectConst';
import { WorkApp } from '../../../../union/WorkappUnion';

// eslint-disable-next-line
const updateDataTypes = (
  newProjectInfo: any,
  clickedDataType: string,
  clickedWorkapp: WorkApp,
  lidar: boolean,
): void => {
  if (
    newProjectInfo.selectedDataType === clickedDataType &&
    newProjectInfo.selectedWorkapp === clickedWorkapp
  ) {
    newProjectInfo.setSelectedDataType('');
    newProjectInfo.setSelectedWorkapp();
    newProjectInfo.setSelectedAnnotationTypes([]);
    newProjectInfo.setSteps(getProjectSteps({}));
  } else {
    newProjectInfo.setSelectedDataType(clickedDataType);
    newProjectInfo.setSelectedWorkapp(clickedWorkapp);

    const nextAnnotationTypes = (
      ProjectConst.dataTypes(lidar)[clickedDataType]?.annotations ?? []
    ).map((item: any) => item.name);
    newProjectInfo.setSelectedAnnotationTypes(nextAnnotationTypes);

    const nextSelectedAnnotationTypes = {};
    newProjectInfo.setSelectedAnnotationTypes(nextSelectedAnnotationTypes);
    newProjectInfo.setSteps(getProjectSteps({}));
  }
};

// eslint-disable-next-line
const updateAnnotationTypes = (
  newProjectInfo: any,
  annotationType: string,
  lidar: boolean,
): void => {
  const nextSelectedAnnotationTypes = cloneDeep(newProjectInfo.selectedAnnotationTypes);

  if (nextSelectedAnnotationTypes[annotationType]) {
    delete nextSelectedAnnotationTypes[annotationType];
  } else {
    nextSelectedAnnotationTypes[annotationType] = true;
  }
  newProjectInfo.setSelectedAnnotationTypes(nextSelectedAnnotationTypes);

  let hasObjectClass = false;
  let hasImageCategory = false;

  const { annotations } = ProjectConst.dataTypes(lidar)[newProjectInfo.selectedDataType] ?? [];
  for (const annotation of annotations) {
    const type = annotation.type;
    switch (type) {
      case ANNOTATION_TYPE.BOX.value:
      case ANNOTATION_TYPE.CUBOID.value:
      case ANNOTATION_TYPE.CUBOID2D.value:
      case ANNOTATION_TYPE.ROTATED_BOX.value:
      case ANNOTATION_TYPE.KEYPOINT.value:
      case ANNOTATION_TYPE.POLYLINE.value:
      case ANNOTATION_TYPE.POLYGON.value:
        if (nextSelectedAnnotationTypes[type]) hasObjectClass = true;
        break;
      case ANNOTATION_TYPE.IMAGE_CATEGORY.value:
        if (nextSelectedAnnotationTypes[type]) hasImageCategory = true;
        break;
      default:
        break;
    }
  }

  const steps = getProjectSteps({
    dataType: newProjectInfo.selectedDataType,
    hasObjectClass,
    hasImageCategory,
  });
  newProjectInfo.setSteps(steps);
};

export default {
  updateDataTypes,
  updateAnnotationTypes,
};
