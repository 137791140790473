// https://stackoverflow.com/questions/66507201/material-ui-creatable-multi-select
// https://react-select.com/creatable

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@mui/material';
import { Check, Clear, Plus, WarningFilled, WarningOutline } from '@superb-ai/icons';
import { Box, Button, Icon, Input, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { useAddIpWhitelistMutation } from '../../../../queries/useIpWhitelistQuery';

function isNetworkAddress(ip: string): boolean {
  const ipSections = ip.split('.').map(Number);

  // For simplicity, consider an address a network address if it ends with .0
  // More complex logic can be added for a thorough check
  return ipSections[ipSections.length - 1] === 0;
}
function checkIpAddressValid(input: string): string | false {
  // Check if the input already has CIDR notation
  const hasCidr = input.includes('/');

  // Append /32 if input is a valid IP address without CIDR notation
  if (!hasCidr) {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (ipRegex.test(input) && !isNetworkAddress(input)) input += '/32';
  }

  const cidrRegex = /^(\d{1,3}\.){3}\d{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/;

  if (!cidrRegex.test(input)) {
    return false;
  }

  const [ip, subnetStr] = input.split('/');
  const ipSections = ip.split('.').map(Number);
  const subnet = parseInt(subnetStr, 10);

  // Check if each section of the IP address is between 0 and 255
  for (const section of ipSections) {
    if (section < 0 || section > 255) {
      return false;
    }
  }

  // Calculate the number of bits for the host portion
  const hostBits = 32 - subnet;
  const hostBitMask = (1 << hostBits) - 1;

  // Combine the IP sections into a single integer
  const ipNum = ipSections.reduce((acc, val) => (acc << 8) + val);

  // Check if the host portion of the IP address is all zeros
  return (ipNum & hostBitMask) === 0 ? input : false;
}

export default function IpWhitelistAdder() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addIpWhitelist } = useAddIpWhitelistMutation((message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  });

  const [inProgress, setInProgress] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [validIpAddress, setValidIpAddress] = useState<string>('');
  const [description, setDescription] = useState('');
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const validatedIpAddress = checkIpAddressValid(ipAddress);
    if (typeof validatedIpAddress === 'string') {
      setValidIpAddress(validatedIpAddress);
      setHasError(false);
    } else {
      setValidIpAddress('');
      setHasError(true);
    }
  }, [ipAddress]);

  function handleConfirm() {
    if (hasError) return;
    if (description.length === 0) return;

    addIpWhitelist({ ip: validIpAddress, description });
    setInProgress(false);
    setIpAddress('');
    setDescription('');
  }

  if (!inProgress) {
    return (
      <TableRow sx={{ '& td': { border: 0 } }}>
        <TableCell colSpan={5}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button variant="text" onClick={() => setInProgress(true)} style={{ width: '100%' }}>
              <Icon icon={Plus} size={16} color="primary-400" />
              <Typography variant="m-strong" color="primary-400">
                {t('settings.security.ipWhitelistAddNewIp')}
              </Typography>
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow sx={{ '& td': { border: 0 }, backgroundColor: '#FFF8F8' }}>
      <TableCell>
        <Box position="relative" style={{ width: '160px', height: '32px' }}>
          <Input
            autoFocus
            size="m"
            placeholder="Input IP Address"
            value={ipAddress}
            onChange={e => setIpAddress(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleConfirm();
              }
            }}
            suffix={hasError ? <Icon icon={WarningFilled} size={16} color="red-400" /> : null}
          />
          {hasError && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid"
              borderColor="red-400"
              position="absolute"
              backgroundColor="primary-100"
              mt={1}
              p={0.5}
            >
              <Icon
                icon={WarningOutline}
                size={16}
                color="red-400"
                style={{ marginRight: '2px' }}
              />
              <Typography variant="m-regular" color="red-400">
                {t('settings.security.ipWhitelistInvalidIp')}
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box position="relative">
          <Input
            size="m"
            placeholder={t('settings.security.ipWhitelistAddNewIpDescriptionPlaceholder')}
            value={description}
            onChange={e => setDescription(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleConfirm();
              }
            }}
          />
          {!hasError && description.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid"
              borderColor="red-400"
              position="absolute"
              backgroundColor="primary-100"
              mt={1}
              p={0.5}
            >
              <Icon
                icon={WarningOutline}
                size={16}
                color="red-400"
                style={{ marginRight: '2px' }}
              />
              <Typography variant="m-regular" color="red-400">
                {t('settings.security.ipWhitelistInvalidIpDescription')}
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell colSpan={3}>
        <Button
          variant="stroke"
          onClick={() => setInProgress(false)}
          style={{ marginRight: '8px' }}
        >
          <Icon icon={Clear} size={16} color="primary-400" />
          <Typography variant="m-strong" color="primary-400">
            {t('settings.security.ipWhitelistAddNewIpCancel')}
          </Typography>
        </Button>
        <Button
          variant="strong-fill"
          color="primary"
          disabled={hasError || description.length === 0}
          onClick={() => {
            handleConfirm();
          }}
        >
          <Icon icon={Check} size={16} />
          <Typography variant="m-strong">
            {t('settings.security.ipWhitelistAddNewIpConfirm')}
          </Typography>
        </Button>
      </TableCell>
    </TableRow>
  );
}
