import { useTranslation } from 'react-i18next';

import type { i18n as I18N } from 'i18next';
import { startCase } from 'lodash';

import { Option } from './convert';

function isOption<V>(option: any): option is { value: V } {
  return typeof option.value !== 'undefined';
}

export function getTranslatedOption<V extends string>({
  i18n,
  option,
  i18nKeyPrefix,
}: {
  i18n: I18N;
  option: V | { value: V; label: string };
  i18nKeyPrefix?: string;
}) {
  function getLabel(option: V) {
    const fallback = startCase(option.toLocaleLowerCase());
    const i18nKey = `${i18nKeyPrefix}.${option}`;
    if (!i18nKeyPrefix || !i18n.exists(i18nKey)) return fallback;
    return i18n.t(i18nKey, { defaultValue: fallback });
  }

  const value = isOption(option) ? option.value : option;
  return Option(value, getLabel(value));
}

/**
 * Converts a list of option values into { value, label } objects
 * using translation keys or an automatic fallback text formatting.
 */
export function useOptions<V extends string>({
  options = [],
  i18nKeyPrefix,
}: {
  options?: V[] | { value: V; label: string }[];
  i18nKeyPrefix?: string;
}) {
  const { i18n } = useTranslation();

  return options.map(option => getTranslatedOption({ option, i18n, i18nKeyPrefix }));
}
