import React, { Dispatch, SetStateAction } from 'react';

import { makeStyles } from '@mui/styles';
import { Box } from '@superb-ai/norwegian-forest';
import { Checkbox } from '@superb-ai/ui';
import cs from 'classnames';

import { ProjectData } from '../../../../types/projectTypes';
import { formatDate } from '../../../../utils/date';
import { overflowOverlayOrAuto } from '../../../../utils/style';

const useStyles = makeStyles(() => ({
  projectsTable: {
    height: '100%',
    fontSize: '12px',
    '& .tableRow': {
      lineHeight: '36px',
      display: 'grid',
      gridTemplateColumns: '46px 1fr 120px',
      '&.selectable': {
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
      '&.isSelected, &.selectable:hover': {
        backgroundColor: '#fffbfb',
      },
      '& > *': {
        padding: '0 18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
      },
      '& > :nth-last-child(1)': {
        textAlign: 'right',
        justifyContent: 'flex-end',
      },
      '& > :nth-last-child(2)': {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      '& .checkbox-cell': {
        padding: '0 10px',
        '& + *': {
          paddingLeft: '0',
        },
      },
      '& .emptyTableInfo': {
        gridColumn: '1 / span 5',
        justifyContent: 'flex-start',
        color: '#777',
      },
    },
  },
  tableHeader: {
    boxShadow: 'inset 0 -1px 0 0px #EFEFEF',
    fontWeight: 500,
  },
  allocationToolip: {
    whiteSpace: 'normal',
    textAlign: 'left',
    lineHeight: 1.4,
  },
  totalSelectMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '36px',
  },
}));

export interface ProjectsTableProps {
  projects: ProjectData[];
  columns: string[];
  selectable?: boolean;
  selectedProjects: ProjectData[];
  setSelectedProjects: Dispatch<SetStateAction<ProjectData[]>>;
}

const ColToLabel: Record<string, string> = {
  project: 'Project',
  joinedAt: 'Assigned Date',
};

const ProjectsTable: React.FC<ProjectsTableProps> = props => {
  const { selectable, columns, projects, selectedProjects, setSelectedProjects } = props;

  const classes = useStyles();

  const isSelectedAll = !!selectedProjects.length && selectedProjects.length === projects.length;

  const handleToggleSelectAll = () => {
    if (isSelectedAll) {
      setSelectedProjects([]);
      return;
    }
    setSelectedProjects(projects);
  };

  const Row: React.FC<{ item: ProjectData }> = ({ item }) => {
    const isSelected =
      selectedProjects && selectedProjects.findIndex(m => m.projectId === item.projectId) !== -1;

    const handleToggleSelectProject = () => {
      if (isSelected) {
        setSelectedProjects(
          selectedProjects.filter(project => project.projectId !== item.projectId),
        );
        return;
      }
      setSelectedProjects(selectedProjects.concat(item));
    };

    const handleCheckboxClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      handleToggleSelectProject();
    };
    // prettier-ignore
    const selectableProps = selectable ? {
      role: 'option',
      tabIndex: 0,
      'aria-selected': isSelected,
      onClick: handleToggleSelectProject,
    } : {};

    return (
      <div {...selectableProps} className={cs('tableRow', { isSelected, selectable })}>
        {selectable && (
          <span className="checkbox-cell">
            <Checkbox
              color="primary"
              tabIndex={-1}
              onClick={handleCheckboxClick}
              value={isSelected}
            />
          </span>
        )}
        <span>{item.name}</span>
        <span>{item.joinedAt && formatDate(item.joinedAt)}</span>
      </div>
    );
  };

  return (
    <div className={classes.projectsTable} role="listbox">
      <div className={cs(classes.tableHeader, 'tableRow')}>
        {selectable && (
          <span className="checkbox-cell">
            <Checkbox color="primary" onClick={handleToggleSelectAll} value={isSelectedAll} />
          </span>
        )}
        {columns.map(colName => (
          <span key={colName}>{ColToLabel[colName] || colName}</span>
        ))}
      </div>
      <Box height="212px" overflow={overflowOverlayOrAuto()}>
        {projects.length === 0 ? (
          <div className="tableRow">
            <div className="emptyTableInfo">No projects found</div>
          </div>
        ) : (
          projects.map(project => <Row key={project.projectId} item={project} />)
        )}
      </Box>
    </div>
  );
};

export default ProjectsTable;
