import * as React from 'react';
import { FileEntry, FileOrDir, IItemRendererProps, ItemType } from 'react-aspen';

import { ClasslistComposite } from 'aspen-decorations';

export interface IItemRendererXProps {
  decorations: ClasslistComposite;
  isDeletable: boolean;
  isSelectable: boolean;
  isVisibilityToggle: boolean;
  onClick: (item: FileOrDir, type: ItemType) => void;
  onClickDirectoryToggle: (item: FileOrDir, type: ItemType) => void;
  onDelete: (item: FileOrDir, type: ItemType) => void;
  toggleVisibility: (item: FileOrDir, type: ItemType) => void;
  showCheckbox?: boolean;
  showIcon?: boolean;
}

abstract class TreeItem extends React.Component<IItemRendererXProps & IItemRendererProps> {
  static RenderHeight = 0; // set in sub class

  constructor(props: Readonly<IItemRendererXProps & IItemRendererProps>) {
    super(props);
    // used to apply decoration changes
    this.forceUpdate = this.forceUpdate.bind(this);
  }

  public componentDidMount(): void {
    if (this.props.decorations) {
      this.props.decorations.addChangeListener(this.forceUpdate);
    }
  }

  public componentDidUpdate(prevProps: IItemRendererXProps): void {
    if (prevProps.decorations) {
      prevProps.decorations.removeChangeListener(this.forceUpdate);
    }
    if (this.props.decorations) {
      this.props.decorations.addChangeListener(this.forceUpdate);
    }
  }

  public componentWillUnmount(): void {
    if (this.props.decorations) {
      this.props.decorations.removeChangeListener(this.forceUpdate);
    }
  }

  protected handleDelete = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const { item, itemType, onDelete } = this.props;
    if (itemType === ItemType.File || itemType === ItemType.Directory) {
      onDelete(item as FileEntry, itemType);
    }
  };

  protected handleClick = (): void => {
    const { item, itemType, onClick } = this.props;
    if (itemType === ItemType.File || itemType === ItemType.Directory) {
      onClick(item as FileEntry, itemType);
    }
  };

  protected handleClickDirectoryToggle = (): void => {
    const { item, itemType, onClickDirectoryToggle } = this.props;
    if (itemType === ItemType.File || itemType === ItemType.Directory) {
      onClickDirectoryToggle(item as FileEntry, itemType);
    }
  };

  protected handleKey = (ev: React.KeyboardEvent): void => {
    const { item, itemType, onClick } = this.props;
    if (ev.key === ' ' || ev.key === 'Enter') {
      onClick(item as FileEntry, itemType);
    }
  };

  protected toggleVisibility = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const { item, itemType, toggleVisibility } = this.props;
    if (itemType === ItemType.File || itemType === ItemType.Directory) {
      toggleVisibility(item as FileEntry, itemType);
    }
  };

  abstract render(): JSX.Element;
}

export default TreeItem;
