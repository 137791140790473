import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import DataLayout from '../../../../../components/pages/data/dataList/Layout';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu/MenuItem';

export const DataListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelDataList, // TODO: check if deprecated
  path: 'data',
  title: ({ t }) => t('data.title'),
  component: DataLayout,
  icon: 'data',
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  isVisible: false,
});
