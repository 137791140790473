import { ReactNode } from 'react';

import { Box, Typography } from '@superb-ai/ui';

export default function Title({ children }: { children: ReactNode }) {
  return (
    <Box mb={1}>
      <Typography variant="h1" color="gray-400">
        {children}
      </Typography>
    </Box>
  );
}
