import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { XYChartElementEvent } from '@elastic/charts';
import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Chip, Icon, Tooltip, Typography } from '@superb-ai/ui';
import { snakeCase } from 'lodash';

import analyticsTracker from '../../../../../../../../../analyticsTracker';
import FileUtils from '../../../../../../../../../utils/FileUtils';
import { useDiagnosisAnalyticsFilterContext } from '../../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { useDiagnosisCommonFilterContext } from '../../../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisModelContext } from '../../../../../../../contexts/DiagnosisModelContext';
import {
  useConfidenceHistogramQuery,
  useIouHistogramQuery,
} from '../../../../../../../queries/diagnosisAnalyticsQueries';
import {
  CONFIDENCE_HISTOGRAM_INTERVAL,
  IOU_HISTOGRAM_INTERVAL,
} from '../../../../../../../services/DiagnosisAnalyticsService';
import { GRID_VIEW } from '../../../../../../../types/viewTypes';
import { getSearchQueryRoute } from '../../../../../../../utils/routeUtils';
import {
  CustomChartConfig,
  Histogram,
  HistogramOptionalProps,
} from '../../../../analytics/charts/Histogram';
import { getXYChartKey } from '../../../../analytics/charts/utils';
import { MetricDatum } from '../../types';
import { getDistributionHistogramTheme } from '../charts/customTheme';
import { chartColors } from '../colorScale';
import { mapKeyToRange, roundAndSortDownload } from '../download';
import { SplitChartCard } from '../SplitChartCard';
import { getDisplayClassName } from '../transformer';
import { CardProps } from './ConfusionMatrixCard';

const DistributionChartCard = ({ diagnosis }: CardProps) => {
  const { t } = useTranslation();
  const { selectedDiagnosis, targetIou } = useDiagnosisModelContext();
  const history = useHistory();
  const { datasetId, accountName } = useParams<{ datasetId: string; accountName: string }>();
  const { selectedClass } = useDiagnosisAnalyticsFilterContext();
  const { sliceId } = useDiagnosisCommonFilterContext();

  const dependencies = {
    datasetId,
    diagnosisId: diagnosis.id,
    modelSource: selectedDiagnosis?.modelSource,
    sliceId,
  };

  const iouQuery = useIouHistogramQuery({
    ...dependencies,
    ...(selectedClass !== 'all' && { predictionClass: selectedClass }),
  });
  const confidenceQuery = useConfidenceHistogramQuery({
    ...dependencies,
    ...(selectedClass !== 'all' && { predictionClass: selectedClass }),
  });

  const histogramColor = chartColors.singleModelLight[1];

  const downloadData = (
    data: Record<string, any>[],
    selectedClass: string,
    metric: 'iou' | 'confidence',
    orderedColumnNames: string[],
  ) => {
    if (!data) return;
    const timestamp = new Date().toISOString();
    const fileName = `${diagnosis?.modelName}_${
      selectedClass === 'all' ? 'all_classes' : selectedClass
    }_${timestamp}_${metric}_distribution`;
    const rounded = roundAndSortDownload({ data });
    const downloadData = mapKeyToRange(rounded as MetricDatum[]);
    FileUtils.exportToCsv(downloadData, orderedColumnNames, fileName);
  };

  const handleIOUDownload = () => {
    const iou = iouQuery?.data;
    if (!iou) return;
    const columnNames = [
      snakeCase(t('curate.diagnosis.chart.iouRange')),
      snakeCase(t('curate.diagnosis.chart.objectCount')),
    ];
    downloadData(iou, selectedClass, 'iou', columnNames);
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: 'iou-distribution',
      fileExtension: 'csv',
      feature: 'model-diagnosis',
    });
  };

  const handleConfidenceDownload = () => {
    const confidence = confidenceQuery?.data?.histogram;
    if (!confidence) return;
    const columnNames = [
      snakeCase(t('curate.diagnosis.chart.confidenceRange')),
      snakeCase(t('curate.diagnosis.chart.objectCount')),
    ];
    downloadData(confidence, selectedClass, 'confidence', columnNames);
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: 'confidence-distribution',
      fileExtension: 'csv',
      feature: 'model-diagnosis',
    });
  };

  const trackChartClick = (chartName: string) => {
    analyticsTracker.chartClicked({
      accountId: accountName,
      chartName,
      feature: 'model-diagnosis',
    });
  };
  const iouOnElementListeners = {
    onBrushEnd: (brushEvent: any) => {
      const rangeFilter = {
        min: Number(brushEvent?.x[0]),
        max: Number(brushEvent?.x[1]),
      };
      const route = getSearchQueryRoute(history, {
        metric: 'iou',
        view: GRID_VIEW,
        ...rangeFilter,
        ...(selectedClass && { prediction: selectedClass }),
      });
      history.push(route);
      trackChartClick('iou-distribution');
    },
    onElementClick: (events: XYChartElementEvent[]): void => {
      const rangeFilter = {
        min: Number(getXYChartKey(events)),
        max: Number(getXYChartKey(events)) + IOU_HISTOGRAM_INTERVAL,
      };
      const route = getSearchQueryRoute(history, {
        metric: 'iou',
        view: GRID_VIEW,
        ...rangeFilter,
        ...(selectedClass && { prediction: selectedClass }),
      });
      history.push(route);
      trackChartClick('iou-distribution');
    },
  };

  const confidenceOnElementListeners = {
    onBrushEnd: (brushEvent: any) => {
      const rangeFilter = {
        min: Number(brushEvent?.x[0]),
        max: Number(brushEvent?.x[1]),
      };
      const route = getSearchQueryRoute(history, {
        metric: 'confidence',
        view: GRID_VIEW,
        ...rangeFilter,
        ...(selectedClass && { prediction: selectedClass }),
      });
      history.push(route);
      trackChartClick('confidence-distribution');
    },
    onElementClick: (events: XYChartElementEvent[]): void => {
      const rangeFilter = {
        min: Number(getXYChartKey(events)),
        max: Number(getXYChartKey(events)) + CONFIDENCE_HISTOGRAM_INTERVAL,
      };
      const route = getSearchQueryRoute(history, {
        metric: 'confidence',
        view: GRID_VIEW,
        ...rangeFilter,
        ...(selectedClass && { prediction: selectedClass }),
      });
      history.push(route);
      trackChartClick('confidence-distribution');
    },
  };

  const optionalProps = {
    tooltipFooterText: t('curate.diagnosis.action.clickToInspectObjects'),
  } as HistogramOptionalProps;

  const getSeriesConfig = (seriesId: string) => {
    return {
      series: {
        seriesId,
        xAccessor: 'key',
        yAccessor: 'count',
      },
    };
  };
  const getHistogramConfig = (metric: string) => {
    const metricDisplayText = {
      iou: t('curate.diagnosis.metric.iouAbbr'),
      confidence: t('curate.diagnosis.metric.confidence'),
    }[metric];
    return {
      axes: {
        yAxisTitle: t('curate.diagnosis.chart.objectCount'),
        xAxisTitle: metricDisplayText,
      },
      tooltipHeader: metricDisplayText,
      ...getSeriesConfig(t('curate.diagnosis.chart.objectCount')),
    } as CustomChartConfig;
  };

  const distributionCharts = [
    {
      title: t('curate.diagnosis.chart.distribution', {
        metric: t('curate.diagnosis.metric.iouAbbr'),
      }),
      topLeftArea: (
        <Box display="flex" gap={1} alignItems="center">
          <Tooltip
            content={
              <Typography>
                {t('curate.diagnosis.chart.iouHistogramInfo', {
                  lessThanSymbol: '<',
                  iouThreshold: targetIou,
                })}
              </Typography>
            }
          >
            <Icon icon={InfoCircleOutline} />
          </Tooltip>
          <Chip color="primary">{getDisplayClassName(selectedClass, t)}</Chip>
        </Box>
      ),
      component: (
        <Histogram
          data={iouQuery.data ?? []}
          color={histogramColor}
          config={getHistogramConfig('iou')}
          theme={getDistributionHistogramTheme(histogramColor)}
          onElementListeners={iouOnElementListeners}
          optional={optionalProps}
        />
      ),
      isLoading: iouQuery?.isLoading ?? true,
      handleDownload: handleIOUDownload,
    },
    {
      title: t('curate.diagnosis.chart.distribution', {
        metric: t('curate.diagnosis.metric.confidence'),
      }),
      topLeftArea: <Chip color="primary">{getDisplayClassName(selectedClass, t)}</Chip>,
      component: (
        <Histogram
          data={confidenceQuery.data?.histogram || []}
          color={histogramColor}
          config={getHistogramConfig('confidence')}
          theme={getDistributionHistogramTheme(histogramColor)}
          onElementListeners={confidenceOnElementListeners}
          optional={optionalProps}
        />
      ),
      isLoading: confidenceQuery?.isLoading ?? true,
      handleDownload: handleConfidenceDownload,
    },
  ];
  return <SplitChartCard charts={distributionCharts} />;
};

export default DistributionChartCard;
