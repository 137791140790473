import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import {
  GenerateImage,
  InfoCircleOutline,
  LinkExternal,
  Model,
  QueryBuilder,
} from '@superb-ai/icons';
import { Box, Button, Chip, Icon, IconButton, Tooltip, Typography } from '@superb-ai/ui';

import { getUrl } from '../../../../../../../../routes/util';
import { useModelDetailQuery } from '../../../../../../../Model/queries/modelQueries';
import { CurateImageData } from '../../../../../../services/DatasetService';
import { getSearchQueryRoute } from '../../../../../../utils/routeUtils';
import { excludedImageMetadataKeys } from '../image/const';
import DetailModalCabinetCollapse from '../image/DetailModalCabinetCollapse';

export default function SyntheticImageInfo({
  imageInfo,
  closeModal,
}: {
  imageInfo: CurateImageData;
  closeModal: () => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountName, suite, datasetId } = useParams<{
    accountName: string;
    suite: string;
    datasetId: string;
  }>();

  const syntheticImageMetadataEntries = Object.fromEntries(
    Object.entries(imageInfo.metadata).filter(([key]) => excludedImageMetadataKeys.includes(key)),
  );

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const { data: modelData } = useModelDetailQuery({
    id: syntheticImageMetadataEntries._gen_model_id,
    modelPurpose: 'generation',
    enabled: !!syntheticImageMetadataEntries._gen_model_id,
    retry: 0,
  });

  function queryOriginalImage() {
    const imageId = syntheticImageMetadataEntries._reference_image_id;
    if (!imageId) return;
    const queryString = `images.id = "${imageId}"`;
    history.push(getSearchQueryRoute(history, { query: queryString }));
    closeModal();
  }

  function queryOriginalAndRelatedImages() {
    const imageId = syntheticImageMetadataEntries._reference_image_id;
    if (!imageId) return;
    const queryString = `images.id = "${imageId}" OR images.metadata."_reference_image_id" = "${imageId}"`;
    history.push(
      getSearchQueryRoute(
        history,
        { query: queryString },
        getUrl([accountName, suite, 'dataset', datasetId, 'explore']),
      ),
    );
    closeModal();
  }

  return (
    <DetailModalCabinetCollapse
      title={
        <Box display="flex" alignItems="center" gap={0.5}>
          <Icon icon={GenerateImage} />
          {t('curate.datasets.detailView.syntheticImageDetails')}
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">
            {t('curate.datasets.detailView.generativeAIModel')}
          </Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Icon icon={Model} color="gray-250" />
            <Tooltip content={syntheticImageMetadataEntries._gen_model_id}>
              <Typography variant="m-regular" style={{ display: 'block', ...textEllipsisStyle }}>
                {modelData?.modelSetting.name || t('curate.datasets.detailView.deletedModel')}
              </Typography>
            </Tooltip>
            <IconButton
              icon={LinkExternal}
              size="s"
              variant="text"
              style={{ marginLeft: 'auto' }}
              disabled={!syntheticImageMetadataEntries._gen_model_id || !modelData}
              onClick={() =>
                history.push(
                  getUrl([
                    accountName,
                    'model',
                    'generation',
                    syntheticImageMetadataEntries._gen_model_id,
                  ]),
                )
              }
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="m-strong">
            {t('curate.datasets.detailView.originalImage')}
          </Typography>
          <Typography variant="m-regular" color="gray-400">
            {syntheticImageMetadataEntries._reference_image_id || '-'}
          </Typography>
          {/* <IconButton
              icon={ArrowRight}
              size="s"
              variant="text"
              style={{ marginLeft: 'auto' }}
              onClick={queryOriginalImage}
            /> */}
          <Button
            variant="stroke"
            size="s"
            color="gray"
            style={{ width: '100%' }}
            onClick={queryOriginalAndRelatedImages}
          >
            <Icon icon={QueryBuilder} />
            {t('curate.datasets.detailView.queryRelatedImages')}
          </Button>
        </Box>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="m-strong">{t('curate.datasets.detailView.generateMode')}</Typography>
          <Tooltip content={t('curate.datasets.detailView.generateModeTooltip')}>
            <Icon icon={InfoCircleOutline} />
          </Tooltip>
          {/* <Typography variant="m-regular" style={{ width: '50%', marginLeft: 'auto' }}>
            {syntheticImageMetadataEntries._gen_mode}
          </Typography> */}
          <Box style={{ width: '50%', marginLeft: 'auto' }}>
            <Chip color="gray">{syntheticImageMetadataEntries._gen_mode}</Chip>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="m-strong">{t('curate.datasets.detailView.strength')}</Typography>
          <Tooltip content={t('curate.datasets.detailView.strengthTooltip')}>
            <Icon icon={InfoCircleOutline} />
          </Tooltip>
          <Typography variant="m-regular" style={{ width: '50%', marginLeft: 'auto' }}>
            {syntheticImageMetadataEntries._gen_strength || '-'}
          </Typography>
        </Box>
      </Box>
    </DetailModalCabinetCollapse>
  );
}
