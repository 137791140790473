import { useTranslation } from 'react-i18next';

import { Clear } from '@superb-ai/icons';
import { Box, Dialog, IconButton, Typography, useDialogState } from '@superb-ai/ui';
import Image from 'next/image';

import { useAppContext } from '../../../contexts/AppContext';
import HubspotForm from '../Layout';
import Cloud from './Cloud.png';
import RocketnPerson from './RocketnPerson.png';

interface Props {
  state: ReturnType<typeof useDialogState>;
  showSuccessModal: () => void;
  description: JSX.Element;
  mainText?: JSX.Element;
  title: JSX.Element;
  hubspotFormId: string;
  clickId: string;
}

export default function TrialHubspotFormDialog({
  state,
  showSuccessModal,
  description,
  mainText,
  title,
  hubspotFormId,
  clickId,
}: Props) {
  const { t } = useTranslation();
  const { tenantCountry } = useAppContext();
  const portalId = '9384297';

  function handleSuccess() {
    showSuccessModal();
    state.hide();
  }

  return (
    <Dialog state={state} style={{ overflowX: 'hidden', maxHeight: 'calc(100vh - 116px)' }}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Image
          src="https://asset.superb-ai.com/assets/img/new_logo.png"
          width={200}
          height={33}
          alt="logo"
        />
        <IconButton variant="text" icon={Clear} onClick={state.hide} />
      </Box>
      <Dialog.Content>
        <Box width="100%" position="absolute" backgroundColor="gray-100" left="0">
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            position="relative"
            gap={2}
            px={4}
            style={{ minHeight: '180px', paddingRight: 300 }}
          >
            <Box position="absolute" bottom="0" right="0" display="flex">
              <Image src={Cloud} width={318} height={67} alt="cloud" />
            </Box>
            <Box position="absolute" style={{ bottom: '17px', right: '43px' }}>
              <Image src={RocketnPerson} width={197} height={145} alt="rocket & person" />
            </Box>
            <Typography
              variant="h1"
              color="primary"
              style={{ fontSize: '32px', fontWeight: 'bold' }}
            >
              {title}
            </Typography>
            <Typography variant="m-regular">{description}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={6}>
          <Box style={{ minHeight: '180px' }} />
          <Typography textAlign="center" variant="l-regular" style={{ fontSize: '18px' }}>
            {mainText}
          </Typography>
          <HubspotForm
            clickId={clickId}
            portalId={portalId}
            formId={hubspotFormId}
            showSuccessModal={handleSuccess}
            style={{ width: '760px', marginBottom: '-1em' }}
          />
        </Box>
      </Dialog.Content>
    </Dialog>
  );
}
