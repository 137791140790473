import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import autonet from '../assets/autonet';
import intutive from '../assets/intutivo';

export const companies = {
  intuitivo: {
    content: 'auth.quote.intuitivo',
    author: 'Jose Benitez',
    position: 'Founder & Director of AI',
    avatar: intutive.avatar.src,
    logo: intutive.logo.src,
  },
  autonet: {
    content: 'auth.quote.autonet',
    author: 'Blaine Bateman',
    position: 'Chief Data Scientist',
    avatar: autonet.avatar.src,
    logo: autonet.logo.src,
  },
} as const;

export default function Testimonial({ company }: { company: keyof typeof companies }) {
  const { content, author, position, avatar, logo } = companies[company];
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="h2" color="gray-400">
        {`“${t(content)}”`}
      </Typography>
      <Box display="flex" gap={1.5} alignItems="center">
        <img
          src={avatar}
          style={{ width: '70px', height: '70px', borderRadius: '50%', objectFit: 'cover' }}
        />
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
          <img src={logo} style={{ height: '18px', objectFit: 'contain' }} />
          <Box display="flex" flexDirection="column">
            <Typography variant="l-strong" color="gray-400">
              {author}
            </Typography>
            <Typography variant="s-regular" color="gray-300">
              {position}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
