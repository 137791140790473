/* eslint-disable react/jsx-key */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Box, Icon, IconName, Typography } from '@superb-ai/norwegian-forest';
import { capitalize, without } from 'lodash';

import { ModelDetail } from '../../../../../types/customAutoLabelTypes';
import { ObjectClass } from '../../../../../utils/LabelInterfaceUtils';
import { Engine } from '../settings/helper';
import CustomAutoLabelTableLayout from './CustomAutoLabelTableLayout';
import { getCurrentEngine } from './utils';

const ObjectDetectionTable: React.FC<{
  searchedObjectClasses: ObjectClass[];
  selectedObjectClasses?: string[];
  modelDetail?: ModelDetail;
  onChangeSelection: (list: string[]) => void;
  oldEnginePerClass: Map<string, (Engine | undefined)[]>;
  newEngine: string;
}> = ({
  searchedObjectClasses,
  selectedObjectClasses,
  modelDetail,
  onChangeSelection,
  oldEnginePerClass,
  newEngine,
}) => {
  const { t } = useTranslation();
  const getEngineList = (id: string) =>
    oldEnginePerClass
      .get(id)
      ?.map(engine => engine?.name || '')
      .join(', ') || '-';

  const [sortBy, setSortBy] = useState<string>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>();

  const classMetrics = modelDetail?.classMetrics;

  const contents = useMemo(() => {
    return searchedObjectClasses.map(objectClass => {
      const classMetric = classMetrics?.find(classMetric => classMetric.classId === objectClass.id);
      return {
        id: objectClass.id,
        failMsg: modelDetail?.failMsg,
        name: objectClass.name,
        type: objectClass.annotationType,
        precision: classMetric?.precision || 'N/A',
        recall: classMetric?.recall || 'N/A',
        engineList: getEngineList(objectClass.id),
      };
    });
  }, [searchedObjectClasses]);

  const sortedContents = useMemo(() => {
    return contents.sort((a, b) => {
      let res = 0;
      if (sortBy === 'Class') {
        res = a.name.localeCompare(b.name);
      }
      if (sortBy === 'Type') {
        res = a.type.localeCompare(b.type);
      }
      if (sortBy === 'Precision' || sortBy === 'Recall') {
        const formattedSortBy = sortBy.toLowerCase() as 'precision' | 'recall';
        res = a[formattedSortBy].toString().localeCompare(b[formattedSortBy].toString());
      }
      if (sortBy === 'Applied Auto Label AI') {
        res = a.engineList.localeCompare(b.engineList);
      }
      return res * (sortOrder === 'desc' ? -1 : 1);
    });
  }, [sortBy, sortOrder, contents]);

  const handleClickRow = (id: string) => () => {
    if (!selectedObjectClasses) {
      onChangeSelection([id]);
      return;
    }
    const index = selectedObjectClasses.indexOf(id);
    if (index >= 0) {
      onChangeSelection(without(selectedObjectClasses, id));
      return;
    }
    onChangeSelection([...selectedObjectClasses, id]);
  };

  function convertAnnotationType(name: string) {
    switch (name) {
      case 'keypoint':
        return 'keypoints';
      default:
        return name;
    }
  }

  const rows = sortedContents?.map(content => {
    const isSelected = !!selectedObjectClasses && selectedObjectClasses.indexOf(content.id) >= 0;

    return {
      id: content.id,
      isSelected,
      disabled: !content?.precision || !content?.recall,
      onClick: handleClickRow(content.id),
      failMsg: content?.failMsg,
      contents: [
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {content.name}
        </Typography>,
        <Box display="flex" alignItems="center" gap="8px">
          <Badge size="xxxs" color="strawberry">
            <Icon name={convertAnnotationType(content.type) as IconName} size="10px" />
          </Badge>
          <Typography style={{ lineHeight: '20px' }} variant="body4">
            {capitalize(content.type)}
          </Typography>
        </Box>,
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {content?.precision || 'N/A'}
        </Typography>,
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {content?.recall || 'N/A'}{' '}
        </Typography>,
        <Typography style={{ lineHeight: '20px' }} variant="body4">
          {content.engineList}
        </Typography>,
        <Typography style={{ lineHeight: '20px' }} variant="body4" themedColor="primary">
          {getCurrentEngine(isSelected, content.engineList, newEngine)}
        </Typography>,
      ],
    };
  });

  const isAllSelected =
    selectedObjectClasses?.length !== 0 &&
    (selectedObjectClasses?.length || 0) === (classMetrics?.length || 0);

  const handleCheckAll = () => {
    const searchedObjectClassesIds = searchedObjectClasses.map(objectClass => objectClass.id);
    if (isAllSelected) {
      onChangeSelection(
        selectedObjectClasses?.filter(selected => !searchedObjectClassesIds.includes(selected)) ||
          [],
      );
      return;
    }
    onChangeSelection(searchedObjectClassesIds);
  };

  const handleSort = (field: string, ascDesc: 'asc' | 'desc') => {
    setSortBy(field);
    setSortOrder(ascDesc);
  };

  return (
    <CustomAutoLabelTableLayout
      type="object"
      cols={
        <>
          <col />
          <col />
          <col width="80px" />
          <col width="80px" />
          <col width="176px" />
          <col width="176px" />
        </>
      }
      isAllChecked={isAllSelected}
      onCheckAll={handleCheckAll}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSort={handleSort}
      columns={[
        { name: t('applyCustomAutoLabelModal.tableHeaders.class'), sortable: true },
        { name: t('applyCustomAutoLabelModal.tableHeaders.type'), sortable: true },
        { name: t('applyCustomAutoLabelModal.tableHeaders.precision'), sortable: true },
        { name: t('applyCustomAutoLabelModal.tableHeaders.recall'), sortable: true },
        { name: t('applyCustomAutoLabelModal.tableHeaders.appliedAutoLabelAI'), sortable: true },
        { name: t('applyCustomAutoLabelModal.tableHeaders.newAutoLabelAI') },
      ]}
      rows={rows}
      checkedCount={selectedObjectClasses?.length || 0}
    />
  );
};

export default ObjectDetectionTable;
