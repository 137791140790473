import React from 'react';

import { Box, Icon, IconName, List, ListItem } from '@superb-ai/norwegian-forest';
import { GetThemeColorInput } from '@superb-ai/norwegian-forest/dist/theme';
import { escapeRegExp } from 'lodash';

import { Category } from '../../../../../../utils/LabelInterfaceUtils';
import { overflowOverlayOrAuto } from '../../../../../../utils/style';
import SearchBar from '../../../../../elements/SearchBar';
import { arePropertyOptionsEqual, Engine } from '../helper';
import CategoryRow from './CategoryRow';
import { useItemSelection, useSearch } from './hooks';

interface Props {
  selectedItem: Category;
  engine: Engine;
  value: string[];
  onChange(ids: string[]): void;
}

export default function SelectCategories(props: Props): JSX.Element {
  const { selectedItem, engine, value, onChange } = props;

  const { isItemSelected, toggleItem } = useItemSelection({
    value,
    multiple: true,
    onChange,
  });

  const { setSearchTerm, visibleItems, formatMatch } = useSearch({
    items: engine.labelInterface.categorization?.properties ?? [],
    predicate: searchTerm => {
      const regex = new RegExp(
        escapeRegExp(searchTerm).replaceAll(/ (\w)/g, '|$1').replaceAll(' ', ''),
        'i',
      );
      // Fix any
      return (item: any) =>
        !!item.name.match(regex) || item.options?.some((opt: any) => opt.name.match(regex));
    },
  });

  const getIsDisabled = (category: Category) => {
    const sameType = category.type === selectedItem.type;
    const sameOptions = arePropertyOptionsEqual(selectedItem, category);
    return !(sameType && sameOptions);
  };

  function getIconProperties(category: Category): { name: IconName; color: GetThemeColorInput } {
    if (getIsDisabled(category)) {
      return {
        name: 'checkbox',
        color: ['grey', 100],
      };
    }
    if (isItemSelected(category.id)) {
      return {
        name: 'checkboxChecked',
        color: 'secondary',
      };
    }
    return {
      name: 'checkbox',
      color: 'textDefault',
    };
  }

  return (
    <Box display="flex" flexDirection="column" minHeight={0}>
      <Box m={1.5}>
        <SearchBar
          size="l"
          placeholder={'Search for category or property'}
          onChange={e => setSearchTerm(e.currentTarget.value)}
        />
      </Box>
      <Box display="flex" minHeight="0" overflow={overflowOverlayOrAuto()} flexDirection="column">
        <List selectedBgColor="secondary" bgColor="grey" margin="" itemPadding="">
          {visibleItems?.map(category => (
            <ListItem
              key={category.id}
              onClick={() => !getIsDisabled(category) && toggleItem(category.id)}
              isSelected={isItemSelected(category.id)}
              disabled={getIsDisabled(category)}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap="8px"
                pl={1}
                pr={1.5}
                py={1.5}
                bb
                themedColor={isItemSelected(category.id) ? 'secondary' : 'grey'}
              >
                <Icon size="20px" {...getIconProperties(category)} />
                <Box flex={1}>
                  <CategoryRow
                    key={category.id}
                    disabled={getIsDisabled(category)}
                    item={category}
                    formatMatch={formatMatch}
                  />
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
