import { Dispatch, ReactElement, ReactNode, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoFilled } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  Input,
  Select,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import capitalize from 'lodash/capitalize';
import { useSnackbar } from 'notistack';

import { Row } from '../../../components/elements/Row';
import {
  MODEL_ENDPOINT_CREATE_START,
  MODEL_ENDPOINT_MAXIMUM_ERROR,
} from '../../../consts/SnackbarMessage';
import { useDebounce } from '../../../hooks/DebounceHook';
import RegexUtils from '../../../utils/RegexUtils';
import DropdownSearchInput, { SchedulePause } from '../components/components';
import {
  ENDPOINT_DEPLOYMENT_CREDIT,
  MAXIMUM_ENDPOINT_NAME_LENGTH,
  MINIMUM_ENDPOINT_NAME_LENGTH,
} from '../constant';
import {
  useCheckEndpointNameUniqueQuery,
  useCreateEndpointMutation,
  useModelListQuery,
} from '../queries/modelQueries';
import { EndpointScheduling, PurposeType } from '../services/types';
import { formatDate } from '../utils/formatUtils';

export const CreateEndpointDialog = ({ disclosure }: { disclosure: ReactElement }) => {
  const state = useDialogState();
  return (
    <Dialog
      state={state}
      disclosure={disclosure}
      style={{ width: 520, overflow: 'visible' }}
      hideOnClickOutside={false}
    >
      {state.visible && <DialogContent state={state} />}
    </Dialog>
  );
};

const DialogContent = ({ state }: { state: ReturnType<typeof useDialogState> }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const debouncedModelNameFilter = useDebounce(searchModelNameFilter, 300);
  const [modelPurpose, setModelPurpose] = useState<PurposeType | null>(null);

  const modelListQuery = useModelListQuery({
    params: {
      name: debouncedModelNameFilter,
      modelPurpose,
      status: ['trained'],
    },
  });
  const myModelsList = modelListQuery.data?.pages.flatMap(p => p.modelList) ?? [];

  const [selectedMyModelId, setSelectedMyModelId] = useState<string | null>(null);
  const [selectedModelName, setSelectedModelName] = useState<string | null>(null);
  const selectedMyModel = myModelsList.find(x => x.id === selectedMyModelId);

  const [uniqueEndpointNameFilter, setUniqueEndpointNameFilter] = useState('');
  const debouncedUniqueEndpointNameFilter = useDebounce(uniqueEndpointNameFilter, 300);

  const { data: _data } = useCheckEndpointNameUniqueQuery({
    name: debouncedUniqueEndpointNameFilter,
  });
  const isEndpointNameUnique = (_data?.pages.flatMap(p => p.endpointList) ?? []).length === 0;

  const { mutate } = useCreateEndpointMutation();
  // 배열인 이유 복수의 Scheduling이 가능해질 수 있음
  const [schedulingTimeList, setSchedulingTimeList] = useState<EndpointScheduling[]>([]);
  const [open, setOpen] = useState(false);
  const modelOptions = myModelsList.map(model => {
    return {
      value: model.id,
      label: (
        <Box
          key={model.id}
          backgroundColor={{ hover: 'gray-100', checked: 'primary-100' }}
          cursor="pointer"
          style={{ width: 282 }}
        >
          <Row mb={0.5}>
            <Typography
              variant="m-regular"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              color={selectedMyModel && model.id === selectedMyModel.id ? 'primary-400' : undefined}
            >
              {model.modelSetting.name}
            </Typography>
          </Row>
          <Row>
            <Typography variant="m-regular" color={'gray-300'}>
              {t('shared.createdAt')} {formatDate(String(new Date(model.createdAt)))}
            </Typography>
          </Row>
        </Box>
      ),
    };
  });
  const invalideNameLength =
    uniqueEndpointNameFilter.length > MAXIMUM_ENDPOINT_NAME_LENGTH ||
    uniqueEndpointNameFilter.length < MINIMUM_ENDPOINT_NAME_LENGTH;

  return (
    <>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.endpoints.createEndpoint')}
      </Dialog.Header>
      <NotiMessage
        text={
          <Typography variant={'m-regular'} color="secondary-400">
            <Trans
              t={t}
              i18nKey={'model.endpoints.endpointDepolymentCredit'}
              values={{ credit: ENDPOINT_DEPLOYMENT_CREDIT }}
            />
          </Typography>
        }
      />
      <Typography variant="m-strong" style={{ marginBottom: -8, marginTop: -8 }}>
        {t('model.title')}
      </Typography>
      <Box display="grid" style={{ gridTemplateColumns: '170px 1fr', columnGap: 4 }}>
        <Select
          value={modelPurpose}
          placeholder={t('model.endpoints.selectModelPurposePlaceholder')}
          data={[
            { label: t('model.generativeAi.title'), value: 'generation' as PurposeType },
            { label: t('model.myModels.title'), value: 'recognition' as PurposeType },
          ]}
          onChangeValue={v => {
            setModelPurpose(v);
            setSelectedMyModelId(null);
            setSelectedModelName(null);
          }}
          formatValue={v => <Typography>{capitalize(v)} AI</Typography>}
        />
        <Select
          value={selectedModelName ?? null}
          prefix={
            <DropdownSearchInput
              placeholder={t('model.train.modelSearchPlaceholder')}
              setSearchValue={setSearchModelNameFilter}
            />
          }
          {...modelListQuery}
          data={modelOptions}
          formatValue={v => <>{v}</>}
          onChangeValue={v => {
            setSelectedMyModelId(v);
            setSelectedModelName(myModelsList.find(model => model.id === v).modelSetting.name);
          }}
          placeholder={t('model.endpoints.selectModelPlaceholder')}
          disabled={!modelPurpose}
        />
      </Box>
      <Typography variant="m-strong" style={{ marginBottom: -8 }}>
        {t('shared.name')}
      </Typography>
      <NameInput
        uniqueEndpointNameFilter={uniqueEndpointNameFilter}
        setUniqueEndpointNameFilter={setUniqueEndpointNameFilter}
        isEndpointNameUnique={isEndpointNameUnique}
        invalideNameLength={invalideNameLength}
      />
      <SchedulePause
        open={open}
        setOpen={setOpen}
        schedulingTimeList={schedulingTimeList}
        setSchedulingTimeList={setSchedulingTimeList}
      />
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          disabled={
            !uniqueEndpointNameFilter ||
            invalideNameLength ||
            !selectedMyModel ||
            !isEndpointNameUnique ||
            RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueEndpointNameFilter)
          }
          onClick={() => {
            mutate(
              {
                modelId: selectedMyModel!.id,
                endpointName: uniqueEndpointNameFilter,
                endpointScheduling: open ? schedulingTimeList : [],
              },
              {
                onSuccess: () => {
                  enqueueSnackbar(MODEL_ENDPOINT_CREATE_START({ t }), {
                    variant: 'info',
                  });
                  state.setVisible(false);
                },
                onError: () => {
                  enqueueSnackbar(MODEL_ENDPOINT_MAXIMUM_ERROR({ t }), {
                    variant: 'error',
                  });
                  state.setVisible(false);
                },
              },
            );
          }}
          color="primary"
          variant="strong-fill"
        >
          {t('button.create')}
        </Button>
      </Dialog.Actions>
    </>
  );
};

const NameInput = ({
  uniqueEndpointNameFilter,
  setUniqueEndpointNameFilter,
  isEndpointNameUnique,
  invalideNameLength,
}: {
  uniqueEndpointNameFilter: string;
  setUniqueEndpointNameFilter: Dispatch<SetStateAction<string>>;
  isEndpointNameUnique: boolean;
  invalideNameLength: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Input
        value={uniqueEndpointNameFilter}
        onChange={e => setUniqueEndpointNameFilter(e.target.value)}
        placeholder={t('model.myModels.placeholder')}
      />
      {!isEndpointNameUnique && (
        <Typography variant="s-regular" color={'red-400'} style={{ marginTop: -12 }}>
          {t('model.train.uniqueEndpointName')}
        </Typography>
      )}
      <Row style={{ marginTop: -12 }}>
        <Box>
          {invalideNameLength && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                <Trans
                  t={t}
                  i18nKey={'model.endpoints.maximumNameLength'}
                  values={{ min: MINIMUM_ENDPOINT_NAME_LENGTH, max: MAXIMUM_ENDPOINT_NAME_LENGTH }}
                />
              </Typography>
            </Box>
          )}
          {RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueEndpointNameFilter) && (
            <Box>
              <Typography variant="s-regular" color={'red-400'}>
                {t('model.endpoints.notIncludeSpecialCharacter')}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          variant="s-regular"
          color={invalideNameLength ? 'red-400' : undefined}
          ml={'auto'}
          mb={'auto'}
        >
          {`${uniqueEndpointNameFilter.length} / ${MAXIMUM_ENDPOINT_NAME_LENGTH}`}
        </Typography>
      </Row>
    </>
  );
};

const NotiMessage = ({ text }: { text: string | ReactNode }) => {
  return (
    <Row px={1.5} backgroundColor={'secondary-100'} gap={0.5} style={{ height: 34 }}>
      <Icon icon={InfoFilled} color={'secondary-400'} />
      {typeof text === 'string' ? (
        <Typography variant="m-regular" color={'secondary-400'}>
          {text}
        </Typography>
      ) : (
        text
      )}
    </Row>
  );
};
