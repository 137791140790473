import React from 'react';

import { Box, Typography } from '@superb-ai/ui';

export default function NumberChip({ number }: { number: number }) {
  return (
    <Box
      borderRadius="100%"
      border="1px solid"
      borderColor="gray-400"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        width: '16px',
        height: '16px',
      }}
    >
      <Typography variant="s-strong">{number}</Typography>
    </Box>
  );
}
