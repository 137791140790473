import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import AnnotationInformation from './AnnotationInformation';
import LabelInformation from './LabelInformation';

const useStyles = makeStyles(() => ({
  labelInformationBox: {
    position: 'relative',
    flex: '4',
    overflow: overflowOverlayOrAuto(),
    background: '#fff',
    padding: '8px 0px',
  },
}));

// eslint-disable-next-line
const CabinetLabelInformation = (props: any): React.ReactElement => {
  const classes = useStyles();
  const labelInformationInfo = useLabelInformationInfo();

  const {
    clickedAnnotationInfo,
    hoveredAnnotation,
    isLabelInformationSwitchActive,
    setIsLabelInformationSwitchActive,
  } = labelInformationInfo;

  const { cabinetStyles } = props;

  return (
    <>
      <MUI.Box className={cabinetStyles.cabinetHeader} mt="12px">
        <MUI.Typography className={cabinetStyles.cabinetTitle}>Label information</MUI.Typography>
        <MUI.Box
          display="flex"
          onClick={() => setIsLabelInformationSwitchActive(!isLabelInformationSwitchActive)}
        >
          <MUI.Switch size="small" color="primary" checked={isLabelInformationSwitchActive} />
        </MUI.Box>
      </MUI.Box>
      <MUI.Box className={classes.labelInformationBox}>
        <MUI.Box className={cabinetStyles.cabinetContent}>
          {clickedAnnotationInfo || hoveredAnnotation ? (
            <AnnotationInformation />
          ) : (
            <LabelInformation />
          )}
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default CabinetLabelInformation;
