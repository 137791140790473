import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styled from '@emotion/styled';
import { CheckFilled } from '@superb-ai/icons';
import { Box, IconButton, Input, Modal } from '@superb-ai/norwegian-forest';
import { Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import RegexUtils from '../../../../../../utils/RegexUtils';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useDatasetModalContext } from '../../../../contexts/ModalContext';
import { useCurateDatasetService } from '../../../../services/DatasetService';

export default function DatasetSettingsModal(): JSX.Element {
  const { datasetSettingsIsOpen, setDatasetSettingsIsOpen } = useDatasetModalContext();
  const { datasetInfo, setDatasetInfo } = useDatasetContext();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { updateDataset } = useCurateDatasetService();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const path = history.location.pathname;

  const datasetName = datasetInfo?.name || '';
  const [newDatasetName, setNewDatasetName] = useState<string>(datasetName);

  const [newDatasetDescription, setNewDatasetDescription] = useState<string>(
    datasetInfo?.description || '',
  );
  const [isValidDatasetName, setIsValidDatasetName] = useState<boolean>(true);

  const handleUpdateDataset = async () => {
    if (!(newDatasetName && datasetId)) return;
    const res = await updateDataset({
      datasetId,
      name: newDatasetName,
      description: newDatasetDescription,
    });

    setDatasetInfo({
      ...datasetInfo,
      ...res,
      description: newDatasetDescription,
      name: newDatasetName,
    });
    enqueueSnackbar(t('curate.datasets.updateSuccessMessage'), {
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      content: (key, message) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            style={{
              width: '420px',
              height: '101px',
              background: '#1f1f1f',
              borderLeft: '4px solid  #82db24',
              padding: '20px',
            }}
          >
            <Box display="flex" width="100%" justifyContent="space-between">
              <Box display="flex">
                <Icon icon={CheckFilled} color="green-400" size={24} />
                <Box display="flex" ml={1} pt={0.5}>
                  <Typography variant="m-strong" color={'white'}>
                    {message}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{ maxHeight: '24px' }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  icon="clear"
                  color="backgroundColor"
                  style={{ padding: '0 8px' }}
                  onClick={() => closeSnackbar(key)}
                />
              </Box>
            </Box>
            <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  size="m"
                  style={{ color: 'white' }}
                  variant="text"
                  onClick={() => {
                    history.push(`/${accountName}/curate/dataset/${datasetId}/explore`);
                    closeSnackbar(key);
                  }}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Box>
        );
      },
    });
    setDatasetSettingsIsOpen(false);
    setNewDatasetName('');
    setNewDatasetDescription('');
    setIsValidDatasetName(false);
  };

  const handleCloseButton = useCallback(() => {
    setDatasetSettingsIsOpen(false);
  }, [setDatasetSettingsIsOpen]);

  const handleDatasetName = useCallback(
    e => {
      const {
        target: { value },
      } = e;
      setNewDatasetName(value);
      if (
        value.length > 3 &&
        value.length < 51 &&
        RegexUtils.IS_ASCII(value) &&
        !RegexUtils.HAS_SPECIAL_SYMBOLS(value)
      )
        setIsValidDatasetName(true);
      else setIsValidDatasetName(false);
    },
    [setNewDatasetName, setIsValidDatasetName],
  );

  const handleDatasetDescription = useCallback(
    e => {
      if (e.target.value.length < 201) setNewDatasetDescription(e.target.value);
    },
    [setNewDatasetDescription],
  );

  useEffect(() => {
    if (datasetInfo) {
      setNewDatasetName(datasetInfo?.name);
      setNewDatasetDescription(datasetInfo?.description);
      setIsValidDatasetName(true);
    }
  }, [datasetInfo, path]);

  return (
    <Modal
      open={datasetSettingsIsOpen}
      close={{
        onClose: () => handleCloseButton(),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.datasetSettings')}
      mainButton={{
        text: t('button.okay'),
        onClick: handleUpdateDataset,
        color: 'primary',
        disabled: !isValidDatasetName,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => handleCloseButton(),
      }}
    >
      <Box py={2.5} px={4} minWidth="520px">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Typography variant="m-regular" color="gray-400">
            {t('curate.data.datasetName')}
          </Typography>
          <Typography variant="m-regular" color="gray-400">
            {`${datasetName?.length}/50`}
          </Typography>
        </div>
        <Input
          color="grey"
          placeholder={t('curate.dialogs.placeholder.name')}
          variant="stroke"
          onChange={handleDatasetName}
          value={newDatasetName}
        />
        {!isValidDatasetName && datasetName.length > 0 && (
          <Box style={{ boxSizing: 'border-box' }} p={0.5}>
            <Typography variant="s-regular" color="primary-400" style={{ padding: '0 2px' }}>
              <Trans t={t} i18nKey={'curate.data.datasetNameGuide'} />
            </Typography>
          </Box>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '8px',
          }}
        >
          <Box display="flex">
            <Typography variant="m-regular" color="gray-400" style={{ marginRight: '4px' }}>
              {t('curate.data.description')}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              ({t('curate.data.optional')})
            </Typography>
          </Box>

          <Typography variant="m-regular" color="gray-400">
            {`${newDatasetDescription.length}/200`}
          </Typography>
        </div>
        <StyledTextarea
          rows={3}
          placeholder={t('curate.dialogs.placeholder.sliceDescription')}
          onChange={handleDatasetDescription}
          value={newDatasetDescription}
        ></StyledTextarea>
      </Box>
    </Modal>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 72px;
  box-shadow: inset 0 0 0 1px #1010102a;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 8px;
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  &::placeholder {
    color: #b3b3b3;
    font-weight: 400;
  }
`;
