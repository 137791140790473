import { LinkTypography, Typography } from '@superb-ai/ui';
import { History } from 'history';
import { TFunction, Trans } from 'next-i18next';
import qs from 'qs';

import { CURATION_TYPE_CONFIG } from '../../../../consts/AutoCurateConst';
import { getUserManualUrl } from '../../../../consts/DocsLink';
import {
  ANNOTATION_IMPORT,
  AUTO_CURATE,
  CLOUD_ANNOTATION_UPLOAD,
  CLOUD_UPLOAD,
  Command,
  CommandStatus,
  CommandType,
  DEACTIVATE_DIAGNOSIS,
  DELETE_IMAGES,
  DELETE_IMAGES_BY_QUERY,
  GENERATE_SYNTHETIC_IMAGES,
  IMAGE_IMPORT,
  IMPORT_FROM_LABELING,
  PREPARE_DOWNLOAD,
  START_DIAGNOSIS,
  UPDATE_ANNOTATION_SCATTER,
  UPDATE_DIAGNOSIS,
  UPDATE_IMAGE_SCATTER,
  UPDATE_SLICE,
  UPDATE_SLICE_BY_ANNOTATIONS,
  UPDATE_SLICE_BY_EVALUATIONS,
  UPDATE_SLICE_BY_QUERY,
} from '../../types/commandTypes';
import { InProgressStatuses, formatNameEmphasize, getIsJobSuccess } from '../../utils/commandUtils';
import AutoCurateAdditionalInfo from './AutoCurateAdditionalInfo';

/**
 * linkedPageName: Used to show the page name in the snackbar button. a(ex. Open dataset, ...)
 * When you add new linkedPageName, add it to i18n curate.bulkActions.openPageTarget
 */
type LinkedPageName = 'dataset' | 'slice' | 'autoCurateHistory' | 'modelDiagnosis' | 'downloads';

export type CommandConfig = {
  link?: string;
  linkedPageName: LinkedPageName | undefined;
  message: JSX.Element | undefined;
  additionalMessage?: JSX.Element;
  additionalActions?: { text: string; handleClick: () => void }[];
  additionalPartialFailAction?: { text: string; handleClick: () => void };
};

export function getConfigPerJobType<T extends CommandType>({
  t,
  language,
  history,
  onClickMessageLink,
  accountName,
  command,
  datasetName,
  sliceName,
  modelName,
  projectName,
  format = 'bold',
  autoCurateSlices,
  downloadUrl,
  downloadName,
}: {
  t: TFunction;
  language: string;
  history: History<unknown>;
  onClickMessageLink?: () => void;
  accountName: string;
  command?: Command<T>;
  datasetName?: string;
  sliceName?: string;
  modelName?: string;
  projectName?: string;
  format?: 'bold' | 'quotes';
  autoCurateSlices?: Record<'train' | 'val' | 'mislabel' | 'result', { id: string; name: string }>;
  downloadUrl?: string;
  downloadName?: string;
}) {
  const { CURATE_SDK_WORKFLOW_MANUAL } = getUserManualUrl(language);

  function getTransKeyByCommandStatus(
    status: CommandStatus,
    failCount: number,
    totalCount: number,
  ) {
    return {
      PENDING: 'pending',
      IN_PROGRESS: 'inProgress',
      COMPLETE: totalCount === 0 ? 'failed' : failCount > 0 ? 'partialFail' : 'complete',
      FAILED: 'failed',
      CANCELED: 'canceled',
      CANCELING: 'canceling',
    }[status];
  }

  const defaultConfig = {
    link: undefined,
    linkedPageName: undefined,
    message: undefined,
    additionalMessage: undefined,
    additionalActions: undefined,
    additionalPartialFailAction: undefined,
  };

  if (!command) return defaultConfig;

  function buildMessage(jobTypeKey: string, values: Record<string, any> = {}, link?: string) {
    const { imageCount, annotationCount, objectCount, ...otherValues } = values;
    if (!command) return undefined;
    const statusKey = getTransKeyByCommandStatus(
      command.status,
      command.result?.failCount,
      command.totalCount,
    );

    const failedCount = command.result?.failCount;

    return (
      <Trans
        t={t}
        i18nKey={`curate.bulkActions.messages.${jobTypeKey}.${statusKey}`}
        values={{
          ...otherValues,
          ...{
            imageCountText: !isNaN(imageCount)
              ? t('curate.bulkActions.imageCountText', { count: imageCount })
              : t('curate.bulkActions.images'),
          },
          ...{
            annotationCountText: !isNaN(annotationCount)
              ? t('curate.bulkActions.annotationCountText', {
                  count: annotationCount,
                })
              : t('curate.bulkActions.annotations'),
          },
          ...{
            objectCountText: !isNaN(objectCount)
              ? t('curate.bulkActions.objectCountText', {
                  count: objectCount,
                })
              : t('curate.bulkActions.objects'),
          },
          ...(failedCount && { failedCount: failedCount || 0 }),
        }}
        components={
          link
            ? {
                link1: (
                  <LinkTypography
                    color="secondary"
                    as="a"
                    onClick={e => {
                      history.push(link || '', new Date());
                      {
                        onClickMessageLink && onClickMessageLink();
                      }
                      e.stopPropagation();
                    }}
                  />
                ),
              }
            : undefined
        }
      />
    );
  }

  function generateLink(path?: string) {
    if (!command || !datasetName || !path) return undefined;
    return `/${accountName}/curate/dataset/${command.param.datasetId}/${path}`;
  }

  const jobTypeConfig: Record<CommandType, () => CommandConfig> = {
    [IMAGE_IMPORT]: () => ({
      link: generateLink('explore'),
      linkedPageName: 'dataset',
      message: buildMessage('imageImport', { imageCount: command.totalCount }),
      additionalPartialFailAction: {
        text: t('curate.bulkActions.button.viewValidExamples'),
        handleClick: () => {
          window.open(CURATE_SDK_WORKFLOW_MANUAL, '_blank', 'noreferrer');
        },
      },
    }),
    [ANNOTATION_IMPORT]: () => ({
      link: generateLink('explore'),
      linkedPageName: 'dataset',
      message: buildMessage('annotationImport', { annotationCount: command.totalCount }),
      additionalPartialFailAction: {
        text: t('curate.bulkActions.button.viewValidExamples'),
        handleClick: () => {
          window.open(CURATE_SDK_WORKFLOW_MANUAL, '_blank', 'noreferrer');
        },
      },
    }),
    [IMPORT_FROM_LABELING]: () => {
      const { param } = command as Command<typeof IMPORT_FROM_LABELING>;
      if (param.sliceId) {
        return {
          link: generateLink(param.sliceId ? `slice/${param.sliceId}` : undefined),
          linkedPageName: 'slice',
          message: buildMessage(
            'importFromLabel',
            {
              imageCount: command.totalCount,
              target: t('curate.bulkActions.messages.importFromLabel.target.slice', {
                name: formatNameEmphasize(
                  sliceName || t('curate.bulkActions.deletedSlice'),
                  format,
                ),
              }),
              projectName,
            },
            `/${accountName}/label/project/${param.projectId}/`,
          ),
        };
      }
      return {
        link: generateLink('explore'),
        linkedPageName: 'dataset',
        message: buildMessage(
          'importFromLabel',
          {
            imageCount: command.totalCount,
            target: t('curate.bulkActions.messages.importFromLabel.target.dataset', {
              name: formatNameEmphasize(
                datasetName || t('curate.bulkActions.deletedDataset'),
                format,
              ),
            }),
            projectName,
          },
          `/${accountName}/label/project/${param.projectId}/`,
        ),
      };
    },
    [UPDATE_SLICE]: () => {
      const { param } = command as Command<typeof UPDATE_SLICE>;
      const { sliceId, remove } = param;

      return {
        link: sliceId ? generateLink(`slice/${sliceId}`) : undefined,
        linkedPageName: sliceName ? 'slice' : undefined,
        message: buildMessage(remove ? 'removeFromSlice' : 'addToSlice', {
          sliceName: formatNameEmphasize(sliceName || t('curate.bulkActions.deletedSlice'), format),
          imageCount: command.totalCount,
        }),
      };
    },
    [UPDATE_SLICE_BY_QUERY]: () => {
      const { param } = command as Command<typeof UPDATE_SLICE_BY_QUERY>;
      const { sliceId, remove } = param;

      return {
        link: sliceId ? generateLink(`slice/${sliceId}`) : generateLink('explore'),
        linkedPageName: sliceName ? 'slice' : 'dataset',
        message: buildMessage(remove ? 'removeFromSlice' : 'addToSlice', {
          sliceName: formatNameEmphasize(sliceName || t('curate.bulkActions.deletedSlice'), format),
          imageCount: command.totalCount,
        }),
      };
    },
    [UPDATE_SLICE_BY_EVALUATIONS]: () => {
      const { param } = command as Command<typeof UPDATE_SLICE_BY_EVALUATIONS>;
      return {
        link: sliceName ? generateLink(`slice/${param.sliceId}`) : generateLink('explore'),
        linkedPageName: sliceName ? 'slice' : 'dataset',
        message: buildMessage('addToSliceWithObject', {
          // imageCount: command.result?.affectedCount || 0,
          sliceName: formatNameEmphasize(sliceName || t('curate.bulkActions.deletedSlice'), format),
          objectCount: command.totalCount,
        }),
      };
    },
    [UPDATE_SLICE_BY_ANNOTATIONS]: () => {
      const { param } = command as Command<typeof UPDATE_SLICE_BY_ANNOTATIONS>;
      return {
        link: sliceName ? generateLink(`slice/${param.sliceId}`) : generateLink('explore'),
        linkedPageName: sliceName ? 'slice' : 'dataset',
        message: buildMessage('addToSliceWithObject', {
          // imageCount: command.result?.affectedCount || 0,
          sliceName: formatNameEmphasize(sliceName || t('curate.bulkActions.deletedSlice'), format),
          objectCount: command.totalCount,
        }),
      };
    },
    [DELETE_IMAGES]: () => ({
      link: generateLink('explore'),
      linkedPageName: 'dataset',
      message: buildMessage('deleteImages', { imageCount: command.totalCount }),
    }),
    [DELETE_IMAGES_BY_QUERY]: () => ({
      link: generateLink('explore'),
      linkedPageName: 'dataset',
      message: buildMessage('deleteImages', { imageCount: command.totalCount }),
    }),
    [AUTO_CURATE]: () => {
      const { param } = command as Command<typeof AUTO_CURATE>;
      return {
        link: generateLink('curation'),
        linkedPageName: 'autoCurateHistory',
        message: buildMessage('autoCurate', {
          imageCount: command.totalCount,
          type: param.curationType ? `(${CURATION_TYPE_CONFIG(t)[param.curationType].text})` : '',
        }),
        additionalMessage: autoCurateSlices ? (
          <AutoCurateAdditionalInfo
            t={t}
            history={history}
            accountName={accountName}
            datasetId={command.param.datasetId}
            autoCurateSlices={autoCurateSlices}
            onClickLink={onClickMessageLink}
          />
        ) : undefined,
      };
    },
    [GENERATE_SYNTHETIC_IMAGES]: () => {
      const { param } = command as Command<typeof GENERATE_SYNTHETIC_IMAGES>;
      return {
        link: sliceName
          ? generateLink(`slice/${param.destinationSliceId}`)
          : generateLink('explore'),
        linkedPageName: 'slice',
        message: buildMessage('generateSyntheticImages', {
          imageCount: command.totalCount,
          sliceName: formatNameEmphasize(sliceName || t('curate.bulkActions.deletedSlice'), format),
        }),
      };
    },
    [UPDATE_IMAGE_SCATTER]: () => ({
      link: generateLink('explore?scope=image&view=2d'),
      linkedPageName: 'dataset',
      message: buildMessage('updateImageScatter', { imageCount: command.totalCount }),
    }),
    [UPDATE_ANNOTATION_SCATTER]: () => ({
      link: generateLink('explore?scope=object&view=2d'),
      linkedPageName: 'dataset',
      message: buildMessage('updateAnnotationScatter', { annotationCount: command.totalCount }),
    }),
    [START_DIAGNOSIS]: () => {
      const { param } = command as Command<typeof START_DIAGNOSIS>;
      return {
        link: generateLink(`model-diagnosis/${param.diagnosisId}`),
        linkedPageName: 'modelDiagnosis',
        message: buildMessage('startDiagnosis', {
          name: formatNameEmphasize(modelName || t('curate.bulkActions.deletedModel'), format),
        }),
      };
    },
    [UPDATE_DIAGNOSIS]: () => {
      const { param } = command as Command<typeof UPDATE_DIAGNOSIS>;
      return {
        link: generateLink(`model-diagnosis/${param.diagnosisId}`),
        linkedPageName: 'modelDiagnosis',
        message: buildMessage('updateDiagnosis', {
          name: formatNameEmphasize(modelName || t('curate.bulkActions.deletedModel'), format),
        }),
      };
    },
    [DEACTIVATE_DIAGNOSIS]: () => {
      const { param } = command as Command<typeof DEACTIVATE_DIAGNOSIS>;
      return {
        link: generateLink(`model-diagnosis/${param.diagnosisId}`),
        linkedPageName: 'modelDiagnosis',
        message: buildMessage('deactivateDiagnosis', {
          name: formatNameEmphasize(modelName || t('curate.bulkActions.deletedModel'), format),
        }),
      };
    },
    [CLOUD_UPLOAD]: () => {
      const { param } = command as Command<typeof CLOUD_UPLOAD>;
      return {
        link: sliceName ? generateLink(`slice/${param.sliceId}`) : generateLink('explore'),
        linkedPageName: sliceName ? 'slice' : 'dataset',
        message: buildMessage('cloudUpload', {
          cloudUploadToSlice: sliceName
            ? t('curate.bulkActions.messages.cloudUpload.cloudUploadToSlice', {
                name: formatNameEmphasize(
                  sliceName || t('curate.bulkActions.deletedSlice'),
                  format,
                ),
              })
            : '',
          imageCount: command.totalCount,
        }),
        additionalMessage:
          param.includeAnnotations && command.status !== 'COMPLETE' ? (
            <Typography variant="m-regular" color="gray-300">
              {t('curate.bulkActions.messages.cloudUpload.withAnnotations')}
            </Typography>
          ) : undefined,
      };
    },
    [CLOUD_ANNOTATION_UPLOAD]: () => {
      return {
        link: generateLink('explore?scope=object'),
        linkedPageName: 'dataset',
        message: buildMessage('cloudAnnotationUpload', {
          annotationCount: command.result?.successCount,
          objectCount: command.result?.successCount,
          imageCount: command.totalCount,
        }),
      };
    },
    [PREPARE_DOWNLOAD]: () => {
      const { param } = command as Command<typeof PREPARE_DOWNLOAD>;
      const { imageFilters } = param;
      return {
        link: downloadUrl
          ? `${generateLink('downloads')}?${qs.stringify({ job_id: command.id })}`
          : undefined,
        linkedPageName: 'downloads',
        message: buildMessage('prepareDownload', {
          name: formatNameEmphasize(
            downloadName || t('curate.bulkActions.deletedDownload'),
            'bold',
          ),
          target: imageFilters?.slice
            ? t('curate.bulkActions.messages.prepareDownload.target.slice', {
                name: formatNameEmphasize(imageFilters.slice, 'bold'),
              })
            : t('curate.bulkActions.messages.prepareDownload.target.dataset', {
                name: formatNameEmphasize(
                  datasetName || t('curate.bulkActions.deletedDataset'),
                  'bold',
                ),
              }),
        }),
        additionalActions: getIsJobSuccess(command) && [
          {
            text: downloadUrl
              ? t('curate.bulkActions.button.downloadFile')
              : t('curate.bulkActions.button.downloadFileDeleted'),
            handleClick: () => {
              window.open(downloadUrl || '', '_blank', 'noreferrer');
            },
            disabled: !downloadUrl,
          },
        ],
      };
    },
  };

  const config = jobTypeConfig[command.jobType];
  return config ? config() : defaultConfig;
}
