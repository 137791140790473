export const EMPTY_MESSAGE = {
  REFILTER_DATE: 'analytics.messages.refilterDate',
  SUBMIT_LABELS: 'analytics.messages.submitLabels',
  ANNOTATE_OBJECTS: 'analytics.messages.annotateObjects',
  ANNOTATE_IMAGE_CATEGORIES: 'analytics.messages.annotateCategories',
  UPLOAD_DATA: 'analytics.messages.uploadData',
  LABEL_DATA: 'analytics.messages.labelData',
  REQUEST_UPDATE: 'analytics.messages.requestUpdate',
  FAILED_REPORT: 'analytics.messages.failedReport',
  REQUEST_REVIEW: 'analytics.messages.requestReview',
  ADD_CONSENSUS_LABEL: 'analytics.messages.addConsensusLabel',
  SUBMIT_CONSENSUS_LABEL: 'analytics.messages.submitConsensusLabel',
  ASSIGN_CONSENSUS_LABELS: 'analytics.messages.assignConsensusLabels',
  UNSUPPORTED_WORKAPP: 'analytics.messages.unsupportedWorkapp',
  SELECT_LABELING_TIME: 'analytics.messages.selectLabelingTime',
  EXPORT_ANNOTATE_OBJECTS: 'analytics.messages.exportAnnotateObjects',
  EXPORT_COMPARE_LABELS: 'analytics.messages.exportCompareLabels',
};

export const getDisplayStatus = (statusKey: string): string => {
  const displayStatus = {
    cumSkipped: 'labels.status.skipped',
    cumSubmitted: 'labels.status.submitted',
    cumWorking: 'labels.status.in_progress',
    total: 'analytics.text.allStatus',
    skipped: 'labels.status.skipped',
    submitted: 'labels.status.submitted',
    working: 'labels.status.in_progress',
  };
  return displayStatus[statusKey as keyof typeof displayStatus] || '';
};

export const getFilterStatus = (statusKey: string): string => {
  const displayStatus = {
    cumSkipped: 'skipped',
    cumSubmitted: 'submitted',
    cumWorking: 'in progress',
    total: 'total',
  };
  return displayStatus[statusKey as keyof typeof displayStatus] || '';
};
