import React from 'react';
import { useLocation } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Box, Button, Chip, Typography } from '@superb-ai/norwegian-forest';
import cn from 'classnames';
import { startCase } from 'lodash';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { useCurrentPlan } from '../../../../queries/useSubscriptionQuery';
import TierUnion from '../../../../union/TierUnion';
import { EDIT_PROJECT_PATH } from '../paths';
import helper from './helper';

const useStyles = makeStyles(() => ({
  button: {
    justifyContent: 'start !important',
    lineHeight: '52px !important',
    gap: 4,
    '&.selected': {
      background: '#ffeeee',
    },
    '& > svg': {
      width: '24px !important',
      height: '24px !important',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '22px',
    height: '22px',
  },
  nameText: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#303030',
    marginLeft: '8px',
    marginBottom: '2px',
  },
  proTextBox: {
    padding: '1px 7px',
    borderRadius: '3px',
    background: '#ff9772',
  },
  proText: {
    fontSize: '11px',
    color: '#fff;',
    fontWeight: 500,
  },
}));

// eslint-disable-next-line
const DataTypeButton = (props: any): React.ReactElement => {
  const classes = useStyles();
  const { data } = props;
  const newProjectInfo = useNewProjectInfo();
  const currentPlan = useCurrentPlan();
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;
  const lidar = useFeatureFlag('lidar');

  const isTeam = TierUnion.isTeam(currentPlan);
  const isEnterprise = TierUnion.isEnterprise(currentPlan);

  const isSelected =
    data.type === newProjectInfo.selectedDataType &&
    data.workapp === newProjectInfo.selectedWorkapp;

  const getPlanLimit = () => {
    if (data.type === 'image sequence') return 'Team';
    if (data.type === 'pointclouds') return 'Enterprise';
    return undefined;
  };

  const isUnavailable =
    !data.isAvailable ||
    (data.type === 'image sequence' && !(isTeam || isEnterprise)) ||
    (data.type === 'pointclouds' && !isEnterprise);

  const handleClickDataTypeButton = () => {
    helper.updateDataTypes(newProjectInfo, data.type, data.workapp, lidar);
  };

  return (
    <Button
      variant="shadow"
      size="l"
      IconAdornment={data.icon}
      disabled={isUnavailable || isEditPage}
      onClick={handleClickDataTypeButton}
      className={cn(classes.button, { selected: isSelected })}
    >
      {startCase(data.label)}
      {data.isDeprecated && (
        <Typography themedColor={isUnavailable || isEditPage ? undefined : ['grey', 300]}>
          (Deprecated)
        </Typography>
      )}
      {isUnavailable && (
        <Box display="flex" alignItems="center" ml={1}>
          <Chip color="strawberry">{getPlanLimit()}</Chip>
        </Box>
      )}
    </Button>
  );
};

export default DataTypeButton;
