import { Trans, useTranslation } from 'react-i18next';

import { InfoFilled, Radio, RadioChecked } from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import { Row } from '../../../../components/elements/Row';
import { getUserManualUrl } from '../../../../consts/DocsLink';
import { useBaselineContext } from '../contexts/BaselineContext';
import { SplitType, useDatasetClassContext } from '../contexts/DatasetClassContext';

export const TrainValidationSplitStep = () => {
  const { t, i18n } = useTranslation();
  const { MODEL_SPLIT_TRAIN_VALIDATION_SET_MANUAL } = getUserManualUrl(i18n.language);
  const { splitType, setSplitType, setTrainingSetNames, setValidationSetNames } =
    useDatasetClassContext();
  const { selectedMyModel } = useBaselineContext();

  const cannotRandomSplit = Boolean(
    selectedMyModel && selectedMyModel.trainingSet.validationSetList.length > 0,
  );

  const handleClickSplitType = (v: SplitType) => {
    setSplitType(v);
  };

  const resetUnderSplitStage = () => {
    setTrainingSetNames(null);
    setValidationSetNames(null);
  };

  return (
    <>
      <Typography variant="m-strong">{t('model.train.trainValidationSplit')}</Typography>
      <Box display="grid" my={1} style={{ gridTemplateColumns: '1fr 1fr', columnGap: 8 }}>
        <SplitCard
          splitType={t('model.train.randomSplit')}
          image={'/static/image/random-split.png'}
          isSelected={splitType === 'random'}
          description={t('model.train.randomSplitDescription')}
          onClick={() => {
            resetUnderSplitStage();
            handleClickSplitType('random');
          }}
          disabled={cannotRandomSplit}
        />
        <SplitCard
          splitType={t('model.train.manualSplit')}
          image={'/static/image/manual-split.png'}
          isSelected={splitType === 'manual'}
          description={t('model.train.manualSplitDescription')}
          onClick={() => {
            resetUnderSplitStage();
            handleClickSplitType('manual');
          }}
          disabled={cannotRandomSplit}
        />
      </Box>
      <Row backgroundColor={'secondary-100'} px={1.5} gap={0.5} style={{ height: 34 }}>
        <Icon icon={InfoFilled} color={'secondary-400'} size={16} />
        <Typography variant="m-regular" color={'secondary-400'}>
          <Trans t={t} i18nKey={'model.train.splitTypeInfo'}>
            Try using{' '}
            <Link
              href={MODEL_SPLIT_TRAIN_VALIDATION_SET_MANUAL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 500, textDecoration: 'underline' }}
            >
              Auto-curate (Split train/validation set)
            </Link>
            to automatically curate the optimal training and validation sets.
          </Trans>
        </Typography>
      </Row>
    </>
  );
};

const SplitCard = ({
  splitType,
  image,
  isSelected,
  description,
  onClick,
  disabled,
}: {
  splitType: string;
  image: string;
  isSelected: boolean;
  description: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <Box
      border="1px solid"
      borderColor={isSelected ? 'primary-400' : 'gray-150'}
      borderRadius="2px"
      p={1.5}
      cursor="pointer"
      onClick={() => {
        disabled ? null : onClick();
      }}
    >
      <Row gap={0.5}>
        <Icon
          icon={isSelected ? RadioChecked : Radio}
          color={isSelected ? 'primary-400' : undefined}
        />
        <Typography variant="m-strong" color={isSelected ? 'primary-400' : undefined}>
          {splitType}
        </Typography>
      </Row>
      <Row justifyContent="center">
        <Image src={image} width={328} height={124} alt="" />
      </Row>
      <Box>
        <Typography variant="m-regular">{description}</Typography>
      </Box>
    </Box>
  );
};
