import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { ClassStatsDatum, DataScope } from '../types';
import { getChartColor } from '../utils/colorScale';
import { ClassStatsTableHeader } from './ClassStatsTableHeader';
import { ClassStatsTableRow } from './ClassStatsTableRow';
import { getHeaderWithImages } from './getHeader';
import { TotalCountBanner } from './TotalCountsBanner';

type ClassStatsTableProps = {
  headers: ReturnType<typeof getHeaderWithImages>;
  isCompareMode: boolean;
  showBanner?: boolean;
  excludeImageInfo?: boolean;
  selection: {
    data: ClassStatsDatum[];
    totalAnnotations: number;
    totalImages: number;
    name: string;
  };
  compared?: {
    data: ClassStatsDatum[];
    totalAnnotations: number;
    totalImages: number;
    name: string;
  };
  dataScope: DataScope;
  hasImageColumns?: boolean;
};

export function ClassStatsTable({
  headers,
  isCompareMode,
  showBanner = true,
  excludeImageInfo,
  selection,
  compared,
  dataScope,
  hasImageColumns = true,
}: ClassStatsTableProps) {
  const { t } = useTranslation();
  const hasCompareData = isCompareMode && compared?.data && compared.data.length > 0;
  const maxHeight = '800px';
  const bannerRef = useRef<HTMLDivElement>(null);
  return (
    <Box overflow="auto" width="100%" style={{ maxHeight }}>
      <Box position="sticky" top="0" backgroundColor="white" style={{ zIndex: 1 }}>
        {showBanner && (
          <Box display="flex" gap={1} ref={bannerRef}>
            <Box style={{ width: headers[0].width }} />
            {
              <TotalCountBanner
                key="selection-total-objects-banner"
                text={t('curate.analytics.classStats.totalObjects')}
                totalCounts={{
                  selection: {
                    color: getChartColor('objects'),
                    count: selection.totalAnnotations,
                    name: selection.name,
                  },
                  ...(hasCompareData && {
                    compared: {
                      color: getChartColor('objectsAll'),
                      count: compared.totalAnnotations || 0,
                      name: compared.name,
                    },
                  }),
                }}
              />
            }
            {hasImageColumns && (
              <TotalCountBanner
                key="compared-total-objects-banner"
                text={t('curate.analytics.classStats.totalImages')}
                infoText={t('curate.analytics.classStats.totalImagesInfoText', {
                  pageSet: dataScope,
                })}
                totalCounts={{
                  selection: {
                    color: getChartColor('objects'),
                    count: selection.totalImages,
                    name: selection.name,
                  },
                  ...(hasCompareData && {
                    compared: {
                      color: getChartColor('objectsAll'),
                      count: compared.totalImages,
                      name: compared.name,
                    },
                  }),
                }}
              />
            )}
          </Box>
        )}
        <ClassStatsTableHeader headers={headers} />
      </Box>
      <Box>
        {selection.data.map((datum, _) => (
          <ClassStatsTableRow
            headers={headers}
            excludeImageInfo={excludeImageInfo}
            key={datum?.name + 'selection'}
            datum={datum}
            totalAnnotations={selection.totalAnnotations}
            totalImages={selection?.totalImages || 0}
            comparedDatum={compared?.data.find(d => d.name === datum.name)}
            totalComparedAnnotations={compared?.totalAnnotations}
            totalComparedImages={compared?.totalImages}
            isCompareMode={isCompareMode}
          />
        ))}
      </Box>
    </Box>
  );
}
