import { Slice } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../../menu';
import SlicesRoute from './Route';

export const SlicesMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetSlice,
  path: 'slice',
  title: ({ t, datasetInfo }) =>
    t('curate.dataset.menus.slicesCount', { count: datasetInfo?.sliceCount ?? 0 }),
  component: SlicesRoute,
  icon: <Icon icon={Slice} />,
  hasLowerLevelPage: true,
});
