import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';
import { cloneDeep, compact, concat, find, join, map, size } from 'lodash';

import { useFilterInfo } from '../../../../../contexts/FilterContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import FilterUtils from '../../../../../utils/FilterUtils';
import { FilterTypeProps, Option } from '../types';
import FilterClassDropdownList from './FilterClassDropdownList';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 80,
    height: '32px',
    width: '100%',
    borderRadius: '2px',
    boxSizing: 'border-box',
    border: 'solid 1px #D4D4D4',
    padding: '0px 10px',
  },
  currentRow: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  filterBy: {
    width: '165px',
  },
  input: {
    width: '100%',
    fontSize: '13px',
  },
  dropdownPaper: {
    marginTop: '8px',
    width: '500px',
    maxHeight: '300px',
    padding: '4px 0px',
    overflow: 'auto',
  },
  typography: {
    display: 'block',
    whiteSpace: 'nowrap',
    marginRight: '4px',
  },
}));

interface Props extends Omit<FilterTypeProps, 'filter'> {
  objKey: string;
}

const FilterClassDropdown = ({
  index,
  objKey,
  onValueChange,
  isCurrentRow,
  onChangeCurrentRow,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = MUI.useTheme();
  const [open, setOpen] = useState(false);
  const [searchedAnnoOptions, setSearchedAnnoOptions] = useState<Option[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const projectInfo = useProjectInfo();
  const { annoOptions, categorizationIdToName, flatAnnoOptions } = projectInfo;
  const filterInfo = useFilterInfo();
  const { selectedFilters } = filterInfo;
  const annoFilterOptions = (find(selectedFilters, ['filterBy', 'annotation'])?.options ??
    []) as string[];
  const selectedOptionsCount = size(annoFilterOptions);

  useEffect(() => {
    setSearchedAnnoOptions(
      annoOptions.map(({ annoType, ...rest }: { annoType: string }) => ({
        ...rest,
        annoType: t(`labels.annotation.${annoType.toLowerCase()}`),
      })),
    );
  }, [annoOptions, anchorEl]);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    if (event.target !== inputRef.current) {
      inputRef.current.blur();
    }
    if (event.target === anchorEl) {
      inputRef.current.focus();
    }
    setOpen(false);
  };

  const handleToggleDropdown = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    if (event.currentTarget) {
      setOpen(true);
      if (inputRef.current) {
        inputRef.current.focus();
      }
      return;
    }
    setOpen(false);
  };

  const getFilteredOptions = (options: Option[], input: string): Option[] => {
    const nodes = cloneDeep(options);
    const isIncludingInput = (option: Option) => {
      const parentsString = join(
        concat(
          map(option.parents, parent => parent.label),
          option.label,
        ),
        ';',
      );
      const result = option.label
        ? parentsString.toLowerCase().includes(input.toLowerCase())
        : false;
      return result;
    };

    const newNodes = map(nodes, node => {
      if (node.children) {
        const nextNodes = getFilteredOptions(node.children, input);
        if (nextNodes.length > 0) {
          const newNode = Object.assign(node, { children: nextNodes });
          return newNode;
        }
      }
      if (isIncludingInput(node)) {
        return node;
      }
      return null;
    });

    return compact(newNodes);
  };

  const handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!open) {
      setOpen(true);
    }
    const input = event.target.value;
    const filteredOptions = getFilteredOptions(annoOptions, input);
    setSearchedAnnoOptions(filteredOptions);
  };

  const handleClickDropdown = () => {
    inputRef.current?.focus();
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <>
      <MUI.Box
        id={id}
        className={`${classes.formControl} ${isCurrentRow ? classes.currentRow : ''}`}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleToggleDropdown}
      >
        {selectedOptionsCount > 0 && (
          <MUI.Typography variant="body2" className={classes.typography}>
            {FilterUtils.getAnnoFilterCount(
              annoFilterOptions,
              categorizationIdToName,
              flatAnnoOptions,
            )}
          </MUI.Typography>
        )}
        <MUI.Input
          className={classes.input}
          inputRef={inputRef}
          placeholder={selectedOptionsCount > 0 ? '' : t('labels.filters.selectPlaceholder')}
          onChange={handleChangeInputValue}
        />
        <Icon
          name="chevronDown"
          size="16px"
          customColor={isCurrentRow ? theme.palette.primary.main : theme.palette.grey[700]}
        />
      </MUI.Box>
      <MUI.Popper open={open} anchorEl={anchorEl} placement="bottom-start">
        <MUI.Paper className={classes.dropdownPaper} onClick={handleClickDropdown}>
          <MUI.ClickAwayListener onClickAway={handleClose}>
            <MUI.Box>
              <FilterClassDropdownList
                index={index}
                objKey={objKey}
                searchedAnnoOptions={searchedAnnoOptions}
                selectedOptions={annoFilterOptions}
                onValueChange={onValueChange}
                onChangeCurrentRow={onChangeCurrentRow}
              />
            </MUI.Box>
          </MUI.ClickAwayListener>
        </MUI.Paper>
      </MUI.Popper>
    </>
  );
};

export default FilterClassDropdown;
