import React, { useEffect, useState } from 'react';

import {
  Box,
  DropdownList,
  Icon,
  Input,
  RadioButtons,
  Typography,
} from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { getVideoFramesSourceDataOptions } from './config';
import { UploadStepInfo } from './type';

const SelectSourceData = (t: TFunction): UploadStepInfo => {
  const fileInfo = useUploadInfo();
  const { enqueueSnackbar } = useSnackbar();
  const {
    setIsStepsCompleted,
    setFiles,
    setUrls,
    setDataset,
    setSelectedProject,
    setFps,
    setCustomFps,
    setVideoFramesSourceData,
    setCloudStorageType,
    setCloudIntegrationId,
    setCloudPrefix,
    setCloudSaveMethod,
    setCloudBucketName,
    setIntegratedCloudPrefix,
    setCsvUploadMethod,
    setManifestFileName,
    videoFramesSourceData,
    setUploadMethod,
    customFps,
    fps,
  } = fileInfo;
  const [inputValue, setInputValue] = useState(1);

  const framePerSeconds = [
    { value: '0.25', label: '0.25' },
    { value: '0.5', label: '0.5' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: 'custom', label: 'custom' },
  ];

  const handleChangeOption = (value: 'video' | 'image-sequence') => {
    setVideoFramesSourceData(value);
    // TODO(shko): reset following options
  };

  const handleChangeFps = (value: string) => {
    if (value === 'custom') {
      setFps('custom');
    } else {
      setFps(Number(value));
    }
  };

  const handleBlurCustomFps = () => {
    const nextCustomFps = Number(inputValue);
    if (Number.isNaN(nextCustomFps)) {
      setInputValue(customFps);
      enqueueSnackbar(t('data.upload.numericOnly'), { variant: 'warning' });
    } else if (nextCustomFps < 0.1 || nextCustomFps > 30) {
      setInputValue(customFps);
      enqueueSnackbar(t('data.upload.frameRateWarning'), { variant: 'warning' });
    } else {
      setCustomFps(nextCustomFps);
      setInputValue(nextCustomFps);
    }
  };

  const handleChangeCustomFps = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setIsStepsCompleted(false);
    setFiles([]);
    setUrls([]);
    setDataset('');
    setSelectedProject(null);
    setFps(1);
    setCustomFps(1);
    setUploadMethod('CLOUD');
    setCloudStorageType('S3');
    setCloudIntegrationId(null);
    setCloudPrefix(null);
    setCloudSaveMethod('copy');
    setCloudBucketName('');
    setIntegratedCloudPrefix(null);
    setCsvUploadMethod('URL');
    setManifestFileName(null);
  }, [videoFramesSourceData]);

  const summary = {
    video: (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <Icon name="videoFrames" />
        </Box>
        {t('components.CustomShortcut.video')} (MP4)
      </Box>
    ),
    'image-sequence': (
      <Box
        display="flex"
        alignItems="center"
        themedColor={['grey', 500]}
        style={{ fontSize: '12px' }}
      >
        <Box display="flex" alignItems="center" mr={0.5}>
          <Icon name="imageSequence" />
        </Box>
        <Typography>{t('data.upload.imageSequence')}</Typography>
      </Box>
    ),
  }[videoFramesSourceData || 'video'];

  return {
    title: t('data.upload.selectSourceFileType'),
    isButtonEnabled: !!videoFramesSourceData,
    summary,
    content: (
      <>
        <RadioButtons
          options={getVideoFramesSourceDataOptions(t)}
          value={videoFramesSourceData || 'video'}
          onChange={handleChangeOption}
        />
        {videoFramesSourceData === 'video' && (
          <>
            <Box mt={2}>
              <Typography variant="label" themedColor="textDefault">
                {t('data.upload.targetFPS')}
              </Typography>
            </Box>
            <Box mt={0.5}>
              <Typography variant="body3" themedColor={['grey', 500]}>
                {t('data.upload.targetFPSDescription')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <Box width="88px" display="flex">
                <DropdownList
                  value={fps.toString()}
                  color="primary"
                  onChange={handleChangeFps}
                  options={framePerSeconds}
                />
              </Box>
              {fps === 'custom' && (
                <Box width="88px" display="flex" ml={1}>
                  <Input
                    value={inputValue}
                    onChange={handleChangeCustomFps}
                    onBlur={handleBlurCustomFps}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </>
    ),
  };
};

export default SelectSourceData;
