import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { isEmpty } from 'lodash';

import { StateGetterSetter } from '../../../contexts/types';
import { METADATA_CARDINALITY_THRESHOLD } from '../components/datasets/dataset/filter/const';
import {
  AnnotationFilterSchema,
  ClassClusterParam,
  CLUSTER_LEVEL_2_SIZE_8,
  FilterSchemaWithExpands,
  ProjectionInParam,
} from '../components/datasets/dataset/filter/types';
import { generateFilterDotColors } from '../components/datasets/dataset/views/embedding/scatterView/utils/color';
import { useDatasetObjectFilterSchemaQuery } from '../queries/datasetObjectQueries';
import { stringifyFilters } from '../utils/filterUtils';
import { useObjectClusterContext } from './ObjectClusterContext';
import { useSliceContext } from './SliceContext';

type ContextProps = StateGetterSetter<
  ['selectedMetadata', 'setSelectedMetadata'],
  Record<string, string[]> | undefined
> &
  StateGetterSetter<['classClusterFilterTab', 'setClassClusterFilterTab'], 'class' | 'cluster'> &
  StateGetterSetter<['selectedClasses', 'setSelectedClasses'], string[] | undefined> &
  StateGetterSetter<
    ['selectedSuperClusters', 'setSelectedSuperClusters'],
    ClassClusterParam[] | undefined
  > &
  StateGetterSetter<
    ['selectedAnnotationTypes', 'setSelectedAnnotationTypes'],
    string[] | undefined
  > &
  StateGetterSetter<['appliedFilters', 'setAppliedFilters'], AnnotationFilterSchema> &
  StateGetterSetter<
    ['selectedProjectionIn', 'setSelectedProjectionIn'],
    ProjectionInParam | undefined
  > &
  StateGetterSetter<['isApplyingFilter', 'setIsApplyingFilter'], boolean> &
  StateGetterSetter<['isFilterOpen', 'setIsFilterOpen'], boolean> &
  StateGetterSetter<
    ['selectedAnnotationClassInClusterTab', 'setSelectedAnnotationClassInClusterTab'],
    string | undefined
  > & {
    filterSchema?: FilterSchemaWithExpands;
    isLoadingFilterSchema: boolean;
    hasAppliedFilters: boolean;
    classNameColorMap: Record<string, string>;
    initializeAppliedFilter: () => void;
    initializeSelectedFilter: () => void;
    isFilterChanged: boolean;
    selectedFilters: AnnotationFilterSchema;
  };

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { sliceInfo } = useSliceContext();
  const { clusterLevel, setClusterLevel } = useObjectClusterContext();

  const { data: filterSchemaData, isLoading: isLoadingFilterSchema } =
    useDatasetObjectFilterSchemaQuery({
      datasetId,
      ...(sliceInfo && { sliceName: sliceInfo.name }),
    });
  const defaultSchema: AnnotationFilterSchema = {
    annotation_type_in: [],
    annotation_class_in: [],
    metadata_in: {},
    annotation_class_cluster_in: [],
  };
  const filterSchema = filterSchemaData;
  const [selectedMetadata, setSelectedMetadata] = useState<Record<string, string[]>>();
  const [selectedClasses, setSelectedClasses] = useState<string[]>();
  const [selectedSuperClusters, setSelectedSuperClusters] = useState<ClassClusterParam[]>();
  const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState<string[]>();
  const [appliedFilters, setAppliedFilters] = useState<AnnotationFilterSchema>(defaultSchema);
  const [selectedProjectionIn, setSelectedProjectionIn] = useState<ProjectionInParam>();
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [classClusterFilterTab, setClassClusterFilterTab] = useState<'class' | 'cluster'>('class');

  const clusterLevelsPerClass = filterSchema?.annotation_class_cluster_level_in;
  const clusterLevelsPerClassEntries =
    clusterLevelsPerClass && Object.entries(clusterLevelsPerClass);
  const firstClassInfo =
    classClusterFilterTab === 'cluster' &&
    clusterLevelsPerClassEntries &&
    clusterLevelsPerClassEntries[0];
  const [selectedAnnotationClassInClusterTab, setSelectedAnnotationClassInClusterTab] =
    useState<string>(firstClassInfo ? (firstClassInfo[0] as string) : undefined);

  useEffect(() => {
    const newClusterLevel = isEmpty(filterSchema?.annotation_class_cluster_level_in)
      ? undefined
      : clusterLevel || CLUSTER_LEVEL_2_SIZE_8;
    if (newClusterLevel === undefined) return;
    setClusterLevel(newClusterLevel);
  }, [filterSchema]);

  useEffect(() => {
    setAppliedFilters({ ...appliedFilters, cluster_level: clusterLevel });
  }, [clusterLevel]);

  const hasAppliedFilters =
    (appliedFilters?.annotation_class_in?.length || 0) +
      (appliedFilters?.annotation_class_cluster_in?.flatMap(v => v.cluster_id_in).length || 0) +
      (appliedFilters?.annotation_type_in?.length || 0) +
      (appliedFilters?.metadata_all_values_in?.length || 0) +
      Object.keys(appliedFilters?.metadata_in || {}).length +
      Object.keys(appliedFilters.projection_in || {}).length >
    0;

  const [classNameColorMap, setClassNameColorMap] = useState<Record<string, string>>();
  useEffect(() => {
    if (filterSchema?.annotation_class_in && filterSchema.annotation_class_in.length) {
      setClassNameColorMap(
        generateFilterDotColors(filterSchema?.annotation_class_in ?? [], clusterLevel),
      );
    }
  }, [filterSchema?.annotation_class_in]);

  function initializeAppliedFilter() {
    setAppliedFilters(defaultSchema);
  }

  function initializeSelectedFilter() {
    setSelectedMetadata(undefined);
    setSelectedClasses(undefined);
    setSelectedSuperClusters(undefined);
    setSelectedAnnotationTypes(undefined);
    setSelectedProjectionIn(undefined);
  }

  const selectedFilters = useMemo(() => {
    const metadataFilters = selectedMetadata
      ? Object.keys(selectedMetadata).reduce(
          (acc, key) => {
            if (
              filterSchema &&
              filterSchema.metadata_cardinality[key] > METADATA_CARDINALITY_THRESHOLD
            ) {
              return { ...acc, metadata_all_values_in: [...acc.metadata_all_values_in, key] };
            }
            return { ...acc, metadata_in: { ...acc.metadata_in, [key]: selectedMetadata[key] } };
          },
          {
            metadata_in: {},
            metadata_all_values_in: [],
          } as {
            metadata_in: Record<string, string[]>;
            metadata_all_values_in: string[];
          },
        )
      : { metadata_in: {} };

    return {
      annotation_class_in: selectedClasses || [],
      cluster_level: clusterLevel,
      annotation_type_in: selectedAnnotationTypes || [],
      ...(selectedSuperClusters && { annotation_class_cluster_in: selectedSuperClusters }),
      ...metadataFilters,
      projection_in: selectedProjectionIn,
    };
  }, [
    selectedMetadata,
    selectedClasses,
    selectedAnnotationTypes,
    selectedSuperClusters,
    filterSchema,
    selectedProjectionIn,
  ]);

  const isFilterChanged = useMemo(
    () => stringifyFilters(appliedFilters) !== stringifyFilters(selectedFilters),
    [appliedFilters, selectedFilters],
  );

  return {
    classNameColorMap,
    filterSchema,
    isLoadingFilterSchema,
    selectedSuperClusters,
    setSelectedSuperClusters,
    selectedMetadata,
    setSelectedMetadata,
    selectedClasses,
    setSelectedClasses,
    selectedAnnotationTypes,
    setSelectedAnnotationTypes,
    hasAppliedFilters,
    appliedFilters,
    setAppliedFilters,
    initializeAppliedFilter,
    isApplyingFilter,
    setIsApplyingFilter,
    isFilterOpen,
    setIsFilterOpen,
    isFilterChanged,
    selectedProjectionIn,
    setSelectedProjectionIn,
    initializeSelectedFilter,
    classClusterFilterTab,
    setClassClusterFilterTab,
    selectedFilters,
    selectedAnnotationClassInClusterTab,
    setSelectedAnnotationClassInClusterTab,
  };
};

export const useObjectFilterContext = (): ContextProps => {
  return React.useContext(Context);
};

export const ObjectFilterProvider: React.FC = ({ children }) => {
  const filterInfo = useProvider();
  return <Context.Provider value={filterInfo}>{children}</Context.Provider>;
};
