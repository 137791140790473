import { TFunction } from 'react-i18next';

import { find, findIndex, groupBy, keys, map, mapValues, reduce } from 'lodash';

import LabelInterfaceUtils, {
  ImageLabelInterface,
  VideoLabelInterface,
} from '../../../../../utils/LabelInterfaceUtils';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { BottomHeader, JsonObj, LabelerBottomHeaderCell, ReportVersions } from '../types';

interface ProjectConfig {
  hasFrame: boolean;
  hasCategory: boolean;
  hasObject?: boolean;
}

const evenColor = '#FAFAFA';
const oddColor = '#F4F4F4';

const cellColorByIndex = (colIndex: number) => {
  return colIndex % 2 === 0 ? evenColor : oddColor;
};

const getGroupColor = (group: string) => {
  return {
    reviewActions: oddColor,
    reviewedLabels: evenColor,
  }[group];
};

export const getReportCreatedAt = (resp: JsonObj): Date => {
  return new Date(resp?.result?.createdAt);
};

const getV2DeployDate = (): Date => {
  return new Date('2022-04-20');
};

export const getReportVersion = (createdAt: Date): ReportVersions => {
  return createdAt < getV2DeployDate() ? 1 : 2;
};

export const isUserReportV2 = (version: ReportVersions): boolean => version === 2;

const columnText = (key: string) => {
  return {
    name: 'analytics.text.name',
    email: 'analytics.text.email',
    role: 'projectMembers.role.role',
    progress: 'analytics.userReports.progress',
    approve: 'review.approve',
    reject: 'review.reject',
    pendingReview: 'review.pendingReview',
    reviewAssigned: 'analytics.userReports.review.reviewAssigned',
    // 10/19/2022 Release
    reviewedLabels: 'text.labels',
    reviewedFrames: 'analytics.asset.frames',
    reviewedFramesHasAnnotations: 'analytics.userReports.review.reviewedFramesHasAnnotations',
    reviewedCategories: 'labelInterface.categories',
    reviewedAnnotations: 'analytics.text.objects',
    approveActionCount: 'analytics.userReports.review.approvals',
    rejectActionCount: 'analytics.userReports.review.rejections',
    // Deprecated 10/19/2022
    //avgReviewRound: 'analytics.userReports.avgReviewRound',
  }[key];
};

export const columnNamesV1: JsonObj = {
  name: { id: 'name', displayName: columnText('name') },
  email: { id: 'email', displayName: columnText('email'), hidden: true },
  role: { id: 'role', displayName: columnText('role') },
  approveLabelCount: { id: 'approveLabelCount', displayName: columnText('approve') },
  rejectLabelCount: { id: 'rejectLabelCount', displayName: columnText('reject') },
};

export const columnNamesV2 = (projectInfo: ProjectConfig): JsonObj => {
  return {
    name: { id: 'name', displayName: columnText('name'), group: 'info' },
    email: { id: 'email', displayName: columnText('email'), hidden: true },
    role: { id: 'role', displayName: columnText('role'), group: 'info' },
    progress: { id: 'role', displayName: columnText('progress'), group: 'tasks', download: false },
    reviewAssigned: {
      id: 'reviewAssigned',
      displayName: columnText('reviewAssigned'),
      group: 'tasks',
    },
    pendingReview: {
      id: 'pendingReview',
      displayName: columnText('pendingReview'),
      group: 'task',
    },
    approved: { id: 'approved', displayName: columnText('approve'), group: 'task' },
    rejected: { id: 'rejected', displayName: columnText('reject'), group: 'tasks' },
    // 10/29/2022 Sprint 105 Release
    approveActionCount: {
      id: 'approveActionCount',
      displayName: columnText('approveActionCount'),
      group: 'reviewActions',
    },
    rejectActionCount: {
      id: 'rejectActionCount',
      displayName: columnText('rejectActionCount'),
      group: 'reviewActions',
    },

    reviewedLabels: {
      id: 'reviewedLabels',
      displayName: columnText('reviewedLabels'),
      group: 'reviewedLabels',
    },
    ...(projectInfo.hasFrame && {
      reviewedFrames: {
        id: 'reviewedFrames',
        displayName: columnText('reviewedFrames'),
        group: 'reviewedLabels',
      },
    }),
    ...(projectInfo.hasCategory && {
      reviewedCategories: {
        id: 'reviewedCategories',
        displayName: columnText('reviewedCategories'),
        group: 'reviewedLabels',
      },
    }),
    ...(projectInfo.hasObject && {
      reviewedAnnotations: {
        id: 'reviewedAnnotations',
        displayName: columnText('reviewedAnnotations'),
        group: 'reviewedLabels',
      },
    }),
  };
};

type MapStringTo<T> = Record<string, T>;

// warning: must match object keys in displayColumnNames
// REFACTOR: generate this programmatically from bottomHeader
export const groupToColumns = (
  apiVersion: ReportVersions,
  projectConfig: ProjectConfig,
): MapStringTo<string[]> => {
  const result = {
    info: ['name', 'role'],
  };
  if (apiVersion === 2) {
    return {
      ...result,
      reviewTasks: ['progress', 'reviewAssigned', 'pendingReview', 'approved', 'rejected'],
      reviewActions: ['rejectActionCount', 'approveActionCount'],
      reviewedLabels: [
        'reviewedLabels',
        // ...(projectConfig.hasFrame ? ['reviewedFrames', 'reviewedFramesHasAnnotations'] : []),
        ...(projectConfig.hasFrame ? ['reviewedFrames'] : []),
        ...(projectConfig.hasCategory ? ['reviewedCategories'] : []),
        ...(projectConfig.hasObject ? ['reviewedAnnotations'] : []),
      ],
      // reviewStatistics: ['avgReviewRound'], (deprecated)
    };
  }
  return {
    ...result,
    reviewTasks: ['approvedLabelCounts', 'rejectedLabelCounts'],
  };
};

export function getGroupToColumns(
  apiVersion: ReportVersions,
  projectConfig: ProjectConfig,
): MapStringTo<string[]> {
  return isUserReportV2(apiVersion)
    ? groupToColumns(apiVersion, projectConfig)
    : groupToColumns(apiVersion, projectConfig);
}

export const getColumnDisplayOrder = (
  apiVersion: ReportVersions,
  projectInfo: ProjectConfig,
): string[] => {
  return isUserReportV2(apiVersion)
    ? Object.keys(columnNamesV2(projectInfo))
    : Object.keys(columnNamesV1);
};

export function getReviewerColumns(
  apiVersion: ReportVersions = 2,
  projectInfo: ProjectConfig,
): JsonObj {
  return isUserReportV2(apiVersion) ? columnNamesV2(projectInfo) : columnNamesV1;
}

export function getProjectConfig(labelInterface: ImageLabelInterface | VideoLabelInterface) {
  // does not support legacy
  return {
    hasObject:
      LabelInterfaceUtils.hasObject(labelInterface) ||
      LabelInterfaceUtils.videoHasObject(labelInterface),
    hasCategory: LabelInterfaceUtils.hasCategory(labelInterface),
    hasFrame: ProjectUtils.isFrameBasedProject(labelInterface),
  };
}

// REFACTOR: use agreed format
export const reviewerColumnIdToName = (params: {
  projectConfig: ProjectConfig;
  t?: TFunction<'translation', undefined>;
  apiVersion?: ReportVersions;
}): JsonObj => {
  const { t, apiVersion, projectConfig } = params;
  const columns = getReviewerColumns(apiVersion, projectConfig);
  const result = mapValues(columns, item => item?.displayName);
  return mapValues(result, col => (t && col.includes('.') ? t(col) : col));
};

export const getColumnGroup = (column: string, groupToColumns: JsonObj): string => {
  const groups = keys(groupToColumns);
  return find(groups, function (group: string) {
    return groupToColumns[group].includes(column);
  }) as string;
};

export const getGroupIndex = (group: string, groupToColumns: JsonObj): number => {
  const orderedGroups = keys(groupToColumns);
  return findIndex(orderedGroups, currentGroup => currentGroup === group);
};

export function getBottomHeader(
  apiVersion: ReportVersions,
  projectConfig: ProjectConfig,
): BottomHeader[] | LabelerBottomHeaderCell[] {
  // consolidate to one type
  const displayNames = reviewerColumnIdToName({ apiVersion, projectConfig });
  const orderedColumns = getColumnDisplayOrder(apiVersion, projectConfig);
  const groupToColumns = getGroupToColumns(apiVersion, projectConfig);

  return orderedColumns.map(col => {
    const group = getColumnGroup(col, groupToColumns);
    const groupIndex = getGroupIndex(group, groupToColumns);
    return {
      id: col,
      name: displayNames[col],
      color: getGroupColor(group) || cellColorByIndex(groupIndex),
      group,
      ...(col === 'email' && { hidden: true, group: 'info' }),
    };
  }) as BottomHeader[];
}

type TopHeader = {
  groupKey: string;
  displayName: string;
  color: string;
  count: number;
};

export function getGroupDisplayName(group: string): string {
  return {
    info: 'review.reviewer',
    reviewTasks: 'analytics.userReports.reviewTasks',
    //reviewStatistics: 'analytics.userReports.reviewStatistics',
    reviewActions: 'analytics.userReports.review.reviewActions',
    reviewedLabels: 'analytics.userReports.reviewedLabels',
  }[group] as string;
}

export function getReviewerTableTopHeader(bottomHeader: BottomHeader[]): TopHeader[] {
  const columns = bottomHeader.filter(column => column?.hidden !== true);
  const columnGroups = groupBy(columns, 'group');

  return map(keys(columnGroups), (group, index) => {
    return {
      groupKey: group,
      displayName: getGroupDisplayName(group),
      count: columnGroups[group].length, // num columns
      color: cellColorByIndex(index),
    };
  });
}

export const getReviewedLabelsV1 = (rows: JsonObj[]) => {
  return reduce(
    rows,
    (acc, reviewer) => {
      return acc + reviewer.approveLabelCount + reviewer.rejectLabelCount;
    },
    0,
  );
};

// export const getReviewerAssignedLabels = (reviewer: JsonObj) => {
//   // const groups = getGroupToColumnsV2()
//   // const labelColumns = omit(groups['labelCounts'], ['avgReviewRound'])
//   return reviewer['approved'] + reviewer['rejected'] + reviewer['pendingReview'] + reviewer['notSubmitted'];
// };

export const getReviewAssignedLabelsV2 = (rows: JsonObj[]) => {
  return reduce(
    rows,
    (acc, reviewer) => {
      return acc + reviewer?.reviewAssigned;
    },
    0,
  );
};

export const getReviewDownloadSheetName = (apiVersion: ReportVersions) => {
  return isUserReportV2(apiVersion) ? 'Reviewed Labels' : 'Review Assigned';
};
