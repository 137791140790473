import React from 'react';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { ProjectSteps } from '../../../../configs/NewProjectStepsConfig';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';

// eslint-disable-next-line
const ClassPropertyItemEditableText = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;

  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Object class name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const sameHierarchyNameList = newProjectInfo.objectClasses[
      newProjectInfo.selectedObjectClassIndex
    ].properties.map((item: any) => item.name);

    return parentHelper.checkNameRule({
      t,
      type: 'Class property name',
      prevValue: classProperty.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
    });
  };

  const editName = (value: string) => {
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);

    const objectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    objectClass.properties[index].name = value;

    newProjectInfo.setObjectClasses(nextObjectClasses);
  };

  return (
    <EditableText
      text={classProperty.name}
      maxWidth="320px"
      fontStyles={{
        fontSize: '13px',
        color: '#797979',
        fontWeight: 500,
      }}
      parseValue={parseValue}
      checkRule={checkNameRule}
      editText={editName}
      isMount={newProjectInfo.createState === ProjectSteps.CLASS_PROPERTY}
    />
  );
};

export default ClassPropertyItemEditableText;
