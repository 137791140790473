import React from 'react';
import { TFunction } from 'react-i18next';

import { Typography } from '@superb-ai/norwegian-forest';

import { getUserManualUrl } from '../../../../../consts/DocsLink';

function SLACK(t: TFunction, language: string) {
  const { SLACK_INTEGRATIONS_MANUAL } = getUserManualUrl(language);
  return {
    title: 'Slack',
    docsLink: SLACK_INTEGRATIONS_MANUAL,
    summary: <>{t('integration.slack.summary')}</>,
    description: <Typography variant="body3">{t('integration.slack.description')}</Typography>,
    logo: <img src="/static/image/slack.png" width="100%" alt="gcp logo" />,
    addUrl: '/account/integrations/SLACK/add',
    tabs: undefined,
  };
}

export default SLACK;
