import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { clone, set, toInteger } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  CanNotChangeLabel,
  ChangeLabelByIdFromSuite,
  IFrameMessageFromAnnotationApp,
} from '../apps/annotationApp/providers/PopupModePostMessageProvider';
import { FIRST_LABEL, LAST_LABEL } from '../consts/SnackbarMessage';
import LabelsService from '../services/LabelsService';
import ParamUtils from '../utils/ParamUtils';
import { useSearchParams } from '../utils/router-utils';
import { useAuthInfo } from './AuthContext';
import { useFeatureFlag } from './FeatureFlagContext';
import { useLabelsInfo } from './LabelsContext';
import { useProjectInfo } from './ProjectContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

type DetailViewContextProps = StateGetterSetter<['isOpen', 'setIsOpen'], boolean> &
  StateGetterSetter<['isConnected', 'setIsConnected'], boolean> &
  StateGetterSetter<['labelId', 'setLabelId'], string> & {
    iframeRef: any;
    changeLabel(dir: 'prev' | 'next'): Promise<void>;
  } & StateGetterSetter<['hasOpenOnce', 'setHasOpenOnce'], boolean> & {
    getPrevAndNextLabelIds(labelId: string): string[];
  };

export const DetailViewContext = React.createContext({} as DetailViewContextProps);

export const DetailViewProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const routeInfo = useRouteInfo();
  const urlSearchParams = useSearchParams();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelsInfo = useLabelsInfo();
  const { labels, cursorInfo } = labelsInfo;

  const iframeRef = useRef<Record<string, any>>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [labelId, setLabelId] = useState<string>('');
  const [dir, setDir] = useState<'none' | 'prev' | 'next'>('none');
  // this is determined when the label is changed by the direction button.
  // When the label is the first or last one, isGettingLabel is not set to true when the direction button is clicked.
  // const [isGettingLabel, setIsGettingLabel] = useState(false);
  const [hasOpenOnce, setHasOpenOnce] = useState(false);
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  useEffect(() => {
    const receiveMessage = (e: MessageEvent) => {
      const messageFromAnnotationApp: IFrameMessageFromAnnotationApp = e.data;
      if (!messageFromAnnotationApp) {
        return;
      }

      switch (messageFromAnnotationApp.name) {
        case 'change-label-by-direction': {
          setDir(messageFromAnnotationApp.payload.direction);
          return;
        }
        case 'connect-success': {
          setIsConnected(true);
          return;
        }
        case 'close-iframe': {
          setIsOpen(false);
          setLabelId('');
          setHasOpenOnce(false);
        }
      }
    };

    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [isOpen, labelId]);

  const loadLabelsForPage = async (page: number) => {
    try {
      const { allowAdvancedQa } = projectInfo.project.settings;

      const filterApiParams = ParamUtils.getApiParamsForFilter({
        filterParams: [...urlSearchParams, ['page', `${page}`]],
        tagIds: projectInfo.tagIds,
      });
      if (allowAdvancedQa) {
        filterApiParams.groupAssets = true;
      }
      const sortParam = routeInfo?.params?.ordering
        ? { ordering: routeInfo?.params?.ordering }
        : {};
      const { results } = await LabelsService.getLabels({
        params: { ...filterApiParams, ...sortParam },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      return results;
    } catch (err: any) {
      return [];
    }
  };

  const loadLabelsForNextCursor = async () => {
    try {
      const { allowAdvancedQa } = projectInfo.project.settings;

      const filterApiParams = ParamUtils.getApiParamsForFilter({
        filterParams: [...urlSearchParams],
        tagIds: projectInfo.tagIds,
      });
      if (allowAdvancedQa) {
        filterApiParams.groupAssets = true;
      }
      const sortParam = routeInfo?.params?.ordering
        ? { ordering: routeInfo?.params?.ordering }
        : {};
      const { results } = await LabelsService.getLabelsV2({
        params: {
          ...filterApiParams,
          ...sortParam,
          searchAfter: cursorInfo['next']?.map(value => value) ?? [],
        },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      return results;
    } catch (err: any) {
      return [];
    }
  };

  const loadLabelsForPrevCursor = async () => {
    try {
      const { allowAdvancedQa } = projectInfo.project.settings;

      const filterApiParams = ParamUtils.getApiParamsForFilter({
        filterParams: [...urlSearchParams],
        tagIds: projectInfo.tagIds,
      });
      if (allowAdvancedQa) {
        filterApiParams.groupAssets = true;
      }
      const sortParam = routeInfo?.params?.ordering
        ? { ordering: routeInfo?.params?.ordering }
        : {};
      const { results } = await LabelsService.getLabelsV2({
        params: {
          ...filterApiParams,
          ...sortParam,
          searchBefore: cursorInfo['prev']?.map(value => value) ?? [],
        },
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      return results;
    } catch (err: any) {
      return [];
    }
  };

  const getPrevAndNextLabelIds = (labelId: string) => {
    const results: string[] = [];

    const currentLabelIndex = labels.findIndex(item => item.id === labelId);
    if (currentLabelIndex === -1) {
      return results;
    }

    const prevLabelIndex = currentLabelIndex - 1;
    if (prevLabelIndex >= 0) {
      results.push(labels[prevLabelIndex].id);
    }

    const nextLabelIndex = currentLabelIndex + 1;
    if (nextLabelIndex < labels.length) {
      results.push(labels[nextLabelIndex].id);
    }

    return results;
  };

  const changeLabelLegacy = async (dir: 'prev' | 'next') => {
    const iframeRefCurrent: Record<string, any> = iframeRef.current || {};
    const curLabelIndex = labels.findIndex(item => item.id === labelId);
    const curPage = routeInfo.params.page ? toInteger(routeInfo.params.page) : 1;
    const rowsPerPage = routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10;

    let nextLabels = labels;
    let nextLabelId = '';
    let nextLabelIndex = -1;
    let nextPage;
    const requestedLabelIds = [];

    if (dir === 'prev') {
      nextLabelIndex = curLabelIndex - 1;

      if (nextLabelIndex < 0) {
        // first label

        if (curPage === 1) {
          // first page
          enqueueSnackbar(FIRST_LABEL({ t }), { variant: 'warning' });
        } else {
          // move page and get labels
          nextPage = curPage - 1;
        }
      } else {
        //  label change

        // eslint-disable-next-line
        nextLabelId = nextLabels[nextLabelIndex].id;

        if (nextLabelIndex === 0 && curPage !== 1) {
          nextLabels = await loadLabelsForPage(curPage - 1);

          if (nextLabels.length !== 0) {
            requestedLabelIds.push(nextLabels[nextLabels.length - 1].id);
          }
        }
      }
    } else if (dir === 'next') {
      nextLabelIndex = curLabelIndex + 1;

      if (nextLabelIndex >= nextLabels.length) {
        // last label
        const totalPage = Math.ceil(labelsInfo.totalCount / rowsPerPage);

        if (curPage === totalPage) {
          // last page
          enqueueSnackbar(LAST_LABEL({ t }), { variant: 'warning' });
        } else {
          // move page and get labels
          nextPage = curPage + 1;
        }
      } else {
        //  label change

        // eslint-disable-next-line
        nextLabelId = nextLabels[nextLabelIndex].id;

        const totalPage = Math.ceil(labelsInfo.totalCount / rowsPerPage);
        if (nextLabelIndex === nextLabels.length - 1 && curPage !== totalPage) {
          await loadLabelsForPage(curPage + 1);

          if (nextLabels.length !== 0) {
            requestedLabelIds.push(nextLabels[0].id);
          }
        }
      }
    }

    if (nextPage) {
      nextLabels = await loadLabelsForPage(nextPage);
      const nextLabelCount = nextLabels.length === rowsPerPage ? rowsPerPage : nextLabels.length;
      nextLabelIndex = dir === 'prev' ? nextLabelCount - 1 : 0;
      const searchParams = new URLSearchParams(set(clone(routeInfo.params), 'page', nextPage));
      routeInfo.history.push(`?${searchParams.toString()}`);
      nextLabelId = nextLabels[nextLabelIndex]?.id;
    }

    if (nextLabelId) {
      const requestedLabelId = nextLabelId;
      requestedLabelIds.push(
        ...[nextLabelIndex - 1, nextLabelIndex + 1]
          .filter(index => index >= 0 && index < nextLabels.length)
          .map(index => nextLabels[index].id),
      );

      iframeRefCurrent.contentWindow.postMessage(
        {
          sender: 'suite',
          receiver: 'annotation-app',
          name: 'change-label-by-id',
          payload: {
            labelId: requestedLabelId,
            prevAndNextLabelIds: requestedLabelIds,
            isPageChanged: nextPage ? true : false,
          },
        } as ChangeLabelByIdFromSuite,
        '*',
      );

      setLabelId(nextLabelId);
    } else {
      iframeRefCurrent.contentWindow.postMessage(
        {
          sender: 'suite',
          receiver: 'annotation-app',
          name: 'can-not-change-label',
        } as CanNotChangeLabel,
        '*',
      );
    }
  };

  const changeLabelLoki = async (dir: 'prev' | 'next') => {
    const iframeRefCurrent: Record<string, any> = iframeRef.current || {};
    const curLabelIndex = labels.findIndex(item => item.id === labelId);
    const curPage = routeInfo.params.page ? toInteger(routeInfo.params.page) : 1;
    const rowsPerPage = routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10;

    let nextLabels = labels;
    let nextLabelId = '';
    let nextLabelIndex = -1;
    let nextPage;
    const requestedLabelIds = [];

    if (dir === 'prev') {
      nextLabelIndex = curLabelIndex - 1;

      if (nextLabelIndex < 0) {
        // first label

        if (curPage === 1) {
          // first page
          enqueueSnackbar(FIRST_LABEL({ t }), { variant: 'warning' });
        } else {
          // move page and get labels
          nextPage = curPage - 1;
        }
      } else {
        //  label change

        // eslint-disable-next-line
        nextLabelId = nextLabels[nextLabelIndex].id;

        if (nextLabelIndex === 0 && curPage !== 1) {
          nextLabels = await loadLabelsForPrevCursor();

          if (nextLabels.length !== 0) {
            requestedLabelIds.push(nextLabels[nextLabels.length - 1].id);
          }
        }
      }
    } else if (dir === 'next') {
      nextLabelIndex = curLabelIndex + 1;

      if (nextLabelIndex >= nextLabels.length) {
        // last label
        const totalPage = Math.ceil(labelsInfo.totalCount / rowsPerPage);

        if (curPage === totalPage) {
          // last page
          enqueueSnackbar(LAST_LABEL({ t }), { variant: 'warning' });
        } else {
          // move page and get labels
          nextPage = curPage + 1;
        }
      } else {
        //  label change

        // eslint-disable-next-line
        nextLabelId = nextLabels[nextLabelIndex].id;

        const totalPage = Math.ceil(labelsInfo.totalCount / rowsPerPage);
        if (nextLabelIndex === nextLabels.length - 1 && curPage !== totalPage) {
          await loadLabelsForNextCursor();

          if (nextLabels.length !== 0) {
            requestedLabelIds.push(nextLabels[0].id);
          }
        }
      }
    }

    if (nextPage) {
      // change url
      const cursorKey = dir === 'prev' ? 'searchBefore' : 'searchAfter';
      const cursorTuples = cursorInfo[dir as 'prev' | 'next']?.map(value => value) ?? [];

      const searchParams = new URLSearchParams([
        ...urlSearchParams.filter(
          ([key]) => !['searchAfter', 'searchBefore', 'page', 'searchLast'].includes(key),
        ),
        ...cursorTuples.map(v => [cursorKey, `${v}`] as [string, string]),
        ['page', `${nextPage}`],
      ]);
      const filterApiParams = ParamUtils.getApiParamsForFilter({
        tagIds: projectInfo.tagIds,
        workApp: projectInfo.project.workapp,
        filterParams: [...searchParams],
      });
      const sortParam = routeInfo?.params?.ordering
        ? { ordering: routeInfo?.params?.ordering }
        : {};
      routeInfo.history.push(`?${searchParams.toString()}`);

      // get next label
      const callArgs = {
        t,
        params: { ...filterApiParams, ...sortParam },
        projectId: projectInfo.project.id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
        origin: 'contexts/DetailViewContext.tsx',
      };
      const { results } = await LabelsService.getLabelsV2(callArgs);

      nextLabels = results;
      const nextLabelCount = nextLabels.length === rowsPerPage ? rowsPerPage : nextLabels.length;
      nextLabelIndex = dir === 'prev' ? nextLabelCount - 1 : 0;
      nextLabelId = nextLabels[nextLabelIndex]?.id;
    }

    if (nextLabelId) {
      const requestedLabelId = nextLabelId;
      requestedLabelIds.push(
        ...[nextLabelIndex - 1, nextLabelIndex + 1]
          .filter(index => index >= 0 && index < nextLabels.length)
          .map(index => nextLabels[index].id),
      );

      iframeRefCurrent.contentWindow.postMessage(
        {
          sender: 'suite',
          receiver: 'annotation-app',
          name: 'change-label-by-id',
          payload: {
            labelId: requestedLabelId,
            prevAndNextLabelIds: requestedLabelIds,
            isPageChanged: nextPage ? true : false,
          },
        } as ChangeLabelByIdFromSuite,
        '*',
      );

      setLabelId(nextLabelId);
    } else {
      iframeRefCurrent.contentWindow.postMessage(
        {
          sender: 'suite',
          receiver: 'annotation-app',
          name: 'can-not-change-label',
        } as CanNotChangeLabel,
        '*',
      );
    }
  };

  useEffect(() => {
    if (dir === 'none') return;

    (async () => {
      if (!enabledLoki) {
        await changeLabelLegacy(dir);
      } else {
        await changeLabelLoki(dir);
      }
      setDir('none');
    })();

    // eslint-disable-next-line
  }, [dir]);

  return (
    <DetailViewContext.Provider
      value={{
        iframeRef,
        isConnected,
        setIsConnected,
        isOpen,
        setIsOpen,
        labelId,
        setLabelId,
        changeLabel: enabledLoki ? changeLabelLoki : changeLabelLegacy,
        hasOpenOnce,
        setHasOpenOnce,
        getPrevAndNextLabelIds,
      }}
    >
      {children}
    </DetailViewContext.Provider>
  );
};

export const useDetailViewInfo = (): DetailViewContextProps => {
  return React.useContext(DetailViewContext);
};
