import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box, Tab, Tabs } from '@superb-ai/norwegian-forest';
import { isEmpty, orderBy } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  COPY_SUCCESS,
  NOTIFICATION_USER_STATS_ANALYTICS,
  USER_STATS_NOT_FOUND,
  USER_STATS_REQUEST_EXISTS,
  USER_STATS_UNKNOWN_ERROR,
  USER_STATS_ZERO_LABELS,
} from '../../../../consts/SnackbarMessage';
import UserStatsStatus from '../../../../consts/UserStatsStatus';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUserReportContext } from '../../../../contexts/UserReportContext';
import { useContainerSize } from '../../../../hooks/ContainerSizeHook';
import UserStatsService from '../../../../services/UserStatsService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import WorkappUnion from '../../../../union/WorkappUnion';
import { formatDuration } from '../../../../utils/date';
import FileUtils from '../../../../utils/FileUtils';
import FilterUtils from '../../../../utils/FilterUtils';
import LabelInterfaceUtils from '../../../../utils/LabelInterfaceUtils';
import UserUtils, { UserProfileObject } from '../../../../utils/UserUtils';
import { getError } from '../../error/error';
import UserLabelCountsChart from '../chartContainers/UserLabelCountsChart';
import { EMPTY_MESSAGE } from '../config/constants';
import { CHART } from '../config/plotConfig';
import { AssigneeLabelStatusDto, FileFormat } from '../dataTypes/analyticsDataTypes';
import { getAnalyticsUrl } from '../tools/url';
import { getReportChartData } from './data-transformer';
import {
  excludeColumnsFromDownload,
  getReportInfo,
  reportName,
  sheetName,
} from './labelerTable/excel';
import {
  addMemberNameAndRole,
  ClassesHeaderMapper,
  getLabelerTableTopHeader,
  isReportState,
  isWorkappSupported,
  transformToAOA,
  transformToAOANoHeader,
  USER_REPORT_HIDDEN_COLUMNS,
} from './labelerTable/helper';
import LabelerContainer from './labelerTable/LabelerContainer';
import { trimLabelerTableData } from './labelerTable/labelerData';
import {
  calculateAvgObjectCountPerLabel,
  calculateAvgTimePerFrameByLabeler,
  calculateAvgTimePerLabelByLabeler,
  calculateLabelingProgress,
  calculateLabelsRequestedForReview,
  calculateReviewProgress,
} from './labelerTable/statistics';
import ReportStatisticsCard from './ReportStatisticsCard';
import ReportVersionDropdown, { Version } from './ReportVersionDropdown';
import ReviewerContainer from './ReviewerContainer';
import { addLabelerProgress, addReviewProgress } from './reviewerTable/data';
import {
  getReportCreatedAt,
  getReportVersion,
  getReviewAssignedLabelsV2,
  getReviewedLabelsV1,
  getReviewerTableTopHeader,
} from './reviewerTable/helper';
import { BottomHeader, LabelerDatum, ReviewerDatum } from './types';
import UserStatsHeaderButtons from './UserStatsHeaderButtons';

const UserReportLayout: FC = () => {
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const history = useHistory();
  const { params } = useRouteMatch<{ reportId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const {
    project: { labelCount, labelInterface, workapp },
  } = projectInfo;

  const hasCategory =
    LabelInterfaceUtils.hasCategory(labelInterface) ||
    LabelInterfaceUtils.hasCategoryLegacy(labelInterface) ||
    false;

  const { accountName } = authInfo;
  const userProfiles = UserUtils.createUserObjects({
    owners: projectInfo.owners,
    admins: projectInfo.admins,
    workers: projectInfo.workers,
    collaborators: projectInfo.collaborators,
    reviewers: projectInfo.reviewers,
  }) as UserProfileObject;

  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);
  const { t } = useTranslation();
  const { filter, setFilter } = useUserReportContext();

  const isSupportedWorkapp = isWorkappSupported(workapp);
  const [chartData, setChartData] = useState<AssigneeLabelStatusDto[]>([]);

  /** Labeler and Reviewer Table Rows */
  const [rows, setRows] = useState<LabelerDatum[]>([]);
  const [reviewerData, setReviewerData] = useState<ReviewerDatum[]>([]);
  const [syncState, setSyncState] = useState(UserStatsStatus.INIT_REQUESTED);
  const [syncTime, setSyncTime] = useState<Date | null>(null);
  const [requestEmail, setRequestEmail] = useState<string>('');
  const [objectClassesDisplayNames, setObjectClassesDisplayNames] = useState<
    ClassesHeaderMapper | undefined
  >(undefined);
  const [apiVersion, setReportVersion] = useState<1 | 2>(2);

  const [versionId, setVersionId] = useState('');
  const [versions, setVersions] = useState<Version[]>([]);
  const [shouldReload, setShouldReload] = useState(false);
  const [tabValue, setTabValue] = useState<string>('labeler');
  const handleChangeTab = (value: string) => {
    setTabValue(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', value);
    history.push({ pathname: location.pathname, search: searchParams.toString() });
  };

  const getVersion = (versionId: string) => {
    if (versionId) {
      return UserStatsService.getVersion({
        projectId: routeInfo.urlMatchInfo.projectId,
        reportId: versionId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
    }
    return UserStatsService.getLatest({
      projectId: routeInfo.urlMatchInfo.projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  };

  const getAsyncData = useCallback(async () => {
    if (!routeInfo?.urlMatchInfo?.projectId) {
      return;
    }
    setRows([]);
    setSyncState(UserStatsStatus.SYNC_REQUESTED);
    setChartData([]);
    try {
      const resp = await getVersion(versionId);
      setFilter(resp.result?.filter ?? '');
      if (resp?.result?.id && resp.result.id !== versionId) {
        setVersionId(resp.result.id);
      }
      if (isReportState(resp, UserStatsStatus.FAILED)) {
        setSyncState(UserStatsStatus.FAILED);
      }
      // Server is preparing User Stats data
      if (isReportState(resp, UserStatsStatus.IN_PROGRESS)) {
        return;
      }
      // Completed User Stats request
      if (resp?.result?.state === UserStatsStatus.FINISHED) {
        setRows(trimLabelerTableData(resp, 2, workapp, hasCategory));
        setReviewerData(resp.result.info?.reviewerData);
        setChartData(getReportChartData(resp));
        setSyncState(UserStatsStatus.SYNCED);
        setReportVersion(getReportVersion(getReportCreatedAt(resp)));
        if (resp.result.info?.date) {
          setSyncTime(resp.result.info.date);
        }
        setRequestEmail(resp.result.info?.requestUserEmail);
        if (resp.result.info?.objectClassDisplayNames) {
          setObjectClassesDisplayNames(resp.result.info?.objectClassDisplayNames);
        }
      }
      // Initial load - there was no previous user statistics data or request
      if (syncState === UserStatsStatus.INIT_REQUESTED && isEmpty(resp?.result?.info?.data)) {
        setSyncState(UserStatsStatus.INIT);
      }
    } catch {
      enqueueSnackbar(USER_STATS_NOT_FOUND({ t }), { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeInfo, versionId]);

  const loadList = useCallback(async () => {
    const resp = await UserStatsService.getList({
      projectId: routeInfo.urlMatchInfo.projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo, // has project_id and tenant_id
    });
    const versions = resp.results;
    setVersions(versions);
  }, [routeInfo, authInfo]);

  useEffect(() => {
    if (
      syncState === UserStatsStatus.FINISHED ||
      syncState === UserStatsStatus.SYNCED ||
      syncState === UserStatsStatus.INIT ||
      syncState === UserStatsStatus.FAILED
    ) {
      return;
    }
    const timeout = setInterval(() => {
      getAsyncData();
      loadList();
    }, 5000);
    return () => clearInterval(timeout);
  }, [syncState, authInfo, routeInfo, getAsyncData, loadList]);

  const setVersionAndUpdateUrl = (newVersionId: string) => {
    setVersionId(newVersionId);
    routeInfo.history.push(getAnalyticsUrl(routeInfo, newVersionId));
  };

  const requestUserStats = async (filter: string, labelCount: number) => {
    const filterInfo = isEmpty(filter) ? { labelCount } : { labelCount, filterUrl: filter };
    try {
      const response = await UserStatsService.requestUserStats({
        projectId: routeInfo.urlMatchInfo.projectId,
        filterInfo,
        usageType: 'analytics',
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo, // has project_id and tenant_id
      });

      if (response?.data?.id) {
        setVersionAndUpdateUrl(response.data.id);
        loadList();
      }

      enqueueSnackbar(
        NOTIFICATION_USER_STATS_ANALYTICS({
          t,
          labelCount: response.labelCount.toString(),
        }),
        { variant: 'info' },
      );
      setSyncState(UserStatsStatus.SYNC_REQUESTED);
    } catch (error) {
      if (getError(error) === 'Duplicated') {
        enqueueSnackbar(USER_STATS_REQUEST_EXISTS({ t }), { variant: 'info' });
      } else if (getError(error) === 'Not Found') {
        enqueueSnackbar(USER_STATS_ZERO_LABELS({ t }), { variant: 'error' });
      } else {
        enqueueSnackbar(USER_STATS_UNKNOWN_ERROR({ t }), { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (shouldReload) {
      setShouldReload(false);
      getAsyncData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload]);

  const handleVersionChange = (newVersionId: string) => {
    if (newVersionId !== versionId) {
      setVersionAndUpdateUrl(newVersionId);
      setShouldReload(true);
    }
  };

  const handleReportDownload = (
    outputType: FileFormat,
    rows: Record<string, string | number>[],
    headerDisplayName: Record<string, string>,
    name: string,
    labelCount: number,
    reportType: 'reviewer' | 'labeler',
    bottomHeader?: BottomHeader[],
  ) => {
    if (!bottomHeader) return;
    const generateTime = syncTime ? new Date(syncTime).toLocaleString() : '';
    const filename = reportName(reportType, name, generateTime);
    const EXCLUDE_COLUMNS = ['progress', ...USER_REPORT_HIDDEN_COLUMNS];
    const newRows = orderBy(
      excludeColumnsFromDownload(rows, EXCLUDE_COLUMNS),
      ['labelCounts'],
      ['desc'],
    );

    const columnOrder = bottomHeader
      .filter(col => !EXCLUDE_COLUMNS.includes(col?.id))
      .map(cell => cell.id);
    const formattedBottomHeader = columnOrder.map(colId => headerDisplayName[colId]);
    const capitalizedTopHeader =
      reportType === 'labeler'
        ? getLabelerTableTopHeader(bottomHeader).map(
            text => text.groupKey.charAt(0).toUpperCase() + text.groupKey.slice(1),
          )
        : getReviewerTableTopHeader(bottomHeader).map(text => t(text.displayName));
    const TableTopHeaderSpace =
      reportType === 'labeler'
        ? getLabelerTableTopHeader(bottomHeader).map(table => {
            const count =
              table.groupKey === 'labeler'
                ? table.count
                : table.groupKey === 'summary'
                ? table.count - 2
                : table.count - 1;
            return Array(count).fill('');
          })
        : getReviewerTableTopHeader(bottomHeader).map(table => {
            const count = table.groupKey === 'info' ? table.count : table.count - 1;
            return Array(count).fill('');
          });
    TableTopHeaderSpace.forEach((space, i) => space.unshift(capitalizedTopHeader[i]));
    const flattenedTableTopHeader = TableTopHeaderSpace.flat(1);

    const transformed = transformToAOANoHeader(newRows, columnOrder);
    if (outputType === 'csv') {
      FileUtils.exportToCsv(
        transformed,
        formattedBottomHeader,
        filename,
        flattenedTableTopHeader.join(',') + '\n',
      );
    } else if (outputType === 'copy') {
      transformed.unshift(flattenedTableTopHeader, formattedBottomHeader);
      const parse = transformed.map(lines => lines.join('\t')).join('\n');
      FileUtils.copyToClipboard({ value: parse });
      enqueueSnackbar(COPY_SUCCESS({ t, label: '' }), { variant: 'success' });
    } else if (outputType === 'xlsx') {
      const decodedFilter = FilterUtils.formatAppliedFilterConcisely(filter, projectInfo.tagIds);
      const reportInfo = getReportInfo({
        generateTime,
        email: requestEmail,
        tenant: accountName as string,
        projectName: name,
        labelCount,
        filter: decodedFilter ?? 'n/a',
      });
      if (typeof bottomHeader === 'undefined') return;
      const transformed = transformToAOA(newRows, columnOrder, formattedBottomHeader);
      transformed.unshift(flattenedTableTopHeader);
      FileUtils.exportToExcel(
        transformed,
        'array',
        sheetName(reportType, labelCount, apiVersion),
        filename,
        reportInfo,
      );
    } else {
      throw new Error('Download format is not supported.');
    }
  };

  useEffect(() => {
    if (params.reportId === versionId) return;
    setVersionId(params.reportId);
    loadList();
    setShouldReload(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reportId]);

  const isUpdateButtonDisabled =
    labelCount === 0 || UserStatsStatus.SYNC_REQUESTED === syncState || !isSupportedWorkapp;

  const getEmptyMessage = () => {
    if (!isSupportedWorkapp) return t(EMPTY_MESSAGE.UNSUPPORTED_WORKAPP);
    if (labelCount === 0) return t(EMPTY_MESSAGE.UPLOAD_DATA);
    if (syncState === UserStatsStatus.FAILED) return `🟡 ${t(EMPTY_MESSAGE.FAILED_REPORT)}`;
    return t(EMPTY_MESSAGE.REQUEST_UPDATE);
  };
  const getEmptyMessageReviewer = () => {
    if (!isSupportedWorkapp) return t(EMPTY_MESSAGE.UNSUPPORTED_WORKAPP);
    if (labelCount === 0) return t(EMPTY_MESSAGE.UPLOAD_DATA);
    if (syncState === UserStatsStatus.FAILED) return `🟡 ${EMPTY_MESSAGE.FAILED_REPORT}`;
    return t(EMPTY_MESSAGE.REQUEST_REVIEW);
  };

  return (
    <div ref={chartContainerRef}>
      <Box mb={2} mt={-0.625} display="flex" alignItems="center" height={32}>
        <Box display="flex" width="210px">
          <ReportVersionDropdown
            value={versionId}
            setValue={handleVersionChange}
            versions={versions}
          />
        </Box>
        <UserStatsHeaderButtons
          isDisabled={isUpdateButtonDisabled}
          filter={filter}
          onGenerate={requestUserStats}
          totalLabelCount={labelCount}
        />
      </Box>
      <Box>
        {!UserRoleUnion.isWorker(authInfo.projectRole) && (
          <Box>
            <>
              <Tabs
                variant="underlined"
                fullWidth={false}
                onChange={handleChangeTab}
                currentTab={tabValue}
              >
                <Tab
                  label={t('analytics.userReports.labeler')}
                  value="labeler"
                  align="left"
                  minWidth={200}
                  buttonProps={{ size: 'l' }}
                />
                <Tab
                  label={t('analytics.userReports.reviewer')}
                  value="reviewer"
                  align="left"
                  minWidth={200}
                  buttonProps={{ size: 'l' }}
                />
              </Tabs>
              {tabValue === 'labeler' && (
                <>
                  <Box mb={4} display="flex" style={{ flexDirection: 'row' }}>
                    <ReportStatisticsCard
                      title={t('analytics.userReports.statistics.labelingProgress')}
                      statistic={`${calculateLabelingProgress(chartData)} %`}
                    />
                    <ReportStatisticsCard
                      title={t('analytics.userReports.statistics.avgObjectCountPerLabel')}
                      statistic={calculateAvgObjectCountPerLabel(rows)}
                    />
                    <ReportStatisticsCard
                      title={t('analytics.userReports.statistics.avgTimePerLabelByLabeler')}
                      statistic={formatDuration(calculateAvgTimePerLabelByLabeler(rows))}
                    />
                    {WorkappUnion.isVideoApp(workapp) && (
                      <ReportStatisticsCard
                        title={t('analytics.userReports.statistics.avgTimePerFrameByLabeler')}
                        statistic={formatDuration(calculateAvgTimePerFrameByLabeler(rows))}
                      />
                    )}
                  </Box>
                  <Box mb={4}>
                    <LabelerContainer
                      rows={addLabelerProgress(chartData, addMemberNameAndRole(rows, userProfiles))}
                      syncState={syncState}
                      handleReportDownload={handleReportDownload}
                      emptyMessage={getEmptyMessage()}
                      width={width}
                      userProfiles={userProfiles}
                      objectClassesDisplayNames={objectClassesDisplayNames}
                    />
                  </Box>
                  <Box mb={4}>
                    <UserLabelCountsChart
                      chartName="workerStats"
                      chartInfo={CHART.workerStats}
                      sourceData={chartData && chartData.length > 0 ? chartData : null}
                      emptyMessage={getEmptyMessage()}
                      labelCount={labelCount}
                      isLoading={[
                        UserStatsStatus.SYNC_REQUESTED,
                        UserStatsStatus.INIT_REQUESTED,
                      ].includes(syncState)}
                      width={width}
                    />
                  </Box>
                </>
              )}
            </>
            {tabValue === 'reviewer' && (
              <>
                <Box mb={4} display="flex" style={{ flexDirection: 'row' }}>
                  <ReportStatisticsCard
                    title={t('analytics.userReports.statistics.reviewProgress')}
                    statistic={`${calculateReviewProgress(reviewerData)} %`}
                  />
                  <ReportStatisticsCard
                    title={t('analytics.userReports.statistics.labelsRequestedForReview')}
                    statistic={`${calculateLabelsRequestedForReview(reviewerData, labelCount)} %`}
                  />
                </Box>
                <Box mb={4}>
                  <ReviewerContainer
                    rows={addReviewProgress(addMemberNameAndRole(reviewerData, userProfiles))}
                    reviewedLabelCount={
                      getReviewAssignedLabelsV2(reviewerData) || getReviewedLabelsV1(reviewerData)
                    }
                    syncState={syncState}
                    apiVersion={apiVersion}
                    handleReportDownload={handleReportDownload}
                    emptyMessage={getEmptyMessageReviewer()}
                    width={width}
                    userProfiles={userProfiles}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default UserReportLayout;
