import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

interface Props {
  parentClasses: Record<string, string>;
}

const NotFound: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { parentClasses } = props;

  return (
    <>
      <MUI.Box className={parentClasses.imageBox}>
        <img alt="symbol" src="https://asset.superb-ai.com/suite/clip-page-not-found.png" />
      </MUI.Box>
      <MUI.Box className={parentClasses.contentsBox}>
        <MUI.Typography className={parentClasses.headerText}>
          {t('pageNotFound.title')}
        </MUI.Typography>
        <MUI.Typography className={parentClasses.contentsText}>
          {t('pageNotFound.description')}
        </MUI.Typography>
      </MUI.Box>
    </>
  );
};

export default NotFound;
