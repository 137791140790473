/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Circle } from 'react-konva';

import Color from 'color';
import { clamp } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import ImageContext from '../../../../../contexts/ImageContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';

let isDrag = false;
let isMouseDown = false;
let isSelectedBadge = false;

const Badge = ({ isCreate, issue, updatePanelPos }) => {
  const { t } = useTranslation();
  const badgeRef = useRef(null);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const imageInfo = useContext(ImageContext.Context);
  const { label } = labelDetailViewInfo;

  const { threadNumber } = issue;
  const { rate } = imageInfo;
  const [x] = useState(issue.info.target.point.x);
  const [y] = useState(issue.info.target.point.y);
  const [color, setColor] = useState(issue.info.color);
  const [isHover, setIsHover] = useState(false);
  const {
    history: {
      location: { pathname, search },
    },
  } = routeInfo;
  const {
    setIssues,
    nextIssue,
    issuePanelState,
    setIssuePanelState,
    openBadgeInfo,
    setOpenBadgeInfo,
    emitSelectedThreadId,
    setEmitSelectedThreadId,
  } = labelIssuesInfo;

  useEffect(
    () => {
      if (!emitSelectedThreadId) return;

      if (emitSelectedThreadId === issue.id) {
        setOpenBadgeInfo({
          issue,
          ref: badgeRef,
        });

        updatePanelPos(
          imageInfo.x + badgeRef.current.getX() * rate,
          imageInfo.y + badgeRef.current.getY() * rate,
          issuePanelState,
        );

        setEmitSelectedThreadId(null);
      }
    },
    // eslint-disable-next-line
    [emitSelectedThreadId],
  );

  useEffect(() => {
    if (isCreate) {
      setOpenBadgeInfo({
        ...openBadgeInfo,
        ref: badgeRef,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      setColor(issue.info.color);
    },
    // eslint-disable-next-line
    [issue.info.color],
  );

  const handleDragStart = () => {
    // badge를 drag 하기 시작
    isDrag = true;

    setOpenBadgeInfo({ ...openBadgeInfo, badgeRef });

    switch (issuePanelState) {
      case null:
        break;
      case 'create':
        if (isCreate) {
          isSelectedBadge = true;
          updatePanelPos(
            imageInfo.x + badgeRef.current.getX() * rate,
            imageInfo.y + badgeRef.current.getY() * rate,
            issuePanelState,
          );
        }
        break;
      case 'thread':
      case 'option':
        if (openBadgeInfo?.issue?.threadNumber === issue?.threadNumber) {
          isSelectedBadge = true;
          updatePanelPos(
            imageInfo.x + badgeRef.current.getX() * rate,
            imageInfo.y + badgeRef.current.getY() * rate,
            issuePanelState,
          );
        }
        break;
      default:
        break;
    }

    // 열려있는 badge가 현재 badge일 경우
  };

  const handleDragMove = () => {
    if (!isSelectedBadge) return;
    updatePanelPos(
      imageInfo.x + badgeRef.current.getX() * rate,
      imageInfo.y + badgeRef.current.getY() * rate,
      issuePanelState,
    );
  };

  const handleDragEnd = async () => {
    isDrag = false;
    isSelectedBadge = false;

    let nextIssues = [];
    switch (issuePanelState) {
      case 'create':
        if (badgeRef.current) {
          nextIssue.info.target.point.x = badgeRef.current.getX();
          nextIssue.info.target.point.y = badgeRef.current.getY();
        }
        break;
      case null:
      case 'thread':
      case 'option':
        await IssuesService.updateIssue({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          issueId: issue.id,
          info: {
            info: {
              ...issue.info,
              target: {
                point: {
                  x: badgeRef.current.getX(),
                  y: badgeRef.current.getY(),
                },
              },
            },
          },
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        nextIssues = await IssuesService.getIssues({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        setIssues(nextIssues);
        break;
      default:
        break;
    }
  };

  const handleMouseDown = () => {
    isMouseDown = true;
  };

  const handleMouseUp = e => {
    if (isDrag || !isMouseDown) return;

    const nextPanelState = e.evt.button === 0 ? 'thread' : 'option';
    // const isOpen = issuePanelState !== null;
    const isSamePanelState = issuePanelState === nextPanelState;
    const isSameIssue = openBadgeInfo.issue?.threadNumber === issue.threadNumber;

    const openThread = () => {
      // 설계를 잘못해서 싱크가 잘 안맞아서 추가한 코드
      if (issuePanelState === 'create') {
        setIssuePanelState(null);
        setOpenBadgeInfo({
          issue: null,
          ref: null,
        });
        return;
      }
      const hash = `#label_id=${label.id}&thread_id=${issue.id}&thread_state=thread`;
      routeInfo.history.push(`${pathname}${search}${hash}`);
    };

    const openOption = () => {
      // 설계를 잘못해서 싱크가 잘 안맞아서 추가한 코드
      if (issuePanelState === 'create') {
        setIssuePanelState(null);
        setOpenBadgeInfo({
          issue: null,
          ref: null,
        });
        return;
      }
      const hash = `#label_id=${label.id}&thread_id=${issue.id}&thread_state=option`;
      routeInfo.history.push(`${pathname}${search}${hash}`);
    };
    const closeThread = () => {
      const hash = `#label_id=${label.id}`;
      routeInfo.history.push(`${pathname}${search}${hash}`);
    };

    if (isSameIssue && isSamePanelState) {
      closeThread();
    } else if (nextPanelState === 'thread') {
      openThread();
    } else if (nextPanelState === 'option') {
      openOption();
    }
  };

  const handleContextMenu = e => {
    e.evt.preventDefault();
  };

  const handleDragBoundFunc = pos => {
    const nextX = clamp(pos.x, imageInfo.x, imageInfo.x + imageInfo.width * rate);
    const nextY = clamp(pos.y, imageInfo.y, imageInfo.y + imageInfo.height * rate);
    return {
      x: nextX,
      y: nextY,
    };
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  return (
    <Circle
      ref={badgeRef}
      id="badge"
      x={x}
      y={y}
      width={10 / rate}
      height={10 / rate}
      fill={color}
      color={color}
      // shadowColor={'#c0c0c0'}
      // shadowBlur={1}
      // shadowOffsetX={1}
      // shadowOffsetY={1}
      draggable
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleContextMenu}
      dragBoundFunc={handleDragBoundFunc}
      sceneFunc={context => {
        context.beginPath();
        context.shadowColor = '#363636';
        context.shadowBlur = 7;
        context.shadowOffsetX = 2;
        context.shadowOffsetY = 3;
        context.arc(0, 0, 10 / rate, 0, 2 * Math.PI);
        context.fill();
        context.closePath();
        context.beginPath();

        context.shadowColor = 'none';
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;

        context.arc(0, 0, 10 / rate, 0, 2 * Math.PI);
        context.lineWidth = 2 / rate;
        context.strokeStyle = '#fff';
        context.fillStyle = isHover ? Color(color).darken(0.2).hex() : color;
        context.stroke();
        context.fill();
        context.closePath();

        context.beginPath();
        context.fillStyle = '#fff';
        context.font = `${14 / rate}px Inter`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        context.fillText(isCreate ? '' : threadNumber, 0, 0);
        context.closePath();
      }}
      hitFunc={(context, circle) => {
        context.beginPath();
        context.arc(0, 0, 10 / rate, 0, 2 * Math.PI);
        context.closePath();
        context.fillStrokeShape(circle);
      }}
    />
  );
};

export default Badge;
