import { useQuery } from '@tanstack/react-query';

import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import ProjectService from '../services/ProjectService';

export const projectQueryKey = 'project';
export const useProjectQuery = ({
  projectId,
  disabled,
}: {
  projectId?: string;
  disabled?: boolean;
}) => {
  const defaultParams = useApiDefaultParams();
  const fetcher = async () => {
    if (!projectId) return;
    return await ProjectService.getProject({
      ...defaultParams,
      projectId: projectId,
    });
  };

  return useQuery({
    queryKey: [projectQueryKey, defaultParams.urlInfo.accountName, projectId],
    queryFn: fetcher,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 600000,
    staleTime: 600000,
    enabled: !!projectId && !disabled,
  });
};

export const useProjectsQuery = ({ projectIds }: { projectIds?: string[] }) => {
  const defaultParams = useApiDefaultParams();
  const fetcher = async () => {
    if (!projectIds) return;

    const projectsResult = Promise.all(
      projectIds.map(projectId =>
        ProjectService.getProject({
          ...defaultParams,
          projectId: projectId,
        }),
      ),
    );

    return projectsResult;
  };

  return useQuery({
    queryKey: [projectQueryKey, defaultParams.urlInfo.accountName, projectIds],
    queryFn: fetcher,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 600000,
    staleTime: 600000,
    enabled: !!projectIds,
  });
};
