import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LabelsService from '../services/LabelsService';
import UserRoleUnion from '../union/UserRoleUnion';
import { getLabelTypeColor } from '../utils/ColorUtils';
import LabelDetailViewUtils from '../utils/LabelDetailViewUtils';
import { useAuthInfo } from './AuthContext';
import { useLabelDetailViewInfo } from './LabelDetailViewContext';
import { useLabelInformationInfo } from './LabelInformationContext';
import { useProjectInfo } from './ProjectContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

// prettier-ignore
type LabelCompareContextProps =
  StateGetterSetter<['activeCompareOptionArr', 'setActiveCompareOptionArr'], any[]> &
  StateGetterSetter<['relatedLabels', 'setRelatedLabels'], any | null>;

export const LabelCompareContext = React.createContext({} as LabelCompareContextProps);

export const LabelCompareProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelInformationInfo = useLabelInformationInfo();

  const [activeCompareOptionArr, setActiveCompareOptionArr] = useState<any[]>([]);
  const [relatedLabels, setRelatedLabels] = useState<any[] | null>(null);

  useEffect(() => {
    if (!labelDetailViewInfo.label) return () => {};

    setActiveCompareOptionArr([{ ...labelInformationInfo.labelAnnotationInfos.current }]);

    if (
      !UserRoleUnion.isWorker(authInfo.projectRole) &&
      projectInfo.project?.settings.allowAdvancedQa
    ) {
      (async () => {
        const res = await LabelsService.getRelatedLabelsList({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: labelDetailViewInfo.label.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });

        const relatedLabels = (res.results || []).reduce((acc: any, item: any) => {
          const extractedLabel = LabelDetailViewUtils.extractRelatedLabelInfos(
            item,
            projectInfo.project.workapp,
          );
          return {
            ...acc,
            [extractedLabel.id]: {
              ...extractedLabel,
              color: getLabelTypeColor(extractedLabel.type),
            },
          };
        }, {});

        setRelatedLabels(relatedLabels);
      })();
    }

    return () => {
      setRelatedLabels(null);
      setActiveCompareOptionArr([]);
    };
    // eslint-disable-next-line
  }, [labelInformationInfo.labelAnnotationInfos]);

  return (
    <LabelCompareContext.Provider
      value={{
        relatedLabels,
        setRelatedLabels,
        activeCompareOptionArr,
        setActiveCompareOptionArr,
      }}
    >
      {children}
    </LabelCompareContext.Provider>
  );
};

export const useLabelCompareInfo = (): LabelCompareContextProps => {
  return React.useContext(LabelCompareContext);
};
