import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import AnalyticsExportsTab from '../../../../../components/pages/analytics/AnalyticsExportsTab';
import { NOT_ENABLED_EXPORT_COMPARE } from '../../../../../consts/FeatureMessage';
import { canManageProject } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';
import WorkappUnion from '../../../../../union/WorkappUnion';
import ProjectUtils from '../../../../../utils/ProjectUtils';

export const ProjectAnalyticsExportsAnalyticsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectExportCompare,
  path: 'export-compare/:reportId?',
  title: ({ t }) => t('analytics.exportsAnalytics.title'),
  component: AnalyticsExportsTab,
  isVisible({ authInfo, featureFlags }) {
    const featureFlagValid = !!featureFlags?.exportsAnalytics?.enabled;
    const authValid = canManageProject(authInfo) || false;
    return featureFlagValid && authValid;
  },
  isEnabled({ authInfo, project }) {
    const isConsensusProject = project?.settings?.allowAdvancedQa || false;
    const isImageApp = WorkappUnion.isImageApp(project?.workapp);
    return canManageProject(authInfo) && !isConsensusProject && isImageApp;
  },
  disabledMessage({ t, project }) {
    const isConsensusProject: boolean = project?.settings?.allowAdvancedQa || false;
    if (!WorkappUnion.isImageApp(project?.workapp)) {
      const dataType = ProjectUtils.getProjectDataType(project?.workapp as string);
      return t('analytics.exportsAnalytics.projectTypeNotSupported', {
        dataType: t(`projects.projectTypes.${dataType}`),
      });
    } else if (isConsensusProject) {
      return NOT_ENABLED_EXPORT_COMPARE(t, t('projectSettings.configuration.advancedQA'));
    }
  },
});
