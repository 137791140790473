import React, { ReactElement, useEffect } from 'react';

import { cloneDeep } from 'lodash';

import { PROPERTY_TYPE } from '../../../../consts/NewProjectConst';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import Layout from './Layout';

// eslint-disable-next-line
const Index = (props: any): ReactElement => {
  const newProjectInfo = useNewProjectInfo();

  useEffect(() => {
    if (
      newProjectInfo.selectedDataType === 'image' &&
      newProjectInfo.imageCategories.length !== 0
    ) {
      const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
      nextImageCategories[0].type = PROPERTY_TYPE.MULTIPLE_SELECTION.value;

      newProjectInfo.setImageCategories(nextImageCategories.slice(0, 1));
    }

    if (newProjectInfo.imageCategories.length !== 0) {
      newProjectInfo.setSelectedImageCategoryIndex(0);
    } else {
      newProjectInfo.setSelectedImageCategoryIndex(-1);
    }

    newProjectInfo.setSelectedCategories({});
    newProjectInfo.setSelectedCategoryGroupId('');
    // eslint-disable-next-line
  }, []);

  return <Layout {...props} />;
};

export default Index;
