import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Button, Typography, useDialogState } from '@superb-ai/ui';

import TrialFormDialog from '../../../../../components/HubspotForms/CurateHubspotFormDialog';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../../../consts/ModalMessage';
import PageWithMenuAndBanner from '../../../../../menu/PageWithMenuAndBanner';
import { getUrl } from '../../../../../routes/util';
import { usePublicDatasetContext } from '../../../contexts/PublicDatasetContextProvider';
import CreateDatasetModal from '../list/CreateDatasetModal';

export default function SampleDatasetPage({
  menu,
  children,
}: PropsWithChildren<{ menu: JSX.Element }>) {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const history = useHistory();

  const eventId = 'suite-curate-sample-dataset-contact-sales-form';
  const { openModal, closeModal } = useAlertModal();
  const state = useDialogState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { hasOwnDataset, isCurateNotEnabled } = usePublicDatasetContext();

  const datasetListPageUrl = getUrl([accountName, 'curate', 'dataset', 'list']);
  function handleButtonClick() {
    if (isCurateNotEnabled) {
      state.show();
      return;
    }
    if (hasOwnDataset) {
      history.push(datasetListPageUrl);
      return;
    }
    setIsModalOpen(true);
  }

  const bannerTextKey = isCurateNotEnabled
    ? 'useCurate'
    : hasOwnDataset
    ? 'myDataset'
    : 'createDataset';
  return (
    <PageWithMenuAndBanner
      menu={menu}
      pageBoxProps={{ p: 0, mb: 0 }}
      banner={
        <>
          <Typography variant="l-strong">
            👉 {t(`curate.datasets.sampleDataset.banner.${bannerTextKey}.message`)}
          </Typography>
          <Button
            variant="strong-fill"
            color="gray"
            style={{ marginLeft: 'auto' }}
            onClick={handleButtonClick}
          >
            {t(`curate.datasets.sampleDataset.banner.${bannerTextKey}.button`)}
          </Button>
        </>
      }
    >
      {children}
      <TrialFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
      <CreateDatasetModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onCreate={() => {
          history.push(datasetListPageUrl);
        }}
      />
    </PageWithMenuAndBanner>
  );
}
