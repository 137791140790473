type Props = {
  label?: string;
  checkboxColor: string;
  checkMarkColor: string;
  checked: boolean;
  isDisabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
};
const Checkbox = ({
  label,
  checkboxColor,
  checkMarkColor,
  checked,
  isDisabled,
  onClick,
}: Props) => {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type="checkbox"
        checked={checked}
        onClick={onClick}
        style={{
          cursor: isDisabled ? 'default' : 'pointer',
          accentColor: checkboxColor,
          marginRight: '1px',
          backgroundColor: checkboxColor,
          borderRadius: '4px',
          border: '1px solid #999',
        }}
      />
      {label}
    </label>
  );
};

export default Checkbox;
