import React from 'react';

import { startsWith } from 'lodash';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { TooltipContent } from '../charts/barChart/BarChartStacked';
import { filterLabelList } from '../config/routeConfig';
import { ExtendedScaleBand } from '../interfaces/d3Types';
import { JsonObj } from '../userStats/types';
import { TooltipTemplate } from './TooltipTemplates';

/**
 * Tooltip for workerStats chart
 */

const TOOLTIP_STYLE: React.CSSProperties = {
  textAnchor: 'start',
  width: '250px',
  fontFamily: 'Inter',
  fontSize: '12px',
  padding: '2px',
};

function adjustTooltipWidth() {
  const textBoxWidth = 230;
  return textBoxWidth;
}

function adjustTooltipHeight(numStatus: 0 | 1 | 2 | 3) {
  const heights = {
    0: 68,
    1: 68,
    2: 85,
    3: 101,
  };
  return heights[numStatus];
}

/**
 * @param {string} chartType 'bar' or 'scrollBar'
 * @param {int} numStatus
 */
function adjustTooltipYOffset(chartType: 'bar' | 'scrollBar', numStatus: number) {
  const yOffset = {
    scrollBar: {
      0: -65,
      1: -65,
      2: -65,
      3: -65,
    },
    bar: {
      0: -116,
      1: -116,
      2: -128,
      3: -140,
    },
  };
  return yOffset[chartType as 'bar' | 'scrollBar'][numStatus as 0 | 1 | 2 | 3];
}

interface Props {
  left: number;
  top: number;
  content: JsonObj | TooltipContent;
  scales: [ExtendedScaleBand, d3.ScaleLinear<number, number, never>];
  checkedStatus: string[];
  chartName: string;
  chartType: 'bar' | 'scrollBar';
  handleTooltipMouseEnter: () => void;
  handleTooltipMouseLeave: () => void;
}

export const TooltipStackedBar: React.FC<Props> = props => {
  const {
    left,
    top,
    content,
    scales,
    checkedStatus,
    chartName,
    chartType,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
  } = props;
  const routeInfo = useRouteInfo();

  const xScale = scales[0];
  const yScale = scales[1];
  const [, xValue] = content.xValue;
  const totalCount = content.total;
  // const [, suppValue] = content.supplementValue;
  // const formattedSuppValue =
  //   suppValue === undefined ||
  //   suppValue === 'No Assignee' || // renamed from "unassigned" in helper.js
  //   suppValue.indexOf('@') !== -1
  //     ? suppValue
  //     : `${suppValue}%`;

  const width = adjustTooltipWidth();
  const height = adjustTooltipHeight(3); // hard code # status
  const yOffset = adjustTooltipYOffset(chartType, 3);
  const xOffset = (width - xScale.bandwidth()) / 2;
  const yPos = yScale(totalCount) + yOffset;
  const xPos = xScale(xValue) as number;
  const xInBound = xPos - xOffset; // - xScale.bandwidth()/2;

  /**
   *
   * @param {string} assigneeName
   * @param {string} assigneeEmail
   * @param {string} statusType One of submitted, in progress, skipped (used in label list filter)
   *
   */
  const handleClickInspect = (assigneeName: string, assigneeEmail: string, statusType: string) => {
    // Makes format acceptable by Filter (ex. Moon Limb (mjlimb@superb-ai.com))
    const filterInput = {
      assigneeName: startsWith(assigneeName, 'em_') ? assigneeEmail : assigneeName,
      assigneeEmail,
      status: statusType,
    };
    filterLabelList({
      ...{ routeInfo },
      filterBy: filterInput,
    });
  };

  return (
    <g transform={`translate(${left}, ${top})`} style={TOOLTIP_STYLE}>
      {!Number.isNaN(xInBound) &&
        !Number.isNaN(yPos) &&
        !Number.isNaN(width) &&
        !Number.isNaN(height) &&
        TooltipTemplate({
          content,
          xPos: xInBound,
          yPos,
          width,
          height,
          handleClickInspect,
          chartName,
          handleTooltipMouseEnter,
          handleTooltipMouseLeave,
          checkedStatus,
        })}
    </g>
  );
};

export default TooltipStackedBar;
