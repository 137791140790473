import { RouteContextProps } from '../../../../../contexts/RouteContext';
import { filterLabelList } from '../../config/routeConfig';
import { JsonObj } from '../../userStats/types';

interface RouteFnParams {
  filter?: Record<string, string[] | []>;
  routeInfo: RouteContextProps;
  datum: JsonObj;
}

export const routeToLabelsWithObject = (params: RouteFnParams): void => {
  const { datum, routeInfo, filter } = params;
  const filterInput = {
    objectClassName: datum?.classId,
    status: 'total',
  };
  filterLabelList({ routeInfo, filterBy: { ...filterInput, ...filter } });
};

export const routeToLabelsWithCategory = (params: RouteFnParams & { groupIds: string[] }): void => {
  const { datum, routeInfo, filter = {}, groupIds } = params;
  const filterBy = {
    categoryName: datum?.id, // label list filter category on id
    categoryGroupIds: groupIds,
    status: 'total',
  };
  filterLabelList({ routeInfo, filterBy: { ...filterBy, ...filter } });
};
