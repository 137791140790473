import { CSSProperties, useEffect, useRef } from 'react';

import { Box } from '@superb-ai/ui';
import Head from 'next/head';

import { useAuthInfo } from '../../contexts/AuthContext';
import AnalyticsTracker from '../../analyticsTracker';

interface Props {
  portalId: string;
  formId: string;
  clickId: string;
  showSuccessModal: () => void;
  id?: string;
  style?: CSSProperties;
}

export default function HubspotForm({
  clickId,
  id = 'suite-hubspot-form',
  showSuccessModal,
  style,
  ...restProps
}: Props) {
  const { accountName } = useAuthInfo();
  const boxRef = useRef<HTMLDivElement>(null);
  const iframe = boxRef?.current && boxRef.current.getElementsByTagName('iframe')[0];
  const hbsptSubmitBtn = iframe?.contentWindow?.document.querySelectorAll(
    'input[type="submit"].hs-button',
  )[0];

  function handleFormSubmit() {
    AnalyticsTracker.contactFormSubmitted({
      accountId: accountName ?? '',
      referrer: clickId,
    });
    showSuccessModal();
  }

  const createForm = () => {
    // @ts-ignore
    if (window.hbspt) {
      // @ts-ignore
      window.hbspt.forms.create({
        ...restProps,
        target: `#${id}`,
        region: 'na1',
        cssClass: 'hubspot-form',
        onFormSubmit: handleFormSubmit,
      });
    } else {
      setTimeout(createForm, 10);
    }
  };

  useEffect(() => {
    createForm();
  }, []);

  useEffect(() => {
    hbsptSubmitBtn?.setAttribute('data-click-id', clickId);
    hbsptSubmitBtn?.setAttribute('click-id', clickId);
    hbsptSubmitBtn?.setAttribute('id', clickId);
  }, [hbsptSubmitBtn]);

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          defer
          onLoad={createForm}
          src="//js.hsforms.net/forms/v2.js"
        ></script>
      </Head>
      <Box ref={boxRef} id={id} style={style}></Box>
    </>
  );
}
