import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles(theme => ({
  popper: ({ navBar }: { navBar: string | number }) => ({
    zIndex: 1300,
    top: navBar,
  }),
  chevronIcon: () => ({
    marginLeft: theme.spacing(0.4),
  }),
}));

interface Props {
  chevronDownIcon?: string;
  navBar?: boolean;
}

const DropDownMenu: React.FC<Props> = ({ children, chevronDownIcon, navBar }) => {
  const classes = useStyles({
    navBar: navBar ? '10px !important' : 0,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleToggle = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget ? (event.currentTarget as HTMLElement) : null);
  };

  const getComponent = (key: string) => {
    const component = React.Children.map(children, child => {
      if (!child) return <></>;
      const childElement = child as React.ReactElement;
      if (childElement.key === key) {
        if (key === 'anchor') {
          // className includes disabled string
          if (childElement.props.className.indexOf('disabled') !== -1) {
            return child;
          }
          return React.cloneElement(childElement, {
            onClick: handleToggle,
          });
        }
        return child;
      }
      return <></>;
    });
    return component;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {chevronDownIcon ? (
        <MUI.IconButton className={classes.chevronIcon} onClick={handleToggle}>
          <Icon name="chevronDown" size="16px" customColor={chevronDownIcon} />
        </MUI.IconButton>
      ) : (
        getComponent('anchor')
      )}
      <MUI.Popover
        className={classes.popper}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MUI.Paper id="menu-list-grow">{getComponent('popper')}</MUI.Paper>
      </MUI.Popover>
    </>
  );
};

export default DropDownMenu;
