import { useEffect, useRef, useState } from 'react';

import { Box, NamedColor, NamedColorWeight } from '@superb-ai/ui';

export type ProgressChartDatum = {
  key: string;
  name: string;
  total: number;
  value: number;
  share?: number | string;
  barColor?: NamedColor | NamedColorWeight;
  textColor?: NamedColor | NamedColorWeight;
};

export default function PercentageBarChartBar({
  height,
  chartData,
  showLegend = true,
}: {
  height: number;
  chartData: ProgressChartDatum;
  showLegend?: boolean;
}) {
  const barRef = useRef<HTMLDivElement | null>(null);
  const legendTextRef = useRef<HTMLSpanElement | null>(null);
  const [alignStyle, setAlignStyle] = useState({ right: 'auto', left: '2px' });

  useEffect(() => {
    alignChild();
  }, [legendTextRef, barRef]);

  function alignChild() {
    if (!barRef.current || !legendTextRef.current) return;
    const barRect = barRef.current.getBoundingClientRect();
    const legendTextRect = legendTextRef.current.getBoundingClientRect();
    if (legendTextRect.width > barRect.width) {
      setAlignStyle({ right: 'auto', left: '2px' });
    } else {
      setAlignStyle({ right: '2px', left: 'auto' });
    }
  }

  return (
    <Box
      ref={barRef}
      position="relative"
      backgroundColor={chartData.barColor || 'blue-500'}
      style={{
        borderRadius: '0 2px 2px 0',
        width: chartData?.share + '%',
        height: height + 'px',
        textOverflow: 'ellipsis',
      }}
    >
      {showLegend && (
        <Box
          ref={legendTextRef}
          display="inline"
          color={chartData.textColor || 'white'}
          position="absolute"
          top="50%"
          style={{
            transform: 'translateY(-50%)',
            ...alignStyle,
            fontSize: height < 12 ? height : 12 + 'px',
          }}
        >
          {chartData?.share}
        </Box>
      )}
    </Box>
  );
}
