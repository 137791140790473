import { map, range, unionBy } from 'lodash';

import { getFeatureFlag } from '../contexts/FeatureFlagContext';
import MathUtils from '../utils/MathUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

/**
 * @returns
 * {
    "date": "2021-04-07", # latest date
    "assetGroup": "ALL",
    "isAllDatasets": true,
    "consistencyScoreSummary": [ # covers scoreBin from 0 to 100
        {
            "scoreBin": 0,
            "labelCount": 0
        },
        {
            "scoreBin": 1,
            "labelCount": 2
        }...,
        {
            "scoreBin": 100,
            "labelCount": 2
        }
    ]
}
 */

export type ScoreBinSummary = { scoreBin: number; labelCount: number };
export interface ConsensusHistogramData {
  scoreDistribution: ScoreBinSummary[];
  maxCount: number;
}
const getConsensusSummary: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const url = `/projects/${projectId}/consensus-summaries/latest/`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: getFeatureFlag('analyticsProxy').enabled ? `/v2${url}` : url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  console.debug('consensus-summaries', res);
  return {
    scoreDistribution: res?.data?.consistencyScoreSummary,
    maxCount: Math.max(...map(res?.data?.consistencyScoreSummary, d => d.labelCount)),
  } as ConsensusHistogramData;
};

/**
 * @returns
 * {
    "date": "2021-04-07",
    "assetGroup": "ALL",
    "isAllDatasets": true,
    "consensusLabelCount": 90,
    "submittedConsensusLabelCount": 45,
    "submittedPercent": 50.0,
    "averageScore": 20.0,
    "averageNumVotes": 3.0,
    // this value is percent difference, not percent increase
    // n.b. last week might not have an entry -> what do?
    "oneWeekChange": {
        "submittedPercent": 49.0,
        "averageScore": -20.0
    },
    "history": [ // last month, for only avail
        {
            "date": "2021-04-07",
            "submittedPercent": 50.0,
            "averageScore": 98.0
        },
        {
            "date": "2021-04-06",
            "submittedPercent": 0.0,
            "averageScore": null
        },
        {
            "date": "2021-03-31",
            "submittedPercent": 1.0,
            "averageScore": 20.0
        }
    // n.b. data may be sparse, interpolated in FE
    ]
  }
 */

export interface ConsensusProgressHistoryDatum {
  date: Date;
  submittedPercent: number | null;
  averageScore: number | null;
}

const getConsensusProgressSummary: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const url = `/projects/${projectId}/consensus-progress-summaries/latest/`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: getFeatureFlag('analyticsProxy').enabled ? `/v2${url}` : url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  console.debug('consensus-progress-summaries', res);

  const {
    consensusLabelCount,
    submittedConsensusLabelCount,
    averageNumVotes,
    averageScore,
    submittedPercent,
    oneWeekChange,
    history,
  } = res?.data;

  // Data preprocessing
  // 1 - convert to string date in history to Date objects

  return [
    {
      consensusLabelCount: consensusLabelCount || 0,
      submittedConsensusLabelCount: submittedConsensusLabelCount || 0,
      averageNumVotes: averageNumVotes || 0,
      averageScore: MathUtils.roundPercent(averageScore) || 0,
      submittedPercent: submittedPercent || 0,
      oneWeekChange: oneWeekChange || { submittedPercent: 0.0, averageScore: 0.0 },
    },
    map(history, d => {
      return {
        date: new Date(d?.date),
        submittedPercent: d?.submittedPercent || 0.0,
        averageScore: MathUtils.roundPercent(d?.averageScore) || 0.0,
      };
    }) as ConsensusProgressHistoryDatum[],
  ];
};

export interface ConsensusLabelerSummary {
  projectId: string; // should be removed
  assetGroup?: string; // can be removed
  email: string;
  name: null | string;
  consensusLabelCount: number;
  submittedConsensusLabelCount: number;
  meanScore: number | null;
  medianScore: number | null;
  maxScore: number | null;
  minScore: number | null;
}

/**
 * Convert consistency score floats to integer, where values between 0 and 1
 * are rounded up to 1, values between 99 and 100 are rounded down to 99, and all
 * other values are rounded to the nearest integer.
 */
const convertScoreToInt = (score: number) => {
  if (0 < score && score <= 1) {
    return 1;
  }
  if (score >= 99 && score < 100) {
    return 99;
  }
  return Math.round(score);
};

const getConsensusLabelerSummary: ApiCall<{ projectId: string }, any> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const url = `/projects/${projectId}/consensus-labeler-summaries/latest/`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: getFeatureFlag('analyticsProxy').enabled ? `/v2${url}` : url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  console.debug('consensus-labeler-summaries', res);

  if (!res?.data) {
    return [];
  }

  /**
   * Preprocess data
   *  - exclude assetGroup & projectId fields (same for all labelers)
   *  - (TODO) remove assetGroup & projectId from api fields (civet)
   */

  return res?.data?.results.flatMap((labeler: ConsensusLabelerSummary) =>
    labeler?.email
      ? {
          email: labeler.email,
          name: null,
          consensusLabelCount: labeler?.consensusLabelCount,
          submittedConsensusLabelCount: labeler?.submittedConsensusLabelCount,
          maxScore: labeler?.maxScore && convertScoreToInt(labeler?.maxScore),
          minScore: labeler?.minScore && convertScoreToInt(labeler?.minScore),
          meanScore: labeler?.meanScore && convertScoreToInt(labeler?.meanScore),
          medianScore: labeler?.medianScore && convertScoreToInt(labeler?.medianScore),
        }
      : [],
  );
};

// V2 only

/**
 * @returns
 * {
    "averageNumVotes": 3,
    "averageScore": 75.0,
    "consensusLabelCount": 90,
    "submittedConsensusLabelCount": 45,
    "submittedPercent": 50.0,
    "consistencyScoreSummary": [ # only include scores that exist
        {
            "scoreBin": 50,
            "labelCount": 2
        },
        {
            "scoreBin": 100,
            "labelCount": 2
        }
    ]
}
 */

export interface ConsensusLabelsSummaryAPI {
  averageNumVotes: number; // ex. 2 ~ 10
  averageScore: number; // [0, 100]
  consensusLabelCount: number;
  history?: any[];
  oneWeekChange?: Record<'submittedPercent' | 'averageScore', number>;
  submittedConsensusLabelCount: number;
  submittedPercent: number; // [0, 100]
  // histogram data
  scoreDistribution: ScoreBinSummary[];
  maxCount: number;
}

const scoreTemplate: ScoreBinSummary[] = range(101).map(score => {
  return { scoreBin: score, labelCount: 0 };
});

const addMissingScoresWithZeroCount = (scores: ScoreBinSummary[]): ScoreBinSummary[] => {
  const template: ScoreBinSummary[] = scoreTemplate;
  return unionBy(scores, template, 'scoreBin');
};

const getConsensusLabelsSummary: ApiCall<
  { projectId: string },
  ConsensusLabelsSummaryAPI
> = async args => {
  const { projectId, isGuest, urlInfo } = args;
  const url = `/projects/${projectId}/consensus-summaries/latest/`;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: getFeatureFlag('analyticsProxy').enabled ? `/v2${url}` : url,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  const {
    consensusLabelCount,
    submittedConsensusLabelCount,
    averageNumVotes,
    averageScore,
    submittedPercent,
    consistencyScoreSummary,
    // oneWeekChange,
    // history,
  } = res?.data;

  return {
    averageNumVotes: averageNumVotes || 0,
    averageScore: MathUtils.roundPercent(averageScore) || 0,
    consensusLabelCount: consensusLabelCount || 0,
    submittedConsensusLabelCount: submittedConsensusLabelCount || 0,
    submittedPercent: submittedPercent || 0,
    scoreDistribution: addMissingScoresWithZeroCount(consistencyScoreSummary),
    maxCount: Math.max(...map(res?.data?.consistencyScoreSummary, d => d.labelCount)),
  } as ConsensusLabelsSummaryAPI;
};

export default {
  getConsensusSummary,
  getConsensusProgressSummary,
  getConsensusLabelerSummary,
  // v2
  getConsensusLabelsSummary,
};
