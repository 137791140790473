import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList, DropdownSelect } from '@superb-ai/norwegian-forest';

import { AccountRoles, getRoleLabel } from '../../../../union/UserRoleUnion';
import { Filters } from '../../../elements/manageMembers/types';

interface Props {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  projects: Map<string, string>;
}

const MemberFilters: React.FC<Props> = ({ filters, setFilters, projects }) => {
  const { t } = useTranslation();
  const projectDefaultOptions = useMemo(() => {
    return [
      { label: t('account.accountMembers.anyProject'), value: '' },
      { label: t('account.accountMembers.noProject'), value: 'no' },
    ];
  }, [t]);

  const statusOptions = useMemo(() => {
    return [
      { label: t('all'), value: '' },
      { label: t('pending'), value: 'Invited' },
      { label: t('active'), value: 'Active' },
    ];
  }, [t]);

  const [projectOptions, setProjectOptions] =
    useState<{ label: string; value: string }[]>(projectDefaultOptions);

  const handleStatusFilterChange = (status: string) => {
    setFilters({ ...filters, status });
  };

  const handleRoleFilterChange = (role: string) => {
    setFilters({ ...filters, role });
  };

  const handleProjectsFilterChange = (projects: string[]) => {
    let nextProjects = projects;
    const lastClicked = projects[projects.length - 1];
    if (lastClicked === 'no') {
      nextProjects = ['no'];
    } else if (lastClicked === '') {
      nextProjects = [''];
    } else {
      nextProjects = nextProjects.filter(p => p !== '' && p !== 'no');
    }
    setFilters({ ...filters, projects: nextProjects });
  };

  useEffect(() => {
    const entries = Array.from(projects.entries()).sort((a, b) => a[1].localeCompare(b[1]));
    const loadedProjectsOptions = entries.map(([projectId, name]) => ({
      label: name,
      value: projectId,
    }));

    setProjectOptions([...projectDefaultOptions, ...loadedProjectsOptions]);
  }, [projects]);

  const roleOptions = [
    { label: t('users.dialogs.projectAccess.all'), value: '' },
    ...AccountRoles.map(r => ({ label: getRoleLabel(t, r), value: r })),
  ];

  return (
    <Box width="600px" display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="8px">
      <DropdownList
        placeholder={t('users.filter.filterByStatus')}
        value={filters.status}
        options={statusOptions}
        onChange={handleStatusFilterChange}
      />
      <DropdownList
        placeholder={t('users.filters.filterByRole')}
        value={filters.role}
        options={roleOptions}
        onChange={handleRoleFilterChange}
      />
      <DropdownSelect
        placeholder={t('users.filter.filterByProjectAccess')}
        value={filters.projects}
        options={projectOptions}
        onChange={handleProjectsFilterChange}
        width={250}
      />
    </Box>
  );
};

export default MemberFilters;
