import React from 'react';
import { ListChildComponentProps } from 'react-window';

import {
  MislabelDetectionAnnotationType,
  MislabelDetectionResult,
} from '../../../../types/mislabelDetectionTypes';
import GroupedResultsLayout from '../GroupedResultsLayout';
import ResultImageObserverContainer from '../ResultImageObserverContainer';
import ResultImage from './ResultImage';

export default function ResultViewListItem({
  index,
  style,
  data: { results, columns, setSelectedItem, setSelectedClass },
}: ListChildComponentProps) {
  const result = results[index];

  return (
    <div style={{ ...style }}>
      <GroupedResultsLayout key={result.class_id} item={result}>
        {result.results.slice(0, columns * 2).map(
          (
            resultPerClass: MislabelDetectionResult<MislabelDetectionAnnotationType> & {
              url?: string;
            },
            index: number,
          ) => (
            <ResultImageObserverContainer
              key={`${result.class_id}-${resultPerClass.mislabel_rank}`}
              aspectRatio={1}
            >
              <ResultImage
                classInfo={result}
                imageInfo={resultPerClass}
                onClickImage={() => {
                  setSelectedItem(index);
                  setSelectedClass(result);
                }}
              />
            </ResultImageObserverContainer>
          ),
        )}
      </GroupedResultsLayout>
    </div>
  );
}
