import * as ShyCredit from '@superb-ai/shy-credit';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { getProjectSteps } from '../../../configs/NewProjectStepsConfig';
import {
  ANNOTATION_TYPE,
  initialFreeResponse,
  initialImageCategoryMap,
  initImageCategories,
  PROPERTY_TYPE,
} from '../../../consts/NewProjectConst';
import { getAllowSelfAssign } from './helper';

const convertAnnotationType = (type: string) => {
  switch (type) {
    case ANNOTATION_TYPE.BOX.value:
      return 'box';
    case 'box':
      return ANNOTATION_TYPE.BOX.value;

    case ANNOTATION_TYPE.CUBOID.value:
      return 'cuboid';
    case 'cuboid':
      return ANNOTATION_TYPE.CUBOID.value;

    case ANNOTATION_TYPE.CUBOID2D.value:
      return 'cuboid2d';
    case 'cuboid2d':
      return ANNOTATION_TYPE.CUBOID2D.value;

    case ANNOTATION_TYPE.ROTATED_BOX.value:
      return 'rbox';
    case 'rbox':
      return ANNOTATION_TYPE.ROTATED_BOX.value;
    case ANNOTATION_TYPE.POLYLINE.value:
      return 'polyline';
    case 'polyline':
      return ANNOTATION_TYPE.POLYLINE.value;

    case ANNOTATION_TYPE.POLYGON.value:
      return 'polygon';
    case 'polygon':
      return ANNOTATION_TYPE.POLYGON.value;

    case ANNOTATION_TYPE.KEYPOINT.value:
      return 'keypoint';
    case 'keypoint':
      return ANNOTATION_TYPE.KEYPOINT.value;

    case ANNOTATION_TYPE.IMAGE_CATEGORY.value:
      return 'image category';
    case 'image category':
      return ANNOTATION_TYPE.IMAGE_CATEGORY.value;
    default:
      return '';
  }
};

const convertPropertyType = (type: string) => {
  switch (type) {
    case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
      return 'radio';
    case 'radio':
      return PROPERTY_TYPE.MULTIPLE_CHOICE.value;
    case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
      return 'checkbox';
    case 'checkbox':
      return PROPERTY_TYPE.MULTIPLE_SELECTION.value;
    case PROPERTY_TYPE.FREE_RESPONSE.value:
      return 'free response';
    case 'free response':
      return PROPERTY_TYPE.FREE_RESPONSE.value;
    default:
      return '';
  }
};
const convertDataType = (workapp: string): 'image beta' | 'image sequence' | 'pointclouds' => {
  switch (workapp) {
    case 'image-siesta':
      return 'image beta';
    case 'pointclouds-siesta':
      return 'pointclouds';
    case 'video-siesta':
    default:
      return 'image sequence';
  }
};

const typeToModel = {
  'image-siesta': ShyCredit.Models.ImageV2,
  'video-siesta': ShyCredit.Models.Video,
  'pointclouds-siesta': ShyCredit.Models.Pointclouds,
} as const;

function getModelForWorkapp(workapp: 'image-siesta' | 'video-siesta' | 'pointclouds-siesta') {
  return typeToModel[workapp];
}

function isModelType<T extends keyof typeof typeToModel>(
  workapp: 'image-siesta' | 'video-siesta' | 'pointclouds-siesta',
  type: T,
  model: (typeof typeToModel)[keyof typeof typeToModel],
): model is (typeof typeToModel)[T] {
  return workapp === type;
}

// image sequence
// eslint-disable-next-line
const createLabelInterfaceForImageSequence = (newProjectInfo: any): any => {
  const model = getModelForWorkapp(newProjectInfo.selectedWorkapp);
  const isImage = isModelType(newProjectInfo.selectedWorkapp, 'image-siesta', model);

  const createLabelResultDef = (): any => {
    const createObjectTrackingDef = (): any => {
      const createAnnotationTypes = (): any => {
        return Object.keys(newProjectInfo.selectedAnnotationTypes).map(convertAnnotationType);
      };

      const createObjectClasses = (): any => {
        return newProjectInfo.objectClasses.map((objectClass: any) => {
          const createProperties = (properties: any): any => {
            return properties.map((property: any) => {
              if (property.type === 'MULTIPLE_CHOICE' || property.type === 'MULTIPLE_SELECTION') {
                const createDefaultValue = (): any => {
                  if (property.type === 'MULTIPLE_CHOICE') {
                    const nextDefaultValue = property.options[property.type].find(
                      (option: any) => option.checked,
                    );
                    return nextDefaultValue ? nextDefaultValue.id : null;
                  }
                  if (property.type === 'MULTIPLE_SELECTION') {
                    return property.options[property.type]
                      .filter((option: any) => option.checked)
                      .map((option: any) => option.id);
                  }

                  return null;
                };

                const getOptions = (): any => {
                  return property.options[property.type].map((option: any) => {
                    return new model.CategoryItem({ id: option.id, name: option.name });
                  });
                };

                if (isImage) {
                  return new ShyCredit.Models.ImageV2.ChoicePropertyDef({
                    id: property.id,
                    name: property.name,
                    description: '',
                    type: convertPropertyType(property.type) as 'radio' | 'dropdown' | 'checkbox',
                    options: getOptions(),
                    defaultValue: createDefaultValue(),
                    required: property.required,
                  });
                }
                return new model.ChoicePropertyDef({
                  id: property.id,
                  name: property.name,
                  description: '',
                  perFrame: property.perFrame,
                  type: convertPropertyType(property.type) as 'radio' | 'dropdown' | 'checkbox',
                  options: getOptions(),
                  defaultValue: createDefaultValue(),
                  required: property.required,
                });
              }
              if (property.type === 'FREE_RESPONSE') {
                const createConstraints = (): any => {
                  if (property.options[property.type].isOnlyNumber) {
                    return { alphabet: false, digit: true, space: false, special: false };
                  }
                  return { alphabet: true, digit: true, space: true, special: true };
                };

                if (isImage) {
                  return new ShyCredit.Models.ImageV2.TextPropertyDef({
                    id: property.id,
                    name: property.name,
                    description: '',
                    type: convertPropertyType(property.type) as 'free response',
                    defaultValue: property.options[property.type].value,
                    blank: !property.required,
                    constraints: createConstraints(),
                  });
                }
                return new model.TextPropertyDef({
                  id: property.id,
                  name: property.name,
                  description: '',
                  perFrame: property.perFrame,
                  type: convertPropertyType(property.type) as 'free response',
                  defaultValue: property.options[property.type].value,
                  blank: !property.required,
                  constraints: createConstraints(),
                });
              }
              return null;
            });
          };

          const createConstraints = ({
            minCount,
            maxCount,
          }: {
            minCount: any;
            maxCount: any;
          }): { minCount?: number; maxCount?: number } => {
            const nextConstraints: { minCount?: number; maxCount?: number } = {
              minCount: 0,
              maxCount: 0,
            };

            if (typeof minCount === 'number') {
              nextConstraints.minCount = minCount;
            } else {
              delete nextConstraints.minCount;
            }

            if (typeof maxCount === 'number') {
              nextConstraints.maxCount = maxCount;
            } else {
              delete nextConstraints.maxCount;
            }

            return nextConstraints;
          };

          const createKeypointInterfaceId = () => {
            if (!objectClass.keypointInfo) return {};

            return { keypointInterfaceId: objectClass.keypointInfo.template.id };
          };

          if (isImage) {
            console.log(objectClass);
            return new ShyCredit.Models.ImageV2.ObjectClassDef({
              id: objectClass.id,
              name: objectClass.name,
              annotationType: convertAnnotationType(objectClass.annotationType),
              color: objectClass.color,
              properties: createProperties(objectClass.properties),
              constraints: createConstraints({
                minCount: objectClass.minCount,
                maxCount: objectClass.maxCount,
              }),
              ...createKeypointInterfaceId(),
              aiClassMap: [],
            });
          }
          const createConfig = () => {
            // create default size
            const result: any = {};
            if (objectClass?.config?.defaultSize) {
              if (
                newProjectInfo.selectedDataType === 'pointclouds' &&
                objectClass.annotationType === 'CUBOID'
              ) {
                const { x, y, z } = objectClass.config.defaultSize;
                result.defaultSize = {
                  x: parseNumberMoreThanZero(x),
                  y: parseNumberMoreThanZero(y),
                  z: parseNumberMoreThanZero(z),
                };
              }
            }

            return result;
            function parseNumberMoreThanZero(value: any) {
              const parsedValue = Number(value);
              if (Number.isNaN(parsedValue) || parsedValue <= 0) {
                return 1;
              }

              return parsedValue;
            }
          };

          return new model.ObjectClassDef({
            id: objectClass.id,
            name: objectClass.name,
            annotationType: convertAnnotationType(objectClass.annotationType),
            color: objectClass.color,
            properties: createProperties(objectClass.properties),
            constraints: createConstraints({
              minCount: objectClass.minCount,
              maxCount: objectClass.maxCount,
            }),
            ...createKeypointInterfaceId(),
            aiClassMap: [],
            config: createConfig(),
          });
        });
      };
      const createObjectGroups = (): any => {
        return Object.values(newProjectInfo.objectClassGroupMap)
          .filter((group: any) => group.parent === 'root' && group.children)
          .map(
            (group: any) =>
              new model.ObjectGroupDef({ name: group.name, objectClassIds: group.children }),
          );
      };

      const createKeypoints = (): any => {
        return newProjectInfo.keypointList;
      };

      const hasObjectDetection = Boolean(
        newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.BOX.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.CUBOID.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.CUBOID2D.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.KEYPOINT.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.POLYGON.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.POLYLINE.value] ||
          newProjectInfo.selectedAnnotationTypes[ANNOTATION_TYPE.ROTATED_BOX.value],
      );

      if (isImage) {
        return new ShyCredit.Models.ImageV2.ObjectDetectionDef({
          annotationTypes: createAnnotationTypes(),
          objectClasses: hasObjectDetection ? createObjectClasses() : [],
          objectGroups: hasObjectDetection ? createObjectGroups() : [],
          keypoints: createKeypoints(),
        });
      }

      return new model.ObjectTrackingDef({
        annotationTypes: createAnnotationTypes(),
        objectClasses: hasObjectDetection ? createObjectClasses() : [],
        objectGroups: hasObjectDetection ? createObjectGroups() : [],
        keypoints: createKeypoints(),
      });
    };

    const createCategorization = (): any => {
      if (!newProjectInfo.selectedAnnotationTypes.IMAGE_CATEGORY) {
        return { properties: [] };
      }

      const createProperties = () => {
        const createOption = (imageCategoryMap: any) => (id: string) => {
          const node = imageCategoryMap[id];
          const isGroup = node.children;
          if (isGroup) {
            return new model.CategoryGroup({
              id,
              name: node.name,
              children: node.children.map(createOption(imageCategoryMap)),
            });
          }
          return new model.CategoryItem({
            id,
            name: node.name,
          });
        };

        const createConstraints = (freeResponseInfo: any) => {
          if (freeResponseInfo.isOnlyNumber) {
            return { alphabet: false, digit: true, space: false, special: false };
          }
          return { alphabet: true, digit: true, space: true, special: true };
        };

        return newProjectInfo.imageCategories.map((imageCategory: any) => {
          const { type, perFrame, required, imageCategoryMap } = imageCategory;

          switch (type) {
            case PROPERTY_TYPE.MULTIPLE_SELECTION.value:
              return new model.ChoicePropertyDef({
                id: imageCategory.id,
                name: imageCategory.imageCategoryMap.root.name,
                description: '',
                type: 'checkbox',
                perFrame,
                defaultValue: [],
                required,
                options: imageCategoryMap.root.children.map(createOption(imageCategoryMap)),
              });

            case PROPERTY_TYPE.MULTIPLE_CHOICE.value:
              if (isImage) {
                return new ShyCredit.Models.ImageV2.ChoicePropertyDef({
                  id: imageCategory.id,
                  name: imageCategory.imageCategoryMap.root.name,
                  description: '',
                  type: 'radio',
                  defaultValue: '',
                  required,
                  options: imageCategoryMap.root.children.map(createOption(imageCategoryMap)),
                });
              }
              return new model.ChoicePropertyDef({
                id: imageCategory.id,
                name: imageCategory.imageCategoryMap.root.name,
                description: '',
                type: 'radio',
                perFrame,
                defaultValue: '',
                required,
                options: imageCategoryMap.root.children.map(createOption(imageCategoryMap)),
              });

            case PROPERTY_TYPE.FREE_RESPONSE.value:
              if (isImage) {
                return new ShyCredit.Models.ImageV2.TextPropertyDef({
                  id: imageCategory.id,
                  name: imageCategory.imageCategoryMap.root.name,
                  description: '',
                  type: 'free response',
                  defaultValue: imageCategory.freeResponseInfo.value,
                  blank: !required,
                  constraints: createConstraints(imageCategory.freeResponseInfo),
                });
              }

              return new model.TextPropertyDef({
                id: imageCategory.id,
                name: imageCategory.imageCategoryMap.root.name,
                description: '',
                type: 'free response',
                perFrame,
                defaultValue: imageCategory.freeResponseInfo.value,
                blank: !required,
                constraints: createConstraints(imageCategory.freeResponseInfo),
              });

            default:
              return null;
          }
        });
      };

      return new model.CategorizationDef({
        properties: createProperties(),
      });
    };

    const createVersion = (): any => {
      if (!newProjectInfo.version) return {};

      return {
        version: newProjectInfo.version,
      };
    };

    if (isImage) {
      return new ShyCredit.Models.ImageV2.LabelResultDef({
        dataType: 'image',
        objectDetection: createObjectTrackingDef(),
        categorization: createCategorization(),
        ...createVersion(),
      });
    }

    return new model.LabelResultDef({
      dataType: convertDataType(newProjectInfo.selectedWorkapp),
      objectTracking: createObjectTrackingDef(),
      categorization: createCategorization(),
      ...createVersion(),
    });
  };

  return createLabelResultDef();
};

const setNewProjectInfo = ({
  project,
  newProjectInfo,
}: {
  project: any;
  newProjectInfo: any;
}): any => {
  const objectDef = project.labelInterface.objectDetection || project.labelInterface.objectTracking;
  const { dataType } = project.labelInterface;

  const createSteps = (): any => {
    const getHasObjectClass = (): any => {
      return (
        objectDef?.annotationTypes?.some((type: string) => {
          switch (convertAnnotationType(type)) {
            case ANNOTATION_TYPE.BOX.value:
            case ANNOTATION_TYPE.CUBOID.value:
            case ANNOTATION_TYPE.CUBOID2D.value:
            case ANNOTATION_TYPE.POLYLINE.value:
            case ANNOTATION_TYPE.POLYGON.value:
            case ANNOTATION_TYPE.KEYPOINT.value:
              return true;
            default:
              return false;
          }
        }) ?? false
      );
    };

    const getHasImageCategory = (): any => {
      return (
        objectDef?.annotationTypes?.some((type: string) => {
          return convertAnnotationType(type) === ANNOTATION_TYPE.IMAGE_CATEGORY.value;
        }) ?? false
      );
    };
    return getProjectSteps({
      dataType,
      hasObjectClass: getHasObjectClass(),
      hasImageCategory: getHasImageCategory(),
    });
  };

  const createSelectedAnnotationTypes = (): any => {
    return (objectDef?.annotationTypes ?? [])
      .map(convertAnnotationType)
      .reduce((acc: any, annotationType: string) => {
        acc[annotationType] = true;
        return acc;
      }, {});
  };

  const createImageCategories = (): any => {
    if (!project?.labelInterface?.categorization?.properties?.length) {
      return cloneDeep(initImageCategories);
    }

    const createImageCategory = (name: string, options?: any[]) => {
      const imageCategoryMap: any = cloneDeep(initialImageCategoryMap);
      imageCategoryMap.root.name = name;

      if (options) {
        const searchChild = (node: any, parentNodeId: string) => {
          // @ts-ignore: hash에 key 값이 있음을 보장해야함
          imageCategoryMap[node.id] = {
            id: node.id,
            name: node.name,
            parent: parentNodeId,
          };

          const { children } = node;

          if (children) {
            const nextChildren = [];

            for (let i = 0; i < children.length; i++) {
              searchChild(children[i], node.id);
              nextChildren.push(children[i].id);
            }

            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            imageCategoryMap[node.id].children = nextChildren;
          }
        };

        options.forEach((option: any) => {
          searchChild(option, 'root');
          imageCategoryMap.root.children.push(option.id);
        });
      }

      return imageCategoryMap;
    };

    return (project.labelInterface.categorization?.properties ?? []).map((property: any) => {
      const { type } = property;

      if (type === 'checkbox') {
        return {
          id: property.id,
          type: PROPERTY_TYPE.MULTIPLE_SELECTION.value,
          perFrame: property?.perFrame || false,
          required: property?.required || false,
          imageCategoryMap: createImageCategory(property.name, property.options),
          freeResponseInfo: cloneDeep(initialFreeResponse),
        };
      }
      if (type === 'radio') {
        return {
          id: property.id,
          type: PROPERTY_TYPE.MULTIPLE_CHOICE.value,
          perFrame: property?.perFrame || false,
          required: property?.required || false,
          imageCategoryMap: createImageCategory(property.name, property.options),
          freeResponseInfo: cloneDeep(initialFreeResponse),
        };
      }
      if (type === 'free response') {
        return {
          id: property.id,
          type: PROPERTY_TYPE.FREE_RESPONSE.value,
          required: !property?.blank || false,
          perFrame: property?.perFrame || false,
          imageCategoryMap: createImageCategory(property.name),
          freeResponseInfo: {
            id: '1',
            isOnlyNumber: !property.constraints.alphabet,
            value: property.defaultValue,
          },
        };
      }

      return null;
    });
  };

  const creatObjectClasses = (): any => {
    return (objectDef?.objectClasses ?? []).map((objectClass: any) => {
      const createProperties = (): any => {
        return objectClass.properties.map((property: any) => {
          const type = convertPropertyType(property.type);

          const getRequired = (): boolean => {
            if (
              type === PROPERTY_TYPE.MULTIPLE_CHOICE.value ||
              type === PROPERTY_TYPE.MULTIPLE_SELECTION.value
            ) {
              return property.required;
            }
            if (type === PROPERTY_TYPE.FREE_RESPONSE.value) {
              return !property.blank;
            }
            return false;
          };

          const getOptions = (): any => {
            if (type === PROPERTY_TYPE.MULTIPLE_CHOICE.value) {
              const multipleChoiceOptions = property.options.map((option: any): any => {
                const getChecked = (): any => {
                  return property.defaultValue === option.id;
                };
                return {
                  id: option.id,
                  name: option.name,
                  checked: getChecked(),
                };
              });
              const multipleSelectionOptions = cloneDeep(multipleChoiceOptions).map(
                (option: any, index: number) => {
                  return {
                    id: index + 1,
                    ...option,
                  };
                },
              );

              return {
                MULTIPLE_CHOICE: multipleChoiceOptions,
                MULTIPLE_SELECTION: multipleSelectionOptions,
                FREE_RESPONSE: {
                  id: 1,
                  // TODO (tsnoh): only number
                  isOnlyNumber: false,
                  value: '',
                },
              };
            }
            if (type === PROPERTY_TYPE.MULTIPLE_SELECTION.value) {
              const multipleSelectionOptions = property.options.map((option: any) => {
                const getChecked = (): any => {
                  return property.defaultValue.includes(option.id);
                };
                return {
                  id: option.id,
                  name: option.name,
                  checked: getChecked(),
                };
              });

              const multipleChoiceOptions = cloneDeep(multipleSelectionOptions).map(
                (option: any, index: number) => {
                  return {
                    id: index,
                    checked: false,
                    ...option,
                  };
                },
              );

              return {
                MULTIPLE_CHOICE: multipleChoiceOptions,
                MULTIPLE_SELECTION: multipleSelectionOptions,
                FREE_RESPONSE: {
                  id: 1,
                  // TODO (tsnoh): only number
                  isOnlyNumber: false,
                  value: '',
                },
              };
            }
            if (type === PROPERTY_TYPE.FREE_RESPONSE.value) {
              return {
                MULTIPLE_CHOICE: [
                  {
                    id: uuidv4(),
                    name: 'Untitled Option',
                    checked: false,
                  },
                  {
                    id: uuidv4(),
                    name: 'Untitled Option (1)',
                    checked: false,
                  },
                ],
                MULTIPLE_SELECTION: [
                  {
                    id: uuidv4(),
                    name: 'Untitled Option',
                    checked: false,
                  },
                  {
                    id: uuidv4(),
                    name: 'Untitled Option (1)',
                    checked: false,
                  },
                ],
                FREE_RESPONSE: {
                  id: property.id,
                  isOnlyNumber:
                    !(
                      property.constraints.alphabet ||
                      property.constraints.space ||
                      property.constraints.special
                    ) && property.constraints.digit,
                  value: property.defaultValue,
                },
              };
            }
            return null;
          };

          return {
            id: property.id,
            name: property.name,
            required: getRequired(),
            perFrame: property?.perFrame || false,
            type,
            options: getOptions(),
          };
        });
      };

      const createKeypointInfo = () => {
        if (objectClass.annotationType !== 'keypoint') return {};
        const { keypointInterfaceId } = objectClass;
        const keypoints =
          project?.labelInterface?.objectDetection?.keypoints ||
          project?.labelInterface?.objectTracking?.keypoints ||
          [];
        const curKeypoint = keypoints.find((keypoint: any) => keypoint.id === keypointInterfaceId);

        const nextKeypointInfo = {
          id: objectClass.keypointInterfaceId,
          template: curKeypoint,
          visibilityOption: {
            type: '3_STATES',
            state: 'VISIBLE',
          },
          workapp: project.workapp,
        };

        return { keypointInfo: nextKeypointInfo };
      };

      return {
        id: objectClass.id,
        name: objectClass.name,
        annotationType: convertAnnotationType(objectClass.annotationType),
        color: objectClass.color,
        properties: createProperties(),
        minCount: objectClass.constraints.minCount ? objectClass.constraints.minCount : '',
        maxCount: objectClass.constraints.maxCount ? objectClass.constraints.maxCount : '',
        ...createKeypointInfo(),
        config: objectClass.config,
      };
    });
  };

  const createObjectClassGroupMap = (): any => {
    const objectGroups = {
      root: {
        id: 'root',
        name: 'Root',
        parent: null,
        type: null,
        children: objectDef?.objectGroups?.map((objectGroup: any) => objectGroup.id) ?? [],
      },
    };

    objectDef?.objectGroups?.forEach((objectGroup: any) => {
      // @ts-ignore: hash에 key 값이 있음을 보장해야함
      objectGroups[objectGroup.id] = {
        id: objectGroup.id,
        name: objectGroup.name,
        parent: 'root',
        children: objectGroup.objectClassIds,
      };

      objectGroup.objectClassIds.forEach((objectClassId: string) => {
        const curObjectClass = objectDef.objectClasses.find(
          (objectClass: any) => objectClass.id === objectClassId,
        );

        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        objectGroups[objectClassId] = {
          id: curObjectClass.id,
          name: curObjectClass.name,
          parent: objectGroup.id,
          annotationType: convertAnnotationType(curObjectClass.annotationType),
        };
      });
    });

    return objectGroups;
  };

  const createKeypointList = (): any[] => {
    return (
      project?.labelInterface?.objectDetection?.keypoints ||
      project?.labelInterface?.objectTracking?.keypoints ||
      []
    );
  };

  // version
  newProjectInfo.setVersion(project.labelInterface.version);
  // meta
  newProjectInfo.setSteps(createSteps());
  // create project
  newProjectInfo.setProjectName(project.name);
  newProjectInfo.setDescription(project.description);
  newProjectInfo.setSelectedDataType(convertDataType(project.workapp));
  newProjectInfo.setSelectedWorkapp(project.workapp);
  newProjectInfo.setSelectedAnnotationTypes(createSelectedAnnotationTypes());
  newProjectInfo.setIsPublic(project.isPublic);
  newProjectInfo.setAllowAdvancedQa(project.settings.allowAdvancedQa);
  newProjectInfo.setAllowSelfAssign(getAllowSelfAssign(project.settings.numMaxSelfAssign));
  // object detection
  newProjectInfo.setImageCategories(createImageCategories());
  newProjectInfo.setObjectClasses(creatObjectClasses());
  newProjectInfo.setObjectClassGroupMap(createObjectClassGroupMap());
  newProjectInfo.setKeypointList(createKeypointList());
};

export default {
  createLabelInterfaceForImageSequence,
  setNewProjectInfo,
};
