import React, { useState } from 'react';
import { useParams } from 'react-router';

import { StateGetterSetter } from '../../../../../../../contexts/types';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { PageSet } from '../../../../../types/routeTypes';
import {
  useStatsComparedMetadataKeyQuery,
  useStatsMetadataKeyQuery,
  ValueDepedencies,
} from '../queries/metadataQueries';
import { MetadataKeyResponse } from '../types';
import { useAnalyticsContext } from './AnalyticsContext';
// prettier-ignore
type ContextProps =
  & StateGetterSetter<['selectedMetadataKey', 'setSelectedMetadataKey'], string>
  & StateGetterSetter<['showSystemMetadata', 'setShowSystemMetadata'], boolean>
  & { isKeySelected: boolean;
    selectionKeyData:MetadataKeyResponse;
    isFetchingSelectionKey:boolean;
    comparedKeyData?: MetadataKeyResponse;
    isFetchingComparedKey?: boolean;
    getValueQueryDependencies: (key?: string) =>ValueDepedencies;
  };

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { queryStringAndHiddenFilterQueryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const currentPageSet = sliceInfo ? 'slice' : ('dataset' as PageSet);
  const { isCompareMode, selectedCompareSetId } = useAnalyticsContext();

  const { showPublicDatasets } = usePublicDatasetContext();
  const { appliedFilters, clusterLevel, selectedProjectionIn } = useImageFilterContext();
  const [selectedMetadataKey, setSelectedMetadataKey] = useState('');
  const [showSystemMetadata, setShowSystemMetadata] = useState(false);
  const isKeySelected = selectedMetadataKey !== '';

  const keyDependencies = {
    datasetId,
    fromPublicDatasets: showPublicDatasets,
    sliceId: sliceInfo?.id,
    sliceName: sliceInfo?.name,
    queryString: queryStringAndHiddenFilterQueryString,
    showSystemMetadata,
    ...(currentPageSet === 'slice' && { sliceId: sliceInfo?.id, slice: sliceInfo?.name }),
  };
  const filterDependencies = {
    clusterLevel,
    selectedProjectionIn,
    appliedFilters,
  };

  const { data: selectionKeyData = { count: 0, data: [] }, isFetching: isFetchingSelectionKey } =
    useStatsMetadataKeyQuery({ ...keyDependencies, ...filterDependencies });

  const comparedKeyDependencies = {
    ...keyDependencies,
    ...(isCompareMode && { compareSetId: selectedCompareSetId }),
    isCompareMode,
  };

  const { data: comparedKeyData = { count: 0, data: [] }, isFetching: isFetchingComparedKey } =
    useStatsComparedMetadataKeyQuery(comparedKeyDependencies);

  function getValueQueryDependencies(metadataKey?: string) {
    return {
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      sliceId: sliceInfo?.id,
      sliceName: sliceInfo?.name,
      queryString: queryStringAndHiddenFilterQueryString,
      metadataKey: metadataKey || selectedMetadataKey || '',
      ...(currentPageSet === 'slice' && { sliceId: sliceInfo?.id, slice: sliceInfo?.name }),
      ...(isCompareMode && { compareSetId: selectedCompareSetId }),
      isCompareMode,
      clusterLevel,
      appliedFilters,
    };
  }

  return {
    selectedMetadataKey,
    setSelectedMetadataKey,
    isKeySelected,
    selectionKeyData,
    isFetchingSelectionKey,
    comparedKeyData,
    isFetchingComparedKey,
    getValueQueryDependencies,
    showSystemMetadata,
    setShowSystemMetadata,
  };
};

export const useMetadataAnalyticsContext = (): ContextProps => {
  return React.useContext(Context);
};

export const MetadataAnalyticsProvider: React.FC = ({ children }) => {
  const metadataInfo = useProvider();
  return <Context.Provider value={metadataInfo}>{children}</Context.Provider>;
};
