import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import LabelList from '../../../../components/pages/label/labelList/LabelList';
import { CreateMenuItem } from '../../../../menu';

export const ProjectLabelsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectLabelList,
  path: 'labels',
  title: ({ t }) => t('labels.title'),
  component: LabelList,
  icon: 'list',
});
