import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { useRouteInfo } from '../../../contexts/RouteContext';
import GoToPage from './GoToPage';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';

const contactEmailAddress = 'hello@superb-ai.com';

export default function InvalidPageLayout({ origin }: { origin: 'account' | 'user' }) {
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();

  useChangeContent('graphic');

  useEffect(() => {
    const { code } = routeInfo.params;

    if (!code) {
      routeInfo.history.push('/auth/login');
    }
  }, [routeInfo.params]);

  return (
    <>
      <ShortCut page="signIn" />
      <Box width="100%" display="flex" flexDirection="column" gap={2}>
        <Title>
          {origin === 'account' ? t('auth.title.invalidAccount') : t('auth.title.invalidUser')}
        </Title>
        <Typography variant="m-regular">
          <Trans t={t} i18nKey="auth.messages.pleaseContactSupport" email={contactEmailAddress}>
            Please contact us at{' '}
            <LinkTypography
              color="secondary"
              onClick={() => window.open(`mailto:${contactEmailAddress}`)}
            >
              {{ email: contactEmailAddress }}
            </LinkTypography>
          </Trans>
        </Typography>
        <GoToPage to="login">{t('auth.goToSignIn')}</GoToPage>
      </Box>
    </>
  );
}
