import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../contexts/AuthContext';
import UserAvatar from './UserAvatar';

interface Props {
  userInfo: {
    avatarUrl?: string | null;
    name?: string | null;
    email?: string | null;
    status?: string;
  };
  withEmail?: boolean;
  size?: 'tiny' | 'xs' | 's' | 'm';
}

const AvatarWithInfo: React.FC<Props> = ({ userInfo, withEmail, size = 's' }) => {
  const authInfo = useAuthInfo();

  const user = !userInfo ? authInfo : userInfo;

  const infoGap = {
    tiny: 4,
    xs: 4,
    s: 6,
    m: 12,
  }[size];

  const userAvatarSize = {
    tiny: 12,
    xs: 16,
    s: 24,
    m: 36,
  }[size];

  const userInfoTypo = {
    tiny: { nameWithoutEmail: 's-regular' },
    xs: { nameWithoutEmail: 's-regular' },
    s: { name: 's-strong', email: 's-regular', nameWithoutEmail: 'm-regular' },
    m: { name: 'l-strong', email: 'm-regular' },
  }[size] as {
    name?: 's-strong' | 'l-strong';
    email?: 's-regular' | 'm-regular';
    nameWithoutEmail?: 's-regular' | 'm-regular';
  };

  return (
    <Box display="flex" gap={infoGap} alignItems="center">
      <UserAvatar userInfo={user} size={userAvatarSize} />
      <Box>
        {withEmail ? (
          <>
            <Typography display="block" variant={userInfoTypo.name}>
              {user.name}
            </Typography>
            <Typography display="block" variant={userInfoTypo.email}>
              {user.email}
            </Typography>
          </>
        ) : (
          <Typography variant={userInfoTypo.nameWithoutEmail}>{user.name}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default AvatarWithInfo;
