import React, { ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box as NFBox } from '@superb-ai/norwegian-forest';
import { Box, Input, RadioGroup, Range, Select, Typography } from '@superb-ai/ui';
import { clamp } from 'lodash';

import {
  IOUOption,
  useExportsAnalyticsInfo,
} from '../../../../../contexts/ExportsAnalyticsContext';
import { VerticalDivider } from '../components/VerticalDivider';

interface Option<V extends string = string> {
  label: string | JSX.Element;
  value: V;
  disabled?: boolean;
}

const labelProps = {
  borderRadius: '2px',
  pl: 0.5,
  border: '1px solid',
  borderColor: { default: 'transparent', checked: 'primary' },
  color: { checked: 'primary' },
} as ComponentProps<typeof RadioGroup>['labelProps'];

const recommendedValueToValues = {
  'High-quality Labeling': [0.8, 0.9],
  'Model Evaluation': [0.5, 0.5],
};

const ReportIoUThreshold = () => {
  const { t } = useTranslation();
  const {
    boxValue,
    setBoxValue,
    polygonValue,
    setPolygonValue,
    setIoUOption,
    ioUOption,
    recommededOption,
    setRecommededOption,
  } = useExportsAnalyticsInfo();

  const recommendedForOptions = [
    {
      value: 'Model Evaluation' as const,
      label: t('analytics.exportsAnalytics.stepper.modelEvaluation'),
    },
    {
      value: 'High-quality Labeling' as const,
      label: t('analytics.exportsAnalytics.stepper.highQualityLabeling'),
    },
  ];
  useEffect(() => {
    const [box, polygon] = recommendedValueToValues[recommededOption];
    setBoxValue(box);
    setPolygonValue(polygon);
  }, [recommededOption, setBoxValue, setPolygonValue]);

  const handleBoxValueChange = (value: number) => {
    setBoxValue(Number(clamp(Number(value), 0, 1).toFixed(2)));
  };

  const handlePolygonValueChange = (value: number) => {
    setPolygonValue(Number(clamp(Number(value), 0, 1).toFixed(2)));
  };

  const handleIoUValueChange = (value: React.SetStateAction<IOUOption>) => {
    setIoUOption(value);
    if (value === 'Recommended') {
      const [box, polygon] = recommendedValueToValues[recommededOption];
      setBoxValue(box);
      setPolygonValue(polygon);
    }
  };

  const options: Option<IOUOption>[] = [
    {
      value: '',
      label: t('analytics.exportsAnalytics.modal.dontCalculate'),
    },
    {
      value: 'Recommended',
      label: (
        <>
          {t('analytics.exportsAnalytics.modal.recommendedFor')}
          <Select
            style={{ marginLeft: 'auto' }}
            variant="text"
            data={recommendedForOptions}
            value={recommededOption}
            onChangeValue={setRecommededOption}
          />
        </>
      ),
    },
    {
      value: 'Custom',
      label: t('analytics.exportsAnalytics.modal.custom'),
    },
  ];

  const hideArrowBtn = {
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    "input[type='number']": {
      MozAppearance: 'textfield',
    },
  };

  return (
    <Box>
      <Typography variant="m-regular">{t('analytics.exportsAnalytics.IoU.description')}</Typography>

      <Box mt={2} mb={1}>
        <Box display="inline" gap="small" alignItems="center" justifyContent="space-between">
          <RadioGroup
            options={options}
            value={ioUOption}
            onChangeValue={handleIoUValueChange}
            labelProps={labelProps}
            style={{ gridTemplateColumns: '350px', gridTemplateRows: '34px 34px 34px' }}
          />
        </Box>
      </Box>

      {ioUOption && (
        <Box
          backgroundColor="gray-100"
          p={2}
          my={2}
          display="grid"
          alignItems="center"
          style={{ gridTemplateColumns: '1fr 1px 1fr' }}
          gap={4}
        >
          <Box
            display="grid"
            style={{ gridTemplateColumns: 'max-content 1fr 45px' }}
            gap={2}
            alignItems="center"
          >
            <Typography variant="m-medium" color={'gray-400'}>
              {t('analytics.exportsAnalytics.IoU.box')}
            </Typography>

            <Range
              color="primary"
              size="medium"
              max={1.0}
              min={0.0}
              step={0.01}
              disabled={ioUOption !== 'Custom'}
              onValueInput={handleBoxValueChange}
              value={boxValue}
            />

            <NFBox css={hideArrowBtn}>
              <Input
                style={{
                  textAlign: 'center',
                }}
                type="number"
                color="gray"
                size="m"
                step=".01"
                variant="stroke"
                disabled={ioUOption !== 'Custom'}
                value={boxValue}
                onChange={event => {
                  handleBoxValueChange(event.target.valueAsNumber);
                }}
                autoFocus
              />
            </NFBox>
          </Box>
          <VerticalDivider style={{ height: '16px' }} />
          <Box
            display="grid"
            style={{ gridTemplateColumns: 'max-content 1fr 45px' }}
            gap={2}
            alignItems="center"
          >
            <Typography variant="m-medium" color={'gray-400'}>
              {t('analytics.exportsAnalytics.IoU.polygon')}
            </Typography>

            <Range
              color="primary"
              size="medium"
              max={1.0}
              min={0.0}
              step={0.01}
              disabled={ioUOption !== 'Custom'}
              onValueInput={handlePolygonValueChange}
              value={polygonValue}
            />

            <NFBox width={42} css={hideArrowBtn}>
              <Input
                style={{
                  textAlign: 'center',
                }}
                step=".01"
                type="number"
                color="gray"
                size="m"
                variant="stroke"
                disabled={ioUOption !== 'Custom'}
                value={polygonValue}
                onChange={event => {
                  handlePolygonValueChange(event.target.valueAsNumber);
                }}
                autoFocus
              />
            </NFBox>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReportIoUThreshold;
