import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export function Layout() {
  const history = useHistory();
  const { i18n } = useTranslation();
  const lang = i18n.language || 'en';

  useEffect(() => {
    window.open(
      `${process.env.NEXT_PUBLIC_APPS_PLATFORM_URL}?spb_user=${localStorage.getItem(
        'spb_user',
      )}&spb_=${localStorage.getItem('spb_')}&lang=${lang}`,
    );
    history.goBack();
  }, [history, lang]);

  return <></>;
}
