import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeHandlerFunc } from 'react-mentions';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { findIndex, trim } from 'lodash';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import IssuesService from '../../../../../services/IssuesService';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import Mentions from '../../../../elements/mentions/Mentions';
import { CommentInputProps } from './types';

const useStyles = makeStyles({
  inputBox: {
    background: '#fbfbfb',
    display: 'flex',
    marginBottom: '9px',
    transition: '0.3s',
    position: 'relative',
    '&.error': {
      background: '#ffcc00',
    },
  },
  commentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  commentButton: {
    width: '60px',
    height: '26px',
    padding: '0px',
    '& .MuiButton-label': {
      color: '#eb5757',
      fontSize: '10px',
    },
  },
  commentLength: {},
});

const ThreadFooterInput: React.FC<CommentInputProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const { label } = labelDetailViewInfo;
  const { isCreate, issue, setFooterMode } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [addedMentions, setAddedMentions] = useState<string[]>([]);
  const [isOverMaxLength, setIsOverMaxLength] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const {
    hash,
    history: {
      location: { pathname, search },
    },
  } = routeInfo;
  const {
    nextIssue,
    setNextIssue,
    setIssues,
    issueViewStatus,
    setIssueViewStatus,
    setIssuePanelState,
    setOpenBadgeInfo,
  } = labelIssuesInfo;

  useEffect(() => {
    if (!isOverMaxLength) return;

    setTimeout(() => {
      setIsOverMaxLength(false);
    }, 300);
  }, [isOverMaxLength]);

  const handleChangeMentionInput: OnChangeHandlerFunc = (
    event,
    newValue,
    newPlainTextValue,
    mentions,
  ) => {
    const lastChar = newPlainTextValue[newPlainTextValue.length - 1];
    if (lastChar === '\n') {
      if (newPlainTextValue.length === 1) return;
    }

    if (newPlainTextValue.length >= 1000) {
      setInputValue(newPlainTextValue.slice(0, 1000));
      if (!isOverMaxLength) setIsOverMaxLength(true);
      return;
    }
    setInputValue(newValue);
    setAddedMentions(mentions.map(mention => mention.id));
  };

  const handleClickPost = async () => {
    const nextInputValue = trim(inputValue);

    if (nextInputValue === '') {
      if (isCreate) {
        setIssuePanelState(null);
      } else {
        setFooterMode('option');
      }
      return;
    }

    if (isCreate) {
      try {
        setIsProcessing(true);
        nextIssue.message = nextInputValue;
        nextIssue.mentioned = addedMentions;
        const createdIssue = await IssuesService.createIssue({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          issueInfo: nextIssue,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        const nextIssues = await IssuesService.getIssues({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        setIssues(nextIssues);

        setNextIssue({
          info: {
            color: '#FF625A',
            target: {
              point: {
                x: 0,
                y: 0,
              },
            },
          },
          message: '',
          mentioned: [],
        });

        if (issueViewStatus === 'RESOLVED') setIssueViewStatus('OPEN');

        routeInfo.history.push(
          `${pathname}${search}${hash}&thread_id=${createdIssue.issueThread.id}&thread_state=thread`,
        );

        setIsProcessing(false);
      } catch (err: any) {
        setIsProcessing(false);
        throw err;
      }
    } else {
      try {
        setIsProcessing(true);
        const createdIssue = await IssuesService.createComment({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          issueId: issue.id,
          info: {
            message: nextInputValue,
            mentioned: addedMentions,
          },
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        const nextIssues = await IssuesService.getIssues({
          projectId: routeInfo.urlMatchInfo.projectId,
          labelId: label.id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        setIssues(nextIssues);
        setFooterMode('option');
        setInputValue('');

        setOpenBadgeInfo({
          issue:
            nextIssues[
              findIndex(
                nextIssues,
                (item: any) => item.threadNumber === createdIssue.issueThread.threadNumber,
              )
            ],
          ref: null,
        });

        setIsProcessing(false);
      } catch (err: any) {
        setIsProcessing(false);
        setFooterMode('option');
        throw err;
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key, shiftKey } = event;
    event.stopPropagation();

    switch (key) {
      case 'Enter':
        if (!shiftKey) handleClickPost();
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const { key } = event;
    event.stopPropagation();

    switch (key) {
      case 'Escape':
        if (isCreate) {
          setIssuePanelState(null);
          setOpenBadgeInfo({
            issue: null,
            ref: null,
          });
        } else {
          setFooterMode('option');
        }
        break;
      default:
        break;
    }
  };

  return (
    <MUI.Box className={`${classes.inputBox} ${isOverMaxLength && 'error'}`}>
      <MUI.Box p="6px 0px 6px 16px" width="calc(100% - 76px)">
        <Mentions
          isEdit
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onChange={handleChangeMentionInput}
          value={inputValue}
        />
      </MUI.Box>
      <MUI.Box className={classes.commentBox}>
        <MUI.Button className={classes.commentButton} onClick={handleClickPost}>
          POST
        </MUI.Button>
      </MUI.Box>
      {isProcessing && <CircularProgressDialog isLoading={isProcessing} />}
    </MUI.Box>
  );
};

export default ThreadFooterInput;
