import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    width: '100%',
    height: '6px',
    background: 'none',
    borderRadius: '4px',
    zIndex: 20,
    '&.prev': {
      top: -2,
    },
    '&.next': {
      bottom: -2,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  },
}));

interface Props {
  dir: string;
  index: number;
}

const ClassPropertyItemDropBar = (props: Props): React.ReactElement | null => {
  const newProjectInfo = useNewProjectInfo();
  const { dir, index } = props;
  const classes = useStyles();

  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.index === index ||
    newProjectInfo.dragState.type !== 'classProperty'
  ) {
    if (isDragEnter) {
      setIsDragEnter(false);
    }
    return null;
  }
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragEnter(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);

    const curObjectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    const curProperties = curObjectClass.properties;

    let selectedClassPropertyId = '';
    if (curProperties[newProjectInfo.selectedClassPropertyIndex]) {
      selectedClassPropertyId = curProperties[newProjectInfo.selectedClassPropertyIndex].id;
    }

    const fromClassProperty = curProperties.splice(newProjectInfo.dragState.index, 1)[0];

    let idx = index;
    if (dir === 'next') {
      if (newProjectInfo.dragState.index > index) {
        idx += 1;
      }
    }
    curProperties.splice(idx, 0, fromClassProperty);
    newProjectInfo.setObjectClasses(nextObjectClasses);

    if (selectedClassPropertyId) {
      newProjectInfo.setSelectedClassPropertyIndex(
        curProperties.findIndex(
          (classProperty: any) => classProperty.id === selectedClassPropertyId,
        ),
      );
    }
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default ClassPropertyItemDropBar;
