import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronBigLeft, ChevronBigRight } from '@superb-ai/icons';
import { Box, IconButton } from '@superb-ai/ui';
import {
  addDays,
  differenceInDays,
  endOfYear,
  format,
  isAfter,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';

import { NoDataToShow } from '../../../../apps/Curate/components/datasets/dataset/analytics/components/NoDataToShow';
import { useAICreditUsage } from '../../../../queries/useMeteringQuery';
import { daysAgo, getUTCDate } from '../../../../utils/date';
import {
  createColorAccessor,
  formatDate,
  formatMonth,
  productColor,
} from '../../../elements/charts/chartUtils';
import { StackedBarChart } from '../../../elements/charts/StackedBarChart';
import { DateRangePicker } from '../../../elements/datePicker/DateRangePicker';
import { ChartCard } from './aiCreditUsage/ChartCard';
import { transformCreditData } from './aiCreditUsage/transformer';
import { DateRange } from './types';

export function AiCreditMetering() {
  const now = new Date();

  const { t } = useTranslation();
  const [chartAreaSize, setChartAreaSize] = useState<{ width: number; height: number }>();
  const [dateRange, setDateRange] = useState<DateRange>({
    from: getUTCDate(daysAgo(29)),
    to: getUTCDate(now),
  });

  const rangeInDays = differenceInDays(new Date(dateRange.to), new Date(dateRange.from));
  const dailyDisabled = rangeInDays > 90;
  const aggregationUnit = dailyDisabled ? 'monthly' : 'daily';

  const aiCreditQuery = useAICreditUsage({
    aggregationUnit,
    dateRange,
  });
  const chartAreaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartAreaRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!chartAreaRef.current) {
        return;
      }
      const { width, height } = chartAreaRef.current.getBoundingClientRect();
      setChartAreaSize({
        width: width,
        height: height,
      });
    });

    observer.observe(chartAreaRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  function selectPreviousRange() {
    setDateRange({
      from: getUTCDate(subDays(new Date(dateRange.from), rangeInDays + 1)),
      to: getUTCDate(subDays(new Date(dateRange.from), 1)),
    });
  }
  const selectNextDisabled = differenceInDays(new Date(dateRange.to), now) >= 0;
  function selectNextRange() {
    setDateRange({
      from: getUTCDate(addDays(new Date(dateRange.to), 1)),
      to: getUTCDate(addDays(new Date(dateRange.to), rangeInDays + 1)),
    });
  }
  const datePickerRanges: RangeType[] = [
    {
      label: '7 Days',
      value: [daysAgo(6), now],
    },
    {
      label: '30 Days',
      value: [daysAgo(29), now],
    },
    {
      label: '12 Months',
      value: [addDays(subMonths(now, 12), 1), now],
    },
    {
      label: 'This year',
      value: [startOfYear(now), endOfYear(now)],
    },
  ];
  const transformedData = aiCreditQuery.data
    ? transformCreditData(aiCreditQuery.data, aggregationUnit, dateRange, t)
    : [];

  return (
    <Box ref={chartAreaRef}>
      <ChartCard
        chartTitle={t('metering.usageByPeriod')}
        datePicker={
          <Box display="flex" gap={0.5}>
            <IconButton
              icon={ChevronBigLeft}
              variant="text"
              onClick={() => selectPreviousRange()}
            />
            <Box border="1px solid" borderColor="gray-200">
              <DateRangePicker
                value={[new Date(dateRange.from), new Date(dateRange.to)]}
                onChange={date => {
                  if (date) {
                    setDateRange({
                      from: format(date[0], 'yyyy-MM-dd'),
                      to: format(date[1], 'yyyy-MM-dd'),
                    });
                  }
                }}
                ranges={datePickerRanges}
                disabledDate={(date, selectDate, selectedDone) =>
                  isAfter(date, endOfYear(now)) ||
                  !(selectedDone || !selectDate?.[0] || isAfter(addDays(selectDate[0], 365), date))
                }
              />
            </Box>
            <IconButton
              icon={ChevronBigRight}
              variant="text"
              onClick={() => selectNextRange()}
              disabled={selectNextDisabled}
            />
          </Box>
        }
        chartComponent={
          transformedData.length === 0 ? (
            <NoDataToShow />
          ) : (
            <StackedBarChart
              data={transformedData}
              chartWidth={chartAreaSize?.width}
              getColor={createColorAccessor(t)}
              color={productColor['autoLabel']}
              xAxis={{
                xAccessor: 'unit',
                stackAccessor: 'unit',
                dateFormatter: aggregationUnit === 'daily' ? formatDate : formatMonth,
              }}
              yAxis={{
                label: 'Credit',
                yAccessor: 'count',
                domain: {
                  min: 0, // clip negative values
                  max: NaN,
                  padding: 0.1, // Pad by 10%
                  paddingUnit: 'domainRatio',
                },
              }}
            />
          )
        }
        isLoading={aiCreditQuery.isInitialLoading}
        style={{
          height: '350px',
        }}
      />
    </Box>
  );
}
