import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import Layout from './Layout';

export const ProjectsListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectList, // also includes create new project
  path: 'list',
  title: ({ t }) => t('projects.title'),
  component: Layout,
});
