import { useTranslation } from 'react-i18next';

import { IconButton } from '@superb-ai/norwegian-forest';
import { Box, Popover, SelectList, ToggleButton, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { HIDE_COLUMNS, SHOW_COLUMNS } from '../../../../../consts/SnackbarMessage';

interface LabelerFilterDropdownProps {
  currentTab: string;
  showAnnoTypeColumns: boolean;
  setshowAnnoTypeColumns: (value: boolean) => void;
  showObjectClassColumns: boolean;
  setShowObjectClassColumns: (value: boolean) => void;
}

const LabelerColumnFilterDropdown = (props: LabelerFilterDropdownProps) => {
  const {
    currentTab,
    setshowAnnoTypeColumns,
    showAnnoTypeColumns,
    setShowObjectClassColumns,
    showObjectClassColumns,
  } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const toggleOptions = [
    {
      value: 'showAnnotationTypeColumns',
      label: (
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} pr={0}>
          <Typography pr={0.5} variant="m-regular">
            {t('analytics.userReports.annotationTypes')}
          </Typography>
          <ToggleButton
            color="primary"
            onClick={() => {
              setshowAnnoTypeColumns(!showAnnoTypeColumns);
            }}
            size="xs"
            state={showAnnoTypeColumns}
            disabled={currentTab === 'reviewResult'}
          />
        </Box>
      ),
    },
    {
      value: 'showObjectClassColumns',
      label: (
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} pr={0}>
          <Typography pr={0.5} variant="m-regular">
            {t('analytics.userReports.objectClasses')}
          </Typography>
          <ToggleButton
            color="primary"
            onClick={() => setShowObjectClassColumns(!showObjectClassColumns)}
            size="xs"
            state={showObjectClassColumns}
            disabled={currentTab === 'reviewResult'}
          />
        </Box>
      ),
    },
  ];
  function getMessageFn(value: string) {
    if (value === 'showAnnotationTypeColumns') {
      return !showAnnoTypeColumns ? SHOW_COLUMNS : HIDE_COLUMNS;
    } else if (value === 'showObjectClassColumns') {
      return !showObjectClassColumns ? SHOW_COLUMNS : HIDE_COLUMNS;
    }
  }

  return (
    <>
      <Popover
        hideOnClick
        disclosure={<IconButton color="lightgrey" variant="stroke" size="s" icon={'filter'} />}
        style={{ zIndex: 1000 }}
      >
        <Box backgroundColor="white" boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
          <Box px={1.5} py={0.5} display="flex" alignItems="center">
            <Typography variant="s-regular" color="gray-300">
              {t('analytics.userReports.filter.showColumns')}
            </Typography>
          </Box>
          <SelectList
            data={toggleOptions}
            multiple={false}
            value={undefined}
            onChangeValue={(value: string) => {
              if (value === 'showAnnotationTypeColumns') {
                const groupName = t('analytics.userReports.filter.annotationType');
                enqueueSnackbar(getMessageFn(value)({ t, groupName }), { variant: 'success' });
              } else if (value === 'showObjectClassColumns') {
                const groupName = t('analytics.userReports.objectClass');
                enqueueSnackbar(getMessageFn(value)({ t, groupName }), { variant: 'success' });
              }
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default LabelerColumnFilterDropdown;
