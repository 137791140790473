import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowRight } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PROPERTY_TYPE } from '../../../../consts/NewProjectConst';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import Title from '../Title';
import CategoryList from './CategoryList';
import FreeResponseList from './FreeResponseList';
import GroupList from './GroupList';
import ImageCategoryList from './ImageCategoryList';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    height: '100%',
  },
  innerBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    height: '30px',
    alignItems: 'flex-start',
  },
  section: {
    width: '100%',
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    padding: '5px',
  },
  arrowBox: {
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Layout = (): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const selectedImageCategory =
    newProjectInfo.selectedImageCategoryIndex !== -1
      ? newProjectInfo.imageCategories[newProjectInfo.selectedImageCategoryIndex]
      : null;

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box width="50px" />
      <MUI.Box className={classes.innerBox}>
        <MUI.Box className={classes.header}>
          <Title
            title={
              WorkappUnion.isImageSequence(newProjectInfo.selectedDataType)
                ? t('projectSettings.configuration.videoCategory')
                : t('projectSettings.configuration.imageCategory')
            }
          />
        </MUI.Box>
        <MUI.Box mb="30px" />
        <MUI.Box className={classes.section}>
          <ImageCategoryList />

          {selectedImageCategory &&
            selectedImageCategory.type === PROPERTY_TYPE.FREE_RESPONSE.value && (
              <>
                <MUI.Box className={classes.arrowBox}>
                  <Icon icon={ArrowRight} size={24} color="gray" />
                </MUI.Box>
                <FreeResponseList imageCategory={selectedImageCategory} />
              </>
            )}
          {selectedImageCategory &&
            selectedImageCategory.type !== PROPERTY_TYPE.FREE_RESPONSE.value && (
              <>
                <MUI.Box className={classes.arrowBox}>
                  <Icon icon={ArrowRight} size={24} color="gray" />
                </MUI.Box>
                <CategoryList imageCategory={selectedImageCategory} />
                <MUI.Box className={classes.arrowBox}>
                  <Icon icon={ArrowRight} size={24} color="gray" />
                </MUI.Box>
                <GroupList imageCategory={selectedImageCategory} />
              </>
            )}
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

export default Layout;
