import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { orderBy as loOrderBy } from 'lodash';

import { rowsPerPageOptions } from '../userStats/config';
import AnnotationsChangedTableRow from './AnnotationsChangedTableRow';
import { annotationsChangedHeader } from './constants/tableHeaders';
import ExportsAnalyticsTableHeader from './ExportsAnalyticsTableHeader';
import { disabledButtonProps } from './ObjectCountsTable';
import { AnnotationsChangedRow, AnnotationsChangedTableProps } from './types/types';

interface Props {
  header: AnnotationsChangedTableProps[];
  rows: AnnotationsChangedRow[];
  isDataEmpty: boolean;
}

const AnnotationsChangedTable = ({ rows, header, isDataEmpty }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [isDesc, setIsDesc] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const getPaginatedRows = (rows: AnnotationsChangedRow[]) =>
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const sortRows = (rows: AnnotationsChangedRow[], isDesc: boolean) => {
    return loOrderBy(rows, orderBy, isDesc ? 'desc' : 'asc');
  };

  const makeRows = (rows: AnnotationsChangedRow[], header: AnnotationsChangedTableProps[]) => {
    return rows.map(row => (
      <AnnotationsChangedTableRow key={row.classId} row={row} header={header} />
    ));
  };

  const handleRequestSort = (property: string, order: 'desc' | 'asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  return (
    <>
      <TablePagination
        {...(isDataEmpty ? disabledButtonProps : {})}
        rowsPerPageOptions={rows.length <= 10 ? [rows.length] : rowsPerPageOptions(rows.length)}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('labels.rowsPerPage')}
      />
      <TableContainer>
        <Table style={{ tableLayout: 'auto' }} size="small">
          <ExportsAnalyticsTableHeader
            tableName={'annotation-change-table'}
            headers={annotationsChangedHeader}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>{makeRows(getPaginatedRows(sortRows(rows, isDesc)), header)}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AnnotationsChangedTable;
