import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { FixedSizeList } from 'react-window';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import ExportListItem from './ExportListItem';

type Props = {
  title: string;
  items: Record<string, any>[];
  selectedItemId: string;
  setSelectedItemId: Dispatch<SetStateAction<string>>;
  disabledItemId?: string;
};
export default function ExportList({
  title,
  items,
  selectedItemId,
  setSelectedItemId,
  disabledItemId,
}: Props) {
  useEffect(() => {
    if (selectedItemId === disabledItemId) {
      setSelectedItemId('');
    }
  }, [selectedItemId, disabledItemId]);

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      style={{ border: '1px solid #E5E5E5', borderRadius: '2px' }}
    >
      <Box p={1} bb>
        <Typography variant="headline6">{title}</Typography>
      </Box>
      <Box p={1} bb display="grid" gridTemplateColumns="5fr 2fr 4fr">
        <Box>
          <Typography variant="body5">Export Name</Typography>
        </Box>
        <Box>
          <Typography variant="body5">Label Count</Typography>
        </Box>
        <Box>
          <Typography variant="body5">Created By / Created At</Typography>
        </Box>
      </Box>
      <FixedSizeList
        width="100%"
        height={440}
        itemSize={56}
        itemCount={items.length}
        itemData={{ items, selectedItemId, setSelectedItemId, disabledItemId }}
      >
        {ExportListItem}
      </FixedSizeList>
    </Box>
  );
}
