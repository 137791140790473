import { isEmpty, last, size, startCase } from 'lodash';
import qs from 'qs';

import { formatCount } from './numberFormat';
import ParamUtils from './ParamUtils';
import ServiceUtils from './ServiceUtils';

const getAnnoFilterCount = (
  annoFilterOptions: string[],
  categorizationIdToName: Record<string, string>,
  objectClassIdToName: Record<string, Record<string, string>>,
  separator = ';',
): string => {
  const selectedOptionsCount = size(annoFilterOptions);
  const selectedOptions = annoFilterOptions
    .slice(0, selectedOptionsCount > 3 ? 3 : selectedOptionsCount)
    .map(option => {
      const lastOption = last(option.split(separator));
      const categorization =
        lastOption && categorizationIdToName ? categorizationIdToName[lastOption] : null;

      return categorization || objectClassIdToName[option]?.label || option;
    })
    .join(', ');

  if (selectedOptionsCount <= 3) {
    return `${formatCount(selectedOptionsCount, 'option')} selected (${selectedOptions})`;
  }
  return `${selectedOptionsCount} options selected (${selectedOptions}...)`;
};

/**
 * Take a list of filters and formats them as a concise string.
 * Example output:
 * "Label Id, in progress (+2 Statuses), not red"
 */
function formatConcisely(
  filters: { filterBy: string; options?: unknown[]; condition: string }[],
): string {
  return filters
    .map(f => {
      const filterName = startCase(f.filterBy);
      if (['label id'].includes(f.filterBy)) {
        return filterName;
      }
      // Show options if available
      if (Array.isArray(f.options) && f.options.length > 0) {
        const showCondition = f.condition.match(/none|not/); // display negative conditions
        const notCondition = showCondition ? 'not ' : '';
        // @ts-ignore
        if (!f.options[0].value) {
          // Fallback for badly formated filters
          return `${filterName} ${notCondition}(${f.options.length})`;
        }
        const plusText =
          f.options.length > 1 ? ` (+${formatCount(f.options.length - 1, filterName)})` : '';
        // @ts-ignore
        return [notCondition, f.options[0].value, plusText].join('');
      }
      // Option-less filters
      return `${filterName} ${f.condition}`;
    })
    .join(', ');
}

function formatAppliedFilterConcisely(filter: string, tagIds: Record<string, string>) {
  type FilterProps = {
    filterBy: string;
    condition: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  const filters = ParamUtils.getFiltersFromApiParams(paramsObj, tagIds) as FilterProps;

  return filters
    .map(
      ({ filterBy, condition, options }) =>
        filterBy + ' ' + condition + ' ' + options.map(option => option.label).join(', '),
    )
    .join('\n');
}

function getFilterObject(
  filterApiParams: any,
  checkedLabels: string[],
  isAllLabelsChecked: boolean,
) {
  const hasCheckedLabels = !isEmpty(checkedLabels) && !isAllLabelsChecked;
  if (hasCheckedLabels) return { idIn: checkedLabels };
  if (!isEmpty(filterApiParams)) return filterApiParams;
  return;
}

export default {
  getAnnoFilterCount,
  formatConcisely,
  formatAppliedFilterConcisely,
  getFilterObject,
};
