import qs from 'qs';

import {
  ProductByFeature,
  UsageByProductFeature,
  UsageHistory,
  UsageResponse,
} from '../components/pages/account/billingAndUsage/types';
import UserUtils from '../utils/UserUtils';
import AuthService from './AuthService';
import { ApiCall } from './types';

export interface Subscription {
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  updatedBy: string | null;
  id: string;
  stripeSubscriptionId?: string | null;
  tenant: string;
  email: string;
  status: string;
  coupon?: string | null;
  billingCycleAnchor: string;
  startDate: string;
  endDate: string | null;
  canceledDate: string | null;
  currentPeriodEnd: string | null;
  currentPeriodStart: string | null;
  trialEnd: string | null;
  isConnectStripe: boolean;
  subscriptionItems?: SubscriptionItemsEntity[] | null;
  plan: Plan;
  tier: Tier;
  paymentMethod?: 'AWS Marketplace' | '';
}
interface SubscriptionItemsEntity {
  id: string;
  stripeSubscriptionItemId?: string | null;
  featureId: string;
  featureName: string;
  maxQuantity: number;
  onDemand: boolean;
}
interface Plan {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string | null;
  id: string;
  name: string;
  displayName: string;
  active: boolean;
  paymentInterval: string;
  default: boolean;
  tier: Tier;
  planItems?: any[] | null;
}
interface Tier {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string | null;
  id: string;
  name: string;
  planIds?: string[] | null;
}

const getSubscription: ApiCall<unknown, Subscription | null> = async ({ isGuest, urlInfo }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: '/billing/api/customers',
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  // Refresh auth info when plan tier changed
  const planTier = res.data.subscription?.plan.tier.name;
  const idToken = localStorage.getItem('spb_user');
  if (!idToken) return null;
  const userTokenInfo = UserUtils.getUserTokenInfo(idToken);
  if (planTier && planTier.toUpperCase() !== userTokenInfo.tier.toUpperCase()) {
    AuthService.loginRefresh({
      accountName: urlInfo.accountName,
    });
  }

  return { ...res.data.subscription, ...res.data };
};

export type FeatureMeteringMetrics = {
  onDemand: boolean;
  quantity: number;
  maxQuantity: number;
};

export type FeatureMeteringItem<Product, Feature> = FeatureMeteringMetrics & {
  product: Product;
  feature: Feature;
};

export type FeaturesMeteringResponse = (
  | FeatureMeteringItem<'common', 'ai-credit'>
  | FeatureMeteringItem<'common', 'user-seat'>
  | FeatureMeteringItem<'label', 'data-volume'>
  | FeatureMeteringItem<'curate', 'data-volume'>
  | FeatureMeteringItem<'model', 'endpoint'>
)[];

type ExtractDiscriminatedSelectors<T> = T extends FeaturesMeteringResponse[number]
  ? {
      product: T['product'];
      feature: T['feature'];
    }
  : never;

export type FeatureMeteringSelector = ExtractDiscriminatedSelectors<
  FeaturesMeteringResponse[number]
>;

const getFeaturesMetering: ApiCall<unknown, FeaturesMeteringResponse> = async ({
  isGuest,
  urlInfo,
}) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: '/billing/api/metering',
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getAICreditUsage: ApiCall<
  { params: { from: string; to: string; aggregate: 'daily' | 'monthly' } },
  UsageByProductFeature[]
> = async ({ isGuest, urlInfo, params }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/billing/api/metering/feature/ai-credit/histories?${qs.stringify(params)}`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  const histories = (res.data as UsageResponse).histories;
  const flattenedData = flattenAICreditHistories(histories);
  return flattenedData;
};

const enablePaymentAws: ApiCall<{ token: string }, any> = async ({ isGuest, urlInfo, token }) => {
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/billing/api/external-payment/connect-aws-marketplace',
    hasPublicApi: false,
    isGuest,
    urlInfo,
    data: {
      token,
    },
  });

  return res;
};

export default {
  getSubscription,
  getFeaturesMetering,
  getAICreditUsage,
  enablePaymentAws,
};

/**
 * @param histories
 *  ex. [
    * {
    *   "details" : [
          {"triggerProduct": "curate", type: "object-embedding", count: 100000}
          {"triggerProduct": "model", type: "train", count: 100000}
        ],
        "totalCount: 200000,
        "unit": "2024-06-10"
    * {
        "details": [
          {"triggerProduct": "model", "type": "endpoint", count: 50000}
          {"triggerProduct": "curate", "type": "curate-synthesis", count: 50000}
        ]
        "totalCount": 100000,
        "unit": "2024-06-07"
    },...
 * ]
 * @returns
  *  Flattend AI credit usage data, so that each datum contains product,
  *  feature type and data count.
 */

const convertToSubProduct = (type: string): ProductByFeature | string => {
  const mapper = {
    train: 'modelTraining',
    endpoint: 'modelEndpoints',
    AutoLabel: 'autoLabel',
    'object-embedding': 'embeddings',
    'curate-synthesis': 'imageSynthesis',
  } as Record<string, ProductByFeature>;
  return mapper[type] || type;
};

function flattenAICreditHistories(histories: UsageHistory[]) {
  return histories.flatMap(({ unit, details }) =>
    details.reduce((result, detail) => {
      const featureKey = convertToSubProduct(detail.type);
      const existingEntry = result.find(entry => entry.productByFeature === featureKey);

      if (existingEntry) {
        existingEntry.count += detail.count;
      } else {
        result.push({
          unit,
          productByFeature: featureKey as ProductByFeature,
          count: detail.count,
        });
      }

      return result;
    }, [] as UsageByProductFeature[]),
  );
}
