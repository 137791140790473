import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import { D3DragEvent, NumberValue } from 'd3';
import * as d3Drag from 'd3-drag';
import { isEmpty, map, mapValues, range, sum, zip } from 'lodash';

import { STATUS_COLORS } from '../../../../../consts/ColorChips';
import { SvgConfigScrollBarObject } from '../../config/types';
import { AssigneeDisplayData, LabelStatusCumulative } from '../../dataTypes/analyticsDataTypes';
import { JsonObj } from '../../userStats/types';
import BarChartStacked from './BarChartStacked';
import ScrollSelector from './ScrollSelector';

/**
 * Scrollable Bar Chart Component
 *  - Y axis
 *  - X Axis
 *  - Main (detail) bar chart
 *  - Full bar chart (overview),scrolalble
 *  - Scroll with handles
 *
 * Design: https://app.zeplin.io/project/5c1769f15cdc42508490f36c/screen/5e7c3d2b174d7e8373d232c5
 */
const calculateScrollBars = (
  xValues: string[],
  yValues: number[],
  selectedStatus: LabelStatusCumulative[],
  svgInfo: SvgConfigScrollBarObject,
) => {
  const scrollChart = { ...svgInfo, bottom: 0 };
  const { SUBMITTED, SKIPPED, WORKING } = STATUS_COLORS;
  const hasMultipleStatusOrNone = isEmpty(selectedStatus) || selectedStatus.length > 1;
  const xScale = d3
    .scaleBand()
    .domain(xValues)
    .range([scrollChart.left, scrollChart.svgWidth - scrollChart.right])
    .paddingInner(0.5);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(yValues) as NumberValue])
    .range([
      scrollChart.svgHeightWithScroll as number,
      scrollChart.svgHeightWithScroll - scrollChart.scrollHeight + 10,
    ]);

  const scrollBars = map(zip(xValues, yValues), (d, index) => {
    const STATUS_COLORS = {
      cumSubmitted: SUBMITTED,
      cumWorking: WORKING,
      cumSkipped: SKIPPED,
      undefined: 'gray',
    };
    const id = index;
    const x = xScale(d[0] as string);
    const y = yScale(d[1] as number);
    const width = xScale.bandwidth();

    const height = scrollChart.svgHeightWithScroll - (yScale(d[1] as number) as number);
    const fill = hasMultipleStatusOrNone
      ? STATUS_COLORS[selectedStatus[0]].color
      : STATUS_COLORS[selectedStatus[0]].color;
    const opacity = hasMultipleStatusOrNone
      ? STATUS_COLORS[selectedStatus[0]].opacity
      : STATUS_COLORS[selectedStatus[0]].opacity;

    return {
      id,
      index,
      x,
      y,
      height,
      width,
      fill,
      opacity,
    };
  });
  return scrollBars;
};

interface Props {
  data: AssigneeDisplayData[];
  selectedStatus: LabelStatusCumulative[];
  chartInfo: JsonObj;
  chartName: string;
  isScrollable: boolean;
  xKeyToDisplayName?: Record<string, string>;
  svgInfo: SvgConfigScrollBarObject;
  width: number;
}

/**
 *
 * xKeyToDisplayName exists iff x keys differ from display names
 *  ex. user email -> user full name
 *
 *  barSliceWidth: Number of slices included in rendered bars??
 * */
const BarChartStackedWithScroll: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    data,
    selectedStatus,
    chartInfo,
    chartName,
    isScrollable,
    svgInfo,
    xKeyToDisplayName,
    width,
  } = props;

  const {
    xVariable: [xKey],
    yVariable: [yKey],
    sVariable: [sKey],
    tooltipKeyMap,
  } = chartInfo;

  const applyI18n = (obj: Record<string, string>): Record<string, string> => {
    const translate = (value: string) => t(value);
    return mapValues(obj, translate);
  };

  const tooltipKeyMapTranslated = applyI18n(tooltipKeyMap);

  const xValues = map(data, d => d[xKey]);
  const yValues = map(data, d => d.currentTotal);
  // const sValues = _.map(data, d=> d[sKey]); // email
  const totalCounts = sum(yValues);
  // const svgInfo = SVG_PROPERTY_SCROLL_BAR(width, xValues.length);
  const svgStyle = {
    overflow: 'visible',
    width: width || svgInfo.svgWidth,
    height: svgInfo.svgHeightFull,
  };

  // ref
  const svgRef = useRef<SVGSVGElement>(null);

  const scrollInitX = svgInfo.left;
  const [sliceStart, setSliceStart] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollX, setScrollX] = useState(svgInfo.left);
  let temporaryScrollX = scrollX;

  const handleDrag = (event: D3DragEvent<SVGGElement, SVGGElement, d3Drag.SubjectPosition>) => {
    setIsScrolling(true);
    if (event.dx !== 0) {
      temporaryScrollX += event.dx;
      const leftBound = svgInfo.scrollLeftBound;
      const rightBound = svgInfo.scrollRightBound;
      // new way with scroll handles (4/5/2020)
      // change svg group that contains scroll and scroll handles
      // let scroll = d3.select(scrollRef.current);

      // get current Y value (will be constant)
      const currentY = svgInfo.svgHeight + 10;
      if (temporaryScrollX > rightBound) {
        temporaryScrollX = rightBound;
      } else if (temporaryScrollX < leftBound) {
        temporaryScrollX = leftBound;
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      d3.select(scrollRef.current).attr('transform', `translate(${temporaryScrollX}, ${currentY})`);
      const newSlice = Math.abs(
        Math.floor((temporaryScrollX - svgInfo.left) / svgInfo.scrollBandWidth),
      );
      setSliceStart(newSlice);
    }
  };

  const handleMouseUp = () => {
    setScrollX(temporaryScrollX);
    setIsScrolling(false);
  };

  const scrollRef = useRef(null);
  /**
   * Upon mouse event over scroller, repositions scroll selector
   * and sets sliceStart (bar index where scroll selector starts at)
   * to render the main bar chart from sliceStart.
   */
  useEffect(
    () => {
      if (isScrollable) {
        d3.select(scrollRef.current).call(
          // @ts-ignore this works
          d3.drag().on('drag', handleDrag).on('end', handleMouseUp),
        );
      }
    },
    // eslint-disable-next-line
    [isScrollable, scrollRef],
  );

  const getSubset = (values: AssigneeDisplayData[], indexFrom: number, indexTo: number) => {
    return values.slice(indexFrom, indexTo);
  };

  // subset of scrollBars that is selected to be rendered!
  const sliceEnd = svgInfo.sliceWidth;
  const dataSubset = getSubset(data, sliceStart, sliceStart + sliceEnd);
  const scrollBars = calculateScrollBars(xValues, yValues, selectedStatus, svgInfo);
  const barsBelowScroll = range(sliceStart, sliceStart + sliceEnd);

  const getBarChartComponent = () => {
    return (
      <BarChartStacked
        chartInfo={chartInfo}
        chartName={chartName}
        checkedStatus={selectedStatus}
        data={isScrollable ? dataSubset : data}
        isScrolling={isScrolling}
        keys={[xKey, yKey, sKey]}
        totalCounts={totalCounts}
        tooltipKeyMap={tooltipKeyMapTranslated}
        xKeyToDisplayName={xKeyToDisplayName}
        width={width}
      />
    );
  };
  const getBarChartWithScrollComponent = () => {
    return (
      <svg id="bar-chart-scrollable-svg" style={svgStyle} ref={svgRef}>
        <g className="chart">
          {getBarChartComponent()}
          {isScrollable ? (
            <g
              key="groupScrollSelectorBox"
              transform={`translate(0, ${svgInfo.scrollBarTopPadding as number})`}
            >
              <rect
                key="scrollSelectorBox"
                fill="#fbfbfb"
                rx={4}
                ry={4}
                x={scrollInitX - 25}
                y={svgInfo.svgHeight}
                width={svgInfo.left + svgInfo.width}
                height={(svgInfo.scrollHeight as number) + 40}
              />
              {totalCounts !== 0 &&
                scrollBars.map(d => (
                  <rect
                    key={`scrollbar-${d.id}`}
                    x={d.x}
                    y={d.y}
                    width={d.width}
                    height={d.height}
                    fill={d3.rgb(d.fill) as unknown as string}
                    opacity={barsBelowScroll.includes(d.index) ? '1' : '0.5'}
                  />
                ))}
              <rect
                key="scrollBottom"
                x={scrollInitX}
                y={svgInfo.svgHeightWithScroll}
                // width={svgInfo.svgWidth - svgInfo.right - 48}
                width={svgInfo.width}
                height={7}
                style={{
                  borderRadius: '2px',
                }}
                fill="#efefef"
                opacity={0.7}
              />
              <g
                key="groupScrollRef"
                ref={scrollRef}
                transform={`translate(${scrollInitX}, ${svgInfo.svgHeight + 10})`}
                style={{ cursor: 'move' }}
              >
                <ScrollSelector svgInfo={svgInfo} />
              </g>
            </g>
          ) : null}
        </g>
      </svg>
    );
  };

  return <>{!isScrollable ? getBarChartComponent() : getBarChartWithScrollComponent()}</>;
};

export default BarChartStackedWithScroll;
