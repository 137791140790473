import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Link, Typography } from '@superb-ai/norwegian-forest';

import { getUserManualUrl } from '../../../../../consts/DocsLink';
import ProjectConst from '../../../../../consts/ProjectConst';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { getUrl } from '../../../../../routes/util';
import LabelInterfaceUtils, {
  Category,
  ObjectClass,
  treeIterator,
} from '../../../../../utils/LabelInterfaceUtils';
import { Engine, fetchEngineInfos } from './helper';
import { TypeIconLabel } from './TypeIconLabel';

const useStyles = makeStyles({
  table: {
    width: '100%',
    position: 'relative',
    borderCollapse: 'collapse',
    fontSize: 12,
    lineHeight: 1.5,
    '& strong': {
      fontWeight: 500,
    },
    '& td': {
      padding: '12px 16px',
    },
    '& th': {
      padding: '8px 16px',
      fontWeight: 'normal',
      textAlign: 'left',
      position: 'sticky',
      top: 50,
      whiteSpace: 'nowrap',
      borderBottom: '1px solid #e5e5e5',
      backgroundColor: '#FBFBFB',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

const NotFoundText = '(Not found, perhaps deleted?)';

export default function SettingsTable(): JSX.Element {
  const classes = useStyles();
  const { project } = useProjectInfo();
  const labelInterface = project.labelInterface;

  const { i18n, t } = useTranslation();
  const { AUTO_LABEL_MANUAL } = getUserManualUrl(i18n.language);

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();

  const [enginesMap, setEnginesMap] = useState<Map<string, Engine>>(new Map());
  const categories: Category[] = labelInterface?.categorization?.properties ?? [];
  const objectClasses: ObjectClass[] = LabelInterfaceUtils.getObjectClasses(labelInterface).filter(
    cls => {
      return ProjectConst.AUTO_LABEL_TYPES.includes(cls.annotationType);
    },
  );

  useEffect(() => {
    fetchEngineInfos({ t, labelInterface, authInfo, routeInfo }).then(setEnginesMap);
  }, [labelInterface, t]);

  function getEngineObjectClasses(engine: Engine, classIds: string[]) {
    const objectClasses = LabelInterfaceUtils.getObjectClasses(engine.labelInterface);
    // Flatten tree structure from pretained AI
    const objectClassesFlat = objectClasses.flatMap(cls =>
      cls.children ? [...treeIterator(cls)] : cls,
    );
    // Map classIds to corresponding AI class
    return classIds.flatMap(id => objectClassesFlat.find(cls => `${cls.id}` === `${id}`) ?? []);
  }

  function getEngineCategory(engine: Engine, categoryId: string) {
    const categories = engine.labelInterface.categorization?.properties ?? [];
    return categories.find(cat => cat.id === categoryId);
  }

  function getEngineObjectClassNames(engine: Engine, classIds: string[]) {
    const classes = getEngineObjectClasses(engine, classIds);
    return classes.map(cls => cls.name);
  }

  function wrapLink(engine: Engine | undefined, children: React.ReactNode) {
    if (!engine?.project?.id || engine.project.id === 'pretrained') {
      return (
        <Link to={AUTO_LABEL_MANUAL} target="_blank" rel="noopener noreferrer">
          {children}
        </Link>
      );
    }
    return (
      <Link
        to={getUrl([
          routeInfo.urlMatchInfo.accountName,
          'label',
          'project',
          engine.project.id,
          'auto-label',
          'cal',
        ])}
      >
        {children}
      </Link>
    );
  }

  function EngineListItem({ engineId }: { engineId?: string }) {
    if (!engineId) return;
    const engine = enginesMap.get(engineId);
    return (
      <div key={engineId}>
        {wrapLink(
          engine,
          <>
            {engine?.project?.name && `${engine.project.name} › `}
            {engine?.name ?? NotFoundText}
          </>,
        )}
      </div>
    );
  }

  const TableFrame: React.FC<{ type: 'objectClasses' | 'categories' }> = ({ children, type }) => (
    <Box my={2} shadow={1}>
      <Box py={1.5} px={3.5} bb>
        <Typography variant="headline6">
          {type === 'objectClasses'
            ? t('labelInterface.objectClasses')
            : t('labelInterface.categories')}
        </Typography>
      </Box>
      <Box py={1} px={1.5} themedBackgroundColor={['grey', 50]}>
        <table className={classes.table}>
          <colgroup>
            <col width="220px" />
            <col width="0" />
            <col width="360px" />
            <col />
          </colgroup>
          {children}
        </table>
      </Box>
    </Box>
  );

  if (!objectClasses?.length && !categories?.length) {
    return (
      <Box my={1}>
        <Typography variant="body3" themedColor="cloud">
          There are no suitable classes or categories configured in the project settings.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {objectClasses?.length > 0 && (
        <TableFrame type="objectClasses">
          <thead>
            <tr>
              <th>{t('autoLabel.settings.mapping.objectClasses.objectName.name')}</th>
              <th>{t('autoLabel.settings.mapping.objectClasses.annotationType.name')}</th>
              <th>{t('autoLabel.autoLabelAI')}</th>
              <th>{t('autoLabel.settings.mapping.objectClasses.appliedObjects.name')}</th>
            </tr>
          </thead>
          <tbody>
            {objectClasses.map(cls => (
              <tr key={cls.id}>
                <td>
                  <strong>{cls.name}</strong>
                </td>
                <td className={classes.nowrap}>
                  <TypeIconLabel type={cls.annotationType} />
                </td>
                <td>{cls.aiClassMap?.map(EngineListItem)}</td>
                <td>
                  {cls.aiClassMap?.map(ai => {
                    const engine = enginesMap.get(ai.engineId);
                    return (
                      <div key={ai.engineId}>
                        {engine && getEngineObjectClassNames(engine, ai.classIds).join(', ')}
                      </div>
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </TableFrame>
      )}
      {categories?.length > 0 && (
        <TableFrame type="categories">
          <thead>
            <tr>
              <th>{t('autoLabel.settings.mapping.categories.categoryName.name')}</th>
              <th>{t('autoLabel.settings.mapping.categories.categoryType.name')}</th>
              <th>{t('autoLabel.settings.mapping.categories.autoLabelAI.name')}</th>
              <th>{t('autoLabel.settings.mapping.categories.appliedCategory.name')}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(cls => (
              <tr key={cls.id}>
                <td>{cls.name}</td>
                <td className={classes.nowrap}>
                  <TypeIconLabel type={cls.type} />
                </td>
                <td>{Array.from(cls.aiProperty ? [cls.aiProperty] : []).map(EngineListItem)}</td>
                <td>
                  {Array.from(cls.aiProperty ? [cls.aiProperty] : []).map(ai => {
                    if (!ai.engineId || !ai.propertyId) return;
                    const engine = enginesMap.get(ai.engineId);
                    return (
                      <div key={ai.engineId}>
                        {engine && getEngineCategory(engine, ai.propertyId)?.name}
                      </div>
                    );
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </TableFrame>
      )}
    </>
  );
}
