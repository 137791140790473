import React from 'react';

import { Box, Card } from '@superb-ai/norwegian-forest';

interface Props extends React.ComponentProps<typeof Card> {
  title: React.ReactNode;
  image?: React.ReactNode;
}

export default function TutorialCard({ title, image, children, ...boxProps }: Props) {
  return (
    <Card {...boxProps} width={910} maxWidth="100%" mx="auto" my={3}>
      <Box display="flex">
        <Box flex={1}>
          <Box p={6} pt={8}>
            {title}
            {image && (
              <Box mt={8} mx={4}>
                {image}
              </Box>
            )}
          </Box>
        </Box>
        <Box flex={1} bl>
          {children}
        </Box>
      </Box>
    </Card>
  );
}
