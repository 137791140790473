import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../menu/MenuItem';
import Layout from './Layout';

export const DatasetsListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetList,
  path: 'list',
  title: ({ t }) => t('curate.datasets.title'),
  component: Layout,
});

export const SampleDatasetsListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateSampleDatasetList,
  path: 'list',
  title: ({ t }) => t('curate.datasets.title'),
  component: Layout,
});
