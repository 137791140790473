import { ComparisonReport } from '../components/pages/analytics/exportsAnalytics/types/types';
import AuthService from './AuthService';
import { ApiCall } from './types';

interface RequestArg {
  queryParams: {
    name: string;
    iou: {
      bbox: number;
      polygon: number;
    };
    query_export_id: string;
    ref_export_id: string;
  };
}

interface ResponseResult {
  id: string;
}

const createReport: ApiCall<RequestArg, ResponseResult> = async args => {
  const {
    queryParams: {
      name,
      iou: { bbox, polygon },
      ref_export_id,
      query_export_id,
    },
    isGuest,
    urlInfo,
  } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/commands/exports_analytics/',
    data: {
      project_id: urlInfo.projectId,
      params: {
        name,
        iou_thresholds: {
          bbox,
          polygon,
        },
        ref_export_id,
        query_export_id,
      },
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return {
    id: res.data.id,
  };
};

const getAllVersions: ApiCall<
  unknown,
  { results: ComparisonReport[]; count: number }
> = async args => {
  const { isGuest, urlInfo } = args;
  try {
    const res = await AuthService.apiCallAfterLogin({
      method: 'get',
      url: `/projects/${urlInfo.projectId}/exports_analytics/`,
      data: {},
      hasPublicApi: false,
      isGuest,
      urlInfo,
    });
    return res.data;
  } catch (err: unknown) {
    throw new Error(`Request failed with ${err}`);
  }
};

const getVersion: ApiCall<
  { exportsAnalyticsId: string },
  { result: ComparisonReport; error?: string }
> = async args => {
  const { exportsAnalyticsId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${urlInfo.projectId}/exports_analytics/${exportsAnalyticsId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getLatestVersion: ApiCall<
  unknown,
  { result: ComparisonReport; error?: string }
> = async args => {
  const { isGuest, urlInfo } = args;
  try {
    const res = await AuthService.apiCallAfterLogin({
      method: 'get',
      url: `/projects/${urlInfo.projectId}/exports_analytics/latest/`,
      data: {},
      hasPublicApi: false,
      isGuest,
      urlInfo,
    });
    return res.data;
  } catch (err: unknown) {
    throw new Error(`Request failed with ${err}`);
  }
};

export default {
  getAllVersions,
  getLatestVersion,
  getVersion,
  createReport,
};
