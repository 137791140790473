import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Label,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { DEFAULT_ERROR } from '../../../consts/ModalMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import RegexUtils from '../../../utils/RegexUtils';
import GoToPage from './GoToPage';
import { getAccountNameError, getEmailError, getNameError } from './helper';
import { useChangeContent } from './Layout';
import ShortCut from './ShortCut';
import Title from './Title';
import ValidationInput, { blurAllInputs, useValidationInputRefs } from './ValidationInput';

const removeRememberUserInfo = () => {
  localStorage.removeItem('spb_auth_remember_me');
  localStorage.removeItem('spb_auth_account_name');
  localStorage.removeItem('spb_auth_email');
};

export default function SignInLayout() {
  const dialog = useDialogState();

  const [isRequesting, setIsRequesting] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const [info, setInfo] = useState({
    accountName: '',
    email: '',
    password: '',
  });
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const refs = useValidationInputRefs(['accountName', 'email', 'password'] as const);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { email, password, accountName } = info;
  const { closeModal, openModal } = useAlertModal();

  useChangeContent('graphic');

  useEffect(() => {
    const SPB_AUTH_REMEMBER_ME = localStorage.getItem('spb_auth_remember_me');
    const SPB_AUTH_ACCOUNT_NAME = localStorage.getItem('spb_auth_account_name');
    const SPB_AUTH_EMAIL = localStorage.getItem('spb_auth_email');

    if (SPB_AUTH_REMEMBER_ME === 'true') {
      setIsRemember(true);
      changeInfo('accountName', SPB_AUTH_ACCOUNT_NAME ?? '');
      changeInfo('email', SPB_AUTH_EMAIL ?? '');
    }

    (async () => {
      const isRemember = SPB_AUTH_REMEMBER_ME === 'true';
      const { tenantId, email, code } = routeInfo.params;

      if (tenantId && email && code) {
        try {
          const { hasSession, data } = await authInfo.login(atob(tenantId), atob(email), code);

          if (hasSession) {
            const { accountName, email, session } = data;
            routeInfo.history.push(
              `/auth/invited?tenantId=${btoa(accountName)}&email=${btoa(email)}&session=${session}`,
            );
            return;
          }
        } catch (e: any) {
          if (e.message === 'Wrong password!') {
            enqueueSnackbar('This account is already verified. Please login.', {
              variant: 'success',
            });
            routeInfo.history.replace('/auth/login');
            return;
          }
        }
      }

      if (tenantId && email) {
        refs.current.password?.origin?.focus();
        setIsRemember(false);
        changeInfo('accountName', atob(tenantId));
        changeInfo('email', atob(email));
      } else if (!isRemember) {
        refs.current.accountName?.origin?.focus();
      } else {
        refs.current.password?.origin?.focus();
      }
    })();
  }, [routeInfo.params]);

  const changeInfo = (key: keyof typeof info, value: string) => {
    setInfo(prev => ({ ...prev, [key]: value }));
  };

  const getIsValid = (): boolean => {
    if (!RegexUtils.IS_ASCII(accountName)) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: t('auth.errors.teamNameNotRegistered'),
          onClickMainButton: () => {
            setIsRequesting(false);
            closeModal();
          },
        }),
      );
      refs.current.accountName?.focusWithError();
      return false;
    } else if (getEmailError(t)(email) !== null) {
      refs.current.email?.focusWithError();
      return false;
    } else if (getNameError(t)(password) !== null) {
      refs.current.password?.focusWithError();
      return false;
    }

    return true;
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!getIsValid()) return;

    try {
      blurAllInputs(refs);
      setIsRequesting(true);
      const { hasSession, data } = await authInfo.login(accountName, email, password);
      setIsRequesting(false);

      if (isRemember) {
        localStorage.setItem('spb_auth_remember_me', 'true');
        localStorage.setItem('spb_auth_account_name', accountName);
        localStorage.setItem('spb_auth_email', email);
      } else removeRememberUserInfo();

      const { session, challengeName } = data;

      if (hasSession) {
        if (challengeName === 'SOFTWARE_TOKEN_MFA') {
          routeInfo.history.push(
            `/auth/login-mfa?tenantId=${btoa(accountName)}&email=${btoa(email)}&session=${session}`,
          );
        } else {
          routeInfo.history.push(
            `/auth/invited?tenantId=${btoa(accountName)}&email=${btoa(email)}&session=${session}`,
          );
        }
      } else if (challengeName === 'MFA_SETUP') {
        routeInfo.history.push('/auth/setup-mfa');
      } else {
        const next = new URLSearchParams(location.search).get('next');
        if (next && next.indexOf('/') === 0 && next.indexOf('/auth') === -1) {
          routeInfo.history.push(next);
        } else {
          routeInfo.history.push(`/${accountName}`);
        }
      }
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: e.message,
          onClickMainButton: () => {
            closeModal();
            if (e.message === 'That email is not registered') {
              refs.current.email?.focusWithError();
            } else if (e.message === 'Wrong Password!') {
              refs.current.password?.focusWithError();
            }
          },
        }),
      );

      setIsRequesting(false);
    }
  };

  useEffect(() => {
    const next = new URLSearchParams(location.search).get('next');
    const isAWS = next?.includes('/payments/aws');

    if (isAWS) {
      dialog.show();
    }
  }, []);

  return (
    <>
      <ShortCut page="signUp" />
      <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
        <Box
          as="form"
          onSubmit={onSubmit}
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={2}
        >
          {/* form 태그안에서 엔터를 입력하면 가장 상단에잇는 button의 onclick이 실행되서 password 안에있는 suffix가 클릭되어 visible 상태가 된다. */}
          <button
            style={{
              position: 'absolute',
              visibility: 'hidden',
            }}
          ></button>
          <Title>{t('auth.title.signIn')}</Title>
          <ValidationInput
            refs={refs}
            refKey="accountName"
            validation={getAccountNameError(t)}
            value={accountName}
            onChange={e => changeInfo('accountName', e.target.value)}
            label={t('settings.profile.teamName')}
          />
          <ValidationInput
            refs={refs}
            refKey="email"
            validation={getEmailError(t)}
            value={email}
            onChange={e => changeInfo('email', e.target.value)}
            label={t('settings.profile.workEmail')}
            autoComplete="email"
          />

          <ValidationInput
            type="password"
            refs={refs}
            refKey="password"
            validation={getNameError(t)}
            value={password}
            onChange={e => changeInfo('password', e.target.value)}
            label={t('settings.profile.password')}
            autoComplete="current-password"
          />
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Label cursor="pointer">
              <Checkbox
                type="button"
                value={isRemember}
                onClick={() => setIsRemember(prev => !prev)}
              />
              <Typography color="gray-300" variant="m-regular">
                {t('auth.rememberMe')}
              </Typography>
            </Label>
            <LinkTypography
              as={Link}
              color="gray-300"
              variant="m-regular"
              {...{ to: '/auth/forgot' }}
            >
              {t('auth.messages.forgotPassword')}
            </LinkTypography>
          </Box>

          <Button
            type="submit"
            variant="strong-fill"
            color="primary"
            size="l"
            loading={isRequesting}
            loadingText={t('button.sending')}
            style={{ width: '100%' }}
          >
            {t('button.continue')}
          </Button>
        </Box>
        <Box display="flex" gap={1} width="100%" justifyContent="center" alignItems="center">
          <Typography variant="m-regular" color="gray-300">
            {t('auth.dontHaveAnAccount')}
          </Typography>
          <GoToPage to="sign_up">{t('auth.goToSignUp')}</GoToPage>
        </Box>
      </Box>
      <Dialog state={dialog}>
        <Dialog.Header>{t('awsMarketplaceLoginLandingModal.title')}</Dialog.Header>
        <Dialog.Content>
          <Typography variant="m-regular">
            {t('awsMarketplaceLoginLandingModal.description')}
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            variant="text"
            onClick={() => {
              void dialog.hide();
              routeInfo.history.push('/auth/sign_up');
            }}
          >
            {t('auth.button.signup')}
          </Button>
          <Button variant="strong-fill" color="primary" onClick={() => void dialog.hide()}>
            {t('auth.button.signin')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
