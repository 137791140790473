import React, { ComponentProps } from 'react';

import { IconButton, useLocalStorage } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';
import cn from 'classnames';

import Page from './Page';

interface Props {
  menu: JSX.Element;
  extraHeader?: JSX.Element;
  pageBoxProps?: ComponentProps<typeof Box>;
}

export default function PageWithMenu({
  menu,
  extraHeader,
  children,
  pageBoxProps,
}: React.PropsWithChildren<Props>): JSX.Element {
  const [isCollapsed, setIsCollapsed] = useLocalStorage('appMenuCollapsed', false);

  const collapseBtnProps = {
    size: 's',
    variant: 'shadow',
    onClick: () => setIsCollapsed(!isCollapsed),
  } as const;

  return (
    <div className={cn('app-layout', { 'menu-collapsed': isCollapsed })}>
      <div className="menu">
        <div className="menu-box">
          {menu}

          <div className="menu-collapse">
            <IconButton
              {...collapseBtnProps}
              color={isCollapsed ? 'primary' : 'grey'}
              icon={isCollapsed ? 'chevronDuoRight' : 'chevronDuoLeft'}
            />
          </div>
        </div>
      </div>

      <Page extraHeader={extraHeader} boxProps={pageBoxProps}>
        {children}
      </Page>
    </div>
  );
}
