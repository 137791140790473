import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Typography } from '@superb-ai/ui';

import GridTable from '../../../../../../components/pages/account/advanced/GridTable';
import SyncedLabelProjectRow from './SyncedLabelProjectRow';
import { useSyncedLabelProjectsQuery } from './synedLabelProjectQueries';

export default function SyncedLabelProject() {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { data, refetch } = useSyncedLabelProjectsQuery({ datasetId });
  const syncedLabelProjects = data?.results || [];

  return (
    <Box p={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="l-strong">
          {t('curate.dataset.settings.syncedLabelProjects')}
        </Typography>
      </Box>

      <Box overflow="auto" mt={2} style={{ maxHeight: 400 }}>
        <GridTable>
          <GridTable.Thead>
            <GridTable.Tr>
              <GridTable.Th firstLastPadding={8} width="auto">
                {t('curate.dataset.settings.projectName')}
              </GridTable.Th>
              <GridTable.Th width="76px">{t('curate.dataset.settings.images')}</GridTable.Th>
              <GridTable.Th width="160px">
                {t('curate.dataset.settings.objectTypeAndClass')}
              </GridTable.Th>
              <GridTable.Th width="80px">{t('curate.dataset.settings.selfAssign')}</GridTable.Th>
              <GridTable.Th width="100px">{t('text.lastUpdated')}</GridTable.Th>
              <GridTable.Th width="56px">{t('curate.dataset.settings.synced')}</GridTable.Th>{' '}
              <GridTable.Th width="80px">{t('curate.dataset.settings.autoSync')}</GridTable.Th>
              <GridTable.Th firstLastPadding={4} width="28px"></GridTable.Th>
            </GridTable.Tr>
          </GridTable.Thead>
          <GridTable.Tbody>
            {syncedLabelProjects.length > 0 ? (
              syncedLabelProjects.map(labelProject => (
                <SyncedLabelProjectRow
                  key={labelProject.id}
                  labelProject={labelProject}
                  refetchLabelProjects={refetch}
                />
              ))
            ) : (
              <GridTable.Tr>
                <GridTable.Td
                  style={{
                    gridColumn: 'span 8',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '24px',
                  }}
                >
                  <Typography variant="m-regular" color="gray-300">
                    {t('curate.dataset.settings.noLabelProjects')}
                  </Typography>
                </GridTable.Td>
              </GridTable.Tr>
            )}
          </GridTable.Tbody>
        </GridTable>
      </Box>
    </Box>
  );
}
