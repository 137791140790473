import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Column, useBlockLayout, useRowSelect, useTable } from 'react-table';

import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { Box, Button, Icon, IconButton, Typography } from '@superb-ai/norwegian-forest';
import { format } from 'date-fns';

import { asRGBA } from '../../../../../consts/ColorChips';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { DatasetResult } from '../../../services/DatasetService';

export default function DatasetListTable({
  datasetList,
  setDeleteModalIsOpen,
}: {
  datasetList: DatasetResult[];
  setDeleteModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const history = useHistory();
  const authInfo = useAuthInfo();
  const [anchorEl, setAnchorEl] = useState<string | null>(null);
  const [datasetNameSort, setDatasetNameSort] = useState<string>('기본');
  const [createdDateSort, setCreatedDateSort] = useState<string>('기본');

  const handleDatasetNameSort = useCallback(() => {
    if (datasetNameSort === '기본' || datasetNameSort === '내림차순')
      setDatasetNameSort('올림차순');
    else if (datasetNameSort === '올림차순') setDatasetNameSort('내림차순');
  }, [datasetNameSort]);

  const handleCreatedDateSort = useCallback(() => {
    if (createdDateSort === '기본' || createdDateSort === '내림차순')
      setCreatedDateSort('올림차순');
    else if (createdDateSort === '올림차순') setCreatedDateSort('내림차순');
  }, [createdDateSort]);

  const handleOpen = useCallback(
    (event: React.MouseEvent, id: string) => {
      event.stopPropagation();
      if (anchorEl) setAnchorEl(null);
      else setAnchorEl(id);
    },
    [anchorEl],
  );
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const data = useMemo(() => {
    return datasetList.map(dataset => {
      return {
        datasetId: dataset.id,
        datasetName: { text: dataset.name, url: dataset.thumbnailUrl },
        description: dataset.description,
        item: dataset?.imageCount?.toLocaleString(),
        slice: dataset?.sliceCount?.toLocaleString(),
        createdBy: dataset.createdBy,
        createdDate: format(new Date(dataset.createdAt), 'MMM,dd,yyyy'),
        thumbnailUrl: dataset.thumbnailUrl,
      };
    });
  }, [datasetList]);

  const columns: Column[] = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleDatasetNameSort}
          >
            <Typography
              style={{
                marginRight: '6px',
              }}
            >
              Dataset name
            </Typography>
            {datasetNameSort === '내림차순' ? (
              <Icon name="arrowDown" size="12px" />
            ) : (
              <Icon name="arrowUp" size="12px" />
            )}
          </div>
        ),
        align: 'center',
        accessor: 'datasetName',
        width: Math.round((window.innerWidth - 404) * 0.2),
        Cell: ({
          cell: {
            value: { url, text },
          },
        }: {
          cell: { value: { url: string; text: string } };
        }) => {
          return (
            <>
              {url && text ? (
                <div className="image-cell">
                  {url ? (
                    <img src={url} alt="" width={54} height={54} />
                  ) : (
                    <NoPhotoWrapper>
                      <Icon name="imageOutline" size="24px" color={['grey', 100]} />
                    </NoPhotoWrapper>
                  )}
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      maxWidth: '250px',
                    }}
                  >
                    <Typography className="image-cell-text">{text}</Typography>
                  </div>
                </div>
              ) : (
                <Typography>-</Typography>
              )}
            </>
          );
        },
      },
      {
        Header: 'Description',
        align: 'center',
        accessor: 'description',
        width: Math.round((window.innerWidth - 404) * 0.4),
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          return (
            <div className="cell">
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
      {
        Header: 'Item',
        align: 'center',
        accessor: 'item',
        width: Math.round((window.innerWidth - 404) * 0.15),
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          return (
            <div className="cell">
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
      {
        Header: 'Slice',
        align: 'center',
        accessor: 'slice',
        width: Math.round((window.innerWidth - 404) * 0.15),
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          return (
            <div className="cell">
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
      {
        Header: 'Created by',
        align: 'center',
        accessor: 'createdBy',
        width: Math.round((window.innerWidth - 404) * 0.1),
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          return (
            <div className="cell">
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
      {
        Header: () => (
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handleCreatedDateSort}
          >
            <Typography
              style={{
                marginRight: '6px',
              }}
            >
              Created Date
            </Typography>
            {createdDateSort === '내림차순' ? (
              <Icon name="arrowDown" size="12px" />
            ) : (
              <Icon name="arrowUp" size="12px" />
            )}
          </div>
        ),
        align: 'right',
        accessor: 'createdDate',
        width: 88,
        Cell: ({ cell: { value } }: { cell: { value: string } }) => {
          return (
            <div className="cell">
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
      {
        Header: '',
        align: 'left',
        accessor: 'more',
        width: 60,
        Cell: ({ row: { id } }: { row: { id: string } }) => {
          return (
            <div className="cell">
              <IconButton
                variant="text"
                color="lightgrey"
                size="l"
                onClick={e => handleOpen(e, id)}
                icon="moreVertical"
              />
              {anchorEl === id && (
                <div className="pop-over">
                  <Button variant="text-simple" color="grey" IconAdornment="arrowRight">
                    Send to Label
                  </Button>
                  <Button
                    variant="text-simple"
                    color="primary"
                    IconAdornment="trash"
                    onClick={() => setDeleteModalIsOpen(true)}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [
      anchorEl,
      handleOpen,
      datasetNameSort,
      createdDateSort,
      handleDatasetNameSort,
      handleCreatedDateSort,
      setDeleteModalIsOpen,
    ],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 50,
          Header: ({ getToggleAllRowsSelectedProps }: any) => {
            return <Checkbox color="primary" {...getToggleAllRowsSelectedProps()} />;
          },
          Cell: ({ row }: any) => {
            return (
              <Checkbox
                color="primary"
                {...(row as any).getToggleRowSelectedProps()}
                onClick={e => e.stopPropagation()}
              />
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const headerProps = (props: any, { column }: any) => getStyles(props, column.align, column.color);

  const getStyles = (props: any, align = 'left', backgroundColor = 'inherit') => [
    props,
    {
      style: {
        backgroundColor,
        textAlign: align,
      },
    },
  ];

  return (
    <Box>
      <StyledWrapper {...getTableProps()} className="table">
        <div className="headerGroup">
          {headerGroups.map(headerGroup => {
            const { key, ...otherProps } = headerGroup.getHeaderGroupProps();
            return (
              <div key={key} {...otherProps} className="tr">
                {headerGroup.headers.map(column => {
                  const { key, ...otherProps } = column.getHeaderProps(headerProps);
                  return (
                    <div key={key} {...otherProps} className="th">
                      {column.render('Header')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <div
                key={key}
                {...restRowProps}
                style={{ borderBottom: '1px solid #E8E8E8', position: 'relative' }}
                onClick={e => {
                  if (anchorEl) handleClose(e);
                  else history.push(`/${authInfo.accountName}/curate/dataset/data/${index}`);
                }}
              >
                {row.cells.map((cell, index) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <div
                      key={key}
                      {...restCellProps}
                      className={index > 0 && index < row.cells.length - 1 ? 'td' : 'edge-td'}
                      onClick={e => {
                        if (index === row.cells.length - 1) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </StyledWrapper>
    </Box>
  );
}

const StyledWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
  .headerGroup {
    font-weight: 600;
    border-bottom: 1px solid #b3b3b3;
    svg {
      cursor: pointer;
    }
  }
  .td,
  .th {
    padding: 9px;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 36px;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
    svg {
      cursor: pointer;
    }
  }
  .edge-td {
    padding: 9px;
    overflow: hidden;
    min-height: 36px;
    line-height: 28px;
    text-align: left;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
    }
  }
  .tr.row {
    &:hover,
    &.selected {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #ff625a0a;
      }
    }
  }
  .cell {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    .pop-over {
      position: absolute;
      right: 20px;
      bottom: -45px;
      width: 145px;
      height: 72px;
      z-index: 999;
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 12px;
      box-sizing: border-box;
    }
  }
  .td {
    color: #333333;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    .image-cell {
      display: flex;
      .image-cell-text {
        margin-left: 30px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
const NoPhotoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 54px;
  height: 54px;
  background-color: ${asRGBA('#000', 0.05)};
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
  }
`;
