import '@elastic/charts/dist/theme_light.css';

import {
  Chart,
  Datum,
  defaultPartitionValueFormatter,
  PartialTheme,
  Partition,
  PartitionLayout,
  Settings,
  ShapeTreeNode,
} from '@elastic/charts';
import { Layer } from '@elastic/charts/dist/chart_types/partition_chart/specs';

import { ChartDatum } from '../../../../../apps/Curate/components/datasets/dataset/analytics/charts/types';
import { arrayToLookup } from './chartUtils';

type Props = {
  data: ChartDatum[];
  theme: PartialTheme;
  // getColor: (key: any, datum?: ShapeTreeNode) => string;
};

export function getTreemapTheme(params: { color?: string }): PartialTheme {
  const { color } = params;
  return {
    chartMargins: { top: 10, left: 10, bottom: 10, right: 10 },
    background: { color: color ?? 'white' },
  };
}

const FALLBACK_DATUM_COLOR = 'orange';

const Treemap = ({ data, theme }: Props): JSX.Element => {
  const labelLookup = arrayToLookup((d: Datum) => d.key, data);

  return (
    <Chart size={{ width: '100%', height: '100%' }}>
      <Settings showLegend theme={theme} legendSize={100} />
      <Partition
        id="treemap"
        data={data}
        layout={PartitionLayout.treemap}
        valueAccessor={d => d.count}
        valueFormatter={(d: number) => `${defaultPartitionValueFormatter(d)}\u00A0`}
        layers={[
          {
            groupByRollup: (d: Datum) => d.key,
            nodeLabel: (d: Datum) => {
              return labelLookup[d].key;
            },
            fillLabel: {
              valueFormatter: (d: number) => `(${defaultPartitionValueFormatter(d)}\u00A0Labels)`,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              minFontSize: 10,
              maxFontSize: 17,
              padding: 16,
              valueFont: {
                padding: 2,
                fontWeight: 600,
              },
              maximizeFontSize: false,
            },
            shape: {
              fillColor: (d: ShapeTreeNode) => {
                return data[d.sortIndex]?.color ?? FALLBACK_DATUM_COLOR;
              },
            },
          } as Layer,
        ]}
      />
    </Chart>
  );
};

export default Treemap;
