import React, { useState } from 'react';
import { useParams } from 'react-router';

import { CloudUploadContextProps } from '../../../components/elements/newUpload/types';
import { SourceIntegrationTarget } from '../../../components/pages/account/integrations/types';
import { StateGetterSetter } from '../../../contexts/types';

export type CloudSaveMethod = 'copy' | 'link';

export type CsvUploadMethod = 'URL' | 'CSV';
export interface CloudParams {
  integrationId: string;
  prefix: string;
  assetSaveMethod: CloudSaveMethod;
  type: SourceIntegrationTarget;
  manifestFileName: string | null;
}

export interface CsvFormat {
  dataKey: string;
  url: string;
}

export type SelectedSliceOption = {
  id: string;
  name: string;
  isNew: boolean;
};

export type UploadTarget = ('image' | 'annotation')[];
const defaultUploadTarget = ['image'] as UploadTarget;

// prettier-ignore
export type UploadContextProps =
  StateGetterSetter<['selectedSlice', 'setSelectedSlice'], SelectedSliceOption | undefined> &
  StateGetterSetter<['uploadTarget', 'setUploadTarget'], UploadTarget> &
  CloudUploadContextProps &
  { isStepsCompleted: boolean; initialize: () => void ; currentSliceId?: string};

export const UploadContext = React.createContext({} as UploadContextProps);

export const UploadProvider: React.FC = ({ children }) => {
  const { sliceId: currentSliceId } = useParams<{ sliceId?: string }>();
  const [uploadTarget, setUploadTarget] = useState<UploadTarget>(defaultUploadTarget);
  const [cloudStorageType, setCloudStorageType] = useState('S3' as SourceIntegrationTarget);
  const [cloudIntegrationId, setCloudIntegrationId] = useState<string | null>(null);
  const [cloudPrefix, setCloudPrefix] = useState<string | null>(null);
  const [cloudBucketName, setCloudBucketName] = useState('');
  const [integratedCloudPrefix, setIntegratedCloudPrefix] = useState<string | null>(null);
  const [selectedSlice, setSelectedSlice] = useState<SelectedSliceOption>();

  const initialize = () => {
    setUploadTarget(defaultUploadTarget);
    setCloudStorageType('S3' as SourceIntegrationTarget);
    setCloudIntegrationId(null);
    setCloudPrefix(null);
    setCloudBucketName('');
    setIntegratedCloudPrefix(null);
    setSelectedSlice(undefined);
  };

  return (
    <UploadContext.Provider
      value={{
        initialize,
        isStepsCompleted: !!(cloudIntegrationId && cloudPrefix != null && cloudBucketName !== ''),
        cloudStorageType,
        setCloudStorageType,
        cloudIntegrationId,
        setCloudIntegrationId,
        cloudPrefix,
        setCloudPrefix,
        cloudBucketName,
        setCloudBucketName,
        integratedCloudPrefix,
        setIntegratedCloudPrefix,
        selectedSlice,
        setSelectedSlice,
        currentSliceId,
        uploadTarget,
        setUploadTarget,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadInfo = (): UploadContextProps => {
  return React.useContext(UploadContext);
};
