import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2px',
  },
  fab: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.json-upload': {
      background: '#FFF4EF',
    },
    '&.auto-label': {
      background: '#EDF1FF',
    },
    '&.failed': {
      background: '#FFEEEE',
    },
    '&.doing': {
      margin: '3px',
    },
    '&.done': {
      margin: '5px',
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

interface Props {
  type: string;
  status: string;
}

const PreLabelStatus: React.FC<Props> = ({ type, status }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getIcon = () => {
    switch (type) {
      case 'json-upload':
        return <Icon name="upload" size="12px" color="secondary" />;
      case 'auto-label':
        if (status === 'doing') {
          return (
            <>
              <Icon name="autoLabel" color="secondary" size="12px" />
              {status === 'doing' ? (
                <MUI.CircularProgress
                  size={30}
                  thickness={2}
                  className={classes.progress}
                  variant="indeterminate"
                  color="secondary"
                />
              ) : null}
            </>
          );
        }
        if (status === 'done') {
          return <Icon name="autoLabel" color="secondary" size="12px" />;
        }
        if (status === 'failed') {
          return (
            <MUI.Tooltip title={t('labels.autoLabelFiled')}>
              <span style={{ display: 'flex' }}>
                <Icon name="errorOutline" size="14px" color="primary" />
              </span>
            </MUI.Tooltip>
          );
        }
        return null;
      default:
        return null;
    }
  };

  const getColor = () => {
    switch (type) {
      case 'json-upload':
        return 'primary';
      case 'auto-label':
      default:
        return 'secondary';
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'json-upload':
        return 'Json upload';
      case 'Auto label':
      default:
        return '';
    }
  };

  return (
    <MUI.Tooltip title={getTitle()}>
      <MUI.Box className={classes.box}>
        <MUI.Box
          className={`${classes.fab} ${type} ${status}`}
          aria-label="save"
          color={getColor()}
        >
          {getIcon()}
        </MUI.Box>
      </MUI.Box>
    </MUI.Tooltip>
  );
};

export default PreLabelStatus;
