import React from 'react';
import { useParams } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useDatasetContext } from '../../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import AutoCurationButton from '../../../actionButton/AutoCurationButton';
import DeleteActionButton from '../../../actionButton/DeleteActionButton';
import GenerateImagesButton from '../../../actionButton/GenerateImagesButton';
import LinkLabelActionButton from '../../../actionButton/LinkLabelActionButton';
import PrepareDownloadButton from '../../../actionButton/PrepareDownloadButton';
import SliceActionButton from '../../../actionButton/SliceActionButton';

export default function GridActions() {
  const { selectedData, deselectedData } = useActionContext();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { totalCount } = useImageScopeContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();
  const { datasetInfo } = useDatasetContext();
  const { sliceInfo } = useSliceContext();

  return (
    <>
      <Box mx={0.5} borderRight="1px solid" borderColor="gray-200" style={{ height: '12px' }} />
      <AutoCurationButton />
      <SliceActionButton />
      <PrepareDownloadButton
        imageTotalCount={totalCount}
        selectedData={selectedData}
        deselectedData={deselectedData}
        datasetId={datasetId}
        datasetName={datasetInfo.name}
        queryString={queryStringWithHiddenFilterAndDeselection}
        sliceId={sliceInfo?.id}
        sliceName={sliceInfo?.name}
        filter={
          clusterLevel && appliedFilters
            ? {
                cluster_id_in: appliedFilters?.cluster_id_in || [],
                cluster_level: clusterLevel,
              }
            : undefined
        }
      />
      <GenerateImagesButton />
      <LinkLabelActionButton />
      <DeleteActionButton />
    </>
  );
}
