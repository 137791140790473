import React, { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router';

import { ImageAlt, LinkExternal, Pencil } from '@superb-ai/icons';
import {
  Box,
  Checkbox,
  Icon,
  IconButton,
  LinkTypography,
  ToggleButton,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { capitalize } from 'lodash';

import GridTable from '../../../../../../components/pages/account/advanced/GridTable';
import { getUrl } from '../../../../../../routes/util';
import { ProjectData } from '../../../../../../types/projectTypes';
import { formatDate, parseDate } from '../../../../../../utils/date';
import { MANUAL } from '../modal/SyncWithLabel/AnnotationTypeAndClass/type';
import AutoSyncSettingDialog from './AutoSyncSettingDialog';
import SyncedLabelEditDialog from './SyncedLabelEditDialog';
import SyncedSettingDialog from './SyncedSettingDialog';

const GridStyledTd = ({ children }: { children: ReactNode }) => {
  return (
    <GridTable.Td px={1} style={{ display: 'block' }}>
      <Typography style={{ width: '100%' }} variant="m-regular" color={'gray-400'}>
        {children}
      </Typography>
    </GridTable.Td>
  );
};

export default function SyncedLabelProjectRow({
  labelProject,
  refetchLabelProjects,
}: {
  labelProject: ProjectData;
  refetchLabelProjects: () => void;
}) {
  const history = useHistory();
  const { accountName } = useParams<{ accountName: string }>();
  const { id, name, lastUpdatedAt, labelCount, curateSyncSettings, settings, labelInterface } =
    labelProject;
  const editDialogState = useDialogState();
  const syncSettingDialogState = useDialogState();
  const autoSyncSettingDialogState = useDialogState();

  return (
    <>
      <GridTable.Tr hoverBackgroundColor="gray-100">
        <GridStyledTd>
          <Box
            display="flex"
            gap={0.5}
            pr={1}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            <Icon icon={ImageAlt} style={{ marginTop: '4px' }} />
            <LinkTypography
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              color="gray-400"
              display="flex"
              gap={0.5}
              onClick={() => history.push(getUrl([accountName, 'label', 'project', id]))}
            >
              {name}
              <Icon icon={LinkExternal} style={{ marginTop: '4px' }} />
            </LinkTypography>
          </Box>
        </GridStyledTd>
        <GridStyledTd>{labelCount}</GridStyledTd>
        <GridStyledTd>
          {labelInterface.objectDetection.objectClasses.map((objectClass: any) => (
            <Typography
              key={objectClass.id}
              variant="m-regular"
              display="block"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              • {capitalize(objectClass.annotationType)} | {objectClass.name}{' '}
              {objectClass.properties.length > 0 && `(${objectClass.properties.length} properties)`}
            </Typography>
          ))}
        </GridStyledTd>
        <GridStyledTd>
          {settings.numMaxSelfAssign === undefined && settings.numMaxSelfReviewAssign === undefined
            ? 'on'
            : 'off'}
        </GridStyledTd>
        <GridStyledTd>{formatDate(parseDate(lastUpdatedAt, true))}</GridStyledTd>
        <GridStyledTd>
          <ToggleButton
            checked={curateSyncSettings.isActive}
            onClick={() => syncSettingDialogState.show()}
          />
        </GridStyledTd>
        <GridStyledTd>
          <Checkbox
            disabled={!curateSyncSettings.isActive}
            value={curateSyncSettings?.operation !== MANUAL}
            onClick={() => autoSyncSettingDialogState.show()}
          />
        </GridStyledTd>
        <GridStyledTd>
          <IconButton
            disabled={!curateSyncSettings.isActive}
            variant="text"
            size="s"
            icon={Pencil}
            onClick={() => editDialogState.show()}
          />
        </GridStyledTd>
      </GridTable.Tr>
      <SyncedLabelEditDialog
        state={editDialogState}
        labelProject={labelProject}
        refetchLabelProjects={refetchLabelProjects}
      />
      <SyncedSettingDialog
        state={syncSettingDialogState}
        labelProject={labelProject}
        refetchLabelProjects={refetchLabelProjects}
      />
      <AutoSyncSettingDialog
        state={autoSyncSettingDialogState}
        labelProject={labelProject}
        refetchLabelProjects={refetchLabelProjects}
      />
    </>
  );
}
