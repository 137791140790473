import { History } from 'history';
import qs from 'qs';

import { GRID_VIEW, IMAGE_SCOPE, SCOPE, ScopeMode, VIEW, ViewMode } from '../types/viewTypes';

export function getSearchQueryRoute(
  history: History,
  newSearchQuery: Record<string, string | number | undefined | null>,
  newPath?: string,
) {
  const searchQueries = qs.parse(history.location.search.split('?')[1]);
  return `${newPath || history.location.pathname}?${qs.stringify({
    ...searchQueries,
    ...newSearchQuery,
  })}`;
}

export function getQueryParams(history: History) {
  const params = new URLSearchParams(history.location.search);
  return Object.fromEntries(params);
}

export function getScopeFromRoute(history: History) {
  const searchParams = new URLSearchParams(history.location.search);
  const scope = (searchParams.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;
  return scope;
}

export function routeView(history: History, value: ViewMode) {
  history.push(getSearchQueryRoute(history, { view: value }));
}

export function getView(history: History): ViewMode {
  const params = new URLSearchParams(history.location.search);
  return (params.get(VIEW) || GRID_VIEW) as ViewMode;
}

export function getNumConvertedParams(defaultValue: number, param?: string) {
  if (!param) return defaultValue;
  const num = Number(param);
  if (Number.isNaN(num)) {
    return defaultValue;
  }
  return num;
}
