import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import getErrorConst from '../../../consts/ErrorConst';
import { useErrorInfo } from '../../../contexts/ErrorContext';
import { StateGetterSetter } from '../../../contexts/types';
import { sleep } from '../../../utils/SpbUtils';
import { DatasetResult, useCurateDatasetService } from '../services/DatasetService';
import { usePublicDatasetContext } from './PublicDatasetContextProvider';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['sliceInfo', 'setSliceInfo'], DatasetResult | undefined> &
  StateGetterSetter<['isLoading', 'setIsLoading'], boolean>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const { t } = useTranslation();
  const { getSlice } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const [sliceInfo, setSliceInfo] = useState<DatasetResult>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { datasetId, sliceId } = useParams<{ datasetId: string; sliceId: string }>();

  const { setError } = useErrorInfo();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (sliceId && datasetId) {
          const sliceInfo = await getSlice({
            datasetId,
            fromPublicDatasets: showPublicDatasets,
            sliceId,
            expand: ['image_count'],
          });
          setSliceInfo(sliceInfo);
        } else {
          await sleep(100);
        }
      } catch (err: any) {
        if (err?.message === getErrorConst(t).NO_PUBLIC_API) {
          setError(err.message);
        }
      }
      setIsLoading(false);
    })();

    return () => {
      setSliceInfo(undefined);
    };
  }, [sliceId]);

  return {
    sliceId,
    sliceInfo,
    setSliceInfo,
    isLoading,
    setIsLoading,
  };
};

export const useSliceContext = (): ContextProps => {
  return React.useContext(Context);
};

export const SliceProvider: React.FC = ({ children }) => {
  const datasetInfo = useProvider();
  return <Context.Provider value={datasetInfo}>{children}</Context.Provider>;
};
