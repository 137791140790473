import React, { useState } from 'react';

import { Grid2X2Big, Grid3X3 } from '@superb-ai/icons';
import { Box, Icon, IconButtonWithTriangle, Popover, Range } from '@superb-ai/ui';

export default function GridColumnsRange({
  isPopoverMode,
  minColumns,
  maxColumns,
  defaultColumns,
  columnsCount,
  setColumnsCount,
}: {
  isPopoverMode: boolean;
  minColumns: number;
  maxColumns: number;
  defaultColumns: number;
  columnsCount: number;
  setColumnsCount: (value: number) => void;
}) {
  const RANGE_WIDTH = '152px';
  const [openColumnsRange, setOpenColumnsRange] = useState(false);

  const ColumnsRange = (
    <Range
      min={minColumns}
      max={maxColumns}
      value={columnsCount}
      maxSymbol={<Icon icon={Grid3X3} />}
      minSymbol={<Icon icon={Grid2X2Big} />}
      onChange={e => {
        setColumnsCount(Number(e.target?.value || defaultColumns));
      }}
    />
  );

  if (isPopoverMode) {
    return (
      <Popover
        open={openColumnsRange}
        hideOnEsc
        hideOnClickOutside
        style={{ zIndex: 1000 }}
        onClose={() => {
          setOpenColumnsRange(false);
        }}
        aria-label="profile-menu"
        disclosure={
          <IconButtonWithTriangle icon={Grid3X3} variant="text" style={{ marginLeft: 'auto' }} />
        }
      >
        <Box
          backgroundColor="white"
          padding={1}
          style={{ width: RANGE_WIDTH }}
          display="flex"
          flexDirection="column"
          borderRadius="2px"
          boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
        >
          {ColumnsRange}
        </Box>
      </Popover>
    );
  }

  return (
    <Box style={{ width: RANGE_WIDTH }} ml="auto">
      {ColumnsRange}
    </Box>
  );
}
