export function buildDatasetQueryEndpoint(path: string, fromPublicDatasets: boolean): string {
  return `/curate/dataset-query/${fromPublicDatasets ? 'public-' : ''}datasets/${path}`;
}
export function buildDatasetAnalyticsEndpoint(
  datasetId: string,
  path: string,
  fromPublicDatasets: boolean,
): string {
  return `/curate/dataset-analytics/${
    fromPublicDatasets ? 'public-' : ''
  }datasets/${datasetId}${path}`;
}
