import { addDays, isBefore, isEqual } from 'date-fns';

import { parseDate } from '../../../../../utils/date';

export function getIsExpired(expirationDate: string | null | undefined) {
  return expirationDate ? isBefore(parseDate(expirationDate), new Date()) : false;
}

export function getIsExpiring(
  expirationDate: string | null | undefined,
  daysBeforeExpiration: number,
): boolean {
  const today = new Date();
  const targetDate = addDays(today, daysBeforeExpiration);

  return (
    isBefore(parseDate(expirationDate), targetDate) ||
    isEqual(parseDate(expirationDate), targetDate)
  );
}

export function getIsExpiredOrExpiring(
  expirationDate: string | null | undefined,
  daysBeforeExpiration: number,
): boolean {
  return expirationDate
    ? getIsExpired(expirationDate) || getIsExpiring(expirationDate, daysBeforeExpiration)
    : false;
}
