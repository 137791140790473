import React, { useEffect, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '8px',
  },
}));

export default (props: { toastState: string }): JSX.Element | null => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { toastState } = props;
  const [curIcon, setCurIcon] = useState<JSX.Element | null>(null);

  const getIcon = () => {
    switch (toastState) {
      case 'init':
        return <MUI.CircularProgress color="primary" className={classes.icon} size="15px" />;
      case 'endProgress':
        return <Icon name="check" color="secondary" className={classes.icon} size="18px" />;
      case 'cancel':
        return <Icon name="errorOutline" color="primary" className={classes.icon} size="18px" />;
      case 'failed':
        return <Icon name="errorOutline" color="primary" className={classes.icon} size="18px" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const nextIcon = getIcon();
    if (!nextIcon) return;
    setCurIcon(getIcon());

    // eslint-disable-next-line
  }, [toastState]);

  return curIcon;
};
