import { CreateMenuItem } from '../../menu/MenuItem';
import Layout from './Layout';
import { ProjectsListMenuItem } from './list/MenuItem';
import { DataListMenuItem } from './project/data/list/MenuItem';
import { ProjectMenuItem } from './project/MenuItem';

export const ProjectsMenuItem = CreateMenuItem({
  // name: 'label-dashboard', // not tracked
  path: 'project',
  title: ({ t }) => t('projects.title'),
  component: Layout,
  children: [ProjectsListMenuItem, ProjectMenuItem, DataListMenuItem],
  icon: 'dashboard',
});
