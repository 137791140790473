import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { Box, Input, Typography } from '@superb-ai/norwegian-forest';

type Props = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
};

export default function MislabelDetectionNameInput({ name, setName }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.select();
  }, []);

  return (
    <Box display="flex" alignItems="center" gap="8px" width={500}>
      <Typography variant="headline7">Name</Typography>
      <Box flex={1}>
        <Input
          ref={inputRef}
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
          autoFocus
        />
      </Box>
    </Box>
  );
}
