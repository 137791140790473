import { Box, Typography } from '@superb-ai/ui';

import { FORMAT_COUNT } from '../../../../../../../components/pages/analytics/config/d3Formatters';

type CellProps = {
  width: string;
  count: number;
  comparedCount?: number;
};
export function CountCell({ width, count, comparedCount }: CellProps) {
  return (
    <Box
      style={{ width, textAlign: 'right' }}
      display="flex"
      flexDirection="column"
      gap={0.5}
      justifyContent="flex-end"
    >
      <Typography variant="m-medium">{FORMAT_COUNT(count)}</Typography>{' '}
      {comparedCount && <Typography variant="m-medium">{FORMAT_COUNT(comparedCount)}</Typography>}
    </Box>
  );
}
