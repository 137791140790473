import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Box, ExternalLink, Typography } from '@superb-ai/ui';

export default function AutoLabelGuide() {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();

  return (
    <Box
      border="1px solid"
      borderColor="secondary-400"
      backgroundColor="secondary-100"
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      gap={1}
      p={3}
      width="100%"
      boxShadow="4px 6px 14px rgba(0, 0, 0, 0.14)"
    >
      <Typography variant="h3" color="secondary-400">
        Auto-Label {t('overview.autoLabel.yourDataProject')} ⚡
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        <Typography variant="m-regular" color="gray-400">
          {t('overview.autoLabel.unlockAutoLabel')}
        </Typography>
        <Box display="flex" gap={0.5} ml="auto" alignItems="center" cursor="pointer">
          <Link to={`/${accountName}/label/plan`}>
            <ExternalLink>{t('autoLabel.cal.learnMore')}</ExternalLink>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
