import { Diagnosis } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../analyticsTracker/pageIds';
import { canManageProject } from '../../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../../menu';
import ModelDiagnosisRoute from './Route';

export const MODEL_DIAGNOSIS_PATH = 'model-diagnosis';

export const ModelDiagnosisMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetModelDiagnosis,
  path: MODEL_DIAGNOSIS_PATH,
  icon: <Icon icon={Diagnosis} />,
  title: ({ t }) => t('curate.dataset.menus.modelDiagnosis'),
  component: ModelDiagnosisRoute,
  isVisible({ authInfo }) {
    const authValid = canManageProject(authInfo) || false;
    return authValid;
  },
  hasLowerLevelPage: true,
});
