import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';

import { PAGE_NOT_FOUND } from '../consts/SnackbarMessage';
import AnalyticsTracker from '../analyticsTracker';
import LabelsService from '../services/LabelsService';
import { LabelData } from '../types/labelTypes';
import UserRoleUnion from '../union/UserRoleUnion';
import WorkappUnion from '../union/WorkappUnion';
import ParamUtils from '../utils/ParamUtils';
import { useSearchParams } from '../utils/router-utils';
import { useAuthInfo } from './AuthContext';
import { useFeatureFlag } from './FeatureFlagContext';
import { useProjectInfo } from './ProjectContext';
import { useRouteInfo } from './RouteContext';
import { StateGetterSetter } from './types';

interface CursorInfo {
  prev: [number, string] | null;
  next: [number, string] | null;
}

type LabelsContextProps = StateGetterSetter<['labels', 'setLabels'], LabelData[]> &
  StateGetterSetter<['rootLabels', 'setRootLabels'], LabelData[]> &
  StateGetterSetter<['checkedLabels', 'setCheckedLabels'], string[]> &
  StateGetterSetter<['expandedLabels', 'setExpandedLabels'], LabelData[]> &
  StateGetterSetter<['loadingLabels', 'setLoadingLabels'], LabelData[]> &
  StateGetterSetter<
    ['relatedLabelsByAssetId', 'setRelatedLabelsByAssetId'],
    Record<string, LabelData[]>
  > &
  StateGetterSetter<['imageUrlsByAssetId', 'setImageUrlsByAssetId'], Record<string, string>> &
  StateGetterSetter<['totalCount', 'setTotalCount'], number> &
  StateGetterSetter<['totalUniqueCount', 'setTotalUniqueCount'], number> &
  StateGetterSetter<['isLoadingLabels', 'setIsLoadingLabels'], boolean> &
  StateGetterSetter<['isAllLabelsChecked', 'setIsAllLabelsChecked'], boolean> &
  StateGetterSetter<['isDesc', 'setIsDesc'], boolean> &
  StateGetterSetter<['orderBy', 'setOrderBy'], string> &
  StateGetterSetter<['cursorInfo', 'setCursorInfo'], CursorInfo> &
  StateGetterSetter<['loadingFinishedAt', 'setLoadingFinishedAt'], Date | null> & {
    updateLabels(params: any, projectId: string): Promise<{ labels: LabelData[]; count: number }>;
    resetLabels(): void;
    getLabels(): Promise<{ labels: LabelData[]; count: number }>;
    getRelatedLabels(rootLabels: LabelData[]): Promise<Map<LabelData, LabelData[]>>;
  };

export const LabelsContext = createContext({} as LabelsContextProps);

export const LabelsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const urlSearchParams = useSearchParams();
  const {
    history,
    urlMatchInfo: { accountName, projectId },
  } = routeInfo;
  const { project, tagIds } = projectInfo;

  const [labels, setLabels] = useState<LabelData[]>([]);
  const [rootLabels, setRootLabels] = useState<LabelData[]>([]);
  const [expandedLabels, setExpandedLabels] = useState<LabelData[]>([]);
  const [loadingLabels, setLoadingLabels] = useState<LabelData[]>([]);
  const [relatedLabelsByAssetId, setRelatedLabelsByAssetId] = useState<Record<string, LabelData[]>>(
    {},
  );
  const [imageUrlsByAssetId, setImageUrlsByAssetId] = useState<Record<string, string>>({});
  const [totalCount, setTotalCount] = useState(0);
  const [totalUniqueCount, setTotalUniqueCount] = useState(0);
  const [isDesc, setIsDesc] = useState(true);
  const [orderBy, setOrderBy] = useState('');
  const [checkedLabels, setCheckedLabels] = useState<string[]>([]);
  const [isLoadingLabels, setIsLoadingLabels] = useState(true);
  const [loadingFinishedAt, setLoadingFinishedAt] = useState<Date | null>(null);
  const [isAllLabelsChecked, setIsAllLabelsChecked] = useState(false);
  const [cursorInfo, setCursorInfo] = useState<CursorInfo>({ prev: null, next: null });

  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const resetLabels = () => {
    setLabels([]);
    setRootLabels([]);
    setExpandedLabels([]);
    setLoadingLabels([]);
    setTotalCount(0);
    setTotalUniqueCount(0);
    setCursorInfo({ prev: null, next: null });
  };

  useEffect(() => {
    // Update visibleLabels
    const visibleLabels = rootLabels.flatMap((label: LabelData) =>
      expandedLabels.indexOf(label) !== -1
        ? [
            label,
            ...relatedLabelsByAssetId[label.asset.id].filter(
              relatedLabel => relatedLabel.id !== label.id,
            ),
          ]
        : [label],
    );

    setLabels(visibleLabels);
  }, [rootLabels, expandedLabels, relatedLabelsByAssetId]);

  const updateLabels = async (params: any, projectId: string) => {
    resetLabels();
    const callArgs = {
      t,
      params,
      projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
      origin: 'contexts/LabelContext.jsx',
    };
    // Get root labels
    if (enabledLoki) {
      const { results, count, prev, next } = await LabelsService.getLabelsV2(callArgs);

      let labelCount = count;
      if (params.groupAssets) {
        // Get total count without grouping
        callArgs.params.groupAssets = false;
        labelCount = await LabelsService.getLabelCountsV2(callArgs);
      }

      setTotalCount(labelCount);
      setRootLabels(results);
      setTotalUniqueCount(count);
      setCursorInfo({ prev, next });

      setLoadingFinishedAt(new Date());

      return { labels: results, count, totalCount: labelCount };
    } else {
      const { results, count } = await LabelsService.getLabels(callArgs);

      let labelCount = count;
      if (params.groupAssets) {
        // Get total count without grouping
        callArgs.params.groupAssets = false;
        labelCount = await LabelsService.getLabelCounts(callArgs);
      }

      setTotalCount(labelCount);
      setRootLabels(results);
      setTotalUniqueCount(count);

      setLoadingFinishedAt(new Date());

      return { labels: results, count, totalCount: labelCount };
    }
  };

  const updateLabelImageUrls = async (projectId: string, labels: LabelData[]) => {
    const labelImages = await LabelsService.getSignedUrl({
      projectId,
      labels,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    const nextImageUrlsByAssetId: Record<string, string> = {};
    for (let i = 0; i < labelImages.length; i++) {
      nextImageUrlsByAssetId[labels[i].asset.id] = labelImages[i];
      const headers: Record<string, string> = {
        mode: 'cors',
        credentials: 'omit',
      };
      // Hotfix -> Temporary Presigned url check
      if (
        !labelImages[i].includes('suite-asset.superb-ai.com') &&
        !labelImages[i].includes('stage-saba-asset.superb-ai.com') &&
        !labelImages[i].includes('suite-asset.dev.superb-ai.com')
      ) {
        headers.cache = 'no-cache';
      }

      fetch(labelImages[i], headers)
        .then(() => {
          const image = new Image();
          image.crossOrigin = 'Anonymous';
          image.src = labelImages[i];
        })
        // eslint-disable-next-line
        .catch(err => {});
    }
    setImageUrlsByAssetId(nextImageUrlsByAssetId);
  };

  const getLabels = async () => {
    if (!projectId) return { labels: [], count: 0 };
    const { allowAdvancedQa } = projectInfo.project.settings;

    setIsLoadingLabels(true);
    setCheckedLabels([]);

    const filterApiParams = ParamUtils.getApiParamsForFilter({
      tagIds,
      workApp: projectInfo.project.workapp,
      filterParams: enabledLoki
        ? urlSearchParams.filter(([filterKey]) => filterKey !== 'page')
        : urlSearchParams,
    });

    if (allowAdvancedQa) {
      filterApiParams.groupAssets = true;
    }
    const sortParam = routeInfo?.params?.ordering ? { ordering: routeInfo?.params?.ordering } : {};

    if (filterApiParams.workAssigneeIn && UserRoleUnion.isWorker(authInfo.projectRole)) {
      history.push(`/${accountName}/project/${projectId}/labels`);
    }

    try {
      const nextLabels = await updateLabels({ ...filterApiParams, ...sortParam }, projectId);
      setIsLoadingLabels(false);

      if (!WorkappUnion.isSiesta(project.workapp)) {
        await updateLabelImageUrls(projectId, nextLabels.labels);
      }

      if (Object.keys(filterApiParams).length > 0) {
        AnalyticsTracker.labelListFiltered({
          accountId: accountName,
          labelCount: nextLabels.totalCount,
          filterBy: Object.keys(filterApiParams),
          filters: Object.entries(filterApiParams),
        });
      }

      return nextLabels;
    } catch (err: any) {
      if (err.message === 'Not Found') {
        // TODO (shko): Fix when implementing error rules
        enqueueSnackbar(PAGE_NOT_FOUND({ t }), { variant: 'warning' });
      }
      setIsAllLabelsChecked(false);
      setIsLoadingLabels(false);
      return { labels: [], count: 0 };
    }
  };

  async function getRelatedLabels(rootLabels: LabelData[]): Promise<Map<LabelData, LabelData[]>> {
    const wasGlobalChecked = labels.length === checkedLabels.length;

    const assetIds = [...new Set(rootLabels.map(label => label.asset.id))];

    const filterApiParams = ParamUtils.getApiParamsForFilter({
      filterParams: urlSearchParams,
      tagIds,
      workApp: projectInfo.project.workapp,
    });
    filterApiParams.assetIn = assetIds;
    filterApiParams.labelTypeIn = ['CONSENSUS'];
    if (!enabledLoki) filterApiParams.page = 1;
    filterApiParams.pageSize = 100; // max 10x10
    const sortParam = routeInfo?.params?.ordering ? { ordering: routeInfo?.params?.ordering } : {};

    setLoadingLabels([...loadingLabels, ...rootLabels]);
    try {
      let results: LabelData[] | undefined;
      if (enabledLoki) {
        results = (
          await LabelsService.getLabelsV2({
            params: { ...filterApiParams, ...sortParam },
            projectId,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          })
        ).results;
      } else {
        results = (
          await LabelsService.getLabels({
            params: { ...filterApiParams, ...sortParam },
            projectId,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          })
        ).results;
      }
      const labelsByAssetId = groupBy(results, label => label.asset.id) as Record<
        string,
        LabelData[]
      >;
      const returnMap = new Map<LabelData, LabelData[]>();
      for (const [assetId, relatedLabels] of Object.entries(labelsByAssetId)) {
        relatedLabelsByAssetId[assetId] = relatedLabels;
        const rootLabel = rootLabels.find(label => label.asset.id === assetId);
        if (rootLabel) {
          returnMap.set(rootLabel, relatedLabels);
        }
      }
      setRelatedLabelsByAssetId(relatedLabelsByAssetId);
      if (wasGlobalChecked) {
        // Add loaded related labels to checkedLabels
        setCheckedLabels(
          Array.from(new Set([...checkedLabels, ...results.map((label: LabelData) => label.id)])),
        );
      }
      return returnMap;
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(PAGE_NOT_FOUND({ t }), { variant: 'warning' });
      }
    } finally {
      setLoadingLabels(loadingLabels.filter(label => rootLabels.indexOf(label) === -1));
    }
    return new Map();
  }

  useEffect(() => {
    const text = isAllLabelsChecked || checkedLabels.length === 0 ? 'all' : 'selected';
    window.CommandBar?.addContext('labelSelectText', text);
    return () => {
      window.CommandBar?.removeContext('labelSelectText');
    };
  }, [isAllLabelsChecked, checkedLabels]);

  return (
    <LabelsContext.Provider
      value={{
        updateLabels,
        resetLabels,
        getLabels,
        labels,
        setLabels,
        rootLabels,
        setRootLabels,
        imageUrlsByAssetId,
        setImageUrlsByAssetId,
        totalCount,
        totalUniqueCount,
        setTotalCount,
        setTotalUniqueCount,
        checkedLabels,
        setCheckedLabels,
        isLoadingLabels,
        setIsLoadingLabels,
        loadingFinishedAt,
        setLoadingFinishedAt,
        isAllLabelsChecked,
        setIsAllLabelsChecked,
        getRelatedLabels,
        relatedLabelsByAssetId,
        setRelatedLabelsByAssetId,
        expandedLabels,
        setExpandedLabels,
        loadingLabels,
        setLoadingLabels,
        isDesc,
        setIsDesc,
        orderBy,
        setOrderBy,
        cursorInfo,
        setCursorInfo,
      }}
    >
      {children}
    </LabelsContext.Provider>
  );
};

export const useLabelsInfo = (): LabelsContextProps => {
  return useContext(LabelsContext);
};
