/**
 * Filters have 3 formats:
 * 1. URL param ([string, string]) - for URL query
 * 2. API params ([string, string][]) - for fetching API
 * 3. Filter value ({ filterBy, condition, options }) - for rendering
 *
 * Options have 2 formats:
 * 1. Flat values - for URL query and API - e.g. [tag1, tag2]
 * 2. Structured values - for rendering - e.g. [{ value: tag1 }, { value: tag2 }] or other depending on render component
 */

export type PossibleOptionValue = string | number | string[] | number[] | Date;
export type PossibleApiValue = string | number | string[] | number[] | boolean;

export type FilterValue<
  FilterBy extends string = string,
  ConditionValue extends string = string,
  Value = unknown,
> = {
  filterBy: FilterBy;
  condition: ConditionValue;
  options?: Value[];
};

export type ToUrlParamFn<
  ConditionValue extends string = string,
  RenderOptionValue = unknown,
> = (value: { condition: ConditionValue; options?: RenderOptionValue[] }) => string | null;
export type FromUrlParamFn<
  ConditionValue extends string = string,
  RenderOptionValue = unknown,
> = (value: {
  condition: ConditionValue;
  options?: PossibleOptionValue[];
}) => { condition: ConditionValue; options?: RenderOptionValue[] } | undefined;
export type ToApiParamsFn<
  ConditionValue extends string = any,
  OptionValue = any,
  ApiKey = any,
  ApiValue = any,
  Meta extends Record<string, any> = any,
> = (
  value: { condition: ConditionValue; options?: OptionValue[] },
  meta?: Meta,
) => [ApiKey, ApiValue][] | undefined | null;
export type FromApiParamsFn<
  ConditionValue extends string = any,
  OptionValue = any,
  Meta extends Record<string, any> = any,
> = (
  apiParams: [string, PossibleApiValue][],
  meta: Meta,
) => { condition: ConditionValue; options?: OptionValue[] }[] | undefined | null;

export type Filter<
  Key extends string = string,
  ConditionValue extends string = string,
  OptionValue extends PossibleOptionValue = PossibleOptionValue,
  RenderOptionValue = unknown,
  ApiKey extends string = string,
  ApiValue extends PossibleApiValue = PossibleApiValue,
> = {
  key: Key;
  conditions?: ConditionValue[];
  options?: OptionValue[];
  toApiParams: ToApiParamsFn<ConditionValue, OptionValue, ApiKey, ApiValue>;
  fromApiParams: FromApiParamsFn<ConditionValue, OptionValue>;
  toUrlParam?: ToUrlParamFn<ConditionValue, RenderOptionValue>;
  fromUrlParam?: FromUrlParamFn<ConditionValue, RenderOptionValue>;
};

/**
 * This function doesn't do anything except enforcing typing while also allowing for type inference.
 */
export function createFilter<
  Key extends string,
  ConditionValue extends string,
  OptionValue extends PossibleOptionValue,
  RenderOptionValue,
  ApiKey extends string,
  ApiValue extends PossibleApiValue,
>(filter: Filter<Key, ConditionValue, OptionValue, RenderOptionValue, ApiKey, ApiValue>) {
  return filter;
}
