import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
    '&.dragEntered': {
      background: '#fff6f6',
      opacity: 0.5,
    },
  },
}));

// eslint-disable-next-line
const ClassGroupItemDropPanel = (props: any): React.ReactElement | null => {
  const classes = useStyles();
  const { classGroup } = props;
  const newProjectInfo = useNewProjectInfo();
  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.type !== 'objectClass' ||
    newProjectInfo.dragState.id === classGroup.id
  )
    return null;

  const handleDragEnter = () => {
    setIsDragEnter(true);
  };

  const handleDragLeave = () => {
    setIsDragEnter(false);
  };

  const handleDrop = () => {
    const ids = Object.keys(newProjectInfo.selectedObjectClassIds);
    helper.moveObjectClassesToGroup(newProjectInfo, ids, classGroup.id, null);
    newProjectInfo.setDragState(null);
    setIsDragEnter(false);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${isDragEnter ? 'dragEntered' : ''} `}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default ClassGroupItemDropPanel;
