import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, map } from 'lodash';
import { useSnackbar } from 'notistack';

import { PROPERTY_TYPE } from '../../../../consts/NewProjectConst';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';

const useStyles = makeStyles(() => ({
  text: {
    color: '#4a4a4a',
    fontSize: '11px',
    display: 'flex',
  },
  typeSelect: {
    width: '100%',
    borderBottom: 'solid 0.5px #9b9b9b ',
    overflow: 'hidden',
    borderRadius: '5px 5px 0px 0px',
    '& .MuiSelect-selectMenu': {
      color: '#4a4a4a',
      fontSize: '11px',
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 0px',
    },
  },
}));

// eslint-disable-next-line
const ImageCategoryItemSelect = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { imageCategory, index } = props;

  const handleChangeSelect = (e: any) => {
    if (newProjectInfo.selectedDataType === 'image') {
      enqueueSnackbar('You can only use multiple selection type using current image tool', {
        variant: 'warning',
      });
      return;
    }
    e.stopPropagation();

    const { value } = e.target;

    if (value === imageCategory.type) return;

    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    nextImageCategories[index].type = value;
    newProjectInfo.setImageCategories(nextImageCategories);
  };

  if (newProjectInfo.selectedDataType === 'image') {
    return (
      <MUI.Typography className={classes.text}>
        {t(PROPERTY_TYPE['MULTIPLE_SELECTION'].label)}{' '}
      </MUI.Typography>
    );
  }

  return (
    <MUI.Select
      variant="standard"
      className={classes.typeSelect}
      value={imageCategory.type}
      onChange={handleChangeSelect}
    >
      {map(Object.values(PROPERTY_TYPE), option => (
        <MUI.MenuItem key={option.value} value={option.value}>
          {t(option.label)}
        </MUI.MenuItem>
      ))}
    </MUI.Select>
  );
};

export default ImageCategoryItemSelect;
