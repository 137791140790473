import { useMemo } from 'react';

import styled from '@emotion/styled';
import { Clear } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import {
  IAdvancedQuery,
  IQuery,
  ISchema,
  ISelectedQuery,
  ITotalSchema,
} from '../../../../types/querySchemaTypes';

export default function QuerySchemasKeyMenu({
  hoverButtonList,
  boxNum,
  index,
  setHoverButtonList,
  list,
  deleteQuery,
  selectedQueryList,
  setSelectedQueryList,
  isSelected = false,
  updateQueryContent,
  totalSchemas,
}: {
  hoverButtonList: string[];
  boxNum: number;
  index: number;
  setHoverButtonList: React.Dispatch<React.SetStateAction<string[]>>;
  list: IAdvancedQuery | IQuery;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  totalSchemas: ITotalSchema;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
  isSelected?: boolean;
}) {
  const schemasList = useMemo(() => {
    const queryType = selectedQueryList.filter(
      list => list.boxNum === boxNum.toString() + (index + 1),
    )[0].queryType;

    if (!totalSchemas) return [];
    const filteredList = Object.fromEntries(
      Object.entries(totalSchemas).filter(([key]) => key === queryType),
    );
    if (typeof filteredList[`${queryType}`] === 'string') return [];
    function traverseAndFlatten(
      currentNode: ISchema,
      target: ISchema,
      flattenedKey?: string | undefined | null,
    ) {
      for (const key in currentNode) {
        if (currentNode.hasOwnProperty(key)) {
          let newKey;
          if (flattenedKey === undefined) {
            newKey = key;
          } else {
            newKey = flattenedKey + '.' + key;
          }

          const value = currentNode[key];
          if (typeof value === 'object') {
            traverseAndFlatten(value, target, newKey);
          } else {
            target[newKey] = value;
          }
        }
      }
    }

    function flatten(obj: ISchema) {
      const flattenedObject = {};
      traverseAndFlatten(obj, flattenedObject);
      return flattenedObject;
    }

    const flattened = JSON.stringify(flatten(filteredList[`${queryType}`]));
    return [...Object.entries(JSON.parse(flattened))];
  }, [selectedQueryList, totalSchemas, boxNum, index]);

  const schemasListLength = useMemo(
    () =>
      schemasList && schemasList.length
        ? [...schemasList].sort((a, b) => b[0].length - a[0].length)[0][0].length
        : 0,
    [schemasList],
  );

  return (
    <>
      {schemasList.length === 0 ? (
        <></>
      ) : (
        <DropdownMenu
          width={schemasListLength > 21 ? schemasListLength * 7 : 152}
          AnchorElement={
            <Button style={{ padding: 0, border: 'none' }} variant="text">
              {isSelected ? (
                <>
                  {selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  )[0].queryKey?.length ? (
                    <Box
                      style={{
                        padding: '5px 8px',
                        background: '#FFFFFF',
                        boxSizing: 'border-box',
                        borderTop: '1px solid #DCDCDC',
                        borderBottom: '1px solid #DCDCDC',
                        cursor: 'pointer',
                        minWidth: '36px',
                      }}
                    >
                      <Typography>
                        {`${
                          selectedQueryList.filter(
                            list => list.boxNum === boxNum.toString() + (index + 1),
                          )[0].queryKey
                        }`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #DCDCDC',
                    borderRadius: '2px',
                    maxHeight: '32px',
                    minWidth: '104px',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={() => {
                    if (!hoverButtonList.includes(boxNum.toString() + (index + 1)))
                      setHoverButtonList(() => [
                        ...hoverButtonList,
                        boxNum.toString() + (index + 1),
                      ]);
                  }}
                  onMouseLeave={() => {
                    const newHoverButtonList = hoverButtonList.filter(
                      list => list !== boxNum.toString() + (index + 1),
                    );
                    setHoverButtonList(() => newHoverButtonList);
                  }}
                >
                  <Box
                    style={{
                      width: '80px',
                      padding: '5px 8px',
                      background: `${
                        hoverButtonList.includes(boxNum.toString() + (index + 1))
                          ? '#F5F5F5'
                          : '#FFFFFF'
                      }`,
                      boxSizing: 'border-box',
                      borderRight: `${
                        hoverButtonList.includes(boxNum.toString() + (index + 1))
                          ? '1px solid #DCDCDC'
                          : 'none'
                      }`,
                    }}
                  >
                    <Typography
                      variant="m-regular"
                      color={`${
                        hoverButtonList.includes(boxNum.toString() + (index + 1))
                          ? 'gray-400'
                          : 'gray-300'
                      }`}
                    >
                      Select...
                    </Typography>
                  </Box>
                  <Box
                    mx={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => deleteQuery(boxNum, list.boxIndex)}
                    style={{ width: '24px', background: '#FFFFFF' }}
                  >
                    <Icon icon={Clear} color={'primary-400'} size="12px" />
                  </Box>
                </Box>
              )}
            </Button>
          }
          placement="bottom-start"
        >
          <DropdownMenuItemWrapper>
            {schemasList.map(schema => {
              return (
                <DropdownMenuItem
                  key={schema[0]}
                  onClick={() => {
                    const hasQuery = selectedQueryList.filter(
                      list => list.boxNum === boxNum.toString() + (index + 1),
                    );

                    const newQueryList = selectedQueryList.filter(
                      list => list.boxNum !== boxNum.toString() + (index + 1),
                    );
                    updateQueryContent(boxNum, index, {
                      ...hasQuery[0],
                      queryKey: schema[0] as string,
                      queryKeyType: schema[1] as string,
                      queryValue: '',
                    });
                    setSelectedQueryList(() => [
                      ...newQueryList,
                      {
                        ...hasQuery[0],
                        queryKey: schema[0] as string,
                        queryKeyType: schema[1] as string,
                        queryValue: '',
                      },
                    ]);
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="m-regular">{schema[0]}</Typography>
                  </Box>
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuItemWrapper>
        </DropdownMenu>
      )}
    </>
  );
}

const DropdownMenuItemWrapper = styled.div`
  position: absolute;
  top: -8px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
