import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalStepper } from '@superb-ai/norwegian-forest';
import { Option } from '@superb-ai/norwegian-forest/dist/components/basic/inputs/RadioButtons/types';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import { UploadMethod } from '../types';
import Cloud from './contents/Cloud';
import File from './contents/File';
import { uploadMethods } from './steps/config';
import ProjectAssign from './steps/ProjectAssign';
import SelectDataset from './steps/SelectDataset';
import SelectUploadMethod from './steps/SelectUploadMethod';

type PointCloudSequenceUploadMethods = Exclude<UploadMethod, 'URL' | 'CLI'>;
const PointCloudSequence: React.FC = () => {
  const { t } = useTranslation();
  const fileInfo = useUploadInfo();
  const { uploadMethod, files, dataset, selectedProject, setIsStepsCompleted } = fileInfo;
  const pointcloudSequenceUploadMethods = uploadMethods().filter(
    method => !(method.value === 'URL' || method.value === 'CLI'),
  );

  const routeInfo = useRouteInfo();
  const projectId = routeInfo.urlMatchInfo.projectId;

  const UPLOAD_METHOD = 'upload_method';

  const DATASET = 'dataset';
  const PROJECT = 'project';

  const [projectAssignButtonText, setProjectAssignButtonText] = useState<string>(t('button.skip'));

  const stepsPerUploadMethods = (uploadMethod: PointCloudSequenceUploadMethods) =>
    ({
      CLOUD: Cloud(t),
      FILE: File(t),
    }[uploadMethod]);

  const stepsPerUploadMethod = (uploadMethod: PointCloudSequenceUploadMethods) => {
    return {
      [UPLOAD_METHOD]: SelectUploadMethod({
        isButtonEnabled: !!uploadMethod,
        options: Object.values(
          pointcloudSequenceUploadMethods,
        ) as Option<PointCloudSequenceUploadMethods>[],
        hasWarning: !!(files.length || dataset || selectedProject),
        t,
      }),
      ...stepsPerUploadMethods(uploadMethod),
    };
  };

  const selectDatasetStep = {
    [DATASET]: SelectDataset(t),
  };

  const assignToProjectStep = {
    [PROJECT]: ProjectAssign(t),
  };

  const steps = (uploadMethod: PointCloudSequenceUploadMethods) => ({
    ...stepsPerUploadMethod(uploadMethod),
    ...selectDatasetStep,
  });
  const pointcloudUploadSteps = (uploadMethod: PointCloudSequenceUploadMethods) =>
    projectId ? steps(uploadMethod) : { ...steps(uploadMethod), ...assignToProjectStep };

  const lastStepButton = projectId
    ? {
        text: t('button.done'),
        onClick: () => {
          setIsStepsCompleted(true);
        },
      }
    : {
        text: projectAssignButtonText,
        onClick: () => {
          setIsStepsCompleted(true);
        },
      };

  useEffect(() => {
    if (selectedProject) {
      setProjectAssignButtonText(t('button.done'));
    } else {
      setProjectAssignButtonText(t('button.skip'));
    }
    // eslint-disable-next-line
  }, [selectedProject, t]);

  return (
    <VerticalStepper.Simple
      cardProps={{ maxHeight: 482, overflow: 'auto' }}
      steps={pointcloudUploadSteps(uploadMethod as PointCloudSequenceUploadMethods)}
      lastStepButton={lastStepButton}
    />
  );
};

export default PointCloudSequence;
