import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';

import { overflowOverlayOrAuto } from '../../../../../utils/style';
import { Edge } from './type';
type Props = {
  keypointId: string;
  edges: Edge[];
  editMode?: boolean;
  setEditEdges?: (edges: Edge[]) => void;
  setEditErrorMessage?: (error: string) => void;
};

const getKey = (keypointId: string, edge: Edge, index: number, editMode: boolean) => {
  if (!editMode) {
    return `${keypointId}.${edge.u}.${edge.v}.${edge.color}.${index}.${editMode}`;
  }

  return `${keypointId}.${index}.${editMode}`;
};

const EdgesEditor: React.FC<Props> = ({
  keypointId,
  edges,
  editMode = false,
  setEditEdges,
  setEditErrorMessage,
}) => {
  const { t } = useTranslation();
  const handleChange =
    (type: 'color', index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!setEditEdges || !setEditErrorMessage) return;

      const { value } = e.target;
      if (type === 'color' && value === '') {
        setEditErrorMessage(`edges[${index}].${type} should not be blank`);
        return;
      }

      const nextEdges = cloneDeep(edges);
      nextEdges[index][type] = value;
      setEditEdges(nextEdges);
      setEditErrorMessage('');
    };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="hidden" p={1}>
      <Box display="flex" flexDirection="column">
        <Typography variant="headline6"> {t('projects.createProject.keypoint.edges')}</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
          <Box width="16px">
            <Typography variant="body3">u</Typography>
          </Box>
          <Box width="16px">
            <Typography variant="body3">v</Typography>
          </Box>
          <Box width="120px" display="flex" justifyContent="center">
            <Typography variant="body3">color</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        overflow={overflowOverlayOrAuto()}
        p={1}
        gap="4px"
      >
        {edges.map((edge, index) => (
          <Box
            key={getKey(keypointId, edge, index, editMode)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="4px"
          >
            <Box width="16px">
              <Typography variant="body4">{edge.u}</Typography>
            </Box>
            <Box width="16px">
              <Typography variant="body4">{edge.v}</Typography>
            </Box>
            <Input
              size="xs"
              defaultValue={edge.color}
              disabled={!editMode}
              onKeyDown={e => e.stopPropagation()}
              onChange={handleChange('color', index)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EdgesEditor;
