import React from 'react';

import { useUploadInfo } from '../../../../contexts/UploadContext';
import Image from './Image';
import PointCloudSequence from './PointCloudSequence';
import VideoFrames from './VideoFrames';
import VideoStream from './VideoStream';

const Container: React.FC = () => {
  const { dataType } = useUploadInfo();
  if (!dataType) return <></>;

  const stepperContent = {
    IMAGE: <Image />,
    VIDEO_FRAMES: <VideoFrames />,
    VIDEO_STREAM: <VideoStream />,
    POINT_CLOUD_SEQUENCE: <PointCloudSequence />,
  }[dataType];

  return stepperContent;
};

export default Container;
