import * as d3 from 'd3';
import { map, orderBy, sumBy } from 'lodash';

import LabelInterfaceUtils, {
  AnnotationType,
  LabelInterface,
} from '../../../../../utils/LabelInterfaceUtils';
import MathUtils from '../../../../../utils/MathUtils';
import { EMPTY_MESSAGE } from '../../config/constants';
import { JsonObj, Mapper } from '../../userStats/types';
import { AnnotationsChangedRow, ComparisonReport, ObjectCountsRow } from '../types/types';

export const arrayToLookup = (
  keyFun: (v: { index: string }) => string,
  array: Array<{ index: string; name: string }>,
): JsonObj => {
  return Object.assign({}, ...array.map(d => ({ [keyFun(d)]: d })));
};

export const midPointCalculator = (p1: [number, number], p2: [number, number]) => [
  (p1[0] + p2[0]) / 2,
  (p1[1] + p2[1]) / 2,
];

export const arcGenerator = (
  innerRadius: number,
  outerRadius: number,
): d3.Arc<unknown, d3.DefaultArcObject> => {
  return d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
};

export const getPieceAngleInDegree = (startAngle: number, endAngle: number) => {
  // convert radian to degree
  return ((endAngle - startAngle) * 180) / Math.PI;
};

export const metricsTransformer = (report: ComparisonReport, sheetName: string) => {
  const metrics = report.info.project.metrics;
  const classesCounts = report.info.project.classesCounts;
  const classNameProperties = classesCounts.reduce((acc: { [x: string]: string }, cv) => {
    acc[cv.classId as keyof typeof acc] = cv.className;
    return acc;
  }, {});

  return {
    data: map(metrics, data => {
      return {
        'Class Name': classNameProperties[data.classId] || '',
        Precision: Number((data.precision / 100.0).toFixed(2)),
        Recall: Number((data.recall / 100.0).toFixed(2)),
      };
    }),
    sheetName,
  };
};

export const addTotalCount = (rows: AnnotationsChangedRow[]): AnnotationsChangedRow[] => {
  const sumFn = (
    acc: AnnotationsChangedRow,
    datum: AnnotationsChangedRow,
  ): AnnotationsChangedRow => {
    acc.added += datum.added;
    acc.deleted += datum.deleted;
    acc.queryEdited += datum?.queryEdited || 0;
    acc.queryFixedClass += datum.queryFixedClass;
    acc.noChange += datum.noChange;
    return acc;
  };
  const totalRow = rows.reduce(sumFn, {
    classId: 'total',
    className: 'Total',
    added: 0,
    deleted: 0,
    queryEdited: 0,
    queryFixedClass: 0,
    noChange: 0,
  } as AnnotationsChangedRow) as AnnotationsChangedRow;
  return rows.concat(totalRow);
};

export const annotationsChangedTransfromer = (
  report: ComparisonReport,
  classDisplayInfo: ClassTypeAndColor,
) => {
  const annotationChanges = report.info.project.annotationChanges;
  const classesCounts = report.info.project.classesCounts;
  const classIdToName = classesCounts.reduce((acc, cv) => {
    acc[cv.classId as string] = cv.className;
    return acc;
  }, {} as Mapper);

  return {
    data: map(annotationChanges, datum => {
      return {
        ...datum,
        edited: datum.queryEdited,
        changedClass: datum.queryFixedClass,
        className: classIdToName[datum.classId] || '',
        annoType: classDisplayInfo[datum.classId]?.annoType || '',
        color: 'gray-400',
      };
    }),
  };
};

export const overallTrendTransformer = (report: ComparisonReport) => {
  const overallTrend = report.info.project.annotationChanges.reduce(
    (acc, cv) => {
      acc['noChange'] += cv.noChange;
      acc['added'] += cv.added;
      acc['deleted'] += cv.deleted;
      acc['edited'] += cv.queryEdited;
      acc['changedClass'] += cv.queryFixedClass;
      return acc;
    },
    {
      noChange: 0,
      added: 0,
      deleted: 0,
      edited: 0,
      changedClass: 0,
    },
  );
  return overallTrend;
};

const calculateChange = (row: ObjectCountsRow) => row.refCount - row.queryCount;

export const objectCountsTransformer = (
  report: ComparisonReport,
  labelInterface: LabelInterface,
): ObjectCountsRow[] => {
  const classesCount = report.info.project.classesCounts;
  const classDisplayInfo = getClassDisplayInfo(labelInterface);
  const totalRow: ObjectCountsRow = classesCount.reduce(
    (acc: ObjectCountsRow, cv) => {
      acc.queryCount += cv.queryCount;
      acc.refCount += cv.refCount;
      acc.changes = calculateChange(acc);
      acc.annoType = classDisplayInfo[cv.classId]?.annoType;
      acc.color = 'gray-400';
      return acc;
    },
    {
      exportId: '',
      classId: 'total',
      className: 'Total',
      queryCount: 0,
      refCount: 0,
      changes: 0,
      refPercentage: 100,
      queryPercentage: 100,
    },
  );

  // @ts-ignore
  const tableRow: ObjectCountsRow[] = orderBy(
    classesCount.map(row => {
      const annoType = classDisplayInfo[row.classId]?.annoType || '';
      return {
        ...row,
        changes: row.refCount - row.queryCount,
        annoType,
        color: 'gray-400',
        refPercentage: MathUtils.calculatePercent({
          numerator: row.refCount,
          denominator: totalRow.refCount,
          nearest: 'hundredth',
        }),
        queryPercentage: MathUtils.calculatePercent({
          numerator: row.queryCount,
          denominator: totalRow.queryCount,
          nearest: 'hundredth',
        }),
      };
    }),
    'className',
  );
  return tableRow.concat(totalRow);
};

type ClassTypeAndColor = Record<string, { annoType: AnnotationType; color: string }>;

export const getClassDisplayInfo = (labelInterface: LabelInterface): ClassTypeAndColor => {
  const objects = LabelInterfaceUtils.getObjectClasses(labelInterface);
  return objects.reduce((acc, cv) => {
    acc[cv.id || cv.name] = {
      annoType: cv.annotationType as AnnotationType,
      color: cv.color as string,
    };
    return acc;
  }, {} as ClassTypeAndColor);
};

export const hasZeroAnnotations = (data: ObjectCountsRow[]) => {
  return sumBy(data, 'refCount') + sumBy(data, 'queryCount') === 0;
};

export const hasZeroAnnotationChanges = (data: JsonObj) => {
  return Object.values(data).reduce((acc, cv) => cv + acc, 0) === 0;
};

export const hasNoComparedLabels = (report: ComparisonReport) =>
  typeof report?.info?.metadata?.processedCount !== undefined &&
  report.info.metadata.processedCount === 0;

export const guideMessage = (report: ComparisonReport) =>
  hasNoComparedLabels(report)
    ? EMPTY_MESSAGE.EXPORT_COMPARE_LABELS
    : EMPTY_MESSAGE.EXPORT_ANNOTATE_OBJECTS;

export const formatCount = d3.format(',');
