import React from 'react';
import { useTranslation } from 'react-i18next';
import { Circle, Layer, Line, Stage, Text } from 'react-konva';

import { Box, Button, Typography } from '@superb-ai/norwegian-forest';
import { omit } from 'lodash';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import FileUtils from '../../../../../utils/FileUtils';
import { Keypoint, Point as PointDef } from './type';

type Props = {
  width: number;
  height: number;
  keypoint: Keypoint | null;
  useCopyJson?: boolean;
};

const Point = ({
  point,
  width,
  height,
  index,
}: {
  point: PointDef;
  width: number;
  height: number;
  index: number;
}) => {
  if (!point.defaultValue) return null;

  const {
    color,
    defaultValue: { x, y },
  } = point;

  const x1 = x * width;
  const y1 = y * height;

  return (
    <>
      <Circle x={x1} y={y1} radius={4} fill={color} />
      <Text x={x1 - 25} y={y1 + 6} width={50} align="center" text={String(index)} />
    </>
  );
};

const Edge = ({
  point1,
  point2,
  color,
  width,
  height,
}: {
  point1: PointDef;
  point2: PointDef;
  color: string;
  width: number;
  height: number;
}) => {
  if (!point1.defaultValue || !point2.defaultValue) return null;

  const points = [
    point1.defaultValue.x * width,
    point1.defaultValue.y * height,
    point2.defaultValue.x * width,
    point2.defaultValue.y * height,
  ];

  return <Line points={points} stroke={color} strokeWidth={2} />;
};

const Visualizer: React.FC<Props> = ({ width, height, keypoint, useCopyJson = false }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const points = keypoint?.points || [];
  const edges = keypoint?.edges || [];

  const padding = 80;
  const reducedWidth = width - padding;
  const reducedHeight = height - padding;

  const handleClickCopyJson = () => {
    if (!keypoint) return;
    FileUtils.copyToClipboard({ value: JSON.stringify(omit(keypoint, 'id')) });
    enqueueSnackbar(COPY_SUCCESS({ t, label: 'keypoint JSON' }), { variant: 'success' });
  };

  return (
    <Box width="100%" height="100%" position="relative" display="flex" alignItems="center" p={1}>
      <Box position="absolute" top="8px" left="8px">
        <Typography variant="headline6">{t('projects.createProject.keypoint.title')}</Typography>
      </Box>
      <Stage width={width} height={height}>
        <Layer x={padding / 2} y={padding / 2}>
          {edges.map((edge, index) => (
            <Edge
              key={index}
              point1={points[edge.u]}
              point2={points[edge.v]}
              color={edge.color}
              {...{ width: reducedWidth, height: reducedHeight, index }}
            />
          ))}
          {points.map((point, index) => (
            <Point key={index} {...{ width: reducedWidth, height: reducedHeight, point, index }} />
          ))}
        </Layer>
      </Stage>
      {useCopyJson && (
        <Box position="absolute" bottom="8px" right="8px">
          <Button onClick={handleClickCopyJson}>
            {t('projects.createProject.keypoint.copyJson')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Visualizer;
