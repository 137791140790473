import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { LABELS_DO_NOT_EXIST, UNKNOWN_ERROR } from '../../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import CommandsService from '../../../../../services/CommandsService';
import { LabelData } from '../../../../../types/labelTypes';
import ParamUtils from '../../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../../utils/router-utils';
import ConsensusSettings from './ConsensusSettings';

interface ConsensusDialogProps {
  checkedLabels: any[];
  setCheckedLabels: any;
  isAllLabelsChecked: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  getLabels: any;
  labels: LabelData[];
  totalUniqueCount: number;
}

const DefaultConsensusCount = 3;

const AssignDialog: React.FC<ConsensusDialogProps> = props => {
  const {
    checkedLabels,
    setCheckedLabels,
    isAllLabelsChecked,
    isOpen,
    setIsOpen,
    getLabels,
    totalUniqueCount,
    labels,
  } = props;

  const { t } = useTranslation();
  const commandContext = useLabelCommandContext();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const urlSearchParams = useSearchParams();
  const { tagIds } = projectInfo;

  const [labelSubsampleCount, setLabelSubsampleCount] = useState(0);
  const [consensusCount, setConsensusCount] = useState(DefaultConsensusCount);
  const [isLoading, setIsLoading] = useState(false);
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;
  // const [isSlowLoading, setIsSlowLoading] = useState(false);

  const uniqLabelsCount = isAllLabelsChecked
    ? totalUniqueCount
    : labels.filter(label => checkedLabels.indexOf(label.id) >= 0).length;

  useEffect(() => {
    setLabelSubsampleCount(uniqLabelsCount);
  }, [uniqLabelsCount]);

  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };
  const filterApiParams = useMemo(
    () =>
      ParamUtils.getApiParamsForFilter({
        filterParams: urlSearchParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      }),
    [routeInfo.params, tagIds],
  );

  const init = () => {
    setIsLoading(false);
    setConsensusCount(DefaultConsensusCount);
    setLabelSubsampleCount(uniqLabelsCount);
  };

  const params = !isAllLabelsChecked
    ? { idIn: checkedLabels }
    : { ...filterApiParams, ...searchParams };

  const handleClickAction = async () => {
    setIsLoading(true);
    try {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'LABELS_CONSENSUS_SETTING',
        projectId: routeInfo.urlMatchInfo.projectId,
        params,
        actionInfo: {
          limit: labelSubsampleCount,
          consensusCount,
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);

      init();
      setIsOpen(false);
      setCheckedLabels([]);
      getLabels();
    } catch (err: any) {
      if (err.message === 'Not Found') {
        enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
      } else {
        enqueueSnackbar(err.message || UNKNOWN_ERROR({ t }), { variant: 'error' });
      }
    } finally {
      setIsLoading(false);
      // setIsSlowLoading(false);
    }
  };

  const handleClickClose = () => {
    init();
    setIsOpen(false);
  };

  const buttonText = `Set to ${labelSubsampleCount * consensusCount} Labels`;

  return (
    <>
      <Modal
        open={isOpen}
        title="Configure Consensus Labeling"
        mainButton={{
          text: buttonText,
          onClick: handleClickAction,
          disabled: labelSubsampleCount === 0 || consensusCount === 0,
          isLoading,
        }}
        subButton={{
          text: 'Cancel',
          onClick: handleClickClose,
        }}
      >
        <Box px={4} width={420}>
          <ConsensusSettings
            {...{
              uniqLabelsCount,
              labelSubsampleCount,
              setLabelSubsampleCount,
              consensusCount,
              setConsensusCount,
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AssignDialog;
