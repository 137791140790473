import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import EditableUserAvatar from '../components/elements/EditableUserAvatar';
import UserAvatar from '../components/elements/UserAvatar';
import { useAppContext } from '../contexts/AppContext';
import { isOwnerOrAdmin, useAuthInfo } from '../contexts/AuthContext';
import { AppMenu, AppRouteLayoutProps, AppRoutes } from '../menu';
import { useAuthService } from '../services/NewAuthService';

export default function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const authInfo = useAuthInfo();
  const appContext = useAppContext();
  const { uploadTenantAvatar: uploadTenantAvatarFetcher } = useAuthService();

  async function uploadTenantAvatar(avatar: string) {
    const result = await uploadTenantAvatarFetcher({ avatar });
    const url = `${result.data.avatarUrl}?t=${new Date().getTime()}`;
    appContext.setTenantAvatar(url);
    return result.data.avatarUrl;
  }

  const canEditTenantAvatar = isOwnerOrAdmin(authInfo);

  return (
    <Box display="flex" flexDirection="row" minHeight="100vh">
      <Box
        themedBackgroundColor="textDefault"
        themedColor="background"
        height="100vh"
        position="sticky"
        top={0}
        display="flex"
        flexDirection="column"
        className="apps-menu"
      >
        <Box display="flex" height={49} alignItems="center" px={1} style={{ marginLeft: '6px' }}>
          {canEditTenantAvatar ? (
            <EditableUserAvatar
              size={24}
              userInfo={{ name: accountName, avatarUrl: appContext.tenantAvatar }}
              uploadImage={uploadTenantAvatar}
              title={t('apps.changeTeamLogo')}
            />
          ) : (
            <UserAvatar
              size={24}
              userInfo={{ name: accountName, avatarUrl: appContext.tenantAvatar }}
              noShadow
              color="#fff"
              margin={0}
            />
          )}
          <Box flex={1} ml={1} className="account-name">
            <Typography variant="headline7" whiteSpace="nowrap">
              {accountName}
            </Typography>
          </Box>
        </Box>
        <AppMenu isMainNavbar items={menuItem.children ?? []} itemProps={{ size: 'l', css: '' }} />
      </Box>
      <Box flex={1} minWidth={0}>
        <AppRoutes items={menuItem.children ?? []} />
      </Box>
    </Box>
  );
}
