import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Typography } from '@superb-ai/ui';
import { format } from 'date-fns';

import { TextEllipsisBox } from '../../../../components/elements/TextEllipsisBox';
import { useTrainUrlParams } from '../queries';
import { useBaselineContext } from './BaselineContext';

export type SplitType = 'random' | 'manual';

type ContextProps = {
  datasetReadOnly: boolean;
  datasetId: string | null;
  setDatasetId: Dispatch<SetStateAction<string | null>>;
  datasetName: string;
  setDatasetName: Dispatch<SetStateAction<string>>;
  trainingSetNames: string[];
  setTrainingSetNames: Dispatch<SetStateAction<string[] | null>>;
  splitType: SplitType | null;
  setSplitType: Dispatch<SetStateAction<SplitType | null>>;
  validationSetNames: string[];
  setValidationSetNames: Dispatch<SetStateAction<string[] | null>>;
  selectedAnnotationClasses: {
    type: string;
    name: string;
  }[];
  setSelectedAnnotationClasses: Dispatch<
    SetStateAction<
      {
        type: string;
        name: string;
      }[]
    >
  >;
  datasetClassSummary: EmotionJSX.Element;
  trainSetSliceName: string;
  setTrainSetSliceName: Dispatch<SetStateAction<string>>;
  validationSetSliceName: string;
  setValidationSetSliceName: Dispatch<SetStateAction<string>>;
  isValidSliceNames: boolean;
  setIsValidSliceNames: Dispatch<SetStateAction<boolean>>;
};

const Context = createContext({} as ContextProps);

const useProvider = () => {
  const params = useTrainUrlParams();
  const { selectedMyModel } = useBaselineContext();

  // 1. Source(Curate dataset) type
  const datasetReadOnly = Boolean(params.datasetId || selectedMyModel?.trainingSet.referenceId);

  // 2. Source(Curate dataset) Id
  const [selectedDatasetId, setDatasetId] = useState<string | null>(null);
  const datasetId =
    selectedDatasetId ?? selectedMyModel?.trainingSet.referenceId ?? params.datasetId ?? null;
  const [datasetName, setDatasetName] = useState<string>(
    selectedMyModel?.trainingSet.referenceName ?? '',
  );

  // 3. Source item ids: slice
  const [selectedTrainingSetNames, setTrainingSetNames] = useState<string[] | null>(null);
  const preselectedTrainingSetNames =
    selectedMyModel?.trainingSet.trainingSetList.map(_ => _.name) ?? [];

  const trainingSetNames = (selectedTrainingSetNames ?? params.sliceNames ?? []).concat(
    preselectedTrainingSetNames,
  );

  const [splitType, setSplitType] = useState<SplitType | null>(
    (params.split as SplitType | null) ?? null,
  );

  const [selectedValidationSetNames, setValidationSetNames] = useState<string[] | null>(null);

  const preselectedValidationSets =
    selectedMyModel?.trainingSet.validationSetList.map(_ => _.name) ?? [];
  const validationSetNames = (selectedValidationSetNames ?? []).concat(preselectedValidationSets);

  // 5.Class list detection, category
  const [_selectedAnnotationClasses, setSelectedAnnotationClasses] = useState<
    { type: string; name: string }[]
  >([]);
  const selectedAnnotationClasses =
    (_selectedAnnotationClasses.length
      ? _selectedAnnotationClasses
      : selectedMyModel?.modelSetting.annotationClassList) ?? [];

  // 6. Train set slice, validation set slice name
  const [trainSetSliceName, setTrainSetSliceName] = useState(
    `train set ${format(new Date(), "yyyy-MM-dd, HH'h' mm'm'")}`,
  );
  const [validationSetSliceName, setValidationSetSliceName] = useState(
    `validation set ${format(new Date(), "yyyy-MM-dd, HH'h' mm'm'")}`,
  );

  const [isValidSliceNames, setIsValidSliceNames] = useState<boolean>(false);

  const datasetClassSummary = (
    <TextEllipsisBox display="flex" gap={1} alignItems="center" style={{ width: 263 }}>
      {Boolean(datasetName) && (
        <Typography variant="m-regular">
          {datasetName} ({trainingSetNames.length} slices)
        </Typography>
      )}
    </TextEllipsisBox>
  );

  return {
    datasetReadOnly,
    datasetId,
    setDatasetId,
    datasetName,
    setDatasetName,
    trainingSetNames,
    setTrainingSetNames,
    splitType,
    setSplitType,
    validationSetNames,
    setValidationSetNames,
    selectedAnnotationClasses,
    setSelectedAnnotationClasses,
    datasetClassSummary,
    trainSetSliceName,
    setTrainSetSliceName,
    validationSetSliceName,
    setValidationSetSliceName,
    isValidSliceNames,
    setIsValidSliceNames,
  };
};
export const useDatasetClassContext = (): ContextProps => {
  return useContext(Context);
};

export const DatasetClassProvider: React.FC = ({ children }) => {
  const datasetClassInfo = useProvider();
  return <Context.Provider value={datasetClassInfo}>{children}</Context.Provider>;
};
