import React from 'react';

import { inRange } from 'lodash';

import ImageContext from '../../../../contexts/ImageContext';
import StageSizeContext from '../../../../contexts/StageSizeContext';

// eslint-disable-next-line
const ImageDragHandler = (props: any): React.ReactElement => {
  const stageSizeInfo = React.useContext(StageSizeContext.Context);
  const imageInfo = React.useContext(ImageContext.Context);

  const { children } = props;

  const handleWheel = async (e: any): Promise<void> => {
    const { offsetX, offsetY, deltaY } = e.nativeEvent;
    const { x, y, width, height, rate, setX, setY, setRate } = imageInfo;

    if (e.target.nodeName !== 'CANVAS') return;

    if (deltaY === 0) return;
    const delta = 0.04;
    const dir = deltaY >= 0 ? -1 : +1;
    const nextRate = rate + delta * dir;

    if (width > height) {
      if (!inRange(width * nextRate, stageSizeInfo.width / 4, stageSizeInfo.width * 16)) return;
    } else if (!inRange(height * nextRate, stageSizeInfo.height / 4, stageSizeInfo.height * 16))
      return;

    const widthDiff = width * delta;
    const heightDiff = height * delta;

    const widthRatio = (offsetX - x) / (width * rate);
    const heightRatio = (offsetY - y) / (height * rate);

    const moveX = widthDiff * widthRatio;
    const moveY = heightDiff * heightRatio;

    setX(x - moveX * dir);
    setY(y - moveY * dir);
    setRate(nextRate);
  };

  if (!imageInfo.image) {
    return <div>{children}</div>;
  }

  return (
    <div role="presentation" onWheel={handleWheel} style={{ position: 'absolute' }}>
      {children}
    </div>
  );
};

export default ImageDragHandler;
