import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button } from '@superb-ai/ui';
import { omit } from 'lodash';
import qs from 'qs';

import { DEFAULT_ERROR, INVITATION_SESSION_EXPIRED } from '../../../consts/ModalMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useMultipleInputState } from '../../../hooks/useMultipleInputState';
import { useAuthService } from '../../../services/NewAuthService';
import UserRoleUnion from '../../../union/UserRoleUnion';
import {
  identifyAdminOnForceChange,
  identifyCollaboratorOnForceChange,
} from '../../../utils/SegmentIdentifyGroup';
import UserUtils from '../../../utils/UserUtils';
import CircularProgressDialog from '../../elements/CircularProgressDialog';
import { getNameError, getPasswordError } from './helper';
import LabeledInput from './LabeledInput';
import { useChangeContent } from './Layout';
import Policy from './Policy';
import PolicyKR from './PolicyKR';
import Title from './Title';
import ValidationInput, { blurAllInputs, useValidationInputRefs } from './ValidationInput';

export default function InvitedSignUpLayout() {
  const [isRequesting, setIsRequesting] = useState(false);
  const [session, setSession] = useState('');
  const [info, setInfo] = useMultipleInputState({
    accountName: '',
    email: '',
    givenName: '',
    familyName: '',
    password: '',
    krMarketingConsentAgreed: undefined,
    krNewsletterConsentAgreed: undefined,
  });
  const refs = useValidationInputRefs(['givenName', 'familyName', 'password'] as const);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { t, i18n } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const { changePasswordAfterInvite } = useAuthService();
  const {
    email,
    accountName,
    familyName,
    givenName,
    password,
    krMarketingConsentAgreed,
    krNewsletterConsentAgreed,
  } = info;
  const language = window.navigator.language.split('-')[0];

  useChangeContent('testimonialIntutivo');

  useEffect(() => {
    const { tenantId, email, session } = routeInfo.params;

    if (!tenantId || !email || !session) {
      routeInfo.history.push('/auth/login');
      return;
    }

    setInfo('accountName', atob(tenantId));
    setInfo('email', atob(email));
    setSession(session);
    refs.current.givenName?.origin?.focus();
  }, [routeInfo.params]);

  const isNameValidated = !getNameError(t)(familyName);
  const isPasswordValidated = !getNameError(t)(password);
  const isMarketingConsentAgreed = language !== 'ko' || !!krMarketingConsentAgreed;

  const getIsValid = () => {
    if (isRequesting) {
      return false;
    } else if (getNameError(t)(familyName)) {
      refs.current.familyName?.focusWithError();
      return false;
    } else if (getPasswordError(t)(password)) {
      refs.current.password?.focusWithError();
      return false;
    }

    return true;
  };

  const handleSignUp = async (): Promise<void> => {
    try {
      blurAllInputs(refs);
      setIsRequesting(true);

      const { data } = await changePasswordAfterInvite({
        email,
        session,
        newPassword: password,
        givenName,
        familyName,
        tenantId: accountName,
      });

      const { role } = UserUtils.getUserTokenInfo(data.idToken);
      if (UserRoleUnion.isAdmin(role)) {
        identifyAdminOnForceChange({
          email,
          firstName: givenName,
          lastName: familyName,
          accountId: accountName,
        });
      } else if (UserRoleUnion.isCollaborator(role)) {
        identifyCollaboratorOnForceChange({
          email,
          firstName: givenName,
          lastName: familyName,
        });
      }

      const { data: loginData } = await authInfo.login(accountName, email, password);
      if (loginData.challengeName === 'MFA_SETUP') {
        routeInfo.history.push('/auth/setup-mfa');
      } else {
        routeInfo.history.push('/');
      }
    } catch (e: any) {
      const message = e.message ?? 'Error';

      if (message === 'Session expired') {
        const param = qs.stringify(
          omit(qs.parse(routeInfo.location.search.substring(1)), 'session'),
        );

        openModal({
          ...INVITATION_SESSION_EXPIRED({ t, title: message }),
          mainButton: {
            text: t('button.ok'),
            onClick: () => routeInfo.history.push(`/auth/login?${param}`),
          },
        });

        return;
      }

      openModal(
        DEFAULT_ERROR({
          t,
          message,
          onClickMainButton: () => {
            closeModal();
          },
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (getIsValid()) {
      handleSignUp();
    }
  };

  return (
    <>
      <Box as="form" onSubmit={onSubmit} display="flex" flexDirection="column" gap={2}>
        <Title>{t('auth.title.signUp')}</Title>
        <LabeledInput label={t('settings.profile.teamName')} value={accountName} disabled />
        <LabeledInput label={t('settings.profile.workEmail')} value={email} disabled />
        <Box display="flex" gap={1}>
          <ValidationInput
            refs={refs}
            refKey="givenName"
            autoComplete="given-name"
            validation={getNameError(t)}
            onChange={e => setInfo('givenName', e.target.value)}
            value={givenName}
            label={t('settings.profile.firstName')}
            placeholder="Johnny"
          />
          <ValidationInput
            refs={refs}
            refKey="familyName"
            autoComplete="family-name"
            validation={getNameError(t)}
            onChange={e => setInfo('familyName', e.target.value)}
            value={familyName}
            label={t('settings.profile.lastName')}
            placeholder="Appleseed"
          />
        </Box>
        <ValidationInput
          type="password"
          autoComplete="new-password"
          refs={refs}
          refKey="password"
          validation={getPasswordError(t)}
          onChange={e => setInfo('password', e.target.value)}
          value={password}
          label={t('settings.profile.password')}
          placeholder="Shhh..."
          info={t('settings.profile.passwordRestriction')}
        />
        {language === 'ko' && (
          <PolicyKR
            info={{ krMarketingConsentAgreed, krNewsletterConsentAgreed }}
            setInfo={setInfo}
          />
        )}
        <Button
          type="submit"
          variant="strong-fill"
          color="primary"
          size="l"
          style={{ width: '100%' }}
          disabled={!isNameValidated || !isPasswordValidated || !isMarketingConsentAgreed}
        >
          {t('auth.button.signup')}
        </Button>
        {language !== 'ko' && <Policy />}
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </>
  );
}
