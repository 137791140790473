import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep, map } from 'lodash';

import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';

const useStyles = makeStyles(() => ({
  annotationTypeBox: {
    position: 'relative',
    flex: '1',
    overflow: overflowOverlayOrAuto(),
    minHeight: '80px',
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 500,
    borderRadius: '12.5px',
    cursor: 'pointer',
    color: '#b4b4b4',
    background: ' #f6f6f6;',
    boxShadow: 'none',
    '&:hover': {
      background: ' rgba(122, 122, 122, 0.2)',
    },
    '&.active': {
      color: '#fff',
      background: ' rgba(122, 122, 122, 0.8)',
      '&:hover': {
        background: ' rgba(122, 122, 122, 0.4)',
      },
    },
    '&.MuiButton-root': {
      padding: '2px 18px',
    },
    marginRight: '9px',
    marginBottom: '9px',
  },
}));
// eslint-disable-next-line
const CabinetAnnotationType = (props: any): React.ReactElement => {
  const classes = useStyles();
  const labelInformationInfo = useLabelInformationInfo();
  const { cabinetStyles } = props;

  const { annotationTypes, setAnnotationTypes } = labelInformationInfo;

  const handleClickAnnotationType = (key: number) => () => {
    const nextAnnotationTypes = cloneDeep(annotationTypes);
    nextAnnotationTypes[key].isActive = !annotationTypes[key].isActive;
    setAnnotationTypes(nextAnnotationTypes);
  };

  return (
    <>
      <MUI.Box className={cabinetStyles.cabinetHeader} mt="20px">
        <MUI.Typography className={cabinetStyles.cabinetTitle}>Annotation type</MUI.Typography>
      </MUI.Box>
      <MUI.Box className={classes.annotationTypeBox} mt="12px" mb="12px">
        <MUI.Box className={cabinetStyles.cabinetContent}>
          {map(annotationTypes, (value: any, key: number) => {
            return (
              <MUI.Button
                className={`${classes.chip} ${value.isActive ? 'active' : ''}`}
                key={key}
                variant="contained"
                onClick={handleClickAnnotationType(key)}
              >
                {key}
              </MUI.Button>
            );
          })}
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default CabinetAnnotationType;
