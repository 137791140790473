import { useTranslation } from 'react-i18next';

import { Box, LoadingIndicator } from '@superb-ai/ui';

import { useAuthInfo } from '../../contexts/AuthContext';
import { AppMenu, AppRouteLayoutProps, AppRoutes, MenuTitle, PageWithMenu } from '../../menu';
import { useMetering } from '../../queries/useMeteringQuery';
import { IntroMenuItem } from './intro/MenuItem';

export default function ModelLayout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const { t } = useTranslation();
  const { tier } = useAuthInfo();
  const { isLoading: isEndpointLoading, maxQuantity: endpointMaxQuantity } =
    useMetering('model:endpoint');
  const { isLoading: isDataVolumeLoading, maxQuantity: dataMaxQuantity } =
    useMetering('curate:data-volume');
  localStorage.setItem('last_visit_page', 'model');

  if (isEndpointLoading || isDataVolumeLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      {tier === 'ENTERPRISE' && (dataMaxQuantity > 0 || endpointMaxQuantity > 0) ? (
        <PageWithMenu
          menu={
            <Box position="sticky" top={'0'}>
              <MenuTitle title={t('model.title')} />
              <Box p={1} mt={1}>
                <AppMenu items={menuItem.children ?? []} nested />
              </Box>
            </Box>
          }
        >
          <AppRoutes items={menuItem.children ?? []} />
        </PageWithMenu>
      ) : (
        <AppRoutes items={[IntroMenuItem] ?? []} />
      )}
    </>
  );
}
