import React, { MouseEvent, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ChartBar, ChartScatterPlot, EditFilter, Grid2X2Big, Search } from '@superb-ai/icons';
import { Box, Checkbox, Icon, IconButton, SegmentedControl, Typography } from '@superb-ai/ui';

import { useFeatureFlag } from '../../../../../../contexts/FeatureFlagContext';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../contexts/QueryContext';
import EmbeddingMonitoringBanner from '../../../../elements/EmbeddingMonitoringBanner';
import {
  ANALYTICS_VIEW,
  GRID_VIEW,
  IMAGE_SCOPE,
  SCATTER_VIEW,
  SCOPE,
  ScopeMode,
  SEMANTIC_SEARCH_VIEW,
  VIEW,
  ViewMode,
} from '../../../../types/viewTypes';
import { getSearchQueryRoute } from '../../../../utils/routeUtils';
import ClusterCenters from '../filter/ClusterCenters';

type Props = {
  LeftSection?: JSX.Element;
  RightSection?: JSX.Element;
  Actions?: JSX.Element | JSX.Element[];
};

export default function ActionTopBar({
  LeftSection,
  Actions = [],
  RightSection,
  children,
}: PropsWithChildren<Props>) {
  const history = useHistory();
  const { t } = useTranslation();

  const params = new URLSearchParams(history.location.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;
  const scope = (params.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;
  const { totalCount: totalImageCount } = useImageScopeContext();
  const { setHiddenQueryString } = useQueryContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const rightSectionRef = useRef<HTMLDivElement>(null);
  const actionButtonsRef = useRef<HTMLDivElement>(null);
  const { isFilterOpen, setIsFilterOpen, hasAppliedFilters } = useImageFilterContext();
  const {
    selectedAllData,
    setSelectedAllData,
    selectedData,
    setSelectedData,
    deselectedData,
    setDeselectedData,
  } = useActionContext();

  const semanticSearchDemoFlag = useFeatureFlag('semanticSearchDemo');

  const hasAnalyticsView = scope === IMAGE_SCOPE;

  const viewOptions = [
    {
      value: GRID_VIEW,
      label: <Icon icon={Grid2X2Big} />,
      tooltip: t('curate.dataset.views.tile'),
    },
    ...(hasAnalyticsView
      ? [
          {
            value: ANALYTICS_VIEW,
            label: <Icon icon={ChartBar} />,
            tooltip: t('curate.dataset.views.analytics'),
          },
        ]
      : []),
    {
      value: SCATTER_VIEW,
      label: <Icon icon={ChartScatterPlot} />,
      tooltip: t('curate.dataset.views.scatter'),
    },
    ...(semanticSearchDemoFlag
      ? [
          {
            value: SEMANTIC_SEARCH_VIEW,
            label: <Icon icon={Search} />,
            tooltip: 'semantic search',
          },
        ]
      : []),
  ];

  function handleChangeViewMode(value: string) {
    history.push(getSearchQueryRoute(history, { view: value }));
    setSelectedAllData(false);
    setSelectedData([]);
    setDeselectedData([]);
  }

  function handleClickKeyCheckbox(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedData([]);
    setSelectedAllData(!selectedAllData);
    setHiddenQueryString(undefined);
    setDeselectedData([]);
  }

  const selectedCount = !selectedAllData
    ? selectedData?.length || 0
    : totalImageCount - deselectedData?.length;

  return (
    <Box display="flex">
      <Box style={{ flex: 1, minWidth: 0 }}>
        <Box display="flex" alignItems="center" pl={4} gap={1} pr={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Checkbox
              value={
                selectedAllData
                  ? deselectedData?.length
                    ? 'mixed'
                    : true
                  : selectedData?.length
                  ? 'mixed'
                  : false
              }
              onClick={handleClickKeyCheckbox}
              disabled={view !== GRID_VIEW && view !== SEMANTIC_SEARCH_VIEW}
            />
            <SegmentedControl
              options={viewOptions}
              value={view}
              onChangeValue={handleChangeViewMode}
              buttonProps={{ square: true }}
            />
            {LeftSection && (
              <>
                <Box borderRight="1px solid" borderColor="gray-200" style={{ height: '12px' }} />
                {LeftSection}
              </>
            )}
            {scope === IMAGE_SCOPE && view === GRID_VIEW && (
              <>
                <Box
                  mx={0.5}
                  borderLeft="1px solid"
                  borderColor="gray-200"
                  style={{ height: '12px' }}
                />
                <Box whiteSpace="nowrap">
                  <Typography
                    variant="m-strong"
                    color={selectedCount === 0 ? 'gray-300' : 'primary'}
                  >
                    {selectedCount.toLocaleString('en')}{' '}
                  </Typography>
                  <Typography variant="m-strong">
                    / {(totalImageCount || 0).toLocaleString('en')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box
            ref={containerRef}
            display="flex"
            alignItems="center"
            gap={0.5}
            // overflow="hidden"
            style={{ marginLeft: 'auto' }}
          >
            <Box ref={rightSectionRef} display="flex" alignItems="center" gap={0.5}>
              {RightSection}
            </Box>
            <Box
              ref={actionButtonsRef}
              display="flex"
              alignItems="center"
              gap={0.5}
              // overflow="hidden"
            >
              {Actions}
              {/* {overflowedActions.length > 0 && (
              <IconButton
                icon={MoreVertical}
                variant="text"
                color="gray"
                onClick={handleClickMoreButton}
              />
               )} */}
            </Box>
            {scope === IMAGE_SCOPE && !isFilterOpen && (
              <IconButton
                icon={EditFilter}
                badge={hasAppliedFilters}
                badgeProps={{ style: { width: '6px', height: '6px' } }}
                color="gray"
                variant="soft-fill"
                size="m"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
            )}
          </Box>
        </Box>
        <EmbeddingMonitoringBanner />
        <Box mt={1} pl={4} style={{ flex: 1 }}>
          {children}
        </Box>
      </Box>
      {scope === IMAGE_SCOPE && isFilterOpen && (
        <ClusterCenters onClose={() => setIsFilterOpen(false)} />
      )}
      {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {overflowedActions.map((action, index) => (
          <MenuItem key={index} onClick={handleCloseMenu}>
            {action}
          </MenuItem>
        ))}
      </Menu> */}
    </Box>
  );
}
