import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button } from '@superb-ai/ui';
import { AxiosError } from 'axios';

import { IS_PERSONAL } from '../../../consts/AuthMessage';
import { DEFAULT_ERROR } from '../../../consts/ModalMessage';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AnalyticsTracker from '../../../analyticsTracker';
import { useAuthService } from '../../../services/NewAuthService';
import { identify } from '../../../utils/SegmentIdentifyGroup';
import { getEmailError, isPersonalEmail } from './helper';
import { useChangeContent } from './Layout';
import Policy from './Policy';
import ShortCut from './ShortCut';
import Title from './Title';
import ValidationInput from './ValidationInput';

const getEmailErrorCustom =
  (t: TFunction) =>
  (value: string): string | null => {
    if (getEmailError(t)(value)) {
      return getEmailError(t)(value);
    } else if (isPersonalEmail(value)) {
      return IS_PERSONAL({ t });
    }

    return null;
  };

export default function SignupLayout() {
  const routeInfo = useRouteInfo();
  const [email, setEmail] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const { createAccountByEmail } = useAuthService();
  const { t, i18n } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const language = window.navigator.language.split('-')[0];

  useChangeContent('valueProposition');

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsRequesting(true);
      const { data } = await createAccountByEmail({ email });
      identify({ email });
      AnalyticsTracker.signupStepCompleted({ step: 'email' });
      routeInfo.history.push(
        `/auth/check_email?tenantId=${data.tenantUuid}&email=${btoa(data.email)}`,
      );
    } catch (e: unknown) {
      let message = `${
        (e as AxiosError<any>).response?.data?.message ?? 'An unknown error occured.'
      }`;
      if (message.match(/already used as owner/) !== null) {
        message = 'An account already exists for this email address.';
      }
      openModal(
        DEFAULT_ERROR({
          t,
          title: 'Account could not be created.',
          message,
          onClickMainButton: closeModal,
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <>
      <ShortCut page="signIn" />
      <Box
        as="form"
        onSubmit={handleSignup}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={2}
      >
        <Title>{t('auth.title.signUp')}</Title>
        <ValidationInput
          autoComplete="email"
          validation={getEmailErrorCustom(t)}
          label={t('settings.profile.workEmail')}
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="johnny@superb-ai.com"
        />
        <Button
          type="submit"
          variant="strong-fill"
          color="primary"
          size="l"
          disabled={!email || !!getEmailErrorCustom(t)(email.trim())}
          loading={isRequesting}
          loadingText={t('button.sending')}
          style={{ width: '100%' }}
          id="suite-signup-email-continue-button"
        >
          {t('button.continue')}
        </Button>
        {language !== 'ko' && (
          <Box style={{ alignSelf: 'center' }}>
            <Policy />
          </Box>
        )}
      </Box>
    </>
  );
}
