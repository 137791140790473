import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../menu/MenuItem';
import Layout from './Layout';

export const CurateTutorialMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateTutorial,
  path: 'tutorial',
  title: ({ t }) => t('curate.tutorial.title'),
  component: Layout,
});
