import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search, WarningFilled } from '@superb-ai/icons';
import {
  Box,
  Button,
  Dialog,
  Icon,
  Input,
  Select,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { Row } from '../../../components/elements/Row';
import { MODEL_ENDPOINT_SETTING_APPLIED } from '../../../consts/SnackbarMessage';
import { useDebounce } from '../../../hooks/DebounceHook';
import { PurposeChip } from '../components/components';
import {
  useCheckEndpointNameUniqueQuery,
  useEditEndpointSettingMutation,
  useModelListQuery,
} from '../queries/modelQueries';
import { EndpointStatus, MyModel } from '../services/types';
import RegexUtils from '../../../utils/RegexUtils';

export const EndpointsSettingsDialog = ({
  state,
  endpointId,
  endpointName,
  model,
  modelName,
  status,
}: {
  state: ReturnType<typeof useDialogState>;
  endpointId: string;
  endpointName: string;
  model: MyModel;
  modelName: string;
  status: EndpointStatus;
}) => {
  const { t } = useTranslation();
  const { mutate: editEndpointsSettings } = useEditEndpointSettingMutation();
  const [uniqueNameFilter, setUniqueNameFilter] = useState(endpointName);
  const debouncedUniqueNameFilter = useDebounce(uniqueNameFilter, 300);
  const { data: _data } = useCheckEndpointNameUniqueQuery({
    id: endpointId,
    name: debouncedUniqueNameFilter,
  });
  const isEndpointNameUnique = (_data?.pages.flatMap(p => p.endpointList) ?? []).length === 0;
  const { enqueueSnackbar } = useSnackbar();

  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const debouncedModelNameFilter = useDebounce(searchModelNameFilter, 300);
  const myModelsQuery = useModelListQuery({
    params: {
      name: debouncedModelNameFilter,
    },
  });

  const { data: myModelData, isLoading, fetchNextPage, hasNextPage } = myModelsQuery;
  const myModelsList = myModelData?.pages.flatMap(p => p.modelList) ?? [];

  const [selectedModel, setSelecteModel] = useState<MyModel | null>(model);
  const hasSelectedDifferentModel =
    status === 'running' && selectedModel && selectedModel.modelSetting.name !== modelName;

  const modelOptions = myModelsList.map(model => {
    return {
      value: model,
      label: (
        <Box>
          <Row justifyContent="space-between" mb={0.5}>
            <Typography variant="m-regular">{model.modelSetting.name}</Typography>
            <PurposeChip
              purpose={model.baselineModel.purpose}
              color={model.baselineModel.purpose === 'generation' ? 'secondary' : 'primary'}
            />
          </Row>
          <Row>
            <Typography color="gray-300" variant="m-regular">{`${t('model.endpoints.createdAt')} ${
              model.createdAt ? format(new Date(model.createdAt), 'M/d/yy h:mm a') : '-'
            }`}</Typography>
          </Row>
        </Box>
      ),
    };
  });
  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.endpoints.settings')}
      </Dialog.Header>
      <Typography variant="m-strong" style={{ marginBottom: -8 }}>
        {t('model.title')}
      </Typography>
      <Box>
        <Box display="flex" mb={1}>
          <Select
            value={selectedModel}
            onChangeValue={v => {
              const model = myModelsList.find(_ => _.id === v.id)!;
              setSelecteModel(model);
            }}
            data={modelOptions}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            fetchNextPage={() => fetchNextPage()}
            disabled={status !== 'paused'}
            placeholder={t('model.endpoints.selectModelPlaceholder')}
            formatValue={v => (
              <Row>
                <PurposeChip
                  purpose={v.baselineModel.purpose}
                  color={v.baselineModel.purpose === 'generation' ? 'secondary' : 'primary'}
                />
                {v.modelSetting.name}
              </Row>
            )}
            prefix={
              <Box
                p={0.5}
                onClick={e => e.stopPropagation()}
                borderBottom="1px solid"
                borderColor="gray-200"
              >
                <Input
                  size="m"
                  variant="text"
                  prefix={<Icon icon={Search} />}
                  placeholder={t('model.train.modelSearchPlaceholder')}
                  value={searchModelNameFilter}
                  onChange={e => setSearchModelNameFilter(e.target.value)}
                />
              </Box>
            }
          />
        </Box>
        {status !== 'paused' && (
          <Row backgroundColor={'primary-100'} gap={0.5} px={1.5} style={{ height: 34 }}>
            <Icon icon={WarningFilled} color={'primary-400'} />
            <Typography color="primary-400" variant="m-regular">
              {t('model.endpoints.warning')}
            </Typography>
          </Row>
        )}
      </Box>
      {hasSelectedDifferentModel && (
        <Row style={{ height: 34, marginTop: -8 }} backgroundColor={'primary-100'} pl={1.5}>
          <Icon icon={WarningFilled} color={'primary'} style={{ marginRight: 4 }} />
          <Typography color="primary-400" variant="m-regular">
            {t('model.endpoints.warning')}
          </Typography>
        </Row>
      )}
      <Typography variant="m-strong" style={{ marginBottom: -8 }}>
        {t('shared.name')}
      </Typography>
      <Input value={uniqueNameFilter} onChange={e => setUniqueNameFilter(e.target.value)} />
      {!isEndpointNameUnique && (
        <Typography variant="s-regular" color={'red-400'} style={{ marginTop: -12 }}>
          {t('model.train.uniqueEndpointName')}
        </Typography>
      )}
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          disabled={
            !selectedModel ||
            hasSelectedDifferentModel ||
            !uniqueNameFilter ||
            !isEndpointNameUnique ||
            RegexUtils.HAS_SPECIAL_SYMBOLS(uniqueNameFilter)
          }
          onClick={() => {
            if (!selectedModel) return;
            editEndpointsSettings({
              endpointId,
              modelId: selectedModel.id,
              endpointName: uniqueNameFilter,
            });
            state.setVisible(false);
            enqueueSnackbar(MODEL_ENDPOINT_SETTING_APPLIED({ t }), {
              variant: 'info',
            });
          }}
        >
          {t('shared.apply')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
