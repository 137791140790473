import { useMemo } from 'react';

import dynamic from 'next/dynamic';

import { useImageClusterContext } from '../../../../../contexts/ImageClusterContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useChartAreaContext } from '../../analytics/contexts/ChartAreaContext';
import { useImageScatterContext } from '../../analytics/contexts/ImageScatterContext';
import Scatter2DContainer from './Scatter2DContainer';
import { resizeX, resizeY } from './scatterView/utils/transformer';

const ScatterPlotProvider = dynamic({
  loader: () => import('./scatterView/ScatterPlotProviders').then(x => x.ScatterPlotProviders),
  ssr: false,
});

export default function ImageScatterContents() {
  const { chartAreaSize } = useChartAreaContext();
  const { data, dataCompare } = useImageScatterContext();
  const { clusterColorMap } = useImageClusterContext();
  const { clusterLevel } = useImageFilterContext();

  const points = useMemo(
    () =>
      chartAreaSize && data
        ? data.map(d => ({
            ...d,
            x: resizeX(d.x, chartAreaSize.width),
            y: resizeY(d.y, chartAreaSize.height),
            color: clusterColorMap?.[d.superClusters[`level${clusterLevel}`]?.id],
            leafClusterId: d.leafClusterId,
          }))
        : [],
    [data, chartAreaSize, clusterColorMap],
  );

  const comparedPoints = useMemo(
    () =>
      chartAreaSize && dataCompare
        ? dataCompare.map(d => ({
            ...d,
            x: resizeX(d.x, chartAreaSize.width),
            y: resizeY(d.y, chartAreaSize.height),
            color: clusterColorMap?.[d.clusterId],
          }))
        : [],
    [dataCompare, chartAreaSize, clusterColorMap],
  );

  return (
    <ScatterPlotProvider points={points} comparedPoints={comparedPoints}>
      <Scatter2DContainer />
    </ScatterPlotProvider>
  );
}
