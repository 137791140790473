import React, {
  ComponentProps,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';

import { Box } from '@superb-ai/ui';
import { startCase } from 'lodash';

import { useChangeTitle } from '../../../hooks/useChangeTitle';
import graphicBg from './assets/graphic_bg.png';
import graphicBottomBg from './assets/graphic_bottom_bg.png';
import Testimonial from './content/Testimonial';
import ValueProposition from './content/ValueProposition';
import Logo from './Logo';

export const contents = {
  graphic: {
    bg: graphicBg.src,
    content: null,
  },
  testimonialIntutivo: {
    bg: graphicBottomBg.src,
    content: <Testimonial company="intuitivo" />,
  },
  testimonialAutonet: {
    bg: graphicBottomBg.src,
    content: <Testimonial company="autonet" />,
  },
  valueProposition: {
    bg: graphicBottomBg.src,
    content: <ValueProposition />,
  },
} as const;

interface ContentProps {
  contentName: keyof typeof contents;
  setContentName: (value: keyof typeof contents) => void;
}

const contentContext = createContext<ContentProps>({
  contentName: 'graphic',
  setContentName: () => {},
});

const withContentContext =
  <T extends React.FC<any>>(Component: T) =>
  (props: ComponentProps<T>) => {
    const [contentName, setContentName] = useState<keyof typeof contents>('graphic');

    return (
      <contentContext.Provider value={{ contentName, setContentName: v => setContentName(v) }}>
        <Component {...props} />
      </contentContext.Provider>
    );
  };

export const useContent = () => useContext(contentContext);

export const useChangeContent = (contentName: keyof typeof contents) => {
  const { setContentName } = useContent();

  useEffect(() => {
    setContentName(contentName);
  }, [contentName, setContentName]);
};

export default withContentContext(function AuthLayout({ children }: { children: ReactNode }) {
  const { contentName } = useContent();
  const { bg, content } = contents[contentName];
  const title = useLocation().pathname.split('/')[2];

  useChangeTitle(`${startCase(title)} - Superb Platform`);

  return (
    <Box
      width="100vw"
      height="100vh"
      backgroundColor="gray-100"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        style={{ minWidth: '1000px', minHeight: '700px', width: '1000px', height: '700px' }}
        backgroundColor="white"
      >
        <Box width="50%" height="100%" position="relative">
          <img
            width="100%"
            height="100%"
            src={bg}
            style={{ position: 'absolute', objectFit: 'contain' }}
          />
          <Box
            position="relative"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={6}
            style={{ boxSizing: 'border-box' }}
          >
            <Logo style={{ top: '50px', left: '50px' }} />
            {content}
          </Box>
        </Box>
        <Box
          position="relative"
          width="50%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={6}
          style={{ boxSizing: 'border-box' }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
