import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@superb-ai/icons';
import { Box, Icon, Input, LoadingIndicator, Typography } from '@superb-ai/ui';
import { without } from 'lodash';

import { Circle } from './components/Circle';
import FilterItem from './FilterItem';
import { getCategoryState, getHighlightedText, searchResults, searchResultsWithKey } from './utils';

export type IdToInfoMap = Record<
  string,
  {
    name?: string;
    color?: string;
    colorWithOpacity?: string;
    size?: number;
    share?: number;
  }
>;

export default function SearchableMultiSelectFilter(props: {
  prefix?: React.ReactNode;
  displayedItems: (string | null)[] | undefined;
  selectedItems: (string | null)[] | undefined;
  hoveredFilterGroup?: string | null;
  setHoveredFilterGroup?: React.Dispatch<React.SetStateAction<string | null>>;
  hoveredDotGroup?: string | null;
  setHoveredDotGroup?: React.Dispatch<React.SetStateAction<string | null>>;
  onClickCheckbox?: (items: string[]) => void;
  idToInfoMap?: IdToInfoMap; // TODO (moon) make type generic
  isLoading?: boolean;
  disabledAllItems?: boolean;
  getIsDisabled?: (item: string) => boolean;
  getTooltipContent?: () => string | JSX.Element;
}) {
  const { t } = useTranslation();
  const {
    prefix,
    displayedItems,
    selectedItems,
    hoveredFilterGroup,
    setHoveredFilterGroup,
    hoveredDotGroup,
    setHoveredDotGroup,
    onClickCheckbox,
    idToInfoMap,
    isLoading,
    disabledAllItems,
    getIsDisabled,
    getTooltipContent,
  } = props;
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  useEffect(() => {
    if (displayedItems) setSearchResult(displayedItems);
  }, [displayedItems]);

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    if (!displayedItems) return;
    const searchKey = event.target.value;
    if (idToInfoMap) {
      const searchItems = displayedItems.map(id => {
        return {
          name: idToInfoMap?.[id]?.name ?? id,
          id,
        };
      });
      setSearchInput(searchKey);
      const filteredItems = searchResultsWithKey(searchItems, 'name', searchKey);
      setSearchResult(filteredItems.map(d => d.id));
    } else {
      setSearchInput(searchKey);
      const filteredItems = searchResults(displayedItems, searchKey);
      setSearchResult(filteredItems);
    }
  }

  const categoryState: boolean | 'mixed' = getCategoryState(displayedItems, selectedItems);

  function handleClickItemCheckbox(item: string) {
    const newSelectedItems =
      selectedItems && selectedItems.indexOf(item) >= 0
        ? without(selectedItems, item)
        : [...(selectedItems || []), item];

    onClickCheckbox && onClickCheckbox(newSelectedItems);
  }

  function handleHoverItem(id: string | null) {
    if (id) {
      setHoveredFilterGroup && setHoveredFilterGroup(id);
      setHoveredDotGroup && setHoveredDotGroup(null);
      return;
    }
    setHoveredFilterGroup && setHoveredFilterGroup(null);
    setHoveredDotGroup && setHoveredDotGroup(null);
  }

  return (
    <Box
      overflow="auto"
      display="grid"
      style={{ gridTemplateRows: prefix ? 'auto auto 1fr' : 'auto 1fr', flex: 1 }}
      gap={0.5}
    >
      {prefix && prefix}
      <Input
        value={searchInput}
        onChange={handleSearch}
        prefix={<Icon icon={Search} />}
        placeholder={t('text.search')}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Box overflow="auto">
          {searchResult?.map(item => {
            const nameOrId = idToInfoMap?.[item]?.name ?? item;
            const text = getHighlightedText(nameOrId || item, searchInput || '');
            const isSelected = categoryState && !!selectedItems && selectedItems.includes(item);
            const color = idToInfoMap ? idToInfoMap[item]?.color : undefined;
            const colorWithOpacity = idToInfoMap ? idToInfoMap[item]?.colorWithOpacity : undefined;
            const share = idToInfoMap ? idToInfoMap[item]?.share : undefined;
            const isHighlighted = (hoveredDotGroup && hoveredDotGroup === item) || false;
            const isDisabled = getIsDisabled ? getIsDisabled(item) : false;
            const isHovered = (hoveredFilterGroup && hoveredFilterGroup === item) || false;

            return (
              <FilterItem
                key={item}
                id={item}
                text={text}
                isSelected={isSelected}
                isHighlighted={isHighlighted}
                isHovered={isHovered}
                handleHoverItem={handleHoverItem}
                disabled={disabledAllItems || isDisabled}
                getTooltipContent={getTooltipContent}
                handleClickItemCheckbox={handleClickItemCheckbox}
                prefix={
                  <Circle
                    isNull={item === null}
                    color={color}
                    colorWithOpacity={colorWithOpacity}
                  />
                }
                suffix={
                  share ? (
                    <Typography variant="m-regular" mr={1} ml="auto">
                      {share?.toLocaleString('en') + ' %'}
                    </Typography>
                  ) : undefined
                }
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}
