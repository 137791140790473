import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import Layout from '../../../components/pages/account/billingAndUsage/Layout';
import { isOwnerOrAdmin } from '../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../menu/MenuItem';

export const BillingMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountBillingAndUsage,
  path: 'billing',
  title: ({ t }) => t('settings.billing.title'),
  component: Layout,
  icon: 'settings',
  isVisible({ authInfo }) {
    return isOwnerOrAdmin(authInfo);
  },
  isEnabled({ authInfo }) {
    return isOwnerOrAdmin(authInfo);
  },
});
