import React from 'react';

import { Typography } from '@superb-ai/norwegian-forest';

import ProjectUtils from '../../../../../utils/ProjectUtils';

const Layout: React.FC<{ labelInterface: any }> = ({ labelInterface }) => {
  return (
    <Typography variant="body2">
      {ProjectUtils.getAnnotationTypes(labelInterface)
        .map(({ label }) => label)
        .join(', ')}
    </Typography>
  );
};

export default Layout;
