const getShapeColor = ({ activeCompareOptionArr, compareOptionColor, shape, classes }) => {
  const useCompareOptionColor = activeCompareOptionArr?.length >= 2;
  if (useCompareOptionColor) {
    return compareOptionColor;
  }
  return shape.class ? classes[shape.class].color[shape.type] : compareOptionColor;
};

export default {
  getShapeColor,
};
