import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  DropdownList,
  Icon,
  Typography,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';

import { useNewProjectInfo } from '../../../../../contexts/NewProjectContext';
import ButtonWithTooltip from './ButtonWithTooltip';
import { Mode } from './const';
import EdgesEditor from './EdgesEditor';
import KeypointVisualizer from './KeypointVisualizer';
import PointsEditor from './PointsEditor';
import { Edge, Keypoint, Point } from './type';

type Props = {
  editMode: boolean;
  setMode: (mode: Mode) => void;
  createdKeypointId: string;
  setCreatedKeypointId: (id: string) => void;
};

const KeypointDialogSelectLayout: React.FC<Props> = ({
  editMode,
  setMode,
  createdKeypointId,
  setCreatedKeypointId,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useAlertModal();
  const newProjectInfo = useNewProjectInfo();
  const objectClass = newProjectInfo.objectClasses[newProjectInfo.selectedObjectClassIndex];
  const [mount, setMount] = useState(false);
  const [selectedKeypointId, setSelectedKeypointId] = useState('');
  const [curKeypoint, setCurKeypoint] = useState<Keypoint | null>(null);
  const [editPoints, setEditPoints] = useState<Point[]>([]);
  const [editEdges, setEditEdges] = useState<Edge[]>([]);
  const [editErrorMessage, setEditErrorMessage] = useState('');

  useEffect(() => {
    // init
    if (!newProjectInfo.isKeypointDialogOpen || !objectClass) return;
    setMount(true);

    const nextCurKeypoint = cloneDeep(objectClass.keypointInfo.template);

    if (createdKeypointId) {
      setSelectedKeypointId(createdKeypointId);
      setCurKeypoint(
        newProjectInfo.keypointList.find((keypoint: Keypoint) => keypoint.id === createdKeypointId),
      );
      setCreatedKeypointId('');
      return;
    }

    newProjectInfo.keypointList, nextCurKeypoint;

    if (nextCurKeypoint) {
      setSelectedKeypointId(nextCurKeypoint.id);
      setCurKeypoint(nextCurKeypoint);
      return;
    } else {
      if (newProjectInfo.keypointList.length === 0) {
        setCurKeypoint(null);
        return;
      } else {
        setCurKeypoint(cloneDeep(newProjectInfo.keypointList[0]));
        setSelectedKeypointId(newProjectInfo.keypointList[0].id);
        return;
      }
    }

    // eslint-disable-next-line
  }, [newProjectInfo.isKeypointDialogOpen]);

  const handleChangeKeypoint = (id: string) => {
    const nextCurKeypoint = newProjectInfo.keypointList.find(
      (keypoint: Keypoint) => keypoint.id === id,
    );
    setSelectedKeypointId(id);
    setCurKeypoint(cloneDeep(nextCurKeypoint));
  };

  const handleClickAdd = () => {
    setMode('CREATE');
  };

  const handleClickEdit = () => {
    if (!curKeypoint) return;
    setMode('EDIT');
    setEditPoints(cloneDeep(curKeypoint.points));
    setEditEdges(cloneDeep(curKeypoint.edges));
    setEditErrorMessage('');
  };

  const handleClickDelete = () => {
    if (!curKeypoint) return;
    openModal({
      title: t('projects.createProject.keypoint.deleteCustomKeypoint'),
      content: t('projects.createProject.keypoint.deleteCustomKeypointDescription'),
      mainButton: {
        text: t('button.delete'),
        onClick: () => {
          let nextCurKeypoint: null | Keypoint = null;
          const nextKeypointList = cloneDeep(newProjectInfo.keypointList);
          const idx = nextKeypointList.findIndex(
            (keypoint: Keypoint) => keypoint.id === curKeypoint.id,
          );

          if (idx !== -1) nextKeypointList.splice(idx, 1);

          if (nextKeypointList.length !== 0) {
            nextCurKeypoint = nextKeypointList[0];
          }

          const nextObjectClasses = newProjectInfo.objectClasses;

          nextObjectClasses.map((OC: any) => {
            if (OC.id === objectClass.id) {
              OC.keypointInfo.template = nextCurKeypoint;
            } else if (OC?.keypointInfo?.template?.id === curKeypoint.id) {
              OC.annotationType = 'SELECT_TYPE';
              delete OC.keypointInfo;
            }
            return OC;
          });

          newProjectInfo.setObjectClasses(nextObjectClasses);
          newProjectInfo.setKeypointList(nextKeypointList);
          setCurKeypoint(nextCurKeypoint);
          setSelectedKeypointId(nextCurKeypoint ? nextCurKeypoint.id : '');
          closeModal();
        },
      },
      subButton: {
        text: t('button.cancel'),
        onClick: () => closeModal(),
      },
    });
  };

  const handleClickSelect = () => {
    if (!curKeypoint) return;
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
    const nextTemplate = cloneDeep(curKeypoint);
    const curObjectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
    curObjectClass.keypointInfo.template = nextTemplate;

    newProjectInfo.setObjectClasses(nextObjectClasses);
    newProjectInfo.setIsKeypointDialogOpen(false);
  };

  const handleClickCancelSelect = () => {
    if (!objectClass.keypointInfo.template) {
      const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
      const curObjectClass = nextObjectClasses[newProjectInfo.selectedObjectClassIndex];
      delete curObjectClass.keypointInfo;
      curObjectClass.annotationType = 'SELECT_TYPE';
      newProjectInfo.setObjectClasses(nextObjectClasses);
    }

    newProjectInfo.setIsKeypointDialogOpen(false);
  };

  const handleClickEditDone = () => {
    const nextKeypointList = cloneDeep(newProjectInfo.keypointList);
    const curKeypoint = nextKeypointList.find(
      (keypoint: Keypoint) => keypoint.id === selectedKeypointId,
    );
    if (curKeypoint) {
      curKeypoint.points = cloneDeep(editPoints);
      curKeypoint.edges = cloneDeep(editEdges);
      setCurKeypoint(cloneDeep(curKeypoint));
      const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
      nextObjectClasses.map((OC: any) => {
        if (OC?.keypointInfo?.template?.id === curKeypoint.id) {
          OC.keypointInfo.template = cloneDeep(curKeypoint);
        }

        return OC;
      });
      newProjectInfo.setObjectClasses(nextObjectClasses);
    }
    newProjectInfo.setKeypointList(nextKeypointList);

    setMode('SELECT');
  };

  const handleClickEditCancel = () => {
    setMode('SELECT');
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="16px"
    >
      <Box display="flex" alignItems="center" gap="8px">
        {!editMode && (
          <>
            <Box display="flex" width="220px">
              {mount && (
                <DropdownList
                  placeholder="Select keypoint"
                  options={newProjectInfo.keypointList.map((keypoint: Keypoint) => ({
                    label: keypoint.name,
                    value: keypoint.id,
                  }))}
                  value={selectedKeypointId}
                  onChange={handleChangeKeypoint}
                />
              )}
            </Box>

            <Button onClick={handleClickAdd} color="grey">
              {t('button.add')}
            </Button>

            {curKeypoint && (
              <>
                <Button onClick={handleClickEdit} color="grey" variant="stroke">
                  {t('button.edit')}
                </Button>
                <Button onClick={handleClickDelete} color="grey" variant="stroke">
                  {t('button.delete')}
                </Button>
              </>
            )}
          </>
        )}
      </Box>

      {newProjectInfo.keypointList.length === 0 ? (
        <Box width="100%" height="220px" display="flex" alignItems="center" zIndex={10}>
          <Box
            position="absolute"
            top="48px"
            right="0px"
            bottom="0px"
            left="0px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" width="100%" height="100%">
              <Button color="grey" variant="soft-fill" onClick={handleClickAdd}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <div style={{ transform: 'scale(4)' }}>
                    <Icon name="plus" customColor="#c0c0c0" />
                  </div>
                  <p style={{ margin: 0, color: '#c0c0c0' }}>
                    <Typography variant="headline6" themedColor="inherit">
                      {t('projects.createProject.keypoint.clickToCreateKeypoint')}
                    </Typography>
                  </p>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box width="100%" height="500px" display="flex" gap="16px">
          <Box
            width="400px"
            height="100%"
            display="flex"
            alignItems="center"
            shadow={1}
            borderRadius="8px"
          >
            <KeypointVisualizer
              width={400}
              height={400}
              keypoint={curKeypoint}
              useCopyJson={!editMode}
            />
          </Box>
          <Box width="300px" height="100%" display="flex" flexDirection="column" gap="16px">
            <Box width="100%" flex={1} overflow="hidden" shadow={1} borderRadius="8px">
              <PointsEditor
                keypointId={curKeypoint?.id || ''}
                points={!editMode ? curKeypoint?.points || [] : editPoints}
                {...{ editMode, setEditPoints, setEditErrorMessage }}
              />
            </Box>
            <Box width="100%" flex={1} overflow="hidden" shadow={1} borderRadius="8px">
              <EdgesEditor
                keypointId={curKeypoint?.id || ''}
                edges={!editMode ? curKeypoint?.edges || [] : editEdges}
                {...{ editMode, setEditEdges, setEditErrorMessage }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box position="absolute" bottom="-44px" right="0" display="flex" gap="8px">
        {!editMode ? (
          <>
            <Button color="grey" variant="text" onClick={handleClickCancelSelect}>
              {t('button.cancel')}
            </Button>
            <ButtonWithTooltip
              Comp={
                <Button color="grey" onClick={handleClickSelect}>
                  {t('shared.select')}
                </Button>
              }
              cond={() => {
                if (newProjectInfo.keypointList.length === 0) {
                  return true;
                }
                return false;
              }}
              props={{ disabled: true }}
              text={t('projects.createProject.keypoint.addKeypoint')}
            />
          </>
        ) : (
          <>
            <Button color="grey" variant="text" onClick={handleClickEditCancel}>
              {t('button.cancel')}
            </Button>
            <ButtonWithTooltip
              Comp={
                <Button color="grey" onClick={handleClickEditDone}>
                  {t('button.done')}
                </Button>
              }
              cond={() => editErrorMessage !== ''}
              props={{ disabled: true }}
              text={editErrorMessage}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default KeypointDialogSelectLayout;
