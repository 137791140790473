import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';
import { getColor } from '@superb-ai/ui';

const useStyles = makeStyles(theme => ({
  nested: (props: { paddingLeft: number | string }) => ({
    paddingLeft: props.paddingLeft,
  }),
  listIcon: {
    minWidth: theme.spacing(3),
  },
}));

interface Props {
  item: any;
  onClickCheck: (value: any) => (event: React.MouseEvent<HTMLDivElement>) => void;
  selectedOption: any;
  flatAnnoOptions: any;
  hierarchy: number;
}

const FilterClassDropdownListItem: React.FC<Props> = props => {
  const { item, onClickCheck, flatAnnoOptions, hierarchy, selectedOption } = props;
  const classes = useStyles({ paddingLeft: `${hierarchy * 16}px` });
  const [open, setOpen] = useState(true);

  const handleClickChevron = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  if (!item.children) {
    return (
      <MUI.ListItem
        style={item.value === selectedOption?.value ? { background: getColor('primary-100') } : {}}
        dense
        button
        className={classes.nested}
        onClick={onClickCheck({ value: item.value, label: item.label })}
      >
        <MUI.ListItemText primary={item.label} />
      </MUI.ListItem>
    );
  }

  return (
    <>
      <MUI.ListItem
        dense
        button
        className={classes.nested}
        onClick={onClickCheck({ value: item.value, label: item.label })}
      >
        <MUI.ListItemText primary={item.label} />
        <MUI.IconButton size="small" onClick={handleClickChevron}>
          {open ? <Icon name="chevronDown" size="12px" /> : <Icon name="chevronLeft" size="12px" />}
        </MUI.IconButton>
      </MUI.ListItem>
      <MUI.Collapse in={open} unmountOnExit timeout="auto">
        {item.children.map((child: any) => (
          <FilterClassDropdownListItem
            key={child.value}
            hierarchy={hierarchy + 1}
            item={child}
            onClickCheck={onClickCheck}
            flatAnnoOptions={flatAnnoOptions}
            selectedOption={selectedOption}
          />
        ))}
      </MUI.Collapse>
    </>
  );
};

export default FilterClassDropdownListItem;
