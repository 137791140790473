import React, { useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import ClassPropertyItemDropBar from './ClassPropertyItemDropBar';
import ClassPropertyItemEditableText from './ClassPropertyItemEditableText';
import ClassPropertyItemFreeResponse from './ClassPropertyItemFreeResponse';
import ClassPropertyItemMultipleList from './ClassPropertyItemMultipleList';
import ClassPropertyItemPerFrameToggle from './ClassPropertyItemPerFrameToggle';
import ClassPropertyItemRequiredToggle from './ClassPropertyItemRequiredToggle';
import ClassPropertyItemSelect from './ClassPropertyItemSelect';

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    padding: '12px 20px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2px',
    cursor: 'pointer',
    '&:hover': {
      background: '#fff6f6',
    },
    '&.selected': {
      background: '#ffeeee',
    },
  },
  selectedBar: {
    position: 'absolute',
    width: '1px',
    left: 0,
    top: 0,
    bottom: 0,
    background: '#ff625a',
  },
  header: {
    // width: "100%",
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    width: '100%',
    height: '100%',
  },
  headerRight: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  section: {},
}));

// eslint-disable-next-line
const ClassPropertyItem = (props: any): React.ReactElement => {
  const classes = useStyles();
  const classPropertyRef = useRef<HTMLDivElement>();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;
  const isSelected = newProjectInfo.selectedClassPropertyIndex === index;
  const [isSequentialData] = useState(
    WorkappUnion.isImageSequence(newProjectInfo.selectedDataType) ||
      WorkappUnion.isPointclouds(newProjectInfo.selectedDataType),
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    newProjectInfo.setSelectedClassPropertyIndex(index);
  };

  const getPropertyOption = () => {
    switch (classProperty.type) {
      case 'MULTIPLE_CHOICE':
      case 'MULTIPLE_SELECTION':
        return <ClassPropertyItemMultipleList classProperty={classProperty} index={index} />;
      case 'FREE_RESPONSE':
        return <ClassPropertyItemFreeResponse classProperty={classProperty} index={index} />;
      default:
        return null;
    }
  };
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (!classPropertyRef || !classPropertyRef.current) return;

    newProjectInfo.setDragState({ type: 'classProperty', index });
    classPropertyRef.current.style.cursor = 'grabbing';
    if (!e?.dataTransfer) return;
    e.dataTransfer.setData('text', index);
    e.dataTransfer.setDragImage(classPropertyRef.current, -10, -10);
  };
  const handleDragEnd = () => {
    if (!classPropertyRef || !classPropertyRef.current) return;

    newProjectInfo.setDragState(null);
    classPropertyRef.current.style.cursor = 'pointer';
  };

  return (
    <MUI.Box position="relative">
      {index === 0 ? <ClassPropertyItemDropBar dir="prev" index={index} /> : null}
      <MUI.Box
        {...{ ref: classPropertyRef }}
        className={`${classes.box} ${isSelected ? 'selected' : ''}`}
        boxShadow={1}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable
      >
        {isSelected ? <MUI.Box className={classes.selectedBar} /> : null}
        <MUI.Box className={classes.header}>
          <MUI.Box className={classes.headerLeft}>
            <ClassPropertyItemEditableText classProperty={classProperty} index={index} />
          </MUI.Box>
          <MUI.Box className={classes.headerRight}>
            {isSequentialData && (
              <ClassPropertyItemPerFrameToggle classProperty={classProperty} index={index} />
            )}
            <ClassPropertyItemRequiredToggle classProperty={classProperty} index={index} />
            <ClassPropertyItemSelect classProperty={classProperty} index={index} />
          </MUI.Box>
        </MUI.Box>
        <MUI.Box className={classes.section}>{getPropertyOption()}</MUI.Box>
      </MUI.Box>
      <ClassPropertyItemDropBar dir="next" index={index} />
    </MUI.Box>
  );
};

export default ClassPropertyItem;
