import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  keypointInfoBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  keypointInfoText: {
    fontSize: '11px',
    color: '#797979',
    marginLeft: '70px',
    maxWidth: '230px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  keypointInfoButton: {
    fontSize: '10px',
    padding: '4px 8px',
    marginLeft: '12px',
  },
}));

// eslint-disable-next-line
const ObjectClassItemMoreInformation = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { objectClass, index } = props;
  const newProjectInfo = useNewProjectInfo();

  const getKeypointInfo = () => {
    if (!objectClass.keypointInfo) return null;

    const handleClickEditTemplate = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      newProjectInfo.setSelectedObjectClassIndex(index);
      newProjectInfo.setIsKeypointDialogOpen(true);
    };

    if (!objectClass?.keypointInfo?.template) return null;

    return (
      <MUI.Box className={classes.keypointInfoBox}>
        <MUI.Typography className={classes.keypointInfoText}>
          {helper.parseKeypointTemplateName(objectClass.keypointInfo.template)}
        </MUI.Typography>
        <MUI.Button
          className={classes.keypointInfoButton}
          variant="contained"
          onClick={handleClickEditTemplate}
        >
          {t('projects.createProject.editTemplate')}
        </MUI.Button>
      </MUI.Box>
    );
  };

  return <MUI.Box className={classes.box}>{getKeypointInfo()}</MUI.Box>;
};

export default ObjectClassItemMoreInformation;
