import React from 'react';

import * as MUI from '@mui/material';

import { issues } from '../../../../utils/filter/labelFilter';
import { useOptions } from '../../../../utils/filter/useOptions';
import FilterDropdown from './components/FilterDropdown';
import FilterWithSearch from './components/FilterWithSearch';
import FilterAssigneeWithSearch from './FilterAssigneeWithSearch';
import { FilterTypeProps } from './types';

const FilterIssue: React.FC<FilterTypeProps> = props => {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  const options = useOptions({
    options: issues.options,
    i18nKeyPrefix: 'labels.filters.option.issue',
  });
  const selectedOptions = useOptions({
    options: filter.options,
    i18nKeyPrefix: 'labels.filters.option.issue',
  });

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={issues.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      {!filter.condition.startsWith('no') && filter.condition.indexOf('type') === -1 && (
        <MUI.Grid item style={{ flex: 1 }}>
          <FilterAssigneeWithSearch
            index={index}
            filter={filter}
            value={selectedOptions}
            isCurrentRow={isCurrentRow}
            onValueChange={onValueChange}
            onChangeCurrentRow={onChangeCurrentRow}
          />
        </MUI.Grid>
      )}
      {filter.condition.indexOf('type') !== -1 && (
        <MUI.Grid item style={{ flex: 1 }}>
          <FilterWithSearch
            index={index}
            objKey="options"
            filterBy={filter.filterBy}
            options={options}
            value={selectedOptions}
            onValueChange={onValueChange}
            isCurrentRow={isCurrentRow}
            onChangeCurrentRow={onChangeCurrentRow}
          />
        </MUI.Grid>
      )}
    </>
  );
};

export default FilterIssue;
