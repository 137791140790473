import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import { Button, Dialog, Typography, useDialogState } from '@superb-ai/ui';

import { getUrl } from '../../../../routes/util';
import { GENERATION_MODEL_LIST, RECOGNITION_MODEL_LIST } from '../../path';
import { useDeleteModelMutation } from '../../queries/modelQueries';
import { PurposeType } from '../../services/types';

export const ConfirmDeleteModelDialog = ({
  state,
  modelId,
}: {
  state: ReturnType<typeof useDialogState>;
  modelId: string;
}) => {
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useDeleteModelMutation();
  const { params } = useRouteMatch<{ accountName: string; purpose: PurposeType }>();
  const history = useHistory();

  return (
    <Dialog state={state} hideOnClickOutside={false} hideOnEsc={false} style={{ width: 520 }}>
      <Dialog.Header onClickClose={() => void state.hide()}>
        {t('model.confirmDeleteModelDialog.title')}
      </Dialog.Header>
      <Typography variant="m-regular">
        <Trans t={t} i18nKey={'model.confirmDeleteModelDialog.content'} />
      </Typography>
      <Dialog.Actions>
        <Button onClick={() => state.setVisible(false)} variant="text">
          {t('shared.cancel')}
        </Button>
        <Button
          color="primary"
          variant="strong-fill"
          onClick={() => {
            deleteMutate(modelId);
            history.push(
              getUrl(
                [
                  params.accountName,
                  params.purpose === 'generation' ? GENERATION_MODEL_LIST : RECOGNITION_MODEL_LIST,
                ],
                {},
                {},
              ),
            );
            state.hide();
          }}
        >
          {t('model.confirmDeleteModelDialog.mainButton')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
