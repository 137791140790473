import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box, Button, Typography } from '@superb-ai/ui';

import InvalidAccess from '../assets/invalid_access.png';
import InvalidUserRole from '../assets/invalid_user_role.png';
import SystemError from '../assets/system_error.png';

export function PaymentInvalid() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" mb={1.5}>
        {t('payments.invalidAccess')}
      </Typography>
      <Typography variant="l-regular" mb={2}>
        {t('payments.useValidLink')}
      </Typography>
      <img src={InvalidAccess.src} width="424px" />
    </Box>
  );
}

export function UserRoleInvalid() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" mb={1.5}>
        {t('payments.invalidUserRole')}
      </Typography>
      <Typography variant="l-regular" mb={2}>
        {t('payments.loginWithOwner')}
      </Typography>
      <img src={InvalidUserRole.src} width="424px" />
    </Box>
  );
}

export function AlreadyPaid() {
  const history = useHistory();
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" mb={1.5}>
        {t('payments.alreadyPaid')}
      </Typography>
      <Typography variant="l-regular" mb={2}>
        <Trans i18nKey="payments.alreadyPaidDescription" />
      </Typography>
      <Button
        variant="strong-fill"
        onClick={() => history.push(`/${accountName}/settings/billing`)}
      >
        {t('button.view')}
      </Button>
      <img src={InvalidUserRole.src} width="424px" />
    </Box>
  );
}

function ServiceError() {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h1" mb={1.5}>
        {t('payments.systemError')}
      </Typography>
      <Typography variant="l-regular" mb={2}>
        {t('payments.systemErrorDescription')}
      </Typography>
      <img src={SystemError.src} width="528px" />
    </Box>
  );
}

export type AwsPaymentEnableError = 'paymentInvalid' | 'serviceError' | 'alreadyPaid';

export function ErrorHandler({ error }: { error: AwsPaymentEnableError }) {
  switch (error) {
    case 'paymentInvalid':
      return <PaymentInvalid />;
    case 'alreadyPaid':
      return <AlreadyPaid />;
    case 'serviceError':
      return <ServiceError />;
  }
}
