import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { map } from 'lodash';

const useStyles = makeStyles(() => ({
  optionBox: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    borderRadius: '7px',
    overflow: 'hidden',
    minWidth: '120px',
  },
  optionButton: {
    borderRadius: '0px',
    fontSize: '12px',
    color: '#635c5c',
    fontWeight: 400,
    borderBottom: 'solid 1px #e8e8e8',
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    width: '100%',
  },
}));

// eslint-disable-next-line
const Menu = (props: any): React.ReactElement => {
  const classes = useStyles();
  const { menuItems, pos } = props;

  return (
    <MUI.Box className={classes.optionBox} style={{ ...pos }} boxShadow={1}>
      {map(
        menuItems,
        item =>
          item.title && (
            <MUI.Button className={classes.optionButton} key={item.title} onClick={item.onClick}>
              {item.title}
            </MUI.Button>
          ),
      )}
    </MUI.Box>
  );
};

export default Menu;
