import { ComponentProps, Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Checkbox as CheckboxUnchecked,
  CheckboxChecked,
  CheckboxMixed,
  CheckFilled,
  InfoFilled,
  LoadingSpinnerSmall,
  OffClose,
  WarningFilled,
} from '@superb-ai/icons';
import { Box, extendComponent, Icon, Table, Tooltip, Typography } from '@superb-ai/ui';
import { capitalize } from 'lodash';

import { Row } from '../../../../components/elements/Row';
import { formatCount, formatNumber } from '../../../../utils/numberFormat';
import { getAnnotationIcon } from '../../../Curate/components/datasets/dataset/views/grid/utils';
import {
  RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER,
  RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
  RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
  RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER,
} from '../../constant';
import { AnnotationType, PurposeType } from '../../services/types';
import { useBaselineContext } from '../contexts/BaselineContext';

export const DetectionSelect = ({
  annotationType,
  isLoading,
  isPreselected,
  isManualSplit,
  classList,
  selectedAnnotationNames,
  setSelectedAnnotationNames,
  trainingSetDataCount,
  validationSetDataCount,
}: {
  annotationType: AnnotationType;
  isLoading: boolean;
  isPreselected: boolean;
  isManualSplit: boolean;
  classList: {
    name: string;
    trainSetAnnotationCount: number;
    validationSetAnnotationCount: number;
  }[];
  selectedAnnotationNames: { type: string; name: string }[];
  setSelectedAnnotationNames: Dispatch<SetStateAction<{ type: string; name: string }[]>>;
  trainingSetDataCount: number;
  validationSetDataCount: number;
}) => {
  const { t } = useTranslation();
  const { modelPurpose } = useBaselineContext();

  const isSatisfyRecommendedImageCount =
    modelPurpose === 'generation'
      ? trainingSetDataCount >= RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER
      : isManualSplit
      ? trainingSetDataCount >=
          RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT &&
        validationSetDataCount >=
          RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT
      : trainingSetDataCount >=
        RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE;

  function toggleSelected(classList: { type: string; name: string }) {
    setSelectedAnnotationNames(selectedNames => {
      const isExpanded = selectedNames.find(g => g.name === classList.name);
      return isExpanded
        ? selectedNames.filter(g => g.name !== classList.name)
        : [...selectedNames, classList];
    });
  }

  const isToggleSelectable = useCallback(
    (classList: {
      name: string;
      trainSetAnnotationCount: number;
      validationSetAnnotationCount: number;
    }) => {
      return (
        (isManualSplit
          ? classList.trainSetAnnotationCount && classList.validationSetAnnotationCount
          : classList.trainSetAnnotationCount) && isSatisfyRecommendedImageCount
      );
    },
    [isManualSplit, isSatisfyRecommendedImageCount],
  );

  const selectableClassList = classList.filter(x => !isToggleSelectable(x));

  const selectAllState =
    classList.length - selectableClassList.length === selectedAnnotationNames?.length &&
    classList.length - selectableClassList.length > 0
      ? true
      : selectedAnnotationNames.length > 0
      ? 'mixed'
      : false;

  function toggleSelectAll() {
    if (selectAllState === true) {
      setSelectedAnnotationNames([]);
    } else {
      const allNames = classList
        .filter(_ => isToggleSelectable(_))
        .map(_ => ({
          name: _.name,
          type: annotationType,
        }));
      setSelectedAnnotationNames(allNames);
    }
  }

  useEffect(() => {
    // 선택된 slice에 따라 선택 불가능한 class들은 선택취소되어야함
    classList.forEach(x => {
      if (!isToggleSelectable(x)) {
        setSelectedAnnotationNames(selectedNames => {
          return selectedNames.filter(g => g.name !== x.name);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (modelPurpose !== 'generation') return;
    const selectedClassList = classList.map(x => ({ name: x.name, type: annotationType }));
    setSelectedAnnotationNames(selectedClassList);
  }, [annotationType, classList, modelPurpose, setSelectedAnnotationNames]);

  return (
    <>
      <SelectedAnnotationHeader
        annotationType={annotationType}
        label={
          isPreselected
            ? t('model.train.detectionsN', { count: classList.length })
            : t('model.train.selectedNofM', {
                count: selectedAnnotationNames.length,
              })
        }
      />
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <Icon icon={LoadingSpinnerSmall} color={'primary-400'} size={20} />
        </Box>
      ) : (
        <Table.Table
          variant="simple"
          width="100%"
          role="listbox"
          style={{ paddingLeft: isPreselected ? undefined : 8 }}
        >
          {/* Header */}
          <Table.Head sticky backgroundColor="white">
            <tr>
              <Table.HeadCell style={{ width: isManualSplit ? '60%' : '80%' }}>
                <Row gap={1}>
                  {!isPreselected && (
                    <Tooltip
                      hideOnEmptyContent
                      placement="top"
                      content={
                        !isSatisfyRecommendedImageCount
                          ? t('model.train.selectMoreDataset')
                          : undefined
                      }
                    >
                      <Icon
                        icon={getCheckboxIcon(selectAllState)}
                        size="16px"
                        onClick={toggleSelectAll}
                        color={
                          selectAllState
                            ? 'primary-400'
                            : isSatisfyRecommendedImageCount
                            ? undefined
                            : 'gray-200'
                        }
                      />
                    </Tooltip>
                  )}
                  {t('model.myModelDetail.classname')}
                </Row>
              </Table.HeadCell>
              {isManualSplit ? (
                <>
                  <Table.HeadCell textAlign="right">{t('model.train.trainSet')}</Table.HeadCell>
                  <Table.HeadCell textAlign="right">
                    {t('model.train.validationSet')}
                  </Table.HeadCell>
                </>
              ) : (
                <Table.HeadCell textAlign="right">{t('analytics.text.total')}</Table.HeadCell>
              )}
              <Table.HeadCell textAlign="center">
                <Row gap={0.5}>
                  {t('model.train.status')}
                  <Tooltip
                    strategy="fixed"
                    placement="top"
                    content={
                      modelPurpose === 'generation' ? (
                        <Trans
                          t={t}
                          i18nKey={'model.train.automaticallyRecommandedWarning'}
                          values={{
                            counts: formatNumber(RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER),
                          }}
                        />
                      ) : isManualSplit ? (
                        <Trans
                          t={t}
                          i18nKey={'model.train.manuallyRecommandedWarning'}
                          values={{
                            train: formatNumber(
                              RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
                            ),
                            valid: formatNumber(
                              RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER,
                            ),
                          }}
                        />
                      ) : (
                        <Trans
                          t={t}
                          i18nKey={'model.train.automaticallyRecommandedWarning'}
                          values={{
                            counts: formatNumber(
                              RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
                            ),
                          }}
                        />
                      )
                    }
                  >
                    <Icon icon={InfoFilled} />
                  </Tooltip>
                </Row>
              </Table.HeadCell>
            </tr>
          </Table.Head>
          {/* Body */}
          <Table.Body>
            {classList.map(x => {
              return (
                <DetectionRow
                  isPreselected={isPreselected}
                  isManualSplit={isManualSplit}
                  selected={Boolean(
                    !isPreselected && selectedAnnotationNames.find(_ => _.name === x.name),
                  )}
                  onToggle={() => {
                    if (isPreselected) return;
                    toggleSelected({ name: x.name, type: annotationType });
                  }}
                  data={x}
                  key={x.name}
                  disabled={!isToggleSelectable(x)}
                  purpose={modelPurpose}
                />
              );
            })}
          </Table.Body>
        </Table.Table>
      )}
    </>
  );
};

function DetectionRow({
  isPreselected,
  isManualSplit,
  selected,
  data,
  onToggle,
  disabled,
  purpose,
}: {
  isPreselected: boolean;
  isManualSplit: boolean;
  selected: boolean;
  data: {
    name: string;
    trainSetAnnotationCount: number;
    validationSetAnnotationCount: number;
  };
  onToggle: () => void;
  disabled: boolean;
  purpose: PurposeType;
}) {
  function getStatusIcon() {
    if (purpose === 'generation') {
      if (data.trainSetAnnotationCount < RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER) {
        return <Icon size={16} icon={WarningFilled} color={'yellow-400'} />;
      } else {
        return <Icon size={16} icon={CheckFilled} color={'green-400'} />;
      }
    }

    if (isManualSplit) {
      if (data.trainSetAnnotationCount === 0 || data.validationSetAnnotationCount === 0) {
        return <Icon size={16} icon={OffClose} color={'red-400'} />;
      } else if (
        data.trainSetAnnotationCount < RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER ||
        data.validationSetAnnotationCount <
          RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER
      ) {
        return <Icon size={16} icon={WarningFilled} color={'yellow-400'} />;
      } else if (
        data.trainSetAnnotationCount > RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER &&
        data.validationSetAnnotationCount >
          RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER
      ) {
        return <Icon size={16} icon={CheckFilled} color={'green-400'} />;
      }
    } else {
      if (data.trainSetAnnotationCount === 0) {
        return <Icon size={16} icon={OffClose} color={'red-400'} />;
      } else if (
        data.trainSetAnnotationCount < RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER
      ) {
        return <Icon size={16} icon={WarningFilled} color={'yellow-400'} />;
      } else {
        return <Icon size={16} icon={CheckFilled} color={'green-400'} />;
      }
    }
  }

  return (
    <SelectableRow
      selected={selected}
      onClick={() => {
        if (disabled) return;
        onToggle();
      }}
    >
      <EllipsisCell>
        <Row gap={1}>
          {!isPreselected && (
            <Icon
              icon={getCheckboxIcon(selected)}
              color={selected ? 'primary' : disabled ? 'gray-200' : undefined}
              size="16px"
              style={{ minWidth: 16 }}
            />
          )}
          <Typography variant="m-regular" color={disabled ? 'gray-200' : undefined}>
            {data.name}
          </Typography>
        </Row>
      </EllipsisCell>
      {isManualSplit ? (
        <>
          <NowrapCell textAlign="right">{formatCount(data.trainSetAnnotationCount)}</NowrapCell>
          <NowrapCell textAlign="right">
            {formatCount(data.validationSetAnnotationCount)}
          </NowrapCell>
        </>
      ) : (
        <NowrapCell textAlign="right">{formatCount(data.trainSetAnnotationCount)}</NowrapCell>
      )}
      <NowrapCell textAlign="center">{getStatusIcon()}</NowrapCell>
    </SelectableRow>
  );
}

function SelectedAnnotationHeader({
  label,
  annotationType,
}: {
  label: string;
  annotationType: AnnotationType;
}) {
  return (
    <Row backgroundColor={'gray-100'} borderRadius="2px" pl={2} style={{ height: 32 }}>
      <Icon icon={getAnnotationIcon(annotationType as any)} style={{ marginRight: 4 }} />
      <Typography variant="m-regular">{capitalize(annotationType)}</Typography>
      <Box mx={1.5} backgroundColor={'gray-300'} style={{ width: 1, height: 8 }} />
      <Typography variant="m-regular">{label}</Typography>
    </Row>
  );
}

function SelectableRow({
  selected,
  ...props
}: { selected: boolean } & ComponentProps<typeof Table.Row>) {
  return (
    <Table.Row
      cursor="pointer"
      backgroundColor={{ default: selected ? 'primary-100' : undefined }}
      role="option"
      aria-selected={selected}
      {...props}
    />
  );
}

const NowrapCell = extendComponent(Table.Cell, {
  whiteSpace: 'nowrap',
});

const EllipsisCell = extendComponent(NowrapCell, {
  maxWidth: '0',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

function getCheckboxIcon(selection: 'mixed' | boolean) {
  return selection === 'mixed' ? CheckboxMixed : selection ? CheckboxChecked : CheckboxUnchecked;
}
