import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  AddIpWhitelistParams,
  UpdateIpWhitelistByIdParams,
  useIpWhitelistService,
} from '../services/IpWhitelistService';

export const useIpWhitelistEnabledQuery = () => {
  const { getIpWhitelistEnabled } = useIpWhitelistService();

  return useQuery(['ipWhitelistEnabled'], () => getIpWhitelistEnabled());
};

export const useIpWhitelistEnabledMutation = (onErrorCallback?: () => void) => {
  const { setIpWhitelistEnabled } = useIpWhitelistService();
  const queryClient = useQueryClient();
  return useMutation(() => setIpWhitelistEnabled(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['ipWhitelistEnabled']);
    },
    onError: () => {
      onErrorCallback?.();
    },
  });
};

export const useMyIpAddressQuery = ({ enabled }: { enabled: boolean }) => {
  const { getMyIpAddress } = useIpWhitelistService();

  return useQuery(['myIpAddress', enabled], () => getMyIpAddress(), { enabled });
};

function convertLastEvaluatedKeyParam(lastEvaluatedKey: {
  tenantId: {
    s: string;
  };
  uniqueKey: {
    s: string;
  };
}) {
  const snakeCase = {
    tenant_id: {
      S: lastEvaluatedKey.tenantId.s,
    },
    unique_key: {
      S: lastEvaluatedKey.uniqueKey.s,
    },
  };
  const param = JSON.stringify(snakeCase);
  return param;
}

export const useIpWhitelistQuery = ({ limit = 10, ...queryParams }) => {
  const { getIpWhitelist } = useIpWhitelistService();

  return useInfiniteQuery(
    ['ipWhitelist', queryParams],
    ({ pageParam }) => {
      return getIpWhitelist({
        limit,
        lastEvaluatedKey: pageParam,
      });
    },
    {
      getNextPageParam: response => {
        const lastEvaluatedKey = response.data.lastEvaluatedKey;
        if (!lastEvaluatedKey) {
          return undefined;
        }
        return convertLastEvaluatedKeyParam(lastEvaluatedKey);
      },
    },
  );
};

export const useAddIpWhitelistMutation = (onErrorCallback?: (message: string) => void) => {
  const { addIpWhitelist } = useIpWhitelistService();
  const queryClient = useQueryClient();
  return useMutation((params: AddIpWhitelistParams) => addIpWhitelist(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['ipWhitelist']);
    },
    onError: (err: Error) => {
      onErrorCallback?.(err.message);
    },
  });
};

export const useUpdateIpWhitelistByIdMutation = () => {
  const { updateIpWhitelistById } = useIpWhitelistService();
  const queryClient = useQueryClient();
  return useMutation((params: UpdateIpWhitelistByIdParams) => updateIpWhitelistById(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['ipWhitelist']);
    },
  });
};
export const useDeleteIpWhitelistByIdMutation = () => {
  const { deleteIpWhitelistById } = useIpWhitelistService();
  const queryClient = useQueryClient();
  return useMutation((params: { id: string }) => deleteIpWhitelistById(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(['ipWhitelist']);
    },
  });
};
