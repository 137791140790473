import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  CheckCircle,
  CircleUp,
  MoreVertical,
  PinFilled,
  PinOutline,
  Plus,
  SearchSmallPlus,
} from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  Icon,
  IconButton,
  LoadingIndicator,
  Popover,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { kebabCase } from 'lodash';

import analyticsTracker from '../../../../analyticsTracker';
import { Row } from '../../../../components/elements/Row';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { AppRouteLayoutProps } from '../../../../menu';
import { hasSufficientModelEndpointVolume } from '../../../../queries/meteringLogic';
import { useMetering } from '../../../../queries/useMeteringQuery';
import { useUsersQuery } from '../../../../queries/useUsers';
import { getUrl } from '../../../../routes/util';
import { ModelStatusChip, TaskTypeChip } from '../../components/components';
import { MODEL_TRAIN } from '../../path';
import {
  useModelDetailQuery,
  useModelListQuery,
  useModelPinMutation,
  useModelTrainingEpochsQuery,
  useModelUnpinMutation,
} from '../../queries/modelQueries';
import { isRecognitionAIModelTrainingResult } from '../../services/modelTrainingResultTypeGuards';
import { MyModelDetail, PurposeType } from '../../services/types';
import { SplitType } from '../../train/contexts/DatasetClassContext';
import { TrainQueryKeyword } from '../../train/queries';
import { EditTagDialog } from '../EditTagDialog';
import { CannotDeleteModelDialog } from '../list/CannotDeleteModelDialog';
import { ConfirmDeleteModelDialog } from '../list/ConfirmDeleteModelDialog';
import { CreateEndpointDialog } from '../list/CreateEndpointDialog';
import { PopoverContrainer, PopoverItem } from '../list/FilterPopover';
import {
  formatDownloadTime,
  getTotalEstimatedTime,
  InfoTooltip,
  MAX_PIN_COUNT,
} from '../list/Layout';
import { RenameDialog } from '../list/RenameDialog';
import { useModelUrlParams } from '../queries';
import { CancelTrainingDialog } from './CancelTrainingDialog';
import { CompleteTrainingDialog } from './CompleteTrainingDialog';
import { Endpoints } from './Endpoints';
import { Overview } from './Overview';
import { Performance } from './Performance';
import { Progress } from './Progress';

type TabConfigurations = {
  id: string;
  name: string;
  onClick?: ComponentProps<typeof Tab>['onClick'];
  component: ReactNode;
  visible: boolean;
}[];

export function Layout({ match }: AppRouteLayoutProps) {
  const { t } = useTranslation();
  const id = match.params.id;
  const { params } = useRouteMatch<{ purpose: PurposeType }>();
  const { data } = useModelDetailQuery({ id: id!, modelPurpose: params.purpose });
  const { data: trainingEpochsData, refetch: refetchTrainingEpochs } = useModelTrainingEpochsQuery({
    id: id!,
    status: data?.status,
  });

  if (!data) return <LoadingIndicator mt={2} mb={4} />;

  const tabConfigurations = [
    {
      id: 'overview',
      name: t('model.myModelDetail.overview'),
      onClick: undefined,
      component: <Overview data={data} />,
      visible: true,
    },
    {
      id: 'progress',
      name: t('model.myModels.trainingProgress.progress'),
      onClick: () => {
        // 디폴트 탭이 아닐 때 렌더링이 제대로 되지 않는 문제가 있음
        refetchTrainingEpochs();
      },
      component: <Progress data={data} trainingEpochsData={trainingEpochsData} />,
      visible: true,
    },
    {
      id: 'performance',
      name: t('model.myModelDetail.performance'),
      onClick: undefined,
      component: <Performance data={data} trainingEpochsData={trainingEpochsData} />,
      visible: data.status === 'trained',
    },
    {
      id: 'endpoints',
      name: `${t('model.endpoints.title')} (${data.endpointsCount})`,
      onClick: undefined,
      component: <Endpoints modelId={data.id} />,
      visible: data.status === 'trained',
    },
  ];

  return (
    <>
      <ModelDetails data={data} tabConfigurations={tabConfigurations} />
    </>
  );
}

export const ModelDetails = ({
  data,
  tabConfigurations,
}: {
  data: MyModelDetail;
  tabConfigurations: TabConfigurations;
}) => {
  const { t } = useTranslation();
  const modelParams = useModelUrlParams();
  const myModelsQuery = useModelListQuery({});
  const { mutate: pinMutate } = useModelPinMutation();
  const { mutate: unpinMutate } = useModelUnpinMutation();
  const authInfo = useAuthInfo();
  const { params } = useRouteMatch<{ accountName: string }>();
  const { data: usersData } = useUsersQuery({ params: { asList: '' }, statusIn: [] });

  const cancelTrainingDialogState = useDialogState();
  const editTagDialogState = useDialogState();

  const [createUserName, setCreateUserName] = useState('');

  const pinDisable =
    myModelsQuery.data?.pages[0].modelList.filter(model => model.pinnedAt).length === MAX_PIN_COUNT;
  const trainingResult = data.modelTraining.trainingResult.data;

  const canEarlyComplete =
    data.modelTraining.currentEpoch > 0 &&
    typeof data.modelTraining.totalEpochs === 'number' &&
    data.modelTraining.currentEpoch < data.modelTraining.totalEpochs &&
    data.status === 'training';

  useEffect(() => {
    // Created By: 모델생성자 이름을 업데이트
    if (!usersData) return;
    const user = usersData.find(user => user.email === data?.createdBy);
    setCreateUserName(user?.name ?? '');
  }, [authInfo.isGuest, data?.createdBy, params.accountName, t, usersData]);

  const handleClickPin = (id: string) => {
    if (data.pinnedAt) {
      unpinMutate(id);
    } else {
      pinMutate(id);
    }
  };

  return (
    <>
      <Row>
        <Box mb="auto" mt={0.5} mr={0.5}>
          <Tooltip
            hideOnEmptyContent
            content={!data.pinnedAt && pinDisable ? t('model.myModels.disablePin') : undefined}
            placement="top"
          >
            <Box>
              <IconButton
                disabled={!data.pinnedAt && pinDisable}
                icon={data.pinnedAt ? PinFilled : PinOutline}
                color={data.pinnedAt ? 'secondary' : undefined}
                onClick={() => {
                  handleClickPin(data.id);
                }}
                variant="text"
                size="s"
              />
            </Box>
          </Tooltip>
        </Box>
        <Box width="100%">
          <Row gap={1} mb={1}>
            <Tooltip placement="top-end" strategy="fixed" content={data.modelSetting.name}>
              <Typography
                variant="h2"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {data.modelSetting.name}
              </Typography>
            </Tooltip>
            <StatusButtonGroup
              data={data}
              canEarlyComplete={canEarlyComplete}
              cancelTrainingDialogState={cancelTrainingDialogState}
              editTagDialogState={editTagDialogState}
            />
          </Row>
          <Row>
            <Box
              display="grid"
              style={{ gridTemplateColumns: 'max-content 1fr', rowGap: 8, columnGap: 20 }}
            >
              <Typography variant="m-regular" color="gray-300">
                {t('model.myModelDetail.createdBy')}
              </Typography>
              <Typography variant="m-regular">
                {createUserName} ({data.createdBy})
              </Typography>
              <Typography variant="m-regular" color="gray-300">
                {t('model.myModelDetail.summary')}
              </Typography>
              <Row mb={3} gap={0.5}>
                <TaskTypeChip taskType={data.baselineModel.task} />
                <ModelStatusChip status={data.status} endpointsCount={data.endpointsCount} />
                {data.status === 'trained' &&
                  isRecognitionAIModelTrainingResult(trainingResult) &&
                  trainingResult.overallPerformance.ap && (
                    <Tooltip
                      strategy="fixed"
                      placement="top"
                      content={t('model.myModels.mAPTooltip')}
                    >
                      <Chip color="primary">
                        mAP {(trainingResult.overallPerformance.ap * 100).toFixed(1)}%
                      </Chip>
                    </Tooltip>
                  )}
                <Row gap={0.5}>
                  <Typography variant="s-regular">
                    {formatDownloadTime(t, getTotalEstimatedTime(data), data.status)}
                  </Typography>
                  {(data.status === 'pending' ||
                    data.status === 'training' ||
                    data.status === 'stopped') && <InfoTooltip data={data} />}
                </Row>
              </Row>
            </Box>
          </Row>
        </Box>
      </Row>
      <Tabs selectedId={modelParams.detailTab ?? 'overview'}>
        <TabList color="primary">
          {tabConfigurations
            .filter(tab => tab.visible)
            .map(tab => (
              <Tab key={tab.id} id={tab.id} onClick={tab.onClick}>
                {tab.name}
              </Tab>
            ))}
        </TabList>
        <Box pt={1.5}>
          {tabConfigurations.map(tab => (
            <TabPanel key={tab.id}>{tab.component}</TabPanel>
          ))}
        </Box>
      </Tabs>
      {cancelTrainingDialogState.visible && (
        <CancelTrainingDialog
          modelId={data.id}
          state={cancelTrainingDialogState}
          currentEpoch={data.modelTraining.currentEpoch}
          totalEpochs={data.modelTraining.totalEpochs}
        />
      )}
      {editTagDialogState.visible && <EditTagDialog id={data.id} state={editTagDialogState} />}
    </>
  );
};

const StatusButtonGroup = ({
  data,
  canEarlyComplete,
  cancelTrainingDialogState,
  editTagDialogState,
}: {
  data: MyModelDetail;
  canEarlyComplete: boolean;
  cancelTrainingDialogState: ReturnType<typeof useDialogState>;
  editTagDialogState: ReturnType<typeof useDialogState>;
}) => {
  const { t } = useTranslation();
  const cannotDeleteModelDialogState = useDialogState();
  const confirmDeleteModelDialogState = useDialogState();
  const completeTrainingDialogState = useDialogState();
  const endpointQuantity = useMetering('model:endpoint');
  const isEndpointMaxCount = !hasSufficientModelEndpointVolume(endpointQuantity);
  const createEndpointDialogState = useDialogState();

  const { params } = useRouteMatch<{ accountName: string; purpose: PurposeType }>();
  const history = useHistory();
  const curateDatasetId = data.trainingSet.referenceId;
  const splitType =
    data.trainingSet.validationSetList.length > 0
      ? ('manual' as SplitType)
      : ('random' as SplitType);

  const trackDiagnoseModelButtonClicked = () => {
    analyticsTracker.diagnoseModelButtonClicked({
      accountId: params.accountName,
      datasetId: curateDatasetId,
      modelId: data.id,
      baselineSource: data.baselineModel.source,
      baselineName: data.baselineModel.name,
      task: kebabCase(data.baselineModel?.task),
    });
  };

  return (
    <Row ml="auto" gap={0.5}>
      {data.status === 'trained' && (
        <>
          <Tooltip
            strategy="fixed"
            placement="top-end"
            hideOnEmptyContent
            content={isEndpointMaxCount ? t('model.endpoints.disableCreateEndpoint') : undefined}
          >
            <Box ml={'auto'}>
              <Button
                disabled={isEndpointMaxCount}
                onClick={() => createEndpointDialogState.show()}
                style={{ width: 'max-content' }}
              >
                <Icon icon={Plus} color="primary" />
                {t('model.endpoints.createEndpoint')}
              </Button>
            </Box>
          </Tooltip>
          <Tooltip
            hideOnEmptyContent
            content={
              data.baselineModel.deleted ? (
                <Box style={{ width: 400 }}>
                  <Trans t={t} i18nKey={'model.myModels.deprecatedMoreTrain'} />
                </Box>
              ) : undefined
            }
            placement="left"
            strategy="fixed"
          >
            <Button
              style={{ width: 'max-content' }}
              disabled={data.baselineModel.deleted}
              onClick={() => {
                analyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-model-detail',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_TRAIN],
                    {},
                    {
                      [TrainQueryKeyword.From]: params.purpose,
                      [TrainQueryKeyword.Split]: splitType,
                      [TrainQueryKeyword.Trained]: data.id,
                    },
                  ),
                );
              }}
            >
              <Icon icon={CircleUp} color="primary" />
              {t('model.myModels.trainMore')}
            </Button>
          </Tooltip>
          {params.purpose === 'recognition' && (
            <Button
              style={{ width: 'max-content' }}
              onClick={() => {
                history.push(
                  getUrl(
                    [
                      params.accountName,
                      'curate',
                      'dataset',
                      curateDatasetId,
                      'model-diagnosis',
                      'list',
                    ],
                    {},
                    {
                      [TrainQueryKeyword.From]: 'model',
                      model_id: data.id,
                    },
                  ),
                );
                trackDiagnoseModelButtonClicked();
              }}
            >
              <Icon icon={SearchSmallPlus} color="primary" />
              {t('model.myModels.diagnose')}
            </Button>
          )}
        </>
      )}
      {data.status === 'training' && canEarlyComplete && (
        <Tooltip
          strategy="fixed"
          placement="top-end"
          content={t('model.myModelDetail.earlyCompleteTooltip')}
        >
          <Button
            onClick={() => completeTrainingDialogState.show()}
            style={{ width: 'max-content' }}
          >
            <Icon icon={CheckCircle} color="primary" />
            {t('model.myModelDetail.earlyComplete')}
          </Button>
        </Tooltip>
      )}
      {(data.status === 'pending' || data.status === 'training') && (
        <Button onClick={() => cancelTrainingDialogState.show()}>{t('shared.cancel')}</Button>
      )}
      <Popover
        fixed
        hideOnClick
        disclosure={<IconButton icon={MoreVertical} />}
        style={{ zIndex: 1 }}
      >
        <PopoverContrainer onClick={e => e.stopPropagation()}>
          <PopoverItem
            onClick={() => {
              editTagDialogState.show();
            }}
          >
            <Typography variant="m-regular">{t('model.myModelDetail.editTag')}</Typography>
          </PopoverItem>
          <RenameDialog
            id={data.id}
            name={data.modelSetting.name}
            disclosure={
              <PopoverItem>
                <Typography variant="m-regular">{t('model.myModels.rename')}</Typography>
              </PopoverItem>
            }
          />
          <PopoverItem
            onClick={() => {
              if (data.endpointsCount > 0) {
                cannotDeleteModelDialogState.show();
              } else {
                confirmDeleteModelDialogState.show();
              }
            }}
          >
            <Typography variant="m-regular" color="primary-400">
              {t('shared.delete')}
            </Typography>
          </PopoverItem>
        </PopoverContrainer>
      </Popover>
      {cannotDeleteModelDialogState.visible && (
        <CannotDeleteModelDialog
          state={cannotDeleteModelDialogState}
          modelId={data.id}
          endpointsCount={data.endpointsCount}
        />
      )}
      {confirmDeleteModelDialogState.visible && (
        <ConfirmDeleteModelDialog state={confirmDeleteModelDialogState} modelId={data.id} />
      )}
      {completeTrainingDialogState.visible && (
        <CompleteTrainingDialog
          modelId={data.id}
          state={completeTrainingDialogState}
          currentEpoch={data.modelTraining.currentEpoch}
          totalEpochs={data.modelTraining.totalEpochs}
        />
      )}
      {createEndpointDialogState.visible && (
        <CreateEndpointDialog
          id={data.id}
          publicModelId={data.baselineModel.id}
          modelName={data.modelSetting.name}
          state={createEndpointDialogState}
        />
      )}
    </Row>
  );
};
