import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import { AssetDataType } from '../../../../apps/projects/project/data/assetDataTypes';
import UploadTypeCard from './UploadTypeCard';

const UploadTypeCards: React.FC<{ dataTypes?: AssetDataType[] }> = ({ dataTypes }) => {
  const count = dataTypes?.length ?? 0;
  const twoColumns = count % 2 === 0;
  const twoRows = count / 2 === 2;

  return (
    <Box p={3} width={twoColumns ? 748 : 960} height={twoRows ? 560 : 280}>
      <Box
        display="grid"
        gridTemplateColumns={twoColumns ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
        gap="16px"
      >
        {dataTypes?.map(type => (
          <UploadTypeCard key={type} type={type} />
        ))}
      </Box>
    </Box>
  );
};

export default UploadTypeCards;
