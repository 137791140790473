import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Dialog, Paragraph, Select, useDialogState } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import AnalyticsTracker from '../../../../analyticsTracker';
import { DownloadMethod } from '../../../../analyticsTracker/types';
import { COPY_SUCCESS } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import ExportService from '../../../../services/ExportService';
import FileUtils from '../../../../utils/FileUtils';
import { ExportFormat, getExportFormatOptions } from './formats';

type Props = {
  exportId: string;
  selectedFormats: ExportFormat[];
  readyToDownloadFormats: ExportFormat[];
  labelCount: number;
  fileSize: number;
};

export function ExportDownloadDialog({
  exportId,
  labelCount,
  fileSize,
  selectedFormats = ['default'],
  readyToDownloadFormats = ['default'],
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const firstNonDefaultFormat = readyToDownloadFormats.filter(f => f !== 'default')[0];
  const [downloadFormat, setDownloadFormat] = useState<ExportFormat>(
    firstNonDefaultFormat ?? 'default',
  );

  const dialog = useDialogState();
  const ExportFormatOptions = getExportFormatOptions(t);
  const options = ExportFormatOptions.filter(({ value }) => selectedFormats.includes(value))
    .map(o => ({ ...o, disabled: !readyToDownloadFormats.includes(o.value) }))
    .map(o => ({ ...o, label: o.disabled ? <>{o.label} (Not ready yet)</> : o.label }));

  function fetchUrlInfo() {
    return ExportService.getExportDownloadPresignedUrl({
      projectId: routeInfo.urlMatchInfo.projectId,
      exportId,
      transform: downloadFormat !== 'default' ? 'transform' : undefined,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  }

  async function download() {
    const fileInfo = await fetchUrlInfo();
    FileUtils.downloadViaPath(fileInfo.downloadPresignedUrl, fileInfo.fileName);

    AnalyticsTracker.labelExportDownloaded({
      accountId: authInfo?.accountName ?? '',
      method: DownloadMethod.DOWNLOAD,
      exportId,
      labelCount,
      format: downloadFormat,
    });
  }

  async function copyURL() {
    const { downloadPresignedUrl } = await fetchUrlInfo();

    FileUtils.copyToClipboard({ value: downloadPresignedUrl });
    enqueueSnackbar(COPY_SUCCESS({ t, label: 'download url' }), { variant: 'success' });

    AnalyticsTracker.labelExportDownloaded({
      accountId: authInfo?.accountName ?? '',
      method: DownloadMethod.COPYlINK,
      exportId,
      labelCount,
      format: downloadFormat,
    });
  }

  const disclosure = (
    <Button variant="strong-fill" color="primary">
      {t('analytics.text.download')}
    </Button>
  );

  return (
    <Dialog state={dialog} disclosure={disclosure} style={{ width: 520 }}>
      <Dialog.Title>{t('exportDownloadDialog.title')}</Dialog.Title>
      <Dialog.Content>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Paragraph variant="m-strong">{t('exportDownloadDialog.content')}</Paragraph>
            <Paragraph variant="m-regular">
              {t('labels.labelCount', { count: labelCount })},{' '}
              {FileUtils.getConvertedFileSize(fileSize)}
            </Paragraph>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Paragraph variant="m-strong">{t('exportDownloadDialog.downloadFormat')}</Paragraph>
            <Box display="grid">
              <Select
                data={options}
                value={downloadFormat}
                onChangeValue={setDownloadFormat}
                formatValue={v =>
                  ExportFormatOptions.find(({ value }) => v === value)?.shortLabel ?? v
                }
              />
            </Box>
          </Box>
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void dialog.hide()} style={{ marginRight: 'auto' }}>
          {t('button.cancel')}
        </Button>
        <Button variant="stroke" color="primary" onClick={() => copyURL()}>
          {t('exportDownloadDialog.copyUrlButtonText')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={() => download()}>
          {t('button.download')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
