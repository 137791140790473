import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import { Box, Typography } from '@superb-ai/norwegian-forest';

import { useContainerSize } from '../../../hooks/ContainerSizeHook';
import { useProjectListInfo } from '../../../hooks/ProjectListInfo';
import { useWindowSize } from '../../../hooks/WindowSizeHook';
import { MemberData } from '../../../types/memberTypes';
import { getMembersSortFunction } from '../../pages/label/labelList/AssignDialog/utils';
import CircularProgressBox from '../CircularProgressBox';
import MembersTableHead from './MembersTableHead';
import MembersTableRow from './MembersTableRow';
import { MembersColumns } from './types';

type MembersTableProps = {
  selectedMembers: MemberData[];
  filteredMembers: MemberData[];
  setSelectedMembers: Dispatch<SetStateAction<MemberData[]>>;
  columns: MembersColumns[];
  columnsWidth: (number | string)[];
  isLoading: boolean;
  onClickDelete: (member: MemberData) => void;
  getIsDeletable: (member: MemberData) => boolean;
  onClickChangeRole?: (member: MemberData) => void;
  onClickProjectAccess?: (member: MemberData) => void;
};

const MembersTable: React.FC<MembersTableProps> = ({
  filteredMembers,
  columns,
  columnsWidth,
  isLoading,
  onClickDelete,
  selectedMembers,
  setSelectedMembers,
  getIsDeletable,
  onClickChangeRole,
  onClickProjectAccess,
}) => {
  const [sortBy, setSortBy] = useState('role');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortedMembers, setSortedMembers] = useState<MemberData[] | null>(null);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const projectListInfo = useProjectListInfo();

  const tableRef = useRef<HTMLDivElement | null>(null);
  const containerSize = useContainerSize(tableRef);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isLoading) return;
    setIsSelectedAll(false);
    setSelectedMembers([]);
    // eslint-disable-next-line
  }, [isLoading]);

  // Apply sort
  useEffect(() => {
    const sortedMembers = [...filteredMembers];
    sortedMembers.sort(getMembersSortFunction(sortBy, sortOrder === 'desc'));
    setSortedMembers(sortedMembers);
  }, [filteredMembers, sortBy, sortOrder]);

  const handleRequestSort = (field: string, ascDesc: 'asc' | 'desc') => {
    setSortBy(field);
    setSortOrder(ascDesc);
  };

  const handleToggleSelectAll = (): void => {
    if (!isSelectedAll && !!sortedMembers) {
      const selectedMembers = [...sortedMembers].filter(member => getIsDeletable(member));
      setSelectedMembers(selectedMembers);
      setIsSelectedAll(true);
    } else {
      setSelectedMembers([]);
      setIsSelectedAll(false);
    }
  };

  if (isLoading || !sortedMembers) {
    return (
      <CircularProgressBox
        boxProps={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mt: '50px',
        }}
      />
    );
  }

  return (
    <Box>
      <MembersTableHead
        columns={columns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        isSelectedAll={isSelectedAll}
        onRequestSort={handleRequestSort}
        onToggleSelectAll={handleToggleSelectAll}
        columnsWidth={columnsWidth}
      />
      <Box ref={tableRef}>
        {sortedMembers.length ? (
          <List
            itemCount={sortedMembers.length}
            itemSize={56}
            width="100%"
            height={windowSize.height - containerSize.top - 50}
            itemData={{
              columns,
              columnsWidth,
              members: sortedMembers,
              selectedMembers,
              setSelectedMembers,
              setIsSelectedAll,
              getIsDeletable,
              onClickDelete,
              onClickChangeRole,
              onClickProjectAccess,
              projectListInfo,
            }}
            overscanCount={10}
          >
            {MembersTableRow}
          </List>
        ) : (
          <Box p={1}>
            <Typography variant="body3">No members found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MembersTable;
