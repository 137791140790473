import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroup } from '@superb-ai/ui';

export default function AutoSync({
  autoSync,
  setAutoSync,
}: {
  autoSync: boolean;
  setAutoSync: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const value = autoSync ? 'on' : 'off';

  const labelProps = {
    borderRadius: '2px',
    p: 0.5,
    border: '1px solid',
    borderColor: { default: 'gray-200', checked: 'primary' },
    color: { checked: 'primary' },
    backgroundColor: { checked: 'primary-100' },
  } as ComponentProps<typeof RadioGroup>['labelProps'];

  const options = [
    {
      value: 'on',
      label: t('curate.datasets.syncWithNewLabelProject.steps.autoSync.on'),
    },
    { value: 'off', label: t('curate.datasets.syncWithNewLabelProject.steps.autoSync.off') },
  ] as const;
  return (
    <RadioGroup
      options={options}
      value={value}
      onChangeValue={value => setAutoSync(value === 'on')}
      labelProps={labelProps}
    />
  );
}
