import { KeysToFirstUpperCase } from '../../../../../types/typeUtils';

/** Precision Recall Curve */
export type PerformanceCurveDatum = {
  confidence: number; // model confidence score
  precision: number;
  recall: number;
  f1Score: number;
};

export type PerformanceCurveResponse = {
  data: PerformanceCurveDatum[];
  optimalThreshold: number;
};
export type FormattedPerformanceCurveDatum = KeysToFirstUpperCase<
  Omit<PerformanceCurveDatum, 'f1Score'>
> & {
  'F1 Score': number;
};

export type PRCurveDatum = Pick<PerformanceCurveDatum, 'precision' | 'recall'> & {
  confidence?: number;
};
export type PRCurveResponse = {
  data: PRCurveDatum[];
};

export type FormattedPRCurveDatum = KeysToFirstUpperCase<PRCurveDatum>;

export type MetricType = 'precision' | 'recall' | 'f1Score';
export type HistogramMetricType = 'iou' | 'confidence';
type HistogramKey = 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9;

export type MetricDatum = {
  key: HistogramKey;
  count: number;
};

export type HistogramResponse = {
  histogram: MetricDatum[];
};

export const PredictionTypeEnum = {
  GROUND_TRUTH: 'groundTruth',
  PREDICTION: 'prediction',
  BASE: 'base',
  COMPARE: 'compare',
} as const;

export type PredictionSetType = (typeof PredictionTypeEnum)[keyof typeof PredictionTypeEnum];

export enum DiagnosisStatusEnum {
  ACTIVE = 'ACTIVE',
  VAL_ACTIVE = 'VAL_ACTIVE',
  ALL_ACTIVE = 'ALL_ACTIVE',
  ACTIVATING = 'ACTIVATING',
  UPDATING = 'UPDATING',
  // below are inactive diangosis lit
  FAILED = 'FAILED',
  INACTIVE = 'INACTIVE',
  DEACTIVATING = 'DEACTIVATING',
}

export const isDiagnosisActive = (status: DiagnosisStatusEnum) => {
  return [
    DiagnosisStatusEnum.ACTIVE,
    DiagnosisStatusEnum.VAL_ACTIVE,
    DiagnosisStatusEnum.ALL_ACTIVE,
    DiagnosisStatusEnum.UPDATING,
  ].includes(status);
};
export const isDiagnosisInactive = (status: DiagnosisStatusEnum) => {
  return [
    DiagnosisStatusEnum.FAILED,
    DiagnosisStatusEnum.INACTIVE,
    DiagnosisStatusEnum.DEACTIVATING,
  ].includes(status);
};
export const isDiagnosisInprogress = (status: DiagnosisStatusEnum) => {
  return [
    DiagnosisStatusEnum.ACTIVATING,
    DiagnosisStatusEnum.UPDATING,
    DiagnosisStatusEnum.DEACTIVATING,
  ].includes(status);
};
export const isDiagnosisDisabled = (status: DiagnosisStatusEnum) => {
  return [DiagnosisStatusEnum.FAILED, DiagnosisStatusEnum.DEACTIVATING].includes(status);
};
export const isDiagnosisFailed = (status?: DiagnosisStatusEnum) =>
  status && status === DiagnosisStatusEnum.FAILED;

export const isDiagnosisDeactivating = (status?: DiagnosisStatusEnum) =>
  status && status === DiagnosisStatusEnum.DEACTIVATING;

export const isDiagnosisFinished = (status: DiagnosisStatusEnum) => {
  return [
    DiagnosisStatusEnum.FAILED,
    DiagnosisStatusEnum.ACTIVE,
    DiagnosisStatusEnum.VAL_ACTIVE,
    DiagnosisStatusEnum.ALL_ACTIVE,
  ].includes(status);
};

export type DiagnosisStatus = (typeof DiagnosisStatusEnum)[keyof typeof DiagnosisStatusEnum];

export type ModelSource = 'superb' | 'external';

export type SortBy = 'iou' | 'confidence';
export type SortOrder = 'asc' | 'desc';

export type ConfusionMatrixDownload = {
  ground_truth_class: string;
  predicted_class: string;
  count: number;
};
