import RegexUtils from '../../../../utils/RegexUtils';

export const extractEmails = (value: string): string[] => {
  let startIndex = -1;
  let endIndex = -1;

  const emails = [];
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char === ' ' || char === ',' || char === ';') {
      startIndex = endIndex;
      endIndex = i;
      const str = value.slice(startIndex + 1, endIndex);
      if (RegexUtils.IS_EMAIL(str) && emails.indexOf(str) < 0) {
        emails.push(str);
      }
    }
  }

  return emails;
};

export const extractLastString = (value: string): string => {
  let lastString = '';

  const trimValue = value.trim();
  for (let i = trimValue.length - 1; i >= 0; i--) {
    const char = trimValue[i];
    if (char === ' ' || char === ',' || char === ';') {
      return lastString;
    }
    lastString = char + lastString;
  }

  return lastString;
};
