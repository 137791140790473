import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDuoLeft, ChevronDuoRight } from '@superb-ai/icons';
import { Box, IconButton, Typography, vars } from '@superb-ai/ui';

import GridColumnsRange from '../../grid/GridColumnsRange';

export const ImagePreviewHeader = ({
  height,
  setCalculatedContentAreaHeight,
  defaultColumns,
  columnsCount,
  setColumnsCount,
  samplingPointCount,
  totalCount,
  isGridAreaExpanded,
  setIsGridAreaExpanded,
  tab,
  setTab,
}: {
  height: number | string;
  setCalculatedContentAreaHeight: (height: string) => void;
  defaultColumns: number;
  columnsCount: number;
  setColumnsCount: (value: number) => void;
  samplingPointCount: number;
  totalCount: number;
  isGridAreaExpanded: boolean;
  setIsGridAreaExpanded: (isGridAreaExpanded: boolean) => void;
  tab: 'sample' | 'all';
  setTab: (tab: 'sample' | 'all') => void;
}) => {
  const { t } = useTranslation();
  const titleBoxRef = useRef<HTMLDivElement | null>(null);

  const minColumns = 1;
  const maxColumns = 5;

  useEffect(() => {
    if (!titleBoxRef.current) return;
    setCalculatedContentAreaHeight(`calc(100% - ${titleBoxRef.current?.clientHeight || 0}px)`);
  }, [height, setCalculatedContentAreaHeight]);

  return (
    <Box
      ref={titleBoxRef}
      display="flex"
      width="100%"
      alignItems="center"
      gap={0.5}
      px={1}
      py={0.5}
      style={{ boxShadow: `inset 0 -1px 0 ${vars.color['gray-250']}` }}
    >
      {/* <Tabs onSelectTab={selectedId => setTab(selectedId as 'sample' | 'all')} selectedId={tab}>
        <Box display="flex" style={{ maxWidth: '224px' }}>
          <TabList color="black" size="large">
            <Tab id="sample">
              Sample (<Typography color="primary">{samplingPointCount.toLocaleString()}</Typography>
              )
            </Tab>
            <Tab id="all">
              All (<Typography color="primary">{totalCount.toLocaleString()}</Typography>)
            </Tab>
          </TabList>
        </Box>
      </Tabs> */}
      <Typography
        variant="l-strong"
        color="gray-400"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {`${t('curate.embeddings.preview.title')} (`}
        <Typography color="primary">{samplingPointCount.toLocaleString()}</Typography>
        {')'}
      </Typography>
      <GridColumnsRange
        isPopoverMode={!isGridAreaExpanded}
        minColumns={minColumns}
        maxColumns={maxColumns}
        columnsCount={columnsCount}
        defaultColumns={defaultColumns}
        setColumnsCount={setColumnsCount}
      />
      <Box style={{ width: '1px', height: '22px' }} backgroundColor="gray-250" />
      <IconButton
        icon={isGridAreaExpanded ? ChevronDuoLeft : ChevronDuoRight}
        variant="text"
        onClick={() => setIsGridAreaExpanded(!isGridAreaExpanded)}
      />
    </Box>
  );
};
