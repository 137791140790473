import React from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { formatCount } from '../../../../utils/numberFormat';
import LabelBadgeStatus from '../../../elements/LabelBadgeStatus';

interface Props {
  counts: {
    labelingInProgressCount: number;
    submittedLabelsCount: number;
    skippedLabelsCount: number;
  };
}

export default function ExportDialogStatusList({ counts }: Props): JSX.Element {
  const { labelingInProgressCount, submittedLabelsCount, skippedLabelsCount } = counts;
  const itemProps = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  } as const;

  return (
    <Box display="flex" gap={3}>
      <Box {...itemProps}>
        <LabelBadgeStatus status="WORKING" />
        <Typography variant="m-regular" color="primary">
          {formatCount(labelingInProgressCount)}
        </Typography>
      </Box>
      <Box {...itemProps}>
        <LabelBadgeStatus status="SUBMITTED" />
        <Typography variant="m-regular" color="primary">
          {formatCount(submittedLabelsCount)}
        </Typography>
      </Box>
      <Box {...itemProps}>
        <LabelBadgeStatus status="SKIPPED" />
        <Typography variant="m-regular" color="primary">
          {formatCount(skippedLabelsCount)}
        </Typography>
      </Box>
    </Box>
  );
}
