import React, { CSSProperties, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';

import { Typography } from '@superb-ai/norwegian-forest';
import { keys } from 'lodash';

import { ClickedEventProps } from '../../chartContainers/types';
import { JsonObj } from '../../userStats/types';
import { DonutLegendRow } from './DonutLegendRow';
import { GetDonutColorFn } from './types';

// From Timeline scrollbar in Norwegian Forest
export const scrollStyle = () => {
  const ScrollBarSize = 16;
  const scrollBarSize = 8;
  const trackSizeInit = ScrollBarSize;
  const margin = `${(trackSizeInit - scrollBarSize) / 2}px`;
  const trackSize = `${trackSizeInit}px`;
  const style = {
    '&::-webkit-scrollbar': {
      width: trackSize,
      height: trackSize,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: trackSize,
      border: `solid ${margin} transparent`,
      boxShadow: `inset 0 0 ${margin} ${margin} #F3F3F3`,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: trackSize,
      border: `solid ${margin} transparent`,
      boxShadow: `inset 0 0 ${margin} ${margin} #D4D4D4`,
      height: '10%',
    },
  };
  return style;
};

export const textStyle: CSSProperties = {
  textAnchor: 'start',
  fontSize: '12px',
  fontWeight: '400',
  fontStyle: 'normal',
  fontFamily: 'Inter, sans-serif',
  lineHeight: '18px',
};

export const titleStyle: CSSProperties = {
  margin: '3px',
  alignmentBaseline: 'middle',
  lineHeight: '18px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '12px',
  fontWeight: 600,
  textAnchor: 'start',
};

const DonutLegend = (props: {
  id: string;
  data: JsonObj[];
  idKey?: string;
  displayNameKey?: string;
  countKey?: string;
  shareKey?: string;
  groupValues?: Record<string, string>[];
  getColor?: GetDonutColorFn;
  highlightSlice: (i: number, status: 'in' | 'out') => void;
  handleOnClick: ClickedEventProps;
  chartName: string;
  xKeyToDisplayName?: Record<string, string>;
  chartObj?: {
    title?: string;
    hasMarker?: boolean;
  };
  fixedTopMargin: boolean;
  showButton: boolean;
  selectedIndex: number;
}): React.ReactElement => {
  const {
    data,
    idKey,
    displayNameKey,
    countKey,
    shareKey,
    groupValues,
    getColor,
    highlightSlice,
    handleOnClick,
    chartName,
    chartObj,
    showButton,
    selectedIndex,
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedRowIndex(selectedIndex);
  }, [selectedIndex]);

  const handleRowClick = handleOnClick
    ? (i: number) => {
        let categoryIds;
        if (chartName === 'imageCategoryStats') categoryIds = keys(groupValues[i]); // ex. ['groupId', 'categoryId']
        handleOnClick(data[i], categoryIds);
      }
    : undefined;

  return (
    <>
      {chartObj?.title && (
        <Typography variant="body2" style={titleStyle}>
          {chartObj.title}
        </Typography>
      )}
      <FixedSizeList
        useIsScrolling
        width={300}
        height={250}
        itemSize={24}
        itemCount={data.length}
        itemData={{
          items: data,
          getDatumColor: getColor,
          idKey: idKey ?? 'name',
          displayNameKey: displayNameKey ?? 'name',
          countKey: countKey ?? 'count',
          shareKey: shareKey ?? 'percentTotal',
          colorKey: 'name',
          selectedIndex: selectedRowIndex,
          handleOnHover: highlightSlice,
          handleOnClick: handleRowClick,
          showButton,
        }}
      >
        {DonutLegendRow}
      </FixedSizeList>
    </>
  );
};

export default DonutLegend;
