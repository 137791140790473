import { ViewMode } from '../apps/Curate/types/viewTypes';
import { ModelStatus, PublicModelSource, PurposeType } from '../apps/Model/services/types';
import { AssetDataType } from '../apps/projects/project/data/assetDataTypes';
import { UploadMethod } from '../components/elements/newUpload/types';
import { SourceIntegrationTarget } from '../components/pages/account/integrations/types';
import { ExportFormat } from '../components/pages/export/exportHistory/formats';
import { CurationType, EmbeddingType } from '../consts/AutoCurateConst';
import { CloudSaveMethod } from '../contexts/UploadContext';

export type AccountId = { accountId: string };
export type WithAccountId<T = {}> = T & AccountId;
export type ProductType = 'label' | 'curate' | 'model';
export type TrainModelStep =
  | 'choose-baseline-model'
  | 'split-train-val'
  | 'select-dataset-and-class'
  | 'complete-model-setting';

export type AutoLabelConfiguredProperties = WithAccountId<{
  mappedCategories: number;
  mappedClasses: number;
  /**
   * if editing existing project
   */
  projectId?: string;
}>;

export type AutoLabelInitiatedProperties = WithAccountId<{
  labelCount: number;
  projectId: string;
}>;

type AnalyticsFeatureType =
  | 'label-analytics'
  | 'curate-analytics'
  | 'model-analytics'
  | 'model-diagnosis';
export type ChartDownloadedProperties = WithAccountId<{
  chartName: string;
  chartType?: string; // 'bar' | 'line' | 'scatter' | 'pie' | 'donut' | 'histogram' | 'heatmap'  | 'table' | 'histogram'
  fileExtension?: 'png' | 'jpeg' | 'pdf' | 'svg' | 'csv' | 'xlsx' | 'copy';
  feature: AnalyticsFeatureType;
}>;

export type ContactFormSubmittedProperties = WithAccountId<{
  referrer?: string;
}>;

export type ContactUsFormType =
  | 'suite-auto-label-trial-form'
  | 'suite-curate-contact-sales-form'
  | 'suite-model-diagnosis-contact-sales-form'
  | 'suite-model-contact-sales-form';

export type ContactUsClickedProperties = WithAccountId<{
  referrer?: ContactUsFormType;
}>;

export type CreateCustomAutoLabelAIInitiatedProperties = WithAccountId<{
  baseline_model?: string;
  exportId: string;
  labelCount: number;
  referrer: 'export' | 'cal';
}>;

export type CreateProjectButtonClickedProperties = WithAccountId<{
  dataType: string;
}>;

export type CustomAutoLabelAIAppliedProperties = WithAccountId<{
  annotationTypeCount: number;
}>;

export type CustomAutoLabelRequestCanceledProperties = WithAccountId<{}>;

export type DocsLinkClickedProperties = WithAccountId<{
  product?: 'label' | 'curate' | 'model' | 'account';
  referrer?: string; // ex. 'label-upload-data-dialog'
  pageSource?: string;
}>;

export enum DownloadMethod {
  DOWNLOAD = 'download',
  COPYlINK = 'link', // (TBD) rename to 'copy-link-to-clipboard'?
}

export type LabelExportDownloadedProperties = WithAccountId<{
  exportId: string;
  format: ExportFormat;
  labelCount: number;
  method: DownloadMethod;
}>;

export type ExportCompareReportGenerated = WithAccountId<{
  // durationBetweenExports: number;
  labelCountBase: number;
  labelCountCompare: number;
}>;

export type IntegrationAddedProperties = WithAccountId<{
  type: string; // integration type
}>;
export type InvitationsSentProperties = WithAccountId<{
  invitationCount: number;
  projectsCount?: number;
  referrer: 'account-members' | 'project-members';
}>;

export type LabelExportRequestedProperties = WithAccountId<{
  filterBy: string[];
  format: string; // export format
  labelCount?: number;
  createCAL: boolean;
}>;

export type LabelListFilteredProperties = WithAccountId<{
  /**
   * Simple list of filtered fields, e.g. \['status', 'assignee'\]
   */
  filterBy: string[];
  /**
   * Complete filter information, e.g.
   *
   * ```
   * [
   *   ['status', 'isAnyOf', 'submitted,skipped'],
   *   ['assignee', 'isAnyOf', 'paul@superb-ai.com']
   * ]
   * ```
   *
   * Free-form values are saved as 'FREEFORM'.
   */
  filters: unknown[][];
  /**
   * total filtered label count
   */
  labelCount: number;
}>;

export type LabelSelfAssignRequestedProperties = WithAccountId<{
  projectId: string;
}>;

export type LabelSkippedProperties = WithAccountId<{}>;
export type LabelSubmittedProperties = WithAccountId<{
  /**
   * Time in seconds the user spend editing this label
   */
  elapsedTime: number;
}>;

export type PersonalAccessKeyCreatedProperties = WithAccountId<{
  expiry: string; // '7 days' | '30 days' | '60 days' | 'Custom' | 'No expiration'
}>;

export type ProjectCreatedProperties = WithAccountId<{
  autoLabelObjectClassCount: number;
  categoriesCount: number;
  dataType: string;
  objectClassCount: number;
  projectId: string;
}>;

// TBD: deprecate
export type ReturnToAccountClickedProperties = WithAccountId<{}>;

export type ReviewSelfAssignRequestedProperties = WithAccountId<{
  projectId: string;
}>;

export type SampleProjectRequestedProperties = WithAccountId<{
  datasetId: string; // 'retail' | 'tiny-set-10' | 'industrial-surface' | 'caltech-pedestrian' | 'minneapple'
  referrer: 'modal' | 'empty-project';
}>;

export type SignInClickedProperties = WithAccountId<{
  referrer: string;
}>;

export type SignupStepCompletedProperties = {
  step: 'account' | 'email' | 'name' | 'verification';
  // accountId is not available until user completes the account step
  accountId?: string;
};

export type UpgradePlanClickedProperties = WithAccountId<{
  referrer?: string;
}>;

export type LabelUploadDialogOpenedProperties = WithAccountId<{
  referrer: 'label-project-menu' | 'label-empty-project' | 'label-data-list';
}>;

export type LabelReviewedProps = WithAccountId<{
  reviewAction: 'approve' | 'reject';
  reviewRound: number;
}>;

export type MembersAssignedProps = WithAccountId<{
  distributionMethod: 'equal' | 'proportional';
  memberCount: number;
  labelCount: number; // assigned label count
  targetRole: 'labeler' | 'reviewer';
}>;

export type LabelDataUploadRequestedProperties = WithAccountId<{
  uploadType: UploadMethod;
  dataType: AssetDataType | null;
  cloudStorage?: SourceIntegrationTarget;
  cloudSaveMethod?: CloudSaveMethod;
}>;

export type StartLabelingClickedProperties = WithAccountId<{}>;
export type StartReviewClickedProperties = WithAccountId<{
  dataType: string;
}>;

export type UserReportRequestedProps = WithAccountId<{
  targetRole: 'labeler' | 'reviewer';
  labelCount: number;
  filterApplied: boolean;
}>;

export type UserReportDownloadedProps = WithAccountId<{
  targetRole: 'labeler' | 'reviewer';
  labelCount: number;
}>;

// Added: 4/30 2024
export type ChartClickedProperties = WithAccountId<{
  chartName: string;
  feature: AnalyticsFeatureType;
}>;

export type StorylaneClickedProperties = WithAccountId<{
  product: ProductType;
}>;

export type HelpHubClickedProperties = WithAccountId<{
  product: ProductType;
}>;

/** Curate Product */
type CurateFilters = {
  queryString?: string;
  filterString?: string;
  appliedFilters?: { [key: string]: any };
};

export type AutoCurateRequestedProps = CurateFilters &
  WithAccountId<{
    datasetId: string;
    sliceId: string[] | undefined;
    curationType: CurationType;
    embeddingType: EmbeddingType;
    dataType: string;
    dataCount: number;
    curationPercent?: number; // what to label only
    validationSetPercent?: number; // split train val only
  }>;

export type DataSliceCreatedProps = CurateFilters &
  WithAccountId<{
    datasetId: string;
    sliceId: string;
    filters?: Record<string, any>;
    viewType?: ViewMode;
    dataCount?: number | 'UNKNOWN'; // not available for explore-object or model-diagnosis
    datasetTotalCount?: number; // total image or annotation count in the dataset
    // number of selected cells in grid view. undefined if selected all
    selectedCount?: number;
    dataType: string;
    referrer: string; // 'model-diagnosis' | 'explore-image' | 'explore-object';
  }>;

export type DataSliceUpdatedProps = DataSliceCreatedProps;

export type AutoCurateReportDownloadedProps = WithAccountId<{
  datasetId: string;
}>;
export type SendToCurateRequestedProps = WithAccountId<{
  datasetId: string;
  dataType: string;
  dataCount: number;
  filterBy: string[];
  filterString: string;
}>;

export type SendToLabelRequestedProps = WithAccountId<{
  datasetId: string;
  sliceId?: string | 'NOT_SELECTED'; // 슬라이스에서 생성한 경우만 있음
  dataCount: 'UNKNOWN' | number;
  dataType: string;
  projectId?: string;
  correlationId?: string;
  syncType: 'send-to-label' | 'sync-with-new-project' | 'add-to-synced-project';
}>;

export type CurateDataUploadRequestedProps = WithAccountId<{
  cloudStorage: SourceIntegrationTarget;
  dataType: 'image';
  hasTargetSlice: boolean;
}>;

export type CurateDataComparedProps = WithAccountId<{
  sliceId: string | undefined;
  chartName: string;
  queryString: string;
  appliedFilters: { [key: string]: any };
  comparisonTarget: 'slice' | 'dataset';
  interactionType: 'click' | 'select-dropdown';
}>;

export type QueryBuilderAppliedProps = WithAccountId<{
  isSampleQuery: boolean;
  datasetId: string;
  sliceId?: string;
  queryString: string;
  scope: string;
  viewType: string;
}>;

export type ScatterComparedProps = WithAccountId<{}>;

/** Model Product */
export type DiagnoseModelButtonClicked = WithAccountId<{
  datasetId: string;
  modelId: string;
  baselineSource: PublicModelSource;
  baselineName: string;
  task: string;
}>;

export type ModelTrainingStepCompletedProps = WithAccountId<{
  modelPurpose?: PurposeType;
  source?: string;
  step: TrainModelStep;
}>;

export type ModelTrainingRequestedProps = WithAccountId<{
  modelPurpose?: PurposeType;
  datasetId: string;
  source?: string;
  trainSliceId?: string[];
  validationSliceId?: string[];
}>;

export type OverviewLinkClickedProps = WithAccountId<{
  clickedLink:
    | 'click-training-model'
    | 'click-running-endpoint'
    | 'click-recognition-model-hub'
    | 'click-recognition-my-model'
    | 'click-recognition-endpoints'
    | 'click-recognition-train-new-model'
    | 'click-generative-model-list'
    | 'click-generative-endpoints'
    | 'click-generative-train-new-model'
    | 'click-pinned-recognition-model'
    | 'click-pinned-generation-model';
}>;

export type TrainModelEnteredProps = WithAccountId<{
  referrer:
    | 'entered-from-overview'
    | 'entered-from-curate-dataset'
    | 'entered-from-model-list'
    | 'entered-from-model-hub'
    | 'entered-from-model-detail'
    | 'entered-from-gen-ai-intro';
}>;

export type ModelDetailClickedProps = WithAccountId<{
  clickedButton:
    | 'click-performance-chart-tab'
    | 'click-progress-sample-prediction-detail'
    | 'click-performance-sample-prediction-detail';
  modelStatus: ModelStatus;
}>;

export type ModelCancelCompleteRequestedProps = WithAccountId<{
  currentEpoch: number;
  totalEpochs: number;
  reason?: string;
  requestedAction: 'cancel' | 'early-complete';
}>;
