import { TFunction } from 'react-i18next';
import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import ExportStatus from '../consts/ExportStatus';
import ExportService from '../services/ExportService';

export interface ExportHistoriesQueryParams {
  refetch: boolean;
  defaultParams: {
    isGuest: boolean;
    urlInfo: Record<string, any>;
    origin: string;
  };
}

export const useExportHistoriesQuery = ({
  t,
  defaultParams: { isGuest, urlInfo, origin },
  refetch,
}: { t: TFunction } & ExportHistoriesQueryParams) => {
  const pageSize = 50;
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();

  const fetcher = async () =>
    await ExportService.getExportHistories({
      projectId,
      isGuest,
      urlInfo,
      params: { pageSize },
    }).then(res => {
      return res.results.filter(history => history.state === ExportStatus.READY_FOR_DOWNLOAD);
    });

  return useQuery([accountName, projectId, 'exportHistoriesList'], fetcher, {
    refetchInterval: refetch ? 5000 : false,
  });
};
