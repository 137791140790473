import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Clear, ErrorFilled, InfoFilled, QueryBuilder, Search } from '@superb-ai/icons';
import { Input, List, ListItem, Popper } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, IconButton, Typography } from '@superb-ai/ui';
import { format } from 'date-fns';
import QueryString from 'qs';

import analyticsTracker from '../../../../../../analyticsTracker';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { getSearchQueryRoute } from '../../../../utils/routeUtils';

type AdvancedQueryProps = {
  setQueryModalIsOpen: (isOpen: boolean) => void;
  handleCheckQueryString: (queryString: string) => void;
  handleInputQueryCheck: (queryString: string) => void;
};

export default function AdvancedQuery({
  setQueryModalIsOpen,
  handleCheckQueryString,
  handleInputQueryCheck,
}: AdvancedQueryProps) {
  const { t } = useTranslation();
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { queryString, setQueryString } = useQueryContext();
  const [queryError, setQueryError] = useState<string>('');

  const history = useHistory();
  const { query: queryParams } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [showQueryError, setShowQueryError] = useState<boolean>(false);
  const [displaySamples, setDisplaySamples] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [newQueryString, setNewQueryString] = useState<string>(queryString || '');

  const searchParams = new URLSearchParams(history.location.search);
  const view = searchParams.get('view') || 'grid';
  const scope = searchParams.get('scope') || 'image';

  const handleSampleQuery = useCallback(
    (query: string) => {
      setQueryString(query);
      setNewQueryString(query);
      setQueryError('');
      setDisplaySamples(false);
      history.push(getSearchQueryRoute(history, { query }));
      analyticsTracker.queryBuilderApplied({
        isSampleQuery: true,
        scope,
        viewType: view,
        accountId: accountName,
        datasetId,
        queryString: query,
      });
    },
    [
      setQueryString,
      setNewQueryString,
      setQueryError,
      setDisplaySamples,
      accountName,
      datasetId,
      history,
      view,
      scope,
    ],
  );

  const handleQueryText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const queryString = e.target.value ?? '';
      setNewQueryString(queryString);
      handleInputQueryCheck(queryString);
      if (view === 'analytics') setQueryString(queryString);
    },
    [setNewQueryString, handleInputQueryCheck, setQueryString, view],
  );

  useEffect(() => {
    setShowQueryError(!!queryError);
  }, [queryError]);

  useEffect(() => {
    if (queryString.length > 0 && newQueryString.length === 0) setNewQueryString(queryString);
    if (queryString !== newQueryString) setNewQueryString(queryString);
  }, [queryString]);

  useEffect(() => {
    if (queryParams) {
      setQueryString(queryParams as string);
      setNewQueryString(queryParams as string);
    }
  }, [queryParams]);

  return (
    <Popper
      sameWidth
      anchorEl={
        <Box width="100%" position="relative">
          <Box
            p={0.5}
            display="flex"
            alignItems="center"
            backgroundColor="cloud-100"
            borderRadius="2px"
            width="100%"
          >
            <Box style={{ flex: 1 }}>
              <Input
                Adornment={
                  <Box display="flex" alignItems="center">
                    <Icon color="gray-400" icon={Search} />
                  </Box>
                }
                variant="text"
                value={newQueryString}
                placeholder={t('curate.advancedQuery.queryBuilderPlaceholder')}
                onChange={e => handleQueryText(e)}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setQueryString(newQueryString);
                    handleCheckQueryString(newQueryString);
                    history.push(getSearchQueryRoute(history, { query: newQueryString }));
                  }
                }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              {newQueryString && (
                <IconButton
                  icon={Clear}
                  onClick={() => {
                    setNewQueryString('');
                    setQueryString('');
                    handleInputQueryCheck('');
                    history.push(getSearchQueryRoute(history, { query: undefined }));
                  }}
                  variant="text"
                />
              )}
              {queryError && (
                <IconButton
                  icon={ErrorFilled}
                  onClick={() => setShowQueryError(!showQueryError)}
                  variant="text"
                  color="primary"
                />
              )}
              <Button onClick={() => setQueryModalIsOpen(true)} variant="stroke" color="primary">
                <Icon icon={QueryBuilder} />
                {t('curate.button.queryBuilder')}
              </Button>
            </Box>
          </Box>
          {displaySamples || isFocus ? (
            <Box
              position="absolute"
              top="100%"
              width="100%"
              backgroundColor="white"
              style={{ zIndex: '999', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}
              borderRadius="2px"
              onMouseEnter={() => setDisplaySamples(true)}
              onMouseLeave={() => setDisplaySamples(false)}
            >
              <Box
                width="100%"
                display="flex"
                borderBottom="1px solid"
                borderColor="gray-100"
                px={1.5}
                py={0.5}
              >
                <Box display="flex" alignItems="center" mr={1} gap="small">
                  <Icon icon={InfoFilled} color={'secondary-400'} />
                  <Typography variant="m-regular" color={'secondary-400'}>
                    {t('curate.advancedQuery.queryBuilderGuide')}
                  </Typography>
                </Box>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    setQueryModalIsOpen(true);
                  }}
                  variant="stroke"
                  color="primary"
                >
                  <Icon icon={QueryBuilder} />
                  <Typography variant="m-strong">{t('curate.button.queryBuilder')}</Typography>
                </Button>
              </Box>
              <Box width="100%" py={0.5} px={1.5}>
                <Typography variant="m-medium">
                  {t('curate.advancedQuery.sampleQueries')}
                </Typography>
              </Box>
              <List style={{ marginTop: '0px' }}>
                <ListItem
                  style={{ padding: '4px' }}
                  onClick={() => handleSampleQuery('annotations.count > 0')}
                >
                  <Box px={2} py={0.5}>
                    <Typography variant="m-regular">annotations.count {'>'} 0</Typography>
                  </Box>
                </ListItem>
                <ListItem
                  style={{ padding: '4px' }}
                  onClick={() => handleSampleQuery('annotations.type.box.count > 0')}
                >
                  <Box px={2} py={0.5}>
                    <Typography variant="m-regular">annotations.type.box.count {'>'} 0</Typography>
                  </Box>
                </ListItem>
                <ListItem
                  style={{ padding: '4px' }}
                  onClick={() =>
                    handleSampleQuery(
                      `images.created_at > ${format(Date.now(), 'yyyy-MM-dd')}T00:00:00.000Z
                      `,
                    )
                  }
                >
                  <Box px={2} py={0.5}>
                    <Typography variant="m-regular">
                      images.created_at {'>'} {`${format(Date.now(), 'yyyy-MM-dd')}T00:00:00.000Z`}
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      }
      open={showQueryError}
      placement="top-start"
      offset={0.5}
    >
      <Box
        backgroundColor={'primary-100'}
        boxSizing="border-box"
        p={1}
        border="1px solid"
        borderColor={'primary-400'}
        borderRadius="2px"
        width="100%"
        display="flex"
        alignItems="flex-start"
      >
        <Box mr={1} display="flex" alignItems="center" style={{ height: '24px' }}>
          <Icon icon={ErrorFilled} color={'primary-400'} />
        </Box>
        <Typography style={{ marginTop: '3px' }} color={'primary-400'} variant="m-regular">
          {queryError ?? ''}
        </Typography>
        <IconButton
          style={{ marginLeft: 'auto' }}
          size="s"
          variant="text"
          color="primary"
          icon={Clear}
          onClick={() => setShowQueryError(false)}
        />
      </Box>
    </Popper>
  );
}
