import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { SearchSmallPlus } from '@superb-ai/icons';
import { Button, Icon } from '@superb-ai/ui';
import { capitalize } from 'lodash';

import { WindowedGrid } from '../../../../../../../../components/elements/windowedImageGrid/WindowedGrid';
import { useColorModeContext } from '../../../../../../contexts/ColorModeContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useObjectClusterContext } from '../../../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { IMAGE_SCOPE } from '../../../../../../types/viewTypes';
import { routeView } from '../../../../../../utils/routeUtils';
import { mapClassToColors } from '../../../filter/ObjectClassFilter';
import { AnnotationFilterSchema, ImageFilterSchema } from '../../../filter/types';
import { mapClusterIdToInfo } from '../../../filter/utils';
import ImageCell from './ImageCell';
import ObjectImageCell from './ObjectImageCell';

export default function AllImagesGrid({
  areaWidth,
  areaHeight,
  columnsCount,
}: {
  areaWidth: number;
  areaHeight: number;
  columnsCount: number;
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const scope = searchParams.get('scope') || 'image';
  const {
    setAppliedFilters: setImageAppliedFilter,
    selectedProjectionIn: setImageSelectedProjectionIn,
    selectedSuperClusters: selectedImageClusters,
    imageClusters,
  } = useImageFilterContext();
  const {
    setAppliedFilters: setObjectAppliedFilter,
    selectedProjectionIn: setObjectSelectedProjectionIn,
    selectedSuperClusters: selectedObjectClusters,
    selectedClasses,
    classClusterFilterTab,
    filterSchema,
    classNameColorMap,
  } = useObjectFilterContext();

  const selectedProjectionIn =
    scope === IMAGE_SCOPE ? setImageSelectedProjectionIn : setObjectSelectedProjectionIn;

  const showDataButtonText = t(`curate.embeddings.button.showAll${capitalize(scope)}sInGrid`);
  const buttonHeight = 32;

  const { colorByMode } = useColorModeContext();

  const groupPrefix = t('curate.imageFilter.cluster');
  const noneText = t('curate.filter.unprocessed');

  const imageClusterMap = imageClusters
    ? mapClusterIdToInfo(imageClusters, colorByMode, groupPrefix, noneText)
    : {};
  const classSchema = filterSchema?.annotation_class_in;

  const objectClassToColorMap = mapClassToColors(classSchema ?? [], classNameColorMap, colorByMode);
  const { clusterColorMap: objectClusterColorMap } = useObjectClusterContext();
  const handleClickShowAllImages = () => {
    setImageAppliedFilter((appliedFilters: ImageFilterSchema) => ({
      ...appliedFilters,
      ...(selectedImageClusters && {
        cluster_id_in: selectedImageClusters as string[],
      }),
      ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
    }));
  };

  const handleClickShowAllObjects = () => {
    setObjectAppliedFilter((appliedFilters: AnnotationFilterSchema) => ({
      ...appliedFilters,
      ...(selectedClasses &&
        classClusterFilterTab === 'class' && {
          annotation_class_in: selectedClasses,
        }),
      ...(classClusterFilterTab === 'cluster' &&
        selectedObjectClusters && {
          annotation_class_cluster_in: selectedObjectClusters,
        }),
      ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
    }));
  };

  const ShowAllDataButton = (
    <Button
      variant="stroke"
      size="m"
      color="primary"
      style={{ height: `${buttonHeight}px`, width: '100%' }}
      onClick={() => {
        if (scope === 'image') handleClickShowAllImages();
        else handleClickShowAllObjects();
        routeView(history, 'grid');
      }}
    >
      <Icon icon={SearchSmallPlus} />
      {showDataButtonText}
    </Button>
  );
  return (
    <>
      <WindowedGrid
        loadedItems={[]}
        width={areaWidth}
        height={areaHeight - buttonHeight}
        itemData={{ columns: columnsCount, displayedPoints: [] }}
        CellComponent={scope === 'image' ? ImageCell : ObjectImageCell}
      />
      {ShowAllDataButton}
    </>
  );
}
