import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import UserRoleUnion from '../../../union/UserRoleUnion';
import WorkappUnion from '../../../union/WorkappUnion';
import ProjectUtils from '../../../utils/ProjectUtils';
import ImageCategoryListContainer from '../../elements/projectConfiguration/ImageCategoryListContainer';
import ObjectClassList from '../../elements/projectConfiguration/ObjectClassList';
import { getAllowSelfAssign } from '../newProject/helper';
import { AnnotationTypeIconList } from '../projects/AnnotationTypeIconList';
import helper from './helper';
import ImageCategoryList from './LegacyOverviewImageCategoryList';
import ProjectConfigSettingButton from './ProjectConfigSettingButton';

const useStyle = makeStyles(theme => ({
  projectConfigSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    width: '100%',
    overflow: 'auto',
  },
  tableCell: {
    padding: '4px 0',
    borderBottom: 'none',
    color: theme.palette.grey[900],
    verticalAlign: 'top',
  },
  icon: {},
}));

type Props = {
  className: string;
};

const ProjectConfig: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const { className } = props;
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const {
    project,
    project: { workapp, labelInterface },
  } = projectInfo;

  const consensusLabeling = useFeatureFlag('consensusLabeling');
  const isAvailableAdvancedQA =
    consensusLabeling && ProjectUtils.isAvailableAdvancedQA(project.workapp);

  const allowAdvancedQa = project?.settings?.allowAdvancedQa || false;
  const allowSelfAssign = getAllowSelfAssign(project?.settings?.numMaxSelfAssign);

  return (
    <MUI.Paper className={classnames(className, classes.projectConfigSection)}>
      <MUI.Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="green"
      >
        <Typography variant="headline4">{t('projectSettings.configuration.title')}</Typography>
        <ProjectConfigSettingButton />
      </MUI.Box>
      <MUI.Box className={classes.box} mt={theme.spacing(2)}>
        <MUI.Table>
          <colgroup>
            <col width="110" />
            <col />
          </colgroup>
          <MUI.TableBody>
            {!!project.description && (
              <MUI.TableRow>
                <MUI.TableCell className={classes.tableCell}>
                  <MUI.Typography variant="h6">
                    {t('projectSettings.configuration.description')}
                  </MUI.Typography>
                </MUI.TableCell>
                <MUI.TableCell
                  className={classes.tableCell}
                  style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                >
                  {project.description}
                </MUI.TableCell>
              </MUI.TableRow>
            )}
            <MUI.TableRow>
              <MUI.TableCell className={classes.tableCell}>
                <MUI.Typography variant="h6">
                  {t('projectSettings.configuration.dataType')}
                </MUI.Typography>
              </MUI.TableCell>
              <MUI.TableCell className={classes.tableCell}>
                {helper.getProjectConfigDataType(project.workapp, t)}
              </MUI.TableCell>
            </MUI.TableRow>
            <MUI.TableRow>
              <MUI.TableCell className={classes.tableCell}>
                <MUI.Typography variant="h6">
                  {t('projectSettings.configuration.annotationTypes')}
                </MUI.Typography>
              </MUI.TableCell>
              <MUI.TableCell className={classes.tableCell} style={{ whiteSpace: 'normal' }}>
                <AnnotationTypeIconList items={ProjectUtils.getAnnotationTypes(labelInterface)} />
              </MUI.TableCell>
            </MUI.TableRow>
            <MUI.TableRow>
              <MUI.TableCell className={classes.tableCell}>
                <MUI.Typography variant="h6">
                  {t('projectSettings.configuration.objectClass')}
                </MUI.Typography>
              </MUI.TableCell>
              <MUI.TableCell className={classes.tableCell}>
                <ObjectClassList project={project} maxHeight={90} />
              </MUI.TableCell>
            </MUI.TableRow>
            {WorkappUnion.isImageDefault(workapp) && labelInterface.categorization && (
              <MUI.TableRow>
                <MUI.TableCell className={classes.tableCell}>
                  <MUI.Typography variant="h6">
                    {t('projectSettings.configuration.imageCategory')}
                  </MUI.Typography>
                </MUI.TableCell>
                <MUI.TableCell className={classes.tableCell}>
                  <ImageCategoryListContainer maxHeight={90}>
                    <ImageCategoryList
                      categorization={project.labelInterface.categorization}
                      fontSize={12}
                      color="#212121"
                    />
                  </ImageCategoryListContainer>
                </MUI.TableCell>
              </MUI.TableRow>
            )}
            {WorkappUnion.isSiesta(workapp) &&
              !!labelInterface.categorization?.properties?.length && (
                <MUI.TableRow>
                  <MUI.TableCell className={classes.tableCell}>
                    <MUI.Typography variant="h6">
                      {WorkappUnion.isVideoApp(workapp)
                        ? t('projectSettings.configuration.videoCategory')
                        : t('projectSettings.configuration.imageCategory')}
                    </MUI.Typography>
                  </MUI.TableCell>
                  <MUI.TableCell className={classes.tableCell}>
                    <ImageCategoryListContainer maxHeight={90}>
                      {labelInterface.categorization?.properties?.map(
                        (property: any, idx: number) => (
                          <ImageCategoryList
                            isSiesta
                            categorization={property}
                            fontSize={12}
                            color="#212121"
                            key={idx}
                          />
                        ),
                      )}
                    </ImageCategoryListContainer>
                  </MUI.TableCell>
                </MUI.TableRow>
              )}
            <MUI.TableRow>
              <MUI.TableCell className={classes.tableCell}>
                <MUI.Typography variant="h6">{t('selfAssign.title')}</MUI.Typography>
              </MUI.TableCell>
              <MUI.TableCell className={classes.tableCell}>
                {allowSelfAssign ? t('status.enabled') : t('status.disabled')}
              </MUI.TableCell>
            </MUI.TableRow>
            {isAvailableAdvancedQA &&
              (UserRoleUnion.isAdmin(authInfo.role) || UserRoleUnion.isOwner(authInfo.role)) && (
                <MUI.TableRow>
                  <MUI.TableCell className={classes.tableCell}>
                    <MUI.Typography variant="h6">
                      {t('projectSettings.configuration.advancedQA')}
                    </MUI.Typography>
                  </MUI.TableCell>
                  <MUI.TableCell className={classes.tableCell}>
                    {allowAdvancedQa ? t('status.enabled') : t('status.disabled')}
                  </MUI.TableCell>
                </MUI.TableRow>
              )}
          </MUI.TableBody>
        </MUI.Table>
      </MUI.Box>
    </MUI.Paper>
  );
};

export default ProjectConfig;
