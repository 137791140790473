import { ComponentProps } from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { formatCount } from '../../../../../../../utils/numberFormat';

export const CountBadge = ({
  name,
  count,
  color,
  size = 'l',
}: {
  name: string;
  count?: number;
  color?: ComponentProps<typeof Typography>['color'];
  size: 'l' | 'm' | 's';
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" alignItems="center" gap={0.5}>
        {color && (
          <Box
            backgroundColor={color}
            style={{ width: '8px', height: '8px' }}
            borderRadius="100%"
          />
        )}
        <Typography variant={`${size}-regular`} color={'gray-300'} whiteSpace="nowrap">
          {name}
        </Typography>
      </Box>
      <Typography display="flex" pr={1} variant={`${size}-strong`} whiteSpace="nowrap">
        {typeof count !== undefined ? formatCount(count ?? 0) : '-'}
      </Typography>
    </Box>
  );
};
