import React, { useEffect } from 'react';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';
import Layout from './Layout';

// eslint-disable-next-line
const Index = (props: any): React.ReactElement => {
  const newProjectInfo = useNewProjectInfo();
  useEffect(() => {
    helper.updateObjectClassGroupMap(newProjectInfo);
    newProjectInfo.setSelectedObjectClassIds({});
    newProjectInfo.setSelectedObjectClassGroupId('');
    // eslint-disable-next-line
  }, []);

  return <Layout {...props} />;
};

export default Index;
