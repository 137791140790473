import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ChevronBigLeft,
  ChevronBigRight,
  ChevronLeft,
  ChevronRight,
  SearchSmallPlus,
} from '@superb-ai/icons';
import { Box, Button, Icon, IconButton, Range, Typography, useDialogState } from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { BorderBox } from '../../components/components';
import { ITERATION_INDEX_COEFFICIENT } from '../../constant';
import { useModelTrainingSamplePredictionsQuery } from '../../queries/modelQueries';
import {
  safeFormatTime,
  TrainingProgress,
  TrainingProgressWithoutSteps,
} from '../../recognition-ai/detail/Progress';
import { isGenerationModelAITraining } from '../../services/modelTrainingTypeGuards';
import { GenerationAIModelDetailTraining, ModelStatus, MyModelDetail } from '../../services/types';
import { SamplePredictionDialog } from '../SamplePredictionDialog';
import { SamplePredictionImages } from './SamplePredictionImages';

export const Progress = ({ data }: { data: MyModelDetail }) => {
  const { t } = useTranslation();
  return (
    <>
      {data.modelTraining.trainingHistory && (
        <BorderBox px={2.5} pt={2} pb={2.5} mb={1}>
          {data.status === 'failed' ? (
            <TrainingProgressWithoutSteps
              startedAt={safeFormatTime(data.modelTraining.trainingHistory[0].startedAt)}
              endedAt={safeFormatTime(data.statusUpdatedAt)}
            />
          ) : (
            <TrainingProgress data={data} />
          )}
        </BorderBox>
      )}
      {isGenerationModelAITraining(data.modelTraining) && (
        <BorderBox>
          <Box px={2.5} py={2} bb="1px solid" borderColor="gray-150">
            <Typography variant="l-strong">
              {t('model.myModelDetail.samplePrediction.title')}
            </Typography>
          </Box>
          {!data.modelTraining.modelTrainingReferenceImages &&
          data.modelTraining.modelTrainingIterations.length === 0 ? (
            <Row
              justifyContent="center"
              backgroundColor={'gray-100'}
              textAlign="center"
              style={{ height: 506 }}
            >
              <Typography variant="l-regular" color={'gray-300'}>
                <Trans t={t} i18nKey={'model.myModelDetail.samplePrediction.beforeOneEpoch'} />
              </Typography>
            </Row>
          ) : (
            <SamplePrediction
              datasetId={data.trainingSet.referenceId}
              modelTraining={data.modelTraining}
              modelStatus={data.status}
            />
          )}
        </BorderBox>
      )}
    </>
  );
};

const SamplePrediction = ({
  datasetId,
  modelTraining,
  modelStatus,
}: {
  datasetId: string;
  modelTraining: GenerationAIModelDetailTraining;
  modelStatus: ModelStatus;
}) => {
  const { t } = useTranslation();
  const [iterationIndex, setIterationIndex] = useState(
    modelTraining.modelTrainingIterations.length - 1,
  );
  const debouncedIterationIndex = useDebounce(iterationIndex, 300);
  const dialogState = useDialogState();

  const { data: referenceAnnotation, isLoading: isRefLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: modelTraining.modelTrainingReferenceImages.annotations,
      enabled: Boolean(modelTraining.modelTrainingReferenceImages.annotations),
    });

  const { data: iterationAnnotation, isLoading: isIterLoading } =
    useModelTrainingSamplePredictionsQuery({
      url: modelTraining.modelTrainingIterations[modelTraining.modelTrainingIterations.length - 1]
        .annotations,
      enabled: Boolean(
        modelTraining.modelTrainingIterations[modelTraining.modelTrainingIterations.length - 1]
          .annotations,
      ),
    });

  return (
    <Box display="grid" style={{ height: 506, gridTemplateColumns: '260px 1fr' }}>
      <Box
        br="1px solid"
        borderColor="gray-150"
        display="grid"
        style={{ gridTemplateRows: '1fr 72px' }}
      >
        <Box p={2.5} bb="1px solid" borderColor="gray-150">
          <Typography variant="m-medium">{t('model.generativeAi.iteration')}</Typography>
          <Row mt={1}>
            <Range
              min={0}
              max={modelTraining.modelTrainingIterations.length - 1}
              value={iterationIndex}
              onChange={e => setIterationIndex(Number(e.target.value))}
            />
            <Row ml={1} style={{ gap: 2 }}>
              <IconButton
                icon={ChevronBigLeft}
                variant="text"
                disabled={iterationIndex === 0}
                onClick={() => {
                  if (iterationIndex === 0) return;
                  setIterationIndex(prev => prev - 1);
                }}
              />
              <BorderBox px={1} py={0.5}>
                <Typography variant="s-regular">
                  {(iterationIndex + 1) * ITERATION_INDEX_COEFFICIENT}
                </Typography>
              </BorderBox>
              <IconButton
                icon={ChevronBigRight}
                variant="text"
                disabled={iterationIndex === modelTraining.modelTrainingIterations.length - 1}
                onClick={() => {
                  if (iterationIndex === modelTraining.modelTrainingIterations.length - 1) return;
                  setIterationIndex(prev => prev + 1);
                }}
              />
            </Row>
          </Row>
        </Box>
        <Row p={2.5} width="100%">
          <Button color="primary" style={{ marginLeft: 'auto' }} onClick={() => dialogState.show()}>
            <Icon icon={SearchSmallPlus} />
            {t('model.generativeAi.viewSampleDetail')}
          </Button>
        </Row>
      </Box>
      <SamplePredictionImages
        modelTraining={modelTraining}
        modelStatus={modelStatus}
        iterationIndex={debouncedIterationIndex}
      />
      {dialogState.visible && (
        <SamplePredictionDialog
          state={dialogState}
          modelTraining={modelTraining}
          iterationIndex={iterationIndex}
          referenceAnnotation={referenceAnnotation}
          iterationAnnotation={iterationAnnotation}
          isRefLoading={isRefLoading}
          isIterLoading={isIterLoading}
          datasetId={datasetId}
          header={
            <ProgressIteration
              iterationIndex={iterationIndex}
              maxIterationIndex={modelTraining.modelTrainingIterations.length - 1}
              setIterationIndex={setIterationIndex}
            />
          }
        />
      )}
    </Box>
  );
};

const ProgressIteration = ({
  iterationIndex,
  maxIterationIndex,
  setIterationIndex,
}: {
  iterationIndex: number;
  maxIterationIndex: number;
  setIterationIndex: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation();
  return (
    <Row gap={1}>
      <Typography variant="m-medium" color="white">
        {t('model.generativeAi.iteration')}
      </Typography>
      <Range
        theme="dark"
        min={0}
        max={maxIterationIndex}
        value={iterationIndex}
        onChange={e => setIterationIndex(Number(e.target.value))}
      />
      <Row>
        <IconButton
          icon={ChevronLeft}
          variant="text"
          color="white"
          disabled={iterationIndex === 0}
          onClick={() => {
            setIterationIndex(prev => prev - 1);
          }}
        />
        <Row
          backgroundColor={'gray-500'}
          borderRadius="2px"
          px={1}
          style={{ width: 36, height: 24 }}
        >
          <Typography variant="s-regular" color="white">
            {(iterationIndex + 1) * ITERATION_INDEX_COEFFICIENT}
          </Typography>
        </Row>
        <IconButton
          icon={ChevronRight}
          variant="text"
          color="white"
          disabled={iterationIndex === maxIterationIndex}
          onClick={() => {
            setIterationIndex(prev => prev + 1);
          }}
        />
      </Row>
    </Row>
  );
};
