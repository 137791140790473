import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '@superb-ai/norwegian-forest';
import { Box, Button, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { getUrl } from '../../../routes/util';
import { useChangeContent } from './Layout';
import Title from './Title';

export default function WelcomeLayout() {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const { accountName } = useAuthInfo();
  const [signupSampleProject] = useLocalStorage<string | null>('signupSampleProject', null);

  useChangeContent('graphic');

  const handleClickLogin = (): void => {
    const query = signupSampleProject ? { createdSampleProject: signupSampleProject } : {};
    routeInfo.history.push(getUrl([accountName ?? '', 'project', 'list'], {}, query));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={3}>
      <Title>{t('auth.messages.congratulations')}</Title>
      <Box>
        <Typography variant="l-regular">{t('auth.messages.accountHasBeenActivated')}</Typography>
      </Box>
      <Button size="l" color="primary" variant="strong-fill" onClick={handleClickLogin}>
        {t('auth.messages.startUsingSuite')}
      </Button>
    </Box>
  );
}
