import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import Intro from '../../../../../components/pages/aiAdvancedFeatures/autoLabel/cal/Intro';
import { CreateMenuItem } from '../../../../../menu';

export const ProjectAutoLabelIntroMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectAutoLabelIntro,
  path: 'upgrade',
  title: ({ t }) => t('autoLabel.upgradeTitle'),
  component: Intro,
});
