import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Icon, List, ListItem, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { escapeRegExp } from 'lodash';

import LabelInterfaceUtils, { ObjectClass } from '../../../../../../utils/LabelInterfaceUtils';
import { overflowOverlayOrAuto } from '../../../../../../utils/style';
import SearchBar from '../../../../../elements/SearchBar';
import { Engine } from '../helper';
import { getIconName } from './components';
import { useItemSelection, useSearch } from './hooks';
import SelectClassesTree from './SelectClassesTree';

interface Props {
  selectedItem: ObjectClass;
  engine: Engine;
  value: string[];
  onChange(ids: string[]): void;
  mappedClassIds: string[];
}

type CheckboxState = 'checked' | 'mixed' | '';

function hasMatchingChildren<T extends { name: string; children?: T[] }>(
  node: T,
  regex: RegExp,
): boolean {
  if (node.children?.length) {
    return node.children.some(
      child => !!child.name?.match(regex) || hasMatchingChildren(child, regex),
    );
  }
  return false;
}

export default function SelectClasses(props: Props): JSX.Element {
  const { selectedItem, engine, value, onChange, mappedClassIds } = props;

  const { t } = useTranslation();

  const { selectedItems, isItemSelected, toggleItem, setSelectedItems } = useItemSelection({
    value,
    multiple: true,
    onChange,
  });

  const { setSearchTerm, visibleItems, formatMatch } = useSearch({
    items: LabelInterfaceUtils.getObjectClasses(engine.labelInterface),
    predicate: searchTerm => {
      const regex = new RegExp(escapeRegExp(searchTerm), 'i');
      return item => !!item.name?.match(regex) || hasMatchingChildren(item, regex);
    },
  });

  function getCheckboxIconName(cls: ObjectClass) {
    return `checkbox${
      isItemSelected(cls.id) ? 'Checked' : ''
    }` as `checkbox${Capitalize<CheckboxState>}`;
  }

  function renderListItems(items: ObjectClass[], depth = 0): React.ReactNode {
    return items.map(cls => {
      const isAlreadyMapped = !isItemSelected(cls.id) && mappedClassIds.includes(cls.id);
      const isDisabled =
        (engine.project.id !== 'pretrained' &&
          cls.annotationType !== selectedItem.annotationType) ||
        isAlreadyMapped;
      const isSelected = isItemSelected(cls.id);
      const boxProps = isDisabled
        ? {
            themedColor: ['grey', 300],
          }
        : isSelected
        ? {
            themedColor: 'secondary',
          }
        : {};
      const content = (
        <Box display="flex" alignItems="center" gap="4px" pl={depth * 2} {...boxProps}>
          <Icon size="16px" name={getCheckboxIconName(cls)} />
          {cls.annotationType && <Icon name={getIconName(cls.annotationType)} size="10px" />}
          <Typography variant="body3">
            {formatMatch(cls.name, m => (
              <span style={{ background: '#5a7bff', color: '#fff' }}>{m}</span>
            ))}
          </Typography>
        </Box>
      );
      return (
        <React.Fragment key={cls.id}>
          <ListItem
            selectedBgColor="secondary"
            bgColor="grey"
            onClick={() => !isDisabled && toggleItem(cls.id)}
            isSelected={isSelected}
            disabled={isDisabled}
          >
            {!isDisabled ? (
              content
            ) : (
              <Tooltip placement="bottom-start" anchorEl={content}>
                {isAlreadyMapped
                  ? t('autoLabel.settings.messages.cannotSelectBecauseAlreadyMapped')
                  : t('autoLabel.settings.messages.cannotSelectBecauseDoesNotMatch')}
              </Tooltip>
            )}
          </ListItem>
        </React.Fragment>
      );
    });
  }

  const showAsTree = visibleItems.some(item => !!item.children?.length);

  const actionText = t('autoLabel.settings.mapping.selectClassesToLabelItem', {
    name: selectedItem.name,
  });

  return (
    <Box display="flex" flexDirection="column" minHeight={0} m={1.5} flex={1}>
      {!showAsTree ? (
        <>
          <SearchBar
            size="l"
            placeholder={actionText}
            onChange={e => setSearchTerm(e.currentTarget.value)}
          />
          <Box
            display="flex"
            minHeight="0"
            overflow={overflowOverlayOrAuto()}
            mt={1}
            flexDirection="column"
          >
            <List selectedBgColor="secondary" bgColor="grey" margin="" itemPadding="9px 10px">
              {renderListItems(visibleItems)}
            </List>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body3" style={{ wordBreak: 'break-all' }}>
            {actionText}
          </Typography>
          <Box mt={1} height="100%">
            <SelectClassesTree
              items={visibleItems}
              disabledItems={mappedClassIds}
              onChange={setSelectedItems}
              selectedItems={selectedItems}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
