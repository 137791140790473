import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useParams } from 'react-router';

import { Box } from '@superb-ai/ui';
import { TFunction } from 'next-i18next';

import { Step } from '../../../../../../../../components/composition/VerticalStepper';
import SliceNameInput from '../../../../../../elements/NameInput/SliceNameInput';

const SelectSlice = (
  t: TFunction,
  isDialogOpen: boolean,
  {
    slice,
    setSlice,
    isSliceNameValid,
    setIsSliceNameValid,
  }: {
    slice: string;
    setSlice: Dispatch<SetStateAction<string>>;
    isSliceNameValid: boolean;
    setIsSliceNameValid: Dispatch<SetStateAction<boolean>>;
  },
): Step => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { datasetId } = useParams<{ datasetId: string }>();

  return {
    title: t('curate.datasets.generateSyntheticImages.steps.saveToSlice.title'),
    isButtonEnabled: !!slice && isSliceNameValid,
    summary: slice,
    content: (
      <Box>
        <SliceNameInput
          ref={inputRef}
          boxProps={{ style: { zIndex: 1000, height: 40 } }}
          sliceName={slice}
          setSliceName={setSlice}
          setIsValid={setIsSliceNameValid}
          datasetId={datasetId}
        />
      </Box>
    ),
  };
};

export default SelectSlice;
