import { useQuery } from '@tanstack/react-query';
import { isBoolean } from 'lodash';

import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateDatasetObjectService } from '../services/DatasetObjectService';
import { useCurateDatasetService } from '../services/DatasetService';

export function useEmbeddingStatusQuery({
  datasetId,
  fromPublicDatasets,
}: {
  datasetId: string;
  fromPublicDatasets?: boolean;
}) {
  const { getDatasetBriefDataList } = useCurateDatasetService();
  const { postGetObjectList } = useCurateDatasetObjectService();
  const { getDataset } = useCurateDatasetService();

  const { showPublicDatasets } = usePublicDatasetContext();

  return useQuery({
    queryKey: ['curate-dataset-embedding-status', datasetId],
    queryFn: async () => {
      const result = await getDataset({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        expand: ['embedding_status'],
      });
      const {
        datasetTotalCount: objectTotalCount,
        count: objectProcessingCount,
        syntheticTotalCount: objectSyntheticCount,
      } = await postGetObjectList({
        dataset_id: datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        size: 0,
        expand: ['dataset_total_count', 'synthetic_total_count'],
        embedding_ready: true,
      });

      const {
        datasetTotalCount: imageTotalCount,
        count: imageProcessingCount,
        syntheticTotalCount: imageSyntheticCount,
      } = await getDatasetBriefDataList({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        size: 0,
        expand: ['dataset_total_count', 'synthetic_total_count'],
        embeddingReady: true,
      });

      // TODO: handle below in server
      // overwrite when datasetEmbeddingStatus is misleading
      if (
        result.embeddingStatus &&
        result.embeddingStatus?.stage === 'done' &&
        (imageTotalCount !== imageProcessingCount + imageSyntheticCount ||
          objectTotalCount !== objectProcessingCount + objectSyntheticCount)
      ) {
        result.embeddingStatus.stage = 'cluster_assign';
        result.embeddingStatus.state = 'pending';
        result.embeddingStatus.remainingTime = undefined;
        result.embeddingStatus.progress = 0;
      }
      return {
        ...result,
        imageTotalCount,
        objectTotalCount,
        imageProcessingCount,
        objectProcessingCount,
        imageSyntheticCount,
        objectSyntheticCount,
      };
    },
    refetchInterval: (data: any) => (data?.embeddingStatus?.state === 'done' ? false : 10000),
    cacheTime: 600000,
    staleTime: 600000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(datasetId) && !fromPublicDatasets,
  });
}
