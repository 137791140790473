import React from 'react';

import * as MUI from '@mui/material';
import { ArrowLeft, Refresh } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { useRouteInfo } from '../../../contexts/RouteContext';

interface Props {
  parentClasses: Record<string, string>;
}

const SomethingWentWrong: React.FC<Props> = props => {
  const { parentClasses } = props;
  const routeInfo = useRouteInfo();

  return (
    <>
      <MUI.Box className={parentClasses.imageBox}>
        <img alt="symbol" src="https://asset.superb-ai.com/suite/clip-something-went-wrong.png" />
      </MUI.Box>
      <MUI.Box className={parentClasses.contentsBox}>
        <MUI.Typography className={parentClasses.headerText}>Oops!</MUI.Typography>
        <MUI.Typography className={parentClasses.contentsText}>
          Something went wrong there...
        </MUI.Typography>
        <MUI.Typography className={parentClasses.contentsText}>We&apos;ll fix asap.</MUI.Typography>
        <MUI.Box className={parentClasses.buttonGroup}>
          <MUI.Button
            className={parentClasses.actionButton}
            onClick={() => routeInfo.history.goBack()}
          >
            <Icon icon={ArrowLeft} />
            <MUI.Typography className={parentClasses.buttonText}>Go back</MUI.Typography>
          </MUI.Button>
          <MUI.Button
            className={parentClasses.actionButton}
            onClick={() => window.location.reload()}
          >
            <Icon icon={Refresh} />
            <MUI.Typography className={parentClasses.buttonText}>Try again?</MUI.Typography>
          </MUI.Button>
        </MUI.Box>
      </MUI.Box>
    </>
  );
};

export default SomethingWentWrong;
