import { TFunction, Trans } from 'next-i18next';

import tutorialAutoCurate from '../../../../../assets/img/tutorialAutoCurate.png';
import tutorialDataManagement from '../../../../../assets/img/tutorialDataManagement.png';
import tutorialDiagnosis from '../../../../../assets/img/tutorialDiagnosis.png';
import { getUserManualUrl } from '../../../../../consts/DocsLink';

export function getTutorialContents(t: TFunction, language: string) {
  const { CURATE_AUTO_CURATE_MANUAL, CURATE_QUERY_MANUAL, CURATE_MODEL_DIAGNOSIS_INTRO } =
    getUserManualUrl(language);

  return [
    {
      imageSrc: tutorialDataManagement,
      title: t('curate.tutorial.dataManagement.title'),
      description: <Trans t={t} i18nKey="curate.tutorial.dataManagement.description" />,
      link: CURATE_QUERY_MANUAL,
    },
    {
      imageSrc: tutorialAutoCurate,
      title: t('curate.tutorial.autoCurateAndScatterView.title'),
      description: t('curate.tutorial.autoCurateAndScatterView.description'),
      link: CURATE_AUTO_CURATE_MANUAL,
    },
    {
      imageSrc: tutorialDiagnosis,
      title: t('curate.tutorial.diagnosis.title'),
      description: t('curate.tutorial.diagnosis.description'),
      link: CURATE_MODEL_DIAGNOSIS_INTRO,
    },
  ];
}
