import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Input, Modal } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import FileService from '../../../../../../services/FileService';
import FileUtils from '../../../../../../utils/FileUtils';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { useDatasetsBriefDataQuery } from '../../../../queries/dataQueries';
import { useCurateCommandsService } from '../../../../services/CommandsService';

export default function DeleteDataModal({
  deleteDataModalIsOpen,
  setDeleteDataModalIsOpen,
}: {
  deleteDataModalIsOpen: boolean;
  setDeleteDataModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { getJobPresignedUrl, postJob } = useCurateCommandsService();
  const { selectedSuperClusters, clusterLevel } = useImageFilterContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const commandContext = useCurateCommandContext();
  const { selectedData, setSelectedData, deselectedData, selectedAllData, setSelectedAllData } =
    useActionContext();

  const { sliceInfo } = useSliceContext();

  const { totalCount: totalImageCount } = useImageScopeContext();
  // TODO: maybe generate job params in FilterContext
  const { selectedProjectionIn, appliedFilters } = useImageFilterContext();
  const totalCount = totalImageCount || 0;
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();

  const [deleteConfirmInput, setDeleteConfirmInput] = useState<string>('');
  const { refetch: refetchData } = useDatasetsBriefDataQuery({
    datasetId,
    queryString: queryStringWithHiddenFilterAndDeselection,
    sliceName: sliceInfo?.name || '',
    // TODO (moon) do we need filter here
    appliedFilters,
  });

  const handleDeleteData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (selectedAllData) {
        const paramData = {
          dataset_id: datasetId,
          image_filters: {
            ...(sliceInfo?.name && { slice: sliceInfo.name }),
            ...(queryStringWithHiddenFilterAndDeselection &&
              queryStringWithHiddenFilterAndDeselection !== '' && {
                query: queryStringWithHiddenFilterAndDeselection,
              }),
            ...(clusterLevel &&
              selectedSuperClusters &&
              selectedSuperClusters.length && {
                cluster_id_in: selectedSuperClusters,
                cluster_level: clusterLevel,
              }),
            ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
          },
        };
        const result = await postJob({
          job_type: 'DELETE_IMAGES_BY_QUERY',
          param: paramData,
        });
        await commandContext.registerCommand(result.id, paramData);
      } else {
        const dataJson = selectedData.map(list => {
          return { id: list.id };
        });
        const jsonFile = JSON.stringify(dataJson);
        const jsonFileSize = FileUtils.getJsonFileSize(jsonFile);
        const presignedUrlRes = await getJobPresignedUrl({ file_size: jsonFileSize });
        await FileService.uploadPresignedJSON({
          uploadUrl: presignedUrlRes.uploadUrl,
          jsonFile,
        });
        const paramData = {
          dataset_id: datasetId,
          images: {
            ids: selectedData.map(image => image.id),
            keys: [],
            param_id: presignedUrlRes.id,
          },
        };
        const result = await postJob({
          job_type: 'DELETE_IMAGES',
          param: paramData,
        });
        await commandContext.registerCommand(result.id, paramData);
      }

      setSelectedAllData(false);
      setSelectedData([]);
      setDeleteDataModalIsOpen(false);
      refetchData();
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedAllData,
    selectedData,
    datasetId,
    sliceInfo,
    queryStringWithHiddenFilterAndDeselection,
  ]);

  return (
    <Modal
      open={deleteDataModalIsOpen}
      close={{
        onClose: () => {
          setDeleteDataModalIsOpen(false);
          setDeleteConfirmInput('');
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.deleteFromDataset')}
      mainButton={{
        text: t('button.confirm'),
        onClick: handleDeleteData,
        color: 'primary',
        isLoading,
        disabled: deleteConfirmInput !== 'DELETE',
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => {
          setDeleteDataModalIsOpen(false);
          setDeleteConfirmInput('');
        },
      }}
    >
      <Box minWidth="520px" py={2.5} px={4}>
        <Box display="flex">
          <Typography variant="m-regular">
            <Trans t={t} i18nKey={'curate.data.deleteWarningDeleteImage'}>
              <>Are you sure you want to delete </>
              <Typography variant="m-medium" color="primary-400">
                {{
                  imageCount: selectedAllData
                    ? totalCount - deselectedData.length
                    : selectedData.length,
                }}{' '}
              </Typography>
            </Trans>
          </Typography>
        </Box>

        <Typography variant="m-regular" color="gray-400">
          <Trans t={t} i18nKey={'curate.data.deleteWarningDeleteSlice'} />
        </Typography>
        {selectedAllData || selectedData?.length > 0 ? (
          <Box mt={2}>
            <Typography variant="m-regular">
              {selectedAllData ? (
                deselectedData.length === 0 ? (
                  <Trans t={t} i18nKey="curate.dialogs.selectedAll">
                    Selected
                    <Typography color="primary">All</Typography>
                  </Trans>
                ) : (
                  <Trans
                    t={t}
                    i18nKey="curate.dialogs.selectedCount"
                    values={{ count: totalCount - deselectedData.length }}
                  >
                    Selected
                    <Typography color="primary">{selectedData?.length}</Typography>
                  </Trans>
                )
              ) : (
                <Trans
                  t={t}
                  i18nKey="curate.dialogs.selectedCount"
                  values={{ count: selectedData.length }}
                >
                  Selected
                  <Typography color="primary">{selectedData?.length}</Typography>
                </Trans>
              )}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box style={{ marginTop: '16px', marginBottom: '6px' }}>
          <Typography variant="m-regular" color="gray-400">
            <Trans t={t} i18nKey={'curate.data.deleteWarningAction'} />
          </Typography>
        </Box>

        <Input
          color="grey"
          placeholder={t('curate.dialogs.placeholder.confirm')}
          variant="filled"
          onChange={event => {
            setDeleteConfirmInput(event.target.value);
          }}
        />
      </Box>
    </Modal>
  );
}
