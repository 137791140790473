import { useEffect, useState } from 'react';

import { toInteger } from 'lodash';

import { useRouteInfo } from '../contexts/RouteContext';

// Replace dataType coming from Data filter for use in projects API
// TODO @paul: move this to a central place
// Similar data is in apps/sahara/src/components/elements/newUpload/stepper/steps/ProjectAssign.tsx
const assetDataTypeToProjectDataType = [
  ['VIDEO_STREAM', 'video'],
  ['VIDEO_FRAMES', 'video'],
  ['IMAGE', 'image'],
  ['POINT_CLOUD_SEQUENCE', 'pointclouds'],
  ['image sequence', 'video'],
];

const getFormattedParams = (routeParams: Record<string, string>) => {
  const { dataType, projectName, search, annotationType, ...params } = routeParams as Record<
    string,
    string
  >;

  if (dataType && dataType.indexOf(',') === -1) {
    // dataType filter only supports one type now,
    // so for now just treat 'more than 1' as 'no filter'.
    params.dataType = dataType;
    for (const [from, to] of assetDataTypeToProjectDataType) {
      params.dataType = params.dataType.replace(from, to);
    }
  }
  if (projectName) {
    params.nameIcontains = projectName;
  }
  if (search) {
    params.nameIcontains = search;
  }
  if (annotationType) {
    params.annotationType = annotationType.split(',') as any;
  }
  return params;
};

export const useProjectPagination = () => {
  const routeInfo = useRouteInfo();
  const [params, setParams] = useState({
    page: routeInfo.params.page ? toInteger(routeInfo.params.page) : 1,
    pageSize: routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10,
  });

  useEffect(() => {
    setParams({
      ...getFormattedParams(routeInfo.params),
      page: routeInfo.params.page ? toInteger(routeInfo.params.page) : 1,
      pageSize: routeInfo.params.pageSize ? toInteger(routeInfo.params.pageSize) : 10,
    });
  }, [routeInfo]);

  return params;
};
