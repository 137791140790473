import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDown, NumericType, RadioChecked, TextAlignLeft } from '@superb-ai/icons';
import { Box, Button, Icon, Popover, SelectList, ToggleButton, Typography } from '@superb-ai/ui';

import { MetadataProperty, MetadataPropertyType } from './type';

export default function PropertyMenu({
  style,
  onChange,
  property,
}: {
  style?: CSSProperties;
  onChange: (value: MetadataProperty) => void;
  property: MetadataProperty;
}) {
  const { t } = useTranslation();
  const selectedOption = property?.type || 'free-response';
  const isRequired = property?.isRequired || false;
  const isOnlyNumber = property?.isOnlyNumber || false;

  const propertyOption = [
    {
      label: (
        <Box display="flex" alignItems="center" gap={0.5}>
          <Icon icon={TextAlignLeft} />
          {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.free-response')}

          {property.type === 'free-response' && (
            <Typography
              variant="s-regular"
              px={0.5}
              ml="auto"
              gap={0.5}
              backgroundColor="gray-opacity-100"
              borderRadius="2px"
              color="gray"
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={e => {
                e.stopPropagation();
                onChange({ ...property, isOnlyNumber: !isOnlyNumber });
              }}
            >
              {isOnlyNumber ? (
                <>
                  <Icon icon={NumericType} />
                  123
                </>
              ) : (
                <>
                  <Icon icon={TextAlignLeft} />
                  ABC
                </>
              )}
            </Typography>
          )}
        </Box>
      ),
      value: 'free-response',
    },
    {
      label: (
        <Box display="flex" alignItems="center" gap={0.5}>
          <Icon icon={RadioChecked} />
          {t(
            'curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.multiple-choice',
          )}
        </Box>
      ),
      value: 'multiple-choice',
    },
  ];

  if (!property) return <></>;

  return (
    <Popover
      disclosure={
        <Button size="s" style={{ ...style, width: '112px' }}>
          {t(
            `curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.${selectedOption}`,
          )}
          <Icon icon={ChevronDown} style={{ marginLeft: 'auto' }} />
        </Button>
      }
      style={{ zIndex: 1000 }}
    >
      <Box
        backgroundColor="white"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
        onClick={e => e.stopPropagation()}
        style={{ width: '192px' }}
      >
        <Box
          p={1}
          borderBottom="1px solid"
          borderColor="gray-200"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={0.5}
        >
          <Typography variant="m-regular" color="gray-300">
            {t('forms.required')}
          </Typography>
          <ToggleButton
            size="xs"
            checked={isRequired}
            onClick={e => e.stopPropagation()}
            onChange={() => {
              onChange({ ...property, isRequired: !isRequired });
            }}
          />
        </Box>
        <SelectList
          data={propertyOption}
          multiple={false}
          value={selectedOption}
          onClick={e => e.stopPropagation()}
          onChangeValue={(value: MetadataPropertyType) => {
            onChange({ ...property, type: value });
          }}
        />
      </Box>
    </Popover>
  );
}
