import { useHistory } from 'react-router';

import { BaselineProvider } from './contexts/BaselineContext';
import { DatasetClassProvider } from './contexts/DatasetClassContext';
import { ModelSettingProvider } from './contexts/ModelSettingContext';
import { TrainModelDialog } from './TrainModelDialog';
import { TrainModelDialogSuccess } from './TrainModelDialogSuccess';

export function Layout() {
  const history = useHistory();
  const showSuccess = history.location.pathname.endsWith('success');
  if (showSuccess) return <TrainModelDialogSuccess />;
  return (
    <BaselineProvider>
      <DatasetClassProvider>
        <ModelSettingProvider>
          <TrainModelDialog />
        </ModelSettingProvider>
      </DatasetClassProvider>
    </BaselineProvider>
  );
}
