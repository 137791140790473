import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import parentHelper from '../helper';
import ListContainer from '../ListContainer';
import CategoryItem from './CategoryItem';
import CategoryListDropZone from './CategoryListDropZone';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '310px',
    height: '100%',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '44px',
    borderBottom: 'solid 0.5px #ffafab',
    padding: '0px 0px 0px 24px',
  },
  optionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '40px',
  },
  input: {
    flex: 1,
    fontWeight: 500,
    fontSize: '12px',
    color: '#202020',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    height: '100%',
    width: '77px',
    borderRadius: '0px',
  },
  categoryBox: {
    // maxHeight: "-webkit-fill-available",
    position: 'relative',
    flex: 1,
    padding: '6px 18px',
    overflow: overflowOverlayOrAuto(),
  },
  categoryInnerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '600px',
  },
  createCategoryGroupButton: {
    width: '100%',
    height: '46px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    fontSize: '14px',
    color: '#fff',
    borderRadius: '0px',
    textTransform: 'none',
  },
  toggleBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  toggleText: {
    fontSize: '11px',
    color: '#9b9b9b',
    marginRight: '4px',
  },
}));

const CategoryList: React.FC<any> = props => {
  const {
    imageCategory,
    imageCategory: { perFrame, required, imageCategoryMap },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const listRef = useRef<HTMLDivElement>();
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { root } = imageCategory.imageCategoryMap;

  const rootCategories = root.children.filter(
    (categoryId: string) => !imageCategoryMap[categoryId].children,
  );
  const [inputValue, setInputValue] = useState('');

  const selectedCategoriesCount = Object.keys(newProjectInfo.selectedCategories).length;

  const [isPressDelete, setIsPressDelete] = useState(false);
  const [isImageSequence] = useState(WorkappUnion.isImageSequence(newProjectInfo.selectedDataType));

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedCategories = () => {
    const categories = Object.keys(newProjectInfo.selectedCategories);
    if (categories.length !== 0) {
      helper.deleteCategory(newProjectInfo);
    }
  };

  useEffect(() => {
    if (!isPressDelete) return;

    deleteSelectedCategories();
    setIsPressDelete(false);
    // eslint-disable-next-line
  }, [isPressDelete]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(parentHelper.parseNameValue(t, e.target.value, 'Category name', enqueueSnackbar));
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;

      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const addCategory = () => {
    const categoryNameList = Object.values(imageCategoryMap)
      .filter((item: any) => !item.children)
      .map((item: any) => item.name);

    const resultOfCheckNameRule = parentHelper.checkNameRule({
      t,
      type: 'Category name',
      prevValue: '',
      nextValue: inputValue,
      index: -1,
      sameHierarchyNameList: categoryNameList,
      enqueueSnackbar,
    });

    if (!resultOfCheckNameRule.result) return;

    const id = uuidv4();
    imageCategoryMap[id] = {
      id,
      parent: 'root',
      name: resultOfCheckNameRule.name,
    };

    imageCategoryMap.root.children.push(id);
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    newProjectInfo.setImageCategories(nextImageCategories);
    setInputValue('');
    scrollToBottom();
  };

  const handleKeyPressInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      addCategory();
    }
  };

  const handleClickAddButton = () => {
    addCategory();
  };

  const handleClickCreateCategoryGroup = () => {
    const nextChildren = Object.keys(newProjectInfo.selectedCategories);
    helper.createGroup(newProjectInfo, 'root', nextChildren, false, false);
  };

  const handleChangeRequired = () => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const curImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    curImageCategory.required = !required;
    newProjectInfo.setImageCategories(nextImageCategories);
  };

  const handleChangePerFrame = () => {
    const nextImageCategories = cloneDeep(newProjectInfo.imageCategories);
    const curImageCategory = nextImageCategories[newProjectInfo.selectedImageCategoryIndex];
    curImageCategory.perFrame = !perFrame;
    newProjectInfo.setImageCategories(nextImageCategories);
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer>
        <MUI.Box className={classes.innerBox}>
          <MUI.Box className={classes.inputBox}>
            <MUI.Input
              inputRef={inputRef}
              className={classes.input}
              placeholder={t('projectSettings.configuration.typeCategoryName')}
              value={inputValue}
              onChange={handleChangeInput}
              onKeyPress={handleKeyPressInput}
              onKeyDown={e => e.stopPropagation()}
            />
            <MUI.Button
              className={classes.addButton}
              color="primary"
              onClick={handleClickAddButton}
            >
              {t('button.add')}
            </MUI.Button>
          </MUI.Box>

          <MUI.Box className={classes.optionBox}>
            <MUI.Box className={classes.toggleBox}>
              <MUI.Typography className={classes.toggleText}>{t('forms.required')}</MUI.Typography>
              <MUI.Switch
                size="small"
                color="primary"
                checked={required}
                onChange={handleChangeRequired}
              />
            </MUI.Box>
            {isImageSequence && (
              <MUI.Box className={classes.toggleBox}>
                <MUI.Typography className={classes.toggleText}>
                  {t('projectSettings.configuration.perFrame')}
                </MUI.Typography>
                <MUI.Switch
                  size="small"
                  color="primary"
                  checked={perFrame}
                  onChange={handleChangePerFrame}
                />
              </MUI.Box>
            )}
          </MUI.Box>
          <MUI.Box {...{ ref: listRef }} className={classes.categoryBox}>
            <CategoryListDropZone />
            <MUI.Box className={classes.categoryInnerBox}>
              {rootCategories.map((categoryId: string, index: number) => (
                <CategoryItem
                  key={categoryId}
                  index={index}
                  category={imageCategoryMap[categoryId]}
                  imageCategoryMap={imageCategoryMap}
                />
              ))}
            </MUI.Box>
          </MUI.Box>
          <MUI.Button
            className={classes.createCategoryGroupButton}
            onClick={handleClickCreateCategoryGroup}
            disabled={selectedCategoriesCount === 0}
            variant="contained"
            color="primary"
          >
            {t('projectSettings.configuration.createCategoryGroup')}
            {` (${selectedCategoriesCount} selected)`}
          </MUI.Button>
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default CategoryList;
