import React from 'react';

import { Box } from '@superb-ai/ui';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import AccessAccount from './AccessAccount';
import Description from './Description';
import PersonalAccessKey from './PersonalAccessKey/PersonalAccessKey';
import TeamAccessKey from './TeamAccessKey';

const Layout = () => {
  const userApiKey = useFeatureFlag('userApiKey');

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Description />
      {userApiKey && <PersonalAccessKey />}
      <TeamAccessKey />
      <AccessAccount />
    </Box>
  );
};

export default Layout;
