import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { LinkExternal } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, LinkTypography, Typography, useDialogState } from '@superb-ai/ui';
import Image from 'next/image';
import Link from 'next/link';

import { getUserManualUrl } from '../../../../../consts/DocsLink';
import { REQUEST_CHANGE_PLAN_SUCCESS } from '../../../../../consts/ModalMessage';
import AnalyticsTracker from '../../../../../analyticsTracker';
import TrialFormDialog from '../../../../HubspotForms/LabelHubspotFormDialog';
import IntroImage from './intro.png';

export default function Intro() {
  const { i18n, t } = useTranslation();
  const { SUITE_MANUAL } = getUserManualUrl(i18n.language);
  const { accountName } = useParams<{ accountName: string }>();
  const state = useDialogState();
  const { openModal, closeModal } = useAlertModal();
  const eventId = 'suite-auto-label-trial-form';

  return (
    <>
      <Box
        display="grid"
        placeItems="center"
        style={{ minHeight: 'calc(100vh - 50px - 64px - 40px)' }}
      >
        <Box
          borderRadius="8px"
          boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
          mx="auto"
          style={{ width: '920px' }}
        >
          <Box display="flex">
            <Box
              width="50%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              borderRight="1px solid"
              borderColor="gray-200"
            >
              <Box
                p={3}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="gray-200"
              >
                <Typography variant="l-strong">
                  Supercharge your labeling process with Auto-Label
                </Typography>
              </Box>
              <Box p={3} width="100%" display="flex" flexDirection="column" gap={4}>
                {Array.from(Array(3)).map((_, i) => (
                  <Box key={i} display="flex" alignItems="center" gap={2}>
                    <Typography variant="h3">{i + 1}</Typography>
                    <Typography variant="m-regular">
                      <Trans t={t} i18nKey={`autoLabel.cal.intro.description.${i}`} />
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box mt="auto" mb={7}>
                <Link href={SUITE_MANUAL} target="_blank" rel="noopener noreferrer">
                  <LinkTypography variant="l-regular" display="flex" alignItems="center" gap={0.5}>
                    <Icon icon={LinkExternal} size={16} color="secondary" />
                    {t('autoLabel.cal.learnMore')}
                  </LinkTypography>
                </Link>
              </Box>
            </Box>
            <Box
              width="50%"
              display="flex"
              flexDirection="column"
              py={6}
              alignItems="center"
              gap={2}
            >
              <Typography variant="m-regular" textAlign="center">
                <Trans t={t} i18nKey="autoLabel.cal.intro.subscribe.description" />
              </Typography>
              <Image alt="Auto Label" width={328} height={272} src={IntroImage} />
              <Button
                variant="strong-fill"
                color="primary"
                size="l"
                onClick={() => {
                  AnalyticsTracker.contactUsClicked({ referrer: eventId, accountId: accountName });
                  state.show();
                }}
              >
                {t('autoLabel.cal.intro.subscribe.button')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <TrialFormDialog
        id={eventId}
        state={state}
        showSuccessModal={() => openModal(REQUEST_CHANGE_PLAN_SUCCESS({ t, closeModal }))}
      />
    </>
  );
}
