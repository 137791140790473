import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableSortLabel from '../../../../elements/TableSortLabel';
import { headerCellStyle } from './style';

interface IHeaderCell {
  id: string;
  name: string;
  color?: string;
}

function AnalyticsTableHeader(props: {
  tableName: string;
  cells: IHeaderCell[];
  orderBy: string;
  isDesc: boolean;
  onRequestSort: (property: string, order: 'desc' | 'asc') => void;
}): JSX.Element {
  const { tableName, orderBy, isDesc, onRequestSort, cells } = props;
  const { t } = useTranslation();

  const getHeaderStyle = (): React.CSSProperties => {
    return {
      borderBottom: 'lightgray',
      borderBottomWidth: '0.8px',
      borderBottomStyle: 'solid',
    };
  };

  // const columnsNotTranslated = [LabelerTa];

  return (
    <TableHead>
      <TableRow key={`table-header-${tableName}`} style={getHeaderStyle()}>
        {cells.map(cell => (
          <TableCell
            key={`${cell.id}-${cell.name}-cell`}
            align="left"
            style={{ backgroundColor: cell.color, lineHeight: 1 }}
          >
            <TableSortLabel
              key={`${cell.id}-${cell.name}-sortLabel`}
              property={cell.id}
              isDesc={isDesc}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            >
              <Typography style={headerCellStyle}>{t(cell.name)}</Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default AnalyticsTableHeader;
