import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LoadingIndicator, Tooltip, Typography } from '@superb-ai/ui';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { HistoryType } from '../../../../types/exportTypes';
import { formatDateTime } from '../../../../utils/date';
import { formatCount } from '../../../../utils/numberFormat';
import { ExportBadge } from './components/ExportBadge';
import { ExportsAnalyticsAppliedFilter } from './ExportsAnalyticsAppliedFilter';
import { ComparisonReport } from './types/types';

interface Props {
  selectedVersion: ComparisonReport;
  exportHistories: HistoryType[];
}

function TextEllipsis(props: ComponentProps<typeof Typography>) {
  return <Typography {...props} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" />;
}

function ComparedLabelsBox({
  count,
  ...props
}: ComponentProps<typeof Box> & { count: number | undefined }) {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" {...props}>
      <Box backgroundColor="gray-200" pl={3} style={{ height: '1px' }} />
      <Box
        backgroundColor="gray-100"
        borderRadius="2px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '44px' }}
        whiteSpace="nowrap"
        px={3}
      >
        <Typography variant="s-strong">{t('analytics.exportsAnalytics.comparedLabels')}</Typography>
        <Typography variant="s-regular">
          {typeof count !== 'undefined' && formatCount(count)}
        </Typography>
      </Box>
      <Box backgroundColor="gray-200" pl={3} style={{ height: '1px' }} />
    </Box>
  );
}

function ExportMetadata({ data, type }: { type: 'base' | 'compare'; data?: HistoryType }) {
  const { mentionUserOptions } = useProjectInfo();

  const userName =
    mentionUserOptions?.find(({ id }) => id === data?.createdBy)?.display ?? data?.createdBy ?? '';

  const renderedName = (
    <Typography ml="auto" variant="m-regular" color="gray-400" whiteSpace="nowrap">
      {userName}
    </Typography>
  );

  return (
    <>
      <Box display="flex" alignItems="center" gap={0.5} my={0.5}>
        <ExportBadge type={type} count={data?.labelCount} size="s" />
        <Typography ml="auto" variant="m-regular" color="gray-400">
          {data && formatDateTime(data.createdAt || '')}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={0.5}>
        <TextEllipsis variant="h3" color="gray-400">
          {data?.name ?? 'N/A'}
        </TextEllipsis>

        {userName !== data?.createdBy ? (
          <Tooltip content={data?.createdBy} placement="bottom">
            {renderedName}
          </Tooltip>
        ) : (
          renderedName
        )}
      </Box>
    </>
  );
}

export function ExportsAnalyticsMetadata({ exportHistories, selectedVersion }: Props) {
  const compareExport = exportHistories.find(
    history => history.id === selectedVersion?.info.metadata.refExportId,
  );
  const baseExport = exportHistories.find(
    history => history.id === selectedVersion?.info.metadata.queryExportId,
  );

  if (!compareExport || !baseExport) {
    return <LoadingIndicator style={{ height: '200px' }} color="primary" size="large" />;
  }

  return (
    <Box boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)">
      <Box
        display="grid"
        alignItems="center"
        p={3}
        gap={2}
        style={{ gridTemplateColumns: '1fr max-content 1fr' }}
      >
        <Box style={{ minWidth: 0 }}>
          <ExportMetadata type="base" data={baseExport} />
        </Box>

        <ComparedLabelsBox count={selectedVersion.info.metadata.processedCount} />

        <Box style={{ minWidth: 0 }}>
          <ExportMetadata type="compare" data={compareExport} />
        </Box>
      </Box>
      {(baseExport?.filter || compareExport?.filter) && (
        <ExportsAnalyticsAppliedFilter
          baseFilter={baseExport?.filter}
          compareFilter={compareExport?.filter}
        />
      )}
    </Box>
  );
}
