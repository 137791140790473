import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

import TableSortLabel from '../../../elements/TableSortLabel';

type Props = {
  onClickGlobalCheckbox: () => void;
  isAllChecked: boolean;
  isDesc: boolean;
  orderBy: string;
  onRequestSort: (property: string, order: 'asc' | 'desc') => void;
};

const DatasetTableViewHead: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { onClickGlobalCheckbox, isAllChecked, isDesc, orderBy, onRequestSort } = props;

  return (
    <>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell width="200px" align="center" padding="checkbox">
            <MUI.Checkbox color="primary" onClick={onClickGlobalCheckbox} checked={isAllChecked} />
          </MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="group"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
            >
              {t('data.table.datasetName')}
            </TableSortLabel>
          </MUI.TableCell>
          <MUI.TableCell align="center">{t('data.table.size')}</MUI.TableCell>
          <MUI.TableCell align="center">
            <TableSortLabel
              property="created_at"
              onRequestSort={onRequestSort}
              isDesc={isDesc}
              orderBy={orderBy}
              defaultOrder="desc"
            >
              {t('projects.table.createdDate')}
            </TableSortLabel>
          </MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableHead>
    </>
  );
};

export default DatasetTableViewHead;
