import { filter, includes, lowerCase, map, mapKeys, omit, snakeCase } from 'lodash';

import { Mapper } from '../../../../utils/LabelInterfaceUtils';
import { JsonObj } from '../userStats/types';
import { PlotDatum, RawPlotDatum } from './types';

/**
 * @param labelerData Labeler QA data from the consensus-labeler-summaries/latest
 * @param labelersInfo Mapping between labeler email to name
 * @returns
 */
export const preprocessQALabelers = (
  labelerData: RawPlotDatum[],
  labelersInfo: Mapper<string>,
): PlotDatum[] => {
  return labelerData.map(item => ({
    ...item,
    name: labelersInfo[item.email],
    isFiltered: true,
    isSelected: false,
  }));
};

/**
 * @param labelerData Labeler QA data from the consensus-labeler-summaries/latest
 * @returns QA labeler data as a list
 *
 * Reset data in QA Labeler chart & table by unselecting all
 */
export const resetQALabelerData = (labelerData: RawPlotDatum[]): PlotDatum[] => {
  return labelerData.map(item => ({ ...item, isFiltered: true, isSelected: false }));
};

export function filterSelectedUsers(selectedEmails: string[], data: PlotDatum[]): JsonObj[] {
  /** If no labeler is selected from the table, download all labeler data */
  if (selectedEmails.length === 0) return data;
  return filter(data, user => includes(selectedEmails, user.email));
}

export const toLowerAndSnakeCase = (column: string): string => snakeCase(lowerCase(column));

export function translateDownloadColumns(
  users: JsonObj[],
  columnKeyToi18nPath: Record<string, string>,
  t: (path: string) => string,
): JsonObj[] {
  return map(users, user =>
    mapKeys(user, function (value, key) {
      return toLowerAndSnakeCase(t(columnKeyToi18nPath[key]));
    }),
  );
}

export const omitColumns = (data: JsonObj[], columns = ['isFiltered', 'isSelected']): JsonObj[] =>
  map(data, user => omit(user, columns));
