import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Plus } from '@superb-ai/icons';
import { Box, Button, Icon, LinkTypography, useDialogState } from '@superb-ai/ui';

import TutorialHeader from '../../../../../components/elements/Tutorial/TutorialHeader';

export default function StartUserTutorialHeader({
  setCreateDatasetModalIsOpen,
}: {
  setCreateDatasetModalIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const dialog = useDialogState();

  const contents = [
    {
      title: t('curate.tutorial.startUserHeader.accessAPIAndSDK.title'),
      description: (
        <Trans t={t} i18nKey="curate.tutorial.startUserHeader.accessAPIAndSDK.description">
          <></>
          <LinkTypography color="blue-400" variant="m-regular">
            Curate SDK
          </LinkTypography>
          <>
            {' '}
            provides a simple way to upload data and perform batch operations on the platform.
            Follow our{' '}
          </>
          <LinkTypography color="blue-400" variant="m-regular">
            step-by-step guide
          </LinkTypography>
        </Trans>
      ),
    },
    {
      title: t('curate.tutorial.startUserHeader.createDataset.title'),
      description: (
        <Trans t={t} i18nKey={'curate.tutorial.startUserHeader.createDataset.description'}>
          <>Easily create an empty dataset with the click of a button or using the </>
          <LinkTypography color="blue-400" variant="m-regular">
            SDK
          </LinkTypography>
        </Trans>
      ),
      action: (
        <Box>
          <Button
            variant="strong-fill"
            color="primary"
            size="m"
            onClick={() => setCreateDatasetModalIsOpen(true)}
          >
            <Icon icon={Plus} />
            {t('curate.button.createDataset')}
          </Button>
        </Box>
      ),
    },
    {
      title: t('curate.tutorial.startUserHeader.uploadData.title'),
      description: (
        <Trans t={t} i18nKey={'curate.tutorial.startUserHeader.uploadData.description'}>
          <></>
          <LinkTypography color="blue-400" variant="m-regular">
            Curate SDK
          </LinkTypography>{' '}
          <> allows for easy data uploads. Simply follow our </>{' '}
          <LinkTypography color="blue-400" variant="m-regular">
            sample code snippet
          </LinkTypography>{' '}
          <>or </>{' '}
          <LinkTypography color="blue-400" variant="m-regular">
            send data directly from Label
          </LinkTypography>{' '}
        </Trans>
      ),
      // action: (
      //   <Box>
      //     <Dialog.Disclosure {...dialog}>
      //       {(disclosureProps: any) => (
      //         <Button variant="strong-fill" color="primary" {...disclosureProps}>
      //           <Icon icon={CloudUp} />
      //           {t('curate.datasets.cloudUpload.button')}
      //         </Button>
      //       )}
      //     </Dialog.Disclosure>
      //     <UploadModal dialog={dialog} hasSliceStep />
      //   </Box>
      // ),
    },
  ];

  return <TutorialHeader contents={contents} />;
}
