import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import ProjectUtils from '../../../../utils/ProjectUtils';

// eslint-disable-next-line
const nonManipulatingAddObjectClassGroup = (
  objectClassGroupMap: any,
  objectClassMap: any,
  id: string,
  parentId: string,
): void => {
  // eslint-disable-next-line no-param-reassign
  objectClassGroupMap[id] = {
    id,
    parent: parentId,
    name: objectClassMap[id].name,
    annotationType: objectClassMap[id].annotationType,
  };

  objectClassGroupMap[parentId].children.push(id);
};

// eslint-disable-next-line
const nonManipulatingDetachChild = (objectClassGroupMap: any, id: string): void => {
  const parentId = objectClassGroupMap[id].parent;

  const idx = objectClassGroupMap[parentId].children.indexOf(id);
  objectClassGroupMap[parentId].children.splice(idx, 1);
};

// eslint-disable-next-line
const nonManipulatingDeleteObjectClassGroup = (objectClassGroupMap: any, id: string): void => {
  nonManipulatingDetachChild(objectClassGroupMap, id);
  // eslint-disable-next-line no-param-reassign
  delete objectClassGroupMap[id];
};

// eslint-disable-next-line
const updateObjectClassGroupMap = (newProjectInfo: any): void => {
  const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);

  const objectClassMap = {};
  for (let i = 0; i < newProjectInfo.objectClasses.length; i++) {
    const curObjectClass = newProjectInfo.objectClasses[i];
    // @ts-ignore: hash에 key 값이 있음을 보장해야함
    objectClassMap[curObjectClass.id] = curObjectClass;
  }

  for (let i = 0; i < newProjectInfo.objectClasses.length; i++) {
    const curObjectClass = newProjectInfo.objectClasses[i];

    const curId = curObjectClass.id;
    if (curObjectClass.id === 'root') continue;

    if (!newProjectInfo.objectClassGroupMap[curId]) {
      nonManipulatingAddObjectClassGroup(nextObjectClassGroupMap, objectClassMap, curId, 'root');
    } else {
      nextObjectClassGroupMap[curId] = {
        id: curId,
        parent: nextObjectClassGroupMap[curId].parent,
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        name: objectClassMap[curId].name,
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        annotationType: objectClassMap[curId].annotationType,
      };
    }
  }

  const prevObjectClassGroupIds = Object.keys(newProjectInfo.objectClassGroupMap);

  for (let i = 0; i < prevObjectClassGroupIds.length; i++) {
    const curId = prevObjectClassGroupIds[i];
    if (curId === 'root') continue;
    if (nextObjectClassGroupMap[curId].children) continue;
    // @ts-ignore: hash에 key 값이 있음을 보장해야함
    if (!objectClassMap[curId]) {
      nonManipulatingDeleteObjectClassGroup(nextObjectClassGroupMap, curId);
    }
  }

  newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
};

// eslint-disable-next-line
const nonManipulatingAddChildren = (
  objectClassGroupMap: any,
  childIds: string[],
  parentId: string,
  dragInfo: any,
): void => {
  for (let i = 0; i < childIds.length; i++) {
    // eslint-disable-next-line no-param-reassign
    objectClassGroupMap[childIds[i]].parent = parentId;
  }

  if (dragInfo) {
    let idx = objectClassGroupMap[parentId].children.indexOf(dragInfo.id);
    if (dragInfo.dir === 'next') idx += 1;
    objectClassGroupMap[parentId].children.splice(idx, 0, ...childIds);
  } else {
    objectClassGroupMap[parentId].children.push(...childIds);
  }
};

// eslint-disable-next-line
const nonManipulatingMoveObjectClassesToGroups = (
  objectClassGroupMap: any,
  childIds: string[],
  parentId: string,
  dragInfo: any,
): void => {
  for (let i = 0; i < childIds.length; i++) {
    nonManipulatingDetachChild(objectClassGroupMap, childIds[i]);
  }
  nonManipulatingAddChildren(objectClassGroupMap, childIds, parentId, dragInfo);
};

// eslint-disable-next-line
const moveObjectClassesToGroup = (newProjectInfo: any, childIds: string[], parentId: string, dragInfo: any): void => {
  const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);

  nonManipulatingMoveObjectClassesToGroups(nextObjectClassGroupMap, childIds, parentId, dragInfo);

  newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
};

// eslint-disable-next-line
const moveGroupToGroup = (newProjectInfo: any, fromGroupId: string, toGroupId: string, dragInfo: any): void => {
  const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);
  nonManipulatingMoveObjectClassesToGroups(
    nextObjectClassGroupMap,
    [fromGroupId],
    'root',
    dragInfo,
  );
  newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
};

// eslint-disable-next-line
const nonManipulatingOpenGroups = (openedObjectClassIds: string[], ids: string[]): void => {
  for (let i = 0; i < ids.length; i++) {
    // @ts-ignore: hash에 key 값이 있음을 보장해야함
    // eslint-disable-next-line no-param-reassign
    openedObjectClassIds[ids[i]] = new Date().getTime();
  }
};

// eslint-disable-next-line
const createObjectClassGroup = (newProjectInfo: any, childIds: string[], parentId: string): void => {
  const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);
  const nextOpenedObjectClassGroupIds = cloneDeep(newProjectInfo.openedObjectClassGroupIds);

  const groups: any = Object.values(nextObjectClassGroupMap).filter((item: any) => item.children);

  const id = uuidv4();

  // eslint-disable-next-line no-param-reassign
  nextObjectClassGroupMap[id] = {
    id,
    name: ProjectUtils.getNewName('Untitled Group', groups),
    parent: parentId,
    children: [],
  };

  nextObjectClassGroupMap[parentId].children.push(id);

  if (childIds) {
    nonManipulatingMoveObjectClassesToGroups(nextObjectClassGroupMap, childIds, id, null);
  }

  nonManipulatingOpenGroups(nextOpenedObjectClassGroupIds, [id]);

  newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
  newProjectInfo.setCreateState('OBJECT_DETECTION_GROUP');
  newProjectInfo.setOpenedObjectClassGroupIds(nextOpenedObjectClassGroupIds);
  newProjectInfo.setSelectedObjectClassGroupId(id);
};

// eslint-disable-next-line
const nonManipulatingSelectSelectedObjectClassGroupIdWhenGroupRemoved = (newProjectInfo: any): void => {
  const children = newProjectInfo.objectClassGroupMap.root.children.filter(
    (item: any) => newProjectInfo.objectClassGroupMap[item].children,
  );
  const curIndex = children.findIndex(
    (item: any) => item === newProjectInfo.selectedObjectClassGroupId,
  );

  if (children.length === 1) {
    newProjectInfo.setSelectedObjectClassGroupId(-1);
  } else if (children.length - 1 === curIndex) {
    newProjectInfo.setSelectedObjectClassGroupId(children[curIndex - 1]);
  } else {
    newProjectInfo.setSelectedObjectClassGroupId(children[curIndex + 1]);
  }
};

// eslint-disable-next-line
const deleteObjectClassGroup = (newProjectInfo: any): void => {
  if (!newProjectInfo.objectClassGroupMap[newProjectInfo.selectedObjectClassGroupId]) return;

  const nextObjectClassGroupMap = cloneDeep(newProjectInfo.objectClassGroupMap);

  nonManipulatingSelectSelectedObjectClassGroupIdWhenGroupRemoved(newProjectInfo);

  nonManipulatingMoveObjectClassesToGroups(
    nextObjectClassGroupMap,
    cloneDeep(nextObjectClassGroupMap[newProjectInfo.selectedObjectClassGroupId].children),
    'root',
    null,
  );

  nonManipulatingDeleteObjectClassGroup(
    nextObjectClassGroupMap,
    newProjectInfo.selectedObjectClassGroupId,
  );

  newProjectInfo.setObjectClassGroupMap(nextObjectClassGroupMap);
};

// eslint-disable-next-line
const openGroups = (newProjectInfo: any, ids: string[]): void => {
  const nextOpenedObjectClassIds = cloneDeep(newProjectInfo.openedObjectClassGroupIds);

  nonManipulatingOpenGroups(nextOpenedObjectClassIds, ids);
  newProjectInfo.setOpenedObjectClassGroupIds(nextOpenedObjectClassIds);
};

// eslint-disable-next-line
const nonManipulatingCloseGroups = (openedObjectClassIds: string[], ids: string[]): void => {
  for (let i = 0; i < ids.length; i++) {
    // @ts-ignore: hash에 key 값이 있음을 보장해야함
    // eslint-disable-next-line no-param-reassign
    delete openedObjectClassIds[ids[i]];
  }
};

// eslint-disable-next-line
const closeGroups = (newProjectInfo: any, ids: string[]): void => {
  const nextOpenedObjectClassIds = cloneDeep(newProjectInfo.openedObjectClassGroupIds);

  nonManipulatingCloseGroups(nextOpenedObjectClassIds, ids);
  newProjectInfo.setOpenedObjectClassGroupIds(nextOpenedObjectClassIds);
};

export default {
  updateObjectClassGroupMap,
  moveObjectClassesToGroup,
  moveGroupToGroup,
  createObjectClassGroup,
  deleteObjectClassGroup,
  openGroups,
  closeGroups,
};
