import { useMemo } from 'react';

import dynamic from 'next/dynamic';

import { useObjectClusterContext } from '../../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../../contexts/ObjectFilterContext';
import { useChartAreaContext } from '../../analytics/contexts/ChartAreaContext';
import { useObjectScatterContext } from '../../analytics/contexts/ObjectScatterContext';
import Scatter2DContainer from './Scatter2DContainer';
import { DEFAULT_POINT_COLOR } from './scatterView/const';
import { hexToDecimal } from './scatterView/utils/color';
import { resizeX, resizeY } from './scatterView/utils/transformer';

const ScatterPlotProvider = dynamic({
  loader: () => import('./scatterView/ScatterPlotProviders').then(x => x.ScatterPlotProviders),
  ssr: false,
});

export default function ObjectScatterContents() {
  const { data, dataCompare, isLoadingProjections: isLoading } = useObjectScatterContext();
  const { chartAreaSize } = useChartAreaContext();
  const { classNameColorMap } = useObjectFilterContext();
  const { clusterColorMap } = useObjectClusterContext();

  const points = useMemo(
    () =>
      chartAreaSize && data
        ? data.map(d => ({
            ...d,
            x: resizeX(d.x, chartAreaSize.width),
            y: resizeY(d.y, chartAreaSize.height),
            color: clusterColorMap?.[d?.clusterId]
              ? hexToDecimal(clusterColorMap?.[d?.clusterId])
              : DEFAULT_POINT_COLOR,
            annotationColor: classNameColorMap?.[d.annotationClass]
              ? hexToDecimal(classNameColorMap?.[d.annotationClass])
              : DEFAULT_POINT_COLOR,
          }))
        : [],
    [chartAreaSize, data, clusterColorMap, classNameColorMap],
  );

  const comparedPoints = useMemo(
    () =>
      chartAreaSize && data
        ? dataCompare.map(d => ({
            ...d,
            x: resizeX(d.x, chartAreaSize.width),
            y: resizeY(d.y, chartAreaSize.height),
            color: DEFAULT_POINT_COLOR,
            annotationColor: DEFAULT_POINT_COLOR,
          }))
        : [],
    [chartAreaSize, dataCompare],
  );

  return (
    <ScatterPlotProvider points={points} comparedPoints={comparedPoints}>
      <Scatter2DContainer />
    </ScatterPlotProvider>
  );
}
