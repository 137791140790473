import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Clear } from '@superb-ai/icons';
import { Box, Button, PasswordInput, Typography } from '@superb-ai/norwegian-forest';
import { IconButton } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS } from '../../../../consts/SnackbarMessage';
import { useAuthService } from '../../../../services/NewAuthService';
import CircularProgressDialog from '../../../elements/CircularProgressDialog';
import helper from './helper';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AvatarUploadDialog: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = props;
  const currentPasswordInputRef = useRef(null);
  const newPasswordInputRef = useRef(null);
  const confirmPasswordInputRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const [isRequesting, setIsRequesting] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordHelperText, setCurrentPasswordHelperText] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] = useState('');
  const { changePasswordOnAccount } = useAuthService();

  useEffect(() => {
    if (isOpen) {
      setCurrentPassword('');
      setCurrentPasswordHelperText('');
      setNewPassword('');
      setNewPasswordHelperText('');
      setConfirmPassword('');
      setConfirmPasswordHelperText('');
      setIsRequesting(false);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const handleChangeCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
    if (currentPasswordHelperText) {
      setCurrentPasswordHelperText('');
    }
  };

  const handleChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    helper.handleChangeCreatePassword(t)({
      value: e.target.value,
      setPassword: setNewPassword,
      passwordErrorMessage: newPasswordHelperText,
      setPasswordErrorMessage: setNewPasswordHelperText,
    });
  };

  const handleChangeReconfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    helper.handleChangeConfirmPassword(t)({
      password: newPassword,
      confirmPassword: e.target.value,
      setConfirmPassword,
      confirmPasswordErrorMessage: confirmPasswordHelperText,
      setConfirmPasswordErrorMessage: setConfirmPasswordHelperText,
    });
  };

  const handleClickChangePassword = async () => {
    if (isRequesting) return;

    if (
      helper.hasErrorMessage({
        errorMessage: currentPasswordHelperText,
        inputRef: currentPasswordInputRef,
      })
    )
      return;

    if (
      helper.hasErrorMessage({
        errorMessage: newPasswordHelperText,
        inputRef: newPasswordInputRef,
      })
    )
      return;

    if (
      helper.hasErrorMessage({
        errorMessage: confirmPasswordHelperText,
        inputRef: confirmPasswordInputRef,
      })
    )
      return;

    if (
      helper.isEmptyField(t)({
        value: currentPassword,
        setErrorMessage: setCurrentPasswordHelperText,
        inputRef: currentPasswordInputRef,
      })
    )
      return;
    if (
      helper.isEmptyField(t)({
        value: newPassword,
        setErrorMessage: setNewPasswordHelperText,
        inputRef: newPasswordInputRef,
      })
    )
      return;

    if (
      helper.isEmptyField(t)({
        value: confirmPassword,
        setErrorMessage: setConfirmPasswordHelperText,
        inputRef: confirmPasswordInputRef,
      })
    )
      return;
    setIsRequesting(true);
    try {
      await changePasswordOnAccount({ oldPassword: currentPassword, newPassword });
      enqueueSnackbar(CHANGE_PASSWORD_SUCCESS({ t }), { variant: 'success' });
      setIsOpen(false);
    } catch (err: any) {
      enqueueSnackbar(CHANGE_PASSWORD_FAIL({ t }), { variant: 'error' });
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <MUI.Dialog open={isOpen} onBackdropClick={handleClickClose}>
      <Box display="flex" alignItems="flex-end" flexDirection="column" gap="20px" p={4}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="headline3" themedColor="primary">
            {t('settings.profile.changePassword')}
          </Typography>
          <IconButton variant="text" icon={Clear} size="s" onClick={handleClickClose} />
        </Box>
        <Box width="356px" display="flex" flexDirection="column" gap="32px">
          <Box width="100%" display="flex" flexDirection="column">
            <PasswordInput
              label={t('settings.profile.currentPassword')}
              ref={currentPasswordInputRef}
              value={currentPassword}
              helperContent={
                <Typography variant="body4" themedColor={['primary', 600]}>
                  {currentPasswordHelperText}
                </Typography>
              }
              onChange={handleChangeCurrentPassword}
              autoComplete="off" // current-password
            />
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            <PasswordInput
              label={t('settings.profile.newPassword')}
              ref={newPasswordInputRef}
              value={newPassword}
              helperContent={
                <Typography variant="body4" themedColor={['primary', 600]}>
                  {newPasswordHelperText}
                </Typography>
              }
              onChange={handleChangeNewPassword}
              autoComplete="new-password"
            />
            <Typography style={{ marginTop: '4px' }} variant="body5">
              {t('settings.profile.passwordRestriction')}
            </Typography>
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            <PasswordInput
              label={t('settings.profile.newPasswordConfirm')}
              ref={confirmPasswordInputRef}
              value={confirmPassword}
              helperContent={
                <Typography variant="body4" themedColor={['primary', 600]}>
                  {confirmPasswordHelperText}
                </Typography>
              }
              onChange={handleChangeReconfirmPassword}
              autoComplete="confirm-password"
            />
          </Box>
        </Box>
        <Box display="flex" gap="8px" mt={6}>
          <Button style={{ flex: 'none' }} onClick={handleClickClose} color="backgroundColor">
            {t('button.cancel')}
          </Button>
          <Button style={{ flex: 'none' }} onClick={handleClickChangePassword}>
            {t('button.ok')}
          </Button>
        </Box>
      </Box>
      <CircularProgressDialog isLoading={isRequesting} />
    </MUI.Dialog>
  );
};

export default AvatarUploadDialog;
