import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@superb-ai/norwegian-forest';

interface Props {
  isLoading: boolean;
  refreshFn: () => void;
}

const SyncButton: React.FC<Props> = props => {
  const { isLoading, refreshFn } = props;
  const { t } = useTranslation();
  return (
    <Tooltip
      anchorEl={
        <span style={{ display: 'inline-flex' }}>
          <IconButton
            color="primary"
            icon="sync"
            disabled={isLoading}
            onClick={refreshFn}
            round
            size="m"
            variant="text"
          />
        </span>
      }
    >
      {t('analytics.icon.sync')}
    </Tooltip>
  );
};

export default SyncButton;
