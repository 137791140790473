import { useQuery } from '@tanstack/react-query';

import { useCurateSyncedLabelProjectService } from '../../../../services/SynecLabelService';

type Props = { datasetId: string };

export const useSyncedLabelProjectsQuery = ({ datasetId }: Props) => {
  const { getSyncedLabelProjects } = useCurateSyncedLabelProjectService();

  return useQuery({
    queryKey: ['getSyncedLabelProjects', datasetId],
    queryFn: () => {
      if (!datasetId) return;
      return getSyncedLabelProjects({
        datasetId,
      });
    },
    enabled: Boolean(datasetId),
    refetchOnWindowFocus: false,
  });
};
