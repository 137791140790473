import React from 'react';
import { useLocation } from 'react-router';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, StatusChip, Tooltip } from '@superb-ai/norwegian-forest';
import cn from 'classnames';

import { useLabelCompareInfo } from '../../../../contexts/LabelCompareContext';
import { useLabelInformationInfo } from '../../../../contexts/LabelInformationContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelUtils from '../../../../utils/LabelUtils';
import ColorChipSelectBox from '../../../elements/ColorChipSelectBox';

const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 0px',

    '&.selectable': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f3f3f3',
      },
    },
  },
  left: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
  },
  iconBackground: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontWeight: 400,
    fontSize: '13px',
    color: '#424242',
    marginLeft: '24px',
  },
  right: {
    height: '100%',
  },
  switch: {},
}));

// eslint-disable-next-line
const CabinetCompareItem = (props: any): React.ReactElement => {
  const { option, selectable } = props;
  const { label, value, title } = option;

  const classes = useStyles();
  const projectInfo = useProjectInfo();
  const labelInformationInfo = useLabelInformationInfo();
  const labelCompareInfo = useLabelCompareInfo();
  const routeInfo = useRouteInfo();
  const { pathname, search } = useLocation();

  const { useAutoLabel } = projectInfo;
  const { labelAnnotationInfos, setLabelAnnotationInfos } = labelInformationInfo;
  const { relatedLabels, setRelatedLabels, activeCompareOptionArr, setActiveCompareOptionArr } =
    labelCompareInfo;
  const isRelatedLabel = value.category === 'relatedLabel';

  const activeOptionsWithoutCurrentItem = activeCompareOptionArr.filter((item: any) =>
    isRelatedLabel ? item.id !== value.id : item.category !== value.category,
  );
  const isSelected = activeOptionsWithoutCurrentItem.length < activeCompareOptionArr.length;

  // const itemName = value.numberId === undefined ? label : `${label} ${value.numberId + 1}`;
  const itemName = value.numberId === undefined ? label : `Label ${value.numberId + 1}`;

  const handleClickColorChip = (color: any): void => {
    if (color === value.color) return;

    setActiveCompareOptionArr(activeCompareOptionArr?.map((option: any) => ({ ...option, color })));

    if (isRelatedLabel) {
      setRelatedLabels({
        ...relatedLabels,
        [title]: { ...relatedLabels[title], color },
      });
      return;
    }

    setLabelAnnotationInfos({
      ...labelAnnotationInfos,
      [value.category]: { ...labelAnnotationInfos[value.category], color },
    });
  };

  const handleClickSwitch = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isSelected) {
      setActiveCompareOptionArr(activeOptionsWithoutCurrentItem);
      return;
    }
    setActiveCompareOptionArr([...activeCompareOptionArr, value]);
  };

  const handleClickItem = () => {
    if (!isRelatedLabel) return;

    const searchParams = new URLSearchParams({
      'label id': `matches,${value.id}`,
    }).toString();
    const pathList = pathname.split('/');
    const labelPathIndex = pathList.lastIndexOf('labels');
    const labelsPathname = `${pathList.slice(0, labelPathIndex + 1).join('/')}`;
    const newSearch = search === '' ? `?${searchParams}` : `${search}&${searchParams}`;
    routeInfo.history.push(`${labelsPathname}${newSearch}#label_id=${value.id}`);
  };

  const hasVisibleConsistencyScore = value.type === 'consensus' && value.status === 'submitted';
  const hasVisibleStatus = value.category !== 'autoLabel';

  return (
    <MUI.Box className={cn(classes.box, { selectable })} onClick={handleClickItem}>
      <MUI.Box className={classes.left}>
        <ColorChipSelectBox
          direction="row"
          chipSize="10px"
          selectedColor={value.color}
          handleClickColorChip={handleClickColorChip}
        />
        <MUI.Typography className={classes.name}>{itemName}</MUI.Typography>
        {hasVisibleStatus && (
          <Box ml={0.5} display="flex" alignItems="center">
            <StatusChip color={value.status} />
          </Box>
        )}
        {hasVisibleConsistencyScore && (
          <Box ml={0.5} display="flex" alignItems="center">
            <Tooltip
              placement="right"
              anchorEl={
                <span style={{ display: 'flex' }}>
                  <StatusChip
                    color={LabelUtils.getColorForConsistencyScore(value.consistencyScore)}
                  >
                    {value.consistencyScore !== null
                      ? `${Math.round(value.consistencyScore)}%`
                      : 'n.a.'}
                  </StatusChip>
                </span>
              }
            >
              Consistency Score
            </Tooltip>
          </Box>
        )}
      </MUI.Box>
      <MUI.Box className={classes.right}>
        <MUI.Switch
          className={classes.switch}
          size="small"
          color="primary"
          checked={isSelected}
          onClick={handleClickSwitch}
          disabled={value.type === 'autoLabel' && !useAutoLabel}
        />
      </MUI.Box>
    </MUI.Box>
  );
};

export default CabinetCompareItem;
