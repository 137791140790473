import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronDown } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';
import classNames from 'classnames';
import { filter } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import IssuesService from '../../../../services/IssuesService';
import Emoji from '../../../elements/Emoji';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    marginBottom: '15px',
    padding: '0px 0px 0px 8px',
  },
  select: {
    width: '100%',
    fontSize: '12px',
    fontWeight: 400,
    color: '#635c5c',
    border: 'solid 1px #dbdbdb',
    borderRadius: '4px',
    overflow: 'hidden',
    transition: '0.2s',
    background: 'none',
    '&:hover': {
      borderColor: '#635c5c',
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiInputBase-inputSelect': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '14px',
    },
    '& .MuiInputBase-input': {
      padding: '5px 0px 8px 0px',
    },
  },
  selectItem: {
    justifyContent: 'center',
  },
  selectIcon: {
    right: '0',
    color: '#635c5c',
    width: '15px',
    paddingRight: '8px',
    paddingLeft: '8px',
    top: 'inherit',
  },
  emoji: {
    fontSize: '15px',
  },
}));

const CabinetIssueStatusSelect = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const labelIssuesInfo = useLabelIssues();
  const { label } = labelDetailViewInfo;
  const {
    issueViewStatus,
    setIssueViewStatus,
    issues,
    setIssues,
    setIssuePanelState,
    setOpenBadgeInfo,
  } = labelIssuesInfo;

  const [status, setStatus] = useState(issueViewStatus);

  useEffect(
    () => {
      setStatus(issueViewStatus);
      setIssuePanelState(null);
      setOpenBadgeInfo({ issue: null, ref: null });
    },
    // eslint-disable-next-line
    [issueViewStatus],
  );

  const handleChangeStatus = async (e: any): Promise<void> => {
    const { value } = e.target;
    setStatus(value);
    setIssueViewStatus(value);

    const nextIssues = await IssuesService.getIssues({
      projectId: routeInfo.urlMatchInfo.projectId,
      labelId: label.id,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    setIssues(nextIssues);
  };

  return (
    <MUI.Box className={classes.box}>
      <MUI.Select
        displayEmpty
        value={status}
        onChange={handleChangeStatus}
        className={classes.select}
        variant="outlined"
        IconComponent={({ className, ...rest }) => {
          return (
            <Icon
              icon={ChevronDown}
              {...rest}
              className={classNames(className, classes.selectIcon)}
            />
          );
        }}
      >
        <MUI.MenuItem className={classes.selectItem} value="OPEN">
          <Emoji symbol="👉" className={classes.emoji} />
          {`Open (${filter(issues, issue => issue.status === 'OPEN').length})`}
        </MUI.MenuItem>
        <MUI.MenuItem className={classes.selectItem} value="RESOLVED">
          <Emoji symbol="👉" className={classes.emoji} />
          {`Resolved (${filter(issues, issue => issue.status === 'RESOLVED').length})`}
        </MUI.MenuItem>
      </MUI.Select>
    </MUI.Box>
  );
};

export default CabinetIssueStatusSelect;
