import React from 'react';

import { Box, Typography } from '@mui/material';
import { Icon, MeteringProgressBar } from '@superb-ai/norwegian-forest';
import classNames from 'classnames';

import { COLOR_CHIP_TAG_DEFAULT } from '../../../../../consts/ColorChips';
import { formatPercent } from '../../tools/d3Helper';
import BaseCard from './BaseCard';
import { useCardStyle } from './cardStyle';

export type PercentCardProps = {
  title: string;
  submitted: number;
  total: number;
};

/**
 * Displays percent submitted labels in Analytics > Project Analytics tab.
 *
 * When calculating roundedRatio:
 *  - if ratio is (0, 1] round to 1
 *  - if ratio is (99, 100] round to 99
 *  - other round down to nearest integer
 */
const PercentCard: React.FC<PercentCardProps> = ({ title, submitted, total }) => {
  const classes = useCardStyle();
  const [percentComplete, roundedRatio] = formatPercent(submitted, total);

  return (
    <BaseCard
      icon={
        <Icon
          name="check"
          className={classes.percentCard}
          size="26px"
          customColor={COLOR_CHIP_TAG_DEFAULT.KIWI}
        />
      }
    >
      <Typography className={classes.cardTitle}>{title}</Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <div className={classNames(classes.root)}>
          <MeteringProgressBar
            criteria={100}
            color={COLOR_CHIP_TAG_DEFAULT.KIWI}
            currentCount={roundedRatio}
            canExceed={false}
            barHeight="6px"
          />
        </div>
        <Typography className={classNames(classes.cardValue, 'percent')}>
          {percentComplete}
        </Typography>
      </Box>
    </BaseCard>
  );
};

export default PercentCard;
