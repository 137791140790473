import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { WarningOutline } from '@superb-ai/icons';
import { Box, Button, Dialog, Icon, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import CommandsService from '../../../../services/CommandsService';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  filterApiParams: any;
};
export default function SyncWithCurateModal({ state, filterApiParams }: Props) {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const commandContext = useLabelCommandContext();

  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  async function handleSyncWithCurate() {
    if (!project.curateDatasetId) return;
    const response = await (enabledLoki
      ? CommandsService.createCommandV2
      : CommandsService.createCommand)({
      type: 'LABELS_CURATE_SYNC',
      projectId: project.id,
      params: {
        filters: filterApiParams,
      },
      actionInfo: {
        projectId: project.id,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    commandContext.registerCommand(response.data.id);

    state.hide();
  }

  return (
    <Dialog state={state} aria-label="Sync with Curate dialog" hideOnClickOutside={false}>
      <Dialog.Header>
        {t('projects.syncWithCurate.title', {
          datasetName: project.curateSyncSettings?.datasetName || '',
        })}
      </Dialog.Header>
      <Dialog.Content>
        <Box py={1} px={2} backgroundColor="primary-100" display="flex" alignItems="center" gap={1}>
          <Icon color="primary" icon={WarningOutline} />
          <Typography variant="m-regular">{t('projects.syncWithCurate.description')}</Typography>
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void state.hide()}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={handleSyncWithCurate}>
          {t('projects.button.syncWithCurate')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
