import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Badge,
  Box,
  Gauge,
  Icon,
  LoadingIndicator,
  Tooltip,
  useAlertModal,
} from '@superb-ai/norwegian-forest';
import { Checkbox, Typography } from '@superb-ai/ui';
import cn from 'classnames';

import { useDetailViewInfo } from '../../../../contexts/DetailViewContext';
import { useLabelDetailViewInfo } from '../../../../contexts/LabelDetailViewContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { fetchUrlAsBlobOnMRAP } from '../../../../shares/fetchUrlAsBlobOnMRAP';
import { LabelData } from '../../../../types/labelTypes';
import { formatDateTime, formatDistance } from '../../../../utils/date';
import LabelUtils from '../../../../utils/LabelUtils';
import { openWorkapp } from '../../../../utils/WorkappUtils';
import LabelBadgeStatus from '../../../elements/LabelBadgeStatus';
import PreLabelStatus from '../../../elements/PreLabelStatus';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';
import VideoAssetSize from '../../../elements/VideoAssetSizeChip';
import { ImageWithFallback } from './ImageWithFallback';
import { iconForReviewStatus, ReviewHistory } from './ReviewHistory';
import Tags from './Tags';
import ThumbnailViewDataKey from './ThumbnailViewDataKey';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    aspectRatio: '90 / 50',
    objectFit: 'cover',
  },
  checkboxContainer: {
    position: 'relative',
    '& .assetLabelsCount': {
      position: 'absolute',
      cursor: 'pointer',
      bottom: -40,
      left: -2,
      padding: 0,
      border: '1px solid #D4D4D4',
      backgroundColor: '#fff',
      borderRadius: 12,
      color: '#929292',
      fontSize: 12,
      minWidth: '24px',
      minHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      '& .icon': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '&.isCollapsed .icon': {
        opacity: 0,
      },
      '&.isCollapsed:hover .icon': {
        opacity: 1,
      },
      '&.isCollapsed .count': {
        opacity: 1,
      },
      '& .count, &:hover .count': {
        opacity: 0,
      },
      '&:hover': {
        borderColor: '#aaa',
        color: '#666',
      },
      '&:active, &:focus': {
        borderColor: '#888',
        color: '#444',
        outline: 'none',
      },
    },
    '& .MuiCheckbox-root': {
      padding: 0,
    },
  },
  hasAdditionalLabels: {
    '&.expandable': {
      borderBottom: '3px double transparent',
      '&:not(.isExpanded)': {
        boxShadow: 'inset 0 -1px 0 0 #fff, inset 0 -2px 0 0 #D4D4D4, 0 1px 0 0 #D4D4D4',
      },
      '&.isExpanded': {
        boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
      },
    },
  },
}));

interface ViewProps {
  label: LabelData;
  checkedLabels: any;
  onClickCheckbox: any;
  onToggleExpand?: () => void;
  expandedState?: 'EXPANDED' | 'LOADING' | 'COLLAPSED';
  advancedQaVisible: boolean;
  reviewVisible: boolean;
  isAllLabelsChecked: boolean;
}

const ThumbnailViewRow: React.FC<ViewProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const detailViewInfo = useDetailViewInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const { openModal, closeModal } = useAlertModal();
  const {
    label,
    checkedLabels,
    onClickCheckbox,
    onToggleExpand,
    expandedState,
    advancedQaVisible,
    reviewVisible,
    isAllLabelsChecked,
  } = props;
  const [isHover, setIsHover] = useState(false);
  const isLabelChecked = checkedLabels.includes(label.id) || isAllLabelsChecked;
  const assigneeName = projectInfo.userObjects[label.workAssignee]
    ? projectInfo.userObjects[label.workAssignee].name
    : label.workAssignee;

  const handleClickRow = () => {
    // video-siesta
    if (
      projectInfo.project.workapp === 'video-siesta' ||
      projectInfo.project.workapp === 'image-siesta' ||
      projectInfo.project.workapp === 'pointclouds-siesta'
    ) {
      detailViewInfo.setLabelId(label.id);
      detailViewInfo.setIsOpen(true);
    } else {
      labelDetailViewInfo.setIsOpen(true);
      const hash = `#label_id=${label.id}`;
      routeInfo.history.push(
        `${routeInfo.history.location.pathname}${routeInfo.history.location.search}${hash}`,
      );
    }
  };

  const handleClickEditLabel = (event: React.MouseEvent) => {
    event.stopPropagation();

    const editLabel = () => {
      openWorkapp({
        workapp: projectInfo.project.workapp,
        urlMatchInfo: routeInfo.urlMatchInfo as { accountName: string; projectId: string },
        labelId: label.id,
        appEnv: 'new-tab',
      });
    };

    if (label.autoLabelStatus !== 'PROCESSING') {
      editLabel();
    } else {
      openModal({
        variant: 'info',
        title: t('annotationApps.AnnotationAppSideEffectEditor.autoLabelInProgress.title'),
        content: t('annotationApps.AnnotationAppSideEffectEditor.autoLabelInProgress.content'),
        mainButton: {
          text: t('annotationApps.AnnotationAppSideEffectEditor.editAnyway'),
          onClick: editLabel,
        },
        subButton: {
          text: t('button.cancel'),
          onClick: closeModal,
        },
      });
    }
  };

  const getPreLabelStatus = () => {
    const { autoLabelStatus } = label;

    if (autoLabelStatus === 'PROCESSING') {
      return <PreLabelStatus type="auto-label" status="doing" />;
    }
    if (autoLabelStatus === 'COMPLETE') {
      return <PreLabelStatus type="auto-label" status="done" />;
    }
    if (autoLabelStatus === 'FAILED') {
      return <PreLabelStatus type="auto-label" status="failed" />;
    }
    return null;
  };

  const getDifficulty = () => {
    const { difficulty } = label;
    const difficultyStates: React.ComponentProps<typeof Gauge>['states'] = [
      [0, 'good'],
      [2, 'medium'],
      [3, 'bad'],
    ];
    return (
      <Tooltip
        anchorEl={
          <span style={{ display: 'flex' }}>
            <Gauge vertical max={3} value={difficulty || 0} states={difficultyStates} />
          </span>
        }
      >
        {t('labels.difficulty')}
      </Tooltip>
    );
  };

  const getConsistencyGauge = () => {
    const { consistencyScore } = label;
    const difficultyStates: React.ComponentProps<typeof Gauge>['states'] = [
      [1, 'bad'],
      [2, 'medium'],
      [4, 'good'],
    ];
    const value = LabelUtils.getRankForConsistencyScore(consistencyScore);
    return <Gauge vertical max={4} value={value} states={difficultyStates} />;
  };

  const getAdditionalLabelType = () => {
    const { additionalLabelType } = label;
    return (
      <>
        {additionalLabelType === 'CONSENSUS' && (
          <Tooltip
            anchorEl={
              <span style={{ display: 'flex', gap: 5 }}>
                <Badge color="purple" variant="strong-fill" size="xs">
                  <Icon name="overlap" color="purple" />
                </Badge>
                {getConsistencyGauge()}
              </span>
            }
          >
            {t('labels.filters.filterBy.consistencyScore')}:{' '}
            {label.consistencyScore !== null ? `${Math.round(label.consistencyScore)}%` : 'n.a.'}
          </Tooltip>
        )}
        {additionalLabelType === 'QUALIFIED' && (
          <Tooltip
            anchorEl={
              <span>
                <Badge color="mint" variant="strong-fill" size="xs">
                  <Icon name="consensusMerge" color="mint" />
                </Badge>
              </span>
            }
          >
            {t('labels.qualifiedLabel')}
          </Tooltip>
        )}
      </>
    );
  };

  const getReviewBadge = () => {
    const { lastReviewAction, reviewRound, reviewer, lastReviewedAt } = label;
    if (!lastReviewAction && !reviewer) return <></>;

    const showReviewRound = !!reviewRound;

    return (
      <span
        style={{ display: 'inline-flex', marginLeft: !showReviewRound ? 18 : 0, paddingLeft: 4 }}
      >
        <Tooltip
          placement="left"
          anchorEl={
            <span style={{ display: 'inline-flex', gap: 2, alignItems: 'center' }}>
              {showReviewRound && (
                <Badge color={LabelUtils.getReviewActionColorName(lastReviewAction)} size="xxxs">
                  {reviewRound}
                </Badge>
              )}
              {iconForReviewStatus(lastReviewAction || 'REQUEST', !!reviewer)}
            </span>
          }
        >
          <Box
            whiteSpace="nowrap"
            display="flex"
            flexDirection="column"
            onClick={(e: any) => e.stopPropagation()}
            width="450px"
          >
            <Box display="flex" alignItems="center" gap="16px">
              <Box display="flex" alignItems="center" gap={0.5} style={{ minWidth: '84px' }}>
                <Typography>{t('labels.reviewTooltip.request')}</Typography>
              </Box>
              {reviewer && lastReviewedAt ? (
                <>
                  <Typography
                    style={{ minWidth: '240px' }}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {projectInfo?.mentionUserOptions?.find(({ id }) => id === reviewer)?.display ??
                      t('labels.review.deletedUser')}{' '}
                    ({reviewer})
                  </Typography>
                  <Box width="100%" display="flex" justifyContent="flex-end">
                    <Typography>{formatDistance(lastReviewedAt)}</Typography>
                  </Box>
                </>
              ) : (
                '-'
              )}
            </Box>
            <ReviewHistory label={label} hasMore={reviewRound > 0} />
          </Box>
        </Tooltip>
      </span>
    );
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggleExpand && onToggleExpand();
  };

  const additionalLabelsCount = label.relatedLabelsCount ?? 0;

  const preLabelStatus = getPreLabelStatus();

  function getExpandStateIcon() {
    if (expandedState === 'COLLAPSED') {
      return <Icon name="chevronUnfold" size="16px" color={['grey', 400]} />;
    }
    if (expandedState === 'LOADING') {
      return <LoadingIndicator variant="spinner-small" size="16px" />;
    }
    return <Icon name="chevronFold" size="16px" color={['grey', 400]} />;
  }
  const expandable = !!expandedState;
  const isExpanded = expandedState === 'EXPANDED';
  const isCollapsed = expandedState === 'COLLAPSED';

  const additionalName =
    typeof label.consensusInfo?.consensusId !== 'undefined'
      ? `(${label.consensusInfo?.consensusId + 1})`
      : '';

  const frameCount = LabelUtils.getFrameCount(label);
  const duration = LabelUtils.getDuration(label);

  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const url = await fetchUrlAsBlobOnMRAP(label.thumbnail);
        setThumbnailUrl(url);
      } catch (error) {}
    })();
  }, [label.thumbnail]);

  return (
    <MUI.TableRow
      hover
      selected={isLabelChecked}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClickRow}
      className={cn({
        [classes.hasAdditionalLabels]: additionalLabelsCount > 0,
        expandable,
        isExpanded,
      })}
    >
      <MUI.TableCell style={{ padding: 0, paddingLeft: 4 }}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            onClick={onClickCheckbox(label.id)}
            value={isLabelChecked}
            disabled={isAllLabelsChecked}
          />
          {expandable && additionalLabelsCount > 0 && (
            <button
              type="button"
              className={cn('assetLabelsCount', { isCollapsed })}
              onClick={toggleExpand}
            >
              <span className="count">{additionalLabelsCount}</span>
              <span className="icon">{getExpandStateIcon()}</span>
            </button>
          )}
        </div>
      </MUI.TableCell>
      <MUI.TableCell align="center">
        <MUI.Box height="50px" display="flex" alignItems="center" justifyContent="center">
          <MUI.Box
            flex={1}
            maxHeight="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ImageWithFallback
              src={thumbnailUrl}
              fallbackSrc="/static/image/not_found_thumbnail.png"
              className={classes.image}
              alt="thumbnail"
              crossOrigin="anonymous"
            />
          </MUI.Box>
        </MUI.Box>
      </MUI.TableCell>
      <MUI.TableCell>
        <ThumbnailViewDataKey
          filename={label.asset.key}
          additionalName={additionalName}
          isHover={isHover}
          onClickEditLabel={handleClickEditLabel}
        />
        {(frameCount > 0 || duration > 0) && (
          <Box mt={0.5}>
            <VideoAssetSize frameCount={frameCount} duration={duration} />
          </Box>
        )}
      </MUI.TableCell>
      <MUI.TableCell>
        <TextEllipsisTooltip text={label.asset.group} justifyContent="center">
          <MUI.Typography>{label.asset.group}</MUI.Typography>
        </TextEllipsisTooltip>
      </MUI.TableCell>
      <MUI.TableCell align="center">
        <Tags tags={label.tags} row={2} column={2} />
      </MUI.TableCell>
      <MUI.TableCell align="center">
        <TextEllipsisTooltip text={assigneeName} justifyContent="center">
          <MUI.Typography>{assigneeName || '-'}</MUI.Typography>
        </TextEllipsisTooltip>
      </MUI.TableCell>

      <MUI.TableCell align="center">
        <MUI.Tooltip title={formatDateTime(label.lastUpdatedAt)}>
          <MUI.Typography>{formatDistance(label.lastUpdatedAt)}</MUI.Typography>
        </MUI.Tooltip>
      </MUI.TableCell>
      <MUI.TableCell align="center">
        <MUI.Box display="flex" justifyContent="center">
          {preLabelStatus}
          {preLabelStatus && getDifficulty()}
        </MUI.Box>
      </MUI.TableCell>
      {!reviewVisible && <MUI.TableCell align="center">{label.openIssueCount}</MUI.TableCell>}
      {reviewVisible && (
        <MUI.TableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center" gap="2px">
            {getReviewBadge()}
          </Box>
        </MUI.TableCell>
      )}
      {advancedQaVisible && (
        <MUI.TableCell align="center">
          <MUI.Box display="flex" justifyContent="center">
            {getAdditionalLabelType()}
          </MUI.Box>
        </MUI.TableCell>
      )}
      <MUI.TableCell>
        <LabelBadgeStatus status={label.status} />
      </MUI.TableCell>
    </MUI.TableRow>
  );
};

export default ThumbnailViewRow;
