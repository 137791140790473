import React, { useEffect, useRef, useState } from 'react';
import { Group, Line } from 'react-konva';

import Color from 'color';
import { flatten, map, max, min } from 'lodash';

import ImageContext from '../../../../../contexts/ImageContext';
import { useLabelInformationInfo } from '../../../../../contexts/LabelInformationContext';
import Properties from './Properties';

const Polygon = props => {
  const {
    shape,
    hoverIndex,
    onClickShape,
    onMouseEnterShape,
    onMouseLeaveShape,
    color,
    isAutoLabelUncertainty,
    isActiveAutoLabelUncertainPanel,
    difficulty,
    isClicked,
  } = props;
  const shapeRef = useRef(null);
  const labelInformationInfo = useLabelInformationInfo();
  const imageInfo = React.useContext(ImageContext.Context);

  const { classes, cabinetTabState, isLabelInformationSwitchActive } = labelInformationInfo;

  const { rate } = imageInfo;

  const isUncertainty = difficulty === 1;

  const [isHovered, setIsHovered] = useState(false);

  const points = map(shape.info, point => [point.x, point.y]);

  const [minX, minY] = [min(map(points, point => point[0])), min(map(points, point => point[1]))];
  const [width, height] = [
    max(map(points, point => point[0])) - minX,
    max(map(points, point => point[1])) - minY,
  ];

  useEffect(() => {
    if (!shapeRef.current) return;

    if (isActiveAutoLabelUncertainPanel && isAutoLabelUncertainty) {
      if (isUncertainty) {
        shapeRef.current.zIndex(6);
      } else {
        shapeRef.current.zIndex(4);
      }
      return;
    }

    if (cabinetTabState === 'issue') {
      shapeRef.current.zIndex(1);
    } else if (hoverIndex === 1) {
      shapeRef.current.zIndex(shapeRef.current.parent.children.length - 1);
    }
    // eslint-disable-next-line
  }, [hoverIndex, cabinetTabState]);

  const getColor = () => {
    if (isAutoLabelUncertainty && isUncertainty) {
      return '#ffcc00';
    }

    if (isClicked || isHovered) {
      return Color(color).negate().hex();
    }
    return color;
  };
  const getStrokeWidth = () => {
    if (isAutoLabelUncertainty && isUncertainty) {
      return 4 / rate;
    }
    return 2 / rate;
  };

  // TODO (tsnoh): 임시코드. project에 등록된 class와 다른 class가 있을경우 방어
  if (!classes[shape.class]) return null;

  return (
    <Group ref={shapeRef}>
      <Group>
        <Line
          lineCap="round"
          lineJoin="round"
          points={flatten(points)}
          strokeWidth={1 / rate}
          fill={getColor()}
          opacity={0.1}
          closed
        />

        <Line
          lineCap="round"
          lineJoin="round"
          points={flatten(points)}
          strokeWidth={getStrokeWidth()}
          stroke={getColor()}
          onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
          onMouseLeave={onMouseLeaveShape({ setIsHovered })}
          onClick={onClickShape}
          closed
        />
      </Group>
      {isLabelInformationSwitchActive && (
        <Properties shape={shape} x={minX} y={minY} width={width} height={height} rate={rate} />
      )}
    </Group>
  );
};

export default Polygon;
