import { PartialTheme } from '@elastic/charts';

export const theme: PartialTheme = {
  chartMargins: { top: 0, left: 0, bottom: 0, right: 0 },
  background: {
    color: '#FBFBFB',
    fallbackColor: '#FBFBFB',
  },
  partition: {
    minFontSize: 4,
    maxFontSize: 84,
    idealFontSizeJump: 1.15,
    outerSizeRatio: 1,
  },
};

export const groupLabelStyle = {
  valueFormatter: (d: number) => `(${d.toLocaleString('en-US')})`,
  fontFamily: 'Inter',
  fontWeight: 600,
  minFontSize: 12,
  maxFontSize: 12,
};

export const leafLabelStyle = {
  valueFormatter: (d: number) => `(${d.toLocaleString('en-US')})`,
  idealFontSizeJump: 1.01,
  maximizeFontSize: true,
  maxFontSize: 24,
  minFontSize: 10,
  fontWeight: 600,
  fontFamily: 'Inter',
  valueFont: {
    fontWeight: 600,
    fontFamily: 'Inter',
  },
  padding: {
    top: 10,
    left: 10,
  },
};
