import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import CustomAutoLabel from '../../../../../components/pages/aiAdvancedFeatures/autoLabel/cal/Layout';
import { getSupportedDataType } from '../../../../../consts/DataTypeConst';
import { COMING_SOON } from '../../../../../consts/FeatureMessage';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { calSupportedWorkapps } from './support';

export const ProjectCustomAutoLabelMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectCustomAutoLabel,
  path: 'cal',
  title: ({ t }) => t('autoLabel.cal.title'),
  component: CustomAutoLabel,
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  isEnabled({ project }) {
    return !!project && calSupportedWorkapps.includes(project.workapp);
  },
  disabledMessage({ t, project }) {
    if (!project) return '';
    return COMING_SOON(
      t,
      t('autoLabel.cal.title'),
      `${getSupportedDataType(t)[ProjectUtils.getProjectDataType(project.workapp)].label} projects`,
    );
  },
});
