import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box as NFBox,
  ButtonGroup,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@superb-ai/norwegian-forest';
import { isNull, isUndefined, map, orderBy } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useContainerSize } from '../../../../hooks/ContainerSizeHook';
import { formatDistanceShort } from '../../../../utils/date';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import LineChartWithTooltip from '../charts/lineChart/lineChart';
import { EMPTY_MESSAGE } from '../config/constants';
import { SVG_PROPERTY } from '../config/svgConfig';
import {
  AnalyticsSummaryType,
  FileFormat,
  LabelingProgressDatum,
  SortOption,
} from '../dataTypes/analyticsDataTypes';
import SyncButton from '../elements/buttons/SyncButton';
import EmptyPlot from '../elements/EmptyPlot';
import SingleItemButton from '../elements/SingleItemButton';
import { getDisplayDate } from '../tools/d3Helper';
import DownloadDropDown from '../tools/DownloadDropDown';
import { sortData } from '../tools/helper';
import { chartMockData } from '../userStats/mock';
import { JsonObj } from '../userStats/types';
import { displayDistanceTime, downloadDataFile } from './helper';

const ProjectProgressChart = (props: {
  chartInfo: JsonObj;
  isLoading: boolean;
  labelCount: number;
  sourceData: LabelingProgressDatum[];
  filter: Record<string, string[] | []>;
  height: number;
  syncTime: Date | string | undefined;
  refresh: (summaryType: AnalyticsSummaryType) => Promise<void>;
}): React.ReactElement => {
  const projectInfo = useProjectInfo();
  const projectName = projectInfo.project.name;
  const { sourceData, chartInfo, isLoading, labelCount, filter, height, syncTime, refresh } = props;
  const chartName = 'projectProgress';

  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerSize(chartContainerRef);
  const { t } = useTranslation();

  const {
    yVariable: [yKey],
    sortX: [sortXKey, sortXName, sortXDirection],
    sortY: [sortYKey, sortYName, sortYDirection],
    chartKind,
    chartDefault,
    buttonSize,
    excelSheetName,
  } = chartInfo;
  const chartType = chartDefault;

  const sortX = {
    key: sortXKey,
    name: sortXName,
    direction: sortXDirection,
    display: false,
  } as SortOption;
  const sortY = {
    key: sortYKey,
    name: sortYName,
    direction: sortYDirection,
    display: true,
  } as SortOption;
  const [isCumulative, setIsCumulative] = useState(true);

  const initDate = !isUndefined(syncTime) ? formatDistanceShort(syncTime) : 'N/a';
  const [syncTimeDistance, setSyncTimeDistance] = useState<string>(initDate);
  const [formattedData, setFormattedData] = useState<JsonObj[]>([]);

  const formatDataCallback = useCallback(() => {
    return sortData(sourceData, sortY, sortX, chartName);
  }, [sourceData]);

  useEffect(() => {
    setFormattedData(formatDataCallback());
    if (!isUndefined(syncTime)) setSyncTimeDistance(formatDistanceShort(syncTime));
    // eslint-disable-next-line
  }, [syncTime, formatDataCallback]);

  useEffect(() => {
    if (isUndefined(syncTime)) return;

    const timeout = setInterval(
      () => {
        setSyncTimeDistance(formatDistanceShort(syncTime));
      },
      60000, // update every min
      10,
    );
    return () => clearInterval(timeout);
  }, []);

  const filterData = (data: JsonObj, isCumulative: boolean): JsonObj => {
    // Hard coding for now
    const xValues = map(data, d => d.date);
    const yValues = map(data, d => d[isCumulative ? yKey : 'submitted']);

    return {
      xValues,
      yValues,
      data: map(data, d => {
        return {
          ...d,
          count: d[isCumulative ? yKey : 'submitted'],
          percentSubmitted: d[isCumulative ? yKey : 'submitted'] / labelCount,
          submitted: d[isCumulative ? yKey : 'submitted'],
          working: d[isCumulative ? 'cumWorking' : 'working'],
          skipped: d[isCumulative ? 'cumSkipped' : 'skipped'],
        };
      }),
    };
  };

  const handleChangeIsCumulative = () => {
    setIsCumulative(!isCumulative);
  };

  const handleDownload = (outputType: FileFormat) => {
    const outputData = orderBy(
      map(formattedData, d => {
        return {
          date: getDisplayDate(d.date),
          submitted_cumulative: d.cumSubmitted,
          skipped_cumulative: d.cumSkipped,
          in_progress_cumulative: d.cumWorking,
        };
      }),
      ['date'],
      ['desc'],
    );

    if (outputType === 'csv') {
      downloadDataFile({
        downloadData: outputData,
        projectName,
        chartName: t(chartInfo.title),
        filter,
      });
      return;
    }
    downloadDataFile({
      downloadData: outputData,
      projectName,
      chartName: t(chartInfo.title),
      excelSheetName,
      filter,
    });
    AnalyticsTracker.chartDownloaded({
      accountId: projectInfo.project.accountId,
      chartType: chartType,
      chartName: t(chartInfo.title),
      fileExtension: outputType,
      feature: 'label-analytics',
    });
  };

  const getEmptyMessage = (isInitDataNull: boolean, labelCount: number) => {
    if (isInitDataNull && labelCount === 0) {
      return t(EMPTY_MESSAGE.UPLOAD_DATA);
    }
    // API returned [] or null (0 labels with status), but there's > 0 total labels.
    return t(EMPTY_MESSAGE.SUBMIT_LABELS);
  };

  /**
   * Note: More chart types (other than 'line') will be added
   */
  const makeChart = (formattedData: JsonObj): React.ReactElement => {
    /** subtract card header height (so that chart fits in the chart-display-area and does not overflow */
    const svgInfo = SVG_PROPERTY('short', width, height - 95);

    return (
      <LineChartWithTooltip
        xValues={formattedData.xValues}
        yValues={formattedData.yValues}
        data={formattedData.data}
        totalLabels={labelCount} // hacky addition
        chartName={chartName}
        isCumulative={isCumulative}
        filter={filter}
        svgInfo={svgInfo}
      />
    );
  };

  /**
   * UI: [Total] [Daily] [HelpIcon]
   */
  const getButtonGroup = (hasZeroLabels: boolean): React.ReactElement => {
    return (
      <ButtonGroup gap={1}>
        <Tooltip
          placement="bottom"
          anchorEl={
            <span style={{ display: 'inline-flex' }}>
              <SingleItemButton
                size={buttonSize.total}
                selectOption={t('analytics.text.total')}
                selected={isCumulative}
                changeIsCumulative={handleChangeIsCumulative}
                isDisabled={hasZeroLabels}
              />
            </span>
          }
        >
          {t('analytics.button.total.hover')}
        </Tooltip>
        <Tooltip
          placement="bottom"
          anchorEl={
            <span style={{ display: 'inline-flex' }}>
              <SingleItemButton
                size={buttonSize.daily}
                selectOption={t('analytics.button.daily.text')}
                selected={!isCumulative}
                changeIsCumulative={handleChangeIsCumulative}
                isDisabled={hasZeroLabels}
              />
            </span>
          }
        >
          {t('analytics.button.daily.hover')}
        </Tooltip>
        <DownloadDropDown
          key={`${chartName}-${isLoading}`}
          ml={0}
          iconButtonEl={
            <IconButton
              size="s"
              icon="download"
              color="primary"
              variant="strong-fill"
              disabled={formattedData.length === 0}
            />
          }
          handleDownload={handleDownload}
          options={['xlsx', 'csv']}
          isToggleDisabled={formattedData.length === 0}
        />
      </ButtonGroup>
    );
  };
  const hasZeroLabels = labelCount === 0 || formattedData.length === 0;

  const refreshData = async () => {
    await refresh('progress-summaries');
  };

  const getRefreshButton = () => {
    return <SyncButton isLoading={isLoading} refreshFn={refreshData} />;
  };

  return (
    <Card pt={2} pb={2} height={height} ref={chartContainerRef}>
      <NFBox
        pl={2}
        pr={2}
        pb={1.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <NFBox display="flex" flexDirection="column" alignItems="left" justifyContent="start">
          <NFBox display="flex" alignItems="center" justifyContent="start">
            <Typography variant="headline5" themedColor="textDefault">
              <strong>{t(chartInfo.title)}</strong>
            </Typography>
            {getRefreshButton()}
          </NFBox>
          <Typography themedColor={['grey', 300]} variant="label">
            {displayDistanceTime(isLoading, syncTimeDistance)}
          </Typography>
        </NFBox>
        <NFBox display="flex" alignItems="center" justifyContent="space-between">
          <NFBox height="28px" mr="5px" display="flex">
            {getButtonGroup(hasZeroLabels)}
          </NFBox>
        </NFBox>
      </NFBox>
      <NFBox
        className={`chart-display-area-${chartName}`}
        display="flex"
        pb={2}
        themedBackgroundColor={['grey', 50]}
        position="relative"
        width={width}
      >
        {isLoading ? (
          <CircularProgressBox
            key={chartName}
            boxProps={{ mt: 5, mb: 5, width: '100%', height: '180px' }}
          />
        ) : (
          <NFBox id={chartName} width="100%">
            {makeChart(
              filterData(hasZeroLabels ? chartMockData[chartName] : formattedData, isCumulative),
            )}
            {hasZeroLabels && (
              <EmptyPlot
                chartKind={chartKind}
                message={getEmptyMessage(isNull(sourceData), labelCount)}
              />
            )}
          </NFBox>
        )}
      </NFBox>
    </Card>
  );
};

export default ProjectProgressChart;
