import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Plus, UserAssign } from '@superb-ai/icons';
import { Box, Button, Icon, LoadingIndicator, Typography } from '@superb-ai/ui';

import { isOwner, useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useUserApiKeysQuery } from '../../../../../queries/userAccessKey';
import { getUrl } from '../../../../../routes/util';
import EmptyCardContent from '../SectionContent';
import PersonalAccessKeyConfirmDialog from './PersonalAccessKeyConfirmDialog';
import PersonalAccessKeyDialog from './PersonalAccessKeyDialog';
import PersonalAccessKeyTable from './PersonalAccessKeyTable';
import PersonalAccessKeyWarning from './PersonalAccessKeyWraning';

export default function PersonalAccessKey() {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const { history, urlMatchInfo } = useRouteInfo();
  const [confirmDialogId, setConfirmDialogId] = useState<string | null>(null);
  const [accessKeyDialogType, setAccessKeyDialogType] = useState<'create' | 'edit' | null>(null);
  const [editDialogId, setEditDialogId] = useState<string | null>(null);

  const { data, isLoading } = useUserApiKeysQuery();

  useEffect(() => {
    if (accessKeyDialogType === null || accessKeyDialogType === 'create') {
      setEditDialogId(null);
    }
  }, [accessKeyDialogType]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h3">{t('settings.advanced.personalAccessKey')}</Typography>
        <Box display="flex" gap={0.5}>
          {isOwner(authInfo) && (
            <Button
              variant="stroke"
              onClick={() =>
                history.push(getUrl(`/${urlMatchInfo.accountName}/settings/access/manage`))
              }
            >
              <Icon icon={UserAssign} /> {t('settings.advanced.manageMembers')}
            </Button>
          )}
          <Button
            variant="strong-fill"
            color="primary"
            onClick={() => setAccessKeyDialogType('create')}
          >
            <Icon icon={Plus} /> {t('settings.advanced.newPersonalAccessKey')}
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
          p={3}
          borderRadius="2px"
          style={{ minHeight: 88 }}
        >
          <LoadingIndicator />
        </Box>
      ) : data?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
          py={1}
          borderRadius="2px"
        >
          <PersonalAccessKeyWarning />
          <Box mt={2}>
            <PersonalAccessKeyTable
              data={data}
              openConfirmDialog={id => setConfirmDialogId(id)}
              openEditDialog={id => {
                setAccessKeyDialogType('edit');
                setEditDialogId(id);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)" p={3} borderRadius="2px">
          <EmptyCardContent type="Personal Access Key" />
        </Box>
      )}
      <PersonalAccessKeyConfirmDialog
        data={data?.find(({ apiKeyId }) => apiKeyId === confirmDialogId)}
        closeConfirmDialog={() => setConfirmDialogId(null)}
        context="User Keys"
      />
      <PersonalAccessKeyDialog
        type={accessKeyDialogType}
        isOpen={accessKeyDialogType !== null}
        handleClose={() => setAccessKeyDialogType(null)}
        data={data?.find(({ apiKeyId }) => apiKeyId === editDialogId)}
        context="User Keys"
      />
    </Box>
  );
}
