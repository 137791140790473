import React from 'react';

import { Box, Typography } from '@superb-ai/norwegian-forest';

interface Props extends React.ComponentProps<typeof Box> {
  title: React.ReactNode;
}

export default function Howto({ title, children }: Props) {
  return (
    <>
      <Box p={3} bb>
        <Typography variant="headline6">{title}</Typography>
      </Box>
      {children}
    </>
  );
}
