import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

import { Box } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';
import * as d3Format from 'd3-format';

import MathUtils from '../../../../utils/MathUtils';
import { getChangeTypeDisplayName } from './utils/header';

const OverallTrendPieChartLegendRow = ({
  index,
  data: { items, getColor, selectedIndex, handleOnHover },
}: ListChildComponentProps) => {
  const total = items.reduce(
    (sum: number, item: { [x: string]: number }) => (sum += item['value']),
    0,
  );
  const { t } = useTranslation();
  const item = items[index];
  const percent = MathUtils.calculatePercent({
    numerator: item?.value,
    denominator: total,
    nearest: 'hundredth',
  });
  return (
    <Box
      key={item?.key}
      display="flex"
      height="23px"
      style={{
        ...(selectedIndex == index && {
          backgroundColor: ' #FFE7E7',
        }),
        cursor: 'default',
        overflowX: 'hidden',
      }}
      onMouseEnter={() => handleOnHover(index, 'in')}
      onMouseLeave={() => handleOnHover(index, 'out')}
    >
      <svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
        <circle cx={4} cy={3.5} r={2} opacity="1" fill={getColor(item?.key)} />
      </svg>
      <Box style={{ flex: 1, marginLeft: '8px' }}>
        <Typography variant="m-regular">{getChangeTypeDisplayName(item?.key, t)}</Typography>
      </Box>
      <Box
        style={{
          fontVariantNumeric: 'tabular-nums',
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="m-regular">{d3Format.format(',')(items[index]['value'])}</Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '20%',
        }}
      >
        <Typography variant="m-regular">{`${percent} %`}</Typography>
      </Box>
    </Box>
  );
};

export default OverallTrendPieChartLegendRow;
