import React from 'react';

import { Box, Button } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import { useMetering } from '../../../../../queries/useMeteringQuery';
import { formatNumber } from '../../../../../utils/numberFormat';
import AddUrls from '../../AddUrls';
import DragAndDropCsv from '../../DragAndDropCsv';
import { UploadStepInfo } from './type';

const UrlUpload = ({
  isButtonEnabled,
  t,
}: {
  isButtonEnabled: boolean;
  t: TFunction;
}): UploadStepInfo => {
  const { leftQuantity: dataCapacity } = useMetering('label:data-volume');
  const { csvUploadMethod, urls } = useUploadInfo();

  return {
    title: t('data.upload.urlUpload.title', {
      urlCount: formatNumber(urls.length),
      maxDataCount: formatNumber(dataCapacity),
    }),
    isButtonEnabled,
    summary: t('data.upload.urlUpload.urlAdded', { urlCount: urls.length }),
    content:
      csvUploadMethod === 'URL' ? (
        <AddUrls />
      ) : (
        <>
          <Box mb={1}>
            <Button
              color="grey"
              onClick={() => {
                window.open('/static/template.csv');
              }}
            >
              {t('data.upload.urlUpload.downloadCSV')}
            </Button>
          </Box>
          <DragAndDropCsv />
        </>
      ),
  };
};

export default UrlUpload;
