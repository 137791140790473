import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box, Button, Dialog, useDialogState } from '@superb-ai/ui';

import AnalyticsTracker from '../../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import { VerticalStepper } from '../../../../../../../components/composition/VerticalStepper';
import { formatDate } from '../../../../../../../utils/date';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { useCurateCreateJobMutation } from '../../../../../queries/commandQueries';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { GENERATE_SYNTHETIC_IMAGES } from '../../../../../types/commandTypes';
import { convertSelectedImageIdsToQueryString } from '../../../../../utils/filterUtils';
import { getView } from '../../../../../utils/routeUtils';
import {
  GenerateSyntheticImageProvider,
  useGenerateSyntheticImageInfo,
} from './GenerateSyntheticImageContext';
import GenerateSyntheticImagesSteps from './Steps';

export default function GenerateSyntheticImagesModal({
  dialog,
}: {
  dialog: ReturnType<typeof useDialogState>;
}) {
  const { t } = useTranslation();
  return (
    <GenerateSyntheticImageProvider>
      <Dialog state={dialog} aria-label={t('curate.datasets.generateSyntheticImages.modalTitle')}>
        <Dialog.Header>
          <Box display="flex" alignItems="center" gap={0.5}>
            {t('curate.datasets.generateSyntheticImages.modalTitle')}
          </Box>
        </Dialog.Header>
        <GenerateSyntheticImagesModalContent dialog={dialog} />
      </Dialog>
    </GenerateSyntheticImageProvider>
  );
}

function GenerateSyntheticImagesModalContent({
  dialog,
}: {
  dialog: ReturnType<typeof useDialogState>;
}) {
  const { mutate: createJob } = useCurateCreateJobMutation();
  const { createSlice } = useCurateDatasetService();
  const history = useHistory();
  const { datasetId, accountName } = useParams<{ datasetId: string; accountName: string }>();
  const { t } = useTranslation();

  const {
    initialize,
    isStepsCompleted,
    generativeModel,
    imagesMultiplier,
    slice,
    applicableImagesCount,
  } = useGenerateSyntheticImageInfo();

  const { selectedData, selectedAllData, setSelectedAllData } = useActionContext();

  const { sliceInfo } = useSliceContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { appliedFilters, clusterLevel } = useImageFilterContext();

  const steps = GenerateSyntheticImagesSteps(t, dialog.visible);

  useEffect(() => {
    if (!dialog.visible) {
      initialize();
    } else {
      if (!selectedAllData && selectedData.length === 0) setSelectedAllData(true);
    }
  }, [dialog.visible]);

  const trackDataSliceCreated = useCallback(
    (newSliceId: string) => {
      AnalyticsTracker.dataSliceCreated({
        accountId: accountName,
        filters: combineFiltersForTracking(appliedFilters, sliceInfo?.id),
        sliceId: newSliceId,
        datasetId,
        viewType: getView(history),
        dataCount: imagesMultiplier * applicableImagesCount,
        dataType: 'image',
        referrer: 'generate-synthetic-images',
        queryString: queryStringWithHiddenFilterAndDeselection,
      });
    },
    [
      accountName,
      appliedFilters,
      datasetId,
      history,
      queryStringWithHiddenFilterAndDeselection,
      sliceInfo?.id,
      applicableImagesCount,
      imagesMultiplier,
    ],
  );

  async function handleClickGenerate() {
    try {
      const createdSlice = await createSlice({
        name: slice,
        description: `${t('curate.datasets.generateSyntheticImages.modalTitle')}: ${formatDate(
          new Date(),
        )}`,
        datasetId,
      });
      trackDataSliceCreated(createdSlice.id);

      await createJob({
        data: {
          model_id: generativeModel.id,
          generation_rule: { multiple: imagesMultiplier },
          dataset_id: datasetId,
          image_filters: {
            ...(sliceInfo?.name && { slice: sliceInfo.name }),
            ...(selectedData.length > 0
              ? {
                  query: convertSelectedImageIdsToQueryString(selectedData.map(data => data.id)),
                }
              : {
                  query: queryStringWithHiddenFilterAndDeselection,
                  ...(clusterLevel && appliedFilters
                    ? {
                        cluster_id_in: appliedFilters?.cluster_id_in || [],
                        cluster_level: clusterLevel,
                      }
                    : undefined),
                }),
          },
          destination_slice_id: createdSlice.id,
        },
        jobType: GENERATE_SYNTHETIC_IMAGES,
      });

      dialog.hide();
    } finally {
      AnalyticsTracker.generateSyntheticImagesRequested({ datasetId, accountId: accountName });
    }
  }

  return (
    <>
      <Dialog.Content
        border="1px solid"
        borderColor="gray-150"
        borderRadius="2px"
        overflow="overlay"
        style={{ height: '482px', width: '600px' }}
      >
        {dialog.visible && <VerticalStepper steps={steps} />}
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={() => void dialog.hide()}>
          {t('button.cancel')}
        </Button>
        <Button
          variant="strong-fill"
          color="primary"
          disabled={!isStepsCompleted}
          onClick={handleClickGenerate}
        >
          {t('button.okay')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
