import React, { ReactNode } from 'react';

import { Card } from '@superb-ai/norwegian-forest';
import { Box } from '@superb-ai/ui';

type Props = {
  children: ReactNode;
  icon: ReactNode;
};

const BaseCard: React.FC<Props> = ({ children, icon }) => {
  return (
    <Card p={2}>
      <Box display="flex" alignItems="center" gap={2}>
        <Box display="flex">{icon}</Box>
        <Box width="100%">{children}</Box>
      </Box>
    </Card>
  );
};

export default BaseCard;
