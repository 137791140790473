import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useApiDefaultParams } from '../hooks/ApiParamsHook';
import ProjectsService from '../services/ProjectsService';
import { ProjectData } from '../types/projectTypes';
import { removeEmptyQueryKeyProjectList } from '../utils/ProjectList';

// I can define this type when clean up code which use projectContext
export type ProjectListQueryParams = any;

export const useProjectListQuery = (params?: ProjectListQueryParams) => {
  const defaultParams = useApiDefaultParams();
  const fetcher = async () =>
    await ProjectsService.getProjects({
      ...defaultParams,
      params,
    });

  return useQuery(
    ['projectList', defaultParams.urlInfo.accountName, removeEmptyQueryKeyProjectList(params)],
    fetcher,
  );
};

export interface GetProjectListResponse {
  count: number;
  results: ProjectData[];
}

export const useUpdateProjectList = () => {
  const queryClient = useQueryClient();
  const defaultParams = useApiDefaultParams();
  const fetcher = async ({ origin, ...params }: ProjectListQueryParams) =>
    await ProjectsService.getProjects({
      ...defaultParams,
      params,
    });

  return useMutation(fetcher, {
    onSuccess: (data, params) => {
      queryClient.setQueryData(
        ['projectList', defaultParams.urlInfo.accountName, removeEmptyQueryKeyProjectList(params)],
        data,
      );
    },
  });
};

export const useSearchedProjectQuery = () => {
  return useQuery<Record<string, any>>(['searchedProjectList'], () => ({}), {
    initialData: () => ({}),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useSearchedProjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (_params: Record<string, any>) =>
      queryClient.getQueryData(['searchedProjectList']) as Record<string, any>,
    {
      onSuccess: (data, params) => {
        queryClient.setQueryData(['searchedProjectList'], {
          ...data,
          ...removeEmptyQueryKeyProjectList(params),
        });
      },
    },
  );
};
