import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Input, Typography } from '@superb-ai/ui';

// import npyjs from 'npyjs';
// import { Tensor } from 'onnxruntime-web';
import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetsBriefDataQuery } from '../../../../../../queries/dataQueries';
import { getClipTextEmbeddings } from '../../../../../../utils/transformersUtils';
import GridLayout from '../GridLayout';
import DetailModal from './DetailModal';
import ImageCell from './ImageCell';

export default function SemanticSearchContents() {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { semanticSearchTotalCount } = useImageScopeContext();
  const { queryStringAndHiddenFilterQueryString } = useQueryContext();

  const { sliceInfo } = useSliceContext();
  const { sortBy, sortOrder } = useActionContext();
  const { appliedFilters } = useImageFilterContext();
  const { selectedData, setSemanticSearchData } = useActionContext();

  const [openDetailIndex, setOpenDetailIndex] = useState<number>();
  const [inputText, setInputText] = useState('');
  const [embeddings, setEmbeddings] = useState(null);
  const pageSize = 100;

  // Function to handle input changes
  const handleInputChange = event => {
    setInputText(event.target.value);
  };

  // Function to handle keydown events
  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      const emb = await getClipTextEmbeddings(inputText);
      setEmbeddings(emb);
    }
  };

  // TODO: useEffect is not ideal here.
  const dataQuery = useDatasetsBriefDataQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name || '',
    pageSize,
    appliedFilters,
    sortBy: [sortBy, 'clusters_search.level_leaf.id', 'clusters_search.level_leaf.score', 'id'],
    sortOrder: [sortOrder, sortOrder, 'desc', 'desc'],
    imageSimilarTo: selectedData ? selectedData[selectedData.length - 1]?.id : undefined,
    clipSimilarTo: embeddings,
    clipEmbedExists: true,
  });

  const loadedItems = useMemo(
    () => dataQuery.data?.pages.flatMap(p => p.results) ?? [],
    [dataQuery.data?.pages],
  );

  useEffect(() => {
    setSemanticSearchData(dataQuery.data?.pages.flatMap(p => p.results) ?? []);
  }, [dataQuery.data, loadedItems, setSemanticSearchData]);

  return (
    <>
      <Box whiteSpace="nowrap">
        <Typography variant="m-strong" color={0 === 0 ? 'gray-300' : 'primary'}>
          {(semanticSearchTotalCount || 0).toLocaleString('en') + ' '}
        </Typography>
        <Typography variant="m-strong">
          / {(dataQuery.data?.pages[0].datasetTotalCount || 0).toLocaleString('en') + ' '}
        </Typography>
        embeddings prepared for semantic search
      </Box>
      <Input
        type="text"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Natural Language Semantic Search"
      />
      <GridLayout
        queryResult={dataQuery}
        DetailModal={
          openDetailIndex !== undefined ? (
            <DetailModal
              pageSize={pageSize}
              totalCount={semanticSearchTotalCount || 0}
              // queries
              loadMoreItem={async () => {
                await dataQuery.fetchNextPage();
              }}
              loadedItems={loadedItems}
              openImageIndex={openDetailIndex}
              setOpenImageIndex={setOpenDetailIndex}
            />
          ) : (
            <></>
          )
        }
        ImageCell={ImageCell}
        setOpenIndex={setOpenDetailIndex}
      />
    </>
  );
}
