import { ComponentProps, ReactElement } from 'react';

import { Box, Typography } from '@superb-ai/ui';

import { LoadingIndicatorDiv } from '../../../analytics/components/LoadingIndicatorDiv';
import { DownloadButton } from './elements/DownloadButton';

export const ChartCard = ({
  isLoading,
  chartComponent,
  chartTitle,
  headerLeftArea,
  headerRightArea,
  chartTopRightArea,
  style,
  topComponent,
  handleDownload,
  chartContainerProps,
}: {
  isLoading?: boolean;
  chartComponent?: ReactElement;
  headerLeftArea?: ReactElement;
  headerRightArea?: ReactElement;
  chartTopRightArea?: ReactElement;
  chartTitle: string;
  style?: ComponentProps<typeof Box>['style'];
  topComponent?: ReactElement;
  handleDownload?: () => void;
  chartContainerProps?: ComponentProps<typeof Box>;
}) => {
  return (
    <Box>
      <Box
        key={chartTitle}
        display="flex"
        style={{
          width: '100%',
          maxHeight: style?.height ?? '500px',
          boxSizing: 'border-box',
          border: '1px solid #DCDCDC',
          borderRadius: '8px',
          ...style,
        }}
        flexDirection="column"
        alignItems="flex-start"
        gap={0.5}
      >
        <Box
          display="flex"
          width="100%"
          p={1.5}
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          borderBottom="1px solid"
          borderColor="gray-200"
        >
          <Typography variant="l-strong" color="gray-400" pr={0.5} ml={0.5}>
            {chartTitle}
          </Typography>
          {headerLeftArea && headerLeftArea}
          <Box display="flex" ml="auto" alignItems="center" flexDirection="row">
            {headerRightArea && headerRightArea}
            {handleDownload && (
              <DownloadButton handleDownload={handleDownload} isDisabled={isLoading === true} />
            )}
          </Box>
        </Box>
        <Box
          px={1}
          py={1}
          width="100%"
          height="100%"
          position="relative"
          gap={1.5}
          flexDirection="column"
          style={{ maxHeight: '2000px' }}
          {...chartContainerProps}
        >
          {chartTopRightArea && chartTopRightArea}
          {topComponent && topComponent}
          {isLoading ? (
            <Box style={{ height: '300px' }}>
              <LoadingIndicatorDiv />
            </Box>
          ) : (
            chartComponent
          )}
        </Box>
      </Box>
    </Box>
  );
};
