import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import { ChevronRight, InfoCircleOutline, Pencil } from '@superb-ai/icons';
import {
  Box,
  Button,
  Chip,
  Icon,
  LinkTypography,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { getUrl } from '../../../../routes/util';
import { textEllipsisStyle } from '../../components/components';
import { MyModelDetail } from '../../services/types';
import { EditTagDialog } from '../EditTagDialog';
import { AutoSliceSet, DatasetSlices } from '../list/DatasetDialog';
import { PreviousModelList } from '../list/PreviousModelDialog';
import { EditMemoDialog } from './EditMemoDialog';

export const Overview = ({ data }: { data: MyModelDetail }) => {
  const { t } = useTranslation();
  const editMemoDialogState = useDialogState();
  const editTagDialogState = useDialogState();

  const isManualSplit = data.trainingSet.validationSetList.length > 0;

  return (
    <Box>
      {data.status === 'failed' && (
        <Row px={1.5} py={1} borderRadius="2px" backgroundColor={'primary-100'} mb={1}>
          <Typography variant="m-regular" color="red-400">
            {data.failReason}
          </Typography>
        </Row>
      )}
      {data.status === 'canceled' && (
        <Row px={1.5} py={1} borderRadius="2px" backgroundColor={'gray-100'} mb={1}>
          <Typography variant="m-regular">{data.failReason}</Typography>
        </Row>
      )}
      <Box
        display="grid"
        mb={1}
        style={{ gridTemplateColumns: 'minmax(490px, 1fr) minmax(490px, 1fr)', columnGap: 8 }}
      >
        <DashboardSection
          title={
            <Row mb={1.5}>
              <Typography variant="l-strong">{t('model.myModels.tag')}</Typography>
              <Box ml="auto">
                <Button
                  variant="soft-fill"
                  size="s"
                  onClick={() => {
                    editTagDialogState.show();
                  }}
                >
                  <Icon icon={Pencil} />
                  {t('model.myModelDetail.editTag')}
                </Button>
              </Box>
            </Row>
          }
        >
          <Box overflow="auto" style={{ height: 70 }}>
            {data.modelTag.length > 0 ? (
              data.modelTag.map(tag => {
                return (
                  <Chip key={tag.name} color={tag.color} mb={0.5} mr={1} style={{ width: 63 }}>
                    <Typography variant="s-regular" style={{ ...textEllipsisStyle }}>
                      {tag.name}
                    </Typography>
                  </Chip>
                );
              })
            ) : (
              <Box display="flex" justifyContent="center">
                <Typography color="gray-300" variant="m-regular">
                  {t('model.myModelDetail.noTags')}
                </Typography>
              </Box>
            )}
          </Box>
        </DashboardSection>
        <DashboardSection
          title={
            <Row mb={1.5}>
              <Typography variant="l-strong">{t('model.myModelDetail.memo')}</Typography>
              <Box ml="auto">
                <Button variant="soft-fill" size="s" onClick={() => editMemoDialogState.show()}>
                  <Icon icon={Pencil} />
                  {t('model.myModelDetail.editMemo')}
                </Button>
              </Box>
            </Row>
          }
        >
          <Box
            backgroundColor={'gray-100'}
            borderRadius="2px"
            px={1}
            py={0.5}
            overflow="auto"
            style={{ wordBreak: 'break-all', height: 70 }}
          >
            {data.memo ? (
              <Typography variant="m-regular">{data.memo}</Typography>
            ) : (
              <Typography variant="m-regular" color="gray-300">
                {t('model.myModelDetail.noMemo')}
              </Typography>
            )}
          </Box>
        </DashboardSection>
      </Box>
      <Box
        display="grid"
        style={{ gridTemplateColumns: 'minmax(490px, 1fr) minmax(490px, 1fr)', columnGap: 8 }}
      >
        <DashboardSection
          title={
            <DatasetSectionTitle
              data={data}
              isManualSplit={isManualSplit}
              datasetName={data.trainingSet.referenceName}
            />
          }
        >
          {isManualSplit ? (
            <Box>
              <Box>
                <Row mb={1}>
                  <Typography variant="m-strong">{t('model.train.trainSetSlice')}</Typography>
                  <Typography variant="m-regular">
                    &nbsp;({data.trainingSet.trainingSetList.length})
                  </Typography>
                </Row>
                <DatasetSlices
                  datasetId={data.trainingSet.referenceId}
                  trainingSetSlices={data.trainingSet.trainingSetList}
                  height={184}
                />
              </Box>
              <Box>
                <Row mb={1} mt={2}>
                  <Typography variant="m-strong">{t('model.train.validationSetSlice')}</Typography>
                  <Typography variant="m-regular">
                    &nbsp;({data.trainingSet.validationSetList.length})
                  </Typography>
                </Row>
                <DatasetSlices
                  datasetId={data.trainingSet.referenceId}
                  trainingSetSlices={data.trainingSet.validationSetList}
                  height={184}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Row mb={1}>
                <Typography variant="m-strong">{t('model.datasetDialog.datasetSlice')}</Typography>
                <Typography variant="m-regular">
                  &nbsp;({data.trainingSet.trainingSetList.length})
                </Typography>
              </Row>
              <DatasetSlices
                datasetId={data.trainingSet.referenceId}
                trainingSetSlices={data.trainingSet.trainingSetList}
                height={
                  data.trainingSet.autoTrainingSet && data.trainingSet.autoValidationSet ? 230 : 410
                }
              />
              {data.trainingSet.autoTrainingSet && data.trainingSet.autoValidationSet && (
                <Box backgroundColor={'secondary-100'} borderRadius="2px" p={1.5} mt={1}>
                  <AutoSliceSet
                    modelData={data}
                    autoTrainSetSlice={data.trainingSet.autoTrainingSet}
                    autoValidationSetSlice={data.trainingSet.autoValidationSet}
                  />
                </Box>
              )}
            </>
          )}
        </DashboardSection>
        <DashboardSection
          title={
            <Box mb={2}>
              <Typography variant="l-strong">{t('model.myModelDetail.previousModel')}</Typography>
            </Box>
          }
        >
          <PreviousModelList id={data.id} height={436} />
        </DashboardSection>
      </Box>
      {editTagDialogState.visible && <EditTagDialog id={data.id} state={editTagDialogState} />}
      {editMemoDialogState.visible && <EditMemoDialog id={data.id} state={editMemoDialogState} />}
    </Box>
  );
};

const DashboardSection = ({ children, title }: { children: ReactNode; title: ReactNode }) => {
  return (
    <Box border="1px solid" borderColor="gray-150" borderRadius="2px" px={2.5} pb={2.5} pt={2}>
      {title}
      {children}
    </Box>
  );
};

const DatasetSectionTitle = ({
  data,
  isManualSplit,
  datasetName,
}: {
  data: MyModelDetail;
  isManualSplit: boolean;
  datasetName: string | null;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Row
      mb={2}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Typography variant="l-strong" style={{ marginRight: 20 }}>
        {t('model.train.dataset')}
      </Typography>
      {getUrl([params.accountName, 'curate', 'dataset', data.trainingSet.referenceId]).includes(
        window.location.host,
      ) ? (
        <LinkTypography
          color={'black-400'}
          underline={isHovered}
          style={{ ...textEllipsisStyle }}
          onClick={() => {
            history.push(
              getUrl([params.accountName, 'curate', 'dataset', data.trainingSet.referenceId]),
            );
          }}
        >
          {datasetName ?? ''}
        </LinkTypography>
      ) : (
        <LinkTypography
          color={'black-400'}
          underline={isHovered}
          style={{ ...textEllipsisStyle }}
          onClick={() => {
            history.push(
              getUrl([params.accountName, 'curate', 'dataset', data.trainingSet.referenceId]),
            );
          }}
        >
          <Typography variant="m-regular" color="gray-400">
            {datasetName ?? ''}
          </Typography>
        </LinkTypography>
      )}
      <Icon icon={ChevronRight} />
      {data.baselineModel.purpose === 'recognition' && (
        <Box ml="auto">
          {isManualSplit ? (
            <Chip color="primary" style={{ width: 'max-content' }}>
              {t('model.train.manualSplit')}
            </Chip>
          ) : (
            <Tooltip content={t('model.datasetDialog.randomSplitInfo')} placement="top-end">
              <Chip color="secondary" style={{ width: 'max-content' }}>
                {t('model.train.randomSplit')}
                <Icon icon={InfoCircleOutline} color="secondary" />
              </Chip>
            </Tooltip>
          )}
        </Box>
      )}
    </Row>
  );
};
