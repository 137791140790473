import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  buttonMargin: {
    margin: theme.spacing(0.5),
  },
  currentPageText: {
    minWidth: '30px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  pageInput: {
    fontSize: '11px',
    fontWeight: 500,
    margin: theme.spacing(0.5),
    '& .MuiInputBase-input': {
      textAlign: 'center',
      width: '30px',
      padding: 0,
    },
  },
}));

type ActionsComponent = NonNullable<MUI.TablePaginationProps['ActionsComponent']>;

const TablePaginationActions: ActionsComponent = props => {
  const theme = useTheme();
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;

  const [isEditable, setIsEditable] = useState(false);

  const maxPage = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, maxPage - 1));
  };

  const handleConfirmPageInput = (
    event:
      | React.MouseEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
  ) => {
    const inputElement = event.target as HTMLInputElement;
    const inputPage = parseInt(inputElement.value);

    if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(inputPage) ||
      inputPage < 1 ||
      inputPage > maxPage ||
      inputPage === page + 1
    ) {
      setIsEditable(false);
      return;
    }
    setIsEditable(false);
    onPageChange(event as any, inputPage - 1);
  };

  const handleKeyDownPageInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirmPageInput(event);
    }
  };

  return (
    <>
      <MUI.Typography className={classes.buttonMargin}>1</MUI.Typography>
      <MUI.IconButton
        className={classes.buttonMargin}
        size="small"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <Icon icon={LastPage} /> : <Icon icon={FirstPage} />}
      </MUI.IconButton>
      <MUI.IconButton
        className={classes.buttonMargin}
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <Icon icon={ChevronRight} /> : <Icon icon={ChevronLeft} />}
      </MUI.IconButton>
      {isEditable ? (
        <MUI.InputBase
          className={classes.pageInput}
          autoFocus
          defaultValue={page + 1}
          onBlur={handleConfirmPageInput as any}
          onKeyPress={handleKeyDownPageInput}
        />
      ) : (
        <MUI.Typography
          className={classNames(classes.currentPageText, classes.buttonMargin)}
          variant="h6"
          color="textPrimary"
          onClick={() => setIsEditable(true)}
        >
          {page + 1}
        </MUI.Typography>
      )}
      <MUI.IconButton
        className={classes.buttonMargin}
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= maxPage - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <Icon icon={ChevronLeft} /> : <Icon icon={ChevronRight} />}
      </MUI.IconButton>
      <MUI.IconButton
        className={classes.buttonMargin}
        size="small"
        onClick={handleLastPageButtonClick}
        disabled={page >= maxPage - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <Icon icon={FirstPage} /> : <Icon icon={LastPage} />}
      </MUI.IconButton>
      <MUI.Typography className={classes.buttonMargin}>{maxPage}</MUI.Typography>
    </>
  );
};

export default TablePaginationActions;
