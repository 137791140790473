import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Input } from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import ProjectUtils from '../../../../utils/ProjectUtils';
import ClassPropertyItemMultipleItem from './ClassPropertyItemMultipleItem';

type OptionType = {
  id: string;
  name: string;
  checked: boolean;
};

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  addOptionText: {
    width: '60px',
    marginTop: '10px',
    textDecoration: 'underline',
    fontSize: '11px',
    color: '#9b9b9b',
    cursor: 'pointer',
    '&:hover': {
      color: '#000',
    },
  },
  radio: {
    padding: '2px 0px 2px 0px ',
    marginRight: '8px',
  },
  inputBox: {
    marginTop: '10px',
  },
}));

// eslint-disable-next-line
const ClassPropertyItemMultipleList = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const { classProperty, index } = props;
  const { type } = classProperty;
  const options = classProperty.options[type];
  const [objectName, setObjectName] = useState('');

  const handleClickAddOption = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { key } = event;
    if (key !== 'Enter') return;
    addOption();
  };

  const addOption = () => {
    if (objectName === '') return;
    const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);

    const options1 =
      nextObjectClasses[newProjectInfo.selectedObjectClassIndex].properties[index].options
        .MULTIPLE_CHOICE;
    const options2 =
      nextObjectClasses[newProjectInfo.selectedObjectClassIndex].properties[index].options
        .MULTIPLE_SELECTION;

    const nextOption = {
      id: uuidv4(),
      name: ProjectUtils.getNewName(objectName, options1),
      checked: false,
    };

    options1.push(nextOption);
    options2.push(nextOption);

    newProjectInfo.setObjectClasses(nextObjectClasses);
    newProjectInfo.setCreateState('MULTIPLE_ITEM');

    setObjectName('');
  };

  return (
    <Box className={classes.box}>
      {options.map((option: OptionType, idx: number) => (
        <ClassPropertyItemMultipleItem
          key={option.id}
          options={options}
          option={option}
          type={type}
          propertyIndex={index}
          index={idx}
        />
      ))}
      <Box display="flex" className={classes.inputBox} alignItems="center" flexDirection="row">
        <Box flex="0 0 auto">
          {type === 'MULTIPLE_CHOICE' ? (
            <MUI.Radio className={classes.radio} disabled />
          ) : (
            <MUI.Checkbox className={classes.radio} disabled />
          )}
        </Box>
        <Box flex={1}>
          <Input
            size="xs"
            value={objectName}
            onKeyPress={handleClickAddOption}
            onChange={event => setObjectName(event.target.value)}
            onKeyDown={event => event.stopPropagation()}
            onBlur={addOption}
            placeholder={t('projects.createProject.addOption')}
            variant="underline"
          />
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  );
};

export default ClassPropertyItemMultipleList;
