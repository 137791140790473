// TODO: i18n
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  FileTree,
  FileTreeModel,
  FileType,
  getTreeModel,
  Icon,
  IconButton,
  IItem,
  Typography,
} from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import FileService from '../../../../../services/FileService';
import { TargetTypeCivet } from '../../../../pages/account/integrations/types';
import { CloudUploadContextProps } from '../../types';
import { UploadStepInfo } from './type';

const SelectCloudPrefix = ({
  t,
  cloudInfos,
}: {
  t: TFunction;
  cloudInfos: Pick<
    CloudUploadContextProps,
    | 'cloudBucketName'
    | 'integratedCloudPrefix'
    | 'cloudIntegrationId'
    | 'cloudStorageType'
    | 'cloudPrefix'
    | 'setCloudPrefix'
  >;
}): UploadStepInfo => {
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { enqueueSnackbar } = useSnackbar();
  const {
    cloudBucketName,
    integratedCloudPrefix,
    cloudIntegrationId,
    cloudStorageType,
    cloudPrefix,
    setCloudPrefix,
  } = cloudInfos;
  const [treeModel, setTreeModel] = useState<FileTreeModel | null>(null);
  const [reloaded, setReloaded] = useState(1);

  const getObjectsAtPath = useCallback(
    async (path: string): Promise<IItem[]> => {
      // Add bucket as a dummy root element because FileTree otherwise doesn't display the element

      if (path === '/') {
        return [{ name: `/${cloudBucketName}`, type: FileType.Directory }];
      }

      // 메인 함수 내에서 getObjectForIntegratedCloudPrefix를 호출하는 부분
      if (integratedCloudPrefix) {
        const objectForPrefix = getObjectForIntegratedCloudPrefix(
          cloudBucketName,
          integratedCloudPrefix,
          path,
        );

        if (objectForPrefix) {
          return [objectForPrefix];
        }
      }

      const prefix =
        path === `/${cloudBucketName}`
          ? ''
          : `${path.replace(new RegExp(`^/${cloudBucketName}/?`), '')}/`;

      const objects = await FileService.getBucketObjects({
        integrationId: cloudIntegrationId || '',
        prefix,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
        target: cloudStorageType.toLowerCase() as TargetTypeCivet,
      });

      if (!objects.length) {
        return [
          {
            name: t('data.upload.noSubDirectories'),
            type: FileType.File,
            metadata: { isInfo: true },
          },
        ];
      }
      // Empty names cannot be rendered in the tree

      const filteredObjects = objects.filter(item => item.folderName);
      return filteredObjects.map((item: any) => ({
        name: item.folderName,
        type: FileType.Directory,
      }));
    },
    [
      authInfo.isGuest,
      cloudBucketName,
      cloudIntegrationId,
      cloudStorageType,
      integratedCloudPrefix,
      routeInfo.urlMatchInfo,
      t,
    ],
  );

  useEffect(() => {
    if (!cloudIntegrationId) return () => {};
    const cacheObjectsByPrefix: Record<string, Promise<IItem[]>> = {};
    const getItems = (path: string) => {
      if (!cacheObjectsByPrefix[path]) {
        cacheObjectsByPrefix[path] = getObjectsAtPath(path).catch(_ => {
          enqueueSnackbar(t('data.upload.bucketLoadingError'), {
            variant: 'error',
          });
          return [];
        });
      }
      return cacheObjectsByPrefix[path];
    };
    const treeModel = getTreeModel(getItems);

    setTreeModel(treeModel);
    return () => setTreeModel(null);
  }, [cloudIntegrationId, enqueueSnackbar, getObjectsAtPath, t]);

  const handleChange = (path: string | null) => {
    if (!path) {
      setCloudPrefix(null);
      return;
    }

    if (path === `/${cloudBucketName}`) {
      setCloudPrefix('');
      return;
    }
    setCloudPrefix(path.replace(new RegExp(`^/${cloudBucketName}/?(.*?)$`), '$1/'));
  };

  const reload = () => {
    setReloaded(prev => prev + 1);
  };

  return {
    title: t('data.upload.selectFolder.title'),
    isButtonEnabled: cloudPrefix != null,
    summary: cloudPrefix || '(root)',
    content: (
      <>
        <Box display="flex" justifyContent="space-between" mb={1} pr={1}>
          <Typography variant="body3" themedColor={['grey', 500]}>
            {t('data.upload.selectFolder.description')}
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton
              style={{ marginRight: '4px' }}
              color="textSecondary"
              icon="refresh"
              size="s"
              round
              variant="text"
              onClick={reload}
            />
            <Box display="flex" alignItems="center" mr={0.5}>
              <Icon name="folderOutline" size="12px" color={['grey', 500]} />
            </Box>
            <Typography variant="body3" themedColor={['grey', 500]}>{`${
              cloudPrefix || '(root)'
            }`}</Typography>
          </Box>
        </Box>
        <Box width={600} height={270} border="1px dashed #ddd">
          {!treeModel ? (
            <Box width="100%" height="100%" themedBackgroundColor={['grey', 80]} />
          ) : (
            <FileTree
              width={598}
              height={270}
              model={treeModel}
              onSelect={handleChange}
              expandTopLevel
              selectableTypes={[FileType.Directory]}
            />
          )}
        </Box>
      </>
    ),
  };
};

export default SelectCloudPrefix;

const getObjectForIntegratedCloudPrefix = (
  cloudBucketName: string,
  integratedCloudPrefix: string,
  path: string,
) => {
  // 불필요한 슬래시를 제거하고, integratedCloudPrefix를 배열로 변환합니다.
  const splitIntegratedCloudPrefix = integratedCloudPrefix.split('/').filter(Boolean);
  // cloudBucketName과 integratedCloudPrefix를 조합하여 전체 경로를 생성합니다.
  const prefixPath = `/${cloudBucketName}/${integratedCloudPrefix.replace(/\/+$/, '')}`;

  // 현재 경로가 prefixPath와 다르고, integratedCloudPrefix 내의 경로일 경우 처리합니다.
  if (path !== prefixPath) {
    // 현재 경로의 깊이를 계산합니다.
    const depth = path.split('/').filter(Boolean).length - 1;
    // 깊이가 integratedCloudPrefix 내에 있다면, 해당 위치의 디렉토리 객체를 반환합니다.
    if (depth < splitIntegratedCloudPrefix.length) {
      return {
        name: splitIntegratedCloudPrefix[depth],
        type: FileType.Directory,
      };
    }
  }
  // 조건에 맞지 않는 경우, null을 반환합니다.
  return null;
};
