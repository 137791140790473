import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => Array.from(new URLSearchParams(search)), [search]);
}

export function useSearchParamsObj() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
