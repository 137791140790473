import React, { useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  box: (props: {
    bgColor: string;
    padding: string;
    width: string | number | undefined;
    shadow: boolean;
  }) => ({
    background: props.bgColor,
    padding: props.padding,
    borderRadius: '4px',
    margin: '2px',
    fontSize: '12px',
    color: '#fff',
    width: props.width ? props.width : '200px',
    shadow: props.shadow ? theme.shadows[1] : 'none',

    '& .MuiLink-root': {
      color: '#fff',
    },
  }),
  popper: {
    zIndex: 50,
  },
}));

interface Props {
  anchor: JSX.Element;
  tooltipWidth?: number | string;
  disablePortal?: boolean;
  placement?: React.ComponentProps<typeof MUI.Popper>['placement'];
  bgColor?: string;
  shadow?: boolean;
  padding?: string;
}

const PopperTooltip: React.FC<Props> = props => {
  const {
    children,
    anchor,
    tooltipWidth,
    disablePortal = true,
    placement = 'right',
    bgColor = '#333',
    shadow = false,
    padding = '8px 15px',
  } = props;
  const classes = useStyles({ width: tooltipWidth, bgColor, shadow, padding });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorRef = useRef<HTMLElement>(null);

  const handleMouseEnter = () => {
    if (anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <MUI.Box display="flex" alignItems="center" onMouseLeave={handleMouseLeave} lineHeight="1.5">
      <MUI.Box
        display="inline-flex"
        alignItems="center"
        onMouseEnter={handleMouseEnter}
        {...({ ref: anchorRef } as any)}
      >
        {anchor}
      </MUI.Box>
      {/* @ts-ignore: saa-680 */}
      <MUI.Popper
        className={classes.popper}
        placement={placement}
        anchorEl={anchorEl}
        open={!!anchorEl}
        disablePortal={disablePortal}
      >
        <MUI.Box className={classes.box} boxShadow={1}>
          {children}
        </MUI.Box>
      </MUI.Popper>
    </MUI.Box>
  );
};

export default PopperTooltip;
