export const COLORS = {
  RED: '#FF625A',
  BLACK: '#333333',
  GRAY: '#A6A6A6',
  GRAY_1: 'rgba(169, 169, 169, 0.26)',
  GRAY_2: '#a6a6a6',
  DARK_GRAY: '#909090',
  SALMON: '#fe9573',
  WHITE: '#ffffff',
};

export const DIVERGENT_COLOR_PALETTE = [
  ['#FE9573', '#FF625A'],
  ['#AFFFFF', '#5A7BFF'],
  ['#DEF00F', '#82DB24'],
  ['#FFC8D9', '#FF4881'],
  ['#AF48FF', '#FFE7CF'],
  ['#FFCC00', '#D5EBFF'],
  ['#4AE2B9', '#A6A6A6'],
];

// export default COLORS;
