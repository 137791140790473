import React from 'react';

import { TFunction } from 'next-i18next';

import { useUploadInfo } from '../../../../../contexts/UploadContext';
import DatasetSelect from '../../DatasetSelect';
import { UploadStepInfo } from './type';

const SelectDataset = (t: TFunction): UploadStepInfo => {
  const { dataset } = useUploadInfo();

  return {
    title: t('labels.table.dataset'),
    summary: dataset,
    isButtonEnabled: !!dataset,
    content: <DatasetSelect />,
  };
};

export default SelectDataset;
