import { Box, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import { ScopeMode } from '../../../../types/viewTypes';
import noDataImage from './noData.png';

export const NoDataView = ({ scope }: { scope: ScopeMode }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      gap={1}
      style={{ transform: 'translateY(-40px)' }}
    >
      <Image width="86" height="86" src={noDataImage} alt="Illustration showing empty data." />
      <Typography variant="h2" color="gray-400">
        Nothing to show here yet.
      </Typography>
      <Typography variant="m-regular" color="gray-300">
        Please update data to see the 2D projections of {scope} embeddings
      </Typography>
    </Box>
  );
};

export default NoDataView;
