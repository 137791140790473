import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styled from '@emotion/styled';
import { Clear } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem, Input } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Select, Typography } from '@superb-ai/ui';

import DatePicker from '../../../../../../components/elements/datePicker/DatePicker';
import { parseDate } from '../../../../../../utils/date';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useCurateDatasetService } from '../../../../services/DatasetService';
import { IAdvancedQuery, IQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';

export default function QueryValueMenu({
  hoverButtonList,
  boxNum,
  index,
  setHoverButtonList,
  list,
  deleteQuery,
  selectedQueryList,
  setSelectedQueryList,
  updateQueryContent,
  handleQueryValue,
  isSelected = false,
}: {
  hoverButtonList: string[];
  boxNum: number;
  index: number;
  setHoverButtonList: React.Dispatch<React.SetStateAction<string[]>>;
  list: IAdvancedQuery | IQuery;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
  handleQueryValue: (value: string | string[], boxNum: number, index: number) => void;
  isSelected?: boolean;
}) {
  const { datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const { getDatasetSchemasValues } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const [values, setValues] = useState<string[] | number[]>([]);
  const [valuesSampleLength, setValuesSampleLength] = useState<number>(0);
  const [hasMoreValue, setHasMoreValue] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { t } = useTranslation();
  const handleQueryText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const queryValue = e.target.value ?? '';

      const hasQuery = selectedQueryList.filter(
        list => list.boxNum === boxNum.toString() + (index + 1),
      );
      const newQueryList = selectedQueryList.filter(
        list => list.boxNum !== boxNum.toString() + (index + 1),
      );
      updateQueryContent(boxNum, index, {
        ...hasQuery[0],
        queryValue: queryValue,
      });
      setSelectedQueryList(() => [
        ...newQueryList,
        {
          ...hasQuery[0],
          queryValue: queryValue,
        },
      ]);
    },
    [boxNum, index, selectedQueryList, setSelectedQueryList, updateQueryContent],
  );

  const handleSelect = (newSelectedItems: string[]) => {
    setSelectedValues(newSelectedItems);
    handleQueryValue(newSelectedItems, boxNum, index);
  };

  const selectedQuery = useMemo(
    () => selectedQueryList.filter(list => list.boxNum === boxNum.toString() + (index + 1))[0],
    [selectedQueryList, boxNum, index],
  );
  useEffect(() => {
    (async () => {
      if (
        selectedQueryList.filter(list => list.boxNum === boxNum.toString() + (index + 1)).length >
          0 &&
        selectedQuery.hasOwnProperty('queryKey') &&
        selectedQuery.hasOwnProperty('queryOperator') &&
        selectedQuery.hasOwnProperty('queryKeyType') &&
        selectedQuery.queryKeyType === 'String'
      ) {
        const datasetInfo = await getDatasetSchemasValues({
          datasetId,
          fromPublicDatasets: showPublicDatasets,
          queryKey: `${
            selectedQuery.queryKey
              ? `${
                  selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  )[0].queryType
                }.${
                  selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  )[0].queryKey
                }`
              : `${
                  selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  )[0].queryType
                }`
          }`,
        });

        if (datasetInfo?.results?.length > 0) {
          setValues(datasetInfo.results);
          const results = [...datasetInfo.results];
          setValuesSampleLength(results.sort((a, b) => b.length - a.length)[0].length);
        } else {
          setValuesSampleLength(0);
        }
        setHasMoreValue(datasetInfo.hasMore);
      }
    })();
  }, [selectedQueryList]);

  useEffect(() => {
    if (typeof selectedQuery.queryValue === 'string' && selectedQuery.queryValue.startsWith('[')) {
      if (['in', 'not in'].includes(selectedQuery.queryOperator)) {
        // Synchronize selectedValues
        setSelectedValues(JSON.parse(selectedQuery.queryValue));
      } else {
        // Reset selectedValues
        setSelectedValues([]);
        // Initialize selectedQueryList & queryContent
        const hasQuery = selectedQueryList.filter(
          list => list.boxNum === boxNum.toString() + (index + 1),
        );
        const newQueryList = selectedQueryList.filter(
          list => list.boxNum !== boxNum.toString() + (index + 1),
        );
        updateQueryContent(boxNum, index, {
          ...hasQuery[0],
          queryValue: '',
        });
        setSelectedQueryList(() => [
          ...newQueryList,
          {
            ...hasQuery[0],
            queryValue: '',
          },
        ]);
      }
    }
  }, [selectedQuery, selectedQueryList]);

  if (['in', 'not in'].includes(selectedQuery.queryOperator)) {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          p={0.25}
          backgroundColor="gray-100"
          borderRadius="4px"
          style={{ width: 276 }}
        >
          <Select
            multiple
            data={values.map(value => ({ label: value, value }))}
            value={selectedValues}
            onChangeValue={handleSelect}
            placeholder={t('curate.advancedQuery.placeholder.select')}
          />
        </Box>
      </>
    );
  }

  if (['starts with', 'ends with', 'contains'].includes(selectedQuery.queryOperator)) {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          p={0.25}
          backgroundColor="gray-100"
          borderRadius="4px"
          style={{ width: 276 }}
        >
          <Input
            size="s"
            variant="text"
            value={
              selectedQueryList.filter(list => list.boxNum === boxNum.toString() + (index + 1))[0]
                .queryValue ?? ''
            }
            style={{ maxHeight: '16px', width: `${valuesSampleLength * 7.5}px` }}
            onChange={e => {
              const queryValue = e.target.value ?? '';

              const hasQuery = selectedQueryList.filter(
                list => list.boxNum === boxNum.toString() + (index + 1),
              );
              const newQueryList = selectedQueryList.filter(
                list => list.boxNum !== boxNum.toString() + (index + 1),
              );
              updateQueryContent(boxNum, index, {
                ...hasQuery[0],
                queryValue: `"${queryValue}"`,
              });
              setSelectedQueryList(() => [
                ...newQueryList,
                {
                  ...hasQuery[0],
                  queryValue: queryValue,
                },
              ]);
            }}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      {selectedQuery.queryKeyType === 'String' && values.length > 0 ? (
        <DropdownMenu
          width={valuesSampleLength > 18 ? valuesSampleLength * 9 : 152}
          AnchorElement={
            <Button style={{ padding: 0, border: 'none' }} variant="text">
              {isSelected ? (
                <Box
                  display="flex"
                  style={{
                    background: '#FFFFFF',
                    boxSizing: 'border-box',
                    borderTop: '1px solid #DCDCDC',
                    borderBottom: '1px solid #DCDCDC',
                    borderRight: '1px solid #DCDCDC',
                    cursor: 'pointer',
                  }}
                >
                  {values.length > 10 ? (
                    <Box style={{ padding: '5px 8px' }}>
                      <Input
                        size="s"
                        variant="text"
                        value={
                          selectedQueryList.filter(
                            list => list.boxNum === boxNum.toString() + (index + 1),
                          )[0].queryValue ?? ''
                        }
                        style={{ maxHeight: '16px', width: `${valuesSampleLength * 7.5}px` }}
                        onChange={e => handleQueryText(e)}
                      />
                    </Box>
                  ) : (
                    <Box mr={1.5} style={{ padding: '5px 8px' }}>
                      {selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      )[0].queryValue ? (
                        <Typography>
                          {`${
                            selectedQueryList.filter(
                              list => list.boxNum === boxNum.toString() + (index + 1),
                            )[0].queryValue
                          }`}
                        </Typography>
                      ) : (
                        <div style={{ minWidth: '30px', minHeight: '18px' }}></div>
                      )}
                    </Box>
                  )}
                  <Box
                    mx={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => deleteQuery(boxNum, list.boxIndex)}
                    style={{ maxWidth: '12px', background: '#FFFFFF' }}
                  >
                    <Icon icon={Clear} color={'primary-400'} size="12px" />
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #DCDCDC',
                    borderRadius: '2px',
                    maxHeight: '32px',
                    minWidth: '104px',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={() => {
                    if (!hoverButtonList.includes(boxNum.toString() + (index + 1)))
                      setHoverButtonList(() => [
                        ...hoverButtonList,
                        boxNum.toString() + (index + 1),
                      ]);
                  }}
                  onMouseLeave={() => {
                    const newHoverButtonList = hoverButtonList.filter(
                      list => list !== boxNum.toString() + (index + 1),
                    );
                    setHoverButtonList(() => newHoverButtonList);
                  }}
                >
                  <Box
                    style={{
                      width: '80px',
                      background: `${
                        hoverButtonList.includes(boxNum.toString() + (index + 1))
                          ? '#F5F5F5'
                          : '#FFFFFF'
                      }`,
                      boxSizing: 'border-box',
                      borderRight: `${
                        hoverButtonList.includes(boxNum.toString() + (index + 1))
                          ? '1px solid #DCDCDC'
                          : 'none'
                      }`,
                    }}
                  >
                    <Box style={{ padding: '5px 8px' }}>
                      {hasMoreValue ? (
                        <Input
                          size="s"
                          variant="text"
                          placeholder={t('curate.advancedQuery.placeholder.select')}
                          value={
                            selectedQueryList.filter(
                              list => list.boxNum === boxNum.toString() + (index + 1),
                            )[0].queryValue ?? ''
                          }
                          style={{
                            maxHeight: '16px',
                            width: `${valuesSampleLength * 7.5}px`,
                          }}
                          onChange={e => handleQueryText(e)}
                        />
                      ) : (
                        <Typography
                          variant="m-regular"
                          color={`${
                            hoverButtonList.includes(boxNum.toString() + (index + 1))
                              ? 'gray-400'
                              : 'gray-300'
                          }`}
                        >
                          Select...
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    mx={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => deleteQuery(boxNum, list.boxIndex)}
                    style={{ maxWidth: '12px', background: '#FFFFFF' }}
                  >
                    <Icon icon={Clear} color={'primary-400'} size="12px" />
                  </Box>
                </Box>
              )}
            </Button>
          }
          placement="bottom-start"
        >
          <DropdownMenuItemWrapper>
            {values.map(queryValue => (
              <DropdownMenuItem
                key={queryValue}
                onClick={() => {
                  const hasQuery = selectedQueryList.filter(
                    list => list.boxNum === boxNum.toString() + (index + 1),
                  );

                  const newQueryList = selectedQueryList.filter(
                    list => list.boxNum !== boxNum.toString() + (index + 1),
                  );

                  updateQueryContent(boxNum, index, {
                    ...hasQuery[0],
                    queryValue:
                      selectedQuery?.queryKeyType === 'String' ? `"${queryValue}"` : queryValue,
                  });
                  setSelectedQueryList(() => [
                    ...newQueryList,
                    {
                      ...hasQuery[0],
                      queryValue:
                        selectedQuery?.queryKeyType === 'String' ? `"${queryValue}"` : queryValue,
                    },
                  ]);
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="m-regular">{queryValue}</Typography>
                </Box>
              </DropdownMenuItem>
            ))}
          </DropdownMenuItemWrapper>
        </DropdownMenu>
      ) : (
        <Box
          display="flex"
          style={{
            width: `${selectedQuery.queryKeyType === 'Date' ? '224px' : '105px'}`,
            border: '1px solid #DCDCDC',
            height: '30px',
            margin: '1px 0',
            background: '#FFFFFF',
          }}
        >
          {selectedQuery.queryKeyType === 'Date' ? (
            <DatePicker
              cleanable={false}
              format="yyyy-MM-dd HH:mm:ss"
              value={
                selectedQuery.queryValue ? parseDate(new Date(selectedQuery.queryValue)) : undefined
              }
              onChange={value => {
                const date = new Date(value || 0);
                date.setMilliseconds(0);
                const UTCdate = date.toISOString();
                handleQueryValue(UTCdate, boxNum, index);
              }}
            />
          ) : (
            <Input
              size="s"
              variant="text"
              type={selectedQuery.queryKeyType === 'Number' ? 'number' : 'string'}
              placeholder={
                selectedQuery.queryKeyType === 'Date' ? 'yyyy-mm-ddThh:mm:ss.sssZ' : 'Select...'
              }
              style={{ backgroundColor: 'transparent', border: '0px solid transparent' }}
              value={selectedQuery.queryValue ?? ''}
              onChange={e => {
                handleQueryValue(e.target.value, boxNum, index);
              }}
            />
          )}
          <Box
            mx={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => deleteQuery(boxNum, list.boxIndex)}
            style={{ maxWidth: '12px', background: '#FFFFFF' }}
          >
            <Icon icon={Clear} color={'primary-400'} size="12px" />
          </Box>
        </Box>
      )}
    </>
  );
}

const DropdownMenuItemWrapper = styled.div`
  position: absolute;
  top: -8px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
