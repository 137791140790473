import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, useDialogState } from '@superb-ai/ui';

import Tutorial from '../../../../../../../../components/elements/Tutorial';
import { getUserManualUrl } from '../../../../../../../../consts/DocsLink';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import ActionTutorialHeader from '../../../../tutorial/ActionTutorialHeader';
import analyticsImage from './asset/analytics-image.png';
import gridImage from './asset/grid-image.png';
import modelImage from './asset/model-image.png';
import ContactUsButton from './ContactUsButton';

export const ModelDiagnosisTutorial = () => {
  const { t, i18n } = useTranslation();
  const { showPublicDatasets } = usePublicDatasetContext();

  const {
    CURATE_MODEL_DIAGNOSIS_MODEL_INTEGRATION,
    CURATE_MODEL_DIAGNOSIS_ANALYTICS_MANUAL,
    CURATE_MODEL_DIAGNOSIS_GRID_MANUAL,
  } = getUserManualUrl(i18n.language);

  const tutorialContents = [
    {
      imageSrc: modelImage,
      title: t('curate.diagnosis.tutorial.model.title'),
      description: t('curate.diagnosis.tutorial.model.content'),
      link: CURATE_MODEL_DIAGNOSIS_MODEL_INTEGRATION,
    },
    {
      imageSrc: analyticsImage,
      title: t('curate.diagnosis.tutorial.analytics.title'),
      description: t('curate.diagnosis.tutorial.analytics.content'),
      link: CURATE_MODEL_DIAGNOSIS_ANALYTICS_MANUAL,
    },
    {
      imageSrc: gridImage,
      title: t('curate.diagnosis.tutorial.grid.title'),
      description: t('curate.diagnosis.tutorial.grid.content'),
      link: CURATE_MODEL_DIAGNOSIS_GRID_MANUAL,
    },
  ];

  return (
    <Box>
      {!showPublicDatasets && <Header />}
      <Tutorial contents={tutorialContents} />
    </Box>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const state = useDialogState();
  const { accountName } = useParams<{ accountName: string }>();

  return (
    <ActionTutorialHeader
      title={t('curate.diagnosis.tutorial.header.prompt')}
      description={<Trans t={t} i18nKey="curate.diagnosis.tutorial.header.text" />}
      action={
        <Box display="flex" alignItems="center" gap={1}>
          <ContactUsButton />
          <Link to={`/${accountName}/model/model/`}>
            <Button
              size="l"
              variant="strong-fill"
              color="gray"
              onClick={() => {
                state.show();
              }}
            >
              {t('curate.diagnosis.tutorial.header.goToTrainModel')}
            </Button>
          </Link>
        </Box>
      }
    />
  );
};
