import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

export default function Logo(
  props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) {
  return (
    <img
      onClick={() => window.open('https://www.superb-ai.com/')}
      width="118px"
      src="https://asset.superb-ai.com/assets/img/new_logo.png"
      alt="logo"
      {...props}
      style={{ cursor: 'pointer', objectFit: 'cover', position: 'absolute', ...props.style }}
    />
  );
}
