import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ScatterDots, ScatterThumbnails } from '@superb-ai/icons';
import { Icon, SegmentedControl } from '@superb-ai/ui';

import { useMode, useSetMode } from './scatterView/providers/ModeProvider';
import {
  ScatterViewMode,
  useSetViewMode,
  useViewMode,
} from './scatterView/providers/ViewModeProvider';

export default function ScatterPointThumbnailMode() {
  const { t } = useTranslation();

  const scatterViewMode = useViewMode();
  const setScatterViewMode = useSetViewMode();
  const mode = useMode();
  const setMode = useSetMode();

  const handleClickDrawBox = useCallback(() => {
    setMode({
      modeName: 'drawSelectionArea',
      state: {
        phase: 'idle',
        selectedPoints:
          mode.modeName === 'selection' && mode.state.phase === 'selecting'
            ? mode.state.points
            : [],
      },
    });
  }, [mode, setMode]);

  const handleClickViewMode = useCallback(
    (value: ScatterViewMode) => {
      setScatterViewMode(value);

      // if (value === 'thumbnails') {
      //   handleClickDrawBox();
      // }
    },
    [handleClickDrawBox, setScatterViewMode],
  );

  return (
    <SegmentedControl
      width="auto"
      buttonProps={{ square: true }}
      options={[
        {
          value: 'points',
          label: <Icon icon={ScatterDots} />,
          tooltip: t('curate.embeddings.viewMode.points'),
        },
        {
          value: 'thumbnails',
          label: <Icon icon={ScatterThumbnails} />,
          tooltip: t('curate.embeddings.viewMode.thumbnails'),
        },
      ]}
      value={scatterViewMode}
      onChangeValue={handleClickViewMode}
    />
  );
}
