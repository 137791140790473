import { ScaleOrdinal } from 'd3-scale';

import { ChartDatum } from '../../../../apps/Curate/components/datasets/dataset/analytics/charts/types';
import { LabelStatus } from '../../../../types/labelTypes';
import {
  colorPalette,
  getSpbColorBlindFriendlyColorsString,
} from '../charts/donutChart/donutChartColors';
import { CustomChartSpec } from './chartSpecs';
import { CategoryFields, GroupByField, LabelFields } from './fields';
import { DataEnum, DataSpec } from './type';

export const getLabelStatusColor = (status: keyof typeof palette) => {
  const palette = CustomChartSpec[DataEnum.labelCount].status.colors as Record<LabelStatus, string>;
  return palette[status];
};

export type GetOrdinalColor = (keys: string[]) => ScaleOrdinal<string, string>;
export type GetColorFn<T> = (key: T) => string | GetOrdinalColor;

type ColorMap = Record<string, string>;

export const makeColorFnFromField = (data: ChartDatum[]) => {
  return (key: string) => {
    return data.reduce((acc: ColorMap, curr: ChartDatum) => {
      acc[curr.key] = curr.color as string;
      return acc;
    }, {} as ColorMap)[key];
  };
};

export const getColorFunc = (field: GroupByField, keys: string[]): GetColorFn<string> => {
  const defaultFn = (_: string) => 'purple';
  if (!keys) return defaultFn as GetColorFn<string>;

  switch (field) {
    case LabelFields.assetGroup:
      return (field: string) => getSpbColorBlindFriendlyColorsString(keys)(field);
    case CategoryFields.categoryIds:
      return (field: string) => getSpbColorBlindFriendlyColorsString(keys)(field);
    default:
      return defaultFn;
  }
};

const DataColor = {
  // [DataEnum.labelCount]: '#FF625A',
  [DataEnum.labelCount]: colorPalette[0],
  [DataEnum.objectCount]: '#3AA7A7',
  [DataEnum.categoryCount]: '#4CAF50',
  [DataEnum.objectAnnotationCount]: '#FFB800',
};

export function getDataColor(dataScope: DataSpec) {
  return dataScope in DataColor ? DataColor[dataScope as DataEnum] : '#3AA7A7';
}

export const PREVIEW_CHART_COLOR = {
  tabView: '#FBFBFB',
};

/** Create Dialog */
export const dialogColors = {
  dialog: '#F5F5F5',
  previewArea: '#E8E8E8',
};
