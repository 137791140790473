import React from 'react';

import * as MUI from '@mui/material';

import { annotation } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import FilterClassDropdown from './FilterClassDropdownMultiple/FilterClassDropdown';
import { FilterTypeProps } from './types';

const FilterClass: React.FC<FilterTypeProps> = props => {
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={annotation.conditions}
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      {!(filter.condition === 'does not exist' || filter.condition === 'exists') && (
        <MUI.Grid item style={{ flex: 1 }}>
          <FilterClassDropdown
            index={index}
            objKey="options"
            onValueChange={onValueChange}
            isCurrentRow={isCurrentRow}
            onChangeCurrentRow={onChangeCurrentRow}
          />
        </MUI.Grid>
      )}
    </>
  );
};

export default FilterClass;
