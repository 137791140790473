import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { Search } from '@superb-ai/icons';
import { atoms, Box, Icon, Input } from '@superb-ai/ui';
import { flatten, isEmpty } from 'lodash';

import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { CategoryCheckbox } from './CategoryCheckbox';
import { METADATA_CARDINALITY_THRESHOLD } from './const';
import ObjectMetadataFilterCollapse from './ObjectMetadataFilterCollapse';
import { searchResults } from './utils';

type SearchResult = { count: number; options: string[] };
export default function ObjectMetadataFilter() {
  const { t } = useTranslation();
  const boxRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<
    VariableSizeList<{
      searchResultEntries: [string, SearchResult][];
      searchInput: string | undefined;
    }>
  >(null);
  const scrollClassName = atoms({ overflow: 'auto' });

  const KEY_HEIGHT = 26;
  const VALUE_HEIGHT = 24;

  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const { filterSchema, selectedMetadata, setSelectedMetadata, appliedFilters, setAppliedFilters } =
    useObjectFilterContext();

  useEffect(() => {
    if (!boxRef.current) return;
  }, [boxRef]);

  const metadataSchema = Object.keys(filterSchema?.metadata_cardinality || {}).reduce(
    (acc, metadataKey) => ({
      ...acc,
      [metadataKey]: {
        count: filterSchema?.metadata_cardinality[metadataKey] || 0,
        options: (filterSchema?.metadata_in && filterSchema?.metadata_in[metadataKey]) || [],
      },
    }),
    {},
  );

  const [searchResult, setSearchResult] = useState(metadataSchema);
  const metadataEntries: [string, { count: number; options: string[] }][] = metadataSchema
    ? Object.entries(metadataSchema)
    : [];
  const searchResultEntries: [string, { count: number; options: string[] }][] = searchResult
    ? Object.entries(searchResult)
    : [];
  const [itemSizes, setItemSizes] = useState<number[]>(
    searchResultEntries.map(entry =>
      entry[1].count > 0 && entry[1].count <= METADATA_CARDINALITY_THRESHOLD
        ? KEY_HEIGHT + entry[1].count * VALUE_HEIGHT
        : KEY_HEIGHT,
    ),
  );
  function handleItemHeightChange(index: number, newHeight: number) {
    listRef.current?.resetAfterIndex(0);
    setItemSizes(sizes => {
      const newSizes = [...sizes];
      newSizes[index] = newHeight;
      return newSizes;
    });
  }

  const [openStates, setOpenStates] = useState<boolean[]>(
    new Array(searchResultEntries.length).fill(true),
  );

  function handleToggle(index: number) {
    setOpenStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      const isOpen = newStates[index];
      const listItem = searchResultEntries[index];
      handleItemHeightChange(
        index,
        isOpen ? KEY_HEIGHT + listItem[1].count * VALUE_HEIGHT : KEY_HEIGHT,
      );
      return newStates;
    });
  }

  function getIsCategoryChecked() {
    const selectedKeys = selectedMetadata ? Object.keys(selectedMetadata) : [];
    const metadataValuesCount = (
      flatten(metadataSchema ? Object.values(metadataSchema) : []) as SearchResult[]
    ).reduce((acc: number, metadata: SearchResult) => {
      return acc + (metadata.count > METADATA_CARDINALITY_THRESHOLD ? 0 : metadata.count);
    }, 0);
    const selectedValues = flatten(selectedMetadata ? Object.values(selectedMetadata) : []);

    const hasSameKeyAndValuesCount =
      metadataEntries.length === selectedKeys.length &&
      metadataValuesCount === selectedValues.length;
    return hasSameKeyAndValuesCount ? true : selectedKeys.length === 0 ? false : 'mixed';
  }

  function handleClickCategoryCheckbox() {
    const categoryState = getIsCategoryChecked();
    if (categoryState && categoryState !== 'mixed') {
      setSelectedMetadata({});
      return;
    }
    setSelectedMetadata(
      Object.keys(metadataSchema).reduce((acc, key) => {
        if (isEmpty(metadataSchema)) return acc;
        return { ...acc, [key]: (metadataSchema as Record<string, SearchResult>)[key].options };
      }, {}),
    );
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const searchKey = event.target.value;
    setSearchInput(searchKey);

    const filteredItems = metadataEntries.reduce((acc, entry) => {
      const isKeyFiltered = searchResults([entry[0]], searchKey).length > 0;
      const filteredValues = searchResults(entry[1].options, searchKey);
      if (isKeyFiltered) {
        return { ...acc, [entry[0]]: !!filteredValues.length ? filteredValues : entry[1] };
      }
      if (filteredValues.length) {
        return { ...acc, [entry[0]]: filteredValues };
      }
      return acc;
    }, {});
    setSearchResult(filteredItems);
  }

  return (
    <Box display="grid" style={{ gridTemplateRows: 'auto auto 1fr', flex: 1 }} gap={0.5}>
      <CategoryCheckbox
        label={t('curate.annotationFilter.metadataFilter')}
        state={getIsCategoryChecked()}
        onClick={handleClickCategoryCheckbox}
        appliedCount={
          Object.values(appliedFilters?.metadata_in || {}).flatMap(v => v).length ||
          appliedFilters?.metadata_all_values_in?.length ||
          0
        }
        handleReset={() => {
          setAppliedFilters({ ...appliedFilters, metadata_in: {}, metadata_all_values_in: [] });
          setSelectedMetadata({});
        }}
      />
      <Input
        value={searchInput}
        onChange={handleSearch}
        prefix={<Icon icon={Search} />}
        placeholder="Search"
      />
      <Box overflow="hidden">
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <VariableSizeList
              ref={listRef}
              className={scrollClassName}
              height={height}
              width={width}
              itemData={{ searchResultEntries, searchInput }}
              itemSize={index => itemSizes[index]}
              itemCount={searchResultEntries.length}
            >
              {({ ...props }) => (
                <ObjectMetadataFilterCollapse
                  {...props}
                  open={openStates[props.index]}
                  handleToggle={handleToggle}
                />
              )}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}
