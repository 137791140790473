import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Copy, LinkExternal } from '@superb-ai/icons';
import { Box, Icon, IconButton, LinkTypography, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import { COPY_SUCCESS } from '../../../../../../../../consts/SnackbarMessage';
import { useProjectsQuery } from '../../../../../../../../queries/useProjectQuery';
import { getUrl } from '../../../../../../../../routes/util';
import FileUtils from '../../../../../../../../utils/FileUtils';
import { CurateImageData } from '../../../../../../services/DatasetService';
import DetailModalCabinetCollapse from '../image/DetailModalCabinetCollapse';

export default function ImageProperties({ imageInfo }: { imageInfo: CurateImageData }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();

  const [copyButtonVisible, setCopyButtonVisible] = useState<
    'image_key' | 'image_id' | 'image_url'
  >();
  const { data: projectsData } = useProjectsQuery({
    projectIds: imageInfo.synced_label_project_ids,
  });

  const textEllipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;

  const handleClickCopy = () => {
    if (!copyButtonVisible) return;
    FileUtils.copyToClipboard({
      value: {
        image_key: imageInfo.key,
        image_id: imageInfo.id,
        image_url: imageInfo.image_url,
      }[copyButtonVisible],
    });
    enqueueSnackbar(COPY_SUCCESS({ t, label: startCase(copyButtonVisible) }), {
      variant: 'success',
    });
  };
  return (
    <DetailModalCabinetCollapse title={t('curate.datasets.detailView.imageProperties')}>
      <Box display="flex" flexDirection="column" gap={1.5}>
        <Box
          onMouseEnter={() => setCopyButtonVisible('image_key')}
          onMouseLeave={() => setCopyButtonVisible(undefined)}
          display="flex"
          flexDirection="column"
          gap={0.5}
        >
          <Typography variant="m-strong">{t('curate.datasets.detailView.imageKey')}</Typography>
          <Box display="flex" alignItems="center" style={{ height: 18 }}>
            <Typography variant="m-regular" style={{ display: 'block', ...textEllipsisStyle }}>
              {imageInfo.key}
            </Typography>
            {copyButtonVisible === 'image_key' && (
              <IconButton
                style={{ marginLeft: 'auto' }}
                onClick={handleClickCopy}
                icon={Copy}
                variant="text"
                size="s"
              />
            )}
          </Box>
        </Box>
        <Box
          onMouseEnter={() => setCopyButtonVisible('image_id')}
          onMouseLeave={() => setCopyButtonVisible(undefined)}
          display="flex"
          flexDirection="column"
          gap={0.5}
        >
          <Typography variant="m-strong">{t('curate.datasets.detailView.imageId')}</Typography>
          <Box display="flex" alignItems="center" style={{ height: 18 }}>
            <Typography variant="m-regular" style={{ display: 'block', ...textEllipsisStyle }}>
              {imageInfo.id}
            </Typography>
            {copyButtonVisible === 'image_id' && (
              <IconButton
                onClick={handleClickCopy}
                icon={Copy}
                variant="text"
                size="s"
                style={{ marginLeft: 'auto' }}
              />
            )}
          </Box>
        </Box>
        <Box
          position="relative"
          onMouseEnter={() => setCopyButtonVisible('image_url')}
          onMouseLeave={() => setCopyButtonVisible(undefined)}
          // style={{ width: cabinetWidth }}
          display="flex"
          flexDirection="column"
          gap={0.5}
        >
          <Typography variant="m-strong">{t('curate.datasets.detailView.imageUrl')}</Typography>
          <Box display="flex" alignItems="center" style={{ height: 18 }}>
            <Typography variant="m-regular" style={{ display: 'block', ...textEllipsisStyle }}>
              {imageInfo.image_url}
            </Typography>
            {copyButtonVisible === 'image_url' && (
              <IconButton
                style={{ marginLeft: 'auto' }}
                onClick={handleClickCopy}
                icon={Copy}
                variant="text"
                size="s"
              />
            )}
          </Box>
        </Box>
        {projectsData?.length > 0 && (
          <Box
            // style={{ width: cabinetWidth }}
            display="flex"
            flexDirection="column"
            gap={0.5}
          >
            <Typography variant="m-strong">
              {t('curate.datasets.detailView.syncedLabelProjects')}
            </Typography>
            <Box>
              {projectsData?.map(data => (
                <Typography
                  key={data.id}
                  display="flex"
                  gap={0.5}
                  variant="m-regular"
                  style={{ width: 240 }}
                >
                  •
                  <LinkTypography
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    color="gray"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    onClick={() => history.push(getUrl([accountName, 'label', 'project', data.id]))}
                  >
                    {data.name}
                    <Icon icon={LinkExternal} />
                  </LinkTypography>
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </DetailModalCabinetCollapse>
  );
}
