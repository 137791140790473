// TODO: i18n
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Icon, Input, Link, Typography } from '@superb-ai/norwegian-forest';
import { TFunction } from 'next-i18next';

import { getUserManualUrl } from '../../../../../consts/DocsLink';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../../contexts/UploadContext';
import FileService from '../../../../../services/FileService';
import { TargetTypeCivet } from '../../../../pages/account/integrations/types';
import { UploadStepInfo } from './type';

const VerifyCloudStorage = (t: TFunction): UploadStepInfo => {
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const {
    cloudIntegrationId,
    cloudStorageType,
    cloudPrefix,
    setCloudPrefix,
    manifestFileName,
    setManifestFileName,
  } = useUploadInfo();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileKey, setFileKey] = useState('');

  const { i18n } = useTranslation();
  const { POINTCLOUD_MANIFEST_MANUAL } = getUserManualUrl(i18n.language);

  const handleClickVerify = async () => {
    try {
      setIsLoading(true);
      await FileService.verifyKeyInBucket({
        integrationId: cloudIntegrationId || '',
        key: fileKey,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
        target: cloudStorageType.toLowerCase() as TargetTypeCivet,
      });

      // divide file key to manifest file name and cloud prefix
      const lastSlashIndex = fileKey.lastIndexOf('/');
      setManifestFileName(fileKey.slice(lastSlashIndex + 1, fileKey.length));
      setCloudPrefix(fileKey.slice(0, lastSlashIndex + 1));

      setHasError(false);
    } catch (err: any) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isAlreadyVerified =
    fileKey !== '' && fileKey === (cloudPrefix || '') + (manifestFileName || '');

  return {
    title: t('data.upload.selectManifestFile'),
    isButtonEnabled: !!manifestFileName,
    content: (
      <>
        {hasError && (
          <Box
            themedBackgroundColor={['primary', 100]}
            themedColor="primary"
            display="flex"
            alignItems="center"
            p={1}
            mb={1.5}
          >
            <Icon name="warningOutline" size="12px" />
            <Box display="flex" alignItems="center" ml={0.5}>
              <Typography variant="body3">
                {t('data.upload.fileDoesNotExist', { manifestFileName })}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography variant="body3" themedColor={['grey', 500]}>
          <Trans
            i18nKey="data.upload.verifyCloudStorage.description"
            components={{
              LinkTypography: (
                <Link
                  underlined
                  themedColor="secondary"
                  to={POINTCLOUD_MANIFEST_MANUAL}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
        <Box display="grid" mt={1} gap={8} gridTemplateColumns="1fr auto">
          <Input
            placeholder={t('data.upload.verifyCloudStorage.filePlaceholder')}
            onChange={e => setFileKey(e.target.value)}
          />
          <Box>
            <Button
              color="grey"
              onClick={handleClickVerify}
              isLoading={isLoading}
              disabled={isAlreadyVerified}
            >
              {isAlreadyVerified ? t('data.upload.verified') : t('data.upload.Verify')}
            </Button>
          </Box>
        </Box>
      </>
    ),
  };
};

export default VerifyCloudStorage;
