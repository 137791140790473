import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, DropdownList } from '@superb-ai/norwegian-forest';

import ProjectConst from '../../../consts/ProjectConst';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';
import { getUrl } from '../../../routes/util';

const QueryParamName = 'dataType';
const DataTypes = ['image', 'image sequence', 'pointclouds'];

export default function ProjectTypeFilter(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const lidar = useFeatureFlag('lidar');
  const searchParams = Object.fromEntries(new URLSearchParams(history.location.search));
  const options = [
    { value: '', label: t('projects.allProjects') },
    ...DataTypes.map(value => ({
      value,
      label: getDataTypeTranslation(t, ProjectConst.dataTypes(lidar)[value]?.label ?? value),
    })),
  ];

  function onChangeFilter(type: string) {
    history.push(
      getUrl(
        history.location.pathname,
        {},
        { ...searchParams, page: 1, [QueryParamName]: type || undefined },
      ),
    );
  }

  const value = searchParams[QueryParamName]?.split(',').filter(t => t) || [];

  return (
    <Box display="flex" width={200}>
      <DropdownList
        variant="stroke"
        value={value[0]}
        options={options}
        placeholder={t('projects.filter.dataType')}
        onChange={onChangeFilter}
      />
    </Box>
  );
}

function getDataTypeTranslation(
  t: TFunction,
  dataType: 'image' | 'video' | 'point cloud sequence',
) {
  switch (dataType) {
    case 'image':
      return t('consts.dataTypeConst.image');
    case 'video':
      return t('consts.dataTypeConst.video');
    case 'point cloud sequence':
      return t('consts.dataTypeConst.pointclouds');
  }
}
