import { IconName } from '@superb-ai/norwegian-forest';
import { capitalize, concat, forEach, map, uniqBy } from 'lodash';

import { ANNOTATION_TYPE } from '../consts/NewProjectConst';
import { ObjectPropertiesAndCategoriesType } from '../types/projectTypes';
import WorkappUnion, { WorkApp } from '../union/WorkappUnion';
import { Category, DataType, ObjectClass } from './LabelInterfaceUtils';

const getNewName = (prefixStr: string, list: { name: string }[]): string => {
  let nextName = `${prefixStr} (${list.length + 1})`;

  let index = 0;
  while (index <= list.length) {
    const candidateName = index === 0 ? prefixStr : `${prefixStr} (${index})`;
    const isExist = typeof list.find(item => item.name === candidateName) !== 'undefined';
    if (!isExist) {
      nextName = candidateName;
      break;
    }
    index++;
  }
  return nextName;
};

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

type Map = Record<string, any>;
const traversalTree = (categoryMap: Record<string, any>, targetNodeId: string, map: Map): Map => {
  const targetNode = categoryMap[targetNodeId];
  // eslint-disable-next-line no-param-reassign
  map[targetNodeId] = {
    id: targetNodeId,
  };

  const { children } = targetNode;
  if (children.length === 0) return map;

  for (let i = 0; i < children.length; i++) {
    const childId = children[i];
    traversalTree(categoryMap, childId, map[targetNodeId]);
  }

  return map;
};

interface Word {
  id: string;
  word: string;
}
const convertToCategorization = <T extends { wordMap: any }>(
  categorization: T,
): {
  words: Word[];
  wordMap: Record<string, Word>;
} & T => {
  const words: Word[] = [];
  const wordMap: Record<string, string> = {};

  const categoryMap: Record<string, Word> = {};
  for (let i = 0; i < categorization.wordMap.length; i++) {
    const word = categorization.wordMap[i];
    words[i] = {
      id: word.id,
      word: word.name,
    };
    categoryMap[word.id] = word;
  }

  traversalTree(categoryMap, 'root', wordMap);

  return {
    ...categorization,
    words,
    wordMap,
  };
};

const getShape = (object: { info: { shapes: Record<string, any> } }): string => {
  return Object.keys(object.info.shapes)[0];
};

// eslint-disable-next-line
const getAnnotationTypes = (labelInterface: any): { value: string; label: string }[] => {
  const types = [];

  if (
    labelInterface.type === 'image-siesta' ||
    labelInterface.type === 'video-siesta' ||
    labelInterface.dataType === 'image sequence' ||
    labelInterface.dataType === 'pointclouds'
  ) {
    const objectDef: any = labelInterface.objectDetection || labelInterface.objectTracking;
    objectDef.annotationTypes.forEach((type: string) => {
      const value = type.toUpperCase() as keyof typeof ANNOTATION_TYPE;
      types.push({
        value,
        label: ANNOTATION_TYPE[value]?.label ?? capitalize(type),
      });
    });
  } else {
    const { objects } = labelInterface;
    forEach(objects, object => {
      forEach(object.info.shapes, () => {
        const value = getShape(object);
        types.push({
          value,
          label: capitalize(value),
        });
      });
    });
    if (labelInterface.categorization) {
      types.push({
        value: 'IMAGE_CATEGORY',
        label: 'Image Category',
      });
    }
  }

  return uniqBy(types, t => t.value).sort();
};

const isFrameBasedProject = (labelInterface: any): boolean => {
  return labelInterface.dataType === 'image sequence' || labelInterface.dataType === 'pointclouds';
};

const convertObjectPropertiesAndCategorizationType = (type: ObjectPropertiesAndCategoriesType) => {
  return {
    checkbox: 'projectSettings.propertyType.multipleSelection',
    radio: 'projectSettings.propertyType.multipleChoice',
    'free response': 'projectSettings.propertyType.freeResponse',
  }[type];
};

const getObjectPropertiesAndCategorizationIcon = (type: ObjectPropertiesAndCategoriesType) => {
  return {
    checkbox: 'checkboxChecked',
    radio: 'radioChecked',
    'free response': 'pencil',
  }[type] as IconName;
};

const getChildrenCategories = (
  children: any[],
  parentLabels: string[],
  parentValues: any[],
  parentChildrenMap: Record<string, any[]>,
  categorizationIdToName: Record<string, string>,
): Record<string, any> => {
  return map(children, child => {
    const childList = parentChildrenMap[child];
    const childName = categorizationIdToName[child];
    const newParentLabels = concat(parentLabels, [childName]);
    const newParentValues = concat(parentValues, [child]);
    if (childList.length > 0) {
      return {
        label: childName,
        value: child,
        parentLabels,
        parentValues,
        children: getChildrenCategories(
          childList,
          newParentLabels,
          newParentValues,
          parentChildrenMap,
          categorizationIdToName,
        ),
      };
    }
    return { label: childName, value: child, parentLabels, parentValues };
  });
};

const flattenCategorizationNodes = (
  flatNodes: Record<string, any>,
  nodes: any[],
  parent: Record<string, any> = {},
  depth = 0,
): Record<string, any> => {
  if (!Array.isArray(nodes) || nodes.length === 0) {
    return {};
  }

  nodes.forEach((node, index) => {
    if (node.value) {
      // eslint-disable-next-line no-param-reassign
      flatNodes[node.value] = {
        label: node.label,
        value: node.value,
        children: node.children,
        parent: parent.annoType ? null : parent,
        parentValues: node.parentValues,
        isChild: parent.value !== undefined,
        isParent: !!node.children,
        isLeaf: !node.children,
        treeDepth: depth,
        index,
      };
    }
    flattenCategorizationNodes(flatNodes, node.children, node, depth + 1);
  });

  return flatNodes;
};

const getUseAutoLabel = (workapp: WorkApp, classesOrProperties: any[]): boolean => {
  // Legacy support
  if (WorkappUnion.isImageDefault(workapp)) {
    return classesOrProperties.some(object =>
      object?.info?.aiClassMap?.some((aiClass: any) => aiClass?.classIds?.length !== 0),
    );
  }

  const [classes, properties] = classesOrProperties;
  return (
    classes.some((object: ObjectClass) =>
      object?.aiClassMap?.some((aiClass: any) => aiClass?.classIds?.length !== 0),
    ) || properties.some((property: Category) => property?.aiProperty?.propertyId)
  );
};

function isAvailableAdvancedQA(workapp: string): boolean {
  const isImageSiestaProject =
    WorkappUnion.isImageBeta(workapp) || WorkappUnion.isImageSiesta(workapp as any);
  return isImageSiestaProject;
}

const getProjectDataType = (workapp: string): DataType => {
  if (workapp.startsWith('video')) {
    return 'video';
  }
  if (workapp.startsWith('pointclouds')) {
    return 'pointclouds';
  }
  return 'image';
};

export default {
  getNewName,
  reorder,
  getShape,
  getAnnotationTypes,
  convertObjectPropertiesAndCategorizationType,
  getObjectPropertiesAndCategorizationIcon,
  convertToCategorization,
  getChildrenCategories,
  flattenCategorizationNodes,
  getUseAutoLabel,
  isFrameBasedProject,
  isAvailableAdvancedQA,
  getProjectDataType,
};
