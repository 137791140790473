import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import {
  NEW_FEATURE_MODEL_DIAGNOSIS,
  VISITED_NEW_FEATURES,
} from '../../../../../../components/elements/NewFeaturesChip';
import { AppRouteLayoutProps } from '../../../../../../menu';
import { joinPaths } from '../../../../../../routes/util';
import { ActionProvider } from '../../../../contexts/ActionContext';
import { DiagnosisModelProvider } from '../../../../contexts/DiagnosisModelContext';
import ModelDiagnosisContainer from './diagnosis/ModelDiagnosisContainer';
import ModelDiagnosisListLayout from './list/Layout';

export default function ModelDiagnosisRoute({ menuItem }: AppRouteLayoutProps): JSX.Element {
  const match = useRouteMatch();

  useEffect(() => {
    const visitedNewFeatures: string[] = JSON.parse(
      localStorage.getItem(VISITED_NEW_FEATURES) || '[]',
    );
    const isFeatureVisited = visitedNewFeatures.includes(NEW_FEATURE_MODEL_DIAGNOSIS);

    if (!isFeatureVisited) {
      localStorage.setItem(
        VISITED_NEW_FEATURES,
        JSON.stringify([...visitedNewFeatures, NEW_FEATURE_MODEL_DIAGNOSIS]),
      );
    }
  }, []);

  return (
    <DiagnosisModelProvider>
      <ActionProvider>
        <Switch>
          {/* <Route exact path={joinPaths(match.path, '')} component={ModelDiagnosisInitialLayout} /> */}
          <Route path={joinPaths(match.path, 'list')} component={ModelDiagnosisListLayout} />
          <Route path={joinPaths(match.path, ':diagnosisId')} component={ModelDiagnosisContainer} />
          <Redirect to={joinPaths(match.path, 'list')} />
        </Switch>
      </ActionProvider>
    </DiagnosisModelProvider>
  );
}
