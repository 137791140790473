import * as d3 from 'd3';
import { format } from 'date-fns';

import { parseDate } from '../../../../utils/date';

export const D3TimeParse = {
  daily: d3.timeParse('%Y-%m-%d'),
  hourly: d3.timeParse('%Y-%m-%d, %H:%M:%S'), // format "3/4/2020, 04:36:43"
};

export const formatPercent = (submitted: number, total: number): [string, number] => {
  const ratio = total === 0 ? 0 : (submitted * 100) / total;
  const roundedRatio: number = ratio > 0 && ratio <= 1 ? Math.ceil(ratio) : Math.floor(ratio);
  return [`${Math.round(roundedRatio)}%` as string, roundedRatio];
};

// Reference on dateFormat: https://github.com/d3/d3-time-format
export const formatD3DateTime = (dateString: Date, dateFormat: string): string => {
  const formatTime = d3.timeFormat(dateFormat);
  return formatTime(dateString);
};

export const getDisplayDate = (date: string): string => {
  return format(parseDate(date), 'yyyy-MM-dd');
};
