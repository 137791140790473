import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { DatePicker as RsuiteDatePicker } from 'rsuite';
import { RangeType } from 'rsuite/esm/DatePicker';

import { CalendarLocaleTexts } from './DateRangePicker';

interface Props extends React.ComponentProps<typeof RsuiteDatePicker> {
  timeEnabled?: boolean;
  activeStyleEnabled?: boolean;
}

export default function DatePicker({ timeEnabled, activeStyleEnabled, ...props }: Props) {
  const { t } = useTranslation();

  const Ranges: RangeType<Date>[] = [
    {
      label: t('datePicker.today'),
      value: new Date(),
    },
  ];

  const translatedLocaleTexts = Object.fromEntries(
    Object.entries(CalendarLocaleTexts).map(([key, i18nKey]) => [key, t(i18nKey)]),
  );

  return (
    <RsuiteDatePicker
      format={`MMM dd, yyyy${timeEnabled ? ', hh:mm aa' : ''}`}
      className={classNames(
        activeStyleEnabled && 'active-rs-picker',
        props.onClean && 'remove-rs-picker',
      )}
      defaultCalendarValue={new Date()}
      preventOverflow
      ranges={Ranges}
      locale={translatedLocaleTexts}
      {...props}
    />
  );
}
