import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ArrowLeft, ArrowRight, LoadingSpinner } from '@superb-ai/icons';
import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Icon, IconButton, Typography } from '@superb-ai/ui';
import dynamic from 'next/dynamic';

import { IouTypeToAnnotationMapping } from '../../../../../../../../../components/elements/windowedImageGrid/types';
import { PixiDetailAnnotation } from '../../../../../../../../../components/pages/aiAdvancedFeatures/Shapes';
import { useDatasetImageQuery } from '../../../../../../../queries/dataQueries';
import { useDiagnosisDetailQuery } from '../../../../../../../queries/diagnosisModelQueries';
import { EvaluationValue } from '../../../../../../../services/diagnosisTypes';
import {
  AnnotationTypeCoordinate,
  Box as AnnotationBox,
} from '../../../../../../../types/annotationTypes';
import DetailModalCabinet from './DetailModalCabinet';

const PixiDetailImage = dynamic({
  loader: () =>
    import('../../../../../../../../../components/pages/aiAdvancedFeatures/PixiDetailImage').then(
      x => x.PixiDetailImage,
    ),
  ssr: false,
});
type Props = {
  pageSize: number;
  totalCount: number;
  loadMoreItem: (startIndex: number, stopIndex?: number) => Promise<void>;
  loadedItems: EvaluationValue[];
  openImageIndex: number;
  setOpenImageIndex: Dispatch<SetStateAction<number | undefined>>;
};

export default function DetailModal({
  pageSize,
  totalCount,
  loadMoreItem,
  loadedItems,
  openImageIndex,
  setOpenImageIndex,
}: Props) {
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const diagnosisDetail = useDiagnosisDetailQuery({
    datasetId,
    diagnosisId: diagnosisId,
  });

  const [loading, setLoading] = useState(false);

  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [filteredAnnotationsIds, setFilteredAnnotationsIds] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleSelectImage = useCallback(() => {
    setAnnotationSelectedMap({});
  }, []);

  const handleSelectShape = useCallback((annotation: PixiDetailAnnotation) => {
    setAnnotationSelectedMap(prevState => {
      if (prevState[annotation.id]) return {};

      return {
        [annotation.id]: true,
      };
    });
  }, []);

  const isPrevDisabled = !openImageIndex || loading;
  const isNextDisabled = openImageIndex + 1 >= totalCount || loading;

  function prev() {
    if (isPrevDisabled) return;
    setOpenImageIndex(openImageIndex - 1);
  }

  async function next() {
    if (isNextDisabled) return;
    const nextIndex = openImageIndex + 1;
    if (nextIndex > loadedItems.length - 1) {
      setLoading(true);
      await loadMoreItem(Math.ceil(nextIndex / pageSize));
      setLoading(false);
    }
    setOpenImageIndex(nextIndex);
  }

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      const { key } = e;
      if (key === 'ArrowLeft') {
        prev();
      } else if (key === 'ArrowRight') {
        next();
      }
    };
    window.addEventListener('keyup', keyUpHandler);
    return () => {
      window.removeEventListener('keyup', keyUpHandler);
    };
    // eslint-disable-next-line
  }, [openImageIndex, loading]);

  const resultData = loadedItems[openImageIndex];
  const imageResult = useDatasetImageQuery({ datasetId, imageId: resultData?.imageId });

  const annotations = useMemo(() => {
    if (!resultData) {
      return [];
    }
    const { annotation, prediction, id } = resultData;
    const annoType = diagnosisDetail.data?.iouType
      ? IouTypeToAnnotationMapping[diagnosisDetail.data.iouType]
      : undefined;
    const resultArray = [];
    annotation &&
      resultArray.push({
        type: annoType,
        coordinate:
          diagnosisDetail.data?.iouType === 'bbox'
            ? (annotation.annotationValue as AnnotationTypeCoordinate<AnnotationBox>)
            : undefined,
        base64Data:
          diagnosisDetail.data?.iouType === 'segm'
            ? (annotation.annotationValue as string)
            : undefined,
        id: annotation.id,
        className: annotation.annotationClass,
        color: '#4ae2b9',
      });

    prediction &&
      resultArray.push({
        id, // prediction의 id가 따로 존재하지 않아서, evaluation id 사용함
        type: prediction.predictionType,
        coordinate:
          diagnosisDetail.data?.iouType === 'bbox'
            ? (prediction.predictionValue as AnnotationTypeCoordinate<AnnotationBox>)
            : undefined,
        base64Data:
          diagnosisDetail.data?.iouType === 'segm'
            ? (prediction.predictionValue as string)
            : undefined,

        className: prediction.predictionClass,
        color: '#ff625a',
      });

    return resultArray;
  }, [resultData, filteredAnnotationsIds]);

  if (!resultData) return <></>;

  return (
    <Modal
      open
      close={{
        onClose: () => {
          setOpenImageIndex(undefined);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={
        <Box width="100%" display="flex">
          <Box flex={1} display="flex" alignItems="center">
            <Typography variant="h2" color="primary">
              {resultData.id}
            </Typography>
            <Box ml="auto">
              <IconButton
                color="gray"
                icon={ArrowLeft}
                variant="text"
                disabled={isPrevDisabled}
                onClick={prev}
              />
              <IconButton
                color="gray"
                icon={ArrowRight}
                variant="text"
                disabled={isNextDisabled}
                onClick={next}
              />
            </Box>
          </Box>
          <Box width={276} />
        </Box>
      }
    >
      <Box
        width="80vw"
        maxWidth="1800px"
        minWidth="1124px"
        height="600px"
        px={4}
        display="flex"
        style={{ marginBottom: '-12px' }}
      >
        <Box
          flex={1}
          backgroundColor="gray-100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          {loading || imageResult.isLoading ? (
            <Icon icon={LoadingSpinner} size="40px" />
          ) : (
            <PixiDetailImage
              alt={resultData.id}
              imgUrl={imageResult.data?.image_url || ''}
              annotations={annotations}
              annotationSelectedMap={annotationSelectedMap}
              onSelectImage={handleSelectImage}
              onSelectShape={handleSelectShape}
            />
          )}
        </Box>
        <Box>
          {imageResult.data && (
            <DetailModalCabinet
              imageInfo={imageResult.data}
              annotation={resultData.annotation}
              prediction={resultData.prediction}
              evaluations={{
                confidence:
                  resultData.prediction?.confidence || t('curate.diagnosis.grid.notAvailable'),
                iou: resultData.iou,
              }}
              filteredAnnotationsIds={filteredAnnotationsIds}
              setFilteredAnnotationsIds={setFilteredAnnotationsIds}
              handleSelectImage={handleSelectImage}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}
