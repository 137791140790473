import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear, SearchSmallPlus } from '@superb-ai/icons';
import { Box, Icon, IconButton, Input, Typography } from '@superb-ai/ui';
import { isEmpty, trim } from 'lodash';
import { useSnackbar } from 'notistack';

import { CurateAnnotationType } from '../../../../../../../../types/curationTypes';
import { getColorsOf400 } from '../../../../../../../../utils/ColorUtils';
import ClassPropertiesList from './ClassPropertiesList';
import { getClassPropertyNamingRulesErrorMessages } from './helper';
import { ClassAndProperties } from './type';

export default function ObjectClassSection({
  originalCurateAnnotations,
  curateAnnotations,
  setCurateAnnotations,
  selectedAnnotationType,
}: {
  originalCurateAnnotations?: ClassAndProperties;
  curateAnnotations?: ClassAndProperties;
  selectedAnnotationType?: CurateAnnotationType;
  setCurateAnnotations: Dispatch<SetStateAction<ClassAndProperties | undefined>>;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchInput, setSearchInput] = useState<string>('');
  const colorOptions = getColorsOf400();

  const currentClassAndPropertiesList = useMemo(
    () =>
      (curateAnnotations && selectedAnnotationType && curateAnnotations[selectedAnnotationType]) ||
      {},
    [curateAnnotations, selectedAnnotationType],
  );

  const currentClasses = useMemo(
    () => Object.values(currentClassAndPropertiesList).map(classProperty => classProperty.class),
    [currentClassAndPropertiesList],
  );

  const [searchedClassList, setSearchedClassList] = useState<string[]>(currentClasses);
  const [selectedClass, setSelectedClass] = useState<string>();

  const isProjectSettingEmpty = isEmpty(curateAnnotations);
  const isAnnotationTypeSettingEmpty =
    curateAnnotations &&
    selectedAnnotationType &&
    isEmpty(curateAnnotations[selectedAnnotationType]);

  function handleSearchClassName(value: string) {
    setSearchInput(value);
    const searchedClasses = currentClasses.filter(currentClass =>
      currentClass.toLowerCase().includes(value.toLowerCase()),
    );
    setSearchedClassList(searchedClasses);
  }

  useEffect(() => {
    if (searchInput !== '') {
      handleSearchClassName(searchInput);
    } else {
      setSearchedClassList(currentClasses);
    }
  }, [currentClasses]);

  function handleCreateNewClass(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Enter' && searchInput) {
      const error = getClassPropertyNamingRulesErrorMessages('class', searchInput, t);
      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
        return;
      }
      setSelectedClass(searchInput);

      if (selectedAnnotationType && curateAnnotations?.[selectedAnnotationType]?.[searchInput]) {
        return;
      }

      const newClassAndProperties = {
        [searchInput]: {
          class: searchInput,
          isNew: !(
            selectedAnnotationType &&
            originalCurateAnnotations?.[selectedAnnotationType]?.[searchInput]
          ),
          color: colorOptions[0],
        },
      };
      setCurateAnnotations(prev => {
        const prevState = prev || ({} as ClassAndProperties);
        const newProjectSettings = { ...prevState };
        if (newProjectSettings && selectedAnnotationType) {
          newProjectSettings[selectedAnnotationType] = {
            ...prevState[selectedAnnotationType],
            ...newClassAndProperties,
          };
        }
        return newProjectSettings;
      });
    }
  }

  return (
    <Box width="100%" height="100%" overflow="auto">
      <Box p={0.5} borderBottom="1px solid" borderColor="gray-200">
        <Typography variant="s-strong" color="gray-300">
          {t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.objectClass')}
        </Typography>
      </Box>
      <Box width="100%" borderBottom="1px solid" borderColor="gray-200">
        <Input
          value={searchInput}
          disabled={!selectedAnnotationType || isProjectSettingEmpty}
          variant="text"
          prefix={<Icon icon={SearchSmallPlus} />}
          suffix={
            searchInput !== '' && (
              <IconButton
                variant="text"
                size="s"
                icon={Clear}
                onClick={() => handleSearchClassName('')}
              />
            )
          }
          placeholder={t(
            `curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.${
              isProjectSettingEmpty || isAnnotationTypeSettingEmpty
                ? 'objectClassCreateNewPlaceholder'
                : 'objectClassSearchPlaceholder'
            }`,
          )}
          onChange={e => {
            const value = trim(e.target.value);
            handleSearchClassName(value);
          }}
          onKeyUp={handleCreateNewClass}
        />
      </Box>
      <Box overflow="auto" style={{ height: 'calc(100% - 60px)' }}>
        <ClassPropertiesList
          classNameInputValue={searchInput}
          searchedClassList={searchedClassList}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
          curateAnnotations={curateAnnotations}
          setCurateAnnotations={setCurateAnnotations}
          selectedAnnotationType={selectedAnnotationType}
        />
      </Box>
    </Box>
  );
}
