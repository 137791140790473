const TIER = ['FREE', 'TEAM', 'ENTERPRISE'] as const;

export type Tier = (typeof TIER)[number];

const isFree = (tier: string | null): boolean => 'FREE' === tier?.toUpperCase();

const isTeam = (tier: string | null): boolean => 'TEAM' === tier?.toUpperCase();

const isEnterprise = (tier: string | null): boolean => 'ENTERPRISE' === tier?.toUpperCase();

const isTeamOrHigher = (tier: string | null): boolean => isTeam(tier) || isEnterprise(tier);

export default {
  TIER,
  isFree,
  isTeam,
  isEnterprise,
  isTeamOrHigher,
};
