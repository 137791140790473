import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAlertModal } from '@superb-ai/norwegian-forest';

import { NEW_PROJECT_DELETE_CATEGORY_GROUP } from '../../../../consts/ModalMessage';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import GroupItem from './GroupItem';
import GroupListDropZone from './GroupListDropZone';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: {
    width: '380px',
    height: '100%',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
}));

const GroupList: React.FC<any> = props => {
  const {
    imageCategory,
    imageCategory: { imageCategoryMap },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const listRef = useRef<HTMLDivElement>();
  const newProjectInfo = useNewProjectInfo();
  const { openModal, closeModal } = useAlertModal();
  const { root } = imageCategoryMap;
  const [isPressDelete, setIsPressDelete] = useState(false);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedCategoryGroup = () => {
    if (imageCategoryMap[newProjectInfo.selectedCategoryGroupId]) {
      if (imageCategoryMap[newProjectInfo.selectedCategoryGroupId].children.length === 0) {
        helper.deleteGroup(newProjectInfo);
      } else {
        openModal({
          ...NEW_PROJECT_DELETE_CATEGORY_GROUP({ t }),
          mainButton: {
            text: t('button.delete'),
            onClick: () => {
              helper.deleteGroup(newProjectInfo);
              closeModal();
            },
          },
          subButton: { text: t('button.cancel'), onClick: closeModal },
        });
      }
    }
  };

  useEffect(() => {
    if (!isPressDelete) return;
    deleteSelectedCategoryGroup();
    setIsPressDelete(false);
    // eslint-disable-next-line
  }, [isPressDelete]);

  const rootGroups = root.children.filter(
    (categoryId: string) =>
      imageCategoryMap[categoryId].parent === 'root' && imageCategoryMap[categoryId].children,
  );

  const handleClickCreateGroup = () => {
    helper.createGroup(
      newProjectInfo,
      'root',
      Object.keys(newProjectInfo.selectedCategories),
      false,
    );
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;

      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClickAdd = () => {
    helper.createGroup(newProjectInfo, 'root');
    scrollToBottom();
  };

  const handleClickDelete = () => {
    deleteSelectedCategoryGroup();
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer
        useHeader
        title={t('projectSettings.steps.categoryGroup')}
        disabledStrings={
          rootGroups.length === 0 ? [t('projectSettings.configuration.categoryGroupClickHere')] : []
        }
        handleClickDisabledBox={handleClickCreateGroup}
        handleClickAdd={handleClickAdd}
        handleClickDelete={handleClickDelete}
      >
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox}>
          <GroupListDropZone />

          {rootGroups.map((groupId: string, index: number) => (
            <GroupItem
              key={groupId}
              index={index}
              group={imageCategoryMap[groupId]}
              imageCategory={imageCategory}
              imageCategoryMap={imageCategoryMap}
              depth={0}
            />
          ))}
        </MUI.Box>
      </ListContainer>
    </MUI.Box>
  );
};

export default GroupList;
