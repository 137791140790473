import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';
import { Box, Icon, Typography } from '@superb-ai/norwegian-forest';

import { MemberData } from '../../../../types/memberTypes';
import MemberSettingMenu from '../../../elements/manageMembers/MemberSettingMenu';

type ProjectMemberMenuProps = {
  member: MemberData;
  isDeletable: boolean;
  isUserNotActive: boolean;
  onClickDelete: (member: MemberData) => void;
  onClickChangeRole?: (member: MemberData) => void;
};

const ProjectMemberMenu: React.FC<ProjectMemberMenuProps> = ({
  member,
  onClickDelete,
  isDeletable,
  isUserNotActive,
  onClickChangeRole,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickChangeRole = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onClickChangeRole) onClickChangeRole(member);
  };

  return (
    <MemberSettingMenu
      isDeletable={isDeletable}
      isUserNotActive={isUserNotActive}
      member={member}
      onClickDelete={onClickDelete}
      target="Project"
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    >
      <MenuItem onClick={handleClickChangeRole}>
        <Box mr={0.5} display="flex" alignItems="center">
          <Icon name="userSetting" />
        </Box>
        <Typography variant="body5">{t('projectMembers.dialogs.changeRole.title')}</Typography>
      </MenuItem>
    </MemberSettingMenu>
  );
};

export default ProjectMemberMenu;
