import React, { CSSProperties } from 'react';

const EmptyDonutCenterText = (
  text: string,
  radius: number,
  chartName: string,
  textColor = '#D4D4D4',
): SVGTextElement => {
  const textStyle = {
    textAnchor: 'middle',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    fill: textColor,
  } as CSSProperties;
  return (
    <text className={`donut-center-text-${chartName}`} style={textStyle}>
      <tspan key="donut-chart-center-text" x="0">
        {text}
      </tspan>
    </text>
  ) as unknown as SVGTextElement;
};

export default EmptyDonutCenterText;
