import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, IconButton } from '@superb-ai/norwegian-forest';

import styles from './ShowPropertiesButton.module.scss';

interface Props {
  handleShowProperties: (newState: boolean) => void;
  showProperties: boolean;
}

export const ShowPropertiesButton = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { showProperties, handleShowProperties } = props;
  const [isHovered, setIsHovered] = useState(false);

  const collapseBtnProps = {
    color: 'grey',
    size: 's',
    variant: 'shadow',
  } as const;

  const clickBtnProps = {
    color: 'grey',
    size: 's',
    iconPosition: 'left',
    onClick: () => handleShowProperties(!showProperties),
    variant: 'shadow',
  } as const;

  const changeIsHovered = (newState: boolean) => {
    setIsHovered(newState);
  };

  const currentIcon = showProperties ? 'chevronRight' : 'chevronLeft';

  return (
    <div className={styles.container}>
      <div
        className={`${styles.button} ${isHovered && styles.hovered} ${
          !showProperties && styles.showClasses
        }`}
      >
        {!isHovered && (
          <IconButton
            {...collapseBtnProps}
            onMouseOver={() => changeIsHovered(true)}
            icon={currentIcon}
          />
        )}
        {isHovered && (
          <Box width={showProperties ? '122px' : '107px'}>
            <Button
              {...clickBtnProps}
              IconAdornment={<Icon name={currentIcon} />}
              onMouseOut={() => changeIsHovered(false)}
            >
              {showProperties
                ? t('analytics.project.property.showProperties')
                : t('analytics.project.property.showClasses')}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
};
