import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
  },
  underline: {
    width: '100%',
    border: `solid 0.5px ${theme.palette.grey[400]}`,
    '&.hasError': {
      borderColor: theme.palette.primary.main,
    },
  },
  errorMessage: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  input: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#333',
  },
}));

// eslint-disable-next-line
const Input = (props: any): React.ReactElement => {
  //   <MUI.TextField
  //   variant="outlined"
  //   // multiline
  //   fullWidth
  //   inputRef={projectNameInputRef}
  //   errorMessages={projectNameErrorMessages}
  //   value={projectName}
  //   onChange={handleChange}
  //   placeholder="e.g., Driverless Car Project"
  // />
  const classes = useStyles();
  const { errorMessages, ...inputProps } = props;
  return (
    <MUI.Box className={classes.box}>
      <MUI.TextField {...inputProps} variant="outlined" fullWidth className={classes.input} />
      {/* <MUI.Box
        className={classnames(classes.underline, {
          hasError: !_.isEmpty(errorMessages),
        })}
      /> */}
      <MUI.Box mt="3px">
        {errorMessages &&
          errorMessages.map((errorMessage: string) => (
            <MUI.Typography key={errorMessage} className={classes.errorMessage}>
              * {errorMessage}
            </MUI.Typography>
          ))}
      </MUI.Box>
    </MUI.Box>
  );
};

export default Input;
