import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LinkTypography, Select } from '@superb-ai/ui';

import { openChatWidget } from '../../../../../../../utils/chatWidget';
import { DiagnosisPredictionCheck } from '../../../../../services/DiagnosisModelService';
import { Split } from '../../../../../types/evaluationTypes';
import { KeysToCamelCase } from '../../../../../types/typeUtils';

export default function TrainValSelect({
  splitIn,
  setSplitIn,
  disabled,
  predictionsCheck,
}: {
  splitIn: Split[];
  setSplitIn: (value: Split[]) => void;
  disabled?: boolean;
  predictionsCheck?: KeysToCamelCase<DiagnosisPredictionCheck>;
}) {
  const { t } = useTranslation();

  const value = splitIn.includes('TRAIN') ? 'val_train' : 'val';

  function handleChangeValue(value: string) {
    if (value === 'val') {
      setSplitIn(['VAL']);
    } else if (value === 'val_train') {
      setSplitIn(['VAL', 'TRAIN']);
    }
  }

  function getDisabledTooltipMessage() {
    if (predictionsCheck?.externalTrain || predictionsCheck?.superbTrain) return undefined;
    if (!predictionsCheck?.externalTrain)
      return (
        <div style={{ width: 200 }}>
          <Trans t={t} i18nKey="curate.diagnosis.filterPanel.externalTrainNotAvailable" />
        </div>
      );
    if (!predictionsCheck?.superbTrain)
      return (
        <div>
          <Trans
            t={t}
            i18nKey="curate.diagnosis.filterPanel.superbTrainNotAvailable"
            components={{ link1: <LinkTypography onClick={() => openChatWidget()} /> }}
          />
        </div>
      );
    return undefined;
  }

  const data = [
    {
      value: 'val',
      label: t('curate.diagnosis.filterPanel.validation'),
    },
    {
      value: 'val_train',
      label: t('curate.diagnosis.filterPanel.validationAndTrain'),
      tooltip: getDisabledTooltipMessage(),
      disabled: !(predictionsCheck?.externalTrain || predictionsCheck?.superbTrain),
    },
  ];
  return (
    <Select
      disabled={disabled}
      variant="text"
      data={data}
      value={value}
      hideOnClick
      onChangeValue={handleChangeValue}
      onClickDisclosure={e => e.stopPropagation()}
    />
  );
}
