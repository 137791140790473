import React from 'react';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import EditableText from '../../../elements/EditableText';
import parentHelper from '../helper';

// eslint-disable-next-line
const ImageCategoryItemEditableText = (props: any): React.ReactElement => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { imageCategory, index } = props;

  const parseValue = (newValue: string) => {
    return parentHelper.parseNameValue(t, newValue, 'Image category name', enqueueSnackbar);
  };

  const checkNameRule = async (newValue: string) => {
    const sameHierarchyNameList = newProjectInfo.imageCategories.map(
      (item: any) => item.imageCategoryMap.root.name,
    );

    return parentHelper.checkNameRule({
      t,
      type: 'Image category name',
      prevValue: imageCategory.imageCategoryMap.root.name,
      nextValue: newValue,
      sameHierarchyNameList,
      index,
      enqueueSnackbar,
    });
  };

  const editName = (value: string) => {
    const nextImageCategorise = cloneDeep(newProjectInfo.imageCategories);
    nextImageCategorise[index].imageCategoryMap.root.name = value;
    newProjectInfo.setImageCategories(nextImageCategorise);
  };

  return (
    <EditableText
      text={imageCategory.imageCategoryMap.root.name}
      maxWidth="150px"
      fontStyles={{
        fontSize: '13px',
        color: '#797979',
        fontWeight: 500,
      }}
      parseValue={parseValue}
      checkRule={checkNameRule}
      editText={editName}
      isMount={newProjectInfo.createState === 'IMAGE_CATEGORY'}
    />
  );
};

export default ImageCategoryItemEditableText;
