import { vars } from '@superb-ai/ui';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';
import { useColorModeContext } from '../../../../../../contexts/ColorModeContext';
import { useObjectClusterContext } from '../../../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import CroppedAnnotatedImage from '../../../../../../elements/AnnotatedImage/CroppedAnnotatedImage';

export default function ObjectImageCell({
  columnIndex,
  rowIndex,
  results,
  columns,
}: CellComponentProps<{}>) {
  const currentIndex = rowIndex * columns + columnIndex;
  const result = results[currentIndex];

  const { colorByMode } = useColorModeContext();
  const { classNameColorMap } = useObjectFilterContext();
  const { clusterColorMap: objectClusterColorMap } = useObjectClusterContext();

  if (!result?.imageThumbnailUrl) return <></>;
  return (
    <CroppedAnnotatedImage
      chipColor={
        colorByMode === 'cluster'
          ? (objectClusterColorMap || {})[result.clusterId] || vars.color['gray-250']
          : undefined
      }
      hasColoredOutline={colorByMode === 'cluster'}
      srcUrl={result.imageThumbnailUrl}
      alt={result.id}
      annotations={[
        {
          coordinate: result.annotationValue,
          roi: result.roi,
          id: result.id,
          type: result.annotationType,
          color: colorByMode === 'class' ? classNameColorMap[result.annotationClass] : undefined,
        },
      ]}
      originalImageSize={[
        result.originalImageSize?.width || 100,
        result.originalImageSize?.height || 100,
      ]}
      roi={result.roi}
    />
  );
}
