import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  Box,
  LoadingIndicator,
  TextEllipsis,
  Typography,
} from '@superb-ai/norwegian-forest';
import { groupBy } from 'lodash';

import { isOwnerOrAdminOrSystemAdmin, useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { daysAgo, formatRecentDistance } from '../../../../utils/date';
import LabelInterfaceUtils, {
  Category,
  LabelInterface,
  ObjectClass,
} from '../../../../utils/LabelInterfaceUtils';
import { Engine, fetchEngineInfos } from '../../aiAdvancedFeatures/autoLabel/settings/helper';
import { TypeIconLabel } from '../../aiAdvancedFeatures/autoLabel/settings/TypeIconLabel';

interface Props {
  labelInterface?: LabelInterface;
}

export function CurrentAutoLabelConfig({ labelInterface }: Props): JSX.Element {
  const { t } = useTranslation();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const canConfigure = isOwnerOrAdminOrSystemAdmin(authInfo);
  const [enginesMap, setEnginesMap] = useState<null | Map<string, Engine>>(null);

  useEffect(() => {
    if (!labelInterface) return;
    fetchEngineInfos({ t, labelInterface, authInfo, routeInfo }).then(setEnginesMap);
  }, [labelInterface, t]);

  if (!canConfigure) {
    return <></>;
  }

  if (!labelInterface || !enginesMap) {
    return (
      <Box display="flex" justifyContent="center">
        <LoadingIndicator size="20px" />
      </Box>
    );
  }

  const categories: Category[] = labelInterface?.categorization?.properties ?? [];
  const objectClasses: ObjectClass[] = LabelInterfaceUtils.getObjectClasses(labelInterface);

  const classesWithAi = objectClasses.filter(cls => cls.aiClassMap?.[0]?.engineId);
  const classesByEngine = Object.entries(
    groupBy(classesWithAi, cls => cls.aiClassMap?.[0]?.engineId),
  );
  const categoriesWithAi = categories.filter(cat => cat.aiProperty?.engineId);
  const categoriesByEngine = Object.entries(
    groupBy(categoriesWithAi, cat => cat.aiProperty?.engineId),
  );

  const _itemsByEngine: Record<string, (Category | ObjectClass)[]> = {};
  for (const list of [classesByEngine, categoriesByEngine]) {
    for (const [engineId, items] of list) {
      _itemsByEngine[engineId] = [...(_itemsByEngine[engineId] ?? []), ...items];
    }
  }
  const itemsByEngine = Object.entries(_itemsByEngine);
  // Sort by createdAt (pretrained items to the end).
  itemsByEngine.sort(([engineIdA], [engineIdB]) => {
    const engineADate = (enginesMap.get(engineIdA) as any)?.createdAt;
    const engineBDate = (enginesMap.get(engineIdB) as any)?.createdAt;
    return engineBDate?.localeCompare(engineADate) ?? -1;
  });

  return (
    <Box display="flex" flexDirection="column">
      {itemsByEngine.map(([engineId, items]) => {
        const engine = enginesMap.get(engineId);
        return (
          <Accordion
            key={engineId}
            summary={
              <Box
                display="flex"
                height="12px"
                flex={1}
                minWidth={0}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box minWidth={0} mr={1}>
                  <TextEllipsis text={engine?.name ?? 'Unknown Auto-Label'} />
                </Box>
                {(engine as any)?.createdAt && (
                  <Typography variant="body4" themedColor={['grey', 400]}>
                    {t('users.table.created')}{' '}
                    {formatRecentDistance((engine as any).createdAt, daysAgo(7))}
                  </Typography>
                )}
              </Box>
            }
            summaryVariant="block"
            summaryBoxProps={{ px: 2 }}
          >
            <Box display="flex" flexWrap="wrap" px={2} gap="8px" mt={-0.5} pb={1}>
              {items.map((item, idx) => {
                return (
                  <TypeIconLabel
                    key={idx}
                    type={(item as any).annotationType ?? (item as any).type ?? 'unknown'}
                    label={item.name}
                  />
                );
              })}
            </Box>
          </Accordion>
        );
      })}
    </Box>
  );
}
