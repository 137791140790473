import { Trans, useTranslation } from 'react-i18next';

import { LoadingIndicator } from '@superb-ai/norwegian-forest';
import { Box, LinkTypography, Typography } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import TotpCodeInput from '../TotpCodeInput';
import { TotpSecret } from '../TotpSecret';

function getOtpLabel(authInfo: ReturnType<typeof useAuthInfo>) {
  return `${authInfo.accountName} - ${authInfo.email}`;
}

interface Props {
  step: 0 | 1;
  secret: string | null;
  onCodeChange(code: string): void;
  code: string;
  layout: 'dialog' | 'full';
}

export function TotpAssociateSteps({ step, secret, code, onCodeChange, layout }: Props) {
  const authInfo = useAuthInfo();
  const { t } = useTranslation();

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        textAlign={layout === 'full' ? 'center' : undefined}
      >
        <Typography variant="m-regular">
          <Trans t={t} i18nKey="settings.security.mfaSetupDialog.text">
            To set-up Two-factor Authentication, you need an OTP app on your phone, such as Google
            Authenticator (
            <LinkTypography
              as="a"
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              Google Play Store
            </LinkTypography>
            ,{' '}
            <LinkTypography
              as="a"
              color="secondary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            >
              iOS AppStore
            </LinkTypography>
            ).
          </Trans>
        </Typography>
      </Box>
      <Box
        style={{ minHeight: '264px' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {!secret && <LoadingIndicator size="40px" />}
        {secret && step === 0 && (
          <>
            <Box mb={3}>
              <TotpSecret secret={secret} label={getOtpLabel(authInfo)} />
            </Box>
            <Typography variant="m-regular" textAlign="center">
              <Trans t={t} i18nKey="settings.security.mfaSetupDialog.addToOtpApp">
                Add your account to your OTP app
                <br />
                by scanning the QR code or entering the secret code manually.
              </Trans>
            </Typography>
          </>
        )}
        {step === 1 && (
          <>
            <Box mb={4} display="flex" justifyContent="center">
              <TotpCodeInput value={code} onValueChange={onCodeChange} />
            </Box>
            <Typography variant="m-regular" textAlign="center">
              {t('settings.security.mfaSetupDialog.enterCode')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}
