import {
  StatsHistogramAPIResponse,
  StatsPercentiles,
} from '../chartContainers/labelingTime/interface';

export const getLabelingTimeAtPercentile = (
  stats: StatsHistogramAPIResponse,
  percentileOption: string,
): number => {
  return stats?.percentiles && stats?.percentiles[percentileOption as keyof StatsPercentiles];
};
