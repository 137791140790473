import AuthService from './AuthService';
import { ApiCall } from './types';

type UpdateProjectWebhooksArgs = { projectId: string; type: string; url: string };
const updateProjectWebhooks: ApiCall<UpdateProjectWebhooksArgs, any> = async args => {
  const { projectId, type, url, isGuest, urlInfo } = args;
  // todo (mjlee): set web hook url, change params
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `notifications/webhooks/tenants/${urlInfo.accountName}/projects`,
    data: {
      projectId,
      type,
      url,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  const result: Record<string, any> = {};
  if (res?.status === 200 && res?.data?.message === 'success') {
    const { data } = res.data;
    result[data.type] = data;
  }
  return result;
};

const getProjectsWebhooks: ApiCall<unknown, any> = async args => {
  const { isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/notifications/webhooks/tenants/${urlInfo.accountName}/projects/`,
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

const deleteProjectWebhooks: ApiCall<{ type: string; projectId: string }, any> = async args => {
  const { type, projectId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `notifications/webhooks/tenants/${urlInfo.accountName}/projects/${projectId}/type/${type}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });

  return res;
};

type SendTestMessageArgs = { projectName: string; projectId: string; type: string };
const sendTestMessage: ApiCall<SendTestMessageArgs, any> = async args => {
  const { projectName, projectId, type, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `notifications/webhooks/tenants/${
      urlInfo.accountName
    }/projects/${projectId}/name/${encodeURIComponent(projectName)}/type/${type}/test/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res;
};

export default {
  updateProjectWebhooks,
  getProjectsWebhooks,
  sendTestMessage,
  deleteProjectWebhooks,
};
