import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Unavailable } from '@superb-ai/icons';
import { Box, Checkbox, Icon, Typography, vars } from '@superb-ai/ui';
import { CellComponentProps } from '../../../../../../../components/elements/windowedImageGrid/types';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';

export default function ImageCell({
  columnIndex,
  rowIndex,
  results,
  columns,
  infoAreaHeight = 0,
  aspectRatio,
}: CellComponentProps<{ infoAreaHeight: number }>) {
  const { t } = useTranslation();
  const currentIndex = rowIndex * columns + columnIndex;
  const result = results[currentIndex];
  const [hover, setHover] = useState(false);
  const [imageSize, setImageSize] = useState<[number, number]>();
  const { selectedLeafIds, handleSelectSuperCluster, handleSelectOrDeselectLeafCluster } =
    useImageFilterContext();
  const { setSelectedAllData, setSelectedData, setDeselectedData } = useActionContext();

  const handleToggleClusterCenter = useCallback(
    (centerId: string | null) => {
      setSelectedAllData(false);
      setSelectedData([]);
      setDeselectedData([]);
      if (centerId === null) {
        handleSelectSuperCluster(null);
      } else {
        handleSelectOrDeselectLeafCluster(centerId);
      }
    },
    [
      setSelectedAllData,
      setSelectedData,
      setDeselectedData,
      handleSelectSuperCluster,
      handleSelectOrDeselectLeafCluster,
    ],
  );

  useEffect(() => {
    if (!result?.thumbnailUrl) return;
    const img = new Image();
    img.src = result.thumbnailUrl;
    img.onload = () => setImageSize([img.naturalWidth, img.naturalHeight]);
  }, [result?.thumbnailUrl]);

  const handleMouseEnter = useCallback(() => setHover(true), []);
  const handleMouseLeave = useCallback(() => setHover(false), []);

  if (!result) return null;

  const isSelected =
    result.id !== null ? selectedLeafIds.includes(result.id) : selectedLeafIds.includes(null);

  const imageStyle = imageSize
    ? imageSize[0] / imageSize[1] < 1 / aspectRatio
      ? { height: '100%' }
      : { width: '100%' }
    : {};

  return (
    <Box
      width="100%"
      height="100%"
      backgroundColor="white"
      border="1px solid"
      borderColor={isSelected ? 'primary' : 'gray-200'}
      borderRadius="4px"
      position="relative"
      overflow="hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray-150"
        style={{ height: `calc(100% - ${infoAreaHeight}px)` }}
      >
        {result.id === null ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={0.5}
            color="gray-300"
          >
            <Icon size="32px" icon={Unavailable} />
          </Box>
        ) : (
          imageSize && <img alt={result.image_id} src={result.thumbnailUrl} style={imageStyle} />
        )}
      </Box>
      <Box
        position="absolute"
        left="0"
        top="0"
        bottom="0"
        right="0"
        backgroundColor="gray-opacity-300"
        style={{
          visibility: hover || isSelected ? 'visible' : 'hidden',
          ...(isSelected && { outline: `2px solid ${vars.color.primary}`, outlineOffset: '-2px' }),
        }}
        onClick={() => handleToggleClusterCenter(result.id)}
        cursor="pointer"
      />
      <Box position="absolute" style={{ top: 0, left: 0 }} mr="auto">
        <Checkbox value={isSelected} onClick={() => handleToggleClusterCenter(result.id)} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={0.5}
        style={{ height: infoAreaHeight }}
      >
        <Typography variant="s-strong" color="gray-300">
          {result.id === null
            ? t('curate.filter.unprocessed')
            : t('curate.imageFilter.similarImages')}
        </Typography>
        <Typography
          variant="s-strong"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {(result.size || 0).toLocaleString('en')}
        </Typography>
      </Box>
    </Box>
  );
}
