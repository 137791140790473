import React from 'react';

import { Box, Link, Tooltip } from '@superb-ai/norwegian-forest';

import Emoji from './Emoji';

type Props = React.ComponentProps<typeof Tooltip> & {
  variant?: 'limit' | 'public' | 'not_supported';
};

const UpgradePlanTooltip: React.FC<Props> = props => {
  const { anchorEl, variant = 'limit', ...popperProps } = props;

  // Current plan page is outdated.
  // const handleClickUpgradePlan = () => {
  //   history.push(`/${urlMatchInfo.accountName}/plan`);
  // };

  const getMessage = () => {
    if (variant === 'public') {
      return 'Private projects are not allowed on your plan.';
    }
    if (variant === 'not_supported') {
      return 'This feature is not supported in your plan.';
    }
    return 'You’ve reached your usage limit.';
  };

  return (
    <Tooltip {...popperProps} anchorEl={anchorEl}>
      {getMessage()}
      <br />
      <Box>
        <Emoji symbol="👉" />
        <Box display="inline" ml={0.5}>
          <Link
            href="https://superb-ai.com/pricing"
            underlined
            external
            target="_blank"
            rel="noopener noreferrer"
          >
            Plans Page
          </Link>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default UpgradePlanTooltip;
