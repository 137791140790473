import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowRight } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import Title from '../Title';
import ClassPropertyList from './ClassPropertyList';
import ObjectClassList from './ObjectClassList';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    height: '100%',
  },
  innerBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    height: '30px',
    alignItems: 'flex-start',
    display: 'flex',
  },
  configureAutoLabelButton: {
    marginLeft: '152px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#5a7bff',
  },
  section: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '5px',
  },
  arrowBox: {
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Layout = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();

  const selectedObjectClass = newProjectInfo.objectClasses[newProjectInfo.selectedObjectClassIndex]
    ? newProjectInfo.objectClasses[newProjectInfo.selectedObjectClassIndex]
    : null;

  return (
    <MUI.Box className={classes.box}>
      <MUI.Box width="50px" />
      <MUI.Box className={classes.innerBox}>
        <MUI.Box className={classes.header}>
          <Title
            title={
              WorkappUnion.isImageSequence(newProjectInfo.selectedDataType)
                ? t('projectSettings.steps.objectTracking')
                : t('projectSettings.steps.objectDetection')
            }
          />
        </MUI.Box>
        <MUI.Box mb="30px" />
        <MUI.Box className={classes.section}>
          <ObjectClassList />
          {selectedObjectClass ? (
            <>
              <MUI.Box className={classes.arrowBox}>
                <Icon icon={ArrowRight} size={24} color="gray" />
              </MUI.Box>
              <ClassPropertyList selectedObjectClass={selectedObjectClass} />
            </>
          ) : null}
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
};

export default Layout;
