import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LinkExternal } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import TextEllipsisTooltip from '../../../elements/TextEllipsisTooltip';

const useStyles = makeStyles(() => ({
  editLabelButton: {
    fontSize: '11px',
    color: '#909090',
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    '& .MuiSvgIcon-root': {
      width: '12px',
      height: '12px',
      marginRight: '5px',
      marginBottom: '1px',
    },
  },
}));

const GuestThumbnailViewDataKey = (props: { filename: string }) => {
  const { filename } = props;
  return (
    <TextEllipsisTooltip text={filename} height="50px" width="180px">
      <MUI.Typography>{filename}</MUI.Typography>
    </TextEllipsisTooltip>
  );
};

interface DataKeyProps {
  filename: string;
  isHover: boolean;
  onClickEditLabel: (event: React.MouseEvent) => void;
  additionalName?: string;
}

const ThumbnailViewDataKey: React.FC<DataKeyProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { filename, isHover, onClickEditLabel, additionalName } = props;
  const authInfo = useAuthInfo();

  if (authInfo.isGuest) {
    return <GuestThumbnailViewDataKey filename={filename} />;
  }

  const showAdditionalName = !UserRoleUnion.isCollaborator(authInfo.role);

  return (
    <MUI.Box position="relative">
      <TextEllipsisTooltip text={filename}>
        <MUI.Typography>
          {filename} {showAdditionalName && additionalName}
        </MUI.Typography>
      </TextEllipsisTooltip>
      {isHover && (
        <MUI.Button
          variant="contained"
          color="inherit"
          size="small"
          className={classes.editLabelButton}
          onClick={onClickEditLabel}
        >
          <Box display="flex" alignItems="center" mr={0.5}>
            <Icon icon={LinkExternal} />
          </Box>
          {t('labels.editLabel')}
        </MUI.Button>
      )}
    </MUI.Box>
  );
};

export default ThumbnailViewDataKey;
