import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Popper } from '@superb-ai/norwegian-forest';
import { AsyncCombobox, Box, IconButton, Typography } from '@superb-ai/ui';

import { getNamingRuleErrorMessage } from '../../../configs/NamingRulesConfig';
import { SelectedSliceOption } from '../contexts/UploadContext';
import { useSlicesInfiniteQuery } from '../queries/sliceQueries';
import { Clear } from '@superb-ai/icons';

export function SliceAsyncCombobox({
  datasetId,
  isCreatable,
  selectedSlice,
  setSelectedSlice,
}: {
  datasetId: string;
  isCreatable?: boolean;
  selectedSlice?: SelectedSliceOption;
  setSelectedSlice: Dispatch<SetStateAction<SelectedSliceOption | undefined>>;
}) {
  const { t } = useTranslation();
  const [sliceSearchInput, setSliceSearchInput] = useState('');
  const [createdSliceList, setCreatedSliceList] = useState<
    { label: string; value: string; isNew: boolean }[]
  >([]);

  const pageSize = 10;
  const {
    data: sliceData,
    hasNextPage: hasSliceNextPage,
    fetchNextPage: fetchSliceNextPage,
    isLoading: isLoadingSlice,
  } = useSlicesInfiniteQuery({
    datasetId,
    size: pageSize,
    nameContains: sliceSearchInput,
    enabled: !!datasetId,
  });

  const filteredExistingSliceOptions = useMemo(
    () =>
      sliceData?.pages.flatMap(page =>
        page.results.map(slice => ({ value: slice.id, label: slice.name, isNew: false })),
      ) || [],
    [sliceData, sliceSearchInput],
  );

  const filteredNewSliceOptions = useMemo(
    () =>
      createdSliceList.filter(slice =>
        slice.value.toLowerCase().includes((sliceSearchInput || '').toLowerCase()),
      ),
    [createdSliceList, sliceSearchInput],
  );

  const sliceOptions = useMemo(
    () => [...filteredExistingSliceOptions, ...filteredNewSliceOptions],
    [sliceData, createdSliceList, sliceSearchInput],
  );

  useEffect(() => {
    setSliceSearchInput('');
    setCreatedSliceList([]);
  }, [datasetId]);

  const sliceNameInvalidReasons = isCreatable
    ? getNamingRuleErrorMessage({
        str: sliceSearchInput,
        target: 'slice',
      })
    : [];

  return (
    <Popper
      open={sliceNameInvalidReasons.length > 0}
      placement="top"
      offset={0}
      sameWidth
      anchorEl={
        <Box display="flex">
          <AsyncCombobox
            disabled={!datasetId}
            isLoading={isLoadingSlice}
            data={sliceOptions}
            value={selectedSlice?.id}
            placeholder={t('labels.sendToCurate.enterSliceName')}
            loadingText={t('button.loading')}
            hasNextPage={hasSliceNextPage}
            fetchNextPage={fetchSliceNextPage}
            onChangeValue={(nextValue: string) => {
              const existingOption = filteredExistingSliceOptions?.find(
                option => option.value === nextValue,
              );
              const newOption = filteredNewSliceOptions?.find(option => option.value === nextValue);
              setSelectedSlice({
                id: nextValue,
                name: existingOption?.label || newOption?.label || nextValue,
                isNew: existingOption ? false : true,
              });
            }}
            onCreate={(value: string) => {
              setCreatedSliceList(prev => [...prev, { label: value, value, isNew: true }]);
            }}
            onChangeSearchInput={(value: string) => {
              setSliceSearchInput(value);
            }}
            onBlurSearchInput={() => {
              setSliceSearchInput('');
            }}
            searchInput={sliceSearchInput}
            multiple={false}
            creatable={isCreatable && sliceNameInvalidReasons.length === 0}
            createMessage={t('curate.datasets.cloudUpload.uploadToNewSlice')}
            suffix={
              selectedSlice && (
                <IconButton
                  variant="text"
                  size="s"
                  icon={Clear}
                  color="gray"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedSlice(undefined);
                    setSliceSearchInput('');
                  }}
                />
              )
            }
          />
        </Box>
      }
    >
      <Box
        mb={0.25}
        p={1}
        border="1px solid"
        borderColor="primary-400"
        backgroundColor="primary-100"
        borderRadius="2px"
        display="flex"
        flexDirection="column"
        gap={0.25}
      >
        {sliceNameInvalidReasons.map(reason => (
          <Typography key={reason} variant="m-regular" color="primary">
            {reason}
          </Typography>
        ))}
      </Box>
    </Popper>
  );
}
