import { useHistory, useParams } from 'react-router';

import { Copy, QueryBuilder } from '@superb-ai/icons';
import { Accordion } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';

import { DatasetPool } from '../../../../types/routeTypes';

export default function AppliedQueryTabs({ query }: { query: string }) {
  const history = useHistory();
  const { accountName, datasetId, datasetPool } = useParams<{
    accountName: string;
    datasetId: string;
    datasetPool: DatasetPool;
  }>();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box p={1} px={2} ml={0} mr={0} style={{ background: '#F5F5F5' }}>
      <Accordion summary="Applied Query">
        <Box display="flex" justifyContent="space-between" pb={4}>
          <Box
            display="flex"
            mt={0.5}
            alignItems="center"
            style={{ maxWidth: 'calc(100% - 240px)' }}
          >
            <Typography variant="m-regular">{query}</Typography>
          </Box>
          <Box display="flex" ml={3}>
            <Box mr={1}>
              <Button
                variant="soft-fill"
                size="s"
                color="gray-opacity"
                onClick={() => {
                  window.navigator.clipboard.writeText(query).then(() => {
                    enqueueSnackbar('Completed copy', {
                      variant: 'success',
                      anchorOrigin: { horizontal: 'right', vertical: 'top' },
                    });
                  });
                }}
              >
                <Icon icon={Copy} />
                <Typography variant="s-regular" color="gray-400">
                  Copy
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                variant="soft-fill"
                size="s"
                color="gray-opacity"
                onClick={() =>
                  history.push(
                    `/${accountName}/curate/${datasetPool}/${datasetId}/explore?query=${query}`,
                  )
                }
              >
                <Icon icon={QueryBuilder} />
                <Typography variant="s-regular" color="gray-400">
                  Apply Query to Dataset
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Accordion>
    </Box>
  );
}
