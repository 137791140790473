import { z } from 'zod';

import { useSearchParamsObj } from '../../../utils/router-utils';
import { PurposeType } from '../services/types';

type FromType = PurposeType;

export const fromQuery = {
  genAI: 'generation',
  recognitionAI: 'recognition',
};

export const TrainQueryKeyword = {
  Public: 'public-model',
  Trained: 'trained-model',
  Slices: 'slice',
  Dataset: 'dataset',
  From: 'from',
  DeployedModel: 'deployed-model',
  Split: 'split',
};

const stringOrNull = z.string().nullable().default(null);
const commaSeparatedStringList = z
  .string()
  .nullable()
  .transform(s => s?.split('*') ?? null);

export function useTrainUrlParams() {
  const searchParams = useSearchParamsObj();
  const publicModelId = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.Public));
  const trainedModelId = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.Trained));
  const sliceNames = commaSeparatedStringList.parse(searchParams?.get(TrainQueryKeyword.Slices));
  const datasetId = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.Dataset));
  const from = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.From)) as FromType | null;
  const deployedModelId = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.DeployedModel));
  const split = stringOrNull.parse(searchParams?.get(TrainQueryKeyword.Split));

  return {
    publicModelId,
    trainedModelId,
    sliceNames,
    datasetId,
    from,
    deployedModelId,
    split,
  };
}
