import React from 'react';
import { TFunction, Trans } from 'react-i18next';

import { Typography } from '@superb-ai/norwegian-forest';
import { LinkTypography } from '@superb-ai/ui';

import { getUserManualUrl } from '../../../../../consts/DocsLink';

function AmazonS3(t: TFunction, language: string) {
  const { AMAZON_S3_INTEGRATIONS_MANUAL } = getUserManualUrl(language);
  return {
    title: 'Amazon S3',
    docsLink: AMAZON_S3_INTEGRATIONS_MANUAL,
    summary: <Trans t={t} i18nKey="integration.s3.summary" />,
    description: (
      <Typography variant="body3">
        <Trans t={t} i18nKey="integration.s3.description" />
      </Typography>
    ),
    logo: <img src="/static/image/amazon_s3.png" width="100%" alt="s3 logo" />,
    addUrl: '/account/integrations/S3/add',
    tabs: {
      before: (
        <Typography variant="body3">
          <strong>{t('integration.s3.tabs.before.1')}</strong>
          <br />
          <Trans t={t} i18nKey="integration.s3.tabs.before.1-1" />
          <br />
          <Trans t={t} i18nKey="integration.s3.tabs.before.1-2" />
          <br />
          <br />
          <strong>{t('integration.s3.tabs.before.2')}</strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.s3.tabs.before.2-1"
            components={{
              link1: (
                <LinkTypography
                  href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/cors.html"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.s3.tabs.before.2-2"
            components={{
              link1: (
                <LinkTypography
                  as="a"
                  href="https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_create.html"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              link2: (
                <LinkTypography
                  as="a"
                  href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/access_policies.html"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          {/* components={{
              link1: (
                <LinkTypography
                  as="a"
                  href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/id_users_create.html"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              link2: (
                <LinkTypography
                  as="a"
                  href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/access_policies.html"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.s3.tabs.before.2-2" */}

          <br />
          <Trans t={t} i18nKey="integration.s3.tabs.before.2-3" />
          <br />
          <br />
          <strong>{t('integration.needHelp')} </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  href={AMAZON_S3_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
      troubleshooting: (
        <Typography variant="body3">
          <strong>{t('integration.s3.tabs.troubleshooting.1')} </strong>
          <br />
          {t('integration.s3.tabs.troubleshooting.1-1')}
          <br />
          <Trans
            t={t}
            i18nKey="integration.s3.tabs.troubleshooting.1-2"
            components={{
              link1: (
                <LinkTypography
                  href="https://docs.aws.amazon.com/en_en/AmazonS3/latest/dev/cors.html"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <Trans
            t={t}
            i18nKey="integration.s3.tabs.troubleshooting.1-3"
            components={{
              link1: (
                <LinkTypography
                  href="https://docs.aws.amazon.com/IAM/latest/UserGuide/tutorial_cross-account-with-roles.html"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
          <br />
          <br />
          <strong>{t('integration.needHelp')} </strong>
          <br />
          <Trans
            t={t}
            i18nKey="integration.needHelpLink"
            components={{
              link1: (
                <LinkTypography
                  href={AMAZON_S3_INTEGRATIONS_MANUAL}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      ),
    },
  };
}

export default AmazonS3;
