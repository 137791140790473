import React from 'react';

import { AppRouteLayoutProps, AppRoutes, PageWithMenu } from '../../../menu';
import { ProjectMenu } from './Menu';
import Providers from './Providers';

export function Layout({ menuItem }: AppRouteLayoutProps): JSX.Element {
  return (
    <Providers>
      <PageWithMenu menu={<ProjectMenu menuItem={menuItem} />}>
        <AppRoutes items={menuItem.children ?? []} />
      </PageWithMenu>
    </Providers>
  );
}
