import React, { useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

interface Props {
  circularProps?: React.ComponentProps<typeof CircularProgress>;
  boxProps?: React.ComponentProps<typeof Box>;
  delay?: number;
}

const CircularProgressBox: React.FC<Props> = ({ circularProps, boxProps, delay }) => {
  const [visibility, setVisibility] = useState(!delay);

  useEffect(() => {
    let isMounted = true;
    if (delay) {
      setTimeout(() => {
        if (!isMounted) return;
        setVisibility(true);
      }, delay);
    }
    return () => {
      isMounted = false;
    };
  }, [delay]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
      style={{ visibility: visibility ? 'visible' : 'hidden' }}
    >
      <CircularProgress {...circularProps} />
    </Box>
  );
};
export default CircularProgressBox;
