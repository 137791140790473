import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import Metering from './Metering';
import Plan from './Plan';

const useStyle = makeStyles(theme => ({
  title: {
    marginBottom: '24px',
  },
  mainText: {
    fontWeight: 500,
    fontSize: '16px',
  },
  mainTextLight: {
    fontWeight: 300,
    fontSize: '16px',
  },
  smallText: {
    fontWeight: 500,
    fontSize: '14px',
  },
  smallTextLight: {
    fontWeight: 300,
    fontSize: '14px',
    color: '#333',
  },
  paper: {
    padding: '20px 30px',
    marginBottom: '12px',
  },
  button: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonOutlined: {
    fontWeight: 500,
    fontSize: '16px',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '5px 16px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  buttonGrey: {
    fontWeight: 500,
    fontSize: '16px',
    backgroundColor: theme.palette.grey[100],
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  buttonGreyOutlined: {
    fontWeight: 500,
    fontSize: '16px',
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '5px 16px',
    boxSizing: 'border-box',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

const Layout: React.FC = () => {
  const classes = useStyle();

  return (
    <>
      <MUI.Box mb="40px">
        <Plan />
        <Metering className={classes} />
      </MUI.Box>
    </>
  );
};

export default Layout;
