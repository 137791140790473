import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Link, Modal } from '@superb-ai/norwegian-forest';
import { Box, Paragraph, Typography } from '@superb-ai/ui';
import { orderBy, startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import ProjectConst from '../../../../consts/ProjectConst';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useExportsAnalyticsInfo } from '../../../../contexts/ExportsAnalyticsContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useApiDefaultParams } from '../../../../hooks/ApiParamsHook';
import EventTracker from '../../../../analyticsTracker';
import ExportsAnalyticsService from '../../../../services/ExportsAnalyticsService';
import { HistoryType } from '../../../../types/exportTypes';
import ReportStepper from './stepper/ReportStepper';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  histories: HistoryType[];
};

const supportedAnnotationTypes = ['BOX', 'POLYGON'] as const;

const GenerateReportModal = ({ isModalOpen, setIsModalOpen, histories }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authInfo = useAuthInfo();
  const commandContext = useLabelCommandContext();

  const {
    confirmation,
    setConfirmation,
    boxValue,
    polygonValue,
    reportName,
    initialize,
    isStepsCompleted,
    selectedCompareExportId,
    selectedBaseExportId,
    ioUOption,
    recommededOption,
    selectedBaseExport,
    selectedCompareExport,
  } = useExportsAnalyticsInfo();
  const defaultParams = useApiDefaultParams({
    origin: 'components/pages/analytics/exportsAnalytics/GenerateReportModal.jsx',
  });
  const handleGenerateAction = async () => {
    const queryParams = {
      name: reportName,
      iou: {
        bbox: boxValue,
        polygon: polygonValue,
      },
      ref_export_id: selectedCompareExportId,
      query_export_id: selectedBaseExportId,
    };

    try {
      const response = await ExportsAnalyticsService.createReport({
        ...defaultParams,
        queryParams,
      });
      commandContext.registerCommand(response.id);
      // const durationBetweenExports = Math.trunc(
      //   (+parseDate(selectedCompareExport.createdAt) - +parseDate(selectedBaseExport.createdAt)) /
      //     1000,
      // );
      EventTracker.exportCompareReportRequested({
        accountId: authInfo?.accountName ?? '',
        // durationBetweenExports,
        labelCountBase: selectedBaseExport.downloadLabelCount,
        labelCountCompare: selectedCompareExport.downloadLabelCount,
      });
    } catch (error) {
      enqueueSnackbar('Fail to create report', { variant: 'error' });
    }
    setIsModalOpen(false);
    initialize();
  };

  return (
    <Modal
      open={isModalOpen}
      close={{
        onClose: () => {
          setConfirmation('close');
        },
        hasCloseButton: true,
      }}
      title={
        <Box
          style={{ flex: 1 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mr={1}
        >
          <Typography variant="h2" color="primary-400">
            {t('analytics.exportsAnalytics.modal.title')}
          </Typography>
          <Link
            themedColor="secondary"
            variant="body3"
            external
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('docs.documentation')}
          </Link>
        </Box>
      }
      mainButton={{
        text: t('button.generate'),
        onClick: handleGenerateAction,
        disabled:
          !reportName.length ||
          Number.isNaN(boxValue) ||
          Number.isNaN(polygonValue) ||
          !isStepsCompleted,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setConfirmation('cancel');
        },
      }}
      confirmation={{
        open: !!confirmation,
        content: (
          <Box style={{ height: '44px' }}>
            <Typography variant="m-medium">
              <Trans t={t} i18nKey="data.dialogs.upload.areYouSureToQuit" />
            </Typography>
          </Box>
        ),
        confirmButtonText: t('button.ok'),
        cancelButtonText: t('button.cancel'),
        onConfirm: () => {
          setIsModalOpen(false);
          setConfirmation(undefined);
          initialize();
        },
        onCancel: () => {
          setConfirmation(undefined);
        },
      }}
    >
      <Box px={4} pb={4} style={{ width: '824px' }}>
        <Paragraph mb={3} variant="m-regular" display="flex" gap={1}>
          <span>{t('analytics.exportsAnalytics.reportsSupportTheseTypes')}</span>
          <Box display="flex" alignItems="center" gap={1}>
            {supportedAnnotationTypes.map(type => (
              <Box key={type} display="flex" alignItems="center" gap={0.5}>
                <Typography color="primary" display="flex">
                  {ProjectConst.annotationIcons[type]}
                </Typography>
                {startCase(type.toLowerCase())}
              </Box>
            ))}
          </Box>
        </Paragraph>
        <ReportStepper data={orderBy(histories, 'createdAt', 'asc')} />
      </Box>
    </Modal>
  );
};

export default GenerateReportModal;
