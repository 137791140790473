import { useTranslation } from 'react-i18next';

import { AlertDialog, useDialogState } from '@superb-ai/ui';

type DialogStateReturn = ReturnType<typeof useDialogState>;

export default function IpWhitelistActivateDialog({
  dialogState,
  deactivate,
  cancel,
}: {
  dialogState: DialogStateReturn;
  deactivate: () => void;
  cancel: () => void;
}) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      state={dialogState}
      title={t('settings.security.ipWhitelistDeactivateTitle')}
      mainButton={{
        label: t('settings.security.ipWhitelistDeactivateConfirm'),
        onClick() {
          dialogState.hide();
          deactivate();
        },
      }}
      subButton={{
        label: t('settings.security.ipWhitelistDeactivateCancel'),
        onClick() {
          dialogState.hide();
          cancel();
        },
      }}
    >
      {t('settings.security.ipWhitelistDeactivateText')}
    </AlertDialog>
  );
}
