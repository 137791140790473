import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { useProjectPagination } from '../../../hooks/ProjectPaginationHook';
import { useSearchParams } from '../../../utils/router-utils';
import TablePaginationAction from './TablePaginationAction';

type Props = {
  totalCount: number;
};

const TablePagination: React.FC<Props> = ({ totalCount }) => {
  const { t } = useTranslation();
  const urlSearchParams = useSearchParams();
  const params = useProjectPagination();
  const routeInfo = useRouteInfo();

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    const searchParams = new URLSearchParams([
      ...urlSearchParams.filter(([key]) => key !== 'page'),
      ['page', `${newPage + 1}`],
    ]);
    routeInfo.history.push(`?${searchParams.toString()}`);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = event.target.value;
    const searchParams = new URLSearchParams([
      ...urlSearchParams.filter(([key]) => key !== 'page' && key !== 'pageSize'),
      ['pageSize', `${rowsPerPage}`],
      ['page', '1'],
    ]);
    routeInfo.history.push(`?${searchParams.toString()}`);
  };

  if (totalCount === 0) return <></>;

  return (
    <MUI.TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={totalCount}
      rowsPerPage={params.pageSize}
      page={params.page - 1}
      labelDisplayedRows={() => ''}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationAction}
      labelRowsPerPage={t('labels.rowsPerPage')}
      style={{
        paddingRight: '60px',
      }}
    />
  );
};

export default TablePagination;
