import { Box, Typography } from '@superb-ai/ui';

import NumberChip from './NumberChip';

type Content = {
  title: string;
  description: string | JSX.Element;
  action?: JSX.Element;
};

export default function TutorialHeader({ contents }: { contents: Content[] }) {
  return (
    <Box backgroundColor="gray-100" justifyContent="center" display="flex">
      <Box
        display="grid"
        backgroundColor="gray-100"
        justifyContent="space-between"
        style={{
          gridTemplateColumns: `repeat(${contents.length}, 1fr)`,
          maxWidth: '1324px',
        }}
      >
        {contents.map((content, index) => (
          <Box
            key={content.title}
            px={2}
            my={3}
            borderRight={index < contents.length - 1 ? '1px solid' : undefined}
            borderColor="gray-200"
            display="grid"
            gap={1}
            style={{ gridTemplateRows: 'auto 1fr auto' }}
          >
            <Box display="flex" gap={1}>
              <NumberChip number={index + 1} />
              <Typography variant="m-strong">{content.title}</Typography>
            </Box>
            <Typography display="block" variant="m-regular">
              {content.description}
            </Typography>
            {content.action}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
