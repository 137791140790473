import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { i18n } from 'next-i18next';

import RegexUtils from '../utils/RegexUtils';

// eslint-disable-next-line import/prefer-default-export
export const getNamingRuleErrorMessage = ({
  str,
  target,
  canOnlyUseASCII,
}: {
  str: string;
  target: string;
  canOnlyUseASCII?: boolean;
}): string[] => {
  const errorMessages: string[] = [];
  if (str.length === 0) return errorMessages;
  if (str.length < 4 || str.length > 50) {
    errorMessages.push(i18n?.t('naming.nameLength', { target: capitalize(target) }) ?? '');
  }
  if (canOnlyUseASCII && !RegexUtils.IS_ASCII(str)) {
    errorMessages.push(i18n?.t('naming.onlyASCII', { target: capitalize(target) }) ?? '');
  }
  if (RegexUtils.HAS_SPECIAL_SYMBOLS(str)) {
    errorMessages.push(
      i18n?.t('naming.cannotContainSpecialSymbol', { target: capitalize(target) }) ?? '',
    );
  }

  return errorMessages;
};

export function getDefaultFileName(prefix: string, length = 50) {
  const date = format(new Date(), 'yyyy-MM-dd HHmmss');
  const l = date.length + 1;
  return `${prefix.substring(0, length - l)} ${date}`;
}
