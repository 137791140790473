import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoFilled } from '@superb-ai/icons';
import {
  Box,
  extendComponent,
  Icon,
  Input,
  Paragraph,
  Select,
  SelectList,
  Typography,
} from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { formatDateTimeLong } from '../../../../utils/date';
import { useStatsClassWithAnnotationTypeQueries } from '../../../Curate/components/datasets/dataset/analytics/queries/annotationQueries';
import { usePublicDatasetContext } from '../../../Curate/contexts/PublicDatasetContextProvider';
import { useDatasetDataCountQuery } from '../../../Curate/queries/dataQueries';
import { useDatasetsInfiniteQuery } from '../../../Curate/queries/datasetQueries';
import { useSlicesInfiniteQuery } from '../../../Curate/queries/sliceQueries';
import { useCurateDatasetService } from '../../../Curate/services/DatasetService';
import {
  isNameLengthValid,
  isNameSpecialCharacterValid,
  MAXIMUM_NAME_LENGTH,
} from '../../../Curate/utils/nameUtils';
import DropdownSearchInput from '../../components/components';
import {
  RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER,
  RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
  RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
  RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER,
} from '../../constant';
import { AnnotationType } from '../../services/types';
import { useBaselineContext } from '../contexts/BaselineContext';
import { useDatasetClassContext } from '../contexts/DatasetClassContext';
import { queryBuilder } from '../TrainModelDialog';
import { DetectionSelect } from './ClassCategorySelect';

export function DatasetClassStep() {
  const { datasetId, trainingSetNames, splitType, validationSetNames } = useDatasetClassContext();
  const { selectedPublicModel, selectedMyModel, modelPurpose } = useBaselineContext();

  const annotationType =
    selectedMyModel?.baselineModel.annotationType ?? selectedPublicModel?.annotationType ?? [];

  const trainingSetDataCountQuery = useDatasetDataCountQuery({
    datasetId: datasetId ?? '',
    queryString: queryBuilder({
      OR: trainingSetNames,
      AND: validationSetNames,
      annotationType: annotationType,
    }),
    disabled: Boolean(!datasetId) || Boolean(trainingSetNames.length === 0),
  });

  const notFetchTrainingSetDataCountQuery = trainingSetNames.length === 0;

  const validationSetDataCountQuery = useDatasetDataCountQuery({
    datasetId: datasetId ?? '',
    queryString: queryBuilder({ OR: validationSetNames, annotationType: annotationType }),
    disabled: Boolean(!datasetId),
  });
  const notFetchValidationSetDataCountQuery = validationSetNames.length === 0;

  const trainingSetDataCount = notFetchTrainingSetDataCountQuery
    ? 0
    : trainingSetDataCountQuery.data?.count ?? 0;
  const validationSetDataCount = notFetchValidationSetDataCountQuery
    ? 0
    : validationSetDataCountQuery.data?.count ?? 0;

  return (
    <Box>
      <DatasetSection
        annotationType={annotationType}
        trainingSetDataCount={trainingSetDataCount}
        validationSetDataCount={validationSetDataCount}
      />
      {splitType !== 'manual' && modelPurpose !== 'generation' && <SliceNameInput />}

      <ClassListSection
        annotationType={annotationType}
        trainingSetDataCount={trainingSetDataCount}
        validationSetDataCount={validationSetDataCount}
      />
    </Box>
  );
}

const DatasetSection = ({
  trainingSetDataCount,
  validationSetDataCount,
  annotationType,
}: {
  trainingSetDataCount: number;
  validationSetDataCount: number;
  annotationType: AnnotationType[];
}) => {
  const { t } = useTranslation();
  const {
    datasetReadOnly,
    setDatasetId,
    datasetName,
    setTrainingSetNames,
    setValidationSetNames,
    setSelectedAnnotationClasses,
  } = useDatasetClassContext();
  const { selectedMyModel } = useBaselineContext();
  const [searchedDatasetName, setSearchDatasetName] = useState<string | null>(null);
  const datasetsQuery = useDatasetsInfiniteQuery({
    enabled: true,
    nameContains: searchedDatasetName,
  });
  const sourceOptions = datasetsQuery.data?.pages.flatMap(page =>
    page.results.map(dataset => ({ value: dataset.id, label: dataset.name })),
  );

  const textSelectSourceShort = t('model.train.datasetPlaceholder');
  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="m-strong">{t('model.train.dataset')}</Typography>

        {/* Select dataset */}
        <Box display="grid" gap={1} style={{ gridTemplateColumns: 'max-content auto' }}>
          <Row
            border="1px solid"
            borderColor={'gray-200'}
            borderRadius="2px"
            pl={1.5}
            style={{ width: 214 }}
          >
            <Typography variant="m-regular">{t('model.train.sourceType')}</Typography>
          </Row>
          <Box display="flex">
            {/* training dataset 선택 */}
            <Select
              value={datasetName ? datasetName : null}
              onChangeValue={v => {
                setTrainingSetNames(null);
                setValidationSetNames(null);
                setSelectedAnnotationClasses([]);
                setDatasetId(v);
                setSearchDatasetName(null);
              }}
              disabled={datasetReadOnly}
              {...datasetsQuery}
              data={sourceOptions}
              placeholder={textSelectSourceShort}
              prefix={
                <DropdownSearchInput
                  placeholder={t('model.myModels.datasetFilterPlaceholder')}
                  setSearchValue={setSearchDatasetName}
                />
              }
            />
          </Box>
        </Box>
        {selectedMyModel && <NotiMessage text={t('model.train.trainingSetNotiMessage')} />}
      </Box>
      {/* Datset select list */}
      <TrainSetSelect
        trainingSetDataCount={trainingSetDataCount}
        validationSetDataCount={validationSetDataCount}
        annotationType={annotationType}
      />
    </Box>
  );
};

const ClassListSection = ({
  annotationType,
  trainingSetDataCount,
  validationSetDataCount,
}: {
  annotationType: AnnotationType[];
  trainingSetDataCount: number;
  validationSetDataCount: number;
}) => {
  const { t } = useTranslation();
  const {
    datasetId,
    trainingSetNames,
    validationSetNames,
    splitType,
    selectedAnnotationClasses,
    setSelectedAnnotationClasses,
  } = useDatasetClassContext();
  const { selectedMyModel, modelPurpose } = useBaselineContext();

  const { showPublicDatasets } = usePublicDatasetContext();
  const classListHeight = 277;

  const trainingSetClassListQuery = useStatsClassWithAnnotationTypeQueries({
    datasetId: datasetId ?? '',
    fromPublicDatasets: showPublicDatasets,
    annotationType: annotationType,
    queryString: queryBuilder({ OR: trainingSetNames, AND: validationSetNames }),
    disabled: Boolean(!datasetId) || Boolean(trainingSetNames.length === 0),
  });

  const validationSetClassListQuery = useStatsClassWithAnnotationTypeQueries({
    datasetId: datasetId ?? '',
    fromPublicDatasets: showPublicDatasets,
    annotationType: annotationType,
    queryString: queryBuilder({ OR: validationSetNames }),
    disabled: Boolean(!datasetId),
  });

  const detectionList = trainingSetClassListQuery
    .filter(_ => _.data !== undefined)
    .flatMap(_ => _.data!.data) as { name: string; annotationCount: number; imageCount: number }[];

  const validationList =
    validationSetNames.length > 0
      ? (validationSetClassListQuery
          .filter(_ => _.data !== undefined)
          .flatMap(_ => _.data!.data) as {
          name: string;
          annotationCount: number;
          imageCount: number;
        }[])
      : [];

  // train with more data로 들어왔을 때 parent model이 갖고 있는 class list
  const classList = selectedMyModel
    ? detectionList.filter(detection =>
        selectedMyModel.modelSetting.annotationClassList.find(_ => _.name === detection.name),
      )
    : detectionList;

  const getMergedClassesInfo = () => {
    const classCountMap = new Map();
    const detectionListMap = new Map(detectionList.map(detection => [detection.name, detection]));
    const validationListMap = new Map(
      validationList.map(validation => [validation.name, validation]),
    );

    for (const train of detectionList) {
      classCountMap.set(train.name, {
        name: train.name,
        trainSetAnnotationCount: 0,
        validationSetAnnotationCount: 0,
      });
    }
    for (const valid of validationList) {
      classCountMap.set(valid.name, {
        name: valid.name,
        trainSetAnnotationCount: 0,
        validationSetAnnotationCount: 0,
      });
    }

    for (const [key, obj] of classCountMap) {
      const detection = detectionListMap.get(key);
      if (detection) {
        const _obj = obj;
        _obj.trainSetAnnotationCount = detection.imageCount;
        classCountMap.set(key, _obj);
      }
      const validation = validationListMap.get(key);
      if (validation) {
        const _obj = obj;
        _obj.validationSetAnnotationCount = validation.imageCount;
        classCountMap.set(key, _obj);
      }
    }

    const mergedArray: {
      name: string;
      trainSetAnnotationCount: number;
      validationSetAnnotationCount: number;
    }[] = [...classCountMap].map(([key, value]) => value);

    const result = mergedArray.filter(x => classList.find(c => c.name === x.name));

    return result;
  };

  const countClassesMeetingRecommendation = getMergedClassesInfo().filter(x =>
    modelPurpose === 'generation'
      ? x.trainSetAnnotationCount >= RECOMMENDED_GEN_AI_TRAIN_SET_QUANTITY_NUMBER
      : splitType === 'manual'
      ? x.trainSetAnnotationCount >= RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER &&
        x.validationSetAnnotationCount >= RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER
      : x.trainSetAnnotationCount >= RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
  ).length;

  const countClasses = classList.length;
  return (
    <Box mt={2}>
      <Paragraph variant="m-strong" mb={0.5}>
        {t('model.train.classList')}
      </Paragraph>
      {Boolean(datasetId) ? (
        <>
          <Box display="flex" mb={1}>
            <Typography variant="m-regular" mr={1}>
              {t('model.train.ensureQuantityIsMet')}
            </Typography>
            <Typography variant="m-regular" ml="auto">
              <Trans
                t={t}
                i18nKey="model.train.recommendedQuantityCount"
                values={{
                  countClasses: countClasses,
                  countClassesMeetingRecommendation: countClassesMeetingRecommendation,
                }}
              >
                <strong>{{ countClassesMeetingRecommendation }}</strong> out of{' '}
                <strong>{{ countClasses }}</strong> meets recommended quantity
              </Trans>
            </Typography>
          </Box>
          {selectedMyModel && (
            <Box mb={1}>
              <NotiMessage text={t('model.train.classListNotiMessage')} />
            </Box>
          )}

          {/* class list 선택 */}
          {trainingSetNames.length ? (
            <BorderedBox p={1} style={{ height: classListHeight }}>
              <Box overflow="overlay" style={{ height: classListHeight - 16 }}>
                {annotationType.map(type => {
                  return (
                    <DetectionSelect
                      key={type}
                      annotationType={type}
                      isLoading={trainingSetClassListQuery.some(_ => _.isLoading)}
                      isPreselected={Boolean(selectedMyModel) || modelPurpose === 'generation'}
                      isManualSplit={splitType === 'manual'}
                      classList={getMergedClassesInfo()}
                      selectedAnnotationNames={selectedAnnotationClasses}
                      setSelectedAnnotationNames={setSelectedAnnotationClasses}
                      trainingSetDataCount={trainingSetDataCount}
                      validationSetDataCount={validationSetDataCount}
                    />
                  );
                })}
              </Box>
            </BorderedBox>
          ) : (
            <BorderedBox
              p={1}
              backgroundColor="gray-100"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: classListHeight }}
            >
              <Typography variant="m-regular" color="gray-300">
                {t('model.train.selectSlice')}
              </Typography>
            </BorderedBox>
          )}
        </>
      ) : (
        <>
          <Box mb={1}>
            <Typography variant="m-regular" mr={1}>
              {t('model.train.ensureQuantityIsMet')}
            </Typography>
          </Box>
          <BorderedBox>
            <Paragraph
              variant="m-regular"
              backgroundColor="gray-100"
              color="gray-300"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: classListHeight }}
            >
              {t('model.train.selectSlice')}
            </Paragraph>
          </BorderedBox>
        </>
      )}
    </Box>
  );
};

const TrainSetSelect = ({
  trainingSetDataCount,
  validationSetDataCount,
  annotationType,
}: {
  trainingSetDataCount: number;
  validationSetDataCount: number;
  annotationType: AnnotationType[];
}) => {
  const {
    datasetId,
    trainingSetNames,
    setTrainingSetNames,
    splitType,
    validationSetNames,
    setValidationSetNames,
  } = useDatasetClassContext();
  const { t } = useTranslation();
  const { selectedMyModel, modelPurpose } = useBaselineContext();

  const slicesQuery = useSlicesInfiniteQuery({
    datasetId: datasetId,
    enabled: Boolean(datasetId),
    expand: ['image_count'],
  });

  const trainingSetOptions = slicesQuery.data?.pages.flatMap(page =>
    page.results.map(slice => ({
      value: slice.name,
      label: <SliceOption {...slice} />,
      disabled:
        validationSetNames?.includes(slice.name) ||
        Boolean(selectedMyModel?.trainingSet.trainingSetList.some(_ => _.name === slice.name)),
    })),
  );

  const validationSetOptions = slicesQuery.data?.pages.flatMap(page =>
    page.results.map(slice => ({
      value: slice.name,
      label: <SliceOption {...slice} />,
      disabled:
        trainingSetNames.includes(slice.name) ||
        Boolean(selectedMyModel?.trainingSet.validationSetList.some(_ => _.name === slice.name)),
    })),
  );

  const trainingSetSelect = datasetId && (
    <SelectList
      {...slicesQuery}
      multiple
      value={trainingSetNames}
      data={trainingSetOptions}
      onChangeValue={v => {
        setTrainingSetNames(v);
      }}
      emptyContent={t('model.train.noOptionsFound')}
    />
  );

  const validationSetSelect = datasetId && (
    <SelectList
      {...slicesQuery}
      multiple
      value={validationSetNames ?? []}
      data={validationSetOptions}
      onChangeValue={v => setValidationSetNames(v)}
      emptyContent={t('model.train.noOptionsFound')}
    />
  );
  const recommendedTrainingSetCountMessage = {
    text:
      modelPurpose === 'generation' ? (
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={'model.train.recommandedCount'}
            values={{
              type: annotationType,
              count: RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER,
            }}
          />
        </Typography>
      ) : splitType === 'manual' ? (
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={'model.train.recommandedCount'}
            values={{
              type: annotationType,
              count: RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
            }}
          />
        </Typography>
      ) : (
        <Typography variant="m-regular">
          <Trans
            t={t}
            i18nKey={'model.train.recommandedCount'}
            values={{
              type: annotationType,
              count: RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE,
            }}
          />
        </Typography>
      ),
    subText: (
      <>
        <Typography
          variant="m-regular"
          color={
            modelPurpose === 'generation'
              ? RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER > trainingSetDataCount
                ? 'primary-400'
                : undefined
              : splitType === 'manual'
              ? RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT >
                trainingSetDataCount
                ? 'primary-400'
                : undefined
              : RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE >
                trainingSetDataCount
              ? 'primary-400'
              : undefined
          }
        >
          {trainingSetDataCount}
        </Typography>
        <Typography variant="m-regular">
          {' '}
          /{' '}
          {modelPurpose === 'generation'
            ? RECOMMENDED_GEN_AI_TRAIN_SET_IMAGE_NUMBER
            : splitType === 'manual'
            ? RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT
            : RECOMMENDED_RECOGNITION_AI_TRAIN_SET_IMAGE_NUMBER_IN_AUTOMATICALLY_SAMPLE}
        </Typography>
      </>
    ),
  };

  const recommendedValidationSetCountMessage = {
    text: (
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={'model.train.recommandedCount'}
          values={{
            type: annotationType,
            count: RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT,
          }}
        />
      </Typography>
    ),
    subText: (
      <>
        <Typography
          variant="m-regular"
          color={
            RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT >
            validationSetDataCount
              ? 'primary-400'
              : undefined
          }
        >
          {validationSetDataCount}
        </Typography>
        <Typography variant="m-regular">
          {' '}
          / {RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_IMAGE_NUMBER_IN_MANUALLY_SELECT}
        </Typography>
      </>
    ),
  };

  const datasetSelectHeight = 128;

  return (
    <>
      {splitType === 'manual' && (
        <Box mt={2}>
          <Typography variant="m-medium">{t('model.train.trainSet')}</Typography>
        </Box>
      )}
      {Boolean(datasetId) ? (
        <BorderedBox p={1} mb={2} mt={1}>
          <NotiMessage
            text={recommendedTrainingSetCountMessage.text}
            subText={recommendedTrainingSetCountMessage.subText}
          />
          <Box mt={0.5} style={{ height: datasetSelectHeight }}>
            {/* training set slice 선택 */}
            {trainingSetSelect}
          </Box>
        </BorderedBox>
      ) : (
        <BorderedBox mb={2} mt={1} style={{ height: 162 }}>
          <Paragraph
            variant="m-regular"
            backgroundColor="gray-100"
            color="gray-300"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {t('model.train.selectDataset')}
          </Paragraph>
        </BorderedBox>
      )}
      {splitType === 'manual' && (
        <>
          <Box mb={1}>
            <Typography variant="m-medium" mr="auto">
              {t('model.train.validationSet')}
            </Typography>
          </Box>
          {Boolean(datasetId) ? (
            <BorderedBox p={1}>
              <NotiMessage
                text={recommendedValidationSetCountMessage.text}
                subText={recommendedValidationSetCountMessage.subText}
              />
              <Box mt={0.5} style={{ height: datasetSelectHeight }}>
                {/* validation set slice 선택 */}
                {validationSetSelect}
              </Box>
            </BorderedBox>
          ) : (
            <BorderedBox>
              <Paragraph
                variant="m-regular"
                backgroundColor="gray-100"
                color="gray-300"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                style={{ height: 162 }}
              >
                {t('model.train.selectDataset')}
              </Paragraph>
            </BorderedBox>
          )}
        </>
      )}
    </>
  );
};

const SliceNameInput = () => {
  const { t } = useTranslation();
  const {
    datasetId,
    trainSetSliceName,
    setTrainSetSliceName,
    validationSetSliceName,
    setValidationSetSliceName,
    setIsValidSliceNames,
  } = useDatasetClassContext();
  const { getSearchSliceName } = useCurateDatasetService();
  const [isUniqueTrainSetSliceName, setIsUniqueTrainSetSliceName] = useState<boolean | null>(null);
  const [isUniqueValidationSetSliceName, setIsUniqueValidationSetSliceName] = useState<
    boolean | null
  >(null);
  const isSameNameTrainSliceWithValidSlice = trainSetSliceName === validationSetSliceName;

  const debouncedTrainSetSliceName = useDebounce(trainSetSliceName, 300);
  const debouncedValidationSetSliceName = useDebounce(validationSetSliceName, 300);

  useEffect(() => {
    setIsValidSliceNames(
      Boolean(
        isNameLengthValid(trainSetSliceName) &&
          isNameSpecialCharacterValid(trainSetSliceName) &&
          isUniqueTrainSetSliceName &&
          isUniqueValidationSetSliceName &&
          !isSameNameTrainSliceWithValidSlice,
      ),
    );
  }, [
    isSameNameTrainSliceWithValidSlice,
    isUniqueTrainSetSliceName,
    isUniqueValidationSetSliceName,
    setIsValidSliceNames,
    trainSetSliceName,
  ]);

  useEffect(() => {
    if (!datasetId) return;
    (async () => {
      try {
        await getSearchSliceName({ name: debouncedTrainSetSliceName, datasetId: datasetId });
        setIsUniqueTrainSetSliceName(false);
      } catch (e) {
        setIsUniqueTrainSetSliceName(true);
      }
    })();
  }, [datasetId, debouncedTrainSetSliceName]);

  useEffect(() => {
    if (!datasetId) return;
    (async () => {
      try {
        await getSearchSliceName({
          name: debouncedValidationSetSliceName,
          datasetId: datasetId,
        });
        setIsUniqueValidationSetSliceName(false);
      } catch (e) {
        setIsUniqueValidationSetSliceName(true);
      }
    })();
  }, [datasetId, debouncedValidationSetSliceName]);

  return (
    <Box mt={2}>
      <Box display="grid" mb={1} style={{ gridTemplateColumns: '1fr 1fr', columnGap: 8 }}>
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="m-strong">{t('model.train.trainSetSlice')}</Typography>
            <Typography
              variant="s-regular"
              color={isNameLengthValid(debouncedTrainSetSliceName) ? undefined : 'primary-400'}
            >{`${debouncedTrainSetSliceName.length}/${MAXIMUM_NAME_LENGTH}`}</Typography>
          </Box>
          <Input
            value={trainSetSliceName}
            onChange={e => {
              setTrainSetSliceName(e.target.value);
            }}
          />
          <Box mt={0.5}>
            {!isNameLengthValid(debouncedTrainSetSliceName) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  <Trans t={t} i18nKey={'curate.slices.sliceNameGuideAboutLength'} />
                </Typography>
              </Box>
            )}
            {!isNameSpecialCharacterValid(debouncedTrainSetSliceName) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  <Trans t={t} i18nKey={'curate.slices.sliceNameGuideAboutCharacter'} />
                </Typography>
              </Box>
            )}
            {(!isUniqueTrainSetSliceName || isSameNameTrainSliceWithValidSlice) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  {t('model.train.uniqueSliceName')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="m-strong">{t('model.train.validationSetSlice')}</Typography>
            <Typography
              variant="s-regular"
              color={isNameLengthValid(validationSetSliceName) ? undefined : 'primary-400'}
            >{`${validationSetSliceName.length}/${MAXIMUM_NAME_LENGTH}`}</Typography>
          </Box>
          <Input
            value={validationSetSliceName}
            onChange={e => setValidationSetSliceName(e.target.value)}
          />
          <Box mt={0.5}>
            {!isNameLengthValid(debouncedValidationSetSliceName) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  <Trans t={t} i18nKey={'curate.slices.sliceNameGuideAboutLength'} />
                </Typography>
              </Box>
            )}
            {!isNameSpecialCharacterValid(debouncedValidationSetSliceName) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  <Trans t={t} i18nKey={'curate.slices.sliceNameGuideAboutCharacter'} />
                </Typography>
              </Box>
            )}
            {(!isUniqueValidationSetSliceName || isSameNameTrainSliceWithValidSlice) && (
              <Box>
                <Typography variant="m-regular" color={'primary-400'} style={{ padding: '0 2px' }}>
                  {t('model.train.uniqueSliceName')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <NotiMessage text={t('model.train.sliceNameInfo')} />
    </Box>
  );
};

function SliceOption({
  name,
  imageCount,
  updatedAt,
}: {
  name: string;
  imageCount?: number;
  updatedAt: string | Date;
}) {
  const { t } = useTranslation();
  return (
    <Box display="flex">
      <Typography
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        style={{ maxWidth: 350 }}
      >
        {name}
      </Typography>
      <Typography ml="auto" color="gray-300">
        {t('curate.slices.imageCount', { count: imageCount || 0 })} ({formatDateTimeLong(updatedAt)}
        )
      </Typography>
    </Box>
  );
}

const NotiMessage = ({
  text,
  subText,
}: {
  text: string | ReactNode;
  subText?: string | ReactNode;
}) => {
  return (
    <Row px={1.5} backgroundColor={'gray-100'} gap={0.5} style={{ height: 34 }}>
      <Icon icon={InfoFilled} />
      {typeof text === 'string' ? <Typography variant="m-regular">{text}</Typography> : text}
      {subText && (
        <Box ml="auto">
          {typeof subText === 'string' ? (
            <Typography variant="m-regular">{subText}</Typography>
          ) : (
            subText
          )}
        </Box>
      )}
    </Row>
  );
};
const BorderedBox = extendComponent(Box, {
  border: '1px solid',
  borderColor: 'gray-200',
  borderRadius: '2px',
});
