import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, PasswordInput, Typography } from '@superb-ai/ui';

interface Props {
  setParams: (params: Record<string, any> | null) => void;
}

export default function EditAuthenticationAzure({ setParams }: Props) {
  const { t } = useTranslation();
  const [accountName, setAccountName] = useState('');
  const [accountKey, serAccountKey] = useState('');

  useEffect(() => {
    if (!accountName || !accountKey) {
      setParams(null);
    } else {
      setParams({
        accountName,
        accountKey,
      });
    }
  }, [accountName, accountKey]);

  return (
    <Box display="grid" gap={2} style={{ gridTemplateColumns: '1fr 1fr' }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="m-strong">{t('integrations.azure.accountName')}</Typography>
        <Input value={accountName} onChange={e => setAccountName(e.target.value)} />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="m-strong">{t('integrations.add.azure.accountKey')}</Typography>
        <PasswordInput value={accountKey} onChange={e => serAccountKey(e.target.value)} />
      </Box>
    </Box>
  );
}
