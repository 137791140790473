import {
  Chart,
  Datum,
  PartialTheme,
  Partition,
  PartitionLayout,
  Settings,
  ShapeTreeNode,
} from '@elastic/charts';
import { upperFirst } from 'lodash';

import { getDonutChartTheme } from './customTheme';
import { ChartDatum } from './types';
import { formatCount } from './utils';

export function NoResults({ msg }: { msg: string }) {
  return <div>{msg}</div>;
}

export function DonutChart({
  data,
  getColor,
  onElementListeners,
  customSettings,
  theme,
}: {
  data: ChartDatum[];
  getColor: (key: any, datum?: ShapeTreeNode) => string;
  onElementListeners?: any;
  xAxis?: { label: string };
  yAxis?: { label: string };
  customSettings?: { showLegendExtra?: boolean };
  theme?: PartialTheme;
}) {
  return (
    <Chart size={{ width: '100%', height: '100%' }}>
      <Settings
        theme={theme ?? getDonutChartTheme({ dataCount: data.length })}
        showLegend
        showLegendExtra={customSettings?.showLegendExtra || false}
        legendStrategy="nodeWithDescendants"
        legendMaxDepth={1}
        {...onElementListeners}
        noResults={<NoResults msg={'No Data'} />}
      />
      <Partition
        id="spec_1"
        data={data}
        layout={PartitionLayout.sunburst}
        valueAccessor={(d: Datum) => d.count as number}
        valueFormatter={(d: number) => `${formatCount(d)}`}
        layers={[
          {
            groupByRollup: (d: Datum) => d.key,
            nodeLabel: (d: Datum) => `${upperFirst(d)}`,
            shape: {
              fillColor: (d: ShapeTreeNode) => getColor(d?.dataName, d),
            },
          },
        ]}
      />
    </Chart>
  );
}
