import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { Box, DropdownList } from '@superb-ai/norwegian-forest';

const languages = {
  en: 'English',
  ja: 'Japanese (日本語)',
  ko: 'Korean (한국어)',
  cimode: 'CI Mode',
};

const showCiMode = process.env.NODE_ENV !== 'production';

export default function LanguageSelector() {
  const { i18n } = useTranslation();

  const [, setCookie] = useCookies();

  function changeLanguage(lng: string) {
    i18n.changeLanguage(lng);
    setCookie('i18next', lng, { domain: '.superb-ai.com' });
    localStorage?.setItem('i18nextLng', lng);
  }

  const supportedLngs = (i18n.options.supportedLngs || []).filter(
    l => showCiMode || l !== 'cimode',
  );
  const options = supportedLngs.map(lang => ({
    value: lang,
    label: languages[lang as keyof typeof languages] ?? lang,
  }));

  return (
    <Box display="flex" width="180px">
      <DropdownList options={options} value={i18n.language} onChange={changeLanguage} />
    </Box>
  );
}
