import {
  Box,
  CheckboxChecked,
  Cuboid,
  Keypoints,
  ListAssign,
  Polygon,
  Polyline,
  RadioChecked,
  RotatedBox,
} from '@superb-ai/icons';

import LabelConst from '../consts/LabelConst';
import { LabelReview, ReviewState } from '../consts/ReviewConst';
import { HistoryType } from '../types/exportTypes';
import { LabelStatus } from '../types/labelTypes';
import { AnnotationType } from './LabelInterfaceUtils';

const getStatusText = (status: string): string => {
  switch (status) {
    case 'WORKING':
      return 'labels.status.in_progress';
    case 'SKIPPED':
      return 'labels.status.skipped';
    case 'SUBMITTED':
      return 'labels.status.submitted';
    default:
      return '';
  }
};

const getStatusKey = (status: string): string => {
  switch (status) {
    case 'WORKING':
      return 'IN PROGRESS';
    case 'SKIPPED':
      return 'SKIPPED';
    case 'SUBMITTED':
      return 'SUBMITTED';
    default:
      return '';
  }
};

const statusToColorName = {
  WORKING: 'in_progress',
  SKIPPED: 'skipped',
  SUBMITTED: 'submitted',
} as const;
const getStatusColorName = (status: LabelStatus): (typeof statusToColorName)[LabelStatus] =>
  statusToColorName[status];

const getStatusColor = (status: string): string => {
  switch (status) {
    case 'WORKING':
      return '#FFCC00';
    case 'SKIPPED':
      return '#A6A6A6';
    case 'SUBMITTED':
      return '#82DB24';
    default:
      return '';
  }
};

const reviewActionToColor = {
  APPROVE: 'approved',
  REJECT: 'rejected',
  UNREVIEWED: 'unreviewed',
} as const;
const getReviewActionColorName = (
  review: LabelReview | ReviewState,
): (typeof reviewActionToColor)[LabelReview] => reviewActionToColor[getKeyForReviewAction(review)];

function getKeyForReviewAction(action: LabelReview | ReviewState): LabelReview {
  if (!action || action === 'REQUEST') {
    return 'UNREVIEWED';
  }
  return action;
}

function getColorForConsistencyScore(score: number): 'submitted' | 'in_progress' | 'failed' {
  if (score >= 80) {
    return 'submitted';
  }
  if (score >= 30) {
    return 'in_progress';
  }
  return 'failed';
}

/**
 * Scales a score from 0..{max} to a number 0 for null and 1..{ranks} for numbers.
 * For default values,
 * null => 0
 * 0..25 => 1
 * 26..50 => 2
 * 51..75 => 3
 * 76..100 => 4
 */
function getRankForConsistencyScore(score: number | null, max = 100, ranks = 4): number {
  if (typeof score !== 'number') return 0;
  if (score === 0) return 1;
  return Math.ceil((ranks * (score - 0.001)) / max);
}

type ScoreColorChoice = 'limegreen' | '#ffdb58' | 'red';

export interface ScoreThresholdLegendRow {
  ranks: number[]; // may remove this (ml)
  min: number;
  max: number;
  description: string;
  scoreRange: string;
  color: ScoreColorChoice;
}
function getConsistencyScoreLegendInfo(): ScoreThresholdLegendRow[] {
  return [
    {
      ranks: [0, 1],
      min: 0,
      max: 25,
      scoreRange: '0 ~ 25',
      description: 'Lowest 25%',
      color: 'red',
    },
    {
      ranks: [2, 3],
      min: 26,
      max: 75,
      scoreRange: '26 ~ 75',
      description: 'Middle 50%',
      color: '#ffdb58',
    },
    {
      ranks: [4],
      min: 76,
      max: 100,
      scoreRange: '76 ~ 100',
      description: 'Upper 25%',
      color: 'limegreen',
    },
  ];
}

const getAnnotationInfoColor = (optionName: string): string => {
  if (optionName === LabelConst.COMPARE_OPTIONS.CURRENT) {
    return '#F89400';
  }
  if (optionName === LabelConst.COMPARE_OPTIONS.AUTO_LABEL) {
    return '#5A7BEF';
  }
  return '#FF625A';
};

const getIsAutoLabelUncertaintyInfo = (
  label: Record<string, any>,
): {
  isAutoLabelUncertainty: boolean;
  autoLabelUncertaintyCount: number;
} => {
  if (label?.autoLabelStatus === 'COMPLETE' && label?.status === 'WORKING' && label?.info) {
    const isDiffrentAutoLabelFromLabel =
      JSON.stringify(label.autoLabelInfo?.result?.objects) !==
      JSON.stringify(label.info?.result?.objects);

    if (isDiffrentAutoLabelFromLabel) {
      return { isAutoLabelUncertainty: false, autoLabelUncertaintyCount: -1 };
    }

    let nextAutoLabelUncertaintyCount = 0;
    const { objects } = label.autoLabelInfo.result;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].difficulty === 1) nextAutoLabelUncertaintyCount++;
    }
    if (nextAutoLabelUncertaintyCount === 0) {
      return { isAutoLabelUncertainty: false, autoLabelUncertaintyCount: -1 };
    }
    return {
      isAutoLabelUncertainty: true,
      autoLabelUncertaintyCount: nextAutoLabelUncertaintyCount,
    };
  }

  return { isAutoLabelUncertainty: false, autoLabelUncertaintyCount: -1 };
};

type LabelTypeValue = 'main' | 'consensus';
type LabelTypeInfo = {
  uniqueLabelType: 'DEFAULT' | null;
  additionalLabelType: 'CONSENSUS' | '';
};

const getLabelType = ({ uniqueLabelType, additionalLabelType }: LabelTypeInfo): LabelTypeValue => {
  if (!uniqueLabelType) return additionalLabelType.toLowerCase() as LabelTypeValue;
  if (uniqueLabelType === 'DEFAULT' && additionalLabelType === '') return 'main';
  // if type is not defined (likely an error)
  return 'main';
};

/**
 * Get label asset's frame count
 */
const getFrameCount = ({
  asset,
}: {
  asset: { info?: { fileInfos?: any[]; frameInfos?: any[] } };
}) => {
  return (
    asset.info?.fileInfos?.length ?? // image-sequence
    asset.info?.frameInfos?.length ?? // pointclouds
    0
  );
};

/**
 * Get label asset's duration in seconds (video-stream)
 */
const getDuration = ({ asset }: { asset: { info?: { duration?: number } } }) => {
  return asset.info?.duration ? asset.info.duration / 1000 : 0;
};

const getAnnotationIcon = (name: AnnotationType) => {
  switch (name) {
    case 'keypoint':
    case 'keypoints':
      return Keypoints;
    case 'box':
    case 'tiltedbox':
      return Box;
    case 'cuboid':
    case 'cuboid2D':
      return Cuboid;
    case 'polygon':
    case 'polygons':
      return Polygon;
    case 'polyline':
      return Polyline;
    case 'rbox':
      return RotatedBox;
  }
};

const getCategoryIcon = (category: string) => {
  return (
    {
      radio: RadioChecked,
      checkbox: CheckboxChecked,
      'free response': ListAssign,
    }[category] ?? null
  );
};

interface Children {
  id: string;
  children?: Children[];
}

interface Option {
  id: string;
  children?: Children[];
}

const idInChildrenRecursive = (id: string, children?: Children[]): boolean => {
  if (!children || !children.length) return false;
  else {
    return children.some(
      ({ id: itemId, children }) => id === itemId || idInChildrenRecursive(id, children),
    );
  }
};

const getCategoriesInAnnotationCount = (
  category: NonNullable<HistoryType['annotations']>['category']['results'],
  labelInterface: any,
) => {
  return Object.entries(
    category.reduce((prev, { id, count }) => {
      const { id: categoryId } =
        labelInterface?.categorization?.properties.find(({ options }: { options?: Option[] }) =>
          options?.some(
            ({ id: optionId, children }) => id === optionId || idInChildrenRecursive(id, children),
          ),
        ) ?? {};

      if (categoryId) {
        if (prev[categoryId]) prev[categoryId].count += count;
        else {
          prev[categoryId] = {
            id: categoryId,
            count,
          };
        }
      }

      return prev;
    }, {} as Record<string, { id: string; count: number }>),
  ).map(([_, value]) => value);
};

export default {
  getStatusText,
  getStatusKey,
  getStatusColor,
  getStatusColorName,
  getColorForConsistencyScore,
  getRankForConsistencyScore,
  getConsistencyScoreLegendInfo,

  getLabelType,
  getFrameCount,
  getDuration,

  getAnnotationInfoColor,
  getIsAutoLabelUncertaintyInfo,

  getReviewActionColorName,
  getKeyForReviewAction,

  getAnnotationIcon,
  getCategoryIcon,
  getCategoriesInAnnotationCount,
};
