import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Typography } from '@superb-ai/ui';

import { LoadingIndicatorDiv } from '../../analytics/components/LoadingIndicatorDiv';

export const LoadingProgressOverlay = () => {
  const { t } = useTranslation();
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      gap={2}
      borderRadius="2px"
      backgroundColor="overlays-black"
    >
      <Box>
        <LoadingIndicatorDiv color={'primary-400'} />
      </Box>
      {/* <ProgressBar
        indeterminate={calculatingState}
        color="primary"
        style={{ width: '260px' }}
        value={progress / (total || 1)}
      /> */}
      {/* <Box display="flex" justifyContent="space-between" style={{ width: '260px' }}> */}
      <Box display="flex" justifyContent="center" style={{ width: '260px' }}>
        <Typography variant="l-strong" color="white">
          {t('curate.diagnosis.text.diagnosingModelPerformance')}
        </Typography>
        {/* {!calculatingState && (
          <Typography variant="l-strong" color="white">
            {percent}%
          </Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default LoadingProgressOverlay;
