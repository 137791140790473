import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as MUI from '@mui/material';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  NOTIFICATION_ASSIGN,
  NOTIFICATION_COMMENT,
  NOTIFICATION_EXPORT,
  NOTIFICATION_INVITE,
  NOTIFICATION_THREAD,
} from '../consts/SnackbarMessage';
import NotificationsUtils from '../utils/NotificationsUtils';
import { StateGetterSetter } from './types';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['isOpen', 'setIsOpen'], boolean> &
  StateGetterSetter<['notifications', 'setNotifications'], any[]> &
  StateGetterSetter<['unreadCount', 'setUnreadCount'], number> &
  StateGetterSetter<['newNotification', 'setNewNotification'], any> &
  StateGetterSetter<['hasNew', 'setHasNew'], boolean>;

const Context = React.createContext({} as ContextProps);

const NotificationsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [newNotification, setNewNotification] = useState(null);
  const [hasNew, setHasNew] = useState(false);
  const lastNewNotification = useRef<any>(null);

  const { enqueueSnackbar } = useSnackbar();
  const genEnqueueSnackbar = (notification: any): void => {
    const {
      type,
      payload: { fromFirstName, labelName, projectName, labelCount, subType, exportSeqNum },
    } = notification;

    switch (type) {
      case 'invite':
        enqueueSnackbar(NOTIFICATION_INVITE({ t, fromFirstName, projectName }), {
          variant: 'info',
        });
        break;
      case 'assign':
        enqueueSnackbar(
          NOTIFICATION_ASSIGN({
            t,
            fromFirstName,
            labelCount: labelCount.toLocaleString('en'),
            projectName,
          }),
          { variant: 'info' },
        );
        break;
      case 'thread':
        enqueueSnackbar(NOTIFICATION_THREAD({ t, fromFirstName, labelName, projectName }), {
          variant: 'info',
        });
        break;
      case 'comment':
        enqueueSnackbar(NOTIFICATION_COMMENT({ t, fromFirstName, labelName, projectName }), {
          variant: 'info',
        });
        break;
      case 'export':
        enqueueSnackbar(
          NOTIFICATION_EXPORT({
            t,
            exportSeqNum: exportSeqNum.toLocaleString('EN'),
            subType: NotificationsUtils.convertExportArchiveSubType(subType),
            labelCount: labelCount.toLocaleString('en'),
            projectName,
          }),
          { variant: 'info' },
        );
        if (!localStorage.getItem('CUSTOM_AUTO_LABEL_RECOMMENDATION')) {
          const handleClickView = () => {
            history.push(
              `/${notification.tenantId}/project/${notification.payload.projectId}/export/history`,
            );
            localStorage.setItem('CUSTOM_AUTO_LABEL_RECOMMENDATION', 'false');
          };

          enqueueSnackbar('Create Custom Auto-Label with the labels you just exported!', {
            persist: false,
            variant: 'info',
            action: (
              <>
                <MUI.Button
                  className="snackbar-button"
                  size="small"
                  color="primary"
                  onClick={handleClickView}
                >
                  View
                </MUI.Button>
              </>
            ),
          });
        }
        break;
      case 'custom_auto_label':
        break;
      default:
    }
  };

  useEffect(() => {
    if (!newNotification) return;

    if (lastNewNotification.current != newNotification) {
      setHasNew(true);
      lastNewNotification.current = newNotification;
    }

    const nextNotifications = cloneDeep(notifications);
    nextNotifications.unshift(newNotification);
    setNotifications(nextNotifications);

    setUnreadCount(unreadCount + 1);
    genEnqueueSnackbar(newNotification);
    // eslint-disable-next-line
  }, [newNotification]);

  useEffect(() => {
    if (!isOpen) {
      setNotifications(cloneDeep(notifications.slice(0, 10)));
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Context.Provider
      value={{
        isOpen,
        setIsOpen,
        notifications,
        setNotifications,
        unreadCount,
        setUnreadCount,
        newNotification,
        setNewNotification,
        hasNew,
        setHasNew,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default {
  Context,
  Provider: NotificationsProvider,
};
