import { startsWith } from 'lodash';

import ParamUtils from '../../../../utils/ParamUtils';
import { JsonObj } from '../userStats/types';
import ServiceUtils from '../../../../utils/ServiceUtils';
import qs from 'qs';

/**
 * @param {string} assigneeName (can be No Assignee)
 * @param {string} assigneeEmail (can be No Assignee)
 * @param {string} status Status to filter by. one of [submitted, skipped, working, total]
 * @returns {object} filterParams Contains assignee (default) and status (optional)
 *
 * ex. {
 *   assignee: "is any one of,Moon Limb (mjlimb@superb-ai.com)",
 *   status: "is any one of,submitted"
 * }
 */
interface MakeAssigneeFilterResult {
  assignee: string[];
  status?: string[];
}
const makeAssigneeFilterParams = (
  assigneeName: string,
  assigneeEmail: string,
): MakeAssigneeFilterResult => {
  const assignee =
    assigneeName === 'No Assignee'
      ? ['does not exist'] // does not exist&assignee=does not exist
      : ['is any one of', `${assigneeName} (${assigneeEmail})`];
  return { assignee };
};

interface MakeReviewerFilterResult {
  reviewer: string[];
}
const makeReviewerFilterParams = (
  reviewerName: string,
  reviewerEmail: string,
): MakeReviewerFilterResult => {
  const reviewer =
    reviewerName === 'No Assignee'
      ? ['does not exist'] // does not exist&assignee=does not exist
      : ['is any one of', `${reviewerName} (${reviewerEmail})`];
  return { reviewer };
};

/**
 * Given object class name and status, constructs filterParams
 */
const makeObjectOrCategoryFilterParams = (objectClassName: string, groupIds?: string[]) => {
  let params = objectClassName;
  if (typeof groupIds !== 'undefined') {
    params = groupIds.join(';');
  }
  return {
    annotation: ['contains all of', params],
  };
};

// side effect: reroutes to filtered label list
export const routeToFilteredLabelList = (
  filter: string,
  email: string,
  routeInfo: any,
  tagIds?: Record<string, string>,
) => {
  let paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  paramsObj = ParamUtils.updateParsedQueryString(paramsObj, 'workAssigneeIn', email);
  const encodedQueryParams = ParamUtils.getQueryParamFromParamsObj(paramsObj, tagIds);
  const PATH = `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels?`;
  routeInfo.history.push(`${PATH}${encodedQueryParams}`);
};

/**
 * @param {String} accountName Team Name in authorization Info
 * @param {Object} routeInfo
 * @param {String} projectId Unique identifier from server
 * @param {Object} filterBy contains relevant filter info.
 *
 * For each stats, filterBy contains:
 *  - assignee: assigneeName, assigneeEmail, status
 *  - object class:
 *  - image categorization:
 */
// eslint-disable-next-line
export const filterLabelList = (props: { filterBy: JsonObj; routeInfo: any }) => {
  const { routeInfo, filterBy } = props;
  const filter = Object.keys(filterBy).reduce((acc: JsonObj, key: string) => {
    if (key === 'status') {
      const { status } = filterBy;

      // don't include status param if it's all statuses
      // Otherwise, we currently don't support linking from analytics to label list
      // based on 2 statuses
      if (status !== 'total') {
        return { ...acc, status: ['is any one of', status] };
      }
      return acc;
    }
    if (key === 'reviewerName') {
      const { reviewerName, reviewerEmail } = filterBy;
      const reviewerFilterParams = makeReviewerFilterParams(reviewerName, reviewerEmail);
      return { ...acc, ...reviewerFilterParams };
    }
    if (key === 'assigneeName') {
      const { assigneeName, assigneeEmail } = filterBy;
      const assigneeFilterParams = makeAssigneeFilterParams(assigneeName, assigneeEmail);
      return { ...acc, ...assigneeFilterParams };
    }
    if (key === 'updatedAt') {
      const { updatedAt } = filterBy;
      // format:  last updated: "is,2020-12-01"
      const updatedAtFilterParams = ParamUtils.getUrlParamsForFilters([
        { filterBy: 'last updated', condition: 'is', options: [{ date: updatedAt }] },
      ]);
      return { ...acc, ...updatedAtFilterParams };
    }
    if (key === 'assetGroupIn') {
      if (!filterBy.assetGroupIn.length) return acc;
      const datasetfilterParams = ParamUtils.getUrlParamsForFilters(
        ParamUtils.getFiltersFromApiParams(filterBy),
      ).flat(2);
      return { ...acc, [datasetfilterParams[0]]: datasetfilterParams[1] };
    }
    if (key === 'objectClassName') {
      const { objectClassName } = filterBy;
      const objects = Array.isArray(objectClassName) ? objectClassName.join(',') : objectClassName;
      const objectClassFilterParams = makeObjectOrCategoryFilterParams(objects);
      return { ...acc, ...objectClassFilterParams };
    }
    // image category
    if (key === 'categoryName') {
      const { categoryName, categoryGroupIds } = filterBy;
      const categoryFilterParams = makeObjectOrCategoryFilterParams(categoryName, categoryGroupIds);
      return { ...acc, ...categoryFilterParams };
    }
    return acc;
  }, {});

  const PATH = `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels?`;
  // @ts-ignore: wrong definition
  const searchParams = new URLSearchParams(filter);
  routeInfo.history.push(`${PATH}${searchParams.toString()}`);
};

// TODO @moonlimb refactor / remove
export const makeFilterByObject = (props: {
  chartName: string;
  xValue: string;
  suppValue?: string;
}): any => {
  const { chartName, xValue, suppValue } = props;
  if (chartName === 'workerStats') {
    // if xValue is a shortened version of email
    // use email directly for correct filtering
    return {
      assigneeName: startsWith(xValue, 'em_') ? suppValue : xValue,
      assigneeEmail: suppValue,
      status: 'total',
    };
  }
  return {
    objectClassName: xValue,
    status: 'total',
  };
};
