import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Plus, Search } from '@superb-ai/icons';
import { DropdownList, Tooltip } from '@superb-ai/norwegian-forest';
import {
  Box,
  Button,
  Chip,
  Icon,
  Input,
  SegmentedControl,
  Select,
  Typography,
} from '@superb-ai/ui';

import { useDebounce } from '../../../../../hooks/DebounceHook';
import { getUrl } from '../../../../../routes/util';
import { useDatasetsInfiniteQuery } from '../../../queries/datasetQueries';
import { DatasetPool, SampleDataset } from '../../../types/routeTypes';
import CreateDatasetModal from '../list/CreateDatasetModal';
interface Props extends Omit<React.ComponentProps<typeof DropdownList>, 'value' | 'onChange'> {
  value: { name: string; id: string };
}

export default function DatasetSelector({ value, ...otherProps }: Props): JSX.Element {
  const { accountName, datasetPool } = useParams<{
    accountName: string;
    datasetPool: DatasetPool;
  }>();
  const history = useHistory();

  const { t } = useTranslation();
  const [selectedDatasetPool, setSelectedDatasetPool] = useState(datasetPool);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datasetSearchInput, setDatasetSearchInput] = useState('');
  const debouncedDatasetSearchInput = useDebounce(datasetSearchInput, 500);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const datasetsQuery = useDatasetsInfiniteQuery({
    enabled: true,
    nameIcontains: debouncedDatasetSearchInput,
    fromPublicDatasets: selectedDatasetPool === SampleDataset,
  });

  const sourceOptions = datasetsQuery.data?.pages.flatMap(page =>
    page.results.map(dataset => ({ value: dataset.id, label: dataset.name })),
  );

  function handleChange(datasetId: string) {
    history.push(getUrl([accountName, 'curate', selectedDatasetPool, datasetId]));
  }

  const handleClickNewDatasetsButton = () => {
    setIsModalOpen(true);
  };

  const createDatasetsButton = (
    <Box borderTop="1px solid" borderColor="gray-150" display="flex">
      <Button
        variant="text"
        color="primary"
        onClick={handleClickNewDatasetsButton}
        style={{ flex: 1 }}
      >
        <Icon icon={Plus} />
        {t('curate.button.createNewDataset')}
      </Button>
    </Box>
  );

  function Header() {
    const options = [
      { value: 'dataset', label: t('curate.datasets.tab.myDatasets') },
      { value: 'sample-dataset', label: t('curate.datasets.tab.sampleDatasets') },
    ];

    function handleSearch(event: ChangeEvent<HTMLInputElement>) {
      setDatasetSearchInput(event.target.value);
    }

    return (
      <Box display="flex" m={1} flexDirection="column" gap={1} mb={0}>
        <Box display="flex">
          <SegmentedControl
            flexWidth
            buttonProps={{ size: 'm' }}
            options={options}
            value={selectedDatasetPool}
            onClick={e => e.stopPropagation()}
            onChangeValue={(value: DatasetPool) => setSelectedDatasetPool(value)}
          />
        </Box>
        <Box borderBottom="1px solid" borderColor="gray-150" display="flex">
          <Input
            ref={searchInputRef}
            type="search"
            variant="text"
            prefix={<Icon icon={Search} />}
            placeholder={t('curate.datasets.placeholder.selectDatasets')}
            onChange={handleSearch}
            onClick={e => e.stopPropagation()}
            style={{ flex: 1 }}
            onBlur={() => setDatasetSearchInput('')}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" width="100%" height="100%" alignItems="center">
      <Select
        fixed
        initialFocusRef={searchInputRef}
        // hideOnClick={false}
        value={value.id}
        onChangeValue={handleChange}
        suffix={createDatasetsButton}
        prefix={Header()}
        variant="text"
        display="flex"
        disclosure={
          <Tooltip
            placement="right"
            anchorEl={
              <>
                <Typography
                  variant="l-strong"
                  textAlign="left"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {value.name}
                </Typography>
                {datasetPool === SampleDataset && (
                  <Chip style={{ whiteSpace: 'nowrap' }}>
                    {t('curate.datasets.sampleDataset.chip')}
                  </Chip>
                )}
              </>
            }
          >
            {value.name}
          </Tooltip>
        }
        {...datasetsQuery}
        data={sourceOptions}
        style={{ flex: 1, width: 280, height: '100%', maxHeight: 316 }}
      />
      <CreateDatasetModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Box>
  );
}
