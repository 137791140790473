import React from 'react';

import { QueryProvider } from '../../../../../contexts/QueryContext';
import { SliceProvider } from '../../../../../contexts/SliceContext';
import SliceContentsByScope from './SliceContentsByScope';

export default function Layout() {
  return (
    <SliceProvider>
      <QueryProvider>
        <SliceContentsByScope />
      </QueryProvider>
    </SliceProvider>
  );
}
