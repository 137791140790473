import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterOutlined } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/norwegian-forest';
import { Button, Chip, Collapse, getColor, Icon as IconV2, Typography } from '@superb-ai/ui';
import { startCase } from 'lodash';
import qs from 'qs';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { formatDate, formatDateTime, parseDate } from '../../../../utils/date';
import {
  allFilters,
  translateLokiCondition2CivetCondition,
} from '../../../../utils/filter/labelFilter';
import { getTranslatedOption } from '../../../../utils/filter/useOptions';
import FilterUtils from '../../../../utils/FilterUtils';
import ParamUtils from '../../../../utils/ParamUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';
import GridTable from '../../account/advanced/GridTable';
import Accordion from './Accordion';

const FilterDetail = ({
  filter,
  showApplyButton = true,
}: {
  filter: string;
  showApplyButton?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const routeInfo = useRouteInfo();
  const projectInfo = useProjectInfo();
  const { tagIds, categorizationIdToName, flatAnnoOptions } = projectInfo;

  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  const filters = ParamUtils.getFiltersFromApiParams(
    Object.fromEntries(
      Object.entries(paramsObj).map(([key, value]) => [
        translateLokiCondition2CivetCondition(key),
        value,
      ]),
    ),
    tagIds,
  );

  const urlParams = ParamUtils.getUrlParamsForFilters(filters);

  const selectedLabels = (filters.find(f => f.filterBy === 'labelId')?.options ?? []).map(
    ({ value }: any) => value,
  ) as string[];
  const hasNoFiltersAndSearch = !filters.length;

  const [showAllLabelIds, setShowAllLabelIds] = useState(false);

  const handleClickFilterSearch = () => {
    if (hasNoFiltersAndSearch) return;
    routeInfo.history.push(
      `/${routeInfo.urlMatchInfo.accountName}/label/project/${
        routeInfo.urlMatchInfo.projectId
      }/labels?${new URLSearchParams(urlParams).toString()}`,
    );
  };

  const getSelectedLabelsList = () => {
    if (selectedLabels.length === 1) {
      return <Typography variant="m-regular">{selectedLabels}</Typography>;
    }
    return (
      <>
        <Box
          className="clickable"
          display="flex"
          alignItems="center"
          gap="4px"
          onClick={() => setShowAllLabelIds(!showAllLabelIds)}
        >
          <Typography variant="m-regular">{selectedLabels[0]}</Typography>{' '}
          <Icon name="caretDown" size="13px" />
        </Box>
        <Collapse open={showAllLabelIds}>
          <Box>
            {selectedLabels.slice(1).map(label => (
              <Typography variant="m-regular" display="block" key={label}>
                {label}
              </Typography>
            ))}
          </Box>
        </Collapse>
      </>
    );
  };

  if (hasNoFiltersAndSearch) return <></>;

  const badgeProps = {
    color: 'gray-150',
    fill: 'soft',
  } as const;

  return (
    <Accordion
      title={t('export.history.filters')}
      rightContent={
        <Box style={{ margin: '-4px -8px' }}>
          {showApplyButton && (
            <Button variant="text" onClick={handleClickFilterSearch}>
              <IconV2 icon={FilterOutlined} />
              {t('export.history.button.applyFiltersToLabelList')}
            </Button>
          )}
        </Box>
      }
    >
      <GridTable>
        <GridTable.Thead>
          <GridTable.Tr px={1.5} py={1.5}>
            <GridTable.Th width="1fr" style={{ background: getColor('gray-100') }}>
              <Typography variant="m-regular">{t('shared.name')}</Typography>
            </GridTable.Th>
            <GridTable.Th width="1fr" style={{ background: getColor('gray-100') }}>
              <Typography variant="m-regular">{t('export.history.option')}</Typography>
            </GridTable.Th>
            <GridTable.Th width="6fr" style={{ background: getColor('gray-100') }}>
              <Typography variant="m-regular">
                {t('curate.analytics.metadataStats.value')}
              </Typography>
            </GridTable.Th>
          </GridTable.Tr>
        </GridTable.Thead>
        <GridTable.Tbody>
          {selectedLabels.length ? (
            <GridTable.Tr px={1.5} py={1}>
              <GridTable.Td style={{ background: getColor('gray-100') }}>
                <Typography variant="m-medium">{t('export.history.labelId')}</Typography>
              </GridTable.Td>
              <GridTable.Td style={{ background: getColor('gray-100') }} />
              <GridTable.Td style={{ background: getColor('gray-100') }}>
                <Box>{getSelectedLabelsList()}</Box>
              </GridTable.Td>
            </GridTable.Tr>
          ) : (
            <>
              {filters.map((filter, index) => {
                const options = filter.options as any[];

                return (
                  <GridTable.Tr key={`${index}-${filter.filterBy}`} px={1.5} py={1}>
                    <GridTable.Td style={{ background: getColor('gray-100') }}>
                      <Typography variant="m-strong">
                        {t(
                          `labels.filters.filterBy.${filter.filterBy}`,
                          startCase(filter.filterBy),
                        )}
                      </Typography>
                    </GridTable.Td>
                    <GridTable.Td style={{ background: getColor('gray-100') }}>
                      <Typography variant="m-regular">
                        {t(`labels.filters.condition.${filter.condition}`, filter.condition)}
                      </Typography>
                    </GridTable.Td>
                    <GridTable.Td style={{ background: getColor('gray-100') }}>
                      {options && (
                        <>
                          {options[0]?.date && (
                            <Chip {...badgeProps}>
                              {formatDate(parseDate(options[0].date, true))}
                            </Chip>
                          )}
                          {options[0]?.sdate && options[1]?.edate && (
                            <>
                              <Chip {...badgeProps}>
                                {formatDateTime(parseDate(options[0].sdate, true))}
                              </Chip>
                              {' - '}
                              <Chip {...badgeProps}>
                                {formatDateTime(parseDate(options[1].edate, true))}
                              </Chip>
                            </>
                          )}
                          {options[0]?.value &&
                            options.map(option => {
                              // Translate options of all filters that have pre-defined options
                              const shouldTranslate = Boolean(
                                allFilters?.[filter.filterBy]?.options?.length,
                              );
                              const translatedOption = !shouldTranslate
                                ? option
                                : getTranslatedOption({
                                    option,
                                    i18n,
                                    i18nKeyPrefix: `labels.filters.option.${filter.filterBy}`,
                                  });
                              return (
                                <Chip {...badgeProps} key={option.value}>
                                  {translatedOption.label}
                                </Chip>
                              );
                            })}
                          {filter.filterBy === 'annotation' && (
                            <Chip {...badgeProps}>
                              {FilterUtils.getAnnoFilterCount(
                                options,
                                categorizationIdToName,
                                flatAnnoOptions,
                              )}
                            </Chip>
                          )}
                        </>
                      )}
                    </GridTable.Td>
                  </GridTable.Tr>
                );
              })}
            </>
          )}
        </GridTable.Tbody>
      </GridTable>
    </Accordion>
  );
};
export default FilterDetail;
