import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@superb-ai/ui';

import { colors } from '../utils/colorScale';

export function ExportBadge({
  type,
  count,
  name,
  size = 'm',
}: {
  type: 'base' | 'compare';
  count?: number;
  name?: string;
  size?: 'm' | 's';
}) {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Box
        backgroundColor={colors[type]}
        style={{ width: '8px', height: '8px' }}
        borderRadius="100%"
      />
      <Typography variant={`${size}-strong`} color={colors[type]}>
        {t(`analytics.exportsAnalytics.${type}`)}
      </Typography>
      {name && (
        <Typography variant={`${size}-regular`} ml={0.5}>
          {name}
        </Typography>
      )}
      {typeof count !== 'undefined' && (
        <Typography variant={`${size}-regular`}>・ {t('labels.labelCount', { count })}</Typography>
      )}
    </Box>
  );
}
