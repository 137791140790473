import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty, orderBy as loOrderBy } from 'lodash';

import { useDetailViewInfo } from '../../../../../contexts/DetailViewContext';
import { useLabelDetailViewInfo } from '../../../../../contexts/LabelDetailViewContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import WorkappUnion, { WorkApp } from '../../../../../union/WorkappUnion';
import { LabelInterface } from '../../../../../utils/LabelInterfaceUtils';
import AnalyticsTableHeader from '../../charts/table/AnalyticsTableHeader';
import { AnalyticsTableRow } from '../../charts/table/AnalyticsTableRow';
import { useAnalyticsTableStyles } from '../../charts/table/style';
import { EMPTY_MESSAGE } from '../../config/constants';
import EmptyPlot from '../../elements/EmptyPlot';
import { BaseDatum, JsonObj } from '../../userStats/types';
import { getColumnDisplayOrder, getHeaderCells } from './helper';
import { mockTableData } from './mockTableData';

interface LabelingTimeTableProps {
  cells: string[];
  rows: BaseDatum[];
  labelInterface: LabelInterface;
  workapp: WorkApp;
  users: Record<string, string>;
  projectLabelCount: number;
}

const LabelingTimeTable: React.FC<LabelingTimeTableProps> = props => {
  const classes = useAnalyticsTableStyles();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const detailViewInfo = useDetailViewInfo();
  const labelDetailViewInfo = useLabelDetailViewInfo();
  const { t } = useTranslation();
  const { rows, labelInterface, workapp, users, projectLabelCount } = props;

  // Consider (mlimb): Set default config in another file
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDesc, setIsDesc] = useState(true);
  const [orderBy, setOrderBy] = useState('total_time');

  const columnsDisplayOrder = getColumnDisplayOrder(labelInterface, workapp);
  // const columnDisplayNames = getColumnDisplayNames(labelInterface, workapp);
  const showEmpty = isEmpty(rows);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property: string, order: 'desc' | 'asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const handleRowClick = (labelId: string) => {
    // From ThumbnailViewRow with small modification => receives labelId instead of label as input
    if (WorkappUnion.isSiesta(projectInfo.project.workapp)) {
      detailViewInfo.setLabelId(labelId);
      detailViewInfo.setIsOpen(true);
    } else {
      labelDetailViewInfo.setIsOpen(true);
      const hash = `#label_id=${labelId}`;
      routeInfo.history.push(
        `${routeInfo.history.location.pathname}${routeInfo.history.location.search}${hash}`,
      );
    }
  };

  const makeRows = (rows: JsonObj[]) => {
    const filteredUnassigned = rows.filter(row => row?.workAssignee);
    return filteredUnassigned.map(row => (
      <AnalyticsTableRow
        key={row.id + row.workAssignee}
        row={row}
        displayOrder={columnsDisplayOrder}
        handleRowClick={handleRowClick}
        users={users}
      />
    ));
  };

  const getPaginatedRows = (rows: BaseDatum[]) =>
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const sortRows = (rows: BaseDatum[], isDesc: boolean) => {
    return loOrderBy(rows, orderBy, isDesc ? 'desc' : 'asc');
  };
  const rowsPerPageOptions = rows.length < 100 ? [10, 25, 100] : [10, 25, 100, rows.length];

  const headerCells = getHeaderCells(labelInterface, workapp);
  const emptyMessage = (labelCount: number, dataLength: number): string => {
    // Refactor: polymorphism => return empty message based on chart
    // Case 1: API returned [] or null and there's 0 total labels
    if (labelCount === 0) return t(EMPTY_MESSAGE.UPLOAD_DATA);
    // Case 2: API returned [] but there's > 0 total labels.
    if (dataLength === 0) return t(EMPTY_MESSAGE.SUBMIT_LABELS);
    return t(EMPTY_MESSAGE.SELECT_LABELING_TIME);
  };

  return (
    <div className={classes.tableWrap}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('labels.rowsPerPage')}
      />
      <TableContainer component={Paper} className={classes.tableContainer}>
        {showEmpty && <EmptyPlot message={emptyMessage(projectLabelCount, rows.length)} />}
        <Table style={{ tableLayout: 'auto' }}>
          <AnalyticsTableHeader
            tableName={'labeling-time-table'}
            cells={headerCells}
            isDesc={isDesc}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {!showEmpty && makeRows(getPaginatedRows(sortRows(rows, isDesc)))}
            {showEmpty && makeRows(getPaginatedRows(mockTableData().data))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LabelingTimeTable;
