import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'lodash';

import {
  REMOVE_MEMBER_FROM_PROJECT,
  REMOVE_MEMBERS_FROM_PROJECT,
} from '../../../../consts/ModalMessage';
import {
  REMOVE_USER_FROM_PROJECT,
  REMOVE_USERS_FROM_PROJECT,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import ProjectService from '../../../../services/ProjectService';
import { MemberData } from '../../../../types/memberTypes';
import UserUtils from '../../../../utils/UserUtils';
import ManageMembers from '../../../elements/manageMembers';
import { RoleOrder } from '../../label/labelList/AssignDialog/utils';
import ProjectMemberFilters from './ProjectMemberFilters';

const ProjectMembers: React.FC = () => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [filters, setFilters] = useState({ status: '', role: '' });

  const getIsDeletable = (member: MemberData) => {
    const isCollaborators = RoleOrder[capitalize(member.projectRole || member.tenantRole)] >= 3;
    const isRoleOrderSameOrGreater =
      RoleOrder[capitalize(member.projectRole || member.tenantRole)] >=
      RoleOrder[capitalize(authInfo.projectRole || authInfo.role || undefined)];
    return isCollaborators && isRoleOrderSameOrGreater;
  };

  const getProjectMembers = useMemo(
    () => async () => {
      let users = await ProjectService.getUsersAsList({
        projectId: routeInfo.urlMatchInfo.projectId,
        statusIn: filters.status ? [filters.status] : [],
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      // Apply role filter (not supported by API yet)
      users = UserUtils.filterByRole(users, filters.role);
      return users;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, authInfo, routeInfo],
  );

  const refetchMembers = useCallback(async () => {
    await ProjectService.getUsersAsList({
      projectId: routeInfo.urlMatchInfo.projectId,
      statusIn: filters.status ? [filters.status] : [],
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  }, [authInfo.isGuest, filters.status, routeInfo.urlMatchInfo]);

  const handleDelete = async (member: MemberData) => {
    await ProjectService.deleteUserRole({
      projectId: routeInfo.urlMatchInfo.projectId,
      userId: member.email,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  };

  const FiltersElement = <ProjectMemberFilters filters={filters} setFilters={setFilters} />;

  return (
    <>
      <ManageMembers
        page="project"
        title={t('projectMembers.manageAccess')}
        columns={['name', 'email', 'role', 'lastLoginedAt', 'projectMenuArea']}
        columnsWidth={['33%', '33%', 120, 210, 60]}
        inviteButtonMsg={t('projectMembers.button.inviteToProject')}
        deleteButtonMsg={t('button.remove')}
        getIsDeletable={getIsDeletable}
        getMembers={getProjectMembers}
        refetchMembers={refetchMembers}
        Filters={FiltersElement}
        deleteMemberSnackbar={REMOVE_USER_FROM_PROJECT({ t })}
        deleteMemberModal={(name: string) => REMOVE_MEMBER_FROM_PROJECT({ t, name })}
        deleteMembersSnackbar={REMOVE_USERS_FROM_PROJECT}
        deleteMembersModal={(count: number) => REMOVE_MEMBERS_FROM_PROJECT({ t, count })}
        onDelete={handleDelete}
      />
    </>
  );
};

export default ProjectMembers;
