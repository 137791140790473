import { formatISO9075 } from 'date-fns';

import DateUtils from '../DateUtils';
import { createFilter } from './dataFilterTypes';

const dateString2Format = (date: string, hours: number) => {
  return formatISO9075(DateUtils.getDateSubtractedTimezone(new Date(date).setHours(hours)));
};

export const page = createFilter({
  key: 'page',
  toApiParams(value) {
    return [['page', value]];
  },
});

export const pageSize = createFilter({
  key: 'pageSize',
  toApiParams(value) {
    return [['pageSize', value]];
  },
});

export const key = createFilter({
  key: 'key',
  toApiParams(value) {
    return [['keyIcontains', value]];
  },
});

// Dataset name search
export const group = createFilter({
  key: 'group',
  toApiParams(value) {
    return [['groupIcontains', value]];
  },
});

// Dataset select
export const dataset = createFilter({
  key: 'dataset',
  toApiParams(value) {
    return [['groupIn', value === '[ all datasets ]' ? [] : [value as string]]];
  },
});

export const dataType = createFilter({
  key: 'dataType',
  toApiParams(value) {
    const assetTypeIn: string[] = [];
    switch (value) {
      case 'IMAGE':
        assetTypeIn.push('img-url', 'img-presigned-url');
        break;
      case 'VIDEO_FRAMES':
        assetTypeIn.push('video-presigned-url');
        break;
      case 'POINT_CLOUD_SEQUENCE':
        assetTypeIn.push('pointclouds-presigned-url');
        break;
      case 'VIDEO_STREAM':
        assetTypeIn.push('videostream-presigned-url');
        break;
    }
    return [['assetTypeIn', assetTypeIn]];
  },
});

export const project = createFilter({
  key: 'project',
  toApiParams(value, projectId) {
    switch (value) {
      case '[ all projects ]':
        return [];
      case '[ assigned to a project ]':
        return [['projectExists', true]];
      case '[ not assigned to any project ]':
        return [['projectExists', false]];
      default:
        return [['inProject', projectId ?? '']];
    }
  },
});

export const uploader = createFilter({
  key: 'uploader',
  toApiParams(value) {
    return [['createdByIn', [`${value}`]]];
  },
});

export const createdStartDate = createFilter({
  key: 'createdStartDate',
  toApiParams(value) {
    return [['createdAtGte', dateString2Format(value as string, 0)]];
  },
});

export const createdEndDate = createFilter({
  key: 'createdEndDate',
  toApiParams(value) {
    return [['createdAtLt', dateString2Format(value as string, 24)]];
  },
});

export const createdDate = createFilter({
  key: 'createdDate',
  toApiParams(value) {
    return [
      ['createdAtGte', dateString2Format(value as string, 0)],
      ['createdAtLt', dateString2Format(value as string, 24)],
    ];
  },
});

export const allFilters = {
  page,
  pageSize,
  key,
  group,
  dataset,
  dataType,
  project,
  uploader,
  createdStartDate,
  createdEndDate,
  createdDate,
} as const;

export type FilterKey = keyof typeof allFilters;
export type DataFilter = (typeof allFilters)[FilterKey];
