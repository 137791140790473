import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CaretDown, CaretRight, WarningOutline } from '@superb-ai/icons';
import { Box, Collapse, Icon, IconButton, Typography } from '@superb-ai/ui';
import qs from 'qs';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { HistoryType } from '../../../../types/exportTypes';
import { translateLokiCondition2CivetCondition } from '../../../../utils/filter/labelFilter';
import ParamUtils from '../../../../utils/ParamUtils';
import ServiceUtils from '../../../../utils/ServiceUtils';
import CountTableAccordion from './AnnotationCountTable';
import FilterDetail from './FilterDetail';

const HistoryInfoDetail: React.FC<{ history: HistoryType }> = props => {
  const { t } = useTranslation();
  const { history } = props;
  const { filter } = history;
  const { tagIds } = useProjectInfo();
  const [open, setOpen] = useState(false);

  if (!filter) return <></>;

  const paramsObj = ServiceUtils.toCamelCaseKeys(qs.parse(filter));
  const filters = ParamUtils.getFiltersFromApiParams(
    Object.fromEntries(
      Object.entries(paramsObj).map(([key, value]) => [
        translateLokiCondition2CivetCondition(key),
        value,
      ]),
    ),
    tagIds,
  );

  const totalObjectCount =
    history.annotations?.objectClass.results.reduce((prev, { count }) => prev + count, 0) ?? 0;
  const totalCategoryCount =
    history.annotations?.category.results.reduce((prev, { count }) => prev + count, 0) ?? 0;

  return (
    <Box py={1} px={2} backgroundColor="gray-100">
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        cursor="pointer"
        onClick={() => setOpen(!open)}
      >
        <IconButton variant="text" size="s" icon={open ? CaretDown : CaretRight} />
        <Typography variant="s-regular">
          {t('export.history.accordionSummary', {
            filterCount: filters.length,
            annotationCount: totalObjectCount + totalCategoryCount,
          })}
        </Typography>
      </Box>
      <Collapse open={open}>
        <Box display="flex" flexDirection="column" gap={1} mt={0.5} pl={3} py={1.5}>
          <FilterDetail filter={filter} />
          {history.annotations ? (
            <CountTableAccordion
              annotations={history.annotations}
              totalObjectCount={totalObjectCount}
              totalCategoryCount={totalCategoryCount}
            />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              gap={0.5}
              py={1}
              px={1.5}
              backgroundColor="primary-100"
            >
              <Icon icon={WarningOutline} color="primary" size={16} />
              <Typography color="primary" variant="m-regular">
                {t('export.history.annotationCountNotExist')}
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
export default HistoryInfoDetail;
