import '@elastic/charts/dist/theme_light.css';

import {
  Axis,
  BarSeries,
  Chart,
  DataSeriesDatum,
  PartialTheme,
  Position,
  ScaleType,
  Settings,
} from '@elastic/charts';

import { ChartDatum } from '../../../../../apps/Curate/components/datasets/dataset/analytics/charts/types';

type ChartProps = {
  data: ChartDatum[];
  config?: {
    xKey: string;
    yKey: string;
    styleAccessor: (datum: DataSeriesDatum) => string | string;
  };
  theme?: PartialTheme;
};

const defaultTheme: PartialTheme = {
  chartMargins: { top: 10, left: 10, bottom: 10, right: 10 },
  axes: {
    tickLabel: {
      fontSize: 13,
    },
  },
};

const Barchart = ({ data, config }: ChartProps): JSX.Element => {
  return (
    <Chart size={{ width: '100%', height: '100%' }}>
      <Settings theme={defaultTheme} />
      <Axis id="xaxis" position={Position.Bottom} title="" />
      <Axis
        id="yaxis"
        position={Position.Left}
        title="Count"
        tickFormat={(d: any) => Number(d).toFixed(0)}
      />

      <BarSeries
        id="Count"
        data={data}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor={config?.xKey ?? 'key'}
        yAccessors={[config?.yKey ?? 'count']}
        styleAccessor={config?.styleAccessor}
      />
    </Chart>
  );
};

export default Barchart;
