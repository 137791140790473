import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Download } from '@superb-ai/icons';
import { Box, IconButton, Tooltip, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../analyticsTracker';
import { useNoScrollHeight } from '../../../../../../hooks/NoScrollHeightHook';
import FileUtils from '../../../../../../utils/FileUtils';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { PageSet } from '../../../../types/routeTypes';
import { DropdownLegend } from '../views/embedding/scatterView/components/DropdownLegend';
import { getCompareMode, getLegendTooltip, getTextByDataScope } from './charts/utils';
import { LegendLabel, SingleLegend } from './components/Legends';
import { LoadingIndicatorDiv } from './components/LoadingIndicatorDiv';
import { useAnalyticsContext } from './contexts/AnalyticsContext';
import { useMetadataAnalyticsContext } from './contexts/MetadataContext';
import { MetadataChartArea } from './MetadataChartArea';
import MetadataKeyTableArea from './MetadataKeyTableArea';
import { useStatsMetadataValueQuery } from './queries/metadataQueries';
import { DataScope } from './types';
import { getChartColor, getSuiteColor } from './utils/colorScale';
import { formatKeyDownload, getDownloadFilePrefix } from './utils/download';
import { CompareOption, getCompareOption } from './utils/utils';

export function MetadataContainer() {
  const { accountName } = useParams<{ accountName: string }>();
  const { datasetInfo } = useDatasetContext();
  const { queryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const currentPageSet = sliceInfo ? 'slice' : ('dataset' as PageSet);
  const currentPageName =
    (currentPageSet === 'dataset' ? datasetInfo?.name : sliceInfo?.name) ?? '';
  const showCompareMode = getCompareMode(currentPageSet, queryString);

  const { t } = useTranslation();
  const {
    selectedMetadataKey,
    setSelectedMetadataKey,
    isKeySelected,
    selectionKeyData,
    isFetchingSelectionKey,
    isFetchingComparedKey,
    getValueQueryDependencies,
  } = useMetadataAnalyticsContext();
  const { isCompareMode, setIsCompareMode, selectedCompareSetId, setSelectedCompareSetId } =
    useAnalyticsContext();
  const containerBoxRef = useRef<HTMLDivElement | null>(null);
  const titleBoxRef = useRef<HTMLDivElement | null>(null);
  const calculatedContainerHeight = useNoScrollHeight(containerBoxRef);
  const [calculatedContentAreaHeight, setCalculatedContentAreaHeight] = useState<string>(); // [containerHeight, contentAreaHeight]
  const { appliedFilters, isFilterOpen } = useImageFilterContext();

  function getDownloadData(isKeySelected: boolean, isCompareMode: boolean): Record<string, any>[] {
    // Note: Does not include compared data
    if (!isKeySelected) return formatKeyDownload(selectionKeyData?.data);
    return selectionValueData?.data;
  }

  useEffect(() => {
    setSelectedMetadataKey(selectionKeyData.data[0]?.key || '');
  }, [selectionKeyData, setSelectedMetadataKey]);

  const { data: selectionValueData = { count: 0, data: [] } } = useStatsMetadataValueQuery(
    getValueQueryDependencies(selectedMetadataKey),
  );

  useEffect(() => {
    if (!titleBoxRef.current) return;

    setCalculatedContentAreaHeight(
      `calc(100% - ${(titleBoxRef.current?.clientHeight || 0) + 2}px)`,
    );
  }, [calculatedContainerHeight]);

  const handleDownload = () => {
    if (!selectionKeyData) return;
    FileUtils.exportToCsv(
      getDownloadData(isKeySelected, isCompareMode),
      null,
      getDownloadFilePrefix(
        currentPageSet,
        currentPageName,
        `metadata_${selectedMetadataKey || 'key'}`,
        'csv',
      ),
    );
    analyticsTracker.chartDownloaded({
      accountId: accountName,
      chartName: 'metadata',
      feature: 'curate-analytics',
    });
  };

  const trackChartCompare = useCallback(
    (target: 'slice' | 'dataset', interaction: 'click' | 'select-dropdown') => {
      analyticsTracker.curateDataCompared({
        accountId: accountName,
        sliceId: sliceInfo?.id,
        chartName: 'metatdata-distribution',
        comparisonTarget: target,
        interactionType: interaction,
        queryString,
        appliedFilters,
      });
    },
    [accountName, appliedFilters, queryString, sliceInfo?.id],
  );

  // TODO:  3 variables are used to represent is key is selected, clean up
  const currentDataScope = isKeySelected ? 'metadataValue' : 'metadataKey';
  const selectionLegend = {
    key: 'metadataSelection',
    legendText: getTextByDataScope(t, currentPageSet, currentPageName, queryString),
    hexColor: getChartColor(currentDataScope),
    textColor: getSuiteColor(currentDataScope),
    visible: true, // always visible
  } as LegendLabel;

  const comparedLegend = {
    key: 'metadataCompared',
    legendText: getTextByDataScope(t, currentPageSet, currentPageName),
    hexColor: getChartColor(currentPageSet as DataScope),
    textColor: getSuiteColor(currentPageSet as DataScope),
    visible: isCompareMode,
    setVisibility: setIsCompareMode,
  } as LegendLabel;

  const compareOptions = [
    { group: 'Slice', id: sliceInfo?.id, name: sliceInfo?.name },
    { group: 'Dataset', id: datasetInfo?.id, name: datasetInfo?.name },
  ] as CompareOption[];

  const getComparedGroup = (id: string) => {
    return getCompareOption(compareOptions, id)?.group ?? 'Dataset';
  };
  const getComparedLegend = () => {
    if (currentPageSet === 'dataset') {
      return (
        <SingleLegend
          tooltipContent={t(`curate.data.${currentPageSet}`)}
          legendLabel={comparedLegend}
          setCompareSetId={setSelectedCompareSetId}
          compareSetId={selectedCompareSetId}
          trackSelectEvent={() => trackChartCompare('dataset', 'click')}
        />
      );
    }
    if (currentPageSet === 'slice') {
      const dropdownLegend = (
        <DropdownLegend
          legend={{
            color: getChartColor(currentPageSet as DataScope),
            selectedOptionColor: getSuiteColor(currentPageSet as DataScope),
            isChecked: isCompareMode,
            handleClickLabel: () => {
              const newMode = !isCompareMode;
              setIsCompareMode(newMode);
              if (newMode === true) trackChartCompare('slice', 'click');
            },
          }}
          options={compareOptions}
          selectedOptionId={selectedCompareSetId}
          handleSelectOption={(newId: string) => {
            if (newId !== selectedCompareSetId) {
              setIsCompareMode(true);
              setSelectedCompareSetId(newId);
              const compareTarget = getComparedGroup(newId);
              trackChartCompare(
                compareTarget.toLowerCase() as 'slice' | 'dataset',
                'select-dropdown',
              );
            }
          }}
        />
      );
      return queryString ? (
        dropdownLegend
      ) : (
        <SingleLegend
          tooltipContent={t('curate.analytics.dataComparison.compareWithDataset')}
          legendLabel={{ ...comparedLegend, legendText: datasetInfo?.name || '' }}
          setCompareSetId={setSelectedCompareSetId}
          compareSetId={datasetInfo?.id || ''}
          trackSelectEvent={() => trackChartCompare('dataset', 'click')}
        />
      );
    }
    return null;
  };

  return (
    <Box ref={containerBoxRef} border="1px solid" borderColor="gray-200" borderRadius="8px">
      {calculatedContainerHeight && (
        <Box style={{ height: calculatedContainerHeight }}>
          <Box
            ref={titleBoxRef}
            display="flex"
            width="100%"
            p={1}
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="gray-250"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="l-strong" color="gray-400" pr={0.5}>
                {t('curate.analytics.distribution')}
              </Typography>
              <Tooltip content={t('curate.analytics.action.downloadCSV')} placement="bottom">
                <IconButton
                  color="black"
                  disabled={
                    isKeySelected ? selectionValueData?.count === 0 : selectionKeyData?.count === 0
                  }
                  icon={Download}
                  onClick={handleDownload}
                  variant="text"
                />
              </Tooltip>
            </Box>
            <Box display="flex" gap={2}>
              <SingleLegend
                isDisabled
                tooltipContent={getLegendTooltip(t, queryString, currentPageSet)}
                legendLabel={selectionLegend}
                setCompareSetId={setSelectedCompareSetId}
                compareSetId={selectedCompareSetId}
              />
              {showCompareMode && getComparedLegend()}
            </Box>
          </Box>
          {calculatedContentAreaHeight && (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width="100%"
              flexDirection={isFilterOpen ? 'column' : 'row'}
              style={{ height: calculatedContentAreaHeight }}
              overflow="auto"
            >
              <Box style={{ width: isFilterOpen ? '100%' : '40%' }} height="100%">
                {(isFetchingSelectionKey || isFetchingComparedKey) && <LoadingIndicatorDiv />}
                {!isFetchingSelectionKey && selectionKeyData && <MetadataKeyTableArea />}
              </Box>
              {isFilterOpen ? (
                <Box bb="1px solid" borderColor="gray-200" style={{ width: '92%' }} />
              ) : (
                <Box bl="1px solid" borderColor="gray-200" style={{ height: '92%' }} />
              )}
              <Box p={0.5} style={{ width: isFilterOpen ? '100%' : '60%' }} height="100%">
                <MetadataChartArea />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
