import React from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from '@superb-ai/norwegian-forest';
import classnames from 'classnames';
import { format } from 'date-fns';

import Emoji from '../../../consts/EmojiConst';
import { useRouteInfo } from '../../../contexts/RouteContext';

const useStyle = makeStyles(theme => ({
  paperSmall: {
    '&.MuiPaper-root': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  list: {
    marginTop: theme.spacing(1.5),
    '& .MuiListItem-root': {
      fontSize: '12px',
      width: 'calc(100% + 50px)',
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(-2.5),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
}));

type Props = {
  className: string;
};
const UsefulLinks: React.FC<Props> = props => {
  const classes = useStyle();
  const { className } = props;
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();

  const handleClickItem = (type: 'submitted' | 'today' | 'issues') => () => {
    switch (type) {
      case 'submitted':
        routeInfo.history.push(
          `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels?status=is any one of,submitted`,
        );
        break;
      case 'today':
        routeInfo.history.push(
          `/${routeInfo.urlMatchInfo.accountName}/label/project/${
            routeInfo.urlMatchInfo.projectId
          }/labels?last updated=is,${format(new Date(), 'yyyy-MM-dd')}`,
        );
        break;
      case 'issues':
        routeInfo.history.push(
          `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels?issues=open by any one of,all users`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <MUI.Paper className={classnames(classes.paperSmall, className)}>
      <Typography variant="headline4">
        {Emoji.DIZZY} {t('overview.usefulLinks.title')}
      </Typography>
      <MUI.List className={classes.list}>
        <MUI.ListItem button divider onClick={handleClickItem('submitted')}>
          <MUI.ListItemText>
            <MUI.Typography variant="body2">
              {t('overview.usefulLinks.applyFilterSubmittedLabels')}
            </MUI.Typography>
          </MUI.ListItemText>
        </MUI.ListItem>
        <MUI.ListItem button divider onClick={handleClickItem('today')}>
          <MUI.ListItemText>
            <MUI.Typography variant="body2">
              {t('overview.usefulLinks.applyFilterLabelsUpdatedToday')}
            </MUI.Typography>
          </MUI.ListItemText>
        </MUI.ListItem>
        <MUI.ListItem button divider onClick={handleClickItem('issues')}>
          <MUI.ListItemText>
            <MUI.Typography variant="body2">
              {t('overview.usefulLinks.applyFilterLabelsOpenIssues')}
            </MUI.Typography>
          </MUI.ListItemText>
        </MUI.ListItem>
      </MUI.List>
    </MUI.Paper>
  );
};

export default UsefulLinks;
