import { Placement } from '@popperjs/core';
import { Gauge, Tooltip } from '@superb-ai/norwegian-forest';

interface Props {
  difficulty: number;
  tooltipPlacement: Placement | undefined;
}

export const AutoLabelDifficultyIcon = ({ difficulty, tooltipPlacement }: Props) => {
  const getTextDifficulty = (difficulty: number): string => {
    const mapper = {
      0: 'N/a',
      1: 'Easy',
      2: 'Medium',
      3: 'Hard',
    };
    return mapper[difficulty as keyof typeof mapper];
  };
  const difficultyStates: React.ComponentProps<typeof Gauge>['states'] = [
    [0, 'good'],
    [2, 'medium'],
    [3, 'bad'],
  ];
  return (
    <Tooltip
      placement={tooltipPlacement}
      anchorEl={
        <span style={{ display: 'flex' }}>
          <Gauge vertical max={3} value={difficulty || 0} states={difficultyStates} />
        </span>
      }
    >
      {getTextDifficulty(difficulty)}
    </Tooltip>
  );
};
