import React from 'react';

import { QueryProvider } from '../../../../contexts/QueryContext';
import ExploreContentsByScope from './ExploreContentsByScope';

export default function Layout() {
  return (
    <QueryProvider>
      <ExploreContentsByScope />
    </QueryProvider>
  );
}
