import { useHistory, useParams } from 'react-router';

import { Box } from '@superb-ai/ui';

import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import { useDiagnosisDetailQuery } from '../../../../../../queries/diagnosisModelQueries';
import { isNotActivated } from '../../diagnosisUnion';
import DeactivateDiagnosisButton from '../DeactivateDiagnosisButton';
import { isDiagnosisInprogress } from '../types';
import UpdateDiagnosisButton from '../UpdateDiagnosisButton';
import { useDiagnosisPathname } from '../useGetUrl';
import ModelsInfoBanner from './ModelInfoBanner';
import ModelSelectDropdown from './ModelSelectDropdown';

export default function ModelsInfoArea(): JSX.Element {
  const { diagnosisList, selectedDiagnosis, setJobInitiatedTimestamp, jobInitiatedTimestamp } =
    useDiagnosisModelContext();
  const history = useHistory();
  const { datasetId } = useParams<{ datasetId: string }>();

  const isNotInProgress =
    selectedDiagnosis && !jobInitiatedTimestamp && !isDiagnosisInprogress(selectedDiagnosis.status);

  const updateButtonIsVisible = isNotInProgress && !isNotActivated(selectedDiagnosis);
  const deactivateButtonIsVisible = isNotInProgress && !isNotActivated(selectedDiagnosis);
  const diagnosisPathName = useDiagnosisPathname();

  const handleSelectDiagnosis = (newDiagnosisId: string) => {
    history.push(`/${diagnosisPathName}${newDiagnosisId}`);
  };

  const detailData = useDiagnosisDetailQuery({
    datasetId,
    diagnosisId: selectedDiagnosis?.id,
    diagnosisStatus: selectedDiagnosis?.status,
  });

  return (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="gray-200"
        backgroundColor={'gray-100'}
        display="flex"
        alignItems="center"
        gap={2}
        px={2}
        py={1}
      >
        {diagnosisList && (
          <ModelSelectDropdown
            handleSelectDiagnosisId={handleSelectDiagnosis}
            selectedDiagnosisId={selectedDiagnosis?.id}
          />
        )}
        <Box display="flex" ml="auto" gap={0.5} mr={2}>
          {updateButtonIsVisible && (
            <UpdateDiagnosisButton
              selectedDiagnosis={detailData.data || selectedDiagnosis}
              setJobInitiatedTimestamp={setJobInitiatedTimestamp}
            />
          )}
          {deactivateButtonIsVisible && (
            <DeactivateDiagnosisButton showIcon selectedDiagnosis={selectedDiagnosis} />
          )}
        </Box>
      </Box>
      <ModelsInfoBanner />
    </>
  );
}
