import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@superb-ai/norwegian-forest';

import ChangePasswordDialog from './ChangePasswordDialog';

const Avatar: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickChangePasswordButton = () => {
    setIsOpen(true);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      py={3.5}
      px={3}
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="headline5">{t('settings.profile.password')}</Typography>
        <Typography variant="body3" themedColor={['grey', 500]}>
          {t('settings.profile.newPasswordMessage')}
        </Typography>
      </Box>
      <Button style={{ flex: 'none', height: '32px' }} onClick={handleClickChangePasswordButton}>
        {t('settings.profile.changePassword')}
      </Button>
      <ChangePasswordDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
};

export default Avatar;
