import { TFunction } from 'react-i18next';

export const IntegrationListColumns = (t: TFunction) => {
  t;
  return {
    // [width, title]
    S3: [
      [100, t('integration.listColumn.status')],
      [120, t('integration.listColumn.name')],
      [234, t('integration.listColumn.bucket')],
      [140, t('integration.listColumn.roleName')],
      [140, t('integration.listColumn.externalIdAndKey')],
      [100, t('integration.listColumn.createdAt')],
      [150, t('integration.listColumn.createdBy')],
      [84, ''],
    ],
    GS: [
      [100, t('integration.listColumn.status')],
      [120, t('integration.listColumn.name')],
      [222, t('integration.listColumn.bucket')],
      [132, t('integration.listColumn.clientEmail')],
      [100, t('integration.listColumn.createdAt')],
      [150, t('integration.listColumn.createdBy')],
      [84, ''],
    ],
    ABS: [
      [100, t('integration.listColumn.status')],
      [120, t('integration.listColumn.name')],
      [222, t('integration.listColumn.container')],
      [120, t('integration.listColumn.key')],
      [100, t('integration.listColumn.createdAt')],
      [150, t('integration.listColumn.createdBy')],
      [84, ''],
    ],
    SLACK: [
      [120, t('integration.listColumn.project')],
      [424, t('integration.listColumn.url')],
      [100, t('integration.listColumn.createdAt')],
      [150, t('integration.listColumn.createdBy')],
      [84, ''],
    ],
  };
};
