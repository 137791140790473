import { SuiteLogo } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../analyticsTracker/pageIds';
import { CreateMenuItem } from '../menu/MenuItem';
import LabelLayout from './LabelLayout';
import { NewProjectMenuItem } from './new-project/MenuItem';
import { ProjectsMenuItem } from './projects/MenuItem';
import { DataListMenuItem } from './projects/project/data/list/MenuItem';

export const LabelMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelInitialPage, // not tracked
  path: 'label',
  matchParam: ':suite(label)',
  icon: <SuiteLogo type="label" />,
  group: 'suite',
  title: ({ t }) => t('label.title'),
  component: LabelLayout,
  children: [ProjectsMenuItem, NewProjectMenuItem, DataListMenuItem],
});
