import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { map } from 'lodash';

import ProjectConst from '../../consts/ProjectConst';

const useStyles = makeStyles(() => ({
  colorChipContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorChipBox: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 2,
    borderRadius: '5px',
    background: '#fff',
    padding: '5px 8px',
  },
  colorChip: {
    position: 'absolute',
    left: '0',
    borderRadius: '50%',
    cursor: 'pointer',
    border: 'solid 1px #fff',
  },
}));

interface Props {
  direction: string;
  chipSize: string;
  selectedColor: string;
  handleClickColorChip: (color: string) => void;
}

const ColorChipSelectBox: React.FC<Props> = props => {
  const classes = useStyles();
  const { direction, chipSize = '8px', selectedColor } = props;

  const [isOpen, setIsOpen] = useState(false);

  const interval = parseInt(chipSize, 10) * 1.5;

  const width = (() => {
    if (direction === 'row') {
      if (!isOpen) return chipSize;
      return interval * ProjectConst.COLOR_CHIP_PALETTE.length - parseInt(chipSize, 10) / 2;
    }
    return chipSize;
  })();

  const height = (() => {
    if (direction === 'row') return chipSize;
    return interval * ProjectConst.COLOR_CHIP_PALETTE.length - parseInt(chipSize, 10) / 2;
  })();

  const handleClickColorChip = (color: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isOpen) {
      props.handleClickColorChip(color);
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const getCurrentColor = () => {
    const style = {
      background: selectedColor,
      width: chipSize,
      height: chipSize,
      ...(direction === 'row' ? { left: 0 } : { top: 0 }),
    };
    return (
      <MUI.Box
        className={classes.colorChip}
        style={style}
        onClick={handleClickColorChip(selectedColor)}
      />
    );
  };

  const getCandidateColors = () => {
    if (!isOpen) return null;
    return (
      <MUI.Box
        className={classes.colorChipBox}
        style={{ width, height, left: interval + 5 }}
        boxShadow={1}
      >
        {map(ProjectConst.COLOR_CHIP_PALETTE, (color, index) => {
          const pos = index * interval + 5;
          const style = {
            width: chipSize,
            height: chipSize,
            background: color,
            ...(direction === 'row' ? { left: pos } : { top: pos }),
          };
          return (
            <MUI.Box
              key={index}
              className={classes.colorChip}
              style={style}
              onClick={handleClickColorChip(color)}
            />
          );
        })}
      </MUI.Box>
    );
  };

  return (
    <MUI.ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <MUI.Box style={{ position: 'relative', left: 0 }}>
        <MUI.Box className={classes.colorChipContainer}>
          {getCurrentColor()}
          {getCandidateColors()}
        </MUI.Box>
      </MUI.Box>
    </MUI.ClickAwayListener>
  );
};

export default ColorChipSelectBox;
