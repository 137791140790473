import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { Search } from '@superb-ai/icons';
import { Box, Button, Chip, extendComponent, Icon, Input, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import analyticsTracker from '../../../../analyticsTracker';
import { Row } from '../../../../components/elements/Row';
import { useDebounce } from '../../../../hooks/DebounceHook';
import { getUrl } from '../../../../routes/util';
import { GENERATION_BASELINE_MODEL, MODEL_TRAIN } from '../../path';
import { useModelListQuery } from '../../queries/modelQueries';
import { MyModelList, MyModelListHeader } from '../../recognition-ai/list/Layout';
import { ModelListSortOrder, ModelStatus, TaskType } from '../../services/types';
import { fromQuery, TrainQueryKeyword } from '../../train/queries';

export function Layout() {
  const myModelsQuery = useModelListQuery({
    params: {
      modelPurpose: 'generation',
    },
  });

  const { data: myModelData } = myModelsQuery;
  const myModelList = myModelData?.pages.flatMap(p => p.modelList);
  if (myModelList && myModelList.length === 0) {
    return <Intro />;
  }
  return (
    <>
      <ModelList />
    </>
  );
}

const Intro = () => {
  const history = useHistory();
  const { params } = useRouteMatch<{ accountName: string }>();
  const { t, i18n } = useTranslation();

  const videoSrc = `/static/video/${i18n.language}-gen-ai-docs-demo.mp4`;
  return (
    <Box style={{ paddingTop: 155, paddingInline: 90 }}>
      <Row justifyContent="center" textAlign="center" mb={4}>
        <Typography variant="h1">{t('model.generativeAi.howToUse.paragraph4Title')}</Typography>
      </Row>
      <Row display="grid" style={{ height: 226, gridTemplateColumns: '1fr 1fr', columnGap: 32 }}>
        <video style={{ width: '100%', height: '100%' }} controls>
          <source src={videoSrc} type="video/mp4" />
        </video>
        <Box display="flex" flexDirection="column" gap={1}>
          <OrderListRow>
            <Typography variant="m-medium">1.</Typography>
            <Typography variant="m-medium">
              {t('model.generativeAi.howToUse.paragraph4SubTitle1')}
            </Typography>
          </OrderListRow>
          <OrderListRow>
            <Typography variant="m-medium">2.</Typography>
            <Typography variant="m-medium">
              {t('model.generativeAi.howToUse.paragraph4SubTitle2')}
            </Typography>
          </OrderListRow>
          <OrderListRow>
            <Typography variant="m-medium">3.</Typography>
            <Typography variant="m-medium">
              {t('model.generativeAi.howToUse.paragraph4SubTitle3')}
            </Typography>
          </OrderListRow>
          <OrderListRow>
            <Typography variant="m-medium">4.</Typography>
            <Typography variant="m-medium">
              {t('model.generativeAi.howToUse.paragraph4SubTitle4')}
            </Typography>
          </OrderListRow>
          <Row gap={1}>
            <Button
              variant="strong-fill"
              color="gray"
              onClick={() => {
                history.push(getUrl([params.accountName, GENERATION_BASELINE_MODEL]));
              }}
            >
              {t('model.generativeAi.viewDetail')}
            </Button>
            <Button
              variant="strong-fill"
              color="primary"
              onClick={() => {
                analyticsTracker.trainModelEntered({
                  accountId: params.accountName,
                  referrer: 'entered-from-gen-ai-intro',
                });
                history.push(
                  getUrl(
                    [params.accountName, MODEL_TRAIN],
                    {},
                    { [TrainQueryKeyword.From]: fromQuery.genAI },
                  ),
                );
              }}
            >
              {t('model.train.trainNewModel')}
            </Button>
          </Row>
        </Box>
      </Row>
    </Box>
  );
};

const OrderListRow = extendComponent(Box, {
  display: 'flex',
  alignItems: 'flex-start',
  gap: 0.5,
});

const ModelList = () => {
  const { t } = useTranslation();
  const { accountName } = useParams<{
    accountName: string;
  }>();
  const { params } = useRouteMatch<{ accountName: string }>();
  const history = useHistory();

  const [datasetIdFilter, setDatasetIdFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<ModelStatus[]>([]);
  const [searchModelNameFilter, setSearchModelNameFilter] = useState('');
  const [datasetTaskFilter, setDatasetTaskFilter] = useState<TaskType[]>([]);
  const [modelHubFilter, setModelHubFilter] = useState<string[]>([]);
  const [previousModelNameFilter, setPreviousModelNameFilter] = useState<string[]>([]);
  const [previouseModelName, setPreviouseModelName] = useState<string>('');
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [tagName, setTagName] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [hasEndpoints, setHasEndpoints] = useState(false);
  const [createdAtSort, setCreatedAtSort] = useState<ModelListSortOrder>('desc');

  const debouncedModelNameFilter = useDebounce(searchModelNameFilter, 300);

  const myModelsQuery = useModelListQuery({
    params: {
      name: debouncedModelNameFilter,
      status: statusFilter,
      task: datasetTaskFilter,
      referenceId: datasetIdFilter,
      modelHub: modelHubFilter,
      previousModel: previousModelNameFilter,
      tag: tagFilter,
      sortOrder: createdAtSort,
      modelPurpose: 'generation',
    },
  });

  const { data: myModelData, fetchNextPage, hasNextPage, isLoading } = myModelsQuery;
  const myModelList = useMemo(
    () =>
      myModelData?.pages
        .flatMap(p => p.modelList)
        .filter(model => (hasEndpoints ? model.endpointsCount > 0 : true)) ?? [],
    [hasEndpoints, myModelData?.pages],
  );

  return (
    <>
      <Row backgroundColor={'secondary-100'} px={2} py={1} justifyContent="space-between" mb={2}>
        <Box>
          <Row mb={1}>
            <Image
              src={'/static/image/ReCo-logo.png'}
              width={16}
              height={16}
              alt="recologo"
              style={{ marginRight: 4 }}
            />
            <Typography variant="h3">ReCo</Typography>
            <Chip fill="strong" color="secondary" style={{ marginLeft: 8 }}>
              {t('model.datasetType.image_generation')}
            </Chip>
          </Row>
          <Typography variant="m-regular">
            {t('model.baselineModels.info.reco.description')}
          </Typography>
        </Box>
        <Row gap={1}>
          <Button
            variant="strong-fill"
            color="gray"
            onClick={() => {
              history.push(getUrl([accountName, GENERATION_BASELINE_MODEL]));
            }}
          >
            {t('model.generativeAi.viewDetail')}
          </Button>
          <Button
            variant="strong-fill"
            color="primary"
            onClick={() => {
              analyticsTracker.trainModelEntered({
                accountId: params.accountName,
                referrer: 'entered-from-model-list',
              });
              history.push(
                getUrl(
                  [params.accountName, MODEL_TRAIN],
                  {},
                  { [TrainQueryKeyword.From]: fromQuery.genAI },
                ),
              );
            }}
          >
            {t('model.train.trainNewModel')}
          </Button>
        </Row>
      </Row>
      <Box mb={1} style={{ width: 357 }}>
        <Input
          prefix={<Icon icon={Search} />}
          placeholder={t('model.train.modelSearchPlaceholder')}
          color={'gray'}
          variant="soft-fill"
          value={searchModelNameFilter}
          onChange={e => setSearchModelNameFilter(e.target.value)}
        />
      </Box>
      <MyModelListHeader
        statusFilter={statusFilter}
        hasEndpoints={hasEndpoints}
        datasetName={datasetName}
        datasetTaskFilter={datasetTaskFilter}
        datasetIdFilter={datasetIdFilter}
        modelHubFilter={modelHubFilter}
        previousModelNameFilter={previousModelNameFilter}
        previouseModelName={previouseModelName}
        tagFilter={tagFilter}
        tagName={tagName}
        createdAtSort={createdAtSort}
        setDatasetIdFilter={setDatasetIdFilter}
        setStatusFilter={setStatusFilter}
        setSearchModelNameFilter={setSearchModelNameFilter}
        setDatasetTaskFilter={setDatasetTaskFilter}
        setModelHubFilter={setModelHubFilter}
        setPreviousModelNameFilter={setPreviousModelNameFilter}
        setPreviouseModelName={setPreviouseModelName}
        setTagFilter={setTagFilter}
        setTagName={setTagName}
        setDatasetName={setDatasetName}
        setHasEndpoints={setHasEndpoints}
        setCreatedAtSort={setCreatedAtSort}
        hasPerformance={false}
      />
      <MyModelList
        myModelList={myModelList}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        path={`/${accountName}/model/generation`}
        hasPerformance={false}
      />
    </>
  );
};
