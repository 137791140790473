import React, { useState } from 'react';

import { StateGetterSetter } from '../../../../../../../contexts/types';

type ContextProps = StateGetterSetter<['chartAreaSize', 'setChartAreaSize'], ChartAreaSize>;
export type ChartAreaSize = { width: number; height: number };

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [chartAreaSize, setChartAreaSize] = useState<ChartAreaSize>({
    width: 0,
    height: 0,
  });

  return {
    chartAreaSize,
    setChartAreaSize,
  };
};

export const useChartAreaContext = (): ContextProps => {
  return React.useContext(Context);
};

export const ChartAreaProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
