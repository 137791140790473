import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Box } from '@superb-ai/ui';

import {
  CloseIframeFromSuite,
  IFrameMessageFromSuite,
} from '../../../apps/annotationApp/providers/PopupModePostMessageProvider';
import { useDetailViewInfo } from '../../../contexts/DetailViewContext';
import { useProjectInfo } from '../../../contexts/ProjectContext';
import { getDetailViewUrl } from '../../../utils/WorkappUtils';

const Layout = (): React.ReactElement => {
  const projectInfo = useProjectInfo();
  const detailViewInfo = useDetailViewInfo();
  const [initUrl, setInitUrl] = useState('');

  useEffect(() => {
    const initUrl = getDetailViewUrl({
      workapp: projectInfo.project.workapp,
      appEnv: 'suite-popup',
    });
    setInitUrl(initUrl);
  }, [projectInfo.project.workapp]);

  useEffect(() => {
    if (!detailViewInfo.isConnected) {
      return;
    }
    if (detailViewInfo.hasOpenOnce) {
      return;
    }
    if (detailViewInfo.iframeRef.current && detailViewInfo.isOpen) {
      detailViewInfo.setHasOpenOnce(true);
      detailViewInfo.iframeRef.current.contentWindow.postMessage({
        name: 'open-iframe',
        sender: 'suite',
        receiver: 'annotation-app',
        payload: {
          projectId: projectInfo.project.id,
          labelId: detailViewInfo.labelId,
          // TODO
          prevAndNextLabelIds: detailViewInfo.getPrevAndNextLabelIds(detailViewInfo.labelId),
        },
      }) as IFrameMessageFromSuite;
    }
  }, [
    detailViewInfo,
    detailViewInfo.hasOpenOnce,
    detailViewInfo.iframeRef,
    detailViewInfo.isOpen,
    detailViewInfo.labelId,
    detailViewInfo.isConnected,
    projectInfo.project.id,
  ]);
  // 아마 [detailViewInfo, projectInfo]로만 해도 될 것임

  const handleClosePopup = useCallback(() => {
    if (!detailViewInfo.isConnected) {
      detailViewInfo.setIsOpen(false);
      detailViewInfo.setLabelId('');
      return;
    }

    // send to new siesta
    detailViewInfo.iframeRef.current.contentWindow.postMessage(
      {
        sender: 'suite',
        receiver: 'annotation-app',
        name: 'close-iframe',
      } as CloseIframeFromSuite,
      '*',
    );
  }, [detailViewInfo]);

  useEffect(() => {
    if (detailViewInfo.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [detailViewInfo.isOpen]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') {
        return;
      }
      handleClosePopup();
    };
    if (detailViewInfo.isOpen) {
      document.addEventListener('keydown', handleKeydown);
    }
    return () => {
      if (detailViewInfo.isOpen) {
        document.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [detailViewInfo, handleClosePopup]);

  return createPortal(
    <Box
      position="fixed"
      top="0"
      right="0"
      left="0"
      bottom="0"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1200,
        padding: '48px',
        display: detailViewInfo.isOpen ? 'flex' : 'none',
      }}
      onClick={handleClosePopup}
      cursor="pointer"
    >
      <Box
        style={{
          width: '100%',
          minWidth: '1280px',
          height: '100%',
          minHeight: '680px',
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <iframe
          style={{
            width: '100%',
            height: '100%',
            outline: 'none',
            border: 'none',
            userSelect: 'none',
          }}
          onLoad={() => {
            detailViewInfo.iframeRef.current.focus();
          }}
          ref={detailViewInfo.iframeRef}
          src={initUrl}
        />
      </Box>
    </Box>,
    document.getElementById('modal-root') as HTMLElement,
  );
};

export default Layout;
