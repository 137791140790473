import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import Color from 'color';
import { lowerCase } from 'lodash';
import { Cell, Label, Pie, PieChart } from 'recharts';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { LabelStatus } from '../../../../types/labelTypes';
import LabelUtils from '../../../../utils/LabelUtils';
import { LabelStatusCount } from '../types';

const useStyle = makeStyles(() => ({
  label: {
    fontSize: '28px',
    fontWeight: 500,
    cursor: 'default',
  },
  subLabel: {
    fontSize: '10px',
  },
  pie: {
    cursor: 'pointer',
  },
}));

type Props = {
  status: LabelStatusCount[];
  totalCount: number;
  colorPickerFn: (name: LabelStatus) => string;
};

function lightenIfNotActive(color: string, index: number, activeIndex: number | undefined) {
  if (activeIndex === undefined || activeIndex === index) {
    return color;
  }
  return Color(color).lighten(0.35).string();
}

const StatusPieChart: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { status, totalCount, colorPickerFn } = props;
  const classes = useStyle();
  const theme = useTheme();
  const history = useHistory();
  const routeInfo = useRouteInfo();

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  // Move to higher component
  const handleClickPie = (curStatus: LabelStatusCount) => {
    const status = lowerCase(LabelUtils.getStatusKey(curStatus.name));
    history.push(
      `/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}/labels?status=is any one of,${status}`,
    );
  };

  const activeValue =
    activeIndex !== undefined ? status[activeIndex].value : totalCount.toLocaleString('en');

  const size = 156;
  const pieWidth = 12;

  const labelText = `${activeValue.toLocaleString('en')}`;
  const labelValue = (
    <Label
      content={
        <text width="30" x={size / 2} y={size / 2} className={classes.label} textAnchor="middle">
          <tspan x={size / 2} dy="4px">
            {labelText}
          </tspan>
        </text>
      }
    />
  );
  const labelLabel = (
    <Label
      content={
        <text width="30" x={size / 2} y={size / 2} className={classes.subLabel} textAnchor="middle">
          <tspan x="79" dy="22px">
            {activeIndex !== undefined
              ? t(LabelUtils.getStatusText(status[activeIndex].name))
              : t('analytics.text.total')}
          </tspan>
        </text>
      }
    />
  );

  return (
    <PieChart width={158} height={158}>
      {totalCount ? (
        <Pie
          className={classes.pie}
          dataKey="value"
          data={status}
          innerRadius={size / 2 - pieWidth}
          outerRadius={size / 2}
          startAngle={90}
          endAngle={-270}
          cornerRadius={0}
          paddingAngle={-2}
          blendStroke
          onClick={handleClickPie}
          onMouseEnter={(_, index) => {
            setActiveIndex(index);
          }}
          onMouseLeave={() => {
            setActiveIndex(undefined);
          }}
          activeIndex={activeIndex}
          animationDuration={500}
        >
          {labelValue}
          {labelLabel}

          {status.map((curStatus: LabelStatusCount, index: number) => (
            <Cell
              key={curStatus.name}
              fill={lightenIfNotActive(colorPickerFn(curStatus.name), index, activeIndex)}
            />
          ))}
        </Pie>
      ) : (
        <Pie
          dataKey="value"
          data={[{ name: '100', value: 100 }]}
          innerRadius={size / 2 - pieWidth}
          outerRadius={size / 2}
          startAngle={180}
          endAngle={-180}
          cornerRadius={5}
          paddingAngle={-2}
          blendStroke
          animationDuration={500}
        >
          {labelValue}
          {labelLabel}
          <Cell fill={theme.palette.grey[100]} />
        </Pie>
      )}
    </PieChart>
  );
};

export default StatusPieChart;
