/**
 * Creates an object composed of keys generated from the results of running
 * each element of `collection` thru `iteratee`. The corresponding value of
 * each key is the last element responsible for generating the key. The
 * iteratee is invoked with one argument: (value).
 *
 * const array = [
 *   { 'dir': 'left', 'code': 97 },
 *   { 'dir': 'right', 'code': 100 }
 * ]
 *
 * keyBy(array, ({ code }) => String.fromCharCode(code))
 * // => { 'a': { 'dir': 'left', 'code': 97 }, 'd': { 'dir': 'right', 'code': 100 } }
 */
export function keyBy<T, K extends string>(
  collection: readonly T[],
  iteratee: (obj: T) => K,
): Record<K, T> {
  // eslint-disable-next-line no-sequences,no-return-assign
  return collection.reduce((acc, obj) => ((acc[iteratee(obj)] = obj), acc), {} as Record<K, T>);
}

/**
 * A Counter is a Map subclass for counting objects.
 * Elements are stored as keys and their counts are stored as values.
 * This is similar to Python's collections.Counter.
 */
export class Counter<T> extends Map<T, number> {
  constructor(collectionOrMap?: readonly T[] | Map<T, number>) {
    if (collectionOrMap instanceof Map) {
      super(collectionOrMap);
    } else if (collectionOrMap) {
      super();
      this.update(collectionOrMap);
    } else {
      super();
    }
  }

  get(key: T): number {
    return super.get(key) ?? 0;
  }

  update(collectionOrMap: readonly T[] | Map<T, number>): this {
    if (collectionOrMap instanceof Map) {
      return Array.from(collectionOrMap.entries()).reduce(
        (acc, [e, c]) => acc.set(e, acc.get(e) + c),
        this,
      );
    }
    return collectionOrMap.reduce((acc, e) => acc.set(e, acc.get(e) + 1), this);
  }

  sorted(): [T, number][] {
    return Array.from(this.entries()).sort((a, b) => b[1] - a[1]);
  }

  sum(): number {
    return Array.from(this.values()).reduce((a, b) => a + b);
  }
}
