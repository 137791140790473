import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { LinearGradient } from '@superb-ai/norwegian-forest';
import { GetThemeColorInput } from '@superb-ai/norwegian-forest/dist/theme';
import * as d3 from 'd3';
import { BrushSelection, D3BrushEvent } from 'd3';
import { isEmpty, isUndefined, map } from 'lodash';

import { ScatterPlotSvgConfig } from '../../config/types';
import { VerticalLegend } from '../../elements/legend/VerticalLegend';
// components & helpers
import XAxis from '../../elements/XAxis';
import YAxis from '../../elements/YAxis';
// configs & utils & types
import { PlotDatum, ScoreType } from '../../qaAnalytics/types';
import { JsonObj } from '../../userStats/types';
import { getXLinearScale, getYLinearScale } from '../helper';
import ChartTooltip from './ChartTooltip';

interface Props {
  data: PlotDatum[];
  svgInfo: ScatterPlotSvgConfig;
  xKey: string;
  yKey: string;
  xLabelName: string;
  yLabelName: string;
  selectedData: string[];
  setData: Dispatch<SetStateAction<PlotDatum[]>>;
  onDebounceChangeData: (data: PlotDatum[]) => void;
  scoreType: ScoreType;
}

/** Styling */
/** Brush Area */
const BRUSH_STYLE = {
  fill: '#e5e5e5',
  fillOpacity: 0.5,
  stroke: '#BCBCBC',
  strokeWidth: 1,
  strokeDashArray: '2,2',
};

/** Vertical Legend */
const LEGEND_BAR_WIDTH = 4;

/** Scatter Dot */
const DEFAULT_RADIUS = 3.5;

/** TODO (ml) - move to utils? */
function isValidPosNumber(value: undefined | number): boolean {
  return !isUndefined(value) && !Number.isNaN(value) && value >= 0;
}

/**
 * Components
 *   - X Axis
 *   - Y Axis
 *   - Scatter Dots
 *   - Brush
 */
const ScatterPlot: React.FC<Props> = props => {
  const {
    data,
    svgInfo,
    xKey,
    yKey,
    xLabelName,
    yLabelName,
    onDebounceChangeData,
    selectedData,
    scoreType,
  } = props;
  const svgRef = useRef<SVGSVGElement>(null);
  const brushRef = useRef<SVGGElement>(null);
  const chartGroupRef = useRef<SVGGElement>(null);
  const chartRef = useRef<SVGGElement>(null);

  /** Define gradient id */
  const gradientId = 'scatter-plot-lg';

  /** SVG styling */
  const SVG_STYLE = {
    overflow: 'visible',
    width: svgInfo.svgWidth,
    height: svgInfo.svgHeight,
  };
  const G_TRANSFORM = `translate(${svgInfo.left},${svgInfo.top})`;

  /** React States */
  const [isBrushing, setIsBrushing] = useState<boolean>(false);

  /** Brush Extent */
  const [xExtent, setXExtent] = useState<number[]>([]);
  const [yExtent, setYExtent] = useState<number[]>([]);
  const [highlightYValueBetween, setHighlightYValueBetween] = useState<number[]>([]);

  /** Show tooltip */
  const [tooltipLocation, setTooltipLocation] = useState<number[]>([]);

  const isInBrushedArea = (xValue: number, yValue: number): boolean => {
    return (
      xExtent[0] <= xValue && xValue <= xExtent[1] && yExtent[0] <= yValue && yValue <= yExtent[1]
    );
  };

  /**
   * Default (xKey, yKey): (numSubmittedConsensusLabels, median_score)
   */
  const xValues = data.map(d => d[xKey as keyof PlotDatum]) as number[];
  const yValues = data.map(d => d[yKey as keyof PlotDatum]) as number[];

  /**
   * x & y scales
   *  - always map yScale range to [0, 100]
   **/
  const xScale = getXLinearScale(xValues, svgInfo);
  const yScale = getYLinearScale([0, 100], svgInfo, 1);

  useEffect(() => {
    setHighlightYValueBetween([]);
  }, [yKey]);

  useEffect(() => {
    const brush = d3.brush();
    const styleBrushGroup = (group: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      group
        .selectAll('rect.selection')
        .attr('id', 'consensus-scatter-plot-brush-selection-id')
        .attr('fill', BRUSH_STYLE.fill)
        .attr('fill-opacity', BRUSH_STYLE.fillOpacity)
        .attr('stroke', BRUSH_STYLE.stroke)
        .attr('stroke-dasharray', BRUSH_STYLE.strokeDashArray)
        .attr('stroke-width', BRUSH_STYLE.strokeWidth);
    };

    // function zoom() {
    // reset x and y scale
    // const t = svg.transition().duration(750);
    // svg.select('.axis--x').transition(t).call(xAxis);
    // svg.select('.axis--y').transition(t).call(yAxis);
    // svg
    //   .selectAll('circle')
    //   .transition(t)
    //   .attr('cx', function (d) {
    //     return x(d[0]);
    //   })
    //   .attr('cy', function (d) {
    //     return y(d[1]);
    //   });
    // }
    if (!(brushRef.current && chartGroupRef.current && chartRef.current)) return;

    const brushGroup = d3.select(brushRef.current);
    brush(brushGroup);
    styleBrushGroup(brushGroup);

    if (!isEmpty(highlightYValueBetween)) {
      const [y0, y1] = highlightYValueBetween.map(yScale);

      if (!Number.isNaN(y0) && !Number.isNaN(y1)) {
        brush.move(brushGroup, [
          [0, y1 as number],
          [svgInfo.svgWidth, y0 as number],
        ]);
      }
    }

    brush
      .on('brush', (event: D3BrushEvent<BrushSelection>) => {
        // highlightBrushedCircles();
        setIsBrushing(true);
        if (event?.selection) {
          const selection = event.selection;
          const [[x1, y1], [x2, y2]] = selection as [[number, number], [number, number]];

          setXExtent([xScale.invert(x1), xScale.invert(x2)]);
          setYExtent([yScale.invert(y2), yScale.invert(y1)]);

          /** Style brush selection */
          styleBrushGroup(brushGroup);
        }
      })
      .on('start', () => {
        if (!isEmpty(xExtent) && !isEmpty(yExtent)) setIsBrushing(false);
        if (!isEmpty(highlightYValueBetween)) {
          setHighlightYValueBetween([]);
        }
      });

    onDebounceChangeData(
      data.map(item => {
        const currentScore = item[yKey as ScoreType] as number;
        if (!isBrushing) {
          // eslint-disable-next-line
          const { isFiltered, ...otherProps } = item;
          if (isEmpty(highlightYValueBetween)) {
            return { ...otherProps, isFiltered: true };
          }

          // Filter, if datum is selected by clicking the legend
          const [lowerScore, upperScore] = highlightYValueBetween;
          const isInLegendScoreRange =
            highlightYValueBetween && currentScore >= lowerScore && currentScore <= upperScore;
          return { ...otherProps, isFiltered: isInLegendScoreRange };
        }

        const isInSubmittedCountRange =
          item.submittedConsensusLabelCount >= xExtent[0] &&
          item.submittedConsensusLabelCount <= xExtent[1];
        const isInScoreRange = currentScore >= yExtent[0] && currentScore <= yExtent[1];
        return { ...item, isFiltered: isInSubmittedCountRange && isInScoreRange };
      }),
    );
  }, [chartRef, brushRef, isBrushing, xExtent, yExtent, highlightYValueBetween]);

  /** Handle events over chart */
  // const handleDotHover = (i: number, state: 'in' | 'out') => {
  // const newIndex = state === 'out' ? -100 : i;
  // setHoveredIndex(newIndex);
  // };

  const processedData = data.map(item => {
    const isSelected = selectedData.includes(item.email);
    return isSelected ? { ...item, isSelected: true } : { ...item, isSelected: false };
  });

  const dots = map(processedData, (d: JsonObj) => {
    // TODO (ml): get this from higher component?
    const labelerEmail = d['email'];
    const submittedLabelCount = d[xKey];
    const score = d[yKey];
    const inBrushedArea = isInBrushedArea(submittedLabelCount, score);

    const getFill = () => {
      if (!d.isFiltered) {
        return '#E5E5E5';
      }
      return `url(#${gradientId})`;
    };

    const getOpacity = () => {
      if (isBrushing) {
        return inBrushedArea ? 1.0 : 0.5;
      }
      return 0.8;
    };

    const getRadius = () => {
      return DEFAULT_RADIUS;
    };

    // 올챙이 알 모앙 ㅋㅋㅋ
    const getStroke = () => {
      if (d.isSelected) {
        return `url(#${gradientId})`;
      }

      return;
    };

    return (
      <g key={`g${labelerEmail}`}>
        {isValidPosNumber(d[xKey]) && isValidPosNumber(d[yKey]) && (
          <circle
            className={'circle--dot'}
            key={labelerEmail}
            cx={xScale(submittedLabelCount)}
            cy={yScale(score)}
            r={getRadius()}
            fill={getFill()}
            opacity={getOpacity()}
            stroke={getStroke()}
            strokeOpacity={0.3}
            strokeWidth={8}
            // onMouseEnter={() => handleDotHover(i, 'in')}
            // onMouseLeave={() => handleDotHover(i, 'out')}
          />
        )}
      </g>
    );
  });

  const gradientStyles = [
    { color: ['green', 100], offset: 0.0 },
    { color: ['lime', 100], offset: 0.25 },
    { color: ['yellow', 100], offset: 0.28 },
    { color: ['carrot', 100], offset: 0.72 },
    { color: ['primary', 600], offset: 0.75 },
    { color: ['primary', 800], offset: 1.0 },
  ] as { offset: number; color: GetThemeColorInput; opacity?: number | undefined }[];

  return (
    <div>
      <svg id="scatter-plot-svg" style={SVG_STYLE} ref={svgRef}>
        <VerticalLegend
          tickSize={0}
          width={LEGEND_BAR_WIDTH}
          height={svgInfo.height}
          mt={svgInfo.top}
          ml={svgInfo.left}
          mb={svgInfo.bottom}
          mr={0}
          ticks={[100, 75, 25, 0]}
          setValues={setHighlightYValueBetween}
          setTooltipLocation={setTooltipLocation}
          clickedLegendRange={highlightYValueBetween}
          gradientId={gradientId}
        />
        <XAxis
          bottom={svgInfo.bottom}
          left={svgInfo.left}
          width={svgInfo.width}
          height={svgInfo.svgHeight}
          scale={xScale as any}
          scaleType="LINEAR"
          xDisplayName={xLabelName}
          xLength={xValues.length}
          xMaxLength={d3.max(xValues) || 0}
          rotateXLabel={false}
          labelFontWeight={'normal'}
          labelFontSize={'10px'}
          labelXOffset={80}
          labelYOffset={25}
          textFontSize={'8px'}
        />
        <YAxis
          top={svgInfo.top}
          left={svgInfo.left - LEGEND_BAR_WIDTH}
          width={svgInfo.width}
          scale={yScale}
          // totalCounts={totalCounts} <- only required for the second % axis
          yDisplayName={yLabelName}
          labelFontWeight={'normal'}
          labelFontSize={'10px'}
          textFontSize={'8px'}
          labelXOffset={-14}
          labelYOffset={10}
          numTicks={10} // Tick at every 10th score
        />
        <g transform={G_TRANSFORM} width="100px" className="outer-group" ref={chartGroupRef}>
          <g ref={chartRef} className="chart">
            {!isEmpty(xValues) && !isEmpty(yValues) && dots}
          </g>
          <svg width={svgInfo.width} height={svgInfo.height}>
            <LinearGradient id={gradientId} stops={gradientStyles} />
            <g ref={brushRef} />
          </svg>
        </g>
      </svg>
      {!isEmpty(tooltipLocation) &&
        !Number.isNaN(yScale(tooltipLocation[0]) && !Number.isNaN(yScale(tooltipLocation[1]))) && (
          <ChartTooltip
            data={processedData}
            leftOffset={svgInfo.left}
            yBottom={yScale(tooltipLocation[0]) as number}
            yUp={yScale(tooltipLocation[1]) as number}
            scoreMin={tooltipLocation[0]}
            scoreMax={tooltipLocation[1]}
            scoreType={scoreType}
            chartHeight={svgInfo.svgHeight}
          />
        )}
    </div>
  );
};

export default ScatterPlot;
