import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Box as WorkappBox,
  Label,
  SecurityCheck,
  Settings,
  Terminal,
  Workflow,
} from '@superb-ai/icons';
import { Box, Icon, Typography } from '@superb-ai/ui';

export default function ValueProposition() {
  const { t } = useTranslation();
  const data = useMemo(() => {
    return [
      {
        icon: WorkappBox,
        label: t('auth.valueProposition.intuitive'),
      },
      {
        icon: Settings,
        label: t('auth.valueProposition.adaptive'),
      },
      {
        icon: Workflow,
        label: t('auth.valueProposition.quick'),
      },
      {
        icon: Terminal,
        label: t('auth.valueProposition.robust'),
      },
      {
        icon: SecurityCheck,
        label: t('auth.valueProposition.security'),
      },
      {
        icon: Label,
        label: t('auth.valueProposition.labeling'),
      },
    ];
  }, [t]);

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={4}>
      <Typography variant="h1" color="gray-400">
        <Trans i18nKey="auth.valueProposition.title" />
      </Typography>
      <Box width="100%" display="flex" flexDirection="column" gap={1} mb={4}>
        {data.map(({ icon, label }) => (
          <Box key={label} display="flex" gap={1} alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="primary-100"
              borderRadius="100%"
              style={{ width: '32px', height: '32px' }}
            >
              <Icon icon={icon} size={20} color="primary" />
            </Box>
            <Typography variant="l-strong" color="gray-400">
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
