// https://linear.app/superb-ai/issue/SE-951/rafiki-ip-whitelist를-위한-model-and-api-추가
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Copy, Search } from '@superb-ai/icons';
import {
  Box,
  Icon,
  IconButton,
  Input,
  // LinkTypography,
  LoadingIndicator,
  ToggleButton,
  Typography,
} from '@superb-ai/ui';
import copyToClipboard from 'copy-to-clipboard';
// import Link from 'next/link';
import { useSnackbar } from 'notistack';

import {
  useIpWhitelistEnabledMutation,
  useIpWhitelistEnabledQuery,
  useIpWhitelistQuery,
  useMyIpAddressQuery,
} from '../../../../queries/useIpWhitelistQuery';
import IpWhitelistTable from './IpWhitelistTable';

export default function IpWhitelist() {
  // const learnMoreLink = 'https://www.superb-ai.com';

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [toggleState, setToggleState] = useState(false);
  const { data: ipWhiteListEnabledResponse } = useIpWhitelistEnabledQuery();
  const isWhitelistEnabled = ipWhiteListEnabledResponse?.data.allowIpWhitelist ?? false;
  useEffect(() => {
    setToggleState(isWhitelistEnabled);
  }, [isWhitelistEnabled]);
  const { mutate: setIpWhitelistEnabled } = useIpWhitelistEnabledMutation(() => {
    setToggleState(toggleState);
  });

  const { data: myIpAddressResponse } = useMyIpAddressQuery({
    enabled: isWhitelistEnabled,
  });
  const unknownString = t('settings.security.ipWhitelistUnknownIp') as string;
  const myIpAdress = myIpAddressResponse?.data?.myIp ?? unknownString;

  const {
    data: ipWhitelistResponse,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useIpWhitelistQuery({});

  const [ipFilter, setIpFilter] = useState<string>('');

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h3">{t('settings.security.ipWhitelistTitle')}</Typography>
        {/* <Link href={learnMoreLink} target="_blank" rel="noopener noreferrer">
          <LinkTypography variant="m-regular">
            {t('settings.security.learnMoreMessage')}
          </LinkTypography>
        </Link> */}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
        p={3}
        borderRadius="2px"
        style={{ minHeight: 88 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="m-regular">
            {t('settings.security.ipWhitelistDescription')}
          </Typography>
          <ToggleButton
            size="s"
            checked={toggleState}
            onClick={() => {
              setToggleState(!toggleState);
              setIpWhitelistEnabled();
            }}
          />
        </Box>
        {toggleState && (
          <Box flexDirection="column" mt={2}>
            <hr style={{ color: '#DCDCDC' }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Box>
                <Box>
                  <Typography variant="l-strong">
                    {t('settings.security.ipWhitelistTableTitle')}
                  </Typography>
                </Box>
                <Box mt={1} display="flex" alignItems="center">
                  <Box>
                    <Typography variant="m-regular" color="gray-400">
                      {t('settings.security.ipWhitelistCurrentIp')}
                    </Typography>
                    <Typography variant="m-strong" color="gray-400">
                      {myIpAdress}
                    </Typography>
                  </Box>
                  <IconButton
                    size="xs"
                    variant="stroke"
                    icon={Copy}
                    style={{ marginLeft: '2px' }}
                    onClick={() => {
                      if (myIpAdress !== unknownString) {
                        enqueueSnackbar(t('settings.security.ipAddressCopied'), {
                          variant: 'success',
                        });
                        copyToClipboard(myIpAdress);
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box style={{ width: '380px' }}>
                <Input
                  variant="soft-fill"
                  color="gray"
                  prefix={<Icon icon={Search} size={16} />}
                  placeholder={t('settings.security.ipWhitelistTableSearchPlaceholder')}
                  onChange={e => {
                    setIpFilter(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Escape') {
                      setIpFilter('');
                      (e.target as HTMLInputElement).value = '';
                    }
                  }}
                />
              </Box>
            </Box>
            {!isLoading && (
              <Box mt={2}>
                <InfiniteScroll
                  hasMore={hasNextPage}
                  loadMore={() => fetchNextPage()}
                  initialLoad={false}
                  loader={<LoadingIndicator />}
                >
                  <IpWhitelistTable
                    data={ipWhitelistResponse}
                    ipFilter={ipFilter}
                    isLoading={isLoading}
                  />
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
