/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { ComponentProps, useState } from 'react';

export function ImageWithFallback({
  src,
  fallbackSrc,
  ...props
}: Omit<ComponentProps<'img'>, 'src'> & { src: string | null; fallbackSrc: string }) {
  const [isError, setIsError] = useState(false);

  if (isError || !src) {
    return <img {...props} src={fallbackSrc} />;
  }

  return <img src={src} onError={() => void setIsError(true)} {...props} />;
}
