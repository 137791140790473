import { ReactNode, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import * as RouterDOM from 'react-router-dom';

import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';
import {
  AlertModal,
  AlertModalProvider,
  ThemeProvider,
  useTheme as useNfTheme,
} from '@superb-ai/norwegian-forest';

import Theme from './assets/theme/Theme';
import Modal from './components/elements/Modal';
import StackSnackbar from './components/elements/StackSnackbar';
import AccessAuthorizationPage from './components/pages/access/AccessAuthorizationPage';
import ErrorPage from './components/pages/error/ErrorPage';
import { AuthProvider } from './contexts/AuthContext';
import { CommandBarProvider } from './contexts/commandBar';
import { ErrorProvider } from './contexts/ErrorContext';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import { ModalProvider } from './contexts/ModalContext';
import { RouteProvider } from './contexts/RouteContext';
import ErrorBoundary from './ErrorBoundary';
import SegmentLoader from './analyticsTracker/segmentLoader';
import Main from './routes/MainRoute';
import NewAuth from './routes/NewAuthRoute';
import PaymentRoutes from './routes/PaymentRoutes';
import { Route } from './routes/types';
import { CHAT_CONTAINER_ID, CHAT_IFRAME_ID } from './utils/chatWidget';
import { initExternals } from './utils/external';
import { addCredentiallessAttributeToIframe } from './utils/iframeCredentialless';

function ThemeInnerProvider({ children }: { children: ReactNode }) {
  const nfTheme = useNfTheme();
  return <MuiThemeProvider theme={{ ...Theme, ...nfTheme }}>{children}</MuiThemeProvider>;
}

const ErrorPageRoute: Route = ({ match }) => (
  <RouterDOM.Route path={match.path} component={ErrorPage} />
);
ErrorPageRoute.url = '/something_went_wrong';

const AccessAuthorizationRoute: Route = ({ match }) => (
  <RouterDOM.Route path={match.path} component={AccessAuthorizationPage} />
);
AccessAuthorizationRoute.url = '/access_authorization';

export default function App(): JSX.Element {
  useEffect(() => {
    try {
      initExternals();
    } catch (e) {
      // On dev, this code gets runs twice due to StrictMode which can cause an error.
    }
  }, []);

  useEffect(() => {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.on('widgetLoaded', () => {
          addCredentiallessAttributeToIframe(CHAT_CONTAINER_ID, CHAT_IFRAME_ID);
        });
      },
    ];
  }, []);

  return (
    <>
      <SegmentLoader />
      <ThemeProvider noGlobalStyle>
        <AlertModalProvider>
          <StyledEngineProvider injectFirst>
            <ThemeInnerProvider>
              <CookiesProvider>
                <StackSnackbar>
                  <ErrorProvider>
                    <ModalProvider>
                      <RouteProvider>
                        <AuthProvider>
                          <FeatureFlagProvider>
                            <ErrorBoundary>
                              <CommandBarProvider>
                                <RouterDOM.Switch>
                                  <RouterDOM.Route
                                    exact
                                    path={ErrorPageRoute.url}
                                    component={ErrorPageRoute}
                                  />
                                  <RouterDOM.Route
                                    exact
                                    path={AccessAuthorizationRoute.url}
                                    component={AccessAuthorizationRoute}
                                  />
                                  <RouterDOM.Route exact path="/" component={Main} />
                                  <RouterDOM.Route path={NewAuth.url} component={NewAuth} />
                                  <RouterDOM.Route path="/payments" component={PaymentRoutes} />
                                  <RouterDOM.Route path={Main.url} component={Main} />
                                </RouterDOM.Switch>
                              </CommandBarProvider>
                              <AlertModal />
                            </ErrorBoundary>
                          </FeatureFlagProvider>
                        </AuthProvider>
                      </RouteProvider>
                      <Modal />
                    </ModalProvider>
                  </ErrorProvider>
                </StackSnackbar>
              </CookiesProvider>
            </ThemeInnerProvider>
          </StyledEngineProvider>
        </AlertModalProvider>
      </ThemeProvider>
    </>
  );
}
