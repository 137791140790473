import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import Layout from '../../../../components/pages/account/advanced/Layout';
import { isOwnerOrAdmin } from '../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../menu/MenuItem';

export const AccessKeysMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountAccessSettings,
  path: 'keys',
  title: ({ t }) => t('settings.advanced.title'),
  component: Layout,
  icon: 'link2',
  isVisible: false,
  isEnabled({ authInfo }) {
    return isOwnerOrAdmin(authInfo);
  },
});
