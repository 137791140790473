import { AnnotationTypeCoordinate, Polygon } from '../../Curate/types/annotationTypes';

type Point = { x: number; y: number };
type Edge = `${number}_${number}_${number}`;

const getFace = (
  width: number,
  height: number,
  data: number[][],
  start_r: number,
  start_c: number,
  visited: boolean[][],
): [number, number][] => {
  // BFS
  const q: [number, number][] = [[start_r, start_c]];
  let tail = 0;
  visited[start_r][start_c] = true;

  while (tail < q.length) {
    const [r, c] = q[tail++];
    for (let dr = -1; dr <= 1; ++dr) {
      for (let dc = -1; dc <= 1; ++dc) {
        if (dr === 0 && dc === 0) continue;
        const [nr, nc] = [r + dr, c + dc];
        if (nr < 0 || nr >= height || nc < 0 || nc >= width) continue;
        if (data[nr][nc] === 0) continue;
        if (visited[nr][nc]) continue;

        q.push([nr, nc]);
        visited[nr][nc] = true;
      }
    }
  }
  return q;
};

const _DIRECTIONS_CW: [number, number][] = [
  [1, 0],
  [0, 1],
  [-1, 0],
  [0, -1],
]; // right, down, left, up

const trackContour = (edges: Set<Edge>, x: number, y: number, d: number): Point[] => {
  const path: [number, number, number][] = [];
  const visited_edges = new Set<Edge>();

  // track through existing edges
  while (true) {
    path.push([x, y, d]);
    visited_edges.add(`${x}_${y}_${d}` as Edge);

    for (let dd = 3; dd < 6; ++dd) {
      // equivalent to -1, 0, 1
      const [nx, ny] = [x + _DIRECTIONS_CW[d][0], y + _DIRECTIONS_CW[d][1]];
      const nd = (d + dd) % 4;
      if (edges.has(`${nx}_${ny}_${nd}` as Edge)) {
        [x, y, d] = [nx, ny, nd];
        break;
      }
    }
    if (visited_edges.has(`${x}_${y}_${d}` as Edge)) break;
  }
  for (const e of visited_edges) {
    edges.delete(e);
  }

  // omit duplicated points
  const points: Point[] = [{ x: path[0][0], y: path[0][1] }];
  for (let i = 1; i < path.length; ++i) {
    if (path[i][2] != path[i - 1][2]) {
      points.push({ x: path[i][0], y: path[i][1] });
    }
  }
  points.push(points[0]);
  return points;
};

const getContour = (
  width: number,
  height: number,
  data: number[][],
  f: [number, number][],
): Point[][] => {
  const contours: Point[][] = [];

  const edges = new Set<Edge>();
  // add all clock-wise edges
  for (const [r, c] of f) {
    // current position is non-zero
    // upper is zero
    if (r === 0 || data[r - 1][c] === 0) {
      edges.add(`${c}_${r}_0` as Edge);
    }
    // left is zero
    if (c === 0 || data[r][c - 1] === 0) {
      edges.add(`${c}_${r + 1}_3` as Edge);
    }
    // lower is zero
    if (r === height - 1 || data[r + 1][c] === 0) {
      edges.add(`${c + 1}_${r + 1}_2` as Edge);
    }
    // right is zero
    if (c === width - 1 || data[r][c + 1] === 0) {
      edges.add(`${c + 1}_${r}_1` as Edge);
    }
  }

  // starting position is left-most of upper-most non-zero value
  const [start_x, start_y, start_d] = [f[0][1], f[0][0], 0]; // this edge exists
  const ret = trackContour(edges, start_x, start_y, start_d);
  contours.push(ret);

  // find holes (unused edges)
  while (edges.size > 0) {
    const pos = edges.values().next().value;
    const [x, y, d] = pos.split('_').map(x => parseInt(x, 10));
    const ret = trackContour(edges, x, y, d);
    contours.push(ret);
  }

  return contours;
};

const base64ToImageData = (base64: string): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get 2D context'));
        return;
      }
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      resolve(imageData);
    };
    img.onerror = reject;
    img.src = base64;
  });
};

const imageDataTo2DArray = (imageData: ImageData): number[][] => {
  const { width, height, data } = imageData;
  const result: number[][] = [];

  for (let y = 0; y < height; y++) {
    const row: number[] = [];
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      // Assuming grayscale image, we only need one channel
      // You might want to adjust this based on your specific needs
      const value = data[index] > 128 ? 1 : 0; // Threshold at 128
      row.push(value);
    }
    result.push(row);
  }

  return result;
};

export const calcPolygon = async (base64: string): Promise<AnnotationTypeCoordinate<Polygon>> => {
  const imageData = await base64ToImageData(base64);
  const data = imageDataTo2DArray(imageData);
  const width = imageData.width;
  const height = imageData.height;

  const visited: boolean[][] = Array(height)
    .fill(null)
    .map(_ => Array(width).fill(false));
  const multipolygon: Point[][][] = [];

  for (let r = 0; r < height; ++r) {
    for (let c = 0; c < width; ++c) {
      if (data[r][c] === 0 || visited[r][c]) continue;

      // At first, get a connected chunk to make a face
      const f = getFace(width, height, data, r, c, visited);

      // Get contours (outer contour + holes) of the face
      const ret = getContour(width, height, data, f);

      multipolygon.push(ret);
    }
  }
  return { points: multipolygon };
};
