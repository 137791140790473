import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '../../../services';
import {
  CancelModelParams,
  CreateEndpointParams,
  CreateModelParams,
  CreateModelTagParams,
  DeployedModelListData,
  EarlyCompleteParams,
  EditEndpointSettingParams,
  EditModelParams,
  Endpoint,
  EndpointListParams,
  EndpointStatus,
  EstimatedTrainingTimeParams,
  FilterChoices as ModelFilterChoices,
  ModelHistoryData,
  ModelHistoryParams,
  ModelListData,
  ModelListParams,
  ModelStatus,
  ModelTag,
  ModelTrainingEpochs,
  MyModelDetail,
  OverviewData,
  PublicModel,
  PublicModelListParams,
  PurposeType,
  ResumeEndpointParams,
  SchedulingEndpointParams,
  TrainingSet,
} from './types';

type CreateModelData = {
  id: string;
  status: ModelStatus;
  failReason: string | null;
  createdBy: string;
};

const createModel: AfterLoginCallback<CreateModelData, CreateModelParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/model/core/api/v1/models/',
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

type EditEndpointSettingData = {
  modelId: string;
  modelName: string;
  endpointName: string;
  region: string;
  gpuType: string;
  createdAt: number;
  updatedAt: number;
};

const editEndpointSetting: AfterLoginCallback<
  EditEndpointSettingData,
  EditEndpointSettingParams
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/model/core/api/v1/endpoints/${params.data.endpointId}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
    data: { endpointName: params.data.endpointName, modelId: params.data.modelId },
  });
  return data;
};

const getPublicModelList: AfterLoginCallback<
  { count: number; data: PublicModel[] },
  PublicModelListParams
> = async params => {
  const { modelPurpose } = params.data;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/baseline-models/${modelPurpose}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });

  return { count: data.length, data };
};

const getPublicModel: AfterLoginCallback<{ data: PublicModel }, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/baseline-models/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });

  return { data };
};

const getModelList: AfterLoginCallback<ModelListData, ModelListParams> = async params => {
  const {
    size = 10,
    searchAfter,
    name,
    referenceId,
    baselineModelId,
    status,
    task,
    modelHub,
    previousModel,
    tag,
    sortOrder,
    modelPurpose,
  } = params.data;

  let url = `aggregate/model/models?size=${size}`;
  if (searchAfter) url = `${url}&search_after=${searchAfter[0]}&search_after=${searchAfter[1]}`;
  if (name) url = `${url}&name_contain=${name}`;
  if (baselineModelId) url = `${url}&baseline_model_id=${baselineModelId}`;
  if (sortOrder) url = `${url}&sort_order=${sortOrder}`;
  if (modelPurpose) url = `${url}&model_purpose=${modelPurpose}`;

  if (referenceId && referenceId.length > 0) {
    referenceId.forEach(_ => {
      url = `${url}&reference_id=${_}`;
    });
  }
  if (status && status.length > 0) {
    status.forEach(_ => {
      if (_ === 'training') {
        url = `${url}&status=PENDING`;
      }
      url = `${url}&status=${_}`;
    });
  }
  if (task && task.length > 0) {
    task.forEach(_ => {
      url = `${url}&task=${_}`;
    });
  }
  if (modelHub && modelHub.length > 0) {
    modelHub.forEach(_ => {
      url = `${url}&model_hub=${_}`;
    });
  }
  if (previousModel && previousModel.length > 0) {
    previousModel.forEach(_ => {
      url = `${url}&previous_model=${_}`;
    });
  }
  if (tag && tag.length > 0) {
    tag.forEach(_ => {
      url = `${url}&tag=${_}`;
    });
  }

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as ModelListData;
};

const getModel: AfterLoginCallback<
  MyModelDetail,
  { id: string; modelPurpose: PurposeType }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/aggregate/model/models/${params.data.modelPurpose}/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as MyModelDetail;
};

const getDeployedModels: AfterLoginCallback<DeployedModelListData, {}> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/model/core/api/v1/models/deployed',
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as DeployedModelListData;
};

const checkModelNameUnique: AfterLoginCallback<
  ModelListData,
  { id?: string; name: string; searchAfter: [number, string] }
> = async params => {
  const { searchAfter, name, id } = params.data;

  let url = `/model/core/api/v1/models?size=10&name_exact=${name}`;
  if (id) url = `${url}&self_exclude=${id}`;
  if (searchAfter) url = `${url}&search_after=${searchAfter[0]}&search_after=${searchAfter[1]}`;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });

  return data as ModelListData;
};

const deleteModel: AfterLoginCallback<{}, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: `/model/core/api/v1/models/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

const getModelHistory: AfterLoginCallback<ModelHistoryData, ModelHistoryParams> = async params => {
  const { size = 10, searchAfter } = params.data;
  let url = `/model/core/api/v1/models/${params.data.id}/model-history?size=${size}`;
  if (searchAfter) url = `${url}&search_after=${searchAfter}`;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as ModelHistoryData;
};

const editModel: AfterLoginCallback<{}, EditModelParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/model/core/api/v1/models/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
    data: { name: params.data.name },
    // accountName: params.accountName,
    // isGuest: params.isGuest,
    // origin: params.origin,
    // router: params.router,
  });
  return data;
};

const getModelTrainingResult: AfterLoginCallback<
  { trainingResult: string },
  { id: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/models/${params.data.id}/training-result`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

const getModelTrainingSet: AfterLoginCallback<
  TrainingSet & { id: string },
  { id: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/models/${params.data.id}/training-set`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

type EndpointListData = {
  endpointList: Endpoint[];
  prev: [number, string];
  next: [number, string];
};

const getEndpointsList: AfterLoginCallback<EndpointListData, EndpointListParams> = async params => {
  const { size = 10, searchAfter, name, modelId, modelPurpose } = params.data;

  let url = `/model/core/api/v1/endpoints?size=${size}`;
  if (searchAfter) url = `${url}&search_after=${searchAfter[0]}&search_after=${searchAfter[1]}`;
  if (name) url = `${url}&name_contain=${name}`;
  if (modelId) url = `${url}&model_id=${modelId}`;
  if (modelPurpose) url = `${url}&model_purpose=${modelPurpose}`;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as EndpointListData;
};

const checkEndpointNameUnique: AfterLoginCallback<
  EndpointListData,
  { id?: string; name: string; searchAfter: [number, string] }
> = async params => {
  const { searchAfter, name, id } = params.data;

  let url = `/model/core/api/v1/endpoints?size=10&name_exact=${name}`;
  if (id) url = `${url}&self_exclude=${id}`;
  if (searchAfter) url = `${url}&search_after=${searchAfter[0]}&search_after=${searchAfter[1]}`;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data as EndpointListData;
};

const getEndpointDetail: AfterLoginCallback<Endpoint, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/endpoints/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

type CreateEndpointData = {
  id: string;
  status: EndpointStatus;
  url: string;
  failReason: string;
  createdAt: number;
  updatedAt: number;
  statusUpdatedAt: number;
  model: {
    id: string;
    name: string;
  };
  endpointSetting: {
    name: string;
    region: string;
    gpuType: string;
  };
};

const createEndpoint: AfterLoginCallback<
  CreateEndpointData,
  CreateEndpointParams
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/model/core/api/v1/endpoints',
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
    data: {
      modelId: params.data.modelId,
      endpointName: params.data.endpointName,
      endpointScheduling: params.data.endpointScheduling,
    },
  });
  return data;
};

const deleteEndpoint: AfterLoginCallback<{}, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: `/model/core/api/v1/endpoints/${params.data.id}`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

const resumeEndpoint: AfterLoginCallback<{}, ResumeEndpointParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/endpoints/${params.data.id}/commands/resume`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
    data: { endpoint_scheduling: params.data.endpointScheduling },
  });
  return data;
};

const pauseEndpoint: AfterLoginCallback<{}, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/endpoints/${params.data.id}/commands/pause`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
  });
  return data;
};

const schedulingEndpoint: AfterLoginCallback<{}, SchedulingEndpointParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/model/core/api/v1/endpoints/${params.data.id}/scheduling`,
    hasPublicApi: false,
    isModelUrl: true,
    ...params,
    data: { endpoint_scheduling: params.data.endpointScheduling },
  });
  return data;
};

const postEarlyCompleteModel: AfterLoginCallback<{}, EarlyCompleteParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/models/${params.data.id}/stop`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const postCancelModel: AfterLoginCallback<{}, CancelModelParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/models/${params.data.id}/cancel`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
    data: {
      fail_reason: params.data.failReason,
    },
  });
  return data;
};

type EstimatedTrainingTimeData = { totalEstimatedTime: number };

const postEstimatedTrainingTime: AfterLoginCallback<
  EstimatedTrainingTimeData,
  EstimatedTrainingTimeParams
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/model/core/api/v1/baseline-models/calculate',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
    data: {
      baseline_model: params.data.baselineModel,
      num_training_set: params.data.numTrainingSet,
      num_validation_set: params.data.numValidationSet,
    },
  });
  return data;
};

const getModelTrainingEpochs: AfterLoginCallback<
  ModelTrainingEpochs,
  { id: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/models/${params.data.id}/training-epochs`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const postModelPin: AfterLoginCallback<{}, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/models/${params.data.id}/pin`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const postModelUnpin: AfterLoginCallback<{}, { id: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/model/core/api/v1/models/${params.data.id}/unpin`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const getModelTags: AfterLoginCallback<{ modelTags: ModelTag[] }, {}> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/model/core/api/v1/models/tag-items',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const editTagToModel: AfterLoginCallback<{}, CreateModelTagParams> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/model/core/api/v1/models/${params.data.modelId}/tags`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
    data: {
      model_tags: params.data.modelTags,
    },
  });
  return data;
};

const deleteModelTag: AfterLoginCallback<{}, { name: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'delete',
    url: '/model/core/api/v1/models/tag-item',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const createModelTag: AfterLoginCallback<{}, { modelTag: ModelTag }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: '/model/core/api/v1/models/tag-item',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
    data: {
      name: params.data.modelTag.name,
      color: params.data.modelTag.color,
    },
  });
  return data;
};

const editModelMemo: AfterLoginCallback<{}, { id: string; memo: string }> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'patch',
    url: `/model/core/api/v1/models/${params.data.id}/memo`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
    data: {
      memo: params.data.memo,
    },
  });
  return data;
};

const getModelFilterChoices: AfterLoginCallback<
  ModelFilterChoices,
  { modelPurpose?: PurposeType }
> = async params => {
  const { modelPurpose } = params.data;
  const url = `/aggregate/model/models/${modelPurpose}/filter-choices`;

  const { data } = await apiCallAfterLogin({
    method: 'get',
    url,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

const getOverview: AfterLoginCallback<OverviewData, {}> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/aggregate/model/overview',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

type EndpointFilterChoicesData = {
  modelList: { id: string; name: string }[];
};

const getEndpointFilterChoices: AfterLoginCallback<
  EndpointFilterChoicesData,
  {}
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: '/model/core/api/v1/endpoints/filter-choices',
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

export type BaselineModelSamplesData = {
  samples: {
    description: string;
    id: string;
    name: string;
    referenceImage: string;
    source: string | null;
    syntheticImages: string[];
    type: string;
  }[];
};

const getBaselineModelSamples: AfterLoginCallback<
  BaselineModelSamplesData,
  { id: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/baseline-models/${params.data.id}/samples`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

export type BaselineModelSamplesDetailData = {
  referenceImage: {
    annotations: string;
    image: { fileName: string; url: string };
  };
  syntheticImages: {
    annotations: string;
    images: {
      fileName: string;
      url: string;
    }[];
  };
};

const getBaselineModelSamplesDetail: AfterLoginCallback<
  BaselineModelSamplesDetailData,
  { id: string; domain: string }
> = async params => {
  const { data } = await apiCallAfterLogin({
    method: 'get',
    url: `/model/core/api/v1/baseline-models/${params.data.id}/samples/${params.data.domain}`,
    isModelUrl: true,
    hasPublicApi: false,
    ...params,
  });
  return data;
};

export type RecognitionEndpointInferenceImageData = {
  objects: {
    class: string;
    score: number;
    mask?: string;
    box?: number[];
  }[];
};

export type RecognitionEndpointInferenceImageParams = {
  id: string;
  imageBlob: Blob;
};

const postRecognitionEndpointInferenceImage: AfterLoginCallback<
  RecognitionEndpointInferenceImageData,
  RecognitionEndpointInferenceImageParams
> = async params => {
  const { id, imageBlob } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/endpoints/${id}/inference`,
    isModelUrl: true,
    hasPublicApi: false,
    headers: { 'Content-Type': 'image/jpeg', 'Access-Control-Allow-Origin': '*' },
    ...params,
    data: imageBlob,
    transformRequestToSnakeCase: false,
  });
  return data;
};

export type GenerationEndpointInferenceImageParams = {
  id: string;
  imageBase64: string;
  objects: { class: string; box: number[] }[];
};

export type GenerationEndpointInferenceImageData = {
  outputs: { image: string; objects: { box: number[]; class: string }[] }[];
};

const postGenerationEndpointInferenceImage: AfterLoginCallback<
  GenerationEndpointInferenceImageData,
  GenerationEndpointInferenceImageParams
> = async params => {
  const { id, imageBase64, objects } = params.data;
  const { data } = await apiCallAfterLogin({
    method: 'post',
    url: `/endpoints/${id}/inference`,
    isModelUrl: true,
    hasPublicApi: false,
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    ...params,
    data: {
      image: imageBase64,
      objects,
    },
    transformRequestToSnakeCase: false,
  });
  return data;
};

export function useModelService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getPublicModel: afterLoginFetcher(getPublicModel),
    getPublicModelList: afterLoginFetcher(getPublicModelList),
    createModel: afterLoginFetcher(createModel),
    editEndpointSetting: afterLoginFetcher(editEndpointSetting),
    getModelList: afterLoginFetcher(getModelList),
    getModel: afterLoginFetcher(getModel),
    getDeployedModels: afterLoginFetcher(getDeployedModels),
    getEndpointsList: afterLoginFetcher(getEndpointsList),
    getEndpointDetail: afterLoginFetcher(getEndpointDetail),
    getModelHistory: afterLoginFetcher(getModelHistory),
    editModel: afterLoginFetcher(editModel),
    getModelTrainingResult: afterLoginFetcher(getModelTrainingResult),
    getModelTrainingSet: afterLoginFetcher(getModelTrainingSet),
    createEndpoint: afterLoginFetcher(createEndpoint),
    deleteModel: afterLoginFetcher(deleteModel),
    checkModelNameUnique: afterLoginFetcher(checkModelNameUnique),
    checkEndpointNameUnique: afterLoginFetcher(checkEndpointNameUnique),
    deleteEndpoint: afterLoginFetcher(deleteEndpoint),
    resumeEndpoint: afterLoginFetcher(resumeEndpoint),
    pauseEndpoint: afterLoginFetcher(pauseEndpoint),
    schedulingEndpoint: afterLoginFetcher(schedulingEndpoint),
    postEarlyCompleteModel: afterLoginFetcher(postEarlyCompleteModel),
    postCancelModel: afterLoginFetcher(postCancelModel),
    postEstimatedTrainingTime: afterLoginFetcher(postEstimatedTrainingTime),
    getModelTrainingEpochs: afterLoginFetcher(getModelTrainingEpochs),
    postModelPin: afterLoginFetcher(postModelPin),
    postModelUnpin: afterLoginFetcher(postModelUnpin),
    getModelTags: afterLoginFetcher(getModelTags),
    deleteModelTag: afterLoginFetcher(deleteModelTag),
    editTagToModel: afterLoginFetcher(editTagToModel),
    editModelMemo: afterLoginFetcher(editModelMemo),
    createModelTag: afterLoginFetcher(createModelTag),
    getModelFilterChoices: afterLoginFetcher(getModelFilterChoices),
    getOverview: afterLoginFetcher(getOverview),
    getEndpointFilterChoices: afterLoginFetcher(getEndpointFilterChoices),
    getBaselineModelSamples: afterLoginFetcher(getBaselineModelSamples),
    getBaselineModelSamplesDetail: afterLoginFetcher(getBaselineModelSamplesDetail),
    postRecognitionEndpointInferenceImage: afterLoginFetcher(postRecognitionEndpointInferenceImage),
    postGenerationEndpointInferenceImage: afterLoginFetcher(postGenerationEndpointInferenceImage),
  };
}
