import React, { useState } from 'react';

import { StateGetterSetter } from '../../../../contexts/types';

type ContextProps = StateGetterSetter<['selectedAssetIndex', 'setSelectedAssetIndex'], number>;

export const DataDetailNavigationContext = React.createContext({} as ContextProps);

export const DataDetailNavigationProvider: React.FC = ({ children }) => {
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  return (
    <DataDetailNavigationContext.Provider
      value={{
        selectedAssetIndex,
        setSelectedAssetIndex,
      }}
    >
      {children}
    </DataDetailNavigationContext.Provider>
  );
};
