import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';
import { Box, Icon, Typography } from '@superb-ai/norwegian-forest';

import { MemberData } from '../../../../types/memberTypes';
import MemberSettingMenu from '../../../elements/manageMembers/MemberSettingMenu';

type AccountMemberMenuProps = {
  member: MemberData;
  isUserNotActive: boolean;
  isDeletable: boolean;
  onClickDelete: (member: MemberData) => void;
  onClickProjectAccess?: (member: MemberData) => void;
};

const AccountMemberMenu: React.FC<AccountMemberMenuProps> = ({
  member,
  onClickDelete,
  isUserNotActive,
  isDeletable,
  onClickProjectAccess,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickManageProjectAccess = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (!onClickProjectAccess) return;
    onClickProjectAccess(member);
  };

  return (
    <MemberSettingMenu
      isUserNotActive={isUserNotActive}
      isDeletable={isDeletable}
      member={member}
      onClickDelete={onClickDelete}
      target="Team"
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    >
      {member.tenantRole === 'Collaborator' && (
        <MenuItem onClick={handleClickManageProjectAccess}>
          <Box mr={0.5} display="flex" alignItems="center">
            <Icon name="userSetting" />
          </Box>
          <Typography variant="body5">{t('users.button.manageProjectAccess')}</Typography>
        </MenuItem>
      )}
    </MemberSettingMenu>
  );
};

export default AccountMemberMenu;
