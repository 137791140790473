import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Box, Button, Chip, Icon, Modal, Tooltip, Typography } from '@superb-ai/norwegian-forest';
import { LinkTypography } from '@superb-ai/ui';
import cn from 'classnames';
import { uniq, without } from 'lodash';
import Link from 'next/link';

import { SampleProjects } from '../../../consts/SampleProjects';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useLabelCommandContext } from '../../../contexts/LabelCommandContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AnalyticsTracker from '../../../analyticsTracker';
import { useMetering } from '../../../queries/useMeteringQuery';
import AssetsService from '../../../services/AssetsService';
import ProjectService from '../../../services/ProjectService';
import { SampleProjectData } from '../../../types/projectTypes';

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid #D4D4D4',
    borderRadius: '2px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FBFBFB',
    },
    '&.selected': {
      border: '1px solid #ff625a',
      backgroundColor: '#FFF6F6',
    },
  },
  img: {
    width: '100%' /* width of container */,
    height: '106px' /* height of container */,
    objectFit: 'cover',
  },
  chip: {
    margin: '0 auto',
  },
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SampleProjectModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commandContext = useLabelCommandContext();
  const { accountName } = useParams<{ accountName: string }>();
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { leftQuantity } = useMetering('label:data-volume');

  const [selectedProject, setSelectedProject] = useState<SampleProjectData>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filteredSampleProjects, setFilteredSampleProjects] = useState(SampleProjects);
  const [existingDatasetProjectMap, setExistingDatasetProjectMap] = useState<Record<string, any>>(
    {},
  );

  const { isOpen, onClose } = props;

  const categories = uniq(SampleProjects.flatMap(project => project.tags));

  useEffect(() => {
    (async () => {
      const existingDatasetProjectMap = await SampleProjects.reduce(async (promise, project) => {
        const result: Record<string, any> = await promise.then();
        result[project.machineName] = { dataset: false, project: false };

        const assetsResults = await AssetsService.getAssets({
          params: { groupIn: [project.machineName] },
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });

        result[project.machineName].dataset = assetsResults.count > 0;

        try {
          await ProjectService.getProjectByName({
            projectName: project.displayName,
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          });
          result[project.machineName].project = true;
        } catch {}

        return Promise.resolve(result);
      }, Promise.resolve({}));

      setExistingDatasetProjectMap(existingDatasetProjectMap);
    })();
  }, []);

  const handleClose = () => {
    setSelectedProject(undefined);
    setIsRequesting(false);
    setIsConfirmationOpen(false);
    setSelectedCategories([]);
    setFilteredSampleProjects(SampleProjects);
    onClose();
  };

  const handleClickCategoryToggle = (target: string) => () => {
    const indexOfCurrentSelectedCategories = selectedCategories.indexOf(target);
    const nextSelectedCategories =
      indexOfCurrentSelectedCategories >= 0
        ? without(selectedCategories, target)
        : selectedCategories.concat([target]);

    setSelectedCategories(nextSelectedCategories);
    setFilteredSampleProjects(
      SampleProjects.filter(project =>
        nextSelectedCategories.every(category => project.tags.includes(category)),
      ),
    );
    setSelectedProject(undefined);
  };

  const handleClickProject = (project: SampleProjectData) => () => {
    setSelectedProject(project);
  };

  const handleClickCreate = async () => {
    if (!selectedProject) return;
    try {
      setIsConfirmationOpen(false);
      setIsRequesting(true);
      const response = await ProjectService.createSampleProject({
        params: {
          newProjectName: selectedProject.displayName,
          datasetSource: 'spbai-s3',
          datasetId: selectedProject.machineName,
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      await commandContext.registerCommand(response.id);
      AnalyticsTracker.sampleProjectRequested({
        datasetId: selectedProject?.machineName || '',
        referrer: 'modal',
        accountId: accountName,
      });
    } finally {
      setIsRequesting(false);
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Typography variant="headline4" themedColor="primary">
            {t('sampleProject.chooseSampleProject')}
          </Typography>
        }
        mainButton={{
          text: 'Create',
          onClick: () => {
            setIsConfirmationOpen(true);
          },
          disabled: !selectedProject,
          isLoading: isRequesting,
        }}
        subButton={{
          text: 'Cancel',
          onClick: handleClose,
        }}
        confirmation={{
          content: (
            <Typography variant="body3">
              {t('projects.table.projectName')}: <strong>{selectedProject?.displayName}</strong>{' '}
              <br />
              {t('labels.table.dataset')}: <strong>{selectedProject?.machineName}</strong> <br />
              <br />
              {t('sampleProject.messages.canTakeUpTo10Minutes')}{' '}
            </Typography>
          ),
          open: isConfirmationOpen,
          cancelButtonText: t('button.cancel'),
          onCancel: () => setIsConfirmationOpen(false),
          confirmButtonText: t('sampleProject.button.create'),
          onConfirm: handleClickCreate,
        }}
      >
        <Box bb data-intercom-target="Choose Sample Project">
          <Box mx={4} mt={3} width={760}>
            <Typography variant="headline6">{t('sampleProject.projectType')}</Typography>
            <Box mt={1} display="flex" gap={8}>
              {categories.map(category => (
                <Chip
                  key={category}
                  onClick={handleClickCategoryToggle(category)}
                  size="m"
                  color={selectedCategories.indexOf(category) >= 0 ? 'lightgrey' : 'cloud'}
                >
                  {category}
                </Chip>
              ))}
            </Box>
            <Box mt={2} height={440} overflow="auto" pb={3}>
              {filteredSampleProjects.length > 0 ? (
                <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="16px">
                  {filteredSampleProjects.map(sample => {
                    const alreadyExists =
                      existingDatasetProjectMap[sample.machineName]?.project ||
                      existingDatasetProjectMap[sample.machineName]?.dataset;
                    const hasSufficientDataVolume = leftQuantity >= sample.itemsCount;

                    return (
                      <Box key={sample.machineName} position="relative">
                        <Box
                          className={cn(classes.card, {
                            selected: selectedProject?.machineName === sample.machineName,
                          })}
                          onClick={!alreadyExists && handleClickProject(sample)}
                        >
                          <img
                            src={`/static/image/sample-project/${sample.machineName}.jpg`}
                            className={classes.img}
                            alt={sample.machineName}
                          />
                          <Box p={1.5} pt={0.5}>
                            <Typography variant="headline6">{sample.displayName}</Typography>
                            <Typography variant="body5" themedColor={['grey', 500]}>
                              {sample.description}
                            </Typography>
                            <Box mt={0.5}>
                              <Typography variant="body5" themedColor={['grey', 500]}>
                                {t('charts.itemCount', { count: sample.itemsCount })}
                              </Typography>
                            </Box>
                            <Box mt={1} display="flex" gap="4px" flexWrap="wrap">
                              {sample.tags.map(tag => (
                                <Chip key={tag} color="cloud">
                                  {tag}
                                </Chip>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                        {(alreadyExists || !hasSufficientDataVolume) && (
                          <Box
                            position="absolute"
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            borderRadius="1px"
                            backgroundColor="#F3F3F3D9"
                            pt={5.5}
                            style={{ textAlign: 'center' }}
                          >
                            <Tooltip
                              placement="bottom"
                              anchorEl={
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  <Chip className={classes.chip} color="cloud">
                                    {!hasSufficientDataVolume ? (
                                      '⚡️ ' + t('sampleProject.messages.upgradePlan')
                                    ) : (
                                      <>
                                        {existingDatasetProjectMap[sample.machineName]?.project &&
                                          existingDatasetProjectMap[sample.machineName]?.dataset &&
                                          t(
                                            'sampleProject.messages.datasetAndProjectAlreadyExists',
                                          )}
                                        {existingDatasetProjectMap[sample.machineName]?.project &&
                                          !existingDatasetProjectMap[sample.machineName]?.dataset &&
                                          t('sampleProject.messages.projectAlreadyExists')}
                                        {!existingDatasetProjectMap[sample.machineName]?.project &&
                                          existingDatasetProjectMap[sample.machineName]?.dataset &&
                                          t('sampleProject.messages.datasetAlreadyExists')}{' '}
                                      </>
                                    )}
                                  </Chip>
                                </Box>
                              }
                            >
                              {!hasSufficientDataVolume ? (
                                <Trans t={t} i18nKey={'sampleProject.messages.pricingPlan'}>
                                  Learn about our pricing plans{' '}
                                  <LinkTypography
                                    as={Link}
                                    underline
                                    color="primary"
                                    href={`https://${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    here
                                  </LinkTypography>
                                </Trans>
                              ) : (
                                <>
                                  {existingDatasetProjectMap[sample.machineName]?.project &&
                                    `${t('sampleProject.project')}: ${sample.displayName}`}
                                  {existingDatasetProjectMap[sample.machineName]?.project &&
                                    existingDatasetProjectMap[sample.machineName]?.dataset && (
                                      <br />
                                    )}
                                  {existingDatasetProjectMap[sample.machineName]?.dataset &&
                                    `${t('labels.table.dataset')}: ${sample.machineName}`}
                                </>
                              )}
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                >
                  <Icon name="folderOpen" size="56px" color={['grey', 300]} />
                  <Box mt={1} />
                  <Typography variant="headline6" themedColor={['grey', 300]}>
                    {t('sampleProject.messages.noMatchingProjects')}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      color="grey"
                      IconAdornment="refresh"
                      onClick={() => {
                        setFilteredSampleProjects(SampleProjects);
                        setSelectedCategories([]);
                      }}
                    >
                      {t('sampleProject.button.resetFilters')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SampleProjectModal;
