import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { concat, filter } from 'lodash';
import { useSnackbar } from 'notistack';

import { TOAST_TASK_NOT_YET_COMPLETE } from '../consts/SnackbarMessage';
import { toastEventManager } from '../utils/ToastUtils';

interface QueueItem {
  id: string;
  type: string;
  action: string;
  options: any;
}

type ContextProps = {
  renderQueue: QueueItem[];
};

const Context = React.createContext({} as ContextProps);

let key = 1;
let toastQueue: Record<string, any> = {};
let toastIds: string[] = [];

const ToastProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [renderQueue, setRenderQueue] = useState<QueueItem[]>([]);

  const getIsAlreadyEnqueued = (type: string, action: string) => {
    for (let i = 0; i < toastIds.length; i++) {
      const targetToast = toastQueue[toastIds[i]];
      if (type === targetToast.type) {
        switch (targetToast.options.state) {
          case 'endProgress':
          case 'cancel':
          case 'close':
            continue;
          default:
            break;
        }

        if (type === 'label') {
          if (action === 'upload') {
            enqueueSnackbar(TOAST_TASK_NOT_YET_COMPLETE({ t, action: 'Upload' }), {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(TOAST_TASK_NOT_YET_COMPLETE({ t, action: 'Delete Labels' }), {
              variant: 'warning',
            });
          }
        } else if (type === 'data') {
          if (action === 'delete') {
            enqueueSnackbar(TOAST_TASK_NOT_YET_COMPLETE({ t, action: 'Delete Data' }), {
              variant: 'warning',
            });
          } else if (action === 'assign') {
            enqueueSnackbar(TOAST_TASK_NOT_YET_COMPLETE({ t, action: 'Assign Data to Project' }), {
              variant: 'warning',
            });
          } else if (action === 'upload') {
            enqueueSnackbar(TOAST_TASK_NOT_YET_COMPLETE({ t, action: 'Upload' }), {
              variant: 'warning',
            });
          }
        }

        return true;
      }
    }
    return false;
  };

  const deleteToast = (id: string) => {
    toastIds = filter(toastIds, toast => toast !== id);
    toastEventManager.emit('CHANGE');
  };

  const setState = (id: string, state: string) => {
    toastQueue[id].options.state = state;
    toastEventManager.emit('CHANGE');
    toastEventManager.emit('CHANGE_STATE', toastQueue[id]);
  };

  // need to test
  const minimizeToast = (id: string) => {
    toastQueue[id].options.isMinimize = true;
    toastEventManager.emit('CHANGE');
  };

  // need to test
  const maximizeToast = (id: string) => {
    toastQueue[id].options.isMinimize = false;
    toastEventManager.emit('CHANGE');
  };

  const change = () => {
    const nextRenderQueue: QueueItem[] = [];
    let index = 0;
    let minIndex = 0;
    let maxIndex = 0;

    Object.keys(toastQueue).forEach(id => {
      const { type, action, options } = toastQueue[id];
      const { isMinimize } = options;

      if (toastIds.indexOf(id) !== -1) {
        nextRenderQueue.push({
          id,
          type,
          action,
          options: {
            ...options,
            index: index++,
            order: isMinimize ? minIndex++ : maxIndex++,
          },
        });
      } else {
        delete toastQueue[id];
      }
    });

    setRenderQueue(nextRenderQueue);
  };

  const push = (type: string, action: string, options: any) => {
    const isAlreadyEnqueued = getIsAlreadyEnqueued(type, action);
    if (isAlreadyEnqueued) {
      return;
    }

    const id = (key++).toString();
    const nextOptions = {
      isMinimize: false,
      deleteToast: () => deleteToast(id),
      setState: (state: string) => setState(id, state),
      minimizeToast: () => minimizeToast(id),
      maximizeToast: () => maximizeToast(id),

      ...options,
    };

    toastQueue[id] = {
      id,
      type,
      action,
      options: nextOptions,
    };

    toastIds = concat(toastIds, id);

    toastEventManager.emit('CHANGE');
  };

  useEffect(() => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      const keys = Object.keys(toastQueue);

      if (keys.length) {
        for (let i = 0; i < keys.length; i++) {
          switch (toastQueue[keys[i]].options.state) {
            case 'endProgress':
            case 'cancel':
            case 'close':
              continue;
            default:
              e.returnValue = 'text';
              return 'test';
          }
        }
      }

      return null;
    };

    // FIXME: 그냥 on('PUSH', push) 하면 안돼?
    toastEventManager
      .on('PUSH', (type: string, action: string, options: any) => push(type, action, options))
      .on('CHANGE', change);

    return () => {
      toastEventManager.off('PUSH').off('CHANGE');
      key = 1;
      toastQueue = {};
      toastIds = [];
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider
      value={{
        renderQueue,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default {
  Context,
  Provider: ToastProvider,
};
