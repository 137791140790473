import React, {
  ChangeEvent,
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/ui';
import debounce from 'debounce-promise';
import { capitalize } from 'lodash';

import { getNamingRuleErrorMessage } from '../../../../configs/NamingRulesConfig';

export default function NameInput({
  target,
  name,
  setName,
  setIsValid,
  onSearchName,
  boxProps,
  ...inputProps
}: {
  target: 'slice' | 'dataset' | 'project';
  name: string;
  setName: (name: string) => void;
  setIsValid: (isValid: boolean) => void;
  onSearchName: (name: string) => Promise<{ count: number; results: any } | void>;
  boxProps?: ComponentProps<typeof Box>;
} & ComponentProps<typeof Input>) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [nameInvalidReasons, setNameInvalidReasons] = useState<string[]>([]);

  useEffect(() => {
    if (nameInvalidReasons.length === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [nameInvalidReasons]);

  async function validateName(value: string) {
    const errorMessages = getNamingRuleErrorMessage({ str: value, target });
    let nameInvalidReasons = errorMessages;
    if (errorMessages.length === 0) {
      try {
        await onSearchName(value);
        nameInvalidReasons = [
          ...nameInvalidReasons,
          t('naming.duplicated', { namingTarget: capitalize(target), value }),
        ];
      } catch (e) {}
    }
    setNameInvalidReasons(nameInvalidReasons);
  }

  const debouncedValidateName = useCallback(
    debounce(async (value: string) => {
      await validateName(value);
    }, 500),
    [],
  );

  async function onChangeSearchInput(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setName(value);
    setIsValid(false);
    await debouncedValidateName(value);
  }

  return (
    <Box position="relative" {...boxProps}>
      <Input
        ref={inputRef}
        placeholder={t('curate.dialogs.placeholder.name')}
        variant="stroke"
        value={name}
        onChange={onChangeSearchInput}
        {...inputProps}
      />
      {nameInvalidReasons.length > 0 && (
        <Box
          mt={0.25}
          position="absolute"
          width="100%"
          p={1}
          border="1px solid"
          borderColor="primary-400"
          backgroundColor="primary-100"
          borderRadius="2px"
          display="flex"
          flexDirection="column"
          gap={0.25}
        >
          {nameInvalidReasons.map(reason => (
            <Typography key={reason} variant="m-regular" color="primary">
              {reason}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
}
