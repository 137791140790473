import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { ensureDelay } from 'timed-async';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import TotpService from '../../../../services/TotpService';
import { TotpAssociateSteps } from './TotpAssociateSteps';

export function TotpAssociateDialog({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose?(): void;
  onSuccess?(): void;
}) {
  const [step, setStep] = useState<0 | 1>(0);
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState<null | string>(null);
  const [isSending, setIsSending] = useState(false);
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();

  const apiParams = useMemo(
    () => ({
      t,
      isGuest: false,
      urlInfo: routeInfo.urlMatchInfo,
      origin: 'components/elements/auth/TotpAssociateDialog/TotpAssociateDialog.tsx',
    }),
    [routeInfo.urlMatchInfo, t],
  );

  useEffect(() => {
    if (!open) return;
    ensureDelay(TotpService.getSecret(apiParams)).then(({ secretCode }) => setSecret(secretCode));
  }, [apiParams, open]);

  function close() {
    setStep(0);
    setCode('');
    setIsSending(false);
    onClose?.();
  }

  const mainButton = {
    text: step === 0 ? t('button.next') : t('button.submit'),
    isLoading: isSending,
    loadingText: t('button.saving'),
    async onClick() {
      if (step === 0) setStep(1);
      else {
        setIsSending(true);
        try {
          await ensureDelay(
            TotpService.verify({
              ...apiParams,
              code,
            }),
          );
          onSuccess?.();
          close();
        } finally {
          setIsSending(false);
        }
      }
    },
  };

  const subButton = {
    text: step === 0 ? t('button.cancel') : t('button.back'),
    onClick() {
      if (step === 1) setStep(0);
      else close();
    },
  };

  const title = t('settings.security.mfaSetupDialog.title');

  return !open ? null : (
    <Modal open={open} title={title} mainButton={mainButton} subButton={subButton}>
      <Box mx={4} mt={1} maxWidth={515}>
        <TotpAssociateSteps
          code={code}
          onCodeChange={setCode}
          secret={secret}
          step={step}
          layout="dialog"
        />
      </Box>
    </Modal>
  );
}
