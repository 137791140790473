import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Box } from '@superb-ai/ui';

import PageHeader from '../components/layouts/Header';

interface Props {
  extraHeader?: JSX.Element;
  boxProps?: React.ComponentProps<typeof Box>;
}

export default function Page({
  extraHeader,
  children,
  boxProps,
}: React.PropsWithChildren<Props>): React.ReactElement {
  // Scroll to top on route change
  const { pathname } = useLocation();
  const history = useHistory();
  const [stateDateData, setStateDateData] = useState(history.location.state);
  const rendererKey = stateDateData ? (stateDateData as Date | string).toString() : 'default';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // Check if history state has changed
      if (location.state !== stateDateData) {
        setStateDateData(location.state);
      }
    });

    return () => {
      // Clean up the listener when the component unmounts
      unlisten();
    };
  }, [history, stateDateData]);

  return (
    <Box display="flex" flexDirection="column" className="page" style={{ minWidth: 0, flex: 1 }}>
      <PageHeader>{extraHeader}</PageHeader>
      <Box
        key={rendererKey}
        position="relative"
        p={4}
        width="100%"
        mx="auto"
        boxSizing="border-box"
        className="page-content"
        style={{ flex: 1, minWidth: 900 }}
        overflow="auto"
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  );
}
