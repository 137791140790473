import { TFunction } from 'react-i18next';

import { GetThemeColorInput } from '@superb-ai/norwegian-forest/dist/theme';
import { groupBy, mapValues } from 'lodash';

import { LabelReview } from '../../../consts/ReviewConst';
import { LabelStatus } from '../../../types/labelTypes';
import LabelUtils from '../../../utils/LabelUtils';
import ProjectUtils from '../../../utils/ProjectUtils';
import {
  FormattedLabelSummary,
  LabelStatusCount,
  LabelStatusReviewCount,
  ReviewCount,
} from './types';

const getProjectConfigDataType = (workapp: string, t: TFunction): string => {
  const type = ProjectUtils.getProjectDataType(workapp);
  switch (type) {
    case 'image':
      return t('shared.image');
    case 'video':
      return t('components.CustomShortcut.video');
    case 'pointclouds':
      return t('data.types.point_cloud_sequence.title');
    default:
      return 'Undefined';
  }
};

const formatLabelCountData = (
  data: LabelStatusReviewCount[],
  field: 'count' | 'frameCount' = 'count',
): FormattedLabelSummary => {
  // Group by status
  const byStatus = groupBy(data, 'status') as Record<LabelStatus, LabelStatusReviewCount[]>;

  // Sum by status
  const statusCounts = mapValues(byStatus, entries =>
    entries.reduce((a, d) => a + (d[field] ?? 0), 0),
  );
  const { SUBMITTED = 0, WORKING = 0, SKIPPED = 0 } = statusCounts;
  const labelStatus: LabelStatusCount[] = [
    { name: 'WORKING', value: WORKING },
    { name: 'SUBMITTED', value: SUBMITTED },
    { name: 'SKIPPED', value: SKIPPED },
  ];

  // Group by by status + review
  const byStatusReview = mapValues(byStatus, entries =>
    entries.reduce(
      (a, item) => {
        const key = LabelUtils.getKeyForReviewAction(item.lastReviewAction);
        a[key] = (a[key] ?? 0) + (item[field] ?? 0);
        return a;
      },
      { UNREVIEWED: 0, APPROVE: 0, REJECT: 0 } as Record<LabelReview, number>,
    ),
  );

  // Extract and order the meaningful review counts from byStatusReview
  function extractCounts(
    status: LabelStatus,
    keys: LabelReview[],
    displayNames: string[],
    colors: GetThemeColorInput[],
  ): ReviewCount[] {
    return keys.map((key, idx) => ({
      name: key,
      color: colors[idx],
      displayName: displayNames[idx],
      value: byStatusReview[status]?.[key] ?? 0,
      status,
    }));
  }
  const review = [
    extractCounts(
      'WORKING',
      ['REJECT', 'UNREVIEWED'],
      ['labels.status.rejected', 'labels.status.not_submitted'],
      ['strawberry', 'yellow'],
    ),
    extractCounts(
      'SUBMITTED',
      ['APPROVE', 'UNREVIEWED'],
      ['labels.status.approved', 'labels.status.pending_review'],
      ['green', ['green', 30]],
    ),
    extractCounts(
      'SKIPPED',
      ['APPROVE', 'UNREVIEWED'],
      ['labels.status.approved', 'labels.status.pending_review'],
      ['cloud', ['cloud', 30]],
    ),
  ];

  return {
    labelStatus,
    review,
  };
};

export default {
  getProjectConfigDataType,
  formatLabelCountData,
};
