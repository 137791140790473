import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';

type ButtonFeatures =
  | 'selectData'
  | 'autoCurate'
  | 'sendToLabel'
  | 'syncWithLabel'
  | 'slice'
  | 'updateScatter'
  | 'updateDiagnosis'
  | 'cloudUpload'
  | 'diagnosisSelectData'
  | 'diagnosisSlice'
  | 'generateImages'
  | 'prepareDownload';

export function SampleDatasetTooltip({ feature }: { feature: ButtonFeatures }) {
  const { t } = useTranslation();
  const { isCurateNotEnabled, hasOwnDataset } = usePublicDatasetContext();

  function getSampleDatasetTooltipI18nKey() {
    const prefix = `curate.datasets.sampleDataset.buttonTooltip.${feature}`;
    if (isCurateNotEnabled) return `${prefix}.upgradePlanTooltip`;
    if (hasOwnDataset) return `${prefix}.myDatasetTooltip`;
    return `${prefix}.createMyDatasetTooltip`;
  }

  // TODO (shko): Should add Link component to the tooltip message.
  return <Trans t={t} i18nKey={getSampleDatasetTooltipI18nKey()} components={{}} />;
}
