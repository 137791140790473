import { SelectedData } from '../../../../../contexts/ActionContext';
import { DatasetPool, PageSet } from '../../../../../types/routeTypes';

export function getPageEndpoint(
  accountName: string,
  datasetId: string,
  datasetPool: DatasetPool,
  pageSet: PageSet,
  sliceId?: string,
) {
  return pageSet === 'dataset'
    ? `/${accountName}/curate/${datasetPool}/${datasetId}/explore`
    : `/${accountName}/curate/${datasetPool}/${datasetId}/slice/${sliceId}/explore`;
}

type BaseOption = {
  id: string | undefined;
  name: string | undefined;
  group: 'Slice' | 'Dataset';
};

export type CompareOption = BaseOption & {
  [key: string]: string | number;
};

export function getCompareOption(options: CompareOption[], id: string) {
  return options.find(option => option.id === id);
}

/**
 * Get selected image count in grid view
 * option 1: selected all, deselected none : totalCount
 * option 2: selected all, deselelcted some : total count - deselected.length
 * option 3: selected some : selected.length
 */
export function getSelectedImageCount(
  selectedAll: boolean,
  selected: SelectedData[],
  deselected: SelectedData[],
  totalCount: number,
) {
  if (selectedAll) {
    return deselected.length ? totalCount - deselected.length : totalCount;
  }
  return selected.length;
}
