import { ProjectData } from '../types/projectTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

// TODO: refine API response types

const getProjects: ApiCall<
  { params: Params },
  { count: number; results: ProjectData[] }
> = async args => {
  const { params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/?${ServiceUtils.getParamString(params)}`,
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data; // { count: number; results: [{ key: 'value'}, ...] }
};

export default {
  getProjects,
};
