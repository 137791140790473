import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { clone, debounce, isArray, set, some } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  FILTER_OPTION_LIMIT,
  PLEASE_ENTER_ALL_REQUIRED_FIELDS,
} from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFilterInfo } from '../../../../contexts/FilterContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { allFilters, FilterKey, labelFilters } from '../../../../utils/filter/labelFilter';
import { FilterValue } from '../../../../utils/filter/types';
import ParamUtils from '../../../../utils/ParamUtils';
import Filter from './Filter';
import { MAX_OPTIONS_SIZE } from './types';

const FilterList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentRow, setCurrentRow] = useState(0);
  const { selectedFilters, setSelectedFilters } = useFilterInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const useConsensusLabeling = projectInfo.project.settings.allowAdvancedQa;

  const filterByRole = useMemo(() => {
    return labelFilters[authInfo.projectRole ?? authInfo.role ?? 'worker'] ?? [];
  }, [authInfo.projectRole, authInfo.role]);

  const filterByConsensus = useMemo(() => {
    if (useConsensusLabeling) {
      return filterByRole;
    }

    // Hide qa filter for consensus disabled project
    const index = filterByRole.findIndex(v => v.key === 'qa');
    if (index !== -1) {
      return [
        ...filterByRole.slice(0, index),
        ...filterByRole.slice(index + 1, filterByRole.length),
      ];
    }
    return filterByRole;
  }, [filterByRole, useConsensusLabeling]);

  const [urlSearchParams, setUrlSearchParams] = useState<[string, string][]>([]);
  const filtersByParams = useMemo(
    () => ParamUtils.getFiltersFromParams(urlSearchParams),
    [urlSearchParams],
  ) as FilterValue<FilterKey | ''>[];

  useEffect(() => {
    setUrlSearchParams(Array.from(new URLSearchParams(routeInfo.location.search)));
  }, [routeInfo.location.search]);

  useEffect(() => {
    // Hide filters not available to user
    const availableFilterKeys = filterByConsensus.map(({ key }) => key);
    const visibleFilters = filtersByParams.filter(filter =>
      availableFilterKeys.includes(filter.filterBy as (typeof availableFilterKeys)[number]),
    );

    if (visibleFilters.length) {
      setSelectedFilters(visibleFilters);
    } else {
      setSelectedFilters([{ filterBy: '', condition: '', options: [] }]);
    }
  }, [filterByConsensus, filtersByParams, setSelectedFilters]);

  const applyFilterChange = (filters: FilterValue[]) => {
    const filterParams = ParamUtils.getUrlParamsForFilters(filters);
    const otherParams = urlSearchParams.filter(([key]) =>
      ['page', 'pageSize', 'ordering'].includes(key),
    );
    // Set page filter to 1
    const pageFilter = otherParams.find(([key]) => key === 'page');
    if (pageFilter) {
      pageFilter[1] = '1';
    }
    const searchParams = new URLSearchParams([...filterParams, ...otherParams]);
    const query = searchParams.toString();
    if (routeInfo.history.location.search !== `?${query}`) {
      // Reload if any filter has changed
      routeInfo.history.push(`?${query}`);
    }
  };

  const debouncedApplyFilterChange = debounce(applyFilterChange, 400);

  const handleAddFilter = () => {
    const filters = clone(selectedFilters);

    if (some(filters, { filterBy: '' })) {
      enqueueSnackbar(PLEASE_ENTER_ALL_REQUIRED_FIELDS({ t }), {
        variant: 'warning',
      });
      return;
    }

    setSelectedFilters([...filters, { filterBy: '' as const, condition: '', options: [] }]);
    setCurrentRow(filters.length);
  };

  const handleRemoveFilter = (index: number) => {
    const filters = clone(selectedFilters);
    let newFilters;

    if (filters.length === 1) {
      newFilters = [{ filterBy: '' as const, condition: '', options: [] }];
    } else {
      newFilters = [...filters.slice(0, index), ...filters.slice(index + 1)];
    }

    setSelectedFilters(newFilters);
    setCurrentRow(newFilters.length - 1);
    debouncedApplyFilterChange(newFilters);
  };

  const handleChangeCurrentRow = (index: number) => {
    setCurrentRow(index);
  };

  const handleValueChange = (index: number, key: string, value: string) => {
    if (value === '' && index !== 0) {
      handleRemoveFilter(index);
      return;
    }

    if (isArray(value) && value.length > MAX_OPTIONS_SIZE) {
      enqueueSnackbar(FILTER_OPTION_LIMIT({ t, max: MAX_OPTIONS_SIZE }), { variant: 'info' });
      return;
    }

    const isOptionlessFilter = (filterBy: string, condition: string) => {
      // filters that should be applied even if no options were selected
      return (
        ['dateAdded', 'lastUpdated'].indexOf(filterBy) !== -1 ||
        (filterBy === 'issues' && condition.startsWith('no')) ||
        condition === 'exists' ||
        condition === 'does not exist' ||
        condition === 'is empty' ||
        condition === 'is not empty'
      );
    };

    const isRequiredOptionsSelected = (condition: string, options: any[]) =>
      condition === 'is in the range' ? options.length >= 2 : options.length > 0;

    const copiedFilters = set(clone(selectedFilters), [index, key], value);

    if (key === 'filterBy') {
      const filterBy = value as keyof typeof allFilters;
      const defaultCondition = allFilters[filterBy]?.conditions?.[0] ?? '';
      set(copiedFilters, [index, 'condition'], defaultCondition);
      set(copiedFilters, [index, 'options'], []);
    }

    setSelectedFilters(copiedFilters);

    if (
      copiedFilters.every(
        filter =>
          isOptionlessFilter(filter.filterBy, filter.condition) ||
          (filter.options && isRequiredOptionsSelected(filter.condition, filter.options)),
      )
    ) {
      debouncedApplyFilterChange(copiedFilters);
    }
  };

  return (
    <>
      {selectedFilters.map((filter, index) => (
        <Filter
          key={index}
          index={index}
          filter={filter}
          filters={filterByConsensus}
          selectedFilters={selectedFilters}
          totalFilters={selectedFilters.length}
          isCurrentRow={index === currentRow}
          onChangeCurrentRow={handleChangeCurrentRow}
          onValueChange={handleValueChange}
          onAddFilter={handleAddFilter}
          onRemoveFilter={handleRemoveFilter}
        />
      ))}
    </>
  );
};

export default FilterList;
