import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@superb-ai/ui';

import { ClassificationKeyDatum, DataScope } from '../types';
import { getChartColor } from '../utils/colorScale';
import { ClassificationTableRow } from './ClassificationTableRow';
import { StatsTableHeader } from './StatsTableHeader';
import { TotalCountBanner } from './TotalCountsBanner';
import { getClassificationHeaders } from './utils';

type ClassificationTableProps = {
  isCompareMode: boolean;
  selection: {
    data: ClassificationKeyDatum[];
    totalImages: number;
    name: string;
  };
  compared?: {
    data: ClassificationKeyDatum[];
    totalImages: number;
    name: string;
  };
  dataScope: DataScope;
};

const maxHeight = '800px';

export function ClassificationTable({
  isCompareMode,
  selection,
  compared,
  dataScope,
}: ClassificationTableProps) {
  const { t } = useTranslation();
  const headers = getClassificationHeaders(t);
  const hasCompareData = isCompareMode && compared?.data && compared.data.length > 0;
  const bannerRef = useRef<HTMLDivElement>(null);

  return (
    <Box overflow="auto" width="100%" style={{ maxHeight }}>
      <Box position="sticky" top="0" backgroundColor="white" style={{ zIndex: 1 }}>
        <Box display="flex" gap={1} ref={bannerRef}>
          <Box style={{ width: headers[0].width }} />
          <TotalCountBanner
            key="total-images-banner"
            text={t('curate.analytics.classStats.totalImages')}
            infoText={t('curate.analytics.classStats.totalImagesInfoText', {
              pageSet: dataScope,
            })}
            totalCounts={{
              selection: {
                name: selection.name,
                count: selection.totalImages,
                color: getChartColor('classification'),
              },
              ...(hasCompareData && {
                compared: {
                  name: compared.name,
                  count: compared.totalImages,
                  color: getChartColor('classificationAll'),
                },
              }),
            }}
          />
        </Box>
        <StatsTableHeader headers={headers} />
      </Box>
      <Box>
        {selection.data.map((datum, index) => (
          <ClassificationTableRow
            key={datum?.name + 'selection'}
            initialOpen={index === 0}
            datum={datum}
            totalImages={selection.totalImages}
            comparedDatum={compared?.data.find(d => d.name === datum.name)}
            totalComparedImages={compared?.totalImages}
            isCompareMode={isCompareMode}
          />
        ))}
      </Box>
    </Box>
  );
}
