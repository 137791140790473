import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';

import { SVG_PROPERTY } from '../config/svgConfig';

const useStyle = makeStyles({
  emptyPlot: {
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: '#FBFBFBD5',
    color: '#333333',
    fontSize: '17px',
    fontWeight: 500,
    width: '100%',
    height: '100%',
    zIndex: 1,
    '& > div': {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      transform: 'translateY(-50%)',
      color: '#6E6E6E',
    },
  },
});

interface Props {
  message: string; // Message to indicate why there is no plot
  chartKind?: 'short' | 'regular';
  svgHeight?: number;
  fullHeight?: string;
  svgWidth?: number;
  marginTop?: number;
  marginLeft?: number;
}

const EmptyPlot: React.FC<Props> = ({
  message,
  chartKind,
  svgHeight,
  svgWidth,
  marginTop,
  marginLeft,
  fullHeight,
}) => {
  const classes = useStyle();
  const divStyle: React.CSSProperties = {};
  if (fullHeight) divStyle.height = '100%';
  else {
    if (chartKind) {
      const height = SVG_PROPERTY(chartKind).svgHeight;
      divStyle.height = height + 4;
    }
    if (svgHeight) {
      divStyle.height = svgHeight + 4;
    }
  }
  if (svgWidth) {
    divStyle.width = svgWidth;
  }
  return (
    <MUI.Box
      className={cn(classes.emptyPlot, 'chart')}
      style={{ ...divStyle, ...(marginTop && { marginTop }), ...(marginLeft && { marginLeft }) }}
    >
      <div>{message}</div>
    </MUI.Box>
  );
};

export default EmptyPlot;
