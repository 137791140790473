import { LabelStatusReviewCount } from '../components/pages/overview/types';
import { getFeatureFlag } from '../contexts/FeatureFlagContext';
import ServiceUtils from '../utils/ServiceUtils';
import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '.';

const ProjectDataFormat = ['image', 'image sequence', 'pointclouds'] as const;

export type ProjectDataType = (typeof ProjectDataFormat)[number];

const getLabelingStatus: AfterLoginCallback<
  LabelStatusReviewCount[],
  { projectId: string; projectDataType?: ProjectDataType }
> = async params => {
  const useOverviewProxy = getFeatureFlag('overviewProxy').enabled;
  const { projectDataType } = params.data;
  const projectId = params.data.projectId;
  const url = `/projects/${projectId}/overview/?${ServiceUtils.getParamString({
    projectDataType,
  })}`;
  const res = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: true,
    version: useOverviewProxy ? 2 : undefined,
    ...params,
    data: {},
  });
  return useOverviewProxy ? res.data?.results : res.data;
};

export function useOverviewService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getLabelingStatus: afterLoginFetcher(getLabelingStatus),
  };
}
