import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Card, ToggleButton, Typography } from '@superb-ai/norwegian-forest';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';

import ProjectConst from '../../../../../consts/ProjectConst';
import { COPY_SUCCESS } from '../../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useCurrentPlan } from '../../../../../queries/useSubscriptionQuery';
import ProjectService from '../../../../../services/ProjectService';
import TierUnion from '../../../../../union/TierUnion';
import FileUtils from '../../../../../utils/FileUtils';
import CircularProgressDialog from '../../../../elements/CircularProgressDialog';
import UpgradePlanTooltip from '../../../../elements/UpgradePlanTooltip';

const Layout: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const projectInfo = useProjectInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const currentPlan = useCurrentPlan();
  const isFree = TierUnion.isFree(currentPlan);

  const [isRequesting, setIsRequesting] = useState(false);
  const isPublicProject = projectInfo?.project?.isPublic;
  const isQaProject = projectInfo?.project?.settings.allowAdvancedQa && !isPublicProject;
  const isDataTypeSupported = ['image', 'image beta'].includes(
    projectInfo?.project?.labelInterface?.dataType,
  );
  const type = startCase(
    ProjectConst.dataTypes(true)[projectInfo?.project?.labelInterface?.dataType]?.label ??
      projectInfo?.project?.labelInterface?.dataType,
  );

  const handleChangeSwitch = async () => {
    setIsRequesting(true);
    const res = await ProjectService.updateProject({
      projectId: projectInfo.project.id,
      newInfo: { isPublic: !isPublicProject },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    projectInfo.setProject(res);
    setIsRequesting(false);
  };

  const handleClickCopy = async () => {
    const projectUrl = `${window.location.origin}/${routeInfo.urlMatchInfo.accountName}/label/project/${routeInfo.urlMatchInfo.projectId}`;
    FileUtils.copyToClipboard({ value: projectUrl });
    enqueueSnackbar(COPY_SUCCESS({ t, label: 'project url' }), { variant: 'success' });
  };

  return (
    <Card p={3} mb={3}>
      <Typography variant="headline5">{t('projectSettings.publicProject.title')}</Typography>
      <Box mt={3} display="flex" alignItems="center">
        {isFree ? (
          <UpgradePlanTooltip
            variant="public"
            anchorEl={
              <span>
                <ToggleButton disabled value={isPublicProject} />
              </span>
            }
          />
        ) : (
          <ToggleButton
            value={isPublicProject}
            onClick={handleChangeSwitch}
            disabled={isQaProject || !isDataTypeSupported}
          />
        )}
        {isQaProject && (
          <Box ml={2}>
            <Typography variant="body3" themedColor={['grey', 300]}>
              {t('projectSettings.publicProject.cannotApplyToQaProject')}
            </Typography>
          </Box>
        )}
        {!isDataTypeSupported && (
          <Box ml={2}>
            <Typography variant="body3" themedColor={['grey', 300]}>
              {t('projectSettings.publicProject.projectTypeNotSupported', { type })}
            </Typography>
          </Box>
        )}
        {isPublicProject ? (
          <Box width="150px" display="flex" ml={3}>
            <Button variant="stroke" color="textDefault" onClick={handleClickCopy}>
              {t('projectSettings.publicProject.copyProjectUrl')}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box mb={4} />
      <Typography variant="body4" themedColor={['grey', 600]}>
        <Trans t={t} i18nKey="projectSettings.publicProject.explanation" />
      </Typography>
      <CircularProgressDialog isLoading={isRequesting} />
    </Card>
  );
};

export default Layout;
