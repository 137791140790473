import React, { CSSProperties } from 'react';

import { ArrowLongDown, ArrowLongUp } from '@superb-ai/icons';
import { Box, Icon } from '@superb-ai/ui';

export default function SortAscDescButton({
  sortOrder,
  onSort,
  style,
}: {
  sortOrder: 'desc' | 'asc';
  onSort: (sortOrder: 'desc' | 'asc') => void;
  style?: CSSProperties;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="2px"
      backgroundColor={{ hover: 'gray-150' }}
      cursor="pointer"
      style={{ ...style, width: '24px', height: '24px' }}
      onClick={() => onSort(sortOrder === 'asc' ? 'desc' : 'asc')}
    >
      <Icon size="12px" icon={ArrowLongUp} color={sortOrder === 'desc' ? 'gray' : 'primary'} />
      <Icon
        size="12px"
        icon={ArrowLongDown}
        color={sortOrder === 'asc' ? 'gray' : 'primary'}
        style={{ marginLeft: '-6px' }}
      />
    </Box>
  );
}
