import { useTranslation } from 'react-i18next';

import { CheckFilled } from '@superb-ai/icons';
import { IconButton } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import { History } from 'history';
import { SnackbarKey, SnackbarMessage } from 'notistack';

export function CreateSliceSnackbarContent(
  message: SnackbarMessage,
  closeSnackbar: { (key?: SnackbarKey | undefined): void; (arg0: any): void },
  key: SnackbarKey,
  history: string[] | History<unknown>,
  accountName: string,
  datasetId: string,
  res: {
    createdAt: Date;
    createdBy: string;
    datasetId: string;
    description: string;
    id: string;
    name: string;
    updatedAt: Date;
    updatedBy: string;
  },
) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '420px',
        height: '101px',
        background: '#1f1f1f',
        borderLeft: '4px solid  #82db24',
        padding: '20px',
      }}
    >
      <Box display="flex" width="100%" justifyContent="space-between">
        <Box display="flex">
          <Icon icon={CheckFilled} color="green-400" size={24} />
          <Box display="flex" ml={1} pt={0.5}>
            <Typography style={{ color: 'white', fontWeight: '600', fontSize: '12px' }}>
              {message}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{ maxHeight: '24px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            icon="clear"
            color="backgroundColor"
            style={{ padding: '0 8px' }}
            onClick={() => closeSnackbar(key)}
          />
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            size="m"
            style={{ color: 'white' }}
            variant="text"
            onClick={() => {
              history.push(`/${accountName}/curate/dataset/${datasetId}/slice/${res.id}`);
              closeSnackbar(key);
            }}
          >
            {t('button.view')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
