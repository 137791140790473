import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Box, LoadingIndicator } from '@superb-ai/ui';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useGetCustomAutoLabelsQuery } from '../../../../../queries/customAutoLabel';
import { Engine, fetchEngineInfos } from '../settings/helper';
import CustomAutoLabelItem from './CustomAutoLabelItem';
import Tutorial from './Tutorial';

type Props = {
  searchValue: string;
  appliedCount: Record<string, { classes?: number; properties?: number }>;
  hasExports: boolean;
};
const CustomAutoLabelList: React.FC<Props> = ({ searchValue, appliedCount, hasExports }) => {
  const { t } = useTranslation();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const projectInfo = useProjectInfo();
  const labelInterface = projectInfo.project.labelInterface;

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } = useGetCustomAutoLabelsQuery();

  const [enginesMap, setEnginesMap] = useState<Map<string, Engine>>(new Map());

  useEffect(() => {
    fetchEngineInfos({ t, labelInterface, authInfo, routeInfo }).then(setEnginesMap);
  }, [labelInterface, authInfo, routeInfo, t]);

  React.useEffect(
    function updatePagesWithProgress() {
      const interval = setInterval(() => {
        if (!data) return;
        const pagesWithInProgress = data.pages.flatMap((page, index) =>
          page.results.some(customAutoLabel =>
            ['IN_QUEUE', 'PROCESSING', 'WAITING'].includes(customAutoLabel?.state),
          )
            ? index
            : [],
        );
        if (pagesWithInProgress.length) {
          refetch({ refetchPage: (_, i) => pagesWithInProgress.includes(i) });
        }
      }, 15000);
      return () => {
        clearInterval(interval);
      };
    },
    [data, refetch],
  );

  if (!isLoading && !data?.pages?.[0]?.count) {
    return <Tutorial hasExports={hasExports} />;
  }

  const searchFilter = ({ name }: { name: string }) =>
    name.toLowerCase().includes(searchValue.toLowerCase());

  function refetchPage(index: number) {
    refetch({ refetchPage: (_, i) => index === i });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Box>
      <InfiniteScroll
        pageStart={1}
        hasMore={hasNextPage}
        loadMore={() => fetchNextPage()}
        initialLoad={false}
        loader={<LoadingIndicator />}
      >
        {data?.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.results.filter(searchFilter).map(customAutoLabel => (
              <CustomAutoLabelItem
                key={customAutoLabel.id}
                customAutoLabel={customAutoLabel}
                appliedCount={appliedCount[customAutoLabel.id]}
                enginesMap={enginesMap}
                onItemChanged={() => refetchPage(index)}
              />
            ))}
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default CustomAutoLabelList;
