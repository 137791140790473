import { EnumAsUnion } from '../../../../apps/Curate/types/typeUtils';
import { GroupByField } from './fields';

export enum ChartTypeEnum {
  BAR = 'bar',
  DONUT = 'donut',
  TREEMAP = 'treemap',
}
export type ChartType = 'bar' | 'donut' | 'treemap' | 'histogram';

/** API request params */
export enum DataEnum {
  labelCount = 'labelCount',
  categoryCount = 'categoryCount',
  objectCount = 'objectCount',
  objectAnnotationCount = 'objectAnnotationCount',
  // categoryCounts = 'categoryCounts', // TODO: fast-follow
}
export type DataKey = keyof typeof DataEnum;
export type DataSpec = EnumAsUnion<typeof DataEnum>;

/** API response */
export type BucketResult = {
  key: string;
  count: number;
};

export type ChartQueryResponse = TonyChartResponse | undefined;
export type TonyChartResponse = {
  totalLabelCount: number;
  totalAnnotationCount?: number;
  data: BucketResult[];
};

/** Dashboard APIs */
export type FilterObject = Record<string, string | string[]>;
export type TonyCustomParams = {
  projectId: string;
  data?: DataSpec;
  groupBy?: GroupByField;
  apiParams?: Record<string, any>;
};
export type ChartStorage = {
  chartId?: string;
  chartType: ChartType;
  name: string;
  data: DataSpec;
  groupBy: GroupByField;
  filters?: FilterObject;
  totalLabelCount?: number;
  totalAnnotationCount?: number;
  // createdAt: Date;
  // createdBy?: string;
  // updatedAt: Date | string;
  // updatedBy?: string;
};
export interface DashboardStorage {
  id: string;
  name: string;
  description: string;
  charts: Required<ChartStorage>[];
  createdAt: Date | string;
  createdBy: string;
  updatedAt: Date | string;
  updatedBy: string;
  setting: DashboardSetting;
}

/** Dashboard display */
export type ViewType = 'full' | 'half';
export type DashboardSetting = {
  viewType: ViewType;
};
