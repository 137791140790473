import { PAGE_TRACKING_ID } from '../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../menu';
import Layout from './Layout';

export const ProjectOverviewMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectOverview,
  path: 'overview',
  title: ({ t }) => t('overview.title'),
  component: Layout,
  icon: 'windowCheck',
});
