import React, { useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';

// prettier-ignore
type ContextProps =
  StateGetterSetter<['createSliceModalIsOpen', 'setCreateSliceModalIsOpen'], boolean> &
  StateGetterSetter<['sendToLabelIsOpen', 'setSendToLabelIsOpen'], boolean> &
  StateGetterSetter<['addSliceModalIsOpen', 'setAddSliceModalIsOpen'], boolean> &
  StateGetterSetter<['sliceSettingsIsOpen', 'setSliceSettingsIsOpen'], boolean>&
  StateGetterSetter<['datasetSettingsIsOpen', 'setDatasetSettingsIsOpen'], boolean> &
  StateGetterSetter<['queryModalIsOpen', 'setQueryModalIsOpen'], boolean> &
  StateGetterSetter<['deleteModalIsOpen', 'setDeleteModalIsOpen'], boolean> &
  StateGetterSetter<['curationModalIsOpen', 'setCurationModalIsOpen'], boolean> &
  StateGetterSetter<['openDetailImageIndex', 'setOpenDetailImageIndex'], number | undefined>;

const Context = React.createContext({} as ContextProps);

const useProvider = () => {
  const [createSliceModalIsOpen, setCreateSliceModalIsOpen] = useState<boolean>(false);
  const [sendToLabelIsOpen, setSendToLabelIsOpen] = useState<boolean>(false);
  const [addSliceModalIsOpen, setAddSliceModalIsOpen] = useState<boolean>(false);
  const [sliceSettingsIsOpen, setSliceSettingsIsOpen] = useState<boolean>(false);
  const [datasetSettingsIsOpen, setDatasetSettingsIsOpen] = useState<boolean>(false);
  const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [curationModalIsOpen, setCurationModalIsOpen] = useState<boolean>(false);
  const [openDetailImageIndex, setOpenDetailImageIndex] = useState<number>();

  return {
    createSliceModalIsOpen,
    setCreateSliceModalIsOpen,
    sendToLabelIsOpen,
    setSendToLabelIsOpen,
    addSliceModalIsOpen,
    setAddSliceModalIsOpen,
    sliceSettingsIsOpen,
    setSliceSettingsIsOpen,
    datasetSettingsIsOpen,
    setDatasetSettingsIsOpen,
    queryModalIsOpen,
    setQueryModalIsOpen,
    deleteModalIsOpen,
    setDeleteModalIsOpen,
    curationModalIsOpen,
    setCurationModalIsOpen,
    openDetailImageIndex,
    setOpenDetailImageIndex,
  };
};

export const useDatasetModalContext = (): ContextProps => {
  return React.useContext(Context);
};

export const DatasetModalProvider: React.FC = ({ children }) => {
  const datasetInfo = useProvider();
  return <Context.Provider value={datasetInfo}>{children}</Context.Provider>;
};
