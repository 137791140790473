const chipColorByRole = {
  owner: { bgColor: '#ff625a' },
  admin: { bgColor: '#5A7BFF' },
  labeler: { bgColor: '#D4D4D4' },
  manager: { bgColor: '#333333' },
  reviewer: { bgColor: '#00CECE' }, // TODO: update color once reviewer role is released
};

const labelerDefaultConfig = {
  sortBy: 'labelCounts', // column
  roles: ['owner', 'admin', 'manager', 'labeler', 'reviewer'],
  tab: 'labelingResult',
};

function labelerRoleOptions(t: any) {
  return [
    { label: t('projectMembers.role.owner'), value: 'owner' },
    { label: t('projectMembers.role.admin'), value: 'admin' },
    { label: t('projectMembers.role.manager'), value: 'manager' },
    { label: t('projectMembers.role.labeler'), value: 'labeler' },
    { label: t('projectMembers.role.reviewer'), value: 'reviewer' },
  ];
}

function reviewerRoleOptions(t: any) {
  return [
    { label: t('projectMembers.role.owner'), value: 'owner' },
    { label: t('projectMembers.role.admin'), value: 'admin' },
    { label: t('projectMembers.role.manager'), value: 'manager' },
    { label: t('projectMembers.role.reviewer'), value: 'reviewer' },
  ];
}

const reviewerDefaultConfig = {
  sortBy: 'labelCounts', // column
  roles: ['owner', 'admin', 'manager', 'reviewer'],
  tab: 'tasks',
};

const rowsPerPageOptions = (numRows: number): number[] => {
  return numRows < 100 ? [10, 25, 100] : [10, 25, 100, numRows];
};
export {
  chipColorByRole,
  labelerDefaultConfig,
  labelerRoleOptions,
  reviewerDefaultConfig,
  reviewerRoleOptions,
  rowsPerPageOptions,
};
