import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { InfoCircle } from '@superb-ai/icons';
import { Modal, VerticalStepper } from '@superb-ai/norwegian-forest';
import { Box, Icon, Typography } from '@superb-ai/ui';

import AnalyticsTracker from '../../../../../../../analyticsTracker';
import { retrieveSliceIdsFromParams } from '../../../../../../../analyticsTracker/curateUtils';
import { combineFiltersForTracking } from '../../../../../../../analyticsTracker/utils';
import {
  CURATE_WHAT_TO_LABEL,
  CurationType,
  EmbeddingType,
  FIND_EDGE_CASES,
  FIND_MISLABELS,
  IMAGE,
  OBJECT,
  SPLIT_TRAIN_VAL,
} from '../../../../../../../consts/AutoCurateConst';
import { formatCount } from '../../../../../../../utils/numberFormat';
import { useActionContext } from '../../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import { useDatasetDataCountQuery } from '../../../../../queries/dataQueries';
import { useCurateCommandsService } from '../../../../../services/CommandsService';
import { useCurateDatasetService } from '../../../../../services/DatasetService';
import { getView } from '../../../../../utils/routeUtils';
import EmbeddingTypeSteps from './steps/EmbeddingTypeSteps';
import NumberOfImageSteps from './steps/NumberOfImageSteps';
import SamplingMethodSteps from './steps/SamplingMethodSteps';
import SliceNameSteps from './steps/SliceNameSteps';

const containsAnnotations = 'annotations.count > 0';

export default function AutoCurateModal({
  curationModalIsOpen,
  setCurationModalIsOpen,
}: {
  curationModalIsOpen: boolean;
  setCurationModalIsOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const { datasetId, accountName } = useParams<{ datasetId: string; accountName: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { datasetInfo, setDatasetInfo } = useDatasetContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { sliceInfo } = useSliceContext();
  const { appliedFilters } = useImageFilterContext();
  const { selectedData, selectedAllData } = useActionContext();

  const [curateSliceName, setCurateSliceName] = useState<string>('');
  const [trainSliceName, setTrainSliceName] = useState<string>('');
  const [mislabelSliceName, setMislabelSliceName] = useState<string>('');
  const [embeddingType, setEmbeddingType] = useState<EmbeddingType>(IMAGE);
  const [samplingMethod, setSamplingMethod] = useState<CurationType>(CURATE_WHAT_TO_LABEL);
  const [curateImageNumber, setCurateImageNumber] = useState<number>(0);
  const [curateImagePercent, setCurateImagePercent] = useState<number>(20);
  const [isStepsFinished, setIsStepsFinished] = useState<boolean>(false);

  const [isValidCurateName, setIsValidCurateName] = useState<boolean>(false);
  const [isValidTrainName, setIsValidTrainName] = useState<boolean>(false);
  const [isValidMislabelName, setIsValidMislabelName] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const { getJobPresignedUrl, postJob } = useCurateCommandsService();
  const commandContext = useCurateCommandContext();
  const { createSlice, getDataset, getSearchSliceName } = useCurateDatasetService();
  const { showPublicDatasets } = usePublicDatasetContext();

  const labeledImageCountQuery = useDatasetDataCountQuery({
    datasetId,
    sliceName: sliceInfo?.name,
    queryString: queryStringWithHiddenFilterAndDeselection
      ? `${queryStringWithHiddenFilterAndDeselection} AND ${containsAnnotations}`
      : containsAnnotations,
    appliedFilters,
  });

  const totalOrFilteredImageCountQuery = useDatasetDataCountQuery({
    datasetId,
    sliceName: sliceInfo?.name,
    queryString: queryStringWithHiddenFilterAndDeselection || '',
    appliedFilters,
  });

  const unlabeledDataCount =
    (totalOrFilteredImageCountQuery.data?.count || 0) - (labeledImageCountQuery.data?.count || 0);

  const labeledDataCount = labeledImageCountQuery.data?.count || 0;

  const trackingProperties = useMemo(() => {
    return {
      accountId: accountName,
      datasetId,
      curationType: samplingMethod,
      embeddingType: embeddingType,
      ...(samplingMethod === CURATE_WHAT_TO_LABEL && {
        curationPercent: curateImagePercent,
      }),
      ...(samplingMethod == SPLIT_TRAIN_VAL && {
        validationSetPercent: curateImagePercent,
      }),
      dataType: 'image',
      dataCount: curateImageNumber,
      queryString: queryStringWithHiddenFilterAndDeselection,
      appliedFilters,
    };
  }, [
    accountName,
    datasetId,
    samplingMethod,
    embeddingType,
    curateImagePercent,
    curateImageNumber,
    queryStringWithHiddenFilterAndDeselection,
    appliedFilters,
  ]);

  const trackDataSliceCreated = useCallback(
    (
      newSliceId: string,
      referrer:
        | 'auto-curate-validation'
        | 'auto-curate-train'
        | 'auto-curate-mislabel'
        | 'auto-curate-label'
        | 'auto-curate-edge',
      dataCount: number | 'UNKNOWN',
    ) => {
      AnalyticsTracker.dataSliceCreated({
        accountId: accountName,
        filters: combineFiltersForTracking(appliedFilters, sliceInfo?.id),
        sliceId: newSliceId,
        datasetId,
        viewType: getView(history),
        dataCount,
        dataType: 'image',
        referrer,
        queryString: queryStringWithHiddenFilterAndDeselection,
      });
    },
    [
      accountName,
      appliedFilters,
      datasetId,
      history,
      queryStringWithHiddenFilterAndDeselection,
      sliceInfo?.id,
    ],
  );

  const handleConfigParams = useCallback(
    async (samplingMethod: CurationType) => {
      if (samplingMethod === SPLIT_TRAIN_VAL) {
        const val_slice = await createSlice({
          name: curateSliceName,
          description: curateSliceName,
          datasetId,
        });
        trackDataSliceCreated(val_slice.id, 'auto-curate-validation', 'UNKNOWN');

        const train_slice = await createSlice({
          name: trainSliceName,
          description: trainSliceName,
          datasetId,
        });
        trackDataSliceCreated(train_slice.id, 'auto-curate-train', 'UNKNOWN');
        if (mislabelSliceName) {
          const mislabel_slice = await createSlice({
            name: mislabelSliceName,
            description: mislabelSliceName,
            datasetId,
          });
          trackDataSliceCreated(mislabel_slice.id, 'auto-curate-mislabel', 'UNKNOWN');
          return {
            num_val_images: curateImageNumber,
            val_slice_id: val_slice.id,
            train_slice_id: train_slice.id,
            mislabel_slice_id: mislabel_slice.id,
            annotation_classes: [],
          };
        } else {
          return {
            num_val_images: curateImageNumber,
            val_slice_id: val_slice.id,
            train_slice_id: train_slice.id,
            annotation_classes: [],
          };
        }
      } else if (samplingMethod === FIND_MISLABELS) {
        const result_slice = await createSlice({
          name: curateSliceName,
          description: curateSliceName,
          datasetId,
        });
        trackDataSliceCreated(result_slice.id, 'auto-curate-mislabel', 'UNKNOWN');
        return { result_slice_id: result_slice.id, annotation_classes: [] };
      } else {
        const result_slice = await createSlice({
          name: curateSliceName,
          description: curateSliceName,
          datasetId,
        });
        trackDataSliceCreated(
          result_slice.id,
          samplingMethod === 'CURATE_WHAT_TO_LABEL' ? 'auto-curate-label' : 'auto-curate-edge',
          curateImageNumber,
        );
        return {
          embedding_type: embeddingType,
          num_images: curateImageNumber,
          result_slice_id: result_slice.id,
          annotation_classes: [],
        };
      }
    },
    [
      createSlice,
      curateSliceName,
      datasetId,
      trackDataSliceCreated,
      trainSliceName,
      mislabelSliceName,
      curateImageNumber,
      embeddingType,
    ],
  );

  const handleAutoCurate = useCallback(async () => {
    try {
      const isSelectedAllOrNone = selectedAllData || selectedData.length === 0;
      if (!isSelectedAllOrNone) return;
      setIsLoading(true);
      const configParams = await handleConfigParams(samplingMethod);
      const dataJson = {
        dataset_id: datasetId,
        images: {
          ...(sliceInfo && { slice: sliceInfo.name }),
          ...(queryStringWithHiddenFilterAndDeselection &&
            queryStringWithHiddenFilterAndDeselection !== '' && {
              query: queryStringWithHiddenFilterAndDeselection,
            }),
          ...appliedFilters,
        },
        curation_type: samplingMethod,
        config: { ...configParams },
      };

      const result = await postJob({
        job_type: 'AUTO_CURATE',
        param: dataJson,
      });

      await commandContext.registerCommand(result.id, dataJson);
      const datasetInfo = await getDataset({
        datasetId,
        fromPublicDatasets: showPublicDatasets,
        expand: ['image_count', 'slice_count'],
      });
      setDatasetInfo(datasetInfo);
      setCurationModalIsOpen(false);
      AnalyticsTracker.autoCurateRequested({
        ...trackingProperties,
        sliceId: retrieveSliceIdsFromParams(configParams),
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedAllData,
    selectedData.length,
    handleConfigParams,
    samplingMethod,
    datasetId,
    sliceInfo,
    queryStringWithHiddenFilterAndDeselection,
    appliedFilters,
    postJob,
    commandContext,
    getDataset,
    showPublicDatasets,
    setDatasetInfo,
    setCurationModalIsOpen,
    trackingProperties,
  ]);

  const isOnlyLabeled = useMemo(
    () => samplingMethod === SPLIT_TRAIN_VAL || embeddingType === OBJECT,
    [samplingMethod, embeddingType],
  );

  const maxLabelsCount = useMemo(
    () => (isOnlyLabeled ? labeledDataCount : unlabeledDataCount),
    [isOnlyLabeled, labeledDataCount, unlabeledDataCount],
  );

  const handleCurateImageNumber = useCallback(
    (value: number) => {
      if (!Number.isNaN(value)) {
        if (value > maxLabelsCount) {
          setCurateImageNumber(maxLabelsCount);
          setCurateImagePercent(100);
        } else {
          setCurateImageNumber(value);
          setCurateImagePercent(Math.floor((value / maxLabelsCount) * 100));
        }
      }
    },
    [setCurateImageNumber, setCurateImagePercent, maxLabelsCount],
  );

  const handleCurateImagePercent = useCallback(
    (value: number) => {
      if (!Number.isNaN(value)) {
        if (value > 100) {
          setCurateImagePercent(100);
          setCurateImageNumber(maxLabelsCount);
        } else {
          setCurateImagePercent(value);
          setCurateImageNumber(Math.floor((maxLabelsCount * value) / 100));
        }
      }
    },
    [setCurateImagePercent, setCurateImageNumber, maxLabelsCount],
  );

  const handleChangeSamplingMethod = useCallback(
    (value: CurationType) => {
      setSamplingMethod(value);
    },
    [setSamplingMethod],
  );

  const handleChangeOption = useCallback(
    (value: EmbeddingType) => {
      setEmbeddingType(value);
    },
    [setEmbeddingType],
  );

  const handleLastButton = useCallback(async () => {
    setIsStepsFinished(true);
  }, [setIsStepsFinished]);

  const isSliceNamesValid =
    samplingMethod === SPLIT_TRAIN_VAL
      ? !!mislabelSliceName
        ? !!isValidMislabelName &&
          !!curateSliceName &&
          !!isValidCurateName &&
          !!trainSliceName &&
          !!isValidTrainName
        : !!curateSliceName && !!isValidCurateName && !!trainSliceName && !!isValidTrainName
      : !!curateSliceName && !!isValidCurateName;

  const sliceNameSteps = useMemo(() => {
    return {
      sliceName: {
        title: t('curate.autoCurate.sliceNameSteps.title'),
        summary:
          samplingMethod === SPLIT_TRAIN_VAL
            ? mislabelSliceName
              ? t('curate.autoCurate.sliceNameSteps.summary', {
                  curateSliceName: curateSliceName,
                  trainSliceName: trainSliceName,
                  mislabelSliceName: mislabelSliceName,
                })
              : t('curate.autoCurate.sliceNameSteps.summaryWithoutMislabel', {
                  curateSliceName: curateSliceName,
                  trainSliceName: trainSliceName,
                })
            : curateSliceName,
        isButtonEnabled: isSliceNamesValid,
        content: (
          <SliceNameSteps
            setCurateSliceName={setCurateSliceName}
            setTrainSliceName={setTrainSliceName}
            setMislabelSliceName={setMislabelSliceName}
            samplingMethod={samplingMethod}
            curateSliceName={curateSliceName}
            trainSliceName={trainSliceName}
            mislabelSliceName={mislabelSliceName}
            setIsValidCurateName={setIsValidCurateName}
            setIsValidTrainName={setIsValidTrainName}
            setIsValidMislabelName={setIsValidMislabelName}
          />
        ),
      },
    };
  }, [
    curateSliceName,
    samplingMethod,
    trainSliceName,
    mislabelSliceName,
    setCurateSliceName,
    setMislabelSliceName,
    setTrainSliceName,
    isValidCurateName,
    isValidTrainName,
    isValidMislabelName,
    setIsValidCurateName,
    setIsValidTrainName,
    setIsValidMislabelName,
    t,
  ]);

  const samplingMethodSteps = useMemo(() => {
    return {
      samplingMethod: {
        title: t('curate.autoCurate.samplingMethodSteps.title'),
        summary: t(`curate.autoCurate.summary.${samplingMethod}`),
        isButtonEnabled: true,
        content: (
          <SamplingMethodSteps
            samplingMethod={samplingMethod}
            handleChangeSamplingMethod={handleChangeSamplingMethod}
            labeledDataCount={labeledDataCount}
          />
        ),
      },
    };
  }, [samplingMethod, handleChangeSamplingMethod, labeledDataCount, t]);

  const embeddingTypeSteps = useMemo(() => {
    return {
      embeddingTypeSteps: {
        title: t('curate.autoCurate.embeddingTypeSteps.title'),
        summary:
          samplingMethod === CURATE_WHAT_TO_LABEL || samplingMethod === FIND_EDGE_CASES
            ? t(
                `curate.autoCurate.summary.${
                  samplingMethod as typeof CURATE_WHAT_TO_LABEL | typeof FIND_EDGE_CASES
                }_${embeddingType}`,
              )
            : '',
        isButtonEnabled: true,
        content: (
          <EmbeddingTypeSteps
            samplingMethod={samplingMethod}
            handleChangeOption={handleChangeOption}
            labeledDataCount={labeledDataCount}
            unlabeledDataCount={unlabeledDataCount}
            embeddingType={embeddingType}
          />
        ),
      },
    };
  }, [samplingMethod, handleChangeOption, labeledDataCount, unlabeledDataCount, embeddingType, t]);

  const numberOfImageSteps = useMemo(() => {
    return {
      numberOfImage: {
        title:
          samplingMethod === SPLIT_TRAIN_VAL
            ? t('curate.autoCurate.numberOfImageSteps.splitTitle')
            : t('curate.autoCurate.numberOfImageSteps.notSplitTitle'),
        summary: `${formatCount(curateImageNumber)}`,
        isButtonEnabled: !!curateImageNumber,
        content: (
          <NumberOfImageSteps
            curateImageNumber={curateImageNumber}
            handleCurateImageNumber={handleCurateImageNumber}
            samplingMethod={samplingMethod}
            unlabeledDataCount={unlabeledDataCount}
            labeledDataCount={labeledDataCount}
            handleCurateImagePercent={handleCurateImagePercent}
            curateImagePercent={curateImagePercent}
            embeddingType={embeddingType}
          />
        ),
      },
    };
  }, [
    samplingMethod,
    t,
    curateImageNumber,
    handleCurateImageNumber,
    unlabeledDataCount,
    labeledDataCount,
    handleCurateImagePercent,
    curateImagePercent,
    embeddingType,
  ]);

  const autoCurateSteps = useMemo(() => {
    if (samplingMethod === CURATE_WHAT_TO_LABEL || samplingMethod === FIND_EDGE_CASES)
      return {
        ...samplingMethodSteps,
        ...embeddingTypeSteps,
        ...numberOfImageSteps,
        ...sliceNameSteps,
      };
    else if (samplingMethod === SPLIT_TRAIN_VAL)
      return {
        ...samplingMethodSteps,
        ...numberOfImageSteps,
        ...sliceNameSteps,
      };
    else
      return {
        ...samplingMethodSteps,
        ...sliceNameSteps,
      };
  }, [samplingMethod, samplingMethodSteps, embeddingTypeSteps, numberOfImageSteps, sliceNameSteps]);

  useEffect(() => {
    setCurateImageNumber(
      Math.floor(isOnlyLabeled ? (labeledDataCount || 0) * 0.2 : (unlabeledDataCount || 0) * 0.2),
    );
  }, [isOnlyLabeled, labeledDataCount, unlabeledDataCount]);

  useEffect(() => {
    if (labeledDataCount === 0) setEmbeddingType(IMAGE);
    else setEmbeddingType(OBJECT);
  }, [labeledDataCount]);

  return (
    <Modal
      open={curationModalIsOpen}
      close={{
        onClose: () => setCurationModalIsOpen(false),
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.autoCurate.title')}
      mainButton={{
        text: 'Curate',
        onClick: handleAutoCurate,
        color: 'primary',
        disabled: !isStepsFinished || !isSliceNamesValid,
        isLoading,
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => setCurationModalIsOpen(false),
      }}
    >
      <Box py={2} px={4} style={{ width: '720px', boxSizing: 'border-box' }}>
        <Typography
          display="flex"
          alignItems="center"
          variant="m-regular"
          backgroundColor="secondary-100"
          color="secondary"
          gap={1}
          px={2}
          py={1}
          mb={1}
          width="100%"
        >
          <Icon icon={InfoCircle} />
          {t('curate.autoCurate.syntheticImageNotIncluded')}
        </Typography>
        <VerticalStepper.Simple
          cardProps={{ maxHeight: 446, overflow: 'auto' }}
          steps={{
            ...autoCurateSteps,
          }}
          lastStepButton={{ text: 'Done', onClick: handleLastButton }}
        />
      </Box>
    </Modal>
  );
}
