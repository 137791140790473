import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { LoadingSpinner, Plus } from '@superb-ai/icons';
import { Card } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Tab, TabList, Tabs, Typography } from '@superb-ai/ui';

import { Page } from '../../../../../menu';
import { useMetering } from '../../../../../queries/useMeteringQuery';
import { getUrl } from '../../../../../routes/util';
import { useDatasetsQuery } from '../../../queries/datasetQueries';
import { useCurateDatasetService } from '../../../services/DatasetService';
import { DatasetPool, SampleDataset } from '../../../types/routeTypes';
import {
  getNumConvertedParams,
  getQueryParams,
  getSearchQueryRoute,
} from '../../../utils/routeUtils';
import DeleteModal from '../dataset/modal/DeleteModal';
import { CurateDatasetsMenuItem, CurateSampleDatasetsMenuItem } from '../MenuItem';
import Pagination from '../Pagination';
import SortDatasetDropdown from '../SortDatasetDropdown';
import NewTutorial from '../tutorial/NewTutorial';
import CreateDatasetModal from './CreateDatasetModal';
import DatasetListBox from './DatasetListBox';
import DatasetListTable from './DatasetListTable';
import ListSearchInput from '../../../elements/ListSearchInput';

export default function Layout(): JSX.Element {
  const { accountName, datasetPool } = useParams<{
    accountName: string;
    datasetPool: DatasetPool;
    datasetId: string;
  }>();
  const history = useHistory();
  const DEFAULT_PAGE = 1;
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_SORT_ORDER = 'desc';
  const DEFAULT_SORT_BY = 'created_at';
  const { page, size, sortOrder, sortBy, search } = getQueryParams(history) as {
    page?: string;
    size?: string;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
    search?: string;
  };
  const curateMetering = useMetering('curate:data-volume');
  const isCurateEnabled = curateMetering.maxQuantity > 0;

  const isSampleDatasetPage = datasetPool === SampleDataset;

  const [gridStyle, setGridStyle] = useState<'box' | 'list'>('box');
  const [selectedDataset, setSelectedDataset] = useState<string[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [deleteConfirmInput, setDeleteConfirmInput] = useState<string>('');
  const [createDatasetModalIsOpen, setCreateDatasetModalIsOpen] = useState<boolean>(false);

  const { deleteDataset } = useCurateDatasetService();
  const { t } = useTranslation();

  useEffect(() => {
    if (curateMetering.isLoading) return;
    if (!isCurateEnabled && !isSampleDatasetPage) {
      history.replace(getUrl([accountName, 'curate', 'tutorial']));
    }
  }, [accountName, curateMetering.isLoading, history, isCurateEnabled, isSampleDatasetPage]);

  const params = {
    page: getNumConvertedParams(DEFAULT_PAGE, page),
    size: getNumConvertedParams(DEFAULT_PAGE_SIZE, size),
    sortOrder,
    sortBy,
    nameIcontains: search,
  };

  const { data: datasetsInfo, isLoading: isLoadingDataset } = useDatasetsQuery({
    size: 1,
    fromPublicDatasets: false,
  });
  const { data: sampleDatasetsInfo, isLoading: isSampleDatasetLoading } = useDatasetsQuery({
    size: 1,
    fromPublicDatasets: true,
  });

  const { count: totalCount } = datasetsInfo || { count: 0 };

  const {
    data: datasetData,
    refetch: refetchDataset,
    isFetching,
  } = useDatasetsQuery({
    ...params,
    expand: ['image_count', 'slice_count', 'thumbnail_url', 'synced_label_project_ids'],
    fromPublicDatasets: isSampleDatasetPage,
    queryRefetchOptions: { refetchOnWindowFocus: false },
  });
  const { results: datasetList, count } = datasetData || { results: [], count: 0 };

  function resetPageParams() {
    history.push(
      getSearchQueryRoute(history, {
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGE_SIZE,
        sortOrder: DEFAULT_SORT_ORDER,
        sortBy: DEFAULT_SORT_BY,
      }),
    );
  }
  function handleSearchName(value?: string) {
    history.push(getSearchQueryRoute(history, { page: DEFAULT_PAGE, search: value }));
  }

  function handleChangePage(_: any, page: number) {
    history.push(getSearchQueryRoute(history, { page: page + 1 }));
  }

  function handleChangeRowsPerPage(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const rowsPerPage = e.target.value;
    history.push(getSearchQueryRoute(history, { page: DEFAULT_PAGE, size: rowsPerPage }));
  }

  function handleSortOrder(sortOrder: string) {
    history.push(getSearchQueryRoute(history, { sortOrder, page: DEFAULT_PAGE }));
  }

  function handleSortBy(sortBy: string) {
    history.push(getSearchQueryRoute(history, { sortBy, page: DEFAULT_PAGE }));
  }

  const handleClickDelete = async (datasetId: string) => {
    await deleteDataset({ datasetId });
    resetPageParams();
    await refetchDataset();
  };

  const handleSelectDataset = (clickedBoxNum: string) => {
    if (selectedDataset.includes(clickedBoxNum))
      setSelectedDataset(prev => prev.filter(num => num !== clickedBoxNum));
    else setSelectedDataset(() => [clickedBoxNum]);
  };

  return (
    <>
      <Page>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap="small"
          mb={2}
          style={{ height: '40px' }}
        >
          <Box display="flex" alignItems="center" gap="small">
            <Tabs
              selectedId={datasetPool}
              onSelectTab={id => {
                if (!id || id === datasetPool) return;
                history.push(getUrl([`/${accountName}/curate/${id}/list`]));
              }}
            >
              <TabList aria-label="dataset tabs">
                <Tab id={CurateDatasetsMenuItem.path}>
                  {t('curate.datasets.tab.myDatasets')} ({totalCount.toLocaleString()})
                </Tab>
                <Tab id={CurateSampleDatasetsMenuItem.path}>
                  {t('curate.datasets.tab.sampleDatasets')} (
                  {(sampleDatasetsInfo?.count || 0).toLocaleString()})
                </Tab>
              </TabList>
            </Tabs>
          </Box>
          <Box display="flex" alignItems="center" gap="small">
            <Box style={{ width: 300 }}>
              <ListSearchInput
                isFetching={isFetching}
                handleSearchName={handleSearchName}
                defaultValue={search}
                placeholder={t('curate.datasets.searchForDataset')}
              />
            </Box>
            {gridStyle === 'box' && (
              <SortDatasetDropdown
                handleSortOrder={handleSortOrder}
                sortOrder={sortOrder || DEFAULT_SORT_ORDER}
                handleSortBy={handleSortBy}
                sortBy={sortBy || DEFAULT_SORT_BY}
              />
            )}
            {!isSampleDatasetPage && (
              <Button
                variant="strong-fill"
                color="primary"
                size="l"
                onClick={() => setCreateDatasetModalIsOpen(true)}
              >
                <Icon icon={Plus} />
                {t('curate.button.createDataset')}
              </Button>
            )}
          </Box>
        </Box>
        {isLoadingDataset || isFetching ? (
          <Box display="flex" p={1} justifyContent="center" alignItems="center">
            <Icon icon={LoadingSpinner} />
          </Box>
        ) : !totalCount && !isSampleDatasetPage ? (
          <NewTutorial setCreateDatasetModalIsOpen={setCreateDatasetModalIsOpen} />
        ) : gridStyle === 'box' ? (
          <>
            <Box width="100%" mt={2}>
              {count > 0 ? (
                <Box
                  display="grid"
                  gap="medium"
                  style={{
                    gridTemplateColumns: 'repeat(5, minmax(180px, 1fr))',
                  }}
                >
                  {datasetList.map(dataset => (
                    <DatasetListBox
                      key={dataset.id}
                      dataset={dataset}
                      setDeleteModalIsOpen={setDeleteModalIsOpen}
                      handleSelectDataset={handleSelectDataset}
                    />
                  ))}
                </Box>
              ) : (
                <Box>
                  <Card
                    style={{
                      background: '#F8F8FF',
                      padding: '16px',
                      marginTop: '16px',
                      boxShadow: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="m-regular" color="blue">
                      <Trans
                        t={t}
                        i18nKey={'curate.datasets.noSearchResult.message'}
                        values={{
                          noSearchResultTarget: t(
                            `curate.datasets.noSearchResult.noSearchResultTarget.${datasetPool}`,
                          ),
                          name: search,
                        }}
                      />
                    </Typography>
                  </Card>
                </Box>
              )}
            </Box>
            <Pagination
              totalCount={totalCount}
              size={getNumConvertedParams(DEFAULT_PAGE_SIZE, size)}
              page={getNumConvertedParams(DEFAULT_PAGE, page)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              type="DATASET"
            />
          </>
        ) : (
          <>
            <DatasetListTable
              datasetList={datasetList}
              setDeleteModalIsOpen={setDeleteModalIsOpen}
            />
            <Pagination
              totalCount={totalCount}
              size={getNumConvertedParams(DEFAULT_PAGE_SIZE, size)}
              page={getNumConvertedParams(DEFAULT_PAGE, page)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              type="DATASET"
            />
          </>
        )}
      </Page>
      {createDatasetModalIsOpen && (
        <CreateDatasetModal
          isOpen={createDatasetModalIsOpen}
          setIsOpen={setCreateDatasetModalIsOpen}
          refetchDataset={refetchDataset}
        />
      )}
      {deleteModalIsOpen && (
        <DeleteModal
          deleteModalIsOpen={deleteModalIsOpen}
          setDeleteModalIsOpen={setDeleteModalIsOpen}
          setDeleteConfirmInput={setDeleteConfirmInput}
          deleteConfirmInput={deleteConfirmInput}
          handleClickDelete={handleClickDelete}
          selected={selectedDataset}
          datasetList={datasetList}
        />
      )}
    </>
  );
}
