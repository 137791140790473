import { v4 as uuidv4 } from 'uuid';

import { LabelFields } from '../components/pages/analytics/customAnalytics/fields';
import {
  ChartStorage,
  DashboardSetting,
  DashboardStorage,
  DataEnum,
  DataSpec,
  TonyChartResponse,
  TonyCustomParams,
} from '../components/pages/analytics/customAnalytics/type';
import { getFeatureFlag } from '../contexts/FeatureFlagContext';
import ServiceUtils from '../utils/ServiceUtils';
import { AfterLoginCallback, apiCallAfterLogin, useFetcher } from '.';
import { BaseApiCallArgs } from './types';

export interface ApiParams extends BaseApiCallArgs {
  projectId: string;
  termsField?: LabelFields;
  apiParams?: Record<string, any>;
}

/** Get chart data */
function getTonyPath(dataSpec: DataSpec): string {
  return {
    [DataEnum.labelCount]: 'label_count',
    [DataEnum.objectCount]: 'object_count',
    [DataEnum.categoryCount]: 'category_count',
    [DataEnum.objectAnnotationCount]: 'object_annotation_count',
    // [DataEnum.autoLabeledObjectCounts]: 'auto_labeled_object_count',
  }[dataSpec];
}

const getChartData: AfterLoginCallback<TonyChartResponse, TonyCustomParams> = async params => {
  const { projectId, data, groupBy, apiParams } = params.data;
  const useAnalyticsProxy = getFeatureFlag('analyticsProxy').enabled;
  if (!data) throw new Error('dataSpec is required');
  if (!groupBy) throw new Error('groupBy is required');
  let url = `/projects/${projectId}/analytics/?data=${getTonyPath(data)}&group_by=${groupBy}`;
  if (apiParams) {
    const paramStr = `${ServiceUtils.getParamString(apiParams)}`;
    url += `&${paramStr}`;
  }
  const response = await apiCallAfterLogin({
    method: 'get',
    url,
    hasPublicApi: true,
    ...params,
    data: {},
    version: useAnalyticsProxy ? 2 : undefined,
  });
  return response.data;
};

export type GetDashboardParams = {
  tenant: string;
  projectId: string;
  dashboardId: string;
};

export type DeleteChartParams = GetDashboardParams & { chartId: string };
export type PatchDashboardParams = GetDashboardParams & { chartData: ChartStorage };

const getDashboardStorage = ({ tenant, projectId, dashboardId }: GetDashboardParams) =>
  `analytics.${tenant}.${projectId}.dashboard.${dashboardId}`;

export const DEFAULT_DASHBOARD = 'default-dashboard';

const deleteChart: AfterLoginCallback<{ chartId: string }, DeleteChartParams> = async params => {
  const { dashboardId, chartId, projectId, tenant } = params.data;
  const path = getDashboardStorage({ tenant, projectId, dashboardId });
  const data = localStorage.getItem(path) ?? '{}';
  const dashboard = JSON.parse(data);
  if (!dashboard?.charts) throw new Error('Dashboard is empty!');

  // TODO: what to do if id not found?
  const charts = dashboard?.charts.filter((c: Required<ChartStorage>) => c.chartId !== chartId);
  localStorage.setItem(path, JSON.stringify({ ...dashboard, charts }));
  return { chartId };
  // const url = `/projects/${projectId}/customize-analytics/dashboard/1`;
  // const res = await AuthService.apiCallAfterLogin({
  //   method: 'post',
  //   url,
  //   data: {}, // chart data
  //   hasPublicApi: true,
  // });
  // return res.data;
};

export type PatchChartParams = PatchDashboardParams & { chartId: string };

const editChart: AfterLoginCallback<{ chartId: string }, PatchChartParams> = async params => {
  const { dashboardId, chartData, chartId, projectId, tenant } = params.data;
  const path = getDashboardStorage({ tenant, projectId, dashboardId });
  const data = localStorage.getItem(path);

  if (!Boolean(data)) throw new Error('Dashboard is empty!');
  const dashboard = JSON.parse(data as string);

  // update chart
  localStorage.setItem(
    path,
    JSON.stringify({
      ...dashboard,
      charts: dashboard?.charts.map((c: Required<ChartStorage>) => {
        return c.chartId === chartId ? { ...c, ...chartData } : c;
      }),
    }),
  );

  return { chartId };
  // return res.data;
};

const addChart: AfterLoginCallback<{ chartId: string }, PatchDashboardParams> = async params => {
  const { dashboardId, chartData, projectId, tenant } = params.data;
  const path = getDashboardStorage({ tenant, projectId, dashboardId });
  const data = localStorage.getItem(path);

  const dashboard = Boolean(data)
    ? JSON.parse(data as string)
    : initDashboard(path, dashboardId, 'Customized Dashboard', 'This is primary dashboard');

  const newChartId = uuidv4();
  localStorage.setItem(
    path,
    JSON.stringify({
      ...dashboard,
      charts: [...dashboard.charts, { ...chartData, chartId: newChartId }],
    }),
  );

  return {
    chartId: newChartId,
  };
  // return res.data;
};

function initDashboard(
  path: string,
  dashboardId: string,
  name?: string,
  description?: string,
): DashboardStorage {
  const newDashboard = {
    description: description ?? 'This is primary dashboard',
    name: name ?? 'Customized Dashboard',
    id: dashboardId,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    charts: [],
    setting: {},
    // createdby: urlInfo?.user,
  };
  localStorage.setItem(path, JSON.stringify(newDashboard));
  return JSON.parse(localStorage.getItem(path) as string);
}

const getDashboard: AfterLoginCallback<DashboardStorage, GetDashboardParams> = async params => {
  const { tenant, projectId, dashboardId } = params.data;
  const path = getDashboardStorage({ tenant, projectId, dashboardId });
  let data = localStorage.getItem(path);
  if (!data) {
    initDashboard(path, dashboardId, 'Customized Dashboard', 'This is primary dashboard');
    data = localStorage.getItem(path);
  }
  const dashboard = JSON.parse(data as string);
  return dashboard;
  // const url = `/projects/${projectId}/customize-analytics/dashboard/1`;
  // const res = await AuthService.apiCallAfterLogin({
  //   method: 'post',
  //   url,
  //   data: {}, // chart data
  //   hasPublicApi: true,
  //   isGuest,
  //   urlInfo,
  //   origin: `postChartToDashboard:${origin}`,
  //   version: useAnalyticsProxy ? 2 : undefined,
  // });

  // return res.data;
};

const editDashboard: AfterLoginCallback<
  { dashboardId: string },
  GetDashboardParams & DashboardSetting
> = async params => {
  const { tenant, projectId, dashboardId, viewType } = params.data;
  const path = getDashboardStorage({ tenant, projectId, dashboardId });
  const data = localStorage.getItem(path);

  const dashboard = Boolean(data)
    ? JSON.parse(data as string)
    : initDashboard(path, dashboardId, 'Customized Dashboard', 'This is primary dashboard');

  localStorage.setItem(
    path,
    JSON.stringify({
      ...dashboard,
      setting: { viewType },
    }),
  );

  return { dashboardId };
};

export function useCustomAnalyticsService() {
  const { afterLoginFetcher } = useFetcher();
  return {
    getChartData: afterLoginFetcher(getChartData),
    deleteChart: afterLoginFetcher(deleteChart),
    addChart: afterLoginFetcher(addChart),
    editChart: afterLoginFetcher(editChart),
    getDashboard: afterLoginFetcher(getDashboard),
    editDashboard: afterLoginFetcher(editDashboard),
  };
}
