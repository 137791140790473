import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Label, Typography } from '@superb-ai/ui';

import { useDiagnosisGridFilterContext } from '../../../../../../contexts/DiagnosisGridFilterContext';
import { EvaluationResult } from '../filterSchema';

export default function EvaluationResultFilter() {
  const { t } = useTranslation();
  const options = [
    { value: 'TP', label: t('curate.diagnosis.filterPanel.truePositive') },
    { value: 'FP', label: t('curate.diagnosis.filterPanel.falsePositive') },
    { value: 'FN', label: t('curate.diagnosis.filterPanel.falseNegative') },
    { value: 'MC', label: t('curate.diagnosis.filterPanel.misclassified') },
  ] as const;

  const {
    filterBody,
    filterBody: { evaluationResult },
    updateSelectedFilter,
  } = useDiagnosisGridFilterContext();

  function handleChangeValue(values: EvaluationResult[]) {
    updateSelectedFilter({
      ...filterBody,
      ...{ evaluationResult: values.length > 0 ? values : undefined },
    });
  }

  return (
    <Box display="grid" gap={0.5}>
      <Typography variant="m-strong">
        {t('curate.diagnosis.filterPanel.evaluationResult')}
      </Typography>
      {options.map(option => {
        const isChecked = !!evaluationResult && evaluationResult?.includes(option.value);

        return (
          <Label key={option.value}>
            <Checkbox
              value={isChecked}
              onClick={() =>
                handleChangeValue(
                  isChecked
                    ? evaluationResult.filter(result => result !== option.value)
                    : [...(evaluationResult || []), option.value],
                )
              }
            />
            {option.label}
          </Label>
        );
      })}
    </Box>
  );
}
