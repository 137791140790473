import React from 'react';

import { Box } from '@superb-ai/norwegian-forest';

import IntegrationCard from './IntegrationCard';

const Integrations: React.FC = () => {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(350px, 1fr))"
        gridTemplateRows="repeat(auto-fill, max-content)"
        flexWrap="wrap"
        gap="16px"
      >
        <IntegrationCard target="S3" />
        <IntegrationCard target="GS" />
        <IntegrationCard target="ABS" />
        <IntegrationCard target="SLACK" />
      </Box>
    </>
  );
};

export default Integrations;
