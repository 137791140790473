import { useMemo } from 'react';

import { useAuthInfo } from '../contexts/AuthContext';
import { useRouteInfo } from '../contexts/RouteContext';

export function useApiDefaultParams<T extends Record<string, any>>(additionalParams?: T) {
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const params = useMemo(
    () =>
      ({
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
        ...additionalParams,
      } as {
        isGuest: boolean;
        urlInfo: Record<string, any>;
      } & T),
    [additionalParams, authInfo.isGuest, routeInfo.urlMatchInfo],
  );

  return params;
}
