import React from 'react';

import { Clear, EditFilter } from '@superb-ai/icons';
import { Box, Icon, IconButton, Typography } from '@superb-ai/ui';

import ResetTotalButton from './ResetTotalButton';

export default function FilterHeader({
  label,
  hasAppliedFilters,
  initializeAppliedFilter,
  initializeSelectedFilter,
  setIsFilterOpen,
}: {
  label: string;
  hasAppliedFilters: boolean;
  initializeAppliedFilter: () => void;
  initializeSelectedFilter: () => void;
  setIsFilterOpen: (arg: boolean) => void;
}) {
  return (
    <Box
      p={1}
      display="flex"
      alignItems="center"
      gap={1}
      borderBottom="1px solid"
      borderColor="gray-150"
    >
      <Icon icon={EditFilter} />
      <Typography variant="m-strong">{label}</Typography>
      <Box display="flex" gap={1} ml="auto" alignItems="center">
        <ResetTotalButton
          hasAppliedFilters={hasAppliedFilters}
          initializeAppliedFilter={initializeAppliedFilter}
          initializeSelectedFilter={initializeSelectedFilter}
        />
        <IconButton variant="text" icon={Clear} size="s" onClick={() => setIsFilterOpen(false)} />
      </Box>
    </Box>
  );
}
