import { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreHorizontal } from '@superb-ai/icons';
import {
  Box,
  Chip,
  DropdownMenu,
  DropdownMenuItem,
  LoadingIndicator,
} from '@superb-ai/norwegian-forest';
import { IconButton, ToggleButton, Typography } from '@superb-ai/ui';

import { useUserApiKeyUpdateMutation } from '../../../../../queries/userAccessKey';
import { useUsersQuery } from '../../../../../queries/useUsers';
import { UserApiKey } from '../../../../../services/UserApiKeyService';
import { formatDateTime, parseDate } from '../../../../../utils/date';
import UserNameWithAvatar from '../../../../elements/UserNameWithAvatar';
import GridTable from '../GridTable';
import { getIsExpired } from '../PersonalAccessKey/util';

interface Props {
  openConfirmDialog: () => void;
  openEditDialog: () => void;
  data: UserApiKey;
}

export default function ManageMembersTableRow({ data, openConfirmDialog, openEditDialog }: Props) {
  const { t } = useTranslation();
  const { data: users } = useUsersQuery({ params: { asList: '' } });
  const { mutate: updateKey, isLoading: isUpdating } = useUserApiKeyUpdateMutation();

  const { name, email, status, createdAt, expirationDate, apiKeyId } = data;

  const user = users?.find(u => u.email === email);

  const isActive = status === 'Active';
  const isExpired = getIsExpired(data?.expirationDate);

  function changeStatus(nextActive: boolean) {
    const nextStatus = nextActive ? 'Active' : 'Inactive';
    updateKey(
      {
        apiKeyId,
        status: nextStatus,
      },
      {
        onSuccess() {},
      },
    );
  }

  const GridStyledTd = ({ children, style }: { children?: ReactNode; style?: CSSProperties }) => {
    return (
      <GridTable.TdText style={{ borderBottom: '1px solid #E8E8E8', ...style }}>
        <Typography color={isExpired ? 'gray-300' : 'gray-400'}>{children}</Typography>
      </GridTable.TdText>
    );
  };

  return (
    <GridTable.Tr>
      <GridStyledTd>{name}</GridStyledTd>
      <GridStyledTd>{user && <UserNameWithAvatar userInfo={user} />}</GridStyledTd>
      <GridStyledTd>{email}</GridStyledTd>
      <GridStyledTd>{formatDateTime(parseDate(createdAt, true))}</GridStyledTd>
      <GridStyledTd>
        {!expirationDate ? (
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="m-regular">{t('settings.advanced.noExpirationDate')}</Typography>
          </Box>
        ) : (
          formatDateTime(expirationDate)
        )}
      </GridStyledTd>
      <GridTable.Td style={{ borderBottom: '1px solid #E8E8E8' }}>
        {isExpired ? (
          <Chip>{t('settings.advanced.expired')}</Chip>
        ) : (
          <ToggleButton
            checked={isActive}
            disabled={isUpdating}
            onClick={() => changeStatus(!isActive)}
          />
        )}
        {isUpdating && <LoadingIndicator variant="spinner-small" size="16px" color="cloud" />}
      </GridTable.Td>
      <GridStyledTd style={{ justifyContent: 'flex-end' }}>
        <DropdownMenu
          AnchorElement={<IconButton icon={MoreHorizontal} variant="text" />}
          placement="bottom-start"
        >
          <DropdownMenuItem onClick={openEditDialog}>
            {t('settings.advanced.editDialogTitle')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={openConfirmDialog}>
            {t('settings.advanced.deleteAccessKey')}
          </DropdownMenuItem>
        </DropdownMenu>
      </GridStyledTd>
    </GridTable.Tr>
  );
}
