import React, { useEffect, useMemo, useState } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { Box, Button, IconButton, Modal, Stepper, Typography } from '@superb-ai/norwegian-forest';
import { compact, map, omit, set } from 'lodash';
import { useSnackbar } from 'notistack';

import AnalyticsTracker from '../../../../../analyticsTracker';
import { LABELS_DO_NOT_EXIST, UNKNOWN_ERROR } from '../../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext';
import { useFilterInfo } from '../../../../../contexts/FilterContext';
import { useLabelCommandContext } from '../../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import CommandsService from '../../../../../services/CommandsService';
import { MemberData } from '../../../../../types/memberTypes';
import { allFilters } from '../../../../../utils/filter/labelFilter';
import ParamUtils from '../../../../../utils/ParamUtils';
import { useSearchParams } from '../../../../../utils/router-utils';
import CircularProgressBox from '../../../../elements/CircularProgressBox';
import AssignAlert from './AssignAlert';
import DistributionSettings from './DistributionSettings';
import MembersTable from './MembersTable';
import RequestReviewAlert from './RequestReviewAlert';
import SelectMembers from './SelectMembers';
import { calculateNewLabelsCount } from './utils';

interface AssignDialogProps {
  checkedLabels: any[];
  setCheckedLabels: any;
  isAllLabelsChecked: boolean;
  checkedLabelsCount: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  getLabels: any;
  type: 'labeler' | 'reviewer';
}

const typeToCommand = {
  labeler: 'LABELS_ASSIGN_LABELER',
  reviewer: 'LABELS_ASSIGN_REVIEWER',
} as const;

const AllSteps = {
  members: { label: 'Select members' },
  settings: { label: 'Adjust settings' },
  verify: { label: 'Verify' },
};

const applyI18nToSteps = (t: TFunction, steps: typeof AllSteps) => {
  return {
    members: { label: t('labels.assignDialog.steps.selectMembers') },
    settings: { label: t('labels.assignDialog.steps.adjustSettings') },
    verify: { label: t('labels.assignDialog.steps.verify') },
  };
};

export type Step = keyof typeof AllSteps;

const AssignDialog = ({
  checkedLabels,
  setCheckedLabels,
  checkedLabelsCount,
  isAllLabelsChecked,
  isOpen,
  setIsOpen,
  getLabels,
  type,
}: AssignDialogProps) => {
  const { t } = useTranslation();
  const commandContext = useLabelCommandContext();
  const projectInfo = useProjectInfo();
  const { selectedFilters } = useFilterInfo();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { enqueueSnackbar } = useSnackbar();
  const urlSearchParams = useSearchParams();
  const { tagIds } = projectInfo;

  const [selectedMembers, setSelectedMembers] = useState<MemberData[]>([]);
  const [labelSubsampleCount, setLabelSubsampleCount] = useState(0);
  const [priorityField, setPriorityField] = useState('-last_updated_at');
  const [method, setMethod] = useState<'equal' | 'proportional'>('equal');
  const [isLoading, setIsLoading] = useState(false);
  const [isSlowLoading, setIsSlowLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<Step>('members');
  const [confirmedStatusBoard, setConfirmedStatusBoard] = useState(false);
  const [statusBoardLoaded, setStatusBoardLoaded] = useState(false);

  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(projectInfo.project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  useEffect(() => {
    setLabelSubsampleCount(checkedLabelsCount);
  }, [checkedLabelsCount]);

  useEffect(() => {
    setPriorityField(routeInfo.params.ordering || '-last_updated_at');
  }, [routeInfo.params.ordering]);

  const searchParams = routeInfo.params.dataKey && {
    [enabledLoki ? 'assetKeyContains' : 'assetKeyIcontains']: routeInfo.params.dataKey,
  };
  const filterApiParams = useMemo(
    () =>
      ParamUtils.getApiParamsForFilter({
        filterParams: urlSearchParams,
        tagIds,
        workApp: projectInfo.project.workapp,
      }),
    [routeInfo.params, tagIds],
  );

  const params = !isAllLabelsChecked
    ? { idIn: checkedLabels }
    : { ...filterApiParams, ...searchParams };

  const init = () => {
    setActiveStep('members');
    setSelectedMembers([]);
    setConfirmedStatusBoard(false);
    setStatusBoardLoaded(false);
    setIsLoading(false);
    setMethod('equal');
  };

  // Count consensus labels included in this assignment for tracking
  // async function getConsensusLabelCount() {
  //   return await (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
  //     params: {
  //       ...params,
  //       labelTypeIn: ['CONSENSUS'],
  //     },
  //     projectId: routeInfo.urlMatchInfo.projectId,
  //     isGuest: authInfo.isGuest,
  //     urlInfo: routeInfo.urlMatchInfo,
  //   });
  // }

  const handleClickAction = async () => {
    if (activeStep === 'members') {
      setActiveStep('settings');
    } else if (activeStep === 'settings') {
      calculateNewLabelsCount(selectedMembers, labelSubsampleCount, method, type);
      setActiveStep('verify');
    } else if (activeStep === 'verify') {
      setIsLoading(true);
      try {
        const filteredParams = omit(params, [
          'page',
          'pageSize',
          'ordering',
          'searchAfter',
          'searchBefore',
        ]);
        const response = await (enabledLoki
          ? CommandsService.createCommandV2
          : CommandsService.createCommand)({
          type: typeToCommand[type],
          projectId: routeInfo.urlMatchInfo.projectId,
          params: {
            ...filteredParams,
            //ordering: priorityField,
          },
          actionInfo: {
            workAssignees: selectedMembers.map(member => member.email),
            limit: labelSubsampleCount,
            distributionMethod: method.toUpperCase(),
          },
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        commandContext.registerCommand(response.data.id);

        // const consensusLabelCount = await getConsensusLabelCount();
        init();
        setIsOpen(false);
        setCheckedLabels([]);
        getLabels();
        AnalyticsTracker.membersAssigned({
          targetRole: type,
          distributionMethod: method,
          memberCount: selectedMembers.length,
          labelCount: labelSubsampleCount, // assigned count
          accountId: authInfo?.accountName ?? '',
        });
      } catch (err: any) {
        if (err.message === 'Not Found') {
          enqueueSnackbar(LABELS_DO_NOT_EXIST({ t }), { variant: 'error' });
        } else {
          enqueueSnackbar(UNKNOWN_ERROR({ t }), { variant: 'error' });
        }
      } finally {
        setIsLoading(false);
        setIsSlowLoading(false);
      }
    }
  };

  const handleClickClose = () => {
    init();
    setIsOpen(false);
  };

  const handleClickBack = () => {
    if (activeStep === 'members') {
      setSelectedMembers([]);
    }
    if (activeStep === 'verify') {
      setActiveStep('settings');
    }
    if (activeStep === 'settings') {
      setActiveStep('members');
    }
  };

  const getDialogContent = (step: Step): JSX.Element => {
    switch (step) {
      case 'members':
        return <SelectMembers {...{ selectedMembers, setSelectedMembers, type }} />;
      case 'settings':
        return (
          <DistributionSettings
            {...{
              checkedLabelsCount,
              labelSubsampleCount,
              setLabelSubsampleCount,
              priorityField,
              setPriorityField,
              method,
              setMethod,
              filterParams: params,
            }}
          />
        );
      case 'verify':
        const columns =
          type === 'labeler'
            ? ['name', 'email', 'tenantRole', 'inProgressCount', 'newLabelsCount']
            : ['name', 'email', 'tenantRole', 'unreviewedCount', 'newLabelsCount'];
        return (
          <Box
            flex={1}
            height="100%"
            px={4}
            pt={4}
            style={{ overflow: 'hidden', boxShadow: 'inset 0 -1px 0 0 #EFEFEF' }}
          >
            <MembersTable members={selectedMembers} columns={columns} />
          </Box>
        );
    }
  };

  const handleClickConfirmStatusBoard = () => {
    setConfirmedStatusBoard(true);
    setStatusBoardLoaded(true);
  };

  const onStatusBoardLoad = ({
    submittedLabelsCount,
    labelingInProgressCount,
    skippedLabelsCount,
  }: Record<'submittedLabelsCount' | 'labelingInProgressCount' | 'skippedLabelsCount', number>) => {
    if (
      (type === 'labeler' && submittedLabelsCount === 0 && skippedLabelsCount === 0) ||
      (type === 'reviewer' && labelingInProgressCount === 0)
    ) {
      setConfirmedStatusBoard(true);
    } else {
      setStatusBoardLoaded(true);
    }
  };

  const addLabelListStatusFilter = () => {
    const filterByType =
      type === 'labeler'
        ? [
            {
              filterBy: 'status' as const,
              condition: 'is any one of',
              options: [{ value: 'in progress', label: 'in progress' }],
            },
            { filterBy: 'assignee' as const, condition: 'does not exist' },
          ]
        : [
            {
              filterBy: 'status' as const,
              condition: 'is any one of',
              options: [
                { value: 'submitted', label: 'submitted' },
                { value: 'skipped', label: 'skipped' },
              ],
            },
            { filterBy: 'reviewer' as const, condition: 'does not exist' },
          ];
    const filterParams = ParamUtils.getUrlParamsForFilters([...selectedFilters, ...filterByType]);
    const otherParams = omit(routeInfo.params, compact(map(allFilters, 'key'))) as Record<
      string,
      any
    >;

    if (otherParams.page && otherParams.page > 1) {
      set(otherParams, 'page', 1);
    }

    const searchParams = new URLSearchParams([
      ...filterParams,
      ...Object.entries(otherParams),
    ] as string[][]);
    routeInfo.history.push(`?${searchParams.toString()}`);
    init();
    setIsOpen(false);
  };

  const alertProps = {
    isOpen: isOpen && !confirmedStatusBoard,
    isAllLabelsChecked: isAllLabelsChecked,
    statusBoardLoaded: statusBoardLoaded,
    checkedLabels: checkedLabels,
    filterApiParams: filterApiParams,
    confirmedStatusBoard: confirmedStatusBoard,
    onStatusBoardLoad: onStatusBoardLoad,
    handleClickConfirmStatusBoard: handleClickConfirmStatusBoard,
    handleClickClose: handleClickClose,
    addLabelListStatusFilter: addLabelListStatusFilter,
  };

  return (
    <>
      {type === 'labeler' ? (
        <AssignAlert {...alertProps} />
      ) : (
        <RequestReviewAlert {...alertProps} />
      )}
      <Modal open={isOpen && confirmedStatusBoard} size="auto">
        <Box
          display="flex"
          flexDirection="column"
          width="calc(100vw - 64px)"
          maxWidth="960px"
          height="calc(100vh - 64px)"
          maxHeight="840px"
        >
          <Box height="0px" pt={1.25} pr={1.25} style={{ textAlign: 'right', zIndex: 2 }}>
            {!isLoading && (
              <IconButton
                icon="clear"
                size="m"
                onClick={handleClickClose}
                round
                color="textSecondary"
              />
            )}
          </Box>
          <Box py={2.5} display="flex" justifyContent="center">
            <Typography variant="headline4" themedColor="primary">
              {type === 'labeler'
                ? t('labels.button.assignToLabeler')
                : t('labels.button.requestReview')}
            </Typography>
          </Box>
          <Box display="flex" flex={1} flexDirection="column" style={{ overflow: 'auto' }}>
            <Box mb={2.5} display="flex" justifyContent="center">
              <Typography variant="body3">
                <Trans
                  i18nKey="labels.assignDialog.divideLabel"
                  values={{ labelCount: labelSubsampleCount }}
                />
              </Typography>
            </Box>
            <Box
              p={1.25}
              themedBackgroundColor={['grey', 60]}
              display="flex"
              justifyContent="center"
              style={{ border: '1px solid #EFEFEF', borderWidth: '1px 0' }}
            >
              <Stepper steps={applyI18nToSteps(t, AllSteps)} activeStep={activeStep} />
            </Box>
            {getDialogContent(activeStep)}
          </Box>
          <Box display="flex" justifyContent="center" pt={3}>
            <Box display="flex" gap="8px">
              <Button
                variant="text"
                color="primary"
                onClick={handleClickBack}
                disabled={selectedMembers.length === 0}
              >
                {activeStep === 'members' ? t('button.clear') : t('button.back')}
              </Button>
              {isLoading ? (
                <>
                  <CircularProgressBox circularProps={{ size: 30 }} />
                  {isSlowLoading && (
                    <Typography variant="body2" color="textSecondary">
                      {t('labels.assignDialog.pleaseWait')}
                    </Typography>
                  )}
                </>
              ) : (
                <Button
                  variant="strong-fill"
                  color="primary"
                  onClick={handleClickAction}
                  disabled={selectedMembers.length === 0 || labelSubsampleCount === 0}
                >
                  {activeStep === 'verify' ? t('button.apply') : t('button.next')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AssignDialog;
