import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Icon } from '@superb-ai/norwegian-forest';
import { concat, last, slice } from 'lodash';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import LabelsService from '../../../services/LabelsService';
import { sleep } from '../../../utils/SpbUtils';
import helper from './helper';
import ToastIcon from './Icon';

const pageSize = 1000;

interface Props {
  classes: Record<string, string>;
  toast: { options: any };
  isCancel: boolean;
  handleClickCancel: () => void;
  handleClickClose: () => void;
  handleClickView: () => void;
}

const Label: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes, toast, isCancel, handleClickCancel, handleClickClose, handleClickView } = props;

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const [message, setMessage] = useState('');
  const [lastId, setLastId] = useState<string | null>(null);
  const [labels, setLabels] = useState<any[]>([]);
  const [deleteLabelIndex, setDeleteLabelIndex] = useState(-1);
  const [isSuccess, setIsSuccess] = useState(false);

  const getLabelsIdPerPageSize = async (lastId: string | null) => {
    const { res } = await LabelsService.getLabelsId({
      projectId: toast.options.projectId,
      params: {
        ...toast.options.paramsForFilter,
        lastId,
        pageSize,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    const { results, hasNext } = res.data;
    const nextLabels = slice(labels);
    setLabels(concat(nextLabels, results));

    if (hasNext) {
      setLastId(last(results as any[]).id);
    } else {
      toast.options.setState('endPrepare');
    }
  };

  const startPrepare = async () => {
    setMessage('Preparing delete labels ...');
    if (toast.options.totalCount <= pageSize) {
      const { res } = await LabelsService.getLabelsId({
        projectId: toast.options.projectId,
        params: {
          ...toast.options.paramsForFilter,
          pageSize,
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      setLabels(res.data.results);
      toast.options.setState('endPrepare');
    } else {
      getLabelsIdPerPageSize(null);
    }
  };

  const endPrepare = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    toast.options.setState('startProgress');
  };

  const startProgress = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    setDeleteLabelIndex(0);
    toast.options.setState('progress');
  };

  const deleteLabel = async (index: number) => {
    if (index < labels.length) {
      try {
        await LabelsService.deleteLabel({
          projectId: toast.options.projectId,
          labelId: labels[index].id,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
      } catch (e: any) {
        // Ignore Not Found error
      }
      setDeleteLabelIndex(index + 1);
      setMessage(
        `Deleting labels ... ${(index + 1).toLocaleString('en')} / ${labels.length.toLocaleString(
          'en',
        )}`,
      );
    } else {
      toast.options.setState('endProgress');
    }
  };

  const endProgress = async () => {
    setMessage(`Completed deleting ${labels.length.toLocaleString('en')} labels`);
    setIsSuccess(true);
  };

  const cancel = async () => {
    setMessage('Canceled deleting labels');

    await sleep(3000);
    toast.options.setState('close');
    await sleep(0);
    toast.options.deleteToast();
  };

  const close = async () => {
    if (isCancel) return;

    await sleep(0);
    toast.options.deleteToast();
  };

  // start prepare -> prepare (use effect) -> end prepare -> start progress -> progress (use effect) -> end progress -> close
  // cancel
  useEffect(
    () => {
      switch (toast.options.state) {
        case 'startPrepare':
          startPrepare();
          break;
        case 'endPrepare':
          endPrepare();
          break;
        case 'startProgress':
          startProgress();
          break;
        case 'endProgress':
          endProgress();
          break;
        case 'cancel':
          cancel();
          break;
        case 'close':
          close();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [toast.options.state],
  );

  useEffect(
    () => {
      if (!lastId) return;

      if (isCancel) {
        toast.options.setState('cancel');
        return;
      }

      getLabelsIdPerPageSize(lastId);
      setMessage(
        `Preparing delete ${labels.length.toLocaleString(
          'en',
        )} / ${toast.options.totalCount.toLocaleString('en')} labels`,
      );
    },
    // eslint-disable-next-line
    [lastId],
  );

  useEffect(
    () => {
      if (deleteLabelIndex === -1) return;

      if (isCancel) {
        toast.options.setState('cancel');
        return;
      }

      deleteLabel(deleteLabelIndex);
    },
    // eslint-disable-next-line
    [deleteLabelIndex],
  );

  return (
    <MUI.Box display="flex" alignItems="center" height="100%">
      <ToastIcon toastState={toast.options.state} />
      <MUI.Typography className={classes.message}>{message} </MUI.Typography>
      {isSuccess && (
        <MUI.Typography className={classes.viewText} onClick={handleClickView}>
          View
        </MUI.Typography>
      )}

      {helper.getIsCancelActive(toast.options.state) ? (
        <MUI.Typography className={classes.cancelText} onClick={handleClickCancel}>
          Cancel
        </MUI.Typography>
      ) : (
        <span onClick={handleClickClose}>
          <Icon name="clear" color="background" className={classes.closeIcon} size="14px" />
        </span>
      )}
    </MUI.Box>
  );
};

export default Label;
