import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, RadioGroup } from '@superb-ai/ui';

export default function SelfAssign({
  selfAssign,
  setSelfAssign,
}: {
  selfAssign: boolean;
  setSelfAssign: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const value = selfAssign ? 'on' : 'off';

  const labelProps = {
    borderRadius: '2px',
    p: 0.5,
    border: '1px solid',
    borderColor: { default: 'gray-200', checked: 'primary' },
    color: { checked: 'primary' },
    backgroundColor: { checked: 'primary-100' },
    alignItems: 'flex-start',
  } as ComponentProps<typeof RadioGroup>['labelProps'];

  const options = [
    {
      value: 'on',
      label: (
        <Box style={{ marginTop: '2px' }}>
          {t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.on.title')}
          <br />
          {t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.on.description')}
        </Box>
      ),
    },
    {
      value: 'off',
      label: (
        <Box style={{ marginTop: '2px' }}>
          {t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.off.title')}
          <br />
          {t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.off.description')}
        </Box>
      ),
    },
  ] as const;
  return (
    <RadioGroup
      options={options}
      value={value}
      onChangeValue={value => setSelfAssign(value === 'on')}
      labelProps={labelProps}
    />
  );
}
