import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Button } from '@superb-ai/norwegian-forest';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { useUploadInfo } from '../../../contexts/UploadContext';
import AnalyticsTracker from '../../../analyticsTracker';
import { hasSufficientLabelVolume } from '../../../queries/meteringLogic';
import { useMetering } from '../../../queries/useMeteringQuery';
import UploadLayout from '../../elements/newUpload/Layout';
import UpgradePlanTooltip from '../../elements/UpgradePlanTooltip';

export default function UploadButton(): JSX.Element {
  const { t } = useTranslation();
  const hasDataCapacity = hasSufficientLabelVolume(useMetering('label:data-volume'));
  const routeInfo = useRouteInfo();
  const match = useRouteMatch();

  const { setIsUploadModalOpen } = useUploadInfo();

  if (!hasDataCapacity)
    return (
      <>
        <UpgradePlanTooltip
          placement="bottom-end"
          anchorEl={
            <Button disabled IconAdornment="upload" variant="strong-fill" color="primary" size="l">
              {t('data.button.uploadData')}
            </Button>
          }
        />
        <UploadLayout />
      </>
    );

  return (
    <>
      <Button
        onClick={() => {
          setIsUploadModalOpen(true);
          AnalyticsTracker.labelUploadDialogOpened({
            referrer: 'label-data-list',
            accountId: routeInfo.urlMatchInfo.accountName,
          });
        }}
        IconAdornment="upload"
        variant="strong-fill"
        color="primary"
        size="l"
      >
        {t('data.button.uploadData')}
      </Button>
      <UploadLayout />
    </>
  );
}
