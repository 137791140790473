import { useModalContext } from '../contexts/ModalContext';

const defaultMainButtonData = { text: 'Okay' };
const defaultSubButtonData = null;

interface OpenModalArgs {
  modalMessage(messageData: any): any;
  messageData?: any;
  mainButtonData?: any;
  subButtonData?: any;
}

// eslint-disable-next-line import/prefer-default-export
export const useModal = (): {
  openModal: (args: OpenModalArgs) => void;
  closeModal: () => void;
} => {
  const modalInfo = useModalContext();
  const { isOpen, setIsOpen, setMessageObject, setButtonObject } = modalInfo;

  // (tsnoh) messageData를 왜 넣었지...? 빼도 될듯
  const openModal = ({
    modalMessage,
    messageData,
    mainButtonData,
    subButtonData,
  }: OpenModalArgs) => {
    if (isOpen) return;

    const resultOfCreateModalMessage = modalMessage(messageData);
    setMessageObject(resultOfCreateModalMessage);
    setButtonObject({
      mainButton: mainButtonData || defaultMainButtonData,
      subButton: subButtonData || defaultSubButtonData,
    });

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return { openModal, closeModal };
};
