import { HistoryType } from '../types/exportTypes';
import ServiceUtils from '../utils/ServiceUtils';
import AuthService from './AuthService';
import { ApiCall, Params } from './types';

const getExportHistories: ApiCall<
  { projectId: string; params: Params },
  { results: HistoryType[]; count: number }
> = async args => {
  const { projectId, params, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/exports/?${ServiceUtils.getParamString(params)}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getExportHistory: ApiCall<
  { projectId: string; exportId: string },
  HistoryType
> = async args => {
  const { projectId, exportId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'get',
    url: `/projects/${projectId}/exports/${exportId}/`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const getExportDownloadPresignedUrl: ApiCall<
  { projectId: string; exportId: string; transform?: string },
  any
> = async args => {
  const { projectId, exportId, transform, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: `/projects/${projectId}/exports/${exportId}/${transform ? `${transform}/` : ''}`,
    data: {},
    hasPublicApi: true,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const changeExportName: ApiCall<
  { projectId: string; exportId: string; name: string },
  any
> = async args => {
  const { projectId, exportId, isGuest, urlInfo, name } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'patch',
    url: `/projects/${projectId}/exports/${exportId}/`,
    data: { name: name },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

const cancelExport: ApiCall<{ projectId: string; exportId: string }, any> = async args => {
  const { projectId, exportId, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: `/projects/${projectId}/exports/${exportId}/`,
    data: {},
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

export default {
  cancelExport,
  getExportHistories,
  getExportHistory,
  getExportDownloadPresignedUrl,
  changeExportName,
};
