import { GenAi } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { PurposeType } from '../services/types';
import { GenerationAIBaselineDetailsMenuItem } from './baseline-details/MenuItem';
import { GenerationAIDetailsMenuItem } from './details/MenuItem';
import { Layout } from './Layout';
import { GenerativeAIListMenuItem } from './list/MenuItem';

export const GenerativeAIMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelGenerativeAI,
  path: 'generation' as PurposeType,
  matchParam: ':purpose(generation)',
  group: 'purpose',
  title: ({ t }) => t('model.generativeAi.title'),
  component: Layout,
  children: [
    GenerativeAIListMenuItem,
    GenerationAIBaselineDetailsMenuItem,
    GenerationAIDetailsMenuItem,
  ],
  icon: <Icon icon={GenAi} />,
});
