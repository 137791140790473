import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';

import { Menu, MenuItem } from '@superb-ai/norwegian-forest';
import { Tooltip } from '@superb-ai/ui';

import { useDatasetContext } from '../apps/Curate/contexts/DatasetContext';
import AppsMenu from '../components/elements/appMenu/AppsMenu';
import { useAuthInfo } from '../contexts/AuthContext';
import { useFeatureFlags } from '../contexts/FeatureFlagContext';
import { useProjectInfo } from '../contexts/ProjectContext';
import { getUrl } from '../routes/util';
import { evaluate } from '../utils/functional';
import { BaseMenuItem, MenuItemContext } from './MenuItem';

//type State = 'expanded' | 'collapsed';
type Variant = React.ComponentProps<typeof MenuItem>['variant'];

export interface ExtendedBaseMenuItem extends BaseMenuItem<string, ExtendedBaseMenuItem> {
  disabled: boolean;
  url: string;
}

interface MenuProps {
  items: BaseMenuItem[];
  variant?: Variant;
  nested?: boolean;
  keepSearchParams?: boolean;
  itemProps?: React.ComponentProps<typeof Menu>['itemProps'];
  exact?: boolean;
  isMainNavbar?: boolean;
}

/**
 * Filters and annotates menu items with computed properties
 */
function filterAndMapItems(
  items: BaseMenuItem[],
  context: MenuItemContext,
  url: string,
  search: Record<string, string>,
  nested = false,
): ExtendedBaseMenuItem[] {
  return items
    .filter(item => evaluate(item.isVisible)(context) !== false)
    .map(item => {
      const disabled = evaluate(item.isEnabled)(context) === false;
      const itemUrl = getUrl([url, item.path], {}, search);
      const isChildrenHidden = evaluate(item.isChildrenVisible)(context) === false;

      // Add disabled options to children menus if the parent menu is disabled
      const childrenItems = (
        disabled && item.children?.length
          ? item.children.map(child => ({
              ...child,
              isEnabled: false,
              disabledMessage: item.disabledMessage,
            }))
          : item.children
      ) as BaseMenuItem<string, any>[];

      const children =
        nested && item.children?.length && !isChildrenHidden
          ? filterAndMapItems(childrenItems, context, itemUrl, search)
          : undefined;

      return {
        ...item,
        disabled,
        title: evaluate(item.title)(context),
        breadcrumb: evaluate(item.breadcrumb)(context),
        url: itemUrl,
        children,
      };
    });
}

export default function AppMenu(props: MenuProps): JSX.Element {
  const {
    items,
    nested,
    exact = false,
    keepSearchParams = false,
    itemProps = {},
    isMainNavbar,
  } = props;
  const { variant } = itemProps as any;
  const { url } = useRouteMatch();
  const history = useHistory();
  const searchParams = keepSearchParams
    ? Object.fromEntries(new URLSearchParams(history.location.search))
    : {};
  // const [collapsedItemPaths, setCollapsedItemPaths] = useLocalStorage<string[]>(`menu-${url}`, []);

  const { datasetInfo } = useDatasetContext();
  const { project } = useProjectInfo();
  const authInfo = useAuthInfo();
  const { flags } = useFeatureFlags();

  const { t } = useTranslation();

  const context = useMemo(
    () => ({
      authInfo,
      project,
      featureFlags: flags,
      t,
      datasetInfo,
    }),
    [authInfo, project, flags, t, datasetInfo],
  );

  const visibleItems = useMemo(
    () => filterAndMapItems(items, context, url, searchParams, nested),
    [items, context],
  );

  /*const collapsedItems = visibleItems.filter(menuItem => collapsedItemPaths.indexOf(menuItem.path) !== -1);

  function onChangeItemState(menuItem: BaseMenuItem, state: State) {
    if (state === 'collapsed') {
      setCollapsedItemPaths([...collapsedItemPaths, menuItem.path]);
    } else {
      setCollapsedItemPaths(collapsedItemPaths.filter(path => path !== menuItem.path));
    }
  }
  */

  /**
   * Wrap MenuItem with Link, Tooltip, or Toggle button depending on type
   */
  const wrapItem = (element: JSX.Element, item: ExtendedBaseMenuItem) => {
    // Disabled menu item
    const disabledMessage =
      evaluate(item.disabledMessage)(context) ?? t('auth.errors.noPermissionToViewPage');

    if (item.disabled) {
      return (
        <Tooltip content={disabledMessage} placement="right" strategy="fixed">
          <span>{element}</span>
        </Tooltip>
      );
    }

    // Nested menu item
    /*
    if (nested && item.children?.length) {
      const collapsed = collapsedItems?.indexOf(item) !== -1;
      const toggledState = collapsed ? 'expanded' : 'collapsed';
      return (
        <span role="button" onClick={() => onChangeItemState?.(item, toggledState)}>
          {element}
        </span>
      );
    }
    */

    // Icon with tooltip
    if (variant == 'icon') {
      return (
        <Tooltip content={item.title} placement="right">
          <NavLink to={item.url} role="link">
            {element}
          </NavLink>
        </Tooltip>
      );
    }
    // Default: link menu item
    const hasLowerLevelPage = evaluate(item.hasLowerLevelPage)(context);
    const isChildrenHidden = evaluate(item.isChildrenVisible)(context) === false;
    const hasOptionalDynamicUrl = /:(.+)?$/.test(item.path);

    return (
      <NavLink
        to={item.url}
        exact={exact && !hasLowerLevelPage && !isChildrenHidden && !hasOptionalDynamicUrl}
        role="link"
      >
        {element}
      </NavLink>
    );
  };

  if (isMainNavbar) {
    return <AppsMenu visibleItems={visibleItems} wrapItem={wrapItem} />;
  }

  return (
    <nav>
      <Menu
        itemProps={{ size: 'xs', ...itemProps } as any}
        nested={nested}
        items={visibleItems as any}
        //collapsedItems={collapsedItems}
        wrapItem={wrapItem as any}
      />
    </nav>
  );
}
