import React, { RefObject, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { DropdownSelect, Tab, Tabs } from '@superb-ai/norwegian-forest';
import { orderBy as loOrderBy } from 'lodash';

import UserStatsStatus from '../../../../../consts/UserStatsStatus';
import { useProjectInfo } from '../../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import { useUserReportContext } from '../../../../../contexts/UserReportContext';
import { UserProfileObject } from '../../../../../utils/UserUtils';
import CircularProgressBox from '../../../../elements/CircularProgressBox';
import { EMPTY_MESSAGE } from '../../config/constants';
import { routeToFilteredLabelList } from '../../config/routeConfig';
import EmptyPlot from '../../elements/EmptyPlot';
import { reviewerDefaultConfig, reviewerRoleOptions, rowsPerPageOptions } from '../config';
import { mockTableData } from '../mock';
import { BottomHeader, JsonObj, ReviewerTableTab } from '../types';
import styles from '../UserReportTable.module.scss';
import UserReportRenderRow from '../UserReportTableRow';
import UserStatsTableHeader from '../UserStatsTableHeader';
import { getReviewerTableTopHeader } from './helper';

interface LabelerTableProps {
  bottomHeader: BottomHeader[];
  rows: JsonObj[];
  syncState: string;
  emptyMessage: string;
  tableRef: RefObject<HTMLTableElement>; // used for excel download
  handleChangeRoles: (newValue: string[]) => void;
  handleChangeTab: (newTab: ReviewerTableTab) => void;
  currentTab: string;
  userProfiles: UserProfileObject;
}

const ReviewerTable: React.FC<LabelerTableProps> = props => {
  const {
    tableRef,
    bottomHeader,
    rows,
    syncState,
    emptyMessage,
    handleChangeRoles,
    handleChangeTab,
    currentTab,
    userProfiles,
  } = props;
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { filter } = useUserReportContext();
  const projectInfo = useProjectInfo();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDesc, setIsDesc] = useState(true);
  const [orderBy, setOrderBy] = useState(reviewerDefaultConfig.sortBy);
  const [selectedRoles, setSelectedRoles] = useState<string[]>(reviewerDefaultConfig.roles);

  const topHeader = useMemo(() => {
    return getReviewerTableTopHeader(bottomHeader);
  }, [bottomHeader]);

  // side effect (reroutes)
  const handleRowClick = (email: string) => {
    routeToFilteredLabelList(filter, email, routeInfo, projectInfo?.tagIds);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property: string, order: 'desc' | 'asc') => {
    setOrderBy(property);
    setIsDesc(order === 'desc');
  };

  const makeRows = (rows: JsonObj[]) => {
    return rows.map(row => (
      <UserReportRenderRow
        key={row.email}
        row={row}
        bottomHeader={bottomHeader}
        handleRowClick={handleRowClick}
        userProfiles={userProfiles}
        progressBarColor={'#5A7BFF'}
      />
    ));
  };

  const numCols = bottomHeader.length;
  const getPaginatedRows = (rows: JsonObj[]) =>
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortRows = (rows: JsonObj[], isDesc: boolean) => {
    return loOrderBy(rows, orderBy, isDesc ? 'desc' : 'asc');
  };

  const loadingProgress = () => {
    return (
      <tr>
        <td key="user-stats-empty-row" className={styles.emptyRow} colSpan={numCols}>
          <div style={{ position: 'absolute', width: '100%', bottom: 0, left: 0 }}>
            <CircularProgressBox boxProps={{ mt: 5, mb: 5, width: '100%' }} />
          </div>
        </td>
      </tr>
    );
  };
  const isLoading =
    syncState === UserStatsStatus.INIT_REQUESTED || syncState === UserStatsStatus.SYNC_REQUESTED;

  const showEmpty =
    (!isLoading && rows.length === 0) || emptyMessage === EMPTY_MESSAGE.UNSUPPORTED_WORKAPP;

  const handleRoleChange = (newValue: string[]) => {
    handleChangeRoles(newValue);
    setSelectedRoles(newValue);
  };

  return (
    <>
      <div className={styles.tableWrap}>
        <Box display="flex" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box width="240px" display="flex" mb={1} mt={1}>
            <DropdownSelect
              value={selectedRoles}
              options={reviewerRoleOptions(t)}
              onChange={handleRoleChange}
              color="lightgrey"
            />
          </Box>
          <Box alignItems={'flex-end'}>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions(rows.length)}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('labels.rowsPerPage')}
            />
          </Box>
        </Box>
        <Box>
          <Tabs
            variant="underlined"
            fullWidth={false}
            onChange={value => handleChangeTab(value as ReviewerTableTab)}
            currentTab={currentTab}
          >
            <Tab
              label={t('analytics.userReports.tasks')}
              value="tasks"
              align="center"
              minWidth={130}
              buttonProps={{ size: 'm' }}
            />
            <Tab
              label={t('analytics.userReports.reviewResult')}
              value="reviewResult"
              align="center"
              minWidth={130}
              buttonProps={{ size: 'm' }}
            />
          </Tabs>
        </Box>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table style={{ tableLayout: 'auto' }} ref={tableRef}>
            <>
              <UserStatsTableHeader
                topHeader={topHeader}
                bottomHeader={bottomHeader}
                isDesc={isDesc}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {showEmpty && <EmptyPlot message={emptyMessage} svgHeight={300} marginTop={50} />}
              <TableBody>
                {isLoading
                  ? loadingProgress()
                  : makeRows(showEmpty ? mockTableData : getPaginatedRows(sortRows(rows, isDesc)))}
              </TableBody>
            </>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ReviewerTable;
