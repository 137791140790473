import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@superb-ai/ui';

import { useUserApiKeyDeleteMutation } from '../../../../../queries/userAccessKey';
import ReconfirmDialog from '../../../../elements/ReconfirmDialog';
import { getIsExpired } from './util';

interface Props {
  data?: { name: string; apiKeyId: string; expirationDate: string | null };
  closeConfirmDialog(): void;
  context: 'Manage Members' | 'User Keys';
}

export default function PersonalAccessKeyConfirmDialog({
  data,
  context,
  closeConfirmDialog,
}: Props) {
  const { t } = useTranslation();
  const { mutate: deleteApiKey } = useUserApiKeyDeleteMutation();

  const isExpired = getIsExpired(data?.expirationDate);

  return (
    <ReconfirmDialog
      title={t('settings.advanced.deleteAccessKey')}
      variant="warning"
      cancelButtonMessage={t('button.cancel')}
      confirmButtonMessage={t('button.delete')}
      isDialogOpen={data !== undefined}
      onClickCancel={closeConfirmDialog}
      onClickConfirm={() => {
        if (data?.apiKeyId) {
          deleteApiKey(
            { apiKeyId: data.apiKeyId },
            {
              onSuccess() {},
            },
          );
        }
        closeConfirmDialog();
      }}
    >
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={'settings.advanced.deletePersonalAccessKeyWarning'}
          values={{ accessKeyName: data?.name }}
          components={{ bold: <Typography variant="m-strong" /> }}
        />
      </Typography>
    </ReconfirmDialog>
  );
}
