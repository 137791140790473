import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { ArrowDown, ArrowUp, ArrowVertical, Download, ImageSequence } from '@superb-ai/icons';
import {
  Box,
  Button,
  extendComponent,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { format } from 'date-fns';

import analyticsTracker from '../../../../analyticsTracker';
import FileUtils from '../../../../utils/FileUtils';
import { formatCount } from '../../../../utils/numberFormat';
import {
  RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER,
  RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER,
} from '../../constant';
import { RecognitionAITrainingResult } from '../../services/types';

type SortType = 'none' | 'asc' | 'desc';

type SortOptions = {
  [Key in keyof Omit<
    RecognitionAITrainingResult['classPerformance'][number],
    'annoType' | 'scoreThres'
  >]: SortType;
};

export const DetectionDetailContent = ({
  trainingResult,
  headerColor = 'gray-150',
  modelName,
  samplePredictionDialogState,
  hasSamplePrediction,
}: {
  trainingResult: RecognitionAITrainingResult;
  headerColor?: React.ComponentProps<typeof Box>['backgroundColor'];
  modelName: string;
  samplePredictionDialogState: ReturnType<typeof useDialogState>;
  hasSamplePrediction: boolean;
}) => {
  const { t } = useTranslation();
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    className: 'asc',
    ap: 'none',
    precision: 'none',
    recall: 'none',
    actualTrainCount: 'none',
    actualValCount: 'none',
  });

  const handleClickSort = (sortType: keyof SortOptions) => {
    setSortOptions(prevSortOptions => {
      const updatedOptions: SortOptions = {
        ...prevSortOptions,
        [sortType]:
          prevSortOptions[sortType] === 'none'
            ? 'asc'
            : prevSortOptions[sortType] === 'asc'
            ? 'desc'
            : 'asc',
      };

      for (const key in updatedOptions) {
        if (key !== sortType) {
          updatedOptions[key as keyof SortOptions] = 'none';
        }
      }

      return updatedOptions;
    });
  };

  const getArrowIcon = (sortType: keyof SortOptions) => {
    if (sortOptions[sortType] === 'none') {
      return ArrowVertical;
    } else if (sortOptions[sortType] === 'asc') {
      return ArrowUp;
    } else {
      return ArrowDown;
    }
  };

  const findNotNoneProperties = (options: SortOptions): (keyof SortOptions)[] => {
    const nonNoneProperties: (keyof SortOptions)[] = [];

    for (const key in options) {
      if (options[key as keyof SortOptions] !== 'none') {
        nonNoneProperties.push(key as keyof SortOptions);
      }
    }
    return nonNoneProperties;
  };

  const sortClassPerformance = () => {
    const sortStandard = findNotNoneProperties(sortOptions);

    if (sortStandard.length > 0)
      return (
        a: (typeof trainingResult)['classPerformance'][number],
        b: (typeof trainingResult)['classPerformance'][number],
      ) => {
        if (typeof a[sortStandard[0]] === 'string' && typeof b[sortStandard[0]] === 'string') {
          const x = a[sortStandard[0]] as string;
          const y = b[sortStandard[0]] as string;
          if (sortOptions[sortStandard[0]] === 'asc') {
            return x.localeCompare(y);
          } else if (sortOptions[sortStandard[0]] === 'desc') {
            return y.localeCompare(x);
          } else {
            return 0;
          }
        } else if (
          typeof a[sortStandard[0]] === 'number' &&
          typeof b[sortStandard[0]] === 'number'
        ) {
          const x = a[sortStandard[0]] as number;
          const y = b[sortStandard[0]] as number;
          if (sortOptions[sortStandard[0]] === 'asc') {
            return y - x;
          } else if (sortOptions[sortStandard[0]] === 'desc') {
            return x - y;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      };
    else
      return (
        a: (typeof trainingResult)['classPerformance'][number],
        b: (typeof trainingResult)['classPerformance'][number],
      ) => 0;
  };

  // TODO: remove after upgrage node version
  // @ts-ignore
  const sortedClassPerformance = trainingResult.classPerformance.toSorted(sortClassPerformance());

  const handleClickDownloadCSV = () => {
    const outputData = sortedClassPerformance.map((x: (typeof sortedClassPerformance)[number]) => {
      return {
        class_name: x.className,
        ap: (x.ap * 100).toFixed(1),
        precision: x.precision.toFixed(2),
        recall: x.recall.toFixed(2),
        train_set: x.actualTrainCount,
        validation_set: x.actualValCount,
      };
    });

    FileUtils.exportToCsv(outputData, null, `${modelName}_${format(new Date(), 'yyyyMMdd_kkmm')}`);
  };

  return (
    <>
      <TableHeader
        trainSetCount={RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER}
        validationSetCount={RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER}
        headerColor={headerColor}
        handleClickDownloadCSV={handleClickDownloadCSV}
        samplePredictionDialogState={samplePredictionDialogState}
        hasSamplePrediction={hasSamplePrediction}
      />
      <Row
        display="grid"
        style={{
          height: 32,
          gridTemplateColumns: '16px calc(45% - 16px) 30% calc(25% - 16px) 16px',
        }}
      >
        <Box />
        <Box />
        <Row backgroundColor={'secondary-100'} height="100%" pl={1}>
          <Typography variant="s-strong" color="secondary-500">
            {t('model.myModels.modelPerformance')}
          </Typography>
        </Row>
        <Row backgroundColor={'primary-100'} height="100%" pl={1}>
          <Typography variant="s-strong" color="primary-500">
            {t('model.myModels.dataStatistics')}
          </Typography>
        </Row>
        <Box backgroundColor={'primary-100'} height="100%" />
      </Row>
      <Box
        style={{
          gridTemplateColumns:
            '16px calc(45% - 16px) 10% 10% 10% calc(12.5% - 8px) calc(12.5% - 8px) 16px',
          display: 'grid',
        }}
      >
        <Box height="100%" />
        <Box style={{ display: 'flex', alignItems: 'center', height: 32 }}>
          <Typography variant="m-medium">{t('model.myModelDetail.classname')}</Typography>
          <IconButton
            icon={getArrowIcon('className')}
            variant="text"
            size="s"
            color={sortOptions['className'] === 'none' ? 'cloud' : undefined}
            onClick={() => {
              handleClickSort('className');
            }}
          />
        </Box>
        <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
          <Typography variant="m-medium">{t('model.myModels.AP')}</Typography>
          <IconButton
            icon={getArrowIcon('ap')}
            variant="text"
            size="s"
            color={sortOptions['ap'] === 'none' ? 'cloud' : undefined}
            onClick={() => handleClickSort('ap')}
          />
        </Row>
        <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
          <Typography variant="m-medium">{t('model.myModels.precision')}</Typography>
          <IconButton
            icon={getArrowIcon('precision')}
            variant="text"
            size="s"
            color={sortOptions['precision'] === 'none' ? 'cloud' : undefined}
            onClick={() => handleClickSort('precision')}
          />
        </Row>
        <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
          <Typography variant="m-medium">{t('model.myModels.recall')}</Typography>
          <IconButton
            icon={getArrowIcon('recall')}
            variant="text"
            size="s"
            color={sortOptions['recall'] === 'none' ? 'cloud' : undefined}
            onClick={() => handleClickSort('recall')}
          />
        </Row>
        <Row justifyContent="flex-end" style={{ height: 32 }} backgroundColor={'primary-100'}>
          <Typography variant="m-medium">{t('model.train.trainSet')}</Typography>
          <IconButton
            icon={getArrowIcon('actualTrainCount')}
            variant="text"
            size="s"
            color={sortOptions['actualTrainCount'] === 'none' ? 'cloud' : undefined}
            onClick={() => handleClickSort('actualTrainCount')}
          />
        </Row>
        <Row justifyContent="flex-end" style={{ height: 32 }} backgroundColor={'primary-100'}>
          <Typography variant="m-medium">{t('model.train.validationSet')}</Typography>
          <IconButton
            icon={getArrowIcon('actualValCount')}
            variant="text"
            size="s"
            color={sortOptions['actualValCount'] === 'none' ? 'cloud' : undefined}
            onClick={() => handleClickSort('actualValCount')}
          />
        </Row>
        <Box height="100%" backgroundColor={'primary-100'} />
      </Box>
      <Box backgroundColor={'gray-400'} style={{ height: '1px' }} />
      <Box
        display="grid"
        pb={3}
        style={{
          gridTemplateColumns:
            '16px calc(45% - 16px) 10% 10% 10% calc(12.5% - 8px) calc(12.5% - 8px) 16px',
        }}
      >
        {/* TODO: remove after upgrage node version */}
        {/* @ts-ignore */}
        {sortedClassPerformance.map(data => {
          return (
            <Fragment key={data.className}>
              <Box />
              <Row key={data.className} style={{ height: 32 }}>
                <Typography variant="m-regular">{data.className}</Typography>
              </Row>
              <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
                <Typography variant="m-regular">{(data.ap * 100).toFixed(1) ?? 'N/A'}</Typography>
              </Row>
              <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
                <Typography variant="m-regular">{data.precision.toFixed(2) ?? 'N/A'}</Typography>
              </Row>
              <Row justifyContent="center" style={{ height: 32 }} backgroundColor={'secondary-100'}>
                <Typography variant="m-regular">{data.recall.toFixed(2) ?? 'N/A'}</Typography>
              </Row>
              <Row justifyContent="flex-end" style={{ height: 32 }} backgroundColor={'primary-100'}>
                <Typography
                  variant="m-regular"
                  color={
                    RECOMMENDED_RECOGNITION_AI_TRAIN_SET_QUANTITY_NUMBER > data.actualTrainCount
                      ? 'red-400'
                      : undefined
                  }
                >
                  {formatCount(data.actualTrainCount)}
                </Typography>
              </Row>
              <Row justifyContent="flex-end" style={{ height: 32 }} backgroundColor={'primary-100'}>
                <Typography
                  variant="m-regular"
                  color={
                    RECOMMENDED_RECOGNITION_AI_VALIDATION_SET_QUANTITY_NUMBER > data.actualValCount
                      ? 'red-400'
                      : undefined
                  }
                >
                  {formatCount(data.actualValCount)}
                </Typography>
              </Row>
              <Box height="100%" backgroundColor={'primary-100'} />
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};

const TableHeader = ({
  trainSetCount,
  validationSetCount,
  headerColor = 'gray-150',
  handleClickDownloadCSV,
  samplePredictionDialogState,
  hasSamplePrediction,
}: {
  trainSetCount: number;
  validationSetCount: number;
  headerColor?: React.ComponentProps<typeof Box>['backgroundColor'];
  handleClickDownloadCSV: () => void;
  samplePredictionDialogState: ReturnType<typeof useDialogState>;
  hasSamplePrediction: boolean;
}) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<{ accountName: string }>();

  return (
    <Row pl={2} pr={0.5} backgroundColor={headerColor} style={{ height: 40 }}>
      <Box mr="auto">
        <Typography variant="m-regular" ml={'auto'}>
          {t('model.myModels.recommendedCounts')} :{' '}
          <Trans
            t={t}
            i18nKey={'model.myModels.tableHeader'}
            values={{
              trainSetCount: formatCount(trainSetCount),
              validationSetCount: formatCount(validationSetCount),
            }}
          />
        </Typography>
      </Box>
      {hasSamplePrediction && (
        <>
          <Button
            variant="text"
            onClick={() => {
              analyticsTracker.modelDetailClicked({
                accountId: params.accountName,
                clickedButton: 'click-progress-sample-prediction-detail',
                modelStatus: 'trained',
              });
              samplePredictionDialogState?.show();
            }}
          >
            <Icon icon={ImageSequence} />
            {t('model.myModelDetail.viewSamplePrediction')}
          </Button>
          <Box backgroundColor={'gray-300'} ml={1.5} mr={0.5} style={{ width: 1, height: 8 }} />
        </>
      )}

      <Tooltip content={t('model.myModels.downloadCSV')} placement="bottom-end">
        <IconButton icon={Download} variant="text" size={'m'} onClick={handleClickDownloadCSV} />
      </Tooltip>
    </Row>
  );
};

const Row = extendComponent(Box, {
  display: 'flex',
  alignItems: 'center',
});
