export const MODE = ['SELECT', 'CREATE', 'EDIT'] as const;

export type Mode = (typeof MODE)[number];

export const keypointTemplate = {
  id: '',
  name: '',
  allowValidInvisibles: true,
  points: [
    {
      color: '#ff625a',
      defaultValue: {
        state: {
          visible: true,
        },
        x: 0.25,
        y: 0.5,
      },
      name: 'left',
      symmetricIdx: 1,
    },
    {
      color: '#ff625a',
      defaultValue: {
        state: {
          visible: true,
        },
        x: 0.75,
        y: 0.5,
      },
      name: 'right',
      symmetricIdx: 0,
    },
  ],
  edges: [
    {
      u: 0,
      v: 1,
      color: '#5A7BFF',
    },
  ],
};
