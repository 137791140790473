import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Typography } from '@superb-ai/norwegian-forest';
import Image from 'next/image';

import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { getUrl } from '../../../../routes/util';
import Howto from '../cards/Howto';
import Step from '../cards/Step';
import TutorialCard from '../cards/TutorialCard';
import tutorialImage from './tutorial.png';

export default function Tutorial({
  hasExports,
  openSettingsModal,
}: {
  hasExports: boolean;
  openSettingsModal: () => void;
}) {
  const { accountName } = useParams<{ accountName: string }>();
  const projectInfo = useProjectInfo();
  const history = useHistory();
  const { t } = useTranslation();

  function goTo(path: string) {
    const url = getUrl([accountName, 'label', 'project', projectInfo.project.id, path]);
    history.push(url);
  }
  return (
    <TutorialCard
      title={
        <Typography variant="body3">
          {t('advancedAIFeatures.mislabelDetection.guide.description')}
        </Typography>
      }
      image={
        <Image
          style={{ maxWidth: '100%', height: 'auto' }}
          src={tutorialImage}
          alt="Illustration giving thumbs down to a label annotated as a cat while is a dog"
        />
      }
    >
      <Howto title={t('advancedAIFeatures.mislabelDetection.guide.title')}>
        <Step
          number={1}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'advancedAIFeatures.mislabelDetection.guide.items.0'} />
            </Typography>
          }
          completed={hasExports}
          onClick={() => goTo('labels')}
        />
        <Step
          number={2}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'advancedAIFeatures.mislabelDetection.guide.items.1'} />
            </Typography>
          }
          onClick={openSettingsModal}
        />
        <Step
          number={3}
          text={
            <Typography variant="body3">
              <Trans t={t} i18nKey={'advancedAIFeatures.mislabelDetection.guide.items.2'} />
            </Typography>
          }
          onClick={() => {}}
        />
      </Howto>
    </TutorialCard>
  );
}
