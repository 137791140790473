import React from 'react';

import { Typography } from '@superb-ai/norwegian-forest';

const ProjectConfigurationBodyDataType: React.FC<{ workapp: string }> = ({ workapp }) => {
  const getDataType = () => {
    if (workapp === 'image-default' || workapp === 'image-siesta') {
      return 'Image';
    }
    if (workapp === 'video-default' || workapp === 'video-siesta') {
      return 'Video';
    }
    if (workapp === 'pointclouds-siesta') {
      return 'Point Cloud Sequence';
    }

    return '-';
  };

  return <Typography variant="body2">{getDataType()}</Typography>;
};

export default ProjectConfigurationBodyDataType;
