import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/norwegian-forest';
import { cloneDeep } from 'lodash';

import { overflowOverlayOrAuto } from '../../../../../utils/style';
import { Point } from './type';
type Props = {
  keypointId: string;
  points: Point[];
  editMode?: boolean;
  setEditPoints?: (points: Point[]) => void;
  setEditErrorMessage?: (error: string) => void;
};

const getKey = (keypointId: string, point: Point, index: number, editMode: boolean) => {
  if (!editMode) {
    return `${keypointId}.${point.color}.${point.name}.${index}.${editMode}`;
  }

  return `${keypointId}.${index}.${editMode}`;
};

const PointsEditor: React.FC<Props> = ({
  keypointId,
  points,
  editMode = false,
  setEditPoints,
  setEditErrorMessage,
}) => {
  const { t } = useTranslation();
  const handleChange =
    (type: 'color' | 'name', index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!setEditPoints || !setEditErrorMessage) return;

      const { value } = e.target;
      if ((type === 'color' || type === 'name') && value === '') {
        setEditErrorMessage(
          t('projects.createProject.keypoint.pointShouldNotBlank', { index, type }),
        );
        return;
      }

      const nextPoints = cloneDeep(points);
      nextPoints[index][type] = value;
      setEditPoints(nextPoints);
      setEditErrorMessage('');
    };

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" overflow="hidden" p={1}>
      <Box display="flex" flexDirection="column">
        <Typography variant="headline6"> {t('projects.createProject.keypoint.points')}</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
          <Box width="16px">
            <Typography variant="body3">{t('projects.createProject.keypoint.no')}</Typography>
          </Box>
          <Box width="120px" display="flex" justifyContent="center">
            <Typography variant="body3">{t('projects.createProject.keypoint.color')}</Typography>
          </Box>
          <Box width="120px" display="flex" justifyContent="center">
            <Typography variant="body3">{t('projects.createProject.keypoint.name')}</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        overflow={overflowOverlayOrAuto()}
        p={1}
        gap="4px"
      >
        {points.map((point, index) => (
          <Box
            key={getKey(keypointId, point, index, editMode)}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="4px"
          >
            <Box width="16px">
              <Typography variant="body4">{index}</Typography>
            </Box>
            <Input
              size="xs"
              defaultValue={point.color}
              disabled={!editMode}
              onKeyDown={e => e.stopPropagation()}
              onChange={handleChange('color', index)}
            />
            <Input
              size="xs"
              defaultValue={point.name}
              disabled={!editMode}
              onKeyDown={e => e.stopPropagation()}
              onChange={handleChange('name', index)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PointsEditor;
