import React, { createContext, useContext, useState } from 'react';

import { StateGetterSetter } from '../../../contexts/types';

export type FilterContextProps = StateGetterSetter<
  ['isApplyingFilter', 'setIsApplyingFilter'],
  boolean
> &
  StateGetterSetter<['isFilterOpen', 'setIsFilterOpen'], boolean>;

const Context = createContext<FilterContextProps>({} as FilterContextProps);

const useProvider = () => {
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  return {
    isApplyingFilter,
    setIsApplyingFilter,
    isFilterOpen,
    setIsFilterOpen,
  };
};

export const useFilterContext = (): FilterContextProps => {
  return useContext(Context);
};

export const FilterProvider: React.FC = ({ children }) => {
  const filterInfo = useProvider();
  return <Context.Provider value={filterInfo}>{children}</Context.Provider>;
};
