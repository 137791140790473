import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Box, Button, ButtonGroup, Card, Icon, Popper } from '@superb-ai/norwegian-forest';
import qs from 'qs';

import AnalyticsTracker from '../../../../analyticsTracker';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import LabelsService from '../../../../services/LabelsService';
import ServiceUtils from '../../../../utils/ServiceUtils';
import ReportFilters from './ReportFilters';
import { JsonObj } from './types';

interface Props {
  filter: string;
  onGenerate: (filter: string, labelCount: number) => void;
  isDisabled: boolean;
  totalLabelCount: number;
}

/**
 * filter - ex. "status_in[]=SUBMITTED&status_in[]=WORKING"
 * tagIds - ex. {'unique-tag-id': 'unique-tag-name'}
 *
 * Returns: {statusIn: ["SUBMITTED", "WORKING"],
 */
const convertFilterURLToApiParamObj = (filter: string): JsonObj => {
  return ServiceUtils.toCamelCaseKeys(qs.parse(filter));
};

export default function UserStatsHeaderButtons(props: Props): JSX.Element {
  const { onGenerate, isDisabled, filter, totalLabelCount } = props;
  const [labelCount, setLabelCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const [isLoadingCount, setIsLoadingCount] = useState(false);

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { t } = useTranslation();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;
  const { tab } = routeInfo.params;

  useEffect(() => {
    if (!currentFilter) {
      setLabelCount(totalLabelCount);
      return;
    }
    setIsLoadingCount(true);
    const filterApiParams = convertFilterURLToApiParamObj(currentFilter);
    (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
      params: filterApiParams,
      projectId: routeInfo.urlMatchInfo.projectId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    }).then(count => {
      setLabelCount(count);
      setIsLoadingCount(false);
    });
  }, [totalLabelCount, currentFilter, authInfo.isGuest, routeInfo.urlMatchInfo]);

  useEffect(() => {
    setCurrentFilter(filter);
  }, [filter]);

  const handleGenerate = (withFilter = false) => {
    onGenerate(withFilter ? currentFilter : '', labelCount);
    setIsFilterOpen(false);
    AnalyticsTracker.userReportRequested({
      targetRole: tab,
      labelCount: labelCount,
      filterApplied: withFilter,
      accountId: authInfo.accountName ?? '',
    });
  };

  return (
    <Popper
      open={isFilterOpen}
      anchorEl={
        <Box ml="auto">
          <ButtonGroup gap={1}>
            <Button
              variant="stroke"
              color="lightgrey"
              IconAdornment={<Icon name="customize" />}
              iconPosition="left"
              disabled={isDisabled}
              onClick={() => {
                setIsFilterOpen(true);
              }}
            >
              {t('analytics.userReports.button.customize')}
            </Button>
            <Box>
              <Button
                variant="strong-fill"
                color="primary"
                disabled={isDisabled}
                onClick={() => handleGenerate()}
              >
                {t('analytics.userReports.button.generateFullReport')}
              </Button>
            </Box>
          </ButtonGroup>
        </Box>
      }
      offset={0.5}
      placement="bottom-end"
      useOutsidePanel
      onClickOutside={() => setIsFilterOpen(false)}
    >
      <Card>
        <Box display="flex" flexDirection="column" style={{ maxWidth: '820px', minWidth: '660px' }}>
          <Box p={2}>
            <ReportFilters filter={currentFilter} setFilter={setCurrentFilter} />
          </Box>
          <Box p={2} bt={1} themedBorderColor={['grey', 100]} display="flex" alignItems="center">
            <Box display="flex" alignItems="baseline">
              <MUI.Typography variant="caption" color="initial">
                Selected
              </MUI.Typography>
              <MUI.Typography variant="h5" color="primary" style={{ padding: '0 5px' }}>
                {isLoadingCount ? '…' : labelCount}
              </MUI.Typography>
              <MUI.Typography variant="caption" color="initial">
                labels
              </MUI.Typography>
            </Box>
            <Box ml="auto">
              <Button
                variant="strong-fill"
                color="primary"
                disabled={labelCount === 0}
                onClick={() => handleGenerate(true)}
              >
                {t('analytics.userReports.button.generateCustomizedReport')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </Popper>
  );
}
