import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, RadioGroup } from '@superb-ai/ui';

import { useDiagnosisAnalyticsFilterContext } from '../../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { useDiagnosisCommonFilterContext } from '../../../../../../contexts/DiagnosisCommonFilterContext';
import { useDiagnosisModelContext } from '../../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../../contexts/PublicDatasetContextProvider';
import { useDiagnosisSchema } from '../../../../../../queries/diagnosisModelQueries';
import { Split } from '../../../../../../types/evaluationTypes';
import { LoadingIndicatorDiv } from '../../../analytics/components/LoadingIndicatorDiv';
import { isSuperbModel } from '../diagnosisUnion';
import SliceDropdown from '../SliceDropdown';
import { DiagnosisStatusEnum } from '../types';
import { ViewsPanel } from '../ViewsPanel';
import ChartsLayout from './ChartsLayout';
import SinglePredictionClassFilter from './SinglePredictionClassFilter';

export default function DiagnosisAnalyticsLayout(): JSX.Element {
  const { t } = useTranslation();
  const { selectedDiagnosis, isLoadingDiagnosisList } = useDiagnosisModelContext();
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const boxRef = useRef<HTMLDivElement>(null);

  const { selectedClass, setSelectedClass } = useDiagnosisAnalyticsFilterContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { splitIn, setSplitIn, sliceId, setSliceId } = useDiagnosisCommonFilterContext();

  const isExternalModel = !(selectedDiagnosis && isSuperbModel(selectedDiagnosis));

  const LayoutView = () => {
    if (isLoadingDiagnosisList) return <LoadingIndicatorDiv />;
    if (selectedDiagnosis) return <ChartsLayout selectedDiagnosis={selectedDiagnosis} />;
  };

  const metadataQuery = useDiagnosisSchema({
    diagnosisId,
    fromPublicDatasets: showPublicDatasets,
    datasetId,
  });

  const isTrainSelected = !splitIn.includes('VAL') && splitIn.includes('TRAIN');
  const isValSelected = splitIn.includes('VAL') && !splitIn.includes('TRAIN');

  const isTrainDisabled =
    selectedDiagnosis?.status === DiagnosisStatusEnum.ACTIVE
      ? isExternalModel
      : selectedDiagnosis?.status !== DiagnosisStatusEnum.ALL_ACTIVE;

  const options = [
    {
      value: 'VAL',
      label: (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          color={!isTrainSelected ? 'primary' : undefined}
        >
          {t('curate.diagnosis.filterPanel.validation')}
          <SliceDropdown
            target="val"
            splits={metadataQuery.data?.splits}
            disabled={isTrainSelected}
            sliceId={isValSelected ? sliceId : undefined}
            setSliceId={setSliceId}
          />
        </Box>
      ),
    },
    {
      value: 'TRAIN',
      label: (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          color={isTrainDisabled ? 'gray-300' : splitIn.includes('TRAIN') ? 'primary' : undefined}
        >
          {t('curate.diagnosis.filterPanel.train')}
          <SliceDropdown
            target="train"
            splits={metadataQuery.data?.splits}
            disabled={!isTrainSelected}
            sliceId={isTrainSelected ? sliceId : undefined}
            setSliceId={setSliceId}
          />
        </Box>
      ),
      disabled: isTrainDisabled,
      tooltip: isTrainDisabled
        ? t('curate.diagnosis.filterPanel.trainSetNotActivated')
        : isExternalModel
        ? t('curate.diagnosis.filterPanel.trainOptionDisabledForExternalModel')
        : undefined,
    },
  ] as const;

  return (
    <>
      <Box px={4} py={2}>
        <ViewsPanel
          additionalInfoOnEvaluationSummarySection={
            <Box display="flex" ml="auto" gap={1} alignItems="center">
              <SinglePredictionClassFilter
                classList={metadataQuery.data?.classList ?? []}
                selectedClass={selectedClass}
                setSelectedClass={setSelectedClass}
              />
            </Box>
          }
        >
          <Box display="flex" ml="auto" gap={1} alignItems="center">
            <RadioGroup
              display="flex"
              options={options}
              value={splitIn.length === 2 ? 'ALL' : splitIn[0]}
              onChangeValue={value =>
                setSplitIn((value === 'ALL' ? ['VAL', 'TRAIN'] : [value]) as Split[])
              }
            />
          </Box>
        </ViewsPanel>
      </Box>
      <Box display="flex" flexDirection="column" mx={4} ref={boxRef} overflow="auto">
        {LayoutView()}
      </Box>
    </>
  );
}
