import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalStepper } from '@superb-ai/norwegian-forest';

import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import Cloud from './contents/Cloud';
import ProjectAssign from './steps/ProjectAssign';
import SelectDataset from './steps/SelectDataset';

const VideoStream: React.FC = () => {
  const { t } = useTranslation();
  const fileInfo = useUploadInfo();
  const { selectedProject, setIsStepsCompleted } = fileInfo;
  const routeInfo = useRouteInfo();
  const projectId = routeInfo.urlMatchInfo.projectId;
  const DATASET = 'dataset';
  const PROJECT = 'project';
  const [projectAssignButtonText, setProjectAssignButtonText] = useState<string>(t('button.skip'));

  const stepsPerUploadMethod = Cloud(t);

  const selectDatasetStep = {
    [DATASET]: SelectDataset(t),
  };

  const assignToProjectStep = {
    [PROJECT]: ProjectAssign(t),
  };

  const steps = { ...stepsPerUploadMethod, ...selectDatasetStep };
  const videoStreamUploadSteps = projectId ? steps : { ...steps, ...assignToProjectStep };

  const lastStepButton = projectId
    ? {
        text: t('button.done'),
        onClick: () => {
          setIsStepsCompleted(true);
        },
      }
    : {
        text: projectAssignButtonText,
        onClick: () => {
          setIsStepsCompleted(true);
        },
      };

  useEffect(() => {
    if (selectedProject) {
      setProjectAssignButtonText(t('button.done'));
    } else {
      setProjectAssignButtonText(t('button.skip'));
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  return (
    <VerticalStepper.Simple
      cardProps={{ maxHeight: 482, overflow: 'auto' }}
      steps={videoStreamUploadSteps}
      lastStepButton={lastStepButton}
    />
  );
};

export default VideoStream;
