import React from 'react';

import { JsonObj } from '../../userStats/types';

const getStyle = () => {
  return {
    strokeWidth: 2,
    opacity: 0.9,
    borderRadius: '1px',
    boxShadow: '0 1px 1px 0', // rgba(0, 0, 0, 0.15)',
    fill: 'white',
  };
};

interface ScrollProps {
  svgInfo: JsonObj;
}

const ScrollSelector: React.FC<ScrollProps> = props => {
  const { svgInfo } = props;
  const style = getStyle();

  return (
    <>
      <rect
        key="scrollSelector"
        className="scroll-selector"
        fill="rgba(255, 143, 106)"
        opacity={0.1}
        width={svgInfo.scrollSelectorWidth}
        height={svgInfo.scrollHeight + 10}
      />
      <rect
        key="handle1"
        x={2}
        y={23}
        width="3px"
        height={svgInfo.scrollHeight - 35}
        style={style}
        rx={2}
        ry={2}
      />
      <rect
        key="handle2"
        x={5.5}
        y={23}
        width="3px"
        height={svgInfo.scrollHeight - 35}
        style={style}
        rx={2}
        ry={2}
      />
      <rect
        key="handle3"
        x={svgInfo.scrollSelectorWidth - 11}
        y={23}
        width="3px"
        height={svgInfo.scrollHeight - 35}
        style={style}
        rx={2}
        ry={2}
      />
      <rect
        key="handle4"
        x={svgInfo.scrollSelectorWidth - 7}
        y={23}
        width="3px"
        height={svgInfo.scrollHeight - 35}
        style={style}
        rx={2}
        ry={2}
      />
    </>
  );
};

export default ScrollSelector;
