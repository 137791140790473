import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Search } from '@superb-ai/icons';
import { Box, Icon, Input, Typography } from '@superb-ai/ui';

import { useObjectClusterContext } from '../../../../contexts/ObjectClusterContext';
import { useObjectFilterContext } from '../../../../contexts/ObjectFilterContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { useDatasetObjectClusterFilterSchemaQuery } from '../../../../queries/datasetObjectQueries';
import ClusterLevelDropdown from './components/ClusterLevelDropdown';
import ObjectClusterFilterCollapse from './ObjectClusterFilterCollapse';
import { CLUSTER_LEVEL_2_SIZE_8, ClusterLevel } from './types';
import { getTextContains } from './utils';

export default function ObjectClusterFilter() {
  const { t } = useTranslation();

  const { datasetId } = useParams<{ datasetId: string }>();
  const { sliceInfo } = useSliceContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const {
    filterSchema,
    selectedClasses,
    selectedAnnotationClassInClusterTab,
    setSelectedAnnotationClassInClusterTab,
  } = useObjectFilterContext();
  const {
    setClusterColorMap,
    clusterLevel,
    setClusterLevel,
    hoveredFilterGroup,
    setHoveredFilterGroup,
  } = useObjectClusterContext();

  const clusterLevelsPerClass = filterSchema?.annotation_class_cluster_level_in;
  const clusterLevelsPerClassEntries =
    clusterLevelsPerClass && Object.entries(clusterLevelsPerClass);

  useEffect(() => {
    if (selectedClasses?.length) {
      setSelectedAnnotationClassInClusterTab(selectedClasses[0]);
    }
  }, [selectedClasses]);

  const [clusterLevelMap, setClusterLevelMap] = useState<Record<string, ClusterLevel>>(
    (clusterLevelsPerClassEntries || []).reduce(
      (acc, entry) => ({ ...acc, [entry[0]]: CLUSTER_LEVEL_2_SIZE_8 }),
      {},
    ),
  ); // key: className, value: level

  const { data: clusterPerClass, isLoading: isLoadingClusterPerClass } =
    useDatasetObjectClusterFilterSchemaQuery({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      sliceName: sliceInfo?.name,
      annotationClass: selectedAnnotationClassInClusterTab,
      clusterLevel: clusterLevel, //selectedAnnotationClassInClusterTab ? clusterLevelMap[selectedAnnotationClassInClusterTab] : undefined,
    });

  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const filteredClusterPerClassEntries = clusterLevelsPerClassEntries?.filter(([annotationClass]) =>
    getTextContains(annotationClass, searchInput || ''),
  );

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const searchKey = event.target.value;
    setSearchInput(searchKey);
  }

  async function handleToggle(annotationClass: string) {
    if (selectedAnnotationClassInClusterTab === annotationClass) {
      setSelectedAnnotationClassInClusterTab(undefined);
      return;
    }
    setSelectedAnnotationClassInClusterTab(annotationClass);
  }

  useEffect(() => {
    setClusterColorMap(clusterPerClass?.reduce((acc, c) => ({ ...acc, [c.id]: c.color }), {}));
  }, [clusterPerClass, setClusterColorMap]);

  return (
    <Box overflow="auto" display="grid" style={{ gridTemplateRows: 'auto auto  1fr' }} gap={0.5}>
      <Input
        value={searchInput}
        onChange={handleSearch}
        prefix={<Icon icon={Search} />}
        placeholder={t('text.search')}
      />
      {clusterLevel && (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="s-strong" color="gray-300">
            {t('curate.imageFilter.numberOfClusters')}
          </Typography>
          <ClusterLevelDropdown
            maxSize={4}
            selectedSize={clusterLevel}
            clickable
            onChange={setClusterLevel}
          />
        </Box>
      )}
      <Box overflow="auto">
        {filteredClusterPerClassEntries?.map(([annotationClass, clusterLevels]) => (
          <ObjectClusterFilterCollapse
            key={annotationClass}
            maxClusterLevel={clusterLevels.length}
            selectedClusterLevel={clusterLevelMap[annotationClass]}
            onChangeClusterLevel={(v: ClusterLevel) => {
              setClusterLevelMap({ ...clusterLevelMap, [annotationClass]: v });
            }}
            isSelectedAnnotationClass={annotationClass === selectedAnnotationClassInClusterTab}
            handleToggle={handleToggle}
            clusterPerClass={clusterPerClass}
            annotationClass={annotationClass}
            searchInput={searchInput}
            isLoadingClusterPerClass={isLoadingClusterPerClass}
            hoveredFilterGroup={hoveredFilterGroup}
            setHoveredFilterGroup={setHoveredFilterGroup}
          />
        ))}
      </Box>
    </Box>
  );
}
