import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import { getCanDownloadRawData } from '../../../../../components/pages/settings/general/rawDataDownload/utils';
import { isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';
import Layout from './Layout';

export const RawDataDownloadMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectRawDataDownload,
  path: 'raw-data',
  title: ({ t }) => t('export.raw-data.title'),
  component: Layout,
  isVisible({ authInfo, project }) {
    return (
      isOwnerOrAdminOrSystemAdmin(authInfo) && !!project && getCanDownloadRawData(project.workapp)
    );
  },
});
