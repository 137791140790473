import { css } from '@emotion/react';

export default () => {
  return css`
    .file-entry {
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      outline: none;
      user-select: none;

      background-color: #fff;

      &.isSelectable {
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: #fbfbfb;
        }
      }

      &.active {
        background-color: #f3f5ff !important;
      }

      &.disabled > * {
        opacity: 0.38;
      }

      .loading-indicator {
        display: none;
        margin: (-4px) 0 0 8px;
      }

      &.loading .loading-indicator {
        display: flex;
      }

      .directory-toggle,
      .file-icon {
        font-size: 16px;
      }

      .directory-toggle {
        margin-right: 6px;
        margin-top: -3px;

        .icon {
          transform: rotate(-90deg);
          transition: transform 0.15s;
        }

        &.open .icon {
          transform: rotate(0deg);
        }
      }

      .file-label {
        display: flex;
        align-items: center;
        align-self: baseline;
      }
      .file-icon {
        line-height: 10px;
        &:not(:empty) {
          margin-right: 8px;
        }
      }
      &.file .file-icon {
        margin-left: 26px;
      }

      &.info {
        .file-label {
          color: #aaa;
        }
      }

      .file-label {
        font: inherit;
        flex-grow: 1;
        user-select: none;
      }

      .visibility-toggle {
        visibility: hidden;
      }
      &:not(.disabled):hover .visibility-toggle {
        visibility: visible;
      }
      &.hidden {
        opacity: 0.55;
      }

      padding: 10px 10px 10px 6px;

      &.object-entry {
        padding: 6px;
        font-size: 12px;

        .directory {
          .file-label {
            font-weight: 500;
          }
        }
        &.file .file-icon {
          margin-left: 0;
        }
        &.file .toggle-icon {
          margin-left: 20px;
        }
        .directory-toggle + .toggle-icon {
          margin-left: -6px;
        }
      }

      button {
        margin-top: -5px;
        transform: translateY(3px);
      }
    }

    ${[...Array(19).keys()]
      .map(
        i =>
          `.file-entry.depth-${i + 2} {
      padding-left: ${26 * (i + 1) + 6}px;

    }`,
      )
      .join('\n')}
  `;
};
