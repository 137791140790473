import AuthService from './AuthService';
import { ApiCall } from './types';

const updateRequiredMfa: ApiCall<{ requiredRoles: string[] }, unknown> = async args => {
  const { requiredRoles, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/tenants/mfa',
    data: { requiredRoles },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

// Get secret for setup
const getSecret: ApiCall<unknown, { mfaStatus: string; secretCode: string }> = async args => {
  const { isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/users/mfa',
    data: {
      accessToken: localStorage.getItem('spb_access'),
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data.data;
};

// Verify mfa setup
const verify: ApiCall<{ code: string }, unknown> = async args => {
  const { code, isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'post',
    url: '/auth/users/mfa/verify',
    data: {
      accessToken: localStorage.getItem('spb_access'),
      userCode: code,
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data;
};

// Remove mfa setup
const removeUserMfa: ApiCall<unknown, unknown> = async args => {
  const { isGuest, urlInfo } = args;
  const res = await AuthService.apiCallAfterLogin({
    method: 'delete',
    url: '/auth/users/mfa',
    data: {
      accessToken: localStorage.getItem('spb_access'),
    },
    hasPublicApi: false,
    isGuest,
    urlInfo,
  });
  return res.data.data;
};

export default {
  updateRequiredMfa,
  getSecret,
  verify,
  removeUserMfa,
};
