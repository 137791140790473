import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router';

import { Box } from '@superb-ai/norwegian-forest';

import { useRouteInfo } from '../../../contexts/RouteContext';
import { getUrl } from '../../../routes/util';
import SearchInput from '../../elements/SearchInput';
import DataViewSelector, { DATA_VIEW_DATA, DATA_VIEW_DATASET } from './DataViewSelector';
import UploadButton from './UploadButton';

function valueToOption(value: string) {
  return { label: value, value };
}

export default function DataViewFilter(): JSX.Element {
  const routeInfo = useRouteInfo();
  const { accountName, projectId } = useParams<{ accountName: string; projectId: string }>();
  const { params } = routeInfo;
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  const baseUrl = [accountName, 'label', 'data'];
  const dataUrl = getUrl(baseUrl);
  const datasetPath = [accountName, 'label', 'data'];
  const datasetUrl = getUrl(datasetPath, {}, { view: 'dataset' });

  const viewValue =
    params.view === 'dataset'
      ? DATA_VIEW_DATASET
      : params.dataset && params.dataset !== '[ all datasets ]'
      ? params.dataset
      : DATA_VIEW_DATA;

  const datasetFilter = params.dataset ? valueToOption(params.dataset) : undefined;

  function handleViewChange(value: string) {
    if (value === DATA_VIEW_DATA) {
      routeInfo.history.push(dataUrl);
    } else if (value === DATA_VIEW_DATASET) {
      routeInfo.history.push(datasetUrl);
    } else {
      const { view, ...newParams } = params;
      const url = getUrl(dataUrl, {}, { ...newParams, dataset: value });
      routeInfo.history.push(url);
    }
  }

  return (
    <Box mt={-2} ml={-1} mb={1} display="flex" alignItems="center">
      <Box>
        <DataViewSelector
          value={viewValue}
          onChange={handleViewChange}
          defaultOptions={datasetFilter ? [datasetFilter] : []}
        />
      </Box>
      <Box ml="auto" width="280px">
        {viewValue === DATA_VIEW_DATASET ? (
          <SearchInput queryParam="group" placeholder={t('data.filters.searchForDataset')} />
        ) : (
          <SearchInput queryParam="key" placeholder={t('data.filters.searchForDataKey')} />
        )}
      </Box>
      <Box pl={2}>
        <UploadButton />
      </Box>
    </Box>
  );
}
