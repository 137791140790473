import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../menu';
import { CurationMenuItem } from './curationHistory/MenuItem';
import DatasetRoute from './DatasetRoute';
import { DownloadsMenuItem } from './downloads/MenuItem';
import { DatasetExploreMenuItem } from './explore/MenuItem';
import { ModelDiagnosisMenuItem } from './modelDiagnosis/MenuItem';
import { SettingsMenuItem } from './settings/MenuItem';
import { SlicesMenuItem } from './slices/MenuItem';

export const DatasetMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDataset,
  path: ':datasetId',
  component: DatasetRoute,
  children: [
    DatasetExploreMenuItem,
    ModelDiagnosisMenuItem,
    SlicesMenuItem,
    CurationMenuItem,
    DownloadsMenuItem,
    SettingsMenuItem,
  ],
});

export const SampleDatasetMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateSampleDatasets,
  path: ':datasetId',
  component: DatasetRoute,
  children: [DatasetExploreMenuItem, ModelDiagnosisMenuItem, SlicesMenuItem, CurationMenuItem],
});
