import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../analyticsTracker/utils';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../contexts/ImageScopeContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { SelectedSliceOption } from '../../../../contexts/UploadContext';
import { SliceAsyncCombobox } from '../../../../elements/SliceAsyncCombobox';
import {
  useUpdateSliceByQueryJobMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../services/DatasetService';
import { SCATTER_VIEW, ViewMode } from '../../../../types/viewTypes';
import { getView } from '../../../../utils/routeUtils';
import { useImageScatterContext } from '../analytics/contexts/ImageScatterContext';

export default function AddSliceModal({
  addSliceModalIsOpen,
  setAddSliceModalIsOpen,
}: {
  addSliceModalIsOpen: boolean;
  setAddSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const history = useHistory();
  const viewType = getView(history);
  const { t } = useTranslation();
  const { sliceInfo } = useSliceContext();
  const { sampledCount } = useImageScatterContext();
  const { appliedFilters } = useImageFilterContext();
  const { selectedData, setSelectedData, selectedAllData, setSelectedAllData } = useActionContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const commandContext = useCurateCommandContext();
  const { totalCount } = useImageScopeContext();

  const selectedImageIds = selectedData?.map(image => image.id) ?? [];

  const { mutateAsync: startUpdateSliceJobWithIds } = useUpdateSliceJobWithIdsMutation({
    datasetId,
    commandContext,
    selectedImageIds,
  });

  const { mutateAsync: startUpdateSliceByQueryJob } = useUpdateSliceByQueryJobMutation({
    datasetId,
    sliceName: sliceInfo?.name,
    commandContext,
    appliedFilters,
    queryString: queryStringWithHiddenFilterAndDeselection,
  });

  const trackDataSliceUpdated = useCallback(
    (selectedSliceId: string, dataCount: number) => {
      analyticsTracker.dataSliceUpdated({
        datasetId,
        accountId: accountName,
        sliceId: selectedSliceId,
        viewType: viewType,
        dataCount: dataCount,
        dataType: 'image',
        referrer: 'explore-image',
        filters: combineFiltersForTracking(appliedFilters, sliceInfo?.id),
        queryString: queryStringWithHiddenFilterAndDeselection,
      });
    },
    [
      accountName,
      appliedFilters,
      datasetId,
      queryStringWithHiddenFilterAndDeselection,
      sliceInfo?.id,
      viewType,
    ],
  );

  const [selectedSlice, setSelectedSlice] = useState<SelectedSliceOption>();
  const [isLoading, setIsLoading] = useState(false);

  const { getSliceList, getDataset } = useCurateDatasetService();
  const { setDatasetInfo } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const handleAddSlice = useCallback(async () => {
    if (!selectedSlice) return;
    setIsLoading(true);
    if (!selectedData?.length) {
      await startUpdateSliceByQueryJob({ sliceId: selectedSlice?.id });
    } else {
      await startUpdateSliceJobWithIds({ sliceId: selectedSlice?.id });
    }
    trackDataSliceUpdated(selectedSlice?.id, selectedData?.length || selectedDataCount);

    setAddSliceModalIsOpen(false);
    const datasetInfo = await getDataset({
      fromPublicDatasets: showPublicDatasets,
      datasetId,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
    if (setSelectedAllData && setSelectedData) {
      setSelectedAllData(false);
      setSelectedData([]);
    }
    setIsLoading(false);
  }, [
    selectedSlice,
    selectedData?.length,
    trackDataSliceUpdated,
    setAddSliceModalIsOpen,
    getDataset,
    showPublicDatasets,
    datasetId,
    setDatasetInfo,
    setSelectedAllData,
    setSelectedData,
    startUpdateSliceByQueryJob,
    startUpdateSliceJobWithIds,
  ]);

  function getSelectedDataCount(viewMode: ViewMode) {
    if (viewMode === SCATTER_VIEW) {
      return totalCount;
    }
    // grid view
    return selectedData?.length || totalCount;
  }

  const selectedDataCount = getSelectedDataCount(viewType);

  return (
    <Modal
      open={addSliceModalIsOpen}
      close={{
        onClose: () => {
          setAddSliceModalIsOpen(false);
        },
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.AddToExistingSlice')}
      mainButton={{
        text: 'Okay',
        onClick: handleAddSlice,
        color: 'primary',
        isLoading,
        disabled: !selectedSlice,
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => {
          setAddSliceModalIsOpen(false);
        },
      }}
    >
      <Box minWidth="520px" px={4} py={2.5}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="s-regular" color="black">
            <Trans
              t={t}
              i18nKey="curate.dialogs.selectedCount"
              values={{ count: selectedDataCount }}
              components={{ 1: <Typography color="primary" /> }}
            />
            {sampledCount && sampledCount > 0 && (
              <>
                {' / '}
                <Trans
                  t={t}
                  i18nKey="curate.dialogs.sampledCount"
                  values={{ count: sampledCount ?? 0 }}
                >
                  Sampled
                  <Typography color="primary">{{ count: sampledCount ?? 0 }}</Typography>
                </Trans>
              </>
            )}
          </Typography>
        </Box>
        <SliceAsyncCombobox
          datasetId={datasetId}
          selectedSlice={selectedSlice}
          setSelectedSlice={setSelectedSlice}
        />
      </Box>
    </Modal>
  );
}
