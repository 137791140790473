import { useHistory } from 'react-router';

import { ColorModeProvider } from '../../../../../contexts/ColorModeContext';
import { FilterProvider } from '../../../../../contexts/FilterContext';
import { ImageClusterProvider } from '../../../../../contexts/ImageClusterContext';
import { ImageFilterProvider } from '../../../../../contexts/ImageFilterContext';
import { ImageScopeProvider } from '../../../../../contexts/ImageScopeContext';
import { ObjectClusterProvider } from '../../../../../contexts/ObjectClusterContext';
import { ObjectFilterProvider } from '../../../../../contexts/ObjectFilterContext';
import { ObjectScopeProvider } from '../../../../../contexts/ObjectScopeContext';
import { ScopeMode } from '../../../../../types/viewTypes';
import SliceLayout from './SliceLayout';
import SliceObjectLayout from './SliceObjectLayout';

export default function SliceContentsByScope() {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const scope = (params.get('scope') || 'image') as ScopeMode;

  const Contents = {
    image: (
      <ColorModeProvider>
        <FilterProvider>
          <ImageFilterProvider>
            <ImageClusterProvider>
              <ImageScopeProvider>
                <SliceLayout />
              </ImageScopeProvider>
            </ImageClusterProvider>
          </ImageFilterProvider>
        </FilterProvider>
      </ColorModeProvider>
    ),
    object: (
      <ColorModeProvider>
        <ObjectClusterProvider>
          <FilterProvider>
            <ObjectFilterProvider>
              <ObjectScopeProvider>
                <SliceObjectLayout />
              </ObjectScopeProvider>
            </ObjectFilterProvider>
          </FilterProvider>
        </ObjectClusterProvider>
      </ColorModeProvider>
    ),
  }[scope];

  return Contents;
}
