import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { FormattedNumber, Icon } from '@superb-ai/norwegian-forest';
import { last, map, slice } from 'lodash';

import {
  ASSIGN_DATA_TO_PROJECT_COMPLETE,
  DELETE_DATA_COMPLETE,
} from '../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import AssetsService from '../../../services/AssetsService';
import LabelsService from '../../../services/LabelsService';
import { sleep } from '../../../utils/SpbUtils';
import helper from './helper';
import ToastIcon from './Icon';

const pageSize = 1000;

interface Props {
  classes: Record<string, string>;
  action: string;
  toast: { options: any };
  isCancel: boolean;
  handleClickCancel: () => void;
  handleClickClose: () => void;
  handleClickView: () => void;
}

const Data: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { classes, action, toast, isCancel, handleClickCancel, handleClickClose, handleClickView } =
    props;
  const { options } = toast;
  const { projectId, variant, isAllChecked, checkedList } = options;

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [datasetList, setDatasetList] = useState<any[]>([]);
  const [datasetListIndex, setDatasetListIndex] = useState(-1);
  const [datasetListPage, setDatasetListPage] = useState(0);

  const [dataList, setDataList] = useState<any[]>([]);
  const [lastDataId, setLastDataId] = useState(null);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [accDataCount, setAccDataCount] = useState(0);
  const [curDataCount, setCurDataCount] = useState(0);

  const [processedDataIndex, setProcessedDataIndex] = useState(-1);

  const [message, setMessage] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  const [failures, setFailures] = useState<Record<string, number>>({});

  const [params, setParams] = useState<any>({});
  const [taskId, setTaskId] = useState<string | null>(null);
  const [completedCount, setCompletedCount] = useState(0);
  const [waitIteration, setWaitIteration] = useState(0);

  const startPrepare = async () => {
    if (action === 'assign') {
      setMessage('Preparing to assign data to project ...');
    } else if (action === 'delete') {
      setMessage('Preparing to delete data ...');
    }

    if (action === 'assign') {
      if (isAllChecked) {
        setParams(options.params);
        setTotalDataCount(options.totalCount);
      } else {
        setTotalDataCount(checkedList.length);
        if (variant === 'dataset') {
          setParams({
            groupIn: checkedList,
          });
        } else {
          setParams({
            idIn: checkedList,
          });
        }
      }

      options.setState('endPrepare');
    } else {
      if (variant === 'dataset') {
        if (!isAllChecked) {
          const { count } = await AssetsService.getAssetId({
            params: {
              groupIn: checkedList,
              page: 1,
              pageSize: 0,
            },
            isGuest: authInfo.isGuest,
            urlInfo: routeInfo.urlMatchInfo,
          });
          setTotalDataCount(count);
          setDatasetList(slice(checkedList));
          setDatasetListIndex(0);
        } else {
          setTotalDataCount(options.totalCount);
          setDatasetListPage(1);
        }
      } else if (variant === 'data') {
        if (!isAllChecked) {
          const nextDataList = map(checkedList, item => ({ id: item }));
          setDataList(nextDataList);
          setTotalDataCount(checkedList.length);
          options.setState('endPrepare');
        } else {
          setDatasetList([options.dataset]);
          setTotalDataCount(options.totalCount);
          setDatasetListIndex(0);
        }
      }
    }
  };

  const getDataset = async () => {
    const { results, count } = await AssetsService.getDatasets({
      params: {
        page: datasetListPage,
        pageSize,
        groupIcontains: options.params.groupIcontains,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    const nextDatasetList = [...datasetList, ...results.map((r: any) => r.group)];
    setDatasetList(nextDatasetList);

    if (nextDatasetList.length < count) {
      setDatasetListPage(datasetListPage + 1);
    } else {
      setDatasetListIndex(0);
    }
  };

  const getData = async () => {
    const datasetName = datasetList[datasetListIndex];

    const { results, hasNext, count } = await AssetsService.getAssetId({
      params: {
        groupIn: [datasetName],
        lastId: lastDataId,
        pageSize,
        keyIcontains: options.params.key || options.params.keyIcontains,
        inProject: options.params.inProject,
        projectExists: options.params.projectExists,
      },
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });

    setDataList([...dataList, ...results]);

    const nextCurDataCount = curDataCount + results.length;
    setCurDataCount(nextCurDataCount);

    if (action === 'assign') {
      if (variant === 'dataset') {
        setMessage(
          `Preparing to assign dataset to project ... ${datasetListIndex.toLocaleString(
            'en',
          )} / ${totalDataCount.toLocaleString('en')}`,
        );
      } else {
        setMessage(
          `Preparing to assign data to project ... ${(
            nextCurDataCount + accDataCount
          ).toLocaleString('en')} / ${totalDataCount.toLocaleString('en')}`,
        );
      }
    } else if (action === 'delete') {
      if (variant === 'dataset') {
        setMessage(
          `Preparing to delete dataset... ${datasetListIndex.toLocaleString(
            'en',
          )} / ${totalDataCount.toLocaleString('en')} `,
        );
      } else {
        setMessage(
          `Preparing to delete data... ${(nextCurDataCount + accDataCount).toLocaleString(
            'en',
          )} / ${totalDataCount.toLocaleString('en')} `,
        );
      }
    }

    if (hasNext) {
      setLastDataId(last(results as any[]).id);
    } else {
      setCurDataCount(0);
      setAccDataCount(accDataCount + count);
      setLastDataId(null);
      setDatasetListIndex(datasetListIndex + 1);
    }
  };

  // start get data on datasetListIndex
  useEffect(() => {
    if (datasetListIndex === -1) return;

    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    if (datasetListIndex >= datasetList.length) {
      options.setState('endPrepare');
      return;
    }

    getData();
    // eslint-disable-next-line
  }, [datasetListIndex]);

  useEffect(() => {
    if (!lastDataId) return;
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }
    getData();
    // eslint-disable-next-line
  }, [lastDataId]);

  // get datasetList
  useEffect(() => {
    if (datasetListPage === 0) return;

    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    getDataset();

    // eslint-disable-next-line
  }, [datasetListPage]);

  const endPrepare = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }
    options.setState('startProgress');
  };

  const startProgress = async () => {
    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }
    setProcessedDataIndex(0);
  };

  const assignDataToProject = async () => {
    setMessage('Assigning data to project ...');

    try {
      const { taskId } = await AssetsService.assignDataToProject({
        projectId,
        params,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      setTaskId(taskId);
      options.setState('waitForTaskCompletion');
      // eslint-disable-next-line
    } catch (err: any) {
      setFailures({
        'Unexpected error': 0,
      });
    }
  };

  const deleteDataToProject = async () => {
    setMessage('Deleting data...');

    try {
      await AssetsService.deleteAsset({
        assetId: dataList[processedDataIndex].id,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });

      // eslint-disable-next-line
    } catch (err: any) {}

    setProcessedDataIndex(processedDataIndex + 1);
  };

  const endProgress = async () => {
    const failureList = Object.values(failures);
    const failedListLength = failureList.reduce((acc, cur) => acc + cur, 0);

    if (action === 'assign') {
      setMessage(
        ASSIGN_DATA_TO_PROJECT_COMPLETE({ t, checkedListLength: completedCount, failedListLength }),
      );
    } else if (action === 'delete') {
      setMessage(DELETE_DATA_COMPLETE({ t, checkedListLength: completedCount }));
    }
    setIsFinished(true);
  };

  const cancel = async () => {
    if (action === 'assign') {
      setMessage('Canceled assigning data to project');
    } else if (action === 'delete') {
      setMessage('Canceled deleting data');
    }

    await sleep(3000);
    toast.options.setState('close');
    await sleep(0);
    toast.options.deleteToast();
  };

  const close = async () => {
    if (isCancel) return;

    await sleep(0);
    toast.options.deleteToast();
  };

  useEffect(() => {
    if (processedDataIndex < 0) return;
    if (processedDataIndex > 0 && action === 'assign') return;

    if (isCancel) {
      toast.options.setState('cancel');
      return;
    }

    if (action === 'assign') {
      assignDataToProject();
    } else if (action === 'delete') {
      if (processedDataIndex >= dataList.length) {
        setCompletedCount(dataList.length);
        options.setState('endProgress');
        return;
      }
      deleteDataToProject();
    }

    // eslint-disable-next-line
  }, [processedDataIndex]);

  const waitForTaskCompletion = async () => {
    if (!taskId) return;
    const taskStatus = await LabelsService.getTaskStatus({
      taskId,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
    if (taskStatus.status === 'SUCCESS') {
      const failMsg = new Map<string, number>();
      if (taskStatus.result.wrongTypeCount) {
        failMsg.set(
          'Skipped assets not matching project data type',
          taskStatus.result.wrongTypeCount,
        );
      }
      if (taskStatus.result.duplicateCount) {
        failMsg.set('Skipped assets already added to project', taskStatus.result.duplicateCount);
      }
      setFailures(Object.fromEntries(failMsg));

      const totalCount =
        (taskStatus.result.count || 0) +
        (taskStatus.result.duplicateCount || 0) +
        (taskStatus.result.wrongTypeCount || 0);

      setTotalDataCount(totalCount);
      setCompletedCount(totalCount);

      options.setState('endProgress');
    } else {
      if (taskStatus.result) {
        setProcessedDataIndex(taskStatus.result.completed);
        setTotalDataCount(taskStatus.result.total);
        setMessage(
          `Assigning data to project (ca. ${Math.round(taskStatus.result.timeLeft)}s left) ...`,
        );
      }
      await sleep(1000);
      setWaitIteration(waitIteration + 1);
    }
  };

  useEffect(() => {
    if (waitIteration > 0) {
      waitForTaskCompletion();
    }
  }, [waitIteration]);

  // start prepare -> prepare (use effect) -> end prepare -> start progress -> progress (use effect) -> end progress -> close
  // cancel
  useEffect(
    () => {
      switch (toast.options.state) {
        case 'startPrepare':
          startPrepare();
          break;
        case 'endPrepare':
          endPrepare();
          break;
        case 'startProgress':
          startProgress();
          break;
        case 'waitForTaskCompletion':
          setWaitIteration(1);
          break;
        case 'endProgress':
          endProgress();
          break;
        case 'cancel':
          cancel();
          break;
        case 'close':
          close();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [toast.options.state],
  );

  return (
    <>
      <MUI.Box display="flex" alignItems="center" height="100%">
        <ToastIcon toastState={toast.options.state} />
        <MUI.Typography className={classes.message}>
          {message}{' '}
          {helper.getIsCountVisible(toast.options.state) && (
            <>
              <FormattedNumber value={processedDataIndex} />
              {' / '}
              <FormattedNumber value={totalDataCount} />{' '}
            </>
          )}
        </MUI.Typography>

        {isFinished && (
          <MUI.Typography className={classes.viewText} onClick={handleClickView}>
            View
          </MUI.Typography>
        )}

        {toast.options.state !== 'waitForTaskCompletion' &&
          (helper.getIsCancelActive(toast.options.state) ? (
            <MUI.Typography className={classes.cancelText} onClick={handleClickCancel}>
              cancel
            </MUI.Typography>
          ) : (
            <span onClick={handleClickClose}>
              <Icon name="clear" color="background" className={classes.closeIcon} size="14px" />
            </span>
          ))}
      </MUI.Box>
      {(toast.options.state === 'endProgress' || toast.options.state === 'cancel') &&
        Object.keys(failures).length > 0 && (
          <MUI.Box display="flex" marginTop="4px" height="100%">
            <ToastIcon toastState="failed" />
            <MUI.Box>
              {Object.keys(failures).map(item => (
                <MUI.Box className={classes.message} key={item}>
                  {item}: {failures[item]}
                </MUI.Box>
              ))}
            </MUI.Box>
          </MUI.Box>
        )}
    </>
  );
};

export default Data;
