import { Box, NamedColor, NamedColorWeight } from '@superb-ai/ui';

interface Props {
  color: NamedColorWeight | NamedColor;
}

export const Circle = ({ color }: Props) => {
  return (
    <Box backgroundColor={color} style={{ width: '8px', height: '8px' }} borderRadius="100%" />
  );
};
