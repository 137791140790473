import React from 'react';

import { TablePagination } from '@mui/material';

import TablePaginationActions from '../../../../components/elements/TablePagination/TablePaginationAction';

export default function DatasetPagination({
  totalCount,
  size,
  page,
  onPageChange,
  onRowsPerPageChange,
  type,
}: {
  totalCount: number;
  size: number;
  page: number;
  onPageChange: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type: 'SLICE' | 'DATASET';
}): JSX.Element {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={totalCount}
      rowsPerPage={size}
      page={page - 1}
      labelDisplayedRows={() => ''}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage={type === 'DATASET' ? 'datasets per page' : 'slices per page'}
      style={{
        paddingRight: '60px',
      }}
    />
  );
}
