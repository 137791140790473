import React from 'react';

import { Icon, Input } from '@superb-ai/norwegian-forest';

import { PlotDatum } from './types';

interface SearchProps {
  data: PlotDatum[];
  onDebounceChangeData: (data: PlotDatum[]) => void;
  t: (text: string) => string;
}

const LabelerQASearch: React.FC<SearchProps> = ({ data, onDebounceChangeData, t }) => {
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onDebounceChangeData(
      data.map(item => {
        if (item.email.includes(value)) {
          return { ...item, isFiltered: true };
        }
        // eslint-disable-next-line
        const { isFiltered, ...otherProps } = item;
        return { ...otherProps, isFiltered: false };
      }),
    );
  };

  return (
    <Input
      Adornment={<Icon color={['grey', 300]} name="search" />}
      onChange={handleChangeInput}
      placeholder={t('analytics.qa.text.searchForEmail')}
      type="text"
    />
  );
};

export default LabelerQASearch;
