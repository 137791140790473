import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@superb-ai/norwegian-forest';

import { ClassNameCell } from './components/ClassNameCell';
import { DataCell } from './components/DataCell';
import { AnnotationsChangedRow, AnnotationsChangedTableProps } from './types/types';

interface Props {
  header: AnnotationsChangedTableProps[];
  row: AnnotationsChangedRow;
}

const AnnotationsChangedTableRow = (props: Props) => {
  const { row, header } = props;
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const TotalCell = (row: AnnotationsChangedRow, id: string): JSX.Element => {
    return (
      <Box display="flex" alignItems="center" ml={0.5} style={{ fontWeight: 600 }}>
        {row[id as keyof AnnotationsChangedRow]}
      </Box>
    );
  };

  return (
    <StyledTableRow hover style={{ cursor: 'default' }}>
      {header.map(col => {
        const columnId = col.id as keyof AnnotationsChangedRow;
        const isClassName = columnId === 'className';
        return (
          <React.Fragment key={`${columnId}`}>
            <TableCell align="right" style={{ textAlign: 'right' }}>
              {isClassName && row[columnId] === 'Total' && TotalCell(row, columnId)}
              {isClassName && row[columnId] !== 'Total' && ClassNameCell(row, columnId)}
              {!isClassName && DataCell(row, columnId)}
            </TableCell>
          </React.Fragment>
        );
      })}
    </StyledTableRow>
  );
};

export default AnnotationsChangedTableRow;
