import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useProjectInfo } from '../../../contexts/ProjectContext';
import { useUsersInfo } from '../../../contexts/UsersContext';
import UserUtils from '../../../utils/UserUtils';
import RoleChip from '../RoleChip';
import TextEllipsisTooltip from '../TextEllipsisTooltip';
import UserAvatar from '../UserAvatar';

const useStyles = makeStyles(theme => ({
  mentionTag: {
    fontWeight: 500,
    fontStyle: 'italic',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  popper: {
    zIndex: 10000,
  },
  paper: {
    top: '12px',
    position: 'relative',
    padding: '12px',
    boxShadow: theme.shadows[1],
    backgroundColor: '#fff',
    borderRadius: '4px',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '-8px',
      left: 'calc(50% - 8px)',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '8px solid #fff',
    },
  },
  userName: {
    marginRight: '12px',
    color: theme.palette.grey[800],
    lineHeight: 1,
  },
}));

interface Props {
  id: string;
  display: string;
}

const MentionTag: React.FC<Props> = ({ id, display }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const projectInfo = useProjectInfo();
  const usersInfo = useUsersInfo();

  const userObjects = projectInfo?.project ? projectInfo.userObjects : usersInfo.userObjects;

  const userObject = UserUtils.getUserObjectForComment(userObjects, id);
  const { avatarUrl, role, email, name } = userObject;

  const handleMouseEnter = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as any);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <MUI.Box
        display="inline"
        className={classes.mentionTag}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        @{display}
      </MUI.Box>
      {/* @ts-ignore: saa-680 */}
      <MUI.Popper transition open={open} anchorEl={anchorEl} className={classes.popper}>
        {({ TransitionProps }) => (
          <MUI.Fade {...TransitionProps}>
            <MUI.Box className={classes.paper} maxWidth="300px" display="flex" alignItems="center">
              <UserAvatar size={16} noShadow userInfo={{ avatarUrl, name, email }} />
              <TextEllipsisTooltip text={name} maxWidth="70px" mr="8px">
                <MUI.Typography variant="body1">{name}</MUI.Typography>
              </TextEllipsisTooltip>
              <TextEllipsisTooltip text={email} maxWidth="116px" mr="8px">
                <MUI.Typography variant="body1" color="textSecondary">
                  {email}
                </MUI.Typography>
              </TextEllipsisTooltip>
              {role && <RoleChip memberRole={role} />}
            </MUI.Box>
          </MUI.Fade>
        )}
      </MUI.Popper>
    </>
  );
};

export default MentionTag;
