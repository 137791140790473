import { RefObject, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Box, vars } from '@superb-ai/ui';
import dynamic from 'next/dynamic';

import { useDebounce } from '../../../../../../../hooks/DebounceHook';
import { useImageClusterContext } from '../../../../../contexts/ImageClusterContext';
import { useImageFilterContext } from '../../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../../contexts/ImageScopeContext';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { IMAGE_SCOPE, SCOPE, ScopeMode } from '../../../../../types/viewTypes';
import { LoadingIndicatorDiv } from '../../analytics/components/LoadingIndicatorDiv';
import { useImageScatterContext } from '../../analytics/contexts/ImageScatterContext';
import NoDataView from '../../scatterView/NoDataView';
import NoResultsView from '../../scatterView/NoResultsView';
import { ScatterActionBar } from './ScatterActionBar';
import { usePoints } from './scatterView/providers/DataProvider';
import { useTranslation } from 'react-i18next';

const ScatterPlotRenderer = dynamic({
  loader: () => import('./scatterView/ScatterPlotRenderer').then(x => x.ScatterPlotRenderer),
  ssr: false,
});
export const ScatterArea = ({
  width,
  height,
  cardStyle,
  chartAreaRef,
  chartAreaSize,
  setChartAreaSize,
}: {
  width: number | string;
  height: number | string;
  cardStyle: React.CSSProperties;
  chartAreaRef: RefObject<HTMLDivElement>;
  chartAreaSize?: { width: number; height: number };
  setChartAreaSize: (size: { width: number; height: number }) => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const points = usePoints();
  const searchParams = new URLSearchParams(history.location.search);
  const scope = (searchParams.get(SCOPE) || IMAGE_SCOPE) as ScopeMode;
  const { totalCount } = useImageScopeContext();
  const {
    data: dataPoints,
    isLoadingProjections,
    isLoadingProjectionsCompare,
  } = useImageScatterContext();
  const { setHoveredDotGroup, hoveredDotGroup, hoveredFilterGroup } = useImageClusterContext();
  const { selectedSuperClusters, setSelectedSuperClusters, selectedLeafClusters } =
    useImageFilterContext();

  const isLoading = isLoadingProjections;

  const { queryString } = useQueryContext();
  const { hasAppliedFilters } = useImageFilterContext();
  const hasAppliedQueryOrFilters = Boolean(queryString || hasAppliedFilters);
  const debouncedChartAreaSize = useDebounce(chartAreaSize, 200);
  const BANNER_HEIGHT = scope === IMAGE_SCOPE ? 0 : 100;

  useEffect(() => {
    if (!chartAreaRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!chartAreaRef.current) {
        return;
      }
      const { width, height } = chartAreaRef.current.getBoundingClientRect();
      setChartAreaSize({
        width: width,
        height: height - BANNER_HEIGHT,
      });
    });

    observer.observe(chartAreaRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSelectGroups = (updatedClusters: string[] | undefined) => {
    setSelectedSuperClusters(updatedClusters);
  };

  const handleHoverDotGroup = (groupId: string | null) => {
    setHoveredDotGroup && setHoveredDotGroup(groupId);
  };

  const getChartArea = () => {
    if (dataPoints && dataPoints.length === 0) {
      // TODO: bc there can be a lag between original datapoints and points
      if (hasAppliedQueryOrFilters) return <NoResultsView scope={scope} />;
      return <NoDataView scope={scope} />;
    }

    return (
      <Box>
        <ScatterPlotRenderer
          canvasSize={[
            debouncedChartAreaSize?.width || 0,
            debouncedChartAreaSize ? debouncedChartAreaSize.height : 0,
          ]}
          handleHoverGroup={handleHoverDotGroup}
          hoveredGroup={hoveredDotGroup || hoveredFilterGroup}
          handleSelectImageGroups={handleSelectGroups}
          selectedImageGroups={selectedSuperClusters}
          selectedImages={selectedLeafClusters}
        />
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      style={{ ...cardStyle, width, height }}
      flexDirection="column"
      alignItems="flex-start"
    >
      <Box
        display="flex"
        width="100%"
        p={0.5}
        alignItems="center"
        justifyContent="space-between"
        style={{ boxShadow: `inset 0 -1px 0 ${vars.color['gray-250']}` }}
      >
        <ScatterActionBar
          width={width}
          height={height}
          chartAreaRef={chartAreaRef}
          setChartAreaSize={setChartAreaSize}
        />
      </Box>
      <Box
        ref={chartAreaRef}
        display="flex"
        width="100%"
        height="100%"
        position="relative"
        overflow="hidden"
      >
        {!dataPoints || isLoading ? (
          <LoadingIndicatorDiv message={t('curate.embeddings.text.loading')} />
        ) : (
          getChartArea()
        )}
      </Box>
    </Box>
  );
};
