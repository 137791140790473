import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Typography } from '@superb-ai/ui';

import { useActionContext } from '../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { useImageScopeContext } from '../../../../contexts/ImageScopeContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { useDatasetsBriefDataQuery } from '../../../../queries/dataQueries';
import {
  useUpdateSliceByQueryJobMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../queries/sliceQueries';
import { useCurateCommandsService } from '../../../../services/CommandsService';
import { useCurateDatasetService } from '../../../../services/DatasetService';

export default function RemoveFromSlice({
  removeFromSliceModalIsOpen,
  setRemoveFromSliceModalIsOpen,
}: {
  removeFromSliceModalIsOpen: boolean;
  setRemoveFromSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, sliceId, datasetId } = useParams<{
    accountName: string;
    sliceId: string;
    datasetId: string;
  }>();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const { totalCount: totalImageCount } = useImageScopeContext();
  const totalCount = totalImageCount || 0;
  const { showPublicDatasets } = usePublicDatasetContext();

  const {
    selectedData,
    setSelectedData,
    selectedAllData,
    setSelectedAllData,
    sampleThumbnailUrls,
  } = useActionContext();
  const { sliceInfo, setSliceInfo } = useSliceContext();
  const { appliedFilters } = useImageFilterContext();

  const commandContext = useCurateCommandContext();
  const imageDataList = selectedAllData ? sampleThumbnailUrls : selectedData.slice(0, 4);
  const { getJobPresignedUrl } = useCurateCommandsService();
  const { getSlice } = useCurateDatasetService();
  const { t } = useTranslation();
  const { refetch: refetchData } = useDatasetsBriefDataQuery({
    datasetId,
    queryString: queryStringWithHiddenFilterAndDeselection,
    sliceName: sliceInfo?.name || '',
    // TODO (moon) do we need filte rhere?
    // appliedFilters,
  });

  const { mutateAsync: startUpdateSliceJobWithIds } = useUpdateSliceJobWithIdsMutation({
    datasetId,
    commandContext,
    selectedImageIds: selectedData.map(image => image.id),
  });

  const { mutateAsync: startUpdateSliceByQueryJob } = useUpdateSliceByQueryJobMutation({
    datasetId,
    sliceName: sliceInfo?.name,
    commandContext,
    appliedFilters,
    queryString: queryStringWithHiddenFilterAndDeselection,
  });

  const handleClickRemove = useCallback(async () => {
    if (selectedAllData) {
      await startUpdateSliceByQueryJob({ sliceId: sliceId, remove: true });
    } else {
      await startUpdateSliceJobWithIds({ sliceId: sliceId, remove: true });
    }

    const newSliceInfo = await getSlice({
      datasetId,
      fromPublicDatasets: showPublicDatasets,
      sliceId,
      expand: ['image_count'],
    });

    setSliceInfo(newSliceInfo);
    setSelectedAllData(false);
    setSelectedData([]);
    setRemoveFromSliceModalIsOpen(false);
    refetchData();
  }, [
    selectedAllData,
    datasetId,
    sliceInfo,
    queryStringWithHiddenFilterAndDeselection,
    sliceId,
    selectedData,
    getSlice,
    setSliceInfo,
    setSelectedAllData,
    setSelectedData,
    setRemoveFromSliceModalIsOpen,
    refetchData,
    commandContext,
    getJobPresignedUrl,
  ]);

  return (
    <Modal
      open={removeFromSliceModalIsOpen}
      close={{
        onClose: () => {
          setRemoveFromSliceModalIsOpen(false);
        },
        canClickOutside: true,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.removeFromThisSlice')}
      mainButton={{
        text: t('button.remove'),
        onClick: handleClickRemove,
        color: 'primary',
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: () => {
          setRemoveFromSliceModalIsOpen(false);
        },
      }}
    >
      <Box minWidth="520px" py={2.5} px={4}>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Typography variant="m-regular" color="gray-400">
              <Trans t={t} i18nKey={'curate.slices.removeInfos'}>
                <>Are you sure you want to remove </>
                <Typography variant="m-medium" color="primary-400" style={{ margin: '0 2px' }}>
                  {{
                    imageCount: selectedAllData
                      ? (totalCount || 0).toLocaleString()
                      : selectedData.length.toLocaleString(),
                  }}
                </Typography>
                <></>
              </Trans>
            </Typography>
          </Box>
        </Box>
        {selectedAllData || selectedData?.length > 0 ? (
          <Box mt={2}>
            <Typography variant="m-regular">
              {selectedAllData ? (
                <Trans t={t} i18nKey="curate.dialogs.selectedAll">
                  Selected
                  <Typography color="primary">All</Typography>
                </Trans>
              ) : (
                <Trans
                  t={t}
                  i18nKey="curate.dialogs.selectedCount"
                  values={{ count: selectedData.length }}
                >
                  Selected
                  <Typography color="primary">{selectedData?.length}</Typography>
                </Trans>
              )}
            </Typography>
            <Box display="flex" mt={1}>
              {imageDataList.map(list => (
                <Box
                  key={list.id}
                  width={'107px'}
                  height={'62px'}
                  mr={1}
                  style={{
                    verticalAlign: 'bottom',
                    lineHeight: '0',
                  }}
                  position="relative"
                  borderRadius="2px"
                  overflow="hidden"
                  boxSizing="border-box"
                  backgroundColor="#EBEBEB"
                  display="flex"
                  justifyContent="center"
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={list.thumbnailUrl}
                      alt=""
                      style={{
                        display: 'block',
                        height: '100%',
                        borderRadius: '2px',
                      }}
                    />
                  </div>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Modal>
  );
}
