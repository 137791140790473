/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from '@superb-ai/norwegian-forest';
import { isEmpty } from 'lodash';

import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useUploadInfo } from '../../../../contexts/UploadContext';
import { LabelData } from '../../../../types/labelTypes';
import { AscDesc } from '../../../../types/muiTypes';
import AssignDialog from '../../../elements/assign/AssignDialog';
import { AssignDialogProvider } from '../../../elements/assign/Context';
import CircularProgressBox from '../../../elements/CircularProgressBox';
import LabelDetailView from '../labelDetail/Layout';
import ThumbnailViewHeader from './ThumbnailViewHeader';
import ThumbnailViewRow from './ThumbnailViewRow';

const useStyles = makeStyles(() => ({
  allSelectedRow: {
    height: '40px',
  },
  allSelectedCell: {
    color: '#635c5c',
    backgroundColor: 'rgba(183, 183, 183, 0.12)',
  },
  textButton: {
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    textTransform: 'initial',
    lineHeight: 1,
    minWidth: 'initial',
    '&:hover': {
      backgroundColor: 'rgba(183, 183, 183, 0.2)',
    },
  },
  noSelectedRow: {
    height: '100px',
    '& td': {
      fontSize: '13px',
      textAlign: 'center',
    },
    '& a': {
      color: '#ff625a',
    },
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  progress: {
    margin: '10px',
  },
}));

const DefaultOrderBy = 'last_updated_at';
const DefaultIsDesc = true;

interface ViewProps {
  labels: LabelData[];
  visibleLabels: LabelData[];
  checkedLabels: string[];
  expandedLabels: LabelData[];
  loadingLabels: LabelData[];
  labelsByAssetId: Record<string, LabelData[]>;
  totalCount: number;
  isDesc: boolean;
  orderBy: string;
  isLoading: boolean;
  loadingFinishedAt: Date | null;
  isAllLabelsChecked: boolean;
  isGlobalChecked: boolean;
  advancedQaVisible: boolean;
  reviewVisible: boolean;
  projectName: string;
  onExpandLabel: (label: LabelData, isExpanded: boolean) => void;
  onExpandAll: (expand: boolean) => void;
  onClickCheckbox: (id: string) => (event: React.MouseEvent) => void;
  onClickGlobalCheckbox: () => void;
  onClickSelectAllLabels: (isAllLabelsChecked: boolean) => () => void;
  onRequestSort: (field: string, ascDesc: AscDesc) => void;
}

const ThumbnailView = ({
  labels,
  checkedLabels,
  expandedLabels,
  loadingLabels,
  labelsByAssetId,
  isDesc = DefaultIsDesc,
  orderBy = DefaultOrderBy,
  isLoading,
  advancedQaVisible,
  reviewVisible,
  projectName,
  isAllLabelsChecked,
  onClickCheckbox,
  onClickGlobalCheckbox,
  onRequestSort,
  onExpandLabel,
  onExpandAll,
  visibleLabels,
}: ViewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isExpanded = useMemo(
    () => (label: any) => expandedLabels.indexOf(label) !== -1,
    [expandedLabels],
  );
  const isLoadingSubLabels = useMemo(
    () => (label: any) => loadingLabels.indexOf(label) !== -1,
    [loadingLabels],
  );
  const { setIsUploadModalOpen } = useUploadInfo();

  const hasExpandableRows = labels.some(label => (label.relatedLabelsCount || 0) > 0);

  const colNumber = advancedQaVisible ? 11 : 10;

  const getListMessage = () => {
    if (isEmpty(labels)) {
      if (authInfo.role === 'collaborator') {
        return (
          <MUI.TableRow className={classes.noSelectedRow}>
            <MUI.TableCell colSpan={colNumber}>
              <MUI.Typography variant="body2">{t('labels.messages.noLabelsFound')}</MUI.Typography>
            </MUI.TableCell>
          </MUI.TableRow>
        );
      }
      if (isEmpty(routeInfo.params)) {
        return (
          <MUI.TableRow className={classes.noSelectedRow}>
            <MUI.TableCell colSpan={colNumber}>
              <MUI.Box display="flex" alignItems="center" justifyContent="center">
                <MUI.Typography variant="body2">
                  {t('labels.messages.noLabelsFound')}{' '}
                  <Trans t={t} i18nKey="labels.messages.uploadOrAssignData">
                    <Link onClick={() => setIsUploadModalOpen(true)}>Upload data</Link> or{' '}
                    <MUI.Link onClick={() => setIsOpen(true)}>assign existing data</MUI.Link> to
                    this project.
                  </Trans>
                </MUI.Typography>
              </MUI.Box>
            </MUI.TableCell>
          </MUI.TableRow>
        );
      }
      return (
        <MUI.TableRow className={classes.noSelectedRow}>
          <MUI.TableCell colSpan={colNumber}>
            <MUI.Typography variant="body2">
              {' '}
              {t('labels.messages.noMatchingabelsFound')}
            </MUI.Typography>
          </MUI.TableCell>
        </MUI.TableRow>
      );
    }

    return <></>;
  };

  return (
    <AssignDialogProvider>
      <MUI.Table size="small">
        <ThumbnailViewHeader
          isDesc={isDesc}
          orderBy={orderBy}
          advancedQaVisible={advancedQaVisible}
          reviewVisible={reviewVisible}
          onRequestSort={onRequestSort}
          onClickGlobalCheckbox={onClickGlobalCheckbox}
          onExpandAll={onExpandAll}
          hasExpandableRows={hasExpandableRows}
          isAllLabelsChecked={isAllLabelsChecked}
          labelCount={labels.length}
          checkedLabels={checkedLabels}
          visibleLabels={visibleLabels}
        />
        {!isLoading && (
          <MUI.TableBody>
            {getListMessage()}
            {labels.map(label => (
              <React.Fragment key={label.id}>
                <ThumbnailViewRow
                  label={label}
                  checkedLabels={checkedLabels}
                  onClickCheckbox={onClickCheckbox}
                  expandedState={
                    isExpanded(label)
                      ? 'EXPANDED'
                      : isLoadingSubLabels(label)
                      ? 'LOADING'
                      : 'COLLAPSED'
                  }
                  advancedQaVisible={advancedQaVisible}
                  reviewVisible={reviewVisible}
                  onToggleExpand={() => onExpandLabel(label, isExpanded(label))}
                  isAllLabelsChecked={isAllLabelsChecked}
                />
                {isExpanded(label) &&
                  labelsByAssetId[label.asset.id]?.map(
                    subLabel =>
                      subLabel.id !== label.id && (
                        <React.Fragment key={label.id + subLabel.id}>
                          <ThumbnailViewRow
                            label={subLabel}
                            checkedLabels={checkedLabels}
                            advancedQaVisible={advancedQaVisible}
                            reviewVisible={reviewVisible}
                            onClickCheckbox={onClickCheckbox}
                            isAllLabelsChecked={isAllLabelsChecked}
                          />
                        </React.Fragment>
                      ),
                  )}
              </React.Fragment>
            ))}
          </MUI.TableBody>
        )}
      </MUI.Table>
      {isLoading && (
        <CircularProgressBox
          boxProps={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: '50px',
          }}
        />
      )}
      <LabelDetailView />
      <AssignDialog {...{ isOpen, setIsOpen, projectName }} />
    </AssignDialogProvider>
  );
};

// (╯°□°）╯︵ ┻━┻
export default React.memo(ThumbnailView, (prevProps, nextProps) => {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.checkedLabels === nextProps.checkedLabels &&
    prevProps.isAllLabelsChecked === nextProps.isAllLabelsChecked &&
    prevProps.isGlobalChecked === nextProps.isGlobalChecked &&
    prevProps.loadingFinishedAt === nextProps.loadingFinishedAt &&
    prevProps.expandedLabels === nextProps.expandedLabels &&
    prevProps.loadingLabels === nextProps.loadingLabels &&
    prevProps.visibleLabels === nextProps.visibleLabels
  );
});
