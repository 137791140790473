import React, { createContext, useContext, useState } from 'react';

type ContextProps = {
  filter: any;
  setFilter: React.Dispatch<React.SetStateAction<any>>;
};

const UserReportContext = createContext<ContextProps>({} as ContextProps);

const useProvider = () => {
  const [filter, setFilter] = useState<any>(null);

  return {
    filter,
    setFilter,
  };
};

export const useUserReportContext = (): ContextProps => {
  return useContext(UserReportContext);
};

export const UserReportProvider: React.FC = ({ children }) => {
  const userReportInfo = useProvider();
  return <UserReportContext.Provider value={userReportInfo}>{children}</UserReportContext.Provider>;
};
