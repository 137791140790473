import React, { useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

import FloatingMenu from './FloatingMenu';

const useStyles = makeStyles(theme => ({
  canvasSection: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
  },
  badgeDiv: {
    position: 'absolute',
  },
  panel: {
    position: 'relative',
  },
  imageAdjustSection: {
    position: 'absolute',
    left: '25px',
    bottom: '0px',
  },
  imageAdjustButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    background: '#ffffff',
    borderRadius: '12px',
    boxShadow: theme.shadows[1],
  },
  icon: {
    width: '24px',
    height: '24px',
    color: '#424242',
  },
}));

const FloatingButton: React.FC = () => {
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <MUI.Box
        className={classes.imageAdjustButton}
        onClick={handleToggleMenu}
        {...({ ref: buttonRef } as any)}
      >
        <Icon name="moreHorizontal" className={classes.icon} />
      </MUI.Box>
      {/* @ts-ignore: saa-680 */}
      <MUI.Popper
        open={isMenuOpen}
        anchorEl={buttonRef.current}
        disablePortal
        transition
        placement="right-end"
      >
        {({ TransitionProps }) => (
          <MUI.Fade {...TransitionProps}>
            <FloatingMenu onCloseMenu={handleCloseMenu} />
          </MUI.Fade>
        )}
      </MUI.Popper>
    </>
  );
};

export default FloatingButton;
