import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Pencil, Upload } from '@superb-ai/icons';
import { Box, Button, Dialog, Icon, Typography, useDialogState } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../../../../contexts/AppContext';
import { useDatasetModalContext } from '../../../../../contexts/ModalContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { useSliceContext } from '../../../../../contexts/SliceContext';
import ListIsEmpty from '../../../ListIsEmpty';
import DataSectionContainer from '../../DataSectionContainer';
import SliceSettingsModal from '../../modal/SliceSettingsModal';
import UploadModal from '../../modal/UploadModal';
import ViewContentsArea from '../../views/ViewContentsArea';

export default function SliceLayout(): JSX.Element {
  const { t } = useTranslation();
  const { sliceId } = useParams<{
    sliceId?: string;
  }>();
  const dialog = useDialogState();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { sliceSettingsIsOpen, setSliceSettingsIsOpen } = useDatasetModalContext();
  const { sliceInfo, isLoading } = useSliceContext();

  const [hoverSliceInfo, setHoverSliceInfo] = useState<boolean>(false);

  useSetPageTitle(t('curate.dataset.menus.slice'), PAGE_TRACKING_ID.curateDatasetSlice);

  if (!sliceInfo) return <></>;

  const SliceNameBar = (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        onMouseEnter={() => setHoverSliceInfo(true)}
        onMouseLeave={() => setHoverSliceInfo(false)}
        style={{ flex: 1 }}
      >
        <p
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 'calc(100% - 84px)',
          }}
        >
          <Typography variant="l-strong">{sliceInfo?.name || ''}</Typography>
          <br />
          <Typography variant="m-regular">{sliceInfo?.description || '...'}</Typography>
        </p>
        {hoverSliceInfo && !showPublicDatasets && (
          <Button
            disabled={showPublicDatasets}
            style={{ marginLeft: '12px' }}
            onClick={() => {
              setSliceSettingsIsOpen(true);
              return;
            }}
            color="gray"
            variant="stroke"
            size="m"
          >
            <Icon icon={Pencil} />
            {t('curate.button.edit')}
          </Button>
        )}
      </Box>
      <Dialog.Disclosure {...dialog}>
        {(disclosureProps: any) => (
          <Button
            style={{ ml: 'auto' }}
            disabled={showPublicDatasets}
            {...disclosureProps}
            color="gray"
            variant="text"
            size="m"
          >
            <Icon icon={Upload} />
            {t('curate.datasets.cloudUpload.button_short')}
          </Button>
        )}
      </Dialog.Disclosure>
      <UploadModal dialog={dialog} hasSliceStep={false} />
    </Box>
  );
  return (
    <Box>
      <Box display="flex">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Box pt={2} pl={4} pr={2}>
            {sliceId && SliceNameBar}
          </Box>
          <DataSectionContainer
            isLoading={isLoading}
            isEmpty={(sliceInfo?.imageCount || 0) <= 0}
            EmptyView={<ListIsEmpty listType={sliceInfo?.name || ''} />}
            ViewContents={<ViewContentsArea />}
          />
        </Box>
      </Box>
      {sliceSettingsIsOpen && <SliceSettingsModal />}
    </Box>
  );
}
