import { CSSProperties } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, LinkTypography, Typography } from '@superb-ai/ui';
import Image from 'next/image';

import noDataImage from './noData.png';

type Props = {
  style?: CSSProperties;
};
export const NoChartsView = ({ style }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const clickLabelsLink = () => {
    history.push(history.location.pathname.replace('analytics/custom-charts', 'labels'));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      gap={1}
      style={{ ...style }}
    >
      <Image width="86" height="86" src={noDataImage} alt="Illustration showing empty data." />
      <Typography variant="h2" color="gray-400">
        {t('analytics.customizeAnalytics.text.noChartsFound')}
      </Typography>

      <Typography variant="m-regular" color="gray-300">
        <Trans
          i18nKey={'analytics.customizeAnalytics.text.pleaseAddChart'}
          t={t}
          components={{
            LinkTypography: (
              <LinkTypography variant="m-regular" color="secondary" onClick={clickLabelsLink} />
            ),
          }}
        >
          Please add chart from
          <LinkTypography variant="m-regular" color="secondary" onClick={clickLabelsLink}>
            Labels list
          </LinkTypography>
        </Trans>
      </Typography>
    </Box>
  );
};

export default NoChartsView;
