import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { Box } from '@superb-ai/norwegian-forest';
import { groupBy } from 'lodash';

import ProjectConst, { DataTypeGroup } from '../../../../consts/ProjectConst';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import { WorkApp } from '../../../../union/WorkappUnion';
import { EDIT_PROJECT_PATH } from '../paths';
import Title from '../Title';
import DataTypeButton from './DataTypeButton';
import helper from './helper';

const DataTypeSelect = (): React.ReactElement => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const segments = pathname.split('/');
  const isEditPage = segments[segments.length - 2] === EDIT_PROJECT_PATH;
  const searchParams = Object.fromEntries(new URLSearchParams(search));
  const dataTypeParam = searchParams['data_type'] as DataTypeGroup | undefined;
  const lidar = useFeatureFlag('lidar');

  const dataTypesConsts = Object.values(ProjectConst.dataTypes(lidar));

  const newProjectInfo = useNewProjectInfo();
  const [dataTypes, setDataTypes] = useState<typeof dataTypesConsts>([]);

  const dataTypesByGroup = groupBy(dataTypesConsts, data => data.group) as Record<
    DataTypeGroup,
    typeof dataTypesConsts
  >;

  useEffect(() => {
    if (!dataTypeParam) {
      setDataTypes(dataTypesConsts);
      return;
    }
    const availabelDataTypes = dataTypesByGroup[dataTypeParam];
    setDataTypes(availabelDataTypes);
    // Select the first type
    helper.updateDataTypes(
      newProjectInfo,
      availabelDataTypes[0].type,
      availabelDataTypes[0].workapp as WorkApp,
      lidar,
    );
  }, [search, pathname]);

  useEffect(() => {
    if (isEditPage) {
      // On edit page, only show configured type
      setDataTypes(dataTypesConsts.filter(data => data.type === newProjectInfo.selectedDataType));
      return;
    }
  }, [newProjectInfo.selectedDataType]);

  // Layout buttons in columns of 2 when more than 3 items
  const gridColumns =
    dataTypes.length > 3 ? '1fr 1fr' : 'repeat(auto-fit, minmax(196px, max-content))';

  return (
    <Box display="flex" flexDirection="column" mb={5} gap={16}>
      <Title title={t('projectSettings.configuration.dataType')} />
      <Box display="grid" gap={12} gridTemplateColumns={gridColumns}>
        {dataTypes.map(info => (
          <DataTypeButton key={info.type} data={info} />
        ))}
      </Box>
    </Box>
  );
};

export default DataTypeSelect;
