import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Input, Modal, Typography } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../contexts/RouteContext';
import ProjectService from '../../../../../services/ProjectService';

const DeleteConfirmDialog: FC<{
  isDialogOpen: boolean;
  setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isDialogOpen, setIsConfirmDialogOpen }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const history = useHistory();

  const handleClickConfirmButton = async () => {
    if (input !== 'DELETE') {
      return;
    }

    setIsLoading(true);
    try {
      await ProjectService.deleteProject({
        projectId: routeInfo.urlMatchInfo.projectId,
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      history.push('/');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setInput('');
    setIsConfirmDialogOpen(false);
  };

  return (
    <Modal
      open={isDialogOpen}
      title={
        <Typography variant="headline4">
          {t('projectSettings.deleteProject.confirm.title')}
        </Typography>
      }
      mainButton={{
        color: 'primary',
        text: t('projectSettings.deleteProject.deleteButton'),
        onClick: handleClickConfirmButton,
        disabled: input !== 'DELETE',
        isLoading: isLoading,
        loadingText: t('button.deleting'),
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClose,
      }}
    >
      <Box width={480} px={4}>
        <Box mb={1} />
        <Box display="flex" width="100%" flexDirection="column" mb={1}>
          <Typography variant="body3" themedColor="text">
            <Trans t={t} i18nKey="projectSettings.deleteProject.confirm.text" />
          </Typography>
        </Box>
        <Box borderRadius={6} mb={1} style={{ backgroundColor: '#f7f7f7' }} p={1}>
          <Typography variant="body4">- {t('projectMembers.title')}</Typography>
          <Box mb={0.5} />
          <Typography variant="body4">- {t('projects.settings.exportHistory')}</Typography>
          <Box mb={0.5} />
          <Typography variant="body4">- {t('projects.settings.guidelines')}</Typography>
        </Box>
        <Box display="flex" width="100%" mb={1}>
          <Typography variant="body3" themedColor="text">
            {t('projectSettings.deleteProject.confirm.cannotUndo')}
          </Typography>
        </Box>
        <Box width="100%" mb={3}>
          <Input
            style={{ width: '100%' }}
            value={input}
            variant="underline"
            placeholder={t('projectSettings.deleteProject.confirm.typeToConfirm')}
            onChange={e => setInput(e.target.value)}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmDialog;
