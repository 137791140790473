import React from 'react';

import { Typography } from '@mui/material';
import { Box, Icon } from '@superb-ai/norwegian-forest';
import * as d3 from 'd3';

import {
  COLOR_CHIP_TAG_10PERCENT_OPACITY,
  COLOR_CHIP_TAG_DEFAULT,
} from '../../../../../consts/ColorChips';
import BaseCard from './BaseCard';
import { useCardStyle } from './cardStyle';

const iconTag = (title: string, classes: Record<string, string>) => {
  const getTotalIcons = () => {
    return (
      <Box display="flex" position="relative">
        <Icon
          className={classes.totalCardBackground}
          name="tag"
          size="26px"
          customColor={COLOR_CHIP_TAG_10PERCENT_OPACITY.VERY_LIGHT_PINK}
        />
        <Box
          display="flex"
          position="absolute"
          m={0}
          width="100%"
          height="100%"
          style={{ left: 0, top: 0, right: 0, bottom: 0 }} // Not sure hot to NOT hard code this
        >
          <Icon
            className={classes.totalCard}
            name="tag"
            size="26px"
            customColor={COLOR_CHIP_TAG_DEFAULT.GRAPEFRUIT}
          />
        </Box>
      </Box>
    );
  };
  const getSubmittedIcon = () => {
    return (
      <Icon
        className={classes.submittedCard}
        name="selectMultiple"
        size="26px"
        customColor={COLOR_CHIP_TAG_DEFAULT.AQUA_MARINE}
      />
    );
  };
  return title === 'Total Labels' ? getTotalIcons() : getSubmittedIcon();
};

export type IProps = {
  title: string;
  labelCount: number;
};

/**
 * Displays project's total submitted label count in Analytics > Project Analytics.
 */
const TotalCard: React.FC<IProps> = ({ title, labelCount }) => {
  /** Card styling */
  const classes = useCardStyle();
  const formatCount = d3.format(',');

  return (
    <BaseCard icon={iconTag(title, classes)}>
      <Typography className={classes.cardTitle}>{title}</Typography>
      <Box display="flex" alignItems="center">
        <Typography className={classes.cardValue}>
          {labelCount === undefined ? 0 : formatCount(labelCount)}
        </Typography>
      </Box>
    </BaseCard>
  );
};

export default TotalCard;
