import { Clear, ImageOutline, Slice } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { IAdvancedQuery, IQuery, ISelectedQuery } from '../../../../types/querySchemaTypes';
import { getAnnotationIcon } from '../views/grid/utils';

export default function QueryTypeMenu({
  hoverButtonList,
  boxNum,
  index,
  setHoverButtonList,
  list,
  deleteQuery,
  selectedQueryList,
  setSelectedQueryList,
  isSelected = false,
  updateQueryContent,
}: {
  hoverButtonList: string[];
  boxNum: number;
  index: number;
  setHoverButtonList: React.Dispatch<React.SetStateAction<string[]>>;
  list: IAdvancedQuery | IQuery;
  deleteQuery: (boxNum: number, boxIndex: number) => void;
  selectedQueryList: ISelectedQuery[];
  setSelectedQueryList: React.Dispatch<React.SetStateAction<ISelectedQuery[]>>;
  isSelected?: boolean;
  updateQueryContent: (boxNum: number, listIndex: number, selectedQuery: ISelectedQuery) => void;
}) {
  return (
    <DropdownMenu
      width={152}
      AnchorElement={
        <Button style={{ padding: 0, border: 'none' }} variant="text">
          {isSelected ? (
            <Box
              style={{
                padding: '5px 8px',
                background: '#FFFFFF',
                boxSizing: 'border-box',
                borderLeft: '1px solid #DCDCDC',
                borderTop: '1px solid #DCDCDC',
                borderBottom: '1px solid #DCDCDC',
                cursor: 'pointer',
              }}
            >
              <Typography>
                {selectedQueryList.filter(
                  list => list.boxNum === boxNum.toString() + (index + 1),
                )[0].queryType === 'slice'
                  ? `${
                      selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      )[0].queryType
                    }`
                  : `${
                      selectedQueryList.filter(
                        list => list.boxNum === boxNum.toString() + (index + 1),
                      )[0].queryType
                    }.`}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{
                background: '#FFFFFF',
                border: '1px solid #DCDCDC',
                borderRadius: '2px',
                maxHeight: '32px',
                minWidth: '104px',
                cursor: 'pointer',
              }}
              onMouseEnter={() => {
                if (!hoverButtonList.includes(boxNum.toString() + (index + 1)))
                  setHoverButtonList(() => [...hoverButtonList, boxNum.toString() + (index + 1)]);
              }}
              onMouseLeave={() => {
                const newHoverButtonList = hoverButtonList.filter(
                  list => list !== boxNum.toString() + (index + 1),
                );
                setHoverButtonList(() => newHoverButtonList);
              }}
            >
              <Box
                style={{
                  width: '80px',
                  padding: '5px 8px',
                  background: `${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? '#F5F5F5'
                      : '#FFFFFF'
                  }`,
                  boxSizing: 'border-box',
                  borderRight: `${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? '1px solid #DCDCDC'
                      : 'none'
                  }`,
                }}
              >
                <Typography
                  variant="m-regular"
                  color={`${
                    hoverButtonList.includes(boxNum.toString() + (index + 1))
                      ? 'gray-400'
                      : 'gray-300'
                  }`}
                >
                  Select...
                </Typography>
              </Box>
              <Box
                mx={0.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => deleteQuery(boxNum, list.boxIndex)}
                style={{ width: '24px', background: '#FFFFFF' }}
              >
                <Icon icon={Clear} color={'primary-400'} size="12px" />
              </Box>
            </Box>
          )}
        </Button>
      }
      placement="bottom-start"
    >
      <div
        style={{
          position: 'absolute',
          top: '-8px',
          background: '#fff',
          width: '100%',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
          borderRadius: '2px',
        }}
      >
        <DropdownMenuItem
          onClick={() => {
            const hasQuery = selectedQueryList.filter(
              list => list.boxNum === boxNum.toString() + (index + 1),
            );
            updateQueryContent(boxNum, index, {
              boxNum: boxNum.toString() + (index + 1),
              queryType: 'annotations',
            });
            if (hasQuery.length < 1)
              setSelectedQueryList(prev => [
                ...prev,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'annotations',
                },
              ]);
            else {
              const newQueryList = selectedQueryList.filter(
                list => list.boxNum !== boxNum.toString() + (index + 1),
              );

              setSelectedQueryList(() => [
                ...newQueryList,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'annotations',
                },
              ]);
            }
          }}
        >
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: '4px', display: 'flex' }}>
              <Icon icon={getAnnotationIcon('box')} />
            </span>
            <Typography variant="m-regular">annotations.</Typography>
          </Box>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            const hasQuery = selectedQueryList.filter(
              list => list.boxNum === boxNum.toString() + (index + 1),
            );

            updateQueryContent(boxNum, index, {
              boxNum: boxNum.toString() + (index + 1),
              queryType: 'images',
            });
            if (hasQuery.length < 1)
              setSelectedQueryList(prev => [
                ...prev,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'images',
                },
              ]);
            else {
              const newQueryList = selectedQueryList.filter(
                list => list.boxNum !== boxNum.toString() + (index + 1),
              );

              setSelectedQueryList(() => [
                ...newQueryList,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'images',
                },
              ]);
            }
          }}
        >
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: '4px', display: 'flex' }}>
              <Icon icon={ImageOutline} />
            </span>
            <Typography variant="m-regular">images.</Typography>
          </Box>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            const hasQuery = selectedQueryList.filter(
              list => list.boxNum === boxNum.toString() + (index + 1),
            );
            updateQueryContent(boxNum, index, {
              boxNum: boxNum.toString() + (index + 1),
              queryType: 'slice',
            });
            if (hasQuery.length < 1)
              setSelectedQueryList(prev => [
                ...prev,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'slice',
                  queryKey: '',
                  queryKeyType: 'String',
                },
              ]);
            else {
              const newQueryList = selectedQueryList.filter(
                list => list.boxNum !== boxNum.toString() + (index + 1),
              );

              setSelectedQueryList(() => [
                ...newQueryList,
                {
                  boxNum: boxNum.toString() + (index + 1),
                  queryType: 'slice',
                  queryKey: '',
                  queryKeyType: 'String',
                },
              ]);
            }
          }}
        >
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: '4px', display: 'flex' }}>
              <Icon icon={Slice} />
            </span>
            <Typography variant="m-regular">slice</Typography>
          </Box>
        </DropdownMenuItem>
      </div>
    </DropdownMenu>
  );
}
