import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, Chip, Icon, Typography } from '@superb-ai/norwegian-forest';

const os = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'MAC';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'IOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'WINDOWS';
  } else if (/Android/.test(userAgent)) {
    return 'ANDROID';
  } else if (/Linux/.test(platform)) {
    return 'LINUX';
  }
  return 'MAC';
};

function osControlKey() {
  if (['WINDOWS', 'ANDROID', 'LINUX'].includes(os())) {
    return 'Ctrl';
  } else {
    return '⌘';
  }
}

const Button = styled.header`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 30px;
  color: #adadad;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 0 10px;
  cursor: text;
  user-select: none;

  &:hover {
    border-color: #d4d4d4;
  }
`;

export default function CommandBarLauncher(
  props: React.ComponentProps<typeof Button>,
): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => {
        window?.CommandBar?.open();
      }}
      {...props}
    >
      <Icon name="search" />
      <Typography variant="body3">{t('commandMenu.findAnything')}</Typography>
      <Box ml="auto" display="flex" gap="4px">
        <Chip>{osControlKey()}</Chip>
        <Chip>K</Chip>
      </Box>
    </Button>
  );
}
