import { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { Box, NamedColor } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../../analyticsTracker';
import MathUtils from '../../../../../../../utils/MathUtils';
import { useQueryContext } from '../../../../../contexts/QueryContext';
import { getSearchQueryRoute } from '../../../../../utils/routeUtils';
import { ProgressChartDatum } from '../charts/PercentageBar';
import PercentageBarChart from '../charts/PercentageBarChart';
import { annotationsGTEQuery, appendQuery } from '../charts/utils';
import { ClassStatsDatum } from '../types';
import { getSuiteColor } from '../utils/colorScale';
import { ClassNameCell } from './ClassNameCell';
import { CountCell } from './CountCell';
import { getHeader } from './getHeader';

interface ClassStatsTableRowProps {
  datum: ClassStatsDatum;
  totalImages: number;
  totalAnnotations: number;
  comparedDatum?: ClassStatsDatum;
  isCompareMode: boolean;
  totalComparedImages?: number;
  totalComparedAnnotations?: number;
  excludeImageInfo?: boolean;
  headers: ReturnType<typeof getHeader>;
}

export function ClassStatsTableRow({
  datum,
  totalImages,
  totalAnnotations,
  isCompareMode,
  comparedDatum,
  totalComparedImages,
  totalComparedAnnotations,
  excludeImageInfo,
  headers,
}: ClassStatsTableRowProps) {
  const { accountName } = useParams<{ accountName: string }>();
  const { queryString, setQueryString } = useQueryContext();
  const history = useHistory();
  const [hoverOnRow, setHoverOnRow] = useState(false);
  const share = MathUtils.calculatePercent({
    numerator: datum?.annotationCount,
    denominator: totalAnnotations,
  });

  const renderPercentageBarChart = (chartDatum: ProgressChartDatum[]) => (
    <PercentageBarChart showLegend={false} chartDatum={chartDatum} />
  );

  // const renderTypography = (text: string) => <Typography variant="m-medium">{text}</Typography>;

  const trackChartClick = () => {
    analyticsTracker.chartClicked({
      accountId: accountName,
      chartName: 'class-distribution',
      feature: 'curate-analytics',
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      style={{ minHeight: '34px' }}
      onMouseEnter={() => setHoverOnRow(true)}
      onMouseLeave={() => setHoverOnRow(false)}
      cursor="pointer"
      p={1}
      backgroundColor={hoverOnRow ? 'gray-opacity-100' : undefined}
      onClick={() => {
        const query = appendQuery(annotationsGTEQuery(datum?.name, 1), queryString);
        setQueryString(query);
        history.push(getSearchQueryRoute(history, { query }));
        trackChartClick();
      }}
    >
      <Box display="flex" alignItems="center" style={{ width: headers[0]?.width }} gap={0.5}>
        <ClassNameCell className={datum?.name} />
      </Box>
      <Box style={{ flex: 1 }} display="flex" flexDirection="column" gap={0.5}>
        {renderPercentageBarChart([
          {
            key: datum?.name + '-annotations',
            name: datum?.name,
            total: totalAnnotations,
            value: datum?.annotationCount,
            share: datum?.annotationShare || share,
            barColor: getSuiteColor('objects') as NamedColor,
          },
        ])}
        {isCompareMode &&
          renderPercentageBarChart([
            {
              key: datum?.name + '-compared-annotations',
              name: datum?.name,
              total: totalComparedAnnotations || 0,
              value: comparedDatum?.annotationCount || 0,
              share: comparedDatum?.annotationShare,
              barColor: getSuiteColor('objectsAll'),
            },
          ])}
      </Box>
      <CountCell
        width={headers[2].width as string}
        count={datum?.annotationShare || 0}
        {...(isCompareMode ? { comparedCount: comparedDatum?.annotationShare || 0 } : {})}
      />
      <CountCell
        width={headers[3].width as string}
        count={datum.annotationCount}
        {...(isCompareMode ? { comparedCount: comparedDatum?.annotationCount || 0 } : {})}
      />
      {!excludeImageInfo && (
        <>
          <Box style={{ flex: 1 }} display="flex" flexDirection="column" gap={0.5}>
            <PercentageBarChart
              showLegend={false}
              chartDatum={[
                {
                  key: datum?.name + '-images',
                  name: datum?.name,
                  total: totalImages,
                  value: datum?.imageCount,
                  share: datum?.imageShare,
                  barColor: getSuiteColor('objects') as NamedColor,
                },
              ]}
            />
            {isCompareMode && (
              <PercentageBarChart
                showLegend={false}
                chartDatum={[
                  {
                    key: datum?.name + '-images',
                    name: datum?.name,
                    total: totalImages,
                    value: datum?.imageCount,
                    share: datum?.imageShare,
                    barColor: getSuiteColor('objectsAll') as NamedColor,
                  },
                  // { key: 'metadata_name-compare', total: 100, value: 10, barColor: 'red-500' },
                ]}
              />
            )}
          </Box>
          <CountCell
            width={headers[5].width as string}
            count={datum?.imageShare || 0}
            {...(isCompareMode ? { comparedCount: comparedDatum?.imageShare || 0 } : {})}
          />
          <CountCell
            width={headers[6].width as string}
            count={datum.imageCount}
            {...(isCompareMode ? { comparedCount: comparedDatum?.imageCount || 0 } : {})}
          />
        </>
      )}
    </Box>
  );
}
