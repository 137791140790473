import { Settings } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import { isSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu/MenuItem';
import { AccessMenuItem } from './access/MenuItem';
import { BillingMenuItem } from './billing/MenuItem';
import Layout from './Layout';
import { ProfileMenuItem } from './profile/MenuItem';
import { SecurityMenuItem } from './security/MenuItem';

export const SettingsMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountSettings,
  path: 'settings',
  group: 'account',
  title: ({ t }) => t('settings.title'),
  component: Layout,
  icon: <Icon icon={Settings} />,
  children: [BillingMenuItem, AccessMenuItem, ProfileMenuItem, SecurityMenuItem],
  isEnabled({ authInfo }) {
    return !authInfo?.isGuest && !isSystemAdmin(authInfo);
  },
  isVisible({ authInfo }) {
    return !isSystemAdmin(authInfo);
  },
});
