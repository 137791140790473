import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '@superb-ai/icons';
import { Box, Icon, Input } from '@superb-ai/ui';

export default function DropdownSearchInput({
  setSearchValue,
  placeholder,
}: {
  setSearchValue: (value: string) => void;
  placeholder?: string;
}) {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      setSearchValue('');
    };
  }, []);

  return (
    <Box p={0.5} borderBottom="1px solid" borderColor="gray-100" display="flex">
      <Input
        type="search"
        variant="text"
        prefix={<Icon icon={Search} />}
        placeholder={placeholder || t('text.search')}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(event.target.value);
        }}
        style={{ flex: 1 }}
      />
    </Box>
  );
}
