import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Icon, Link, Modal, Typography } from '@superb-ai/norwegian-forest';
import { useMutation, useQueries } from '@tanstack/react-query';
import { isEmpty, omit } from 'lodash';

import AnalyticsTracker from '../../../../analyticsTracker';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useLabelCommandContext } from '../../../../contexts/LabelCommandContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { getUrl } from '../../../../routes/util';
import CommandsService from '../../../../services/CommandsService';
import LabelsService from '../../../../services/LabelsService';
import { CurrentAutoLabelConfig } from './CurrentAutoLabelConfig';

interface DialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  checkedLabels: any[];
  setCheckedLabels: Dispatch<SetStateAction<any[]>>;
  setIsAllLabelsChecked: Dispatch<SetStateAction<boolean>>;
  totalCount: number;
  isAllLabelsChecked: boolean;
  filterApiParams: any;
}

const AutoLabelDialog: React.FC<DialogProps> = props => {
  const { t } = useTranslation();
  const {
    isOpen,
    setIsOpen,
    checkedLabels,
    totalCount,
    isAllLabelsChecked,
    setCheckedLabels,
    setIsAllLabelsChecked,
    filterApiParams,
  } = props;

  const commandContext = useLabelCommandContext();

  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  const getFilterParamRaw = () => {
    if (!isEmpty(checkedLabels) && !isAllLabelsChecked) {
      return { idIn: checkedLabels };
    }
    return omit(filterApiParams, ['ordering', 'page', 'pageSize']);
  };

  const checkedLabelCount = isAllLabelsChecked ? totalCount : checkedLabels.length;

  const { mutate: startAutoLabel, isLoading } = useMutation(
    async () => {
      const response = await (enabledLoki
        ? CommandsService.createCommandV2
        : CommandsService.createCommand)({
        type: 'LABELS_AUTO_LABEL',
        projectId: routeInfo.urlMatchInfo.projectId,
        params: getFilterParamRaw(),
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      commandContext.registerCommand(response.data.id);
    },
    {
      onSuccess() {
        AnalyticsTracker.autoLabelInitiated({
          accountId: authInfo.accountName ?? '',
          projectId: routeInfo.urlMatchInfo.projectId,
          labelCount: checkedLabelCount,
        });
        setIsAllLabelsChecked(false);
        setCheckedLabels([]);
        setIsOpen(false);
      },
    },
  );

  const handleClickMessageClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      mainButton={{
        text: `${t('button.apply')} (${t('labels.labelCount', { count: checkedLabelCount })})`,
        onClick: () => startAutoLabel(),
        isLoading,
      }}
      close={{
        onClose: handleClickMessageClose,
        hasCloseButton: true,
        canCloseWithExit: true,
      }}
      subButton={{ text: t('button.cancel'), onClick: handleClickMessageClose }}
      open={isOpen}
      title={t('labels.dialogs.autoLabel.title')}
      size="s"
    >
      <Box mx={4} mt={3} display="flex" flexDirection="column" gap="16px">
        <SelectionWarning filters={getFilterParamRaw()} />
        <Typography variant="body3">
          <Trans t={t} i18nKey="labels.dialogs.autoLabel.text" />
        </Typography>
        <Box mt={3} display="flex" alignItems="center">
          <Typography variant="headline7">
            {t('labels.dialogs.autoLabel.currentConfiguration')}
          </Typography>
          <Box ml="auto" display="flex" alignItems="center">
            <Link
              to={getUrl([
                routeInfo.urlMatchInfo.accountName,
                'project',
                routeInfo.urlMatchInfo.projectId,
                'auto-label',
                'settings',
              ])}
              onClick={handleClickMessageClose}
              variant="body4"
              themedColor="secondary"
              underlined
            >
              {t('labels.dialogs.autoLabel.changeConfiguration')}
            </Link>
          </Box>
        </Box>
        <Box themedBackgroundColor={['grey', 50]} mx={-2}>
          <CurrentAutoLabelConfig labelInterface={project.labelInterface} />
        </Box>
      </Box>
    </Modal>
  );
};

export default AutoLabelDialog;

function SelectionWarning({ filters }: { filters: Record<string, any> }) {
  const { t } = useTranslation();
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;
  const labels = ['submitted', 'approved'];
  const queryParams = [
    {
      ...filters,
      statusIn: ['SUBMITTED'],
    },
    {
      ...filters,
      reviewIn: ['APPROVE'],
    },
  ];
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const queries = useQueries({
    queries: queryParams.map(params => ({
      queryKey: ['labelCount', params],
      queryFn: () =>
        (enabledLoki ? LabelsService.getLabelCountsV2 : LabelsService.getLabelCounts)({
          params,
          projectId: routeInfo.urlMatchInfo.projectId,
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        }),
      staleTime: 1000,
    })),
  });
  const warningDisabled =
    (filters?.statusIn && !filters.statusIn.includes('SUBMITTED')) ||
    (filters?.reviewIn && !filters.reviewIn.includes('APPROVE'));

  if (queries.some(({ isLoading }) => isLoading)) return <></>;

  const texts = queries.flatMap(({ data }, index) =>
    !data ? [] : `${data} ${t(`labels.status.${labels[index]}`)}`,
  );
  if (!texts.length || warningDisabled) return <></>;

  return (
    <Box themedBackgroundColor={['primary', 50]} p={1} display="flex" gap="8px">
      <Icon name="warningOutline" color="primary" size="16px" />
      <Box>
        <Typography variant="body3">{t('labels.yourSelectionIncludes')}</Typography>
        <Typography as="ul" variant="bold3" themedColor="primary" style={{ margin: '0 0 0 16px' }}>
          {texts.map((text, idx) => (
            <li key={idx}>{text}</li>
          ))}
        </Typography>
      </Box>
    </Box>
  );
}
