import React from 'react';

import { Icon } from '@superb-ai/norwegian-forest';

import { ANNOTATION_TYPE } from './NewProjectConst';

export type DataTypeGroup = 'image' | 'video' | 'lidar';

const annotationIcons = {
  box: <Icon name="box" />,
  cuboid: <Icon name="cuboid" />,
  cuboid2d: <Icon name="cuboid" />,
  rotatedBox: <Icon name="rotatedBox" />,
  polyline: <Icon name="polyline" />,
  polygonSegmentation: <Icon name="polygon" />,
  keypoint: <Icon name="keypoints" />,
  imageCategory: <Icon name="imageCategory" />,

  tiltedbox: <Icon name="rotatedBox" />,

  BOX: <Icon name="box" />,
  CUBOID: <Icon name="cuboid" />,
  CUBOID2D: <Icon name="cuboid" />,
  RBOX: <Icon name="rotatedBox" />,
  ROTATED_BOX: <Icon name="rotatedBox" />,
  POLYLINE: <Icon name="polyline" />,
  POLYGON: <Icon name="polygon" />,
  KEYPOINT: <Icon name="keypoints" />,
  'IMAGE CATEGORY': <Icon name="imageCategory" />,
  CATEGORY: <Icon name="imageCategory" />,
};

const dataTypes = (featureFlagLidar: boolean): Record<string, any> => {
  const originalDataTypes = {
    'image beta': {
      type: 'image beta' as const,
      group: 'image' as const,
      workapp: 'image-siesta',
      label: 'image',
      isAvailable: true,
      icon: 'imageOutline',
      annotations: [
        {
          type: ANNOTATION_TYPE.BOX.value,
          label: ANNOTATION_TYPE.BOX.label,
          img: annotationIcons.box,
        },
        {
          type: ANNOTATION_TYPE.ROTATED_BOX.value,
          label: ANNOTATION_TYPE.ROTATED_BOX.label,
          img: annotationIcons.rotatedBox,
        },
        {
          type: ANNOTATION_TYPE.POLYLINE.value,
          label: ANNOTATION_TYPE.POLYLINE.label,
          img: annotationIcons.polyline,
        },
        {
          type: ANNOTATION_TYPE.POLYGON.value,
          label: ANNOTATION_TYPE.POLYGON.label,
          img: annotationIcons.polygonSegmentation,
        },
        {
          type: ANNOTATION_TYPE.KEYPOINT.value,
          label: ANNOTATION_TYPE.KEYPOINT.label,
          img: annotationIcons.keypoint,
        },
        {
          type: ANNOTATION_TYPE.CUBOID2D.value,
          label: ANNOTATION_TYPE.CUBOID2D.label,
          img: annotationIcons.cuboid2d,
        },
        {
          type: ANNOTATION_TYPE.IMAGE_CATEGORY.value,
          label: ANNOTATION_TYPE.IMAGE_CATEGORY.label,
          img: annotationIcons.imageCategory,
        },
      ],
    },
    'image sequence': {
      type: 'image sequence' as const,
      group: 'video' as const,
      workapp: 'video-siesta',
      label: 'video',
      isAvailable: true,
      icon: 'playCircleOutline',
      annotations: [
        {
          type: ANNOTATION_TYPE.BOX.value,
          label: ANNOTATION_TYPE.BOX.label,
          img: annotationIcons.box,
        },
        {
          type: ANNOTATION_TYPE.ROTATED_BOX.value,
          label: ANNOTATION_TYPE.ROTATED_BOX.label,
          img: annotationIcons.rotatedBox,
        },
        {
          type: ANNOTATION_TYPE.POLYLINE.value,
          label: ANNOTATION_TYPE.POLYLINE.label,
          img: annotationIcons.polyline,
        },
        {
          type: ANNOTATION_TYPE.POLYGON.value,
          label: ANNOTATION_TYPE.POLYGON.label,
          img: annotationIcons.polygonSegmentation,
        },
        {
          type: ANNOTATION_TYPE.KEYPOINT.value,
          label: ANNOTATION_TYPE.KEYPOINT.label,
          img: annotationIcons.keypoint,
        },
        {
          type: ANNOTATION_TYPE.CUBOID2D.value,
          label: ANNOTATION_TYPE.CUBOID2D.label,
          img: annotationIcons.cuboid2d,
        },
        {
          type: ANNOTATION_TYPE.IMAGE_CATEGORY.value,
          label: ANNOTATION_TYPE.IMAGE_CATEGORY.label,
          img: annotationIcons.imageCategory,
        },
      ],
    },
  };

  return featureFlagLidar
    ? {
        ...originalDataTypes,
        pointclouds: {
          type: 'pointclouds' as const,
          group: 'lidar' as const,
          workapp: 'pointclouds-siesta',
          label: 'point cloud sequence',
          isAvailable: true,
          icon: 'lidar',
          annotations: [
            {
              type: ANNOTATION_TYPE.CUBOID.value,
              label: ANNOTATION_TYPE.CUBOID.label,
              img: annotationIcons.cuboid,
            },
          ],
        },
      }
    : originalDataTypes;
};

const COLOR_CHIP_PALETTE = [
  '#FF625A',
  '#FE9573',
  '#FFAF5A',
  '#FFCC00',
  '#FFF73E',
  '#DEF00F',
  '#A3EB57',
  '#2EFF68',
  '#4AE2B9',
  '#72BBFF',
  '#39F8F8',
  '#72BBFF',
  '#5A7BFF',
  '#6648FF',
  '#AF48FF',
  '#FF4881',
  '#A6A6A6',
];

const ADDITIONAL_COLOR_CHIP_PALETTE = [
  '#FFD0CE',
  '#FFDFD5',
  '#FFE7CE',
  '#FFF0B3',
  '#FFFB9E',
  '#F5FBB7',
  '#E3F9CD',
  '#B8FFD0',
  '#C9F6EA',
  '#AFFFFF',
  '#D5EBFF',
  '#CED7FF',
  '#D1C8FF',
  '#E7C8FF',
  '#FFC8D9',
  '#F3F3F3',
  '#EE0A00',
  '#EB5222',
  '#EA7700',
  '#EFC000',
  '#ECE30D',
  '#CCDE0C',
  '#76C923',
  '#00C742',
  '#00B283',
  '#00CECE',
  '#0073DB',
  '#0028DB',
  '#1D00B2',
  '#560099',
  '#CF0040',
  '#7B7B7B',
];

const CAN_USE_AUTO_LABEL = {
  // image
  BOX: true,
  POLYGON: true,
  KEYPOINT: true,
  // point clouds
  CUBOID: true,
};

const AUTO_LABEL_TYPES = [
  // image
  'box',
  'polygon',
  'keypoint',
  // point clouds
  'cuboid',
];

export default {
  annotationIcons,
  dataTypes,
  COLOR_CHIP_PALETTE,
  ADDITIONAL_COLOR_CHIP_PALETTE,
  CAN_USE_AUTO_LABEL,
  AUTO_LABEL_TYPES,
};
