import { produce } from 'immer';
import { cloneDeep, upperFirst } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ProjectSteps } from '../../../../configs/NewProjectStepsConfig';
import {
  initialFreeResponse,
  initialMultipleChoice,
  initialMultipleSelection,
} from '../../../../consts/NewProjectConst';
import ProjectConst from '../../../../consts/ProjectConst';
import ProjectUtils from '../../../../utils/ProjectUtils';

// eslint-disable-next-line
const updateObjectClasses = (newProjectInfo: any): void => {
  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);

  if (nextObjectClasses.length === 0) {
    const types = Object.keys(newProjectInfo.selectedAnnotationTypes);
    const type =
      types.filter((item: string) => item !== 'KEYPOINT' && item !== 'IMAGE_CATEGORY')[0] ||
      'SELECT_TYPE';

    const tempObjectClass = {
      id: uuidv4(),
      name: 'Untitled Object Class',
      annotationType: type,
      color: '#FF625A',
      properties: [],
      minCount: '',
      maxCount: '',
    };
    nextObjectClasses.push(tempObjectClass);
  } else {
    for (let i = 0; i < nextObjectClasses.length; i++) {
      const { annotationType } = nextObjectClasses[i];
      if (annotationType === 'SELECT_TYPE') {
        continue;
      } else if (!newProjectInfo.selectedAnnotationTypes[annotationType]) {
        if (annotationType === 'KEYPOINT') {
          delete nextObjectClasses[i].keypointInfo;
        }
        nextObjectClasses[i].annotationType = 'SELECT_TYPE';
      }
    }
  }

  // workapp 타입이 바뀌었을 때, template을 초기화 하기 위해 사용
  nextObjectClasses.map((objectClass: any) => {
    if (objectClass.keypointInfo) {
      if (newProjectInfo.selectedWorkapp !== objectClass.keypointInfo.workapp) {
        // eslint-disable-next-line no-param-reassign
        delete objectClass.keypointInfo;
        // eslint-disable-next-line no-param-reassign
        objectClass.annotationType = 'SELECT_TYPE';
      }
    }

    return objectClass;
  });

  newProjectInfo.setObjectClasses(nextObjectClasses);
};

// eslint-disable-next-line
const createObjectClass = (newProjectInfo: any): void => {
  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  const id = uuidv4();

  const curSelectedAnnotationTypes = cloneDeep(newProjectInfo.selectedAnnotationTypes);

  if (curSelectedAnnotationTypes.IMAGE_CATEGORY) delete curSelectedAnnotationTypes.IMAGE_CATEGORY;

  const curSelectedAnnotationTypesArr = Object.keys(curSelectedAnnotationTypes);

  const getColor = () => {
    const paletteTotalCount =
      ProjectConst.COLOR_CHIP_PALETTE.length + ProjectConst.ADDITIONAL_COLOR_CHIP_PALETTE.length;
    const index = nextObjectClasses.length % paletteTotalCount;

    return index < ProjectConst.COLOR_CHIP_PALETTE.length
      ? ProjectConst.COLOR_CHIP_PALETTE[index]
      : ProjectConst.ADDITIONAL_COLOR_CHIP_PALETTE[index - ProjectConst.COLOR_CHIP_PALETTE.length];
  };

  nextObjectClasses.push({
    id,
    name: ProjectUtils.getNewName('Untitled Object Class', nextObjectClasses),
    annotationType:
      curSelectedAnnotationTypesArr.length === 1 && curSelectedAnnotationTypesArr[0] !== 'KEYPOINT'
        ? curSelectedAnnotationTypesArr[0]
        : 'SELECT_TYPE',
    color: getColor(),
    properties: [],
    minCount: '',
    maxCount: '',
  });

  newProjectInfo.setSelectedObjectClassIndex(nextObjectClasses.length - 1);
  newProjectInfo.setObjectClasses(nextObjectClasses);
  newProjectInfo.setCreateState('OBJECT_CLASS');
};

// eslint-disable-next-line
const copyObjectClass = (newProjectInfo: any): void => {
  const index = newProjectInfo.selectedObjectClassIndex;
  if (!newProjectInfo.objectClasses[index]) return;

  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  const fromObjectClass = nextObjectClasses[index];

  const id = uuidv4();
  nextObjectClasses.splice(index + 1, 0, {
    ...fromObjectClass,
    properties: cloneDeep(fromObjectClass.properties),
    id,
    name: ProjectUtils.getNewName(fromObjectClass.name, nextObjectClasses),
  });

  newProjectInfo.setObjectClasses(nextObjectClasses);
  newProjectInfo.setCreateState('OBJECT_CLASS');
};

// eslint-disable-next-line
const deleteObjectClass = (newProjectInfo: any): void => {
  const index = newProjectInfo.selectedObjectClassIndex;
  if (!newProjectInfo.objectClasses[index]) return;

  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  nextObjectClasses.splice(index, 1);

  if (newProjectInfo.objectClasses.length - 1 === index) {
    if (index === 0) {
      newProjectInfo.setSelectedObjectClassIndex(-1);
    } else {
      newProjectInfo.setSelectedObjectClassIndex(index - 1);
    }
  }

  newProjectInfo.setObjectClasses(nextObjectClasses);
};

// eslint-disable-next-line
const createClassProperty = (newProjectInfo: any): void => {
  const index = newProjectInfo.selectedObjectClassIndex;
  if (!newProjectInfo.objectClasses[index]) return;
  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  const { properties } = nextObjectClasses[index];

  const id = uuidv4();
  properties.push({
    id,
    name: ProjectUtils.getNewName(`${nextObjectClasses[index].name} Property`, properties),
    required: true,
    perFrame: false,
    type: 'MULTIPLE_CHOICE', // MULTIPLE_CHOICE, MULTIPLE_SELECTION, FREE_RESPONSE
    options: {
      MULTIPLE_CHOICE: initialMultipleChoice(),
      MULTIPLE_SELECTION: initialMultipleSelection(),
      FREE_RESPONSE: initialFreeResponse,
    },
  });

  newProjectInfo.setObjectClasses(nextObjectClasses);
  newProjectInfo.setSelectedClassPropertyIndex(properties.length - 1);
  newProjectInfo.setCreateState(ProjectSteps.CLASS_PROPERTY);
};

// eslint-disable-next-line
const copyClassProperty = (newProjectInfo: any): void => {
  const { selectedObjectClassIndex, selectedClassPropertyIndex } = newProjectInfo;

  if (
    !newProjectInfo.objectClasses[selectedObjectClassIndex] ||
    !newProjectInfo.objectClasses[selectedObjectClassIndex].properties[selectedClassPropertyIndex]
  )
    return;

  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  const { properties } = nextObjectClasses[selectedObjectClassIndex];
  const copiedClassProperty = cloneDeep(properties[selectedClassPropertyIndex]);

  copiedClassProperty.id = uuidv4();
  copiedClassProperty.name = ProjectUtils.getNewName(copiedClassProperty.name, properties);

  const { options } = copiedClassProperty;
  const multipleChoiceOptions = options.MULTIPLE_CHOICE;
  const multipleSelectionOptions = options.MULTIPLE_SELECTION;

  const ids = [];
  for (let i = 0; i < multipleChoiceOptions.length; i++) {
    ids.push(uuidv4());
    multipleChoiceOptions[i].id = ids[i];
  }

  for (let i = 0; i < multipleSelectionOptions.length; i++) {
    multipleSelectionOptions[i].id = ids[i];
  }
  options.FREE_RESPONSE.id = uuidv4();

  properties.splice(selectedClassPropertyIndex + 1, 0, copiedClassProperty);
  newProjectInfo.setObjectClasses(nextObjectClasses);
  newProjectInfo.setCreateState(ProjectSteps.CLASS_PROPERTY);
};

// eslint-disable-next-line
const deleteClassProperty = (newProjectInfo: any): void => {
  const { selectedObjectClassIndex, selectedClassPropertyIndex } = newProjectInfo;

  if (
    !newProjectInfo.objectClasses[selectedObjectClassIndex] ||
    !newProjectInfo.objectClasses[selectedObjectClassIndex].properties[selectedClassPropertyIndex]
  )
    return;

  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  nextObjectClasses[selectedObjectClassIndex].properties.splice(selectedClassPropertyIndex, 1);

  if (
    newProjectInfo.objectClasses[selectedObjectClassIndex].properties.length - 1 ===
    selectedClassPropertyIndex
  ) {
    if (selectedClassPropertyIndex === 0) {
      newProjectInfo.setSelectedClassPropertyIndex(-1);
    } else {
      newProjectInfo.setSelectedClassPropertyIndex(selectedClassPropertyIndex - 1);
    }
  }

  newProjectInfo.setObjectClasses(nextObjectClasses);
};

// eslint-disable-next-line
const changeAnnotationCountRange = (
  newProjectInfo: any,
  min: number | '',
  max: number | '',
): void => {
  const index = newProjectInfo.selectedObjectClassIndex;
  if (!newProjectInfo.objectClasses[index]) return;
  const nextObjectClasses = cloneDeep(newProjectInfo.objectClasses);
  nextObjectClasses[index].minCount = min;
  nextObjectClasses[index].maxCount = max;
  newProjectInfo.setObjectClasses(nextObjectClasses);
};

// eslint-disable-next-line
const parseKeypointTemplateName = (template: any): string => {
  const length =
    template?.keypointDef?.points?.length ||
    template?.keypointDetection?.points?.length ||
    template?.points?.length ||
    0;

  return `${template.name
    .split(' ')
    .map((a: any) => upperFirst(a))
    .join(' ')} template : ${length} points`;
};

// eslint-disable-next-line
const changeDefaultSize = (newProjectInfo: any, defaultSize: any): void => {
  const index = newProjectInfo.selectedObjectClassIndex;
  if (!newProjectInfo.objectClasses[index]) return;

  const nextObjectClasses = produce(newProjectInfo.objectClasses, (draft: any) => {
    const objectClass = draft[index];

    objectClass.config = {
      defaultSize,
    };
  });
  newProjectInfo.setObjectClasses(nextObjectClasses);
};

export default {
  updateObjectClasses,
  createObjectClass,
  copyObjectClass,
  deleteObjectClass,
  createClassProperty,
  copyClassProperty,
  deleteClassProperty,
  changeAnnotationCountRange,
  parseKeypointTemplateName,
  changeDefaultSize,
};
