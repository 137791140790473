import { SVGProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Key, Users, WindowTerminal } from '@superb-ai/icons';
import { Box, Icon, LinkTypography, Typography } from '@superb-ai/ui';

import { getUserManualUrl } from '../../../../consts/DocsLink';

type Section = 'Description' | 'Personal Access Key' | 'Team Access Key';

const IconBySection: Record<Section, (props: SVGProps<SVGSVGElement>) => JSX.Element> = {
  Description: WindowTerminal,
  'Personal Access Key': Key,
  'Team Access Key': Users,
} as const;

const TransBySection: Record<Section, string> = {
  Description: 'settings.advanced.description',
  'Personal Access Key': 'settings.advanced.personalAccessKeyDescription',
  'Team Access Key': 'settings.advanced.teamAccessKeyDescription',
};

export default function SectionContent({ type }: { type: Section }) {
  const { i18n, t } = useTranslation();
  const { SDK_INTRO_MANUAL } = getUserManualUrl(i18n.language);

  const ApiManualLink = SDK_INTRO_MANUAL;

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="100%"
        style={{ width: '40px', height: '40px' }}
        backgroundColor="gray-100"
      >
        <Icon icon={IconBySection[type]} size={16} />
      </Box>
      <Typography variant="m-regular">
        <Trans
          t={t}
          i18nKey={TransBySection[type]}
          components={{
            bold: <Typography variant="m-medium" />,
            apiLink: (
              <LinkTypography
                as="a"
                href={ApiManualLink}
                target="_blank"
                variant="m-regular"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
}
