import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Check } from '@superb-ai/icons';
import { DropdownList, Modal } from '@superb-ai/norwegian-forest';
import { Box, Icon, Input, Typography } from '@superb-ai/ui';
import { addDays, endOfDay } from 'date-fns';

import AnalyticsTracker from '../../../../../analyticsTracker';
import {
  useUserApiKeyCreateMutation,
  useUserApiKeyUpdateMutation,
} from '../../../../../queries/userAccessKey';
import { formatDate, parseDate } from '../../../../../utils/date';
import { date2ISOUTC } from '../../../../../utils/filter/labelFilter';
import DatePicker from '../../../../elements/datePicker/DatePicker';
import PersonalAccessKeyWarning from './PersonalAccessKeyWraning';

export interface DialogData {
  name: string;
  expirationDate: string | null;
  apiKeyId?: string;
}

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  type: 'create' | 'edit' | null;
  data?: DialogData;
  context?: 'User Keys' | 'Manage Members';
}

const DatePresets = ['7 days', '30 days', '60 days', 'Custom', 'No expiration'] as const;
type DatePreset = (typeof DatePresets)[number];

export default function PersonalAccessKeyDialog({
  handleClose,
  isOpen,
  type,
  data,
  context,
}: Props) {
  const datePresets = type === 'create' ? DatePresets : (['Custom'] as const);
  const { t } = useTranslation();
  const { accountName } = useParams<{ accountName: string }>();
  const [name, setName] = useState<string>(data?.name ?? '');
  const [option, setOption] = useState<DatePreset>(datePresets[0]);
  const [expirationDate, setExpirationDate] = useState<Date | null>(
    data?.expirationDate ? parseDate(data.expirationDate) : null,
  );

  const { mutate: createKey, isLoading: isCreating } = useUserApiKeyCreateMutation();
  const { mutate: updateKey, isLoading: isUpdating } = useUserApiKeyUpdateMutation();

  useEffect(() => {
    if (!isOpen) {
      setName('');
      setOption(datePresets[0]);
      setExpirationDate(null);
    }
  }, [isOpen]);

  useEffect(() => {
    setName(data?.name ?? '');
    setExpirationDate(data?.expirationDate ? parseDate(data.expirationDate) : null);
  }, [data]);

  useEffect(() => {
    switch (option) {
      case 'Custom':
        setExpirationDate(
          data?.expirationDate ? parseDate(data.expirationDate) : endOfDay(new Date()),
        );
        break;
      case 'No expiration':
        setExpirationDate(null);
        break;
      default:
        setExpirationDate(endOfDay(addDays(new Date(), Number(option.split(' ')[0]))));
        break;
    }
  }, [option]);

  useEffect(() => {
    setOption(datePresets[0]);
  }, [type]);

  function reset() {
    setName('');
    setOption(datePresets[0]);
  }

  async function handleSubmit() {
    const changeData = {
      name,
      expirationDate: expirationDate ? date2ISOUTC(expirationDate) : null,
    };
    if (type === 'create') {
      createKey(changeData, {
        onSuccess() {
          AnalyticsTracker.personalAccessKeyCreated({
            expiry: option,
            accountId: accountName,
          });
          handleClose();
          reset();
        },
      });
    } else {
      if (!data?.apiKeyId) return;
      updateKey(
        {
          ...changeData,
          apiKeyId: data.apiKeyId,
        },
        {
          onSuccess() {
            handleClose();
            reset();
          },
        },
      );
    }
  }

  const getLabel = (option: DatePreset) => {
    const labelByOption: Partial<Record<DatePreset, string>> = {
      Custom: t('settings.advanced.custom'),
      'No expiration': t('settings.advanced.noExpiration'),
    };

    return labelByOption[option] ?? option;
  };

  const getDropdownContent = (): ReactNode => {
    switch (option) {
      case 'No expiration':
        return null;
      case 'Custom':
        return (
          <Box
            display="flex"
            alignItems="center"
            style={{ height: '32px' }}
            border="1px solid"
            borderColor="gray-200"
          >
            <DatePicker
              value={expirationDate}
              onChangeCalendarDate={date =>
                setExpirationDate(endOfDay(date) ?? endOfDay(new Date()))
              }
            />
          </Box>
        );
      default:
        return (
          <Box display="flex" gap={1} alignItems="center">
            <Icon icon={Check} color="green-400" size={12} />
            <Typography variant="s-regular">
              {expirationDate && (
                <Trans
                  i18nKey="settings.advanced.tokenWillExpireOn"
                  values={{ date: formatDate(expirationDate) }}
                  components={{ bold: <Typography variant="s-strong" /> }}
                />
              )}
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Modal
      open={isOpen}
      title={
        <Typography variant="h2" color="primary">
          {type === 'create'
            ? t('settings.advanced.createDialogTitle')
            : t('settings.advanced.editDialogTitle')}
        </Typography>
      }
      mainButton={{
        text: type === 'create' ? t('button.generate') : t('bulkActions.status.done'),
        onClick: handleSubmit,
        color: 'primary',
        disabled: !name,
        isLoading: isCreating || isUpdating,
      }}
      subButton={{
        text: t('button.cancel'),
        onClick: handleClose,
      }}
      close={{
        onClose: handleClose,
        canCloseWithExit: true,
        hasCloseButton: true,
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} mx={4} mt={2} style={{ width: '456px' }}>
        {type === 'create' && <PersonalAccessKeyWarning />}
        <Box width="100%" display="flex" flexDirection="column" gap={1}>
          <Typography variant="m-medium">{t('users.table.name')}</Typography>
          <Input value={name} onChange={e => setName(e.target.value)} type="text" color="primary" />
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap={1}>
          <Typography variant="m-medium">{t('settings.advanced.expiryDate')}</Typography>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" style={{ width: '170px' }}>
              <DropdownList
                value={option}
                options={datePresets.map(v => ({ value: v, label: getLabel(v) }))}
                onChange={v => setOption(v as DatePreset)}
              />
            </Box>
            {getDropdownContent()}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
