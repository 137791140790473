import { ImageQuery } from '../services/SemanticSearchService';
import {
  AdvancedAIFeaturesProgress,
  AdvancedAIFeaturesStatus,
  GeoJSONPolygon,
} from './advancedAIFeaturesTypes';
import { CurationType } from './curationTypes';
import { ObjectPropertiesAndCategoriesType } from './projectTypes';

type MislabelDetectionSet = { id: string; name: string | null; labelCount: number };

export type MislabelDetection = {
  config?: Record<string, any>;
  createdAt: string;
  createdBy: string;
  id: string;
  name: string;
  querySet: MislabelDetectionSet;
  referenceSet: MislabelDetectionSet;
  status: AdvancedAIFeaturesStatus;
  progress: AdvancedAIFeaturesProgress;
  resultUrl: string;
  reportUrl: string;
  imageQueries?: ImageQuery[];
  textQueries?: string[];
  curationType?: CurationType;
  labelCount?: number;
  filter?: string;
};

type Box = 'box';
type Polygon = 'polygon';

export type MislabelDetectionAnnotationType = Box | Polygon;

export type MislabelDetectionAnnotationTypeCoordinate<T> = T extends Box
  ? number[]
  : GeoJSONPolygon;

export type MislabelDetectionResult<T extends MislabelDetectionAnnotationType> = {
  annotation_id: string;
  label_id: string;
  index: number;
  bbox: number[]; // DEPRECATED
  annot_ori: MislabelDetectionAnnotationTypeCoordinate<T>; // original image - box or polygon
  annot_new: MislabelDetectionAnnotationTypeCoordinate<T>; // thumbnail image - box or polygon
  mislabel_rank: number;
  mislabel_score: number;
  resolution: string;
  worker: string;
  annotation_type: T;
  properties?: {
    type: ObjectPropertiesAndCategoriesType;
    property_name: string;
    option_name: string;
  }[];
};

export type MislabelDetectionClassInfo = {
  class_id: string;
  class_name: string;
  class_mislabel_score_all_avg: number;
  class_mislabel_score_10_avg: number;
  class_instance_num: number;
};

export type MislabelDetectionResultsPerClass = MislabelDetectionClassInfo & {
  results: MislabelDetectionResult<MislabelDetectionAnnotationType>[];
};

export type MislabelDetectionImageInfo = { labelId: string; index: string; resolution: string };

export type MislabelDetectionCreateIssueActionParams = {
  message: string;
};

export type MislabelDetectionAction = {
  label_id: string;
  annotation_id: string;
  mislabel_rank: number;
  change_type: 'CREATE_ISSUE';
  params: MislabelDetectionCreateIssueActionParams;
  bbox: number[];
  issue_type?: ('redraw' | 'change_class' | 'delete' | 'other')[]; // only used for UI filter, not for API
};

export type MislabelDetectionActionHistory = {
  created_by: string;
  created_at: Date;
  id: string;
  items: MislabelDetectionAction[];
  name: string;
};

export const mislabelDetectionIssueTypeValueLabelMap = {
  redraw: 'Redraw Annotation',
  change_class: 'Change Class',
  delete: 'Delete Annotation',
  other: 'Other',
};

export const MISLABEL_DETECTION_FAIL_MESSAGES = [
  'NotSupportedAnnotation',
  'SingleClassNotSupported',
  'InsufficientDataSize',
  'ClassMismatch',
  'Unexpected',
] as const;

export type MislabelDetectionFailMessageType = (typeof MISLABEL_DETECTION_FAIL_MESSAGES)[number];
