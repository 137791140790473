const WORK_APP = ['image-siesta', 'image-default', 'video-siesta', 'pointclouds-siesta'] as const;

export type WorkApp = (typeof WORK_APP)[number];

const isImageDefault = (workapp?: WorkApp): boolean =>
  !!workapp && 'IMAGE_DEFAULT' === workapp.toUpperCase().replace('-', '_');

const isImageSiesta = (workapp?: WorkApp): boolean =>
  !!workapp && 'IMAGE_SIESTA' === workapp.toUpperCase().replace('-', '_');

const isVideoSiesta = (workapp?: WorkApp): boolean =>
  !!workapp && 'VIDEO_SIESTA' === workapp.toUpperCase().replace('-', '_');

const isPointcloudsSiesta = (workapp?: WorkApp): boolean =>
  !!workapp && 'POINTCLOUDS_SIESTA' === workapp.toUpperCase().replace('-', '_');

const isImageApp = (workapp?: WorkApp): boolean =>
  (!!workapp && isImageDefault(workapp)) || isImageSiesta(workapp);
const isVideoApp = (workapp?: WorkApp): boolean => !!workapp && isVideoSiesta(workapp);
const isSiesta = (workapp?: WorkApp): boolean =>
  !!workapp && (isImageSiesta(workapp) || isVideoSiesta(workapp) || isPointcloudsSiesta(workapp));

const DataFormat = ['IMAGE', 'IMAGE_BETA', 'IMAGE_SEQUENCE', 'POINTCLOUDS'] as const;

export type DataFormatType = (typeof DataFormat)[number];

const isImage = (dataType: string): boolean => 'IMAGE' === dataType.toUpperCase();
const isImageBeta = (dataType: string): boolean =>
  'IMAGE_BETA' === dataType.toUpperCase().replace(' ', '_');
const isImageSequence = (dataType: string): boolean =>
  'IMAGE_SEQUENCE' === dataType.toUpperCase().replace(' ', '_');
const isPointclouds = (dataType: string): boolean =>
  'POINTCLOUDS' === dataType.toUpperCase().replace(' ', '_');

export default {
  WORK_APP,
  isImageDefault,
  isImageSiesta,
  isVideoSiesta,
  isPointcloudsSiesta,
  isImageApp,
  isVideoApp,
  isSiesta,
  DataFormat,
  isImage,
  isImageBeta,
  isImageSequence,
  isPointclouds,
};
