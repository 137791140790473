import { Annotation, AnnotationType, Category } from '../../services/types';

export const annotationTypeResolver = (
  annotation: Annotation,
  categories: Category[],
): AnnotationType => {
  if (!categories[annotation.category_id - 1]) return 'box';
  return categories[annotation.category_id - 1].type;
};

export const classNameResolver = (annotation: Annotation, categories: Category[]) => {
  const category = categories.find(category => category.id === annotation.category_id);
  if (!category) return '';
  return category.name;
};
