import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router';

import { ArrowLeft } from '@superb-ai/icons';
import { Box, Button, Icon, LoadingIndicator, makePrintIfChanged, Typography } from '@superb-ai/ui';

import { formatDateTodayYesterday } from '../../../../../../utils/date';
import {
  useDownloadQuery,
  useDownloadsInfiniteListQuery,
} from '../../../../queries/downloadsQueries';
import { getQueryParams } from '../../../../utils/routeUtils';
import DownloadItem from './DownloadItem';
import EmptyPage from './EmptyPage';

export default function Layout() {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const history = useHistory();
  const queryParams = getQueryParams(history);
  const { data, refetch, isLoading, fetchNextPage, hasNextPage } = useDownloadsInfiniteListQuery({
    datasetId,
    disabled: !!queryParams.job_id,
  });
  const { data: downloadData } = useDownloadQuery({ jobId: queryParams.job_id });

  const results = data?.pages?.flatMap(page => page.results) || [];
  const printIfChanged = makePrintIfChanged();

  if (queryParams.job_id && downloadData) {
    return (
      <Box px={4} py={2} overflow="auto">
        <DownloadItem downloadInfo={downloadData} refetchList={refetch} />
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="strong-fill"
            color="gray"
            size="l"
            onClick={() => history.push(history.location.pathname)}
          >
            <Icon icon={ArrowLeft} />
            {t('curate.downloads.viewAllDownloads')}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box px={4} py={2} overflow="auto">
      {isLoading ? (
        <LoadingIndicator mt={2} />
      ) : results.length > 0 ? (
        <InfiniteScroll
          pageStart={0}
          hasMore={hasNextPage}
          loadMore={() => fetchNextPage()}
          initialLoad={false}
          loader={<LoadingIndicator mt={2} />}
        >
          {results.map(result => (
            <Fragment key={result.job_id}>
              {printIfChanged(
                formatDateTodayYesterday(result.created_at),
                <Box mb={2}>
                  <Typography variant="l-regular">
                    {formatDateTodayYesterday(result.created_at)}
                  </Typography>
                </Box>,
              )}
              <DownloadItem downloadInfo={result} refetchList={refetch} />
            </Fragment>
          ))}
        </InfiniteScroll>
      ) : (
        <EmptyPage />
      )}
    </Box>
  );
}
