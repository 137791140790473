import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { InfoCircleOutline } from '@superb-ai/icons';
import { Box, Icon, Tooltip, Typography } from '@superb-ai/ui';

export type TableHeader = {
  title: string;
  width?: string;
  infoText?: string | EmotionJSX.Element;
  justifyContent?: string;
};

export function StatsTableHeader({ headers }: { headers: TableHeader[] }) {
  return (
    <Box
      px={1}
      py={1.5}
      gap={1}
      borderBottom="1px solid"
      borderColor="gray-200"
      display="flex"
      alignItems="center"
      position="relative"
    >
      {headers.map((header, index) => (
        <Box
          display="flex"
          key={header.title + index}
          mr={index === 3 ? 1 : 0}
          style={header.width ? { width: header.width } : { flex: 1 }}
          justifyContent={header?.justifyContent ? 'flex-end' : 'flex-start'}
        >
          <Typography key={header.title + 'text'} variant="m-medium">
            {header.title}
          </Typography>
          {header?.infoText && (
            <Tooltip content={header.infoText} placement="top">
              <Box display="flex" alignItems="center">
                <Icon color="black" icon={InfoCircleOutline} size="s" style={{ margin: '2px' }} />
              </Box>
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  );
}
