import React from 'react';

import { ChevronBigDown } from '@superb-ai/icons';
import { Box, Dropdown, Icon, SelectList } from '@superb-ai/ui';

import { CLUSTER_LEVELS, ClusterLevel } from '../types';
function ClusterLevelBar({ selected }: { selected?: boolean }) {
  return (
    <svg
      width="3"
      height="8"
      viewBox="0 0 3 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fontSize={0}
    >
      <rect
        width="3"
        height="8"
        rx="1.5"
        fill="currentColor"
        fill-opacity={selected ? '0.3' : '0.1'}
      />
      <rect
        x="0.5"
        y="0.5"
        width="2"
        height="7"
        rx="1.5"
        stroke="currentColor"
        stroke-opacity="0.2"
      />
    </svg>
  );
}
export default function ClusterLevelDropdown({
  maxSize,
  selectedSize,
  clickable,
  onChange,
}: {
  maxSize: number;
  selectedSize: string;
  clickable?: boolean;
  onChange?: (value: ClusterLevel) => void;
}) {
  const arrayOfMaxItems = Array(maxSize).fill(0);
  const dataList = arrayOfMaxItems.map((i, index) => ({
    label: (
      <Box display="flex" alignItems="center" gap={2}>
        <ClusterLevelIndicator maxSize={maxSize} selectedSize={`${index}`} />
        {CLUSTER_LEVELS[index.toString() as ClusterLevel]}
      </Box>
    ),
    value: `${index}`,
  }));

  if (!clickable) return <ClusterLevelIndicator {...{ maxSize, selectedSize }} />;

  return (
    <Dropdown
      fixed
      useDefaultDisclosureSetup={false}
      disclosure={
        <Box>
          <ClusterLevelIndicator {...{ maxSize, selectedSize, clickable }} />
        </Box>
      }
    >
      <Box backgroundColor="white" borderRadius="2px" boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)">
        <SelectList data={dataList} value={selectedSize} onChangeValue={onChange} />
      </Box>
    </Dropdown>
  );
}

function ClusterLevelIndicator({
  maxSize,
  selectedSize,
  clickable,
}: {
  maxSize: number;
  selectedSize: string;
  clickable?: boolean;
}) {
  const clickableProps = {
    cursor: 'pointer' as const,
    backgroundColor: 'gray-100' as const,
  };
  const arrayOfMaxItems = Array(maxSize).fill(0);

  return (
    <Box
      color="primary"
      p={0.5}
      display="flex"
      alignItems="center"
      borderRadius="8px"
      style={{ gap: '1px' }}
      {...(clickable && clickableProps)}
    >
      {arrayOfMaxItems.map((i, index) => (
        <ClusterLevelBar key={`bar-${index}`} selected={index <= Number(selectedSize)} />
      ))}
      {clickable && (
        <Icon size={8} color="black" icon={ChevronBigDown} style={{ marginLeft: '4px' }} />
      )}
    </Box>
  );
}
