export type AppEnv = 'suite-popup' | 'new-tab';
type Workapp = 'image-default' | 'image-siesta' | 'video-siesta' | 'pointclouds-siesta';
export const openWorkapp = ({
  workapp,
  urlMatchInfo,
  labelId,
  appEnv,
  mode = 'edit',
}: {
  workapp: Workapp;
  urlMatchInfo: { accountName: string; projectId: string };
  labelId: string | null;
  appEnv: AppEnv;
  mode?: 'edit' | 'view';
}): void => {
  switch (workapp) {
    case 'image-default': {
      // @ts-ignore: Argument of type 'URL' is  assignable to parameter of type 'string'.
      window.open(getImageDefaultAnnotationAppUrl());
      return;
    }
    case 'image-siesta': {
      window.open(getModuleSiestaUrl());
      return;
    }
    case 'video-siesta': {
      window.open(getModuleSiestaUrl());
      return;
    }
    case 'pointclouds-siesta': {
      window.open(getModuleSiestaUrl());
      return;
    }
  }

  function getImageDefaultAnnotationAppUrl() {
    const accessToken = loadAndAssertTokenFromStorage('spb_access');
    const idToken = loadAndAssertTokenFromStorage('spb_user');
    const refreshToken = loadAndAssertTokenFromStorage('spb_');
    const url = new URL(
      `https://${process.env.NEXT_PUBLIC_WORK_HOST}/${urlMatchInfo.projectId}${
        labelId ? `/${labelId}` : ''
      }`,
    );
    url.searchParams.append('accessToken', accessToken);
    url.searchParams.append('idToken', idToken);
    url.searchParams.append('refreshToken', refreshToken);
    return url;
  }

  function getModuleSiestaUrl() {
    const url = new URL(`${window.location.origin}/annotation/${getModuleSiestaMode(workapp)}`);
    url.searchParams.append('app_mode', mode);
    url.searchParams.append('app_env', appEnv);
    url.searchParams.append('project_id', urlMatchInfo.projectId);
    if (labelId) {
      url.searchParams.append('label_id', labelId);
    }
    return url;
  }
};

function getModuleSiestaMode(workapp: Workapp) {
  switch (workapp) {
    case 'image-siesta':
      return 'image';
    case 'video-siesta':
      return 'video';
    case 'pointclouds-siesta':
      return 'pointcloudsV2';
    default:
      throw new Error(`unknown workapp: ${workapp}`);
  }
}

export function assertUrlMatchInfo(
  value: unknown,
): asserts value is { accountName: string; projectId: string } {
  if (
    typeof value === 'object' &&
    value != null &&
    typeof (value as any).accountName === 'string' &&
    typeof (value as any).projectId === 'string'
  ) {
    return;
  }

  throw new Error('value is not UrlMatchInfo');
}

export const getDetailViewUrl = ({
  workapp,
  // urlMatchInfo,
  // labelId,
  appEnv,
}: {
  workapp: Workapp;
  // urlMatchInfo: { accountName: string; projectId: string };
  // labelId: string | null;
  appEnv: AppEnv;
}): string => {
  switch (workapp) {
    case 'image-siesta': {
      return getModuleSiestaUrl();
    }
    case 'video-siesta': {
      return getModuleSiestaUrl();
    }
    case 'pointclouds-siesta': {
      return getModuleSiestaUrl();
    }
  }

  function getModuleSiestaUrl() {
    const url = new URL(`${window.location.origin}/annotation/${getModuleSiestaMode(workapp)}`);
    url.searchParams.append('app_mode', 'view');
    url.searchParams.append('app_env', appEnv);
    // url.searchParams.append('project_id', urlMatchInfo.projectId);
    // if (labelId) {
    //   url.searchParams.append('label_id', labelId);
    // }
    return url.toString();
  }

  throw new Error(`unhandled workapp: ${workapp}`);
};

export default {
  openWorkapp,
  getDetailViewUrl,
};

function loadAndAssertTokenFromStorage(key: string): string {
  const token = localStorage.getItem(key);
  if (typeof token !== 'string') {
    throw new Error(`token ${key} does not found`);
  }
  return token;
}
