import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box, Modal } from '@superb-ai/norwegian-forest';
import { Input, Typography } from '@superb-ai/ui';

import analyticsTracker from '../../../../../../analyticsTracker';
import { combineFiltersForTracking } from '../../../../../../analyticsTracker/utils';
import { useActionContext } from '../../../../contexts/ActionContext';
import { useCurateCommandContext } from '../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../contexts/DatasetContext';
import { useImageFilterContext } from '../../../../contexts/ImageFilterContext';
import { usePublicDatasetContext } from '../../../../contexts/PublicDatasetContextProvider';
import { useQueryContext } from '../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../contexts/SliceContext';
import { SelectedSliceOption } from '../../../../contexts/UploadContext';
import { SliceAsyncCombobox } from '../../../../elements/SliceAsyncCombobox';
import {
  useUpdateSliceByQueryJobMutation,
  useUpdateSliceJobWithIdsMutation,
} from '../../../../queries/sliceQueries';
import { useCurateDatasetService } from '../../../../services/DatasetService';
import { getView } from '../../../../utils/routeUtils';
import { useImageScatterContext } from '../analytics/contexts/ImageScatterContext';

export default function SemanticSearchAddSliceModal({
  addSliceModalIsOpen,
  setAddSliceModalIsOpen,
}: {
  addSliceModalIsOpen: boolean;
  setAddSliceModalIsOpen: (isOpen: boolean) => void;
}): JSX.Element {
  const { accountName, datasetId } = useParams<{ accountName: string; datasetId: string }>();
  const history = useHistory();
  const viewType = getView(history);
  const { t } = useTranslation();
  const { sliceInfo } = useSliceContext();
  const { sampledCount } = useImageScatterContext();
  const { appliedFilters } = useImageFilterContext();
  const { setSelectedData, setSelectedAllData, semanticSearchData } = useActionContext();
  const { queryStringWithHiddenFilterAndDeselection } = useQueryContext();
  const commandContext = useCurateCommandContext();

  const selectedImageIds = semanticSearchData?.map(image => image.id) ?? [];

  const [selectedSlice, setSelectedSlice] = useState<SelectedSliceOption>();
  const [isLoading, setIsLoading] = useState(false);

  const { getDataset } = useCurateDatasetService();
  const { setDatasetInfo } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  const selectedDataCount = semanticSearchData?.length;
  const [numImagesToAdd, setNumImagesToAdd] = useState<number>(selectedDataCount);

  const { mutateAsync: startUpdateSliceJobWithIds } = useUpdateSliceJobWithIdsMutation({
    datasetId,
    commandContext,
    selectedImageIds: selectedImageIds.slice(0, numImagesToAdd),
  });

  const trackDataSliceUpdated = useCallback(
    (selectedSliceId: string, dataCount: number) => {
      analyticsTracker.dataSliceUpdated({
        datasetId,
        accountId: accountName,
        sliceId: selectedSliceId,
        viewType: viewType,
        dataCount: dataCount,
        dataType: 'image',
        referrer: 'semantic-search-image',
        filters: combineFiltersForTracking(appliedFilters, sliceInfo?.id),
        queryString: queryStringWithHiddenFilterAndDeselection,
      });
    },
    [
      accountName,
      appliedFilters,
      datasetId,
      queryStringWithHiddenFilterAndDeselection,
      sliceInfo?.id,
      viewType,
    ],
  );

  const handleAddSlice = useCallback(async () => {
    if (!selectedSlice) return;
    setIsLoading(true);
    await startUpdateSliceJobWithIds({ sliceId: selectedSlice?.id });
    trackDataSliceUpdated(selectedSlice?.id, selectedDataCount);

    setAddSliceModalIsOpen(false);
    const datasetInfo = await getDataset({
      fromPublicDatasets: showPublicDatasets,
      datasetId,
      expand: ['image_count', 'slice_count'],
    });
    setDatasetInfo(datasetInfo);
    if (setSelectedAllData && setSelectedData) {
      setSelectedAllData(false);
      setSelectedData([]);
    }
    setIsLoading(false);
  }, [
    selectedSlice,
    trackDataSliceUpdated,
    setAddSliceModalIsOpen,
    getDataset,
    showPublicDatasets,
    datasetId,
    setDatasetInfo,
    setSelectedAllData,
    setSelectedData,
    startUpdateSliceJobWithIds,
    selectedDataCount,
  ]);

  return (
    <Modal
      open={addSliceModalIsOpen}
      close={{
        onClose: () => {
          setAddSliceModalIsOpen(false);
        },
        hasCloseButton: true,
      }}
      title={t('curate.dialogs.AddToExistingSlice')}
      mainButton={{
        text: 'Okay',
        onClick: handleAddSlice,
        color: 'primary',
        isLoading,
        disabled: !selectedSlice,
      }}
      subButton={{
        text: 'Cancel',
        onClick: () => {
          setAddSliceModalIsOpen(false);
        },
      }}
    >
      <Box minWidth="520px" px={4} py={2.5}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="s-regular" color="black">
            <Trans
              t={t}
              i18nKey="curate.dialogs.selectedCount"
              values={{ count: selectedDataCount }}
              components={{ 1: <Typography color="primary" /> }}
            />
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="s-regular" color="black">
              # images to add:
            </Typography>
            <Input
              defaultValue={selectedDataCount}
              onChange={e => setNumImagesToAdd(Number(e.target.value))}
              type="number"
              boxProps={{ style: { width: '64px', height: '20px' } }}
            />
          </Box>
        </Box>
        <SliceAsyncCombobox
          datasetId={datasetId}
          selectedSlice={selectedSlice}
          setSelectedSlice={setSelectedSlice}
        />
      </Box>
    </Modal>
  );
}
