import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import WorkappUnion from '../../../../union/WorkappUnion';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import ListContainer from '../ListContainer';
import helper from './helper';
import { KeypointDialog } from './keypointDialog';
import ObjectClassItem from './ObjectClassItem';
import ObjectClassItemKeypointDialog from './ObjectClassItemKeypointDialog';

const useStyles = makeStyles(() => ({
  box: {
    width: '420px',
    height: '100%',
  },
  innerBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: overflowOverlayOrAuto(),
  },
}));

const ObjectClassList = (): React.ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const newProjectInfo = useNewProjectInfo();
  const listRef = useRef<HTMLDivElement>();
  const [isPressDelete, setIsPressDelete] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        setIsPressDelete(true);
      }
    };

    window.addEventListener('keydown', keyDownHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };

    // eslint-disable-next-line
  }, []);

  const deleteSelectedObjectClass = () => {
    if (newProjectInfo.selectedClassPropertyIndex !== -1) return;

    helper.deleteObjectClass(newProjectInfo);
  };

  useEffect(() => {
    if (!isPressDelete) return;
    setIsPressDelete(false);

    deleteSelectedObjectClass();
    // eslint-disable-next-line
  }, [isPressDelete]);

  const handleClickList = () => {
    newProjectInfo.setSelectedObjectClassIndex(-1);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!listRef || !listRef.current) return;

      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClickAdd = () => {
    helper.createObjectClass(newProjectInfo);
    scrollToBottom();
  };
  const handleClickCopy = () => {
    helper.copyObjectClass(newProjectInfo);
  };
  const handleClickDelete = () => {
    deleteSelectedObjectClass();
  };

  const handleScroll = () => {
    if (!listRef || !listRef.current) return;

    let nextScrollTop = listRef.current.scrollTop;
    nextScrollTop = typeof nextScrollTop === 'number' ? nextScrollTop : 0;
    setScrollTop(nextScrollTop);
  };

  return (
    <MUI.Box className={classes.box}>
      <ListContainer
        useHeader
        title={t('labelInterface.objectClass')}
        disabledStrings={
          newProjectInfo.objectClasses.length === 0
            ? [t('projects.createProject.clickToCreateObjectClass')]
            : []
        }
        handleClickDisabledBox={handleClickAdd}
        handleClickAdd={handleClickAdd}
        handleClickCopy={handleClickCopy}
        handleClickDelete={handleClickDelete}
        handleScroll={handleScroll}
      >
        <MUI.Box {...{ ref: listRef }} className={classes.innerBox} onClick={handleClickList}>
          {newProjectInfo.objectClasses.map((objectClass: any, index: number) => (
            <ObjectClassItem
              key={objectClass.id}
              index={index}
              objectClass={objectClass}
              scrollTop={scrollTop}
              listHeight={600}
            />
          ))}
        </MUI.Box>
      </ListContainer>
      {newProjectInfo.selectedAnnotationTypes.KEYPOINT ? (
        WorkappUnion.isImageDefault(newProjectInfo.selectedWorkapp) ? (
          <ObjectClassItemKeypointDialog />
        ) : (
          <KeypointDialog />
        )
      ) : null}
    </MUI.Box>
  );
};

export default ObjectClassList;
