import React, { useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Icon } from '@superb-ai/norwegian-forest';

const useStyles = makeStyles(theme => ({
  nested: (props: { paddingLeft: number | string }) => ({
    paddingLeft: props.paddingLeft,
  }),
  listIcon: {
    minWidth: theme.spacing(3),
  },
}));

interface Props {
  item: any;
  onClickCheck: (value: any) => (event: React.MouseEvent<HTMLDivElement>) => void;
  status: string;
  flatAnnoOptions: any;
  hierarchy: number;
}

const FilterClassDropdownListItem: React.FC<Props> = props => {
  const { item, onClickCheck, flatAnnoOptions, hierarchy, status } = props;
  const classes = useStyles({ paddingLeft: `${hierarchy * 16}px` });
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleClickChevron = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const getCheckboxIcon = (status: string) => {
    switch (status) {
      case 'checked':
        return <Icon name="checkboxChecked" size="14px" customColor={theme.palette.primary.main} />;
      case 'indeterminate':
        return <Icon name="checkboxMixed" size="14px" customColor={theme.palette.grey[600]} />;
      case 'empty':
      default:
        return <Icon name="checkbox" size="14px" />;
    }
  };

  if (!item.children) {
    return (
      <MUI.ListItem dense button className={classes.nested} onClick={onClickCheck(item.value)}>
        <MUI.ListItemIcon className={classes.listIcon}>{getCheckboxIcon(status)}</MUI.ListItemIcon>
        <MUI.ListItemText primary={item.label} />
      </MUI.ListItem>
    );
  }

  return (
    <>
      <MUI.ListItem dense button className={classes.nested} onClick={onClickCheck(item.value)}>
        <MUI.ListItemIcon className={classes.listIcon}>{getCheckboxIcon(status)}</MUI.ListItemIcon>
        <MUI.ListItemText primary={item.label} />
        <MUI.IconButton size="small" onClick={handleClickChevron}>
          {open ? <Icon name="chevronDown" size="12px" /> : <Icon name="chevronLeft" size="12px" />}
        </MUI.IconButton>
      </MUI.ListItem>
      <MUI.Collapse in={open} unmountOnExit timeout="auto">
        {item.children.map((child: any) => (
          <FilterClassDropdownListItem
            key={child.value}
            hierarchy={hierarchy + 1}
            item={child}
            onClickCheck={onClickCheck}
            flatAnnoOptions={flatAnnoOptions}
            status={flatAnnoOptions[child.value] ? flatAnnoOptions[child.value].status : 'empty'}
          />
        ))}
      </MUI.Collapse>
    </>
  );
};

export default FilterClassDropdownListItem;
