/* eslint-disable import/prefer-default-export */
import * as d3 from 'd3';
import { isEmpty, max } from 'lodash';

import { ScatterPlotSvgConfig, SvgConfigObject } from '../config/types';
import { ExtendedScaleBand } from '../interfaces/d3Types';

/**
 * @param {array} xValues
 * @param {array} yValues
 * @param {object} chart Chart config, including margins
 * @param {object} xScaleType Optional scale type (ex. BAND, DATE)
 * @param {number} innerPadding padding between bars
 * @param {number} outerPadding padding before the first bar and after the last bar
 */

// TODO: combine two below into one function by adjusting margins!

// eslint-disable-next-line
export const getBarScales = (
  xValues: string[],
  yValues: number[],
  chart: SvgConfigObject,
  xScaleType: 'BAND' | undefined,
  scaleBy?: number,
): [ExtendedScaleBand, d3.ScaleLinear<number, number>] => {
  const xScale = d3
    .scaleBand()
    .domain(xValues)
    .range([0, chart.width])
    .paddingOuter(chart?.outerPadding ?? 0.9)
    .paddingInner(chart?.innerPadding ?? 0.5) as ExtendedScaleBand;

  if (xScaleType) {
    xScale.scaleType = xScaleType;
  }

  const Y_RESCALE = scaleBy || 1.2;
  const Y_MAX = isEmpty(yValues) ? 0 : (max(yValues) as number);
  const yScale = d3
    .scaleLinear()
    .domain([0, Y_MAX === 0 ? 5 : Y_MAX * Y_RESCALE])
    .range([chart.height, 0]);
  return [xScale, yScale];
};

/** Get Linear Scales */
export const getXLinearScale = (
  xValues: number[],
  svgInfo: SvgConfigObject | ScatterPlotSvgConfig,
): d3.ScaleLinear<number, number> => {
  return d3
    .scaleLinear()
    .domain(d3.extent(xValues) as number[])
    .range([0, svgInfo.width]);
};

export const getYLinearScale = (
  yValues: number[],
  svgInfo: SvgConfigObject | ScatterPlotSvgConfig,
  scaleBy?: number,
): d3.ScaleLinear<number, number> => {
  const Y_RESCALE = scaleBy || 1.2;
  const Y_MAX = isEmpty(yValues) ? 0 : (max(yValues) as number);
  return d3
    .scaleLinear()
    .domain([0, Y_MAX === 0 ? 5 : Y_MAX * Y_RESCALE])
    .range([svgInfo.height, 0]);
};
