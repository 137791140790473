import React, { Fragment, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { ArrowRight, Box as BoxIcon, Edit, Polygon, Trash } from '@superb-ai/icons';
import { getConvertedFileSize } from '@superb-ai/norwegian-forest';
import {
  Box,
  Button,
  Icon,
  IconButton,
  LinkTypography,
  Typography,
  useDialogState,
} from '@superb-ai/ui';
import { InfiniteData, QueryObserverResult } from '@tanstack/react-query';
import { compact, startCase, trim } from 'lodash';

import Accordion from '../../../../../../components/pages/export/exportHistory/Accordion';
import { getUrl } from '../../../../../../routes/util';
import { addDays, formatDate, formatTime } from '../../../../../../utils/date';
import { useSlicesQuery } from '../../../../queries/sliceQueries';
import { useDownloadsService } from '../../../../services/DownloadsService';
import { DownloadInfo } from '../../../../types/downloadTypes';
import DeleteDownloadModal from './DeleteDownloadModal';
import DownloadFilterInfo from './\bDownloadFilterInfo';
import DownloadNameInput from './DownloadNameInput';

export default function DownloadItem({
  downloadInfo,
  refetchList,
}: {
  downloadInfo: DownloadInfo;
  refetchList: () => Promise<
    QueryObserverResult<InfiniteData<{ results: DownloadInfo[]; next_cursor: string }>, unknown>
  >;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { datasetId, accountName, suite } = useParams<{
    datasetId: string;
    accountName: string;
    suite: string;
  }>();
  const [nameChangeActive, setNameChangeActive] = useState(false);
  const [currentDownloadInfo, setCurrentDownloadInfo] = useState<DownloadInfo>(downloadInfo);
  const [name, setName] = useState<string>(currentDownloadInfo.download_name);
  const [nameErrorMessages, setNameErrorMessages] = useState<string[]>([]);
  const { updateDownload, deleteDownload } = useDownloadsService();
  const downloadNameRef = useRef<HTMLInputElement>(null);
  const dialog = useDialogState();

  const isDownloadExpired = currentDownloadInfo.download_status !== 'ACTIVE';
  const annotationSummary = currentDownloadInfo.annotation_summary
    ? Object.entries(currentDownloadInfo.annotation_summary).reduce(
        (acc, [entity, annotations]) => {
          Object.entries(annotations).forEach(([annotationType, count]) => {
            acc.push([annotationType, entity, count]);
          });
          return acc;
        },
        [],
      )
    : undefined;

  const { data: sliceInfoByName } = useSlicesQuery({
    datasetId,
    name: currentDownloadInfo.image_filters.slice,
    enabled: !!currentDownloadInfo.image_filters.slice,
  });

  function handleEnableNameChange() {
    if (isDownloadExpired) return;
    setNameChangeActive(true);
  }

  async function handleChangeName() {
    if (nameErrorMessages.length > 0 || !name || name === currentDownloadInfo.download_name) {
      setNameChangeActive(false);
      return;
    }
    const download = await updateDownload({
      jobId: currentDownloadInfo.job_id,
      downloadName: trim(name),
    });
    setCurrentDownloadInfo(download);
    setNameChangeActive(false);
  }

  async function handleDeleteDownload() {
    await deleteDownload({ jobId: currentDownloadInfo.job_id });
    await refetchList();
  }

  const expiredDate = formatDate(addDays(currentDownloadInfo.created_at, 14));

  return (
    <Box
      color={isDownloadExpired ? 'gray-300' : undefined}
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      mb={2}
    >
      <Box
        px={2}
        py={1}
        borderBottom="1px solid"
        borderColor="gray-150"
        display="flex"
        alignItems="center"
      >
        {nameChangeActive ? (
          <DownloadNameInput
            name={name}
            setName={setName}
            nameErrorMessages={nameErrorMessages}
            setNameErrorMessages={setNameErrorMessages}
            inputProps={{
              type: 'text',
              autoFocus: true,
              variant: 'text',
              style: {
                width: 500,
                padding: 0,
              },
              ref: downloadNameRef,
              onKeyDown: event => {
                if (event.nativeEvent.isComposing) return;
                if (event.key === 'Escape') {
                  setNameChangeActive(false);
                  setName(currentDownloadInfo.download_name);
                  return;
                }
                if (event.key === 'Enter') handleChangeName();
              },
              onBlur: () => handleChangeName(),
            }}
          />
        ) : (
          <Box display="flex" alignItems="center" gap={2}>
            <Typography
              variant="l-strong"
              style={{
                lineHeight: '32px',
                textDecoration: isDownloadExpired ? 'line-through' : 'initial',
              }}
              onDoubleClick={handleEnableNameChange}
            >
              {currentDownloadInfo.download_name}
            </Typography>
            <Typography variant="m-regular" color="gray-300">
              {isDownloadExpired ? (
                t('curate.downloads.expiredDate', {
                  date: expiredDate,
                })
              ) : (
                <Trans
                  t={t}
                  i18nKey="curate.downloads.expirationDate"
                  values={{
                    date: expiredDate,
                  }}
                  components={{ emphasize1: <Typography color="primary"></Typography> }}
                />
              )}
            </Typography>
          </Box>
        )}
        <Box ml="auto" display="flex" alignItems="center">
          {!isDownloadExpired && (
            <IconButton variant="text" size="s" icon={Edit} onClick={handleEnableNameChange} />
          )}
          <IconButton
            variant="text"
            size="s"
            icon={Trash}
            onClick={() => {
              dialog.show();
            }}
          />
        </Box>
      </Box>
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={4}>
          {currentDownloadInfo.download_type !== 'IMAGES' && (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="m-regular" color="gray-300">
                {t('curate.downloads.downloadFormat')}
              </Typography>
              <Typography variant="m-regular">
                {currentDownloadInfo.download_format === 'CURATE' ? 'Superb AI' : 'YOLOv5'}
              </Typography>
            </Box>
          )}
          {currentDownloadInfo.image_filters.slice && (
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="m-regular" color="gray-300">
                {t('curate.downloads.slice')}
              </Typography>
              <LinkTypography
                color={sliceInfoByName?.count ? 'gray' : 'gray-300'}
                variant="m-regular"
                disabled={!sliceInfoByName?.count}
                onClick={() => {
                  if (!sliceInfoByName?.count) return;
                  history.push(
                    getUrl([
                      accountName,
                      suite,
                      'dataset',
                      datasetId,
                      'slice',
                      sliceInfoByName.results[0].id,
                    ]),
                  );
                }}
              >
                {currentDownloadInfo.image_filters.slice}
              </LinkTypography>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="m-regular" color="gray-300">
              {t('curate.downloads.dataCountAndSize')}
            </Typography>
            <Typography variant="m-regular">
              {compact([
                currentDownloadInfo.image_count
                  ? t('curate.bulkActions.imageCountText', {
                      count: currentDownloadInfo.image_count,
                    })
                  : undefined,
                currentDownloadInfo.annotation_count
                  ? t('curate.bulkActions.annotationCountText', {
                      count: currentDownloadInfo.annotation_count,
                    })
                  : undefined,
              ]).join(', ')}
              {' / '}
              {getConvertedFileSize(currentDownloadInfo.download_size)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="m-regular" color="gray-300">
              {t('text.createdBy')}
            </Typography>
            <Typography variant="m-regular">{currentDownloadInfo.created_by}</Typography>
          </Box>
          <Box ml="auto" display="flex" flexDirection="column" gap={1} alignItems="flex-end">
            <Typography variant="m-regular" color="gray-300">
              {formatTime(currentDownloadInfo.created_at)}
            </Typography>
            <Box>
              {isDownloadExpired ? (
                <Typography variant="m-strong" color="gray-300" style={{ lineHeight: '32px' }}>
                  {t('curate.downloads.expired')}
                </Typography>
              ) : (
                <Button
                  variant="strong-fill"
                  color="primary"
                  onClick={() =>
                    window.open(currentDownloadInfo.zip_download_url, '_blank', 'noreferrer')
                  }
                >
                  {t('curate.downloads.downloadButton')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {currentDownloadInfo.image_filters.query && (
          <Accordion
            title={t('curate.downloads.filtersAndQuery')}
            boxProps={{ backgroundColor: 'gray-100', px: 1, color: 'gray' }}
            defaultOpen={false}
            rightContent={
              <Box>
                <Button
                  variant="text"
                  onClick={e => {
                    e.stopPropagation();
                    history.push(
                      getUrl(
                        currentDownloadInfo.image_filters.slice
                          ? [
                              accountName,
                              suite,
                              'dataset',
                              datasetId,
                              'slice',
                              sliceInfoByName.results[0].id,
                            ]
                          : [accountName, suite, 'dataset', datasetId, 'explore'],
                        {},
                        { filter_query: currentDownloadInfo.image_filters.query },
                      ),
                    );
                  }}
                >
                  <Icon icon={ArrowRight} />
                  {t('curate.downloads.applyFilters')}
                </Button>
              </Box>
            }
          >
            <DownloadFilterInfo imageFilter={currentDownloadInfo.image_filters} />
          </Accordion>
        )}

        {!!currentDownloadInfo.annotation_count && currentDownloadInfo.annotation_summary && (
          <Accordion
            title={t('curate.downloads.annotations', {
              count: currentDownloadInfo.annotation_count,
            })}
            boxProps={{ color: 'gray', backgroundColor: 'gray-100', px: 1, py: 1 }}
            defaultOpen={false}
          >
            <Box display="grid" style={{ gridTemplateColumns: '160px 1fr 1fr' }} color="gray">
              {currentDownloadInfo.image_filters.query && (
                <>
                  <Typography
                    p={1}
                    variant="m-medium"
                    borderBottom="1px solid"
                    borderColor="gray-150"
                  >
                    {t('curate.downloads.annotationType')}
                  </Typography>
                  <Typography
                    p={1}
                    variant="m-medium"
                    borderBottom="1px solid"
                    borderColor="gray-150"
                  >
                    {t('curate.downloads.annotationName')}
                  </Typography>
                  <Typography
                    p={1}
                    variant="m-medium"
                    borderBottom="1px solid"
                    borderColor="gray-150"
                  >
                    {t('curate.downloads.annotationCount')}
                  </Typography>
                </>
              )}
              {annotationSummary?.map(([annotationType, entity, count]) => (
                <Fragment key={`${annotationType}-${entity}`}>
                  <Typography p={1} variant="m-regular" display="flex" alignItems="center" gap={1}>
                    <Icon icon={annotationType === 'box' ? BoxIcon : Polygon} />
                    {startCase(annotationType)}
                  </Typography>
                  <Typography p={1} variant="m-regular">
                    {entity}
                  </Typography>
                  <Typography p={1} variant="m-regular">
                    {(count || 0).toLocaleString('en')}
                  </Typography>
                </Fragment>
              ))}
            </Box>
          </Accordion>
        )}
      </Box>
      <DeleteDownloadModal dialog={dialog} onConfirm={handleDeleteDownload} />
    </Box>
  );
}
