import { Slice } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../../../../../menu/MenuItem';
import Layout from './Layout';

export const SliceListMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.curateDatasetSliceList,
  path: 'list',
  icon: <Icon icon={Slice} />,
  title: ({ t }) => t('curate.dataset.menus.slices'),
  component: Layout,
});
