import React, { useEffect, useRef, useState } from 'react';
import { Circle, Group, Line } from 'react-konva';

import Color from 'color';
import { concat } from 'lodash';

import ImageContext from '../../../../../contexts/ImageContext';
import { useLabelInformationInfo } from '../../../../../contexts/LabelInformationContext';
import MathUtils from '../../../../../utils/MathUtils';
import Properties from './Properties';

const getTiltedboxPoints = (tiltedbox, radian = null) => {
  if (!radian) {
    // eslint-disable-next-line no-param-reassign
    radian = tiltedbox.radian;
  }

  return [
    MathUtils.rotateTransform(
      { x: tiltedbox.cx, y: tiltedbox.cy },
      {
        x: tiltedbox.cx - tiltedbox.width / 2,
        y: tiltedbox.cy - tiltedbox.height / 2,
      },
      radian,
    ),
    MathUtils.rotateTransform(
      { x: tiltedbox.cx, y: tiltedbox.cy },
      {
        x: tiltedbox.cx + tiltedbox.width / 2,
        y: tiltedbox.cy - tiltedbox.height / 2,
      },
      radian,
    ),
    MathUtils.rotateTransform(
      { x: tiltedbox.cx, y: tiltedbox.cy },
      {
        x: tiltedbox.cx + tiltedbox.width / 2,
        y: tiltedbox.cy + tiltedbox.height / 2,
      },
      radian,
    ),
    MathUtils.rotateTransform(
      { x: tiltedbox.cx, y: tiltedbox.cy },
      {
        x: tiltedbox.cx - tiltedbox.width / 2,
        y: tiltedbox.cy + tiltedbox.height / 2,
      },
      radian,
    ),
  ];
};

const Tiltedbox = props => {
  const {
    shape,
    hoverIndex,
    onClickShape,
    onMouseEnterShape,
    onMouseLeaveShape,
    color,
    isClicked,
  } = props;
  const shapeRef = useRef(null);
  const labelInformationInfo = useLabelInformationInfo();
  const imageInfo = React.useContext(ImageContext.Context);
  const { rate } = imageInfo;

  const { classes, cabinetTabState, isLabelInformationSwitchActive } = labelInformationInfo;

  const { info } = shape;
  const tiltedbox = info;

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!shapeRef.current) return;

    if (cabinetTabState === 'issue') {
      shapeRef.current.zIndex(1);
    } else if (hoverIndex === 1) {
      shapeRef.current.zIndex(shapeRef.current.parent.children.length - 1);
    }
  }, [hoverIndex, cabinetTabState]);

  // TODO (tsnoh): 임시코드. project에 등록된 class와 다른 class가 있을경우 방어
  if (!classes[shape.class]) return null;

  const tiltedPoints = getTiltedboxPoints(tiltedbox);

  const supportedPoints = [
    {
      x: (tiltedPoints[0].x + tiltedPoints[1].x) / 2,
      y: (tiltedPoints[0].y + tiltedPoints[1].y) / 2,
    },
    {
      x: (tiltedPoints[2].x + tiltedPoints[3].x) / 2,
      y: (tiltedPoints[2].y + tiltedPoints[3].y) / 2,
    },
    {
      x: (tiltedPoints[0].x + tiltedPoints[3].x) / 2,
      y: (tiltedPoints[0].y + tiltedPoints[3].y) / 2,
    },
    {
      x: (tiltedPoints[1].x + tiltedPoints[2].x) / 2,
      y: (tiltedPoints[1].y + tiltedPoints[2].y) / 2,
    },
  ];

  const rotatePoint = {
    x:
      ((tiltedbox.height / 2 + 30) * ((tiltedPoints[0].x + tiltedPoints[1].x) / 2) -
        30 * tiltedbox.cx) /
      (tiltedbox.height / 2 + 30 - 30),
    y:
      ((tiltedbox.height / 2 + 30) * ((tiltedPoints[0].y + tiltedPoints[1].y) / 2) -
        30 * tiltedbox.cy) /
      (tiltedbox.height / 2 + 30 - 30),
  };

  let points = [];
  for (let i = 0; i < tiltedPoints.length; i++) {
    const temp = [tiltedPoints[i].x, tiltedPoints[i].y];
    points = concat(points, temp);
  }

  return (
    <Group ref={shapeRef}>
      {isLabelInformationSwitchActive && (
        <Properties
          shape={shape}
          x={tiltedbox.cx}
          y={tiltedbox.cy}
          width={tiltedbox.width}
          height={tiltedbox.height}
          rate={rate}
        />
      )}
      <Line
        lineCap="round"
        lineJoin="round"
        strokeWidth={2 / rate}
        points={points}
        stroke={isClicked || isHovered ? Color(color).negate().hex() : color}
        onClick={onClickShape}
        onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
        onMouseLeave={onMouseLeaveShape({ setIsHovered })}
        cursor="pointer"
        closed
      />
      <Line
        lineCap="round"
        lineJoin="round"
        strokeWidth={2 / rate}
        points={[supportedPoints[0].x, supportedPoints[0].y, rotatePoint.x, rotatePoint.y]}
        stroke={isClicked || isHovered ? Color(color).negate().hex() : color}
        onClick={onClickShape}
        onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
        onMouseLeave={onMouseLeaveShape({ setIsHovered })}
        cursor="pointer"
      />
      <>
        {tiltedPoints.map((tiltedPoint, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Circle
              x={tiltedPoint.x}
              y={tiltedPoint.y}
              fill={isClicked || isHovered ? Color(color).negate().hex() : color}
              width={6 / rate}
              height={6 / rate}
              onClick={onClickShape}
              onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
              onMouseLeave={onMouseLeaveShape({ setIsHovered })}
              cursor="pointer"
            />
          </React.Fragment>
        ))}
        {supportedPoints.map((supportedPoint, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <Circle
              x={supportedPoint.x}
              y={supportedPoint.y}
              fill={isClicked || isHovered ? Color(color).negate().hex() : color}
              width={6 / rate}
              height={6 / rate}
              onClick={onClickShape}
              onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
              onMouseLeave={onMouseLeaveShape({ setIsHovered })}
              cursor="pointer"
            />
          </React.Fragment>
        ))}
        <Circle
          x={rotatePoint.x}
          y={rotatePoint.y}
          fill={isClicked || isHovered ? Color(color).negate().hex() : color}
          width={6 / rate}
          height={6 / rate}
          onClick={onClickShape}
          onMouseEnter={onMouseEnterShape({ shapeRef, setIsHovered })}
          onMouseLeave={onMouseLeaveShape({ setIsHovered })}
          cursor="pointer"
        />
      </>
    </Group>
  );
};

export default Tiltedbox;
