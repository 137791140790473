import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as d3 from 'd3';
import { isEmpty, isEqual, map, reverse } from 'lodash';

import { STATUS_COLORS } from '../../../../../consts/ColorChips';
import { getDisplayStatus, getFilterStatus } from '../../config/constants';
import { FORMAT_COUNT } from '../../config/d3Formatters';
import { SVG_PROPERTY } from '../../config/svgConfig';
import { AssigneeDisplayData } from '../../dataTypes/analyticsDataTypes';
import Legend from '../../elements/Legend';
import { TooltipStackedBar } from '../../elements/TooltipStackedBar';
import XAxis from '../../elements/XAxis';
import YAxis from '../../elements/YAxis';
import { ExtendedScaleBand } from '../../interfaces/d3Types';
import { BaseDatum, JsonObj } from '../../userStats/types';

const TEXT_STYLE = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  textAnchor: 'middle',
};

interface Props {
  chartName: string;
  chartInfo: JsonObj;
  readonly data: AssigneeDisplayData[];
  readonly keys: string[];
  readonly checkedStatus: string[];
  tooltipKeyMap: Record<string, string>;
  isScrolling: boolean;
  totalCounts: number;
  xKeyToDisplayName?: Record<string, string>;
  width: number;
}

export interface TooltipContent {
  data: Record<string, string | number>;
  readonly xValue: string[];
  readonly yValue: string[];
  readonly supplementValue: string[];
  total: number;
}

interface Content {
  data: Record<string, string | number>;
  xKey: string;
  yKey: string;
  supplement: string;
  statusKey: string;
  currentTotal: number;
}

/**
 * @param {array} data
 * @param {array} keys
 * @param {array} selectedKeys Keys in data object to filter by
 * @param {object} tooltipKeyMap Map of data keys to display names for tooltip content
 *
 * */
const BarChartStacked: React.FC<Props> = props => {
  const {
    data,
    keys,
    checkedStatus,
    tooltipKeyMap,
    isScrolling,
    chartName,
    xKeyToDisplayName,
    width,
  } = props;
  const { t } = useTranslation();
  const svgInfo = SVG_PROPERTY('regular', width);

  const svgStyle = {
    width: svgInfo.svgWidth,
    height: svgInfo.svgHeight,
    overflow: 'visible',
  };

  const { SUBMITTED, SKIPPED, WORKING } = STATUS_COLORS;
  const filtered = data;
  const xKey: string = keys[0];
  const emailKey = keys[2];
  // const hasPercentShare = (!_.isEmpty(sValues) && sValues[0].indexOf("@")) === -1 ? true : false;  // is not email
  const [hoveredIndex, setHoveredIndex] = useState<BaseDatum[]>([]);
  const [tooltipContent, setTooltipContent] = useState<TooltipContent | JsonObj>({});
  const [keepTooltipOpen, setKeepTooltipOpen] = useState(false);
  const [removeTooltip, setRemoveTooltip] = useState(true);

  const colors: d3.ScaleOrdinal<string, string> = d3
    .scaleOrdinal<string, string>()
    .domain(['cumSubmitted', 'cumWorking', 'cumSkipped'])
    .range([SUBMITTED.color, WORKING.color, SKIPPED.color]);

  const COLOR_MAP = map(checkedStatus, s => {
    return { text: t(getDisplayStatus(s)), color: colors(s), opacity: 1 };
  });

  const xScale: ExtendedScaleBand = d3
    .scaleBand(
      map(data, d => d[xKey]),
      [0, svgInfo.width],
    )
    .paddingInner(0.5)
    .paddingOuter(0.9);
  xScale.scaleType = 'BAND';

  const Y_RESCALE = 1.2;
  const Y_MAX: number = d3.max(filtered, d => d.currentTotal) || 0;
  const yScale = d3
    .scaleLinear<number>()
    .domain([0, (Y_MAX === 0 ? 1 : Y_MAX) * Y_RESCALE])
    .range([svgInfo.height, 0]);
  const stack = d3.stack().keys(checkedStatus);

  useEffect(() => {
    if (removeTooltip && !keepTooltipOpen) {
      const timer = setTimeout(() => {
        setHoveredIndex([]);
        setKeepTooltipOpen(false);
        setRemoveTooltip(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [removeTooltip, keepTooltipOpen]);

  const handleMouseEnter = (layerRectIndices: BaseDatum[], content: Content) => {
    setRemoveTooltip(false);
    if (isEmpty(setHoveredIndex) && !keepTooltipOpen) {
      setHoveredIndex(layerRectIndices);
      setTooltipContent({
        data: content.data,
        xValue: [tooltipKeyMap.x, content.xKey],
        yValue: [getFilterStatus(content.statusKey), content.yKey],
        supplementValue: [tooltipKeyMap.email, content.supplement],
        total: content.currentTotal,
      });
    }
  };
  const handleMouseLeave = () => {
    setRemoveTooltip(false);
    if (!keepTooltipOpen) {
      setRemoveTooltip(true);
    }
  };
  const handleClick = () => {
    if (keepTooltipOpen) {
      setHoveredIndex([]);
    }
    setKeepTooltipOpen(!keepTooltipOpen);
  };
  const handleSVGClick = () => {
    if (keepTooltipOpen) {
      setKeepTooltipOpen(!keepTooltipOpen);
      setHoveredIndex([]);
    }
  };

  const handleTooltipMouseEnter = () => {
    setKeepTooltipOpen(true);
  };

  const handleTooltipMouseLeave = () => {
    setKeepTooltipOpen(false);
  };

  /**
   *  0: [
   *    0: [0,2, data: Object]
   *    1: [0,0, data: Object]
   *    key: "cumSkipped"
   *    index: 0
   *  ], ...
   *
   */
  const bars = map(reverse(stack(filtered)), (layer, index) => {
    // each layer (status) of stack goes in group
    // use key in layer's array to set the color
    const COLOR_LAYER: string = colors(layer.key);

    // d is single Array data in each layer
    const getRect = (d: JsonObj, i: number) => {
      const darkColor = String(d3.rgb(COLOR_LAYER).darker(0.1));
      const rectFill: string | undefined = isEqual(hoveredIndex, [index, i])
        ? darkColor
        : COLOR_LAYER;
      return (
        <Fragment key={`${index}-${i}-${chartName}`}>
          <rect
            key={`${d[1]}-${index}-rect${i}-${chartName}`}
            x={xScale(d.data[xKey]) as number}
            y={yScale(d[1]) as number}
            height={(yScale(d[0]) as number) - (yScale(d[1]) as number)}
            width={xScale.bandwidth()}
            fill={rectFill}
            onClick={() => handleClick()}
            onMouseEnter={() =>
              handleMouseEnter([index, i], {
                data: d.data,
                xKey: d.data[xKey],
                yKey: d.data[layer.key],
                statusKey: layer.key,
                currentTotal: d.data.currentTotal,
                supplement: d.data[emailKey],
              })
            }
            onMouseLeave={() => handleMouseLeave()}
          />
        </Fragment>
      );
    };
    return (
      <g key={`group-layer-${index}-${chartName}`} fill={colors(layer.key)}>
        {map(layer, (d, i) => getRect(d, i))}
      </g>
    );
  });

  const texts = map(filtered, (d, index) => {
    const textDistanceAboveBar = 8; // Distance between text and rect svg
    const currentTotalKey = 'currentTotal';
    return (
      d &&
      !Number.isNaN(d[currentTotalKey]) && (
        <Fragment key={`fragment-${index}-${chartName}`}>
          <rect
            key={`rect-text-${index}-${chartName}`}
            x={xScale(d[xKey])}
            y={(yScale(d[currentTotalKey]) as number) - textDistanceAboveBar - 25}
            width={xScale.bandwidth()}
            height={35}
            opacity={0}
            fill="blue"
            onClick={() => handleClick()}
            onMouseEnter={() =>
              handleMouseEnter([index, 100], {
                data: d,
                xKey: d[xKey],
                yKey: String(d[currentTotalKey]),
                statusKey: 'total', //
                currentTotal: d[currentTotalKey],
                supplement: d[emailKey],
              })
            }
            onMouseLeave={() => handleMouseLeave()}
          />
          <text
            fontSize={TEXT_STYLE.fontSize}
            fontFamily={TEXT_STYLE.fontFamily}
            textAnchor={TEXT_STYLE.textAnchor}
            key={`text-above-bar-${index}`}
            fill={String(
              isEqual(hoveredIndex[0], index)
                ? d3.rgb(SKIPPED.color).darker(0.5)
                : d3.rgb(SKIPPED.color).darker(0.3),
            )}
            x={(xScale(d[xKey]) as number) + xScale.bandwidth() / 2}
            y={(yScale(d.currentTotal) as number) - textDistanceAboveBar}
            onMouseEnter={() =>
              handleMouseEnter([index, 100], {
                data: d,
                xKey: d[xKey],
                yKey: String(d[currentTotalKey]),
                statusKey: 'total',
                currentTotal: d[currentTotalKey],
                supplement: d[emailKey],
              })
            }
            onClick={() => handleClick()}
            onMouseLeave={() => handleMouseLeave()}
          >
            {FORMAT_COUNT(d.currentTotal)}
          </text>
        </Fragment>
      )
    );
  });
  const gTransform = `translate(${svgInfo.left},${svgInfo.top})`;
  const xMaxLength = Math.max(...filtered.map(el => el?.assignee_display_name?.length));

  return (
    <svg id={`bar-chart-svg-${chartName}`} style={svgStyle}>
      <rect
        key={`rect-svg-${chartName}`}
        width="100%"
        height="100%"
        opacity="0"
        fill="white"
        onClick={handleSVGClick}
      />
      <XAxis
        bottom={svgInfo.bottom}
        left={svgInfo.left}
        width={svgInfo.width}
        height={svgInfo.svgHeight}
        scale={xScale}
        xLength={data.length}
        xKeyToDisplayName={xKeyToDisplayName}
        xMaxLength={xMaxLength}
        rotateXLabel
      />
      <YAxis top={svgInfo.top} left={svgInfo.left} width={svgInfo.width} scale={yScale} />
      {/* {hasPercentShare ? (
        <YAxis
          top={svgInfo.top}
          left={svgInfo.left}
          width={svgInfo.width}
          height={svgInfo.height}
          scale={y}
          totalCounts={totalCounts}
          isSecondAxis
        />
      ) : null} */}
      <g transform={gTransform} className="stacked-bar-group">
        {bars}
        {texts}
      </g>
      {checkedStatus.length > 0 ? (
        <Legend
          variables={COLOR_MAP}
          location={{ transformX: svgInfo.svgWidth - 340, transformY: 30 }}
        />
      ) : null}
      {!isScrolling && !isEmpty(hoveredIndex) && !isEmpty(tooltipContent) ? (
        <TooltipStackedBar
          left={svgInfo.left}
          top={svgInfo.top + 14}
          content={tooltipContent}
          scales={[xScale, yScale]}
          checkedStatus={checkedStatus}
          chartName={chartName}
          chartType="bar"
          handleTooltipMouseEnter={handleTooltipMouseEnter}
          handleTooltipMouseLeave={handleTooltipMouseLeave}
        />
      ) : null}
    </svg>
  );
};

export default BarChartStacked;
