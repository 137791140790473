/* eslint-disable no-param-reassign */
import React from 'react';
import { Group, Text } from 'react-konva';

import { map } from 'lodash';

import { ColorUtils } from '../../../../../utils/SpbUtils';

const Properties = props => {
  const { shape, x, y, width, height, rate } = props;

  const fontSize = 12 / rate;
  const rectHeight = 14 / rate;

  return (
    <Group>
      {height >= rectHeight && (
        <Text
          x={x}
          y={y}
          width={width}
          fill="#000"
          wrap="none"
          ellipsis
          text={shape.class}
          fontSize={fontSize}
          sceneFunc={(context, text) => {
            const { attrs } = text;

            if (!text.textArr[0]) return;
            context.beginPath();
            context.fillStyle = '#e6e6e6';
            context.fillRect(0, 0, text.textWidth + 10, rectHeight);
            context.stroke();
            context.closePath();

            context.beginPath();
            context.setAttr('textBaseline', 'ideographic');
            context.fillStyle = attrs.fill;
            context.font = `${attrs.fontSize}px Inter`;
            context.fillText(text.textArr[0].text, 5, rectHeight);
            context.closePath();
          }}
        />
      )}
      {height >= rectHeight * 2 && (
        <Text
          x={x}
          y={y}
          width={width}
          fill="#000"
          wrap="none"
          ellipsis
          text={shape.id}
          fontSize={fontSize}
          sceneFunc={(context, text) => {
            const { attrs } = text;

            if (!text.textArr[0]) return;
            context.beginPath();
            context.fillStyle = '#a6a6a6';
            context.fillRect(0, rectHeight, text.textWidth + 10, rectHeight);
            context.stroke();
            context.closePath();

            context.beginPath();
            context.setAttr('textBaseline', 'ideographic');
            context.fillStyle = attrs.fill;
            context.font = `${attrs.fontSize}px Inter`;
            context.fillText(text.textArr[0].text, 5, rectHeight * 2);
            context.closePath();
          }}
        />
      )}
      {shape.properties.length !== 0 &&
        map(shape.properties, (property, index) => {
          const { name, value } = property;
          const curY = (index + 3) * rectHeight;

          return (
            height >= curY + rectHeight && (
              <Text
                x={x}
                y={y + curY}
                key={index}
                width={width}
                wrap="none"
                ellipsis
                fill={ColorUtils.invertColor('#e6e6e6', true)}
                text={`${name}: ${value}`}
                fontSize={fontSize}
                sceneFunc={(context, text) => {
                  const { attrs } = text;

                  if (!text.textArr[0]) return;
                  context.beginPath();
                  context.fillStyle = `${index % 2 === 0 ? '#e6e6e6' : '#a6a6a6'}`;
                  context.fillRect(0, 0, text.textWidth + 10, rectHeight);
                  context.stroke();
                  context.closePath();

                  context.beginPath();
                  context.setAttr('textBaseline', 'ideographic');
                  context.fillStyle = attrs.fill;
                  context.font = `${attrs.fontSize}px Inter 300`;
                  context.fillText(text.textArr[0].text, 5, rectHeight);
                  context.closePath();
                }}
              />
            )
          );
        })}
    </Group>
  );
};

export default Properties;
