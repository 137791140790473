import { useTranslation } from 'react-i18next';

import { Edit } from '@superb-ai/icons';
import { Box, IconButton, Typography } from '@superb-ai/ui';

import { useDatasetContext } from '../../../contexts/DatasetContext';
import { useDatasetModalContext } from '../../../contexts/ModalContext';
import { usePublicDatasetContext } from '../../../contexts/PublicDatasetContextProvider';
import DatasetSettingsModal from './modal/DatasetSettingsModal';

export default function DatasetDesc() {
  const { datasetInfo } = useDatasetContext();
  const { t } = useTranslation();
  const { setDatasetSettingsIsOpen } = useDatasetModalContext();
  const { showPublicDatasets } = usePublicDatasetContext();

  return (
    <>
      <DatasetSettingsModal />
      <Box bt="1px solid" borderColor="gray-150" py={1} mx={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography value="s-strong" color="gray-300">
            Description
          </Typography>
          {!showPublicDatasets && (
            <IconButton
              variant="text"
              size="xs"
              icon={Edit}
              onClick={() => {
                setDatasetSettingsIsOpen(true);
              }}
            />
          )}
        </Box>
        <Box mt={0.5}>
          {datasetInfo?.description ? (
            <Typography variant="m-regular" style={{ lineBreak: 'anywhere' }}>
              {datasetInfo?.description}
            </Typography>
          ) : (
            <Typography variant="m-regular" color="gray-250">
              {t('curate.datasets.descriptionPlaceholder')}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
