import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Input,
  Typography,
  useAlertModal,
  useTheme,
} from '@superb-ai/norwegian-forest';
import { useSnackbar } from 'notistack';

import { DEFAULT_ERROR } from '../../../../consts/ModalMessage';
import { NAME_CHANGE_SUCCESS } from '../../../../consts/SnackbarMessage';
import { useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import { useAuthService } from '../../../../services/NewAuthService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import {
  identifyAdminNameChange,
  identifyCollaboratorNameChange,
  identifyOwnerNameChange,
} from '../../../../utils/SegmentIdentifyGroup';
import helper from './helper';

interface Name {
  givenName: string;
  familyName: string;
}

interface Props extends Name {
  onCancel: () => void;
}

const NameEditor = ({ givenName, familyName, onCancel }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [name, setName] = useState<Name>({ givenName, familyName });
  const [focusName, setFocusName] = useState<keyof Name | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useAlertModal();
  const authInfo = useAuthInfo();

  const routeInfo = useRouteInfo();

  const firstNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);

  const [isRequesting, setIsRequesting] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState('');
  const [lastNameHelperText, setLastNameHelperText] = useState('');
  const { loginRefresh } = useAuthService();

  const { changeName } = useAuthService();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    const setStateName = setName;
    const helperText = {
      familyName: [lastNameHelperText, setLastNameHelperText],
      givenName: [firstNameHelperText, setFirstNameHelperText],
    };

    helper.handleChangeName({
      value: e.target.value,
      setName: (name: string) =>
        setStateName(prev => ({
          ...prev,
          [e.target.name]: name,
        })),
      // @ts-ignore this code is bad and impossible to type correctly. Need to rewrite this whole component.
      nameErrorMessage: helperText[e.target.name][0],
      // @ts-ignore this code is bad and impossible to type correctly. Need to rewrite this whole component.
      setNameErrorMessage: helperText[e.target.name][1],
    });
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocusName(e.target.name as keyof Name);
  };

  const onBlur = () => {
    setFocusName(null);
  };
  const handleClickChangeName = async () => {
    if (isRequesting) return;

    const trimFirstName = (name.givenName || '').trim();
    const trimLastName = (name.familyName || '').trim();

    let isFirstNameLengthInvalid = false;
    let isLastNameLengthInvalid = false;
    if (
      !helper.isValidLength(t)({
        value: trimFirstName,
        min: 1,
        max: 50,
        setErrorMessage: setFirstNameHelperText,
        inputRef: firstNameInputRef,
      })
    )
      isFirstNameLengthInvalid = true;

    if (
      !helper.isValidLength(t)({
        value: trimLastName,
        min: 1,
        max: 50,
        setErrorMessage: setLastNameHelperText,
        inputRef: lastNameInputRef,
      })
    )
      isLastNameLengthInvalid = true;

    let isEmptyFirstNameField = false;
    let isEmptyLastNameField = false;
    if (
      helper.isEmptyField(t)({
        value: trimFirstName,
        setErrorMessage: setFirstNameHelperText,
        inputRef: firstNameInputRef,
      })
    )
      isEmptyFirstNameField = true;

    if (
      helper.isEmptyField(t)({
        value: trimLastName,
        setErrorMessage: setLastNameHelperText,
        inputRef: lastNameInputRef,
      })
    )
      isEmptyLastNameField = true;

    if (
      isFirstNameLengthInvalid ||
      isLastNameLengthInvalid ||
      isEmptyFirstNameField ||
      isEmptyLastNameField
    )
      return;

    if (authInfo.firstName === trimFirstName && authInfo.lastName === trimLastName) {
      return;
    }
    setIsRequesting(true);

    try {
      await changeName({
        givenName: trimFirstName,
        familyName: trimLastName,
      });
      authInfo.setName(`${trimFirstName} ${trimLastName}`);
      authInfo.setFirstName(trimFirstName);
      authInfo.setLastName(trimLastName);

      if (UserRoleUnion.isOwner(authInfo.role)) {
        identifyOwnerNameChange({
          email: authInfo.email || '',
          firstName: trimFirstName,
          lastName: trimLastName,
        });
      } else if (UserRoleUnion.isAdmin(authInfo.role)) {
        identifyAdminNameChange({
          email: authInfo.email || '',
          firstName: trimFirstName,
          lastName: trimLastName,
        });
      } else if (UserRoleUnion.isCollaborator(authInfo.role)) {
        identifyCollaboratorNameChange({
          email: authInfo.email || '',
          firstName: trimFirstName,
          lastName: trimLastName,
        });
      }

      await loginRefresh();
      enqueueSnackbar(NAME_CHANGE_SUCCESS({ t }), { variant: 'success' });
      setIsRequesting(false);
      setFirstNameHelperText('');
      setLastNameHelperText('');
      onCancel();
    } catch (err: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          message: err.message,
          onClickMainButton: () => {
            setIsRequesting(false);
            closeModal();
          },
        }),
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" gap="16px">
      <Box display="flex" gap="6px">
        <Box display="flex" flexDirection="column">
          <Input
            label={
              <Box mb={0.5}>
                <label
                  style={focusName === 'givenName' ? { color: theme.nfPalette.primary[400] } : {}}
                >
                  {t('settings.profile.firstName')}
                </label>
              </Box>
            }
            helperContent={
              <Typography variant="body4" themedColor={['primary', 600]}>
                {firstNameHelperText}
              </Typography>
            }
            value={name.givenName}
            name="givenName"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Input
            label={
              <Box mb={0.5}>
                <label
                  style={focusName === 'familyName' ? { color: theme.nfPalette.primary[400] } : {}}
                >
                  {t('settings.profile.lastName')}
                </label>
              </Box>
            }
            helperContent={
              <Typography variant="body4" themedColor={['primary', 600]}>
                {lastNameHelperText}
              </Typography>
            }
            value={name.familyName}
            name="familyName"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Box>
      </Box>
      <Box display="flex" gap="4px">
        <Button color="backgroundColor" onClick={onCancel}>
          {t('button.cancel')}
        </Button>
        <Button color="grey" onClick={handleClickChangeName}>
          {t('button.update')}
        </Button>
      </Box>
    </Box>
  );
};

export default NameEditor;
