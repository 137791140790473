import { useEffect } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { CaretLeft } from '@superb-ai/icons';
import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, IconButton } from '@superb-ai/ui';

import { DEFAULT_ERROR } from '../../../../consts/ModalMessage';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import AccountService from '../../../../services/AccountService';
import { ErrorHandler, getEmptyFieldError, getValidLengthError, parseAccountName } from '../helper';
import { useChangeContent } from '../Layout';
import PolicyKR from '../PolicyKR';
import ValidationInput, { blurAllInputs, useValidationInputRefs } from '../ValidationInput';

interface TextInputKeys {
  companyName: string;
  accountName: string;
}

interface CheckboxInputKeys {
  krMarketingConsentAgreed?: boolean;
  krNewsletterConsentAgreed?: boolean;
}

type InputKeys = TextInputKeys & CheckboxInputKeys;

type UpdateFunction = {
  <K extends keyof TextInputKeys>(key: K, value: TextInputKeys[K]): void;
  <K extends keyof CheckboxInputKeys>(key: K, value: CheckboxInputKeys[K]): void;
};

interface Props {
  handleContinue: () => void;
  onClickPrevButton: () => void;
  info: InputKeys;
  changeInfo: UpdateFunction;
  isRequesting: boolean;
}

const getCompanyNameError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string) => getValidLengthError(t)(value, 4, 50))
    .createInstance();

const getAccountNameError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string) => getValidLengthError(t)(value, 4, 20))
    .createInstance();

export default function CompanyInfo({
  handleContinue,
  onClickPrevButton,
  changeInfo,
  info,
  isRequesting,
}: Props) {
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { closeModal, openModal } = useAlertModal();
  const { companyName, accountName, krMarketingConsentAgreed, krNewsletterConsentAgreed } = info;
  const refs = useValidationInputRefs(['companyName', 'accountName'] as const);
  const language = window.navigator.language.split('-')[0];

  useChangeContent('testimonialAutonet');

  const isCompanyNameValidated = !getCompanyNameError(t)(companyName);
  const isAccountNameValidated = !getAccountNameError(t)(accountName);
  const isMarketingConsentAgreed = language !== 'ko' || !!krMarketingConsentAgreed;

  const getIsValid = async (): Promise<boolean> => {
    try {
      await AccountService.checkAccountName({
        accountName,
        isGuest: false,
        urlInfo: routeInfo.urlMatchInfo,
      });

      if (getCompanyNameError(t)(companyName)) {
        refs.current.companyName?.focusWithError();
        return false;
      } else if (getAccountNameError(t)(accountName)) {
        refs.current.accountName?.focusWithError();
        return false;
      }

      return true;
    } catch (e: any) {
      const message = e?.message ?? 'Error';

      if (message === 'Team Name already exists') {
        openModal(
          DEFAULT_ERROR({
            t,
            message: t('auth.messages.teamAlreadyExistsExplanation'),
            onClickMainButton: () => {
              closeModal();
              refs.current.accountName?.focusWithError();
            },
          }),
        );
      }

      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (await getIsValid()) {
      blurAllInputs(refs);
      handleContinue();
    }
  };

  const onBlurCompanyName = () => {
    if (accountName === '') {
      changeInfo('accountName', parseAccountName(companyName));
    }
  };

  useEffect(() => {
    refs.current.companyName?.origin?.focus();
  }, []);

  return (
    <Box as="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2}>
      <ValidationInput
        onBlur={onBlurCompanyName}
        refs={refs}
        refKey="companyName"
        validation={getCompanyNameError(t)}
        onChange={e => changeInfo('companyName', e.target.value)}
        value={companyName}
        label={t('settings.profile.companyName')}
        placeholder="Superb AI"
      />
      <ValidationInput
        refs={refs}
        refKey="accountName"
        validation={getAccountNameError(t)}
        onChange={e => changeInfo('accountName', e.target.value)}
        value={accountName}
        label={t('settings.profile.teamName')}
        info={<Trans t={t} i18nKey="settings.profile.teamNameExplanation" />}
        placeholder="superb-ai"
      />

      {language === 'ko' && (
        <PolicyKR
          info={{ krMarketingConsentAgreed, krNewsletterConsentAgreed }}
          setInfo={changeInfo}
        />
      )}
      <Box display="flex" gap={1}>
        <IconButton
          disabled={isRequesting}
          icon={CaretLeft}
          size="l"
          color="primary"
          variant="strong-fill"
          onClick={onClickPrevButton}
        />
        <Button
          type="submit"
          disabled={!isCompanyNameValidated || !isAccountNameValidated || !isMarketingConsentAgreed}
          loading={isRequesting}
          variant="strong-fill"
          color="primary"
          size="l"
          style={{ width: '100%' }}
          id="suite-complete-signup-continue-button"
        >
          {t('button.continue')}
        </Button>
      </Box>
    </Box>
  );
}
