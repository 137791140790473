import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Input, Typography } from '@superb-ai/ui';

import { useActionContext } from '../../../../../../contexts/ActionContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { useObjectScopeContext } from '../../../../../../contexts/ObjectScopeContext';
import { useQueryContext } from '../../../../../../contexts/QueryContext';
import { useSliceContext } from '../../../../../../contexts/SliceContext';
import { useDatasetObjectsInfiniteQuery } from '../../../../../../queries/datasetObjectQueries';
import { getClipTextEmbeddings } from '../../../../../../utils/transformersUtils';
import GridLayout from '../GridLayout';
import ImageCell from './ImageCell';
import ObjectDetailModal from './ObjectDetailModal';
export default function ObjectSemanticSearchContents() {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { semanticSearchTotalCount } = useObjectScopeContext();
  const { queryString } = useQueryContext();

  const { sliceInfo } = useSliceContext();
  const { appliedFilters, setIsApplyingFilter } = useObjectFilterContext();

  const [openDetailIndex, setOpenDetailIndex] = useState<number>();
  const [inputText, setInputText] = useState('');
  const [embeddings, setEmbeddings] = useState(null);
  const pageSize = 100;
  const { selectedData, setSemanticSearchData } = useActionContext();

  // Function to handle input changes
  const handleInputChange = event => {
    setInputText(event.target.value);
  };

  // Function to handle keydown events
  const handleKeyDown = async event => {
    if (event.key === 'Enter') {
      const emb = await getClipTextEmbeddings(inputText);
      setEmbeddings(emb);
    }
  };

  //It is expected to call the api by distinguishing it here with the scope variable.
  const objectDataQuery = useDatasetObjectsInfiniteQuery({
    datasetId,
    queryString,
    sliceName: sliceInfo?.name || '',
    pageSize,
    appliedFilters,
    objectSimilarTo: selectedData ? selectedData[selectedData.length - 1]?.id : undefined,
    clipSimilarTo: embeddings,
    clipEmbedExists: true,
  });

  useEffect(() => {
    setIsApplyingFilter(objectDataQuery.isLoading);
  }, [objectDataQuery.isLoading, setIsApplyingFilter]);

  const loadedItems = useMemo(
    () => objectDataQuery.data?.pages.flatMap(p => p.results) ?? [],
    [objectDataQuery.data?.pages],
  );

  useEffect(() => {
    setSemanticSearchData(objectDataQuery.data?.pages.flatMap(p => p.results) ?? []),
      [objectDataQuery.data, loadedItems, setSemanticSearchData];
  });

  return (
    <>
      <Box whiteSpace="nowrap">
        <Typography variant="m-strong" color={0 === 0 ? 'gray-300' : 'primary'}>
          {(semanticSearchTotalCount || 0).toLocaleString('en') + ' '}
        </Typography>
        <Typography variant="m-strong">
          / {(objectDataQuery.data?.pages[0].datasetTotalCount || 0).toLocaleString('en') + ' '}
        </Typography>
        embeddings prepared for semantic search
      </Box>
      <Input
        type="text"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Natural Language Semantic Search"
      />{' '}
      <GridLayout
        queryResult={objectDataQuery}
        DetailModal={
          openDetailIndex !== undefined ? (
            <ObjectDetailModal
              pageSize={pageSize}
              totalCount={semanticSearchTotalCount || 0}
              loadMoreItem={async () => {
                await objectDataQuery.fetchNextPage();
              }}
              loadedItems={loadedItems}
              openImageIndex={openDetailIndex}
              setOpenImageIndex={setOpenDetailIndex}
            />
          ) : (
            <></>
          )
        }
        ImageCell={ImageCell}
        setOpenIndex={setOpenDetailIndex}
      />
    </>
  );
}
