import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { ModelTag } from '../../services/types';

type ContextProps = {
  selectedTags: ModelTag[];
  setSelectedTags: Dispatch<SetStateAction<ModelTag[]>>;
  memo: string;
  setMemo: Dispatch<SetStateAction<string>>;
};

const Context = createContext({} as ContextProps);

const useProvider = () => {
  const [selectedTags, setSelectedTags] = useState<ModelTag[]>([]);
  const [memo, setMemo] = useState<string>('');

  return { selectedTags, setSelectedTags, memo, setMemo };
};
export const useModelSettingContext = (): ContextProps => {
  return useContext(Context);
};

export const ModelSettingProvider: React.FC = ({ children }) => {
  const modelSettingInfo = useProvider();
  return <Context.Provider value={modelSettingInfo}>{children}</Context.Provider>;
};
