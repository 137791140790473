import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LinkTypography, ToggleButton, Typography } from '@superb-ai/ui';
import Link from 'next/link';
import { useSnackbar } from 'notistack';

import { isMfaRequired, useAuthInfo } from '../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../contexts/RouteContext';
import TotpService from '../../../../services/TotpService';
import UserRoleUnion from '../../../../union/UserRoleUnion';
import MfaTeam from './MfaTeam';
import MfaUser from './MfaUser';
import { Role, Roles } from './types';

export default function Mfa({}: {}) {
  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [mfaTeamOn, setMfaTeamOn] = useState(false); // mfa is required for the account
  const [mfaRoles, setMfaRoles] = useState<Role[]>([]); // which roles require mfa (owner, admin, collaborator)
  const [mfaUserOn, setMfaUserOn] = useState(false); // if user has mfa enabled

  useEffect(() => {
    setMfaUserOn(authInfo.mfaStatus === 'Verified');
  }, [authInfo.mfaStatus]);

  useEffect(() => {
    const roles = authInfo.mfaRequiredRoles.filter((r: any) => Roles.includes(r)) as Role[];
    if (roles.length) {
      setMfaRoles(roles);
      setMfaTeamOn(true);
    }
  }, [authInfo.mfaRequiredRoles]);

  async function onChangeMfaTeamOn(enabled: boolean) {
    setMfaTeamOn(enabled);
    onChangeMfaRoles(enabled ? [...Roles] : []);
  }

  async function onChangeMfaRoles(roles: Role[]) {
    setMfaRoles(roles);
    await TotpService.updateRequiredMfa({
      requiredRoles: roles.includes('Admin') ? [...roles, 'Owner'] : roles,
      isGuest: authInfo.isGuest,
      urlInfo: routeInfo.urlMatchInfo,
    });
  }

  async function onChangeMfaUserOn(enabled: boolean) {
    setMfaUserOn(enabled);
    if (enabled === false) {
      try {
        await TotpService.removeUserMfa({
          isGuest: authInfo.isGuest,
          urlInfo: routeInfo.urlMatchInfo,
        });
        enqueueSnackbar('Succesfully disabled Two-factor Authentication.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(
          `An error occured while disabling Two-factor Authentication: ${
            (e as Error).message ?? e
          }`,
          {
            variant: 'error',
          },
        );
        setMfaUserOn(true);
      }
    }
  }

  const title = t('settings.security.mfaTeam');
  const description = t('settings.security.mfaDescription');
  const learnMoreLink = 'https://www.superb-ai.com';
  const learnMoreText = t('settings.security.learnMoreMessage');

  const canChangeTeamSettings = UserRoleUnion.isOwner(authInfo.role);
  const canSetupMfa =
    (UserRoleUnion.isOwner(authInfo.role) && mfaTeamOn) || isMfaRequired(authInfo) || mfaUserOn;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h3">{title}</Typography>
        {/* <Link href={learnMoreLink} target="_blank" rel="noopener noreferrer">
          <LinkTypography variant="m-regular">{learnMoreText}</LinkTypography>
        </Link> */}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        boxShadow="2px 4px 10px rgba(0, 0, 0, 0.1)"
        p={3}
        borderRadius="2px"
        style={{ minHeight: 88 }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="m-regular">{description}</Typography>
          <ToggleButton
            size="s"
            disabled={!canChangeTeamSettings}
            checked={mfaTeamOn}
            onClick={() => {
              onChangeMfaTeamOn(!mfaTeamOn);
            }}
          />
        </Box>
        {mfaTeamOn && (
          <Box mt={2}>
            <hr style={{ color: '#DCDCDC' }} />
            <Box display="flex" style={{ minHeight: 88 }} mt={2}>
              {canSetupMfa && (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    minWidth: '50%',
                    borderRight: '1px solid #E8E8E8',
                  }}
                >
                  <MfaTeam mfaRoles={mfaRoles} onChangeMfaRoles={onChangeMfaRoles} />
                </Box>
              )}
              <Box
                style={{
                  minWidth: '50%',
                }}
              >
                <MfaUser
                  disabled={!canSetupMfa}
                  mfaUserOn={mfaUserOn}
                  onChangeMfaUserOn={onChangeMfaUserOn}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
