import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { chunk, compact, isBoolean } from 'lodash';

import {
  AnnotationFilterSchema,
  ClusterLevel,
  ImageClusterFilterSchema,
} from '../components/datasets/dataset/filter/types';
import { generateFilterDotColors } from '../components/datasets/dataset/views/embedding/scatterView/utils/color';
import { usePublicDatasetContext } from '../contexts/PublicDatasetContextProvider';
import { useCurateDatasetObjectService } from '../services/DatasetObjectService';
import { stringifyFilters } from '../utils/filterUtils';
import { calculatePercent } from '../utils/numberUtils';

type Props = {
  datasetId: string;
  fromPublicDatasets?: boolean;
  queryString?: string;
  sliceName?: string | null | undefined;
  pageSize?: number;
  appliedFilters?: AnnotationFilterSchema;
  clusterLevel?: ClusterLevel;
  disabled?: boolean;
  objectSimilarTo?: string;
  clipSimilarTo?: number[];
  clipEmbedExists?: boolean;
};

export function useDatasetObjectsInfiniteQuery({
  datasetId,
  fromPublicDatasets,
  queryString,
  sliceName,
  pageSize = 20,
  appliedFilters,
  objectSimilarTo,
  clipSimilarTo,
  clipEmbedExists,
}: Props) {
  const { postGetObjectList } = useCurateDatasetObjectService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const stringifiedFilters = stringifyFilters(appliedFilters);
  return useInfiniteQuery({
    queryKey: [
      'curate-dataset-object-list',
      datasetId,
      queryString,
      sliceName,
      stringifiedFilters,
      objectSimilarTo,
      clipSimilarTo,
      clipEmbedExists,
    ],
    queryFn: ({ pageParam }) => {
      return postGetObjectList({
        dataset_id: datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        slice: sliceName || undefined,
        search_after: pageParam,
        expand: [
          'image_thumbnail_url',
          'original_image_size',
          'cluster_id',
          'label_project_sync_exists',
          'dataset_total_count',
        ],
        query: queryString,
        size: pageSize,
        ...(appliedFilters && { annotation_filters: appliedFilters }),
        ...(objectSimilarTo && { object_similar_to: objectSimilarTo }),
        ...(clipSimilarTo && { clip_similar_to: clipSimilarTo }),
        clipEmbedExists,
      });
    },
    getNextPageParam: ({ last }) => last,
    enabled: Boolean(datasetId) && Boolean(appliedFilters),
    refetchOnWindowFocus: false,
  });
}

export function useDatasetObjectFilterSchemaQuery({
  datasetId,
  fromPublicDatasets,
  sliceName,
}: {
  datasetId: string;
  fromPublicDatasets?: boolean;
  sliceName?: string;
}) {
  const { getFilterSchema } = useCurateDatasetObjectService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const metadataCardinalityLimit = 200;
  return useQuery({
    queryKey: ['curate-dataset-object-filter-schema', datasetId, fromPublicDatasets, sliceName],
    queryFn: async () => {
      const filterSchema = await getFilterSchema({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        sliceName,
        expand: [
          'annotation_class',
          'annotation_type',
          'metadata_cardinality',
          'annotation_class_cluster_level',
        ],
      });
      const metadataCardinality = filterSchema.annotation_filters.metadata_cardinality;
      const metadataKeysWithCardinalityOverLimit = chunk(
        Object.keys(metadataCardinality || {}).reduce((acc, key) => {
          if (metadataCardinality[key] > metadataCardinalityLimit || metadataCardinality[key] <= 0)
            return acc;
          return [...acc, key];
        }, [] as string[]),
        100,
      );
      const filterSchemaWithValues = await Promise.all(
        metadataKeysWithCardinalityOverLimit.flatMap(keys => {
          return getFilterSchema({
            datasetId,
            fromPublicDatasets: isBoolean(fromPublicDatasets)
              ? fromPublicDatasets
              : showPublicDatasets,
            sliceName,
            metadataKeys: keys,
            metadataInSize: metadataCardinalityLimit,
          });
        }),
      ).then(schema =>
        schema.reduce((acc, s) => ({ ...acc, ...s.annotation_filters.metadata_in }), {}),
      );
      return {
        ...filterSchema.annotation_filters,
        ...{ metadata_in: filterSchemaWithValues },
      };
    },
    staleTime: 60000, // TODO: should test
  });
}

export function useDatasetObjectClusterFilterSchemaQuery({
  datasetId,
  fromPublicDatasets,
  sliceName,
  annotationClass,
  clusterLevel,
}: {
  datasetId: string;
  fromPublicDatasets?: boolean;
  sliceName?: string;
  annotationClass?: string;
  clusterLevel?: ClusterLevel;
}) {
  const { getAnnotationClusterFilterSchemaPerClass } = useCurateDatasetObjectService();
  const { showPublicDatasets } = usePublicDatasetContext();

  return useQuery({
    queryKey: [
      'curate-dataset-object-cluster-filter-schema',
      datasetId,
      fromPublicDatasets,
      sliceName,
      annotationClass,
      clusterLevel,
    ],
    queryFn: async () => {
      if (!datasetId || !annotationClass || !clusterLevel) return [];
      const { clusters } = await getAnnotationClusterFilterSchemaPerClass({
        datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        sliceName,
        annotationClass,
        clusterLevel,
      });
      const colorMap = generateFilterDotColors(compact(clusters?.map(d => d.id)), clusterLevel);
      const totalCount = clusters?.reduce((acc, d) => acc + d.annotation_count, 0);

      return clusters?.map((d, index) => {
        return {
          ...d,
          color: colorMap[d.id],
          share: calculatePercent({
            numerator: d.annotation_count,
            denominator: totalCount,
            nearest: 'int',
          }),
        };
      });
    },
    enabled: Boolean(datasetId) && Boolean(annotationClass) && Boolean(clusterLevel),
    staleTime: 60000, // TODO: should test
  });
}

export function useDatasetObjectCountQuery({
  datasetId,
  fromPublicDatasets,
  queryString,
  sliceName,
  appliedFilters,
  clipEmbedExists,
}: Props) {
  const { postGetObjectList } = useCurateDatasetObjectService();
  const { showPublicDatasets } = usePublicDatasetContext();
  const stringifiedFilters = stringifyFilters(appliedFilters);

  return useQuery({
    queryKey: [
      'curate-dataset-object-count',
      datasetId,
      queryString,
      sliceName,
      stringifiedFilters,
      clipEmbedExists,
    ],
    queryFn: () => {
      return postGetObjectList({
        dataset_id: datasetId,
        fromPublicDatasets: isBoolean(fromPublicDatasets) ? fromPublicDatasets : showPublicDatasets,
        slice: sliceName || undefined,
        query: queryString,
        size: 1, // TODO: update once API is updated
        ...(appliedFilters && { annotation_filters: appliedFilters }),
        clipEmbedExists,
      });
    },
    enabled: Boolean(datasetId),
  });
}

export const useDatasetAnnotationClassesQuery = ({
  datasetId,
  queryString,
  sliceName,
  appliedFilters,
  imageFilters,
  disabled,
}: Props & { imageFilters?: ImageClusterFilterSchema }) => {
  const { postGetAnnotationClasses } = useCurateDatasetObjectService();
  const stringifiedAnnotationFilters = stringifyFilters(appliedFilters);
  const stringifiedImageClusterFilters = stringifyFilters(imageFilters);

  return useQuery({
    queryKey: [
      'searchAnnotationClasses',
      datasetId,
      queryString,
      sliceName,
      stringifiedAnnotationFilters,
      stringifiedImageClusterFilters,
      disabled,
    ],
    queryFn: () =>
      postGetAnnotationClasses({
        datasetId,
        query: queryString,
        slice: sliceName || undefined,
        ...(appliedFilters && { annotation_filters: appliedFilters }),
        ...(imageFilters && { image_filters: imageFilters }),
      }),
    enabled: Boolean(datasetId) && !disabled,
  });
};

export const useDatasetAnnotationMetadataKeysQuery = ({
  datasetId,
  queryString,
  sliceName,
  appliedFilters,
  imageFilters,
  annotationClass,
  disabled,
}: Props & {
  imageFilters?: ImageClusterFilterSchema;
  annotationClass?: string;
  disabled?: boolean;
}) => {
  const { postGetAnnotationMetadataKeys } = useCurateDatasetObjectService();
  const stringifiedAnnotationFilters = stringifyFilters(appliedFilters);
  const stringifiedImageClusterFilters = stringifyFilters(imageFilters);

  return useQuery({
    queryKey: [
      'searchAnnotationMetadataKeys',
      datasetId,
      annotationClass,
      queryString,
      sliceName,
      stringifiedAnnotationFilters,
      stringifiedImageClusterFilters,
    ],
    queryFn: () => {
      if (!datasetId || !annotationClass) return;
      return postGetAnnotationMetadataKeys({
        datasetId,
        query: queryString,
        slice: sliceName || undefined,
        annotation_class: annotationClass,
        ...(appliedFilters && { annotation_filters: appliedFilters }),
        ...(imageFilters && { image_filters: imageFilters }),
      });
    },
    enabled: Boolean(datasetId && annotationClass) && !disabled,
    cacheTime: 600000,
    staleTime: 600000,
    refetchOnWindowFocus: false,
  });
};

export const useDatasetAnnotationMetadataValuesQuery = ({
  datasetId,
  queryString,
  sliceName,
  appliedFilters,
  imageFilters,
  annotationClass,
  annotationMetadataKey,
  disabled,
}: Props & {
  imageFilters?: ImageClusterFilterSchema;
  annotationClass: string;
  annotationMetadataKey?: string;
  disabled?: boolean;
}) => {
  const { postGetAnnotationMetadataValues } = useCurateDatasetObjectService();
  const stringifiedAnnotationFilters = stringifyFilters(appliedFilters);
  const stringifiedImageClusterFilters = stringifyFilters(imageFilters);

  return useQuery({
    queryKey: [
      'searchAnnotationMetadataValues',
      annotationClass,
      annotationMetadataKey,
      datasetId,
      queryString,
      sliceName,
      stringifiedAnnotationFilters,
      stringifiedImageClusterFilters,
    ],
    queryFn: () => {
      if (!datasetId || !annotationClass || !annotationMetadataKey || disabled) return;
      return postGetAnnotationMetadataValues({
        datasetId,
        query: queryString,
        slice: sliceName || undefined,
        annotation_class: annotationClass,
        annotation_metadata_key: annotationMetadataKey,
        ...(appliedFilters && { annotation_filters: appliedFilters }),
        ...(imageFilters && { image_filters: imageFilters }),
      });
    },
    enabled: !disabled && Boolean(datasetId && annotationClass && annotationMetadataKey),
    cacheTime: 600000,
    staleTime: 600000,
    refetchOnWindowFocus: false,
  });
};
