import { useTranslation } from 'react-i18next';

import { WarningFilled } from '@superb-ai/icons';
import { Tooltip, Typography } from '@superb-ai/ui';

import { LOW_AP_THRESHOLD, LOW_PRECISION_THRESHOLD, LOW_RECALL_THRESHOLD } from '../const';
import { formatDecimalPrecision, NumericColumns } from '../transformer';

type Props = {
  columnKey: NumericColumns;
  value: number;
};

export const NumberCell = (props: Props) => {
  const { t } = useTranslation();
  const { value, columnKey } = props;
  const threshold = {
    precision: LOW_PRECISION_THRESHOLD,
    recall: LOW_RECALL_THRESHOLD,
    ap: LOW_AP_THRESHOLD,
    ap50: LOW_AP_THRESHOLD,
  } as Record<string, number>;

  const roundedValue = formatDecimalPrecision(value, columnKey);
  return (
    <>
      {value < threshold[columnKey] && (
        <Tooltip
          content={t(`curate.diagnosis.classMetrics.${columnKey}IsLowerThan`, {
            threshold: LOW_PRECISION_THRESHOLD,
          })}
        >
          <WarningFilled color="#FFD704" style={{ marginBottom: '-2px', marginRight: '2px' }} />
        </Tooltip>
      )}
      <Typography variant="m-regular">{roundedValue}</Typography>
    </>
  );
};
