import { Compare } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../analyticsTracker/pageIds';
import { CreateMenuItem } from '../../../menu';
import { PurposeType } from '../services/types';
import { RecognitionAIDetailMenuItem } from './detail/MenuItem';
import { Layout } from './Layout';
import { RecognitionAIListMenuItem } from './list/MenuItem';

export const RecognitionAIMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.modelRecognitionAI,
  path: 'recognition' as PurposeType,
  matchParam: ':purpose(recognition)',
  group: 'purpose',
  title: ({ t }) => t('model.myModels.title'),
  component: Layout,
  children: [RecognitionAIListMenuItem, RecognitionAIDetailMenuItem],
  icon: <Icon icon={Compare} />,
});
