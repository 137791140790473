// 여기는 번역할 필요가 없었음.......
import { TFunction } from 'react-i18next';

function getErrorConst(t: TFunction) {
  return {
    PAGE_NOT_FOUND: t('consts.errorConst.pageNotFound'),
    NO_PUBLIC_API: t('consts.errorConst.noPublicApi'),
  };
}

export default getErrorConst;
