import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowDown, ArrowUp, ChevronDown } from '@superb-ai/icons';
import { DropdownMenu, DropdownMenuItem } from '@superb-ai/norwegian-forest';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';

import { ClassPerformance } from '../../../../../../services/DiagnosisAnalyticsService';
import { SortOrder } from '../types';

export type PerformanceTableSortBy = keyof ClassPerformance;

export default function SortDropdown<T>({
  dropdownItems,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}: {
  dropdownItems: { key: T; label: string }[];
  sortBy: T;
  setSortBy: Dispatch<SetStateAction<T>>;
  sortOrder: SortOrder;
  setSortOrder: Dispatch<SetStateAction<SortOrder>>;
}) {
  const { t } = useTranslation();

  function handleClickSortBy(target: T) {
    setSortBy(prev => {
      if (prev === target) {
        setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortOrder('asc');
      }
      return target;
    });
  }

  const DROPDOWN_WIDTH = 128;
  const getCurrentSortBy = (sortBy: T) => dropdownItems.find(item => item.key === sortBy)?.label;
  return (
    <DropdownMenu
      AnchorElement={
        <Button variant="stroke" color="gray" style={{ padding: '0 12px', width: DROPDOWN_WIDTH }}>
          <Typography variant="m-regular" display="flex" alignItems="center" gap={0.5}>
            {getCurrentSortBy(sortBy) || t('curate.diagnosis.sort.sortBy')}
            <Icon icon={sortOrder === 'asc' ? ArrowUp : ArrowDown} />
          </Typography>
          <Icon icon={ChevronDown} style={{ marginLeft: 'auto' }} />
        </Button>
      }
      placement="bottom-start"
      width={DROPDOWN_WIDTH}
    >
      {dropdownItems.map(item => {
        return (
          <DropdownMenuItem key={item.label} onClick={() => handleClickSortBy(item.key)}>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap="small">
              <Typography variant="m-regular" color={sortBy === item.key ? 'primary' : 'black'}>
                {item.label}
              </Typography>
              {sortBy === item.key && (
                <Icon color="primary" icon={sortOrder === 'asc' ? ArrowUp : ArrowDown} />
              )}
            </Box>
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );
}
