import React, { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import * as ShyCredit from '@superb-ai/shy-credit';
import { Button, Dialog } from '@superb-ai/ui';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import { VerticalStepper } from '../../../../../../components/composition/VerticalStepper';
import { useAuthInfo } from '../../../../../../contexts/AuthContext';
import { useRouteInfo } from '../../../../../../contexts/RouteContext';
import ProjectService from '../../../../../../services/ProjectService';
import { ProjectData } from '../../../../../../types/projectTypes';
import AnnotationTypeAndClass from '../modal/SyncWithLabel/AnnotationTypeAndClass';
import {
  convertToLabelResultDef,
  createClassAndProperties,
} from '../modal/SyncWithLabel/AnnotationTypeAndClass/helper';
import { ClassAndProperties } from '../modal/SyncWithLabel/AnnotationTypeAndClass/type';
import ProjectNameAndDescription from '../modal/SyncWithLabel/ProjectNameAndDescription';
import SelfAssign from '../modal/SyncWithLabel/SelfAssign';

type Props = {
  state: NonNullable<ComponentProps<typeof Dialog>['state']>;
  labelProject: ProjectData;
  refetchLabelProjects: () => void;
};

export default function SyncedLabelEditDialog({
  state,
  labelProject,
  refetchLabelProjects,
}: Props) {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const authInfo = useAuthInfo();
  const routeInfo = useRouteInfo();
  const [projectName, setProjectName] = useState(labelProject.name);
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [projectDescription, setProjectDescription] = useState(labelProject.description || '');
  const [selfAssign, setSelfAssign] = useState(
    labelProject.settings.numMaxSelfAssign === undefined || false,
  );
  const classAndProperties = createClassAndProperties(
    labelProject.labelInterface as ShyCredit.Models.ImageV2.LabelResultDef,
  );

  const [curateAnnotations, setCurateAnnotations] = useState<ClassAndProperties | undefined>(
    classAndProperties,
  );

  useEffect(() => {
    const hash = qs.parse(history.location.hash);
    if (hash['#edit-synced-label-project'] === labelProject.id) {
      state.show();
    }
  }, []);

  useEffect(() => {
    setCurateAnnotations(classAndProperties);
  }, [state.visible]);

  function handleCloseDialog() {
    history.replace(history.location.pathname);
    state.hide();
  }

  async function handleEdit() {
    if (!curateAnnotations) return;
    try {
      const { id } = await ProjectService.updateProject({
        projectId: labelProject.id,
        newInfo: {
          name: projectName,
          description: projectDescription,
          settings: {
            ...labelProject.settings,
            ...(selfAssign
              ? {
                  num_max_self_assign: undefined,
                  num_max_self_review_assign: undefined,
                }
              : {
                  num_max_self_assign: 0,
                  num_max_self_review_assign: 0,
                }),
          },
          labelInterface: convertToLabelResultDef(curateAnnotations),
        },
        isGuest: authInfo.isGuest,
        urlInfo: routeInfo.urlMatchInfo,
      });
      await refetchLabelProjects();
      handleCloseDialog();
      enqueueSnackbar(t('curate.dialogs.editSyncedLabelProject', { projectName }), {
        variant: 'success',
        action: key => (
          <Button
            variant="text"
            color="white"
            onClick={() => {
              history.push(`/${routeInfo.urlMatchInfo.accountName}/label/project/${id}/settings`);
              closeSnackbar(key);
            }}
          >
            {t('button.view')}
          </Button>
        ),
      });
    } catch (e) {}
  }

  const steps = {
    project: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.project.title'),
      isButtonEnabled: isProjectNameValid && projectName.length > 0,
      isOptional: false,
      summary: `${projectName}${projectDescription ? `, ${projectDescription}` : ''}`,
      content: (
        <ProjectNameAndDescription
          projectName={projectName}
          setProjectName={setProjectName}
          projectDescription={projectDescription}
          setProjectDescription={setProjectDescription}
          setIsProjectNameValid={setIsProjectNameValid}
        />
      ),
    },
    annotationTypeAndClass: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.annotationTypeAndClass.title'),
      isButtonEnabled:
        Object.keys(curateAnnotations?.box || {}).length +
          Object.keys(curateAnnotations?.polygon || {}).length >
        0,
      isOptional: false,
      summary: `${projectName}${projectDescription ? `, ${projectDescription}` : ''}`,
      content: (
        <AnnotationTypeAndClass
          isEditMode
          curateAnnotations={curateAnnotations}
          setCurateAnnotations={setCurateAnnotations}
        />
      ),
    },
    selfAssign: {
      title: t('curate.datasets.syncWithNewLabelProject.steps.selfAssign.title'),
      isButtonEnabled: true,
      isOptional: false,
      summary: selfAssign ? 'on' : 'off',
      content: <SelfAssign selfAssign={selfAssign} setSelfAssign={setSelfAssign} />,
    },
  };
  return (
    <Dialog state={state} aria-label="Sync with Curate dialog" hideOnClickOutside={false}>
      <Dialog.Header>
        {t('curate.datasets.editSyncedLabelProject.title', { projectName: labelProject.name })}
      </Dialog.Header>
      <Dialog.Content
        border="1px solid"
        borderColor="gray-150"
        borderRadius="2px"
        overflow="overlay"
        style={{ height: '482px', width: '720px' }}
      >
        {state.visible && <VerticalStepper steps={steps} />}
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant="text" onClick={handleCloseDialog}>
          {t('button.cancel')}
        </Button>
        <Button variant="strong-fill" color="primary" onClick={handleEdit}>
          {t('button.edit')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
