import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import { useNewProjectInfo } from '../../../../contexts/NewProjectContext';
import helper from './helper';

const useStyles = makeStyles(() => ({
  box: (props: any) => ({
    position: 'absolute',
    width: '100%',
    height: '6px',
    background: 'none',
    borderRadius: '4px',
    zIndex: (props.depth + 1) * 11,
    '&.prev': {
      top: 0,
    },
    '&.next': {
      bottom: 0,
    },
    '&.dragEntered': {
      background: '#c2c2c2',
    },
  }),
}));

// eslint-disable-next-line
const GroupItemDropBar = (props: any): ReactElement | null => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const newProjectInfo = useNewProjectInfo();
  const { dir, id, pl, depth, imageCategoryMap } = props;
  const classes = useStyles({ depth });

  const [isDragEnter, setIsDragEnter] = useState(false);

  if (
    !newProjectInfo.dragState ||
    newProjectInfo.dragState.id === id ||
    newProjectInfo.dragState.type !== 'imageCategoryGroup'
  )
    return null;

  const isChildOfParent = helper.nonManipulatingIsChildOfParent(
    imageCategoryMap,
    newProjectInfo.dragState.id,
    id,
  );

  if (isChildOfParent) return null;

  const fromGroup = imageCategoryMap[newProjectInfo.dragState.id];

  const hasSubgroupInFromGroup = !!(
    fromGroup.children[0] && imageCategoryMap[fromGroup.children[0]].children
  );

  if (hasSubgroupInFromGroup && depth !== 0) return null;

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDragEnter(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    helper.moveGroupToGroup(
      t,
      newProjectInfo,
      newProjectInfo.dragState.id,
      imageCategoryMap[id].parent,
      { dir, id },
      enqueueSnackbar,
    );

    setIsDragEnter(false);
    newProjectInfo.setDragState(null);
  };

  return (
    <MUI.Box
      className={`${classes.box} ${dir} ${isDragEnter ? 'dragEntered' : null}`}
      marginLeft={depth === 0 ? 0 : `${pl}px`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    />
  );
};

export default GroupItemDropBar;
