import { TFunction } from 'next-i18next';

import {
  IS_INVALID,
  IS_VALID_LENGTH_SHORT,
  PLEASE_CHECK_THE_RULES_BELOW,
  REQUIRED,
} from '../../../consts/AuthMessage';
import RegexUtils from '../../../utils/RegexUtils';

export const parseAccountName = (accountName: string): string => {
  return accountName
    .trimLeft()
    .split('')
    .reduce((prev, cur) => {
      if (RegexUtils.IS_ONLY_LOWER_UPPER(cur)) {
        return prev + cur.toLowerCase();
      } else if (RegexUtils.IS_ONLY_NUMERIC(cur)) {
        return prev + cur;
      } else if (prev.length && (cur === ' ' || cur === '-')) {
        return prev + '-';
      }

      return prev;
    }, '');
};

export const getEmptyFieldError =
  (t: TFunction) =>
  (value: string): string | null => {
    if (value === '') {
      return REQUIRED({ t });
    }

    return null;
  };

export const getValidLengthError =
  (t: TFunction) =>
  (value: string, min: number, max: number): string | null => {
    const { length } = value;

    if (length >= min && length <= max) {
      return null;
    }

    return IS_VALID_LENGTH_SHORT({ t, min, max });
  };

export class ErrorHandler {
  private callbacks: ((value: string) => string | null)[];
  private value: string | null;

  constructor() {
    this.callbacks = [];
    this.value = null;
  }

  private getError(value: string) {
    this.value = null;
    let error: string | null = null;

    for (const callback of this.callbacks) {
      error = callback(this.value ?? value);

      if (error) break;
    }

    return error;
  }

  public addValidator(callback: (value: string) => string | null) {
    this.callbacks.push(callback);

    return this;
  }

  public createInstance() {
    return this.getError.bind(this);
  }
}

export const getAccountNameError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string): string | null => {
      if (value.length !== 0) {
        const lastChar = value[value.length - 1];
        if (!RegexUtils.IS_ONLY_LOWER_UPPER_NUMERIC_HYPHEN(lastChar) && lastChar !== ' ') {
          return PLEASE_CHECK_THE_RULES_BELOW({ t });
        }
      }

      const parsedAccountName = parseAccountName(value);
      if (value.length !== 0 && parsedAccountName.length === 0) {
        return PLEASE_CHECK_THE_RULES_BELOW({ t });
      }

      return null;
    })
    .createInstance();

const PERSONAL_HOSTS = [
  'daum.',
  'foxmail.',
  'gmail.',
  'googlemail.',
  'hanmail.',
  'hotmail.',
  'icloud.',
  'kakao.',
  'nate.',
  'naver.',
  'outlook.',
  'protonmail.',
  'qq.',
  'yahoo.',
];

export function isPersonalEmail(email: string) {
  const emailHost = email.substr(email.indexOf('@') + 1).toLowerCase();
  return PERSONAL_HOSTS.some(host => emailHost.startsWith(host));
}

export const getNameError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string) => getValidLengthError(t)(value, 1, 50))
    .createInstance();

export const getEmailError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string) => getValidLengthError(t)(value, 1, 50))
    .addValidator((value: string): string | null => {
      if (value === '') {
        return null;
      } else if (!RegexUtils.IS_EMAIL(value)) {
        return IS_INVALID({ t, name: 'Email address' });
      }

      return null;
    })
    .createInstance();

export const getPasswordError = (t: TFunction) =>
  new ErrorHandler()
    .addValidator(getEmptyFieldError(t))
    .addValidator((value: string) => getValidLengthError(t)(value, 1, 50))
    .addValidator((value: string): string | null => {
      if (
        !RegexUtils.IS_LOWER(value) ||
        !RegexUtils.IS_UPPER(value) ||
        !RegexUtils.IS_NUMERIC(value) ||
        !RegexUtils.IS_SPECIAL(value) ||
        value.length < 12
      ) {
        return IS_INVALID({ t, name: 'Password' });
      }

      return null;
    })
    .createInstance();
