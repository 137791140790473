import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Box, LoadingIndicator } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../../../../../../analyticsTracker/pageIds';
import { useSetPageTitle } from '../../../../../../../contexts/AppContext';
import { ActionProvider } from '../../../../../contexts/ActionContext';
import { DiagnosisAnalyticsFilterProvider } from '../../../../../contexts/DiagnosisAnalyticsFilterContext';
import { DiagnosisCommonFilterProvider } from '../../../../../contexts/DiagnosisCommonFilterContext';
import { DiagnosisGridFilterProvider } from '../../../../../contexts/DiagnosisGridFilterContext';
import { useDiagnosisModelContext } from '../../../../../contexts/DiagnosisModelContext';
import {
  useDiagnosisDetailQuery,
  useLatestStartOrUpdateDiagnosisJob,
} from '../../../../../queries/diagnosisModelQueries';
import { Command, CommandType } from '../../../../../types/commandTypes';
import { ANALYTICS_VIEW, GRID_VIEW, ViewMode } from '../../../../../types/viewTypes';
import { DoneStatuses, InProgressStatuses } from '../../../../../utils/commandUtils';
import DiagnosisAnalyticsLayout from './analytics/Layout';
import GridLayout from './grid/Layout';
import LoadingProgressOverlay from './LoadingProgressOverlay';
import ModelsInfoArea from './modelInfo/ModelsInfoArea';
import { isDiagnosisActive, isDiagnosisFinished } from './types';
import { useDiagnosisPathname } from './useGetUrl';

export default function ModelDiagnosisContainer(): JSX.Element {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const view = (params.get('view') || ANALYTICS_VIEW) as ViewMode;
  const { datasetId, diagnosisId } = useParams<{ datasetId: string; diagnosisId: string }>();
  const {
    selectedDiagnosis,
    setSelectedDiagnosis,
    isLoadingDiagnosisList,
    jobInitiatedTimestamp,
    setJobInitiatedTimestamp,
  } = useDiagnosisModelContext();
  const diagnosisPathName = useDiagnosisPathname();
  const { t } = useTranslation();

  // TODO: validate
  useSetPageTitle(t('curate.dataset.menus.modelDiagnosis'), PAGE_TRACKING_ID.curateModelDiagnosis);

  function resetJobInitiatedTimestamp({ jobResult }: { jobResult?: Command<CommandType> }) {
    if (!jobInitiatedTimestamp || !jobResult) return;
    const createdAtTime = new Date(jobResult.createdAt).getTime();

    if (
      DoneStatuses.includes(jobResult.status) &&
      jobInitiatedTimestamp.timeStamp <= createdAtTime
    ) {
      setJobInitiatedTimestamp(undefined);
    }
  }

  const { data: jobResult } = useLatestStartOrUpdateDiagnosisJob({
    diagnosisId,
    jobInitiatedTimestamp,
    currentStatus: selectedDiagnosis?.status,
    handleSuccess: jobResult => {
      resetJobInitiatedTimestamp({ jobResult });
    },
  });

  const { refetch: refetchDiagnosisDetail } = useDiagnosisDetailQuery({
    diagnosisId,
    datasetId,
    handleSuccess: async data => {
      setSelectedDiagnosis(data);
    },
    diagnosisStatus: selectedDiagnosis?.status,
  });

  useEffect(() => {
    if (selectedDiagnosis && !isDiagnosisActive(selectedDiagnosis.status)) {
      history.replace(`/${diagnosisPathName}list?diagnosis_id=${selectedDiagnosis.id}`);
    }
  }, [selectedDiagnosis]);

  useEffect(() => {
    if (!jobInitiatedTimestamp) {
      refetchDiagnosisDetail();
    }
  }, [jobInitiatedTimestamp, jobResult?.status]);

  useEffect(() => {
    setJobInitiatedTimestamp(undefined);
  }, [diagnosisId]);

  if (isLoadingDiagnosisList) return <LoadingIndicator mt={3} />;

  const isDiagnosisJobFinished =
    selectedDiagnosis && isDiagnosisFinished(selectedDiagnosis?.status);

  const Contents = () => {
    const createdAtTimestamp = jobResult ? new Date(jobResult.createdAt).getTime() : 0;
    if (
      (jobResult && InProgressStatuses.includes(jobResult.status)) ||
      (jobInitiatedTimestamp && jobInitiatedTimestamp.timeStamp > createdAtTimestamp)
    )
      return <LoadingProgressOverlay />;
    if (isDiagnosisJobFinished) {
      return {
        analytics: (
          <DiagnosisAnalyticsFilterProvider>
            <DiagnosisAnalyticsLayout />
          </DiagnosisAnalyticsFilterProvider>
        ),
        grid: (
          <DiagnosisGridFilterProvider>
            <ActionProvider>
              <GridLayout />
            </ActionProvider>
          </DiagnosisGridFilterProvider>
        ),
      }[view as typeof ANALYTICS_VIEW | typeof GRID_VIEW];
    }
    return <LoadingProgressOverlay />;
  };

  return (
    <DiagnosisCommonFilterProvider>
      <Box display="flex" height="100%" flexDirection="column">
        <ModelsInfoArea />
        {Contents()}
      </Box>
    </DiagnosisCommonFilterProvider>
  );
}
