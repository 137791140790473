import { concat, map, reduce, values } from 'lodash';

import { Category } from '../../../../utils/LabelInterfaceUtils';
import { SortOption } from '../dataTypes/analyticsDataTypes';
import { CategoryData } from '../interfaces/apiResponse';
import { JsonObj } from '../userStats/types';
import { sortData } from './CategorySummaryTransformer';

/**
 * Aggregate different dataset results that belong to same propertyId
 */
export const catAggregateDatasets2011 = (datasetResults: JsonObj[]): JsonObj[] => {
  if (datasetResults.length <= 1) {
    return datasetResults;
  }

  /**
   * Group results into a list by property
   */
  const grouped = reduce(
    datasetResults,
    (agg, categoryInfo) => {
      const propertyId = categoryInfo.propertyId;
      const datasetResult = categoryInfo.data;
      if (propertyId in agg) {
        agg[propertyId] = concat(agg[propertyId], datasetResult);
        return agg;
      }
      // add categoryPropertyInfo if it dne
      return { ...agg, [propertyId]: datasetResult };
    },
    {} as JsonObj,
  );

  /**
   * Aggregate category data from different datasets by category option id
   */
  const output = {} as JsonObj;

  for (const propertyId in grouped) {
    output[propertyId] = reduce(
      grouped[propertyId],
      (agg, newCategory) => {
        const categoryId = newCategory.id;
        if (!(categoryId in agg)) {
          agg[categoryId] = newCategory;
          return agg;
        } else {
          const accumulated = agg[categoryId];
          agg[categoryId] = { ...accumulated, count: accumulated?.count + newCategory?.count };
          return agg;
        }
      },
      {} as JsonObj,
    );
  }

  // convert output from an object (propertyId -> propertyData as array)
  // to a list of objects, containing {data: merged propertyData as list, propertyId: string}
  return map(output, (v, k) => {
    return {
      data: values(v),
      propertyId: k,
    };
  });
};

/**
 * Adds groups (mapping of category group id to name) to category data
 **/
export const formatSiestaCategoryData2011 = (
  data: CategoryData, // update later
  propertyInfo: Category,
  sortX: SortOption,
  sortY: SortOption,
): JsonObj[] => {
  const getGroupsFrom = reduce(
    propertyInfo?.options,
    (agg: JsonObj, option) => {
      agg[option?.id] = option?.parents;
      return agg;
    },
    {},
  );

  const flatData = map(data?.data, option => {
    const assetGroup = option?.assetGroup;
    return {
      ...option,
      assetGroup,
      groups: getGroupsFrom[option?.id] || [],
    };
  });

  return sortData(flatData, sortY, sortX);
};
