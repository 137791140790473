import React from 'react';
import { ListChildComponentProps } from 'react-window';

import { Checkbox } from '@mui/material';
import { Badge, Box, Button, Icon, TextEllipsis, Typography } from '@superb-ai/norwegian-forest';

import { useAuthInfo } from '../../../contexts/AuthContext';
import { AccountRole, MemberData } from '../../../types/memberTypes';
import { daysAgo, formatRecentDistance } from '../../../utils/date';
import AccountMemberMenu from '../../pages/account/accountMembers/AccountMemberMenu';
import ProjectMemberMenu from '../../pages/settings/projectMembers/ProjectMemberMenu';
import RoleChip from '../RoleChip';
import UserNameWithAvatar from '../UserNameWithAvatar';
import AccountMemberProjectList from './AccountMemberProjectList';
import { MembersColumns } from './types';

const MembersTableRow: React.FC<ListChildComponentProps> = ({
  index,
  style,
  data: {
    members,
    columns,
    columnsWidth,
    selectedMembers,
    setSelectedMembers,
    setIsSelectedAll,
    onClickDelete,
    getIsDeletable,
    onClickChangeRole,
    onClickProjectAccess,
    projectListInfo,
  },
}) => {
  const member = members[index];
  const isDeletable = getIsDeletable(member);
  const isSelected =
    selectedMembers &&
    selectedMembers.findIndex((m: MemberData) => m.email === member.email) !== -1;
  const isUserNotActive = member.status === 'Invited';

  const authInfo = useAuthInfo();
  function isRequiredMfa(role: AccountRole) {
    return authInfo.mfaRequiredRoles.some(r => r.toUpperCase() === role.toUpperCase());
  }

  const mfaActive = member.mfaStatus === 'Verified';
  const mfaInactive = isRequiredMfa(member.tenantRole);

  const handleToggleSelectMember = (): void => {
    if (!isDeletable) return;
    if (!isSelected) {
      setSelectedMembers([...selectedMembers, member]);
    } else {
      setSelectedMembers(selectedMembers.filter((m: MemberData) => m.email !== member.email));
    }
    setIsSelectedAll(false);
  };

  const Cell = (column: MembersColumns) => {
    return {
      name: (
        <UserNameWithAvatar userInfo={member}>
          {isUserNotActive ? (
            <Typography variant="body3" themedColor={['grey', 300]}>
              Pending Activation
            </Typography>
          ) : (
            <Typography variant="body3">
              <TextEllipsis mode="tail" text={member.name} />
            </Typography>
          )}
        </UserNameWithAvatar>
      ),
      email: (
        <Typography variant="body3">
          <TextEllipsis mode="tail" text={member.email} />
        </Typography>
      ),
      role: <RoleChip memberRole={(member.projectRole || member.tenantRole).toLowerCase()} />,
      projects: <AccountMemberProjectList member={member} projectListInfo={projectListInfo} />,
      createdAt: (
        <Typography variant="body3">
          {formatRecentDistance(member.createdAt, daysAgo(7))}
        </Typography>
      ),
      lastLoginedAt: (
        <Typography variant="body3">
          {member.lastLoginedAt ? formatRecentDistance(member.lastLoginedAt, daysAgo(7)) : '-'}
        </Typography>
      ),
      deleteButtonArea: (
        <>
          {isDeletable ? (
            <Button
              variant="text"
              color="lightgrey"
              onClick={() => onClickDelete(member)}
              IconAdornment={<Icon name="trash" />}
            >
              Remove access to this project
            </Button>
          ) : (
            <></>
          )}
        </>
      ),
      accountMenuArea: (
        <AccountMemberMenu
          member={member}
          onClickDelete={onClickDelete}
          onClickProjectAccess={onClickProjectAccess}
          isUserNotActive={isUserNotActive}
          isDeletable={isDeletable}
        />
      ),
      projectMenuArea: (
        <ProjectMemberMenu
          member={member}
          onClickDelete={onClickDelete}
          onClickChangeRole={onClickChangeRole}
          isDeletable={isDeletable}
          isUserNotActive={isUserNotActive}
        />
      ),
      mfaStatus: mfaActive ? (
        <Badge size="s" color="green">
          <Icon name="shield" size="14px" />
        </Badge>
      ) : mfaInactive ? (
        <Badge size="s" color="carrot">
          <Icon name="shield" size="14px" />
        </Badge>
      ) : null,
    }[column];
  };

  return (
    <div style={style}>
      <Box
        display="flex"
        alignItems="center"
        height="56px"
        hover={isDeletable}
        selected={isSelected}
        onClick={handleToggleSelectMember}
        bb
      >
        <Box p={1} width={44} boxSizing="border-box">
          {isDeletable && (
            <Checkbox
              color="primary"
              tabIndex={-1}
              onClick={handleToggleSelectMember}
              checked={isSelected}
            />
          )}
        </Box>
        {columns.map((column: MembersColumns, index: number) => (
          <Box
            p={1}
            key={column}
            width={columnsWidth[index]}
            style={{ padding: column.endsWith('MenuArea') ? '0' : undefined }}
          >
            {Cell(column)}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default MembersTableRow;
