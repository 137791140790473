import { User } from '@superb-ai/icons';
import { Icon } from '@superb-ai/ui';

import { PAGE_TRACKING_ID } from '../../analyticsTracker/pageIds';
import { isOwnerOrAdminOrSystemAdmin } from '../../contexts/AuthContext';
import { CreateMenuItem } from '../../menu/MenuItem';
import Layout from './Layout';

export const UsersMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.accountUsers,
  path: 'users',
  group: 'account',
  title: ({ t }) => t('users.title'),
  component: Layout,
  icon: <Icon icon={User} />,
  isEnabled({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo);
  },
  isVisible({ authInfo }) {
    return isOwnerOrAdminOrSystemAdmin(authInfo) || authInfo?.isGuest || false;
  },
});
