import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Input, Typography } from '@superb-ai/ui';
import { trim } from 'lodash';

import RangeConst from '../../../../../../../consts/RangeConst';

export default function ApplyTags({
  appliedTags,
  setAppliedTags,
  setIsTagValid,
}: {
  appliedTags: string;
  setAppliedTags: (value: string) => void;
  setIsTagValid: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [nameInvalidReasons, setNameInvalidReasons] = useState<string[]>();

  function checkTagNamingRule(tag: string) {
    const isLengthValid = tag.length >= RangeConst.TAG.min && tag.length <= RangeConst.TAG.max;

    if (isLengthValid || tag.length === 0) {
      setNameInvalidReasons(undefined);
      setIsTagValid(true);
    } else {
      setNameInvalidReasons([t('labels.editTag.tagNamingRule')]);
      setIsTagValid(false);
    }
  }

  return (
    <Box position="relative">
      <Input
        placeholder={t('curate.datasets.syncWithNewLabelProject.steps.applyTags.placeholder')}
        variant="stroke"
        value={appliedTags}
        onChange={e => {
          const trimValue = trim(e.target.value);
          setAppliedTags(trimValue);
          checkTagNamingRule(trimValue);
        }}
      />
      {nameInvalidReasons && (
        <Box
          mt={0.25}
          position="absolute"
          width="100%"
          p={1}
          border="1px solid"
          borderColor="primary-400"
          backgroundColor="primary-100"
          borderRadius="2px"
          display="flex"
          flexDirection="column"
          gap={0.25}
        >
          {nameInvalidReasons.map(reason => (
            <Typography key={reason} variant="m-regular" color="primary">
              {reason}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
}
