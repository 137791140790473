import { PAGE_TRACKING_ID } from '../../../../../analyticsTracker/pageIds';
import LabelQATab from '../../../../../components/pages/analytics/AnalyticsLabelQATab';
import {
  NOT_ENABLED_FOR_PROJECT,
  NOT_ENABLED_FOR_PROJECT_ADMIN,
} from '../../../../../consts/FeatureMessage';
import { canManageProject, isOwnerOrAdminOrSystemAdmin } from '../../../../../contexts/AuthContext';
import { CreateMenuItem } from '../../../../../menu';
import { getUrl } from '../../../../../routes/util';
import ProjectUtils from '../../../../../utils/ProjectUtils';

export const ProjectAnalyticsQAMenuItem = CreateMenuItem({
  pageId: PAGE_TRACKING_ID.labelProjectQAAnalytics,
  path: 'qa',
  title: ({ t }) => t('analytics.qa.title'),
  component: LabelQATab,
  isVisible({ authInfo, project, featureFlags }) {
    const featureAvailable =
      (!!project &&
        featureFlags?.consensusLabeling?.enabled &&
        ProjectUtils.isAvailableAdvancedQA(project.workapp)) ||
      false;
    return !!authInfo && canManageProject(authInfo) && featureAvailable;
  },
  isEnabled({ project }) {
    const isSettingEnabled = project?.settings?.allowAdvancedQa || false;
    return isSettingEnabled;
  },
  disabledMessage({ t, authInfo, project }) {
    if (authInfo && project && isOwnerOrAdminOrSystemAdmin(authInfo)) {
      return NOT_ENABLED_FOR_PROJECT_ADMIN(
        t,
        t('projectSettings.configuration.advancedQA'),
        getUrl([authInfo.accountName || '', 'project', project.id || '', 'settings']),
      );
    }
    return NOT_ENABLED_FOR_PROJECT(t, t('projectSettings.configuration.advancedQA'));
  },
});
