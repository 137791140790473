import React, { useEffect, useState } from 'react';

import { getProjectSteps } from '../configs/NewProjectStepsConfig';
import { initialObjectClassGroupMap, initImageCategories } from '../consts/NewProjectConst';
import { WorkApp } from '../union/WorkappUnion';
import { StateGetterSetter } from './types';

const mock = {
  newProjectType: 'CREATE',
  steps: [
    { title: 'Create Project', settingPage: 'CREATE_PROJECT', activeStep: 0 },
    {
      title: 'Object Tracking',
      settingPage: 'OBJECT_DETECTION',
      hasInnerSteps: true,
      contents: [
        {
          title: 'Class & Property',
          settingPage: 'CLASS_PROPERTY',
          isOptional: false,
          activeStep: 1,
          innerStep: 0,
        },
        {
          title: 'Class Group',
          settingPage: 'CLASS_GROUP',
          isOptional: true,
          activeStep: 1,
          innerStep: 1,
        },
      ],
      activeStep: 1,
    },
  ],
  currentStep: {
    title: 'Class & Property',
    settingPage: 'CLASS_PROPERTY',
    isOptional: false,
    activeStep: 1,
    innerStep: 0,
  },
  projectName: '20210402-test',
  selectedDataType: 'image beta',
  selectedWorkapp: 'image-siesta' as WorkApp,
  selectedAnnotationTypes: { KEYPOINT: true },
  objectClasses: [
    {
      id: '411b6235-f9fc-4f26-8bc0-8cf63e61dd85',
      name: 'Untitled Object Class',
      annotationType: 'SELECT_TYPE',
      color: '#FF625A',
      properties: [],
      minCount: '',
      maxCount: '',
      config: {},
    },
  ],
};

const useMock = false;

// prettier-ignore
type NewProjectContextProps =
  StateGetterSetter<['version', 'setVersion'], string | null> &
  StateGetterSetter<['projectId', 'setProjectId'], string> &
  StateGetterSetter<['steps', 'setSteps'], any> &
  StateGetterSetter<['currentStep', 'setCurrentStep'], any> &
  StateGetterSetter<['dragState', 'setDragState'], any> &
  StateGetterSetter<['createState', 'setCreateState'], any> &
  StateGetterSetter<['projectName', 'setProjectName'], string> &
  StateGetterSetter<['isRequiredCheckDuplicateName', 'setIsRequiredCheckDuplicateName'], boolean> &
  StateGetterSetter<['projectNameErrorMessages', 'setProjectNameErrorMessages'], any> &
  StateGetterSetter<['description', 'setDescription'], any> &
  StateGetterSetter<['selectedDataType', 'setSelectedDataType'], string> &
  StateGetterSetter<['selectedWorkapp', 'setSelectedWorkapp'], WorkApp | undefined> &
  StateGetterSetter<['selectedAnnotationTypes', 'setSelectedAnnotationTypes'], any> &
  StateGetterSetter<['isPublic', 'setIsPublic'], boolean> &
  StateGetterSetter<['allowSelfAssign', 'setAllowSelfAssign'], boolean> &
  StateGetterSetter<['allowAdvancedQa', 'setAllowAdvancedQa'], boolean> &
  StateGetterSetter<['imageCategories', 'setImageCategories'], any> &
  StateGetterSetter<['selectedImageCategoryIndex', 'setSelectedImageCategoryIndex'], any> &
  StateGetterSetter<['selectedCategories', 'setSelectedCategories'], any> &
  StateGetterSetter<['selectedCategoryGroupId', 'setSelectedCategoryGroupId'], any> &
  StateGetterSetter<['objectClasses', 'setObjectClasses'], any> &
  StateGetterSetter<['selectedObjectClassIndex', 'setSelectedObjectClassIndex'], any> &
  StateGetterSetter<['selectedClassPropertyIndex', 'setSelectedClassPropertyIndex'], any> &
  StateGetterSetter<['keypointList', 'setKeypointList'], any> &
  StateGetterSetter<['isKeypointDialogOpen', 'setIsKeypointDialogOpen'], boolean> &
  StateGetterSetter<['objectClassGroupMap', 'setObjectClassGroupMap'], any> &
  StateGetterSetter<['selectedObjectClassIds', 'setSelectedObjectClassIds'], any> &
  StateGetterSetter<['selectedObjectClassGroupId', 'setSelectedObjectClassGroupId'], any> &
  StateGetterSetter<['openedObjectClassGroupIds', 'setOpenedObjectClassGroupIds'], any>;

export const NewProjectContext = React.createContext({} as NewProjectContextProps);

export const NewProjectProvider: React.FC = ({ children }) => {
  const [version, setVersion] = useState<string | null>(null);
  // event
  const [dragState, setDragState] = useState<any>(null);
  const [createState, setCreateState] = useState<any>(null);

  // meta
  const [projectId, setProjectId] = useState('');
  const [steps, setSteps] = useState(mock.steps);
  const [currentStep, setCurrentStep] = useState(mock.currentStep);

  // create project
  const [projectName, setProjectName] = useState(!useMock ? '' : mock.projectName);
  const [isRequiredCheckDuplicateName, setIsRequiredCheckDuplicateName] = useState(true);
  const [projectNameErrorMessages, setProjectNameErrorMessages] = useState<any[]>([]);
  const [description, setDescription] = useState('');
  const [selectedDataType, setSelectedDataType] = useState(!useMock ? '' : mock.selectedDataType);
  const [selectedWorkapp, setSelectedWorkapp] = useState(
    !useMock ? undefined : mock.selectedWorkapp,
  );
  const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState(
    !useMock ? {} : mock.selectedAnnotationTypes,
  );
  const [isPublic, setIsPublic] = useState(false);
  const [allowSelfAssign, setAllowSelfAssign] = useState(true);
  const [allowAdvancedQa, setAllowAdvancedQa] = useState(false);

  // image category
  const [imageCategories, setImageCategories] = useState(initImageCategories);
  const [selectedImageCategoryIndex, setSelectedImageCategoryIndex] = useState(-1);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategoryGroupId, setSelectedCategoryGroupId] = useState('');

  // object detection
  const [objectClasses, setObjectClasses] = useState<any[]>(!useMock ? [] : mock.objectClasses);
  const [selectedObjectClassIndex, setSelectedObjectClassIndex] = useState(-1);
  const [selectedClassPropertyIndex, setSelectedClassPropertyIndex] = useState(-1);

  const [keypointList, setKeypointList] = useState([]);
  const [isKeypointDialogOpen, setIsKeypointDialogOpen] = useState(false);

  // object detection group
  const [objectClassGroupMap, setObjectClassGroupMap] = useState(initialObjectClassGroupMap);

  const [selectedObjectClassIds, setSelectedObjectClassIds] = useState({});
  const [selectedObjectClassGroupId, setSelectedObjectClassGroupId] = useState('');
  const [openedObjectClassGroupIds, setOpenedObjectClassGroupIds] = useState({});

  const resetWhenStepChanged = () => {
    setDragState(null);
  };

  useEffect(() => {
    const steps = getProjectSteps({});
    setSteps(steps);
    setCurrentStep(steps[0]);
  }, []);

  useEffect(() => {
    resetWhenStepChanged();
  }, [currentStep]);

  useEffect(() => {
    if (!createState) return;
    setCreateState(null);
  }, [createState]);

  useEffect(() => {
    if (!isPublic) return;
    if (!allowAdvancedQa) return;
    setAllowAdvancedQa(false);
    // eslint-disable-next-line
  }, [isPublic]);

  return (
    <NewProjectContext.Provider
      value={{
        version,
        setVersion,
        // meta
        projectId,
        setProjectId,
        steps,
        setSteps,
        currentStep,
        setCurrentStep,
        dragState,
        setDragState,
        createState,
        setCreateState,

        // create project
        projectName,
        setProjectName,
        isRequiredCheckDuplicateName,
        setIsRequiredCheckDuplicateName,

        projectNameErrorMessages,
        setProjectNameErrorMessages,
        description,
        setDescription,
        selectedDataType,
        setSelectedDataType,
        selectedWorkapp,
        setSelectedWorkapp,
        selectedAnnotationTypes,
        setSelectedAnnotationTypes,
        isPublic,
        setIsPublic,
        allowSelfAssign,
        setAllowSelfAssign,
        allowAdvancedQa,
        setAllowAdvancedQa,

        // image category
        imageCategories,
        setImageCategories,
        selectedImageCategoryIndex,
        setSelectedImageCategoryIndex,
        selectedCategories,
        setSelectedCategories,
        selectedCategoryGroupId,
        setSelectedCategoryGroupId,

        // object detection
        objectClasses,
        setObjectClasses,
        selectedObjectClassIndex,
        setSelectedObjectClassIndex,
        selectedClassPropertyIndex,
        setSelectedClassPropertyIndex,
        keypointList,
        setKeypointList,
        isKeypointDialogOpen,
        setIsKeypointDialogOpen,

        // object detection group
        objectClassGroupMap,
        setObjectClassGroupMap,
        selectedObjectClassIds,
        setSelectedObjectClassIds,
        selectedObjectClassGroupId,
        setSelectedObjectClassGroupId,
        openedObjectClassGroupIds,
        setOpenedObjectClassGroupIds,
      }}
    >
      {children}
    </NewProjectContext.Provider>
  );
};

export const useNewProjectInfo = (): NewProjectContextProps => {
  return React.useContext(NewProjectContext);
};
