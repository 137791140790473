import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Sync } from '@superb-ai/icons';
import { Button, Icon, Tooltip } from '@superb-ai/ui';

import { useCurateCommandContext } from '../../../../../contexts/CommandContext';
import { useDatasetContext } from '../../../../../contexts/DatasetContext';
import { JobInitiatedTimestamp } from '../../../../../contexts/DiagnosisModelContext';
import { usePublicDatasetContext } from '../../../../../contexts/PublicDatasetContextProvider';
import { SampleDatasetTooltip } from '../../../../../elements/SampleDatasetTooltip';
import { useStartOrUpdateDiagnosisMutation } from '../../../../../queries/diagnosisModelQueries';
import { DiagnosisDetail } from '../../../../../services/DiagnosisModelService';

export default function UpdateDiagnosisButton(props: {
  selectedDiagnosis: DiagnosisDetail;
  setJobInitiatedTimestamp: (timeStamp: JobInitiatedTimestamp) => void;
}) {
  const { selectedDiagnosis, setJobInitiatedTimestamp } = props;
  const history = useHistory();
  const { datasetId } = useDatasetContext();
  const { showPublicDatasets } = usePublicDatasetContext();
  const { t } = useTranslation();

  const commandContext = useCurateCommandContext();
  const { mutate: updateDiagnosis, isError: isUpdateDiagnosisError } =
    useStartOrUpdateDiagnosisMutation(commandContext);

  const handleUpdateDiagnosis = async () => {
    await updateDiagnosis({
      datasetId,
      diagnosisId: selectedDiagnosis.id,
      modelId: selectedDiagnosis.modelId,
      modelSource: selectedDiagnosis.modelSource,
      jobName: 'UPDATE_DIAGNOSIS',
      annotationType: selectedDiagnosis.iouType === 'bbox' ? 'box' : 'polygon',
      createdAt: selectedDiagnosis.createdAt,
    });

    if (!isUpdateDiagnosisError) {
      setJobInitiatedTimestamp({ jobType: 'UPDATE_DIAGNOSIS', timeStamp: new Date().getTime() });
    }
  };

  const needsUpdate =
    (selectedDiagnosis.updatedImageCount || 0) + (selectedDiagnosis.deletedImageCount || 0) > 0;
  const isDeletedModel = !selectedDiagnosis.modelName;

  function TooltipMessage() {
    if (showPublicDatasets) {
      return <SampleDatasetTooltip feature="updateDiagnosis" />;
    }

    const tooltipText = isDeletedModel
      ? t('curate.diagnosis.text.modelDeleted')
      : needsUpdate
      ? t('curate.diagnosis.text.pleaseUpdateDiagnosis')
      : t('curate.diagnosis.text.diagnosisUpToDate');

    return tooltipText;
  }

  return (
    <Tooltip content={TooltipMessage()} placement="bottom">
      <span>
        <Button
          variant="strong-fill"
          size="m"
          color="primary"
          disabled={!needsUpdate || isDeletedModel || showPublicDatasets}
          onClick={handleUpdateDiagnosis}
        >
          <Icon icon={Sync} />
          {t('curate.diagnosis.action.updateDiagnosis')}
        </Button>
      </span>
    </Tooltip>
  );
}
