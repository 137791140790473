import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlertModal } from '@superb-ai/norwegian-forest';
import { Box, Button, Typography } from '@superb-ai/ui';

import { DEFAULT_ERROR } from '../../../consts/ModalMessage';
import { useAuthInfo } from '../../../contexts/AuthContext';
import { useRouteInfo } from '../../../contexts/RouteContext';
import { useApiDefaultParams } from '../../../hooks/ApiParamsHook';
import { useWindowEventListener } from '../../../hooks/useWindowEventListener';
import TotpService from '../../../services/TotpService';
import { TotpAssociateSteps } from '../../elements/auth/TotpAssociateDialog/TotpAssociateSteps';
import bg from './assets/dot_bg.png';
import { useChangeContent } from './Layout';
import Logo from './Logo';
import MfaProblems from './MfaProblems';
import ShortCut from './ShortCut';

const origin = 'components/pages/auth/setup-mfa/Layout.tsx';

export default function MfaSetupLayout() {
  const [step, setStep] = useState<0 | 1>(0);
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState<null | string>(null);
  const [isSending, setIsSending] = useState(false);
  const routeInfo = useRouteInfo();
  const authInfo = useAuthInfo();
  const apiDefaultParams = useApiDefaultParams();
  const params = useMemo(() => ({ ...apiDefaultParams, origin }), [apiDefaultParams]);
  const { t } = useTranslation();
  const { closeModal, openModal } = useAlertModal();
  const isSecretCodeRequested = useRef(false);

  useChangeContent('graphic');

  /**
   * Normally isGuest is only false when URL's accountName matches auth accountName,
   * which isn't the case here. It's a bit of a hack.
   */
  useEffect(() => {
    if (authInfo.isGuest) {
      authInfo.setIsGuest(false);
    }
  }, [authInfo]);

  useEffect(() => {
    if (authInfo.isGuest || isSecretCodeRequested.current) return;
    (async () => {
      isSecretCodeRequested.current = true;
      const { secretCode } = await TotpService.getSecret(params);
      setSecret(secretCode);
    })();
  }, [authInfo]);

  const getValid = () => {
    if (code.length !== 6) {
      return false;
    }

    return true;
  };

  const onClickNext = async () => {
    if (step === 0) {
      setStep(1);
      return;
    } else if (!getValid()) {
      return;
    }

    try {
      setIsSending(true);
      await TotpService.verify({
        ...params,
        code,
      });
      routeInfo.history.push(`/${authInfo.accountName}`);
    } catch (e: any) {
      openModal(
        DEFAULT_ERROR({
          t,
          title: 'OTP authentication failed',
          message: 'OTP number is not correct.',
          onClickMainButton: closeModal,
        }),
      );
    } finally {
      setIsSending(false);
    }
  };

  useWindowEventListener(
    'keydown',
    e => {
      if (step === 1 && e.key === 'Enter') {
        onClickNext();
      }
    },
    [step, onClickNext],
  );

  const onClickPrev = () => {
    setStep(0);
  };

  return (
    <Box
      position="absolute"
      backgroundColor="white"
      style={{ width: '200%', height: '100%', left: '-100%' }}
    >
      <img
        src={bg.src}
        width="100%"
        height="100%"
        style={{ position: 'absolute', objectFit: 'cover', top: 0, left: 0 }}
      />
      <Box
        p={8}
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        <Typography variant="h2" color="gray-400">
          {t('auth.mfa.setup.setupIsRequired')}
        </Typography>
        <Box borderRadius="2px" backgroundColor="gray-200" px={2} py={1} my={1}>
          <Typography variant="l-strong">
            {t('auth.mfa.setup.welcomeUser', { email: authInfo.email })}
          </Typography>
        </Box>
        <TotpAssociateSteps
          code={code}
          onCodeChange={setCode}
          secret={secret}
          step={step}
          layout="full"
        />
        <Box width="50%" display="flex" gap={1}>
          {step === 1 && (
            <Button variant="text" size="l" onClick={onClickPrev} style={{ flex: 1 }}>
              {t('button.back')}
            </Button>
          )}
          <Button
            color="primary"
            variant="strong-fill"
            size="l"
            onClick={onClickNext}
            loading={isSending}
            loadingText={t('button.saving')}
            style={{ flex: 2 }}
          >
            {step === 0 ? t('button.next') : t('button.submit')}
          </Button>
        </Box>
        <MfaProblems />
      </Box>
      <ShortCut page="signUp" />
      <Logo style={{ left: '50px', top: '50px' }} />
    </Box>
  );
}
