/**
 * Check if browser supports overflow: overlay.
 * Notably, it is not supported in Firefox.
 * See https://github.com/facebook/react/issues/6467#issuecomment-354781011
 */
function getSupportsOverflowOverlay(): boolean {
  if (typeof document === 'undefined') {
    return false;
  }
  const elem = document.createElement('div');
  elem.style.overflow = 'overlay';
  return elem.style.overflow === 'overlay';
}

export const supportsOverflowOverlay = getSupportsOverflowOverlay();

export function overflowOverlayOrAuto(): 'overlay' | 'auto' {
  return supportsOverflowOverlay ? 'overlay' : 'auto';
}
