import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { UserApiKey } from '../../../../../services/UserApiKeyService';
import GridTable from '../GridTable';
import ManageMembersTableRow from './ManageMembersTableRow';
interface Props {
  data?: { pages: { objects: UserApiKey[]; lastEvaluatedKey?: string }[] };
  openConfirmDialog: (id: string) => void;
  openEditDialog: (id: string) => void;
}

export default function ManageMembersTable({ data, openConfirmDialog, openEditDialog }: Props) {
  const { t } = useTranslation();
  return (
    <GridTable>
      <GridTable.Thead>
        <GridTable.Tr>
          <GridTable.Th width="2fr">{t('settings.advanced.accessKeyName')}</GridTable.Th>
          <GridTable.Th width="2fr">{t('settings.advanced.userName')}</GridTable.Th>
          <GridTable.Th width="2fr">{t('settings.profile.email')}</GridTable.Th>
          <GridTable.Th width="2fr">{t('settings.advanced.created')}</GridTable.Th>
          <GridTable.Th width="2fr">{t('settings.advanced.expiryDate')}</GridTable.Th>
          <GridTable.Th width="80px">{t('labels.table.labelStatus')}</GridTable.Th>
          <GridTable.Th width="60px"></GridTable.Th>
        </GridTable.Tr>
      </GridTable.Thead>
      <GridTable.Tbody>
        {data?.pages.map((page, index) => (
          <Fragment key={index}>
            {page.objects.map(key => (
              <ManageMembersTableRow
                key={key.apiKeyId}
                openConfirmDialog={() => openConfirmDialog(key.apiKeyId)}
                openEditDialog={() => openEditDialog(key.apiKeyId)}
                data={key}
              />
            ))}
          </Fragment>
        ))}
      </GridTable.Tbody>
    </GridTable>
  );
}
