import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { StateGetterSetter } from '../../../contexts/types';
import { Split } from '../types/evaluationTypes';
import { ANALYTICS_VIEW, GRID_VIEW, VIEW, ViewMode } from '../types/viewTypes';

export type DiagnosisCommonFilterContextProps = StateGetterSetter<
  ['splitIn', 'setSplitIn'],
  Split[]
> &
  StateGetterSetter<['sliceId', 'setSliceId'], string | undefined>;

const Context = React.createContext({} as DiagnosisCommonFilterContextProps);

const useProvider = () => {
  const [splitIn, setSplitIn] = useState<Split[]>(['VAL']);
  const [sliceId, setSliceId] = useState<string>();
  const history = useHistory();
  const { location: search } = history;
  const params = new URLSearchParams(search.search);
  const view = (params.get(VIEW) || GRID_VIEW) as ViewMode;

  useEffect(() => {
    if (view === ANALYTICS_VIEW && splitIn.length > 1) {
      setSplitIn(['VAL']);
      setSliceId(undefined);
    }
  }, [view]);

  useEffect(() => {
    setSliceId(undefined);
  }, [splitIn]);

  return { splitIn, setSplitIn, sliceId, setSliceId };
};

export const useDiagnosisCommonFilterContext = (): DiagnosisCommonFilterContextProps => {
  return React.useContext(Context);
};

export const DiagnosisCommonFilterProvider: React.FC = ({ children }) => {
  const info = useProvider();
  return <Context.Provider value={info}>{children}</Context.Provider>;
};
