import { eachDayOfInterval, eachMonthOfInterval, startOfMonth } from 'date-fns';
import { TFunction } from 'next-i18next';

import DateUtils from '../../../../../utils/DateUtils';
import { convertMeteringUnit } from '../styles';
import {
  AggregationUnit,
  DateRange,
  ProductByFeature,
  SupportedProductTypes,
  UsageByProductFeature,
} from '../types';

export function initializeUsage(productTypes: ProductByFeature[]) {
  return productTypes.reduce((usage, product) => {
    usage[product] = 0;
    return usage;
  }, {} as Record<ProductByFeature, number>);
}

export function fillMissingMonths(
  t: TFunction,
  data: UsageByProductFeature[],
  min: string,
  max: string,
): UsageByProductFeature[] {
  const startDate = startOfMonth(new Date(min));
  const endDate = startOfMonth(new Date(max));
  const monthsInRange = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  const usageByMonth = monthsInRange.reduce((obj, firstDayOfMonth) => {
    const initializedUsage = initializeUsage(SupportedProductTypes);
    obj[DateUtils.getFormattedDate(firstDayOfMonth)] = { ...initializedUsage };
    return obj;
  }, {} as Record<string, Record<ProductByFeature, number>>);

  data.forEach(record => {
    const month = DateUtils.getFormattedDate(startOfMonth(new Date(record.unit)));
    const usageType = record.productByFeature;
    const count = record.count;
    usageByMonth[month][usageType] += count;
  });
  const filledData: UsageByProductFeature[] = [];

  Object.keys(usageByMonth).forEach(month => {
    for (const product of SupportedProductTypes) {
      filledData.push({
        unit: new Date(month).toUTCString(), // conversion for chart rendering
        productByFeature: product,
        group: translateProductKey(product, t),
        count: usageByMonth[month][product],
      });
    }
  });

  return filledData;
}

function addBoundaryDates(data: UsageByProductFeature[], range: DateRange, unit: AggregationUnit) {
  const rangeFromInUnit = unit === 'daily' ? range.from : range.from.slice(0, 7);
  const rangeToInUnit = unit === 'daily' ? range.to : range.to.slice(0, 7);
  const isMissingFirstDate = data[0].unit !== rangeFromInUnit;
  const isMissingLastDate = data[data.length - 1].unit !== rangeToInUnit;
  if (isMissingFirstDate) {
    SupportedProductTypes.map(productByFeature => {
      data.unshift({
        unit: rangeFromInUnit,
        productByFeature: productByFeature as ProductByFeature,
        count: 0,
      });
    });
  }
  if (isMissingLastDate) {
    SupportedProductTypes.map(productByFeature => {
      data.push({
        unit: rangeToInUnit,
        productByFeature: productByFeature as ProductByFeature,
        count: 0,
      });
    });
  }
  return data;
}

export const transformCreditData = (
  data: readonly UsageByProductFeature[],
  unit: AggregationUnit,
  dateRange: DateRange,
  t: TFunction,
) => {
  if (!data.length) return [];
  let result = data.map(row => ({
    ...row,
    count: convertMeteringUnit(row['count'], 'common:ai-credit'),
  }));
  result = addBoundaryDates(result, dateRange, unit);
  result = result
    .sort(function (a, b) {
      return Date.parse(a.unit) - Date.parse(b.unit);
    })
    .map(d => {
      return { ...d, unit: new Date(d.unit).toUTCString() };
    });
  const fillMissing = unit === 'daily' ? fillMissingDates : fillMissingMonths;
  result = fillMissing(t, result, result[0].unit, result[result.length - 1].unit);
  return result;
};

function getDatesInRange(min: string, max: string): string[] {
  const dateRange: string[] = [];
  const startDate = new Date(min);
  const endDate = new Date(max);
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  days.forEach(date => {
    dateRange.push(DateUtils.getFormattedDate(date));
  });
  return dateRange;
}

function translateProductKey(value: string, t: TFunction) {
  return t(`metering.usageChart.${value}`);
}

export function fillMissingDates(
  t: TFunction,
  data: UsageByProductFeature[],
  min: string,
  max: string,
): UsageByProductFeature[] {
  const dateRange = getDatesInRange(min, max);
  const filledData: UsageByProductFeature[] = [];
  const usageByDate = dateRange.reduce((obj, date) => {
    obj[date] = initializeUsage(SupportedProductTypes);
    return obj;
  }, {} as Record<string, Record<ProductByFeature, number>>);

  data.forEach(record => {
    const date = DateUtils.getFormattedDate(new Date(record.unit));
    const usageType = record.productByFeature;
    const count = record.count;
    usageByDate[date][usageType] += count;
  });

  Object.keys(usageByDate).forEach(date => {
    for (const product of SupportedProductTypes) {
      filledData.push({
        unit: date,
        productByFeature: product,
        group: translateProductKey(product, t),
        count: usageByDate[date][product],
      });
    }
  });

  return filledData;
}
