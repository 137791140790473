import React, { useEffect, useRef, useState } from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';
import { filter, map, reverse } from 'lodash';

// import {useLabelDetailViewInfo} from '../../../../contexts/LabelDetailViewContext'
import { useLabelIssues } from '../../../../contexts/LabelIssuesContext';
import { overflowOverlayOrAuto } from '../../../../utils/style';
import Emoji from '../../../elements/Emoji';
import CabinetIssueCard from './CabinetIssueCard';
import CabinetIssueStatusController from './CabinetIssueStatusController';
import CabinetIssueStatusSelect from './CabinetIssueStatusSelect';

const useStyles = makeStyles(() => ({
  box: {
    flexGrow: 1,
    background: '#fff',
    padding: '0px 8px 0px 0px',
    borderRadius: '6px 0px 0px 0px',
    overflow: overflowOverlayOrAuto(),
  },
  progressBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cardBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    transition: '0.3s',
  },
  messageBox: {
    display: 'flex',
    flex: '1',
    width: '100%',
    marginTop: '20px',
    justifyContent: 'center',
  },
  message: {
    fontSize: '16px',
    color: '#635c5c',
    marginBottom: '50px',
  },
}));

const CabinetIssue = () => {
  const boxRef = useRef<HTMLDivElement>();
  const cardRef = useRef<HTMLDivElement>();
  const classes = useStyles();
  const labelIssuesInfo = useLabelIssues();

  const { issues, issueViewStatus, openBadgeInfo } = labelIssuesInfo;

  const [cardList, setCardList] = useState<any[]>([]);
  const [selectedCardMap, setSelectedCardMap] = useState({});

  useEffect(() => {
    const nextCardList = reverse(
      filter(issues, issue => issue.status === issueViewStatus),
    ) as any[];
    setCardList(nextCardList);
  }, [issues, issueViewStatus]);

  useEffect(() => {
    const nextSelectedCardMap = {};
    for (let i = 0; i < cardList.length; i++) {
      const curCard = cardList[i];
      // @ts-ignore: hash에 key 값이 있음을 보장해야함
      if (selectedCardMap[curCard.id]) {
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        nextSelectedCardMap[curCard.id] = selectedCardMap[curCard.id];
      } else {
        // @ts-ignore: hash에 key 값이 있음을 보장해야함
        nextSelectedCardMap[curCard.id] = false;
      }
    }
    setSelectedCardMap(nextSelectedCardMap);
    // eslint-disable-next-line
  }, [cardList]);

  useEffect(() => {
    setTimeout(() => {
      if (!boxRef || !boxRef.current) return;
      if (!cardRef || !cardRef.current) return;
      if (!openBadgeInfo?.issue) return;

      boxRef.current.scrollTo({
        top: cardRef.current.offsetTop,
        behavior: 'smooth',
      });
    }, 0);
  }, [openBadgeInfo]);

  const renderCabinetIssue = (): React.ReactElement => {
    if (cardList.length === 0) {
      return (
        <MUI.Box className={classes.messageBox}>
          <MUI.Typography className={classes.message}>
            <Emoji symbol="👈" /> Click to open the issue
          </MUI.Typography>
        </MUI.Box>
      );
    }
    return (
      <MUI.Box className={classes.cardBox}>
        {map(cardList, issue => (
          <CabinetIssueCard
            cardRef={
              openBadgeInfo.issue && issue.threadNumber === openBadgeInfo.issue.threadNumber
                ? cardRef
                : null
            }
            key={issue.id}
            issue={issue}
            // @ts-ignore: hash에 key 값이 있음을 보장해야함
            checked={selectedCardMap[issue.id]}
            selectedCardMap={selectedCardMap}
            setSelectedCardMap={setSelectedCardMap}
          />
        ))}
      </MUI.Box>
    );
  };

  return (
    <MUI.Box className={classes.box}>
      <CabinetIssueStatusSelect />
      <CabinetIssueStatusController
        selectedCardMap={selectedCardMap}
        setSelectedCardMap={setSelectedCardMap}
      />
      {renderCabinetIssue()}
    </MUI.Box>
  );
};

export default CabinetIssue;
