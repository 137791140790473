import { EmbeddingDatum } from '../../types';

const SCATTER_AREA_MARGIN = 20;
/** Scale x & y values - originally between [0,1] - to fit canvas */
export function resizeX(x_orig: EmbeddingDatum['x'], width: number) {
  return x_orig * (width - SCATTER_AREA_MARGIN * 2) + SCATTER_AREA_MARGIN;
}

export function resizeY(y_orig: EmbeddingDatum['y'], height: number) {
  return y_orig * (height - SCATTER_AREA_MARGIN * 2) + SCATTER_AREA_MARGIN;
}

export function resizeXInverse(x: number, width: number) {
  // clip to [0,1]
  // this is best we can do, since selection box is orignally drawn in canvas coordinates
  const x_orig = (x - SCATTER_AREA_MARGIN) / (width - SCATTER_AREA_MARGIN * 2);
  return Math.max(0, Math.min(1, x_orig));
}

export function resizeYInverse(y: number, height: number) {
  const y_orig = (y - SCATTER_AREA_MARGIN) / (height - SCATTER_AREA_MARGIN * 2);
  return Math.max(0, Math.min(1, y_orig));
}
