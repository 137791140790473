import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles } from '@mui/styles';

import { overflowOverlayOrAuto } from '../../../../utils/style';
import CabinetCompareList from './CabinetCompareList';

const useStyles = makeStyles(() => ({
  box: {
    flexGrow: 1,
    background: '#fff',
    borderRadius: '6px 0px 0px 0px',
    overflow: overflowOverlayOrAuto(),
  },
}));

const CabinetCompare = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <MUI.Box className={classes.box}>
      <CabinetCompareList />
    </MUI.Box>
  );
};

export default CabinetCompare;
