import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { SearchSmallPlus } from '@superb-ai/icons';
import { Box, Button, Icon, Typography } from '@superb-ai/ui';
import { capitalize } from 'lodash';

import { CellComponentProps } from '../../../../../../../../components/elements/windowedImageGrid/types';
import { WindowedGrid } from '../../../../../../../../components/elements/windowedImageGrid/WindowedGrid';
import { useImageFilterContext } from '../../../../../../contexts/ImageFilterContext';
import { useObjectFilterContext } from '../../../../../../contexts/ObjectFilterContext';
import { IMAGE_SCOPE } from '../../../../../../types/viewTypes';
import { routeView } from '../../../../../../utils/routeUtils';
import { AnnotationFilterSchema, ImageFilterSchema } from '../../../filter/types';
import { EmbeddingDatum, ObjectEmbeddingDatum } from '../types';

export default function SampleImagesGrid<T>({
  areaWidth,
  areaHeight,
  CellComponent,
  columnsCount,
  displayedPoints,
}: {
  areaWidth: number;
  areaHeight: number;
  CellComponent: FC<CellComponentProps<T>>;
  columnsCount: number;
  displayedPoints: (EmbeddingDatum | ObjectEmbeddingDatum)[];
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const scope = searchParams.get('scope') || 'image';
  const {
    setAppliedFilters: setImageAppliedFilter,
    selectedProjectionIn: setImageSelectedProjectionIn,
    selectedSuperClusters: selectedImageClusters,
  } = useImageFilterContext();
  const {
    setAppliedFilters: setObjectAppliedFilter,
    selectedProjectionIn: setObjectSelectedProjectionIn,
    selectedSuperClusters: selectedObjectClusters,
    selectedClasses,
    classClusterFilterTab,
  } = useObjectFilterContext();

  const selectedProjectionIn =
    scope === IMAGE_SCOPE ? setImageSelectedProjectionIn : setObjectSelectedProjectionIn;

  const showDataButtonText = t(`curate.embeddings.button.showAll${capitalize(scope)}sInGrid`);

  const buttonHeight = 32;
  const handleClickShowAllImages = () => {
    setImageAppliedFilter((appliedFilters: ImageFilterSchema) => ({
      ...appliedFilters,
      ...(selectedImageClusters && {
        cluster_id_in: selectedImageClusters as string[],
      }),
      ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
    }));
  };

  const handleClickShowAllObjects = () => {
    setObjectAppliedFilter((appliedFilters: AnnotationFilterSchema) => ({
      ...appliedFilters,
      ...(selectedClasses &&
        classClusterFilterTab === 'class' && {
          annotation_class_in: selectedClasses,
        }),
      ...(classClusterFilterTab === 'cluster' &&
        selectedObjectClusters && {
          annotation_class_cluster_in: selectedObjectClusters,
        }),
      ...(selectedProjectionIn && { projection_in: selectedProjectionIn }),
    }));
  };

  const ShowAllDataButton = (
    <Button
      variant="stroke"
      size="m"
      color="primary"
      style={{ height: `${buttonHeight}px`, width: '100%' }}
      onClick={() => {
        if (scope === 'image') handleClickShowAllImages();
        else handleClickShowAllObjects();
        routeView(history, 'grid');
      }}
    >
      <Icon icon={SearchSmallPlus} />
      {showDataButtonText}
    </Button>
  );

  if (!displayedPoints.length)
    return (
      <Box mt={1}>
        <Typography variant="m-regular" color="gray-300">
          {t('curate.embeddings.text.noDataToShow')}
        </Typography>
      </Box>
    );
  return (
    <>
      <Box display="flex" mr={1} mb={1}>
        {ShowAllDataButton}
      </Box>
      <WindowedGrid
        loadedItems={displayedPoints}
        width={areaWidth}
        height={areaHeight - buttonHeight}
        itemData={{ columns: columnsCount, displayedPoints }}
        CellComponent={CellComponent}
      />
    </>
  );
}
