import { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { InfoCircleOutline, InfoFilled } from '@superb-ai/icons';
import { Card } from '@superb-ai/norwegian-forest';
import { Box, Icon, Label, Tooltip, Typography } from '@superb-ai/ui';

import { CurationType, SPLIT_TRAIN_VAL } from '../../../../../../../../consts/AutoCurateConst';
import SliceNameInput from '../../../../../../elements/NameInput/SliceNameInput';
export default function SliceNameSteps({
  setCurateSliceName,
  setTrainSliceName,
  setMislabelSliceName,
  samplingMethod,
  curateSliceName,
  trainSliceName,
  mislabelSliceName,
  setIsValidCurateName,
  setIsValidTrainName,
  setIsValidMislabelName,
}: {
  setCurateSliceName: (value: string) => void;
  setTrainSliceName: (value: string) => void;
  setMislabelSliceName: (value: string) => void;
  samplingMethod: CurationType;
  curateSliceName: string;
  trainSliceName: string;
  mislabelSliceName: string;
  setIsValidCurateName: Dispatch<SetStateAction<boolean>>;
  setIsValidTrainName: Dispatch<SetStateAction<boolean>>;
  setIsValidMislabelName: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const { t } = useTranslation();
  const { datasetId } = useParams<{ datasetId: string }>();

  return (
    <>
      {samplingMethod === SPLIT_TRAIN_VAL ? (
        <Box display="flex" flexDirection="column" gap={1}>
          <Label vertical alignItems="stretch">
            <Typography variant="m-regular">
              {t('curate.autoCurate.sliceNameSteps.validationSetSliceName')}
            </Typography>
            <SliceNameInput
              placeholder={t('curate.autoCurate.placeholder.validationName')}
              sliceName={curateSliceName}
              setSliceName={setCurateSliceName}
              setIsValid={setIsValidCurateName}
              datasetId={datasetId}
            />
          </Label>
          <Label vertical alignItems="stretch">
            <Typography variant="m-regular">
              {t('curate.autoCurate.sliceNameSteps.trainSetSliceName')}
            </Typography>
            <SliceNameInput
              placeholder={t('curate.autoCurate.placeholder.trainName')}
              sliceName={trainSliceName}
              setSliceName={setTrainSliceName}
              setIsValid={setIsValidTrainName}
              datasetId={datasetId}
            />
          </Label>
          <Label vertical alignItems="stretch">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="m-regular">
                {t('curate.autoCurate.sliceNameSteps.mislabelSliceName')}
              </Typography>
              <Tooltip content={t('curate.autoCurate.sliceNameTooltip')} placement="top-end">
                <Icon icon={InfoCircleOutline} size="12px" />
              </Tooltip>
            </Box>
            <SliceNameInput
              placeholder={t('curate.autoCurate.placeholder.mislabelName')}
              sliceName={mislabelSliceName}
              setSliceName={setMislabelSliceName}
              setIsValid={setIsValidMislabelName}
              datasetId={datasetId}
            />
          </Label>
        </Box>
      ) : (
        <>
          <SliceNameInput
            placeholder={t('curate.autoCurate.placeholder.toLabeling')}
            sliceName={curateSliceName}
            setSliceName={setCurateSliceName}
            setIsValid={setIsValidCurateName}
            datasetId={datasetId}
          />
          <Card
            style={{
              background: '#F8F8FF',
              padding: '12px',
              boxShadow: 'none',
              display: 'flex',
              marginTop: '8px',
            }}
          >
            <Box display="flex">
              <Box mr={0.5}>
                <Icon icon={InfoFilled} color="secondary-400" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="m-regular" color="secondary-400">
                  <Trans t={t} i18nKey={'curate.autoCurate.sliceNameInfo'} />
                </Typography>
              </Box>
            </Box>
          </Card>
        </>
      )}
    </>
  );
}
