import React, { CSSProperties, ComponentProps } from 'react';

import { Box, Typography } from '@superb-ai/norwegian-forest';
import { Icon } from '@superb-ai/ui';

export type ReportStatisticsCardProps = {
  title: string;
  statistic?: number | string;
  type?: 'time' | 'count';
  style?: CSSProperties;
  icon?: ComponentProps<typeof Icon>['icon'];
};

const ReportStatisticsCard: React.FC<ReportStatisticsCardProps> = (
  props: ReportStatisticsCardProps,
) => {
  const { title, statistic, icon } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={8}
      style={{
        width: '250px',
        height: '60px',
        marginTop: '40px',
        marginLeft: '40px',
        marginRight: '40px',
        ...props.style,
      }}
    >
      <Box display="flex" gap={3} alignItems="center" justifyContent="left" width="100%">
        {icon && <Icon icon={icon} color="gray" />}
        <Typography variant="label" themedColor="cloud">
          {title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="left" width="100%">
        <Typography variant="headline3">{statistic}</Typography>
      </Box>
    </Box>
  );
};

export default ReportStatisticsCard;
