import React from 'react';

import DangerZone from '../../../../components/pages/settings/general/dangerZone';
import ProjectConfiguration from '../../../../components/pages/settings/general/projectConfiguration';
import PublicProject from '../../../../components/pages/settings/general/publicProject';
import { ProjectRole } from '../../../../types/memberTypes';

const Settings: Record<string, JSX.Element> = {
  projectConfiguration: <ProjectConfiguration />,
  publicProject: <PublicProject />,
  dangerZone: <DangerZone />,
};

export const SettingsPerRole: Record<ProjectRole, JSX.Element[]> = {
  owner: [Settings.projectConfiguration, Settings.dangerZone],
  admin: [Settings.projectConfiguration, Settings.dangerZone],
  'system admin': [Settings.projectConfiguration, Settings.dangerZone],
  manager: [Settings.projectConfiguration],
  reviewer: [],
  labeler: [],
};
