import React from 'react';

import * as MUI from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import RoleChip from '../RoleChip';
import TextEllipsisTooltip from '../TextEllipsisTooltip';
import UserAvatar from '../UserAvatar';

const useStyles = makeStyles(theme => ({
  item: (props: { focused: boolean }) => ({
    backgroundColor: props.focused ? theme.palette.grey[200] : 'initial',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  }),
}));

interface Props {
  entry: { avatarUrl: string; display: string; id: string; title: string };
  focused: boolean;
}

const MentionSelectList: React.FC<Props> = props => {
  const { entry, focused } = props;
  const { avatarUrl, display, id, title } = entry;
  const classes = useStyles({ focused });
  const theme = useTheme();

  return (
    <MUI.Box
      p={theme.spacing(0.08)}
      width="238px"
      display="flex"
      alignItems="center"
      className={classes.item}
    >
      <UserAvatar size={16} noShadow userInfo={{ avatarUrl, name: display, email: id }} />
      <TextEllipsisTooltip text={display} width="70px" mr="4px">
        <MUI.Typography variant="body1">{display}</MUI.Typography>
      </TextEllipsisTooltip>
      <TextEllipsisTooltip text={id} width="114px" mr="4px">
        <MUI.Typography variant="body1" color="textSecondary">
          {id}
        </MUI.Typography>
      </TextEllipsisTooltip>
      {title && <RoleChip memberRole={title} initial />}
    </MUI.Box>
  );
};

export default MentionSelectList;
