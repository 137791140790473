import React, { createContext, useContext } from 'react';
import { useParams } from 'react-router';

import { useDatasetDataCountQuery } from '../queries/dataQueries';
import { useDatasetObjectCountQuery } from '../queries/datasetObjectQueries';
import { useImageFilterContext } from './ImageFilterContext';
import { useQueryContext } from './QueryContext';
import { useSliceContext } from './SliceContext';

type ImageScopeContext = {
  totalCount: number | undefined;
  objectsInImageTotalCount: number | undefined;

  semanticSearchTotalCount: number | undefined;
  semanticSearchobjectsInImageTotalCount: number | undefined;
} & {};

const ScopeContext = createContext<ImageScopeContext>({} as ImageScopeContext);

const useProvider = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const { queryStringAndHiddenFilterQueryString } = useQueryContext();
  const { sliceInfo } = useSliceContext();

  const { appliedFilters } = useImageFilterContext();
  const dataQuery = useDatasetDataCountQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name,
    appliedFilters,
  });
  const objectQuery = useDatasetObjectCountQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name || '',
    appliedFilters,
  });

  const semanticSearchDataQuery = useDatasetDataCountQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name,
    appliedFilters,
    clipEmbedExists: true,
  });
  const semanticSearchObjectQuery = useDatasetObjectCountQuery({
    datasetId,
    queryString: queryStringAndHiddenFilterQueryString,
    sliceName: sliceInfo?.name || '',
    appliedFilters,
    clipEmbedExists: true,
  });

  return {
    totalCount: dataQuery?.data?.count,
    objectsInImageTotalCount: objectQuery?.data?.count,

    semanticSearchTotalCount: semanticSearchDataQuery?.data?.count,
    semanticSearchobjectsInImageTotalCount: semanticSearchObjectQuery?.data?.count,
  };
};

export const useImageScopeContext = () => {
  return useContext(ScopeContext);
};

export const ImageScopeProvider: React.FC = ({ children }) => {
  const imageScopeInfo = useProvider();
  return <ScopeContext.Provider value={imageScopeInfo}>{children}</ScopeContext.Provider>;
};
