import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const SkeletonWave = keyframes`
0%{
  letf: -100%;
}
100%{
  left: 200%;
}
`;

export const ProgressAnimation = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  animation: ${SkeletonWave} 1.6s linear 0s infinite;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
`;
