export function getError(error: unknown) {
  return toErrorWithMessage(error).message;
}

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  // maybeError == actual error
  if (isErrorWithMessage(maybeError)) return maybeError;

  // maybeError !== actual error
  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // Fallback: there's an error stringifying the maybeError
    return new Error(String(maybeError));
  }
}
