import { TFunction } from 'next-i18next';

import { useGenerateSyntheticImageInfo } from './GenerateSyntheticImageContext';
import SelectGenerativeAIModel from './steps/SelectGenerativeAIModel';
import SelectImagesMultiplier from './steps/SelectImagesMultiplier';
import SelectSlice from './steps/SelectSlice';

const GenerateSyntheticImagesSteps = (t: TFunction, isDialogOpen: boolean) => {
  const GENERATIVE_AI_MODEL = 'generative_ai_model';
  const GENERATED_IMAGES_COUNT = 'generated_images_count';
  const SLICE = 'slice';

  const {
    generativeModel,
    setGenerativeModel,
    applicableImagesCount,
    setApplicableImagesCount,
    imagesMultiplier,
    setImagesMultiplier,
    slice,
    setSlice,
    isSliceNameValid,
    setIsSliceNameValid,
  } = useGenerateSyntheticImageInfo();

  return {
    [GENERATIVE_AI_MODEL]: SelectGenerativeAIModel(t, isDialogOpen, {
      generativeModel,
      setGenerativeModel,
      setApplicableImagesCount,
    }),
    [GENERATED_IMAGES_COUNT]: SelectImagesMultiplier(t, isDialogOpen, {
      imagesMultiplier,
      setImagesMultiplier,
      applicableImagesCount,
    }),
    [SLICE]: SelectSlice(t, isDialogOpen, {
      slice,
      setSlice,
      isSliceNameValid,
      setIsSliceNameValid,
    }),
  };
};

export default GenerateSyntheticImagesSteps;
