import React, { Dispatch, SetStateAction } from 'react';

import * as MUI from '@mui/material';

import TablePaginationAction from '../TablePagination/TablePaginationAction';

type Props = {
  totalCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
};

const TablePagination: React.FC<Props> = ({ totalCount, currentPage, setCurrentPage }) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setCurrentPage(newPage + 1);
  };

  if (totalCount === 0) return <></>;

  return (
    <MUI.TablePagination
      rowsPerPageOptions={[10]}
      component="div"
      count={totalCount}
      rowsPerPage={10}
      page={currentPage - 1}
      labelDisplayedRows={() => ''}
      onPageChange={handleChangePage}
      ActionsComponent={TablePaginationAction as any}
      style={{
        paddingRight: '60px',
      }}
    />
  );
};

export default TablePagination;
