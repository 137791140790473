import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CheckFilled, ClearFilled, InfoFilled, LoadingSpinnerSmall, Radio } from '@superb-ai/icons';
import { Box, Icon, LoadingIndicator, Typography, useDialogState } from '@superb-ai/ui';

import { Row } from '../../../../components/elements/Row';
import { formatDateTime } from '../../../../utils/date';
import { ITERATION_INDEX_COEFFICIENT, MIN_EPOCH } from '../../constant';
import { isRecognitionAIModelTraining } from '../../services/modelTrainingTypeGuards';
import {
  ModelStatus,
  ModelTrainingEpochs,
  MyModel,
  MyModelDetail,
  TrainingStage,
} from '../../services/types';
import { convertSecondsToTime } from '../../utils/formatUtils';
import { formatDownloadTime } from '../list/Layout';
import { CompleteTrainingDialog } from './CompleteTrainingDialog';
import { PerformanceChart } from './PerformanceChart';
import { SamplePrediction } from './SamplePrediction';

export const Progress = ({
  data,
  trainingEpochsData,
}: {
  data: MyModelDetail;
  trainingEpochsData: ModelTrainingEpochs | undefined;
}) => {
  const { t } = useTranslation();
  const completeTrainingDialogState = useDialogState();
  const samplePredictionDialogState = useDialogState();

  const hasTrainingProcess = data.modelTraining.currentEpoch > 0;
  const canEarlyComplete =
    data.modelTraining.currentEpoch > 0 &&
    typeof data.modelTraining.totalEpochs === 'number' &&
    data.modelTraining.currentEpoch < data.modelTraining.totalEpochs &&
    data.status === 'training';

  return (
    <>
      {canEarlyComplete && (
        <Row
          backgroundColor={'secondary-100'}
          px={1.5}
          mt={1}
          mb={1}
          gap={0.5}
          style={{ height: 34 }}
        >
          <Icon icon={InfoFilled} color="secondary" />
          <Typography variant="m-regular" color="secondary-400">
            {t('model.myModelDetail.canCompleteEarlyInfo')}
          </Typography>
        </Row>
      )}
      {data.modelTraining.trainingHistory && (
        <Box
          border="1px solid"
          borderColor="gray-150"
          borderRadius="2px"
          px={2.5}
          pt={2}
          pb={2.5}
          mb={1}
        >
          {data.status === 'failed' ? (
            <TrainingProgressWithoutSteps
              startedAt={safeFormatTime(data.modelTraining.trainingHistory[0].startedAt)}
              endedAt={safeFormatTime(data.statusUpdatedAt)}
            />
          ) : (
            <TrainingProgress data={data} />
          )}
        </Box>
      )}

      {!(
        data.status === 'failed' ||
        (data.status === 'canceled' &&
          isRecognitionAIModelTraining(data.modelTraining) &&
          data.modelTraining.modelTrainingEpochs.length === 0)
      ) && (
        <Box>
          <Box
            display="grid"
            columnGap={1}
            mb={2}
            style={{ gridTemplateColumns: 'calc(50% - 4px) calc(50% - 4px)' }}
          >
            <Box
              border="1px solid"
              borderColor="gray-150"
              borderRadius="2px"
              px={2.5}
              pt={2}
              pb={2.5}
            >
              <Box mb={1}>
                <Typography variant="l-strong">
                  {t('model.myModelDetail.performanceChart.title')}
                </Typography>
              </Box>
              <PerformanceChart data={data} hasTrainingProcess={hasTrainingProcess} />
            </Box>
            <Box
              border="1px solid"
              borderColor="gray-150"
              borderRadius="2px"
              px={2.5}
              pt={2}
              pb={2.5}
            >
              <Box mb={1}>
                <Typography variant="l-strong">
                  {t('model.myModelDetail.samplePrediction.title')}
                </Typography>
              </Box>
              {trainingEpochsData ? (
                <SamplePrediction
                  trainingEpochsData={trainingEpochsData}
                  dialogState={samplePredictionDialogState}
                  hasTrainingProcess={hasTrainingProcess}
                  minEpoch={MIN_EPOCH}
                  maxEpoch={data.modelTraining.currentEpoch}
                  status={data.status}
                />
              ) : (
                <LoadingIndicator />
              )}
            </Box>
          </Box>
        </Box>
      )}

      {completeTrainingDialogState.visible && (
        <CompleteTrainingDialog
          modelId={data.id}
          state={completeTrainingDialogState}
          currentEpoch={data.modelTraining.currentEpoch}
          totalEpochs={data.modelTraining.totalEpochs}
        />
      )}
    </>
  );
};

export const TrainingProgress = ({ data }: { data: MyModelDetail }) => {
  const { t } = useTranslation();

  const convertEpochs = (step: number) => {
    const done =
      data.modelTraining.trainingHistory[2].startedAt &&
      data.modelTraining.trainingHistory[2].endedAt;
    const completing =
      data.status === 'stopped' &&
      data.modelTraining.trainingHistory[2].trainingStage === 'training' &&
      data.modelTraining.trainingStage === 'training';

    if (data.baselineModel.purpose === 'recognition') {
      if (done)
        return step === 1
          ? `${step} ${t('model.myModelDetail.epoch')}`
          : `${step} ${t('model.myModelDetail.epochs')}`;
      if (completing)
        return step === 1
          ? `${step} ${t('model.myModelDetail.epoch')}...`
          : `${step} ${t('model.myModelDetail.epochs')}...`;
      return step === 1
        ? `${step}/${data.modelTraining.totalEpochs} ${t('model.myModelDetail.epoch')}`
        : `${step}/${data.modelTraining.totalEpochs} ${t('model.myModelDetail.epochs')}`;
    } else {
      if (done)
        return step === 1
          ? `${step * ITERATION_INDEX_COEFFICIENT} ${t('model.generativeAi.interationUnit')}`
          : `${step * ITERATION_INDEX_COEFFICIENT} ${t('model.generativeAi.interationUnits')}`;
      if (completing)
        return step === 1
          ? `${step * ITERATION_INDEX_COEFFICIENT} ${t('model.generativeAi.interationUnit')}...`
          : `${step * ITERATION_INDEX_COEFFICIENT} ${t('model.generativeAi.interationUnits')}...`;
      return step === 1
        ? `${step * ITERATION_INDEX_COEFFICIENT}/${data.modelTraining.totalEpochs} ${t(
            'model.generativeAi.interationUnit',
          )}`
        : `${step * ITERATION_INDEX_COEFFICIENT}/${data.modelTraining.totalEpochs} ${t(
            'model.generativeAi.interationUnits',
          )}`;
    }
  };

  return (
    <>
      <Row mb={2.5}>
        <Typography variant="l-strong" mr={1}>
          {t('model.myModelDetail.trainingStep')}
        </Typography>
        <Typography variant="s-regular">
          {formatDownloadTime(t, data.modelTraining.totalEstimatedTime, data.status)}
        </Typography>
        <Typography variant="m-regular" color={'gray-300'} ml="auto">
          {t('model.myModelDetail.startedFinishedAt')}
        </Typography>
        <Typography variant="m-regular" ml={2}>
          {safeFormatTime(data.createdAt)} /{' '}
          {safeFormatTime(
            data.status === 'canceled'
              ? data.statusUpdatedAt
              : data.modelTraining.trainingHistory[3].endedAt,
          )}
        </Typography>
      </Row>
      <Row
        display="grid"
        style={{
          gridTemplateColumns:
            'calc(25% - 6px) 8px calc(25% - 6px) 8px calc(25% - 6px) 8px calc(25% - 6px)',
        }}
      >
        <TrainingStep
          progressUpdatedAt={data.modelTraining.progressUpdatedAt}
          trainingHistory={data.modelTraining.trainingHistory[0]}
          progress={data.modelTraining.progress}
          trainingStage={data.modelTraining.trainingStage}
          modelStatus={data.status}
        />
        <Box backgroundColor={'gray-150'} style={{ height: '1px' }} />
        <TrainingStep
          progressUpdatedAt={data.modelTraining.progressUpdatedAt}
          trainingHistory={data.modelTraining.trainingHistory[1]}
          progress={data.modelTraining.progress}
          modelStatus={data.status}
          trainingStage={data.modelTraining.trainingStage}
        />
        <Box backgroundColor={'gray-150'} style={{ height: '1px' }} />
        <TrainingStep
          progressUpdatedAt={data.modelTraining.progressUpdatedAt}
          trainingHistory={data.modelTraining.trainingHistory[2]}
          progress={data.modelTraining.progress}
          trainingSteps={
            data.modelTraining.totalEpochs
              ? convertEpochs(data?.modelTraining.currentEpoch)
              : undefined
          }
          trainingStage={data.modelTraining.trainingStage}
          modelStatus={data.status}
        />
        <Box backgroundColor={'gray-150'} style={{ height: '1px' }} />
        <TrainingStep
          progressUpdatedAt={data.modelTraining.progressUpdatedAt}
          trainingHistory={data.modelTraining.trainingHistory[3]}
          progress={data.modelTraining.progress}
          trainingStage={data.modelTraining.trainingStage}
          modelStatus={data.status}
        />
      </Row>
    </>
  );
};

const TrainingStep = ({
  progressUpdatedAt,
  trainingHistory,
  progress,
  trainingSteps,
  trainingStage,
  modelStatus,
}: {
  progressUpdatedAt: number;
  trainingHistory: MyModel['modelTraining']['trainingHistory'][number];
  progress: number;
  trainingSteps?: string | ReactNode;
  trainingStage: TrainingStage;
  modelStatus: ModelStatus;
}) => {
  const { t } = useTranslation();

  const inProgress = trainingHistory.startedAt && !trainingHistory.endedAt;
  const done = trainingHistory.startedAt && trainingHistory.endedAt;
  const scheduled = !trainingHistory.startedAt && !trainingHistory.endedAt;
  const canceled = modelStatus === 'canceled' && trainingStage === trainingHistory.trainingStage;
  const completing =
    modelStatus === 'stopped' &&
    trainingHistory.trainingStage === 'training' &&
    trainingStage === 'training';

  const getStageIcon = () => {
    if (done) {
      return <Icon icon={CheckFilled} color={'green-400'} size={16} style={{ marginRight: 4 }} />;
    } else if (canceled) {
      return <Icon icon={ClearFilled} color="cloud-400" size={16} style={{ marginRight: 4 }} />;
    } else if (scheduled) {
      return <Icon icon={Radio} color={'cloud-400'} size={16} style={{ marginRight: 4 }} />;
    } else if (inProgress) {
      return (
        <Icon icon={LoadingSpinnerSmall} color="yellow-400" size={16} style={{ marginRight: 4 }} />
      );
    } else {
      return <Icon icon={Radio} color={'cloud-400'} size={16} style={{ marginRight: 4 }} />;
    }
  };

  const getSpentTime = () => {
    if (trainingHistory.startedAt && trainingHistory.endedAt) {
      // done
      return (
        <Trans t={t} i18nKey={'model.myModelDetail.timeSpent'}>
          {{
            time: convertSecondsToTime(
              (trainingHistory.endedAt - trainingHistory.startedAt) / 1000,
            ),
          }}
        </Trans>
      );
    } else if (!trainingHistory.startedAt && !trainingHistory.endedAt) {
      // scheduled
      return (
        <Trans t={t} i18nKey={'model.myModelDetail.timeSpent'}>
          {{
            time: '-',
          }}
        </Trans>
      );
    } else if (trainingHistory.startedAt && !trainingHistory.endedAt) {
      if (canceled) {
        return (
          <Trans t={t} i18nKey={'model.myModelDetail.timeSpent'}>
            {{
              time: convertSecondsToTime((progressUpdatedAt - trainingHistory.startedAt) / 1000),
            }}
          </Trans>
        );
      }
      // in progress
      return (
        <Trans t={t} i18nKey={'model.myModelDetail.timeSpent'}>
          {{
            time: convertSecondsToTime((new Date().getTime() - trainingHistory.startedAt) / 1000),
          }}
        </Trans>
      );
    } else {
      return '-';
    }
  };

  const getEstimatedTime = () => {
    if (canceled) return null;
    if (completing) return t('model.myModels.completeing');
    if (!trainingHistory.startedAt && !trainingHistory.endedAt) {
      // scheduled
      return (
        <Trans t={t} i18nKey={'model.myModels.trainingProgress.estimated'}>
          {{ time: convertSecondsToTime(trainingHistory.estimatedTime) }}
        </Trans>
      );
    } else if (trainingHistory.startedAt && !trainingHistory.endedAt) {
      // in progress
      const remainingTime =
        trainingHistory.estimatedTime - (new Date().getTime() - progressUpdatedAt) / 1000;
      if (remainingTime < 60) {
        return t('model.myModels.lessThanMinute');
      }
      return (
        <Trans t={t} i18nKey={'model.myModelDetail.totalEstimatedTime'}>
          {{ percentage: progress, time: convertSecondsToTime(remainingTime) }}
        </Trans>
      );
    } else {
      return '';
    }
  };

  return (
    <Box
      border="1px solid"
      borderColor="gray-150"
      borderRadius="2px"
      p={1}
      style={{ width: '100%' }}
    >
      <Row>
        {getStageIcon()}
        <Typography variant="m-regular">
          {t(`model.myModelDetail.trainingStages.${trainingHistory.trainingStage}`)}
        </Typography>
        <Typography
          variant="s-regular"
          color={inProgress || completing ? 'yellow-400' : undefined}
          style={{ marginLeft: 'auto' }}
        >
          {getEstimatedTime()}
        </Typography>
      </Row>
      <Row>
        <Box style={{ width: 20 }} />
        <Typography variant="s-regular" color={'gray-300'}>
          {getSpentTime()}
        </Typography>
        {!canceled && Boolean(trainingSteps) && (
          <Typography variant="s-regular" ml="auto">
            {trainingSteps}
          </Typography>
        )}
      </Row>
    </Box>
  );
};

export const TrainingProgressWithoutSteps = ({
  startedAt,
  endedAt,
}: {
  startedAt: string | null;
  endedAt: string | null;
}) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Typography variant="l-strong">{t('model.myModelDetail.trainingStep')}</Typography>
      <Box ml="auto">
        <Typography variant="m-regular" color={'gray-300'} ml="auto">
          {t('model.myModelDetail.startedFinishedAt')}
        </Typography>
        <Typography variant="m-regular" ml={2}>
          {startedAt} / {endedAt}
        </Typography>
      </Box>
    </Row>
  );
};

export const safeFormatTime = (time: number | null) => {
  if (!time) return '-';
  return formatDateTime(new Date(time));
};
