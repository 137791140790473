import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const PlanPage = () => {
  const history = useHistory();
  useEffect(() => {
    window.open(`//${process.env.NEXT_PUBLIC_HOMEPAGE}/pricing`, '_blank', 'noreferrer');
    history.goBack();
  }, []);

  return <></>;
};

export default PlanPage;
