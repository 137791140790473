import React from 'react';
import { LightAsync } from 'react-syntax-highlighter';
import shell from 'react-syntax-highlighter/dist/esm/languages/hljs/shell';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

LightAsync.registerLanguage('shell', shell);

type Props = React.ComponentProps<typeof LightAsync>;

const style = {
  ...dracula,
  'hljs-meta': {
    opacity: 0.5,
  },
};

const customStyle = {
  borderRadius: '2px',
};

export default function SyntaxHighlighter({ children, ...props }: Props): JSX.Element {
  return (
    <LightAsync style={style} customStyle={customStyle} showLineNumbers language="shell" {...props}>
      {children}
    </LightAsync>
  );
}
