import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as MUI from '@mui/material';
import { Input } from '@superb-ai/norwegian-forest';

import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext';
import { useProjectInfo } from '../../../../contexts/ProjectContext';
import { dataKey } from '../../../../utils/filter/labelFilter';
import FilterDropdown from './components/FilterDropdown';
import { FilterTypeProps } from './types';

const FilterDataKey: React.FC<FilterTypeProps> = props => {
  const { t } = useTranslation();
  const { index, filter, isCurrentRow, onChangeCurrentRow, onValueChange } = props;
  const [value, setValue] = useState(filter.options?.[0]?.value ?? '');
  const { project } = useProjectInfo();
  const lokiFlag = useFeatureFlag('labelsLoki');
  const enabledLoki = !(project?.settings.allowAdvancedQa ?? false) && lokiFlag;

  // TODO: Add test code in data key or also other filters (Ye In)
  const getCharacterCount = () => {
    switch (filter.condition) {
      case 'contains':
        return 3;
      case 'ends with':
        return 2;
      default:
        return 1;
    }
  };

  useEffect(() => {
    if (enabledLoki || value.length >= getCharacterCount()) {
      onValueChange(index, 'options', [{ value }]);
    } else {
      onValueChange(index, 'options', [{ value: '' }]);
    }
  }, [enabledLoki, value, filter.condition]);

  return (
    <>
      <MUI.Grid item>
        <FilterDropdown
          index={index}
          objKey="condition"
          isCurrentRow={isCurrentRow}
          options={
            enabledLoki
              ? dataKey.conditions
              : dataKey.conditions?.[0]
              ? [dataKey.conditions?.[0]]
              : undefined
          }
          value={filter.condition}
          onValueChange={onValueChange}
          onChangeCurrentRow={onChangeCurrentRow}
        />
      </MUI.Grid>
      <MUI.Grid item style={{ flex: 1 }}>
        <Input
          variant="stroke"
          focussed={isCurrentRow}
          onFocus={() => onChangeCurrentRow(index)}
          value={value}
          onChange={e => setValue(e.target.value)}
          minLength={3}
          placeholder={
            enabledLoki
              ? t('labels.filters.dataKey.placeholderWithoutConstraint')
              : t('labels.filters.dataKey.placeholder', {
                  characterCount: getCharacterCount(),
                })
          }
          style={{ height: '30px' }}
        />
      </MUI.Grid>
    </>
  );
};

export default FilterDataKey;
