import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { CamelizedProperty } from 'humps';

import { useDatasetImageQuery } from '../../../../Curate/queries/dataQueries';
import { CurateImageData } from '../../../../Curate/services/DatasetService';
import { usePostGenerationEndpointInferenceImage } from '../../../queries/modelQueries';
import { GenerationEndpointInferenceImageData } from '../../../services/ModelService';
import { MyModelDetail } from '../../../services/types';

type ContextProps = {
  datasetImage: CamelizedProperty<CurateImageData> | null;
  setDatasetImage: Dispatch<SetStateAction<CamelizedProperty<CurateImageData> | null>>;
  outputIndex: number;
  setOutputIndex: Dispatch<SetStateAction<number>>;
  filteredPredictionAnnotationsIds: string[];
  setFilteredPredictionAnnotationsIds: Dispatch<SetStateAction<string[]>>;
  annotationSelectedMap: Record<string, boolean>;
  setAnnotationSelectedMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  annotationHoveredMap: Record<string, boolean>;
  setAnnotationHoveredMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  clickedAnnotationId: string | null;
  setClickedAnnotationId: Dispatch<SetStateAction<string | null>>;
  hoveredAnnotatinoId: string | null;
  setHoveredAnnotationId: Dispatch<SetStateAction<string | null>>;
  datasetImageQueryData: CurateImageData;
  inferenceMutate: ReturnType<typeof usePostGenerationEndpointInferenceImage>['mutate'];
  isInferenceLoading: boolean;
  inferenceData: GenerationEndpointInferenceImageData;
  model: MyModelDetail;
  isModelLoading: boolean;
};

const Context = createContext({} as ContextProps);

const useProvider = ({
  model,
  isModelLoading,
}: {
  model: MyModelDetail;
  isModelLoading: boolean;
}) => {
  const [datasetImage, setDatasetImage] = useState<CamelizedProperty<CurateImageData> | null>(null);
  const [outputIndex, setOutputIndex] = useState(0);
  const [filteredPredictionAnnotationsIds, setFilteredPredictionAnnotationsIds] = useState<
    string[]
  >([]);
  const [annotationSelectedMap, setAnnotationSelectedMap] = useState<Record<string, boolean>>({});
  const [annotationHoveredMap, setAnnotationHoveredMap] = useState<Record<string, boolean>>({});
  const [clickedAnnotationId, setClickedAnnotationId] = useState(null);
  const [hoveredAnnotatinoId, setHoveredAnnotationId] = useState<string | null>(null);

  const { data: datasetImageQueryData } = useDatasetImageQuery({
    datasetId: model?.trainingSet.referenceId,
    imageId: datasetImage?.id,
  });
  const {
    mutate: inferenceMutate,
    isLoading: isInferenceLoading,
    data: inferenceData,
  } = usePostGenerationEndpointInferenceImage();

  return {
    datasetImage,
    setDatasetImage,
    outputIndex,
    setOutputIndex,
    filteredPredictionAnnotationsIds,
    setFilteredPredictionAnnotationsIds,
    annotationSelectedMap,
    setAnnotationSelectedMap,
    annotationHoveredMap,
    setAnnotationHoveredMap,
    clickedAnnotationId,
    setClickedAnnotationId,
    hoveredAnnotatinoId,
    setHoveredAnnotationId,
    datasetImageQueryData,
    inferenceMutate,
    isInferenceLoading,
    inferenceData,
    model,
    isModelLoading,
  };
};
export const useGenerationPredictionTestContext = (): ContextProps => {
  return useContext(Context);
};

export const GenerationPredictionTestProvider = ({
  model,
  isModelLoading,
  children,
}: {
  model: MyModelDetail;
  isModelLoading: boolean;
  children: ReactNode;
}) => {
  const recognitionPredictionTestInfo = useProvider({ model, isModelLoading });
  return <Context.Provider value={recognitionPredictionTestInfo}>{children}</Context.Provider>;
};
